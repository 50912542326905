import React, { } from 'react';
import PropTypes from 'prop-types'
import esitoQuestionario from "../../utils/dataset/questionariData/esitoQuestionario.json";

function InfoPopupEsitoQuestionario({ tipoQuestionario }) {
    const infoPopup = esitoQuestionario[tipoQuestionario];
    return (
        <ul>{infoPopup?.map((item) => (<li key={item.label}>{`${item.label} : ${item.description}`}</li>))}</ul>
    )
}

InfoPopupEsitoQuestionario.propTypes = {
    tipoQuestionario: PropTypes.string,
};

InfoPopupEsitoQuestionario.defaultProps = {
    tipoQuestionario: '',
};


export default InfoPopupEsitoQuestionario;
