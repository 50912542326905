import React, {Fragment} from "react";
import styles from "./Accordion.module.css";
import PropTypes from 'prop-types'
import {CheckBox} from "web-client-archetype";

const propTypes = {
    idHeader: PropTypes.string,
    headerClassName: PropTypes.string,
    headerText: PropTypes.any,
    id: PropTypes.string,
    bodyClassName: PropTypes.string,
    body: PropTypes.any,
    ariaExpanded: PropTypes.bool,
    labelHeaderStyle: PropTypes.object,
    fieldCheckbox: PropTypes.string,
    onChangeCheckbox: PropTypes.func,
    elementsNearHeaderLabel: PropTypes.arrayOf(PropTypes.any),
    disableCheckbox: PropTypes.bool
}

const defaultProps = {
    idHeader: "heading1",
    headerClassName: "collapse-header",
    headerText: "Sezione 1",
    id: "collapse1",
    bodyClassName: "collapse-body",
    body: "Lorem Ipsum Sezione 1",
    ariaExpanded: false,
    labelHeaderStyle: {fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"},
    fieldCheckbox: "field",
    onChangeCheckbox: () => {},
    elementsNearHeaderLabel: null,
    disableCheckbox: false
}

class Accordion extends React.Component {

    state = {
        ariaExpanded: this.props.ariaExpanded,
        collapse: "collapse show"
    }

    collapse = () => {
        this.setState({ariaExpanded: !this.state.ariaExpanded}, () => {
            this.setState({collapse: (this.state.ariaExpanded ? "collapse show" : "collapse")})
        })
    }

    render() {
        return (
            <>
                <div className={this.props.ariaExpanded ? styles.border : styles.noBorder}>
                    <div id={"accordionDiv"} className={styles.noBorder.concat(" collapse-div")} role="tablist">
                        <div className={this.props.ariaExpanded ? this.props.headerClassName.concat(" px-2 " + styles.header) : this.props.headerClassName.concat(" " + styles.header)}
                             id={this.props.idHeader}>
                            <div className={"row"}>
                                <div className={this.props.ariaExpanded ? "col-xl-2 col-sm-5 col-6 pt-4" : "col-xl-2 col-sm-4 pt-2"}>
                                    <CheckBox id={this.props.id} data-toggle="collapse" data-target={"#" + this.props.id}
                                              aria-expanded={this.state.ariaExpanded}
                                              aria-controls={this.props.id}
                                              styleLabel={this.props.labelHeaderStyle}
                                              text={this.props.headerText}
                                              field={this.props.fieldCheckbox}
                                              onChange={(field, elem) => this.props.onChangeCheckbox(field, elem.target.checked)}
                                              onClick={() => this.collapse()}
                                              checked={this.props.ariaExpanded}
                                              disabled={this.props.disableCheckbox}/>
                                </div>
                                {this.props.elementsNearHeaderLabel != null && this.props.elementsNearHeaderLabel.length > 0
                                    ? <div className={this.props.ariaExpanded ? "offset-xl-1 offset-lg-1 col-md-8 pt-2" : "d-none"}>
                                        <div className={"row"}>
                                            {this.props.elementsNearHeaderLabel.map(elementJSX => elementJSX)}
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                        <div id={this.props.id} className={this.state.collapse.concat(" " + styles.transition)} role="tabpanel"
                             aria-labelledby={this.props.idHeader}>
                            {this.props.ariaExpanded ?
                                <div className={this.props.bodyClassName.concat(" pb-3 px-3")}>
                                    {this.props.body}
                                </div> :
                                <Fragment/>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

Accordion.propTypes = propTypes;
Accordion.defaultProps = defaultProps;

export default Accordion;
