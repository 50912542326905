import React, {Component, Fragment} from "react";
import {Button, Input} from "web-client-archetype";
import Utils from "../../../utils/Utils";
import GestionePasswordADIService from "../../../service/GestionePasswordADIService";
import PropTypes from "prop-types";
import {Redirect} from 'react-router-dom';
import styleGestionePassword from "./GestionePassword.module.css";
import routePath from "../../../utils/route/route-path";
import ModalGestionePassword from "./ModalGestionePassword";
import {mostraSpinner, nascondiSpinner} from "../../../App";
import {
    CONDIZIONE_PASSWORD_CARATTERE_SPECIALE,
    CONDIZIONE_PASSWORD_LENGTH,
    CONDIZIONE_PASSWORD_MAIUSCOLA
} from "./LockPassword";
import routepath from "../../../utils/route/route-path.json";

const propTypes = {
    location: PropTypes.object,
    pathname: PropTypes.string
}

export default class RecuperaPassword extends Component {
    state = {
        password: null,
        confPassword: null,
        token: "",
        pulsanteConfermaAbilitato: false,
        apriModaleConferma: false,
        redirectTo: null,
    }

    constructor(props) {
        super(props);
        this.handleAggiornaState = this.handleAggiornaState.bind(this);
    }


    recuperaPassword = () => {
        mostraSpinner();
        let msgErrore = "ERRORE";
        let request = {
            password: this.state.password,
            confPassword: this.state.confPassword,
            token: this.state.token
        }
        GestionePasswordADIService.resetPassword(request).subscribe((res) => {
            if (res?.msg === msgErrore) {
                this.apriToasterErrore(res);
                nascondiSpinner();  
            } else {
                this.setState({apriModaleConferma: true})
                nascondiSpinner();
            }
        })
    }

    verificaToken = (token) => {
        let request = {
            token,
        }
        return GestionePasswordADIService.verificaToken(request).toPromise();
    }

    apriToasterErrore = (response) => {
        Utils.toasterFunction({
            status: response?.data?.codice ? response?.data?.codice : response?.status,
            type: "critical",
            text: response?.data?.errors?.pop().message || response?.data?.message
        })
    }

    handleAggiornaState = (field, event) => {
        this.setState({[field]: event.target.value}, this.aggiornaBottoni);
    }

    aggiornaBottoni = () => {
        let pulsanteConfermaAbilitato = false;
        if (Utils.isPasswordValid(this.state.password) &&
            Utils.arePasswordMatch(this.state.password, this.state.confPassword)) {
            pulsanteConfermaAbilitato = true;
        }
        this.setState({pulsanteConfermaAbilitato: pulsanteConfermaAbilitato})
    }


    async componentDidMount() {
        const current_url = new URL(window.location.href);
        const search_params = current_url.searchParams;
        const token = search_params.get('token');

        mostraSpinner();
        const res = await this.verificaToken(token);
        nascondiSpinner();

        if (!res.risultato && this.props.pathname !== routepath.recupera_password
            && this.props.pathname !== routepath.ripristino_password_scaduta) {
            this.setState({redirectTo: {path: routePath.home}})
        }

        this.setState({token: token});
    }

    handleRedirectModale = () => {
        this.setState({redirectTo:{path: routePath.home}})
    }

    render() {
        if(this.state.redirectTo){
            return <Redirect to={{pathname: this.state.redirectTo.path}}/>
        }
        return (
            <Fragment>
                <div className={styleGestionePassword.foregroundMode.concat(" position-relative pt-4")}>
                    <div className="pl-4 pr-4 mr-3">
                        <div className={"row"}>
                            <h1 className={"pb-2"}>Recupera Password</h1>
                        </div>
                        <div className="row text-align-center mb-3">
                            {this.renderInputPasswords()}
                            {this.renderBottoni()}
                        </div>
                    </div>
                </div>
                {this.state.apriModaleConferma ?
                    this.renderModaleConferma() : <Fragment/>}
            </Fragment>
        )
    }

    renderInputPasswords = () => {
        return <Fragment>
            <div className="col">
                <Input
                    parentClass="mb-0 p-0"
                    placeholder="Imposta Password"
                    field="password"
                    onChange={this.handleAggiornaState}
                    id="password"
                    value={this.state.password}
                    type="password"
                    onValidate={(value) => value && !Utils.isPasswordValid(value)}
                    required
                    label={"Imposta Password"}
                    infoText="Campo obbligatorio"
                />
                {this.renderTestoInformativo()}
            </div>
            <div className="col">
                <Input
                    parentClass="mb-3 p-0"
                    placeholder="Conferma Password"
                    field="confPassword"
                    onChange={this.handleAggiornaState}
                    id="confPassword"
                    value={this.state.confPassword}
                    type="password"
                    onValidate={(value) => value && !Utils.arePasswordMatch(this.state.password, value) && "*Le password non coincidono"}
                    required
                    label={"Conferma Password"}
                    infoText="Campo obbligatorio"
                />
            </div>
        </Fragment>;
    }

    renderBottoni = () => {
        return <div className="col-auto mt-1">
            <Button
                id={"conferma"}
                text="Conferma"
                className={"btn btn-adi btnGreenOutline float-right"}
                style={{ outline: "none" }}
                disabled={!this.state.pulsanteConfermaAbilitato}
                onClick={() => this.recuperaPassword()}
            />
        </div>;
    }

    renderTestoInformativo = () => {
        return <div >
                {!Utils.isValidStringMinLength(this.state.password,9)&& CONDIZIONE_PASSWORD_LENGTH}
                {!Utils.isValidAlphanumericMaiuscola(this.state.password) && CONDIZIONE_PASSWORD_MAIUSCOLA}
                {!Utils.isValidCaratteriSpeciali(this.state.password) && CONDIZIONE_PASSWORD_CARATTERE_SPECIALE}
        </div>;
    }

    renderModaleConferma = () => {
        return <ModalGestionePassword
            body={"La password è stata impostata correttamente"}
            handleOnClickModale={this.handleRedirectModale}
            btnText={"OK"}
            renderFooter={true}
            title={"Operazione Eseguita!"}
        />;
    }
}

RecuperaPassword.propTypes = propTypes;
