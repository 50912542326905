import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti,
    defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import Utils from "../../../utils/Utils";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    datiNoteERegoleOsservateDaCulturaEReligione: PropTypes.object,
    onChangeDatiAssistito: PropTypes.func,
    openAccordion: PropTypes.func,
    onClickAccordion: PropTypes.func,
    field: PropTypes.string,
    pageState: PropTypes.string,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class NoteERegoleOsservateDaCulturaEReligione extends AccordionSecondLevel {

    state = {
        datiNoteERegoleOsservateDaCulturaEReligione: _.cloneDeep(this.props.datiNoteERegoleOsservateDaCulturaEReligione)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiNoteERegoleOsservateDaCulturaEReligione, this.props.datiNoteERegoleOsservateDaCulturaEReligione)) {
            this.inizializzaForm();
        }
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    inizializzaForm = () => {
        const datiNoteERegoleOsservateDaCulturaEReligione = _.cloneDeep(this.props.datiNoteERegoleOsservateDaCulturaEReligione);
        this.setState({datiNoteERegoleOsservateDaCulturaEReligione}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiNoteERegoleOsservateDaCulturaEReligione}) => {
            datiNoteERegoleOsservateDaCulturaEReligione[field] = elem;

            if (!datiNoteERegoleOsservateDaCulturaEReligione.restrizioniReligioneCultura) {
                datiNoteERegoleOsservateDaCulturaEReligione.tipoRestrizioni = null;
                datiNoteERegoleOsservateDaCulturaEReligione.condizioneRilevata = null;
            }

            return {datiNoteERegoleOsservateDaCulturaEReligione};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        const datiNoteERegoleOsservateDaCulturaEReligione = _.cloneDeep(this.state.datiNoteERegoleOsservateDaCulturaEReligione);
        this.props.onChangeDatiAssistito("datiNoteERegoleOsservateDaCulturaEReligione", datiNoteERegoleOsservateDaCulturaEReligione);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiNoteERegoleOsservateDaCulturaEReligione?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneNoteERegoleOsservateDaCulturaEReligione", campiObbligatoriRimanenti);
    }

    renderBody = () => {
        const datiNoteERegoleOsservateDaCulturaEReligione = this.state.datiNoteERegoleOsservateDaCulturaEReligione;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'RESTR_RELIG_CULT.NOTEEREGOLEOSSERVATEDACULTURAERELIGIONE.DETERMINANTIDISALUTE'}
                        pageState={this.props.pageState}
                        id={"restrizioniReligioneCultura"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiNoteERegoleOsservateDaCulturaEReligione.restrizioniReligioneCultura}
                        onChange={(elem) => this.handleAggiornaForm("restrizioniReligioneCultura", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Restrizioni legate alla religione/cultura non esistente"}
                        fieldLabel={'Restrizioni legate alla religione/cultura'}
                        handleOnlyValue={true}
                        field={"restrizioniReligioneCultura"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    {datiNoteERegoleOsservateDaCulturaEReligione.restrizioniReligioneCultura &&
                        <RoleBasedInput
                            fieldId={'TIPO_RESTR.NOTEEREGOLEOSSERVATEDACULTURAERELIGIONE.DETERMINANTIDISALUTE'}
                            pageState={this.props.pageState}
                            id={"tipoRestrizioni"}
                            value={datiNoteERegoleOsservateDaCulturaEReligione.tipoRestrizioni}
                            placeholder={"Tipo restrizioni"}
                            field={"tipoRestrizioni"}
                            onChange={(field, elem) => this.handleAggiornaForm("tipoRestrizioni", elem.target.value)}
                            fieldLabel={'Tipo restrizioni'}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />}
                </FieldsRow>

                <CondizioneRilevataSection
                    ambito={'NOTE E REGOLE OSSERVATE DA CULTURA E RELIGIONE'}
                    parentFieldId={'NOTEEREGOLEOSSERVATEDACULTURAERELIGIONE.DETERMINANTIDISALUTE'}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={datiNoteERegoleOsservateDaCulturaEReligione.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={datiNoteERegoleOsservateDaCulturaEReligione.daAttenzionare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={datiNoteERegoleOsservateDaCulturaEReligione.propostaAttivitaSuggerimenti}
                    openCondition={datiNoteERegoleOsservateDaCulturaEReligione.restrizioniReligioneCultura}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />

                <FieldsRow>
                    {datiNoteERegoleOsservateDaCulturaEReligione.restrizioniReligioneCultura &&
                    <RoleBasedCalendar
                        fieldId={'DATA_VAL.NOTEEREGOLEOSSERVATEDACULTURAERELIGIONE.DETERMINANTIDISALUTE'}
                        pageState={this.props.pageState}
                        id={"religioneDataValutazione"}
                        value={datiNoteERegoleOsservateDaCulturaEReligione.dataValutazione}
                        onChange={(elem) => this.handleAggiornaForm("dataValutazione", elem.target.value)}
                        errorMessage={"Data valutazione non valida"}
                        fieldLabel={"Data Valutazione"}
                        field={"dataValutazione"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />}
                </FieldsRow>
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiNoteERegoleOsservateDaCulturaEReligione"}
                title={"Note e regole osservate da cultura e religione"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["determinantiDiSalute.datiNoteERegoleOsservateDaCulturaEReligione.propostaAttivitaSuggerimenti"],
                    [defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}
NoteERegoleOsservateDaCulturaEReligione.propTypes = propTypes;

