import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "web-client-archetype";
import ModalHelper from "../../modale/ModalHelper";
import RichiestaADIService from "../../../service/RichiestaADIService";
import _ from "lodash";
import Utils, { closeModaleStyle } from "../../../utils/Utils";
import { mostraSpinner, nascondiSpinner } from "../../../App";

const propTypes = {
    uuidRichiesta: PropTypes.string,
    chiudiPopupSostituzioneMedicoichiedente: PropTypes.func,
    showPopup: PropTypes.bool,
    updateRichiesta: PropTypes.func,
}

const bodyModaleText = {
    top: "Stai per procedere con la sostituzione del Medico richiedente nella fase di valutazione multidimensionale.",
    down: "Verrà inviata una notifica al Medico richiedente. Vuoi confermare?"
};
const bodyModaleOperazioneEseguitaText = "La richiesta è stata correttamente effettuata";

const titleModale = "Attenzione!";
const titleModaleOperazioneEseguita = "Operazione completata";

const idModale = "modaleConfermaSostituzioneMedicoRichiedente";
const idModaleOperazioneEseguita = "modaleSostituzioneMedicoichiedenteOperazioneEseguita";

export default class PopupConfermaSostituizioneMedicoRichiedente extends Component {
    state = {
        showModaleOperazioneEseguita: false
    }

    annullaSostituzioneMedicoichiedente = () => {
        closeModaleStyle();
        this.props.chiudiPopupSostituzioneMedicoichiedente();
    }

    showOperazioneEseguita = () => {
        this.setState({ showModaleOperazioneEseguita: true });
        this.props.updateRichiesta();
    }

    confermaSostituzioneMedicoichiedente = () => {
        let uuidRichiesta = this.props.uuidRichiesta;
        mostraSpinner();
        RichiestaADIService.sostituzioneMedicoRichiedenti(uuidRichiesta).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                this.annullaSostituzioneMedicoichiedente();
                if (response?.msgCode && response.msgCode.startsWith("WARNING_WD")) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "informational",
                        text: response?.msgCode
                    });
                } else if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                    });
                    nascondiSpinner();
                    return;
                }
            }
            nascondiSpinner();
            this.showOperazioneEseguita();
        });
    }

    bodyModale = <div>{bodyModaleText.top} <strong>{bodyModaleText.down}</strong></div>

    bodyModaleOperazioneEseguita = <strong>{bodyModaleOperazioneEseguitaText}</strong>

    footerModale = (
        <>
            <Button
                onClick={() => this.confermaSostituzioneMedicoichiedente()}
                id={"confermaBtn"}
                text="Conferma"
                className={"btn btn-adi btnOrangeOutline"}
            />
            <Button
                onClick={() => this.annullaSostituzioneMedicoichiedente()}
                id={"annullaBtn"}
                text="Annulla"
                className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                disabled={false}
            />
        </>
    );

    footerModaleOperazioneEseguita = (
        <Button
            onClick={() => this.annullaSostituzioneMedicoichiedente()}
            id={"annullaBtn"}
            text="Chiudi"
            className={"btn btn-adi btnWhiteOutlineBorderGreen"}
            disabled={false}
        />
    );

    getBackgroundStyle() {
        if (this.state.showModaleOperazioneEseguita) return "backgroundGreen";
        else return "backgroundOrange";
    }

    render() {
        const { showModaleOperazioneEseguita } = _.cloneDeep(this.state);
        const { bodyModaleOperazioneEseguita, footerModaleOperazioneEseguita, bodyModale, footerModale } = this;
        const getBody = () => showModaleOperazioneEseguita ? bodyModaleOperazioneEseguita : bodyModale
        const getFooter = () => showModaleOperazioneEseguita ? footerModaleOperazioneEseguita : footerModale;
        const getTitle = () => showModaleOperazioneEseguita ? titleModaleOperazioneEseguita : titleModale;
        const getId = () => showModaleOperazioneEseguita ? idModaleOperazioneEseguita : idModale;

        return (
            <ModalHelper
                id={getId()}
                title={getTitle()}
                body={getBody()}
                footer={getFooter()}
                titleClassName={("modal-header d-inline text-center ").concat(this.getBackgroundStyle())}
                containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                contentClassName={"modal-content"}
                footerClassName={"modal-footer justify-content-around"}
                bodyClassName={"modal-body d-flex align-items-center text-center mx-auto"}
            />
        )
    }
}

PopupConfermaSostituizioneMedicoRichiedente.propTypes = propTypes;
