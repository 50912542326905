import React, {Fragment} from "react";
import PropTypes from "prop-types";
import AccordionSecondLevel from "../../../accordionSezioneRichiesta/AccordionSecondLevel";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {bisogniPrestazioniAss} from "../../../../utils/SessionRequest";
import {
    AddSectionButton,
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedCheckbox
} from "../../../roleBasedComponents/RoleBasedComponents";
import {v4 as uuid} from "uuid";
import Utils, { closeModaleStyle } from "../../../../utils/Utils";
import styles from "../../CompilaProposte.module.css";
import ModalHelper from "../../../modale/ModalHelper";
import {Button, SessioneUtente} from "web-client-archetype";
import {Container, Table} from "reactstrap";
import {RoleBasedContext} from "../../../../utils/RoleBasedContext";

const propTypes = {
    dati: PropTypes.any,
    onShowTabellaBisogniPrestazioniAssistenziali: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    sezioneDatiRichiesta: PropTypes.bool
}

const bisogniPrestazioniAssistenziali = _.cloneDeep(new Map(Object.entries(bisogniPrestazioniAss)));

export default class BisogniPrestazioniAssistenzialiSottoAccordion extends AccordionSecondLevel {

    state = {
        showModaleBisogniPrestazioniAssistenziali: false,
        currentBisogniListCheckbox: _.cloneDeep(this.props.dati),
        currentBisogniList: _.cloneDeep(this.props.dati)
    }

    componentDidMount() {
        this.props.onChangeRequiredFieldsNumber(this.isRequiredFieldNotValued(this.state.currentBisogniList) ? 1 : 0);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati)) {
            this.props.onChangeRequiredFieldsNumber(this.isRequiredFieldNotValued(this.state.currentBisogniList) ? 1 : 0);
        }
    }

    recuperaBisogniSelezionati() {
        let bisogniSelezionati = [];
        for (const [key, bisogni] of bisogniPrestazioniAssistenziali.entries()) {
            let descrizioneList = [];
            for (let bisogno of bisogni) {
                if (this.state.currentBisogniList?.some(b => b.codice === bisogno.codice))
                    descrizioneList.push(bisogno.descrizione);
            }
            if (descrizioneList.length) {
                bisogniSelezionati.push(
                    <span style={{whiteSpace: "pre-line"}}>
                        <strong>{key}</strong><br/>{descrizioneList.join('\n')}
                    </span>
                );
            }
        }
        return bisogniSelezionati;
    }

    isRequiredFieldNotValued = (list) => {
        return (Utils.isStateRichiesta(this.props.pageState) || Utils.isStateChiusuraPreDialogo(this.props.pageState)) && list.length === 0;
    }

    renderNewBisognoPrestazioneAssistenziale = (bisogno, index) => {
        return <FieldsRow colNumber={1} key={index}><Fragment>{bisogno}</Fragment></FieldsRow>;
    }

    renderRows = (chiave, value, index, bisogni, bisogniPrestazioniSelezionati) => {
        return (<Fragment key={index}>
                <tr>
                    <th rowSpan={value.length + 1}>{chiave}</th>
                </tr>
                {value.length && value.map(elem => {
                    const bisogno = bisogniPrestazioniSelezionati?.find(el => el.codice === elem.codice);
                    const readOnly = Utils.isStateValutazione(this.props.pageState) && bisogno != null && (Object.prototype.hasOwnProperty.call(bisogno, "codiceFiscale") ? bisogno.codiceFiscale !== SessioneUtente.getInstance().idUtente : true);
                    return <tr key={elem.codice}>
                        <td>
                            <RoleBasedCheckbox
                                fieldId={"X_BISOGNI_PRESTAZ_ASSIST.BISOGNIPRESTAZIONALIASSISTCHEHANNODETLARICHIESTA.RICHIESTACAMBIOSETTING"}
                                pageState={this.props.pageState}
                                id={"checkbox" + elem.codice}
                                text={elem.descrizione}
                                styleLabel={{fontFamily: "Arial", fontSize: "12px"}}
                                field={"codice"}
                                checked={bisogniPrestazioniSelezionati?.some(el => el.codice === elem.codice)}
                                onChange={(field, value) => {
                                    let listaBisogniAggiornata = _.cloneDeep(this.state.currentBisogniListCheckbox);
                                    elem.checked = value.target.checked;
                                    if (value.target.checked) {
                                        listaBisogniAggiornata.push({
                                            id: uuid(),
                                            codice: elem.codice,
                                            codiceFiscale: SessioneUtente.getInstance().idUtente
                                        });
                                    } else {
                                        listaBisogniAggiornata = listaBisogniAggiornata.filter(el => el.codice !== elem.codice);
                                    }
                                    this.setState({currentBisogniListCheckbox: Utils.getDistinctListByField(listaBisogniAggiornata, "codice")});
                                }}
                                forceReadOnly={readOnly}
                                parentJsonPath={'bisogniPrestazioniAssistenziali[?(@.id == \'' + bisogno?.id + '\')]'}
                                showFieldIfRequiredAccordionPage={true}
                            />
                        </td>
                    </tr>
                })}
            </Fragment>
        );
    }

    renderModaleBisogniPrestazioniAssistenziali = () => {
        let bisogniPrestazioniSelezionati = _.cloneDeep(this.state.currentBisogniListCheckbox);

        const body = (
            <Container>
                <Table className={"table table-bordered"}>
                    <tbody>
                    {Array.from(bisogniPrestazioniAssistenziali, ([key, value], index) => this.renderRows(key, value, index, bisogniPrestazioniAssistenziali, bisogniPrestazioniSelezionati))}
                    </tbody>
                </Table>
            </Container>
        );

        const footer = (
            <>
                <div className={"col-3 text-left"}>
                    <Button
                        onClick={() => {
                            this.props.onChangeBisogniPrestazioniAssistenziali("bisogniPrestazioniAssistenziali", bisogniPrestazioniSelezionati);
                            this.setState({
                                currentBisogniListCheckbox: this.state.currentBisogniList,
                                showModaleBisogniPrestazioniAssistenziali: false
                            }, () => closeModaleStyle())
                        }}
                        id={"indietroBtn"}
                        text={"Indietro"}
                        className={styles.btnAnnullaStyle.concat(" btn btn-adi btnGreenOutline")}
                    />
                </div>
                <div className={"col-3 text-center"}>
                    <Button
                        onClick={() => this.setState({currentBisogniListCheckbox: this.state.currentBisogniList})}
                        id={"annullaBisogniAssistenzialiBtn"}
                        text={"Annulla"}
                        className={styles.btnAnnullaStyle.concat(" btn btn-adi btnWhiteOutlineBorderGreen")}
                        disabled={_.isEqual(this.state.currentBisogniList?.map(b => b.codice), bisogniPrestazioniSelezionati?.map(b => b.codice))}
                    />
                </div>
                <div className={"col-3 text-right"}>
                    <Button
                        onClick={() => {
                            this.props.onChangeBisogniPrestazioniAssistenziali("bisogniPrestazioniAssistenziali", bisogniPrestazioniSelezionati);
                            this.setState({
                                currentBisogniList: bisogniPrestazioniSelezionati,
                                currentBisogniListCheckbox: bisogniPrestazioniSelezionati,
                                showModaleBisogniPrestazioniAssistenziali: false
                            }, () => {
                                closeModaleStyle();
                                this.props.onChangeRequiredFieldsNumber(this.isRequiredFieldNotValued(this.state.currentBisogniList) ? 1 : 0)
                            });
                        }}
                        id={"salvaBisogniAssistenzialiBtn"}
                        text={"Salva"}
                        className={styles.btnAnnullaStyle.concat(" btn btn-adi btnWhiteOutlineBorderGreen")}
                        disabled={_.isEqual(this.state.currentBisogniList?.map(b => b.codice), bisogniPrestazioniSelezionati?.map(b => b.codice))}
                    />
                </div>
            </>
        );

      

        return (
            <ModalHelper
                id={"modaleFiltro"}
                title={ <span tag={"span"} className="modal-title w-100 font-weight-bold">Bisogni/Prestazioni assistenziali (ALLEGATO D alla Dgr n. 2372 del 29 dicembre 2011)</span>}
                titleClassName={"modal-header text-center"}
                containerClassName={"modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered"}
                contentClassName={("modal-content px-3 py-1 ")}
                body={body}
                footer={footer}
                footerClassName={"modal-footer justify-content-around"}
            />);
    }

    renderBody = () => {
        const bisogniSelezionati = this.recuperaBisogniSelezionati();
        const buttonVisibility = !this.context.forceReadOnly && isFieldIdWriteable("X_BISOGNI_PRESTAZ_ASSIST.BISOGNIPRESTAZIONALIASSISTCHEHANNODETLARICHIESTA.RICHIESTACAMBIOSETTING", this.props.pageState, this.context.forceUserRole);
        const testoBottone = bisogniSelezionati?.length > 0 ? "Modifica bisogni assistenziali" : "Aggiungi bisogno assistenziale";
        return (
            <>
                {this.state.showModaleBisogniPrestazioniAssistenziali && this.renderModaleBisogniPrestazioniAssistenziali()}
                {bisogniSelezionati.map((bisogno, index) => this.renderNewBisognoPrestazioneAssistenziale(bisogno, index))}
                {buttonVisibility && <AddSectionButton
                    text={testoBottone}
                    onClick={() => this.setState({showModaleBisogniPrestazioniAssistenziali: true})}/>}
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"bisogniPrestazioniAssistenzialiSub"}
                title={"Bisogni/prestazioni assistenziali"}
                required={this.isRequiredFieldNotValued(this.state.currentBisogniList)}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}

BisogniPrestazioniAssistenzialiSottoAccordion.propTypes = propTypes;
BisogniPrestazioniAssistenzialiSottoAccordion.contextType = RoleBasedContext;
