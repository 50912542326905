import React, {Component, Fragment} from "react";
import {Button, SessioneUtente} from "web-client-archetype";
import AnagrafePaziente, {isPazienteMinoreDi18Anni} from "../anagrafePaziente/AnagrafePaziente";
import PropTypes from "prop-types";
import RichiestaADIService from "../../service/RichiestaADIService";
import Utils from "../../utils/Utils";
import * as _ from "lodash";
import SituazioneSociale from "../situazioneSociale/SituazioneSociale";
import DeterminantiDiSalute from "../determinantiDiSalute/DeterminantiDiSalute";
import AnamnesiFisiologica from "../anamnesiFisiologica/AnamnesiFisiologica";
import InformazioniDiBase from "../infoBase/InformazioniDiBase";
import AnagrafeService from "../../service/AnagrafeService";
import {map} from "rxjs/operators";
import {forkJoin, of} from "rxjs";
import AnamnesiPatologicaProssima from "../anamnesiPatologicaProssima/AnamnesiPatologicaProssima";
import AnamnesiPatologicaRemota from "../anamnesiPatologicaRemota/AnamnesiPatologicaRemota";
import AccordionHelper from "../../service/AccordionHelper";
import AnamnesiFamiliare from "../anamnesiFamiliare/AnamnesiFamiliare";
import enumIndirizzo from "../../enum/enumIndirizzo.json";
import enumContatto from "../../enum/enumContatto.json";
import enumsUtente from "../../enum/enumsUtente";
import BenessereAbitativo from "../benessereAbitativo/BenessereAbitativo";
import SessionRequest, {defaultAnagrafePaziente} from "../../utils/SessionRequest";
import $ from "jquery";
import routepath from "../../utils/route/route-path.json";
import {Redirect} from "react-router-dom";
import enumPaginaCompila from "../../enum/enumPaginaCompila.json";
import InformazioniBaseFaseValutazione from "../informazioniBaseFaseValutazione/InformazioniBaseFaseValutazione";
import EsameObiettivo from "../esameObiettivo/EsameObiettivo";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import {mostraSpinner, nascondiSpinner} from "../../App";
import ButtonHelper from "../../service/ButtonHelper";
import enumUtente from "../../enum/enumsUtente.json";
import ParametriVitali from "../parametriVitali/ParametriVitali";
import InserimentoRichiesta from "../inserimentoRichiesta/InserimentoRichiesta";
import {getCountMandatoryFieldsMissing} from "../anagrafeRichiedente/AnagrafeRichiedente";
import ValutazioneBisogniPalliativi from "../valutazioneBisogniPalliativi/ValutazioneBisogniPalliativi"
import VersionedAccordion from "../VersionedAccordion";
import {
    FieldsGroup,
    isFieldIdWriteable,
    isSomeFieldIdWriteable,
    RoleBasedInput
} from "../roleBasedComponents/RoleBasedComponents";
import ScrollTop from "../scrollTop/ScrollTop";
import enumRichiesta from "../../enum/enumRichiesta";
import {buildRichiesta, disableButton} from "../compilaRichiesta/CompilaRichiesta";
import errors from "../../utils/errors/errors.json";
import ModalHelper from "../modale/ModalHelper";
import enumTipoAvvisoSettingModale from "../../enum/enumTipoAvvisoSettingModale.json";
import AuthUtils from "../../utils/AuthUtils";
import ButtonsBoxStick from "../buttonsBoxStick/buttonsBoxStick";
import UserHelper from "../../service/userHelper";

const propTypes = {
    richiesta: PropTypes.object,
    richiestaMedico: PropTypes.object,
    persone: PropTypes.any,
    pageState: PropTypes.string,
    pageName: PropTypes.string,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    firstPageName: PropTypes.string,
    pageNumber: PropTypes.number,
    openAccordion: PropTypes.bool,
    listaCodiciFiscaliUtentiOnline: PropTypes.array
}

export const getChiudiPredialogoBodyForBe = (richiestaRight, richiestaLeft, esitoValutazione, self) => {
    let chiusuraPreDialogo = {};
    const regexTime = /timestamp(\w*|\W*)":"(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})[Z]*/g;

    if (!Utils.isStringEmptyOrNullOrUndefined(richiestaLeft?.formalizzazione?.dataValutazione)) {
        richiestaLeft.formalizzazione.dataValutazione = Utils.transformDateForBackend(richiestaLeft.formalizzazione.dataValutazione, "YYYY-MM-DD");
    }

    if (!Utils.isStringEmptyOrNullOrUndefined(richiestaRight?.formalizzazione?.dataValutazione)) {
        richiestaRight.formalizzazione.dataValutazione = Utils.transformDateForBackend(richiestaRight.formalizzazione.dataValutazione, "YYYY-MM-DD");
    }
    richiestaRight.formalizzazione.esitoValutazione = self.state.esitoValutazione ?? esitoValutazione;
    richiestaRight.formalizzazione.motivazione = self.state.motivazione;

    chiusuraPreDialogo.richiestaLeft = JSON.stringify(richiestaLeft).replace(regexTime, g1 => g1.endsWith('Z') ? g1 : g1 + 'Z');
    chiusuraPreDialogo.richiestaRight = JSON.stringify(richiestaRight).replace(regexTime, g1 => g1.endsWith('Z') ? g1 : g1 + 'Z');
    chiusuraPreDialogo.esitoValutazione = self.state.esitoValutazione ?? esitoValutazione;
    chiusuraPreDialogo.motivazione = self.state.motivazione;

    return chiusuraPreDialogo
}

export default class SchedaPazienteChiudiPreDialogo extends Component {

    state = {
        redirectToCompilaProposte: false,
        redirectToAnagrafeRichiedente: false,
        salvaBozzaSubscription: null,
        accordion: {
            openAccordionAnagrafePaziente: false,
            openAccordionSituazioneSociale: false,
            openAccordionBenessereAbitativo: false,
            openAccordionDeterminantiSalute: false,
            openAccordionDieta: false,
            openAccordionAnamnesiFisiologica: false,
            openAccordionGestioneDellaSalute: false,
            openAccordionSonnoRiposo: false,
            openAccordionCapacitaCuraDiSe: false,
            openAccordionIdratazioneInfermiere: false,
            openAccordionAssorbimento: false,
            openAccordionComunicazioneSensorio: false,
            openAccordionInformazioniBaseFaseValutazione: false,
            openAccordionInformazioniBaseFaseRichiesta: false,
            openAccordionInfoBase: false,
            openAccordionAnamnesiPatologicaRemota: false,
            openAccordionAnamnesiPatologicaProssima: false,
            openAccordionAnamnesiFamiliare: false,
            openAccordionEsameObiettivo: false,
            openAccordionIngestione: false,
            openAccordionFunzioneUrinaria: false,
            openAccordionFunzioneTegumentaria: false,
            openAccordionFunzioneRespiratoria: false,
            openAccordionFunzioneGastrointestinale: false,
            openAccordionCopingTolleranzaStressInfermiere: false,
            openAccordionParametriVitali: false,
            openAccordionValutazioneBisogniPalliativi: false,
        },
        // bool per modale successo
        isSettingRifiutato: false,
        // variabile di stato per la visualizzazione della modale
        tipoModaleVisualizzata: null,
        // motivazione rifiuto setting
        motivazione: null,

        esitoValutazione: null,

        gotoHome: false
    }

    accordionData;
    datiA0;

    // oggetto utilizzato per controllare se abilitare o disabilitare il pulsante Prosegui
    obbligatorieta = {
        anagrafePaziente: null,
        situazioneSociale: null,
        anamnesiFisiologica: null,
        informazioniDiBase: null,
        informazioniBaseFaseRichiesta: null,
        informazioniBaseFaseValutazione: null,
        anamnesiPatologicaProssima: null,
        funzioneUrinaria: null
    };

    // oggetto utilizzato per controllare che al click del bottone Prosegui si può accedere alla pagina successiva
    validitaPagina = {
        anagrafePaziente: false,
        situazioneSociale: false
    };

    constructor(props) {
        super(props);

        this.accordionData = SessionRequest.getSessionObjectFor(props.richiesta);
        this.updateFields();
        this.datiA0 = _.cloneDeep({
            ...this.accordionData.anagrafePaziente,
            aulssAssistenza: this.props.richiesta.aulss,
            distrettoAssistenza: this.props.richiesta.distretto,
            provinciaAssistenza: this.props.richiesta.provincia
        });
    }

    componentDidMount() {
        this.countMandatoryFieldsMissing();

        this.setState({
            salvaBozzaSubscription: InserimentoRichiesta.salvaBozzaSubject.subscribe(() => this.effettuaSalvataggio(false, enumRichiesta.stato.IN_CHIUSURA_PRE_DIALOGO))
        }, () => {
            InserimentoRichiesta.infoRichiestaSubject.next({
                idRichiesta: this.props.richiesta.id,
                anagrafePaziente: this.accordionData?.anagrafePaziente ?? null,
                situazioneSociale: this.accordionData?.situazioneSociale ?? null,
                richiedenteOrMcp: this.props.persone?.[Utils.recuperoIdProfessionista(this.props.richiesta)]
            });
        });

        if (this.props.openAccordion) {
            let accordion = _.cloneDeep(this.state.accordion);
            for (const singoloAccordion in accordion) {
                accordion[singoloAccordion] = true;
            }
            this.setState({accordion: accordion});
        } else {
            this.setState({accordion: AccordionHelper.initLastOpenedAccordionInAccordionObject(this.state.accordion, AccordionHelper.lastOpenedAccordion, this.props.openAccordion)});
        }
    }

    countMandatoryFieldsMissing = () => {
        if (this.props.pageName === enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE) {
            const richiedente = this.props.persone?.[Utils.recuperoIdProfessionista(this.props.richiesta)];
            let missingFieldsCount = 0;
            if (richiedente?.identificativoUtente === SessioneUtente.getInstance().idUtente) {
                missingFieldsCount = getCountMandatoryFieldsMissing(richiedente, this.props.pageState);
            }
            this.setState({redirectToAnagrafeRichiedente: missingFieldsCount > 0});
        }
    }

    componentWillUnmount() {
        if (this.state.salvaBozzaSubscription) this.state.salvaBozzaSubscription.unsubscribe();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.hideUnsetValues) {

            $('div.box-accordion-body:empty').closest('div.box-accordion').remove();

            //Duplicato perchè prima vengono eliminati i sottoAccordion vuoti e poi i relativi Accordion vuoti
            $('div.collapse-body:empty').closest('div.collapse-div').remove();
            $('div.collapse-body:empty').closest('div.collapse-div').remove();
        }

        if (!_.isEqual(prevProps.richiesta, this.props.richiesta)) {
            this.updateFields();
        }
    }

    updateFields() {
        let richiesta = this.props.richiesta;

        if (richiesta) {
            this.initObjectsForm(this.accordionData.anagrafePaziente, "anagrafePaziente", richiesta, defaultAnagrafePaziente);
            this.initObjectsForm(this.accordionData.situazioneSociale, "situazioneSociale", richiesta, this.accordionData.situazioneSociale);
            this.initObjectsForm(this.accordionData.determinantiDiSalute, "determinantiDiSalute", richiesta, this.accordionData.determinantiDiSalute);
            this.initObjectsForm(this.accordionData.anamnesiFisiologica, "anamnesiFisiologica", richiesta, this.accordionData.anamnesiFisiologica);
            this.initObjectsForm(this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione], enumRichiesta.attributi.informazioniBaseFaseValutazione, richiesta, this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]);
            this.initObjectsForm(this.accordionData.informazioniDiBase, "informazioniDiBase", richiesta, this.accordionData.informazioniDiBase);
            this.initObjectsForm(this.accordionData.datiAnamnesiFamiliare, "anamnesiFamiliare", richiesta, this.accordionData.datiAnamnesiFamiliare);
            this.initObjectsForm(this.accordionData.anamnesiPatologicaProssima, "anamnesiPatologicaProssima", richiesta, this.accordionData.anamnesiPatologicaProssima);
            this.initObjectsForm(this.accordionData.anamnesiPatologicaRemota, "anamnesiPatologicaRemota", richiesta, this.accordionData.anamnesiPatologicaRemota);
            this.initObjectsForm(this.accordionData.benessereAbitativo, "benessereAbitativo", richiesta, this.accordionData.benessereAbitativo);
            this.initObjectsForm(this.accordionData.esameObiettivo, "esameObiettivo", richiesta, this.accordionData.esameObiettivo);
            this.initObjectsForm(this.accordionData.parametriVitali, "parametriVitali", richiesta, this.accordionData.parametriVitali);
            this.initObjectsForm(this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta], enumRichiesta.attributi.informazioniBaseFaseRichiesta, richiesta, this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]);
            this.initObjectsForm(this.accordionData.valutazioneBisogniPalliativi, "valutazioneBisogniPalliativi", richiesta, this.accordionData.valutazioneBisogniPalliativi);
        }
    }

    initObjectsForm = (object, property, richiesta, defaultObject) => {
        if (richiesta.hasOwnProperty(property)) {
            Object.keys(defaultObject).forEach(key => {
                if (key in richiesta[property]) {
                    object[key] = richiesta[property][key];
                } else {
                    object[key] = defaultObject[key];
                }
            });
        }
    }

    handleChangeDatiAnagrafePaziente = (field, value) => {
        let fieldSplit = field.split(",");
        if (fieldSplit.length === 1) {
            this.accordionData.anagrafePaziente[field] = value;
        } else if (fieldSplit.length > 1) {
            fieldSplit.forEach(fieldName => {
                this.accordionData.anagrafePaziente[fieldName] = value[fieldName];
            });
        }
    }

    handleChangeDatiSituazioneSocialeForm = (field, value) => {
        this.accordionData.situazioneSociale[field] = value;
    }

    handleChangeDatiParametriVitaliForm = (field, value) => {
        this.accordionData.parametriVitali[field] = value;
    }

    handleChangeDeterminantiDiSaluteForm = (field, value) => {
        this.accordionData.determinantiDiSalute[field] = value;
    }

    handleChangeAnamnesiFisiologicaForm = (field, value) => {
        this.accordionData.anamnesiFisiologica[field] = value;
    }

    handleChangeInformazioniBaseFaseValutazioneForm = (field, value) => {
        this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione][field] = value;
    }

    handleChangeInformazioniDiBaseForm = (field, value) => {
        this.accordionData.informazioniDiBase[field] = value;
    }

    handleChangeAnamnesiPatologicaRemota = (value) => {
        this.accordionData.anamnesiPatologicaRemota = value;
    }

    handleChangeBenessereAbitativo = (field, value) => {
        this.accordionData.benessereAbitativo[field] = value;
    }

    handleChangeAnamnesiPatologicaProssima = (value) => {
        this.accordionData.anamnesiPatologicaProssima = value;
    }

    handleChangeAnamnesiFamiliare = (field, value) => {
        this.accordionData.datiAnamnesiFamiliare[field] = value;
    }

    handleChangeEsameObiettivo = (field, value) => {
        this.accordionData.esameObiettivo[field] = value;
    }

    handleChangeValutazioneBisogniPalliativi = (field, value) => {
        this.accordionData.valutazioneBisogniPalliativi[field] = value;
    }

    handleChangeObbligatorieta = (field, value) => {
        this.obbligatorieta[field] = value;

        let obbligatorietaClone = _.cloneDeep(this.obbligatorieta);
        if (Utils.isStateRichiesta(this.props.pageState)) {
            Object.keys(obbligatorietaClone?.informazioniDiBase ?? {}).forEach(key => {
                if (key !== "sottosezioneTerapiaFarmacologicaInAtto") delete obbligatorietaClone.informazioniDiBase[key];
            });
        }
        let isButtonProseguiEnabled = obbligatorietaClone && Object.keys(obbligatorietaClone).every(key => !obbligatorietaClone[key]);
        AccordionHelper.abilitazioneProseguiSubject.next(isButtonProseguiEnabled);
        disableButton("#proseguiBtn", !isButtonProseguiEnabled);
    }

    handleValiditaPagina = (field, value) => {
        this.validitaPagina[field] = value;
    }

    chiudiPreDialogo = (richiestaBody, isProcediBtn = false, esitoValutazione = this.state.esitoValutazione) => {
        const chiudiPreDialogoDto = getChiudiPredialogoBodyForBe(richiestaBody, RichiestaADIService.richiestaRicercata, esitoValutazione, this);

        if (isProcediBtn) mostraSpinner();

        return RichiestaADIService.chiudiPreDialogo(richiestaBody.id, chiudiPreDialogoDto, ButtonHelper.clickSalvaButton)
            .pipe(map((response) => {
                if (isProcediBtn) nascondiSpinner();

                if (response?.msg === "ERRORE") {
                    if (response?.msgCode && response.msgCode.startsWith("WARNING_WD")) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ?? response?.status,
                            type: "confirm",
                            text: errors[response?.msgCode]?.message || response?.msgCode
                        });
                        //l'operazione è stata comunque eseguita, quindi proseguo come per un ok
                    } else if (response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_NON_INVIATO")) {
                        //non deve più farlo ma bisogna comunque gestire il caso
                        //this.setState({ pdfBtnDisabled: true });
                    } else if (response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_IN_ERRORE")) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ?? response?.status,
                            type: "critical",
                            text: "Errore nella creazione del CDA2"
                        });
                    } else if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ?? response?.status,
                            type: "critical",
                            text: response?.data?.errore ?? response?.data?.message
                        });
                    }
                }

                // restituisco richiestaBody (richiesta aggiornata) e non l'oggetto restituito dal salvaVariazioni (response.data)
                // perchè l'operation di salvaVariazioni sul backend aggiorna soltanto la lista delle variazioni della valutazione della richiesta originaria
                // senza aggiornare le properties della richiesta originaria
                // (che vengono ricostruite dall'operation ricercaRichiesta, invocata solo al cambio di una delle tre pagine degli accordion)
                return richiestaBody;
            }));
    }

    creaPersona = (personaBody) => {
        if (!Utils.isObjectNull(personaBody)) {
            return AnagrafeService.insertPersona(personaBody).pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                    return of(null)
                } else {
                    return response;
                }
            }));
        } else
            return of(null);
    }

    modificaPersona = (idPersona, personaBody) => {
        if (!Utils.isStringEmptyOrNullOrUndefined(idPersona) && !Utils.isObjectNull(personaBody)) {
            return AnagrafeService.modificaPersona(idPersona, personaBody).pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                    return of(null)
                } else {
                    return response;
                }
            }));
        } else
            return of(null);
    }

    cancellaPersona = (uuidPersona) => {
        if (!Utils.isStringEmptyOrNullOrUndefined(uuidPersona)) {
            AnagrafeService.cancellaPersona(uuidPersona).pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                    return of(null)
                } else {
                    return response;
                }
            })).subscribe((response) => {
                if (response) this.prepareToSalvataggio();
            });
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            });
            this.effettuaSalvataggio(false, enumRichiesta.stato.IN_CHIUSURA_PRE_DIALOGO);

            AccordionHelper.setLastOpenedAccordion(field);
            this.setState({accordion});
        }
    }

    effettuaSalvataggio = (isProcediBtn = false, esitoValutazione = this.state.esitoValutazione) => {
        if (this.props.readOnly) {
            if (isProcediBtn) {
                this.setState({redirectToCompilaProposte: true});
            }
            return;
        }

        let lastOpenedAccordion = AccordionHelper.lastOpenedAccordion;
        if (!Utils.isStringEmptyOrNullOrUndefined(lastOpenedAccordion) && lastOpenedAccordion === "openAccordionAnagrafePaziente") {
            let anagrafePaziente = _.cloneDeep(this.accordionData.anagrafePaziente);
            let obsList = [];

            let assistito = this.convertObjectToPersonaDto(this.props.richiesta.idAssistito, anagrafePaziente.datiGenerali);
            assistito.indirizzi = [];
            this.setRecapito(assistito.indirizzi, anagrafePaziente.residenza, enumIndirizzo.indirizzo.residenza);
            this.setRecapito(assistito.indirizzi, anagrafePaziente.domicilio, enumIndirizzo.indirizzo.domicilio);
            this.setRecapito(assistito.indirizzi, anagrafePaziente.domicilioDiCura, enumIndirizzo.indirizzo.altro);
            assistito.contatti = [];
            this.setContatto(assistito.contatti, anagrafePaziente.datiGenerali, enumContatto.contatto.telefono, "telefono");
            this.setContatto(assistito.contatti, anagrafePaziente.datiGenerali, enumContatto.contatto.cellulare, "cellulare");
            this.setContatto(assistito.contatti, anagrafePaziente.datiGenerali, enumContatto.contatto.email, "email");
            assistito.esenzioni = anagrafePaziente.esenzioni?.filter(esenzione => Object.keys(esenzione).some(k => k !== "idElemento" && !Utils.isObjectNull(esenzione[k])));
            let mascheraModificabilitaAssistito = anagrafePaziente.datiGenerali?.mascheraModificabilita;
            assistito.mascheraModificabilita = mascheraModificabilitaAssistito !== undefined ? mascheraModificabilitaAssistito : null;
            obsList.push(this.modificaPersona(assistito.id, assistito).pipe(map(paziente => paziente)));

            if (anagrafePaziente.hasOwnProperty("mmgPlsDelPaziente")
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.mmgPlsDelPaziente.cognome)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.mmgPlsDelPaziente.nome)) {
                let mmgPlsDelPaziente = this.convertObjectToPersonaDto(anagrafePaziente.mmgPlsDelPaziente.id, anagrafePaziente.mmgPlsDelPaziente);
                mmgPlsDelPaziente.contatti = [];
                this.setContatto(mmgPlsDelPaziente.contatti, anagrafePaziente.mmgPlsDelPaziente, enumContatto.contatto.telefono, "telefono");
                this.setContatto(mmgPlsDelPaziente.contatti, anagrafePaziente.mmgPlsDelPaziente, enumContatto.contatto.cellulare, "cellulare");
                this.setContatto(mmgPlsDelPaziente.contatti, anagrafePaziente.mmgPlsDelPaziente, enumContatto.contatto.email, "email");
                if (mmgPlsDelPaziente?.id) {
                    obsList.push(this.modificaPersona(mmgPlsDelPaziente.id, mmgPlsDelPaziente).pipe(map(mmgPlsPaziente => mmgPlsPaziente)));
                } else {
                    const observableMmgPlsPaziente = this.creaPersona(mmgPlsDelPaziente).pipe(map((response) => {
                        if (response)
                            anagrafePaziente.mmgPlsDelPaziente.id = response.id;
                        return response;
                    }));
                    obsList.push(observableMmgPlsPaziente);
                }
            }

            if (anagrafePaziente.hasOwnProperty("padre")
                && isPazienteMinoreDi18Anni(anagrafePaziente.datiGenerali.dataNascita)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.padre.cognome)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.padre.nome)) {

                let padre = this.convertObjectToPersonaDto(anagrafePaziente.padre.id, anagrafePaziente.padre);
                padre.indirizzi = [];
                if (!Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.padre.indirizzo))
                    this.setRecapito(padre.indirizzi, anagrafePaziente.padre, enumIndirizzo.indirizzo.domicilio);
                padre.contatti = [];
                this.setContatto(padre.contatti, anagrafePaziente.padre, enumContatto.contatto.telefono, "telefono");
                this.setContatto(padre.contatti, anagrafePaziente.padre, enumContatto.contatto.email, "email");
                if (padre?.id) {
                    obsList.push(this.modificaPersona(padre.id, padre).pipe(map(padre => padre)));
                } else {
                    const observablePadre = this.creaPersona(padre).pipe(map((response) => {
                        if (response)
                            anagrafePaziente.padre.id = response.id;
                        return response;
                    }));
                    obsList.push(observablePadre);
                }
            }

            if (anagrafePaziente.hasOwnProperty("madre")
                && isPazienteMinoreDi18Anni(anagrafePaziente.datiGenerali.dataNascita)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.madre.cognome)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.madre.nome)) {

                let madre = this.convertObjectToPersonaDto(anagrafePaziente.madre.id, anagrafePaziente.madre);
                madre.indirizzi = [];
                if (!Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.madre.indirizzo))
                    this.setRecapito(madre.indirizzi, anagrafePaziente.madre, enumIndirizzo.indirizzo.domicilio);
                madre.contatti = [];
                this.setContatto(madre.contatti, anagrafePaziente.madre, enumContatto.contatto.telefono, "telefono");
                this.setContatto(madre.contatti, anagrafePaziente.madre, enumContatto.contatto.email, "email");
                if (madre?.id) {
                    obsList.push(this.modificaPersona(madre.id, madre).pipe(map(madre => madre)));
                } else {
                    const observableMadre = this.creaPersona(madre).pipe(map((response) => {
                        if (response)
                            anagrafePaziente.madre.id = response.id;
                        return response;
                    }));
                    obsList.push(observableMadre);
                }
            }

            if (anagrafePaziente.hasOwnProperty("tutore")) {
                anagrafePaziente.tutore.forEach(tutore => {
                    if (!Utils.isStringEmptyOrNullOrUndefined(tutore.cognome)
                        && !Utils.isStringEmptyOrNullOrUndefined(tutore.nome)) {

                        let indexTutore = anagrafePaziente.tutore.indexOf(tutore);
                        let personaTutore = this.convertObjectToPersonaDto(tutore.id, tutore);
                        personaTutore.indirizzi = [];
                        if (!Utils.isStringEmptyOrNullOrUndefined(tutore.indirizzo))
                            this.setRecapito(personaTutore.indirizzi, tutore, enumIndirizzo.indirizzo.domicilio);
                        personaTutore.contatti = [];
                        this.setContatto(personaTutore.contatti, tutore, enumContatto.contatto.telefono, "telefono");
                        this.setContatto(personaTutore.contatti, tutore, enumContatto.contatto.email, "email");
                        if (personaTutore?.id) {
                            obsList.push(this.modificaPersona(personaTutore.id, personaTutore).pipe(map(tutore => tutore)));
                        } else {
                            const observableTutori = this.creaPersona(personaTutore).pipe(map((response) => {
                                if (response)
                                    anagrafePaziente.tutore[indexTutore].id = response.id;
                                return response;
                            }));
                            obsList.push(observableTutori);
                        }
                    }
                });
            }

            if (anagrafePaziente.hasOwnProperty("amministratoreDiSostegno")) {
                anagrafePaziente.amministratoreDiSostegno.forEach(amministratoreDiSostegno => {
                    if (!Utils.isStringEmptyOrNullOrUndefined(amministratoreDiSostegno.cognome)
                        && !Utils.isStringEmptyOrNullOrUndefined(amministratoreDiSostegno.nome)) {

                        let indexAmministratoreDiSostegno = anagrafePaziente.amministratoreDiSostegno.indexOf(amministratoreDiSostegno);
                        let personaAmministratoreDiSostegno = this.convertObjectToPersonaDto(amministratoreDiSostegno.id, amministratoreDiSostegno);
                        personaAmministratoreDiSostegno.indirizzi = [];
                        if (!Utils.isStringEmptyOrNullOrUndefined(amministratoreDiSostegno.indirizzo))
                            this.setRecapito(personaAmministratoreDiSostegno.indirizzi, amministratoreDiSostegno, enumIndirizzo.indirizzo.domicilio);
                        personaAmministratoreDiSostegno.contatti = [];
                        this.setContatto(personaAmministratoreDiSostegno.contatti, amministratoreDiSostegno, enumContatto.contatto.telefono, "telefono");
                        this.setContatto(personaAmministratoreDiSostegno.contatti, amministratoreDiSostegno, enumContatto.contatto.email, "email");
                        if (personaAmministratoreDiSostegno?.id) {
                            const observableAmministratoriDiSostegno = this.modificaPersona(personaAmministratoreDiSostegno.id, personaAmministratoreDiSostegno)
                                .pipe(map(amministratoreDiSostegno => amministratoreDiSostegno));
                            obsList.push(observableAmministratoriDiSostegno);
                        } else {
                            const observableAmministratoriDiSostegno = this.creaPersona(personaAmministratoreDiSostegno).pipe(map((response) => {
                                if (response)
                                    anagrafePaziente.amministratoreDiSostegno[indexAmministratoreDiSostegno].id = response.id;
                                return response;
                            }));
                            obsList.push(observableAmministratoriDiSostegno);
                        }
                    }
                });
            }

            if (anagrafePaziente.hasOwnProperty("fiduciario")) {
                // Eliminato controllo sulla presenza di cognome & nome - sul db è stato tolto il vincolo del not null su entrambe
                let fiduciario = this.convertObjectToPersonaDto(anagrafePaziente.fiduciario.id, anagrafePaziente.fiduciario);
                fiduciario.indirizzi = [];
                if (!Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.fiduciario.indirizzo))
                    this.setRecapito(fiduciario.indirizzi, anagrafePaziente.fiduciario, enumIndirizzo.indirizzo.domicilio);
                fiduciario.contatti = [];
                this.setContatto(fiduciario.contatti, anagrafePaziente.fiduciario, enumContatto.contatto.telefono, "telefono");
                this.setContatto(fiduciario.contatti, anagrafePaziente.fiduciario, enumContatto.contatto.email, "email");
                if (fiduciario?.id) {
                    obsList.push(this.modificaPersona(fiduciario.id, fiduciario).pipe(map(fiduciario => fiduciario)));
                } else {
                    const observableFiduciario = this.creaPersona(fiduciario).pipe(map((response) => {
                        if (response)
                            anagrafePaziente.fiduciario.id = response.id;
                        return response;
                    }));
                    obsList.push(observableFiduciario);
                }
            }

            forkJoin(obsList)
                .subscribe((response) => {
                    this.accordionData.anagrafePaziente = anagrafePaziente;
                    response.forEach(r => {
                        if (r != null) this.props.persone[r.id] = r;
                    });
                    this.prepareToSalvataggio(isProcediBtn, false, esitoValutazione);
                });
        } else {
            this.prepareToSalvataggio(isProcediBtn, false, esitoValutazione);
        }
    }

    convertObjectToPersonaDto = (idPersona, object) => {
        let persona = {
            ..._.cloneDeep(this.props.persone?.[idPersona]),
            nome: object.nome,
            cognome: object.cognome,
            identificativoUtente: object.identificativoUtente,
            tipoIdentificativoUtente: object.tipoIdentificativoUtente,
            dataNascita: object.dataNascita ? object.dataNascita : null,
            sesso: object.sesso ? object.sesso : null,
            comune: object.comune ? object.comune : null,
            provincia: object.provincia ? object.provincia : null,
            nazione: object.nazione ? object.nazione : null,
            cittadinanza: object.cittadinanza ? object.cittadinanza : null,
            tesseraSanitaria: object.tesseraSanitaria ? object.tesseraSanitaria : null,
            titoloStudio: object.titoloStudio ? object.titoloStudio : null,
            codiceRegionale: object.codiceRegionale ? object.codiceRegionale : null,
            esenzioni: []
        };
        persona.lingue = [];
        this.setLingue(persona.lingue, object);

        return persona
    }

    setLingue = (lingue, object) => {
        if (object.hasOwnProperty("madrelingua") && object.hasOwnProperty("livello")
            && object.hasOwnProperty("descrizione") && object.madrelingua !== null) {
            let lingua = {
                idElemento: object?.idLingue?.[0] ?? null,
                descrizione: null,
                madrelingua: object.madrelingua,
                livello: object.livello
            };

            if (object.madrelingua || !Utils.isStringEmptyOrNullOrUndefined(object.livello) || object.descrizione.length > 0) {
                if (object.descrizione.length > 0 && object.descrizione.some(d => d !== null)) {
                    let descrizioniDistinct = Array.from(new Set(object.descrizione)).filter(el => el !== null);
                    descrizioniDistinct.forEach((linguaParlata, index) => {
                        lingue.push({
                            ..._.cloneDeep(lingua),
                            idElemento: object?.idLingue?.[index] ?? null,
                            descrizione: linguaParlata
                        });
                    });
                } else lingue.push(_.cloneDeep(lingua));
            } else lingue.push(_.cloneDeep(lingua));
        }
    }

    setRecapito = (indirizzi, recapito, tipoRecapito) => {
        if (!Utils.isObjectNull(recapito)) {
            let indirizzo = {};
            indirizzo.tipo = tipoRecapito;
            indirizzo.indirizzo = recapito?.indirizzo ?? null;
            indirizzo.numero = recapito?.numero ?? null;
            indirizzo.cap = recapito?.cap ?? null;
            indirizzo.comune = recapito?.comune ?? null;
            indirizzo.provincia = recapito?.provincia ?? null;
            indirizzo.nazione = recapito?.nazione ?? null;
            indirizzo.aulss = recapito?.aulss ?? null;
            indirizzo.distretto = recapito?.distretto ?? null;
            indirizzo.idElemento = recapito.hasOwnProperty("idElementoIndirizzo") ? recapito.idElementoIndirizzo : recapito.idElemento ? recapito.idElemento : null;
            indirizzi.push(indirizzo);
        }
    }

    setContatto = (contatti, object, tipoContatto, field) => {
        if (!Utils.isStringEmptyOrNullOrUndefined(object[field])) {
            contatti.push({
                tipo: tipoContatto,
                valore: object[field],
                idElemento: object["idElemento".concat(field.charAt(0).toUpperCase()).concat(field.slice(1))]
            });
        }
    }

    prepareToSalvataggio = (isProcediBtn = false, returnObservable = false, esitoValutazione = this.state.esitoValutazione) => {
        if (this.props.readOnly) return;

        let richiesta = _.cloneDeep(this.props.richiesta);
        buildRichiesta(richiesta, this, this.props.pageName);
        let salva = this.chiudiPreDialogo(richiesta, isProcediBtn, esitoValutazione);
        if (isProcediBtn) {
            salva.subscribe(() => this.setState({redirectToCompilaProposte: true}));
        } else if (returnObservable) {
            return salva;
        } else {
            salva.subscribe();
        }
    }

    controlloProfilo = () => {
        const isUtenteCentraleAdi = _.some(this.state?.richiesta?.team, t => SessioneUtente.getInstance().idUtente === t.professionista?.codiceFiscale && t.profilo === enumsUtente.profiliUtente.centraleAdi);
    }


    renderButtons() {
        let btnList = [
            {
                id:"rifiutaSettingPropostoBtn",
                text:"Rifiuta setting proposto",
                text: this.controlloProfilo() ? 'Rifiuta setting proposto' : 'Rifiuta setting proposto e firma',
                className:"btnWhiteOutlineBorderGreen",
                onclickFunction:() => this.setState({tipoModaleVisualizzata: enumTipoAvvisoSettingModale.tipoAvvisoSettingModale.RIFIUTA_SETTING_PROPOSTO}),
                isvisible:!this.props.readOnly
            },
            {
                id:"salvaBozzaBtn",
                text:"Salva bozza",
                className:"btnWhiteOutlineBorderGreen",
                onclickFunction:() => {
                    this.setState({esitoValutazione: enumRichiesta.stato.IN_CHIUSURA_PRE_DIALOGO}, () => {
                        ButtonHelper.setSalvaButtonFlag(true);
                        this.effettuaSalvataggio(false, this.state.esitoValutazione);
                        InserimentoRichiesta.infoRichiestaSubject.next({
                            idRichiesta: this.props.richiesta.id,
                            anagrafePaziente: this.accordionData?.anagrafePaziente ?? null,
                            situazioneSociale: this.accordionData?.situazioneSociale ?? null,
                            richiedenteOrMcp: this.props.persone?.[Utils.recuperoIdProfessionista(this.props.richiesta)]
                        });
                    });
                },
                isvisible:!this.props.readOnly
            },
            {
                id:"proseguiBtn",
                text:"Prosegui",
                className:"btnGreenOutline",
                onclickFunction:() => {
                    let accordionValidatorKeys = _.cloneDeep(Object.keys(this.validitaPagina));
                    const numberOfValidAccordions = accordionValidatorKeys.filter(k => {
                        const validator = this.validitaPagina[k];
                        let validation;
                        let subAccordionValidatorKeys = Object.keys(validator);
                        if (subAccordionValidatorKeys.length) validation = subAccordionValidatorKeys.every(k2 => validator[k2] === true);
                        else validation = validator === true;
                        return validation;
                    }).length;
    
                    if (numberOfValidAccordions === accordionValidatorKeys.length) this.effettuaSalvataggio(true, enumRichiesta.stato.IN_CHIUSURA_PRE_DIALOGO);
                    else Utils.toasterFunction(Utils.getAlertMessageInvalidPage(accordionValidatorKeys, this.validitaPagina));
                },
                isvisible:true,
                aling:"right"
            }
        ]

        return (
            <ButtonsBoxStick
                parentClass={!this.props.readOnly ? "d-flex justify-content-between" : "row"}
                btnList={btnList}
            />
        );
    }

    renderAccordions() {
        return (
            <>
                <VersionedAccordion accordion={AnagrafePaziente}
                                    datiAssistitoA0={this.datiA0}
                                    datiAnagrafePaziente={this.accordionData.anagrafePaziente}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvataggio}
                                    onChangeDatiAnagrafePaziente={this.handleChangeDatiAnagrafePaziente}
                                    openAccordion={this.state.accordion.openAccordionAnagrafePaziente}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnagrafePaziente"}
                                    pageState={this.props.pageState}
                                    cancellaPersona={this.cancellaPersona}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    onValidaPagina={this.handleValiditaPagina}
                />

                <VersionedAccordion accordion={ValutazioneBisogniPalliativi}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvataggio}
                                    openAccordion={this.state.accordion.openAccordionValutazioneBisogniPalliativi}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionValutazioneBisogniPalliativi"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    onChangeValutazioneBisogniPalliativi={this.handleChangeValutazioneBisogniPalliativi}
                                    onValidaPagina={this.handleValiditaPagina}
                                    readOnly={this.props.readOnly}
                />

                <VersionedAccordion accordion={SituazioneSociale}
                                    datiAnagrafePaziente={this.accordionData.anagrafePaziente}
                                    datiSituazioneSociale={this.accordionData.situazioneSociale}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvataggio}
                                    onChangeDatiSituazioneSociale={this.handleChangeDatiSituazioneSocialeForm}
                                    openAccordion={this.state.accordion.openAccordionSituazioneSociale}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionSituazioneSociale"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    onValidaPagina={this.handleValiditaPagina}
                />

                <VersionedAccordion accordion={BenessereAbitativo}
                                    benessereAbitativo={this.accordionData.benessereAbitativo}
                                    onChangeBenessereAbitativo={this.handleChangeBenessereAbitativo}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvataggio}
                                    openAccordion={this.state.accordion.openAccordionBenessereAbitativo}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionBenessereAbitativo"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={() => null}
                />

                <VersionedAccordion accordion={DeterminantiDiSalute}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    datiPaziente={this.accordionData.anagrafePaziente}
                                    datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvataggio}
                                    onChangeDeterminatiDiSalute={this.handleChangeDeterminantiDiSaluteForm}
                                    openAccordion={this.state.accordion.openAccordionDeterminantiSalute}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionDeterminantiSalute"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={() => null}
                />

                <VersionedAccordion accordion={AnamnesiFisiologica}
                                    datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                    datiAderenzaAllaTerapia={this.accordionData.informazioniDiBase?.datiAderenzaAllaTerapia}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvataggio}
                                    onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                    onChangeDatiAderenzaAllaTerapia={this.handleChangeInformazioniDiBaseForm}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiFisiologica}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiFisiologica"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    datiConsumoTabacco={this.accordionData.determinantiDiSalute?.datiConsumoTabacco}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={InformazioniBaseFaseValutazione}
                                    datiInformazioniBaseFaseValutazione={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvataggio}
                                    onChangeDatiInformazioniBaseFaseValutazione={this.handleChangeInformazioniBaseFaseValutazioneForm}
                                    openAccordion={this.state.accordion.openAccordionInformazioniBaseFaseValutazione}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionInformazioniBaseFaseValutazione"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={InformazioniDiBase}
                                    datiInformazioniDiBase={this.accordionData.informazioniDiBase}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvataggio}
                                    onChangeDatiInformazioniDiBase={this.handleChangeInformazioniDiBaseForm}
                                    openAccordion={this.state.accordion.openAccordionInfoBase}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionInfoBase"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={AnamnesiFamiliare}
                                    datiAnamnesiFamiliare={this.accordionData.datiAnamnesiFamiliare}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvataggio}
                                    onChangeDatiAnamnesiFamiliare={this.handleChangeAnamnesiFamiliare}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiFamiliare}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiFamiliare"}
                                    pageState={this.props.pageState}
                />

                <VersionedAccordion accordion={AnamnesiPatologicaRemota}
                                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvataggio}
                                    onChangeDatiAnamnesiPatologicaRemota={this.handleChangeAnamnesiPatologicaRemota}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaRemota}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiPatologicaRemota"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={() => null}
                />

                <VersionedAccordion accordion={AnamnesiPatologicaProssima}
                                    datiAnamnesiPatologicaProssima={this.accordionData.anamnesiPatologicaProssima}
                                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvataggio}
                                    onChangeDatiAnamnesiPatologicaProssima={this.handleChangeAnamnesiPatologicaProssima}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaProssima}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiPatologicaProssima"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={EsameObiettivo}
                                    esameObiettivo={this.accordionData.esameObiettivo}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvataggio}
                                    onChangeEsameObiettivo={this.handleChangeEsameObiettivo}
                                    openAccordion={this.state.accordion.openAccordionEsameObiettivo}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionEsameObiettivo"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    showAccordionSezioneRichiesta={!isFieldIdWriteable('M_PRES_ALT_COLOR.CUTEEANNESSI.ESAMEOBIETTIVO', this.props.pageState, this.context.forceUserRole)}
                />

                <VersionedAccordion accordion={ParametriVitali}
                                    datiParametriVitali={this.accordionData.parametriVitali}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvataggio}
                                    onChangeDatiParametriVitali={this.handleChangeDatiParametriVitaliForm}
                                    openAccordion={this.state.accordion.openAccordionParametriVitali}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionParametriVitali"}
                                    pageState={this.props.pageState}
                                    punteggioTestEsasr={this.accordionData.anamnesiFisiologica?.copingTolleranzaStress?.punteggioAnsia}
                />
            </>
        );
    }

    renderModaleSuccesso = () => {
        const footerModaleSettingRifiutato = (
            <>
                <Button
                    onClick={() => this.downloadPdf()}
                    id={"visualizzaPdfBtn"}
                    text="Visualizza Pdf"
                    className={"btn btn-adi btnGreenOutline"}
                />
                <Button
                    onClick={() => this.setState({gotoHome: true})}
                    id={"tornaHomeBtn"}
                    text="Torna a Home"
                    dataDismiss={"modal"}
                    className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                />
            </>
        );

        return (
            <>
                <ModalHelper
                    id={"operazioneCompletataModal"}
                    title={"Operazione completata"}
                    body={"La richiesta e il relativo setting sono stati rifiutati"}
                    footer={footerModaleSettingRifiutato}
                    titleClassName={"modal-header d-inline text-center backgroundGreen"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={"modal-content"}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={"modal-body d-flex align-items-center justify-content-around f-weight-600"}
                />
            </>
        );
    }

    downloadPdf = () => {
        let uuidRichiesta = this.props.richiesta?.id;
        RichiestaADIService.generaPdf(uuidRichiesta, "STEP_CDA2_CONFERMA_SETTING").subscribe((response) => {
            if (response?.msg === "ERRORE") {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "confirm",
                    text: "Il fascicolo sanitario elettronico non è al momento disponibile. Si prega di riprovare più tardi"
                });
            } else {
                const linkSource = `data:${response.data.contentType};base64,${response.data.file}`;
                const downloadLink = document.createElement("a");
                const fileName = response.data.fileName;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        });
    }

    controlloProfilo = () => {
        const isUtenteCentraleAdi = _.some(this.state?.richiesta?.team, t => SessioneUtente.getInstance().idUtente === t.professionista?.codiceFiscale && t.profilo === enumsUtente.profiliUtente.centraleAdi);
    }

    renderModaleRifiutaSetting = () => {
        const titleRifiutaSetting = (
            <Fragment>
                <span className={"modal-title"}>Rifiuta setting proposto</span>
            </Fragment>
        );

        const bodyRifiutaSetting = <Fragment>
            <div className={"row"}>
                <div className={"col"}>
                    <FieldsGroup>
                        <RoleBasedInput
                            id={"motivazione"}
                            fieldId={"MOTIVAZIONE.VALUTAZIONECAMBIOSETTING.PROGETTOASSISTENZIALERICHIESTO"}
                            pageState={this.props.pageState}
                            parentClass={"mb-0"}
                            value={this.state.motivazione}
                            placeholder={"Inserisci motivazione"}
                            field={"motivazione"}
                            onChange={(field, elem) => this.setState({[field]: elem.target.value})}
                            type={"textarea"}
                            forceReadOnly={false}
                            hideUnsetValues={false}
                            disableDoubleInput={false}
                            maxlength={500}
                            showAvailableCharsLabel={true}
                            fieldRequired={true}
                            fieldLabel={"Motivazione"}
                            rows={"5"}
                        />
                    </FieldsGroup>
                </div>
            </div>
        </Fragment>

        const footerRifiutaSetting = (
            <Fragment>
                <Button
                    id={"conferma"}
                    text={this.controlloProfilo() ? 'Rifiuta setting' : 'Rifiuta setting e firma'}
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => this.setState({
                        esitoValutazione: enumRichiesta.stato.RIFIUTATA,
                        isSettingRifiutato: true
                    },
                        () => this.prepareToSalvataggio())}
                    disabled={Utils.isStringEmptyOrNullOrUndefined(this.state.motivazione)}
                />
                <Button
                    id={"annulla"}
                    text={"Annulla"}
                    dataDismiss={"modal"}
                    className={ "btn btn-adi btnWhiteOutlineBorderOrange" }
                    onClick={() => this.setState({motivazione: null}, () => this.setState({tipoModaleVisualizzata: null}))}
                />
            </Fragment>
        );

        return (
            <ModalHelper id={"modaleAvvisoSetting"}
                         title={titleRifiutaSetting}
                         body={bodyRifiutaSetting}
                         footer={footerRifiutaSetting}
                         titleClassName={"modal-header d-inline text-center backgroundOrange"}
                         containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                         contentClassName={"modal-content"}
                         footerClassName={"modal-footer justify-content-around"}
                         bodyClassName={"modal-body align-items-center f-weight-600"}
            />)
    }

    render() {
        AccordionHelper.accordionPageSubject.next({position: 2, title: "Chiudi Pre-Dialogo (1 d 3)"});

        if (this.state.redirectToCompilaProposte) {
            let baseUrl = routepath.compila_chiusura_predialogo;
            let richiesta = _.cloneDeep(this.props.richiesta);
            buildRichiesta(richiesta, this, this.props.pageName, false);
            return (
                <Redirect to={{
                    pathname: baseUrl.concat(routepath.compila_proposte),
                    uuidRichiesta: this.props.richiesta.id,
                    pageState: this.props.pageState,
                    readOnly: this.props.readOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    pageName: enumPaginaCompila.COMPILA_PROPOSTE,
                    firstPageName: enumPaginaCompila.COMPILA_CHIUDI_PREDIALOGO,
                    richiesta: richiesta,
                    richiestaMedico: this.props.richiestaMedico,
                    persone: this.props.persone,
                    tipologia: this.props.richiesta?.tipologia,
                    stato: this.props.richiesta?.stato,
                    pageNumber: 2
                }}/>
            );
        }

        if (this.state.gotoHome) return <Redirect to={{pathname: routepath.home}}/>;

        if (this.state.redirectToAnagrafeRichiedente) {
            return (
                <Redirect to={{
                    pathname: routepath.inserimento_richiesta.concat(routepath.compila_scheda_paziente).concat(routepath.anagrafica_medico_richiedente),
                    idPersona: Utils.recuperoIdProfessionista(this.props.richiesta),
                    uuidRichiesta: this.props.richiesta.id,
                    pageState: this.props.pageState,
                    pageName: enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE,
                    readOnly: !isSomeFieldIdWriteable('ANAGRAFESOGGETTORICHIEDENTE', this.props.pageState, forceUserRole) || !!this.props.readOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    insRichiestaPathname: routepath.compila_chiusura_predialogo.concat(routepath.compila_scheda_paziente),
                    richiesta: this.props.richiesta,
                    pageNumber: this.props.pageNumber
                }}/>
            );
        }

        const isStateValutazione = Utils.isStateValutazione(this.props.pageState);
        const forceUserRole = isStateValutazione && this.props.richiesta.sostituzioneRichiedente
        && (AuthUtils.hasUtenteCentraleAdi() || AuthUtils.hasUtenteRuoloMcp()) ? enumUtente.ruoli.mmg : null;

        UserHelper.setForceUserRole(forceUserRole);

        const {accordion} = this.state;
        return (
            <>
                {((!this.props.openAccordion && !Object.values(accordion).includes(true)) || (this.props.openAccordion)) &&
                    <ScrollTop scrollInit={true} conAnimazione={false}/>}
                {this.state.tipoModaleVisualizzata && this.renderModaleRifiutaSetting()}
                {this.state.isSettingRifiutato && this.renderModaleSuccesso()}
                <div id={"compilaRichiestaId"}>
                    <RoleBasedContext.Provider
                        value={{
                            forceReadOnly: this.props.readOnly,
                            hideUnsetValues: this.props.hideUnsetValues,
                            scrollToHead: this.props.openAccordion,
                            forceUserRole: forceUserRole,
                            requestVersion: this.props.richiesta?.versione,
                            showOnlyRequiredFields: false
                        }}>
                        <div className="mx-5">{this.renderAccordions()}</div>
                    </RoleBasedContext.Provider>
                </div>
                {!this.props.readOnly && this.renderButtons()}
            </>
        );
    }
}

SchedaPazienteChiudiPreDialogo.propTypes = propTypes;
SchedaPazienteChiudiPreDialogo.defaultProps = {
    readOnly: false,
    hideUnsetValues: false,
    openAccordion: false
}
