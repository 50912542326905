import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Utils from "../../../utils/Utils.js";
import {FieldsRow, RoleBasedAutocomplete, SectionList} from "../../roleBasedComponents/RoleBasedComponents";
import {v4 as uuid} from "uuid";

const propTypes = {
    dispositiviPresenti: PropTypes.array.isRequired,
    handleArrayChange: PropTypes.func.isRequired,
    pageState: PropTypes.string.isRequired,
    fieldId: PropTypes.string.isRequired,
    infermiereFieldId: PropTypes.string,
    field: PropTypes.string,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    indentOffset: PropTypes.number,
    otherElements: PropTypes.func,
    listId: PropTypes.arrayOf(PropTypes.object),
    parentJsonPath: PropTypes.string,
    fieldInAccordionReadOnly: PropTypes.bool
}

export const defaultDispositiviPresenti = {
    id: null,
    codice: null
}

export default class DispositiviPresenti extends Component {

    static cndData = Utils.getListCndData();

    componentDidMount() {
        if (!Array.isArray(this.props.dispositiviPresenti)) {
            this.props.handleArrayChange([], this.props.field);
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.dispositiviPresenti, prevProps.dispositiviPresenti)) {
            this.props.dispositiviPresenti.forEach((e, i) => {
                if (!this.props.dispositiviPresenti[i].codice && i !== (this.props.dispositiviPresenti.length - 1)) {
                    this.remove(i);
                }
            })
        }
    }

    addNew = () => {
        let array = _.cloneDeep(this.props.dispositiviPresenti) ?? [];
        array.push({codice: null, id: uuid()});
        this.props.handleArrayChange(array, this.props.field);
    }

    remove = (index) => {
        let array = _.cloneDeep(this.props.dispositiviPresenti);
        array.splice(index, 1);
        this.props.handleArrayChange(array, this.props.field);
    }

    update = (field, elem, i) => {
        let value = elem?.target?.value;

        if (this.props.dispositiviPresenti.some(e => e === value)) value = null;

        let array = _.cloneDeep(this.props.dispositiviPresenti);
        array[i].codice = value;
        this.props.handleArrayChange(array, this.props.field);
    }

    renderSection = (el, i) => {
        return <FieldsRow>
            <RoleBasedAutocomplete
                fieldId={this.props.fieldId}
                infermiereFieldId={this.props.infermiereFieldId}
                pageState={this.props.pageState}
                id={this.props.field}
                keyField={"codice"}
                descriptionField={"descrizione"}
                onChange={(field, elem) => this.update(field, elem, i)}
                searchFromStart={false}
                value={el.codice}
                field={'codice'}
                placeholder={'Inserisci dispositivo'}
                suggestions={DispositiviPresenti.cndData}
                noSuggestions={'Errore: Dispositivo non presente'}
                forceReadOnly={this.props.forceReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                disableDoubleInput={this.props.disableDoubleInput}
                handleOnlyValue={true}
                fieldLabel={'Dispositivo'}
                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
            />
            {this.props.otherElements && this.props.otherElements(el, i)}
        </FieldsRow>;
    }

    render() {
        return <SectionList
            addNewSectionCallback={this.addNew}
            data={this.props.dispositiviPresenti}
            removeSectionCallback={this.remove}
            title={'Dispositivi (presidi, ausili, ortesi ecc.) presenti'}
            subtitle={'Dispositivo presente'}
            renderSection={this.renderSection}
            keyFieldId={this.props.fieldId}
            infermiereKeyFieldId={this.props.infermiereFieldId}
            pageState={this.props.pageState}
            addButtonVisibilityHandler={this.props.dispositiviPresenti?.every(e => e?.codice && e.codice !== '')}
            forceReadOnly={this.props.forceReadOnly}
            indentOffset={this.props.indentOffset}
            field={this.props.field}
            listId={this.props.listId}
            parentJsonPath={this.props.parentJsonPath}
            fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
        />;
    }
}

DispositiviPresenti.propTypes = propTypes;

DispositiviPresenti.defaultProps = {
    field: 'dispositiviPresenti',
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false,
    infermiereFieldId: null,
    indentOffset: 1,
    fieldInAccordionReadOnly: false
}
