import React from "react";
import PropTypes from "prop-types";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import AuthUtils from "../../utils/AuthUtils";
import VersionedAccordion from "../VersionedAccordion";
import AnagrafePaziente from "../anagrafePaziente/AnagrafePaziente";
import ValutazioneBisogniPalliativi from "../valutazioneBisogniPalliativi/ValutazioneBisogniPalliativi";
import SituazioneSociale from "../situazioneSociale/SituazioneSociale";
import DeterminantiDiSalute from "../determinantiDiSalute/DeterminantiDiSalute";
import AnamnesiFisiologica from "../anamnesiFisiologica/AnamnesiFisiologica";
import InformazioniDiBase from "../infoBase/InformazioniDiBase";
import AnamnesiPatologicaRemota from "../anamnesiPatologicaRemota/AnamnesiPatologicaRemota";
import AnamnesiPatologicaProssima from "../anamnesiPatologicaProssima/AnamnesiPatologicaProssima";
import * as _ from "lodash";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import * as $ from "jquery";
import SessionRequest from "../../utils/SessionRequest";
import AccordionHelper from "../../service/AccordionHelper";
import Utils from "../../utils/Utils";
import LivelloPrioritaRichiesta from "../compilaProposte/livelloPriorita/LivelloPrioritaRichiesta";
import statiRichiesta from "../../utils/dataset/richiestaData/statiRichiesta.json";
import DataDimissioneProgrammataDaRicovero
    from "../compilaProposte/dataDimissioneProgrammataDaRicovero/DataDimissioneProgrammataDaRicovero";
import DataDiPresaInCaricoTerritorialeDaDimissioneProtetta
    from "../compilaProposte/dataDiPresaInCaricoDaDimissioneProtetta/DataDiPresaInCaricoTerritorialeDaDimissioneProtetta";
import BisogniPrestazioniAssistenziali
    from "../compilaProposte/bisogniPrestazioniAssistenziali/BisogniPrestazioniAssistenziali";
import BisogniAllaDimissione from "../compilaProposte/bisogniAllaDimissione/BisogniAllaDimissione";
import FigureProfessionaliPresaInCarico
    from "../compilaProposte/figureProfessionaliPresaInCarico/FigureProfessionaliPresaInCarico";
import ProposteAttivitaSuggerimenti from "../compilaProposte/proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimenti";
import enumRichiesta from "../../enum/enumRichiesta.json";
import FigureProfessionaliSupportoAValutazione
    from "../compilaProposte/FigureProfessionaliSupportoAValutazione/FigureProfessionaliSupportoAValutazione";
import ValutazioneInFormalizzazione
    from "../compilaFormalizzazione/caratteristicheSettingRichiesto/ValutazioneInFormalizzazione";

export default class DatiRichiesta extends AccordionFirstLevel {

    state = {
        accordion: {
            openAccordionAnagrafePaziente: true,
            openAccordionSituazioneSociale: true,
            openAccordionDeterminantiSalute: true,
            openAccordionAnamnesiFisiologica: true,
            openAccordionInformazioniBaseFaseRichiesta: true,
            openAccordionInfoBase: true,
            openAccordionAnamnesiPatologicaRemota: true,
            openAccordionAnamnesiPatologicaProssima: true,
            openAccordionValutazioneBisogniPalliativi: true,
            openAccordionBisogniPrestazioniAssistenziali: true,
            openAccordionFigureProfessionaliPresaInCarico: true,
            openAccordionProposteAttivitaSuggerimenti: true,
            openAccordionFigureProfessionaliSupportoAValutazione: true,
            openAccordionLivelloPriorita: true,
            openAccordionDataDimissioneProgrammataDaRicovero: true,
            openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta: true,
            openAccordionBisogniAllaDimissione: true
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        $('label:contains("*Campo obbligatorio")').remove();
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion});
        }
    }

    renderAccordionInfermiere = () => {
        return (
            <>
                <VersionedAccordion accordion={AnagrafePaziente}
                                    datiAssistitoA0={this.props.datiAssistitoA0}
                                    datiAnagrafePaziente={this.props.accordionData.anagrafePaziente}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.props.salvaBozza}
                                    onChangeDatiAnagrafePaziente={this.props.onChangeDatiAnagrafePaziente}
                                    openAccordion={this.state.accordion.openAccordionAnagrafePaziente}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnagrafePaziente"}
                                    pageState={this.props.pageState}
                                    cancellaPersona={this.props.cancellaPersona}
                                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                                    onValidaPagina={this.props.onValidaPagina}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                />

                <VersionedAccordion accordion={ValutazioneBisogniPalliativi}
                                    richiesta={this.props.richiesta}
                                    openAccordion={this.state.accordion.openAccordionValutazioneBisogniPalliativi}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionValutazioneBisogniPalliativi"}
                                    pageState={this.props.pageState}
                                    readOnly={this.props.readOnly}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                />

                <VersionedAccordion accordion={InformazioniDiBase}
                                    datiInformazioniDiBase={this.props.accordionData.informazioniDiBase}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.props.salvaBozza}
                                    onChangeDatiInformazioniDiBase={this.props.onChangeDatiInformazioniDiBase}
                                    openAccordion={this.state.accordion.openAccordionInfoBase}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionInfoBase"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                />

                <VersionedAccordion accordion={AnamnesiFisiologica}
                                    datiAnamnesiFisiologica={this.props.richiestaMedico?.anamnesiFisiologica ?? SessionRequest.getInitObject('anamnesiFisiologica')}
                                    datiAderenzaAllaTerapia={this.props.accordionData.informazioniDiBase?.datiAderenzaAllaTerapia ?? SessionRequest.getInitObject('informazioniDiBase.datiAderenzaAllaTerapia')}
                                    onChangeDatiAderenzaAllaTerapia={() => undefined}
                                    richiesta={this.props.richiestaMedico}
                                    salvaBozza={() => null}
                                    onChangeDatiAnamnesiFisiologica={() => undefined}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiFisiologica}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiFisiologica"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.props.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    datiConsumoTabacco={this.props.accordionData.determinantiDiSalute?.datiConsumoTabacco}
                                    onChangeObbligatorieta={() => undefined}
                                    forceReadOnly={true}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={true}
                                    sezioneDatiRichiesta={true}
                />

                <VersionedAccordion accordion={AnamnesiPatologicaRemota}
                                    datiAnamnesiPatologicaRemota={this.props.richiestaMedico?.anamnesiPatologicaRemota ?? SessionRequest.getInitObject('anamnesiPatologicaRemota')}
                                    richiesta={this.props.richiestaMedico}
                                    salvaBozza={() => null}
                                    onChangeDatiAnamnesiPatologicaRemota={() => undefined}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaRemota}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiPatologicaRemota"}
                                    pageState={this.props.pageState}
                                    showAccordionSezioneRichiesta={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    onChangeObbligatorieta={() => null}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                />

                <VersionedAccordion accordion={AnamnesiPatologicaProssima}
                                    datiAnamnesiPatologicaProssima={this.props.richiestaMedico?.anamnesiPatologicaProssima ?? SessionRequest.getInitObject('anamnesiPatologicaProssima')}
                                    datiAnamnesiPatologicaRemota={this.props.richiestaMedico?.anamnesiPatologicaRemota ?? SessionRequest.getInitObject('anamnesiPatologicaRemota')}
                                    richiesta={this.props.richiestaMedico}
                                    salvaBozza={() => null}
                                    onChangeDatiAnamnesiPatologicaProssima={() => undefined}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaProssima}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiPatologicaProssima"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={() => null}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                />

                <VersionedAccordion accordion={DeterminantiDiSalute}
                                    accordionTitle={"Consapevolezza della salute"}
                                    datiPaziente={this.props.accordionData.anagrafePaziente}
                                    datiDeterminantiDiSalute={this.props.accordionData.determinantiDiSalute}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.props.salvaBozza}
                                    onChangeDeterminatiDiSalute={this.props.onChangeDeterminatiDiSalute}
                                    openAccordion={this.state.accordion.openAccordionDeterminantiSalute}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionDeterminantiSalute"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                />

                <VersionedAccordion accordion={SituazioneSociale}
                                    datiAnagrafePaziente={this.props.accordionData.anagrafePaziente}
                                    datiSituazioneSociale={this.props.accordionData.situazioneSociale}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.props.salvaBozza}
                                    onChangeDatiSituazioneSociale={this.props.onChangeDatiSituazioneSociale}
                                    openAccordion={this.state.accordion.openAccordionSituazioneSociale}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionSituazioneSociale"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                                    onValidaPagina={this.props.onValidaPagina}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                                    disableDoubleInput={true}
                />

                {Utils.isDimissioneProtetta(this.props.pageState)
                    ? this.renderFormPaginaProposteFaseDimissioneProtetta()
                    : this.renderFormPaginaProposteFaseClassica()}
            </>
        );
    }

    renderAccordionValutazioneMedico = () => {
        return (
            <>
                <VersionedAccordion accordion={AnagrafePaziente}
                                    datiAssistitoA0={this.props.datiAssistitoA0}
                                    datiAnagrafePaziente={this.props.accordionData.anagrafePaziente}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.props.salvaBozza}
                                    onChangeDatiAnagrafePaziente={this.props.onChangeDatiAnagrafePaziente}
                                    openAccordion={this.state.accordion.openAccordionAnagrafePaziente}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnagrafePaziente"}
                                    pageState={this.props.pageState}
                                    cancellaPersona={this.props.cancellaPersona}
                                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                                    onValidaPagina={this.props.onValidaPagina}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                />

                <VersionedAccordion accordion={ValutazioneBisogniPalliativi}
                                    richiesta={this.props.richiesta}
                                    openAccordion={this.state.accordion.openAccordionValutazioneBisogniPalliativi}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionValutazioneBisogniPalliativi"}
                                    pageState={this.props.pageState}
                                    readOnly={this.props.forceReadOnly}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                />

                <VersionedAccordion accordion={SituazioneSociale}
                                    datiAnagrafePaziente={this.props.accordionData.anagrafePaziente}
                                    datiSituazioneSociale={this.props.accordionData.situazioneSociale}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.props.salvaBozza}
                                    onChangeDatiSituazioneSociale={this.props.onChangeDatiSituazioneSociale}
                                    openAccordion={this.state.accordion.openAccordionSituazioneSociale}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionSituazioneSociale"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                                    onValidaPagina={this.props.onValidaPagina}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                />

                {/* <VersionedAccordion accordion={DeterminantiDiSalute}
                                    sessoPaziente={this.props.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    datiPaziente={this.props.accordionData.anagrafePaziente}
                                    datiDeterminantiDiSalute={this.props.accordionData.determinantiDiSalute}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.props.salvaBozza}
                                    onChangeDeterminatiDiSalute={this.props.onChangeDeterminatiDiSalute}
                                    openAccordion={this.state.accordion.openAccordionDeterminantiSalute}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionDeterminantiSalute"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                /> */}

                <VersionedAccordion accordion={AnamnesiFisiologica}
                                    datiAnamnesiFisiologica={this.props.accordionData.anamnesiFisiologica}
                                    datiAderenzaAllaTerapia={this.props.accordionData.informazioniDiBase?.datiAderenzaAllaTerapia}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.props.salvaBozza}
                                    onChangeDatiAnamnesiFisiologica={this.props.onChangeDatiAnamnesiFisiologica}
                                    onChangeDatiAderenzaAllaTerapia={this.props.onChangeDatiAderenzaAllaTerapia}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiFisiologica}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiFisiologica"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.props.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    datiConsumoTabacco={this.props.accordionData.determinantiDiSalute?.datiConsumoTabacco}
                                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                />

                <VersionedAccordion accordion={InformazioniDiBase}
                                    datiInformazioniDiBase={this.props.accordionData.informazioniDiBase}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.props.salvaBozza}
                                    onChangeDatiInformazioniDiBase={this.props.onChangeDatiInformazioniDiBase}
                                    openAccordion={this.state.accordion.openAccordionInfoBase}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionInfoBase"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                />

                <VersionedAccordion accordion={AnamnesiPatologicaRemota}
                                    datiAnamnesiPatologicaRemota={this.props.accordionData.anamnesiPatologicaRemota}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.props.salvaBozza}
                                    onChangeDatiAnamnesiPatologicaRemota={this.props.onChangeDatiAnamnesiPatologicaRemota}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaRemota}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiPatologicaRemota"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                />

                <VersionedAccordion accordion={AnamnesiPatologicaProssima}
                                    datiAnamnesiPatologicaProssima={this.props.accordionData.anamnesiPatologicaProssima}
                                    datiAnamnesiPatologicaRemota={this.props.accordionData.anamnesiPatologicaRemota}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.props.salvaBozza}
                                    onChangeDatiAnamnesiPatologicaProssima={this.props.onChangeDatiAnamnesiPatologicaProssima}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaProssima}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiPatologicaProssima"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                                    accordionReadOnly={this.props.forceReadOnly}
                                    sezioneDatiRichiesta={true}
                />

                {Utils.isDimissioneProtetta(this.props.pageState)
                    ? this.renderFormPaginaProposteFaseDimissioneProtetta()
                    : this.renderFormPaginaProposteFaseClassica()}
            </>
        );
    }

    renderFormPaginaProposteFaseClassica = () => {
        return (
            <>
                <VersionedAccordion
                    accordion={BisogniPrestazioniAssistenziali}
                    dati={this.props.accordionData.bisogniPrestazioniAssistenziali ?? []}
                    onChangeBisogniPrestazioniAssistenziali={this.props.onChangeBisogniPrestazioniAssistenziali}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    openAccordion={this.state.accordion.openAccordionBisogniPrestazioniAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniPrestazioniAssistenziali"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                    sezioneDatiRichiesta={true}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliPresaInCarico}
                    dati={this.props.accordionData.figureProfessionaliPresaInCarico}
                    datiNecessitaAssistenzaSanitaria={this.props.accordionData.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    onChangeDatiFigureProfessionaliPresaInCarico={this.props.onChangeDatiFigureProfessionaliPresaInCarico}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliPresaInCarico}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliPresaInCarico"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                    firstPageName={this.props.pageName}
                    previousPageOnlyRequiredFields={true}
                    sezioneDatiRichiesta={true}/>

                <VersionedAccordion
                    accordion={LivelloPrioritaRichiesta}
                    professionistiSupportoAValutazione={this.props.accordionData.professionistiSupportoAValutazione}
                    dati={this.props.accordionData.livelloPriorita}
                    figureProfessionali={this.props.accordionData.figureProfessionaliPresaInCarico}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    openAccordion={this.state.accordion.openAccordionLivelloPriorita}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionLivelloPriorita"}
                    onChangeLivelloPriorita={this.props.onChangeLivelloPriorita}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                    sezioneDatiRichiesta={true}/>

                <VersionedAccordion
                    accordion={ProposteAttivitaSuggerimenti}
                    datiProposteAttivitaSuggerimenti={this.props.accordionData.proposteAttivitaSuggerimenti}
                    datiproposteAttivitaSuggerimentiAssistenteSociale={this.props.accordionData.proposteAttivitaSuggerimentiAssistenteSociale}
                    datiProposteAttivitaSuggerimentiNote={this.props.accordionData.proposteAttivitaSuggerimentiNote}
                    datiSituazioneSociale={this.props.accordionData.situazioneSociale}
                    datiDeterminantiDiSalute={this.props.accordionData.determinantiDiSalute}
                    datiAnamnesiFisiologica={this.props.accordionData.anamnesiFisiologica}
                    datiInformazioniBase={this.props.accordionData.informazioniDiBase}
                    datiInformazioniBaseFaseRichiesta={this.props.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]}
                    datiInformazioniBaseFaseValutazione={this.props.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                    datiBenessereAbitativo={this.props.accordionData.benessereAbitativo}
                    datiEsameObiettivo={this.props.accordionData.esameObiettivo}
                    datiAnamnesiPatologicaRemota={this.props.accordionData.anamnesiPatologicaRemota}
                    datiAnamnesiFamiliare={this.props.accordionData.datiAnamnesiFamiliare}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    onChangeDatiProposteAttivitaSuggerimenti={this.props.onChangeDatiProposteAttivitaSuggerimenti}
                    openAccordion={this.state.accordion.openAccordionProposteAttivitaSuggerimenti}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionProposteAttivitaSuggerimenti"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                    sezioneDatiRichiesta={true}/>
            </>
        );
    }

    renderFormPaginaProposteFaseDimissioneProtetta = () => {
        return (
            <>
                <VersionedAccordion
                    accordion={LivelloPrioritaRichiesta}
                    professionistiSupportoAValutazione={this.props.accordionData.professionistiSupportoAValutazione}
                    dati={this.props.richiesta.stato === statiRichiesta.confermataPreDialogo.value || this.props.richiesta.stato === statiRichiesta.inChiusuraPreDialogo.value
                        ? this.props.accordionData.livelloPrioritaBozza
                        : this.props.accordionData.livelloPriorita}
                    figureProfessionali={this.props.accordionData.figureProfessionaliPresaInCarico}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    openAccordion={this.state.accordion.openAccordionLivelloPriorita}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionLivelloPriorita"}
                    onChangeLivelloPriorita={this.props.onChangeLivelloPriorita}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                    sezioneDatiRichiesta={true}/>

                <VersionedAccordion
                    accordion={DataDimissioneProgrammataDaRicovero}
                    dati={this.props.accordionData.dataDimissioneProgrammataDaRicovero}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    onChangeDatiDataDimissioneProgrammataDaRicovero={this.props.onChangeDatiDataDimissioneProgrammataDaRicovero}
                    openAccordion={this.state.accordion.openAccordionDataDimissioneProgrammataDaRicovero}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionDataDimissioneProgrammataDaRicovero"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                    sezioneDatiRichiesta={true}/>

                {!Utils.isStateRichiestaPreDialogo(this.props.pageState)
                    && !Utils.isStateValutazionePreDialogo(this.props.pageState) &&
                    <VersionedAccordion
                        accordion={DataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                        dati={this.props.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                        richiesta={this.props.richiesta}
                        salvaBozza={this.props.salvaBozza}
                        onChangeDatiDataDiPresaInCaricoTerritorialeDaDimissioneProtetta={this.props.onChangeDatiDataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                        openAccordion={this.state.accordion.openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta"}
                        pageState={this.props.pageState}
                        onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                        addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                        sezioneDatiRichiesta={true}/>}

                <VersionedAccordion
                    accordion={BisogniPrestazioniAssistenziali}
                    dati={this.props.accordionData.bisogniPrestazioniAssistenziali ?? []}
                    onChangeBisogniPrestazioniAssistenziali={this.props.onChangeBisogniPrestazioniAssistenziali}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    openAccordion={this.state.accordion.openAccordionBisogniPrestazioniAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniPrestazioniAssistenziali"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                    sezioneDatiRichiesta={true}/>

                <VersionedAccordion
                    accordion={BisogniAllaDimissione}
                    dati={this.props.accordionData.bisogniAllaDimissione}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    onChangeDatiBisogniAllaDimissione={this.props.onChangeDatiBisogniAllaDimissione}
                    openAccordion={this.state.accordion.openAccordionBisogniAllaDimissione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniAllaDimissione"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                    sezioneDatiRichiesta={true}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliPresaInCarico}
                    dati={this.props.accordionData.figureProfessionaliPresaInCarico}
                    datiNecessitaAssistenzaSanitaria={this.props.accordionData.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    onChangeDatiFigureProfessionaliPresaInCarico={this.props.onChangeDatiFigureProfessionaliPresaInCarico}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliPresaInCarico}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliPresaInCarico"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                    firstPageName={this.props.pageName}
                    previousPageOnlyRequiredFields={true}
                    sezioneDatiRichiesta={true}/>

                <VersionedAccordion
                    accordion={ProposteAttivitaSuggerimenti}
                    datiProposteAttivitaSuggerimenti={this.props.accordionData.proposteAttivitaSuggerimenti}
                    datiproposteAttivitaSuggerimentiAssistenteSociale={this.props.accordionData.proposteAttivitaSuggerimentiAssistenteSociale}
                    datiProposteAttivitaSuggerimentiNote={this.props.accordionData.proposteAttivitaSuggerimentiNote}
                    datiSituazioneSociale={this.props.accordionData.situazioneSociale}
                    datiDeterminantiDiSalute={this.props.accordionData.determinantiDiSalute}
                    datiAnamnesiFisiologica={this.props.accordionData.anamnesiFisiologica}
                    datiInformazioniBase={this.props.accordionData.informazioniDiBase}
                    datiInformazioniBaseFaseRichiesta={this.props.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]}
                    datiInformazioniBaseFaseValutazione={this.props.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                    datiBenessereAbitativo={this.props.accordionData.benessereAbitativo}
                    datiEsameObiettivo={this.props.accordionData.esameObiettivo}
                    datiAnamnesiPatologicaRemota={this.props.accordionData.anamnesiPatologicaRemota}
                    datiAnamnesiFamiliare={this.props.accordionData.datiAnamnesiFamiliare}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    onChangeDatiProposteAttivitaSuggerimenti={this.props.onChangeDatiProposteAttivitaSuggerimenti}
                    openAccordion={this.state.accordion.openAccordionProposteAttivitaSuggerimenti}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionProposteAttivitaSuggerimenti"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                    sezioneDatiRichiesta={true}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliSupportoAValutazione}
                    dati={this.props.accordionData.professionistiSupportoAValutazione}
                    datiNecessitaAssistenzaSanitaria={this.props.accordionData.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    onChangeDatiFigureProfessionaliSupportoAValutazione={this.props.onChangeDatiFigureProfessionaliSupportoAValutazione}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliSupportoAValutazione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliSupportoAValutazione"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.props.onChangeObbligatorieta}
                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                    firstPageName={this.props.pageName}
                    previousPageOnlyRequiredFields={true}
                    sezioneDatiRichiesta={true}/>

                {!Utils.isStateRichiestaPreDialogo(this.props.pageState)
                    && !Utils.isStateValutazionePreDialogo(this.props.pageState)
                    && <AccordionAvvioRichiesta
                        idAccordion={"valutazioneChiusuraPreDialogo"}
                        title={"Valutazione"}
                        openAccordion={this.state.accordion.openAccordionValutazione}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionValutazione"}
                        pageState={this.props.pageState}
                        addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                        sezioneDatiRichiesta={true}>
                        <VersionedAccordion
                            accordion={ValutazioneInFormalizzazione}
                            tipoValutazione={this.props.richiesta?.formalizzazione?.tipoValutazione}
                            dataValutazione={!Utils.isStringEmptyOrNullOrUndefined(this.props.richiesta?.formalizzazione?.dataValutazione)
                                ? this.props.richiesta.formalizzazione.dataValutazione.match(/\d{4}-\d{2}-\d{2}/g)
                                    ? Utils.formatDate(this.props.richiesta?.formalizzazione.dataValutazione, "YYYY-MM-DD", "DD/MM/YYYY")
                                    : this.props.richiesta?.formalizzazione.dataValutazione
                                : ''}
                            openAccordion={this.state.accordion.openAccordionValutazione}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionValutazione"}
                            pageState={this.props.pageState}
                            sezioneDatiRichiesta={true}/>
                    </AccordionAvvioRichiesta>}
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"datiRichiestaAccordion"}
                title={"Dati Richiesta"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                additionalClass={'mx-2'}>
                {AuthUtils.hasUtenteRuoloInfermiere() ? this.renderAccordionInfermiere() : this.renderAccordionValutazioneMedico()}
            </AccordionAvvioRichiesta>
        );
    }
}

DatiRichiesta.propTypes = {
    datiAssistitoA0: PropTypes.object,
    accordionData: PropTypes.object,
    richiesta: PropTypes.object,
    richiestaMedico: PropTypes.object,
    openAccordion: PropTypes.bool,
    onClickAccordion: PropTypes.func,
    field: PropTypes.string,
    pageState: PropTypes.string,
    addInformazioniAggiuntive: PropTypes.func,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    salvaBozza: PropTypes.func,
    onChangeDatiAnagrafePaziente: PropTypes.func,
    cancellaPersona: PropTypes.func,
    onChangeObbligatorieta: PropTypes.func,
    onValidaPagina: PropTypes.func,
    onChangeDatiSituazioneSociale: PropTypes.func,
    onChangeDeterminatiDiSalute: PropTypes.func,
    onChangeDatiAnamnesiFisiologica: PropTypes.func,
    onChangeDatiAderenzaAllaTerapia: PropTypes.func,
    onChangeDatiInformazioniDiBase: PropTypes.func,
    onChangeDatiAnamnesiPatologicaRemota: PropTypes.func,
    onChangeDatiAnamnesiPatologicaProssima: PropTypes.func,
    onChangeBisogniPrestazioniAssistenziali: PropTypes.func,
    onChangeDatiFigureProfessionaliPresaInCarico: PropTypes.func,
    onChangeLivelloPriorita: PropTypes.func,
    onChangeDatiProposteAttivitaSuggerimenti: PropTypes.func,
    onChangeDatiFigureProfessionaliSupportoAValutazione: PropTypes.func,
    onChangeDatiDataDimissioneProgrammataDaRicovero: PropTypes.func,
    onChangeDatiDataDiPresaInCaricoTerritorialeDaDimissioneProtetta: PropTypes.func,
    onChangeDatiBisogniAllaDimissione: PropTypes.func
}
