import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FieldsGroup, FieldsRow} from "../../roleBasedComponents/RoleBasedComponents";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";

function ProfiloAutonomia({
  codiceProfilo,
  openAccordion,
  onClickAccordion,
  field,
  pageState,
}) {
  // TODO Al momento il fieldId è mockato per poter permettere la ricerca di questo campo nella barra di ricerca
  let codiceProfiloComp = (
    <FieldsGroup fieldId={"CODICE_PROFILO"} fieldLabel={"Codice profilo"} showFieldIfRequiredAccordionPage={false}>
      <span>{codiceProfilo ? codiceProfilo : "-"}</span>
    </FieldsGroup>
  );

  return (
    <Fragment>
      <AccordionSezioneRichiesta
        idAccordion={"profiloAutonomia"}
        title={"Profilo dell'autonomia"}
        required={false}
        openAccordion={openAccordion}
        onClickAccordion={onClickAccordion}
        field={field}
        pageState={pageState}
      >
        <FieldsRow>{codiceProfiloComp}</FieldsRow>
      </AccordionSezioneRichiesta>
    </Fragment>
  );
}

ProfiloAutonomia.propTypes = {
  codiceProfilo: PropTypes.string,
  openAccordion: PropTypes.bool,
  onClickAccordion: PropTypes.func,
  field: PropTypes.string,
  pageState: PropTypes.string,
};

ProfiloAutonomia.defaultProps = {
  codiceProfilo: "",
  openAccordion: false,
  onClickAccordion: null,
  field: "",
  pageState: "",
};

export default ProfiloAutonomia;
