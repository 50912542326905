import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    apparatoVascolarePeriferico: PropTypes.any,
    showAccordion: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultSede = {
    id: null,
    codice: null,
}

const defaultAlterazione = {
    id: null,
    tipologiaDiAlterazione: null,
    altro: null,
    sedi: [],
    dispositiviPresenti: []
};

export default class ApparatoVascolarePeriferico extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.apparatoVascolarePeriferico)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.apparatoVascolarePeriferico, this.props.apparatoVascolarePeriferico))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.apparatoVascolarePeriferico);
        if (dati.alterazioni == null) dati.alterazioni = [];
        this.setState({dati}, () => this.validazioneDati());
    }

    handleAggiornaAlterazione = (field, elem, index) => {
        this.setState(({dati}) => {
            if (field === "tipologiaDiAlterazione") {
                if (!elem) {
                    dati.alterazioni[index].tipologiaDiAlterazione = elem;
                    dati.alterazioni[index].sedi = []
                    dati.alterazioni[index].dispositiviPresenti = []
                } else {
                    dati.alterazioni[index].tipologiaDiAlterazione = elem;
                    dati.alterazioni[index].altro = null;
                }
            }
            if (field === "altro") {
                dati.alterazioni[index].altro = elem;
            }
            if (!this.showCondizioneRilevata(dati)) {
                dati.condizioneRilevata = null;
            }
            return {dati};
        }, this.validazioneDati);
    }


    handleAggiornaForm = (field, elem) => {
        this.setState(({dati}) => {
            dati[field] = elem;
            if (!this.showCondizioneRilevata(dati)) {
                dati.condizioneRilevata = null;
            }
            return {dati};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        let dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("apparatoVascolarePeriferico", dati);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneApparatoVascolarePeriferico", campiObbligatoriRimanenti);
    }

    renderTipoAlterazione = (alterazione, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'TIPO_ALT.APPARATOVASCOLAREPERIFERICO.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"selectTipologiaDiAlterazione"}
                        options={OggettiUtili.tipologiaAlterazioneApparatoVascolarePerifico}
                        value={alterazione.tipologiaDiAlterazione}
                        onChange={(elem) => this.handleAggiornaAlterazione("tipologiaDiAlterazione", elem, index)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                        fieldLabel={"Tipologia di alterazione"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        field={"tipologiaDiAlterazione"}
                    />
                    {alterazione.tipologiaDiAlterazione === "03" &&
                        <RoleBasedICD9Input
                            fieldId={'TIPO_ALT_ALTRO.APPARATOVASCOLAREPERIFERICO.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            onChange={(field, elem) => this.handleAggiornaAlterazione("altro", elem.target.value, index)}
                            value={alterazione.altro}
                            field={"altro"}
                            fieldLabel={"Altro"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                        />}
                </FieldsRow>
                {this.isAlterazioneValida(alterazione) &&
                    <>
                        <SectionList
                            title={'Sede'}
                            data={alterazione.sedi}
                            renderSection={(sede, indexSede) => this.renderSede(sede, indexSede, index)}
                            addNewSectionCallback={() => this.addNewSede(index)}
                            removeSectionCallback={(indexSede) => this.removeSede(indexSede, index)}
                            addButtonVisibilityHandler={(alterazione.sedi?.length === 0 || alterazione.sedi?.every(this.isSedeValida))}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            indentOffset={2}
                            field={'sedi'}
                            keyFieldId={'SEDE_ALT.APPARATOVASCOLAREPERIFERICO.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            parentJsonPath={"esameObiettivo.apparatoVascolarePeriferico.alterazioni[?(@.id == '" + alterazione?.id + "')].sedi"}
                        />
                        <DispositiviPresenti
                            handleArrayChange={(array, field) => this.handleDispositiviArrayChange(array, field, index)}
                            dispositiviPresenti={alterazione.dispositiviPresenti}
                            pageState={this.props.pageState}
                            fieldId={"DISPOSITIVI.APPARATOVASCOLAREPERIFERICO.ESAMEOBIETTIVO"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            indentOffset={2}
                            parentJsonPath={"esameObiettivo.apparatoVascolarePeriferico.alterazioni[?(@.id == '" + alterazione?.id + "')].dispositiviPresenti"}
                        />
                    </>}
            </>
        )
    }

    isSedeValida = (sede) => {
        return !Utils.isObjectNull(sede) && ((sede.codice !== "" && sede.descrizione !== null) || !Utils.isStringEmptyOrNullOrUndefined(sede));
    }

    addNewTipoAlterazione = () => {
        let dati = _.cloneDeep(this.state.dati);
        let alterazione = _.cloneDeep(defaultAlterazione);
        alterazione.id = uuid();
        dati.alterazioni.push(alterazione);
        this.setState({dati: dati}, () => this.validazioneDati(dati));
    }

    removeTipoAlterazione = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.alterazioni.splice(index, 1);
        if (!this.showCondizioneRilevata(dati)) {
            dati.condizioneRilevata = null;
        }
        this.setState({dati: dati}, () => this.validazioneDati(dati));
    }

    isAlterazioneValida = (alterazione) => {
        return !!alterazione?.tipologiaDiAlterazione;
    }

    renderSede = (sede, index, parentIndex) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'SEDE_ALT.APPARATOVASCOLAREPERIFERICO.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    id={"selectSede"}
                    options={OggettiUtili.sedeApparatoVascolarePerifico}
                    value={sede.codice}
                    onChange={(elem) => this.handleAggiornaSede(elem, index, parentIndex)}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Sede non esistente"}
                    fieldLabel={"Sede"}
                    handleOnlyValue={true}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    field={"codice"}
                />
            </FieldsRow>
        );
    }

    handleAggiornaSede = (elem, index, parentIndex) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.alterazioni[parentIndex].sedi[index].codice = elem;
        this.setState({dati: dati}, () => this.props.onChangeEsameObiettivo("apparatoVascolarePeriferico", dati));
    }

    addNewSede = (parentIndex) => {
        let dati = _.cloneDeep(this.state.dati);
        let sede = _.cloneDeep(defaultSede);
        sede.id = uuid();
        dati.alterazioni[parentIndex].sedi.push(sede);
        this.setState({dati: dati}, () => this.props.onChangeEsameObiettivo("apparatoVascolarePeriferico", dati));
    }

    removeSede = (index, parentIndex) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.alterazioni[parentIndex].sedi.splice(index, 1);
        this.setState({dati: dati}, () => this.validazioneDati(dati));
    }

    handleDispositiviArrayChange = (array, field, parentIndex) => {
        const data = _.cloneDeep(this.state.dati);
        const alterazione = data.alterazioni[parentIndex];
        if (alterazione) alterazione[field] = array;
        this.setState({dati: data}, () => {
            this.props.onChangeEsameObiettivo("apparatoVascolarePeriferico", data);
            this.forceUpdate();
        });
    }

    showCondizioneRilevata(dati) {
        return dati.polsi === false || (dati.alterazioni.length > 0 && dati.alterazioni?.every(e => !!e.tipologiaDiAlterazione))
    }

    renderBodySezione() {
        let dati = this.state.dati;
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'POLSI.APPARATOVASCOLAREPERIFERICO.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"selectPolsi"}
                        options={OggettiUtili.polsi}
                        value={dati.polsi}
                        onChange={(elem) => this.handleAggiornaForm("polsi", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                        fieldLabel={"Polsi"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        field={"polsi"}
                    />
                </FieldsRow>
                <SectionList
                    title={'Tipologia di alterazione'}
                    data={dati.alterazioni}
                    renderSection={this.renderTipoAlterazione}
                    addNewSectionCallback={this.addNewTipoAlterazione}
                    removeSectionCallback={this.removeTipoAlterazione}
                    addButtonVisibilityHandler={(dati.alterazioni?.length === 0 || dati.alterazioni?.every(this.isAlterazioneValida))}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    field={'alterazioni'}
                    keyFieldId={'TIPO_ALT.APPARATOVASCOLAREPERIFERICO.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    parentJsonPath={"esameObiettivo.apparatoVascolarePeriferico.alterazioni"}
                />
                <CondizioneRilevataSection
                    ambito={'APPARATO VASCOLARE PERIFERICO'}
                    parentFieldId={'APPARATOVASCOLAREPERIFERICO.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={dati.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={dati.daAttenzionare}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                    showSuggerimenti={true}
                    openCondition={this.showCondizioneRilevata(dati)}
                />
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"apparatoVascolarePeriferico"}
                    title={"Apparato vascolare periferico"}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.apparatoVascolarePeriferico.alterazioni",
                            "esameObiettivo.apparatoVascolarePeriferico.alterazioni.sedi",
                            "esameObiettivo.apparatoVascolarePeriferico.alterazioni.dispositiviPresenti",
                            "esameObiettivo.apparatoVascolarePeriferico.propostaAttivitaSuggerimenti"],
                        [defaultAlterazione, defaultSede, defaultDispositiviPresenti, defaultProposteAttivitaSuggerimenti]
                    )}
                    accordionReadOnly={this.props.accordionReadOnly}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}
ApparatoVascolarePeriferico.propTypes = propTypes;

ApparatoVascolarePeriferico.defaultProps = {
    showAccordion: false,
    forceReadOnly: false,
    disableDoubleInput: false,
    hideUnsetValues: false
}
