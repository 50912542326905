import React from 'react'

export const RoleBasedContext = React.createContext({
    forceReadOnly: false,
    hideUnsetValues: false,
    scrollToHead: false,
    forceUserRole: null,
    requestVersion: null,
    showOnlyRequiredFields: false
});
