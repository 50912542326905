import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import SessionRequest from "../../utils/SessionRequest";
import Utils, {closeModaleStyle} from "../../utils/Utils";
import InserimentoRichiesta from "../inserimentoRichiesta/InserimentoRichiesta";
import * as _ from "lodash";
import AccordionHelper from "../../service/AccordionHelper";
import WebSocketHelper from "../../websocket/WebSocketHelper";
import * as $ from "jquery";
import RichiestaADIService from "../../service/RichiestaADIService";
import ButtonHelper from "../../service/ButtonHelper";
import {map} from "rxjs/operators";
import {buildRichiesta, disableButton} from "../compilaRichiesta/CompilaRichiesta";
import {FieldsGroup, RoleBasedInput} from "../roleBasedComponents/RoleBasedComponents";
import VersionedAccordion from "../VersionedAccordion";
import BisogniPrestazioniAssistenziali
    from "../compilaProposte/bisogniPrestazioniAssistenziali/BisogniPrestazioniAssistenziali";
import ObiettiviClinicoAssistenziali
    from "../compilaProposte/obiettiviClinicoAssistenziali/ObiettiviClinicoAssistenziali";
import FigureProfessionaliPresaInCarico
    from "../compilaProposte/figureProfessionaliPresaInCarico/FigureProfessionaliPresaInCarico";
import LivelloPrioritaRichiesta from "../compilaProposte/livelloPriorita/LivelloPrioritaRichiesta";
import statiRichiesta from "../../utils/dataset/richiestaData/statiRichiesta.json";
import ProposteAttivitaSuggerimenti from "../compilaProposte/proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimenti";
import enumRichiesta from "../../enum/enumRichiesta.json";
import FigureProfessionaliSupportoAValutazione
    from "../compilaProposte/FigureProfessionaliSupportoAValutazione/FigureProfessionaliSupportoAValutazione";
import DataDimissioneProgrammataDaRicovero
    from "../compilaProposte/dataDimissioneProgrammataDaRicovero/DataDimissioneProgrammataDaRicovero";
import BisogniAllaDimissione from "../compilaProposte/bisogniAllaDimissione/BisogniAllaDimissione";
import DataDiPresaInCaricoTerritorialeDaDimissioneProtetta
    from "../compilaProposte/dataDiPresaInCaricoDaDimissioneProtetta/DataDiPresaInCaricoTerritorialeDaDimissioneProtetta";
import styles from "../compilaProposte/CompilaProposte.module.css";
import ModalHelper from "../modale/ModalHelper";
import routepath from "../../utils/route/route-path.json";
import enumUtente from "../../enum/enumsUtente.json";
import ScrollTop from "../scrollTop/ScrollTop";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import {Button, SessioneUtente} from "web-client-archetype";
import errors from "../../utils/errors/errors.json";
import {mostraSpinner, nascondiSpinner} from "../../App";
import {getChiudiPredialogoBodyForBe} from "./SchedaPazienteChiudiPreDialogo";
import enumPaginaCompila from "../../enum/enumPaginaCompila.json";
import ValutazioneInFormalizzazione
    from "../compilaFormalizzazione/caratteristicheSettingRichiesto/ValutazioneInFormalizzazione";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import enumTipoAvvisoSettingModale from "../../enum/enumTipoAvvisoSettingModale.json";
import {Redirect} from "react-router-dom";
import AuthUtils from "../../utils/AuthUtils";
import ButtonsBoxStick from "../buttonsBoxStick/buttonsBoxStick";
import UserHelper from "../../service/userHelper";

const propTypes = {
    richiesta: PropTypes.any,
    richiestaMedico: PropTypes.any,
    persone: PropTypes.object,
    pageState: PropTypes.string,
    pageName: PropTypes.string,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    firstPageName: PropTypes.string,
    openAccordion: PropTypes.bool,
    pageNumber: PropTypes.number,
    listaCodiciFiscaliUtentiOnline: PropTypes.array
}

export default class ProposteChiudiPreDialogo extends Component {

    state = {
        redirectToIndietro: false,
        redirectToVisualizzaRiepilogo: false,
        salvaBozzaSubscription: null,
        accordion: {
            openAccordionBisogniPrestazioniAssistenziali: false,
            openAccordionObiettiviClinicoAssistenziali: false,
            openAccordionFigureProfessionaliPresaInCarico: false,
            openAccordionProposteAttivitaSuggerimenti: false,
            openAccordionFigureProfessionaliSupportoAValutazione: false,
            openAccordionLivelloPriorita: false,
            openAccordionDataDimissioneProgrammataDaRicovero: false,
            openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta: false,
            openAccordionBisogniAllaDimissione: false,
            openAccordionValutazione: false
        },
        apriModaleAlert: false,
        // bool per modale successo
        isSettingRifiutato: false,
        // variabile di stato per la visualizzazione della modale
        tipoModaleVisualizzata: null,
        // motivazione rifiuto setting
        motivazione: null,

        esitoValutazione: null,

        gotoHome: false,

        formalizzazione: _.cloneDeep(this.props.richiesta.formalizzazione)
    }

    accordionData;
    accordionCompilaProposte;
    team;

    // oggetto utilizzato per controllare se abilitare o disabilitare il pulsante Prosegui
    obbligatorieta = {
        bisogniPrestazioniAssistenziali: null,
        figureProfessionaliPresaInCarico: null,
        proposteAttivitaSuggerimenti: null,
        figureProfessionaliSupportoAllaValutazione: null,
        dataDiPresaInCaricoTerritorialeDaDimissioneProtetta: null,
        livelloPriorita: null,
        dataDimissioneProgrammataDaRicovero: null,
        bisogniAllaDimissione: null,
        valutazione: null
    };

    constructor(props) {
        super(props);
        this.accordionData = SessionRequest.getSessionObjectFor(props.richiesta);
        this.updateFields();
        this.team = this.props.richiesta?.team ?? [];
    }

    componentDidMount() {
        this.setState({
            salvaBozzaSubscription: InserimentoRichiesta.salvaBozzaSubject.subscribe(() => this.prepareToSalvataggio(this.props.richiesta, false, enumRichiesta.stato.IN_CHIUSURA_PRE_DIALOGO))
        }, () => {
            InserimentoRichiesta.infoRichiestaSubject.next({
                idRichiesta: this.props.richiesta.id,
                anagrafePaziente: this.accordionData?.anagrafePaziente ?? null,
                situazioneSociale: this.accordionData?.situazioneSociale ?? null,
                richiedenteOrMcp: this.props.persone?.[Utils.recuperoIdProfessionista(this.props.richiesta)]
            });
        });

        if (this.props.openAccordion) {
            let accordion = _.cloneDeep(this.state.accordion);
            for (const singoloAccordion in accordion) {
                accordion[singoloAccordion] = true;
            }
            this.setState({accordion: accordion});
        } else {
            this.setState({accordion: AccordionHelper.initLastOpenedAccordionInAccordionObject(this.state.accordion, AccordionHelper.lastOpenedAccordion)}, () => WebSocketHelper.onMessageListener());
        }
    }

    componentWillUnmount() {
        if (this.state.salvaBozzaSubscription) this.state.salvaBozzaSubscription.unsubscribe();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.richiesta, this.props.richiesta)) {
            this.updateFields();
        }

        if (!_.isEqual(prevProps.richiesta.formalizzazione, this.props.richiesta.formalizzazione))
            this.setState({formalizzazione: this.props.richiesta.formalizzazione});

        if (this.props.hideUnsetValues) {
            $('div.box-accordion-body:empty').closest('div.box-accordion').remove();

            //Duplicato perchè prima vengono eliminati i sottoAccordion vuoti e poi i relativi Accordion vuoti
            $('div.collapse-body:empty').closest('div.collapse-div').remove();
            $('div.collapse-body:empty').closest('div.collapse-div').remove();
        }
    }

    updateFields() {
        let richiesta = this.props.richiesta;

        if (richiesta) {
            this.accordionData.bisogniPrestazioniAssistenziali = this.initObjectsForm("bisogniPrestazioniAssistenziali", richiesta, this.accordionData.bisogniPrestazioniAssistenziali) ?? [];
            this.accordionData.obiettiviClinicoAssistenziali = this.initObjectsForm("obiettiviClinicoAssistenziali", richiesta, this.accordionData.obiettiviClinicoAssistenziali);
            this.accordionData.figureProfessionaliPresaInCarico = this.initObjectsForm("figureProfessionaliPresaInCarico", richiesta, this.accordionData.figureProfessionaliPresaInCarico) ?? [];
            this.accordionData.valutazioneProfessionista = this.initObjectsForm("valutazioneProfessionista", richiesta, this.accordionData.valutazioneProfessionista);
            this.accordionData.proposteAttivitaSuggerimenti = this.initObjectsForm("proposteAttivitaSuggerimenti", richiesta, this.accordionData.proposteAttivitaSuggerimenti) ?? [];
            this.accordionData.professionistiSupportoAValutazione = this.initObjectsForm("professionistiSupportoAValutazione", richiesta, this.accordionData.professionistiSupportoAValutazione) ?? [];
            this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale = this.initObjectsForm("proposteAttivitaSuggerimentiAssistenteSociale", richiesta, this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale);
            this.initObjectsForm(this.accordionData.proposteAttivitaSuggerimentiNote, "proposteAttivitaSuggerimentiNote", richiesta, this.accordionData.proposteAttivitaSuggerimentiNote);
            this.accordionData.dataDimissioneProgrammataDaRicovero = this.initObjectsForm("dataDimissioneProgrammataDaRicovero", richiesta, this.accordionData.dataDimissioneProgrammataDaRicovero);
            this.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta = this.initObjectsForm("dataDiPresaInCaricoTerritorialeDaDimissioneProtetta", richiesta, this.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta);
            this.accordionData.bisogniAllaDimissione = this.initObjectsForm("bisogniAllaDimissione", richiesta, this.accordionData.bisogniAllaDimissione);
        }

        this.accordionCompilaProposte = {
            bisogniPrestazioniAssistenziali: _.cloneDeep(this.accordionData.bisogniPrestazioniAssistenziali),
            obiettiviClinicoAssistenziali: _.cloneDeep(this.accordionData.obiettiviClinicoAssistenziali),
            figureProfessionaliPresaInCarico: _.cloneDeep(this.accordionData.figureProfessionaliPresaInCarico),
            valutazioneProfessionista: _.cloneDeep(this.accordionData.valutazioneProfessionista),
            proposteAttivitaSuggerimenti: _.cloneDeep(this.accordionData.proposteAttivitaSuggerimenti),
            professionistiSupportoAValutazione: _.cloneDeep(this.accordionData.professionistiSupportoAValutazione),
            livelloPriorita: _.cloneDeep(richiesta.livelloPriorita),
            livelloPrioritaBozza: _.cloneDeep(richiesta.livelloPrioritaBozza),
            proposteAttivitaSuggerimentiAssistenteSociale: _.cloneDeep(this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale),
            proposteAttivitaSuggerimentiNote: _.cloneDeep(this.accordionData.proposteAttivitaSuggerimentiNote),
            dataDimissioneProgrammataDaRicovero: _.cloneDeep(this.accordionData.dataDimissioneProgrammataDaRicovero),
            dataDiPresaInCaricoTerritorialeDaDimissioneProtetta: _.cloneDeep(this.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta),
            bisogniAllaDimissione: _.cloneDeep(this.accordionData.bisogniAllaDimissione)

        }
    }

    initObjectsForm = (property, richiesta, defaultObject) => {
        let output;

        if (Object.prototype.hasOwnProperty.call(richiesta, property)) {
            if (Array.isArray(richiesta[property])) {
                output = [];
                output.push(...richiesta[property]);
            } else if (Object.prototype.toString.call(defaultObject) === '[object Object]') {
                output = _.cloneDeep(defaultObject);
                Object.keys(defaultObject).forEach(key => {
                    if (richiesta[property] != null && key in richiesta[property]) {
                        output[key] = richiesta[property][key];
                    } else {
                        output[key] = defaultObject[key];
                    }
                });
            } else output = defaultObject;
        } else output = defaultObject;

        return output;
    }

    chudiPreDialogo = (richiestaBody, esitoValutazione = this.state.esitoValutazione, isProcediBtn = false) => {
        const chiudiPreDialogoDto = getChiudiPredialogoBodyForBe(
            {..._.cloneDeep(richiestaBody), formalizzazione: this.state.formalizzazione},
            RichiestaADIService.richiestaRicercata,
            esitoValutazione,
            this
        );

        if (isProcediBtn) mostraSpinner();

        return RichiestaADIService.chiudiPreDialogo(richiestaBody.id, chiudiPreDialogoDto, ButtonHelper.clickSalvaButton)
            .pipe(map((response) => {
                if (isProcediBtn) nascondiSpinner();

                if (response?.msg === "ERRORE") {
                    if (response?.msgCode && response.msgCode.startsWith("WARNING_WD")) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ?? response?.status,
                            type: "confirm",
                            text: errors[response?.msgCode]?.message || response?.msgCode
                        });
                        //l'operazione è stata comunque eseguita, quindi proseguo come per un ok
                    } else if (response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_NON_INVIATO")) {
                        //non deve più farlo ma bisogna comunque gestire il caso
                        //this.setState({ pdfBtnDisabled: true });
                    } else if (response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_IN_ERRORE")) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ?? response?.status,
                            type: "critical",
                            text: "Errore nella creazione del CDA2"
                        });
                    } else if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ?? response?.status,
                            type: "critical",
                            text: response?.data?.errore ?? response?.data?.message
                        });
                    }
                }
                this.team = richiestaBody?.team ?? [];
            }));
    }


    prepareToSalvataggio = (richiesta, isProcediBtn = false, esitoValutazione = this.state.esitoValutazione) => {
        if (this.props.readOnly) {
            if (isProcediBtn) {
                this.setState({redirectToVisualizzaRiepilogo: true});
            }
            return;
        }

        this.accordionData = Object.assign(this.accordionData, this.accordionCompilaProposte);
        let richiestaClone = {
            ..._.cloneDeep(richiesta),
            team: this.team,
        };
        buildRichiesta(richiestaClone, this, this.props.pageName);
        let salva = this.chudiPreDialogo(richiestaClone, esitoValutazione, isProcediBtn);

        if (isProcediBtn) {
            salva.subscribe(() => this.setState({redirectToVisualizzaRiepilogo: true}));
        } else {
            salva.subscribe();
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        this.prepareToSalvataggio(this.props.richiesta, false, enumRichiesta.stato.IN_CHIUSURA_PRE_DIALOGO);
        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            });
            AccordionHelper.setLastOpenedAccordion(field);
            this.setState({accordion});
        }
    }

    handleChangeDati = (field, value) => {
        const split = field.split('|');
        if (split.length === 2) {
            this.accordionCompilaProposte[split[0]] = value[0];
            if (split[1] && split[1].startsWith('path')) {
                const propostaOtherAccordionModified = this.accordionCompilaProposte.proposteAttivitaSuggerimenti
                    .filter(e => e.firstPageAccordionPath === value[1])?.map(p => p?.propostaAttivitaSuggerimenti) ?? [];
                _.set(this.accordionData, value[1], propostaOtherAccordionModified);
            }
        } else {
            this.accordionCompilaProposte[field] = value;
        }
        this.forceUpdate();
    }

    handleChangeValutazione = (field, value) => {
        let formalizzazione = _.cloneDeep(this.state.formalizzazione);
        formalizzazione[field] = value;
        formalizzazione.nomeFormalizzatore = SessioneUtente.getInstance().nomeUtente;
        formalizzazione.cognomeFormalizzatore = SessioneUtente.getInstance().cognomeUtente;
        formalizzazione.codiceFiscaleFormalizzatore = SessioneUtente.getInstance()?.idUtente;
        this.setState({formalizzazione});
    }

    handleChangeObbligatorieta = (field, value) => {
        this.obbligatorieta[field] = value;
        let isButtonProseguiEnabled = this.obbligatorieta != null
            && Object.keys(this.obbligatorieta).every(key => this.obbligatorieta[key] !== null && this.obbligatorieta[key] === 0);
        disableButton("#proseguiBtn", !isButtonProseguiEnabled);
    }

    renderButtons() {
        let btnList = [
            {
                gropBtnList: [
                    {
                        id: "indietroBtn",
                        text: "Indietro",
                        className: "btnWhiteOutlineBorderGreen",
                        onclickFunction: () => this.setState({redirectToIndietro: true}),
                        isvisible: true,
                        aling:"start"
                    },
                    {
                        id: "rifiutaSettingPropostoBtn",
                        text: this.controlloProfilo() ? 'Rifiuta setting proposto' : 'Rifiuta setting proposto e firma',
                        className: "btnWhiteOutlineBorderGreen",
                        onclickFunction: () => this.setState({tipoModaleVisualizzata: enumTipoAvvisoSettingModale.tipoAvvisoSettingModale.RIFIUTA_SETTING_PROPOSTO}),
                        isvisible: !this.props.readOnly,
                        aling:"start"
                    }, 
                ],
                isvisible: true
            },
            {
                id: "salvaBozzaBtn",
                text: "Salva bozza",
                className: "btn btn-adi btnWhiteOutlineBorderGreen",
                onclickFunction: () => {
                    this.setState({esitoValutazione: enumRichiesta.stato.IN_CHIUSURA_PRE_DIALOGO}, () => {
                        ButtonHelper.setSalvaButtonFlag(true);
                        this.prepareToSalvataggio(this.props.richiesta, false, this.state.esitoValutazione);
                    });
                },
                isvisible: !this.props.readOnly,
                aling:"center"
            },
            {
                id: "proseguiBtn",
                text: "Prosegui",
                className: "btn btn-adi btnGreenOutline",
                onclickFunction: () => {
                    if (WebSocketHelper.valutazioneConfermataEvent !== null) this.apriModaleAlert();
                    else this.onClickProsegui();
                },
                isvisible: true,
                aling:"right"
            }
        ]

        return (

            <ButtonsBoxStick
                parentClass={!this.props.readOnly ? "row" : "d-flex justify-content-between"}
                btnList={btnList}
            />
        );
    }

    onClickProsegui() {
        this.prepareToSalvataggio(this.props.richiesta, true, enumRichiesta.stato.IN_CHIUSURA_PRE_DIALOGO);
    }

    countNotValuedFields() {
        let count = 0;
        if (this.state?.formalizzazione?.tipoValutazione == null) count++;
        if (Utils.isStringEmptyOrNullOrUndefined(this.state?.formalizzazione?.dataValutazione)) count++;

        this.handleChangeObbligatorieta("valutazione", count);

        return count;
    }

    renderAccordions = () => {
        return (
            <>
                {this.state.apriModaleAlert && this.renderModaleAlert()}

                <VersionedAccordion
                    accordion={LivelloPrioritaRichiesta}
                    professionistiSupportoAValutazione={this.accordionCompilaProposte.professionistiSupportoAValutazione}
                    dati={this.props.richiesta.stato === statiRichiesta.confermataPreDialogo.value || this.props.richiesta.stato === statiRichiesta.inChiusuraPreDialogo.value
                        ? this.accordionCompilaProposte.livelloPrioritaBozza
                        : this.accordionCompilaProposte.livelloPriorita}
                    figureProfessionali={this.accordionCompilaProposte.figureProfessionaliPresaInCarico}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvataggio}
                    openAccordion={this.state.accordion.openAccordionLivelloPriorita}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionLivelloPriorita"}
                    onChangeLivelloPriorita={this.handleChangeDati}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion
                    accordion={DataDimissioneProgrammataDaRicovero}
                    dati={this.accordionCompilaProposte.dataDimissioneProgrammataDaRicovero}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvataggio}
                    onChangeDatiDataDimissioneProgrammataDaRicovero={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionDataDimissioneProgrammataDaRicovero}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionDataDimissioneProgrammataDaRicovero"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={DataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                    dati={this.accordionCompilaProposte.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvataggio}
                    onChangeDatiDataDiPresaInCaricoTerritorialeDaDimissioneProtetta={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={BisogniPrestazioniAssistenziali}
                    dati={this.accordionCompilaProposte.bisogniPrestazioniAssistenziali ?? []}
                    onChangeBisogniPrestazioniAssistenziali={this.handleChangeDati}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvataggio}
                    openAccordion={this.state.accordion.openAccordionBisogniPrestazioniAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniPrestazioniAssistenziali"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={BisogniAllaDimissione}
                    dati={this.accordionCompilaProposte.bisogniAllaDimissione}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvataggio}
                    onChangeDatiBisogniAllaDimissione={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionBisogniAllaDimissione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniAllaDimissione"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={ObiettiviClinicoAssistenziali}
                    dati={this.accordionCompilaProposte.obiettiviClinicoAssistenziali}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvataggio}
                    onChangeDatiObiettiviClinicoAssistenziali={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionObiettiviClinicoAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionObiettiviClinicoAssistenziali"}
                    pageState={this.props.pageState}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliPresaInCarico}
                    dati={this.accordionCompilaProposte.figureProfessionaliPresaInCarico}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvataggio}
                    onChangeDatiFigureProfessionaliPresaInCarico={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliPresaInCarico}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliPresaInCarico"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={ProposteAttivitaSuggerimenti}
                    datiProposteAttivitaSuggerimenti={this.accordionCompilaProposte.proposteAttivitaSuggerimenti}
                    datiproposteAttivitaSuggerimentiAssistenteSociale={this.accordionCompilaProposte.proposteAttivitaSuggerimentiAssistenteSociale}
                    datiProposteAttivitaSuggerimentiNote={this.accordionCompilaProposte.proposteAttivitaSuggerimentiNote}
                    datiSituazioneSociale={this.accordionData.situazioneSociale}
                    datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                    datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                    datiInformazioniBase={this.accordionData.informazioniDiBase}
                    datiInformazioniBaseFaseRichiesta={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]}
                    datiInformazioniBaseFaseValutazione={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                    datiBenessereAbitativo={this.accordionData.benessereAbitativo}
                    datiEsameObiettivo={this.accordionData.esameObiettivo}
                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvataggio}
                    onChangeDatiProposteAttivitaSuggerimenti={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionProposteAttivitaSuggerimenti}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionProposteAttivitaSuggerimenti"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliSupportoAValutazione}
                    dati={this.accordionCompilaProposte.professionistiSupportoAValutazione}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvataggio}
                    onChangeDatiFigureProfessionaliSupportoAValutazione={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliSupportoAValutazione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliSupportoAValutazione"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <AccordionAvvioRichiesta
                    idAccordion={"valutazioneChiusuraPreDialogo"}
                    title={"Valutazione"}
                    openAccordion={this.state.accordion.openAccordionValutazione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionValutazione"}
                    pageState={this.props.pageState}
                    numberOfRequiredFieldsMissing={this.countNotValuedFields()}>
                    <VersionedAccordion
                        accordion={ValutazioneInFormalizzazione}
                        tipoValutazione={this.state.formalizzazione?.tipoValutazione}
                        dataValutazione={!Utils.isStringEmptyOrNullOrUndefined(this.state.formalizzazione?.dataValutazione)
                            ? this.state.formalizzazione.dataValutazione.match(/\d{4}-\d{2}-\d{2}/g)
                                ? Utils.formatDate(this.state?.formalizzazione.dataValutazione, "YYYY-MM-DD", "DD/MM/YYYY")
                                : this.state.formalizzazione.dataValutazione
                            : ''}
                        handleChangeCaratteristicheSettingRichiesto={this.handleChangeValutazione}
                        openAccordion={this.state.accordion.openAccordionValutazione}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionValutazione"}
                        pageState={this.props.pageState}
                    />
                </AccordionAvvioRichiesta>
            </>
        );
    }

    // Modale di alert al click del button "Prosegui"
    apriModaleAlert = () => {
        let showModale = WebSocketHelper.valutazioneConfermataEvent.idRichiesta === this.props.richiesta.id;
        this.setState({apriModaleAlert: showModale});
    }

    chiudiModaleAlert = () => {
        this.setState({apriModaleAlert: false, redirectToIndietro: false});
    }

    renderRowsSezioni = (listaSezioniModificate) => {
        let righeJSX = [];
        let arraySezioniModificate = listaSezioniModificate != null
            ? Array.from(listaSezioniModificate.split(";"))
            : ["nessuna sezione modificata"];

        let rigaJSX = <Fragment>
            {arraySezioniModificate?.map((nomeSezione, index) => {
                return (<tr key={index} className={index % 2 === 0 ? styles.rowTableGray : styles.rowTableWhite}>
                    <td>
                        <div className={'row ml-1 mb-1 mt-1 text-justify'}>
                            <div className={'col- span mb-1 mt-1 '}>
                                {nomeSezione}
                            </div>
                        </div>
                    </td>
                </tr>);
            })}

        </Fragment>
        righeJSX.push(rigaJSX);

        return (<Fragment>{righeJSX}</Fragment>);
    }

    renderModaleAlert = () => {
        let nominativoUtente = WebSocketHelper.valutazioneConfermataEvent?.nominativoUtente
        let isInfermiere = Utils.isRuoloInfermiere();

        const body = (
            <div className={"container vertical-scrollable"}>
                <div className={"row"}>
                    <div className={"text-center py-2"}>
                         <span>
                             <p>
                                {isInfermiere === "P_INFERMIERE" ? "L'infermiere" : "Il medico"} {nominativoUtente} ha confermato la propria valutazione professionale, indicando nella sua valutazione dati diversi da quelli da te inseriti.
                             </p>
                             <p>
                                 {"Puoi visualizzare il dato inserito, nello \"Storico modifiche\" di ciascun campo selezionando la relativa icona. Le sezioni modificate sono le seguenti:"}
                             </p>
                         </span>
                    </div>
                </div>
                <div className={"table-responsive px-2 mt-4 mb-4"}>
                    <table className={"table table-striped table-sm mx-auto w-50"}>
                        <th className="col-span p-2 pr-md-3 text-white" style={{background: "#333333"}}> Sezioni modificate</th>
                        <tbody>
                        {this.renderRowsSezioni(WebSocketHelper.valutazioneConfermataEvent?.listaSezioniModificate)}
                        </tbody>
                    </table>
                </div>
            </div>
        );

        const footer = (
            <>
                <div className={"mx-auto w-auto"}>
                    <Button
                        id={"chiudiBtn"}
                        text={"Ho preso atto"}
                        className={"btn btn-adi btnGreenOutline"}
                        onClick={() => {
                            this.setState({apriModaleAlert: false}, () => this.onClickProsegui());
                            closeModaleStyle();
                            //Si rimanda a caso d’uso UC_04_15_0L: Conferma valutazione: Professionista diverso da MMG
                        }}/>
                </div>
            </>
        );

        return (
            <>
                <ModalHelper
                    id={"modaleAlert"}
                    title={"Attenzione!"}
                    titleClassName={"modal-header text-center"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered"}
                    contentClassName={("modal-content px-3 py-1 text-center")}
                    body={body}
                    footer={footer}
                    footerClassName={"modal-footer justify-content-around"}
                    closePopupFunction={() => this.chiudiModaleAlert()}
                />
            </>
        );
    }

    renderModaleSuccesso = () => {
        const footerModaleSettingRifiutato = (
            <>
                <Button
                    onClick={() => this.downloadPdf()}
                    id={"visualizzaPdfBtn"}
                    text="Visualizza Pdf"
                    className={"btn btn-adi btnGreenOutline"}
                />
                <Button
                    onClick={() => this.setState({gotoHome: true})}
                    id={"tornaHomeBtn"}
                    text="Torna a Home"
                    dataDismiss={"modal"}
                    className={("btn btn-adi btnWhiteOutlineBorderGreen")}
                />
            </>
        );

        return (
            <>
                <ModalHelper
                    id={"operazioneCompletataModal"}
                    title={"Operazione completata"}
                    body={"La richiesta e il relativo setting sono stati rifiutati"}
                    footer={footerModaleSettingRifiutato}
                    titleClassName={"modal-header d-inline text-center backgroundGreen"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={"modal-content"}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={"modal-body d-flex align-items-center justify-content-around f-weight-600"}
                />
            </>
        );
    }

    downloadPdf = () => {
        let uuidRichiesta = this.props.richiesta?.id;
        RichiestaADIService.generaPdf(uuidRichiesta, "STEP_CDA2_CONFERMA_SETTING").subscribe((response) => {
            if (response?.msg === "ERRORE") {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "confirm",
                    text: "Il fascicolo sanitario elettronico non è al momento disponibile. Si prega di riprovare più tardi"
                });
            } else {
                const linkSource = `data:${response.data.contentType};base64,${response.data.file}`;
                const downloadLink = document.createElement("a");
                const fileName = response.data.fileName;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        });
    }

    controlloProfilo = () => {
        const isUtenteCentraleAdi = _.some(this.state?.richiesta?.team, t => SessioneUtente.getInstance().idUtente === t.professionista?.codiceFiscale && t.profilo === enumUtente.profiliUtente.centraleAdi);
    }

    renderModaleRifiutaSetting = () => {
        const titleRifiutaSetting = (
            <Fragment>
                <span className={"modal-title"}>Rifiuta setting proposto</span>
            </Fragment>
        );

        const bodyRifiutaSetting = <Fragment>
            <div className={"row"}>
                <div className={"col"}>
                    <FieldsGroup >
                        <RoleBasedInput
                            id={"motivazione"}
                            fieldId={"MOTIVAZIONE.VALUTAZIONECAMBIOSETTING.PROGETTOASSISTENZIALERICHIESTO"}
                            pageState={this.props.pageState}
                            parentClass={"mb-0"}
                            value={this.state.motivazione}
                            placeholder={"Inserisci motivazione"}
                            field={"motivazione"}
                            onChange={(field, elem) => this.setState({[field]: elem.target.value})}
                            type={"textarea"}
                            forceReadOnly={false}
                            hideUnsetValues={false}
                            disableDoubleInput={false}
                            maxlength={500}
                            showAvailableCharsLabel={true}
                            fieldRequired={true}
                            fieldLabel={"Motivazione"}
                            rows={"5"}
                        />
                    </FieldsGroup>
                </div>
            </div>
        </Fragment>

        const footerRifiutaSetting = (
            <Fragment>
                <Button
                    id={"conferma"}
                    text={this.controlloProfilo() ? 'Rifiuta setting' : 'Rifiuta setting e firma' }
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => this.setState({
                        esitoValutazione: enumRichiesta.stato.RIFIUTATA,
                        isSettingRifiutato: true
                    },
                        () => this.prepareToSalvataggio())}
                    disabled={Utils.isStringEmptyOrNullOrUndefined(this.state.motivazione)}
                />

                <Button
                    id={"annulla"}
                    text={"Annulla"}
                    dataDismiss={"modal"}
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    onClick={() => this.setState({motivazione: null}, () => this.setState({tipoModaleVisualizzata: null}))}
                />
            </Fragment>
        );

        return (
            <ModalHelper id={"modaleAvvisoSetting"}
                         title={titleRifiutaSetting}
                         body={bodyRifiutaSetting}
                         footer={footerRifiutaSetting}
                         titleClassName={"modal-header d-inline text-center backgroundOrange"}
                         containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                         contentClassName={"modal-content"}
                         footerClassName={"modal-footer justify-content-around"}
                         bodyClassName={"modal-body align-items-center f-weight-600"}
            />)
    }

    render() {
        AccordionHelper.accordionPageSubject.next({position: 2, title: "Chiudi Pre-Dialogo: Proposte (2 d 3)"});

        let baseUrl = routepath.compila_chiusura_predialogo;
        let richiesta = _.cloneDeep(this.props.richiesta);
        if (this.state.redirectToIndietro) {
            buildRichiesta(richiesta, this, this.props.pageName, false);
            return (
                <Redirect to={{
                    pathname: baseUrl.concat(routepath.compila_scheda_paziente),
                    uuidRichiesta: this.props.richiesta.id,
                    pageState: this.props.pageState,
                    readOnly: this.props.readOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    pageName: enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE,
                    firstPageName: this.props.firstPageName,
                    richiesta: richiesta,
                    richiestaMedico: this.props.richiestaMedico,
                    persone: this.props.persone,
                    tipologia: richiesta?.tipologia,
                    stato: richiesta?.stato,
                    pageNumber: 1
                }}/>
            );
        } else if (this.state.redirectToVisualizzaRiepilogo) {
            buildRichiesta(richiesta, this, this.props.pageName, true);
            return (
                <Redirect to={{
                    pathname: baseUrl.concat(routepath.visualizza_riepilogo),
                    uuidRichiesta: this.props.richiesta.id,
                    richiesta: richiesta,
                    richiestaMedico: this.props.richiestaMedico,
                    pageState: this.props.pageState,
                    readOnly: this.props.readOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    pageName: enumPaginaCompila.VISUALIZZA_RIEPILOGO,
                    firstPageName: this.props.firstPageName,
                    tipologia: this.props.richiesta?.tipologia,
                    stato: this.props.richiesta?.stato,
                    pageNumber: 3
                }}/>
            );
        }

        if (this.state.gotoHome) return <Redirect to={{pathname: routepath.home}}/>;

        const isStateValutazione = Utils.isStateValutazione(this.props.pageState);
        const forceUserRole = isStateValutazione && this.props.richiesta.sostituzioneRichiedente
        && (AuthUtils.hasUtenteCentraleAdi() || AuthUtils.hasUtenteRuoloMcp()) ? enumUtente.ruoli.mmg : null;

        UserHelper.setForceUserRole(forceUserRole);

        const {accordion} = this.state;
        return (
            <>
                {((!this.props.openAccordion && !Object.values(accordion).includes(true)) || (this.props.openAccordion)) &&
                    <ScrollTop scrollInit={true} conAnimazione={false}/>}
                {this.state.tipoModaleVisualizzata && this.renderModaleRifiutaSetting()}
                {this.state.isSettingRifiutato && this.renderModaleSuccesso()}
                <div id={"compilaProposteId"}>
                    <RoleBasedContext.Provider
                        value={{
                            forceReadOnly: this.props.readOnly,
                            hideUnsetValues: this.props.hideUnsetValues,
                            scrollToHead: this.props.openAccordion,
                            forceUserRole: forceUserRole,
                            requestVersion: this.props.richiesta?.versione,
                            showOnlyRequiredFields: false
                        }}>
                        <div className="mx-5">{this.renderAccordions()}</div>
                    </RoleBasedContext.Provider>
                </div>
                {!this.props.readOnly && this.renderButtons()}
            </>
        );
    }
}

ProposteChiudiPreDialogo.propTypes = propTypes;
ProposteChiudiPreDialogo.defaultProps = {
    openAccordion: false
}
