import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {v4 as uuid} from "uuid";
import OggettiUtili from "../../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../../roleBasedComponents/RoleBasedComponents";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import CondizioneRilevataSection from "../../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import Box from "../../../generalComponents/box/Box";
import AccordionSecondLevel from "../../../accordionSezioneRichiesta/AccordionSecondLevel";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import Statistica from "../../../statistica/Statistica";
import enumQuestionario from "../../../../enum/enumQuestionario.json";
import Utils from "../../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco, defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import {isArray} from "lodash";
import {connectableObservableDescriptor} from "rxjs/internal/observable/ConnectableObservable";
import {Key} from "react-bootstrap-icons";

const propTypes = {
    torace: PropTypes.any,
    respiro: PropTypes.any,
    onChangeDatiRespiro: PropTypes.func,
    onChangeEsameObiettivo: PropTypes.func,
    showSectionMurme: PropTypes.bool,
    accordionTitle: PropTypes.string,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultAlterazine = {
    id: null,
    tipoAlterazione: null,
    sede: null
}

const defaultRumore = {
    id: null,
    rumore: null,
    sede: null
}

const defaultEspettorazioniAspetto = {
    id: null,
    aspetto: null
}

const defaultTipologia = {
    id: null,
    tipologia: null,
    altro: null
}

const nomeAccordion = "VALUTAZIONESISTEMARESPIRATORIO";

export default class ValutazioneSistemaRespiratorio extends AccordionSecondLevel {
    state = {
        datiTorace: _.cloneDeep(this.props.torace),
        datiRespiro: _.cloneDeep(this.props.respiro)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.torace, this.props.torace) || !_.isEqual(prevProps.respiro, this.props.respiro)) {
            this.inizializzaForm();
        }
            
    }

    inizializzaForm = () => {
        let datiTorace = _.cloneDeep(this.props.torace);
        let datiRespiro = _.cloneDeep(this.props.respiro);
        this.setState({datiTorace: datiTorace, datiRespiro: datiRespiro}, () => {
            this.validazioneDatiTorace();
            this.validazioneDatiRespiro();
        });
    }

    handleAggiornaFormTorace = (field, elem, index = null, subField = null) => {
        this.setState(({datiTorace}) => {
            if (index === null && subField === null) {
                datiTorace[field] = elem;
            } else {
                datiTorace[field][index][subField] = elem;
            }

            /* Alterazioni */
            if (!datiTorace.alterazione) {
                datiTorace.listaAlterazioni = [];
                datiTorace.alterazioneCondizioneRilevata = null;
            }

            /* Dispnea */
            if (!datiTorace.dispnea) {
                datiTorace.dispneaCondizioneRilevata = null;
            }

            /* Murmure */
            if (datiTorace.murmureVescicolare !== "02") {
                datiTorace.murmureVescicolareAlterazione = null;
            }
            if (datiTorace.murmureVescicolare !== "02" && datiTorace.listaRumori?.length === 0) {
                datiTorace.murmureVescicolareCondizioneRilevata = null;
            }

            /* Percussione */
            if (datiTorace.percussione !== "02") {
                datiTorace.percussioneCondizioneRilevata = null;
            }

            /* Espettorazioni */
            if (!datiTorace.espettorazioni) {
                datiTorace.espettorazioniAspetto = [];
                datiTorace.espettorazioniQuantita = null;
                datiTorace.espettorazioniCondizioneRilevata = null;
            }

            /* Spo2 */
            if (parseInt(datiTorace.spo2, 10) >= 90) {
                datiTorace.spo2CondizioneRilevata = null;
            }

            return {datiTorace};
        }, this.validazioneDatiTorace);
    }

    handleAggiornaFormRespiro = (field, elem, index = null, subField = null) => {
        this.setState(({datiRespiro}) => {
            if (index === null && subField === null) {
                datiRespiro[field] = elem;
            } else {
                datiRespiro[field][index][subField] = elem;
                if (datiRespiro.tipologia[index]?.tipologia !== "06") {
                    datiRespiro.tipologia[index].altro = null;
                }
            }

            if (!datiRespiro.presenzaAlterazioni) {
                datiRespiro.tipologia = [];
            }

            if (!this.checkPresenzaDispnea(datiRespiro.tipologia)) {
                datiRespiro.mMRC = null;
                datiRespiro.condizioneRilevataDispnea = null;
            }

            if (!datiRespiro.tosse) {
                datiRespiro.tipologiaTosse = null;
                datiRespiro.condizioneRilevataTosse = null;
            }

            return {datiRespiro};
        }, this.validazioneDatiRespiro)
    }

    /* Tipologia */

    addNewTipologia = () => {
        let datiRespiro = _.cloneDeep(this.state.datiRespiro);
        let type = _.cloneDeep(defaultTipologia);
        type.id = uuid();
        datiRespiro.tipologia.push(type);
        this.setState({datiRespiro}, () => this.validazioneDatiRespiro());
    }

    removeTipologia = (index) => {
        let datiRespiro = _.cloneDeep(this.state.datiRespiro);
        datiRespiro.tipologia.splice(index, 1);
        if (!this.checkPresenzaDispnea(datiRespiro.tipologia)) {
            datiRespiro.mMRC = null;
            datiRespiro.condizioneRilevataDispnea = null;
        }
        this.setState({datiRespiro}, () => this.validazioneDatiRespiro());
    }

    renderTipologia = (tipologia, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'M_TIPO_DISPNEA.RESPIRO.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={'I_DISPNEA.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                    pageState={this.props.pageState}
                    id={"tipologia" + index}
                    options={OggettiUtili.tipologiaRespiro}
                    value={tipologia.tipologia}
                    onChange={(elem) => this.handleAggiornaFormRespiro("tipologia", elem, index, "tipologia")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Tipologia respiro non esistente"}
                    fieldLabel={'Tipologia'}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    handleOnlyValue={true}
                    field={"tipologia"}
                />

                {tipologia.tipologia === "06" &&
                    <RoleBasedInput
                        fieldId={'M_TIPO_DISPNEA.RESPIRO.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_DISPNEA.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        placeholder={"Altro, specificare"}
                        field={"altro"}
                        onChange={(field, elem) => this.handleAggiornaFormRespiro("tipologia", elem.target.value, index, "altro")}
                        id={"altro" + index}
                        value={tipologia.altro}
                        fieldLabel={"Altro"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />}

            </FieldsRow>
        );
    }

    checkPresenzaDispnea(tipologie) {
        return tipologie?.some(t => (t.tipologia === "01" || t.tipologia === "02"
            || t.tipologia === "03" || t.tipologia === "04")) ?? true
    }

    validazioneDatiTorace = () => {
        let datiTorace = _.cloneDeep(this.state.datiTorace);
        this.props.onChangeEsameObiettivo("torace", datiTorace);
        this.countCampiObbligatoriRimanentiAndValida();
    }

    validazioneDatiRespiro = () => {
        let datiRespiro = _.cloneDeep(this.state.datiRespiro);
        this.props.onChangeDatiRespiro("datiRespiro", datiRespiro);
        this.countCampiObbligatoriRimanentiAndValida();
    }

    countCampiObbligatoriRimanentiAndValida = () => {
        let proposteAttEsugg = [];
        let campiObbligatoriRimanenti = 0;
        Object.entries({..._.cloneDeep(this.state.datiTorace), ..._.cloneDeep(this.state.datiRespiro)}).forEach(([key, value]) => {
            if ((key.includes("PropostaAttivitaSuggerimenti") || key.includes("propostaAttivitaSuggerimenti") || key.includes("propostaAttivitaSuggerimentiTosse") || key.includes("dispneaPropostaAttivitaSuggerimenti") || key.includes("alterazionePropostaAttivitaSuggerimenti")) && isArray(value)){
                value.forEach(element => {
                    proposteAttEsugg.push(element)
                });
            }
        });
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(proposteAttEsugg)
        this.props.onChangeRequiredFieldsNumber("sottosezioneValutazioneSistemaRespiratorio", campiObbligatoriRimanenti);
    }

    /* Alterazioni */

    addNewAlterazione = () => {
        let datiTorace = _.cloneDeep(this.state.datiTorace);
        let type = _.cloneDeep(defaultAlterazine);
        type.id = uuid();
        datiTorace.listaAlterazioni.push(type);
        this.setState({datiTorace: datiTorace}, () => this.validazioneDatiTorace());
    }

    removeAlterazione = (index) => {
        let datiTorace = _.cloneDeep(this.state.datiTorace);
        datiTorace.listaAlterazioni.splice(index, 1);
        this.setState({datiTorace: datiTorace}, () => this.validazioneDatiTorace());
    }

    renderAlterazione = (alterazione, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedICD9Input
                        fieldId={'M_TIPO_ALT.TORACE.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_TIPO_ALT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        onChange={(field, elem) => this.handleAggiornaFormTorace("listaAlterazioni", elem.target.value, index, field)}
                        value={alterazione.tipoAlterazione}
                        field={"tipoAlterazione"}
                        fieldLabel={"Tipo di alterazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                    <RoleBasedInput
                        fieldId={'M_SEDE_ALT.TORACE.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_SEDE_ALT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci sede"}
                        field={"sede"}
                        onChange={(field, elem) => this.handleAggiornaFormTorace("listaAlterazioni", elem.target.value, index, field)}
                        id={"inputAlterazioneSede"}
                        value={alterazione.sede}
                        fieldLabel={"Sede/i"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                </FieldsRow>
            </>
        );
    }

    /* Murmure */

    addNewRumore = () => {
        let datiTorace = _.cloneDeep(this.state.datiTorace);
        let type = _.cloneDeep(defaultRumore);
        type.id = uuid();
        datiTorace.listaRumori.push(type);
        this.setState({datiTorace: datiTorace}, () => this.validazioneDatiTorace());
    }

    removeRumore = (index) => {
        let datiTorace = _.cloneDeep(this.state.datiTorace);
        datiTorace.listaRumori.splice(index, 1);
        if (datiTorace.murmureVescicolare !== "02" && datiTorace.listaRumori?.length === 0) {
            datiTorace.murmureVescicolareCondizioneRilevata = null;
            datiTorace.murmureVescicolareDaAttenzionare = null;
        }
        this.setState({datiTorace: datiTorace}, () => this.validazioneDatiTorace());
    }

    renderRumore = (rumore, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_RUM_AGG.TORACE.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_RUM_AGG.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        id={"selectRumore" + index}
                        options={OggettiUtili.rumoriAggiunti}
                        value={rumore.rumore}
                        onChange={(elem) => this.handleAggiornaFormTorace("listaRumori", elem, index, "rumore")}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Rumore non esistente"}
                        fieldLabel={"Rumori aggiunti"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"rumore"}
                    />
                    <RoleBasedInput
                        fieldId={'M_SEDE_MURM.TORACE.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_SEDE_MURM.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci sede/i"}
                        field={"sede"}
                        onChange={(field, elem) => this.handleAggiornaFormTorace("listaRumori", elem.target.value, index, "sede")}
                        id={"murmureVescicolareSede" + index}
                        value={rumore.sede}
                        fieldLabel={"Sede/i"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                </FieldsRow>
            </>
        );
    }

    /* Espettorazioni aspetto */

    addNewEspettorazioniAspetto = () => {
        let datiTorace = _.cloneDeep(this.state.datiTorace);
        let type = _.cloneDeep(defaultEspettorazioniAspetto);
        type.id = uuid();
        datiTorace.espettorazioniAspetto.push(type);
        this.setState({datiTorace: datiTorace}, () => this.validazioneDatiTorace());
    }

    removeEspettorazioniAspetto = (index) => {
        let datiTorace = _.cloneDeep(this.state.datiTorace);
        datiTorace.espettorazioniAspetto.splice(index, 1);
        this.setState({datiTorace: datiTorace}, () => this.validazioneDatiTorace());
    }

    renderEspettorazioniAspetto = (espettorazione, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_ESPET_SECR_BRONCH_ASP.TORACE.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_ESPET_SECR_BRONCH_ASP.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        id={"selectEspettorazioniAspetto"}
                        options={OggettiUtili.espettorazioniAspetto}
                        value={espettorazione.aspetto}
                        onChange={(elem) => this.handleAggiornaFormTorace("espettorazioniAspetto", elem, index, "aspetto")}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Aspetto non esistente"}
                        fieldLabel={"Espettorazioni/Secrezioni bronchiali - Aspetto"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"aspetto"}
                    />
                </FieldsRow>
            </>
        );
    }

    handleDispositiviArrayChange = (array, field) => {
        let data = _.cloneDeep(this.state.datiTorace);
        data[field] = array;
        this.setState({datiTorace: data}, () => {
            this.validazioneDatiTorace();
            this.forceUpdate();
        });
    }

    renderBodySezione() {
        let datiTorace = this.state.datiTorace;
        let datiRespiro = this.state.datiRespiro;

        return (
            <>
                { /* Alterazioni - Torace */
                    <Box key={"boxAlterazioni" + nomeAccordion} titleBox={"Alterazioni"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_PRES_ALT.TORACE.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_PRES_ALT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                pageState={this.props.pageState}
                                id={"selectAlterazione"}
                                options={OggettiUtili.rispostaBreve}
                                value={datiTorace.alterazione}
                                onChange={(elem) => this.handleAggiornaFormTorace("alterazione", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Alterazione non esistente"}
                                fieldLabel={"Presenza di alterazioni"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"alterazione"}
                            />
                        </FieldsRow>

                        {datiTorace.alterazione &&
                            <SectionList
                                title={'Alterazione'}
                                data={datiTorace.listaAlterazioni}
                                renderSection={this.renderAlterazione}
                                addNewSectionCallback={this.addNewAlterazione}
                                removeSectionCallback={this.removeAlterazione}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                pageState={this.props.pageState}
                                keyFieldId={'M_TIPO_ALT.TORACE.ESAMEOBIETTIVO'}
                                infermiereKeyFieldId={'I_TIPO_ALT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                field={'listaAlterazioni'}
                                parentJsonPath={"esameObiettivo.torace.listaAlterazioni"}
                            />}

                        <DispositiviPresenti
                            handleArrayChange={this.handleDispositiviArrayChange}
                            dispositiviPresenti={datiTorace.listaDispositiviAlterazioni}
                            pageState={this.props.pageState}
                            fieldId={'M_DISP_ALT.TORACE.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_DISP_ALT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            field={"listaDispositiviAlterazioni"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            parentJsonPath={"esameObiettivo.torace.listaDispositiviAlterazioni"}
                        />

                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_FREQ_RESP_ALT.TORACE.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_FREQ_RESP_ALT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci frequenza respiratoria"}
                                field={"alterazioneFrequenzaRespiratoria"}
                                onChange={(field, elem) => this.handleAggiornaFormTorace(field, elem.target.value)}
                                id={"inputAlterazioneFrequenzaRespiratoria"}
                                value={datiTorace.alterazioneFrequenzaRespiratoria}
                                fieldLabel={"Frequenza respiratoria (Atti/min)"}
                                type={"number"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>

                        <CondizioneRilevataSection
                            ambito={'TORACE'}
                            appendRolePrefix={true}
                            fieldsSuffix={'ALT'}
                            parentFieldId={'TORACE.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormTorace("alterazioneCondizioneRilevata", elem)}
                            valueCondizioneRilevata={datiTorace.alterazioneCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormTorace("alterazioneDaAttenzionare", elem)}
                            valueDaAttenzionare={datiTorace.alterazioneDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={"alterazione"}
                            openCondition={datiTorace.alterazione}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormTorace("alterazionePropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={datiTorace.alterazionePropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                        />
                    </Box>
                }

                { /* Dispnea - Respiro */
                    <Box key={"boxDispnea" + nomeAccordion} titleBox={"Dispnea"}>
                        {datiRespiro.presenzaAlterazioni &&
                            <SectionList
                                title={'Tipologia'}
                                data={datiRespiro.tipologia}
                                renderSection={this.renderTipologia}
                                addNewSectionCallback={this.addNewTipologia}
                                removeSectionCallback={this.removeTipologia}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                pageState={this.props.pageState}
                                keyFieldId={'M_TIPO_DISPNEA.RESPIRO.ANAMNESIFISIOLOGICA'}
                                infermiereKeyFieldId={'I_DISPNEA.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                addButtonVisibilityHandler={!datiRespiro.tipologia?.some(t => !t.tipologia) ?? true}
                                field={'tipologia'}
                                parentJsonPath={"anamnesiFisiologica.datiRespiro.tipologia"}
                            />}

                        {this.checkPresenzaDispnea(datiRespiro.tipologia) &&
                            <Statistica
                                fieldId={'M_PUNT_mMRC.RESPIRO.ANAMNESIFISIOLOGICA'}
                                infermiereFieldId={'I_PUNT_mMRC.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                pageState={this.props.pageState}
                                nomeLink={"Compila Test mMRC"}
                                nomePunteggio={"Punteggio Test mMRC"}
                                questionario={datiRespiro.mMRC}
                                profileName={'Livello Dispnea'}
                                profileFieldId={'M_LIV_DISPNEA.RESPIRO.ANAMNESIFISIOLOGICA'}
                                profileInfermiereFieldId={'I_LIV_DISPNEA.SISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                onChange={(questionario) => this.handleAggiornaFormRespiro(enumQuestionario.tipoQuestionario.mMRC, questionario)}
                                richiesta={this.props.richiesta}
                                pathRichiestaQuestionario={"anamnesiFisiologica.datiRespiro.mMRC"}
                                tipoQuestionario={enumQuestionario.tipoQuestionario.mMRC}
                                profileRequired={false}
                                salvaBozza={this.props.salvaBozza}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />}

                        <CondizioneRilevataSection
                            ambito={'VALUTAZIONESISTEMARESPIRATORIO_DISPNEA'}
                            parentFieldId={'RESPIRO.ANAMNESIFISIOLOGICA'}
                            fieldsSuffix={'DISPNEA'}
                            infParentFieldId={'SISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            appendRolePrefix={true}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormRespiro("condizioneRilevataDispnea", elem)}
                            valueCondizioneRilevata={datiRespiro.condizioneRilevataDispnea}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormRespiro("daAttenzionareDispnea", elem)}
                            valueDaAttenzionare={datiRespiro.daAttenzionareDispnea}
                            showSuggerimenti={true}
                            valueSuggerimenti={datiRespiro.propostaAttivitaSuggerimentiDispnea}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormRespiro("propostaAttivitaSuggerimentiDispnea", elem)}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            showAutonomia={true}
                            onChangeAutonomiaPaziente={(elem) => this.handleAggiornaFormRespiro("autonomiaGestionePazienteDispnea", elem)}
                            valueAutonomiaPaziente={datiRespiro.autonomiaGestionePazienteDispnea}
                            onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaFormRespiro("autonomiaGestioneCaregiverDispnea", elem)}
                            valueAutonomiaCaregiver={datiRespiro.autonomiaGestioneCaregiverDispnea}
                            fieldJsonPathSuffix={"dispnea"}
                            openCondition={this.checkPresenzaDispnea(datiRespiro.tipologia)}
                        />
                    </Box>
                }

                { /* Murmure - Torace */
                    <Box key={"boxMurmure" + nomeAccordion} titleBox={"Murmure vescicolare"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_MURM_VESC.TORACE.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_MURM_VESC.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                pageState={this.props.pageState}
                                id={"selectMurmureVescicolare"}
                                options={OggettiUtili.murmureVescicolare}
                                value={datiTorace.murmureVescicolare}
                                onChange={(elem) => this.handleAggiornaFormTorace("murmureVescicolare", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Murmure vescicolare non esistente"}
                                fieldLabel={"Presenza di murmure vescicolare"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"murmureVescicolare"}
                            />
                            {datiTorace.murmureVescicolare === "02" &&
                                <RoleBasedInput
                                    fieldId={'M_MURM_VESC_ALTERAZIONE.TORACE.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_MURM_VESC_ALTERAZIONE.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci alterazione"}
                                    field={"murmureVescicolareAlterazione"}
                                    onChange={(field, elem) => this.handleAggiornaFormTorace(field, elem.target.value)}
                                    id={"inputAlterazioneFrequenzaRespiratoria"}
                                    value={datiTorace.murmureVescicolareAlterazione}
                                    fieldLabel={"Alterazione"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />}
                        </FieldsRow>

                        <SectionList
                            title={'Rumore'}
                            data={datiTorace.listaRumori}
                            renderSection={this.renderRumore}
                            addNewSectionCallback={this.addNewRumore}
                            removeSectionCallback={this.removeRumore}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            pageState={this.props.pageState}
                            keyFieldId={'M_RUM_AGG.TORACE.ESAMEOBIETTIVO'}
                            infermiereKeyFieldId={'I_RUM_AGG.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            field={'listaRumori'}
                            parentJsonPath={"esameObiettivo.torace.listaRumori"}
                        />

                        <CondizioneRilevataSection
                            ambito={'TORACE'}
                            appendRolePrefix={true}
                            fieldsSuffix={'MURM'}
                            parentFieldId={'TORACE.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormTorace("murmureVescicolareCondizioneRilevata", elem)}
                            valueCondizioneRilevata={datiTorace.murmureVescicolareCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormTorace("murmureVescicolareDaAttenzionare", elem)}
                            valueDaAttenzionare={datiTorace.murmureVescicolareDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={"murmureVescicolare"}
                            openCondition={(datiTorace.murmureVescicolare === "02" || datiTorace.listaRumori?.length > 0)}
                        />
                    </Box>
                }

                { /* Tosse - Respiro */
                    <Box key={"boxTosse" + nomeAccordion} titleBox={"Tosse"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_TOSSE.RESPIRO.ANAMNESIFISIOLOGICA'}
                                infermiereFieldId={'I_TOSSE.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                pageState={this.props.pageState}
                                id={"tosse"}
                                options={OggettiUtili.rispostaBreve}
                                value={datiRespiro.tosse}
                                onChange={(elem) => this.handleAggiornaFormRespiro("tosse", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Tipologia respiro non esistente"}
                                fieldLabel={'Tosse'}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                                handleOnlyValue={true}
                                field={"tosse"}
                            />
                            {datiRespiro.tosse &&
                                <RoleBasedSelect
                                    fieldId={'M_TIPO_TOSSE.RESPIRO.ANAMNESIFISIOLOGICA'}
                                    infermiereFieldId={'I_TIPO_TOSSE.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                    pageState={this.props.pageState}
                                    id={"tipologiaTosse"}
                                    options={OggettiUtili.tipologiaTosse}
                                    value={datiRespiro.tipologiaTosse}
                                    onChange={(elem) => this.handleAggiornaFormRespiro("tipologiaTosse", elem)}
                                    isSearchable={false}
                                    noOptionsMessage={() => "Errore: Tipologia di tosse non esistente"}
                                    fieldLabel={'Tipologia di tosse'}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    handleOnlyValue={true}
                                    field={"tipologiaTosse"}
                                />}
                        </FieldsRow>

                        <CondizioneRilevataSection
                            ambito={'VALUTAZIONESISTEMARESPIRATORIO_RESPIRO_TOSSE'}
                            parentFieldId={'RESPIRO.ANAMNESIFISIOLOGICA'}
                            fieldsSuffix={'TOSSE'}
                            infParentFieldId={'VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            appendRolePrefix={true}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormRespiro("condizioneRilevataTosse", elem)}
                            valueCondizioneRilevata={datiRespiro.condizioneRilevataTosse}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormRespiro("daAttenzionareTosse", elem)}
                            valueDaAttenzionare={datiRespiro.daAttenzionareTosse}
                            showSuggerimenti={true}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormRespiro("propostaAttivitaSuggerimentiTosse", elem)}
                            valueSuggerimenti={datiRespiro.propostaAttivitaSuggerimentiTosse}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            showAutonomia={true}
                            onChangeAutonomiaPaziente={(elem) => this.handleAggiornaFormRespiro("autonomiaGestionePazienteTosse", elem)}
                            valueAutonomiaPaziente={datiRespiro.autonomiaGestionePazienteTosse}
                            onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaFormRespiro("autonomiaGestioneCaregiverTosse", elem)}
                            valueAutonomiaCaregiver={datiRespiro.autonomiaGestioneCaregiverTosse}
                            fieldJsonPathSuffix={"tosse"}
                            openCondition={datiRespiro.tosse}
                        />
                    </Box>
                }

                { /* Espettorazioni - Torace */
                    <Box key={"boxEspettorazioni" + nomeAccordion} titleBox={"Espettorazioni"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_ESPET_SECR_BRONCH.TORACE.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_ESPET_SECR_BRONCH.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                pageState={this.props.pageState}
                                id={"selectEspettorazioni"}
                                options={OggettiUtili.rispostaBreve}
                                value={datiTorace.espettorazioni}
                                onChange={(elem) => this.handleAggiornaFormTorace("espettorazioni", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Espettorazioni non esistente"}
                                fieldLabel={"Espettorazioni/Secrezioni bronchiali"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"espettorazioni"}
                            />
                        </FieldsRow>

                        {datiTorace.espettorazioni && <>
                            <FieldsRow>
                                <RoleBasedSelect
                                    fieldId={'M_ESPET_SECR_BRONCH_QUANT.TORACE.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_ESPET_SECR_BRONCH_QUANT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                    pageState={this.props.pageState}
                                    id={"selectEspettorazioniQuantita"}
                                    options={OggettiUtili.espettorazioniQuantita}
                                    value={datiTorace.espettorazioniQuantita}
                                    onChange={(elem) => this.handleAggiornaFormTorace("espettorazioniQuantita", elem)}
                                    isSearchable={false}
                                    noOptionsMessage={() => "Errore: Quantità non esistente"}
                                    fieldLabel={"Espettorazioni/Secrezioni bronchiali - Quantità"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    handleOnlyValue={true}
                                    field={"espettorazioniQuantita"}
                                />
                            </FieldsRow>

                            <SectionList
                                title={'Aspetto'}
                                data={datiTorace.espettorazioniAspetto}
                                renderSection={this.renderEspettorazioniAspetto}
                                addNewSectionCallback={this.addNewEspettorazioniAspetto}
                                removeSectionCallback={this.removeEspettorazioniAspetto}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                pageState={this.props.pageState}
                                keyFieldId={'M_ESPET_SECR_BRONCH_ASP.TORACE.ESAMEOBIETTIVO'}
                                infermiereKeyFieldId={'I_ESPET_SECR_BRONCH_ASP.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                field={'espettorazioniAspetto'}
                                parentJsonPath={"esameObiettivo.torace.espettorazioniAspetto"}
                            />
                        </>}

                        <CondizioneRilevataSection
                            ambito={'TORACE'}
                            appendRolePrefix={true}
                            fieldsPrefix={'ESPET_SECR_BRONCH'}
                            parentFieldId={'TORACE.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormTorace("espettorazioniCondizioneRilevata", elem)}
                            valueCondizioneRilevata={datiTorace.espettorazioniCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormTorace("espettorazioniDaAttenzionare", elem)}
                            valueDaAttenzionare={datiTorace.espettorazioniDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={"espettorazioni"}
                            openCondition={datiTorace.espettorazioni}
                        />
                    </Box>
                }

                { /* SpO2 - Torace */
                    <Box key={"boxSpo2" + nomeAccordion} titleBox={"SpO2"}>
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_SPO2.TORACE.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_SPO2.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci SpO2"}
                                field={"spo2"}
                                onChange={(field, elem) => this.handleAggiornaFormTorace(field, elem.target.value)}
                                id={"inputSpo2"}
                                value={datiTorace.spo2}
                                fieldLabel={"SpO2 (%)"}
                                type={"number"}
                                min={"0"}
                                max={"100"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>

                        <CondizioneRilevataSection
                            ambito={'TORACE'}
                            fieldsPrefix={'SPO2'}
                            appendRolePrefix={true}
                            parentFieldId={'TORACE.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormTorace("spo2CondizioneRilevata", elem)}
                            valueCondizioneRilevata={datiTorace.spo2CondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormTorace("spo2DaAttenzionare", elem)}
                            valueDaAttenzionare={datiTorace.spo2DaAttenzionare}
                            showAutonomia={true}
                            onChangeAutonomiaPaziente={(elem) => this.handleAggiornaFormTorace("spo2autonomiaGestionePaziente", elem)}
                            valueAutonomiaPaziente={datiTorace.spo2autonomiaGestionePaziente}
                            onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaFormTorace("spo2autonomiaGestioneCaregiver", elem)}
                            valueAutonomiaCaregiver={datiTorace.spo2autonomiaGestioneCaregiver}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={"spo2"}
                            openCondition={parseInt(datiTorace.spo2, 10) < 90}
                        />
                    </Box>
                }
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"valutazioneSistemaRespiratorio"}
                title={"Valutazione del sistema respiratorio"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["esameObiettivo.torace.listaAlterazioni", "esameObiettivo.torace.listaDispositiviAlterazioni",
                    "anamnesiFisiologica.datiRespiro.tipologia", "esameObiettivo.torace.listaRumori","esameObiettivo.torace.espettorazioniAspetto",
                    "esameObiettivo.torace.propostaAttivitaSuggerimenti", "esameObiettivo.datiRespiro.propostaAttivitaSuggerimenti"],
                    [defaultAlterazine, defaultDispositiviPresenti, defaultTipologia, defaultRumore, defaultEspettorazioniAspetto,
                        defaultProposteAttivitaSuggerimenti, defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }
}
ValutazioneSistemaRespiratorio.propTypes = propTypes;

ValutazioneSistemaRespiratorio.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false,
}
