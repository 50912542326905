import React, {useState} from "react";
import PropTypes from "prop-types";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import enumQuestionario from "../../../enum/enumQuestionario"
import styles from "./ScalaEQuestionari.module.css";

const questionariSvama = [
    {
        tipoQuestionario: enumQuestionario.tipoQuestionario.PSOC,
        label: "Valutazione della rete sociale (PSOC)",
        pathRichiestaQuestionario: "situazioneSociale.datiValutazioneReteSociale.PSOC"
    },
    {
        tipoQuestionario: enumQuestionario.tipoQuestionario.PMOB,
        label: "Grado di Mobilità (PMOB)",
        pathRichiestaQuestionario: "anamnesiFisiologica.datiGradoDiMobilita.PMOB"
    },
    {
        tipoQuestionario: enumQuestionario.tipoQuestionario.PADL,
        label: "Situazione funzionale (Attività di base)",
        pathRichiestaQuestionario: "anamnesiFisiologica.statoFunzionale.PADL"
    },
    {
        tipoQuestionario: enumQuestionario.tipoQuestionario.PSAN,
        label: "Necessità di assistenza sanitaria (PSAN)",
        pathRichiestaQuestionario: "anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria.PSAN"
    },
    {
        tipoQuestionario: enumQuestionario.tipoQuestionario.PCOG,
        label: "Profilo cognitivo (PCOG)",
        pathRichiestaQuestionario: "anamnesiFisiologica.datiStatoCognitivo.PCOG"
    },
    {
        tipoQuestionario: enumQuestionario.tipoQuestionario.PADL,
        label: "Stato Funzionale (PADL)",
        pathRichiestaQuestionario: "anamnesiFisiologica.statoFunzionale.PADL"
    }

]

function SvamaQuestionari ({openQuestionario, onClickAccordion, sottoTitolo, dataCompletamento, setQuestionario, verificaQuestionarioCompilado}) {

    const questionTotalCompiled = questionariSvama.filter(a => verificaQuestionarioCompilado(a)).length;

    return (
        <>
            <AccordionSezioneRichiesta
                idAccordion={"questionariSvamaAccordion"}
                title={"Questionari SVAMA"}
                required={false}
                openAccordion={openQuestionario}
                onClickAccordion={onClickAccordion}
                field={"openAccordionQuestionariSvama"}>   
                {sottoTitolo}
                {questionTotalCompiled && dataCompletamento}
                {questionTotalCompiled ? questionariSvama.map(questionario => {
                    if(verificaQuestionarioCompilado(questionario))
                        return <div onClick={() => setQuestionario(questionario)} className={styles.linkQuestionario.concat(" mb-3")}>{questionario.label}</div>
                    else return
                }):<div>Non ci sono questionari compilati</div>}
            </AccordionSezioneRichiesta>
        </>

    )
}

SvamaQuestionari.propTypes = {
    openQuestionario: PropTypes.bool,
    onClickAccordion: PropTypes.func,
    sottoTitolo: PropTypes.node,
    dataCompletamento: PropTypes.node,
    setQuestionario: PropTypes.func,
    verificaQuestionarioCompilado: PropTypes.func 
};

SvamaQuestionari.defaultProps = {};

export default SvamaQuestionari;
