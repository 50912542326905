import React, { Component, Fragment } from "react";
import Utils from "../../utils/Utils";
import RichiestaADIService from "../../service/RichiestaADIService";
import TabellaRichiesteDaFirmare from "./tabellaRichiesteDaFirmare/TabellaRichiesteDaFirmare";
import { Button } from "web-client-archetype";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import ModalHelper from "../modale/ModalHelper";
import * as _ from "lodash";
import {Container} from "reactstrap";
import InfoTable from "../home/tabellaRichieste/InfoTable";
import {mostraSpinner, nascondiSpinner} from "../../App";
import PropTypes from "prop-types";

const propTypes = {
    profileAction: PropTypes.bool
}

export default class FirmaFormalizzazioni extends Component {

    state = {
        listaRichiesteDaFirmare: [],
        sintesiRichieste: null,
        successo: null,
        isConferma: false,
        defaultLimit: 15,
        limit: 15,
        totalElements: null,
        rows: null
    }

    componentDidMount() {
        this.recuperoRichieste(this.state.limit);
    }

    recuperoRichieste(limit) {
        mostraSpinner();
        RichiestaADIService.recuperaRichiesteDaFirmare(limit).subscribe((res) => {
            this.setState({ sintesiRichieste: res?.data });
            this.setState({ totalElements: res?.data?.elementiTotali });
            nascondiSpinner()
        })
        this.renderTable();
    }

    confermaFirme(listaRichiesteDaFirmare) {
        mostraSpinner();
        RichiestaADIService.firmaFormalizzazioni(listaRichiesteDaFirmare).subscribe((res) => {
            if (res?.msg === "ERRORE") {
                Utils.toasterFunction({
                    status: res?.data?.codice ? res?.data?.codice : res?.status,
                    type: "critical",
                    text: res?.data?.errore ? res?.data?.errore : res?.data?.message
                });
                this.setState({ isConferma: false });
                nascondiSpinner()
                return;
            }

            let statoAggiornato = { limit: this.state.defaultLimit, listaRichiesteDaFirmare: [], isConferma: false, successo: true };
            let callback = () => this.recuperoRichieste(this.state.defaultLimit);
            this.setState(statoAggiornato, callback);
            nascondiSpinner()
        });
    }

    incrementaNumeroRichieste = () => {
        let newLimit = this.state.limit + 15;
        this.setState({ limit: newLimit });
        this.recuperoRichieste(newLimit);
    }

    renderTable() {
        return (
            <Fragment>
                <InfoTable info={"Richieste approvate/rifiutate in attesa di firma"} />
                <TabellaRichiesteDaFirmare
                    sintesiRichieste={this.state.sintesiRichieste}
                    aggiornaRichiesteDaFirmare={(richiesta) => {this.setState({listaRichiesteDaFirmare: richiesta})}}
                    aggiornaRighe={(riga) => {this.setState({rows: riga})}}
                    changePage={() => {this.incrementaNumeroRichieste()}}
                />
            </Fragment>
        )
    }

    renderModaleConferma = () => {
        let listaRichiesteDaFirmare = _.cloneDeep(this.state.listaRichiesteDaFirmare);
        let body = <div>
            <span>Stai per procedere con la conferma della firma.</span>
            <br />
            <span className="font-weight-bold">Vuoi confermare l’operazione?</span>
        </div>;

        let footer = (
            <Fragment>
                <Button
                    id={"conferma"}
                    text={"Conferma"}
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => this.confermaFirme(listaRichiesteDaFirmare)}
                />
                <Button
                    id={"annulla"}
                    text={"Annulla"}
                    dataDismiss={"modal"}
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    onClick={() => this.setState({ isConferma: false })}
                />
            </Fragment>
        );

        return (
            <ModalHelper id={"modaleConfermaFirma"}
                title={"Attenzione!"}
                body={body}
                footer={footer}
                titleClassName={"modal-header d-inline text-center backgroundOrange"}
                containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                contentClassName={"modal-content"}
                footerClassName={"modal-footer justify-content-around"}
                bodyClassName={"modal-body align-items-center d-flex justify-content-center text-center"}
            />)
    }

    renderModaleSuccesso() {
        let body = <>
            <div>
                <span>La richiesta di firma è stata correttamente inoltrata.</span>
            </div>
        </>;

        let footer = <>
            <div className={"col-12 text-center"}>
                <Button
                    id={"chiudiBtn"}
                    text={"Chiudi"}
                    className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                    dataDismiss={"modal"}
                    onClick={() => { this.setState({ successo: false }); }}
                />
            </div>
        </>;

        return <ModalHelper id={"modaleSuccesso"}
            title={"Operazione completata"}
            body={body}
            footer={footer}
            titleClassName={"modal-header d-inline text-center backgroundGreen"}
            containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
            contentClassName={"modal-content"}
            footerClassName={"modal-footer justify-content-around"}
            bodyClassName={"modal-body row align-items-center justify-content-around"}
        />;
    }

    mostraAltri() {
        return this.state.rows?.length < this.state.totalElements ?
            <Fragment>
                <div className="row justify-content-center mb-3">
                    <Button
                        id={"mostraAltri"}
                        text="Mostra altri"
                        onClick={this.incrementaNumeroRichieste}
                        className="btn btn-adi btnGreenOutline" />
                </div>
            </Fragment>
            : null;
    }

    render() {
        return (
            <Fragment>
                {this.state.isConferma ? this.renderModaleConferma() : <Fragment />}
                {this.state.successo ? this.renderModaleSuccesso() : <Fragment />}

                <Breadcrumb path={['Home', 'Firma formalizzazioni']} profileAction={this.props.profileAction}/>
                <Container fluid className={"px-5 position-relative"}>
                    <div className={"d-flex align-items-center pt-4"}>
                        <div className={"col-6 position-relative p-0"}>
                            <h1>{"Firma formalizzazioni"}</h1>
                        </div>
                        {this.state.listaRichiesteDaFirmare.length ?
                            <div className={"col-6 text-right p-0"}>
                                <Button
                                    id={"confermaFirma"}
                                    text={(`Firma (${this.state.listaRichiesteDaFirmare.length})`)}
                                    className={"btn btn-adi btnGreenOutline"}
                                    onClick={() => {this.setState({isConferma: true})}}
                                />
                            </div> : null
                        }
                    </div>
                    {this.renderTable()}
                    {this.mostraAltri()}
                </Container>
             
            </Fragment>
        )
    }
}

FirmaFormalizzazioni.propTypes = propTypes;
