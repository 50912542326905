import React, {Component} from "react";
import PropTypes from "prop-types";
import {DropdownIndicator, inputSelectStyles} from "../../common/select/SelectUtils";
import Select from "react-select";
import aulssElenco from "../../../utils/dataset/aulssData/aulssElenco.json";
import {Button, Input, SessioneUtente} from "web-client-archetype";
import AulssUtils from "../../../utils/AulssUtils";
import Utils from "../../../utils/Utils";
import AuthUtils from "../../../utils/AuthUtils";
import * as _ from "lodash";
import ApiAPMSService from "../../../service/ApiAPMSService";
import ListaUtenti from "../listaUtenti/ListaUtenti";
import styles from "./FiltroUtente.module.css";
import ToponomasticaUtils from "../../../utils/ToponomasticaUtils";
import {mostraSpinner, nascondiSpinner} from "../../../App";
import enumsUtente from "../../../enum/enumsUtente.json";
import * as Icon from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import routePath from "../../../utils/route/route-path.json";


const propTypes = {
    filtraUtente: PropTypes.func
}

const defaultUtenteapiapms = {
    cognome: "",
    nome: "",
    codiceFiscale: "",
    aulss: "",
    distretto: "",
    comune: "",
    sede: ""
};

export default class FiltroUtente extends Component {
    state = {
        utenteapiapms: _.cloneDeep(defaultUtenteapiapms),
        utenti: null,
        page: null,
        totalElements: null
    }

    listaAulssSelect = [];

    constructor(props) {
        super(props);
        this.callToRicercaUtenti = this.callToRicercaUtenti.bind(this);
    }

    componentDidMount() {
        this.popolaListaAulss();
        this.inizializzaForm();
    }

    getNullSelectOption = (selectOptions) => {
        if (selectOptions.length > 0)
            selectOptions.unshift({value: null, label: "Seleziona"});
    }

    popolaListaAulss = () => {
        aulssElenco.forEach(el => {
            this.listaAulssSelect.push({value: el.id, label: el.descrizione});
        });
        this.getNullSelectOption(this.listaAulssSelect);
    }

    inizializzaForm = () => {
        let utenteapiapms = _.cloneDeep(defaultUtenteapiapms);
        if (AuthUtils.hasUtenteRuoloReferenteAulss())
            this.setAulssReferenteAULSS(utenteapiapms);
        this.setState({utenteapiapms: utenteapiapms});
    }

    setAulssReferenteAULSS = (utenteapiapms) => {
        utenteapiapms.aulss = AulssUtils.getAulssByCodiceAulss(SessioneUtente.getInstance().codiceStruttura);
        utenteapiapms.aulss = {value: utenteapiapms.aulss.id, label: utenteapiapms.aulss.nome};
    }

    handleAggiornaForm = (field, elem) => {
        let currentUtente = {...this.state.utenteapiapms};
        currentUtente[field] = elem;

        if (field === "aulss" || field === "distretto" || field === "sede" || field === "comune") {
            if (elem.value === null)
                currentUtente[field] = null;
            if (field === "aulss") {
                currentUtente.distretto = "";
                currentUtente.comune = "";
            } else if (field === "distretto")
                currentUtente.sede = "";
        }

        this.setState({utenteapiapms: currentUtente});
    }

    pulisciCampi() {
        let utenteapiapms = _.cloneDeep(defaultUtenteapiapms);
        if (AuthUtils.hasUtenteRuoloReferenteAulss())
            this.setAulssReferenteAULSS(utenteapiapms);
        this.setState({utenteapiapms: utenteapiapms, utenti: null, page: null, totalElements: null});
    }

    callToRicercaUtenti(mostraAltri) {
        mostraSpinner();
        let utente = _.cloneDeep(this.state.utenteapiapms);
        let page = 0;
        let oldUsers;

        if (mostraAltri) {
            page = ((this.state.page) + 1);
            oldUsers = this.state.utenti;
        }

        let strutture = Array.of(SessioneUtente.getInstance().codiceStruttura);
        if (AuthUtils.hasUtenteRuoloReferenteAulss())
            utente.aulss = strutture.reduce((f, s) => `${f},${s}`);
        else if (utente.aulss)
            utente.aulss = this.state.utenteapiapms.aulss.value;
        else
            utente.aulss = null;

        ApiAPMSService.getUtenti(utente, page).subscribe(utenti => {
            if (!Utils.isObjectNull(utenti) && utenti.elementi.length > 0) {

                let users = utenti.elementi;
                let newUsers;

                if (oldUsers)
                    newUsers = oldUsers.concat(users);
                else
                    newUsers = users;

                for (let i = 0; i < newUsers.length; i++) {

                    if (newUsers[i].struttura) {
                        let aulss = AulssUtils.getAulssByCodiceAulss(newUsers[i].struttura);
                        let distretto = newUsers[i]?.gruppi?.map(gruppo=> gruppo?.attributi).map(att=> att[0]).map(c => c?.valore)[0]?.split(",").map(d => d?.split("@")[2]).filter(e => e)[0] || null
                        newUsers[i].aulss = Array.of(aulss.nome)
                        newUsers[i].distretto = distretto ? aulss?.distretti?.find(dis => dis.id == distretto)?.descrizione : "";
                        delete newUsers[i].struttura;
                    }

                    if (!newUsers[i].profili && newUsers[i].gruppi) {
                        newUsers[i].profili = newUsers[i].gruppi.map(gruppo => this.getProfilo(gruppo.codice_gruppo));
                    }

                    let cf = newUsers[i].codiceFiscale;

                    let j = i + 1;

                    while (j < newUsers.length) {
                        if (cf === newUsers[j].codiceFiscale) {
                            let aulss = AulssUtils.getAulssByCodiceAulss(newUsers[j].struttura);
                            newUsers[i].aulss.push(aulss.nome);
                            newUsers.splice(j,1);
                        }
                        else {
                            j++;
                        }
                    }
                }

                utenti.elementi = newUsers;
            }
            this.setState({utenti: utenti?.elementi, page: utenti?.page, totalElements: utenti?.totalElements}, () => {
                nascondiSpinner();
            });
        })
    }

    render() {
        return (
            <>
                <div id={"pannelloConfigurazioneRicercaUtenteBody"}
                     className={styles.layout.concat(" pl-5 pr-5 pt-3 pb-3")}>
                    <div className={"row"}>
                        <div className={"col-6 text-left"}><h1>Ricerca utenti profilati</h1></div>
                        <div className={"col-6 text-right"}>
                            <Link to={routePath.inserimento_utente}>
                                <Button id={"btnCreaUtente"}
                                        text={<>
                                            <Icon.PlusLg size={24} className={"mr-3"} style={{cursor: "pointer"}}/>
                                            Crea nuovo utente
                                        </>}
                                        className={"btn btn-adi btnGreenOutline"}
                                />
                            </Link>
                        </div>
                    </div>
                    {this.renderCampiInputTesto()}
                    {AuthUtils.hasUtenteRuoloReferenteAulss() || this.state.utenteapiapms.aulss
                        ? <div className={"row py-2"}>
                            {this.renderDistrettoSelect(AuthUtils.hasUtenteRuoloReferenteAulss()
                                ? SessioneUtente.getInstance().codiceStruttura
                                : this.state.utenteapiapms.aulss.value)}
                            {this.state.utenteapiapms.distretto ? this.renderSedeSelect(this.state.utenteapiapms.aulss.value, this.state.utenteapiapms.distretto.value) : null}
                            {this.renderComuneSelect(AuthUtils.hasUtenteRuoloReferenteAulss()
                                ? SessioneUtente.getInstance().codiceStruttura
                                : this.state.utenteapiapms.aulss.value)}
                        </div>
                        : null}
                    {this.renderBottoni()}
                </div>

                {this.state.utenti
                    ? <ListaUtenti rows={this.state.utenti}
                                   mostraAltri={this.callToRicercaUtenti}
                                   totalElements={this.state.totalElements} />
                    : null}
            </>
        );
    }

    renderCampiInputTesto = () => {
        return (
            <div className={"row py-2"}>
                <div className={"col-md-3"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <span >&nbsp;</span>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Input
                                parentClass={"mb-3"}
                                placeholder="Inserisci cognome utente"
                                field="cognome"
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id="filterInputCognome"
                                value={this.state.utenteapiapms.cognome}
                                label="Cognome"
                            />
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}/>
                    </div>
                </div>
                <div className={"col-md-3"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <span >&nbsp;</span>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Input
                                parentClass={"mb-3"}
                                placeholder="Inserisci nome utente"
                                field="nome"
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id="filterInputNome"
                                value={this.state.utenteapiapms.nome}
                                label="Nome"
                            />
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}/>
                    </div>
                </div>
                <div className={"col-md-3"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <span >&nbsp;</span>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Input
                                parentClass={!Utils.isStringEmptyOrNullOrUndefined(this.validaCodiceFiscale()) ? "mb-0" : "mb-3"}
                                placeholder="Inserisci codice fiscale utente"
                                field="codiceFiscale"
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id="filterInputCodiceFiscale"
                                value={this.state.utenteapiapms.codiceFiscale}
                                maxlength={"16"}
                                label="Codice fiscale"
                            />
                        </div>
                    </div>
                    <div className={!Utils.isStringEmptyOrNullOrUndefined(this.validaCodiceFiscale()) ? "row pb-2" : "row"}>
                        <div className={"col-12"}>
                            <span className={styles.errorText}>{this.validaCodiceFiscale()}</span>
                        </div>
                    </div>
                </div>
                {this.renderAulssSelect()}
            </div>
        );
    }

    validaCodiceFiscale = () => {
        if (!Utils.isStringEmptyOrNullOrUndefined(this.state.utenteapiapms.codiceFiscale) && !Utils.isValidCodiceFiscale(this.state.utenteapiapms.codiceFiscale)) {
            return "Inserire un codice fiscale valido";
        } else {
            return null;
        }
    }


    renderAulssSelect = () => {
        let utenteapiapms = _.cloneDeep(this.state.utenteapiapms);
        let aulss;

        if (!Utils.isObjectNull(utenteapiapms.aulss)) {
            aulss = utenteapiapms.aulss;
        } else {
            aulss = null;
        }

        return (
            <div className={"col-md-3"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <span><strong>AULSS</strong></span>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <Select
                            id={"selectAulss"}
                            className={"aulss"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            options={this.listaAulssSelect}
                            value={aulss}
                            onChange={(elem) => this.handleAggiornaForm("aulss", elem)}
                            isSearchable={false}
                            styles={inputSelectStyles}
                            placeholder={"Seleziona"}
                            isDisabled={AuthUtils.hasUtenteRuoloReferenteAulss()}
                            noOptionsMessage={() => "Errore: AULSS non esistente"}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderDistrettoSelect = (idAulss) => {
        let distretti = AulssUtils.getDistrettiByAulssId(idAulss);
        distretti = distretti.map(el => {
            return {value: el.id, label: el.descrizione};
        });
        this.getNullSelectOption(distretti);

        return (
            <div className={"col-md-3 mb-3"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <span>Distretto</span>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <Select
                            id={"selectDistretto"}
                            className={"distretti"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            options={distretti}
                            value={this.state.utenteapiapms.distretto}
                            onChange={(elem) => this.handleAggiornaForm("distretto", elem)}
                            isSearchable={false}
                            styles={inputSelectStyles}
                            placeholder={"Seleziona"}
                            noOptionsMessage={() => {
                                if (distretti.length > 0) {
                                    return "Errore: Distretto non esistente"
                                } else {
                                    return "Inserire un AULSS prima di selezionare un distretto";
                                }
                            }}
                            isDisabled={this.state.utenteapiapms.comune}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderComuneSelect = (idAulss) => {
        let comuniAulss = AulssUtils.getComuniByAulssId(idAulss);
        let listaComuniSelect = [];
        let listaComuniTemp = ToponomasticaUtils.getComuniValidi(JSON.parse(sessionStorage.getItem("comuni")))
            ?.filter(comune => comuniAulss.some(comuneAulss => comuneAulss === comune.codice) && comune.dataFineValidita === null) ?? [];
        listaComuniTemp.forEach(comune => listaComuniSelect.push({value: comune.codice, label: comune.descrizione}));
        this.getNullSelectOption(listaComuniSelect);

        return (
            <div className={"col-md-3"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <span>Comune</span>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <Select
                            id={"selectComuni"}
                            className={"comuni"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            options={listaComuniSelect}
                            value={this.state.utenteapiapms.comune}
                            onChange={(elem) => this.handleAggiornaForm("comune", elem)}
                            isSearchable={false}
                            styles={inputSelectStyles}
                            placeholder={"Seleziona"}
                            noOptionsMessage={() => {
                                if (listaComuniSelect.length > 0) {
                                    return "Errore: Comune non esistente"
                                } else {
                                    return "Inserire un AULSS prima di selezionare un comune";
                                }
                            }}
                            isDisabled={this.state.utenteapiapms.distretto}
                        />
                    </div>
                </div>
            </div>
        );
    }


    renderSedeSelect = (idAulss, idDistretto) => {
        let sedi = AulssUtils.getSediByAulssIdAndDistrettoId(idAulss, idDistretto);
        sedi = sedi.map(el => {
            return {value: el.id, label: el.nome};
        });
        this.getNullSelectOption(sedi);

        return (
            <div className={"col-md-3 mb-3"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <span>Sede</span>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <Select
                            id={"selectSedi"}
                            className={"sedi"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            options={sedi}
                            value={this.state.utenteapiapms.sede}
                            onChange={(elem) => this.handleAggiornaForm("sede", elem)}
                            isSearchable={false}
                            styles={inputSelectStyles}
                            placeholder={"Seleziona"}
                            noOptionsMessage={() => {
                                if (sedi.length > 0) {
                                    return "Errore: Sede non esistente"
                                } else {
                                    return "Inserire un distretto prima di selezionare una sede";
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }


    renderBottoni = () => {
        return(
            <div className="row py-2 justify-content-between">
                        <Button
                            id="pulisciCampiBtn"
                            text="Pulisci campi"
                            className="btn btn-adi btnGreenOutline"
                            style={{outline: "none"}}
                            onClick={() => this.pulisciCampi()}
                            disabled={this.isButtonDisabled(false, AuthUtils.hasUtenteRuoloReferenteAulss())}
                        />
                        <Button
                            id="cercaProfiloBtn"
                            text="Cerca"
                            className="btn btn-adi btnGreenOutline"
                            style={{outline: "none"}}
                            onClick={() => this.callToRicercaUtenti()}
                            disabled={this.isButtonDisabled(true, AuthUtils.hasUtenteRuoloReferenteAulss())}
                        />
            </div>
        );
    }


    isButtonDisabled(isCercaBtnClicked, isReferenteAulss) {
        let utente = _.cloneDeep(this.state.utenteapiapms);
        if (!isReferenteAulss) {
            delete utente.distretto;
            delete utente.comune;
            delete utente.sede;
        }

        if (isCercaBtnClicked) {
            return this.isDefaultDisableCondition(utente)
                || (!Utils.isStringEmptyOrNullOrUndefined(utente.codiceFiscale) && !Utils.isValidCodiceFiscale(utente.codiceFiscale))
                || !Object.keys(utente).some(key => {
                    if (key === "codiceFiscale") {
                        return !Utils.isStringEmptyOrNullOrUndefined(utente.codiceFiscale) && Utils.isValidCodiceFiscale(utente.codiceFiscale);
                    } else {
                        return !Utils.isStringEmptyOrNullOrUndefined(utente[key]);
                    }
                });
        } else {
            if (isReferenteAulss)
                delete utente.aulss;
            return this.isDefaultDisableCondition(utente);
        }
    }

    isDefaultDisableCondition = (utente) => {
        return Object.keys(utente).every(key => Utils.isStringEmptyOrNullOrUndefined(utente[key]));
    }

    getProfilo(codice_gruppo) {
        return enumsUtente.profilo[codice_gruppo];
    }
}

FiltroUtente.propTypes = propTypes;
