import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import FormVariabili from "../formVariabili/FormVariabili";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import AuthUtils from "../../../utils/AuthUtils";
import Box from "../../generalComponents/box/Box";
import {FieldsRow, RoleBasedCheckbox, SectionList} from "../../roleBasedComponents/RoleBasedComponents";
import {
    defaultAltro,
    defaultFarmaco, defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";

const propTypes = {
    dati: PropTypes.any,
    onChangeDati: PropTypes.any,
    pageState: PropTypes.any,
    argomento: PropTypes.object,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.any,
    showAccordionSezioneRichiesta: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    mascheraModificabilita: PropTypes.any,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class Argomento extends React.Component {

    state = {
        dati: {
            checked: false,
            lista: []
        }
    };

    mappaPathsObjects = Utils.generateMapPathsObjects(
        [`anamnesiPatologicaRemota.${this.props.argomento.field}.lista`,
            `anamnesiPatologicaRemota.${this.props.argomento.field}.lista.propostaAttivitaSuggerimenti`],
        [this.props.argomento.datiDefault, defaultProposteAttivitaSuggerimenti]
    );

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati)) this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiStato = [];
        let dati = _.cloneDeep(this.props.dati?.lista);
        let checked = null;

        if (dati?.length === 0 && this.props.dati?.checked) {
            let defaultData = _.cloneDeep(this.props.argomento.datiDefault);
            defaultData.id = uuid();
            datiStato.push(defaultData);
        } else if (dati?.length > 0) {
            datiStato.push(...dati)
        }

        if (datiStato?.length > 0) checked = true;

        datiStato = this.formatObject(datiStato, false);

        this.setState({dati: {checked: checked, lista: datiStato}});
    }

    formatObject = (dati, isObjectForBackend) => {
        if (dati.length > 0) {
            return dati.map(datiVariabili => {
                let datiFormattati = {};
                if (isObjectForBackend) {
                    // formatta dati per be
                    _.forIn(datiVariabili, (value, key) => {
                        if (!Utils.isStringEmptyOrNullOrUndefined(value)) {
                            if (key === "data" && !this.validazioneData(value)) {
                                datiFormattati[key] = null
                            } else datiFormattati[key] = value

                        } else {
                            datiFormattati[key] = null;
                        }
                    })
                } else {
                    // default
                    datiFormattati.id = datiVariabili?.id ?? uuid();
                    datiFormattati.diagnosiPatologiaPregressa = datiVariabili?.diagnosiPatologiaPregressa;
                    datiFormattati.data = datiVariabili?.data?.toString();
                    datiFormattati.patologiaAttiva = datiVariabili?.patologiaAttiva;
                    datiFormattati.condizioneRilevata = datiVariabili?.condizioneRilevata;
                    datiFormattati.daAttenzionare = datiVariabili?.daAttenzionare;
                    datiFormattati.note = datiVariabili?.note;
                    datiFormattati.propostaAttivitaSuggerimenti = datiVariabili?.propostaAttivitaSuggerimenti;
                    // neoplasia
                    if (datiVariabili?.neoplasiaTipologia || datiVariabili?.neoplasiaIntervento) {
                        datiFormattati.neoplasiaTipologia = datiVariabili?.neoplasiaTipologia;
                        datiFormattati.neoplasiaIntervento = datiVariabili?.neoplasiaIntervento;
                    }
                    // displasia
                    if (datiVariabili?.displasiaSpecificareIntervento) {
                        datiFormattati.displasiaSpecificareIntervento = datiVariabili?.displasiaSpecificareIntervento;
                    }
                    // anemia
                    if (datiVariabili?.anemiaTipologia || datiVariabili?.trasfusioni) {
                        datiFormattati.anemiaTipologia = datiVariabili?.anemiaTipologia;
                        datiFormattati.trasfusioni = datiVariabili?.trasfusioni;
                    }
                    // patologia peptica
                    if (datiVariabili?.sePatologiaPeptica) {
                        datiFormattati.sePatologiaPeptica = datiVariabili?.sePatologiaPeptica;
                    }
                    // insufficieza renale
                    if (datiVariabili?.insufficienzaRenaleTipologia || datiVariabili?.insufficienzaRenaleStadio) {
                        datiFormattati.insufficienzaRenaleTipologia = datiVariabili?.insufficienzaRenaleTipologia;
                        datiFormattati.insufficienzaRenaleStadio = datiVariabili?.insufficienzaRenaleStadio;
                    }
                    // psichiatria
                    if (datiVariabili?.storiaFamiliare || datiVariabili?.storiaPersonale) {
                        datiFormattati.storiaFamiliare = datiVariabili?.storiaFamiliare;
                        datiFormattati.storiaPersonale = datiVariabili?.storiaPersonale;
                    }
                }

                return datiFormattati;
            });
        } else {
            return [];
        }
    }

    validazioneDati = () => {
        let dati = _.cloneDeep(this.state.dati);
        let datiDaInviare = this.formatObject(dati.lista, true);
        datiDaInviare = _.map(datiDaInviare, item => _.mapValues(item, el => el === null || el === undefined || el === "" ? null : el));
        this.props.onChangeDati(this.props.argomento.field, {checked: datiDaInviare.length > 0, lista: datiDaInviare});
    }

    handleAggiornaForm = (field, elem, index) => {
        let dati = _.cloneDeep(this.state.dati.lista);

        if (field === "diagnosiPatologiaPregressa") {
            let value = elem;
            // se diagnosi patologia pregressa è vuoto o null pulisce tutti i dati
            if (Utils.isStringEmptyOrNullOrUndefined(value)) {
                let defaultData = _.cloneDeep(this.props.argomento.datiDefault);
                defaultData.id = uuid();
                dati[index] = defaultData;
            } else {
                let isOpzioneAutocompleteValida = !Utils.isObjectNull(value);
                if (isOpzioneAutocompleteValida) {
                    dati[index].diagnosiPatologiaPregressa = value;
                } else {
                    dati[index].diagnosiPatologiaPregressa = "";
                }
            }
        } else {
            dati[index][field] = elem;
        }

        if (field === "condizioneRilevata" && elem === "01") {
            dati[index].propostaAttivitaSuggerimenti = [];
        }

        this.setState({dati: {checked: dati.length > 0, lista: dati}}, () => this.validazioneDati());
    }

    handleChangeCheckBox = (_field, checked) => {
        this.setState({dati: {...this.state.dati, checked: checked}}, () => {
            if (!this.state.dati.checked) this.eliminaDati();
            this.props.onChangeDati(this.props.argomento.field, {...this.state.dati, checked: checked});
        });
    }

    eliminaDati = () => {
        this.setState({dati: {checked: this.state.dati.lista.length > 0, lista: []}}, () => this.validazioneDati());
    }

    validazioneData = (data) => {
        let dateYear = new RegExp(/^(19|20)\d{2}$/);
        if (!Utils.isStringEmptyOrNullOrUndefined(data)) return dateYear.test(data);
        else return true;
    }

    renderPatologia = (patologia, index) => {
        let ultimoIndex = this.state.dati.lista.length - 1;
        let datiVariabili = this.props.argomento.form(this, patologia, index, this.state.dati.lista, ultimoIndex, this.props.argomento.fieldsId, this.props.argomento.field, this.props.pageState);
        return (
            <FormVariabili
                dati={datiVariabili}
                mascheraModificabilita={this.props.mascheraModificabilita}
                argomentoField={this.props.argomento.field}
                idPatologiaItem={patologia.id}
                pageState={this.props.pageState}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                disableDoubleInput={this.props.disableDoubleInput}
                field={`${this.props.argomento.field}.lista`}
                ambito={this.props.argomento.text.toUpperCase()}
            />
        )
    }

    nuovaDiagnosiPatologia = () => {
        let dati = _.cloneDeep(this.state.dati.lista);
        let datiDefault = _.cloneDeep(this.props.argomento.datiDefault);
        datiDefault.id = uuid();
        dati.push(datiDefault);
        this.setState({dati: {checked: dati.length > 0, lista: dati}});
    }

    rimuoviDiagnosiPatologia = (index) => {
        let dati = _.cloneDeep(this.state.dati.lista);
        dati.splice(index, 1);
        this.setState({dati: {checked: dati.length > 0, lista: dati}}, () => this.validazioneDati());
    }

    renderBody = () => {
        return (<SectionList
            title={'Patologia'}
            data={this.state.dati.lista}
            renderSection={this.renderPatologia}
            addNewSectionCallback={this.nuovaDiagnosiPatologia}
            removeSectionCallback={this.rimuoviDiagnosiPatologia}
            disableDoubleInput={this.props.disableDoubleInput}
            hideUnsetValues={this.props.hideUnsetValues}
            mascheraModificabilita={this.props.mascheraModificabilita}
            forceReadOnly={this.props.forceReadOnly}
            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            pageState={this.props.pageState}
            keyFieldId={`${this.props.argomento.fieldsId["diagnosiPatologiaPregressa"]}`}
            field={'lista'}
            parentJsonPath={`anamnesiPatologicaRemota.${this.props.argomento.field}.lista`}
            atLeastOne={this.state.dati.lista.length === 1}
            addButtonVisibilityHandler={this.state.dati.lista.length > 0 && this.state.dati.lista?.every(e =>
                (e?.diagnosiPatologiaPregressa && (e.diagnosiPatologiaPregressa !== '')) && (e.data && this.validazioneData(e.data)) ||
                (e?.diagnosiPatologiaPregressa && (e.diagnosiPatologiaPregressa !== '')) && (!e.data))
            }
            fieldsOrderMap={this.mappaPathsObjects}
        />)
    }

    render() {
        if (this.context.forceReadOnly || this.props.showAccordionSezioneRichiesta) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={this.props.argomento.field}
                    title={this.props.argomento.text}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.argomento.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={this.mappaPathsObjects}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                >
                    <FieldsRow colNumber={-1}>
                        <RoleBasedCheckbox
                            fieldId={this.props.argomento.checkboxFieldId}
                            pageState={this.props.pageState}
                            id={this.props.argomento.field}
                            text={this.props.argomento.text}
                            styleLabel={{fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"}}
                            field={"checked"}
                            checked={this.state.dati.checked}
                            parentJsonPath={`anamnesiPatologicaRemota.${this.props.argomento.field}`}
                            disabled={true}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>
                    {this.state.dati.checked ? this.renderBody() : <></>}
                </AccordionSezioneRichiesta>
            );
        } else {
            return (
                <Box key={this.props.argomento.field} hideBox={!this.state.dati.checked} accordionPadre={true}>
                    <FieldsRow colNumber={-1}>
                        <RoleBasedCheckbox
                            fieldId={this.props.argomento.checkboxFieldId}
                            pageState={this.props.pageState}
                            id={this.props.argomento.field}
                            text={this.props.argomento.text}
                            styleLabel={{fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"}}
                            field={"checked"}
                            checked={this.state.dati.checked}
                            onChange={(field, elem) => this.handleChangeCheckBox(field, elem.target.checked)}
                            parentJsonPath={`anamnesiPatologicaRemota.${this.props.argomento.field}`}
                            disabled={AuthUtils.hasUtenteRuoloAssistenteSociale() || this.context.forceReadOnly || this.mascheraModificabilita?.anamnesiPatologicaRemota?.patologieApparatoRespiratorio}
                            fieldsOrderMap={this.mappaPathsObjects}
                            forceReadOnly={this.props.mascheraModificabilita?.anamnesiPatologicaRemota?.[this.props.argomento?.field]?.checked}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>
                    {this.state.dati.checked && this.renderBody()}
                </Box>
            );
        }
    }
}
Argomento.propTypes = propTypes;

Argomento.defaultProps = {
    showAccordionSezioneRichiesta: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

Argomento.contextType = RoleBasedContext;
