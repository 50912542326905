import React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {FieldsRow, RoleBasedCalendar} from "../../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../../utils/Utils";

const propTypes = {
    dati: PropTypes.any,
    figureProfessionali: PropTypes.array,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onChangeDatiDataDimissioneProgrammataDaRicovero: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    statoRichiesta: PropTypes.string,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class DataDimissioneProgrammataDaRicoveroSub extends React.Component {
    state = {
        dataDimissioneProgrammataDaRicovero: _.cloneDeep(this.props.dati)
    };

    componentDidMount() {
        this.props.onChangeRequiredFieldsNumber(this.countObbligatorieta())
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati))
            this.setState({dataDimissioneProgrammataDaRicovero: this.props.dati}, () => {
                this.props.onChangeRequiredFieldsNumber(this.countObbligatorieta());
            });
    }


    handleAggiornaForm = (field, elem) => {
        let dataDimissioneProgrammataDaRicovero = _.cloneDeep(this.state.dataDimissioneProgrammataDaRicovero)
        dataDimissioneProgrammataDaRicovero[field] = elem
        this.setState({dataDimissioneProgrammataDaRicovero: dataDimissioneProgrammataDaRicovero}, () => {
            this.props.onChangeDatiDataDimissioneProgrammataDaRicovero(field, this.state.dataDimissioneProgrammataDaRicovero);
            this.props.onChangeRequiredFieldsNumber(this.countObbligatorieta());
        });
    }

    countObbligatorieta = () => {
        let dati = this.state.dataDimissioneProgrammataDaRicovero
        let numeroCampiObbligatoriMancanti = 0
        if (!dati.dataDimissioneProgrammataDaRicovero) numeroCampiObbligatoriMancanti++
        return numeroCampiObbligatoriMancanti
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"dataPresaInCaricoDimissioneProtetta"}
                title={"Data di presa in carico da dimissione protetta"}
                required={Utils.isStateRichiesta(this.props.pageState)}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}>
                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'DATA.DATADIMISSIONEPROGRAMMATADARICOVERO.RICHIESTACAMBIOSETTING'}
                        pageState={this.props.pageState}
                        id={"dataDimissioneProgrammataDaRicovero"}
                        value={this.state.dataDimissioneProgrammataDaRicovero.dataDimissioneProgrammataDaRicovero}
                        onChange={(elem) => this.handleAggiornaForm("dataDimissioneProgrammataDaRicovero", elem.target.value)}
                        errorMessage={"Data non valida"}
                        fieldLabel={"Data Dimissione programmata da ricovero"}
                        forceReadOnly={this.props.forceReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"dataDimissioneProgrammataDaRicovero"}
                        fieldRequired={Utils.isDimissioneProtetta(this.props.pageState)}
                        parentJsonPath={"dataDimissioneProgrammataDaRicovero"}
                    />
                </FieldsRow>
            </AccordionSezioneRichiesta>
        )
    }
}

DataDimissioneProgrammataDaRicoveroSub.propTypes = propTypes;
