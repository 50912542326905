import {useEffect, useState, useContext} from "react";
import _ from "lodash";
import {RoleBasedContext} from "../utils/RoleBasedContext";
import {BehaviorSubject} from "rxjs";

export const proposteGlobaleList = new BehaviorSubject([]);
export const proposteGlobaleList$ = proposteGlobaleList.asObservable();



export const setPropostaAttivitaEsuggGlobal = (proposte) => {
    const currentProposteAttivitaEsuggerimentiGlobal = _.cloneDeep(proposteGlobaleList.getValue());
    if (!currentProposteAttivitaEsuggerimentiGlobal.length) {
        proposteGlobaleList.next(proposte)
    } else {
        let difference = proposte.filter(x => !currentProposteAttivitaEsuggerimentiGlobal.find(a => a?.id === x?.id));

        if (difference.length) {
            difference.forEach(element => {
                currentProposteAttivitaEsuggerimentiGlobal.push(element)
            });
            proposteGlobaleList.next(currentProposteAttivitaEsuggerimentiGlobal)
        }
    }
}

export const deletePropostaAttivitaEsuggGlobal = (proposta) => {
    const currentProposteAttivitaEsuggerimentiGlobal = _.cloneDeep(proposteGlobaleList.getValue());
    proposteGlobaleList.next(currentProposteAttivitaEsuggerimentiGlobal.filter(p => p?.id !== proposta?.id))
}


export const useProposteAttivitaEsuggerimenti = (suggerimenti) => {


    const addPropostaAttivitaEsuggGlobal = (proposta) => {
        const currentProposteAttivitaEsuggerimentiGlobal = _.cloneDeep(proposteGlobaleList.getValue());
        currentProposteAttivitaEsuggerimentiGlobal.push(proposta);
        proposteGlobaleList.next(currentProposteAttivitaEsuggerimentiGlobal)
    }


    const editPropostaAttivitaEsuggGlobal = (proposta) => {
        const currentProposteAttivitaEsuggerimentiGlobal = _.cloneDeep(proposteGlobaleList.getValue());
        proposteGlobaleList.next(currentProposteAttivitaEsuggerimentiGlobal?.map(p => {
            if (p.id === proposta.id)
                return proposta
            else return p;
        }))
    }

    return {
        addPropostaAttivitaEsuggGlobal: addPropostaAttivitaEsuggGlobal,
        deletePropostaAttivitaEsuggGlobal: deletePropostaAttivitaEsuggGlobal,
        editPropostaAttivitaEsuggGlobal: editPropostaAttivitaEsuggGlobal,
        setPropostaAttivitaEsuggGlobal: setPropostaAttivitaEsuggGlobal,
        proposteGlobaleList: proposteGlobaleList,
        proposteGlobaleList$: proposteGlobaleList$,
    }
}