import React from "react";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Statistica from "../../statistica/Statistica";
import Utils from "../../../utils/Utils";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import enumQuestionario from "../../../enum/enumQuestionario.json";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import enumsUtente from "../../../enum/enumsUtente.json";
import {SessioneUtente} from "web-client-archetype";
import AuthUtils from "../../../utils/AuthUtils";

const propTypes = {
    datiPADL: PropTypes.any,
    onChangeDatiPADL: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    pageState: PropTypes.any,
    field: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class StatoFunzionale extends AccordionSecondLevel {

    state = {
        datiPADL: _.cloneDeep(this.props.datiPADL)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiPADL, this.props.datiPADL))
            this.forceUpdate(() => this.inizializzaForm());
    }

    inizializzaForm = () => {
        const datiPADL = _.cloneDeep(this.props.datiPADL);
        this.setState({datiPADL: datiPADL}, this.validazioneDati);
    }

    validazioneDati = () => {
        const datiPADL = _.cloneDeep(this.state.datiPADL);
        this.props.onChangeDatiPADL("statoFunzionale", datiPADL);
        this.props.onChangeRequiredFieldsNumber("sottosezioneStatoFunzionale", this.numeroCampiObbligatori());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiPADL}) => {
            datiPADL[field] = elem;
            return {datiPADL};
        }, this.validazioneDati);
    }

    renderBodyPADL = () => {
        const isMmgCurrentEvaluator = AuthUtils.isCurrentUserEvaluator(enumsUtente.ruoli.mmg, this.props.richiesta, this.context.forceUserRole, this.props.pageState);

        return (
            <>   
            <Statistica
                    fieldId={"M_VADL.STATOFUNZIONALEPADL.ANAMNESIFISIOLOGICA"}
                    infermiereFieldId={"I_VADL.STATOFUNZIONALEPADL.CAPACITAECURADISE"}
                    pageState={this.props.pageState}
                    nomeLink={"Compila questionario \"Situazione funzionale (Attività di base)\""}
                    nomePunteggio={"Punteggio indice di Barthel (VADL)"}
                    questionario={this.state.datiPADL.PADL}
                    profileName={'Profilo funzionale – Punteggio PADL'}
                    profileFieldId={'M_PROF_FUNZ.STATOFUNZIONALEPADL.ANAMNESIFISIOLOGICA'}
                    onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.PADL, questionario)}
                    profileInfermiereFieldId={'I_PROF_FUNZ.STATOFUNZIONALEPADL.CAPACITAECURADISE'}
                    richiesta={this.props.richiesta}
                    pathRichiestaQuestionario={"anamnesiFisiologica.statoFunzionale.PADL"}
                    tipoQuestionario={enumQuestionario.tipoQuestionario.PADL}
                    salvaBozza={this.props.salvaBozza}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    parentJsonPathSubObject={'padl'}
                    scoreMandatoryForRole={enumsUtente.ruoli.mmg}
                    compilaQuestionarioObbligatorio={isMmgCurrentEvaluator ? true : false}
                    obbligatorieta={{
                        surveyType: enumQuestionario.tipoQuestionario.PADL,
                        forceRequiredCompilation: isMmgCurrentEvaluator && Utils.isStringEmptyOrNullOrUndefined(this.state.datiPADL?.PADL?.punteggio)
                    }}
                />
                <ValutazioneSection
                    pageState={this.props.pageState}
                    ambito={"STATO FUNZIONALE (PADL)"}
                    parentFieldId={'STATOFUNZIONALEPADL.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'STATOFUNZIONALEPADL.CAPACITAECURADISE'}
                    appendRolePrefix={true}
                    onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                    valueStatoDopoValutazione={this.state.datiPADL.statoDopoValutazione}
                    onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                    valueDataValutazione={this.state.datiPADL.dataValutazione}
                    requiredDataValutazione={true}
                    onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                    valueDaConsiderare={this.state.datiPADL.daConsiderare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={this.state.datiPADL.propostaAttivitaSuggerimenti}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={this.state.datiPADL.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={this.state.datiPADL.autonomiaGestioneCaregiver}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </>
        );
    }

    numeroCampiObbligatori = () => {
        if (this.props.forceReadOnly || this.context.forceReadOnly) return 0;

        let campiObbligatori = 0;

        campiObbligatori += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(this.state.datiPADL?.propostaAttivitaSuggerimenti);

        if (Utils.isStateRichiesta(this.props.pageState) && Utils.isStringEmptyOrNullOrUndefined(this.state.datiPADL?.PADL?.valutazione)) {
            campiObbligatori++;
        }

        if (AuthUtils.isCurrentUserEvaluator(enumsUtente.ruoli.mmg, this.props.richiesta, this.context.forceUserRole, this.props.pageState) && Utils.isStringEmptyOrNullOrUndefined(this.state.datiPADL?.PADL?.punteggio)) {
            campiObbligatori++;
        }

        if (this.state.datiPADL.statoDopoValutazione === "02" && Utils.isStringEmptyOrNullOrUndefined(this.state.datiPADL.dataValutazione)) {
            campiObbligatori++;
        }

        return campiObbligatori;
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"statoFunzionale"}
                title={"Stato Funzionale (PADL)"}
                required={this.numeroCampiObbligatori() > 0}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                modeAccordion={false}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodyPADL()}</AccordionSezioneRichiesta>
        );
    }
}

StatoFunzionale.propTypes = propTypes;

StatoFunzionale.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

StatoFunzionale.contextType = RoleBasedContext;
