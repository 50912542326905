import * as _ from "lodash";
import * as moment from "moment";

export default class HistorySessionRequest {

    static data;

    static getData() {
        return HistorySessionRequest.data;
    }

    static getDataStartWithJsonPath(jsonPath, operationType = null) {
        const data = HistorySessionRequest.data;

        if (data == null) return [];

        if (jsonPath.startsWith('.')) jsonPath = jsonPath.slice(1);
        if (!jsonPath.startsWith('$')) jsonPath = '$.' + jsonPath;

        return Object.keys(data)
            .filter(k => {
                const key = k.replaceAll('.dati', '.').toLowerCase();
                const keyToMatch = jsonPath.replaceAll('.dati', '.').toLowerCase();
                return key.startsWith(keyToMatch);
            })
            .flatMap(k => data[k])
            ?.filter(d => !["system", "code", "linkId", "tipoRisposta", "display"].includes(d?.field) && !(["text"].includes(d?.field) && d?.jsonPath?.includes("\.sezioni"))) // rimossi visualizzazioni codici, risposte da storico questionari
            .filter(d => {
                const defaultCondition = d.value != null && d.value !== "";
                return operationType != null ? d.tipoOperazione === operationType && defaultCondition : defaultCondition;
            }) ?? [];
    }

    static setData(storicoListaCampi, request) {
        const valutatori = [...(request.valutazioni?.map(v => v.valutatore)), request.mmg, request.richiedente]
            .reduce((r, a) => {
                r[a.codiceFiscale] = r[a.codiceFiscale] || {};
                r[a.codiceFiscale] = {
                    name: a.nome,
                    surname: a.cognome,
                    role: a.ruolo,
                };
                return r;
            }, {});

        let data = _.orderBy(storicoListaCampi, 'timestamp', 'desc');
        HistorySessionRequest.data = Object.freeze(data.reduce((r, a) => {
            const key = a.jsonPath.replaceAll('.dati', '.').toLowerCase();

            const date = moment.utc(a.timestamp).local();
            r[key] = r[key] || [];
            r[key].push(Object.freeze({
                field: a.attributo,
                value: a.valore,
                decodedValue: a.decodedValue,
                tipoOperazione: a.tipoOperazione,
                timestamp: a.timestamp,
                date: date.format('DD/MM/YYYY'),
                hour: date.format('HH:mm'),
                name: valutatori[a.codiceFiscaleValutatore]?.name ?? 'N/A',
                surname: valutatori[a.codiceFiscaleValutatore]?.surname ?? 'N/A',
                role: valutatori[a.codiceFiscaleValutatore]?.role ?? 'N/A',
                cf: a.codiceFiscaleValutatore ?? 'N/A',
                cfEsecuzioneOperazione: a.cfEsecuzioneOperazione,
                jsonPath: a.jsonPath
            }));
            return r;
        }, {}));
    }
}
