import moment from 'moment'

export default class ToponomasticaUtils {

    comuni = [];
    province = [];
    nazioni = [];
    cap = [];
    sigleProvince = [];

    static instance = (field) => {
        if (field === "comuni" && sessionStorage.getItem(field))
            this.comuni = ToponomasticaUtils.getComuniValidi(JSON.parse(sessionStorage.getItem(field)));

        if (field === "province" && sessionStorage.getItem(field))
            this.province = ToponomasticaUtils.getProvinceValide(JSON.parse(sessionStorage.getItem(field)));

        if (field === "nazioni" && sessionStorage.getItem(field))
            this.nazioni = ToponomasticaUtils.getNazioniValide(JSON.parse(sessionStorage.getItem(field)));

        if (field === "cap" && sessionStorage.getItem(field))
            this.cap = JSON.parse(sessionStorage.getItem(field));

        if (field === "sigleProvince" && sessionStorage.getItem(field))
            this.sigleProvince = ToponomasticaUtils.getSigleProvinceValide(JSON.parse(sessionStorage.getItem(field)));
    }

    static getComuni = () => {
        return ToponomasticaUtils.comuni ?? [];
    }

    static getProvince = () => {
        return ToponomasticaUtils.province ?? [];
    }

    static getNazioni = () => {
        return ToponomasticaUtils.nazioni ?? [];
    }

    static getSigleProvince = () => {
        return ToponomasticaUtils.sigleProvince ?? [];
    }

    static getComuniValidi = (listaComuni, validDate = moment().format("YYYY-MM-DD")) => {
        return listaComuni && listaComuni.filter(el => ToponomasticaUtils.filterCondition(el, validDate))
    }

    static getProvinceValide = (listaProvince, validDate = moment().format("YYYY-MM-DD")) => {
        return listaProvince && listaProvince.filter(el => ToponomasticaUtils.filterCondition(el, validDate));
    }

    static getNazioniValide = (listaNazioni, validDate = moment().format("YYYY-MM-DD")) => {
        return listaNazioni && listaNazioni.filter(el => ToponomasticaUtils.filterCondition(el, validDate));
    }

    static getSigleProvinceValide = (listaSigleProvince, validDate = moment().format("YYYY-MM-DD")) => {
        return listaSigleProvince && listaSigleProvince.filter(el => ToponomasticaUtils.filterCondition(el, validDate));
    }

    static filterCondition = (el, validDate) => {
        return (el.dataInizioValidita ? (el.dataInizioValidita <= validDate) : true) && (el.dataFineValidita ? (el.dataFineValidita > validDate) : true);
    }
}
