import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    esplorazioneRettale: PropTypes.any,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultAlterazione = {
    id: null,
    tipologiaDiAlterazione: null,
    sede: null,
    altraSede: null
};

export default class EsplorazioneRettale extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.esplorazioneRettale)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.esplorazioneRettale, this.props.esplorazioneRettale))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.esplorazioneRettale);
        this.setState({dati}, this.validazioneDati);
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({dati}) => {
            if (index === null && subField === null) {
                dati[field] = elem;
            } else {
                if (field === "alterazioni" && subField === "sede" && elem !== "04") dati[field][index].altraSede = null;
                dati[field][index][subField] = elem;
            }

            if (!dati.presenzaDiAlterazioni) {
                dati.alterazioni = [];
                dati.condizioneRilevata = null;
            }
            return {dati};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        const dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("esplorazioneRettale", dati);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneEsplorazioneRettale", campiObbligatoriRimanenti);
    }

    addNewTipoAlterazione = () => {
        let dati = _.cloneDeep(this.state.dati);
        let alterazione = _.cloneDeep(defaultAlterazione);
        alterazione.id = uuid();
        dati.alterazioni.push(alterazione);
        this.setState({dati}, () => this.validazioneDati);
    }

    removeTipoAlterazione = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.alterazioni.splice(index, 1);
        this.setState({dati}, () => this.validazioneDati);
    }

    renderTipoAlterazione = (alterazione, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedICD9Input
                        fieldId={'M_TIPO_ALT.ESPLORAZIONERETTALE.ESAMEOBIETTIVO'}
                        infermiereFieldId={"I_TIPO_ALT.ESPLORAZIONERETTALE.FUNZIONEGASTROINTESTINALE"}
                        pageState={this.props.pageState}
                        value={alterazione.tipologiaDiAlterazione}
                        field={"tipologiaDiAlterazione"}
                        onChange={(field, elem) => this.handleAggiornaForm("alterazioni", elem.target.value, index, field)}
                        fieldLabel={"Tipo di alterazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                    <RoleBasedSelect
                        fieldId={"M_SEDE_ALT.ESPLORAZIONERETTALE.ESAMEOBIETTIVO"}
                        infermiereFieldId={"I_SEDE_ALT.ESPLORAZIONERETTALE.FUNZIONEGASTROINTESTINALE"}
                        pageState={this.props.pageState}
                        id={"selectSede"}
                        options={OggettiUtili.sedeEsplorazioneRettale}
                        value={alterazione.sede}
                        onChange={(elem) => this.handleAggiornaForm("alterazioni", elem, index, "sede")}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Sede non esistente"}
                        fieldLabel={"Sede"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        field={"sede"}
                    />
                    {alterazione.sede === "04" &&
                        <RoleBasedInput
                            id={"inputAltraSede"}
                            fieldId={'M_SEDE_ALT.ESPLORAZIONERETTALE.ESAMEOBIETTIVO'}
                            infermiereFieldId={"I_SEDE_ALT.ESPLORAZIONERETTALE.FUNZIONEGASTROINTESTINALE"}
                            pageState={this.props.pageState}
                            value={alterazione.altraSede}
                            placeholder={"Inserisci altra sede"}
                            field={"altraSede"}
                            onChange={(field, elem) => this.handleAggiornaForm("alterazioni", elem.target.value, index, field)}
                            fieldLabel={'Altro'}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                        />}
                </FieldsRow>
            </>

        )
    }

    handleDispositiviArrayChange = (array, field) => {
        let dati = _.cloneDeep(this.state.dati);
        dati[field] = array;
        this.setState({dati}, () => {
            this.validazioneDati()
            this.forceUpdate();
        });
    }

    renderBodySezione = () => {
        const dati = this.state.dati;
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_PRES_ALT.ESPLORAZIONERETTALE.ESAMEOBIETTIVO'}
                        infermiereFieldId={"I_PRES_ALT.ESPLORAZIONERETTALE.FUNZIONEGASTROINTESTINALE"}
                        pageState={this.props.pageState}
                        id={"selectPresenzaDiAlterazioni"}
                        options={OggettiUtili.rispostaBreve}
                        value={dati.presenzaDiAlterazioni}
                        onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioni", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                        fieldLabel={"Presenza di alterazioni"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"presenzaDiAlterazioni"}
                    />
                </FieldsRow>

                {dati.presenzaDiAlterazioni &&
                    <SectionList
                        title={'Tipo di alterazione'}
                        data={dati.alterazioni}
                        renderSection={this.renderTipoAlterazione}
                        addNewSectionCallback={this.addNewTipoAlterazione}
                        removeSectionCallback={this.removeTipoAlterazione}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'M_TIPO_ALT.ESPLORAZIONERETTALE.ESAMEOBIETTIVO'}
                        infermiereKeyFieldId={"I_TIPO_ALT.ESPLORAZIONERETTALE.FUNZIONEGASTROINTESTINALE"}
                        field={'alterazioni'}
                        addButtonVisibilityHandler={dati?.alterazioni.every(a => !!a.tipologiaDiAlterazione)}
                        parentJsonPath={"esameObiettivo.esplorazioneRettale.alterazioni"}
                    />}

                <DispositiviPresenti
                    handleArrayChange={this.handleDispositiviArrayChange}
                    dispositiviPresenti={dati.dispositiviPresenti}
                    pageState={this.props.pageState}
                    infermiereFieldId={"I_COND_RIL.ESPLORAZIONERETTALE.FUNZIONEGASTROINTESTINALE"}
                    fieldId={"M_DISP.ESPLORAZIONERETTALE.ESAMEOBIETTIVO"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    parentJsonPath={"esameObiettivo.esplorazioneRettale.dispositiviPresenti"}
                />

                <CondizioneRilevataSection
                    ambito={'ESPLORAZIONE RETTALE'}
                    parentFieldId={'ESPLORAZIONERETTALE.ESAMEOBIETTIVO'}
                    infParentFieldId={"ESPLORAZIONERETTALE.FUNZIONEGASTROINTESTINALE"}
                    appendRolePrefix={true}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={dati.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={dati.daAttenzionare}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={dati.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={dati.autonomiaGestioneCaregiver}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                    showSuggerimenti={true}
                    openCondition={dati.presenzaDiAlterazioni}
                />
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"esplorazioneRettale"}
                    title={"Esplorazione rettale"}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.esplorazioneRettale.alterazioni",
                            "esameObiettivo.esplorazioneRettale.dispositiviPresenti",
                            "esameObiettivo.esplorazioneRettale.propostaAttivitaSuggerimenti"],
                        [defaultAlterazione, defaultDispositiviPresenti, defaultProposteAttivitaSuggerimenti]
                    )}
                    accordionReadOnly={this.props.accordionReadOnly}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}

EsplorazioneRettale.propTypes = propTypes;

EsplorazioneRettale.defaultProps = {
    showAccordion: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}
