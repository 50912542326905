import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    occhi: PropTypes.any,
    onChangeEsameObiettivo: PropTypes.func,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber:  PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultAlterazione = {
    id: null,
    alterazione: null
}

export default class Occhi extends AccordionSecondLevel {

    state = {
        dati: _.cloneDeep(this.props.occhi)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.occhi, this.props.occhi))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.occhi);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({dati}) => {
            if (index === null && subField === null) {
                dati[field] = elem;
            } else {
                dati[field][index][subField] = elem;
            }

            if (!dati.isPresenzaCorrezione) {
                dati.entitaCorrezioneDx = null
                dati.entitaCorrezioneSx = null
            }

            if (!this.presenzaAlterazioni(dati.alterazioniDx, dati.alterazioniSx)) {
                dati.condizioneRilevata = null;
            }

            return {dati};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        let dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("occhi", dati);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneOcchi", campiObbligatoriRimanenti);
    }

    presenzaAlterazioni = (alterazioniDx, alterazioniSx) => {
        return alterazioniDx?.length > 0 || alterazioniSx?.length > 0;
    }

    /* Alterazione Dx */

    addNewAlterazioneDx = () => {
        let dati = _.cloneDeep(this.state.dati);
        let alterazione = _.cloneDeep(defaultAlterazione);
        alterazione.id = uuid();
        dati.alterazioniDx.push(alterazione);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeAlterazioneDx = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.alterazioniDx.splice(index, 1);
        if (!this.presenzaAlterazioni(dati.alterazioniDx, dati.alterazioniSx)) {
            dati.condizioneRilevata = null;
        }
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderAlterazioneDx = (alterazione, index) => {
        return <FieldsRow>
            <RoleBasedICD9Input
                fieldId={'PRES_ALT_DX.OCCHI.ESAMEOBIETTIVO'}
                pageState={this.props.pageState}
                onChange={(field, elem) => this.handleAggiornaForm("alterazioniDx", elem.target.value, index, "alterazione")}
                value={alterazione.alterazione}
                field={"alterazione"}
                disableDoubleInput={this.props.disableDoubleInput}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
            />
        </FieldsRow>;
    }

    /* Alterazione Sx */

    addNewAlterazioneSx = () => {
        let dati = _.cloneDeep(this.state.dati);
        let alterazione = _.cloneDeep(defaultAlterazione);
        alterazione.id = uuid();
        dati.alterazioniSx.push(alterazione);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeAlterazioneSx = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.alterazioniSx.splice(index, 1);
        if (!this.presenzaAlterazioni(dati.alterazioniDx, dati.alterazioniSx)) {
            dati.condizioneRilevata = null;
        }
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderAlterazioneSx = (alterazione, index) => {
        return <FieldsRow>
            <RoleBasedICD9Input
                fieldId={'PRES_ALT_SX.OCCHI.ESAMEOBIETTIVO'}
                pageState={this.props.pageState}
                onChange={(field, elem) => this.handleAggiornaForm("alterazioniSx", elem.target.value, index, "alterazione")}
                searchFromStart={false}
                value={alterazione.alterazione}
                field={"alterazione"}
                disableDoubleInput={this.props.disableDoubleInput}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
            />
        </FieldsRow>;
    }

    handleDispositiviArrayChange = (array, field) => {
        let data = _.cloneDeep(this.state.dati);
        data[field] = array;
        this.setState({dati: data}, () => {
            this.props.onChangeEsameObiettivo("occhi", data);
            this.forceUpdate();
        });
    }

    renderBodySezione() {
        let dati = this.state.dati;
        return <>
            <FieldsRow>
                <RoleBasedInput
                    fieldId={'ACUITA_VIS_DX.OCCHI.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    id={"acuitaVisDx"}
                    value={dati.acuitaVisDx ? dati.acuitaVisDx : ""}
                    placeholder={"Acuità visiva dx (decimi)"}
                    field={"acuitaVisDx"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    type={"number"}
                    fieldLabel={"Acuità visiva dx"}
                    disableDoubleInput={this.props.disableDoubleInput}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    min={"0"}
                    max={"20"}
                />
                <RoleBasedInput
                    fieldId={'ACUITA_VIS_SX.OCCHI.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    id={"acuitaVisSx"}
                    value={dati.acuitaVisSx ? dati.acuitaVisSx : ""}
                    placeholder={"Acuità visiva sx (decimi)"}
                    field={"acuitaVisSx"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    type={"number"}
                    fieldLabel={"Acuità visiva sx"}
                    disableDoubleInput={this.props.disableDoubleInput}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    min={0}
                    max={20}
                />
            </FieldsRow>

            <SectionList
                title={'Presenza di Alterazioni occhio destro'}
                data={dati.alterazioniDx}
                renderSection={this.renderAlterazioneDx}
                addNewSectionCallback={this.addNewAlterazioneDx}
                removeSectionCallback={this.removeAlterazioneDx}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                pageState={this.props.pageState}
                hideUnsetValues={this.props.hideUnsetValues}
                keyFieldId={'PRES_ALT_DX.OCCHI.ESAMEOBIETTIVO'}
                field={'alterazioniDx'}
                parentJsonPath={"esameObiettivo.occhi.alterazioniDx"}
                addButtonVisibilityHandler={dati.alterazioniDx?.every(a => !!a.alterazione)}
            />

            <SectionList
                title={'Presenza di Alterazioni occhio sinistro'}
                data={dati.alterazioniSx}
                renderSection={this.renderAlterazioneSx}
                addNewSectionCallback={this.addNewAlterazioneSx}
                removeSectionCallback={this.removeAlterazioneSx}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                pageState={this.props.pageState}
                keyFieldId={'PRES_ALT_SX.OCCHI.ESAMEOBIETTIVO'}
                field={'alterazioniSx'}
                parentJsonPath={"esameObiettivo.occhi.alterazioniSx"}
                addButtonVisibilityHandler={dati.alterazioniSx?.every(a => !!a.alterazione)}
            />

            <DispositiviPresenti
                handleArrayChange={this.handleDispositiviArrayChange}
                dispositiviPresenti={dati.dispositiviPresenti}
                pageState={this.props.pageState}
                fieldId={"DISPOSITIVI.OCCHI.ESAMEOBIETTIVO"}
                disableDoubleInput={this.props.disableDoubleInput}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                parentJsonPath={"esameObiettivo.occhi.dispositiviPresenti"}
            />

            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'PRES_CORREZ.OCCHI.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    id={"selectIsPresenzaCorrezione"}
                    options={OggettiUtili.rispostaBreve}
                    value={dati.isPresenzaCorrezione}
                    onChange={(elem) => this.handleAggiornaForm("isPresenzaCorrezione", elem)}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Presenza di correzione? non esistente"}
                    fieldLabel={"Presenza di correzione?"}
                    disableDoubleInput={this.props.disableDoubleInput}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    handleOnlyValue={true}
                    field={"isPresenzaCorrezione"}
                />
                {dati.isPresenzaCorrezione &&
                    <RoleBasedInput
                        fieldId={'ENTITA_CORREZ_SX.OCCHI.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"entitaCorrezioneSx"}
                        value={dati.entitaCorrezioneSx ? dati.entitaCorrezioneSx : ""}
                        placeholder={"decimi"}
                        field={"entitaCorrezioneSx"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        type={"number"}
                        fieldLabel={"Entità correzione sx (decimi)"}
                        disableDoubleInput={this.props.disableDoubleInput}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        min={0}
                        max={20}
                    />}
                {dati.isPresenzaCorrezione &&
                    <RoleBasedInput
                        fieldId={'ENTITA_CORREZ_DX.OCCHI.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"entitaCorrezioneDx"}
                        value={dati.entitaCorrezioneDx ? dati.entitaCorrezioneDx : ""}
                        placeholder={"decimi"}
                        field={"entitaCorrezioneDx"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        type={"number"}
                        fieldLabel={"Entità correzione dx (decimi)"}
                        disableDoubleInput={this.props.disableDoubleInput}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        min={0}
                        max={20}
                    />}
            </FieldsRow>

            <CondizioneRilevataSection
                ambito={"OCCHI"}
                pageState={this.props.pageState}
                parentFieldId={'OCCHI.ESAMEOBIETTIVO'}
                onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                valueCondizioneRilevata={dati.condizioneRilevata}
                onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                valueDaAttenzionare={dati.daAttenzionare}
                disableDoubleInput={this.props.disableDoubleInput}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                onChangeSuggerimenti={this.handleAggiornaForm}
                valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                showSuggerimenti={true}
                openCondition={this.presenzaAlterazioni(dati.alterazioniDx, dati.alterazioniSx)}
            />
        </>;
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"occhi"}
                    title={"Occhi"}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.occhi.alterazioniDx", "esameObiettivo.occhi.alterazioniSx",
                            "esameObiettivo.occhi.dispositiviPresenti",
                            "esameObiettivo.occhi.propostaAttivitaSuggerimenti"],
                        [defaultAlterazione, defaultAlterazione, defaultDispositiviPresenti, defaultProposteAttivitaSuggerimenti]
                    )}
                    accordionReadOnly={this.props.accordionReadOnly}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}
Occhi.propTypes = propTypes;

Occhi.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    showAccordion: false,
    disableDoubleInput: false
}
