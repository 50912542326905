import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {
    aicList,
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedAICInput,
    RoleBasedCalendar,
    RoleBasedClock,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {v4 as uuid} from "uuid";
import {Label} from "reactstrap";
import moment from "moment";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";

const propTypes = {
    datiIntegratoriEFitoterapici: PropTypes.any,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultIntegratoriEFitoterapici = {
    id: null,
    codiceAIC: null,
    dosaggio: null,
    unitaDiMisura: null,
    ultimaDoseData: null,
    ultimaDoseOra: null,
    orariPosologia: null,
    intervalloTempoPeriodicoPosologia: null,
    intervalloTempoPeriodicoDaPosologia: null,
    intervalloTempoPeriodicoAPosologia: null,
    intervalloTempoRelativoEventiVita: null,
}

export default class IntegratoriEFitoterapici extends AccordionSecondLevel {
    state = {
        datiIntegratoriEFitoterapici: _.cloneDeep(this.props.datiIntegratoriEFitoterapici),
        campiSelectelect: [
            {principioAttivo: "principioAttivo"},
            {unitaDiMisura: "unitaDiMisura"},
            {posologia: "posologia"}
        ]
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    validazioneDati = () => {
        let datiIntegratoriEFitoterapici = _.cloneDeep(this.state.datiIntegratoriEFitoterapici);
        datiIntegratoriEFitoterapici = Utils.objectToValue(datiIntegratoriEFitoterapici, this.state.campiSelectelect);
        datiIntegratoriEFitoterapici = _.mapValues(datiIntegratoriEFitoterapici, el => el === null || el === undefined || el === "" ? null : el);

        datiIntegratoriEFitoterapici.integratoriEFitoterapici = datiIntegratoriEFitoterapici.integratoriEFitoterapici?.map(dato => {
            dato = Utils.objectToValue(dato, this.state.campiSelectelect);
            return _.mapValues(dato, el => el === null || el === undefined || el === "" ? null : el);
        });

        this.props.onChangeDatiFornitureSanitarie("datiIntegratoriEFitoterapici", datiIntegratoriEFitoterapici);
        this.props.onChangeRequiredFieldsNumber("sottosezioneIntegratoriEFitoterapici", this.getNumeroCampiObbligatoriMancanti(_.cloneDeep(this.state.datiIntegratoriEFitoterapici.integratoriEFitoterapici)));
    }

    inizializzaForm = () => {
        let datiIntegratoriEFitoterapici = _.cloneDeep(this.state.datiIntegratoriEFitoterapici);
        datiIntegratoriEFitoterapici = Utils.valueToObject(datiIntegratoriEFitoterapici, this.state.campiSelectelect);

        datiIntegratoriEFitoterapici.integratoriEFitoterapici = datiIntegratoriEFitoterapici
            .integratoriEFitoterapici?.map(dato => Utils.valueToObject(dato, this.state.campiSelectelect));

        this.setState({datiIntegratoriEFitoterapici: datiIntegratoriEFitoterapici}, () => {
            this.validazioneDati();
        });
    }

    handleAggiornaForm = (field, elem, index = null) => {
        let datiIntegratoriEFitoterapici = _.cloneDeep(this.state.datiIntegratoriEFitoterapici);

        if (index === null) {
            datiIntegratoriEFitoterapici[field] = elem;
        } else {
            datiIntegratoriEFitoterapici.integratoriEFitoterapici[index][field] = elem;
        }

        if (datiIntegratoriEFitoterapici.principioAttivo?.value !== "03") {
            datiIntegratoriEFitoterapici.integratoriEFitoterapici = [];
        }

        this.setState({datiIntegratoriEFitoterapici: datiIntegratoriEFitoterapici}, () => this.validazioneDati());
    }

    addNewIntegratoriEFitoterapici = () => {
        let datiIntegratoriEFitoterapici = _.cloneDeep(this.state.datiIntegratoriEFitoterapici);
        let dato = _.cloneDeep(defaultIntegratoriEFitoterapici);
        dato.id = uuid();
        datiIntegratoriEFitoterapici.integratoriEFitoterapici.push(dato);
        this.setState({datiIntegratoriEFitoterapici: datiIntegratoriEFitoterapici}, () => {
            this.validazioneDati();
        });
    }

    removeIntegratoriEFitoterapici = (index) => {
        let datiIntegratoriEFitoterapici = _.cloneDeep(this.state.datiIntegratoriEFitoterapici);
        datiIntegratoriEFitoterapici.integratoriEFitoterapici.splice(index, 1);
        this.setState({datiIntegratoriEFitoterapici: datiIntegratoriEFitoterapici}, () => {
            if (this.state.datiIntegratoriEFitoterapici.integratoriEFitoterapici.length === 0) {
                this.setState({areDatiIntegratoriEFitoterapici: false}, () => this.validazioneDati());
            } else {
                this.validazioneDati();
            }
        });
    }

    updateClock(field, index, hh, mm) {
        if (hh && mm) {
            this.handleAggiornaForm(field, (hh + ':' + mm), index);
        } else if(!hh && mm) {
            hh = "00"
            this.handleAggiornaForm(field, (hh + ':' + mm), index);
        } else if(hh && !mm){
            mm = "00"
            this.handleAggiornaForm(field, (hh + ':' + mm), index);
        }
        else {
            this.handleAggiornaForm(field, null, index);
        }
    }

    getSintesiListaIntegratoriEFitoterapici = (integratoriEFitoterapici) => {
        return integratoriEFitoterapici
            .map(f => ({
                orariPosologia: f.orariPosologia,
                intervalloTempoPeriodicoPosologia: f.intervalloTempoPeriodicoPosologia,
                intervalloTempoPeriodicoDaPosologia: f.intervalloTempoPeriodicoDaPosologia,
                intervalloTempoPeriodicoAPosologia: f.intervalloTempoPeriodicoAPosologia,
                intervalloTempoRelativoEventiVita: f.intervalloTempoRelativoEventiVita,
            }));
    }


    getNumeroCampiObbligatoriMancanti = (integratoriEFitoterapici) => {
        let numRequiredFieldsNotValued = 0;

        if (this.checkPermissionToWriteFields()) {
            this.getSintesiListaIntegratoriEFitoterapici(integratoriEFitoterapici)
                .forEach(f => {
                    if (Object.keys(f).every(key => Utils.isStringEmptyOrNullOrUndefined(f[key]))) numRequiredFieldsNotValued += 5;
                    else {
                        if ((!Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoDaPosologia) && Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoAPosologia))
                            || (!Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoAPosologia) && Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoDaPosologia))) {
                            numRequiredFieldsNotValued++;
                        } else {
                            let isInvalidDate;
                            if (!f.intervalloTempoPeriodicoDaPosologia && f.intervalloTempoPeriodicoAPosologia) {
                                isInvalidDate = moment(f.intervalloTempoPeriodicoAPosologia, "DD/MM/YYYY") < moment().format("DD/MM/YYYY");
                            } else if (f.intervalloTempoPeriodicoDaPosologia && f.intervalloTempoPeriodicoAPosologia) {
                                isInvalidDate = moment(f.intervalloTempoPeriodicoAPosologia, "DD/MM/YYYY").isBefore(moment(f.intervalloTempoPeriodicoDaPosologia, "DD/MM/YYYY"));
                            } else isInvalidDate = false;
                            if (isInvalidDate) numRequiredFieldsNotValued++;
                        }
                    }
                });
        }

        return numRequiredFieldsNotValued;
    }

    checkPermissionToWriteFields = () => {
        const orarioWritable = isFieldIdWriteable('POSOLOGIA_ORARI.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE', this.props.pageState, this.context.forceUserRole);
        const intervalloTempoPeriodicoWritable = isFieldIdWriteable('POSOLOGIA_INT_TEMPO_PERIOD.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE', this.props.pageState, this.context.forceUserRole);
        const intervalloTempoPeriodicoDaWritable = isFieldIdWriteable('POSOLOGIA_INT_TEMPO_PERIOD_DA.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE', this.props.pageState, this.context.forceUserRole);
        const intervalloTempoPeriodicoAWritable = isFieldIdWriteable('POSOLOGIA_INT_TEMPO_PERIOD_A.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE', this.props.pageState, this.context.forceUserRole);
        const intervalloTempoRelativoEventiVitaWritable = isFieldIdWriteable('POSOLOGIA_INT_TEMPO_REL_EVENTI_VITA.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE', this.props.pageState, this.context.forceUserRole);

        return orarioWritable && intervalloTempoPeriodicoWritable && intervalloTempoPeriodicoDaWritable
            && intervalloTempoPeriodicoAWritable && intervalloTempoRelativoEventiVitaWritable
    }

    getRequiredField = (field, index) => {
        if (this.checkPermissionToWriteFields()) {
            let esiti = [];
            this.getSintesiListaIntegratoriEFitoterapici(_.cloneDeep([this.state.datiIntegratoriEFitoterapici.integratoriEFitoterapici[index]] || []))
                .forEach(f => {
                    let esito = Object.keys(f).every(key => Utils.isStringEmptyOrNullOrUndefined(f[key]));
                    if (!esito) {
                        const isIntervalloDaAndNotIntervalloA = (field === "intervalloTempoPeriodicoDaPosologia" || field === "intervalloTempoPeriodicoAPosologia")
                            && !Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoDaPosologia)
                            && Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoAPosologia);
                        const isIntervalloAAndNotIntervalloDa = (field === "intervalloTempoPeriodicoAPosologia" || field === "intervalloTempoPeriodicoDaPosologia")
                            && !Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoAPosologia)
                            && Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoDaPosologia);
                        esito = isIntervalloDaAndNotIntervalloA || isIntervalloAAndNotIntervalloDa;
                    }
                    esiti.push(esito);
                });
            return !esiti.length || esiti.every(e => e);
        } else return false;
    }

    renderNewIntegratoriEFitoterapici = (dato, index) => {
        const optionsAIC = _.cloneDeep(aicList)
            .filter(f => !this.state.datiIntegratoriEFitoterapici.integratoriEFitoterapici.find(d => dato.codiceAIC !== d.codiceAIC && d.codiceAIC === f.value));

        return (
            <div key={index}>
                <FieldsRow>
                    <RoleBasedAICInput
                        id="inputCodiceAIC"
                        fieldId={'COD_AIC.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        field={"codiceAIC"}
                        fieldLabel={"Codice AIC"}
                        pageState={this.props.pageState}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        value={dato.codiceAIC}
                        suggestions={optionsAIC}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'DOSAGGIO.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci dosaggio"}
                        field={"dosaggio"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        id={"dosaggio"}
                        value={dato.dosaggio}
                        fieldLabel={"Dosaggio"}
                        type={"number"}
                        min={0}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedSelect
                        fieldId={'DOSAGGIO.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"selectUnitaDiMisura"}
                        options={OggettiUtili.unitaDiMisura}
                        value={dato.unitaDiMisura}
                        onChange={(elem) => this.handleAggiornaForm("unitaDiMisura", elem, index)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Dosaggio non esistente"}
                        field={"unitaDiMisura"}
                        fielLabel={"Unità misura del dosaggio"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>

                {/* Posologia */}
                <FieldsRow spaceBetweenRows={false} containerClass={"pt-3"}>
                    <span className="font-weight-bold m-0">Posologia</span>
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedClock
                        fieldId={'POSOLOGIA_ORARI.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"inputOrariPosologia"}
                        fieldLabel={'Orari'}
                        value={dato.orariPosologia}
                        onChange={(hh, mm) => this.updateClock("orariPosologia", index, hh, mm)}
                        field={'orariPosologia'}
                        fieldRequired={this.getRequiredField("orariPosologia", index)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>

                <FieldsRow colNumber={-1}>
                    <div className="mb-2">
                        <strong>Intervallo di tempo periodico: Ogni </strong>
                    </div>
                    <RoleBasedInput
                        fieldId={'POSOLOGIA_INT_TEMPO_PERIOD.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        value={dato.intervalloTempoPeriodicoPosologia ?? ""}
                        field={"intervalloTempoPeriodicoPosologia"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        type={"number"}
                        min={0}
                        placeholder="Inserisci ore"
                        fieldRequired={this.getRequiredField("intervalloTempoPeriodicoPosologia", index)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <div
                        className={"mb-2 ".concat(this.getRequiredField("intervalloTempoPeriodicoPosologia", index) && "required")}>
                        <strong> ore</strong>
                    </div>
                </FieldsRow>

                <FieldsRow colNumber={-1}>
                    <div
                        className={"mb-2 ".concat(this.getRequiredField("intervalloTempoPeriodicoDaPosologia", index) && "required")}>
                        <strong>Intervallo di tempo periodico: Da </strong>
                    </div>
                    <RoleBasedCalendar
                        fieldId={'POSOLOGIA_INT_TEMPO_PERIOD_DA.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"integratoriFitoterapiciIntervalloTempoPeriodicoDaPosologia" + index}
                        value={dato.intervalloTempoPeriodicoDaPosologia}
                        onChange={(elem) => this.handleAggiornaForm("intervalloTempoPeriodicoDaPosologia", elem.target.value, index)}
                        error={
                            dato.intervalloTempoPeriodicoDaPosologia && dato.intervalloTempoPeriodicoAPosologia
                                ? moment(dato.intervalloTempoPeriodicoDaPosologia, "DD/MM/YYYY").isAfter(moment(dato.intervalloTempoPeriodicoAPosologia, "DD/MM/YYYY"))
                                : false
                        }
                        errorMessage={"Data non valida"}
                        field={'intervalloTempoPeriodicoDaPosologia'}
                        fieldRequired={this.getRequiredField("intervalloTempoPeriodicoDaPosologia", index)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <div
                        className={"mb-2 ".concat(this.getRequiredField("intervalloTempoPeriodicoAPosologia", index) && "required")}>
                        <strong> a </strong>
                    </div>
                    <RoleBasedCalendar
                        fieldId={'POSOLOGIA_INT_TEMPO_PERIOD_A.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"integratoriFitoterapiciIntervalloTempoPeriodicoAPosologia" + index}
                        value={dato.intervalloTempoPeriodicoAPosologia}
                        onChange={(elem) => this.handleAggiornaForm("intervalloTempoPeriodicoAPosologia", elem.target.value, index)}
                        error={
                            !dato.intervalloTempoPeriodicoDaPosologia && dato.intervalloTempoPeriodicoAPosologia
                                ? moment(dato.intervalloTempoPeriodicoAPosologia, "DD/MM/YYYY") < moment().format("DD/MM/YYYY")
                                : dato.intervalloTempoPeriodicoDaPosologia && dato.intervalloTempoPeriodicoAPosologia
                                    ? moment(dato.intervalloTempoPeriodicoAPosologia, "DD/MM/YYYY").isBefore(moment(dato.intervalloTempoPeriodicoDaPosologia, "DD/MM/YYYY"))
                                    : false
                        }
                        errorMessage={"Data non valida"}
                        field={'intervalloTempoPeriodicoAPosologia'}
                        fieldRequired={this.getRequiredField("intervalloTempoPeriodicoAPosologia", index)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'POSOLOGIA_INT_TEMPO_REL_EVENTI_VITA.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        options={OggettiUtili.intervalliTempoRelativiEventiVita}
                        value={dato.intervalloTempoRelativoEventiVita ?? ""}
                        onChange={(elem) => this.handleAggiornaForm("intervalloTempoRelativoEventiVita", elem, index)}
                        isSearchable={false}
                        fieldLabel={'Intervallo tempo relativo ad eventi della vita'}
                        noOptionsMessage={() => "Errore: Intervallo non esistente"}
                        handleOnlyValue={true}
                        field={"intervalloTempoRelativoEventiVita"}
                        id={"intervalloTempoRelativoEventiVita"}
                        fieldRequired={this.getRequiredField("intervalloTempoRelativoEventiVita", index)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                {/* Posologia */}

                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'ULTIMA_DOSE_DATA.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"integratoriFitoterapiciUltimaDoseData"}
                        value={dato.ultimaDoseData}
                        field="ultimaDoseData"
                        onChange={(elem) => this.handleAggiornaForm("ultimaDoseData", elem.target.value, index)}
                        errorMessage={"Data non valida"}
                        fieldLabel={"Ultima dose"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedClock
                        fieldId={'ULTIMA_DOSE_ORA.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"ultimaDoseOra"}
                        value={dato.ultimaDoseOra}
                        onChange={(hh, mm) => this.updateClock("ultimaDoseOra", index, hh, mm)}
                        field={'ultimaDoseOra'}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
            </div>
        );
    }

    renderBodyDatiIntegratoriEFitoterapici = () => {
        let datiIntegratoriEFitoterapici = _.cloneDeep(this.state.datiIntegratoriEFitoterapici);

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PRINC_ATT.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"selectPrincipioAttivo"}
                        options={OggettiUtili.principioAttivo}
                        value={datiIntegratoriEFitoterapici.principioAttivo}
                        onChange={(elem) => this.handleAggiornaForm("principioAttivo", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Principio attivo non esistente"}
                        fieldLabel={"Principio attivo"}
                        field={"principioAttivo"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>

                <SectionList
                    title={'Integratori e fitoterapici'}
                    data={datiIntegratoriEFitoterapici.integratoriEFitoterapici}
                    renderSection={this.renderNewIntegratoriEFitoterapici}
                    addNewSectionCallback={this.addNewIntegratoriEFitoterapici}
                    removeSectionCallback={this.removeIntegratoriEFitoterapici}
                    addButtonVisibilityHandler={datiIntegratoriEFitoterapici.principioAttivo?.value === "03" && this.getNumeroCampiObbligatoriMancanti(_.cloneDeep(this.state.datiIntegratoriEFitoterapici.integratoriEFitoterapici)) == 0}
                    pageState={this.props.pageState}
                    keyFieldId={'COD_AIC.INTEGRATORIEFITOTERAPICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={'integratoriEFitoterapici'}
                    parentJsonPath={"informazioniDiBase.datiIntegratoriEFitoterapici.integratoriEFitoterapici"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </>
        )
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiIntegratoriEFitoterapici"}
                title={"Integratori e fitoterapici"}
                required={this.getNumeroCampiObbligatoriMancanti(_.cloneDeep(this.state.datiIntegratoriEFitoterapici.integratoriEFitoterapici)) > 0}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["informazioniDiBase.datiIntegratoriEFitoterapici.integratoriEFitoterapici"],
                    [defaultIntegratoriEFitoterapici]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodyDatiIntegratoriEFitoterapici()}</AccordionSezioneRichiesta>
        );
    }
}

IntegratoriEFitoterapici.propTypes = propTypes;
IntegratoriEFitoterapici.contextType = RoleBasedContext;
