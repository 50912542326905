import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionHelper from "../../../service/AccordionHelper";
import Alimentazione from "../../determinantiDiSalute/alimentazione/Alimentazione";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import TrattamentiDietetici from "../../infoBase/trattamentiDietetici/TrattamentiDietetici";
import VersionedAccordion from "../../VersionedAccordion";
import Utils from "../../../utils/Utils";

const propTypes = {
    datiDeterminantiDiSalute: PropTypes.any,
    datiInformazioniDiBase: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDeterminatiDiSalute: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    hideUnsetValues: PropTypes.bool,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

export default class Dieta extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: {
            sottosezioneAlimentazione:0,
        },
        accordion: {
            openAccordionAlimentazione: true,
            openAccordionTrattamentiDietetici: true
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta.determinantiDiSalute = this.props.datiDeterminantiDiSalute;
            richiesta.datiInformazioniDiBase = this.props.datiInformazioniDiBase;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        let campiObbligatoriMancanti = this.state.campiObbligatoriMancanti;
        campiObbligatoriMancanti[field] = value;
        this.setState({campiObbligatoriMancanti: campiObbligatoriMancanti}, 
            ()=>this.props.onChangeObbligatorieta("dieta",  Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)));
    }

    renderBodySezione = () => {        
        return (
            <>
                <VersionedAccordion accordion={Alimentazione} datiAlimentazione={this.props.datiDeterminantiDiSalute.datiAlimentazione}
                               onChangeDatiAssistito={this.props.onChangeDeterminatiDiSalute}
                               openAccordion={this.state.accordion.openAccordionAlimentazione}
                               onClickAccordion={this.onClickAccordion}
                               field={"openAccordionAlimentazione"}
                               pageState={this.props.pageState}
                               onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}  
                               hideUnsetValues={this.props.hideUnsetValues}
                               accordionReadOnly={this.props.accordionReadOnly}
                               disableDoubleInput={this.props.disableDoubleInput}/>

                <VersionedAccordion accordion={TrattamentiDietetici} datiTrattamentiDietetici={this.props.datiInformazioniDiBase.trattamentiDietetici}
                                      onChangeTrattamentiDietetici={this.props.onChangeDatiInformazioniDiBase}
                                      openAccordion={this.state.accordion.openAccordionTrattamentiDietetici}
                                      onClickAccordion={this.onClickAccordion}
                                      field={"openAccordionTrattamentiDietetici"}
                                      pageState={this.props.pageState}
                                      hideUnsetValues={this.props.hideUnsetValues}
                                      accordionReadOnly={this.props.accordionReadOnly}
                                      disableDoubleInput={this.props.disableDoubleInput}/>
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"dietaInfermiere"}
                title={"Dieta"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBodySezione()}</AccordionAvvioRichiesta>
        );
    }
}

Dieta.propTypes = propTypes;
