import React from "react";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import * as _ from "lodash";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import PropTypes from "prop-types";
import {
    FieldsRow,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti,
    defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import Utils from "../../../utils/Utils";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    datiDroghe: PropTypes.any,
    onChangeDatiAssistito: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    mascheraModificabilita: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class DrogheStupefacenti extends AccordionSecondLevel {

    state = {
        datiDroghe: _.cloneDeep(this.props.datiDroghe)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiDroghe, this.props.datiDroghe))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        const datiDroghe = _.cloneDeep(this.props.datiDroghe);
        this.setState({datiDroghe}, () => this.countCampiObbligatoriRimanentiAndValida());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiDroghe}) => {
            datiDroghe[field] = elem;

            if (datiDroghe.consumoDroghe !== "03" && datiDroghe.consumoDroghe !== "04") {
                datiDroghe.tipologia = null;
                datiDroghe.condizioneRilevata = null;
            }

            return {datiDroghe};
        }, () => {
            const datiDroghe = _.cloneDeep(this.state.datiDroghe);
            this.props.onChangeDatiAssistito("datiDroghe", datiDroghe);
            this.countCampiObbligatoriRimanentiAndValida();
        });
    }

    countCampiObbligatoriRimanentiAndValida = () => {
        let dati = _.cloneDeep(this.state.datiDroghe);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneDrogheStupefacenti", campiObbligatoriRimanenti);
    }

    renderBodySezione = () => {
        const datiDroghe = this.state.datiDroghe;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'CONS_DRO_STUP.DROGHESTUPEFACENTI.DETERMINANTIDISALUTE'}
                        pageState={this.props.pageState}
                        id={"selectConsumoDroghe"}
                        options={OggettiUtili.consumoDroghe}
                        value={datiDroghe.consumoDroghe}
                        onChange={(elem) => this.handleAggiornaForm("consumoDroghe", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Consumo droghe non esistente"}
                        fieldLabel={'Consumo di droghe/stupefacenti'}
                        handleOnlyValue={true}
                        field={"consumoDroghe"}
                        forceReadOnly={this.props.forceReadOnly
                            || this.props.mascheraModificabilita?.determinantiDiSalute?.datiDroghe?.consumoDroghe}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    {(datiDroghe.consumoDroghe === "03" || datiDroghe.consumoDroghe === "04") &&
                        <RoleBasedInput
                            fieldId={'TIP.DROGHESTUPEFACENTI.DETERMINANTIDISALUTE'}
                            pageState={this.props.pageState}
                            value={datiDroghe.tipologia}
                            placeholder="Inserisci tipologia"
                            field="tipologia"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id="tipologia"
                            fieldLabel={'Tipologia'}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />}
                </FieldsRow>

                <CondizioneRilevataSection
                    ambito={"DROGHE/STUPEFACENTI"}
                    pageState={this.props.pageState}
                    parentFieldId={'DROGHESTUPEFACENTI.DETERMINANTIDISALUTE'}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={datiDroghe.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={datiDroghe.daAttenzionare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={datiDroghe.propostaAttivitaSuggerimenti}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={datiDroghe.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={datiDroghe.autonomiaGestioneCaregiver}
                    openCondition={(datiDroghe.consumoDroghe === "03" || datiDroghe.consumoDroghe === "04")}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />

                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'DATA_VAL.DROGHESTUPEFACENTI.DETERMINANTIDISALUTE'}
                        pageState={this.props.pageState}
                        id={"drogheDataValutazione"}
                        value={datiDroghe.dataValutazione}
                        onChange={(elem) => this.handleAggiornaForm("dataValutazione", elem.target.value)}
                        errorMessage={"Data valutazione non valida"}
                        fieldLabel={"Data Valutazione"}
                        field={"dataValutazione"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiDroghe"}
                title={"Droghe/stupefacenti"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState} 
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["determinantiDiSalute.datiDroghe.propostaAttivitaSuggerimenti"],
                    [defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }
}

DrogheStupefacenti.propTypes = propTypes;
