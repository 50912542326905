import React from "react";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import AccordionHelper from "../../../service/AccordionHelper";
import PropTypes from "prop-types";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import * as _ from "lodash";

import VersionedAccordion from "../../VersionedAccordion";
import DataDimissioneProgrammataDaRicoveroSub
    from "./dataDimissioneProgrammataDaRicoveroSub/DataDimissioneProgrammataDaRicoveroSub";

const propTypes = {
    dati: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiDataDimissioneProgrammataDaRicovero: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    statoRichiesta: PropTypes.string,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class DataDimissioneProgrammataDaRicovero extends AccordionFirstLevel {
    state = {
        numeroCampiObbligatoriMancanti: 0,
        accordion: {
            openSubAccordionDataDimissioneProgrammataDaRicovero: true
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        let richiesta = _.cloneDeep(this.props.richiesta);
        richiesta.dataDimissioneProgrammataDaRicovero = this.props.dati;
        this.props.salvaBozza(false, false, richiesta);
        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            })
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeRequiredFieldsNumber = (value) => {
        this.setState({numeroCampiObbligatoriMancanti: value}, () => {
            this.props.onChangeObbligatorieta("dataDimissioneProgrammataDaRicovero", this.state.numeroCampiObbligatoriMancanti);
        });
    }

    renderBody = () => {
        return (
            <>
                <VersionedAccordion accordion={DataDimissioneProgrammataDaRicoveroSub}
                                    dati={this.props.dati}
                                    statoRichiesta={this.props.statoRichiesta}
                                    onChangeDatiDataDimissioneProgrammataDaRicovero={this.props.onChangeDatiDataDimissioneProgrammataDaRicovero}
                                    openAccordion={this.state.accordion.openSubAccordionDataDimissioneProgrammataDaRicovero}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openSubAccordionDataDimissioneProgrammataDaRicovero"}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"dataDimissioneProgrammataDaRicovero"}
                title={"Data Dimissione programmata da ricovero"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                numberOfRequiredFieldsMissing={this.state.numeroCampiObbligatoriMancanti}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionAvvioRichiesta>
        );
    }
}

DataDimissioneProgrammataDaRicovero.propTypes = propTypes;
