import React, {Fragment} from "react";
import PropTypes from 'prop-types';
import styles from "./Overlay.module.css";

const propTypes = {
    contenuto: PropTypes.element
}

const defaultProps = {
    contenuto: <Fragment/>
}

export default class Overlay extends React.Component {
    state = {
    }

    render() {
        return (
            <>
                <div className={styles.overlayBackground} style={{height: document.getElementById('appBody').scrollHeight}}/>
                <div className={styles.contentBackground}>
                    <div className={styles.content}>
                        {this.props.contenuto}
                    </div>
                </div>
            </>
        )
    }
}

Overlay.propTypes = propTypes;
Overlay.defaultProps = defaultProps;
