import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import Statistica from "../../statistica/Statistica";
import Utils from "../../../utils/Utils";
import moment from "moment";
import {FieldsRow, RoleBasedCalendar, RoleBasedSelect} from "../../roleBasedComponents/RoleBasedComponents";
import enumQuestionario from "../../../enum/enumQuestionario";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg, ProposteAttivitaSuggerimentiList} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import {isArray} from "lodash";
import {SessioneUtente} from "web-client-archetype";

const propTypes = {
    datiConsumoTabaccoForm: PropTypes.any,
    openAccordion: PropTypes.any,
    richiesta: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    onChangeDatiConsumoTabacco: PropTypes.func,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    mascheraModificabilita: PropTypes.any,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class ConsumoDiTabacco extends AccordionSecondLevel {
    state = {
        datiConsumoTabaccoForm: null,
        campiSelect: [
            {isConsumoTabaccoDeterminantiSalute: "rispostaCondizioneRilevata"},
            {autonomiaGestionePaziente: "autonomiaGestione"},
            {autonomiaGestioneCaregiver: "autonomiaGestione"},
            {consumoTabacco: "consumoTabacco"},
            {statoDopoValutazione: "statoDopoValutazione"},
            {daConsiderare: "daConsiderareAiFiniAssistenziali"}
        ]
    }

    condRilevataForzeRead;

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiConsumoTabaccoForm, this.props.datiConsumoTabaccoForm)){
            this.forceUpdate(() => this.inizializzaForm());
        }
    }

    inizializzaForm = () => {
        let datiConsumoTabacco = _.cloneDeep(this.props.datiConsumoTabaccoForm)
        Utils.valueToObject(datiConsumoTabacco, this.state.campiSelect);
        this.setState({datiConsumoTabaccoForm: datiConsumoTabacco}, () => {this.validazioneDati(); this.countCampiObbligatoriRimanentiAndValida()});

    }

    validazioneDati = () => {
        let datiConsumoTabacco = _.cloneDeep(this.state.datiConsumoTabaccoForm)
        Utils.objectToValue(datiConsumoTabacco, this.state.campiSelect);
        datiConsumoTabacco = _.mapValues(datiConsumoTabacco, el => el === null || el === undefined || el === "" ? null : el);
        this.props.onChangeDatiConsumoTabacco("datiConsumoTabacco", datiConsumoTabacco);
    }

    onChangePunteggio = (tipoPunteggio, punteggio, tipoLivello) => {
        let datiConsumoTabacco = _.cloneDeep(this.state.datiConsumoTabaccoForm)
        datiConsumoTabacco[tipoPunteggio] = punteggio
        this.setState({datiConsumoTabaccoForm: datiConsumoTabacco}, () =>
            this.handleAggiornaForm(tipoPunteggio, punteggio, tipoLivello));
    }

    handleAggiornaForm = (field, elem, tipoLivello) => {
        let consumoTabacco = _.cloneDeep(this.state.datiConsumoTabaccoForm);
        if (field === "isConsumoTabaccoDeterminantiSalute") {
            consumoTabacco.isConsumoTabaccoDeterminantiSalute = elem
            if (!elem?.value) {
                if (Object.prototype.hasOwnProperty.call(consumoTabacco, "CAT")) consumoTabacco.CAT = null;
                consumoTabacco.livelloImpatto = ""
                if (Object.prototype.hasOwnProperty.call(consumoTabacco, "mMRC")) consumoTabacco.mMRC = null;
                consumoTabacco.livelloDispnea = "";
                consumoTabacco.statoDopoValutazione = null;
                consumoTabacco.daConsiderare = null;
                if (Object.prototype.hasOwnProperty.call(consumoTabacco, "FRAGERSTROM")) consumoTabacco.FRAGERSTROM = null;
                if (Object.prototype.hasOwnProperty.call(consumoTabacco, "MONDOR")) consumoTabacco.MONDOR = null;
                consumoTabacco.livelloDipendenzaNicotina = "";
                consumoTabacco.probabilitaDiSmettere = "";
                consumoTabacco.propostaAttivitaSuggerimenti = [];
                consumoTabacco.autonomiaGestionePaziente = null;
                consumoTabacco.autonomiaGestioneCaregiver = null;
            }

        } else if (field === "statoDopoValutazione") {
            consumoTabacco.statoDopoValutazione = elem
            if (elem?.value !== "02") {
                consumoTabacco.daConsiderare = null;
                if (Object.prototype.hasOwnProperty.call(consumoTabacco, "FRAGERSTROM")) consumoTabacco.FRAGERSTROM = null;
                if (Object.prototype.hasOwnProperty.call(consumoTabacco, "MONDOR")) consumoTabacco.MONDOR = null;
                consumoTabacco.livelloDipendenzaNicotina = "";
                consumoTabacco.probabilitaDiSmettere = "";
                consumoTabacco.propostaAttivitaSuggerimenti = [];
                consumoTabacco.autonomiaGestionePaziente = null;
                consumoTabacco.autonomiaGestioneCaregiver = null;
            }

        } else if (tipoLivello === "livelloImpatto") {
            switch (true) {
                case (parseInt(elem, 10) > 30):
                    consumoTabacco[tipoLivello] = "Molto Alto";
                    break;
                case (20 < parseInt(elem, 10) && parseInt(elem, 10) <= 30):
                    consumoTabacco[tipoLivello] = "Alto";
                    break;
                case (10 < parseInt(elem, 10) && parseInt(elem, 10) <= 20):
                    consumoTabacco[tipoLivello] = "Moderato";
                    break;
                case (parseInt(elem, 10) <= 10): {
                    consumoTabacco[tipoLivello] = "Lieve";
                    break;
                }
                default: {
                    consumoTabacco[tipoLivello] = "";
                    break;
                }
            }
        } else if (tipoLivello === "livelloDispnea") {
            switch (true) {
                case (parseInt(elem, 10) === 4):
                    consumoTabacco[tipoLivello] = "Alta";
                    break;
                case (2 <= parseInt(elem, 10) && parseInt(elem, 10) <= 3):
                    consumoTabacco[tipoLivello] = "Moderata";
                    break;
                case (parseInt(elem, 10) <= 1): {
                    consumoTabacco[tipoLivello] = "Lieve";
                    break;
                }
                default: {
                    consumoTabacco[tipoLivello] = "";
                    break;
                }
            }
        } else if (tipoLivello === "livelloDipendenzaNicotina") {
            switch (true) {
                // da chiedere i valori
                case (7 <= parseInt(elem, 10) && parseInt(elem, 10) <= 10):
                    consumoTabacco[tipoLivello] = "Molto forte";
                    break;
                case (5 <= parseInt(elem, 10) && parseInt(elem, 10) <= 6):
                    consumoTabacco[tipoLivello] = "Forte";
                    break;
                case (3 <= parseInt(elem, 10) && parseInt(elem, 10) <= 4):
                    consumoTabacco[tipoLivello] = "Media";
                    break;
                case (parseInt(elem, 10) <= 2): {
                    consumoTabacco[tipoLivello] = "Lieve";
                    break;
                }
                default: {
                    consumoTabacco[tipoLivello] = "";
                    break;
                }
            }
        } else if (tipoLivello === "probabilitaDiSmettere") {
            switch (true) {
                case (parseInt(elem, 10) >= 16):
                    consumoTabacco[tipoLivello] = "Ottime";
                    break;
                case (12 <= parseInt(elem, 10) && parseInt(elem, 10) < 16):
                    consumoTabacco[tipoLivello] = "Buone";
                    break;
                case (6 <= parseInt(elem, 10) && parseInt(elem, 10) < 12):
                    consumoTabacco[tipoLivello] = "Discrete";
                    break;
                case (parseInt(elem, 10) < 6): {
                    consumoTabacco[tipoLivello] = "Scarse";
                    break;
                }
                default: {
                    consumoTabacco[tipoLivello] = "";
                    break;
                }
            }
        } else {
            consumoTabacco[field] = elem;
        }

        this.setState({datiConsumoTabaccoForm: consumoTabacco}, () => {this.validazioneDati();  this.countCampiObbligatoriRimanentiAndValida()});
    }

    
    countCampiObbligatoriRimanentiAndValida = () => {
        let datiConsumoTabaccoForm = _.cloneDeep(this.state.datiConsumoTabaccoForm);
        let campiObbligatoriRimanenti = 0;

        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiConsumoTabaccoForm?.propostaAttivitaSuggerimenti)

        this.props.onChangeRequiredFieldsNumber("sottosezioneConsumoDiTabacco", campiObbligatoriRimanenti);
    }

    
    renderBody = () => {
         if(this.state.datiConsumoTabaccoForm?.propostaAttivitaSuggerimenti && isArray(this.state.datiConsumoTabaccoForm?.propostaAttivitaSuggerimenti)) {
            this.condRilevataForzeRead = (this.context.forceReadOnly || this.props.forceReadOnly || !!(this.state.datiConsumoTabaccoForm?.propostaAttivitaSuggerimenti.filter(p => p?.codiceFiscale && p?.codiceFiscale != SessioneUtente.getInstance().idUtente || p?.propostaAttivitaSuggerimenti?.codiceFiscale && p?.propostaAttivitaSuggerimenti?.codiceFiscale != SessioneUtente.getInstance().idUtente)?.length))
        }
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'CONS_TAB.CONSUMODITABACCO.DETERMINANTIDISALUTE'}
                        pageState={this.props.pageState}
                        id={"consumoTabacco"}
                        options={oggettiUtili.consumoTabacco}
                        value={this.state.datiConsumoTabaccoForm?.consumoTabacco}
                        onChange={(elem) => this.handleAggiornaForm("consumoTabacco", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Consumo di tabacco non esistente"}
                        fieldLabel={'Consumo di tabacco'}
                        field={"consumoTabacco"}
                        forceReadOnly={this.props.forceReadOnly
                            || this.props.mascheraModificabilita?.determinantiDiSalute?.datiConsumoTabacco?.consumoTabacco}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>

                {this.state.datiConsumoTabaccoForm?.consumoTabacco &&
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'COND_RIL.CONSUMODITABACCO.DETERMINANTIDISALUTE'}
                            pageState={this.props.pageState}
                            id={"selectIsConsumoTabaccoDeterminantiSalute"}
                            options={oggettiUtili.rispostaCondizioneRilevata}
                            value={this.state.datiConsumoTabaccoForm?.isConsumoTabaccoDeterminantiSalute}
                            onChange={(elem) => this.handleAggiornaForm("isConsumoTabaccoDeterminantiSalute", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Condizione rilevata non esistente"}
                            fieldLabel={'La condizione rilevata'}
                            field={"isConsumoTabaccoDeterminantiSalute"}
                            forceReadOnly={this.props.forceReadOnly || this.condRilevataForzeRead}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </FieldsRow>
                }

    
                {this.state.datiConsumoTabaccoForm?.isConsumoTabaccoDeterminantiSalute?.value &&
                <>
                    <div className="col-12 col-xl-6">
                        <Statistica
                            fieldId={"PUNT_TEST_CAT.CONSUMODITABACCO.DETERMINANTIDISALUTE"}
                            pageState={this.props.pageState}
                            nomeLink={"Compila Test CAT"}
                            nomePunteggio={"Punteggio Test CAT"}
                            questionario={this.state.datiConsumoTabaccoForm?.CAT}
                            profileName={"Livello di impatto"}
                            profileFieldId={'LIV_IMPAT.CONSUMODITABACCO.DETERMINANTIDISALUTE'}
                            onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.CAT, questionario)}
                            richiesta={this.props.richiesta}
                            pathRichiestaQuestionario={"determinantiDiSalute.datiConsumoTabacco.CAT"}
                            tipoQuestionario={enumQuestionario.tipoQuestionario.CAT}
                            profileRequired={false}
                            salvaBozza={this.props.salvaBozza}
                            parentJsonPathSubObject={'cat'}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </div>

                    <div className="col-12 col-xl-6">
                        <Statistica
                            fieldId={"PUNT_TEST_MMRC.CONSUMODITABACCO.DETERMINANTIDISALUTE"}
                            pageState={this.props.pageState}
                            nomeLink={"Compila Test mMRC"}
                            nomePunteggio={"Punteggio Test mMRC"}
                            questionario={this.state.datiConsumoTabaccoForm?.mMRC}
                            profileName={"Livello di dispnea"}
                            profileFieldId={'LIV_DISPNEA.CONSUMODITABACCO.DETERMINANTIDISALUTE'}
                            onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.mMRC, questionario)}
                            richiesta={this.props.richiesta}
                            pathRichiestaQuestionario={"determinantiDiSalute.datiConsumoTabacco.mMRC"}
                            tipoQuestionario={enumQuestionario.tipoQuestionario.mMRC}
                            profileRequired={false}
                            salvaBozza={this.props.salvaBozza}
                            parentJsonPathSubObject={'mmrc'}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </div>

                    <FieldsRow>
                        <RoleBasedCalendar
                            fieldId={"DATA_VAL.CONSUMODITABACCO.DETERMINANTIDISALUTE"}
                            pageState={this.props.pageState}
                            id={"consumoTabaccoDataValutazione"}
                            value={this.state.datiConsumoTabaccoForm?.dataValutazione}
                            onChange={(elem) => this.handleAggiornaForm("dataValutazione", elem.target.value)}
                            error={moment(this.state.datiConsumoTabaccoForm?.dataValutazione, "DD/MM/YYYY") > new Date()}
                            errorMessage={"Data non valida"}
                            fieldLabel={'Data valutazione'}
                            field={"dataValutazione"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />

                        <RoleBasedSelect
                            fieldId={"STATO_DOPO_VAL.CONSUMODITABACCO.DETERMINANTIDISALUTE"}
                            pageState={this.props.pageState}
                            id={"statoDopoValutazioneConsumoTabacco"}
                            options={oggettiUtili.statoDopoValutazione}
                            value={this.state.datiConsumoTabaccoForm?.statoDopoValutazione}
                            onChange={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                            isSearchable={false}
                            fieldLabel={'Stato dopo valutazione'}
                            noOptionsMessage={() => "Errore: stato dopo valutazione non esistente"}
                            field={"statoDopoValutazione"}
                            forceReadOnly={this.condRilevataForzeRead}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        {this.state.datiConsumoTabaccoForm?.statoDopoValutazione?.value === "02" ?
                            <RoleBasedSelect
                                fieldId={'DA_CONSID.CONSUMODITABACCO.DETERMINANTIDISALUTE'}
                                pageState={this.props.pageState}
                                options={oggettiUtili.daConsiderareAiFiniAssistenziali}
                                value={this.state.datiConsumoTabaccoForm?.daConsiderare}
                                onChange={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                                isSearchable={false}
                                fieldLabel={'Da considerare'}
                                field={"daConsiderare"}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            /> : <></>}
                    </FieldsRow>
                </>}

                {this.state.datiConsumoTabaccoForm?.statoDopoValutazione?.value === "02" &&
                <>
                    <div className="col-12 col-xl-6">
                        <Statistica
                            fieldId={"PUNT_TEST_FAGER.CONSUMODITABACCO.DETERMINANTIDISALUTE"}
                            pageState={this.props.pageState}
                            nomeLink={"Compila Test di Fragerstrom"}
                            nomePunteggio={"Punteggio Test di Fragerstrom"}
                            questionario={this.state.datiConsumoTabaccoForm?.FRAGERSTROM}
                            profileName={"Livello di dipendenza da nicotina"}
                            profileFieldId={'LIV_DIP_NICO.CONSUMODITABACCO.DETERMINANTIDISALUTE'}
                            onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.FRAGERSTROM, questionario)}
                            richiesta={this.props.richiesta}
                            pathRichiestaQuestionario={"determinantiDiSalute.datiConsumoTabacco.FRAGERSTROM"}
                            tipoQuestionario={enumQuestionario.tipoQuestionario.FRAGERSTROM}
                            profileRequired={false}
                            salvaBozza={this.props.salvaBozza}
                            parentJsonPathSubObject={'fragerstrom'}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </div>
                    <div className="col-12 col-xl-6">
                        <Statistica
                            fieldId={"PUNT_TEST_MONDOR.CONSUMODITABACCO.DETERMINANTIDISALUTE"}
                            pageState={this.props.pageState}
                            nomeLink={"Compila Test H. Mondor"}
                            nomePunteggio={"Punteggio Test H. Mondor"}
                            questionario={this.state.datiConsumoTabaccoForm?.MONDOR}
                            profileName={"Livello di smettere"}
                            profileFieldId={'PROB_SMETTERE.CONSUMODITABACCO.DETERMINANTIDISALUTE'}
                            onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.MONDOR, questionario)}
                            richiesta={this.props.richiesta}
                            pathRichiestaQuestionario={"determinantiDiSalute.datiConsumoTabacco.MONDOR"}
                            tipoQuestionario={enumQuestionario.tipoQuestionario.MONDOR}
                            profileRequired={false}
                            salvaBozza={this.props.salvaBozza}
                            parentJsonPathSubObject={'mondor'}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </div>
                    {this.state.datiConsumoTabaccoForm?.isConsumoTabaccoDeterminantiSalute &&
                    <>
                        <ProposteAttivitaSuggerimentiList                            
                            suggerimenti={this.state.datiConsumoTabaccoForm?.propostaAttivitaSuggerimenti && !isArray(this.state.datiConsumoTabaccoForm?.propostaAttivitaSuggerimenti) ? [this.state.datiConsumoTabaccoForm?.propostaAttivitaSuggerimenti] : this.state.datiConsumoTabaccoForm?.propostaAttivitaSuggerimenti}   
                            pageState={this.props.pageState} 
                            ambito={"CONSUMO DI TABACCO"}
                            onChange={(name, value) => {this.handleAggiornaForm(name, value)}}
                            parentFieldId={"CONSUMODITABACCO.DETERMINANTIDISALUTE"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            infParentFieldId={"CONSUMODITABACCO.DETERMINANTIDISALUTE"}
                            id={"ConsumoTabacco"}/>

                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'AUT_GEST_PAZ.CONSUMODITABACCO.DETERMINANTIDISALUTE'}
                                pageState={this.props.pageState}
                                id={"selectAutonomiaGestionePaziente"}
                                className={"selectAutonomiaGestionePaziente"}
                                options={oggettiUtili.autonomiaGestione}
                                value={this.state.datiConsumoTabaccoForm?.autonomiaGestionePaziente}
                                onChange={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                                isSearchable={false}
                                fieldLabel={'Autonomia di gestione del paziente'}
                                field={"autonomiaGestionePaziente"}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                            <RoleBasedSelect
                                fieldId={'AUT_GEST_CAREG.CONSUMODITABACCO.DETERMINANTIDISALUTE'}
                                pageState={this.props.pageState}
                                id={"autonomiaGestioneCaregiver"}
                                options={oggettiUtili.autonomiaGestione}
                                value={this.state.datiConsumoTabaccoForm?.autonomiaGestioneCaregiver}
                                onChange={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                                isSearchable={false}
                                fieldLabel={'Autonomia di gestione del caregiver'}
                                noOptionsMessage={() => "Errore: Autonomia di gestione del caregiver non esistente"}
                                field={"autonomiaGestioneCaregiver"}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                        </FieldsRow>
                    </>}
                </>}
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiConsumoTabacco"}
                title={"Consumo Di Tabacco"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}
ConsumoDiTabacco.propTypes = propTypes;

