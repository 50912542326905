import Utils from "../../utils/Utils";
import AuthUtils from "../../utils/AuthUtils";
import { SessioneUtente } from "web-client-archetype";
import azioniRichiesta from "../../utils/dataset/richiestaData/azioniRichiesta.json";
import statiRichiesta from "../../utils/dataset/richiestaData/statiRichiesta.json";
import scadenzeAttivitaJson from "../../utils/dataset/prioritaData/scadenze_attivita.json";
import enumRichiesta from "../../enum/enumRichiesta.json";
import routepath from "../../utils/route/route-path.json";
import enumsUtente from "../../enum/enumsUtente.json";
import * as Icon from "react-bootstrap-icons";
import React from "react";
import oggettiUtili from "../../utils/dataset/OggettiUtili.json";
import * as _ from "lodash";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import PopoverReact from "../popover/Popover";
import styles from "./Home.module.css";
import classificazione from "../../utils/dataset/richiestaData/classificazione.json"

export const getValutazionePrecedente = (richiesta) => {
    return _.find(richiesta?.valutazioni, v => v.valutatore?.codiceFiscale === SessioneUtente.getInstance().idUtente)?.valutazionePrecedente ?? null;
}

export const OGGETTO_SUBENTRA = {
    label: azioniRichiesta.subentra.label,
    linkProps: {}
}

export const canShowRequiredAccordionPage = (statoRichiesta) => {
    const statiRichiestaValidi = [
        azioniRichiesta.avviaNuovaRichiesta.label, azioniRichiesta.avviaNuovoPreDialogo.label,
        azioniRichiesta.completaRichiesta.label, azioniRichiesta.completaPreDialogo.label,
        azioniRichiesta.completaValutazione.label, azioniRichiesta.completaValutazionePreDialogo.label
    ];
    return statiRichiestaValidi.some(s => _.isEqual(statoRichiesta, s));
}

export const getLinkProps = (label, richiesta, showMultiCompilationPages = false) => {
    return (
        {
            pathname: getLinkStatoRichiesta(label),
            uuidRichiesta: richiesta.id,
            tipologia: richiesta.tipologia,
            stato: richiesta.stato,
            valutazionePrecedente: getValutazionePrecedente(richiesta),
            showOnlyRequiredFields: canShowRequiredAccordionPage(label),
            rediretDaHome: true,
            showMultiCompilationPages: showMultiCompilationPages
        }
    )
}

export const getLinkStatoRichiesta = (statoRichiesta) => {
    switch (statoRichiesta) {
        //Completa richiesta e Completa pre-dialogo
        case azioniRichiesta.completaRichiesta.label:
        case azioniRichiesta.completaPreDialogo.label:
            return routepath.inserimento_richiesta.concat(routepath.compila_scheda_paziente);
        case azioniRichiesta.attivaProfessionista.label:
            if (AuthUtils.hasUtenteRuoloFormalizzatore() || AuthUtils.hasUtenteRuoloCoordinatore() || AuthUtils.hasUtenteCentraleAdi()) return routepath.verifica_valutazione + routepath.stato_valutazione_componenti_team;
            return routepath.valutazione_multidimensionale + routepath.accedi_valutazione_multidimensionale + routepath.stato_valutazione_componenti_team;
        //Accedi valutazione, Accedi valutazione pre-dialogo e Attiva professionista
        case azioniRichiesta.accediValutazione.label:
        case azioniRichiesta.accediValutazionePreDialogo.label:
            if (AuthUtils.hasUtenteRuoloFormalizzatore() || AuthUtils.hasUtenteRuoloCoordinatore() || AuthUtils.hasUtenteCentraleAdi()) return routepath.verifica_valutazione;
            return routepath.valutazione_multidimensionale + routepath.accedi_valutazione_multidimensionale;
        //Completa valutazione e completa valutazione pre-dialogo
        case azioniRichiesta.completaValutazione.label:
        case azioniRichiesta.completaValutazionePreDialogo.label:
            return routepath.valutazione_multidimensionale.concat(routepath.completa_valutazione_multidimensionale);
        //Visualizza valutazione e visualizza valutazione pre-dialogo
        case azioniRichiesta.visualizzaValutazione.label:
        case azioniRichiesta.visualizzaValutazionePreDialogo.label:
            if (AuthUtils.hasUtenteRuoloFormalizzatore() || AuthUtils.hasUtenteRuoloCoordinatore() || AuthUtils.hasUtenteCentraleAdi()) return routepath.verifica_valutazione;
            return routepath.valutazione_multidimensionale + routepath.visualizza_valutazione_multidimensionale;
        //Avvia formalizzazione
        case azioniRichiesta.avviaFormalizzazione.label:
            return routepath.verifica_valutazione;
        //Completa formalizzazione
        case azioniRichiesta.completaFormalizzazione.label:
            return routepath.compila_formalizzazione;
        case azioniRichiesta.avviaChiusuraPreDialogo.label:
        case azioniRichiesta.completaChiusuraPreDialogo.label:
            return routepath.compila_chiusura_predialogo + routepath.compila_scheda_paziente;
        //Visualizza per presa in carico e visualizza pre-dialogo per presa in carico
        case azioniRichiesta.visualizzaPreDialogoPerPresaInCarico.label:
            return routepath.valutazione_multidimensionale + routepath.visualizza_valutazione_multidimensionale;
        default:
            return '';
    }
}

export default class HomeUtils {

    static changeFiltriRapidiFromFiltriRichiesteSubject = new BehaviorSubject();


    static getStato(livelloPriorita, timestamp, fase, tipologiaRichiesta, timestampEsitoPreDialogo) {
        let tipoPriorita;
        let data = new Date();
        data.setMinutes(0);
        data.setSeconds(0);
        data.setMilliseconds(0);
        data = Utils.convertUtcToLocalDate(data);

        if (timestamp) {
            timestamp = new Date(timestamp);
            timestamp.setMinutes(0);
            timestamp.setSeconds(0);
            timestamp.setMilliseconds(0);
            timestamp = Utils.convertUtcToLocalDate(timestamp);
        }

        let stato = {
            sNome: "",
            cssClass: "stickerTrattino"
        }

        if (tipologiaRichiesta === enumRichiesta.tipologia.SEGNALAZIONE) {
            tipoPriorita = scadenzeAttivitaJson.filter(e => e.tipologiaRichiesta === tipologiaRichiesta).filter(e => e.fase === (timestampEsitoPreDialogo === null ? enumRichiesta.fase.PREDIALOGO : enumRichiesta.fase.CLASSICA))?.shift()?.tipoPriorita?.filter(e => e.codice === livelloPriorita)?.shift();
        }
        else {
            tipoPriorita = scadenzeAttivitaJson.filter(e => e.tipologiaRichiesta === tipologiaRichiesta)?.shift()?.tipoPriorita?.filter(e => e.codice === livelloPriorita)?.shift();
        }

        if (tipoPriorita) {
            if (fase === "F") {
                HomeUtils.setStato(data, timestamp, tipoPriorita?.tempisticheAttivita.oreFormalizzazioneInScadenza, tipoPriorita?.tempisticheAttivita.oreFormalizzazioneScaduta, stato);
            }
            else {
                HomeUtils.setStato(data, timestamp, tipoPriorita?.tempisticheAttivita.oreValutazioneInScadenza, tipoPriorita?.tempisticheAttivita.oreValutazioneScaduta, stato);
            }
        }

        return stato;

    }

    static setStato(data, timestamp, timestampMin, timestampMax, stato) {

        if (timestamp) {
            if (data.isSameOrAfter(moment(timestamp).add(timestampMin, "hours")) && data.isBefore(moment(timestamp).add(timestampMax, "hours"))) {
                stato.sNome = "In scadenza";
                stato.cssClass = "stickerYellow";
            }
            else if (data.isSameOrAfter(moment(timestamp).add(timestampMax, "hours"))) {
                stato.sNome = "Scaduta";
                stato.cssClass = "stickerRed";
            }
        }
    }

    static getStatoTeamValutazione(richiesta, profilo) {

        let professionista = richiesta.team?.find(professionista => professionista.profilo === profilo);

        if (!Utils.isStringEmptyOrNullOrUndefined(professionista)) {
            if (!Utils.isStringEmptyOrNullOrUndefined(professionista?.timestampConferma)) {
                return <>
                    <PopoverReact
                        text={<Icon.PeopleFill color={"#176A65"} size={15} />}
                        body={"Confermato"}
                        openOnMouseOver={true}
                        position={'bottom'}
                        className={'popover-info'}
                        align={"center"}
                        arrowContainerClassName={[styles.containerPopoverTeamIcon, styles.teamIconConfermato].join(" ")}
                    />
                </>;
            } else {
                return <>
                    <PopoverReact
                        text={<Icon.PeopleFill color={"#8FB5B2"} size={15} />}
                        body={"Non confermato"}
                        openOnMouseOver={true}
                        position={'bottom'}
                        className={'popover-info'}
                        align={"center"}
                        arrowContainerClassName={[styles.containerPopoverTeamIcon, styles.teamIconNonConfermato].join(" ")}
                    />
                </>;
            }
        } else {
            return <>-</>;
        }
    }

    static getIconaOspedale(tipologiaRichiesta) {
        if (tipologiaRichiesta === enumRichiesta.tipologia.SEGNALAZIONE) {
            return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#176A65" className="bi bi-hospital-fill" viewBox="0 0 16 16">
                <path d="M6 0a1 1 0 0 0-1 1v1a1 1 0 0 0-1 1v4H1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h6v-2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V16h6a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-3V3a1 1 0 0 0-1-1V1a1 1 0 0 0-1-1H6Zm2.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1h1ZM2.25 9h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 2 9.75v-.5A.25.25 0 0 1 2.25 9Zm0 2h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25ZM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5ZM13.25 9h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25ZM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Zm.25 1.75h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25Z" />
            </svg>)
        }
        else return <></>;
    }

    static mapGetRichiesteHome(response) {
        let res = [];

        console.log("SESSIONE UTENTE: ", SessioneUtente.getInstance())

        response.forEach(function (richiesta) {

            let element = {}
            let valutazionePreDialogo = null;

            element.primiLink = [];
            element.cognomeNome = richiesta.assistito?.cognome + " " + richiesta.assistito?.nome;
            element.iconaOspedale = HomeUtils.getIconaOspedale(richiesta.tipologia);
            element.dataRichiesta = richiesta.timestampCompletamento ? Utils.transformTimeStampForFrontend(richiesta.timestampCompletamento) : "-";
            element.dataDimissioneProgrammataDaRicovero = richiesta?.dataDimissioneProgrammataDaRicovero?.dataDimissioneProgrammataDaRicovero;

            if (richiesta.valutazioniPreDialogo) {
                richiesta.valutazioniPreDialogo.forEach(function (val) {
                    if (SessioneUtente.getInstance().idUtente === val.valutatore.codiceFiscale) {
                        valutazionePreDialogo = val;
                    }
                })
            }

            // recupero ultima valutazione, se presente, dell'utente loggato
            let valutazione = HomeUtils.getUltimaValutazioneperUtenteLoggato(richiesta);

            if (AuthUtils.hasRuoloMedicoMedicinaGenerale() || AuthUtils.hasUtenteRuoloMcp() || AuthUtils.hasUtenteCentraleAdi()) {
                if (richiesta.valutazioni) {
                    richiesta.valutazioni.forEach(function (val) {
                        if (SessioneUtente.getInstance().idUtente === val.valutatore.codiceFiscale) {
                            if (!Utils.isStringEmptyOrNullOrUndefined(val.timestampConferma)) {
                                element.valutazioneProfessionale = {
                                    sNome: Utils.transformTimeStampForFrontend(val.timestampConferma),
                                    cssClass: "stickerGreen"
                                }
                            }
                            else element.valutazioneProfessionale = HomeUtils.getStato(richiesta.livelloPriorita, richiesta.timestampCompletamentoPreDialogo, "V", enumRichiesta.tipologia.SEGNALAZIONE, null);
                        }
                    })
                }

                if (Utils.isStringEmptyOrNullOrUndefined(element.valutazioneProfessionale)) {
                    element.valutazioneProfessionale = { sNome: "", cssClass: "stickerTrattino" }
                }
            }

            if (AuthUtils.hasUtenteRuoloProfessionistaValutatore() ||
                AuthUtils.hasUtenteRuoloCoordinatore() ||
                AuthUtils.hasUtenteProfiloDirettoreUO() ||
                AuthUtils.hasUtenteProfiloReferenteAssistentiSociali()) {

                if (richiesta.valutazioni) {
                    richiesta.valutazioni.forEach(function (val) {
                        if (SessioneUtente.getInstance().idUtente === val.valutatore.codiceFiscale) {
                            if (!Utils.isStringEmptyOrNullOrUndefined(val.timestampConferma)) {
                                element.valutazioneProfessionale = {
                                    sNome: Utils.transformTimeStampForFrontend(val.timestampConferma),
                                    cssClass: "stickerGreen"
                                }
                            }
                            else element.valutazioneProfessionale = HomeUtils.getStato(richiesta.livelloPriorita, richiesta.timestampCompletamento, "V", richiesta.tipologia, richiesta?.timestampEsitoPreDialogo);
                        }
                    })
                }


                if (Utils.isStringEmptyOrNullOrUndefined(element.valutazioneProfessionale)) {
                    element.valutazioneProfessionale = { sNome: "", cssClass: "stickerTrattino" }
                }
            }

            // TODO - da rivedere
            let operatoreDelega = null;
            if (richiesta?.delega?.length > 0) {
                operatoreDelega = richiesta.delega.find(delega => delega?.valore === true)?.profilo;
            }

            if (operatoreDelega) {
                if (operatoreDelega === enumsUtente.profiliUtente.centraleAdi) {
                    element.teamValutazione = HomeUtils.getStatoTeamValutazione(richiesta, enumsUtente.profiliUtente.centraleAdi);
                    element.inCaricoA = HomeUtils.getInCaricoA(HomeUtils.getProfessionistaRichiesta("centraleAdiDelega", richiesta));
                }
            }
            else {

                if (AuthUtils.hasUtenteRuoloFormalizzatore()) {
                    element.teamValutazione = HomeUtils.getStatoTeamValutazione(richiesta, enumsUtente.profiliUtente.medicoFormalizzatore);
                    element.inCaricoA = HomeUtils.getInCaricoA(HomeUtils.getProfessionistaRichiesta("formalizzatore", richiesta));
                }

                if (AuthUtils.hasUtenteRuoloCoordinatore()) {
                    element.teamValutazione = HomeUtils.getStatoTeamValutazione(richiesta, enumsUtente.profiliUtente.coordinatore);
                    element.inCaricoA = HomeUtils.getInCaricoA(HomeUtils.getProfessionistaRichiesta("coordinatore", richiesta));
                }

                if (AuthUtils.hasUtenteCentraleAdi()) {
                    element.teamValutazione = HomeUtils.getStatoTeamValutazione(richiesta, enumsUtente.profiliUtente.centraleAdi);
                    element.inCaricoA = HomeUtils.getInCaricoA(HomeUtils.getProfessionistaRichiesta("centraleAdi", richiesta));
                }
            }

            element.livelloPriorita = HomeUtils.getLivelloPriorita(richiesta.livelloPriorita);
            element.medicoRichiedente = HomeUtils.getMedicoRichiedente(richiesta.richiedente);

            if (richiesta.tipologia === enumRichiesta.tipologia.SEGNALAZIONE && richiesta.timestampEsitoPreDialogo === null) {
                if (!Utils.isStringEmptyOrNullOrUndefined(richiesta.timestampConfermaPreDialogo)) {
                    element.valutazioneMultid = {
                        sNome: Utils.transformTimeStampForFrontend(richiesta.timestampConfermaPreDialogo),
                        cssClass: "stickerGreen"
                    }
                }
                else {
                    element.valutazioneMultid = HomeUtils.getStato(richiesta.livelloPrioritaPreDialogo, richiesta.timestampCompletamentoPreDialogo, "V", richiesta.tipologia, richiesta.timestampEsitoPreDialogo);
                }
            }
            else if (richiesta.tipologia === enumRichiesta.tipologia.CLASSICA || (richiesta.tipologia === enumRichiesta.tipologia.SEGNALAZIONE && richiesta.timestampEsitoPreDialogo !== null)) {
                if (!Utils.isStringEmptyOrNullOrUndefined(richiesta.timestampConferma)) {
                    element.valutazioneMultid = {
                        sNome: Utils.transformTimeStampForFrontend(richiesta.timestampConferma),
                        cssClass: "stickerGreen"
                    }
                }
                else {
                    element.valutazioneMultid = HomeUtils.getStato(richiesta.livelloPriorita, richiesta.timestampCompletamento, "V", richiesta.tipologia, richiesta.timestampEsitoPreDialogo);
                }
            }
            if (Utils.isStringEmptyOrNullOrUndefined(element.valutazioneMultid)) {
                element.valutazioneMultid = { sNome: "", cssClass: "stickerTrattino" }
            }

            if (richiesta.tipologia === enumRichiesta.tipologia.SEGNALAZIONE && richiesta.timestampEsitoPreDialogo === null) {
                element.formalizzazione = HomeUtils.getStato(richiesta.livelloPrioritaPreDialogo, richiesta.timestampCompletamentoPreDialogo, "F", richiesta.tipologia, richiesta.timestampEsitoPreDialogo);
            }
            else if (richiesta.tipologia === enumRichiesta.tipologia.CLASSICA || (richiesta.tipologia === enumRichiesta.tipologia.SEGNALAZIONE && richiesta.timestampEsitoPreDialogo !== null)) {
                element.formalizzazione = HomeUtils.getStato(richiesta.livelloPriorita, richiesta.timestampCompletamento, "F", richiesta.tipologia, richiesta.timestampEsitoPreDialogo);
            }
            if (Utils.isStringEmptyOrNullOrUndefined(element.formalizzazione)) {
                element.formalizzazione = { sNome: "", cssClass: "stickerTrattino" }
            }

            HomeUtils.setAzioneRichiesta(richiesta, element, valutazione, valutazionePreDialogo);
            element.linkStatoRichiesta = "";
            element.uuidRichiesta = richiesta.id;
            element.messaggioDaVisualizzare = HomeUtils.getMessaggioDaVisualizzare(richiesta, element.primiLink);
            element.valutazionePrecedente = getValutazionePrecedente(richiesta);
            element.tipologia = richiesta.tipologia;
            element.stato = richiesta.stato;
            element.showOnlyRequiredFields = true;
            element.showMultiCompilationPages = false;


            res.push(element)
        })

        if (res.length === 0) {
            return response;
        }
        return res
    }


    static getUltimaValutazioneperUtenteLoggato = (richiesta) => {
        let valutazione = null;
        if (richiesta?.valutazioni?.length) {
            valutazione =  _
                .chain(_.cloneDeep(richiesta.valutazioni))
                .filter(v => SessioneUtente.getInstance().idUtente === v.valutatore?.codiceFiscale)
                .map(v => {
                    v.timestampInserimento = moment(v.timestampInserimento, "YYYY-MM-DD'T'HH:mm:ss").unix();
                    return v;
                })
                .maxBy('timestampInserimento')
                .value();
        }
        return valutazione;
    }

    static getMessaggioDaVisualizzare(richiesta, primiLink) {
        if (richiesta?.tipologia === enumRichiesta.tipologia.CLASSICA &&
            (AuthUtils.hasRuoloMedicoRichiedenteAndMedicoMedicinaGenerale() || AuthUtils.hasRuoloMedicoRichiedenteAndPediatraLiberaScelta()) &&
            richiesta?.mmg?.codiceFiscale === SessioneUtente.getInstance().idUtente &&
            richiesta?.richiedente?.codiceFiscale !== SessioneUtente.getInstance().idUtente &&
            primiLink?.length > 0 &&
            primiLink[0]?.label === azioniRichiesta.visualizzaValutazione.label) {
            return "Vuoi prendere in carico la richiesta avviata da " + richiesta?.richiedente?.nome + " " + richiesta?.richiedente?.cognome + " oppure vuoi solo visualizzarla?";
        }
        else return null;
    }

    static getProfessionistaRichiesta(ruoloProfessionista, richiesta) {

        let professionista;

        switch (ruoloProfessionista) {
            case "formalizzatore":
                professionista = richiesta.team?.find(el => el.profilo === enumsUtente.profiliUtente.medicoFormalizzatore)?.professionista;
                if (professionista && AuthUtils.hasUtenteRuoloFormalizzatore()) return professionista;
                else return null;
            case "coordinatore":
                professionista = richiesta.team?.find(el => el.profilo === enumsUtente.profiliUtente.coordinatore && Utils.isStringEmptyOrNullOrUndefined(el.disciplina))?.professionista;
                if (professionista) return professionista;
                else return null;
            case "refDirigenteAssistentiSociali":
                professionista = richiesta.team?.find(el => el.profilo === enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali)?.professionista;
                if (professionista && AuthUtils.hasUtenteProfiloReferenteAssistentiSociali()) return professionista;
                else return null;
            case "centraleAdi":
                professionista = richiesta.team?.find(el => el.profilo === enumsUtente.profiliUtente.centraleAdi && el.ruolo === enumsUtente.ruoli.operatore)?.professionista;
                if (professionista) return professionista;
                else return null;
            case "centraleAdiCurePalliative":
                professionista = richiesta.team?.find(el => el.profilo === enumsUtente.profiliUtente.centraleAdi && el.ruolo === enumsUtente.ruoli.operatore_cure_palliative)?.professionista;
                if (professionista) return professionista;
                else return null;
            case "coordinatorePalliativo":
                professionista = richiesta.team?.find(el => el.profilo === enumsUtente.profiliUtente.coordinatore && !Utils.isStringEmptyOrNullOrUndefined(el.disciplina))?.professionista;
                if (professionista) return professionista;
                else return null;
            default:
                return null;
        }
    }

    static getLinkVisualizzaValutazione = (richiesta) => {
        let link = null
        if (!_.isEqual(richiesta.assistito?.aulss, richiesta.aulss)
            || !_.isEqual(richiesta.assistito?.distretto, richiesta.distretto)) {
            link = azioniRichiesta.visualizzaValutazione.label;
        }
        return link;
    }

    static setAzioneRichiesta(richiesta, element, valutazione) {

        const OGGETTO_COMPLETA_VALUTAZIONE = {
            label: azioniRichiesta.completaValutazione.label,
            linkProps: getLinkProps(azioniRichiesta.completaValutazione.label, richiesta, true)
        }

        const OGGETTO_VISUALIZZA_VALUTAZIONE = {
            label: azioniRichiesta.visualizzaValutazione.label,
            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
        };
        const OGGETTO_ACCEDI_VALUTAZIONE = {
            label: azioniRichiesta.accediValutazione.label,
            linkProps: getLinkProps(azioniRichiesta.accediValutazione.label, richiesta, true)
        };
        const OGGETTO_ATTIVA_PROFESSIONISTA = {
            label: azioniRichiesta.attivaProfessionista.label,
            linkProps: getLinkProps(azioniRichiesta.attivaProfessionista.label, richiesta, true)
        };
        const OGGETTO_PRENDI_IN_CARICO = {
            label: azioniRichiesta.prendiInCarico.label,
            linkProps: {}
        };
        const OGGETTO_COMPLETA_FORMALIZZAZIONE = {
            label: azioniRichiesta.completaFormalizzazione.label,
            linkProps: getLinkProps(azioniRichiesta.completaFormalizzazione.label, richiesta)
        };
        const OGGETTO_AVVIA_FORMALIZZAZIONE = {
            label: azioniRichiesta.avviaFormalizzazione.label,
            linkProps: getLinkProps(azioniRichiesta.avviaFormalizzazione.label, richiesta, true)
        };
        const OGGETTO_FORZA_VALUTAZIONE = {
            label: azioniRichiesta.forzaValutazione.label,
            linkProps: {}
        };
        const OGGETTO_ANNULLA_RICHIESTA = {
            label: azioniRichiesta.annullaRichiesta.label,
            linkProps: {}
        }
        if (AuthUtils.hasRuoloMedicoRichiedenteAndMedicoMedicinaGenerale() || AuthUtils.hasRuoloMedicoRichiedenteAndPediatraLiberaScelta()) {
            switch (richiesta.stato) {
                case statiRichiesta.bozza.value:
                    if (richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        element.primiLink = [
                            {
                                label: azioniRichiesta.completaRichiesta.label,
                                linkProps: getLinkProps(azioniRichiesta.completaRichiesta.label, richiesta)
                            }
                        ]
                        element.secondiLink = [
                            {
                                label: azioniRichiesta.annullaRichiesta.label,
                                linkProps: {}
                            }
                        ]
                    }
                    else {
                        element.primiLink = [
                            {
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }
                        ]
                    }
                    break;
                case statiRichiesta.inviata.value:
                case statiRichiesta.inConferimento.value:
                case statiRichiesta.inValutazione.value:
                    if (richiesta.tipologia === enumRichiesta.tipologia.CLASSICA && richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        element.primiLink = [
                            {
                                label: this.getLinkVisualizzaValutazione(richiesta) ?? richiesta?.sostituzioneRichiedente ? azioniRichiesta.visualizzaValutazione.label : azioniRichiesta.accediValutazione.label,
                                linkProps: getLinkProps(this.getLinkVisualizzaValutazione(richiesta) ?? richiesta?.sostituzioneRichiedente ? azioniRichiesta.visualizzaValutazione.label : azioniRichiesta.accediValutazione.label, richiesta, true)
                            }
                        ]
                        element.secondiLink = [
                            {
                                label: richiesta?.sostituzioneRichiedente ? null : azioniRichiesta.annullaRichiesta.label,
                                linkProps: {}
                            }
                        ]
                    }
                    else if (richiesta.tipologia === enumRichiesta.tipologia.CURE_PALLIATIVE && richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        if (valutazione?.valutatore?.codiceFiscale === SessioneUtente.getInstance().idUtente && valutazione?.stato === statiRichiesta.bozza.value) {
                            element.primiLink = [
                                OGGETTO_COMPLETA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE
                            ]
                        }
                        else if (valutazione?.valutatore?.codiceFiscale === SessioneUtente.getInstance().idUtente && valutazione?.stato === statiRichiesta.confermata.value) {
                            element.primiLink = [
                                OGGETTO_VISUALIZZA_VALUTAZIONE
                            ]
                        }
                        element.secondiLink = [
                            {
                                label: azioniRichiesta.annullaRichiesta.label,
                                linkProps: {}
                            }
                        ]
                    }
                    else {
                        element.primiLink = [
                            {
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }
                        ]
                    }
                    break;
                case statiRichiesta.valutata.value:
                    if (richiesta.tipologia === enumRichiesta.tipologia.CLASSICA && richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        let linksPrimari = [];
                        if(richiesta?.sostituzioneRichiedente){
                            linksPrimari = [
                                OGGETTO_VISUALIZZA_VALUTAZIONE
                            ]
                        } else {
                            linksPrimari = [
                                OGGETTO_COMPLETA_VALUTAZIONE,
                                OGGETTO_ACCEDI_VALUTAZIONE
                            ]
                        }
                        element.primiLink = linksPrimari;
                        element.secondiLink = [
                            {
                                label: richiesta?.sostituzioneRichiedente ? null : azioniRichiesta.annullaRichiesta.label,
                                linkProps: {}
                            }
                        ]
                    }
                    else if (richiesta.tipologia === enumRichiesta.tipologia.CURE_PALLIATIVE && richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        element.primiLink = [
                            {
                                OGGETTO_VISUALIZZA_VALUTAZIONE
                            }
                        ]
                        element.secondiLink = [
                            {
                                label: azioniRichiesta.annullaRichiesta.label,
                                linkProps: {}
                            }
                        ]
                    }
                    else {
                        element.primiLink = [
                            {
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }
                        ]
                    }
                    break;
                case statiRichiesta.confermata.value:
                    if (richiesta.tipologia === enumRichiesta.tipologia.CLASSICA && richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        element.primiLink = [
                            {
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }
                        ]
                        element.secondiLink = [
                            {
                                label: azioniRichiesta.annullaRichiesta.label,
                                linkProps: {}
                            }
                        ]
                    }
                    else if (richiesta.tipologia === enumRichiesta.tipologia.CURE_PALLIATIVE && richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        element.primiLink = [
                            OGGETTO_VISUALIZZA_VALUTAZIONE
                        ]
                        element.secondiLink = [
                            {
                                label: azioniRichiesta.annullaRichiesta.label,
                                linkProps: {}
                            }
                        ]
                    }
                    else {
                        element.primiLink = [
                            {
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }
                        ]
                    }
                    break;
                case statiRichiesta.inFormalizzazione.value:
                    element.primiLink = [
                        {
                            label: azioniRichiesta.visualizzaValutazione.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                        }
                    ]
                    break;
            }
        }
        else if (AuthUtils.hasRuoloMedicoRichiedenteAndMedicoOspedaliero() || (AuthUtils.hasRuoloMedicoRichiedenteAndMedicoOspedaliero() && AuthUtils.hasRuoloValutatoreAndMedicoOspedaliero())) {
            switch (richiesta.stato) {
                case statiRichiesta.bozzaPreDialogo.value:
                    element.primiLink = [
                        {
                            label: azioniRichiesta.completaPreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.completaPreDialogo.label, richiesta)
                        }
                    ]
                    element.secondiLink = [
                        {
                            label: azioniRichiesta.annullaRichiesta.label,
                            linkProps: {}
                        }
                    ]
                    break;
                case statiRichiesta.inviataPreDialogo.value:
                case statiRichiesta.inConferimentoPreDialogo.value:
                case statiRichiesta.inValutazionePreDialogo.value:
                    element.primiLink = [
                        {
                            label: azioniRichiesta.accediValutazionePreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.accediValutazionePreDialogo.label, richiesta, true)
                        }
                    ]
                    element.secondiLink = [
                        { label: azioniRichiesta.annullaRichiesta.label, linkProps: {} },
                        { label: azioniRichiesta.modificaDataDimissione.label, linkProps: {} }
                    ]
                    break;
                case statiRichiesta.valutataPreDialogo.value:
                    element.primiLink = [
                        {
                            label: azioniRichiesta.completaValutazionePreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.completaValutazionePreDialogo.label, richiesta)
                        }
                    ]
                    element.secondiLink = [
                        { label: azioniRichiesta.annullaRichiesta.label, linkProps: {} },
                        { label: azioniRichiesta.modificaDataDimissione.label, linkProps: {} }
                    ]
                    break;
                case statiRichiesta.confermataPreDialogo.value:
                case statiRichiesta.inChiusuraPreDialogo.value:
                    element.primiLink = [
                        {
                            label: azioniRichiesta.visualizzaValutazionePreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazionePreDialogo.label, richiesta, true)
                        }
                    ]
                    element.secondiLink = [
                        { label: azioniRichiesta.annullaRichiesta.label, linkProps: {} },
                        { label: azioniRichiesta.modificaDataDimissione.label, linkProps: {} }
                    ]
                    break;
                case statiRichiesta.inviata.value:
                    element.primiLink = [
                        {
                            label: azioniRichiesta.visualizzaValutazione.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                        }
                    ]
                    element.secondiLink = [
                        { label: azioniRichiesta.annullaRichiesta.label, linkProps: {} },
                        { label: azioniRichiesta.modificaDataDimissione.label, linkProps: {} }
                    ]
                    break;
                case statiRichiesta.inConferimento.value:
                case statiRichiesta.inValutazione.value:
                    if (valutazione?.valutatore?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        if (valutazione.stato === statiRichiesta.bozza.value) {
                            element.primiLink = [
                                {
                                    label: azioniRichiesta.completaValutazione.label,
                                    linkProps: getLinkProps(azioniRichiesta.completaValutazione.label, richiesta)
                                }
                            ]
                        }
                        else if (valutazione.stato === statiRichiesta.confermata.value) {
                            element.primiLink = [
                                {
                                    label: azioniRichiesta.visualizzaValutazione.label,
                                    linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                                }
                            ]
                        }
                    }
                    else {
                        element.primiLink = [
                            {
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }
                        ]
                    }
                    if (richiesta.professionistaCreazione.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        if (richiesta.richiestaAnnulamento) {
                            element.secondiLink = [
                                { label: azioniRichiesta.modificaDataDimissione.label, linkProps: {} }
                            ]
                        }
                        else {
                            element.secondiLink = [
                                { label: azioniRichiesta.richiediAnnullamentoRichiesta.label, linkProps: {} },
                                { label: azioniRichiesta.modificaDataDimissione.label, linkProps: {} }
                            ]
                        }
                    }
                    break;
                case statiRichiesta.valutata.value:
                case statiRichiesta.confermata.value:
                    element.primiLink = [
                        {
                            label: azioniRichiesta.visualizzaValutazione.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                        }
                    ]
                    if (richiesta.professionistaCreazione?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        if (richiesta.richiestaAnnulamento) {
                            element.secondiLink = [
                                { label: azioniRichiesta.modificaDataDimissione.label, linkProps: {} }
                            ]
                        }
                        else {
                            element.secondiLink = [
                                { label: azioniRichiesta.richiediAnnullamentoRichiesta.label, linkProps: {} },
                                { label: azioniRichiesta.modificaDataDimissione.label, linkProps: {} }
                            ]
                        }
                    }
                    break;
                case statiRichiesta.inFormalizzazione.value:
                    element.primiLink = [{
                        label: azioniRichiesta.visualizzaValutazione.label,
                        linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                    }]
                    if (richiesta.professionistaCreazione?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        element.secondiLink = [
                            { label: azioniRichiesta.modificaDataDimissione.label, linkProps: {} }
                        ]
                    }
                    break;
            }
        }
        else if (AuthUtils.hasUtenteRuoloProfessionistaValutatore()) {
            let cfFormalizzatore = richiesta?.formalizzazione?.professionista?.codiceFiscale || richiesta?.formalizzazione?.codiceFiscaleFormalizzatore;
            switch (richiesta.stato) {
                case statiRichiesta.inConferimentoPreDialogo.value:
                case statiRichiesta.inValutazionePreDialogo.value:
                    if (valutazione?.valutatore?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        if (valutazione.stato === statiRichiesta.bozza.value) {
                            element.primiLink = [{
                                label: azioniRichiesta.completaValutazionePreDialogo.label,
                                linkProps: getLinkProps(azioniRichiesta.completaValutazionePreDialogo.label, richiesta)
                            }]
                        }
                        else if (valutazione.stato === statiRichiesta.confermata.value) {
                            element.primiLink = [{
                                label: azioniRichiesta.visualizzaValutazionePreDialogo.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazionePreDialogo.label, richiesta, true)
                            }]
                        }
                    }
                    break;
                case statiRichiesta.valutataPreDialogo.value:
                case statiRichiesta.confermataPreDialogo.value:
                case statiRichiesta.inChiusuraPreDialogo.value:
                case statiRichiesta.inviata.value:
                    break;
                case statiRichiesta.inConferimento.value:
                case statiRichiesta.inValutazione.value:
                    if (!Utils.isObjectEmpty(valutazione)) {
                        if (valutazione.stato === statiRichiesta.bozza.value) {
                            element.primiLink = [{
                                label: azioniRichiesta.completaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.completaValutazione.label, richiesta)
                            }]
                        } else if (valutazione.stato === statiRichiesta.confermata.value) {
                            element.primiLink = [{
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }]
                        }
                    }
                    else if (Utils.isObjectEmpty(valutazione)) {
                        element.primiLink = [{
                            label: azioniRichiesta.visualizzaValutazione.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                        }]
                    }
                    break;
                case statiRichiesta.valutata.value:
                    element.primiLink = [{
                        label: azioniRichiesta.visualizzaValutazione.label,
                        linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                    }];
                    break;
                case statiRichiesta.confermata.value: {
                    let delegaTrovata = richiesta?.delega?.find(delega => delega.profilo === enumsUtente.profiliUtente.professionistaValutatore && delega.valore);
                    let ruoloDelegaTrovata = delegaTrovata?.ruolo;
                    let isDelegaAttivaPerRuoloValutatore = richiesta.classificazione === classificazione.RIABILITATIVA.value && ruoloDelegaTrovata && SessioneUtente.getInstance()?.settings?.some(setting => ruoloDelegaTrovata === setting.ruolo);
                    if (isDelegaAttivaPerRuoloValutatore) {
                        element.primiLink = [{
                            label: azioniRichiesta.avviaFormalizzazione.label,
                            linkProps: getLinkProps(azioniRichiesta.avviaFormalizzazione.label, richiesta, true)
                        }, {
                            label: azioniRichiesta.visualizzaValutazione.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                        }]
                    } else {
                        element.primiLink = [{
                            label: azioniRichiesta.visualizzaValutazione.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                        }]
                    }
                }
                    break;
                case statiRichiesta.inFormalizzazione.value:
                    if (cfFormalizzatore === SessioneUtente.getInstance()?.idUtente) {
                        element.primiLink = [{
                            label: azioniRichiesta.completaFormalizzazione.label,
                            linkProps: getLinkProps(azioniRichiesta.completaFormalizzazione.label, richiesta)
                        }, OGGETTO_ACCEDI_VALUTAZIONE]
                    } else {
                        element.primiLink = [{
                            label: azioniRichiesta.visualizzaValutazione.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                        }]
                    }
                    break;
            }
        }
        else if (AuthUtils.hasUtenteRuoloFormalizzatore()) {
            if (AuthUtils.hasUtenteRuoloDirettoreDiDistretto()) {
                switch (richiesta.stato) {
                    case statiRichiesta.inviataPreDialogo.value:
                    case statiRichiesta.inConferimentoPreDialogo.value:
                    case statiRichiesta.inValutazionePreDialogo.value:
                    case statiRichiesta.valutataPreDialogo.value:
                    case statiRichiesta.confermataPreDialogo.value:
                    case statiRichiesta.inChiusuraPreDialogo.value:
                        element.primiLink = [
                            {
                                label: azioniRichiesta.visualizzaValutazionePreDialogo.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazionePreDialogo.label, richiesta, true)
                            }
                        ]
                        break;
                    case statiRichiesta.inviata.value:
                    case statiRichiesta.inConferimento.value:
                    case statiRichiesta.inValutazione.value:
                    case statiRichiesta.valutata.value:
                    case statiRichiesta.confermata.value:
                        element.primiLink = [
                            {
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }
                        ]
                        break;
                    case statiRichiesta.inFormalizzazione.value:
                        element.primiLink = [{
                            label: azioniRichiesta.visualizzaValutazione.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                        }]
                        break;
                }
            }
            else if (AuthUtils.hasUtenteRuoloMcp()) {
                let membroTeam = richiesta?.team?.find(el => el.profilo === enumsUtente.profiliUtente.medicoFormalizzatore);
                let codiceFiscaleProfessionistaTeamFormalizzatore = membroTeam?.professionista?.codiceFiscale;
                let codiceFiscaleProfessionistaTeamCentrale = richiesta?.team?.find(el => el.profilo === enumsUtente.profiliUtente.centraleAdi && el.ruolo === enumsUtente.ruoli.operatore)?.professionista?.codiceFiscale;
                let isDelegaAttivaPerCentraleAdiTerritoriale = richiesta?.delega?.some(delega => delega.profilo === enumsUtente.profiliUtente.centraleAdi && delega.ruolo === enumsUtente.ruoli.operatore && delega.valore);

                switch (richiesta.stato) {
                    case statiRichiesta.inviata.value:
                        if (isDelegaAttivaPerCentraleAdiTerritoriale) {
                            if (Utils.isStringEmptyOrNullOrUndefined(codiceFiscaleProfessionistaTeamFormalizzatore)
                                && Utils.isStringEmptyOrNullOrUndefined(codiceFiscaleProfessionistaTeamCentrale)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                // se il professionista del team di ADI-FOR è null significa che esiste il professionista
                                // centrale adi che ha preso in carico la richiesta
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                if (richiesta?.sostituzioneRichiedente) {
                                    element.secondiLink = [
                                        {
                                            label: azioniRichiesta.annullaRichiesta.label,
                                            linkProps: {}
                                        }]
                                }
                                element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                            }
                        }
                        else {
                            if (Utils.isStringEmptyOrNullOrUndefined(codiceFiscaleProfessionistaTeamFormalizzatore)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                if (richiesta?.sostituzioneRichiedente) {
                                    element.secondiLink = [
                                        {
                                            label: azioniRichiesta.annullaRichiesta.label,
                                            linkProps: {}
                                        }]
                                }
                                element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]

                            }
                        }
                        break;
                    case statiRichiesta.inConferimento.value:
                        if (isDelegaAttivaPerCentraleAdiTerritoriale) {
                            if (Utils.isStringEmptyOrNullOrUndefined(codiceFiscaleProfessionistaTeamCentrale) &&
                                Utils.isStringEmptyOrNullOrUndefined(codiceFiscaleProfessionistaTeamFormalizzatore)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                if (richiesta?.sostituzioneRichiedente) {
                                    element.secondiLink = [
                                        {
                                            label: azioniRichiesta.annullaRichiesta.label,
                                            linkProps: {}
                                        }]
                                }
                                if (membroTeam?.timestampConferma) {
                                    element.primiLink = [OGGETTO_ACCEDI_VALUTAZIONE]
                                } else {
                                    element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                                }
                            }
                        } else {
                            if (Utils.isStringEmptyOrNullOrUndefined(codiceFiscaleProfessionistaTeamFormalizzatore)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {

                                if (membroTeam?.timestampConferma) {
                                    element.primiLink = [OGGETTO_ACCEDI_VALUTAZIONE]
                                } else {
                                    element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                                }
                                element.secondiLink = [{
                                    label: richiesta?.sostituzioneRichiedente ? azioniRichiesta.annullaRichiesta.label : null,
                                    linkProps: {}
                                }]
                            }
                        }
                        break;
                    case statiRichiesta.inValutazione.value:
                        if (isDelegaAttivaPerCentraleAdiTerritoriale) {
                            if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                if (richiesta?.sostituzioneRichiedente) {
                                    element.secondiLink = [
                                        {
                                            label: azioniRichiesta.annullaRichiesta.label,
                                            linkProps: {}
                                        }]
                                }
                                element.primiLink = [OGGETTO_ACCEDI_VALUTAZIONE]
                            }
                        } else {
                            if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                if (richiesta?.sostituzioneRichiedente) {
                                    element.secondiLink = [
                                        {
                                            label: azioniRichiesta.annullaRichiesta.label,
                                            linkProps: {}
                                        }]
                                }
                                element.primiLink = [OGGETTO_ACCEDI_VALUTAZIONE]
                            }
                        }
                        break;
                    case statiRichiesta.valutata.value:
                        if (isDelegaAttivaPerCentraleAdiTerritoriale) {
                            if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                if (richiesta?.sostituzioneRichiedente) {
                                    element.primiLink = [OGGETTO_COMPLETA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                    element.secondiLink = [
                                        {
                                            label: azioniRichiesta.annullaRichiesta.label,
                                            linkProps: {}
                                        }]
                                }
                                else {
                                    element.primiLink = [OGGETTO_ACCEDI_VALUTAZIONE]
                                }

                            }
                        }
                        else {
                            if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                if (richiesta?.sostituzioneRichiedente) {
                                    element.primiLink = [OGGETTO_COMPLETA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                    element.secondiLink = [
                                        {
                                            label: azioniRichiesta.annullaRichiesta.label,
                                            linkProps: {}
                                        }]
                                }
                                else {
                                    element.primiLink = [OGGETTO_ACCEDI_VALUTAZIONE]
                                }
                            }
                        }
                        break;
                    case statiRichiesta.confermata.value:
                        if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                            element.primiLink = [OGGETTO_AVVIA_FORMALIZZAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                            if (richiesta?.sostituzioneRichiedente) {
                                element.secondiLink = [{ label: azioniRichiesta.annullaRichiesta.label, linkProps: {} }]
                            }
                        } else if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente ||
                            Utils.isStringEmptyOrNullOrUndefined(codiceFiscaleProfessionistaTeamFormalizzatore)) {
                            element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                        }
                        break;
                    case statiRichiesta.inFormalizzazione.value:
                        if (richiesta?.formalizzazione?.codiceFiscaleFormalizzatore === SessioneUtente.getInstance().idUtente) {
                            element.primiLink = [OGGETTO_COMPLETA_FORMALIZZAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                        }
                        else {
                            element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                        }
                        break;
                }
            }
            else if (AuthUtils.hasUtenteRuoloMpa()) {
                let teamFormalizzatore = richiesta?.team?.find(el => el.profilo === enumsUtente.profiliUtente.medicoFormalizzatore);
                let professionistaTeamCentraleAdiCurePalliative = richiesta?.team?.find(el => el.profilo === enumsUtente.profiliUtente.centraleAdi && el.ruolo === enumsUtente.ruoli.operatore_cure_palliative)?.professionista;
                let codiceFiscaleProfessionistaTeamFormalizzatore = teamFormalizzatore?.professionista?.codiceFiscale;
                let isDelegaAttivaCentraleAdiCurePalliative = richiesta?.delega?.some(delega => delega.profilo === enumsUtente.profiliUtente.centraleAdi && delega.ruolo === enumsUtente.ruoli.operatore_cure_palliative && delega?.valore);

                if (!Utils.isObjectEmpty(valutazione)) {
                    element.primiLink = this.getStatoRichiestaValutatore(valutazione, richiesta);
                }
                else if (isDelegaAttivaCentraleAdiCurePalliative) {
                    switch (richiesta.stato) {
                        case statiRichiesta.inviata.value:
                            if (!teamFormalizzatore?.professionista && !professionistaTeamCentraleAdiCurePalliative) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_FORZA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                element.secondiLink = [OGGETTO_ANNULLA_RICHIESTA]
                            }
                            break;
                        case statiRichiesta.inConferimento.value:
                            if (!teamFormalizzatore?.professionista && !professionistaTeamCentraleAdiCurePalliative) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                if (Utils.isStringEmptyOrNullOrUndefined(teamFormalizzatore?.timestampConferma)) {
                                    element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_FORZA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                } else {
                                    element.primiLink = [OGGETTO_FORZA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                }
                                element.secondiLink = [OGGETTO_ANNULLA_RICHIESTA]
                            }
                            break;
                        case statiRichiesta.inValutazione.value:
                            if (teamFormalizzatore?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_FORZA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                element.secondiLink = [OGGETTO_ANNULLA_RICHIESTA]
                            }
                            break;
                        case statiRichiesta.valutata.value:
                            if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_COMPLETA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                element.secondiLink = [
                                    {
                                        label: azioniRichiesta.annullaRichiesta.label,
                                        linkProps: {}
                                    }
                                ]
                            }
                            break;
                        case statiRichiesta.confermata.value:
                            if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_AVVIA_FORMALIZZAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                element.secondiLink = [
                                    {
                                        label: azioniRichiesta.annullaRichiesta.label,
                                        linkProps: {}
                                    }
                                ]
                            }
                            break;
                        case statiRichiesta.inFormalizzazione.value:
                            if (richiesta?.formalizzazione?.codiceFiscaleFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_COMPLETA_FORMALIZZAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                            }
                            else {
                                element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            break;
                    }
                }
                else {
                    switch (richiesta.stato) {
                        case statiRichiesta.inviata.value:
                            if (Utils.isStringEmptyOrNullOrUndefined(codiceFiscaleProfessionistaTeamFormalizzatore)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE, OGGETTO_FORZA_VALUTAZIONE]
                                element.secondiLink = [OGGETTO_ANNULLA_RICHIESTA]
                            }
                            break;
                        case statiRichiesta.inConferimento.value:
                            if (Utils.isStringEmptyOrNullOrUndefined(codiceFiscaleProfessionistaTeamFormalizzatore)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                if (Utils.isStringEmptyOrNullOrUndefined(teamFormalizzatore?.timestampConferma)) {
                                    element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_FORZA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                } else {
                                    element.primiLink = [OGGETTO_FORZA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                }
                                element.secondiLink = [OGGETTO_ANNULLA_RICHIESTA]
                            }
                            break;
                        case statiRichiesta.inValutazione.value:
                            if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_FORZA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                element.secondiLink = [OGGETTO_ANNULLA_RICHIESTA]
                            }
                            break;
                        case statiRichiesta.valutata.value:
                            if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_COMPLETA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                element.secondiLink = [
                                    {
                                        label: azioniRichiesta.annullaRichiesta.label,
                                        linkProps: {}
                                    }
                                ]
                            }
                            break;
                        case statiRichiesta.confermata.value:
                            if (codiceFiscaleProfessionistaTeamFormalizzatore !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else if (codiceFiscaleProfessionistaTeamFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_AVVIA_FORMALIZZAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                element.secondiLink = [
                                    {
                                        label: azioniRichiesta.annullaRichiesta.label,
                                        linkProps: {}
                                    }
                                ]
                            }
                            break;
                        case statiRichiesta.inFormalizzazione.value:
                            if (richiesta?.formalizzazione?.codiceFiscaleFormalizzatore === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_COMPLETA_FORMALIZZAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                            }
                            else {
                                element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            break;
                    }
                }
            }
        }
        else if (AuthUtils.hasUtenteRuoloCoordinatore()) {
            const disciplinaUtenteLoggato = SessioneUtente.getInstance().settings?.find(el => el.profilo === enumsUtente.profiliUtente.coordinatore)?.disciplina;
            let teamCoordinatore, coordinatore;
            if (disciplinaUtenteLoggato) {
                teamCoordinatore = _.find(richiesta.team, t => t.profilo === enumsUtente.profiliUtente.coordinatore && t.disciplina != null);
            } else {
                teamCoordinatore = _.find(richiesta.team, t => t.profilo === enumsUtente.profiliUtente.coordinatore && t.disciplina === null);
            }
            coordinatore = teamCoordinatore?.professionista;
            // primo ramo if - coordinatore territoriale prendo sempre coordinatore che non ha disciplina
            // secondo ramo if - coordinatore cure palliative
            if (!disciplinaUtenteLoggato) {
                if (!Utils.isObjectEmpty(valutazione)) {
                    element.primiLink = this.getStatoRichiestaValutatore(valutazione, richiesta);
                } else {
                    switch (richiesta.stato) {
                        case statiRichiesta.inviata.value:
                            if(!teamCoordinatore){
                                element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (Utils.isStringEmptyOrNullOrUndefined(coordinatore?.codiceFiscale)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (coordinatore?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (coordinatore?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                            }
                            break;
                        case statiRichiesta.inConferimento.value:
                            if(!teamCoordinatore){
                                element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (Utils.isStringEmptyOrNullOrUndefined(coordinatore?.codiceFiscale)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (coordinatore?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (coordinatore?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                if (teamCoordinatore?.timestampConferma) {
                                    element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                                } else {
                                    element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                                }
                            }
                            break;
                        case statiRichiesta.inValutazione.value:
                            if(!teamCoordinatore){
                                element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (coordinatore?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else {
                                element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            break;
                        case statiRichiesta.valutata.value:
                        case statiRichiesta.confermata.value:
                        case statiRichiesta.inFormalizzazione.value:
                            element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                            break;
                    }
                }
            }
            else if (disciplinaUtenteLoggato === enumRichiesta.tipologia.CURE_PALLIATIVE) {
                switch (richiesta.stato) {
                    case statiRichiesta.inviata.value:
                        if (Utils.isStringEmptyOrNullOrUndefined(coordinatore?.codiceFiscale)) {
                            element.primiLink = [
                                OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                        }
                        else if (coordinatore?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                            element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                        }
                        else if (coordinatore?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                        }
                        break;
                    case statiRichiesta.inConferimento.value:
                        if (Utils.isStringEmptyOrNullOrUndefined(coordinatore?.codiceFiscale)) {
                            element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                        }
                        else if (coordinatore?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                            element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                        }
                        else if (coordinatore?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            if (teamCoordinatore?.timestampConferma) {
                                element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                            else {
                                element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                            }
                        }
                        break;
                    case statiRichiesta.inValutazione.value:
                        if (Utils.isStringEmptyOrNullOrUndefined(coordinatore?.codiceFiscale)) {
                            element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                        }
                        else if (coordinatore?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                            element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                        } else {
                            element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                        }
                        break;
                    case statiRichiesta.valutata.value:
                    case statiRichiesta.confermata.value:
                    case statiRichiesta.inFormalizzazione.value:
                        element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                        break;
                }
            }
        }
        else if (AuthUtils.hasUtenteCOT()) {
            switch (richiesta.stato) {
                case statiRichiesta.inviata.value:
                case statiRichiesta.inConferimento.value:
                case statiRichiesta.inValutazione.value:
                case statiRichiesta.valutata.value:
                case statiRichiesta.confermata.value:
                case statiRichiesta.inFormalizzazione.value:
                    element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                    break;
            }

        }
        else if (AuthUtils.hasUtenteProfiloDirettoreUO()) {
            let teamDirettoreUO = richiesta.team?.find(el => el.profilo === enumsUtente.profiliUtente.direttoreUnitaOperativa);
            if (!Utils.isObjectEmpty(valutazione)) {
                element.primiLink = this.getStatoRichiestaValutatore(valutazione, richiesta);
            } else {

                switch (richiesta.stato) {
                    case statiRichiesta.inviata.value:
                    case statiRichiesta.inConferimento.value:
                        if (!Utils.isStringEmptyOrNullOrUndefined(teamDirettoreUO?.timestampConferma)) {
                            element.primiLink = [{
                                label: azioniRichiesta.accediValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.accediValutazione.label, richiesta, true)
                            }]
                        }
                        else {
                            element.primiLink = [{
                                label: azioniRichiesta.attivaProfessionista.label,
                                linkProps: getLinkProps(azioniRichiesta.attivaProfessionista.label, richiesta, true)
                            },
                                {
                                    label: azioniRichiesta.accediValutazione.label,
                                    linkProps: getLinkProps(azioniRichiesta.accediValutazione.label, richiesta, true)
                                }]
                        }
                        break;
                    case statiRichiesta.inValutazione.value:
                    case statiRichiesta.valutata.value:
                    case statiRichiesta.confermata.value:
                    case statiRichiesta.inFormalizzazione.value:
                        element.primiLink = [{
                            label: azioniRichiesta.visualizzaValutazione.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                        }]
                        break;
                }
            }
        }
        else if (AuthUtils.hasUtenteProfiloReferenteAssistentiSociali()) {
            if (!Utils.isObjectEmpty(valutazione)) {
                element.primiLink = this.getStatoRichiestaValutatore(valutazione, richiesta);
            }
            else {
                let refDirAs = this.getProfessionistaRichiesta("refDirigenteAssistentiSociali", richiesta);
                let membroTeam = richiesta?.team?.find(membroTeam => membroTeam.profilo === enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali);

                switch (richiesta.stato) {
                    case statiRichiesta.inviataPreDialogo.value:
                    case statiRichiesta.inConferimentoPreDialogo.value:
                    case statiRichiesta.inValutazionePreDialogo.value:
                        if (Utils.isStringEmptyOrNullOrUndefined(refDirAs?.codiceFiscale)) {
                            element.primiLink = [{
                                label: azioniRichiesta.visualizzaPreDialogoPerPresaInCarico.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaPreDialogoPerPresaInCarico.label, richiesta, true)
                            }]
                        }
                        else if (refDirAs?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                            element.primiLink = [{
                                label: azioniRichiesta.visualizzaValutazionePreDialogo.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazionePreDialogo.label, richiesta, true)
                            }, OGGETTO_SUBENTRA]
                        }
                        else if (refDirAs?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            element.primiLink = [{
                                label: azioniRichiesta.accediValutazionePreDialogo.label,
                                linkProps: getLinkProps(azioniRichiesta.accediValutazionePreDialogo.label, richiesta, true)
                            }]
                        }
                        break;
                    case statiRichiesta.valutataPreDialogo.value:
                    case statiRichiesta.confermataPreDialogo.value:
                    case statiRichiesta.inChiusuraPreDialogo.value:
                        element.primiLink = [{
                            label: azioniRichiesta.visualizzaValutazionePreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazionePreDialogo.label, richiesta, true)
                        }]
                        break;
                    case statiRichiesta.inviata.value:
                    case statiRichiesta.inConferimento.value:
                        if (!Utils.isStringEmptyOrNullOrUndefined(membroTeam?.timestampConferma)) {
                            element.primiLink = [{
                                label: azioniRichiesta.accediValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.accediValutazione.label, richiesta, true)
                            }]
                        }
                        else {
                            element.primiLink = [{
                                label: azioniRichiesta.attivaProfessionista.label,
                                linkProps: getLinkProps(azioniRichiesta.attivaProfessionista.label, richiesta, true)
                            },
                                {
                                    label: azioniRichiesta.accediValutazione.label,
                                    linkProps: getLinkProps(azioniRichiesta.accediValutazione.label, richiesta, true)
                                }]
                        }
                        break;
                    case statiRichiesta.inValutazione.value:
                    case statiRichiesta.valutata.value:
                    case statiRichiesta.confermata.value:
                    case statiRichiesta.inFormalizzazione.value:
                        element.primiLink = [{
                            label: azioniRichiesta.visualizzaValutazione.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                        }]
                        break;
                }
            }

        }
        else if (AuthUtils.hasUtenteCentraleAdi()) {
            let isUtenteOperatorePalliativo = AuthUtils.hasUtenteRuoloOperatorePalliativo();
            let isUtenteOperatoreTerritoriale = AuthUtils.hasUtenteRuoloOperatore();
            let teamCentraleAdiPalliativo = richiesta?.team?.find(el => el.profilo === enumsUtente.profiliUtente.centraleAdi && el.ruolo === enumsUtente.ruoli.operatore_cure_palliative);
            let teamCentraleAdiTerritoriale = richiesta?.team?.find(el => el.profilo === enumsUtente.profiliUtente.centraleAdi && el.ruolo === enumsUtente.ruoli.operatore);
            let isDelegaAttivaPerCentraleAdiPalliativa = richiesta?.delega?.find(delega => delega.profilo === enumsUtente.profiliUtente.centraleAdi && delega.ruolo === enumsUtente.ruoli.operatore_cure_palliative)?.valore;
            let isDelegaAttivaPerCentraleAdiTerritoriale = richiesta?.delega?.find(delega => delega.profilo === enumsUtente.profiliUtente.centraleAdi && delega.ruolo === enumsUtente.ruoli.operatore)?.valore;

            switch (richiesta.stato) {
                case statiRichiesta.inviata.value:
                    if (isUtenteOperatorePalliativo) {
                        if (isDelegaAttivaPerCentraleAdiPalliativa) {
                            if (Utils.isStringEmptyOrNullOrUndefined(teamCentraleAdiPalliativo?.professionista?.codiceFiscale)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                                element.secondiLink = [{
                                    label: azioniRichiesta.annullaRichiesta.label,
                                    linkProps: {}
                                }]
                            }
                        }
                        else {
                            if (Utils.isStringEmptyOrNullOrUndefined(teamCentraleAdiPalliativo?.professionista?.codiceFiscale)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                            }
                        }

                    }
                    else if (isUtenteOperatoreTerritoriale) {
                        if (isDelegaAttivaPerCentraleAdiTerritoriale) {
                            if (Utils.isStringEmptyOrNullOrUndefined(teamCentraleAdiTerritoriale?.professionista?.codiceFiscale)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                            }
                        }
                        else {
                            if (Utils.isStringEmptyOrNullOrUndefined(teamCentraleAdiTerritoriale?.professionista?.codiceFiscale)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                            }
                        }
                    }
                    break;
                case statiRichiesta.inConferimento.value:
                    if (isUtenteOperatorePalliativo) {
                        if (isDelegaAttivaPerCentraleAdiPalliativa) {
                            if (Utils.isStringEmptyOrNullOrUndefined(teamCentraleAdiPalliativo?.professionista?.codiceFiscale)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                if (teamCentraleAdiPalliativo?.timestampConferma === null) {
                                    element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                                } else {
                                    element.primiLink = [OGGETTO_ACCEDI_VALUTAZIONE]
                                }
                                element.secondiLink = [{
                                    label: azioniRichiesta.annullaRichiesta.label,
                                    linkProps: {}
                                }]
                            }
                        } else {
                            if (Utils.isStringEmptyOrNullOrUndefined(teamCentraleAdiPalliativo?.professionista?.codiceFiscale)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                if (teamCentraleAdiPalliativo?.timestampConferma === null) {
                                    element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                                } else {

                                    element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                                }
                            }
                        }

                    }
                    else if (isUtenteOperatoreTerritoriale) {
                        if (isDelegaAttivaPerCentraleAdiTerritoriale) {
                            if (Utils.isStringEmptyOrNullOrUndefined(teamCentraleAdiTerritoriale?.professionista?.codiceFiscale)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                if (teamCentraleAdiTerritoriale?.timestampConferma === null){
                                    element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                                } else {
                                    element.primiLink = [OGGETTO_ACCEDI_VALUTAZIONE]
                                }
                            }
                        }
                        else {
                            if (Utils.isStringEmptyOrNullOrUndefined(teamCentraleAdiTerritoriale?.professionista?.codiceFiscale)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                if (teamCentraleAdiTerritoriale?.timestampConferma === null){
                                    element.primiLink = [OGGETTO_ATTIVA_PROFESSIONISTA, OGGETTO_ACCEDI_VALUTAZIONE]
                                } else {
                                    element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                                }
                            }
                        }
                    }
                    break;
                case statiRichiesta.inValutazione.value:
                    if (isUtenteOperatorePalliativo) {
                        if (isDelegaAttivaPerCentraleAdiPalliativa) {
                            if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_ACCEDI_VALUTAZIONE]
                                element.secondiLink = [{
                                    label: azioniRichiesta.annullaRichiesta.label,
                                    linkProps: {}
                                }]
                            }
                        } else {
                            if (Utils.isStringEmptyOrNullOrUndefined(teamCentraleAdiPalliativo?.professionista?.codiceFiscale)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else {
                                element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                        }
                    }
                    else if (isUtenteOperatoreTerritoriale) {
                        if (isDelegaAttivaPerCentraleAdiTerritoriale) {
                            if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_ACCEDI_VALUTAZIONE]
                            }
                        } else {
                            if (Utils.isStringEmptyOrNullOrUndefined(teamCentraleAdiTerritoriale?.professionista?.codiceFiscale)) {
                                element.primiLink = [OGGETTO_PRENDI_IN_CARICO, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                            }
                        }
                    }
                    break;
                case statiRichiesta.valutata.value:
                    if (isUtenteOperatorePalliativo) {
                        // aggiungere prendi in carico su coordinatore
                        if (isDelegaAttivaPerCentraleAdiPalliativa) {
                            if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_COMPLETA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                element.secondiLink = [{
                                    label: azioniRichiesta.annullaRichiesta.label,
                                    linkProps: {}
                                }]
                            }
                        } else {
                            element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                        }
                    }
                    else if (isUtenteOperatoreTerritoriale) {
                        if (isDelegaAttivaPerCentraleAdiTerritoriale) {
                            if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_ACCEDI_VALUTAZIONE]
                            }
                        } else {
                            element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                        }
                    }
                    break;
                case statiRichiesta.confermata.value:
                    if (isUtenteOperatorePalliativo) {
                        if (isDelegaAttivaPerCentraleAdiPalliativa) {
                            if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiPalliativo?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_AVVIA_FORMALIZZAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                                element.secondiLink = [{
                                    label: azioniRichiesta.annullaRichiesta.label,
                                    linkProps: {}
                                }]
                            }
                        } else {
                            element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                        }
                    }
                    else if (isUtenteOperatoreTerritoriale) {
                        if (isDelegaAttivaPerCentraleAdiTerritoriale) {
                            if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_SUBENTRA, OGGETTO_VISUALIZZA_VALUTAZIONE]
                            } else if (teamCentraleAdiTerritoriale?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                                element.primiLink = [OGGETTO_AVVIA_FORMALIZZAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                            }
                        } else {
                            element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                        }
                    }
                    break;
                case statiRichiesta.inFormalizzazione.value:
                    if (richiesta?.formalizzazione?.codiceFiscaleFormalizzatore === SessioneUtente.getInstance().idUtente) {
                        element.primiLink = [OGGETTO_COMPLETA_FORMALIZZAZIONE, OGGETTO_ACCEDI_VALUTAZIONE]
                    } else {
                        element.primiLink = [OGGETTO_VISUALIZZA_VALUTAZIONE]
                    }
                    break;
            }

        }
        else {
            element.primiLink = [];
            element.secondiLink = [];
        }
    }

    static getStatoRichiestaValutatore(valutazione, richiesta, context) {
        switch (richiesta.stato) {
            case statiRichiesta.inConferimentoPreDialogo.value:
            case statiRichiesta.inValutazionePreDialogo.value:
                if (valutazione?.valutatore?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                    if (valutazione.stato === statiRichiesta.bozza.value) {
                        return [{
                            label: azioniRichiesta.completaValutazionePreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.completaValutazionePreDialogo.label, richiesta)
                        }];
                    }
                    else if (valutazione.stato === statiRichiesta.confermata.value) {
                        return [{
                            label: azioniRichiesta.visualizzaValutazionePreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazionePreDialogo.label, richiesta, true)
                        }];
                    }
                }
                break;
            case statiRichiesta.valutataPreDialogo.value:
            case statiRichiesta.confermataPreDialogo.value:
            case statiRichiesta.inChiusuraPreDialogo.value:
                return [{
                    label: azioniRichiesta.visualizzaValutazionePreDialogo.label,
                    linkProps: getLinkProps(azioniRichiesta.visualizzaValutazionePreDialogo.label, richiesta, true)
                }];
            case statiRichiesta.inConferimento.value:
            case statiRichiesta.inValutazione.value:
                if (valutazione?.stato === statiRichiesta.bozza.value) {
                    return [{
                        label: azioniRichiesta.completaValutazione.label,
                        linkProps: getLinkProps(azioniRichiesta.completaValutazione.label, richiesta)
                    }, {
                        label: azioniRichiesta.accediValutazione.label,
                        linkProps: getLinkProps(azioniRichiesta.accediValutazione.label, richiesta)
                    }];
                } else if (valutazione?.stato === statiRichiesta.confermata.value) {
                    return [{
                        label: azioniRichiesta.visualizzaValutazione.label,
                        linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                    }];
                }
                break;
            case statiRichiesta.valutata.value:
                return [{
                    label: azioniRichiesta.visualizzaValutazione.label,
                    linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                }];
            case statiRichiesta.confermata.value:
                if (richiesta?.delega?.some(delega => delega.profilo === enumsUtente.profiliUtente.professionistaValutatore) &&
                    richiesta?.delega?.find(delega => delega.profilo === enumsUtente.profiliUtente.professionistaValutatore)?.ruolo === SessioneUtente.getInstance()?.settings?.find(setting => enumsUtente.profiliUtente.direttoreUnitaOperativa === setting.profilo)?.ruolo &&
                    richiesta?.delega?.find(delega => delega.profilo === enumsUtente.profiliUtente.professionistaValutatore && delega.ruolo === SessioneUtente.getInstance()?.settings?.find(setting => enumsUtente.profiliUtente.direttoreUnitaOperativa === setting.profilo)?.ruolo)?.valore) {
                    return [{
                        label: azioniRichiesta.avviaFormalizzazione.label,
                        linkProps: getLinkProps(azioniRichiesta.avviaFormalizzazione.label, richiesta, true)
                    }, {
                        label: azioniRichiesta.visualizzaValutazione.label,
                        linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                    }];
                }
                else {
                    return [{
                        label: azioniRichiesta.visualizzaValutazione.label,
                        linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                    }];
                }
            case statiRichiesta.inFormalizzazione.value:
                if (richiesta?.formalizzazione?.professionista?.codiceFiscale === SessioneUtente.getInstance()?.idUtente || richiesta?.formalizzazione?.codiceFiscaleFormalizzatore === SessioneUtente.getInstance()?.idUtente) {
                    return [{
                        label: azioniRichiesta.completaFormalizzazione.label,
                        linkProps: getLinkProps(azioniRichiesta.completaFormalizzazione.label, richiesta)
                    }];
                }
                else {
                    return [{
                        label: azioniRichiesta.visualizzaValutazione.label,
                        linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                    }];
                }
            case statiRichiesta.accettata.value:
                if (context === "ricercaAssistiti") {
                    return [{
                        label: azioniRichiesta.apriCartellaUnica.label,
                        linkProps: getLinkProps(azioniRichiesta.apriCartellaUnica.label, richiesta)
                    }];
                }
        }
    }

    static controllaValutazione(valutazione) {
        if (!Utils.isStringEmptyOrNullOrUndefined(valutazione)) {
            if (valutazione.stato === statiRichiesta.bozza.value) {
                return azioniRichiesta.completaValutazionePreDialogo.label;
            }
            else if (valutazione.stato === statiRichiesta.confermata.value) {
                return azioniRichiesta.visualizzaValutazionePreDialogo.label;
            }
        }
        else {
            return azioniRichiesta.visualizzaValutazione.label;
        }
    }

    static getLivelloPriorita(priorita) {
        if (priorita) return oggettiUtili.livelloPrioritaSintesi.find(el => el.value === priorita)?.label;
        else return "-";
    }

    static getInCaricoA(utente) {
        let sessioneUtente = SessioneUtente.getInstance();
        if (!Utils.isObjectEmpty(utente)) {
            if (utente?.codiceFiscale === sessioneUtente.idUtente) return "Io";
            else return utente?.cognome + " " + utente?.nome;
        }
        else return "-";
    }

    static getMedicoRichiedente(richiedente) {
        let sessioneUtente = SessioneUtente.getInstance();
        if (Utils.isStringEmptyOrNullOrUndefined(richiedente)) {
            return "";
        } else if (richiedente.codiceFiscale === sessioneUtente.idUtente) {
            return "Io (" + enumsUtente.ruoliFE[richiedente.ruolo] + ")";
        } else {
            return richiedente.cognome + " " + richiedente.nome + " (" + enumsUtente.ruoliFE[richiedente.ruolo] + ")";
        }
    }

    static getValoreFiltro(value, key) {
        if (typeof value === "object") return value?.label;
        else if (key.startsWith("data") && !key.includes("CureDomiciliari")) return Utils.convertUtcToLocalDate(value).format("DD/MM/YYYY");
        else return value;
    }

    static getEtichettaFiltro(filtro) {
        switch (filtro) {
            case "livelloPriorita":
                return "Priorità: ";
            case "dataScadenzaRichiestaDa":
                return "Data scadenza richiesta da: ";
            case "dataScadenzaRichiestaA":
                return "Data scadenza richiesta a: ";
            case "cognome":
                return "Cognome assistito: ";
            case "nome":
                return "Nome assistito: ";
            case "codiceFiscale":
                return "Medico richiedente: ";
            case "statoSegnalazione":
                return "Segnalazione: ";
            case "dataSegnalazioneDa":
                return "Data segnalazione da: ";
            case "dataSegnalazioneA":
                return "Data segnalazione a: ";
            case "statoRichiesta":
                return "Richiesta: ";
            case "dataRichiestaDa":
                return "Data richiesta da: ";
            case "dataRichiestaA":
                return "Data richiesta a: ";
            case "dataValutazioneDa":
                return "Data valutazione da: ";
            case "dataValutazioneA":
                return "Data valutazione a: ";
            case "dataValutazioneProfessionaleDa":
                return "Data valutazione professionale da: ";
            case "dataValutazioneProfessionaleA":
                return "Data valutazione professionale a: ";
            case "dataFormalizzazioneDa":
                return "Data formalizzazione da: ";
            case "dataFormalizzazioneA":
                return "Data formalizzazione a: ";
            case "dataInizioCureDomiciliariDa":
                return "Data inizio cure domiciliari da: ";
            case "dataInizioCureDomiciliariA":
                return "Data inizio cure domiciliari a: ";
            case "dataFineCureDomiciliariDa":
                return "Data fine cure domiciliari da: ";
            case "dataFineCureDomiciliariA":
                return "Data fine cure domiciliari a: ";
            case "dataAnnullamentoRichiestaDa":
                return "Data annullamento richiesta da: ";
            case "dataAnnullamentoRichiestaA":
                return "Data annullamento richiesta a: ";
            case "statoValidazioneMultidimensionale":
                return "Validazione multidimensionale: ";
            case "statoValutazioneProfessionale":
                return "Valutazione professionale: ";
            case "statoFormalizzazione":
                return "Formalizzazione: ";
            case "codiceFiscaleFormalizzatore":
                return "Medico formalizzatore: ";
            case "statoCureDomiciliari":
                return "Cure Domiciliari: ";
            case "classificazione":
                return "Tipologia: ";
            case "codiceFiscaleFirmatario":
                return "Medico Firmatario: ";
            default:
                return 'N/A'
        }
    }

    static getTeamDaProfilo(richiesta, profilo) {
        return richiesta?.team?.find(team => team.profilo === profilo);
    }

    static controllaTeamProfessionista(team, professionista) {
        switch (professionista) {
            case "coordinatore":
            case "referenteDirigenteAssistentiSociali":
                if (team?.professionista?.codiceFiscale === null) {
                    return azioniRichiesta.visualizzaPreDialogoPerPresaInCarico.label;
                }
                else if (team?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                    return azioniRichiesta.accediValutazionePreDialogo.label;
                }
                else {
                    return azioniRichiesta.visualizzaValutazionePreDialogo.label;
                }
        }
    }

    static estraiMediciRichiedenti(data) {
        let res = [];
        let sessioneUtente = SessioneUtente.getInstance();

        const aggiungiMedicoRichiedente = (richiedente, res) => {
            let element = {}
            element.label = richiedente.identificativo + " " + richiedente.ruolo;
            element.value = richiedente.codiceFiscale;

            res.push(element);
        }

        if (data.data?.length > 0) {
            data.data.forEach(function (el) {
                let richiedente;
                if (AuthUtils.hasUtenteRuoloMedicoRichiedente() && el.codiceFiscale === sessioneUtente.idUtente) {
                    richiedente = {
                        identificativo: "Io",
                        ruolo: "(" + enumsUtente.ruoliFE[el.ruolo] + ")",
                        codiceFiscale: sessioneUtente.idUtente
                    }
                } else {
                    richiedente = {
                        identificativo: el.cognome + " " + el.nome,
                        ruolo: "(" + enumsUtente.ruoliFE[el.ruolo] + ")",
                        codiceFiscale: el.codiceFiscale
                    }
                }
                aggiungiMedicoRichiedente(richiedente, res);
            })
        }

        if (res.length === 0) {
            return data.data?.content;
        }

        return res;
    }

    static disabledApplicaAnnullaFiltri(filtriAssistito, filtriRichiesta) {
        return !(Object.keys(filtriRichiesta).some(key => !Utils.isStringEmptyOrNullOrUndefined(filtriRichiesta[key])) ||
            Object.keys(filtriAssistito).some(key => !Utils.isStringEmptyOrNullOrUndefined(filtriAssistito[key])));
    }
}
