import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "web-client-archetype";
import ModalHelper from "../../modale/ModalHelper";
import * as $ from "jquery";
import styles from "./AnnullamentoRichiesta.module.css"
import RichiestaADIService from "../../../service/RichiestaADIService";
import _ from "lodash";
import Utils, { closeModaleStyle } from "../../../utils/Utils";
import aulssMappingSedi from "../../../utils/dataset/aulssData/aulssMappingSedi.json";
import { Context } from '../../../utils/Context';
import messageIt from "../../../utils/message/message-it.json";
import { mostraSpinner, nascondiSpinner } from "../../../App";
import SessionRequest from "../../../utils/SessionRequest";
import errors from "../../../utils/errors/errors.json";
import azioniRichiesta from "../../../utils/dataset/richiestaData/azioniRichiesta.json";
import AuthUtils from "../../../utils/AuthUtils";
import * as BsIcon from "react-bootstrap-icons";
import {Link, Redirect} from "react-router-dom";
import routepath from "../../../utils/route/route-path.json";

const propTypes = {
    uuidRichiesta: PropTypes.object,
    chiudiAnnullamentoRichiesta: PropTypes.func,
    redirectStoricoRichiesta: PropTypes.func,
    contestoAnnullamentoRichiesta: PropTypes.string
}

const bodyModaleAttenzioneText = "Stai per procedere con l’annullamento della richiesta precedentemente inviata. Una volta annullata verrà inviata una notifica al Distretto. Vuoi confermare l’operazione?";
const bodyModaleAttenzioneTimestampCompletamentoNullText = "Stai per procedere con l’annullamento della richiesta precedentemente avviata. Vuoi confermare l’operazione?";
const bodyModaleOperazioneEseguitaText = "La richiesta è stata correttamente annullata. È stata inviata una notifica al Distretto.";
const bodyModaleOperazioneEseguitaTimestampCompletamentoNullText = "La richiesta è stata correttamente annullata.";

const getBodyModaleAvviaPreDialogoText = (cognome, nome) => {
    return "Per l’assistito è già stata avviata una richiesta di cure domiciliari da parte di " + cognome + " " + nome + ". Sei sicuro di voler procedere?";
}

const titleModaleAnnullaRichiesta = "Annulla richiesta";
const titleModaleAttenzione = "Attenzione!";
const titleModaleOperazioneEseguita = "Operazione completata";

const idModale = "modaleAnnullaRichiesta";
const idModaleAttenzione = "modaleAnnullaRichiestaAttenzione";
const idModaleOperazioneEseguita = "modaleAnnullaRichiestaOperazioneEseguita";

const caratteriMax = 500;
const defaultMotivazioneAnnullamento = {
    text: "",
    caratteriDisponibili: caratteriMax
}

export default class AnnullamentoRichiesta extends Component {
    state = {
        mostraModaleAttenzione: false,
        mostraModaleOperazioneEseguita: false,
        richiesta: {},
        motivazioneAnnullamento: defaultMotivazioneAnnullamento
    }

    componentDidMount() {
        mostraSpinner();
        this.cercaRichiesta();
    }

    chiudiAnnullamentoRichiesta = () => {
        closeModaleStyle();
        this.setState({ mostraModaleAttenzione: false, mostraModaleOperazioneEseguita: false });
        this.props.chiudiAnnullamentoRichiesta();
    }

    showOperazioneEseguita = () => {
        this.setState({ showModaleOperazioneEseguita: true });
    }

    handleAggiornaForm = (field, event) => {
        let state = _.cloneDeep(this.state);

        if (field === "motivazioneAnnullamento") {
            if ((caratteriMax - event.target.value.length) >= 0) {
                state[field].text = event.target.value;
                state[field].caratteriDisponibili = caratteriMax - state[field].text?.length;
            }
        }

        this.setState(state);
    }

    confermaInoltraRichiesta = () => {
        let uuidRichiesta = this.props.uuidRichiesta;
        RichiestaADIService.inoltraRichiesta(uuidRichiesta).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                if (response?.msgCode && response.msgCode.startsWith("WARNING_WD")) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "confirm",
                        text: errors[response?.msgCode]?.message || response?.msgCode
                    });
                    //l'operazione è stata comunque eseguita, quindi proseguo come per un ok
                } else if (response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_NON_INVIATO")) {
                    //non deve più farlo ma bisogna comunque gestire il caso
                    //this.setState({ pdfBtnDisabled: true });                    
                } else if (response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_IN_ERRORE")) {
                    this.chiudiAnnullamentoRichiesta();
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: "Errore nella creazione del CDA2"
                    });
                    return;
                } else if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    this.chiudiAnnullamentoRichiesta();
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                    });
                    return;
                }
            }
            this.showOperazioneEseguita();
        });
    }

    cercaRichiesta = () => {
        let filtri = {
            filtriRichiesta: {
                id: this.props.uuidRichiesta
            },
            filtriAssistito: {},
            profilazione: AuthUtils.hasRuoloMedicoRichiedenteAndMedicoOspedaliero() ? false : true
        }

        RichiestaADIService.getRichiesteHome(null, null, filtri, null, null, null).subscribe((response) => {
            let richiesta;
            if (response?.msg === "ERRORE") {
                if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                    })
                    nascondiSpinner();
                }
            }
            else {
                richiesta = response?.data?.elementi[0];
                this.setState({ richiesta: richiesta }, () => nascondiSpinner());
            }
        });
    }

    confermaAnnullamento = () => {
        this.setState({ mostraModaleAttenzione: true });
    }

    downloadPdf = () => {
        let uuidRichiesta = this.props.uuidRichiesta;
        RichiestaADIService.generaPdf(uuidRichiesta, "STEP_CDA2_RIFIUTO_SETTING").subscribe((response) => {
            if (response?.msg === "ERRORE") {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "confirm",
                    text: "Il fascicolo sanitario elettronico non è al momento disponibile. Si prega di riprovare più tardi"
                });
            } else {
                const linkSource = `data:${response.data.contentType};base64,${response.data.file}`;
                const downloadLink = document.createElement("a");
                const fileName = response.data.fileName;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        });
    }

    annullaRichiesta = () => {
        mostraSpinner();

        let params = {};

        if (this.props.contestoAnnullamentoRichiesta === azioniRichiesta.richiediAnnullamentoRichiesta.label) {
            params.contesto = "richiesta";
        }
        else if (this.props.contestoAnnullamentoRichiesta === azioniRichiesta.avviaNuovoPreDialogo.label) {
            params.contesto = "sostituzione";
        }

        RichiestaADIService.annullaRichiesta(this.props.uuidRichiesta, this.state.motivazioneAnnullamento.text, params).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                if (response?.msgCode && response.msgCode.startsWith("WARNING_WD")) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "confirm",
                        text: errors[response?.msgCode]?.message || response?.msgCode
                    });
                    //l'operazione è stata comunque eseguita, quindi proseguo come per un ok
                } else {
                    Utils.toasterFunction(messageIt.alertAnnullamentoRichiestaFallito);
                    this.chiudiAnnullamentoRichiesta();
                    nascondiSpinner();
                    return;
                }
            }
            this.setState({
                mostraModaleAttenzione: false,
                mostraModaleOperazioneEseguita: true
            }, () => {
                SessionRequest.disposeSessionFor(this.props.uuidRichiesta);
                nascondiSpinner();
            });
        });

    }

    getNomeDistretto = (idDistretto) => {
        let nomeDistretto = aulssMappingSedi.find(el => el.distretti.find(el => el.id === idDistretto))?.distretti.find(el => el.id === idDistretto).nome;

        return nomeDistretto;
    }

    tornaHome = () => {
        this.chiudiAnnullamentoRichiesta();
        this.setState({ showModaleOperazioneEseguita: false });
    }

    renderModale = () => {
        let state = _.cloneDeep(this.state);

        let getDataInviata = () => {
            if (this.props.contestoAnnullamentoRichiesta === azioniRichiesta.annullaPreDialogo.label || this.props.contestoAnnullamentoRichiesta === azioniRichiesta.richiediAnnullamentoRichiesta.label) {
                return state.richiesta?.timestampCompletamentoPreDialogo;
            }
            else {
                return state.richiesta?.timestampCompletamento;
            }
        }

        const bodyModale = (
            <div className={"text-center"}>
                <label className={styles.inputLabel.concat("  pb-4")}>{"Assistito " + state.richiesta?.assistito?.cognome + " " + state.richiesta?.assistito?.nome + " | Inviata in data " + Utils.transformTimeStampForFrontend(getDataInviata()) + " al " + this.getNomeDistretto(state.richiesta.distretto)}</label>
                <Input
                    placeholder="Inserisci motivazione annullamento"
                    field="motivazioneAnnullamento"
                    onChange={this.handleAggiornaForm}
                    id="motivazioneAnnullamento"
                    value={state.motivazioneAnnullamento.text}
                    type={"textarea"}
                    parentClass={"mb-0"}
                    label="Motivazione dell'annullamento"
                    required
                    infoText="Campo obbligatorio"
                    caratteriMax={caratteriMax}
                />
            </div>
        );

        const bodyModaleAttenzione = (
            <div>{bodyModaleAttenzioneText}</div>
        )

        const bodyModaleAttenzioneTimestampCompletamentoNull = (
            <div>{bodyModaleAttenzioneTimestampCompletamentoNullText}</div>
        )

        const bodyModaleOperazioneEseguita = (
            <div>{bodyModaleOperazioneEseguitaText}</div>
        );

        const bodyModaleOperazioneEseguitaTimestampCompletamentoNull = (
            <div>{bodyModaleOperazioneEseguitaTimestampCompletamentoNullText}</div>
        )

        const bodyModaleAvviaPreDialogo = (
            <div>{getBodyModaleAvviaPreDialogoText(state.richiesta?.richiedente?.cognome, state.richiesta?.richiedente?.nome)}</div>
        )

        const getBody = () => {

            if (this.props.contestoAnnullamentoRichiesta === azioniRichiesta.annullaPreDialogo.label) {
                if (state.mostraModaleOperazioneEseguita) {
                    if (Utils.isStringEmptyOrNullOrUndefined(state.richiesta.timestampCompletamentoPreDialogo)) return (bodyModaleOperazioneEseguitaTimestampCompletamentoNull);
                    else return (bodyModaleOperazioneEseguita);
                }
                else if (state.mostraModaleAttenzione) {
                    return (bodyModaleAttenzione);
                }
                else if (!Utils.isStringEmptyOrNullOrUndefined(state.richiesta.timestampCompletamentoPreDialogo)) {
                    return (bodyModale);
                }
                else {
                    return (bodyModaleAttenzioneTimestampCompletamentoNull);
                }
            }
            else if (this.props.contestoAnnullamentoRichiesta === azioniRichiesta.richiediAnnullamentoRichiesta.label) {
                if (state.mostraModaleOperazioneEseguita) {
                    return (bodyModaleOperazioneEseguita);
                }
                else if (state.mostraModaleAttenzione) {
                    return (bodyModaleAttenzione);
                }
                else {
                    return (bodyModale);
                }
            }
            else if (this.props.contestoAnnullamentoRichiesta === azioniRichiesta.avviaNuovoPreDialogo.label) {
                if (state.mostraModaleOperazioneEseguita) {
                    return (bodyModaleOperazioneEseguita);
                }
                else {
                    return (bodyModaleAvviaPreDialogo);
                }
            }
            else {
                if (state.mostraModaleOperazioneEseguita) {
                    if (Utils.isStringEmptyOrNullOrUndefined(state.richiesta.timestampCompletamento)) return (bodyModaleOperazioneEseguitaTimestampCompletamentoNull);
                    else return (bodyModaleOperazioneEseguita);
                }
                else if (state.mostraModaleAttenzione) {
                    return (bodyModaleAttenzione);
                }
                else if (!Utils.isStringEmptyOrNullOrUndefined(state.richiesta.timestampCompletamento)) {
                    return (bodyModale);
                }
                else {
                    return (bodyModaleAttenzioneTimestampCompletamentoNull);
                }
            }
        };

        const footerModale = (
            <>
                <Button
                    onClick={() => this.confermaAnnullamento()}
                    id={"confermaBtn"}
                    text="Conferma annullamento"
                    className={"btn btn-adi btnGreenOutline"}
                    disabled={state.motivazioneAnnullamento.text.length === 0}
                />
            </>
        );

        const footerModaleAttenzione = (
            <>
                <Button
                    onClick={() => this.annullaRichiesta()}
                    id={"confermaBtnAttenzione"}
                    text="Conferma"
                    className={"btn btn-adi btnOrangeOutline"}
                />
                <Button
                    onClick={() => this.chiudiAnnullamentoRichiesta()}
                    id={"annullaBtnAttenzione"}
                    text="Annulla"
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    disabled={false}
                />
            </>
        )

        const footerModaleOperazioneEseguita = (
            <>
                <Button
                    onClick={() => this.downloadPdf()}
                    id={"visualizzaPdfBtn"}
                    text="Visualizza Pdf"
                    className={"btn btn-adi btnGreenOutline"}
                />
                        <Button
                            onClick={() => {
                                this.chiudiAnnullamentoRichiesta();
                                this.props.redirectStoricoRichiesta();
                            }}
                            id={"chiudiAnnullamentoRichiesta"}
                            text="Chiudi"
                            className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                        />
            </>
        );

        const getFooter = () => {
            if (this.props?.contestoAnnullamentoRichiesta === azioniRichiesta.annullaPreDialogo.label) {
                if (state.mostraModaleOperazioneEseguita) {
                    return (footerModaleOperazioneEseguita);
                }
                else if (state.mostraModaleAttenzione || Utils.isStringEmptyOrNullOrUndefined(state.richiesta.timestampCompletamentoPreDialogo)) {
                    return (footerModaleAttenzione);
                }
                else {
                    return (footerModale);
                }
            }
            else if (this.props.contestoAnnullamentoRichiesta === azioniRichiesta.richiediAnnullamentoRichiesta.label) {
                if (state.mostraModaleOperazioneEseguita) {
                    return (footerModaleOperazioneEseguita);
                }
                else if (state.mostraModaleAttenzione) {
                    return (footerModaleAttenzione);
                }
                else {
                    return (footerModale);
                }
            }
            else if (this.props.contestoAnnullamentoRichiesta === azioniRichiesta.avviaNuovoPreDialogo.label) {
                if (state.mostraModaleOperazioneEseguita) {
                    return (footerModaleOperazioneEseguita);
                }
                else {
                    return (footerModaleAttenzione);
                }
            }
            else {
                if (state.mostraModaleOperazioneEseguita) {
                    return (footerModaleOperazioneEseguita);
                }
                else if (state.mostraModaleAttenzione || Utils.isStringEmptyOrNullOrUndefined(state.richiesta.timestampCompletamento)) {
                    return (footerModaleAttenzione);
                }
                else {
                    return (footerModale);
                }
            }
        }

        const getTitle = () => {
            if (this.props?.contestoAnnullamentoRichiesta === azioniRichiesta.annullaPreDialogo.label) {
                if (state.mostraModaleOperazioneEseguita) return titleModaleOperazioneEseguita;
                else if (state.mostraModaleAttenzione || Utils.isStringEmptyOrNullOrUndefined(state.richiesta.timestampCompletamentoPreDialogo)) return titleModaleAttenzione;
                else return (
                    <>
                        <span>{titleModaleAnnullaRichiesta}</span>
                        <span className={styles.closeAnnullaRichiesta} onClick={() => this.chiudiAnnullamentoRichiesta()}><BsIcon.X size={24} /></span>
                    </>
                );
            }
            else if (this.props.contestoAnnullamentoRichiesta === azioniRichiesta.richiediAnnullamentoRichiesta.label) {
                if (state.mostraModaleOperazioneEseguita) {
                    return (titleModaleOperazioneEseguita);
                }
                else if (state.mostraModaleAttenzione) return titleModaleAttenzione;
                else {
                    return (
                        <>
                            <span>{titleModaleAnnullaRichiesta}</span>
                            <span className={styles.closeAnnullaRichiesta} onClick={() => this.chiudiAnnullamentoRichiesta()}><BsIcon.X size={24} /></span>
                        </>
                    );
                }
            }
            else if (this.props.contestoAnnullamentoRichiesta === azioniRichiesta.avviaNuovoPreDialogo.label) {
                if (state.mostraModaleOperazioneEseguita) return (titleModaleOperazioneEseguita);
                else return (titleModaleAttenzione);
            }
            else {
                if (state.mostraModaleOperazioneEseguita) return titleModaleOperazioneEseguita;
                else if (state.mostraModaleAttenzione || Utils.isStringEmptyOrNullOrUndefined(state.richiesta.timestampCompletamento)) return titleModaleAttenzione;
                else return (
                    <>
                        <span>{titleModaleAnnullaRichiesta}</span>
                        <span className={styles.closeAnnullaRichiesta} onClick={() => this.chiudiAnnullamentoRichiesta()}><BsIcon.X size={24} /></span>
                    </>
                );
            }
        }

        function getId() {
            if (state.mostraModaleOperazioneEseguita) return idModaleOperazioneEseguita;
            else if (state.mostraModaleAttenzione || Utils.isStringEmptyOrNullOrUndefined(state.richiesta.timestampCompletamento)) return idModaleAttenzione;
            else return idModale;
        }

        const getBackgroundStyle = () => {
            if (this.props?.contestoAnnullamentoRichiesta === azioniRichiesta.annullaPreDialogo.label) {
                if (state.mostraModaleOperazioneEseguita) return "backgroundGreen";
                else if (state.mostraModaleAttenzione || Utils.isStringEmptyOrNullOrUndefined(state.richiesta.timestampCompletamentoPreDialogo)) return "backgroundOrange"
                else return "backgroundGreen";
            }
            else if (this.props.contestoAnnullamentoRichiesta === azioniRichiesta.avviaNuovoPreDialogo.label) {
                if (state.mostraModaleOperazioneEseguita) return "backgroundGreen";
                else return "backgroundOrange";
            }
            else if (this.props.contestoAnnullamentoRichiesta === azioniRichiesta.richiediAnnullamentoRichiesta.label) {
                if (state.mostraModaleOperazioneEseguita) return "backgroundGreen";
                else if (state.mostraModaleAttenzione) return "backgroundOrange"
                else return "backgroundGreen";
            }
            else {
                if (state.mostraModaleOperazioneEseguita) return "backgroundGreen";
                else if (state.mostraModaleAttenzione || Utils.isStringEmptyOrNullOrUndefined(state.richiesta.timestampCompletamento)) return "backgroundOrange"
                else return "backgroundGreen";
            }
        }

        return (
            <>
                <ModalHelper
                    id={getId()}
                    title={getTitle()}
                    titleClassName={("modal-header d-inline text-center ").concat(getBackgroundStyle())}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={"modal-content"}
                    body={getBody()}
                    footer={getFooter()}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={"modal-body d-flex align-items-center text-center"}
                />
            </>
        );
    }

    render() {
        return (
            <>
                {!Utils.isObjectNull(this.state.richiesta) && this.renderModale()}
            </>
        )
    }

}

AnnullamentoRichiesta.propTypes = propTypes;
