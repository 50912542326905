import React, {Component} from "react";
import {Button, Icon, Input, SessioneUtente} from "web-client-archetype";
import Utils from "../../../utils/Utils";
import * as _ from "lodash";
import {Context} from "../../../utils/Context";
import PropTypes from "prop-types";
import styleGestionePassword from "./GestionePassword.module.css";
import {
    CONDIZIONE_PASSWORD_CARATTERE_SPECIALE,
    CONDIZIONE_PASSWORD_LENGTH,
    CONDIZIONE_PASSWORD_MAIUSCOLA
} from "./LockPassword";
import colors from "../../../hook/colors";

const propTypes = {
    location: PropTypes.object,
    crea_password: PropTypes.any,
}
const PASSWORD_MIN_LENGTH = 9;

class CreaPasswordModal extends Component {

    state = {
        password: null,
        confPassword: null,
        pulsanteConfermaAbilitato: false,
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }


    handleAggiornaState = (field, event) => {
        this.setState({[field]: event.target.value}, this.aggiornaBottoni);
    }

    aggiornaBottoni = () => {
        let pulsanteConfermaAbilitato = false;
        if (Utils.isPasswordValid(this.state.password) &&
            Utils.arePasswordMatch(this.state.password, this.state.confPassword)) {
            pulsanteConfermaAbilitato = true;
        }
        this.setState({pulsanteConfermaAbilitato: pulsanteConfermaAbilitato})
    }

    render() {
        return (
            <div className={"row pt-2"}>
                <div className={"col"}>
                    {this.renderInputPasswords()}
                    {this.renderTestoInformativo()}
                    {this.renderBottoni()}
                </div>
            </div>
        )
    }


    renderBottoni = () => {
        return <div className="d-flex justify-content-center">
            <Button
                id={"conferma"}
                text="Conferma"
                className={"btn btn-adi btnGreenOutline w-50"}
                disabled={!this.state.pulsanteConfermaAbilitato}
                onClick={() => this.props.crea_password(this.state.password, this.state.confPassword)}
            />
        </div>
    }

    renderTestoInformativo = () => {
        return <div className={"row pt-5 pb-2"}>
            <div className={"col"}>

                {!Utils.isValidStringMinLength(this.state.password,9)&& CONDIZIONE_PASSWORD_LENGTH}
                {!Utils.isValidAlphanumericMaiuscola(this.state.password) && CONDIZIONE_PASSWORD_MAIUSCOLA}
                {!Utils.isValidCaratteriSpeciali(this.state.password) && CONDIZIONE_PASSWORD_CARATTERE_SPECIALE}
            </div>
        </div>;
    }

    renderInputPasswords = () => {
        return <div className="row align-items-center">
            <div className="col-lg-12 col-xl-6">
                <Input
                    parentClass="mb-3 p-0"
                    placeholder="Imposta Password"
                    field="password"
                    onChange={this.handleAggiornaState}
                    id="password"
                    value={this.state.password}
                    type="password"
                    onValidate={(value) => value && !Utils.isPasswordValid(value)}
                    required
                    label={"Imposta Password"}
                />
            </div>
            <div className="col-lg-12 col-xl-6">
                <Input
                    parentClass="mb-3 p-0"
                    placeholder="Conferma Password"
                    field="confPassword"
                    onChange={this.handleAggiornaState}
                    id="confPassword"
                    value={this.state.confPassword}
                    type="password"
                    onValidate={(value) => value && !Utils.arePasswordMatch(this.state.password, value) && "*Le password non coincidono"}
                    required
                    label={"Conferma Password"}
                />
            </div>
        </div>;
    }
    
}



CreaPasswordModal.contextType = Context;
CreaPasswordModal.propTypes = propTypes;

export default CreaPasswordModal;
