import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    sistemaLinfatico: PropTypes.any,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultAlterazione = {
    id: null,
    tipoAlterazione: null,
    sede: null
}

export default class SistemaLinfatico extends AccordionSecondLevel {

    state = {
        dati: _.cloneDeep(this.props.sistemaLinfatico)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.sistemaLinfatico, this.props.sistemaLinfatico))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.sistemaLinfatico);
        this.setState({dati: dati});
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({dati}) => {
            if (index === null && subField === null) {
                dati[field] = elem;
            } else {
                dati[field][index][subField] = elem;
            }

            if (!dati.presenzaDiAlterazioni) {
                dati.tipoAlterazione = [];
                dati.condizioneRilevata = null;
            }

            return {dati};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        let dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("sistemaLinfatico", dati)
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneSistemaLinfatico", campiObbligatoriRimanenti);
    }

    /* Alterazioni */

    addNewAlterazione = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultAlterazione);
        type.id = uuid();
        dati.tipoAlterazione.push(type);
        this.setState({dati}, () => this.validazioneDati());
    }

    removeAlterazione = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.tipoAlterazione.splice(index, 1);
        this.setState({dati}, () => this.validazioneDati());
    }

    renderAlterazione = (alterazione, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'TIPO_ALT.SISTEMALINFATICO.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    id={"autocompleteTipoAlterazione" + index}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={(field, elem) => this.handleAggiornaForm("tipoAlterazione", elem.target.value, index, field)}
                    searchFromStart={false}
                    value={alterazione.tipoAlterazione}
                    field={"tipoAlterazione"}
                    placeholder={"ICD9-CM - Descrizione"}
                    fieldLabel={"Tipologia"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
                <RoleBasedInput
                    fieldId={'SEDE_ALT.SISTEMALINFATICO.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci sede"}
                    field={"sede"}
                    onChange={(field, elem) => this.handleAggiornaForm("tipoAlterazione", elem.target.value, index, field)}
                    id={"inputAlterazioneSede"}
                    value={alterazione.sede}
                    fieldLabel={"Sede/i"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
            </FieldsRow>
        );
    }

    renderBodySezione() {
        const dati = this.state.dati;
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        handleOnlyValue={true}
                        fieldId={'PRES_ALT.SISTEMALINFATICO.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"selectPresenzaDiAlterazioni"}
                        options={OggettiUtili.rispostaBreve}
                        value={dati.presenzaDiAlterazioni}
                        onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioni", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                        fieldLabel={"Presenza di alterazioni"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        field={"presenzaDiAlterazioni"}
                    />
                </FieldsRow>
                {dati.presenzaDiAlterazioni &&
                    <>
                        <SectionList
                            title={'Tipo di alterazione'}
                            data={dati.tipoAlterazione}
                            renderSection={this.renderAlterazione}
                            addNewSectionCallback={this.addNewAlterazione}
                            removeSectionCallback={this.removeAlterazione}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            pageState={this.props.pageState}
                            keyFieldId={'TIPO_ALT.SISTEMALINFATICO.ESAMEOBIETTIVO'}
                            field={'tipoAlterazione'}
                            parentJsonPath={"esameObiettivo.sistemaLinfatico.tipoAlterazione"}
                            addButtonVisibilityHandler={dati.tipoAlterazione?.every(t => !!t.tipoAlterazione)}
                        />

                        <CondizioneRilevataSection
                            ambito={'SISTEMA LINFATICO'}
                            parentFieldId={'SISTEMALINFATICO.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.condizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                            valueDaAttenzionare={dati.daAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            onChangeSuggerimenti={this.handleAggiornaForm}
                            valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.presenzaDiAlterazioni}
                        />
                </>}
            </>);
    }

    render() {
        if (this.props.showAccordion) {
            return (<AccordionSezioneRichiesta
                idAccordion={"sistemaLinfatico"}
                title={"Sistema linfatico"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.sistemaLinfatico.tipoAlterazione",
                            "esameObiettivo.sistemaLinfatico.propostaAttivitaSuggerimenti"],
                        [defaultAlterazione, defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>);
        } else {
            return this.renderBodySezione();
        }
    }
}
SistemaLinfatico.propTypes = propTypes;

SistemaLinfatico.defaultProps = {
    showAccordion: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}
