import React, {useContext, useEffect, useState} from "react";
import routepath from "../../../src/utils/route/route-path";

const routepathInWhichToShowFooter = [
    routepath.compila_scheda_paziente, routepath.completa_valutazione_multidimensionale,
    routepath.accedi_valutazione_multidimensionale, routepath.visualizza_valutazione_multidimensionale,
    routepath.compila_proposte, routepath.visualizza_riepilogo, routepath.verifica_valutazione,
    routepath.compila_formalizzazione, routepath.anagrafica_medico_richiedente, routepath.stato_valutazione_componenti_team
];

function RenderFooter ({}) {

    const [isVisibleFooter, setIsVisibleFooter] = useState(false);

    useEffect(() => {
        setIsVisibleFooter(!routepathInWhichToShowFooter.find(l => window.location.pathname.includes(l)))
    }, [window.location?.pathname]);

    return (
        isVisibleFooter && <div className="footer-container mt-auto"><div className={"footer d-flex align-items-center"}><span className='ml-4'>© Regione Veneto</span></div></div>
    )
}

RenderFooter.propTypes = {};

RenderFooter.defaultProps = {};

export default RenderFooter;
