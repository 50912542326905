import React, {Fragment} from "react";
import PropTypes from "prop-types";
import styles from "./Questionario.module.css";
import enumQuestionario from "../../../enum/enumQuestionario";
import {CheckBox, Input, RadioButton} from "web-client-archetype";
import {
    areCampiObbligatoriSezioneCompilati,
    calcoloValutazione,
    getSezioneQuestionario,
    getSezioneValutazione,
    punteggiIntermedi
} from "../../../utils/QuestionariUtils";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import {DropdownIndicator, inputSelectStyles} from "../../common/select/SelectUtils";
import Select from "react-select";
import DicotomicaOrizzontale from "./risposte/DicotomicaOrizzontale";
import RenderPunteggio from "./sezione/RenderPunteggio";
import RenderTitoloSezione from "./sezione/RenderTitoloSezione";

const propTypes = {
    tipoQuestionario: PropTypes.string,
    sezioneRichiesta: PropTypes.object,
    punteggioRichiesta: PropTypes.any,
    changeDatiQuestionario: PropTypes.func,
    disabilitato: PropTypes.bool,
    isQuestionarioCompleto: PropTypes.bool,
    isQuestionarioObbligatorio: PropTypes.bool,
    sessoPaziente: PropTypes.any,
    aggiornaPunteggiIntermedi: PropTypes.func,
    showPunteggioRisposta: PropTypes.bool,
    obbligatorieta: PropTypes.object
};
const defaultProps = {
    punteggioRichiesta: null,
    showPunteggioRisposta: false,
    isQuestionarioObbligatorio: false,
    obbligatorieta: null
};

export default class Questionario extends React.Component {
    state = {
        sezione: null,
        punteggio: null,
        valutazione: null
    };

    sezioneQuestionario = null;
    valoriModificati = {};

    componentDidMount() {
        if(this.sezioneQuestionario == null && this.props.sezioneRichiesta){
            this.sezioneQuestionario = _.cloneDeep(getSezioneQuestionario(this.props.tipoQuestionario, this.props.sezioneRichiesta.id));
        }
        this.setSezione(true);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!_.isEqual(prevProps.sezioneRichiesta, this.props.sezioneRichiesta)){
            // setta sezione ed eventualmente calcola il punteggio
            if(prevProps && (prevProps.sezioneRichiesta?.id != this.props.sezioneRichiesta.id) && this.props.tipoQuestionario === enumQuestionario.tipoQuestionario.PSAN) {
                this.sezioneQuestionario = _.cloneDeep(getSezioneQuestionario(this.props.tipoQuestionario, this.props.sezioneRichiesta.id));
            }
            this.setSezione(true);
        }
        // si aggiorna il punteggio solo in caso di props valorizzata,
        // viene valorizzata se è una sezione (sotto-questionario) che ha solo il punteggio (es. PSAN, TINETTI)
        if(!_.isEqual(prevProps.punteggioRichiesta, this.props.punteggioRichiesta) &&
            this.props.punteggioRichiesta != null){
            this.setPunteggio(_.cloneDeep(this.props.punteggioRichiesta));
        }
    }

    componentWillUnmount() {
        this.sezioneQuestionario = null;
    }

    setSezione = (isCalcolaPunteggio = false) => {
        this.setState({sezione: this.props.sezioneRichiesta}, () => {
            this.aggiornaSezioneQuestionario();
            if (isCalcolaPunteggio) {
                let punteggio = this.props.punteggioRichiesta != null ? this.props.punteggioRichiesta : this.calcoloPunteggio();
                this.setPunteggio(punteggio);
            }
        })
    }

    aggiornaSezioneQuestionario() {
        _.forEach(this.state.sezione.domande, domanda => {
            if (domanda.risposteValori?.length > 0) {
                _.forEach(domanda.risposteValori, rispostaValore => {
                    this.aggiornaRisposteValori(domanda.id, rispostaValore.id, rispostaValore.valore)
                })
            }
            this.modificaSezioneQuestionario(domanda);
        })
    }

    modificaSezioneQuestionario(domanda) {
        _.forEach(domanda.risposte, risposta => {
            switch (this.props.tipoQuestionario) {
                case enumQuestionario.tipoQuestionario.PMOB:
                    this.modificaSezionePMOB(domanda.id, risposta.id, _.cloneDeep(this.state.sezione))
                    break;
                case enumQuestionario.tipoQuestionario.NOPPAIN:
                    this.modificaSezioneNOPPAIN(domanda.id, risposta.id, _.cloneDeep(this.state.sezione))
                    break;
                case enumQuestionario.tipoQuestionario.NECPAL:
                    this.modificaSezioneNECPAL(domanda.id, risposta.id, _.cloneDeep(this.state.sezione))
                    break;
                case enumQuestionario.tipoQuestionario.BRASS:
                    this.modificaSezioneBRASS(domanda.id, risposta.id, _.cloneDeep(this.state.sezione))
                    break;
                default:
                    break;
            }
        })
    }

    modificaSezioneBRASS(idDomanda, idRisposta) {
        if (idDomanda == 3)
            if (idRisposta == 12) this.modificaDomande([4], true)
            else this.modificaDomande([4], false)
    }

    handleChangeDatiQuestionarioBRASS(idDomanda, idRisposta, sezione, punteggio, isDomandaConRisposteMultiple, valoreRisposta, linkId, system, code, tipoRisposta, display, text) {
        if(idDomanda == 3) {
            if(idRisposta == 11) sezione.domande = sezione.domande.filter(datiDomandaRichiesta => datiDomandaRichiesta.id !== 4)
            this.modificaSezioneBRASS(idDomanda, idRisposta)
        }
        this.handleChangeDatiSezioneEPunteggio(sezione, idDomanda, isDomandaConRisposteMultiple, idRisposta, punteggio, valoreRisposta, false, linkId, system, code, tipoRisposta, display, text);
    }

    setPunteggio = (punteggio) => {
        this.setState({punteggio: punteggio}, () => {
            if (getSezioneValutazione(this.props.tipoQuestionario).id !== this.sezioneQuestionario.id) {
                // se props punteggioRichiesto = null si valorizza punteggiIntermedi, quindi solo per sotto-questionari
                if (punteggiIntermedi[this.props.tipoQuestionario] == null) {
                    punteggiIntermedi[this.props.tipoQuestionario] = {}
                }
                punteggiIntermedi[this.props.tipoQuestionario][this.sezioneQuestionario.id] = punteggio;
            }
            // if(this.props.tipoQuestionario === enumQuestionario.tipoQuestionario.PASSI){
            //     punteggio = this.impostaPunteggioFittizzioPerQuestionarioPASSI();
            // }
            let valutazione = calcoloValutazione(this.props.tipoQuestionario, this.sezioneQuestionario, punteggio, this.props.sessoPaziente);
            this.setState({valutazione: valutazione})
        })
    }

    calcoloPunteggio = () => {
        let punteggio = null;

        if (this.state.sezione.domande?.length > 0) {
            punteggio = 0;
            this.state.sezione.domande.forEach(domanda => {
                let risposte = this.getListaIdRisposteDomanda(domanda.id);
                risposte.forEach(idRisposta => {
                    let valoreRisposta = this.getValoreRisposta(this.getDomandaQuestionario(domanda.id), idRisposta);
                    punteggio += valoreRisposta;
                })
            })
        }

        if (punteggio != null) {
            let decimaliPunteggio = punteggio - Math.floor(punteggio);
            if (decimaliPunteggio !== 0.5) punteggio = Math.round(punteggio);
        }

        return punteggio;
    }

    handleChangeDatiQuestionario = (idDomanda, idRisposta, isDomandaConRisposteMultiple, valoreRisposta, isAggiornaValoreRispostaSezione, linkId, system, code, tipoRisposta, display, text) => {
        let sezione = _.cloneDeep(this.state.sezione);
        let punteggio = _.cloneDeep(this.state.punteggio);

        if(isAggiornaValoreRispostaSezione){
            this.aggiornaRisposteValori(idDomanda, idRisposta, valoreRisposta);
        }

        switch(this.props.tipoQuestionario){
            case enumQuestionario.tipoQuestionario.PMOB: {
                this.handleChangeDatiQuestionarioPMOB(idDomanda, idRisposta, sezione, punteggio, isDomandaConRisposteMultiple, valoreRisposta, linkId, system, code, tipoRisposta, display, text);
                break;
            }
            case enumQuestionario.tipoQuestionario.PASSI: {
                this.handleChangeDatiQuestionarioPASSI(idDomanda, idRisposta, sezione, isDomandaConRisposteMultiple, punteggio, valoreRisposta, isAggiornaValoreRispostaSezione, linkId, system, code, tipoRisposta, display, text);
                break;
            }
            case enumQuestionario.tipoQuestionario.NOPPAIN: {
                this.handleChangeDatiQuestionarioNOPPAIN(idDomanda, idRisposta, sezione, isDomandaConRisposteMultiple, punteggio, valoreRisposta, isAggiornaValoreRispostaSezione, linkId, system, code, tipoRisposta, display, text);
                break;
            }
            case enumQuestionario.tipoQuestionario.NECPAL: {
                this.handleChangeDatiQuestionarioNECPAL(idDomanda, idRisposta, sezione, isDomandaConRisposteMultiple, punteggio, valoreRisposta, isAggiornaValoreRispostaSezione, linkId, system, code, tipoRisposta, display, text);
                break;
            }
            case enumQuestionario.tipoQuestionario.BRASS: {
                this.handleChangeDatiQuestionarioBRASS(idDomanda, idRisposta, sezione, punteggio, isDomandaConRisposteMultiple, valoreRisposta, linkId, system, code, tipoRisposta, display, text);
                break;
            }
            default:
                this.handleChangeDatiSezioneEPunteggio(sezione, idDomanda, isDomandaConRisposteMultiple, idRisposta, punteggio, valoreRisposta, isAggiornaValoreRispostaSezione, linkId, system, code, tipoRisposta, display, text);
                break;
        }
    }

    aggiornaRisposteValori(idDomanda, idRisposta, valoreRisposta) {
        let indexDomandaSezione = _.findIndex(this.sezioneQuestionario.domande, domandaSezione => domandaSezione.id === idDomanda);
        let risposteDomandaSezione = this.sezioneQuestionario.domande[indexDomandaSezione].risposte;
        let indexRispostaSezione = _.findIndex(risposteDomandaSezione, rispostaSezione => rispostaSezione.id === idRisposta);
        this.valoriModificati = {[idDomanda]: {[idRisposta]: {valore: this.sezioneQuestionario.domande[indexDomandaSezione].risposte[indexRispostaSezione].valore}}};
        this.sezioneQuestionario.domande[indexDomandaSezione].risposte[indexRispostaSezione].valore = valoreRisposta;
    }

    /**
     * funzione che abilita/disabilita le domande in funzione alla risposta precedente del questionario NECPAL
     * se la risposta della prima domanda del questionario en NO nasconde tutte le sessione che la prosegueno
    */

     abilitaDisabilitaDomandeNECPAL(idDomanda, idRisposta) {
        if (idDomanda === 4)
            if (idRisposta === 8) this.modificaDomande([5], true)
            else this.modificaDomande([5], false)

        if (idDomanda === 6)
            if (idRisposta === 13) this.modificaDomande([7], true)
            else this.modificaDomande([7], false)

        if (idDomanda === 8)
            if (idRisposta === 18) this.modificaDomande([9], true)
            else this.modificaDomande([9], false)

        if (idDomanda === 11)
            if (idRisposta === 27) this.modificaDomande([12], true)
            else this.modificaDomande([12], false)

        if (idDomanda === 14)
            if (idRisposta === 33) this.modificaDomande([15], true)
            else this.modificaDomande([15], false)

        if (idDomanda === 16)
            if (idRisposta === 38) this.modificaDomande([17], true)
            else this.modificaDomande([17], false)   
        
        if (idDomanda === 18)
            if (idRisposta === 46) this.modificaDomande([19], true)
            else this.modificaDomande([19], false)

        if (idDomanda === 20)
            if (idRisposta === 54) this.modificaDomande([21], true)
            else this.modificaDomande([21], false)

        if (idDomanda === 22)
            if (idRisposta === 58) this.modificaDomande([23], true)
            else this.modificaDomande([23], false)

        if (idDomanda === 24)
            if (idRisposta === 65) this.modificaDomande([25], true)
            else this.modificaDomande([25], false)

        if (idDomanda === 26)
            if (idRisposta === 69) this.modificaDomande([27], true)
            else this.modificaDomande([27], false)

        if (idDomanda === 28)
            if (idRisposta === 72) this.modificaDomande([29], true)
            else this.modificaDomande([29], false)

        if (idDomanda === 1)
            if (idRisposta === 1) this.modificaDomande([1, 2, 3, 4, 6, 8, 10, 11, 13, 14, 16, 18, 20, 22, 24, 26, 28], true)
            else this.modificaDomande([2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24, 26, 28], false)
    }

    handleChangeDatiQuestionarioNECPAL(idDomanda, idRisposta, sezione, isDomandaConRisposteMultiple, punteggio, valoreRisposta, isAggiornaValoreRispostaSezione, linkId, system, code, tipoRisposta, display, text) {
        this.abilitaDisabilitaDomandeNECPAL(idDomanda, idRisposta)
        this.handleChangeDatiSezioneEPunteggio(sezione, idDomanda, isDomandaConRisposteMultiple, idRisposta, punteggio, valoreRisposta, isAggiornaValoreRispostaSezione, linkId, system, code, tipoRisposta, display, text);
    }

    modificaSezioneNECPAL(idDomanda, idRisposta) { this.abilitaDisabilitaDomandeNECPAL(idDomanda, idRisposta) }

    handleChangeDatiQuestionarioNOPPAIN(idDomanda, idRisposta, sezione, isDomandaConRisposteMultiple, punteggio, valoreRisposta, isAggiornaValoreRispostaSezione, linkId, system, code, tipoRisposta, display, text) {
        let isDomandaSezione1 = idDomanda > 0 && idDomanda < 19;
        let isDomandaA = (idDomanda % 2) === 1; // idDomanda dispari
        let isRispostaSi = (idRisposta % 2) === 1; // idRisposta dispari

        if (isDomandaSezione1 && isDomandaA) {
            let idDomandaLabelB = idDomanda + 1;
            if(!isRispostaSi){
                // pulisco array dalla domanda successiva label "B" se valorizzata
                let domandaLabelB = this.getDomanda(sezione, idDomandaLabelB);
                if(domandaLabelB != null){
                    sezione.domande = sezione.domande.filter(datiDomandaRichiesta => idDomandaLabelB !== datiDomandaRichiesta.id);
                    let valoreRispostaDomandaLabelB = this.getValoreRisposta(this.getDomandaQuestionario(idDomandaLabelB), domandaLabelB.risposte[0]);
                    punteggio -= valoreRispostaDomandaLabelB;
                }
                // non obbligatorietà domanda label "B"
                this.modificaDomande([idDomandaLabelB], false);
            } else {
                // obbligatorietà domanda label "B"
                this.modificaDomande([idDomandaLabelB], true)
            }
        }

        this.handleChangeDatiSezioneEPunteggio(sezione, idDomanda, isDomandaConRisposteMultiple, idRisposta, punteggio, valoreRisposta, isAggiornaValoreRispostaSezione, linkId, system, code, tipoRisposta, display, text);
    }

    handleChangeDatiQuestionarioPASSI(idDomanda, idRisposta, sezione, isDomandaConRisposteMultiple, punteggio, valoreRisposta, isAggiornaValoreRispostaSezione, linkId, system, code, tipoRisposta, display, text) {
        if (idDomanda === 1 && idRisposta !== 1) sezione.domande = sezione.domande.filter(datiDomandaRichiesta => ![2].includes(datiDomandaRichiesta.id));
        else if (idDomanda === 3 && idRisposta !== 8) sezione.domande = sezione.domande.filter(datiDomandaRichiesta => ![4, 5].includes(datiDomandaRichiesta.id));
        else if (idDomanda === 6 && idRisposta !== 21) sezione.domande = sezione.domande.filter(datiDomandaRichiesta => ![7, 8].includes(datiDomandaRichiesta.id));
        this.handleChangeDatiSezioneEPunteggio(sezione, idDomanda, isDomandaConRisposteMultiple, idRisposta, punteggio, valoreRisposta, isAggiornaValoreRispostaSezione, linkId, system, code, tipoRisposta, display, text);
    }

    handleChangeDatiQuestionarioPMOB(idDomanda, idRisposta, sezione, punteggio, isDomandaConRisposteMultiple, valoreRisposta, linkId, system, code, tipoRisposta, display, text) {
        const ID_DOMANDA_2 = 2; // Deambulazione
        const ID_RISPOSTA_10_CONDIZIONE_ID_DOMANDA_2 = 10;
        const ID_DOMANDA_3 = 3;

        // se la domanda ha id 2: Deambulazione
        if (idDomanda === ID_DOMANDA_2) {
            if(idRisposta !== ID_RISPOSTA_10_CONDIZIONE_ID_DOMANDA_2){
                let domandaId3 = this.getDomanda(sezione, ID_DOMANDA_3);
                if(domandaId3 != null){
                    sezione.domande = sezione.domande.filter(datiDomandaRichiesta => datiDomandaRichiesta.id !== ID_DOMANDA_3);
                    let valoreRispostaIdDomanda3 = this.getValoreRisposta(this.getDomandaQuestionario(ID_DOMANDA_3), domandaId3.risposte[0].id);
                    punteggio -= valoreRispostaIdDomanda3;
                }
                // non obbligatorietà domanda id 3
                this.modificaDomande([ID_DOMANDA_3], false);
            } else {
                // obbligatorietà domanda id 3
                this.modificaDomande([ID_DOMANDA_3], true)
            }
        }

        this.handleChangeDatiSezioneEPunteggio(sezione, idDomanda, isDomandaConRisposteMultiple, idRisposta, punteggio, valoreRisposta, false, linkId, system, code, tipoRisposta, display, text);
    }

    modificaDomande = (idDomandeDaModificare, obbligatorio) => {
        this.sezioneQuestionario.domande = _.map(this.sezioneQuestionario.domande, domanda => {
            if (idDomandeDaModificare.includes(domanda.id)) {
                domanda.obbligatorio = obbligatorio;
            }
            return domanda;
        });
    };

    modificaSezionePMOB(idDomanda, idRisposta, sezione) {
        const ID_DOMANDA_2 = 2; // Deambulazione
        const ID_RISPOSTA_10_CONDIZIONE_ID_DOMANDA_2 = 10;
        const ID_DOMANDA_3 = 3;

        // se la domanda ha id 2: Deambulazione
        if (idDomanda === ID_DOMANDA_2) {
            if(idRisposta !== ID_RISPOSTA_10_CONDIZIONE_ID_DOMANDA_2){
                let domandaId3 = this.getDomanda(sezione, ID_DOMANDA_3);
                if(domandaId3 != null){
                    // non obbligatorietà domanda id 3
                    this.modificaDomande([ID_DOMANDA_3], false);
                }
            } else {
                // obbligatorietà domanda id 3
                this.modificaDomande([ID_DOMANDA_3], true)
            }
        }
    }

    modificaSezioneNOPPAIN(idDomanda, idRisposta, sezione) {
        let isDomandaSezione1 = idDomanda > 0 && idDomanda < 19;
        let isDomandaA = (idDomanda % 2) === 1; // idDomanda dispari
        let isRispostaSi = (idRisposta % 2) === 1; // idRisposta dispari

        if (isDomandaSezione1 && isDomandaA) {
            let idDomandaLabelB = idDomanda + 1;
            if(!isRispostaSi){
                // non obbligatorietà domanda label "B"
                this.modificaDomande([idDomandaLabelB], false);
            } else {
                // obbligatorietà domanda label "B"
                this.modificaDomande([idDomandaLabelB], true)
            }
        }
    }

    handleChangeDatiSezioneEPunteggio(sezione, idDomanda, isDomandaConRisposteMultiple, idRisposta, punteggio, valoreRisposta, isAggiornaValoreRispostaSezione, linkId, system, code, tipoRisposta, display, text) {
        if (sezione) {
            let domanda = this.getDomanda(sezione, idDomanda);
            if (domanda) {
                // Se ci sono già dati sulle risposte relative alla domanda

                if (isDomandaConRisposteMultiple) {
                    // Se la domanda è con risposte di tipo checkbox

                    if (_.findIndex(domanda.risposte, risposta => risposta.id === idRisposta) > -1) {
                        // Se la risposta specificata è già fra quelle selezionate, voglio de-selezionarla

                        if (domanda.risposte?.length > 1) {
                            // Se c'è più di una risposta selezionata, lascio l'oggetto con i dati della domanda, e rimuovo la risposta da de-selezionare
                            domanda.risposte = domanda.risposte.filter(risposta => risposta.id !== idRisposta);
                        } else {
                            // Se la risposta specificata è l'unica legata alla domanda, rimuovo i dati della domanda
                            sezione.domande = sezione.domande.filter(domanda => domanda.id !== idDomanda);
                        }

                        punteggio -= Number(valoreRisposta);

                    } else {
                        // Se la risposta specificata non è fra quelle già selezionate, voglio aggiungerla
                        domanda.risposte.push({id: idRisposta, system: system, code: code, display: display});
                        punteggio += Number(valoreRisposta);
                    }
                } else {
                    // Sovrascrivo l'array risposte (che conterrà sempre un solo elemento) inserendo la nuova risposta

                    let valoreRispostaPrecedente;
                    if (isAggiornaValoreRispostaSezione) {
                        valoreRispostaPrecedente = this.valoriModificati[idDomanda][idRisposta].valore
                    } else {
                        valoreRispostaPrecedente = this.getValoreRisposta(this.getDomandaQuestionario(domanda.id), domanda?.risposte[0]?.id);
                    }

                    punteggio -= Number(valoreRispostaPrecedente);

                    domanda.risposte = [{id: idRisposta, system: system, code: code, display: display}];
                    if(isAggiornaValoreRispostaSezione){
                        let rispostaValore = {id: idRisposta, valore: this.valoriModificati[idDomanda][idRisposta].valore};
                        if(domanda.risposteValori?.length > 0){
                            let indexRispostaValore = _.findIndex(domanda.risposteValori, rispostaValore => rispostaValore.id === idRisposta)
                            if(indexRispostaValore > -1){
                                domanda.risposteValori[indexRispostaValore] = rispostaValore
                            } else {
                                domanda.risposteValori.push(rispostaValore)
                            }
                        } else {
                            domanda.risposteValori = [rispostaValore]
                        }
                    }

                    punteggio += Number(valoreRisposta);
                }
            } else {
                // Se non era stata inserita nessuna risposta per la domanda, aggiungo i dati
                let risposteValori = null;
                if(isAggiornaValoreRispostaSezione){
                    risposteValori = [{id: idRisposta, valore: this.valoriModificati[idDomanda][idRisposta].valore}]
                }
                let domandaRichiesta = {id: idDomanda, linkId: linkId, text: text, tipoRisposta: tipoRisposta, risposte: [{id: idRisposta, system: system, code: code, display: display}], risposteValori: risposteValori};
                sezione.domande.push(domandaRichiesta);
                punteggio += Number(valoreRisposta);
            }

            this.setState({sezione: sezione, punteggio: punteggio}, () => {
                // if(this.props.tipoQuestionario === enumQuestionario.tipoQuestionario.PASSI){
                //     punteggio = this.impostaPunteggioFittizzioPerQuestionarioPASSI();
                // }
                let valutazione = calcoloValutazione(this.props.tipoQuestionario, this.sezioneQuestionario, punteggio, this.props.sessoPaziente);
                this.setState({valutazione: valutazione}, () => {
                    this.props.changeDatiQuestionario(this.state.sezione, this.state.punteggio, this.state.valutazione, this.sezioneQuestionario)
                })

            });
        }
    }


    getDomanda(sezione, idDomanda) {
        let indexDomanda = sezione.domande.findIndex(datiDomanda => datiDomanda.id === idDomanda);
        return sezione.domande[indexDomanda];
    }

    getDomandaQuestionario = (idDomanda) => {
        return this.sezioneQuestionario.domande.find(domanda => domanda.id === idDomanda);
    }

    getValoreRisposta = (domanda, idRisposta) => {
        let valoreRisposta = 0;
        if (domanda) {
            let risposta = domanda.risposte.find(risposta => risposta.id === idRisposta);
            if(risposta?.valore){
                valoreRisposta = risposta.valore;
            }
        }
        return valoreRisposta;
    }

    renderDomande = () => {
        let domande = [];

        if (this.sezioneQuestionario.domande) {
            let gruppiTrovati = [];
            this.sezioneQuestionario.domande.forEach(domanda => {
                // render gruppo domanda
                let gruppo = domanda.gruppo;
                // se già presente il gruppo nell'array gruppiTrovati significa che è già stata renderizzata la domanda quindi si skippa
                if(gruppiTrovati.includes(gruppo)){
                    return;
                }

                if (!Utils.isStringEmptyOrNullOrUndefined(gruppo)) {
                    // aggiorno la lista dei gruppi trovati
                    gruppiTrovati.push(gruppo);
                    // raggruppa tutte le domande per quel gruppo presenti nelle array domande
                    let domandeGruppo = _.filter(this.sezioneQuestionario.domande, domandaArray => domandaArray.gruppo === gruppo);
                    let domandeGruppoJSX = [];
                    _.forEach(domandeGruppo, domandaGruppo => {
                        let isDomandaDisabilitata = !this.isDomandaAbilitataPerTipoQuestionario(domandaGruppo);
                        this.renderDomanda(domandeGruppoJSX, domandaGruppo, isDomandaDisabilitata, true)
                    });
                    domande.push(
                        <div className={"row mt-2"}>
                            <div className={"col"}>
                                <span>{gruppo} {domanda.obbligatorio ?
                                    <span className={styles.requiredFields}>*</span> : null}</span>
                                {domandeGruppoJSX}
                            </div>
                        </div>
                    )
                } else {
                    // controlla se la domanda deve essere disabilitata secondo le logiche del questionario
                    let isDomandaDisabilitata = !this.isDomandaAbilitataPerTipoQuestionario(domanda);
                    this.renderDomanda(domande, domanda, isDomandaDisabilitata);
                }
            })
        }

        return (
            <Fragment>
                {domande}
            </Fragment>
        )
    }

    renderDomanda(domande, domanda, disabilitaDomanda, isGruppo = false) {
        let labelDomanda = <span className={"f-weight-600"}  >{domanda.label} {domanda.label && domanda.obbligatorio ?
            <span className={styles.requiredFields}>*</span> : null}</span>;
        let labelDomandaGruppo = <span className="f-weight-600">{domanda.label}</span>;

        if(domanda.tipoRisposte === enumQuestionario.tipoRisposte.dicotomicaOrizzontale){
            let DomandadicotomicaOrizzontale = <DicotomicaOrizzontale labelDomanda={labelDomanda} renderRisposte={() => this.renderRisposte(domanda, disabilitaDomanda)}/>  
            domande.push(DomandadicotomicaOrizzontale);
            return;
        }

        if(domanda.tipoRisposte === enumQuestionario.tipoRisposte.rating){
            let domandaRating = <Fragment>
                <div className={styles.risposta.concat(" row mt-2")}>
                    <div className={"col m-auto"}>
                        {domanda.label}
                    </div>
                    <div className={"col align-items-center justify-content-center"}>
                        {this.renderRisposte(domanda, disabilitaDomanda)}
                    </div>
                    <div className={"col m-auto"}>
                        {domanda.labelSecondaria}
                    </div>
                    <div className={"col-auto"}>
                        {domanda.obbligatorio ?
                            <span className={styles.requiredFields}>*</span>
                            : <Fragment/>
                        }
                    </div>
                </div>
            </Fragment>;
            domande.push(domandaRating);
        } else {
            let domandaBase = <Fragment>
                <div className={isGruppo ? "row" : "row mt-2"}>
                    <div className={!isGruppo && !domanda.indicazione ? "mb-2 mt-2 col": "col"}>
                        {
                            isGruppo ? labelDomandaGruppo : labelDomanda
                        }
                        {domanda.indicazione && <p className={"mb-4"}>{domanda.indicazione}</p>}
                    </div>
                </div>
            </Fragment>;
            domande.push(domandaBase);

            if (domanda.risposte) {
                domande.push((
                    <div className={"row"}>
                        <div className={"col"}>
                            {this.renderRisposte(domanda, disabilitaDomanda)}
                        </div>
                    </div>
                ))
            }
        }
    }

    isDomandaAbilitataPerTipoQuestionario(domanda) {
        let isDomandaAbilitata;
        switch (this.props.tipoQuestionario) {
            case enumQuestionario.tipoQuestionario.PMOB: {
                let ID_DOMANDA_2 = 2;
                let ID_RISPOSTA_10_CONDIZIONE_DOMANDA_2 = 10;
                isDomandaAbilitata = (domanda.id) !== 3 ||
                    (domanda.id === 3 && this.getListaIdRisposteDomanda(ID_DOMANDA_2).includes(ID_RISPOSTA_10_CONDIZIONE_DOMANDA_2));
            }
                break;
            case enumQuestionario.tipoQuestionario.PASSI: {
                let domandaAbilitataIdDomanda1 = [1].includes(domanda.id) ||
                    ([2].includes(domanda.id) && this.getListaIdRisposteDomanda(1).includes(1));
                let domandaAbilitataIdDomanda3 = [3].includes(domanda.id) ||
                    ([4, 5].includes(domanda.id) && this.getListaIdRisposteDomanda(3).includes(8));
                let domandaAbilitataIdDomanda6 = [6].includes(domanda.id) ||
                    ([7, 8].includes(domanda.id) && this.getListaIdRisposteDomanda(6).includes(21));
                let domandaAbilitataIdDomdana9 = [9].includes(domanda.id);
                isDomandaAbilitata = domandaAbilitataIdDomanda1 || domandaAbilitataIdDomanda3 || domandaAbilitataIdDomanda6 || domandaAbilitataIdDomdana9;
            }
                break;
            case enumQuestionario.tipoQuestionario.NOPPAIN: {
                let isDomandaSezione1 = domanda.id > 0 && domanda.id < 19;
                let isDomandaAltreSezioni = domanda.id >= 19;
                let labelA = domanda.label === "A" && isDomandaSezione1;
                let idDomandaLabelA = domanda.id - 1;
                let rispostaDispari = (this.getListaIdRisposteDomanda(idDomandaLabelA)[0] % 2) === 1;
                let labelB = domanda.label === "B" && rispostaDispari;
                isDomandaAbilitata = isDomandaAltreSezioni || (isDomandaSezione1 && (labelA || labelB));
            }
                break;
            case enumQuestionario.tipoQuestionario.NECPAL: {
                const domandeAbilitate = [1,2,3,4,6,8,10,11,13,14,16,18,20,22,24,26,28];
                if(this.getListaIdRisposteDomanda(4).includes(8))   domandeAbilitate.push(5);
                if(this.getListaIdRisposteDomanda(6).includes(13))  domandeAbilitate.push(7);
                if(this.getListaIdRisposteDomanda(8).includes(18))  domandeAbilitate.push(9);
                if(this.getListaIdRisposteDomanda(11).includes(27)) domandeAbilitate.push(12);
                if(this.getListaIdRisposteDomanda(14).includes(33)) domandeAbilitate.push(15);
                if(this.getListaIdRisposteDomanda(16).includes(38)) domandeAbilitate.push(17);
                if(this.getListaIdRisposteDomanda(18).includes(46)) domandeAbilitate.push(19);
                if(this.getListaIdRisposteDomanda(20).includes(54)) domandeAbilitate.push(21);
                if(this.getListaIdRisposteDomanda(22).includes(58)) domandeAbilitate.push(23);
                if(this.getListaIdRisposteDomanda(24).includes(65)) domandeAbilitate.push(25);
                if(this.getListaIdRisposteDomanda(26).includes(69)) domandeAbilitate.push(27);
                if(this.getListaIdRisposteDomanda(28).includes(72)) domandeAbilitate.push(29);

                isDomandaAbilitata = domandeAbilitate.includes(domanda.id);
            }
            break;
            case enumQuestionario.tipoQuestionario.BRASS: {
                isDomandaAbilitata = true;
                if((this.getListaIdRisposteDomanda(3)?.length == 0 || this.getListaIdRisposteDomanda(3).includes(11)) &&  domanda.id === 4) isDomandaAbilitata = false;
            }
            break;
            default:
                isDomandaAbilitata = true;
                break;
        }
        return isDomandaAbilitata;
    }

    renderRisposte = (domanda, disabilitaDomanda = false) => {
        let risposte = [];
        let gruppiSelect = [];

        let isTipoRisposteRating = (domanda.tipoRisposte === enumQuestionario.tipoRisposte.rating)  || (domanda.tipoRisposte === enumQuestionario.tipoRisposte.dicotomicaOrizzontale) ;

        domanda.risposte.forEach(risposta => {
            let input;
            let listaIdRisposteDomanda = this.getListaIdRisposteDomanda(domanda.id);
            let isChecked = listaIdRisposteDomanda.includes(risposta.id);

            let gruppoSelect = risposta.gruppoSelect;
            let isRispostaSelect = !Utils.isStringEmptyOrNullOrUndefined(gruppoSelect);
            if(isRispostaSelect && gruppiSelect.includes(gruppoSelect)){
                return;
            }
            let tipoInput = null;
            if(risposta.hasOwnProperty("tipoInput")){
                tipoInput = risposta.tipoInput
            } else if(isRispostaSelect){
                tipoInput = enumQuestionario.tipoInput.select
            }
            switch(true) {
                case ((domanda.tipoRisposte === enumQuestionario.tipoRisposte.radiobutton || domanda.tipoRisposte === enumQuestionario.tipoRisposte.rating || domanda.tipoRisposte === enumQuestionario.tipoRisposte.dicotomicaOrizzontale) && tipoInput === null) :
                    input = (
                        <RadioButton
                            id={domanda.id + "r" + risposta.id + this.props.tipoQuestionario}
                            group={domanda.id + this.props.tipoQuestionario }
                            items={[{value: risposta.id, label: ""}]}
                            onChange={() => {
                                this.handleChangeDatiQuestionario(domanda.id, risposta.id, false,
                                    risposta.valore, false, domanda?.linkId ?? null,
                                    risposta?.system ?? null, risposta?.code ?? null,
                                    domanda?.tipoRisposta ?? null, risposta?.display ?? null,
                                    domanda?.label || null);
                            }}
                            defaultChoice={isChecked ? 0 : -1}
                            customFormCheckClassName={"form-check mt-3"}
                            showLabel={false}
                            disabled={this.props.disabilitato ? this.props.disabilitato : disabilitaDomanda}
                        />
                    );
                    break;
                case (domanda.tipoRisposte === enumQuestionario.tipoRisposte.radiobutton && tipoInput === enumQuestionario.tipoInput.select) : {
                    // aggiorno la lista dei gruppi select trovati
                    gruppiSelect.push(gruppoSelect);
                    // ragruppa tutte le domande per quel gruppo presenti nelle array domande
                    let risposteGruppoSelect = _.filter(domanda.risposte, rispostaArray => rispostaArray.gruppoSelect === gruppoSelect);
                    let opzioni = _.map(risposteGruppoSelect, rispostaGruppoSelect => {
                        return {value: rispostaGruppoSelect.id, label: rispostaGruppoSelect.label}
                    });
                    let valoreSelect = listaIdRisposteDomanda[0];
                    let indexSelezione = valoreSelect ? _.findIndex(opzioni, opzione => opzione.value === valoreSelect) : -1;
                    let valoreOption = indexSelezione > -1 ? opzioni[indexSelezione] : null;
                    input = (
                        <Select
                            id={domanda.id + "r" + risposta.id + this.props.tipoQuestionario}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            options={opzioni}
                            value={valoreOption}
                            onChange={(elem) => {
                                this.handleChangeDatiQuestionario(domanda.id,
                                    elem.value, false,
                                    this.getValoreRisposta(this.getDomandaQuestionario(domanda.id), elem.value),
                                    false, domanda?.linkId ?? null, risposta?.system ?? null,
                                    risposta?.code ?? null, domanda?.tipoRisposta ?? null,
                                    risposta?.display ?? null, domanda?.label || null);
                            }}
                            isSearchable={false}
                            styles={inputSelectStyles}
                            placeholder={"-"}
                            isDisabled={this.props.disabilitato ? this.props.disabilitato : disabilitaDomanda}
                            noOptionsMessage={() => ""}
                        />
                    )
                }
                    break;
                case (domanda.tipoRisposte === enumQuestionario.tipoRisposte.radiobutton && tipoInput === enumQuestionario.tipoInput.text_number):
                    input = (
                        <Input
                            parentClass={"mb-0"}
                            value={isChecked ? risposta.valore : ""}
                            placeholder="-"
                            field={risposta.label}
                            onChange={(field, elem) => {
                                const valNum = parseInt(elem.target.value, 10);
                                let valoreRisposta = (risposta.maxInputValue != null && valNum > risposta.maxInputValue) ? risposta.maxInputValue.toString() : elem.target.value;
                                this.handleChangeDatiQuestionario(domanda.id, risposta.id, false,
                                    valoreRisposta, true, domanda?.linkId ?? null,
                                    risposta?.system ?? null, risposta?.code ?? null,
                                    domanda?.tipoRisposta ?? null, risposta?.display ?? null,
                                    domanda?.label || null);
                            }}
                            id={risposta.id + this.props.tipoQuestionario}
                            fieldLabel={""}
                            type={"number"}
                            min={risposta.minInputValue}
                            max={risposta.maxInputValue}
                            disabled={this.props.disabilitato ? this.props.disabilitato : disabilitaDomanda}
                        />
                    );
                    break;
                case (domanda.tipoRisposte === enumQuestionario.tipoRisposte.checkbox && tipoInput === null):
                    input = (
                        <CheckBox
                            id={risposta.id + this.props.tipoQuestionario}
                            field={null}
                            text={""}
                            onChange={() => {
                                this.handleChangeDatiQuestionario(domanda.id, risposta.id, true,
                                    risposta.valore, false, domanda?.linkId ?? null,
                                    risposta?.system ?? null, risposta?.code ?? null,
                                    domanda?.tipoRisposta ?? null, risposta?.display ?? null,
                                    domanda?.label || null);
                            }}
                            checked={isChecked}
                            disabled={this.props.disabilitato ? this.props.disabilitato : disabilitaDomanda}
                        />
                    );
                    break;
                default:
                    input = null;
                    break;
            }

            const showPunteggio = () => (!!(risposta.valore != null && this.props.showPunteggioRisposta) ? (<div className={styles.punteggi}>{`Punteggio: ${risposta.valore}`}</div>) : null) 

            let rispostaBase = <div className={styles.risposta.concat(" row pl-2")}>
                <div className={"col-11 align-self-center"}>
                    <p className={!!(risposta.valore != null && this.props.showPunteggioRisposta) && "mb-0"}>{isRispostaSelect ? gruppoSelect : risposta.label}</p>
                    {showPunteggio()}
                </div>
                <div className={"col-1 align-self-center"}>
                    {input}
                </div>
            </div>;
            let rispostaRating = <div className={"col"}>
                <div className={"row"}>
                    <div className={"col-5 align-self-center"}>
                        {isRispostaSelect ? gruppoSelect : risposta.label}
                    </div>
                    <div className={"col-7 align-self-center"}>
                        {input}
                    </div>
                </div>
            </div>;
            risposte.push(isTipoRisposteRating ? rispostaRating : rispostaBase)
        });

        if(isTipoRisposteRating){
            // aggiungo riga a risposte
            risposte = <div className={"row pl-2 justify-content-center"}>
                    {risposte}
                </div>
        }

        return risposte;
    }

    getListaIdRisposteDomanda = (idDomanda) => {
        let idRisposteDomanda = [];
        let sezione = _.cloneDeep(this.state.sezione);
        if (sezione && sezione.domande?.length > 0) {
            let domanda = sezione.domande.find(datiDomanda => datiDomanda.id === idDomanda) ?? null;
            if (domanda) {
                idRisposteDomanda = _.map(domanda.risposte, risposta => risposta.id);
            }
        }
        return idRisposteDomanda;
    }

    render() {
        const {isQuestionarioCompleto} = this.props;
        const {sezione, punteggio, valutazione} = this.state;

        if (this.sezioneQuestionario && this.props.isQuestionarioObbligatorio) {
            this.sezioneQuestionario.domande = _.map(this.sezioneQuestionario.domande, (domanda) => {
                if (this.props.obbligatorieta?.sectionIdListRequired?.length > 0) {
                    if (this.props.obbligatorieta?.sectionIdListRequired?.includes(this.sezioneQuestionario.id)) {
                        domanda.obbligatorio = true;
                    }
                } else if (this.props.tipoQuestionario === enumQuestionario.tipoQuestionario.PMOB && domanda.id === 3) {
                    domanda.obbligatorio = !!this.props.sezioneRichiesta.domande.find(d => d.id === 2)?.risposte?.map(r => r.id)?.some(id => id === 10);
                } else {
                    domanda.obbligatorio = true;
                }
                return domanda;
            });
        }

        return this.sezioneQuestionario ? (
            <Fragment>
                <RenderTitoloSezione
                    sezioneQuestionario={this.sezioneQuestionario}
                    forceRequiredTitleStyle={this.props.obbligatorieta?.surveyType === this.props.tipoQuestionario
                        && !!this.props.obbligatorieta?.forceRequiredCompilation
                        && !!this.sezioneQuestionario?.domande?.length
                        && !!this.sezioneQuestionario?.domande?.every(d => (d.label == null || d.label === '') && d.obbligatorio)}
                />
                {this.renderDomande()}
                <RenderPunteggio
                    sezioneQuestionario={this.sezioneQuestionario}
                    isQuestionarioCompleto={isQuestionarioCompleto}
                    areCampiObbligatoriCompilati={areCampiObbligatoriSezioneCompilati(sezione, this.sezioneQuestionario)}
                    punteggio={_.cloneDeep(punteggio)}
                    valutazione={_.cloneDeep(valutazione)}
                    sezione={sezione}
                /> 
            </Fragment>
        ) : <Fragment/>
    }
}

Questionario.propTypes = propTypes;
Questionario.defaultProps = defaultProps;
