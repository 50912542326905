import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Icon, Input} from "web-client-archetype";
import ModalHelper from "../../modale/ModalHelper";
import styles from "./PopupRichiestaDiModifica.module.css";
import RichiestaADIService from "../../../service/RichiestaADIService";
import {Link} from "react-router-dom";
import routePath from "../../../utils/route/route-path.json";
import Utils, {closeModaleStyle} from "../../../utils/Utils";
import {v4 as uuid} from "uuid";

const propTypes = {
    uuidRichiesta: PropTypes.string,
    tipologia: PropTypes.string,
    stato: PropTypes.string,
    sostituzioneRichiedente: PropTypes.bool,
    chiudiPopupRichiestaDiModifica: PropTypes.func
}

export default class PopupRichiestaDiModifica extends Component {
    state = {
        motivazione: null,
        isModificaRichiestaInoltrata: false
    }

    componentWillUnmount() {
        closeModaleStyle();
    }

    closeModale = () => {
        closeModaleStyle();
        this.props.chiudiPopupRichiestaDiModifica();
    }

    annulla = (error) => {
        this.setState({isModificaRichiestaInoltrata: false}, () => {
            if (!error) this.closeModale();
        });
    }

    confermaInoltraModifica = () => {
        const body = {
            motivazione: this.state.motivazione,
            timestampMotivazione: null
        };
        RichiestaADIService.riapriValutazione(this.props.uuidRichiesta, body).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ?? response?.status,
                        type: "critical",
                        text: response?.data?.errore ?? response?.data?.message
                    });
                    this.annulla(true);
                }
            } else {
                this.setState({isModificaRichiestaInoltrata: true});
            }
        });
    }

    renderModale = () => {
        let title;
        let body;
        let footer;

        if (this.state.isModificaRichiestaInoltrata) {
            const pathName = this.props.sostituzioneRichiedente
                ? routePath.valutazione_multidimensionale + routePath.completa_valutazione_multidimensionale
                : routePath.verifica_valutazione;
            let to = {
                pathname: pathName,
                uuidRichiesta: this.props.uuidRichiesta,
                tipologia: this.props.tipologia,
                stato: this.props.stato
            };
            title = "Operazione completata";
            body = <strong>La richiesta di modifica è stata correttamente inviata al Medico richiedente</strong>
            footer = <>
                <Link to={this.props.sostituzioneRichiedente ? to : {...to, key: uuid()}}>
                    <Button
                        onClick={() => this.annulla(false)}
                        id={"proseguiConsultazioneBtn"}
                        text={"Prosegui con la consultazione"}
                        className={'btn btn-adi btnGreenOutline'}
                    />
                </Link>
                <Link to={{pathname: routePath.home}}>
                    <Button
                        onClick={() => this.annulla(false)}
                        id={"tornaAHomepageBtn"}
                        text={"Torna a Home"}
                        className={'btn btn-adi btnWhiteOutlineBorderGreen'}
                    />
                </Link>
            </>;
        } else {
            const maxChars = 500;
            const missingChars = maxChars - (this.state.motivazione ?? '').length;
            title = <>
                <span className={"align-self-center d-table"} style={{margin: '0 auto'}}>
                    Richiedi una modifica della Valutazione Multidimensionale
                </span>
            </>;
            body = <>
                <Input
                    placeholder={"Inserisci richiesta"}
                    field={"motivazione"}
                    onChange={(field, elem) => this.setState({[field]: elem.target.value})}
                    id={"motivazioneRichiestaModificaValutazione"}
                    value={this.state.motivazione ?? ''}
                    type={"textarea"}
                    maxLength={maxChars.toString(10)}
                    rows={"4"}
                    parentClass={"mb-0 w-100"}
                    label={"Richiesta di modifica"}
                    required
                    infoText="Campo obbligatorio"
                    caratteriMax={maxChars}
                />
            </>;

            footer = <>
                <Button
                    onClick={() => this.confermaInoltraModifica()}
                    id={"inoltraModificaBtn"}
                    text={"Inoltra richiesta"}
                    className={"btn btn-adi btnGreenOutline"}
                    disabled={!this.state.motivazione}
                />
            </>;
        }

        return (
            <>
                <ModalHelper
                    id={this.state.isModificaRichiestaInoltrata ? "modaleRichiestaModificaOperazioneEseguita" : "modaleRichiestaModifica"}
                    title={title}
                    titleClassName={['font-weight-bold', 'modal-header d-flex justify-content-center text-center', this.state.isModificaRichiestaInoltrata ? "backgroundGreen" : ''].join(' ')}
                    containerClassName={'modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered'}
                    contentClassName={'modal-content'}
                    body={body}
                    footer={footer}
                    footerClassName={'modal-footer justify-content-around'}
                    bodyClassName={('modal-body d-flex align-items-center').concat(this.state.isModificaRichiestaInoltrata ? " text-center mx-auto": "")}
                    closePopupFunction={this.state.isModificaRichiestaInoltrata ? null : () => this.annulla(false)}
                />
            </>
        );
    }

    render() {
        return <>{this.renderModale()}</>;
    }
}

PopupRichiestaDiModifica.propTypes = propTypes;
