import React, { Component } from "react";
import PropTypes from 'prop-types';
import Table from "../../generalComponents/table/Table"
import { Button } from "web-client-archetype";
import RichiestaADIService from "../../../service/RichiestaADIService";
import _ from 'lodash';
import { mostraSpinner, nascondiSpinner } from "../../../App";
import AuthUtils from "../../../utils/AuthUtils";
import Utils from "../../../utils/Utils";
import { Context } from "../../../utils/Context";
import HomeUtils from "../HomeUtils";
import FiltriUtils from "../../../utils/FiltriUtils";
import {map} from "rxjs/operators";
import InfoTable from "./InfoTable";
import {forkJoin} from "rxjs";
import style from "./TabellaRichieste.module.css";


const defaultSize = 15;
const defaultPage = 1;

const cols = [
    { key: "cognomeNome", name: "Assistito", sortDirection: "none" },
    { key: "iconaOspedale", name: "", tipo: "icon" },
    { key: "dataRichiesta", name: "Data Richiesta", sortDirection: "none" },
    { key: "livelloPriorita", name: "Priorità", sortDirection: "none" },
    { key: "medicoRichiedente", name: "Medico Richiedente", sortDirection: "none" },
    { key: "valutazioneProfessionale", name: "La tua valutazione", sortDirection: "none", tipo: "pallino", home: true },
    { key: "valutazioneMultid", name: "Valutazione multid.", sortDirection: "none", tipo: "pallino", home: true },
    { key: "formalizzazione", name: 'Formalizzazione <br>/ Chiusura', sortDirection: "none", tipo: "pallino", home: true },
    { key: "primiLink", name: "", tipo: "link", path: "", nomeParametro: "" },
    { key: "secondiLink", name: "", tipo: "link", path: "", nomeParametro: "" },
];

const getColsValutatore = () => {
    let colsValutatore = _.cloneDeep(cols);
    colsValutatore.splice(10, 1);
    return colsValutatore;
};

const getColsFormalizzatoreDdd = () => {
    let colsFormalizzatoreDdd = _.cloneDeep(cols);
    colsFormalizzatoreDdd.splice(6, 1, {key: "inCaricoA", name: "In carico a", sortDirection: "none"});
    colsFormalizzatoreDdd.splice(7, 0, {key: "teamValutazione", name: "Team", tipo: "icon", home: true});
    return colsFormalizzatoreDdd;
}

const getColsFormalizzatoreMcp = () => {
    let colsFormalizzatore = _.cloneDeep(cols);
    colsFormalizzatore.splice(6, 0, { key: "inCaricoA", name: "In carico a", sortDirection: "none" });
    colsFormalizzatore.splice(7, 0, { key: "teamValutazione", name: "Team", tipo: "icon", home: true });
    return colsFormalizzatore;
}

const getColsCoordinatore = () => {
    let colsCoordinatore = _.cloneDeep(cols);
    colsCoordinatore.splice(10, 1);
    colsCoordinatore.splice(6, 0, { key: "inCaricoA", name: "In carico a", sortDirection: "none" });
    colsCoordinatore.splice(7, 0, { key: "teamValutazione", name: "Team", tipo: "icon", home: true });
    return colsCoordinatore;
}

const getColsDirettoreUOeReferenteAssistentiSociali = () => {
    let colsDirettoreUO = _.cloneDeep(cols);
    colsDirettoreUO.splice(10, 1);
    return colsDirettoreUO;
}

const getColsMedicoOspedaliero = () => {
    let colsMO = _.cloneDeep(cols);
    colsMO.splice(6, 1);
    return colsMO;
}

const getColsCentrale = () => {
    let colsCentrale = _.cloneDeep(cols);
    colsCentrale.splice(6, 0, { key: "inCaricoA", name: "In carico a", sortDirection: "none" });
    colsCentrale.splice(7, 0, { key: "teamValutazione", name: "Team", tipo: "icon", home: true });
    return colsCentrale;
}

const defaultState = {
    rows: [],
    totalElements: 0,
    richieste: [],
    loading: false,
    size: defaultSize,
    page: defaultPage,
    sort: "",
    direction: "",
    countRichiesteDaFormalizzare: 0,
    cols: _.cloneDeep(cols),
    colsValutatore: _.cloneDeep(getColsValutatore()),
    colsFormalizzatoreMcp: _.cloneDeep(getColsFormalizzatoreMcp()),
    colsFormalizzatoreDdd: _.cloneDeep(getColsFormalizzatoreDdd()),
    colsCoordinatore: _.cloneDeep(getColsCoordinatore()),
    colsDirettoreUO: _.cloneDeep(getColsDirettoreUOeReferenteAssistentiSociali()),
    colsReferenteAssistentiSociali: _.cloneDeep(getColsDirettoreUOeReferenteAssistentiSociali()),
    colsMO: _.cloneDeep(getColsMedicoOspedaliero()),
    colsCentrale: _.cloneDeep(getColsCentrale())
};

const propTypes = {
    impostaRichieste: PropTypes.func,
    filtri: PropTypes.object,
    apriAnnullamentoRichiesta: PropTypes.func,
    apriModificaDataDimissione: PropTypes.func,
    apriSubentroRichiesta: PropTypes.func,
    mostraSubentroRichiesta: PropTypes.bool,
    apriModalePresaInCaricoRichiesta: PropTypes.func,
    mostraModalePresaInCaricoRichiesta: PropTypes.bool,
    filtriRicercaRapidi: PropTypes.object,
    updatedLivelloPriorita: PropTypes.string,
    updatedStatoRichiesta: PropTypes.string,
    info: PropTypes.string,
}

export default class TabellaRichieste extends Component {

    state = _.cloneDeep(defaultState);

    resetState = () => {
        let defState = _.cloneDeep(defaultState);
        this.setState(defState, () => {
            this.ricercaRichieste(defaultSize, defaultPage);
        });
    }

    constructor(props) {
        super(props);
        this.props.impostaRichieste(this.state)
        this.mostraAltreRichieste = this.mostraAltreRichieste.bind(this)
    }

    componentDidMount() {
        this.ricercaRichieste(this.state.size, this.state.page, this.state.filtri);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.filtriRicercaRapidi, this.props.filtriRicercaRapidi)) {
            this.setState({
                size: defaultSize,
                page: defaultPage,
                totalElements: 0
            }, () => this.ricercaRichieste(this.state.size, this.state.page, this.props.filtri));
        } else {
            if (JSON.stringify(prevProps.filtri) !== JSON.stringify(this.props.filtri)) {
                this.setState({size: defaultSize, totalElements: 0}, () => {
                    this.ricercaRichieste(this.state.size, this.state.page, this.props.filtri)
                });
            }
        }

        if (this.context.clickHome) {
            this.resetState();
        }

        if ((prevProps.mostraSubentroRichiesta?.valore && !this.props.mostraSubentroRichiesta?.valore) ||
            (prevProps.mostraModalePresaInCaricoRichiesta && !this.props.mostraModalePresaInCaricoRichiesta)) {
            this.ricaricaRichieste(this.state.size, this.state.page, this.props.filtri, this.state.sort, this.state.direction);
        }
    }

    ricaricaRichieste = (size, page, filtri, sort, direction) => {
        let newSize = page * defaultSize;
        this.ricercaRichieste(newSize, defaultPage, filtri, sort, direction, false, true)
    }

    ricercaRichieste(size, page, filtri, sort, direction, caricamentoAltreRichieste, ricaricaRichieste, mostraAltri) {
        mostraSpinner();
        let richiesta;
        //Gestione default filtri richiesta
        let params = {};
        FiltriUtils.setDefaultFiltriRichiesta("homepage", params);
        params.ordinamento = FiltriUtils.getOrdinamentoHomepage();
        if (!mostraAltri && (direction !== this.state.direction || sort !== this.state.sort ||
            (Object.values(filtri?.filtriAssistito).some(value => value !== undefined && value !== null && value !== "") ||
                Object.values(filtri?.filtriRichiesta).some(value => value !== undefined && value !== null && value !== ""))))
            page = 1;

        RichiestaADIService.getRichiesteHome(size, page, filtri, sort, direction, params, this.props.filtriRicercaRapidi).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "critical",
                    text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                });
            } else {
                if (caricamentoAltreRichieste) {
                    richiesta = _.cloneDeep(this.state?.richieste)
                    richiesta = richiesta.concat(response.data.elementi);

                }
                else {
                    richiesta = _.cloneDeep(response.data.elementi)
                }
                this.setState({
                    sort: sort,
                    direction: direction,
                    rows: HomeUtils.mapGetRichiesteHome(richiesta),
                    loading: false,
                    countRichiesteDaFormalizzare: response.data.richiesteDaFormalizzare,
                    totalElements: response.data.elementiTotali,
                    size: ricaricaRichieste ? this.state.size : size,
                    page: ricaricaRichieste ? this.state.page : page,
                    richieste: _.cloneDeep(richiesta)
                })
                this.props.impostaRichieste(this.state);
            }

            let filtriList = [];
            let obsList = [];
            if (!Utils.isStringEmptyOrNullOrUndefined(this.props.updatedLivelloPriorita)) {
                const obs = RichiestaADIService.contaFiltriRapidi(0, `livelloPriorita::${this.props.updatedLivelloPriorita}`)
                    .pipe(map(resp => {
                        if (resp) {
                            filtriList = [...filtriList, {
                                field: "priorita",
                                value: this.props.updatedLivelloPriorita,
                                count: resp.data["elementiTotali"]
                            }];
                        }
                        return resp;
                    }))
                obsList.push(obs);
            }
            if (!AuthUtils.hasUtenteRuoloMcp() && !Utils.isStringEmptyOrNullOrUndefined(this.props.updatedStatoRichiesta) && this.props.updatedStatoRichiesta === "01") {
                const obs = RichiestaADIService.contaFiltriRapidi(0, "stato::BOZZA")
                    .pipe(map(resp => {
                        if (resp) {
                            filtriList = [...filtriList, {
                                field: "stato",
                                value: this.props.updatedStatoRichiesta,
                                count: resp.data["elementiTotali"]
                            }];
                        }
                        return resp;
                    }))
                obsList.push(obs);
            }
            if (obsList.length > 0) {
                forkJoin(obsList)
                    .pipe(map((resp) => {
                        HomeUtils.changeFiltriRapidiFromFiltriRichiesteSubject.next(filtriList);
                        return resp;
                    })).subscribe(() => nascondiSpinner());
            } else {
                nascondiSpinner();
            }
        })
    }

    mostraAltreRichieste(sort, direction) {
        this.ricercaRichieste(this.state.size, this.state.page, this.props.filtri, sort, direction)
    }

    getColsTable = () => {

        var cols = [];

        if (AuthUtils.hasUtenteRuoloProfessionistaValutatore()) {
            cols = cols.concat(this.state.colsValutatore);
        }

        if (AuthUtils.hasUtenteRuoloMcp()) {
            cols = this.state.colsFormalizzatoreMcp.concat(cols);
        }

        if (AuthUtils.hasUtenteRuoloDirettoreDiDistretto()) {
            cols = cols.concat(this.state.colsFormalizzatoreDdd);
        }

        if (AuthUtils.hasUtenteRuoloCoordinatore()) {
            cols = this.state.colsCoordinatore.concat(cols);
        }

        if (AuthUtils.hasRuoloMedicoMedicinaGenerale()) {
            cols = cols.concat(this.state.cols);
        }

        if (AuthUtils.hasUtenteProfiloDirettoreUO()) {
            cols = cols.concat(this.state.colsDirettoreUO);
        }

        if (AuthUtils.hasUtenteProfiloReferenteAssistentiSociali()) {
            cols = cols.concat(this.state.colsReferenteAssistentiSociali);
        }

        if (AuthUtils.hasRuoloMedicoRichiedenteAndMedicoOspedaliero()) {
            cols = cols.concat(this.state.colsMO);
        }

        if (AuthUtils.hasUtenteCentraleAdi()) {
            cols = this.state.colsCentrale.concat(cols);
        }

        let colsSenzaDuplicati = Array.from(new Set(cols.map(a => a.key))).map(key => {
            return cols.find(a => a.key === key)
        })

        return colsSenzaDuplicati;
    }

    render() {
        let page = this.state.page;
        let filtri = this.props.filtri;
        return (
            <>
                <div className={style.container}>
                    <div className={"row"}>
                        <div className={"col-12 pl-xl-4 pl-lg-5 pl-md-0"}>
                            <InfoTable info={this.props.info}/>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <Table
                                        cols={this.getColsTable()}
                                        rows={this.state.rows}
                                        emptyMsg={"Non sono disponibili richieste che corrispondano ai filtri inseriti"}
                                        lengthEditable={false}
                                        totalElements={this.state.totalElements}
                                        bigTable={true}
                                        homePage={true}
                                        tableLenght={this.state.rows ? this.state.rows.length : 0}
                                        nascondiPaginazione={true}
                                        changePage={this.mostraAltreRichieste}
                                        apriAnnullamentoRichiesta={this.props.apriAnnullamentoRichiesta}
                                        apriModificaDataDimissione={this.props.apriModificaDataDimissione}
                                        apriSubentroRichiesta={this.props.apriSubentroRichiesta}
                                        apriModalePresaInCaricoRichiesta={this.props.apriModalePresaInCaricoRichiesta}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.rows.length < this.state.totalElements ?
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"row text-center"}>
                                    <div className={"col-12"}>
                                        <Button
                                            id={"mostraAltri"}
                                            text="Mostra altri"
                                            onClick={() => this.ricercaRichieste(defaultSize, page + defaultPage, filtri, this.state.sort, this.state.direction, true, false, true)}
                                            className="btn btn-adi btnGreenOutline"
                                        />
                                    </div>
                                </div>
                                <div className={"row text-center"}>
                                    <div className={"col-12"}>
                                        <p>{this.state.rows.length} richieste di {this.state.totalElements}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </>
        );
    }
}

TabellaRichieste.contextType = Context;
TabellaRichieste.propTypes = propTypes;
