import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {
    aicList,
    FieldsRow,
    RoleBasedAICInput,
    RoleBasedAutocomplete,
    RoleBasedCheckbox,
    RoleBasedClock,
    RoleBasedFarmacoEquivalenteInput,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../utils/dataset/OggettiUtili.json";
import prestazioniNomenclatore from "../../utils/dataset/proposteAttivitaSuggerimentiData/prestazioniNomenclatore.json";
import cvp from "../../utils/dataset/proposteAttivitaSuggerimentiData/cvp.json";
import AuthUtils from "../../utils/AuthUtils";
import Utils from "../../utils/Utils";
import {v4 as uuid} from "uuid";
import {SessioneUtente} from "web-client-archetype";
import {Col, Row} from "reactstrap";
import styles from './ProposteAttivitaSuggerimentiSection.module.css';

const propTypes = {
    pageState: PropTypes.string.isRequired,
    ambito: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    parentFieldId: PropTypes.string.isRequired,
    infParentFieldId: PropTypes.string,
    fieldsPrefix: PropTypes.string,
    fieldsSuffix: PropTypes.string,
    appendRolePrefix: PropTypes.bool,
    fieldJsonPath: PropTypes.string,

    forceReadOnly: PropTypes.bool,
    fieldInAccordionReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,

    checkboxIdSuffix: PropTypes.string,

    parentJsonPath: PropTypes.string,
    required: PropTypes.bool,

    title: PropTypes.string,
    fieldId: PropTypes.string,

    listId: PropTypes.arrayOf(PropTypes.object),

    unicheParentId: PropTypes.string,
    showLabelCampoObbligatorio:PropTypes.bool,
}

export const defaultProposteAttivitaSuggerimenti = {
    required: false,
    propostaEffettuataDa: null,
    codiceFiscale: null,
    ambito: null,
    nomenclatore: {
        checked: false,
        valori: []
    },
    cvp: {
        checked: false,
        valori: []
    },
    farmaci: {
        checked: false,
        valori: []
    },
    altro: {
        checked: false,
        valore: null
    }
};

export const defaultObject = {
    checked: false,
    valori: []
}

export const defaultAltro = {
    checked: false,
    valore: null
}

export const defaultValore = {
    id: null,
    valore: null
}

export const defaultFarmaco = {
    id: null,
    codiceAIC: null,
    classeDiEquivalenza: null,
    viaDiSomministrazione: null,
    dosaggio: null,
    modalitaDiInfusione: null,
    tipoSoluzione: null,
    altroTipoSoluzione: null,
    volumeDiDiluizione: null,
    altroVolumeDiDiluizione: null,
    posologia: null,
    terapiaCondizionata: null,
    doseMassima: null
}

export default class ProposteAttivitaSuggerimentiSection extends React.Component {

    state = {
        propostaAttivitaSuggerimenti: {
            ..._.cloneDeep(this.props.value),
            ambito: this.props.ambito
        },
        propostaEffettuataDa: AuthUtils.getInfoUtente()
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.value, this.props.value)) {
            this.inizializzaForm();
        }
    }

    inizializzaForm = () => {
        const propostaAttivitaSuggerimenti = {
            ..._.cloneDeep(defaultProposteAttivitaSuggerimenti),
            ..._.cloneDeep(this.props.value ?? {})
        };
        propostaAttivitaSuggerimenti.ambito = this.props.ambito;
        propostaAttivitaSuggerimenti.propostaEffettuataDa = this.state.propostaEffettuataDa;
        propostaAttivitaSuggerimenti.codiceFiscale = SessioneUtente.getInstance().idUtente;

        this.setState({propostaAttivitaSuggerimenti});
    }

    checkPropostaAttivitaSuggerimento = (nomeSottoOggetto, field, checked) => {
        let propostaAttivitaSuggerimenti = _.cloneDeep(this.state.propostaAttivitaSuggerimenti);
        let object = propostaAttivitaSuggerimenti[nomeSottoOggetto];
        object[field] = checked;

        if (!checked) {
            if (Object.prototype.hasOwnProperty.call(object, "valori") && object.valori.length > 0) object.valori = []
            else if (Object.prototype.hasOwnProperty.call(object, "valore") && object.valore) object.valore = null;
        }

        propostaAttivitaSuggerimenti[nomeSottoOggetto] = object;
        this.setState({propostaAttivitaSuggerimenti},
            () => this.props.onChange("propostaAttivitaSuggerimenti", _.cloneDeep(this.state.propostaAttivitaSuggerimenti)));
    }

    addNewProposta = (objectName) => {
        let propostaAttivitaSuggerimenti = _.cloneDeep(this.state.propostaAttivitaSuggerimenti);
        const defaultValue = objectName === "farmaci" ? defaultFarmaco : defaultValore;
        const defaultProposta = {..._.cloneDeep(defaultValue), id: uuid()};
        if (propostaAttivitaSuggerimenti[objectName]?.valori == null)
            propostaAttivitaSuggerimenti[objectName].valori = [defaultProposta];
        else
            propostaAttivitaSuggerimenti[objectName].valori.push(defaultProposta);

        this.setState({propostaAttivitaSuggerimenti},
            () => this.props.onChange("propostaAttivitaSuggerimenti", _.cloneDeep(this.state.propostaAttivitaSuggerimenti)));
    }

    removeProposta = (index, objectName) => {
        let propostaAttivitaSuggerimenti = _.cloneDeep(this.state.propostaAttivitaSuggerimenti);
        propostaAttivitaSuggerimenti[objectName].valori.splice(index, 1);
        this.setState({propostaAttivitaSuggerimenti},
            () => this.props.onChange("propostaAttivitaSuggerimenti", _.cloneDeep(this.state.propostaAttivitaSuggerimenti)));
    }

    handleAggiornaFarmaco = (field, elem, name, index) => {
        let propostaAttivitaSuggerimenti = _.cloneDeep(this.state.propostaAttivitaSuggerimenti);
        let currentFarmaco = propostaAttivitaSuggerimenti[name].valori[index];

        if ((field === "codiceAIC" || field === "classeDiEquivalenza") && currentFarmaco[field] != null && !_.isEqual(elem, currentFarmaco[field])) {
            Object.keys(currentFarmaco).forEach(key => {
                if (!["id", "codiceAIC", "classeDiEquivalenza"].includes(key)) currentFarmaco[key] = null;
            });
        } else if (field === "modalitaDiInfusione" && !_.isEqual(elem, currentFarmaco[field])) {
            currentFarmaco.tipoSoluzione = null;
            currentFarmaco.altroTipoSoluzione = null;
            currentFarmaco.volumeDiDiluizione = null;
            currentFarmaco.altroVolumeDiDiluizione = null;
        } else if (field === "tipoSoluzione" && !_.isEqual(elem, currentFarmaco[field])) {
            currentFarmaco.altroTipoSoluzione = null;
        } else if (field === "volumeDiDiluizione" && !_.isEqual(elem, currentFarmaco[field])) {
            currentFarmaco.altroVolumeDiDiluizione = null;
        }
        currentFarmaco[field] = elem;
        propostaAttivitaSuggerimenti[name].valori[index] = currentFarmaco;

        this.setState({propostaAttivitaSuggerimenti},
            () => this.props.onChange("propostaAttivitaSuggerimenti", _.cloneDeep(this.state.propostaAttivitaSuggerimenti)));
    }

    updateClock(field, name, index, hh, mm) {
        if (hh && mm) {
            this.handleAggiornaFarmaco(field, (hh + ':' + mm), name, index);
        } else if(!hh && mm) {
            hh = "00"
            this.handleAggiornaFarmaco(field, (hh + ':' + mm), name, index);
        } else if(hh && !mm){
            mm = "00"
            this.handleAggiornaFarmaco(field, (hh + ':' + mm), name, index);
        }
        else {
            this.handleAggiornaFarmaco(field, null, name, index);
        }
    }

    renderCheckbox = (name, id, text, checked) => {
        const obj = (this.state.propostaAttivitaSuggerimenti ?? defaultProposteAttivitaSuggerimenti)[name];
        const argument = name.charAt(0).toUpperCase() + name.slice(1);

        const mRolePrefix = this.props.appendRolePrefix ? 'M_' : '';
        const iRolePrefix = this.props.appendRolePrefix ? 'I_' : null;
        const fieldsPrefix = this.props.fieldsPrefix ? this.props.fieldsPrefix + '_' : '';
        const fieldsSuffix = this.props.fieldsSuffix ? '_' + this.props.fieldsSuffix : '';
        const fieldId = mRolePrefix + fieldsPrefix + 'PROP_ATT_SUGG' + fieldsSuffix + '.' + this.props.parentFieldId;
        const infermiereFieldId = iRolePrefix ? (iRolePrefix + fieldsPrefix + 'PROP_ATT_SUGG' + fieldsSuffix + '.' + this.props.infParentFieldId) : null;

        return (
            <Row>
                <Col md={4} sm={12} className={!obj?.valori?.length ? "align-self-center py-3" : "py-3"}>
                    <RoleBasedCheckbox
                        fieldId={fieldId}
                        infermiereFieldId={infermiereFieldId}
                        id={fieldsPrefix + '_' + id + '_' + fieldsSuffix + '_' + this.props.parentFieldId + (this.props.checkboxIdSuffix ?? '') + (this.props.unicheParentId ?? '')}
                        pageState={this.props.pageState}
                        field={"checked"}
                        text={text}
                        styleLabel={{fontFamily: "Arial", fontSize: "14px"}}
                        checked={checked}
                        onChange={(field, elem) => this.checkPropostaAttivitaSuggerimento(name, field, elem.target?.checked)}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        parentJsonPathSubObject={`${this.props.fieldJsonPath}.${name}`}
                        parentJsonPath={this.props.parentJsonPath}
                        listId={this.props.listId}
                        showAddValueAndTrashButton={false}
                        fieldRequired={true}
                    />
                </Col>
                {checked &&
                    <>
                        <Col md={name === "farmaci" ? 8 : 4} sm={12}>
                            {name === "nomenclatore" || name === "cvp" || name === "farmaci"
                                ? <SectionList
                                    title={name === "nomenclatore" || name === "cvp" ? 'Prestazione' : 'Farmaco'}
                                    data={obj.valori}
                                    renderSection={(e, i) => (name === "nomenclatore" || name === "cvp" ? this.renderProposte : this.renderFarmaco)(e, i, name, fieldId, infermiereFieldId, argument)}
                                    addNewSectionCallback={() => this.addNewProposta(name)}
                                    removeSectionCallback={(i) => this.removeProposta(i, name)}
                                    addButtonVisibilityHandler={!this.props.forceReadOnly
                                        && (!obj.valori?.length || obj.valori?.every(el => Object.prototype.hasOwnProperty.call(el, "valore") ? !Utils.isObjectNull(el?.valore) : Object.keys(el).some(key => key !== "id" && el[key])))}
                                    canRemoveData={!this.props.forceReadOnly}
                                    pageState={this.props.pageState}
                                    indentOffset={0}
                                    field={`${this.props.fieldJsonPath}.${name}.valori`}
                                    listId={this.props.listId}
                                    keyFieldId={Utils.isRuoloInfermiere() && infermiereFieldId ? infermiereFieldId : fieldId}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    required={true}
                                />
                                : <RoleBasedInput
                                    fieldId={fieldId}
                                    infermiereFieldId={infermiereFieldId}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci proposta"}
                                    field={"valore"}
                                    value={obj.valore}
                                    onChange={(field, elem) => {
                                        let propostaAttivitaSuggerimenti = _.cloneDeep(this.state.propostaAttivitaSuggerimenti);
                                        propostaAttivitaSuggerimenti[name][field] = elem.target.value;
                                        this.setState({propostaAttivitaSuggerimenti},
                                            () => this.props.onChange("propostaAttivitaSuggerimenti", _.cloneDeep(this.state.propostaAttivitaSuggerimenti)));
                                    }}
                                    id={"input" + argument}
                                    fieldLabel={name === "altro" ? 'Proposta di attività' : 'Descrizione'}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    parentJsonPathSubObject={`${this.props.fieldJsonPath}.${name}`}
                                    parentJsonPath={this.props.parentJsonPath}
                                    listId={this.props.listId}
                                    fieldRequired={true}
                                />}
                        </Col>
                    </>
                }
            </Row>
        );
    }

    renderProposte = (item, index, name, fieldId, infermiereFieldId, argument) => {
        return name === "nomenclatore" || name === "cvp"
            ? <RoleBasedAutocomplete
                fieldId={fieldId}
                infermiereFieldId={infermiereFieldId}
                pageState={this.props.pageState}
                id={"autocomplete" + argument}
                keyField={"codice"}
                descriptionField={"descrizione"}
                onChange={(field, elem) => {
                    let propostaAttivitaSuggerimenti = _.cloneDeep(this.state.propostaAttivitaSuggerimenti);
                    propostaAttivitaSuggerimenti[name].valori[index][field] = elem.target.value;
                    this.setState({propostaAttivitaSuggerimenti},
                        () => this.props.onChange("propostaAttivitaSuggerimenti", _.cloneDeep(this.state.propostaAttivitaSuggerimenti)));
                }}
                searchFromStart={true}
                value={item.valore}
                field={"valore"}
                placeholder={"Inserisci descrizione " + name}
                suggestions={name === "nomenclatore" ? prestazioniNomenclatore : cvp}
                noSuggestions={"Errore: " + argument + " non presente"}
                fieldLabel={'Descrizione'}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                disableDoubleInput={this.props.disableDoubleInput}
                handleOnlyValue={true}
                parentJsonPath={this.props.parentJsonPath}
                listId={this.props.listId}
                fieldRequired={true}
            />
            : <RoleBasedInput
                fieldId={fieldId}
                infermiereFieldId={infermiereFieldId}
                pageState={this.props.pageState}
                placeholder={"Inserisci proposta"}
                field={"valore"}
                value={item.valore}
                onChange={(field, elem) => {
                    let propostaAttivitaSuggerimenti = _.cloneDeep(this.state.propostaAttivitaSuggerimenti);
                    propostaAttivitaSuggerimenti[name].valori[index][field] = elem.target.value;
                    this.setState({propostaAttivitaSuggerimenti},
                        () => this.props.onChange("propostaAttivitaSuggerimenti", _.cloneDeep(this.state.propostaAttivitaSuggerimenti)));
                }}
                id={"input" + argument}
                fieldLabel={name === "altro" ? 'Proposta di attività' : 'Descrizione'}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                disableDoubleInput={this.props.disableDoubleInput}
                parentJsonPath={this.props.parentJsonPath}
                listId={this.props.listId}
                fieldRequired={true}
            />;
    }

    renderFarmaco = (obj, index, name, fieldId, infermiereFieldId, argument) => {
        const currentListaFarmaci = (this.state.propostaAttivitaSuggerimenti ?? defaultProposteAttivitaSuggerimenti).farmaci.valori;
        const optionsAIC = _.cloneDeep(aicList).filter(f => !currentListaFarmaci.find(d => obj.codiceAIC !== d.codiceAIC && d.codiceAIC === f.value));

        const condForRenderInput = obj.modalitaDiInfusione === "02" || obj.tipoSoluzione === "09" || obj.modalitaDiInfusione === "02" || obj.volumeDiDiluizione === "05";
        return (
            <>
                <FieldsRow colNumber={2}>
                    <RoleBasedAICInput
                        id={"codiceAIC" + argument}
                        infermiereFieldId={infermiereFieldId}
                        fieldId={fieldId}
                        field={"codiceAIC"}
                        fieldLabel={'Codice AIC'}
                        pageState={this.props.pageState}
                        onChange={(field, elem) => this.handleAggiornaFarmaco(field, elem.target.value, name, index)}
                        value={obj.codiceAIC}
                        suggestions={optionsAIC}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        classeEquivalenza={obj.classeDiEquivalenza}
                        parentJsonPath={this.props.parentJsonPath}
                        listId={this.props.listId}
                        fieldRequired={obj.classeDiEquivalenza ? false : true}
                        showFieldIfRequiredAccordionPage={!Utils.isObjectNull(obj.classeDiEquivalenza)}
                    />
                    <RoleBasedFarmacoEquivalenteInput
                        id={"classeDiEquivalenza" + argument}
                        infermiereFieldId={infermiereFieldId}
                        fieldId={fieldId}
                        field={"classeDiEquivalenza"}
                        fieldLabel={'Classe di equivalenza'}
                        pageState={this.props.pageState}
                        onChange={(field, elem) => this.handleAggiornaFarmaco(field, elem.target.value, name, index)}
                        value={obj.classeDiEquivalenza}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        codiceAIC={obj.codiceAIC}
                        parentJsonPath={this.props.parentJsonPath}
                        listId={this.props.listId}
                        fieldRequired={obj.codiceAIC ? false: true}
                        showFieldIfRequiredAccordionPage={!Utils.isObjectNull(obj.codiceAIC)}
                    />
                </FieldsRow>
                {(!Utils.isStringEmptyOrNullOrUndefined(obj.classeDiEquivalenza) || !Utils.isStringEmptyOrNullOrUndefined(obj.codiceAIC)) &&
                    <>
                        <FieldsRow colNumber={3}>
                            <RoleBasedSelect
                                fieldId={fieldId}
                                infermiereFieldId={infermiereFieldId}
                                pageState={this.props.pageState}
                                options={OggettiUtili.viaDiSomministrazione}
                                value={obj.viaDiSomministrazione}
                                onChange={(elem) => this.handleAggiornaFarmaco("viaDiSomministrazione", elem, name, index)}
                                isSearchable={false}
                                fieldLabel={'Via di somministrazione'}
                                id={"viaDiSomministrazione" + argument}
                                noOptionsMessage={() => "Errore: Via di somministrazione non esistente"}
                                handleOnlyValue={true}
                                field={"viaDiSomministrazione"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                                parentJsonPath={this.props.parentJsonPath}
                                listId={this.props.listId}
                            />
                            <RoleBasedInput
                                fieldId={fieldId}
                                infermiereFieldId={infermiereFieldId}
                                pageState={this.props.pageState}
                                value={obj.dosaggio ?? ""}
                                placeholder={"Inserisci dosaggio"}
                                field={"dosaggio"}
                                onChange={(field, elem) => this.handleAggiornaFarmaco(field, elem.target.value, name, index)}
                                type={"number"}
                                min={0}
                                fieldLabel={'Dosaggio'}
                                id={"dosaggio" + argument}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                parentJsonPath={this.props.parentJsonPath}
                                disableDoubleInput={this.props.disableDoubleInput}
                                listId={this.props.listId}
                            />
                            <RoleBasedSelect
                                fieldId={fieldId}
                                infermiereFieldId={infermiereFieldId}
                                pageState={this.props.pageState}
                                options={OggettiUtili.modalitaDiInfusione}
                                value={obj.modalitaDiInfusione}
                                onChange={(elem) => this.handleAggiornaFarmaco("modalitaDiInfusione", elem, name, index)}
                                isSearchable={false}
                                fieldLabel={'Modalità di infusione'}
                                noOptionsMessage={() => "Errore: Modalita di infusione non esistente"}
                                handleOnlyValue={true}
                                field={"modalitaDiInfusione"}
                                id={"modalitaDiInfusione" + argument}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                parentJsonPath={this.props.parentJsonPath}
                                disableDoubleInput={this.props.disableDoubleInput}
                                listId={this.props.listId}
                            />
                        </FieldsRow>

                        {condForRenderInput && (
                            <FieldsRow colNumber={4}>
                                {obj.modalitaDiInfusione === "02" &&
                                    <RoleBasedSelect
                                        fieldId={fieldId}
                                        infermiereFieldId={infermiereFieldId}
                                        pageState={this.props.pageState}
                                        options={OggettiUtili.tipoSoluzione}
                                        value={obj.tipoSoluzione}
                                        onChange={(elem) => this.handleAggiornaFarmaco("tipoSoluzione", elem, name, index)}
                                        isSearchable={false}
                                        noOptionsMessage={() => "Errore: Tipo soluzione non esistente"}
                                        fieldLabel={'Tipo di soluzione'}
                                        handleOnlyValue={true}
                                        field={"tipoSoluzione"}
                                        forceReadOnly={this.props.forceReadOnly}
                                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                                        hideUnsetValues={this.props.hideUnsetValues}
                                        parentJsonPath={this.props.parentJsonPath}
                                        disableDoubleInput={this.props.disableDoubleInput}
                                        listId={this.props.listId}
                                    />}
                                {obj.tipoSoluzione === "09" &&
                                    <RoleBasedInput
                                        fieldId={fieldId}
                                        infermiereFieldId={infermiereFieldId}
                                        pageState={this.props.pageState}
                                        value={obj.altroTipoSoluzione ?? ""}
                                        placeholder={"Inserisci altro tipo di soluzione"}
                                        field={"altroTipoSoluzione"}
                                        onChange={(field, elem) => this.handleAggiornaFarmaco(field, elem.target.value, name, index)}
                                        fieldLabel={'Altro tipo di soluzione'}
                                        id={"altroTipoSoluzione" + argument}
                                        forceReadOnly={this.props.forceReadOnly}
                                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                                        hideUnsetValues={this.props.hideUnsetValues}
                                        parentJsonPath={this.props.parentJsonPath}
                                        disableDoubleInput={this.props.disableDoubleInput}
                                        listId={this.props.listId}
                                    />}
                                {obj.modalitaDiInfusione === "02" &&
                                    <RoleBasedSelect
                                        fieldId={fieldId}
                                        infermiereFieldId={infermiereFieldId}
                                        pageState={this.props.pageState}
                                        options={OggettiUtili.volumeDiDiluizione}
                                        value={obj.volumeDiDiluizione}
                                        onChange={(elem) => this.handleAggiornaFarmaco("volumeDiDiluizione", elem, name, index)}
                                        isSearchable={false}
                                        noOptionsMessage={() => "Errore: Volume di diluizione non esistente"}
                                        fieldLabel={'Volume di diluizione'}
                                        handleOnlyValue={true}
                                        field={"volumeDiDiluizione"}
                                        forceReadOnly={this.props.forceReadOnly}
                                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                                        hideUnsetValues={this.props.hideUnsetValues}
                                        parentJsonPath={this.props.parentJsonPath}
                                        disableDoubleInput={this.props.disableDoubleInput}
                                        listId={this.props.listId}
                                    />}
                                {obj.volumeDiDiluizione === "05" &&
                                    <RoleBasedInput
                                        fieldId={fieldId}
                                        infermiereFieldId={infermiereFieldId}
                                        pageState={this.props.pageState}
                                        value={obj.altroVolumeDiDiluizione ?? ""}
                                        placeholder={"Inserisci altro volume di diluizione"}
                                        field={"altroVolumeDiDiluizione"}
                                        onChange={(field, elem) => this.handleAggiornaFarmaco(field, elem.target.value, name, index)}
                                        fieldLabel={'Altro volume di diluizione (mL)'}
                                        id={"altroVolumeDiDiluizione" + argument}
                                        type={"number"}
                                        min={0}
                                        forceReadOnly={this.props.forceReadOnly}
                                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                                        hideUnsetValues={this.props.hideUnsetValues}
                                        parentJsonPath={this.props.parentJsonPath}
                                        disableDoubleInput={this.props.disableDoubleInput}
                                        listId={this.props.listId}
                                    />}
                            </FieldsRow>
                        )}
                        <FieldsRow colNumber={3}>
                            <RoleBasedClock
                                fieldId={fieldId}
                                infermiereFieldId={infermiereFieldId}
                                pageState={this.props.pageState}
                                id={"inputPosologia"}
                                fieldLabel={'Posologia'}
                                value={obj.posologia}
                                onChange={(hh, mm) => this.updateClock("posologia", name, index, hh, mm)}
                                field={'posologia'}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                                parentJsonPath={this.props.parentJsonPath}
                                listId={this.props.listId}
                            />
                            <RoleBasedInput
                                fieldId={fieldId}
                                infermiereFieldId={infermiereFieldId}
                                pageState={this.props.pageState}
                                value={obj.terapiaCondizionata ?? ""}
                                placeholder={"Inserisci terapia condizionata"}
                                field={"terapiaCondizionata"}
                                onChange={(field, elem) => this.handleAggiornaFarmaco(field, elem.target.value, name, index)}
                                fieldLabel={'Terapia condizionata'}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                parentJsonPath={this.props.parentJsonPath}
                                disableDoubleInput={this.props.disableDoubleInput}
                                listId={this.props.listId}
                            />
                            <RoleBasedInput
                                fieldId={fieldId}
                                infermiereFieldId={infermiereFieldId}
                                pageState={this.props.pageState}
                                value={obj.doseMassima ?? ""}
                                placeholder={"Inserisci dosaggio"}
                                field={"doseMassima"}
                                onChange={(field, elem) => this.handleAggiornaFarmaco(field, elem.target.value, name, index)}
                                fieldLabel={'Dose massima'}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                parentJsonPath={this.props.parentJsonPath}
                                disableDoubleInput={this.props.disableDoubleInput}
                                listId={this.props.listId}
                            />
                        </FieldsRow>
                    </>}
            </>
        );
    }

    render() {
        const checkboxValues = this.state.propostaAttivitaSuggerimenti ?? defaultProposteAttivitaSuggerimenti;

        return (
            <>

                <div className={this.props.required && !this.props.forceReadOnly ? ' required' : ''}><strong>{this.props.title}</strong></div>
                {this.props.showLabelCampoObbligatorio && (<div className={!this.props.forceReadOnly ? styles.inputMissingValue.concat(' required') : ''}>Campo Obbligatorio</div>)}

 

                {this.renderCheckbox("nomenclatore", "checkboxNomenclatore", "Nomenclatore Unico Regionale delle prestazioni nel setting domiciliare", checkboxValues.nomenclatore?.checked)}
                {this.renderCheckbox("cvp", "checkboxCVP", "Catalogo Veneto del Prescrivibile", checkboxValues.cvp?.checked)}
                {this.renderCheckbox("farmaci", "checkboxFarmaci", "Farmaci", checkboxValues.farmaci?.checked)}
                {this.renderCheckbox("altro", "checkboxAltro", "Altro, Specificare", checkboxValues.altro?.checked)}
            </>
        );
    }
}

ProposteAttivitaSuggerimentiSection.propTypes = propTypes;

ProposteAttivitaSuggerimentiSection.defaultProps = {
    appendRolePrefix: false,
    forceReadOnly: false,
    fieldInAccordionReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false,
    title: "Proposte di attività e suggerimenti",
    fieldJsonPath: 'propostaattivitasuggerimenti'
}
