import React from "react";
import styles from './Popover.module.css';
import Popover, {ArrowContainer} from 'react-tiny-popover'
import PropTypes from "prop-types";

/*
    Props da inserire:
     - position: posizione che assumera' il popover. Questa props può essere valorizzata con 'top', 'bottom', 'left', 'right'
                 e se si desidera si possono fornire una serie di posizioni classificate in ordine di prorità, ad esempio
                 ['top', 'right', 'left', 'bottom']. Di default è valorizzato a 'top'.
     - padding: distanza tra il popover e il testo su cui apparirà il popover. Di default è valorizzato a 6.
     - align: allineamento del popover rispetto al testo. Questa props può assumere i valori 'start', 'center', 'end'. Di
              default è valorizzata a 'center'.
     - containerClassName: nome della classe del css che si vuole applicare al contenuto del popover
     - Nella props obbligatoria content del componente Popover è possibile renderizzare il componente ArrowContainer, sempre presente
       nella libreria di react-tiny-popover che presenta come props:
       Obbligatori:
        - position: posizione che può assumere la freccia. É valorizzata come indicato dalla props position del componente Popover
        - targetRect, popoverRect: consente di conoscere il rettangolo di delimitazione del popover per posizionare
                                   correttamente la freccia. Questo oggetto è di tipo {width: number, height: number, top: number, left: number, right: number, bottom: number}
        Opzionali:
        - arrowColor: colore della freccia
        - arrowSize: dimensione della freccia. Di default è valorizzata a 10
        - arrowStyle: classe css da passare per modificare lo stile della freccia


       body: contenuto del popover
       text: testo dello span che verrà mostrato e su cui si vorrà far apparire il popover
       id: id del popover
 */

export default class PopoverReact extends React.Component {

    state = {
        isPopoverOpen: false
    };

    showArrowContainer = (position, targetRect, popoverRect) => {
        if (this.state.isPopoverOpen) {
            return <ArrowContainer
                position={position}
                targetRect={targetRect}
                popoverRect={popoverRect}
                arrowColor={"#176A65"}
                arrowSize={10}
                arrowStyle={this.props.arrowStyle}>
                <div id={this.props.idContainer} className={this.props.arrowContainerClassName}>
                    {this.props.body}
                </div>
            </ArrowContainer>;
        } else return <></>;
    }

    render() {
        return <>
            <Popover
                isOpen={this.state.isPopoverOpen}
                position={this.props.position}
                padding={this.props.padding}
                disableReposition
                align={this.props.align}
                onClickOutside={() => this.setState({isPopoverOpen: false})}
                containerClassName={styles.popover.concat(` ${this.props.className}`)}
                content={({position, targetRect, popoverRect}) => this.showArrowContainer(position, targetRect, popoverRect)}>
                <span className={styles.textPopover}
                      style={{display: this.props.textDisplayMode}}
                      onMouseOver={() => {
                          if (!this.props.disabled && this.props.openOnMouseOver) {
                              this.setState({isPopoverOpen: true});
                          }
                      }}
                      onMouseLeave={() => {
                          if (!this.props.disabled && this.props.openOnMouseOver) {
                              this.setState({isPopoverOpen: false});
                          }
                      }}
                      onClick={() => {
                          if (this.props.openOnMouseOver) return;

                          if (!this.props.disabled && this.props.body.length > 0) {
                              this.setState({isPopoverOpen: !this.state.isPopoverOpen});
                          }
                      }}>
                    <span className={styles[this.props.className]}>{this.props.text}</span>
                </span>
            </Popover>
        </>
    }
}

PopoverReact.propTypes = {
    className: PropTypes.string,
    body: PropTypes.node.isRequired,
    text: PropTypes.node.isRequired,
    textDisplayMode: PropTypes.oneOf(['block', 'inline-block']),
    position: PropTypes.oneOf(["left", "right", "top", "bottom"]).isRequired,
    padding: PropTypes.number,
    align: PropTypes.oneOf(["start", "center", "end"]),
    arrowStyle: PropTypes.object,
    disabled: PropTypes.bool,
    openOnMouseOver: PropTypes.bool,
    arrowContainerClassName: PropTypes.any,
    idContainer:PropTypes.string,
}

PopoverReact.defaultProps = {
    textDisplayMode: 'inline-block',
    className: "popover",
    disabled: false,
    openOnMouseOver: false,
    arrowStyle: {},
    idContainer:"popover-info"
}
