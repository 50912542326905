import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import Utils from "../../../utils/Utils";
import {FieldsRow, RoleBasedSelect} from "../../roleBasedComponents/RoleBasedComponents";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import InfoPopupEsitoQuestionario from "../../statistica/infoPopupEsitoQuestionario";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";


const propTypes = {
    datiStatoComportamentaleForm: PropTypes.any,
    onChangeDatiStatoComportamentaleForm: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class StatoComportamentale extends AccordionSecondLevel {
    state = {
        datiStatoComportamentaleForm: _.cloneDeep(this.props.datiStatoComportamentaleForm),
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiStatoComportamentaleForm, this.props.datiStatoComportamentaleForm))
            this.forceUpdate(() => this.inizializzaForm());
    }

    inizializzaForm = () => {
        const datiStatoComportamentaleForm = _.cloneDeep(this.props.datiStatoComportamentaleForm);
        this.setState({datiStatoComportamentaleForm}, () => this.validazioneDati());
    }

    validazioneDati = () => {
        const datiStatoComportamentaleForm = _.cloneDeep(this.state.datiStatoComportamentaleForm);
        this.props.onChangeDatiStatoComportamentaleForm("datiStatoComportamentale", datiStatoComportamentaleForm);
        this.props.onChangeRequiredFieldsNumber("sottosezioneStatoComportamentale", this.numeroCampiObbligatori());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiStatoComportamentaleForm}) => {
            datiStatoComportamentaleForm[field] = elem;
            return {datiStatoComportamentaleForm};
        }, this.validazioneDati);
    }

    handleArrayDispositiviChange = (array, field) => {
        let datiStatoComportamentaleForm = _.cloneDeep(this.state.datiStatoComportamentaleForm);
        datiStatoComportamentaleForm[field] = array;
        this.setState({datiStatoComportamentaleForm}, () => {
            this.validazioneDati();
            this.forceUpdate();
        });
    }

    renderBody = () => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_PROBLEMI_COMP_COMP.STATOCOMPORTAMENTALE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_PROBLEMI_COMP_COMP.STATOCOMPORTAMENTALE.CAPACITAECURADISE'}
                        pageState={this.props.pageState}
                        id={"selectSetting"}
                        options={OggettiUtili.problemiStatoComportamentale}
                        value={this.state.datiStatoComportamentaleForm.problemiStatoComportamentale}
                        onChange={(elem) => this.handleAggiornaForm("problemiStatoComportamentale", elem)}
                        isSearchable={false}
                        fieldLabel={'Problemi comportamentali (PCOMP)'}
                        fieldRequired={true}
                        noOptionsMessage={() => "Errore: problema comportamentale non esistente"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"problemiStatoComportamentale"}
                        handleOnlyValue={true}
                        hint={<InfoPopupEsitoQuestionario tipoQuestionario="PCOMP" />}
                    />
                </FieldsRow>

                <DispositiviPresenti
                    dispositiviPresenti={this.state.datiStatoComportamentaleForm.dispositiviPresenti}
                    handleArrayChange={this.handleArrayDispositiviChange}
                    pageState={this.props.pageState}
                    fieldId={'M_DISP.STATOCOMPORTAMENTALE.ANAMNESIFISIOLOGICA'}
                    field={"dispositiviPresenti"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    parentJsonPath={"anamnesiFisiologica.datiStatoComportamentale.dispositiviPresenti"}
                />

                <ValutazioneSection
                    pageState={this.props.pageState}
                    ambito={"STATO COMPORTAMENTALE (PCOMP)"}
                    parentFieldId={'STATOCOMPORTAMENTALE.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'ATTIVITASTRUMENTALIDELLAVITAQUOTIDIANA.CAPACITAECURADISE'}
                    appendRolePrefix={true}
                    onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                    valueStatoDopoValutazione={this.state.datiStatoComportamentaleForm.statoDopoValutazione}
                    onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                    valueDataValutazione={this.state.datiStatoComportamentaleForm.dataValutazione}
                    onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                    valueDaConsiderare={this.state.datiStatoComportamentaleForm.daConsiderare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={this.state.datiStatoComportamentaleForm.propostaAttivitaSuggerimenti}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={this.state.datiStatoComportamentaleForm.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={this.state.datiStatoComportamentaleForm.autonomiaGestioneCaregiver}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}

                />
            </>
        );
    }

    numeroCampiObbligatori = () => {
        if (this.props.forceReadOnly || this.context.forceReadOnly) return 0;

        let campiObbligatori = 0;
        campiObbligatori += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(this.state.datiStatoComportamentaleForm?.propostaAttivitaSuggerimenti)
        if (Utils.isStringEmptyOrNullOrUndefined(this.state.datiStatoComportamentaleForm.problemiStatoComportamentale))
            campiObbligatori++;

        return campiObbligatori;
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiStatoComportamentale"}
                title={"Stato comportamentale (PCOMP)"}
                required={this.numeroCampiObbligatori() > 0}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["anamnesiFisiologica.datiStatoComportamentale.dispositiviPresenti"],
                    [defaultDispositiviPresenti]
                )}
                modeAccordion={false}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}
StatoComportamentale.propTypes = propTypes;

StatoComportamentale.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

StatoComportamentale.contextType = RoleBasedContext;
