import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    collo: PropTypes.any,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber:  PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultAlterazione = {
    id: null,
    tipoAlterazione: null,
    latoAlterazione: null,
    sede: null
}

export default class Collo extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.collo)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.collo, this.props.collo))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.collo);
        this.setState({dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({dati}) => {
            if (index === null && subField === null) {
                dati[field] = elem;
            } else {
                dati[field][index][subField] = elem;
            }

            if (!dati.presenzaDiAlterazioni) {
                dati.alterazioni = [];
                dati.condizioneRilevata = null;
            }
            return {dati};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        let dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("collo", dati);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneCollo", campiObbligatoriRimanenti);
    }

    handleDispositiviArrayChange = (array, field) => {
        let data = _.cloneDeep(this.state.dati);
        data[field] = array;
        this.setState({dati: data}, () => {
            this.props.onChangeEsameObiettivo("collo", data);
            this.forceUpdate();
        });
    }

    /* Alterazione */

    addNewAlterazione = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultAlterazione);
        type.id = uuid();
        dati.alterazioni.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeAlterazione = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.alterazioni.splice(index, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderAlterazione = (alterazione, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'TIPO_ALT.COLLO.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    value={alterazione.tipoAlterazione}
                    placeholder={"Testo libero o ICD9-CM - Descrizione"}
                    onChange={(field, elem) => this.handleAggiornaForm("alterazioni", elem.target.value, index, "tipoAlterazione")}
                    id={"basedInputTipoDiAlterazione" + index}
                    fieldLabel={"Tipo di alterazione"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    field={'tipoAlterazione'}
                />
                <RoleBasedSelect
                    fieldId={'LATO_ALT.COLLO.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    id={"selectLatoAlterazione" + index}
                    options={OggettiUtili.latoAlterazione}
                    value={alterazione.latoAlterazione}
                    onChange={(elem) => this.handleAggiornaForm("alterazioni", elem, index, "latoAlterazione")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                    fieldLabel={"Lato alterazione"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    handleOnlyValue={true}
                    field={"latoAlterazione"}
                />
                <RoleBasedInput
                    fieldId={'SEDE_ALT.COLLO.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    value={alterazione.sede}
                    placeholder={"Sede/i"}
                    field={"sede"}
                    onChange={(field, elem) => this.handleAggiornaForm("alterazioni", elem.target.value, index, "sede")}
                    id={"basedInputSede" + index}
                    fieldLabel={"Sede/i"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
            </FieldsRow>
        );
    }

    renderBodySezione() {
        const dati = this.state.dati;
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PRES_ALT.COLLO.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"selectPresenzaDiAlterazioni"}
                        options={OggettiUtili.rispostaBreve}
                        value={dati.presenzaDiAlterazioni}
                        onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioni", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                        fieldLabel={"Presenza di alterazioni"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"presenzaDiAlterazioni"}
                    />
                </FieldsRow>

                {dati.presenzaDiAlterazioni &&
                    <SectionList
                        title={'Alterazione'}
                        data={dati.alterazioni}
                        renderSection={this.renderAlterazione}
                        addNewSectionCallback={this.addNewAlterazione}
                        removeSectionCallback={this.removeAlterazione}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'TIPO_ALT.COLLO.ESAMEOBIETTIVO'}
                        field={'alterazioni'}
                        addButtonVisibilityHandler={dati?.alterazioni.every(a => !!a.tipoAlterazione)}
                        parentJsonPath={"esameObiettivo.collo.alterazioni"}
                    />}

                <DispositiviPresenti
                    handleArrayChange={this.handleDispositiviArrayChange}
                    dispositiviPresenti={dati.dispositiviPresenti}
                    pageState={this.props.pageState}
                    fieldId={"DISPOSITIVI.COLLO.ESAMEOBIETTIVO"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    parentJsonPath={"esameObiettivo.collo.dispositiviPresenti"}
                />

                <CondizioneRilevataSection
                    ambito={'COLLO'}
                    parentFieldId={'COLLO.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={dati.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={dati.daAttenzionare}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                    showSuggerimenti={true}
                    openCondition={dati.presenzaDiAlterazioni}
                />
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"collo"}
                    title={"Collo"}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.collo.alterazioni", "esameObiettivo.collo.dispositiviPresenti",
                            "esameObiettivo.collo.propostaAttivitaSuggerimenti"],
                        [defaultAlterazione, defaultDispositiviPresenti, defaultProposteAttivitaSuggerimenti]
                    )}
                    accordionReadOnly={this.props.accordionReadOnly}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}
Collo.propTypes = propTypes;

Collo.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    showAccordion: false,
    disableDoubleInput: false
}

