import React, {Fragment} from "react";
import * as _ from "lodash";
import * as $ from "jquery";
import moment from "moment";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import PropTypes from "prop-types";
import {
    FieldsRow,
    RoleBasedAutocomplete,
    RoleBasedCalendar,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import esenzioniData from "../../../utils/dataset/esenzioniData/esenzioni.json";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";

const propTypes = {
    mascheraModificabilita: PropTypes.any,
    mascheraModificabilitaRichiesta: PropTypes.any,
    esenzioniA0: PropTypes.any,
    datiEsenzioniForm: PropTypes.any,
    onChangeDatiAssistito: PropTypes.func,
    openAccordion: PropTypes.any,
    richiesta: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultEsenzione = {
    codice: null,
    descrizione: null,
    tipologia: null,
    patologia: null,
    diagnosiInvalidita: null,
    gradoRiconosciuto: null,
    inizioEsenzione: null,
    fineEsenzione: null,
    idElemento: null
}
export default class Esenzioni extends AccordionSecondLevel {

    state = {
        datiEsenzioni: _.cloneDeep(this.props.datiEsenzioniForm),
    }

    esenzioneSeparatorValue = '.';
    icd9CmEmptyValue = '-';

    esenzioniData = esenzioniData.map(e => ({
        tipologia: e.tipologia,
        codice: e.codiceEsenzione + (e.codiceIcd9Cm !== this.icd9CmEmptyValue ? this.esenzioneSeparatorValue + e.codiceIcd9Cm : ""),
        descrizione: e.descrizione
    }));

    componentDidMount() {
        this.inizializzaForm(this.props.datiEsenzioniForm, true);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiEsenzioniForm, this.props.datiEsenzioniForm))
            this.inizializzaForm(this.props.datiEsenzioniForm, false);
        else if (!_.isEqual(prevState.datiEsenzioni, this.state.datiEsenzioni))
            this.inizializzaForm(this.state.datiEsenzioni, false);
    }

    inizializzaForm = (esenzioniList, canUpdate) => {
        let esenzioni = _.cloneDeep(esenzioniList);
        esenzioni = esenzioni.map(esenzione => {
            let esenzioneMap = esenzione;
            let dati = this.findFromDataset(esenzione);
            esenzioneMap.descrizione = dati.descrizione;
            esenzioneMap.tipologia = dati.tipologia;
            esenzioneMap.inizioEsenzione = this.formatData(esenzione.inizioEsenzione);
            esenzioneMap.fineEsenzione = this.formatData(esenzione.fineEsenzione);
            return esenzioneMap;
        });
        this.setState({datiEsenzioni: esenzioni}, () => {
            if (canUpdate) {
                let dati = _.cloneDeep(this.state.datiEsenzioni);
                dati.forEach(e => {
                    e.inizioEsenzione = !Utils.isStringEmptyOrNullOrUndefined(e.inizioEsenzione) && Utils.isValidDateFormatDDMMYYYY(e.inizioEsenzione) ? Utils.transformDateForBackend(e.inizioEsenzione) : '';
                    e.fineEsenzione = !Utils.isStringEmptyOrNullOrUndefined(e.fineEsenzione) && Utils.isValidDateFormatDDMMYYYY(e.fineEsenzione) ? Utils.transformDateForBackend(e.fineEsenzione) : '';
                });
                this.props.onChangeDatiAssistito("esenzioni", dati);
            }
        });
    }

    formatData = (data) => {
        if (!Utils.isStringEmptyOrNullOrUndefined(data)) {
            const isValidData = moment(data, ["DD/MM/YYYY", "YYYY/MM/DD HH:mm:ss"], true).isValid()
                || new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:(.*)\+\d{2}:\d{2}$/g).test(data);
            if (isValidData) data = Utils.transformTimeStampForFrontend(data);
        }
        return data;
    }

    findFromDataset = (esenzione) => {
        let esenzioneMap = esenzione;
        const labelTipologia = oggettiUtili.tipologiaEsenzione.find(t => t.value === esenzione.tipologia)?.label?.toLowerCase() ?? null;
        let find = this.esenzioniData?.find(el => el.codice === esenzione.codice && (!labelTipologia || el.tipologia.toLowerCase() === labelTipologia));
        if (find) {
            esenzioneMap.descrizione = find.descrizione;
            esenzioneMap.tipologia = oggettiUtili.tipologiaEsenzione.find(el => el.label.toLowerCase() === find.tipologia?.toLowerCase())?.value;
        } else if (esenzione.tipologia) {
            esenzioneMap.descrizione = "";
        } else {
            esenzioneMap.descrizione = "";
            esenzioneMap.tipologia = "";
        }
        return esenzioneMap;
    }

    handleAggiornaForm = (field, elem, index) => {
        let datiEsenzioni = _.cloneDeep(this.state.datiEsenzioni);
        let esenzione = datiEsenzioni[index];
        if (!esenzione) return;
        
        if (field === "descrizione") {
            esenzione.codice =  elem?.codice;
            esenzione.descrizione =  elem?.descrizione;
        } else {esenzione[field] = elem ?? null}
        
        if (field === "codice" || field === "descrizione" && !Utils.isStringEmptyOrNullOrUndefined(elem)) {
            if (field === "codice"){
                if (!_.isEqual(this.state.datiEsenzioni[index][field], elem)) {
                    esenzione.tipologia = "";
                    this.forceUpdate(() => $("#selectTipologiaEsenzione").val(''));
                }
            }
            let dati = this.findFromDataset(esenzione);
            esenzione.descrizione = dati.descrizione;
            esenzione.tipologia = dati.tipologia;
        } else if (field === "descrizione" && Utils.isStringEmptyOrNullOrUndefined(elem)) {
            esenzione.codice = "";
            $("#autocompleteCodiceEsenzione_" + index).val('');
        } else if (field === "tipologia") {
            if (!_.isEqual(this.state.datiEsenzioni[index][field], elem)) {
                esenzione.codice = "";
                this.forceUpdate(() => $("#autocompleteCodiceEsenzione_" + index).val(''));
                esenzione.descrizione = "";
            }
            if (esenzione[field] !== oggettiUtili.tipologiaEsenzione[2]) {
                esenzione.patologia = null;
            } else if (esenzione[field] !== oggettiUtili.tipologiaEsenzione[6]) {
                esenzione.diagnosiInvalidita = null;
                esenzione.gradoRiconosciuto = null;
            }
        }

        datiEsenzioni[index] = esenzione;

        this.setState({datiEsenzioni: datiEsenzioni}, () => {
            let esenzioni = _.cloneDeep(this.state.datiEsenzioni);
                esenzioni.forEach(e => {
                    e.inizioEsenzione = !Utils.isStringEmptyOrNullOrUndefined(e.inizioEsenzione) && Utils.isValidDateFormatDDMMYYYY(e.inizioEsenzione) ? Utils.transformDateForBackend(e.inizioEsenzione) : '';
                    e.fineEsenzione = !Utils.isStringEmptyOrNullOrUndefined(e.fineEsenzione) && Utils.isValidDateFormatDDMMYYYY(e.fineEsenzione) ? Utils.transformDateForBackend(e.fineEsenzione) : '';
                });

            this.props.onChangeDatiAssistito("esenzioni", _.cloneDeep(esenzioni));
        });
    }


    addNewEsenzione = () => {
        let datiEsenzioni = _.cloneDeep(this.state.datiEsenzioni);
        let esenzione = _.cloneDeep(defaultEsenzione);
        datiEsenzioni.push(esenzione);
        this.setState({datiEsenzioni: datiEsenzioni});
    }

    removeEsenzione = (index) => {
        let datiEsenzioni = _.cloneDeep(this.state.datiEsenzioni);
        datiEsenzioni.splice(index, 1);

        this.setState({datiEsenzioni: datiEsenzioni}, () => this.props.onChangeDatiAssistito("esenzioni", datiEsenzioni));
    }

    isFromA0 = (esenzioneMascheraModificabilita, field) => {
        return esenzioneMascheraModificabilita && esenzioneMascheraModificabilita[field] !== null && !esenzioneMascheraModificabilita[field];
    }

    isFieldValued = (currentEsenzione, esenzioneA0, field) => {
        const currentValue = currentEsenzione[field]?.value ?? currentEsenzione[field];
        const valueA0 = esenzioneA0?.[field];
        return !Utils.isStringEmptyOrNullOrUndefined(currentValue) && valueA0 != null && _.isEqual(currentValue, valueA0);
    }

    checkValidCodice(elem, index) {
        let findCodice = this.esenzioniData.find((esen) => elem.target.value === esen.codice);
        if (findCodice == null) {
            let datiEsenzioni = _.cloneDeep(this.state.datiEsenzioni);
            datiEsenzioni[index].codice = "";
            elem.target.value = "";
            this.setState({datiEsenzioni: datiEsenzioni});
        }
    }

    renderNewEsenzione = (esenzione, index) => {
        const esenzioneMascheraModificabilita = this.props.mascheraModificabilita?.esenzioni?.find(el => el.idElemento === esenzione?.idElemento) ?? null;
        const esenzioneA0 = this.props.esenzioniA0?.find(el => el.idElemento === esenzione?.idElemento) ?? null;

        let esenzioniDataNotExist = _.cloneDeep(this.esenzioniData)
            .filter(item => !this.state.datiEsenzioni
                ?.find(esenFilter => esenzione.codice !== esenFilter.codice && esenFilter.codice === item.codice));
        if (!Utils.isStringEmptyOrNullOrUndefined(esenzione.tipologia)) {
            const tipologiaLabel = oggettiUtili.tipologiaEsenzione.find(t => t.value === esenzione.tipologia).label;
            esenzioniDataNotExist = esenzioniDataNotExist.filter(e => e.tipologia.toLowerCase() === tipologiaLabel.toLowerCase());
        }

        return (
            <Fragment key={index}>
                <FieldsRow>
                    <RoleBasedAutocomplete
                        fieldId={'COD_ESENZ.ESENZIONI.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        forceReadOnly={(this.isFromA0(esenzioneMascheraModificabilita, "codice") && this.isFieldValued(esenzione, esenzioneA0, "codice"))
                                    || Utils.getValoreCampoElementoLista(this.props.mascheraModificabilitaRichiesta?.anagrafePaziente?.esenzioni, "codice", esenzione?.id)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        placeholder="Inserisci codice esenzione"
                        field="codice"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        onBlur={(elem) => this.checkValidCodice(elem, index)}
                        id={`autocompleteCodiceEsenzione_${index}`}
                        searchFromStart={true}
                        suggestions={esenzioniDataNotExist}
                        noSuggestions={'Errore: Esenzione non esistente'}
                        keyField={"codice"}
                        handleOnlyValue={true}
                        descriptionField={"codice"}
                        value={esenzione.codice ?? ""}
                        fieldLabel={"Codice esenzione"}
                        fieldRequired={this.isFromA0(esenzioneMascheraModificabilita, "codice")}
                        disableDoubleInput={true}
                    />

                    <RoleBasedAutocomplete
                        fieldId={'DESCR_ESENZ.ESENZIONI.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci codice esenzione"
                        field="descrizione"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        id={`autocompleteDescrizioneEsenzione_${index}`}
                        searchFromStart={true}
                        suggestions={esenzioniDataNotExist}
                        noSuggestions={'Errore: Descrizione non esistente'}
                        keyField={"descrizione"}
                        handleOnlyValue={true}
                        descriptionField={"descrizione"}
                        value={esenzione.descrizione ?? ""}
                        fieldLabel={"Descrizione esenzione"}
                        fieldRequired={this.isFromA0(esenzioneMascheraModificabilita, "descrizione")}
                        disableDoubleInput={true}
                        fullElement={true}
                        addCodiceToDescription={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />

                    <RoleBasedSelect
                        fieldId={'TIPO_ESENZ.ESENZIONI.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        id={"selectTipologiaEsenzione"}
                        options={oggettiUtili.tipologiaEsenzione}
                        value={esenzione.tipologia ?? ""}
                        onChange={(elem) => this.handleAggiornaForm("tipologia", elem, index)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Tipologia esenzione non esistente"}
                        fieldLabel={"Tipologia esenzione"}
                        fieldRequired={this.isFromA0(esenzioneMascheraModificabilita, "tipologia")}
                        handleOnlyValue={true}
                        forceReadOnly={this.isFromA0(esenzioneMascheraModificabilita, "tipologia") && this.isFieldValued(esenzione, esenzioneA0, "tipologia")}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        field={"tipologia"}
                        disableDoubleInput={true}
                    />
                    {!Utils.isObjectNull(esenzione.tipologia) && (esenzione.tipologia === "02" || esenzione.tipologia?.value === "02") &&
                        <RoleBasedICD9Input
                            fieldId={'PATO.ESENZIONI.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            value={esenzione.patologia}
                            field="patologia"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            id="icd9CmInputPatologia"
                            fieldLabel={"Patologia"}
                            fieldRequired={this.isFromA0(esenzioneMascheraModificabilita, "patologia")}
                            forceReadOnly={this.isFromA0(esenzioneMascheraModificabilita, "patologia") && this.isFieldValued(esenzione, esenzioneA0, "patologia")}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={true}
                        />}
                </FieldsRow>
                {!Utils.isObjectNull(esenzione.tipologia) && (esenzione.tipologia === "06" || esenzione.tipologia?.value === "06") &&
                    <FieldsRow>
                        <RoleBasedICD9Input
                            fieldId={'DIAGN_INVAL.ESENZIONI.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            value={esenzione.diagnosiInvalidita}
                            field="diagnosiInvalidita"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            id="icd9CmDiagnosiInvalidita"
                            fieldLabel={"Diagnosi invalidità"}
                            fieldRequired={this.isFromA0(esenzioneMascheraModificabilita, "diagnosiInvalidita")}
                            forceReadOnly={this.isFromA0(esenzioneMascheraModificabilita, "diagnosiInvalidita") && this.isFieldValued(esenzione, esenzioneA0, "diagnosiInvalidita")}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={true}
                        />
                        <RoleBasedInput
                            fieldId={'GRADO_RIC.ESENZIONI.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            placeholder="Inserisci grado riconosciuto"
                            field="gradoRiconosciuto"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            id="filterInputNome"
                            value={esenzione.gradoRiconosciuto ?? ""}
                            fieldLabel={"Grado riconosciuto"}
                            disableDoubleInput={true}
                            type={"number"}
                            min={"0"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </FieldsRow>}
                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'DATA_INIZIO_ES.ESENZIONI.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        forceReadOnly={this.isFromA0(esenzioneMascheraModificabilita, "inizioEsenzione") && this.isFieldValued(esenzione, esenzioneA0, "inizioEsenzione")}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        id={"esenzioniInizioEsenzione" + index}
                        value={esenzione.inizioEsenzione}
                        onChange={(elem) => this.handleAggiornaForm("inizioEsenzione", elem.target.value, index)}
                        error={
                            esenzione.inizioEsenzione && esenzione.fineEsenzione
                                ? moment(esenzione.inizioEsenzione, "DD/MM/YYYY").isAfter(moment(esenzione.fineEsenzione, "DD/MM/YYYY"))
                                : false
                        }
                        errorMessage={"Data non valida"}
                        fieldLabel={"Data inizio esenzione"}
                        fieldRequired={this.isFromA0(esenzioneMascheraModificabilita, "inizioEsenzione")}
                        field={"inizioEsenzione"}
                        disableDoubleInput={true}
                    />
                    <RoleBasedCalendar
                        fieldId={'DATA_FINE_ES.ESENZIONI.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        forceReadOnly={this.isFromA0(esenzioneMascheraModificabilita, "fineEsenzione") && this.isFieldValued(esenzione, esenzioneA0, "fineEsenzione")}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        id={"esenzioniFineEsenzione" + index}
                        value={esenzione.fineEsenzione}
                        onChange={(elem) => this.handleAggiornaForm("fineEsenzione", elem.target.value, index)}
                        error={
                            !esenzione.inizioEsenzione && esenzione.fineEsenzione
                                ? moment(esenzione.fineEsenzione, "DD/MM/YYYY") < moment().format("DD/MM/YYYY")
                                : esenzione.inizioEsenzione && esenzione.fineEsenzione
                                    ? moment(esenzione.fineEsenzione, "DD/MM/YYYY").isBefore(moment(esenzione.inizioEsenzione, "DD/MM/YYYY"))
                                    : false
                        }
                        errorMessage={"Data non valida"}
                        fieldLabel={"Data fine esenzione"}
                        fieldRequired={this.isFromA0(esenzioneMascheraModificabilita, "fineEsenzione")}
                        field={"fineEsenzione"}
                        disableDoubleInput={true}
                    />
                </FieldsRow>
            </Fragment>
        );
    }

    renderBodySezione = () => {
        const datiEsenzioni = _.cloneDeep(this.state.datiEsenzioni);
        const canRemove = datiEsenzioni.map(e => (!this.props.mascheraModificabilita?.esenzioni
            ?.find(el => el.idElemento === e?.idElemento)) ?? true);

        return (
            <>
                {this.props.mascheraModificabilita?.esenzioni?.length === 0 && !this.context.showOnlyRequiredFields
                    ?
                    <FieldsRow colNumber={-1}>
                        <p>Non sono presenti esenzioni in Anagrafe regionale</p>
                    </FieldsRow>
                    : null}

                <SectionList
                    title={'Esenzione'}
                    data={datiEsenzioni}
                    canRemoveData={canRemove}
                    addNewSectionCallback={this.addNewEsenzione}
                    removeSectionCallback={this.removeEsenzione}
                    renderSection={this.renderNewEsenzione}
                    pageState={this.props.pageState}
                    keyFieldId={'COD_ESENZ.ESENZIONI.ANAGRAFEPAZIENTE'}
                    field={'datiEsenzioni'}
                    forceReadOnly={this.props.mascheraModificabilitaRichiesta?.esenzioni}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </>
        );
    }


    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"esenzioni"}
                title={"Esenzioni"}
                required={this.props.mascheraModificabilita?.esenzioni?.length > 0}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }

}

Esenzioni.propTypes = propTypes;
Esenzioni.contextType = RoleBasedContext;
