import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../../utils/dataset/OggettiUtili.json";
import {v4 as uuid} from "uuid";
import Utils from "../../../../utils/Utils";
import {isSectionReadOnly} from "../DispositiviMediciPresenti";

const propTypes = {
    dati: PropTypes.object,
    onChange: PropTypes.func,
    pageState: PropTypes.string,
    idDispositivo: PropTypes.string,
    fieldInAccordionReadOnly: PropTypes.bool
}

export const defaultSublistElementCatetereVescicale = {
    id: null,
    codice: null,
    icd9Cm: null
}

export default class CatetereVescicale extends Component {

    state = {
        tipologia: null,
        motivoPosizionamento: null,
        frequenzaDiSostituzione: null,
        tipo: null,
        descrizioneStomia: null,
        numeroVie: null,
        materiale: null,
        gonfiaggioPalloncinoDiFissaggio: null,
        diametroCatetere: null,
        tentativiDiRimozione: null,
        rieducazioneVescicale: null,
        necessitaDiInstillazioniIrrigazioniVescicali: null,
        quantitaDiLiquidi: null,
        tipologiaLiquidi: null
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati)) this.inizializzaForm();
    }

    inizializzaForm = () => {
        let state = _.cloneDeep(this.props.dati);
        if (state == null) state = _.cloneDeep(this.state);
        this.setState(state);
    }

    handleAggiornaForm = (field, elem) => {
        let state = _.cloneDeep(this.state);
        if (field === "necessitaDiInstillazioniIrrigazioniVescicali" && !_.isEqual(state[field], elem)) {
            state["quantitaDiLiquidi"] = null;
            state["tipologiaLiquidi"] = null;
        }
        state[field] = new RegExp(/^[-]\d+$/g).test(elem) ? "0" : elem;
        this.setState(state, () => this.props.onChange("catetereVescicale", this.state));
    }

    addNewElementToList = (field) => {
        let dati = _.cloneDeep(this.state);
        if (!dati[field]) dati[field] = [];
        dati[field].push({..._.cloneDeep(defaultSublistElementCatetereVescicale), id: uuid()});
        this.handleAggiornaForm(field, dati[field]);
    }

    removeElementToList = (sublistIndex, field) => {
        let dati = _.cloneDeep(this.state);
        dati[field].splice(sublistIndex, 1);
        this.handleAggiornaForm(field, dati[field]);
    }

    renderMotivoPosizionamento = (element, index) => {
        const {motivoPosizionamento} = this.state;
        const filterTipologiaProtesiDentaria = (item) => {
            return !(motivoPosizionamento?.find(e => e.codice === item.value))
        }
        const options = OggettiUtili.motivoPosizionamentoCatVesc.filter(filterTipologiaProtesiDentaria);
        if (element?.codice) options.push(OggettiUtili.motivoPosizionamentoCatVesc.find(e => e?.value === element?.codice));

        return <Fragment key={index}>
            <FieldsRow>
                <RoleBasedSelect
                    id={"selectMotivoPosizionamento"}
                    fieldId={'MOT_POS_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"motivoPosizionamento"}
                    fieldLabel={"Motivo di posizionamento"}
                    pageState={this.props.pageState}
                    options={options}
                    value={element.codice}
                    onChange={(elem) => this.handleAggiornaForm(
                        "motivoPosizionamento",
                        _.cloneDeep(this.state)?.motivoPosizionamento?.map(function (typePro) {
                            if (element.id === typePro.id)
                                return {...element, codice: elem}
                            return typePro
                        }))}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                {element.codice === "04"
                    ? <RoleBasedICD9Input
                        id={"autocompleteMotivoPosizionamento"}
                        fieldId={'MOT_POS_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        field={"icd9Cm"}
                        fieldLabel={"Altro ICD9-CM"}
                        pageState={this.props.pageState}
                        onChange={(f, e) => this.handleAggiornaForm(
                            "motivoPosizionamento",
                            _.cloneDeep(this.state)?.motivoPosizionamento?.map(function (typePro) {
                                if (element.id === typePro.id)
                                    return {...element, [f]: e.target.value}
                                return typePro
                            }))}
                        placeholder={"ICD9-CM"}
                        value={element.icd9Cm}
                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                    />
                    : null}
            </FieldsRow>
        </Fragment>;
    }

    render() {
        return <>
            <FieldsRow>
                <RoleBasedSelect
                    id={"selectTipologia"}
                    fieldId={'TIPOLOGIA_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"tipologia"}
                    fieldLabel={"Tipologia"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.tipologia}
                    value={this.state.tipologia}
                    onChange={(elem) => this.handleAggiornaForm("tipologia", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedInput
                    fieldId={'TIPOLOGIA_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'} //TODO: inserire field id corretto
                    pageState={this.props.pageState}
                    placeholder={"Descrizione stomia"}
                    field={"descrizioneStomia"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    id={"descrizioneStomia"}
                    value={this.state.descrizioneStomia}
                    fieldLabel={"Descrizione stomia"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
            <SectionList
                addNewSectionCallback={() => this.addNewElementToList("motivoPosizionamento")}
                removeSectionCallback={(i) => this.removeElementToList(i, "motivoPosizionamento")}
                data={this.state.motivoPosizionamento || []}
                title={'Motivo di posizionamento'}
                renderSection={(e, i) => {
                    return this.renderMotivoPosizionamento(e, i);
                }}
                pageState={this.props.pageState}
                field={"motivoPosizionamento"}
                addButtonVisibilityHandler={this.state.motivoPosizionamento?.length === 0
                || this.state.motivoPosizionamento?.every(el => !Utils.isObjectNull(el?.codice))}
                indentOffset={0}
                forceReadOnly={isSectionReadOnly(this.props.pageState)}
                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                parentJsonPath={"informazioniDiBase.dispositiviMediciPresenti[?(@.id == '"+ this.props.idDispositivo +"')].catetereVescicale"}
            />
            <FieldsRow>
                <RoleBasedSelect
                    id={"selectFrequenzaDiSostituzione"}
                    fieldId={'FREQ_SOST_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"frequenzaDiSostituzione"}
                    fieldLabel={"Frequenza di sostituzione"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.frequenzaSostituzione}
                    value={this.state.frequenzaDiSostituzione}
                    onChange={(elem) => this.handleAggiornaForm("frequenzaDiSostituzione", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectTipo"}
                    fieldId={'TIPO_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"tipo"}
                    fieldLabel={"Tipo"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.tipoCatVesc}
                    value={this.state.tipo}
                    onChange={(elem) => this.handleAggiornaForm("tipo", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectNumeroVie"}
                    fieldId={'N_VIE_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"numeroVie"}
                    fieldLabel={"N° vie"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.numeroVie}
                    value={this.state.numeroVie}
                    onChange={(elem) => this.handleAggiornaForm("numeroVie", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
            <FieldsRow>
                <RoleBasedSelect
                    id={"selectMaterialeCatVes"}
                    fieldId={'MAT_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"materiale"}
                    fieldLabel={"Materiale"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.materialeCatVes}
                    value={this.state.materiale}
                    onChange={(elem) => this.handleAggiornaForm("materiale", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectGonfiaggioPalloncinoDiFissaggio"}
                    fieldId={'GONF_PALL_FISS_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"gonfiaggioPalloncinoDiFissaggio"}
                    fieldLabel={"Gonfiaggio palloncino di fissaggio"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.gonfiaggioPalloncinoFissaggio}
                    value={this.state.gonfiaggioPalloncinoDiFissaggio}
                    onChange={(elem) => this.handleAggiornaForm("gonfiaggioPalloncinoDiFissaggio", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedInput
                    id={"inputDiametroCatetere"}
                    fieldId={'DIAM_CAT_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Diametro catetere"}
                    field={"diametroCatetere"}
                    pageState={this.props.pageState}
                    placeholder={"Diametro catetere"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.diametroCatetere}
                    type={"number"}
                    min={"0"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
            <FieldsRow>
                <RoleBasedSelect
                    id={"selectTentativiDiRimozione"}
                    fieldId={'TENT_RIM_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"tentativiDiRimozione"}
                    fieldLabel={"Tentativi di rimozione"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.rispostaBreve}
                    value={this.state.tentativiDiRimozione}
                    onChange={(elem) => this.handleAggiornaForm("tentativiDiRimozione", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectRieducazioneVescicale"}
                    fieldId={'RIED_VESC_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"rieducazioneVescicale"}
                    fieldLabel={"Rieducazione vescicale"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.rispostaBreve}
                    value={this.state.rieducazioneVescicale}
                    onChange={(elem) => this.handleAggiornaForm("rieducazioneVescicale", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectNecessitàDiInstillazioniIrrigazioniVescicali"}
                    fieldId={'NEC_INSTILL_IRR_VESC_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"necessitaDiInstillazioniIrrigazioniVescicali"}
                    fieldLabel={"Necessità di instillazioni/irrigazioni vescicali"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.rispostaBreve}
                    value={this.state.necessitaDiInstillazioniIrrigazioniVescicali}
                    onChange={(elem) => this.handleAggiornaForm("necessitaDiInstillazioniIrrigazioniVescicali", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
            {this.state.necessitaDiInstillazioniIrrigazioniVescicali &&
            <FieldsRow>
                <RoleBasedInput
                    id={"inputQuantitaDiLiquidi"}
                    fieldId={'QUANT_LIQ_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Quantità di liquidi"}
                    field={"quantitaDiLiquidi"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci quantità di liquidi"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.quantitaDiLiquidi}
                    type={"number"}
                    min={"0"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedInput
                    id={"inputTipologia"}
                    fieldId={'TIPO_LIQ_CAT_VESC.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Tipologia"}
                    field={"tipologiaLiquidi"}
                    pageState={this.props.pageState}
                    placeholder={"Indicare Tipologia"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.tipologiaLiquidi}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>}
        </>;
    }
}

CatetereVescicale.propTypes = propTypes;


