import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {
    aicList,
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedAICInput,
    RoleBasedCalendar,
    RoleBasedFarmacoEquivalenteInput,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import SessionRequest from "../../../utils/SessionRequest";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import {
    defaultAltro,
    defaultFarmaco, defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    ritmoSonnoVeglia: PropTypes.any,
    onChangeDatiRitmoSonnoVeglia: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    isOptional: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultFarmaciPerDormire = {
    id: null,
    codiceAIC: null,
    classeDiEquivalenza: null
}

const defaultValutazioniAnomalieSonno = {
    id: null,
    valutazione: null
}

const defaultSintomiCorrelatiAnomalieSonno = {
    id: null,
    sintomo: null
}

const defaultPossibiliCauseAlterazioniSonno = {
    id: null,
    causa: null
}

export default class RitmoSonnoVeglia extends AccordionSecondLevel {
    state = {
        ritmoSonnoVeglia: _.cloneDeep(this.props.ritmoSonnoVeglia)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.ritmoSonnoVeglia, this.props.ritmoSonnoVeglia))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        const ritmoSonnoVeglia = _.cloneDeep(this.props.ritmoSonnoVeglia);
        this.setState({ritmoSonnoVeglia}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, value, index = null, subField = null) => {
        this.setState(({ritmoSonnoVeglia}) => {
            if (index === null && subField === null) {
                ritmoSonnoVeglia[field] = value;
            } else {
                ritmoSonnoVeglia[field][index][subField] = value;
            }

            if (ritmoSonnoVeglia.ritmo !== "02") {
                ritmoSonnoVeglia.insonnia = null;
                ritmoSonnoVeglia.usoFarmaciPerDormire = null;
                ritmoSonnoVeglia.valutazioniAnomalieSonno = [];
                ritmoSonnoVeglia.sintomiCorrelatiAnomalieSonno = [];
                ritmoSonnoVeglia.possibiliCauseAlterazioniSonno = [];
                ritmoSonnoVeglia.condizioneRilevata = null;
            }

            if (!ritmoSonnoVeglia.usoFarmaciPerDormire) {
                ritmoSonnoVeglia.farmaciPerDormire = [];
            }

            return {ritmoSonnoVeglia};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        const datiRitmoSonnoVeglia = _.cloneDeep(this.state.ritmoSonnoVeglia);
        this.props.onChangeDatiRitmoSonnoVeglia("datiRitmoSonnoVeglia", datiRitmoSonnoVeglia);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiRitmoSonnoVeglia?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneRitmoSonnoVeglia", campiObbligatoriRimanenti);
    }

    addNewElement = (typeElement) => {
        let ritmoSonnoVeglia = _.cloneDeep(this.state.ritmoSonnoVeglia);

        if (typeElement === "defaultFarmaciPerDormire") {
            let type = _.cloneDeep(defaultFarmaciPerDormire);
            type.id = uuid();
            ritmoSonnoVeglia.farmaciPerDormire.push(type);
        } else if (typeElement === "defaultValutazioniAnomalieSonno") {
            let type = _.cloneDeep(defaultValutazioniAnomalieSonno);
            type.id = uuid();
            ritmoSonnoVeglia.valutazioniAnomalieSonno.push(type);
        } else if (typeElement === "defaultSintomiCorrelatiAnomalieSonno") {
            let type = _.cloneDeep(defaultSintomiCorrelatiAnomalieSonno);
            type.id = uuid();
            ritmoSonnoVeglia.sintomiCorrelatiAnomalieSonno.push(type);
        } else if (typeElement === "defaultPossibiliCauseAlterazioniSonno") {
            let type = _.cloneDeep(defaultPossibiliCauseAlterazioniSonno);
            type.id = uuid();
            ritmoSonnoVeglia.possibiliCauseAlterazioniSonno.push(type);
        }

        this.setState({ritmoSonnoVeglia}, () => this.validazioneDati());
    }

    removeElement = (typeElement, index) => {
        let ritmoSonnoVeglia = _.cloneDeep(this.state.ritmoSonnoVeglia);

        if (typeElement === "defaultFarmaciPerDormire") {
            ritmoSonnoVeglia.farmaciPerDormire.splice(index, 1);
        } else if (typeElement === "defaultValutazioniAnomalieSonno") {
            ritmoSonnoVeglia.valutazioniAnomalieSonno.splice(index, 1);
        } else if (typeElement === "defaultSintomiCorrelatiAnomalieSonno") {
            ritmoSonnoVeglia.sintomiCorrelatiAnomalieSonno.splice(index, 1);
        } else if (typeElement === "defaultPossibiliCauseAlterazioniSonno") {
            ritmoSonnoVeglia.possibiliCauseAlterazioniSonno.splice(index, 1);
        }

        this.setState({ritmoSonnoVeglia}, () => this.validazioneDati());
    }

    onHide = () => {
        const ritmoSonnoVeglia = _.cloneDeep(SessionRequest.getInitObject('anamnesiFisiologica.datiRitmoSonnoVeglia'));
        this.setState({ritmoSonnoVeglia},
            () => {
                this.props.onChangeDatiRitmoSonnoVeglia("datiRitmoSonnoVeglia", ritmoSonnoVeglia);
                this.props.onHide();
            });
    }

    renderNewFarmacoPerDormire = (ritmoSonnoVeglia, index) => {
        const optionsAIC = _.cloneDeep(aicList)
            .filter(f => !this.state.ritmoSonnoVeglia.farmaciPerDormire.find(d => ritmoSonnoVeglia.codiceAIC !== d.codiceAIC && d.codiceAIC === f.value));

        return (
            <FieldsRow>
                <RoleBasedAICInput
                    id={"codiceAIC"}
                    fieldId={'M_TIPO_CODICE_AIC_FARMA.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={'I_TIPO_CODICE_AIC_FARMA.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                    field={"codiceAIC"}
                    fieldLabel={"Codice AIC"}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm("farmaciPerDormire", elem.target.value, index, field)}
                    value={ritmoSonnoVeglia.codiceAIC}
                    suggestions={optionsAIC}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    classeEquivalenza={ritmoSonnoVeglia.classeDiEquivalenza}
                />
                <RoleBasedFarmacoEquivalenteInput
                    id={"classeDiEquivalenza"}
                    fieldId={'M_TIPO_CLASSE_EQUIVALENZA_FARMA.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={'I_TIPO_CLASSE_EQUIVALENZA_FARMA.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                    field={"classeDiEquivalenza"}
                    fieldLabel={"Classe di equivalenza"}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm("farmaciPerDormire", elem.target.value, index, field)}
                    value={ritmoSonnoVeglia.classeDiEquivalenza}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    codiceAIC={ritmoSonnoVeglia.codiceAIC}
                />
            </FieldsRow>
        );
    }

    renderNewValutazioneAnomalieSonno = (valutazioneAnomaliaSonno, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={"M_VALUT_ANOM_SONNO.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA"}
                    infermiereFieldId={'I_VALUT_ANOM_SONNO.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                    pageState={this.props.pageState}
                    id={"selectValutazione"}
                    options={OggettiUtili.valutazioniAnomalieSonno}
                    value={valutazioneAnomaliaSonno.valutazione}
                    field="valutazione"
                    onChange={(elem) => this.handleAggiornaForm("valutazioniAnomalieSonno", elem, index, "valutazione")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Valutazione non esistente"}
                    fieldLabel={"Valutazione anomalie del sonno"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    handleOnlyValue={true}
                />
            </FieldsRow>
        );
    }

    renderNewSintomoCorrelatoAnomalieSonno = (sintomoCorrelatoAnomalieSonno, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={"M_SINTOMI_CORR_ANOM.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA"}
                    infermiereFieldId={'I_SINTOMI_CORR_ANOM.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                    pageState={this.props.pageState}
                    id={"selectSintomo"}
                    options={OggettiUtili.sintomiCorrelatiAnomalieSonno}
                    value={sintomoCorrelatoAnomalieSonno.sintomo}
                    field="sintomo"
                    onChange={(elem) => this.handleAggiornaForm("sintomiCorrelatiAnomalieSonno", elem, index, "sintomo")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Sintomo non esistente"}
                    fieldLabel={"Sintomi correlati ad anomalie"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    handleOnlyValue={true}
                />
            </FieldsRow>
        );
    }

    renderNewPossileCausaAlterazioneSonno = (possibileCausaAlterazioneSonno, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={"M_POSS_CAU_ALT_SONNO.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA"}
                    infermiereFieldId={'I_POSS_CAU_ALT_SONNO.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                    pageState={this.props.pageState}
                    id={"selectCausa"}
                    options={OggettiUtili.possibiliCausaAlterazioniSonno}
                    value={possibileCausaAlterazioneSonno.causa}
                    field="causa"
                    onChange={(elem) => this.handleAggiornaForm("possibiliCauseAlterazioniSonno", elem, index, "causa")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Causa non esistente"}
                    fieldLabel={"Possibile causa dell’alterazione del sonno"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    handleOnlyValue={true}
                />
            </FieldsRow>
        );
    }

    renderBodySezione = () => {
        let ritmoSonnoVeglia = this.state.ritmoSonnoVeglia;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_RITMO.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_RITMO.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                        pageState={this.props.pageState}
                        id={"selectRitmo"}
                        options={OggettiUtili.ritmo}
                        value={ritmoSonnoVeglia.ritmo}
                        field={"ritmo"}
                        onChange={(elem) => this.handleAggiornaForm("ritmo", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Ritmo non esistente"}
                        fieldLabel={"Ritmo"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                    />
                    {ritmoSonnoVeglia.ritmo === "02" &&
                        <RoleBasedSelect
                            fieldId={'M_INSONNIA.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_INSONNIA.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                            pageState={this.props.pageState}
                            id={"selectInsonnia"}
                            options={OggettiUtili.rispostaBreve}
                            value={ritmoSonnoVeglia.insonnia}
                            field={"insonnia"}
                            onChange={(elem) => this.handleAggiornaForm("insonnia", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Insonnia non esistente"}
                            fieldLabel={"Insonnia"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                        />}
                    {ritmoSonnoVeglia.ritmo === "02" &&
                        <RoleBasedSelect
                            fieldId={'M_USO_FARMA.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_USO_FARMA.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                            pageState={this.props.pageState}
                            id={"usoFarmaciPerDormire"}
                            options={OggettiUtili.rispostaBreve}
                            value={ritmoSonnoVeglia.usoFarmaciPerDormire}
                            field={"usoFarmaciPerDormire"}
                            onChange={(elem) => this.handleAggiornaForm("usoFarmaciPerDormire", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Uso di farmaci per dormire non esistente"}
                            fieldLabel={"Uso di farmaci per dormire"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                        />}
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'M_ORE_DORM.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_ORE_DORM.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci ore dormire/notte"
                        field="oreDormite"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="oreDormite"
                        value={ritmoSonnoVeglia.oreDormite}
                        type={"number"}
                        min={0}
                        fieldLabel={"Ore dormite/notte"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedInput
                        fieldId={'M_RIPOS_POMER.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_RIP_POMERIDIANI.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci riposi pomeridiani"
                        field="riposiPomeridiani"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="riposiPomeridiani"
                        value={ritmoSonnoVeglia.riposiPomeridiani}
                        type={"number"}
                        min={0}
                        fieldLabel={"Riposi pomeridiani"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                {ritmoSonnoVeglia.usoFarmaciPerDormire &&
                    <SectionList
                        title={'Farmaco per dormire'}
                        data={ritmoSonnoVeglia.farmaciPerDormire}
                        renderSection={this.renderNewFarmacoPerDormire}
                        addNewSectionCallback={() => this.addNewElement("defaultFarmaciPerDormire")}
                        removeSectionCallback={(index) => this.removeElement("defaultFarmaciPerDormire", index)}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        pageState={this.props.pageState}
                        addButtonVisibilityHandler={!ritmoSonnoVeglia.farmaciPerDormire?.some(a => !(a.codiceAIC && a.classeDiEquivalenza)) ?? true}
                        keyFieldId={'M_TIPO_CODICE_AIC_FARMA.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA'}
                        infermiereKeyFieldId={'I_TIPO_CODICE_AIC_FARMA.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                        field={'farmaciPerDormire'}
                        parentJsonPath={"anamnesiFisiologica.datiRitmoSonnoVeglia.farmaciPerDormire"}
                    />}

                {ritmoSonnoVeglia.ritmo === "02" &&
                    <>
                        <SectionList
                            title={'Valutazione anomalie del sonno'}
                            data={ritmoSonnoVeglia.valutazioniAnomalieSonno}
                            renderSection={this.renderNewValutazioneAnomalieSonno}
                            addNewSectionCallback={() => this.addNewElement("defaultValutazioniAnomalieSonno")}
                            removeSectionCallback={(index) => this.removeElement("defaultValutazioniAnomalieSonno", index)}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            pageState={this.props.pageState}
                            addButtonVisibilityHandler={!ritmoSonnoVeglia.valutazioniAnomalieSonno?.some(a => !a.valutazione) ?? true}
                            keyFieldId={"M_VALUT_ANOM_SONNO.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA"}
                            infermiereKeyFieldId={'I_VALUT_ANOM_SONNO.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                            field={'valutazioniAnomalieSonno'}
                            parentJsonPath={"anamnesiFisiologica.datiRitmoSonnoVeglia.valutazioniAnomalieSonno"}
                        />
                        <SectionList
                            title={'Sintomi correlati ad anomalie'}
                            data={ritmoSonnoVeglia.sintomiCorrelatiAnomalieSonno}
                            renderSection={this.renderNewSintomoCorrelatoAnomalieSonno}
                            addNewSectionCallback={() => this.addNewElement("defaultSintomiCorrelatiAnomalieSonno")}
                            removeSectionCallback={(index) => this.removeElement("defaultSintomiCorrelatiAnomalieSonno", index)}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            pageState={this.props.pageState}
                            addButtonVisibilityHandler={!ritmoSonnoVeglia.sintomiCorrelatiAnomalieSonno?.some(a => !a.sintomo) ?? true}
                            keyFieldId={"M_SINTOMI_CORR_ANOM.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA"}
                            infermiereKeyFieldId={'I_SINTOMI_CORR_ANOM.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                            field={'sintomiCorrelatiAnomalieSonno'}
                            parentJsonPath={"anamnesiFisiologica.datiRitmoSonnoVeglia.sintomiCorrelatiAnomalieSonno"}
                        />
                        <SectionList
                            title={'Possibili cause delle alterazioni del sonno'}
                            data={ritmoSonnoVeglia.possibiliCauseAlterazioniSonno}
                            renderSection={this.renderNewPossileCausaAlterazioneSonno}
                            addNewSectionCallback={() => this.addNewElement("defaultPossibiliCauseAlterazioniSonno")}
                            removeSectionCallback={(index) => this.removeElement("defaultPossibiliCauseAlterazioniSonno", index)}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            pageState={this.props.pageState}
                            addButtonVisibilityHandler={!ritmoSonnoVeglia.possibiliCauseAlterazioniSonno?.some(a => !a.causa) ?? true}
                            keyFieldId={"M_POSS_CAU_ALT_SONNO.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA"}
                            infermiereKeyFieldId={'I_POSS_CAU_ALT_SONNO.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                            field={'possibiliCauseAlterazioniSonno'}
                            parentJsonPath={"anamnesiFisiologica.datiRitmoSonnoVeglia.possibiliCauseAlterazioniSonno"}
                        />
                    </>}

                <CondizioneRilevataSection
                    ambito={'RITMOSONNOEVEGLIA'}
                    parentFieldId={'RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                    appendRolePrefix={true}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={ritmoSonnoVeglia.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={ritmoSonnoVeglia.daAttenzionare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={ritmoSonnoVeglia.propostaAttivitaSuggerimenti}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={ritmoSonnoVeglia.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={ritmoSonnoVeglia.autonomiaGestioneCaregiver}
                    openCondition={ritmoSonnoVeglia.ritmo === "02"}
                />

                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'M_DATA_VAL.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_DATA_VAL.RITMOSONNOEVEGLIA.SONNOERIPOSO'}
                        pageState={this.props.pageState}
                        id={"ritmoSonnoVegliaDataValutazione"}
                        value={ritmoSonnoVeglia.dataValutazione}
                        onChange={(elem) => this.handleAggiornaForm("dataValutazione", elem.target.value)}
                        errorMessage={"Data valutazione non valida"}
                        fieldLabel={"Data Valutazione"}
                        field={"dataValutazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"datiRitmoSonnoVeglia"}
            title={"Ritmo sonno e veglia"}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            onHide={this.onHide}
            isOptional={this.props.isOptional && isFieldIdWriteable('M_TIPO_CODICE_AIC_FARMA.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA', this.props.pageState, this.context.forceUserRole)}
            fieldsOrderMap={Utils.generateMapPathsObjects(
                ["anamnesiFisiologica.datiRitmoSonnoVeglia.farmaciPerDormire", "anamnesiFisiologica.datiRitmoSonnoVeglia.valutazioniAnomalieSonno",
                    "anamnesiFisiologica.datiRitmoSonnoVeglia.sintomiCorrelatiAnomalieSonno", "anamnesiFisiologica.datiRitmoSonnoVeglia.possibiliCauseAlterazioniSonno",
                    "anamnesiFisiologica.datiRitmoSonnoVeglia.propostaAttivitaSuggerimenti"],
                [defaultFarmaciPerDormire, defaultValutazioniAnomalieSonno, defaultSintomiCorrelatiAnomalieSonno, defaultPossibiliCauseAlterazioniSonno,
                    defaultProposteAttivitaSuggerimenti]
            )}
            accordionReadOnly={this.props.accordionReadOnly}
            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
        >{this.renderBodySezione()}</AccordionSezioneRichiesta>
    }
}

RitmoSonnoVeglia.propTypes = propTypes;

RitmoSonnoVeglia.defaultProps = {
    isOptional: true,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

RitmoSonnoVeglia.contextType = RoleBasedContext;
