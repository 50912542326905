import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import Statistica from "../../statistica/Statistica";
import Utils from "../../../utils/Utils";
import {FieldsRow, RoleBasedText} from "../../roleBasedComponents/RoleBasedComponents";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import enumQuestionario from "../../../enum/enumQuestionario.json";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import {
    contaNumeroCampiObbligatoriInPropostaAttivitaEsugg
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import enumsUtente from "../../../enum/enumsUtente.json";
import classificazioneData from "../../../utils/dataset/richiestaData/classificazione.json";
import AuthUtils from "../../../utils/AuthUtils";
import {SessioneUtente} from "web-client-archetype";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionHelper from "../../../service/AccordionHelper";

const propTypes = {
    datiNecessitaDiAssistenzaSanitariaForm: PropTypes.any,
    figurePai: PropTypes.arrayOf(PropTypes.object),
    professionistiSupportoAValutazione: PropTypes.arrayOf(PropTypes.object),
    onChangeDatiNecessitaDiAssistenzaSanitaria: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    pageState: PropTypes.any,
    field: PropTypes.any,
    richiesta: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const ID_VIP = 1;
const ID_VPOT = 2;
const ID_SCALA_BRADEN = 4;
const ID_VPIA = 5;

export const isPsanSectionVipRequired = (figurePai, professionistiSupportoAValutazione) => {
    const areFigurePaiValued = !!figurePai?.filter(fp => fp.figuraProfessionale !== OggettiUtili.figureProfessionaliPerPAI[0].value)?.length;
    const figureRichiesteProfessionistiValutazione = (professionistiSupportoAValutazione ?? []).flatMap(psv => psv.figureRichieste);
    const areProfessionistiSupportoValutazioneValued = !!figureRichiesteProfessionistiValutazione?.filter(fr => fr.ruolo !== OggettiUtili.figureProfessionaliPerValutazione[0].value)?.length;
    const isPsanRequired = AccordionHelper.isPsanSurveyRequired;

    return isPsanRequired || (isPsanRequired == null && (areFigurePaiValued || areProfessionistiSupportoValutazioneValued));
}

export default class NecessitaDiAssistenzaSanitaria extends AccordionSecondLevel {
    state = {
        datiNecessitaDiAssistenzaSanitaria: _.cloneDeep(this.props.datiNecessitaDiAssistenzaSanitariaForm),
        isPsanRequired: false
    }

    componentDidMount() {
        this.inizializzaForm();

        AccordionHelper.visibilityPsanSubject.subscribe(flag => {
            this.forceUpdate(() => {
                this.props.onChangeRequiredFieldsNumber("sottosezioneDatiNecessitaDiAssistenzaSanitaria", this.numeroCampiObbligatori());
            });
        });
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiNecessitaDiAssistenzaSanitariaForm, this.props.datiNecessitaDiAssistenzaSanitariaForm)) {
            this.forceUpdate(() => this.inizializzaForm());
        }
    }

    inizializzaForm = () => {
        let datiNecessitaDiAssistenzaSanitaria = _.cloneDeep(this.props.datiNecessitaDiAssistenzaSanitariaForm);
        this.setState({datiNecessitaDiAssistenzaSanitaria}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiNecessitaDiAssistenzaSanitaria}) => {
            datiNecessitaDiAssistenzaSanitaria[field] = elem;
            datiNecessitaDiAssistenzaSanitaria.interpretazionePunteggioScalaDiBraden = this.getInterpretazionePunteggioBraden(datiNecessitaDiAssistenzaSanitaria?.PSAN);
            return {datiNecessitaDiAssistenzaSanitaria};
        }, () => this.validazioneDati());
    }

    validazioneDati = () => {
        let datiNecessitaDiAssistenzaSanitaria = _.cloneDeep(this.state.datiNecessitaDiAssistenzaSanitaria);
        datiNecessitaDiAssistenzaSanitaria.interpretazionePunteggioScalaDiBraden = this.getInterpretazionePunteggioBraden(datiNecessitaDiAssistenzaSanitaria?.PSAN);
        this.props.onChangeDatiNecessitaDiAssistenzaSanitaria("datiNecessitaDiAssistenzaSanitaria", datiNecessitaDiAssistenzaSanitaria);
        this.props.onChangeRequiredFieldsNumber("sottosezioneDatiNecessitaDiAssistenzaSanitaria", this.numeroCampiObbligatori());
    }

    getInterpretazionePunteggioBraden(PSAN) {
        if (!Utils.isObjectNull(PSAN)) {
            const punteggioScalaDiBraden = parseInt(PSAN?.punteggiIntermedi?.find(punteggioIntermedio => punteggioIntermedio.id === ID_SCALA_BRADEN)?.valore);
            if (punteggioScalaDiBraden > 18) {
                return "Assenza di rischio";
            } else if (punteggioScalaDiBraden >= 15 && punteggioScalaDiBraden <= 18) {
                return "Basso rischio";
            } else if (punteggioScalaDiBraden >= 13 && punteggioScalaDiBraden <= 14) {
                return "Medio rischio";
            } else if (punteggioScalaDiBraden >= 10 && punteggioScalaDiBraden <= 12) {
                return "Alto rischio";
            } else if (punteggioScalaDiBraden <= 9) {
                return "Altissimo rischio";
            }
        }
    }

    renderBody = () => {
        const isAdiNotProgrammataAndMmg = this.isAdiNotProgrammataAndCurrentEvaluator(enumsUtente.ruoli.mmg);
        const isAdiNotProgrammataAndInfermiere = this.isAdiNotProgrammataAndCurrentEvaluator(enumsUtente.ruoli.infermiere);
        const isAdiNotProgrammataAndFisiatra = this.isAdiNotProgrammataAndCurrentEvaluator(enumsUtente.ruoli.fisiatra);
        const isPsanVipRequired = isPsanSectionVipRequired(this.props.figurePai, this.props.professionistiSupportoAValutazione);
        const showPSANInRequiredAccordionPage = isPsanVipRequired || isAdiNotProgrammataAndMmg
            || isAdiNotProgrammataAndInfermiere || isAdiNotProgrammataAndFisiatra;

        return (
            <>
                {(showPSANInRequiredAccordionPage || !this.context.showOnlyRequiredFields)
                    && <Statistica
                        fieldId={'M_PUNTEGGIO_VIP.NECESSITADIASSISTENZASANITARIA.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_PUNTEGGIO_VIP.NECESSITADIASSISTENZASANITARIA.CAPACITAECURADISE'}
                        pageState={this.props.pageState}
                        nomeLink={"Compila questionario Necessità di Assistenza Sanitaria - PSAN"}
                        nomePunteggio={"Punteggio Valutazione Necessità Assistenza Sanitaria - VSAN"}
                        questionario={this.state.datiNecessitaDiAssistenzaSanitaria.PSAN}
                        profileName={'Punteggio Necessità di Assistenza sanitaria – PSAN'}
                        profileFieldId={'M_PUNTEGGIO_PSAN.NECESSITADIASSISTENZASANITARIA.ANAMNESIFISIOLOGICA'}
                        profileRequired={isPsanVipRequired}
                        onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.PSAN, questionario)}
                        richiesta={this.props.richiesta}
                        pathRichiestaQuestionario={"anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria.PSAN"}
                        tipoQuestionario={enumQuestionario.tipoQuestionario.PSAN}
                        salvaBozza={this.props.salvaBozza}
                        idSezioniPunteggiIntermedi={[ID_VIP, ID_VPOT, ID_VPIA, ID_SCALA_BRADEN]}
                        sezioniPunteggiRequired={[isPsanVipRequired, false, false, false]}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        parentJsonPathSubObject={'psan'}
                        scoreMandatoryForRole={enumsUtente.ruoli.mmg}
                        compilaQuestionarioObbligatorio={isPsanVipRequired || isAdiNotProgrammataAndMmg}
                        obbligatorieta={isPsanVipRequired ? null : {
                            surveyType: enumQuestionario.tipoQuestionario.PSAN,
                            forceRequiredCompilation: !!this.obbligatorietaPSAN(),
                            sectionIdListRequired: [ID_VIP, ID_VPOT, ID_VPIA]
                        }}
                    />}

                <FieldsRow>
                    <RoleBasedText
                        fieldId={'M_INTERPRETAZIONEPUNTEGGIO_BRADEN.NECESSITADIASSISTENZASANITARIA.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_INTERPRETAZIONEPUNTEGGIO_BRADEN.NECESSITADIASSISTENZASANITARIA.CAPACITAECURADISE'}
                        pageState={this.props.pageState}
                        value={this.state.datiNecessitaDiAssistenzaSanitaria.interpretazionePunteggioScalaDiBraden ?? null}
                        placeholder={"Interpretazione punteggio"}
                        field={"interpretazionePunteggioScalaDiBraden"}
                        fieldLabel={'Interpretazione punteggio scala di Braden'}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <ValutazioneSection
                    pageState={this.props.pageState}
                    ambito={'NECESSITÀ DI ASSISTENZA SANITARIA (PSAN)'}
                    parentFieldId={'NECESSITADIASSISTENZASANITARIA.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'NECESSITADIASSISTENZASANITARIA.CAPACITAECURADISE'}
                    appendRolePrefix={true}
                    onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                    valueStatoDopoValutazione={this.state.datiNecessitaDiAssistenzaSanitaria.statoDopoValutazione}
                    onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                    valueDataValutazione={this.state.datiNecessitaDiAssistenzaSanitaria.dataValutazione}
                    onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                    valueDaConsiderare={this.state.datiNecessitaDiAssistenzaSanitaria.daConsiderare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={this.state.datiNecessitaDiAssistenzaSanitaria.propostaAttivitaSuggerimenti}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={this.state.datiNecessitaDiAssistenzaSanitaria.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={this.state.datiNecessitaDiAssistenzaSanitaria.autonomiaGestioneCaregiver}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </>
        );
    }

    isAdiNotProgrammataAndCurrentEvaluator = (role) => {
        const richiesta = this.props.richiesta;
        const isAdiNotProgrammata = !!richiesta?.classificazione && richiesta?.classificazione !== classificazioneData.PROGRAMMATA.value;

        const cfUtenteAutenticato = SessioneUtente.getInstance().idUtente;
        let isCurrentEvaluator = false;
        switch (role) {
            case enumsUtente.ruoli.mmg:
                isCurrentEvaluator = AuthUtils.hasRuoloMedicoMedicinaGenerale() && richiesta?.valutazioni?.some(v => v.valutatore?.codiceFiscale === cfUtenteAutenticato);
                break;
            case enumsUtente.ruoli.infermiere:
                isCurrentEvaluator = AuthUtils.hasUtenteValutatoreInfermiere() && richiesta?.valutazioni?.some(v => v.valutatore?.codiceFiscale === cfUtenteAutenticato);
                break;
            case enumsUtente.ruoli.specialista:
                isCurrentEvaluator = AuthUtils.hasUtenteValutatoreFisiatra() && richiesta?.valutazioni?.some(v => v.valutatore?.codiceFiscale === cfUtenteAutenticato);
                break;
            default:
                break;
        }

        return isAdiNotProgrammata && Utils.isStateValutazione(this.props.pageState) && isCurrentEvaluator;
    }

    obbligatorietaPSAN = () => {
        let campiObbligatori = 0;
        const isPsanVipRequired = isPsanSectionVipRequired(this.props.figurePai, this.props.professionistiSupportoAValutazione);

        if (isPsanVipRequired) {
            const datiNecessitaDiAssistenzaSanitaria = _.cloneDeep(this.state.datiNecessitaDiAssistenzaSanitaria);
            const isEmptyVIP = Utils.isStringEmptyOrNullOrUndefined(datiNecessitaDiAssistenzaSanitaria?.PSAN?.punteggiIntermedi?.find(p => p.id === ID_VIP)?.valore);
            const isEmptyProfile = Utils.isStringEmptyOrNullOrUndefined(datiNecessitaDiAssistenzaSanitaria?.PSAN?.valutazione);

            let count = 0;
            if (isEmptyVIP) count++;
            if (isEmptyProfile) count++;
            if (count > 0) campiObbligatori++;
        } else if (this.isAdiNotProgrammataAndCurrentEvaluator(enumsUtente.ruoli.mmg)) {
            const datiNecessitaDiAssistenzaSanitaria = _.cloneDeep(this.state.datiNecessitaDiAssistenzaSanitaria);
            const isEmptyVIP = Utils.isStringEmptyOrNullOrUndefined(datiNecessitaDiAssistenzaSanitaria?.PSAN?.punteggiIntermedi?.find(p => p.id === ID_VIP)?.valore);
            const isEmptyVPOT = Utils.isStringEmptyOrNullOrUndefined(datiNecessitaDiAssistenzaSanitaria?.PSAN?.punteggiIntermedi?.find(p => p.id === ID_VPOT)?.valore);
            const isEmptyVPIA = Utils.isStringEmptyOrNullOrUndefined(datiNecessitaDiAssistenzaSanitaria?.PSAN?.punteggiIntermedi?.find(p => p.id === ID_VPIA)?.valore);

            let count = 0;
            if (isEmptyVIP) count++;
            if (isEmptyVPOT) count++;
            if (isEmptyVPIA) count++;
            if (count > 0) campiObbligatori++;
        }

        return campiObbligatori;
    }

    numeroCampiObbligatori = () => {
        if (this.props.forceReadOnly || this.context.forceReadOnly) return 0;

        let campiObbligatori = 0;
        campiObbligatori += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(this.state.datiNecessitaDiAssistenzaSanitaria?.propostaAttivitaSuggerimenti);
        campiObbligatori += this.obbligatorietaPSAN();

        return campiObbligatori;
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiNecessitaDiAssistenzaSanitaria"}
                title={"Necessità di Assistenza Sanitaria (PSAN)"}
                required={this.numeroCampiObbligatori() > 0}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                modeAccordion={false}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}
NecessitaDiAssistenzaSanitaria.propTypes = propTypes;

NecessitaDiAssistenzaSanitaria.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

NecessitaDiAssistenzaSanitaria.contextType = RoleBasedContext;
