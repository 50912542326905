import React, {Fragment, useContext} from "react";
import PropTypes from "prop-types";
import AccordionHelper from "../../service/AccordionHelper";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import Utils from "../../utils/Utils";
import {isFieldIdWriteable} from "../roleBasedComponents/RoleBasedComponents";
import styles from "./Statistica.module.css";
import * as IconBootstrap from "react-bootstrap-icons";
import enumQuestionario from "../../enum/enumQuestionario"


function LinkQuestionario ({tipoQuestionario, questionario, salvaBozza, forceReadOnly, fieldInAccordionReadOnly, infermiereFieldId,
                               pageState, fieldId, nomeLink, compilaQuestionarioObbligatorio, forceRequiredCompilation,
                               fieldIdParent}) {
    const context = useContext(RoleBasedContext);
    /**
       funzione che controlla la visualizazione del link che aprono i questionari
       @returns true/false
    */
    const showLink = () => {
        if (forceReadOnly || context.forceReadOnly || fieldInAccordionReadOnly) return false;

        if (Utils.isRuoloInfermiere() && infermiereFieldId) {
            return isFieldIdWriteable(
                infermiereFieldId,
                pageState
            );
        } else {
            return isFieldIdWriteable(fieldId, pageState, context.forceUserRole);
        }
    };

    const onClickLink = () => {
        AccordionHelper.setLastSurveyCompiled(fieldIdParent + tipoQuestionario);
        if (questionario) salvaBozza();
    }

    const requiredCondition = (questionario?.stato !== enumQuestionario.statoQuestionario.completo || forceRequiredCompilation) && compilaQuestionarioObbligatorio;
    
    return (
        <Fragment>
            {showLink() ? (
                <div>
                    <div id={fieldIdParent + tipoQuestionario} className={"row"}>
                        <div
                            className={styles.linkQuestionario.concat(" col pt-3")}
                            onClick={onClickLink}
                        >
                            <IconBootstrap.ListTask color={"#176A65"} size={24} className={"mr-2"}/>
                            {nomeLink}
                            {compilaQuestionarioObbligatorio && <span className={styles.requiredField.concat(" pl-1")}>*</span>}
                        </div>
                    </div>
                    {requiredCondition && <div className={styles.requiredField.concat(" pb-1")}>È obbligatorio compilare e confermare il questionario </div>}
                </div>
            ) : null}
        </Fragment>
    );
}

LinkQuestionario.propTypes = {
    tipoQuestionario: PropTypes.string,
    questionario: PropTypes.node,
    salvaBozza: PropTypes.func,
    forceReadOnly: PropTypes.bool,
    fieldInAccordionReadOnly: PropTypes.bool,
    infermiereFieldId: PropTypes.string,
    pageState: PropTypes.string,
    fieldId: PropTypes.string,
    nomeLink: PropTypes.string,
    compilaQuestionarioObbligatorio: PropTypes.bool,
    forceRequiredCompilation: PropTypes.bool
};

LinkQuestionario.defaultProps = {
    tipoQuestionario: "",
    questionario: {},
    salvaBozza: () => null,
    forceReadOnly: false,
    fieldInAccordionReadOnly: false,
    infermiereFieldId: "",
    pageState: "",
    fieldId: "",
    nomeLink: "",
    forceRequiredCompilation: false
};

export default LinkQuestionario;
