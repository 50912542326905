import React, {Fragment} from "react";
import PropTypes from "prop-types";
import AccordionSecondLevel from "../../../accordionSezioneRichiesta/AccordionSecondLevel";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import OggettiUtili from "../../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../../roleBasedComponents/RoleBasedComponents";
import Utils, {closeModaleStyle} from "../../../../utils/Utils";
import {v4 as uuid} from "uuid";
import disciplinaData from "../../../../utils/dataset/disciplinaData/disciplinaSpecialisti.json";
import {Button, SessioneUtente} from "web-client-archetype";
import {RoleBasedContext} from "../../../../utils/RoleBasedContext";
import enumsUtente from "../../../../enum/enumsUtente.json"
import ModalHelper from "../../../modale/ModalHelper";
import AuthUtils from "../../../../utils/AuthUtils";
import routePath from "../../../../utils/route/route-path.json";
import enumQuestionario from "../../../../enum/enumQuestionario.json";
import {defaultQuestionario, setRichiestaQuestionario} from "../../../../utils/QuestionariUtils";
import {Redirect} from "react-router-dom";
import AccordionHelper from "../../../../service/AccordionHelper";

const propTypes = {
    dati: PropTypes.any,
    datiNecessitaAssistenzaSanitaria: PropTypes.object,
    richiesta: PropTypes.object,
    salvaBozza: PropTypes.func,
    onChangeDatiFigureProfessionaliPAI: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    firstPageName: PropTypes.string,
    previousPageOnlyRequiredFields: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultFiguraProfessionalePAI = {
    id: null,
    figuraProfessionale: null,
    disciplina: null,
    numeroAccessi: null,
    cadenza: null,
    durataPrevista: null,
    codiceFiscale: null,
    cadenzaDurataPrevista: null
}

export default class FigureProfessionaliPAI extends AccordionSecondLevel {
    state = {
        figureProfessionaliPAI: _.cloneDeep(this.props.dati),
        showAlertSurveyToCompile: false,
        redirectToSurvey: false,
        richiestaForSurveyProp: null
    }

    numeroCampiObbligatoriMancanti = 0;
    listaDisciplineSelect = disciplinaData.map(el => ({value: el.codice, label: el.descrizione}));

    componentDidMount() {
        let figurePAI = this.recuperaFigurePAI();
        this.inizializzaForm(figurePAI);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati)) {
            let figurePAI = this.recuperaFigurePAI();
            this.inizializzaForm(figurePAI);
        }
    }

    getRuoloUtenteLoggato = () => {
        return SessioneUtente.getInstance().settings.find(s => s.ruolo !== enumsUtente.ruoli.referenteAulss);
    }

    recuperaFigurePAI = () => {
        let figurePAI = _.cloneDeep(this.props.dati);
        let list = [];

        if (figurePAI?.length > 0) {
            list.push(...figurePAI);
        } else {
            let figuraPAIDefault = _.cloneDeep(defaultFiguraProfessionalePAI);
            figuraPAIDefault.id = uuid();
            if (Utils.isStateValutazione(this.props.pageState))
                figuraPAIDefault.figuraProfessionale = OggettiUtili.mapRuoliFigureProfessionaliPerPAI.find(obj => obj.value === this.getRuoloUtenteLoggato().ruolo).label;
            list.push(figuraPAIDefault);
        }

        return list;
    }

    inizializzaForm = (figurePAI = this.props.dati) => {
        let figureProfessionaliPAI = _.cloneDeep(figurePAI);
        figureProfessionaliPAI = this.formatValues(figureProfessionaliPAI, false);
        this.setState({figureProfessionaliPAI: figureProfessionaliPAI}, () => this.validaSezione());
    }

    formatValues = (figurePAI, isObjectForBackend) => {
        if (figurePAI.length > 0) {
            return figurePAI.map(figuraPAI => {
                let figuraPAIMap = figuraPAI;
                if (isObjectForBackend) {
                    figuraPAIMap.numeroAccessi = !Utils.isStringEmptyOrNullOrUndefined(figuraPAI.numeroAccessi) ? Number(figuraPAI.numeroAccessi) : null;
                    figuraPAIMap.durataPrevista = !Utils.isStringEmptyOrNullOrUndefined(figuraPAI.durataPrevista) ? Number(figuraPAI.durataPrevista) : null;
                } else {
                    figuraPAIMap.numeroAccessi = figuraPAI.numeroAccessi !== null ? figuraPAI.numeroAccessi.toString() : null;
                    figuraPAIMap.durataPrevista = figuraPAI.durataPrevista !== null ? figuraPAI.durataPrevista.toString() : null;
                }
                return figuraPAIMap;
            });
        } else return [];
    }

    handleAggiornaForm = (field, elem, index) => {
        let figureProfessionaliPAI = _.cloneDeep(this.state.figureProfessionaliPAI);
        let figuraPAI = figureProfessionaliPAI[index];
        figuraPAI[field] = new RegExp(/^[-]\d+$/g).test(elem) || elem === "0" ? "1" : elem;

        let showAlertSurveyToCompile = false;
        if (field === "figuraProfessionale") {
            Object.keys(figuraPAI).forEach(key => {
                if (key !== "id" && key !== field && key !== 'codiceFiscale')
                    figuraPAI[key] = null;
            });

            const isValidFiguraPai = this.state.figureProfessionaliPAI?.length > 1 && _.isEqual(elem, OggettiUtili.figureProfessionaliPerPAI[0].value);
            showAlertSurveyToCompile = AuthUtils.hasRuoloMedicoMedicinaGenerale()
                && !_.isEqual(elem, this.state.figureProfessionaliPAI[index].figuraProfessionale)
                && !!elem && !_.isEqual(elem, OggettiUtili.figureProfessionaliPerPAI[0].value)
                && this.props.datiNecessitaAssistenzaSanitaria?.PSAN?.punteggiIntermedi?.find(p => p.id === 1)?.valore == null;
            AccordionHelper.visibilityPsanSubject.next(showAlertSurveyToCompile || isValidFiguraPai);
            AccordionHelper.isPsanSurveyRequired = showAlertSurveyToCompile || isValidFiguraPai;
        }

        figureProfessionaliPAI[index] = figuraPAI;
        this.setState({figureProfessionaliPAI, showAlertSurveyToCompile}, () => {
            this.validaSezione();
            this.formatObjectForBackend();
        });
    }

    validaSezione = () => {
        if (this.context.forceReadOnly) {
            this.numeroCampiObbligatoriMancanti = 0;
            return;
        }
        let numeroCampiObbligatoriMancanti = 0;
        this.state.figureProfessionaliPAI.forEach(figuraPAI => {
            if (figuraPAI.figuraProfessionale == null) numeroCampiObbligatoriMancanti++;

            const ruoloLoggato = this.getRuoloUtenteLoggato().ruolo
            const ruoloSelezionato = OggettiUtili.mapRuoliFigureProfessionaliPerPAI.find(obj => obj.label === figuraPAI.figuraProfessionale)?.value
            const isSameRole = ruoloLoggato === ruoloSelezionato;

            if ((Utils.isStateRichiesta(this.props.pageState) && figuraPAI.figuraProfessionale === OggettiUtili.figureProfessionaliPerPAI[0].value)
                || (Utils.isStateValutazione(this.props.pageState) && isSameRole)) {
                if (Utils.isStringEmptyOrNullOrUndefined(figuraPAI.numeroAccessi)) numeroCampiObbligatoriMancanti++;
                if (Utils.isObjectNull(figuraPAI.cadenza)) numeroCampiObbligatoriMancanti++;
                if (Utils.isStringEmptyOrNullOrUndefined(figuraPAI.durataPrevista)) numeroCampiObbligatoriMancanti++;

                if (Utils.isObjectNull(figuraPAI.cadenzaDurataPrevista)
                    && isFieldIdWriteable("X_DURATA_PREV_SETTIMANE.ACCESSI.RICHIESTACAMBIOSETTING", this.props.pageState, this.context.forceUserRole)) {
                    numeroCampiObbligatoriMancanti++;
                }
            }
            if (figuraPAI.figuraProfessionale === "06" && Utils.isStringEmptyOrNullOrUndefined(figuraPAI.disciplina)) {
                numeroCampiObbligatoriMancanti++;
            }
        });

        this.numeroCampiObbligatoriMancanti = numeroCampiObbligatoriMancanti;

        this.props.onChangeRequiredFieldsNumber(numeroCampiObbligatoriMancanti);
    }

    formatObjectForBackend = () => {
        let figureProfessionaliPAI = _.cloneDeep(this.state.figureProfessionaliPAI);
        this.formatValues(figureProfessionaliPAI, true);
        this.props.onChangeDatiFigureProfessionaliPAI("figureProfessionaliPresaInCarico", figureProfessionaliPAI);
    }

    isRequiredField = (object, index) => {
        return !Utils.isObjectNull(object.figuraProfessionale)
            && (Utils.isStateRichiesta(this.props.pageState) && object.figuraProfessionale === OggettiUtili.figureProfessionaliPerPAI[0].value);
    }

    renderNewFiguraProfessionalePAI = (figuraProfessionalePAI, index) => {
        const ruoloLoggato = this.getRuoloUtenteLoggato().ruolo
        const ruoloSelezionato = OggettiUtili.mapRuoliFigureProfessionaliPerPAI.find(obj => obj.label === figuraProfessionalePAI?.figuraProfessionale)?.value
        const isNotSameRole = ruoloLoggato !== ruoloSelezionato;

        return (
            <Fragment key={index}>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'FIGURE_PROF_PAI_PRI.FIGUREPROFESSIONALIRICHIESTEPERLAPRESAINCARICO.RICHIESTACAMBIOSETTING'}
                        pageState={this.props.pageState}
                        id={"selectFigureProfessionaliPAI"}
                        className={"selectFigureProfessionaliPAI"}
                        options={OggettiUtili.figureProfessionaliPerPAI}
                        value={figuraProfessionalePAI?.figuraProfessionale}
                        onChange={(elem) => this.handleAggiornaForm("figuraProfessionale", elem, index)}
                        isSearchable={false}
                        fieldLabel={"Figura professionale per PAI"}
                        fieldRequired={true}
                        field={"figuraProfessionale"}
                        handleOnlyValue={true}
                        forceReadOnly={Utils.isStateValutazione(this.props.pageState)}
                    />
                    {figuraProfessionalePAI?.figuraProfessionale === "06" &&
                    <RoleBasedSelect
                        fieldId={'FIGURE_PROF_PAI_PRI.FIGUREPROFESSIONALIRICHIESTEPERLAPRESAINCARICO.RICHIESTACAMBIOSETTING'}
                        pageState={this.props.pageState}
                        id={"selectDisciplinaFigureProfessionaliPAI"}
                        className={"selectDisciplinaFigureProfessionaliPAI"}
                        options={this.listaDisciplineSelect}
                        value={figuraProfessionalePAI?.disciplina}
                        onChange={(elem) => this.handleAggiornaForm("disciplina", elem, index)}
                        isSearchable={false}
                        fieldLabel={"Specificare"}
                        fieldRequired={true}
                        field={"disciplina"}
                        handleOnlyValue={true}
                        forceReadOnly={Utils.isStateValutazione(this.props.pageState)}
                    />}
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'X_NUM_ACCESSI.ACCESSI.RICHIESTACAMBIOSETTING'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci numero accessi"}
                        field={"numeroAccessi"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        id={"inputNumeroAccessiFiguraProfessionalePAI"}
                        value={figuraProfessionalePAI?.numeroAccessi ?? ""}
                        type={"number"}
                        min={"1"}
                        fieldLabel={"Numero accessi"}
                        fieldRequired={this.isRequiredField(figuraProfessionalePAI) || (Utils.isStateValutazione(this.props.pageState) && !isNotSameRole)}
                        forceReadOnly={Utils.isStateValutazione(this.props.pageState) && isNotSameRole}
                    />
                    <RoleBasedSelect
                        fieldId={'X_CADENZA.ACCESSI.RICHIESTACAMBIOSETTING'}
                        pageState={this.props.pageState}
                        id={"selectCadenzaAccessi"}
                        className={"selectCadenzaAccessi"}
                        options={OggettiUtili.ripetizioneNelTempo}
                        value={figuraProfessionalePAI?.cadenza}
                        onChange={(elem) => this.handleAggiornaForm("cadenza", elem, index)}
                        isSearchable={false}
                        fieldLabel={"Cadenza accessi"}
                        fieldRequired={this.isRequiredField(figuraProfessionalePAI) || (Utils.isStateValutazione(this.props.pageState) && !isNotSameRole)}
                        field={"cadenza"}
                        handleOnlyValue={true}
                        forceReadOnly={Utils.isStateValutazione(this.props.pageState) && isNotSameRole}
                    />
                    <RoleBasedInput
                        fieldId={'X_DURATA_PREV.ACCESSI.RICHIESTACAMBIOSETTING'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci durata prevista"}
                        field={"durataPrevista"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        id={"inputDurataPrevistaAccessoFiguraProfessionalePAI"}
                        value={figuraProfessionalePAI?.durataPrevista ?? ""}
                        type={"number"}
                        min={"1"}
                        fieldLabel={"Durata prevista accessi"}
                        fieldRequired={this.isRequiredField(figuraProfessionalePAI) || (Utils.isStateValutazione(this.props.pageState) && !isNotSameRole)}
                        forceReadOnly={Utils.isStateValutazione(this.props.pageState) && isNotSameRole}
                    />

                    <RoleBasedSelect
                        fieldId={'X_DURATA_PREV_SETTIMANE.ACCESSI.RICHIESTACAMBIOSETTING'}
                        pageState={this.props.pageState}
                        id={"selectCadenzaSettimaneAccessi"}
                        className={"selectCadenzaAccessi"}
                        options={OggettiUtili.ripetizioneNelTempoSettimaneEmesi}
                        value={figuraProfessionalePAI?.cadenzaDurataPrevista}
                        onChange={(elem) => this.handleAggiornaForm("cadenzaDurataPrevista", elem, index)}
                        isSearchable={false}
                        fieldLabel={"Cadenza Durata Prevista"}
                        fieldRequired={this.isRequiredField(figuraProfessionalePAI) || (Utils.isStateValutazione(this.props.pageState) && !isNotSameRole)}
                        field={"cadenzaDurataPrevista"}
                        handleOnlyValue={true}
                        forceReadOnly={Utils.isStateValutazione(this.props.pageState) && isNotSameRole}
                    />
                </FieldsRow>
            </Fragment>
        );
    }

    addNewFiguraProfessionalePAI = () => {
        let array = this.state.figureProfessionaliPAI ?? [];

        this.setState({
            figureProfessionaliPAI: [...array, {
                ...defaultFiguraProfessionalePAI,
                id: uuid(),
                figuraProfessionale: Utils.isStateValutazione(this.props.pageState) ? OggettiUtili.mapRuoliFigureProfessionaliPerPAI.find(obj => obj.value === this.getRuoloUtenteLoggato().ruolo).label : null,
                disciplina: Utils.isStateValutazione(this.props.pageState) ? this.listaDisciplineSelect.find(el => el.value === this.getRuoloUtenteLoggato().disciplina)?.value : null,
                codiceFiscale: SessioneUtente.getInstance().idUtente
            }]
        }, this.validaSezione);
    }

    removeFiguraProfessionalePAI = (index) => {
        let figureProfessionaliPAI = _.cloneDeep(this.state.figureProfessionaliPAI);
        figureProfessionaliPAI.splice(index, 1);

        const areValidFigurePai = figureProfessionaliPAI?.length > 1
            || figureProfessionaliPAI?.find(fpp => _.isEqual(fpp.figuraProfessionale, OggettiUtili.figureProfessionaliPerPAI[0].value)) == null;
        AccordionHelper.visibilityPsanSubject.next(areValidFigurePai);
        AccordionHelper.isPsanSurveyRequired = areValidFigurePai;

        this.setState({figureProfessionaliPAI: figureProfessionaliPAI}, () => {
            this.validaSezione();
            this.formatObjectForBackend();
        });
    }

    renderAlertSurveyToCompile = () => {
        const footerModale = (
            <>
                <Button
                    onClick={() => {
                        return this.props.salvaBozza(false, true, this.props.richiesta).subscribe((data) => {
                            if (data) {
                                this.setState({
                                    redirectToSurvey: true,
                                    richiestaForSurveyProp: data
                                }, () => closeModaleStyle());
                            }
                        });
                    }}
                    id={"goToSurveyBtn"}
                    text="Vai al questionario"
                    className={"btn btn-adi btnOrangeOutline"}
                />
                <Button
                    onClick={() => this.setState({showAlertSurveyToCompile: false}, () => closeModaleStyle())}
                    id={"chiudiBtn"}
                    text="Chiudi"
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                />
            </>
        );

        return <ModalHelper
            id={'idModaleCompilaPSAN_VIP'}
            title={"Per le ADI non programmate è obbligatorio compilare e confermare il questionario PSAN (Punteggio VIP)."}
            titleClassName={"modal-header d-inline text-center backgroundOrange"}
            containerClassName={("modal-dialog modal-dialog-scrollable modal modal-dialog-centered")}
            contentClassName={"modal-content"}
            body={"Si vuole procedere con la compilazione?"}
            footer={footerModale}
            footerClassName={"modal-footer justify-content-around"}
            bodyClassName={"modal-body d-flex align-items-center mx-auto"}
        />;
    }

    redirectToSurveyCompilation = () => {
        let baseUrl = Utils.isStateRichiesta(this.props.pageState) ? routePath.inserimento_richiesta : routePath.valutazione_multidimensionale;
        let questionario = this.props.datiNecessitaDiAssistenzaSanitaria?.PSAN ?? _.cloneDeep(defaultQuestionario);
        let richiesta = _.cloneDeep(this.state.richiestaForSurveyProp);

        const pathRichiestaQuestionario = "anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria.PSAN";
        setRichiestaQuestionario(richiesta, questionario, pathRichiestaQuestionario);

        AccordionHelper.setIdSelectedAccordions("figureProfessionaliPresaInCarico|figurePAI");

        return <Redirect to={{
            pathname: baseUrl.concat(routePath.compila_questionario),
            richiesta: richiesta,
            tipoQuestionario: enumQuestionario.tipoQuestionario.PSAN,
            pageState: this.props.pageState,
            pathRichiestaQuestionario: pathRichiestaQuestionario,
            showOnlyRequiredFields: this.context.showOnlyRequiredFields,
            redirectPage: window.location.pathname,
            firstPageName: this.props.firstPageName,
            previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields
        }}/>;
    }

    renderBodySezione = () => {
        let figurePAI = _.cloneDeep(this.state.figureProfessionaliPAI);
        const ruoloLoggato = OggettiUtili.mapRuoliFigureProfessionaliPerPAI.find(obj => obj.value === this.getRuoloUtenteLoggato().ruolo)?.label
        const readOnlyFigure = this.context.forceReadOnly || figurePAI.map(figura => Utils.isStateValutazione(this.props.pageState) && (figura.codiceFiscale !== SessioneUtente.getInstance().idUtente || figura.figuraProfessionale !== ruoloLoggato));

        if (this.state.redirectToSurvey) return this.redirectToSurveyCompilation();

        return <>
            {this.state.showAlertSurveyToCompile && this.renderAlertSurveyToCompile()}
            <SectionList
                title={"Figura PAI"}
                data={figurePAI}
                renderSection={this.renderNewFiguraProfessionalePAI}
                addNewSectionCallback={this.addNewFiguraProfessionalePAI}
                removeSectionCallback={this.removeFiguraProfessionalePAI}
                addButtonVisibilityHandler={(Utils.isStateValutazione(this.props.pageState) && !figurePAI.some(e => e.figuraProfessionale === ruoloLoggato))
                    || (Utils.isStateRichiesta(this.props.pageState) && this.numeroCampiObbligatoriMancanti === 0)}
                pageState={this.props.pageState}
                keyFieldId={'X_NUM_ACCESSI.ACCESSI.RICHIESTACAMBIOSETTING'}
                atLeastOne={true}
                field={'figureProfessionaliPresaInCarico'}
                forceReadOnly={readOnlyFigure}
            />
        </>;
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"figurePAI"}
                title={"Figure professionali per PAI/PRI"}
                required={this.numeroCampiObbligatoriMancanti > 0}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }
}

FigureProfessionaliPAI.propTypes = propTypes;
FigureProfessionaliPAI.contextType = RoleBasedContext;
