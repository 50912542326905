import React, {Fragment} from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
import Questionario from "./questionario/Questionario";
import routepath from "../../utils/route/route-path"
import styles from "./CompilaQuestionario.module.css"
import * as _ from "lodash";
import enumQuestionario from "../../enum/enumQuestionario"
import Utils from "../../utils/Utils";
import ScrollTop from "../scrollTop/ScrollTop"
import SvuotaQuestionario from "./svuotaQuestionario/SvuotaQuestionario";
import BottoniQuestionario from "./bottoniQuestionario/BottoniQuestionario";
import {
    areCampiObbligatoriSezioneCompilati,
    calcoloValutazione,
    getRichiestaQuestionario,
    defaultQuestionario,
    getSezioneValutazione,
    getQuestionario,
    getSezioneQuestionario,
    punteggiIntermedi,
    areAllSectionValorised
} from "../../utils/QuestionariUtils";
import {getValutazionePrecedente} from "../home/HomeUtils";
import InserimentoRichiesta from "../inserimentoRichiesta/InserimentoRichiesta";
import * as $ from "jquery";

const propTypes = {
    richiesta: PropTypes.object,
    tipoQuestionario: PropTypes.string,
    pageState: PropTypes.string,
    pathRichiestaQuestionario: PropTypes.string,
    sessoPaziente: PropTypes.any,
    externalChange: PropTypes.any,
    hiddenCtaContainer: PropTypes.any,
    disabilitato: PropTypes.bool,
    hiddenScroll:PropTypes.bool,
    labelSezioneObbligatoria: PropTypes.bool,
    titoloRequired: PropTypes.bool,
    salvaBozza:PropTypes.func,
    showOnlyRequiredFields: PropTypes.bool,
    redirectPage: PropTypes.string,
    firstPageName: PropTypes.string,
    previousPageOnlyRequiredFields: PropTypes.bool,
    obbligatorieta: PropTypes.object,
    infoRichiesta: PropTypes.object,
    tornaIndietro: PropTypes.string
}
const defaultProps = {
    labelSezioneObbligatoria: true,
    titoloRequired: false,
    obbligatorieta: null
}

export default class CompilaQuestionario extends React.Component {
    state = {
        richiestaAggiornata: {},
        areRispostePresenti: false,
        isQuestionarioCompleto: false,
        tornaIndietro: false,
        salvaBozzaSubscription: null,
        isMenuItemClicked: false
    }


    questionario = null;
    //TODO ELIMINARE QUESTA VARIABNILE NOT USED CON LA NUOVA SPECIFICA 
    // // variabile che mantiene le informazioni del questionario confermato
    // questionarioConfermato = {};

    componentDidMount() {
        this.inizializzaRichiesta();
    }

    inizializzaRichiesta = () => {
        this.questionario = getQuestionario(this.props.tipoQuestionario);
        let richiesta = _.cloneDeep(this.props.richiesta);
        let nuovaRichiesta;
        let richiestaQuestionario = getRichiestaQuestionario(richiesta, _.cloneDeep(this.props.pathRichiestaQuestionario));
        if (richiesta && richiestaQuestionario && richiestaQuestionario.hasOwnProperty(this.props.tipoQuestionario)
            && richiestaQuestionario[this.props.tipoQuestionario]) {
            nuovaRichiesta = richiestaQuestionario;
            // TODO ELIMINARE QUESTA VARIABNILE NOT USED CON LA NUOVA SPECIFICA 
            // this.questionarioConfermato = richiestaQuestionario;
            if (_.isEqual(nuovaRichiesta[this.props.tipoQuestionario], _.cloneDeep(defaultQuestionario)) || nuovaRichiesta[this.props.tipoQuestionario]?.sezioni?.length === 0) {
                this.inizializzaSezioni(nuovaRichiesta);
            }
            // set punteggi intermedi
            _.forEach(nuovaRichiesta[this.props.tipoQuestionario].punteggiIntermedi, punteggioIntermedio => {
                if(punteggiIntermedi[this.props.tipoQuestionario] == null){
                    punteggiIntermedi[this.props.tipoQuestionario] = {}
                }
                punteggiIntermedi[this.props.tipoQuestionario][punteggioIntermedio.id] = punteggioIntermedio.valore;
            });

        } else {
            nuovaRichiesta = {
                [this.props.tipoQuestionario]: _.cloneDeep(defaultQuestionario)
            };
            this.inizializzaSezioni(nuovaRichiesta);
        }

        this.setState({
            richiestaAggiornata: nuovaRichiesta,
            salvaBozzaSubscription: InserimentoRichiesta.salvaBozzaSubject.subscribe((isMenuItemClicked) => {
                this.setState({isMenuItemClicked}, () => $($("button#bozza")[0]).trigger("click"));
            })
        }, () => {
            this.setState({isQuestionarioCompleto: this.validaQuestionario(_.cloneDeep(this.state.richiestaAggiornata[this.props.tipoQuestionario]))});
            this.inizzializazionePerTipoQuestionario(nuovaRichiesta);
        });
    }


    inizializzaSezioni = (richiesta) => {
        if (this.questionario && this.questionario.sezioni) {
            this.questionario.sezioni.forEach(sezione => {
                richiesta[this.props.tipoQuestionario].sezioni.push({
                    id: sezione.id,
                    domande: []
                })
            })
        }
    }

    componentWillUnmount() {
        if (this.state.salvaBozzaSubscription) this.state.salvaBozzaSubscription.unsubscribe();
    }

    changeDatiQuestionario = (sezione, punteggio, valutazione, sezioneAttualeQuestionario) => {
        let richiesta = _.cloneDeep(this.state.richiestaAggiornata);
        let punteggioSottoQuestionari = punteggiIntermedi;
        // inizializza oggetto punteggio sotto questionari se null
        if (punteggioSottoQuestionari[this.props.tipoQuestionario] == null) {
            punteggioSottoQuestionari[this.props.tipoQuestionario] = {}
        }
        let indexSezioneRichiesta = this.getIndexSezioneRichiesta(richiesta, sezione.id);

        if (indexSezioneRichiesta > -1) {
            // assegnazione dati sezione a richiesta
            richiesta[this.props.tipoQuestionario].sezioni[indexSezioneRichiesta] = sezione;

            // assegnazione punteggio sotto questionari
            if (getSezioneValutazione(this.props.tipoQuestionario).id !== sezione.id) {
                punteggioSottoQuestionari[this.props.tipoQuestionario][sezione.id] = punteggio;
            } else if (this.questionario?.sezioni?.length === 1) {
                punteggioSottoQuestionari[this.props.tipoQuestionario][sezione.id] = punteggio;
            }


            // logiche questionari particolari
            // metodo utile nei casi in cui sono presenti sotto-questionari comunicanti tra loro
            this.modificaQuestionariParticolari(richiesta, punteggioSottoQuestionari, sezione, sezioneAttualeQuestionario);
            let punteggioTotale = this.punteggioTotaleArrotondato(punteggioSottoQuestionari);

            // controllo validità
            let isQuestionarioCompleto = this.validaQuestionario(richiesta[this.props.tipoQuestionario], sezioneAttualeQuestionario);

            // calcolo valutazione
            if (isQuestionarioCompleto && this.canShowAndStorePunteggio(richiesta)) {
                // set punteggio totale
                richiesta[this.props.tipoQuestionario].punteggio = punteggioTotale;

                // set valutazione
                let sezioneValutazione = getSezioneValutazione(this.props.tipoQuestionario);
                richiesta[this.props.tipoQuestionario].valutazione = valutazione != null ? valutazione : calcoloValutazione(this.props.tipoQuestionario,
                    sezioneValutazione, punteggioTotale, this.props.sessoPaziente);
            } else {
                // se il questionario in fase di compilazione non è completo la richiesta non avrà punteggio e valutazione
                richiesta[this.props.tipoQuestionario].punteggio = null;
                if (this.props.tipoQuestionario !== enumQuestionario.tipoQuestionario.PSAN) {
                    richiesta[this.props.tipoQuestionario].valutazione = null;
                }
            }

            // aggiorna stato
            this.setState({
                richiestaAggiornata: richiesta,
                isQuestionarioCompleto: isQuestionarioCompleto
            }, () => {
                this.setState({
                    areRispostePresenti: this.controllaRispostePresenti()
                })
            });

            if (this.props.externalChange) {
                this.props.externalChange(
                    {
                        richiesta: richiesta[this.props.tipoQuestionario],
                        isQuestionarioCompleto: isQuestionarioCompleto,
                        numeroCampiMancanti: (this.numeroDiRisposteObbligatorie(sezioneAttualeQuestionario) + this.numeroDiObbligatorietaExternal(richiesta)) - this.numerodiDomandeRisposte(richiesta)
                    }
                )
            }
        }
    }

    punteggioTotaleArrotondato(punteggioSottoQuestionari) {
        // calcolo punteggio totale questionario
        let punteggioTotale = this.calcoloPunteggioTotaleQuestionario(punteggioSottoQuestionari);

        if (punteggioTotale != null) {
            let decimaliPunteggio = punteggioTotale - Math.floor(punteggioTotale);
            if (decimaliPunteggio !== 0.5) punteggioTotale = Math.round(punteggioTotale);
        }

        return punteggioTotale;
    }

    calcoloPunteggioTotaleQuestionario(punteggioSottoQuestionari) {
        let punteggioTotale = 0;
        _.forIn(punteggioSottoQuestionari[this.props.tipoQuestionario], (value, key) => {
            // non calcola il punteggio di scala braden e exton smith per psan
            if (this.props.tipoQuestionario === enumQuestionario.tipoQuestionario.PSAN && ["3","4"].includes(key)) return;
            punteggioTotale += value ?? 0;
        });
        return punteggioTotale;
    }

    modificaQuestionariParticolari(richiesta, punteggioSottoQuestionari, sezione, sezioneAttualeQuestionario) {
        switch (this.props.tipoQuestionario) {
            case enumQuestionario.tipoQuestionario.PSAN:
                this.modificaQuestionarioPSAN(richiesta, punteggioSottoQuestionari, sezione);
                break;
            case enumQuestionario.tipoQuestionario.NECPAL:
                this.modificaQuestionarioNECPAL(richiesta, sezione, sezioneAttualeQuestionario);
            break;
            default:
                break;
        }
    }


    inizzializazionePerTipoQuestionario(richiesta) {
        switch (this.props.tipoQuestionario) {
            case enumQuestionario.tipoQuestionario.NECPAL:
                this.inizializazioneNECPAL(richiesta);
            break;
            case enumQuestionario.tipoQuestionario.PSAN:
                this.inizializazionePSAN(richiesta);
            break;
            default:
                break;
        }
    }


    /**
     * funzione che ritorna il numero delle risposte obbligatorie
     * @sezioneAttualeQuestionario contiene la sezione attuale del questionario
    */
    numeroDiRisposteObbligatorie = (sezioneAttualeQuestionario) => {
        const suma2 = []
        this.questionario = {
            ...this.questionario,
            sezioni: this.questionario.sezioni.map(function (e) {
                if (sezioneAttualeQuestionario && e.id === sezioneAttualeQuestionario.id)
                    return sezioneAttualeQuestionario
                return e
            })
        }
        
        this.questionario.sezioni.forEach(function (element) {
            suma2.push(element.domande.filter(el => el.obbligatorio).length)  
        })
        return suma2.reduce((partialSum, a) => partialSum + a, 0);
    }

    /**
     * funzione che ritorna il numero delle domande rispostes
     * @richiesta richiesta aggiornata nel momento del change
    */
    numerodiDomandeRisposte = (richiesta) => {
        const suma = []
        richiesta[this.props.tipoQuestionario].sezioni.filter(el => el.domande).map(function (e) {
            suma.push(e.domande.filter(el => el.risposte && el.risposte.length).length)   
        })
        return suma.reduce((partialSum, a) => partialSum + a, 0);
    }

    /**
     * funzione che ritorna il numero delle domande obbligatorie funzione custom solo per il questionario NECPAL
     * @richiesta richiesta aggiornata nel momento del change
    */
    numeroDiObbligatorietaExternal = (richiesta) => {
        const suma = []
        richiesta[this.props.tipoQuestionario].sezioni.forEach(function (e) {
            e && e.domande.forEach(function (element) {
                const conditionRipostaDomanda9 = e?.domande.some(e => e.id === 9 && e.risposte.length>=2);
                const conditionRipostaDomanda17 = e?.domande.some(e => e.id === 17 && e.risposte.length>=2);
                const conditionRipostaDomanda19 = e?.domande.some(e => e.id === 19 && e.risposte.length>=2);
                const conditionRipostaDomanda23 = e?.domande.some(e => e.id === 23 && e.risposte.length>=2);
                const conditionRipostaDomanda29 = e?.domande.some(e => e.id === 29 && e.risposte.length>=2);

                if(
                    element?.id == 8   && element?.risposte[0]?.id == 18 && !conditionRipostaDomanda9  ||
                    element?.id == 16  && element?.risposte[0]?.id == 38 && !conditionRipostaDomanda17 ||
                    element?.id == 18  && element?.risposte[0]?.id == 46 && !conditionRipostaDomanda19 ||
                    element?.id == 22  && element?.risposte[0]?.id == 58 && !conditionRipostaDomanda23 ||
                    element?.id == 28  && element?.risposte[0]?.id == 72 && !conditionRipostaDomanda29 
                ) suma.push(1);
            });
        })
        return suma.reduce((partialSum, a) => partialSum + a, 0);
    }

    /**
     * funzione che modifica la richiesta NECPAL quando la prima domanda e valorizata a SI
     * @richiesta richiesta aggiornata nel momento del change
    */
    modificaRichiestaPrimaRispostaSiNECPAL = (richiesta) => {
        const svuotaDomande = (domande) => {
            const d = domande.map(function (el) {
                if (el.id !==1)
                    return {...el, risposte:[]}
                return el
            })  
            return d
        }

        richiesta[this.props.tipoQuestionario] = {
            ...richiesta[this.props.tipoQuestionario],
            sezioni: richiesta[this.props.tipoQuestionario]?.sezioni?.map(function (el) {
                if (el.id !== 1)
                    return {...el, domande:svuotaDomande(el.domande)}
                return el
            })   
        }
        this.questionario = {...this.questionario, sezioni: this.questionario.sezioni.filter(sesione => sesione.id === 1) };
    }

    /**
     * funzione che modifica la richiesta NECPAL quando la prima domanda e valorizata a NO
     * @richiesta richiesta aggiornata nel momento del change
    */
     modificaRichiestaPrimaRispostaNoNECPAL = (richiesta) => {
        const aggiornaDomanda = (domande, idSezione) => {
            const d = domande.map(function (el) {
                const domande = richiesta["NECPAL"]?.sezioni[idSezione]?.domande;
                const conditionRipostaDomanda4 = el.id === 5 && domande.some(e => e.id === 4 && e?.risposte[0]?.id == 8);
                const conditionRipostaDomanda6 = el.id === 7 && domande.some(e => e.id === 6 && e?.risposte[0]?.id == 13);
                const conditionRipostaDomanda8 = el.id === 9 && domande.some(e => e.id === 8 && e?.risposte[0]?.id == 18);
                const conditionRipostaDomanda11 = el.id === 12 && domande.some(e => e.id === 11 && e?.risposte[0]?.id == 27);
                const conditionRipostaDomanda14 = el.id === 15 && domande.some(e => e.id === 14 && e?.risposte[0]?.id == 33);
                const conditionRipostaDomanda16 = el.id === 17 && domande.some(e => e.id === 16 && e.risposte[0]?.id == 38);
                const conditionRipostaDomanda18 = el.id === 19 && domande.some(e => e.id === 18 && e.risposte[0]?.id == 46);
                const conditionRipostaDomanda20 = el.id === 21 && domande.some(e => e.id === 20 && e.risposte[0]?.id == 54);
                const conditionRipostaDomanda22 = el.id === 23 && domande.some(e => e.id === 22 && e.risposte[0]?.id == 58);
                const conditionRipostaDomanda24 = el.id === 25 && domande.some(e => e.id === 24 && e.risposte[0]?.id == 65);
                const conditionRipostaDomanda26 = el.id === 27 && domande.some(e => e.id === 26 && e.risposte[0]?.id == 69);
                const conditionRipostaDomanda28 = el.id === 29 && domande.some(e => e.id === 28 && e.risposte[0]?.id == 72);
                const conditionSessioneDomandeSessione4 = conditionRipostaDomanda14 || conditionRipostaDomanda16 || conditionRipostaDomanda18 || conditionRipostaDomanda20 || conditionRipostaDomanda22 || conditionRipostaDomanda24 || conditionRipostaDomanda26 || conditionRipostaDomanda28;
                if (conditionRipostaDomanda4 || conditionRipostaDomanda6 || conditionRipostaDomanda8 || conditionRipostaDomanda11 || conditionSessioneDomandeSessione4)
                    return { ...el, obbligatorio: true }
                return el
            })
            return d
        }
        this.questionario = {
            ...this.questionario,
            sezioni: this.questionario.sezioni.map(function (e, index) {
                if (e.id === 3 || e.id === 4)
                    return { ...e, domande: aggiornaDomanda(e.domande, index) }
                return e
            })
        }
    }

    /**
     * funzione che inizializa il questionario NECPAL
     * se la risposta della prima domanda del questionario en NO nasconde tutte le sessione che la prosegueno
     * @param richiesta continene l'informazione aggiornata del questionario
    */
    inizializazioneNECPAL= (richiesta) =>{
        const ripostaOneValue = richiesta[this.props.tipoQuestionario].sezioni.find(el => el.id === 1)?.domande[0]?.risposte[0]?.id === 1;
       
        if(ripostaOneValue) this.modificaRichiestaPrimaRispostaNoNECPAL(richiesta)
        else if(!ripostaOneValue) this.modificaRichiestaPrimaRispostaSiNECPAL(richiesta)
        
        if (this.props.externalChange) {
            this.props.externalChange(
                {
                    richiesta: richiesta[this.props.tipoQuestionario],
                    numeroCampiMancanti: (this.numeroDiRisposteObbligatorie() + this.numeroDiObbligatorietaExternal(richiesta)) - this.numerodiDomandeRisposte(richiesta)
                }
            )
        }
    }

    /**
     * funzione che modifica il questionario NECPAL
     * se la risposta della prima domanda del questionario en SI nasconde tutte le sessione che la prosegueno
     * @param richiesta continene linformazione aggiornata del quistionario 
     * @param sezione continiene la sessione del questionario dove e stato fatto il change
     * @sezioneAttualeQuestionario contiene la sezione attuale del questionario
    */
    modificaQuestionarioNECPAL(richiesta, sezione) {
        
        const ripostaOneValue = richiesta[this.props.tipoQuestionario].sezioni.find(el => el.id === 1)?.domande[0]?.risposte[0]?.id === 1;

        if(sezione.id ==1) {
            if(ripostaOneValue) this.questionario = getQuestionario(this.props.tipoQuestionario)
            else if(!ripostaOneValue) this.modificaRichiestaPrimaRispostaSiNECPAL(richiesta)
        }

        if (sezione.id == 3 || sezione.id == 4) {
            const svuotaDomande = (domande) => {
                const d = domande.map(function (el) {
                    const conditionRipostaDomanda4 = el.id === 5 && domande.some(e => e.id === 4 && e.risposte[0]?.id !== 8);
                    const conditionRipostaDomanda6 = el.id === 7 && domande.some(e => e.id === 6 && e.risposte[0]?.id !== 13);
                    const conditionRipostaDomanda8 = el.id === 9 && domande.some(e => e.id === 8 && e.risposte[0]?.id !== 18);
                    const conditionRipostaDomanda11 = el.id === 12 && domande.some(e => e.id === 11 && e.risposte[0]?.id !== 27);
                    const conditionRipostaDomanda14 = el.id === 15 && domande.some(e => e.id === 14 && e.risposte[0]?.id !== 33);
                    const conditionRipostaDomanda16 = el.id === 17 && domande.some(e => e.id === 16 && e.risposte[0]?.id !== 38);
                    const conditionRipostaDomanda18 = el.id === 19 && domande.some(e => e.id === 18 && e.risposte[0]?.id !== 46);
                    const conditionRipostaDomanda20 = el.id === 21 && domande.some(e => e.id === 20 && e.risposte[0]?.id !== 54);
                    const conditionRipostaDomanda22 = el.id === 23 && domande.some(e => e.id === 22 && e.risposte[0]?.id !== 58);
                    const conditionRipostaDomanda24 = el.id === 25 && domande.some(e => e.id === 24 && e.risposte[0]?.id !== 65);
                    const conditionRipostaDomanda26 = el.id === 27 && domande.some(e => e.id === 26 && e.risposte[0]?.id !== 69);
                    const conditionRipostaDomanda28 = el.id === 29 && domande.some(e => e.id === 28 && e.risposte[0]?.id !== 72);
                    const conditionSessioneDomandeSessione4 = conditionRipostaDomanda14 || conditionRipostaDomanda16 || conditionRipostaDomanda18 || conditionRipostaDomanda20 || conditionRipostaDomanda22 || conditionRipostaDomanda24 || conditionRipostaDomanda26 || conditionRipostaDomanda28;
                    if (conditionRipostaDomanda4 || conditionRipostaDomanda6 ||conditionRipostaDomanda8 || conditionRipostaDomanda11|| conditionSessioneDomandeSessione4)
                        return { ...el, risposte: [] }
                    return el
                })
                return d
            }
    
            richiesta[this.props.tipoQuestionario] = {
                ...richiesta[this.props.tipoQuestionario],
                sezioni: richiesta[this.props.tipoQuestionario]?.sezioni?.map(function (el) {
                    return { ...el, domande: svuotaDomande(el.domande) }
                })
            }
        }

    }

    /**
     * funzione che inizializa il questionario PSAN
     * se la risposta della domanda con id 25 della sezione 7 del questionario e uguale a 73 renderizo la sezione 4 altrimenti la sezione 3
     * sezione 3: Scala di Braden
     * sezione 4: Indice di Exton Smith per la valutazione del rischio di decubiti
     * @param richiesta continene l'informazione aggiornata del questionario
    */
    inizializazionePSAN(richiesta) { 
        const ripostaOneValue = richiesta[this.props.tipoQuestionario].sezioni.find(el => el.id === 6)?.domande[0]?.risposte[0]?.id;
        if(!ripostaOneValue) {
            this.questionario = {
                ...this.questionario,
                sezioni: this.questionario.sezioni?.filter(sez => sez.id !== 3 && sez.id !== 4)
            }
        } else {
            const idSezioneDaScludere = ripostaOneValue === 73 ? 4 : 3;
            this.questionario = {
                ...this.questionario,
                sezioni: this.questionario.sezioni?.filter(sez => sez.id !== idSezioneDaScludere)
            }
        }
    }

    /**
     * funzione che viene chiamata nel change delle risposte del questionario PSAN
     * se la risposta della domanda con id 25 della sezione 7 del questionario e uguale a 73 renderizo la sezione 4 altrimenti la sezione 3
     * sezione 3: Scala di Braden
     * sezione 4: Indice di Exton Smith per la valutazione del rischio di decubiti
     * @param richiesta continene l'informazione aggiornata del questionario
     * @param idSezioneDaScludere id della sezione da nascondere
    */
    svuotaRichiestaForPSAN(richiesta, idSezioneDaScludere) {
        let newQuestionario = getQuestionario(this.props.tipoQuestionario);
        richiesta[this.props.tipoQuestionario] = {
            ...richiesta[this.props.tipoQuestionario],
            sezioni: richiesta[this.props.tipoQuestionario]?.sezioni?.map(function (el) {
                if(idSezioneDaScludere === el.id) return { ...el, domande:[] }
                else return el
            })
        }

        this.questionario = {
            ...newQuestionario,
            sezioni: newQuestionario.sezioni?.filter(sez => sez.id !== idSezioneDaScludere)
        }

    }

    modificaQuestionarioPSAN(richiesta, punteggioSottoQuestionari, sezione) {
        const {domande, id} = sezione;
        const idRisposta = domande[0]?.risposte[0]?.id;

        if(id === 6 && idRisposta === 73) {
            this.svuotaRichiestaForPSAN(richiesta, 4, 3)
        } else if (id === 6 && idRisposta === 74) {
            this.svuotaRichiestaForPSAN(richiesta, 3, 4)
        }

        // funzione per valorizzare sotto questionario VPIA
        const idSezioneExtonSmith = 3;
        const idSezioneScalaBraden = 4;
        const idSezioneVPIA = 5;

        let valorizzaVPIA = (domande) => {
            let indexSezioneVPIA = this.getIndexSezioneRichiesta(richiesta, idSezioneVPIA);
            richiesta[this.props.tipoQuestionario].sezioni[indexSezioneVPIA] = {
                id: idSezioneVPIA,
                domande: domande
            };
            punteggioSottoQuestionari[this.props.tipoQuestionario][idSezioneVPIA] = 10;
        };

        let risposteValorizzate = sezione.domande.some(domanda => domanda.risposte?.length > 0);
        let valorizzatoExtonSmith = sezione.id === idSezioneExtonSmith;
        let valorizzatoScalaBraden = sezione.id === idSezioneScalaBraden;
        if (risposteValorizzate && valorizzatoExtonSmith) {
            let indexSezioneScaleBraden = this.getIndexSezioneRichiesta(richiesta, idSezioneScalaBraden);
            richiesta[this.props.tipoQuestionario].sezioni[indexSezioneScaleBraden] = {
                id: idSezioneScalaBraden,
                domande: []
            };
            delete punteggioSottoQuestionari[this.props.tipoQuestionario][idSezioneScalaBraden];
            if (punteggioSottoQuestionari[this.props.tipoQuestionario][idSezioneExtonSmith] <= 10) {
                valorizzaVPIA([{id: 24, risposte: [{id:70}]}]);
            } else {
                valorizzaVPIA([])
            }
        } else if (risposteValorizzate && valorizzatoScalaBraden) {
            let indexSezioneExtonSmith = this.getIndexSezioneRichiesta(richiesta, idSezioneExtonSmith);
            richiesta[this.props.tipoQuestionario].sezioni[indexSezioneExtonSmith] = {
                id: idSezioneExtonSmith,
                domande: []
            };
            delete punteggioSottoQuestionari[this.props.tipoQuestionario][idSezioneExtonSmith];
            if (punteggioSottoQuestionari[this.props.tipoQuestionario][idSezioneScalaBraden] <= 10) {
                valorizzaVPIA([{id: 24, risposte: [{id:70}]}]);
            } else {
                valorizzaVPIA([])
            }
        }
    }

    getIndexSezioneRichiesta(richiesta, idSezione) {
        return richiesta[this.props.tipoQuestionario].sezioni.findIndex(sezione => sezione.id === idSezione);
    }

    validaQuestionario = (richiesta, sezioneAttualeQuestionario = null) => {
        const tipoQuestionario = this.props.tipoQuestionario;
        let isSurveyValid;

        if (this.props.obbligatorieta?.forceRequiredCompilation && !!this.props.obbligatorieta?.sectionIdListRequired?.length) {
            const sezioni = richiesta?.sezioni?.filter(s => !!this.props.obbligatorieta?.sectionIdListRequired?.includes(s.id));
            isSurveyValid = areAllSectionValorised(sezioni, tipoQuestionario);
        } else {
            isSurveyValid = richiesta.sezioni.every(sezioneRichiesta => {
                let datiSezione;
                if (sezioneAttualeQuestionario != null && sezioneAttualeQuestionario.id === sezioneRichiesta.id && this.props.obbligatorieta == null) {
                    datiSezione = sezioneAttualeQuestionario;
                } else {
                    datiSezione = getSezioneQuestionario(tipoQuestionario, sezioneRichiesta.id);
                }

                if (this.props.obbligatorieta?.forceRequiredCompilation) {
                    return datiSezione.domande.every(domanda => {
                        if (this.props.obbligatorieta.surveyType === enumQuestionario.tipoQuestionario.PMOB && (domanda.id === 2 || domanda.id === 3)) {
                            const risposteSezioneDeambulazione = sezioneRichiesta.domande.find(d => d.id === 2)?.risposte?.map(r => r.id) ?? [];
                            const risposteSezioneUsoCarrozzina = sezioneRichiesta.domande.find(d => d.id === 3)?.risposte?.map(r => r.id) ?? [];
                            return !(risposteSezioneDeambulazione.some(id => id === 10) && risposteSezioneUsoCarrozzina.length === 0);
                        } else {
                            let indiceDomandaRichiesta = sezioneRichiesta.domande.findIndex(infoDomanda => infoDomanda.id === domanda.id);
                            return indiceDomandaRichiesta > -1;
                        }
                    });
                } else {
                    let sezioneRichiestaTrovata = richiesta.sezioni.find(sezioneRichiesta => sezioneRichiesta.id === datiSezione.id);
                    return areCampiObbligatoriSezioneCompilati(sezioneRichiestaTrovata, datiSezione);
                }
            });
        }

        return isSurveyValid;
    }

    controllaRispostePresenti = () => {
        return this.state.richiestaAggiornata[this.props.tipoQuestionario].sezioni.some(sezioneRichiesta => {
            return sezioneRichiesta.domande.length > 0;
        });
    }

    aggiornaQuestionario = (richiesta) => {
        this.setState({
            richiestaAggiornata: richiesta
        }, () => {
            punteggiIntermedi[this.props.tipoQuestionario] = null;
            this.setState({
                areRispostePresenti: this.controllaRispostePresenti(),
                isQuestionarioCompleto: this.validaQuestionario(_.cloneDeep(this.state.richiestaAggiornata[this.props.tipoQuestionario])),
                esegueSalvaBozza:true
            })
        });

        if (this.props.externalChange) {
            this.props.externalChange(
                {
                    richiesta: richiesta[this.props.tipoQuestionario],
                }
            )
            this.inizzializazionePerTipoQuestionario(richiesta);
        }
    }

    renderQuestionari = () => {
        let questionari = []

        if (this.questionario && Array.isArray(this.questionario.sezioni) && this.questionario.sezioni.length > 0) {
            if (this.questionario.titolo) {
                const titolo = this.props.titoloRequired ? <span className={styles.descrizioneQuestionario}>{this.questionario.titolo}<span className={styles.requiredFields.concat(" pl-2")}>*</span></span>
                    : <span className={styles.descrizioneQuestionario}>{this.questionario.titolo}</span>
                
                const showSvuotaQuestionario = !!(this.questionario.showSvuotaQuestionario && !this.props.disabilitato && this.state.richiestaAggiornata[this.props.tipoQuestionario].sezioni.some(e => e.domande.find(f => f.risposte.length)))
                
                questionari.push((
                    <div className={"row justify-content-center mb-2"}>
                        {titolo}
                        { showSvuotaQuestionario && <SvuotaQuestionario
                            tipoQuestionario={this.props.tipoQuestionario}
                            richiestaAggiornata={this.state.richiestaAggiornata}
                            aggiornaQuestionario={(richiestaAggiornata) => this.aggiornaQuestionario(richiestaAggiornata)} 
                            salvaBozza={this.props.salvaBozza} />}
                    </div>
                ));
            }   

            if (this.questionario.sottotitolo) {
                questionari.push((
                    <div className={"row"}>
                        <div className={"col text-center mb-3"}>
                            <span className={styles.descrizioneQuestionario}>{this.questionario.sottotitolo}</span>
                        </div>
                    </div>
                ));
            }

            if (this.questionario.indicazione) {
                questionari.push((
                    <div className={"row"}>
                        <div className={"col"}>
                            <p className={styles.descrizioneQuestionario}>{this.questionario.indicazione}</p>
                        </div>
                    </div>
                ));
            }
            
            if (this.props.labelSezioneObbligatoria) {
                questionari.push((
                    <div className={"row"}>
                        <div className={"col-2 offset-10"}>
                            <strong className={styles.requiredFields}>* sezione obbligatoria</strong>
                        </div>
                    </div>
                ));
            }

            let richiesta = _.cloneDeep(this.state.richiestaAggiornata);
            let sezioniRichiesta = richiesta[this.props.tipoQuestionario]?.sezioni;
            let punteggioRichiesta = null;
            // todo verificare condizione per rendere solo visualizzabile il questionario
            let disabled = this.props.disabilitato ? this.props.disabilitato: false;

            this.questionario.sezioni.forEach((sezione) => {
                let sezioneRichiesta = sezioniRichiesta ? sezioniRichiesta.find(sez => sez.id === sezione.id) : null;
                // si passa la props punteggioRichiesta solo alle sezioni (sotto-questionari) che hanno solo il punteggio totale del questionario
                // (es. PSAN, TINETTI)
                if (sezioneRichiesta && this.isPunteggioRichiestaValorizzabile(sezioneRichiesta)) {
                    punteggioRichiesta = this.calcoloPunteggioTotaleQuestionario(punteggiIntermedi)
                }
                // controllo se il questionario deve essere obbligatorio o meno
                let isQuestionarioObbligatorio = this.isQuestionarioObbligatorio(sezione);

                questionari.push((
                    <div className={"row"}>
                        <div className={"col"}>
                            <Questionario
                                tipoQuestionario={this.props.tipoQuestionario}
                                sezioneRichiesta={sezioneRichiesta}
                                punteggioRichiesta={punteggioRichiesta}
                                changeDatiQuestionario={this.changeDatiQuestionario}
                                disabilitato={disabled}
                                isQuestionarioCompleto={this.state.isQuestionarioCompleto && this.canShowAndStorePunteggio(this.state.richiestaAggiornata)}
                                isQuestionarioObbligatorio={isQuestionarioObbligatorio}
                                obbligatorieta={this.props.obbligatorieta}
                                sessoPaziente={this.props.sessoPaziente}
                                showPunteggioRisposta={this.questionario.showPunteggioRisposta}
                            />
                        </div>
                    </div>
                ))
            })

        }

        return (
            <Fragment>
                {questionari}
            </Fragment>
        )
    }

    isQuestionarioObbligatorio(sezione) {
        const ID_SEZIONE_SCREENING_MNA = 1;
        const ID_SEZIONE_SCREENING_VALUTAZIONE_GLOBALE = 2;

        if (this.props.obbligatorieta?.surveyType === this.props.tipoQuestionario) {
            if (this.props.obbligatorieta?.sectionIdListRequired?.length > 0) return !!this.props.obbligatorieta?.sectionIdListRequired?.includes(sezione.id);
            if (this.props.obbligatorieta?.forceRequiredCompilation) return true;
        }

        return this.props.tipoQuestionario === enumQuestionario.tipoQuestionario.MNA
            && sezione.id === ID_SEZIONE_SCREENING_VALUTAZIONE_GLOBALE
            && punteggiIntermedi?.[this.props.tipoQuestionario]?.[ID_SEZIONE_SCREENING_MNA] <= 11;
    }

    isPunteggioRichiestaValorizzabile(sezioneRichiesta) {
        let sezionePunteggioTotalePSAN = this.props.tipoQuestionario === enumQuestionario.tipoQuestionario.PSAN && sezioneRichiesta.id === 7;
        let sezionePunteggioTotaleTINETTI = this.props.tipoQuestionario === enumQuestionario.tipoQuestionario.TINETTI && sezioneRichiesta.id === 3;
        let sezionePunteggioTotaleMNA = this.props.tipoQuestionario === enumQuestionario.tipoQuestionario.MNA && sezioneRichiesta.id === 3;
        let sezionePunteggioTotaleNOPPAIN = this.props.tipoQuestionario === enumQuestionario.tipoQuestionario.NOPPAIN && sezioneRichiesta.id === 5;

        return (sezionePunteggioTotalePSAN || sezionePunteggioTotaleTINETTI || sezionePunteggioTotaleMNA || sezionePunteggioTotaleNOPPAIN)
            && this.state.isQuestionarioCompleto && this.canShowAndStorePunteggio(this.state.richiestaAggiornata);
    }

    canShowAndStorePunteggio = (richiesta) => {
        const tipoQuestionario = this.props.tipoQuestionario;
        let isAdditionalConditionValid = true;
        if (tipoQuestionario === enumQuestionario.tipoQuestionario.PSAN) {
            let sezioni = _.cloneDeep(richiesta?.[tipoQuestionario]?.sezioni);
            sezioni = sezioni?.filter(s => [1, 2, 5].includes(s.id));
            isAdditionalConditionValid = areAllSectionValorised(sezioni, tipoQuestionario);
        }

        return isAdditionalConditionValid;
    }

    render() {
        if (this.state.tornaIndietro) { 
            let pathname = Utils.isStateRichiesta(this.props.pageState)
                ? routepath.inserimento_richiesta.concat(routepath.compila_scheda_paziente)
                : routepath.valutazione_multidimensionale.concat(routepath.completa_valutazione_multidimensionale);
            return (
                <Redirect to={{
                    pathname: this.props.tornaIndietro ? this.props.tornaIndietro : this.props.redirectPage ?? pathname,
                    uuidRichiesta: this.props.richiesta.id,
                    pageState: this.props.pageState,
                    tipologia: this.props.richiesta?.tipologia,
                    stato: this.props.richiesta?.stato,
                    valutazionePrecedente: (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) ? getValutazionePrecedente(this.props.richiesta) : null,
                    showOnlyRequiredFields: this.props.showOnlyRequiredFields,
                    firstPageName: this.props.firstPageName,
                    previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields
                }}
                />
            )
        }

        return (
            <Fragment>
                {!this.props.hiddenScroll && <ScrollTop />}
                <div className={styles.containerSezioneQuestionari.concat(" m-5")}>
                    {this.renderQuestionari()}
                </div>
                {!this.props.hiddenCtaContainer
                    && <BottoniQuestionario
                        indietro={() => this.setState({tornaIndietro: true})}
                        richiesta={this.props.richiesta}
                        richiestaAggiornata={this.state.richiestaAggiornata}
                        tipoQuestionario={this.props.tipoQuestionario}
                        isQuestionarioCompleto={this.state.isQuestionarioCompleto}
                        sessoPaziente={this.props.sessoPaziente}
                        pathRichiestaQuestionario={this.props.pathRichiestaQuestionario}
                        areRispostePresenti={this.state.areRispostePresenti}
                        pageState={this.props.pageState}
                        questionario={_.cloneDeep(this.questionario)}
                        punteggiIntermedi={punteggiIntermedi}
                        esegueSalvaBozza={this.state.esegueSalvaBozza}
                        resetSalvaBozza={() => this.setState({esegueSalvaBozza: false})}
                        canStorePunteggioAndValutazione={this.canShowAndStorePunteggio(this.state.richiestaAggiornata)}
                        disabilitato={this.props.disabilitato}
                        isMenuItemClicked={!!this.state.isMenuItemClicked}
                    />
                }
            </Fragment>
        )
    }
}

CompilaQuestionario.propTypes = propTypes;
CompilaQuestionario.defaultProps = defaultProps;
