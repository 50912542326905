import * as _ from "lodash";
import enumRichiesta from "../enum/enumRichiesta"

export default class SessionRequest {

    static data = {};
    static idRichiedente = null;

    static getSessionObjectFor(request) {
        const id = request?.id;
        let object = SessionRequest.data[id];
        if (!object) {
            SessionRequest.data[id] = object = _.cloneDeep(sessionObjectInit);
        }

        return object;
    }

    static disposeSessionFor(requestId) {
        delete SessionRequest.data[requestId];
    }

    static getInitObject(jsonPath) {
        let init = sessionObjectInit;
        if (jsonPath != null) jsonPath.split('.').forEach(key => init = init[key]);
        return _.cloneDeep(init);
    }

    static setIdRichiedente(idRichiedente) {
        SessionRequest.idRichiedente = idRichiedente;
    }
}

export const defaultAnagrafePaziente = {
    datiGenerali: {
        cognome: null,
        nome: null,
        sesso: null,
        dataNascita: null,
        comune: "",
        provincia: "",
        nazione: "",
        cittadinanza: null,
        titoloStudio: null,
        madrelingua: false,
        livello: null,
        descrizione: [],
        telefono: null,
        cellulare: null,
        email: null,
        categoria: null,
        identificativoUtente: null,
        tesseraSanitaria: null,
        ilPazienteEInformatoDellaDomanda: null,
        tipoIdentificativoUtente: null,
        identificativoEventoAssistenzialeDiCD: null
    },
    mmgPlsDelPaziente: {
        id: null,
        cognome: null,
        nome: null,
        identificativoUtente: null,
        telefono: null,
        cellulare: null,
        email: null
    },
    residenza: {
        idElemento: null,
        indirizzo: null,
        numero: null,
        comune: "",
        provincia: "",
        cap: null,
        nazione: "",
        aulss: null,
        distretto: null,
        residenzaDiversaDaDomicilio: null
    },
    domicilio: {
        idElemento: null,
        indirizzo: null,
        numero: null,
        comune: "",
        provincia: "",
        cap: null,
        aulss: null,
        distretto: null,
        domicilioDiversoDaDomicilioCure: null
    },
    domicilioDiCura: {
        idElemento: null,
        indirizzo: null,
        numero: null,
        comune: "",
        provincia: "",
        cap: null,
        aulss: null,
        distretto: null
    },
    settingDiProvenienza: {
        tipologiaSetting: null,
        domicilio: null,
        cureDomiciliariAttive: null,
        strutturaIntermedia: null,
        ospedale: null,
        altroSetting: null
    },
    esenzioni: [],
    invalidita: {
        presenzaInvaliditaRiconosciuta: false,
        presentataDomanda: false
    },
    padre: {
        id: null,
        nome: null,
        cognome: null,
        identificativoUtente: null,
        isPadreIndicatoComeRiferimentoPerPaziente: null,
        indirizzo: null,
        numero: null,
        telefono: null,
        email: null,
        madrelingua: null,
        livello: null,
        descrizione: []
    },
    madre: {
        id: null,
        nome: null,
        cognome: null,
        identificativoUtente: null,
        isMadreIndicataComeRiferimentoPerPaziente: null,
        indirizzo: null,
        numero: null,
        telefono: null,
        email: null,
        madrelingua: null,
        livello: null,
        descrizione: []
    },
    tutore: [],
    amministratoreDiSostegno: [],
    fiduciario: {
        id: null,
        cognome: null,
        nome: null,
        identificativoUtente: null,
        indirizzo: null,
        numero: null,
        telefono: null,
        email: null,
        madrelingua: null,
        livello: null,
        descrizione: []
    }
}

export const bisogniPrestazioniAss = {
    "Progettualità assistenziale": [
        {
            codice: "1",
            descrizione: "Valutazione monoprofessionale",
            checked: false
        },
        {
            codice: "2",
            descrizione: "Valutazione multidisciplinare",
            checked: false
        }
    ],
    "Rischio infettivo": [
        {
            codice: "3",
            descrizione: "Interventi di educazione terapeutica teorica e pratica / counselling (almeno 15 minuti)",
            checked: false
        },
        {
            codice: "4",
            descrizione: "Assistenza per rischio infettivo e gestione procedure di prevenzione",
            checked: false
        }
    ],
    "Respiratori": [
        {
            codice: "5",
            descrizione: "Interventi di educazione terapeutica teorica e pratica / counselling (almeno 15 minuti)",
            checked: false
        },
        {
            codice: "6",
            descrizione: "Gestione O2 terapia",
            checked: false
        },
        {
            codice: "7",
            descrizione: "Gestione tracheostomia",
            checked: false
        },
        {
            codice: "8",
            descrizione: "Gestione ventilazione invasiva",
            checked: false
        },
        {
            codice: "9",
            descrizione: "Gestione broncoaspirazione / drenaggio posturale",
            checked: false
        }
    ],
    "Alimentazione": [
        {
            codice: "10",
            descrizione: "Interventi di educazione terapeutica teorica e pratica / counselling (almeno 15 minuti)",
            checked: false
        },
        {
            codice: "11",
            descrizione: "Gestione alimentazione per OS",
            checked: false
        },
        {
            codice: "12",
            descrizione: "Gestione S.N.G.",
            checked: false
        },
        {
            codice: "13",
            descrizione: "Gestione P.E.G.",
            checked: false
        },
        {
            codice: "14",
            descrizione: "Gestione alimentazione parenterale",
            checked: false
        }
    ],
    "Eliminazione urinaria/intestinale": [
        {
            codice: "15",
            descrizione: "Interventi di educazione terapeutica teorica e pratica / counselling (almeno 15 minuti)",
            checked: false
        },
        {
            codice: "16",
            descrizione: "Gestione cateterismo vescicale",
            checked: false
        },
        {
            codice: "17",
            descrizione: "Gestione urostomia",
            checked: false
        },
        {
            codice: "18",
            descrizione: "Dialisi",
            checked: false
        },
        {
            codice: "19",
            descrizione: "Clistere evacuativo",
            checked: false
        },
        {
            codice: "20",
            descrizione: "Gestione stomia (ileo/colon)",
            checked: false
        }
    ],
    "Integrità cutanea": [
        {
            codice: "21",
            descrizione: "Interventi di educazione terapeutica teorica e pratica / counselling (almeno 15 minuti)",
            checked: false
        },
        {
            codice: "22",
            descrizione: "Trattamenti lesioni decubito I e II grado",
            checked: false
        },
        {
            codice: "23",
            descrizione: "Trattamenti lesioni decubito III e IV grado",
            checked: false
        },
        {
            codice: "24",
            descrizione: "Trattamenti lesioni decubito III e IV grado multiple",
            checked: false
        },
        {
            codice: "25",
            descrizione: "Trattamenti ulcere trofiche",
            checked: false
        },
        {
            codice: "26",
            descrizione: "Trattamenti ferite chirurgiche",
            checked: false
        }
    ],
    "Riposo/sonno": [
        {
            codice: "27",
            descrizione: "Assistenza per alterazione del ritmo sonno veglia",
            checked: false
        }
    ],
    "Dolore": [
        {
            codice: "28",
            descrizione: "Gestione dolore (oncologico e non oncologico)",
            checked: false
        }
    ],
    "Terminalità": [
        {
            codice: "29",
            descrizione: "Assistenza relativa allo stato di terminalità oncologica",
            checked: false
        },
        {
            codice: "30",
            descrizione: "Assistenza relativa allo stato di terminalità non oncologica",
            checked: false
        }
    ],
    "Stress del Caregiver": [
        {
            codice: "31",
            descrizione: "Supporto al caregiver (compreso supporto al lutto)",
            checked: false
        }
    ],
    "Alterazione della relazione, del coping e della tolleranza allo stress": [
        {
            codice: "32",
            descrizione: "Supporto al paziente",
            checked: false
        }
    ],
    "Procedure diagnostiche": [
        {
            codice: "33",
            descrizione: "Interventi di educazione terapeutica teorica e pratica / counselling (almeno 15 minuti)",
            checked: false
        },
        {
            codice: "34",
            descrizione: "Rilevazione parametri vitali e fisici",
            checked: false
        },
        {
            codice: "35",
            descrizione: "Prelievo venoso (non occasionale)",
            checked: false
        },
        {
            codice: "36",
            descrizione: "Prelievo materiale organico per microbiologia",
            checked: false
        },
        {
            codice: "37",
            descrizione: "Prelievo arterioso per emogasanalisi",
            checked: false
        },
        {
            codice: "38",
            descrizione: "E.C.G.",
            checked: false
        },
        {
            codice: "39",
            descrizione: "Telemetria",
            checked: false
        }
    ],
    "Procedure terapeutiche": [
        {
            codice: "40",
            descrizione: "Interventi di educazione terapeutica teorica e pratica / counselling (almeno 15 minuti)",
            checked: false
        },
        {
            codice: "41",
            descrizione: "Terapia intramuscolare e sottocutanea",
            checked: false
        },
        {
            codice: "42",
            descrizione: "Gestione C.V.C. / Porth-cath",
            checked: false
        },
        {
            codice: "43",
            descrizione: "Gestione terapia infusiva periferica",
            checked: false
        },
        {
            codice: "44",
            descrizione: "Gestione cateterismo peridurale",
            checked: false
        },
        {
            codice: "45",
            descrizione: "Gestione terapia con microinfusori / elastomeri",
            checked: false
        },
        {
            codice: "46",
            descrizione: "Trasfusioni",
            checked: false
        }
    ],
    "Disabilità / handicap con potenziale residuo": [
        {
            codice: "47",
            descrizione: "Prescrizione, addestramento, collaudo ausili",
            checked: false
        },
        {
            codice: "48",
            descrizione: "Trattam. riabilitativo post acuto paz. neurologico",
            checked: false
        },
        {
            codice: "49",
            descrizione: "Trattam. riabilitativo post acuto paz. ortopedico",
            checked: false
        },
        {
            codice: "50",
            descrizione: "Trattam. riabilitativo post acuto paz. altre patologie",
            checked: false
        },
        {
            codice: "51",
            descrizione: "Trattamento patologie della comunicazione",
            checked: false
        },
        {
            codice: "52",
            descrizione: "Trattamento riattivativo di sindr. da allettamento recente",
            checked: false
        },
        {
            codice: "53",
            descrizione: "Cicli riabilitativi di mantenimento in presenza di disabilità per prevenzione di complicanze",
            checked: false
        },
        {
            codice: "54",
            descrizione: "Educazione ed addestramento",
            checked: false
        }
    ],
    "Disabilità / handicap": [
        {
            codice: "55",
            descrizione: "Supervisione continua",
            checked: false
        },
        {
            codice: "56",
            descrizione: "Assistenza nelle ADL (bagno, igiene, vestirsi, mangiare, trasferimenti)",
            checked: false
        },
        {
            codice: "57",
            descrizione: "Assistenza nelle iADL (pulizia casa, acquisti, preparazione pasti, lavanderia)",
            checked: false
        },
        {
            codice: "58",
            descrizione: "Accompagnamento/trasporti fuori casa",
            checked: false
        }
    ]
}

const sessionObjectInit = {
    anagrafePaziente: _.cloneDeep(defaultAnagrafePaziente),
    situazioneSociale: {
        datiRelazioniFamiliari: {
            statoCivile: null,
            numeroComponentiNucleoFamiliare: null,
            figli: null
        },
        datiBenessereSociale: {
            occupazioneBenessereSociale: null,
            disponibilitaEconomicaMensileAssistito: null
        },
        datiAssistenzaSociale: {
            assistenzaSocialeAttiva: null,
            cognome: null,
            nome: null,
            telefono: null,
            email: null
        },
        parentiTenutiAgliAlimenti: {
            presenzaParentiTenutiAgliAlimenti: null,
            parenti: []
        },
        personaDiRiferimento: {
            presenzaPersonaDiRiferimento: null,
            personeDiRiferimento: []
        },
        datiValutazioneReteSociale: {
            profiloSocioAssistenziale: null,
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null
        },
        assistenteNonFamiliare: [],
        datiContributiEconomici: {
            contributiEconomici: null,
            tipologia: []
        },
        datiServiziAllaPersonaAttiviOInCorsoDiAttivazione: { //VMD
            serviziAttiviOInCorso: false,
            serviziIgieneCasa: null,
            serviziIgienePersonale: null,
            serviziPastoDomicilio: null,
            serviziEducativiDomicilio: null,
            servizioTelesoccorso: null,
            servizioTrasporto: null,
            tipologiaTrasporto: [],
            inserimentoCentroDiurno: null
        },
    },
    parametriVitali: {
        datiParametri: {
            respirazioneFrequenza: null,
            respirazioneDataMisurazione: null,
            respirazioneCondizioneRilevata: null,
            respirazioneDaAttenzionare: null,

            spo2: null,
            spo2OssigenoTerapia: null,
            spo2DataMisurazione: null,
            spo2CondizioneRilevata: null,
            spo2DaAttenzionare: null,

            pressioneArteriosaSistolica: null,
            pressioneArteriosaDiastolica: null,
            pressioneDataMisurazione: null,
            pressioneCondizioneRilevata: null,
            pressioneDaAttenzionare: null,

            frequenzaCardiaca: null,
            frequenzaCardiacaDataMisurazione: null,
            frequenzaCardiacaCondizioneRilevata: null,
            frequenzaCardiacaDaAttenzionare: null,

            temperatura: null,
            temperaturaDataMisurazione: null,
            temperaturaCondizioneRilevata: null,
            temperaturaDaAttenzionare: null,

            intensitaDolore: null,
            dolore: null,
            doloreDataMisurazione: null,
            doloreCondizioneRilevata: null,
            doloreDaAttenzionare: null,
            dolereSede: null,
        }
    },
    valutazioneBisogniPalliativi: {
        NECPAL: null,
    },
    determinantiDiSalute: {
        datiConsumoTabacco: {
            consumoTabacco: null,
            isConsumoTabaccoDeterminantiSalute: null,
            punteggioTestCat: null,
            livelloImpatto: null,
            punteggioTestMmrc: null,
            livelloDispnea: null,
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            punteggioTestFagerstrom: null,
            livelloDipendenzaNicotina: null,
            punteggioTestMondor: null,
            probabilitaDiSmettere: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        alcool: {
            consumoDiAlcolici: null,
            nBicchieriGiorno: null,
            nBicchieriSettimana: null,
            condizioneRilevata: null,
            AUDIT: null,
            AUDITC: null,
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiDroghe: {
            consumoDroghe: null,
            tipologia: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null,
            dataValutazione: null
        },
        datiAlimentazione: {
            altezza: null,
            peso: null,
            bmi: null,
            statoBmi: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null,
            dataValutazione: null
        },
        datiAttivitaFisicaSedentarieta: {
            PASSI: null,
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiNoteERegoleOsservateDaCulturaEReligione: {
            restrizioniReligioneCultura: null,
            tipoRestrizioni: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null,
            dataValutazione: null
        }
    },
    anamnesiFisiologica: {
        statoFunzionale: {
            PADL: null,
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiGradoDiMobilita: {
            PMOB: null,
            statoDopoValutazione: null,
            dataValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiSensorioUdito: {
            esitoValutazioneUditoOrecchio: null,
            alterazione: [],
            dispositiviPresenti: [],
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiStatoComportamentale: {
            problemiStatoComportamentale: null,
            dispositiviPresenti: [],
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiStatoCognitivo: {
            PCOG: null,
            statoDopoValutazione: null,
            dataValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiComunicazione: {
            alterazioniLettura: null,
            listaAlterazioni: [],
            tipoAlterazione: [],
            linguaggioComprensione: null,
            causeLinguaggioComprensione: [],
            linguaggioProduzione: null,
            causeLinguaggioProduzione: [],
            dispositivi: [],
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null
        },
        datiNecessitaDiAssistenzaSanitaria: {
            interpretazionePunteggioScalaDiBraden: null,
            PSAN: null,
            statoDopoValutazione: null,
            dataValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiIADL: {
            valutazioneNecessaria: null,
            IADL: null,
            statoDopoValutazione: null,
            dataValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiSensorioVista: {
            esitoValutazioneVista: null,
            alterazione: [],
            dispositivi: [],
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiSensorioDolore: {
            valutazioneEffettuata: null,
            mantenutaSensibilitaTattile: null,
            mantenutaSensibilitaTermica: null,
            mantenutaSensibilitaDolorifica: null,
            intensitaDolore: null,
            dolore: null,
            sogliaDelDolore: null,
            NOPPAIN: null,
            condizioneRilevata: null,
            esitoValutazioneDolore: null,
            sede: null,
            durataDolore: null,
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiIdratazione: {
            abitudiniAssunzioneLiquidi: null,
            statoDisidratazione: null,
            limitazioniPrescritteIntroitoLiquidi: null,
            limitazioni: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiInformazioniGenerali: {
            natoDaParto: null,
            note: null,
            sviluppoPsicosomatico: null,
            malattieEsantematiche: [],
            vaccinazioniEffettuate: []
        },
        donna: {
            menarca: null,
            etaMenarca: null,
            mestruazioni: null,
            gravidanzePrecedenti: null,
            partiATermine: null,
            figliVivi: null,
            abortiSpontanei: null,
            abortiIndotti: null,
            gravidanzaInAtto: null,
            menopausa: null,
            etaMenopausa: null,
            fisiologica: null,
            ultimoPapTest: null,
            esitoPapTest: null,
            noteUltimoPapTest: null,
            ultimaVisitaGinecologica: null,
            noteUltimaVisitaGinecologica: null,
            ultimaMammografia: null,
            noteUltimaMammografia: null,
            gravidanzaInAttoProposta: null,
            esitoPapTestNote: null,
            amenorrea: null,
            dismenorrea: null,
            quantita: null,
            durata: null,
            dataUltimaMestruazione: null,
            note: null,
            noteGravidanza: null
        },
        uomo: {
            ultimaVisitaUrologica: null,
            noteUltimaVisitaUrologica: null,
            ultimoEsamePSA: null,
            esitoPSA: null,
            noteEsitoPSA: null
        },
        appetito: {
            isModificheAppetito: null,
            tipologiaModifica: null,
            isNausea: null,
            isVomito: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null,
            dataValutazione: null
        },
        alvoEliminazioneFecale: {
            isRegolare: null,
            specificaRegolare: null,
            tipologiaAlterazione: null,
            isTurbe: null,
            tipologiaTurbe: [],
            isRitenzioneFeci: null,
            isUsoLassativo: null,
            tipologiaLassativo: null,
            isUsoMicroclismi: null,
            tipologiaMicroclismi: null,
            isIncontinenza: null,
            tipologiaIncontinenza: null,
            dispositiviPresenti: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        datiRespiro: {
            presenzaAlterazioni: null,
            tipologia: [],
            mMRC: null,
            livelloDispnea: null,
            condizioneRilevataDispnea: null,
            daAttenzionareDispnea: null,
            propostaAttivitaSuggerimentiDispnea: null,
            autonomiaGestionePazienteDispnea: null,
            autonomiaGestioneCaregiverDispnea: null,
            tosse: null,
            tipologiaTosse: null,
            condizioneRilevataTosse: null,
            daAttenzionareTosse: null,
            propostaAttivitaSuggerimentiTosse: null,
            autonomiaGestionePazienteTosse: null,
            autonomiaGestioneCaregiverTosse: null,
            dataValutazione: null
        },
        datiAderenzaAllaTerapia: {
            testUsato: null,
            MMAS4: null,
            MMAS8: null,
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        sensorioOlfatto: {
            esitoValutazioneOlfatto: null,
            alterazione: [],
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        sensorioGusto: {
            esitoValutazioneOlfatto: null,
            alterazione: [],
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        sensorioTatto: {
            esitoValutazioneTatto: null,
            alterazione: [],
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiRitmoSonnoVeglia: {
            ritmo: null,
            oreDormite: null,
            riposiPomeridiani: null,
            insonnia: null,
            usoFarmaciPerDormire: null,
            farmaciPerDormire: [],
            valutazioniAnomalieSonno: [],
            sintomiCorrelatiAnomalieSonno: [],
            possibiliCauseAlterazioniSonno: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        sensorioEquilibrio: {
            scaleRegionaliLatoOspedale: null,
            TINETTI: null,
            alterazione: [],
            statoDopoValutazione: null,
            dataValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiDiuresi: {
            isRegolare: null,
            numeroMinzioni: null,
            tipologieAlterazione: [],
            durataAlterazioneDataDa: null,
            durataAlterazioneDataA: null,
            isIncontinenzaUrinaria: null,
            tipologiaIncontinenza: null,
            tipologiaIncontinenzaAbituale: null,
            periodoIncontinenzaDa: null,
            periodoIncontinenzaA: null,
            tipologiaIncontinenzaOccasionale: null,
            dispositiviPresenti: [],
            isTurbe: null,
            tipologieTurbe: [],
            isLimitazioni: null,
            tipologieLimitazioni: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null,
        },
        copingTolleranzaStress: {
            percezione: null,
            risposteCoping: null,
            isPreoccupazione: null,
            descrizionePreoccupazione: null,
            punteggioAnsia: null,
            isRispostePostTraumatiche: null,
            descrizioneRispostePostTraumatice: null,
            visioneFuturo: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        statoNutrizionale: {
            altezza: null,
            peso: null,
            BMI: null,
            statoBMI: null,
            variazionePesoSignificativo: null,
            caratteristicheVariazione: null,
            variazioneKg: null,
            tempoVariazione: null,
            noteVariazionePeso: null,
            condizioneRilevata: null,
            MNA: null,
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null,
        }
    },
    [enumRichiesta.attributi.informazioniBaseFaseRichiesta]: {
        infoUltimoRicovero: {
            isRicovero: null,
            codiceIstitutoCura: null,
            unitaOperativaDimissione: null,
            diagnosiDimissione: null,
            dataRicovero: null,
            dataDimissione: null,
            provenienzaPaziente: null,
            modalitaDimissione: null
        },
        allergie: {
            presenzaAllergie: null,
            listaAllergie: [],
            dataRilevazione: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        ultimoAccessoPS: {
            settingProvenienza: null,
            codiceIstitutoCuraDimissione: null,
            diagnosiDimissione: null,
            settingPresaInCarico: null
        },
        punteggioDiBrass: {
            BRASS: null,
            indiceDiRischio: null,
            noteSchedaDiBrass: null,
            pazienteCandidabile: null
        }
    },
    informazioniDiBase: {
        dispositiviMediciPresenti: [],
        terapiaFarmacologicaInAtto: {
            presenzaTerapiaFarmacologica: null,
            farmaci: [],
            dispositivi: []
        },
        trattamentiDietetici: [],
        fornitureAttive: [],
        datiTrattamentiLesioniTrofiche: {
            trattamentiLesioniTrofiche: null,
            pressioneSistolicaCaviglia: null,
            pressioneSistolicaBraccioOmolaterale: null,
            indiceDiWinsor: null,
            interpretazioneIndiceDiWinsor: null,
            sede: [],
            tipologiaMedicazioneApplicata: [],
            trattamentiUlteriori: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiAderenzaAllaTerapia: {
            testUsato: null,
            punteggio4Domande: null,
            punteggio8Domande: null,
            esitoTest4Domande: null,
            esitoTest8Domande: null,
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null
        },
        datiIntegratoriEFitoterapici: {
            principioAttivo: null,
            integratoriEFitoterapici: []
        },
        datiAltriTrattamenti: []
    },
    anamnesiPatologicaProssima: {
        patologieAttive: []
    },
    anamnesiPatologicaRemota: {
        patologieApparatoRespiratorio: {
            checked: null,
            lista: []
        },
        diabete: {
            checked: null,
            lista: []
        },
        cardiopatia: {
            checked: null,
            lista: []
        },
        ipertensioneArteriosa: {
            checked: null,
            lista: []
        },
        vasculopatia: {
            checked: null,
            lista: []
        },
        epatopatia: {
            checked: null,
            lista: []
        },
        patologiaGastroenterica: {
            checked: null,
            lista: []
        },
        nefropatia: {
            checked: null,
            lista: []
        },
        patologiaUrogenitale: {
            checked: null,
            lista: []
        },
        malattieGinecologiche: {
            checked: null,
            lista: []
        },
        sistemaLinfoEmatologico: {
            checked: null,
            lista: []
        },
        ghiandoleEndocrineMetabolismo: {
            checked: null,
            lista: []
        },
        malattieAutoimmuniReumatologia: {
            checked: null,
            lista: []
        },
        patologiaNeurologica: {
            checked: null,
            lista: []
        },
        patologiaPsichiatrica: {
            checked: null,
            lista: []
        },
        malattieInfettive: {
            checked: null,
            lista: []
        },
        patologieOsteoarticolari: {
            checked: null,
            lista: []
        },
        patologiaOculare: {
            checked: null,
            lista: []
        },
        malattieORL: {
            checked: null,
            lista: []
        },
        cuteSottocutaneo: {
            checked: null,
            lista: []
        },
        altro: {
            checked: null,
            lista: []
        }
    },
    datiAnamnesiFamiliare: {
        patologieFamiliari: {
            isPatologieFamiliari: null,
            patologieFamiliari: [],
            tipoRelazioneConAssistito: null,
            dataValutazione: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        }
    },
    benessereAbitativo: {
        situazioneAbitativa: {
            contestoAbitativo: null,
            posizioneNelTerritorio: null,
            servizi: null,
            piano: null,
            barriereArchitettoniche: null,
            ascensore: null,
            compatibileAccessoCarrozzina: null,
            montascale: null,
            scale: null,
            scalini: null,
            rampe: null,
            numRampe: null,
            corrimano: null,
            condizIgAppCasa: null,
            bagno: null,
            condizionamento: null,
            riscaldamento: null,
            illuminazione: null,
            presenzaTappeti: null,
            presenzaSupScivolose: null,
            presenzaAnimaliDomestici: null,
            tipoAnimaleDomestico: [],
            altreCondDaApprofondire: null,
            valAbitudiniSicurDomestica: null,
            esitoValutazione: null,
            dataValutazione: null,
            statoDopoValutazione: null,
            daConsiderare: null,
            propostaAttivitaSuggerimenti: null
        }
    },
    esameObiettivo: {
        cuteEAnnessi: {
            checked: null,
            alterazioneColorito: null,
            alterazioneColoritoTipologia: null,
            alterazioneColoritoCondizioneRilevata: null,
            alterazioneColoritoDaAttenzionare: null,
            alterazioneColoritoPropostaAttivitaSuggerimenti: null,
            alterazioneTemperatura: null,
            alterazioneTemperaturaAlterazione: null,
            alterazioneTemperaturaTemperatura: null,
            alterazioneTemperaturaNote: null,
            alterazioneTemperaturaCondizioneRilevata: null,
            alterazioneTemperaturaDaAttenzionare: null,
            alterazioneTemperaturaPropostaAttivitaSuggerimenti: null,
            idratazione: null,
            idratazioneNote: null,
            idratazioneCondizioneRilevata: null,
            idratazioneDaAttenzionare: null,
            idratazionePropostaAttivitaSuggerimenti: null,
            edemi: null,
            edemiSede: null,
            edemiDescrizione: null,
            edemiNote: null,
            edemiCondizioneRilevata: null,
            edemiDaAttenzionare: null,
            edemiPropostaAttivitaSuggerimenti: null,
            cicatrici: null,
            cicatriciSede: null,
            cicatriciNote: null,
            cicatriciCondizioneRilevata: null,
            cicatriciDaAttenzionare: null,
            cicatriciPropostaAttivitaSuggerimenti: null,
            eritema: null,
            eritemaSede: null,
            eritemaNote: null,
            eritemaCondizioneRilevata: null,
            eritemaDaAttenzionare: null,
            eritemaPropostaAttivitaSuggerimenti: null,
            ecchimosi: null,
            ecchimosiSede: null,
            ecchimosiNote: null,
            ecchimosiCondizioneRilevata: null,
            ecchimosiDaAttenzionare: null,
            ecchimosiPropostaAttivitaSuggerimenti: null,
            lesione: null,
            lesioneSedi: [],
            lesioneCondizioneRilevata: null,
            lesioneDaAttenzionare: null,
            lesionePropostaAttivitaSuggerimenti: null,
            ustioni: null,
            listaUstioni: [],
            ustioniCondizioneRilevata: null,
            ustioniDaAttenzionare: null,
            ustioniPropostaAttivitaSuggerimenti: null,
            dermatiti: null,
            listaDermatiti: [],
            dermatitiPrurito: null,
            dermatitiFrequenza: null,
            dermatitiCondizioneRilevata: null,
            dermatitiDaAttenzionare: null,
            dermatitiPropostaAttivitaSuggerimenti: null,
            igiene: null,
            listaDispositiviIgiene: [],
            igieneCondizioneRilevata: null,
            igieneDaAttenzionare: null,
            igienePropostaAttivitaSuggerimenti: null
        },
        orofaringe: {
            checked: null,
            presenzaDiAlterazioniDentizione: null,
            listaTipoAlterazione: [],
            dispositiviPresenti: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null,
            igieneCavoOrale: null,
            condizioneRilevataIgieneCavoOrale: null,
            daAttenzionareIgieneCavoOrale: null,
            propostaAttivitaSuggerimentiIgieneCavoOrale: null,
            autonomiaGestionePazienteIgieneCavoOrale: null,
            autonomiaGestioneCaregiverIgieneCavoOrale: null,
            statoGengive: [],
            condizioneRilevataGengive: null,
            daAttenzionareGengive: null,
            propostaAttivitaSuggerimentiGengive: null,
            lingua: null,
            presenzaStomatite: null,
            condizioneRilevataLingua: null,
            daAttenzionareLingua: null,
            propostaAttivitaSuggerimentiLingua: null,
            presenzaMicosi: null,
            condizioneRilevataMicosi: null,
            daAttenzionareMicosi: null,
            propostaAttivitaSuggerimentiMicosi: null,
            presenzaAlterazioniDeglutizione: null,
            listaAlterazioniDeglutizione: [],
            condizioneRilevataDeglutizione: null,
            daAttenzionareDeglutizione: null,
            propostaAttivitaSuggerimentiDeglutizione: null,
            listaRinofaringe: [],
            condizioneRilevataRinofaringe: null,
            daAttenzionareRinofaringe: null,
            propostaAttivitaSuggerimentiRinofaringe: null,
            listaLaringe: [],
            condizioneRilevataLaringe: null,
            daAttenzionareLaringe: null,
            propostaAttivitaSuggerimentiLaringe: null,
        },
        capo: {
            checked: null,
            presenzaDiAlterazioni: null,
            dispositiviPresenti: [],
            alterazioni: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        collo: {
            checked: null,
            presenzaDiAlterazioni: null,
            alterazioni: [],
            dispositiviPresenti: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        apparatoVascolarePeriferico: {
            checked: null,
            polsi: null,
            alterazioni: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        occhi: {
            checked: null,
            acuitaVisDx: null,
            acuitaVisSx: null,
            alterazioniDx: [],
            alterazioniSx: [],
            dispositiviPresenti: [],
            isPresenzaCorrezione: null,
            entitaCorrezioneDx: null,
            entitaCorrezioneSx: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        naso: {
            checked: null,
            presenzaDiAlterazioni: null,
            alterazioni: [],
            dispositiviPresenti: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        orecchie: {
            checked: null,
            listaAlterazioneOrecchioDx: [],
            listaAlterazioneOrecchioSx: [],
            dispositiviPresenti: [],
            orecchio: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        sistemaLinfatico: {
            checked: null,
            presenzaDiAlterazioni: null,
            tipoAlterazione: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        urogenitaleFemminile: {
            checked: null,
            presenzaDiAlterazioni: null,
            alterazioni: [],
            dispositiviPresenti: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        urogenitaleFemminileOstetrico: {
            checked: null,
            note: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        urogenitaleMaschile: {
            checked: null,
            presenzaDiAlterazioni: null,
            alterazioni: [],
            dispositiviPresenti: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        osteoarticolare: {
            checked: null,
            presenzaAlterazione: null,
            alterazioni: [],
            dispositiviPresenti: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        addome: {
            checked: null,
            presenzaDiAlterazioni: null,
            tipoDiAlterazione: [],
            alterazioneDispositiviPresenti: [],
            alterazioneIspezione: null,
            palpazioneSuperficiale: null,
            sedePalpazioneSuperficiale: null,
            palpazioneProfonda: null,
            sedePalpazioneProfonda: null,
            alterazioneCondizioneRilevata: null,
            alterazioneDaAttenzionare: null,
            alterazionePropostaAttivitaSuggerimenti: null,
            segnoDiBlumberg: null,
            segnoDiBlumbergSede: null,
            segnoDiBlumbergCondizioneRilevata: null,
            segnoDiBlumbergDaAttenzionare: null,
            segnoDiBlumbergPropostaAttivitaSuggerimenti: null,
            segnoDiMurphy: null,
            segnoDiMurphyNote: null,
            segnoDiMurphyCondizioneRilevata: null,
            segnoDiMurphyDaAttenzionare: null,
            segnoDiMurphyPropostaAttivitaSuggerimenti: null,
            segnoGiordano: null,
            listaGiordano: [],
            segnoGiordanoNote: null,
            segnoGiordanoCondizioneRilevata: null,
            segnoGiordanoDaAttenzionare: null,
            segnoGiordanoPropostaAttivitaSuggerimenti: null,
            meteorismo: null,
            meteorismoCondizioneRilevata: null,
            meteorismoDaAttenzionare: null,
            meteorismoPropostaAttivitaSuggerimenti: null,
            peristalsi: null,
            peristalsiNote: null,
            peristalsiCondizioneRilevata: null,
            peristalsiDaAttenzionare: null,
            peristalsiPropostaAttivitaSuggerimenti: null,
            fegatoPalpabile: null,
            fegatoPalpabileNote: null,
            fegatoPalpabileCondizioneRilevata: null,
            fegatoPalpabileDaAttenzionare: null,
            fegatoPalpabilePropostaAttivitaSuggerimenti: null,
            milzaPalpabile: null,
            milzaPalpabileNote: null,
            milzaPalpabileCondizioneRilevata: null,
            milzaPalpabileDaAttenzionare: null,
            milzaPalpabilePropostaAttivitaSuggerimenti: null,
            massaPalpabile: null,
            massaPalpabileDescrizione: null,
            massaPalpabileCondizioneRilevata: null,
            massaPalpabileDaAttenzionare: null,
            massaPalpabilePropostaAttivitaSuggerimenti: null,
            laparocele: null,
            laparoceleNote: null,
            laparoceleCondizioneRilevata: null,
            laparoceleDaAttenzionare: null,
            laparocelePropostaAttivitaSuggerimenti: null,
            listaErnia: [],
            erniaNote: null,
            erniaCondizioneRilevata: null,
            erniaDaAttenzionare: null,
            erniaPropostaAttivitaSuggerimenti: null,
            globoVescicale: null,
            globoVescicaleNote: null,
            globoVescicaleCondizioneRilevata: null,
            globoVescicaleDaAttenzionare: null,
            globoVescicalePropostaAttivitaSuggerimenti: null,
            globoVescicaleAutonomiaGestionePaziente: null,
            globoVescicaleAutonomiaGestioneCaregiver: null
        },
        mammelle: {
            checked: null,
            presenzaDiAlterazioniMammellaDx: null,
            presenzaDiAlterazioniMammellaSx: null,
            alterazioniDx: [],
            alterazioniSx: [],
            dispositiviPresenti: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        esplorazioneRettale: {
            checked: null,
            presenzaDiAlterazioni: null,
            alterazioni: [],
            dispositiviPresenti: [],
            condizioneRilevata: null,
            daAttenzionare: null,
            autonomiaGestionePaziente: null,
            autonomiaGestioneCaregiver: null,
            propostaAttivitaSuggerimenti: null
        },
        cuore: {
            checked: null,
            presenzaDiAlterazioni: null,
            tipoDiAlterazione: [],
            dispositiviPresenti: [],
            ritmoCardiaco: null,
            toniCardiaci: null,
            noteToniCardiaci: null,
            pauseLibere: null,
            listaPauseLibere: [],
            notePauseLibere: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        neurologicoEstatoDiVigilanza: {
            checked: null,
            presenzaDiAlterazioniStatoVigilanza: null,
            tipoDiAlterazioneStatoVigilanza: [],
            presenzaDiAlterazioniPupille: null,
            tipoDiAlterazionePupille: [],
            dispositiviPresentiPupille: [],
            proveCerebellari: null,
            tipoDiAlterazioneCerebellari: [],
            presenzaDiAlterazioniNerviCranici: null,
            tipoDiAlterazioneNerviCranici: [],
            tonoMuscolare: null,
            trofismoMuscolare: null,
            testDiMingazziniI: null,
            noteMingazziniI: null,
            testDiMingazziniII: null,
            noteMingazziniII: null,
            deficitSensoriali: null,
            tipoDiAlterazioneSensoriali: [],
            rigor: null,
            presenzaDiAlterazioniROT: null,
            tipoDiAlterazioneROT: [],
            stazioneEretta: null,
            romberg: null,
            noteRomberg: null,
            presenzaDiAlterazioniMarcia: null,
            tipoDiAlterazioneMarcia: [],
            presenzaDiAlterazioniFunzioniSuperiori: null,
            tipoDiAlterazioneFunzioniSuperiori: [],
            noteFunzioniSuperiori: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            propostaAttivitaSuggerimenti: null
        },
        torace: {
            checked: null,
            alterazione: null,
            listaAlterazioni: [],
            listaDispositiviAlterazioni: [],
            alterazioneFrequenzaRespiratoria: null,
            alterazioneCondizioneRilevata: null,
            alterazioneDaAttenzionare: null,
            alterazionePropostaAttivitaSuggerimenti: null,
            dispnea: null,
            dispneaCondizioneRilevata: null,
            dispneaDaAttenzionare: null,
            dispneaPropostaAttivitaSuggerimenti: null,
            murmureVescicolare: null,
            murmureVescicolareAlterazione: null,
            listaRumori: [],
            murmureVescicolareCondizioneRilevata: null,
            murmureVescicolareDaAttenzionare: null,
            murmureVescicolarePropostaAttivitaSuggerimenti: null,
            percussione: null,
            percussioneAlterazione: null,
            percussioneCondizioneRilevata: null,
            percussioneDaAttenzionare: null,
            percussionePropostaAttivitaSuggerimenti: null,
            espettorazioni: null,
            espettorazioniAspetto: [],
            espettorazioniQuantita: null,
            espettorazioniCondizioneRilevata: null,
            espettorazioniDaAttenzionare: null,
            espettorazioniPropostaAttivitaSuggerimenti: null,
            spo2: null,
            spo2CondizioneRilevata: null,
            spo2DaAttenzionare: null,
            spo2PropostaAttivitaSuggerimenti: null,
            spo2autonomiaGestionePaziente: null,
            spo2autonomiaGestioneCaregiver: null
        }
    },
    bisogniPrestazioniAssistenziali: [],
    obiettiviClinicoAssistenziali: {
        obiettivi: null
    },
    figureProfessionaliPresaInCarico: [],
    proposteAttivitaSuggerimenti: [],
    proposteAttivitaSuggerimentiNote: {
        note: null
    },
    proposteAttivitaSuggerimentiAssistenteSociale: {
        propostaAssistenteSociale: null,
        specificareSeAltro: null
    },
    professionistiSupportoAValutazione: [],
    dataDimissioneProgrammataDaRicovero: {
        dataDimissioneProgrammataDaRicovero: null
    },
    dataDiPresaInCaricoTerritorialeDaDimissioneProtetta: {
        dataPresaInCaricoTerritoriale: null,
        isInoltroRichiestaCureomiciliari: null,
        note: null
    },
    bisogniAllaDimissione: {
        note:null
    },
    livelloPrioritaBozza: null,
    valutazioneProfessionista: {
        valutazioni: []
    }
}
