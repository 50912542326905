import React, {Fragment} from "react";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import PropTypes from "prop-types";
import * as _ from "lodash";
import ToponomasticaUtils from "../../../utils/ToponomasticaUtils";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import Utils from "../../../utils/Utils";
import {
    FieldsRow,
    RoleBasedAutocomplete,
    RoleBasedInput,
    RoleBasedPhoneInput,
    RoleBasedRadio,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import {v4 as uuid} from "uuid";
import {isMadrelingua} from "../../anagrafePaziente/datiGenerali/DatiGenerali";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";

const propTypes = {
    personeDiRiferimentoForm: PropTypes.any,
    datiPadreForm: PropTypes.any,
    datiMadreForm: PropTypes.any,
    onChangePersoneDiRiferimento: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onValidaSezione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    mascheraModificabilita:PropTypes.any,
    forceReadOnly: PropTypes.bool,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

const defaultPersonaDiRiferimento = {
    id: null,
    cognome: null,
    nome: null,
    indirizzo: null,
    numero: null,
    comune: "",
    provincia: "",
    telefono: null,
    cellulare: null,
    email: null,
    isItalianoMadrelinguaPersonaDiRiferimento: null,
    livello: null,
    descrizione: [],
    tipoRelazioneConAssistito: null
}

const defaultDescrizione = {
    id: null,
    codice: null
}

export default class PersonaDiRiferimento extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.personeDiRiferimentoForm)
    }

    isSezioneValida = false;

    listaComuni = [];
    listaProvince = [];

    tipoRelazioneAssistitoSelectList = _.cloneDeep(oggettiUtili.tipoRelazioneAssistitoPersonaRiferimento);

    componentDidMount() {
        this.listaComuni = ToponomasticaUtils.getComuni();
        this.listaProvince = ToponomasticaUtils.getProvince();
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        let dati = _.cloneDeep(this.state.dati);

        if (!this.props.datiPadreForm.isPadreIndicatoComeRiferimentoPerPaziente
            && !this.props.datiMadreForm.isMadreIndicataComeRiferimentoPerPaziente
            && !_.isEqual(prevProps.personeDiRiferimentoForm, this.props.personeDiRiferimentoForm)) {
            this.setState({dati: this.props.personeDiRiferimentoForm}, () => {
                this.inizializzaForm();
            });
        } else {
            if (!_.isEqual(prevProps.datiMadreForm, this.props.datiMadreForm)) {
                let temp = dati.personeDiRiferimento.filter(persona => persona?.tipo === "madre" && persona.tipoRelazioneConAssistito.value === "04");
                temp.forEach(el => {
                    let index = dati.personeDiRiferimento.indexOf(el);
                    if (index !== -1)
                        dati.personeDiRiferimento.splice(index, 1);
                });

                if (!Utils.isObjectNull(this.props.datiMadreForm) && this.props.datiMadreForm.isMadreIndicataComeRiferimentoPerPaziente) {
                    let madrePersonaDiRiferimento = _.cloneDeep(defaultPersonaDiRiferimento);
                    this.inizializzaDatiComePadreMadre("datiMadreForm", madrePersonaDiRiferimento, "isItalianoMadrelinguaMadre");
                    madrePersonaDiRiferimento.tipo = "madre";
                    dati.personeDiRiferimento.unshift(madrePersonaDiRiferimento);
                }
                this.setState({dati: dati}, () => {
                    this.inizializzaForm();
                });
            }

            if (!_.isEqual(prevProps.datiPadreForm, this.props.datiPadreForm)) {
                let temp = dati.personeDiRiferimento.filter(persona => persona?.tipo === "padre" && persona.tipoRelazioneConAssistito.value === "04");
                temp.forEach(el => {
                    let index = dati.personeDiRiferimento.indexOf(el);
                    if (index !== -1)
                        dati.personeDiRiferimento.splice(index, 1);
                });

                if (!Utils.isObjectNull(this.props.datiPadreForm) && this.props.datiPadreForm.isPadreIndicatoComeRiferimentoPerPaziente) {
                    let padrePersonaDiRiferimento = _.cloneDeep(defaultPersonaDiRiferimento);
                    this.inizializzaDatiComePadreMadre("datiPadreForm", padrePersonaDiRiferimento, "isItalianoMadrelinguaPadre");
                    padrePersonaDiRiferimento.tipo = "padre";
                    dati.personeDiRiferimento.unshift(padrePersonaDiRiferimento);
                }
                this.setState({dati: dati}, () => {
                    this.inizializzaForm();
                });
            }
        }

    }

    inizializzaDatiComePadreMadre = (object, parente, isItalianoMadrelingua) => {
        parente.cognome = this.props[object].cognome;
        parente.nome = this.props[object].nome;
        parente.indirizzo = this.props[object].indirizzo;
        parente.numero = this.props[object].numero;
        parente.telefono = this.props[object].telefono;
        parente.email = this.props[object].email;
        parente.isItalianoMadrelinguaPersonaDiRiferimento = this.props[object][isItalianoMadrelingua];
        parente.livello = this.props[object].livello;
        parente.descrizione = this.props[object].descrizione;
        parente.tipoRelazioneConAssistito = "04";
    }

    inizializzaForm = () => {
        let dati = _.cloneDeep(this.state.dati);

        if (dati.presenzaPersonaDiRiferimento === true && dati.personeDiRiferimento.length > 0) {
            dati.personeDiRiferimento = dati.personeDiRiferimento.map(persona => {
                let personaMap = persona;
                this.inizializzaDatiComuniProvince(personaMap);
                personaMap.isItalianoMadrelinguaPersonaDiRiferimento = oggettiUtili.rispostaBreve.find(el => el.value === persona.isItalianoMadrelinguaPersonaDiRiferimento);
                personaMap.livello = oggettiUtili.linguaItalianaParlataCompresa.find(el => el.value === this.getValue(persona.livello));
                if (personaMap.descrizione == null) {
                    personaMap.descrizione = [];
                } else {
                    personaMap.descrizione = personaMap.descrizione.map(linguaParlata => {
                        linguaParlata.codice = oggettiUtili.elencoLingue.find(el => el.value === this.getValue(linguaParlata.codice));
                        return linguaParlata;
                    });
                }
                personaMap.tipoRelazioneConAssistito = !Utils.isObjectNull(persona.tipoRelazioneConAssistito)
                    ? this.tipoRelazioneAssistitoSelectList.find(el => el.value === this.getValue(persona.tipoRelazioneConAssistito))
                    : null;
                return personaMap;
            });
        }

        this.setState({dati: dati}, () => this.controllaCampiObbligatoriMancantiAndValida());
    }

    getValue = (field) => {
        return (typeof field === "object" && !Utils.isObjectNull(field)) ? field.value : field;
    }

    inizializzaDatiComuniProvince = (personaDiRiferimento) => {
        let comune = this.listaComuni.find(comune => comune.codice === personaDiRiferimento.comune);
        personaDiRiferimento.comune = comune ? this.getOggettoComune(comune) : comune;

        if (personaDiRiferimento.provincia) {
            let provincia = this.listaProvince.find(provincia => provincia.codice === personaDiRiferimento.comune.codiceProvincia);
            personaDiRiferimento.provincia = provincia ? this.getOggettoProvincia(provincia) : provincia;
        }
    }

    handleAggiornaForm = (field, elem, index) => {
        let dati = _.cloneDeep(this.state.dati);
        let persona = dati.personeDiRiferimento[index];

        if (field === "comune" || field === "provincia") {
            let isOpzioneAutocompleteValida = !Utils.isObjectNull(elem.target.value) && !Utils.isObjectNull(elem.target.value["codice"]);
            if (isOpzioneAutocompleteValida) {
                elem = elem.target.value;
                if (field === "comune") {
                    let provincia = this.listaProvince.find(provincia => provincia.codice === elem.codice.substring(0, 3));
                    persona.provincia = this.getOggettoProvincia(provincia);
                }
            } else {
                elem = null;
            }
        } else if (field === "isItalianoMadrelinguaPersonaDiRiferimento" && !_.isEqual(elem, this.state.dati.personeDiRiferimento[index][field])) {
            persona.livello = null;
            persona.descrizione = [];
        }

        persona[field] = elem;
        dati.personeDiRiferimento[index] = persona;
        this.setState({dati: dati}, () => {
            this.formatObjectForBackend();
            this.controllaCampiObbligatoriMancantiAndValida();
        });
    }

    getOggettoComune = (comune) => {
        return {
            codice: comune.codice,
            codiceProvincia: comune.codiceProvincia,
            descrizione: comune.descrizione
        };
    }

    getOggettoProvincia = (provincia) => {
        return {
            codice: provincia.codice,
            codiceRegione: provincia.codiceRegione,
            descrizione: provincia.descrizione
        };
    }

    addNewPersonaDiRiferimento = () => {
        let dati = _.cloneDeep(this.state.dati);
        let persona = _.cloneDeep(defaultPersonaDiRiferimento);
        persona.id = uuid();
        dati.personeDiRiferimento.push(persona);
        this.setState({dati: dati}, () => {
            this.formatObjectForBackend();
            this.controllaCampiObbligatoriMancantiAndValida();
        });
    }

    removePersonaDiRiferimento = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.personeDiRiferimento.splice(index, 1);
        this.setState({dati: dati}, () => {
            if (this.state.dati.personeDiRiferimento.length === 0) {
                let dati = _.cloneDeep(this.state.dati);
                dati.presenzaPersonaDiRiferimento = false;
                this.setState({dati: dati}, () => {
                    this.formatObjectForBackend();
                    this.controllaCampiObbligatoriMancantiAndValida();
                });
            } else {
                this.formatObjectForBackend();
                this.controllaCampiObbligatoriMancantiAndValida();
            }
        });
    }

    formatObjectForBackend = () => {
        let dati = _.cloneDeep(this.state.dati);
        // formatto l'oggetto per inviarlo come parametro di input al metodo salvaBozza nel componente padre "CompilaRichiesta"
        dati.personeDiRiferimento = dati.personeDiRiferimento.map(persona => {
            let personaMap = persona;
            this.getCodice(personaMap);
            return _.mapValues(personaMap, el => el === null || el === undefined || el === "" ? null : el);
        });
        this.props.onChangePersoneDiRiferimento("personaDiRiferimento", dati);
    }

    controllaCampiObbligatoriMancantiAndValida = () => {
        let dati = _.cloneDeep(this.state.dati);
        let numeroCampiObbligatoriMancanti = 0;
        let isSezioneValida = false;

        // controllo la validità dei vari campi di ogni elemento della lista personeDiRiferimento e se ci sono campi obbligatori non valorizzati li conto
        if (dati.presenzaPersonaDiRiferimento === null) {
            numeroCampiObbligatoriMancanti = 1;
        } else if (dati.presenzaPersonaDiRiferimento === true) {
            dati.personeDiRiferimento.forEach(persona => {
                if (Utils.isStateRichiesta(this.props.pageState)) {
                    numeroCampiObbligatoriMancanti += dati.presenzaPersonaDiRiferimento === null ? 1 : 0;
                    numeroCampiObbligatoriMancanti += Utils.isStringEmptyOrNullOrUndefined(persona.cognome) ? 1 : 0;
                    numeroCampiObbligatoriMancanti += Utils.isStringEmptyOrNullOrUndefined(persona.nome) ? 1 : 0;
                }

                // Almeno uno dei due contatti tra telefono o cellulare devono essere valorizzati
                // Nel caso in cui entrambi sono null li conto come campi obbligatori ancora da valorizzare
                if (Utils.isStringEmptyOrNullOrUndefined(persona.telefono) && Utils.isStringEmptyOrNullOrUndefined(persona.cellulare)) {
                    numeroCampiObbligatoriMancanti += 2;
                }
            });

            isSezioneValida = dati.personeDiRiferimento.filter(persona => {
                return (Utils.isStringEmptyOrNullOrUndefined(persona.telefono) || Utils.isValidTelefono(persona.telefono))
                    && (Utils.isStringEmptyOrNullOrUndefined(persona.email) || Utils.isValidEmail(persona.email))
                    && (Utils.isStringEmptyOrNullOrUndefined(persona.cellulare) || Utils.isValidTelefono(persona.cellulare));
            }).length === dati.personeDiRiferimento.length;
        } else {
            isSezioneValida = true;
        }

        this.isSezioneValida = isSezioneValida;

        this.props.onChangeRequiredFieldsNumber("sottoSezionePersonaDiRiferimento", numeroCampiObbligatoriMancanti);
        this.props.onValidaSezione("isPersonaDiRiferimentoValida", isSezioneValida);
    }

    getCodice = (persona) => {
        persona.comune = !Utils.isObjectNull(persona.comune) ? persona.comune.codice : "";
        persona.provincia = !Utils.isObjectNull(persona.provincia) ? persona.provincia.codice : "";
        persona.isItalianoMadrelinguaPersonaDiRiferimento = persona.isItalianoMadrelinguaPersonaDiRiferimento?.value;
        persona.livello = persona.livello?.value;
        if (persona.descrizione.length > 0) {
            persona.descrizione = persona.descrizione
                .filter((v, i, a) => a.findIndex(t => (_.isEqual(t.codice, v.codice))) === i)
                ?.map(linguaParlata => {
                    linguaParlata.codice = linguaParlata.codice?.value ?? null;
                    return linguaParlata
                })
                .filter(el => !Utils.isObjectNull(el.codice));
        }
        persona.tipoRelazioneConAssistito = persona.tipoRelazioneConAssistito?.value;
    }

    renderLinguaParlata = (linguaParlata, indexListaLinguaParlata, indexListaPersonaDiRiferimento) => {
        return (
            <RoleBasedSelect
                fieldId={'LING_PARL_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                pageState={this.props.pageState}
                parentClass={"mb-0"}
                id={"selectLinguaParlata"}
                className={"selectLinguaParlata"}
                options={oggettiUtili.elencoLingue}
                value={linguaParlata.codice}
                onChange={(elem) => this.handleAggiornaLinguaParlata(elem, indexListaLinguaParlata, indexListaPersonaDiRiferimento)}
                isSearchable={false}
                fieldLabel={"Lingua parlata"}
                field={"codice"}
                parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                disableDoubleInput={this.props.disableDoubleInput}
            />
        );
    }

    handleAggiornaLinguaParlata = (elem, indexListaLinguaParlata, indexListaPersoneDiRiferimento) => {
        let personaDiRiferimento = _.cloneDeep(this.state.dati);
        let currentPersonaDiRiferimento = personaDiRiferimento.personeDiRiferimento[indexListaPersoneDiRiferimento];
        currentPersonaDiRiferimento.descrizione[indexListaLinguaParlata].codice = elem;
        personaDiRiferimento.personeDiRiferimento[indexListaPersoneDiRiferimento] = currentPersonaDiRiferimento;

        this.setState({dati: personaDiRiferimento}, () => this.formatObjectForBackend());
    }

    addNewLinguaParlata = (indexCurrentPersonaDiRiferimento) => {
        let personaDiRiferimento = _.cloneDeep(this.state.dati);
        let currentPersonaDiRiferimento = personaDiRiferimento.personeDiRiferimento[indexCurrentPersonaDiRiferimento];
        let defaultDescrizioneClone = _.cloneDeep(defaultDescrizione);
        defaultDescrizioneClone.id = uuid();
        currentPersonaDiRiferimento.descrizione.push(defaultDescrizioneClone);
        personaDiRiferimento.personeDiRiferimento[indexCurrentPersonaDiRiferimento] = currentPersonaDiRiferimento;

        this.setState({dati: personaDiRiferimento}, () => this.formatObjectForBackend());
    }

    removeLinguaParlata = (indexListaLinguaParlata, indexCurrentPersonaDiRiferimento) => {
        let personaDiRiferimento = _.cloneDeep(this.state.dati);
        let currentPersonaDiRiferimento = personaDiRiferimento.personeDiRiferimento[indexCurrentPersonaDiRiferimento];
        currentPersonaDiRiferimento.descrizione.splice(indexListaLinguaParlata, 1);
        personaDiRiferimento.personeDiRiferimento[indexCurrentPersonaDiRiferimento] = currentPersonaDiRiferimento;

        this.setState({dati: personaDiRiferimento}, () => this.formatObjectForBackend());
    }

    renderNewPersonaDiRiferimento = (persona, index) => {
        let comuniSuggestions = !Utils.isObjectNull(persona.provincia?.codice)
            ? this.listaComuni.filter(comune => comune.codiceProvincia === persona.provincia.codice)
            : this.listaComuni;
        comuniSuggestions = comuniSuggestions.map(comune => this.getOggettoComune(comune));

        let provinceSuggestions = !Utils.isObjectNull(persona.comune?.codice)
            ? this.listaProvince.filter(provincia => provincia.codice === persona.comune.codice.substring(0, 3))
            : this.listaProvince;
        provinceSuggestions = provinceSuggestions.map(provincia => this.getOggettoProvincia(provincia));

        const isContactRequired = Utils.isStringEmptyOrNullOrUndefined(persona.telefono) && Utils.isStringEmptyOrNullOrUndefined(persona.cellulare);
        const forceContactVisibility = !Utils.isStringEmptyOrNullOrUndefined(persona.telefono) || !Utils.isStringEmptyOrNullOrUndefined(persona.cellulare);

        return <Fragment key={index}>
            <FieldsRow>
                <RoleBasedInput
                    fieldId={'COGN_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    placeholder="Inserisci cognome"
                    field="cognome"
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id="inputCognomePersonaDiRiferimento"
                    value={persona.cognome ?? ""}
                    fieldLabel={"Cognome"}
                    fieldRequired={true}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                    forceReadOnly= {Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.situazioneSociale?.personaDiRiferimento?.personeDiRiferimento, "cognome", persona?.id)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedInput
                    fieldId={'NOME_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    placeholder="Inserisci nome"
                    field="nome"
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id="inputNomePersonaDiRiferimento"
                    value={persona.nome ?? ""}
                    fieldLabel={"Nome"}
                    fieldRequired={true}
                    forceReadOnly= {Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.situazioneSociale?.personaDiRiferimento?.personeDiRiferimento, "nome", persona?.id)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
            <FieldsRow>
                <RoleBasedInput
                    fieldId={'INDIR_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    placeholder="Inserisci indirizzo"
                    field="indirizzo"
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id="inputIndirizzoPersonaDiRiferimento"
                    value={persona.indirizzo ?? ""}
                    fieldLabel={"Indirizzo"}
                    forceReadOnly= {Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.situazioneSociale?.personaDiRiferimento?.personeDiRiferimento, "indirizzo", persona?.id)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedInput
                    fieldId={"NUMERO_CIVICO_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci numero civico"}
                    field={"numero"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id={"inputNumeroCivico"}
                    value={persona.numero ?? ""}
                    fieldLabel={'N°'}
                    forceReadOnly= {Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.situazioneSociale?.personaDiRiferimento?.personeDiRiferimento, "numero", persona?.id)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedAutocomplete
                    fieldId={'COM_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    id={"autocompleteComunePersonaDiRiferimento"}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem, index)}
                    value={persona.comune ?? ""}
                    field={"comune"}
                    placeholder={"Inserisci comune"}
                    suggestions={comuniSuggestions}
                    noSuggestions={"Errore: Comune non esistente"}
                    fieldLabel={"Comune"}
                    forceReadOnly= {Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.situazioneSociale?.personaDiRiferimento?.personeDiRiferimento, "comune", persona?.id)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedAutocomplete
                    fieldId={'PROV_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    id={"autocompleteProvinciaPersonaDiRiferimento"}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem, index)}
                    value={persona.provincia ?? ""}
                    field={"provincia"}
                    placeholder={"Inserisci provincia"}
                    suggestions={provinceSuggestions}
                    noSuggestions={"Errore: Provincia non relativa al comune inserito"}
                    fieldLabel={"Provincia"}
                    forceReadOnly= {Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.situazioneSociale?.personaDiRiferimento?.personeDiRiferimento, "provincia", persona?.id)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
            <FieldsRow>
                <RoleBasedPhoneInput
                    fieldId={'TEL_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci numero di telefono"}
                    field={"telefono"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id={"inputNumeroTelefonoPersonaDiRiferimento"}
                    value={persona.telefono ?? ""}
                    phonePrefix={null}
                    fieldLabel={"Telefono"}
                    fieldRequired={isContactRequired}
                    showFieldIfRequiredAccordionPage={forceContactVisibility}
                    disabled={false}
                    forceReadOnly= {Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.situazioneSociale?.personaDiRiferimento?.personeDiRiferimento, "telefono", persona?.id)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedPhoneInput
                    fieldId={'CELL_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci numero di cellulare"}
                    field={"cellulare"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id={"inputCellularePersonaDiRiferimento"}
                    value={persona.cellulare ?? ""}
                    fieldLabel={'Cellulare'}
                    fieldRequired={isContactRequired}
                    showFieldIfRequiredAccordionPage={forceContactVisibility}
                    disabled={false}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedInput
                    fieldLabel={"E-mail"}
                    fieldId={'EMAIL_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci e-mail"}
                    field={"email"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id={"inputEmailPersonaDiRiferimento"}
                    value={persona.email}
                    validator={Utils.isValidEmail}
                    invalidText={"Inserire un'email valida"}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'ITA_MADREL_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    id={"selectMadrelingua"}
                    className={"selectMadrelingua"}
                    options={oggettiUtili.rispostaBreve}
                    value={persona.isItalianoMadrelinguaPersonaDiRiferimento}
                    onChange={(elem) => this.handleAggiornaForm("isItalianoMadrelinguaPersonaDiRiferimento", elem, index)}
                    isSearchable={false}
                    fieldLabel={"Italiano madrelingua?"}
                    field={"isItalianoMadrelinguaPersonaDiRiferimento"}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
            <FieldsRow containerClass={"align-self-center"}
                       childClass={"align-self-start"}>
                {!isMadrelingua(persona.isItalianoMadrelinguaPersonaDiRiferimento) &&
                <RoleBasedSelect
                    fieldId={'LING_ITA_PARL_COMPR_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    id={"selectLinguaItalianaParlataCompresa"}
                    className={"selectLinguaItalianaParlataCompresa"}
                    options={oggettiUtili.linguaItalianaParlataCompresa}
                    value={persona.livello}
                    onChange={(elem) => this.handleAggiornaForm("livello", elem, index)}
                    isSearchable={false}
                    fieldLabel={"Lingua italiana parlata e compresa"}
                    field={"livello"}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />}
                {!isMadrelingua(persona.isItalianoMadrelinguaPersonaDiRiferimento) &&
                <SectionList
                    title={'Lingua parlata'}
                    data={persona.descrizione}
                    renderSection={(e, i) => this.renderLinguaParlata(e, i, index)}
                    addNewSectionCallback={() => this.addNewLinguaParlata(index)}
                    removeSectionCallback={(i) => this.removeLinguaParlata(i, index)}
                    addButtonVisibilityHandler={!isMadrelingua(persona.isItalianoMadrelinguaPersonaDiRiferimento)
                    && (persona.descrizione?.length === 0 || persona.descrizione?.every(el => !Utils.isObjectNull(el?.codice)))}
                    pageState={this.props.pageState}
                    keyFieldId={'LING_PARL_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    indentOffset={0}
                    field={'descrizione'}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento.personeDiRiferimento[?(@.id == '" + persona?.id + "')].descrizione"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />}
            </FieldsRow>
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'TIPO_REL_ASS_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    id={"selectTipoRelazioneConAssistito"}
                    className={"selectTipoRelazioneConAssistito"}
                    options={this.tipoRelazioneAssistitoSelectList}
                    value={persona.tipoRelazioneConAssistito}
                    onChange={(elem) => this.handleAggiornaForm("tipoRelazioneConAssistito", elem, index)}
                    isSearchable={false}
                    fieldLabel={"Tipo di relazione con assistito"}
                    field={"tipoRelazioneConAssistito"}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
        </Fragment>;
    }

    renderBodyPersonaDiRiferimento = () => {
        let dati = _.cloneDeep(this.state.dati);

        return (
            <>
                <FieldsRow containerClass={"row align-items-center"}>
                    <RoleBasedRadio
                        fieldId={'PRES_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        id={"radioButtonPresenzaPersonaDiRiferimento"}
                        group={"presenzaPersonaDiRiferimento"}
                        onChange={(field, value) => {
                            let dati = _.cloneDeep(this.state.dati);
                            dati[field] = value;
                            this.setState({dati: dati}, () => {
                                if (this.state.dati.presenzaPersonaDiRiferimento === false) {
                                    let dati = _.cloneDeep(this.state.dati);
                                    dati.personeDiRiferimento = [];
                                    this.setState({dati: dati}, () => {
                                        this.formatObjectForBackend();
                                        this.controllaCampiObbligatoriMancantiAndValida();
                                    });
                                } else {
                                    if (this.state.dati.personeDiRiferimento.length === 0)
                                        this.addNewPersonaDiRiferimento();
                                }
                            });
                        }}
                        value={this.state.dati.presenzaPersonaDiRiferimento}
                        items={oggettiUtili.rispostaBreve}
                        fieldLabel={'Presenza persona di riferimento'}
                        fieldRequired={true}
                        forceReadOnly= {this.props.mascheraModificabilita?.situazioneSociale?.personaDiRiferimento?.presenzaPersonaDiRiferimento}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        field={"presenzaPersonaDiRiferimento"}
                        parentJsonPath={"situazioneSociale.personaDiRiferimento"}
                        disableDoubleInput={this.props.disableDoubleInput}
                     />
                </FieldsRow>

                {dati.presenzaPersonaDiRiferimento &&
                <SectionList
                    addNewSectionCallback={this.addNewPersonaDiRiferimento}
                    removeSectionCallback={this.removePersonaDiRiferimento}
                    renderSection={this.renderNewPersonaDiRiferimento}
                    data={dati.personeDiRiferimento}
                    title={'Persona di riferimento'}
                    addButtonVisibilityHandler={this.showAddButtonElementList(dati)}
                    field={'personeDiRiferimento'}
                    forceReadOnly= {this.props.mascheraModificabilita?.situazioneSociale?.personaDiRiferimento?.personeDiRiferimento}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    keyFieldId={"COGN_PERS_RIF.RELAZIONIFAMILIARI.SITUAZIONESOCIALE"}
                    pageState={this.props.pageState}
                    parentJsonPath={"situazioneSociale.personaDiRiferimento.personeDiRiferimento"}
                    disableDoubleInput={this.props.disableDoubleInput}
                />}
            </>
        )
    }

    showAddButtonElementList = (dati) => {
        const areCampiObbligatoriValorizzati = dati.personeDiRiferimento
            .every(persona => !Utils.isObjectNull(persona)
                && (Utils.isValidTelefono(persona.telefono) || Utils.isValidEmail(persona.email) || Utils.isValidTelefono(persona.cellulare)));
        return areCampiObbligatoriValorizzati;
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"personaDiRiferimento"}
                title={"Persona di riferimento"}
                required={true}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["situazioneSociale.personaDiRiferimento.personeDiRiferimento", "situazioneSociale.personaDiRiferimento.personeDiRiferimento.descrizione"],
                    [defaultPersonaDiRiferimento, defaultDescrizione]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodyPersonaDiRiferimento()}</AccordionSezioneRichiesta>
        );
    }
}

PersonaDiRiferimento.propTypes = propTypes;
PersonaDiRiferimento.contextType = RoleBasedContext;
