import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionHelper from "../../../service/AccordionHelper";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import Appetito from "../../anamnesiFisiologica/appetito/Appetito";
import AlvoEliminazioneFecale from "../../anamnesiFisiologica/alvoEliminazioneFecale/AlvoEliminazioneFecale";
import EsplorazioneRettale from "../../esameObiettivo/esplorazioneRettale/EsplorazioneRettale";
import VersionedAccordion from "../../VersionedAccordion";
import Utils from "../../../utils/Utils";

const propTypes = {
    datiAnamnesiFisiologica: PropTypes.any,
    datiEsameObiettivo: PropTypes.any,
    onChangeDatiAnamnesiFisiologica: PropTypes.func,
    onChangeEsameObiettivo: PropTypes.func,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

export default class FunzioneGastrointestinale extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: {
            sottosezioneEsplorazioneRettale:0,
            sottosezioneAppetito:0,
            sottosezioneAlvoEliminazioneFecale:0
        },
        accordion: {
            openAccordionAppetito: true,
            openAccordionAlvoEliminazioneFecale: true,
            openAccordionEsplorazioneRettale: true
        }
    }

    campiObbligatori = {...this.state.campiObbligatoriMancanti};

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        this.campiObbligatori[field] = value;
        this.setState({campiObbligatoriMancanti: _.cloneDeep(this.campiObbligatori)}, () => {
            this.props.onChangeObbligatorieta("funzioneGastrointestinale", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }


    renderBodySezione = () => {
        return (
            <>
                <VersionedAccordion accordion={Appetito}
                    dati={this.props.datiAnamnesiFisiologica.appetito}
                    onChangeDatiAppetito={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionAppetito}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionAppetito"}
                    pageState={this.props.pageState}
                    isOptional={false}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                />

                <VersionedAccordion accordion={AlvoEliminazioneFecale}
                    dati={this.props.datiAnamnesiFisiologica.alvoEliminazioneFecale}
                    onChangeDatiAlvoEliminazioneFecale={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionAlvoEliminazioneFecale}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionAlvoEliminazioneFecale"}
                    pageState={this.props.pageState}
                    isOptional={false}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                />

                <VersionedAccordion accordion={EsplorazioneRettale}
                    esplorazioneRettale={this.props.datiEsameObiettivo.esplorazioneRettale}
                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                    openAccordion={this.state.accordion.openAccordionEsplorazioneRettale}
                    onClickAccordion={this.onClickAccordion}
                    pageState={this.props.pageState}
                    showAccordion={true}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                />

            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"funzioneGastroIntestinaleInfermiere"}
                title={"Funzione gastrointestinale"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBodySezione()}</AccordionAvvioRichiesta>
        );
    }
}

FunzioneGastrointestinale.propTypes = propTypes;
