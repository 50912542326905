import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {FieldsRow, RoleBasedInput, RoleBasedSelect} from "../../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../../utils/dataset/OggettiUtili.json";

const propTypes = {
    dati: PropTypes.object,
    onChange: PropTypes.func,
    pageState: PropTypes.string,
    fieldInAccordionReadOnly: PropTypes.bool
}

export default class SondinoNasoGastrico extends Component {

    state = {
        diametroSonda: null,
        lunghezzaSonda: null,
        numeroVie: null,
        quantitaRistagnoGastrico: null,
        coloreRistagnoGastrico: null,
        note: null
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati)) this.inizializzaForm();
    }

    inizializzaForm = () => {
        let state = _.cloneDeep(this.props.dati);
        if (state == null) state = _.cloneDeep(this.state);
        this.setState(state);
    }

    handleAggiornaForm = (field, elem) => {
        let state = _.cloneDeep(this.state);
        state[field] = new RegExp(/^[-]\d+$/g).test(elem) ? "0" : elem;
        this.setState(state, () => this.props.onChange("sondinoNasoGastrico", this.state));
    }

    render() {
        return <>
            <FieldsRow>
                <RoleBasedInput
                    id={"inputDiametroSonda"}
                    fieldId={'DIM_SONDA_DIAM_SNG.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Dimensioni sonda (diametro)"}
                    field={"diametroSonda"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci diametro sonda"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.diametroSonda}
                    type={"number"}
                    min={"0"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedInput
                    id={"inputLunghezzaSonda"}
                    fieldId={'DIM_SONDA_LUNGH_SNG.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Dimensioni sonda (lunghezza)"}
                    field={"lunghezzaSonda"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci lunghezza sonda"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.lunghezzaSonda}
                    type={"number"}
                    min={"0"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectNumeroVie"}
                    fieldId={'NUM_VIE_SNG.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"numeroVie"}
                    fieldLabel={"N° vie"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.numeroVie}
                    value={this.state.numeroVie}
                    onChange={(elem) => this.handleAggiornaForm("numeroVie", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
            <FieldsRow>
                <RoleBasedInput
                    id={"inputQuantitaRistagnoGastrico"}
                    fieldId={'QUANT_RIST_GASTR_SNG.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Quantità di ristagno gastrico"}
                    field={"quantitaRistagnoGastrico"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci quantità di ristagno gastrico"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.quantitaRistagnoGastrico}
                    type={"number"}
                    min={"0"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectColoreRistagnoGastrico"}
                    fieldId={'COLORE_RIST_GASTR_SNG.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"coloreRistagnoGastrico"}
                    fieldLabel={"Colore del ristagno gastrico"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.coloreRistagnoGastrico}
                    value={this.state.coloreRistagnoGastrico}
                    onChange={(elem) => this.handleAggiornaForm("coloreRistagnoGastrico", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedInput
                    id={"inputNoteSondinoNasoGastrico"}
                    fieldId={'NOTE_SNG.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"note"}
                    fieldLabel={'Note'}
                    pageState={this.props.pageState}
                    value={this.state.note}
                    placeholder={"Inserisci note"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
        </>;
    }
}

SondinoNasoGastrico.propTypes = propTypes;
