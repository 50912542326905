import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    title: PropTypes.any,
    subtitle: PropTypes.string,
    body: PropTypes.any,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    numberOfRequiredFieldsMissing: PropTypes.number,
    children: PropTypes.node.isRequired
}

const defaultProps = {
    numberOfRequiredFieldsMissing: null
}

export default class AccordionFirstLevel extends React.Component {
}

AccordionFirstLevel.propTypes = propTypes;
AccordionFirstLevel.defaultProps = defaultProps;
