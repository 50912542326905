import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import Box from "../../generalComponents/box/Box";

const propTypes = {
    donna: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class Donna extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.donna)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.donna, this.props.donna))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.donna);
        this.setState({dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        let datiDonna = _.cloneDeep(this.state.dati);
        datiDonna[field] = elem;

        /* Mestruazioni */
        if (!datiDonna.menarca) {
            datiDonna.etaMenarca = null;
            datiDonna.mestruazioni = null;
            datiDonna.dismenorrea = null;
            datiDonna.quantita = null;
            datiDonna.durata = null;
            datiDonna.dataUltimaMestruazione = null;
        }
        if (datiDonna.mestruazioni !== "02") {
            datiDonna.amenorrea = null;
        }

        /* Gravidanze */
        if (!datiDonna.gravidanzePrecedenti) {
            datiDonna.partiATermine = null;
            datiDonna.figliVivi = null;
            datiDonna.abortiSpontanei = null;
            datiDonna.abortiIndotti = null;
        }

        /* Menopausa */
        if (!datiDonna.menopausa) {
            datiDonna.etaMenopausa = null;
            datiDonna.fisiologica = null;
        }

        this.setState({dati: datiDonna},
            () => this.validazioneDati());
    }

    validazioneDati = () => {
        let datiDonna = _.cloneDeep(this.state.dati);
        this.props.onChangeDatiAssistito("donna", datiDonna);
    }

    renderBody = () => {
        let dati = this.state.dati;

        return (
            <>
                <Box key={"boxDonnaMestruazioni"} titleBox={"Mestruazioni"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'MENARCA.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"isIncontinenza"}
                            className={"isIncontinenza"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.menarca}
                            onChange={(elem) => this.handleAggiornaForm("menarca", elem)}
                            isSearchable={false}
                            fieldLabel={"Menarca"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                            field={"menarca"}
                        />
                        {dati.menarca &&
                        <RoleBasedInput
                            type={"number"}
                            fieldId={'ETA_MENARCA.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            value={this.state.dati.etaMenarca}
                            placeholder="Inserisci età menarca"
                            field={"etaMenarca"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"basedInputEtaMenarca"}
                            fieldLabel={"Età"}
                            min={0}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />}
                    </FieldsRow>

                    {dati.menarca &&
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'MESTRUAZIONI.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"selectMestruazioni"}
                            options={OggettiUtili.mestruazioni}
                            value={dati.mestruazioni}
                            onChange={(elem) => this.handleAggiornaForm("mestruazioni", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Mestruazioni non esistente"}
                            fieldLabel={"Mestruazioni"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                            field={"mestruazioni"}
                        />
                        {dati.mestruazioni === "02" &&
                        <RoleBasedSelect
                            fieldId={'AMENORREA.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"selectDismenorrea"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.amenorrea}
                            onChange={(elem) => this.handleAggiornaForm("amenorrea", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Presenza di amenorrea non esistente"}
                            fieldLabel={"Presenza di amenorrea"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                            field={"amenorrea"}
                        />}
                    </FieldsRow>}

                    {dati.menarca &&
                    <>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'DISMENORREA.DONNA.ANAMNESIFISIOLOGICA'}
                                pageState={this.props.pageState}
                                id={"selectDismenorrea"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.dismenorrea}
                                onChange={(elem) => this.handleAggiornaForm("dismenorrea", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Presenza di dismenorrea non esistente"}
                                fieldLabel={"Presenza di dismenorrea"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                                handleOnlyValue={true}
                                field={"dismenorrea"}
                            />

                            <RoleBasedSelect
                                fieldId={'QUANTITA.DONNA.ANAMNESIFISIOLOGICA'}
                                pageState={this.props.pageState}
                                id={"selectQuantita"}
                                options={OggettiUtili.quantitaDonnaAnamn}
                                value={dati.quantita}
                                onChange={(elem) => this.handleAggiornaForm("quantita", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Quantità non esistente"}
                                fieldLabel={"Quantità"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                                handleOnlyValue={true}
                                field={"quantita"}
                            />

                            <RoleBasedInput
                                fieldId={'DURATA.DONNA.ANAMNESIFISIOLOGICA'}
                                pageState={this.props.pageState}
                                value={this.state.dati.durata}
                                placeholder="Inserisci durata"
                                field={"durata"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"basedInputDurata"}
                                fieldLabel={"Durata"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                            <RoleBasedCalendar
                                fieldId={'ULTIMAMESTRUAZIONE.DONNA.ANAMNESIFISIOLOGICA'}
                                pageState={this.props.pageState}
                                id={"donnaDataUltimaMestruazione"}
                                value={dati.dataUltimaMestruazione}
                                onChange={(elem) => this.handleAggiornaForm("dataUltimaMestruazione", elem.target.value)}
                                errorMessage={"Data non valida"}
                                fieldLabel={"Data ultima mestruazione"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                                field={"dataUltimaMestruazione"}
                            />
                        </FieldsRow>
                    </>}
                    <FieldsRow>
                        <RoleBasedInput
                            fieldId={'NOTE.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            value={this.state.dati.note}
                            placeholder="Inserisci eventuali note"
                            field={"note"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"basedInputNote"}
                            fieldLabel={"Note"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>
                </Box>

                <Box key={'boxDonnaGravidanze'} titleBox={"Gravidanze"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'GRAV_PREC.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"selectGravidanzePrecedenti"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.gravidanzePrecedenti}
                            onChange={(elem) => this.handleAggiornaForm("gravidanzePrecedenti", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Gravidanze Precedenti non esistente"}
                            fieldLabel={"Gravidanze precedenti"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                            field={"gravidanzePrecedenti"}
                        />
                    </FieldsRow>

                    {dati.gravidanzePrecedenti &&
                    <FieldsRow>
                        <RoleBasedInput
                            type={"number"}
                            fieldId={'PARTI_TERM.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            value={this.state.dati.partiATermine}
                            placeholder="Inserisci parti a termine"
                            field={"partiATermine"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"basedInputPartiATermine"}
                            fieldLabel={"Parti al termine"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                        <RoleBasedInput
                            type={"number"}
                            fieldId={'FIGLI_VIVI.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            value={this.state.dati.figliVivi}
                            placeholder="Inserisci i figli vivi"
                            field={"figliVivi"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"basedInputFigliVivi"}
                            fieldLabel={"Figli vivi"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                        <RoleBasedInput
                            type={"number"}
                            fieldId={'ABORTI_SPONTANEI.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            value={this.state.dati.abortiSpontanei}
                            placeholder="Inserisci gli aborti spontanei"
                            field={"abortiSpontanei"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"basedInputAbortiSpontanei"}
                            fieldLabel={"Aborti spontanei"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                        <RoleBasedInput
                            type={"number"}
                            fieldId={'ABORTI_INDOTTI.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            value={this.state.dati.abortiIndotti}
                            placeholder="Inserisci gli aborti indotti"
                            field={"abortiIndotti"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"basedInputAbortiIndotti"}
                            fieldLabel={"Aborti indotti"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>}

                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'GRAV_IN_ATTO.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"selectGravidanzaInAtto"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.gravidanzaInAtto}
                            onChange={(elem) => this.handleAggiornaForm("gravidanzaInAtto", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: proposta attivita o suggerimenti non esistente"}
                            fieldLabel={"Gravidanza in atto"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                            field={"gravidanzaInAtto"}
                        />
                        <RoleBasedInput
                            fieldId={'GRAV_IN_ATTO.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            value={this.state.dati.noteGravidanza}
                            placeholder={"Inserisci note"}
                            field={"noteGravidanza"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"basedInputNoteGravidanza"}
                            fieldLabel={"Note"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>
                </Box>

                <Box key={'boxDonnaMenopausa'} titleBox={"Menopausa"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'MENOPAUSA.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"selectMenopausa"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.menopausa}
                            onChange={(elem) => this.handleAggiornaForm("menopausa", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Gravidanza in atto non esistente"}
                            fieldLabel={"Menopausa"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                            field={"menopausa"}
                        />
                        {dati.menopausa &&
                        <RoleBasedInput
                            type={"number"}
                            fieldId={'ETA_MENOPAUSA.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            value={this.state.dati.etaMenopausa}
                            placeholder="Inserisci età inizio menopausa"
                            field={"etaMenopausa"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"basedInputEtaMenopausa"}
                            fieldLabel={"Età"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />}
                        {dati.menopausa &&
                        <RoleBasedSelect
                            fieldId={'FISIOLOGICA_MENOPAUSA.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"selectFisiologica"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.fisiologica}
                            onChange={(elem) => this.handleAggiornaForm("fisiologica", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Fisiologica non esistente"}
                            fieldLabel={"Fisiologica"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                            field={"fisiologica"}
                        />}
                    </FieldsRow>

                </Box>

                <Box key={'boxDonnaUltimoPAPATest'} titleBox={"Ultimo PAP test"}>
                    <FieldsRow>
                        <RoleBasedCalendar
                            fieldId={'ULT_PAP_TEST.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"donnaUltimoPapTest"}
                            value={dati.ultimoPapTest}
                            onChange={(elem) => this.handleAggiornaForm("ultimoPapTest", elem.target.value)}
                            errorMessage={"Data non valida"}
                            fieldLabel={"Ultimo PAP Test"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={"ultimoPapTest"}
                        />
                        <RoleBasedSelect
                            fieldId={'ESITO_PAP_TEST.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"selectEsitoPapTest"}
                            options={OggettiUtili.esitoTest}
                            value={dati.esitoPapTest}
                            onChange={(elem) => this.handleAggiornaForm("esitoPapTest", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: proposta attivita o suggerimenti non esistente"}
                            fieldLabel={"Esito PAP Test"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                            field={"esitoPapTest"}
                        />
                        <RoleBasedInput
                            fieldId={'ULT_PAP_TEST.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"noteUltimoPapTest"}
                            value={dati.noteUltimoPapTest}
                            placeholder={"Inserisci nota ultimo pap test"}
                            fieldLabel={"Note"}
                            field={"noteUltimoPapTest"}
                            onChange={(field, elem) => this.handleAggiornaForm("noteUltimoPapTest", elem.target.value)}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>
                </Box>

                <Box key={'boxDonnaUltimaVisitaGinecologica'} titleBox={"Ultima visita ginecologica"}>
                    <FieldsRow>
                        <RoleBasedCalendar
                            fieldId={'ULT_VISIT_GINE.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"donnaUltimaVisitaGinecologica"}
                            value={dati.ultimaVisitaGinecologica}
                            onChange={(elem) => this.handleAggiornaForm("ultimaVisitaGinecologica", elem.target.value)}
                            errorMessage={"Data non valida"}
                            fieldLabel={"Ultima visita ginecologica"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={"ultimaVisitaGinecologica"}
                        />
                        <RoleBasedInput
                            fieldId={'ULT_VISIT_GINE.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"noteUltimaVisitaGinecologica"}
                            value={dati.noteUltimaVisitaGinecologica}
                            placeholder={"Inserisci nota ultima visita ginecologica"}
                            fieldLabel={"Note"}
                            field={"noteUltimaVisitaGinecologica"}
                            onChange={(field, elem) => this.handleAggiornaForm("noteUltimaVisitaGinecologica", elem.target.value)}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>
                    <FieldsRow>
                        <RoleBasedCalendar
                            fieldId={'ULT_MAMMO.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"donnaUltimaMammografia"}
                            value={dati.ultimamammografia}
                            onChange={(elem) => this.handleAggiornaForm("ultimamammografia", elem.target.value)}
                            errorMessage={"Data non valida"}
                            fieldLabel={"Ultima mammografia"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={"ultimamammografia"}
                        />
                        <RoleBasedInput
                            fieldId={'ULT_MAMMO.DONNA.ANAMNESIFISIOLOGICA'}
                            pageState={this.props.pageState}
                            id={"noteUltimamammografia"}
                            value={dati.noteUltimamammografia}
                            placeholder={"Inserisci nota ultima mammografia"}
                            field={"noteUltimamammografia"}
                            fieldLabel={"Note"}
                            onChange={(field, elem) => this.handleAggiornaForm("noteUltimamammografia", elem.target.value)}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>
                </Box>
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"donna"}
                title={"Donna"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}

Donna.propTypes = propTypes;

Donna.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}


