import React from "react";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import AccordionHelper from "../../../service/AccordionHelper";
import PropTypes from "prop-types";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import * as _ from "lodash";
import BisogniPrestazioniAssistenzialiSottoAccordion
    from "./bisogniPrestazioniAssistenzialiSottoAccordion/BisogniPrestazioniAssistenzialiSottoAccordion";
import Utils, {closeModaleStyle} from "../../../utils/Utils";
import VersionedAccordion from "../../VersionedAccordion";

const propTypes = {
    dati: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onShowTabellaBisogniPrestazioniAssistenziali: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta: PropTypes.func,
    onChangeBisogniPrestazioniAssistenziali: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class BisogniPrestazioniAssistenziali extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: 0,
        accordion: {
            openSubAccordionBisogniPrestazioniAssistenziali: true
        }
    }

    componentWillUnmount() {
        closeModaleStyle();
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        let richiesta = _.cloneDeep(this.props.richiesta);
        richiesta.bisogniPrestazioniAssistenziali = this.props.dati.filter(el => !Utils.isStringEmptyOrNullOrUndefined(el));
        this.props.salvaBozza(false, false, richiesta);
        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            })
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeRequiredFieldsNumber = (value) => {
        this.setState({campiObbligatoriMancanti: value}, () => {
            this.props.onChangeObbligatorieta("bisogniPrestazioniAssistenziali", this.state.campiObbligatoriMancanti);
        });
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"bisogniPrestazioniAssistenziali"}
                title={"Bisogni/prestazioni assistenziali che hanno determinato la richiesta"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                numberOfRequiredFieldsMissing={this.state.campiObbligatoriMancanti}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}>
                <VersionedAccordion accordion={BisogniPrestazioniAssistenzialiSottoAccordion}
                                    dati={this.props.dati}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    onChangeBisogniPrestazioniAssistenziali={this.props.onChangeBisogniPrestazioniAssistenziali}
                                    openAccordion={this.state.accordion.openSubAccordionBisogniPrestazioniAssistenziali}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openSubAccordionBisogniPrestazioniAssistenziali"}
                                    pageState={this.props.pageState}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
            </AccordionAvvioRichiesta>
        );
    }
}

BisogniPrestazioniAssistenziali.propTypes = propTypes;
