import React, {Component, Fragment} from "react";
import Utils from "../../utils/Utils";
import {Icon} from "web-client-archetype";
import {Badge, Collapse, ListGroup, ListGroupItem, Navbar} from 'reactstrap';
import accordionsCompilaRichiesta from "../../utils/dataset/accordionData/accordionSubaccordionCompilaRichiesta.json";
import accordionsCompilaProposteClassica
    from "../../utils/dataset/accordionData/accordionSubaccordionCompilaProposteClassica.json";
import accordionsCompilaProposteDP from "../../utils/dataset/accordionData/accordionSubaccordionCompilaProposteDP.json";
import accordionsCompilaFormalizzazione
    from "../../utils/dataset/accordionData/accordionSubaccordionCompilaFormalizzazione.json";
import accordionsChiusuraPreDialogo from "../../utils/dataset/accordionData/accordionSubaccordionChiudiPredialogo.json";
import * as _ from "lodash";
import AccordionHelper from "../../service/AccordionHelper";
import AuthUtils from "../../utils/AuthUtils";
import {isSomeFieldIdWriteable, rolesProfessionista} from "../roleBasedComponents/RoleBasedComponents";
import styles from "./MenuAccordion.module.css";
import PropTypes from "prop-types";
import {isPazienteMinoreDi18Anni} from "../anagrafePaziente/AnagrafePaziente";
import routePath from "../../utils/route/route-path.json";
import {v4 as uuid} from "uuid";
import {getValutazionePrecedente} from "../home/HomeUtils";
import {Redirect} from "react-router-dom";
import * as $ from "jquery";
import InserimentoRichiesta from "../inserimentoRichiesta/InserimentoRichiesta";
import * as BsIcon from "react-bootstrap-icons";
import PopoverReact from "../popover/Popover";

const PAGE_1 = ["1:SCHEDA_PAZIENTE"];
const PAGE_2 = ["2:PROPOSTE", "2:PROPOSTE_DIMISSIONE_PROTETTA"];

export default class MenuAccordion extends Component {
    state = {
        listaAccordion: {},
        isMenuCollapsed: true,
        subMenuCollapsed: [],
        canShowAllMenuItems: false,
        indexSelectedAccordion: null,
        indexSelectedSubaccordion: null,
        redirectToAccordionPage: null,
        additionalPropsRedirectFullAccordionPage: null,
        redirectToStatoValutazioneComponentiTeam: false,
        gotoAnagraficaRichiedente: false
    }

    isArrowClicked = false;

    componentDidMount() {
        const sesso = this.props.richiesta?.anagrafePaziente?.datiGenerali?.sesso;
        const isChiusuraPreDialogo = Utils.isStateChiusuraPreDialogo(this.props.pageState);

        let cloneListaAccordion;
        if (Utils.isStateFormalizzazione(this.props.pageState)) {
            cloneListaAccordion = _.cloneDeep(accordionsCompilaFormalizzazione);
        } else if (isChiusuraPreDialogo) {
            cloneListaAccordion = _.cloneDeep(accordionsChiusuraPreDialogo);
        } else {
            cloneListaAccordion = _.merge(
                _.cloneDeep(accordionsCompilaRichiesta),
                Utils.isDimissioneProtetta(this.props.pageState) ? _.cloneDeep(accordionsCompilaProposteDP) : _.cloneDeep(accordionsCompilaProposteClassica)
            );
        }

        let listaAccordion = {};
        for (const key of Object.keys(cloneListaAccordion)) {
            if (!this.isConditionValid(cloneListaAccordion[key]?.["visualCondition"])) continue;
            if (cloneListaAccordion[key]?.subAccordions != null && !!cloneListaAccordion[key]?.subAccordions?.length) {
                const subAccordions = cloneListaAccordion[key].subAccordions.filter(e => this.isConditionValid(e?.["visualCondition"]));
                listaAccordion[key] = {...cloneListaAccordion[key], subAccordions: subAccordions};
            }
        }

        if (AuthUtils.hasUtenteRuoloInfermiere()) {
            const label = sesso === '02' ? 'Femminile' : 'Maschile';
            listaAccordion["funzioneUrinariaInfermiere"].subAccordions[0].subaccordionTitle += ' ' + label;
            listaAccordion["funzioneUrinariaInfermiere"].subAccordions[0].idAccordion += label;
        }

        if (Utils.isStateRichiesta(this.props.pageState) || Utils.isStateRichiestaPreDialogo(this.props.pageState)) {
            listaAccordion["anamnesiFisiologica"].subAccordions = [
                ...listaAccordion["anamnesiFisiologica"].subAccordions,
                {subaccordionTitle: "Comunicazione", disabled: false, idAccordion: "datiComunicazione"},
                {subaccordionTitle: "Sensorio – Udito", disabled: false, idAccordion: "datiSensorioUdito"},
                {subaccordionTitle: "Sensorio – Vista", disabled: false, idAccordion: "datiSensorioVista"},
                {subaccordionTitle: "Sensorio - Dolore", disabled: false, idAccordion: "datiSensorioDolore"}
            ];
        } else if (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState) || isChiusuraPreDialogo) {
            listaAccordion["anamnesiFisiologica"].subAccordions[0].subaccordionTitle = "Valutazione della situazione funzionale (PADL)";

            listaAccordion["anamnesiFisiologica"].subAccordions = [
                {
                    subaccordionTitle: "Informazioni generali",
                    disabled: false,
                    idAccordion: "datiInformazioniGenerali"
                },
                {
                    subaccordionTitle: sesso === '02' ? 'Donna' : 'Uomo',
                    disabled: false,
                    idAccordion: sesso === '02' ? 'donna' : 'uomo'
                },
                {subaccordionTitle: "Stato nutrizionale", disabled: false, idAccordion: "statoNutrizionale"},
                {subaccordionTitle: "Appetito", disabled: false, idAccordion: "appetito"},
                {subaccordionTitle: "Respiro", disabled: false, idAccordion: "datiRespiro"},
                {subaccordionTitle: "Idratazione", disabled: false, idAccordion: "datiIdratazione"},
                {
                    subaccordionTitle: "Alvo/Eliminazione fecale",
                    disabled: false,
                    idAccordion: "alvoEliminazioneFecale"
                },
                {subaccordionTitle: "Diuresi", disabled: false, idAccordion: "datiDiuresi"},
                {subaccordionTitle: "Ritmo sonno e veglia", disabled: false, idAccordion: "datiRitmoSonnoVeglia"},
                ...listaAccordion["anamnesiFisiologica"].subAccordions,
                {subaccordionTitle: "Comunicazione", disabled: false, idAccordion: "datiComunicazione"},
                {subaccordionTitle: "Sensorio – Udito", disabled: false, idAccordion: "datiSensorioUdito"},
                {subaccordionTitle: "Sensorio – Vista", disabled: false, idAccordion: "datiSensorioVista"},
                {subaccordionTitle: "Sensorio - Olfatto", disabled: false, idAccordion: "sensorioOlfatto"},
                {subaccordionTitle: "Sensorio - Gusto", disabled: false, idAccordion: "sensorioGusto"},
                {subaccordionTitle: "Sensorio - Tatto", disabled: false, idAccordion: "sensorioTatto"},
                {subaccordionTitle: "Sensorio – Equilibrio", disabled: false, idAccordion: "sensorioEquilibrio"},
                {subaccordionTitle: "Sensorio - Dolore", disabled: false, idAccordion: "datiSensorioDolore"},
                {
                    subaccordionTitle: "Coping/Tolleranza allo stress",
                    disabled: false,
                    idAccordion: "copingTolleranzaStress"
                },
                {subaccordionTitle: "Aderenza alla terapia", disabled: false, idAccordion: "datiAderenzaAllaTerapia"}
            ];
        }

        if (listaAccordion['determinantiDiSalute']) {
            if(Utils.isRuoloInfermiere()){
                listaAccordion['determinantiDiSalute'].accordionTitle = 'Consapevolezza della salute' ;
            } 
        }

        this.setState({
            listaAccordion: this.getMenuItemsWithCount(listaAccordion),
            isMenuCollapsed: true,
            subMenuCollapsed: this.getSubMenuCollapsed(listaAccordion),
            canShowAllMenuItems: this.props.showAllMenuItems
        });

        AccordionHelper.updateCountOptionalFieldsSubject.subscribe(mappaCountOptionalFields => {
            if (!Utils.isObjectEmpty(mappaCountOptionalFields))
                this.setState({listaAccordion: this.getMenuItemsWithCount(listaAccordion, mappaCountOptionalFields)});
        });

        if (!this.props.showAllMenuItems) {
            AccordionHelper.abilitazioneProseguiSubject.subscribe(canShowAllMenuItems =>
                this.setState({canShowAllMenuItems: canShowAllMenuItems})
            );
        }

        AccordionHelper.requiredAccordionFieldsSubject.subscribe(additionalPropsRedirectFullAccordionPage => {
            if (!_.isEqual(this.state.additionalPropsRedirectFullAccordionPage, additionalPropsRedirectFullAccordionPage)) {
                this.setState({additionalPropsRedirectFullAccordionPage});
            }
        });
    }

    getSubMenuCollapsed = (listaAccordion) => {
        return Object
            .keys(listaAccordion)
            .filter(k => !!listaAccordion[k].subAccordions.length)
            .map((_e, i) => ({firstLevel: i, isSubMenuCollapsed: true}));
    }

    isConditionValid = (visualCondition) => {
        switch (visualCondition) {
            case "IS_PAGESTATE_VALUTAZIONE":
                return Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState);
            case "IS_RUOLO_INFERMIERE":
                return AuthUtils.hasUtenteRuoloInfermiere();
            case "IS_SEGNALAZIONE":
                return Utils.isSegnalazione(this.props.richiesta?.tipologia);
            case "IS_NOT_DIMISSIONE_PROTETTA_AND_CAN_SHOW_VALUTAZIONE_PROFESSIONISTA":
                return !Utils.isDimissioneProtetta(this.props.pageState)
                    && this.props.richiesta?.valutazioni?.filter(valutazione => rolesProfessionista.has(valutazione?.valutatore?.ruolo))?.length > 0;
            case "NOT_PAGESTATE_RICHIESTA_PREDIALOGO_AND_NOT_PAGESTATE_VALUTAZIONE_PREDIALOGO":
                return !Utils.isStateRichiestaPreDialogo(this.props.pageState) && !Utils.isStateValutazionePreDialogo(this.props.pageState);
            case "IS_MINORE_18_ANNI":
                return isPazienteMinoreDi18Anni(this.props.richiesta?.anagrafePaziente?.datiGenerali?.dataNascita);
            default:
                return true;
        }
    }

    getMenuItemsWithCount = (listaAccordion, mappaCount = null) => {
        let updatedMenu = _.cloneDeep(listaAccordion);
        const mappaCountOptionalAccordionFields = _.cloneDeep(mappaCount ?? AccordionHelper.mappaCountOptionalAccordionFields);

        if (this.props.showOptionalFieldsCount && !Utils.isObjectEmpty(mappaCountOptionalAccordionFields)) {
            Object.keys(listaAccordion).forEach(idAccordion => {
                const accordion = mappaCountOptionalAccordionFields?.[idAccordion.replaceAll(/^dati/g, '').toLowerCase()];
                if (!Utils.isObjectEmpty(accordion)) {
                    updatedMenu[idAccordion] = {
                        ...updatedMenu[idAccordion],
                        subAccordions: updatedMenu[idAccordion].subAccordions.flatMap(subaccordion => {
                            const idSubaccordion = subaccordion?.["countId"] ?? subaccordion.idAccordion
                            const subaccordionCount = accordion["sottoaccordion"]?.[idSubaccordion.replaceAll(/^dati/g, '').toLowerCase()];
                            if (!Utils.isObjectEmpty(subaccordionCount)) {
                                return {
                                    ...subaccordion,
                                    countOptionalFields: subaccordionCount
                                };
                            }
                            return subaccordion;
                        }),
                        totalCountOptionalFields: accordion["totaleNumeroCampiOpzionali"]
                    };
                }
            });
        }

        return updatedMenu
    }

    gestionePaginaLinkContatti = () => {
        return Utils.isStateChiusuraPreDialogo(this.props.pageState) ? "1:SCHEDA_PAZIENTE_CHIUSURA_PREDIALOGO" : "1:SCHEDA_PAZIENTE";
    }

    getNavbarJSX = () => {
        const showTutore = this.props.richiesta?.anagrafePaziente?.tutore?.length > 0;
        const showAmministratoreDiSostegno = this.props.richiesta?.anagrafePaziente?.amministratoreDiSostegno?.length > 0;
        const showPersonaDiRiferimentoInfo = this.props.richiesta?.situazioneSociale?.personaDiRiferimento?.presenzaPersonaDiRiferimento === true
            && this.props.richiesta?.situazioneSociale?.personaDiRiferimento?.personeDiRiferimento?.length > 0;

        const showElementiMenu = Utils.isStateFormalizzazione(this.props.pageState);

        const isPaginaComposizioneTeam = window.location.href.includes(routePath.stato_valutazione_componenti_team);
        const showLinkTeam = (Utils.isStateValutazione(this.props.pageState)
            || Utils.isStateValutazionePreDialogo(this.props.pageState)
            || Utils.isStateFormalizzazione(this.props.pageState)
            || Utils.isStateChiusuraPreDialogo(this.props.pageState)) && !isPaginaComposizioneTeam;

        return <Navbar className={['navbar', 'bg-black', styles.navbarCustomStyle].join(' ')}
                       onMouseOver={() => {
                           document.getElementsByTagName('body')[0].style.overflow = 'hidden';
                           if (this.state.isMenuCollapsed) this.setState({isMenuCollapsed: false});
                       }}>
            <div className={"row"}
                 style={this.state.isMenuCollapsed ? {
                     minWidth: "4vw",
                     cursor: "pointer",
                     minHeight: "3vw"
                 } : {minWidth: "22vw"}}>
                <div className={"col-12 mx-xl-3 mx-lg-3 mx-2 text-center align-self-center"}>
                    <BsIcon.List color={"#FFFFFF"} width={32} height={32}/>
                </div>
            </div>
            <Collapse isOpen={!this.state.isMenuCollapsed}
                      navbar
                      onEntering={() => {
                          const breadcrumbContainer = document.getElementById("breadcrumbContainer") ?? null;
                          if (breadcrumbContainer) breadcrumbContainer.style.zIndex = '0';
                      }}
                      onExited={() => {
                          const breadcrumbContainer = document.getElementById("breadcrumbContainer") ?? null;
                          if (breadcrumbContainer) breadcrumbContainer.style.zIndex = '51';
                      }}>
                <div className={[styles.menuPosition, 'row'].join(' ')}>
                    <div style={{backgroundColor: '#4F4F4F'}} className={"col-10 col-sm-10 col-md-8 col-lg-6 col-xl-4"}>
                        <div className={styles.scroll}>
                            <div style={{color: "white", marginTop: '30px'}} className={"row pl-4 align-self-center"}>
                                <div className={'col-2'}>
                                    <BsIcon.Telephone color={"#ffffff"} width={25} height={25}/>
                                </div>
                                <div style={{marginLeft: '-40px'}} className={'col-4'}>
                                    CONTATTI UTILI
                                </div>
                            </div>
                            <div style={{color: "white", marginLeft: '71px'}} className={"row pl-4 align-self-center"}>
                                <ul style={{listStyle: 'none'}}>
                                    {!showElementiMenu ?
                                        <li className={styles.contattiUtili} onClick={() => {
                                            document.getElementsByTagName('body')[0].style.overflow = 'auto';
                                            if (this.props.containsOnlyRequiredFields || window.location.pathname.includes(routePath.stato_valutazione_componenti_team)) {
                                                this.clickItem(['anagrafePaziente', 'datiGenerali'], this.gestionePaginaLinkContatti(), false);
                                            } else {
                                                AccordionHelper.onScrollIntoAccordion('anagrafePaziente|datiGenerali');
                                            }
                                            this.setState({isMenuCollapsed: true});
                                        }}>
                                            Paziente
                                        </li>
                                        : ''}
                                    <li className={styles.contattiUtili}
                                        onClick={() => {
                                            if (!isPaginaComposizioneTeam) {
                                                InserimentoRichiesta.redirectToAnagrafeRichiedenteSubject.next();
                                            } else {
                                                this.setState({gotoAnagraficaRichiedente: true});
                                            }
                                            this.setState({isMenuCollapsed: true});
                                        }}>Medico richiedente
                                    </li>
                                    {!showElementiMenu && showTutore ?
                                        <li className={styles.contattiUtili}
                                            onClick={() => {
                                                if (this.props.containsOnlyRequiredFields || window.location.pathname.includes(routePath.stato_valutazione_componenti_team)) {
                                                    this.clickItem(['anagrafePaziente', 'tutore'], this.gestionePaginaLinkContatti(), false);
                                                } else {
                                                    AccordionHelper.onScrollIntoAccordion('anagrafePaziente|tutore');
                                                }
                                                this.setState({isMenuCollapsed: true});
                                            }}
                                        >Tutore</li>
                                        : !showElementiMenu && showAmministratoreDiSostegno ?
                                            <li className={styles.contattiUtili}
                                                onClick={() => {
                                                    if (this.props.containsOnlyRequiredFields || window.location.pathname.includes(routePath.stato_valutazione_componenti_team)) {
                                                        this.clickItem(['anagrafePaziente', 'amministratoreDiSostegno'], this.gestionePaginaLinkContatti(), false);
                                                    } else {
                                                        AccordionHelper.onScrollIntoAccordion('anagrafePaziente|amministratoreDiSostegno');
                                                    }
                                                    this.setState({isMenuCollapsed: true});
                                                }}
                                            >Amministratore di sostegno</li>
                                            : !showElementiMenu && showPersonaDiRiferimentoInfo ?
                                                <li className={styles.contattiUtili}
                                                    onClick={() => {
                                                        if (this.props.containsOnlyRequiredFields || window.location.pathname.includes(routePath.stato_valutazione_componenti_team)) {
                                                            this.clickItem(['situazioneSociale', 'personaDiRiferimento'], this.gestionePaginaLinkContatti(), false);
                                                        } else {
                                                            AccordionHelper.onScrollIntoAccordion('situazioneSociale|personaDiRiferimento');
                                                        }
                                                        this.setState({isMenuCollapsed: true});
                                                    }}
                                                >Persona di Riferimento</li>
                                                : ''}
                                </ul>
                            </div>
                            {showLinkTeam && (<div style={{color: "white"}} className={"row pl-4 align-self-center"}>
                                <div className={'col-2'}>
                                    <BsIcon.People color={"#FFFFFF"} width={25} height={25}/>
                                </div>
                                <div onClick={() => this.setState({redirectToStatoValutazioneComponentiTeam: true, isMenuCollapsed: true})
                                }
                                     style={{marginLeft: '-40px', cursor: 'pointer'}} className={'col-8'}>
                                    TEAM E MONITORAGGIO RICHIESTA
                                </div>
                            </div>)}
                            <div style={{color: "white", marginTop: '10px'}} className={"row pl-4 align-self-center"}>
                                <div className={'col-2'}>
                                    <BsIcon.Pen color={"#FFFFFF"} width={25} height={25}/>
                                </div>
                                <div style={{marginLeft: '-40px'}} className={'col-10'}>
                                    APPROFONDIMENTO VALUTAZIONE PAZIENTE
                                </div>
                            </div>
                            <ListGroup className={styles.listFirstLevelAccordions}>{this.formatMenuItems()}</ListGroup>
                        </div>
                    </div>
                    <div onMouseMove={() => {
                        document.getElementsByTagName('body')[0].style.overflow = 'auto';

                        this.setState({
                            isMenuCollapsed: true,
                            subMenuCollapsed: this.getSubMenuCollapsed(this.state.listaAccordion),
                            indexSelectedAccordion: null,
                            indexSelectedSubaccordion: null
                        });
                    }}
                         style={{backgroundColor: 'gray', opacity: "0.5", marginLeft: "-7px"}}
                         className={"col-2 col-sm-2 col-md-4 col-lg-6 col-xl-8"}>
                    </div>
                </div>
            </Collapse>
        </Navbar>;
    }


    formatMenuItems = () => {
        let menuItems = [];
        Object
            .keys(this.state.listaAccordion)
            .forEach((k, index) => {
                const item = this.state.listaAccordion[k];
                if (item.subAccordions.length > 0) {
                    let collapsedSubMenu = _.cloneDeep(this.state.subMenuCollapsed.find(e => e.firstLevel === index));
                    const indexCollapsed = this.state.subMenuCollapsed.findIndex(e => e.firstLevel === index);
                    const firstLevelItems = <Fragment>
                        <div className={'bd-highlight col-10'}>
                            <span style={{textDecoration: "underline"}}>{item?.accordionTitle}</span>
                            {item?.totalCountOptionalFields && <div className={'bd-highlight'} style={{float: 'right'}}>
                                <PopoverReact
                                    text={<BsIcon.InfoCircle color={"#176A65"} className={styles.customBadge} size={25} />}
                                    body={'Informazioni aggiunte rispetto alla Richiesta'}
                                    openOnMouseOver={true}
                                    position={'right'}
                                    className={'popover-info'}
                                />
                                {/* <Badge key={'accordion_badge_' + index} pill
                                       className={[styles.customBadge, 'col-2'].join(' ')}>
                                    {item.totalCountOptionalFields}
                                </Badge> */}
                            </div>}
                        </div>
                        <div
                            onClick={() => {
                                document.getElementsByTagName('body')[0].style.overflow = 'auto';
                                collapsedSubMenu.isSubMenuCollapsed = !collapsedSubMenu.isSubMenuCollapsed;
                                let copiaSubMenu = _.cloneDeep(this.state.subMenuCollapsed);
                                copiaSubMenu[indexCollapsed] = collapsedSubMenu;
                                copiaSubMenu.filter((_e, i) => i !== indexCollapsed).forEach(e => e.isSubMenuCollapsed = true);
                                this.isArrowClicked = true;
                                this.setState({subMenuCollapsed: copiaSubMenu});
                                this.setState({isMenuCollapsed: true});
                            }}>
                            <Icon
                                id={collapsedSubMenu?.isSubMenuCollapsed ? "it-arrow-down-triangle" : "it-arrow-up-triangle"}
                                className={item?.disabled && !this.state.canShowAllMenuItems ? styles.disabledArrowIcon : styles.activeArrowIcon}>
                            </Icon>
                        </div>
                    </Fragment>;
                    menuItems.push(<>
                            {this.getListGroupItemJSX(index, 'd-flex align-items-center activeArrowIcon', item, k, firstLevelItems)}
                            <Collapse isOpen={!collapsedSubMenu?.isSubMenuCollapsed}
                                      navbar
                                      style={{maxHeight: `${(item.subAccordions.length * 10)/2}%`}}>
                                <ListGroup className={styles.listSecondLevelAccordions}>
                                    {item.subAccordions.map((e, i) => (
                                        <ListGroupItem key={i}
                                                       active={i === this.state.indexSelectedSubaccordion}
                                                       className={'d-flex justify-content-between'}
                                                       style={!item?.disabled || this.state.canShowAllMenuItems ? {cursor: "pointer"} : {}}
                                                       disabled={e.disabled && !this.state.canShowAllMenuItems}
                                                       action={!e.disabled || this.state.canShowAllMenuItems}
                                                       onClick={() => {
                                                           document.getElementsByTagName('body')[0].style.overflow = 'auto';
                                                           this.setState({indexSelectedSubaccordion: i});
                                                           this.clickItem([k, e.idAccordion], item.page);
                                                           this.setState({isMenuCollapsed: true});

                                                       }}>
                                            <Fragment>
                                                <span style={{textDecoration: "underline"}}>{e?.["subaccordionTitle"]}</span>
                                                {e?.countOptionalFields &&
                                                    <div className={'align-self-center'}>
                                                        <Badge key={'subaccordion_badge_' + index} pill
                                                               className={['mx-5', styles.customBadge].join(' ')}>
                                                            {e.countOptionalFields}
                                                        </Badge>
                                                    </div>}
                                            </Fragment>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </Collapse>
                        </>
                    );
                } else {
                    const children = <Fragment>
                        <span style={{textDecoration: "underline"}}>{item?.accordionTitle}</span>
                        {item?.totalCountOptionalFields && <div className={' align-self-center'}>
                            <Badge key={'accordion_badge_' + index} pill
                                   className={['mx-5', styles.customBadge].join(' ')}>
                                {item.totalCountOptionalFields}
                            </Badge>
                        </div>}
                    </Fragment>;
                    menuItems.push(this.getListGroupItemJSX(index, 'd-flex align-items-center', item, k, children));
                }
            });
        return menuItems;
    }

    getListGroupItemJSX = (index, className, item, idAccordionFirstLevel, children) => {
        return <ListGroupItem key={index}
                              active={index === this.state.indexSelectedAccordion}
                              className={className}
                              style={!item?.disabled || this.state.canShowAllMenuItems ? {cursor: "pointer"} : {}}
                              disabled={item?.disabled && !this.state.canShowAllMenuItems}
                              action={!item?.disabled || this.state.canShowAllMenuItems}
                              onClick={() => {
                                  document.getElementsByTagName('body')[0].style.overflow = 'auto';
                                  if (!this.isArrowClicked) {
                                      this.clickItem([idAccordionFirstLevel], item.page, false);
                                  }
                                  let indexSelectedSubaccordion = _.cloneDeep(this.state.indexSelectedSubaccordion);
                                  if (index !== this.state.indexSelectedAccordion) indexSelectedSubaccordion = null;
                                  this.setState({
                                      indexSelectedAccordion: index,
                                      indexSelectedSubaccordion: indexSelectedSubaccordion
                                  });
                                  this.isArrowClicked = false;
                                  this.setState({isMenuCollapsed: true});
                              }}>
            {children}
        </ListGroupItem>;
    }

    clickItem = (idsSelected, page) => {
        const currentUrl = _.cloneDeep(window.location.pathname.replaceAll(routePath.stato_valutazione_componenti_team, ''));
        const lastPath = currentUrl.substring(currentUrl.lastIndexOf('/'));
        const selectedAccordions = idsSelected.join('|');
        const visualizzaValutazionePath = routePath.visualizza_valutazione_multidimensionale;
        const accediValutazionePath = routePath.accedi_valutazione_multidimensionale;

        let finalPage = _.cloneDeep(page);
        if (!this.props.showMultiCompilationPages && PAGE_2.some(p => page.startsWith(p))) finalPage = PAGE_1[0];

        AccordionHelper.setIdSelectedAccordions(selectedAccordions);

        // logica di redirect per la pagina con gli accordion in sola visualizzazione
        if ((lastPath === routePath.verifica_valutazione || lastPath === routePath.visualizza_valutazione_multidimensionale)
            && window.location.pathname.includes(routePath.stato_valutazione_componenti_team)) {
            this.setAccordionPageUrl(currentUrl, PAGE_1.includes(page) ? 1 : 2);
            return;
        }

        switch (finalPage) {
            case PAGE_1[0]:
                this.casisticaPaginaSchedaPaziente(lastPath, accediValutazionePath, visualizzaValutazionePath, selectedAccordions, currentUrl);
                break;
            case PAGE_2[0]:
            case PAGE_2[1]:
                this.casisticaPaginaProposte(lastPath, accediValutazionePath, visualizzaValutazionePath, selectedAccordions, currentUrl);
                break;
            default:
                if (window.location.pathname.endsWith(routePath.stato_valutazione_componenti_team)) {
                    this.setAccordionPageUrl(currentUrl);
                } else {
                    AccordionHelper.onScrollIntoAccordion(selectedAccordions, _.isEqual(lastPath, routePath.visualizza_riepilogo), 1000);
                }
                break;
        }
    }

    casisticaPaginaSchedaPaziente = (lastPath, accediValutazionePath,
                                     visualizzaValutazionePath, selectedAccordions,
                                     currentUrl) => {
        const validRoutePath = [routePath.compila_scheda_paziente, routePath.completa_valutazione_multidimensionale,
            routePath.visualizza_riepilogo, visualizzaValutazionePath, accediValutazionePath];

        if (validRoutePath.includes(lastPath) && !this.props.containsOnlyRequiredFields) {
            if ([visualizzaValutazionePath, accediValutazionePath].includes(lastPath)) {
                const idFirstLevel = selectedAccordions.split('|')[0];
                const selector = $(`#${idFirstLevel}`)
                const isIdFirstPage = this.checkIdAccordionPage(PAGE_1, idFirstLevel);
                const isIdSecondPage = this.checkIdAccordionPage(PAGE_2, idFirstLevel);

                if (isIdFirstPage && selector.length === 0) this.setAccordionPageUrl(currentUrl, 1);
                else if (isIdSecondPage && selector.length === 0) this.setAccordionPageUrl(currentUrl, 2);
                else AccordionHelper.onScrollIntoAccordion(selectedAccordions, _.isEqual(lastPath, routePath.visualizza_riepilogo), 1000);
            } else {
                AccordionHelper.onScrollIntoAccordion(selectedAccordions, _.isEqual(lastPath, routePath.visualizza_riepilogo), 1000);
            }
        } else if (lastPath === visualizzaValutazionePath || lastPath === accediValutazionePath || lastPath === routePath.verifica_valutazione) {
            this.setAccordionPageUrl(currentUrl, 1);
        } else if (Utils.isStateRichiesta(this.props.pageState) || Utils.isStateRichiestaPreDialogo(this.props.pageState)) {
            this.setAccordionPageUrl(currentUrl.replace(lastPath, routePath.compila_scheda_paziente));
        } else {
            this.setAccordionPageUrl(currentUrl.replace(lastPath, routePath.completa_valutazione_multidimensionale));
        }
    }

    casisticaPaginaProposte = (lastPath, accediValutazionePath,
                               visualizzaValutazionePath, selectedAccordions,
                               currentUrl) => {
        const validRoutePath = [routePath.compila_proposte, routePath.visualizza_riepilogo,
            visualizzaValutazionePath, accediValutazionePath];

        if (validRoutePath.includes(lastPath) && !this.props.containsOnlyRequiredFields) {
            if ([visualizzaValutazionePath, accediValutazionePath].includes(lastPath)) {
                const idFirstLevel = selectedAccordions.split('|')[0];
                const selector = $(`#${idFirstLevel}`)
                const isIdSecondPage = this.checkIdAccordionPage(PAGE_2, idFirstLevel);
                const isIdFirstPage = this.checkIdAccordionPage(PAGE_1, idFirstLevel);

                if (isIdSecondPage && selector.length === 0) this.setAccordionPageUrl(currentUrl, 2);
                else if (isIdFirstPage && selector.length === 0) this.setAccordionPageUrl(currentUrl, 1);
                else AccordionHelper.onScrollIntoAccordion(selectedAccordions, _.isEqual(lastPath, routePath.visualizza_riepilogo), 1000);
            } else {
                AccordionHelper.onScrollIntoAccordion(selectedAccordions, _.isEqual(lastPath, routePath.visualizza_riepilogo), 1000);
            }
        } else if (lastPath === visualizzaValutazionePath || lastPath === accediValutazionePath || lastPath === routePath.verifica_valutazione) {
            this.setAccordionPageUrl(currentUrl, 2);
        } else {
            this.setAccordionPageUrl(currentUrl.replace(lastPath, routePath.compila_proposte));
        }
    }

    checkIdAccordionPage = (page, idToMatch) => {
        return Object.keys(this.state.listaAccordion).filter(k => page.includes(this.state.listaAccordion[k].page)).includes(idToMatch);
    }

    setAccordionPageUrl = (redirectUrl, lastPageNumber = null) => {
        AccordionHelper.setLastPageUrlBeforeMenuItemClick(window.location.pathname);
        InserimentoRichiesta.salvaBozzaSubject.next(true);

        this.setState({
            redirectToAccordionPage: {
                key: uuid(),
                pathname: redirectUrl,
                uuidRichiesta: this.props.richiesta?.id,
                pageState: this.props.pageState,
                readOnly: this.props.readOnly,
                hideUnsetValues: this.props.hideUnsetValues,
                firstPageName: window.location.pathname.replaceAll(routePath.stato_valutazione_componenti_team, ''),
                persone: this.props.persone,
                tipologia: this.props.richiesta?.tipologia,
                stato: this.props.richiesta?.stato,
                valutazionePrecedente: (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) ? getValutazionePrecedente(this.props.richiesta) : null,
                showOnlyRequiredFields: false,
                previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields != null ? this.props.previousPageOnlyRequiredFields : this.props.containsOnlyRequiredFields,
                lastPageNumber: lastPageNumber
            }
        }, () => document.getElementsByTagName('body')[0].style.overflow = 'auto');
    }

    render() {
        if (this.state.redirectToStatoValutazioneComponentiTeam) {
            return <Redirect to={{
                pathname: window.location.pathname.concat(routePath.stato_valutazione_componenti_team),
                richiesta: this.props.richiesta,
                pageState: this.props.pageState,
                showAllMenuItems: this.state.canShowAllMenuItems,
                readOnly: this.props.readOnly,
                hideUnsetValues: this.props.hideUnsetValues,
                richiestaMedico: this.props.richiestaMedico,
                pageName: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
                persone: this.props.persone,
                showOnlyRequiredFields: this.props.containsOnlyRequiredFields,
                containsOnlyRequiredFields: this.props.containsOnlyRequiredFields,
                previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields != null ? this.props.previousPageOnlyRequiredFields : this.props.containsOnlyRequiredFields,
                showOptionalFieldsCount: this.props.showOptionalFieldsCount
            }}/>;
        }

        if (this.state.gotoAnagraficaRichiedente) {
            return <Redirect to={{
                pathname: window.location.pathname.concat(routePath.anagrafica_medico_richiedente),
                idPersona: Utils.recuperoIdProfessionista(this.props.richiesta),
                uuidRichiesta: this.props.richiesta.id,
                pageState: this.props.pageState,
                pageName: this.props.pageName,
                readOnly: !isSomeFieldIdWriteable('ANAGRAFESOGGETTORICHIEDENTE', this.props.pageState) || !!this.props.readOnly,
                hideUnsetValues: this.props.hideUnsetValues,
                firstPageName: this.props.firstPageName,
                insRichiestaPathname: window.location.pathname,
                richiesta: this.props.richiesta,
                showOnlyRequiredFields: this.props.containsOnlyRequiredFields,
                previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields != null ? this.props.previousPageOnlyRequiredFields : this.props.containsOnlyRequiredFields,
                showMultiCompilationPages: this.props.showMultiCompilationPages
            }}/>;
        }

        if (!!this.state.additionalPropsRedirectFullAccordionPage?.canRedirectToFullAccordionPage && this.state.redirectToAccordionPage) {
            AccordionHelper.requiredAccordionFieldsSubject.next({
                canRedirectToFullAccordionPage: false,
                richiesta: null,
                richiestaMedico: null,
                persone: this.props.persone
            });
            return <Redirect to={{
                ...this.state.redirectToAccordionPage,
                richiesta: this.state.additionalPropsRedirectFullAccordionPage.richiesta,
                richiestaMedico: this.state.additionalPropsRedirectFullAccordionPage.richiestaMedico ?? this.props.richiestaMedico,
                persone: this.state.additionalPropsRedirectFullAccordionPage.persone ?? this.props.persone,
                scrollIntoAccordionAfterRedirect: true
            }}/>;
        } else if (window.location.pathname.endsWith(routePath.stato_valutazione_componenti_team) && this.state.redirectToAccordionPage) {
            return <Redirect to={{...this.state.redirectToAccordionPage, scrollIntoAccordionAfterRedirect: true}}/>;
        }

        return this.getNavbarJSX();
    }
}

MenuAccordion.propTypes = {
    richiesta: PropTypes.object,
    pageState: PropTypes.string,
    showAllMenuItems: PropTypes.bool,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    richiestaMedico: PropTypes.object,
    persone: PropTypes.object,
    containsOnlyRequiredFields: PropTypes.bool,
    previousPageOnlyRequiredFields: PropTypes.any,
    showOptionalFieldsCount: PropTypes.bool,
    pageName: PropTypes.string,
    firstPageName: PropTypes.string,
    showMultiCompilationPages: PropTypes.bool
}

MenuAccordion.defaultProps = {
    previousPageOnlyRequiredFields: null,
    showOptionalFieldsCount: true,
    pageName: null,
    firstPageName: null,
    showMultiCompilationPages: true
}
