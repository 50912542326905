import React from "react";
import Accordion from "../../accordion/Accordion";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedRadio,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";
import {Icon} from "web-client-archetype";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import {ProposteAttivitaSuggerimentiList} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import {isArray} from "lodash";

const propTypes = {
    dati: PropTypes.any,
    idPatologiaItem: PropTypes.string,
    argomentoField: PropTypes.string,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    mascheraModificabilita: PropTypes.any,
    ambito: PropTypes.string,
    fieldInAccordionReadOnly: PropTypes.bool
}

export default class FormVariabili extends Accordion {

    state = {
        dati: []
    }

    componentDidMount() {
        this.setDati()
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!Utils.arrayEquals(prevProps.dati, this.props.dati)) {
            let datiModificati = _.map(this.props.dati, (dato, index) => {
                // verificare la possibilità di utilizzare isEqual di lodash
                return JSON.stringify(dato) !== JSON.stringify(prevProps.dati[index]);
            })

            if (datiModificati.includes(true)) {
                this.setDati()
            }
        }
    }

    setDati = () => {
        this.setState({dati: this.props.dati})
    }

    getForm = (item, rowJSX) => {
        if (item.visible) {
            if (Utils.isObjectEmpty(item?.propostaAttivitaSuggerimenti)) return (<FieldsRow>{rowJSX}</FieldsRow>);
            else return rowJSX;
        } else return null;
    }

    render = () => {
        let dati = _.cloneDeep(this.props.dati);

        let formVariabiliJSX = [];

        _.forEach(dati, (item) => {
            let rowJSX = [];
            _.forIn(item, (valueItem, key) => {
                if (key !== "visible") {
                    let elementJSX;
                    let valoreCampoElementoListaMaschera = Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.anamnesiPatologicaRemota?.[this.props.argomentoField]?.lista, valueItem.field, this.props.idPatologiaItem);
                    if (valueItem.tipo === "autocomplete" && valueItem.field === "diagnosiPatologiaPregressa") {
                        elementJSX = <RoleBasedICD9Input
                            fieldId={valueItem.fieldId}
                            pageState={this.props.pageState}
                            id={valueItem.id}
                            field={valueItem.field}
                            placeholder={valueItem.placeholder}
                            value={valueItem.value}
                            inputValue={valueItem.value}
                            onChange={(field, value) => valueItem.onChange(valueItem.field, value.target.value)}
                            noOptionsMessage={() => "Errore: Motivazione non esistente"}
                            forceReadOnly={this.props.forceReadOnly || valoreCampoElementoListaMaschera}
                            fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            fieldLabel={valueItem.label}
                            listId={[{name: this.props.field, id: this.props.idPatologiaItem}]}
                            parentJsonPath={'anamnesiPatologicaRemota'}
                        />;
                    }
                    else if (valueItem.tipo === "data") {
                        elementJSX = <RoleBasedInput
                            fieldId={valueItem.fieldId}
                            pageState={this.props.pageState}
                            minLength={"4"}
                            maxlength={"4"}
                            placeholder={valueItem.placeholder}
                            field={valueItem.field}
                            value={valueItem.value}
                            onChange={(field, elem) => valueItem.onChange(field, elem)}
                            id={valueItem.id}
                            forceReadOnly={this.props.forceReadOnly || valoreCampoElementoListaMaschera}
                            fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            invalidText={'Data non valida'}
                            validator={val => !val || val.match(/^(19|20)\d{2}$/)}
                            listId={[{name: this.props.field, id: this.props.idPatologiaItem}]}
                            parentJsonPath={'anamnesiPatologicaRemota'}
                            fieldLabel={valueItem.label}
                            notForzeNull={true}
                            type={'number'}
                        />;
                    }
                    else if (valueItem.tipo === "radio") {
                        elementJSX = <RoleBasedRadio
                            fieldId={valueItem.fieldId}
                            pageState={this.props.pageState}
                            id={valueItem.id}
                            group={valueItem.group}
                            onChange={(field, value) => valueItem.onChange(valueItem.field, value)}
                            value={valueItem.value}
                            items={valueItem.items}
                            customStyles={valueItem.customStyles}
                            customItemStyles={valueItem.customItemStyles}
                            forceReadOnly={this.props.forceReadOnly || valoreCampoElementoListaMaschera}
                            fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            listId={[{name: this.props.field, id: this.props.idPatologiaItem}]}
                            parentJsonPath={'anamnesiPatologicaRemota'}
                            fieldLabel={valueItem.label}
                        />;
                    }
                    else if (valueItem.tipo === "select") {
                        elementJSX = <RoleBasedSelect
                            fieldId={valueItem.fieldId}
                            pageState={this.props.pageState}
                            id={valueItem.id}
                            options={valueItem.options}
                            value={valueItem.value}
                            onChange={(elem) => valueItem.onChange(valueItem.field, elem)}
                            isSearchable={false}
                            noOptionsMessage={() => valueItem.noOptionsMessage}
                            forceReadOnly={this.props.forceReadOnly || valoreCampoElementoListaMaschera || valueItem?.readOnly}
                            fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={valueItem.field}
                            listId={[{name: this.props.field, id: this.props.idPatologiaItem}]}
                            parentJsonPath={'anamnesiPatologicaRemota'}
                            fieldLabel={valueItem.label}
                            handleOnlyValue={true}
                        />;
                    }
                    else if (valueItem.tipo === "input") {
                        elementJSX = <RoleBasedInput
                            fieldId={valueItem.fieldId}
                            pageState={this.props.pageState}
                            value={valueItem.value}
                            placeholder={valueItem.placeholder}
                            field={valueItem.field}
                            onChange={(field, elem) => valueItem.onChange(field, elem)}
                            id={valueItem.id}
                            forceReadOnly={this.props.forceReadOnly || valoreCampoElementoListaMaschera}
                            fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            listId={[{name: this.props.field, id: this.props.idPatologiaItem}]}
                            parentJsonPath={'anamnesiPatologicaRemota'}
                            fieldLabel={valueItem.label}
                        />;
                    }
                    else if (valueItem.tipo === "propostaAttivitaSuggerimenti") {
                        elementJSX = <ProposteAttivitaSuggerimentiList
                        suggerimenti={valueItem.value && !isArray(valueItem.value) ? [valueItem.value] : valueItem.value  || []}
                        pageState={this.props.pageState} 
                        id={valueItem.id}
                        parentFieldId={valueItem.fieldId}
                        infParentFieldId={valueItem.fieldId}
                        forceReadOnly={this.props.forceReadOnly || valoreCampoElementoListaMaschera}
                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        onChange={(field, elem) => valueItem.onChange(field, elem)}
                        fieldLabel={valueItem.label}
                        ambito={this.props.ambito + ' ' + (valueItem.indexItem + 1)}
                        checkboxIdSuffix={valueItem.checkboxIdSuffix}
                        parentJsonPath={`anamnesiPatologicaRemota.${this.props.field}[?(@.id == '${this.props.idPatologiaItem}')]`}
                    />
                    }
                    else if (valueItem.tipo === "button" && !this.props.forceReadOnly && !this.context.forceReadOnly) {
                        elementJSX = <button className="btn btn-link"
                                             onClick={() => valueItem.onClick()}
                                             disabled={valueItem.disabled}>
                            {valueItem.text}
                        </button>;
                    }
                    else if (valueItem.tipo === "icon" && !this.props.forceReadOnly && !this.context.forceReadOnly) {
                        elementJSX = <>
                            <div className={["col-" + valueItem.colSize, "align-self-end"].join(" ")}>
                                <span onClick={() => valueItem.onClick()} style={{cursor: "pointer"}}>
                                    <Icon id={valueItem.id}
                                          className={["bg-white", "icon", "icon-green", "icon-lg"]}/>
                                </span>
                            </div>
                        </>
                    }
                    rowJSX.push(elementJSX)
                }
            })

            formVariabiliJSX.push(this.getForm(item, rowJSX));
        });

        return formVariabiliJSX;
    }
}

FormVariabili.propTypes = propTypes;

FormVariabili.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false,
    fieldInAccordionReadOnly: false
}

FormVariabili.contextType = RoleBasedContext;
