import React from "react";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import PropTypes from "prop-types";
import * as _ from "lodash";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import Statistica from "../../statistica/Statistica";
import {FieldsRow, RoleBasedSelect} from "../../roleBasedComponents/RoleBasedComponents";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import enumQuestionario from "../../../enum/enumQuestionario";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco, defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    datiIADL: PropTypes.any,
    onChangeDatiIADL: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    richiesta: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class AttivitaStrumentaliDellaVitaQuotidiana extends AccordionSecondLevel {

    state = {
        datiIADL: _.cloneDeep(this.props.datiIADL)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiIADL, this.props.datiIADL))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiIADL = _.cloneDeep(this.props.datiIADL);
        this.setState({datiIADL: datiIADL}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiIADL}) => {
            datiIADL[field] = elem;
            if (!datiIADL?.valutazioneNecessaria) {
                datiIADL.IADL = null;
                datiIADL.statoDopoValutazione = null;
            }
            return {datiIADL};
        }, () => this.validazioneDati());
    }

    validazioneDati = () => {
        let datiIADL = _.cloneDeep(this.state.datiIADL);
        this.props.onChangeDatiIADL("datiIADL", datiIADL);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiIADL?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneAttivitaStrumentaliDellaVitaQuotidiana", campiObbligatoriRimanenti);
    }

    renderBodyIADL = () => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_PUNTEGGIOIADL_SCALADILAWTON.ATTIVITASTRUMENTALIDELLAVITAQUOTIDIANA.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_PUNTEGGIOIADL_SCALADILAWTON.ATTIVITASTRUMENTALIDELLAVITAQUOTIDIANA.CAPACITAECURADISE'}
                        pageState={this.props.pageState}
                        id={"valutazioneAttivitaIADL"}
                        className={"valutazioneAttivitaIADL"}
                        options={OggettiUtili.rispostaBreve}
                        value={this.state.datiIADL.valutazioneNecessaria}
                        onChange={(elem) => this.handleAggiornaForm("valutazioneNecessaria", elem)}
                        isSearchable={false}
                        fieldLabel={"È necessaria la valutazione delle attività della vita quotidiana (IADL)?"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"valutazioneNecessaria"}
                        parentJsonPath={'anamnesifisiologica.iadl'}
                        handleOnlyValue={true}
                    />
                </FieldsRow>

                {this.state.datiIADL.valutazioneNecessaria &&
                    <Statistica
                        fieldId={'M_PUNTEGGIOIADL_SCALADILAWTON.ATTIVITASTRUMENTALIDELLAVITAQUOTIDIANA.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_PUNTEGGIOIADL_SCALADILAWTON.ATTIVITASTRUMENTALIDELLAVITAQUOTIDIANA.CAPACITAECURADISE'}
                        pageState={this.props.pageState}
                        nomeLink={"Compila questionario \"Attività strumentali della vita quotidiana - IADL\""}
                        nomePunteggio={"Punteggio IADL – Scala di Lawton"}
                        questionario={this.state.datiIADL.IADL}
                        profileName={'Esito IADL'}
                        profileFieldId={'M_ESITOIADL.ATTIVITASTRUMENTALIDELLAVITAQUOTIDIANA.ANAMNESIFISIOLOGICA'}
                        onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.IADL, questionario)}
                        richiesta={this.props.richiesta}
                        pathRichiestaQuestionario={"anamnesiFisiologica.datiIADL.IADL"}
                        tipoQuestionario={enumQuestionario.tipoQuestionario.IADL}
                        profileRequired={false}
                        salvaBozza={this.props.salvaBozza}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        scoreMandatoryForRole={null}
                        parentJsonPath={'anamnesifisiologica.iadl.iadl'}
                    />}

                <ValutazioneSection
                    ambito={"ATTIVITÀ STRUMENTALI DELLA VITA QUOTIDIANA (IADL)"}
                    pageState={this.props.pageState}
                    parentFieldId={'ATTIVITASTRUMENTALIDELLAVITAQUOTIDIANA.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'ATTIVITASTRUMENTALIDELLAVITAQUOTIDIANA.CAPACITAECURADISE'}
                    appendRolePrefix={true}
                    valueStatoDopoValutazione={this.state.datiIADL.statoDopoValutazione}
                    onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                    valueDataValutazione={this.state.datiIADL.dataValutazione}
                    onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                    valueDaConsiderare={this.state.datiIADL.daConsiderare}
                    onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                    showSuggerimenti={true}
                    valueSuggerimenti={this.state.datiIADL.propostaAttivitaSuggerimenti}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={this.state.datiIADL.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={this.state.datiIADL.autonomiaGestioneCaregiver}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    openCondition={this.state.datiIADL.valutazioneNecessaria}
                />
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiIADL"}
                title={"Attività strumentali della vita quotidiana (IADL)"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["anamnesiFisiologica.datiIADL.propostaAttivitaSuggerimenti"],
                    [defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodyIADL()}</AccordionSezioneRichiesta>
        );
    }
}

AttivitaStrumentaliDellaVitaQuotidiana.propTypes = propTypes;

AttivitaStrumentaliDellaVitaQuotidiana.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

