import React from "react";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import PropTypes from "prop-types";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import * as _ from "lodash";
import SituazioneAbitativa from "./situazioneAbitativa/SituazioneAbitativa";
import AccordionHelper from "../../service/AccordionHelper";
import VersionedAccordion from "../VersionedAccordion";
import Utils from "../../utils/Utils";

const propTypes = {
    benessereAbitativo: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeBenessereAbitativo: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

export default class BenessereAbitativo extends AccordionFirstLevel {

    state = {
        campiObbligatoriMancanti: {
            sottosezioneSituazioneAbitativa: 0,
        },
        accordion: {
            openAccordionSituazioneAbitativa: true
        }
    }

    campiObbligatori = {...this.state.campiObbligatoriMancanti};

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;
        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            })
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta.benessereAbitativo = this.props.benessereAbitativo;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        this.campiObbligatori[field] = value;
        this.setState({campiObbligatoriMancanti: _.cloneDeep(this.campiObbligatori)}, () => {
            this.props.onChangeObbligatorieta("benessereAbitativo", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"benessereAbitativo"}
                title={"Benessere abitativo"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}>
                <VersionedAccordion accordion={SituazioneAbitativa}
                    datiSituazioneAbitativa={this.props.benessereAbitativo.situazioneAbitativa}
                    onChangeBenessereAbitativo={this.props.onChangeBenessereAbitativo}
                    openAccordion={this.state.accordion.openAccordionSituazioneAbitativa}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSituazioneAbitativa"}
                    pageState={this.props.pageState}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                    accordionReadOnly={this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </AccordionAvvioRichiesta>
        );
    }
}

BenessereAbitativo.propTypes = propTypes;
