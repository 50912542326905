import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionHelper from "../../../service/AccordionHelper";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import Urogenitale from "../../esameObiettivo/urogenitale/Urogenitale";
import Diuresi from "../../anamnesiFisiologica/diuresi/Diuresi";
import VersionedAccordion from "../../VersionedAccordion";
import Utils from "../../../utils/Utils";

const propTypes = {
    esameObiettivo: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeEsameObiettivo: PropTypes.func,
    openAccordion: PropTypes.any,
    openAccordionCapo: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

export default class FunzioneUrinaria extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: {
            sottosezioneDiuresi: 0,
            sottosezioneUrogenitale:0
        },
        accordion: {
            openAccordionOrofaringe: true,
            openAccordionDiuresi: true
        }
    }

    campiObbligatori = {...this.state.campiObbligatoriMancanti};

    handleChangeRequiredFieldsNumber = (field, value) => {
        this.campiObbligatori[field] = value;
        this.setState({campiObbligatoriMancanti: _.cloneDeep(this.campiObbligatori)}, () => {
            this.props.onChangeObbligatorieta("funzioneUrinaria", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }


    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta.esameObiettivo = this.props.esameObiettivo;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    renderBodySezione = () => {
        return (
            <>
                <VersionedAccordion accordion={Urogenitale}
                    urogenitale={this.isSessoM() ? this.props.esameObiettivo.urogenitaleMaschile : this.props.esameObiettivo.urogenitaleFemminile}
                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                    openAccordion={this.state.accordion.openAccordionOrofaringe}
                    onClickAccordion={this.onClickAccordion}
                    pageState={this.props.pageState}
                    sessoPaziente={this.props.sessoPaziente}
                    tipoUrogenitale={this.isSessoM() ? "Maschile" : "Femminile"}
                    showAccordion={true}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    disableDoubleInput={Utils.isRuoloInfermiere()}
                />

                <VersionedAccordion accordion={Diuresi}
                    dati={this.props.datiAnamnesiFisiologica.datiDiuresi}
                    onChangeDatiDiuresi={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionDiuresi}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionDiuresi"}
                    pageState={this.props.pageState}
                    isOptional={false}
                    onHide={() => this.hideOptionalAccordion('visualizzaAccordionDiuresi')}
                    accordionReadOnly={this.props.accordionReadOnly}
                />
            </>
        );
    }

    isSessoM() {
        return this.props.sessoPaziente === "01";
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"funzioneUrinariaInfermiere"}
                title={"Funzione urinaria"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBodySezione()}</AccordionAvvioRichiesta>
        );
    }
}

FunzioneUrinaria.propTypes = propTypes;
