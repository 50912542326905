import React from "react";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import ToponomasticaUtils from "../../../utils/ToponomasticaUtils";
import PropTypes from "prop-types";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {
    FieldsRow,
    RoleBasedAutocomplete,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedPhoneInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import moment from "moment";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";

const propTypes = {
    datiGeneraliForm: PropTypes.any,
    datiA0: PropTypes.any,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onValidaSezione: PropTypes.func,
    onChangeDatiAssistito: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export const isMadrelingua = (madrelingua) => {
    return Utils.isObjectNull(madrelingua) || madrelingua.value === null || madrelingua.value === true;
}

export default class DatiGenerali extends React.Component {

    state = {
        datiGenerali: _.cloneDeep(this.props.datiGeneraliForm),
        numeroCampiObbligatori: 0,
        campiObbligatoriString: ["cognome", "nome", "sesso", "dataNascita", "identificativoUtente"],
        campiObligatoriObject: ["comune", "provincia", "nazione", "cittadinanza"],
        campiSelect: ["madrelingua|rispostaBreve", "livello|linguaItalianaParlataCompresa", "sesso",
            "descrizione|elencoLingue", "titoloStudio|titoliStudio", "cittadinanza|cittadinanzaAnagrafePaziente",
            "ilPazienteEInformatoDellaDomanda|rispostaBreve"],
        campiAutocomplete: ["comune", "provincia", "nazione"]
    };

    listaComuni = [];
    listaProvince = [];
    listaNazioni = [];

    constructor(props) {
        super(props);
        this.handleAggiornaDatiGenerali = this.handleAggiornaDatiGenerali.bind(this);
        this.riceviValoreInput = this.riceviValoreInput.bind(this);
    }

    componentDidMount() {
        this.listaComuni = ToponomasticaUtils.getComuni();
        this.listaProvince = ToponomasticaUtils.getProvince();
        this.listaNazioni = ToponomasticaUtils.getNazioni();
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiGeneraliForm, this.props.datiGeneraliForm))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiRecuperati = _.cloneDeep(this.props.datiGeneraliForm);
        if (!Utils.isObjectNull(datiRecuperati)) {
            if (!Utils.isStringEmptyOrNullOrUndefined(datiRecuperati.dataNascita))
                datiRecuperati.dataNascita = !Utils.isStringEmptyOrNullOrUndefined(datiRecuperati.dataNascita)
                && moment(datiRecuperati.dataNascita, ["DD/MM/YYYY", "YYYY-MM-DD", "YYYY-MM-DDTHH:mm:ssZ"], true).isValid()
                    ? Utils.transformTimeStampForFrontend(datiRecuperati.dataNascita) : datiRecuperati.dataNascita;

            if (!Utils.isStringEmptyOrNullOrUndefined(datiRecuperati.comune)) {
                let comune = this.listaComuni.find(c => c.codice === datiRecuperati.comune) ?? null;
                datiRecuperati.comune = comune ? this.getOggettoComune(comune) : "";
            } else {
                datiRecuperati.comune = "";
            }

            if (!Utils.isStringEmptyOrNullOrUndefined(datiRecuperati.provincia)) {
                let provincia = this.listaProvince.find(p => p.codice === datiRecuperati.provincia) ?? null;
                datiRecuperati.provincia = provincia ? this.getOggettoProvincia(provincia) : "";
            } else {
                datiRecuperati.provincia = "";
            }

            if (!Utils.isStringEmptyOrNullOrUndefined(datiRecuperati.nazione)) {
                let nazione = this.listaNazioni.find(n => n.codice === datiRecuperati.nazione) ?? null;
                datiRecuperati.nazione = nazione ? this.getOggettoNazione(nazione) : "";
            } else {
                datiRecuperati.nazione = "";
            }

            if ((this.props.datiA0.comune || this.props.datiA0.provincia)) {
                if(!this.props.datiA0.cittadinanza) datiRecuperati.cittadinanza = OggettiUtili.cittadinanzaAnagrafePaziente.find(citt => citt.value === "01")?.value;
                if(this.props.datiA0.comune && !this.props.datiA0.provincia) {
                    let comuneDatiA0 = this.listaComuni.find(c => c.codice === this.props.datiA0.comune) ?? null;
                    datiRecuperati.comune = comuneDatiA0 ? this.getOggettoComune(comuneDatiA0) : "";
     
                    let provinciaDatiA0 = this.listaProvince.find(p => p.codice === comuneDatiA0.codiceProvincia) ?? null;
                    datiRecuperati.provincia = provinciaDatiA0 ? this.getOggettoProvincia(provinciaDatiA0) : "";
                }
            }

            this.formatSelectObject(datiRecuperati);
        }
        this.setState({datiGenerali: datiRecuperati}, () => {
            this.countCampiObbligatori(this.state.datiGenerali);
            this.validaCampi(this.state.datiGenerali);
        });
    }

    formatSelectObject = (datiGenerali) => {
        this.state.campiSelect.forEach(field => {
            let split = field.split("|");
            if (split.length === 2) {
                let value = split[0] === "cittadinanza" && datiGenerali[split[0]] !== null
                    ? datiGenerali[split[0]].charAt(0).toUpperCase() + datiGenerali[split[0]].slice(1).toLocaleLowerCase()
                    : datiGenerali[split[0]];

                if (split[0] === "descrizione")
                    datiGenerali.descrizione = datiGenerali.descrizione.map(el => OggettiUtili[split[1]].find(el2 => el2.value === el));
                else
                    datiGenerali[split[0]] = OggettiUtili[split[1]].find(el => el.value === value || el.label === value) ?? null;
            } else {
                datiGenerali[split[0]] = OggettiUtili[split[0]].find(el => el.value === datiGenerali[split[0]] || el.label === datiGenerali[split[0]]) ?? null;
            }
        });
    }

    handleAggiornaDatiGenerali(field, event) {
        let state = _.cloneDeep(this.state.datiGenerali);
        if (field.currentTarget) {
            state[field.currentTarget.id] = field.currentTarget.value;
        } else {
            state[field] = event.target.value;
        }
        this.validaCampi(state)
    }

    riceviValoreInput(field, event) {
        let newDatiGenerali = _.cloneDeep(this.state.datiGenerali);
        if (field.currentTarget) {
            newDatiGenerali[field.currentTarget.id] = field.currentTarget.value
        } else if (field === "comune" || field === "provincia" || field === "nazione") {
            newDatiGenerali[field] = event.target.value;
            if (field === "comune") {
                if(!this.props.datiA0.comune && !this.props.datiA0.provincia) newDatiGenerali.provincia = "";
            }
            if (event?.target?.value?.codiceProvincia) {
                let provinceSuggestions;
                provinceSuggestions = !Utils.isObjectNull(newDatiGenerali[field]?.codice)
                    ? this.listaProvince.filter(p => p.codice === newDatiGenerali[field].codice.substring(0, 3))
                    : this.listaProvince;
                let provincia = this.getOggettoProvincia(provinceSuggestions[0]);
                newDatiGenerali["provincia"] = provincia;
            }
        } else if (field === "madrelingua") {
            newDatiGenerali[field] = event;
            if (newDatiGenerali[field] !== this.state.datiGenerali[field]) {
                newDatiGenerali.livello = null;
                newDatiGenerali.descrizione = [];
            }
        } else {
            if (event.target) {
                newDatiGenerali[field] = event.target.value
            } else {
                newDatiGenerali[field] = event
            }

        }
        this.validaCampi(newDatiGenerali)
    }

    countCampiObbligatori = (datiGenerali) => {
        let datiGeneraliClone = _.cloneDeep(datiGenerali);
        let campiDaValorizzare = 0;

        if (!Utils.isObjectNull(datiGeneraliClone.cittadinanza)) {
            if (datiGeneraliClone.cittadinanza?.value === "01") {
                delete datiGeneraliClone.nazione;
            } else {
                delete datiGeneraliClone.comune;
                delete datiGeneraliClone.provincia;
            }
        }

        this.state.campiObbligatoriString.forEach(campo => {
            if (Utils.isStringEmptyOrNullOrUndefined(datiGeneraliClone[campo]) || Utils.isObjectNull(datiGeneraliClone[campo])
                || (campo === "identificativoUtente" && !Utils.isValidCodiceFiscale(datiGeneraliClone[campo]))) {
                campiDaValorizzare += 1
            }
        });
        // Almeno uno dei due contatti tra telefono o cellulare devono essere valorizzati
        // Nel caso in cui entrambi sono null li conto come campi obbligatori ancora da valorizzare
        if (Utils.isStringEmptyOrNullOrUndefined(datiGeneraliClone.telefono) && Utils.isStringEmptyOrNullOrUndefined(datiGeneraliClone.cellulare)) {
            campiDaValorizzare += 2;
        }

        this.state.campiObligatoriObject.forEach(campo => {
            if (typeof datiGeneraliClone[campo] === "string") {
                campiDaValorizzare += Utils.isStringEmptyOrNullOrUndefined(datiGeneraliClone[campo]) ? 1 : 0;
            } else if (typeof datiGeneraliClone[campo] === "object") {
                campiDaValorizzare += Utils.isObjectNull(datiGeneraliClone[campo]) || Object.keys(campo).every(key => !Utils.isObjectNull(datiGeneraliClone[campo][key])) ? 1 : 0;
            }
        })
        this.props.onChangeRequiredFieldsNumber("sottoSezioneDatiGenerali", campiDaValorizzare);
    }

    validaCampi = (datiGenerali) => {
        let datiGeneraliClone = _.cloneDeep(datiGenerali);
        let isTelefonoValido = Utils.isStringEmptyOrNullOrUndefined(datiGeneraliClone.telefono) || Utils.isValidTelefono(datiGeneraliClone.telefono);
        let isCellulareValido = Utils.isStringEmptyOrNullOrUndefined(datiGeneraliClone.cellulare) || Utils.isValidTelefono(datiGeneraliClone.cellulare);
        let isCodiceFiscaleValido = Utils.isValidCodiceFiscale(datiGeneraliClone.identificativoUtente)
        let isEmailValida = Utils.isStringEmptyOrNullOrUndefined(datiGeneraliClone.email) || Utils.isValidEmail(datiGeneraliClone.email);

        this.setState({datiGenerali: datiGeneraliClone}, () => {
            let datiGeneraliProps = _.cloneDeep(this.state.datiGenerali);
            this.state.campiAutocomplete.forEach(campo => {
                datiGeneraliProps[campo] = datiGeneraliClone[campo]?.codice
            })

            this.state.campiSelect.forEach(campo => {
                let split = campo.split("|");
                if (split[0] === "descrizione")
                    datiGeneraliProps[split[0]] = datiGeneraliProps[split[0]].map(el => el?.value ?? null);
                else
                    datiGeneraliProps[split[0]] = datiGeneraliClone[split[0]]?.value ?? null;
            })
            datiGeneraliProps.dataNascita = !Utils.isStringEmptyOrNullOrUndefined(datiGeneraliClone.dataNascita)
            && Utils.isValidDateFormatDDMMYYYY(datiGeneraliClone.dataNascita)
                ? Utils.convertDDMMYYYYDateToIsoDate(datiGeneraliClone.dataNascita) : '';

            this.countCampiObbligatori(datiGeneraliClone)
            datiGeneraliProps.telefono = datiGeneraliProps.telefono?.trim();
            datiGeneraliProps.cellulare = datiGeneraliProps.cellulare?.trim();
            datiGeneraliProps.email = datiGeneraliProps.email?.trim();
            this.props.onChangeDatiAssistito("datiGenerali", datiGeneraliProps)
        })
        this.props.onValidaSezione("isDatiGeneraliValido", isTelefonoValido && isCellulareValido && isCodiceFiscaleValido && isEmailValida);

    }

    getOggettoComune = (comune) => {
        return {
            codice: comune.codice,
            codiceProvincia: comune.codiceProvincia,
            descrizione: comune.descrizione
        };
    }

    getOggettoProvincia = (provincia) => {
        return {
            codice: provincia.codice,
            codiceRegione: provincia.codiceRegione,
            descrizione: provincia.descrizione
        };
    }

    getOggettoNazione = (nazione) => {
        return {
            codice: nazione.codice,
            descrizione: nazione.descrizione
        };
    }

    renderLinguaParlata = (linguaParlata, index) => {
        return (
            <RoleBasedSelect
                fieldId={"LING_PARL.PAZIENTE.ANAGRAFEPAZIENTE"}
                pageState={this.props.pageState}
                parentClass={"mb-0"}
                id={"descrizione"}
                className={"descrizione"}
                options={OggettiUtili.elencoLingue}
                value={linguaParlata}
                onChange={(elem) => {
                    let newDatiGenerali = _.cloneDeep(this.state.datiGenerali);
                    newDatiGenerali.descrizione[index] = elem
                    this.validaCampi(newDatiGenerali)
                }}
                isSearchable={false}
                noOptionsMessage={() => "Errore: Lingua parlata non esistente"}
                fieldLabel={'Lingua parlata'}
                field={"linguaParlata"}
                disableDoubleInput={true}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            />
        );
    }

    addNewLinguaParlata = () => {
        let datiGenerali = _.cloneDeep(this.state.datiGenerali);
        datiGenerali.descrizione.push(null);
        this.setState({datiGenerali}, () => this.validaCampi(this.state.datiGenerali))
    }

    removeLinguaParlata = (index) => {
        let datiGenerali = _.cloneDeep(this.state.datiGenerali);
        datiGenerali.descrizione.splice(index, 1);
        this.setState({datiGenerali}, () => this.validaCampi(this.state.datiGenerali));
    }

    renderBody = () => {
        let comuniSuggestions;
        let provinceSuggestions;
        let nazioniSuggestions;
        comuniSuggestions = !Utils.isObjectNull(this.state.datiGenerali?.provincia?.codice)
            ? this.listaComuni.filter(comune => comune.codiceProvincia === this.state.datiGenerali.provincia.codice)
            : this.listaComuni;
        comuniSuggestions = comuniSuggestions.map(comune => this.getOggettoComune(comune));

        provinceSuggestions = !Utils.isObjectNull(this.state.datiGenerali?.comune?.codice)
            ? this.listaProvince.filter(provincia => provincia.codice === this.state.datiGenerali.comune.codice.substring(0, 3))
            : this.listaProvince;
        provinceSuggestions = provinceSuggestions.map(provincia => this.getOggettoProvincia(provincia));

        nazioniSuggestions = !Utils.isObjectNull(this.state.datiGenerali?.nazione?.codice)
            ? this.listaNazioni.filter(nazione => nazione.codice === this.state.datiGenerali.nazione.codice)
            : this.listaNazioni;
        nazioniSuggestions = nazioniSuggestions.map(nazione => this.getOggettoNazione(nazione));

        let isComuneOrProvinciaDisabled = !Utils.isObjectNull(this.state.datiGenerali.cittadinanza)
            && (this.state.datiGenerali.cittadinanza?.value !== "01" || !Utils.isObjectNull(this.state.datiGenerali.nazione));

        let isStatoEsteroDisabled = !Utils.isObjectNull(this.state.datiGenerali.cittadinanza)
            && (this.state.datiGenerali.cittadinanza?.value === "01" || !Utils.isObjectNull(this.state.datiGenerali.comune)
                || !Utils.isObjectNull(this.state.datiGenerali.provincia));

        const isContactRequired = Utils.isStringEmptyOrNullOrUndefined(this.state.datiGenerali.telefono)
            && Utils.isStringEmptyOrNullOrUndefined(this.state.datiGenerali.cellulare);
        const forceContactVisibility = !Utils.isStringEmptyOrNullOrUndefined(this.state.datiGenerali.telefono)
            || !Utils.isStringEmptyOrNullOrUndefined(this.state.datiGenerali.cellulare);

        const disabilitaCittadinanza = ((this.props.datiA0.comune || this.props.datiA0.provincia) && !this.props.datiA0.cittadinanza)

        return <>
            <FieldsRow>
                <RoleBasedInput
                    fieldId={"COGN.PAZIENTE.ANAGRAFEPAZIENTE"}
                    pageState={this.props.pageState}
                    forceReadOnly={!this.props.datiA0?.mascheraModificabilita?.cognome && !Utils.isStringEmptyOrNullOrUndefined(this.props.datiA0.cognome)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    value={this.state.datiGenerali.cognome}
                    placeholder="Inserisci cognome"
                    field="cognome"
                    onChange={this.handleAggiornaDatiGenerali}
                    id="cognome"
                    fieldLabel={'Cognome'}
                    fieldRequired={true}
                    disableDoubleInput={true}
                />
                <RoleBasedInput
                    fieldId={"NOME.PAZIENTE.ANAGRAFEPAZIENTE"}
                    pageState={this.props.pageState}
                    forceReadOnly={!this.props.datiA0?.mascheraModificabilita?.nome && !Utils.isStringEmptyOrNullOrUndefined(this.props.datiA0.nome)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    value={this.state.datiGenerali.nome}
                    placeholder="Inserisci nome"
                    field="cognome"
                    onChange={this.handleAggiornaDatiGenerali}
                    id="cognome"
                    fieldLabel={'Nome'}
                    fieldRequired={true}
                    disableDoubleInput={true}
                />
                <RoleBasedSelect
                    fieldId={"SESSO.PAZIENTE.ANAGRAFEPAZIENTE"}
                    pageState={this.props.pageState}
                    forceReadOnly={!this.props.datiA0?.mascheraModificabilita?.sesso && !Utils.isObjectNull(this.props.datiA0.sesso)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    id={"sesso"}
                    className={"sesso"}
                    options={OggettiUtili.sesso}
                    value={this.state.datiGenerali.sesso}
                    onChange={(elem) => {
                        let newDatiGenerali = _.cloneDeep(this.state.datiGenerali);
                        newDatiGenerali.sesso = elem
                        this.validaCampi(newDatiGenerali)
                    }}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Sesso non esistente"}
                    fieldLabel={'Sesso'}
                    fieldRequired={true}
                    field={"sesso"}
                    disableDoubleInput={true}
                />
                <RoleBasedCalendar
                    fieldId={"DATA_NASC.PAZIENTE.ANAGRAFEPAZIENTE"}
                    pageState={this.props.pageState}
                    forceReadOnly={!this.props.datiA0?.mascheraModificabilita?.dataNascita && this.props.datiA0.dataNascita !== null}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    id={"datiGeneraliDataNascitaPaziente"}
                    value={this.state.datiGenerali.dataNascita}
                    field={"dataNascita"}
                    onChange={this.handleAggiornaDatiGenerali}
                    errorMessage={"Data non valida"}
                    error={(Utils.timeDifference(Date.now(), new Date(Utils.convertDDMMYYYYDateToIsoDate(this.state.datiGenerali.dataNascita)), true) < 0)}
                    fieldLabel={'Data di nascita'}
                    fieldRequired={true}
                    disableDoubleInput={true}
                />
            </FieldsRow>
            <FieldsRow>
            {/* TODO : verificare perche e stata utilizzata la condizione citadinanza dentro il comune */}
            {/* && _.isEqual(this.props.datiA0.cittadinanza, this.state.datiGenerali.cittadinanza) */}
            {(this.state.datiGenerali?.cittadinanza?.value === "01" || !Utils.isObjectNull(this.state.datiGenerali.nazione)) &&
                <RoleBasedAutocomplete
                    fieldId={"COM_NASC.PAZIENTE.ANAGRAFEPAZIENTE"}
                    pageState={this.props.pageState}
                    forceReadOnly={!this.props.datiA0?.mascheraModificabilita?.comune && !Utils.isObjectNull(this.props.datiA0.comune)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disabled={isComuneOrProvinciaDisabled}
                    id={"comune"}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={this.riceviValoreInput}
                    value={this.state.datiGenerali.comune}
                    field={"comune"}
                    placeholder={"comune nascita"}
                    suggestions={comuniSuggestions}
                    noSuggestions={"Errore: Comune non esistente"}
                    fieldLabel={'Comune di nascita'}
                    fieldRequired={!isComuneOrProvinciaDisabled}
                    disableDoubleInput={true}
                />
                }
                {/* TODO : verificare perche e stata utilizzata la condizione citadinanza dentro la provincia */}
                {/* && _.isEqual(this.props.datiA0.cittadinanza, this.state.datiGenerali.cittadinanza) */}
                {(this.state.datiGenerali?.cittadinanza?.value === "01" || !Utils.isObjectNull(this.state.datiGenerali.nazione)) &&
                <RoleBasedAutocomplete
                    fieldId={"PROV_NASC.PAZIENTE.ANAGRAFEPAZIENTE"}
                    pageState={this.props.pageState}
                    forceReadOnly={!this.props.datiA0?.mascheraModificabilita?.provincia && !Utils.isObjectNull(this.props.datiA0.provincia) || this.props.datiA0.comune}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disabled={isComuneOrProvinciaDisabled}
                    id={"provincia"}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={this.riceviValoreInput}
                    value={this.state.datiGenerali.provincia}
                    field={"provincia"}
                    placeholder={"provincia nascita"}
                    suggestions={provinceSuggestions}
                    noSuggestions={"Errore: provincia non esistente"}
                    fieldLabel={'Provincia di nascita'}
                    fieldRequired={!isComuneOrProvinciaDisabled}
                    disableDoubleInput={true}
                />
                }
                {(this.state.datiGenerali?.cittadinanza?.value !== "01" || !Utils.isObjectNull(this.state.datiGenerali.nazione)) &&
                    <RoleBasedAutocomplete
                        fieldId={"STATO_EST_NASC.PAZIENTE.ANAGRAFEPAZIENTE"}
                        pageState={this.props.pageState}
                        forceReadOnly={!this.props.datiA0?.mascheraModificabilita?.nazione && !Utils.isObjectNull(this.props.datiA0.nazione) && _.isEqual(this.props.datiA0.cittadinanza, this.state.datiGenerali.cittadinanza)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disabled={isStatoEsteroDisabled}
                        id={"nazione"}
                        keyField={"codice"}
                        descriptionField={"descrizione"}
                        onChange={this.handleAggiornaDatiGenerali}
                        value={this.state.datiGenerali.nazione}
                        field={"nazione"}
                        placeholder={"Inserisci stato estero nascita"}
                        suggestions={nazioniSuggestions}
                        noSuggestions={"Errore: Nazione non esistente"}
                        fieldLabel={'Stato estero di nascita'}
                        fieldRequired={!isStatoEsteroDisabled}
                        disableDoubleInput={true}
                    />}
                <RoleBasedSelect
                    fieldId={"CITTADIN.PAZIENTE.ANAGRAFEPAZIENTE"}
                    pageState={this.props.pageState}
                    forceReadOnly={(!this.props.datiA0?.mascheraModificabilita?.cittadinanza && !Utils.isObjectNull(this.props.datiA0.cittadinanza)) || disabilitaCittadinanza}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    id={"cittadinanza"}
                    className={"cittadinanza"}
                    options={OggettiUtili.cittadinanzaAnagrafePaziente}
                    value={this.state.datiGenerali.cittadinanza}
                    onChange={(elem) => {
                        let newDatiGenerali = _.cloneDeep(this.state.datiGenerali);
                        newDatiGenerali.cittadinanza = elem;
                        if (elem !== this.state.datiGenerali.cittadinanza) {
                            if (elem?.value === "01") {
                                newDatiGenerali.nazione = "";
                            } else {
                                newDatiGenerali.nazione = "";
                                newDatiGenerali.comune = "";
                                newDatiGenerali.provincia = "";
                            }
                        }
                        this.validaCampi(newDatiGenerali)
                    }}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Cittadinanza non esistente"}
                    fieldLabel={'Cittadinanza'}
                    fieldRequired={true}
                    field={"cittadinanza"}
                    disableDoubleInput={true}
                />
            </FieldsRow>
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={"TIT_STUD.ISTRUZIONE.ANAGRAFEPAZIENTE"}
                    pageState={this.props.pageState}
                    id={"titoloStudio"}
                    className={"titoloStudio"}
                    options={OggettiUtili.titoliStudio}
                    value={this.state.datiGenerali.titoloStudio}
                    onChange={(elem) => {
                        let newDatiGenerali = _.cloneDeep(this.state.datiGenerali);
                        newDatiGenerali.titoloStudio = elem
                        this.validaCampi(newDatiGenerali)
                    }}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Titolo studio non esistente"}
                    fieldLabel={'Titolo di studio'}
                    field={"titoloStudio"}
                    disableDoubleInput={true}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                <RoleBasedSelect
                    fieldId={"ITA_MADREL.PAZIENTE.ANAGRAFEPAZIENTE"}
                    pageState={this.props.pageState}
                    id={"selectItalianoMadrelingua"}
                    className={"selectItalianoMadrelingua"}
                    options={OggettiUtili.rispostaBreve}
                    value={this.state.datiGenerali.madrelingua}
                    onChange={(elem) => {
                        this.riceviValoreInput("madrelingua", elem);
                    }}
                    isSearchable={false}
                    fieldLabel={'Italiano madrelingua'}
                    field={"madrelingua"}
                    disableDoubleInput={true}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                {!isMadrelingua(this.state.datiGenerali.madrelingua)
                    ? <RoleBasedSelect
                        fieldId={"LING_ITA_PARL_COMPR.PAZIENTE.ANAGRAFEPAZIENTE"}
                        pageState={this.props.pageState}
                        isDisabled={Utils.isObjectNull(this.state.datiGenerali.madrelingua) || this.state.datiGenerali.madrelingua.value === null || this.state.datiGenerali.madrelingua.value === true}
                        id={"livello"}
                        className={"livello"}
                        options={OggettiUtili.linguaItalianaParlataCompresa}
                        value={this.state.datiGenerali.livello}
                        onChange={(elem) => {
                            let newDatiGenerali = _.cloneDeep(this.state.datiGenerali);
                            newDatiGenerali.livello = elem
                            this.validaCampi(newDatiGenerali)
                        }}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Lingua non esistente"}
                        fieldLabel={'Lingua italiana parlata e compresa'}
                        field={"livello"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    : null}
                {!isMadrelingua(this.state.datiGenerali.madrelingua) &&
                    <SectionList
                        title={'Lingua parlata'}
                        data={this.state.datiGenerali.descrizione}
                        renderSection={this.renderLinguaParlata}
                        addNewSectionCallback={this.addNewLinguaParlata}
                        removeSectionCallback={this.removeLinguaParlata}
                        addButtonVisibilityHandler={!isMadrelingua(this.state.datiGenerali.madrelingua)
                            && (this.state.datiGenerali.descrizione?.length === 0 || this.state.datiGenerali.descrizione?.every(el => !Utils.isObjectNull(el)))}
                        pageState={this.props.pageState}
                        keyFieldId={'LING_PARL.PAZIENTE.ANAGRAFEPAZIENTE'}
                        indentOffset={0}
                        field={'descrizione'}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />}
            </FieldsRow>
            <FieldsRow>
                <RoleBasedPhoneInput
                    fieldLabel={"Telefono"}
                    fieldId={"TEL_ASSIST.CONTATTIPERSONALIASSISTITO.ANAGRAFEPAZIENTE"}
                    pageState={this.props.pageState}
                    value={this.state.datiGenerali.telefono}
                    placeholder="Inserisci numero telefono"
                    field="telefono"
                    onChange={this.handleAggiornaDatiGenerali}
                    id="telefono"
                    fieldRequired={isContactRequired}
                    showFieldIfRequiredAccordionPage={forceContactVisibility}
                    phonePrefix={null}
                    disableDoubleInput={true}
                    disabled={false}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                <RoleBasedPhoneInput
                    fieldId={"CELL_ASSIST.CONTATTIPERSONALIASSISTITO.ANAGRAFEPAZIENTE"}
                    fieldLabel={'Cellulare'}
                    pageState={this.props.pageState}
                    value={this.state.datiGenerali.cellulare}
                    placeholder="Inserisci numero cellulare"
                    field="cellulare"
                    onChange={this.handleAggiornaDatiGenerali}
                    id="cellulare"
                    fieldRequired={isContactRequired}
                    showFieldIfRequiredAccordionPage={forceContactVisibility}
                    disableDoubleInput={true}
                    disabled={false}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                <RoleBasedInput
                    fieldId={"EMAIL_ASSIST.CONTATTIPERSONALIASSISTITO.ANAGRAFEPAZIENTE"}
                    value={this.state.datiGenerali.email}
                    placeholder="Inserisci e-mail"
                    field="email"
                    onChange={this.handleAggiornaDatiGenerali}
                    id="email"
                    disableDoubleInput={true}
                    fieldLabel={'E-mail'}
                    validator={Utils.isValidEmail}
                    invalidText={"Inserire un'email valida"}
                    pageState={this.props.pageState}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                <RoleBasedInput
                    fieldId={"CODCATEG.PAZIENTE.ANAGRAFEPAZIENTE"}
                    pageState={this.props.pageState}
                    forceReadOnly={!this.props.datiA0?.mascheraModificabilita?.categoria && !Utils.isStringEmptyOrNullOrUndefined(this.props.datiA0.categoria)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    value={this.state.datiGenerali.categoria}
                    placeholder="Inserisci Cod. Categoria"
                    field="categoria"
                    onChange={this.handleAggiornaDatiGenerali}
                    id="categoria"
                    fieldLabel={'Cod. Categoria'}
                    disableDoubleInput={true}
                />
            </FieldsRow>
            <FieldsRow>
                <RoleBasedInput
                    fieldLabel={"Codice Fiscale o altro identificativo utente"}
                    fieldId={"COD_FISC_ALTRO_ID_UTENTE.PAZIENTE.ANAGRAFEPAZIENTE"}
                    fieldRequired={true}
                    pageState={this.props.pageState}
                    forceReadOnly={!this.props.datiA0?.mascheraModificabilita?.identificativoUtente && !Utils.isStringEmptyOrNullOrUndefined(this.state.datiGenerali.identificativoUtente)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    id={"inputIdentificativoUtente"}
                    value={this.state.datiGenerali.identificativoUtente}
                    placeholder={"Inserisci identificativo utente"}
                    field={"identificativoUtente"}
                    onChange={this.handleAggiornaDatiGenerali}
                    validator={Utils.isValidCodiceFiscale}
                    invalidText={"Inserire un codice fiscale valido"}
                    disableDoubleInput={true}
                />
                <RoleBasedInput
                    fieldId={"TESS_SAN.PAZIENTE.ANAGRAFEPAZIENTE"}
                    pageState={this.props.pageState}
                    forceReadOnly={!this.props.datiA0?.mascheraModificabilita?.tesseraSanitaria && !Utils.isStringEmptyOrNullOrUndefined(this.props.datiA0.tesseraSanitaria)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    value={this.state.datiGenerali.tesseraSanitaria}
                    placeholder="Inserisci Tessera sanitaria"
                    field="tesseraSanitaria"
                    onChange={this.handleAggiornaDatiGenerali}
                    id="tesseraSanitaria"
                    fieldLabel={'Tessera sanitaria'}
                    disableDoubleInput={true}
                />
                <RoleBasedSelect
                    fieldId={"PAZ_INFORMATO_DOMANDA.PAZIENTEINFORMATODELLADOMANDA.RICHIESTACAMBIOSETTING"}
                    pageState={this.props.pageState}
                    id={"selectIlPazienteInformatoDellaDomanda"}
                    className={"selectIlPazienteInformatoDellaDomanda"}
                    options={OggettiUtili.rispostaBreve}
                    value={this.state.datiGenerali.ilPazienteEInformatoDellaDomanda}
                    onChange={(elem) => {
                        this.riceviValoreInput("ilPazienteEInformatoDellaDomanda", elem);
                    }}
                    isSearchable={false}
                    fieldLabel={'Il paziente è informato della domanda?'}
                    field={"ilPazienteEInformatoDellaDomanda"}
                    disableDoubleInput={true}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </FieldsRow>
        </>;
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiGenerali"}
                title={"Dati generali"}
                required={true}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        )
    }
}

DatiGenerali.propTypes = propTypes;
DatiGenerali.contextType = RoleBasedContext;
