import React from "react";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import * as _ from "lodash";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import PropTypes from "prop-types";
import {
    FieldsRow,
    RoleBasedInput,
    RoleBasedSelect,
    RoleBasedPhoneInput
} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";

const propTypes = {
    datiAssistenzaSociale: PropTypes.any,
    onChangeDatiAssistito: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

export default class ServizioAssistenzaSociale extends AccordionSecondLevel {
    state = {
        datiAssistenzaSociale: _.cloneDeep(this.props.datiAssistenzaSociale)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiAssistenzaSociale, this.props.datiAssistenzaSociale))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiAssistenzaSociale = _.cloneDeep(this.props.datiAssistenzaSociale);
        datiAssistenzaSociale.assistenzaSocialeAttiva = !Utils.isStringEmptyOrNullOrUndefined(datiAssistenzaSociale.assistenzaSocialeAttiva)
            ? OggettiUtili.assistenzaSocialeAttiva.find(el => el.value === datiAssistenzaSociale.assistenzaSocialeAttiva)
            : null;
        this.setState({ datiAssistenzaSociale: datiAssistenzaSociale });
    }

    handleAggiornaForm = (field, elem) => {
        let datiAssistenzaSociale = _.cloneDeep(this.state.datiAssistenzaSociale);
        datiAssistenzaSociale[field] = elem;

        if (field === "assistenzaSocialeAttiva") {
            datiAssistenzaSociale.nome = null;
            datiAssistenzaSociale.cognome = null;
            datiAssistenzaSociale.telefono = null;
            datiAssistenzaSociale.email = null;
        }

        this.setState({ datiAssistenzaSociale: datiAssistenzaSociale }, () => {
            let datiAssistenzaSociale = _.cloneDeep(this.state.datiAssistenzaSociale);
            datiAssistenzaSociale.assistenzaSocialeAttiva = !Utils.isObjectNull(datiAssistenzaSociale.assistenzaSocialeAttiva?.value)
                ? OggettiUtili.assistenzaSocialeAttiva.find(el => el.value === datiAssistenzaSociale.assistenzaSocialeAttiva.value).value
                : null;
            this.props.onChangeDatiAssistito("datiAssistenzaSociale", datiAssistenzaSociale)
        });
    }


    renderBodySezione = () => {
        let datiAssistenzaSociale = this.state.datiAssistenzaSociale;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PRES_SERV_ASSIST_SOC_ATT.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        id={"selectAssistenzaSocialeAttiva"}
                        options={OggettiUtili.assistenzaSocialeAttiva}
                        value={datiAssistenzaSociale.assistenzaSocialeAttiva}
                        onChange={(elem) => this.handleAggiornaForm("assistenzaSocialeAttiva", elem)}
                        isSearchable={false}
                        fieldLabel={'Presenza di servizio di Assistenza Sociale attiva?'}
                        noOptionsMessage={() => "Errore: Assistenza sociale attiva non esistente"}
                        field={"assistenzaSocialeAttiva"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                {datiAssistenzaSociale.assistenzaSocialeAttiva?.value === "01"
                    ?
                    <>
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'COGN_ASS_SOC.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                value={datiAssistenzaSociale.cognome ?? ""}
                                placeholder="Inserisci cognome"
                                field="cognome"
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id="cognome"
                                fieldLabel={'Cognome'}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                            <RoleBasedInput
                                fieldId={'NOME_ASS_SOC.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                value={datiAssistenzaSociale.nome ?? ""}
                                placeholder="Inserisci nome"
                                field="nome"
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id="nome"
                                fieldLabel={'Nome'}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                            <RoleBasedPhoneInput
                                fieldId={'TEL_ASS_SOC.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                placeholder={"Telefono"}
                                field={"telefono"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"telefono"}
                                value={datiAssistenzaSociale.telefono}
                                phonePrefix={null}
                                fieldLabel={"Telefono"}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                            <RoleBasedInput
                                fieldId={'EMAIL_ASS_SOC.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                value={datiAssistenzaSociale.email}
                                placeholder="E-mail"
                                field="email"
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id="email"
                                fieldLabel={'E-mail'}
                                validator={Utils.isValidEmail}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                        </FieldsRow>
                    </>
                    : null}
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiAssistenzaSociale"}
                title={"Supporto sociale: Servizio di assistenza sociale"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }


}

ServizioAssistenzaSociale.propTypes = propTypes;
