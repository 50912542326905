import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {
    FieldsRow,
    icd9List,
    isFieldIdWriteable,
    RoleBasedICD9Input,
    RoleBasedICPCInput,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {v4 as uuid} from "uuid";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import styles from "./AnamnesiPatologicaProssimaSottoAccordion.module.css"
import {RoleBasedContext} from "../../../utils/RoleBasedContext";

const propTypes = {
    datiAnamnesiPatologicaProssima: PropTypes.any,
    datiAnamnesiPatologicaRemota: PropTypes.any,
    onChangeDatiAnamnesiPatologicaProssima: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultPatologiaAttiva = {
    id: null,
    idAnamnesiPatologiaRemota: null,
    patologiaAttiva: null,
    condizioneRilevata: null,
    daAttenzionare: null,
    tipologiaDiagnosi: null,
    descrizioneTipologiaDiagnosi: null,
    note: null
}

export default class AnamnesiPatologicaProssimaSottoAccordion extends AccordionSecondLevel {

    state = {
        datiAnamnesiPatologicaProssima: _.cloneDeep(this.props.datiAnamnesiPatologicaProssima)
    }

    componentDidMount() {
        let cloneDatiAnamnesiPatologicaProssima = this.recuperoPatologieAttive();
        this.inizializzaForm(cloneDatiAnamnesiPatologicaProssima);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiAnamnesiPatologicaProssima, this.props.datiAnamnesiPatologicaProssima) ||
            !_.isEqual(prevProps.datiAnamnesiPatologicaRemota, this.props.datiAnamnesiPatologicaRemota)) {
            let cloneDatiAnamnesiPatologicaProssima = this.recuperoPatologieAttive();
            this.inizializzaForm(cloneDatiAnamnesiPatologicaProssima);
        }
    }

    /**
     * viene fatto un merge tra le pattologie Attive e le patologie attive remote
     * se la lista delle patologie attive e vuota prendo la lista delle patologie remote attive
     * se la lista delle patologie attive e piena faccio un merge tra le due liste 
     * @returns la lista delle patoligie Attive + la lista delle patologie Attive Remote
    */
     recuperoPatologieAttive = () => {
        const patologieAttive =  _.cloneDeep(this.props.datiAnamnesiPatologicaProssima.patologieAttive) || [];
        const patologiaActiveRomote = _.cloneDeep(this.getPatologieAttive()) || [];

        let mergeTraPatologia = [];
        if(!patologieAttive.length)  mergeTraPatologia = [...patologiaActiveRomote]; 
        else {
            const mergeTraPatologiaAttive = patologieAttive.map(function (el) {
                const pat = patologiaActiveRomote.find(f => f?.idAnamnesiPatologiaRemota === el?.idAnamnesiPatologiaRemota);
                if (pat || !el?.idAnamnesiPatologiaRemota)
                    return el
            }).filter(e => e)  
    
            const mergeTraPatologiaAttiveRemote = patologiaActiveRomote.filter(f => !patologieAttive?.find(a => a.idAnamnesiPatologiaRemota === f.idAnamnesiPatologiaRemota));
    
            mergeTraPatologia = [...mergeTraPatologiaAttiveRemote, ...mergeTraPatologiaAttive]
        }
    
        if(!mergeTraPatologia.length) mergeTraPatologia.push({..._.cloneDeep(defaultPatologiaAttiva), id:uuid()})
        
        return { "patologieAttive": mergeTraPatologia }
    } 

    getPatologieAttive = () => {
        let datiAnamnesiPatologicaRemota = _.cloneDeep(this.props.datiAnamnesiPatologicaRemota);
        let patologieAttive = [];
        if (!Utils.isObjectNull(datiAnamnesiPatologicaRemota)) {
            Object.keys(datiAnamnesiPatologicaRemota).forEach(key => {
                if (Array.isArray(datiAnamnesiPatologicaRemota[key]['lista'])) {
                    let list = datiAnamnesiPatologicaRemota[key]['lista'].filter(el => el.patologiaAttiva === true && !Utils.isStringEmptyOrNullOrUndefined(el.diagnosiPatologiaPregressa));
                    if (list.length > 0)
                        list.forEach(el => patologieAttive.push({
                            id: uuid(),
                            idAnamnesiPatologiaRemota: el.id,
                            patologiaAttiva: el.diagnosiPatologiaPregressa,
                            condizioneRilevata: el.condizioneRilevata,
                            note: el.note,
                            daAttenzionare: el.daAttenzionare
                        }))
                }
            });
        }
        return patologieAttive.filter(el => !Utils.isStringEmptyOrNullOrUndefined(el.patologiaAttiva));
    }

    inizializzaForm = (datiAnamnesiPatologicaProssima = this.props.datiAnamnesiPatologicaProssima) => {
        let cloneDatiAnamnesiPatologicaProssima = _.cloneDeep(datiAnamnesiPatologicaProssima);
        this.setState({datiAnamnesiPatologicaProssima: cloneDatiAnamnesiPatologicaProssima}, () => {
            this.validaSezione();
        });
    }

    validaSezione = () => {
        let datiAnamnesiPatologicaProssima = _.cloneDeep(this.state.datiAnamnesiPatologicaProssima);
        let numeroCampiObbligatoriMancanti = 0;
        if (Utils.isStateRichiesta(this.props.pageState)) {
            numeroCampiObbligatoriMancanti = this.checkNumeroCampiObbligatoriMancantiPatologiaInserita(datiAnamnesiPatologicaProssima.patologieAttive)
        }
        this.props.onChangeRequiredFieldsNumber(Utils.isStateValutazione(this.props.pageState) ? 0 : numeroCampiObbligatoriMancanti);
    }

    checkNumeroCampiObbligatoriMancantiPatologiaInserita = (patologie) => {
        let numeroCampiObbligatoriMancanti = 0
        patologie.forEach(patologia => {
            if (Utils.isObjectNull(patologia.patologiaAttiva))
                numeroCampiObbligatoriMancanti++;
            if (Utils.isObjectNull(patologia.condizioneRilevata))
                numeroCampiObbligatoriMancanti++;
            if (patologia.condizioneRilevata === "02" && Utils.isObjectNull(patologia.daAttenzionare))
                numeroCampiObbligatoriMancanti++;
            if (!Utils.isObjectNull(patologia.tipologiaDiagnosi) && Utils.isObjectNull(patologia.descrizioneTipologiaDiagnosi)) {
                numeroCampiObbligatoriMancanti += 1;
            }
        })
        if (!patologie.some(patologia => patologia.tipologiaDiagnosi === "01")) {
            numeroCampiObbligatoriMancanti += 1;
        }
        return numeroCampiObbligatoriMancanti
    }

    handleAggiornaForm = (field, elem, index) => {
        this.setState(({datiAnamnesiPatologicaProssima}) => {
            if (field === "patologiaAttiva") {
                datiAnamnesiPatologicaProssima.patologieAttive[index].patologiaAttiva = elem.target.value;
                if (!elem.target.value) {
                    datiAnamnesiPatologicaProssima.patologieAttive[index].condizioneRilevata = null;
                    datiAnamnesiPatologicaProssima.patologieAttive[index].note = null;
                    datiAnamnesiPatologicaProssima.patologieAttive[index].tipologiaDiagnosi = null;
                }
                datiAnamnesiPatologicaProssima.patologieAttive[index].descrizioneTipologiaDiagnosi = null;
            } else if (field === "tipologiaDiagnosi") {
                if (elem) {
                    datiAnamnesiPatologicaProssima.patologieAttive.forEach(patologiaAttiva => {
                        if (patologiaAttiva.tipologiaDiagnosi === elem) patologiaAttiva.tipologiaDiagnosi = null;
                    })
                }
                datiAnamnesiPatologicaProssima.patologieAttive[index].tipologiaDiagnosi = elem;
                datiAnamnesiPatologicaProssima.patologieAttive[index].descrizioneTipologiaDiagnosi = null;
            } else {
                datiAnamnesiPatologicaProssima.patologieAttive[index][field] = elem;
            }
            return {datiAnamnesiPatologicaProssima};
        }, () => {
            this.validaSezione();
            this.formatObjectForBackend();
        });
    }

    formatObjectForBackend = () => {
        let datiAnamnesiPatologicaProssima = _.cloneDeep(this.state.datiAnamnesiPatologicaProssima);
        this.props.onChangeDatiAnamnesiPatologicaProssima(datiAnamnesiPatologicaProssima);
    }

    addNewPatologiaAttiva = () => {
        let datiAnamnesiPatologicaProssima = _.cloneDeep(this.state.datiAnamnesiPatologicaProssima);
        let patologia = _.cloneDeep(defaultPatologiaAttiva);
        patologia.id = uuid();
        patologia.tipologiaDiagnosi = datiAnamnesiPatologicaProssima.patologieAttive.length === 0 ? "01" : null
        datiAnamnesiPatologicaProssima.patologieAttive.push(patologia);

        this.setState({datiAnamnesiPatologicaProssima: datiAnamnesiPatologicaProssima}, () => {
            this.validaSezione();
        });
    }

    removePatologiaAttiva = (index) => {
        let datiAnamnesiPatologicaProssima = _.cloneDeep(this.state.datiAnamnesiPatologicaProssima);
        datiAnamnesiPatologicaProssima.patologieAttive.splice(index, 1);

        if (datiAnamnesiPatologicaProssima.patologieAttive != null && datiAnamnesiPatologicaProssima.patologieAttive.length === 1) {
            datiAnamnesiPatologicaProssima.patologieAttive[0].tipologiaDiagnosi = "01";
        }

        this.setState({datiAnamnesiPatologicaProssima}, () => {
            this.validaSezione();
            this.formatObjectForBackend();
        });
    }

    renderNewPatologiaAttiva = (patologia, index) => {
        const icd9ListCustom = _.cloneDeep(icd9List);

        return <>
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'PAT_ATTIVE_YYY.ANAMNESIPATOLOGICAPROSSIMA.ANAMNESIPATOLOGICAPROSSIMA'}
                    pageState={this.props.pageState}
                    field={"patologiaAttiva"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem, index)}
                    value={patologia?.patologiaAttiva}
                    placeholder={"Inserisci patologia attiva"}
                    forceReadOnly={this.props.forceReadOnly || patologia?.idAnamnesiPatologiaRemota}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    fieldLabel={'Patologia attiva'}
                    fieldRequired={true}
                    suggestions={icd9ListCustom}
                />
                <RoleBasedSelect
                    fieldId={"COND_RIL_YYY.ANAMNESIPATOLOGICAPROSSIMA.ANAMNESIPATOLOGICAPROSSIMA"}
                    pageState={this.props.pageState}
                    id={"selectCondizioneRilevata"}
                    options={OggettiUtili.condizioneRilevata}
                    value={patologia.condizioneRilevata}
                    onChange={(elem) => this.handleAggiornaForm("condizioneRilevata", elem, index)}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Condizione rilevata non esistente"}
                    fieldLabel={'La condizione rilevata'}
                    fieldRequired={true}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    handleOnlyValue={true}
                    field={"condizioneRilevata"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                {patologia.condizioneRilevata === "02" &&
                    <RoleBasedSelect
                        fieldId={"DA_ATTENZ_YYY.ANAMNESIPATOLOGICAPROSSIMA.ANAMNESIPATOLOGICAPROSSIMA"}
                        pageState={this.props.pageState}
                        id={"selectDaAttenzionare"}
                        options={OggettiUtili.daConsiderareAiFiniAssistenziali}
                        value={patologia.daAttenzionare}
                        onChange={(elem) => this.handleAggiornaForm("daAttenzionare", elem, index)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Da Attenzionare non esistente"}
                        fieldLabel={'Da attenzionare'}
                        fieldRequired={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"daAttenzionare"}
                    />}
            </FieldsRow>
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'DIAGNOSI_PRINC_YYY.ANAMNESIPATOLOGICAPROSSIMA.ANAMNESIPATOLOGICAPROSSIMA'}//TODO: filed id mancante
                    pageState={this.props.pageState}
                    id={"selectDiagnosi"}
                    options={OggettiUtili.diagnosiAnamnesiPatologicaProssima}
                    value={patologia.tipologiaDiagnosi}
                    onChange={(elem) => this.handleAggiornaForm("tipologiaDiagnosi", elem, index)}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: tipologia non esistente"}
                    fieldLabel={'Tipologia diagnosi'}
                    handleOnlyValue={true}
                    field={"tipologiaDiagnosi"}
                    showFieldIfRequiredAccordionPage={isFieldIdWriteable('DIAGNOSI_PRINC_YYY.ANAMNESIPATOLOGICAPROSSIMA.ANAMNESIPATOLOGICAPROSSIMA', this.props.pageState, this.context.forceUserRole)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                {patologia.tipologiaDiagnosi &&
                    <RoleBasedICPCInput
                        fieldId={'DIAGNOSI_PAT_CONC_YYY.ANAMNESIPATOLOGICAPROSSIMA.ANAMNESIPATOLOGICAPROSSIMA'}
                        pageState={this.props.pageState}
                        field={"descrizioneTipologiaDiagnosi"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        value={patologia.descrizioneTipologiaDiagnosi}
                        placeholder={"Codifica ICPC - Descrizione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldLabel={'Descrizione tipologia'}
                        fieldRequired={true}
                        icpcFilter={patologia?.patologiaAttiva}
                    />}
                <RoleBasedInput
                    fieldId={'NOTE_YYY.ANAMNESIPATOLOGICAPROSSIMA.ANAMNESIPATOLOGICAPROSSIMA'}
                    pageState={this.props.pageState}
                    value={patologia.note ?? ''}
                    placeholder={"Inserisci note"}
                    field={"note"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id={"note"}
                    type={"textarea"}
                    maxlength={"500"}
                    fieldLabel={'Note'}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    showAvailableCharsLabel={true}
                />
            </FieldsRow>
        </>;
    }

    renderBody = () => {
        const datiAnamnesiPatologicaProssima = _.cloneDeep(this.state.datiAnamnesiPatologicaProssima);
        const numeroCampiObbligatoriMancanti = this.checkNumeroCampiObbligatoriMancantiPatologiaInserita(datiAnamnesiPatologicaProssima.patologieAttive);
        const isPresentTipologiaPrincipale = datiAnamnesiPatologicaProssima.patologieAttive.some(patologia => patologia.tipologiaDiagnosi === "01");
        const isAtLeastOne = datiAnamnesiPatologicaProssima.patologieAttive.length === 1 && datiAnamnesiPatologicaProssima.patologieAttive.some(patologia => !patologia.idAnamnesiPatologiaRemota);
        return (
            <>
                {!isPresentTipologiaPrincipale && !this.props.accordionReadOnly
                    && <div className={styles.noDiagPrincMessage}>Attenzione! Almeno una Patologia Attiva deve essere
                        Diagnosi Principale.</div>}
                <SectionList
                    addNewSectionCallback={this.addNewPatologiaAttiva}
                    removeSectionCallback={this.removePatologiaAttiva}
                    renderSection={this.renderNewPatologiaAttiva}
                    data={datiAnamnesiPatologicaProssima.patologieAttive}
                    title={'Patologia attiva'}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    atLeastOne={isAtLeastOne}
                    addButtonVisibilityHandler={Utils.isStateRichiesta(this.props.pageState) ?
                        numeroCampiObbligatoriMancanti === 0 || (numeroCampiObbligatoriMancanti === 1 && !isPresentTipologiaPrincipale) : true}
                    field={'patologieAttive'}
                    pageState={this.props.pageState}
                    keyFieldId={'PAT_ATTIVE_YYY.ANAMNESIPATOLOGICAPROSSIMA.ANAMNESIPATOLOGICAPROSSIMA'}
                    canRemoveData={datiAnamnesiPatologicaProssima.patologieAttive.map(e => !e?.idAnamnesiPatologiaRemota)}
                    parentJsonPath={"anamnesiPatologicaProssima.patologieAttive"}
                />
                {/* e stato richiesto aggiungere il doppio messaggio 14/03/2022 */}
                {!isPresentTipologiaPrincipale && !this.props.accordionReadOnly
                    && <div className={styles.noDiagPrincMessage}>Attenzione! Almeno una Patologia Attiva deve essere
                        Diagnosi Principale.</div>}
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"patologieAttive"}
                title={"Anamnesi patologica prossima"}
                required={true}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["anamnesiPatologicaProssima.patologieAttive"],
                    [defaultPatologiaAttiva]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}

AnamnesiPatologicaProssimaSottoAccordion.propTypes = propTypes;
AnamnesiPatologicaProssimaSottoAccordion.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false
}
AnamnesiPatologicaProssimaSottoAccordion.contextType = RoleBasedContext
