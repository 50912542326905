import React, {Component} from 'react'
import Styles from "./Select.module.css"
import $ from 'jquery'

class Select extends Component {

    state = {
        className: "",
        value: this.props.value,
    }

    constructor(props) {
        super(props)

        if (this.props.className) this.state.className = this.props.className
    }

    componentDidMount() {
        $(function () {
            $('.selectpicker').selectpicker();
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value
            })
        }
    }

    render() {
        return (
            <div className={this.state.className}>
                <select
                    id={this.props.id}
                    field={this.props.field}
                    value={this.state.value}
                    onChange={this.props.newSelect ? (e) => this.props.onChange(this.props.field, e) : this.props.onChange}
                    className={Styles.struttura + " elemento-visualizzazione"}
                    disabled={this.props.disabled}
                    style={{cursor: this.props.disabled ? "not-allowed" : ""}}
                >
                    {!this.props.vaccinazione ?
                        <option value={""}>{(this.props.defaultSelect) ? this.props.defaultSelect : ""}</option> : null}
                    {this.props.functionMap}

                </select>
            </div>

        );
    }
}

export default Select;

