import React from "react";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import PropTypes from "prop-types";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import * as _ from "lodash";
import AccordionHelper from "../../service/AccordionHelper";
import AnamnesiPatologicaProssimaSottoAccordion
    from "./anamnesiPatologicaProssimaSottoAccordion/AnamnesiPatologicaProssimaSottoAccordion";
import Utils from "../../utils/Utils";
import VersionedAccordion from "../VersionedAccordion";

const propTypes = {
    datiAnamnesiPatologicaProssima: PropTypes.any,
    datiAnamnesiPatologicaRemota: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiAnamnesiPatologicaProssima: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta: PropTypes.func,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues:PropTypes.bool,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class AnamnesiPatologicaProssima extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: 0,
        accordion: {
            openSubAccordionAnamnesiPatologicaProssima: true
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            })

            let richiesta = _.cloneDeep(this.props.richiesta);
            this.props.datiAnamnesiPatologicaProssima.patologieAttive = this.props.datiAnamnesiPatologicaProssima.patologieAttive?.filter(patologia => {
                let e = _.cloneDeep(patologia);
                delete e.id;
                return !Utils.isObjectNull(e);
            });
            richiesta.anamnesiPatologicaProssima = this.props.datiAnamnesiPatologicaProssima;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeRequiredFieldsNumber = (value) => {
        this.state.campiObbligatoriMancanti = value;
        this.setState({campiObbligatoriMancanti: _.cloneDeep(this.state.campiObbligatoriMancanti)}, () => {
            this.props.onChangeObbligatorieta("anamnesiPatologicaProssima", this.state.campiObbligatoriMancanti);
        });
    }

    renderBody = () => {
        return (
            <>
                <VersionedAccordion accordion={AnamnesiPatologicaProssimaSottoAccordion}
                                    datiAnamnesiPatologicaProssima={this.props.datiAnamnesiPatologicaProssima}
                                    datiAnamnesiPatologicaRemota={this.props.datiAnamnesiPatologicaRemota}
                                    onChangeDatiAnamnesiPatologicaProssima={this.props.onChangeDatiAnamnesiPatologicaProssima}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    openAccordion={this.state.accordion.openSubAccordionAnamnesiPatologicaProssima}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openSubAccordionAnamnesiPatologicaProssima"}
                                    pageState={this.props.pageState}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"anamnesiPatologicaProssima"}
                title={"Anamnesi patologica prossima"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                numberOfRequiredFieldsMissing={this.state.campiObbligatoriMancanti}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionAvvioRichiesta>
        );
    }

}

AnamnesiPatologicaProssima.propTypes = propTypes;
AnamnesiPatologicaProssima.defaultProps={
    forceReadOnly: false,
    hideUnsetValues: false
}
