import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    Jumbotron,
    Label,
    Row
} from "reactstrap";
import styles from "./CentroNotifiche.module.css";
import * as _ from "lodash";
import Notifica from "./notifica/Notifica";
import NotificaService from "../../service/NotificaService";
import RichiestaADIService from "../../service/RichiestaADIService";
import { mostraSpinner, nascondiSpinner } from "../../App";
import Utils from "../../utils/Utils";
import PreviewNotifica from "./previewNotifica/PreviewNotifica";
import * as Icon from "react-bootstrap-icons";
import CentroNotificheUtils from "./CentroNotificheUtils";
import PropTypes from "prop-types";
import { Context } from "../../utils/Context";
import pageTexts from "../../utils/message/pageTexts.json";
import {Button as BtnWA} from "web-client-archetype";
import CheckBox from "../generalComponents/checkbox/CheckBox";

const idFiltroNotifiche = "filtroNotifiche";

const msgErrore = "ERRORE";

const fieldCampoDaEvidenziare = "campoDaEvidenziare";
const fieldMostraNotificheNonLette = "mostraNotificheNonLette";
const fieldOrdinaNotifichePerDataCrescente = "ordinaNotifichePerDataCrescente";

const propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    idNotifica: PropTypes.string
}

const defaultSize = 50;
const defaultPage = 1;

class CentroNotifiche extends Component {
    state = {
        filtroNotifiche: "",
        notifiche: [],
        notificheTotali: 0,
        sizeNotifiche: _.cloneDeep(defaultSize),
        pageNotifiche: _.cloneDeep(defaultPage),
        notifica: null,
        idNotifica: null,
        mostraNotificheNonLette: false,
        ordinaNotifichePerDataCrescente: false,
        campoDaEvidenziare: "",
        richiesta: null,
        notificheDaEliminare: [],
        selezionaTutteLeNotifiche: false
    };

    componentDidMount() {
        this.caricaNotifiche();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.mostraNotificheNonLette !== this.state.mostraNotificheNonLette || prevState.ordinaNotifichePerDataCrescente !== this.state.ordinaNotifichePerDataCrescente) {
            this.caricaNotifiche();
        }

        if (prevState.filtroNotifiche !== this.state.filtroNotifiche && Utils.isStringEmptyOrNullOrUndefined(this.state.filtroNotifiche)) {
            this.handleChange(fieldCampoDaEvidenziare, "");
            this.caricaNotifiche();
        }

        if (prevProps.location?.idNotifica !== this.props.location?.idNotifica) {
            this.caricaNotifiche();
        }

        if (prevState.pageNotifiche !== this.state.pageNotifiche && this.state.pageNotifiche !== defaultPage) {
            this.caricaNotifiche(true);
        }
    }

    caricaNotifiche = (updateNotifiche = false) => {
        const state = _.cloneDeep(this.state);
        mostraSpinner();
        const params = CentroNotificheUtils.getFiltriNotifiche(state.filtroNotifiche, state.mostraNotificheNonLette, state.ordinaNotifichePerDataCrescente, state.sizeNotifiche, state.pageNotifiche);
        NotificaService.getNotifiche(params).subscribe((response) => {
            if (response?.msg === msgErrore) {
                this.apriToasterErrore(response);
            } else {
                if (updateNotifiche) {
                    let notifiche = _.cloneDeep(this.state.notifiche);
                    notifiche = notifiche.concat(response?.notificaDto?.elementi);
                    this.setState({
                        notifiche,
                        notificheTotali: response?.notificaDto?.elementiTotali
                    });
                } else {
                    let notifiche = response?.notificaDto?.elementi;
                    notifiche.map(notifica => ({ ...notifica, checked: false }));
                    this.setState({
                        notifiche: notifiche,
                        notificheTotali: response?.notificaDto?.elementiTotali
                    });
                }

                if (!Utils.isStringEmptyOrNullOrUndefined(this.state?.idNotifica)) {
                    this.caricaNotifica(this.state?.idNotifica);
                } else if (response?.notificaDto?.elementi.length > 0) {
                    this.caricaNotifica(response?.notificaDto?.elementi[0]?.id);
                } else this.setState({ notifica: null });
            }
            nascondiSpinner();
        })
    }

    caricaNotifica = (idNotifica) => {
        mostraSpinner();
        NotificaService.getNotifica(idNotifica).subscribe((response) => {
            if (response?.msg === msgErrore) {
                this.apriToasterErrore(response);
            } else {
                let notifiche = _.cloneDeep(this.state.notifiche);

                if (notifiche?.filter(el => el.id === idNotifica)?.[0]?.visualizzato === "NO") {
                    this.context.diminuisciNumeroNotificheNonLette(1);
                    notifiche.filter(el => el.id === idNotifica)[0].visualizzato = "SI";
                }

                this.setState({ notifica: response, idNotifica: response.id, notifiche: notifiche }, () => {
                    this.caricaRichiesta(response.idRichiesta);
                });
            }
            nascondiSpinner();
        })
    }

    caricaRichiesta = (idRichiesta) => {
        RichiestaADIService.getRichiesteHome(null, null, {
            filtriRichiesta: { id: idRichiesta },
            filtriAssistito: {}
        }, null, null, null).subscribe((response) => {
            if (response?.msg === msgErrore) {
                this.apriToasterErrore(response);
            } else if (response?.data?.elementi?.length > 0) {
                this.setState({ richiesta: response?.data?.elementi[0] });
            }
        })
    }

    apriToasterErrore = (response) => {
        Utils.toasterFunction({
            status: response?.data?.codice ?? response?.status,
            type: "critical",
            text: response?.data?.errore ?? response?.data?.message
        })
    }

    handleChange = (field, value) => {
        let state = _.cloneDeep(this.state);

        state[field] = value;
        state.sizeNotifiche = _.cloneDeep(defaultSize);
        state.pageNotifiche = _.cloneDeep(defaultPage);

        if ((field === fieldMostraNotificheNonLette && value) || field === fieldCampoDaEvidenziare) {
            const notifica = state.notifiche?.find(n => n.visualizzato === "NO");
            state.idNotifica = notifica?.idNotifica ?? null;
            state.notifica = notifica ?? null;
        }

        this.setState(state);
    }

    getColoreIconaCercaNelleNotifiche = (state) => {
        if (Utils.isStringEmptyOrNullOrUndefined(state.filtroNotifiche)) return {colore: "#A5A5A5", disabled: true};
        else return {colore: "#176A65", disabled: false};
    }

    onClickPreviewNotifica = (idNotifica) => {
        this.caricaNotifica(idNotifica);
    }

    resetFiltroNotifiche = () => {
        this.setState({
            filtroNotifiche: "",
            sizeNotifiche: _.cloneDeep(defaultSize),
            pageNotifiche: _.cloneDeep(defaultPage)
        }, () => {
            this.caricaNotifiche();
            this.handleChange(fieldCampoDaEvidenziare, "");
        });
    }

    checkNotifica = (check, id) => {
        let notifiche = _.cloneDeep(this.state.notifiche);
        let notificheDaEliminare = _.cloneDeep(this.state.notificheDaEliminare);

        if (check) {
            notificheDaEliminare.push(id);
        }
        else {
            notificheDaEliminare = notificheDaEliminare.filter(el => el !== id);
        }

        let newNotifiche = notifiche.map(el => el.id === id ? { ...el, checked: check } : el);

        this.setState({ notifiche: newNotifiche, notificheDaEliminare: notificheDaEliminare });
    }

    selezionaTutteLeNotifiche = () => {
        let notifiche = _.cloneDeep(this.state.notifiche);
        let notificheDaEliminare = _.cloneDeep(this.state.notificheDaEliminare);

        let newNotifiche = notifiche.map(el => ({ ...el, checked: true }));
        notificheDaEliminare = notifiche.map(el => el.id);

        this.setState({ notifiche: newNotifiche, notificheDaEliminare: notificheDaEliminare, selezionaTutteLeNotifiche: true });
    }

    deselezionaTutteLeNotifiche = () => {
        let notifiche = _.cloneDeep(this.state.notifiche);
        let notificheDaEliminare = _.cloneDeep(this.state.notificheDaEliminare);

        let newNotifiche = notifiche.map(el => ({ ...el, checked: false }));
        notificheDaEliminare = [];

        this.setState({ notifiche: newNotifiche, notificheDaEliminare: notificheDaEliminare, selezionaTutteLeNotifiche: false });
    }

    isDisabledElimina = () => {
        let notifiche = _.cloneDeep(this.state.notifiche);

        return !notifiche.some(notifica => notifica.checked === true);
    }

    eliminaNotificheSelezionate = () => {
        NotificaService.eliminaNotifiche(this.state.notificheDaEliminare).subscribe((response) => {
            if (response?.msg === msgErrore) {
                this.apriToasterErrore(response);
            } else {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "successo",
                    text: "Notifiche eliminate correttamente."
                })
                this.setState({notificheDaEliminare: [], idNotifica: null})
                this.caricaNotifiche();
            }
        })
    }

    onClick = (state) => {
        this.caricaNotifiche();
        this.handleChange(fieldCampoDaEvidenziare, state.filtroNotifiche);
    }

    render() {
        let state = _.cloneDeep(this.state);
        let iconaCercaNelleNotifiche = this.getColoreIconaCercaNelleNotifiche(state);
        return (
            <Fragment>
                <Breadcrumb listClassName={[styles.breadcrumbCentroNotifiche, "container-fluid"].join(" ")}>
                    <BreadcrumbItem tag="span">Home</BreadcrumbItem>
                    <BreadcrumbItem tag="span" className={styles.breadcrumbSeparator}><span className={styles.breadcrumbCentroNotificheElem}>Centro notifiche</span></BreadcrumbItem>
                </Breadcrumb>
                <Row className={styles.title}>
                    <Col xs={5} className={"align-self-center"}>
                        <h1 style={{color: "var(--prymary)"}}>{pageTexts.centroNotifiche.header}</h1>
                    </Col>
                </Row>
                <Row className={styles.searchBarStyle}>
                    <Col xs={1} >
                        <Row className="w-100 justify-content-center">
                            <Button
                                id={"ricerca"}
                                text={"Ricerca"}
                                className={[styles.buttonIconSearch, styles.cerca].join(" ")}
                                //style={iconaCercaNelleNotifiche.disabled || (!state.notifiche?.length > 0 && Utils.isStringEmptyOrNullOrUndefined(state.filtroNotifiche)) ? {cursor: "not-allowed", outline: "none"} : {outline: "none"}}
                                disabled={iconaCercaNelleNotifiche.disabled || (!state.notifiche?.length > 0 && Utils.isStringEmptyOrNullOrUndefined(state.filtroNotifiche))}
                                onClick={() => {this.onClick(state)}}>
                                <Icon.Search size={24}/>
                                <span className={styles.textSearch}>Cerca</span>
                            </Button>
                        </Row>
                    </Col>
                    <Col xs={4}
                         className={styles.buttonIcons}
                         style={{boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 20%)"}}>

                        <InputGroup>
                            <Input
                                placeholder={pageTexts.centroNotifiche.placeholderFiltroNotifiche}
                                id={idFiltroNotifiche}
                                field={idFiltroNotifiche}
                                className={styles.inputStyle.concat(" border-bottom-0")}
                                onChange={(event) => this.handleChange(idFiltroNotifiche, event.target.value)}
                                value={state.filtroNotifiche}
                                disabled={!state.notifiche?.length > 0 && Utils.isStringEmptyOrNullOrUndefined(state.filtroNotifiche)}
                                autoComplete={"off"}
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        this.onClick(state)
                                    }
                                }}
                            />
                            {!iconaCercaNelleNotifiche.disabled &&
                                <InputGroupAddon addonType="append">
                                    <Button className={styles.buttonIcons}
                                            onClick={() => this.resetFiltroNotifiche()}>
                                        <Icon.X color={iconaCercaNelleNotifiche.colore} size={24}/>
                                    </Button>
                                </InputGroupAddon>}
                        </InputGroup>
                    </Col>
                </Row>
                <Container fluid={true}
                           id={"containerNotificheList"}
                           className={"pl-4 pr-4 mr-3 position-relative"}
                           style={{minHeight: "calc(100vh - 20.7rem)"}}>
                    <Row>
                        <Col xs={6}>
                            <Container fluid={true} className={"d-flex flex-column"}>
                                <Row className={"d-flex justify-content-between w-100 pb-3"}>
                                    <div className={"text-left col-1"}>
                                        <Label
                                            className={[styles.labelFiltri, !state.mostraNotificheNonLette ? styles.labelFiltriSelected : ""].join(" ").trim()}
                                            onClick={() => this.handleChange(fieldMostraNotificheNonLette, false)}
                                        >
                                            Tutte
                                        </Label>
                                    </div>
                                    <div className={"col-auto"}>
                                        <Label
                                            className={[styles.labelFiltri, state.mostraNotificheNonLette ? styles.labelFiltriSelected : ""].join(" ").trim()}
                                            onClick={() => this.handleChange(fieldMostraNotificheNonLette, true)}
                                        >
                                            Non lette
                                        </Label>
                                    </div>
                                    <Col className={"text-right"}>
                                        <Label
                                            className={styles.labelFiltri}
                                            onClick={() => this.handleChange(fieldOrdinaNotifichePerDataCrescente, !state.ordinaNotifichePerDataCrescente)}
                                        >
                                            Per data {state.ordinaNotifichePerDataCrescente ?
                                                <Icon.ChevronUp size={16} /> : <Icon.ChevronDown size={16} />}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row className={!state.notifiche?.length > 0 ? 'd-none' : ''}>
                                    <Col xs="auto" className="pl-2">
                                        <CheckBox
                                            id={"selezionaTutteLeNotifiche"}
                                            onClick={() => { state.selezionaTutteLeNotifiche ? this.deselezionaTutteLeNotifiche() : this.selezionaTutteLeNotifiche() }}
                                            checked={state.selezionaTutteLeNotifiche}
                                            className={styles.checkboxNotifica.concat(" pt-3")}
                                        />
                                    </Col>
                                    <Col>
                                        <Label>{state.selezionaTutteLeNotifiche ? " Deseleziona tutte le notifiche" : " Seleziona tutte le notifiche"}</Label>
                                    </Col>
                                </Row>
                                <Row className="w-100">
                                    <Jumbotron
                                        className={[styles.borderStyle, "w-100", styles.sectionScroll, "customScrollbarStyle"].join(" ")}
                                        style={!state.notifiche?.length > 0
                                            ? {height: "420px"}
                                            : {height: `calc(100vh - ${document.getElementById("containerNotificheList").offsetHeight}px - 2rem)`}}>
                                        {state.notifiche?.map(el => (
                                            <>
                                                <Row className={"pb-2"}>
                                                    <Col xs="auto" className="align-self-center pl-3">
                                                        <CheckBox
                                                            id={el.id}
                                                            onClick={(e) => this.checkNotifica(e.target.checked, el.id)}
                                                            checked={el.checked}
                                                            className={styles.checkboxNotifica.concat(" pt-3")}
                                                        />
                                                    </Col>
                                                    <div className={"col-xl col-11"}>
                                                        <Label check className={styles.labelNotifiche}>
                                                            <PreviewNotifica key={el.id} notifica={el}
                                                                onClickNotifica={this.onClickPreviewNotifica}
                                                                idNotificaSelezionata={state.idNotifica} />
                                                        </Label>
                                                    </div>
                                                </Row>
                                            </>
                                        ))}
                                        {!state.notifiche?.length > 0
                                            && <Col className={"text-center"} style={{marginTop: "30%"}}>Nessuna notifica presente</Col>}
                                    </Jumbotron>
                                </Row>
                                {state.notifiche?.length > 0
                                    && <Row className={'mt-4'}>
                                        <Col xs={this.state.notifiche?.length < this.state.notificheTotali ? 6 : 12}>
                                            <Row className="w-100 justify-content-center">
                                                <BtnWA
                                                    id={"elimina"}
                                                    text={"Elimina"}
                                                    onClick={() => this.eliminaNotificheSelezionate()}
                                                    className={"btn btn-adi btnRedOutline"}
                                                    disabled={this.isDisabledElimina()}/>
                                            </Row>
                                        </Col>
                                        {this.state.notifiche?.length < this.state.notificheTotali &&
                                            <Col xs={6}>
                                                <Row className="w-100 justify-content-center">
                                                    <BtnWA
                                                        id={"mostraAltri"}
                                                        text={"Mostra altri"}
                                                        onClick={() => this.setState({pageNotifiche: this.state.pageNotifiche + defaultPage})}
                                                        className={"btn btn-adi btnGreenOutline"}/>
                                                </Row>
                                                <Row className="w-100 justify-content-center">
                                                    <p className="mb-0">{this.state.notifiche?.length} notifiche
                                                        di {this.state.notificheTotali}</p>
                                                </Row>
                                            </Col>}
                                    </Row>}
                            </Container>
                        </Col>
                        {!Utils.isStringEmptyOrNullOrUndefined(state.notifica)
                            && <Col xs={6}>
                                <Jumbotron fluid
                                           className={[styles.notificaInfoContainer, styles.maxHeightContent].join(" ")}>
                                    <Notifica notifica={state.notifica}
                                              richiesta={state.richiesta}
                                              campoDaEvidenziare={state.campoDaEvidenziare}
                                              additionalPadding={this.state.notifiche?.length < this.state.notificheTotali ? 'pb-3' : 'pb-1'}/>
                                </Jumbotron>
                            </Col>}
                    </Row>
                </Container>
            </Fragment >
        )
    }
}

CentroNotifiche.propTypes = propTypes;
CentroNotifiche.contextType = Context;

export default withRouter(CentroNotifiche);
