import React, {Component} from 'react'
import styles from './CheckBox.module.css'
import PropTypes from 'prop-types'

const defaultProps = {
    text: '',
    disabled: false
}

const propTypes = {
    title: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    field: PropTypes.string,
    id: PropTypes.string,
    customStyles: PropTypes.string,
    styleLabel: PropTypes.object,
    className: PropTypes.string,
    text: PropTypes.string,
    disabled: PropTypes.bool,
    classContainer: PropTypes.string,
}

export default class CheckBox extends Component {

    state = {
        checked: this.props.checked
    }

    handleOnChange(e) {
        this.setState({checked: e.target.checked})
        if (this.props.onChange) {
            this.props.onChange(this.props.field, {
                target: {checked: e.target.checked}
            })
        }
    }

    render() {
        const {
            ...attributes
        } = this.props
        return <div className={styles.removeMargin.concat(' form-check ').concat( this.props.classContainer)}>
            <input
                {...attributes}
                checked={this.props.checked}
                onChange={e => this.handleOnChange(e)}
                id={this.props.id ?? 'checkbox1'}
                type="checkbox"
                style={this.props.customStyles}
                disabled={this.props.disabled}
            />
            <label htmlFor={this.props.id ?? 'checkbox1'} className={styles.removeMargin}>
                <span style={this.props.styleLabel}
                      className={[styles.fontCheckBox, this.props.className ? this.props.className : null].join(' ')}>
                    {this.props.text}
                </span>
            </label>
        </div>
    }
}

CheckBox.propTypes = propTypes
CheckBox.defaultProps = defaultProps

