import React, {Component, Fragment} from "react";
import Table from "../../generalComponents/table/Table";
import * as _ from 'lodash';
import DelegaService from "../../../service/DelegaService";
import {Button} from "web-client-archetype";
import stili from "./TabellaDeleghe.module.css";
import ModalHelper from "../../modale/ModalHelper";
import Utils from "../../../utils/Utils";
import enumUtente from "../../../enum/enumsUtente"
import enumDelega from "../../../enum/enumDelega"
import classificazione from "../../../utils/dataset/richiestaData/classificazione"
import PropTypes from "prop-types";
import AulssUtils from "../../../utils/AulssUtils";
import disciplinaData from "../../../utils/dataset/disciplinaData/disciplinaSpecialisti";
import styles from "../GestioneDeleghe.module.css";
import * as BsIcon from "react-bootstrap-icons";
import {mostraSpinner, nascondiSpinner} from "../../../App";
import AuthUtils from "../../../utils/AuthUtils";
import {LISTA_TIPOLOGIE_ADI} from "../inserisciDelega/InserisciDelega";
import aulssStrutture from "../../../utils/dataset/aulssData/strutture.json";

const propTypes = {
    name: PropTypes.string,
    listaDeleghe: PropTypes.array, // lista oggetti delega
    ricaricaTabella: PropTypes.func, // ricerca la liste deleghe
    cambiaPagina:PropTypes.func // cambia pagina al click del bottone
}

const DEFAULT_SIZE = 15;

const COLS_ADI_DIR_UO = [
    { key: "nomeCognomeProfessionista", name: "Professionista nominato" },
    { key: "codiceFiscaleProfessionista", name: "Codice fiscale" },
    { key: "ruolo", name: "Ruolo" },
    { key: "unitaOperativa", name: "Unita operativa" },
    { key: "disciplina", name: "Disciplina" },
    { key: "dataInizio", name: "Data inizio" },
    { key: "dataFine", name: "Data fine" },
    { key: "azioni", name: "Azioni nomina" },
    { key: "elimina", name: null, sortDirection: null, tipo: "array" },
    { key: "modifica", name: null, sortDirection: null, tipo: "array" }
];

const COLS_ADI_REF = [
    { key: "aulss", name: "Aulss" },
    { key: "distretto", name: "Distretto" },
    { key: "ruolo", name: "Delega attiva per" },
    { key: "tipologia", name: "Tipologia ADI" },
    { key: "azioni", name: "Azioni delegate" },
    { key: "elimina", name: null, sortDirection: null, tipo: "array" }
];

const COLS_ADI_AMM = [
    { key: "aulss", name: "Aulss" },
    { key: "distretto", name: "Distretto" },
    { key: "ambito", name: "Ambito" },
    { key: "sede", name: "Sede" },
    { key: "email", name: "Email" },
    { key: "attivaDisattiva", name: null, sortDirection: null, tipo: "array"},
    { key: "modifica", name: null, sortDirection: null, tipo: "array" }
];

export default class TabellaDeleghe extends Component {

    state = {
        delega: null,
        cols: [],
        rows: [],
        totalElements: null,
        size: _.cloneDeep(DEFAULT_SIZE),
        isElimina: false,
        centraleToggle: null
    };

    componentDidMount() {
        this.setCols()
        this.creaRigheDeleghe();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!_.isEqual(prevProps.listaDeleghe, this.props.listaDeleghe)){
            this.creaRigheDeleghe()
        }
    }

    setCols = (callback = null) => {
        let cols;
       if(this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
            cols = _.cloneDeep(COLS_ADI_DIR_UO);
        } else if ( this.props.name == enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE) {
            cols = _.cloneDeep(COLS_ADI_REF);
            if(AuthUtils.hasUtenteRuoloAmministratoreSistema()){
                cols = _.filter(cols, col => col.key !== "elimina");
            }
        }else if ( this.props.name == enumDelega.menu.CENSIMENTO_CENTRALE_ADI) {
           cols = _.cloneDeep(COLS_ADI_AMM);
       }
        this.setState({cols: cols}, () => {if(callback) callback()})
    }

    creaRigheDeleghe = () => {
        let righe = [];

        if(this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
            this.creaRigheDelegheDirUo(righe);
        } else if(this.props.name == enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE){
            this.creaRigheDelegheRef(righe)
        } else if(this.props.name == enumDelega.menu.CENSIMENTO_CENTRALE_ADI){
            this.creaRigheDelegheAmm(righe)
        }
        // ordine alfabetico per aulss e distretto
        righe = _.orderBy(righe, ['aulss', 'distretto', 'ruolo', 'tipologia', 'ambito'], ['asc', 'asc', 'asc', 'asc']);
        this.setState({rows: righe, totalElements: righe?.length});
    };

    creaRigheDelegheDirUo(righe) {
        _.forEach(this.props.listaDeleghe, delega => {
            // conversione per la lettura sul fe
            delega.dataInizio = Utils.transformDateAndTimeForFrontend(delega.dataInizio);
            delega.dataFine = delega.dataFine ? Utils.transformDateAndTimeForFrontend(delega.dataFine) : null

            let riga = {};
            riga.aulss = AulssUtils.getAulssByCodiceAulss(delega.aulss)?.descrizione;
            riga.distretto = AulssUtils.getDistrettoByIdAulssAndIdDistretto(delega.aulss, delega.distretto)?.descrizione;
            riga.ambito = AulssUtils.getAmbitoByIdAulssAndIdDistrettoAndIdAmbito(delega.aulss, delega.distretto, delega.ambito)?.descrizione;

            riga.disciplina = delega.disciplina ? (_.find(disciplinaData, d => d.codice === delega.disciplina)?.descrizione ?? null) : "-";

            riga.nomeCognomeProfessionista = _.join([delega.professionistaDelegato.nome, delega.professionistaDelegato.cognome], " ");
            riga.codiceFiscaleProfessionista = delega.professionistaDelegato.codiceFiscale;

            riga.ruolo = enumUtente.ruoliFE[delega.ruolo];
            riga.unitaOperativa = delega?.unitaOperativa;
            riga.dataInizio = delega.dataInizio;
            riga.dataFine = delega.dataFine ? delega.dataFine : "-";
            let azioniLabel = _.map(delega.azioni, azione => _.find(enumDelega.azioni, a => a.value === azione).label);
            riga.azioni = _.join(azioniLabel, " | ");

            riga.elimina = [this.renderElimina(delega)];
            riga.modifica = [this.renderModifica(delega)];
            righe.push(riga);
        })
    }

    creaRigheDelegheRef(righe) {
        _.forEach(this.props.listaDeleghe, delega => {
            _.forEach(delega.tipologie, tipologia => {
                let riga = {};
                riga.aulss = AulssUtils.getAulssByCodiceAulss(delega.aulss)?.descrizione;
                riga.distretto = AulssUtils.getDistrettoByIdAulssAndIdDistretto(delega.aulss, delega.distretto)?.descrizione;
                let ruolo = enumUtente.ruoliFE[delega.ruolo];
                riga.ruolo = ruolo == null ? enumUtente.profilo[delega.ruolo] : ruolo;
                let azioniLabel = _.map(delega.azioni, azione => _.find(enumDelega.azioni, a => a.value === azione).label);
                riga.azioni = _.join(azioniLabel, " | ");
                let tipologiaLabel = classificazione[tipologia]?.label;
                riga.tipologia = tipologiaLabel ? tipologiaLabel : _.find(LISTA_TIPOLOGIE_ADI, t => t.value === tipologia)?.label;

                riga.elimina = [this.renderElimina(delega, tipologia)];
                righe.push(riga);
            })
        })
    }

    creaRigheDelegheAmm(righe) {
        _.forEach(this.props.listaDeleghe, delega => {
            _.forEach(delega.listaCentrali, centrale => {
                let riga = {};
                riga.aulss = AulssUtils.getAulssByCodiceAulss(delega.aulss)?.descrizione;
                riga.distretto = AulssUtils.getDistrettoByIdAulssAndIdDistretto(delega.aulss, delega.distretto)?.descrizione;
                riga.ambito = centrale.ambito;
                riga.sede = aulssStrutture.find(a => a.id === delega?.aulss)?.distretti?.find(d => d.id === delega?.distretto)?.sedi?.find(s => s.ambiti.find(a => a.codice === centrale.ambito))?.nome
                riga.email = centrale.email;
                riga.attivaDisattiva = [this.renderAttivaDisattiva(delega, centrale)];
                riga.modifica =[this.renderModifica(delega, centrale)];
                righe.push(riga);
            })
        })
    }

    renderElimina = (delega, tipologia) => {
        return <Fragment>
                <span
                    id={"elimina" + delega.id}
                    className={stili.testoAzioni}
                    onClick={() => {
                        this.setState({delega: delega, tipologiaDaEliminare: tipologia, isElimina: true});
                    }}
                >
                        <BsIcon.Trash3Fill size={20} className={stili.iconSpaces} color={"#176A65"}/>
                        Elimina
                        </span>
        </Fragment>
    }

    renderModifica = (delega, centrale = null) => {
        let func;
        let color = "#717273";
        if (!centrale || centrale?.attivo) {
            func = () => {
                this.props.cambiaPagina(true, delega, centrale)
            }
            color = "#176A65"
        }
        return <Fragment>
                <span
                    id={"modifica" + delega.id}
                    className={stili.testoAzioni}
                    onClick={func}
                >
                        <BsIcon.PenFill size={20} color={color} className={stili.iconSpaces}/>
                        Modifica
                        </span>
        </Fragment>
    }

    renderAttivaDisattiva = (delega, centrale = null) => {
        let text = centrale.attivo ? "Disattiva" : "Attiva";
        let icon =  <BsIcon.CheckCircleFill size={20} className={stili.iconSpaces} color={!centrale.attivo ? "#D83D3D" : "#176A65"}/>;
        return <Fragment>
                <span
                    id={"attivaDisattiva" + delega.id}
                    className={stili.testoAzioni}
                    onClick={() => {
                        this.setState({delega: delega, centraleToggle: centrale})
                    }}
                >
                    {icon}
                    {text}
                        </span>
        </Fragment>
    }

    eliminaDelega = (delega, tipologia) => {
        DelegaService.eliminaDelega(delega.id, tipologia).subscribe((res) => {
            setTimeout(() => {
                this.setState({delega: null, isElimina: false}, () => {
                    if (!res.isError) {
                        let text;
                        if(this.props.name === enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE){
                            text = "La delega è stata correttamente eliminata.";
                        } else if(this.props.name === enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
                            text = "La nomina automatica è stata correttamente eliminata.";
                        }
                        let load = {
                            messaggioSuccesso: text
                        }
                        this.props.ricaricaTabella(load);
                    }
                })
            }, 200)
        });
    }

    modificaDelega = (delega, centraleToggle) => {
        let listaCentrali = _.map(delega.listaCentrali, centrale => {
            if(centrale.ambito === centraleToggle.ambito){
                centrale.attivo = !centraleToggle.attivo;
            }
            return centrale;
        })
        let patchBody = {
            listaCentrali: listaCentrali
        };
        DelegaService.modificaDelega(delega.id, patchBody).subscribe((res) => {
            this.setState({delega: null, centraleToggle: null}, () => {
                if (!res.isError) {
                    let text = centraleToggle.attivo ? "disattivata" : "attivata";
                    let load = {
                        messaggioSuccesso: "La centrale ADI è stata " + text + " correttamente"
                    }
                    this.props.ricaricaTabella(load);
                }
            })
        });
    }

    mostraAltreDeleghe = () => {
        mostraSpinner()
        let size = _.cloneDeep(this.state.size);
        this.setState({size: size + _.cloneDeep(DEFAULT_SIZE)}, () => {this.setCols(nascondiSpinner)});
    }

    renderModaleElimina = (textBody, textBold) => {
        let delega = _.cloneDeep(this.state.delega);
        let tipologiaDaEliminare = _.cloneDeep(this.state.tipologiaDaEliminare);

        textBody = textBody
            .replace("$nome", delega?.professionistaDelegato?.nome)
            .replace("$cognome", delega?.professionistaDelegato?.cognome);

        let body = <div>
                    <span>{textBody}</span>
            <br/>
            <span className="font-weight-bold">{textBold}</span>
        </div>;

        let footer = (
            <Fragment>
                <Button
                    id={"conferma"}
                    text={"Conferma"}
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => this.eliminaDelega(delega, tipologiaDaEliminare)}
                />
                <Button
                    id={"annulla"}
                    text={"Annulla"}
                    dataDismiss={"modal"}
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    onClick={() => this.setState({delega: null, isElimina: false})}
                />
            </Fragment>
        );

        return (
            <ModalHelper id={"modaleEliminaDelega"}
                         title={"Attenzione!"}
                         body={body}
                         footer={footer}
                         titleClassName={"modal-header d-inline text-center backgroundOrange"}
                         containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                         contentClassName={"modal-content"}
                         footerClassName={"modal-footer justify-content-around"}
                         bodyClassName={"modal-body align-items-center d-flex justify-content-center text-center"}
            />)
    }

    renderModaleAttivaDisattiva = () => {
        let delega = _.cloneDeep(this.state.delega);
        let centraleToggle = _.cloneDeep(this.state.centraleToggle);

        let text = centraleToggle.attivo ? "la disattivazione" : "l'attivazione";
        let body = <div>
            <span>Stai per procedere con {text} di una centrale adi.</span>
            <br/>
            <span className="font-weight-bold">Vuoi confermare l’operazione?</span>
        </div>;

        let footer = (
            <Fragment>
                <Button
                    id={"conferma"}
                    text={"Conferma"}
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => this.modificaDelega(delega, centraleToggle)}
                />
                <Button
                    id={"annulla"}
                    text={"Annulla"}
                    dataDismiss={"modal"}
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    onClick={() => this.setState({delega: null, centraleToggle: null})}
                />
            </Fragment>
        );

        return (
            <ModalHelper id={"modaleEliminaDelega"}
                         title={"Attenzione!"}
                         body={body}
                         footer={footer}
                         titleClassName={"modal-header d-inline text-center backgroundOrange"}
                         containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                         contentClassName={"modal-content"}
                         footerClassName={"modal-footer justify-content-around"}
                         bodyClassName={"modal-body align-items-center d-flex justify-content-center text-center"}
            />)
    }

    render() {
        // slice rows su size (mostra altri fe)
        let rows = _.cloneDeep(this.state.rows);
        rows = _.slice(rows, 0, (this.state.size));

        let titolo, emptyMsg, testoBottoneInserimento, testoModaleElimina, testoAttenzioneModaleElimina;
        if(this.props.name === enumDelega.menu.CENSIMENTO_CENTRALE_ADI){
            titolo = "Monitoraggio centrali ADI";
            emptyMsg = "Non sono presenti centrali ADI"
            testoBottoneInserimento = "Inserisci nuova centrale ADI"
        } else if(this.props.name === enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE){
            titolo = "Monitoraggio deleghe";
            emptyMsg = "Non sono presenti deleghe";
            if (!AuthUtils.hasUtenteRuoloAmministratoreSistema()) {
                testoBottoneInserimento = "Inserisci nuova delega"
            }
            testoModaleElimina = "Stai per procedere con l’eliminazione della delega precedentemente inserita";
            testoAttenzioneModaleElimina ="Vuoi confermare l’operazione?";
        } else if(this.props.name === enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
            titolo = "Monitoraggio nomine automatiche professionisti";
            emptyMsg = "Non sono presenti nomine automatiche di professionisti"
            testoBottoneInserimento = "Inserisci nuova nomina"
            testoModaleElimina = "Stai per eliminare la nomina automatica per il professionista $nome $cognome";
            testoAttenzioneModaleElimina = "Vuoi procedere?"
        }
        return (
            <Fragment>
                {this.state.isElimina ? this.renderModaleElimina(testoModaleElimina, testoAttenzioneModaleElimina) : <Fragment/>}
                {this.state.centraleToggle ? this.renderModaleAttivaDisattiva() : <Fragment/>}
                <div className={styles.customContainer.concat(" pt-5 px-5")}>
                    <div className="row">
                        <div className="col-6 mb-3"> <h1>{titolo}</h1></div>
                        <div className="col-6 mt-3"> {this.renderBottoneInserisciDelega(testoBottoneInserimento)}</div>
                    </div>
                    <hr className={stili.separator}/>
                    <div id={"containerStoricoRichiesteList"} className={"mb-5"}>
                        <Fragment>
                            <Table
                                cols={this.state.cols}
                                rows={rows}
                                emptyMsg={emptyMsg}
                                totalElements={this.state.totalElements}
                                bigTable={true}
                                nascondiPaginazione={true}
                                lengthEditable={false}
                                homePage={false}
                                showScroll={true}
                                internalScroll={true}
                                tableHeightForInternalScroll={'46vh'}
                                tableLenght={rows?.length}
                                alignColsToStart={true}
                            />
                            {this.renderMostraAltri(rows)}
                        </Fragment>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderBottoneInserisciDelega = (text) => {
        let bottoneJSX = <Fragment/>

        if (text) {
            bottoneJSX = <Fragment>
                <div className={"row m-0 justify-content-end"}>
                    <div className={"col-auto"}>
                        <Button
                            id={"inserisciNuovaDelega"}
                            text={<>
                                <BsIcon.PlusLg size={24} className={"mr-3"} style={{cursor: "pointer"}}/>
                                {text}
                            </>}
                            className={"btn btn-adi btnGreenOutline"}
                            onClick={() => {
                                this.props.cambiaPagina(true)
                            }}
                            disabled={false}
                        />
                    </div>
                </div>
            </Fragment>;
        }
        return bottoneJSX;
    }

    renderMostraAltri = (rows) => {
        let btnMostraAltri = <Fragment/>;
        if(rows?.length < this.state.totalElements) {
            btnMostraAltri = <Fragment>
                <div className="row justify-content-center mb-3 mt-3">
                    <Button
                        id={"mostraAltri"}
                        text="Mostra altri"
                        onClick={() => this.mostraAltreDeleghe()}
                        className="btn btn-adi btnGreenOutline"
                    />
                </div>
                <div className="row justify-content-center mb-3">
                    <p>{rows.length} richieste di {this.state.totalElements}</p>
                </div>
            </Fragment>;
        }
        return btnMostraAltri;
    }
}

TabellaDeleghe.propTypes = propTypes;


