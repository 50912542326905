import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import disciplinaData from "../../../utils/dataset/disciplinaData/disciplinaSpecialisti.json";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import stylesModale from "./ModaleAttivazioneFiguraProfessionale.module.css";
import styles from "./AttivazioneFiguraProfessionale.module.css";
import {Button, Input, SessioneUtente} from "web-client-archetype";
import Select from "react-select";
import {DropdownIndicator, inputSelectStyles} from "../../common/select/SelectUtils";
import {FieldsGroup, FieldsRow} from "../../roleBasedComponents/RoleBasedComponents";
import AuthUtils from "../../../utils/AuthUtils";
import enumUtente from "../../../enum/enumsUtente"
import ApiAPMSService from "../../../service/ApiAPMSService";
import {getDistrettoSettings, professionistaDefault} from "../StatoValutazioneEComponentiDelTeam";
import RichiestaADIService from "../../../service/RichiestaADIService";
import PopoverReact from "../../popover/Popover";
import {v4 as uuid} from "uuid";
import {LISTA_PROFESSIONISTI_INTERMEDI} from "../schedaProfessionista/SchedaProfessionista";
import * as BsIcon from "react-bootstrap-icons";
import enumsUtente from "../../../enum/enumsUtente.json";
import ButtonsBoxStick from "../../buttonsBoxStick/buttonsBoxStick";
import AulssUtils from "../../../utils/AulssUtils";
import ToponomasticaUtils from "../../../utils/ToponomasticaUtils";
import {isArray} from "lodash";

const propTypes = {
    pageState: PropTypes.string,
    onClickIndietro: PropTypes.func,
    onClickConferma: PropTypes.func,
    uuidRichiesta: PropTypes.string,
    richiedente: PropTypes.object,
    valutatori: PropTypes.array,
    paziente: PropTypes.object,
    teams: PropTypes.array,
    professionistaDaSostituire: PropTypes.object,
    professionistaDaInserire: PropTypes.object,
    richiesta: PropTypes.object
}

export const DISCIPLINA_FISIATRA = "22";

export const isRuoloMedicoSpecialista = (ruolo) => {
    return (ruolo === enumUtente.ruoli.specialista);
}

export const isRuoloMedicoSpecialistaFisiatra = (ruolo) => {
    return (ruolo === enumUtente.ruoli.fisiatra);
}

let profiliSelect = [
    {value: enumUtente.profiliUtente.professionistaValutatore, label: enumUtente.profilo["ADI-VAL"]}
];

let ruoliValutatoreSelect = [
    {
        value: enumUtente.ruoli.infermiere,
        label: "Infermiere"
    },
    {
        value: enumUtente.ruoli.specialista,
        label: "Medico specialista"
    },
    {
        value: enumUtente.ruoli.fisiatra,
        label: "Medico specialista - Fisiatra"
    },
    {
        value: enumUtente.ruoli.assistenteSociale,
        label: "Assistente Sociale"
    },
    {
        value: enumUtente.ruoli.logopedista,
        label: "Logopedista"
    },
    {
        value: enumUtente.ruoli.dietista,
        label: "Dietista"
    }
];

export const ruoliValutatoreSpecialistaSelect = [
    {
        value: enumUtente.ruoli.specialista,
        label: "Medico specialista"
    },
    {
        value: enumUtente.ruoli.fisiatra,
        label: "Medico specialista - Fisiatra"
    },
    {
        value: enumUtente.ruoli.psicologo,
        label: "Psicologo"
    },
];

export const disciplinaSelect = _.map(disciplinaData, disciplina => {
    return {value: disciplina.codice, label: disciplina.descrizione}
})

export const RUOLI_SELEZIONABILI_DA_MCP = [enumUtente.ruoli.logopedista, enumUtente.ruoli.dietista];
export const RUOLI_SELEZIONABILI_DA_COORDINATORE = [enumUtente.ruoli.infermiere];
export const RUOLI_SELEZIONABILI_DA_DIRETTORE_UO = [enumUtente.ruoli.specialista, enumUtente.ruoli.fisiatra, enumUtente.ruoli.psicologo];
export const RUOLI_SELEZIONABILI_DA_REF_DIR_ASS_SOCIALI = [enumUtente.ruoli.assistenteSociale];
export const RUOLI_SELEZIONABILI_DA_CENTRALE_ADI = [enumUtente.ruoli.infermiere];


const FIGURA_RICHIESTA_DEFAULT = {
    id: null,
    ruolo: null,
    disciplina: null,
    numeroProfessionisti: 1,
    aggiunto: false,
    codiceFiscaleAggiunti: []
}

export default class AttivazioneFiguraProfessionale extends Component {

    getNoteAggiuntaDaMcp = () => {
        if (AuthUtils.hasUtenteProfiloCoordinatore() || AuthUtils.hasUtenteProfiloReferenteAssistentiSociali() || AuthUtils.hasUtenteProfiloDirettoreUO()) {
            if (isArray(this.props.richiesta?.team))
                return this.props.richiesta?.team?.find(element => element?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente)?.professionista?.note || null
        }
        return null
    }

    state = {
        figuraProfessionale: {
            profilo: null,
            ruolo: null,
            disciplina: null,
            note: this.getNoteAggiuntaDaMcp(),
            codiceFiscale: null,
            nome: null,
            cognome: null,
            email: null,
        },
        figureRichieste: null,
        disabilitaFiltri: null,
        listaProfessionisti: [],
        isModaleAvvisoVisibile: false,
        ruoliSelect: [],
        profiliSelect: [],
        disciplinaSelect: [],
        unitaOperativaSelect: [],
        ruoliRichiestiSelect: [], // solo in caso di lista figure richieste
        disciplineRichiesteSelect: [], // solo in caso di lista figure richieste
        disciplinePerUo: [], // valori discipline per professionisti esistenti per uo
        isRicercaProfiloIntermedio: false
    }

    professionistiApms = [];

    componentDidMount() {
        this.inizializzaSelect();
    }

    hasUtenteCentraleAdiDelega = (richiesta) => {
        return richiesta?.delega?.some(d => d.valore && d.profilo===enumsUtente.profiliUtente.centraleAdi)
    }

    inizializzaSelect = () => {
        let profiliSelectState = _.cloneDeep(profiliSelect);
        let ruoliSelectState = _.cloneDeep(ruoliValutatoreSelect);
        let disciplinaSelectState = _.cloneDeep(disciplinaSelect);
        let unitaOperativaSelectState = [];
        let figuraProfessionale = _.clone(this.state.figuraProfessionale);
        let disabilitaFiltri = _.clone(this.state.disabilitaFiltri);
        if (disabilitaFiltri == null) {
            disabilitaFiltri = {}
        }
        let ruolo = null;
        let disciplina = null;
        let figureRichieste = null;
        let isSubentro = this.props.professionistaDaSostituire != null;

        if (AuthUtils.hasUtenteProfiloCoordinatore()) {
            // se il profilo dell'utente loggato è coordinatore avrà i profili (valutatore) con ruolo infermiere già valorizzato
            figuraProfessionale.profilo = enumUtente.profiliUtente.professionistaValutatore;
            ruolo = enumUtente.ruoli.infermiere;
            disabilitaFiltri.profilo = true;
            disabilitaFiltri.ruolo = true;
        }
        else if (AuthUtils.hasUtenteRuoloMcp() ) {
            unitaOperativaSelectState = AulssUtils.getUnitaOperativeByIdAulss(SessioneUtente.getInstance().ulss);
            let tutteUo = {value: null, label: "Tutti"};
            unitaOperativaSelectState = [tutteUo, ...unitaOperativaSelectState];
            // se il ruolo dell'utente loggato è mcp avrà i profili (valutatore, direttore uo, referente assistente sociale)
            let profiloDirettoreUO = {
                value: enumUtente.profiliUtente.direttoreUnitaOperativa,
                label: enumUtente.profilo["ADI-DIR-UO"]
            };
            profiliSelectState.push(profiloDirettoreUO);
            // setto lo stato per la lista figure richieste
            this.setState({
                ruoliRichiestiSelect: ruoliValutatoreSpecialistaSelect
            })


            let teamReferenteAssistentiSociali = _.find(this.props.teams, t => t.profilo === enumUtente.profiliUtente.referenteDirigenteAssistentiSociali) ?? null;
            if (teamReferenteAssistentiSociali == null || isSubentro) {
                let profiloReferenteAssistentiSociali = {
                    value: enumUtente.profiliUtente.referenteDirigenteAssistentiSociali,
                    label: enumUtente.profilo["ADI-REF-DIR-AS"]
                };
                profiliSelectState.push(profiloReferenteAssistentiSociali);
            }
        }
        else if (AuthUtils.hasUtenteCentraleAdi() ) {
            // se il ruolo dell'utente loggato è mcp avrà i profili (valutatore, direttore uo, referente assistente sociale)
            unitaOperativaSelectState = AulssUtils.getUnitaOperativeByIdAulss(SessioneUtente.getInstance().ulss);
            let tutteUo = {value: null, label: "Tutti"};
            unitaOperativaSelectState = [tutteUo, ...unitaOperativaSelectState];

            if(this.hasUtenteCentraleAdiDelega(this.props.richiesta)) {
                let profiloDirettoreUO = {
                    value: enumUtente.profiliUtente.direttoreUnitaOperativa,
                    label: enumUtente.profilo["ADI-DIR-UO"]
                };
                profiliSelectState.push(profiloDirettoreUO);
                // setto lo stato per la lista figure richieste
                this.setState({
                    ruoliRichiestiSelect: ruoliValutatoreSpecialistaSelect,
                    disciplineRichiesteSelect: disciplinaSelect
                })


                let teamReferenteAssistentiSociali = _.find(this.props.teams, t => t.profilo === enumUtente.profiliUtente.referenteDirigenteAssistentiSociali) ?? null;
                if (teamReferenteAssistentiSociali == null || isSubentro) {
                    let profiloReferenteAssistentiSociali = {
                        value: enumUtente.profiliUtente.referenteDirigenteAssistentiSociali,
                        label: enumUtente.profilo["ADI-REF-DIR-AS"]
                    };
                    profiliSelectState.push(profiloReferenteAssistentiSociali);
                }
            } else {
                ruoliSelectState = _.filter(ruoliValutatoreSelect, ruolo => RUOLI_SELEZIONABILI_DA_COORDINATORE.includes(ruolo.value));
            }
        }
        else if (AuthUtils.hasUtenteProfiloReferenteAssistentiSociali()) {
            // se il profilo dell'utente loggato è referente assistenti sociali avrà i profili (valutatore) con ruolo assistente sociale già valorizzato
            figuraProfessionale.profilo = enumUtente.profiliUtente.professionistaValutatore;
            ruolo = enumUtente.ruoli.assistenteSociale;
            disabilitaFiltri.profilo = true;
            disabilitaFiltri.ruolo = true;
        }
        else if (AuthUtils.hasUtenteProfiloDirettoreUO()) {
            let settingsDirUO = _.find(SessioneUtente.getInstance().settings, setting => setting.profilo === enumUtente.profiliUtente.direttoreUnitaOperativa);
            let teamProfessionista = _.find(this.props.teams, t => SessioneUtente.getInstance().idUtente === t?.professionista?.codiceFiscale
                && t.profilo === settingsDirUO?.profilo) ?? null;
            if (teamProfessionista != null) {
                let figureRichieste = teamProfessionista.figureRichieste;
                let ruoliRichiesti = _.uniq(_.map(figureRichieste, figura => figura.ruolo));
                let figureRichiesteSpecialisti = _.filter(figureRichieste, figura => figura.ruolo === enumUtente.ruoli.specialista);
                let disciplineRichieste = _.uniq(_.map(figureRichiesteSpecialisti, figura => figura.disciplina));
                ruoliSelectState = _.filter(ruoliValutatoreSpecialistaSelect, ruolo => ruoliRichiesti.includes(ruolo.value));
                disciplinaSelectState = _.filter(disciplinaSelectState, disciplina => disciplineRichieste.includes(disciplina.value));
            }
            figuraProfessionale.profilo = enumUtente.profiliUtente.professionistaValutatore;
        }

        // se viene passata la props professionistaDaSostituire avrà profilo, ruolo e disciplina già valorizzati
        if (this.props.professionistaDaSostituire) {
           let taFormSubentro = this.settaFormSubentro(figuraProfessionale, disabilitaFiltri, figureRichieste, ruolo, disciplina);
            figureRichieste = taFormSubentro.figureRichieste;
            ruolo = taFormSubentro.ruolo;
            disciplina = taFormSubentro.disciplina;
        }

        if(this.props.professionistaDaInserire){
            let taFormInserimento = this.settaFormInserimento(figuraProfessionale, disabilitaFiltri, figureRichieste, ruolo, disciplina);
            figureRichieste = taFormInserimento.figureRichieste;
            ruolo = taFormInserimento.ruolo;
            disciplina = taFormInserimento.disciplina;
        }

        this.inizializzaStato(ruolo, figuraProfessionale, disciplina, disabilitaFiltri, profiliSelectState, ruoliSelectState, disciplinaSelectState, figureRichieste, unitaOperativaSelectState);
    }

    settaFormSubentro = (figuraProfessionale, disabilitaFiltri, figureRichieste, ruolo, disciplina) => {
        if ((AuthUtils.hasUtenteRuoloMcp() || (AuthUtils.hasUtenteCentraleAdi() && this.hasUtenteCentraleAdiDelega(this.props.richiesta))) && LISTA_PROFESSIONISTI_INTERMEDI.includes(this.props.professionistaDaSostituire?.profilo)) {
            figuraProfessionale.profilo = this.props.professionistaDaSostituire?.profilo;
            disabilitaFiltri.profilo = true;
            // cerca figure
            figureRichieste = _.find(this.props.teams, team =>
                team.professionista?.codiceFiscale === this.props.professionistaDaSostituire?.codiceFiscale &&
                team?.professionista?.profilo === enumUtente.profiliUtente.direttoreUnitaOperativa)?.figureRichieste;
            // se si sostituisce un coord o ref ass soc si seleziona anche il ruolo
            if ([enumUtente.profiliUtente.referenteDirigenteAssistentiSociali,
                enumUtente.profiliUtente.coordinatore].includes(this.props.professionistaDaSostituire?.profilo)) {
                ruolo = this.props.professionistaDaSostituire.ruolo;
                disabilitaFiltri.ruolo = true;
            }
        } else {
            figuraProfessionale.profilo = enumUtente.profiliUtente.professionistaValutatore;
            ruolo = this.props.professionistaDaSostituire.ruolo;
            disciplina = this.props.professionistaDaSostituire.disciplina;
            disabilitaFiltri.profilo = true;
            disabilitaFiltri.ruolo = true;
            disabilitaFiltri.disciplina = true;
        }
        return {figureRichieste, ruolo, disciplina};
    }

    settaFormInserimento = (figuraProfessionale, disabilitaFiltri, figureRichieste, ruolo, disciplina) => {

        figuraProfessionale.profilo = this.props.professionistaDaInserire.profilo;
        ruolo = this.props.professionistaDaInserire.ruolo;
        disciplina = this.props.professionistaDaInserire.disciplina;

        return {figureRichieste, ruolo, disciplina};
    }

    inizializzaStato(ruolo, figuraProfessionale, disciplina, disabilitaFiltri, profiliSelectState, ruoliSelectState, disciplinaSelectState, figureRichieste, unitaOperativaSelectState) {
        if (ruolo) {
            figuraProfessionale.ruolo = ruolo;
        }

        if (disciplina) {
            figuraProfessionale.disciplina = ruolo;
        }

        let callbackAvviaRicerca = null;
        let oggettoStato = {figuraProfessionale: figuraProfessionale, disabilitaFiltri: disabilitaFiltri};
        if (!Utils.isObjectNull(figuraProfessionale) && figuraProfessionale?.disciplina) {
            callbackAvviaRicerca = () => {
                this.handleAggiornaForm("disciplina", disciplina)
            };
        } else if (!Utils.isObjectNull(figuraProfessionale) && figuraProfessionale?.ruolo) {
            callbackAvviaRicerca = () => {
                this.handleAggiornaForm("ruolo", ruolo)
            };
        } else if (!Utils.isObjectNull(figuraProfessionale)) {
            callbackAvviaRicerca = () => {
                this.handleAggiornaForm("profilo", figuraProfessionale.profilo)
            };
        }

        if (figureRichieste?.length) {
            oggettoStato.figureRichieste = figureRichieste;
        }

        let setStateSelect = () => {
            this.setState({
                profiliSelect: profiliSelectState,
                ruoliSelect: ruoliSelectState,
                disciplinaSelect: disciplinaSelectState,
                unitaOperativaSelect: unitaOperativaSelectState
            })
        }

        if (!Utils.isObjectNull(oggettoStato)) {
            this.setState(oggettoStato, () => {
                if (callbackAvviaRicerca != null) {
                    callbackAvviaRicerca()
                }
                setStateSelect();
            });
        } else {
            setStateSelect();
        }
    }

    hasUtenteDelega = (richiesta) => {
        return richiesta?.delega?.some(d => d.valore && d.profilo === enumsUtente.profiliUtente.centraleAdi)
    }

    handleAggiornaForm = (field, elem) => {
        let figuraProfessionale = _.cloneDeep(this.state.figuraProfessionale);
        let listaProfessionisti = _.cloneDeep(this.state.listaProfessionisti);
        let ruoliSelect = _.cloneDeep(this.state.ruoliSelect);
        let figureRichieste = _.cloneDeep(this.state.figureRichieste);
        let disciplinePerUo = _.cloneDeep(this.state.disciplinePerUo);
     //   const infermiereIsPresentToFigurePai = !!(this.props.richiesta?.figureProfessionaliPresaInCarico?.find(professionista => professionista?.figuraProfessionale === "03" || professionista?.figuraProfessionale === "04"));
        switch (field) {
            case "profilo": {
                let isProfiloModificato = figuraProfessionale.profilo !== elem;
                if (isProfiloModificato) {
                    figuraProfessionale.ruolo = null;
                    figuraProfessionale.disciplina = null;
                    figuraProfessionale.codiceFiscale = null;
                    listaProfessionisti = [];
                    figureRichieste = null;
                }
                if (elem === enumUtente.profiliUtente.referenteDirigenteAssistentiSociali) {
                    ruoliSelect = _.filter(ruoliValutatoreSelect, ruolo => ruolo.value === enumUtente.ruoli.assistenteSociale);
                    figuraProfessionale.ruolo = enumUtente.ruoli.assistenteSociale;
                } else if (elem === enumUtente.profiliUtente.professionistaValutatore) {
                    if (AuthUtils.hasUtenteRuoloMcp()) {
                        ruoliSelect = _.filter(ruoliValutatoreSelect, ruolo => RUOLI_SELEZIONABILI_DA_MCP.includes(ruolo.value));
                    } else if (AuthUtils.hasUtenteCentraleAdi()) {
                        if (this.hasUtenteCentraleAdiDelega(this.props.richiesta)) {
                            ruoliSelect = _.filter(ruoliValutatoreSelect, ruolo => RUOLI_SELEZIONABILI_DA_MCP.concat(RUOLI_SELEZIONABILI_DA_COORDINATORE).includes(ruolo.value));
                        } else {
                            ruoliSelect = _.filter(ruoliValutatoreSelect, ruolo => RUOLI_SELEZIONABILI_DA_COORDINATORE.includes(ruolo.value));
                        }
                    }
                } else if (elem === enumUtente.profiliUtente.direttoreUnitaOperativa) {
                    // se le figure richieste non sono state già inizializzate come nel caso del subentra si mostra una figura richiesta di default (vuota)f
                    if (!figureRichieste?.length) {
                        figureRichieste = [FIGURA_RICHIESTA_DEFAULT];
                    }
                }
            }
                break;
            case "ruolo": {
                let isRuoloModificato = figuraProfessionale.ruolo !== elem;
                if (isRuoloModificato) {
                    figuraProfessionale.disciplina = null;
                    figuraProfessionale.codiceFiscale = null;
                    listaProfessionisti = [];
                }
                if (elem === enumUtente.ruoli.fisiatra) {
                    figuraProfessionale.disciplina = DISCIPLINA_FISIATRA;
                }
            }
                break;
            case "disciplina": {
                let isDisciplinaModificata = figuraProfessionale.disciplina !== elem;
                if (isDisciplinaModificata) {
                    figuraProfessionale.codiceFiscale = null;
                    listaProfessionisti = [];
                }
            }
                break;
            case "unitaOperativa": {
                let resettaCfFigureRichieste = () => {
                    figuraProfessionale.codiceFiscale = null;
                    if(!this.props.professionistaDaSostituire) {
                        figureRichieste = [];
                    }
                }
                if(elem != null) {
                    let professionistiPerUoSelezionata = _.filter(this.professionistiApms, professionistaApms => professionistaApms.unitaOperativa === elem);
                    if (professionistiPerUoSelezionata?.length) {
                        let listaProfessionistiPerUo = [];
                        _.forEach(this.professionistiApms, professionistaApms => {
                            if (professionistaApms.unitaOperativa === elem) {
                                this.inserisciProfessionistaInLista(professionistaApms, listaProfessionistiPerUo)
                            }
                        });
                        listaProfessionisti = listaProfessionistiPerUo;
                        if(!listaProfessionisti?.length) {
                            resettaCfFigureRichieste()
                        }
                    } else {
                        listaProfessionisti = [];
                        resettaCfFigureRichieste()
                    }
                } else {
                    let nuovaListaProfessionisti = []
                    _.forEach(this.professionistiApms, professionistaApms =>
                        this.inserisciProfessionistaInLista(professionistaApms, nuovaListaProfessionisti))
                    listaProfessionisti = nuovaListaProfessionisti;
                    resettaCfFigureRichieste()
                }


            }
                break;
            case "codiceFiscale": {
                if (figuraProfessionale.profilo === enumUtente.profiliUtente.direttoreUnitaOperativa) {
                    let teamProfessionistaTrovato = _.find(this.props.teams,
                        team => team?.professionista?.codiceFiscale === elem &&
                            team?.professionista?.profilo === enumUtente.profiliUtente.direttoreUnitaOperativa);
                    if (teamProfessionistaTrovato) {
                        figureRichieste = _.concat(teamProfessionistaTrovato.figureRichieste, [FIGURA_RICHIESTA_DEFAULT]);
                    } else {
                        let isSubentro = this.props.professionistaDaSostituire != null;
                        if (!isSubentro) {
                            figureRichieste = [FIGURA_RICHIESTA_DEFAULT];
                        }
                    }

                    let unitaOperativa = _.find(this.professionistiApms, professionistaApms => professionistaApms.codiceFiscale === elem)?.unitaOperativa ?? null;
                    figuraProfessionale.unitaOperativa = unitaOperativa;
                    this.gestioneUnitaOperativa(unitaOperativa);
                }
            }
                break;
            default:
                break;
        }

        figuraProfessionale[field] = elem;
        this.setState({
            figuraProfessionale: figuraProfessionale,
            listaProfessionisti: listaProfessionisti,
            ruoliSelect: ruoliSelect,
            figureRichieste: figureRichieste
        }, () => {
            let cercaPerProfessionistaIntermedio = (field === "profilo" && elem !== enumUtente.profiliUtente.professionistaValutatore);
            let cercaPerValutatore = (field === "ruolo" && !isRuoloMedicoSpecialista(elem)) || field === "disciplina";
            let cerca = cercaPerProfessionistaIntermedio || cercaPerValutatore;
            if (cerca) {
                this.cercaProfessionisti()
            }
        });
    }

    gestioneUnitaOperativa = (unitaOperativa) => {
        if (unitaOperativa) {
            let ruoliValutatoreSpecialistaTemp = _.cloneDeep(ruoliValutatoreSpecialistaSelect);
            let utente = {
                aulss: SessioneUtente.getInstance().codiceStruttura,
                profilo: enumsUtente.profiliUtente.professionistaValutatore,
                unitaOperativa: unitaOperativa
            }
            let gestioneResponse = res => {
                let elementi = res.elementi;
                let disciplineFiltrate = [];
                let ruoliFiltrati = [];
                _.forEach(elementi, e => {
                    let gruppo = _.find(e?.gruppi, g => g["codice_gruppo"] === utente.profilo);
                    let attributo = gruppo ? _.find(gruppo?.attributi, a => a.codiceAttributo === "adi-lista-settings") : null;
                    let valoreAttributo = attributo?.valore?.slice(1, -1);
                    // recupero il corretto elemento della adi lista settings
                    let elements = valoreAttributo.split(",");
                    let elementsCodiceGruppo = _.filter(elements, elem => {
                        let settings = elem?.split("@");
                        return settings[0] === utente.profilo
                    });
                    _.forEach(elementsCodiceGruppo, elemento => {
                        let listaSettings = elemento?.split("@");
                        if(listaSettings[1])  ruoliFiltrati.push(listaSettings[1]);
                        if(listaSettings[5])  disciplineFiltrate.push(listaSettings[5]);
                    })
                })
                ruoliFiltrati = _.uniq(ruoliFiltrati);
                if (!ruoliFiltrati.includes(enumUtente.ruoli.fisiatra)) {
                    let indexRuolo = _.findIndex(ruoliValutatoreSpecialistaTemp, ruoloRichiesto => ruoloRichiesto.value === enumUtente.ruoli.fisiatra);
                    ruoliValutatoreSpecialistaTemp.splice(indexRuolo, 1);
                }
                if (!ruoliFiltrati.includes(enumUtente.ruoli.psicologo)) {
                    let indexRuolo = _.findIndex(ruoliValutatoreSpecialistaTemp, ruoloRichiesto => ruoloRichiesto.value === enumUtente.ruoli.psicologo);
                    ruoliValutatoreSpecialistaTemp.splice(indexRuolo, 1);
                }
                disciplineFiltrate = _.uniq(disciplineFiltrate);
                let disciplinePerUo = _.filter(disciplinaSelect, d => disciplineFiltrate.includes(d.value))
                this.setState({disciplineRichiesteSelect: disciplinePerUo, disciplinePerUo, ruoliRichiestiSelect: ruoliValutatoreSpecialistaTemp});
            };

            this.letturaUtentiApms(utente, gestioneResponse);
        }
    }

    handleAggiornaFigureRichieste = (field, elem, index) => {
        let figureRichieste = _.cloneDeep(this.state.figureRichieste);
        figureRichieste[index][field] = elem;
        if (field === "ruolo" && elem === enumUtente.ruoli.fisiatra) {
            figureRichieste[index].disciplina = DISCIPLINA_FISIATRA;
        } else if (field === "ruolo" && elem === enumUtente.ruoli.psicologo) {
            figureRichieste[index].disciplina = null;
        } else if (field === "ruolo" && elem === enumUtente.ruoli.specialista) {
            // resetta disciplina se selezionato specialista
            figureRichieste[index].disciplina = null;
        }
        this.aggiornaFigureRichieste(figureRichieste);
    }

    aggiornaFigureRichieste(figureRichieste) {
        let ruoliRichiestiSelect = _.cloneDeep(ruoliValutatoreSpecialistaSelect);
        let disciplineRichiesteSelect = _.cloneDeep(this.state.disciplinePerUo);
        // aggiorna select ruoli e discipline
        this.rimuoviRuolo(figureRichieste, ruoliRichiestiSelect, enumUtente.ruoli.fisiatra);
        this.rimuoviRuolo(figureRichieste, ruoliRichiestiSelect, enumUtente.ruoli.psicologo);
        this.rimuoviDiscipline(figureRichieste, disciplineRichiesteSelect);
        if (disciplineRichiesteSelect?.length === 0) {
            this.rimuoviRuolo(figureRichieste, ruoliRichiestiSelect, enumUtente.ruoli.specialista);
        }

        this.setState({
            figureRichieste: figureRichieste,
            ruoliRichiestiSelect: ruoliRichiestiSelect, disciplineRichiesteSelect: disciplineRichiesteSelect
        });
    }

    rimuoviDiscipline(figureRichieste, disciplineRichiesteSelect) {
        let isSpecilistaPresente = _.some(figureRichieste, figura => figura.ruolo === enumUtente.ruoli.specialista);
        if (isSpecilistaPresente) {
            let disciplineAggiunte = _.map(figureRichieste, figura => figura.disciplina);
            _.forEach(disciplineAggiunte, disciplinaAggiunta => {
                let indexDisciplina = _.findIndex(disciplineRichiesteSelect,
                    disciplinaRichiesta => disciplinaRichiesta.value === disciplinaAggiunta);
                disciplineRichiesteSelect.splice(indexDisciplina, 1);
            })
        }
    }

    rimuoviRuolo(figureRichieste, ruoliRichiestiSelect, ruolo) {
        let ruoloTrovato = _.find(figureRichieste, figura => figura.ruolo === ruolo) || null;
        if (ruoloTrovato != null) {
            let indexRuolo = _.findIndex(ruoliRichiestiSelect, ruoloRichiesto => ruoloRichiesto.value === ruolo);
            ruoliRichiestiSelect.splice(indexRuolo, 1);
        }
    }

    aggiungiFiguraRichiesta() {
        let figureRichieste = _.cloneDeep(this.state.figureRichieste);
        figureRichieste.push(FIGURA_RICHIESTA_DEFAULT);
        this.aggiornaFigureRichieste(figureRichieste);
    }

    eliminaFiguraRichiesta(index) {
        let figureRichieste = _.cloneDeep(this.state.figureRichieste);
        figureRichieste.splice(index, 1);
        this.aggiornaFigureRichieste(figureRichieste);
    }

    cercaProfessionisti = () => {
        let figuraProfessionale = _.cloneDeep(this.state.figuraProfessionale);
        let utente = {
            aulss: SessioneUtente.getInstance().codiceStruttura,
            profilo: figuraProfessionale.profilo,
            ruolo: figuraProfessionale.ruolo,
            disciplina: figuraProfessionale.disciplina,
            distretto: getDistrettoSettings(SessioneUtente.getInstance().settings)
        };

        // mcp cerca per direttore uo, per logopedista e per dietista con solo la aulss
        if (AuthUtils.hasUtenteRuoloMcp() || (AuthUtils.hasUtenteCentraleAdi() && this.hasUtenteCentraleAdiDelega(this.props.richiesta))) {
            if (figuraProfessionale.profilo === enumUtente.profiliUtente.direttoreUnitaOperativa ||
                figuraProfessionale.profilo === enumUtente.profiliUtente.professionistaValutatore) {
                utente.distretto = null
            }
        }

        // referente assistenti sociali cerca per proprio comune appartenenza
        if (figuraProfessionale.ruolo === enumUtente.ruoli.assistenteSociale &&
            AuthUtils.hasUtenteProfiloReferenteAssistentiSociali()) {
            let settingsRefDirAssSoc = _.find(SessioneUtente.getInstance().settings,
                setting => setting.profilo === enumUtente.profiliUtente.referenteDirigenteAssistentiSociali);
            utente.comune = settingsRefDirAssSoc?.comune
        }

        // direttore uo cerca per unita operativa e distretto null
        if (AuthUtils.hasUtenteProfiloDirettoreUO()) {
            let settingsDirUO = _.find(SessioneUtente.getInstance().settings, setting => setting.profilo === enumUtente.profiliUtente.direttoreUnitaOperativa);
            utente.unitaOperativa = settingsDirUO?.unitaOperativa ?? null
            utente.distretto = null
        }

        let gestioneResponse = res => {
            let elementi = res.elementi.filter(el => el.codiceFiscale !== SessioneUtente.getInstance().idUtente);

            this.setListaProfessionisti(elementi, utente.profilo, utente.ruolo, utente.disciplina)
        };

        this.letturaUtentiApms(utente, gestioneResponse);
    }

    letturaUtentiApms = (utente, gestioneResponse) => {
        ApiAPMSService.letturaUtenti(utente).subscribe(gestioneResponse);
    }

    setListaProfessionisti = (utenti, profilo, ruolo, disciplina) => {
        this.professionistiApms = [];
        let listaProfessionisti = [];
        _.forEach(utenti, utente => {
            // non si aggunge alla lista utente il richiedente
            if (utente?.codiceFiscale === this.props.richiedente?.codiceFiscale) {
                return;
            }

            // non si aggiunge alla lista utente i professionisti già presenti nelle schede professionisti,
            // almeno che non si cerca per profilo direttore unita operativa
            let valutatorePresenteInListaSchede = _.some(this.props.valutatori,
                val => val.isValutatore && (val.codiceFiscale === utente?.codiceFiscale) && (profilo !== enumUtente.profiliUtente.direttoreUnitaOperativa))
            if (this.props.valutatori?.length && valutatorePresenteInListaSchede) {
                return;
            }

            // non si aggiunge alla lista utente i professionisti già presenti nei team per medico formalizzatore
            // tranne per l'attivazione dei dir-uo se non è subentro
            if (AuthUtils.hasUtenteRuoloMcp()) {
                let isSubentro = this.props.professionistaDaSostituire != null;
                let professionistaPresenteInTeam = _.some(this.props.teams,
                    team => team?.professionista?.codiceFiscale === utente?.codiceFiscale &&
                        (team.professionista.profilo !== enumUtente.profiliUtente.direttoreUnitaOperativa || isSubentro));
                if (this.props.teams?.length && professionistaPresenteInTeam) {
                    return;
                }
            }

            let gruppo = _.find(utente?.gruppi, g => g["codice_gruppo"] === profilo);
            let attributo = gruppo ? _.find(gruppo?.attributi, a => a.codiceAttributo === "adi-lista-settings") : null;
            let valoreAttributo = attributo?.valore?.slice(1, -1);
            // recupero il corretto elemento della adi lista settings
            let elements = valoreAttributo.split(",");
            let elementsCodiceGruppo = _.filter(elements, elem => {
                let settings = elem?.split("@");
                return settings[0] === profilo
            });
            _.forEach(elementsCodiceGruppo, elemento => {
                let listaSettings = elemento?.split("@");
                let professionistaApms = _.cloneDeep(professionistaDefault);
                professionistaApms.codiceFiscale = utente?.codiceFiscale;
                professionistaApms.nome = utente?.nome;
                professionistaApms.cognome = utente?.cognome;
                professionistaApms.telefono = utente?.telefono;
                professionistaApms.cellulare = utente?.cellulare;
                professionistaApms.email = utente?.mail;
                professionistaApms.ruolo = listaSettings[1];
                professionistaApms.comune = listaSettings[4];
                professionistaApms.profilo = listaSettings[0];
                professionistaApms.disciplina = listaSettings[5] || null;
                professionistaApms.unitaOperativa = listaSettings[6] || null;
                this.professionistiApms.push(professionistaApms);
                this.inserisciProfessionistaInLista(professionistaApms, listaProfessionisti);
            })
        });

        // se non sono presenti già come valutatori aggiungo le figure intermedie alla select dei professionisti
        // coordinatore come valutatore
        let coordinatorePresenteAsVal = _.some(this.props.valutatori, val =>
            val.profilo === enumUtente.profiliUtente.coordinatore && val.isValutatore);
        if (AuthUtils.hasUtenteProfiloCoordinatore() && !coordinatorePresenteAsVal) {
            this.setProfessionistaIntermedioInSelectProfessionisti(listaProfessionisti, enumUtente.profiliUtente.coordinatore);
        }

        // referente assistente sociali come valutatore
        let refAssSocialePresenteAsVal = _.some(this.props.valutatori, val =>
            val.profilo === enumUtente.profiliUtente.referenteDirigenteAssistentiSociali && val.isValutatore);
        if (AuthUtils.hasUtenteProfiloReferenteAssistentiSociali() && !refAssSocialePresenteAsVal) {
            this.setProfessionistaIntermedioInSelectProfessionisti(listaProfessionisti, enumUtente.profiliUtente.referenteDirigenteAssistentiSociali);
        }

        // direttore unita operativa come valutatore
        let direttoreUOPresenteAsVal = _.some(this.props.valutatori, val =>
            val.profilo === enumUtente.profiliUtente.direttoreUnitaOperativa &&
            val.codiceFiscale === SessioneUtente.getInstance().idUtente &&
            val.isValutatore);
        if (AuthUtils.hasUtenteProfiloDirettoreUO() && !direttoreUOPresenteAsVal) {
            this.setProfessionistaIntermedioInSelectProfessionisti(listaProfessionisti, enumUtente.profiliUtente.direttoreUnitaOperativa, disciplina);
        }

        if (AuthUtils.hasUtenteRuoloMcp() && this.state.figuraProfessionale.profilo === enumUtente.profiliUtente.referenteDirigenteAssistentiSociali) {
            // mette in evidenza i professionisti con lo stesso comune dell'assistito
            let professionistiApmsEvidenziati = _.filter(this.professionistiApms, professionista => professionista.comune === this.props.paziente?.residenza?.comune);
            let valuesProfessionistiEvidenziati = _.map(professionistiApmsEvidenziati, p => p.codiceFiscale);
            let professionistiInEvidenza = _.filter(listaProfessionisti, professionista => valuesProfessionistiEvidenziati.includes(professionista.value));
            let professionisti = _.filter(listaProfessionisti, professionista => !valuesProfessionistiEvidenziati.includes(professionista.value));
            listaProfessionisti = [...professionistiInEvidenza, ...professionisti];
        }
        this.setState({listaProfessionisti: listaProfessionisti})
    }

    inserisciProfessionistaInLista(professionistaApms, listaProfessionisti) {
        let codiceFiscale = professionistaApms?.codiceFiscale;
        let presenteInLista = _.some(listaProfessionisti, p => p.value === codiceFiscale);
        if(!presenteInLista){
            // inserisci
            this.inserisciInListaProfessionisti(professionistaApms, codiceFiscale, listaProfessionisti);
        } else {
            // aggiorna (concatena comuni o unita operative)
            this.aggiornaInListaProfessionisti(listaProfessionisti, codiceFiscale, professionistaApms);
        }
    }

    aggiornaInListaProfessionisti = (listaProfessionisti, codiceFiscale, professionistaApms) => {
        let indexElemento = _.findIndex(listaProfessionisti, p => p.value === codiceFiscale);
        if (AuthUtils.hasUtenteRuoloMcp() || (AuthUtils.hasUtenteCentraleAdi() && this.hasUtenteCentraleAdiDelega(this.props.richiesta))) {
            if (professionistaApms.profilo === enumUtente.profiliUtente.direttoreUnitaOperativa &&
                !Utils.isStringEmptyOrNullOrUndefined(professionistaApms.unitaOperativa)) {
                let unitaOperativaLabel = professionistaApms.unitaOperativa || null;
                if (unitaOperativaLabel) {
                    listaProfessionisti[indexElemento].label = listaProfessionisti[indexElemento].label.concat(", ", unitaOperativaLabel)
                }
            } else if (professionistaApms.profilo === enumUtente.profiliUtente.referenteDirigenteAssistentiSociali &&
                !Utils.isStringEmptyOrNullOrUndefined(professionistaApms.comune)) {
                let comune = professionistaApms.comune || null;
                if (comune) {
                    // cerca nella lista dei comuni e aggiunge la descrizione del comune trovato al nome e cognome del professionista
                    let labelComune = _.find(ToponomasticaUtils.getComuni(), c => c.codice === comune)?.descrizione ?? "Comune non trovato";
                    listaProfessionisti[indexElemento].label = listaProfessionisti[indexElemento].label.concat(", ", labelComune)
                }
            }
        }
    }

    inserisciInListaProfessionisti = (professionistaApms, codiceFiscale, listaProfessionisti) => {
        // set label
        let label = professionistaApms?.nome + " " + professionistaApms?.cognome;
        // set unita operativa in label select
        if (AuthUtils.hasUtenteRuoloMcp() || (AuthUtils.hasUtenteCentraleAdi() && this.hasUtenteCentraleAdiDelega(this.props.richiesta))) {
            if (professionistaApms.profilo === enumUtente.profiliUtente.direttoreUnitaOperativa &&
                !Utils.isStringEmptyOrNullOrUndefined(professionistaApms.unitaOperativa)) {
                let unitaOperativaLabel = professionistaApms.unitaOperativa || null;
                if (unitaOperativaLabel) {
                    label = professionistaApms?.nome + " " + professionistaApms?.cognome + " - " + unitaOperativaLabel;
                }
            } else if (professionistaApms.profilo === enumUtente.profiliUtente.referenteDirigenteAssistentiSociali &&
                !Utils.isStringEmptyOrNullOrUndefined(professionistaApms.comune)) {
                let comune = professionistaApms.comune || null;
                if (comune) {
                    // cerca nella lista dei comuni e aggiunge la descrizione del comune trovato al nome e cognome del professionista
                    let labelComune = _.find(ToponomasticaUtils.getComuni(), c => c.codice === comune)?.descrizione ?? "Comune non trovato";
                    label = professionistaApms?.nome + " " + professionistaApms?.cognome + " - " + labelComune;
                }
            }
        }
        let professionistaSelect = {value: codiceFiscale, label: label};
        listaProfessionisti.push(professionistaSelect);
    }

    setProfessionistaIntermedioInSelectProfessionisti(listaProfessionisti, profilo, disciplina = null) {
        let professionistaIntermedio = _.find(this.props.valutatori,
            val => val.profilo === profilo && val.codiceFiscale === SessioneUtente.getInstance().idUtente) ?? null;
        let consideraDisciplina = profilo === enumUtente.profiliUtente.direttoreUnitaOperativa ? professionistaIntermedio.disciplina === disciplina : true;
        if (professionistaIntermedio && consideraDisciplina) {
            delete professionistaIntermedio.idProfessionista;
            this.professionistiApms.push(professionistaIntermedio);
            listaProfessionisti.push({
                value: professionistaIntermedio?.codiceFiscale,
                label: professionistaIntermedio?.nome + " " + professionistaIntermedio?.cognome
            })
        }
    }

    renderTitle = () => {
        return <>
            <div className={"row py-2"}>
                <div className={"col pb-3"}>
                    <span className={styles.title}>Attiva professionista</span>
                </div>
            </div>
        </>
    }

    renderForm = () => {
        let caratteriNoteMancanti = Utils.isStringEmptyOrNullOrUndefined(this.state.figuraProfessionale.note)
            ? (500).toString()
            : (500 - this.state.figuraProfessionale.note.length).toString();

        let caratteriMancantiJSX = <span className={styles.bottomPlaceholder}>
            {parseInt(caratteriNoteMancanti) === 1
                ? caratteriNoteMancanti.concat(" carattere disponibile")
                : caratteriNoteMancanti.concat(" caratteri disponibili")}
        </span>;

        let ruoliSelect = _.cloneDeep(this.state.ruoliSelect);

        let isDisciplinaDisabilitata = this.state.figuraProfessionale?.ruolo == null ||
            ![enumUtente.ruoli.specialista].includes(this.state.figuraProfessionale?.ruolo);

        let col = (elementJSX) => {
            return <div className={"col"}>
                {elementJSX}
            </div>
        }
        return (
            <div className={"container-fluid pb-5"}>
                    <div className={"row align-items-top"}>

                    {/*profilo*/}
                    {col(<FieldsGroup fieldLabel={"Profilo"} fieldRequired={true}>
                        <Select
                            id={"selectProfiloFiguraProfessionale"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            styles={inputSelectStyles}
                            options={this.state.profiliSelect}
                            value={_.find(this.state.profiliSelect, profilo => profilo.value === this.state.figuraProfessionale.profilo) ?? null}
                            onChange={(elem) => {
                                this.handleAggiornaForm("profilo", elem.value)
                            }}
                            isSearchable={false}
                            placeholder={"Seleziona profilo"}
                            noOptionsMessage={() => "Errore: Profilo non esistente"}
                            isDisabled={this.state.disabilitaFiltri?.profilo || AuthUtils.hasUtenteProfiloDirettoreUO()}
                        />
                        <div className="row">
                            {!(this.state.disabilitaFiltri?.profilo || AuthUtils.hasUtenteProfiloDirettoreUO()) && !_.find(this.state.profiliSelect, profilo => profilo.value === this.state.figuraProfessionale.profilo) &&
                            <div className="col-6"><span
                                className={stylesModale.requiredField}>Campo obbligatorio</span></div>}
                        </div>
                    </FieldsGroup>)}

                    {/*ruolo*/}
                        {col(<FieldsGroup fieldLabel={"Ruolo"}
                                 fieldRequired={this.state.figuraProfessionale.profilo !== enumUtente.profiliUtente.direttoreUnitaOperativa}>
                        <Select
                            id={"selectRuoloFiguraProfessionale"}
                            className={"ruoloFiguraProfessionale"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            styles={inputSelectStyles}
                            options={ruoliSelect}
                            value={_.find(ruoliSelect, ruolo => ruolo.value === this.state.figuraProfessionale.ruolo) ?? null}
                            onChange={(elem) => this.handleAggiornaForm("ruolo", elem.value)}
                            isSearchable={false}
                            placeholder={"Seleziona ruolo"}
                            noOptionsMessage={() => "Errore: Ruolo non esistente"}
                            isDisabled={this.state.disabilitaFiltri?.ruolo ||
                            this.state.figuraProfessionale.profilo !== enumUtente.profiliUtente.professionistaValutatore}
                        />
                        <div className="row">
                            {!(this.state.disabilitaFiltri?.ruolo ||
                                this.state.figuraProfessionale.profilo !== enumUtente.profiliUtente.professionistaValutatore) && !_.find(ruoliSelect, ruolo => ruolo.value === this.state.figuraProfessionale.ruolo) &&
                            <div className="col-6"><span
                                className={stylesModale.requiredField}>Campo obbligatorio</span></div>}
                        </div>
                    </FieldsGroup>)}
                    {/*disciplina*/}
                    {!AuthUtils.hasUtenteProfiloCoordinatore() ?
                        col(<FieldsGroup fieldLabel={"Disciplina"}
                                     fieldRequired={[enumUtente.ruoli.specialista].includes(this.state.figuraProfessionale?.ruolo)}
                                     hide={AuthUtils.hasUtenteProfiloCoordinatore()}>
                            <Select
                                id={"selectDisciplinaSpecialisti"}
                                className={"disciplinaFiguraProfessionale"}
                                classNamePrefix={"inputSelect"}
                                components={DropdownIndicator}
                                styles={inputSelectStyles}
                                options={this.state.disciplinaSelect}
                                value={_.find(disciplinaSelect, disciplina => disciplina.value === this.state.figuraProfessionale.disciplina) ?? null}
                                onChange={(elem) => this.handleAggiornaForm("disciplina", elem.value)}
                                isSearchable={false}
                                placeholder={"Seleziona disciplina"}
                                noOptionsMessage={() => "Non risultano altre discipline nei professionisti profilati nell’Unità Operativa selezionata."}
                                isDisabled={this.state.disabilitaFiltri?.disciplina || (isDisciplinaDisabilitata)}
                            />
                            <div className="row">
                                {!(this.state.disabilitaFiltri?.disciplina || (isDisciplinaDisabilitata)) && !_.find(disciplinaSelect, disciplina => disciplina.value === this.state.figuraProfessionale.disciplina) &&
                                <div className="col-6"><span
                                    className={stylesModale.requiredField}>Campo obbligatorio</span></div>}
                            </div>
                        </FieldsGroup>) :
                        null}

                    {/*unita operativa*/}
                    {this.state.figuraProfessionale.profilo === enumsUtente.profiliUtente.direttoreUnitaOperativa ?
                        col(<FieldsGroup fieldLabel={"Unità operativa"}
                                     fieldRequired={true}>
                            <Select
                                id={"selectUnitaOperativa"}
                                classNamePrefix={"inputSelect"}
                                components={DropdownIndicator}
                                styles={inputSelectStyles}
                                options={this.state.unitaOperativaSelect}
                                value={_.find(this.state.unitaOperativaSelect, uo => uo.value === this.state.figuraProfessionale.unitaOperativa) ?? null}
                                onChange={(elem) => this.handleAggiornaForm("unitaOperativa", elem.value)}
                                isSearchable={false}
                                placeholder={"Seleziona unità operativa"}
                                noOptionsMessage={() => "Errore: Unità operativa non esistente"}
                                isDisabled={this.state.disabilitaFiltri?.unitaOperativa}
                            />
                            <div className="row">
                                {!(this.state.disabilitaFiltri?.unitaOperativa) && !_.find(this.state.unitaOperativaSelect, uo => uo.value === this.state.figuraProfessionale.unitaOperativa) &&
                                <div className="col-auto"><span
                                    className={stylesModale.requiredField}>Campo obbligatorio</span></div>}
                            </div>
                        </FieldsGroup>) :
                        null}

                    {/*professionista*/}
                        {col(<FieldsGroup fieldLabel={"Professionista"} fieldRequired={true}>
                        <Select
                            id={"selectRuoloFiguraProfessionale"}
                            className={"ruoloFiguraProfessionale"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            styles={inputSelectStyles}
                            options={this.state.listaProfessionisti}
                            value={_.find(this.state.listaProfessionisti, professionista => professionista.value === this.state.figuraProfessionale.codiceFiscale) ?? null}
                            onChange={(elem) => {
                                this.handleAggiornaForm("codiceFiscale", elem.value)
                            }}
                            isSearchable={false}
                            isDisabled={this.isProfessionistaDisabilitato()}
                            placeholder={"Seleziona professionista"}
                            noOptionsMessage={() => "Nessun risultato trovato"}
                        />
                        <div className="row">
                            {!this.isProfessionistaDisabilitato() && !_.find(this.state.listaProfessionisti, professionista => professionista.value === this.state.figuraProfessionale.codiceFiscale) &&
                            <div className="col-auto"><span
                                className={stylesModale.requiredField}>Campo obbligatorio</span></div>}
                        </div>
                    </FieldsGroup>)}
                    </div>
                {/*figure richieste*/}
                {this.renderFigureRichieste()}
                {/*note*/}
                <FieldsRow colNumber={2} spaceBetweenRows={false} containerClass={"mt-4"}>
                    <FieldsGroup>
                        <Input
                            value={this.state.figuraProfessionale.note ?? ""}
                            placeholder={"Motivo dell'attivazione"}
                            field={"note"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"inputNote"}
                            type={"textarea"}
                            rows={"4"}
                            parentClass={"mb-0"}
                            maxlength={"500"}
                            label="Motivazione dell'attivazione"
                            required
                            infoText="Campo obbligatorio"
                            caratteriMax={500}
                        />
                    </FieldsGroup>
                </FieldsRow>
            </div>
        );
    }

    renderFigureRichieste = () => {
        if (this.state.figuraProfessionale.profilo !== enumUtente.profiliUtente.direttoreUnitaOperativa || this.state.figuraProfessionale.codiceFiscale == null) {
            return null
        }

        if(!this.state.disciplinePerUo?.length){
            return   <div className={"row align-items-start py-4"}>
                <div className={"col ".concat(stylesModale.requiredField)}>
                    Non sono presenti figure profilate per l'unità operativa del professionista selezionato
                </div>
            </div>
        }

        let figureRichieste = _.cloneDeep(this.state.figureRichieste);
        let figureRichiesteJSX = _.map(figureRichieste, (figuraRichiesta, index) => {
            let isUltima = figureRichieste.length === (index + 1);
            let disabled = this.state.ruoliRichiestiSelect?.length === 0;
            let aggiungiFiguraRichiestaStile = !disabled ? styles.aggiungiFiguraRichiesta : styles.disabledAggiungiFiguraRichiesta;
            let aggiungiJSX = isUltima && <div className={"col-auto d-flex align-items-center"}>
                <span
                    id={"aggiungiFiguraRichiesta"}
                    onClick={() => {
                        if (!disabled) {
                            this.aggiungiFiguraRichiesta();
                        }
                    }}
                    style={{cursor: "pointer"}}
                    className={aggiungiFiguraRichiestaStile}
                >
                        <BsIcon.PlusCircle size={28} className={aggiungiFiguraRichiestaStile}/> Aggiungi professionista
                </span>
            </div>;
            let teamConFigure = _.find(this.props.teams, team => team?.professionista?.codiceFiscale === this.state.figuraProfessionale?.codiceFiscale);
            let isDisabled = false;
            if (teamConFigure) {
                isDisabled = _.some(teamConFigure?.figureRichieste, figura => figura.codiceFiscaleAggiunti?.length && figura.ruolo === figuraRichiesta.ruolo && figura.disciplina === figuraRichiesta.disciplina)
            }
            let messaggio = !isDisabled ? "Elimina la figura richiesta" : "E' già stato inserito un professionista per questa disciplina, non è possibile eliminare la figura richiesta";
            let stileElimina = !isDisabled ? styles.eliminaFiguraRichiesta : styles.eliminaFiguraRichiestaDisabilitato;
            let eliminaJSX = figureRichieste.length > 1 &&
                <div className={"col-auto d-flex align-items-center"}>
                    <PopoverReact
                        body={messaggio}
                        text={<Fragment>
                        <span
                            id={"aggiungiFiguraRichiesta" + index}
                            className={stileElimina}
                            onClick={() => {
                                this.eliminaFiguraRichiesta(index);
                            }}
                        >
                        <BsIcon.Trash3 size={28} className={stileElimina}/>
                        </span>
                        </Fragment>}
                        openOnMouseOver
                        position={'right'}
                        className={'popover-info '.concat(styles.popoverElimina)}
                        arrowStyle={{}}
                        id={`popoverEliminaFiguraRichiesta` + index}
                    /></div>;

            let containerClass = "pb-3";

            return <>
                <FieldsRow colNumber={4} containerClass={containerClass} spaceBetweenRows={false}>
                    <FieldsGroup>
                        <Select
                            id={"selectRuoloRichiesto"}
                            className={"ruoloFiguraProfessionale"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            styles={inputSelectStyles}
                            options={this.state.ruoliRichiestiSelect}
                            value={_.find(ruoliValutatoreSpecialistaSelect, ruolo => ruolo.value === figuraRichiesta.ruolo) ?? null}
                            onChange={(elem) => this.handleAggiornaFigureRichieste("ruolo", elem.value, index)}
                            isSearchable={false}
                            placeholder={"Seleziona ruolo"}
                            noOptionsMessage={() => "Errore: Ruolo non esistente"}
                            isDisabled={figuraRichiesta?.id}
                        />
                    </FieldsGroup>
                    <FieldsGroup>
                        <Select
                            id={"selectDisciplinaRichiesta"}
                            className={"disciplinaFiguraProfessionale"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            styles={inputSelectStyles}
                            options={this.state.disciplineRichiesteSelect}
                            value={_.find(disciplinaSelect, disciplina => disciplina.value === figuraRichiesta.disciplina) ?? null}
                            onChange={(elem) => this.handleAggiornaFigureRichieste("disciplina", elem.value, index)}
                            isSearchable={false}
                            placeholder={"Seleziona disciplina"}
                            noOptionsMessage={() => "Non risultano altre discipline nei professionisti profilati nell’Unità Operativa selezionata."}
                            isDisabled={figuraRichiesta?.ruolo == null || ![enumUtente.ruoli.specialista].includes(figuraRichiesta?.ruolo) || figuraRichiesta?.id}
                        />
                    </FieldsGroup>
                    <FieldsGroup>
                        <div className={"row py-1"}>
                            {!this.props.professionistaDaSostituire && eliminaJSX}
                        </div>
                    </FieldsGroup>
                </FieldsRow>
                <FieldsRow>
                    {!this.props.professionistaDaSostituire && aggiungiJSX}
                </FieldsRow>
            </>;
        });

        return <>
            {figureRichieste?.length ?
                <FieldsRow colNumber={4} spaceBetweenRows={false} containerClass={"mt-4"}>
                    <span className={'mr-1 required'}><strong>Ruolo professionista richiesto</strong></span>
                    <span className={'mr-1 required'}><strong>Disciplina professionista richiesto</strong></span>
                </FieldsRow> : <Fragment/>
            }
            {figureRichiesteJSX}
        </>;
    }

    isProfessionistaDisabilitato = () => {
        return (isRuoloMedicoSpecialista(this.state.figuraProfessionale.ruolo) && Utils.isStringEmptyOrNullOrUndefined(this.state.figuraProfessionale.disciplina))
            || (this.state.figuraProfessionale.profilo !== enumUtente.profiliUtente.direttoreUnitaOperativa && Utils.isStringEmptyOrNullOrUndefined(this.state.figuraProfessionale.ruolo) || this.state.figuraProfessionale.profilo == null);
    }

    renderButtons = () => {
        return (
            <ButtonsBoxStick
                parentClass="d-flex justify-content-between"
                btnList={[
                    {
                        id: "indietro",
                        text: "Indietro",
                        className: "btnWhiteOutlineBorderGreen",
                        onclickFunction: () => {this.props.onClickIndietro()},
                        isvisible: true
                    },
                    {
                        id: "attivaProfessionistaBtn",
                        text: this.props.professionistaDaSostituire ? "Sostituisci professionista" : "Attiva professionista",
                        className: "btnGreenOutline",
                        onclickFunction: () => {this.conferma()},
                        disabled: this.isButtonDisabled(),
                        isvisible: true
                    }
                ]}
            />
        );
    }

    isButtonDisabled = () => {
        let figuraProfessionale = _.cloneDeep(this.state.figuraProfessionale);
        let listaProfessionisti = _.cloneDeep(this.state.listaProfessionisti);
        let figureRichieste = _.cloneDeep(this.state.figureRichieste);
        let isProfiloValued = !Utils.isObjectNull(figuraProfessionale.profilo);
        let isRuoloValued = figuraProfessionale.profilo === enumUtente.profiliUtente.direttoreUnitaOperativa ||
            !Utils.isObjectNull(figuraProfessionale.ruolo);
        let isDisciplinaValued = isRuoloMedicoSpecialista(figuraProfessionale.ruolo) ?
            !Utils.isObjectNull(this.state.figuraProfessionale.disciplina) :
            true;
        let isUoValued = figuraProfessionale.profilo === enumsUtente.profiliUtente.direttoreUnitaOperativa ?
            !Utils.isObjectNull(this.state.figuraProfessionale.unitaOperativa) :
            true;
        let isProfessionistaValued = listaProfessionisti?.length > 0 ? !Utils.isStringEmptyOrNullOrUndefined(this.state.figuraProfessionale.codiceFiscale) : false;
        let figuraRichiestaIncompleta = figureRichieste?.some(figuraRichiesta => {
            if (figuraRichiesta.ruolo == null) {
                return true;
            } else {
                if (figuraRichiesta.ruolo === enumUtente.ruoli.specialista) {
                    return figuraRichiesta.disciplina == null;
                } else {
                    return false;
                }
            }
        }) ?? false;
        let isFigureRichiesteValued = figuraProfessionale?.profilo !== enumUtente.profiliUtente.direttoreUnitaOperativa || figuraProfessionale?.profilo === enumUtente.profiliUtente.direttoreUnitaOperativa
            && !figuraRichiestaIncompleta;
        return !isProfiloValued || !isRuoloValued || !isDisciplinaValued || !isUoValued || !isProfessionistaValued || !isFigureRichiesteValued || !this.state.figuraProfessionale.note;
    }

    conferma = () => {
        let figuraProfessionale = _.cloneDeep(this.state.figuraProfessionale);
        let figureRichieste = _.cloneDeep(this.state.figureRichieste);
        let professionista = _.find(this.professionistiApms, professionistaApms => professionistaApms.codiceFiscale === figuraProfessionale.codiceFiscale);
        figuraProfessionale.profilo = professionista.profilo;
        figuraProfessionale.nome = professionista.nome;
        figuraProfessionale.cognome = professionista.cognome;
        figuraProfessionale.email = professionista.email;
        figuraProfessionale.disciplina = professionista?.disciplina || null;
        figuraProfessionale.ruolo = professionista?.ruolo || null;
        figuraProfessionale.unitaOperativa = professionista?.unitaOperativa || null;
        let dto = {
            id: uuid(),
            profilo: null,
            professionista: figuraProfessionale,
            figureRichieste: figureRichieste,
            timestampConferma: null,
            note: null,
            fase: 'R'
        }
        // se è presente l'oggetto professionista da sostituire
        // allora chiamare subentra professionista
        // altrimenti attiva porfessionista
        let servizioCall;
        if (this.props.professionistaDaSostituire) {
            servizioCall = RichiestaADIService.subentraProfessionista(this.props.uuidRichiesta, this.props.professionistaDaSostituire?.codiceFiscale, dto);
        } else {
            servizioCall = RichiestaADIService.aggiungiProfessionista(this.props.uuidRichiesta, dto);
        }

        servizioCall.subscribe(res => {
            if (res.msg !== "ERRORE") {
                this.props.onClickConferma()
            }
        });
    }


    render() {
        return (
            <>
                <div className={styles.attivaProfessionaleContainer.concat(" row px-5 pt-3")}>
                    <div className={"col-12"}>
                        {this.renderTitle()}
                        {this.renderForm()}
                    </div>
                </div>
                {this.renderButtons()}
            </>
        );
    }

}

AttivazioneFiguraProfessionale.propTypes = propTypes;
