import React, {Component} from "react";
import {Button, Input} from "web-client-archetype";
import Utils from "../../../utils/Utils";
import PropTypes from "prop-types";
import genericStyle from "./GestionePassword.module.css"
import colors from "../../../hook/colors";

const propTypes = {
    location: PropTypes.object,
    checkPassword: PropTypes.func,
    recuperaPassword: PropTypes.func
};

export const CONDIZIONE_PASSWORD_LENGTH = <span id={'password_lenght'} style={{ color: "#D83D3D", fontStyle: 'italic' }} className={genericStyle.infoText}>{"minimo 9 caratteri"}</span>
export const CONDIZIONE_PASSWORD_CARATTERE_SPECIALE = <span id={'password_speciale'}  style={{ color: "#D83D3D", fontStyle: 'italic' }} className={genericStyle.infoText}><br/> {"almeno un carattere speciale ! \" ? $ ? % ^ & * ( ) _ - + = { [ } ] : ; @ ' ~ # | \\ < , > . ? / "}<br/></span>
export const CONDIZIONE_PASSWORD_MAIUSCOLA = <span id={'password_maiuscola'} style={{ color: "#D83D3D", fontStyle: 'italic' }} className={genericStyle.infoText}><br/> {"almeno una maiuscola "}</span>
export const CONDIZIONE_NUOVA_PASSWORD_DIVERSA_DA_VECCHIA_PASSWORD = <span id={'nuova_password_diversa_da_vecchia_password'} style={{color: "#D83D3D", fontStyle: 'italic'}} className={genericStyle.infoText}>{"Vecchia Password diversa da Nuova Password"}</span>


export default class LockPassword extends Component {

    state = {
        password: null,
        confPassword: null,
        pulsanteConfermaAbilitato: false,
    }

   componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.password!==this.state.password)
        {
            if(Utils.isValidStringMinLength(this.state.password,9))
            {
                document.getElementById('password_lenght').style.color='black';
            }
            if (Utils.isValidAlphanumericMaiuscola(this.state.password))
            {
                document.getElementById('password_maiuscola').style.color='black';
            }
            if (Utils.isValidCaratteriSpeciali(this.state.password))
            {
                document.getElementById('password_speciale').style.color='black';
            }
        }
   }

    constructor(props) {
        super(props);
    }

    handleAggiornaState = (field, event) => {
        this.setState({[field]: event.target.value}, this.aggiornaBottoni);
    }

    aggiornaBottoni = () => {
        let pulsanteConfermaAbilitato = false;
        if (Utils.isPasswordValid(this.state.password) &&
            Utils.arePasswordMatch(this.state.password, this.state.confPassword)) {
            pulsanteConfermaAbilitato = true;
        }
        this.setState({pulsanteConfermaAbilitato: pulsanteConfermaAbilitato})
    }

    render() {
        return (
            <div className={"row pt-2"}>
                <div className={"col"}>
                    {this.renderInputPasswords()}
                    {this.renderTestoInformativo()}
                    {this.renderBottoni()}
                </div>
            </div>
        )
    }

    renderBottoni = () => {
        return <div className="row align-items-center">
            <div className="col-lg-12 col-xl-6">
                <Button
                    id={"conferma"}
                    text="Conferma"
                    className={"btn btn-adi btnGreenOutline  w-100"}
                    disabled={!this.state.pulsanteConfermaAbilitato}
                    onClick={() => this.props.checkPassword(this.state.password, this.state.confPassword)}
                />
            </div>
            <div className="col-lg-12 col-xl-6">
                <Button
                    id={"recPassword"}
                    text="Recupera Password"
                    className={"btn btn-adi btnWhiteOutlineBorderGreen w-100"}
                    onClick={() => this.props.recuperaPassword()}
                />
            </div>
        </div>;
    }

    renderTestoInformativo = () => {
        return <div className={"row pt-5 pb-2"}>
            <div className={"col"}>
                {CONDIZIONE_PASSWORD_LENGTH}
                {CONDIZIONE_PASSWORD_CARATTERE_SPECIALE}
                {CONDIZIONE_PASSWORD_MAIUSCOLA}
            </div>
        </div>;
    }

    renderInputPasswords = () => {
        return <div className="row align-items-center">
            <div className="col-lg-12 col-xl-6">
                <Input
                    parentClass="mb-3 p-0"
                    placeholder="Inserisci Password"
                    field="password"
                    onChange={this.handleAggiornaState}
                    id="password"
                    value={this.state.password}
                    type="password"
                    onValidate={(value) => value && !Utils.isPasswordValid(value)}
                    required
                    label={"Inserisci Password"}
                />
            </div>
            <div className="col-lg-12 col-xl-6">
                <Input
                    parentClass="mb-3 p-0"
                    placeholder="Conferma Password"
                    field="confPassword"
                    onChange={this.handleAggiornaState}
                    id="confPassword"
                    value={this.state.confPassword}
                    type="password"
                    onValidate={(value) => value && !Utils.arePasswordMatch(this.state.password, value) && "*Le password non coincidono"}
                    required
                    label={"Conferma Password"}
                />
            </div>
        </div>;
    }
}

LockPassword.propTypes = propTypes;
