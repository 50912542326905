import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {Context} from "../../../utils/Context";
import styles from "./NavBar.module.css";
import {Icon} from "web-client-archetype";
import {Collapse} from "reactstrap";
import PopoverReact from "../../popover/Popover";


const menuDx = ["home"];
const menuCentro = ["ricercaAssistitoBtn", "storicoPaziente", "panelloConfigurazionale", "firme", "gestioneDelega"];
const menuSx = ["notifiche", "faq", "menuUtente", "ricercaCampo", "logout"];

/**
 * @function NavBar, renderizza la sezione contenente Home (Icona) link Ricerca Paziente, Storico Richieste, e la sezione MenuNotification
 * @param navbarOptions E' un Array di oggetti, ogni oggetto contiene le caratteristiche dei link inseriti nella NavBar
 * con la seguente struttura  {className, iconSrc, id, infoPopupOver, label, link, nonLette, onClickFunction, type, url}
 */

function NavBar ({navbarOptions}) {
    const location = useLocation();
    const [linkActive, setLinkActive] = useState(navbarOptions.find(m => m.url && m.url[0] === location.pathname));
    const [toggleHamburger, setToggleHamburger] = useState(false);

    useEffect(() => {
        if (location.pathname) {
            setLinkActive(navbarOptions.find(m => m.url && location.pathname.includes(m.url[0])))
        }
    }, [location.pathname]); 


    /**
     * @function getLink recupera le informazioni dell'oggetto navbarOptions e costruisce i link delle varie sezioni della NavBar
     * @param element singolo oggetto contenuto nell'Array navbarOptions da cui vengono recuperate le singole proprietà
     * la struttura è la seguente {className, iconSrc, id, infoPopupOver, label, link, nonLette, onClickFunction, type, url}
     */
    const getLink = (element) => {
        return <Context.Consumer>
            {({clickHome, setClickHome}) => (
                <Link to={{...element?.propAggiuntive ?? {}, pathname: element.url[0]}} onClick={() => {
                    if (element?.url[0] === "/home") setClickHome(true);
                }}>
                    {element.infoPopupOver ? <PopoverReact
                        text={<span className={[linkActive?.id === element?.id ? styles.activePage : "", styles.stylePagineLi, styles.liPagina, styles.labelCustom].join(' ')}>{element.label}</span>}
                        body={element.infoPopupOver}
                        openOnMouseOver={true}
                        position={'bottom'}
                        align={"center"}
                        arrowStyle={{display: "none"}}
                        id={element.id}
                        className={'popover-info '.concat(styles.popoverNavBar)}
                        idContainer={"popoverInfoNavBar"}
                    /> : <span className={[linkActive?.id === element?.id ? styles.activePage : "", styles.stylePagineLi, styles.liPagina, styles.labelCustom].join(' ')}>{element.label}</span>}
                </Link>
            )}
        </Context.Consumer>
    }

    const showMenuDx = navbarOptions.filter(m => menuDx.includes(m.id));
    const showMenuCentro = navbarOptions.filter(m => menuCentro.includes(m.id));
    const showMenuSx = navbarOptions.filter(m => menuSx.includes(m.id));

    /**
     * @function renderMenuDX recupera le specifiche informazioni presenti nell'elemento dell'oggetto navbarOptions, in questo caso link Home
     */
    const renderMenuDX = () => {
        return showMenuDx.map((menu) => getLink(menu))
    }

    /**
     * @function renderCentro, renderizza la sezione centrale del NavBar ricostruendo le seguenti sezioni ["ricercaAssistitoBtn", "storicoPaziente", "panelloConfigurazionale", "firme"]
     * in base all'utente loggato
     */
    const renderCentro = () => {
        return showMenuCentro.map(menu => {
            if (menu.id === "ricercaAssistitoBtn" || menu.id === "creaNuovoUtente") {
                return <Link to={{pathname: menu?.url[0]}}>
                    {menu.infoPopupOver ? <PopoverReact
                        text={<span className={[linkActive?.id === menu?.id ? styles.activePage : "", styles.stylePagineLi, styles.liPagina, styles.labelCustom].join(' ')}>{menu.label}</span>}
                        body={menu.infoPopupOver}
                        openOnMouseOver={true}
                        position={'bottom'}
                        align={"center"}
                        arrowStyle={{display: "none"}}
                        id={menu.id}
                        className={'popover-info '.concat(styles.popoverNavBar)}
                        idContainer={"popoverInfoNavBar"}
                    /> : <span className={[linkActive?.id === menu?.id ? styles.activePage : "", styles.stylePagineLi, styles.liPagina, styles.labelCustom].join(' ')}>{menu.label}</span>}
                </Link>
            }
            return getLink(menu)
        })
    }

    /**
     * @function renderMenuSX renderizza la struttura della sezione SX del NavBar(MenuNotification)
     */
    const renderMenuSX = () => {

        return showMenuSx.map(menu => {
            if (menu.id === "faq") {
                return getLink(menu)
            }

            if(menu.infoPopupOver) return <PopoverReact
                text={menu.id === "menuUtente" || menu.id === "notifiche" ? menu.label : <span className={[linkActive?.id === menu?.id ? styles.activePage : "", styles.stylePagineLi, styles.liPagina, styles.labelCustom].join(' ')} onClick={menu.onClickFunction}>{menu.label}</span>}
                body={menu.infoPopupOver}
                openOnMouseOver={true}
                position={'bottom'}
                align={"center"}
                arrowStyle={{display: "none"}}
                id={menu.id}
                className={'popover-info '.concat(styles.popoverNavBar)}
                idContainer={"popoverInfoNavBar"}
            />

            return <span className={[linkActive?.id === menu?.id ? styles.activePage : "", styles.stylePagineLi, styles.liPagina, styles.labelCustom].join(' ')} onClick={menu.onClickFunction}>{menu.label}</span>

        })
    }


    return (
        <div>
            <div className={styles.navContainer.concat(" d-flex align-items-center")}>
                    <div className="col-xl-3 col-lg-1 col-md-1 d-none d-xl-block">
                        {renderMenuDX()}
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-5 d-none d-xl-block">
                        <div className="d-flex justify-content-xl-center justify-content-lg-end">
                            {renderCentro()}
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-5 col-md-6 d-none d-xl-block mt-1">
                        <div className='float-right d-flex align-items-center'>
                            {renderMenuSX()}
                        </div>
                    </div>
                    <div className="col-12 d-xl-none" >
                        <span className={styles.icohamburgerNav.concat(' navbar-toggler mt-2 p-0')} type="button"
                            onClick={() => setToggleHamburger(!toggleHamburger)}>
                            {!toggleHamburger && <Icon id={'it-burger'} className={styles.icohamburgerNav}  />}
                            {toggleHamburger  && <Icon id={'it-close-big'} className={styles.icohamburgerNav}  />}
                        </span>
                    </div>
            </div>
            <div className="d-xl-none" >
                <Collapse isOpen={toggleHamburger}>
                    <nav className={styles.hamburgerNav.concat(' navbar navbar-light white')}>
                        <div className={styles.navbarCollapse.concat(' collapse d-block')}
                            id="navbarSupportedContent1"
                        >
                            <ul className="navbar-nav mr-auto mt-3 mb-4">
                                <li className={"nav-item active".concat( linkActive?.id === "home" && " ml-3 mb-2")} >{renderMenuDX()}</li>
                                <li className="nav-item active">{renderCentro()}</li>
                                <li className="nav-item active d-flex align-items-center">{renderMenuSX()}</li>
                            </ul>
                        </div>
                    </nav>
                </Collapse>
            </div>
        </div>



    );
}

NavBar.propTypes = {};

NavBar.defaultProps = {};
NavBar.contextType = Context;

export default NavBar;
