import React from "react";
import PropTypes from "prop-types";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {
    FieldsRow,
    RoleBasedAutocomplete,
    RoleBasedInput,
    RoleBasedPhoneInput,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AulssUtils from "../../../utils/AulssUtils";
import Utils from "../../../utils/Utils";
import ospedaleData from "../../../utils/dataset/ospedaleData/ospedale.json";

const fieldContatti = 'contatti';
const fieldIndirizzi = 'indirizzi';

export default function SedeLavorativa(props) {
    const distretti = props.datiMedico?.aulss ? AulssUtils.getDistrettiByAulssId(props.datiMedico.aulss) : [];
    const cellulare = props.datiMedico.contatti?.find(e => e.tipo === 'CELLULARE_SEDE_LAVORATIVA')?.valore;
    const telefono = props.datiMedico.contatti?.find(e => e.tipo === 'TELEFONO_SEDE_LAVORATIVA')?.valore;
    const provincia = props.datiMedico.indirizzi?.find(e => e.tipo === 'SEDE_LAVORATIVA')?.provincia;
    const comune = props.datiMedico.indirizzi?.find(e => e.tipo === 'SEDE_LAVORATIVA')?.comune;

    const ospedaliSuggestions = ospedaleData.map(o => ({
        codice: `${o.aulss}_${o.subcodice}`,
        descrizione: o.denominazione
    }));
    const unitaOperativeSuggestions = AulssUtils.getUnitaOperativeByIdAulss(props.datiMedico.aulss);

    return <AccordionSezioneRichiesta
        title={"Sede Lavorativa"}
        openAccordion={props.openAccordion}
        onClickAccordion={props.onClickAccordion}
        pageState={props.pageState}
        required={true}>
        <FieldsRow>
            <RoleBasedInput
                fieldId={'INDIR.SEDELAVORATIVA.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                id="inputIndirizzo"
                field="indirizzo"
                fieldLabel={"Indirizzo"}
                placeholder="Indirizzo"
                onChange={(field, elem) => props.onChangeIndirizzi(fieldIndirizzi, 'SEDE_LAVORATIVA', field, elem.target.value)}
                value={props.datiMedico.indirizzi?.find(e => e.tipo === 'SEDE_LAVORATIVA')?.indirizzo}
                forceReadOnly={props.datiMedico.indirizzi.some(e => e.tipo === 'SEDE_LAVORATIVA' && !Utils.isStringEmptyOrNullOrUndefined(e.indirizzo)) &&
                    (props.editableFields.indirizzi != null && props.editableFields.indirizzi.length > 0 &&
                        props.editableFields.indirizzi.some(e => e.tipo === 'SEDE_LAVORATIVA' && !e.indirizzo))}
                disableDoubleInput={true}
            />
            <RoleBasedInput
                fieldId={'NUM_CIV.SEDELAVORATIVA.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                id="inputNumCivico"
                field="numero"
                fieldLabel={"N°"}
                placeholder="N°"
                onChange={(field, elem) => props.onChangeIndirizzi(fieldIndirizzi, 'SEDE_LAVORATIVA', field, elem.target.value)}
                value={props.datiMedico.indirizzi?.find(e => e.tipo === 'SEDE_LAVORATIVA')?.numero}
                disableDoubleInput={true}
                forceReadOnly={props.datiMedico.indirizzi.some(e => e.tipo === 'SEDE_LAVORATIVA' && !Utils.isStringEmptyOrNullOrUndefined(e.numero)) &&
                    (props.editableFields.indirizzi != null && props.editableFields.indirizzi.length > 0 &&
                        props.editableFields.indirizzi.some(e => e.tipo === 'SEDE_LAVORATIVA' && !e.numero))}
            />
            <RoleBasedAutocomplete
                fieldId={'CITTA.SEDELAVORATIVA.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                id={"inputCitta"}
                keyField={"codice"}
                descriptionField={"descrizione"}
                field={"comune"}
                fieldLabel={"Città"}
                placeholder={"Città"}
                suggestions={provincia ? props.listaComuni.filter(c => c.codiceProvincia === provincia) : props.listaComuni}
                onChange={(field, elem) => props.onChangeIndirizzi(fieldIndirizzi, 'SEDE_LAVORATIVA', field, elem.target.value)}
                value={comune}
                disableDoubleInput={true}
                handleOnlyValue={true}
            />
            <RoleBasedAutocomplete
                fieldId={'PROV.SEDELAVORATIVA.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                id={"inputProvincia"}
                keyField={"codice"}
                descriptionField={"descrizione"}
                field={"provincia"}
                fieldLabel={"Provincia"}
                placeholder={"Provincia"}
                suggestions={props.listaProvince}
                onChange={(field, elem) => props.onChangeIndirizzi(fieldIndirizzi, 'SEDE_LAVORATIVA', field, elem.target.value)}
                value={provincia}
                disableDoubleInput={true}
                handleOnlyValue={true}
            />
        </FieldsRow>

        <FieldsRow>
            <RoleBasedInput
                fieldId={'CAP.SEDELAVORATIVA.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                id="inputCAP"
                field="cap"
                fieldLabel={"CAP"}
                placeholder="CAP"
                maxLength={'5'}
                onChange={(field, elem) => props.onChangeIndirizzi(fieldIndirizzi, 'SEDE_LAVORATIVA', field, elem.target.value)}
                value={props.datiMedico.indirizzi?.find(e => e.tipo === 'SEDE_LAVORATIVA')?.cap}
                validator={Utils.isValidCap}
                disableDoubleInput={true}
                forceReadOnly={props.datiMedico.indirizzi.some(e => e.tipo === 'SEDE_LAVORATIVA' && !Utils.isStringEmptyOrNullOrUndefined(e.cap)) &&
                    (props.editableFields.indirizzi != null && props.editableFields.indirizzi.length > 0 &&
                        props.editableFields.indirizzi.some(e => e.tipo === 'SEDE_LAVORATIVA' && !e.cap))}
            />
            <RoleBasedPhoneInput
                fieldId={'TEL.SEDELAVORATIVA.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                placeholder={"Telefono"}
                field="TELEFONO_SEDE_LAVORATIVA"
                onChange={(field, elem) => props.onChangeContatti(fieldContatti, field, elem.target.value)}
                id="inputTelefono"
                value={telefono}
                phonePrefix={null}
                fieldLabel={"Telefono"}
                fieldRequired={(!telefono && !cellulare) || telefono}
                disableDoubleInput={true}
                forceReadOnly={props.editableFields.contatti != null && props.editableFields.contatti.length > 0 && props.editableFields.contatti.some(e => e.tipo === 'TELEFONO_SEDE_LAVORATIVA' && !e.telefono)}
            />
            <RoleBasedPhoneInput
                fieldId={'CELL.SEDELAVORATIVA.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                placeholder={"Cellulare"}
                field="CELLULARE_SEDE_LAVORATIVA"
                onChange={(field, elem) => props.onChangeContatti(fieldContatti, field, elem.target.value)}
                id="inputCellulare"
                value={cellulare}
                fieldLabel={"Cellulare"}
                fieldRequired={(!telefono && !cellulare) || cellulare}
                disableDoubleInput={true}
            />
            <RoleBasedPhoneInput
                fieldId={'FAX.SEDELAVORATIVA.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                placeholder={"Fax"}
                field="FAX_SEDE_LAVORATIVA"
                onChange={(field, elem) => props.onChangeContatti(fieldContatti, field, elem.target.value)}
                id="inputFax"
                value={props.datiMedico.contatti?.find(e => e.tipo === 'FAX_SEDE_LAVORATIVA')?.valore}
                phonePrefix={null}
                fieldLabel={"Fax"}
                disableDoubleInput={true}
            />
        </FieldsRow>

        <FieldsRow>
            <RoleBasedInput
                fieldId={'EMAIL.SEDELAVORATIVA.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                id="inputEmail"
                field="EMAIL_SEDE_LAVORATIVA"
                fieldLabel={"E-mail"}
                placeholder="E-mail"
                onChange={(field, elem) => props.onChangeContatti(fieldContatti, field, elem.target.value)}
                value={props.datiMedico.contatti?.find(e => e.tipo === 'EMAIL_SEDE_LAVORATIVA')?.valore}
                validator={Utils.isValidEmail}
                disableDoubleInput={true}
            />
            <RoleBasedSelect
                fieldId={'TIT_STUD.ISTRUZIONE.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                id={"titoloStudio"}
                className={"titoloStudio"}
                options={OggettiUtili.titoliStudio}
                value={props.datiMedico.titoloStudio}
                onChange={elem => props.onChangeDatiMedico('titoloStudio', elem)}
                fieldLabel={'Titolo di studio'}
                field={"titoloStudio"}
                isSearchable={false}
                handleOnlyValue={true}
                disableDoubleInput={true}
            />
            <RoleBasedSelect
                fieldId={'DISTR.SEDELAVORATIVA.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                id={"Distretto"}
                className={"Distretto"}
                options={distretti}
                value={props.datiMedico.indirizzi?.find(e => e.tipo === 'SEDE_LAVORATIVA')?.distretto}
                onChange={elem => props.onChangeIndirizzi(fieldIndirizzi, 'SEDE_LAVORATIVA', 'distretto', elem.target.value)}
                fieldLabel={'Distretto'}
                field={"distretto"}
                isSearchable={false}
                handleOnlyValue={true}
                disableDoubleInput={true}
            />
        </FieldsRow>

        {Utils.isDimissioneProtetta(props.pageState)
            && <FieldsRow>
                <RoleBasedAutocomplete
                    fieldId={'OSPEDALE.SEDELAVORATIVA.ANAGRAFESOGGETTORICHIEDENTEINDIMISSIONEPROTETTA'}
                    pageState={props.pageState}
                    id={"inputOspedale"}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={(field, elem) => props.onChangeIndirizzi(fieldIndirizzi, 'SEDE_LAVORATIVA', field, elem?.target?.value)}
                    value={props.datiMedico.indirizzi?.find(e => e.tipo === 'SEDE_LAVORATIVA')?.ospedale}
                    field="ospedale"
                    placeholder="Ospedale"
                    suggestions={ospedaliSuggestions}
                    noSuggestions={"Errore: Ospedale non esistente"}
                    fieldLabel={"Ospedale"}
                    fieldRequired={true}
                    disableDoubleInput={true}
                    handleOnlyValue={true}
                />
                <RoleBasedAutocomplete
                    fieldId={'UNITAOPERATIVA.SEDELAVORATIVA.ANAGRAFESOGGETTORICHIEDENTEINDIMISSIONEPROTETTA'}
                    pageState={props.pageState}
                    id={"inputUnitaOperativa"}
                    keyField={"value"}
                    descriptionField={"label"}
                    onChange={(field, elem) => props.onChangeIndirizzi(fieldIndirizzi, 'SEDE_LAVORATIVA', field, elem.target.value)}
                    value={props.datiMedico.indirizzi?.find(e => e.tipo === 'SEDE_LAVORATIVA')?.unitaOperativa}
                    field={"unitaOperativa"}
                    placeholder={"Unità operativa"}
                    suggestions={unitaOperativeSuggestions}
                    noSuggestions={Utils.isObjectNull(props.datiMedico.aulss)
                        ? "Valorizzare il campo AULSS nell'accordion \"Soggetto richiedente cure domiciliari\" prima di selezionare una unità operativa"
                        : "Errore: Unità operativa non esistente"}
                    fieldLabel={"Unità operativa"}
                    fieldRequired={true}
                    disableDoubleInput={true}
                    handleOnlyValue={true}
                />
            </FieldsRow>}
    </AccordionSezioneRichiesta>;
}

SedeLavorativa.propTypes = {
    pageState: PropTypes.string.isRequired,
    datiMedico: PropTypes.object.isRequired,
    editableFields: PropTypes.object.isRequired,
    onChangeDatiMedico: PropTypes.func.isRequired,
    onChangeContatti: PropTypes.func.isRequired,
    onChangeIndirizzi: PropTypes.func.isRequired,
    openAccordion: PropTypes.bool.isRequired,
    onClickAccordion: PropTypes.func.isRequired,
    listaComuni: PropTypes.array.isRequired,
    listaProvince: PropTypes.array.isRequired
};
