import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import {FieldsRow, RoleBasedSelect, RoleBasedInput, isFieldIdWriteable} from "../../roleBasedComponents/RoleBasedComponents";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import Statistica from "../../statistica/Statistica";
import enumQuestionario from "../../../enum/enumQuestionario";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import RenderDomanda from "../../compilaQuestionario/questionario/domande/RenderDomanda";
import {getQuestionario} from "../../../utils/QuestionariUtils";

const propTypes = {
    datiPunteggioDiBrass: PropTypes.any,
    onChangeDatiPunteggioDiBrass: PropTypes.any,
    onChangeRequiredFieldsNumber: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    mascheraModificabilita: PropTypes.any,
    richiesta: PropTypes.object,
    pageState: PropTypes.string,
    salvaBozza: PropTypes.func,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    accordionReadOnly: PropTypes.bool
}

export default class PunteggioDiBrass extends Component {

    state = {
        punteggioDiBrass: _.cloneDeep(this.props.datiPunteggioDiBrass),
        campiObbligatori: 0,
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    inizializzaForm = () => {
        let punteggioDiBrass = _.cloneDeep(this.props.datiPunteggioDiBrass);
        this.setState({punteggioDiBrass: punteggioDiBrass}, () => this.props.onChangeRequiredFieldsNumber("punteggioDiBrass", this.numeroCampiObbligatori()));
    }

    numeroCampiObbligatori = () => {
        let campiObbligatori = 0;
        if (Utils.isStateRichiesta(this.props.pageState)) {
            const {punteggioDiBrass} = this.state;
            const includeRispostaSupportoSociale = !!(punteggioDiBrass?.BRASS?.sezioni[0]?.domande.find(domanda => domanda.id === 2));
            if (!punteggioDiBrass?.BRASS?.valutazione) campiObbligatori++;
            if (!includeRispostaSupportoSociale) campiObbligatori++;
            if (punteggioDiBrass?.pazienteCandidabile == null) campiObbligatori++;
        }
        return campiObbligatori;
    }

    handleAggiornaForm = (field, elem) => {
        let punteggioDiBrass = _.cloneDeep(this.state.punteggioDiBrass);

        punteggioDiBrass[field] = elem;
        this.setState({punteggioDiBrass: punteggioDiBrass}, () => {
            let punteggioDiBrassProps = _.cloneDeep(punteggioDiBrass);
            this.props.onChangeDatiPunteggioDiBrass("punteggioDiBrass", punteggioDiBrassProps);
            this.props.onChangeRequiredFieldsNumber("punteggioDiBrass", this.numeroCampiObbligatori());
        });
    }

    handleAggiornaRispostaSupportoSociale = (field, risposta) => {
        let punteggioDiBrass = _.cloneDeep(this.state.punteggioDiBrass);
        const includeRisposta = !!(punteggioDiBrass?.BRASS?.sezioni[0]?.domande.find(domanda => domanda.id === risposta.id));

        if (punteggioDiBrass.BRASS) {
            if (includeRisposta) {
                punteggioDiBrass = {
                    ...punteggioDiBrass,
                    BRASS:{
                        ...punteggioDiBrass.BRASS,
                        sezioni: punteggioDiBrass.BRASS.sezioni.map(function (el) {return {...el, domande: el.domande.map(function (el) {
                            if (el.id === risposta.id)
                                return risposta
                            return el
                        })}})
                    }
                }
            } else {
                if(!punteggioDiBrass.BRASS.sezioni.lenght){
                    punteggioDiBrass.BRASS.sezioni= [{
                        id: 1,
                        domande: [risposta]
                    }]
                }else {
                    punteggioDiBrass.BRASS.sezioni[0].domande.push(risposta)}
                }
        } else {
            punteggioDiBrass = {
                ...punteggioDiBrass,
                BRASS: {
                    punteggiIntermedi: null,
                    punteggio: null,
                    sezioni: [{
                        id: 1,
                        domande: [risposta]
                    }],
                    stato: null,
                    valutazione: null,
                }
            }
        }
        this.handleAggiornaForm(field, punteggioDiBrass.BRASS);
    }

    renderBody = () => {
        const punteggioDiBrass = _.cloneDeep(this.state.punteggioDiBrass);
        const tipoQuestionario = enumQuestionario.tipoQuestionario.BRASS;
        const domanda = getQuestionario(tipoQuestionario).sezioni[0].domande.find(s => s.id === 2);
        const questionarioCompleto = punteggioDiBrass?.BRASS?.stato === enumQuestionario.statoQuestionario.completo ? true : false;
        const rispostaSupportoSociale = punteggioDiBrass?.BRASS?.sezioni[0]?.domande.find(domanda => domanda.id === 2)?.risposte[0].id;
        return (
            <>
                <Statistica
                    fieldId={'PUNT_BRASS.PUNTEGGIOSCHEDADIBRASS.PUNTEGGIOBRASS'}
                    pageState={this.props.pageState}
                    nomeLink={"Compila Scheda di Brass"}
                    nomePunteggio={"Blaylock Risk Assessment Screening - Punteggio Scheda di BRASS"}
                    questionario={punteggioDiBrass.BRASS}
                    profileName={'Indice di rischio'}
                    profileFieldId={'IND_RISCHIO_BRASS.PUNTEGGIOSCHEDADIBRASS.PUNTEGGIOBRASS'}
                    onChange={(questionario) => this.handleAggiornaForm(tipoQuestionario, questionario)}
                    richiesta={this.props.richiesta}
                    pathRichiestaQuestionario={"informazioniBaseRicoveroAllergieAccesso.punteggioDiBrass.BRASS"}
                    tipoQuestionario={tipoQuestionario}
                    salvaBozza={this.props.salvaBozza}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    parentJsonPathSubObject={'brass'}
                    scoreMandatoryForRole={null}
                    profileRequired = {!questionarioCompleto}
                    altreInfo={(<RenderDomanda value = {rispostaSupportoSociale} disabilitaDomanda = {questionarioCompleto || !isFieldIdWriteable("PUNT_BRASS.PUNTEGGIOSCHEDADIBRASS.PUNTEGGIOBRASS", this.props.pageState, this.context.forceUserRole) || this.context.forceReadOnly} domanda={domanda} tipoQuestionario={tipoQuestionario} handleChange={(riposta) => this.handleAggiornaRispostaSupportoSociale(tipoQuestionario, riposta)} />)}
                />
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'NOTE_SCHEDA_BRASS.PUNTEGGIOSCHEDADIBRASS.PUNTEGGIOBRASS'}
                        pageState={this.props.pageState}
                        id={"noteSchedaDiBrass"}
                        value={punteggioDiBrass.noteSchedaDiBrass}
                        placeholder={"Note alla scheda di Brass"}
                        field={"noteSchedaDiBrass"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        fieldLabel={"Note alla scheda di Brass"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PAZ_CAND_CURE_DOMIC.PUNTEGGIOSCHEDADIBRASS.PUNTEGGIOBRASS'}
                        pageState={this.props.pageState}
                        id={"selectPazienteCandidabile"}
                        options={OggettiUtili.rispostaBreve}
                        value={punteggioDiBrass.pazienteCandidabile}
                        onChange={(elem) => this.handleAggiornaForm("pazienteCandidabile", elem)}
                        noOptionsMessage={() => "Errore: Il paziente è candidabile a un percorso di cure domiciliari non esistente"}
                        fieldLabel={"Il paziente è candidabile a un percorso di cure domiciliari"}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"pazienteCandidabile"}
                        fieldRequired={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"punteggioDiBrass"}
            title={"Punteggio di Brass"}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            required={true}
            accordionReadOnly={this.props.accordionReadOnly}
        >{this.renderBody()}</AccordionSezioneRichiesta>
    }
}

PunteggioDiBrass.contextType = RoleBasedContext;

PunteggioDiBrass.propTypes = propTypes;
