import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import Box from "../../generalComponents/box/Box";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti,
    defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import {isArray} from "lodash";

const propTypes = {
    dati: PropTypes.any,
    onChangeEsameObiettivo: PropTypes.func,
    pageState: PropTypes.string,
    openAccordion: PropTypes.bool,
    onClickAccordion: PropTypes.func,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultTipoAlterazione = {
    id: null,
    alterazione: null,
    sede: null
}

const defaultListaErnia = {
    id: null,
    ernia: null
}

const defaultSegnoGiordano = {
    id: null,
    segno: null,
    sede: null,
}

const nomeAccordion = "ADDOME";

export default class Addome extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.dati)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.dati);
        this.setState({dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({dati}) => {
            if (index === null && subField === null) {
                dati[field] = elem;
            } else {
                dati[field][index][subField] = elem;
            }

            /* Alterazioni */
            if (!dati.presenzaDiAlterazioni) {
                dati.tipoDiAlterazione = [];
            }
            if (dati.palpazioneSuperficiale !== "02") {
                dati.sedePalpazioneSuperficiale = null;
            }
            if (dati.palpazioneProfonda !== "02") {
                dati.sedePalpazioneProfonda = null;
            }
            if (!this.visualizzaCondizioneRilevata(dati)) {
                dati.alterazioneCondizioneRilevata = null;
            }

            /* Segno di blumberg*/
            if (!dati.segnoDiBlumberg) {
                dati.segnoDiBlumbergSede = null;
                dati.segnoDiBlumbergCondizioneRilevata = null;
            }

            /* Segno di murphy*/
            if (!dati.segnoDiMurphy) {
                dati.segnoDiMurphyNote = null;
                dati.segnoDiMurphyCondizioneRilevata = null;
            }

            /* Segno di giordano*/
            if (dati.listaGiordano?.length === 2 && field === "listaGiordano" && subField === "sede") {
                let sedeOpposta = null;
                if (dati.listaGiordano[index].sede === "01") {
                    sedeOpposta = "02";
                } else if (dati.listaGiordano[index].sede === "02") {
                    sedeOpposta = "01";
                }
                dati.listaGiordano[1 - index].sede = sedeOpposta;
            }
            if (!this.isSegnoDiGiordanoPositivo(dati.listaGiordano)) {
                dati.segnoGiordanoNote = null;
                dati.segnoGiordanoCondizioneRilevata = null;
            }

            /* Meteorismo */
            if (!this.visualizzaCondizioneRilevataMetabolismo(dati)) {
                dati.meteorismoCondizioneRilevata = null;
            }

            /* Peristalsi */
            if (!this.visualizzaNotePeristalsi(dati)) {
                dati.peristalsiNote = null;
                dati.peristalsiCondizioneRilevata = null;
            }

            /* Fegato palpabile*/
            if (!dati.fegatoPalpabile) {
                dati.fegatoPalpabileNote = null;
                dati.fegatoPalpabileCondizioneRilevata = null;
            }

            /* Milza palpabile*/
            if (!dati.milzaPalpabile) {
                dati.milzaPalpabileNote = null;
                dati.milzaPalpabileCondizioneRilevata = null;
            }

            /* Massa palpabile*/
            if (!dati.massaPalpabile) {
                dati.massaPalpabileDescrizione = null;
                dati.massaPalpabileCondizioneRilevata = null;
            }

            /* Laparocele */
            if (!dati.laparocele) {
                dati.laparoceleNote = null;
                dati.laparoceleCondizioneRilevata = null;
            }

            /* Ernie */
            if (!this.isErnieDiversoDaNo(dati.listaErnia)) {
                dati.erniaNote = null;
                dati.erniaCondizioneRilevata = null;
            }
            if (dati.listaErnia?.length === 2 && field === "listaErnia" && subField === "ernia") {
                if (elem === "01") {
                    dati.listaErnia[0].ernia = "01";
                    dati.listaErnia.splice(1, 1);
                } else {
                    let sedeOpposta = null;
                    if (dati.listaErnia[index].ernia === "02") {
                        sedeOpposta = "03";
                    } else if (dati.listaErnia[index].ernia === "03") {
                        sedeOpposta = "02";
                    }
                    dati.listaErnia[1 - index].ernia = sedeOpposta;
                }
            }

            /* Globo vescicolare */
            if (!dati.globoVescicale) {
                dati.globoVescicaleNote = null;
                dati.globoVescicaleCondizioneRilevata = null;
            }
            return {dati};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        const dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("addome", dati);
        let proposteAttEsugg = [];
        let campiObbligatoriRimanenti = 0;
        Object.entries(this.state.dati).forEach(([key, value]) => {
            if (key.includes("PropostaAttivitaSuggerimenti") && isArray(value)){
                value.forEach(element => {
                    proposteAttEsugg.push(element)
                });
            }
        });
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(proposteAttEsugg)
        this.props.onChangeRequiredFieldsNumber("sottosezioneAddome", campiObbligatoriRimanenti);
    }

    handleDispositiviArrayChange = (array, field) => {
        let data = _.cloneDeep(this.state.dati);
        data[field] = array;
        this.setState({dati: data}, () => {
            this.props.onChangeEsameObiettivo("addome", data);
            this.forceUpdate();
        });
    }

    visualizzaCondizioneRilevata = (dati) => {
        return dati.presenzaDiAlterazioni || dati.palpazioneSuperficiale === "02" || dati.palpazioneProfonda === "02";
    }

    visualizzaCondizioneRilevataMetabolismo = (dati) => {
        return dati.meteorismo === "01" || dati.meteorismo === "03";
    }

    visualizzaNotePeristalsi = (dati) => {
        return dati.peristalsi !== null && dati.peristalsi !== "02";
    }

    /* Alterazione */

    renderTipoAlterazione = (alterazione, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'M_TIPO_ALT.ADDOME.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_TIPO_ALT.ADDOME.INGESTIONE'}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm("tipoDiAlterazione", elem.target.value, index, "alterazione")}
                    value={alterazione.alterazione}
                    field={"alterazione"}
                    placeholder={"Tipo di alterazione"}
                    fieldLabel={"Tipo di alterazione"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
                <RoleBasedInput
                    fieldId={'M_SEDE_ALT.ADDOME.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_SEDE_ALT.ADDOME.INGESTIONE'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci sede/i alterazione"}
                    field={"sede"}
                    onChange={(field, elem) => this.handleAggiornaForm("tipoDiAlterazione", elem.target.value, index, "sede")}
                    id={"alterazioneSedi"}
                    value={alterazione.sede}
                    fieldLabel={"Sede/i"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
            </FieldsRow>
        )
    }

    addNewTipoAlterazione = () => {
        let dati = _.cloneDeep(this.state.dati);
        let tipoAlterazione = _.cloneDeep(defaultTipoAlterazione);
        tipoAlterazione.id = uuid();
        dati.tipoDiAlterazione.push(tipoAlterazione);
        this.setState({dati}, () => this.validazioneDati(dati));
    }

    removeTipoAlterazione = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.tipoDiAlterazione.splice(index, 1);
        this.setState({dati}, () => this.validazioneDati(dati));
    }

    /* Ernia */

    isAddErnie = (listaErnia) => {
        switch (listaErnia.length) {
            case 0:
                return true;

            case 1:
                return listaErnia[0].ernia !== "01";

            default:
                return false;
        }
    }

    isErnieDiversoDaNo = (listaErnie) => {
        if (listaErnie.length === 0) return false;

        return listaErnie?.every(e => e.ernia !== null && e.ernia !== "01");
    }

    addNewErnia = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultListaErnia);
        type.id = uuid();

        if (dati.listaErnia.length === 1) {
            let newSede = null;
            if (dati.listaErnia[0].ernia === "02") {
                newSede = "03";
            } else if (dati.listaErnia[0].ernia === "03") {
                newSede = "02";
            }
            type.ernia = newSede;
        }

        dati.listaErnia.push(type);
        this.setState({dati}, () => this.validazioneDati());
    }

    removeErnia = (indice) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.listaErnia.splice(indice, 1);
        if (!this.isErnieDiversoDaNo(dati.listaErnia)) {
            dati.erniaNote = null;
            dati.erniaCondizioneRilevata = null;
        }
        this.setState({dati}, () => this.validazioneDati());
    }

    renderErnia = (ernia, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'M_ERNIE.ADDOME.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_ERNIE.ADDOME.INGESTIONE'}
                    pageState={this.props.pageState}
                    id={"selectErnia" + index}
                    options={OggettiUtili.rispostaErnia}
                    value={ernia.ernia}
                    onChange={(elem) => this.handleAggiornaForm("listaErnia", elem, index, "ernia")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                    fieldLabel={"Ernie"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    handleOnlyValue={true}
                    field={"ernia"}
                />
            </FieldsRow>
        );
    }

    /* Segno di Giordano */

    isSegnoDiGiordanoPositivo = (listaGiordano) => {
        let segno = false;
        listaGiordano.forEach(giordano => {
            if (giordano.segno) segno = true;
        });
        return segno;
    }

    addNewSegnoDiGiordano = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultSegnoGiordano);
        type.id = uuid();

        if (dati.listaGiordano.length === 1) {
            let newSede = null;
            if (dati.listaGiordano[0].sede === "01") {
                newSede = "02";
            } else if (dati.listaGiordano[0].sede === "02") {
                newSede = "01";
            }
            type.sede = newSede;
        }

        dati.listaGiordano.push(type);
        this.setState({dati}, () => this.validazioneDati());
    }

    removeSegnoDiGiordano = (indice) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.listaGiordano.splice(indice, 1);
        this.setState({dati}, () => this.validazioneDati());
    }

    renderSegnoDiGiordano = (giordano, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_SEGNO_GIORD.ADDOME.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_SEGNO_GIORD.ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        id={"selectGiordano"}
                        options={OggettiUtili.segnoDiBlumberg}
                        value={giordano.segno}
                        onChange={(elem) => this.handleAggiornaForm("listaGiordano", elem, index, "segno")}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Segno non esistente"}
                        fieldLabel={"Segno di Giordano"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"segno"}
                    />
                    <RoleBasedSelect
                        fieldId={'M_SEGNO_GIORD_SEDE.ADDOME.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_SEGNO_GIORD_SEDE.ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        id={"selectGiordano" + index}
                        options={OggettiUtili.sede}
                        value={giordano.sede}
                        onChange={(elem) => this.handleAggiornaForm("listaGiordano", elem, index, "sede")}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Sede non esistente"}
                        fieldLabel={"Sede"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"sede"}
                    />
                </FieldsRow>
            </>
        );
    }


    renderBodySezione() {
        const dati = this.state.dati;

        return (
            <>
                <Box key={"boxAlterazioni" + nomeAccordion} titleBox={"Alterazione"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_PRES_ALT.ADDOME.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_PRES_ALT.ADDOME.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"presenzaDiAlterazioni"}
                            className={"presenzaDiAlterazioni"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.presenzaDiAlterazioni}
                            onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioni", elem)}
                            isSearchable={false}
                            fieldLabel={"Presenza di alterazioni"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"presenzaDiAlterazioni"}
                        />
                    </FieldsRow>
                    {dati.presenzaDiAlterazioni &&
                        <SectionList
                            title={'Tipo di alterazione'}
                            data={dati.tipoDiAlterazione}
                            renderSection={this.renderTipoAlterazione}
                            addNewSectionCallback={this.addNewTipoAlterazione}
                            removeSectionCallback={this.removeTipoAlterazione}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            pageState={this.props.pageState}
                            keyFieldId={'M_TIPO_ALT.ADDOME.ESAMEOBIETTIVO'}
                            infermiereKeyFieldId={'I_TIPO_ALT.ADDOME.INGESTIONE'}
                            field={'tipoDiAlterazione'}
                            addButtonVisibilityHandler={dati.tipoDiAlterazione?.every(t => !!t.alterazione)}
                            parentJsonPath={"esameObiettivo.addome.tipoDiAlterazione"}
                        />}
                    <DispositiviPresenti
                        handleArrayChange={this.handleDispositiviArrayChange}
                        dispositiviPresenti={dati.alterazioneDispositiviPresenti}
                        pageState={this.props.pageState}
                        fieldId={'M_DISP_ALT.ADDOME.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_DISP_ALT.ADDOME.INGESTIONE'}
                        field={"alterazioneDispositiviPresenti"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        parentJsonPath={"esameObiettivo.addome.alterazioneDispositiviPresenti"}
                    />
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_ISPEZ.ADDOME.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_ISPEZ.ADDOME.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"alterazioneIspezione"}
                            className={"alterazioneIspezione"}
                            options={OggettiUtili.ispezioneAddome}
                            value={dati.alterazioneIspezione}
                            onChange={(elem) => this.handleAggiornaForm("alterazioneIspezione", elem)}
                            isSearchable={false}
                            fieldLabel={"Ispezione"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"alterazioneIspezione"}
                        />
                    </FieldsRow>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_PALP_SUP.ADDOME.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_PALP_SUP.ADDOME.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"palpazioneSuperficiale"}
                            className={"palpazioneSuperficiale"}
                            options={OggettiUtili.palpazione}
                            value={dati.palpazioneSuperficiale}
                            onChange={(elem) => this.handleAggiornaForm("palpazioneSuperficiale", elem)}
                            isSearchable={false}
                            fieldLabel={"Palpazione superficiale"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"palpazioneSuperficiale"}
                        />
                        {dati.palpazioneSuperficiale === "02" &&
                            <RoleBasedInput
                                fieldId={'M_SEDE_PALP_SUP.ADDOME.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_SEDE_PALP_SUP.ADDOME.INGESTIONE'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci sede"}
                                field={"sedePalpazioneSuperficiale"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"sedePalpazioneSuperficiale"}
                                value={dati.sedePalpazioneSuperficiale}
                                fieldLabel={"Sede"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />}
                    </FieldsRow>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_PALP_PROF.ADDOME.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_PALP_PROF.ADDOME.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"palpazioneProfonda"}
                            className={"palpazioneProfonda"}
                            options={OggettiUtili.palpazione}
                            value={dati.palpazioneProfonda}
                            onChange={(elem) => this.handleAggiornaForm("palpazioneProfonda", elem)}
                            isSearchable={false}
                            fieldLabel={"Palpazione profonda"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"palpazioneProfonda"}
                        />
                        {dati.palpazioneProfonda === "02" &&
                            <RoleBasedInput
                                fieldId={'M_SEDE_PALP_PROF.ADDOME.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_SEDE_PALP_PROF.ADDOME.INGESTIONE'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci sede"}
                                field={"sedePalpazioneProfonda"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"sedePalpazioneProfonda"}
                                value={dati.sedePalpazioneProfonda}
                                fieldLabel={"Sede"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />}
                    </FieldsRow>
                    <CondizioneRilevataSection
                        ambito={'ADDOME ALTERAZIONE'}
                        appendRolePrefix={true}
                        fieldsSuffix={'PALP'}
                        parentFieldId={'ADDOME.ESAMEOBIETTIVO'}
                        infParentFieldId={'ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("alterazioneCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.alterazioneCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("alterazioneDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.alterazioneDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={"alterazione"}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("alterazionePropostaAttivitaSuggerimenti", elem)}
                        valueSuggerimenti={dati.alterazionePropostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={this.visualizzaCondizioneRilevata(dati)}
                    />
                </Box>

                <Box key={"boxBlumberg" + nomeAccordion} titleBox={"Segno di Blumberg"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_SEGNO_BLUM.ADDOME.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_SEGNO_BLUM.ADDOME.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"segnoDiBlumberg"}
                            className={"segnoDiBlumberg"}
                            options={OggettiUtili.segnoDiBlumberg}
                            value={dati.segnoDiBlumberg}
                            onChange={(elem) => this.handleAggiornaForm("segnoDiBlumberg", elem)}
                            isSearchable={false}
                            fieldLabel={"Segno di Blumberg"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"segnoDiBlumberg"}
                        />
                    </FieldsRow>

                    {dati.segnoDiBlumberg &&
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_SEGNO_BLUM_SEDE.ADDOME.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_SEGNO_BLUM_SEDE.ADDOME.INGESTIONE'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci sede"}
                                field={"segnoDiBlumbergSede"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"segnoDiBlumbergSede"}
                                value={dati.segnoDiBlumbergSede}
                                fieldLabel={"Sede"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>}
                    <CondizioneRilevataSection
                        ambito={'ADDOME SEGNO DI BLUMBERG'}
                        appendRolePrefix={true}
                        fieldsPrefix={'SEGNO_BLUM'}
                        parentFieldId={'ADDOME.ESAMEOBIETTIVO'}
                        infParentFieldId={'ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("segnoDiBlumbergCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.segnoDiBlumbergCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("segnoDiBlumbergDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.segnoDiBlumbergDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={"segnoDiBlumberg"}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("segnoDiBlumbergPropostaAttivitaSuggerimenti", elem)}
                        valueSuggerimenti={dati.segnoDiBlumbergPropostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={dati.segnoDiBlumberg}
                    />
                </Box>

                <Box key={"boxMurphy" + nomeAccordion} titleBox={"Segno di Murphy"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_SEGNO_MURPHY.ADDOME.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_SEGNO_MURPHY.ADDOME.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"segnoDiMurphy"}
                            className={"segnoDiMurphy"}
                            options={OggettiUtili.segnoDiBlumberg}
                            value={dati.segnoDiMurphy}
                            onChange={(elem) => this.handleAggiornaForm("segnoDiMurphy", elem)}
                            isSearchable={false}
                            fieldLabel={"Segno di Murphy"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"segnoDiMurphy"}
                        />
                    </FieldsRow>

                    {dati.segnoDiMurphy &&
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_SEGNO_MURPHY_NOTE.ADDOME.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_SEGNO_MURPHY_SEDE.ADDOME.INGESTIONE'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci note"}
                                field={"segnoDiMurphyNote"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"segnoDiMurphyNote"}
                                value={dati.segnoDiMurphyNote}
                                fieldLabel={"Note"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>}
                    <CondizioneRilevataSection
                        ambito={'ADDOME SEGNO DI MURPHY'}
                        appendRolePrefix={true}
                        fieldsPrefix={'SEGNO_MURPHY'}
                        parentFieldId={'ADDOME.ESAMEOBIETTIVO'}
                        infParentFieldId={'ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("segnoDiMurphyCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.segnoDiMurphyCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("segnoDiMurphyDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.segnoDiMurphyDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={"segnoDiMurphy"}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("segnoDiMurphyPropostaAttivitaSuggerimenti", elem)}
                        valueSuggerimenti={dati.segnoDiMurphyPropostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={dati.segnoDiMurphy}
                    />
                </Box>

                <Box key={"boxGiordano" + nomeAccordion} titleBox={"Segno di Giordano"}>
                    <SectionList
                        title={'Segno di giordano'}
                        data={dati.listaGiordano}
                        renderSection={this.renderSegnoDiGiordano}
                        addNewSectionCallback={this.addNewSegnoDiGiordano}
                        removeSectionCallback={this.removeSegnoDiGiordano}
                        addButtonVisibilityHandler={dati.listaGiordano?.length < 2 ?? true}
                        pageState={this.props.pageState}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        keyFieldId={'M_SEGNO_GIORD.ADDOME.ESAMEOBIETTIVO'}
                        infermiereKeyFieldId={'I_SEGNO_GIORD.ADDOME.INGESTIONE'}
                        field={'listaGiordano'}
                        parentJsonPath={"esameObiettivo.addome.listaGiordano"}
                    />
                    {this.isSegnoDiGiordanoPositivo(dati.listaGiordano) &&
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_SEGNO_GIORD_NOTE.ADDOME.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_SEGNO_GIORD_NOTE.ADDOME.INGESTIONE'}
                                pageState={this.props.pageState}
                                placeholder={"Note"}
                                field={"segnoGiordanoNote"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"inputSegnoGiordanoNote"}
                                value={dati.segnoGiordanoNote}
                                fieldLabel={"Note"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>}
                    <CondizioneRilevataSection
                        ambito={'ADDOME SEGNO DI GIORDANO'}
                        appendRolePrefix={true}
                        fieldsPrefix={'SEGNO_GIORD'}
                        parentFieldId={'ADDOME.ESAMEOBIETTIVO'}
                        infParentFieldId={'ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("segnoGiordanoCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.segnoGiordanoCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("segnoGiordanoDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.segnoGiordanoDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={"segnoGiordano"}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("segnoGiordanoPropostaAttivitaSuggerimenti", elem)}
                        valueSuggerimenti={dati.segnoGiordanoPropostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={this.isSegnoDiGiordanoPositivo(dati.listaGiordano)}
                    />
                </Box>

                <Box key={"boxMeteorismo" + nomeAccordion} titleBox={"Meteorismo"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_METEOR.ADDOME.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_METEOR.ADDOME.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"meteorismo"}
                            className={"meteorismo"}
                            options={OggettiUtili.meteorismo}
                            value={dati.meteorismo}
                            onChange={(elem) => this.handleAggiornaForm("meteorismo", elem)}
                            isSearchable={false}
                            fieldLabel={"Meteorismo"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"meteorismo"}
                        />
                    </FieldsRow>
                    <CondizioneRilevataSection
                        ambito={'ADDOME METEORISMO'}
                        appendRolePrefix={true}
                        fieldsPrefix={'METEOR'}
                        parentFieldId={'ADDOME.ESAMEOBIETTIVO'}
                        infParentFieldId={'ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("meteorismoCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.meteorismoCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("meteorismoDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.meteorismoDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={"meteorismo"}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("meteorismoPropostaAttivitaSuggerimenti", elem)}
                        valueSuggerimenti={dati.meteorismoPropostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={this.visualizzaCondizioneRilevataMetabolismo(dati)}
                    />
                </Box>

                <Box key={"boxPeristalsi" + nomeAccordion} titleBox={"Peristalsi"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_PERIST.ADDOME.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_PERIST.ADDOME.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"peristalsi"}
                            className={"peristalsi"}
                            options={OggettiUtili.peristalsi}
                            value={dati.peristalsi}
                            onChange={(elem) => this.handleAggiornaForm("peristalsi", elem)}
                            isSearchable={false}
                            fieldLabel={"Peristalsi"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"peristalsi"}
                        />
                    </FieldsRow>
                    {this.visualizzaNotePeristalsi(dati) &&
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_PERIST_NOTE.ADDOME.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_PERIST_NOTE.ADDOME.INGESTIONE'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci note"}
                                field={"peristalsiNote"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"peristalsiNote"}
                                value={dati.peristalsiNote}
                                fieldLabel={"Note"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>}
                    <CondizioneRilevataSection
                        ambito={'ADDOME PERISTALSI'}
                        appendRolePrefix={true}
                        fieldsPrefix={'PERIST'}
                        parentFieldId={'ADDOME.ESAMEOBIETTIVO'}
                        infParentFieldId={'ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("peristalsiCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.peristalsiCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("peristalsiDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.peristalsiDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={"peristalsi"}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("peristalsiPropostaAttivitaSuggerimenti", elem)}
                        valueSuggerimenti={dati.peristalsiPropostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={this.visualizzaNotePeristalsi(dati)}
                    />
                </Box>

                <Box key={"boxFegatoPalpabile" + nomeAccordion} titleBox={"Fegato palpabile"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_FEG_PALP.ADDOME.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_FEG_PALP.ADDOME.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"fegatoPalpabile"}
                            className={"fegatoPalpabile"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.fegatoPalpabile}
                            onChange={(elem) => this.handleAggiornaForm("fegatoPalpabile", elem)}
                            isSearchable={false}
                            fieldLabel={"Fegato Palpabile"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"fegatoPalpabile"}
                        />
                    </FieldsRow>
                    {dati.fegatoPalpabile &&
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_FEG_PALP_NOTE.ADDOME.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_FEG_PALP_NOTE.ADDOME.INGESTIONE'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci note"}
                                field={"fegatoPalpabileNote"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"fegatoPalpabileNote"}
                                value={dati.fegatoPalpabileNote}
                                fieldLabel={"Note"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>}
                    <CondizioneRilevataSection
                        ambito={'ADDOME FEGATO PALPABILE'}
                        appendRolePrefix={true}
                        fieldsPrefix={'FEG_PALP'}
                        parentFieldId={'ADDOME.ESAMEOBIETTIVO'}
                        infParentFieldId={'ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("fegatoPalpabileCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.fegatoPalpabileCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("fegatoPalpabileDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.fegatoPalpabileDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={"fegatoPalpabile"}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("fegatoPalpabilePropostaAttivitaSuggerimenti", elem)}
                        valueSuggerimenti={dati.fegatoPalpabilePropostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={dati.fegatoPalpabile}
                    />
                </Box>

                <Box key={"boxMilzaPalpabile" + nomeAccordion} titleBox={"Milza palpabile"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_MILZA_PALP.ADDOME.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_MILZA_PALP.ADDOME.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"milzaPalpabile"}
                            className={"milzaPalpabile"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.milzaPalpabile}
                            onChange={(elem) => this.handleAggiornaForm("milzaPalpabile", elem)}
                            isSearchable={false}
                            fieldLabel={"Milza Palpabile"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"milzaPalpabile"}
                        />
                    </FieldsRow>
                    {dati.milzaPalpabile &&
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_MILZA_PALP_NOTE.ADDOME.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_MILZA_PALP_NOTE.ADDOME.INGESTIONE'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci note"}
                                field={"milzaPalpabileNote"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"milzaPalpabileNote"}
                                value={dati.milzaPalpabileNote}
                                fieldLabel={"Note"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>}
                    <CondizioneRilevataSection
                        ambito={'ADDOME MILZA PALPABILE'}
                        appendRolePrefix={true}
                        fieldsPrefix={'MILZA_PALP'}
                        parentFieldId={'ADDOME.ESAMEOBIETTIVO'}
                        infParentFieldId={'ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("milzaPalpabileCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.milzaPalpabileCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("milzaPalpabileDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.milzaPalpabileDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={"milzaPalpabile"}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("milzaPalpabilePropostaAttivitaSuggerimenti", elem)}
                        valueSuggerimenti={dati.milzaPalpabilePropostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={dati.milzaPalpabile}
                    />
                </Box>

                <Box key={"boxMassaPalpabile" + nomeAccordion} titleBox={"Masse palpabili"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_MAS_PALP.ADDOME.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_MAS_PALP.ADDOME.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"massaPalpabile"}
                            className={"massaPalpabile"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.massaPalpabile}
                            onChange={(elem) => this.handleAggiornaForm("massaPalpabile", elem)}
                            isSearchable={false}
                            fieldLabel={"Masse Palpabili"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"massaPalpabile"}
                        />
                    </FieldsRow>
                    {dati.massaPalpabile &&
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_MAS_PALP_DESCR.ADDOME.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_MAS_PALP_DESCR.ADDOME.INGESTIONE'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci descrizione"}
                                field={"massaPalpabileDescrizione"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"massaPalpabileDescrizione"}
                                value={dati.massaPalpabileDescrizione}
                                fieldLabel={"Descrizione"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>}
                    <CondizioneRilevataSection
                        ambito={'ADDOME MASSA PALPABILE'}
                        appendRolePrefix={true}
                        fieldsPrefix={'MAS_PALP'}
                        parentFieldId={'ADDOME.ESAMEOBIETTIVO'}
                        infParentFieldId={'ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("massaPalpabileCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.massaPalpabileCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("massaPalpabileDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.massaPalpabileDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={"massaPalpabile"}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("massaPalpabilePropostaAttivitaSuggerimenti", elem)}
                        valueSuggerimenti={dati.massaPalpabilePropostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={dati.massaPalpabile}
                    />
                </Box>

                <Box key={"boxLaparocele" + nomeAccordion} titleBox={"Laparocele"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_LAPAR.ADDOME.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_LAPAR.ADDOME.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"laparocele"}
                            className={"laparocele"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.laparocele}
                            onChange={(elem) => this.handleAggiornaForm("laparocele", elem)}
                            isSearchable={false}
                            fieldLabel={"Laparocele"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"laparocele"}
                        />
                    </FieldsRow>
                    {dati.laparocele &&
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_LAPAR_NOTE.ADDOME.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_LAPAR_NOTE.ADDOME.INGESTIONE'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci descrizione"}
                                field={"laparoceleNote"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"laparoceleNote"}
                                value={dati.laparoceleNote}
                                fieldLabel={"Note"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>}
                    <CondizioneRilevataSection
                        ambito={'ADDOME LAPAROCELE'}
                        appendRolePrefix={true}
                        fieldsPrefix={'LAPAR'}
                        parentFieldId={'ADDOME.ESAMEOBIETTIVO'}
                        infParentFieldId={'ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("laparoceleCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.laparoceleCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("laparoceleDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.laparoceleDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={"laparocele"}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("laparocelePropostaAttivitaSuggerimenti", elem)}
                        valueSuggerimenti={dati.laparocelePropostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={dati.laparocele}
                    />

                </Box>

                <Box key={"boxErnie" + nomeAccordion} titleBox={"Ernie"}>
                    <SectionList
                        title={'Ernia'}
                        data={dati.listaErnia}
                        renderSection={this.renderErnia}
                        addNewSectionCallback={this.addNewErnia}
                        removeSectionCallback={this.removeErnia}
                        addButtonVisibilityHandler={this.isAddErnie(dati.listaErnia)}
                        pageState={this.props.pageState}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        keyFieldId={'M_ERNIE.ADDOME.ESAMEOBIETTIVO'}
                        infermiereKeyFieldId={'I_ERNIE.ADDOME.INGESTIONE'}
                        field={'listaErnia'}
                        parentJsonPath={"esameObiettivo.addome.listaErnia"}
                    />
                    {this.isErnieDiversoDaNo(dati.listaErnia) &&
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_ERNIE_NOTE.ADDOME.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_ERNIE_NOTE.ADDOME.INGESTIONE'}
                                pageState={this.props.pageState}
                                placeholder={"Note"}
                                field={"erniaNote"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"inputErniaNote"}
                                value={dati.erniaNote}
                                fieldLabel={"Note"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>}
                    <CondizioneRilevataSection
                        ambito={'ADDOME ERNIE'}
                        appendRolePrefix={true}
                        fieldsPrefix={'ERNIE'}
                        parentFieldId={'ADDOME.ESAMEOBIETTIVO'}
                        infParentFieldId={'ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("erniaCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.erniaCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("erniaDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.erniaDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={"ernia"}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("erniaPropostaAttivitaSuggerimenti", elem)}
                        valueSuggerimenti={dati.erniaPropostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={this.isErnieDiversoDaNo(dati.listaErnia)}
                    />
                </Box>

                <Box key={"boxGloboVescicale" + nomeAccordion} titleBox={"Globo vescicolare"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_GLOBO_VESC.ADDOME.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_GLOBO_VESC.ADDOME.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"globoVescicale"}
                            className={"globoVescicale"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.globoVescicale}
                            onChange={(elem) => this.handleAggiornaForm("globoVescicale", elem)}
                            isSearchable={false}
                            fieldLabel={"Globo vescicale"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"globoVescicale"}
                        />
                    </FieldsRow>
                    {dati.globoVescicale &&
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_GLOBO_VESC_NOTE.ADDOME.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_GLOBO_VESC_NOTE.ADDOME.INGESTIONE'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci nota"}
                                field={"globoVescicaleNote"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"globoVescicaleNote"}
                                value={dati.globoVescicaleNote}
                                fieldLabel={"Note"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>}
                    <CondizioneRilevataSection
                        ambito={'ADDOME GLOBO VESCICOLARE'}
                        appendRolePrefix={true}
                        fieldsPrefix={'GLOBO_VESC'}
                        parentFieldId={'ADDOME.ESAMEOBIETTIVO'}
                        infParentFieldId={'ADDOME.INGESTIONE'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("globoVescicaleCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.globoVescicaleCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("globoVescicaleDaAttenzionare", elem)}
                        showAutonomia={true}
                        onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("globoVescicaleAutonomiaGestionePaziente", elem)}
                        valueAutonomiaPaziente={dati.globoVescicaleAutonomiaGestionePaziente}
                        onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("globoVescicaleAutonomiaGestioneCaregiver", elem)}
                        valueAutonomiaCaregiver={dati.globoVescicaleAutonomiaGestioneCaregiver}
                        valueDaAttenzionare={dati.globoVescicaleDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={"globoVescicale"}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("globoVescicalePropostaAttivitaSuggerimenti", elem)}
                        valueSuggerimenti={dati.globoVescicalePropostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={dati.globoVescicale}
                    />
                </Box>
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"addome"}
                    title={"Addome"}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.addome.tipoDiAlterazione", "esameObiettivo.addome.alterazioneDispositiviPresenti",
                            "esameObiettivo.addome.listaGiordano", "esameObiettivo.addome.listaErnia",
                            "esameObiettivo.addome.propostaAttivitaSuggerimenti"],
                        [defaultTipoAlterazione, defaultDispositiviPresenti, defaultSegnoGiordano,
                            defaultListaErnia, defaultProposteAttivitaSuggerimenti]
                    )}
                    accordionReadOnly={this.props.accordionReadOnly}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}

Addome.propTypes = propTypes;

Addome.defaultProps = {
    showAccordion: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

