import React, {useState} from "react";
import PropTypes from "prop-types";
import styles from "./SvuotaQuestionario.module.css";
import Icon from "../../generalComponents/icon/Icon";
import PopoverReact from "../../popover/Popover";
import _ from "lodash";
import ModalHelper from "../../modale/ModalHelper";
import {Button} from "web-client-archetype";
import {closeModaleStyle} from "../../../utils/Utils";
import * as BsIcon from "react-bootstrap-icons";
function SvuotaQuestionario ({tipoQuestionario, richiestaAggiornata, aggiornaQuestionario, salvaBozza}) {

    const [apriModal, setApriModal] = useState();

    const svuotaQuestionario = () => {
        let richiesta = _.cloneDeep(richiestaAggiornata);

        richiesta[tipoQuestionario] = {
            ...richiesta[tipoQuestionario],
            sezioni: richiesta[tipoQuestionario]?.sezioni?.map(function (el) {
                return {...el, domande: []}
            })
        }

        aggiornaQuestionario(richiesta)
        closeModaleStyle();
        setApriModal(false)

        if(salvaBozza) salvaBozza();
    }

    const footerModale = (
        <>
            <Button
                onClick={() => svuotaQuestionario()}
                id={"confermaBtn"}
                text="Conferma"
                className={"btn btn-adi btnOrangeOutline"}
            />
            <Button
                onClick={() => {closeModaleStyle(); setApriModal(false)}}
                id={"annullaBtn"}
                text="Annulla"
                className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                disabled={false}
            />
        </>
    );

    return (
        <>
            <span className={styles.btnSvuotaQuestionationario.concat(" pl-2")} onClick={() => setApriModal(true)} id={"svuotaBtnQuestionario_" + tipoQuestionario}>
                <PopoverReact
                    text={<BsIcon.ArrowCounterclockwise color={"#176A65"} width={25} height={25} />}
                    body={"Selezionare per cancellare le risposte inserite"}
                    openOnMouseOver={true}
                    position={'right'}
                    className={'popover-info'}
                />
            </span>
            {apriModal && <ModalHelper
                id={`confermaResetQuestionario_${tipoQuestionario}`}
                title={"Attenzione!"}
                titleClassName={"modal-header d-inline text-center backgroundOrange"}
                containerClassName={("modal-dialog modal-dialog-scrollable modal modal-dialog-centered")}
                contentClassName={"modal-content"}
                body={"Vuoi procedere con la cancellazione delle risposte inserite?"}
                footer={footerModale}
                footerClassName={"modal-footer justify-content-around"}
                bodyClassName={"modal-body d-flex align-items-center mx-auto"}
            />}
        </>
    )
}

SvuotaQuestionario.propTypes = {
    tipoQuestionario: PropTypes.string,
    richiestaAggiornata: PropTypes.node.isRequired,
    aggiornaQuestionario: PropTypes.func.isRequired, 
    salvaBozza: PropTypes.func
};

SvuotaQuestionario.defaultProps = {
    tipoQuestionario: "",
    salvaBozza: () => null
};

export default SvuotaQuestionario;
