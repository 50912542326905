import React, {Fragment} from "react";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import PropTypes from "prop-types";
import * as _ from "lodash";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import ToponomasticaUtils from "../../../utils/ToponomasticaUtils";
import Utils from "../../../utils/Utils";
import {
    FieldsRow,
    RoleBasedAutocomplete,
    RoleBasedInput,
    RoleBasedPhoneInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import {v4 as uuid} from "uuid";
import {defaultTipoOccupazione} from "../supportoSocialeAssistenteNonFamiliare/SupportoSocialeAssistenteNonFamiliare";

const propTypes = {
    datiParentiForm: PropTypes.any,
    onChangeParentiTenutiAgliAlimenti: PropTypes.func,
    onValidaSezione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

const defaultParenteTenutoAgliAlimenti = {
    id: null,
    cognome: null,
    nome: null,
    indirizzo: null,
    numero: null,
    comune: "",
    provincia: "",
    telefono: null,
    tipoDiRelazioneConAssistito: null,
    tipoOccupazione: [],
    numeroGiorniSettimanaAttuale: null,
    numeroGiorniSettimanaFuturo: null
}

export default class ParentiTenutiAgliAlimenti extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.datiParentiForm)
    }

    listaComuni = [];
    listaProvince = [];

    componentDidMount() {
        this.listaComuni = ToponomasticaUtils.getComuni();
        this.listaProvince = ToponomasticaUtils.getProvince();
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiParentiForm, this.props.datiParentiForm))
            this.inizializzaForm();
    }

    formatObjectForBackend = () => {
        let dati = _.cloneDeep(this.state.dati);
        // formatto l'oggetto per inviarlo come parametro di input al metodo salvaBozza nel componente padre "CompilaRichiesta"
        dati.parenti = dati.parenti.map(parente => {
            let parenteMap = parente;
            this.getCodice(parenteMap);
            return _.mapValues(parenteMap, el => el === null || el === undefined || el === "" ? null : el);
        });
        this.props.onChangeParentiTenutiAgliAlimenti("parentiTenutiAgliAlimenti", dati);
    }

    validaSezione = () => {
        let dati = _.cloneDeep(this.state.dati);
        let isTelefonoValido;

        // controllo la validità dei vari campi di ogni elemetnto della lista parentiTenutiAgliAlimenti e se ci sono campi obbligatori non valorizzati li conto
        if (dati.presenzaParentiTenutiAgliAlimenti === true && dati.parenti.length > 0) {
            isTelefonoValido = dati.parenti.every(parente => Utils.isStringEmptyOrNullOrUndefined(parente.telefono) || Utils.isValidTelefono(parente.telefono));
        } else {
            isTelefonoValido = true;
        }

        this.props.onValidaSezione("isParenteTenutoAgliAlimentiValido", isTelefonoValido);
    }

    getCodice = (parente) => {
        parente.comune = !Utils.isObjectNull(parente.comune) ? parente.comune.codice : "";
        parente.provincia = !Utils.isObjectNull(parente.provincia) ? parente.provincia.codice : "";
    }

    inizializzaForm = () => {
        let dati = _.cloneDeep(this.props.datiParentiForm);

        if (dati.presenzaParentiTenutiAgliAlimenti === true && dati.parenti.length > 0) {
            dati.parenti = dati.parenti.map(parente => {
                let parenteMap = parente;
                this.inizializzaDatiComuniProvince(parenteMap);
                parenteMap.tipoOccupazione = parente.tipoOccupazione;
                return parenteMap;
            });
        }

        this.setState({dati: dati}, () => this.validaSezione());
    }

    inizializzaDatiComuniProvince = (parente) => {
        let comune = this.listaComuni.find(c => c.codice === parente.comune);
        parente.comune = comune ? this.getOggettoComune(comune) : comune;

        if (parente.provincia) {
            let provincia = this.listaProvince.find(p => p.codice === parente.comune?.codiceProvincia);
            parente.provincia = provincia ? this.getOggettoProvincia(provincia) : provincia;
        }
    }

    handleAggiornaForm = (field, elem, index) => {
        let dati = _.cloneDeep(this.state.dati);
        let parente = dati.parenti[index];

        if (field === "comune" || field === "provincia") {
            let isOpzioneAutocompleteValida = !Utils.isObjectNull(elem.target.value) && !Utils.isObjectNull(elem.target.value["codice"]);
            if (isOpzioneAutocompleteValida) {
                elem = elem.target.value;
                if (field === "comune") {
                    let provincia = this.listaProvince.find(p => p.codice === elem.codice.substring(0, 3));
                    parente.provincia = this.getOggettoProvincia(provincia);
                }
            } else {
                elem = null;
            }
        } else if (field === "tipoOccupazione" && (elem === "04" || elem === null)) {
            parente.numeroGiorniSettimanaAttuale = null;
        }

        parente[field] = elem;
        dati.parenti[index] = parente;
        this.setState({dati: dati}, () => {
            this.formatObjectForBackend();
            this.validaSezione();
        });
    }

    getOggettoComune = (comune) => {
        return {
            codice: comune.codice,
            codiceProvincia: comune.codiceProvincia,
            descrizione: comune.descrizione
        };
    }

    getOggettoProvincia = (provincia) => {
        return {
            codice: provincia.codice,
            codiceRegione: provincia.codiceRegione,
            descrizione: provincia.descrizione
        };
    }

    addNewParenteTenutoAgliAlimenti = () => {
        let dati = _.cloneDeep(this.state.dati);
        let parente = _.cloneDeep(defaultParenteTenutoAgliAlimenti);
        parente.id = uuid();
        dati.parenti.push(parente);
        this.setState({dati: dati}, () => {
            this.formatObjectForBackend();
            this.validaSezione();
        });
    }

    removeParenteTenutoAgliAlimenti = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.parenti.splice(index, 1);
        this.setState({dati: dati}, () => {
            if (this.state.dati.parenti.length === 0) {
                let cloneDati = _.cloneDeep(this.state.dati);
                cloneDati.presenzaParentiTenutiAgliAlimenti = false;
                this.setState({dati: cloneDati}, () => {
                    this.formatObjectForBackend();
                    this.validaSezione();
                });
            } else {
                this.formatObjectForBackend();
                this.validaSezione();
            }
        });
    }

    handleAggiornaTipoOccupazione = (elem, indexListaTipoOccupazione, indexParente) => {
        let dati = _.cloneDeep(this.state.dati);
        let current = dati.parenti[indexParente];
        current.tipoOccupazione[indexListaTipoOccupazione].codice = elem;
        dati.parenti[indexParente] = current;

        this.setState({dati:dati}, () => {this.formatObjectForBackend(); this.validaSezione()});
    }

    addNewTipoOccupazione = (indexParente) => {
        let dati = _.cloneDeep(this.state.dati);
        let current = dati.parenti[indexParente];
        let defaultTipoOccupazioneClone = _.cloneDeep(defaultTipoOccupazione);
        defaultTipoOccupazioneClone.id = uuid();
        current.tipoOccupazione.push(defaultTipoOccupazioneClone);
        dati.parenti[indexParente] = current;

        this.setState({dati}, () => {this.formatObjectForBackend(); this.validaSezione()});
    }

    removeTipoOccupazione = (indexListaTipoOccupazione, indexParente) => {
        let dati = _.cloneDeep(this.state.dati);
        let current = dati.parenti[indexParente];
        current.tipoOccupazione.splice(indexListaTipoOccupazione, 1);
        dati.parenti[indexParente] = current;

        this.setState({dati: dati}, () => {this.formatObjectForBackend(); this.validaSezione()});
    }

    renderTipoOccupazione  = (tipoOccupazione, indexListaOccupazione, indexListaAssistentiNonFamiliari) => {
      return <RoleBasedSelect
        fieldId={'OCCUP_PAR.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
        pageState={this.props.pageState}
            id={"selectTipoOccupazione"}
            className={"selectTipoOccupazione"}
            options={oggettiUtili.occupazioneAssistenteNonFamiliare}
            value={tipoOccupazione.codice}
            onChange={(elem) => this.handleAggiornaTipoOccupazione(elem, indexListaOccupazione, indexListaAssistentiNonFamiliari)}
            isSearchable={false}
            fieldLabel={'Si occupa di'}
            handleOnlyValue={true}
            field={"codice"}
            parentJsonPath={"situazioneSociale.parentiTenutiAgliAlimenti"}
            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            disableDoubleInput={this.props.disableDoubleInput}
        />;
    }

    renderNewParenteTenutoAgliAlimenti = (parente, index) => {
        let comuniSuggestions = !Utils.isObjectNull(parente.provincia?.codice)
            ? this.listaComuni.filter(comune => comune.codiceProvincia === parente.provincia.codice)
            : this.listaComuni;
        comuniSuggestions = comuniSuggestions.map(comune => this.getOggettoComune(comune));

        let provinceSuggestions = !Utils.isObjectNull(parente.comune?.codice)
            ? this.listaProvince.filter(provincia => provincia.codice === parente.comune.codice.substring(0, 3))
            : this.listaProvince;
        provinceSuggestions = provinceSuggestions.map(provincia => this.getOggettoProvincia(provincia));

        return <Fragment key={index}>
            <FieldsRow>
                <RoleBasedInput
                    fieldId={'COGN_PAR.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    placeholder="Inserisci cognome"
                    field="cognome"
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id="inputCognomeParenteTenutoAgliAlimenti"
                    value={parente.cognome}
                    fieldLabel={"Cognome"}
                    parentJsonPath={"situazioneSociale.parentiTenutiAgliAlimenti"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedInput
                    fieldId={'NOME_PAR.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    placeholder="Inserisci nome"
                    field="nome"
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id="inputNomeParenteTenutoAgliAlimenti"
                    value={parente.nome}
                    fieldLabel={"Nome"}
                    parentJsonPath={"situazioneSociale.parentiTenutiAgliAlimenti"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedInput
                    fieldId={'INDIR_PAR.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    placeholder="Inserisci indirizzo"
                    field="indirizzo"
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id="inputIndirizzoParenteTenutoAgliAlimenti"
                    value={parente.indirizzo}
                    fieldLabel={"Indirizzo"}
                    parentJsonPath={"situazioneSociale.parentiTenutiAgliAlimenti"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedInput
                    fieldId={"NUMEROCIVICO_PAR.RELAZIONIFAMILIARI.SITUAZIONESOCIALE"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci numero civico"}
                    field={"numero"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id={"inputNumeroCivico"}
                    value={parente.numero}
                    fieldLabel={'N°'}
                    parentJsonPath={"situazioneSociale.parentiTenutiAgliAlimenti"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
            <FieldsRow>
                <RoleBasedAutocomplete
                    fieldId={'COM_PAR.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    id={"autocompleteComuneParenteTenutoAgliAlimenti"}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem, index)}
                    value={parente.comune ?? ""}
                    field={"comune"}
                    placeholder={"Inserisci comune"}
                    suggestions={comuniSuggestions}
                    noSuggestions={"Errore: Comune non esistente"}
                    fieldLabel={"Comune"}
                    parentJsonPath={"situazioneSociale.parentiTenutiAgliAlimenti"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedAutocomplete
                    fieldId={'PROV_PAR.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    id={"autocompleteProvinciaParenteTenutoAgliAlimenti"}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem, index)}
                    value={parente.provincia ?? ""}
                    field={"provincia"}
                    placeholder={"Inserisci provincia"}
                    suggestions={provinceSuggestions}
                    noSuggestions={"Errore: Provincia non relativa al comune inserito"}
                    fieldLabel={"Provincia"}
                    parentJsonPath={"situazioneSociale.parentiTenutiAgliAlimenti"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedPhoneInput
                    fieldId={'TEL_PAR.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci numero di telefono"}
                    field={"telefono"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id={"inputNumeroTelefonoParenteTenutoAgliAlimenti"}
                    value={parente.telefono}
                    fieldLabel={"Telefono"}
                    parentJsonPath={"situazioneSociale.parentiTenutiAgliAlimenti"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'TIPO_REL_ASS_PAR.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    id={"selectTipoRelazioneConAssistito"}
                    className={"selectTipoRelazioneConAssistito"}
                    options={oggettiUtili.tipoRelazioneConAssistitoParenteTenutoAgliAlimenti}
                    value={parente.tipoDiRelazioneConAssistito}
                    onChange={(elem) => this.handleAggiornaForm("tipoDiRelazioneConAssistito", elem, index)}
                    isSearchable={false}
                    fieldLabel={"Tipo di relazione con assistito"}
                    handleOnlyValue={true}
                    field={"tipoDiRelazioneConAssistito"}
                    parentJsonPath={"situazioneSociale.parentiTenutiAgliAlimenti"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <SectionList
                    field={'tipoOccupazione'}
                    title={'Si occupa di'}
                    data={parente.tipoOccupazione}
                    renderSection={(e, i) => this.renderTipoOccupazione(e, i, index)}
                    addNewSectionCallback={() => this.addNewTipoOccupazione(index)}
                    removeSectionCallback={(i) => this.removeTipoOccupazione(i, index)}
                    pageState={this.props.pageState}
                    keyFieldId={'OCCUP_PAR.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    indentOffset={0}
                    addButtonVisibilityHandler={parente.tipoOccupazione?.length === 0
                    || parente.tipoOccupazione?.every(el => !Utils.isObjectNull(el?.codice))}
                    parentJsonPath={"datiSituazioneSociale.parentiTenutiAgliAlimenti[?(@.id == '" + parente?.id + "')].tipoOccupazione"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                {parente?.tipoOccupazione.some(tc => tc.codice !== "04") &&
                    <RoleBasedSelect
                        fieldId={'N_GG_SETT_ATT.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        id={"selectNumeroGiorniSettimanaAttuale"}
                        className={"selectNumeroGiorniSettimanaAttuale"}
                        options={oggettiUtili.giorniSettimanaAttuale}
                        value={parente.numeroGiorniSettimanaAttuale}
                        onChange={(elem) => this.handleAggiornaForm("numeroGiorniSettimanaAttuale", elem, index)}
                        isSearchable={false}
                        handleOnlyValue={true}
                        fieldLabel={"N° gg/settimana attuale"}
                        field={"numeroGiorniSettimanaAttuale"}
                        parentJsonPath={"situazioneSociale.parentiTenutiAgliAlimenti"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />}

                <RoleBasedSelect
                    fieldId={'N_GG_SETT_FUT.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    id={"selectNumeroGiorniSettimanaFuturo"}
                    className={"selectNumeroGiorniSettimanaFuturo"}
                    options={oggettiUtili.giorniSettimanaFutura}
                    value={parente.numeroGiorniSettimanaFuturo}
                    onChange={(elem) => this.handleAggiornaForm("numeroGiorniSettimanaFuturo", elem, index)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldLabel={"N° gg/settimana futuro"}
                    field={"numeroGiorniSettimanaFuturo"}
                    parentJsonPath={"situazioneSociale.parentiTenutiAgliAlimenti"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
        </Fragment>
    }

    renderBodyParentiTenutiAgliAlimenti = () => {
        let dati = _.cloneDeep(this.state.dati);

        let flag = oggettiUtili.rispostaBreve.find(el => el.value === this.state.dati.presenzaParentiTenutiAgliAlimenti);
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PAR_TENUTI_ALIM.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        id={"selectParentiTenutiAgliAlimenti"}
                        className={"selectParentiTenutiAgliAlimenti"}
                        options={oggettiUtili.rispostaBreve}
                        value={flag}
                        onChange={(elem) => {
                            let cloneDati = _.cloneDeep(this.state.dati);
                            cloneDati.presenzaParentiTenutiAgliAlimenti = elem.value;
                            cloneDati.parenti = elem.value ? cloneDati.parenti : [];
                            this.setState({dati: cloneDati}, () => {
                                if (this.state.dati.parenti.length === 0) {
                                    this.addNewParenteTenutoAgliAlimenti();
                                } else {
                                    this.formatObjectForBackend();
                                    this.validaSezione();
                                }
                            });
                        }}
                        isSearchable={false}
                        fieldLabel={'Parenti tenuti agli alimenti'}
                        hint={"(art 433c.c: coniuge, figli e discendenti prossimi, genitori e ascendenti prossimi, generi e nuore, suoceri, fratelli e sorelle)"}
                        field={"presenzaParentiTenutiAgliAlimenti"}
                        parentJsonPath={"situazioneSociale.parentiTenutiAgliAlimenti"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
                {dati.presenzaParentiTenutiAgliAlimenti &&
                <SectionList
                    addNewSectionCallback={this.addNewParenteTenutoAgliAlimenti}
                    removeSectionCallback={this.removeParenteTenutoAgliAlimenti}
                    renderSection={this.renderNewParenteTenutoAgliAlimenti}
                    data={dati.parenti}
                    title={'Parente tenuto agli alimenti'}
                    addButtonVisibilityHandler={dati.parenti?.length === 0 || dati.parenti?.every(parente => !Utils.isObjectNull(parente))}
                    field={'parenti'}
                    keyFieldId={"COGN_PAR.RELAZIONIFAMILIARI.SITUAZIONESOCIALE"}
                    pageState={this.props.pageState}
                    parentJsonPath={"situazioneSociale.parentiTenutiAgliAlimenti.parenti"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />}
            </>
        )
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"parentiTenutiAgliAlimenti"}
                title={"Parenti tenuti agli alimenti"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["situazioneSociale.parentiTenutiAgliAlimenti.parenti"],
                    [defaultParenteTenutoAgliAlimenti]
                )}
                accordionReadOnly={!!this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodyParentiTenutiAgliAlimenti()}</AccordionSezioneRichiesta>
        );
    }
}

ParentiTenutiAgliAlimenti.propTypes = propTypes;
