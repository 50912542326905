import React, {Component, Fragment} from "react";
import RichiestaADIService from "../../service/RichiestaADIService";
import Utils from "../../utils/Utils";
import OggettiUtili from "../../utils/dataset/OggettiUtili.json";
import Table from "../generalComponents/table/Table";
import CheckBox from "../generalComponents/checkbox/CheckBox";
import {Button} from "web-client-archetype";
import {Redirect} from "react-router-dom";
import routepath from "../../utils/route/route-path.json";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import ModalHelper from "../modale/ModalHelper";
import * as _ from "lodash";
import {Container} from "reactstrap";
import PropTypes from "prop-types";
import enumAzioniUtente from "../../enum/enumAzioniUtente"
import {SessioneUtente} from "web-client-archetype";
import enumFirma from "../../enum/enumFirma";
import styles from "../firma/tabellaRichiesteDaFirmare/TabellaRichiesteDaFirmare.module.css";

const propTypes = {
    profileAction: PropTypes.bool,
    name: PropTypes.string,
}

const COLS_SUBENTRO_RICHIESTE_MULTIPLE = [
    {key: "nomeCognomeAssistito", name: "Nome e cognome", sortDirection: null},
    {key: "codiceFiscaleAssistito", name: "Codice fiscale  dell'assistito", sortDirection: null},
    {key: "dataRichiesta", name: "Data della richiesta", sortDirection: null},
    {key: "priorita", name: "Priorità della richiesta", sortDirection: null},
    {key: "medicoRichiedente", name: "Medico richiedente", sortDirection: null},
    {key: "selezioneSubentro", name: "", sortDirection: null, tipo: "checkbox", classContainer: "text-center pt-2"}
];

export default class SubentroRichiesteMultiple extends Component {

    state = {
        cols: COLS_SUBENTRO_RICHIESTE_MULTIPLE,
        rows: [],
        richiesta: null,
        sintesiRichieste: null,
        richiesteSelezionate: [],
        defaultLimit: 15,
        limit: 15,
        totalElements: null,
        isConferma: false,
        successo: null,
        gotoHome: false
    }

    componentDidMount() {
        this.recuperoRichieste(this.state.limit);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevState.sintesiRichieste, this.state.sintesiRichieste)) {
            this.inizializzaTabella(this.state.sintesiRichieste);
        }
    }

    recuperoRichieste(limit) {
        let codiceFiscaleSubentrante = null;
        if(this.props.name === enumAzioniUtente.menu.SUBENTRO_MULTIPLO){
            codiceFiscaleSubentrante = SessioneUtente.getInstance().idUtente;
        }
        RichiestaADIService.subentroPresaInCarico(limit, codiceFiscaleSubentrante).subscribe((res) => {
            this.setState({
                sintesiRichieste: res?.data?.elementi,
                totalElements: res?.data?.elementiTotali
            }, () => this.inizializzaTabella(this.state.sintesiRichieste));
        })
    }

    confermaSubentroRichiesteMultiple(listaRichieste) {
        RichiestaADIService.presaInCaricoMultipla(listaRichieste).subscribe((res) => {
            setTimeout(() => {
                if (res?.msg === "ERRORE") {
                    Utils.toasterFunction({
                        status: res?.data?.codice ? res?.data?.codice : res?.status,
                        type: "critical",
                        text: res?.data?.errore ? res?.data?.errore : res?.data?.message
                    });
                } else {
                    this.setState({isConferma: false, successo: true});
                }
            }, 500)
        });

    }

    inizializzaTabella(sintesiRichieste) {
        if (sintesiRichieste) {
            this.setState({richiesteSelezionate: []}, () => this.creaRigheTabellaSubentroRichiesteMultiple(sintesiRichieste));
        }
    }

    handleChanges(richiesta, checked) {
        let richiesteSelezionate = _.cloneDeep(this.state.richiesteSelezionate);

        if (checked) {
            richiesteSelezionate.push(richiesta.id);
        } else {
            _.pull(richiesteSelezionate, richiesta.id);
        }

        let rows = _.cloneDeep(this.state.rows);
        let index = _.findIndex(rows, row => row.id === richiesta.id);
        rows[index].selezioneSubentro = [this.renderSelezioneSubentro(richiesta, this.isChecked(richiesteSelezionate, richiesta.id))];

        this.setState({richiesteSelezionate: richiesteSelezionate, rows: rows});
    }

    isChecked = (richiesteSelezionate, id) => {
        return _.some(richiesteSelezionate, (idRichiesta) => idRichiesta === id)
    }

    renderSelezioneSubentro = (richiesta, checked) => {
        return (
            <Fragment>
                <CheckBox
                    id={"checkboxSubentro" + richiesta.id}
                    disabled={false}
                    checked={checked}
                    onChange={(_field, elem) => this.handleChanges(richiesta, elem.target.checked)}
                />
            </Fragment>
        )
    }

    creaRigheTabellaSubentroRichiesteMultiple = (listaRichieste) => {
        let righe = [];
        const { richiesteSelezionate, cols, rows} = this.state;

        _.forEach(listaRichieste, richiesta => {
            let riga = {};

            let priorita = _.find(OggettiUtili.livelloPrioritaRichiesta, (elem) => {
                return elem.value === richiesta?.livelloPriorita
            });

            riga.id = richiesta?.id;
            riga.nomeCognomeAssistito = _.join([richiesta?.assistito?.nome, richiesta?.assistito?.cognome], " ");
            riga.codiceFiscaleAssistito = richiesta?.assistito?.identificativoUtente;
            riga.dataRichiesta = Utils.transformDateAndTimeForFrontend(richiesta?.timestampCreazione);
            riga.priorita = priorita?.label;
            riga.medicoRichiedente = _.join([richiesta?.richiedente.nome, richiesta?.richiedente.cognome], " ");
            riga.checkBox = {hangleChange: (row, value) => this.aggiornaCheck(row, value), checkValue: false,  disabled:false};


            righe.push(riga);
        })

        let selezionaTuttoIsCheck = righe?.length === richiesteSelezionate?.length;

        this.setState({rows: righe,
            cols: cols.map(a => {
                let title = !selezionaTuttoIsCheck ? "Seleziona tutte" : "Deseleziona tutte"
                if (a.key == "selezioneSubentro" && righe.length) return {...a, name: (<span onClick={() => this.selezionaTuttePerFirma()} className={styles.linkSelezionaTutte}>{title}</span>)}
                else return a;
            })});
    }

    selezionaTuttePerFirma  = () => {
        const { richiesteSelezionate, cols, rows} = this.state;
        let rowsState = _.cloneDeep(rows);
        let colsState = _.cloneDeep(cols);
        let selezionaTuttoIsCheck = rowsState?.length === richiesteSelezionate?.length;

        this.setState({
            richiesteSelezionate: !selezionaTuttoIsCheck ? rowsState.map(row => row.id) : [],
            rows: rowsState.map(row => {
                return {...row, checkBox:{...row.checkBox, checkValue: !selezionaTuttoIsCheck}}
            }),
            cols: colsState.map(a => {
                let title = selezionaTuttoIsCheck ? "Seleziona tutte" : "Deseleziona tutte"
                if (a.key == "selezioneSubentro") return {...a, name: (<span onClick={() => this.selezionaTuttePerFirma()} className={styles.linkSelezionaTutte}>{title}</span>)}
                else return a;
            })
        });

    }

    aggiornaCheck = (row, checked) => {
        let richiesteSelezionate = _.cloneDeep(this.state.richiesteSelezionate);
        const { cols, rows} = this.state;
        let rowsState = _.cloneDeep(rows);
        let colsState = _.cloneDeep(cols);

        if (checked) {
            richiesteSelezionate.push(row.id);
        } else {
            _.pull(richiesteSelezionate, row.id);
        }

        let selezionaTuttoIsCheck = rowsState?.length === richiesteSelezionate?.length;
        this.setState({
            richiesteSelezionate: richiesteSelezionate,
            rows: rowsState.map(r => {
                if(r.id === row.id) return row
                else return r
            }),
            cols: colsState.map(a => {
                let title = !selezionaTuttoIsCheck ? "Seleziona tutte" : "Deseleziona tutte"
                if (a.key == "selezioneSubentro") return {...a, name: (<span onClick={() => this.selezionaTuttePerFirma()} className={styles.linkSelezionaTutte}>{title}</span>)}
                else return a;
            })
        });
    }

    incrementaNumeroRichieste = () => {
        let newLimit = this.state.limit + 15;
        this.setState({limit: newLimit});
        this.recuperoRichieste(newLimit);
    }

    renderModaleConferma = () => {
        let richiesteSelezionate = _.cloneDeep(this.state.richiesteSelezionate);

        let body = <div>
            <span>Stai per procedere con {this.props.name}.</span>
            <br/>
            <span className="font-weight-bold">Vuoi confermare l’operazione?</span>
        </div>;

        let footer = (
            <Fragment>
                <Button
                    id={"conferma"}
                    text={"Conferma"}
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => this.confermaSubentroRichiesteMultiple(richiesteSelezionate)}
                />
                <Button
                    id={"annulla"}
                    text={"Annulla"}
                    dataDismiss={"modal"}
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    onClick={() => this.setState({isConferma: false})}
                />
            </Fragment>
        );

        return (
            <ModalHelper id={"modaleConfermaFirma"}
                         title={"Attenzione!"}
                         body={body}
                         footer={footer}
                         titleClassName={"modal-header d-inline text-center backgroundOrange"}
                         containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                         contentClassName={"modal-content"}
                         footerClassName={"modal-footer justify-content-around"}
                         bodyClassName={"modal-body align-items-center d-flex justify-content-center text-center"}
            />)
    }

    renderModaleSuccesso() {
        let body = <>
            <div>
                <span>La richiesta di {this.props.name} è stata correttamente inoltrata.</span>
            </div>
        </>;
        let footer = <>
            <div className={"col-12 text-center"}>
                <Button
                    id={"TornaHome"}
                    text={"Torna a home"}
                    className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                    dataDismiss={"modal"}
                    onClick={() => this.setState({successo: false, gotoHome: true})}
                />
            </div>
        </>;

        return <ModalHelper id={"modaleSuccesso"}
            title={"Operazione completata"}
            body={body}
            footer={footer}
            titleClassName={"modal-header d-inline text-center backgroundGreen"}
            containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
            contentClassName={"modal-content"}
            footerClassName={"modal-footer justify-content-around"}
            bodyClassName={"modal-body row align-items-center justify-content-around text-center f-weight-600"}
        />;
    }

    mostraAltri() {
        return this.state.rows?.length < this.state.totalElements ?
            <Fragment>
                <div className="row justify-content-center mb-3">
                    <Button
                        id={"mostraAltri"}
                        text="Mostra altri"
                        onClick={this.incrementaNumeroRichieste}
                        className="btn btn-adi btnGreenOutline"/>
                </div>
            </Fragment>
            : null;
    }

    render() {
        if (this.state.gotoHome) return <Redirect to={{pathname: routepath.home}}/>;

        return (
            <Fragment>
                {this.state.isConferma ? this.renderModaleConferma() : <Fragment/>}
                {this.state.successo ? this.renderModaleSuccesso() : <Fragment/>}

                <Breadcrumb path={['Home', this.props.name]} profileAction={this.props.profileAction}/>

                <Container fluid className={"px-5 position-relative"}>
                    <div className={"d-flex align-items-center pt-4"}>
                        <div className={"col-6 position-relative p-0"}>
                            <h1>{this.props.name}</h1>
                        </div>
                        {this.state.richiesteSelezionate?.length > 0 && <div className={"col-6 text-right p-0"}>
                            <Button
                                id={"confermaSubentroRichiesteMultipleBtn"}
                                text={`${this.props.name} ${this.state.richiesteSelezionate?.length > 0 ? '(' + this.state.richiesteSelezionate.length + ')' : ''}`.trim()}
                                className={"btn btn-adi btnGreenOutline"}
                                onClick={() => this.setState({isConferma: true})}
                            />
                        </div>}
                    </div>
                    <div className={"mb-5"}>
                        <div>
                            <Table
                                cols={this.state.cols}
                                rows={this.state.rows}
                                emptyMsg={"Non sono disponibili richieste"}
                                lengthEditable={false}
                                totalElements={this.state.totalElements}
                                bigTable={true}
                                homePage={false}
                                tableLenght={this.state.rows?.length}
                                nascondiPaginazione={true}
                                internalScroll={false}
                                //tableHeightForInternalScroll={'55vh'}
                                keyColsAlignStart={this.state.cols.filter(c => !!c.key && c.key !== "selezioneSubentro").map(c => c.key)}
                            />
                        </div>
                    </div>
                    {this.mostraAltri()}
                </Container>
            </Fragment>
        )
    }
}

SubentroRichiesteMultiple.propTypes = propTypes;
