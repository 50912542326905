import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {Button, SessioneUtente} from "web-client-archetype";
import enumPaginaCompila from "../../enum/enumPaginaCompila.json";
import enumRichiesta from "../../enum/enumRichiesta.json";
import routepath from "../../utils/route/route-path.json";
import {Redirect, Link} from "react-router-dom";
import styles from "./StatoValutazioneEComponentiDelTeam.module.css";
import $ from "jquery";
import SchedaProfessionista, {getDescrizioneDisciplina} from "./schedaProfessionista/SchedaProfessionista";
import TabellaStatoValutazione from "./tabellaStatoValutazione/TabellaStatoValutazione";
import enumsUtente from "../../enum/enumsUtente.json";
import * as _ from "lodash";
import AttivazioneFiguraProfessionale, {disciplinaSelect} from "./modaleAttivazioneFiguraProfessionale/AttivazioneFiguraProfessionale";
import ModalHelper from "../modale/ModalHelper";
import WebSocketHelper from "../../websocket/WebSocketHelper";
import AuthUtils from "../../utils/AuthUtils";
import RichiestaADIService from "../../service/RichiestaADIService";
import Utils, {setFasciaBottoniStyle} from "../../utils/Utils";
import moment from "moment";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import {mostraSpinner, nascondiSpinner} from "../../App";
import {getValutazionePrecedente} from "../home/HomeUtils";
import {v4 as uuid} from "uuid";
import classificazione from "../../utils/dataset/richiestaData/classificazione";
import MenuAccordion from "../menuAccordion/MenuAccordion";
import {showMessageAulssDistrettoDifferences} from "../inserimentoRichiesta/InserimentoRichiesta";
import ButtonsBoxStick from "../buttonsBoxStick/buttonsBoxStick";
import RadioButton from "../generalComponents/radiobutton/RadioButton";
import PopoverReact from "../popover/Popover";
import * as BsIcon from "react-bootstrap-icons";
import OggettiUtili from "../../utils/dataset/OggettiUtili"

const propTypes = {
    richiesta: PropTypes.object,
    pageState: PropTypes.string,
    showAllMenuItems: PropTypes.bool,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    richiestaMedico: PropTypes.object,
    persone: PropTypes.object,
    showOnlyRequiredFields: PropTypes.bool,
    containsOnlyRequiredFields: PropTypes.bool,
    previousPageOnlyRequiredFields: PropTypes.bool,
    showOptionalFieldsCount: PropTypes.bool,
    showMultiCompilationPages: PropTypes.bool
}

export const getDistrettoSettings = (settings) => {
    let settingFormalizzatore = _.find(settings, setting => setting.profilo === enumsUtente.profiliUtente.medicoFormalizzatore);
    let settingCoordinatore = _.find(settings, setting => setting.profilo === enumsUtente.profiliUtente.coordinatore);
    if (settingFormalizzatore) {
        return settingFormalizzatore.distretto;
    } else if (settingCoordinatore) {
        return settingCoordinatore.distretto;
    } else {
        let settingTrovato = _.find(settings, setting => !Utils.isStringEmptyOrNullOrUndefined(setting?.distretto));
        return settingTrovato?.distretto || null;
    }
}

export const getSettingsProfessionista = (settings) => {
    let settingFormalizzatore = _.find(settings, setting => setting.profilo === enumsUtente.profiliUtente.medicoFormalizzatore);
    let settingCoordinatore = _.find(settings, setting => setting.profilo === enumsUtente.profiliUtente.coordinatore);
    let settingDirRefAssSoc = _.find(settings, setting => setting.profilo === enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali);
    if (settingFormalizzatore) {
        return settingFormalizzatore;
    } else if (settingCoordinatore) {
        return settingCoordinatore;
    } else if (settingDirRefAssSoc) {
        return settingDirRefAssSoc;
    }
}

export const professionistaDefault = {
    codiceFiscale: null,
    nome: null,
    cognome: null,
    telefono: null,
    cellulare: null,
    email: null,
    ruolo: null
};

const DELEGA_FISIATRA_VALUES = [
    {value: 1, label: "Sì", map: true},
    {value: 0, label: "No", map: false}
]

const MESSAGGIO_BASE_PROFESSIONISTI_RICHIESTI_MMG = "Il Medico richiedente ha richiesto l’attivazione delle seguenti figure per la valutazione:";
const OGGETTI_UTILI_FIGURE_PAI_SPECIALISTA = OggettiUtili.figureProfessionaliPerPAI[5];
const OGGETTI_UTILI_FIGURE_PROFESSIONALI_PER_VALUTAZIONE = OggettiUtili.figureProfessionaliPerValutazione[5];

export default class StatoValutazioneEComponentiDelTeam extends Component {

    state = {
        redirectToIndietro: false,
        isPaginaAttivazioneFiguraProfessionale: false,
        modaleSuccesso: null,
        professionisti: [],
        richiesta: null,
        // state modale conferma
        isModaleConfermaVisibile: false,
        riaperturaTeam: false,
        delegaFisiatra: false,
        richiediInfermiere: false,

        listaCodiciFiscaliUtentiOnline: [],
        isConfermaTeamCompletato: false,
        operazioneCompletata: false,
        infoModalSucesso:"",
        teams: null,
        valutazioni: null,
        professionistaDaSostituire: null,
        professionistaDaInserire: null,
        infoRichiesta: null,
        valoreDelegaFisiatra: null
    }

    pageName;

    componentDidMount() {
        setFasciaBottoniStyle("#fasciaBottoni");
        WebSocketHelper.onMessageListener();
        WebSocketHelper.utenteAutenticatoSubject.subscribe((message) => {
            console.log("Lista Utenti Loggati Stato Valutazione", WebSocketHelper.utentiLoggati);
            this.setState({listaCodiciFiscaliUtentiOnline: _.cloneDeep(WebSocketHelper.utentiLoggati)});
        });
        WebSocketHelper.utenteDisconnessoSubject.subscribe((flag) => {
            if (flag)
                this.setState({listaCodiciFiscaliUtentiOnline: WebSocketHelper.utentiLoggati});
        });

        this.setPageName();

        // prendo richiesta da props
        this.setRichiesta(_.cloneDeep(this.props.richiesta));
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevState.operazioneCompletata !== this.state.operazioneCompletata) &&
            this.state.operazioneCompletata) {
            this.letturaRichiesta();
        }
    }

    setPageName = () => {
        const pathname = window.location.pathname.substring(0, window.location.pathname.lastIndexOf(routepath.stato_valutazione_componenti_team));
        const split = pathname.split('/');
        this.pageName = split[split.length - 1];
    }

    letturaRichiesta = () => {
        mostraSpinner();
        RichiestaADIService.letturaRichiesta(this.state.richiesta.id).subscribe(res => {
            // reset flag
            nascondiSpinner();
            this.setState({operazioneCompletata: false, modaleSuccesso: this.state.infoModalSucesso ? {testo:this.state.infoModalSucesso} : null}, () => {
                this.setRichiesta(res.data);
                // Aggiornamento della richiesta ricercata dopo che faccio Riapro o Riconfermo il Team
                RichiestaADIService.richiestaRicercata = _.cloneDeep(res.data);
            });

        })
    }

    setRichiesta = (richiesta) => {
       this.setState({richiesta: richiesta, teams: richiesta.team}, () => {
           let delega = this.getDelega(enumsUtente.profiliUtente.professionistaValutatore, enumsUtente.ruoli.fisiatra);
            if(delega){
                this.setState({valoreDelegaFisiatra: delega?.valore})
            }
           this.inizializzaValutatori();
        })
    }

    inizializzaValutatori() {
        let professionisti = [];
        let richiesta = _.cloneDeep(this.state.richiesta);

        let setValutatori = () => {
            // medico richiedente
            let professionistaMedicoRichiedente = _.cloneDeep(professionistaDefault);
            let richiedente = richiesta?.richiedente;
            this.getProfessionista(professionistaMedicoRichiedente, richiedente);
            professionisti.push(professionistaMedicoRichiedente);

            if(!Utils.isClassica(richiesta?.tipologia)) {
                // professionista medico ospedaliero
                let professionistaCreazioneMedico = _.cloneDeep(professionistaDefault);
                this.getProfessionista(professionistaCreazioneMedico, richiesta?.professionistaCreazione);
                professionisti.push(professionistaCreazioneMedico);
            }

            // set teams e valutazioni
            let teams = richiesta.team;
            let valutazioni = richiesta.valutazioni;

            // professionisti formalizzatori
            this.trovaProfessionista(teams, professionisti, enumsUtente.profiliUtente.medicoFormalizzatore, enumsUtente.ruoli.mcp);

            // Gestione precedente per Centrale Adi
            /* if(!_.some(professionisti, professionista => professionista?.profilo === enumsUtente.profiliUtente.medicoFormalizzatore && professionista?.codiceFiscale != null) &&
                _.some(richiesta.delega, d => d.profilo === enumsUtente.profiliUtente.centraleAdi && d.valore)){
                    this.trovaProfessionista(teams, professionisti, enumsUtente.profiliUtente.centraleAdi, enumsUtente.ruoli.operatore);
            } */

            if(_.some(richiesta.team, t => t.profilo === enumsUtente.profiliUtente.centraleAdi && (t.ruolo === enumsUtente.ruoli.operatore || t.ruolo === enumsUtente.ruoli.operatoreCurePalliative) && !Utils.isStringEmptyOrNullOrUndefined(t.professionista))){
                this.trovaProfessionista(teams, professionisti, enumsUtente.profiliUtente.centraleAdi);
            }

            // professionisti intermedi coord
            this.trovaProfessionista(teams, professionisti, enumsUtente.profiliUtente.coordinatore, enumsUtente.ruoli.infermiere);
            // professionisti intermedi dir uo
            this.trovaProfessionista(teams, professionisti, enumsUtente.profiliUtente.direttoreUnitaOperativa);
            // professionisti intermedi referente assistenti sociali
            this.trovaProfessionista(teams, professionisti, enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali, enumsUtente.ruoli.assistenteSociale);

            // professionisti valutatori recuperati dalla richiesta
            // se la richiesta è in fase di predialogo i valutatori saranno recuperati dalle valutazioniPreDialogo
            if (valutazioni?.length) {
                let professionistiValutatoriRichiesta = _.map(valutazioni, valutazione => {
                    valutazione.valutatore.isValutatore = true;
                    // passato professionista attivatore a oggetto valutatore
                    valutazione.valutatore.professionistaAttivatore = valutazione.professionistaAttivatore;
                    return valutazione.valutatore
                });
                // non includo medico richiedente
                professionistiValutatoriRichiesta = _.filter(professionistiValutatoriRichiesta, valutatore =>
                    ![professionistaMedicoRichiedente?.codiceFiscale].includes(valutatore.codiceFiscale)
                );
                professionisti.push(...professionistiValutatoriRichiesta);
            }

            this.setState({professionisti: professionisti, teams: teams, valutazioni: valutazioni})
        }

        setValutatori()
    }

    getProfessionista(professionistaMedicoRichiedente, professionista) {
        professionistaMedicoRichiedente.codiceFiscale = professionista?.codiceFiscale;
        professionistaMedicoRichiedente.nome = professionista?.nome;
        professionistaMedicoRichiedente.cognome = professionista?.cognome;
        professionistaMedicoRichiedente.telefono = professionista?.telefono;
        professionistaMedicoRichiedente.cellulare = professionista?.cellulare;
        professionistaMedicoRichiedente.email = professionista?.email;
        professionistaMedicoRichiedente.ruolo = professionista?.ruolo;
        professionistaMedicoRichiedente.profilo = professionista?.profilo;
    }

    trovaProfessionista(teams, professionisti, profilo, ruolo = null) {
        let filterPerProfilo = _.filter(teams, t => t.profilo === profilo);
        if (filterPerProfilo != null) {
            _.forEach(filterPerProfilo, team => {
                let professionistaRichiesta = team.professionista;
                if(professionistaRichiesta != null) {
                    let professionista = _.cloneDeep(professionistaDefault);
                    professionista.codiceFiscale = professionistaRichiesta?.codiceFiscale;
                    professionista.nome = professionistaRichiesta?.nome;
                    professionista.cognome = professionistaRichiesta?.cognome;
                    professionista.telefono = professionistaRichiesta?.telefono;
                    professionista.cellulare = professionistaRichiesta?.cellulare;
                    professionista.email = professionistaRichiesta?.email;
                    professionista.ruolo = ruolo || professionistaRichiesta?.ruolo;
                    professionista.profilo = profilo;
                    professionista.disciplina = professionistaRichiesta?.disciplina;
                    // passato professionista attivatore a oggetto professionista
                    professionista.professionistaAttivatore = team.professionistaAttivatore;
                    professionisti.push(professionista);
                }
            })
        }
    }

    componentWillUnmount() {
        $("#fasciaBottoni").css("border-bottom", "");
    }

    getTeamFormalizzatore = () => {
        return _.find(this.state.teams, (t) => {
            return t.profilo === enumsUtente.profiliUtente.centraleAdi || t.profilo === enumsUtente.profiliUtente.medicoFormalizzatore
        });
    }

    hasUtenteDelega = (richiesta) => {
        return richiesta?.delega?.some(d => d.valore && d.profilo===enumsUtente.profiliUtente.centraleAdi)
    }

    renderButtons = () => {
        let btnList = [
            {
                id: "indietroBtn",
                text: "Indietro",
                className: "btnWhiteOutlineBorderGreen",
                onclickFunction: () => this.setState({redirectToIndietro: true}),
                aling:"start",
                isvisible: true
            },
        ]

        let teamFormalizzatore = this.getTeamFormalizzatore();
        let teamInCarico = _.find(this.state.teams, (t) => {
            return (t.profilo === enumsUtente.profiliUtente.centraleAdi || t.profilo === enumsUtente.profiliUtente.medicoFormalizzatore)
                && t?.professionista?.codiceFiscale === SessioneUtente.getInstance()?.idUtente
        });

        let presenteCoordinatore = _.find(this.state.richiesta?.team, t => t.profilo === enumsUtente.profiliUtente.coordinatore);
        let presenteAdiCentrale = _.find(this.state.richiesta?.team, t => t.profilo === enumsUtente.profiliUtente.centraleAdi);
        let presenteCoordinatoreOrAdiCentrale = presenteCoordinatore || presenteAdiCentrale;

        let isTeamNonConfermato = teamFormalizzatore != null ? Utils.isStringEmptyOrNullOrUndefined(teamFormalizzatore.timestampConferma) : true;
        let isFormalizzatore = ((AuthUtils.hasUtenteCentraleAdi() && this.hasUtenteDelega(this.props.richiesta))|| AuthUtils.hasUtenteRuoloMcp())  && (teamInCarico != null);


        let condizioneRichiestaAggiuntaInfermiere = AuthUtils.hasUtenteMcpFormalizzatoreRichiesta(this.state.richiesta?.team)
            && [enumRichiesta.stato.INVIATA, enumRichiesta.stato.IN_CONFERIMENTO, enumRichiesta.stato.IN_VALUTAZIONE, enumRichiesta.stato.VALUTATA].includes(this.state.richiesta.stato)
            && !presenteCoordinatoreOrAdiCentrale;

        if (condizioneRichiestaAggiuntaInfermiere){
            btnList.push({
                id: "richiestaAggiuntaInfermiere",
                text: "Richiesta aggiunta infermiere",
                className: "btnWhiteOutlineBorderGreen ",
                onclickFunction: () => this.apriModaleConferma(true, false, false, true),
                aling:"center",
                isvisible: true,
            })
        }

        if ((isTeamNonConfermato && isFormalizzatore) && !this.state.isConfermaTeamCompletato ) {
            btnList.push({
                id: "confermaTeamBtn",
                text: "Conferma team di valutazione",
                className: "btnGreenOutline ",
                onclickFunction: () => {
                    if (!Utils.isStringEmptyOrNullOrUndefined(teamFormalizzatore?.timestampRiapertura) && !this.state.riaperturaTeam
                        && !this.state.valutazioni?.map(v => v.timestampInserimento)?.every(v => moment(v) < moment(teamFormalizzatore?.timestampRiapertura))) {
                        this.confermaTeam();
                    } else {
                        this.apriModaleConferma(true, false, false)
                    }
                },
                aling: "right",
                isvisible: true
            })
        }
        else if (!Utils.isStringEmptyOrNullOrUndefined(teamFormalizzatore?.timestampConferma) && isFormalizzatore
            && [enumRichiesta.stato.INVIATA, enumRichiesta.stato.IN_CONFERIMENTO, enumRichiesta.stato.IN_VALUTAZIONE, enumRichiesta.stato.VALUTATA].includes(this.state.richiesta.stato)&& !this.state.richiesta?.timestampRiapertura) {
            btnList.push({
                id: "riapriTeamBtn",
                text: "Riapri team",
                className: "btnGreenOutline",
                onclickFunction: () => this.apriModaleConferma(true, true, false),
                aling: "right",
                isvisible: true
            })
        }

        return (
            <ButtonsBoxStick
                parentClass="row"
                btnList={btnList}
            />
        );
    }

    renderDelegaFisiatra = () => {
        let radioButton = <Fragment/>;
        // se l'utente loggato è un mcp o una centrale adi (con delega) ed ha preso in carico la richiesta,
        // quest'ultima è riabilitativa ed esiste una figura richiesta con ruolo fisiatra
        // allora mostro il radio button della delega al fisiatra
        let isPresaInCaricoFormalizzatore = _.some(this.state?.richiesta?.team,
            t => t.profilo === enumsUtente.profiliUtente.medicoFormalizzatore
                && t.professionista != null && t.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente);
        let isCentraleAdiDelegata = _.some(this.state.richiesta?.delega, d => d.profilo === enumsUtente.profiliUtente.centraleAdi && d.valore);
        let isPresaInCaricoCentraleAdi = isCentraleAdiDelegata && _.some(this.state?.richiesta?.team,
            t => t.profilo === enumsUtente.profiliUtente.centraleAdi
                && t.professionista != null && t.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente);
        let teamDirettoreUO = _.find(this.state?.richiesta?.team, t => t.profilo === enumsUtente.profiliUtente.direttoreUnitaOperativa)
        let isFiguraRichiestaFisiatraPresente = teamDirettoreUO != null && _.some(teamDirettoreUO.figureRichieste, figuraRichiesta => figuraRichiesta.ruolo === enumsUtente.ruoli.fisiatra);
        let isRiabilitativa = this.state?.richiesta?.classificazione === classificazione.RIABILITATIVA.value;
        let isDelegaFisiatraVisibile = (isPresaInCaricoFormalizzatore || isPresaInCaricoCentraleAdi) && isRiabilitativa && isFiguraRichiestaFisiatraPresente;

        if(isDelegaFisiatraVisibile) {
            let defaultChoice = _.findIndex(DELEGA_FISIATRA_VALUES, value =>
                value.map === this.state.valoreDelegaFisiatra
            )
            let popoverInfo = <PopoverReact
                body={'Selezionando "Sì" viene attribuita la delega di formalizzazione e firma della richiesta al Medico Specialista - Fisiatra nominato dal Direttore UO'}
                text={<Fragment>
                    <BsIcon.InfoCircle color={"#176A65"} size={24}/>
                </Fragment>}
                openOnMouseOver
                position={'right'}
                className={'popover-info'}
                arrowStyle={{}}
                id={`popoverInfo`}
            />;
            let labelSi = <div className={"row"}>
                <div className={"col"}>
                    {DELEGA_FISIATRA_VALUES[0].label}
                </div>
                <div className={"col"}>
                    {popoverInfo}
                </div>
            </div>;
            radioButton = <Fragment>
                <div className={"row mb-5 pl-4 align-items-baseline"}>
                    <div className={"col-auto"}>
                        Vuoi delegare il fisiatra alla formalizzazione e firma della richiesta?
                    </div>
                    <div className={"col-auto"}>
                        <RadioButton
                            id={"radioButtonDelegaFisiatra"}
                            items={[{value: DELEGA_FISIATRA_VALUES[0].value, label: <Fragment>{labelSi}</Fragment>}, DELEGA_FISIATRA_VALUES[1]]}
                            onChange={(value, field) => {this.changeDelegaFisiatra(value, field)}}
                            defaultChoice={defaultChoice}
                            customFormCheckClassName={"form-check mt-3"}
                            showLabel={true}
                            disabled={false}
                        />
                    </div>
                </div>
            </Fragment>;
        }
        return radioButton;
    }

    getDelega = (profilo, ruolo) => {
        let delega = _.find(this.state?.richiesta?.delega, delega => delega.profilo === profilo &&
            delega.ruolo === ruolo);
        return delega;
    }

    changeDelegaFisiatra = (field, value) => {
        let valoreDelegaFisiatra = _.find(DELEGA_FISIATRA_VALUES, valore => valore.value === value)?.map;
        this.setState({valoreDelegaFisiatra: valoreDelegaFisiatra}, () => {
            this.delegaFisiatra(valoreDelegaFisiatra)
        });
    }

    verificaCentraleAdiSenzaDelega(t,richiesta)
    {
        return  t.profilo === enumsUtente.profiliUtente.centraleAdi && richiesta?.delega?.some(delega=> delega.profilo === enumsUtente.profiliUtente.centraleAdi && !delega.valore)
    }

    renderSchedeProfessionista = () => {
        let professionisti = _.cloneDeep(this.state.professionisti);
        let numeroProfessionisti = professionisti?.length;

        let schedeJSX = [];

        if (numeroProfessionisti) {
            let dirUO = _.find(this.state.teams, t=> t.profilo === enumsUtente.profiliUtente.direttoreUnitaOperativa)?.professionista ?? null;
            let refDirAssSoc = _.find(this.state.teams, t=> t.profilo === enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali)?.professionista ?? null;
            let coord = _.find(this.state.teams, t=> t.profilo === enumsUtente.profiliUtente.coordinatore)?.professionista ?? null;
            // si vedranno le schede solo dei professionisti intermedi direttore-uo, ref-assistenti-sociali e dei valutatori (tolte eventuali autoassegnazioni)
            professionisti = _.filter(professionisti, professionista => (!professionista.isValutatore)
                || (professionista.isValutatore && ![dirUO?.codiceFiscale, refDirAssSoc?.codiceFiscale, coord?.codiceFiscale].includes(professionista.codiceFiscale)));
            professionisti.forEach(professionista => {
                schedeJSX.push(<SchedaProfessionista
                    uuidRichiesta={this.props.richiesta?.id}
                    valutatore={professionista}
                    teams={this.state.teams}
                    valutazioni={this.state.valutazioni}
                    professionistaEliminato={()=>{this.operazioneCompletata("L'eliminazione della figura professionale è avvenuta correttamente")}}
                    onClickAggiungiSpecialista={(flag, professionista = null) => {
                        this.setState({isPaginaAttivazioneFiguraProfessionale: flag, professionistaDaSostituire: professionista})
                    }}
                    utentiOnline={this.state.listaCodiciFiscaliUtentiOnline}
                    label={this.getLabelScheda(professionista)}
                    deleghe={this.state.richiesta?.delega}
                />);
            });
        }

        let nSchedeProfessionisteDaAttivare = this.schedeProfessionistaDaAttivare(schedeJSX);

        // aggiungi scheda per attivazione professionista
        this.schedaAttivazioneProfessionista(schedeJSX, nSchedeProfessionisteDaAttivare);

        return (
            <>
                <span className={styles.title.concat(" mb-3 pl-4")}>
                    Team di valutazione multidimensionale: {numeroProfessionisti} Professionisti coinvolti
                </span>
                <div className={"row ml-3 mt-4"} >
                    {schedeJSX}
                </div>
            </>
        )
    }

    schedeProfessionistaDaAttivare(schedeJSX) {
        // INIZIO schede attesa conferma per professionisti intermedi
        let nSchedeProfessionisteDaAttivare = 0;
        let isAttivazioneFiguraProfessionaleCentraleAdi = AuthUtils.hasUtenteCentraleAdi() && this.isUtenteInTeam(enumsUtente.profiliUtente.centraleAdi) && !Utils.isFasePreDialogo(this.state.richiesta?.tipologia, this.state.richiesta?.stato);

        let teamFormalizzatore = this.getTeamFormalizzatore();
        let isTeamFormalizzatoreConfermato = !Utils.isStringEmptyOrNullOrUndefined(teamFormalizzatore?.timestampConferma);

        // si visualizzerà la scheda per l'attesa di attivazione di un infermiere
        // solo quando è presente un coordinatore o una centrale adi senza delega nei team
        // e il team non è stato confermato da parte del coord o dal mcp
        if (this.state.teams?.some(t => ([enumsUtente.profiliUtente.coordinatore].includes(t?.profilo) || this.verificaCentraleAdiSenzaDelega(t, this.state.richiesta)) && t?.timestampConferma == null)) {
            let scheda;
            let isAttivazioneFiguraProfessionaleCoordinatore = AuthUtils.hasUtenteProfiloCoordinatore() && this.isUtenteInTeam(enumsUtente.profiliUtente.coordinatore);
            let hasCentraleAzioneInserimentoInfermiere = this.state.richiesta?.delega?.some(d => d.profilo === enumsUtente.profiliUtente.centraleAdi && !d.valore) && this.isUtenteInTeam(enumsUtente.profiliUtente.centraleAdi);
            let isTeamConfermato = this.isTeamProfessionistaIntermedioConfermato(enumsUtente.profiliUtente.coordinatore) && isTeamFormalizzatoreConfermato;
            let isAttivazioneProfessionista = (isAttivazioneFiguraProfessionaleCoordinatore || hasCentraleAzioneInserimentoInfermiere) && !isTeamConfermato && !this.state.isConfermaTeamCompletato;
            if (isAttivazioneProfessionista) {
                scheda = <SchedaProfessionista
                    attivaFiguraProfessionale={true}
                    onClickAggiungiSpecialista={(flag, professionista) =>
                        this.setState({
                            isPaginaAttivazioneFiguraProfessionale: flag,
                            professionistaDaInserire: professionista
                        })}
                    valutatore={{
                        profilo: enumsUtente.profiliUtente.professionistaValutatore,
                        ruolo: enumsUtente.ruoli.infermiere,
                        isValutatore: true
                    }}
                    attesaConferma={true}
                    deleghe={this.state.richiesta?.delega}
                />
                nSchedeProfessionisteDaAttivare++;
            }
            else {
                // gli utenti con profili diversi da coord o centrale adi senza delega vedranno la scheda con infermiere da attivare
                scheda = <SchedaProfessionista
                    attivaFiguraProfessionale={false}
                    valutatore={{
                        ruolo: enumsUtente.ruoli.infermiere,
                        isValutatore: true
                    }}
                    attesaConferma={true}
                    deleghe={this.state.richiesta?.delega}
                />
            }
            schedeJSX.push(
                scheda
            );
        }

        // per il direttore di uo si visualizza la scheda in attesa per ogni figura con flag aggiunto a false
        let teamsDirettoreUO = _.find(this.state.teams, t => t?.profilo === enumsUtente.profiliUtente.direttoreUnitaOperativa) ?? null;
        if (teamsDirettoreUO != null) {
            let isTeamConfermato = this.isTeamProfessionistaIntermedioConfermato(enumsUtente.profiliUtente.direttoreUnitaOperativa) && isTeamFormalizzatoreConfermato;
            let isAttivazioneFiguraProfessionaleDirettoreUO = AuthUtils.hasUtenteProfiloDirettoreUO() && this.isUtenteInTeam(enumsUtente.profiliUtente.direttoreUnitaOperativa);
            let isAttivazioneProfessionista = isAttivazioneFiguraProfessionaleDirettoreUO && !isTeamConfermato && !this.state.isConfermaTeamCompletato;

            _.forEach(teamsDirettoreUO.figureRichieste, figura => {
                if (!figura.aggiunto) {
                    figura.isValutatore = true;
                    let scheda;
                    if (isAttivazioneProfessionista) {
                        figura.profilo = enumsUtente.profiliUtente.professionistaValutatore;
                        scheda = <SchedaProfessionista
                            attivaFiguraProfessionale={true}
                            onClickAggiungiSpecialista={(flag, professionista) =>
                                this.setState({
                                    isPaginaAttivazioneFiguraProfessionale: flag,
                                    professionistaDaInserire: professionista
                                })}
                            valutatore={figura}
                            label={this.getLabelScheda(figura)}
                            attesaConferma={true}
                            deleghe={this.state.richiesta?.delega}
                        />
                        nSchedeProfessionisteDaAttivare++;
                    } else {
                        // gli utenti con profili diversi da direttore uo vedranno la scheda con la figura richiesta da attivare
                        scheda = <SchedaProfessionista
                            attivaFiguraProfessionale={false}
                            valutatore={figura}
                            label={this.getLabelScheda(figura)}
                            attesaConferma={true}
                            deleghe={this.state.richiesta?.delega}
                        />
                    }
                    schedeJSX.push(scheda);
                }
            });
        }

        // referente dirigente assistenti sociali
        if (this.state.teams?.some(t => t?.profilo === enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali &&
            t?.timestampConferma == null)) {
            let scheda;
            let isAttivazioneFiguraProfessionaleReferenteAssistentiSociali = AuthUtils.hasUtenteProfiloReferenteAssistentiSociali() && this.isUtenteInTeam(enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali);
            let isTeamConfermato = this.isTeamProfessionistaIntermedioConfermato(enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali) && isTeamFormalizzatoreConfermato;
            let isAttivazioneProfessionista = isAttivazioneFiguraProfessionaleReferenteAssistentiSociali && !isTeamConfermato && !this.state.isConfermaTeamCompletato;

            if (isAttivazioneProfessionista) {
                scheda = <SchedaProfessionista
                    attivaFiguraProfessionale={true}
                    onClickAggiungiSpecialista={(flag, professionista) =>
                        this.setState({
                            isPaginaAttivazioneFiguraProfessionale: flag,
                            professionistaDaInserire: professionista
                        })}
                    valutatore={{
                        profilo: enumsUtente.profiliUtente.professionistaValutatore,
                        ruolo: enumsUtente.ruoli.assistenteSociale,
                        isValutatore: true
                    }}
                    attesaConferma={true}
                    deleghe={this.state.richiesta?.delega}
                />
                nSchedeProfessionisteDaAttivare++;
            } else {
                // gli utenti con profili diversi da ref. dir. ass. soc. vedranno la scheda con assistente sociale da attivare
                scheda = <SchedaProfessionista
                    attivaFiguraProfessionale={false}
                    valutatore={{
                        ruolo: enumsUtente.ruoli.assistenteSociale,
                        isValutatore: true
                    }}
                    attesaConferma={true}
                />
            }
            schedeJSX.push(scheda);
        }
        // FINE schede attesa conferma per professionisti intermedi

        return nSchedeProfessionisteDaAttivare;
    }

    isUtenteInTeam = (profilo) => {
        return _.some(this.state.teams, t => SessioneUtente.getInstance().idUtente === t.professionista?.codiceFiscale && t.profilo === profilo);
    }

    getLabelScheda = (professionista) => {
        let label = null;
        // label scheda per fisiatra
        if (professionista.isValutatore &&
            professionista.ruolo === enumsUtente.ruoli.fisiatra &&
            this.state?.richiesta?.classificazione === classificazione.RIABILITATIVA.value) {
            let delegaFisiatra = this.getDelega(enumsUtente.profiliUtente.professionistaValutatore, enumsUtente.ruoli.fisiatra);
            if (delegaFisiatra?.valore) {
                label = "Delegato a formalizzare e firmare la richiesta";
            }
        } else if(professionista.profilo === enumsUtente.profiliUtente.direttoreUnitaOperativa){
            let teamDirettoreUO = _.find(this.state.richiesta?.team, t => t.profilo === enumsUtente.profiliUtente.direttoreUnitaOperativa &&
                t.professionista.codiceFiscale === professionista.codiceFiscale);
            if(teamDirettoreUO){
                let figureRichieste = teamDirettoreUO?.figureRichieste;
                let figureRichiesteString = _.map(figureRichieste, figura => {
                    let disc = getDescrizioneDisciplina(figura.disciplina);
                    let discJSX = disc ? " " + disc : "";
                    return <div className={"row"}><div className={"col"}>{enumsUtente.ruoliFE[figura.ruolo] + discJSX}</div></div>});
                label = <Fragment>
                    <div className={"row"}><div className={"col"}>Incaricato alla nomina del professionista </div></div>
                    {figureRichiesteString}
                </Fragment>;
            }
        } else if(professionista.profilo === enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali){
            let teamRefDirAssSoc = _.find(this.state.richiesta?.team, t => t.profilo === enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali &&
                t.professionista.codiceFiscale === professionista.codiceFiscale);
            if(teamRefDirAssSoc){
                label = <Fragment>
                    <div className={"row"}><div className={"col"}>Incaricato alla nomina del professionista </div></div>
                    <div className={"row"}><div className={"col"}>{enumsUtente.ruoliFE.P_ASSISTENTE_SOCIALE}</div></div>
                </Fragment>;
            }
        }

        return label;
    }

    schedaAttivazioneProfessionista(schedeJSX, nSchedeProfessionisteDaAttivare) {

        let isAttivazioneProfessionista = this.isAttivazioneProfessionale(nSchedeProfessionisteDaAttivare);

        if (isAttivazioneProfessionista) {
            schedeJSX.push(
                <SchedaProfessionista
                    attivaFiguraProfessionale={true}
                    onClickAggiungiSpecialista={(flag) => this.setState({isPaginaAttivazioneFiguraProfessionale: flag})}
                    deleghe={this.state.richiesta?.delega}
                />
            );
        }
    }

    isAttivazioneProfessionale = (nSchedeProfessionisteDaAttivare) => {
        let isAttivazioneFiguraProfessionaleCentraleAdi = AuthUtils.hasUtenteCentraleAdi() && this.isUtenteInTeam(enumsUtente.profiliUtente.centraleAdi) && !Utils.isFasePreDialogo(this.state.richiesta?.tipologia, this.state.richiesta?.stato);
        let isAttivazioneFiguraProfessionaleCoordinatore = AuthUtils.hasUtenteProfiloCoordinatore() && this.isUtenteInTeam(enumsUtente.profiliUtente.coordinatore) && !isAttivazioneFiguraProfessionaleCentraleAdi;
        let isAttivazioneFiguraProfessionaleReferenteAssistentiSociali = AuthUtils.hasUtenteProfiloReferenteAssistentiSociali() && this.isUtenteInTeam(enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali) && !isAttivazioneFiguraProfessionaleCentraleAdi;
        let isAttivazioneFiguraProfessionaleDirettoreUO = AuthUtils.hasUtenteProfiloDirettoreUO() && this.isUtenteInTeam(enumsUtente.profiliUtente.direttoreUnitaOperativa) && !isAttivazioneFiguraProfessionaleCentraleAdi;
        // aggiunto controllo per mcp che non sia fase predialogo per attivare un professionista
        let isAttivazioneFiguraProfessionaleMCP = AuthUtils.hasUtenteRuoloMcp() && this.isUtenteInTeam(enumsUtente.profiliUtente.medicoFormalizzatore) && !Utils.isFasePreDialogo(this.state.richiesta?.tipologia, this.state.richiesta?.stato) && !isAttivazioneFiguraProfessionaleCentraleAdi;

        let isAttivazioneFiguraProfessionale = isAttivazioneFiguraProfessionaleCoordinatore ||
            isAttivazioneFiguraProfessionaleDirettoreUO ||
            isAttivazioneFiguraProfessionaleReferenteAssistentiSociali ||
            isAttivazioneFiguraProfessionaleMCP ||
            isAttivazioneFiguraProfessionaleCentraleAdi;


        let teamFormalizzatore = this.getTeamFormalizzatore();
        let isTeamFormalizzatoreConfermato = !Utils.isStringEmptyOrNullOrUndefined(teamFormalizzatore?.timestampConferma);
        // Non sarà possibile attivare ulteriori professionisti per:
        // - formalizzatore se il suo team è confermato
        // - coordinatore se il suo team e il team del formalizzatore sono confermati
        // - direttore uo se il suo team e il team del formalizzatore sono confermati
        // - referente ass sociali se il suo team e il team del formalizzatore sono confermati
        let isTeamConfermato;
        if (isAttivazioneFiguraProfessionaleCentraleAdi) {
            isTeamConfermato = this.isTeamProfessionistaIntermedioConfermato(enumsUtente.profiliUtente.centraleAdi) && isTeamFormalizzatoreConfermato;
        } else if (isAttivazioneFiguraProfessionaleCoordinatore) {
            isTeamConfermato = this.isTeamProfessionistaIntermedioConfermato(enumsUtente.profiliUtente.coordinatore) && isTeamFormalizzatoreConfermato;
        } else if (isAttivazioneFiguraProfessionaleDirettoreUO) {
            isTeamConfermato = this.isTeamProfessionistaIntermedioConfermato(enumsUtente.profiliUtente.direttoreUnitaOperativa) && isTeamFormalizzatoreConfermato;
        } else if (isAttivazioneFiguraProfessionaleReferenteAssistentiSociali) {
            isTeamConfermato = this.isTeamProfessionistaIntermedioConfermato(enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali) && isTeamFormalizzatoreConfermato;
        } else {
            isTeamConfermato = isTeamFormalizzatoreConfermato;
        }

        let isAttivazioneProfessionista = isAttivazioneFiguraProfessionale && nSchedeProfessionisteDaAttivare === 0 && !isTeamConfermato && !this.state.isConfermaTeamCompletato;
        return isAttivazioneProfessionista;
    }

    isTeamProfessionistaIntermedioConfermato = (profiloUtente) => {
        let isTeamConfermato;
        let team = _.find(this.state.teams, (t) => {
            return t.profilo === profiloUtente && t?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente
        });
        isTeamConfermato = !Utils.isStringEmptyOrNullOrUndefined(team?.timestampConferma);
        return isTeamConfermato;
    }

    operazioneCompletata(msgModale) {
        this.setState({
            infoModalSucesso:msgModale,
            isPaginaAttivazioneFiguraProfessionale: false,
            operazioneCompletata: true,
            professionistaDaSostituire: null,
            professionistaDaInserire: null
        })
    }

    confermaTeam = () => {
        RichiestaADIService.confermaTeam(this.state.richiesta?.id).subscribe(res => {
            this.setState({
                infoModalSucesso: "La fase di attivazione del team si è conclusa correttamente",
                isModaleConfermaVisibile: false,
                isConfermaTeamCompletato: true,
                operazioneCompletata: true
            })
        }, err => {
            if(err?.response?.status === 403 && err?.response?.data?.message){
                Utils.toasterFunction({
                    status: 500,
                    type: "critical",
                    text: err?.response?.data?.message
                });
            }
        });
    }

    riapriTeam = () => {
        RichiestaADIService.riapriTeamValutatori(this.state.richiesta?.id).subscribe(res => {
            this.setState({
                isModaleConfermaVisibile: false,
                riaperturaTeam: false,
                isConfermaTeamCompletato: false,
                operazioneCompletata: true
            });
        }, err => {
        });
    }

    delegaFisiatra = (valoreRadio) => {
        let delega = this.getDelega(enumsUtente.profiliUtente.professionistaValutatore, enumsUtente.ruoli.fisiatra);
        let valore = delega ? !delega.valore : valoreRadio;
        RichiestaADIService.delegaFisiatra(this.state.richiesta?.id, valore).subscribe(res => {
            this.setState({
                isModaleConfermaVisibile: false,
                delegaFisiatra: false,
                operazioneCompletata: true,
                infoModalSucesso: valore ? "La richiesta è stata delegata al Medico Specialista - Fisiatra" : "La delega al Medico Specialista - Fisiatra è stata rimossa",
            });
        }, err => {
        });
    }

    richiestaAggiuntaInfermiere = () => {
        RichiestaADIService.richiestaAggiuntaInfermiere(this.state.richiesta?.id).subscribe(res => {
            this.setState({
                isModaleConfermaVisibile: false,
                infoModalSucesso: "Aggiunta infermiere richiesta con successo",
                modaleSuccesso: this.state.infoModalSucesso ? {testo:this.state.infoModalSucesso} : null,
                operazioneCompletata: true
            });
        });
    }

    apriModaleConferma = (isModaleConfermaVisibile, riaperturaTeam = false, delegaFisiatra = false, richiediInfermiere = false) => {
        this.setState({isModaleConfermaVisibile: isModaleConfermaVisibile, riaperturaTeam: riaperturaTeam, delegaFisiatra: delegaFisiatra, richiediInfermiere: richiediInfermiere});
    }

    chiudiModaleConferma = () => {
        this.setState({isModaleConfermaVisibile: false, riaperturaTeam: false, delegaFisiatra: false, richiediInfermiere: false, valoreDelegaFisiatra: !this.state.valoreDelegaFisiatra});
    }

    renderModaleConferma = () => {
        let body;

        const teamFormalizzatore = this.getTeamFormalizzatore();
        if (this.state.riaperturaTeam) {
            body = (<div>
                    <span>Stai per procedere con la riapertura del team di valutazione. La scadenza per la formalizzazione
                        della richiesta di attivazione di cure domiciliari non verrà prorogata.</span>
                <br/>
                <span className="font-weight-bold">Vuoi confermare l’operazione?</span>
            </div>);
        } else if (this.state.delegaFisiatra) {
            let delega = this.getDelega(enumsUtente.profiliUtente.professionistaValutatore, enumsUtente.ruoli.fisiatra);
            if(!delega.valore){
                body = (<div>
                    <span>Stai per procedere con l&#39;attribuzione della delega di formalizzazione e firma della richiesta al Medico Specialista - Fisiatra nominato dal Direttore UO.</span>
                    <br/>
                    <span className="font-weight-bold">Vuoi confermare l’operazione?</span>
                </div>)
            } else {
                body = (<div>
                    <span>Stai per procedere con la rimozione della delega di formalizzazione e firma della richiesta al Medico Specialista - Fisiatra nominato dal Direttore UO.</span>
                    <br/>
                    <span className="font-weight-bold">Vuoi confermare l’operazione?</span>
                </div>)
            }
        } else if(this.state.richiediInfermiere){
            body = (<div className="ml-3 mr-3">
                <span>Stai richiedendo l&#39;aggiunta di un infermiere.</span>
                <br/>
                <span className="font-weight-bold">Vuoi confermare l’operazione?</span>
            </div>);
        } else if (Utils.isStringEmptyOrNullOrUndefined(teamFormalizzatore?.timestampRiapertura) && !this.state.riaperturaTeam) {
            body = (<div>
                <span>Stai per procedere con la conferma dell’attivazione del team di valutazione. Una volta confermato, il team non potrà essere modificato</span>
                <br/>
                <span className="font-weight-bold">Vuoi confermare l’operazione?</span>
            </div>)

        } else if (!Utils.isStringEmptyOrNullOrUndefined(teamFormalizzatore?.timestampRiapertura) && this.state.valutazioni?.map(v => v.timestampInserimento)?.every(v => moment(v) < moment(teamFormalizzatore?.timestampRiapertura))) {
            body = (<div className="ml-3 mr-3">
                <span>Non è stato attivato nessun nuovo professionista.</span>
                <br/>
                <span className="font-weight-bold">Vuoi confermare l’operazione?</span>
            </div>);
        }


        let confermaFunc;
        if(this.state.riaperturaTeam){
            confermaFunc =  this.riapriTeam
        } else if(this.state.delegaFisiatra){
            confermaFunc = this.delegaFisiatra
        } else if(this.state.richiediInfermiere){
            confermaFunc = this.richiestaAggiuntaInfermiere
        } else {
            confermaFunc =  this.confermaTeam
        }

        let footer = (
            <Fragment>
                <Link to={{
                    pathname: routepath.stato_valutazione_componenti_team,
                    showMultiCompilationPages: this.props.showMultiCompilationPages
                }}>
                <Button
                    id={"conferma"}
                    text={"Conferma"}
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => confermaFunc()}
                />
                </Link>
                <Button
                    id={"annulla"}
                    text={"Annulla"}
                    dataDismiss={"modal"}
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    onClick={() => this.chiudiModaleConferma()}
                />
            </Fragment>
        );

        return (
            <ModalHelper id={"modaleAvviso"}
                         title={"Attenzione!"}
                         body={body}
                         footer={footer}
                         titleClassName={"modal-header d-inline text-center backgroundOrange"}
                         containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                         contentClassName={"modal-content"}
                         footerClassName={"modal-footer justify-content-around"}
                         bodyClassName={"modal-body justify-content-center align-items-center d-flex text-center"}
            />)
    }

    renderModaleSuccesso() {
        let body = <><span className={"text-center"}>
                                    {this.state.modaleSuccesso.testo}
                                </span></>;
        let footer = <>
            <div className={"col-12 text-center"}>
                <Button
                    id={"chiudiBtn"}
                    text={"Chiudi"}
                    className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                    dataDismiss={"modal"}
                    onClick={() => {
                        this.setState({modaleSuccesso: null, infoModalSucesso: ""});
                    }}
                />
            </div>
        </>;
        return <ModalHelper id={"successoAttivazioneFiguraProfessionale"}
                            title={"Operazione completata"}
                            body={body}
                            footer={footer}
                            titleClassName={"modal-header d-inline text-center backgroundGreen"}
                            containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                            contentClassName={"modal-content"}
                            footerClassName={"modal-footer justify-content-around"}
                            bodyClassName={"modal-body d-flex align-items-center justify-content-around"}
        />;
    }

    isProfessionistaValutatore = () => this.state.professionisti.some(pro => pro.isValutatore && pro.codiceFiscale === SessioneUtente.getInstance().idUtente);

    renderProfessionistiRichiestiDalMMG = () => {
        let messaggio = MESSAGGIO_BASE_PROFESSIONISTI_RICHIESTI_MMG;
        let figureProfessionaliPresaInCarico = _.cloneDeep(this.props.richiesta?.figureProfessionaliPresaInCarico);
        let professionistiSupportoAValutazione = _.cloneDeep(this.props.richiesta?.professionistiSupportoAValutazione);
        professionistiSupportoAValutazione = _.flatMap(professionistiSupportoAValutazione, p => p.figureRichieste);
        let messaggioFigureProfessionaliPresaInCarico = this.messaggioProfessionisti(figureProfessionaliPresaInCarico, OggettiUtili.figureProfessionaliPerPAI, "figuraProfessionale", OGGETTI_UTILI_FIGURE_PAI_SPECIALISTA.value);
        let messaggioProfessionistiSupportoAValutazione = this.messaggioProfessionisti(professionistiSupportoAValutazione, OggettiUtili.figureProfessionaliPerValutazione, "ruolo", OGGETTI_UTILI_FIGURE_PROFESSIONALI_PER_VALUTAZIONE.value);
        if(messaggioFigureProfessionaliPresaInCarico){
            messaggio = messaggio.concat(" ", messaggioFigureProfessionaliPresaInCarico);
        }
        if(messaggioProfessionistiSupportoAValutazione){
            messaggio = messaggio.concat(" ", messaggioProfessionistiSupportoAValutazione);
        }
        return <div className={"row pl-4 pb-4"}><div className={"col"}>{messaggio}</div></div>
    }

    messaggioProfessionisti = (figureProfessionaliPresaInCarico, listaValori, attributo, associazioneDisciplina) => {
        if(!figureProfessionaliPresaInCarico?.length){
            return null;
        }

        let labels = _.map(figureProfessionaliPresaInCarico, figura => {
            let pai = _.find(listaValori, pai => pai.value === figura[attributo]);
            let label = pai?.label ?? null;
            if (label && pai.value === associazioneDisciplina) {
                let disciplinaLabel = _.find(disciplinaSelect, d => d.value === figura.disciplina)?.label;
                if (disciplinaLabel) {
                    label = label.concat(" - ", disciplinaLabel)
                }
            }
            return label;
        });
        return _.join(labels, ", ")
    }

    render() {
        const isStateValutazioneOrValutazionePreDialogo = Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState);
        let firstPageName;
        if (isStateValutazioneOrValutazionePreDialogo) {
            firstPageName = enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE;
        } else if (Utils.isStateFormalizzazione(this.props.pageState)) {
            firstPageName = enumPaginaCompila.COMPILA_FORMALIZZAZIONE;
        } else if (Utils.isStateChiusuraPreDialogo(this.props.pageState)) {
            firstPageName = enumPaginaCompila.COMPILA_CHIUDI_PREDIALOGO;
        } else {
            firstPageName = enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE;
        }

        if (this.state.redirectToIndietro) {
            let pathname;
            let basePath = "";
            let isUtenteFormalizzatoreInCarico = AuthUtils.hasUtenteCentraleAdi() && this.isUtenteInTeam(enumsUtente.profiliUtente.centraleAdi) || AuthUtils.hasUtenteRuoloMcp() && this.isUtenteInTeam(enumsUtente.profiliUtente.medicoFormalizzatore);
            const {sostituzioneRichiedente, stato} = this.state.richiesta ? this.state.richiesta : {};
            let pageRedirect;

            if (this.pageName === enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE) {
                // CASO MCP richiesta nn valutata 
                // questo caso viene presentato quando un MCP accede da home Page per completare la valutazione multidimenzionale
                // ma nel fratempo viene cambiato lo stato della richiesta
                if (isUtenteFormalizzatoreInCarico && stato !== enumRichiesta.stato.VALUTATA) {
                    pageRedirect = routepath.verifica_valutazione;
                }
                    // CASO ADI-DIR-UO, ADI-COORD, ADI-REF-DIR-AS
                    // questo caso viene presentato quando un utente con profilo ADI-DIR-UO || ADI-COORD || ADI-REF-DIR-AS accede da home Page per completare la valutazione multidimenzionale
                    // ma nel fratempo viene eliminato il profilo come valutatore
                else if ((AuthUtils.hasUtenteProfiloCoordinatore() || AuthUtils.hasUtenteProfiloReferenteAssistentiSociali() || AuthUtils.hasUtenteProfiloDirettoreUO()) && !this.isProfessionistaValutatore()) {
                    basePath = routepath.valutazione_multidimensionale;
                    pageRedirect = routepath.accedi_valutazione_multidimensionale;
                } else {
                    basePath = routepath.valutazione_multidimensionale;
                    pageRedirect = routepath.completa_valutazione_multidimensionale;
                }
            } else if (this.pageName === enumPaginaCompila.COMPILA_PROPOSTE) {
                if (isStateValutazioneOrValutazionePreDialogo) {
                    // CASO MCP richiesta nn valutata 
                    // questo caso viene presentato quando un MCP accede da home Page per completare la valutazione multidimenzionale > va alla pagina compila Proposta
                    // ma nel fratempo viene cambiato lo stato della richiesta
                    if (isUtenteFormalizzatoreInCarico) {
                        if (stato !== enumRichiesta.stato.VALUTATA) {
                            pageRedirect = routepath.verifica_valutazione;
                        } else {
                            basePath = routepath.valutazione_multidimensionale;
                            pageRedirect = routepath.completa_valutazione_multidimensionale;
                        }
                    }
                        // CASO ADI-DIR-UO, ADI-COORD, ADI-REF-DIR-AS
                        // questo caso viene presentato quando un utente con profilo ADI-DIR-UO || ADI-COORD || ADI-REF-DIR-AS accede da home Page per completare la valutazione multidimenzionale > va alla pagina compila Proposta
                        // ma nel fratempo viene eliminato il profilo come valutatore
                    else if ((AuthUtils.hasUtenteProfiloCoordinatore() || AuthUtils.hasUtenteProfiloReferenteAssistentiSociali() || AuthUtils.hasUtenteProfiloDirettoreUO())) {
                        if (this.isProfessionistaValutatore()) {
                            basePath = routepath.valutazione_multidimensionale;
                            pageRedirect = routepath.completa_valutazione_multidimensionale;
                        } else {
                            basePath = routepath.valutazione_multidimensionale;
                            pageRedirect = routepath.accedi_valutazione_multidimensionale;
                        }
                    } else {
                        basePath = routepath.valutazione_multidimensionale;
                        pageRedirect = routepath.compila_proposte;
                    }
                } else {
                    basePath = routepath.inserimento_richiesta
                    pageRedirect = routepath.compila_proposte;
                }
            } else if (this.pageName === enumPaginaCompila.COMPILA_FORMALIZZAZIONE) {
                pageRedirect = routepath.compila_formalizzazione;
            } else if (this.pageName === enumPaginaCompila.VISUALIZZA_VALUTAZIONE_MULTIDIMENSIONALE) {
                  // CASO ADI-DIR-UO, ADI-COORD, ADI-REF-DIR-AS 
                // questo caso viene presentato quando un utente con profilo ADI-DIR-UO || ADI-COORD || ADI-REF-DIR-AS accede da home Page per vissualizzare la valutazione multidimenzionale
                // ma nel fratempo viene aggiunto il profilo come valutatore
                if ((AuthUtils.hasUtenteProfiloCoordinatore() || AuthUtils.hasUtenteProfiloReferenteAssistentiSociali() || AuthUtils.hasUtenteProfiloDirettoreUO()) && this.isProfessionistaValutatore()) {
                    basePath = routepath.valutazione_multidimensionale;
                    pageRedirect = routepath.completa_valutazione_multidimensionale;
                } else {
                    basePath = routepath.valutazione_multidimensionale;
                    pageRedirect = routepath.visualizza_valutazione_multidimensionale;
                }
            } else if (this.pageName === enumPaginaCompila.ACCEDI_VALUTAZIONE_MULTIDIMENSIONALE) {
                // CASO ADI-DIR-UO, ADI-COORD, ADI-REF-DIR-AS 
                // questo caso viene presentato quando un utente con profilo ADI-DIR-UO || ADI-COORD || ADI-REF-DIR-AS accede da home Page per completare la valutazione multidimenzionale
                // ma nel fratempo viene aggiunto il profilo come valutatore
                if ((AuthUtils.hasUtenteProfiloCoordinatore() || AuthUtils.hasUtenteProfiloReferenteAssistentiSociali() || AuthUtils.hasUtenteProfiloDirettoreUO()) && this.isProfessionistaValutatore()) {
                    basePath = routepath.valutazione_multidimensionale;
                    pageRedirect = routepath.completa_valutazione_multidimensionale;
                } else {
                    basePath = routepath.valutazione_multidimensionale;
                    pageRedirect = routepath.accedi_valutazione_multidimensionale;
                }
            } else if (this.pageName === enumPaginaCompila.COMPILA_CHIUDI_PREDIALOGO || window.location.pathname.includes(enumPaginaCompila.COMPILA_CHIUDI_PREDIALOGO)) {
                const split = window.location.pathname.split('/');
                pageRedirect = split.filter((_e, i) => i !== split.length - 1).join('/');
            } else {
                let isUtenteCoordInCarico = AuthUtils.hasUtenteProfiloCoordinatore() && this.isUtenteInTeam(enumsUtente.profiliUtente.coordinatore);
                switch (true) {
                    // nel caso in cui:
                    // 1-il MCP si trova dentro la lista di valutatori
                    // 2- sostituzioneRichiedente della richiesta e True
                    // 3-lo stato della richiesta e diversa IN_FORMALIZZAZIONE e uguale a VALUTATA
                    // faccio redirect  su verifica_valutazione
                    case (isUtenteFormalizzatoreInCarico && sostituzioneRichiedente && stato === enumRichiesta.stato.VALUTATA):
                        basePath = routepath.valutazione_multidimensionale;
                        pageRedirect = routepath.completa_valutazione_multidimensionale;
                        break;
                    // CASO ADI-DIR-UO, ADI-COORD, ADI-REF-DIR-AS valutatore
                    // homePage > verifica valutazione >
                    // ma nel fratempo viene aggiunto il profilo come valutatore
                    case ((AuthUtils.hasUtenteProfiloCoordinatore() || AuthUtils.hasUtenteProfiloReferenteAssistentiSociali() || AuthUtils.hasUtenteProfiloDirettoreUO()) && this.isProfessionistaValutatore()):
                        basePath = routepath.valutazione_multidimensionale;
                        pageRedirect = routepath.completa_valutazione_multidimensionale;
                        break;
                    // gestisce gli altri casi
                    default:
                        if ((isUtenteFormalizzatoreInCarico || isUtenteCoordInCarico) && this.state.richiesta.stato !== enumRichiesta.stato.IN_FORMALIZZAZIONE) {
                            pageRedirect = routepath.verifica_valutazione;
                        } else {
                            if (isStateValutazioneOrValutazionePreDialogo) {
                                basePath = routepath.valutazione_multidimensionale;
                            } else if (Utils.isStateRichiesta(this.props.pageState) || Utils.isStateRichiestaPreDialogo(this.props.pageState)) {
                                basePath = routepath.inserimento_richiesta
                            }
                            pageRedirect = routepath.visualizza_riepilogo
                        }
                        break;
                }
            }

            pathname = basePath.concat(pageRedirect);

            return (
                <Redirect to={{
                    key: uuid(),
                    pathname: pathname,
                    uuidRichiesta: this.state.richiesta?.id,
                    pageState: this.props.pageState,
                    pageName: this.pageName,
                    firstPageName: firstPageName,
                    tipologia: this.state.richiesta?.tipologia,
                    stato: this.state.richiesta?.stato,
                    valutazionePrecedente: (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) ? getValutazionePrecedente(this.state.richiesta) : null,
                    showOnlyRequiredFields: this.props.showOnlyRequiredFields,
                    previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields,
                    showMultiCompilationPages: this.props.showMultiCompilationPages
                }}/>
            );
        }

        let pagina;
        if (this.state.isPaginaAttivazioneFiguraProfessionale) {
            pagina = <AttivazioneFiguraProfessionale
                pageState={this.props.pageState}
                onClickIndietro={() => this.setState({
                    isPaginaAttivazioneFiguraProfessionale: false,
                    professionistaDaSostituire: null,
                    professionistaDaInserire: null
                })}
                onClickConferma={() => {
                    let msg = this.state.professionistaDaSostituire ?
                        "La sostituzione della figura professionale è avvenuta correttamente" :
                        "L'attivazione della figura professionale è avvenuta correttamente";
                    this.operazioneCompletata(msg);
                }}
                uuidRichiesta={this.state.richiesta.id}
                richiedente={this.state.richiesta?.richiedente}
                valutatori={this.state.professionisti}
                paziente={this.state.richiesta?.anagrafePaziente}
                teams={this.state.teams}
                professionistaDaSostituire={this.state.professionistaDaSostituire}
                professionistaDaInserire={this.state.professionistaDaInserire}
                richiesta={this.state.richiesta}
            />
        } else {
            pagina = <Fragment>
                <div className={"row"}>
                    <div className={"col-1"} style={{flexBasis: "content"}}>
                        <div className={["row", styles.menuSticky].join(" ")}>
                            <div className={"col-12"}>
                                <MenuAccordion richiesta={this.state.richiesta}
                                               pageState={this.props.pageState}
                                               showAllMenuItems={this.props.showAllMenuItems}
                                               readOnly={this.props.readOnly}
                                               hideUnsetValues={this.props.hideUnsetValues}
                                               richiestaMedico={this.props.richiestaMedico}
                                               persone={this.props.persone}
                                               containsOnlyRequiredFields={this.props.containsOnlyRequiredFields}
                                               previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields}
                                               showOptionalFieldsCount={this.props.showOptionalFieldsCount}
                                               pageName={this.pageName}
                                               firstPageName={firstPageName}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"col-11"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"mt-4"}>
                                    <div className={styles.customContainer}>
                                        <TabellaStatoValutazione
                                            richiesta={this.state.richiesta}
                                            updateRichiesta={() => this.letturaRichiesta()}
                                        />
                                    </div>
                                    {this.renderProfessionistiRichiestiDalMMG()}
                                    {this.renderDelegaFisiatra()}
                                    {this.renderSchedeProfessionista()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderButtons()}
            </Fragment>

        }

        const goToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "auto",
            });
        };

        return (
            <Fragment>
                {this.state.isPaginaAttivazioneFiguraProfessionale && goToTop()}
                {this.state.modaleSuccesso ? this.renderModaleSuccesso() : <Fragment/>}
                {this.state.isModaleConfermaVisibile ? this.renderModaleConferma() : <Fragment/>}
                <Breadcrumb
                    path={['Home', Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState) ? "Dettaglio valutazione" : "Dettaglio formalizzazione", 'Team e monitoraggio richiesta']}
                    richiesta={this.props.richiesta}/>
                {showMessageAulssDistrettoDifferences(this.props.richiesta, null)}
                {pagina}
            </Fragment>
        )
    }
}

StatoValutazioneEComponentiDelTeam.propTypes = propTypes;
