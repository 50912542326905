import React, { Component } from "react";
import PropTypes from 'prop-types';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const propTypes = {
    idDropdown: PropTypes.string,
    idDropdownToggle: PropTypes.string,
    idDropdownMenu: PropTypes.string,
    direction: PropTypes.string,
    tagDropdownToggle: PropTypes.string,
    renderDropdownToggle: PropTypes.func,
    renderDropdownMenu: PropTypes.func,
    classNameDropdown: PropTypes.string,
    classNameDropdownToggle: PropTypes.string,
    classNameDropdownMenu: PropTypes.string,
}

class Dropdown extends Component {

    render() {
        return (
            <UncontrolledDropdown id={this.props.idDropdown} direction={this.props.direction} className={this.props.classNameDropdown}>
                <DropdownToggle tag={this.props.tagDropdownToggle} id={this.props.idDropdownToggle} className={this.props.classNameDropdownToggle} caret>
                    {this.props.renderDropdownToggle()}
                </DropdownToggle>
                <DropdownMenu id={this.props.idDropdownMenu ?? "idDropdownMenu"} className={this.props.classNameDropdownMenu}>
                    {this.props.renderDropdownMenu()}
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }
}

Dropdown.propTypes = propTypes;
export default Dropdown;
