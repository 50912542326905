import React from "react";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {
    FieldsRow,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedText
} from "../../roleBasedComponents/RoleBasedComponents";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import {calcoloBMI, statoBMI} from "../../anamnesiFisiologica/statoNutrizionale/StatoNutrizionale";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";


const propTypes = {
    datiAlimentazione: PropTypes.any,
    onChangeDatiAssistito: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    mascheraModificabilita: PropTypes.any,
    hideUnsetValues: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

export default class Alimentazione extends AccordionSecondLevel {

    state = {
        datiAlimentazione: _.cloneDeep(this.props.datiAlimentazione)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiAlimentazione, this.props.datiAlimentazione))
            this.forceUpdate(() => this.inizializzaForm());
    }

    inizializzaForm = () => {
        let datiAlimentazione = _.cloneDeep(this.props.datiAlimentazione);
        this.setState({datiAlimentazione: datiAlimentazione}, () => this.countCampiObbligatoriRimanentiAndValida());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiAlimentazione}) => {
            datiAlimentazione[field] = elem;

            datiAlimentazione.BMI = calcoloBMI(datiAlimentazione.peso, datiAlimentazione.altezza);
            datiAlimentazione.statoBMI = statoBMI(datiAlimentazione.BMI);

            return {datiAlimentazione};
        }, () => {
            const alimentazione = _.cloneDeep(this.state.datiAlimentazione);
            this.props.onChangeDatiAssistito("datiAlimentazione", alimentazione);
            this.countCampiObbligatoriRimanentiAndValida();
        });
    }

    countCampiObbligatoriRimanentiAndValida = () => {
        let dati = _.cloneDeep(this.state.datiAlimentazione);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneAlimentazione", campiObbligatoriRimanenti);
    }

    renderBodySezione = () => {
        const datiAlimentazione = this.state.datiAlimentazione;        
        return (
            <>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'ALTEZZA.ALIMENTAZIONE.DETERMINANTIDISALUTE'}
                        pageState={this.props.pageState}
                        value={datiAlimentazione.altezza}
                        placeholder="Inserisci altezza"
                        field="altezza"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="altezza"
                        type={"number"}
                        min={0}
                        fieldLabel={'Altezza (cm)'}
                        hideUnsetValues={this.props.hideUnsetValues}  
                        forceReadOnly={this.props.forceReadOnly
                            || this.props.mascheraModificabilita?.determinantiDiSalute?.datiAlimentazione?.altezza}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />

                    <RoleBasedInput
                        fieldId={'PESO.ALIMENTAZIONE.DETERMINANTIDISALUTE'}
                        pageState={this.props.pageState}
                        value={datiAlimentazione.peso}
                        placeholder="Inserisci peso"
                        field="peso"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="peso"
                        type={"number"}
                        min={0}
                        fieldLabel={'Peso (Kg)'}
                        hideUnsetValues={this.props.hideUnsetValues}  
                        forceReadOnly={this.props.forceReadOnly
                            || this.props.mascheraModificabilita?.determinantiDiSalute?.datiAlimentazione?.peso}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <FieldsRow>
                    {datiAlimentazione.BMI ?
                        <RoleBasedText
                            fieldId={'BMI.ALIMENTAZIONE.DETERMINANTIDISALUTE'}
                            pageState={this.props.pageState}
                            value={datiAlimentazione.BMI}
                            field={"bmi"}
                            fieldLabel={'Bmi (Kg/m2)'}
                            id={"textBmi"}
                            hideUnsetValues={this.props.hideUnsetValues}
                            forceReadOnly={this.props.forceReadOnly
                                || this.props.mascheraModificabilita?.determinantiDiSalute?.datiAlimentazione?.bmi}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    : ''}
                    {datiAlimentazione.statoBMI  ?
                        <RoleBasedText
                            fieldId={'STATO_BMI.ALIMENTAZIONE.DETERMINANTIDISALUTE'}
                            pageState={this.props.pageState}
                            value={datiAlimentazione.statoBMI}
                            fieldLabel={'Stato Bmi'}
                            handleOnlyValue={true}
                            field={"statoBmi"}
                            id={"textStatoBmi"}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        /> 
                    : ''}
                        
                </FieldsRow>

                {datiAlimentazione.altezza && datiAlimentazione.peso ?
                    <>
                        < CondizioneRilevataSection
                            ambito={"ALIMENTAZIONE"}
                            pageState={this.props.pageState}
                            parentFieldId={'ALIMENTAZIONE.DETERMINANTIDISALUTE'}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                            valueCondizioneRilevata={datiAlimentazione.condizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                            valueDaAttenzionare={datiAlimentazione.daAttenzionare}
                            showSuggerimenti={true}
                            onChangeSuggerimenti={this.handleAggiornaForm}
                            valueSuggerimenti={datiAlimentazione.propostaAttivitaSuggerimenti}
                            showAutonomia={true}
                            onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                            valueAutonomiaPaziente={datiAlimentazione.autonomiaGestionePaziente}
                            onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                            valueAutonomiaCaregiver={datiAlimentazione.autonomiaGestioneCaregiver}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />

                        <FieldsRow>
                            <RoleBasedCalendar
                                fieldId={'DATA_VAL.ALIMENTAZIONE.DETERMINANTIDISALUTE'}
                                pageState={this.props.pageState}
                                id={"alimentazioneDataValutazione"}
                                value={datiAlimentazione.dataValutazione}
                                onChange={(elem) => this.handleAggiornaForm("dataValutazione", elem.target.value)}
                                errorMessage={"Data valutazione non valida"}
                                fieldLabel={"Data Valutazione"}
                                field={"dataValutazione"}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                        </FieldsRow>
                    </> : null
                }
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiAlimentazione"}
                title={"Alimentazione"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }
}
Alimentazione.contextType = RoleBasedContext;

Alimentazione.propTypes = propTypes;
