import accounting from "accounting";

export default class Formatter {


    static insertString(originalString, newString, index) {
        return originalString.substr(0, index) + newString + originalString.substr(index);
    }

    static formatDate(dateString, pattern) {
        let cleanString = dateString.replace(/\D/g, ''),
            output = cleanString.substr(0, 8),
            size = dateString.length;
        if ((pattern === undefined || pattern === null || pattern === '')) {
            let delimiter = '/'
            output = this.formatPatternDayMonthYearAndMonthDayYear(output, size, delimiter)
        } else {
            output = this.formatterDateGeneralWithRegex(output, size, pattern)
        }
        return output;
    }

    static formatterDateGeneralWithRegex(output, size, pattern) {
        let regexDMY = new RegExp(/dd[/-]mm[/-]yyyy/ig)
        let regexYMD = new RegExp(/yyyy[/-]mm[/-]dd/ig)
        if (pattern.match(regexDMY) !== null) {
            let delimiter = pattern.match(/[/-]/)
            output = this.formatPatternDayMonthYearAndMonthDayYear(output, size, delimiter)
        } else if (pattern.match(regexYMD) !== null) {
            let delimiter = pattern.match(/[/-]/)
            output = this.formatPatternYearDayMonthAndYearMonthDay(output, size, delimiter)
        }
        return output;
    }

    static formatPatternDayMonthYearAndMonthDayYear(output, size, delimiter) {
        if (size > 4)
            output = this.insertString(output, delimiter, 4);
        if (size > 2)
            output = this.insertString(output, delimiter, 2);

        return output;
    }

    static formatPatternYearDayMonthAndYearMonthDay(output, size, delimiter) {
        if (size > 4)
            output = this.insertString(output, delimiter, 4);
        if (size > 7)
            output = this.insertString(output, delimiter, 7);

        return output;

    }

    static formatImport(importo) {
        try {
            return accounting.formatMoney(importo, "€ ", "2", ".", ",", ",");
        } catch (e) {
            return importo
        }
    }


}
