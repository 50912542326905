import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import ToponomasticaUtils from "../../../utils/ToponomasticaUtils";
import Utils from "../../../utils/Utils";
import AulssUtils from "../../../utils/AulssUtils";
import aulssElenco from "../../../utils/dataset/aulssData/aulssElenco.json";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {
    FieldsGroup,
    FieldsRow,
    RoleBasedAutocomplete,
    RoleBasedInput,
    RoleBasedRadio,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";

const propTypes = {
    datiResidenzaForm: PropTypes.any,
    datiDomicilioForm: PropTypes.any,
    datiDomicilioDiCuraForm: PropTypes.any,
    aulssAssistenza: PropTypes.string,
    distrettoAssistenza: PropTypes.string,
    provinciaAssistenza: PropTypes.string,
    datiGeneraliA0: PropTypes.any,
    residenzaA0: PropTypes.any,
    domicilioA0: PropTypes.any,
    domicilioDiCuraA0: PropTypes.any,
    onChangeDatiResidenzaDomicilio: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onValidaSezione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class ResidenzaEDomicilio extends AccordionSecondLevel {

    state = {
        datiResidenza: this.props.datiResidenzaForm,
        datiDomicilio: this.props.datiDomicilioForm,
        datiDomicilioDiCura: this.props.datiDomicilioDiCuraForm,
        datiAulssAssistenza: {
            aulss: _.cloneDeep(this.props.aulssAssistenza),
            distretto: _.cloneDeep(this.props.distrettoAssistenza),
            provincia: _.cloneDeep(this.props.provinciaAssistenza)
        },
        previousSelectionResidenzaDiversaDaDomicilio: null
    }

    listaComuni = [];
    listaProvince = [];
    listaNazioni = [];
    listaSigleProvince = [];

    listaAulssSelect = [];

    componentDidMount() {
        this.popolaListaAulss();
        this.listaComuni = ToponomasticaUtils.getComuni();
        this.listaProvince = ToponomasticaUtils.getProvince();
        this.listaNazioni = ToponomasticaUtils.getNazioni();
        // note: e stato aggiunto il filter perche ci hanno segnalato che la provincia deve far parte dell’AULSS
        this.listaSigleProvince = ToponomasticaUtils.getSigleProvince()
        .filter(prov => prov.codice === AulssUtils.getAulssByCodiceAulss(this.state.datiAulssAssistenza.aulss)?.codiceProvincia)
        .map(valore => ({label: valore.descrizione, value: valore.codice }));
        if(this?.listaSigleProvince?.length === 1){
            let datiAulssAssistenza = _.cloneDeep(this.state.datiAulssAssistenza);
            datiAulssAssistenza.provincia = this.listaSigleProvince[0];
            this.setState({datiAulssAssistenza}, () => this.controllaCampi());
        }
        this.inizializzaForm(false, this.state.datiResidenza.residenzaDiversaDaDomicilio);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiResidenzaForm, this.props.datiResidenzaForm)
            || !_.isEqual(prevProps.datiDomicilioForm, this.props.datiDomicilioForm)
            || !_.isEqual(prevProps.datiDomicilioDiCuraForm, this.props.datiDomicilioDiCuraForm)) {
            this.inizializzaForm(true, this.state.datiResidenza.residenzaDiversaDaDomicilio);
        }
    }

    popolaListaAulss = () => {
        aulssElenco.forEach(el => {
            this.listaAulssSelect.push({value: el.id, label: el.descrizione});
        });
    }

    getDistrettiSelectOptions = (idAulss) => {
        let listaDistrettiSelect = AulssUtils.getDistrettiByAulssId(idAulss);
        listaDistrettiSelect = listaDistrettiSelect.map(el => {
            return {value: el.id, label: el.descrizione};
        });

        return listaDistrettiSelect;
    }

    inizializzaForm = (isUpdate, residenzaDiversaDaDomicilio) => {
        let datiResidenzaRecuperati = _.cloneDeep(this.props.datiResidenzaForm);
        let datiDomicilioRecuperati = _.cloneDeep(this.props.datiDomicilioForm);
        let datiDomicilioDiCuraRecuperati = _.cloneDeep(this.props.datiDomicilioDiCuraForm);

        if (!Utils.isObjectNull(datiResidenzaRecuperati)) {
            if (!Utils.isObjectNull(datiResidenzaRecuperati.nazione)) {
                let nazione = this.listaNazioni.find(nazione => nazione.codice === datiResidenzaRecuperati.nazione);
                datiResidenzaRecuperati.nazione = this.getOggettoNazione(nazione);
            } else {
                this.inizializzaAulssDistretti(datiResidenzaRecuperati);
            }
        }
        datiResidenzaRecuperati.residenzaDiversaDaDomicilio = residenzaDiversaDaDomicilio ?? !this.isRadioButtonNotEditable("residenzaDiversaDaDomicilio");

        if (!Utils.isObjectNull(datiDomicilioRecuperati)) {
            this.inizializzaAulssDistretti(datiDomicilioRecuperati);
        }

        let domicilioDiCuraDiversoDaDomicilio = datiDomicilioRecuperati.domicilioDiversoDaDomicilioCure;
        if (residenzaDiversaDaDomicilio && domicilioDiCuraDiversoDaDomicilio === null && !isUpdate) {
            domicilioDiCuraDiversoDaDomicilio = this.isDomicilioCuraDiversoDaDomicilio(_.cloneDeep(this.props.datiDomicilioDiCuraForm), datiDomicilioRecuperati);
        }
        datiDomicilioRecuperati.domicilioDiversoDaDomicilioCure = domicilioDiCuraDiversoDaDomicilio ?? false;
        this.inizializzaAulssDistretti(datiDomicilioDiCuraRecuperati);

        this.inizializzaDatiComuniProvince(datiResidenzaRecuperati);
        this.inizializzaDatiComuniProvince(datiDomicilioRecuperati);
        this.inizializzaDatiComuniProvince(datiDomicilioDiCuraRecuperati);

        this.setState({
            datiResidenza: datiResidenzaRecuperati,
            datiDomicilio: datiDomicilioRecuperati,
            datiDomicilioDiCura: datiDomicilioDiCuraRecuperati
        }, () => {
            this.contaCampiObbligatoriRestantiAndValida(this.state.datiResidenza, this.state.datiDomicilio, this.state.datiDomicilioDiCura);
        });
    }

    isResidenzaDiversoDaDomicilio = (residenza, domicilio) => {
        let residenzaObjectClone = _.cloneDeep(residenza);
        let domicilioClone = _.cloneDeep(domicilio);
        delete residenzaObjectClone.idElemento;
        delete residenzaObjectClone.residenzaDiversaDaDomicilio;
        delete residenzaObjectClone.nazione;
        delete domicilioClone.idElemento;
        delete domicilioClone.domicilioDiversoDaDomicilioCure;

        residenzaObjectClone = _.mapValues(residenzaObjectClone, el => el === null || el === undefined || el === "" ? null : el);
        domicilioClone = _.mapValues(domicilioClone, el => el === null || el === undefined || el === "" ? null : el);

        return residenza.residenzaDiversaDaDomicilio !== null ? !residenza.residenzaDiversaDaDomicilio : !_.isEqual(residenzaObjectClone, domicilioClone);
    }

    isDomicilioCuraDiversoDaDomicilio = (domicilioDiCura, domicilio) => {
        let domicilioDiCuraObjectClone = _.cloneDeep(domicilioDiCura);
        let domicilioClone = _.cloneDeep(domicilio);
        delete domicilioDiCuraObjectClone.idElemento;
        delete domicilioClone.idElemento;
        delete domicilioClone.domicilioDiversoDaDomicilioCure;

        domicilioClone = _.mapValues(domicilioClone, el => el === null || el === undefined || el === "" ? null : el);
        domicilioDiCuraObjectClone = _.mapValues(domicilioDiCuraObjectClone, el => el === null || el === undefined || el === "" ? null : el);

        return domicilio.domicilioDiversoDaDomicilioCure !== null ? !domicilio.domicilioDiversoDaDomicilioCure : !_.isEqual(domicilioDiCuraObjectClone, domicilioClone);
    }

    inizializzaDatiComuniProvince = (contatti) => {
        if (Utils.isObjectNull(contatti.comune))
            contatti.comune = "";
        else {
            let comune = this.listaComuni.find(c => c.codice === contatti.comune);
            contatti.comune = this.getOggettoComune(comune);
        }

        if (Utils.isObjectNull(contatti.provincia))
            contatti.provincia = "";
        else if (!Utils.isObjectNull(contatti.comune)) {
            let provincia = this.listaProvince.find(p => p.codice === contatti.comune.codiceProvincia);
            contatti.provincia = this.getOggettoProvincia(provincia);
        }
    }

    inizializzaAulssDistretti = (contatto) => {
        let aulss = AulssUtils.getAulssByCodiceAulss(contatto.aulss);
        if (!Utils.isObjectNull(aulss)) {
            contatto.aulss = {value: aulss.id, label: "Azienda " + aulss.nome + " " + aulss.descrizione};
            if (!Utils.isObjectNull(contatto.distretto)) {
                let distretto = AulssUtils.getDistrettoByIdAulssAndIdDistretto(contatto.aulss.value, contatto.distretto);
                contatto.distretto = !Utils.isObjectNull(distretto) ? {
                    value: distretto.id,
                    label: distretto.descrizione
                } : null;
            }
        }
    }

    getOggettoComune = (comune) => {
        return {
            codice: comune.codice,
            codiceProvincia: comune.codiceProvincia,
            descrizione: comune.descrizione
        };
    }

    getOggettoProvincia = (provincia) => {
        return {
            codice: provincia.codice,
            codiceRegione: provincia.codiceRegione,
            descrizione: provincia.descrizione
        };
    }

    getOggettoNazione = (nazione) => {
        return {
            codice: nazione.codice,
            descrizione: nazione.descrizione
        };
    }

    handleAggiornaSottosezioneResidenzaDomicilio = (objectState, field, elem) => {
        let objectClone = _.cloneDeep(this.state[objectState]);

        if (field === "comune" || field === "provincia" || field === "nazione") {
            let isOpzioneAutocompleteValida = !Utils.isObjectNull(elem.target.value) && !Utils.isObjectNull(elem.target.value["codice"]);
            if (isOpzioneAutocompleteValida) {
                elem = elem.target.value;
                if (field === "comune") {
                    let provincia = this.listaProvince.find(p => p.codice === elem.codice.substring(0, 3));
                    objectClone.provincia = this.getOggettoProvincia(provincia);
                }
            } else {
                elem = {};
                if (field === "comune" || field === "provincia") {
                    objectClone.cap = "";
                }
            }
            objectClone[field] = elem;
        } else if (field === "aulss") {
            objectClone.distretto = null;
            objectClone[field] = elem;
        } else {
            objectClone[field] = elem;
        }

        this.setState({[objectState]: objectClone}, () => this.controllaCampi());
    }

    resetSezione = (object) => {
        Object.keys(object).forEach(key => {
            if (key !== "idElemento")
                object[key] = (key === "comune" || key === "provincia" || key === "nazione") ? "" : null;
        });
    }

    controllaCampi = () => {
        let residenza = _.cloneDeep(this.state.datiResidenza);
        let domicilio = _.cloneDeep(this.state.datiDomicilio);
        let domicilioDiCura = _.cloneDeep(this.state.datiDomicilioDiCura);
        let datiAulssAssistenza = _.cloneDeep(this.state.datiAulssAssistenza);

        // formatto l'oggetto per inviarlo come parametro di input al metodo salvaBozza nel componente padre "CompilaRichiesta"
        this.getCodice(residenza, "datiResidenza");
        residenza.nazione = Utils.isObjectNull(this.state.datiResidenza.nazione) ? null : this.state.datiResidenza.nazione.codice;
        this.getCodice(domicilio, "datiDomicilio");
        this.getCodice(domicilioDiCura, "datiDomicilioDiCura");
        this.props.onChangeDatiResidenzaDomicilio(residenza, domicilio, domicilioDiCura, datiAulssAssistenza);

        // controllo la validità dei vari campi degli oggetti residenza, domicilio e domicilioDiCura e nel caso in cui ci siano campi obbligatori non valorizzati li conto
        this.contaCampiObbligatoriRestantiAndValida(residenza, domicilio, domicilioDiCura)
    }

    contaCampiObbligatoriRestantiAndValida = (residenza, domicilio, domicilioDiCura) => {
        let residenzaClone = _.cloneDeep(residenza);
        let domicilioClone = _.cloneDeep(domicilio);
        let domicilioCuraClone = _.cloneDeep(domicilioDiCura);
        let numeroCampiObbligatoriDaValorizzare = 0;

        delete residenzaClone.idElemento;
        delete domicilioClone.idElemento;
        delete domicilioCuraClone.idElemento;

        if (Utils.isObjectNull(residenzaClone.nazione)) {
            delete residenzaClone.nazione;
            numeroCampiObbligatoriDaValorizzare += Object.keys(residenzaClone).filter(key => !this.validaCampi(residenzaClone, key)).length;
        } else {
            if (Utils.isObjectNull(residenzaClone.nazione))
                numeroCampiObbligatoriDaValorizzare += 1;
        }

        if (this.state.datiResidenza.residenzaDiversaDaDomicilio)
            numeroCampiObbligatoriDaValorizzare += Object.keys(domicilioClone).filter(key => !this.validaCampi(domicilioClone, key)).length;

        if (this.state.datiDomicilio.domicilioDiversoDaDomicilioCure)
            numeroCampiObbligatoriDaValorizzare += Object.keys(domicilioCuraClone).filter(key => !this.validaCampi(domicilioCuraClone, key)).length;

        this.props.onChangeRequiredFieldsNumber("sottoSezioneContattiResidenzaDomicilio", numeroCampiObbligatoriDaValorizzare);
        this.props.onValidaSezione("isResidenzaDomicilioValida", numeroCampiObbligatoriDaValorizzare === 0);
    }

    validaCampi = (object, key) => {
        return key === "cap" ? Utils.isValidCap(object[key]) : !Utils.isStringEmptyOrNullOrUndefined(object[key]);
    }

    getCodice = (object, objectName) => {
        if (object.hasOwnProperty("comune"))
            object.comune = !Utils.isObjectNull(this.state[objectName].comune) ? this.state[objectName].comune.codice : "";

        object.provincia = !Utils.isObjectNull(this.state[objectName].provincia) ? this.state[objectName].provincia.codice : "";
        object.aulss = !Utils.isObjectNull(this.state[objectName].aulss) ? this.state[objectName].aulss.value : null;
        object.distretto = !Utils.isObjectNull(this.state[objectName].distretto) ? this.state[objectName].distretto.value : null;
    }

    getAulssSelectOptions = (comuneObject) => {
        let aulssOptions;
        if (!Utils.isObjectNull(comuneObject)) {
            aulssOptions = AulssUtils.getListaAulssByComune(comuneObject.codice);
            if (aulssOptions.length > 0) {
                aulssOptions = aulssOptions.map(aulss => {
                    return {value: aulss.id, label: aulss.descrizione}
                });
            } else {
                aulssOptions = [];
            }
        } else {
            aulssOptions = this.listaAulssSelect;
        }

        return aulssOptions;
    }

    isFromA0 = (indirizzoA0, field, a0Data, objectName) => {
        return indirizzoA0 !== null && indirizzoA0.hasOwnProperty(field) && !indirizzoA0[field]
            && (objectName === "datiDomicilio" ? this.state.previousSelectionResidenzaDiversaDaDomicilio == null || this.state.previousSelectionResidenzaDiversaDaDomicilio : true)
            && !Utils.isStringEmptyOrNullOrUndefined(a0Data)
    }

    getInfoFromA0 = (idElemento, objectName) => {
        let indirizziA0 = this.props.datiGeneraliA0?.mascheraModificabilita?.indirizzi;
        let indirizzoA0 = null;
        if (indirizziA0 !== undefined && indirizziA0.length > 0) {
            indirizzoA0 = indirizziA0.find(el => el.idElemento === idElemento);
            indirizzoA0 = indirizzoA0 !== undefined && !Utils.isObjectNull(indirizzoA0) ? indirizzoA0 : null;
            if (objectName === "datiResidenza" && indirizzoA0) {
                indirizzoA0 = {
                    ...indirizzoA0,
                    distretto: this.props.datiGeneraliA0?.mascheraModificabilita?.distrettoResidenza ?? false
                };
            }
        }

        return indirizzoA0;
    }

    renderCampiInput = (objectState, forceVisibilityInRequiredAccordion) => {
        let listaProvince = objectState === "datiDomicilioDiCura" ? this.listaProvince.filter(provincia => provincia.codiceRegione === "05") : this.listaProvince;
        let listaComuni = objectState === "datiDomicilioDiCura" ? this.listaComuni.filter(comune => listaProvince.find(provincia => provincia.codice === comune.codiceProvincia)) : this.listaComuni;
        let listaAulssSelectOptions = this.getAulssSelectOptions(this.state[objectState].comune);
        let comuniSuggestions;
        let provinceSuggestions;
        let nazioniSuggestions;
        let listaDistrettiSelect = [];

        let tipoContatto = objectState === "datiResidenza" ? "residenza" : objectState === "datiDomicilio" ? "domicilio" : "domicilio di cura";

        let modificabilitaIndirizzoA0 = this.getInfoFromA0(this.state[objectState].idElemento, objectState);

        let isRequiredField = objectState === "datiResidenza"
            || (objectState === "datiDomicilio" && this.state.datiResidenza.residenzaDiversaDaDomicilio)
            || (objectState === "datiDomicilioDiCura" && this.state.datiDomicilio.domicilioDiversoDaDomicilioCure);

        if (!Utils.isObjectNull(this.state[objectState].provincia))
            comuniSuggestions = listaComuni.filter(comune => comune.codiceProvincia === this.state[objectState].provincia.codice)
        else
            comuniSuggestions = listaComuni;

        if (!Utils.isObjectNull(this.state[objectState].aulss)) {
            let listaComuniByAulss = AulssUtils.getComuniByAulssId(this.state[objectState].aulss.value);
            comuniSuggestions = comuniSuggestions.filter(comune => listaComuniByAulss.some(comuneAulss => comuneAulss === comune.codice));
        }
        comuniSuggestions = comuniSuggestions.map(comune => this.getOggettoComune(comune));

        provinceSuggestions = !Utils.isObjectNull(this.state[objectState].comune?.codice)
            ? listaProvince.filter(provincia => provincia.codice === this.state[objectState].comune.codice.substring(0, 3))
            : listaProvince;
        provinceSuggestions = provinceSuggestions.map(provincia => this.getOggettoProvincia(provincia));

        if (objectState === "datiResidenza") {
            nazioniSuggestions = !Utils.isObjectNull(this.state.datiResidenza.nazione?.codice)
                ? this.listaNazioni.filter(nazione => nazione.codice === this.state.datiResidenza.nazione.codice)
                : this.listaNazioni;
            nazioniSuggestions = nazioniSuggestions.map(nazione => this.getOggettoNazione(nazione));
        }
        
        if (!Utils.isObjectNull(this.state[objectState].aulss)) {
            listaDistrettiSelect=this.getDistrettiSelectOptions(this.state[objectState].aulss.value);
        }

        let propA0 = objectState === "datiResidenza" ? "residenzaA0" : objectState === "datiDomicilio"
            ? "domicilioA0" : "domicilioDiCuraA0";

        return (
            objectState !== "datiResidenza" || Utils.isObjectNull(this.state[objectState].nazione)
                ? <FieldsGroup fieldLabel={<span className="f-weight-600">{_.upperFirst(tipoContatto)}</span>}
                               pageState={this.props.pageState}
                               fieldInAccordionReadOnly={!!this.props.accordionReadOnly}>
                    <FieldsRow>
                        <RoleBasedInput
                            fieldId={tipoContatto === "residenza" ? "VIA_PIAZ.RESIDENZA.ANAGRAFEPAZIENTE" :
                                tipoContatto === "domicilio" ? "VIA_PIAZ.DOMICILIO.ANAGRAFEPAZIENTE" : "VIA_PIAZ.DOMICILIODICURA.ANAGRAFEPAZIENTE"}
                            pageState={this.props.pageState}
                            placeholder={"Inserisci Via/Piazza di " + tipoContatto}
                            field={"indirizzo"}
                            onChange={(field, elem) => this.handleAggiornaSottosezioneResidenzaDomicilio(objectState, field, elem.target.value)}
                            id={"inputViaPiazza" + tipoContatto.replace(/\s+/g, '')}
                            value={this.state[objectState].indirizzo}
                            fieldLabel={'Via/Piazza'}
                            fieldRequired={isRequiredField}
                            forceFieldRequiredVisual={forceVisibilityInRequiredAccordion}
                            forceReadOnly={this.isFromA0(modificabilitaIndirizzoA0, "via", this.props[propA0].indirizzo, objectState)}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={true}
                        />
                        <RoleBasedInput
                            fieldId={tipoContatto === "residenza" ? "NUM.RESIDENZA.ANAGRAFEPAZIENTE" :
                                tipoContatto === "domicilio" ? "NUM.DOMICILIO.ANAGRAFEPAZIENTE" : "NUM.DOMICILIODICURA.ANAGRAFEPAZIENTE"}
                            pageState={this.props.pageState}
                            placeholder={"Inserisci numero di " + tipoContatto}
                            field={"numero"}
                            onChange={(field, elem) => this.handleAggiornaSottosezioneResidenzaDomicilio(objectState, field, elem.target.value)}
                            id={"inputNumero" + tipoContatto.replace(/\s+/g, '')}
                            value={this.state[objectState].numero}
                            fieldLabel={'N.'}
                            fieldRequired={isRequiredField}
                            forceFieldRequiredVisual={forceVisibilityInRequiredAccordion}
                            forceReadOnly={this.isFromA0(modificabilitaIndirizzoA0, "numero", this.props[propA0].numero, objectState)}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={true}
                        />
                        <RoleBasedAutocomplete
                            fieldId={tipoContatto === "residenza" ? "COM.RESIDENZA.ANAGRAFEPAZIENTE" :
                                tipoContatto === "domicilio" ? "COM.DOMICILIO.ANAGRAFEPAZIENTE" : "COM.DOMICILIODICURA.ANAGRAFEPAZIENTE"}
                            pageState={this.props.pageState}
                            id={"autocompleteComune" + tipoContatto.replace(/\s+/g, '')}
                            keyField={"codice"}
                            descriptionField={"descrizione"}
                            onChange={(field, elem) => this.handleAggiornaSottosezioneResidenzaDomicilio(objectState, field, elem)}
                            value={this.state[objectState].comune}
                            field={"comune"}
                            placeholder={"Inserisci comune di " + tipoContatto}
                            suggestions={comuniSuggestions}
                            noSuggestions={(objectState === "datiDomicilioDiCura") ? "Errore: Comune non in regione Veneto" : "Errore: Comune non esistente"}
                            fieldLabel={`Comune di ${tipoContatto}`}
                            fieldRequired={isRequiredField}
                            forceFieldRequiredVisual={forceVisibilityInRequiredAccordion}
                            forceReadOnly={this.isFromA0(modificabilitaIndirizzoA0, "comune", this.props[propA0].comune, objectState)}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={true}
                        />
                        <RoleBasedAutocomplete
                            fieldId={tipoContatto === "residenza" ? "PROV.RESIDENZA.ANAGRAFEPAZIENTE" :
                                tipoContatto === "domicilio" ? "PROV.DOMICILIO.ANAGRAFEPAZIENTE" : "PROV.DOMICILIODICURA.ANAGRAFEPAZIENTE"}
                            pageState={this.props.pageState}
                            id={"autocompleteProvincia" + tipoContatto.replace(/\s+/g, '')}
                            keyField={"codice"}
                            descriptionField={"descrizione"}
                            onChange={(field, elem) => this.handleAggiornaSottosezioneResidenzaDomicilio(objectState, field, elem)}
                            value={this.state[objectState].provincia}
                            field={"provincia"}
                            placeholder={"Inserisci provincia di " + tipoContatto}
                            suggestions={provinceSuggestions}
                            noSuggestions={(objectState === "datiDomicilioDiCura") ? "Errore: Provincia non in regione Veneto" : "Errore: Provincia non relativa al comune inserito"}
                            fieldLabel={`Provincia di ${tipoContatto}`}
                            fieldRequired={isRequiredField}
                            forceFieldRequiredVisual={forceVisibilityInRequiredAccordion}
                            forceReadOnly={this.isFromA0(modificabilitaIndirizzoA0, "provincia", this.props[propA0].provincia, objectState)}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={true}
                        />
                    </FieldsRow>
                    <FieldsRow>
                        <RoleBasedInput
                            fieldId={tipoContatto === "residenza" ? "CAP.RESIDENZA.ANAGRAFEPAZIENTE" :
                                tipoContatto === "domicilio" ? "CAP.DOMICILIO.ANAGRAFEPAZIENTE" : "CAP.DOMICILIODICURA.ANAGRAFEPAZIENTE"}
                            fieldLabel={'CAP'}
                            fieldRequired={isRequiredField}
                            forceFieldRequiredVisual={forceVisibilityInRequiredAccordion}
                            placeholder={"Inserisci CAP di " + tipoContatto}
                            field={"cap"}
                            onChange={(field, elem) => this.handleAggiornaSottosezioneResidenzaDomicilio(objectState, field, elem.target.value)}
                            id={"inputCap" + tipoContatto.replace(/\s+/g, '')}
                            value={this.state[objectState].cap}
                            maxlength={"5"}
                            autoComplete={"off"}
                            forceReadOnly={this.isFromA0(modificabilitaIndirizzoA0, "cap", this.props[propA0].cap, objectState)}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={true}
                            validator={Utils.isValidCap}
                            invalidText={'Inserire un CAP valido'}
                            pageState={this.props.pageState}
                        />
                        <RoleBasedSelect
                            fieldId={tipoContatto === "residenza" ? "AULSS_RES.RESIDENZA.ANAGRAFEPAZIENTE" :
                                tipoContatto === "domicilio" ? "AULSS_DOM.DOMICILIO.ANAGRAFEPAZIENTE" : "AULSS_DOM.DOMICILIODICURA.ANAGRAFEPAZIENTE"}
                            pageState={this.props.pageState}
                            id={"selectAulss" + tipoContatto.replace(/\s+/g, '')}
                            className={"aulss"}
                            options={listaAulssSelectOptions}
                            value={this.state[objectState].aulss}
                            onChange={(elem) => this.handleAggiornaSottosezioneResidenzaDomicilio(objectState, "aulss", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: AULSS non esistente"}
                            fieldLabel={`AULSS di ${tipoContatto}`}
                            fieldRequired={isRequiredField}
                            forceFieldRequiredVisual={forceVisibilityInRequiredAccordion}
                            forceReadOnly={this.isFromA0(modificabilitaIndirizzoA0, "aulss", this.props[propA0].aulss, objectState)}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            field={"aulss"}
                            disableDoubleInput={true}
                        />
                        <RoleBasedSelect
                            fieldId={tipoContatto === "residenza" ? "DISTR_RES.RESIDENZA.ANAGRAFEPAZIENTE" :
                                tipoContatto === "domicilio" ? "DISTR_DOM.DOMICILIO.ANAGRAFEPAZIENTE" : "DISTR_DOM.DOMICILIODICURA.ANAGRAFEPAZIENTE"}
                            pageState={this.props.pageState}
                            id={"selectDistretto" + tipoContatto.replace(/\s+/g, '')}
                            className={"distretto"}
                            options={listaDistrettiSelect}
                            value={this.state[objectState].distretto}
                            onChange={(elem) => this.handleAggiornaSottosezioneResidenzaDomicilio(objectState, "distretto", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => listaDistrettiSelect.length > 0 ? "Errore: Distretto non esistente" : "Inserire un AULSS prima di selezionare un distretto"}
                            fieldLabel={`Distretto di ${tipoContatto}`}
                            fieldRequired={isRequiredField}
                            forceFieldRequiredVisual={forceVisibilityInRequiredAccordion}
                            forceReadOnly={this.isFromA0(modificabilitaIndirizzoA0, "distretto", this.props[propA0].distretto, objectState)}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            field={"distretto"}
                            disableDoubleInput={true}
                        />
                    </FieldsRow>
                </FieldsGroup>
                : <FieldsGroup fieldLabel={<span className="f-weight-600">{_.upperFirst(tipoContatto)}</span>}
                               pageState={this.props.pageState}
                               fieldInAccordionReadOnly={!!this.props.accordionReadOnly}>
                    <FieldsRow>
                        <RoleBasedAutocomplete
                            /* TODO: Manca il campo fieldId corrispondente */
                            fieldId={tipoContatto === "residenza" ? "STATO_EST.RESIDENZA.ANAGRAFEPAZIENTE" :
                                tipoContatto === "domicilio" ? "STATO_EST.RESIDENZA.ANAGRAFEPAZIENTE" : "STATO_EST.RESIDENZA.ANAGRAFEPAZIENTE"}
                            pageState={this.props.pageState}
                            id={"autocompleteStatoEstero" + tipoContatto.replace(/\s+/g, '')}
                            keyField={"codice"}
                            descriptionField={"descrizione"}
                            onChange={(field, elem) => this.handleAggiornaSottosezioneResidenzaDomicilio("datiResidenza", field, elem)}
                            value={this.state.datiResidenza.nazione}
                            field={"nazione"}
                            placeholder={"Inserisci Stato estero di " + tipoContatto}
                            suggestions={nazioniSuggestions}
                            noSuggestions={"Errore: Stato estero non esistente"}
                            fieldLabel={'Stato estero'}
                            fieldRequired={isRequiredField}
                            showFieldIfRequiredAccordionPage={forceVisibilityInRequiredAccordion}
                            forceReadOnly={this.isFromA0(modificabilitaIndirizzoA0, "nazione", this.props[propA0].nazione, objectState)}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={true}
                        />
                    </FieldsRow>
                </FieldsGroup>
        );
    }

    handleAggiornaSezioneAulssAssistenza = (field, elem) => {
        let datiAulssAssistenza = _.cloneDeep(this.state.datiAulssAssistenza);
        if (field === "aulss") {
            datiAulssAssistenza.distretto = null;
        }
        datiAulssAssistenza[field] = (elem?.codice || elem?.value) ?? null;
        this.setState({datiAulssAssistenza}, () => this.controllaCampi());
    }

    renderSezioneAulssAssistenza = () => {
        let listaDistrettiSelect = [];
        let siglaProvincia = null;
        let aulssAssistenza = null;
        let distrettoAssistenza = null;

        if (this.state.datiAulssAssistenza.provincia) {
            siglaProvincia = this.listaSigleProvince.find(el => el.value === this.state.datiAulssAssistenza.provincia);
        } 
        
        if(!siglaProvincia && this?.listaSigleProvince?.length === 1){
            siglaProvincia = this.listaSigleProvince[0];            
        }

        if (!Utils.isObjectNull(this.state.datiAulssAssistenza)) {
            let aulss = AulssUtils.getAulssByCodiceAulss(this.state.datiAulssAssistenza.aulss);
            aulssAssistenza = {value: aulss.id, label: "Azienda " + aulss.nome + " " + aulss.descrizione};
            let distretti = AulssUtils.getDistrettiByAulssId(aulssAssistenza.value);
            distretti.forEach(el => listaDistrettiSelect.push({value: el.id, label: el.descrizione}));
            if (this.state.datiAulssAssistenza.distretto) {
                distrettoAssistenza = listaDistrettiSelect.find(el => el.value === this.state.datiAulssAssistenza.distretto);
            }
        }

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"PROVINCIA.AULSSDIASSISTENZA.ANAGRAFEPAZIENTE"}
                        pageState={this.props.pageState}
                        id={"selectSiglaProvinciaAssistenza"}
                        options={this.listaSigleProvince}
                        value={siglaProvincia ?? ""}
                        onChange={(elem) => this.handleAggiornaSezioneAulssAssistenza("provincia", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore:  Sigla provincia non esistente"}
                        fieldLabel={"Provincia di assistenza"}
                        forceReadOnly={(!this.props.datiGeneraliA0?.mascheraModificabilita?.provinciaAssistenza && !Utils.isObjectNull(this.props.provinciaAssistenza))
                                        || this?.listaSigleProvince?.length === 1}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        field={"provincia"}
                        disableDoubleInput={true}
                    />
                    <RoleBasedSelect
                        fieldId={"AULSS.AULSSDIASSISTENZA.ANAGRAFEPAZIENTE"}
                        pageState={this.props.pageState}
                        id={"selectAulssAssistenza"}
                        className={"aulss"}
                        options={this.listaAulssSelect}
                        value={aulssAssistenza}
                        onChange={(elem) => this.handleAggiornaSezioneAulssAssistenza("aulss", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: AULSS assistenza non esistente"}
                        fieldLabel={"AULSS di assistenza"}
                        forceReadOnly={!this.props.datiGeneraliA0?.mascheraModificabilita?.aulssAssistenza && !Utils.isObjectNull(this.props.aulssAssistenza)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        field={"aulss"}
                        disableDoubleInput={true}
                    />
                    <RoleBasedSelect
                        fieldId={"DISTRETTO.AULSSDIASSISTENZA.ANAGRAFEPAZIENTE"}
                        pageState={this.props.pageState}
                        id={"selectDistrettoAssistenza"}
                        className={"distretto"}
                        options={listaDistrettiSelect}
                        value={distrettoAssistenza}
                        onChange={(elem) => this.handleAggiornaSezioneAulssAssistenza("distretto", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => listaDistrettiSelect.length > 0 ? "Errore: Distretto non esistente" : "Inserire un AULSS prima di selezionare un distretto"}
                        fieldLabel={"Distretto di assistenza"}
                        forceReadOnly={!this.props.datiGeneraliA0?.mascheraModificabilita?.distrettoAssistenza && !Utils.isObjectNull(this.props.distrettoAssistenza)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        field={"distretto"}
                        disableDoubleInput={true}
                    />
                </FieldsRow>
            </>
        );
    }

    isRadioButtonNotEditable = (field) => {
        let residenza = _.cloneDeep(this.state.datiResidenza);
        let domicilio = _.cloneDeep(this.state.datiDomicilio);
        let differences = 0;

        // Se nella sezione Domicilio c'è almeno un campo not null
        if (Object.keys(domicilio).some(k => k !== "idElemento" && !Utils.isObjectNull(domicilio[k]))) {
            let modificabilitaResidenzaA0 = this.getInfoFromA0(residenza.idElemento, "datiResidenza");
            let modificabilitaDomicilioA0 = this.getInfoFromA0(domicilio.idElemento, "datiDomicilio");
            let residenzaMap = new Map(Object.entries(residenza));
            residenzaMap.delete("idElemento");
            let domicilioMap = new Map(Object.entries(domicilio));
            domicilioMap.delete("idElemento");

            // Se sia in Residenza che in Domicilio la coppia campo-valore arriva da A0 e il valore di Domicilio è diverso dal valore di Residenza
            // allora il radio button viene disabilitato senza possibilità di modificarlo
            domicilioMap.forEach((value, key) => {
                let residenzaFieldByKey = residenzaMap.get(key);
                const a0Differences = this.isFromA0(modificabilitaDomicilioA0, (key === "indirizzo" ? "via" : key), this.props.domicilioA0, "datiDomicilio")
                    && this.isFromA0(modificabilitaResidenzaA0, (key === "indirizzo" ? "via" : key), this.props.residenzaA0, "datiResidenza")
                    && !_.isEqual(value, residenzaFieldByKey);
                if (a0Differences) differences++;
            });
        }

        return field === "residenzaDiversaDaDomicilio" && differences > 0;
    }

    renderRadioChoice = (objectState, title, id, field, roleBasedFieldId) => {
        return <FieldsRow>
            <RoleBasedRadio
                fieldId={roleBasedFieldId}
                field={field}
                fieldLabel={title}
                pageState={this.props.pageState}
                id={id}
                group={field}
                items={oggettiUtili.rispostaBreve}
                forceReadOnly={this.isRadioButtonNotEditable(field)}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                value={this.state[objectState][field]}
                onChange={(f, v) => {
                    let resetObjectName = f === "residenzaDiversaDaDomicilio" ? "datiDomicilio" : "datiDomicilioDiCura";
                    let resetObject = _.cloneDeep(this.state[resetObjectName]);
                    let objectStateClone = _.cloneDeep(this.state[objectState]);
                    objectStateClone[f] = v;
                    if (!_.isEqual(this.state[objectState][f], v)) this.resetSezione(resetObject);
                    this.setState({
                        [objectState]: objectStateClone,
                        [resetObjectName]: resetObject,
                        previousSelectionResidenzaDiversaDaDomicilio: f === "residenzaDiversaDaDomicilio" ? !objectStateClone[f] : null
                    }, () => this.controllaCampi());
                }}
            />
        </FieldsRow>;
    }

    renderBodyResidenzaEDomicilio = () => {
        return (
            <>
                {this.renderCampiInput("datiResidenza", true)}
                {this.renderRadioChoice(
                    "datiResidenza",
                    "Residenza diversa da domicilio?",
                    "selectResidenzaDiversoDaDomicilio",
                    "residenzaDiversaDaDomicilio",
                    "VIA_PIAZ.RESIDENZA.ANAGRAFEPAZIENTE")}
                {this.state.datiResidenza.residenzaDiversaDaDomicilio
                    ? this.renderCampiInput("datiDomicilio", true)
                    : null}
                {this.renderRadioChoice(
                    "datiDomicilio",
                    "Domicilio di cura diverso da Domicilio?",
                    "selectDomicilioDiversoDaDomicilioDiCura",
                    "domicilioDiversoDaDomicilioCure",
                    "VIA_PIAZ.DOMICILIODICURA.ANAGRAFEPAZIENTE")}
                {this.state.datiDomicilio.domicilioDiversoDaDomicilioCure
                    ? this.renderCampiInput("datiDomicilioDiCura", true)
                    : null}
                {!this.context.showOnlyRequiredFields && <div className={"row pt-4"}>
                    <div className={"col-12"}>
                        <span className="f-weight-600">AULSS di assistenza</span>
                    </div>
                </div>}
                {this.renderSezioneAulssAssistenza()}
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"residenza-domicilio-domicilioDiCura"}
                title={"Residenza e domicilio"}
                required={this.state.datiResidenza.residenzaDiversaDaDomicilio || this.state.datiDomicilio.domicilioDiversoDaDomicilioCure}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodyResidenzaEDomicilio()}</AccordionSezioneRichiesta>
        );
    }
}

ResidenzaEDomicilio.propTypes = propTypes;
ResidenzaEDomicilio.contextType = RoleBasedContext;
