import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {
    FieldsRow,
    RoleBasedInput,
    RoleBasedPhoneInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import {isMadrelingua} from "../datiGenerali/DatiGenerali";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";

const propTypes = {
    fiduciarioForm: PropTypes.any,
    onChangeDatiFiduciari: PropTypes.func,
    onValidaSezione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class Fiduciario extends AccordionSecondLevel {
    state = {
        fiduciario: _.cloneDeep(this.props.fiduciarioForm)
    }

    isSottosezioneFiduciarioValida = false;

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.fiduciarioForm, this.props.fiduciarioForm))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let fiduciario = _.cloneDeep(this.props.fiduciarioForm);
        fiduciario.madrelingua = oggettiUtili.rispostaBreve.find(el => el.value === fiduciario.madrelingua);
        fiduciario.livello = oggettiUtili.linguaItalianaParlataCompresa.find(el => el.value === fiduciario.livello);

        if (fiduciario.descrizione.length > 0) {
            fiduciario.descrizione = fiduciario.descrizione.map(el => oggettiUtili.elencoLingue.find(el2 => el2.value === el));
        }

        this.setState({fiduciario: fiduciario}, () => {
            this.contaCampiObbligatoriDaValorizzareAndValida(this.state.fiduciario);
        });
    }

    handleAggiornaForm = (field, elem) => {
        let fiduciario = _.cloneDeep(this.state.fiduciario);
        fiduciario[field] = elem;

        if (field === "madrelingua" && (!_.isEqual(elem, this.state.fiduciario[field]))) {
            fiduciario.livello = null;
            fiduciario.descrizione = [];
        }

        this.setState({fiduciario: fiduciario}, () => {
            this.controllaCampi();
        });
    }

    controllaCampi = () => {
        let fiduciario = _.cloneDeep(this.state.fiduciario);

        // formatto l'oggetto per inviarlo come parametro di input al metodo salvaBozza nel componente padre "CompilaRichiesta"
        fiduciario.madrelingua = fiduciario.madrelingua?.value;
        fiduciario.livello = fiduciario.livello?.value;
        if (fiduciario.descrizione.length > 0) {
            fiduciario.descrizione = fiduciario.descrizione.map(el => el?.value ?? null).filter(el => el !== null);
        }
        fiduciario = _.mapValues(fiduciario, el => el === null || el === undefined || el === "" ? null : el);
        this.props.onChangeDatiFiduciari("fiduciario", fiduciario);

        // controllo la validità dei vari campi dell'oggetto fiduciario e nel caso in cui ci siano campi obbligatori non valorizzati li conto
        this.contaCampiObbligatoriDaValorizzareAndValida(fiduciario);
    }

    contaCampiObbligatoriDaValorizzareAndValida = (fiduciario) => {
        let isSezioneValida;
        let isCodiceFiscaleValido = false;
        let isTelefonoValido = false;
        let isEmailValida = false;

        if (!Utils.isObjectNull(fiduciario)) {
            isCodiceFiscaleValido = Utils.isStringEmptyOrNullOrUndefined(fiduciario.identificativoUtente) || Utils.isValidCodiceFiscale(fiduciario.identificativoUtente);
            isTelefonoValido = Utils.isStringEmptyOrNullOrUndefined(fiduciario.telefono) || Utils.isValidTelefono(fiduciario.telefono);
            isEmailValida = Utils.isStringEmptyOrNullOrUndefined(fiduciario.email) || Utils.isValidEmail(fiduciario.email);

            isSezioneValida = isCodiceFiscaleValido && isTelefonoValido && isEmailValida;
        } else {
            isSezioneValida = true;
        }

        this.isSottosezioneFiduciarioValida = isSezioneValida;

        this.props.onValidaSezione("isFiduciarioValido", isSezioneValida);
    }

    renderLinguaParlata = (linguaParlata, index, fiduciario) => {
        return (
            <RoleBasedSelect
                fieldId={'LING_PARL.FIDUCIARIO.ANAGRAFEPAZIENTE'}
                pageState={this.props.pageState}
                parentClass={"mb-0"}
                id={"selectLinguaParlata"}
                className={"selectLinguaParlata"}
                options={oggettiUtili.elencoLingue}
                value={linguaParlata}
                onChange={(elem) => {
                    let objectClone = _.cloneDeep(fiduciario);
                    objectClone.descrizione[index] = elem;
                    this.setState({fiduciario: objectClone}, () => this.controllaCampi());
                }}
                isSearchable={false}
                fieldLabel={"Lingua parlata"}
                field={"linguaParlata"}
                disableDoubleInput={true}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            />
        );
    }

    addNewLinguaParlata = () => {
        let fiduciario = _.cloneDeep(this.state.fiduciario);
        fiduciario.descrizione.push(null);
        this.setState({fiduciario}, () => this.controllaCampi())
    }

    removeLinguaParlata = (index) => {
        let fiduciario = _.cloneDeep(this.state.fiduciario);
        fiduciario.descrizione.splice(index, 1);
        this.setState({fiduciario}, () => this.controllaCampi());
    }

    renderBodyFiduciario = () => {
        let fiduciario = _.cloneDeep(this.state.fiduciario);
        delete fiduciario.id;

        return (
            <>
                {!this.context.showOnlyRequiredFields && (!this.context.forceReadOnly || !Utils.isObjectNull(fiduciario)) &&
                    <div className="row py-3">
                        <div className="col-12">
                            <span className="f-weight-600">Fiduciario</span>
                        </div>
                    </div>}
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'COGN.FIDUCIARIO.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci cognome"
                        field="cognome"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="inputCognomeFiduciario"
                        value={fiduciario?.cognome}
                        fieldLabel={"Cognome"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldId={'NOME.FIDUCIARIO.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci nome"
                        field="nome"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="inputNomeFiduciario"
                        value={fiduciario?.nome}
                        fieldLabel={"Nome"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldLabel={"Codice fiscale"}
                        fieldId={'COD_FISC.FIDUCIARIO.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci codice fiscale"
                        field="identificativoUtente"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="inputCodiceFiscaleFiduciario"
                        value={fiduciario?.identificativoUtente}
                        maxlength={"16"}
                        validator={Utils.isValidCodiceFiscale}
                        invalidText={"Inserire un codice fiscale valido"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'INDIR.FIDUCIARIO.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci indirizzo"
                        field="indirizzo"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="inputIndirizzoFiduciario"
                        value={fiduciario?.indirizzo}
                        fieldLabel={"Indirizzo"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldId={"NUMEROCIVICO.FIDUCIARIO.ANAGRAFEPAZIENTE"}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci numero civico"}
                        field={"numero"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"inputNumeroCivico"}
                        value={fiduciario?.numero}
                        fieldLabel={'N°'}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedPhoneInput
                        fieldLabel={"Telefono"}
                        fieldId={'TEL.FIDUCIARIO.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci numero di telefono"}
                        field={"telefono"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"inputNumeroTelefonoFiduciario"}
                        value={fiduciario?.telefono}
                        phonePrefix={null}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldLabel={"E-mail"}
                        fieldId={'EMAIL.FIDUCIARIO.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci e-mail"}
                        field={"email"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"inputEmailFiduciario"}
                        value={fiduciario?.email}
                        validator={Utils.isValidEmail}
                        invalidText={"Inserire un'email valida"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'ITA_MADREL.FIDUCIARIO.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        id={"selectMadrelingua"}
                        className={"selectMadrelingua"}
                        options={oggettiUtili.rispostaBreve}
                        value={fiduciario.madrelingua}
                        onChange={(elem) => this.handleAggiornaForm("madrelingua", elem)}
                        isSearchable={false}
                        fieldLabel={"Italiano madrelingua?"}
                        field={"madrelingua"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                <FieldsRow containerClass={"align-self-center"}
                           childClass={"align-self-start"}>
                    {!isMadrelingua(fiduciario.madrelingua) &&
                    <RoleBasedSelect
                        fieldId={'LING_ITA_PARL_COMPR.FIDUCIARIO.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        id={"selectLinguaItalianaParlataCompresa"}
                        className={"selectLinguaItalianaParlataCompresa"}
                        options={oggettiUtili.linguaItalianaParlataCompresa}
                        value={fiduciario?.livello}
                        onChange={(elem) => this.handleAggiornaForm("livello", elem)}
                        isSearchable={false}
                        fieldLabel={"Lingua italiana parlata e compresa"}
                        field={"livello"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />}
                    {!isMadrelingua(fiduciario.madrelingua) &&
                    <SectionList
                        title={'Lingua parlata'}
                        data={fiduciario.descrizione}
                        renderSection={(e, i) => this.renderLinguaParlata(e, i, fiduciario)}
                        addNewSectionCallback={this.addNewLinguaParlata}
                        removeSectionCallback={this.removeLinguaParlata}
                        addButtonVisibilityHandler={!isMadrelingua(fiduciario.madrelingua)
                        && (fiduciario.descrizione?.length === 0 || fiduciario.descrizione?.every(el => !Utils.isObjectNull(el)))}
                        pageState={this.props.pageState}
                        keyFieldId={'LING_PARL.FIDUCIARIO.ANAGRAFEPAZIENTE'}
                        indentOffset={0}
                        field={'descrizione'}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />}
                </FieldsRow>
            </>
        );
    }

    render() {
    return (
        !this.context.showOnlyRequiredFields && (!this.context.forceReadOnly) &&
        <AccordionSezioneRichiesta
            idAccordion={"fiduciario"}
            title={"Fiduciario (art. 4 della Legge 219 del 22 dicembre 2017)"}
            required={false}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            accordionReadOnly={this.props.accordionReadOnly}
            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
        >{this.renderBodyFiduciario()}</AccordionSezioneRichiesta>
    );
    }
}

Fiduciario.propTypes = propTypes;
Fiduciario.contextType = RoleBasedContext;
