import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "web-client-archetype";
import ModalHelper from "../../modale/ModalHelper";
import * as $ from "jquery";
import RichiestaADIService from "../../../service/RichiestaADIService";
import { Link } from "react-router-dom";
import routePath from "../../../utils/route/route-path.json";
import _ from "lodash";
import Utils from "../../../utils/Utils";
import { mostraSpinner, nascondiSpinner } from "../../../App";

const propTypes = {
    uuidRichiesta: PropTypes.string,
    chiudiPopupCopiaRichiesta: PropTypes.func,
    showPopup: PropTypes.bool,
    confermaCopiaRichiesta:PropTypes.func
}

const bodyModaleText = {
    top: "Stai per procedere con la copia della richiesta. La nuova richiesta sarà precompilata con i dati inseriti nella precedente richiesta di cure domiciliari",
    down: "Vuoi confermare l'operazione?"
};
const bodyModaleOperazioneEseguitaText = "La richiesta è stata correttamente copiata.";

const titleModale = "Attenzione!";
const titleModaleOperazioneEseguita = "Operazione completata";

const idModale = "modaleCopiaRichiesta";
const idModaleOperazioneEseguita = "modaleCopiaRichiestaOperazioneEseguita";

export default class PopupConfermaCopiaRichiesta extends Component {
    state = {
        showModaleOperazioneEseguita: false
    }

    closeModaleStyle = () => {
        $(".modal-backdrop").css("display", "none");
        $(".modal-open").removeClass("modal-open");
        $("body").attr("style", "padding-right: 0 !important");
    }

    annullaCopiaRichiesta = () => {
        this.closeModaleStyle();
        this.props.chiudiPopupCopiaRichiesta();
    }

    showOperazioneEseguita = () => {
        this.setState({ showModaleOperazioneEseguita: true });
    }

    confermaCopiaRichiesta = () => {
        let uuidRichiesta = this.props.uuidRichiesta;
        mostraSpinner();
        RichiestaADIService.copiaRichiesta(uuidRichiesta).subscribe((response) => {
            if (response?.msg === "ERRORE") {                                
                this.annullaCopiaRichiesta();
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "critical",
                    text:  response?.data?.errors?.pop().message || response?.data?.errore || response?.data?.message
                });
                nascondiSpinner();
                return;                                
            }            
            nascondiSpinner();
            if(this.props.confermaCopiaRichiesta) {
                this.closeModaleStyle();
                this.props.confermaCopiaRichiesta(response?.data); 
            }        
        });
    }

    tornaHome = () => {
        this.annullaCopiaRichiesta();
        this.setState({ showModaleOperazioneEseguita: false })
    }

    renderModale = () => {
        let state = _.cloneDeep(this.state);

        const bodyModale = (
            <div>
                {bodyModaleText.top}
                <div><strong>{bodyModaleText.down}</strong></div>
            </div>
        );

        const bodyModaleOperazioneEseguita = (
            <div>{bodyModaleOperazioneEseguitaText}</div>
        );

        const getBody = () => (
            <>{state.showModaleOperazioneEseguita ? bodyModaleOperazioneEseguita : bodyModale}</>
        );

        const footerModale = (
            <>
                <Button
                    onClick={() => this.confermaCopiaRichiesta()}
                    id={"confermaBtn"}
                    text="Conferma"
                    className={"btn btn-adi btnOrangeOutline"}
                />
                <Button
                    onClick={() => this.annullaCopiaRichiesta()}
                    id={"annullaBtn"}
                    text="Annulla"
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    disabled={false}
                />
            </>
        );

        const footerModaleOperazioneEseguita = (
            <>                
                <Link to={{ pathname: routePath.home }}>
                    <Button
                        onClick={() => this.tornaHome()}
                        id={"tornaHomeBtn"}
                        text="Torna a Home"
                        className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                        disabled={false}
                    />
                </Link>
            </>
        );

        const getFooter = () => (
            <> {state.showModaleOperazioneEseguita ? footerModaleOperazioneEseguita : footerModale} </>
        )

        function getTitle() {
            if (state.showModaleOperazioneEseguita) return titleModaleOperazioneEseguita;
            else return titleModale;
        }

        function getId() {
            if (state.showModaleOperazioneEseguita) return idModaleOperazioneEseguita;
            else return idModale;
        }

        function getBackgroundStyle() {
            if (state.showModaleOperazioneEseguita) return "backgroundGreen";
            else return "backgroundOrange";
        }

        return (
            <>
                <ModalHelper
                    id={getId()}
                    title={getTitle()}
                    titleClassName={("modal-header d-inline text-center ").concat(getBackgroundStyle())}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={"modal-content"}
                    body={getBody()}
                    footer={getFooter()}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={"modal-body d-flex align-items-center justify-content-around text-center"}
                />
            </>
        );
    }

    render() {
        return (
            <>
                {this.renderModale()}
            </>
        )
    }

}

PopupConfermaCopiaRichiesta.propTypes = propTypes;
