import React from "react";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import PropTypes from "prop-types";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import * as _ from "lodash";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco, defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    sensorio: PropTypes.string,
    datiSensorio: PropTypes.any,
    onChangeDatiSensorio: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}
const defaultAlterazione = {
    id: null,
    tipoAlterazione: null
}

export default class SensorioBaseValutazione extends AccordionSecondLevel {

    state = {
        datiSensorio: _.cloneDeep(this.props.datiSensorio)
    }


    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiSensorio, this.props.datiSensorio)) {
            this.forceUpdate(() => this.inizializzaForm());
        }
    }

    inizializzaForm = () => {
        let datiSensorio = _.cloneDeep(this.props.datiSensorio);
        if (datiSensorio.alterazione == null) datiSensorio.alterazione = [];
        this.setState({datiSensorio: datiSensorio});
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({datiSensorio}) => {
            const esitoValutazione = "esitoValutazione".concat(this.props.sensorio);
            if (index === null && subField === null) {
                datiSensorio[field] = elem;
            } else {
                datiSensorio[field][index][subField] = elem;
            }

            if (datiSensorio[esitoValutazione] !== "02") datiSensorio.alterazione = [];

            if (!this.showCondizioneRilevata(datiSensorio[esitoValutazione])) datiSensorio.statoDopoValutazione = null;

            return {datiSensorio};
        }, this.validazioneDati);
    }

    validazioneDati() {
        const datiSensorio = _.cloneDeep(this.state.datiSensorio);
        this.props.onChangeDatiSensorio("sensorio".concat(this.props.sensorio), datiSensorio);
        let campiObbligatori = 0;
        campiObbligatori += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiSensorio?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneSensorioBaseValutazione".concat(this.props.sensorio), campiObbligatori);
    }

    addNewAlterazione = () => {
        let datiSensorio = _.cloneDeep(this.state.datiSensorio);
        let type = _.cloneDeep(defaultAlterazione);
        type.id = uuid();
        datiSensorio.alterazione.push(type);
        this.setState({datiSensorio}, this.validazioneDati);
    }

    removeAlterazione = (index) => {
        let datiSensorio = _.cloneDeep(this.state.datiSensorio);
        datiSensorio.alterazione.splice(index, 1);
        this.setState({datiSensorio}, this.validazioneDati);
    }

    renderAlterazione = (alterazione, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'M_ALT.SENSORIO' + this.props.sensorio.toUpperCase() + ".ANAMNESIFISIOLOGICA"}
                    infermiereFieldId={'I_ALT.' + this.props.sensorio.toUpperCase() + '.SENSORIO'}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm("alterazione", elem.target.value, index, "tipoAlterazione")}
                    value={alterazione.tipoAlterazione}
                    disableDoubleInput={this.props.disableDoubleInput}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    fieldLabel={'Alterazione'}
                    field={'tipoAlterazione'}
                />
            </FieldsRow>
        )
    }

    renderBodySezione = () => {
        const datiSensorio = this.state.datiSensorio;
        const esitoValutazione = "esitoValutazione".concat(this.props.sensorio)
        const fieldIdSelect = this.props.sensorio === "Olfatto" ? "OLF" : this.props.sensorio.toUpperCase()

        return <>
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={"M_ESITO_VAL_" + fieldIdSelect + ".SENSORIO" + this.props.sensorio.toUpperCase() + ".ANAMNESIFISIOLOGICA"}
                    infermiereFieldId={"I_ESITO_VAL_" + fieldIdSelect + "." + this.props.sensorio.toUpperCase() + ".SENSORIO"}
                    pageState={this.props.pageState}
                    id={"selectesitoValutazione"}
                    options={OggettiUtili.esitoValutazioneSensorioBaseValutazione}
                    value={datiSensorio[esitoValutazione]}
                    onChange={(elem) => this.handleAggiornaForm(esitoValutazione, elem)}
                    field={esitoValutazione}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Esito valutazione "}
                    fieldLabel={"Esito valutazione ".concat(this.props.sensorio.toLowerCase())}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    handleOnlyValue={true}
                />
            </FieldsRow>

            {datiSensorio[esitoValutazione] === "02" &&
                <SectionList
                    title={'Alterazione'}
                    data={this.state.datiSensorio.alterazione}
                    renderSection={this.renderAlterazione}
                    addNewSectionCallback={this.addNewAlterazione}
                    removeSectionCallback={this.removeAlterazione}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    pageState={this.props.pageState}
                    keyFieldId={'M_ALT.SENSORIO' + this.props.sensorio.toUpperCase() + ".ANAMNESIFISIOLOGICA"}
                    infermiereKeyFieldId={'I_ALT.' + this.props.sensorio.toUpperCase() + '.SENSORIO'}
                    addButtonVisibilityHandler={this.state.datiSensorio?.alterazione?.every(a => !!a.tipoAlterazione)}
                    field={'alterazione'}
                    parentJsonPath={"anamnesiFisiologica.sensorio" + this.props.sensorio + ".alterazione"}
                />}

            <ValutazioneSection
                ambito={('Sensorio - '.concat(this.props.sensorio)).toUpperCase()}
                pageState={this.props.pageState}
                parentFieldId={'SENSORIO' + this.props.sensorio.toUpperCase() + '.ANAMNESIFISIOLOGICA'}
                infParentFieldId={this.props.sensorio.toUpperCase() + '.SENSORIO'}
                appendRolePrefix={true}
                onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                valueStatoDopoValutazione={datiSensorio.statoDopoValutazione}
                onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                valueDataValutazione={datiSensorio.dataValutazione}
                onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                valueDaConsiderare={datiSensorio.daConsiderare}
                showSuggerimenti={true}
                valueSuggerimenti={datiSensorio.propostaAttivitaSuggerimenti}
                onChangeSuggerimenti={this.handleAggiornaForm}
                showAutonomia={true}
                onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                valueAutonomiaPaziente={datiSensorio.autonomiaGestionePaziente}
                onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                valueAutonomiaCaregiver={datiSensorio.autonomiaGestioneCaregiver}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                disableDoubleInput={this.props.disableDoubleInput}
                openCondition={this.showCondizioneRilevata(datiSensorio[esitoValutazione])}
            />
        </>;
    }

    showCondizioneRilevata(esitoValutazione) {
        return !!esitoValutazione && esitoValutazione !== "01";
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"sensorio" + this.props.sensorio.charAt(0).toUpperCase() + this.props.sensorio.slice(1)}
                title={"Sensorio - ".concat(this.props.sensorio)}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["anamnesiFisiologica.sensorio" + this.props.sensorio + ".alterazione",
                    "anamnesiFisiologica.sensorio" + this.props.sensorio + "propostaAttivitaSuggerimenti"],
                    [defaultAlterazione, defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }

}

SensorioBaseValutazione.propTypes = propTypes;

SensorioBaseValutazione.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}
