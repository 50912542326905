import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {buildFullRichiesta, disableButton} from "../compilaRichiestaCampiObbligatori/CompilaRichiestaCampiObbligatori";
import SessionRequest, {defaultAnagrafePaziente} from "../../utils/SessionRequest";
import * as _ from "lodash";
import InserimentoRichiesta from "../inserimentoRichiesta/InserimentoRichiesta";
import Utils, {closeModaleStyle} from "../../utils/Utils";
import AccordionHelper from "../../service/AccordionHelper";
import $ from "jquery";
import enumPaginaCompila from "../../enum/enumPaginaCompila.json";
import {getCountMandatoryFieldsMissing} from "../anagrafeRichiedente/AnagrafeRichiedente";
import enumRichiesta from "../../enum/enumRichiesta.json";
import RichiestaADIService from "../../service/RichiestaADIService";
import ButtonHelper from "../../service/ButtonHelper";
import {map} from "rxjs/operators";
import {mostraSpinner, nascondiSpinner} from "../../App";
import AnagrafeService from "../../service/AnagrafeService";
import {forkJoin, of} from "rxjs";
import enumIndirizzo from "../../enum/enumIndirizzo.json";
import enumContatto from "../../enum/enumContatto.json";
import AnagrafePaziente, {isPazienteMinoreDi18Anni} from "../anagrafePaziente/AnagrafePaziente";
import ButtonsBoxStick from "../buttonsBoxStick/buttonsBoxStick";
import VersionedAccordion from "../VersionedAccordion";
import ValutazioneBisogniPalliativi from "../valutazioneBisogniPalliativi/ValutazioneBisogniPalliativi";
import SituazioneSociale from "../situazioneSociale/SituazioneSociale";
import DeterminantiDiSalute from "../determinantiDiSalute/DeterminantiDiSalute";
import AnamnesiFisiologica from "../anamnesiFisiologica/AnamnesiFisiologica";
import InformazioniBaseFaseRichiesta from "../informazioniBaseFaseRichiesta/InformazioniBaseFaseRichiesta";
import InformazioniDiBase from "../infoBase/InformazioniDiBase";
import AnamnesiPatologicaRemota from "../anamnesiPatologicaRemota/AnamnesiPatologicaRemota";
import AnamnesiPatologicaProssima from "../anamnesiPatologicaProssima/AnamnesiPatologicaProssima";
import ParametriVitali from "../parametriVitali/ParametriVitali";
import BenessereAbitativo from "../benessereAbitativo/BenessereAbitativo";
import InformazioniBaseFaseValutazione from "../informazioniBaseFaseValutazione/InformazioniBaseFaseValutazione";
import AnamnesiFamiliare from "../anamnesiFamiliare/AnamnesiFamiliare";
import EsameObiettivo from "../esameObiettivo/EsameObiettivo";
import {
    isFieldIdWriteable,
    isSomeFieldIdWriteable,
    rolesProfessionista
} from "../roleBasedComponents/RoleBasedComponents";
import GestioneDellaSalute from "../accordionInfermiere/gestioneDellaSalute/GestioneDellaSalute";
import Dieta from "../accordionInfermiere/dieta/Dieta";
import Ingestione from "../accordionInfermiere/ingestione/Ingestione";
import IdratazioneInfermiere from "../accordionInfermiere/idratazione/IdratazioneInfermiere";
import Assorbimento from "../accordionInfermiere/assorbimento/Assorbimento";
import FunzioneGastrointestinale from "../accordionInfermiere/funzioneGastrointestinale/FunzioneGastrointestinale";
import FunzioneUrinaria from "../accordionInfermiere/funzioneUrinaria/FunzioneUrinaria";
import FunzioneTegumentaria from "../accordionInfermiere/funzioneTegumentaria/FunzioneTegumentaria";
import FunzioneRespiratoria from "../accordionInfermiere/funzioneRespiratoria/FunzioneRespiratoria";
import SonnoRiposo from "../accordionInfermiere/SonnoERiposo/SonnoRiposo";
import CapacitaCuraDiSe from "../accordionInfermiere/capacitaCuraDiSe/CapacitaCuraDiSe";
import ComunicazioneSensorio from "../accordionInfermiere/comunicazioneSensorio/ComunicazioneSensorio";
import CopingTolleranzaStressInfermiere
    from "../accordionInfermiere/copingTolleranzaStress/CopingTolleranzaStressInfermiere";
import routepath from "../../utils/route/route-path.json";
import {Redirect} from "react-router-dom";
import {v4 as uuid} from "uuid";
import {getValutazionePrecedente} from "../home/HomeUtils";
import AuthUtils from "../../utils/AuthUtils";
import enumUtente from "../../enum/enumsUtente.json";
import UserHelper from "../../service/userHelper";
import ScrollTop from "../scrollTop/ScrollTop";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import {Button, SessioneUtente} from "web-client-archetype";
import WebSocketHelper from "../../websocket/WebSocketHelper";
import ValutazioneProfessionista from "../compilaProposte/valutazioneProfessionista/ValutazioneProfessionista";
import BisogniPrestazioniAssistenziali
    from "../compilaProposte/bisogniPrestazioniAssistenziali/BisogniPrestazioniAssistenziali";
import ObiettiviClinicoAssistenziali
    from "../compilaProposte/obiettiviClinicoAssistenziali/ObiettiviClinicoAssistenziali";
import FigureProfessionaliPresaInCarico
    from "../compilaProposte/figureProfessionaliPresaInCarico/FigureProfessionaliPresaInCarico";
import LivelloPrioritaRichiesta from "../compilaProposte/livelloPriorita/LivelloPrioritaRichiesta";
import ProposteAttivitaSuggerimenti from "../compilaProposte/proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimenti";
import FigureProfessionaliSupportoAValutazione
    from "../compilaProposte/FigureProfessionaliSupportoAValutazione/FigureProfessionaliSupportoAValutazione";
import statiRichiesta from "../../utils/dataset/richiestaData/statiRichiesta.json";
import DataDimissioneProgrammataDaRicovero
    from "../compilaProposte/dataDimissioneProgrammataDaRicovero/DataDimissioneProgrammataDaRicovero";
import DataDiPresaInCaricoTerritorialeDaDimissioneProtetta
    from "../compilaProposte/dataDiPresaInCaricoDaDimissioneProtetta/DataDiPresaInCaricoTerritorialeDaDimissioneProtetta";
import BisogniAllaDimissione from "../compilaProposte/bisogniAllaDimissione/BisogniAllaDimissione";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import ValutazioneInFormalizzazione
    from "../compilaFormalizzazione/caratteristicheSettingRichiesto/ValutazioneInFormalizzazione";
import styles from "../compilaProposte/CompilaProposte.module.css";
import ModalHelper from "../modale/ModalHelper";

const propTypes = {
    richiesta: PropTypes.object,
    richiestaMedico: PropTypes.object,
    persone: PropTypes.object,
    pageState: PropTypes.string,
    pageName: PropTypes.string,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    openAccordion: PropTypes.bool,
    previousPageOnlyRequiredFields: PropTypes.bool,
    hideDefaultHeader: PropTypes.bool,
    listaCodiciFiscaliUtentiOnline: PropTypes.array
}

export default class ContainerPaginaCompila extends Component {

    state = {
        redirectToIndietro: false,
        redirectToVisualizzaRiepilogo: false,
        redirectToAnagrafeRichiedente: false,
        salvaBozzaSubscription: null,
        accordion: {
            openAccordionAnagrafePaziente: false,
            openAccordionSituazioneSociale: false,
            openAccordionBenessereAbitativo: false,
            openAccordionDeterminantiSalute: false,
            openAccordionDieta: false,
            openAccordionAnamnesiFisiologica: false,
            openAccordionGestioneDellaSalute: false,
            openAccordionSonnoRiposo: false,
            openAccordionCapacitaCuraDiSe: false,
            openAccordionIdratazioneInfermiere: false,
            openAccordionAssorbimento: false,
            openAccordionComunicazioneSensorio: false,
            openAccordionInformazioniBaseFaseValutazione: false,
            openAccordionInformazioniBaseFaseRichiesta: false,
            openAccordionInfoBase: false,
            openAccordionAnamnesiPatologicaRemota: false,
            openAccordionAnamnesiPatologicaProssima: false,
            openAccordionAnamnesiFamiliare: false,
            openAccordionEsameObiettivo: false,
            openAccordionIngestione: false,
            openAccordionFunzioneUrinaria: false,
            openAccordionFunzioneTegumentaria: false,
            openAccordionFunzioneRespiratoria: false,
            openAccordionFunzioneGastrointestinale: false,
            openAccordionCopingTolleranzaStressInfermiere: false,
            openAccordionParametriVitali: false,
            openAccordionValutazioneBisogniPalliativi: false,
            openAccordionBisogniPrestazioniAssistenziali: false,
            openAccordionObiettiviClinicoAssistenziali: false,
            openAccordionFigureProfessionaliPresaInCarico: false,
            openAccordionValutazioneProfessionista: false,
            openAccordionProposteAttivitaSuggerimenti: false,
            openAccordionFigureProfessionaliSupportoAValutazione: false,
            openAccordionLivelloPriorita: false,
            openAccordionDataDimissioneProgrammataDaRicovero: false,
            openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta: false,
            openAccordionBisogniAllaDimissione: false,
            openAccordionValutazione: false
        },
        apriModaleAlert: false
    }

    accordionData;
    datiA0;
    team;

    // oggetto utilizzato per controllare se abilitare o disabilitare il pulsante Prosegui
    obbligatorieta = {
        anagrafePaziente: null,
        situazioneSociale: null,
        anamnesiFisiologica: null,
        informazioniDiBase: null,
        informazioniBaseFaseRichiesta: null,
        informazioniBaseFaseValutazione: null,
        anamnesiPatologicaProssima: null,
        funzioneUrinaria: null,
        determinantiDiSalute: null,
        comunicazioneSensorio: null,
        assorbimento: null,
        benessereAbitativo: null,
        anamnesiPatologicaRemota: null,
        esameObiettivo: null,
        funzioneGastrointestinale: null,
        dieta: null,
        sonnoRiposo: null,
        funzioneRespiratoria: null,
        ingestione: null,
        funzioneTegumentaria: null,
        bisogniPrestazioniAssistenziali: null,
        figureProfessionaliPresaInCarico: null,
        proposteAttivitaSuggerimenti: null,
        figureProfessionaliSupportoAllaValutazione: null,
        dataDiPresaInCaricoTerritorialeDaDimissioneProtetta: null,
        livelloPriorita: null,
        dataDimissioneProgrammataDaRicovero: null,
        bisogniAllaDimissione: null
    };

    // oggetto utilizzato per controllare che al click del bottone Prosegui si può accedere alla pagina successiva
    validitaPagina = {
        anagrafePaziente: false,
        situazioneSociale: false
    };

    constructor(props) {
        super(props);

        this.accordionData = SessionRequest.getSessionObjectFor(props.richiesta);

        if (Utils.isStateRichiestaPreDialogo(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) {
            delete this.obbligatorieta.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta;
        }

        if (!Utils.isDimissioneProtetta(this.props.pageState)) {
            delete this.obbligatorieta.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta;
            delete this.obbligatorieta.dataDimissioneProgrammataDaRicovero;
            delete this.obbligatorieta.bisogniAllaDimissione;
        }

        this.updateFields();

        this.datiA0 = _.cloneDeep({
            ...this.accordionData.anagrafePaziente,
            aulssAssistenza: this.props.richiesta.aulss,
            distrettoAssistenza: this.props.richiesta.distretto,
            provinciaAssistenza: this.props.richiesta.provincia
        });
        this.team = this.props.richiesta?.team ?? [];
    }

    componentDidMount() {
        this.countMandatoryFieldsMissing();

        this.setState({
            salvaBozzaSubscription: InserimentoRichiesta.salvaBozzaSubject.subscribe(() => this.effettuaSalvataggio(false))
        }, () => {
            InserimentoRichiesta.infoRichiestaSubject.next({
                idRichiesta: this.props.richiesta.id,
                anagrafePaziente: this.accordionData?.anagrafePaziente ?? null,
                situazioneSociale: this.accordionData?.situazioneSociale ?? null,
                richiedenteOrMcp: this.props.persone?.[Utils.recuperoIdProfessionista(this.props.richiesta)]
            });
        });

        const idLastSurveyCompiled = AccordionHelper.lastSurveyCompiled;
        if (this.props.openAccordion) {
            let accordion = _.cloneDeep(this.state.accordion);
            for (const singoloAccordion in accordion) {
                accordion[singoloAccordion] = true;
            }
            this.setState({accordion: accordion});
        } else if (AccordionHelper.idAccordionToAddInformation != null && AccordionHelper.idSelectedAccordions == null && idLastSurveyCompiled == null) {
            const ref = $(document.getElementById(AccordionHelper.idAccordionToAddInformation.replace("datiRichiesta", "")));
            ref.find('> div.collapse-header > button').trigger('click');
            AccordionHelper.setCloneIdAccordionToAddInformation(null);
        } else if (idLastSurveyCompiled != null) {
            var props = this.props;
            $(document).ready(function () {
                const collapseBody = $($(document.getElementById(idLastSurveyCompiled))[0]).parents('div.collapse-body');
                const accordion = $(collapseBody.get(collapseBody.length === 1 ? 0 : 1)).parents('div.collapse-div');
                AccordionHelper.onScrollIntoAccordion(accordion[0].id + '|' + idLastSurveyCompiled, props.readOnly);
                AccordionHelper.setLastSurveyCompiled(null);
            });
        } else if (AccordionHelper.idSelectedAccordions != null || AccordionHelper.idAccordionToAddInformation != null) {
            this.setState({accordion: AccordionHelper.initLastOpenedAccordionInAccordionObject(this.state.accordion, AccordionHelper.lastOpenedAccordion, this.props.openAccordion)});
        }
    }

    countMandatoryFieldsMissing = () => {
        if (this.props.pageName === enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE) {
            const richiedente = this.props.persone?.[Utils.recuperoIdProfessionista(this.props.richiesta)];
            let missingFieldsCount = 0;
            if (richiedente?.identificativoUtente === SessioneUtente.getInstance().idUtente) {
                missingFieldsCount = getCountMandatoryFieldsMissing(richiedente, this.props.pageState);
            }
            this.setState({redirectToAnagrafeRichiedente: missingFieldsCount > 0});
        }
    }

    componentWillUnmount() {
        if (this.state.salvaBozzaSubscription) this.state.salvaBozzaSubscription.unsubscribe();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        let $this = this;
        $(document).ready(function () { 
            $('div.box-accordion-body:empty').closest('div.box-accordion').remove();

            if ($('div#statisticaContainer').is("html *")) {
                const arrayEmpty = $("div#statisticaContainer > div.col-6 > div.empty");
                arrayEmpty.each(function () {
                    $(this).parent().remove()
                });
    
                $("div#statisticaContainer > div.col-6:empty").remove();
                $("div#statisticaContainer > div.col-12:empty").remove();
                $("div#statisticaContainer:empty").remove();
                $("div.align-items-top > div.mb-3:empty").remove();
                $("div.align-items-top:empty").remove();
            }
    
            $this.removeEmptySubAccordionsWithSurvey("idSottoaccordionQuestionariAnamnesiFisiologica");
            $this.removeEmptySubAccordionsWithSurvey("idSottoaccordionQuestionariCapacitaCuraDiSe");
    
            $this.removeEmptySubAccordionsWithSurvey("idSottoaccordionQuestionariAnamnesiFisiologica");
            $this.removeEmptySubAccordionsWithSurvey("idSottoaccordionQuestionariCapacitaCuraDiSe");
    
            //Duplicato perchè prima vengono eliminati i sottoAccordion vuoti e poi i relativi Accordion vuoti
            $('div.collapse-body:empty').closest('div.collapse-div').remove();
            $('div.collapse-body:empty').closest('div.collapse-div').remove();
    
    
            $('div.align-items-top > div.col-3:empty').remove();
            $('div.align-items-top:empty').remove();
            $('div.collapse-body > div:empty').closest('div.collapse-div').remove();
            $('div.collapse-body > div.row > div.col:empty').closest('div.collapse-div').remove();
    
        })

        if (!_.isEqual(prevProps.richiesta, this.props.richiesta)) {
            this.updateFields();
        }
    }

    removeEmptySubAccordionsWithSurvey = (idSelector) => {
        const selectorAll = "[id^='" + idSelector + "']";
        let subAccordionsWithSurvey = $(selectorAll + " > div.col-12.col-xl-6 > ");
        let childrenToRemove = [];

        if (subAccordionsWithSurvey.is("html *")) {
            for (const element of subAccordionsWithSurvey) {
                if ($(element).is("html *") && $(element)?.children()?.length <= 1) childrenToRemove.push(element);
            }
            $(childrenToRemove).remove();
            $(selectorAll + " > div.col-12.col-xl-9:empty").remove();
            $(selectorAll + " > div:empty").remove();
        }
    }

    updateFields() {
        let richiesta = this.props.richiesta;

        if (richiesta) {
            this.accordionData.anagrafePaziente = this.initObjectsForm("anagrafePaziente", richiesta, defaultAnagrafePaziente);
            this.accordionData.situazioneSociale = this.initObjectsForm("situazioneSociale", richiesta, this.accordionData.situazioneSociale);
            this.accordionData.determinantiDiSalute = this.initObjectsForm("determinantiDiSalute", richiesta, this.accordionData.determinantiDiSalute);
            this.accordionData.anamnesiFisiologica = this.initObjectsForm("anamnesiFisiologica", richiesta, this.accordionData.anamnesiFisiologica);
            this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione] = this.initObjectsForm(enumRichiesta.attributi.informazioniBaseFaseValutazione, richiesta, this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]);
            this.accordionData.informazioniDiBase = this.initObjectsForm("informazioniDiBase", richiesta, this.accordionData.informazioniDiBase);
            this.accordionData.datiAnamnesiFamiliare = this.initObjectsForm("anamnesiFamiliare", richiesta, this.accordionData.datiAnamnesiFamiliare);
            this.accordionData.anamnesiPatologicaProssima = this.initObjectsForm("anamnesiPatologicaProssima", richiesta, this.accordionData.anamnesiPatologicaProssima);
            this.accordionData.anamnesiPatologicaRemota = this.initObjectsForm("anamnesiPatologicaRemota", richiesta, this.accordionData.anamnesiPatologicaRemota);
            this.accordionData.benessereAbitativo = this.initObjectsForm("benessereAbitativo", richiesta, this.accordionData.benessereAbitativo);
            this.accordionData.esameObiettivo = this.initObjectsForm("esameObiettivo", richiesta, this.accordionData.esameObiettivo);
            this.accordionData.parametriVitali = this.initObjectsForm("parametriVitali", richiesta, this.accordionData.parametriVitali);
            this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta] = this.initObjectsForm(enumRichiesta.attributi.informazioniBaseFaseRichiesta, richiesta, this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]);
            this.accordionData.valutazioneBisogniPalliativi = this.initObjectsForm("valutazioneBisogniPalliativi", richiesta, this.accordionData.valutazioneBisogniPalliativi);
            this.accordionData.bisogniPrestazioniAssistenziali = this.initObjectsForm("bisogniPrestazioniAssistenziali", richiesta, this.accordionData.bisogniPrestazioniAssistenziali) ?? [];
            this.accordionData.obiettiviClinicoAssistenziali = this.initObjectsForm("obiettiviClinicoAssistenziali", richiesta, this.accordionData.obiettiviClinicoAssistenziali);
            this.accordionData.figureProfessionaliPresaInCarico = this.initObjectsForm("figureProfessionaliPresaInCarico", richiesta, this.accordionData.figureProfessionaliPresaInCarico) ?? [];
            this.accordionData.valutazioneProfessionista = this.initObjectsForm("valutazioneProfessionista", richiesta, this.accordionData.valutazioneProfessionista);
            this.accordionData.proposteAttivitaSuggerimenti = this.initObjectsForm("proposteAttivitaSuggerimenti", richiesta, this.accordionData.proposteAttivitaSuggerimenti) ?? [];
            this.accordionData.professionistiSupportoAValutazione = this.initObjectsForm("professionistiSupportoAValutazione", richiesta, this.accordionData.professionistiSupportoAValutazione) ?? [];
            this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale = this.initObjectsForm("proposteAttivitaSuggerimentiAssistenteSociale", richiesta, this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale);
            this.accordionData.proposteAttivitaSuggerimentiNote = this.initObjectsForm("proposteAttivitaSuggerimentiNote", richiesta, this.accordionData.proposteAttivitaSuggerimentiNote);
            this.accordionData.dataDimissioneProgrammataDaRicovero = this.initObjectsForm("dataDimissioneProgrammataDaRicovero", richiesta, this.accordionData.dataDimissioneProgrammataDaRicovero);
            this.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta = this.initObjectsForm("dataDiPresaInCaricoTerritorialeDaDimissioneProtetta", richiesta, this.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta);
            this.accordionData.bisogniAllaDimissione = this.initObjectsForm("bisogniAllaDimissione", richiesta, this.accordionData.bisogniAllaDimissione);
            this.accordionData.livelloPriorita = richiesta?.livelloPriorita ?? null;
            this.accordionData.livelloPrioritaBozza = richiesta?.livelloPrioritaBozza ?? null;
        }
    }

    initObjectsForm = (property, richiesta, defaultObject) => {
        let output;

        if (Object.prototype.hasOwnProperty.call(richiesta, property)) {
            if (Array.isArray(richiesta[property])) {
                output = [];
                output.push(...richiesta[property]);
            } else if (Object.prototype.toString.call(defaultObject) === '[object Object]') {
                output = _.cloneDeep(defaultObject);
                Object.keys(defaultObject).forEach(key => {
                    if (richiesta[property] != null && key in richiesta[property]) {
                        output[key] = richiesta[property][key];
                    } else {
                        output[key] = defaultObject[key];
                    }
                });
            } else output = defaultObject;
        } else output = defaultObject;

        return output;
    }

    handleChangeDatiAnagrafePaziente = (field, value) => {
        let fieldSplit = field.split(",");
        if (fieldSplit.length === 1) {
            this.accordionData.anagrafePaziente[field] = value;
        } else if (fieldSplit.length > 1) {
            fieldSplit.forEach(fieldName => {
                this.accordionData.anagrafePaziente[fieldName] = value[fieldName];
            });
        }
    }

    handleChangeDatiSituazioneSocialeForm = (field, value) => {
        this.accordionData.situazioneSociale[field] = value;
    }

    handleChangeDatiParametriVitaliForm = (field, value) => {
        this.accordionData.parametriVitali[field] = value;
    }

    handleChangeDeterminantiDiSaluteForm = (field, value) => {
        this.accordionData.determinantiDiSalute[field] = value;
    }

    handleChangeAnamnesiFisiologicaForm = (field, value) => {
        this.accordionData.anamnesiFisiologica[field] = value;
    }

    handleChangeInformazioniBaseFaseValutazioneForm = (field, value) => {
        this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione][field] = value;
    }

    handleChangeInformazioniBaseFaseRichiestaForm = (field, value) => {
        this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta][field] = value;
    }

    handleChangeInformazioniDiBaseForm = (field, value) => {
        this.accordionData.informazioniDiBase[field] = value;
    }

    handleChangeAnamnesiPatologicaRemota = (value) => {
        this.accordionData.anamnesiPatologicaRemota = value;
    }

    handleChangeBenessereAbitativo = (field, value) => {
        this.accordionData.benessereAbitativo[field] = value;
    }

    handleChangeAnamnesiPatologicaProssima = (value) => {
        this.accordionData.anamnesiPatologicaProssima = value;
    }

    handleChangeAnamnesiFamiliare = (field, value) => {
        this.accordionData.datiAnamnesiFamiliare[field] = value;
    }

    handleChangeEsameObiettivo = (field, value) => {
        this.accordionData.esameObiettivo[field] = value;
    }

    handleChangeValutazioneBisogniPalliativi = (field, value) => {
        this.accordionData.valutazioneBisogniPalliativi[field] = value;
    }

    handleChangeDatiAccordionPagina2 = (field, value) => {
        const split = field.split('|');
        if (split.length === 2) {
            this.accordionData[split[0]] = value[0];
            if (split[1] && split[1].startsWith('path')) {
                const propostaOtherAccordionModified = this.accordionData.proposteAttivitaSuggerimenti
                    .filter(e => e.firstPageAccordionPath === value[1])?.map(p => p?.propostaAttivitaSuggerimenti) ?? [];
                _.set(this.accordionData, value[1], propostaOtherAccordionModified);
            }
        } else {
            this.accordionData[field] = value;
        }
        this.forceUpdate();
    }

    handleChangeObbligatorieta = (field, value) => {
        this.obbligatorieta[field] = value;

        let obbligatorietaClone = _.cloneDeep(this.obbligatorieta);
        if (Utils.isStateRichiesta(this.props.pageState)) {
            Object.keys(obbligatorietaClone?.informazioniDiBase ?? {}).forEach(key => {
                if (key !== "sottosezioneTerapiaFarmacologicaInAtto") delete obbligatorietaClone.informazioniDiBase[key];
            });
        }
        let isButtonProseguiEnabled = obbligatorietaClone && Object.keys(obbligatorietaClone).every(key => !obbligatorietaClone[key]);
        AccordionHelper.abilitazioneProseguiSubject.next(true);
        disableButton("#proseguiBtn", !isButtonProseguiEnabled);
    }

    handleValiditaPagina = (field, value) => {
        this.validitaPagina[field] = value;
    }

    salvaBozza = (richiestaBody) => {
        return RichiestaADIService.salvaBozza(richiestaBody.id, richiestaBody, ButtonHelper.clickSalvaButton)
            .pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                }
                this.team = richiestaBody?.team ?? [];

                return richiestaBody;
            }));
    }

    salvaValutazioni = (richiestaBody, isProcediBtn = false) => {
        if (isProcediBtn) mostraSpinner();
        return RichiestaADIService.salvaVariazioniInBozza(richiestaBody.id, richiestaBody, ButtonHelper.clickSalvaButton)
            .pipe(map((response) => {
                if (isProcediBtn) nascondiSpinner();
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                }
                this.team = richiestaBody?.team ?? [];

                return richiestaBody;
            }));
    }

    creaPersona = (personaBody) => {
        if (!Utils.isObjectNull(personaBody)) {
            return AnagrafeService.insertPersona(personaBody).pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                    return of(null)
                } else {
                    return response;
                }
            }));
        } else
            return of(null);
    }

    modificaPersona = (idPersona, personaBody) => {
        if (!Utils.isStringEmptyOrNullOrUndefined(idPersona) && !Utils.isObjectNull(personaBody)) {
            return AnagrafeService.modificaPersona(idPersona, personaBody).pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                    return of(null)
                } else {
                    return response;
                }
            }));
        } else
            return of(null);
    }

    cancellaPersona = (uuidPersona) => {
        if (!Utils.isStringEmptyOrNullOrUndefined(uuidPersona)) {
            AnagrafeService.cancellaPersona(uuidPersona).pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                    return of(null)
                } else {
                    return response;
                }
            })).subscribe((response) => {
                if (response) this.prepareToSalvaBozza();
            });
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            });
            if (!this.props.readOnly) this.effettuaSalvataggio();

            AccordionHelper.setLastOpenedAccordion(field);
            this.setState({accordion});
        }
    }

    effettuaSalvataggio = (isProcediBtn = false) => {
        if (this.props.readOnly) {
            if (isProcediBtn) this.setState({redirectToVisualizzaRiepilogo: true});
            return;
        }

        let lastOpenedAccordion = AccordionHelper.lastOpenedAccordion;
        if (!Utils.isStringEmptyOrNullOrUndefined(lastOpenedAccordion) && lastOpenedAccordion === "openAccordionAnagrafePaziente") {
            let anagrafePaziente = _.cloneDeep(this.accordionData.anagrafePaziente);
            let obsList = [];

            let assistito = this.convertObjectToPersonaDto(this.props.richiesta.idAssistito, anagrafePaziente.datiGenerali);
            assistito.indirizzi = [];
            this.setRecapito(assistito.indirizzi, anagrafePaziente.residenza, enumIndirizzo.indirizzo.residenza);
            this.setRecapito(assistito.indirizzi, anagrafePaziente.domicilio, enumIndirizzo.indirizzo.domicilio);
            this.setRecapito(assistito.indirizzi, anagrafePaziente.domicilioDiCura, enumIndirizzo.indirizzo.altro);
            assistito.contatti = [];
            this.setContatto(assistito.contatti, anagrafePaziente.datiGenerali, enumContatto.contatto.telefono, "telefono");
            this.setContatto(assistito.contatti, anagrafePaziente.datiGenerali, enumContatto.contatto.cellulare, "cellulare");
            this.setContatto(assistito.contatti, anagrafePaziente.datiGenerali, enumContatto.contatto.email, "email");
            assistito.esenzioni = anagrafePaziente.esenzioni?.filter(esenzione => Object.keys(esenzione).some(k => k !== "idElemento" && !Utils.isObjectNull(esenzione[k])));
            let mascheraModificabilitaAssistito = anagrafePaziente.datiGenerali?.mascheraModificabilita;
            assistito.mascheraModificabilita = mascheraModificabilitaAssistito !== undefined ? mascheraModificabilitaAssistito : null;
            obsList.push(this.modificaPersona(assistito.id, assistito).pipe(map(paziente => paziente)));

            if (Object.prototype.hasOwnProperty.call(anagrafePaziente, "mmgPlsDelPaziente")
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.mmgPlsDelPaziente.cognome)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.mmgPlsDelPaziente.nome)) {
                let mmgPlsDelPaziente = this.convertObjectToPersonaDto(anagrafePaziente.mmgPlsDelPaziente.id, anagrafePaziente.mmgPlsDelPaziente);
                mmgPlsDelPaziente.contatti = [];
                this.setContatto(mmgPlsDelPaziente.contatti, anagrafePaziente.mmgPlsDelPaziente, enumContatto.contatto.telefono, "telefono");
                this.setContatto(mmgPlsDelPaziente.contatti, anagrafePaziente.mmgPlsDelPaziente, enumContatto.contatto.cellulare, "cellulare");
                this.setContatto(mmgPlsDelPaziente.contatti, anagrafePaziente.mmgPlsDelPaziente, enumContatto.contatto.email, "email");
                if (mmgPlsDelPaziente?.id) {
                    obsList.push(this.modificaPersona(mmgPlsDelPaziente.id, mmgPlsDelPaziente).pipe(map(mmgPlsPaziente => mmgPlsPaziente)));
                } else {
                    const observableMmgPlsPaziente = this.creaPersona(mmgPlsDelPaziente).pipe(map((response) => {
                        if (response)
                            anagrafePaziente.mmgPlsDelPaziente.id = response.id;
                        return response;
                    }));
                    obsList.push(observableMmgPlsPaziente);
                }
            }

            if (Object.prototype.hasOwnProperty.call(anagrafePaziente, "padre")
                && isPazienteMinoreDi18Anni(anagrafePaziente.datiGenerali.dataNascita)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.padre.cognome)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.padre.nome)) {

                let padre = this.convertObjectToPersonaDto(anagrafePaziente.padre.id, anagrafePaziente.padre);
                padre.indirizzi = [];
                if (!Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.padre.indirizzo))
                    this.setRecapito(padre.indirizzi, anagrafePaziente.padre, enumIndirizzo.indirizzo.domicilio);
                padre.contatti = [];
                this.setContatto(padre.contatti, anagrafePaziente.padre, enumContatto.contatto.telefono, "telefono");
                this.setContatto(padre.contatti, anagrafePaziente.padre, enumContatto.contatto.email, "email");
                if (padre?.id) {
                    obsList.push(this.modificaPersona(padre.id, padre).pipe(map(padre => padre)));
                } else {
                    const observablePadre = this.creaPersona(padre).pipe(map((response) => {
                        if (response)
                            anagrafePaziente.padre.id = response.id;
                        return response;
                    }));
                    obsList.push(observablePadre);
                }
            }

            if (Object.prototype.hasOwnProperty.call(anagrafePaziente, "madre")
                && isPazienteMinoreDi18Anni(anagrafePaziente.datiGenerali.dataNascita)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.madre.cognome)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.madre.nome)) {

                let madre = this.convertObjectToPersonaDto(anagrafePaziente.madre.id, anagrafePaziente.madre);
                madre.indirizzi = [];
                if (!Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.madre.indirizzo))
                    this.setRecapito(madre.indirizzi, anagrafePaziente.madre, enumIndirizzo.indirizzo.domicilio);
                madre.contatti = [];
                this.setContatto(madre.contatti, anagrafePaziente.madre, enumContatto.contatto.telefono, "telefono");
                this.setContatto(madre.contatti, anagrafePaziente.madre, enumContatto.contatto.email, "email");
                if (madre?.id) {
                    obsList.push(this.modificaPersona(madre.id, madre).pipe(map(madre => madre)));
                } else {
                    const observableMadre = this.creaPersona(madre).pipe(map((response) => {
                        if (response)
                            anagrafePaziente.madre.id = response.id;
                        return response;
                    }));
                    obsList.push(observableMadre);
                }
            }

            if (Object.prototype.hasOwnProperty.call(anagrafePaziente, "tutore")) {
                anagrafePaziente.tutore.forEach(tutore => {
                    if (!Utils.isStringEmptyOrNullOrUndefined(tutore.cognome)
                        && !Utils.isStringEmptyOrNullOrUndefined(tutore.nome)) {

                        let indexTutore = anagrafePaziente.tutore.indexOf(tutore);
                        let personaTutore = this.convertObjectToPersonaDto(tutore.id, tutore);
                        personaTutore.indirizzi = [];
                        if (!Utils.isStringEmptyOrNullOrUndefined(tutore.indirizzo))
                            this.setRecapito(personaTutore.indirizzi, tutore, enumIndirizzo.indirizzo.domicilio);
                        personaTutore.contatti = [];
                        this.setContatto(personaTutore.contatti, tutore, enumContatto.contatto.telefono, "telefono");
                        this.setContatto(personaTutore.contatti, tutore, enumContatto.contatto.email, "email");
                        if (personaTutore?.id) {
                            obsList.push(this.modificaPersona(personaTutore.id, personaTutore).pipe(map(tutore => tutore)));
                        } else {
                            const observableTutori = this.creaPersona(personaTutore).pipe(map((response) => {
                                if (response)
                                    anagrafePaziente.tutore[indexTutore].id = response.id;
                                return response;
                            }));
                            obsList.push(observableTutori);
                        }
                    }
                });
            }

            if (Object.prototype.hasOwnProperty.call(anagrafePaziente, "amministratoreDiSostegno")) {
                anagrafePaziente.amministratoreDiSostegno.forEach(amministratoreDiSostegno => {
                    if (!Utils.isStringEmptyOrNullOrUndefined(amministratoreDiSostegno.cognome)
                        && !Utils.isStringEmptyOrNullOrUndefined(amministratoreDiSostegno.nome)) {

                        let indexAmministratoreDiSostegno = anagrafePaziente.amministratoreDiSostegno.indexOf(amministratoreDiSostegno);
                        let personaAmministratoreDiSostegno = this.convertObjectToPersonaDto(amministratoreDiSostegno.id, amministratoreDiSostegno);
                        personaAmministratoreDiSostegno.indirizzi = [];
                        if (!Utils.isStringEmptyOrNullOrUndefined(amministratoreDiSostegno.indirizzo))
                            this.setRecapito(personaAmministratoreDiSostegno.indirizzi, amministratoreDiSostegno, enumIndirizzo.indirizzo.domicilio);
                        personaAmministratoreDiSostegno.contatti = [];
                        this.setContatto(personaAmministratoreDiSostegno.contatti, amministratoreDiSostegno, enumContatto.contatto.telefono, "telefono");
                        this.setContatto(personaAmministratoreDiSostegno.contatti, amministratoreDiSostegno, enumContatto.contatto.email, "email");
                        if (personaAmministratoreDiSostegno?.id) {
                            const observableAmministratoriDiSostegno = this.modificaPersona(personaAmministratoreDiSostegno.id, personaAmministratoreDiSostegno)
                                .pipe(map(amministratoreDiSostegno => amministratoreDiSostegno));
                            obsList.push(observableAmministratoriDiSostegno);
                        } else {
                            const observableAmministratoriDiSostegno = this.creaPersona(personaAmministratoreDiSostegno).pipe(map((response) => {
                                if (response)
                                    anagrafePaziente.amministratoreDiSostegno[indexAmministratoreDiSostegno].id = response.id;
                                return response;
                            }));
                            obsList.push(observableAmministratoriDiSostegno);
                        }
                    }
                });
            }

            if (Object.prototype.hasOwnProperty.call(anagrafePaziente, "fiduciario")) {
                // Eliminato controllo sulla presenza di cognome & nome - sul db è stato tolto il vincolo del not null su entrambe
                let fiduciario = this.convertObjectToPersonaDto(anagrafePaziente.fiduciario.id, anagrafePaziente.fiduciario);
                fiduciario.indirizzi = [];
                if (!Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.fiduciario.indirizzo))
                    this.setRecapito(fiduciario.indirizzi, anagrafePaziente.fiduciario, enumIndirizzo.indirizzo.domicilio);
                fiduciario.contatti = [];
                this.setContatto(fiduciario.contatti, anagrafePaziente.fiduciario, enumContatto.contatto.telefono, "telefono");
                this.setContatto(fiduciario.contatti, anagrafePaziente.fiduciario, enumContatto.contatto.email, "email");
                if (fiduciario?.id) {
                    obsList.push(this.modificaPersona(fiduciario.id, fiduciario).pipe(map(fiduciario => fiduciario)));
                } else {
                    const observableFiduciario = this.creaPersona(fiduciario).pipe(map((response) => {
                        if (response)
                            anagrafePaziente.fiduciario.id = response.id;
                        return response;
                    }));
                    obsList.push(observableFiduciario);
                }
            }

            forkJoin(obsList)
                .subscribe((response) => {
                    this.accordionData.anagrafePaziente = anagrafePaziente;
                    response.forEach(r => {
                        if (r != null) this.props.persone[r.id] = r;
                    });
                    this.prepareToSalvaBozza(isProcediBtn);
                });
        } else {
            this.prepareToSalvaBozza(isProcediBtn);
        }
    }

    convertObjectToPersonaDto = (idPersona, object) => {
        let persona = {
            ..._.cloneDeep(this.props.persone?.[idPersona]),
            nome: object.nome,
            cognome: object.cognome,
            identificativoUtente: object.identificativoUtente,
            tipoIdentificativoUtente: object.tipoIdentificativoUtente,
            dataNascita: object.dataNascita ? object.dataNascita : null,
            sesso: object.sesso ? object.sesso : null,
            comune: object.comune ? object.comune : null,
            provincia: object.provincia ? object.provincia : null,
            nazione: object.nazione ? object.nazione : null,
            cittadinanza: object.cittadinanza ? object.cittadinanza : null,
            tesseraSanitaria: object.tesseraSanitaria ? object.tesseraSanitaria : null,
            titoloStudio: object.titoloStudio ? object.titoloStudio : null,
            codiceRegionale: object.codiceRegionale ? object.codiceRegionale : null,
            esenzioni: []
        };
        persona.lingue = [];
        this.setLingue(persona.lingue, object);

        return persona
    }

    setLingue = (lingue, object) => {
        if (Object.prototype.hasOwnProperty.call(object, "madrelingua")
            && Object.prototype.hasOwnProperty.call(object, "livello")
            && Object.prototype.hasOwnProperty.call(object, "descrizione")
            && object.madrelingua !== null) {
            let lingua = {
                idElemento: object?.idLingue?.[0] ?? null,
                descrizione: null,
                madrelingua: object.madrelingua,
                livello: object.livello
            };

            if (object.madrelingua || !Utils.isStringEmptyOrNullOrUndefined(object.livello) || object.descrizione.length > 0) {
                if (object.descrizione.length > 0 && object.descrizione.some(d => d !== null)) {
                    let descrizioniDistinct = Array.from(new Set(object.descrizione)).filter(el => el !== null);
                    descrizioniDistinct.forEach((linguaParlata, index) => {
                        lingue.push({
                            ..._.cloneDeep(lingua),
                            idElemento: object?.idLingue?.[index] ?? null,
                            descrizione: linguaParlata
                        });
                    });
                } else lingue.push(_.cloneDeep(lingua));
            } else lingue.push(_.cloneDeep(lingua));
        }
    }

    setRecapito = (indirizzi, recapito, tipoRecapito) => {
        if (!Utils.isObjectNull(recapito)) {
            let indirizzo = {};
            indirizzo.tipo = tipoRecapito;
            indirizzo.indirizzo = recapito?.indirizzo ?? null;
            indirizzo.numero = recapito?.numero ?? null;
            indirizzo.cap = recapito?.cap ?? null;
            indirizzo.comune = recapito?.comune ?? null;
            indirizzo.provincia = recapito?.provincia ?? null;
            indirizzo.nazione = recapito?.nazione ?? null;
            indirizzo.aulss = recapito?.aulss ?? null;
            indirizzo.distretto = recapito?.distretto ?? null;
            indirizzo.idElemento = Object.prototype.hasOwnProperty.call(recapito, "idElementoIndirizzo")
                ? recapito.idElementoIndirizzo
                : recapito.idElemento
                    ? recapito.idElemento
                    : null;
            indirizzi.push(indirizzo);
        }
    }

    setContatto = (contatti, object, tipoContatto, field) => {
        if (!Utils.isStringEmptyOrNullOrUndefined(object[field])) {
            contatti.push({
                tipo: tipoContatto,
                valore: object[field],
                idElemento: object["idElemento".concat(field.charAt(0).toUpperCase()).concat(field.slice(1))]
            });
        }
    }

    prepareToSalvaBozza = (isProcediBtn = false,
                           returnObservable = false,
                           richiesta = _.cloneDeep(this.props.richiesta)) => {
        if (this.props.readOnly) return;

        let richiestaClone = {
            ..._.cloneDeep(richiesta),
            team: this.team,
        };
        buildFullRichiesta(richiestaClone, this, this.props.pageName);
        let salva = Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)
            ? this.salvaValutazioni(richiestaClone, isProcediBtn)
            : this.salvaBozza(richiestaClone);
        if (isProcediBtn) {
            salva.subscribe(() => this.setState({redirectToVisualizzaRiepilogo: true}));
        } else if (returnObservable) {
            return salva;
        } else {
            salva.subscribe();
        }
    }

    renderForm() {
        switch (this.props.pageState) {
            case 'R':
            case 'DP-PDA':
                return this.renderAccordionAvvioRichiesta();
            case 'V':
                if (SessioneUtente.getInstance().settings.some(setting => setting.ruolo === 'P_INFERMIERE')) {
                    return this.renderAccordionValutazioneInfermiere();
                } else {
                    return this.renderAccordionValutazioneMedico();
                }
            case 'F':
            case 'DP-PDC':
            case 'DP-F':
                return this.renderAccordionFormalizzazione();
            case 'DP-PDV':
            case 'DP-V':
                return this.renderAccordionValutazioneMedico();
            default:
                return null;
        }
    }

    renderButtons() {
        return (
            <ButtonsBoxStick
                parentClass="d-flex justify-content-between"
                btnList={[
                    {
                        id: "indietroBtn",
                        text: "Indietro",
                        className: "btnWhiteOutlineBorderGreen",
                        onclickFunction: () => this.setState({redirectToIndietro: true}),
                        isvisible: true,
                    },
                    {
                        id: "salvaBozzaBtn",
                        text: "Salva bozza",
                        className: "btnWhiteOutlineBorderGreen",
                        isvisible: !this.props.readOnly,
                        onclickFunction: () => {
                            ButtonHelper.setSalvaButtonFlag(true);
                            this.effettuaSalvataggio();
                            InserimentoRichiesta.infoRichiestaSubject.next({
                                idRichiesta: this.props.richiesta.id,
                                anagrafePaziente: this.accordionData?.anagrafePaziente ?? null,
                                situazioneSociale: this.accordionData?.situazioneSociale ?? null,
                                richiedenteOrMcp: this.props.persone?.[Utils.recuperoIdProfessionista(this.props.richiesta)]
                            });
                        }
                    },
                    {
                        id: "proseguiBtn",
                        text: "Prosegui",
                        className: "btnGreenOutline",
                        onclickFunction: () => {
                            let accordionValidatorKeys = _.cloneDeep(Object.keys(this.validitaPagina));
                            const numberOfValidAccordions = accordionValidatorKeys.filter(k => {
                                const validator = this.validitaPagina[k];
                                let validation;
                                let subAccordionValidatorKeys = Object.keys(validator);
                                if (subAccordionValidatorKeys.length) {
                                    validation = subAccordionValidatorKeys
                                        .filter(k => k !== "isTutoreValido" && k !== "isAmministratoreDiSostegnoValido")
                                        .every(k2 => validator[k2] === true);
                                }
                                else validation = validator === true;
                                return validation;
                            }).length;

                            if (numberOfValidAccordions === accordionValidatorKeys.length) {
                                if (WebSocketHelper.valutazioneConfermataEvent !== null) this.apriModaleAlert();
                                else this.effettuaSalvataggio(true);
                            } else {
                                Utils.toasterFunction(Utils.getAlertMessageInvalidPage(accordionValidatorKeys, this.validitaPagina));
                            }
                        },
                        isvisible: true,
                    }
                ]}
            />
        );
    }

    renderAccordionAvvioRichiesta = () => {
        return (
            <>
                <VersionedAccordion accordion={AnagrafePaziente}
                                    datiAssistitoA0={this.datiA0}
                                    datiAnagrafePaziente={this.accordionData.anagrafePaziente}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnagrafePaziente={this.handleChangeDatiAnagrafePaziente}
                                    openAccordion={this.state.accordion.openAccordionAnagrafePaziente}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnagrafePaziente"}
                                    pageState={this.props.pageState}
                                    cancellaPersona={this.cancellaPersona}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    onValidaPagina={this.handleValiditaPagina}
                                    readOnly={this.props.readOnly}
                />

                <VersionedAccordion accordion={ValutazioneBisogniPalliativi}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    openAccordion={this.state.accordion.openAccordionValutazioneBisogniPalliativi}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionValutazioneBisogniPalliativi"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    onChangeValutazioneBisogniPalliativi={this.handleChangeValutazioneBisogniPalliativi}
                                    onValidaPagina={this.handleValiditaPagina}
                                    readOnly={this.props.readOnly}
                />

                <VersionedAccordion accordion={SituazioneSociale}
                                    datiAnagrafePaziente={this.accordionData.anagrafePaziente}
                                    datiSituazioneSociale={this.accordionData.situazioneSociale}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiSituazioneSociale={this.handleChangeDatiSituazioneSocialeForm}
                                    openAccordion={this.state.accordion.openAccordionSituazioneSociale}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionSituazioneSociale"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    onValidaPagina={this.handleValiditaPagina}
                />

                <VersionedAccordion accordion={DeterminantiDiSalute}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDeterminatiDiSalute={this.handleChangeDeterminantiDiSaluteForm}
                                    openAccordion={this.state.accordion.openAccordionDeterminantiSalute}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionDeterminantiSalute"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={AnamnesiFisiologica}
                                    datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiFisiologica}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiFisiologica"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    datiConsumoTabacco={this.accordionData.determinantiDiSalute?.datiConsumoTabacco}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={InformazioniBaseFaseRichiesta}
                                    datiInformazioniBaseFaseRichiesta={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiInformazioniBaseFaseRichiesta={this.handleChangeInformazioniBaseFaseRichiestaForm}
                                    openAccordion={this.state.accordion.openAccordionInformazioniBaseFaseRichiesta}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionInformazioniBaseFaseRichiesta"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={InformazioniDiBase}
                                    datiInformazioniDiBase={this.accordionData.informazioniDiBase}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiInformazioniDiBase={this.handleChangeInformazioniDiBaseForm}
                                    openAccordion={this.state.accordion.openAccordionInfoBase}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionInfoBase"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={AnamnesiPatologicaRemota}
                                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnamnesiPatologicaRemota={this.handleChangeAnamnesiPatologicaRemota}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaRemota}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiPatologicaRemota"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={AnamnesiPatologicaProssima}
                                    datiAnamnesiPatologicaProssima={this.accordionData.anamnesiPatologicaProssima}
                                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnamnesiPatologicaProssima={this.handleChangeAnamnesiPatologicaProssima}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaProssima}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiPatologicaProssima"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={ParametriVitali}
                                    datiParametriVitali={this.accordionData.parametriVitali}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiParametriVitali={this.handleChangeDatiParametriVitaliForm}
                                    openAccordion={this.state.accordion.openAccordionParametriVitali}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionParametriVitali"}
                                    pageState={this.props.pageState}
                                    punteggioTestEsasr={this.accordionData.anamnesiFisiologica?.copingTolleranzaStress?.punteggioAnsia}
                />
            </>
        );
    }

    renderAccordionValutazioneMedico() {
        return (
            <>
                <VersionedAccordion accordion={AnagrafePaziente}
                                    datiAssistitoA0={this.datiA0}
                                    datiAnagrafePaziente={this.accordionData.anagrafePaziente}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnagrafePaziente={this.handleChangeDatiAnagrafePaziente}
                                    openAccordion={this.state.accordion.openAccordionAnagrafePaziente}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnagrafePaziente"}
                                    pageState={this.props.pageState}
                                    cancellaPersona={this.cancellaPersona}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    onValidaPagina={this.handleValiditaPagina}
                />

                <VersionedAccordion accordion={ValutazioneBisogniPalliativi}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    openAccordion={this.state.accordion.openAccordionValutazioneBisogniPalliativi}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionValutazioneBisogniPalliativi"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    onChangeValutazioneBisogniPalliativi={this.handleChangeValutazioneBisogniPalliativi}
                                    onValidaPagina={this.handleValiditaPagina}
                                    readOnly={this.props.readOnly}
                />

                <VersionedAccordion accordion={SituazioneSociale}
                                    datiAnagrafePaziente={this.accordionData.anagrafePaziente}
                                    datiSituazioneSociale={this.accordionData.situazioneSociale}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiSituazioneSociale={this.handleChangeDatiSituazioneSocialeForm}
                                    openAccordion={this.state.accordion.openAccordionSituazioneSociale}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionSituazioneSociale"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    onValidaPagina={this.handleValiditaPagina}
                />

                <VersionedAccordion accordion={BenessereAbitativo}
                                    benessereAbitativo={this.accordionData.benessereAbitativo}
                                    onChangeBenessereAbitativo={this.handleChangeBenessereAbitativo}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    openAccordion={this.state.accordion.openAccordionBenessereAbitativo}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionBenessereAbitativo"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    disableDoubleInput={AuthUtils.hasUtenteRuoloFisiatra()}
                />

                <VersionedAccordion accordion={DeterminantiDiSalute}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    datiPaziente={this.accordionData.anagrafePaziente}
                                    datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDeterminatiDiSalute={this.handleChangeDeterminantiDiSaluteForm}
                                    openAccordion={this.state.accordion.openAccordionDeterminantiSalute}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionDeterminantiSalute"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={AnamnesiFisiologica}
                                    datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                    datiAderenzaAllaTerapia={this.accordionData.informazioniDiBase?.datiAderenzaAllaTerapia}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                    onChangeDatiAderenzaAllaTerapia={this.handleChangeInformazioniDiBaseForm}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiFisiologica}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiFisiologica"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    datiConsumoTabacco={this.accordionData.determinantiDiSalute?.datiConsumoTabacco}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={InformazioniBaseFaseValutazione}
                                    datiInformazioniBaseFaseValutazione={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiInformazioniBaseFaseValutazione={this.handleChangeInformazioniBaseFaseValutazioneForm}
                                    openAccordion={this.state.accordion.openAccordionInformazioniBaseFaseValutazione}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionInformazioniBaseFaseValutazione"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={InformazioniDiBase}
                                    datiInformazioniDiBase={this.accordionData.informazioniDiBase}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiInformazioniDiBase={this.handleChangeInformazioniDiBaseForm}
                                    openAccordion={this.state.accordion.openAccordionInfoBase}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionInfoBase"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={AnamnesiFamiliare}
                                    datiAnamnesiFamiliare={this.accordionData.datiAnamnesiFamiliare}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnamnesiFamiliare={this.handleChangeAnamnesiFamiliare}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiFamiliare}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiFamiliare"}
                                    pageState={this.props.pageState}
                />

                <VersionedAccordion accordion={AnamnesiPatologicaRemota}
                                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnamnesiPatologicaRemota={this.handleChangeAnamnesiPatologicaRemota}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaRemota}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiPatologicaRemota"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={AnamnesiPatologicaProssima}
                                    datiAnamnesiPatologicaProssima={this.accordionData.anamnesiPatologicaProssima}
                                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnamnesiPatologicaProssima={this.handleChangeAnamnesiPatologicaProssima}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaProssima}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiPatologicaProssima"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion accordion={EsameObiettivo}
                                    esameObiettivo={this.accordionData.esameObiettivo}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeEsameObiettivo={this.handleChangeEsameObiettivo}
                                    openAccordion={this.state.accordion.openAccordionEsameObiettivo}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionEsameObiettivo"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    showAccordionSezioneRichiesta={!isFieldIdWriteable('M_PRES_ALT_COLOR.CUTEEANNESSI.ESAMEOBIETTIVO', this.props.pageState, this.context.forceUserRole)}
                />

                <VersionedAccordion accordion={ParametriVitali}
                                    datiParametriVitali={this.accordionData.parametriVitali}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiParametriVitali={this.handleChangeDatiParametriVitaliForm}
                                    openAccordion={this.state.accordion.openAccordionParametriVitali}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionParametriVitali"}
                                    pageState={this.props.pageState}
                                    punteggioTestEsasr={this.accordionData.anamnesiFisiologica?.copingTolleranzaStress?.punteggioAnsia}
                />
            </>
        );
    }

    renderAccordionValutazioneInfermiere() {
        return <>
            <VersionedAccordion accordion={AnagrafePaziente}
                                datiAssistitoA0={this.datiA0}
                                datiAnagrafePaziente={this.accordionData.anagrafePaziente}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnagrafePaziente={this.handleChangeDatiAnagrafePaziente}
                                openAccordion={this.state.accordion.openAccordionAnagrafePaziente}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionAnagrafePaziente"}
                                pageState={this.props.pageState}
                                cancellaPersona={this.cancellaPersona}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                onValidaPagina={this.handleValiditaPagina}
            />

            <VersionedAccordion accordion={ValutazioneBisogniPalliativi}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                openAccordion={this.state.accordion.openAccordionValutazioneBisogniPalliativi}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionValutazioneBisogniPalliativi"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                onChangeValutazioneBisogniPalliativi={this.handleChangeValutazioneBisogniPalliativi}
                                onValidaPagina={this.handleValiditaPagina}
                                readOnly={this.props.readOnly}
            />

            <VersionedAccordion accordion={InformazioniBaseFaseValutazione}
                                accordionSubtitle={""}
                                datiInformazioniBaseFaseValutazione={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiInformazioniBaseFaseValutazione={this.handleChangeInformazioniBaseFaseValutazioneForm}
                                openAccordion={this.state.accordion.openAccordionInformazioniBaseFaseValutazione}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionInformazioniBaseFaseValutazione"}
                                pageState={this.props.pageState}
                                sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={InformazioniDiBase}
                                datiInformazioniDiBase={this.accordionData.informazioniDiBase}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiInformazioniDiBase={this.handleChangeInformazioniDiBaseForm}
                                openAccordion={this.state.accordion.openAccordionInfoBase}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionInfoBase"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={AnamnesiFamiliare}
                                datiAnamnesiFamiliare={this.accordionData.datiAnamnesiFamiliare}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFamiliare={this.handleChangeAnamnesiFamiliare}
                                openAccordion={this.state.accordion.openAccordionAnamnesiFamiliare}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionAnamnesiFamiliare"}
                                pageState={this.props.pageState}
            />

            <VersionedAccordion accordion={AnamnesiFisiologica}
                                datiAnamnesiFisiologica={this.props.richiestaMedico?.anamnesiFisiologica ?? SessionRequest.getInitObject('anamnesiFisiologica')}
                                datiAderenzaAllaTerapia={this.accordionData.informazioniDiBase?.datiAderenzaAllaTerapia ?? SessionRequest.getInitObject('informazioniDiBase.datiAderenzaAllaTerapia')}
                                onChangeDatiAderenzaAllaTerapia={() => undefined}
                                richiesta={this.props.richiestaMedico}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFisiologica={() => undefined}
                                openAccordion={this.state.accordion.openAccordionAnamnesiFisiologica}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionAnamnesiFisiologica"}
                                pageState={this.props.pageState}
                                sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                datiConsumoTabacco={this.accordionData.determinantiDiSalute?.datiConsumoTabacco}
                                onChangeObbligatorieta={() => undefined}
                                forceReadOnly={true}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={true}
            />

            <VersionedAccordion accordion={AnamnesiPatologicaRemota}
                                datiAnamnesiPatologicaRemota={this.props.richiestaMedico?.anamnesiPatologicaRemota ?? SessionRequest.getInitObject('anamnesiPatologicaRemota')}
                                richiesta={this.props.richiestaMedico}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiPatologicaRemota={() => undefined}
                                openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaRemota}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionAnamnesiPatologicaRemota"}
                                pageState={this.props.pageState}
                                showAccordionSezioneRichiesta={true}
                                forceReadOnly={true}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={true}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={AnamnesiPatologicaProssima}
                                datiAnamnesiPatologicaProssima={this.props.richiestaMedico?.anamnesiPatologicaProssima ?? SessionRequest.getInitObject('anamnesiPatologicaProssima')}
                                datiAnamnesiPatologicaRemota={this.props.richiestaMedico?.anamnesiPatologicaRemota ?? SessionRequest.getInitObject('anamnesiPatologicaRemota')}
                                richiesta={this.props.richiestaMedico}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiPatologicaProssima={() => undefined}
                                openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaProssima}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionAnamnesiPatologicaProssima"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                forceReadOnly={true}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={true}
            />

            <VersionedAccordion accordion={DeterminantiDiSalute}
                                accordionTitle={"Consapevolezza della salute"}
                                datiPaziente={this.accordionData.anagrafePaziente}
                                datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDeterminatiDiSalute={this.handleChangeDeterminantiDiSaluteForm}
                                openAccordion={this.state.accordion.openAccordionDeterminantiSalute}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionDeterminantiSalute"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={GestioneDellaSalute}
                                datiInformazioniDiBase={this.accordionData.informazioniDiBase}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiInformazioniDiBase={this.handleChangeInformazioniDiBaseForm}
                                openAccordion={this.state.accordion.openAccordionGestioneDellaSalute}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionGestioneDellaSalute"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={Dieta}
                                datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                                onChangeDeterminatiDiSalute={this.handleChangeDeterminantiDiSaluteForm}
                                datiInformazioniDiBase={this.accordionData.informazioniDiBase}
                                onChangeDatiInformazioniDiBase={this.handleChangeInformazioniDiBaseForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                openAccordion={this.state.accordion.openAccordionDieta}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionDieta"}
                                pageState={this.props.pageState}
                                hideUnsetValues={this.props.hideUnsetValues}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={Ingestione}
                                esameObiettivo={this.accordionData.esameObiettivo}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeEsameObiettivo={this.handleChangeEsameObiettivo}
                                openAccordion={this.state.accordion.openAccordionIngestione}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionIngestione"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={IdratazioneInfermiere}
                                datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                datiIdratazione={this.accordionData.anamnesiFisiologica?.statoFunzionale}
                                onChangeDatiIdratazione={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionIdratazioneInfermiere}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionIdratazioneInfermiere"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={Assorbimento}
                                datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                datiAssorbimento={this.accordionData.anamnesiFisiologica?.statoFunzionale}
                                onChangeDatiAssorbimento={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionAssorbimento}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionAssorbimento"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={FunzioneGastrointestinale}
                                datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                datiEsameObiettivo={this.accordionData.esameObiettivo}
                                onChangeEsameObiettivo={this.handleChangeEsameObiettivo}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                openAccordion={this.state.accordion.openAccordionFunzioneGastrointestinale}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionFunzioneGastrointestinale"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={FunzioneUrinaria}
                                esameObiettivo={this.accordionData.esameObiettivo}
                                onChangeEsameObiettivo={this.handleChangeEsameObiettivo}
                                datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                openAccordion={this.state.accordion.openAccordionFunzioneUrinaria}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionFunzioneUrinaria"}
                                pageState={this.props.pageState}
                                sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={FunzioneTegumentaria}
                                esameObiettivo={this.accordionData.esameObiettivo}
                                datiInformazioniDiBase={this.accordionData.informazioniDiBase}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeEsameObiettivo={this.handleChangeEsameObiettivo}
                                onChangeDatiInformazioniDiBase={this.handleChangeInformazioniDiBaseForm}
                                openAccordion={this.state.accordion.openAccordionFunzioneTegumentaria}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionFunzioneTegumentaria"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={FunzioneRespiratoria}
                                esameObiettivo={this.accordionData.esameObiettivo}
                                anamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeEsameObiettivo={this.handleChangeEsameObiettivo}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionFunzioneRespiratoria}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionFunzioneRespiratoria"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={SonnoRiposo}
                                datiSonnoRiposo={this.accordionData.anamnesiFisiologica?.datiRitmoSonnoVeglia}
                                onChangeDatiSonnoRiposo={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionSonnoRiposo}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionSonnoRiposo"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={CapacitaCuraDiSe}
                                datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                datiCapacitaCuraDiSe={this.accordionData.anamnesiFisiologica?.statoFunzionale}
                                onChangeDatiCapacitaCuraDiSe={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionCapacitaCuraDiSe}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionCapacitaCuraDiSe"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={ComunicazioneSensorio}
                                datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                datiComunicazioneSensorio={this.accordionData.anamnesiFisiologica?.statoFunzionale}
                                onChangeComunicazioneSensorio={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionComunicazioneSensorio}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionComunicazioneSensorio"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={CopingTolleranzaStressInfermiere}
                                datiCopingTolleranzaStress={this.accordionData.anamnesiFisiologica?.copingTolleranzaStress}
                                onChangeCopingTolleranzaStress={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionCopingTolleranzaStressInfermiere}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionCopingTolleranzaStressInfermiere"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={SituazioneSociale}
                                datiAnagrafePaziente={this.accordionData.anagrafePaziente}
                                datiSituazioneSociale={this.accordionData.situazioneSociale}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiSituazioneSociale={this.handleChangeDatiSituazioneSocialeForm}
                                openAccordion={this.state.accordion.openAccordionSituazioneSociale}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionSituazioneSociale"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                onValidaPagina={this.handleValiditaPagina}
                                disableDoubleInput={true}
            />

            <VersionedAccordion accordion={BenessereAbitativo}
                                benessereAbitativo={this.accordionData.benessereAbitativo}
                                onChangeBenessereAbitativo={this.handleChangeBenessereAbitativo}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                openAccordion={this.state.accordion.openAccordionBenessereAbitativo}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionBenessereAbitativo"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                disableDoubleInput={true}
            />

            <VersionedAccordion accordion={EsameObiettivo}
                                esameObiettivo={this.props.richiestaMedico?.esameObiettivo ?? SessionRequest.getInitObject('esameObiettivo')}
                                richiesta={this.props.richiestaMedico}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeEsameObiettivo={() => undefined}
                                openAccordion={this.state.accordion.openAccordionEsameObiettivo}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionEsameObiettivo"}
                                pageState={this.props.pageState}
                                sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                showAccordionSezioneRichiesta={true}
                                forceReadOnly={true}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={true}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
            />

            <VersionedAccordion accordion={ParametriVitali}
                                datiParametriVitali={this.accordionData.parametriVitali}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiParametriVitali={this.handleChangeDatiParametriVitaliForm}
                                openAccordion={this.state.accordion.openAccordionParametriVitali}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionParametriVitali"}
                                pageState={this.props.pageState}
                                punteggioTestEsasr={this.accordionData.anamnesiFisiologica?.copingTolleranzaStress?.punteggioAnsia}
                                disableDoubleInput={true}
            />
        </>;
    }

    renderAccordionFormalizzazione() {
        return null;
    }

    renderFormPaginaProposteFaseClassica = () => {
        let showValutazioneProfessionista = false;
        if (this.props.richiesta?.valutazioni) {
            this.props.richiesta.valutazioni.forEach(valutazione => {
                if (rolesProfessionista.has(valutazione.valutatore?.ruolo))
                    showValutazioneProfessionista = true
            })
        }

        return (
            <>
                {this.state.apriModaleAlert && this.renderModaleAlert()}

                {showValutazioneProfessionista &&
                    <VersionedAccordion
                        accordion={ValutazioneProfessionista}
                        dati={this.accordionData.valutazioneProfessionista}
                        richiesta={this.props.richiesta}
                        salvaBozza={this.prepareToSalvaBozza}
                        onChangeDatiValutazioneProfessionista={this.handleChangeDatiAccordionPagina2}
                        openAccordion={this.state.accordion.openAccordionValutazioneProfessionista}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionValutazioneProfessionista"}
                        pageState={this.props.pageState}
                        onChangeObbligatorieta={this.handleChangeObbligatorieta}/>}

                <VersionedAccordion
                    accordion={BisogniPrestazioniAssistenziali}
                    dati={this.accordionData.bisogniPrestazioniAssistenziali ?? []}
                    onChangeBisogniPrestazioniAssistenziali={this.handleChangeDatiAccordionPagina2}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    openAccordion={this.state.accordion.openAccordionBisogniPrestazioniAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniPrestazioniAssistenziali"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={ObiettiviClinicoAssistenziali}
                    dati={this.accordionData.obiettiviClinicoAssistenziali}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiObiettiviClinicoAssistenziali={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionObiettiviClinicoAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionObiettiviClinicoAssistenziali"}
                    pageState={this.props.pageState}
                    disableDoubleInput={Utils.isRuoloInfermiere()}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliPresaInCarico}
                    dati={this.accordionData.figureProfessionaliPresaInCarico}
                    datiNecessitaAssistenzaSanitaria={this.accordionData.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiFigureProfessionaliPresaInCarico={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliPresaInCarico}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliPresaInCarico"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    firstPageName={this.props.pageName}
                    previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields}/>

                <VersionedAccordion
                    accordion={LivelloPrioritaRichiesta}
                    professionistiSupportoAValutazione={this.accordionData.professionistiSupportoAValutazione}
                    dati={this.accordionData.livelloPriorita}
                    figureProfessionali={this.accordionData.figureProfessionaliPresaInCarico}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    openAccordion={this.state.accordion.openAccordionLivelloPriorita}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionLivelloPriorita"}
                    onChangeLivelloPriorita={this.handleChangeDatiAccordionPagina2}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion
                    accordion={ProposteAttivitaSuggerimenti}
                    datiProposteAttivitaSuggerimenti={this.accordionData.proposteAttivitaSuggerimenti}
                    datiproposteAttivitaSuggerimentiAssistenteSociale={this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale}
                    datiProposteAttivitaSuggerimentiNote={this.accordionData.proposteAttivitaSuggerimentiNote}
                    datiSituazioneSociale={this.accordionData.situazioneSociale}
                    datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                    datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                    datiInformazioniBase={this.accordionData.informazioniDiBase}
                    datiAnamnesiFamiliare={this.accordionData.datiAnamnesiFamiliare}
                    datiInformazioniBaseFaseRichiesta={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]}
                    datiInformazioniBaseFaseValutazione={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                    datiBenessereAbitativo={this.accordionData.benessereAbitativo}
                    datiEsameObiettivo={this.accordionData.esameObiettivo}
                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiProposteAttivitaSuggerimenti={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionProposteAttivitaSuggerimenti}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionProposteAttivitaSuggerimenti"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliSupportoAValutazione}
                    dati={this.accordionData.professionistiSupportoAValutazione}
                    datiNecessitaAssistenzaSanitaria={this.accordionData.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiFigureProfessionaliSupportoAValutazione={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliSupportoAValutazione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliSupportoAValutazione"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    firstPageName={this.props.pageName}
                    previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields}/>
            </>
        );
    }

    renderFormPaginaProposteFaseDimissioneProtetta = () => {
        return (
            <>
                {this.state.apriModaleAlert && this.renderModaleAlert()}

                <VersionedAccordion
                    accordion={LivelloPrioritaRichiesta}
                    professionistiSupportoAValutazione={this.accordionData.professionistiSupportoAValutazione}
                    dati={this.props.richiesta.stato === statiRichiesta.confermataPreDialogo.value || this.props.richiesta.stato === statiRichiesta.inChiusuraPreDialogo.value
                        ? this.accordionData.livelloPrioritaBozza
                        : this.accordionData.livelloPriorita}
                    figureProfessionali={this.accordionData.figureProfessionaliPresaInCarico}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    openAccordion={this.state.accordion.openAccordionLivelloPriorita}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionLivelloPriorita"}
                    onChangeLivelloPriorita={this.handleChangeDatiAccordionPagina2}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion
                    accordion={DataDimissioneProgrammataDaRicovero}
                    dati={this.accordionData.dataDimissioneProgrammataDaRicovero}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiDataDimissioneProgrammataDaRicovero={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionDataDimissioneProgrammataDaRicovero}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionDataDimissioneProgrammataDaRicovero"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                {!Utils.isStateRichiestaPreDialogo(this.props.pageState)
                    && !Utils.isStateValutazionePreDialogo(this.props.pageState) &&
                    <VersionedAccordion
                        accordion={DataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                        dati={this.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                        richiesta={this.props.richiesta}
                        salvaBozza={this.prepareToSalvaBozza}
                        onChangeDatiDataDiPresaInCaricoTerritorialeDaDimissioneProtetta={this.handleChangeDatiAccordionPagina2}
                        openAccordion={this.state.accordion.openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta"}
                        pageState={this.props.pageState}
                        onChangeObbligatorieta={this.handleChangeObbligatorieta}/>}

                <VersionedAccordion
                    accordion={BisogniPrestazioniAssistenziali}
                    dati={this.accordionData.bisogniPrestazioniAssistenziali ?? []}
                    onChangeBisogniPrestazioniAssistenziali={this.handleChangeDatiAccordionPagina2}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    openAccordion={this.state.accordion.openAccordionBisogniPrestazioniAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniPrestazioniAssistenziali"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={BisogniAllaDimissione}
                    dati={this.accordionData.bisogniAllaDimissione}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiBisogniAllaDimissione={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionBisogniAllaDimissione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniAllaDimissione"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={ObiettiviClinicoAssistenziali}
                    dati={this.accordionData.obiettiviClinicoAssistenziali}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiObiettiviClinicoAssistenziali={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionObiettiviClinicoAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionObiettiviClinicoAssistenziali"}
                    pageState={this.props.pageState}
                    disableDoubleInput={Utils.isRuoloInfermiere()}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliPresaInCarico}
                    dati={this.accordionData.figureProfessionaliPresaInCarico}
                    datiNecessitaAssistenzaSanitaria={this.accordionData.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiFigureProfessionaliPresaInCarico={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliPresaInCarico}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliPresaInCarico"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    firstPageName={this.props.pageName}
                    previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields}/>

                <VersionedAccordion
                    accordion={ProposteAttivitaSuggerimenti}
                    datiProposteAttivitaSuggerimenti={this.accordionData.proposteAttivitaSuggerimenti}
                    datiproposteAttivitaSuggerimentiAssistenteSociale={this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale}
                    datiProposteAttivitaSuggerimentiNote={this.accordionData.proposteAttivitaSuggerimentiNote}
                    datiSituazioneSociale={this.accordionData.situazioneSociale}
                    datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                    datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                    datiInformazioniBase={this.accordionData.informazioniDiBase}
                    datiInformazioniBaseFaseRichiesta={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]}
                    datiInformazioniBaseFaseValutazione={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                    datiBenessereAbitativo={this.accordionData.benessereAbitativo}
                    datiEsameObiettivo={this.accordionData.esameObiettivo}
                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                    datiAnamnesiFamiliare={this.accordionData.datiAnamnesiFamiliare}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiProposteAttivitaSuggerimenti={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionProposteAttivitaSuggerimenti}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionProposteAttivitaSuggerimenti"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliSupportoAValutazione}
                    dati={this.accordionData.professionistiSupportoAValutazione}
                    datiNecessitaAssistenzaSanitaria={this.accordionData.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiFigureProfessionaliSupportoAValutazione={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliSupportoAValutazione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliSupportoAValutazione"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    firstPageName={this.props.pageName}
                    previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields}/>

                {!Utils.isStateRichiestaPreDialogo(this.props.pageState)
                    && !Utils.isStateValutazionePreDialogo(this.props.pageState)
                    && <AccordionAvvioRichiesta
                        idAccordion={"valutazioneChiusuraPreDialogo"}
                        title={"Valutazione"}
                        openAccordion={this.state.accordion.openAccordionValutazione}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionValutazione"}
                        pageState={this.props.pageState}>
                        <VersionedAccordion
                            accordion={ValutazioneInFormalizzazione}
                            tipoValutazione={this.props.richiesta?.formalizzazione?.tipoValutazione}
                            dataValutazione={!Utils.isStringEmptyOrNullOrUndefined(this.props.richiesta?.formalizzazione?.dataValutazione)
                                ? this.props.richiesta.formalizzazione.dataValutazione.match(/\d{4}-\d{2}-\d{2}/g)
                                    ? Utils.formatDate(this.props.richiesta?.formalizzazione.dataValutazione, "YYYY-MM-DD", "DD/MM/YYYY")
                                    : this.props.richiesta?.formalizzazione.dataValutazione
                                : ''}
                            openAccordion={this.state.accordion.openAccordionValutazione}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionValutazione"}
                            pageState={this.props.pageState}/>
                    </AccordionAvvioRichiesta>}
            </>
        );
    }

    // Modale di alert al click del button "Prosegui"
    apriModaleAlert = () => {
        let showModale = WebSocketHelper.valutazioneConfermataEvent.idRichiesta === this.props.richiesta.id;
        this.setState({apriModaleAlert: showModale});
    }

    chiudiModaleAlert = () => {
        this.setState({apriModaleAlert: false});
    }

    renderRowsSezioni = (listaSezioniModificate) => {
        let righeJSX = [];
        let arraySezioniModificate = listaSezioniModificate != null
            ? Array.from(listaSezioniModificate.split(";"))
            : ["nessuna sezione modificata"];

        let rigaJSX = <Fragment>
            {arraySezioniModificate?.map((nomeSezione, index) => {
                return (<tr key={index} className={index % 2 === 0 ? styles.rowTableGray : styles.rowTableWhite}>
                    <td>
                        <div className={'row ml-1 mb-1 mt-1 text-justify'}>
                            <div className={'col- span mb-1 mt-1 '}>
                                {nomeSezione}
                            </div>
                        </div>
                    </td>
                </tr>);
            })}

        </Fragment>
        righeJSX.push(rigaJSX);

        return (<Fragment>{righeJSX}</Fragment>);
    }

    renderModaleAlert = () => {
        let nominativoUtente = WebSocketHelper.valutazioneConfermataEvent?.nominativoUtente
        let isInfermiere = Utils.isRuoloInfermiere();

        const body = (
            <div className={"container vertical-scrollable"}>
                <div className={"row"}>
                    <div className={"offset-1 col-10 font-weight-100 text-center py-2"}>
                         <span>
                             <p>
                                {isInfermiere === "P_INFERMIERE" ? "L'infermiere" : "Il medico"} {nominativoUtente} ha confermato la propria valutazione professionale, indicando nella sua valutazione dati diversi da quelli da te inseriti.
                             </p>
                             <p>
                                 {"Puoi visualizzare il dato inserito, nello \"Storico modifiche\" di ciascun campo selezionando la relativa icona. Le sezioni modificate sono le seguenti:"}
                             </p>
                         </span>
                    </div>
                </div>
                <div className={"table-responsive px-2 mt-4 mb-4"}>
                    <table className={"table table-striped table-sm mx-auto w-50"}>
                        <th className="col-span p-2 pr-md-3 bg-secondary text-white"> Sezioni modificate</th>
                        <tbody>
                        {this.renderRowsSezioni(WebSocketHelper.valutazioneConfermataEvent?.listaSezioniModificate)}
                        </tbody>
                    </table>
                </div>
            </div>
        );

        const footer = (
            <>
                <div className={"mx-auto w-auto"}>
                    <Button
                        id={"chiudiBtn"}
                        text={"Ho preso atto"}
                        className={"btn btn-adi btnGreenOutline"}
                        onClick={() => {
                            this.setState({apriModaleAlert: false}, () => this.effettuaSalvataggio(true));
                            closeModaleStyle();
                            //Si rimanda a caso d’uso UC_04_15_0L: Conferma valutazione: Professionista diverso da MMG
                        }}/>
                </div>
            </>
        );

        const title = (
            <>
                <h6 className="modal-title w-100">Attenzione!</h6>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={() => this.chiudiModaleAlert()}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </>
        )

        return (
            <>
                <ModalHelper
                    id={"modaleAlert"}
                    title={title}
                    titleClassName={"modal-header text-center"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered"}
                    contentClassName={("modal-content px-3 py-1 text-center text-danger")}
                    body={body}
                    footer={footer}
                    footerClassName={"modal-footer justify-content-around"}
                />
            </>
        );
    }

    render() {
        if (!this.props.hideDefaultHeader) {
            let headerLabel;
            if (this.props.pageState === 'R') headerLabel = "Compila Scheda paziente";
            else if (Utils.isStateRichiestaPreDialogo(this.props.pageState)) headerLabel = "Attiva Pre-Dialogo";
            else if (Utils.isStateValutazione(this.props.pageState)) headerLabel = "Completa valutazione multidimensionale";
            else headerLabel = "Completa Pre-Dialogo";
            AccordionHelper.accordionPageSubject.next({position: 2, title: headerLabel});
        }


        if (this.state.redirectToIndietro) {
            const redirectUrl = Utils.isStateRichiesta(this.props.pageState)
                ? routepath.inserimento_richiesta + routepath.compila_scheda_paziente
                : routepath.valutazione_multidimensionale + routepath.completa_valutazione_multidimensionale;
            let richiesta = _.cloneDeep(this.props.richiesta);
            buildFullRichiesta(richiesta, this, this.props.pageName, false);
            return (
                <Redirect to={{
                    key: uuid(),
                    pathname: redirectUrl,
                    uuidRichiesta: this.props.richiesta.id,
                    pageState: this.props.pageState,
                    pageName: this.props.pageName,
                    readOnly: this.props.readOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    firstPageName: this.props.pageName,
                    richiesta: richiesta,
                    richiestaMedico: this.props.richiestaMedico,
                    persone: this.props.persone,
                    tipologia: richiesta?.tipologia,
                    stato: richiesta?.stato,
                    valutazionePrecedente: (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) ? getValutazionePrecedente(richiesta) : null,
                    showOnlyRequiredFields: true
                }}/>
            );
        } else if (this.state.redirectToVisualizzaRiepilogo) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            const basePath = Utils.isStateRichiesta(this.props.pageState) ? routepath.inserimento_richiesta : routepath.valutazione_multidimensionale;
            buildFullRichiesta(richiesta, this, this.props.pageName, false);
            richiesta = {
                ...richiesta,
                team: richiesta?.team?.map((t, index) => {
                    return {...t, professionista: this.props.richiesta?.team?.[index]?.professionista}
                })
            }
            return (
                <Redirect to={{
                    pathname: basePath.concat(routepath.visualizza_riepilogo),
                    uuidRichiesta: this.props.richiesta.id,
                    richiesta: richiesta,
                    richiestaMedico: this.props.richiestaMedico,
                    pageState: this.props.pageState,
                    readOnly: this.props.readOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    firstPageName: this.props.pageName,
                    tipologia: this.props.richiesta?.tipologia,
                    stato: this.props.richiesta?.stato,
                    valutazionePrecedente: (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) ? getValutazionePrecedente(this.props.richiesta) : null,
                    showOnlyRequiredFields: false,
                    previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields,
                    showMultiCompilationPages: true
                }}/>
            );
        }

        if (this.state.redirectToAnagrafeRichiedente) {
            return (
                <Redirect to={{
                    pathname: routepath.inserimento_richiesta.concat(routepath.compila_scheda_paziente).concat(routepath.anagrafica_medico_richiedente),
                    idPersona: Utils.recuperoIdProfessionista(this.props.richiesta),
                    uuidRichiesta: this.props.richiesta.id,
                    pageState: this.props.pageState,
                    pageName: this.props.pageName,
                    readOnly: !isSomeFieldIdWriteable('ANAGRAFESOGGETTORICHIEDENTE', this.props.pageState, forceUserRole) || !!this.props.readOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    insRichiestaPathname: routepath.inserimento_richiesta.concat(routepath.compila_scheda_paziente),
                    richiesta: this.props.richiesta,
                    showOnlyRequiredFields: false,
                    previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields,
                    showMultiCompilationPages: true
                }}/>
            );
        }


        const isStateValutazione = Utils.isStateValutazione(this.props.pageState);
        const forceUserRole = isStateValutazione && this.props.richiesta.sostituzioneRichiedente
        && (AuthUtils.hasUtenteCentraleAdi() || AuthUtils.hasUtenteRuoloMcp()) ? enumUtente.ruoli.mmg : null;

        UserHelper.setForceUserRole(forceUserRole);

        const {accordion} = this.state;
        return (
            <>
                {((!this.props.openAccordion && !Object.values(accordion).includes(true)) || (this.props.openAccordion)) &&
                    <ScrollTop scrollInit={true} conAnimazione={false}/>}
                    <div id={"compilaRichiestaId"}>
                        <RoleBasedContext.Provider
                            value={{
                                forceReadOnly: this.props.readOnly,
                                hideUnsetValues: this.props.hideUnsetValues,
                                scrollToHead: this.props.openAccordion,
                                forceUserRole: forceUserRole,
                                requestVersion: this.props.richiesta?.versione,
                                showOnlyRequiredFields: false
                            }}>
                            <div className="mx-5">
                                {this.renderForm()}
                                {Utils.isDimissioneProtetta(this.props.pageState)
                                    ? this.renderFormPaginaProposteFaseDimissioneProtetta()
                                    : this.renderFormPaginaProposteFaseClassica()}
                            </div>
                        </RoleBasedContext.Provider>
                    </div>
                    {!this.props.readOnly && this.renderButtons()}
            </>
        );
    }
}

ContainerPaginaCompila.propTypes = propTypes;

ContainerPaginaCompila.defaultProps = {
    readOnly: false,
    hideUnsetValues: false,
    openAccordion: false,
    hideDefaultHeader: false
}
