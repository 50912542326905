import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {FieldsRow, RoleBasedInput, RoleBasedSelect, SectionList} from "../../roleBasedComponents/RoleBasedComponents";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import Utils from "../../../utils/Utils";
import {v4 as uuid} from "uuid";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti,
    defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    datiSituazioneAbitativa: PropTypes.any,
    onChangeBenessereAbitativo: PropTypes.func,
    openAccordion: PropTypes.bool,
    onClickAccordion: PropTypes.func,
    field: PropTypes.string,
    pageState: PropTypes.string,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

const defaultTipoAnimaleDomestico = {
    id: null,
    codice: null,
    numero: null
}
export default class SituazioneAbitativa extends AccordionSezioneRichiesta {

    state = {
        datiSituazioneAbitativa: _.cloneDeep(this.props.datiSituazioneAbitativa)
    }

    tipoAnimaleDomesticoDataNotExist = [];

    componentDidMount() {
        this.inizializzaForm();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiSituazioneAbitativa, this.props.datiSituazioneAbitativa)) {
            this.inizializzaForm();
        }
            
    }

    inizializzaForm = () => {
        let datiSituazioneAbitativa = _.cloneDeep(this.props.datiSituazioneAbitativa);
        this.setState({datiSituazioneAbitativa: datiSituazioneAbitativa}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiSituazioneAbitativa}) => {
            datiSituazioneAbitativa[field] = elem;
            if (field === "ascensore" && elem) {
                datiSituazioneAbitativa.compatibileAccessoCarrozzina = null;
            } else if (field === "scale" && elem) {
                datiSituazioneAbitativa.scalini = null;
            } else if (field === "rampe" && elem) {
                datiSituazioneAbitativa.numRampe = null;
            } else if (field === "presenzaAnimaliDomestici" && elem) {
                datiSituazioneAbitativa.tipoAnimaleDomestico = [];
            } else if (field === "valAbitudiniSicurDomestica" && elem) {
                datiSituazioneAbitativa.esitoValutazione = null;
                datiSituazioneAbitativa.statoDopoValutazione = null;
            }
            return {datiSituazioneAbitativa};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        const datiSituazioneAbitativa = _.cloneDeep(this.state.datiSituazioneAbitativa);
        this.props.onChangeBenessereAbitativo("situazioneAbitativa", datiSituazioneAbitativa);
        let campiObbligatori = 0;
        campiObbligatori += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiSituazioneAbitativa?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneSituazioneAbitativa", campiObbligatori);
    }

    /* AnimaleDomestico */

    handleAggiornaTipoAnimaleDomestico = (field, elem, index) => {
        let datiSituazioneAbitativa = _.cloneDeep(this.state.datiSituazioneAbitativa);
        let currentTipoAnimaleDomestico = datiSituazioneAbitativa.tipoAnimaleDomestico[index];
        currentTipoAnimaleDomestico[field] = elem ?? null;

        if (currentTipoAnimaleDomestico.codice === null) {
            return this.removeTipologiaAnimaliDomestici(index);
        }

        datiSituazioneAbitativa.tipoAnimaleDomestico[index] = currentTipoAnimaleDomestico;

        this.setState({datiSituazioneAbitativa: datiSituazioneAbitativa}, () => this.validazioneDati());
    }

    renderTipologiaAnimaliDomestici = (tipoAnimaleDomestico, index) => {
        this.tipoAnimaleDomesticoDataNotExist[index] = _.cloneDeep(OggettiUtili.tipoAnimaleDomestico);
        this.tipoAnimaleDomesticoDataNotExist[index] = OggettiUtili.tipoAnimaleDomestico.filter((item) => item.value === "03" || tipoAnimaleDomestico.codice === item.value || !this.state.datiSituazioneAbitativa.tipoAnimaleDomestico?.find((tipoFilter) => tipoFilter.codice === item.value));
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'TIPO_ANIM_DOM.SITUAZIONEABITATIVA.BENESSEREABITATIVO'}
                        pageState={this.props.pageState}
                        id={"selectTipologieAnimaliDomestici " + index}
                        options={this.tipoAnimaleDomesticoDataNotExist[index]}
                        value={tipoAnimaleDomestico.codice}
                        onChange={(elem) => this.handleAggiornaTipoAnimaleDomestico("codice", elem, index)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: tipologia animale domestico non esistente"}
                        fieldLabel={'Tipologia'}
                        field={"codice"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedInput
                        fieldId={"NUM_ANIM_DOM.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        value={tipoAnimaleDomestico.numero}
                        placeholder="Inserire numero"
                        field="numero"
                        onChange={(field, elem) => this.handleAggiornaTipoAnimaleDomestico(field, elem.target.value, index)}
                        id="numAnimaliDomestici"
                        type={"number"}
                        min={0}
                        fieldLabel={'N°'}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
            </>
        );
    }

    addNewTipologiaAnimaliDomestici = () => {
        let datiSituazioneAbitativa = _.cloneDeep(this.state.datiSituazioneAbitativa);
        let curTipoAnimaleDomestico = _.cloneDeep(defaultTipoAnimaleDomestico);
        curTipoAnimaleDomestico.id = uuid();
        datiSituazioneAbitativa.tipoAnimaleDomestico.push(curTipoAnimaleDomestico);
        this.setState({datiSituazioneAbitativa: datiSituazioneAbitativa}, () => this.validazioneDati());
    }

    removeTipologiaAnimaliDomestici = (index) => {
        let datiSituazioneAbitativa = _.cloneDeep(this.state.datiSituazioneAbitativa);
        datiSituazioneAbitativa.tipoAnimaleDomestico.splice(index, 1);
        this.setState({datiSituazioneAbitativa: datiSituazioneAbitativa}, () => this.validazioneDati());
    }

    renderBody = () => {
        let datiSituazioneAbitativa = this.state.datiSituazioneAbitativa;
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"CONT_ABIT.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"contestoAbitativo"}
                        options={OggettiUtili.contestoAbitativo}
                        value={datiSituazioneAbitativa.contestoAbitativo}
                        onChange={(elem) => this.handleAggiornaForm("contestoAbitativo", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: contesto abitativo non esistente"}
                        fieldLabel={'Contesto abitativo'}
                        field={"contestoAbitativo"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedSelect
                        fieldId={"POS_TERR.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"posizioneNelTerritorio"}
                        options={OggettiUtili.posizioneNelTerritorio}
                        value={datiSituazioneAbitativa.posizioneNelTerritorio}
                        onChange={(elem) => this.handleAggiornaForm("posizioneNelTerritorio", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: posizione nel territorio non esistente"}
                        fieldLabel={'Posizione nel territorio'}
                        field={"posizioneNelTerritorio"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedSelect
                        fieldId={"SERV.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"servizi"}
                        options={OggettiUtili.servizi}
                        value={datiSituazioneAbitativa.servizi}
                        onChange={(elem) => this.handleAggiornaForm("servizi", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: servizi non esistente"}
                        fieldLabel={'Servizi'}
                        field={"servizi"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedInput
                        fieldId={"PIANO.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        id="piano"
                        field="piano"
                        placeholder="Inserire piano"
                        pageState={this.props.pageState}
                        value={datiSituazioneAbitativa.piano}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        type={"number"}
                        fieldLabel={'Piano'}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedSelect
                        fieldId={"BARR_ARCHIT.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"barriereArchitettoniche"}
                        options={OggettiUtili.barriereArchitettoniche}
                        value={datiSituazioneAbitativa.barriereArchitettoniche}
                        onChange={(elem) => this.handleAggiornaForm("barriereArchitettoniche", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: barriereArchitettoniche non esistente"}
                        fieldLabel={'Barriere architettoniche'}
                        field={"barriereArchitettoniche"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"ASCENS.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"ascensore"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiSituazioneAbitativa.ascensore}
                        onChange={(elem) => this.handleAggiornaForm("ascensore", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: ascensore non esistente"}
                        fieldLabel={'Ascensore'}
                        handleOnlyValue={true}
                        field={"ascensore"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />

                    {datiSituazioneAbitativa.ascensore &&
                        <RoleBasedSelect
                            fieldId={"COMP_ACCES_CARROZ.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                            pageState={this.props.pageState}
                            id={"compatibileAccessoCarrozzina"}
                            options={OggettiUtili.rispostaBreve}
                            value={datiSituazioneAbitativa.compatibileAccessoCarrozzina}
                            onChange={(elem) => this.handleAggiornaForm("compatibileAccessoCarrozzina", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Compatibile con accesso carrozzina non esistente"}
                            fieldLabel={'Compatibile con accesso carrozzina'}
                            handleOnlyValue={true}
                            field={"compatibileAccessoCarrozzina"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />}
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"MONTASC.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"montascale"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiSituazioneAbitativa.montascale}
                        onChange={(elem) => this.handleAggiornaForm("montascale", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Montascale non esistente"}
                        fieldLabel={'Montascale'}
                        handleOnlyValue={true}
                        field={"montascale"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"SCALE.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"scale"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiSituazioneAbitativa.scale}
                        onChange={(elem) => this.handleAggiornaForm("scale", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Scale non esistente"}
                        fieldLabel={'Scale'}
                        handleOnlyValue={true}
                        field={"scale"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />

                    {datiSituazioneAbitativa?.scale &&
                        <RoleBasedInput
                            fieldId={"NUM_SCAL.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                            pageState={this.props.pageState}
                            value={datiSituazioneAbitativa.scalini}
                            placeholder="Inserire numero scalini"
                            field="scalini"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id="scalini"
                            type={"number"}
                            fieldLabel={'N° scalini'}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />}
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"RAMPE.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"rampe"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiSituazioneAbitativa.rampe}
                        onChange={(elem) => this.handleAggiornaForm("rampe", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Rampe non esistente"}
                        fieldLabel={'Rampe'}
                        handleOnlyValue={true}
                        field={"rampe"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    {datiSituazioneAbitativa?.rampe &&
                        <RoleBasedInput
                            fieldId={"NUM_RAMPE.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                            pageState={this.props.pageState}
                            value={datiSituazioneAbitativa.numRampe}
                            placeholder="Inserire numero rampe"
                            field="numRampe"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id="rampe"
                            type={"number"}
                            fieldLabel={'N° rampe'}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />}
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"CORRIM.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"corrimano"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiSituazioneAbitativa.corrimano}
                        onChange={(elem) => this.handleAggiornaForm("corrimano", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Corrimano non esistente"}
                        fieldLabel={'Corrimano'}
                        handleOnlyValue={true}
                        field={"corrimano"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"CONDIZ_IG_APP_CASA.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"condizIgAppCasa"}
                        options={OggettiUtili.condizIgAppCasa}
                        value={datiSituazioneAbitativa.condizIgAppCasa}
                        onChange={(elem) => this.handleAggiornaForm("condizIgAppCasa", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Condizioni igieniche apparenti della casa non esistente"}
                        fieldLabel={'Condizioni igieniche apparenti della casa'}
                        field={"condizIgAppCasa"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedSelect
                        fieldId={"BAGNO.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"bagno"}
                        options={OggettiUtili.bagno}
                        value={datiSituazioneAbitativa.bagno}
                        onChange={(elem) => this.handleAggiornaForm("bagno", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: bagno non esistente"}
                        fieldLabel={'Bagno'}
                        field={"bagno"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"CONDIZION.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"condizionamento"}
                        options={OggettiUtili.condizionamento}
                        value={datiSituazioneAbitativa.condizionamento}
                        onChange={(elem) => this.handleAggiornaForm("condizionamento", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: condizionamento non esistente"}
                        fieldLabel={'Condizionamento'}
                        field={"condizionamento"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedSelect
                        fieldId={"RISCALD.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"riscaldamento"}
                        options={OggettiUtili.riscaldamento}
                        value={datiSituazioneAbitativa.riscaldamento}
                        onChange={(elem) => this.handleAggiornaForm("riscaldamento", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: riscaldamento non esistente"}
                        fieldLabel={'Riscaldamento'}
                        field={"riscaldamento"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedSelect
                        fieldId={"ILLUM.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"illuminazione"}
                        options={OggettiUtili.illuminazione}
                        value={datiSituazioneAbitativa.illuminazione}
                        onChange={(elem) => this.handleAggiornaForm("illuminazione", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: illuminazione non esistente"}
                        fieldLabel={'Illuminazione'}
                        field={"illuminazione"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"PRES_TAPPETI.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"presenzaTappeti"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiSituazioneAbitativa.presenzaTappeti}
                        onChange={(elem) => this.handleAggiornaForm("presenzaTappeti", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Presenza di tappeti non esistente"}
                        fieldLabel={'Presenza di tappeti'}
                        handleOnlyValue={true}
                        field={"presenzaTappeti"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedSelect
                        fieldId={"PRES_SUP_SCIV.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"presenzaSupScivolose"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiSituazioneAbitativa.presenzaSupScivolose}
                        onChange={(elem) => this.handleAggiornaForm("presenzaSupScivolose", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Presenza di superfici scivolose non esistente"}
                        fieldLabel={'Presenza di superfici scivolose'}
                        handleOnlyValue={true}
                        field={"presenzaSupScivolose"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedSelect
                        fieldId={"PRES_ANIM_DOM.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"presenzaAnimaliDomestici"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiSituazioneAbitativa.presenzaAnimaliDomestici}
                        onChange={(elem) => this.handleAggiornaForm("presenzaAnimaliDomestici", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Presenza di animali domestici non esistente"}
                        fieldLabel={'Presenza di animali domestici'}
                        handleOnlyValue={true}
                        field={"presenzaAnimaliDomestici"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                {datiSituazioneAbitativa?.presenzaAnimaliDomestici &&
                    <FieldsRow colNumber={2}>
                        <SectionList
                            title={'Tipologia'}
                            data={datiSituazioneAbitativa?.tipoAnimaleDomestico || []}
                            renderSection={this.renderTipologiaAnimaliDomestici}
                            addNewSectionCallback={this.addNewTipologiaAnimaliDomestici}
                            removeSectionCallback={this.removeTipologiaAnimaliDomestici}
                            pageState={this.props.pageState}
                            keyFieldId={'TIPO_ANIM_DOM.SITUAZIONEABITATIVA.BENESSEREABITATIVO'}
                            infermiereKeyFieldId={'TIPO_ANIM_DOM.SITUAZIONEABITATIVA.BENESSEREABITATIVO'}
                            field={'tipoAnimaleDomestico'}
                            atLeastOne={true}
                            addButtonVisibilityHandler={datiSituazioneAbitativa?.tipoAnimaleDomestico?.length === 0
                                || datiSituazioneAbitativa?.tipoAnimaleDomestico?.every(el => !Utils.isObjectNull(el?.codice))}
                            parentJsonPath={"benessereAbitativo.situazioneAbitativa.tipoAnimaleDomestico"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>}

                <FieldsRow colNumber={2}>
                    <RoleBasedInput
                        fieldId={"ALTRE_COND_RIT_DA_APPROF.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        value={datiSituazioneAbitativa.altreCondDaApprofondire}
                        placeholder={"Specificare altre condizioni"}
                        field="altreCondDaApprofondire"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"altreCondDaApprofondire"}
                        type={"textarea"}
                        maxlength={"400"}
                        fieldLabel={'Altre condizioni ritenute da approfondire'}
                        showAvailableCharsLabel={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"NECES_VALUT_ABITUD_REL_SICUR_DOM.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                        pageState={this.props.pageState}
                        id={"valAbitudiniSicurDomestica"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiSituazioneAbitativa.valAbitudiniSicurDomestica}
                        onChange={(elem) => this.handleAggiornaForm("valAbitudiniSicurDomestica", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Necessità di valutazione abitudini relative alla sicurezza domestica non esistente"}
                        fieldLabel={'Necessità di valutazione abitudini relative alla sicurezza domestica'}
                        handleOnlyValue={true}
                        field={"valAbitudiniSicurDomestica"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    {datiSituazioneAbitativa?.valAbitudiniSicurDomestica &&
                        <RoleBasedInput
                            fieldId={"ESITO_VAL.SITUAZIONEABITATIVA.BENESSEREABITATIVO"}
                            pageState={this.props.pageState}
                            value={datiSituazioneAbitativa.esitoValutazione}
                            placeholder={"Inserisci esito valutazione"}
                            field="esitoValutazione"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id="esitoValutazione"
                            fieldLabel={'Esito valutazione'}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />}
                </FieldsRow>

                <ValutazioneSection
                    ambito={"SITUAZIONE ABITATIVA"}
                    pageState={this.props.pageState}
                    parentFieldId={'SITUAZIONEABITATIVA.BENESSEREABITATIVO'}
                    valueStatoDopoValutazione={datiSituazioneAbitativa.statoDopoValutazione}
                    onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                    valueDataValutazione={datiSituazioneAbitativa.dataValutazione}
                    onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                    valueDaConsiderare={datiSituazioneAbitativa.daConsiderare}
                    onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                    showSuggerimenti={true}
                    valueSuggerimenti={datiSituazioneAbitativa.propostaAttivitaSuggerimenti}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    openCondition={datiSituazioneAbitativa?.valAbitudiniSicurDomestica}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"situazioneAbitativa"}
                title={"Situazione abitativa"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["benessereAbitativo.situazioneAbitativa.tipoAnimaleDomestico", "benessereAbitativo.situazioneAbitativa.propostaAttivitaSuggerimenti"],
                    [defaultTipoAnimaleDomestico, defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}
SituazioneAbitativa.propTypes = propTypes;
