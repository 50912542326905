import React, {Component} from "react";
import AccordionHelper from "../../service/AccordionHelper";
import Autocomplete from "../generalComponents/autocomplete/Autocomplete";
import Draggable from 'react-draggable';
import * as BsIcon from "react-bootstrap-icons";
import * as _ from "lodash";
import * as $ from "jquery";
import styles from "./SearchBar.module.css";
import PopoverReact from "../popover/Popover";
import PropTypes from "prop-types";

export default class SearchBar extends Component {
    state = {
        campo: null,
        isSearchIconClicked: false,
        canDeleteResearchResult: false
    }


    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        const previousSearchedField = AccordionHelper.previousSearchedField;

        if (this.state.campo && this.state.isSearchIconClicked) {
            const elem = document.getElementById(this.state.campo.value);
            if (elem != null) {
                if (previousSearchedField != null) {
                    this.handleHighlightText(document.getElementById(previousSearchedField.value), previousSearchedField.label, null, false);
                }

                this.handleHighlightText(elem, this.state.campo.label, this.state.campo, true);

                const elementPosition = elem.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.scrollY - 75;
                window.scrollTo({top: offsetPosition, behavior: "smooth"});

                this.setState({isSearchIconClicked: false});
            }
        }

        if ((this.state.campo == null || this.state.campo === '') && previousSearchedField != null) {
            this.handleHighlightText(document.getElementById(previousSearchedField.value), previousSearchedField.label, null, false);
        }
    }

    componentWillUnmount() {
        this.props.onCloseSearchBar();
    }

    handleHighlightText = (elem, text, searchedField, addHighlightStyle) => {
        if (elem != null) {
            let innerHTML = _.cloneDeep(elem.innerHTML);
            const textToEdit = text.split(" - ")[0];
            const index = innerHTML.includes("checkbox") ? innerHTML.lastIndexOf(textToEdit) : innerHTML.indexOf(textToEdit);

            if (index >= 0) {
                if (addHighlightStyle) {
                    elem.innerHTML = `${innerHTML.substring(0, index)}<span class=${styles.highlight}>${innerHTML.substring(index, index + textToEdit.length)}</span>${innerHTML.substring(index + textToEdit.length)}`;
                } else {
                    const indexHighlightStyle1 = innerHTML.substring(0, index).lastIndexOf("<strong><span class=\"SearchBar_highlight");
                    if (indexHighlightStyle1 >= 0) {
                        elem.innerHTML = `${innerHTML.substring(0, indexHighlightStyle1)}<strong>${textToEdit}${innerHTML.substring(index + textToEdit.length + 7)}`;
                    } else {
                        const indexHighlightStyle2 = innerHTML.substring(0, index).lastIndexOf("<span class=\"SearchBar_highlight");
                        if (indexHighlightStyle2 >= 0) {
                            elem.innerHTML = `${innerHTML.substring(0, indexHighlightStyle2)}${textToEdit}${innerHTML.substring(index + textToEdit.length)}`;
                        }
                    }
                }
            }

            if (addHighlightStyle) AccordionHelper.setPreviousSearchedField(searchedField);
        }
    }

    ricercaCampo = () => {
        const collapseBody = $($(document.getElementById(this.state.campo.value))[0]).parents('div.collapse-body');
        const accordion = $(collapseBody.get(collapseBody.length === 1 ? 0 : 1)).parents('div.collapse-div');
        if (accordion.find("> div.collapse.show").length === 0) {
            accordion.find("> div.collapse-header > button").trigger('click');
        }
        setTimeout(() => this.setState({isSearchIconClicked: true}), 10);
    }

    render() {

        const iconSearch = <PopoverReact
            text={<BsIcon.Search color={"#176A65"}
                                 size={30}
                                 height={40}
                                 cursor={'pointer'}
                                 onClick={() => this.state.campo != null && this.state.campo !== '' ? this.ricercaCampo() : null}
            />}
            body={"Cerca"}
            openOnMouseOver={true}
            position={'bottom'}
            className={'popover-info'}
            id={"idPopoverCerca"}
            align={"center"}
            arrowStyle={{left: "45%"}}
            arrowContainerClassName={styles.containerPopoverSearchBar}
        />;

        const iconTrash = <PopoverReact
            text={<BsIcon.Trash3 color={"#176A65"}
                                size={30}
                                height={40}
                                cursor={'pointer'}
                                onClick={() => {
                                    this.setState({campo: null, canDeleteResearchResult: false});
                                }}
            />}
            body={"Pulisci risultati"}
            openOnMouseOver={true}
            position={'bottom'}
            className={'popover-info'}
            id={"idPopoverPulisciRisultati"}
            align={"center"}
            arrowStyle={{left: "45%"}}
            arrowContainerClassName={styles.containerPopoverSearchBar}
        />;

        const iconClose = <PopoverReact
            text={<BsIcon.XLg color={"#176A65"}
                              size={30}
                              height={40}
                              cursor={'pointer'}
                              onClick={() => {
                                  const previousSearchedField = AccordionHelper.previousSearchedField;
                                  if (previousSearchedField != null) {
                                      this.handleHighlightText(document.getElementById(previousSearchedField.value), previousSearchedField.label, null, false);
                                  }
                                  this.props.onCloseSearchBar();
                              }}
            />}
            body={"Chiudi barra di ricerca"}
            openOnMouseOver={true}
            position={'bottom'}
            className={'popover-info'}
            id={"idPopoverChiudiBarraRicerca"}
            align={"center"}
            arrowStyle={{left: "45%"}}
            arrowContainerClassName={styles.containerPopoverSearchBar}
        />;

        return <Draggable positionOffset={{x: '100%', y: '20px'}}>
            <div id={"inputSearchContainer"}
                 className={["row justify-content-center", styles.draggableContainer].join(' ')}>
                <div className={"col-8"}>
                    <Autocomplete
                        id={'autocompleteRicercaCampoInAccordion'}
                        placeholder={'Inserisci nome campo'}
                        field={"campo"}
                        onChange={(_field, elem) => this.setState({
                            campo: elem.target.value,
                            canDeleteResearchResult: elem.target.value?.value != null
                        })}
                        suggestions={AccordionHelper.getSubAccordionFieldsList()}
                        noOptionsMessageInDropdown={'Errore: Campo non presente'}
                        keyField={"value"}
                        descriptionField={"label"}
                        value={this.state.campo != null && this.state.campo !== '' ? this.state.campo : ""}
                        forceUpdate={() => this.forceUpdate()}
                        searchFromStart={false}
                    />
                </div>
                <div className={"col-4"}>
                    {iconSearch}
                    &nbsp;&nbsp;&nbsp;
                    {this.state.canDeleteResearchResult && iconTrash}
                    &nbsp;&nbsp;&nbsp;
                    {iconClose}
                </div>
            </div>
        </Draggable>;
    }
}

SearchBar.propTypes = {
    onCloseSearchBar: PropTypes.func
}
