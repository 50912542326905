import React, {useContext} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {RoleBasedContext} from "../utils/RoleBasedContext";

const versionDefault = 0;
const getVersionJs = _.memoize(version => require(`../utils/dataset/versionRichiesta/version-${version}.js`));

const VersionedAccordion = props => {
    const context = useContext(RoleBasedContext);
    //TODO : versionDefault e stata aggiunta per evitare bonificare il DB - per la richieste aggiunte precedenti al versionamento
    const jsFile = getVersionJs(context.requestVersion || versionDefault);
    const showAccordion = jsFile.accordions.some(e => e.accordion === props.accordion || e.subAccordions.includes(props.accordion));
    if (!showAccordion) return null;

    const accordionProps = _.cloneDeep(props);
    delete accordionProps.idAccordion;
    delete accordionProps.accordion;
    delete accordionProps.children;

    return React.createElement(props.accordion, accordionProps, props.children);
}

VersionedAccordion.propTypes = {
    accordion: PropTypes.element.isRequired,
    children: PropTypes.arrayOf(PropTypes.element)
}

VersionedAccordion.defaultProps = {
    accordionChildren: null
}

export default VersionedAccordion;
