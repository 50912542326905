import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button} from "web-client-archetype";
import ModalHelper from "../../modale/ModalHelper";
import * as $ from "jquery";
import styles from "./PopupDataDimissioneProgrammataAggiornata.module.css"
import _ from "lodash";

const propTypes = {
    chiudiPopupDataDimissioneProgrammataAggiornata: PropTypes.func,
    dataDimissioneProgrammataDaRicovero: PropTypes.string,
}

const bodyModaleText = {
    top: "Il Medico ospedaliero ha modificato la data di dimissione programmata da ricovero, la quale è definita per il giorno: {data}\n",
    down: "Confermare per proseguire",
};
const titleModale = "Attenzione!";
const idModale = "modaleDataDimissioneProgrammataAggiornata";

export default class PopupDataDimissioneProgrammataAggiornata extends Component {
    state = {
        showModaleOperazioneEseguita: false,
        pdfBtnDisabled: false
    }

    closeModaleStyle = () => {
        $(".modal-backdrop").css("display", "none");
        $(".modal-open").removeClass("modal-open");
        $("body").attr("style", "padding-right: 0 !important");
    }

    componentWillUnmount() {
        this.closeModaleStyle()
    }

    annullaAggiornamentoDataDimissioneProgrammataAggiornata = () => {
        this.closeModaleStyle();
        this.props.chiudiPopupDataDimissioneProgrammataAggiornata();
    }

    showOperazioneEseguita = () => {
        this.setState({showModaleOperazioneEseguita: true}, () =>
            this.props.chiudiPopupDataDimissioneProgrammataAggiornata(this.state.showModaleOperazioneEseguita));

    }

    renderModale = () => {
        let state = _.cloneDeep(this.state);

        const getBody = () => (
            <div>
                {bodyModaleText.top.replace("{data}", this.props.dataDimissioneProgrammataDaRicovero)}
                <div><strong>{bodyModaleText.down}</strong></div>
            </div>
        );

        const getFooter = () => (
            <>
                <Button
                    onClick={() => this.showOperazioneEseguita()}
                    id={"confermaBtn"}
                    text="Conferma"
                    className={"btn btn-adi btnOrangeOutline"}
                />
                <Button
                    onClick={() => this.annullaAggiornamentoDataDimissioneProgrammataAggiornata()}
                    id={"annullaBtn"}
                    text="Annulla"
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    disabled={false}
                />
            </>
        )

        function getBackgroundStyle() {
            if (state.showModaleOperazioneEseguita) return "backgroundGreen";
            else return "backgroundOrange";
        }

        return (
            <>
                <ModalHelper
                    id={idModale}
                    title={titleModale}
                    titleClassName={("modal-header d-inline text-center ").concat(getBackgroundStyle())}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={"modal-content"}
                    body={getBody()}
                    footer={getFooter()}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={"modal-body d-flex align-items-center justify-content-around text-center mx-auto"}
                />
            </>
        );
    }

    render() {
        return (
            <>
                {this.renderModale()}
            </>
        )
    }

}

PopupDataDimissioneProgrammataAggiornata.propTypes = propTypes;
