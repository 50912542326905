export const modalCssConsenso = ["modalConsenso", "triangle-down-modal-consenso", "btn btn-adi btnGreenOutline", "btn btn-adi btnWhiteOutlineBorderGreen", "containerConsenso", "modalHeader", "modalBody", "modalFooter"]
export const modalCssOscura = ["modalOscura", "triangle-down-modal-oscura", "btnWarning", "btnWarningOutline", "containerOscura", "modalHeaderOscura", "modalBodyOscura", "modalFooterOscura"]
export const modalCssViola = ["modalViola", "triangle-down-modal-viola", "btnViola", "btnOutlineViola", "containerViola", "modalHeaderViola", "modalBodyViola", "modalFooterViola"];


export const modalMessageConsenso = ["Rilascio del consenso della consulatazione", "Confermi di voler rilasciare questo consenso?", "Una volta rilasciato , ", "i dati e documenti sanitari contenuti nel tuo Fascicolo potranno essere visualizzati dai soggetti autorizzati che ti prenderanno in cura", "si, rilascia il consenso", "#modal1"];
export const modalMessageOscura = ["Oscura il documento", "Sei sicuro di voler procedere ?", "Una volta oscurato, questo documento ", "non sarà visibile nel tuo Fascicolo per esempio in caso di emergenza.", "si, oscura il documento", "#modal2"];
export const modalMessageViola = ["Oscura il documento", "Sei sicuro di voler procedere ?", "Una volta oscurato, questo documento ", "non sarà visibile nel tuo Fascicolo per esempio in caso di emergenza.", "si, oscura il documento", "#modal3"];


const modalConfig = {
    modalCssConsenso: modalCssConsenso,
    modalCssOscura: modalCssOscura,
    modalCssViola: modalCssViola,
    modalMessageConsenso: modalMessageConsenso,
    modalMessageOscura: modalMessageOscura,
    modalMessageViola: modalMessageViola
}
export default modalConfig