import React from "react";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import * as _ from "lodash";
import {v4 as uuid} from "uuid";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import PropTypes from "prop-types";
import {
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedICD9Input,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import SessionRequest from "../../../utils/SessionRequest";
import Utils from "../../../utils/Utils";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti,
    defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    datiComunicazione: PropTypes.any,
    onChangeDatiComunicazione: PropTypes.func,
    pageState: PropTypes.string,
    isOptional: PropTypes.bool,
    onHide: PropTypes.func,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultAlterazione = {
    id: null,
    tipoAlterazione: null
}
const defaultCausa = {
    id: null,
    causa: null
}

export default class Comunicazione extends AccordionSecondLevel {

    state = {
        datiComunicazione: _.cloneDeep(this.props.datiComunicazione)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiComunicazione, this.props.datiComunicazione))
            this.forceUpdate(() => this.inizializzaForm());
    }

    inizializzaForm = () => {
        let datiComunicazione = _.cloneDeep(this.props.datiComunicazione);

        if (datiComunicazione.dispositivi == null) datiComunicazione.dispositivi = [];
        if (datiComunicazione.causeLinguaggioProduzione == null) datiComunicazione.causeLinguaggioProduzione = [];
        if (datiComunicazione.causeLinguaggioComprensione == null) datiComunicazione.causeLinguaggioComprensione = [];

        this.setState({datiComunicazione: datiComunicazione}, () => this.validazioneDati());
    }

    validazioneDati = () => {
        let datiComunicazione = _.cloneDeep(this.state.datiComunicazione);
        this.props.onChangeDatiComunicazione("datiComunicazione", datiComunicazione);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiComunicazione?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneComunicazione", campiObbligatoriRimanenti);
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({datiComunicazione}) => {
            if (index === null && subField === null) {
                datiComunicazione[field] = elem;
            } else {
                datiComunicazione[field][index][subField] = elem;
            }

            if (datiComunicazione.alterazioniLettura !== "01") {
                datiComunicazione.tipoAlterazione = [];
            } else if (datiComunicazione.linguaggioComprensione === "01" || datiComunicazione.linguaggioComprensione === null) {
                datiComunicazione.causeLinguaggioComprensione = [];
            } else if (datiComunicazione.linguaggioProduzione === "01" || datiComunicazione.linguaggioProduzione === null) {
                datiComunicazione.causeLinguaggioProduzione = [];
            } else if (!datiComunicazione.alterazioniLettura) {
                datiComunicazione.tipoAlterazione = [];
            }
            if (datiComunicazione.tipoAlterazione === null) {
                datiComunicazione.tipoAlterazione = [];
            }

            return {datiComunicazione};
        }, () => this.validazioneDati());
    }

    onHide = () => {
        const datiComunicazione = _.cloneDeep(SessionRequest.getInitObject('anamnesiFisiologica.datiComunicazione'));
        this.setState({datiComunicazione: datiComunicazione},
            () => {
                this.props.onChangeDatiComunicazione("datiComunicazione", datiComunicazione);
                this.props.onHide();
            });
    }

    handleArrayDispositiviChange = (array, field) => {
        let data = _.cloneDeep(this.state.datiComunicazione);
        data[field] = array;
        this.setState({datiComunicazione: data}, () => {
            this.validazioneDati();
            this.forceUpdate();
        });
    }

    /* Alterazioni */

    addNewAlterazione = () => {
        let dati = _.cloneDeep(this.state.datiComunicazione);
        let type = _.cloneDeep(defaultAlterazione);
        type.id = uuid();
        dati.tipoAlterazione.push(type);
        this.setState({datiComunicazione: dati}, () => this.validazioneDati());
    }

    removeAlterazione = (index) => {
        let dati = _.cloneDeep(this.state.datiComunicazione);
        dati.tipoAlterazione.splice(index, 1);
        this.setState({datiComunicazione: dati}, () => this.validazioneDati());
    }

    isAlterazioneValida = (alterazione) => {
        return !Utils.isObjectNull(alterazione) && !Utils.isStringEmptyOrNullOrUndefined(alterazione?.tipoAlterazione);
    }

    /* Cause */
    addNewCausa = () => {
        let dati = _.cloneDeep(this.state.datiComunicazione);
        let type = _.cloneDeep(defaultCausa);
        type.id = uuid();
        dati.causeLinguaggioProduzione.push(type);
        this.setState({datiComunicazione: dati}, () => this.validazioneDati());
    }

    removeCausa = (index) => {
        let dati = _.cloneDeep(this.state.datiComunicazione);
        dati.causeLinguaggioProduzione.splice(index, 1);
        this.setState({datiComunicazione: dati}, () => this.validazioneDati());
    }

    renderAlterazione = (alterazione, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedICD9Input
                        fieldId={'M_TIPO_ALT_LETT.COMUNICAZIONE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_TIPO_ALT_LETT.COMUNICAZIONE.COMUNICAZIONE'}
                        pageState={this.props.pageState}
                        id={"autocompleteTipoAlterazione" + index}
                        keyField={"codice"}
                        descriptionField={"descrizione"}
                        onChange={(field, elem) => this.handleAggiornaForm("tipoAlterazione", elem.target.value, index, field)}
                        searchFromStart={false}
                        value={alterazione.tipoAlterazione}
                        field={"tipoAlterazione"}
                        placeholder={"ICD9-CM - Descrizione"}
                        fieldLabel={"Tipo di alterazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                </FieldsRow>
            </>
        );
    }

    renderCauseLinguaggioProduzione = (alterazione, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedICD9Input
                        fieldId={'M_CAUSE_PROD.COMUNICAZIONE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_CAUSE_PROD.COMUNICAZIONE.COMUNICAZIONE'}
                        pageState={this.props.pageState}
                        value={alterazione.causa}
                        placeholder={"Testo libero o ICD9-CM - Descrizione"}
                        onChange={(field, elem) => this.handleAggiornaForm("causeLinguaggioProduzione", elem.target.value, index, "causa")}
                        id={"basedInputCause" + index}
                        keyField={"codice"}
                        fieldLabel={"Cause"}
                        field={'causa'}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                </FieldsRow>
            </>
        );
    }

    addNewCausaLinguaggioComprensione = () => {
        let dati = _.cloneDeep(this.state.datiComunicazione);
        let type = _.cloneDeep(defaultCausa);
        type.id = uuid();
        dati.causeLinguaggioComprensione.push(type);
        this.setState({datiComunicazione: dati}, () => this.validazioneDati());
    }

    removeCausaLinguaggioComprensione = (index) => {
        let dati = _.cloneDeep(this.state.datiComunicazione);
        dati.causeLinguaggioComprensione.splice(index, 1);
        this.setState({datiComunicazione: dati}, () => this.validazioneDati());
    }

    renderCauseLinguaggioComprensione = (alterazione, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedICD9Input
                        fieldId={'M_CAUSE_COMPR.COMUNICAZIONE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={"I_CAUSE_COMPR.COMUNICAZIONE.COMUNICAZIONE"}
                        pageState={this.props.pageState}
                        value={alterazione.causa}
                        placeholder={"Testo libero o ICD9-CM - Descrizione"}
                        onChange={(field, elem) => this.handleAggiornaForm("causeLinguaggioComprensione", elem.target.value, index, "causa")}
                        id={"basedInputCause" + index}
                        keyField={"codice"}
                        fieldLabel={"Cause"}
                        field={'causa'}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                </FieldsRow>
            </>
        );
    }

    renderBody = () => {
        let datiComunicazione = this.state.datiComunicazione;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"M_PRES_ALT_LETT.COMUNICAZIONE.ANAMNESIFISIOLOGICA"}
                        infermiereFieldId={"I_PRES_ALT_LETT.COMUNICAZIONE.COMUNICAZIONE"}
                        pageState={this.props.pageState}
                        id={"selectAlterazioniLettura"}
                        options={OggettiUtili.alterazioniLettura}
                        value={datiComunicazione.alterazioniLettura}
                        onChange={(elem) => this.handleAggiornaForm("alterazioniLettura", elem)}
                        isSearchable={false}
                        fieldLabel={'Presenza di alterazioni nella lettura'}
                        noOptionsMessage={() => "Errore: Linguaggio comprensione non esistente"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"alterazioniLettura"}
                    />
                </FieldsRow>

                {datiComunicazione.alterazioniLettura === "01" &&
                <SectionList
                    title={'Alterazione'}
                    data={datiComunicazione.tipoAlterazione}
                    renderSection={this.renderAlterazione}
                    addNewSectionCallback={this.addNewAlterazione}
                    removeSectionCallback={this.removeAlterazione}
                    addButtonVisibilityHandler={datiComunicazione.tipoAlterazione?.length === 0 || datiComunicazione.tipoAlterazione?.every(this.isAlterazioneValida)}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    pageState={this.props.pageState}
                    keyFieldId={'M_TIPO_ALT_LETT.COMUNICAZIONE.ANAMNESIFISIOLOGICA'}
                    infermiereKeyFieldId={'I_TIPO_ALT_LETT.COMUNICAZIONE.COMUNICAZIONE'}
                    field={'tipoAlterazione'}
                    parentJsonPath={"anamnesiFisiologica.datiComunicazione.tipoAlterazione"}
                />}

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"M_LING_COMPR.COMUNICAZIONE.ANAMNESIFISIOLOGICA"}
                        infermiereFieldId={"I_LING_COMPR.COMUNICAZIONE.COMUNICAZIONE"}
                        pageState={this.props.pageState}
                        id={"selectLinguaggioComprensione"}
                        options={OggettiUtili.linguaggioComprensione}
                        value={datiComunicazione.linguaggioComprensione}
                        onChange={(elem) => this.handleAggiornaForm("linguaggioComprensione", elem)}
                        isSearchable={false}
                        fieldLabel={'Linguaggio (comprensione)'}
                        noOptionsMessage={() => "Errore: Linguaggio comprensione non esistente"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"linguaggioComprensione"}
                    />
                </FieldsRow>

                {datiComunicazione.linguaggioComprensione !== "01" && datiComunicazione.linguaggioComprensione !== null &&
                <SectionList
                    title={'Cause'}
                    data={datiComunicazione.causeLinguaggioComprensione}
                    renderSection={this.renderCauseLinguaggioComprensione}
                    addNewSectionCallback={this.addNewCausaLinguaggioComprensione}
                    removeSectionCallback={this.removeCausaLinguaggioComprensione}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    pageState={this.props.pageState}
                    keyFieldId={'M_CAUSE_COMPR.COMUNICAZIONE.ANAMNESIFISIOLOGICA'}
                    infermiereKeyFieldId={'I_CAUSE_COMPR.COMUNICAZIONE.COMUNICAZIONE'}
                    field={'causeLinguaggioComprensione'}
                    parentJsonPath={"anamnesiFisiologica.datiComunicazione.causeLinguaggioComprensione"}
                />}

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"M_LING_PROD.COMUNICAZIONE.ANAMNESIFISIOLOGICA"}
                        infermiereFieldId={"I_LING_PROD.COMUNICAZIONE.COMUNICAZIONE"}
                        pageState={this.props.pageState}
                        id={"selectLinguaggioProduzione"}
                        options={OggettiUtili.linguaggioProduzione}
                        value={datiComunicazione.linguaggioProduzione}
                        onChange={(elem) => this.handleAggiornaForm("linguaggioProduzione", elem)}
                        isSearchable={false}
                        fieldLabel={'Linguaggio (produzione)'}
                        noOptionsMessage={() => "Errore: Linguaggio produzione non esistente"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"linguaggioProduzione"}
                    />
                </FieldsRow>

                {datiComunicazione.linguaggioProduzione !== "01" && datiComunicazione.linguaggioProduzione !== null &&
                <SectionList
                    title={'Cause'}
                    data={datiComunicazione.causeLinguaggioProduzione}
                    renderSection={this.renderCauseLinguaggioProduzione}
                    addNewSectionCallback={this.addNewCausa}
                    removeSectionCallback={this.removeCausa}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    pageState={this.props.pageState}
                    keyFieldId={'M_CAUSE_PROD.COMUNICAZIONE.ANAMNESIFISIOLOGICA'}
                    infermiereKeyFieldId={'I_CAUSE_PROD.COMUNICAZIONE.COMUNICAZIONE'}
                    field={'causeLinguaggioProduzione'}
                    parentJsonPath={"anamnesiFisiologica.datiComunicazione.causeLinguaggioProduzione"}
                />}

                <DispositiviPresenti
                    dispositiviPresenti={datiComunicazione.dispositivi}
                    handleArrayChange={this.handleArrayDispositiviChange}
                    pageState={this.props.pageState}
                    fieldId={'M_DISP_PRES.COMUNICAZIONE.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={'I_DISP_PRES.COMUNICAZIONE.COMUNICAZIONE'}
                    field={"dispositivi"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    parentJsonPath={"anamnesiFisiologica.datiComunicazione.dispositivi"}
                />

                {((datiComunicazione.linguaggioComprensione && datiComunicazione.linguaggioComprensione !== "01")
                    || (datiComunicazione.linguaggioProduzione && datiComunicazione.linguaggioProduzione !== "01")) &&
                <ValutazioneSection
                    ambito={"COMUNICAZIONE"}
                    pageState={this.props.pageState}
                    parentFieldId={'COMUNICAZIONE.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'COMUNICAZIONE.COMUNICAZIONE'}
                    appendRolePrefix={true}
                    onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                    valueStatoDopoValutazione={datiComunicazione.statoDopoValutazione}
                    onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                    valueDataValutazione={datiComunicazione.dataValutazione}
                    onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                    valueDaConsiderare={datiComunicazione.daConsiderare}
                    showSuggerimenti={true}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={datiComunicazione.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={datiComunicazione.autonomiaGestioneCaregiver}
                    valueSuggerimenti={this.state.datiComunicazione.propostaAttivitaSuggerimenti}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                />}
            </>
        );
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"datiComunicazione"}
            title={"Comunicazione"}
            required={false}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            onHide={this.onHide}
            isOptional={this.props.isOptional && isFieldIdWriteable('M_TIPO_ALT_LETT.COMUNICAZIONE.ANAMNESIFISIOLOGICA', this.props.pageState, this.context.forceUserRole)}
            fieldsOrderMap={Utils.generateMapPathsObjects(
                ["anamnesiFisiologica.datiComunicazione.tipoAlterazione",
                    "anamnesiFisiologica.datiComunicazione.causeLinguaggioComprensione",
                    "anamnesiFisiologica.datiComunicazione.causeLinguaggioProduzione",
                    "anamnesiFisiologica.datiComunicazione.dispositivi",
                    "anamnesiFisiologica.datiComunicazione.propostaAttivitaSuggerimenti"],
                [defaultAlterazione, defaultCausa, defaultCausa, defaultDispositiviPresenti, defaultProposteAttivitaSuggerimenti]
            )}
            accordionReadOnly={this.props.accordionReadOnly}
            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
        >{this.renderBody()}</AccordionSezioneRichiesta>
    }
}

Comunicazione.propTypes = propTypes;

Comunicazione.defaultProps = {
    isOptional: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

Comunicazione.contextType = RoleBasedContext;
