import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "web-client-archetype";
import ModalHelper from "../../modale/ModalHelper";
import RichiestaADIService from "../../../service/RichiestaADIService";
import _ from "lodash";
import Utils, { closeModaleStyle } from "../../../utils/Utils";
import { mostraSpinner, nascondiSpinner } from "../../../App";
import errors from "../../../utils/errors/errors.json";
import messageIt from "../../../utils/message/message-it.json";
import azioniRichiesta from "../../../utils/dataset/richiestaData/azioniRichiesta"

const propTypes = {
    uuidRichiesta: PropTypes.object,
    chiudiSubentroRichiesta: PropTypes.func,
    tipo: PropTypes.string
}

const bodyModaleText = "Stai per procedere con il subentro della richiesta. Vuoi confermare l’operazione?";
const bodyModaleTextPresaInCarico = "Stai per procedere con la presa in carico della richiesta. Vuoi confermare l’operazione?";
const bodyModaleOperazioneEseguitaText = "L'operazione è terminata con successo.";

const titleModale = "Subentro richiesta";
const titleModalePresaInCarico = "Presa in carico richiesta";
const titleModaleOperazioneEseguita = "Operazione completata";

const idModale = "modaleSubentroRichiesta";
const idModaleOperazioneEseguita = "modaleOperazioneEseguita";

export default class SubentroRichiesta extends Component {
    state = {
        mostraModaleOperazioneEseguita: false
    }

    componentDidMount() {
    }

    chiudiSubentroRichiesta = () => {
        closeModaleStyle();
        this.props.chiudiSubentroRichiesta();
    }

    subentraRichiesta = () => {
        mostraSpinner();

        RichiestaADIService.presaInCarico(this.props.uuidRichiesta).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                if (response?.msgCode && response.msgCode.startsWith("WARNING_WD")) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ?? response?.status,
                        type: "confirm",
                        text: errors[response?.msgCode]?.message || response?.msgCode
                    });
                    nascondiSpinner();
                    //l'operazione è stata comunque eseguita, quindi proseguo fino alla redirect
                } else if (response?.status === "403") {
                    Utils.toasterFunction(messageIt.alertPresaInCaricoGiaEffettuata);
                    nascondiSpinner();
                    return;
                }

                this.chiudiSubentroRichiesta();
            }
            else {
                this.setState({ mostraModaleOperazioneEseguita: true }, () => { nascondiSpinner(); })
            }
        })
    }

    renderModale = () => {
        let state = _.cloneDeep(this.state);

        const bodyModale = (
            <div className={"text-center"}>
                {this.props.tipo === azioniRichiesta.subentra.label ? bodyModaleText : bodyModaleTextPresaInCarico}
            </div>
        );

        const bodyModaleOperazioneEseguita = (
            <div>{bodyModaleOperazioneEseguitaText}</div>
        );

        const getBody = () => {
            if (state.mostraModaleOperazioneEseguita) {
                return (bodyModaleOperazioneEseguita);
            }
            else {
                return (bodyModale);
            }
        };

        const footerModale = (
            <>
                <Button
                    onClick={() => this.subentraRichiesta()}
                    id={"confermaSubentraBtn"}
                    text="Conferma"
                    className={"btn btn-adi btnGreenOutline"}
                />
                <Button
                    onClick={() => this.chiudiSubentroRichiesta()}
                    id={"annullaSubentraBtn"}
                    text="Annulla"
                    className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                    disabled={false}
                />
            </>
        )

        const footerModaleOperazioneEseguita = (
            <>
                <Button
                    onClick={() => this.chiudiSubentroRichiesta()}
                    id={"chiudiBtn"}
                    text="Chiudi"
                    className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                />
            </>
        );

        const getFooter = () => {
            if (state.mostraModaleOperazioneEseguita) {
                return (footerModaleOperazioneEseguita);
            }
            else {
                return (footerModale);
            }
        }

        const getTitle = () => {
            if (state.mostraModaleOperazioneEseguita) {
                return (titleModaleOperazioneEseguita);
            }
            else {
                return (this.props.tipo === azioniRichiesta.subentra.label ? titleModale : titleModalePresaInCarico);
            }
        }

        function getId() {
            if (state.mostraModaleOperazioneEseguita) {
                return idModaleOperazioneEseguita;
            }
            else {
                return idModale;
            }
        }


        return (
            <>
                <ModalHelper
                    id={getId()}
                    title={getTitle()}
                    titleClassName={"modal-header d-inline text-center backgroundGreen"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={"modal-content"}
                    body={getBody()}
                    footer={getFooter()}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={"modal-body d-flex align-items-center align-self-center f-weight-600"}
                />
            </>
        );
    }

    render() {
        return (
            <>
                {this.renderModale()}
            </>
        )
    }

}

SubentroRichiesta.propTypes = propTypes;
