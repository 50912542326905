import {catchError, finalize, map} from "rxjs/operators";
import {from, of} from "rxjs";
import config from "../config/serviceConfig";
import auth from "../utils/AthnAuth";
import {mostraSpinner, nascondiSpinner} from "../App";

const MATCH_ANY_CHARACTERS = ".*";

export default class ApiAPMSService {

    static urlUtente = config["ms_utenza"].utente;
    static urlUtenti = config["ms_utenza"].utenti;
    static urlListaUtenti = config["ms_utenza"].listaUtenti;
    static urlUtentiV2 = config["ms_utenza_v2"].utenti;

    static listaUtentiConAttributi(body) {

        let instance = auth._httpClient;

        let obs = from(instance.post(ApiAPMSService.urlListaUtenti, body));
        return obs.pipe(
            map(response => response.data),
            catchError(err => {
                    return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
                }
            ));
    }

    static inserisciListaUtenti(body) {
        let instance = auth._httpClient;

        let obs = from(instance.post(ApiAPMSService.urlUtenti, body));
        return obs.pipe(
            map(response => response.data),
            catchError(err => {
                    return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
                }
            ));
    }

    static eliminaUtente(body) {
        let instance = auth._httpClient;

        let obs = from(instance.delete(ApiAPMSService.urlUtente, { data: body }));
        return obs.pipe(
            map(response => response.data),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            })
        )
    }

    static getUtenti(utente, page) {

        let instance = auth._httpClient;

        let params = {};

        params.applicazione = "ADI";
        params.page = page;

        if (Object.prototype.hasOwnProperty.call(utente, "size")) params.size = utente.size;
        if (utente.nome) params.nome = utente.nome;
        if (utente.cognome) params.cognome = utente.cognome;
        if (utente.codiceFiscale) params.codiceFiscale = utente.codiceFiscale;
        if (utente.aulss) params.strutture = utente.aulss;

        let profilo = "*;adi-lista-settings;@";

        if(utente.comune)
            params.profilo = profilo.concat("@" + utente.comune.value);
        else if (utente.distretto && utente.sede)
            params.profilo = profilo.concat(utente.distretto.value + "@"+ utente.distretto.value + utente.sede.value);
        else if (utente.distretto)
            params.profilo = profilo.concat(utente.distretto.value);
        
        let obs = from(instance.get(ApiAPMSService.urlUtentiV2,  {params: params}));

        return obs.pipe(
            map(response => response.data),
            catchError((err) => {
                console.log(err)
                return [];
            }));
    }

    static letturaUtenti(utente) {
        mostraSpinner();
        let instance = auth._httpClient;

        let params = {};

        params.size = 0;
        params.applicazione = "ADI";
        params.strutture = utente.aulss;

        // •	profilo: attributo composto da una stringa del tipo: codice_gruppo;codice_attributo;regex_attributo.
        // Tramite questo parametro è possibile filtrare ulteriormente la ricerca nei seguenti modi:
        // •	codice_gruppo;codice_attributo;regex_attributo: in questo caso si esplicita per ognuno il relativo valore e separandoli tramite l’operatore ; .
        // •	*;codice_attributo;regex_attributo: in questo caso viene assegnato al codice_gruppo il valore *(wild card) con la quale si filtra per ogni codice_gruppo.
        // •	codice_gruppo: in questo caso viene esplicitato solo il codice_gruppo e vengono omessi gli altri due parametri e il filtro verrà applicato solo per il valore di codice_gruppo.

        let codiceGruppo = "*";
        const CODICE_ATTRIBUTO = "adi-lista-settings";

        // formatta la stringa della regex della lista settings
        let formattaRegexAttributo = (regexAttributo) => {
            let arr = regexAttributo.split("@");
            let valuedIndices = arr.map((e, i) => {
                return i;
            }).filter((e) => {
                return arr[e] !== "";
            });
            let lastIndexValued = valuedIndices[valuedIndices.length - 1];
            let n = lastIndexValued + 1;
            let i = 0;
            let res = "";
            while(n > i){
                res = res + (i === 0 ? "" : "@") + arr[i];
                i++;
            }
            return res;
        };

        const MATCH_ANY_VALUE_ATTRIBUTE = `@${MATCH_ANY_CHARACTERS}`;
        // regex_attributo =  profilo@ruolo@distretto@sede@comune@disciplina (regex attributo per adi)
        let profilo = utente.profilo ? `${utente.profilo}` : MATCH_ANY_CHARACTERS;
        let ruolo = utente.ruolo ? `@${utente.ruolo}` : MATCH_ANY_VALUE_ATTRIBUTE;
        let distretto = utente.distretto ? `@${utente.distretto}` : MATCH_ANY_VALUE_ATTRIBUTE;
        let sede = utente.sede ? `@${utente.sede}` : MATCH_ANY_VALUE_ATTRIBUTE;
        let comune = utente.comune ? `@${utente.comune}` : MATCH_ANY_VALUE_ATTRIBUTE;
        let disciplina = utente.disciplina ? `@${utente.disciplina}` : MATCH_ANY_VALUE_ATTRIBUTE;
        let unitaOperativa = utente.unitaOperativa ? `@${utente.unitaOperativa}` : MATCH_ANY_VALUE_ATTRIBUTE;
        let regexAttributo = profilo + ruolo + distretto + sede + comune + disciplina + unitaOperativa;
        regexAttributo = formattaRegexAttributo(regexAttributo);

        let aggregazioneProfilo = `${codiceGruppo};${CODICE_ATTRIBUTO};${regexAttributo}`;

        params.profilo = aggregazioneProfilo;


        let obs = from(instance.get(ApiAPMSService.urlUtentiV2,  {params: params}));

        return obs.pipe(
            map(response => response.data),
            finalize(() => {
                nascondiSpinner();
            })
        );
    }
}
