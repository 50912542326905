import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {FieldsRow, RoleBasedInput, RoleBasedSelect} from "../../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../../utils/dataset/OggettiUtili.json";

const propTypes = {
    dati: PropTypes.object,
    onChange: PropTypes.func,
    pageState: PropTypes.string,
    fieldInAccordionReadOnly: PropTypes.bool
}

export default class StomiaUrinaria extends Component {

    state = {
        tipo: null,
        descrizioneStomia: null,
        dimensioniCatetere: null,
        necessitaDiInstillazioniIrrigazioniVescicali: null,
        quantitaDiLiquidi: null,
        tipologia: null
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati)) this.inizializzaForm();
    }

    inizializzaForm = () => {
        let state = _.cloneDeep(this.props.dati);
        if (state == null) state = _.cloneDeep(this.state);
        this.setState(state);
    }

    handleAggiornaForm = (field, elem) => {
        let state = _.cloneDeep(this.state);
        if (field === "necessitaDiInstillazioniIrrigazioniVescicali") {
            state["quantitaDiLiquidi"] = null;
            state["tipologia"] = null;
        }
        state[field] = new RegExp(/^[-]\d+$/g).test(elem) ? "0" : elem;
        this.setState(state, () => this.props.onChange("stomiaUrinaria", this.state));
    }

    render() {
        return <>
            <FieldsRow>
                <RoleBasedSelect
                    id={"selectTipo"}
                    fieldId={'TIPO_STOMIA_URIN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"tipo"}
                    fieldLabel={"Tipo"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.tipoStomiaUrin}
                    value={this.state.tipo}
                    onChange={(elem) => this.handleAggiornaForm("tipo", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedInput
                    fieldId={'TIPO_STOMIA_URIN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'} //TODO: inserire field id corretto
                    pageState={this.props.pageState}
                    placeholder={"Descrizione stomia"}
                    field={"descrizioneStomia"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    id={"descrizioneStomia"}
                    value={this.state.descrizioneStomia}
                    fieldLabel={"Descrizione stomia"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedInput
                    id={"inputDimensioniCatetere"}
                    fieldId={'DIM_CAT_STOMIA_URIN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Dimensioni catetere (diametro)"}
                    field={"dimensioniCatetere"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci dimensioni catetere (diametro)"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.dimensioniCatetere}
                    type={"number"}
                    min={"0"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectNecessitaDiInstillazioniIrrigazioniVescicali"}
                    fieldId={'NEC_INSTIL_IRRIG_VESC_STOMIA_URIN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"necessitaDiInstillazioniIrrigazioniVescicali"}
                    fieldLabel={"Necessità di instillazioni/irrigazioni vescicali"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.rispostaBreve}
                    value={this.state.necessitaDiInstillazioniIrrigazioniVescicali}
                    onChange={(elem) => this.handleAggiornaForm("necessitaDiInstillazioniIrrigazioniVescicali", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
            {this.state.necessitaDiInstillazioniIrrigazioniVescicali &&
            <FieldsRow>
                <RoleBasedInput
                    id={"inputQuantitaDiLiquidi"}
                    fieldId={'QUANT_LIQ_STOMIA_URIN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Quantità di liquidi"}
                    field={"quantitaDiLiquidi"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci quantità di liquidi"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.quantitaDiLiquidi}
                    type={"number"}
                    min={"0"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedInput
                    id={"inputTipologia"}
                    fieldId={'TIPO_LIQ_STOMIA_URIN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Tipologia"}
                    field={"tipologia"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci Tipologia"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.tipologia}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>}
        </>;
    }
}

StomiaUrinaria.propTypes = propTypes;
