import assert from 'assert'
import aulssUtils from '../src/utils/AulssUtils'

describe('Test aulss', function() {
  describe('Id distretti univoci', function() {
    it('ritorna false se gli id dei distretti sono univoci ', function(){
      assert.equal(false, aulssUtils.checkIdDistrettiDuplicati());
    });
  });
  describe('Id sedi univoche', function() {
    it('ritorna false se gli id delle sedi sono univoci', function(){
      assert.equal(false, aulssUtils.checkIdSediDuplicate());
    });
  });
});