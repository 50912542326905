import React, {Component, Fragment} from "react";
import {Button, SessioneUtente} from "web-client-archetype";
import PropTypes from "prop-types";
import routepath from "../../../utils/route/route-path.json";
import {Redirect} from "react-router-dom";
import * as _ from "lodash";
import AuthUtils from "../../../utils/AuthUtils";
import enumRichiesta from "../../../enum/enumRichiesta.json";
import ModalHelper from "../../modale/ModalHelper";
import enumPaginaCompila from "../../../enum/enumPaginaCompila";
import enumRiepilogo from "../../../enum/enumRiepilogo";
import CompilaRichiesta from "../../compilaRichiesta/CompilaRichiesta";
import CompilaProposte from "../../compilaProposte/CompilaProposte";
import $ from "jquery";
import PopupConfermaInoltraRichiesta from "../popupConfermaInoltraRichiesta/PopupConfermaInoltraRichiesta";
import PopupConfermaValutazione from "../popupConfermaValutazione/PopupConfermaValutazione";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import Utils from "../../../utils/Utils";
import statiRichiesta from "../../../utils/dataset/richiestaData/statiRichiesta.json";
import {mostraSpinner, nascondiSpinner} from "../../../App";
import RichiestaADIService from "../../../service/RichiestaADIService";
import messageIt from "../../../utils/message/message-it.json";
import enumsUtente from "../../../enum/enumsUtente";
import errors from "../../../utils/errors/errors.json";
import PopupRichiestaDiModifica from "../popupRichiestaDiModifica/PopupRichiestaDiModifica";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import PopupAutoAssegnazioneValutazione from "../popupAutoAssegnazioneValutazione/PopupAutoAssegnazioneValutazione";
import PopupDataDimissioneProgrammataAggiornata
    from "../popupDataDimissioneProgrammataAggiornata/PopupDataDimissioneProgrammataAggiornata";
import {getSettingsProfessionista} from "../../statoValutazioneEComponentiDelTeam/StatoValutazioneEComponentiDelTeam";
import {getValutazionePrecedente} from "../../home/HomeUtils";
import classificazione from "../../../utils/dataset/richiestaData/classificazione.json";
import CompilaRichiestaCampiObbligatori from "../../compilaRichiestaCampiObbligatori/CompilaRichiestaCampiObbligatori";
import {v4 as uuid} from "uuid";
import AccordionHelper from "../../../service/AccordionHelper";
import ButtonsBoxStick from "../../buttonsBoxStick/buttonsBoxStick";
import SubentroRichiesta from "../../home/subentroRichiesta/SubentroRichiesta";
import ContainerPaginaCompila from "../../containerPaginaCompila/ContainerPaginaCompila";

const propTypes = {
    richiesta: PropTypes.object,
    richiestaMedico: PropTypes.object,
    modalita: PropTypes.string,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    firstPageName: PropTypes.string,
    persone: PropTypes.object,
    pageState: PropTypes.string,
    showOnlyRequiredFields: PropTypes.bool,
    previousPageOnlyRequiredFields: PropTypes.bool,
    lastPageNumber: PropTypes.number,
    showMultiCompilationPages: PropTypes.bool
}

const defaultProps = {
    readOnly: true,
    hideUnsetValues: true
}

export default class FormRiepilogo extends Component {
    state = {
        pageNumber: null,
        richiesta: null,
        richiestaMedico: null,
        mostraAvviaFormalizzazione: true,
        redirectTo: null,
        dataDimissioneProgrammataDaRicovero: null,

        apriModaleConferma: false,
        apriModaleConfermaPresaInCarico: false,
        apriModaleInoltraRichiesta: false,
        apriModaleConfermaValutazione: false,
        confermaValutazioneAbilitato: true,
        apriModaleRichiediModificaValutazione: false,
        apriModaleSubentra: false,
        apriModaleAutoAssegnazioneValutazione: true,
        dataDimissioneProgrammataDaRicoveroAggiornata: false,
        isFormalizzazione: false,
        isValutazione: false,
        richiestaAggiornata:{}
    }

    NUMERO_PAGINE = {
        pageOne: 1,
        pageTwo: 2
    }

    constructor(props) {
        super(props);
        this.disabilitaConfermaValutazione = this.disabilitaConfermaValutazione.bind(this);
    }

    componentDidMount() {
        this.montaFormRiepilogo();
    }

    disabilitaConfermaValutazione() {
        this.setState({confermaValutazioneAbilitato: false});
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.richiesta, this.props.richiesta) || !_.isEqual(prevProps.richiestaMedico, this.props.richiestaMedico)) this.montaFormRiepilogo();

        if ((!_.isEqual(prevState.richiesta, this.state.richiesta) && this.state.richiesta) ||
            (!_.isEqual(prevState.pageNumber, this.state.pageNumber) && this.state.richiesta)) {
            this.disabilitaContenuti();
        }
    }

    montaFormRiepilogo = () => {
        this.setState({
            richiesta: this.props.richiesta,
            richiestaMedico: this.props.richiestaMedico,
            pageNumber: this.props.lastPageNumber ?? this.NUMERO_PAGINE.pageOne
        }, () => {
            if(this.state.richiesta?.id) this.recuperaRichiesta();
            if (this.props.modalita === enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_FORMALIZZATORE_COORDINATORE)
                this.controllaAvvioFormalizzazione(_.cloneDeep(this.state.richiesta));
        });
    }

    recuperaRichiesta = () => {
        RichiestaADIService.letturaRichiesta(this.state.richiesta.id, false)
            .subscribe(res => {
                this.setState({ richiestaAggiornata: res?.data });
            })
    }

    disabilitaContenuti = () => {
        document.addEventListener('readystatechange', e => {
        if (document.readyState === "complete" || document.readyState === "interactive") {
            $('#compilaRichiestaId div#trackbar, #compilaRichiestaId div.headerRichiesta, #compilaProposteId div#trackbar, #compilaProposteId div.headerRichiesta').remove();
            $('#compilaRichiestaId div.bottoniLayout, #compilaProposteId div.bottoniLayout').remove();
            $('label:contains("*Campo obbligatorio")').remove()
        }})
    }

    // Controllo lo stato della richiesta
    controllaAvvioFormalizzazione = () => {
        const isMcp = AuthUtils.hasUtenteRuoloMcp() || AuthUtils.hasUtenteCentraleAdi;
        const mostraAvviaFormalizzazione = (this.state.richiesta?.stato === enumRichiesta.stato.CONFERMATA || this.state.richiesta?.stato === enumRichiesta.stato.CONFERMATA_PRE_DIALOGO) && (isMcp);
        this.setState({mostraAvviaFormalizzazione});
    }

    prosegui = () => {
        this.setState({pageNumber: this.NUMERO_PAGINE.pageTwo});
    }

    prendiInCarico = () => {
        let teamUtentePresente = _.filter(this.props.richiesta?.team,
            t => (t.professionista?.codiceFiscale != null && t.professionista?.codiceFiscale !== SessioneUtente.getInstance().idUtente) &&
                t.professionista?.profilo === getSettingsProfessionista(SessioneUtente.getInstance().settings)?.profilo);
        if (teamUtentePresente?.length) {
            this.setState({apriModaleConfermaPresaInCarico: true})
        } else {
            this.chiamaPrendiInCarico();
        }
    }

    chiamaPrendiInCarico() {
        mostraSpinner();
        RichiestaADIService.presaInCarico(this.props.richiesta.id).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                if (response?.msgCode && response.msgCode.startsWith("WARNING_WD")) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ?? response?.status,
                        type: "confirm",
                        text: errors[response?.msgCode]?.message || response?.msgCode
                    });
                    //l'operazione è stata comunque eseguita, quindi proseguo fino alla redirect
                } else if (response?.status === "403") {
                    Utils.toasterFunction(messageIt.alertPresaInCaricoGiaEffettuata);
                    nascondiSpinner();
                    return;
                }
            }
            let richiesta = _.cloneDeep(this.props.richiesta);

            if (AuthUtils.hasUtenteProfiloCoordinatore())
                richiesta.coordinatore = {codiceFiscale: SessioneUtente.getInstance().idUtente};
            else if (AuthUtils.hasUtenteRuoloFormalizzatore() && AuthUtils.hasUtenteRuoloMcp())
                richiesta.mcp = {codiceFiscale: SessioneUtente.getInstance().idUtente};

            // una volta preso in carico la richiesta sia il coordinatore, che l'mcp vanno sul path verifica-valutazione
            let pathnameRedirect = routepath.verifica_valutazione;
            let pageState = Utils.getPageStateDimissioneProtetta(this.props.richiesta?.tipologia, this.props.richiesta?.stato, enumPaginaCompila.VERIFICA_VALUTAZIONE) ?? "V";
            if (AuthUtils.hasUtenteRuoloMcp() && Utils.isSegnalazioneConfermataPreDialogoOrInChiusuraPreDialogo(richiesta)) {
                pathnameRedirect = routepath.compila_chiusura_predialogo.concat(routepath.compila_scheda_paziente);
                pageState = "DP-PDC";
            }

            const redirect = {
                pathname: pathnameRedirect,
                pageState: pageState,
                readOnly: false,
                hideUnsetValues: this.props.hideUnsetValues,
                firstPageName: this.props.firstPageName,
                showOnlyRequiredFields: this.props.showOnlyRequiredFields || this.props.previousPageOnlyRequiredFields
            };

            this.setState({redirectTo: redirect}, () => nascondiSpinner());
        });
    }

    hasUtenteDelega = (richiesta,profilo) => {
        return richiesta?.delega?.some(d => d.valore && d.profilo===profilo)
    }
    stessoUtenteinTeam=(richiesta, profilo) => {
        return richiesta?.team?.some(t => t.profilo === profilo && t.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente)
}

    stessoUtenteinValutazione=(richiesta) => {
        return richiesta?.valutazioni?.some(v => v.valutatore?.codiceFiscale === SessioneUtente.getInstance().idUtente)
    }

    hasProfesionistaProfilo = (richiesta,profilo) => {
        return richiesta?.team?.some(t => t.professionista!=null && t.profilo===profilo)
    }


    indietro = () => {
        let pathname, pageState, readOnly, hideUnsetValues, firstPageName, showOnlyRequiredFields;
        switch (this.props.modalita) {
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_CONFERMATA:
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_FORMALIZZATORE_COORDINATORE:
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_MEDICO_RICHIEDENTE_NON_VALUTATORE: {
                pathname = !this.props.showOnlyRequiredFields || this.state.pageNumber === this.NUMERO_PAGINE.pageTwo ? this.redirectPagine(window.location.pathname) : this.redirectPagine(routepath.home);
                showOnlyRequiredFields = this.props.showOnlyRequiredFields || this.props.previousPageOnlyRequiredFields;
                if (!this.props.showOnlyRequiredFields && this.state.pageNumber > this.props.lastPageNumber) showOnlyRequiredFields = false;
            }
                break;
            case enumRiepilogo.modalitaRiepilogo.FASE_RICHIESTA_STATO_TEAM_VALUTAZIONE: {
                pathname = this.redirectPagine(routepath.valutazione_multidimensionale.concat(routepath.visualizza_valutazione_multidimensionale).concat(routepath.stato_valutazione_componenti_team));
                if (this.state.pageNumber === this.NUMERO_PAGINE.pageOne) {
                    pageState = Utils.getPageStateDimissioneProtetta(this.state.richiesta?.tipologia, this.state.richiesta?.stato, `valutazione-${enumPaginaCompila.STATO_VALUTAZIONE_COMPONENTI_TEAM}`) ?? "V";
                    readOnly = this.props.readOnly;
                    hideUnsetValues = this.props.hideUnsetValues;
                    firstPageName = this.props.firstPageName;
                }
            }
                break;
            case enumRiepilogo.modalitaRiepilogo.FASE_RICHIESTA_MEDICO_RICHIEDENTE: {
                pathname = this.props.readOnly ? window.location.pathname : routepath.inserimento_richiesta.concat(routepath.compila_scheda_paziente);
                pageState = Utils.getPageStateDimissioneProtetta(this.state.richiesta?.tipologia, this.state.richiesta?.stato, this.props.firstPageName) ?? "R";
                readOnly = this.props.previousPageOnlyRequiredFields && !this.props.showOnlyRequiredFields ? false : this.props.readOnly;
                hideUnsetValues = this.props.hideUnsetValues;
                firstPageName = this.props.firstPageName;
                showOnlyRequiredFields = this.props.readOnly && (this.props.showOnlyRequiredFields || this.props.previousPageOnlyRequiredFields);
                if (!this.props.showOnlyRequiredFields && this.state.pageNumber > this.props.lastPageNumber) showOnlyRequiredFields = false;
            }
                break;
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_VALUTATORE:
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_MEDICO_RICHIEDENTE: {
                pathname = this.props.readOnly ? window.location.pathname : routepath.valutazione_multidimensionale.concat(routepath.completa_valutazione_multidimensionale);
                pageState = Utils.getPageStateDimissioneProtetta(this.state.richiesta?.tipologia, this.state.richiesta?.stato, `valutazione-${enumPaginaCompila.COMPILA_PROPOSTE}`) ?? "V";
                readOnly = this.props.previousPageOnlyRequiredFields && !this.props.showOnlyRequiredFields ? false : this.props.readOnly;
                hideUnsetValues = this.props.hideUnsetValues;
                firstPageName = this.props.firstPageName;
                showOnlyRequiredFields = this.props.readOnly && (this.props.showOnlyRequiredFields || this.props.previousPageOnlyRequiredFields);
                if (!this.props.showOnlyRequiredFields && this.state.pageNumber > this.props.lastPageNumber) showOnlyRequiredFields = false;
            }
                break;
            default:
                break;
        }

        if (pathname) {
            this.setState({
                redirectTo: {
                    key: uuid(),
                    pathname,
                    pageState,
                    readOnly,
                    hideUnsetValues,
                    firstPageName,
                    showOnlyRequiredFields
                }
            })
        }
    }

    redirectPagine = (path) => {
        const isReadOnlyPage = window.location.href.includes(routepath.visualizza_valutazione_multidimensionale)
            || window.location.href.includes(routepath.accedi_valutazione_multidimensionale);
        const canSetPath = (this.state.pageNumber === this.NUMERO_PAGINE.pageOne)
            || (this.props.previousPageOnlyRequiredFields && !this.props.showOnlyRequiredFields && isReadOnlyPage);
        let pathname;

        if (canSetPath) pathname = path;
        else if (this.state.pageNumber === this.NUMERO_PAGINE.pageTwo) this.setState({pageNumber: this.NUMERO_PAGINE.pageOne});

        return pathname;
    }

    renderRedirect() {
        const redirectTo = this.state.redirectTo;
        let to = {
            uuidRichiesta: this.state.richiesta.id,
            pathname: redirectTo.pathname,
            pageState: redirectTo.pageState,
            readOnly: redirectTo.readOnly,
            hideUnsetValues: redirectTo.hideUnsetValues,
            firstPageName: redirectTo.firstPageName,
            tipologia: this.props.richiesta?.tipologia,
            stato: this.props.richiesta?.stato,
            valutazionePrecedente: (Utils.isStateValutazione(redirectTo.pageState) || Utils.isStateValutazionePreDialogo(redirectTo.pageState)) ? getValutazionePrecedente(this.state.richiesta) : null,
            showOnlyRequiredFields: this.props.showOnlyRequiredFields || redirectTo.showOnlyRequiredFields,
            previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields,
            lastPageNumber: this.props.lastPageNumber
        };

        if (this.props.modalita === enumRiepilogo.modalitaRiepilogo.FASE_RICHIESTA_STATO_TEAM_VALUTAZIONE)
            to.richiesta = _.cloneDeep(this.state.richiesta);

        return <Redirect to={to}/>;
    }

    visualizzaSubentro = () => {
        let  profilo;
        const {team, stato, delega} = this.props.richiesta;

        const codiceFiscaleProfessionistaCentrale = team?.find(el => el.profilo === enumsUtente.profiliUtente.centraleAdi)?.professionista?.codiceFiscale;

        const thereIsProfiloCentraleInDeleghe = delega?.some(delega => delega.profilo === enumsUtente.profiliUtente.centraleAdi);
        const valore = delega?.find(delega => delega?.profilo === enumsUtente.profiliUtente.centraleAdi)?.valore;

        const statiRichiestaAccettati = (AuthUtils.hasUtenteProfiloCoordinatore()) || (AuthUtils.hasUtenteCentraleAdi() && !valore) ?
            [statiRichiesta.inviata.value, statiRichiesta.inConferimento.value, statiRichiesta.inValutazione.value] :
            ((AuthUtils.hasUtenteRuoloFormalizzatore() && AuthUtils.hasUtenteRuoloMcp())) || (AuthUtils.hasUtenteCentraleAdi() && valore) ?
                [statiRichiesta.inviata.value, statiRichiesta.inConferimento.value, statiRichiesta.inValutazione.value, statiRichiesta.valutata.value, statiRichiesta.confermata.value] : [];

        if(AuthUtils.hasUtenteRuoloFormalizzatore() && AuthUtils.hasUtenteRuoloMcp()) profilo = enumsUtente.profiliUtente.medicoFormalizzatore;
        else if (AuthUtils.hasUtenteProfiloCoordinatore()) profilo = enumsUtente.profiliUtente.coordinatore;
        else if (AuthUtils.hasUtenteCentraleAdi())  profilo = enumsUtente.profiliUtente.centraleAdi;

        //TODO [12/10/2022] gestiore ruolo con cure palleative. 
        const codiceFiscaleProfiloTrovato = team.find(t => t.profilo === profilo)?.professionista?.codiceFiscale;
        let  showSubentra = codiceFiscaleProfiloTrovato && codiceFiscaleProfiloTrovato !== SessioneUtente.getInstance().idUtente;


        if (AuthUtils.hasUtenteRuoloFormalizzatore() && AuthUtils.hasUtenteRuoloMcp()) {
            if(thereIsProfiloCentraleInDeleghe && valore) {
                if(!codiceFiscaleProfessionistaCentrale && !codiceFiscaleProfiloTrovato) showSubentra = false;
                else if(!codiceFiscaleProfiloTrovato && codiceFiscaleProfessionistaCentrale) showSubentra = true;
            } 
        }

        return statiRichiestaAccettati.includes(stato) && showSubentra
            && _.isEqual(this.props.persone?.[this.props.richiesta.idAssistito]?.aulssResidenza, this.props.richiesta.aulss)
            && _.isEqual(this.props.persone?.[this.props.richiesta.idAssistito]?.distrettoResidenza, this.props.richiesta.distretto);
    }

    visualizzaPrendiInCarico = () => {
        const isMcp = AuthUtils.hasUtenteRuoloFormalizzatore() && AuthUtils.hasUtenteRuoloMcp();
        const isCoordinatore = AuthUtils.hasUtenteProfiloCoordinatore();
        const isReferenteDirigenteAS = AuthUtils.hasUtenteProfiloReferenteAssistentiSociali();
        const isUtenteCentraleAdi = AuthUtils.hasUtenteCentraleAdi();
        const utenteAdiCentralePresenteInTeam = this.props.richiesta?.team?.find(el => el?.profilo === enumsUtente.profiliUtente.centraleAdi)?.professionista;


        const isValidProfile = (isLoggedInWithValidProfile, profileToMatch, teamList) => (isLoggedInWithValidProfile &&
            _.find(teamList, t => t.profilo === profileToMatch && !t.professionista?.codiceFiscale)
        );

        let statiRichiestaAccettati = [];
        let statiRichiestaPreDialogoAccettati = [];

        let isFormalizzatoreMcpValido;
        let isCoordinatoreValido;
        let isReferenteDirigenteASValido = false;
        let isStatoRichiestaValido;
        let isUtenteCentraleAdiValido = false;

        if (isMcp || isUtenteCentraleAdi) {
            statiRichiestaAccettati.push(statiRichiesta.inviata.value, statiRichiesta.inConferimento.value, statiRichiesta.inValutazione.value, statiRichiesta.valutata.value);
            statiRichiestaPreDialogoAccettati.push(statiRichiesta.inviataPreDialogo.value,
                statiRichiesta.inConferimentoPreDialogo.value, statiRichiesta.inValutazionePreDialogo.value,
                statiRichiesta.valutataPreDialogo.value, statiRichiesta.confermataPreDialogo.value);
        } else if (isCoordinatore) {
            statiRichiestaAccettati.push(statiRichiesta.inviata.value, statiRichiesta.inConferimento.value, statiRichiesta.inValutazione.value);
            statiRichiestaPreDialogoAccettati.push(statiRichiesta.inviataPreDialogo.value, statiRichiesta.inConferimentoPreDialogo.value, statiRichiesta.inValutazionePreDialogo.value);
        } else if (isReferenteDirigenteAS) {
            statiRichiestaPreDialogoAccettati.push(statiRichiesta.inviataPreDialogo.value, statiRichiesta.inConferimentoPreDialogo.value, statiRichiesta.inValutazionePreDialogo.value);
        }

        if (!Utils.isFasePreDialogo(this.props.richiesta?.tipologia, this.props.richiesta.stato)) {
            isFormalizzatoreMcpValido = isValidProfile(isMcp, enumsUtente.profiliUtente.medicoFormalizzatore, this.props.richiesta.team) && !isUtenteCentraleAdi;
            isCoordinatoreValido = isValidProfile(isCoordinatore, enumsUtente.profiliUtente.coordinatore, this.props.richiesta.team) && !isUtenteCentraleAdi;
            isUtenteCentraleAdiValido = isValidProfile(isUtenteCentraleAdi, enumsUtente.profiliUtente.centraleAdi, this.props.richiesta.team);
            isStatoRichiestaValido = statiRichiestaAccettati.includes(this.props.richiesta.stato);
        } else {
            isFormalizzatoreMcpValido = isValidProfile(isMcp, enumsUtente.profiliUtente.medicoFormalizzatore, this.props.richiesta?.team) && !isUtenteCentraleAdi;
            isCoordinatoreValido = isValidProfile(isCoordinatore, enumsUtente.profiliUtente.coordinatore, this.props.richiesta?.team) && !isUtenteCentraleAdi;
            isReferenteDirigenteASValido = isValidProfile(isReferenteDirigenteAS, enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali, this.props.richiesta?.team);
            isUtenteCentraleAdiValido = isValidProfile(isUtenteCentraleAdi, enumsUtente.profiliUtente.centraleAdi, this.props.richiesta?.team);
            isStatoRichiestaValido = statiRichiestaPreDialogoAccettati.includes(this.props.richiesta.stato);
        }

        return isStatoRichiestaValido
            && ((isFormalizzatoreMcpValido && !utenteAdiCentralePresenteInTeam) || (isCoordinatoreValido && !utenteAdiCentralePresenteInTeam) || isReferenteDirigenteASValido || isUtenteCentraleAdiValido)
            && _.isEqual(this.props.persone?.[this.props.richiesta.idAssistito]?.aulssResidenza, this.props.richiesta.aulss)
            && _.isEqual(this.props.persone?.[this.props.richiesta.idAssistito]?.distrettoResidenza, this.props.richiesta.distretto);
    }

    renderFormPagina1 = () => {
        let formJSX, indicazione, header;
        const pageStateValutazione = Utils.getPageStateDimissioneProtetta(this.state.richiesta.tipologia, this.state.richiesta.stato, enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE) ?? "V";
        const pageStateRichiesta = Utils.getPageStateDimissioneProtetta(this.state.richiesta.tipologia, this.state.richiesta.stato, enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE) ?? "R";

        // Riepilogo (2 di 2): FASE_RICHIESTA_MEDICO_RICHIEDENTE, FASE_VALUTAZIONE_VALUTATORE, FASE_VALUTAZIONE_MEDICO_RICHIEDENTE
        // Visualizza valutazione (1 di 2): FASE_VALUTAZIONE_FORMALIZZATORE_COORDINATORE, FASE_VALUTAZIONE_MEDICO_RICHIEDENTE_NON_VALUTATORE, FASE_VALUTAZIONE_CONFERMATA, FASE_RICHIESTA_STATO_TEAM_VALUTAZIONE
        switch (this.props.modalita) {
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_FORMALIZZATORE_COORDINATORE: {
                header = `${Utils.isFasePreDialogo(this.state.richiesta?.tipologia, this.state.richiesta?.stato) ? 'Accedi a Pre-Dialogo' : 'Verifica Valutazione multidimensionale del team (1 di 2)'}`;
                indicazione = "Valutazione multidimensionale del team";
                formJSX =
                    <RoleBasedContext.Provider
                        value={{
                            forceReadOnly: this.state.anagReadOnly,
                            hideUnsetValues: false,
                            scrollToHead: true,
                            requestVersion: this.props.richiesta?.versione,
                            showOnlyRequiredFields: this.props.showOnlyRequiredFields
                        }}>
                        <CompilaRichiesta
                                richiesta={this.state.richiesta}
                                pageState={pageStateValutazione}
                                pageName={enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE}
                                readOnly={true}
                                richiestaMedico={this.state.richiestaMedico}
                                hideUnsetValues={false}
                                hideDefaultHeader={true}/>
                    </RoleBasedContext.Provider>
            }
                break;
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_MEDICO_RICHIEDENTE_NON_VALUTATORE:
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_CONFERMATA: {
                header = `${Utils.isFasePreDialogo(this.state.richiesta?.tipologia, this.state.richiesta?.stato) ? 'Accedi a Pre-Dialogo' : 'Visualizza Valutazione multidimensionale del team'} (1 di 2)`;
                formJSX =
                    <RoleBasedContext.Provider
                        value={{
                            forceReadOnly: true,
                            hideUnsetValues: false,
                            scrollToHead: true,
                            requestVersion: this.props.richiesta?.versione,
                            showOnlyRequiredFields: this.props.showOnlyRequiredFields
                        }}>
                        <CompilaRichiesta
                                richiesta={this.state.richiesta}
                                pageState={pageStateValutazione}
                                pageName={enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE}
                                readOnly={true}
                                hideUnsetValues={false}
                                richiestaMedico={this.state.richiestaMedico}
                                hideDefaultHeader={true}/>
                    </RoleBasedContext.Provider>
            }
                break;
            case enumRiepilogo.modalitaRiepilogo.FASE_RICHIESTA_STATO_TEAM_VALUTAZIONE: {
                header = `${Utils.isFasePreDialogo(this.state.richiesta?.tipologia, this.state.richiesta?.stato) ? 'Accedi a Pre-Dialogo' : 'Visualizza richiesta di attivazione'} (1 di 2)`;
                formJSX =
                    <RoleBasedContext.Provider
                        value={{
                            forceReadOnly: true,
                            hideUnsetValues: false,
                            scrollToHead: true,
                            requestVersion: this.props.richiesta?.versione,
                            showOnlyRequiredFields: this.props.showOnlyRequiredFields
                        }}>
                        <CompilaRichiesta
                                richiesta={this.state.richiesta}
                                pageState={pageStateRichiesta}
                                pageName={enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE}
                                readOnly={true}
                                hideUnsetValues={false}
                                richiestaMedico={this.state.richiestaMedico}
                                hideDefaultHeader={true}/>
                    </RoleBasedContext.Provider>
            }
                break;
            case enumRiepilogo.modalitaRiepilogo.FASE_RICHIESTA_MEDICO_RICHIEDENTE: {
                header = "Visualizza Riepilogo";
                formJSX = <RoleBasedContext.Provider
                    value={{
                        forceReadOnly: true,
                        hideUnsetValues: true,
                        scrollToHead: true,
                        requestVersion: this.props.richiesta?.versione,
                        showOnlyRequiredFields: this.props.showOnlyRequiredFields
                    }}>
                    <Fragment>
                        <ContainerPaginaCompila
                            richiestaMedico={this.state.richiestaMedico}
                            richiesta={this.state.richiesta}
                            pageState={pageStateRichiesta}
                            pageName={enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE}
                            readOnly={true}
                            hideUnsetValues={true}
                            openAccordion={true}
                            hideDefaultHeader={true}/>
                    </Fragment>
                </RoleBasedContext.Provider>
            }
                break;
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_VALUTATORE:
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_MEDICO_RICHIEDENTE: {
                header = "Visualizza Riepilogo";
                formJSX = <RoleBasedContext.Provider
                    value={{
                        forceReadOnly: true,
                        hideUnsetValues: true,
                        scrollToHead: true,
                        requestVersion: this.props.richiesta?.versione,
                        showOnlyRequiredFields: this.props.showOnlyRequiredFields
                    }}>
                    <Fragment>
                        <ContainerPaginaCompila
                            richiesta={this.state.richiesta}
                            richiestaMedico={this.state.richiestaMedico}
                            pageState={pageStateValutazione}
                            pageName={enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE}
                            readOnly={true}
                            hideUnsetValues={true}
                            openAccordion={true}
                            hideDefaultHeader={true}/>
                    </Fragment>
                </RoleBasedContext.Provider>
            }
                break;
            default: {
                formJSX = <Fragment/>
            }
                break;
        }

        AccordionHelper.accordionPageSubject.next({position: 2, title: `${header} ${indicazione ? '- ' + indicazione : ''}`.trim()});

        return (
            <Fragment>
                {formJSX}
            </Fragment>
        )
    }

    renderFormPagina2 = () => {
        let formJSX, header, indicazione;
        const pageStateValutazione = Utils.getPageStateDimissioneProtetta(this.state.richiesta.tipologia, this.state.richiesta.stato, `valutazione-${enumPaginaCompila.COMPILA_PROPOSTE}`) ?? "V";
        const pageStateRichiesta = Utils.getPageStateDimissioneProtetta(this.state.richiesta.tipologia, this.state.richiesta.stato, enumPaginaCompila.COMPILA_PROPOSTE) ?? "R";

        // Visualizza valutazione (2 di 2): FASE_VALUTAZIONE_FORMALIZZATORE_COORDINATORE, FASE_VALUTAZIONE_MEDICO_RICHIEDENTE_NON_VALUTATORE, FASE_VALUTAZIONE_CONFERMATA, FASE_RICHIESTA_STATO_TEAM_VALUTAZIONE
        switch (this.props.modalita) {
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_FORMALIZZATORE_COORDINATORE: {
                header = `${Utils.isFasePreDialogo(this.state.richiesta?.tipologia, this.state.richiesta?.stato) ? 'Accedi a Pre-Dialogo' : 'Conferma Proposte (2 di 2)'}`;
                indicazione = "Proposte del team";
                formJSX =
                    <RoleBasedContext.Provider
                        value={{
                            forceReadOnly: true,
                            hideUnsetValues: this.props.hideUnsetValues,
                            scrollToHead: true,
                            requestVersion: this.props.richiesta?.versione,
                            showOnlyRequiredFields: this.props.showOnlyRequiredFields
                        }}>
                        <CompilaProposte
                            richiesta={this.state.richiesta}
                            richiestaMedico={this.state.richiestaMedico}
                            pageState={pageStateValutazione}
                            pageName={enumPaginaCompila.COMPILA_PROPOSTE}
                            disabilitaPerFormalizzatore={true}
                            readOnly={true}
                            hideUnsetValues={this.props.hideUnsetValues}
                            hideDefaultHeader={true}/>
                    </RoleBasedContext.Provider>
            }
                break;
            case enumRiepilogo.modalitaRiepilogo.FASE_RICHIESTA_STATO_TEAM_VALUTAZIONE: {
                header = `${Utils.isFasePreDialogo(this.state.richiesta?.tipologia, this.state.richiesta?.stato) ? 'Accedi a Pre-Dialogo: Proposte' : 'Visualizza proposte'} (2 di 2)`;
                formJSX =

                    <RoleBasedContext.Provider
                        value={{
                            forceReadOnly: true,
                            hideUnsetValues: true,
                            scrollToHead: true,
                            requestVersion: this.props.richiesta?.versione,
                            showOnlyRequiredFields: this.props.showOnlyRequiredFields
                        }}>
                        <CompilaProposte
                            richiesta={this.state.richiesta}
                            pageState={pageStateRichiesta}
                            pageName={enumPaginaCompila.COMPILA_PROPOSTE}
                            readOnly={true}
                            richiestaMedico={this.state.richiestaMedico}
                            hideUnsetValues={true}
                            hideDefaultHeader={true}/>
                    </RoleBasedContext.Provider>
            }
                break;
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_MEDICO_RICHIEDENTE_NON_VALUTATORE:
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_CONFERMATA: {
                header = `Completa ${Utils.isFasePreDialogo(this.state.richiesta?.tipologia, this.state.richiesta?.stato) ? ' Pre-Dialogo: ' : ''} Proposte (2 di 2)`;
                formJSX =
                    <RoleBasedContext.Provider
                        value={{
                            forceReadOnly: true,
                            hideUnsetValues: this.props.hideUnsetValues,
                            scrollToHead: true,
                            requestVersion: this.props.richiesta?.versione,
                            showOnlyRequiredFields: this.props.showOnlyRequiredFields
                        }}>
                        <CompilaProposte
                            richiesta={this.state.richiesta}
                            richiestaMedico={this.state.richiestaMedico}
                            pageState={pageStateValutazione}
                            pageName={enumPaginaCompila.COMPILA_PROPOSTE}
                            disabilitaPerFormalizzatore={true}
                            readOnly={true}
                            hideUnsetValues={this.props.hideUnsetValues}
                            hideDefaultHeader={true}/>
                    </RoleBasedContext.Provider>
            }
                break;
            default: {
                formJSX = <Fragment/>
            }
                break;
        }

        AccordionHelper.accordionPageSubject.next({position: 2, title: `${header} ${indicazione ? '- ' + indicazione : ''}`.trim()});

        return (
            <Fragment>
                {formJSX}
            </Fragment>
        )
    }

    renderModaleConferma = () => {
        const body = (
            <>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"offset-1 col-10 font-weight-100 text-center py-2"}>
                            <span>
                                <div> Stai per procedere con l&apos;avvio della formalizzazione </div>
                                <strong> Vuoi confermare l&apos;operazione? </strong>
                            </span>
                        </div>
                    </div>
                </div>
            </>
        )

        const footer = (
            <Fragment>
                <Button
                    id={"confermaBtn"}
                    text={"Conferma"}
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => {
                        if((AuthUtils.hasUtenteRuoloMcp() || AuthUtils.hasUtenteCentraleAdi()) && this.state.richiesta?.classificazione === classificazione.PROGRAMMATA.value) {
                            mostraSpinner();
                            RichiestaADIService.presaInCarico(this.props.richiesta.id).subscribe((response) => {
                                if (response?.msg === "ERRORE") {
                                    if (response?.msgCode && response.msgCode.startsWith("WARNING_WD")) {
                                        Utils.toasterFunction({
                                            status: response?.data?.codice ?? response?.status,
                                            type: "confirm",
                                            text: errors[response?.msgCode]?.message || response?.msgCode
                                        });
                                        //l'operazione è stata comunque eseguita, quindi proseguo fino alla redirect
                                    } else if (response?.status === "403") {
                                        Utils.toasterFunction(messageIt.alertPresaInCaricoGiaEffettuata);
                                        nascondiSpinner();
                                        return;
                                    }
                                }
                                this.setState({
                                    redirectTo: {pathname: routepath.compila_formalizzazione},
                                    apriModaleConferma: false
                                });
                                nascondiSpinner();
                            });
                        } else {
                            this.setState({
                                redirectTo: {pathname: routepath.compila_formalizzazione},
                                apriModaleConferma: false
                            });
                        }
        
                    }}/>
                <Button
                    onClick={() => this.setState({apriModaleConferma: false})}
                    id={"annullaBtn"}
                    dataDismiss={"modal"}
                    text={"Annulla"}
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                />
            </Fragment>
        );

        return (
            <Fragment>
                <ModalHelper
                    id={"modaleConferma"}
                    title={"Attenzione!"}
                    body={body}
                    footer={footer}
                    titleClassName={"modal-header d-inline text-center backgroundOrange"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={"modal-content"}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={"modal-body align-items-center d-flex"}
                />
            </Fragment>
        );
    }

    renderModaleConfermaPresaInCarico = () => {
        const body = (
            <>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"offset-1 col-10 font-weight-100 text-center py-2"}>
                            <span>
                                <p> La richiesta è già stata presa in carico da un altro utente.</p>
                                <strong> Vuoi procedere con la presa in carico? </strong>
                            </span>
                        </div>
                    </div>
                </div>
            </>
        )

        const footer = (
            <Fragment>
                <Button
                    id={"confermaBtn"}
                    text={"Conferma"}
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => {
                        this.setState({
                            apriModaleConfermaPresaInCarico: false
                        }, () => {
                            this.chiamaPrendiInCarico()
                        });
                    }}/>
                <Button
                    onClick={() => this.setState({apriModaleConfermaPresaInCarico: false})}
                    id={"annullaBtn"}
                    dataDismiss={"modal"}
                    text={"Annulla"}
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                />
            </Fragment>
        );

        return (
            <Fragment>
                <ModalHelper
                    id={"modaleConferma"}
                    title={"Attenzione!"}
                    body={body}
                    footer={footer}
                    titleClassName={"modal-header d-inline text-center backgroundOrange"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={"modal-content"}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={"modal-body align-items-center d-flex f-weight-600"}
                />
            </Fragment>
        );
    }

    // TODO CODE DA ELMINARE
    // renderButtons() {
    //     // bottoni
    //     let buttonIndietro =
    //         <div className="col-auto ml-3 mr-auto">
    //             <Button
    //                 onClick={() => {
    //                     this.indietro();
    //                 }}
    //                 id={"indietroBtn"}
    //                 text={"Indietro"}
    //                 className={"btn btn-adi btnWhiteOutlineBorderGreen"}
    //             />
    //         </div>;
    //     let buttonRichiediModificaValutazione = this.richiediModificaConsentito() && <div className="col-auto ml-3 mr-auto">
    //         <Button
    //             onClick={() => this.setState({apriModaleRichiediModificaValutazione: true})}
    //             id={"richiediModificaValutazioneBtn"}
    //             text={"Richiedi una modifica"}
    //             className={"btn btn-adi btnWhiteOutlineBorderGreen"}
    //         />
    //     </div>;
    //     let bottoneAvviaFormalizzazione = this.avviaFormalizzazioneConsentito() && <div
    //         className={"col-auto mr-4 ".concat(!this.state.mostraAvviaFormalizzazione && "invisible")}>
    //         <Button
    //             onClick={() => {
    //                 if (Utils.isDimissioneProtetta(this.props.pageState)) {
    //                     Utils.getDataDimissioneProgrammataDaRicovero(this.state.richiesta.id)
    //                         .subscribe(dataDimissioneProgrammataDaRicovero => {
    //                             if (dataDimissioneProgrammataDaRicovero !==
    //                                 this.state.richiesta?.dataDimissioneProgrammataDaRicovero?.dataDimissioneProgrammataDaRicovero) {
    //                                 this.setState({
    //                                     apriModaleDataDimissioneProgrammataAggiornata: true,
    //                                     dataDimissioneProgrammataDaRicovero: dataDimissioneProgrammataDaRicovero
    //                                 })
    //                             } else {
    //                                 this.setState({apriModaleConferma: true})
    //                             }
    //                         });
    //                 } else {
    //                     this.setState({apriModaleConferma: true});
    //                 }
    //             }}
    //             id={"avviaFormalizzazioneBtn"}
    //             text={"Avvia formalizzazione"}
    //             className={"btn btn-adi btnGreenOutline"}
    //         />
    //     </div>;
    //     let buttonProsegui = <div className="col-auto mr-4">
    //         <Button
    //             onClick={() => {
    //                 this.prosegui();
    //             }}
    //             id={"proseguiVerificaValutazione"}
    //             text={"Prosegui"}
    //             className={"btn btn-adi btnGreenOutline"}
    //             disabled={false}
    //         />
    //     </div>;
    //     let buttonPrendiInCarico = <div className="col-auto">
    //         <Button
    //             onClick={() => {
    //                 this.prendiInCarico();
    //             }}
    //             id={"prendiInCaricoBtn"}
    //             text={"Prendi in carico"}
    //             className={"btn btn-adi btnGreenOutline"}
    //             disabled={false}
    //         />
    //     </div>;

    //     const isUtenteCentraleAdi = _.some(this.state?.richiesta?.team, t => SessioneUtente.getInstance().idUtente === t.professionista?.codiceFiscale && t.profilo === enumsUtente.profiliUtente.centraleAdi);

    //     const showButtonRichiediModificaValutazione = this.state.pageNumber === this.NUMERO_PAGINE.pageOne
    //         && AuthUtils.hasUtenteMcpFormalizzatoreRichiesta(this.state?.richiesta?.team) || isUtenteCentraleAdi
    //         && (_.isEqual(this.state.richiesta.stato, enumRichiesta.stato.CONFERMATA) || (_.isEqual(this.state.richiesta.stato, enumRichiesta.stato.VALUTATA) && !Utils.isStringEmptyOrNullOrUndefined(this.state.richiesta.timestampRiapertura)));
    //     let leftButtonsJSX, rightButtonsJSX, middleButtonJSX;

    //     switch (this.props.modalita) {
    //         case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_FORMALIZZATORE_COORDINATORE: {
    //             leftButtonsJSX = <Fragment>
    //                 {showButtonRichiediModificaValutazione ? buttonRichiediModificaValutazione : buttonIndietro}
    //             </Fragment>;
    //             middleButtonJSX = this.visualizzaPrendiInCarico() && <Fragment>{buttonPrendiInCarico}</Fragment>
    //             if (this.state.pageNumber === this.NUMERO_PAGINE.pageTwo) {
    //                 rightButtonsJSX = <Fragment>
    //                     {this.state.apriModaleDataDimissioneProgrammataAggiornata &&
    //                         <PopupDataDimissioneProgrammataAggiornata
    //                             dataDimissioneProgrammataDaRicovero={this.state.dataDimissioneProgrammataDaRicovero}
    //                             chiudiPopupDataDimissioneProgrammataAggiornata={(isDataConfermata) => {
    //                                 if (isDataConfermata) this.setState({
    //                                     apriModaleDataDimissioneProgrammataAggiornata: false,
    //                                     apriModaleConferma: true
    //                                 })
    //                                 else this.setState({apriModaleDataDimissioneProgrammataAggiornata: false})
    //                             }}
    //                         />
    //                     }
    //                     {bottoneAvviaFormalizzazione}
    //                 </Fragment>
    //             } else {
    //                 rightButtonsJSX = <Fragment>
    //                     {buttonProsegui}
    //                 </Fragment>
    //             }
    //         }
    //             break;
    //         case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_MEDICO_RICHIEDENTE_NON_VALUTATORE:
    //         case enumRiepilogo.modalitaRiepilogo.FASE_RICHIESTA_STATO_TEAM_VALUTAZIONE:
    //         case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_CONFERMATA: {
    //             leftButtonsJSX = <Fragment>
    //                 {showButtonRichiediModificaValutazione ? buttonRichiediModificaValutazione : buttonIndietro}
    //             </Fragment>;
    //             if (this.state.pageNumber === this.NUMERO_PAGINE.pageOne) {
    //                 rightButtonsJSX = <Fragment>
    //                     {buttonProsegui}
    //                 </Fragment>
    //             }
    //         }
    //             break;
    //         case enumRiepilogo.modalitaRiepilogo.FASE_RICHIESTA_MEDICO_RICHIEDENTE: {
    //             leftButtonsJSX = <Fragment>
    //                 {buttonIndietro}
    //             </Fragment>
    //             rightButtonsJSX = <div className={"col-auto"}>
    //                 <Button
    //                     onClick={() => {
    //                         this.setState({apriModaleInoltraRichiesta: true})
    //                     }}
    //                     id={"confermaInoltraRichiestaBtn"}
    //                     text={"Conferma e inoltra richiesta"}
    //                     className={"btn btn-adi btnGreenOutline"}
    //                 />
    //             </div>
    //         }
    //             break;
    //         case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_MEDICO_RICHIEDENTE:
    //         case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_VALUTATORE: {
    //             leftButtonsJSX = <Fragment>
    //                 {buttonIndietro}
    //             </Fragment>
    //             rightButtonsJSX = <div className={"col-auto"}>
    //                 <Button
    //                     onClick={() => {
    //                         if (Utils.isDimissioneProtetta(this.props.pageState)) {
    //                             Utils.getDataDimissioneProgrammataDaRicovero(this.state.richiesta.id)
    //                                 .subscribe(dataDimissioneProgrammataDaRicovero => {
    //                                     if (dataDimissioneProgrammataDaRicovero !==
    //                                         this.state.richiesta?.dataDimissioneProgrammataDaRicovero?.dataDimissioneProgrammataDaRicovero) {
    //                                         this.setState({
    //                                             apriModaleDataDimissioneProgrammataAggiornata: true,
    //                                             dataDimissioneProgrammataDaRicovero: dataDimissioneProgrammataDaRicovero
    //                                         })
    //                                     } else {
    //                                         this.setState({apriModaleConfermaValutazione: true})
    //                                     }
    //                                 });
    //                         } else {
    //                             this.setState({apriModaleConfermaValutazione: true});
    //                         }
    //                     }}
    //                     id={"confermaValutazioneBtn"}
    //                     text={"Conferma valutazione"}
    //                     className={"btn btn-adi btnGreenOutline"}
    //                     disabled={!this.state.confermaValutazioneAbilitato}
    //                 />
    //             </div>
    //         }
    //             break;

    //         default: {
    //             rightButtonsJSX = <Fragment>
    //                 {buttonIndietro}
    //             </Fragment>
    //         }
    //             break;
    //     }

    //     if (leftButtonsJSX && rightButtonsJSX && middleButtonJSX) {
    //         return (
    //             <div className={["row py-3 bottoniLayout justify-content-between", this.props.showOnlyRequiredFields ? styles.customButton : "m-0"].join(' ')}>
    //                 <div className={"col-auto ml-3"}>
    //                     <div className={"row"}>
    //                         {leftButtonsJSX}
    //                     </div>
    //                 </div>
    //                 <div className={"col-auto"}>
    //                     <div className={"row"}>
    //                         {middleButtonJSX}
    //                     </div>
    //                 </div>
    //                 <div className="col-auto mr-3">
    //                     <div className={"row"}>
    //                         {rightButtonsJSX}
    //                     </div>
    //                 </div>
    //             </div>
    //         );
    //     } else if (leftButtonsJSX && rightButtonsJSX) {
    //         return (
    //             <div className={["row py-3 bottoniLayout justify-content-around", this.props.showOnlyRequiredFields ? styles.customButton : "m-0"].join(' ')}>
    //                 <div className={"col-auto ml-3 mr-auto"}>
    //                     <div className={"row"}>
    //                         {leftButtonsJSX}
    //                     </div>
    //                 </div>
    //                 <div className="col-auto mr-4">
    //                     <div className={"row"}>
    //                         {rightButtonsJSX}
    //                     </div>
    //                 </div>
    //             </div>
    //         );
    //     } else {
    //         if (leftButtonsJSX) {
    //             return (
    //                 <div className={["row py-3 bottoniLayout", this.props.showOnlyRequiredFields ? styles.customButton : "m-0"].join(' ')}>
    //                     <div className={"col-auto ml-3 mr-auto"}>
    //                         {leftButtonsJSX}
    //                     </div>
    //                 </div>
    //             );
    //         } else {
    //             return (
    //                 <div className={["row py-3  bottoniLayout justify-content-end", this.props.showOnlyRequiredFields ? styles.customButton : "m-0"].join(' ')}>
    //                     <div className={"col-auto mr-4"}>
    //                         {rightButtonsJSX}
    //                     </div>
    //                 </div>
    //             );
    //         }
    //     }
    // }

    //TODO questa funzione deve essere diventata un nuovo componente
    renderButtons() {
        const isUtenteCentraleAdi = _.some(this.state?.richiesta?.team, t => SessioneUtente.getInstance().idUtente === t.professionista?.codiceFiscale && t.profilo === enumsUtente.profiliUtente.centraleAdi);

        const showButtonRichiediModificaValutazione = this.state.pageNumber === this.NUMERO_PAGINE.pageOne
            && (AuthUtils.hasUtenteMcpFormalizzatoreRichiesta(this.state?.richiesta?.team) || isUtenteCentraleAdi)
            && (_.isEqual(this.state.richiesta.stato, enumRichiesta.stato.CONFERMATA) || (_.isEqual(this.state.richiesta.stato, enumRichiesta.stato.VALUTATA) && !Utils.isStringEmptyOrNullOrUndefined(this.state.richiesta.timestampRiapertura)));

        let btnList = [];

        const indietroBtn = {
            id: "indietroBtn",
            text: "Indietro",
            className: "btnWhiteOutlineBorderGreen",
            onclickFunction: () => {this.indietro()},
            isvisible: !showButtonRichiediModificaValutazione,
            aling:"start"
        }

        const subentroBtn = {
            id: "subentroBtn",
            text: "Subentro",
            className: "btnWhiteOutlineBorderGreen",
            onclickFunction: () => this.setState({apriModaleSubentra: true}),
            isvisible: (!this.visualizzaPrendiInCarico() && this.visualizzaSubentro()),
            aling: this.state.pageNumber === this.NUMERO_PAGINE.pageTwo ? "start" : "center"
        }

        const richiediModificaValutazioneBtn = {
            id: "richiediModificaValutazioneBtn",
            text: "Richiedi una modifica",
            className: "btnWhiteOutlineBorderGreen",
            onclickFunction: () => this.setState({apriModaleRichiediModificaValutazione: true}),
            isvisible: this.richiediModificaConsentito() && showButtonRichiediModificaValutazione,
            aling:"start"
        }

        const prendiInCaricoBtn = {
            id: "prendiInCaricoBtn",
            text: "Prendi in carico",
            className: "btnGreenOutline",
            disabled: false,
            onclickFunction: () => {this.prendiInCarico()},
            isvisible: this.visualizzaPrendiInCarico(),
            aling: this.state.pageNumber === this.NUMERO_PAGINE.pageTwo ? "start" : "center"
        }

        const avviaFormalizzazioneBtn = {
            id: "avviaFormalizzazioneBtn",
            text: "Avvia formalizzazione",
            className: "btnGreenOutline",
            onclickFunction: () => {
                if (Utils.isDimissioneProtetta(this.props.pageState)) {
                    Utils.getDataDimissioneProgrammataDaRicovero(this.state.richiesta.id)
                        .subscribe(dataDimissioneProgrammataDaRicovero => {
                            if (dataDimissioneProgrammataDaRicovero !==
                                this.state.richiesta?.dataDimissioneProgrammataDaRicovero?.dataDimissioneProgrammataDaRicovero) {
                                this.setState({
                                    apriModaleDataDimissioneProgrammataAggiornata: true,
                                    dataDimissioneProgrammataDaRicovero: dataDimissioneProgrammataDaRicovero,
                                    isFormalizzazione: true,
                                    isValutazione: false
                                })
                            } else {
                                this.setState({apriModaleConferma: true})
                            }
                        });
                } else {
                    this.setState({apriModaleConferma: true});
                }
            },
            isvisible: this.avviaFormalizzazioneConsentito() && this.state.pageNumber === this.NUMERO_PAGINE.pageTwo && this.state.mostraAvviaFormalizzazione,
            aling:"right"
        }

        const proseguiVerificaValutazione = {
            id: "proseguiVerificaValutazione",
            text: "Prosegui",
            className: "btnGreenOutline",
            disabled: false,
            isvisible: this.state.pageNumber !== this.NUMERO_PAGINE.pageTwo,
            onclickFunction: () => {this.prosegui()},
            aling:"right"
        }

        const confermaInoltraRichiestaBtn = {
            id: "confermaInoltraRichiestaBtn",
            text: "Conferma e inoltra richiesta",
            className: "btn btn-adi btnGreenOutline",
            onclickFunction: () => {this.setState({apriModaleInoltraRichiesta: true})},
            isvisible: true,
            aling:"right"
        }

        const confermaValutazioneBtn = {
            id:"confermaValutazioneBtn",
            text:"Conferma valutazione",
            className:"btn btn-adi btnGreenOutline",
            disabled:!this.state.confermaValutazioneAbilitato,
            isvisible: true,
            onclickFunction:() => {
                if (Utils.isDimissioneProtetta(this.props.pageState)) {
                    Utils.getDataDimissioneProgrammataDaRicovero(this.state.richiesta.id)
                        .subscribe(dataDimissioneProgrammataDaRicovero => {
                            if (dataDimissioneProgrammataDaRicovero !==
                                this.state.richiesta?.dataDimissioneProgrammataDaRicovero?.dataDimissioneProgrammataDaRicovero) {
                                this.setState({
                                    apriModaleDataDimissioneProgrammataAggiornata: true,
                                    dataDimissioneProgrammataDaRicovero: dataDimissioneProgrammataDaRicovero,
                                    isFormalizzazione: false,
                                    isValutazione: true
                                })
                            } else {
                                this.setState({apriModaleConfermaValutazione: true})
                            }
                        });
                } else {
                    this.setState({apriModaleConfermaValutazione: true});
                }
            },
            aling:"right"
        }

        switch (this.props.modalita) {
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_FORMALIZZATORE_COORDINATORE: {
                showButtonRichiediModificaValutazione ? btnList.push(richiediModificaValutazioneBtn) : btnList.push(indietroBtn);
                btnList = [...btnList, ...[subentroBtn, prendiInCaricoBtn, avviaFormalizzazioneBtn, proseguiVerificaValutazione]]
            }
                break;
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_MEDICO_RICHIEDENTE_NON_VALUTATORE:
            case enumRiepilogo.modalitaRiepilogo.FASE_RICHIESTA_STATO_TEAM_VALUTAZIONE:
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_CONFERMATA: {
                showButtonRichiediModificaValutazione ? btnList.push(richiediModificaValutazioneBtn) : btnList.push(indietroBtn);
                btnList =[...btnList, ...[proseguiVerificaValutazione]]
            }
                break;
            case enumRiepilogo.modalitaRiepilogo.FASE_RICHIESTA_MEDICO_RICHIEDENTE: {
                btnList=[indietroBtn, confermaInoltraRichiestaBtn]
            }
                break;
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_MEDICO_RICHIEDENTE:
            case enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_VALUTATORE: {
                btnList=[indietroBtn, confermaValutazioneBtn]
            }
                break;

            default: {
                btnList.push(indietroBtn);
            }
                break;
        }
        return (
            <ButtonsBoxStick
                parentClass={"row"}
                btnList={btnList}
            />
        )

    }

    avviaFormalizzazioneConsentito()
    {
        return    (AuthUtils.hasUtenteCentraleAdi()&& this.props.richiesta?.classificazione!==classificazione.PROGRAMMATA.value && this.hasUtenteDelega(this.props.richiesta,enumsUtente.profiliUtente.centraleAdi) && this.stessoUtenteinTeam(this.props.richiesta,enumsUtente.profiliUtente.centraleAdi))
                || (AuthUtils.hasUtenteRuoloMcp() && !this.hasProfesionistaProfilo(this.props.richiesta,enumsUtente.profiliUtente.medicoFormalizzatore) && !this.hasProfesionistaProfilo(this.props.richiesta,enumsUtente.profiliUtente.centraleAdi))
            || (AuthUtils.hasUtenteRuoloMcp() && this.hasProfesionistaProfilo(this.props.richiesta,enumsUtente.profiliUtente.medicoFormalizzatore) && this.stessoUtenteinTeam(this.props.richiesta,enumsUtente.profiliUtente.medicoFormalizzatore)
                || (AuthUtils.hasUtenteRuoloFisiatra() && [classificazione.RIABILITATIVA.value].includes(this.props.richiesta?.classificazione) && this.stessoUtenteinValutazione(this.props.richiesta) && this.hasUtenteDelega(this.props.richiesta,enumsUtente.profiliUtente.professionistaValutatore)))

    }

    richiediModificaConsentito()
    {
        return    (AuthUtils.hasUtenteCentraleAdi()&& this.props.richiesta?.classificazione!==classificazione.PROGRAMMATA.value && this.hasUtenteDelega(this.props.richiesta,enumsUtente.profiliUtente.centraleAdi) && this.stessoUtenteinTeam(this.props.richiesta,enumsUtente.profiliUtente.centraleAdi))
            || (AuthUtils.hasUtenteRuoloMcp() && !this.hasProfesionistaProfilo(this.props.richiesta,enumsUtente.profiliUtente.medicoFormalizzatore) && !this.hasProfesionistaProfilo(this.props.richiesta,enumsUtente.profiliUtente.centraleAdi))
            || (AuthUtils.hasUtenteRuoloMcp() && this.hasProfesionistaProfilo(this.props.richiesta,enumsUtente.profiliUtente.medicoFormalizzatore) && this.stessoUtenteinTeam(this.props.richiesta,enumsUtente.profiliUtente.medicoFormalizzatore) )

    }

    showPopupAutoAssegnazioneValutazione = () => {
        const isTipologiaSegnalazione = _.isEqual(this.state.richiesta?.tipologia, enumRichiesta.tipologia.SEGNALAZIONE);
        let isStatoRichiestaValido;
        let isConnectedUserNotBetweenEvaluators;

        const mmgIsPresentToFigurePai = !!(this.state.richiesta?.figureProfessionaliPresaInCarico?.find(professionista => professionista?.figuraProfessionale === "01"));
        const fasePredialogo = Utils.isFasePreDialogo(this.state.richiesta?.tipologia, this.state.richiesta.stato);

        if (AuthUtils.hasRuoloMedicoMedicinaGenerale() && SessioneUtente.getInstance().idUtente === this.state.richiesta.mmg.codiceFiscale) {
            if (isTipologiaSegnalazione) {
                if (fasePredialogo) {
                    isStatoRichiestaValido = true;
                } else {
                    if (mmgIsPresentToFigurePai) isStatoRichiestaValido = false
                    else if (!mmgIsPresentToFigurePai) isStatoRichiestaValido = true
                }
            }
            isConnectedUserNotBetweenEvaluators = this.state.richiesta?.valutazioni?.flatMap(vp => vp.valutatore)?.find(vp => vp.codiceFiscale === SessioneUtente.getInstance().idUtente) == null;
        } else if (AuthUtils.hasRuoloMedicoOspedaliero()) {
            isStatoRichiestaValido = _.isEqual(this.state.richiesta.stato, enumRichiesta.stato.IN_VALUTAZIONE_PRE_DIALOGO);
            isConnectedUserNotBetweenEvaluators = this.state.richiesta?.valutazioni?.flatMap(vp => vp.valutatore)?.find(vp => vp.codiceFiscale === SessioneUtente.getInstance().idUtente) == null;
        } else if (AuthUtils.hasUtenteRuoloMcp) {
            if (isTipologiaSegnalazione) {
                if (!_.isEqual(this.state.richiesta.stato, enumRichiesta.stato.IN_VALUTAZIONE)) {
                    isStatoRichiestaValido = false;
                } else if (!fasePredialogo) {
                    if (mmgIsPresentToFigurePai) isStatoRichiestaValido = false
                    else isStatoRichiestaValido = true
                }
            }
            isConnectedUserNotBetweenEvaluators = this.state.richiesta?.valutazioni?.flatMap(vp => vp.valutatore)?.find(vp => vp.codiceFiscale === SessioneUtente.getInstance().idUtente) == null;
        } else {
            isStatoRichiestaValido = false;
            isConnectedUserNotBetweenEvaluators = false;
        }

        return isTipologiaSegnalazione && isStatoRichiestaValido && isConnectedUserNotBetweenEvaluators;
    }

    chiudiSubentro = () => {
        let richiesta = _.cloneDeep(this.props.richiesta);

        if (AuthUtils.hasUtenteProfiloCoordinatore())
            richiesta.coordinatore = {codiceFiscale: SessioneUtente.getInstance().idUtente};
        else if (AuthUtils.hasUtenteRuoloFormalizzatore() && AuthUtils.hasUtenteRuoloMcp())
            richiesta.mcp = {codiceFiscale: SessioneUtente.getInstance().idUtente};

        // una volta preso in carico la richiesta sia il coordinatore, che l'mcp vanno sul path verifica-valutazione
        let pathnameRedirect = routepath.verifica_valutazione;
        let pageState = Utils.getPageStateDimissioneProtetta(this.props.richiesta?.tipologia, this.props.richiesta?.stato, enumPaginaCompila.VERIFICA_VALUTAZIONE) ?? "V";
        if (AuthUtils.hasUtenteRuoloMcp() && Utils.isSegnalazioneConfermataPreDialogoOrInChiusuraPreDialogo(richiesta)) {
            pathnameRedirect = routepath.compila_chiusura_predialogo.concat(routepath.compila_scheda_paziente);
            pageState = "DP-PDC";
        }

        const redirect = {
            pathname: pathnameRedirect,
            pageState: pageState,
            readOnly: false,
            hideUnsetValues: this.props.hideUnsetValues,
            firstPageName: this.props.firstPageName,
            showOnlyRequiredFields: this.props.showOnlyRequiredFields || this.props.previousPageOnlyRequiredFields
        };

        this.setState({redirectTo: redirect, apriModaleSubentra: false}, () => nascondiSpinner());
    }

    render() {
        if (this.state.redirectTo) return this.renderRedirect();

        if (this.state.richiesta == null) return <Fragment/>;

        return (
            <Fragment>
                {this.state.apriModaleInoltraRichiesta &&
                    <PopupConfermaInoltraRichiesta
                        uuidRichiesta={this.state.richiesta.id}
                        showPopup={this.state.apriModaleInoltraRichiesta}
                        chiudiPopupInoltraRichiesta={() => {
                            this.setState({apriModaleInoltraRichiesta: false})
                        }}
                    />}


                {this.state.apriModaleDataDimissioneProgrammataAggiornata &&
                    <PopupDataDimissioneProgrammataAggiornata
                        dataDimissioneProgrammataDaRicovero={this.state.dataDimissioneProgrammataDaRicovero}
                        chiudiPopupDataDimissioneProgrammataAggiornata={(isDataConfermata) => {
                            if (isDataConfermata) this.setState({
                                apriModaleDataDimissioneProgrammataAggiornata: false,
                                apriModaleConfermaValutazione: this.state.isValutazione,
                                apriModaleConferma: this.state.isFormalizzazione
                            })
                            else this.setState({apriModaleDataDimissioneProgrammataAggiornata: false})
                        }}
                    />
                }
                
                {this.state.apriModaleConfermaValutazione &&
                    <PopupConfermaValutazione
                        uuidRichiesta={this.state.richiesta.id}
                        timestampRiapertura={this.state.richiesta?.timestampRiapertura}
                        variazioniPresenti={this.state.richiestaAggiornata?.valutazioni?.find(f => f?.valutatore?.codiceFiscale === SessioneUtente.getInstance().idUtente)?.variazioniPresenti}
                        showPopup={this.state.apriModaleConfermaValutazione}
                        chiudiPopupConfermaValutazione={() => {
                            this.setState({apriModaleConfermaValutazione: false})
                        }}
                        disabilitaConfermaValutazione={this.disabilitaConfermaValutazione}
                        cfRichiedenteRichiesta={this.state.richiesta?.richiedente?.codiceFiscale}
                        sostituzioneRichiedenteRichiesta={this.state.richiesta?.sostituzioneRichiedente}
                        cfProfessonistaAdiForRichiesta={this.state.richiesta?.team?.find(t => t?.profilo === 'ADI-CENTRALE' || t?.profilo === 'ADI-FOR').professionista?.codiceFiscale}
                        cfUtente={SessioneUtente.getInstance().idUtente}
                    />}
                {this.state.apriModaleRichiediModificaValutazione &&
                    <PopupRichiestaDiModifica
                        uuidRichiesta={this.state.richiesta.id}
                        tipologia={this.state.richiesta.tipologia}
                        stato={this.state.richiesta.stato}
                        sostituzioneRichiedente={this.state.richiesta.sostituzioneRichiedente}
                        chiudiPopupRichiestaDiModifica={() => this.setState({apriModaleRichiediModificaValutazione: false})}
                    />}
                {this.state.apriModaleSubentra &&
                    <SubentroRichiesta chiudiSubentroRichiesta={() => this.chiudiSubentro()}
                       uuidRichiesta={this.state.richiesta.id}
                        tipo={'Subentra'}/>
                }

                {this.showPopupAutoAssegnazioneValutazione() && this.state.apriModaleAutoAssegnazioneValutazione &&
                    <PopupAutoAssegnazioneValutazione
                        uuidRichiesta={this.state.richiesta.id}
                        livelloPriorita={this.state.richiesta.livelloPriorita}  // TODO prendere campo livelloPrioritaPreDialogo
                        timestampCompletamento={this.state.richiesta.timestampCompletamento}
                        timestampCompletamentoPreDialogo={this.state.richiesta.timestampCompletamentoPreDialogo}
                        statoRichiesta={this.state.richiesta.stato}
                        valutazioni={this.state.richiesta?.valutazioni ?? []}
                        mmg={this.state.richiesta.mmg}
                        chiudiPopupAutoAssegnazioneValutazione={() => this.setState({apriModaleAutoAssegnazioneValutazione: false})}
                    />}
                {this.state.apriModaleConferma && this.renderModaleConferma()}
                {this.state.apriModaleConfermaPresaInCarico && this.renderModaleConfermaPresaInCarico()}
                {this.state.pageNumber === this.NUMERO_PAGINE.pageOne ? this.renderFormPagina1() : this.renderFormPagina2()}
                {this.renderButtons()}
            </Fragment>
        );
    }
}

FormRiepilogo.propTypes = propTypes;
AccordionAvvioRichiesta.defaultProps = defaultProps;
