import React, {Component} from "react";



class Button extends Component {
    state = {
        className: this.props.className
    }

    render() {

        return (
            <button type="button" id={this.props.id} className={this.state.className} onClick={this.props.onClick}
                    title={this.props.title ? this.props.title : ""}
                    style={this.props.style} disabled={this.props.disabled}>
                {this.props.text}{this.props.icon ? this.props.icon : ''}
            </button>
        )
    }
}

export default Button;
