import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {
    FieldsRow,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../../utils/dataset/OggettiUtili.json";
import Utils from "../../../../utils/Utils";
import {v4 as uuid} from "uuid";
import {isSectionReadOnly} from "../DispositiviMediciPresenti";

const propTypes = {
    dati: PropTypes.object,
    onChange: PropTypes.func,
    pageState: PropTypes.string,
    idDispositivo: PropTypes.string,
    fieldInAccordionReadOnly: PropTypes.bool
}

export const defaultTipoDrenaggio = {
    id: null,
    tipoDrenaggio: null,
    altro: null,
    diametroSonda: null,
    sistemaDiFissaggio: null,
    saccaDiRaccolta: null,
    presenzaDiValvola: null,
    quantitaDescrizioneSecrezioniDrenate: null,
    caratteristicheOrganolettiche: null,
    lavaggi: null,
    quantitaLiquidi: null,
    tipologia: null,
    valutazioneEsitoLavaggio: null,
    esitoLavaggio: null
}

export default class Drenaggi extends Component {

    state = {
        tipi: []
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati)) this.inizializzaForm();
    }

    inizializzaForm = () => {
        let tipi = _.cloneDeep(this.props.dati);
        if (tipi == null) tipi = [];
        this.setState({tipi});
    }

    handleAggiornaForm = (field, elem, index) => {
        let tipi = _.cloneDeep(this.state.tipi);
        let currentTipo = tipi[index];

        if (field === "lavaggi" && !_.isEqual(currentTipo.lavaggi, "02")) {
            currentTipo.quantitaLiquidi = null;
            currentTipo.tipologia = null;
            currentTipo.valutazioneEsitoLavaggio = null;
            currentTipo.esitoLavaggio = null;
        }

        currentTipo[field] = new RegExp(/^[-]\d+$/g).test(elem) ? "0" : elem;
        tipi[index] = currentTipo;
        this.setState({tipi}, () => this.props.onChange("drenaggi", this.state.tipi));
    }

    addNewDrenaggio = () => {
        let tipi = _.cloneDeep(this.state.tipi);
        tipi.push({..._.cloneDeep(defaultTipoDrenaggio), id: uuid()});
        this.setState({tipi});
    }

    removeDrenaggio = (index) => {
        let tipi = _.cloneDeep(this.state.tipi);
        tipi.splice(index, 1);
        this.setState({tipi}, () => this.props.onChange("drenaggi", this.state.tipi));
    }

    renderNewDrenaggio = (element, index) => {
        return <>
            <FieldsRow colNumber={4}>
                <RoleBasedSelect
                    id={"selectTipoDrenaggio"}
                    fieldId={'TIPO_DRENAGGI.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"tipoDrenaggio"}
                    fieldLabel={"Tipo"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.tipoDrenaggio}
                    value={element.tipoDrenaggio}
                    onChange={(elem) => this.handleAggiornaForm("tipoDrenaggio", elem, index)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                {element.tipoDrenaggio === "07" &&
                <RoleBasedInput
                    id={"inputAltroSonda"}
                    fieldId={'TIPO_DRENAGGI.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Altro tipo sonda"}
                    field={"altro"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci testo libero per tipo sonda"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    value={element.altro}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />}
                <RoleBasedInput
                    id={"inputDiametroSonda"}
                    fieldId={'DIM_SONDA_DIAM_DREN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Dimensioni sonda (diametro)"}
                    field={"diametroSonda"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci diametro sonda"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    value={element.diametroSonda}
                    type={"number"}
                    min={"0"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectSistemaDiFissaggio"}
                    fieldId={'SIST_FISSAGGIO_DREN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"sistemaDiFissaggio"}
                    fieldLabel={"Sistema di fissaggio"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.sistemaDiFissaggio}
                    value={element.sistemaDiFissaggio}
                    onChange={(elem) => this.handleAggiornaForm("sistemaDiFissaggio", elem, index)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
            <FieldsRow>
                <RoleBasedSelect
                    id={"selectSaccaDiRaccolta"}
                    fieldId={'SACCA_RACCOLTA_DREN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"saccaDiRaccolta"}
                    fieldLabel={"Sacca di raccolta"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.rispostaBreve}
                    value={element.saccaDiRaccolta}
                    onChange={(elem) => this.handleAggiornaForm("saccaDiRaccolta", elem, index)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectPresenzaDiValvola"}
                    fieldId={'PRES_VALV_DREN.DISPOSITIVIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"presenzaDiValvola"}
                    fieldLabel={"Presenza di valvola"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.rispostaBreve}
                    value={element.presenzaDiValvola}
                    onChange={(elem) => this.handleAggiornaForm("presenzaDiValvola", elem, index)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedInput
                    id={"inputQuantitaDescrizioneSecrezioniDrenate"}
                    fieldId={'SECR_DREN_QUANT_DREN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Descrizione delle secrezioni drenate - Quantità"}
                    field={"quantitaDescrizioneSecrezioniDrenate"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci quantità descrizione secrezioni drenate"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    value={element.quantitaDescrizioneSecrezioniDrenate}
                    type={"number"}
                    min={"0"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
            <FieldsRow>
                <RoleBasedInput
                    id={"inputCaratteristicheOrganolettiche"}
                    fieldId={'SECR_DREN_CARATT_ORGAN_DREN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Descrizione delle secrezioni drenate - Caratteristiche organolettiche"}
                    field={"caratteristicheOrganolettiche"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci caratteristiche organolettiche"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    value={element.caratteristicheOrganolettiche}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectLavaggi"}
                    fieldId={'LAVAGGI_DREN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"lavaggi"}
                    fieldLabel={"Lavaggi"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.lavaggi}
                    value={element.lavaggi}
                    onChange={(elem) => this.handleAggiornaForm("lavaggi", elem, index)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                {element.lavaggi === "02" &&
                <RoleBasedInput
                    id={"inputQuantitaDiLiquidi"}
                    fieldId={'QUANT_LIQ_DREN.DISPOSITIVIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Quantità di liquidi"}
                    field={"quantitaLiquidi"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci quantità di liquidi"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    value={element.quantitaLiquidi}
                    type={"number"}
                    min={"0"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />}
            </FieldsRow>
            {element.lavaggi === "02" &&
            <FieldsRow>
                <RoleBasedInput
                    id={"inputTipologia"}
                    fieldId={'TIPO_LIQ_DREN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Tipologia"}
                    field={"tipologia"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci tipologia di liquido"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    value={element.tipologia}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectValutazioneEsitoLavaggio"}
                    fieldId={'VAL_ESITO_LAV_DREN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"valutazioneEsitoLavaggio"}
                    fieldLabel={"Valutazione esito lavaggio"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.valutazioneEsitoLavaggio}
                    value={element.valutazioneEsitoLavaggio}
                    onChange={(elem) => this.handleAggiornaForm("valutazioneEsitoLavaggio", elem, index)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedInput
                    id={"inputEsitoLavaggio"}
                    fieldId={'ESITO_LAV_DREN.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Esito lavaggio"}
                    field={"esitoLavaggio"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci esito lavaggio"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    value={element.esitoLavaggio}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>}
        </>;
    }

    render() {
        return <SectionList
            addNewSectionCallback={this.addNewDrenaggio}
            removeSectionCallback={this.removeDrenaggio}
            data={_.cloneDeep(this.state.tipi)}
            title={'Tipo drenaggio'}
            renderSection={this.renderNewDrenaggio}
            pageState={this.props.pageState}
            field={"tipi"}
            addButtonVisibilityHandler={this.state.tipi?.every(e => Object.keys(e).some(key => key !== "id" && !Utils.isObjectNull(e[key])))}
            indentOffset={0}
            forceReadOnly={isSectionReadOnly(this.props.pageState)}
            fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
            parentJsonPath={"informazioniDiBase.dispositiviMediciPresenti[?(@.id == '"+ this.props.idDispositivo +"')].drenaggi"}
        />
    }
}

Drenaggi.propTypes = propTypes;
