import React, {Component, Fragment} from "react";
import {Button, Input} from "web-client-archetype";
import Utils from "../../../utils/Utils";
import * as _ from "lodash";
import GestionePasswordADIService from "../../../service/GestionePasswordADIService";
import PropTypes from "prop-types";
import {Redirect} from 'react-router-dom';
import styleGestionePassword from "./GestionePassword.module.css";
import routePath from "../../../utils/route/route-path";
import {mostraSpinner, nascondiSpinner} from "../../../App";
import ModalGestionePassword from "./ModalGestionePassword";
import {
    CONDIZIONE_PASSWORD_CARATTERE_SPECIALE,
    CONDIZIONE_PASSWORD_LENGTH,
    CONDIZIONE_PASSWORD_MAIUSCOLA
} from "./LockPassword";

const propTypes = {
    location: PropTypes.object
}

export default class CreaPassword extends Component {

    state = {
        password: null,
        confPassword: null,
        pulsanteConfermaAbilitato: false,
        apriModaleConferma: false,
        redirectTo: null,
    }

    constructor(props) {
        super(props);
    }


    creaPassword = () => {
        mostraSpinner();
        let msgErrore = "ERRORE";
        let request = {
            password: this.state.password,
            confPassword: this.state.confPassword
        }
        GestionePasswordADIService.creaPassword(request).subscribe((res) => {
            if (res?.msg === msgErrore) {
                nascondiSpinner();
                this.apriToasterErrore(res);
            } else {
                this.setState({apriModaleConferma: true})
                nascondiSpinner();
            }
        })
    }

    apriToasterErrore = (response) => {
        Utils.toasterFunction({
            status: response?.data?.codice ? response?.data?.codice : response?.status,
            type: "critical",
            text: response?.data?.errors?.pop().message || response?.data?.message
        })
    }

    handleAggiornaState = (field, event) => {
        this.setState({[field]: event.target.value}, this.aggiornaBottoni);
    }

    handleRedirectModale = () => {
        this.setState({redirectTo:{path: routePath.home}})
    }

    render() {
        if(this.state.redirectTo){
            return <Redirect to={{pathname: this.state.redirectTo.path}}/>
        }
        return (
            <>
                <div className={styleGestionePassword.foregroundMode.concat(" position-relative pt-4")}>
                    <div className="pl-4 pr-4 mr-3">
                        <div className={"row"}>
                            <h1 className={"pb-2"}>Crea Password</h1>
                        </div>
                        <div className="row text-align-center mb-3">
                            {this.renderInputPasswords()}
                            {this.renderBottoni()}
                        </div>
                        {this.renderTestoInformativo()}
                    </div>
                </div>
                {this.state.apriModaleConferma ?
                    this.renderModaleConferma() : <Fragment/>}
            </>
        )
    }

    renderInputPasswords = () => {
        return <Fragment>
            <div className="col">
                <Input
                    parentClass="mb-3 p-0"
                    placeholder="Imposta Password"
                    field="password"
                    onChange={this.handleAggiornaState}
                    id="password"
                    value={this.state.password}
                    type="password"
                    onValidate={(value) => value && !Utils.isPasswordValid(value)}
                    required
                    label={"Imposta Password"}
                />
            </div>
            <div className="col">
                <Input
                    parentClass="mb-3 p-0"
                    placeholder="Conferma Password"
                    field="confPassword"
                    onChange={this.handleAggiornaState}
                    id="confPassword"
                    value={this.state.confPassword}
                    type="password"
                    onValidate={(value) => value && !Utils.arePasswordMatch(this.state.password, value) && "*Le password non coincidono"}
                    required
                    label={"Conferma Password"}
                />
            </div>
        </Fragment>;
    }

    renderBottoni = () => {
        return <div className="col-auto mt-4">
            <Button
                id={"conferma"}
                text="Conferma"
                className={"btn btn-adi btnGreenOutline float-right"}
                style={{ outline: "none" }}
                disabled={!this.state.pulsanteConfermaAbilitato}
                onClick={() => this.creaPassword()}
            />
        </div>;
    }

    renderTestoInformativo = () => {
        return <div className={"row pt-5 pb-2"}>
            <div className={"col"}>
                {!Utils.isValidStringMinLength(this.state.password,9)&& CONDIZIONE_PASSWORD_LENGTH}
                {!Utils.isValidAlphanumericMaiuscola(this.state.password) && CONDIZIONE_PASSWORD_MAIUSCOLA}
                {!Utils.isValidCaratteriSpeciali(this.state.password) && CONDIZIONE_PASSWORD_CARATTERE_SPECIALE}
            </div>
        </div>;
    }

    renderModaleConferma = () => {
        return <ModalGestionePassword
            body={"La password è stata impostata correttamente"}
            handleOnClickModale={this.handleRedirectModale}
            btnText={"OK"}
            renderFooter={true}
            title={"Operazione Eseguita!"}
        />;
    }
}

CreaPassword.propTypes = propTypes;
