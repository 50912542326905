import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    dati: PropTypes.any,
    onChangeEsameObiettivo: PropTypes.func,
    pageState: PropTypes.any,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultAlterazioneOrecchioDx = {
    id: null,
    alterazioneOrecchioDx: null
}

const defaultAlterazioneOrecchioSx = {
    id: null,
    alterazioneOrecchioSx: null
}

export default class Orecchie extends AccordionSecondLevel {

    state = {
        dati: _.cloneDeep(this.props.dati)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.dati);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({dati}) => {
            if (index === null && subField === null) {
                dati[field] = elem;
            } else {
                dati[field][index][subField] = elem;
            }
            return {dati};
        }, this.validazioneDati)
    }

    validazioneDati = () => {
        const dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("orecchie", dati);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneOrecchie", campiObbligatoriRimanenti);
    }

    /* Alterazioni Dx */

    addNewAlterazioneOrecchioDx = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultAlterazioneOrecchioDx);
        type.id = uuid();
        dati.listaAlterazioneOrecchioDx.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeAlterazioneOrecchioDx = (indice) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.listaAlterazioneOrecchioDx.splice(indice, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderAlterazioneOrecchioDx = (alterazioneOrecchioDx, index) => {
        return <FieldsRow colNumber={-1}>
            <RoleBasedICD9Input
                fieldId={'PRES_ALT_DX.ORECCHIE.ESAMEOBIETTIVO'}
                pageState={this.props.pageState}
                field={'alterazioneOrecchioDx'}
                onChange={(field, elem) => this.handleAggiornaForm("listaAlterazioneOrecchioDx", elem.target.value, index, field)}
                value={alterazioneOrecchioDx.alterazioneOrecchioDx}
                fieldLabel={"Presenza di alterazione orecchio dx"}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                disableDoubleInput={this.props.disableDoubleInput}
                hideUnsetValues={this.props.hideUnsetValues}
            />
        </FieldsRow>;
    }

    /* Alterazioni Sx */

    addNewAlterazioneOrecchioSx = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultAlterazioneOrecchioSx);
        type.id = uuid();
        dati.listaAlterazioneOrecchioSx.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeAlterazioneOrecchioSx = (indice) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.listaAlterazioneOrecchioSx.splice(indice, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderAlterazioneOrecchioSx = (alterazioneOrecchioSx, index) => {
        return <FieldsRow colNumber={-1}>
            <RoleBasedICD9Input
                fieldId={'PRES_ALT_SX.ORECCHIE.ESAMEOBIETTIVO'}
                pageState={this.props.pageState}
                field={'alterazioneOrecchioSx'}
                onChange={(field, elem) => this.handleAggiornaForm("listaAlterazioneOrecchioSx", elem.target.value, index, field)}
                value={alterazioneOrecchioSx.alterazioneOrecchioSx}
                fieldLabel={"Presenza di alterazione orecchio sx"}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                disableDoubleInput={this.props.disableDoubleInput}
                hideUnsetValues={this.props.hideUnsetValues}
            />
        </FieldsRow>;
    }

    handleDispositiviArrayChange = (array, field) => {
        let data = _.cloneDeep(this.state.dati);
        data[field] = array;
        this.setState({dati: data}, () => {
            this.props.onChangeEsameObiettivo("orecchie", data);
            this.forceUpdate();
        });
    }

    aggiornaDispositivi = (field, elem, indice, elemOrecchio = null) => {
        let dati = _.cloneDeep(this.state.dati);
        dati[field][indice] = {
            codice: elem?.codice,
            orecchio: elemOrecchio,
            id: elem?.id
        }
        this.setState({dati}, () => this.validazioneDati());
    }

    getSezioneSede = (el, i) => {
        return <RoleBasedSelect
            fieldId={'ORECCHIO_DISP.ORECCHIE.ESAMEOBIETTIVO'}
            pageState={this.props.pageState}
            id={"orecchio"}
            className={"orecchio" + i}
            options={OggettiUtili.sede}
            value={el.orecchio}
            onChange={(elem) => this.aggiornaDispositivi("dispositiviPresenti", el, i, elem)}
            isSearchable={false}
            fieldLabel={"Orecchio"}
            forceReadOnly={this.props.forceReadOnly}
            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            disableDoubleInput={this.props.disableDoubleInput}
            hideUnsetValues={this.props.hideUnsetValues}
            handleOnlyValue={true}
            field={"orecchio"}
        />;
    }

    renderBodySezione() {
        const dati = this.state.dati;
        return (
            <>
                <FieldsRow>
                    <SectionList
                        title={'Presenza di alterazione orecchio dx'}
                        data={dati.listaAlterazioneOrecchioDx}
                        renderSection={this.renderAlterazioneOrecchioDx}
                        addNewSectionCallback={this.addNewAlterazioneOrecchioDx}
                        removeSectionCallback={this.removeAlterazioneOrecchioDx}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'PRES_ALT_DX.ORECCHIE.ESAMEOBIETTIVO'}
                        field={'listaAlterazioneOrecchioDx'}
                        parentJsonPath={"esameObiettivo.orecchie.listaAlterazioneOrecchioDx"}
                        addButtonVisibilityHandler={dati.listaAlterazioneOrecchioDx?.every(a => !!a.alterazioneOrecchioDx)}
                    />
                </FieldsRow>
                <FieldsRow>
                    <SectionList
                        title={'Presenza di alterazione orecchio sx'}
                        data={dati.listaAlterazioneOrecchioSx}
                        renderSection={this.renderAlterazioneOrecchioSx}
                        addNewSectionCallback={this.addNewAlterazioneOrecchioSx}
                        removeSectionCallback={this.removeAlterazioneOrecchioSx}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'PRES_ALT_SX.ORECCHIE.ESAMEOBIETTIVO'}
                        field={'listaAlterazioneOrecchioSx'}
                        parentJsonPath={"esameObiettivo.orecchie.listaAlterazioneOrecchioSx"}
                        addButtonVisibilityHandler={dati.listaAlterazioneOrecchioSx?.every(a => !!a.alterazioneOrecchioSx)}
                    />
                </FieldsRow>
                <DispositiviPresenti
                    handleArrayChange={this.handleDispositiviArrayChange}
                    dispositiviPresenti={dati.dispositiviPresenti}
                    pageState={this.props.pageState}
                    fieldId={"DISPOSITIVI.ORECCHIE.ESAMEOBIETTIVO"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    otherElements={this.getSezioneSede}
                    parentJsonPath={"esameObiettivo.orecchie.dispositiviPresenti"}
                />
                <CondizioneRilevataSection
                    ambito={'ORECCHIE'}
                    parentFieldId={'ORECCHIE.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={dati.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={dati.daAttenzionare}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                    showSuggerimenti={true}
                />
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"orecchie"}
                    title={"Orecchie"}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.orecchie.listaAlterazioneOrecchioDx",
                            "esameObiettivo.occhi.listaAlterazioneOrecchioSx",
                            "esameObiettivo.occhi.dispositiviPresenti"],
                        [defaultAlterazioneOrecchioDx, defaultAlterazioneOrecchioSx, defaultDispositiviPresenti]
                    )}
                    accordionReadOnly={this.props.accordionReadOnly}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}
Orecchie.propTypes = propTypes;

Orecchie.defaultProps = {
    showAccordion: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}
