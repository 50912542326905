import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button} from "web-client-archetype";
import ModalHelper from "../../../modale/ModalHelper";
import * as $ from "jquery";
import {Link} from "react-router-dom";

const propTypes = {
    link: PropTypes.object.isRequired,
    text: PropTypes.string,
    clickAvviaNuovaRichiesta: PropTypes.func,
    chiudiPopupAvvisoCategoriaAur: PropTypes.func,
    showPopup: PropTypes.bool,
}

const titleModale = "Attenzione";

export default class PopupAvvisoCategoriaAur extends Component {

    closeModaleStyle = () => {
        $(".modal-backdrop").css("display", "none");
        $(".modal-open").removeClass("modal-open");
        $("body").attr("style", "padding-right: 0 !important");
    }

    conferma = () => {
        this.closeModaleStyle();
        this.props.chiudiPopupAvvisoCategoriaAur();
    }

    annullaAvviso = () => {
        this.closeModaleStyle();
        this.props.chiudiPopupAvvisoCategoriaAur();
    }

    bodyModal = () => {
        return (<div>{this.props.text}</div>);
    }

    footerModal = () => {
        return (
            <>
                <div onClick={this.props.clickAvviaNuovaRichiesta}>
                    <Link to={this.props.link}>
                        <Button
                            onClick={() => this.conferma()}
                            id={"confermaBtn"}
                            text="Ho preso atto"
                            className={"btn btn-adi btnOrangeOutline"}
                        />
                    </Link>
                </div>
                <Button
                    onClick={() => this.annullaAvviso()}
                    id={"annullaBtn"}
                    text="Annulla"
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    disabled={false}
                />
            </>);
    }

    render() {
        return (
            <ModalHelper
                id={"modalPopupAvvisoCategoriaAur"}
                title={titleModale}
                titleClassName="modal-header d-inline text-center backgroundOrange"
                containerClassName="modal-dialog modal-dialog-scrollable modal modal-dialog-centered"
                contentClassName="modal-content"
                body={this.bodyModal()}
                footer={this.footerModal()}
                footerClassName={"modal-footer justify-content-around"}
                bodyClassName={"modal-body d-flex align-items-center justify-content-around f-weight-600"}
            />
        )
    }

}

PopupAvvisoCategoriaAur.propTypes = propTypes;
