import Utils from "../../utils/Utils";
import oggettiUtili from "../../utils/dataset/OggettiUtili"
import {DropdownIndicator, inputSelectStyles} from "../common/select/SelectUtils";
import * as _ from "lodash";
import {isFieldIdWriteable} from "../roleBasedComponents/RoleBasedComponents";

import {SessioneUtente} from "web-client-archetype";
import {isArray} from "lodash";
// todo modificare codici categorie
// const CODICI_CATEGORIA_NEOPLASIA = ["2038", "2852", "2029", "3383", "377", "V1241", "V5841"];
// const CODICI_CATEGORIA_DISPLASIA = ["5278", "5698", "6023", "610", "6221", "6230", "7485", "7531", "7565", "7543"];
const CODICI_CATEGORIA_ANEMIA = ["280", "281", "283", "284", "285", "6482", "7735", "7740", "7765", "9641", "E9341", "V182", "V781"];
const CODICI_CATEGORIA_PATOLOGIA_PEPTICA = ["571"];
const CODICI_CATEGORIA_INSUFFICIENZA_RENALE = ["274", "403"];

const CATEGORIA = {
    NEOPLASIA: "neoplasia",
    DISPLASIA: "displasia",
    ANEMIA: "anemia",
    PATOLOGIA_PEPTICA: "patologiaPeptica",
    INSUFFICIENZA_RENALE: "insufficienzaRenale",
}

const VISIBILITA = true;

const condizionePerCategoria = (categoria, codice) => {

    if (codice) {
        switch (categoria) {
            case CATEGORIA.NEOPLASIA:
                return VISIBILITA;
            case CATEGORIA.DISPLASIA:
                return VISIBILITA;
            case CATEGORIA.ANEMIA:
                return _.filter(CODICI_CATEGORIA_ANEMIA, (COD) => _.startsWith(codice, COD)).length > 0;
            case CATEGORIA.PATOLOGIA_PEPTICA:
                return _.filter(CODICI_CATEGORIA_PATOLOGIA_PEPTICA, (COD) => _.startsWith(codice, COD)).length > 0;
            case CATEGORIA.INSUFFICIENZA_RENALE:
                return _.filter(CODICI_CATEGORIA_INSUFFICIENZA_RENALE, (COD) => _.startsWith(codice, COD)).length > 0;
            default:
                return false;
        }
    } else {
        return false;
    }
};

const rigaDiagnosiPatologiaPregressaDefault = (fieldsId, that, index, datiVariabile) => {
    return {
        diagnosiPatologiaPregressa: {
            tipo: "autocomplete",
            label: "Diagnosi patologia pregressa",
            fieldId: fieldsId.diagnosiPatologiaPregressa,
            id: "autocompleteCardiopatia",
            keyField: "codice",
            descriptionField: "descrizione",
            onChange: (field, elem) => {
                that.handleAggiornaForm(field, elem, index);
            },
            searchFromStart: false,
            value: datiVariabile?.diagnosiPatologiaPregressa ? datiVariabile?.diagnosiPatologiaPregressa : "",
            field: "diagnosiPatologiaPregressa",
            placeholder: "Inserisci patologia",
            noSuggestions: "Errore: Malattia non presente",
            colSize: 3
        },
        visible: true
    };
}

const rigaDataPatologiaAttivaDefault = (fieldsId, datiVariabile, that, index, visible, field) => {
    return {
        data: {
            colSize: 3,
            label: "Data",
            tipo: "data",
            fieldId: fieldsId.data,
            parentClass: "mb-3",
            maxlength: "4",
            placeholder: "Specificare anno",
            field: "data",
            value: datiVariabile?.data,
            onChange: (field, elem) => {
                that.handleAggiornaForm(field, elem.target.value, index);
            },
            id: `data${field.charAt(0).toUpperCase()}${field.slice(1)}`
        },
        patologiaAttiva: {
            label: "Patologia Attiva",
            colSize: "3",
            tipo: "select",
            fieldId: fieldsId.patologiaAttiva,
            field: "patologiaAttiva",
            id: "selectPatologiaAttiva" + field + index,
            classNamePrefix: "inputSelect",
            components: DropdownIndicator,
            onChange: (field, value) => {
                that.handleAggiornaForm(field, value, index);
            },
            options: oggettiUtili.rispostaBreve,
            value: datiVariabile?.patologiaAttiva,
            isSearchable: false,
            styles: inputSelectStyles,
            placeholder: "Seleziona",
            noOptionsMessage: () => {
                return "Errore: Patologia attiva non esistente"
            }
        },
        visible: visible
    };
}

const rigaProposteAttivitaSuggerimenti = (fieldsId, datiVariabile, that, index, visible) => {
    return {
        propostaAttivitaSuggerimenti: {
            tipo: "propostaAttivitaSuggerimenti",
            fieldId: fieldsId.propostaAttivitaSuggerimenti,
            id: "propostaAttivitaSuggerimenti",
            value: datiVariabile?.propostaAttivitaSuggerimenti,
            onChange: (field, elem) => {
                that.handleAggiornaForm(field, elem, index)
            },
            field: "propostaAttivitaSuggerimenti",
            checkboxIdSuffix: '_' + index,
            indexItem: index
        },
        visible: visible
    }
}

const rigaCondizioneRilevataDaAttenzionareNoteDefault = (fieldsId, datiVariabile, that, index, visible, arrayDati) => {
    let riga3 = {
        condizioneRilevata: {
            colSize: 3,
            label: "La condizione rilevata",
            tipo: "select",
            fieldId: fieldsId.condizioneRilevata,
            id: "selectCondizioneRilevata",
            classNamePrefix: "inputSelect",
            components: DropdownIndicator,
            options: oggettiUtili.condizioneRilevata,
            value: datiVariabile?.condizioneRilevata,
            onChange: (field, elem) => {
                that.handleAggiornaForm(field, elem, index)
            },
            field: "condizioneRilevata",
            isSearchable: false,
            styles: inputSelectStyles,
            placeholder: "Seleziona",
            noOptionsMessage: () => {
                return "Errore: Condizione rilevata non esistente"
            },
            readOnly: !!(isArray(datiVariabile?.propostaAttivitaSuggerimenti) && datiVariabile?.propostaAttivitaSuggerimenti?.filter(p => p?.codiceFiscale && p?.codiceFiscale != SessioneUtente.getInstance().idUtente || p?.propostaAttivitaSuggerimenti?.codiceFiscale && p?.propostaAttivitaSuggerimenti?.codiceFiscale != SessioneUtente.getInstance().idUtente)?.length)
        }
    }

    if (datiVariabile?.condizioneRilevata === oggettiUtili.condizioneRilevata[1].value) {
        riga3.daAttenzionare = {
            label: "Da attenzionare",
            colSize: "3",
            tipo: "select",
            fieldId: fieldsId.daAttenzionare,
            id: "selectDaAttenzionare",
            classNamePrefix: "inputSelect",
            components: DropdownIndicator,
            options: oggettiUtili.daConsiderareAiFiniAssistenziali,
            value: datiVariabile?.daAttenzionare,
            onChange: (field, elem) => {
                that.handleAggiornaForm(field, elem, index)
            },
            field: "daAttenzionare",
            isSearchable: false,
            styles: inputSelectStyles,
            placeholder: "Seleziona",
            noOptionsMessage: () => {
                return "Errore: Da Atttenzionare non esistente"
            },
        }
    }

    riga3.note = {
        label: "Note",
        colSize: "3",
        tipo: "input",
        fieldId: fieldsId.note,
        parentClass: "mb-0",
        value: datiVariabile?.note,
        placeholder: "Inserisci note",
        field: "note",
        onChange: (field, elem) => {
            that.handleAggiornaForm(field, elem.target.value, index);
        },
        id: "note"
    };

    riga3.visible = visible;
    // TODO [20/04/20200] - CODE DA ELIMINARE
    // if (arrayDati.length > 1) {
    //     riga3.eliminaDiagnosi = {
    //         colSize: "3",
    //         tipo: "icon",
    //         onClick: () => {
    //             that.rimuoviDiagnosiPatologia(index)
    //         },
    //         id: "it-delete"
    //     }
    // }
    return riga3
}
// TODO [20/04/20200] - CODE DA ELIMINARE
// const rigaBottoneNuovaDiagnosiPatologiaDefault = (index, ultimoIndex, datiVariabile, that, datiVariabili, visible, subsectionName) => {
//     const isAtLeastOneDataValued = datiVariabili.some(v => Object.keys(v).filter(key => key !== "visible").some(key => v[key].value != null && v[key].value !== ''));

//     const dateElement = document.getElementById(`data${subsectionName.charAt(0).toUpperCase()}${subsectionName.slice(1)}`);
//     const isValidDate = that.validazioneData(dateElement?.value?.toString());

//     if (index === ultimoIndex) {
//         let riga5 = {
//             aggiungiDiagnosiPatologia: {
//                 tipo: "button",
//                 colSize: "3",
//                 onClick: () => {
//                     that.nuovaDiagnosiPatologia()
//                 },
//                 text: "Aggiungi patologia",
//                 disabled: !isAtLeastOneDataValued || !isValidDate
//             },
//             visible: visible
//         }
//         datiVariabili.push(riga5)
//     }
// }

const rigaNeoplasia = (fieldsId, index, that, datiVariabile, visibleNeoplasia) => {
    return {
        neoplasiaTipologia: {
            tipo: "select",
            colSize: 3,
            label: "Neoplasia - Tipologia",
            fieldId: fieldsId.neoplasiaTipologia,
            field: "neoplasiaTipologia",
            id: "selectNeoplasiaTipologia" + index,
            options: oggettiUtili.neoplasiaTipologia,
            onChange: (field, value) => {
                that.handleAggiornaForm(field, value, index);
            },
            value: datiVariabile?.neoplasiaTipologia,
            classNamePrefix: "inputSelect",
            components: DropdownIndicator,
            isSearchable: false,
            styles: inputSelectStyles,
            placeholder: "Seleziona",
            noOptionsMessage: () => {
                return "Errore: Neoplasia tipologia non esistente"
            },
        },
        neoplasiaIntervento: {
            tipo: "select",
            colSize: 3,
            label: "Neoplasia - Intervento",
            fieldId: fieldsId.neoplasiaIntervento,
            id: "selectNeoplasiaIntervento",
            classNamePrefix: "inputSelect",
            components: DropdownIndicator,
            options: oggettiUtili.neoplasiaIntervento,
            value: datiVariabile?.neoplasiaIntervento,
            onChange: (field, elem) => {
                that.handleAggiornaForm(field, elem, index)
            },
            field: "neoplasiaIntervento",
            isSearchable: false,
            styles: inputSelectStyles,
            placeholder: "Seleziona",
            noOptionsMessage: () => {
                return "Errore: Neoplasia intervento non esistente"
            },
        },
        visible: visibleNeoplasia
    };
}

const rigaPeptica = (fieldsId, index, that, datiVariabile, visiblePeptica, field) => {
    return {
        sePatologiaPeptica: {
            tipo: "select",
            colSize: 3,
            label: "Se Patologia peptica",
            fieldId: fieldsId.sePatologiaPeptica,
            field: "sePatologiaPeptica",
            classNamePrefix: "inputSelect",
            components: DropdownIndicator,
            id: "radioButtonSePatologiaPeptica" + field + index,
            options: oggettiUtili[field + "SePatologiaPeptica"],
            value: datiVariabile?.sePatologiaPeptica,
            onChange: (field, value) => {
                that.handleAggiornaForm(field, value, index);
            },
            isSearchable: false,
            styles: inputSelectStyles,
            placeholder: "Seleziona",
            noOptionsMessage: () => {
                return "Errore: Se patologia peptica non esistente"
            },
        },
        visible: visiblePeptica
    };
}

const rigaInsufficienzaRenale = (fieldsId, index, that, datiVariabile, visibleInsRenale, field) => {
    return {
        insufficienzaRenaleTipologia: {
            tipo: "select",
            colSize: 3,
            label: "Insufficienza renale - Tipologia",
            fieldId: fieldsId.insufficienzaRenaleTipologia,
            field: "insufficienzaRenaleTipologia",
            id: "selectInsufficienzaRenaleTipologia" + field + index,
            options: oggettiUtili.insufficienzaRenaleTipologia,
            classNamePrefix: "inputSelect",
            components: DropdownIndicator,
            onChange: (field, value) => {
                that.handleAggiornaForm(field, value, index);
            },
            value: datiVariabile?.insufficienzaRenaleTipologia,
            isSearchable: false,
            styles: inputSelectStyles,
            placeholder: "Seleziona",
            noOptionsMessage: () => {
                return "Errore: Insufficienza renale tipologia non esistente"
            },
        },
        insufficienzaRenaleStadio: {
            tipo: "select",
            colSize: 3,
            label: "Insufficienza renale - Stadio",
            fieldId: fieldsId.insufficienzaRenaleStadio,
            field: "insufficienzaRenaleStadio",
            id: "selectInsufficienzaRenaleStadio" + field + index,
            options: oggettiUtili.insufficienzaRenaleStadio,
            value: datiVariabile?.insufficienzaRenaleStadio,
            classNamePrefix: "inputSelect",
            components: DropdownIndicator,
            onChange: (field, value) => {
                that.handleAggiornaForm(field, value, index);
            },
            isSearchable: false,
            styles: inputSelectStyles,
            placeholder: "Seleziona",
            noOptionsMessage: () => {
                return "Errore: Insufficienza renale stadio non esistente"
            },
        },
        visible: visibleInsRenale
    };
}

const rigaDisplasia = (fieldsId, index, that, datiVariabile, visibleDisplasia, field) => {
    return {
        displasiaSpecificareIntervento: {
            label: "Displasia, specificare intervento",
            colSize: "3",
            tipo: "input",
            fieldId: fieldsId.displasiaSpecificareIntervento,
            parentClass: "mb-0",
            value: datiVariabile?.displasiaSpecificareIntervento,
            placeholder: "Inserisci intervento",
            field: "displasiaSpecificareIntervento",
            onChange: (field, elem) => {
                that.handleAggiornaForm(field, elem.target.value, index);
            },
            id: "displasiaSpecificareIntervento"
        },
        visible: visibleDisplasia
    };
}
const rigaAnemiaTipologia = (fieldsId, datiVariabile, that, index, visible, field) => {
    return {
        anemiaTipologia: {
            tipo: "select",
            colSize: 3,
            label: "Anemia - Tipologia",
            fieldId: fieldsId.anemiaTipologia,
            field: "anemiaTipologia",
            id: "selectAnemiaTipologia" + field + index,
            onChange: (field, value) => {
                that.handleAggiornaForm(field, value, index);
            },
            classNamePrefix: "inputSelect",
            components: DropdownIndicator,
            options: oggettiUtili.anemiaTipologia,
            value: datiVariabile?.anemiaTipologia,
            isSearchable: false,
            styles: inputSelectStyles,
            placeholder: "Seleziona",
            noOptionsMessage: () => {
                return "Errore: Anemia tipologia non esistente"
            },
        }, visible: visible
    };
}

const rigaAnemia = (fieldsId, index, that, datiVariabile, visibleInsRenale, field) => {
    return {
        trasfusioni: {
            tipo: "select",
            colSize: 3,
            label: "Trasfusioni",
            fieldId: fieldsId.trasfusioni,
            field: "trasfusioni",
            id: "selectTrasfusioni" + field + index,
            onChange: (field, value) => {
                that.handleAggiornaForm(field, value, index);
            },
            classNamePrefix: "inputSelect",
            components: DropdownIndicator,
            options: oggettiUtili.trasfusioni,
            value: datiVariabile?.trasfusioni,
            isSearchable: false,
            styles: inputSelectStyles,
            placeholder: "Seleziona",
            noOptionsMessage: () => {
                return "Errore: Trasfusioni non esistente"
            },
        },
        visible: visibleInsRenale
    };
}

const rigaPsichiatria = (fieldsId, index, that, datiVariabile, visible, field) => {
    return {
        storiaFamiliare: {
            label: "Storia familiare",
            colSize: "3",
            tipo: "input",
            fieldId: fieldsId.storiaFamiliare,
            parentClass: "mb-0",
            value: datiVariabile?.storiaFamiliare,
            placeholder: "Inserisci storia familiare",
            field: "storiaFamiliare",
            onChange: (field, elem) => {
                that.handleAggiornaForm(field, elem.target.value, index);
            },
            id: "storiaFamiliare"
        },
        storiaPersonale: {
            label: "Storia personale",
            colSize: "3",
            tipo: "input",
            fieldId: fieldsId.storiaPersonale,
            parentClass: "mb-0",
            value: datiVariabile?.storiaPersonale,
            placeholder: "Inserisci storia personale",
            field: "storiaPersonale",
            onChange: (field, elem) => {
                that.handleAggiornaForm(field, elem.target.value, index);
            },
            id: "storiaPersonale"
        },
        visible: visible
    };
}

function preparaCloneDati(datiVariabile) {
    let cloneDati = _.cloneDeep(datiVariabile);
    delete cloneDati.diagnosiPatologiaPregressa;
    if (datiVariabile?.condizioneRilevata !== oggettiUtili.condizioneRilevata[1].value) {
        delete cloneDati.daAttenzionare;
    }
    return cloneDati;
}

const formDefault = (that, datiVariabile, index, arrayDati, ultimoIndex, fieldsId, field, pageState) => {
    let datiVariabili = [];
    let visible = !Utils.isObjectNull(datiVariabile?.diagnosiPatologiaPregressa);
    let visibleButton = visible && isFieldIdWriteable(fieldsId.diagnosiPatologiaPregressa, pageState);
    let visibleProposteAttivitaSuggerimenti = datiVariabile?.condizioneRilevata === "02";

    let riga1 = rigaDiagnosiPatologiaPregressaDefault(fieldsId, that, index, datiVariabile);
    datiVariabili.push(riga1);

    let riga2 = rigaDataPatologiaAttivaDefault(fieldsId, datiVariabile, that, index, visible, field);
    datiVariabili.push(riga2);

    let riga3 = rigaCondizioneRilevataDaAttenzionareNoteDefault(fieldsId, datiVariabile, that, index, visible, arrayDati);
    datiVariabili.push(riga3);

    let riga5 = rigaProposteAttivitaSuggerimenti(fieldsId, datiVariabile, that, index, visibleProposteAttivitaSuggerimenti);
    datiVariabili.push(riga5);
    // TODO [20/04/20200] - CODE DA ELIMINARE
    // rigaBottoneNuovaDiagnosiPatologiaDefault(index, ultimoIndex, datiVariabile, that, datiVariabili, visibleButton, field);

    return datiVariabili;
};

const formNeoplasia = (that, datiVariabile, index, arrayDati, ultimoIndex, fieldsId, field, pageState) => {
    let datiVariabili = [];
    let visible = !Utils.isObjectNull(datiVariabile?.diagnosiPatologiaPregressa);
    let visibleButton = visible && isFieldIdWriteable(fieldsId.diagnosiPatologiaPregressa, pageState);
    let visibleProposteAttivitaSuggerimenti = datiVariabile?.condizioneRilevata === "02";
    let visibleNeoplasia = condizionePerCategoria(CATEGORIA.NEOPLASIA, datiVariabile?.diagnosiPatologiaPregressa);
    let cloneDati = preparaCloneDati(datiVariabile);
    if (!visibleNeoplasia) {
        delete cloneDati.neoplasiaTipologia;
        delete cloneDati.neoplasiaIntervento;
    }

    let riga1 = rigaDiagnosiPatologiaPregressaDefault(fieldsId, that, index, datiVariabile);
    datiVariabili.push(riga1);

    let riga2 = rigaNeoplasia(fieldsId, index, that, datiVariabile, visibleNeoplasia);
    datiVariabili.push(riga2);

    let riga3 = rigaDataPatologiaAttivaDefault(fieldsId, datiVariabile, that, index, visible, field);
    datiVariabili.push(riga3);

    let riga4 = rigaCondizioneRilevataDaAttenzionareNoteDefault(fieldsId, datiVariabile, that, index, visible, arrayDati);
    datiVariabili.push(riga4);

    let riga5 = rigaProposteAttivitaSuggerimenti(fieldsId, datiVariabile, that, index, visibleProposteAttivitaSuggerimenti);
    datiVariabili.push(riga5);
    // TODO [20/04/20200] - CODE DA ELIMINARE
    // rigaBottoneNuovaDiagnosiPatologiaDefault(index, ultimoIndex, datiVariabile, that, datiVariabili, visibleButton, field);

    return datiVariabili;
};

const formNeoplasiaPeptica = (that, datiVariabile, index, arrayDati, ultimoIndex, fieldsId, field, pageState) => {
    let datiVariabili = [];
    let visible = !Utils.isObjectNull(datiVariabile?.diagnosiPatologiaPregressa);
    let visibleButton = visible && isFieldIdWriteable(fieldsId.diagnosiPatologiaPregressa, pageState);
    let visibleProposteAttivitaSuggerimenti = datiVariabile?.condizioneRilevata === "02";
    let visibleNeoplasia = condizionePerCategoria(CATEGORIA.NEOPLASIA, datiVariabile?.diagnosiPatologiaPregressa);
    let visiblePeptica = condizionePerCategoria(CATEGORIA.PATOLOGIA_PEPTICA, datiVariabile?.diagnosiPatologiaPregressa);

    let cloneDati = preparaCloneDati(datiVariabile);
    if (!visibleNeoplasia) {
        delete cloneDati.neoplasiaTipologia;
        delete cloneDati.neoplasiaIntervento;
    }
    if (!visiblePeptica) {
        delete cloneDati.sePatologiaPeptica;
    }

    let riga1 = rigaDiagnosiPatologiaPregressaDefault(fieldsId, that, index, datiVariabile);
    datiVariabili.push(riga1);

    let riga2 = rigaPeptica(fieldsId, index, that, datiVariabile, visibleNeoplasia, field);
    datiVariabili.push(riga2);

    let riga3 = rigaNeoplasia(fieldsId, index, that, datiVariabile, visibleNeoplasia);
    datiVariabili.push(riga3);

    let riga4 = rigaDataPatologiaAttivaDefault(fieldsId, datiVariabile, that, index, visible, field);
    datiVariabili.push(riga4);

    let riga5 = rigaCondizioneRilevataDaAttenzionareNoteDefault(fieldsId, datiVariabile, that, index, visible, arrayDati);
    datiVariabili.push(riga5);

    let riga6 = rigaProposteAttivitaSuggerimenti(fieldsId, datiVariabile, that, index, visibleProposteAttivitaSuggerimenti);
    datiVariabili.push(riga6);
    // TODO [20/04/20200] - CODE DA ELIMINARE
    // rigaBottoneNuovaDiagnosiPatologiaDefault(index, ultimoIndex, datiVariabile, that, datiVariabili, visibleButton, field);

    return datiVariabili;
};

const formNeoplasiaInsufficienzaRenale = (that, datiVariabile, index, arrayDati, ultimoIndex, fieldsId, field, pageState) => {
    let datiVariabili = [];
    let visible = !Utils.isObjectNull(datiVariabile?.diagnosiPatologiaPregressa);
    let visibleButton = visible && isFieldIdWriteable(fieldsId.diagnosiPatologiaPregressa, pageState);
    let visibleNeoplasia = condizionePerCategoria(CATEGORIA.NEOPLASIA, datiVariabile?.diagnosiPatologiaPregressa);
    let visibleProposteAttivitaSuggerimenti = datiVariabile?.condizioneRilevata === "02";
    let visibleInsufficienzaRenale = condizionePerCategoria(CATEGORIA.NEOPLASIA, datiVariabile?.diagnosiPatologiaPregressa);

    let cloneDati = preparaCloneDati(datiVariabile);
    if (!visibleNeoplasia) {
        delete cloneDati.neoplasiaTipologia;
        delete cloneDati.neoplasiaIntervento;
    }
    if (!visibleInsufficienzaRenale) {
        delete cloneDati.insufficienzaRenaleTipologia;
        delete cloneDati.insufficienzaRenaleStadio;
    }

    let riga1 = rigaDiagnosiPatologiaPregressaDefault(fieldsId, that, index, datiVariabile);
    datiVariabili.push(riga1);

    let riga2 = rigaInsufficienzaRenale(fieldsId, index, that, datiVariabile, visibleInsufficienzaRenale, field);
    datiVariabili.push(riga2);

    let riga3 = rigaNeoplasia(fieldsId, index, that, datiVariabile, visibleNeoplasia);
    datiVariabili.push(riga3);

    let riga4 = rigaDataPatologiaAttivaDefault(fieldsId, datiVariabile, that, index, visible, field);
    datiVariabili.push(riga4);

    let riga5 = rigaCondizioneRilevataDaAttenzionareNoteDefault(fieldsId, datiVariabile, that, index, visible, arrayDati);
    datiVariabili.push(riga5);

    let riga6 = rigaProposteAttivitaSuggerimenti(fieldsId, datiVariabile, that, index, visibleProposteAttivitaSuggerimenti);
    datiVariabili.push(riga6);
    // TODO [20/04/20200] - CODE DA ELIMINARE
    // rigaBottoneNuovaDiagnosiPatologiaDefault(index, ultimoIndex, datiVariabile, that, datiVariabili, visibleButton, field);

    return datiVariabili;
};

const formNeoplasiaDisplasia = (that, datiVariabile, index, arrayDati, ultimoIndex, fieldsId, field, pageState) => {
    let datiVariabili = [];
    let visible = !Utils.isObjectNull(datiVariabile?.diagnosiPatologiaPregressa);
    let visibleButton = visible && isFieldIdWriteable(fieldsId.diagnosiPatologiaPregressa, pageState);
    let visibleProposteAttivitaSuggerimenti = datiVariabile?.condizioneRilevata === "02";
    let visibleNeoplasia = condizionePerCategoria(CATEGORIA.NEOPLASIA, datiVariabile?.diagnosiPatologiaPregressa);
    let visibleDisplasia = condizionePerCategoria(CATEGORIA.DISPLASIA, datiVariabile?.diagnosiPatologiaPregressa);

    let cloneDati = preparaCloneDati(datiVariabile);
    if (!visibleNeoplasia) {
        delete cloneDati.neoplasiaTipologia;
        delete cloneDati.neoplasiaIntervento;
    }
    if (!visibleDisplasia) {
        delete cloneDati.displasiaSpecificareIntervento;
    }

    let riga1 = rigaDiagnosiPatologiaPregressaDefault(fieldsId, that, index, datiVariabile);
    datiVariabili.push(riga1);

    let riga2 = rigaDisplasia(fieldsId, index, that, datiVariabile, visibleDisplasia, field);
    datiVariabili.push(riga2);

    let riga3 = rigaNeoplasia(fieldsId, index, that, datiVariabile, visibleNeoplasia);
    datiVariabili.push(riga3);

    let riga4 = rigaDataPatologiaAttivaDefault(fieldsId, datiVariabile, that, index, visible, field);
    datiVariabili.push(riga4);

    let riga5 = rigaCondizioneRilevataDaAttenzionareNoteDefault(fieldsId, datiVariabile, that, index, visible, arrayDati);
    datiVariabili.push(riga5);

    let riga6 = rigaProposteAttivitaSuggerimenti(fieldsId, datiVariabile, that, index, visibleProposteAttivitaSuggerimenti);
    datiVariabili.push(riga6);
    // TODO [20/04/20200] - CODE DA ELIMINARE
    // rigaBottoneNuovaDiagnosiPatologiaDefault(index, ultimoIndex, datiVariabile, that, datiVariabili, visibleButton, field);

    return datiVariabili;
};

const formPsichiatria = (that, datiVariabile, index, arrayDati, ultimoIndex, fieldsId, field, pageState) => {
    let datiVariabili = [];
    let visible = !Utils.isObjectNull(datiVariabile?.diagnosiPatologiaPregressa);
    let visibleButton = visible && isFieldIdWriteable(fieldsId.diagnosiPatologiaPregressa, pageState);
    let visibleProposteAttivitaSuggerimenti = datiVariabile?.condizioneRilevata === "02";

    let riga1 = rigaDiagnosiPatologiaPregressaDefault(fieldsId, that, index, datiVariabile);
    datiVariabili.push(riga1);

    let riga2 = rigaPsichiatria(fieldsId, index, that, datiVariabile, visible, field);
    datiVariabili.push(riga2);

    let riga4 = rigaDataPatologiaAttivaDefault(fieldsId, datiVariabile, that, index, visible, field);
    datiVariabili.push(riga4);

    let riga5 = rigaCondizioneRilevataDaAttenzionareNoteDefault(fieldsId, datiVariabile, that, index, visible, arrayDati);
    datiVariabili.push(riga5);

    let riga6 = rigaProposteAttivitaSuggerimenti(fieldsId, datiVariabile, that, index, visibleProposteAttivitaSuggerimenti);
    datiVariabili.push(riga6);
    // TODO [20/04/20200] - CODE DA ELIMINARE
    // rigaBottoneNuovaDiagnosiPatologiaDefault(index, ultimoIndex, datiVariabile, that, datiVariabili, visibleButton, field);

    return datiVariabili;
};

const formAnemia = (that, datiVariabile, index, arrayDati, ultimoIndex, fieldsId, field, pageState) => {
    let datiVariabili = [];
    let visible = !Utils.isObjectNull(datiVariabile?.diagnosiPatologiaPregressa);
    let visibleButton = visible && isFieldIdWriteable(fieldsId.diagnosiPatologiaPregressa, pageState);
    let visibleProposteAttivitaSuggerimenti = datiVariabile?.condizioneRilevata === "02";
    let visibleAnemia = condizionePerCategoria(CATEGORIA.ANEMIA, datiVariabile?.diagnosiPatologiaPregressa);

    let cloneDati = preparaCloneDati(datiVariabile);
    if (!visibleAnemia) {
        delete cloneDati.trasfusioni;
    }

    let riga1 = rigaDiagnosiPatologiaPregressaDefault(fieldsId, that, index, datiVariabile);
    datiVariabili.push(riga1);

    let riga2 = rigaAnemiaTipologia(fieldsId, datiVariabile, that, index, visible, field);
    datiVariabili.push(riga2);

    let riga3 = rigaAnemia(fieldsId, index, that, datiVariabile, visibleAnemia, field);
    datiVariabili.push(riga3);

    let riga4 = rigaDataPatologiaAttivaDefault(fieldsId, datiVariabile, that, index, visible, field);
    datiVariabili.push(riga4);

    let riga5 = rigaCondizioneRilevataDaAttenzionareNoteDefault(fieldsId, datiVariabile, that, index, visible, arrayDati);
    datiVariabili.push(riga5);

    let riga6 = rigaProposteAttivitaSuggerimenti(fieldsId, datiVariabile, that, index, visibleProposteAttivitaSuggerimenti);
    datiVariabili.push(riga6);
    // TODO [20/04/20200] - CODE DA ELIMINARE
    // rigaBottoneNuovaDiagnosiPatologiaDefault(index, ultimoIndex, datiVariabile, that, datiVariabili, visibleButton, field);

    return datiVariabili;
};

export const argomenti = {
    patologieApparatoRespiratorio: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            neoplasiaTipologia: null,
            neoplasiaIntervento: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.PATOLOGIEDELLAPPARATORESPIRATORIO.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaTipologia: "NEOPL_TIPOL.PATOLOGIEDELLAPPARATORESPIRATORIO.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaIntervento: "NEOPL_INTERV.PATOLOGIEDELLAPPARATORESPIRATORIO.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.PATOLOGIEDELLAPPARATORESPIRATORIO.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.PATOLOGIEDELLAPPARATORESPIRATORIO.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.PATOLOGIEDELLAPPARATORESPIRATORIO.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.PATOLOGIEDELLAPPARATORESPIRATORIO.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.PATOLOGIEDELLAPPARATORESPIRATORIO.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'PATOLOGIEDELLAPPARATORESPIRATORIO.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formNeoplasia,
        field: "patologieApparatoRespiratorio",
        text: "Patologie apparato respiratorio",
        checkboxFieldId: "PRESENZA.PATOLOGIEDELLAPPARATORESPIRATORIO.ANAMNESIPATOLOGICAREMOTA"
    },
    diabete: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.DIABETE.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.DIABETE.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.DIABETE.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.DIABETE.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.DIABETE.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.DIABETE.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'DIABETE.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formDefault,
        field: "diabete",
        text: "Diabete",
        checkboxFieldId: "PRESENZA.DIABETE.ANAMNESIPATOLOGICAREMOTA"
    },
    cardiopatia: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.CARDIOPATIA.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.CARDIOPATIA.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.CARDIOPATIA.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.CARDIOPATIA.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.CARDIOPATIA.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.CARDIOPATIA.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'CARDIOPATIA.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formDefault,
        field: "cardiopatia",
        text: "Cardiopatia",
        checkboxFieldId: "PRESENZA.CARDIOPATIA.ANAMNESIPATOLOGICAREMOTA"
    },
    ipertensioneArteriosa: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.IPERTENSIONEARTERIOSA.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.IPERTENSIONEARTERIOSA.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.IPERTENSIONEARTERIOSA.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.IPERTENSIONEARTERIOSA.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.IPERTENSIONEARTERIOSA.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.IPERTENSIONEARTERIOSA.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'IPERTENSIONEARTERIOSA.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formDefault,
        field: "ipertensioneArteriosa",
        text: "Ipertensione arteriosa",
        checkboxFieldId: "PRESENZA.IPERTENSIONEARTERIOSA.ANAMNESIPATOLOGICAREMOTA"
    },
    vasculopatia: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.VASCULOPATIA.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.VASCULOPATIA.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.VASCULOPATIA.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.VASCULOPATIA.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.VASCULOPATIA.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.VASCULOPATIA.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'VASCULOPATIA.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formDefault,
        field: "vasculopatia",
        text: "Vasculopatia",
        checkboxFieldId: "PRESENZA.VASCULOPATIA.ANAMNESIPATOLOGICAREMOTA"
    },
    epatopatia: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            sePatologiaPeptica: null,
            neoplasiaTipologia: null,
            neoplasiaIntervento: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.EPATOPATIA.ANAMNESIPATOLOGICAREMOTA",
            sePatologiaPeptica: "SE_PAT_PEPT.EPATOPATIA.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaTipologia: "NEOPL_TIPOL.EPATOPATIA.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaIntervento: "NEOPL_INTERV.EPATOPATIA.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.EPATOPATIA.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.EPATOPATIA.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.EPATOPATIA.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.EPATOPATIA.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.EPATOPATIA.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'EPATOPATIA.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formNeoplasiaPeptica,
        field: "epatopatia",
        text: "Epatopatia",
        checkboxFieldId: "PRESENZA.EPATOPATIA.ANAMNESIPATOLOGICAREMOTA"
    },
    patologiaGastroenterica: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            sePatologiaPeptica: null,
            neoplasiaTipologia: null,
            neoplasiaIntervento: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.PATOLOGIAGASTROENTERICA.ANAMNESIPATOLOGICAREMOTA",
            sePatologiaPeptica: "SE_PAT_PEPT.PATOLOGIAGASTROENTERICA.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaTipologia: "NEOPL_TIPOL.PATOLOGIAGASTROENTERICA.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaIntervento: "NEOPL_INTERV.PATOLOGIAGASTROENTERICA.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.PATOLOGIAGASTROENTERICA.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.PATOLOGIAGASTROENTERICA.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.PATOLOGIAGASTROENTERICA.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.PATOLOGIAGASTROENTERICA.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.PATOLOGIAGASTROENTERICA.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'PATOLOGIAGASTROENTERICA.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formNeoplasiaPeptica,
        field: "patologiaGastroenterica",
        text: "Patologia gastroenterica",
        checkboxFieldId: "PRESENZA.PATOLOGIAGASTROENTERICA.ANAMNESIPATOLOGICAREMOTA"
    },
    nefropatia: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            insufficienzaRenaleTipologia: null,
            insufficienzaRenaleStadio: null,
            neoplasiaTipologia: null,
            neoplasiaIntervento: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.NEFROPATIA.ANAMNESIPATOLOGICAREMOTA",
            insufficienzaRenaleTipologia: "INSUFF_REN_TIPO.NEFROPATIA.ANAMNESIPATOLOGICAREMOTA",
            insufficienzaRenaleStadio: "INSUFF_REN_STADIO.NEFROPATIA.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaTipologia: "NEOPL_TIPOL.NEFROPATIA.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaIntervento: "NEOPL_INTERV.NEFROPATIA.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.NEFROPATIA.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.NEFROPATIA.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.NEFROPATIA.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.NEFROPATIA.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.NEFROPATIA.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'NEFROPATIA.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formNeoplasiaInsufficienzaRenale,
        field: "nefropatia",
        text: "Nefropatia",
        checkboxFieldId: "PRESENZA.NEFROPATIA.ANAMNESIPATOLOGICAREMOTA"
    },
    patologiaUrogenitale: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            neoplasiaTipologia: null,
            neoplasiaIntervento: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.PATOLOGIAUROGENITALE.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaTipologia: "NEOPL_TIPOL.PATOLOGIAUROGENITALE.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaIntervento: "NEOPL_INTERV.PATOLOGIAUROGENITALE.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.PATOLOGIAUROGENITALE.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.PATOLOGIAUROGENITALE.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.PATOLOGIAUROGENITALE.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.PATOLOGIAUROGENITALE.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.PATOLOGIAUROGENITALE.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'PATOLOGIAUROGENITALE.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formNeoplasia,
        field: "patologiaUrogenitale",
        text: "Patologia urogenitale",
        checkboxFieldId: "PRESENZA.PATOLOGIAUROGENITALE.ANAMNESIPATOLOGICAREMOTA"
    },
    malattieGinecologiche: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            displasiaSpecificareIntervento: null,
            neoplasiaTipologia: null,
            neoplasiaIntervento: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.MALATTIEGINECOLOGICHE.ANAMNESIPATOLOGICAREMOTA",
            displasiaSpecificareIntervento: "DISPL_SPEC_INTERV.MALATTIEGINECOLOGICHE.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaTipologia: "NEOPL_TIPOL.MALATTIEGINECOLOGICHE.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaIntervento: "NEOPL_INTERV.MALATTIEGINECOLOGICHE.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.MALATTIEGINECOLOGICHE.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.MALATTIEGINECOLOGICHE.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.MALATTIEGINECOLOGICHE.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.MALATTIEGINECOLOGICHE.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.MALATTIEGINECOLOGICHE.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'MALATTIEGINECOLOGICHE.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formNeoplasiaDisplasia,
        field: "malattieGinecologiche",
        text: "Malattie ginecologiche",
        checkboxFieldId: "PRESENZA.MALATTIEGINECOLOGICHE.ANAMNESIPATOLOGICAREMOTA"
    },
    sistemaLinfoEmatologico: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            anemiaTipologia: null,
            trasfusioni: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.SISTEMALINFOEMATOLOGICO.ANAMNESIPATOLOGICAREMOTA",
            anemiaTipologia: "ANEM_TIPOL.SISTEMALINFOEMATOLOGICO.ANAMNESIPATOLOGICAREMOTA",
            trasfusioni: "TRASFUS.SISTEMALINFOEMATOLOGICO.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.SISTEMALINFOEMATOLOGICO.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.SISTEMALINFOEMATOLOGICO.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.SISTEMALINFOEMATOLOGICO.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.SISTEMALINFOEMATOLOGICO.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.SISTEMALINFOEMATOLOGICO.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'SISTEMALINFOEMATOLOGICO.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formAnemia,
        field: "sistemaLinfoEmatologico",
        text: "Sistema linfo/ematologico",
        checkboxFieldId: "PRESENZA.SISTEMALINFOEMATOLOGICO.ANAMNESIPATOLOGICAREMOTA"
    },
    ghiandoleEndocrineMetabolismo: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            neoplasiaTipologia: null,
            neoplasiaIntervento: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.GHIANGOLEENDOCRINEEMETABOLISMO.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaTipologia: "NEOPL_TIPOL.GHIANGOLEENDOCRINEEMETABOLISMO.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaIntervento: "NEOPL_INTERV.GHIANGOLEENDOCRINEEMETABOLISMO.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.GHIANGOLEENDOCRINEEMETABOLISMO.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.GHIANGOLEENDOCRINEEMETABOLISMO.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.GHIANGOLEENDOCRINEEMETABOLISMO.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.GHIANGOLEENDOCRINEEMETABOLISMO.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.GHIANGOLEENDOCRINEEMETABOLISMO.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'GHIANGOLEENDOCRINEEMETABOLISMO.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formNeoplasia,
        field: "ghiandoleEndocrineMetabolismo",
        text: "Ghiandole endocrine e metabolismo",
        checkboxFieldId: "PRESENZA.GHIANGOLEENDOCRINEEMETABOLISMO.ANAMNESIPATOLOGICAREMOTA"
    },
    malattieAutoimmuniReumatologia: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.MALATTIEAUTOIMMUNIREUMATOLOGIA.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.MALATTIEAUTOIMMUNIREUMATOLOGIA.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.MALATTIEAUTOIMMUNIREUMATOLOGIA.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.MALATTIEAUTOIMMUNIREUMATOLOGIA.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.MALATTIEAUTOIMMUNIREUMATOLOGIA.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.MALATTIEAUTOIMMUNIREUMATOLOGIA.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'MALATTIEAUTOIMMUNIREUMATOLOGIA.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formDefault,
        field: "malattieAutoimmuniReumatologia",
        text: "Malattie autoimmuni/reumatologia",
        checkboxFieldId: "PRESENZA.MALATTIEAUTOIMMUNIREUMATOLOGIA.ANAMNESIPATOLOGICAREMOTA"
    },
    patologiaNeurologica: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            neoplasiaTipologia: null,
            neoplasiaIntervento: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.PATOLOGIANEUROLOGICA.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaTipologia: "NEOPL_TIPOL.PATOLOGIANEUROLOGICA.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaIntervento: "NEOPL_INTERV.PATOLOGIANEUROLOGICA.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.PATOLOGIANEUROLOGICA.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.PATOLOGIANEUROLOGICA.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.PATOLOGIANEUROLOGICA.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.PATOLOGIANEUROLOGICA.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.PATOLOGIANEUROLOGICA.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'PATOLOGIANEUROLOGICA.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formNeoplasia,
        field: "patologiaNeurologica",
        text: "Patologia neurologica",
        checkboxFieldId: "PRESENZA.PATOLOGIANEUROLOGICA.ANAMNESIPATOLOGICAREMOTA"
    },
    patologiaPsichiatrica: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            storiaFamiliare: null,
            storiaPersonale: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.PATOLOGIAPSICHIATRICA.ANAMNESIPATOLOGICAREMOTA",
            storiaFamiliare: "STORIA_FAMIL.PATOLOGIAPSICHIATRICA.ANAMNESIPATOLOGICAREMOTA",
            storiaPersonale: "STORIA_PERS.PATOLOGIAPSICHIATRICA.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.PATOLOGIAPSICHIATRICA.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.PATOLOGIAPSICHIATRICA.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.PATOLOGIAPSICHIATRICA.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.PATOLOGIAPSICHIATRICA.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.PATOLOGIAPSICHIATRICA.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'PATOLOGIAPSICHIATRICA.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formPsichiatria,
        field: "patologiaPsichiatrica",
        text: "Patologia psichiatrica",
        checkboxFieldId: "PRESENZA.PATOLOGIAPSICHIATRICA.ANAMNESIPATOLOGICAREMOTA"
    },
    malattieInfettive: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.MALATTIEINFETTIVE.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.MALATTIEINFETTIVE.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.MALATTIEINFETTIVE.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.MALATTIEINFETTIVE.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.MALATTIEINFETTIVE.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.MALATTIEINFETTIVE.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'MALATTIEINFETTIVE.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formDefault,
        field: "malattieInfettive",
        text: "Malattie infettive",
        checkboxFieldId: "PRESENZA.MALATTIEINFETTIVE.ANAMNESIPATOLOGICAREMOTA"
    },
    patologieOsteoarticolari: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            neoplasiaTipologia: null,
            neoplasiaIntervento: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.PATOLOGIEOSTEOARTICOLARI.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaTipologia: "NEOPL_TIPOL.PATOLOGIEOSTEOARTICOLARI.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaIntervento: "NEOPL_INTERV.PATOLOGIEOSTEOARTICOLARI.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.PATOLOGIEOSTEOARTICOLARI.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.PATOLOGIEOSTEOARTICOLARI.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.PATOLOGIEOSTEOARTICOLARI.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.PATOLOGIEOSTEOARTICOLARI.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.PATOLOGIEOSTEOARTICOLARI.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'PATOLOGIEOSTEOARTICOLARI.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formNeoplasia,
        field: "patologieOsteoarticolari",
        text: "Patologie osteoarticolari",
        checkboxFieldId: "PRESENZA.PATOLOGIEOSTEOARTICOLARI.ANAMNESIPATOLOGICAREMOTA"
    },
    patologiaOculare: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            neoplasiaTipologia: null,
            neoplasiaIntervento: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.PATOLOGIAOCULARE.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaTipologia: "NEOPL_TIPOL.PATOLOGIAOCULARE.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaIntervento: "NEOPL_INTERV.PATOLOGIAOCULARE.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.PATOLOGIAOCULARE.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.PATOLOGIAOCULARE.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.PATOLOGIAOCULARE.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.PATOLOGIAOCULARE.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.PATOLOGIAOCULARE.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'PATOLOGIAOCULARE.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formNeoplasia,
        field: "patologiaOculare",
        text: "Patologia oculare",
        checkboxFieldId: "PRESENZA.PATOLOGIAOCULARE.ANAMNESIPATOLOGICAREMOTA"
    },
    malattieORL: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            neoplasiaTipologia: null,
            neoplasiaIntervento: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.MALATTIEORL.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaTipologia: "NEOPL_TIPOL.MALATTIEORL.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaIntervento: "NEOPL_INTERV.MALATTIEORL.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.MALATTIEORL.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.MALATTIEORL.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.MALATTIEORL.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.MALATTIEORL.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.MALATTIEORL.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'MALATTIEORL.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formNeoplasia,
        field: "malattieORL",
        text: "Malattie ORL",
        checkboxFieldId: "PRESENZA.MALATTIEORL.ANAMNESIPATOLOGICAREMOTA"
    },
    cuteSottocutaneo: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            neoplasiaTipologia: null,
            neoplasiaIntervento: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.CUTEESOTTOCUTANEO.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaTipologia: "NEOPL_TIPOL.CUTEESOTTOCUTANEO.ANAMNESIPATOLOGICAREMOTA",
            neoplasiaIntervento: "NEOPL_INTERV.CUTEESOTTOCUTANEO.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.CUTEESOTTOCUTANEO.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.CUTEESOTTOCUTANEO.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.CUTEESOTTOCUTANEO.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.CUTEESOTTOCUTANEO.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.CUTEESOTTOCUTANEO.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'CUTEESOTTOCUTANEO.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formNeoplasia,
        field: "cuteSottocutaneo",
        text: "Cute e sottocutaneo",
        checkboxFieldId: "PRESENZA.CUTEESOTTOCUTANEO.ANAMNESIPATOLOGICAREMOTA"
    },
    altro: {
        datiDefault: {
            id: null,
            diagnosiPatologiaPregressa: null,
            data: null,
            patologiaAttiva: null,
            condizioneRilevata: null,
            daAttenzionare: null,
            note: null,
            propostaAttivitaSuggerimenti: null
        },
        fieldsId: {
            diagnosiPatologiaPregressa: "DIAG_PAT_PREG.ALTRO.ANAMNESIPATOLOGICAREMOTA",
            data: 'DATA.ALTRO.ANAMNESIPATOLOGICAREMOTA',
            patologiaAttiva: 'PAT_ATT.ALTRO.ANAMNESIPATOLOGICAREMOTA',
            condizioneRilevata: 'COND_RIL.ALTRO.ANAMNESIPATOLOGICAREMOTA',
            daAttenzionare: 'DA_ATTENZ.ALTRO.ANAMNESIPATOLOGICAREMOTA',
            note: 'NOTE.ALTRO.ANAMNESIPATOLOGICAREMOTA',
            propostaAttivitaSuggerimenti: 'ALTRO.ANAMNESIPATOLOGICAREMOTA'
        },
        form: formDefault,
        field: "altro",
        text: "Altro",
        checkboxFieldId: "PRESENZA.ALTRO.ANAMNESIPATOLOGICAREMOTA"
    },
};
