import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionHelper from "../../service/AccordionHelper";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import Utils from "../../utils/Utils";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import Capo from "./capo/Capo";
import CuteEAnnessi from "./cuteEAnnessi/CuteEAnnessi";
import Orecchie from "./orecchie/Orecchie";
import Occhi from "./occhi/Occhi";
import Naso from "./naso/Naso";
import SistemaLinfatico from "./sistemaLinfatico/SistemaLinfatico";
import UrogenitaleFemminileOstetrico from "./urogenitaleFemminileOstetrico/UrogenitaleFemminileOstetrico";
import Collo from "./collo/Collo";
import Osteoarticolare from "./osteoarticolare/Osteoarticolare";
import Addome from "./addome/Addome";
import ApparatoVascolarePeriferico from "./apparatoVascolarePeriferico/ApparatoVascolarePeriferico";
import Mammelle from "./mammelle/Mammelle";
import Urogenitale from "./urogenitale/Urogenitale.js";
import EsplorazioneRettale from "./esplorazioneRettale/EsplorazioneRettale";
import Cuore from "./cuore/Cuore";
import NeurologicoEstatoDiVigilanza from "./neurologicoEstatoDiVigilanza/NeurologicoEstatoDiVigilanza";
import Torace from "./torace/Torace";
import Orofaringe from "./orofaringe/Orofaringe";
import SessionRequest from "../../utils/SessionRequest";
import Box from "../generalComponents/box/Box";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import VersionedAccordion from "../VersionedAccordion";
import {FieldsRow, RoleBasedCheckbox} from "../roleBasedComponents/RoleBasedComponents";
import HistorySessionRequest from "../../utils/HistorySessionRequest";
import AuthUtils from "../../utils/AuthUtils";

const propTypes = {
    esameObiettivo: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeEsameObiettivo: PropTypes.func,
    openAccordion: PropTypes.any,
    openAccordionCapo: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    showAccordionSezioneRichiesta: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

export default class EsameObiettivo extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: {
            sottosezioneUrogenitaleFemminileOstetrico: 0,
            sottosezioneUrogenitale: 0,
            sottosezioneTorace: 0,
            sottosezioneSistemaLinfatico: 0,
            sottosezioneOsteoarticolare: 0,
            sottosezioneOrofaringe:0,
            sottosezioneOrecchie:0,
            sottosezioneOcchi:0,
            sottosezioneNeurologicoEstatoDiVigilanza:0,
            sottosezioneNaso:0,
            sottosezioneMammelle:0,
            sottosezioneEsplorazioneRettale:0,
            sottosezioneCuteEAnnessi:0,
            sottosezioneCuore:0,
            sottosezioneCollo:0,
            sottosezioneCapo:0,
            sottosezioneApparatoVascolarePeriferico:0,
            sottosezioneAddome:0,
        },
        esameObiettivo: _.cloneDeep(this.props.esameObiettivo),
        sottoAccordion: {
            openAccordionCuteEAnnessi: true,
            openAccordionCapo: true,
            openAccordionOcchi: true,
            openAccordionOrofaringe: true,
            openAccordionNaso: true,
            openAccordionOrecchie: true,
            openAccordionCollo: true,
            openAccordionTorace: true,
            openAccordionMammelle: true,
            openAccordionSistemaLinfatico: true,
            openAccordionCuore: true,
            openAccordionApparatoVascolarePeriferico: true,
            openAccordionAddome: true,
            openAccordionUrogenitaleFemminile: true,
            openAccordionUrogenitaleFemminileOstetrico: true,
            openAccordionUrogenitaleMaschile: true,
            openAccordionEsplorazioneRettale: true,
            openAccordionOsteoarticolare: true,
            openAccordionNeurologicoEStatoDiVigilanza: true
        }
    }

    checkboxes = [
        {
            stato: "openAccordionCuteEAnnessi",
            label: "Cute e annessi",
            campo: "cuteEAnnessi",
            fieldId: "PRESENZA.CUTEEANNESSI.ESAMEOBIETTIVO"
        },
        {stato: "openAccordionCapo", label: "Capo", campo: "capo", fieldId: "PRESENZA.CAPO.ESAMEOBIETTIVO"},
        {stato: "openAccordionOcchi", label: "Occhi", campo: "occhi", fieldId: "PRESENZA.OCCHI.ESAMEOBIETTIVO"},
        {
            stato: "openAccordionOrofaringe",
            label: "Orofaringe",
            campo: "orofaringe",
            fieldId: "PRESENZA.OROFARINGE.ESAMEOBIETTIVO"
        },
        {stato: "openAccordionNaso", label: "Naso", campo: "naso", fieldId: "PRESENZA.NASO.ESAMEOBIETTIVO"},
        {
            stato: "openAccordionOrecchie",
            label: "Orecchie",
            campo: "orecchie",
            fieldId: "PRESENZA.ORECCHIE.ESAMEOBIETTIVO"
        },
        {stato: "openAccordionCollo", label: "Collo", campo: "collo", fieldId: "PRESENZA.COLLO.ESAMEOBIETTIVO"},
        {stato: "openAccordionTorace", label: "Torace", campo: "torace", fieldId: "PRESENZA.TORACE.ESAMEOBIETTIVO"},
        {
            stato: "openAccordionMammelle",
            label: "Mammelle",
            campo: "mammelle",
            sesso: "02",
            fieldId: "PRESENZA.MAMMELLE.ESAMEOBIETTIVO"
        },
        {
            stato: "openAccordionSistemaLinfatico",
            label: "Sistema linfatico",
            campo: "sistemaLinfatico",
            fieldId: "PRESENZA.SISTEMALINFATICO.ESAMEOBIETTIVO"
        },
        {stato: "openAccordionCuore", label: "Cuore", campo: "cuore", fieldId: "PRESENZA.CUORE.ESAMEOBIETTIVO"},
        {
            stato: "openAccordionApparatoVascolarePeriferico",
            label: "Apparato vascolare periferico",
            campo: "apparatoVascolarePeriferico",
            fieldId: "PRESENZA.APPARATOVASCOLAREPERIFERICO.ESAMEOBIETTIVO"
        },
        {stato: "openAccordionAddome", label: "Addome", campo: "addome", fieldId: "PRESENZA.ADDOME.ESAMEOBIETTIVO"},
        {
            stato: "openAccordionUrogenitaleFemminile",
            label: "Urogenitale femminile",
            campo: "urogenitaleFemminile",
            sesso: "02",
            fieldId: "PRESENZA.UROGENITALEFEMMINILE.ESAMEOBIETTIVO"
        },
        {
            stato: "openAccordionUrogenitaleFemminileOstetrico",
            label: "Urogenitale femminile ostetrico",
            campo: "urogenitaleFemminileOstetrico",
            sesso: "02",
            fieldId: "PRESENZA.UROGENITALEFEMMINILEOSTETRICO.ESAMEOBIETTIVO"
        },
        {
            stato: "openAccordionUrogenitaleMaschile",
            label: "Urogenitale maschile",
            campo: "urogenitaleMaschile",
            sesso: "01",
            fieldId: "PRESENZA.UROGENITALEMASCHILE.ESAMEOBIETTIVO"
        },
        {
            stato: "openAccordionEsplorazioneRettale",
            label: "Esplorazione rettale",
            campo: "esplorazioneRettale",
            fieldId: "PRESENZA.ESPLORAZIONERETTALE.ESAMEOBIETTIVO"
        },
        {
            stato: "openAccordionOsteoarticolare",
            label: "Osteoarticolare",
            campo: "osteoarticolare",
            fieldId: "PRESENZA.OSTEOARTICOLARE.ESAMEOBIETTIVO"
        },
        {
            stato: "openAccordionNeurologicoEStatoDiVigilanza",
            label: "Neurologico e stato di vigilanza",
            campo: "neurologicoEstatoDiVigilanza",
            fieldId: "PRESENZA.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO"
        }
    ]

    campiObbligatori = {...this.state.campiObbligatoriMancanti};

    componentDidMount() {
        if (!(this.props.showAccordionSezioneRichiesta || this.context.forceReadOnly)) this.inizializzaVisualizzazioneAccordion();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.esameObiettivo, this.props.esameObiettivo))
            this.setState({esameObiettivo: this.props.esameObiettivo});
    }

    inizializzaVisualizzazioneAccordion = () => {
        const sottoAccordion = Utils.inizializzaVisualizzazioneAccordion(this.checkboxes, this.state.sottoAccordion, this.state.esameObiettivo, this.context.forceReadOnly && !this.context.hideUnsetValues, this.context.forceUserRole);
        this.setState({sottoAccordion: sottoAccordion});
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.sottoAccordion);
        accordion[field] = value;

        if (accordion[field]) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta.esameObiettivo = this.props.esameObiettivo;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({sottoAccordion: accordion});
        }
    }

    handleChangeCheckbox(field, elem) {
        let esameObiettivo = _.cloneDeep(this.state.esameObiettivo);
        let sottoAccordion = _.cloneDeep(this.state.sottoAccordion);

        const campo = this.checkboxes.filter(el => el.stato === field)[0]?.campo;
        sottoAccordion[field] = elem.target.checked;
        if (!elem.target.checked) {
            esameObiettivo[campo] = _.cloneDeep(SessionRequest.getInitObject('esameObiettivo.'.concat(campo)));
        }
        esameObiettivo[campo].checked = elem.target.checked;

        this.setState({sottoAccordion, esameObiettivo},
            () => this.props.onChangeEsameObiettivo(campo, esameObiettivo[campo]));
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        this.campiObbligatori[field] = value;
        this.setState({campiObbligatoriMancanti: _.cloneDeep(this.campiObbligatori)}, () => {
            this.props.onChangeObbligatorieta("esameObiettivo", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    renderBodyWithCheckbox = () => {
        let sottoAccordion = _.cloneDeep(this.state.sottoAccordion);
        const headerText = "Selezionare uno o più organi/distretti anatomici per inserire segni/sintomi presenti.";
        const sessoPaziente = this.props.sessoPaziente ?? "01";

        return (
            <>
                {Utils.isStateValutazione(this.props.pageState) &&
                <>
                    <div className={"pb-4"}>{headerText}</div>
                    {
                        this.checkboxes?.map(el => (
                            (el.sesso === undefined || el.sesso === sessoPaziente) &&

                            <Box key={el.stato} hideBox={!sottoAccordion[el.stato]} accordionPadre={true}>
                                <FieldsRow colNumber={-1}>
                                    <RoleBasedCheckbox
                                        fieldId={el.fieldId}
                                        pageState={this.props.pageState}
                                        id={el.stato}
                                        text={el.label + (el.sesso !== undefined && Utils.isStringEmptyOrNullOrUndefined(this.props.sessoPaziente) ? " !!!" : "")}
                                        styleLabel={{fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"}}
                                        field={"checked"}
                                        checked={sottoAccordion[el.stato]}
                                        onChange={(_field, elem) => this.handleChangeCheckbox(el.stato, elem)}
                                        parentJsonPath={`esameobiettivo.${el.campo}`}
                                    />
                                </FieldsRow>

                                {sottoAccordion[el.stato] && el.stato === "openAccordionCapo" &&
                                <VersionedAccordion accordion={Capo}
                                    capo={this.state.esameObiettivo.capo}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionCollo" &&
                                <VersionedAccordion accordion={Collo}
                                    collo={this.state.esameObiettivo.collo}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionApparatoVascolarePeriferico" &&
                                <VersionedAccordion accordion={ApparatoVascolarePeriferico}
                                    apparatoVascolarePeriferico={this.state.esameObiettivo.apparatoVascolarePeriferico}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionOrecchie" &&
                                <VersionedAccordion accordion={Orecchie}
                                    dati={this.state.esameObiettivo.orecchie}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}
                                {sottoAccordion[el.stato] && el.stato === "openAccordionOrofaringe" &&
                                <VersionedAccordion accordion={Orofaringe}
                                    orofaringe={this.state.esameObiettivo.orofaringe}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    disableDoubleInput={Utils.isRuoloInfermiere()}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionOcchi" &&
                                <VersionedAccordion accordion={Occhi}
                                    occhi={this.state.esameObiettivo.occhi}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionCuteEAnnessi" &&
                                <VersionedAccordion accordion={CuteEAnnessi}
                                    cuteEAnnessi={this.state.esameObiettivo.cuteEAnnessi}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    disableDoubleInput={Utils.isRuoloInfermiere()}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionTorace" &&
                                <VersionedAccordion accordion={Torace}
                                    torace={this.state.esameObiettivo.torace}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    disableDoubleInput={Utils.isRuoloInfermiere()}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionNaso" &&
                                <VersionedAccordion accordion={Naso}
                                    naso={this.state.esameObiettivo.naso}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionSistemaLinfatico" &&
                                <VersionedAccordion accordion={SistemaLinfatico}
                                    sistemaLinfatico={this.state.esameObiettivo.sistemaLinfatico}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionCuore" &&
                                <VersionedAccordion accordion={Cuore}
                                    cuore={this.state.esameObiettivo.cuore}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionCuore}
                                    field={"openAccordionCuore"}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionUrogenitaleFemminile" &&
                                <VersionedAccordion accordion={Urogenitale}
                                    urogenitale={this.state.esameObiettivo.urogenitaleFemminile}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.props.sessoPaziente}
                                    tipoUrogenitale={"Femminile"}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    disableDoubleInput={Utils.isRuoloInfermiere()}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionUrogenitaleFemminileOstetrico" &&
                                <VersionedAccordion accordion={UrogenitaleFemminileOstetrico}
                                    dati={this.state.esameObiettivo.urogenitaleFemminileOstetrico}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.props.sessoPaziente}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionUrogenitaleMaschile" &&
                                <VersionedAccordion accordion={Urogenitale}
                                    urogenitale={this.state.esameObiettivo.urogenitaleMaschile}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.props.sessoPaziente}
                                    tipoUrogenitale={"Maschile"}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionOsteoarticolare" &&
                                <VersionedAccordion accordion={Osteoarticolare}
                                    dati={this.state.esameObiettivo.osteoarticolare}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionAddome" &&
                                <VersionedAccordion accordion={Addome}
                                    dati={this.state.esameObiettivo.addome}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    disableDoubleInput={Utils.isRuoloInfermiere()}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionMammelle" &&
                                <VersionedAccordion accordion={Mammelle}
                                    mammelle={this.state.esameObiettivo.mammelle}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionEsplorazioneRettale" &&
                                <VersionedAccordion accordion={EsplorazioneRettale}
                                    esplorazioneRettale={this.state.esameObiettivo.esplorazioneRettale}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    disableDoubleInput={Utils.isRuoloInfermiere()}
                                />}

                                {sottoAccordion[el.stato] && el.stato === "openAccordionNeurologicoEStatoDiVigilanza" &&
                                <VersionedAccordion accordion={NeurologicoEstatoDiVigilanza}
                                    neurologicoEstatoDiVigilanza={this.state.esameObiettivo.neurologicoEstatoDiVigilanza}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}
                            </Box>
                        ))
                    }
                </>}
            </>
        );
    }

    renderBodyWithAccordion = () => {
        let sottoAccordion = _.cloneDeep(this.state.sottoAccordion);
        const sessoPaziente = this.props.sessoPaziente ?? "01";

        return (
            <>
                {Utils.isStateValutazione(this.props.pageState) &&
                <>
                    {
                        this.checkboxes?.map(el => (
                            <>
                                {!!(HistorySessionRequest.getDataStartWithJsonPath(`esameobiettivo.${el.campo}`).length) && (<FieldsRow colNumber={-1}>
                                    <RoleBasedCheckbox
                                        fieldId={el.fieldId}
                                        pageState={this.props.pageState}
                                        id={el.stato}
                                        text={el.label + (el.sesso !== undefined && Utils.isStringEmptyOrNullOrUndefined(this.props.sessoPaziente) ? " !!!" : "")}
                                        styleLabel={{fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"}}
                                        field={"checked"}
                                        checked={this.state.esameObiettivo[el.campo].checked}
                                        parentJsonPath={`esameobiettivo.${el.campo}`}
                                        disabled={true}
                                    />
                                </FieldsRow>)}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionCuteEAnnessi" &&
                                <VersionedAccordion accordion={CuteEAnnessi}
                                    cuteEAnnessi={this.state.esameObiettivo.cuteEAnnessi}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionCuteEAnnessi}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput || Utils.isRuoloInfermiere()}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}


                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionCapo" &&
                                <VersionedAccordion accordion={Capo}
                                    capo={this.state.esameObiettivo.capo}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionCapo}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput && !AuthUtils.hasUtenteRuoloSpecialistaValutatore() && !AuthUtils.hasRuoloMedicoMedicinaGenerale()}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionOcchi" &&
                                <VersionedAccordion accordion={Occhi}
                                    occhi={this.state.esameObiettivo.occhi}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionOcchi}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionOrofaringe" &&
                                <VersionedAccordion accordion={Orofaringe}
                                    orofaringe={this.state.esameObiettivo.orofaringe}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionOrofaringe}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput || Utils.isRuoloInfermiere()}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionNaso" &&
                                <VersionedAccordion accordion={Naso}
                                    naso={this.state.esameObiettivo.naso}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionNaso}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionOrecchie" &&
                                <VersionedAccordion accordion={Orecchie}
                                    dati={this.state.esameObiettivo.orecchie}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionOrecchie}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionCollo" &&
                                <VersionedAccordion accordion={Collo}
                                    collo={this.state.esameObiettivo.collo}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionCollo}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionTorace" &&
                                <VersionedAccordion accordion={Torace}
                                    torace={this.state.esameObiettivo.torace}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionTorace}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    accordionTitle={"Torace"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput || Utils.isRuoloInfermiere()}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionMammelle" && el.sesso === sessoPaziente &&
                                <VersionedAccordion accordion={Mammelle}
                                    mammelle={this.state.esameObiettivo.mammelle}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionMammelle}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    sessoPaziente={this.props.sessoPaziente}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionSistemaLinfatico" &&
                                <VersionedAccordion accordion={SistemaLinfatico}
                                    sistemaLinfatico={this.state.esameObiettivo.sistemaLinfatico}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionSistemaLinfatico}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionCuore" &&
                                <VersionedAccordion accordion={Cuore}
                                    cuore={this.state.esameObiettivo.cuore}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionCuore}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionCuore"}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput && !AuthUtils.hasUtenteRuoloFisiatra() && !AuthUtils.hasUtenteRuoloSpecialistaValutatore() && !AuthUtils.hasRuoloMedicoMedicinaGenerale()}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionApparatoVascolarePeriferico" &&
                                <VersionedAccordion accordion={ApparatoVascolarePeriferico}
                                    apparatoVascolarePeriferico={this.state.esameObiettivo.apparatoVascolarePeriferico}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionApparatoVascolarePeriferico}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionAddome" &&
                                <VersionedAccordion accordion={Addome}
                                    dati={this.state.esameObiettivo.addome}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionAddome}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput || Utils.isRuoloInfermiere()}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionUrogenitaleMaschile" && el.sesso === sessoPaziente &&
                                <VersionedAccordion accordion={Urogenitale}
                                    urogenitale={this.state.esameObiettivo.urogenitaleMaschile}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionUrogenitaleMaschile}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.props.sessoPaziente}
                                    tipoUrogenitale={"Maschile"}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionUrogenitaleFemminile" && el.sesso === sessoPaziente &&
                                <VersionedAccordion accordion={Urogenitale}
                                    urogenitale={this.state.esameObiettivo.urogenitaleFemminile}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionUrogenitaleFemminile}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.props.sessoPaziente}
                                    tipoUrogenitale={"Femminile"}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput || Utils.isRuoloInfermiere()}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionUrogenitaleFemminileOstetrico" && el.sesso === sessoPaziente &&
                                <VersionedAccordion accordion={UrogenitaleFemminileOstetrico}
                                    dati={this.state.esameObiettivo.urogenitaleFemminileOstetrico}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionUrogenitaleFemminileOstetrico}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.props.sessoPaziente}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionEsplorazioneRettale" &&
                                <VersionedAccordion accordion={EsplorazioneRettale}
                                    esplorazioneRettale={this.state.esameObiettivo.esplorazioneRettale}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionEsplorazioneRettale}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput || Utils.isRuoloInfermiere()}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionOsteoarticolare" &&
                                <VersionedAccordion accordion={Osteoarticolare}
                                    dati={this.state.esameObiettivo.osteoarticolare}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionOsteoarticolare}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}

                                {(!this.context.hideUnsetValues || sottoAccordion[el.stato] ) && el.stato === "openAccordionNeurologicoEStatoDiVigilanza" &&
                                <VersionedAccordion accordion={NeurologicoEstatoDiVigilanza}
                                    neurologicoEstatoDiVigilanza={this.state.esameObiettivo.neurologicoEstatoDiVigilanza}
                                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                                    openAccordion={this.state.sottoAccordion.openAccordionNeurologicoEStatoDiVigilanza}
                                    onClickAccordion={this.onClickAccordion}
                                    pageState={this.props.pageState}
                                    showAccordion={true}
                                    forceReadOnly={this.props.forceReadOnly}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                />}
                            </>

                        ))
                    }
                </>}
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"esameObiettivo"}
                title={"Esame obiettivo"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
            >{(this.props.showAccordionSezioneRichiesta || this.context.forceReadOnly || !!this.props.accordionReadOnly) ? this.renderBodyWithAccordion() : this.renderBodyWithCheckbox()}</AccordionAvvioRichiesta>
        );
    }
}
EsameObiettivo.contextType = RoleBasedContext;
EsameObiettivo.propTypes = propTypes;
EsameObiettivo.defaultProps = {
    showAccordionSezioneRichiesta: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false,
    onChangeObbligatorieta: () => null
}

