import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {FieldsRow, RoleBasedSelect, SectionList} from "../../roleBasedComponents/RoleBasedComponents";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";

const propTypes = {
    datiServiziAllaPersonaAttiviOInCorsoDiAttivazione: PropTypes.object,
    onChangeDatiAssistito: PropTypes.func,
    openAccordion: PropTypes.bool,
    onClickAccordion: PropTypes.func,
    field: PropTypes.string,
    pageState: PropTypes.string,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

const defaultTipologiaTrasporto = {
    id: null,
    tipologiaTrasporto: null
}

export default class ServiziAllaPersonaAttiviOInCorsoDiAttivazione extends AccordionSecondLevel {
    state = {
        datiServiziAllaPersonaAttiviOInCorsoDiAttivazione: _.cloneDeep(this.props.datiServiziAllaPersonaAttiviOInCorsoDiAttivazione)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiServiziAllaPersonaAttiviOInCorsoDiAttivazione = _.cloneDeep(this.state.datiServiziAllaPersonaAttiviOInCorsoDiAttivazione);
        if (datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.tipologiaTrasporto == null) datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.tipologiaTrasporto = [];
        this.setState({datiServiziAllaPersonaAttiviOInCorsoDiAttivazione: datiServiziAllaPersonaAttiviOInCorsoDiAttivazione}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        let datiServiziAllaPersonaAttiviOInCorsoDiAttivazione = _.cloneDeep(this.state.datiServiziAllaPersonaAttiviOInCorsoDiAttivazione);

        if (index === null && subField === null) {
            datiServiziAllaPersonaAttiviOInCorsoDiAttivazione[field] = elem;
        } else {
            datiServiziAllaPersonaAttiviOInCorsoDiAttivazione[field][index][subField] = elem;
        }

        if (field === 'serviziAttiviOInCorso' && !elem) {
            datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.serviziIgieneCasa = null;
            datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.serviziIgienePersonale = null;
            datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.serviziPastoDomicilio = null;
            datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.serviziEducativiDomicilio = null;
            datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.servizioTelesoccorso = null;
            datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.servizioTrasporto = null;
            datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.tipologiaTrasporto = [];
            datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.inserimentoCentroDiurno = null;
        }

        this.setState({datiServiziAllaPersonaAttiviOInCorsoDiAttivazione: datiServiziAllaPersonaAttiviOInCorsoDiAttivazione}, () => this.validazioneDati());
    }

    validazioneDati = () => {
        let datiServiziAllaPersonaAttiviOInCorsoDiAttivazione = _.cloneDeep(this.state.datiServiziAllaPersonaAttiviOInCorsoDiAttivazione);
        this.props.onChangeDatiAssistito("datiServiziAllaPersonaAttiviOInCorsoDiAttivazione", datiServiziAllaPersonaAttiviOInCorsoDiAttivazione);
    }

    addNewTipologiaTrasporto = () => {
        let dati = _.cloneDeep(this.state.datiServiziAllaPersonaAttiviOInCorsoDiAttivazione);
        let type = _.cloneDeep(defaultTipologiaTrasporto);
        type.id = uuid();
        dati.tipologiaTrasporto.push(type);
        this.setState({datiServiziAllaPersonaAttiviOInCorsoDiAttivazione: dati}, () => this.validazioneDati());
    }

    removeTipologiaTrasporto = (index) => {
        let dati = _.cloneDeep(this.state.datiServiziAllaPersonaAttiviOInCorsoDiAttivazione);
        dati.tipologiaTrasporto.splice(index, 1);
        this.setState({datiServiziAllaPersonaAttiviOInCorsoDiAttivazione: dati}, () => this.validazioneDati());
    }

    renderTipologiaTrasporto = (tipologiaTrasporto, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'TIPO_TRASP.SERVIZIALLAPERSONAATTIVIOINCORSODIATTIVAZIONE.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    id={"selectTipologiaTrasporto" + index}
                    options={OggettiUtili.tipologiaDiTrasporto}
                    value={tipologiaTrasporto.tipologiaTrasporto}
                    onChange={(elem) => this.handleAggiornaForm("tipologiaTrasporto", elem, index, "tipologiaTrasporto")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Tipologia di trasporto non esistente"}
                    fieldLabel={"Tipologia di trasporto"}
                    handleOnlyValue={true}
                    field={"tipologiaTrasporto"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
        );
    }

    renderBody = () => {
        const datiServiziAllaPersonaAttiviOInCorsoDiAttivazione = this.state.datiServiziAllaPersonaAttiviOInCorsoDiAttivazione;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'SERV_ALLA_PERS_ATT.SERVIZIALLAPERSONAATTIVIOINCORSODIATTIVAZIONE.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        id={"selectServiziAttiviOInCorso"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.serviziAttiviOInCorso}
                        onChange={(elem) => this.handleAggiornaForm("serviziAttiviOInCorso", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: servizi alla persona attivi o in corso di attivazione non esistente"}
                        fieldLabel={"Ci sono servizi alla persona attivi o in corso di attivazione?"}
                        handleOnlyValue={true}
                        field={"serviziAttiviOInCorso"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                {datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.serviziAttiviOInCorso && <>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'SERV_PER_IG_CASA.SERVIZIALLAPERSONAATTIVIOINCORSODIATTIVAZIONE.SITUAZIONESOCIALE'}
                            pageState={this.props.pageState}
                            id={"selectServiziIgieneCasa"}
                            options={OggettiUtili.serviziAllaPersonaAttiviOInCorsoDiAttivazione}
                            value={datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.serviziIgieneCasa}
                            onChange={(elem) => this.handleAggiornaForm("serviziIgieneCasa", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: servizi per l'igiene della casa non esistente"}
                            fieldLabel={"Servizi per l'igiene della casa"}
                            handleOnlyValue={true}
                            field={"serviziIgieneCasa"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />

                        <RoleBasedSelect
                            fieldId={'SERV_PER_IG_PERS.SERVIZIALLAPERSONAATTIVIOINCORSODIATTIVAZIONE.SITUAZIONESOCIALE'}
                            pageState={this.props.pageState}
                            id={"selectServiziIgienePersonale"}
                            options={OggettiUtili.serviziAllaPersonaAttiviOInCorsoDiAttivazione}
                            value={datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.serviziIgienePersonale}
                            onChange={(elem) => this.handleAggiornaForm("serviziIgienePersonale", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: servizi per l'igiene personale non esistente"}
                            fieldLabel={"Servizi per l'igiene personale"}
                            handleOnlyValue={true}
                            field={"serviziIgienePersonale"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>

                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'SERV_PASTO_DOM.SERVIZIALLAPERSONAATTIVIOINCORSODIATTIVAZIONE.SITUAZIONESOCIALE'}
                            pageState={this.props.pageState}
                            id={"selectServiziPastoDomicilio"}
                            options={OggettiUtili.servizoPerilPastoADomicilio}
                            value={datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.serviziPastoDomicilio}
                            onChange={(elem) => this.handleAggiornaForm("serviziPastoDomicilio", elem)}
                            isSearchable={false}
                            handleOnlyValue={true}
                            noOptionsMessage={() => "Errore: Servizio pasto domicilio non esistente"}
                            field={"serviziPastoDomicilio"}
                            fieldLabel={"Servizio per il pasto a domicilio"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />

                        <RoleBasedSelect
                            fieldId={'SERV_EDU_DOM.SERVIZIALLAPERSONAATTIVIOINCORSODIATTIVAZIONE.SITUAZIONESOCIALE'}
                            pageState={this.props.pageState}
                            id={"selectServiziEducativiDomicilio"}
                            options={OggettiUtili.serviziAllaPersonaAttiviOInCorsoDiAttivazione}
                            value={datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.serviziEducativiDomicilio}
                            onChange={(elem) => this.handleAggiornaForm("serviziEducativiDomicilio", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: servizi educativi a domicilio non esistente"}
                            fieldLabel={"Servizi educativi a domicilio"}
                            handleOnlyValue={true}
                            field={"serviziEducativiDomicilio"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>

                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'SERV_TELESOC.SERVIZIALLAPERSONAATTIVIOINCORSODIATTIVAZIONE.SITUAZIONESOCIALE'}
                            pageState={this.props.pageState}
                            id={"selectServizioTelesoccorso"}
                            options={OggettiUtili.serviziAllaPersonaAttiviOInCorsoDiAttivazione}
                            value={datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.servizioTelesoccorso}
                            onChange={(elem) => this.handleAggiornaForm("servizioTelesoccorso", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Servizio di Telesoccorso non esistente"}
                            fieldLabel={"Servizio di Telesoccorso"}
                            handleOnlyValue={true}
                            field={"servizioTelesoccorso"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />

                        <RoleBasedSelect
                            fieldId={'SERV_TRASP.SERVIZIALLAPERSONAATTIVIOINCORSODIATTIVAZIONE.SITUAZIONESOCIALE'}
                            pageState={this.props.pageState}
                            id={"selectServizioTrasporto"}
                            options={OggettiUtili.serviziAllaPersonaAttiviOInCorsoDiAttivazione}
                            value={datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.servizioTrasporto}
                            onChange={(elem) => this.handleAggiornaForm("servizioTrasporto", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Servizio di Trasporto non esistente"}
                            fieldLabel={"Servizio di Trasporto"}
                            handleOnlyValue={true}
                            field={"servizioTrasporto"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>

                    <SectionList
                        title={'Tipologia di trasporto'}
                        data={datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.tipologiaTrasporto}
                        renderSection={this.renderTipologiaTrasporto}
                        addNewSectionCallback={this.addNewTipologiaTrasporto}
                        removeSectionCallback={this.removeTipologiaTrasporto}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'TIPO_TRASP.SERVIZIALLAPERSONAATTIVIOINCORSODIATTIVAZIONE.SITUAZIONESOCIALE'}
                        field={'tipologiaTrasporto'}
                        parentJsonPath={"situazioneSociale.datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.tipologiaTrasporto"}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />

                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'INS_CENTRO_DIURNO.SERVIZIALLAPERSONAATTIVIOINCORSODIATTIVAZIONE.SITUAZIONESOCIALE'}
                            pageState={this.props.pageState}
                            id={"selectInserimentoCentroDiurno"}
                            options={OggettiUtili.serviziAllaPersonaAttiviOInCorsoDiAttivazione}
                            value={datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.inserimentoCentroDiurno}
                            onChange={(elem) => this.handleAggiornaForm("inserimentoCentroDiurno", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Inserimento in Centro diurno non esistente"}
                            fieldLabel={"Inserimento in Centro diurno"}
                            handleOnlyValue={true}
                            field={"inserimentoCentroDiurno"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>
                </>}
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiServiziAllaPersonaAttiviOInCorsoDiAttivazione"}
                title={"Servizi alla persona attivi o in corso di attivazione"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["situazioneSociale.datiServiziAllaPersonaAttiviOInCorsoDiAttivazione.tipologiaTrasporto"],
                    [defaultTipologiaTrasporto]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}
ServiziAllaPersonaAttiviOInCorsoDiAttivazione.propTypes = propTypes;

