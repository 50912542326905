import React from "react";
import PropTypes from "prop-types";
import styles from './AccordionSezioneRichiesta.module.css';
import {Col, Row} from "reactstrap";
import * as BsIcon from "react-bootstrap-icons";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import {spreadFieldsOrderMap} from "../roleBasedComponents/RoleBasedComponents";

const propTypes = {
    fieldsOrderMap: PropTypes.any,
    title: PropTypes.any,
    body: PropTypes.any,
    required: PropTypes.any,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.any,
    field: PropTypes.any,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    isOptional: PropTypes.bool,
    onHide: PropTypes.func,
    idAccordion: PropTypes.any,
    modeAccordion: PropTypes.bool,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultProps = {
    fieldsOrderMap: {},
    disabled: false,
    isOptional: false,
    idAccordion: "",
    modeAccordion: true,
    sezioneDatiRichiesta: false
}

export default class AccordionSezioneRichiesta extends React.Component {

    state = {
        openAccordion: this.props.openAccordion
    }

    toggleAccordion = () => {
        let openAccordion = this.state.openAccordion;
        this.setState({openAccordion: !openAccordion},
            () => this.props.onClickAccordion(this.props.field, !openAccordion))
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.openAccordion !== this.props.openAccordion) {
            this.setState({openAccordion: this.props.openAccordion})
        }
    }

    render() {
        const isClickable = true;
        const isDeletable = this.props.isOptional && !this.context.forceReadOnly;

        const childrenArray = React.Children.toArray(this.props.children)
            .filter(React.isValidElement)
            .map(child => spreadFieldsOrderMap(child, this.props.fieldsOrderMap));

        return (

            this.props.modeAccordion

                ? <div id={`${this.props.sezioneDatiRichiesta ? 'datiRichiestaSub' : ''}${this.props.idAccordion}`}
                    className="collapse-div"
                    style={!this.props.disabled ? {border: "none"} : {border: "none", pointerEvents: "none"}}>
                    <div className="collapse-header mx-3 py-2"
                        style={{borderBottom: this.state.openAccordion ? "1px solid #e3e4e6" : "none", borderTop: "1px solid #e3e4e6"}}>
                        <Row>
                            {isDeletable &&
                                <Col xs={"auto"} className={"mb-2 align-self-center"}>
                                    <button className="bg-transparent"
                                        style={{border: "none", outline: "none"}}
                                        onClick={this.props.onHide}>
                                        <BsIcon.Trash3 color={"#176A65"} size={24}/>
                                    </button>
                                </Col>}
                            <Col>
                                <button
                                    id={this.props.field}
                                    style={{
                                        textDecoration: "none",
                                        outline: "none",
                                        cursor: !isClickable ? "default" : null,
                                        color: this.props.disabled ? "#D6D6D6" : "#7AB8F5",
                                        border: 0
                                    }}
                                    className={isDeletable ? "pl-0" : null}
                                    data-toggle="collapse"
                                    aria-expanded={this.state.openAccordion}
                                    onClick={() => isClickable && this.toggleAccordion()}>
                                    <Row>
                                        <Col xs={"auto"} className="mr-auto">
                                            <label className={styles.titleSezione}>{this.props.title}</label>
                                        </Col>
                                        {this.props.required &&
                                            <Col xs={"auto"} className="mr-5">
                                                <label className={styles.requiredFields}>*Campo obbligatorio</label>
                                            </Col>}
                                    </Row>
                                </button>
                            </Col>
                        </Row>
                    </div>
                    <div className={"collapse" + (this.state.openAccordion ? " show " : "")}>
                        <div className={styles.sezioneAccordionBody.concat(" collapse-body mx-3 my-2 p-2")}>
                            {childrenArray}
                        </div>
                    </div>
                </div> :
                <div id={`${this.props.sezioneDatiRichiesta ? 'datiRichiestaSub' : ''}${this.props.idAccordion}`}
                className={styles.sezioneAccordionBody.concat(" mx-3 my-2 p-2 ")}
                    style={!this.props.disabled ? {border: "none"} : {border: "none", pointerEvents: "none"}}>
                    <Row>
                        <Col xs={"auto"} className="mr-auto">
                            <b className={styles.titleSezioneNotAccordion}>{this.props.title}</b>
                        </Col>
                        {this.props.required &&
                            <Col xs={"auto"} className="mr-4">
                                <label className={styles.requiredFields}>*Campo obbligatorio</label>
                            </Col>}
                    </Row>
                    {childrenArray}
                </div> 
        );
    }
}

AccordionSezioneRichiesta.propTypes = propTypes;
AccordionSezioneRichiesta.defaultProps = defaultProps;
AccordionSezioneRichiesta.contextType = RoleBasedContext;
