import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import Box from "../../generalComponents/box/Box";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    torace: PropTypes.any,
    showAccordion: PropTypes.bool,
    showSectionMurme: PropTypes.bool,
    accordionTitle: PropTypes.string,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultAlterazine = {
    id: null,
    tipoAlterazione: null,
    sede: null
}

const defaultRumore = {
    id: null,
    rumore: null,
    sede: null
}

const defaultEspettorazioniAspetto = {
    id: null,
    aspetto: null
}

const nomeAccordion = "TORACE";

export default class Torace extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.torace)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.torace, this.props.torace))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.torace);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({dati}) => {
            if (index === null && subField === null) {
                dati[field] = elem;
            } else {
                dati[field][index][subField] = elem;
            }

            /* Alterazioni */
            if (!dati.alterazione) {
                dati.listaAlterazioni = [];
                dati.alterazioneCondizioneRilevata = null;
            }

            /* Dispnea */
            if (!dati.dispnea) {
                dati.dispneaCondizioneRilevata = null;
            }

            /* Murmure */
            if (dati.murmureVescicolare !== "02") {
                dati.murmureVescicolareAlterazione = null;
            }
            if (dati.murmureVescicolare !== "02" && dati.listaRumori?.length === 0) {
                dati.murmureVescicolareCondizioneRilevata = null;
            }

            /* Percussione */
            if (dati.percussione !== "02") {
                dati.percussioneCondizioneRilevata = null;
            }

            /* Espettorazioni */
            if (!dati.espettorazioni) {
                dati.espettorazioniAspetto = [];
                dati.espettorazioniQuantita = null;
                dati.espettorazioniCondizioneRilevata = null;
            }

            /* Spo2 */
            if (parseInt(dati.spo2, 10) >= 90) {
                dati.spo2CondizioneRilevata = null;
            }

            return {dati};

        }, this.validazioneDati);
    }

    validazioneDati = () => {
        let dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("torace", dati);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneTorace", campiObbligatoriRimanenti);
    }

    /* Alterazioni */

    addNewAlterazione = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultAlterazine);
        type.id = uuid();
        dati.listaAlterazioni.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeAlterazione = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.listaAlterazioni.splice(index, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderAlterazione = (alterazione, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedICD9Input
                        fieldId={'M_TIPO_ALT.TORACE.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_TIPO_ALT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        id={"autocompleteTipoAlterazione" + index}
                        keyField={"codice"}
                        descriptionField={"descrizione"}
                        onChange={(field, elem) => this.handleAggiornaForm("listaAlterazioni", elem.target.value, index, field)}
                        searchFromStart={false}
                        value={alterazione.tipoAlterazione}
                        field={"tipoAlterazione"}
                        placeholder={"ICD9-CM - Descrizione"}
                        fieldLabel={"Tipologia"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                    <RoleBasedInput
                        fieldId={'M_SEDE_ALT.TORACE.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_SEDE_ALT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci sede"}
                        field={"sede"}
                        onChange={(field, elem) => this.handleAggiornaForm("listaAlterazioni", elem.target.value, index, field)}
                        id={"inputAlterazioneSede"}
                        value={alterazione.sede}
                        fieldLabel={"Sede/i"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                </FieldsRow>
            </>
        );
    }

    /* Murmure */

    addNewRumore = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultRumore);
        type.id = uuid();
        dati.listaRumori.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeRumore = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.listaRumori.splice(index, 1);
        if (dati.murmureVescicolare !== "02" && dati.listaRumori?.length === 0) {
            dati.murmureVescicolareCondizioneRilevata = null;
            dati.murmureVescicolareDaAttenzionare = null;
        }
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderRumore = (rumore, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_RUM_AGG.TORACE.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_RUM_AGG.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        id={"selectRumore" + index}
                        options={OggettiUtili.rumoriAggiunti}
                        value={rumore.rumore}
                        onChange={(elem) => this.handleAggiornaForm("listaRumori", elem, index, "rumore")}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Rumore non esistente"}
                        fieldLabel={"Rumori aggiunti"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"rumore"}
                    />
                    <RoleBasedInput
                        fieldId={'M_SEDE_MURM.TORACE.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_SEDE_MURM.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci sede/i"}
                        field={"sede"}
                        onChange={(field, elem) => this.handleAggiornaForm("listaRumori", elem.target.value, index, "sede")}
                        id={"murmureVescicolareSede" + index}
                        value={rumore.sede}
                        fieldLabel={"Sede/i"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                </FieldsRow>
            </>
        );
    }

    /* Espettorazioni aspetto */

    addNewEspettorazioniAspetto = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultEspettorazioniAspetto);
        type.id = uuid();
        dati.espettorazioniAspetto.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeEspettorazioniAspetto = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.espettorazioniAspetto.splice(index, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderEspettorazioniAspetto = (espettorazione, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_ESPET_SECR_BRONCH_ASP.TORACE.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_ESPET_SECR_BRONCH_ASP.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        id={"selectEspettorazioniAspetto"}
                        options={OggettiUtili.espettorazioniAspetto}
                        value={espettorazione.aspetto}
                        onChange={(elem) => this.handleAggiornaForm("espettorazioniAspetto", elem, index, "aspetto")}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Aspetto non esistente"}
                        fieldLabel={"Espettorazioni/Secrezioni bronchiali - Aspetto"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"aspetto"}
                    />
                </FieldsRow>
            </>
        );
    }

    handleDispositiviArrayChange = (array, field) => {
        let data = _.cloneDeep(this.state.dati);
        data[field] = array;
        this.setState({dati: data}, () => {
            this.validazioneDati();
            this.forceUpdate();
        });
    }

    renderBodySezione() {
        let dati = this.state.dati;
        return (
            <>
                { /* Alterazioni */
                    <Box key={"boxAlterazioni" + nomeAccordion} titleBox={"Alterazioni"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_PRES_ALT.TORACE.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_PRES_ALT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                pageState={this.props.pageState}
                                id={"selectAlterazione"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.alterazione}
                                onChange={(elem) => this.handleAggiornaForm("alterazione", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Alterazione non esistente"}
                                fieldLabel={"Presenza di alterazioni"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"alterazione"}
                            />
                        </FieldsRow>

                        {dati.alterazione &&
                            <SectionList
                                title={'Alterazione'}
                                data={dati.listaAlterazioni}
                                renderSection={this.renderAlterazione}
                                addNewSectionCallback={this.addNewAlterazione}
                                removeSectionCallback={this.removeAlterazione}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                pageState={this.props.pageState}
                                keyFieldId={'M_TIPO_ALT.TORACE.ESAMEOBIETTIVO'}
                                infermiereKeyFieldId={'I_TIPO_ALT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                field={'listaAlterazioni'}
                                parentJsonPath={"esameObiettivo.torace.listaAlterazioni"}
                            />}

                        <DispositiviPresenti
                            handleArrayChange={this.handleDispositiviArrayChange}
                            dispositiviPresenti={dati.listaDispositiviAlterazioni}
                            pageState={this.props.pageState}
                            fieldId={'M_DISP_ALT.TORACE.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_DISP_ALT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            field={"listaDispositiviAlterazioni"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            parentJsonPath={"esameObiettivo.torace.listaDispositiviAlterazioni"}
                        />

                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_FREQ_RESP_ALT.TORACE.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_FREQ_RESP_ALT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci frequenza respiratoria"}
                                field={"alterazioneFrequenzaRespiratoria"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"inputAlterazioneFrequenzaRespiratoria"}
                                value={dati.alterazioneFrequenzaRespiratoria}
                                fieldLabel={"Frequenza respiratoria (Atti/min)"}
                                type={"number"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>

                        <CondizioneRilevataSection
                            ambito={'TORACE ALTERAZIONI'}
                            appendRolePrefix={true}
                            fieldsSuffix={'ALT'}
                            parentFieldId={'TORACE.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("alterazioneCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.alterazioneCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("alterazioneDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.alterazioneDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'alterazione'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("alterazionePropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.alterazionePropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.alterazione}
                        />
                    </Box>
                }

                { /* Dispnea */
                    <Box key={"boxDispnea" + nomeAccordion} titleBox={"Dispnea"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'PRES_DISPNEA.TORACE.ESAMEOBIETTIVO'}
                                pageState={this.props.pageState}
                                id={"selectAlterazione"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.dispnea}
                                onChange={(elem) => this.handleAggiornaForm("dispnea", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Dispnea non esistente"}
                                fieldLabel={"Presenza di Dispnea"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"dispnea"}
                            />
                        </FieldsRow>

                        <CondizioneRilevataSection
                            ambito={'TORACE DISPNEA'}
                            fieldsSuffix={'PRES_DISPNEA'}
                            parentFieldId={'TORACE.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("dispneaCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.dispneaCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("dispneaDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.dispneaDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'dispnea'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("dispneaPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.dispneaPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.dispnea}
                        />
                    </Box>
                }

                { /* Murmure */
                    <Box key={"boxMurmure" + nomeAccordion} titleBox={"Murmure vescicolare"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_MURM_VESC.TORACE.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_MURM_VESC.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                pageState={this.props.pageState}
                                id={"selectMurmureVescicolare"}
                                options={OggettiUtili.murmureVescicolare}
                                value={dati.murmureVescicolare}
                                onChange={(elem) => this.handleAggiornaForm("murmureVescicolare", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Murmure vescicolare non esistente"}
                                fieldLabel={"Presenza di murmure vescicolare"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"murmureVescicolare"}
                            />
                            {dati.murmureVescicolare === "02" &&
                                <RoleBasedInput
                                    fieldId={'M_MURM_VESC_ALTERAZIONE.TORACE.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_MURM_VESC_ALTERAZIONE.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci alterazione"}
                                    field={"murmureVescicolareAlterazione"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                    id={"inputAlterazioneFrequenzaRespiratoria"}
                                    value={dati.murmureVescicolareAlterazione}
                                    fieldLabel={"Alterazione"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />}
                        </FieldsRow>

                        <SectionList
                            title={'Rumore'}
                            data={dati.listaRumori}
                            renderSection={this.renderRumore}
                            addNewSectionCallback={this.addNewRumore}
                            removeSectionCallback={this.removeRumore}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            pageState={this.props.pageState}
                            keyFieldId={'M_RUM_AGG.TORACE.ESAMEOBIETTIVO'}
                            infermiereKeyFieldId={'I_RUM_AGG.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            field={'listaRumori'}
                            parentJsonPath={"esameObiettivo.torace.listaRumori"}
                        />

                        <CondizioneRilevataSection
                            ambito={'TORACE MURMURE VESCICOLARE'}
                            appendRolePrefix={true}
                            fieldsSuffix={'MURM'}
                            parentFieldId={'TORACE.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("murmureVescicolareCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.murmureVescicolareCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("murmureVescicolareDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.murmureVescicolareDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'murmureVescicolare'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("murmureVescicolarePropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.murmureVescicolarePropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.murmureVescicolare === "02" || dati.listaRumori?.length > 0}
                        />
                    </Box>
                }

                { /* Percussione */
                    <Box key={"boxPercussione" + nomeAccordion} titleBox={"Percussione"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_PERCUSSIONE.TORACE.ESAMEOBIETTIVO'}
                                pageState={this.props.pageState}
                                id={"selectPercussione"}
                                options={OggettiUtili.percussione}
                                value={dati.percussione}
                                onChange={(elem) => this.handleAggiornaForm("percussione", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Percussione non esistente"}
                                fieldLabel={"Percussione"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"percussione"}
                            />
                            <RoleBasedInput
                                fieldId={'M_PERCUSSIONE_ALTERAZIONE.TORACE.ESAMEOBIETTIVO'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci alterazione"}
                                field={"percussioneAlterazione"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"inputPercussioneAlterazione"}
                                value={dati.percussioneAlterazione}
                                fieldLabel={"Alterazione"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>

                        <CondizioneRilevataSection
                            ambito={'TORACE PERCUSSIONE'}
                            fieldsPrefix={'M'}
                            fieldsSuffix={'PERCUSS'}
                            parentFieldId={'TORACE.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("percussioneCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.percussioneCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("percussioneDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.percussioneDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'percussione'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("percussionePropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.percussionePropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.percussione === "02"}
                        />
                    </Box>
                }

                { /* Espettorazioni */
                    <Box key={"boxEspettorazioni" + nomeAccordion} titleBox={"Espettorazioni"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_ESPET_SECR_BRONCH.TORACE.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_ESPET_SECR_BRONCH.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                pageState={this.props.pageState}
                                id={"selectEspettorazioni"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.espettorazioni}
                                onChange={(elem) => this.handleAggiornaForm("espettorazioni", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Espettorazioni non esistente"}
                                fieldLabel={"Espettorazioni/Secrezioni bronchiali"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"espettorazioni"}
                            />
                        </FieldsRow>

                        {dati.espettorazioni &&
                            <>
                                <FieldsRow>
                                    <RoleBasedSelect
                                        fieldId={'M_ESPET_SECR_BRONCH_QUANT.TORACE.ESAMEOBIETTIVO'}
                                        infermiereFieldId={'I_ESPET_SECR_BRONCH_QUANT.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                        pageState={this.props.pageState}
                                        id={"selectEspettorazioniQuantita"}
                                        options={OggettiUtili.espettorazioniQuantita}
                                        value={dati.espettorazioniQuantita}
                                        onChange={(elem) => this.handleAggiornaForm("espettorazioniQuantita", elem)}
                                        isSearchable={false}
                                        noOptionsMessage={() => "Errore: Quantità non esistente"}
                                        fieldLabel={"Espettorazioni/Secrezioni bronchiali - Quantità"}
                                        forceReadOnly={this.props.forceReadOnly}
                                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                        disableDoubleInput={this.props.disableDoubleInput}
                                        hideUnsetValues={this.props.hideUnsetValues}
                                        handleOnlyValue={true}
                                        field={"espettorazioniQuantita"}
                                    />
                                </FieldsRow>

                                <SectionList
                                    title={'Aspetto'}
                                    data={dati.espettorazioniAspetto}
                                    renderSection={this.renderEspettorazioniAspetto}
                                    addNewSectionCallback={this.addNewEspettorazioniAspetto}
                                    removeSectionCallback={this.removeEspettorazioniAspetto}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    pageState={this.props.pageState}
                                    keyFieldId={'M_ESPET_SECR_BRONCH_ASP.TORACE.ESAMEOBIETTIVO'}
                                    infermiereKeyFieldId={'I_ESPET_SECR_BRONCH_ASP.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                    field={'espettorazioniAspetto'}
                                    parentJsonPath={"esameObiettivo.torace.espettorazioniAspetto"}
                                />
                            </>}

                        <CondizioneRilevataSection
                            ambito={'TORACE ESPETTORAZIONI'}
                            appendRolePrefix={true}
                            fieldsPrefix={'ESPET_SECR_BRONCH'}
                            parentFieldId={'TORACE.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("espettorazioniCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.espettorazioniCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("espettorazioniDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.espettorazioniDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'espettorazioni'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("espettorazioniPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.espettorazioniPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.espettorazioni}
                        />
                    </Box>
                }

                { /* SpO2 */
                    <Box key={"boxSpo2" + nomeAccordion} titleBox={"SpO2"}>
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'M_SPO2.TORACE.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_SPO2.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci SpO2"}
                                field={"spo2"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"inputSpo2"}
                                value={dati.spo2}
                                fieldLabel={"SpO2 (%)"}
                                type={"number"}
                                min={"0"}
                                max={"100"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />
                        </FieldsRow>

                        <CondizioneRilevataSection
                            ambito={'TORACE SPO2'}
                            fieldsPrefix={'SPO2'}
                            appendRolePrefix={true}
                            parentFieldId={'TORACE.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("spo2CondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.spo2CondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("spo2DaAttenzionare", elem)}
                            valueDaAttenzionare={dati.spo2DaAttenzionare}
                            showAutonomia={true}
                            onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("spo2autonomiaGestionePaziente", elem)}
                            valueAutonomiaPaziente={dati.spo2autonomiaGestionePaziente}
                            onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("spo2autonomiaGestioneCaregiver", elem)}
                            valueAutonomiaCaregiver={dati.spo2autonomiaGestioneCaregiver}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'spo2'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("spo2PropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.spo2PropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={parseInt(dati.spo2, 10) < 90}
                        />
                    </Box>
                }
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"torace"}
                    title={this.props.accordionTitle}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.torace.listaAlterazioni", "esameObiettivo.torace.listaDispositiviAlterazioni",
                            "esameObiettivo.torace.listaRumori", "esameObiettivo.torace.espettorazioniAspetto",
                            "esameObiettivo.torace.propostaAttivitaSuggerimenti"],
                        [defaultAlterazine, defaultDispositiviPresenti, defaultRumore,
                            defaultEspettorazioniAspetto, defaultProposteAttivitaSuggerimenti]
                    )}
                    accordionReadOnly={this.props.accordionReadOnly}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}
Torace.propTypes = propTypes;

Torace.defaultProps = {
    showAccordion: false,
    accordionTitle: "Valutazione del sistema respiratorio",
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false,
}
