import React, {Component, Fragment} from "react";
import {Container} from "reactstrap";
import PropTypes from "prop-types";
import TabellaStoricoRichieste from "./tabellaStoricoRichieste/TabellaStoricoRichieste";
import _ from "lodash";
import {Context} from "../../utils/Context";
import pageTexts from "../../utils/message/pageTexts.json";
import Breadcrumb from "../breadcrumb/Breadcrumb";

const propTypes = {
    id: PropTypes.string
}

const defaultFiltriRichiesta = {
    codiceFiscale: "",
    dataRichiestaDa: "",
    dataRichiestaA: "",
    dataValutazioneDa: "",
    dataValutazioneA: "",
    dataValutazioneProfessionaleDa: "",
    dataValutazioneProfessionaleA: "",
    statoValidazioneMultidimensionale: "",
    statoValutazioneProfessionale: "",
    statoRichiesta: "",
    statoFormalizzazione: ""
}
const defaultFiltriAssistito = {
    cognome: "",
    nome: ""
}

class StoricoRichieste extends Component {
    state = {
        filtri: {
            filtriAssistito: _.cloneDeep(defaultFiltriAssistito),
            filtriRichiesta: _.cloneDeep(defaultFiltriRichiesta),
        },
        richieste: {},
    };

    impostaRichieste = (richieste) => {
        this.setState({ richieste: richieste })
    }

    impostaFiltri = (filtriAssistito, filtriRichiesta) => {
        let filtri = _.cloneDeep(this.state.filtri);
        filtri.filtriAssistito = filtriAssistito;
        filtri.filtriRichiesta = filtriRichiesta;
        this.setState({ filtri: filtri })
    }

    eliminaFiltro(identificativoFiltri, filtro) {
        let filtri = _.cloneDeep(this.state.filtri);
        filtri[identificativoFiltri][filtro] = "";
        this.setState({ filtri: filtri })
    }

    render() {
        let state = _.cloneDeep(this.state);

        return (
            <Fragment>
                <Breadcrumb path={['Home', 'Storico richieste']} profileAction={true}/>
                <Container fluid className={"px-5 position-relative"}>
                    <div className={"row"}>
                        <div className={"col-12 position-relative pt-4"}>
                            <h1>{pageTexts.storicoRichieste.header}</h1>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <span>{pageTexts.storicoRichieste.headerDescription}</span>
                        </div>
                    </div>
                    <TabellaStoricoRichieste
                        impostaFiltri={this.impostaFiltri}
                        impostaRichieste={this.impostaRichieste}
                        eliminaFiltro={(identificativoFiltri, filtro) => this.eliminaFiltro(identificativoFiltri, filtro)}
                        filtri={state.filtri}
                        info={pageTexts.storicoRichieste.headerTable}
                    />
                </Container>
            </Fragment>
        )
    }
}

StoricoRichieste.propTypes = propTypes;
StoricoRichieste.contextType = Context;

export default StoricoRichieste;
