import React, {Component} from "react";
import {mostraSpinner, nascondiSpinner} from "../../App";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import routepath from "../../utils/route/route-path.json";
import {map} from "rxjs/operators";
import AnagrafeService from "../../service/AnagrafeService";
import Breadcrumb from '../breadcrumb/Breadcrumb';
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import {SessioneUtente} from "web-client-archetype";
import SoggettoRichiedenteCureDomiciliari
    from "./soggettoRichiedenteCureDomiciliari/SoggettoRichiedenteCureDomiciliari";
import * as _ from "lodash";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import Utils from "../../utils/Utils";
import Ruolo from "./ruolo/Ruolo";
import SedeLavorativa from "./sedeLavorativa/SedeLavorativa";
import ContattiRichiedente from "./contattiRichiedente/ContattiRichiedente";
import DisponibilitaContatto from "./disponibilitaContatto/DisponibilitaContatto";
import VersionedAccordion from "../VersionedAccordion";
import enumPaginaCompila from "../../enum/enumPaginaCompila";
import ToponomasticaUtils from "../../utils/ToponomasticaUtils";
import RichiestaADIService from "../../service/RichiestaADIService";
import * as $ from "jquery";
import ButtonsBoxStick from "../buttonsBoxStick/buttonsBoxStick";
import ModalAnagraficaRichiedente from "./ModalAnagraficaRichiedente";
import routePath from "../../utils/route/route-path";

const mandatoryFields = ['cognome', 'nome', 'identificativoUtente', 'ruolo',
    'contatti:TELEFONO:valore|contatti:CELLULARE:valore', 'contatti:EMAIL:valore',
    'contatti:TELEFONO_SEDE_LAVORATIVA:valore|contatti:CELLULARE_SEDE_LAVORATIVA:valore'];

export const getCountMandatoryFieldsMissing = (persona, pageState) => {
    let missingFieldsCount = 0;

    if (Utils.isDimissioneProtetta(pageState)) {
        mandatoryFields.push('indirizzi:SEDE_LAVORATIVA:ospedale');
        mandatoryFields.push('indirizzi:SEDE_LAVORATIVA:unitaOperativa');
    }

    mandatoryFields.forEach(e => {
        let found = false;

        for (const e2 of e.split('|')) {
            let val;
            const idx1 = e2.indexOf(':');
            if (idx1 !== -1) {
                const idx2 = e2.indexOf(':', idx1 + 1);
                const type = e2.substring(idx1 + 1, idx2);
                const targetField = e2.substring(idx2 + 1)
                val = persona[e2.substring(0, idx1)]?.find(el => el.tipo === type)?.[targetField];
            } else {
                val = persona[e2];
            }

            if (val?.trim()) {
                found = true;
                break;
            }
        }

        if (!found) missingFieldsCount++;
    });

    return missingFieldsCount;
}

export default class AnagrafeRichiedente extends Component {

    state = {
        persona: null,
        editableFields: null,
        error: false,
        backTo: null,
        missingFieldsCount: 0,
        anagReadOnly: false,
        apriModaleConferma: false,
        redirectTo: null,
        accordion: {
            openAccordionSoggettoRichiedenteCureDomiciliari: true,
            openAccordionRuolo: true,
            openAccordionSedeLavorativa: true,
            openAccordionContattiMedicoRichiedente: true,
            openAccordionDisponibilitaContatto: true
        }
    }

    listaComuni = [];
    listaProvince = [];

    onClickAccordion = (accordionName, isOpen) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[accordionName] = isOpen;
        this.setState({accordion});
    }

    componentDidMount() {
        this.listaProvince = ToponomasticaUtils.getProvince();
        this.listaComuni = ToponomasticaUtils.getComuni();
        mostraSpinner();
        AnagrafeService.getPersone({
            vista: 'totale',
            filtro: `id_persona~~${this.props.idPersona}`
        }).pipe(map((data) => {
            if (!(data && data.length === 1)) throw new Error('data unavailable');
            return data[0];
        })).subscribe({
            next: data => {
                const persona = _.cloneDeep(data);
                delete persona.mascheraModificabilita;

                this.setState({
                    persona,
                    editableFields: this.getEditableFields(data),
                    anagReadOnly: this.props.readOnly || SessioneUtente.getInstance().idUtente !== data.identificativoUtente
                }, () => {
                    this.countMandatoryFieldsMissing();
                    nascondiSpinner();
                });
            },
            error: () => this.setState({error: true}, nascondiSpinner)
        });
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        // Duplicato perchè prima vengono eliminati i sottoAccordion vuoti e poi i relativi Accordion vuoti
        $('div.collapse-body:empty').closest('div.collapse-div').remove();
        $('div.collapse-body:empty').closest('div.collapse-div').remove();

        if (this.state.anagReadOnly) $('label:contains("*Campo obbligatorio")').remove();
    }

    // TODO to be removed
    getEditableFields = data => {
        const editableFields = _.cloneDeep(data.mascheraModificabilita);
        for (const field in data) {
            if (field === 'mascheraModificabilita') continue;

            let val = data[field];
            if (typeof val === 'string') val = val.trim();
            if (Array.isArray(val)) {
                editableFields[field] = editableFields[field].map(elementoMaschera => {
                    let valoreTrovato = val.find(el => el.idElemento === elementoMaschera.idElemento);
                    elementoMaschera.tipo = valoreTrovato?.tipo || elementoMaschera?.tipo;
                    return elementoMaschera;
                })
            }
            else {
                if (!editableFields[field] && (val == null || val === '')) {
                    editableFields[field] = true;
                }
            }
        }
        return editableFields;
    }

    countMandatoryFieldsMissing = () => {
        this.setState({missingFieldsCount: getCountMandatoryFieldsMissing(this.state.persona, this.props.pageState)});
    }

    onChangeField = (field, value) => {
        const persona = _.cloneDeep(this.state.persona);
        persona[field] = value;
        this.setState({persona}, this.countMandatoryFieldsMissing);
    }

    onChangeContactField = (field, contactType, value) => {
        this.onChangeSubObject(field, contactType, 'valore', value);
    }

    onChangeAddressField = (field, addressType, addressField, value) => {
        this.onChangeSubObject(field, addressType, addressField, value);
    }

    onChangeSubObject = (field, type, subField, value) => {
        const persona = _.cloneDeep(this.state.persona);
        let subObject = persona[field].find(e => e.tipo === type);
        if (!subObject) {
            subObject = {tipo: type};
            persona[field].push(subObject);
        }

        if (type === "SEDE_LAVORATIVA" && subField === "comune") {
            subObject.provincia = value ? this.listaComuni.find(c => c.codice === value)?.codiceProvincia : null;
        }

        if (type === "SEDE_LAVORATIVA" && subField === "provincia" && !value) {
            subObject.comune = null;
        }

        subObject[subField] = value;
        this.setState({persona}, this.countMandatoryFieldsMissing);
    }

    doSave() {
        if (!Utils.isObjectNull(this.state.persona)) {
            mostraSpinner();
            AnagrafeService.modificaPersona(this.props.idPersona, _.cloneDeep(this.state.persona))
                .pipe(map(response => {
                    if (response?.msg === "ERRORE" && response?.data && (response?.data?.errore || response?.data?.error)) {
                        nascondiSpinner();
                        throw Object.assign(new Error(response?.data?.errore ?? response?.data?.message), {status: response?.data?.codice ?? response?.status});
                    }else{
                        this.setState({ apriModaleConferma: true })
                    }
                }))
                .subscribe({
                    next: () => {
                        RichiestaADIService.aggiornaDatiRichiedente(this.props.uuidRichiesta, this.state.persona, this.props.pageState, this.state.anagReadOnly)
                            .pipe(map(response => {
                                if (response?.msg === "ERRORE" && response?.data && (response?.data?.errore || response?.data?.error)) {
                                    nascondiSpinner();
                                    throw Object.assign(new Error(response?.data?.errore ?? response?.data?.message), {status: response?.data?.codice ?? response?.status});
                                }
                            })).subscribe({
                            error: err => {
                                Utils.toasterFunction({
                                    status: err.status ?? '',
                                    type: 'critical',
                                    text: err.text
                                })
                            },
                            complete: nascondiSpinner
                        });
                    },
                    error: err => {
                        Utils.toasterFunction({
                            status: err.status ?? '',
                            type: 'critical',
                            text: err.text
                        })
                    },
                    complete: nascondiSpinner
                });
        }
    }

    goBack() {
        this.setState({backTo: this.props.insRichiestaPathname});
    }

    redirectToPaginaDiCortesia = () => {
        return <Redirect to={{
            pathname: routepath.not_found,
            state: {title: "Errore", motivation: "Errore recupero anagrafica medico richiedente"}
        }}/>;
    }

    renderButtons() {
        return <ButtonsBoxStick
            parentClass="row"
            btnList={[
                {
                    id: "backBtn",
                    text: "Indietro",
                    className: "btnGreenOutline",
                    isvisible: !this.state.anagReadOnly,
                    onclickFunction: this.goBack.bind(this),
                    aling: "start"
                },
                {
                    id: "confirmBtn",
                    text: "Conferma dati",
                    className: "btnGreenOutline",
                    isvisible: !this.state.anagReadOnly,
                    isDisabled: this.state.missingFieldsCount > 0,
                    onclickFunction: this.doSave.bind(this),
                    aling: "right"
                }
            ]}
        />
    }

    renderAccordions() {
        return <>
            <VersionedAccordion
                accordion={SoggettoRichiedenteCureDomiciliari}
                datiMedico={this.state.persona}
                editableFields={this.state.editableFields}
                openAccordion={this.state.accordion.openAccordionSoggettoRichiedenteCureDomiciliari}
                onClickAccordion={this.onClickAccordion}
                onChangeDatiMedico={this.onChangeField}
                field={"openAccordionSoggettoRichiedenteCureDomiciliari"}
                pageState={this.props.pageState}/>

            <VersionedAccordion
                accordion={Ruolo}
                datiMedico={this.state.persona}
                editableFields={this.state.editableFields}
                openAccordion={this.state.accordion.openAccordionRuolo}
                onClickAccordion={this.onClickAccordion}
                onChangeDatiMedico={this.onChangeField}
                field={"openAccordionRuolo"}
                pageState={this.props.pageState}/>

            <VersionedAccordion
                accordion={SedeLavorativa}
                datiMedico={this.state.persona}
                editableFields={this.state.editableFields}
                openAccordion={this.state.accordion.openAccordionSedeLavorativa}
                onClickAccordion={this.onClickAccordion}
                onChangeContatti={this.onChangeContactField}
                onChangeIndirizzi={this.onChangeAddressField}
                onChangeDatiMedico={this.onChangeField}
                listaComuni={this.listaComuni}
                listaProvince={this.listaProvince}
                field={"openAccordionSedeLavorativa"}
                pageState={this.props.pageState}/>

            <VersionedAccordion
                accordion={ContattiRichiedente}
                datiMedico={this.state.persona}
                editableFields={this.state.editableFields}
                openAccordion={this.state.accordion.openAccordionContattiMedicoRichiedente}
                onClickAccordion={this.onClickAccordion}
                onChangeContatti={this.onChangeContactField}
                field={"openAccordionContattiMedicoRichiedente"}
                pageState={this.props.pageState}/>

            <VersionedAccordion
                accordion={DisponibilitaContatto}
                datiMedico={this.state.persona}
                editableFields={this.state.editableFields}
                openAccordion={this.state.accordion.openAccordionDisponibilitaContatto}
                onClickAccordion={this.onClickAccordion}
                field={"openAccordionDisponibilitaContatto"}
                pageState={this.props.pageState}
                onChangeDatiMedico={this.onChangeField}/>
        </>;
    }

    render() {
        if (this.state.error) return this.redirectToPaginaDiCortesia();
        if (this.state.backTo) {
            return <Redirect to={{
                pathname: this.props.insRichiestaPathname,
                uuidRichiesta: this.props.uuidRichiesta,
                pageState: this.props.pageState,
                pageName: this.props.pageName,
                tipologia: this.props.richiesta?.tipologia,
                idPersona:this.props.idPersona,
                stato: this.props.richiesta?.stato,
                firstPageName: Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)
                    ? enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE
                    : Utils.isStateFormalizzazione(this.props.pageState)
                        ? enumPaginaCompila.COMPILA_FORMALIZZAZIONE
                        : Utils.isStateChiusuraPreDialogo(this.props.pageState)
                            ? enumPaginaCompila.COMPILA_CHIUDI_PREDIALOGO
                            : enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE,
                pageNumber: this.props.pageNumber,
                showOnlyRequiredFields: this.props.showOnlyRequiredFields,
                previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields,
                richiesta: this.state.backTo.includes(routepath.stato_valutazione_componenti_team) ? this.props.richiesta : null,
                showMultiCompilationPages: !!this.props.showMultiCompilationPages
            }}/>;
        }
      
        let bcIntermediate;
        switch (this.props.pageState) {
            case 'R':
            case 'DP-PDA':
                bcIntermediate = 'Dettaglio richiesta';
                break;
            case 'V':
            case 'DP-PDV':
            case 'DP-V':
                bcIntermediate = 'Dettaglio valutazione';
                break;
            case 'F':
            case 'DP-PDC':
            case 'DP-F':
                bcIntermediate = 'Compila formalizzazione';
                break;
        }

        let breadcrumbPath = ['Home', bcIntermediate, 'Anagrafica Medico richiedente'];
        if (this.props.profileAction) breadcrumbPath = ['Home', 'La tua anagrafica'];

        return <>
            {this.state.apriModaleConferma && this.renderModale()}
            
            <Breadcrumb path={breadcrumbPath}
                        richiesta={!this.props.profileAction ? this.props.richiesta : null}
                        profileAction={this.props.profileAction}/>
            {this.state.persona &&
                <RoleBasedContext.Provider
                    value={{
                        forceReadOnly: this.state.anagReadOnly,
                        hideUnsetValues: this.props.hideUnsetValues,
                        scrollToHead: true,
                        requestVersion: this.props.richiesta?.versione,
                        showOnlyRequiredFields: this.props.showOnlyRequiredFields
                    }}>

                    <div className={"mx-5"}>
                        <AccordionAvvioRichiesta
                            idAccordion={"anagrafeSoggettoRichiedente"}
                            title={Utils.isStateRichiestaPreDialogo(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)
                                ? "Anagrafe soggetto richiedente in dimissione protetta"
                                : "Anagrafe Soggetto Richiedente"}
                            openAccordion={true}
                            onClickAccordion={this.onClickAccordion}
                            numberOfRequiredFieldsMissing={this.state.missingFieldsCount}
                            pageState={this.props.pageState}>
                            {this.renderAccordions()}
                        </AccordionAvvioRichiesta>
                    </div>
                </RoleBasedContext.Provider>}
            {this.renderButtons()}
        </>;
    }

    handleRedirectModale = () => {
        
        if (this.props.richiesta !== undefined) {
            this.setState({ backTo: this.props.insRichiestaPathname, apriModaleConferma: false })
        }
        
    }

    renderModale = () => {
        return <ModalAnagraficaRichiedente
            body={"I dati sono stati modificati correttamente"}
            handleOnClickModale={this.handleRedirectModale}
            btnText={"OK"}
            renderFooter={true}
            title={"Operazione Eseguita!"}
        />;
    }

}

AnagrafeRichiedente.propTypes = {
    uuidRichiesta: PropTypes.string,
    idPersona: PropTypes.string,
    pageState: PropTypes.string,
    pageName: PropTypes.string,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    firstPageName: PropTypes.string,
    insRichiestaPathname: PropTypes.string,
    insRichiestaPathnameHome: PropTypes.string,
    richiesta: PropTypes.node,
    pageNumber: PropTypes.number,
    showOnlyRequiredFields: PropTypes.bool,
    previousPageOnlyRequiredFields: PropTypes.bool,
    profileAction: PropTypes.bool,
    showMultiCompilationPages: PropTypes.bool
};
