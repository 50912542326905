import React, {Component} from "react";
import FiltroUtente from "./filtroUtente/FiltroUtente";


export default class PannelloConfigurazioneRicercaUtenteADI extends Component {
    state = {
        filtroUtente: null,
        utente: null
    }

    handleFiltraUtente = (filtroUtente) => {
        this.setState({filtroUtente: filtroUtente})
    }

    render() {
        return (
            <>
                <FiltroUtente filtraUtente={this.handleFiltraUtente} />
            </>
        )
    }
}
