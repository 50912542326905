import React, {Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {
    FieldsRow,
    RoleBasedAutocomplete,
    RoleBasedCalendar,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";
import {v4 as uuid} from "uuid";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import SondinoNasoGastrico from "./sondinoNasoGastrico/SondinoNasoGastrico";
import TracheostomiaLaringectomia from "./tracheostomiaLaringectomia/TracheostomiaLaringectomia";
import Drenaggi, {defaultTipoDrenaggio} from "./drenaggi/Drenaggi";
import CatetereVescicale, {defaultSublistElementCatetereVescicale} from "./catetereVescicale/CatetereVescicale";
import ProtesiOdontoiatriche, {defaultSublistElementProtesi} from "./protesiOdontoiatriche/ProtesiOdontoiatriche";
import StomiaUrinaria from "./stomiaUrinaria/StomiaUrinaria";
import StomiaIntestinale, {defaultSublistElementStomia} from "./stomiaIntestinale/StomiaIntestinale";
import StomieAddominaliGastriche, {defaultSublistElementStomie}  from "./stomieAddominaliGastriche/StomieAddominaliGastriche";
import enumsUtente from "../../../enum/enumsUtente.json";
import {SessioneUtente} from "web-client-archetype";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";


const propTypes = {
    datiDispositiviMediciPresenti: PropTypes.any,
    onChangeDispositiviMediciPresenti: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    mascheraModificabilita: PropTypes.any,
    pageState: PropTypes.string,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

const defaultDispositivoMedico = {
    id: null,
    tipologiaPresidio: null,
    distrettoAnatomico: null,
    sede: null,
    motiviPosizionamento: [],
    dataPosizionamento: null,
    dataPrevistaDiSostituzione: null,
    dataConfezionamento: null,
    statoCutePerInserzionePeristomale: null,
    topografiaDellaLesione: null,
    presenzaDiComplicanze: null,
    tipologieDiComplicanze: [],
    condizioneRilevata: null,
    daAttenzionare: null,
    propostaAttivitaSuggerimenti: null,
    autonomiaGestionePaziente: null,
    autonomiaGestioneCaregiver: null
}

const defaultSublistElement = {
    id: null,
    codice: null,
    icd9Cm: null,
    altro: null
}

const START_WITH_CODICE_PADRE_SONDINO_NASO_GASTRICO = "G020201";
const START_WITH_CODICE_PADRE_TRACHEOSTOMIA_LARINGECTOMIA = "R0105";
const START_WITH_CODICI_PADRE_DRENAGGI = ["A0601", "A0602"];
const START_WITH_CODICI_PADRE_STOMIE_ADDOMINALI_GASTRICHE = ["G02020201", "G02020202"];
const CODICI_STOMIA_INTESTINALE = ["A100101", "A100102", "A10020101", "A10020102", "A100202", "A100203"];
const CODICI_STOMIA_URINARIA = ["A100103", "A10020103", "A100204"];
const START_WITH_CODICI_PADRE_CATETERE_VESCICALE = ["U0101", "U0102"];
const START_WITH_CODICE_PADRE_PROTESI_ODONTOIATRICHE = "P0102";

export const isSectionReadOnly = (pageState) => {
    const ruoliUtenteAutenticato = SessioneUtente.getInstance().settings.map(setting => setting.ruolo);
    let isReadOnly;
    if (Utils.isStateValutazione(pageState)) {
        isReadOnly = ruoliUtenteAutenticato.includes(enumsUtente.ruoli.assistenteSociale)
            || ruoliUtenteAutenticato.includes(enumsUtente.ruoli.mcp);
    } else if (Utils.isStateFormalizzazione(pageState)) {
        isReadOnly = ruoliUtenteAutenticato.includes(enumsUtente.ruoli.mcp);
    } else isReadOnly = false;

    return isReadOnly;
}

export default class DispositiviMediciPresenti extends AccordionSecondLevel {

    state = {
        dati: _.cloneDeep(this.props.datiDispositiviMediciPresenti)
    }

    cndData = Utils.getListCndData();

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiDispositiviMediciPresenti, this.props.datiDispositiviMediciPresenti))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let dati = _.cloneDeep(this.props.datiDispositiviMediciPresenti);
        this.setState({dati}, () => {this.countCampiObbligatoriRimanentiAndValida()});
    }

    handleAggiornaForm = (field, elem, index) => {
        this.setState(({dati}) => {
            if (field === "tipologiaPresidio") {
                if (!_.isEqual(dati[index][field], elem))
                    dati[index] = _.pick(dati[index], Object.keys(dati[index]).filter(key => key in defaultDispositivoMedico));
            }

            if (field === "presenzaDiComplicanze" && !elem) dati[index]["tipologieDiComplicanze"] = [];

            if (field === "statoCutePerInserzionePeristomale" && elem === "0") dati[index]["topografiaDellaLesione"] = null;

            dati[index][field] = elem;
            return {dati};
        },this.validaDati);
    }

    validaDati = () => {
        this.props.onChangeDispositiviMediciPresenti("dispositiviMediciPresenti", this.state.dati)
        this.countCampiObbligatoriRimanentiAndValida();
    }

    countCampiObbligatoriRimanentiAndValida = () => {
        let dati = _.cloneDeep(this.state.dati);
        let campiObbligatoriRimanenti = 0;
        let proposte = [];
        //TODO: verificare se element.propostaAttivitaSuggerimenti è un array o un oggetto e fare eventualemente controllo di oggetto
        dati.filter(e => e.propostaAttivitaSuggerimenti).forEach(element => {
            if (element.propostaAttivitaSuggerimenti instanceof Array){
                element.propostaAttivitaSuggerimenti.forEach(e => {
                    proposte.push(e)
                });
            } else if (element.propostaAttivitaSuggerimenti instanceof Object){
                proposte.push(element.propostaAttivitaSuggerimenti)
            }
        });
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(proposte)
        this.props.onChangeRequiredFieldsNumber("sottosezioneDispositiviMediciPresenti", campiObbligatoriRimanenti);
    }

    addNewDispositivoMedico = () => {
        let dati = _.cloneDeep(this.state.dati);
        dati.push({..._.cloneDeep(defaultDispositivoMedico), id: uuid()});
        this.setState({dati});
    }

    removeDispositivoMedico = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.splice(index, 1);
        this.setState({dati}, () => {
            this.props.onChangeDispositiviMediciPresenti("dispositiviMediciPresenti", this.state.dati);
        });
    }

    addNewSublistElement = (field, mainListIndex) => {
        let dati = _.cloneDeep(this.state.dati);
        if (!dati[mainListIndex][field]) dati[mainListIndex][field] = [];
        dati[mainListIndex][field].push({..._.cloneDeep(defaultSublistElement), id: uuid()});
        this.setState({dati});
    }

    removeSublistElement = (sublistIndex, field, mainListIndex) => {
        let dati = _.cloneDeep(this.state.dati);
        dati[mainListIndex][field].splice(sublistIndex, 1);
        this.handleAggiornaForm(field, dati[mainListIndex][field], mainListIndex);
    }

    renderNewSublistElement = (element, sublistIndex, mainListIndex, field, fieldLabel, fieldIdSelect, fieldIdInputAltro) => {
        const {motiviPosizionamento} = this.state.dati[mainListIndex];
        const filterMotiviPosizionamento = (item) => {
            return !(motiviPosizionamento?.find(e => e.codice === item.value))
        }
        const options = OggettiUtili.motivoPosizionamento.filter(filterMotiviPosizionamento);
        if (element?.codice) options.push(OggettiUtili.motivoPosizionamento.find(e => e?.value === element?.codice));
        return <FieldsRow>
            <RoleBasedSelect
                id={`select${field.charAt(0).toUpperCase()}${field.slice(1)}`}
                fieldId={fieldIdSelect}
                field={"codice"}
                fieldLabel={fieldLabel}
                pageState={this.props.pageState}
                options={options}
                value={element.codice}
                onChange={(elem) => {
                    let dati = _.cloneDeep(this.state.dati);
                    let sublistElement = dati[mainListIndex][field][sublistIndex];
                    if (sublistElement.codice !== elem) {
                        sublistElement = {
                            ..._.cloneDeep(defaultSublistElement),
                            id: sublistElement.id
                        }
                        sublistElement.codice = elem;
                        dati[mainListIndex][field][sublistIndex] = sublistElement;
                        this.handleAggiornaForm(field, dati[mainListIndex][field], mainListIndex);
                    }
                }}
                isSearchable={false}
                handleOnlyValue={true}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            />
            {field === "motiviPosizionamento" && element.codice === "01"
                ? <RoleBasedICD9Input
                    id={`autocomplete${field.charAt(0).toUpperCase()}${field.slice(1)}`}
                    fieldId={fieldIdSelect}
                    field={"icd9Cm"}
                    fieldLabel={"ICD9-CM"}
                    pageState={this.props.pageState}
                    onChange={(f, e) => {
                        let dati = _.cloneDeep(this.state.dati);
                        dati[mainListIndex][field][sublistIndex].icd9Cm = e.target.value;
                        this.handleAggiornaForm(field, dati[mainListIndex][field], mainListIndex);
                    }}
                    placeholder={"ICD9-CM"}
                    value={element.icd9Cm}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                : null}
            {element.codice === "05"
                ? <RoleBasedInput
                    id={"inputAltro"}
                    fieldId={fieldIdInputAltro}
                    field={"altro"}
                    fieldLabel={'Altro'}
                    pageState={this.props.pageState}
                    value={element.altro}
                    placeholder={"Inserisci altro motivo posizionamento"}
                    onChange={(f, e) => {
                        let dati = _.cloneDeep(this.state.dati);
                        dati[mainListIndex][field][sublistIndex].altro = e.target.value;
                        this.handleAggiornaForm(field, dati[mainListIndex][field], mainListIndex);
                    }}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                : null}
        </FieldsRow>
    }

    renderTipologiaDiComplicanze = (element, index, mainListIndex) => {
        const {tipologieDiComplicanze} = this.state.dati[mainListIndex];
        const filterTipologiaDiComplicanze = (item) => {
            return !(tipologieDiComplicanze?.find(e => e.codice === item.value))
        }
        const options = OggettiUtili.tipologiaDiComplicanze.filter(filterTipologiaDiComplicanze);
        if (element?.codice) options.push(OggettiUtili.tipologiaDiComplicanze.find(e => e?.value === element?.codice));
        return <Fragment key={index}>
            <FieldsRow>
                <RoleBasedSelect
                    id={"selectTipologiaDiComplicanza"}
                    fieldId={'TIPO_COMPLICANZE_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"codice"}
                    fieldLabel={'Tipologia di complicanze'}
                    pageState={this.props.pageState}
                    options={options}
                    value={element?.codice}
                    onChange={(elem) => this.handleAggiornaForm(
                        "tipologieDiComplicanze",
                        _.cloneDeep(this.state.dati)[mainListIndex]?.tipologieDiComplicanze?.map(function (typeCompl) {
                            if (element.id === typeCompl.id)
                                return {...element, codice: elem, altro: null}
                            return typeCompl
                        }), mainListIndex)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                {element.codice === "05"
                    ? <RoleBasedInput
                        id={"inputAltroTipologiaDiComplicanze"}
                        fieldId={"TIPO_COMPLICANZE_ALTRO.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE"}
                        field={"altro"}
                        fieldLabel={'Altro'}
                        pageState={this.props.pageState}
                        value={element.altro}
                        placeholder={"Inserisci altro tipologia di complicanze"}
                        onChange={(f, e) => this.handleAggiornaForm(
                            "tipologieDiComplicanze",
                            _.cloneDeep(this.state.dati)[mainListIndex]?.tipologieDiComplicanze?.map(function (typeCompl) {
                                if (element.id === typeCompl.id)
                                    return {...element, altro: e.target.value}
                                return typeCompl
                            }), mainListIndex)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    : null}
            </FieldsRow>
        </Fragment>;
    }

    renderSectionByTipologiaPresidio = (element, index) => {
        let tipologiaPresidio = element.tipologiaPresidio;
        let input;
        switch (true) {
            case tipologiaPresidio.startsWith(START_WITH_CODICE_PADRE_SONDINO_NASO_GASTRICO):
                input = <SondinoNasoGastrico dati={element.sondinoNasoGastrico}
                                             onChange={(f, e) => this.handleAggiornaForm(f, e, index)}
                                             pageState={this.props.pageState}
                                             fieldInAccordionReadOnly={!!this.props.accordionReadOnly}/>
                break;
            case tipologiaPresidio.startsWith(START_WITH_CODICE_PADRE_TRACHEOSTOMIA_LARINGECTOMIA):
                input = <TracheostomiaLaringectomia dati={element.tracheostomiaLaringectomia}
                                                    onChange={(f, e) => this.handleAggiornaForm(f, e, index)}
                                                    pageState={this.props.pageState}
                                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}/>
                break;
            case START_WITH_CODICI_PADRE_DRENAGGI.some(e => tipologiaPresidio.startsWith(e)):
                input = <Drenaggi dati={element.drenaggi}
                                  onChange={(f, e) => this.handleAggiornaForm(f, e, index)}
                                  pageState={this.props.pageState}
                                  idDispositivo={element?.id}
                                  fieldInAccordionReadOnly={!!this.props.accordionReadOnly}/>
                break;
            case START_WITH_CODICI_PADRE_STOMIE_ADDOMINALI_GASTRICHE.some(e => tipologiaPresidio.startsWith(e)):
                input = <StomieAddominaliGastriche dati={element.stomieAddominaliGastriche}
                                                   onChange={(f, e) => this.handleAggiornaForm(f, e, index)}
                                                   pageState={this.props.pageState}
                                                   idDispositivo={element?.id}
                                                   fieldInAccordionReadOnly={!!this.props.accordionReadOnly}/>
                break;
            case CODICI_STOMIA_INTESTINALE.includes(tipologiaPresidio):
                input = <StomiaIntestinale dati={element.stomiaIntestinale}
                                           onChange={(f, e) => this.handleAggiornaForm(f, e, index)}
                                           pageState={this.props.pageState}
                                           idDispositivo={element?.id}
                                           fieldInAccordionReadOnly={!!this.props.accordionReadOnly}/>
                break;
            case CODICI_STOMIA_URINARIA.includes(tipologiaPresidio):
                input = <StomiaUrinaria dati={element.stomiaUrinaria}
                                        onChange={(f, e) => this.handleAggiornaForm(f, e, index)}
                                        pageState={this.props.pageState}
                                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}/>
                break;
            case START_WITH_CODICI_PADRE_CATETERE_VESCICALE.some(e => tipologiaPresidio.startsWith(e)):
                input = <CatetereVescicale dati={element.catetereVescicale}
                                           onChange={(f, e) => this.handleAggiornaForm(f, e, index)}
                                           pageState={this.props.pageState}
                                           idDispositivo={element?.id}
                                           fieldInAccordionReadOnly={!!this.props.accordionReadOnly}/>
                break;
            case tipologiaPresidio.startsWith(START_WITH_CODICE_PADRE_PROTESI_ODONTOIATRICHE):
                input = <ProtesiOdontoiatriche dati={element.protesiOdontoiatriche}
                                               onChange={(f, e) => this.handleAggiornaForm(f, e, index)}
                                               pageState={this.props.pageState}
                                               idDispositivo={element?.id}
                                               fieldInAccordionReadOnly={!!this.props.accordionReadOnly}/>
                break;
            default:
                input = null;
                break;
        }

        return input;
    }

    renderNewDispositivoMedico = (element, index) => {
        const optionsTipologiaPresidio = _.cloneDeep(this.cndData)
            .filter(e => !this.state.dati?.find(d => element.tipologiaPresidio !== d.tipologiaPresidio && d.tipologiaPresidio === e.codice));

        return <Fragment key={index}>
            <FieldsRow>
                <RoleBasedAutocomplete
                    fieldId={"TIPO_PRESIDIO_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE"}
                    field={'tipologiaPresidio'}
                    fieldLabel={'Tipologia presidio'}
                    pageState={this.props.pageState}
                    id={"autocompleteTipologiaPresidio"}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    searchFromStart={false}
                    value={element.tipologiaPresidio}
                    placeholder={'Inserisci tipologia presidio'}
                    suggestions={optionsTipologiaPresidio}
                    noSuggestions={'Errore: Tipologia presidio non presente'}
                    handleOnlyValue={true}
                    forceReadOnly={Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.informazioniDiBase?.dispositiviMediciPresenti, "tipologiaPresidio", element?.id)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedSelect
                    id={"selectDistrettoAnatomico"}
                    fieldId={'DISTRETTO_ANATOMICO_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"distrettoAnatomico"}
                    fieldLabel={'Distretto anatomico'}
                    pageState={this.props.pageState}
                    options={OggettiUtili.distrettoAnatomico}
                    value={element.distrettoAnatomico}
                    onChange={(elem) => this.handleAggiornaForm("distrettoAnatomico", elem, index)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedInput
                    id={"inputSede"}
                    fieldId={'SEDE_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"sede"}
                    fieldLabel={'Sede'}
                    pageState={this.props.pageState}
                    value={element.sede}
                    placeholder={"Inserisci sede"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
            <SectionList
                addNewSectionCallback={() => this.addNewSublistElement("motiviPosizionamento", index)}
                removeSectionCallback={(i) => this.removeSublistElement(i, "motiviPosizionamento", index)}
                data={element.motiviPosizionamento}
                title={'Motivo posizionamento'}
                renderSection={(e, i) => {
                    return this.renderNewSublistElement(e, i, index, "motiviPosizionamento", "Motivo posizionamento",
                        "MOTIVO_POSIZ_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE",
                        "MOTIVO_POSIZ_ALTRO.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE");
                }}
                pageState={this.props.pageState}
                field={"motiviPosizionamento"}
                addButtonVisibilityHandler={element.motiviPosizionamento?.every(e => !Utils.isStringEmptyOrNullOrUndefined(e.codice))}
                indentOffset={1}
                forceReadOnly={isSectionReadOnly(this.props.pageState)}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                keyFieldId={'MOTIVO_POSIZ_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                parentJsonPath={"informazioniDiBase.dispositiviMediciPresenti[?(@.id == '" + element?.id + "')].motiviPosizionamento"}
                disableDoubleInput={this.props.disableDoubleInput}
           />
            <FieldsRow>
                <RoleBasedCalendar
                    fieldId={"DATA_POSIZ_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE"}
                    pageState={this.props.pageState}
                    id={"dispositiviMediciPresentiDataPosizionamento" + index}
                    value={element.dataPosizionamento}
                    field={"dataPosizionamento"}
                    onChange={(elem) => this.handleAggiornaForm("dataPosizionamento", elem?.target?.value, index)}
                    errorMessage={"Data non valida"}
                    fieldLabel={"Data posizionamento"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedCalendar
                    fieldId={"DATA_PREV_SOSTITUZ_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE"}
                    pageState={this.props.pageState}
                    id={"dispositiviMediciPresentiDataPrevistaDiSostituzione" + index}
                    value={element.dataPrevistaDiSostituzione}
                    field={"dataPrevistaDiSostituzione"}
                    onChange={(elem) => this.handleAggiornaForm("dataPrevistaDiSostituzione", elem?.target?.value, index)}
                    errorMessage={"Data non valida"}
                    fieldLabel={"Data prevista di sostituzione"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedCalendar
                    fieldId={"DATA_CONFEZ_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE"}
                    pageState={this.props.pageState}
                    id={"dispositiviMediciPresentiDataConfezionamento" + index}
                    value={element.dataConfezionamento}
                    field={"dataConfezionamento"}
                    onChange={(elem) => this.handleAggiornaForm("dataConfezionamento", elem?.target?.value, index)}
                    errorMessage={"Data non valida"}
                    fieldLabel={"Data confezionamento"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
            <FieldsRow>
                <RoleBasedSelect
                    id={"selectstatoCutePerInserzionePeristomale"}
                    fieldId={'STATO_CUTE_PERI_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"statoCutePerInserzionePeristomale"}
                    fieldLabel={'Stato cute perinserzione/peristomale'}
                    pageState={this.props.pageState}
                    options={OggettiUtili.statoCute}
                    value={element.statoCutePerInserzionePeristomale}
                    onChange={(elem) => this.handleAggiornaForm("statoCutePerInserzionePeristomale", elem, index)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                {element.statoCutePerInserzionePeristomale && element.statoCutePerInserzionePeristomale !== "0" &&
                    <RoleBasedSelect
                        id={"selectTopografiaDellaLesione"}
                        fieldId={'TOPOGRAFIA_LESIONE_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        field={"topografiaDellaLesione"}
                        fieldLabel={'Topografia della lesione'}
                        pageState={this.props.pageState}
                        options={OggettiUtili.topografiaDellaLesione}
                        value={element.topografiaDellaLesione}
                        onChange={(elem) => this.handleAggiornaForm("topografiaDellaLesione", elem, index)}
                        isSearchable={false}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />}
                <RoleBasedSelect
                    id={"selectPresenzaDiComplicanze"}
                    fieldId={'PRES_COMPLICANZE_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"presenzaDiComplicanze"}
                    fieldLabel={'Presenza di complicanze'}
                    pageState={this.props.pageState}
                    options={OggettiUtili.rispostaBreve}
                    value={element.presenzaDiComplicanze}
                    onChange={(elem) => this.handleAggiornaForm("presenzaDiComplicanze", elem, index)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
            {element.presenzaDiComplicanze &&
                <SectionList
                    addNewSectionCallback={() => this.addNewSublistElement("tipologieDiComplicanze", index)}
                    removeSectionCallback={(i) => this.removeSublistElement(i, "tipologieDiComplicanze", index)}
                    data={element.tipologieDiComplicanze || []}
                    title={'Tipologia di complicanze'}
                    renderSection={(e, i) => {
                        return this.renderTipologiaDiComplicanze(e, i, index);
                    }}
                    pageState={this.props.pageState}
                    field={"tipologieDiComplicanze"}
                    addButtonVisibilityHandler={element?.tipologieDiComplicanze?.length === 0
                        || element?.tipologieDiComplicanze?.every(el => !Utils.isObjectNull(el?.codice))}
                    indentOffset={1}
                    forceReadOnly={isSectionReadOnly(this.props.pageState)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    keyFieldId={'TIPO_COMPLICANZE_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    parentJsonPath={"informazioniDiBase.dispositiviMediciPresenti[?(@.id == '" + element?.id + "')].tipologieDiComplicanze"}
                    disableDoubleInput={this.props.disableDoubleInput}
            />}
            <CondizioneRilevataSection
                ambito={'DISPOSITIVIMEDICIPRESENTI'}
                pageState={this.props.pageState}
                onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem, index)}
                valueCondizioneRilevata={element.condizioneRilevata}
                onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem, index)}
                valueDaAttenzionare={element.daAttenzionare}
                showSuggerimenti={true}
                onChangeSuggerimenti={(elem, listSugg) => this.handleAggiornaForm("propostaAttivitaSuggerimenti", listSugg, index)}
                valueSuggerimenti={element.propostaAttivitaSuggerimenti}
                showAutonomia={true}
                onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem, index)}
                valueAutonomiaPaziente={element.autonomiaGestionePaziente}
                onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem, index)}
                valueAutonomiaCaregiver={element.autonomiaGestioneCaregiver}
                fieldsSuffix={'XXXX'}
                parentFieldId={'DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                disableDoubleInput={this.props.disableDoubleInput}
            />
            {!Utils.isStringEmptyOrNullOrUndefined(element.tipologiaPresidio) && this.renderSectionByTipologiaPresidio(element, index)}
        </Fragment>;
    }

    render() {
        let dati = _.cloneDeep(this.state.dati);

        return (
            <AccordionSezioneRichiesta
                idAccordion={"dispositiviMediciPresenti"}
                title={"Dispositivi medici presenti"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["informazioniDiBase.dispositiviMediciPresenti", "informazioniDiBase.dispositiviMediciPresenti.motiviPosizionamento",
                     "informazioniDiBase.dispositiviMediciPresenti.tipologieDiComplicanze", "informazioniDiBase.dispositiviMediciPresenti.drenaggi",
                     "informazioniDiBase.dispositiviMediciPresenti.stomieAddominaliGastriche", "informazioniDiBase.dispositiviMediciPresenti.stomiaIntestinale",
                     "informazioniDiBase.dispositiviMediciPresenti.catetereVescicale", "informazioniDiBase.dispositiviMediciPresenti.protesiOdontoiatriche"],
                    [defaultDispositivoMedico, defaultSublistElement, defaultSublistElement,
                    defaultTipoDrenaggio, defaultSublistElementStomie, defaultSublistElementStomia,
                    defaultSublistElementCatetereVescicale, defaultSublistElementProtesi]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >
                <SectionList
                    addNewSectionCallback={this.addNewDispositivoMedico}
                    removeSectionCallback={this.removeDispositivoMedico}
                    data={dati}
                    title={'Dispositivo medico presente'}
                    renderSection={this.renderNewDispositivoMedico}
                    pageState={this.props.pageState}
                    field={"dispositiviMediciPresenti"}
                    addButtonVisibilityHandler={dati?.every(e => !Utils.isStringEmptyOrNullOrUndefined(e.tipologiaPresidio))}
                    indentOffset={0}
                    forceReadOnly={isSectionReadOnly(this.props.pageState) || this.props.mascheraModificabilita?.informazioniDiBase?.dispositiviMediciPresenti}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    keyFieldId={'TIPO_PRESIDIO_XXXX.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    parentJsonPath={"informazioniDiBase.dispositiviMediciPresenti"}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </AccordionSezioneRichiesta>
        );
    }
}
DispositiviMediciPresenti.propTypes = propTypes;
