import React from "react";
import PropTypes from "prop-types";
import AccordionSecondLevel from "../../../accordionSezioneRichiesta/AccordionSecondLevel";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {
    FieldsGroup,
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedInput,
    RoleBasedText,
    rolesProfessionista,
    SectionList
} from "../../../roleBasedComponents/RoleBasedComponents";
import {SessioneUtente} from "web-client-archetype";
import {v4 as uuid} from "uuid";
import AuthUtils from "../../../../utils/AuthUtils";
import {RoleBasedContext} from "../../../../utils/RoleBasedContext";
import {isArray} from "lodash";
import {Fragment} from "react";

const propTypes = {
    dati: PropTypes.any,
    onChangeDatiValutazioneProfessionista: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    onChangeRequiredFieldsNumber: PropTypes.func
}

export const defaultValutazione = {
    value:null,
    valutazioneEffettuataDa: null,
    codiceFiscale: null,
};

export default class ValutazioneProfessionistaSub extends AccordionSecondLevel {
    state = {
        valutazioneProfessionista: _.cloneDeep(this.props.dati)
    }

    componentDidMount() {
        this.setState({valutazioneProfessionista: this.props.dati}, () => this.props.onChangeRequiredFieldsNumber(this.getNumeroCampiObbligatori()));
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati)) {
            this.setState({valutazioneProfessionista: this.props.dati}, () => this.props.onChangeRequiredFieldsNumber(this.getNumeroCampiObbligatori()));
        }
    }

    getNumeroCampiObbligatori = () => {
        const isWriteableField = isFieldIdWriteable("X_VALUTAZ_PROFESSIONALE.VALUTAZIONEPROFESSIONISTA.RICHIESTACAMBIOSETTING", this.props.pageState, this.context.forceUserRole);
        const isValidList = isArray(this.props.dati?.valutazioni)
            && !!(this.props.dati?.valutazioni?.find(valutazione => valutazione.codiceFiscale === SessioneUtente.getInstance().idUtente && valutazione.value));
        if (!isWriteableField) return 0;
        if (!isValidList) return 1;
        return 0;
    }

    forceRequiredVisibility = () => {
        const isWriteableField = isFieldIdWriteable("X_VALUTAZ_PROFESSIONALE.VALUTAZIONEPROFESSIONISTA.RICHIESTACAMBIOSETTING", this.props.pageState, this.context.forceUserRole);
        const isValidList = isArray(this.props.dati?.valutazioni)
            && !!(this.props.dati?.valutazioni?.find(valutazione => valutazione.codiceFiscale === SessioneUtente.getInstance().idUtente && valutazione.value));
        return (isWriteableField || !isValidList);
    }

    renderValutazioni = (valuazione, index, readOnly) => {
        return (
            <>
                <RoleBasedText
                    fieldId={'X_VALUTAZ_PROFESSIONALE.VALUTAZIONEPROFESSIONISTA.RICHIESTACAMBIOSETTING'}
                    pageState={this.props.pageState}
                    value={valuazione?.valutazioneEffettuataDa}
                    field={"valutazioneEffettuataDa"}
                    id={"valutazioneEffettuataDa"}
                    fieldLabel={'Valutazione effettuata da'}
                    showFieldIfRequiredAccordionPage={this.forceRequiredVisibility()}
                />
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'X_VALUTAZ_PROFESSIONALE.VALUTAZIONEPROFESSIONISTA.RICHIESTACAMBIOSETTING'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci valutazione"}
                        field={"valutazione"}
                        onChange={(field, elem) => {
                            let currentValutazioni = _.cloneDeep(this.state.valutazioneProfessionista?.valutazioni);
                            currentValutazioni[index].value = elem.target.value
                            this.props.onChangeDatiValutazioneProfessionista("valutazioneProfessionista",  {"valutazioni" : currentValutazioni});
                        }}
                        id={"inputValutazioneProfessionista"}
                        value={valuazione.value}
                        fieldLabel={"Valutazione Professionista"}
                        parentJsonPath={"valutazioneProfessionista"}

                        type={"textarea"}
                        showAvailableCharsLabel={true}
                        maxlength={"500"}
                        rows={"8"}
                        forceReadOnly={readOnly}
                        showFieldIfRequiredAccordionPage={this.forceRequiredVisibility()}
                        forceVisibility={this.forceRequiredVisibility()}
                        fieldRequired={true}
                    />
                </FieldsRow>
            </>
        );
    }

    addNewValutazione = () => {
        let currentValutazioni = _.cloneDeep(this.state.valutazioneProfessionista?.valutazioni);
        if(!currentValutazioni){ currentValutazioni = [] }
        currentValutazioni.push({
            ..._.cloneDeep(defaultValutazione),
            id: uuid(),
            valutazioneEffettuataDa: AuthUtils.getInfoUtente(),
            codiceFiscale: SessioneUtente.getInstance().idUtente
        });

        this.props.onChangeDatiValutazioneProfessionista("valutazioneProfessionista", {"valutazioni" : currentValutazioni});

    }

    removeValutazione = (index) => {
        let currentValutazioni= _.cloneDeep(this.state.valutazioneProfessionista?.valutazioni);
        currentValutazioni.splice(index, 1);
        this.props.onChangeDatiValutazioneProfessionista("valutazioneProfessionista", {"valutazioni" : currentValutazioni});
    }

    render () {
        const readOnlyRuolo = this.context.forceReadOnly || this.state.valutazioneProfessionista?.valutazioni?.map(valutazione => valutazione.codiceFiscale !== SessioneUtente.getInstance().idUtente);
        return (
            <AccordionSezioneRichiesta
                idAccordion={"valutazioni"}
                title={"Valutazione Professionista"}
                required={true}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
            >
                <SectionList
                    title={"Valutazione"}
                    data={this.state.valutazioneProfessionista?.valutazioni || []}
                    renderSection={this.renderValutazioni}
                    addNewSectionCallback={this.addNewValutazione}
                    removeSectionCallback={this.removeValutazione}
                    pageState={this.props.pageState}
                    forceReadOnly={readOnlyRuolo}
                    field={'valutazioneProfessionaleList'}
                    atLeastOne={!this.state.valutazioneProfessionista?.valutazioni?.some(v => v?.value) && SessioneUtente.getInstance().settings.some(setting => rolesProfessionista.has(setting.ruolo)) }
                    addButtonVisibilityHandler={!(this.state.valutazioneProfessionista?.valutazioni.find(e => e.codiceFiscale === SessioneUtente.getInstance().idUtente))}
                    keyFieldId={'X_VALUTAZ_PROFESSIONALE.VALUTAZIONEPROFESSIONISTA.RICHIESTACAMBIOSETTING'}
                    required={this.forceRequiredVisibility()}
                />
            </AccordionSezioneRichiesta>
        );
    }
}

ValutazioneProfessionistaSub.propTypes = propTypes;

ValutazioneProfessionistaSub.contextType = RoleBasedContext;
