import globals from "./globalConfig";

// ms_anagrafica
// const appUrlMSAnagrafica = globals["app-url-ms-anagrafica"]

//ms_richiestaADI
const appUrlMSRichiestaADI = globals["app-url-ms-richiestaadi"]

// ms_toponomastica
const appUrlMSToponomastica = globals["app-url-ms-toponomastica"]
const comuni = appUrlMSToponomastica.concat('comuni');
const province = appUrlMSToponomastica.concat('province');
const nazioni = appUrlMSToponomastica.concat('nazioni');
const nazioniIstat = appUrlMSToponomastica.concat('nazioniIstat');
const sigleProvince = appUrlMSToponomastica.concat('sigleProvince');
const cap = appUrlMSToponomastica.concat('cap');

// ms_utenza
const appUrlMSUtenza = globals['app-url-ms-utenza']
const appUrlMSUtenzaV2 = globals['app-url-ms-utenza-v2']
const utente = appUrlMSUtenza.concat("utente")
const utenti = appUrlMSUtenza.concat("utenti")
const utentiV2 = appUrlMSUtenzaV2.concat("utentiv2")
const listaUtenti = utente.concat("/lista")

const home = appUrlMSRichiestaADI.concat("richieste")
const listaMediciRichiedenti = appUrlMSRichiestaADI.concat("richieste/professionisti/richiedenti")
const getRichiesta = appUrlMSRichiestaADI.concat("richieste/{id}")
const postRicercaRichiesta = appUrlMSRichiestaADI.concat("richieste/ricercaRichiesta");
const aggiornaRichiestaDaFser = appUrlMSRichiestaADI.concat("richieste/{id}/aggiornaRichiestaDaFser")
const annullaRichiesta = getRichiesta.concat("/annullarichiesta")
const presaInCarico = getRichiesta.concat("/presaInCarico")
const salvaBozza = appUrlMSRichiestaADI.concat("richieste/{id}/salvaBozza")
const salvaBozzaAsync = appUrlMSRichiestaADI.concat("richieste/{id}/salvaBozzaAsync")
const salvaVariazioniInBozza = appUrlMSRichiestaADI.concat("richieste/{id}/salvaVariazioni")
const salvaVariazioniInBozzaAsync = appUrlMSRichiestaADI.concat("richieste/{id}/salvaVariazioniAsync")
const storicoListaCampi = appUrlMSRichiestaADI.concat("richieste/{id}/storicoListaCampi")
const inoltraRichiesta = appUrlMSRichiestaADI.concat("richieste/{id}/inoltraRichiesta")
const creaValutatore = appUrlMSRichiestaADI.concat("richieste/{id}/creaValutatoreMock")
const notifiche = appUrlMSRichiestaADI.concat("richieste/notifiche")
const getNotifica = notifiche.concat("/{id}")
const deleteNotifiche = notifiche.concat("/deleteIds")
const salvaFormalizzazione = appUrlMSRichiestaADI.concat("richieste/{id}/salvaFormalizzazione")
const salvaFormalizzazioneAsync = appUrlMSRichiestaADI.concat("richieste/{id}/salvaFormalizzazioneAsync")
const chiudiPreDialogo = appUrlMSRichiestaADI.concat("richieste/{id}/chiudiPreDialogo")
const chiudiPreDialogoAsync = appUrlMSRichiestaADI.concat("richieste/{id}/chiudiPreDialogoAsync")
const confermaValutazione = appUrlMSRichiestaADI.concat("richieste/{id}/confermaValutazione")
const formalizzatori = appUrlMSRichiestaADI.concat("richieste/professionisti/formalizzatori")
const firmatari = appUrlMSRichiestaADI.concat("richieste/professionisti/firmatari")
const aggiungiProfessionista = appUrlMSRichiestaADI.concat("richieste/{id}/aggiungiProfessionista")
const eliminaProfessionista = appUrlMSRichiestaADI.concat("richieste/{id}/eliminaProfessionista")
const confermaTeam = appUrlMSRichiestaADI.concat("richieste/{id}/confermaTeam")
const visualizzaDettaglioRichiestaStorico = appUrlMSRichiestaADI.concat("richieste/{id}/visualizzaDettaglioStorico")
const riapriTeamValutatori = appUrlMSRichiestaADI.concat("richieste/{id}/riapriTeam")
const richiestaAggiuntaInfermiere = appUrlMSRichiestaADI.concat("richieste/{id}/richiestaAggiuntaInfermiere")
const sostituzioneMedicoRichiedenti = appUrlMSRichiestaADI.concat("richieste/{id}/sostituzionerichiedente")
const riapriValutazione = appUrlMSRichiestaADI.concat("richieste/{id}/riapriValutazione")
const generaPdf = appUrlMSRichiestaADI.concat("richieste/{id}/{step}/generaPdf")
const autoAssegnazioneValutazione = appUrlMSRichiestaADI.concat("richieste/{id}/autoAssegnazioneValutazione")
const subentraProfessionista = appUrlMSRichiestaADI.concat("richieste/{id}/subentraProfessionista/{codiceFiscale}")
const modificaDataDimissioneProgrammata = appUrlMSRichiestaADI.concat("richieste/{id}/modificaDataDimissioneProgrammata")
const aggiornaDatiRichiedente = appUrlMSRichiestaADI.concat("richieste/{id}/aggiornaDatiRichiedente")
const contaCampiOpzionali = appUrlMSRichiestaADI.concat("richieste/{id}/contaCampiOpzionali")
const copiaRichiesta = appUrlMSRichiestaADI.concat("richieste/{id}/copia")
const subentroPresaInCarico = appUrlMSRichiestaADI.concat("richieste/subentroPresaInCarico")
const presaInCaricoMultipla = appUrlMSRichiestaADI.concat("richieste/presaInCaricoMultipla")
const delegaFisiatra = appUrlMSRichiestaADI.concat("richieste/{id}/delegaFisiatra/{delega}")
// deleghe
const basePathDeleghe = appUrlMSRichiestaADI.concat("deleghe")
const inserimentoDelega = basePathDeleghe;
const eliminaDelega = basePathDeleghe.concat("/{id}").concat("/{tipologia}");
const modificaDelega = basePathDeleghe.concat("/{id}")
//firme
const recuperaRichiesteDaFirmare = appUrlMSRichiestaADI.concat("richieste/daFirmare")
const firmaFormalizzazioni = appUrlMSRichiestaADI.concat("richieste/firmaFormalizzazioni")

//ms_anagrafica
const appUrlMsAnagrafica = globals['app-url-ms-anagrafica']
const anagrafiche = appUrlMsAnagrafica.concat("anagrafiche")
const personeAssistiti = appUrlMsAnagrafica.concat("persone/assistiti/{id}")
const persone = appUrlMsAnagrafica.concat("persone")
const personaById = persone.concat("/{id}")
const uuid = persone.concat("/identificativo/{id}")
const medici = personeAssistiti.concat("/medici")
const mmg = medici.concat("/generali")
const richiedente = medici.concat("/richiedenti")
const ricercheAnagrafiche = anagrafiche.concat("/ricerche")
const ricerchePersone = persone.concat("/ricerche")
const ricercaAnagraficheMediciRichiedenti = anagrafiche.concat("/medici/richiedenti")


//ms_gestione_password
const appUrlMSGestionePassword = globals['app-url-ms-gestione-password']
const gestionePassword = appUrlMSGestionePassword.concat("gestionePassword")
const creaPassword = gestionePassword
const modificaPassword = gestionePassword
const recuperaPassword = gestionePassword.concat("/recuperaPassword")
const verificaToken = gestionePassword.concat("/verificaToken")
const resetPassword = gestionePassword.concat("/resetPassword")
const checkPassword = gestionePassword.concat("/checkPassword")
const verificaEsistenzaPassword = gestionePassword.concat("/verificaEsistenzaPassword")
const invioEmailCreazionePassword = gestionePassword.concat("/invioEmailCreazionePassword");

const config = {
  // 'ms_anagrafica': {
  //
  // },
  'ms_toponomastica': {
    comuni: comuni,
    province: province,
    nazioni: nazioni,
    nazioniIstat: nazioniIstat,
    sigleProvince: sigleProvince,
    cap: cap
  },
  'ms_utenza': {
    utente: utente,
    utenti: utenti,
    listaUtenti: listaUtenti
  },
  'ms_richiesta': {
    home: home,
    medici: listaMediciRichiedenti,
    getRichiesta: getRichiesta,
    aggiornaRichiestaDaFser: aggiornaRichiestaDaFser,
    salvaBozza: salvaBozza,
    salvaBozzaAsync: salvaBozzaAsync,
    salvaVariazioniInBozza: salvaVariazioniInBozza,
    salvaVariazioniInBozzaAsync: salvaVariazioniInBozzaAsync,
    storicoListaCampi: storicoListaCampi,
    inoltraRichiesta: inoltraRichiesta,
    creaValutatore: creaValutatore,
    notifiche: notifiche,
    annullaRichiesta: annullaRichiesta,
    presaInCarico: presaInCarico,
    getNotifica: getNotifica,
    deleteNotifiche: deleteNotifiche,
    salvaFormalizzazione: salvaFormalizzazione,
    salvaFormalizzazioneAsync: salvaFormalizzazioneAsync,
    chiudiPreDialogo: chiudiPreDialogo,
    chiudiPreDialogoAsync: chiudiPreDialogoAsync,
    confermaValutazione: confermaValutazione,
    formalizzatori: formalizzatori,
    firmatari: firmatari,
    aggiungiProfessionista: aggiungiProfessionista,
    eliminaProfessionista: eliminaProfessionista,
    confermaTeam: confermaTeam,
    visualizzaDettaglioRichiestaStorico: visualizzaDettaglioRichiestaStorico,
    riapriTeamValutatori: riapriTeamValutatori,
    richiestaAggiuntaInfermiere: richiestaAggiuntaInfermiere,
    sostituzioneMedicoRichiedenti: sostituzioneMedicoRichiedenti,
    riapriValutazione: riapriValutazione,
    generaPdf: generaPdf,
    postRicercaRichiesta: postRicercaRichiesta,
    autoAssegnazioneValutazione: autoAssegnazioneValutazione,
    subentraProfessionista: subentraProfessionista,
    modificaDataDimissioneProgrammata: modificaDataDimissioneProgrammata,
    aggiornaDatiRichiedente: aggiornaDatiRichiedente,
    contaCampiOpzionali: contaCampiOpzionali,
    ricercaDeleghe: basePathDeleghe,
    inserimentoDelega: inserimentoDelega,
    eliminaDelega: eliminaDelega,
    modificaDelega: modificaDelega,
    recuperaRichiesteDaFirmare: recuperaRichiesteDaFirmare,
    firmaFormalizzazioni: firmaFormalizzazioni,
    copiaRichiesta: copiaRichiesta,
    subentroPresaInCarico: subentroPresaInCarico,
    presaInCaricoMultipla: presaInCaricoMultipla,
    delegaFisiatra: delegaFisiatra
  },
  'ms_utenza_v2': {
    utenti: utentiV2
  },
  'ms_anagrafica': {
    anagrafiche: anagrafiche,
    ricerche: ricercheAnagrafiche,
    ricerchePersone: ricerchePersone,
    personeAssistiti: personeAssistiti,
    persone: persone,
    personaById: personaById,
    uuid:uuid,
    mmg: mmg,
    richiedente: richiedente,
    ricercaAnagraficheMediciRichiedenti: ricercaAnagraficheMediciRichiedenti
  },
  'ms_gestione_password': {
    creaPassword : creaPassword,
    modificaPassword : modificaPassword,
    recuperaPassword : recuperaPassword,
    verificaToken : verificaToken,
    resetPassword : resetPassword,
    checkPassword : checkPassword,
    verificaEsistenzaPassword : verificaEsistenzaPassword,
    invioEmailCreazionePassword : invioEmailCreazionePassword
  }
};

export default config
