import React from "react";
import PropTypes from "prop-types";
import * as BsIcon from "react-bootstrap-icons";
import Dropdown from "../../../generalComponents/dropdown/Dropdown";
import stylesNavbarComponent from "../NavBar.module.css";
import {Link} from "react-router-dom";
import routepath from "../../../../utils/route/route-path";
import {SessioneUtente} from "web-client-archetype";
import AuthUtils from "../../../../utils/AuthUtils";
import {v4 as uuid} from "uuid";
import SessionRequest from "../../../../utils/SessionRequest";
import enumDelega from "../../../../enum/enumDelega.json";
import enumAzioniUtente from "../../../../enum/enumAzioniUtente.json";
import AnagrafeService from "../../../../service/AnagrafeService";

function MenuUtente ({bloccoApplicativo, recuperaPassword, showAnagrafica, idPersona}) {



    const isInstance = SessioneUtente.getInstance();

    const settaMenuAzioni = (utenteAbilitato, name, path) => {
        let opzioneMenuDelega = null;

        if (utenteAbilitato) {
            opzioneMenuDelega = {
                id: path,
                type: 'Link',
                element: <Link to={{pathname: path, name: name, profileAction: true}}
                               className={"link listItem"}>{name}</Link>
            };
        }

        return opzioneMenuDelega;
    }

const closeMenu = () => {
    document.getElementById("idDropdownMenuUtente").style.display = "none";
}


    let opzioniMenuUtente = [
        {
            id: "bloccaApplicativo",
            type: 'span',
            element:
                <span className={"link listItem"} onClick={() => bloccoApplicativo()}>
                    {"Blocca applicativo"}
                </span>
        },
        {
            id: "cambiaPassword",
            type: 'Link',
            element:
                <Link to={routepath.cambia_password} className={"link listItem"}>
                    {"Cambio password"}
                </Link>
        },
        {
            id: "recuperPassword",
            type: 'span',
            element:
                <span className={"link listItem"} onClick={() => recuperaPassword()}>
                    {"Recupera password"}
                </span>
        }
    ];

    if (isInstance && AuthUtils.hasUtenteRuoloMedicoRichiedente() && showAnagrafica) {
        opzioniMenuUtente.push({
            id:"laTuaAnagrafica",
            type: 'Link',
            element:
                <Link
                    to={{
                        key: uuid(),
                        pathname: routepath.anagrafica_medico_richiedente,
                        idPersona:idPersona,
                        pageState: 'R',
                        readOnly: false,
                        hideUnsetValues: false,
                        insRichiestaPathname: routepath.home,
                        profileAction: true
                    }}
                    className={"link listItem"}>{"La tua anagrafica"}
                </Link>
        });
    }


    if (isInstance) {
        opzioniMenuUtente = [
            ...opzioniMenuUtente,
            settaMenuAzioni(AuthUtils.hasUtenteMcpFormalizzatore() || AuthUtils.hasUtenteCentraleAdi(), enumAzioniUtente.menu.SUBENTRO_MULTIPLO, routepath.subentroRichiesteMultiple),
            settaMenuAzioni(AuthUtils.hasUtenteMcpFormalizzatore() || AuthUtils.hasUtenteCentraleAdi(), enumAzioniUtente.menu.PRESA_IN_CARICO_MULTIPLO, routepath.presaInCaricoRichiesteMultiple)
        ].filter(e => !!e);
    }

    if (isInstance && (AuthUtils.hasUtenteRuoloMcp() || AuthUtils.hasUtenteRuoloFisiatra())) {
        opzioniMenuUtente.push({
            id:"firmaFormalizzazione",
            type: 'Link',
            element:
                <Link to={{pathname: routepath.firme, profileAction: true}} className={"link listItem"}>
                    {"Firma formalizzazioni"}
                </Link>
        });
    }

    if (isInstance) {
        opzioniMenuUtente = [
            ...opzioniMenuUtente,
            settaMenuAzioni(AuthUtils.hasUtenteRuoloAmministratoreSistema(), enumDelega.menu.CENSIMENTO_CENTRALE_ADI, routepath.censimentoCentraleADI),
            settaMenuAzioni(AuthUtils.hasUtenteProfiloDirettoreUO(), enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI, routepath.nominaAutomaticaProfessionisti)
        ].filter(e => !!e);
    }

    const renderDropdownToggleUtente = () => {
        return (
            <>
                <span className={[stylesNavbarComponent.stylePagineLi, stylesNavbarComponent.liPagina, stylesNavbarComponent.labelCustom].join(' ')} >
                    <div><BsIcon.FilterCircle width={30} height={30} /></div>
                </span>
            </>
        )
    }


    const renderDropdownMenuUtente = () => {
        return opzioniMenuUtente?.map(el => {
            return el.element
        })

    }

    return (
        <>
            <div onClick={() => closeMenu()} id={"dropdownUtente"}>
                <Dropdown
                    idDropdown={"idDropdownUtente"}
                    idDropdownToggle={"dropdownToggleUtente"}
                    idDropdownMenu={"idDropdownMenuUtente"}
                    tagDropdownToggle={"span"}
                    renderDropdownToggle={renderDropdownToggleUtente}
                    renderDropdownMenu={renderDropdownMenuUtente}
                    direction={"down"}
                    classNameDropdownMenu={"dropdown-Utente"}
                />
            </div>
        </>
    )
}

MenuUtente.propTypes = {
    bloccoApplicativo: PropTypes.func,
    recuperaPassword: PropTypes.func,
    showAnagrafica:PropTypes.bool,
    idPersona:PropTypes.string
};

MenuUtente.defaultProps = {
    bloccoApplicativo: () => null,
    recuperaPassword: () => null
};

export default MenuUtente;
