import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionHelper from "../../../service/AccordionHelper";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import ValutazioneDellaCute from "./valutazioneDellaCute/ValutazioneDellaCute";
import Utils from "../../../utils/Utils";

const propTypes = {
    esameObiettivo: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeEsameObiettivo: PropTypes.func,
    openAccordion: PropTypes.any,
    openAccordionCapo: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

export default class FunzioneTegumentaria extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: {
            sottosezioneValutazioneDellaCute:0
        },
        accordion: {
            openAccordionValutazioneDellaCute: true
        }
    }

    campiObbligatori = {...this.state.campiObbligatoriMancanti};

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta.esameObiettivo = this.props.esameObiettivo;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        this.campiObbligatori[field] = value;
        this.setState({campiObbligatoriMancanti: _.cloneDeep(this.campiObbligatori)}, () => {
            this.props.onChangeObbligatorieta("funzioneTegumentaria", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    renderBodySezione = () => {
        return (
            <ValutazioneDellaCute
                cuteEAnnessi={this.props.esameObiettivo.cuteEAnnessi}
                onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                datiTrattamentiLesioniTrofiche={this.props.datiInformazioniDiBase.datiTrattamentiLesioniTrofiche}
                onChangeTrattamentiLesioniTrofiche={this.props.onChangeDatiInformazioniDiBase}
                openAccordion={this.state.accordion.openAccordionValutazioneDellaCute}
                onClickAccordion={this.onClickAccordion}
                pageState={this.props.pageState}
                onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                accordionReadOnly={this.props.accordionReadOnly}
            />
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"funzioneTegumentariaInfermiere"}
                title={"Funzione tegumentaria"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBodySezione()}</AccordionAvvioRichiesta>
        );
    }
}

FunzioneTegumentaria.propTypes = propTypes;
