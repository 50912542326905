import React from "react";
import PropTypes from "prop-types";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import aulssElenco from "../../../utils/dataset/aulssData/aulssElenco.json";
import {
    FieldsRow,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import Utils from "../../../utils/Utils";

const listaAulssSelect = aulssElenco.map(el => ({value: el.id, label: el.descrizione}));

export default function SoggettoRichiedenteCureDomiciliari(props) {
    let medico = props.datiMedico;

    return <AccordionSezioneRichiesta
        idAccordion={"soggettoRichiedenteCureDomiciliari"}
        title={"Soggetto richiedente cure domiciliari"}
        openAccordion={props.openAccordion}
        onClickAccordion={props.onClickAccordion}
        pageState={props.pageState}
        required={true}>
        <FieldsRow>
            <RoleBasedSelect
                fieldId={'TIT.SOGGETTORICHIEDENTECUREDOMICILIARI.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                id={"selectTitoloMedico"}
                options={OggettiUtili.titoloMedico}
                value={medico.titolo}
                onChange={(elem) => props.onChangeDatiMedico("titolo", elem)}
                isSearchable={false}
                noOptionsMessage={() => "Errore: titolo medico non esistente"}
                fieldLabel={"Titolo"}
                handleOnlyValue={true}
                field={"titoloMedico"}
                forceReadOnly={!props.editableFields.titolo}
                disableDoubleInput={true}
            />
        </FieldsRow>
        <FieldsRow>
            <RoleBasedInput
                fieldId={'COGN.SOGGETTORICHIEDENTECUREDOMICILIARI.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                placeholder="Inserisci cognome"
                field="cognome"
                onChange={(field, elem) => props.onChangeDatiMedico(field, elem.target.value)}
                id="cognomeMedico"
                value={medico.cognome}
                fieldLabel={"Cognome"}
                fieldRequired={true}
                forceReadOnly={!props.editableFields.cognome}
                disableDoubleInput={true}
            />
            <RoleBasedInput
                fieldId={'NOME.SOGGETTORICHIEDENTECUREDOMICILIARI.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                placeholder="Inserisci nome"
                field="nome"
                onChange={(field, elem) => props.onChangeDatiMedico(field, elem.target.value)}
                id="nomeMedico"
                value={medico.nome}
                fieldLabel={"Nome"}
                fieldRequired={true}
                forceReadOnly={!props.editableFields.nome}
                disableDoubleInput={true}
            />
            <RoleBasedCalendar
                fieldId={'DATA_NASC.SOGGETTORICHIEDENTECUREDOMICILIARI.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                id={"soggettoRichiedenteCureDomicialiariDataNascitaMedico"}
                placeholder={"gg/mm/aaaa"}
                onChange={(elem) => props.onChangeDatiMedico("dataNascita", elem?.target?.value)}
                value={medico.dataNascita}
                errorMessage={"Data non valida"}
                fieldLabel={'Data di nascita'}
                field={"dataNascita"}
                forceReadOnly={!props.editableFields.dataNascita}
                originalSyntax={'YYYY-MM-DD'}
                disableDoubleInput={true}
            />
            <RoleBasedInput
                fieldId={'COD_FISC.SOGGETTORICHIEDENTECUREDOMICILIARI.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                placeholder="Inserisci codice fiscale"
                field="identificativoUtente"
                onChange={(field, elem) => props.onChangeDatiMedico(field, elem.target.value)}
                id="inputCodiceFiscaleMedico"
                value={medico.identificativoUtente}
                maxlength={"16"}
                fieldLabel={"Codice fiscale"}
                validator={Utils.isValidCodiceFiscale}
                invalidText={"Inserire un codice fiscale valido"}
                fieldRequired={true}
                forceReadOnly={!props.editableFields.identificativoUtente}
                disableDoubleInput={true}
            />
        </FieldsRow>
        <FieldsRow>
            <RoleBasedInput
                fieldId={'PART_IVA.SOGGETTORICHIEDENTECUREDOMICILIARI.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                placeholder="Inserisci partita iva"
                field="partitaIva"
                onChange={(field, elem) => props.onChangeDatiMedico(field, elem.target.value)}
                id="inputPartitaIvaMedico"
                value={medico.partitaIva}
                maxlength={"11"}
                fieldLabel={"Partita IVA"}
                validator={Utils.controllaPartitaIVA}
                invalidText={"Inserire Partita IVA valida"}
                forceReadOnly={!props.editableFields.partitaIva}
                disableDoubleInput={true}
            />
            <RoleBasedInput
                fieldId={'COD_REG.SOGGETTORICHIEDENTECUREDOMICILIARI.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                placeholder="Inserisci Codice Regionale"
                field="codiceRegionale"
                onChange={(field, elem) => props.onChangeDatiMedico(field, elem.target.value)}
                id="inputCodiceRegionale"
                value={medico.codiceRegionale}
                fieldLabel={"Codice Regionale"}
                invalidText={"Inserire un Codice Regionale valido"}
                forceReadOnly={!props.editableFields.codiceregionale}
                disableDoubleInput={true}
            />
            <RoleBasedSelect
                fieldId={'AULSS.SOGGETTORICHIEDENTECUREDOMICILIARI.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                id={"selectAulss"}
                options={listaAulssSelect}
                value={medico.aulss}
                onChange={(elem) => props.onChangeDatiMedico("aulss", elem)}
                isSearchable={false}
                noOptionsMessage={() => "Errore: AULSS non esistente"}
                fieldLabel={"AULSS"}
                handleOnlyValue={true}
                field={"aulss"}
                forceReadOnly={!props.editableFields.aulss}
                disableDoubleInput={true}
            />
            <RoleBasedInput
                fieldId={'ALBO_PROV.SOGGETTORICHIEDENTECUREDOMICILIARI.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                placeholder="Inserisci ALBO Provinciale"
                field="alboProvinciale"
                onChange={(field, elem) => props.onChangeDatiMedico(field, elem.target.value)}
                id="inputAlboProvinciale"
                value={medico.alboProvinciale}
                fieldLabel={"ALBO Provinciale"}
                forceReadOnly={!props.editableFields.alboProvinciale}
                disableDoubleInput={true}
            />
        </FieldsRow>
        <FieldsRow>
            <RoleBasedInput
                fieldId={'N_ISCR.SOGGETTORICHIEDENTECUREDOMICILIARI.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                placeholder="Inserisci N° iscrizione"
                field="alboIscrizione"
                onChange={(field, elem) => props.onChangeDatiMedico(field, elem.target.value)}
                id="inputNumeroIscrizione"
                value={medico.alboIscrizione}
                fieldLabel={"N° iscrizione"}
                forceReadOnly={!props.editableFields.alboIscrizione}
                disableDoubleInput={true}
            />
        </FieldsRow>
    </AccordionSezioneRichiesta>;
}

SoggettoRichiedenteCureDomiciliari.propTypes = {
    pageState: PropTypes.string.isRequired,
    datiMedico: PropTypes.object.isRequired,
    editableFields: PropTypes.object.isRequired,
    onChangeDatiMedico: PropTypes.func.isRequired,
    openAccordion: PropTypes.bool.isRequired,
    onClickAccordion: PropTypes.func.isRequired
};
