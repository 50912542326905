import React, {Component, Fragment} from "react";
import {SessioneUtente} from "web-client-archetype";
import PropTypes from "prop-types";
import * as _ from "lodash";
import enumRichiesta from "../../enum/enumRichiesta"
import enumRiepilogo from "../../enum/enumRiepilogo"
import enumUtente from "../../enum/enumsUtente"
import AuthUtils from "../../utils/AuthUtils";
import FormRiepilogo from "./formRiepilogo/FormRiepilogo";
import AccordionHelper from "../../service/AccordionHelper";

const propTypes = {
    richiesta: PropTypes.any,
    richiestaMedico: PropTypes.any,
    pageState: PropTypes.string,
    forcedModalitaRiepilogo: PropTypes.string,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    firstPageName: PropTypes.string,
    persone: PropTypes.object,
    showOnlyRequiredFields: PropTypes.bool,
    previousPageOnlyRequiredFields: PropTypes.bool,
    lastPageNumber: PropTypes.number,
    listaCodiciFiscaliUtentiOnline: PropTypes.array,
    showMultiCompilationPages: PropTypes.bool
}

export const getModalitaRiepilogo = (forcedModalitaRiepilogo, richiesta) => {
    let modalita;
    if (forcedModalitaRiepilogo) {
        modalita = forcedModalitaRiepilogo;
    } else {
        if (richiesta.stato === enumRichiesta.stato.BOZZA || richiesta.stato === enumRichiesta.stato.BOZZA_PRE_DIALOGO) {
            modalita = enumRiepilogo.modalitaRiepilogo.FASE_RICHIESTA_MEDICO_RICHIEDENTE;
        } else if (isValutazioneUtenteLoggatoPresente(richiesta)) {
            let valutazioneUtenteLoggato = getValutazioneUtenteLoggato(richiesta);
            if (valutazioneUtenteLoggato?.stato === enumRichiesta.stato.CONFERMATA) {
                let delegaFisiatra = _.find(richiesta?.delega, delega => delega.profilo === enumUtente.profiliUtente.professionistaValutatore &&
                    delega.ruolo === enumUtente.ruoli.fisiatra);
                if(AuthUtils.hasUtenteCentraleAdi() || AuthUtils.hasUtenteRuoloMcp() || (delegaFisiatra?.valore && AuthUtils.hasUtenteRuoloFisiatra())){
                    modalita = enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_FORMALIZZATORE_COORDINATORE;
                } else {
                    modalita = enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_CONFERMATA;
                }
            } else {
                if (valutazioneUtenteLoggato?.profilo === enumUtente.profiliUtente.medicoRichiedente) {
                    modalita = enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_MEDICO_RICHIEDENTE;
                } else {
                    modalita = enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_VALUTATORE;
                }
            }
        } else if (AuthUtils.hasUtenteCentraleAdi() || AuthUtils.hasUtenteRuoloMcp() || AuthUtils.hasUtenteRuoloDirettoreDiDistretto() || AuthUtils.hasUtenteProfiloCoordinatore()
            || AuthUtils.hasUtenteProfiloReferenteAssistentiSociali()  || AuthUtils.hasUtenteProfiloDirettoreUO()) {
            modalita = enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_FORMALIZZATORE_COORDINATORE;
        } else if (AuthUtils.hasUtenteRuoloMedicoRichiedente() && richiesta.stato !== enumRichiesta.stato.BOZZA) {
            modalita = enumRiepilogo.modalitaRiepilogo.FASE_VALUTAZIONE_MEDICO_RICHIEDENTE_NON_VALUTATORE;
        }  else {
            throw new Error("Non è possibile mostrare la pagina Visualizza Riepilogo");
        }
    }

    return modalita;
}

const isValutazioneUtenteLoggatoPresente = (richiesta) => {
    return _.some(richiesta.valutazioni, valutazione => isUtenteLoggato(valutazione?.valutatore?.codiceFiscale));
}

const getValutazioneUtenteLoggato = (richiesta) => {
    return _.find(richiesta.valutazioni, valutazione => isUtenteLoggato(valutazione?.valutatore?.codiceFiscale));
}

const isUtenteLoggato = (codiceFiscale) => {
    return codiceFiscale === SessioneUtente.getInstance().idUtente;
}

export default class VisualizzaRiepilogo extends Component {
    state = {
        richiesta: null,
        richiestaMedico: null,
        modalitaRiepilogo: null
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setRichesta();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.richiesta, this.props.richiesta) || !_.isEqual(prevProps.richiestaMedico, this.props.richiestaMedico)) {
            this.setRichesta();
        }
        AccordionHelper.onScrollIntoAccordion(AccordionHelper.cloneIdAccordionToAddInformation, true);
    }

    setRichesta = () => {
        this.setState({richiesta: this.props.richiesta, richiestaMedico: this.props.richiestaMedico}, () => {
            this.setState({modalitaRiepilogo: getModalitaRiepilogo(this.props.forcedModalitaRiepilogo, this.state.richiesta)});
        });
    }

    renderForm() {
        return <FormRiepilogo
            richiesta={this.state.richiesta}
            richiestaMedico={this.state.richiestaMedico}
            modalita={this.state.modalitaRiepilogo}
            readOnly={this.props.readOnly}
            hideUnsetValues={this.props.hideUnsetValues}
            firstPageName={this.props.firstPageName}
            persone={this.props.persone}
            pageState={this.props.pageState}
            showOnlyRequiredFields={this.props.showOnlyRequiredFields}
            previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields}
            lastPageNumber={this.props.lastPageNumber}
            showMultiCompilationPages={this.props.showMultiCompilationPages}
        />
    }

    render() {
        return (
            <Fragment>{this.renderForm()}</Fragment>
        );
    }

}

VisualizzaRiepilogo.propTypes = propTypes;
