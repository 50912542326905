import React from "react";
import styles from "./AccordionAvvioRichiesta.module.css";
import PropTypes from "prop-types";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import {Icon} from "web-client-archetype";
import routePath from "../../utils/route/route-path.json";
import AccordionHelper from "../../service/AccordionHelper";

const propTypes = {
    idAccordion: PropTypes.any,
    title: PropTypes.any,
    subtitle: PropTypes.string,
    body: PropTypes.any,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    numberOfRequiredFieldsMissing: PropTypes.number,
    children: PropTypes.node.isRequired,
    addInformazioniAggiuntive: PropTypes.func,
    additionalClass: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    parentClass: PropTypes.string,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultProps = {
    numberOfRequiredFieldsMissing: null,
    additionalClass: null,
    parentClass: "",
    sezioneDatiRichiesta: false
}

export default class AccordionAvvioRichiesta extends React.Component {

    state = {
        openAccordion: this.props.openAccordion
    }

    canAddOtherInformation = false;

    isOpenAccordion = () => {
        let openAccordion = this.state.openAccordion;
        this.setState({openAccordion: !openAccordion},
            () => this.props.onClickAccordion(this.props.field, !openAccordion))
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.openAccordion !== this.props.openAccordion) {
            this.setState({openAccordion: this.props.openAccordion}, () => {
                if (this.props.openAccordion && !this.context.scrollToHead) {
                    const idAccordion = (this.props.sezioneDatiRichiesta ? 'datiRichiesta' : '') + this.props.idAccordion;
                    AccordionHelper.onScrollIntoAccordion(idAccordion);
                }
            })
        }
    }

    getTextRequiredFieldsMissing = () => {
        if (this.props.numberOfRequiredFieldsMissing === 1) {
            return `${this.props.numberOfRequiredFieldsMissing} campo obbligatorio mancante`;
        } else {
            return `${this.props.numberOfRequiredFieldsMissing} campi obbligatori mancanti`;
        }
    }

    onToggle = (canRedirectToAccordionPage, idAccordion) => {
        if (canRedirectToAccordionPage) {
            this.canAddOtherInformation = true;
            AccordionHelper.setLastSurveyCompiled(null);
            this.props.addInformazioniAggiuntive(idAccordion);
        } else if (!this.canAddOtherInformation) this.isOpenAccordion();
    }

    render() {
        const excludeLink = !window.location.href.includes(routePath.anagrafica_medico_richiedente) && !window.location.href.includes(routePath.compila_chiusura_predialogo);
        const showAddOtherInformationLink = this.context.showOnlyRequiredFields && excludeLink && this.props.addInformazioniAggiuntive;
        const idAccordion = (this.props.sezioneDatiRichiesta ? 'datiRichiesta' : '') + this.props.idAccordion;

        const urlReadOnly = [routePath.accedi_valutazione_multidimensionale, routePath.visualizza_valutazione_multidimensionale, routePath.verifica_valutazione];
        const linkWithMoreInfo = `${urlReadOnly.some(url => window.location.href.includes(url)) ? 'Visualizza' : 'Inserisci'} informazioni aggiuntive`;

        return (
            <div id={idAccordion}
                 className={"collapse-div collapse-left-icon my-3 " + styles.collapseHeader}>
                <div className={"collapse-header p-2"}>
                    <button
                        style={{textDecoration: "none", outline: "none", borderTop: "none", position: "relative"}}
                        data-toggle="collapse"
                        aria-expanded={this.state.openAccordion}
                        onClick={() => this.onToggle(false, idAccordion)}>
                        
                        <div className={"row d-flex align-items-center"}>
                            <div className={"col-6"}>
                                <div className="col-12">
                                    <span className={styles.title}>{this.props.title}</span>
                                </div>
                                {this.props.subtitle &&
                                    <div className={"col-12 ml-n1 mr-auto"}>
                                        <h3 className={'font-weight-light p-0 m-0'}>{this.props.subtitle}</h3>
                                    </div>}
                            </div>
                    
                            {showAddOtherInformationLink &&
                                <div className={"col-3"}>
                                    <span className={styles.aggiungiInformazioneAggiuntiva}
                                          onClick={() => this.onToggle(true, idAccordion)}>
                                        {linkWithMoreInfo}
                                        <Icon id={"it-external-link"}
                                              className={["icon-sm", "mb-2"]}
                                              style={{fill: "#176A65"}}></Icon>
                                    </span>
                                </div>}

                            {this.props.numberOfRequiredFieldsMissing != null && parseInt(this.props.numberOfRequiredFieldsMissing, 10) !== 0 &&
                                <div className={"text-right pr-5 " + (showAddOtherInformationLink ? "col-3" : "col-6")}>
                                    <label className={styles.requiredFieldsMissing}>
                                        {this.getTextRequiredFieldsMissing()}
                                    </label>
                                </div>}
                        </div>
                        
                    </button>
                </div>
                <div className={"collapse " + (this.state.openAccordion ? "show" : "")}>
                    <div className={["collapse-body", "my-2","p-0", this.props.additionalClass].join(' ').trim()}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

AccordionAvvioRichiesta.propTypes = propTypes;
AccordionAvvioRichiesta.defaultProps = defaultProps;
AccordionAvvioRichiesta.contextType = RoleBasedContext;
