import React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import Utils from "../../../utils/Utils";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {
    FieldsRow,
    RoleBasedInput,
    RoleBasedPhoneInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import {isMadrelingua} from "../datiGenerali/DatiGenerali";

const propTypes = {
    datiPadreForm: PropTypes.any,
    onChangeDatiPadre: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onValidaSezione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    forceReadOnly: PropTypes.bool,
    mascheraModificabilita: PropTypes.any,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export const validaCodiceFiscale = (field) => {
    if (!Utils.isStringEmptyOrNullOrUndefined(field) && !Utils.isValidCodiceFiscale(field)) {
        return "Inserire un codice fiscale valido";
    } else {
        return null;
    }
}

export const validaCampi = (object, nomeValidoCondition, cognomeValidoCondition, defaultCodiceFiscaleValidoCondition,
                            telefonoValidoCondition, isIndicatoComeRiferimentoPerPaziente, pageState) => {

    const isPageStateRichiesta = Utils.isStateRichiesta(pageState);
    let isSezioneValida;
    let isNomeValido = false;
    let isCognomeValido = false;
    let isCodiceFiscaleValido = false;
    let personaDiRiferimentoValida = false;
    let numeroCampiObbligatoriDaValorizzare = 0;
    Object.keys(object).forEach(key => {
        if (key.startsWith("nome")) {
            if (nomeValidoCondition)
                isNomeValido = true;
            else {
                numeroCampiObbligatoriDaValorizzare += isPageStateRichiesta ? 1 : 0;
                isNomeValido = false;
            }
        }
        if (key.startsWith("cognome")) {
            if (cognomeValidoCondition)
                isCognomeValido = true;
            else {
                numeroCampiObbligatoriDaValorizzare += isPageStateRichiesta ? 1 : 0;
                isCognomeValido = false;
            }
        }
        if (key.startsWith("identificativoUtente")) {
            isCodiceFiscaleValido = defaultCodiceFiscaleValidoCondition;
        }
        if (key.includes("Riferimento")) {
            const isPersonaRiferimento = object[isIndicatoComeRiferimentoPerPaziente] === true || object[isIndicatoComeRiferimentoPerPaziente]?.value;
            if (isPersonaRiferimento) {
                if (!telefonoValidoCondition) {
                    numeroCampiObbligatoriDaValorizzare += isPageStateRichiesta ? 1 : 0;
                    personaDiRiferimentoValida = false;
                } else
                    personaDiRiferimentoValida = true;
            } else {
                personaDiRiferimentoValida = true;
            }
        }
    });

    isSezioneValida = isNomeValido && isCognomeValido && isCodiceFiscaleValido && personaDiRiferimentoValida;
    return {
        isSezioneValida,
        numeroCampiObbligatoriDaValorizzare
    };
}

export const calcolaNumeroCampiObbligatoriDaValorizzareAndValida = (props, object, id,
                                                                    isPersonaIndicataComeRiferimentoPerPaziente,
                                                                    nomeSottosezione, flagValidaSezione, pageState) => {
    let objectClone = _.cloneDeep(object);
    let validazioneCampi;

    delete objectClone[id];
    let defaultCodiceFiscaleValidoCondition = Utils.isStringEmptyOrNullOrUndefined(objectClone.identificativoUtente) || Utils.isValidCodiceFiscale(objectClone.identificativoUtente);
    let telefonoValidoCondition = !Utils.isStringEmptyOrNullOrUndefined(objectClone.telefono) && Utils.isValidTelefono(objectClone.telefono);

    let nomeValidoCondition = !Utils.isStringEmptyOrNullOrUndefined(objectClone.nome);
    let cognomeValidoCondition = !Utils.isStringEmptyOrNullOrUndefined(objectClone.cognome);
    validazioneCampi = validaCampi(objectClone, nomeValidoCondition, cognomeValidoCondition,
        defaultCodiceFiscaleValidoCondition, telefonoValidoCondition,
        isPersonaIndicataComeRiferimentoPerPaziente, pageState);

    props.onChangeRequiredFieldsNumber(nomeSottosezione, validazioneCampi.numeroCampiObbligatoriDaValorizzare);
    props.onValidaSezione(flagValidaSezione, validazioneCampi.isSezioneValida);
}

export default class Padre extends AccordionSecondLevel {
    state = {
        datiPadre: _.cloneDeep(this.props.datiPadreForm)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiPadreForm, this.props.datiPadreForm))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiPadre = _.cloneDeep(this.props.datiPadreForm);
        datiPadre.isPadreIndicatoComeRiferimentoPerPaziente = oggettiUtili.rispostaBreve.find(el => el.value === datiPadre.isPadreIndicatoComeRiferimentoPerPaziente);
        datiPadre.madrelingua = oggettiUtili.rispostaBreve.find(el => el.value === datiPadre.madrelingua);
        datiPadre.livello = oggettiUtili.linguaItalianaParlataCompresa.find(el => el.value === datiPadre.livello);
        if (datiPadre.descrizione.length > 0) {
            datiPadre.descrizione = datiPadre.descrizione.map(el => oggettiUtili.elencoLingue.find(el2 => el2.value === el));
        }
        this.setState({datiPadre: datiPadre}, () => {
            calcolaNumeroCampiObbligatoriDaValorizzareAndValida(this.props, this.state.datiPadre, "id",
                "isPadreIndicatoComeRiferimentoPerPaziente",
                "sottoSezionePadre", "isPadreValido", this.props.pageState);
        });
    }

    handleAggiornaForm = (field, elem) => {
        let datiPadre = _.cloneDeep(this.state.datiPadre);
        datiPadre[field] = elem;

        if (field === "isPadreIndicatoComeRiferimentoPerPaziente" && !_.isEqual(elem, this.state.datiPadre[field])) {
            datiPadre.indirizzo = "";
            datiPadre.numero = "";
            datiPadre.telefono = "";
            datiPadre.email = "";
        }
        if (field === "madrelingua" && !_.isEqual(elem, this.state.datiPadre[field])) {
            datiPadre.livello = null;
            datiPadre.descrizione = [];
        }

        this.setState({datiPadre: datiPadre}, () => {
            this.controllaCampi();
        });
    }

    controllaCampi = () => {
        let datiPadre = _.cloneDeep(this.state.datiPadre);

        // formatto l'oggetto per inviarlo come parametro di input al metodo salvaBozza nel componente padre "CompilaRichiesta"
        datiPadre.isPadreIndicatoComeRiferimentoPerPaziente = datiPadre.isPadreIndicatoComeRiferimentoPerPaziente?.value;
        datiPadre.madrelingua = datiPadre.madrelingua?.value;
        datiPadre.livello = datiPadre.livello?.value;
        if (datiPadre.descrizione.length > 0) {
            datiPadre.descrizione = datiPadre.descrizione.map(el => el?.value ?? null);
        }
        this.props.onChangeDatiPadre("padre", _.mapValues(datiPadre, v => Utils.isStringEmptyOrNullOrUndefined(v) ? null : v));

        // controllo la validità dei vari campi dell'oggetto datiPadre e nel caso in cui ci siano campi obbligatori non valorizzati li conto
        calcolaNumeroCampiObbligatoriDaValorizzareAndValida(this.props, datiPadre, "id",
            "isPadreIndicatoComeRiferimentoPerPaziente",
            "sottoSezionePadre", "isPadreValido", this.props.pageState);
    }

    renderLinguaParlata = (linguaParlata, index) => {
        return (
            <RoleBasedSelect
                fieldId={'LING_PARL.PADRE.ANAGRAFEPAZIENTE'}
                pageState={this.props.pageState}
                parentClass={"mb-0"}
                id={"selectLinguaParlata"}
                className={"selectLinguaParlata"}
                options={oggettiUtili.elencoLingue}
                value={linguaParlata}
                onChange={(elem) => {
                    let datiPadre = _.cloneDeep(this.state.datiPadre);
                    datiPadre.descrizione[index] = elem;
                    this.setState({datiPadre}, () => this.controllaCampi());
                }}
                isSearchable={false}
                fieldLabel={"Lingua parlata"}
                field={"linguaParlata"}
                disableDoubleInput={true}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            />
        );
    }

    addNewLinguaParlata = () => {
        let datiPadre = _.cloneDeep(this.state.datiPadre);
        datiPadre.descrizione.push(null);
        this.setState({datiPadre}, () => this.controllaCampi())
    }

    removeLinguaParlata = (index) => {
        let datiPadre = _.cloneDeep(this.state.datiPadre);
        datiPadre.descrizione.splice(index, 1);
        this.setState({datiPadre}, () => this.controllaCampi());
    }

    renderInput = () => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'COGN_PADRE.PADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci cognome padre"
                        field="cognome"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="inputCognomePadre"
                        value={this.state.datiPadre.cognome}
                        fieldLabel={"Cognome Padre"}
                        fieldRequired={true}
                        forceReadOnly={this.props.forceReadOnly || this.props.mascheraModificabilita?.anagrafePaziente?.padre?.cognome}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={true}
                    />
                    <RoleBasedInput
                        fieldId={'NOME_PADRE.PADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci nome padre"
                        field="nome"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="inputNomePadre"
                        value={this.state.datiPadre.nome}
                        fieldLabel={"Nome Padre"}
                        fieldRequired={true}
                        forceReadOnly={this.props.forceReadOnly || this.props.mascheraModificabilita?.anagrafePaziente?.padre?.nome}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={true}
                    />
                    <RoleBasedInput
                        fieldLabel={"Codice fiscale Padre"}
                        fieldId={'COD_FISC_PADRE.PADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci codice fiscale padre"
                        field="identificativoUtente"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="inputCodiceFiscalePadre"
                        value={this.state.datiPadre.identificativoUtente}
                        maxlength={"16"}
                        validator={Utils.isValidCodiceFiscale}
                        invalidText={"Inserire un codice fiscale valido"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PERS_IND_RIF_PAZ.PADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        id={"selectPersonaRiferimentoPadre"}
                        className={"selectPersonaRiferimentoPadre"}
                        options={oggettiUtili.rispostaBreve}
                        value={this.state.datiPadre.isPadreIndicatoComeRiferimentoPerPaziente}
                        onChange={(elem) => this.handleAggiornaForm("isPadreIndicatoComeRiferimentoPerPaziente", elem)}
                        isSearchable={false}
                        fieldLabel={"Persona indicata come riferimento per il paziente?"}
                        fieldRequired={true}
                        field={"isPadreIndicatoComeRiferimentoPerPaziente"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldId={'INDIR_PADRE.PADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci indirizzo padre"}
                        field={"indirizzo"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"inputIndirizzoPadre"}
                        value={this.state.datiPadre.indirizzo}
                        disabled={this.state.datiPadre.isPadreIndicatoComeRiferimentoPerPaziente?.value === false}
                        fieldLabel={"Indirizzo Padre"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldId={"NUMEROCIVICO_PADRE.PADRE.ANAGRAFEPAZIENTE"}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci numero civico"}
                        field={"numero"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"inputNumeroCivico"}
                        value={this.state.datiPadre.numero}
                        disabled={this.state.datiPadre.isPadreIndicatoComeRiferimentoPerPaziente?.value === false}
                        fieldLabel={'N°'}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedPhoneInput
                        fieldLabel={"Telefono Padre"}
                        fieldRequired={this.state.datiPadre.isPadreIndicatoComeRiferimentoPerPaziente?.value === true && Utils.isStateRichiesta(this.props.pageState)}
                        fieldId={'TEL_PADRE.PADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci numero di telefono"}
                        field={"telefono"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"inputNumeroTelefonoPadre"}
                        value={this.state.datiPadre.telefono}
                        disabled={this.state.datiPadre.isPadreIndicatoComeRiferimentoPerPaziente?.value === false}
                        phonePrefix={null}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldLabel={"E-mail Padre"}
                        fieldId={'EMAIL_PADRE.PADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci e-mail padre"}
                        field={"email"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"inputEmailPadre"}
                        value={this.state.datiPadre.email}
                        disabled={this.state.datiPadre.isPadreIndicatoComeRiferimentoPerPaziente?.value === false}
                        validator={Utils.isValidEmail}
                        invalidText={"Inserire un'email valida"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedSelect
                        fieldId={'ITA_MADREL.PADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        id={"selectMadrelingua"}
                        className={"selectMadrelingua"}
                        options={oggettiUtili.rispostaBreve}
                        value={this.state.datiPadre.madrelingua}
                        onChange={(elem) => this.handleAggiornaForm("madrelingua", elem)}
                        isSearchable={false}
                        fieldLabel={"Italiano madrelingua?"}
                        field={"madrelingua"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                {!isMadrelingua(this.state.datiPadre.madrelingua) &&
                <FieldsRow containerClass={"align-self-center"}
                           childClass={"align-self-start"}>
                    <RoleBasedSelect
                        fieldId={'LING_ITA_PARL_COMPR.PADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        id={"selectLinguaItalianaParlataCompresa"}
                        className={"selectLinguaItalianaParlataCompresa"}
                        options={oggettiUtili.linguaItalianaParlataCompresa}
                        value={this.state.datiPadre.livello}
                        onChange={(elem) => this.handleAggiornaForm("livello", elem)}
                        isSearchable={false}
                        fieldLabel={"Lingua italiana parlata e compresa"}
                        field={"livello"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <SectionList
                        title={'Lingua parlata'}
                        data={this.state.datiPadre.descrizione}
                        renderSection={this.renderLinguaParlata}
                        addNewSectionCallback={this.addNewLinguaParlata}
                        removeSectionCallback={this.removeLinguaParlata}
                        addButtonVisibilityHandler={!isMadrelingua(this.state.datiPadre.madrelingua)
                        && (this.state.datiPadre.descrizione?.length === 0 || this.state.datiPadre.descrizione?.every(el => !Utils.isObjectNull(el)))}
                        pageState={this.props.pageState}
                        keyFieldId={'LING_PARL.PADRE.ANAGRAFEPAZIENTE'}
                        indentOffset={0}
                        field={'descrizione'}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>}
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"padre"}
                title={"Padre"}
                required={true}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderInput()}</AccordionSezioneRichiesta>
        );
    }
}

Padre.propTypes = propTypes;
