import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import Utils from "../../../utils/Utils";
import { RoleBasedContext } from "../../../utils/RoleBasedContext";

const propTypes = {
    datiParametriVitali: PropTypes.any,
    onChangeDatiParametriVitali: PropTypes.func,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    mascheraModificabilita: PropTypes.any,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    punteggioTestEsasr: PropTypes.any
}

const nomeAccordion = "PARAMETRI";

export default class Parametri extends AccordionSecondLevel {

    state = {
        dati: _.cloneDeep(this.props.datiParametriVitali),
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    inizializzaForm = () => {
        let dati = _.cloneDeep(this.state.dati);
        this.setState({dati: dati});
    }

    handleAggiornaForm = (field, elem, fieldCondizioneRilevata = null) => {
        console.log(field, elem);
        this.setState(({dati}) => {
            dati[field] = elem;
            if(elem === "" || elem === null && fieldCondizioneRilevata) dati[fieldCondizioneRilevata] = null;
            return {dati}
        }, () => {
            let dati = _.cloneDeep(this.state.dati)
            this.props.onChangeDatiParametriVitali("datiParametri", dati)
        })
    }

    showSesione(fielId, forzeCondition) {
        if(isFieldIdWriteable(fielId, this.props.pageState, this.context.forceUserRole)) {
            return true;
        } else {
            return forzeCondition
        } 
    }

    renderBodySezione() {
        let dati = _.cloneDeep(this.state.dati);
        let hideUnsetValues = this.context.hideUnsetValues || this.props.hideUnsetValues || this.props.forceReadOnly || this.context.forceReadOnly

        return <>
            { ((!hideUnsetValues && this.showSesione('FR.PARAMETRI.PARAMETRIVITALI', dati.respirazioneFrequenza || dati.respirazioneDataMisurazione || dati.respirazioneCondizioneRilevata || dati.respirazioneDaAttenzionare))
                || (hideUnsetValues && (dati.respirazioneFrequenza || dati.respirazioneDataMisurazione || dati.respirazioneCondizioneRilevata || dati.respirazioneDaAttenzionare))) &&
            <>

                <div style={{fontWeight: "bold"}} className="mb-1 mt-1">Frequenza respiratoria</div>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'FR.PARAMETRI.PARAMETRIVITALI'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci frequenza respiratoria"}
                        field={"respirazioneFrequenza"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, "respirazioneCondizioneRilevata")}
                        id={"inputAlterazioneFrequenzaRespiratoria"}
                        value={dati.respirazioneFrequenza}
                        fieldLabel={"Frequenza respiratoria (Atti/min)"}
                        type={"number"}
                        min={0}
                    forceReadOnly={this.props.forceReadOnly || this.props.mascheraModificabilita?.parametriVitali?.datiParametri?.respirazioneFrequenza}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    /> 

                    <RoleBasedCalendar
                        fieldId={"DATA_MISUR_FR.PARAMETRI.PARAMETRIVITALI"}
                        pageState={this.props.pageState}
                        forceReadOnly={false}
                        id={"parametriDataMisurazioneFrequenzaRespiratoria"}
                        value={dati.respirazioneDataMisurazione}
                        field={"respirazioneDataMisurazione"}
                        onChange={(elem) => this.handleAggiornaForm("respirazioneDataMisurazione", elem.currentTarget.value)}
                        errorMessage={"Data non valida"}
                        error={(Utils.timeDifference(Date.now(), new Date(Utils.convertDDMMYYYYDateToIsoDate(dati.respirazioneDataMisurazione)), true) < 0)}
                        fieldLabel={'Data misurazione'}
                        fieldRequired={false}
                        disableDoubleInput={true}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                </FieldsRow>

                {(dati.respirazioneFrequenza !== null && dati.respirazioneFrequenza !== "") &&
                    (<CondizioneRilevataSection
                        ambito={nomeAccordion}
                        parentFieldId={'PARAMETRI.PARAMETRIVITALI'}
                        fieldsSuffix={'FR'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("respirazioneCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.respirazioneCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("respirazioneDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.respirazioneDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        fieldJsonPathPrefix={"respirazione"}
                    />)}

            </>}
            
            {(!hideUnsetValues && this.showSesione('SPO2.PARAMETRI.PARAMETRIVITALI', dati.spo2 || dati.spo2OssigenoTerapia || dati.spo2DataMisurazione || dati.spo2CondizioneRilevata || dati.spo2DaAttenzionare)
                || (hideUnsetValues && (dati.spo2 || dati.spo2OssigenoTerapia || dati.spo2DataMisurazione || dati.spo2CondizioneRilevata || dati.spo2DaAttenzionare))) &&
            <>
               
                <div className={"box-accordion-header mb-1 mt-3"} style={{fontWeight: "bold"}}>SpO2</div>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'SPO2.PARAMETRI.PARAMETRIVITALI'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci SpO2"}
                        field={"spo2"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value,  "spo2CondizioneRilevata")}
                        id={"inputSpo2"}
                        value={dati.spo2}
                        fieldLabel={"SpO2 (%)"}
                        type={"number"}
                        min={"0"}
                        max={"100"}
                        forceReadOnly={this.props.forceReadOnly || this.props.mascheraModificabilita?.parametriVitali?.datiParametri?.spo2}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                    <RoleBasedSelect
                        fieldId={'IN_CORSO_DI_O2TERAPIA.PARAMETRI.PARAMETRIVITALI'}
                        pageState={this.props.pageState}
                        id={"selectOssigenoTerapia"}
                        options={OggettiUtili.rispostaBreve}
                        value={dati.spo2OssigenoTerapia}
                        onChange={(elem) => this.handleAggiornaForm("spo2OssigenoTerapia", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: In corso di ossigeno terapia non esistente"}
                        fieldLabel={"In corso di ossigeno terapia"}
                        forceReadOnly={this.props.forceReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"spo2OssigenoTerapia"}
                    />
                    <RoleBasedCalendar
                        fieldId={"DATA_MISUR_SPO2.PARAMETRI.PARAMETRIVITALI"}
                        pageState={this.props.pageState}
                        id={"parametriSpo2DataMisurazione"}
                        value={dati.spo2DataMisurazione}
                        field={"spo2DataMisurazione"}
                        onChange={(elem) => this.handleAggiornaForm("spo2DataMisurazione", elem.currentTarget.value)}
                        errorMessage={"Data non valida"}
                        error={(Utils.timeDifference(Date.now(), new Date(Utils.convertDDMMYYYYDateToIsoDate(dati.spo2DataMisurazione)), true) < 0)}
                        fieldLabel={'Data misurazione'}
                        fieldRequired={false}
                        disableDoubleInput={true}
                        hideUnsetValues={this.props.hideUnsetValues}
                        forceReadOnly={this.props.forceReadOnly }
                    />
                </FieldsRow>

                {((dati.spo2 !== null && dati.spo2 !== "") || (dati.spo2OssigenoTerapia !== null && dati.spo2OssigenoTerapia !== "")) &&
                    <CondizioneRilevataSection
                        ambito={'PARAMETRI'}
                        fieldsSuffix={'SPO2'}
                        parentFieldId={'PARAMETRI.PARAMETRIVITALI'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("spo2CondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.spo2CondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("spo2DaAttenzionare", elem)}
                        valueDaAttenzionare={dati.spo2DaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={'spo2'}
                    />
                }
            </>}

            { (!hideUnsetValues && this.showSesione('PA_SIST.PARAMETRI.PARAMETRIVITALI', dati.pressioneArteriosaSistolica || dati.pressioneArteriosaDiastolica || dati.pressioneDataMisurazione || dati.pressioneCondizioneRilevata || dati.pressioneDaAttenzionare)
                || (hideUnsetValues && (dati.pressioneArteriosaSistolica || dati.pressioneArteriosaDiastolica || dati.pressioneDataMisurazione || dati.pressioneCondizioneRilevata || dati.pressioneDaAttenzionare))) &&
            <>
                <div className={"box-accordion-header mb-1 mt-3"} style={{fontWeight: "bold"}}>Pressione</div>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'PA_SIST.PARAMETRI.PARAMETRIVITALI'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci pressione arteriosa - sistolica (mmHg)"}
                        field={"pressioneArteriosaSistolica"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, "pressioneCondizioneRilevata")}
                        id={"inputPressioneArteriosaSistolica"}
                        value={dati.pressioneArteriosaSistolica}
                        fieldLabel={"Pressione arteriosa - Sistolica (mmHg)"}
                        type={"number"}
                        min={0}
                        forceReadOnly={this.props.forceReadOnly || this.props.mascheraModificabilita?.parametriVitali?.datiParametri?.pressioneArteriosaSistolica}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    /> 
                    <RoleBasedInput
                        fieldId={'PA_DIAST.PARAMETRI.PARAMETRIVITALI'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci pressione arteriosa - diastolica (mmHg)"}
                        field={"pressioneArteriosaDiastolica"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"inputPressioneArteriosaDiastolica"}
                        value={dati.pressioneArteriosaDiastolica}
                        fieldLabel={"Pressione arteriosa - Diastolica (mmHg)"}
                        type={"number"}
                        min={0}
                        forceReadOnly={this.props.forceReadOnly || this.props.mascheraModificabilita?.parametriVitali?.datiParametri?.pressioneArteriosaDiastolica}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    /> 
                    <RoleBasedCalendar
                        fieldId={"DATA_MISUR_PA.PARAMETRI.PARAMETRIVITALI"}
                        pageState={this.props.pageState}
                        id={"parametriPressioneDataMisurazione"}
                        value={dati.pressioneDataMisurazione}
                        field={"pressioneDataMisurazione"}
                        onChange={(elem) => this.handleAggiornaForm("pressioneDataMisurazione", elem.currentTarget.value)}
                        errorMessage={"Data non valida"}
                        error={(Utils.timeDifference(Date.now(), new Date(Utils.convertDDMMYYYYDateToIsoDate(dati.pressioneDataMisurazione)), true) < 0)}
                        fieldLabel={'Data misurazione'}
                        fieldRequired={false}
                        disableDoubleInput={true}
                        hideUnsetValues={this.props.hideUnsetValues}
                        forceReadOnly={this.props.forceReadOnly}
                    /> 
                </FieldsRow>

                {((dati.pressioneArteriosaSistolica !== null && dati.pressioneArteriosaSistolica !== "") || (dati.pressioneArteriosaDiastolica !== null && dati.pressioneArteriosaDiastolica !== "")) && 
                        <CondizioneRilevataSection
                            ambito={'PARAMETRI'}
                            fieldsSuffix={'PA'}
                            parentFieldId={'PARAMETRI.PARAMETRIVITALI'}

                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("pressioneCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.pressioneCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("pressioneDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.pressioneDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'pressione'}
                        />
                }
            </>}
            
            { (!hideUnsetValues && this.showSesione('FC.PARAMETRI.PARAMETRIVITALI', dati.frequenzaCardiaca || dati.frequenzaCardiacaDataMisurazione || dati.frequenzaCardiacaCondizioneRilevata || dati.frequenzaCardiacaDaAttenzionare)
                || (hideUnsetValues && (dati.frequenzaCardiaca || dati.frequenzaCardiacaDataMisurazione || dati.frequenzaCardiacaCondizioneRilevata || dati.frequenzaCardiacaDaAttenzionare))) &&
            <>
                <div className={"box-accordion-header mb-1 mt-3"} style={{fontWeight: "bold"}}>Frequenza cardiaca</div> 
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'FC.PARAMETRI.PARAMETRIVITALI'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci frequenza cardiaca (Battiti/min)"}
                        field={"frequenzaCardiaca"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, "frequenzaCardiacaCondizioneRilevata")}
                        id={"inputFrequenzaCardiaca"}
                        value={dati.frequenzaCardiaca}
                        fieldLabel={"Frequenza cardiaca (Battiti/min)"}
                        type={"number"}
                        min={0}
                        forceReadOnly={this.props.forceReadOnly || this.props.mascheraModificabilita?.parametriVitali?.datiParametri?.frequenzaCardiaca}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                    <RoleBasedCalendar
                        fieldId={"DATA_MISUR_FC.PARAMETRI.PARAMETRIVITALI"}
                        pageState={this.props.pageState}
                        forceReadOnly={false}
                        id={"parametriFrequenzaCardiacaDataMisurazione"}
                        value={dati.frequenzaCardiacaDataMisurazione}
                        field={"frequenzaCardiacaDataMisurazione"}
                        onChange={(elem) => this.handleAggiornaForm("frequenzaCardiacaDataMisurazione", elem.currentTarget.value)}
                        errorMessage={"Data non valida"}
                        error={(Utils.timeDifference(Date.now(), new Date(Utils.convertDDMMYYYYDateToIsoDate(dati.frequenzaCardiacaDataMisurazione)), true) < 0)}
                        fieldLabel={'Data misurazione'}
                        fieldRequired={false}
                        disableDoubleInput={true}
                        hideUnsetValues={this.props.hideUnsetValues}
                    /> 
                </FieldsRow>

                {(dati.frequenzaCardiaca !== null && dati.frequenzaCardiaca !== "") &&
                    (
                        <CondizioneRilevataSection
                            ambito={'PARAMETRI'}
                            fieldsSuffix={'FC'}
                            parentFieldId={'PARAMETRI.PARAMETRIVITALI'}

                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("frequenzaCardiacaCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.frequenzaCardiacaCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("frequenzaCardiacaDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.frequenzaCardiacaDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'pressione'}
                        />
                    )
                }
            </>}

            { (!hideUnsetValues && this.showSesione('TEMPERATURA.PARAMETRI.PARAMETRIVITALI', dati.temperatura || dati.temperaturaDataMisurazione || dati.temperaturaCondizioneRilevata || dati.temperaturaDaAttenzionare)
                || (hideUnsetValues && (dati.temperatura || dati.temperaturaDataMisurazione || dati.temperaturaCondizioneRilevata || dati.temperaturaDaAttenzionare))) &&
            <>
                <div className={"box-accordion-header mb-1 mt-3"} style={{fontWeight: "bold"}}>Temperatura</div>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'TEMPERATURA.PARAMETRI.PARAMETRIVITALI'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci temperatura (°C)"}
                        field={"temperatura"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, "temperaturaCondizioneRilevata")}
                        id={"inputTemperatura"}
                        value={dati.temperatura}
                        fieldLabel={"Temperatura (°C)"}
                        type={"number"}
                        forceReadOnly={this.props.forceReadOnly || this.props.mascheraModificabilita?.parametriVitali?.datiParametri?.temperatura}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    /> 
                    <RoleBasedCalendar
                        fieldId={"DATA_MISUR_TEMP.PARAMETRI.PARAMETRIVITALI"}
                        pageState={this.props.pageState}
                        forceReadOnly={false}
                        id={"parametriTemperaturaDataMisurazione"}
                        value={dati.temperaturaDataMisurazione}
                        field={"temperaturaDataMisurazione"}
                        onChange={(elem) => this.handleAggiornaForm("temperaturaDataMisurazione", elem.currentTarget.value)}
                        errorMessage={"Data non valida"}
                        error={(Utils.timeDifference(Date.now(), new Date(Utils.convertDDMMYYYYDateToIsoDate(dati.temperaturaDataMisurazione)), true) < 0)}
                        fieldLabel={'Data misurazione'}
                        fieldRequired={false}
                        disableDoubleInput={true}
                        hideUnsetValues={this.props.hideUnsetValues}
                    /> 
                </FieldsRow>

                {(dati.temperatura !== null && dati.temperatura !== "") && (
                    <CondizioneRilevataSection
                        ambito={'PARAMETRI'}
                        fieldsSuffix={'TEMPERATURA'}
                        parentFieldId={'PARAMETRI.PARAMETRIVITALI'}

                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("temperaturaCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.temperaturaCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("temperaturaDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.temperaturaDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={'pressione'}
                    />
                )}
            </>}

            { (!hideUnsetValues && this.showSesione('INTESITA_DEL_DOLORE.PARAMETRI.PARAMETRIVITALI',dati.intensitaDolore || this.props.punteggioTestEsasr || dati.doloreDataMisurazione || dati.dolereSede || dati.doloreCondizioneRilevata || dati.doloreDaAttenzionare)
                || (hideUnsetValues && (dati.intensitaDolore || this.props.punteggioTestEsasr || dati.doloreDataMisurazione || dati.dolereSede || dati.doloreCondizioneRilevata || dati.doloreDaAttenzionare))) &&
            <>
                <div className="mt-3" style={{fontWeight: "bold"}}>Dolore</div>
                <FieldsRow>

                    <RoleBasedInput
                        fieldId={"INTESITA_DEL_DOLORE.PARAMETRI.PARAMETRIVITALI"}
                        pageState={this.props.pageState}
                        parentClass={"mb-0"}
                        value={dati.intensitaDolore}
                        placeholder={"-"}
                        field={'intensitaDolore'}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, "doloreCondizioneRilevata")}
                        id={"inputIntensitaDolore"}
                        fieldLabel={"Intensità del dolore"}
                        type={"number"}
                        min={0}
                        max={10}
                        forceReadOnly={this.props.forceReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    /> 

                    {this.props.punteggioTestEsasr && <RoleBasedSelect
                        handleOnlyValue={true}
                        fieldId={'DOLORE_ESASR.PARAMETRI.PARAMETRIVITALI'}
                        pageState={this.props.pageState}
                        id={"selectDolore"}
                        options={OggettiUtili.punteggioAnsia}
                        value={this.props.punteggioTestEsasr}
                        onChange={(elem) => this.handleAggiornaForm("dolore", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Punteggio dolore non esistente"}
                        fieldLabel={"Dolore (Punteggio Test ESASr)"}
                        forceReadOnly={this.props.forceReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"dolore"}
                    />}

                    <RoleBasedCalendar
                        fieldId={"DATA_MISUR_DOLORE.PARAMETRI.PARAMETRIVITALI"}
                        pageState={this.props.pageState}
                        forceReadOnly={false}
                        id={"parametriDoloreDataMisurazione"}
                        value={dati.doloreDataMisurazione}
                        field={"doloreDataMisurazione"}
                        onChange={(elem) => this.handleAggiornaForm("doloreDataMisurazione", elem.currentTarget.value)}
                        errorMessage={"Data non valida"}
                        error={(Utils.timeDifference(Date.now(), new Date(Utils.convertDDMMYYYYDateToIsoDate(dati.doloreDataMisurazione)), true) < 0)}
                        fieldLabel={'Data misurazione'}
                        fieldRequired={false}
                        disableDoubleInput={true}
                        hideUnsetValues={this.props.hideUnsetValues}
                    /> 

                    <RoleBasedInput
                        fieldId={"SEDE_DOLORE.PARAMETRI.PARAMETRIVITALI"}
                        pageState={this.props.pageState}
                        parentClass={"mb-0"}
                        value={dati.dolereSede}
                        placeholder={"Inserisci sede"}
                        field={'dolereSede'}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"inputSede"}
                        fieldLabel={"Sede"}
                        forceReadOnly={this.props.forceReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    /> 
                </FieldsRow>

                {(dati.intensitaDolore !== null && dati.intensitaDolore !== "") && (
                    <CondizioneRilevataSection
                        ambito={'PARAMETRI'}
                        fieldsSuffix={'DOLORE'}
                        parentFieldId={'PARAMETRI.PARAMETRIVITALI'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("doloreCondizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.doloreCondizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("doloreDaAttenzionare", elem)}
                        valueDaAttenzionare={dati.doloreDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathPrefix={'pressione'}
                    />
                )}
            </>}
        </>;
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"datiParametri"}
                    title={"Parametri"}
                    id={"parametri"}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}

Parametri.contextType = RoleBasedContext;
Parametri.propTypes = propTypes;

Parametri.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    showAccordion: false,
    disableDoubleInput: false
}
