import React from 'react'
import {Icon} from "web-client-archetype";
import styles from './Error.module.css'

export default class Error extends React.Component{
    render() {
        return (
            <div className={styles.errorContainer.concat(" ml-5")}>
                <Icon id={"it-error"} style={{width: "200px", height:"200px"}} />
                <h1>Siamo spiacenti, sì è verificato un errore.</h1>
            </div>
        );
    }
}
