import PASSI from "./PASSI.json";
import PSOC from "./PSOC.json";
import PADL from "./PADL.json";
import PMOB from "./PMOB.json";
import PCOG from "./PCOG.json";
import PSAN from "./PSAN.json";
import IADL from "./IADL.json";
import TINETTI from "./TINETTI.json";
import mMRC from "./mMRC.json";
import MNA from "./MNA.json";
import MMAS4 from "./MMAS4.json";
import MMAS8 from "./MMAS8.json";
import AUDIT from "./AUDIT.json";
import AUDITC from "./AUDITC.json";
import FRAGERSTROM from "./FRAGERSTROM.json";
import CAT from "./CAT.json";
import NOPPAIN from "./NOPPAIN.json";
import MONDOR from "./MONDOR.json";
import BRADEN from "./BRADEN.json";
import NECPAL from "./NECPAL.json";
import BRASS from "./BRASS.json";

/**
 * @description contiene una serie di JSON di configurazione per ogni questionario che vengono compilati su ogni richiesta ADI
 * @constant questionariData JSON di configurazione per renderizzare ogni questionario Questionari
 * @param PASSI JSON PASSI
 * @param PSOC JSON PSOC
 * @param PMOB JSON PMOB
 * @param PCOG JSON PCOG
 * @param PSAN JSON PSAN
 * @param IADL JSON IADL
 * @param TINETTI JSON TINETTI
 * @param mMRC JSON mMRC
 * @param MNA JSON MNA
 * @param MMAS4 JSON MMAS4
 * @param MMAS8 JSON MMAS8
 * @param AUDIT JSON AUDIT
 * @param AUDITC JSON AUDITC
 * @param FRAGERSTROM JSON FRAGERSTROM
 * @param CAT JSON CAT
 * @param NOPPAIN JSON NOPPAIN
 * @param MONDOR JSON MONDOR
 * @param BRADEN JSON BRADEN
 * @param NECPAL JSON NECPAL
 * @param BRASS JSON BRASS
 *
*/

const questionariData = {
  "PASSI": PASSI,
  "PSOC": PSOC,
  "PADL": PADL,
  "PMOB": PMOB,
  "PCOG": PCOG,
  "PSAN": PSAN,
  "IADL": IADL,
  "TINETTI": TINETTI,
  "mMRC": mMRC,
  "MNA": MNA,
  "MMAS4": MMAS4,
  "MMAS8": MMAS8,
  "AUDIT": AUDIT,
  "AUDITC": AUDITC,
  "FRAGERSTROM": FRAGERSTROM,
  "CAT": CAT,
  "NOPPAIN": NOPPAIN,
  "MONDOR": MONDOR,
  "BRADEN": BRADEN,
  "NECPAL": NECPAL,
  "BRASS": BRASS
}

export default questionariData
