import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {FieldsRow, RoleBasedInput, RoleBasedSelect, RoleBasedText} from "../../roleBasedComponents/RoleBasedComponents";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import Statistica from "../../statistica/Statistica";
import enumQuestionario from "../../../enum/enumQuestionario"
import SessionRequest from "../../../utils/SessionRequest";
import {
    defaultAltro,
    defaultFarmaco, defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import Utils from "../../../utils/Utils";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    datiSensorioDolore: PropTypes.any,
    onChangeDatiSensorioDoloreForm: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    richiesta: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class SensorioDolore extends AccordionSecondLevel {

    state = {
        datiSensorioDolore: _.cloneDeep(this.props.datiSensorioDolore)
    }

    componentDidMount() {
        this.inizializzaForm()
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiSensorioDolore, this.props.datiSensorioDolore)) {
            this.forceUpdate(() => this.inizializzaForm());
        }
    }

    inizializzaForm = () => {
        const datiSensorioDolore = _.cloneDeep(this.props.datiSensorioDolore);
        this.setState({datiSensorioDolore}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiSensorioDolore}) => {
            datiSensorioDolore[field] = elem;

            if (!datiSensorioDolore.valutazioneEffettuata) {
                datiSensorioDolore.mantenutaSensibilitaTattile = null;
                datiSensorioDolore.mantenutaSensibilitaTermica = null;
                datiSensorioDolore.mantenutaSensibilitaDolorifica = null;
                datiSensorioDolore.intensitaDolore = null;
                datiSensorioDolore.dolore = null;
                datiSensorioDolore.sogliaDelDolore = null;
                datiSensorioDolore.NOPPAIN = null;
                datiSensorioDolore.condizioneRilevata = null;
                datiSensorioDolore.esitoValutazioneDolore = null;
                datiSensorioDolore.sede = null;
                datiSensorioDolore.durataDolore = null;
                datiSensorioDolore.statoDopoValutazione = null;
            }

            if (!this.showCondizioneRilevata(datiSensorioDolore)) datiSensorioDolore.condizioneRilevata = null;

            if (datiSensorioDolore.condizioneRilevata !== "01") datiSensorioDolore.statoDopoValutazione = null;

            return {datiSensorioDolore};
        }, () => this.validazioneDati());
    }

    validazioneDati = () => {
        const datiSensorioDolore = _.cloneDeep(this.state.datiSensorioDolore);
        this.props.onChangeDatiAssistito("datiSensorioDolore", datiSensorioDolore);
        let campiObbligatori = 0;
        campiObbligatori += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiSensorioDolore?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneSensorioDolore", campiObbligatori);
    }

    onHide = () => {
        const datiSensorioDolore = _.cloneDeep(SessionRequest.getInitObject('anamnesiFisiologica.datiSensorioDolore'));
        this.setState({datiSensorioDolore}, () => {
            this.props.onChangeDatiAssistito("datiSensorioDolore", datiSensorioDolore);
            this.props.onHide();
        });
    }

    renderBodySezione = () => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_MANT_SENS_TERMICA.SENSORIODOLORE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_MANT_SENS_TERMICA.DOLORE.SENSORIO'}
                        pageState={this.props.pageState}
                        id={"valutazioneSensorioDolore"}
                        className={"valutazioneSensorioDolore"}
                        options={OggettiUtili.rispostaBreve}
                        value={this.state.datiSensorioDolore.valutazioneEffettuata}
                        onChange={(elem) => this.handleAggiornaForm("valutazioneEffettuata", elem)}
                        isSearchable={false}
                        fieldLabel={"È stata effettuata la valutazione?"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"valutazioneEffettuata"}
                    />
                </FieldsRow>

                {this.state.datiSensorioDolore.valutazioneEffettuata && <>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={"M_MANT_SENS_TATTILE.SENSORIODOLORE.ANAMNESIFISIOLOGICA"}
                            infermiereFieldId={'I_MANT_SENS_TATTILE.DOLORE.SENSORIO'}
                            pageState={this.props.pageState}
                            id={"mantenutaSensibilitaTattile"}
                            options={OggettiUtili.alterazioniLettura}
                            value={this.state.datiSensorioDolore.mantenutaSensibilitaTattile}
                            onChange={(elem) => this.handleAggiornaForm("mantenutaSensibilitaTattile", elem)}
                            isSearchable={false}
                            fieldLabel={'Mantenuta sensibilità tattile'}
                            handleOnlyValue={true}
                            noOptionsMessage={() => "Errore: Dolore non esistente"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={"mantenutaSensibilitaTattile"}
                        />
                        <RoleBasedSelect
                            fieldId={"M_MANT_SENS_TERMICA.SENSORIODOLORE.ANAMNESIFISIOLOGICA"}
                            infermiereFieldId={'I_MANT_SENS_TERMICA.DOLORE.SENSORIO'}
                            pageState={this.props.pageState}
                            id={"mantenutaSensibilitaTermica"}
                            options={OggettiUtili.alterazioniLettura}
                            value={this.state.datiSensorioDolore.mantenutaSensibilitaTermica}
                            onChange={(elem) => this.handleAggiornaForm("mantenutaSensibilitaTermica", elem)}
                            isSearchable={false}
                            fieldLabel={'Mantenuta sensibilità termica'}
                            handleOnlyValue={true}
                            noOptionsMessage={() => "Errore: Dolore non esistente"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={"mantenutaSensibilitaTermica"}
                        />
                        <RoleBasedSelect
                            fieldId={"M_MANT_SENS_DOLORIFICA.SENSORIODOLORE.ANAMNESIFISIOLOGICA"}
                            infermiereFieldId={'I_MANT_SENS_DOLORIFICA.DOLORE.SENSORIO'}
                            pageState={this.props.pageState}
                            id={"mantenutaSensibilitaDolorifica"}
                            options={OggettiUtili.alterazioniLettura}
                            value={this.state.datiSensorioDolore.mantenutaSensibilitaDolorifica}
                            onChange={(elem) => this.handleAggiornaForm("mantenutaSensibilitaDolorifica", elem)}
                            isSearchable={false}
                            fieldLabel={'Mantenuta sensibilità dolorifica'}
                            handleOnlyValue={true}
                            noOptionsMessage={() => "Errore: Sensibilita dolorifica non esistente"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={"mantenutaSensibilitaDolorifica"}
                        />
                    </FieldsRow>

                    <FieldsRow>
                        <RoleBasedInput
                            fieldId={"M_INT_DOL.SENSORIODOLORE.ANAMNESIFISIOLOGICA"}
                            infermiereFieldId={'I_INT_DOL.DOLORE.SENSORIO'}
                            pageState={this.props.pageState}
                            parentClass={"mb-0"}
                            value={this.state.datiSensorioDolore.intensitaDolore}
                            placeholder={"-"}
                            field={'intensitaDolore'}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"M_INT_DOL"}
                            fieldLabel={"Intensità del dolore"}
                            type={"number"}
                            min={0}
                            max={10}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                        <RoleBasedSelect
                            fieldId={"M_DOLORE.SENSORIODOLORE.ANAMNESIFISIOLOGICA"}
                            infermiereFieldId={'I_DOLORE.DOLORE.SENSORIO'}
                            pageState={this.props.pageState}
                            id={"selectDolore"}
                            options={OggettiUtili.dolore}
                            value={this.state.datiSensorioDolore.dolore}
                            onChange={(elem) => this.handleAggiornaForm("dolore", elem)}
                            isSearchable={false}
                            fieldLabel={'Dolore'}
                            handleOnlyValue={true}
                            noOptionsMessage={() => "Errore: Dolore non esistente"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={"dolore"}
                        />
                        <RoleBasedSelect
                            fieldId={"M_SOGLIA_DOL.SENSORIODOLORE.ANAMNESIFISIOLOGICA"}
                            infermiereFieldId={'I_SOGLIA_DOL.DOLORE.SENSORIO'}
                            pageState={this.props.pageState}
                            id={"selectSogliaDolore"}
                            options={OggettiUtili.sogliaDolore}
                            value={this.state.datiSensorioDolore.sogliaDelDolore}
                            onChange={(elem) => this.handleAggiornaForm("sogliaDelDolore", elem)}
                            isSearchable={false}
                            fieldLabel={'Soglia del dolore'}
                            handleOnlyValue={true}
                            noOptionsMessage={() => "Errore: Soglia dolore non esistente"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={"sogliaDelDolore"}
                        />
                    </FieldsRow>

                    <Statistica
                        fieldId={"M_NOPPAIN.SENSORIODOLORE.ANAMNESIFISIOLOGICA"}
                        infermiereFieldId={'I_NOPPAIN.DOLORE.SENSORIO'}
                        pageState={this.props.pageState}
                        nomeLink={"Compila questionario Noppain"}
                        nomePunteggio={"Punteggio NOPPAIN"}
                        questionario={this.state.datiSensorioDolore.NOPPAIN}
                        onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.NOPPAIN, questionario)}
                        richiesta={this.props.richiesta}
                        pathRichiestaQuestionario={"anamnesiFisiologica.datiSensorioDolore.NOPPAIN"}
                        tipoQuestionario={enumQuestionario.tipoQuestionario.NOPPAIN}
                        profileRequired={false}
                        salvaBozza={this.props.salvaBozza}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        parentJsonPathSubObject={'noppain'}
                    />

                    {/*TODO: Il punteggio sarà valorizzato in una fase successiva, fase 'cartella'*/
                        <FieldsRow>
                            <RoleBasedText
                                fieldId={'M_ESITO_VAL_DOL_ESASR.SENSORIODOLORE.ANAMNESIFISIOLOGICA'}
                                infermiereFieldId={'I_ESITO_VAL_DOL_ESASR.DOLORE.SENSORIO'}
                                pageState={this.props.pageState}
                                id={"selectEsitoValutazioneDolore"}
                                options={OggettiUtili.punteggioAnsia}
                                value={""}
                                fieldLabel={"Punteggio Test ESASr"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                                field={"esitoValutazioneDolore"}
                            />
                        </FieldsRow>}

                    {this.showCondizioneRilevata(this.state.datiSensorioDolore) &&
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_COND_RIL.SENSORIODOLORE.ANAMNESIFISIOLOGICA'}
                                infermiereFieldId={'I_COND_RIL.DOLORE.SENSORIO'}
                                pageState={this.props.pageState}
                                id={"selectCondizioneRilevata"}
                                options={OggettiUtili.condizioneRilevataPeso}
                                value={this.state.datiSensorioDolore.condizioneRilevata}
                                onChange={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                                isSearchable={false}
                                fieldLabel={'La condizione rilevata'}
                                handleOnlyValue={true}
                                noOptionsMessage={() => "Errore: Condizione rilevata non esistente"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                                field={"condizioneRilevata"}
                            />
                        </FieldsRow>}

                    <FieldsRow colNumber={2}>
                        <RoleBasedInput
                            fieldId={"M_SEDE.SENSORIODOLORE.ANAMNESIFISIOLOGICA"}
                            infermiereFieldId={'I_SEDE.DOLORE.SENSORIO'}
                            pageState={this.props.pageState}
                            value={this.state.datiSensorioDolore.sede}
                            placeholder={"Inserisci sede"}
                            field={"sede"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"sede"}
                            fieldLabel={'Sede'}
                            type={"textarea"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />
                    </FieldsRow>

                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={"M_DURATA_DOL.SENSORIODOLORE.ANAMNESIFISIOLOGICA"}
                            infermiereFieldId={'I_DURATA_DOL.DOLORE.SENSORIO'}
                            pageState={this.props.pageState}
                            id={"durataDolore"}
                            options={OggettiUtili.durataDolore}
                            value={this.state.datiSensorioDolore.durataDolore}
                            onChange={(elem) => this.handleAggiornaForm("durataDolore", elem)}
                            isSearchable={false}
                            fieldLabel={'Durata dolore'}
                            handleOnlyValue={true}
                            noOptionsMessage={() => "Errore: Durata dolore non esistente"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={"durataDolore"}
                        />
                    </FieldsRow>
                </>}

                <ValutazioneSection
                    ambito={'SENSORIO - DOLORE'}
                    pageState={this.props.pageState}
                    parentFieldId={'SENSORIODOLORE.ANAMNESIFISIOLOGICA'}
                    appendRolePrefix={true}
                    infParentFieldId={'DOLORE.SENSORIO'}
                    onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                    valueStatoDopoValutazione={this.state.datiSensorioDolore.statoDopoValutazione}
                    onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                    valueDataValutazione={this.state.datiSensorioDolore.dataValutazione}
                    onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                    valueDaConsiderare={this.state.datiSensorioDolore.daConsiderare}
                    showSuggerimenti={true}
                    valueSuggerimenti={this.state.datiSensorioDolore.propostaAttivitaSuggerimenti}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={this.state.datiSensorioDolore.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={this.state.datiSensorioDolore.autonomiaGestioneCaregiver}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    openCondition={this.state.datiSensorioDolore.valutazioneEffettuata && this.state.datiSensorioDolore.condizioneRilevata === "01"}
                />
            </>
        );
    }

    showCondizioneRilevata(datiSensorioDolore) {
        return datiSensorioDolore?.mantenutaSensibilitaTermica
            || datiSensorioDolore?.mantenutaSensibilitaDolorifica
            || datiSensorioDolore?.mantenutaSensibilitaTattile
            || datiSensorioDolore?.dolore
            || datiSensorioDolore?.sogliaDelDolore
            || datiSensorioDolore?.NOPPAIN?.punteggio
            || datiSensorioDolore?.intensitaDolore;
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"datiSensorioDolore"}
            title={"Sensorio - Dolore"}
            required={false}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            onHide={this.onHide}
            isOptional={this.props.isOptional}
            fieldsOrderMap={Utils.generateMapPathsObjects(
                ["anamnesiFisiologica.datiSensorioDolore.propostaAttivitaSuggerimenti"],
                [defaultProposteAttivitaSuggerimenti]
            )}
            accordionReadOnly={this.props.accordionReadOnly}
            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
        >{this.renderBodySezione()}</AccordionSezioneRichiesta>
    }
}

SensorioDolore.propTypes = propTypes;
SensorioDolore.defaultProps = {
    isOptional: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}
