import React, {Component, Fragment} from "react";
import {Button, SessioneUtente} from "web-client-archetype";
import PropTypes from "prop-types";
import * as _ from "lodash";
import routepath from "../../utils/route/route-path.json";
import RichiestaADIService from "../../service/RichiestaADIService";
import Utils from "../../utils/Utils";
import AccordionHelper from "../../service/AccordionHelper";
import CaratteristicheSettingRichiesto from "./caratteristicheSettingRichiesto/CaratteristicheSettingRichiesto";
import ProgettoAssistenziale from "./progettoAssistenziale/ProgettoAssistenziale";
import {flatMap, map} from "rxjs/operators";
import enumRichiesta from "../../enum/enumRichiesta"
import ModalHelper from "../modale/ModalHelper";
import {FieldsGroup, isSomeFieldIdWriteable, RoleBasedInput} from "../roleBasedComponents/RoleBasedComponents";
import {mostraSpinner, nascondiSpinner} from "../../App";
import AnagrafeService from "../../service/AnagrafeService";
import {defaultAnagrafePaziente} from "../../utils/SessionRequest";
import enumContatto from "../../enum/enumContatto";
import enumIndirizzo from "../../enum/enumIndirizzo";
import enumsUtente from "../../enum/enumsUtente.json";
import enumPaginaCompila from "../../enum/enumPaginaCompila.json";
import {forkJoin, of} from "rxjs";
import WebSocketHelper from "../../websocket/WebSocketHelper";
import {Link, Redirect} from "react-router-dom";
import routePath from "../../utils/route/route-path";
import HistorySessionRequest from "../../utils/HistorySessionRequest";
import moment from "moment";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import errors from "../../utils/errors/errors.json";
import VersionedAccordion from "../VersionedAccordion";
import PopupDataDimissioneProgrammataAggiornata
    from "../visualizzaRiepilogo/popupDataDimissioneProgrammataAggiornata/PopupDataDimissioneProgrammataAggiornata";
import Trackbar from "../trackbar/Trackbar";
import enumTipoAvvisoSettingModale from "../../enum/enumTipoAvvisoSettingModale.json";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import * as $ from "jquery";
import {showMessageAulssDistrettoDifferences} from "../inserimentoRichiesta/InserimentoRichiesta";
import ButtonsBoxStick from "../buttonsBoxStick/buttonsBoxStick";
import UserHelper from "../../service/userHelper";
import InserimentoRichiesta from "../inserimentoRichiesta/InserimentoRichiesta";

const propTypes = {
    uuidRichiesta: PropTypes.string,
    pageState: PropTypes.string,
    pageName: PropTypes.string,
    pathname: PropTypes.string
}

export var CONTROLLO_CAMPI_FORMALIZZAZIONE = {
    codiceProfilo: {fieldAccordion: "caratteristicheSettingRichiesto", required: false},
    tipoValutazione: {fieldAccordion: "caratteristicheSettingRichiesto", required: true},
    dataValutazione: {fieldAccordion: "caratteristicheSettingRichiesto", required: true, validazione: Utils.isValidDateFormatDDMMYYYY},
    progettoAssistenzialePrincipale: {fieldAccordion: "progettoAssistenziale", required: true},
    progettoAssistenzialeSecondario: {fieldAccordion: "progettoAssistenziale", required: false},
    ruoloDelResponsabile: {fieldAccordion: "progettoAssistenziale", required: true},
    nomeResponsabile: {fieldAccordion: "progettoAssistenziale", required: true},
    cognomeResponsabile: {fieldAccordion: "progettoAssistenziale", required: true},
    telefonoResponsabile: {fieldAccordion: "progettoAssistenziale", required: true, validazione: Utils.isValidTelefono},
    cellulareResponsabile: {fieldAccordion: "progettoAssistenziale", required: true, optional: true, validazione: Utils.isValidTelefono},
    faxResponsabile: {fieldAccordion: "progettoAssistenziale", required: false, validazione: Utils.isValidTelefono},
    emailResponsabile: {fieldAccordion: "progettoAssistenziale", required: false, validazione: Utils.isValidEmail},
    dataInizioProgettoAssistenziale: {fieldAccordion: "progettoAssistenziale", required: true, validazione: Utils.isValidDateFormatDDMMYYYY},
    dataVerificaProgrammata: {fieldAccordion: "progettoAssistenziale", required: false, validazione: Utils.isValidDateFormatDDMMYYYY},
    esitoValutazione: {fieldAccordion: null, required: false},
    motivazione: {fieldAccordion: null, required: false}
}

export const calcolaCampiObbligatoriRimanenti = (oggettoVerifica, oggettoControllo, fieldAccordion) => {
    let campiObbligatoriRimanenti = 0;
    _.forIn(oggettoVerifica, (valore, chiave) => {
        if (valore.required && valore.fieldAccordion === fieldAccordion && !valore.optional) {
            if (oggettoControllo) {
                let isPresent;
                if(chiave == "telefonoResponsabile" || chiave == "cellulareResponsabile") {
                    isPresent = !Utils.isStringEmptyOrNullOrUndefined(oggettoControllo["telefonoResponsabile"]) || !Utils.isStringEmptyOrNullOrUndefined(oggettoControllo["cellulareResponsabile"]);
                }else {
                    isPresent = !Utils.isStringEmptyOrNullOrUndefined(oggettoControllo[chiave]);
                }
                if (!isPresent) {
                    campiObbligatoriRimanenti++
                }
            }
        }
    });
    return campiObbligatoriRimanenti;
}

export default class CompilaFormalizzazione extends Component {
    state = {
        richiesta: null,
        persone: null,
        gotoAnagraficaRichiedente: false,
        listaCodiciFiscaliUtentiOnline: [],
        formalizzazione: {
            caratteristicheSettingRichiesto: {},
            progettoAssistenziale: {},
            esitoValutazione: null,
            motivazione: null
        },
        accordion: {
            openAccordionCaratteristicheSettingRichiesto: true,
            openAccordionProgettoAssistenziale: true
        },
        // variabile di stato per il bottone accetta setting richiesto
        sezioniValidate: false,
        // variabile di stato per la visualizzazione della modale
        tipoModaleVisualizzata: null,
        // bool per modale successo
        operazioneEseguita: null,
        redirectTo: null,
        pdfBtnDisabled: false,
        //utilizzate per mostrare o meno la modale di avviso aggiornamento della data dimissione programmata in dimissione protetta
        dataDimissioneProgrammataDaRicovero: null,
        dataDimissioneProgrammataDaRicoveroAggiornata: false
    };

    accordionValidi = {
        caratteristicheSettingRichiesto: false,
        progettoAssistenziale: false
    }


    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let callback = () => {
            if (AccordionHelper.idSelectedAccordions != null) {
                const ref = $(document.getElementById(AccordionHelper.idSelectedAccordions));
                ref.find('> div.collapse-header > button').trigger('click');
            } else {
                this.setState({
                    accordion: AccordionHelper.initLastOpenedAccordionInAccordionObject(this.state.accordion, AccordionHelper.lastOpenedAccordion)
                });
            }
        }
        this.montaComponente(this.props.uuidRichiesta, callback);
        UserHelper.setForceUserRole(null);
        InserimentoRichiesta.redirectToAnagrafeRichiedenteSubject.subscribe(() => this.gotoAnagraficaRichiedente());
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.uuidRichiesta, this.props.uuidRichiesta)) {
            this.montaComponente(this.props.uuidRichiesta);
        }
    }

    montaComponente = (uuidRichiesta, callback) => {
        if (this.props.richiesta != null) {
            this.setState({richiesta: this.props.richiesta});
            return;
        }
        mostraSpinner();

        let requests = [this.recuperaRichiesta(uuidRichiesta, callback), of(null), of(null)];

        if (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) {
            if (!this.props.readOnly) requests[1] = this.recuperaStoricoListaCampi(uuidRichiesta, null);
            if (SessioneUtente.getInstance().settings.map(setting => setting.ruolo).includes(enumsUtente.ruoli.infermiere)) {
                requests[2] = this.recuperaRichiestaMediciConfermati(uuidRichiesta);
            }
        }

        forkJoin(requests).subscribe((data) => {
            if (!data?.[0]) {
                this.setState({error: true}, nascondiSpinner);
            } else {
                WebSocketHelper.onMessageListener();
                WebSocketHelper.setIdRichiesta(this.state.richiesta?.id ?? null);
                WebSocketHelper.utenteAutenticatoSubject.subscribe((message) => {
                    console.log("Lista Utenti Loggati Comp. Form.", WebSocketHelper.utentiLoggati);
                    this.setState({listaCodiciFiscaliUtentiOnline: _.cloneDeep(WebSocketHelper.utentiLoggati)});
                });
                WebSocketHelper.utenteDisconnessoSubject.subscribe((flag) => {
                    if (flag) this.setState({listaCodiciFiscaliUtentiOnline: WebSocketHelper.utentiLoggati});
                });
                if (data[1]) HistorySessionRequest.setData(data[1], data[0]?.[0]);
                if (data[2]) this.setState({richiestaMedico: data[2]});

                AccordionHelper.resetMappaVariazionePathDecodedValue();

                nascondiSpinner();
            }
        });
    }

    recuperaRichiesta(uuidRichiesta, callback = null) {
        return RichiestaADIService.letturaRichiesta(uuidRichiesta)
            .pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        if (response?.status === 404) {
                            this.setState({error: true}, () => nascondiSpinner());
                        } else {
                            Utils.toasterFunction({
                                status: response?.data?.codice ? response?.data?.codice : response?.status,
                                type: "critical",
                                text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                            });
                        }
                    }
                    return null;
                } else {
                    return [response.data, this.estraiUUIDPersone(response.data)];
                }
            }))
            .pipe(flatMap((dataArray) => {
                if (!dataArray?.[0]) return of(null);

                let richiesta = dataArray[0];
                let listaUUIDPersone = dataArray[1];
                let queryParams = {};
                queryParams.vista = "totale";
                queryParams.filtro = "id_persona~~" + listaUUIDPersone.join("|");
                return AnagrafeService.getPersone(queryParams).pipe(map((data) => {
                    if (data) {
                        let map = _.keyBy(data, "id");
                        if (!richiesta.hasOwnProperty("anagrafePaziente"))
                            richiesta.anagrafePaziente = {};
                        let anagrafePaziente = _.cloneDeep(richiesta.anagrafePaziente);

                        Utils.resetObject(defaultAnagrafePaziente);

                        let assistito = map[richiesta.idAssistito];
                        if (!Utils.isObjectNull(assistito)) {
                            anagrafePaziente.datiGenerali = defaultAnagrafePaziente.datiGenerali;
                            anagrafePaziente.datiGenerali.identificativoEventoAssistenzialeDiCD = richiesta.id;
                            anagrafePaziente.datiGenerali.ilPazienteEInformatoDellaDomanda = richiesta.anagrafePaziente?.datiGenerali?.ilPazienteEInformatoDellaDomanda !== undefined
                                ? richiesta.anagrafePaziente?.datiGenerali?.ilPazienteEInformatoDellaDomanda : null;
                            Object.keys(assistito).forEach(key => {
                                if (key === "lingue") {
                                    this.getLingua(anagrafePaziente.datiGenerali, assistito[key]);
                                } else if (key === "contatti" && assistito[key].length > 0) {
                                    this.setContatto(assistito[key], enumContatto.contatto.telefono, anagrafePaziente.datiGenerali);
                                    this.setContatto(assistito[key], enumContatto.contatto.cellulare, anagrafePaziente.datiGenerali);
                                    this.setContatto(assistito[key], enumContatto.contatto.email, anagrafePaziente.datiGenerali);
                                } else if (key in defaultAnagrafePaziente.datiGenerali)
                                    anagrafePaziente.datiGenerali[key] = assistito[key];
                            });

                            anagrafePaziente.residenza = defaultAnagrafePaziente.residenza;
                            anagrafePaziente.domicilio = defaultAnagrafePaziente.domicilio;
                            anagrafePaziente.domicilioDiCura = defaultAnagrafePaziente.domicilioDiCura;
                            if (assistito.indirizzi.length > 0) {
                                let residenza = this.getRecapito("residenza", assistito.indirizzi, enumIndirizzo.indirizzo.residenza);
                                if (residenza !== null) {
                                    anagrafePaziente.residenza = {
                                        ...residenza,
                                        distretto: assistito.distrettoResidenza ?? residenza.distretto
                                    };
                                }

                                let domicilio = this.getRecapito("domicilio", assistito.indirizzi, enumIndirizzo.indirizzo.domicilio);
                                if (domicilio !== null)
                                    anagrafePaziente.domicilio = domicilio;

                                let domicilioDiCura = this.getRecapito("domicilioDiCura", assistito.indirizzi, enumIndirizzo.indirizzo.altro);
                                if (domicilioDiCura !== null)
                                    anagrafePaziente.domicilioDiCura = domicilioDiCura;
                            }
                            anagrafePaziente.residenza.residenzaDiversaDaDomicilio = richiesta.anagrafePaziente?.residenza?.residenzaDiversaDaDomicilio ?? null;
                            anagrafePaziente.domicilio.domicilioDiversoDaDomicilioCure = richiesta.anagrafePaziente?.domicilio?.domicilioDiversoDaDomicilioCure ?? null;

                            anagrafePaziente.esenzioni = defaultAnagrafePaziente.esenzioni;
                            if (assistito.esenzioni?.length > 0) {
                                anagrafePaziente.esenzioni = [];
                                assistito.esenzioni.forEach(esenzione => {
                                    Object.keys(esenzione).forEach(key => {
                                        if (!(key in esenzione))
                                            delete esenzione[key];
                                    });
                                    anagrafePaziente.esenzioni.push(esenzione);
                                });
                            }
                            let mascheraModificabilitaAssistito = _.cloneDeep(assistito.mascheraModificabilita);
                            if (!Utils.isObjectNull(mascheraModificabilitaAssistito)) {
                                mascheraModificabilitaAssistito.indirizzi = mascheraModificabilitaAssistito.indirizzi.map(i => {
                                    if (i.idElemento === anagrafePaziente.residenza?.idElemento
                                        && !Utils.isStringEmptyOrNullOrUndefined(assistito.distrettoResidenza)) {
                                        i.distretto = mascheraModificabilitaAssistito.distrettoResidenza;
                                    }
                                    return i;
                                });
                            }
                            anagrafePaziente.datiGenerali.mascheraModificabilita = mascheraModificabilitaAssistito;
                        }

                        anagrafePaziente.mmgPlsDelPaziente = defaultAnagrafePaziente.mmgPlsDelPaziente;
                        let mmgPlsPaziente = map[richiesta.anagrafePaziente.mmgPlsDelPaziente?.id];
                        if (!Utils.isObjectNull(mmgPlsPaziente)) {
                            Object.keys(mmgPlsPaziente).forEach(key => {
                                if (key === "contatti" && mmgPlsPaziente[key].length > 0) {
                                    this.setContatto(mmgPlsPaziente[key], enumContatto.contatto.telefono, anagrafePaziente.mmgPlsDelPaziente);
                                    this.setContatto(mmgPlsPaziente[key], enumContatto.contatto.cellulare, anagrafePaziente.mmgPlsDelPaziente);
                                    this.setContatto(mmgPlsPaziente[key], enumContatto.contatto.email, anagrafePaziente.mmgPlsDelPaziente);
                                } else if (key in defaultAnagrafePaziente.mmgPlsDelPaziente)
                                    anagrafePaziente.mmgPlsDelPaziente[key] = mmgPlsPaziente[key];
                            });
                            anagrafePaziente.mmgPlsDelPaziente.mascheraModificabilita = mmgPlsPaziente.mascheraModificabilita;
                            if (!Utils.isObjectNull(richiesta?.mmg)) richiesta.mmg.id = mmgPlsPaziente.id;
                        }

                        anagrafePaziente.padre = defaultAnagrafePaziente.padre;
                        let padre = map[richiesta.anagrafePaziente.padre?.id];
                        if (!Utils.isObjectNull(padre)) {
                            anagrafePaziente.padre.isPadreIndicatoComeRiferimentoPerPaziente = richiesta.anagrafePaziente.padre?.isPadreIndicatoComeRiferimentoPerPaziente;
                            Object.keys(padre).forEach(key => {
                                if (key === "indirizzi" && padre[key].length > 0) {
                                    this.setIndirizzo(padre[key], anagrafePaziente.padre);
                                } else if (key === "contatti" && padre[key].length > 0) {
                                    this.setContatto(padre[key], enumContatto.contatto.telefono, anagrafePaziente.padre);
                                    this.setContatto(padre[key], enumContatto.contatto.cellulare, anagrafePaziente.padre);
                                    this.setContatto(padre[key], enumContatto.contatto.email, anagrafePaziente.padre);
                                } else if (key === "lingue")
                                    this.getLingua(anagrafePaziente.padre, padre[key]);
                                else if (key in defaultAnagrafePaziente.padre)
                                    anagrafePaziente.padre[key] = padre[key];
                            });
                        }

                        anagrafePaziente.madre = defaultAnagrafePaziente.madre;
                        let madre = map[richiesta.anagrafePaziente.madre?.id];
                        if (!Utils.isObjectNull(madre)) {
                            anagrafePaziente.madre.isMadreIndicataComeRiferimentoPerPaziente = richiesta.anagrafePaziente.madre?.isMadreIndicataComeRiferimentoPerPaziente;
                            Object.keys(madre).forEach(key => {
                                if (key === "indirizzi" && madre[key].length > 0) {
                                    this.setIndirizzo(madre[key], anagrafePaziente.madre);
                                } else if (key === "contatti" && madre[key].length > 0) {
                                    this.setContatto(madre[key], enumContatto.contatto.telefono, anagrafePaziente.madre);
                                    this.setContatto(madre[key], enumContatto.contatto.cellulare, anagrafePaziente.madre);
                                    this.setContatto(madre[key], enumContatto.contatto.email, anagrafePaziente.madre);
                                } else if (key === "lingue")
                                    this.getLingua(anagrafePaziente.madre, madre[key]);
                                else if (key in defaultAnagrafePaziente.madre)
                                    anagrafePaziente.madre[key] = madre[key];
                            });
                        }

                        anagrafePaziente.tutore = defaultAnagrafePaziente.tutore;
                        if (richiesta.anagrafePaziente?.tutore) {
                            anagrafePaziente.tutore = [];
                            richiesta.anagrafePaziente.tutore.forEach(tutore => {
                                let tutoreRecuperato = map[tutore.id];
                                if (!Utils.isObjectNull(tutoreRecuperato)) {
                                    let obj = {};
                                    Object.keys(tutoreRecuperato).forEach(key => {
                                        if (key === "indirizzi" && tutoreRecuperato[key].length > 0) {
                                            this.setIndirizzo(tutoreRecuperato[key], obj);
                                        } else if (key === "contatti" && tutoreRecuperato[key].length > 0) {
                                            this.setContatto(tutoreRecuperato[key], enumContatto.contatto.telefono, obj);
                                            this.setContatto(tutoreRecuperato[key], enumContatto.contatto.cellulare, obj);
                                            this.setContatto(tutoreRecuperato[key], enumContatto.contatto.email, obj);
                                        } else if (key === "lingue")
                                            this.getLingua(obj, tutoreRecuperato[key]);
                                        else if (key === "id" || key === "nome" || key === "cognome" || key === "identificativoUtente")
                                            obj[key] = tutoreRecuperato[key];
                                    });
                                    anagrafePaziente.tutore.push(obj);
                                }
                            });
                        }

                        anagrafePaziente.amministratoreDiSostegno = defaultAnagrafePaziente.amministratoreDiSostegno;
                        if (richiesta.anagrafePaziente?.amministratoreDiSostegno) {
                            anagrafePaziente.amministratoreDiSostegno = [];
                            richiesta.anagrafePaziente.amministratoreDiSostegno.forEach(amministratoreDiSostegno => {
                                let amministratoreDiSostegnoRichiesta = richiesta.anagrafePaziente.amministratoreDiSostegno.find(el => el.id === amministratoreDiSostegno.id);
                                let amministratoreDiSostegnoRecuperato = map[amministratoreDiSostegno.id];
                                if (!Utils.isObjectNull(amministratoreDiSostegnoRecuperato)) {
                                    let obj = {};
                                    obj.conDelegaSanitaria = amministratoreDiSostegnoRichiesta?.conDelegaSanitaria;
                                    obj.numeroDecretoDiNomina = amministratoreDiSostegnoRichiesta?.numeroDecretoDiNomina;
                                    obj.annoDecretoDiNomina = amministratoreDiSostegnoRichiesta?.annoDecretoDiNomina;
                                    Object.keys(amministratoreDiSostegnoRecuperato).forEach(key => {
                                        if (key === "indirizzi" && amministratoreDiSostegnoRecuperato[key].length > 0) {
                                            this.setIndirizzo(amministratoreDiSostegnoRecuperato[key], obj);
                                        } else if (key === "contatti" && amministratoreDiSostegnoRecuperato[key].length > 0) {
                                            this.setContatto(amministratoreDiSostegnoRecuperato[key], enumContatto.contatto.telefono, obj);
                                            this.setContatto(amministratoreDiSostegnoRecuperato[key], enumContatto.contatto.cellulare, obj);
                                            this.setContatto(amministratoreDiSostegnoRecuperato[key], enumContatto.contatto.email, obj);
                                        } else if (key === "lingue")
                                            this.getLingua(obj, amministratoreDiSostegnoRecuperato[key]);
                                        else if (key === "id" || key === "nome" || key === "cognome" || key === "identificativoUtente")
                                            obj[key] = amministratoreDiSostegnoRecuperato[key];
                                    });
                                    anagrafePaziente.amministratoreDiSostegno.push(obj);
                                }
                            });
                        }

                        anagrafePaziente.fiduciario = defaultAnagrafePaziente.fiduciario;
                        let fiduciarioRecuperato = map[richiesta.anagrafePaziente.fiduciario?.id];
                        if (!Utils.isObjectNull(fiduciarioRecuperato)) {
                            Object.keys(fiduciarioRecuperato).forEach(key => {
                                if (key === "indirizzi" && fiduciarioRecuperato[key].length > 0) {
                                    this.setIndirizzo(fiduciarioRecuperato[key], anagrafePaziente.fiduciario);
                                } else if (key === "contatti" && fiduciarioRecuperato[key].length > 0) {
                                    this.setContatto(fiduciarioRecuperato[key], enumContatto.contatto.telefono, anagrafePaziente.fiduciario);
                                    this.setContatto(fiduciarioRecuperato[key], enumContatto.contatto.cellulare, anagrafePaziente.fiduciario);
                                    this.setContatto(fiduciarioRecuperato[key], enumContatto.contatto.email, anagrafePaziente.fiduciario);
                                } else if (key === "lingue")
                                    this.getLingua(anagrafePaziente.fiduciario, fiduciarioRecuperato[key]);
                                else if (key in defaultAnagrafePaziente.fiduciario)
                                    anagrafePaziente.fiduciario[key] = fiduciarioRecuperato[key];
                            });
                        }

                        richiesta.anagrafePaziente = anagrafePaziente;

                        let listaUtentiCfOnline = _.cloneDeep(WebSocketHelper.utentiLoggati) ?? [];
                        if (WebSocketHelper.isWebSocketConnected()) {
                            WebSocketHelper.utentiLoggati.push(SessioneUtente.getInstance().idUtente);
                            WebSocketHelper.utentiLoggati = Array.from(new Set(WebSocketHelper.utentiLoggati));
                            listaUtentiCfOnline = WebSocketHelper.utentiLoggati;
                        }

                        RichiestaADIService.richiestaRicercata = _.cloneDeep(richiesta);

                        this.setState({
                            richiesta: richiesta,
                            persone: map,
                            listaCodiciFiscaliUtentiOnline: listaUtentiCfOnline,
                            formalizzazione: richiesta.formalizzazione
                                ? this.formattaFormalizzazione(richiesta.formalizzazione)
                                : this.formattaFormalizzazione({})
                        }, () => {
                            if (callback) callback();
                        });
                    }
                    dataArray.push(data);
                    return dataArray;
                }));
            }));
    }

    recuperaStoricoListaCampi = (uuidRichiesta, nomiCampi) => {
        return RichiestaADIService.storicoListaCampi(uuidRichiesta, nomiCampi)
            .pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        if (response?.status === 404) {
                            //this.setState({error: true}, () => nascondiSpinner());
                        } else {
                            Utils.toasterFunction({
                                status: response?.data?.codice ? response?.data?.codice : response?.status,
                                type: "critical",
                                text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                            });
                        }
                    }
                    return null;
                } else {
                    return response.data;
                }
            }));
    }

    recuperaRichiestaMediciConfermati = (uuidRichiesta) => {
        return RichiestaADIService.letturaRichiesta(uuidRichiesta, true)
            .pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        if (response?.status === 404) {
                            this.setState({error: true}, () => nascondiSpinner());
                        } else {
                            Utils.toasterFunction({
                                status: response?.data?.codice ? response?.data?.codice : response?.status,
                                type: "critical",
                                text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                            });
                        }
                    }
                    return null;
                } else {
                    return response.data;
                }
            }));
    }

    estraiUUIDPersone = (richiesta) => {
        let listUUIDPersone = [];

        this.addToUUIDPersoneList(richiesta.idAssistito, listUUIDPersone);
        this.addToUUIDPersoneList(richiesta.richiedente.idProfessionista, listUUIDPersone);
        this.addToUUIDPersoneList(Utils.recuperoIdProfessionista(richiesta), listUUIDPersone);

        if (richiesta.hasOwnProperty("anagrafePaziente")) {
            let anagrafePaziente = richiesta.anagrafePaziente;
            let uuidMmgPlsPaziente = anagrafePaziente.hasOwnProperty("mmgPlsDelPaziente") ? anagrafePaziente.mmgPlsDelPaziente.id : null;
            this.addToUUIDPersoneList(uuidMmgPlsPaziente, listUUIDPersone);
            let uuidPadre = anagrafePaziente.hasOwnProperty("padre") ? anagrafePaziente.padre.id : null;
            this.addToUUIDPersoneList(uuidPadre, listUUIDPersone);
            let uuidMadre = anagrafePaziente.hasOwnProperty("madre") ? anagrafePaziente.madre.id : null;
            this.addToUUIDPersoneList(uuidMadre, listUUIDPersone);
            if (anagrafePaziente.hasOwnProperty("tutore"))
                anagrafePaziente.tutore.forEach(el => this.addToUUIDPersoneList(el.id, listUUIDPersone));
            if (anagrafePaziente.hasOwnProperty("amministratoreDiSostegno"))
                anagrafePaziente.amministratoreDiSostegno.forEach(el => this.addToUUIDPersoneList(el.id, listUUIDPersone));
            let uuidFiduciario = anagrafePaziente.hasOwnProperty("fiduciario") ? anagrafePaziente.fiduciario.id : null;
            this.addToUUIDPersoneList(uuidFiduciario, listUUIDPersone);
        }

        return Array.from(new Set(listUUIDPersone));
    }

    addToUUIDPersoneList = (uuid, list) => {
        if (uuid)
            list.push(uuid);
    }

    getLingua = (object, lingue) => {
        object.idLingue = [];
        object.madrelingua = null;
        object.livello = null;
        object.descrizione = [];
        if (lingue.length > 0) {
            let isMadreLingua = !!lingue.find(lingua => lingua.madrelingua);
            if (isMadreLingua) {
                object.idLingue = [lingue[0].idElemento];
                object.madrelingua = isMadreLingua;
            } else {
                object.idLingue = lingue.map(lingua => lingua.idElemento);
                object.madrelingua = isMadreLingua;
                object.livello = lingue.find(lingua => !lingua.madrelingua)?.livello ?? null;
                object.descrizione = lingue.map(lingua => lingua.descrizione);
            }
        }
    }

    setIndirizzo = (indirizzi, object) => {
        let residenza = this.getRecapito("residenza", indirizzi, enumIndirizzo.indirizzo.residenza);
        if (residenza !== null && !Utils.isObjectNull(residenza)) {
            object.indirizzo = residenza.indirizzo;
            object.numero = residenza.numero;
            object.idElementoIndirizzo = residenza.idElemento;
        } else {
            let domicilio = this.getRecapito("domicilio", indirizzi, enumIndirizzo.indirizzo.domicilio);
            if (domicilio !== null && !Utils.isObjectNull(domicilio)) {
                object.indirizzo = domicilio.indirizzo;
                object.numero = domicilio.numero;
                object.idElementoIndirizzo = domicilio.idElemento;
            } else {
                let domicilioDiCura = this.getRecapito("domicilioDiCura", indirizzi, enumIndirizzo.indirizzo.altro);
                if (domicilioDiCura !== null && !Utils.isObjectNull(domicilioDiCura)) {
                    object.indirizzo = domicilioDiCura.indirizzo;
                    object.numero = domicilioDiCura.numero;
                    object.idElementoIndirizzo = domicilioDiCura.idElemento;
                } else {
                    object.indirizzo = null;
                    object.numero = null;
                    object.idElementoIndirizzo = null;
                }
            }
        }
    }

    getRecapito = (field, indirizzi, tipoIndirizzo) => {
        let listaIndirizzi = indirizzi.filter(indirizzo => indirizzo.tipo === tipoIndirizzo);
        let object = undefined;

        if (listaIndirizzi.length > 0)
            object = listaIndirizzi[listaIndirizzi.length - 1];

        if (object !== undefined || !Utils.isObjectNull(object)) {
            Object.keys(object).forEach(key => {
                if (!(key in defaultAnagrafePaziente[field]))
                    delete object[key];
            });
            return object;
        } else
            return null;
    }

    setContatto = (contatti, tipoContatto, object) => {
        let listaContatti = contatti.filter(el => el.tipo === tipoContatto);
        let contatto;

        if (listaContatti.length > 0)
            contatto = listaContatti[listaContatti.length - 1];

        switch (tipoContatto) {
            case enumContatto.contatto.telefono:
                object.telefono = contatto?.valore ?? null;
                object.idElementoTelefono = contatto?.idElemento ?? null;
                break;
            case enumContatto.contatto.cellulare:
                object.cellulare = contatto?.valore ?? null;
                object.idElementoCellulare = contatto?.idElemento ?? null;
                break;
            case enumContatto.contatto.email:
                object.email = contatto?.valore ?? null;
                object.idElementoEmail = contatto?.idElemento ?? null;
                break;
            default:
                break;
        }
    }

    formattaFormalizzazione = (formalizzazione) => {
        let stateFormalizzazione = {};
        stateFormalizzazione.caratteristicheSettingRichiesto = {
            codiceProfilo: formalizzazione.codiceProfilo,
            tipoValutazione: formalizzazione.tipoValutazione,
            dataValutazione: formalizzazione.dataValutazione ?? null
        };
        stateFormalizzazione.progettoAssistenziale = {
            progettoAssistenzialePrincipale: formalizzazione.progettoAssistenzialePrincipale,
            progettoAssistenzialeSecondario: formalizzazione.progettoAssistenzialeSecondario,
            ruoloDelResponsabile: formalizzazione.ruoloDelResponsabile,
            nomeResponsabile: formalizzazione.nomeResponsabile,
            cognomeResponsabile: formalizzazione.cognomeResponsabile,
            telefonoResponsabile: formalizzazione.telefonoResponsabile,
            cellulareResponsabile: formalizzazione.cellulareResponsabile,
            faxResponsabile: formalizzazione.faxResponsabile,
            emailResponsabile: formalizzazione.emailResponsabile,
            dataInizioProgettoAssistenziale: formalizzazione.dataInizioProgettoAssistenziale ?? null,
            dataVerificaProgrammata: formalizzazione.dataVerificaProgrammata ?? null
        };
        stateFormalizzazione.esitoValutazione = formalizzazione.esitoValutazione;
        stateFormalizzazione.motivazione = formalizzazione.motivazione;
        return stateFormalizzazione
    }

    changeAccordionAttivo = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            });
            AccordionHelper.setLastOpenedAccordion(field);
            this.setState({accordion}, () => {
                this.salvaBozzaFormalizzazione(false)
            });
        }
    }

    changeDatiFormalizzazione = (datiAccordion, fieldAccordion, numeroCampiObbligatoriRimanenti) => {
        this.accordionValidi[fieldAccordion] = numeroCampiObbligatoriRimanenti === 0;
        let sezioniValidate = this.validaFormalizzazione();
        let formalizzazione = _.cloneDeep(this.state.formalizzazione);
        formalizzazione[fieldAccordion] = datiAccordion;
        this.setState({formalizzazione: formalizzazione, sezioniValidate: sezioniValidate});
    }

    validaFormalizzazione = () => {
        let formCaratteristicheSettingValido = !_.some(this.state.formalizzazione.caratteristicheSettingRichiesto,
            (value, key) => {
                if(!Utils.isStringEmptyOrNullOrUndefined(value) && CONTROLLO_CAMPI_FORMALIZZAZIONE[key].validazione != null){
                    return !CONTROLLO_CAMPI_FORMALIZZAZIONE[key].validazione(value)
                } else {
                    return false
                }
            })
        let formProgettoAssistenzialeValido = !_.some(this.state.formalizzazione.progettoAssistenziale,
            (value, key) => {
                if(!Utils.isStringEmptyOrNullOrUndefined(value) && CONTROLLO_CAMPI_FORMALIZZAZIONE[key].validazione != null){
                    let valido = CONTROLLO_CAMPI_FORMALIZZAZIONE[key].validazione(value);
                    if(key === "dataVerificaProgrammata" && valido){
                        if(this.state.formalizzazione.progettoAssistenziale.dataInizioProgettoAssistenziale){
                            let inizio = moment(this.state.formalizzazione.progettoAssistenziale.dataInizioProgettoAssistenziale, "DD/MM/YYYY")
                            return !moment(value, "DD/MM/YYYY").isAfter(inizio);
                        } else {
                            return !valido;
                        }
                    } else if(key === "dataInizioProgettoAssistenziale" && valido){
                        if(this.state.formalizzazione.progettoAssistenziale.dataVerificaProgrammata){
                            let fine = moment(this.state.formalizzazione.progettoAssistenziale.dataVerificaProgrammata, "DD/MM/YYYY")
                            return !moment(value, "DD/MM/YYYY").isBefore(fine);
                        } else {
                            return !valido;
                        }
                    }
                    return !valido
                } else {
                    return false
                }
            })
        let formValidi = formCaratteristicheSettingValido && formProgettoAssistenzialeValido;
        let formCompleti = _.every(this.accordionValidi, sezione => sezione)
        return formCompleti && formValidi;
    }

    salvaBozzaFormalizzazione = (isButtonSalvaBozzaClicked) => {
        let formalizzazione = _.cloneDeep(this.state.formalizzazione);
        formalizzazione.esitoValutazione = enumRichiesta.stato.IN_FORMALIZZAZIONE;
        this.setState({formalizzazione: formalizzazione},
            () => this.salvaFormalizzazione(formalizzazione, isButtonSalvaBozzaClicked, true));
    }

    rifiutaSettingProposto = () => {
        let formalizzazione = _.cloneDeep(this.state.formalizzazione);
        formalizzazione.esitoValutazione = enumRichiesta.stato.RIFIUTATA;
        this.setState({formalizzazione: formalizzazione}, () => this.salvaFormalizzazione(formalizzazione));
    }

    accettaSettingProposto = () => {
        let formalizzazione = _.cloneDeep(this.state.formalizzazione);
        formalizzazione.esitoValutazione = enumRichiesta.stato.ACCETTATA;
        this.setState({formalizzazione: formalizzazione}, () => this.salvaFormalizzazione(formalizzazione));
    }

    salvaFormalizzazione = (formalizzazione = _.cloneDeep(this.state.formalizzazione), isButtonClicked = true, isSalvaBozza = false) => {
        let formalizzazioneForBe = this.preparaFormalizzazione(formalizzazione);
        return RichiestaADIService.salvaFormalizzazione(this.props.uuidRichiesta, formalizzazioneForBe, isButtonClicked).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                if(response?.msgCode && response.msgCode.startsWith("WARNING_WD")){
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "confirm",
                        text: errors[response?.msgCode]?.message || response?.msgCode
                    });
                    //l'operazione è stata comunque eseguita, quindi proseguo come per un ok
                } else if(response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_NON_INVIATO")) {
                    //non deve più farlo ma bisogna comunque gestire il caso
                    //this.setState({ pdfBtnDisabled: true });                    
                } else if(response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_IN_ERRORE")) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: "Errore nella creazione del CDA2"
                    });
                    this.chiudiModale();
                    return;
                } else if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                    });
                    this.chiudiModale();
                    return;
                }
            }
            if (isButtonClicked && !isSalvaBozza) {
              this.setState({operazioneEseguita: true})
            }
        });
    }

    preparaFormalizzazione(formalizzazione) {
        let formalizzazioneForBe = {};
        formalizzazioneForBe.nomeFormalizzatore = SessioneUtente?.getInstance()?.nomeUtente;
        formalizzazioneForBe.cognomeFormalizzatore = SessioneUtente?.getInstance()?.cognomeUtente;
        formalizzazioneForBe.codiceFiscaleFormalizzatore = SessioneUtente?.getInstance()?.idUtente;
        formalizzazioneForBe.codiceProfilo = formalizzazione.caratteristicheSettingRichiesto.codiceProfilo;
        formalizzazioneForBe.tipoValutazione = formalizzazione.caratteristicheSettingRichiesto.tipoValutazione;
        formalizzazioneForBe.dataValutazione = formalizzazione.caratteristicheSettingRichiesto.dataValutazione ?? null;

        formalizzazioneForBe.progettoAssistenzialePrincipale = formalizzazione.progettoAssistenziale.progettoAssistenzialePrincipale;
        formalizzazioneForBe.progettoAssistenzialeSecondario = formalizzazione.progettoAssistenziale.progettoAssistenzialeSecondario;
        formalizzazioneForBe.ruoloDelResponsabile = formalizzazione.progettoAssistenziale.ruoloDelResponsabile;
        formalizzazioneForBe.nomeResponsabile = formalizzazione.progettoAssistenziale.nomeResponsabile;
        formalizzazioneForBe.cognomeResponsabile = formalizzazione.progettoAssistenziale.cognomeResponsabile;
        formalizzazioneForBe.telefonoResponsabile = formalizzazione.progettoAssistenziale.telefonoResponsabile;
        formalizzazioneForBe.cellulareResponsabile = formalizzazione.progettoAssistenziale.cellulareResponsabile;
        formalizzazioneForBe.faxResponsabile = formalizzazione.progettoAssistenziale.faxResponsabile;
        formalizzazioneForBe.emailResponsabile = formalizzazione.progettoAssistenziale.emailResponsabile;
        formalizzazioneForBe.dataInizioProgettoAssistenziale = formalizzazione.progettoAssistenziale.dataInizioProgettoAssistenziale ?? null;
        formalizzazioneForBe.dataVerificaProgrammata = formalizzazione.progettoAssistenziale.dataVerificaProgrammata ?? null;

        formalizzazioneForBe.esitoValutazione = formalizzazione.esitoValutazione;
        formalizzazioneForBe.motivazione = formalizzazione.motivazione;

        _.forIn(formalizzazioneForBe, (value, key) => {
            formalizzazioneForBe[key] = value ? value : null;
        });
        return formalizzazioneForBe
    }

    apriModale = (tipoModale) => {
        this.setState({tipoModaleVisualizzata: tipoModale})
    }

    chiudiModale = () => {
        this.setState({tipoModaleVisualizzata: null})
    }

    renderForm = () => {
        return (
            <Fragment>
                <VersionedAccordion accordion={CaratteristicheSettingRichiesto}
                                    caratteristicheSettingRichiesto={this.state.formalizzazione.caratteristicheSettingRichiesto}
                                    richiesta={this.state.richiesta}
                                    openAccordion={this.state.accordion.openAccordionCaratteristicheSettingRichiesto}
                                    onClickAccordion={this.changeAccordionAttivo}
                                    field={"openAccordionCaratteristicheSettingRichiesto"}
                                    onChangeFormalizzazione={this.changeDatiFormalizzazione}
                                    pageState={this.props.pageState}/>

                <VersionedAccordion accordion={ProgettoAssistenziale}
                                    progettoAssistenziale={this.state.formalizzazione.progettoAssistenziale}
                                    richiesta={this.state.richiesta}
                                    openAccordion={this.state.accordion.openAccordionProgettoAssistenziale}
                                    onClickAccordion={this.changeAccordionAttivo}
                                    field={"openAccordionProgettoAssistenziale"}
                                    onChangeFormalizzazione={this.changeDatiFormalizzazione}
                                    pageState={this.props.pageState}/>
            </Fragment>
        )
    }

    controlloProfilo = () => {
        const isUtenteCentraleAdi = _.some(this.state?.richiesta?.team, t => SessioneUtente.getInstance().idUtente === t.professionista?.codiceFiscale && t.profilo === enumsUtente.profiliUtente.centraleAdi);
    }


    renderButtons() {
        if (this.state.operazioneEseguita) return <div className="row py-3 m-0"/>

        const caratteristicheSetting = _.cloneDeep(this.state.formalizzazione.caratteristicheSettingRichiesto);
        const btnList = [
            {
                id:"rifiutaSettingPropostoBtn",
                text: this.controlloProfilo() ? 'Rifiuta setting proposto' : 'Rifiuta setting proposto e firma',
                className:"btnWhiteOutlineBorderGreen",
                onclickFunction:() => {this.apriModale(enumTipoAvvisoSettingModale.tipoAvvisoSettingModale.RIFIUTA_SETTING_PROPOSTO)},
                isvisible: true,
                disabled: Object.keys(caratteristicheSetting).some(k => !!CONTROLLO_CAMPI_FORMALIZZAZIONE[k].required && (caratteristicheSetting[k] == null || caratteristicheSetting[k] === ""))
            },
            {
                id: "salvaBozzaBtn",
                text: "Salva bozza",
                className: "btnWhiteOutlineBorderGreen",
                onclickFunction: () => {this.salvaBozzaFormalizzazione()},
                isvisible: true
            },
            {
                id:"accettaSettingPropostoBtn",
                text: this.controlloProfilo() ? 'Accetta setting proposto' : 'Accetta setting proposto e firma',
                className:"btnGreenOutline",
                onclickFunction:() => {

                    if (Utils.isDimissioneProtetta(this.props.pageState)) {
                        Utils.getDataDimissioneProgrammataDaRicovero(this.state.richiesta.id)
                            .subscribe(dataDimissioneProgrammataDaRicovero => {
                                if (dataDimissioneProgrammataDaRicovero !==
                                    this.state.richiesta?.dataDimissioneProgrammataDaRicovero?.dataDimissioneProgrammataDaRicovero) {
                                    this.setState({
                                        apriModaleDataDimissioneProgrammataAggiornata: true,
                                        dataDimissioneProgrammataDaRicovero: dataDimissioneProgrammataDaRicovero
                                    })
                                } else {
                                    this.apriModale(enumTipoAvvisoSettingModale.tipoAvvisoSettingModale.CONFERMA_SETTING_PROPOSTO)
                                }
                            });
                    } else {
                        this.apriModale(enumTipoAvvisoSettingModale.tipoAvvisoSettingModale.CONFERMA_SETTING_PROPOSTO)
                    }
                },
                disabled:!this.state.sezioniValidate,
                isvisible: true
            }
        ]

        return (
            <ButtonsBoxStick
                parentClass="d-flex justify-content-between"
                btnList={btnList}
            />
        );
    }

    // controlloProfilo = () => {
    //     const isUtenteCentraleAdi = _.some(this.state?.richiesta?.team, t => SessioneUtente.getInstance().idUtente === t.professionista?.codiceFiscale && t.profilo === enumsUtente.profiliUtente.centraleAdi);
    // }    

    renderModaleAvvisoSetting = () => {
        let tipoModale = _.cloneDeep(this.state.tipoModaleVisualizzata);
        let titleConfermaSetting = (
            <Fragment>
                <span className={"modal-title"}>Attenzione</span>
            </Fragment>
        );
        let titleRifiutaSetting = (
            <Fragment>
                <span className={"modal-title"}>Rifiuta setting proposto</span>
            </Fragment>
        );

        let bodyConfermaSetting = "Stai per procedere con l’accettazione del setting proposto nella richiesta di cure domiciliari. Vuoi confermare l’operazione?";
        let bodyRifiutaSetting = <Fragment>
            <div className={"row"}>
                <div className={"col"}>
                    <FieldsGroup>
                        <RoleBasedInput
                            id={"motivazione"}
                            fieldId={"MOTIVAZIONE.VALUTAZIONECAMBIOSETTING.PROGETTOASSISTENZIALERICHIESTO"}
                            pageState={this.props.pageState}
                            parentClass={"mb-0"}
                            value={this.state.formalizzazione?.motivazione}
                            placeholder={"Inserisci motivazione"}
                            field={"motivazione"}
                            onChange={(field, elem) => {
                                let formalizzazione = _.cloneDeep(this.state.formalizzazione);
                                formalizzazione.motivazione = elem.target.value;
                                this.setState({formalizzazione: formalizzazione})
                            }}
                            type={"textarea"}
                            forceReadOnly={false}
                            hideUnsetValues={false}
                            disableDoubleInput={false}
                            maxlength={500}
                            showAvailableCharsLabel={true}
                            fieldRequired={true}
                            fieldLabel={"Motivazione"}
                            rows={"5"}
                        />
                    </FieldsGroup>
                </div>
            </div>
        </Fragment>

        let footerConfermaSetting = (
            <Fragment>
                <Link to={{ pathname: routePath.storico_richieste }}>
                <Button
                    id={"conferma"}
                    text={"Conferma"}
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => {
                        this.accettaSettingProposto()
                    }}
                />
                </Link>
                <Button
                    id={"annulla"}
                    text={"Annulla"}
                    dataDismiss={"modal"}
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    onClick={() => {
                        this.chiudiModale()
                    }}
                />
            </Fragment>
        );

        let footerRifiutaSetting = (
            <Fragment>
                <Link to={{ pathname: routePath.storico_richieste }}>
                <Button
                    id={"conferma"}
                        text={this.controlloProfilo() ? 'Rifiuta setting' : 'Rifiuta setting e firma'}
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => {
                        this.rifiutaSettingProposto()
                    }}
                    disabled={Utils.isStringEmptyOrNullOrUndefined(this.state.formalizzazione?.motivazione)}
                />
                </Link>
                <Button
                    id={"annulla"}
                    text={"Annulla"}
                    dataDismiss={"modal"}
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    onClick={() => {
                        let formalizzazione = _.cloneDeep(this.state.formalizzazione);
                        formalizzazione.motivazione = null;
                        this.setState({formalizzazione: formalizzazione}, ()=> {
                            this.chiudiModale()
                        });

                    }}
                />
            </Fragment>
        );

        let title;
        let body;
        let footer;
        let styleBody= "";
        switch (tipoModale) {
            case enumTipoAvvisoSettingModale.tipoAvvisoSettingModale.CONFERMA_SETTING_PROPOSTO: {
                title = titleConfermaSetting;
                body = bodyConfermaSetting;
                footer = footerConfermaSetting;
                styleBody = "d-flex "
            }
                break;
            case enumTipoAvvisoSettingModale.tipoAvvisoSettingModale.RIFIUTA_SETTING_PROPOSTO: {
                title = titleRifiutaSetting;
                body = bodyRifiutaSetting;
                footer = footerRifiutaSetting;
            }
                break;
            default:
                break;
        }
        return (
            <ModalHelper id={"modaleAvvisoSetting"}
                         title={title}
                         body={body}
                         footer={footer}
                         titleClassName={"modal-header d-inline text-center backgroundOrange"}
                         containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                         contentClassName={"modal-content"}
                         footerClassName={"modal-footer justify-content-around"}
                         bodyClassName={"modal-body align-items-center f-weight-600 "+ styleBody}
            />)
    }

    downloadPdf = () => {
        let uuidRichiesta = this.state.richiesta?.id;
        RichiestaADIService.generaPdf(uuidRichiesta, "STEP_CDA2_CONFERMA_SETTING").subscribe((response) => {
            if (response?.msg === "ERRORE") {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "confirm",
                    text: "Il fascicolo sanitario elettronico non è al momento disponibile. Si prega di riprovare più tardi"
                });
            } else {
                const linkSource = `data:${response.data.contentType};base64,${response.data.file}`;
                const downloadLink = document.createElement("a");
                const fileName = response.data.fileName;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        });
    }

    renderModaleSuccesso = () => {
        let getBody = () => {
            let stringAccettata = "La richiesta e il relativo setting sono stati correttamente accettati";
            let stringRifiutata = "La richiesta e il relativo setting sono stati rifiutati";
            return this.state.formalizzazione.esitoValutazione === enumRichiesta.stato.ACCETTATA ? stringAccettata : stringRifiutata;
        };

        const footerModaleOperazioneEseguita = (
            <>
                <Button
                    onClick={() => this.downloadPdf()}
                    id={"visualizzaPdfBtn"}
                    text="Visualizza Pdf"
                    className={"btn btn-adi btnGreenOutline"}
                    disabled={this.state.pdfBtnDisabled}
                />
                <Link to={{ pathname: routePath.home }}>
                    <Button
                        onClick={() => this.setState({redirectTo:{path: routePath.home}})}
                        id={"tornaHomeBtn"}
                        text="Torna a Home"
                        dataDismiss={"modal"}
                        className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                        disabled={false}
                    />
                </Link>
            </>
        );


        return (
            <>
                <ModalHelper
                    id={"operazioneCompletataModal"}
                    title={"Operazione completata"}
                    body={getBody()}
                    footer={footerModaleOperazioneEseguita}
                    titleClassName={"modal-header d-inline text-center backgroundGreen"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={"modal-content"}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={"modal-body d-flex align-items-center justify-content-around f-weight-600"}
                />
            </>
        );
    }

    gotoAnagraficaRichiedente = () => {
        InserimentoRichiesta.salvaBozzaSubject.next();
        this.setState({gotoAnagraficaRichiedente: true});
    }

    render() {
        AccordionHelper.accordionPageSubject.next({position: 2, title: "Compila Progetto assistenziale"});

        if (this.state.redirectTo) return <Redirect to={{pathname: this.state.redirectTo.path}}/>

        if (this.state.gotoAnagraficaRichiedente) {
            return <Redirect to={{
                pathname: routePath.compila_formalizzazione.concat(routePath.anagrafica_medico_richiedente),
                idPersona: Utils.recuperoIdProfessionista(this.state.richiesta),
                uuidRichiesta: this.props.uuidRichiesta,
                pageState: this.props.pageState,
                pageName: this.props.pageName,
                readOnly: !isSomeFieldIdWriteable('ANAGRAFESOGGETTORICHIEDENTE', this.props.pageState),
                hideUnsetValues: true,
                insRichiestaPathname: routePath.compila_formalizzazione,
                richiesta: this.state.richiesta,
                tipologia: this.state.richiesta?.tipologia,
                stato: this.state.richiesta?.stato
            }}/>
        }

        AccordionHelper.abilitazioneProseguiSubject.next(this.state.sezioniValidate);

        if (this.state.gotoAnagraficaRichiedente) {
            return <Redirect to={{
                pathname: this.props.pathname?.concat(routepath.anagrafica_medico_richiedente),
                idPersona: Utils.recuperoIdProfessionista(this.state.richiesta),
                uuidRichiesta: this.props.uuidRichiesta,
                pageState: this.props.pageState,
                pageName: this.props.pageName,
                readOnly: true,
                hideUnsetValues: true,
                firstPageName: enumPaginaCompila.COMPILA_FORMALIZZAZIONE,
                insRichiestaPathname: this.props.pathname,
                richiesta: this.state.richiesta,
                showOnlyRequiredFields: false,
                previousPageOnlyRequiredFields: false,
                showMultiCompilationPages: false
            }}/>
        }

        return (
            <Fragment>
                {this.state.tipoModaleVisualizzata && this.renderModaleAvvisoSetting()}
                {this.state.operazioneEseguita && this.renderModaleSuccesso()}
                <Breadcrumb path={['Home', 'Compila formalizzazione']}
                            richiesta={this.state.richiesta}/>
                {this.state.apriModaleDataDimissioneProgrammataAggiornata &&
                <PopupDataDimissioneProgrammataAggiornata
                    dataDimissioneProgrammataDaRicovero={this.state.dataDimissioneProgrammataDaRicovero}
                    chiudiPopupDataDimissioneProgrammataAggiornata={(isDataConfermata) => {
                        if (isDataConfermata) this.setState({
                            apriModaleDataDimissioneProgrammataAggiornata: false
                        }, () => this.apriModale(enumTipoAvvisoSettingModale.tipoAvvisoSettingModale.CONFERMA_SETTING_PROPOSTO))
                        else this.setState({apriModaleDataDimissioneProgrammataAggiornata: false})

                    }}
                />
                }
                {showMessageAulssDistrettoDifferences(this.state.richiesta, this.state.persone)}
                <Trackbar
                    pageState={Utils.getPageStateDimissioneProtetta(this.state.richiesta?.tipologia, this.state.richiesta?.stato, enumPaginaCompila.COMPILA_FORMALIZZAZIONE) ?? "F"}
                    richiesta={this.state.richiesta}
                    persone={this.state.persone}
                    showOptionalFieldsCount={false}
                    listaCodiciFiscaliUtentiOnline={this.state.listaCodiciFiscaliUtentiOnline}/>
                <div>
                    <RoleBasedContext.Provider
                        value={{
                            forceReadOnly: false,
                            hideUnsetValues: false,
                            scrollToHead: false,
                            forceUserRole: null,
                            requestVersion: this.state.richiesta?.versione,
                            showOnlyRequiredFields: false
                        }}>
                        <div className="mx-5">{this.renderForm()}</div>
                    </RoleBasedContext.Provider>
                </div>
                {this.renderButtons()}
            </Fragment>
        );
    }
}

CompilaFormalizzazione.propTypes = propTypes;
