import React, {Component, Fragment} from "react";
import {Container} from "reactstrap";
import _ from "lodash";
import {Context} from "../../utils/Context";
import pageTexts from "../../utils/message/pageTexts.json";
import AccordionHelper from "../../service/AccordionHelper";
import Question from "./Question";
import annullaRichiesta from '../../img/faq/annullaRichiesta.png';
import statoValutazioneEComponentiDelTeam from '../../img/faq/statoValutazioneEComponentiDelTeam.png';
const classNameContainer = "pl-5 pr-5 position-relative";

class Faq extends Component {

    state = {
        accordion: {
            openAccordionQuestionOne: false,
            openAccordionQuestionTwo: false,
            openAccordionQuestionThree: false,
            openAccordionQuestionFour: false,
            openAccordionQuestionFive: false,
            openAccordionQuestionSix: false,
            openAccordionQuestionSevent: false,
            openAccordionQuestionEight: false,
            openAccordionQuestionNine: false,
            openAccordionQuestionTen: false,
        }
    };

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            });

            AccordionHelper.setLastOpenedAccordion(field);
            this.setState({accordion});
        }
    }

    render () {
        return (
            <Fragment>
                <Container fluid className={classNameContainer}>
                    <Container fluid className={"position-relative pt-4"}>
                        <h1>{pageTexts.faq.header}</h1>
                        {/* QUESTION ONE */}
                        <Question
                            openAccordion={this.state.accordion.openAccordionQuestionOne}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionQuestionOne"}
                            idAccordion={"accordionQuesionOne"}
                            title={pageTexts.faq.questionOne.title}
                            content={pageTexts.faq.questionOne.content}>
                        </Question>
                        {/* QUESTION TWO */}
                        <Question
                            openAccordion={this.state.accordion.openAccordionQuestionTwo}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionQuestionTwo"}
                            idAccordion={"accordionQuesionTwo"}
                            title={pageTexts.faq.questionTwo.title}
                            content={pageTexts.faq.questionTwo.content}>
                        </Question>
                        {/* QUESTION THREE */}
                        <Question
                            openAccordion={this.state.accordion.openAccordionQuestionThree}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionQuestionThree"}
                            idAccordion={"accordionQuesionThree"}
                            title={pageTexts.faq.questionThree.title}
                            content={pageTexts.faq.questionThree.content}>
                        </Question>
                        {/* QUESTION FOUR */}
                        <Question
                            openAccordion={this.state.accordion.openAccordionQuestionFour}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionQuestionFour"}
                            idAccordion={"accordionQuesionFour"}
                            title={pageTexts.faq.questionFour.title}
                            content={pageTexts.faq.questionFour.content}>
                        </Question>
                        {/* QUESTION FIVE */}
                        <Question
                            openAccordion={this.state.accordion.openAccordionQuestionFive}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionQuestionFive"}
                            idAccordion={"accordionQuesionFive"}
                            title={pageTexts.faq.questionFive.title}
                            content={pageTexts.faq.questionFive.content}>
                        </Question>
                        {/* QUESTION SIX */}
                        <Question
                            openAccordion={this.state.accordion.openAccordionQuestionSix}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionQuestionSix"}
                            idAccordion={"accordionQuesionSix"}
                            title={pageTexts.faq.questionSix.title}
                            content={pageTexts.faq.questionSix.content}
                            subContent={pageTexts.faq.questionSix.subContent}
                            // img={annullaRichiesta}
                            // alt={"annullaRichiesta"}
                        >
                        </Question>
                        {/* QUESTION SEVEN */}
                        <Question
                            openAccordion={this.state.accordion.openAccordionQuestionSevent}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionQuestionSevent"}
                            idAccordion={"accordionQuesionSevent"}
                            title={pageTexts.faq.questionSevent.title}
                            content={pageTexts.faq.questionSevent.content}
                            // img={statoValutazioneEComponentiDelTeam}
                            // alt={"statoValutazioneEComponentiDelTeam"}
                            
                            >
                        </Question>
                        {/* QUESTION EIGHT */}
                        <Question
                            openAccordion={this.state.accordion.openAccordionQuestionEight}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionQuestionEight"}
                            idAccordion={"accordionQuestionEight"}
                            title={pageTexts.faq.questionEight.title}
                            content={pageTexts.faq.questionEight.content}>
                        </Question>
                        {/* QUESTION NINE */}
                        <Question
                            openAccordion={this.state.accordion.openAccordionQuestionNine}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionQuestionNine"}
                            idAccordion={"accordionQuestionNine"}
                            title={pageTexts.faq.questionNine.title}
                            content={pageTexts.faq.questionNine.content}>
                        </Question>
                        {/* QUESTION TEN */}
                        <Question
                            openAccordion={this.state.accordion.openAccordionQuestionTen}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionQuestionTen"}
                            idAccordion={"accordionQuestionTen"}
                            title={pageTexts.faq.questionTen.title}
                            content={pageTexts.faq.questionTen.content}>
                        </Question>
                    </Container>
                </Container>
            </Fragment >
        )
    }
}


Faq.contextType = Context;

export default Faq;