import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import SchedaPazienteChiudiPreDialogo, {getChiudiPredialogoBodyForBe} from "./SchedaPazienteChiudiPreDialogo";
import ProposteChiudiPreDialogo from "./ProposteChiudiPreDialogo";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import { Button, SessioneUtente } from "web-client-archetype";
import * as _ from "lodash";
import Utils from "../../utils/Utils";
import PopupDataDimissioneProgrammataAggiornata
    from "../visualizzaRiepilogo/popupDataDimissioneProgrammataAggiornata/PopupDataDimissioneProgrammataAggiornata";
import * as $ from "jquery";
import {FieldsGroup, RoleBasedInput} from "../roleBasedComponents/RoleBasedComponents";
import ModalHelper from "../modale/ModalHelper";
import RichiestaADIService from "../../service/RichiestaADIService";
import enumRichiesta from "../../enum/enumRichiesta.json";
import {Redirect} from "react-router-dom";
import routepath from "../../utils/route/route-path.json";
import errors from "../../utils/errors/errors.json";
import enumPaginaCompila from "../../enum/enumPaginaCompila.json";
import enumTipoAvvisoSettingModale from "../../enum/enumTipoAvvisoSettingModale.json";
import AccordionHelper from "../../service/AccordionHelper";
import ButtonsBoxStick from "../buttonsBoxStick/buttonsBoxStick";
import enumsUtente from "../../enum/enumsUtente.json";

const propTypes = {
    richiesta: PropTypes.any,
    richiestaMedico: PropTypes.any,
    pageState: PropTypes.string,
    forcedModalitaRiepilogo: PropTypes.string,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    pageName: PropTypes.string,
    firstPageName: PropTypes.string,
    persone: PropTypes.object,
    pageNumber: PropTypes.number,
    listaCodiciFiscaliUtentiOnline: PropTypes.array
}

export default class RiepilogoChiudiPreDialogo extends Component {

    state = {
        redirectToIndietro: false,
        // bool per modale successo
        operazioneEseguita: false,
        // variabile di stato per la visualizzazione della modale
        tipoModaleVisualizzata: null,
        // motivazione rifiuto setting
        motivazione: null,

        esitoValutazione: null,

        gotoHome: false,

        // variabile utilizzate per mostrare o meno la modale di avviso aggiornamento della data dimissione programmata in dimissione protetta
        dataDimissioneProgrammataDaRicovero: null
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        $('#compilaRichiestaId div#trackbar, #compilaRichiestaId div.headerRichiesta, #compilaProposteId div#trackbar, #compilaProposteId div.headerRichiesta').remove();
        $('#compilaRichiestaId div.bottoniLayout, #compilaProposteId div.bottoniLayout').remove();
        $('label:contains("*Campo obbligatorio")').remove()
    }

    controlloProfilo = () => {
        const isUtenteCentraleAdi = _.some(this.state?.richiesta?.team, t => SessioneUtente.getInstance().idUtente === t.professionista?.codiceFiscale && t.profilo === enumsUtente.profiliUtente.centraleAdi);
    }


    renderAccordions = () => {
        return <RoleBasedContext.Provider
            value={{
                forceReadOnly: true,
                hideUnsetValues: true,
                scrollToHead: true,
                requestVersion: this.props.richiesta?.versione,
                showOnlyRequiredFields: false
            }}>
            <Fragment>
                <SchedaPazienteChiudiPreDialogo
                    richiesta={this.props.richiesta}
                    pageState={this.props.pageState}
                    pageName={this.props.pageName}
                    readOnly={true}
                    hideUnsetValues={true}
                    openAccordion={true}
                    pageNumber={1}/>

                <ProposteChiudiPreDialogo
                    richiesta={this.props.richiesta}
                    pageState={this.props.pageState}
                    pageName={this.props.pageName}
                    readOnly={true}
                    hideUnsetValues={true}
                    openAccordion={true}
                    pageNumber={2}/>
            </Fragment>
        </RoleBasedContext.Provider>
    }

    renderButtons() {
        let btnList = [
            {
                gropBtnList: [
                    {
                        id:"indietroBtn",
                        text:"Indietro",
                        className:"btnWhiteOutlineBorderGreen",
                        onclickFunction:() => this.setState({redirectToIndietro: true}),
                        isvisible: true
                    },
                    {
                        id:"rifiutaSettingPropostoBtn",
                        text: this.controlloProfilo() ? 'Rifiuta setting proposto' : 'Rifiuta setting proposto e firma',
                        className:"btnWhiteOutlineBorderGreen",
                        onclickFunction:() => this.setState({tipoModaleVisualizzata: enumTipoAvvisoSettingModale.tipoAvvisoSettingModale.RIFIUTA_SETTING_PROPOSTO}),
                        isvisible: !this.props.readOnly,
                    },
                ],
                isvisible: true
            },
            {
                id:"accettaSettingPropostoBtn",
                text:"Accetta setting proposto",
                className:"btn btn-adi btnGreenOutline",
                onclickFunction:() => {
                    const thisData = this.props.richiesta?.dataDimissioneProgrammataDaRicovero?.dataDimissioneProgrammataDaRicovero;
                    if (thisData) {
                        Utils.getDataDimissioneProgrammataDaRicovero(this.props.richiesta.id)
                            .subscribe(dataDimissioneProgrammataDaRicovero => {
                                if (dataDimissioneProgrammataDaRicovero !== thisData) {
                                    this.setState({
                                        apriModaleDataDimissioneProgrammataAggiornata: true,
                                        dataDimissioneProgrammataDaRicovero: dataDimissioneProgrammataDaRicovero
                                    })
                                } else {
                                    this.setState({tipoModaleVisualizzata: enumTipoAvvisoSettingModale.tipoAvvisoSettingModale.CONFERMA_SETTING_PROPOSTO});
                                }
                            });
                    }
                },
                isvisible: true
            }  
        ]

        return (
            <ButtonsBoxStick 
                parentClass="d-flex justify-content-between"
                btnList={btnList}
            />
        );
    }
    
    controlloProfilo = () => {
        const isUtenteCentraleAdi = _.some(this.state?.richiesta?.team, t => SessioneUtente.getInstance().idUtente === t.professionista?.codiceFiscale && t.profilo === enumsUtente.profiliUtente.centraleAdi);
    }


    renderModaleAvvisoSetting = () => {
        const isSettingAccettato = this.state.tipoModaleVisualizzata === enumTipoAvvisoSettingModale.tipoAvvisoSettingModale.CONFERMA_SETTING_PROPOSTO;

        const titleConfermaSetting = (
            <Fragment>
                <span className={"modal-title"}>Attenzione</span>
            </Fragment>
        );
        const titleRifiutaSetting = (
            <Fragment>
                <span className={"modal-title"}>Rifiuta setting proposto</span>
            </Fragment>
        );

        const bodyConfermaSetting = "Stai per procedere con l’accettazione del setting proposto nella richiesta di cure domiciliari. Vuoi confermare l’operazione?";
        const bodyRifiutaSetting = <Fragment>
            <div className={"row"}>
                <div className={"col"}>
                    <FieldsGroup >
                        <RoleBasedInput
                            id={"motivazione"}
                            fieldId={"MOTIVAZIONE.VALUTAZIONECAMBIOSETTING.PROGETTOASSISTENZIALERICHIESTO"}
                            pageState={this.props.pageState}
                            parentClass={"mb-0"}
                            value={this.state.motivazione}
                            placeholder={"Inserisci motivazione"}
                            field={"motivazione"}
                            onChange={(field, elem) => this.setState({[field]: elem.target.value})}
                            type={"textarea"}
                            forceReadOnly={false}
                            hideUnsetValues={false}
                            disableDoubleInput={false}
                            maxlength={500}
                            showAvailableCharsLabel={true}
                            fieldRequired={true}
                            fieldLabel={"Motivazione"}
                            rows={"5"}
                        />
                    </FieldsGroup>
                </div>
            </div>
        </Fragment>;

        const footerConfermaSetting = (
            <Fragment>
                <Button
                    id={"conferma"}
                    text={"Conferma"}
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => {
                        this.setState({tipoModaleVisualizzata: enumTipoAvvisoSettingModale.tipoAvvisoSettingModale.CONFERMA_SETTING_PROPOSTO},
                            () => this.chiudiPredialogo(enumRichiesta.stato.ACCETTATA));
                    }}
                />
                <Button
                    id={"annulla"}
                    text={"Annulla"}
                    dataDismiss={"modal"}
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    onClick={() => this.setState({tipoModaleVisualizzata: null})}
                />
            </Fragment>
        );

        const footerRifiutaSetting = (
            <Fragment>
                <Button
                    id={"conferma"}
                    text={this.controlloProfilo() ? 'Rifiuta setting' : 'Rifiuta setting e firma'}
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => this.chiudiPredialogo(enumRichiesta.stato.RIFIUTATA)}
                    disabled={Utils.isStringEmptyOrNullOrUndefined(this.state.motivazione)}
                />
                <Button
                    id={"annulla"}
                    text={"Annulla"}
                    dataDismiss={"modal"}
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    onClick={() => this.setState({tipoModaleVisualizzata: null, motivazione: null})}
                />
            </Fragment>
        );

        return (
            <ModalHelper id={"modaleAvvisoSetting"}
                         title={isSettingAccettato ? titleConfermaSetting : titleRifiutaSetting}
                         body={isSettingAccettato ? bodyConfermaSetting : bodyRifiutaSetting}
                         footer={isSettingAccettato ? footerConfermaSetting : footerRifiutaSetting}
                         titleClassName={["modal-header d-inline text-center backgroundOrange"].join(" ")}
                         containerClassName={["modal-dialog modal-dialog-scrollable modal modal-dialog-centered"].join(" ")}
                         contentClassName={["modal-content"].join(" ")}
                         footerClassName={["modal-footer justify-content-around"].join(" ")}
                         bodyClassName={["modal-body align-items-center f-weight-600 ", isSettingAccettato ? "d-flex " : ""].join(" ")}
            />)
    }

    downloadPdf = () => {
        let uuidRichiesta = this.state.richiesta?.id;
        RichiestaADIService.generaPdf(uuidRichiesta, "STEP_CDA2_CONFERMA_SETTING").subscribe((response) => {
            if (response?.msg === "ERRORE") {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "confirm",
                    text: "Il fascicolo sanitario elettronico non è al momento disponibile. Si prega di riprovare più tardi"
                });
            } else {
                const linkSource = `data:${response.data.contentType};base64,${response.data.file}`;
                const downloadLink = document.createElement("a");
                const fileName = response.data.fileName;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        });
    }

    renderModaleSuccesso = () => {
        let getBody = () => {
            let stringAccettata = "La richiesta e il relativo setting sono stati correttamente accettati";
            let stringRifiutata = "La richiesta e il relativo setting sono stati rifiutati";
            return this.state.esitoValutazione === enumRichiesta.stato.ACCETTATA ? stringAccettata : stringRifiutata;
        };

        const footerModaleOperazioneEseguita = (
            <>
                <Button
                    onClick={() => this.downloadPdf()}
                    id={"visualizzaPdfBtn"}
                    text="Visualizza Pdf"
                    className={"btn btn-adi btnGreenOutline"}
                    disabled={this.state.pdfBtnDisabled}
                />
                <Button
                    onClick={() => this.setState({gotoHome: true})}
                    id={"tornaHomeBtn"}
                    text="Torna a Home"
                    dataDismiss={"modal"}
                    className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                    disabled={false}
                />
            </>
        );

        return (
            <>
                <ModalHelper
                    id={"operazioneCompletataModal"}
                    title={"Operazione completata"}
                    body={getBody()}
                    footer={footerModaleOperazioneEseguita}
                    titleClassName={"modal-header d-inline text-center backgroundGreen"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={"modal-content"}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={"modal-body d-flex align-items-center justify-content-around f-weight-600"}
                />
            </>
        );
    }

    chiudiPredialogo = (esitoValutazione) => {
        const chiudiPreDialogoDto = getChiudiPredialogoBodyForBe(this.props.richiesta, RichiestaADIService.richiestaRicercata, esitoValutazione, this);
        return RichiestaADIService.chiudiPreDialogo(this.props.richiesta.id, chiudiPreDialogoDto, true)
            .subscribe((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.msgCode && response.msgCode.startsWith("WARNING_WD")) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ?? response?.status,
                            type: "confirm",
                            text: errors[response?.msgCode]?.message || response?.msgCode
                        });
                        // l'operazione è stata comunque eseguita, quindi proseguo come per un ok
                        this.setState({operazioneEseguita: true, esitoValutazione: esitoValutazione});
                    } else if (response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_NON_INVIATO")) {
                        // non deve più farlo ma bisogna comunque gestire il caso
                        // this.setState({ pdfBtnDisabled: true });
                        this.setState({operazioneEseguita: true, esitoValutazione: esitoValutazione});
                    } else if (response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_IN_ERRORE")) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ?? response?.status,
                            type: "critical",
                            text: "Errore nella creazione del CDA2"
                        });                        
                    } else if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ?? response?.status,
                            type: "critical",
                            text: response?.data?.errore ?? response?.data?.message
                        });
                    }
                } else this.setState({operazioneEseguita: true, esitoValutazione: esitoValutazione});
            });
    }

    render() {
        AccordionHelper.accordionPageSubject.next({position: 2, title: "Visualizza Riepilogo (3 di 3)"});

        if (this.state.redirectToIndietro) {
            return (
                <Redirect to={{
                    pathname: routepath.compila_chiusura_predialogo.concat(routepath.compila_proposte),
                    uuidRichiesta: this.props.richiesta.id,
                    pageState: this.props.pageState,
                    readOnly: this.props.readOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    pageName: enumPaginaCompila.COMPILA_PROPOSTE,
                    firstPageName: this.props.firstPageName,
                    richiesta: this.props.richiesta,
                    richiestaMedico: this.props.richiestaMedico,
                    persone: this.props.persone,
                    tipologia: this.props.richiesta?.tipologia,
                    stato: this.props.richiesta?.stato,
                    pageNumber: 2
                }}/>
            );
        }

        if (this.state.gotoHome) return <Redirect to={{pathname: routepath.home}}/>;

        return (
            <Fragment>
                {this.state.apriModaleDataDimissioneProgrammataAggiornata &&
                    <PopupDataDimissioneProgrammataAggiornata
                        dataDimissioneProgrammataDaRicovero={this.state.dataDimissioneProgrammataDaRicovero}
                        chiudiPopupDataDimissioneProgrammataAggiornata={(isDataConfermata) => {
                            if (isDataConfermata) {
                                this.setState({apriModaleDataDimissioneProgrammataAggiornata: false},
                                    () => this.setState({tipoModaleVisualizzata: enumTipoAvvisoSettingModale.tipoAvvisoSettingModale.CONFERMA_SETTING_PROPOSTO}));
                            } else {
                                this.setState({apriModaleDataDimissioneProgrammataAggiornata: false});
                            }
                        }}
                    />}
                {this.state.tipoModaleVisualizzata && this.renderModaleAvvisoSetting()}
                {this.state.operazioneEseguita && this.renderModaleSuccesso()}
                {this.renderAccordions()}
                {this.renderButtons()}
            </Fragment>
        )
    }
}

RiepilogoChiudiPreDialogo.propTypes = propTypes;
