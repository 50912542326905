import React, {Fragment} from "react";
import AccordionFirstLevel from "../../../accordionAvvioRichiesta/AccordionFirstLevel";
import PropTypes from "prop-types";
import AccordionAvvioRichiesta from "../../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import * as _ from "lodash";
import Utils from "../../../../utils/Utils";
import AulssUtils from "../../../../utils/AulssUtils";
import AuthUtils from "../../../../utils/AuthUtils";

const propTypes = {
    datiSezione: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    idAccordion: PropTypes.string,
    titleAccordion: PropTypes.string,
    openAccordion: PropTypes.bool,
    onClickAccordion: PropTypes.func,
    accordionField: PropTypes.string
}

const NOME_OGGETTO_DETTAGLIO_ASSISTITO = "datiAssistito";
const NOME_OGGETTO_DETTAGLIO_RICHIESTA = "datiRichiesta";
const NOME_OGGETTO_DETTAGLIO_VALUTAZIONE_PROFESSIONALE = "datiValutazioneProfessionale";
const NOME_OGGETTO_DETTAGLIO_PROFESSIONISTI = "professionisti";
const NOME_OGGETTO_DETTAGLIO_VALUTAZIONE_MULTIDIMENSIONALE = "datiValutazioneMultidimensionale";
const NOME_OGGETTO_DETTAGLIO_FORMALIZZAZIONE = "datiFormalizzazione";
const NOME_OGGETTO_DETTAGLIO_CURE_DOMICILIARI = "datiCureDomiciliari";

export default class SintesiRichiesta extends AccordionFirstLevel {
    state = {
        dettaglio: _.cloneDeep(this.props.datiSezione)
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiSezione, this.props.datiSezione))
            this.setState({dettaglio: this.props.datiSezione});
    }

    getNomeAulssByCodice = (codiceAulss) => {
        const aulss = AulssUtils.getAulssByCodiceAulss(codiceAulss);
        return aulss ? `Azienda ${aulss.nome} ${aulss.descrizione}` : null;
    }

    getNomeDistrettoByCodiceAulssDistretto = (codiceAulss, codiceDistretto) => {
        const distretto = AulssUtils.getDistrettoByIdAulssAndIdDistretto(codiceAulss, codiceDistretto);
        return distretto?.descrizione ?? null;
    }

    areElementsEqual = (first, second) => {
        return first && second && _.isEqual(first, second);
    }

    renderColumn = (titolo, testo) => {
        return testo != null
            ? <div className={"col-4"}>
                <div className={"row"}>
                    <div className={"col-12"}><span><strong>{titolo}</strong></span></div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}><span className={"text-break"}>{testo}</span></div>
                </div>
            </div>
            : <></>
    }

    renderDettaglioAssistito = () => {
        const residenzaDomicilio = this.state.dettaglio.residenzaDomicilio;
        let residenzaDomicilioJSX = <></>;
        if (!Utils.isObjectNull(residenzaDomicilio)) {
            residenzaDomicilioJSX = <>
                <div className={"row px-2"}>
                    <span className="f-weight-700" style={{color: "#176A65", textDecoration: "underline"}}>
                        Residenza e domicilio
                    </span>
                </div>
                <div className={"row mb-2"}>
                    {this.renderColumn("AULSS di residenza", this.getNomeAulssByCodice(residenzaDomicilio.aulssResidenza))}
                </div>
                <div className={"row mb-2"}>
                    {this.renderColumn("Distretto di residenza", this.getNomeDistrettoByCodiceAulssDistretto(residenzaDomicilio.aulssResidenza, residenzaDomicilio.distrettoResidenza))}
                </div>
                {!this.areElementsEqual(residenzaDomicilio.aulssResidenza, residenzaDomicilio.aulssDomicilio)
                    ? <div className={"row mb-2"}>
                        {this.renderColumn("AULSS di domicilio", this.getNomeAulssByCodice(residenzaDomicilio.aulssDomicilio))}
                    </div>
                    : <></>}
                {!this.areElementsEqual(residenzaDomicilio.distrettoResidenza, residenzaDomicilio.distrettoDomicilio)
                    ? <div className={"row mb-2"}>
                        {this.renderColumn("Distretto di domicilio", this.getNomeDistrettoByCodiceAulssDistretto(residenzaDomicilio.aulssDomicilio, residenzaDomicilio.distrettoDomicilio))}
                    </div>
                    : <></>}
                {!this.areElementsEqual(residenzaDomicilio.aulssCura, residenzaDomicilio.aulssDomicilio)
                    ? <div className={"row mb-2"}>
                        {this.renderColumn("AULSS di domicilio di cura", this.getNomeAulssByCodice(residenzaDomicilio.aulssCura))}
                    </div>
                    : <></>}
                {!_.isEqual(residenzaDomicilio.distrettoCura, residenzaDomicilio.distrettoDomicilio)
                    ? <div className={"row mb-2"}>
                        {this.renderColumn("Distretto di domicilio di cura", this.getNomeDistrettoByCodiceAulssDistretto(residenzaDomicilio.aulssCura, residenzaDomicilio.distrettoCura))}
                    </div>
                    : <></>}
            </>;
        }

        return (
            <>
                <div className={"row mb-2"}>
                    {this.renderColumn("Cognome", this.state.dettaglio.cognome)}
                    {this.renderColumn("Nome", this.state.dettaglio.nome)}
                </div>
                <div className={"row mb-2"}>
                    {this.renderColumn("Data di nascita", Utils.transformTimeStampForFrontend(this.state.dettaglio.dataNascita))}
                </div>
                <div className={"row mb-2"}>
                    {this.renderColumn("Codice fiscale", this.state.dettaglio.codiceFiscale)}
                </div>
                <div className={"row mb-2"}>
                    {this.renderColumn("Tessera sanitaria", this.state.dettaglio.tesseraSanitaria)}
                </div>
                {residenzaDomicilioJSX}
            </>
        );
    }

    renderDettaglioRichiesta = () => {
        return (
            <>
                <div className={"row mb-2"}>
                    {this.renderColumn("Medico richiedente", this.state.dettaglio.medicoRichiedente)}
                </div>
                <div className={"row mb-2"}>
                    {this.renderColumn("Data richiesta", Utils.transformTimeStampForFrontend(this.state.dettaglio.dataRichiesta))}
                </div>
                <div className={"row mb-2"}>
                    {this.state.dettaglio.dataAnnullamentoRichiesta
                        ? <>
                            {this.renderColumn("Data annullamento richiesta", Utils.transformTimeStampForFrontend(this.state.dettaglio.dataAnnullamentoRichiesta))}
                            {this.renderColumn("Motivazione annullamento richiesta", this.state.dettaglio.motivazioneAnnullamentoRichiesta)}
                        </>
                        : <></>}
                </div>
            </>
        );
    }

    renderDettaglioValutazione = (labelColumn) => {
        return AuthUtils.hasUtenteProfiloValutatore()
            ? <div className={"row mb-2"}>
                {this.renderColumn(labelColumn, Utils.transformTimeStampForFrontend(this.state.dettaglio.data))}
            </div>
            : <></>;
    }

    renderDettaglioProfessionisti = () => {
        return this.state.dettaglio.length > 0
            ? this.state.dettaglio.map((professionista, i) => (
                <div key={i} className={"row mb-2"}>
                    {this.renderColumn("Nome", professionista.nome)}
                    {this.renderColumn("Cognome", professionista.cognome)}
                    {this.renderColumn("Ruolo", professionista.ruolo)}
                </div>
            ))
            : <></>;
    }

    renderDettaglioFormalizzazione = () => {
        return <div className={"row mb-2"}>
            {this.renderColumn("Data formalizzazione", Utils.transformTimeStampForFrontend(this.state.dettaglio.data))}
        </div>;
    }

    renderDettaglioCureDomiciliari = () => {
        return this.state.dettaglio != null
            ? <>
                <div className={"row mb-2"}>
                    {this.renderColumn("Data inizio Cure Domiciliari", Utils.transformTimeStampForFrontend(this.state.dettaglio.dataInizioCureDomiciliari))}
                    {this.renderColumn("Data conclusione Cure Domiciliari", Utils.transformTimeStampForFrontend(this.state.dettaglio.dataFineCureDomiciliari))}
                    {this.renderColumn("Motivazione conclusione Cure Domiciliari", this.state.dettaglio.motivazioneConclusioneCureDomiciliari)}
                </div>
            </>
            : <></>;
    }

    renderBody = () => {
        let contenuto;

        switch (this.props.idAccordion) {
            case NOME_OGGETTO_DETTAGLIO_ASSISTITO:
                contenuto = this.renderDettaglioAssistito();
                break;
            case NOME_OGGETTO_DETTAGLIO_RICHIESTA:
                contenuto = this.renderDettaglioRichiesta();
                break;
            case NOME_OGGETTO_DETTAGLIO_VALUTAZIONE_PROFESSIONALE:
                contenuto = this.renderDettaglioValutazione("Valutazione professionale");
                break;
            case NOME_OGGETTO_DETTAGLIO_PROFESSIONISTI:
                contenuto = this.renderDettaglioProfessionisti();
                break;
            case NOME_OGGETTO_DETTAGLIO_VALUTAZIONE_MULTIDIMENSIONALE:
                contenuto = this.renderDettaglioValutazione("Data Valutazione multidimensionale");
                break;
            case NOME_OGGETTO_DETTAGLIO_FORMALIZZAZIONE:
                contenuto = this.renderDettaglioFormalizzazione();
                break;
            case NOME_OGGETTO_DETTAGLIO_CURE_DOMICILIARI:
                contenuto = this.renderDettaglioCureDomiciliari();
                break;
            default:
                contenuto = <Fragment/>;
                break;
        }

        return <>{contenuto}</>
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={this.props.idAccordion}
                title={this.props.titleAccordion}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.accordionField}>
                {this.renderBody()}
            </AccordionAvvioRichiesta>
        );
    }
}

SintesiRichiesta.propTypes = propTypes;
