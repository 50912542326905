import React from "react";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionHelper from "../../../service/AccordionHelper";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import VersionedAccordion from "../../VersionedAccordion";
import ValutazioneProfessionistaSub from "./valutazioneProfessionistaSub/ValutazioneProfessionistaSub";

const propTypes = {
    dati: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiValutazioneProfessionista: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func
}

const defaultProps = {
    salvaBozza: () => undefined,
    onClickAccordion: () => undefined,
    onChangeDatiValutazioneProfessionista: () => undefined,
}

export default class ValutazioneProfessionista extends AccordionFirstLevel {
    state = {
        accordion: {
            openSubAccordionValutazioneProfessionista: true,
        },
        campiObbligatoriMancanti:0
    };

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;
        if (accordion[field]) {
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeRequiredFieldsNumber = (value) => {
        this.setState({campiObbligatoriMancanti: value}, () => {
            this.props.onChangeObbligatorieta("valutazioneProfessionista", this.state.campiObbligatoriMancanti);
        });
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"valutazioneProfessionista"}
                title={"Valutazione Professionista"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                numberOfRequiredFieldsMissing={this.state.campiObbligatoriMancanti}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}>
                <VersionedAccordion accordion={ValutazioneProfessionistaSub}
                                    dati={this.props.dati}
                                    onChangeDatiValutazioneProfessionista={this.props.onChangeDatiValutazioneProfessionista}
                                    openAccordion={this.state.accordion.openSubAccordionValutazioneProfessionista}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openSubAccordionValutazioneProfessionista"}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    pageState={this.props.pageState}/>
            </AccordionAvvioRichiesta>
        );
    }
}

ValutazioneProfessionista.propTypes = propTypes;
ValutazioneProfessionista.defaultProps = defaultProps;
