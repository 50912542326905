import React from "react";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import AccordionHelper from "../../../service/AccordionHelper";
import PropTypes from "prop-types";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import ProposteAttivitaSuggerimentiSottoAccordion
    from "./proposteAttivitaSuggerimentiSottoAccordion/ProposteAttivitaSuggerimentiSottoAccordion";
import VersionedAccordion from "../../VersionedAccordion";

const propTypes = {
    datiProposteAttivitaSuggerimenti: PropTypes.any,
    datiProposteAttivitaSuggerimentiNote: PropTypes.any,
    datiSituazioneSociale: PropTypes.any,
    datiDeterminantiDiSalute: PropTypes.any,
    datiAnamnesiFisiologica: PropTypes.any,
    datiInformazioniBase: PropTypes.any,
    datiInformazioniBaseFaseRichiesta: PropTypes.any,
    datiInformazioniBaseFaseValutazione: PropTypes.any,
    datiBenessereAbitativo: PropTypes.any,
    datiEsameObiettivo: PropTypes.any,
    datiAnamnesiPatologicaRemota: PropTypes.any,
    datiAnamnesiFamiliare: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiProposteAttivitaSuggerimenti: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta: PropTypes.any,
    datiproposteAttivitaSuggerimentiAssistenteSociale: PropTypes.node,
    addInformazioniAggiuntive: PropTypes.func,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class ProposteAttivitaSuggerimenti extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: 0,
        accordion: {
            openSubAccordionProposteAttivitaSuggerimenti: true
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        let richiesta = _.cloneDeep(this.props.richiesta);
        richiesta.proposteAttivitaSuggerimenti = this.props.datiProposteAttivitaSuggerimenti.filter(el => !Utils.isObjectNull(el));
        this.props.salvaBozza(false, false, richiesta);
        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            })
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeRequiredFieldsNumber = (value) => {
        this.setState({campiObbligatoriMancanti: value}, () => {
            this.props.onChangeObbligatorieta("proposteAttivitaSuggerimenti", this.state.campiObbligatoriMancanti);
        });
    }

    renderBody = () => {
        return (
            <>
                <VersionedAccordion accordion={ProposteAttivitaSuggerimentiSottoAccordion}
                                    datiProposteAttivitaSuggerimenti={this.props.datiProposteAttivitaSuggerimenti}
                                    datiProposteAttivitaSuggerimentiNote={this.props.datiProposteAttivitaSuggerimentiNote}
                                    datiproposteAttivitaSuggerimentiAssistenteSociale={this.props.datiproposteAttivitaSuggerimentiAssistenteSociale}
                                    datiSituazioneSociale={this.props.datiSituazioneSociale}
                                    datiDeterminantiDiSalute={this.props.datiDeterminantiDiSalute}
                                    datiAnamnesiFisiologica={this.props.datiAnamnesiFisiologica}
                                    datiInformazioniBase={this.props.datiInformazioniBase}
                                    datiInformazioniBaseFaseRichiesta={this.props.datiInformazioniBaseFaseRichiesta}
                                    datiInformazioniBaseFaseValutazione={this.props.datiInformazioniBaseFaseValutazione}
                                    datiBenessereAbitativo={this.props.datiBenessereAbitativo}
                                    datiEsameObiettivo={this.props.datiEsameObiettivo}
                                    datiAnamnesiPatologicaRemota={this.props.datiAnamnesiPatologicaRemota}
                                    datiAnamnesiFamiliare={this.props.datiAnamnesiFamiliare}
                                    richiesta={this.props.richiesta}
                                    onChangeDatiProposteAttivitaSuggerimenti={this.props.onChangeDatiProposteAttivitaSuggerimenti}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    openAccordion={this.state.accordion.openSubAccordionProposteAttivitaSuggerimenti}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openSubAccordionProposteAttivitaSuggerimenti"}
                                    pageState={this.props.pageState}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"proposteAttivitaSuggerimenti"}
                title={"Proposte di attività e suggerimenti"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                numberOfRequiredFieldsMissing={this.state.campiObbligatoriMancanti}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionAvvioRichiesta>
        );
    }
}

ProposteAttivitaSuggerimenti.propTypes = propTypes;
