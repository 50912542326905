import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionHelper from "../../../service/AccordionHelper";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import ValutazioneSistemaRespiratorio from "./valutazioneSistemaRespiratorio/ValutazioneSistemaRespiratorio";
import Utils from "../../../utils/Utils";

const propTypes = {
    esameObiettivo: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeEsameObiettivo: PropTypes.func,
    openAccordion: PropTypes.any,
    openAccordionCapo: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

export default class FunzioneRespiratoria extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: {
            sottosezioneValutazioneSistemaRespiratorio: 0,
        },
        accordion: {
            openAccordionTorace: true
        }
    }

    campiObbligatori = {...this.state.campiObbligatoriMancanti};

    handleChangeRequiredFieldsNumber = (field, value) => {
        this.campiObbligatori[field] = value;
        this.setState({campiObbligatoriMancanti: _.cloneDeep(this.campiObbligatori)}, () => {
            this.props.onChangeObbligatorieta("funzioneRespiratoria", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta.esameObiettivo = this.props.esameObiettivo;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    renderBodySezione = () => {
        return (
            <>
                <ValutazioneSistemaRespiratorio
                    torace={this.props.esameObiettivo.torace}
                    respiro={this.props.anamnesiFisiologica.datiRespiro}
                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                    onChangeDatiRespiro={this.props.onChangeDatiAnamnesiFisiologica}
                    richiesta={this.props.richiesta}
                    openAccordion={this.state.accordion.openAccordionTorace}
                    onClickAccordion={this.onClickAccordion}
                    pageState={this.props.pageState}
                    salvaBozza={this.props.salvaBozza}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                />
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"funzioneRespiratoriaInfermiere"}
                title={"Funzione respiratoria"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBodySezione()}</AccordionAvvioRichiesta>
        );
    }
}

FunzioneRespiratoria.propTypes = propTypes;
