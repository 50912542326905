import React, {Fragment} from 'react';
import "./App.css";
import {globals, Interceptor, SessioneUtente, Spinner, ToasterSingleton} from "web-client-archetype";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import {createBrowserHistory} from 'history'
import authConfig from "./config/authConfig";
import routepath from "../src/utils/route/route-path";
import auth from "./utils/AthnAuth";
import {Subject} from "rxjs";
import Utils, {closeModaleStyle} from "./utils/Utils";
import Header from './components/common/header/Header';
import AuthUtils from "./utils/AuthUtils";
import RouteComponent from "./utils/route/RouteComponent";
import enumTipoOpzioneNavbar from "./enum/enumTipoOpzioneNavbar.json";
import ToponomasticaService from "./service/ToponomasticaService";
import AccordionHelper from "./service/AccordionHelper";
import {Context} from './utils/Context';
import WebSocketHelper from "./websocket/WebSocketHelper";
import NotificaService from "./service/NotificaService";
import _ from "lodash";
import messageIt from "./utils/message/message-it.json";
import ButtonHelper from "./service/ButtonHelper";
import ToponomasticaUtils from "./utils/ToponomasticaUtils";
import RichiestaADIService from "./service/RichiestaADIService";
import GestionePasswordADIService from "./service/GestionePasswordADIService";
import ModalGestionePassword from "./components/home/gestionePassword/ModalGestionePassword";
import LockPassword from "./components/home/gestionePassword/LockPassword"
import CreaPasswordModal from './components/home/gestionePassword/CreaPasswordModal';
import theme from './hook/theme';
import {ThemeProvider} from 'styled-components';
import TornaSuBtn from './components/tornaSuBtn/TornaSuBtn';
import FiltriUtils from './utils/FiltriUtils';
import HomeUtils from './components/home/HomeUtils';
import SearchBar from "./components/searchBar/SearchBar";
import NavBar from './components/common/navbar/NavBar';
import RenderFooter from './components/renderFooter/renderFooter';
import * as BsIcon from "react-bootstrap-icons";
import MenuNotification from './components/common/navbar/menu/MenuNotification';
import MenuUtente from './components/common/navbar/menu/MenuUtente';
import AnagrafeService from "./service/AnagrafeService";
import {map} from "rxjs/operators";


export const browserHistory = createBrowserHistory();

auth.configurazione({
    auth: authConfig['attivazione-auth'],
    urlAthn: authConfig['url-redirect-auth'],
    applicationId: "ADI",
    samlTokenErrorFunction: (code, description) => {
        ToasterSingleton.instance().aggiuntaToaster({
            type: "confirm", //in caso di problemi nel recuperare il tipo di toaster, lo mostro come errore
            text: "Si è verificato un problema nell'accedere ai servizi infrastrutturali (come ad esempio anagrafe e fascicolo sanitario)." +
                " Contattare l'Helpdesk per sistemare la posizione fornendo il codice errore: " + code + " - " + description,
            timestamp: Date.now(),
            keepOpen: true
        });
    },
    samlTokenResources: [
        `GET#${globals["app-url-ms-anagrafica"]}anagrafiche$`,
        `PUT#${globals["app-url-ms-anagrafica"]}persone/assistiti/[^/]*$`,
        `POST#${globals["app-url-ms-anagrafica"]}persone/assistiti/[^/]*/medici/generali$`,
        `POST#${globals["app-url-ms-anagrafica"]}persone/assistiti/[^/]*/medici/richiedenti$`,
        `PUT#${globals["app-url-ms-anagrafica"]}persone/assistiti/[^/]*/medici/richiedenti$`
    ]
});

const spinnerSubject = new Subject();

export const mostraSpinner = () => {
    spinnerSubject.next(true);
}

export const nascondiSpinner = () => {
    spinnerSubject.next(false);
}

const defaultNotifiche = {
    notificaDto: [],
    numeroNotificheNonLette: 0
}

class App extends React.Component {

    state = {
        toastersActive: false,
        toastersNumber: 0,
        toasters: [],
        allToasterClose: true,
        loggedIn: false,
        showNavbar: false,
        navbarPath: '',
        clickHome: false,
        notifiche: _.cloneDeep(defaultNotifiche),
        apriLockScreenModal: false,
        apriCreaPasswordModal: false,
        changeUrl: "",
        showSearchBar: false,
        showSearchIcon: false,
        showAnagrafica: false,
        idPersona:null
    };

    setClickHome = (flag) => {
        this.setState({ clickHome: flag })
    }

    toasterSingleton = ToasterSingleton.instance();

    contatoreSpinner = 0;

    constructor(props) {
        super(props);
        globals["app-application-name"] = "Cure Domiciliari";
        globals["app-id-applicazione"] = "adi";

        this.toasterSingleton.sottoscrizione(this);
        new Interceptor(Utils.toasterFunction);
    }

    subscribeCaricamentoInCorso = () => {
        spinnerSubject.subscribe(isCaricamentoInCorso => {
            // Il contatore è inserito fuori dallo stato per forzare una modifica sincrona dei valori (invece di usare un setState asincrono con rischio di perdita dati)
            // this.contatoreSpinner = isCaricamentoInCorso ? this.contatoreSpinner + 1 : this.contatoreSpinner - 1;
            this.contatoreSpinner = isCaricamentoInCorso ? this.contatoreSpinner + 1 : this.contatoreSpinner === 0 ? this.contatoreSpinner : this.contatoreSpinner - 1;
            this.forceUpdate();
        });
    } 


    async componentDidMount() {

        // eslint-disable-next-line no-undef
        require("bootstrap-italia/dist/js/bootstrap-italia.min");
        // eslint-disable-next-line no-undef
        require("bootstrap-italia/dist/css/bootstrap-italia.min.css");
        // eslint-disable-next-line no-undef
        require("bootstrap-italia/dist/js/bootstrap-italia.bundle.min");

        try {
            let utente = await auth.recuperoSessione();
            console.log(utente)
            this.subscribeCaricamentoInCorso();
            SessioneUtente.getInstance({
                sessionId: "",
                CodiceFiscale: authConfig['utente-reale'] ? utente.codiceFiscale : "BRGPLA59L22M048Q",
                userName: utente.nome,
                userSurname: utente.cognome,
                userRoles: authConfig['utente-reale'] ? utente.gruppi : "ADI-RIC",
                CodiceStruttura: utente.codiceStruttura,
                listaSettings: authConfig["utente-reale"] ? utente.listaSettings : '[ADI-RIC@P_MEDICO_MEDICINA_GENERALE@DIS_1@@]'
            });

            const isUtenteAbilitato = AuthUtils.isUtenteAbilitato();

            if (isUtenteAbilitato) this.caricaDatiToponomastica();
            this.showAnagrafica();

            const current_url = new URL(window.location.href);
            const search_params = current_url.searchParams;
            let changeUrl = search_params.get('changeUrl');

            if (Utils.isStringEmptyOrNullOrUndefined(changeUrl)) {
                changeUrl = sessionStorage.getItem("changeUrl");
                sessionStorage.removeItem('changeUrl');
            }

            if (!Utils.isStringEmptyOrNullOrUndefined(changeUrl)) {
                const token = changeUrl.split('&token=')[1];
                const idRichiesta = changeUrl.split('&idRichiesta=')[1];
                if (!Utils.isStringEmptyOrNullOrUndefined(token)) {
                    changeUrl = changeUrl + '?token=' + token;
                }
                if (!Utils.isStringEmptyOrNullOrUndefined(idRichiesta)) {
                    this.setChangeUrlByIdRichiesta(idRichiesta);
                }
                if (changeUrl.split('&token=')[0] === routepath.recupera_password) {
                    browserHistory.push({
                        pathname: routepath.recupera_password,
                        search: '?token=' + token
                    });
                } else if (changeUrl.split('&token=')[0] === routepath.ripristino_password_scaduta) {
                    browserHistory.push({
                        pathname: routepath.ripristino_password_scaduta,
                        search: '?token=' + token
                    });
                } else {
                    browserHistory.push(changeUrl);
                }
            } else {
                browserHistory.push(AuthUtils.hasUtenteRuoloDiCategoriaAmministrativa()
                    ? routepath.pannello_configurazione_ricerca
                    : routepath.home);
            }

            if (isUtenteAbilitato) {
                let res = await GestionePasswordADIService.verificaEsistenzaPassword().toPromise();
                console.log(res);
                if (!res.risultato) {
                    localStorage.setItem("a9d4eb2a413a4bd9855824f87265377c", SessioneUtente.getInstance().idUtente);
                    const crea_password = localStorage.getItem("a9d4eb2a413a4bd9855824f87265377c");
                    if (crea_password === SessioneUtente.getInstance().idUtente) {
                        this.setState({apriCreaPasswordModal: true});
                    } else if (crea_password !== SessioneUtente.getInstance().idUtente) {
                        localStorage.removeItem("a9d4eb2a413a4bd9855824f87265377c");
                        this.setState({apriCreaPasswordModal: false});
                    }
                }
                const lockScreen = localStorage.getItem("504a8b2b5541360c99894b3a730b814a");
                if (lockScreen === SessioneUtente.getInstance().idUtente
                    && (!changeUrl || (changeUrl?.indexOf('recupera-password') === -1 && changeUrl?.indexOf('ripristino-password-scaduta') === -1))) {
                    this.setState({apriLockScreenModal: true});
                } else if (lockScreen !== SessioneUtente.getInstance().idUtente) {
                    localStorage.removeItem("504a8b2b5541360c99894b3a730b814a");
                    this.setState({apriLockScreenModal: false});
                }
            }
            
            this.setState({loggedIn: true});

            WebSocketHelper.connect();

            if (!AuthUtils.hasUtenteRuoloAmministratoreSistema() && isUtenteAbilitato) this.caricaNotifiche();

            WebSocketHelper.nuovaNotificaSubject.subscribe((notifica) => {
                if (notifica?.aulss === SessioneUtente.getInstance().codiceStruttura) {
                    this.setNumeroNotificheNonLette(notifica.numeroNotificheDaLeggere);
                }
            });
        } catch (error) {
            console.log("Not signed in");
            console.error(`Errore: ${error}`);
        }

        // gestione dell'evento di chiusura del browser o del tab del browser
        // alla chiusura viene richiamato il metodo per disconnettere la WebsSocket
        window.addEventListener('unload', (event) => {
            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = '';

            if (auth._auth) {
                WebSocketHelper.disconnect(`${auth._urlAthnLogout}`);
            } else {
                WebSocketHelper.disconnect("logout-callback");
            }
        });

        AccordionHelper.showSearchIconSubject.subscribe(showSearchIcon => {
            if (this.state.showSearchIcon !== showSearchIcon) this.setState({showSearchIcon});
        });
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (AuthUtils.isUtenteAbilitato()) {

            if (!ToponomasticaUtils.getComuni().length) ToponomasticaUtils.instance("comuni");
            if (!ToponomasticaUtils.getProvince().length) ToponomasticaUtils.instance("province");
            if (!ToponomasticaUtils.getNazioni().length) ToponomasticaUtils.instance("nazioni");
            if (!ToponomasticaUtils.getSigleProvince().length) ToponomasticaUtils.instance("sigleProvince");

            if (prevState.clickHome !== this.state.clickHome && this.state.clickHome && !AuthUtils.hasUtenteRuoloAmministratoreSistema()) {
                this.caricaNotifiche();
            }

            if (prevState.changeUrl !== this.state.changeUrl) {
                browserHistory.push(this.state.changeUrl)
                this.setState({loggedIn: true})
            }
        }
    }

    setChangeUrlByIdRichiesta = (idRichiesta) => {
        let params = {};
        let filtri = {
            filtriRichiesta: {
                id: idRichiesta
            },
            filtriAssistito: {}
        }

        FiltriUtils.setDefaultFiltriRichiesta("homepage", params)

        RichiestaADIService.getRichiesteHome(null, null, filtri, null, null, params).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                    })
                    nascondiSpinner();
                    
                    this.setState({changeUrl: {pathname: routepath.home}, loggedIn: false})
                }
            }
            else {
                nascondiSpinner();
                if (response?.data?.elementi?.length) {
                    let richiesta = HomeUtils.mapGetRichiesteHome(response?.data?.elementi);

                    let changeUrl = {
                        pathname: richiesta[0]?.primiLink[0]?.linkProps?.pathname,
                        state: {
                            uuidRichiesta: richiesta[0].uuidRichiesta,
                            tipologia: richiesta[0].tipologia,
                            stato: richiesta[0].stato,
                            pathname: richiesta[0]?.primiLink[0]?.linkProps?.pathname,
                            valutazionePrecedente: richiesta[0].valutazionePrecedente
                        }
                    }

                    this.setState({changeUrl: changeUrl, loggedIn: false})
                }
                else {

                    Utils.toasterFunction({
                        status: response?.status,
                        type: "confirm",
                        text: "La richiesta non può essere visualizzata, è presente nello Storico Richieste."
                    })

                    this.setState({changeUrl: {pathname: routepath.home}, loggedIn: false})
                }
            }
        });
    }

    caricaDatiToponomastica = () => {
        if (!sessionStorage.getItem("comuni"))
            ToponomasticaService.getComuni(globals["lingua-default"]).subscribe(() => ToponomasticaUtils.instance("comuni"));
        if (!sessionStorage.getItem("province"))
            ToponomasticaService.getProvince(globals["lingua-default"]).subscribe(() => ToponomasticaUtils.instance("province"));
        if (!sessionStorage.getItem("cap"))
            ToponomasticaService.getCap(null).subscribe(() => ToponomasticaUtils.instance("cap"));
        if (!sessionStorage.getItem("nazioni"))
            ToponomasticaService.getNazioni(globals["lingua-default"]).subscribe(() => ToponomasticaUtils.instance("nazioni"));
        if (!sessionStorage.getItem("sigleProvince"))
            ToponomasticaService.getSigleProvince(globals["lingua-default"]).subscribe(() => ToponomasticaUtils.instance("sigleProvince"));
    }

    caricaNotifiche = () => {
        let msgErrore = "ERRORE";
        let params = { numeroElementi: 4 }
        NotificaService.getNotifiche(params).subscribe((response) => {
            if (response?.msg === msgErrore) {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "critical",
                    text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                })
            }
            else {
                this.setState({ notifiche: response });
            }
        })
    }

    getToaster = () => {
        return this.toasterSingleton.renderToaster();
    }

    onClickLogout = () => {
        auth.logout();
    }

    recuperaPassword = () => {
        mostraSpinner();
        let msgErrore = "ERRORE";
        GestionePasswordADIService.recuperaPassword().subscribe((res) => {
            if (res?.msg === msgErrore) {
                this.apriToasterErrore(res);
                nascondiSpinner();
            } else {
                Utils.toasterFunction({
                    status: "200",
                    type: "confirm",
                    text: "E' stata mandata una mail al tuo indirizzo di posta elettronica per effettuare il recupero della password"
                });
                nascondiSpinner();
            }
        })
    }
    showAnagrafica=()=>{
    AnagrafeService.getUuid().subscribe(res=>{
        if(res.msg!=='ERRORE')
        {
            this.setState({showAnagrafica:res})
            AnagrafeService.getPersone({
                vista: 'totale',
                filtro: `id_persona~~${res}`
            }).pipe(map((data) => {
                if (!(data && data.length === 1)) {
                    this.setState({showAnagrafica:false})
                }
                else {
                    this.setState({showAnagrafica:true,idPersona:res})
                }
                return data[0];
            })).subscribe()
        }
    })
    }

    bloccoApplicativo = () => {
        localStorage.setItem("504a8b2b5541360c99894b3a730b814a", SessioneUtente.getInstance().idUtente);
        this.setState({apriLockScreenModal:true});
    }

    crea_password = (password, confPassword) => {
        mostraSpinner();
        let msgErrore = "ERRORE";
        let request = {
            password: password,
            confPassword: confPassword
        }
        GestionePasswordADIService.creaPassword(request).subscribe((res) => {
            if (res?.msg === msgErrore) {
                this.apriToasterErrore(res);
                nascondiSpinner();
            } else {
                if (res?.msg === msgErrore) {
                    nascondiSpinner();
                    this.apriToasterErrore(res);
                } else {
                    nascondiSpinner();
                    Utils.toasterFunction({
                        "status": 200,
                        "type": "successo",
                        "text": "Password creata con successo."
                    })
                    localStorage.removeItem("a9d4eb2a413a4bd9855824f87265377c");
                    this.setState({apriCreaPasswordModal: false});
                    closeModaleStyle();
                }
            }
        })
    }

    checkPassword = (password, confPassword) => {
        mostraSpinner();
        let msgErrore = "ERRORE";
        let request = {
            password: password,
            confPassword: confPassword
        }
        GestionePasswordADIService.checkPassword(request).subscribe((res) => {
            if (res?.msg === msgErrore) {
                this.apriToasterErrore(res);
                nascondiSpinner();
            } else {
                nascondiSpinner();
                if(res.risultato){
                    localStorage.removeItem("504a8b2b5541360c99894b3a730b814a");
                    this.setState({apriLockScreenModal: false});
                    closeModaleStyle();
                }else{
                    Utils.toasterFunction({
                        type: "critical",
                        text: "La password inserita non risulta corretta"
                    })
                }
            }
        })
    }

    apriToasterErrore = (response) => {
        Utils.toasterFunction({
            status: response?.data?.codice ? response?.data?.codice : response?.status,
            type: "critical",
            text: response?.data?.errors?.pop().message || response?.data?.message
        })
    }

    renderHeader = () => {
        return (
            <>
                <Header/>
                {AuthUtils.isUtenteAbilitato() ? this.renderNavbar() : <Fragment/>}
            </>
        );
    }



    renderNavbar = () => {
        let opzioniNavbar = [];
        let state = _.cloneDeep(this.state);

        const routepathInWhichToShowSearchIcon = [
            routepath.compila_scheda_paziente, routepath.completa_valutazione_multidimensionale,
            routepath.accedi_valutazione_multidimensionale, routepath.visualizza_valutazione_multidimensionale,
            routepath.compila_proposte, routepath.visualizza_riepilogo, routepath.verifica_valutazione,
            routepath.compila_formalizzazione, routepath.anagrafica_medico_richiedente
        ];
        if (this.state.showSearchIcon && routepathInWhichToShowSearchIcon.some(r => window.location.href.includes(r))) {
            opzioniNavbar.push({
                id:"ricercaCampo",
                type: enumTipoOpzioneNavbar.actionIcon,
                iconSrc: 'it-search',
                label: <div><BsIcon.Search width={30} height={30}/></div>,
                className: "pb-1",
                onClickFunction: () => this.setShowSearchBar(true)
            });
        }

        if (!AuthUtils.hasUtenteRuoloDiCategoriaAmministrativa()) {
            opzioniNavbar.push(
                {
                    type: enumTipoOpzioneNavbar.button,
                    label: "RICERCA PAZIENTE",
                    iconSrc: "it-search",
                    url: [routepath.ricercaPaziente],
                    id: "ricercaAssistitoBtn"
                },
                {
                    id: "home",
                    type: enumTipoOpzioneNavbar.link,
                    url: [routepath.home],
                    label: <div><BsIcon.HouseDoorFill width={30} height={30}/></div>
                },
                {
                    id: "storicoPaziente",
                    type: enumTipoOpzioneNavbar.link,
                    url: [routepath.storico_richieste],
                    label: "STORICO RICHIESTE",
                    infoPopupOver: "Richieste approvate/rifiutate o annullate"
                },
                {
                    id: "notifiche",
                    type: enumTipoOpzioneNavbar.actionIcon,
                    iconSrc: 'it-horn',
                    label: <MenuNotification notifiche={this.state.notifiche} />,
                    className: "ml-xl-2",
                    onClickFunction: () => {
                        if (state.notifiche?.notificaDto?.length === 0) {
                            Utils.toasterFunction(messageIt.alertNotificheNonPresenti)
                        }
                    },
                    link: state.notifiche?.notificaDto?.length > 0 ? routepath.centro_notifiche : routepath.home,
                    nonLette: state.notifiche?.numeroNotificheNonLette,
                    url: [routepath.centro_notifiche],
                    infoPopupOver: "Notifiche"
                }
            );
        }


        opzioniNavbar.push(
            {
                id: "faq",
                type: enumTipoOpzioneNavbar.link,
                url: [routepath.faq],
                label: <div><BsIcon.QuestionCircle width={30} height={30}/></div>,
                infoPopupOver: "FAQ"
            },
            {
                id: "menuUtente",
                type: enumTipoOpzioneNavbar.link,
                url: [routepath.faq],
                label: <MenuUtente showAnagrafica={this.state.showAnagrafica} idPersona={this.state.idPersona} bloccoApplicativo={()  => this.bloccoApplicativo()} recuperaPassword={() => this.recuperaPassword()}/>,
                onClickFunction: () => {},
                infoPopupOver: "Azioni profilo"
            },
            {
                id: "logout",
                type: enumTipoOpzioneNavbar.link,
                onClickFunction: () => {
                    this.onClickLogout()
                },
                label: <div><BsIcon.BoxArrowRight width={30} height={30}/></div>,
                infoPopupOver: "Logout"
            }
        );


        if (AuthUtils.hasUtenteRuoloDiCategoriaAmministrativa() || AuthUtils.hasUtenteRuoloReferenteAulss()) {
            opzioniNavbar.push({
                id:"panelloConfigurazionale",
                type: enumTipoOpzioneNavbar.link,
                url: [routepath.pannello_configurazione_ricerca, routepath.inserimento_utente],
                label: "PANNELLO CONFIGURAZIONALE"
            });
        }

        if (AuthUtils.hasUtenteRuoloDiCategoriaAmministrativa() || AuthUtils.hasUtenteRuoloReferenteAulss()) {
            opzioniNavbar.push({
                id:"gestioneDelega",
                type: enumTipoOpzioneNavbar.link,
                url: [routepath.deleghe],
                label: "GESTIONE DELEGHE",
                propAggiuntive: {name: "Gestione Deleghe"}
            });
        }


        return (
            <NavBar
                url={this.state.navbarPath}
                navbarOptions={opzioniNavbar}
            />
        );
    }

    setShowSearchBar = (flag) => {
        this.setState({showSearchBar: flag});
    }

    setNumeroNotificheNonLette = (numeroNotificheNonLette) => {
        let notifiche = _.cloneDeep(this.state.notifiche);
        notifiche.numeroNotificheNonLette = numeroNotificheNonLette;
        this.setState({ notifiche: notifiche });
    }

    diminuisciNumeroNotificheNonLette = (numeroNotificheLette) => {
        let numeroNotificheNonLette = _.cloneDeep(this.state.notifiche.numeroNotificheNonLette);

        if (numeroNotificheNonLette - numeroNotificheLette > 0) {
            this.setNumeroNotificheNonLette(numeroNotificheNonLette - numeroNotificheLette);
        }
        else {
            this.setNumeroNotificheNonLette(0);
        }
    }

    render() {

        let context = {
            clickHome: this.state.clickHome,
            setClickHome: this.setClickHome,
            numeroNotificheNonLette: this.state.notifiche.numeroNotificheNonLette,
            setNumeroNotificheNonLette: this.setNumeroNotificheNonLette,
            diminuisciNumeroNotificheNonLette: this.diminuisciNumeroNotificheNonLette
        }

        if (this.state.loggedIn) {
            return (
                <div className="App">
                    <div className={"shadow bg-white rounded generalLayout d-flex flex-column min-vh-100"}>
                        <div id={"appBody"}>
                            {this.contatoreSpinner > 0 && <Spinner/>}
                            <Router history={browserHistory}>
                                <ThemeProvider theme={theme}>
                                    <Context.Provider value={context}>
                                        {this.renderHeader()}
                                        {this.getToaster()}
                                        {this.state.showSearchBar &&
                                            <SearchBar onCloseSearchBar={() => this.setShowSearchBar(false)}/>}
                                        <Switch>
                                            <RouteComponent changeUrl={this.changeUrl}/>
                                        </Switch>
                                        <TornaSuBtn/>
                                    </Context.Provider>
                                </ThemeProvider>
                            </Router>
                        </div>
                        <RenderFooter/>
                    </div>
                    {this.state.apriLockScreenModal ?
                        <ModalGestionePassword
                            body={<LockPassword
                                checkPassword={this.checkPassword}
                                recuperaPassword={this.recuperaPassword}
                            />}
                            renderFooter={false}
                            title={"Inserisci la password per sbloccare l'applicativo"}
                        /> : <></>}
                    {this.state.apriCreaPasswordModal ?
                        <ModalGestionePassword
                            body={<CreaPasswordModal
                                crea_password={this.crea_password}
                            />}
                            renderFooter={false}
                            title={"Crea una password per sbloccare l'applicativo"}
                        /> : <></>}
                </div>
            );
        }
        else {
            return (<Fragment />);
        }
    }

    // Aggiorna lo stato in seguito ad un cambio url, per aggiornare la renderizzazione della Navbar
    changeUrl = (showNavbar = true) => {
        let navbarPath = window.location.href;
        if (this.state.navbarPath !== navbarPath && this.state.showNavbar !== showNavbar)
            this.setState({
                navbarPath: navbarPath,
                showNavbar: showNavbar,
                showSearchBar: false
            }, () => this.resetHelperVariables());
        else if (this.state.showNavbar !== showNavbar)
            this.setState({showNav: showNavbar, showSearchBar: false});
        else if (this.state.navbarPath !== navbarPath)
            this.setState({
                navbarPath: navbarPath,
                showSearchBar: false
            }, () => this.resetHelperVariables());
    }

    resetHelperVariables = () => {
        if (this.state.navbarPath.includes(routepath.home)) {
            RichiestaADIService.richiestaRicercata = null;
            AccordionHelper.setLastSurveyCompiled(null);
            AccordionHelper.setIdAccordionToAddInformation(null);
            AccordionHelper.setCloneIdAccordionToAddInformation(null);
            AccordionHelper.setIdSelectedAccordions(null);
            AccordionHelper.setLastPageUrlBeforeMenuItemClick(null);
            AccordionHelper.listaCampiObbligatori = [];
            AccordionHelper.setMappaCountOptionalAccordionFields({});
            AccordionHelper.setIdAccordionsAfterSurveyPage(null);
            AccordionHelper.setPropsRichiestaQuestionario(null);
            AccordionHelper.isPsanSurveyRequired = null;
        }

        AccordionHelper.resetMappaVariazionePathDecodedValue();

        RichiestaADIService.salvaVariazioniCurrentObject = null;
        RichiestaADIService.salvaVariazioniPreviousObject = null;
        RichiestaADIService.salvaVariazioniErrorCode = null;

        RichiestaADIService.salvaFormalizzazioneCurrentObject = null;
        RichiestaADIService.salvaFormalizzazionePreviousObject = null;
        RichiestaADIService.salvaFormalizzazioneErrorCode = null;

        RichiestaADIService.chiudiPreDialogoCurrentObject = null;
        RichiestaADIService.chiudiPreDialogoPreviousObject = null;
        RichiestaADIService.chiudiPreDialogoErrorCode = null;

        AccordionHelper.setPreviousSearchedField(null);
        AccordionHelper.mappaFieldLabelFieldId = {};

        if (!this.state.navbarPath.includes(routepath.inserimento_richiesta)
            && !this.state.navbarPath.includes(routepath.valutazione_multidimensionale)
            && !this.state.navbarPath.includes(routepath.compila_chiusura_predialogo)) {
            AccordionHelper.setLastOpenedAccordion(null);
            AccordionHelper.setLastOpenedSubAccordion(null);
            ButtonHelper.setSalvaButtonFlag(false);
            WebSocketHelper.utentiOnline = [];
            WebSocketHelper.sendDisconnect(SessioneUtente.getInstance().idUtente, WebSocketHelper.idRichiesta, false);
            AccordionHelper.setLastSurveyCompiled(null);
            AccordionHelper.setIdAccordionsAfterSurveyPage(null);
        }

        if (!this.state.navbarPath.includes(routepath.inserimento_utente) && !this.state.navbarPath.includes(routepath.modifica_utente)) {
            document.getElementsByTagName('body')[0].style.overflow = 'auto';
        }
    }
}

export default App;
