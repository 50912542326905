import React from "react";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import PropTypes from "prop-types";
import * as _ from "lodash";
import FornitureSanitarieAttive from "./fornitureSanitarieAttive/FornitureSanitarieAttive";
import TrattamentiDietetici from "./trattamentiDietetici/TrattamentiDietetici";
import TerapiaFarmacologicaInAtto from "./terapiaFarmacologicaInAtto/TerapiaFarmacologicaInAtto";
import TrattamentiLesioniTrofiche from "./trattamentiLesioniTrofiche/TrattamentiLesioniTrofiche";
import AccordionHelper from "../../service/AccordionHelper";
import Utils from "../../utils/Utils";
import AderenzaAllaTerapia from "./aderenzaAllaTerapia/AderenzaAllaTerapia";
import IntegratoriEFitoterapici from "./integratoriEFitoterapici/IntegratoriEFitoterapici";
import AltriTrattamenti from "./altriTrattamenti/AltriTrattamenti";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import DispositiviMediciPresenti from "./dispositiviMediciPresenti/DispositiviMediciPresenti";
import VersionedAccordion from "../VersionedAccordion";
import enumRichiesta from "../../enum/enumRichiesta.json";
import AuthUtils from "../../utils/AuthUtils";

const propTypes = {
    datiInformazioniDiBase: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiInformazioniDiBase: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class InformazioniDiBase extends AccordionFirstLevel {

    state = {
        campiObbligatoriMancanti: {
            sottosezioneTerapiaFarmacologicaInAtto: 0,
            sottosezioneAderenzaAllaTerapia: 0,
            sottosezioneIntegratoriEFitoterapici: 0,
            sottosezioneAltriTrattamenti: 0,
            sottosezioneTrattamentiLesioniTrofiche: 0,
            sottosezioneDispositiviMediciPresenti: 0
        },
        accordion: {
            openAccordionTerapiaFarmacologicaInAtto: true,
            openAccordionTrattamentiDietetici: true,
            openAccordionTrattamentiLesioniTrofiche: true,
            openAccordionFornitureSanitarieAttive: true,
            openAccordionAderenzaAllaTerapia: true,
            openAccordionIntegratoriEFitoterapici: true,
            openAccordionAltriTrattamenti: true,
            openAccordionDispositiviMediciPresenti: true
        }
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        let campiObbligatoriMancanti = this.state.campiObbligatoriMancanti;
        campiObbligatoriMancanti[field] = value;
        this.setState({campiObbligatoriMancanti: campiObbligatoriMancanti}, () => {
            this.props.onChangeObbligatorieta("informazioniDiBase", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta.informazioniDiBase = this.props.datiInformazioniDiBase;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    renderBodyInfoBase = () => {
        return (
            <>
                <VersionedAccordion accordion={DispositiviMediciPresenti}
                                    datiDispositiviMediciPresenti={this.props.datiInformazioniDiBase.dispositiviMediciPresenti}
                                    onChangeDispositiviMediciPresenti={this.props.onChangeDatiInformazioniDiBase}
                                    openAccordion={this.state.accordion.openAccordionDispositiviMediciPresenti}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionDispositiviMediciPresenti"}
                                    pageState={this.props.pageState}
                                    mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                                    disableDoubleInput={Utils.isRuoloInfermiere()}
                />

                <VersionedAccordion accordion={TerapiaFarmacologicaInAtto}
                                    datiTerapiaFarmacologicaInAtto={this.props.datiInformazioniDiBase.terapiaFarmacologicaInAtto}
                                    onChangeTerapiaFarmacologicaInAtto={this.props.onChangeDatiInformazioniDiBase}
                                    openAccordion={this.state.accordion.openAccordionTerapiaFarmacologicaInAtto}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionTerapiaFarmacologicaInAtto"}
                                    mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion accordion={TrattamentiDietetici}
                                    datiTrattamentiDietetici={this.props.datiInformazioniDiBase.trattamentiDietetici}
                                    onChangeTrattamentiDietetici={this.props.onChangeDatiInformazioniDiBase}
                                    openAccordion={this.state.accordion.openAccordionTrattamentiDietetici}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionTrattamentiDietetici"}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                                    disableDoubleInput={Utils.isRuoloInfermiere()}/>

                {!Utils.isRuoloInfermiere() &&
                    <VersionedAccordion accordion={TrattamentiLesioniTrofiche}
                                        datiTrattamentiLesioniTrofiche={this.props.datiInformazioniDiBase.datiTrattamentiLesioniTrofiche}
                                        onChangeTrattamentiLesioniTrofiche={this.props.onChangeDatiInformazioniDiBase}
                                        openAccordion={this.state.accordion.openAccordionTrattamentiLesioniTrofiche}
                                        onClickAccordion={this.onClickAccordion}
                                        field={"openAccordionTrattamentiLesioniTrofiche"}
                                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                        pageState={this.props.pageState}
                                        accordionReadOnly={this.props.accordionReadOnly}
                                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>}

                {!Utils.isStateValutazione(this.props.pageState) &&
                    <VersionedAccordion accordion={FornitureSanitarieAttive}
                                        fornitureAttive={this.props.datiInformazioniDiBase.fornitureAttive}
                                        onChangeDatiFornitureSanitarie={this.props.onChangeDatiInformazioniDiBase}
                                        openAccordion={this.state.accordion.openAccordionFornitureSanitarieAttive}
                                        onClickAccordion={this.onClickAccordion}
                                        field={"openAccordionFornitureSanitarieAttive"}
                                        pageState={this.props.pageState}
                                        accordionReadOnly={this.props.accordionReadOnly}
                                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                                        disableDoubleInput={Utils.isRuoloInfermiere()}/>}

                {Utils.isStateValutazione(this.props.pageState) &&
                    <>
                        <VersionedAccordion accordion={AderenzaAllaTerapia}
                                            datiAderenzaAllaTerapia={this.props.datiInformazioniDiBase.datiAderenzaAllaTerapia}
                                            onChangeDatiAderenzaAllaTerapia={this.props.onChangeDatiInformazioniDiBase}
                                            openAccordion={this.state.accordion.openAccordionAderenzaAllaTerapia}
                                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                            onClickAccordion={this.onClickAccordion}
                                            field={"openAccordionAderenzaAllaTerapia"}
                                            pageState={this.props.pageState}
                                            onHide={() => this.hideOptionalAccordion('visualizzaAccordionAderenzaAllaTerapia')}
                                            richiesta={this.props.richiesta}
                                            salvaBozza={this.props.salvaBozza}
                                            accordionPadre={'INFOBASE'}
                                            accordionReadOnly={this.props.accordionReadOnly}
                                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                        />

                        <VersionedAccordion accordion={IntegratoriEFitoterapici}
                                            datiIntegratoriEFitoterapici={this.props.datiInformazioniDiBase.datiIntegratoriEFitoterapici}
                                            onChangeDatiFornitureSanitarie={this.props.onChangeDatiInformazioniDiBase}
                                            openAccordion={this.state.accordion.openAccordionIntegratoriEFitoterapici}
                                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                            onClickAccordion={this.onClickAccordion}
                                            field={"openAccordionIntegratoriEFitoterapici"}
                                            pageState={this.props.pageState}
                                            accordionReadOnly={this.props.accordionReadOnly}
                                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>


                        <VersionedAccordion accordion={FornitureSanitarieAttive}
                                            fornitureAttive={this.props.datiInformazioniDiBase.fornitureAttive}
                                            onChangeDatiFornitureSanitarie={this.props.onChangeDatiInformazioniDiBase}
                                            openAccordion={this.state.accordion.openAccordionFornitureSanitarieAttive}
                                            onClickAccordion={this.onClickAccordion}
                                            field={"openAccordionFornitureSanitarieAttive"}
                                            pageState={this.props.pageState}
                                            accordionReadOnly={this.props.accordionReadOnly}
                                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                                            disableDoubleInput={Utils.isRuoloInfermiere()}/>
                    </>}

                <VersionedAccordion accordion={AltriTrattamenti}
                    datiAltriTrattamenti={this.props.datiInformazioniDiBase.datiAltriTrattamenti}
                    onChangeDatiFornitureSanitarie={this.props.onChangeDatiInformazioniDiBase}
                    openAccordion={this.state.accordion.openAccordionAltriTrattamenti}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionAltriTrattamenti"}
                    pageState={this.props.pageState}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta} />
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"informazioniDiBase"}
                title={"Informazioni di base: Trattamenti in atto e Forniture sanitarie attive del periodo"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                field={this.props.field}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodyInfoBase()}</AccordionAvvioRichiesta>
        );
    }
}

InformazioniDiBase.propTypes = propTypes;
