import React from "react";
import * as _ from "lodash";
import settingData from "../../../utils/settingData/settingData.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import PropTypes from "prop-types";
import {FieldsRow, RoleBasedInput, RoleBasedSelect} from "../../roleBasedComponents/RoleBasedComponents";

const propTypes = {
    datiSettingProvenienza: PropTypes.any,
    onChangeDatiAssistito: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class SettingProvenienza extends AccordionSecondLevel {
    state = {
        datiSettingProvenienza: _.cloneDeep(this.props.datiSettingProvenienza)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiSettingProvenienza, this.props.datiSettingProvenienza))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let settingProvenienza = _.cloneDeep(this.props.datiSettingProvenienza);
        this.getValues(settingProvenienza, false);

        this.setState({datiSettingProvenienza: settingProvenienza});
    }

    getValues = (settingProvenienza, isForBackend) => {
        if (isForBackend) {
            settingProvenienza.tipologiaSetting = settingProvenienza.tipologiaSetting?.value !== undefined ? settingProvenienza.tipologiaSetting.value : null;
            settingProvenienza.domicilio = settingProvenienza.domicilio?.value !== undefined ? settingProvenienza.domicilio.value : null;
            settingProvenienza.cureDomiciliariAttive = settingProvenienza.cureDomiciliariAttive?.value !== undefined ? settingProvenienza.cureDomiciliariAttive.value : null;
            settingProvenienza.strutturaIntermedia = settingProvenienza.strutturaIntermedia?.value !== undefined ? settingProvenienza.strutturaIntermedia.value : null;
        } else {
            settingProvenienza.tipologiaSetting = settingData.tipoSetting.find(el => el.value === settingProvenienza.tipologiaSetting);
            settingProvenienza.domicilio = settingData.domicilio.find(el => el.value === settingProvenienza.domicilio);
            settingProvenienza.cureDomiciliariAttive = settingData.cureDomiciliariAttive.find(el => el.value === settingProvenienza.cureDomiciliariAttive);
            settingProvenienza.strutturaIntermedia = settingData.strutturaIntermedia.find(el => el.value === settingProvenienza.strutturaIntermedia);
        }
    }

    formattaOggettoForBackend = () => {
        let datiSettingProvenienza = _.cloneDeep(this.state.datiSettingProvenienza);
        this.getValues(datiSettingProvenienza, true);
        this.props.onChangeDatiAssistito("settingDiProvenienza", datiSettingProvenienza)
    }

    handleAggiornaForm = (field, elem) => {
        let datiSettingProvenienza = _.cloneDeep(this.state.datiSettingProvenienza);
        datiSettingProvenienza[field] = elem ? elem : null;

        if (field === "tipologiaSetting") {
            datiSettingProvenienza.domicilio = null;
            datiSettingProvenienza.cureDomiciliariAttive = null;
            datiSettingProvenienza.strutturaIntermedia = null;
            datiSettingProvenienza.ospedale = null;
            datiSettingProvenienza.altroSetting = null;
        }

        if (field === "domicilio") {
            datiSettingProvenienza.cureDomiciliariAttive = null;
        }

        this.setState({datiSettingProvenienza: datiSettingProvenienza}, () => this.formattaOggettoForBackend());
    }

    renderBodySezione = () => {
        let datiSettingProvenienza = _.cloneDeep(this.state.datiSettingProvenienza);

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'TIPO_SETT.SETTINGDIPROVENIENZA.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        id={"selectSetting"}
                        options={settingData.tipoSetting}
                        value={datiSettingProvenienza.tipologiaSetting}
                        onChange={(elem) => this.handleAggiornaForm("tipologiaSetting", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Setting non esistente"}
                        fieldLabel={"Tipologia setting"}
                        field={"tipologiaSetting"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    {datiSettingProvenienza.tipologiaSetting?.value === "01"
                        ? <RoleBasedSelect
                            fieldId={'DOM.SETTINGDIPROVENIENZA.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            id={"selectDomicilio"}
                            options={settingData.domicilio}
                            value={datiSettingProvenienza.domicilio}
                            onChange={(elem) => this.handleAggiornaForm("domicilio", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Domicilio non esistente"}
                            fieldLabel={"Domicilio"}
                            field={"domicilio"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        : null}
                    {datiSettingProvenienza.domicilio?.value === "01"
                        ? <RoleBasedSelect
                            fieldId={'CURE_DOM_ATT.SETTINGDIPROVENIENZA.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            id={"selectCureDomiciliariAttive"}
                            options={settingData.cureDomiciliariAttive}
                            value={datiSettingProvenienza.cureDomiciliariAttive}
                            onChange={(elem) => this.handleAggiornaForm("cureDomiciliariAttive", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Cure domiciliari attive non esistenti"}
                            fieldLabel={"Cure domiciliari attive"}
                            field={"cureDomiciliariAttive"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        : null}
                    {datiSettingProvenienza.tipologiaSetting?.value === "02"
                        ? <RoleBasedSelect
                            fieldId={'STRUT_INTERM.SETTINGDIPROVENIENZA.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            id={"selectStrutturaIntermedia"}
                            options={settingData.strutturaIntermedia}
                            value={datiSettingProvenienza.strutturaIntermedia}
                            onChange={(elem) => this.handleAggiornaForm("strutturaIntermedia", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Struttura intermedia non esistente"}
                            fieldLabel={"Struttura intermedia"}
                            field={"strutturaIntermedia"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        : null}
                    {datiSettingProvenienza.tipologiaSetting?.value === "03"
                        ? <RoleBasedInput
                            fieldId={'OSP.SETTINGDIPROVENIENZA.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            placeholder="Inserisci ospedale"
                            field="ospedale"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id="inputOspedale"
                            value={datiSettingProvenienza.ospedale ?? ""}
                            fieldLabel={"Ospedale"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        : null}
                    {datiSettingProvenienza.tipologiaSetting?.value === "04"
                        ? <RoleBasedInput
                            fieldId={'TIPO_SETT.SETTINGDIPROVENIENZA.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            placeholder="Inserisci altro setting"
                            field="altroSetting"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id="inputAltroSetting"
                            fieldLabel={"Altro"}
                            value={datiSettingProvenienza.altroSetting ?? ""}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        : null}
                </FieldsRow>
            </>
        );
    }


    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"settingDiProvenienza"}
                title={"Setting di provenienza"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }


}


SettingProvenienza.propTypes = propTypes;
