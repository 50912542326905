import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Utils from "../../../../utils/Utils";
import {
    aicList,
    FieldsRow,
    RoleBasedAICInput,
    RoleBasedFarmacoEquivalenteInput,
    RoleBasedInput,
    SectionList
} from "../../../roleBasedComponents/RoleBasedComponents";
import {v4 as uuid} from "uuid";

const propTypes = {
    lassativiMicroclismi: PropTypes.array,
    onChangeLassativiMicroclisma: PropTypes.func,
    lassativo: PropTypes.any,
    microclisma: PropTypes.any,
    label: PropTypes.any,
    pageState: PropTypes.string,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    field: PropTypes.string,
    parentJsonPath: PropTypes.string
}

export const defaultLassativiMicroclismi = {
    id: null,
    nomeCommerciale: null,
    codiceAIC: null,
    classeEquivalenza: null
}

export default class LassativiMicroclismi extends Component {

    state = {
        lassativiMicroclismi: this.props.lassativiMicroclismi ? _.cloneDeep(this.props.lassativiMicroclismi) : [],
        campiObbligatori: 0
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.lassativiMicroclismi, this.props.lassativiMicroclismi))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let props = this.props
        let lassativiMicroclismi = _.cloneDeep(props[props.label]);
        if (!Utils.isObjectNull(lassativiMicroclismi) && lassativiMicroclismi.lenbits > 0) {
            this.setState({lassativiMicroclismi: lassativiMicroclismi});
        }
    }

    handleAggiornaForm = (field, elem, index) => {
        let lassativiMicroclismi = _.cloneDeep(this.state.lassativiMicroclismi);
        lassativiMicroclismi[index][field] = elem;
        this.setState({lassativiMicroclismi: lassativiMicroclismi}, () => {
            let lassativiMicroclismiProps = _.cloneDeep(lassativiMicroclismi)
            this.props.onChangeLassativiMicroclisma(this.props.label, lassativiMicroclismiProps)
        });
    }

    addNewLassativiMicroclismi = () => {
        let lassativiMicroclismi = this.state.lassativiMicroclismi ? _.cloneDeep(this.state.lassativiMicroclismi) : [];
        let newLassativiMicroclismi = _.cloneDeep(defaultLassativiMicroclismi);
        newLassativiMicroclismi.id = uuid();
        lassativiMicroclismi.push(newLassativiMicroclismi);
        this.setState({lassativiMicroclismi: lassativiMicroclismi}, () => {
            let lassativiMicroclismiProps = _.cloneDeep(lassativiMicroclismi)
            this.props.onChangeLassativiMicroclisma(this.props.label, lassativiMicroclismiProps)
        });
    }

    removeLassativiMicroclismi = (index) => {
        let lassativiMicroclismi = _.cloneDeep(this.state.lassativiMicroclismi);
        lassativiMicroclismi.splice(index, 1);
        this.setState({lassativiMicroclismi: lassativiMicroclismi}, () => {
                let lassativiMicroclismiProps = _.cloneDeep(lassativiMicroclismi)
                this.props.onChangeLassativiMicroclisma(this.props.label, lassativiMicroclismiProps)
            }
        )
    }

    renderNewLassativiMicroclismi = (lassativiMicroclismi, index) => {
        const optionsAIC = _.cloneDeep(aicList)
            .filter(f => !this.state.lassativiMicroclismi.find(d => lassativiMicroclismi.codiceAIC !== d.codiceAIC && d.codiceAIC === f.value));

        return (
            <FieldsRow>
                <RoleBasedInput
                    fieldId={this.props.label === "lassativo" ? 'M_TIPO_NOME_COMMERCIALE_LASS.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA' : 'M_TIPO_NOME_COMMERCIALE_MICRO.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={this.props.label === "lassativo" ? 'I_TIPO_NOME_COMMERCIALE_LASS.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA' : 'I_TIPO_NOME_COMMERCIALE_MICRO.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci nome commerciale"}
                    field={"nomeCommerciale"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id={"nomeCommerciale"}
                    value={lassativiMicroclismi.nomeCommerciale}
                    fieldLabel={"Nome commerciale ".concat(this.props.label)}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedAICInput
                    id={"codiceAIC"}
                    fieldId={this.props.label === "lassativo" ? 'M_TIPO_CODICE_AIC_LASS.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA' : 'M_TIPO_CODICE_AIC_MICRO.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={this.props.label === "lassativo" ? 'I_TIPO_CODICE_AIC_LASS.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA' : 'I_TIPO_NOME_COMMERCIALE_MICRO.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                    field={"codiceAIC"}
                    fieldLabel={"Codice AIC ".concat(this.props.label)}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    value={lassativiMicroclismi.codiceAIC}
                    suggestions={optionsAIC}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    classeEquivalenza={lassativiMicroclismi.classeEquivalenza}
                />
                <RoleBasedFarmacoEquivalenteInput
                    id={"classeEquivalenza"}
                    fieldId={this.props.label === "lassativo" ? 'M_TIPO_CLASSE_EQUIVALENZA_LASS.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA' : 'M_TIPO_CLASSE_EQUIVALENZA_MICRO.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={this.props.label === "lassativo" ? 'I_TIPO_NOME_COMMERCIALE_LASS.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA' : 'I_TIPO_NOME_COMMERCIALE_MICRO.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                    field={"classeEquivalenza"}
                    fieldLabel={"Classe di equivalenza ".concat(this.props.label)}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    value={lassativiMicroclismi.classeEquivalenza}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    codiceAIC={lassativiMicroclismi.codiceAIC}
                />
            </FieldsRow>
        );
    }

    render() {
        let lassativiMicroclismi = _.cloneDeep(this.state.lassativiMicroclismi);
        return <SectionList
            addNewSectionCallback={this.addNewLassativiMicroclismi}
            removeSectionCallback={this.removeLassativiMicroclismi}
            renderSection={this.renderNewLassativiMicroclismi}
            data={lassativiMicroclismi}
            title={this.props.label}
            forceReadOnly={this.props.forceReadOnly}
            atLeastOne={true}
            addButtonVisibilityHandler={(!Utils.isStringEmptyOrNullOrUndefined(this.state.lassativiMicroclismi[this.state.lassativiMicroclismi?.length - 1]?.nomeCommerciale))
            || (!Utils.isStringEmptyOrNullOrUndefined(this.state.lassativiMicroclismi[this.state.lassativiMicroclismi?.length - 1]?.codiceAIC))}
            field={this.props.field}
            parentJsonPath={this.props.parentJsonPath}
        />;
    }
}

LassativiMicroclismi.propTypes = propTypes;

LassativiMicroclismi.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false,
}
