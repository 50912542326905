import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedInput,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import VaccinazioniEffettuate, {defaultVaccinazioniEffettuate} from "./vaccinazioniEffettuate/VaccinazioniEffettuate";
import MalattieEsantematiche, {defaultMalattieEsantematiche} from "./malattieEsantematiche/MalattieEsantematiche";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import SessionRequest from "../../../utils/SessionRequest";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import Utils from "../../../utils/Utils";
import enumRichiesta from "../../../enum/enumRichiesta.json"

const propTypes = {
    datiInformazioniGenerali: PropTypes.array,
    onChangeDatiInformazioniGenerali: PropTypes.func,
    onValidaSezione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    deleteInformazioniGenerali: PropTypes.func,
    onHide: PropTypes.func,
    isOptional: PropTypes.bool,
    richiesta: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class InformazioniGenerali extends AccordionSecondLevel {

    state = {
        datiInformazioniGenerali: _.cloneDeep(this.props.datiInformazioniGenerali)
    }

    constructor(props) {
        super(props);
        this.onHide.bind(this);
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiInformazioniGenerali, this.props.datiInformazioniGenerali))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let listaInformazioniGenerali = _.cloneDeep(this.props.datiInformazioniGenerali);
        this.setState({datiInformazioniGenerali: listaInformazioniGenerali}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        let datiInformazioniGenerali = _.cloneDeep(this.state.datiInformazioniGenerali);
        datiInformazioniGenerali[field] = elem;

        this.setState({datiInformazioniGenerali: datiInformazioniGenerali}, () => this.validazioneDati());
    }

    validazioneDati = () => {
        let datiInformazioniGenerali = _.cloneDeep(this.state.datiInformazioniGenerali);
        this.props.onChangeDatiInformazioniGenerali("datiInformazioniGenerali", datiInformazioniGenerali);
    }

    onHide = () => {
        const datiInformazioniGenerali = _.cloneDeep(SessionRequest.getInitObject('anamnesiFisiologica.datiInformazioniGenerali'));
        this.setState({datiInformazioniGenerali},
            () => {
                this.props.onChangeDatiInformazioniGenerali("datiInformazioniGenerali", datiInformazioniGenerali);
                this.props.onHide();
            });
    }

    renderNewInformazioneGenerale = () => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'NATO_DA_PARTO.INFORMAZIONIGENERALI.ANAMNESIFISIOLOGICA'}
                        pageState={this.props.pageState}
                        id={"selectNatoDaParto"}
                        className={"selectNatoDaParto"}
                        options={oggettiUtili.natoDaParto}
                        value={this.state.datiInformazioniGenerali.natoDaParto}
                        onChange={(elem) => this.handleAggiornaForm("natoDaParto", elem)}
                        isSearchable={false}
                        fieldLabel={"Nato da parto"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.forceReadOnly}
                        handleOnlyValue={true}
                        field={"natoDaParto"}
                    />
                    <RoleBasedInput
                        fieldId={'NATO_DA_PARTO.INFORMAZIONIGENERALI.ANAMNESIFISIOLOGICA'}
                        pageState={this.props.pageState}
                        placeholder={"Note"}
                        field={"note"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"note"}
                        value={this.state.datiInformazioniGenerali.note}
                        fieldLabel={"Note"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'SVIL_PSICOS_REG.INFORMAZIONIGENERALI.ANAMNESIFISIOLOGICA'}
                        pageState={this.props.pageState}
                        id={"selectSviluppoPsicosomatico"}
                        className={"selectSviluppoPsicosomatico"}
                        options={oggettiUtili.rispostaBreve}
                        value={this.state.datiInformazioniGenerali.sviluppoPsicosomatico}
                        onChange={(elem) => this.handleAggiornaForm("sviluppoPsicosomatico", elem)}
                        isSearchable={false}
                        fieldLabel={"Sviluppo psicosomatico regolare"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.forceReadOnly}
                        handleOnlyValue={true}
                        field={"sviluppoPsicosomatico"}
                    />
                </FieldsRow>
                <MalattieEsantematiche
                    malattieEsantematiche={this.state.datiInformazioniGenerali.malattieEsantematiche}
                    onChangeDatiInformazioniGenerali={this.handleAggiornaForm}
                    pageState={this.props.pageState}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.forceReadOnly}
                />
                <VaccinazioniEffettuate
                    vaccinazioniEffettuate={this.state.datiInformazioniGenerali.vaccinazioniEffettuate}
                    onChangeDatiInformazioniGenerali={this.handleAggiornaForm}
                    pageState={this.props.pageState}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.forceReadOnly}
                />
            </>
        );
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"informazioniGenerali"}
            title={"Informazioni generali"}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            onHide={this.onHide}
            fieldsOrderMap={Utils.generateMapPathsObjects(
                ["anamnesiFisiologica.datiInformazioniGenerali.malattieEsantematiche", "anamnesiFisiologica.datiInformazioniGenerali.vaccinazioniEffettuate"],
                [defaultMalattieEsantematiche, defaultVaccinazioniEffettuate]
            )}
            isOptional={this.props.isOptional && isFieldIdWriteable('NATO_DA_PARTO.INFORMAZIONIGENERALI.ANAMNESIFISIOLOGICA', this.props.pageState, this.context.forceUserRole)}
            accordionReadOnly={this.props.accordionReadOnly}
            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
        >
            {this.renderNewInformazioneGenerale()}
        </AccordionSezioneRichiesta>
    }

}

InformazioniGenerali.propTypes = propTypes;

InformazioniGenerali.defaultProps = {
    isOptional: true,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

InformazioniGenerali.contextType = RoleBasedContext;
