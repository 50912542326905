import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import Box from "../../generalComponents/box/Box";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import {isArray} from "lodash";

const propTypes = {
    orofaringe: PropTypes.any,
    onChangeEsameObiettivo: PropTypes.func,
    pageState: PropTypes.string,
    openAccordion: PropTypes.bool,
    onClickAccordion: PropTypes.func,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultTipoAlterazione = {
    id: null,
    tipoDiAlterazione: null
}

const defaultListaAlterazioniDeglutizione = {
    id: null,
    tipoDiAlterazione: null,
    tipologia: null,
    gradoDiDisfagia: null
}

const defaultListaRinofaringe = {
    id: null,
    tipoDiAlterazione: null,
}

const defaultListaLaringe = {
    id: null,
    tipoDiAlterazione: null,
    condizioneRilevata: null,
    daAttenzionare: null
}

const defaultStatoGengive = {
    id: null,
    codice: null
}

const nomeAccordion = "OROFARINGE";

export default class Orofaringe extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.orofaringe)
    }

    rispostaStatoGengiveDataNotExist = [];

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.orofaringe, this.props.orofaringe))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.orofaringe);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({dati}) => {
            if (index === null && subField === null) {
                dati[field] = elem;
            } else {
                dati[field][index][subField] = elem;
            }

            if (dati.presenzaDiAlterazioniDentizione !== true) {
                dati.listaTipoAlterazione = [];
                dati.condizioneRilevata = null;
            }

            if (dati.igieneCavoOrale !== false) {
                dati.condizioneRilevataIgieneCavoOrale = null;
            }

            if (!(dati.statoGengive?.some((elem) => elem.codice !== null && elem.codice !== "01"))) {
                dati.condizioneRilevataGengive = null;
            }

            if (field === "statoGengive" && elem === null) {
                return this.removeStatoGengive(index);
            }

            if (!(dati.lingua !== "01" || dati.presenzaStomatite)) {
                dati.condizioneRilevataLingua = null;
            }

            if (dati.presenzaMicosi !== true) {
                dati.condizioneRilevataMicosi = null;
            }

            if (dati.presenzaAlterazioniDeglutizione !== true) {
                dati.listaAlterazioniDeglutizione = [];
            }

            if (!dati.presenzaAlterazioniDeglutizione) {
                dati.condizioneRilevataDeglutizione = null;
            }

            dati.listaAlterazioniDeglutizione.forEach(alterazioneDeglutizione => {
                if (!this.isAlterazioneDisfagia(alterazioneDeglutizione.tipoDiAlterazione)) {
                    alterazioneDeglutizione.tipologia = null;
                    alterazioneDeglutizione.gradoDiDisfagia = null;
                }
            });

            if (dati.listaRinofaringe?.length === 0) {
                dati.condizioneRilevataRinofaringe = null;
            }

            if (dati.listaLaringe?.length === 0) {
                dati.condizioneRilevataLaringe = null;
            }

            return {dati};
        }, this.validazioneDati)
    }

    validazioneDati = () => {
        let dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("orofaringe", dati);
        let campiObbligatoriRimanenti = 0;
        let proposteAttEsugg = [];
        Object.entries(this.state.dati).forEach(([key, value]) => {
            if (key.includes("propostaAttivitaSuggerimenti") && isArray(value)){
                value.forEach(element => {
                    proposteAttEsugg.push(element)
                });
            }
        });
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(proposteAttEsugg)
        this.props.onChangeRequiredFieldsNumber("sottosezioneOrofaringe", campiObbligatoriRimanenti);
      
    }

    /* TipoAlterazione */

    addNewTipoAlterazione = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultTipoAlterazione);
        type.id = uuid();
        dati.listaTipoAlterazione.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeTipoAlterazione = (indice) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.listaTipoAlterazione.splice(indice, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderTipoAlterazione = (tipoAlterazione, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'M_TIPO_ALT_DENT.OROFARINGE.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_TIPO_ALT_DENT.OROFARINGE.INGESTIONE'}
                    pageState={this.props.pageState}
                    id={"autocompleteTipoAlterazione" + index}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={(field, elem) => this.handleAggiornaForm("listaTipoAlterazione", elem.target.value, index, field)}
                    searchFromStart={false}
                    value={tipoAlterazione.tipoDiAlterazione}
                    field={"tipoDiAlterazione"}
                    placeholder={"ICD9-CM - Descrizione"}
                    fieldLabel={"Tipologia"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
            </FieldsRow>
        );
    }

    /* Rinofaringe */

    addNewRinofaringe = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultListaRinofaringe);
        type.id = uuid();
        dati.listaRinofaringe.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeRinofaringe = (indice) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.listaRinofaringe.splice(indice, 1);
        if (dati.listaRinofaringe?.length === 0) {
            dati.condizioneRilevataRinofaringe = null;
            dati.daAttenzionareRinofaringe = null;
        }
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderRinofaringe = (tipoAlterazioneRinofaringe, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'M_RINOF.OROFARINGE.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_RINOF.OROFARINGE.INGESTIONE'}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm("listaRinofaringe", elem.target.value, index, field)}
                    value={tipoAlterazioneRinofaringe.tipoDiAlterazione}
                    field={"tipoDiAlterazione"}
                    fieldLabel={"Tipologia"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
            </FieldsRow>
        );

    }

    /* Laringe */

    addNewLaringe = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultListaLaringe);
        type.id = uuid();
        dati.listaLaringe.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeLaringe = (indice) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.listaLaringe.splice(indice, 1);
        if (dati.listaLaringe?.length === 0) {
            dati.condizioneRilevataLaringe = null;
            dati.daAttenzionareLaringe = null;
        }
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderLaringe = (tipoAlterazioneLaringe, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'M_LAR.OROFARINGE.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_LAR.OROFARINGE.INGESTIONE'}
                    pageState={this.props.pageState}
                    id={"autocompleteLaringe" + index}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={(field, elem) => this.handleAggiornaForm("listaLaringe", elem.target.value, index, field)}
                    searchFromStart={false}
                    value={tipoAlterazioneLaringe.tipoDiAlterazione}
                    field={"tipoDiAlterazione"}
                    placeholder={"ICD9-CM - Descrizione"}
                    fieldLabel={"Tipologia"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
            </FieldsRow>
        );
    }

    /* TipoAlterazioneDeglutizione */

    isAlterazioneDisfagia(tipoAlterazioneDeglutizione) {
        return tipoAlterazioneDeglutizione === "43882" || tipoAlterazioneDeglutizione === "7872";
    }

    addNewTipoAlterazioneDeglutizione = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultListaAlterazioniDeglutizione);
        type.id = uuid();
        dati.listaAlterazioniDeglutizione.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeTipoAlterazioneDeglutizione = (indice) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.listaAlterazioniDeglutizione.splice(indice, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderTipoAlterazioneDeglutizione = (tipoAlterazioneDeglutizione, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'M_TIPO_ALT_DEGL.OROFARINGE.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_TIPO_ALT_DEGL.OROFARINGE.INGESTIONE'}
                    pageState={this.props.pageState}
                    id={"autocompleteTipoAlterazioneDeglutizione" + index}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={(field, elem) => this.handleAggiornaForm("listaAlterazioniDeglutizione", elem.target.value, index, field)}
                    searchFromStart={false}
                    value={tipoAlterazioneDeglutizione.tipoDiAlterazione}
                    field={"tipoDiAlterazione"}
                    placeholder={"ICD9-CM - Descrizione"}
                    fieldLabel={"Tipologia"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />

                {this.isAlterazioneDisfagia(tipoAlterazioneDeglutizione.tipoDiAlterazione) &&
                    <RoleBasedSelect
                        fieldId={'M_TIPO_DEGL.OROFARINGE.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_TIPO_ALT_DEGL.OROFARINGE.INGESTIONE'}
                        pageState={this.props.pageState}
                        id={"selectPresenzaDiAlterazioniDentizione"}
                        options={OggettiUtili.rispostaTipologiaDeglutizione}
                        value={tipoAlterazioneDeglutizione.tipologia}
                        onChange={(elem) => this.handleAggiornaForm("listaAlterazioniDeglutizione", elem, index, "tipologia")}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                        fieldLabel={"Tipologia"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"tipologia"}
                    />}

                {this.isAlterazioneDisfagia(tipoAlterazioneDeglutizione.tipoDiAlterazione) &&
                    <RoleBasedSelect
                        fieldId={'M_GRADO_DISF_DEGL.OROFARINGE.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_TIPO_DEGL.OROFARINGE.INGESTIONE'}
                        pageState={this.props.pageState}
                        id={"selectPresenzaDiAlterazioniDentizione"}
                        options={OggettiUtili.gradoDisfagia}
                        value={tipoAlterazioneDeglutizione.gradoDiDisfagia}
                        onChange={(elem) => this.handleAggiornaForm("listaAlterazioniDeglutizione", elem, index, "gradoDiDisfagia")}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                        fieldLabel={"Grado di disfagia"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"gradoDiDisfagia"}
                    />}
            </FieldsRow>
        );

    }

    renderStatoGengive = (statoGengive, index) => {
        let dati = this.state.dati;
        this.rispostaStatoGengiveDataNotExist[index] = OggettiUtili.rispostaStatoGengive.filter((item) => statoGengive.codice === item.value || !dati.statoGengive?.find((tipoFilter) => tipoFilter.codice === item.value));
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'M_STATO_GENG.OROFARINGE.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_STATO_GENG.OROFARINGE.INGESTIONE'}
                    pageState={this.props.pageState}
                    id={"selectIgieneCavoOrale " + index}
                    options={this.rispostaStatoGengiveDataNotExist[index]}
                    value={statoGengive.codice}
                    onChange={(elem) => this.handleAggiornaForm("statoGengive", elem, index, "codice")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                    fieldLabel={"Stato gengive"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    handleOnlyValue={true}
                    field={"codice"}
                />
            </FieldsRow>
        );
    }

    addNewStatoGengive = () => {
        let dati = _.cloneDeep(this.state.dati);
        let curStatoGengive = _.cloneDeep(defaultStatoGengive);
        curStatoGengive.id = uuid();
        dati.statoGengive.push(curStatoGengive);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeStatoGengive = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.statoGengive.splice(index, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    handleDispositiviArrayChange = (array, field) => {
        let data = _.cloneDeep(this.state.dati);
        data[field] = array;
        this.setState({dati: data}, () => {
            this.validazioneDati();
            this.forceUpdate();
        });
    }

    renderBodySezione() {
        let dati = this.state.dati;
        return (
            <>
                <Box key={"boxDentizione" + nomeAccordion} titleBox={"Dentizione"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_PRES_ALT_DENT.OROFARINGE.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_PRES_ALT_DENT.OROFARINGE.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"selectPresenzaDiAlterazioniDentizione"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.presenzaDiAlterazioniDentizione}
                            onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioniDentizione", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                            fieldLabel={"Presenza di alterazioni nella dentizione"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"presenzaDiAlterazioniDentizione"}
                        />
                    </FieldsRow>
                    {dati.presenzaDiAlterazioniDentizione &&
                        <SectionList
                            title={'Tipo di alterazione'}
                            data={dati.listaTipoAlterazione}
                            renderSection={this.renderTipoAlterazione}
                            addNewSectionCallback={this.addNewTipoAlterazione}
                            removeSectionCallback={this.removeTipoAlterazione}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            pageState={this.props.pageState}
                            keyFieldId={'M_TIPO_ALT_DENT.OROFARINGE.ESAMEOBIETTIVO'}
                            infermiereKeyFieldId={'I_TIPO_ALT_DENT.OROFARINGE.INGESTIONE'}
                            field={'listaTipoAlterazione'}
                            parentJsonPath={"esameObiettivo.orofaringe.listaTipoAlterazione"}
                            addButtonVisibilityHandler={dati.listaTipoAlterazione?.every(a => !!a.tipoDiAlterazione)}
                        />}

                    <DispositiviPresenti
                        handleArrayChange={this.handleDispositiviArrayChange}
                        dispositiviPresenti={dati.dispositiviPresenti}
                        pageState={this.props.pageState}
                        fieldId={'M_TIPO_ALT_DENT.OROFARINGE.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_TIPO_ALT_DENT.OROFARINGE.INGESTIONE'}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        parentJsonPath={"esameObiettivo.orofaringe.dispositiviPresenti"}
                    />

                    <CondizioneRilevataSection
                        ambito={'OROFARINGE DENTIZIONE'}
                        parentFieldId={'OROFARINGE.ESAMEOBIETTIVO'}
                        infParentFieldId={'OROFARINGE.INGESTIONE'}
                        fieldsSuffix={'ALT_DENT'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.condizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                        valueDaAttenzionare={dati.daAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        appendRolePrefix={true}
                        onChangeSuggerimenti={this.handleAggiornaForm}
                        valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={dati.presenzaDiAlterazioniDentizione}
                    />
                </Box>

                <Box key={"boxIgieneCavoOrale" + nomeAccordion} titleBox={"Igiene del cavo orale"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_IG_CAVO_OR.OROFARINGE.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_IG_CAVO_OR.OROFARINGE.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"selectIgieneCavoOrale"}
                            options={OggettiUtili.rispostaBreveIgieneCavoOrale}
                            value={dati.igieneCavoOrale}
                            onChange={(elem) => this.handleAggiornaForm("igieneCavoOrale", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                            fieldLabel={"Igiene del cavo orale"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"igieneCavoOrale"}
                        />
                    </FieldsRow>
                    <CondizioneRilevataSection
                        ambito={'OROFARINGE IGIENE DEL CAVO ORALE'}
                        parentFieldId={'OROFARINGE.ESAMEOBIETTIVO'}
                        infParentFieldId={'OROFARINGE.INGESTIONE'}
                        fieldsSuffix={'IG_CAVO'}
                        appendRolePrefix={true}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevataIgieneCavoOrale", elem)}
                        valueCondizioneRilevata={dati.condizioneRilevataIgieneCavoOrale}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionareIgieneCavoOrale", elem)}
                        valueDaAttenzionare={dati.daAttenzionareIgieneCavoOrale}
                        showAutonomia={true}
                        onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePazienteIgieneCavoOrale", elem)}
                        valueAutonomiaPaziente={dati.autonomiaGestionePazienteIgieneCavoOrale}
                        onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiverIgieneCavoOrale", elem)}
                        valueAutonomiaCaregiver={dati.autonomiaGestioneCaregiverIgieneCavoOrale}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathSuffix={'IgieneCavoOrale'}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("propostaAttivitaSuggerimentiIgieneCavoOrale", elem)}
                        valueSuggerimenti={dati.propostaAttivitaSuggerimentiIgieneCavoOrale}
                        showSuggerimenti={true}
                        openCondition={dati.igieneCavoOrale === false}
                    />
                </Box>

                <Box key={"boxGengive" + nomeAccordion} titleBox={"Stato gengive"}>
                    <SectionList
                        title={'Stato gengive'}
                        data={dati?.statoGengive || []}
                        renderSection={this.renderStatoGengive}
                        addNewSectionCallback={this.addNewStatoGengive}
                        removeSectionCallback={this.removeStatoGengive}
                        pageState={this.props.pageState}
                        keyFieldId={'M_STATO_GENG.OROFARINGE.ESAMEOBIETTIVO'}
                        infermiereKeyFieldId={'I_STATO_GENG.OROFARINGE.INGESTIONE'}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        field={'statoGengive'}
                        addButtonVisibilityHandler={dati?.statoGengive?.length === 0
                            || dati?.statoGengive?.every(el => !Utils.isObjectNull(el?.codice))}
                        parentJsonPath={"esameObiettivo.orofaringe.statoGenginve"}
                    />
                    <CondizioneRilevataSection
                        ambito={'OROFARINGE STATO GENGIVE'}
                        parentFieldId={'OROFARINGE.ESAMEOBIETTIVO'}
                        infParentFieldId={'OROFARINGE.INGESTIONE'}
                        appendRolePrefix={true}
                        fieldsSuffix={'GENG'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevataGengive", elem)}
                        valueCondizioneRilevata={dati.condizioneRilevataGengive}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionareGengive", elem)}
                        valueDaAttenzionare={dati.daAttenzionareGengive}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathSuffix={'Gengive'}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("propostaAttivitaSuggerimentiGengive", elem)}
                        valueSuggerimenti={dati.propostaAttivitaSuggerimentiGengive}
                        showSuggerimenti={true}
                        openCondition={(dati.statoGengive?.some((elem) => elem.codice !== null && elem.codice !== "01"))}
                    />
                </Box>

                <Box key={"boxLingua" + nomeAccordion} titleBox={"Lingua"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_LINGUA.OROFARINGE.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_LINGUA.OROFARINGE.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"selectLingua"}
                            options={OggettiUtili.rispostaLingua}
                            value={dati.lingua}
                            onChange={(elem) => this.handleAggiornaForm("lingua", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                            fieldLabel={"Lingua"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"lingua"}
                        />
                        <RoleBasedSelect
                            fieldId={'M_PRES_STOMAT.OROFARINGE.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_LINGUA.OROFARINGE.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"selectStomatite"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.presenzaStomatite}
                            onChange={(elem) => this.handleAggiornaForm("presenzaStomatite", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                            fieldLabel={"Presenza di stomatite"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"presenzaStomatite"}
                        />
                    </FieldsRow>
                    <CondizioneRilevataSection
                        ambito={'OROFARINGE LINGUA'}
                        parentFieldId={'OROFARINGE.ESAMEOBIETTIVO'}
                        infParentFieldId={'OROFARINGE.INGESTIONE'}
                        appendRolePrefix={true}
                        fieldsSuffix={'LING'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevataLingua", elem)}
                        valueCondizioneRilevata={dati.condizioneRilevataLingua}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionareLingua", elem)}
                        valueDaAttenzionare={dati.daAttenzionareLingua}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathSuffix={'Lingua'}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("propostaAttivitaSuggerimentiLingua", elem)}
                        valueSuggerimenti={dati.propostaAttivitaSuggerimentiLingua}
                        showSuggerimenti={true}
                        openCondition={(dati.lingua !== "01" || dati.presenzaStomatite)}
                    />
                </Box>

                <Box key={"boxMicosi" + nomeAccordion} titleBox={"Micosi"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_PRES_MIC_CAVO_OR.OROFARINGE.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_STATO_PRES_MIC_CAVO_OR.OROFARINGE.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"selectMicosi"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.presenzaMicosi}
                            onChange={(elem) => this.handleAggiornaForm("presenzaMicosi", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                            fieldLabel={"Presenza di micosi del cavo orale"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"presenzaMicosi"}
                        />
                    </FieldsRow>
                    <CondizioneRilevataSection
                        ambito={'OROFARINGE MICOSI'}
                        parentFieldId={'OROFARINGE.ESAMEOBIETTIVO'}
                        infParentFieldId={'OROFARINGE.INGESTIONE'}
                        appendRolePrefix={true}
                        fieldsSuffix={'MIC'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevataMicosi", elem)}
                        valueCondizioneRilevata={dati.condizioneRilevataMicosi}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionareMicosi", elem)}
                        valueDaAttenzionare={dati.daAttenzionareMicosi}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathSuffix={'Micosi'}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("propostaAttivitaSuggerimentiMicosi", elem)}
                        valueSuggerimenti={dati.propostaAttivitaSuggerimentiMicosi}
                        showSuggerimenti={true}
                        openCondition={dati.presenzaMicosi === true}
                    />
                </Box>

                <Box key={"boxDeglutizione" + nomeAccordion} titleBox={"Deglutizione"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_PRES_ALT_DEGL.OROFARINGE.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_PRES_ALT_DEGL.OROFARINGE.INGESTIONE'}
                            pageState={this.props.pageState}
                            id={"selectDeglutizione"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.presenzaAlterazioniDeglutizione}
                            onChange={(elem) => this.handleAggiornaForm("presenzaAlterazioniDeglutizione", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                            fieldLabel={"Presenza di alterazioni nella deglutizione"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"presenzaAlterazioniDeglutizione"}
                        />
                    </FieldsRow>
                    {dati.presenzaAlterazioniDeglutizione &&
                        <>
                            <SectionList
                                title={'Tipo di alterazione'}
                                data={dati.listaAlterazioniDeglutizione}
                                renderSection={this.renderTipoAlterazioneDeglutizione}
                                addNewSectionCallback={this.addNewTipoAlterazioneDeglutizione}
                                removeSectionCallback={this.removeTipoAlterazioneDeglutizione}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                pageState={this.props.pageState}
                                keyFieldId={'M_TIPO_ALT_DEGL.OROFARINGE.ESAMEOBIETTIVO'}
                                infermiereKeyFieldId={'I_TIPO_ALT_DEGL.OROFARINGE.INGESTIONE'}
                                field={'listaAlterazioniDeglutizione'}
                                parentJsonPath={"esameObiettivo.orofaringe.listaAlterazioniDeglutizione"}
                                addButtonVisibilityHandler={dati.listaAlterazioniDeglutizione?.every(a => !!a.tipoDiAlterazione)}
                            />
                            <CondizioneRilevataSection
                                ambito={'OROFARINGE DEGLUTIZIONE'}
                                parentFieldId={'OROFARINGE.ESAMEOBIETTIVO'}
                                infParentFieldId={'OROFARINGE.INGESTIONE'}
                                appendRolePrefix={true}
                                fieldsSuffix={'DEGL'}
                                pageState={this.props.pageState}
                                onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevataDeglutizione", elem)}
                                valueCondizioneRilevata={dati.condizioneRilevataDeglutizione}
                                onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionareDeglutizione", elem)}
                                valueDaAttenzionare={dati.daAttenzionareDeglutizione}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                fieldJsonPathSuffix={'Deglutizione'}
                                onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("propostaAttivitaSuggerimentiDeglutizione", elem)}
                                valueSuggerimenti={dati.propostaAttivitaSuggerimentiDeglutizione}
                                showSuggerimenti={true}
                                openCondition={dati.presenzaAlterazioniDeglutizione}
                            />
                        </>}
                </Box>

                <Box key={"boxRinofaringe" + nomeAccordion} titleBox={"Rinofaringe"}>
                    <SectionList
                        title={'Rinofaringe'}
                        data={dati.listaRinofaringe}
                        renderSection={this.renderRinofaringe}
                        addNewSectionCallback={this.addNewRinofaringe}
                        removeSectionCallback={this.removeRinofaringe}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'M_RINOF.OROFARINGE.ESAMEOBIETTIVO'}
                        infermiereKeyFieldId={'I_RINOF.OROFARINGE.INGESTIONE'}
                        field={'listaRinofaringe'}
                        parentJsonPath={"esameObiettivo.orofaringe.listaRinofaringe"}
                        addButtonVisibilityHandler={dati.listaRinofaringe?.every(r => !!r.tipoDiAlterazione)}
                    />
                    <CondizioneRilevataSection
                        ambito={'OROFARINGE RINOFARINGE'}
                        parentFieldId={'OROFARINGE.ESAMEOBIETTIVO'}
                        infParentFieldId={'OROFARINGE.INGESTIONE'}
                        appendRolePrefix={true}
                        fieldsSuffix={'RINOF'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevataRinofaringe", elem)}
                        valueCondizioneRilevata={dati.condizioneRilevataRinofaringe}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionareRinofaringe", elem)}
                        valueDaAttenzionare={dati.daAttenzionareRinofaringe}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathSuffix={'Rinofaringe'}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("propostaAttivitaSuggerimentiRinofaringe", elem)}
                        valueSuggerimenti={dati.propostaAttivitaSuggerimentiRinofaringe}
                        showSuggerimenti={true}
                        openCondition={dati.listaRinofaringe?.length > 0}
                    />
                </Box>

                <Box key={"boxLaringe" + nomeAccordion} titleBox={"Laringe"}>
                    <SectionList
                        title={'Laringe'}
                        data={dati.listaLaringe}
                        renderSection={this.renderLaringe}
                        addNewSectionCallback={this.addNewLaringe}
                        removeSectionCallback={this.removeLaringe}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'M_LAR.OROFARINGE.ESAMEOBIETTIVO'}
                        infermiereKeyFieldId={'I_LAR.OROFARINGE.INGESTIONE'}
                        field={'listaLaringe'}
                        parentJsonPath={"esameObiettivo.orofaringe.listaLaringe"}
                        addButtonVisibilityHandler={dati.listaLaringe?.every(l => !!l.tipoDiAlterazione)}
                    />

                    <CondizioneRilevataSection
                        ambito={'OROFARINGE LARINGE'}
                        parentFieldId={'OROFARINGE.ESAMEOBIETTIVO'}
                        infParentFieldId={'OROFARINGE.INGESTIONE'}
                        appendRolePrefix={true}
                        fieldsSuffix={'LAR'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevataLaringe", elem)}
                        valueCondizioneRilevata={dati.condizioneRilevataLaringe}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionareLaringe", elem)}
                        valueDaAttenzionare={dati.daAttenzionareLaringe}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldJsonPathSuffix={'laringe'}
                        onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("propostaAttivitaSuggerimentiLaringe", elem)}
                        valueSuggerimenti={dati.propostaAttivitaSuggerimentiLaringe}
                        showSuggerimenti={true}
                        openCondition={dati.listaLaringe?.length > 0}
                    />
                </Box>
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"orofaringe"}
                    title={"Orofaringe"}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.orofaringe.listaTipoDiAlterazione", "esameObiettivo.orofaringe.dispositiviPresenti",
                            "esameObiettivo.orofaringe.statoGengive", "esameObiettivo.orofaringe.listaAlterazioniDeglutizione",
                            "esameObiettivo.orofaringe.listaRinofaringe", "esameObiettivo.orofaringe.listaLaringe",
                            "esameObiettivo.orofaringe.propostaAttivitaSuggerimenti"],
                        [defaultTipoAlterazione, defaultDispositiviPresenti, defaultStatoGengive,
                            defaultListaAlterazioniDeglutizione, defaultListaRinofaringe, defaultListaLaringe,
                            defaultProposteAttivitaSuggerimenti]
                    )}
                    accordionReadOnly={!!this.props.accordionReadOnly}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}

Orofaringe.propTypes = propTypes;

Orofaringe.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    showAccordion: false,
    disableDoubleInput: false
}

