const globals = {
  'app-application-name': '',
  'app-id-applicazione': '',
  'app-nome-dominio': process.env.REACT_APP_APP_NOME_DOMINIO,
  'app-url-backend': process.env.REACT_APP_APP_URL_BACKEND,
  'app-url-request-auth-tokens': process.env.REACT_APP_REQUEST_AUTH_TOKENS,
  'app-url-request-saml-tokens': process.env.REACT_APP_REQUEST_SAML_TOKENS,
  'app-url-request-refresh-tokens': process.env.REACT_APP_REQUEST_REFRESH_TOKENS,
  'app-url-ms-schede': process.env.REACT_APP_APP_URL_BACKEND_MSSCHEDE,
  'app-url-ms-anagrafica': process.env.REACT_APP_APP_URL_BACKEND_MSANAGRAFICALOCALE,
  'app-url-ms-anamnesi': process.env.REACT_APP_APP_URL_BACKEND_MSANAMNESI,
  'app-url-ms-toponomastica': "http://localhost:8093/api/v1",
  'app-application-logo': require('web-client-archetype/dist/img/avatar/avatar_padre.png'),
  'app-application-logo-alt': '',
  'app-header-image': require('web-client-archetype/dist/img/logo/logo-regione-veneto--bw.png'),
  'app-icon-sprite': require('web-client-archetype/dist/svg/sprite.svg'),
  'arrow-ascendant-sort': require('web-client-archetype/dist/img/icons/arrow_ascendant_sort.png'),
  'arrow-descendant-sort': require('web-client-archetype/dist/img/icons/arrow_descendant_sort.png'),
  'arrow-both-sort': require('web-client-archetype/dist/img/icons/arrow_sort_both.png'),
  urlComuni: '/comuni',
  urlProvince: '/province',
  urlSiglaProvince: '/sigleProvince',
  urlNazioni: '/nazioni',
  appWidthBreakdown: 1220,
  'lingua-default': 'it-IT'
}

export default globals
