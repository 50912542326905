import React, {Fragment, useContext} from "react";
import PropTypes from "prop-types";
import RadioButton from "../../../generalComponents/radiobutton/RadioButton";
import styles from "../Questionario.module.css";

function RenderDomanda ({domanda, disabilitaDomanda, handleChange, tipoQuestionario, value}) {

    const changeRisposta = (risposta) => {
        const {code, display, id, system} = risposta;
        const object = {
            text: domanda?.label ?? null,
            tipoRisposta:domanda?.tipoRisposta ?? null,
            id: domanda.id,
            linkId: domanda?.linkId ?? null,
            risposte: [{code: code ?? null, display:display ?? null, id: id, system: system ?? null}]
        }
        handleChange(object);
    }

    const renderRisposte = () => {
        return (
            domanda.risposte.map((risposta) => (
                <div className={styles.risposta.concat(" row pl-2")}>
                    <div className={"col-11 align-self-center"}>
                        <p className={"mb-0"}>{risposta.label}</p>
                    </div>
                    <div className={"col-1 align-self-center"}>
                        <RadioButton
                            id={domanda.id + "r" + risposta.id + tipoQuestionario}
                            group={domanda.id + tipoQuestionario}
                            items={[{value: risposta.id, label: ""}]}
                            onChange={() => {changeRisposta(risposta)}}
                            defaultChoice={value == risposta.id ? 0 : -1}
                            customFormCheckClassName={"form-check mt-3"}
                            showLabel={false}
                            disabled={disabilitaDomanda}
                        />
                    </div>
                </div>

            )))
    }

    return (
        <Fragment>
            <span className="f-weight-500">{domanda.label} {domanda.label && domanda.obbligatorio ?
                <span className={styles.requiredFields}>*</span> : null}
            </span>
            {renderRisposte()}
        </Fragment>
    )
}

RenderDomanda.propTypes = {
    domanda: PropTypes.node,
    disabilitaDomanda:PropTypes.bool, 
    handleChange:PropTypes.func.isRequired, 
    value: PropTypes.number
};

RenderDomanda.defaultProps = {
    domanda: {},
    disabilitaDomanda:false, 
    value: null
};

export default RenderDomanda;
