import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import Statistica from "../../statistica/Statistica";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import enumQuestionario from "../../../enum/enumQuestionario.json";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import AuthUtils from "../../../utils/AuthUtils";
import enumsUtente from "../../../enum/enumsUtente.json";
const propTypes = {
    datiSensorioEquilibrio: PropTypes.any,
    onChangeDatiSensorioEquilibrio: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    richiesta: PropTypes.any,
    accordionTitle: PropTypes.string,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    fieldIdParent: PropTypes.string,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}
const defaultAlterazione = {
    id: null,
    tipoAlterazione: null
}

export default class SensorioEquilibrio extends AccordionSecondLevel {

    state = {
        datiSensorioEquilibrio: _.cloneDeep(this.props.datiSensorioEquilibrio)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    inizializzaForm = () => {
        const datiSensorioEquilibrio = _.cloneDeep(this.props.datiSensorioEquilibrio)
        this.setState({datiSensorioEquilibrio}, this.validazioneDati);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiSensorioEquilibrio, this.props.datiSensorioEquilibrio)) {
            this.forceUpdate(() => this.inizializzaForm());
        }
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({datiSensorioEquilibrio}) => {
            if (index == null && subField == null) {
                datiSensorioEquilibrio[field] = elem;
            } else {
                datiSensorioEquilibrio[field][index][subField] = elem;
            }
            return {datiSensorioEquilibrio};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        const datiSensorioEquilibrio = _.cloneDeep(this.state.datiSensorioEquilibrio);
        this.props.onChangeDatiSensorioEquilibrio("sensorioEquilibrio", datiSensorioEquilibrio)
        
        let campiObbligatori = 0;
        campiObbligatori += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiSensorioEquilibrio?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneSensorioEquilibrio", campiObbligatori);
    }

    addNewAlterazione = () => {
        let datiSensorioEquilibrio = _.cloneDeep(this.state.datiSensorioEquilibrio);
        let type = _.cloneDeep(defaultAlterazione);
        type.id = uuid();
        if (datiSensorioEquilibrio.alterazione == null) datiSensorioEquilibrio.alterazione = [];
        datiSensorioEquilibrio.alterazione.push(type);
        this.setState({datiSensorioEquilibrio}, this.validazioneDati);
    }

    removeAlterazione = (index) => {
        let datiSensorioEquilibrio = _.cloneDeep(this.state.datiSensorioEquilibrio);
        datiSensorioEquilibrio.alterazione.splice(index, 1);
        this.setState({datiSensorioEquilibrio}, this.validazioneDati);
    }

    renderAlterazione = (alterazione, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'M_ALT.SENSORIOEQUILIBRIO.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={'I_ALT.EQUILIBRIO.SENSORIO'}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm("alterazione", elem.target.value, index, "tipoAlterazione")}
                    value={alterazione.tipoAlterazione}
                    disableDoubleInput={this.props.disableDoubleInput}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    fieldLabel={'Alterazione'}
                    field={'tipoAlterazione'}
                />
            </FieldsRow>
        )
    }

    renderBody = () => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'M_SCALE_REG_OSP.SENSORIOEQUILIBRIO.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_SCALE_REG_OSP.EQUILIBRIO.SENSORIO'}
                        pageState={this.props.pageState}
                        value={this.state.datiSensorioEquilibrio.scaleRegionaliLatoOspedale}
                        placeholder="Inserisci Scale regionali lato ospedale"
                        field="scaleRegionaliLatoOspedale"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="altraProposta"
                        fieldLabel={'Scale regionali lato ospedale'}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <Statistica
                    fieldId={'M_PTINETTI.SENSORIOEQUILIBRIO.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={'I_PTINETTI.EQUILIBRIO.SENSORIO'}
                    pageState={this.props.pageState}
                    nomeLink={"Compila questionario Scala di valutazione dell’equilibrio e dell’andatura (TINETTI)"}
                    nomePunteggio={"Punteggio Tinetti"}
                    questionario={this.state.datiSensorioEquilibrio.TINETTI}
                    profileName={"Rischio di cadute (Tinetti)"}
                    profileFieldId={'M_RISC_CADUTE.SENSORIOEQUILIBRIO.ANAMNESIFISIOLOGICA'}
                    onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.TINETTI, questionario)}
                    richiesta={this.props.richiesta}
                    pathRichiestaQuestionario={"anamnesiFisiologica.sensorioEquilibrio.TINETTI"}
                    tipoQuestionario={enumQuestionario.tipoQuestionario.TINETTI}
                    profileRequired={false}
                    scoreMandatoryForRole={null}
                    salvaBozza={this.props.salvaBozza}
                    idSezioniPunteggiIntermedi={[1, 2]}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    parentJsonPathSubObject={'tinetti'}
                    fieldIdParent={this.props.fieldIdParent}
                />

                <SectionList
                    title={'Alterazione'}
                    data={this.state.datiSensorioEquilibrio.alterazione}
                    renderSection={this.renderAlterazione}
                    addNewSectionCallback={this.addNewAlterazione}
                    removeSectionCallback={this.removeAlterazione}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    pageState={this.props.pageState}
                    keyFieldId={'M_ALT.SENSORIOEQUILIBRIO.ANAMNESIFISIOLOGICA'}
                    infermiereKeyFieldId={'I_ALT.EQUILIBRIO.SENSORIO'}
                    addButtonVisibilityHandler={!this.state.datiSensorioEquilibrio.alterazione?.some(a => !a.tipoAlterazione) ?? true}
                    field={'alterazione'}
                    parentJsonPath={"anamnesiFisiologica.sensorioEquilibrio.alterazione"}
                />

                <ValutazioneSection
                    ambito={"SENSORIO - EQUILIBRIO"}
                    pageState={this.props.pageState}
                    parentFieldId={'SENSORIOEQUILIBRIO.ANAMNESIFISIOLOGICA'}
                    appendRolePrefix={true}
                    infParentFieldId={'EQUILIBRIO.SENSORIO'}
                    valueStatoDopoValutazione={this.state.datiSensorioEquilibrio.statoDopoValutazione}
                    onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                    valueDataValutazione={this.state.datiSensorioEquilibrio.dataValutazione}
                    onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                    valueDaConsiderare={this.state.datiSensorioEquilibrio.daConsiderare}
                    onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                    showSuggerimenti={true}
                    valueSuggerimenti={this.state.datiSensorioEquilibrio.propostaAttivitaSuggerimenti}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={this.state.datiSensorioEquilibrio.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={this.state.datiSensorioEquilibrio.autonomiaGestioneCaregiver}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    unicheParentId={this.props.fieldIdParent}
                />
            </>

        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"sensorioEquilibrio"}
                title={this.props.accordionTitle}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["anamnesiFisiologica.sensorioEquilibrio.alterazione"],
                    [defaultAlterazione]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}
SensorioEquilibrio.propTypes = propTypes;
SensorioEquilibrio.defaultProps = {
    accordionTitle: "Sensorio - Equilibrio",
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

