import React, {Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import {
    calcolaCampiObbligatoriRimanenti,
    CONTROLLO_CAMPI_FORMALIZZAZIONE
} from "../CompilaFormalizzazione";
import ValutazioniSettingRichiesto from "./ValutazioniSettingRichiesto";
import ResponsabileDelCasoNelSettingRichiesto from "./ResponsabileDelCasoNelSettingRichiesto";
import EventoAssistenzialeCureDomiciliari from "./EventoAssistenzialeCureDomiciliari";
import VersionedAccordion from "../../VersionedAccordion";

const propTypes = {
    progettoAssistenziale: PropTypes.any,
    richiesta: PropTypes.any,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeFormalizzazione: PropTypes.func,
    pageState: PropTypes.string
}

export default class ProgettoAssistenziale extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: 0,
        progettoAssistenziale: null,
    }

    componentDidMount() {
        this.setProgettoAssistenziale(_.cloneDeep(this.props.progettoAssistenziale));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.progettoAssistenziale, this.props.progettoAssistenziale)) {
            this.setProgettoAssistenziale(_.cloneDeep(this.props.progettoAssistenziale));
        }
    }

    setProgettoAssistenziale(progettoAssistenziale) {
        let numeroCampiObbligatoriRimanenti = calcolaCampiObbligatoriRimanenti(CONTROLLO_CAMPI_FORMALIZZAZIONE, progettoAssistenziale, "progettoAssistenziale");
        this.setState({
            progettoAssistenziale: progettoAssistenziale,
            campiObbligatoriMancanti: numeroCampiObbligatoriRimanenti
        }, () => {
            this.props.onChangeFormalizzazione(progettoAssistenziale, "progettoAssistenziale", numeroCampiObbligatoriRimanenti);
        })
    }

    handleChangeProgettoAssistenziale = (field, value) => {
        let progettoAssistenziale = _.cloneDeep(this.state.progettoAssistenziale);
        if (progettoAssistenziale) {
            progettoAssistenziale[field] = value;

            if (field === "ruoloDelResponsabile" && value === "01") {
                progettoAssistenziale.nomeResponsabile = this.props.richiesta?.anagrafePaziente?.mmgPlsDelPaziente?.nome;
                progettoAssistenziale.cognomeResponsabile = this.props.richiesta?.anagrafePaziente?.mmgPlsDelPaziente?.cognome;
                progettoAssistenziale.telefonoResponsabile = this.props.richiesta?.anagrafePaziente?.mmgPlsDelPaziente?.telefono;
                progettoAssistenziale.cellulareResponsabile = this.props.richiesta?.anagrafePaziente?.mmgPlsDelPaziente?.cellulare;
                progettoAssistenziale.emailResponsabile = this.props.richiesta?.anagrafePaziente?.mmgPlsDelPaziente?.email;
            }

            this.modificaObbligatorietaCampi(field, value);
            let numeroCampiObbligatoriRimanenti = calcolaCampiObbligatoriRimanenti(CONTROLLO_CAMPI_FORMALIZZAZIONE, progettoAssistenziale, "progettoAssistenziale");
            this.setState({campiObbligatoriMancanti: numeroCampiObbligatoriRimanenti}, () => {
                this.props.onChangeFormalizzazione(progettoAssistenziale, "progettoAssistenziale", numeroCampiObbligatoriRimanenti);
            })
        }
    };
    //fix merge dxc-master
    modificaObbligatorietaCampi = (field, value) => {
        if (field === "cellulareResponsabile") {
            if (value && !this.state.progettoAssistenziale?.telefonoResponsabile) {
                CONTROLLO_CAMPI_FORMALIZZAZIONE.telefonoResponsabile.required = false;
                CONTROLLO_CAMPI_FORMALIZZAZIONE.cellulareResponsabile.required = true;
            } else if (!value && this.state.progettoAssistenziale?.cellulareResponsabile) {
                CONTROLLO_CAMPI_FORMALIZZAZIONE.telefonoResponsabile.required = true;
                CONTROLLO_CAMPI_FORMALIZZAZIONE.cellulareResponsabile.required = false;
            } else if ((!value && !this.state.progettoAssistenziale?.telefonoResponsabile) || (value && this.state.progettoAssistenziale?.telefonoResponsabile)) {
                CONTROLLO_CAMPI_FORMALIZZAZIONE.telefonoResponsabile.required = true;
                CONTROLLO_CAMPI_FORMALIZZAZIONE.cellulareResponsabile.required = true;
            }
        } else if (field === "telefonoResponsabile") {
            if (value && !this.state.progettoAssistenziale?.cellulareResponsabile) {
                CONTROLLO_CAMPI_FORMALIZZAZIONE.cellulareResponsabile.required = false;
                CONTROLLO_CAMPI_FORMALIZZAZIONE.telefonoResponsabile.required = true;
            } else if (!value && this.state.progettoAssistenziale?.telefonoResponsabile) {
                CONTROLLO_CAMPI_FORMALIZZAZIONE.cellulareResponsabile.required = true;
                CONTROLLO_CAMPI_FORMALIZZAZIONE.telefonoResponsabile.required = false;
            } else if ((!value && !this.state.progettoAssistenziale?.cellulareResponsabile) || (value && this.state.progettoAssistenziale?.cellulareResponsabile)) {
                CONTROLLO_CAMPI_FORMALIZZAZIONE.cellulareResponsabile.required = true;
                CONTROLLO_CAMPI_FORMALIZZAZIONE.telefonoResponsabile.required = true;
            }
        }
    }

    renderValutazioniSettingRichiesto = () => (
        <VersionedAccordion accordion={ValutazioniSettingRichiesto}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            progettoAssistenziale = {this.state.progettoAssistenziale}
            handleChangeProgettoAssistenziale = {this.handleChangeProgettoAssistenziale}
        />
    );

    renderResponsabileDelCasoNelSettingRichiesto = () => (
        <VersionedAccordion accordion={ResponsabileDelCasoNelSettingRichiesto}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            progettoAssistenziale = {this.state.progettoAssistenziale}
            handleChangeProgettoAssistenziale = {this.handleChangeProgettoAssistenziale}
        />
    );

    renderEventoAssistenzialeCureDomiciliari = () => (
        <VersionedAccordion accordion={EventoAssistenzialeCureDomiciliari}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            progettoAssistenziale = {this.state.progettoAssistenziale}
            handleChangeProgettoAssistenziale = {this.handleChangeProgettoAssistenziale}
        />
    );

    render() {
        return (
            <Fragment>
                <AccordionAvvioRichiesta
                    idAccordion={"progettoAssistenziale"}
                    title={"Progetto assistenziale"}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    numberOfRequiredFieldsMissing={this.state.campiObbligatoriMancanti}
                >
                    {this.renderValutazioniSettingRichiesto()}
                    {this.renderResponsabileDelCasoNelSettingRichiesto()}
                    {this.renderEventoAssistenzialeCureDomiciliari()}
                </AccordionAvvioRichiesta>
            </Fragment>

        );
    }
}

ProgettoAssistenziale.propTypes = propTypes;
