import React from 'react'
import styles from './NotAuthorized.module.css'
import {Button} from "web-client-archetype";
import authConfig from "../../config/authConfig";

export default class NotAuthorized extends React.Component {
    render() {
        console.log('[DEBUG] NotAuthorized: render')
        return (
            <div className={styles.notAuthContainer.concat(" ml-5")}>

                <h1>Siamo spiacenti, non è stata concessa l'autorizzazione per visualizzare questa pagina. Rivolgersi al referente ULSS di competenza per richiedere l'abilitazione.</h1>

                <a href={authConfig["url-logout"]}>
                    <Button
                        id={"bottoneLogout"}
                        text={"Esci"}
                        className={styles.btnAccesso.concat(" btn btn-adi btnGreenOutline")}
                    />
                </a>
            </div>
        );
    }
}
