import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    datiIdratazione: PropTypes.any,
    accordionTitle: PropTypes.string,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class Idratazione extends AccordionSecondLevel {
    state = {
        datiIdratazione: _.cloneDeep(this.props.datiIdratazione)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiIdratazione, this.props.datiIdratazione))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        const datiIdratazione = _.cloneDeep(this.props.datiIdratazione);
        this.setState({datiIdratazione}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiIdratazione}) => {
            datiIdratazione[field] = elem;

            if (!datiIdratazione.limitazioniPrescritteIntroitoLiquidi) {
                datiIdratazione.limitazioni = null;
            }

            if (!this.showCondizioneRilevata(datiIdratazione)) {
                datiIdratazione.condizioneRilevata = null;
            }

            return {datiIdratazione};
        }, this.validazioneDati)
    }

    validazioneDati = () => {
        const datiIdratazione = _.cloneDeep(this.state.datiIdratazione);
        this.props.onChangeDatiAssistito("datiIdratazione", datiIdratazione);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiIdratazione?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneIdratazione", campiObbligatoriRimanenti);
    }

    showCondizioneRilevata(datiIdratazione) {
        return datiIdratazione.abitudiniAssunzioneLiquidi || datiIdratazione.statoDisidratazione || datiIdratazione.limitazioniPrescritteIntroitoLiquidi;
    }

    renderBody = () => {
        const datiIdratazione = this.state.datiIdratazione;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_AB_ASS_LIQUID.IDRATAZIONE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_AB_ASS_LIQUID.STATOIDRATAZIONE.IDRATAZIONE'}
                        pageState={this.props.pageState}
                        id={"selectAbitudiniAssunzioneLiquidi"}
                        options={OggettiUtili.abitudiniAssunzioneLiquidi}
                        value={datiIdratazione.abitudiniAssunzioneLiquidi}
                        onChange={(elem) => this.handleAggiornaForm("abitudiniAssunzioneLiquidi", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Abitudini assunzione liquidi non esistente"}
                        fieldLabel={"Abitudini nell'assunzione di liquidi"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"abitudiniAssunzioneLiquidi"}
                    />
                    <RoleBasedSelect
                        fieldId={'M_STATO_DISIDR.IDRATAZIONE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_STATO_DISIDR.STATOIDRATAZIONE.IDRATAZIONE'}
                        pageState={this.props.pageState}
                        id={"selectStatoDisidratazione"}
                        options={OggettiUtili.statoDisidratazione}
                        value={datiIdratazione.statoDisidratazione}
                        onChange={(elem) => this.handleAggiornaForm("statoDisidratazione", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Stato di disidratazione non esistente"}
                        fieldLabel={"Stato di disidratazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"statoDisidratazione"}
                    />
                    <RoleBasedSelect
                        fieldId={'M_LIM_PRESC_INTR.IDRATAZIONE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_LIM_PRESC_INTR.STATOIDRATAZIONE.IDRATAZIONE'}
                        pageState={this.props.pageState}
                        id={"selectLimitazioniPrescritteIntroitoLiquidi"}
                        options={OggettiUtili.limitazioniPrescritteIntroitoLiquidi}
                        value={datiIdratazione.limitazioniPrescritteIntroitoLiquidi}
                        onChange={(elem) => this.handleAggiornaForm("limitazioniPrescritteIntroitoLiquidi", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Limitazioni prescritte nell'introito di liquidi non esistente"}
                        fieldLabel={"Limitazioni prescritte nell'introito di liquidi"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"limitazioniPrescritteIntroitoLiquidi"}
                    />
                    {datiIdratazione.limitazioniPrescritteIntroitoLiquidi &&
                        <RoleBasedInput
                            fieldId={'M_LIM_PRESC_INTR.IDRATAZIONE.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_LIM_PRESC_INTR.STATOIDRATAZIONE.IDRATAZIONE'}
                            pageState={this.props.pageState}
                            placeholder={"Inserisci limitazioni (mL/die)"}
                            field={"limitazioni"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"inputLimitazioniPrescritteIntroitoLiquidi"}
                            value={datiIdratazione.limitazioni}
                            fieldLabel={"Limitazioni (mL/die)"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                        />}
                </FieldsRow>

                <CondizioneRilevataSection
                    ambito={'IDRATAZIONE'}
                    parentFieldId={'IDRATAZIONE.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'STATOIDRATAZIONE.IDRATAZIONE'}
                    appendRolePrefix={true}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={datiIdratazione.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={datiIdratazione.daAttenzionare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={datiIdratazione.propostaAttivitaSuggerimenti}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={datiIdratazione.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={datiIdratazione.autonomiaGestioneCaregiver}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    openCondition={this.showCondizioneRilevata(datiIdratazione)}
                />

                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'M_DATA_VAL.IDRATAZIONE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_DATA_VAL.STATOIDRATAZIONE.IDRATAZIONE'}
                        pageState={this.props.pageState}
                        id={"idratazioneDataValutazione"}
                        value={datiIdratazione.dataValutazione}
                        onChange={(elem) => this.handleAggiornaForm("dataValutazione", elem.target.value)}
                        errorMessage={"Data valutazione non valida"}
                        fieldLabel={"Data Valutazione"}
                        field={"dataValutazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiIdratazione"}
                title={this.props.accordionTitle}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["anamnesiFisiologica.datiIdratazione.propostaAttivitaSuggerimenti"],
                    [defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}
Idratazione.propTypes = propTypes;
Idratazione.defaultProps = {
    accordionTitle: "Idratazione",
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

