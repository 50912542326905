import React, { Component } from "react";
import PropTypes from "prop-types";

const propTypes = { scrollInit: PropTypes.bool, conAnimazione: PropTypes.bool}
const defaultProps = { scrollInit: false, conAnimazione: true }

export default class ScrollTop extends Component {

    scrollTop = false;

    componentDidMount() {
        const handleScroll = () => {
            const tabScroll = document.getElementById("idDivScrollTo");
            if (!tabScroll || this.scrollTop) return;
            if (window.scrollY >= tabScroll.offsetTop) {
                this.scrollTop = true;
                window.removeEventListener('scroll', this.handleScroll);
            }
        }
        window.addEventListener('scroll', handleScroll)
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { scrollInit, conAnimazione} = this.props;
        if (!this.scrollTop) {
            const tabScroll = document.getElementById("idDivScrollTo");
            window.scrollTo({
                'behavior': conAnimazione ? 'smooth': 'auto',
                'left': 0,
                'top': scrollInit ? 0 : tabScroll.offsetTop 
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        return (
            <div id="idDivScrollTo"></div>
        )
    }
}

ScrollTop.propTypes = propTypes;
ScrollTop.defaultProps = defaultProps;

