import React from "react";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import AccordionHelper from "../../../service/AccordionHelper";
import PropTypes from "prop-types";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import * as _ from "lodash";
import FigureProfessionaliSupportoAValutazioneSottoAccordion
    from "./figureProfessionaliSupportoAValutazioneSottoAccordion/FigureProfessionaliSupportoAValutazioneSottoAccordion";
import VersionedAccordion from "../../VersionedAccordion";

const propTypes = {
    dati: PropTypes.any,
    datiNecessitaAssistenzaSanitaria: PropTypes.object,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiFigureProfessionaliSupportoAValutazione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    firstPageName: PropTypes.string,
    previousPageOnlyRequiredFields: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class FigureProfessionaliSupportoAValutazione extends AccordionFirstLevel {

    state = {
        campiObbligatoriMancanti: 0,
        accordion: {
            openSubAccordionFigureProfessionaliSupportoAValutazione: true
        }
    }

    handleChangeRequiredFieldsNumber = (value) => {
        this.setState({campiObbligatoriMancanti: value}, () => {
            this.props.onChangeObbligatorieta("figureProfessionaliSupportoAllaValutazione", this.state.campiObbligatoriMancanti);
        });
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        let richiesta = _.cloneDeep(this.props.richiesta);
        richiesta.professionistiSupportoAValutazione = this.props.dati;
        this.props.salvaBozza(false, false, richiesta);
        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            })
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"professionistiSupportoAValutazione"}
                title={"Figure professionali di supporto alla valutazione"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                numberOfRequiredFieldsMissing={this.state.campiObbligatoriMancanti}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}>
                <VersionedAccordion accordion={FigureProfessionaliSupportoAValutazioneSottoAccordion}
                                    dati={this.props.dati}
                                    datiNecessitaAssistenzaSanitaria={this.props.datiNecessitaAssistenzaSanitaria}
                                    salvaBozza={this.props.salvaBozza}
                                    richiesta={this.props.richiesta}
                                    onChangeDatiFigureProfessionaliSupportoAValutazione={this.props.onChangeDatiFigureProfessionaliSupportoAValutazione}
                                    openAccordion={this.state.accordion.openSubAccordionFigureProfessionaliSupportoAValutazione}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openSubAccordionFigureProfessionaliSupportoAValutazione"}
                                    pageState={this.props.pageState}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    firstPageName={this.props.firstPageName}
                                    previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />
            </AccordionAvvioRichiesta>
        );
    }
}

FigureProfessionaliSupportoAValutazione.propTypes = propTypes;
