import React from "react";
import PropTypes from "prop-types";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {FieldsRow, RoleBasedInput, RoleBasedPhoneInput} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";

const fieldContatti = 'contatti';

export default function ContattiRichiedente(props) {
    const telefono = props.datiMedico.contatti?.find(e => e.tipo === 'TELEFONO')?.valore
    const cellulare = props.datiMedico.contatti?.find(e => e.tipo === 'CELLULARE')?.valore

    return <AccordionSezioneRichiesta
        title={"Contatti Medico Richiedente"}
        openAccordion={props.openAccordion}
        onClickAccordion={props.onClickAccordion}
        pageState={props.pageState}
        required={true}>
        <FieldsRow>
            <RoleBasedPhoneInput
                fieldId={'TEL.CONTATTIMEDICORICHIEDENTE.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                placeholder={"Telefono"}
                field={"TELEFONO"}
                onChange={(field, elem) => props.onChangeContatti(fieldContatti, field, elem.target.value)}
                id={"basedInputTelefono"}
                value={props.datiMedico.contatti?.find(e => e.tipo === 'TELEFONO')?.valore}
                phonePrefix={null}
                fieldLabel={"Telefono"}
                fieldRequired={(!telefono && !cellulare) || telefono}
                disableDoubleInput={true}
            />
            <RoleBasedPhoneInput
                fieldId={'CELL.CONTATTIMEDICORICHIEDENTE.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                placeholder={"Cellulare"}
                field={"CELLULARE"}
                onChange={(field, elem) => props.onChangeContatti(fieldContatti, field, elem.target.value)}
                id={"basedInputCellulare"}
                value={props.datiMedico.contatti?.find(e => e.tipo === 'CELLULARE')?.valore}
                fieldLabel={"Cellulare"}
                fieldRequired={(!telefono && !cellulare) || cellulare}
                disableDoubleInput={true}
            />
            <RoleBasedPhoneInput
                fieldId={'FAX.CONTATTIMEDICORICHIEDENTE.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                placeholder={"Fax"}
                field={"FAX"}
                onChange={(field, elem) => props.onChangeContatti(fieldContatti, field, elem.target.value)}
                id={"basedInputFax"}
                value={props.datiMedico.contatti?.find(e => e.tipo === 'FAX')?.valore}
                phonePrefix={null}
                fieldLabel={"Fax"}
                disableDoubleInput={true}
            />
            <RoleBasedInput
                fieldId={'EMAIL.CONTATTIMEDICORICHIEDENTE.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                value={props.datiMedico.contatti?.find(e => e.tipo === 'EMAIL')?.valore}
                placeholder={"E-mail"}
                field={"EMAIL"}
                onChange={(field, elem) => props.onChangeContatti(fieldContatti, field, elem.target.value)}
                id={"basedInputEmail"}
                fieldLabel={"E-mail"}
                validator={Utils.isValidEmail}
                fieldRequired={true}
                disableDoubleInput={true}
            />
        </FieldsRow>
    </AccordionSezioneRichiesta>;
}

ContattiRichiedente.propTypes = {
    pageState: PropTypes.string.isRequired,
    datiMedico: PropTypes.object.isRequired,
    editableFields: PropTypes.object.isRequired,
    onChangeContatti: PropTypes.func.isRequired,
    openAccordion: PropTypes.bool.isRequired,
    onClickAccordion: PropTypes.func.isRequired
};
