import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import Statistica from "../../statistica/Statistica";
import SessionRequest from "../../../utils/SessionRequest";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import enumQuestionario from "../../../enum/enumQuestionario";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    dati: PropTypes.any,
    onChangeDatiRespiro: PropTypes.func,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    richiesta: PropTypes.any,
    onHide: PropTypes.func,
    isOptional: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber:  PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultTipologia = {
    id: null,
    tipologia: null,
    altro: null
}

export default class Respiro extends AccordionSecondLevel {

    state = {
        respiro: _.cloneDeep(this.props.dati)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        const respiro = _.cloneDeep(this.props.dati);
        this.setState({respiro}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({respiro}) => {
            if (index === null && subField === null) {
                respiro[field] = elem;
            } else {
                respiro[field][index][subField] = elem;
                if (respiro.tipologia[index]?.tipologia !== "06") {
                    respiro.tipologia[index].altro = null;
                }
            }

            if (!respiro.presenzaAlterazioni) {
                respiro.tipologia = [];
            }

            if (!this.checkPresenzaDispnea(respiro.tipologia)) {
                respiro.mMRC = null;
                respiro.condizioneRilevataDispnea = null;
            }

            if (!respiro.tosse) {
                respiro.tipologiaTosse = null;
                respiro.condizioneRilevataTosse = null;
            }

            return {respiro};
        }, this.validazioneDati)
    }

    validazioneDati = () => {
        let respiro = _.cloneDeep(this.state.respiro);
        this.props.onChangeDatiRespiro("datiRespiro", respiro);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(respiro?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneRespiro", campiObbligatoriRimanenti);
    }
    

    onHide = () => {
        const datiRespiro = _.cloneDeep(SessionRequest.getInitObject('anamnesiFisiologica.datiRespiro'));
        this.setState({respiro: datiRespiro},
            () => {
                this.props.onChangeDatiRespiro("datiRespiro", datiRespiro)
                this.props.onHide();
            });
    }

    /* Tipologia */

    addNewTipologia = () => {
        let respiro = _.cloneDeep(this.state.respiro);
        let type = _.cloneDeep(defaultTipologia);
        type.id = uuid();
        respiro.tipologia.push(type);
        this.setState({respiro: respiro}, () => this.validazioneDati());
    }

    removeTipologia = (index) => {
        let respiro = _.cloneDeep(this.state.respiro);
        respiro.tipologia.splice(index, 1);
        if (!this.checkPresenzaDispnea(respiro.tipologia)) {
            respiro.mMRC = null;
            respiro.condizioneRilevataDispnea = null;
        }
        this.setState({respiro: respiro}, () => this.validazioneDati());
    }

    renderTipologia = (tipologia, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'M_TIPO_DISPNEA.RESPIRO.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={'I_DISPNEA.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                    pageState={this.props.pageState}
                    id={"tipologia" + index}
                    options={OggettiUtili.tipologiaRespiro}
                    value={tipologia.tipologia}
                    onChange={(elem) => this.handleAggiornaForm("tipologia", elem, index, "tipologia")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Tipologia respiro non esistente"}
                    fieldLabel={'Tipologia'}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    handleOnlyValue={true}
                    field={"tipologia"}
                />

                {tipologia.tipologia === "06" &&
                    <RoleBasedInput
                        fieldId={'M_TIPO_DISPNEA.RESPIRO.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_DISPNEA.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        placeholder={"Altro, specificare"}
                        field={"altro"}
                        onChange={(field, elem) => this.handleAggiornaForm("tipologia", elem.target.value, index, "altro")}
                        id={"altro" + index}
                        value={tipologia.altro}
                        fieldLabel={"Altro"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />}

            </FieldsRow>
        );
    }

    checkPresenzaDispnea(tipologie) {
        return tipologie?.some(t => (t.tipologia === "01" || t.tipologia === "02"
            || t.tipologia === "03" || t.tipologia === "04")) ?? true
    }

    renderBody = () => {
        let respiro = this.state.respiro;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_PRES_ALT.RESPIRO.ANAMNESIFISIOLOGICA'}
                        pageState={this.props.pageState}
                        id={"presenzaAlterazioni"}
                        options={OggettiUtili.rispostaBreve}
                        value={respiro.presenzaAlterazioni}
                        onChange={(elem) => this.handleAggiornaForm("presenzaAlterazioni", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Tipologia respiro non esistente"}
                        fieldLabel={'Presenza di alterazioni'}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"presenzaAlterazioni"}
                    />
                </FieldsRow>

                {respiro.presenzaAlterazioni &&
                    <SectionList
                        title={'Tipologia'}
                        data={respiro.tipologia}
                        renderSection={this.renderTipologia}
                        addNewSectionCallback={this.addNewTipologia}
                        removeSectionCallback={this.removeTipologia}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'M_TIPO_DISPNEA.RESPIRO.ANAMNESIFISIOLOGICA'}
                        infermiereKeyFieldId={'I_DISPNEA.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        addButtonVisibilityHandler={!respiro.tipologia?.some(t => !t.tipologia) ?? true}
                        field={'tipologia'}
                        parentJsonPath={"anamnesiFisiologica.datiRespiro.tipologia"}
                    />}

                {this.checkPresenzaDispnea(respiro.tipologia) &&
                    <Statistica
                        fieldId={'M_PUNT_mMRC.RESPIRO.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_PUNT_mMRC.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        nomeLink={"Compila Test mMRC"}
                        nomePunteggio={"Punteggio Test mMRC"}
                        questionario={respiro.mMRC}
                        profileName={'Livello Dispnea'}
                        profileFieldId={'M_LIV_DISPNEA.RESPIRO.ANAMNESIFISIOLOGICA'}
                        profileInfermiereFieldId={'I_LIV_DISPNEA.SISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.mMRC, questionario)}
                        richiesta={this.props.richiesta}
                        pathRichiestaQuestionario={"anamnesiFisiologica.datiRespiro.mMRC"}
                        tipoQuestionario={enumQuestionario.tipoQuestionario.mMRC}
                        profileRequired={false}
                        salvaBozza={this.props.salvaBozza}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        parentJsonPathSubObject={'mmrc'}
                    />}

                <CondizioneRilevataSection
                    id={'propostaAttivitaSuggerimentiDispnea'}
                    ambito={'RESPIRO_DISPNEA'}
                    parentFieldId={'RESPIRO.ANAMNESIFISIOLOGICA'}
                    fieldsSuffix={'DISPNEA'}
                    infParentFieldId={'SISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                    appendRolePrefix={true}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevataDispnea", elem)}
                    valueCondizioneRilevata={this.state.respiro.condizioneRilevataDispnea}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionareDispnea", elem)}
                    valueDaAttenzionare={this.state.respiro.daAttenzionareDispnea}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("propostaAttivitaSuggerimentiDispnea", elem)}
                    valueSuggerimenti={this.state.respiro.propostaAttivitaSuggerimentiDispnea}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePazienteDispnea", elem)}
                    valueAutonomiaPaziente={respiro.autonomiaGestionePazienteDispnea}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiverDispnea", elem)}
                    valueAutonomiaCaregiver={respiro.autonomiaGestioneCaregiverDispnea}
                    fieldJsonPathSuffix={'dispnea'}
                    openCondition={this.checkPresenzaDispnea(respiro.tipologia)}
                />

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_TOSSE.RESPIRO.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_TOSSE.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        id={"tosse"}
                        options={OggettiUtili.rispostaBreve}
                        value={this.state.respiro.tosse}
                        onChange={(elem) => this.handleAggiornaForm("tosse", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Tipologia respiro non esistente"}
                        fieldLabel={'Tosse'}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"tosse"}
                    />

                    {respiro.tosse &&
                        <RoleBasedSelect
                            fieldId={'M_TIPO_TOSSE.RESPIRO.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_TIPO_TOSSE.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                            pageState={this.props.pageState}
                            id={"tipologiaTosse"}
                            options={OggettiUtili.tipologiaTosse}
                            value={this.state.respiro.tipologiaTosse}
                            onChange={(elem) => this.handleAggiornaForm("tipologiaTosse", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Tipologia di tosse non esistente"}
                            fieldLabel={'Tipologia di tosse'}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                            field={"tipologiaTosse"}
                        />}
                </FieldsRow>

                <CondizioneRilevataSection
                    id={'propostaAttivitaSuggerimentiTosse'}
                    ambito={'RESPIRO_TOSSE'}
                    parentFieldId={'RESPIRO.ANAMNESIFISIOLOGICA'}
                    fieldsSuffix={'TOSSE'}
                    infParentFieldId={'VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                    appendRolePrefix={true}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevataTosse", elem)}
                    valueCondizioneRilevata={this.state.respiro.condizioneRilevataTosse}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionareTosse", elem)}
                    valueDaAttenzionare={this.state.respiro.daAttenzionareTosse}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("propostaAttivitaSuggerimentiTosse", elem)}
                    valueSuggerimenti={this.state.respiro.propostaAttivitaSuggerimentiTosse}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePazienteTosse", elem)}
                    valueAutonomiaPaziente={respiro.autonomiaGestionePazienteTosse}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiverTosse", elem)}
                    valueAutonomiaCaregiver={respiro.autonomiaGestioneCaregiverTosse}
                    fieldJsonPathSuffix={'tosse'}
                    openCondition={respiro.tosse}
                />

                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'M_DATA_VAL.RESPIRO.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_DATA_VAL.VALUTAZIONEDELSISTEMARESPIRATORIO.FUNZIONERESPIRATORIA'}
                        pageState={this.props.pageState}
                        id={"respiroDataValutazione"}
                        value={respiro.dataValutazione}
                        onChange={(elem) => this.handleAggiornaForm("dataValutazione", elem.target.value)}
                        errorMessage={"Data valutazione non valida"}
                        fieldLabel={"Data Valutazione"}
                        field={"dataValutazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

            </>
        );
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"respiro"}
            title={"Respiro"}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            onHide={this.onHide}
            fieldsOrderMap={Utils.generateMapPathsObjects(
                ["anamnesiFisiologica.datiRespiro.tipologia",
                    "anamnesiFisiologica.datiRespiro.propostaAttivitaSuggerimenti"],
                [defaultTipologia, defaultProposteAttivitaSuggerimenti]
            )}
            isOptional={this.props.isOptional && isFieldIdWriteable('M_PRES_ALT.RESPIRO.ANAMNESIFISIOLOGICA', this.props.pageState, this.context.forceUserRole)}
            accordionReadOnly={this.props.accordionReadOnly}
            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
        >
            {this.renderBody()}
        </AccordionSezioneRichiesta>
    }
}

Respiro.propTypes = propTypes;

Respiro.defaultProps = {
    isOptional: true,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

Respiro.contextType = RoleBasedContext;
