import React, {Component, Fragment} from "react";
import {Button, Input} from "web-client-archetype";
import Utils from "../../../utils/Utils";
import GestionePasswordADIService from "../../../service/GestionePasswordADIService";
import PropTypes from "prop-types";
import {Redirect} from 'react-router-dom';
import styleGestionePassword from "./GestionePassword.module.css";
import routePath from "../../../utils/route/route-path";
import ModalGestionePassword from "./ModalGestionePassword";
import {mostraSpinner, nascondiSpinner} from "../../../App";
import {
    CONDIZIONE_NUOVA_PASSWORD_DIVERSA_DA_VECCHIA_PASSWORD,
    CONDIZIONE_PASSWORD_CARATTERE_SPECIALE,
    CONDIZIONE_PASSWORD_LENGTH,
    CONDIZIONE_PASSWORD_MAIUSCOLA
} from "./LockPassword";
import * as _ from "lodash";

const propTypes = {
    location: PropTypes.object
}

export default class ModificaPassword extends Component {

    state = {
        oldPassword: null,
        newPassword: null,
        confPassword: null,
        pulsanteConfermaAbilitato: false,
        apriModaleConferma: false,
        redirectTo: null,
    }

    constructor(props) {
        super(props);
    }

    modificaPassword = () => {
        mostraSpinner();
        let msgErrore = "ERRORE";
        let request = {
            oldPassword: this.state.oldPassword,
            newPassword : this.state.newPassword,
            confPassword: this.state.confPassword
        };
        GestionePasswordADIService.modificaPassword(request).subscribe((res) => {
            if (res?.msg === msgErrore) {
                this.apriToasterErrore(res);
                nascondiSpinner();
            } else {
                this.setState({apriModaleConferma: true})
                nascondiSpinner();
            }
        })
    };

    apriToasterErrore = (response) => {
        Utils.toasterFunction({
            status: response?.data?.codice ? response?.data?.codice : response?.status,
            type: "critical",
            text: response?.data?.errors?.pop().message || response?.data?.message
        })
    };

    handleAggiornaState = (field, event) => {
        this.setState({[field]: event.target.value}, this.aggiornaBottoni);
    }

    handleRedirectModale = () => {
        this.setState({redirectTo:{path: routePath.home}})
    }

    aggiornaBottoni = () => {
        let pulsanteConfermaAbilitato = false;
        if (Utils.isPasswordValid(this.state.newPassword) && Utils.isPasswordValid(this.state.oldPassword) &&
            Utils.arePasswordMatch(this.state.newPassword, this.state.confPassword) &&
            !Utils.arePasswordMatch(this.state.oldPassword, this.state.newPassword)) {
            pulsanteConfermaAbilitato = true;
        }
        this.setState({pulsanteConfermaAbilitato: pulsanteConfermaAbilitato})
    }



    render() {
        if (this.state.redirectTo) return <Redirect to={{pathname: this.state.redirectTo.path}}/>;

        return (
            <>
                {this.state.apriModaleConferma && this.renderModale()}

                <div className={styleGestionePassword.foregroundMode.concat(" position-relative pt-4")}>
                    <div className="pl-4 pr-4 mr-3">
                        <div className={"row"}>
                            <h1 className={"pb-2"}>Modifica Password</h1>
                        </div>
                        <div className="row text-align-center mb-3">
                            {this.renderInputPasswords()}
                            {this.renderBottoni()}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderInputPasswords = () => {
        return <Fragment>
            <div className="col">
                <Input
                    parentClass="mb-3 p-0"
                    placeholder="Vecchia Password"
                    field="oldPassword"
                    onChange={this.handleAggiornaState}
                    id="oldPassword"
                    value={this.state.oldPassword}
                    type="password"
                    onValidate={(value) => value && !Utils.isPasswordValid(value)}
                    required
                    label={"Vecchia Password"}
                    infoText="Campo obbligatorio"
                />
            </div>
            <div className="col">
                <Input
                    parentClass="mb-0 p-0"
                    placeholder="Nuova Password"
                    field="newPassword"
                    onChange={this.handleAggiornaState}
                    id="newPassword"
                    value={this.state.newPassword}
                    type="password"
                    onValidate={(value) => value && !Utils.isPasswordValid(value)}
                    required
                    label={"Nuova Password"}
                    infoText="Campo obbligatorio"
                />
                {this.renderTestoInformativo()}
            </div>
            <div className="col">
                <Input
                    parentClass="mb-3 p-0"
                    placeholder="Conferma Password"
                    field="confPassword"
                    onChange={this.handleAggiornaState}
                    id="confPassword"
                    value={this.state.confPassword}
                    type="password"
                    onValidate={(value) => value && !Utils.arePasswordMatch(this.state.newPassword, value) && "*Le password non coincidono"}
                    required
                    label={"Conferma Password"}
                    infoText="Campo obbligatorio"
                />
            </div>
        </Fragment>;
    }

    renderBottoni = () => {
        return <div className="col-auto mt-1">
            <Button
                id={"conferma"}
                text="Conferma"
                className={" btn btn-adi btnGreenOutline float-right"}
                style={{outline: "none"}}
                disabled={!this.state.pulsanteConfermaAbilitato}
                onClick={() => this.modificaPassword()}
            />
        </div>;
    }

    renderTestoInformativo = () => {
        return <div>
            {!Utils.isValidStringMinLength(this.state.newPassword, 9) && CONDIZIONE_PASSWORD_LENGTH}
            {!Utils.isValidAlphanumericMaiuscola(this.state.newPassword) && CONDIZIONE_PASSWORD_MAIUSCOLA}
            {!Utils.isValidCaratteriSpeciali(this.state.newPassword) && CONDIZIONE_PASSWORD_CARATTERE_SPECIALE}
            {_.isEqual(this.state.newPassword, this.state.oldPassword) && CONDIZIONE_NUOVA_PASSWORD_DIVERSA_DA_VECCHIA_PASSWORD}
        </div>;
    }

    renderModale = () => {
        return <ModalGestionePassword
            body={"La password è stata modificata correttamente"}
            handleOnClickModale={this.handleRedirectModale}
            btnText={"OK"}
            renderFooter={true}
            title={"Operazione Eseguita!"}
        />;
    }
}

ModificaPassword.propTypes = propTypes;
