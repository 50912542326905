import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import {v4 as uuid} from "uuid";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    mammelle: PropTypes.any,
    onChangeEsameObiettivo: PropTypes.func,
    pageState: PropTypes.any,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const mammellaDx = "Dx";
const mammellaSx = "Sx";

const defaultAlterazione = {
    id: null,
    tipologiaDiAlterazione: null,
    sedi: []
};

const defaultSede = {
    id: null,
    codice: null
}

export default class Mammelle extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.mammelle)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.mammelle, this.props.mammelle))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.mammelle);
        this.setState({dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index, posizione) => {
        this.setState(({dati}) => {
            if (field === "tipologiaDiAlterazione") {
                if (posizione === mammellaDx)
                    dati.alterazioniDx[index].tipologiaDiAlterazione = elem
                else
                    dati.alterazioniSx[index].tipologiaDiAlterazione = elem
            } else {
                dati[field] = elem;
            }

            if (!dati.presenzaDiAlterazioniMammellaDx) {
                dati.alterazioniDx = [];
            }
            if (!dati.presenzaDiAlterazioniMammellaSx) {
                dati.alterazioniSx = [];
            }

            if (!dati.presenzaDiAlterazioniMammellaDx && !dati.presenzaDiAlterazioniMammellaSx) {
                dati.condizioneRilevata = null;
            }
            return {dati};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        const dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("mammelle", dati);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneMammelle", campiObbligatoriRimanenti);
    }

    isAlterazioneValida = (alterazione) => {
        return !Utils.isObjectNull(alterazione) && !Utils.isStringEmptyOrNullOrUndefined(alterazione?.tipologiaDiAlterazione);
    }

    /* Lista alterazioni mammella */
    addNewAlterazioneMammella = (posizione) => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultAlterazione)
        type.id = uuid();
        dati["alterazioni" + posizione].push(type)
        this.setState({dati}, () => this.props.onChangeEsameObiettivo("mammelle", dati));
    }

    removeAlterazioneMammella = (index, posizione) => {
        let dati = _.cloneDeep(this.state.dati);
        dati["alterazioni" + posizione].splice(index, 1);
        this.setState({dati}, () => this.props.onChangeEsameObiettivo("mammelle", dati));
    }

    renderAlterazione = (alterazione, index, posizione, suffix) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedICD9Input
                        fieldId={'TIPO_ALT_MAMM_' + posizione.toUpperCase() + '.MAMMELLE.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        value={alterazione.tipologiaDiAlterazione}
                        field={"tipologiaDiAlterazione"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index, posizione)}
                        id={"autocompleteTipologiaDiAlterazione" + posizione.toUpperCase()}
                        fieldLabel={"Tipo di alterazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                </FieldsRow>

                {this.isAlterazioneValida(alterazione) &&
                    <SectionList
                        title={'Sede'}
                        data={alterazione.sedi}
                        renderSection={(sede, indexSede) => this.renderSede(sede, indexSede, index, posizione)}
                        addNewSectionCallback={() => this.addNewSede(index, posizione)}
                        removeSectionCallback={(indexSede) => this.removeSede(indexSede, index, posizione)}
                        addButtonVisibilityHandler={alterazione.sedi?.every(s => !!s.codice)}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        indentOffset={2}
                        field={'sedi'}
                        keyFieldId={'SEDE_ALT_MAMM_SX.MAMMELLE.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        parentJsonPath={"esameObiettivo.mammelle.alterazioni" + suffix + "[?(@.id == '" + alterazione?.id + "')].sedi"}
                    />}
            </>
        );
    }

    renderSede = (sede, index, parentIndex, posizione) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'SEDE_ALT_MAMM_SX.MAMMELLE.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    id={"selectSedeSx" + index}
                    options={OggettiUtili.sedeMammella}
                    value={sede.codice}
                    onChange={(elem) => this.handleAggiornaSede(elem, index, parentIndex, posizione)}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Sede non esistente"}
                    fieldLabel={"Sede"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    handleOnlyValue={true}
                    field={"codice"}
                />
            </FieldsRow>
        );
    }

    handleAggiornaSede = (elem, index, parentIndex, posizione) => {
        let dati = _.cloneDeep(this.state.dati);
        dati["alterazioni" + posizione][parentIndex].sedi[index].codice = elem;
        this.setState({dati}, () => this.props.onChangeEsameObiettivo("mammelle", dati));
    }

    addNewSede = (parentIndex, posizione) => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultSede)
        type.id = uuid();
        dati["alterazioni" + posizione][parentIndex].sedi.push(type);
        this.setState({dati}, () => this.props.onChangeEsameObiettivo("mammelle", dati));
    }

    removeSede = (index, parentIndex, posizione) => {
        let dati = _.cloneDeep(this.state.dati);
        dati["alterazioni" + posizione][parentIndex].sedi.splice(index, 1);
        this.setState({dati}, () => this.props.onChangeEsameObiettivo("mammelle", dati));
    }

    handleDispositiviArrayChange = (array, field) => {
        let dati = _.cloneDeep(this.state.dati);
        dati[field] = array;
        this.setState({dati}, () => {
            this.props.onChangeEsameObiettivo("mammelle", dati);
            this.forceUpdate();
        });
    }

    renderBodySezione = () => {
        const dati = this.state.dati;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PRES_ALT_MAMM_DX.MAMMELLE.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"selectPresenzaDiAlterazioni"}
                        options={OggettiUtili.rispostaBreve}
                        value={dati.presenzaDiAlterazioniMammellaDx}
                        onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioniMammellaDx", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                        fieldLabel={"Presenza di alterazioni mammella dx"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"presenzaDiAlterazioniMammellaDx"}
                    />
                </FieldsRow>

                {dati.presenzaDiAlterazioniMammellaDx &&
                    <SectionList
                        title={'Tipo di alterazione destra'}
                        data={dati.alterazioniDx}
                        renderSection={(e, i) => this.renderAlterazione(e, i, mammellaDx, 'Dx')}
                        addNewSectionCallback={() => this.addNewAlterazioneMammella(mammellaDx)}
                        removeSectionCallback={(i) => this.removeAlterazioneMammella(i, mammellaDx)}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'TIPO_ALT_MAMM_DX.MAMMELLE.ESAMEOBIETTIVO'}
                        field={'alterazioniDx'}
                        addButtonVisibilityHandler={dati.alterazioniDx?.every(a => !!a.tipologiaDiAlterazione)}
                        parentJsonPath={"esameObiettivo.mammelle.alterazioniDx"}
                    />}

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PRES_ALT_MAMM_SX.MAMMELLE.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"selectPresenzaDiAlterazioni"}
                        options={OggettiUtili.rispostaBreve}
                        value={dati.presenzaDiAlterazioniMammellaSx}
                        onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioniMammellaSx", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                        fieldLabel={"Presenza di alterazioni mammella sx"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"presenzaDiAlterazioniMammellaSx"}
                    />
                </FieldsRow>

                {dati.presenzaDiAlterazioniMammellaSx &&
                    <SectionList
                        title={'Tipo di alterazione sinistra'}
                        data={dati.alterazioniSx}
                        renderSection={(e, i) => this.renderAlterazione(e, i, mammellaSx, 'Sx')}
                        addNewSectionCallback={() => this.addNewAlterazioneMammella(mammellaSx)}
                        removeSectionCallback={(i) => this.removeAlterazioneMammella(i, mammellaSx)}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'TIPO_ALT_MAMM_SX.MAMMELLE.ESAMEOBIETTIVO'}
                        field={'alterazioniSx'}
                        addButtonVisibilityHandler={dati.alterazioniSx?.every(a => !!a.tipologiaDiAlterazione)}
                        parentJsonPath={"esameObiettivo.mammelle.alterazioniSx"}
                    />}

                <DispositiviPresenti
                    handleArrayChange={this.handleDispositiviArrayChange}
                    dispositiviPresenti={dati.dispositiviPresenti}
                    pageState={this.props.pageState}
                    fieldId={"DISPOSITIVI.MAMMELLE.ESAMEOBIETTIVO"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    parentJsonPath={"esameObiettivo.mammelle.dispositiviPresenti"}
                />

                <CondizioneRilevataSection
                    ambito={'MAMMELLE'}
                    parentFieldId={'MAMMELLE.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={dati.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={dati.daAttenzionare}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                    showSuggerimenti={true}
                    openCondition={(dati.presenzaDiAlterazioniMammellaDx || dati.presenzaDiAlterazioniMammellaSx)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"mammelle"}
                    title={"Mammelle" + (Utils.isStringEmptyOrNullOrUndefined(this.props.sessoPaziente) ? " !!!" : "")}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.mammelle.alterazioniDx", "esameObiettivo.mammelle.alterazioniSx",
                            "esameObiettivo.mammelle.alterazioniDx.sedi", "esameObiettivo.mammelle.alterazioniSx.sedi",
                            "esameObiettivo.mammelle.dispositiviPresenti",
                            "esameObiettivo.mammelle.propostaAttivitaSuggerimenti"],
                        [defaultAlterazione, defaultAlterazione, defaultSede, defaultSede,
                            defaultDispositiviPresenti, defaultProposteAttivitaSuggerimenti]
                    )}
                    accordionReadOnly={this.props.accordionReadOnly}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}

Mammelle.propTypes = propTypes;

Mammelle.defaultProps = {
    showAccordion: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

