import React, {Component} from "react";
import Utils from "../../../utils/Utils";
import $ from "jquery";
import {Button} from "web-client-archetype";
import {Link} from "react-router-dom";
import routepath from "../../../utils/route/route-path.json";
import PropTypes from "prop-types";
import SintesiRichiesta from "./sintesiRichiesta/SintesiRichiesta";
import * as _ from "lodash";
import styles from "./VisualizzaDettagliRichiestaStorico.module.css";
import RichiestaADIService from "../../../service/RichiestaADIService";
import {mostraSpinner, nascondiSpinner} from "../../../App";
import Breadcrumb from "../../breadcrumb/Breadcrumb";
import ButtonsBoxStick from "../../buttonsBoxStick/buttonsBoxStick";

const propTypes = {
    uuidRichiesta: PropTypes.string
}

export default class VisualizzaDettagliRichiestaStorico extends Component {
    state = {
        datiRichiesta: {
            id: null,
            datiAssistito: {
                cognome: null,
                nome: null,
                dataNascita: null,
                codiceFiscale: null,
                tesseraSanitaria: null,
                residenzaDomicilio: {
                    aulssResidenza: null,
                    distrettoResidenza: null,
                    aulssDomicilio: null,
                    distrettoDomicilio: null,
                    aulssCura: null,
                    distrettoCura: null
                }
            },
            datiRichiesta: {
                medicoRichiedente: null,
                dataRichiesta: null,
                dataAnnullamentoRichiesta: null,
                motivazioneAnnullamentoRichiesta: null
            },
            datiValutazioneProfessionale: {
                data: null
            },
            professionisti: [],
            datiValutazioneMultidimensionale: {
                data: null
            },
            datiFormalizzazione: {
                data: null
            },
            datiCureDomiciliari: {
                dataInizioCureDomiciliari: null,
                dataFineCureDomiciliari: null,
                motivazioneConclusioneCureDomiciliari: null
            }
        },
        accordion: {
            openAccordionAssistito: true,
            openAccordionRichiesta: true,
            openAccordionValutazioneProfessionale: true,
            openAccordionProfessionisti: true,
            openAccordionValutazioneMultidimensionale: true,
            openAccordionFormalizzazione: true,
            openAccordionCureDomiciliari: true
        }
    }

    componentDidMount() {
        this.montaComponente();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        $("div.collapse-body").addClass("ml-2");
    }

    componentWillUnmount() {
        $("div.collapse-body").removeClass("ml-2");
    }

    montaComponente = () => {
        mostraSpinner();
        RichiestaADIService.visualizzaDettaglioRichiestaStorico(this.props.uuidRichiesta).subscribe(response => {
            if (response?.msg === "ERRORE") {
                if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                    });
                }
            }
            if (response?.data) this.setState({datiRichiesta: this.mapObjectDatiRichiesta(response.data)});
            nascondiSpinner();
        });
    }

    mapObjectDatiRichiesta = (data) => {
        let dettaglioRichiesta = _.cloneDeep(this.state.datiRichiesta);
        dettaglioRichiesta.id = data.id;

        const assistito = data.datiAssistito;
        dettaglioRichiesta.datiAssistito = {
            cognome: assistito.cognome,
            nome: assistito.nome,
            dataNascita: assistito.dataNascita,
            codiceFiscale: assistito.codiceFiscale,
            tesseraSanitaria: assistito.tesseraSanitaria,
            residenzaDomicilio: {
                aulssResidenza: assistito.aulssResidenza,
                distrettoResidenza: assistito.distrettoResidenza,
                aulssDomicilio: assistito.aulssDomicilio,
                distrettoDomicilio: assistito.distrettoDomicilio,
                aulssCura: assistito.aulssCura,
                distrettoCura: assistito.distrettoCura
            }
        };

        dettaglioRichiesta.datiRichiesta = data.datiRichiesta;
        dettaglioRichiesta.datiValutazioneProfessionale.data = data.datiValutazioneProfessionale?.dataValutazioneProfessionale ?? null;
        dettaglioRichiesta.professionisti = data.professionisti;
        dettaglioRichiesta.datiValutazioneMultidimensionale.data = data.dataValutazioneMultidimensionale;
        dettaglioRichiesta.datiFormalizzazione.data = data.dataFormalizzazione;
        dettaglioRichiesta.datiCureDomiciliari = data.datiCureDomiciliari ?? dettaglioRichiesta.datiCureDomiciliari;
        return dettaglioRichiesta;
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;
        if (accordion[field]) this.setState({accordion});
    }

    formatAccordionTitle = (nomeSezione) => {
        if (nomeSezione.toLowerCase().includes("professionisti"))
            return "Professionisti che hanno effettuato la Valutazione multidimensionale";
        else return Utils.addSpaceBetweenCamelCaseString(nomeSezione);
    }

    renderSezioniDettaglio = () => {
        let datiClone = _.cloneDeep(this.state.datiRichiesta);
        delete datiClone.id;
        return <div className={[styles.accordionDettaglio, "mx-5"].join(" ")}>
            {datiClone && Object.keys(datiClone)
                .map(sezione => {
                    let sezioneLabel = sezione.replace("dati", '');
                    sezioneLabel = sezioneLabel.charAt(0).toUpperCase() + sezioneLabel.slice(1);
                    return !Utils.isObjectNull(datiClone[sezione])
                        ? <SintesiRichiesta
                            key={sezione}
                            datiSezione={datiClone[sezione]}
                            idAccordion={sezione}
                            titleAccordion={this.formatAccordionTitle(sezione)}
                            openAccordion={this.state.accordion[`openAccordion${sezioneLabel}`]}
                            onClickAccordion={this.onClickAccordion}
                            accordionField={`openAccordion${sezioneLabel}`}
                        />
                        : <></>
                })}
        </div>
    }

    renderBackButton = () => {
        return (
            <ButtonsBoxStick
                parentClass={"d-flex justify-content-between"}
                btnList={[
                    {
                        id: "indietroBtn",
                        text: "Indietro",
                        className: "btn btn-adi btnWhiteOutlineBorderGreen",
                        isLink: true,
                        to: routepath.storico_richieste,
                        isvisible: true
                    }
                ]}
            />
        );
    }

    render() {
        return (
            <>
                <Breadcrumb path={['Home', 'Storico richieste', 'Visualizza maggiori dettagli']} profileAction={true}/>
                <div className={[styles.customContainer, "pt-2"].join(" ")}>
                    {this.renderSezioniDettaglio()}
                </div>
                {this.renderBackButton()}
            </>
        );
    }
}

VisualizzaDettagliRichiestaStorico.propTypes = propTypes;
