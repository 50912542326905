import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, SessioneUtente} from "web-client-archetype";
import ModalHelper from "../../modale/ModalHelper";
import RichiestaADIService from "../../../service/RichiestaADIService";
import {Link} from "react-router-dom";
import routePath from "../../../utils/route/route-path.json";
import _ from "lodash";
import Utils, {closeModaleStyle} from "../../../utils/Utils";
import {mostraSpinner, nascondiSpinner} from "../../../App";
import errors from "../../../utils/errors/errors.json";

const propTypes = {
    uuidRichiesta: PropTypes.string,
    timestampRiapertura: PropTypes.string,
    variazioniPresenti: PropTypes.bool,
    chiudiPopupConfermaValutazione: PropTypes.func,
    showPopup: PropTypes.bool,
    disabilitaConfermaValutazione: PropTypes.func,
    cfRichiedenteRichiesta: PropTypes.string,
    sostituzioneRichiedenteRichiesta : PropTypes.bool,
    cfProfessonistaAdiForRichiesta: PropTypes.string,
    cfUtente: PropTypes.string
}

const bodyModaleText = {
    topMultidimensionale: "Stai per procedere con la conferma della valutazione multidimensionale. \n",
    topProfessionale: "Stai per procedere con la conferma della valutazione professionale. \n",
    nessunaVariazioneTop:"Nessun dato è stato modificato.\n",
    down: "Vuoi confermare l'operazione?",
    nessunaVariazioneDown:" Vuoi procedere con la conferma della valutazione multidimensionale?"
};
const bodyModaleOperazioneEseguitaTextProf = "La valutazione professionale è stata correttamente confermata.";
const bodyModaleOperazioneEseguitaTextMulti = "La valutazione multidimensionale è stata correttamente confermata.";

const titleModale = "Attenzione!";
const titleModaleOperazioneEseguita = "Operazione completata";

const idModale = "modaleConfermaValutazione";
const idModaleOperazioneEseguita = "modaleConfermaValutazioneOperazioneEseguita";

export default class PopupConfermaValutazione extends Component {
    state = {
        showModaleOperazioneEseguita: false,
        pdfBtnDisabled: false
    }

    annullaConfermaValutazione = () => {
        closeModaleStyle();
        this.props.chiudiPopupConfermaValutazione();
    }

    showOperazioneEseguita = () => {        
        this.setState({ showModaleOperazioneEseguita: true });
        this.props.disabilitaConfermaValutazione();
    }

    confermaConfermaValutazione = () => {
        let uuidRichiesta = this.props.uuidRichiesta;
        mostraSpinner();
        RichiestaADIService.confermaValutazione(uuidRichiesta).subscribe((response) => {
            if (response?.msg === "ERRORE") {                
                if(response?.msgCode && response.msgCode.startsWith("WARNING_WD")){
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "confirm",
                        text: errors[response?.msgCode]?.message || response?.msgCode
                    });
                     //l'operazione è stata comunque eseguita, quindi proseguo come per un ok
                } else if(response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_NON_INVIATO")) {
                    //non deve più farlo ma bisogna comunque gestire il caso
                    //this.setState({ pdfBtnDisabled: true });                    
                } else if(response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_IN_ERRORE")) {
                    this.annullaConfermaValutazione();
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: "Errore nella creazione del CDA2"
                    });                    
                    nascondiSpinner();
                    return;
                } else {
                    this.annullaConfermaValutazione();
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                    });
                    nascondiSpinner();
                    return;
                }                
            }            
            this.showOperazioneEseguita();
            nascondiSpinner();            
        });
    }

    tornaHome = () => {
        this.annullaConfermaValutazione();
        this.setState({ showModaleOperazioneEseguita: false })
    }

    downloadPdf = () => {
        let uuidRichiesta = this.props.uuidRichiesta;
        //TODO ci va una if, se tra i ruoli c'è il richiedente ci va STEP_CDA2_VALUTAZIONE_MEDICO_RICHIEDENTE, altrimenti STEP_CDA2_INVIO_VALUTAZIONE
        let step = SessioneUtente.getInstance().settings.ruolo === "ADI-RIC" || SessioneUtente.getInstance().settings.ruolo === "ADI-FOR" ? "STEP_CDA2_VALUTAZIONE_MEDICO_RICHIEDENTE" : "STEP_CDA2_INVIO_VALUTAZIONE";
        RichiestaADIService.generaPdf(uuidRichiesta, step).subscribe((response) => {            
            if (response?.msg === "ERRORE") {       
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "confirm",
                    text: "Il fascicolo sanitario elettronico non è al momento disponibile. Si prega di riprovare più tardi"
                });         
            } else {
                const linkSource = `data:${response.data.contentType};base64,${response.data.file}`;
                const downloadLink = document.createElement("a");
                const fileName = response.data.fileName;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();                        
            }                        
        });
    }



    renderModale = () => {
        let state = _.cloneDeep(this.state);
        let bodyModale = null;
        let bodyModaleOperazioneEseguita = null;
        if (this.props.timestampRiapertura && this.props.variazioniPresenti === false)
        {
            bodyModale = (
                <div>
                    {bodyModaleText.nessunaVariazioneTop}
                    <strong>{bodyModaleText.nessunaVariazioneDown}</strong>
                </div>
            );
            
            bodyModaleOperazioneEseguita = (
                <div>{bodyModaleOperazioneEseguitaTextMulti}</div>
            );
        }
        else if(this.props.cfUtente===this.props.cfRichiedenteRichiesta ||
            (this.props.sostituzioneRichiedenteRichiesta && this.props.cfUtente===this.props.cfProfessonistaAdiForRichiesta))
        {
             bodyModale = (
            <div>
                {bodyModaleText.topMultidimensionale}
                <strong>{bodyModaleText.down}</strong>
            </div>
        );

            bodyModaleOperazioneEseguita = (
                <div>{bodyModaleOperazioneEseguitaTextMulti}</div>
            );
        }
        else
        {
            bodyModale = (
                <div>
                    {bodyModaleText.topProfessionale}
                    <strong>{bodyModaleText.down}</strong>
                </div>
            );

            bodyModaleOperazioneEseguita = (
                <div>{bodyModaleOperazioneEseguitaTextProf}</div>
            );

        }

        const getBody = () => (
            <>{state.showModaleOperazioneEseguita ? bodyModaleOperazioneEseguita : bodyModale}</>
        );

        const footerModale = (
            <>
                <Button
                    onClick={() => this.confermaConfermaValutazione()}
                    id={"confermaBtn"}
                    text="Conferma"
                    className={"btn btn-adi btnOrangeOutline"}
                />
                <Button
                    onClick={() => this.annullaConfermaValutazione()}
                    id={"annullaBtn"}
                    text="Annulla"
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    disabled={false}
                />
            </>
        );

        const footerModaleOperazioneEseguita = (
            <>
                <Button
                    onClick={() => this.downloadPdf()}
                    id={"visualizzaPdfBtn"}
                    text="Visualizza Pdf"
                    className={"btn btn-adi btnGreenOutline"}
                    disabled={state.pdfBtnDisabled} 
                />
                <Link to={{ pathname: routePath.home }}>
                    <Button
                        onClick={() => this.tornaHome()}
                        id={"tornaHomeBtn"}
                        text="Torna a Home"
                        className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                        disabled={false}
                    />
                </Link>
            </>
        );

        const getFooter = () => (
            <> {state.showModaleOperazioneEseguita ? footerModaleOperazioneEseguita : footerModale} </>
        )

        function getTitle() {
            if (state.showModaleOperazioneEseguita) return titleModaleOperazioneEseguita;
            else return titleModale;
        }

        function getId() {
            if (state.showModaleOperazioneEseguita) return idModaleOperazioneEseguita;
            else return idModale;
        }

        function getBackgroundStyle() {
            if (state.showModaleOperazioneEseguita) return "backgroundGreen";
            else return "backgroundOrange";
        }

        return (
            <>
                <ModalHelper
                    id={getId()}
                    title={getTitle()}
                    titleClassName={("modal-header d-inline text-center ").concat(getBackgroundStyle())}
                    containerClassName={("modal-dialog modal-dialog-scrollable modal modal-dialog-centered")}
                    contentClassName={"modal-content"}
                    body={getBody()}
                    footer={getFooter()}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={(" modal-body d-flex align-items-center text-center m-auto")}
                />
            </>
        );
    }

    render() {
        return (
            <>
                {this.renderModale()}
            </>
        )
    }

}

PopupConfermaValutazione.propTypes = propTypes;
