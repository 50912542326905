import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionHelper from "../../service/AccordionHelper";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import Allergie from "./allergie/Allergie";
import UltimoAccessoPS from "./ultimoAccessoPS/UltimoAccessoPS";
import InfoUltimoRicovero from "./infoUltimoRicovero/InfoUltimoRicovero";
import Utils from "../../utils/Utils";
import VersionedAccordion from "../VersionedAccordion";
import enumRichiesta from "../../enum/enumRichiesta";
import PunteggioDiBrass from "../informazioniBaseFaseRichiesta/punteggioDiBrass/punteggioDiBrass";
import AuthUtils from "../../utils/AuthUtils";

const propTypes = {
    datiInformazioniBaseFaseValutazione: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiInformazioniBaseFaseValutazione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    sessoPaziente: PropTypes.string,
    onChangeObbligatorieta: PropTypes.func,
    accordionSubtitle: PropTypes.string,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

export default class InformazioniBaseFaseValutazione extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: {
            informazioniSuUltimoRicovero: 0,
            punteggioDiBrass: 0,
            sottoSezioneAllergie: 0
        },
        accordion: {
            openAccordionInformazioniSuUltimoRicovero: true,
            openAccordionUltimoAccessoPS: true,
            openAccordionAllergie: true,
            openAccordionPunteggioDiBrass: true
        }
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        let campiObbligatoriMancanti = this.state.campiObbligatoriMancanti;
        campiObbligatoriMancanti[field] = value;
        this.setState({campiObbligatoriMancanti: campiObbligatoriMancanti}, () => {
            this.props.onChangeObbligatorieta(enumRichiesta.attributi.informazioniBaseFaseValutazione, Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta[enumRichiesta.attributi.informazioniBaseFaseValutazione] = this.props[enumRichiesta.attributi.informazioniBaseFaseValutazione];
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    renderBody = () => {
        let utenteLoggatoInfermiere = AuthUtils.hasUtenteRuoloInfermiere();
        return (
            <>
                <VersionedAccordion accordion={InfoUltimoRicovero}
                    datiInfoUltimoRicovero={this.props.datiInformazioniBaseFaseValutazione.infoUltimoRicovero}
                    onChangeDatiInfoUltimoRicovero={this.props.onChangeDatiInformazioniBaseFaseValutazione}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    openAccordion={this.state.accordion.openAccordionInformazioniSuUltimoRicovero}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionInformazioniSuUltimoRicovero"}
                    mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                    pageState={this.props.pageState}
                    accordionReadOnly={this.props.accordionReadOnly}/>

                <VersionedAccordion accordion={Allergie}
                    datiAllergie={this.props.datiInformazioniBaseFaseValutazione.allergie}
                    onChangeDatiAllergie={this.props.onChangeDatiInformazioniBaseFaseValutazione}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    openAccordion={this.state.accordion.openAccordionAllergie}
                    onClickAccordion={this.onClickAccordion}
                    mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                    field={"openAccordionAllergie"}
                    pageState={this.props.pageState}
                    accordionReadOnly={this.props.accordionReadOnly}
                    disableDoubleInput={utenteLoggatoInfermiere}
                />

                <VersionedAccordion accordion={UltimoAccessoPS}
                    datiUltimoAccessoPS={this.props.datiInformazioniBaseFaseValutazione.ultimoAccessoPS}
                    onChangeDatiUltimoAccessoPS={this.props.onChangeDatiInformazioniBaseFaseValutazione}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    openAccordion={this.state.accordion.openAccordionUltimoAccessoPS}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionUltimoAccessoPS"}
                    mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                    pageState={this.props.pageState}
                    accordionReadOnly={this.props.accordionReadOnly}
                    disableDoubleInput={utenteLoggatoInfermiere}
                />

                {Utils.isSegnalazione(this.props.richiesta?.tipologia) &&
                   <VersionedAccordion accordion={PunteggioDiBrass}
                   datiPunteggioDiBrass={this.props.datiInformazioniBaseFaseValutazione.punteggioDiBrass}
                   onChangeDatiPunteggioDiBrass={this.props.onChangeDatiInformazioniBaseFaseValutazione}
                   onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                   openAccordion={this.state.accordion.openAccordionPunteggioDiBrass}
                   onClickAccordion={this.onClickAccordion}
                   field={"openAccordionPunteggioDiBrass"}
                   mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                   pageState={this.props.pageState}
                   salvaBozza={this.props.salvaBozza}
                   accordionReadOnly={this.props.accordionReadOnly}
               />}
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={enumRichiesta.attributi.informazioniBaseFaseValutazione}
                title={"Informazioni di base"}
                subtitle={this.props.accordionSubtitle}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBody()}</AccordionAvvioRichiesta>
        );
    }
}

InformazioniBaseFaseValutazione.propTypes = propTypes;
InformazioniBaseFaseValutazione.defaultProps = {
    accordionSubtitle: "(Ricoveri, Accessi al Pronto soccorso, Allergie)"
}
