import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {
    isFieldIdWriteable,
    RoleBasedSelect,
    RoleBasedText
} from "../roleBasedComponents/RoleBasedComponents";
import {DropdownIndicator, inputSelectStyles} from "../common/select/SelectUtils";
import routepath from "../../utils/route/route-path";
import {
    defaultQuestionario,
    getSezioneQuestionario,
    getSezioneValutazione,
    setRichiestaQuestionario
} from "../../utils/QuestionariUtils";
import enumQuestionario from "../../enum/enumQuestionario";
import {Redirect} from "react-router-dom";
import styles from "./Statistica.module.css";
import enumsUtente from '../../enum/enumsUtente.json'
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import Utils from '../../utils/Utils'
import AccordionHelper from "../../service/AccordionHelper";
import InfoPopupEsitoQuestionario from "./infoPopupEsitoQuestionario";
import esitoQuestionario from "../../utils/dataset/questionariData/esitoQuestionario.json";
import LinkQuestionario from "./LinkQuestionario";


const questionariFaccoltativi = [
    enumQuestionario.tipoQuestionario.IADL,
    enumQuestionario.tipoQuestionario.NOPPAIN,
    enumQuestionario.tipoQuestionario.TINETTI,
    enumQuestionario.tipoQuestionario.PASSI,
    enumQuestionario.tipoQuestionario.mMRC,
    enumQuestionario.tipoQuestionario.MNA,
    enumQuestionario.tipoQuestionario.AUDITC,
    enumQuestionario.tipoQuestionario.AUDIT,
    enumQuestionario.tipoQuestionario.FRAGERSTROM,
    enumQuestionario.tipoQuestionario.CAT,
    enumQuestionario.tipoQuestionario.MMAS4,
    enumQuestionario.tipoQuestionario.MMAS8,
    
    
]

const propTypes = {
    pageState: PropTypes.string,

    nomeLink: PropTypes.string,

    fieldId: PropTypes.string,
    nomePunteggio: PropTypes.string,
    questionario: PropTypes.any,

    onChange: PropTypes.func,

    profileName: PropTypes.string,
    profileRequired: PropTypes.bool,
    profileFieldId: PropTypes.string,

    richiesta: PropTypes.object,
    pathRichiestaQuestionario: PropTypes.string,
    tipoQuestionario: PropTypes.string,
    idSezioniPunteggiIntermedi: PropTypes.arrayOf(PropTypes.number),
    sezioniPunteggiRequired: PropTypes.arrayOf(PropTypes.bool),

    sessoPaziente: PropTypes.any, //utile per calcolo valutazione AUDIT-C
    salvaBozza: PropTypes.func,
    hideUnsetValues: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    fieldInAccordionReadOnly: PropTypes.bool,

    infermiereFieldId: PropTypes.string,
    profileInfermiereFieldId: PropTypes.string,
    disableDoubleInput: PropTypes.bool,
    parentJsonPath: PropTypes.string,
    parentJsonPathSubObject: PropTypes.string,

    scoreMandatoryForRole: PropTypes.string,
    scoresMandatory: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.bool), PropTypes.bool]),

    compilaQuestionarioObbligatorio:  PropTypes.bool,
    obbligatorieta: PropTypes.object,

    fieldIdParent: PropTypes.string,
    altreInfo:  PropTypes.node
}

const defaultProps = {
    profileRequired: true,
    tipoQuestionario: enumQuestionario.tipoQuestionario.PCOG,
    sezioniPunteggiRequired: [],
    forceReadOnly: false,
    fieldInAccordionReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false,
    scoreMandatoryForRole: enumsUtente.ruoli.infermiere,
    compilaQuestionarioObbligatorio: false,
    obbligatorieta: null,
    altreInfo: null
}

export default class Statistica extends Component {

    state = {
        questionario: null,
        richiesta: null,
        redirectToCompilaQuestionario: false,
        scoresMandatory: this.props.richiesta?.valutazioni?.some(v => v.valutatore?.ruolo === this.props.scoreMandatoryForRole) && Utils.isActiveRole(this.props.scoreMandatoryForRole)
    };

    opzioniValutazione = []; // profili in base al punteggio

    componentDidMount() {
        this.setOpzioniValutazione();
        let richiesta = _.cloneDeep(this.props.richiesta);
        let questionario = _.cloneDeep(this.props.questionario);
        if (this.props.pathRichiestaQuestionario) {
            questionario = questionario ?? _.cloneDeep(defaultQuestionario);
            setRichiestaQuestionario(richiesta, questionario, this.props.pathRichiestaQuestionario);
        }
        this.setState({questionario: questionario, richiesta: richiesta});
    }

    setOpzioniValutazione() {
        this.opzioniValutazione = getSezioneValutazione(this.props.tipoQuestionario)
            .punteggi
            .filter(punteggio => !!punteggio.valori)
            .flatMap(punteggio => punteggio.valori)
            .map(valore => ({label: valore.label, value: valore.label}));
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.questionario, this.props.questionario)) {
            // se cambia il questionario modifico anche lo stato della richiesta
            let richiesta = _.cloneDeep(this.state.richiesta);
            setRichiestaQuestionario(richiesta, this.props.questionario, this.props.pathRichiestaQuestionario);
            this.setState({questionario: this.props.questionario, richiesta: richiesta});
        }
        if (!_.isEqual(prevProps.richiesta, this.props.richiesta)) {
            this.setState({richiesta: this.props.richiesta});
        }

        AccordionHelper.onScrollIntoAccordion(AccordionHelper.lastSurveyCompiled);
    }

    handleChange = (field, value) => {
        let questionario;
        if(field === "valutazione" && this.props.tipoQuestionario === enumQuestionario.tipoQuestionario.BRASS) {
            questionario = {
                ..._.cloneDeep(defaultQuestionario),
                sezioni: _.cloneDeep(this.state.questionario).sezioni.map(function (el) {return {...el, domande: el.domande.filter(e => e.id === 2)}}),
                valutazione: value
            }
        } else if (field === "valutazione" && this.props.tipoQuestionario === enumQuestionario.tipoQuestionario.PSAN) {
            if(this.state.questionario) {
                questionario = {
                    ..._.cloneDeep(defaultQuestionario),
                    valutazione: value
                };
            }
        } else {
            questionario = _.cloneDeep(defaultQuestionario);
            questionario[field] = value;
        }
        
        this.setState({questionario: questionario}, () => {
            this.props.onChange(questionario)
        })
    }

    render() {

        if(questionariFaccoltativi.includes(this.props.tipoQuestionario) && this.context.showOnlyRequiredFields) return null;

        if (this.state.redirectToCompilaQuestionario) return this.redirectToCompilaQuestionario();
        const {tipoQuestionario, forceReadOnly, fieldInAccordionReadOnly, infermiereFieldId, pageState, fieldId, nomeLink, compilaQuestionarioObbligatorio, fieldIdParent} = this.props;
        const { questionario } = this.state;
        const conditionRiepilogo = (this.context.hideUnsetValues && this.props.forceReadOnly) || (this.context.hideUnsetValues && this.context.forceReadOnly)
        return <div id={"statisticaContainer"}
                    className={!conditionRiepilogo ? styles.container.concat(" row pl-2 pr-2") : " row pl-2 pb-3 pr-2"}>
            <div className="col-12 pb-2">
                <LinkQuestionario
                    tipoQuestionario={tipoQuestionario}
                    questionario={questionario}
                    salvaBozza={this.salvaBozza}
                    forceReadOnly={forceReadOnly}
                    fieldInAccordionReadOnly={fieldInAccordionReadOnly}
                    infermiereFieldId={infermiereFieldId}
                    pageState={pageState}
                    fieldId={fieldId}
                    nomeLink={nomeLink}
                    forceRequiredCompilation={!!this.props.obbligatorieta?.forceRequiredCompilation}
                    compilaQuestionarioObbligatorio={compilaQuestionarioObbligatorio}
                    fieldIdParent={fieldIdParent}
                />
            </div>
            {this.renderIntermediateScores()}
            {this.renderScores()}
            {this.renderProfiles()}
        </div>;
    }

    salvaBozza = () => {
        return this.props.salvaBozza(false, true).subscribe((data) => {
            if (data) this.setState({redirectToCompilaQuestionario: true, richiesta: data})
        });
    }

    redirectToCompilaQuestionario = () => {
        let baseUrl = Utils.isStateRichiesta(this.props.pageState) ? routepath.inserimento_richiesta : routepath.valutazione_multidimensionale;

        return <Redirect to={{
            pathname: baseUrl.concat(routepath.compila_questionario),
            richiesta: this.state.richiesta,
            tipoQuestionario: this.props.tipoQuestionario,
            pageState: this.props.pageState,
            pathRichiestaQuestionario: this.props.pathRichiestaQuestionario,
            sessoPaziente: this.props.sessoPaziente,
            labelSezioneObbligatoria: this.props.compilaQuestionarioObbligatorio,
            showOnlyRequiredFields: this.context.showOnlyRequiredFields,
            infoRichiesta: _.cloneDeep(this.props.richiesta),
            obbligatorieta: this.props.obbligatorieta
        }}/>;
    }

    forceFieldVisibility = (checkVisibilityOnlyOnRequiredAccordionPage, field) => {
        const isConditionValid = () => {
            if (this.props.forceReadOnly || this.context.forceReadOnly || this.props.fieldInAccordionReadOnly) return false;

            if (Utils.isRuoloInfermiere() && this.props.infermiereFieldId) {
                return isFieldIdWriteable(field || this.props.infermiereFieldId, this.props.pageState);
            } else {
                return isFieldIdWriteable(field || this.props.fieldId, this.props.pageState, this.context.forceUserRole);
            }
        };

        if (checkVisibilityOnlyOnRequiredAccordionPage) {
            if (this.context.showOnlyRequiredFields) return isConditionValid();
            else return false;
        } else return isConditionValid();
    }

    renderIntermediateScores() {
        let punteggiIntermedi = this.state.questionario?.punteggiIntermedi;
        let idSezioniPunteggiIntermedi = this.props.idSezioniPunteggiIntermedi;

        return idSezioniPunteggiIntermedi?.length &&
            idSezioniPunteggiIntermedi.map((id, i) => <>
                <div className="col-6">
                    <RoleBasedText
                        fieldId={this.props.fieldId}
                        pageState={this.props.pageState}
                        field={'valore'}
                        fieldLabel={getSezioneQuestionario(this.props.tipoQuestionario, id)?.punteggi[0]?.titolo}
                        fieldRequired={!this.context.forceReadOnly && ((Array.isArray(this.props.scoresMandatory) && this.props.scoresMandatory[i]) || this.state.scoresMandatory || !!this.props.sezioniPunteggiRequired[i])}
                        // forceFieldRequiredVisual={!this.context.forceReadOnly}
                        forceVisibility={_.find(punteggiIntermedi, punteggioInt => punteggioInt.id === id)?.valore != null ?? this.forceFieldVisibility(true)}
                        value={_.find(punteggiIntermedi, punteggioInt => punteggioInt.id === id)?.valore}
                        preParentJsonPathSubObject={this.props.parentJsonPathSubObject}
                        parentJsonPath={this.props.parentJsonPath}
                        listId={[{name: 'punteggiIntermedi', id}]}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                    />
                </div>
            </>);
    }

    renderScores() {
        return this.props.nomePunteggio &&
            <div className={"col-6"}>
                <RoleBasedText
                    fieldId={this.props.fieldId}
                    pageState={this.props.pageState}
                    field={'punteggio'}
                    fieldLabel={this.props.nomePunteggio}
                    fieldRequired={!this.context.forceReadOnly && ((Array.isArray(this.props.scoresMandatory) && this.props.scoresMandatory[this.props.scoresMandatory.length - 1]) || this.state.scoresMandatory)}
                    // forceFieldRequiredVisual={!this.context.forceReadOnly}
                    forceVisibility={this.state.questionario?.punteggio != null ?? this.forceFieldVisibility(true)}
                    value={this.state.questionario?.punteggio}
                    parentJsonPathSubObject={this.props.parentJsonPathSubObject}
                    parentJsonPath={this.props.parentJsonPath}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </div>;
    }

    renderProfiles() {
        let valutazione = _.clone(this.state.questionario?.valutazione);
        let valueValutazione = _.find(this.opzioniValutazione, opzione => opzione.value === valutazione);

        const popupVisible = !!(esitoQuestionario[this.props.tipoQuestionario]);
        let elem;
        if (this.props.profileName) {
            const forceVisibility = this.forceFieldVisibility(false, Utils.isRuoloInfermiere() ? this.props.profileInfermiereFieldId : this.props.profileFieldId);
            if (this.props.profileRequired || forceVisibility) {
                elem = React.createElement(RoleBasedSelect, {
                    fieldId: this.props.profileFieldId,
                    infermiereFieldId: this.props.profileInfermiereFieldId,
                    pageState: this.props.pageState,
                    classNamePrefix: 'inputSelect',
                    components: DropdownIndicator,
                    options: this.opzioniValutazione,
                    value: valueValutazione,
                    onChange: e => this.handleChange("valutazione", e.value),
                    isSearchable: false,
                    styles: inputSelectStyles,
                    placeholder: 'Seleziona',
                    noOptionsMessage: () => "Errore: punteggio non esistente",
                    forceReadOnly: this.props.forceReadOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    disableDoubleInput: this.props.disableDoubleInput,
                    field: 'valutazione',
                    parentJsonPathSubObject: this.props.parentJsonPathSubObject,
                    parentJsonPath: this.props.parentJsonPath,
                    fieldLabel: this.props.profileName,
                    fieldRequired: this.props.profileRequired,
                    forceVisibility: forceVisibility,
                    hint: popupVisible ?
                        <InfoPopupEsitoQuestionario tipoQuestionario={this.props.tipoQuestionario}/> : null,
                    fieldInAccordionReadOnly: this.props.fieldInAccordionReadOnly
                }, null);
            } else {
                elem = React.createElement(RoleBasedText, {
                    fieldId: this.props.profileFieldId,
                    infermiereFieldId: this.props.profileInfermiereFieldId,
                    pageState: this.props.pageState,
                    value: valutazione,
                    forceReadOnly: this.props.forceReadOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    disableDoubleInput: this.props.disableDoubleInput,
                    field: 'valutazione',
                    parentJsonPathSubObject: this.props.parentJsonPathSubObject,
                    parentJsonPath: this.props.parentJsonPath,
                    fieldLabel: this.props.profileName,
                    hint: popupVisible ? <InfoPopupEsitoQuestionario tipoQuestionario={this.props.tipoQuestionario}/> : null,
                    fieldInAccordionReadOnly: this.props.fieldInAccordionReadOnly,
                    forceVisibility: valutazione != null ? true : false
                }, null);
            }

            return <div className={"col-6"}>{elem}</div>;
        }

        return null;
    }
}

Statistica.propTypes = propTypes;
Statistica.defaultProps = defaultProps;
Statistica.contextType = RoleBasedContext;
