import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";


const propTypes = {
    title: PropTypes.any,
    motivation: PropTypes.any
}

export default class PaginaDiCortesia extends Component{
    state = {
        title: _.cloneDeep(this.props.title),
        motivation: _.cloneDeep(this.props.motivation)
    }

    render() {
        return (
            <>
                <div className="row py-3 justify-content-center">
                    <h1>{this.state.title}</h1>
                </div>
                <div className="row py-3 justify-content-center">
                    <p>{this.state.motivation}</p>
                </div>
            </>
        );
    }

}

PaginaDiCortesia.propTypes = propTypes;
