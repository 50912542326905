import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";

const propTypes = {
    uomo: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class Uomo extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.uomo)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.uomo, this.props.uomo))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.uomo);
        this.setState({dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        let dati = _.cloneDeep(this.state.dati);
        dati[field] = elem;

        if (Utils.isStringEmptyOrNullOrUndefined(dati.ultimoEsamePSA)) {
            dati.noteEsitoPSA = null;
            dati.esitoPSA = null;
        }

        this.setState({dati: dati}, () => this.validazioneDati());
    }

    validazioneDati = () => {
        let dati = _.cloneDeep(this.state.dati);
        this.props.onChangeDatiAssistito("uomo", dati);
    }

    renderBody = () => {
        let dati = this.state.dati;
        return (
            <>
                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'ULT_VIS_UROL.UOMO.ANAMNESIFISIOLOGICA'}
                        pageState={this.props.pageState}
                        id={"uomoDataUltimaVisitaUrologica"}
                        value={dati.ultimaVisitaUrologica}
                        onChange={(elem) => this.handleAggiornaForm("ultimaVisitaUrologica", elem.target.value)}
                        errorMessage={"Data non valida"}
                        fieldLabel={"Ultima visita urologica"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"ultimaVisitaUrologica"}
                    />
                    <RoleBasedInput
                        fieldId={'NOTE.UOMO.ANAMNESIFISIOLOGICA'}
                        pageState={this.props.pageState}
                        value={dati.noteUltimaVisitaUrologica}
                        placeholder="Inserisci eventuali note"
                        field={"noteUltimaVisitaUrologica"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"basedInputNoteUltimaVisitaUrologica"}
                        fieldLabel={"Note"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'ULT_ESAME_PSA.UOMO.ANAMNESIFISIOLOGICA'}
                        pageState={this.props.pageState}
                        id={"uomoUltimoEsamePSA"}
                        value={dati.ultimoEsamePSA}
                        onChange={(elem) => this.handleAggiornaForm("ultimoEsamePSA", elem.target.value)}
                        errorMessage={"Data non valida"}
                        fieldLabel={"Ultimo esame PSA"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"ultimoEsamePSA"}
                    />
                    {dati.ultimoEsamePSA &&
                    <RoleBasedSelect
                        fieldId={'ESITO_PSA.UOMO.ANAMNESIFISIOLOGICA'}
                        pageState={this.props.pageState}
                        id={"selectEsitoPSA"}
                        options={OggettiUtili.esitoTest}
                        value={dati.esitoPSA}
                        onChange={(elem) => this.handleAggiornaForm("esitoPSA", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Esito PSA non esistente"}
                        fieldLabel={"Esito PSA"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"esitoPSA"}
                    />}
                    {dati.ultimoEsamePSA &&
                    <RoleBasedInput
                        fieldId={'ESITO_PSA.UOMO.ANAMNESIFISIOLOGICA'}
                        pageState={this.props.pageState}
                        id={"noteEsitoPSA"}
                        parentClass={"mb-0"}
                        value={dati.noteEsitoPSA}
                        placeholder={"Inserisci esito nota PSA"}
                        field={"noteEsitoPSA"}
                        fieldLabel={"Note"}
                        onChange={(field, elem) => this.handleAggiornaForm("noteEsitoPSA", elem.target.value)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />}
                </FieldsRow>
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"uomo"}
                title={"Uomo"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}

Uomo.propTypes = propTypes;

Uomo.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}
