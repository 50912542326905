import React, {Fragment} from "react";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import PropTypes from "prop-types";
import * as _ from "lodash";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import Utils from "../../../utils/Utils";
import {
    FieldsRow,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import {v4 as uuid} from "uuid";

const propTypes = {
    datiTrattamentiDietetici: PropTypes.any,
    onChangeTrattamentiDietetici: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    hideUnsetValues: PropTypes.bool,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

const defaultTrattamentoDietetico = {
    id: null,
    trattamento: null
}

const defaultPrescrizioneDietetica = {
    apportoCaloricoGiornalieroPrescritto: null,
    restrizioniDietetiche: [],
    restrizioniAssunzioneLiquidi: null,
    quantitaLiquidiPrescritti: null,
    aderenzaPrescrizione: null,
    noteAderenzaPrescrizione: null
}

const defaultNutrizioneOS = {
    consistenza: null,
    utilizzoDiAddensanti: null,
    numeroPastiAlGiorno: null,
    numeroSpuntini: null,
    autonomiaGestionePaziente: null,
    autonomiaGestioneCaregiver: null,
    aderenzaPrescrizione: null,
    noteAderenzaPrescrizione: null
}

const defaultNutrizioneParenterale = {
    codiceSaccaNutrizionale: null,
    dataPrescrizione: "",
    quantitaGiornaliera: null,
    velocitaDiSomministrazione: null,
    autonomiaGestionePaziente: null,
    autonomiaGestioneCaregiver: null,
    aderenzaPrescrizione: null,
    noteAderenzaPrescrizione: null
}

const defaultNutrizioneEnterale = {
    nutrientePrescritto: [],
    acqua: null,
    autonomiaGestionePaziente: null,
    autonomiaGestioneCaregiver: null,
    aderenzaPrescrizione: null,
    noteAderenzaPrescrizione: null
}

const defaultSulblistElement = {
    id: null,
    valore: null
}

export default class TrattamentiDietetici extends AccordionSecondLevel {
    state = {
        datiTrattamentiDietetici: _.cloneDeep(this.props.datiTrattamentiDietetici),
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiTrattamentiDietetici, this.props.datiTrattamentiDietetici))
            this.inizializzaForm()
    }

    inizializzaForm = () => {
        let trattamentiDietetici = _.cloneDeep(this.props.datiTrattamentiDietetici);

        let listaTrattamentiDietetici = [];
        if (trattamentiDietetici.length === 0) {
            let trattamentoDieteticoDefault = _.cloneDeep(defaultTrattamentoDietetico);
            trattamentoDieteticoDefault.id = uuid();
            listaTrattamentiDietetici.push(trattamentoDieteticoDefault);
        } else if (trattamentiDietetici.length > 0) {
            listaTrattamentiDietetici.push(...trattamentiDietetici);
        }

        if (listaTrattamentiDietetici.length > 0) {
            listaTrattamentiDietetici = listaTrattamentiDietetici.map(trattamentoDietetico => {
                let trattamentoDieteticoMap = trattamentoDietetico;
                if (!Utils.isStringEmptyOrNullOrUndefined(trattamentoDietetico.trattamento)) {
                    let trattamentiOptions = oggettiUtili.trattamento;
                    trattamentoDieteticoMap.trattamento = trattamentiOptions.find(el => el.value === trattamentoDietetico.trattamento);
                    switch (trattamentoDieteticoMap.trattamento) {
                        case trattamentiOptions[1]:
                            if (Utils.isObjectNull(trattamentoDietetico.prescrizioneDietetica)) {
                                trattamentoDieteticoMap.prescrizioneDietetica = _.cloneDeep(defaultPrescrizioneDietetica);
                            } else {
                                this.formatObjectPrescrizioneDietetica(trattamentoDieteticoMap.prescrizioneDietetica, false);
                            }
                            break;
                        case trattamentiOptions[2]:
                            if (Utils.isObjectNull(trattamentoDietetico.nutrizioneOS)) {
                                trattamentoDieteticoMap.nutrizioneOS = _.cloneDeep(defaultNutrizioneOS);
                            } else {
                                this.formatObjectNutrizioneOS(trattamentoDieteticoMap.nutrizioneOS, false);
                            }
                            break;
                        case trattamentiOptions[3]:
                            if (Utils.isObjectNull(trattamentoDietetico.nutrizioneParenterale)) {
                                trattamentoDieteticoMap.nutrizioneParenterale = _.cloneDeep(defaultNutrizioneParenterale);
                            } else {
                                this.formatObjectNutrizioneParenterale(trattamentoDieteticoMap.nutrizioneParenterale, false);
                            }
                            break;
                        case trattamentiOptions[4]:
                            if (Utils.isObjectNull(trattamentoDietetico.nutrizioneEnterale)) {
                                trattamentoDieteticoMap.nutrizioneEnterale = _.cloneDeep(defaultNutrizioneEnterale);
                            } else {
                                trattamentoDieteticoMap.nutrizioneEnterale.acqua = !Utils.isStringEmptyOrNullOrUndefined(trattamentoDietetico.nutrizioneEnterale.acqua)
                                    ? Number(trattamentoDietetico.nutrizioneEnterale.acqua) : null;
                                if (trattamentoDietetico.nutrizioneEnterale.nutrientePrescritto === null) {
                                    trattamentoDieteticoMap.nutrizioneEnterale.nutrientePrescritto = [];
                                }
                                this.formatCommonObjectFields(trattamentoDieteticoMap.nutrizioneEnterale, false);
                            }
                            break;
                        default:
                            break;
                    }
                }
                return trattamentoDieteticoMap;
            });
        }

        this.setState({
            datiTrattamentiDietetici: listaTrattamentiDietetici,
        });
    }

    formatCommonObjectFields = (object, isObjectForBackend) => {
        if (isObjectForBackend) {
            if (!Utils.isStringEmptyOrNullOrUndefined(object?.quantitaGiornaliera))
                object.quantitaGiornaliera = Number(object.quantitaGiornaliera);
            if (!Utils.isStringEmptyOrNullOrUndefined(object?.velocitaDiSomministrazione))
                object.velocitaDiSomministrazione = Number(object.velocitaDiSomministrazione);
        } else {
            if (object?.quantitaGiornaliera)
                object.quantitaGiornaliera = object.quantitaGiornaliera.toString();
            if (object?.velocitaDiSomministrazione)
                object.velocitaDiSomministrazione = object.velocitaDiSomministrazione.toString();
        }
    }

    formatObjectPrescrizioneDietetica = (prescrizioneDietetica, isObjectForBackend) => {
        if (isObjectForBackend) {
            if (!Utils.isStringEmptyOrNullOrUndefined(prescrizioneDietetica.apportoCaloricoGiornalieroPrescritto))
                prescrizioneDietetica.apportoCaloricoGiornalieroPrescritto = Number(prescrizioneDietetica.apportoCaloricoGiornalieroPrescritto);
            if (prescrizioneDietetica.restrizioniDietetiche !== null && prescrizioneDietetica.restrizioniDietetiche.length > 0)
                prescrizioneDietetica.restrizioniDietetiche = Utils.getDistinctListByField(prescrizioneDietetica.restrizioniDietetiche, "valore");
            if (!Utils.isStringEmptyOrNullOrUndefined(prescrizioneDietetica.quantitaLiquidiPrescritti))
                prescrizioneDietetica.quantitaLiquidiPrescritti = Number(prescrizioneDietetica.quantitaLiquidiPrescritti);
            this.formatCommonObjectFields(prescrizioneDietetica, isObjectForBackend);
        } else {
            if (prescrizioneDietetica.apportoCaloricoGiornalieroPrescritto !== null)
                prescrizioneDietetica.apportoCaloricoGiornalieroPrescritto = prescrizioneDietetica.apportoCaloricoGiornalieroPrescritto.toString();
            if (prescrizioneDietetica.restrizioniDietetiche === null)
                prescrizioneDietetica.restrizioniDietetiche = [];
            if (prescrizioneDietetica.quantitaLiquidiPrescritti !== null)
                prescrizioneDietetica.quantitaLiquidiPrescritti = prescrizioneDietetica.quantitaLiquidiPrescritti.toString();
            this.formatCommonObjectFields(prescrizioneDietetica, isObjectForBackend);
        }
    }

    formatObjectNutrizioneOS = (nutrizioneOS, isObjectForBackend) => {
        if (isObjectForBackend) {
            if (!Utils.isStringEmptyOrNullOrUndefined(nutrizioneOS.numeroPastiAlGiorno))
                nutrizioneOS.numeroPastiAlGiorno = Number(nutrizioneOS.numeroPastiAlGiorno);
            if (!Utils.isStringEmptyOrNullOrUndefined(nutrizioneOS.numeroSpuntini))
                nutrizioneOS.numeroSpuntini = Number(nutrizioneOS.numeroSpuntini);
            this.formatCommonObjectFields(nutrizioneOS, isObjectForBackend);
        } else {
            if (nutrizioneOS.numeroPastiAlGiorno !== null)
                nutrizioneOS.numeroPastiAlGiorno = nutrizioneOS.numeroPastiAlGiorno.toString();
            if (nutrizioneOS.numeroSpuntini !== null)
                nutrizioneOS.numeroSpuntini = nutrizioneOS.numeroSpuntini.toString();
            this.formatCommonObjectFields(nutrizioneOS, isObjectForBackend);
        }
    }

    formatObjectNutrizioneParenterale = (nutrizioneParenterale, isObjectForBackend) => {
        if (!Utils.isStringEmptyOrNullOrUndefined(nutrizioneParenterale.dataPrescrizione))
            nutrizioneParenterale.dataPrescrizione = isObjectForBackend
                ? nutrizioneParenterale.dataPrescrizione
                : Utils.transformTimeStampForFrontend(nutrizioneParenterale.dataPrescrizione);
        else
            nutrizioneParenterale.dataPrescrizione = isObjectForBackend ? null : "";
        this.formatCommonObjectFields(nutrizioneParenterale, isObjectForBackend);
    }

    handleAggiornaForm = (field, elem, index) => {
        let trattamentiDietetici = _.cloneDeep(this.state.datiTrattamentiDietetici);
        let trattamentoDietetico = trattamentiDietetici[index];

        let fieldSplit = field.split("|");

        if (fieldSplit.length > 1) {
            let subObject = fieldSplit[0];
            let subField = fieldSplit[1];
            trattamentoDietetico[subObject][subField] = elem;
        } else {
            if (!_.isEqual(elem, trattamentoDietetico[field])) {
                trattamentoDietetico[field] = elem;
                let trattamentiOptions = oggettiUtili.trattamento;
                switch (elem) {
                    case trattamentiOptions[0]:
                        this.resetSottoOggettoTrattamentiDietetici(trattamentoDietetico);
                        break;
                    case trattamentiOptions[1]:
                        this.resetSottoOggettoTrattamentiDietetici(trattamentoDietetico);
                        trattamentoDietetico.prescrizioneDietetica = _.cloneDeep(defaultPrescrizioneDietetica);
                        break;
                    case trattamentiOptions[2]:
                        this.resetSottoOggettoTrattamentiDietetici(trattamentoDietetico);
                        trattamentoDietetico.nutrizioneOS = _.cloneDeep(defaultNutrizioneOS);
                        break;
                    case trattamentiOptions[3]:
                        this.resetSottoOggettoTrattamentiDietetici(trattamentoDietetico);
                        trattamentoDietetico.nutrizioneParenterale = _.cloneDeep(defaultNutrizioneParenterale);
                        break;
                    case trattamentiOptions[4]:
                        this.resetSottoOggettoTrattamentiDietetici(trattamentoDietetico);
                        trattamentoDietetico.nutrizioneEnterale = _.cloneDeep(defaultNutrizioneEnterale);
                        break;
                    default:
                        break;
                }
            }
        }
        trattamentiDietetici[index] = trattamentoDietetico;

        this.setState({datiTrattamentiDietetici: trattamentiDietetici}, () => this.createObjectForBackend());
    }

    resetSottoOggettoTrattamentiDietetici = (trattamentoDietetico) => {
        Object.keys(trattamentoDietetico).forEach(key => {
            if (key !== "id" && key !== "trattamento")
                delete trattamentoDietetico[key];
        });
    }

    createObjectForBackend = () => {
        let trattamentiDietetici = _.cloneDeep(this.state.datiTrattamentiDietetici);

        if (trattamentiDietetici.length > 0) {
            trattamentiDietetici = trattamentiDietetici.map(trattamentoDietetico => {
                let trattamentoDieteticoMap = trattamentoDietetico;
                if (!Utils.isObjectNull(trattamentoDietetico.trattamento)) {
                    let trattamentiOptions = oggettiUtili.trattamento;
                    trattamentoDieteticoMap.trattamento = trattamentoDietetico.trattamento.value;
                    switch (trattamentoDieteticoMap.trattamento) {
                        case trattamentiOptions[1].value:
                            this.formatObjectPrescrizioneDietetica(trattamentoDieteticoMap.prescrizioneDietetica, true);
                            break;
                        case trattamentiOptions[2].value:
                            this.formatObjectNutrizioneOS(trattamentoDieteticoMap.nutrizioneOS, true);
                            break;
                        case trattamentiOptions[3].value:
                            this.formatObjectNutrizioneParenterale(trattamentoDieteticoMap.nutrizioneParenterale, true);
                            break;
                        case trattamentiOptions[4].value:
                            trattamentoDieteticoMap.nutrizioneEnterale.acqua = trattamentoDietetico.nutrizioneEnterale.acqua !== null ? trattamentoDietetico.nutrizioneEnterale.acqua.toString() : null;
                            if (trattamentoDietetico.nutrizioneEnterale.nutrientePrescritto !== null && trattamentoDietetico.nutrizioneEnterale.nutrientePrescritto.length > 0) {
                                trattamentoDieteticoMap.nutrizioneEnterale.nutrientePrescritto = Utils.getDistinctListByField(trattamentoDietetico.nutrizioneEnterale.nutrientePrescritto, "valore");
                            }
                            this.formatCommonObjectFields(trattamentoDieteticoMap.nutrizioneEnterale, true);
                            break;
                        default:
                            break;
                    }
                }
                return _.mapValues(trattamentoDieteticoMap, el => el === null || el === undefined || el === "" ? null : el);
            });
        }

        this.props.onChangeTrattamentiDietetici("trattamentiDietetici", trattamentiDietetici);
    }

    addNewTrattamentoDietetico = () => {
        let trattamentiDietetici = _.cloneDeep(this.state.datiTrattamentiDietetici);
        let trattamentoDietetico = _.cloneDeep(defaultTrattamentoDietetico);
        trattamentoDietetico.id = uuid();
        trattamentiDietetici.push(trattamentoDietetico);
        this.setState({datiTrattamentiDietetici: trattamentiDietetici});
    }

    removeTrattamentoDietetico = (index) => {
        let trattamentiDietetici = _.cloneDeep(this.state.datiTrattamentiDietetici);
        trattamentiDietetici.splice(index, 1);

        this.setState({
            datiTrattamentiDietetici: trattamentiDietetici,
        }, () => {
            this.createObjectForBackend();
        });
    }

    renderNewTrattamentoDietetico = (trattamentoDietetico, index) => {
        let tipoTrattamentoDietetico = oggettiUtili.trattamento;

        let input;
        switch (trattamentoDietetico.trattamento?.value) {
            case tipoTrattamentoDietetico[1].value:
                input = this.renderInputPrescrizioneDietetica(trattamentoDietetico.prescrizioneDietetica, index, trattamentoDietetico?.id);
                break;
            case tipoTrattamentoDietetico[2].value:
                input = this.renderInputNutrizionePerOS(trattamentoDietetico.nutrizioneOS, index);
                break;
            case tipoTrattamentoDietetico[3].value:
                input = this.renderInputNutrizioneParenterale(trattamentoDietetico.nutrizioneParenterale, index);
                break;
            case tipoTrattamentoDietetico[4].value:
                input = this.renderInputNutrizioneEnterale(trattamentoDietetico.nutrizioneEnterale, index, trattamentoDietetico?.id);
                break;
            default:
                input = null;
                break;
        }

        return (
            <Fragment key={index}>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'TRATTAMENTO.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"selectTipoTrattamentoDietetico"}
                        className={"selectTipoTrattamentoDietetico"}
                        options={tipoTrattamentoDietetico}
                        value={trattamentoDietetico.trattamento}
                        onChange={(elem) => this.handleAggiornaForm("trattamento", elem, index)}
                        isSearchable={false}
                        fieldLabel={'Trattamento'}
                        field={"trattamento"}
                        parentJsonPath={"informazionidibase"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
                {input}
            </Fragment>
        );
    }

    renderRestrizioneDietetica = (restrizioneDietetica, indexCurrentRestrizioneDietetica, sublistPath, indexCurrentTrattamentoDietetico, idElemento) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'RESTR_DIET_PRESCR_DIET.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    pageState={this.props.pageState}
                    id={"selectRestrizioniDietetiche"}
                    className={"selectRestrizioniDietetiche"}
                    options={oggettiUtili.restrizioniDietetiche}
                    value={restrizioneDietetica.valore}
                    onChange={(elem) => this.handleAggiornaSublist(elem, indexCurrentRestrizioneDietetica, sublistPath, indexCurrentTrattamentoDietetico, "valore")}
                    isSearchable={false}
                    fieldLabel={'Restrizioni dietetiche'}
                    handleOnlyValue={true}
                    field={"valore"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
        );
    }

    handleAggiornaSublist = (elem, indexCurrentRestrizioneDietetica, sublistPath, indexCurrentTrattamentoDietetico,field) => {
        let datiTrattamentiDietetici = _.cloneDeep(this.state.datiTrattamentiDietetici);
        let currentTrattamentoDietetico = datiTrattamentiDietetici[indexCurrentTrattamentoDietetico];
        let split = sublistPath.split(".");
        currentTrattamentoDietetico[split[0]][split[1]][indexCurrentRestrizioneDietetica][field] = elem;
        datiTrattamentiDietetici[indexCurrentTrattamentoDietetico] = currentTrattamentoDietetico;
        this.setState({datiTrattamentiDietetici}, () => this.createObjectForBackend());
    }

    addNewElementToSublist = (sublistPath, indexCurrentTrattamentoDietetico) => {
        let datiTrattamentiDietetici = _.cloneDeep(this.state.datiTrattamentiDietetici);
        let currentTrattamentoDietetico = datiTrattamentiDietetici[indexCurrentTrattamentoDietetico];
        let split = sublistPath.split(".");
        let defaultRestrizioneDieteticaClone = _.cloneDeep(defaultSulblistElement);
        defaultRestrizioneDieteticaClone.id = uuid();
        currentTrattamentoDietetico[split[0]][split[1]].push(defaultRestrizioneDieteticaClone);
        datiTrattamentiDietetici[indexCurrentTrattamentoDietetico] = currentTrattamentoDietetico;
        this.setState({datiTrattamentiDietetici}, () => this.createObjectForBackend());
    }

    removeElementToSublist = (indexCurrentRestrizioneDietetica, sublistPath, indexCurrentTrattamentoDietetico) => {
        let datiTrattamentiDietetici = _.cloneDeep(this.state.datiTrattamentiDietetici);
        let currentTrattamentoDietetico = datiTrattamentiDietetici[indexCurrentTrattamentoDietetico];
        let split = sublistPath.split(".");
        currentTrattamentoDietetico[split[0]][split[1]].splice(indexCurrentRestrizioneDietetica, 1);
        datiTrattamentiDietetici[indexCurrentTrattamentoDietetico] = currentTrattamentoDietetico;
        this.setState({datiTrattamentiDietetici}, () => this.createObjectForBackend());
    }

    renderInputPrescrizioneDietetica = (prescrizioneDietetica, index, idTrattamentoDieteticoItem) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'APP_CALOR_GIORN_PRESCR_PRESCR_DIET.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        type={"number"}
                        min={0}
                        placeholder={"Inserisci apporto calorico giornaliero prescritto"}
                        field={"apportoCaloricoGiornalieroPrescritto"}
                        onChange={(field, elem) => this.handleAggiornaForm("prescrizioneDietetica|apportoCaloricoGiornalieroPrescritto", elem.target.value, index)}
                        id={"inputApportoCaloricoGiornalieroPrescritto"}
                        fieldLabel={'Apporto calorico giornaliero prescritto'}
                        value={prescrizioneDietetica.apportoCaloricoGiornalieroPrescritto}
                        parentJsonPathSubObject={"prescrizioneDietetica"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <span>{"Kcal"}</span>
                </FieldsRow>

                <SectionList
                    title={'Restrizioni dietetiche'}
                    data={prescrizioneDietetica.restrizioniDietetiche}
                    renderSection={(e, i) => this.renderRestrizioneDietetica(e, i, "prescrizioneDietetica.restrizioniDietetiche", index)}
                    addNewSectionCallback={() => this.addNewElementToSublist("prescrizioneDietetica.restrizioniDietetiche", index)}
                    removeSectionCallback={(i) => this.removeElementToSublist(i, "prescrizioneDietetica.restrizioniDietetiche", index)}
                    pageState={this.props.pageState}
                    keyFieldId={'RESTR_DIET_PRESCR_DIET.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    indentOffset={1}
                    field={'prescrizioneDietetica.restrizioniDietetiche'}
                    addButtonVisibilityHandler = {prescrizioneDietetica.restrizioniDietetiche?.length === 0 || prescrizioneDietetica.restrizioniDietetiche.every(el => !Utils.isObjectNull(el?.valore))}
                    parentJsonPath={"informazioniDiBase.trattamentiDietetici[?(@.id == '" + idTrattamentoDieteticoItem + "')].prescrizioneDietetica.restrizioniDietetiche"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'RESTR_LIQUIDI_PRESC_DIET.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"selectRestrizioniAssunzioniLiquidi"}
                        className={"selectRestrizioniAssunzioniLiquidi"}
                        options={oggettiUtili.rispostaBreve}
                        value={prescrizioneDietetica.restrizioniAssunzioneLiquidi}
                        onChange={(elem) => this.handleAggiornaForm("prescrizioneDietetica|restrizioniAssunzioneLiquidi", elem, index)}
                        isSearchable={false}
                        fieldLabel={"Restrizioni nell'assunzione di liquidi?"}
                        field={"restrizioniAssunzioneLiquidi"}
                        parentJsonPathSubObject={"prescrizioneDietetica"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />

                    <RoleBasedInput
                        fieldId={'QUANT_LIQ_PRESCR_PRESC_DIET.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        type={"number"}
                        min={0}
                        placeholder={"Inserisci quantità di liquidi prescritti"}
                        field={"quantitaLiquidiPrescritti"}
                        onChange={(field, elem) => this.handleAggiornaForm("prescrizioneDietetica|quantitaLiquidiPrescritti", elem.target.value, index)}
                        id={"inputQuantitaLiquidiPrescritti"}
                        fieldLabel={'Quantità di liquidi prescritti'}
                        value={prescrizioneDietetica.quantitaLiquidiPrescritti ?? ""}
                        parentJsonPathSubObject={"prescrizioneDietetica"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <span>{"ml/die"}</span>
                </FieldsRow>

                {this.renderAderenzaAllaPrescrizione(prescrizioneDietetica.aderenzaPrescrizione,
                    "prescrizioneDietetica|aderenzaPrescrizione", prescrizioneDietetica.noteAderenzaPrescrizione,
                    "prescrizioneDietetica|noteAderenzaPrescrizione", index)}
            </>
        );
    }

    renderInputNutrizionePerOS = (nutrizioneOS, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'CONSISTENZA_OS.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"selectTipoConsistenza"}
                        className={"selectTipoConsistenza"}
                        options={oggettiUtili.consistenza}
                        value={nutrizioneOS.consistenza}
                        onChange={(elem) => this.handleAggiornaForm("nutrizioneOS|consistenza", elem, index)}
                        isSearchable={false}
                        fieldLabel={'Consistenza'}
                        field={"consistenza"}
                        parentJsonPathSubObject={"nutrizioneOS"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />

                    <RoleBasedSelect
                        fieldId={'UTIL_ADDENS_OS.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"selectUtilizzoDiAddensanti"}
                        className={"selectUtilizzoDiAddensanti"}
                        options={oggettiUtili.rispostaBreve}
                        value={nutrizioneOS.utilizzoDiAddensanti}
                        onChange={(elem) => this.handleAggiornaForm("nutrizioneOS|utilizzoDiAddensanti", elem, index)}
                        isSearchable={false}
                        fieldLabel={'Utilizzo di addensanti?'}
                        field={"utilizzoDiAddensanti"}
                        parentJsonPathSubObject={"nutrizioneOS"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />

                    <RoleBasedInput
                        fieldId={'NUM_PASTI_GIORN_OS.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        type={"number"}
                        min={0}
                        placeholder={"Inserisci numero pasti al giorno"}
                        field={"numeroPastiAlGiorno"}
                        onChange={(field, elem) => this.handleAggiornaForm("nutrizioneOS|numeroPastiAlGiorno", elem.target.value, index)}
                        id={"inputNumeroPastiAlGiorno"}
                        fieldLabel={'Numero pasti al giorno'}
                        value={nutrizioneOS.numeroPastiAlGiorno ?? ""}
                        parentJsonPathSubObject={"nutrizioneOS"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'NUM_SPUNTINI_OS.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        type={"number"}
                        min={0}
                        placeholder={"Inserisci numero spuntini"}
                        field={"numeroSpuntini"}
                        onChange={(field, elem) => this.handleAggiornaForm("nutrizioneOS|numeroSpuntini", elem.target.value, index)}
                        id={"inputNumeroSpuntini"}
                        value={nutrizioneOS.numeroSpuntini ?? ""}
                        fieldLabel={'Numero spuntini'}
                        parentJsonPathSubObject={"nutrizioneOS"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
                {this.renderAderenzaAllaPrescrizione(nutrizioneOS.aderenzaPrescrizione,
                    "nutrizioneOS|aderenzaPrescrizione", nutrizioneOS.noteAderenzaPrescrizione,
                    "nutrizioneOS|noteAderenzaPrescrizione", index)}

                {Utils.isStateValutazione(this.props.pageState) &&
                this.renderSelectAutonomiaDiGestione(nutrizioneOS.autonomiaGestionePaziente, 
                    "nutrizioneOS|autonomiaGestionePaziente", nutrizioneOS.autonomiaGestioneCaregiver,
                    "nutrizioneOS|autonomiaGestioneCaregiver", index, 'OS')}
            </>
        );
    }

    renderInputNutrizioneParenterale = (nutrizioneParenterale, index) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'COD_SACCA_NUTR_PARENT.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci codice sacca nutrizionale"}
                        field={"codiceSaccaNutrizionale"}
                        onChange={(field, elem) => this.handleAggiornaForm("nutrizioneParenterale|codiceSaccaNutrizionale", elem.target.value, index)}
                        id={"inputCodiceSaccaNutrizionale"}
                        value={nutrizioneParenterale.codiceSaccaNutrizionale ?? ""}
                        fieldLabel={'Codice Sacca nutrizionale'}
                        parentJsonPathSubObject={"nutrizioneParenterale"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedCalendar
                        fieldId={'DATA_PRESCR_PARENT.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"trattamentiDieteticiDataPrescrizione"}
                        placeholder={"gg/mm/aaaa"}
                        onChange={(elem) => this.handleAggiornaForm("nutrizioneParenterale|dataPrescrizione", elem.target.value, index)}
                        value={nutrizioneParenterale.dataPrescrizione}
                        errorMessage={"Data non valida"}
                        fieldLabel={'Data prescrizione'}
                        field={"dataPrescrizione"}
                        parentJsonPathSubObject={"nutrizioneParenterale"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedInput
                        fieldId={'QUANT_GIORN_PARENT.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        type={"number"}
                        min={0}
                        placeholder={"Inserisci quantità giornaliera"}
                        field={"quantitaGiornaliera"}
                        onChange={(field, elem) => this.handleAggiornaForm("nutrizioneParenterale|quantitaGiornaliera", elem.target.value, index)}
                        id={"inputQuantitaGiornalieraNutrizioneParenterale"}
                        value={nutrizioneParenterale.quantitaGiornaliera ?? ""}
                        fieldLabel={'Quantità giornaliera'}
                        parentJsonPathSubObject={"nutrizioneParenterale"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <span>{"ml/die"}</span>
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'VEL_SOMM_PARENT.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        type={"number"}
                        min={0}
                        placeholder={"Inserisci velocità di somministrazione"}
                        field={"velocitaDiSomministrazione"}
                        onChange={(field, elem) => this.handleAggiornaForm("nutrizioneParenterale|velocitaDiSomministrazione", elem.target.value, index)}
                        id={"inputVelocitaSomministrazioneNutrizioneParenterale"}
                        value={nutrizioneParenterale.velocitaDiSomministrazione ?? ""}
                        fieldLabel={'Velocità di somministrazione'}
                        parentJsonPathSubObject={"nutrizioneParenterale"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <span>{"ml/h"}</span>
                </FieldsRow>

                {this.renderAderenzaAllaPrescrizione(nutrizioneParenterale.aderenzaPrescrizione,
                    "nutrizioneParenterale|aderenzaPrescrizione", nutrizioneParenterale.noteAderenzaPrescrizione,
                    "nutrizioneParenterale|noteAderenzaPrescrizione", index)}

                {Utils.isStateValutazione(this.props.pageState) &&
                this.renderSelectAutonomiaDiGestione(nutrizioneParenterale.autonomiaGestionePaziente, 
                    "nutrizioneParenterale|autonomiaGestionePaziente", nutrizioneParenterale.autonomiaGestioneCaregiver,
                    "nutrizioneParenterale|autonomiaGestioneCaregiver", index, 'PARENT')}
            </>
        );
    }


    renderNutrizioneEnterale = (nutrientePrescritto, indexCurrentNutrientePrescritto, sublistPath, indexCurrentTrattamentoDietetico) => {
        return (
            <FieldsRow>
                <RoleBasedInput
                    fieldId={'NUTRIENTE_PRESCR_ENTERALE.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci nutriente prescritto"}
                    field={"valore"}
                    onChange={(field, elem) => this.handleAggiornaSublist(elem.target.value, indexCurrentNutrientePrescritto, sublistPath, indexCurrentTrattamentoDietetico, field)}
                    id={"inputNutrientePrescritto"}
                    value={nutrientePrescritto.valore ?? ""}
                    fieldLabel={'Nutriente prescritto'}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedInput
                    fieldId={'QUANT_GIORN_ENTERALE.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    pageState={this.props.pageState}
                    type={"number"}
                    min={0}
                    placeholder={"Inserisci quantità giornaliera"}
                    field={"quantitaGiornaliera"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    onChange={(field, elem) => this.handleAggiornaSublist(elem.target.value,indexCurrentNutrientePrescritto, sublistPath, indexCurrentTrattamentoDietetico, field)}
                    id={"inputQuantitaGiornalieraNutrizioneEnterale"}
                    value={nutrientePrescritto.quantitaGiornaliera ?? ""}
                    fieldLabel={'Quantità giornaliera (mL/die)'}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <RoleBasedInput
                    fieldId={'VEL_SOMM_ENTERALE.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    pageState={this.props.pageState}
                    type={"number"}
                    min={0}
                    placeholder={"Inserisci velocità di somministrazione"}
                    field={"velocitaDiSomministrazione"}
                    onChange={(field, elem) => this.handleAggiornaSublist(elem.target.value,indexCurrentNutrientePrescritto, sublistPath, indexCurrentTrattamentoDietetico, field)}
                    id={"inputVelocitaSomministrazioneNutrizioneEnterale"}
                    value={nutrientePrescritto.velocitaDiSomministrazione ?? ""}
                    fieldLabel={'Velocità di somministrazione (mL/h)'}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
        );
    }

    renderInputNutrizioneEnterale = (nutrizioneEnterale, index, idTrattamentoDieteticoItem) => {
        return (
            <>
                <SectionList
                    title={'Nutriente prescritto'}
                    data={nutrizioneEnterale.nutrientePrescritto}
                    renderSection={(e, i) => this.renderNutrizioneEnterale(e, i, "nutrizioneEnterale.nutrientePrescritto", index)}
                    addNewSectionCallback={() => this.addNewElementToSublist("nutrizioneEnterale.nutrientePrescritto", index)}
                    removeSectionCallback={(i) => this.removeElementToSublist(i, "nutrizioneEnterale.nutrientePrescritto", index)}
                    pageState={this.props.pageState}
                    keyFieldId={'NUTRIENTE_PRESCR_ENTERALE.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    indentOffset={1}
                    field={'nutrizioneEnterale.nutrientePrescritto'}
                    addButtonVisibilityHandler={nutrizioneEnterale.nutrientePrescritto?.length === 0 || nutrizioneEnterale.nutrientePrescritto.every( el => !Utils.isStringEmptyOrNullOrUndefined(el?.valore))}
                    parentJsonPath={"informazioniDiBase.trattamentiDietetici[?(@.id == '" + idTrattamentoDieteticoItem + "')].nutrizioneEnterale.nutrientePrescritto"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'ACQUA_ENTERALE.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        type={"number"}
                        min={0}
                        placeholder={"Inserisci"}
                        field={"acqua"}
                        onChange={(field, elem) => this.handleAggiornaForm("nutrizioneEnterale|acqua", elem.target.value, index)}
                        id={"inputAcqua"}
                        value={nutrizioneEnterale.acqua ?? ""}
                        fieldLabel={'Acqua'}
                        parentJsonPathSubObject={"nutrizioneEnterale"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <span>{"mL/die"}</span>
                </FieldsRow>

                {this.renderAderenzaAllaPrescrizione(nutrizioneEnterale.aderenzaPrescrizione,
                    "nutrizioneEnterale|aderenzaPrescrizione", nutrizioneEnterale.noteAderenzaPrescrizione,
                    "nutrizioneEnterale|noteAderenzaPrescrizione", index)}

                {Utils.isStateValutazione(this.props.pageState) &&
                this.renderSelectAutonomiaDiGestione(nutrizioneEnterale.autonomiaGestionePaziente, 
                    "nutrizioneEnterale|autonomiaGestionePaziente", nutrizioneEnterale.autonomiaGestioneCaregiver,
                    "nutrizioneEnterale|autonomiaGestioneCaregiver", index, 'ENTERALE')}
            </>
        );
    }

    renderSelectAutonomiaDiGestione = (gestionePazienteValue, gestionePazienteField, gestioneCaregiverValue,
                                       gestioneCaregiverField, index, fieldIdPart) => {
        return <FieldsRow>
            <RoleBasedSelect
                fieldId={`AUTON_GEST_PAZ_${fieldIdPart}.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE`}
                pageState={this.props.pageState}
                id={"selectAutonomiaGestionePaziente"}
                className={"selectAutonomiaGestionePaziente"}
                options={oggettiUtili.autonomiaGestione}
                value={gestionePazienteValue}
                onChange={(elem) => this.handleAggiornaForm(gestionePazienteField, elem, index)}
                field={gestionePazienteField?.replace('|', '.')}
                isSearchable={false}
                fieldLabel={'Autonomia di gestione del paziente'}
                key={`AUTON_GEST_PAZ_${fieldIdPart}.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE_${index}`}
                handleOnlyValue={true}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                disableDoubleInput={this.props.disableDoubleInput}
            />
            <RoleBasedSelect
                fieldId={`AUTON_GEST_CAREG_${fieldIdPart}.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE`}
                pageState={this.props.pageState}
                id={"selectAutonomiaGestioneCaregiver"}
                className={"selectAutonomiaGestioneCaregiver"}
                options={oggettiUtili.autonomiaGestione}
                value={gestioneCaregiverValue}
                onChange={(elem) => this.handleAggiornaForm(gestioneCaregiverField, elem, index)}
                field={gestioneCaregiverField?.replace('|', '.')}
                isSearchable={false}
                fieldLabel={'Autonomia di gestione del caregiver'}
                key={`AUTON_GEST_CAREG_${fieldIdPart}.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE_${index}`}
                handleOnlyValue={true}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                disableDoubleInput={this.props.disableDoubleInput}
            />
        </FieldsRow>;
    }

    renderAderenzaAllaPrescrizione = (selectValue, selectField, inputValue, inputField, index) => {
        let aderenzaAllaPrescrizioneOptions = oggettiUtili.aderenzaAllaPrescrizione;

        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'ADER_PRESCR_ENTERALE.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    pageState={this.props.pageState}
                    id={"selectAderenzaAllaPrescrizione"}
                    className={"selectAderenzaAllaPrescrizione"}
                    options={aderenzaAllaPrescrizioneOptions}
                    value={selectValue}
                    onChange={(elem) => this.handleAggiornaForm(selectField, elem, index)}
                    field={selectField.substring(selectField.indexOf("|") + 1, selectField.length)}
                    parentJsonPathSubObject={selectField.substring(0, selectField.indexOf("|"))}
                    isSearchable={false}
                    fieldLabel={'Aderenza alla prescrizione'}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />

                <RoleBasedInput
                    fieldId={'ADER_PRESCR_ENTERALE.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci note aderenza alla prescrizione"}
                    field={inputField.substring(inputField.indexOf("|") + 1, inputField.length)}
                    parentJsonPathSubObject={inputField.substring(0, inputField.indexOf("|"))}
                    onChange={(field, elem) => this.handleAggiornaForm(inputField, elem.target.value, index)}
                    id={"inputAderenzaAllaPrescrizione"}
                    value={inputValue ?? ""}
                    fieldLabel={'Note'}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
        );
    }

    render() {        
        return (
            <AccordionSezioneRichiesta
                idAccordion={"trattamentiDietetici"}
                title={"Trattamenti dietetici"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["informazioniDiBase.trattamentiDietetici",
                        "informazioniDiBase.trattamentiDietetici.prescrizioneDietetica",
                        "informazioniDiBase.trattamentiDietetici.prescrizioneDietetica.restrizioniDietetiche",
                        "informazioniDiBase.trattamentiDietetici.nutrizioneOS",
                        "informazioniDiBase.trattamentiDietetici.nutrizioneParenterale",
                        "informazioniDiBase.trattamentiDietetici.nutrizioneEnterale",
                        "informazioniDiBase.trattamentiDietetici.nutrizioneEnterale.nutrientePrescritto"],
                    [defaultTrattamentoDietetico, defaultPrescrizioneDietetica, defaultSulblistElement,
                        defaultNutrizioneOS, defaultNutrizioneParenterale, defaultNutrizioneEnterale,
                        defaultSulblistElement]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >
                <SectionList
                    addNewSectionCallback={this.addNewTrattamentoDietetico}
                    removeSectionCallback={this.removeTrattamentoDietetico}
                    data={_.cloneDeep(this.state.datiTrattamentiDietetici)}
                    title={'Trattamento dietetico'}
                    subtitle={'Trattamento dietetico'}
                    renderSection={this.renderNewTrattamentoDietetico}
                    keyFieldId={'TRATTAMENTO.TRATTAMENTIDIETETICI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    pageState={this.props.pageState}
                    field={"trattamentiDietetici"}
                    hideUnsetValues={this.props.hideUnsetValues}
                    addButtonVisibilityHandler={this.state.datiTrattamentiDietetici?.length === 0 || this.state.datiTrattamentiDietetici?.every(el => Object.prototype.hasOwnProperty.call(el, "trattamentoDietetico") ? !Utils.isObjectNull(el?.trattamento) : Object.keys(el).some(key => key !== "id" && el["trattamento"]?.value !== null))}
                    parentJsonPath={"informazioniDiBase.trattamentiDietetici"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
               />
            </AccordionSezioneRichiesta>
        );
    }
}

TrattamentiDietetici.propTypes = propTypes;
