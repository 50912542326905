import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionHelper from "../../../service/AccordionHelper";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import Orofaringe from "../../esameObiettivo/orofaringe/Orofaringe";
import Addome from "../../esameObiettivo/addome/Addome";
import Utils from "../../../utils/Utils";

const propTypes = {
    esameObiettivo: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeEsameObiettivo: PropTypes.func,
    openAccordion: PropTypes.any,
    openAccordionCapo: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta:PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

export default class Ingestione extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: {
            sottosezioneOrofaringe:0,
            sottosezioneAddome:0,
        },
        accordion: {
            openAccordionOrofaringe: true
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta.esameObiettivo = this.props.esameObiettivo;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        let campiObbligatoriMancanti = this.state.campiObbligatoriMancanti;
        campiObbligatoriMancanti[field] = value;
        this.setState({campiObbligatoriMancanti: campiObbligatoriMancanti}, () => {
            this.props.onChangeObbligatorieta("ingestione", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    renderBodySezione = () => {
        return (
            <>
                <Orofaringe
                    orofaringe={this.props.esameObiettivo.orofaringe}
                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                    openAccordion={this.state.accordion.openAccordionOrofaringe}
                    onClickAccordion={this.onClickAccordion}
                    pageState={this.props.pageState}
                    showAccordion={true}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    disableDoubleInput={Utils.isRuoloInfermiere()}
                />

                <Addome
                    dati={this.props.esameObiettivo.addome}
                    onChangeEsameObiettivo={this.props.onChangeEsameObiettivo}
                    openAccordion={this.state.accordion.openAccordionOrofaringe}
                    onClickAccordion={this.onClickAccordion}
                    pageState={this.props.pageState}
                    showAccordion={true}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    disableDoubleInput={Utils.isRuoloInfermiere()}
                />
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"ingestioneInfermiere"}
                title={"Ingestione"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBodySezione()}</AccordionAvvioRichiesta>
        );
    }
}

Ingestione.propTypes = propTypes;
