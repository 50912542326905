import React, {Component} from "react";
import PropTypes from 'prop-types'
import Select from "react-select";
import {DropdownIndicator, inputSelectStyles} from "../../common/select/SelectUtils";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json"
import * as $ from "jquery"
import {Button, Input} from "web-client-archetype";
import styles from "./FiltroRichieste.module.css";
import ModalHelper from "../../modale/ModalHelper";
import Calendar from "../../generalComponents/calendar/Calendar";
import Utils from "../../../utils/Utils";
import * as _ from "lodash";
import RichiestaADIService from "../../../service/RichiestaADIService";
import AuthUtils from "../../../utils/AuthUtils";
import FiltriUtils from "../../../utils/FiltriUtils";
import HomeUtils from "../HomeUtils";
import * as Icon from "react-bootstrap-icons";


const propTypes = {
    onChange: PropTypes.func,
    filtri: PropTypes.object,
    impostaFiltri: PropTypes.func
};

const defaultFiltriRichiesta = {
    codiceFiscale: "",
    dataRichiestaDa: "",
    dataRichiestaA: "",
    dataValutazioneDa: "",
    dataValutazioneA: "",
    dataValutazioneProfessionaleDa: "",
    dataValutazioneProfessionaleA: "",
    statoValidazioneMultidimensionale: "",
    statoValutazioneProfessionale: "",
    statoRichiesta: "",
    statoFormalizzazione: "",
    livelloPriorita: "",
    dataScadenzaRichiestaDa: "",
    dataScadenzaRichiestaA: "",
    statoSegnalazione: "",
    dataSegnalazioneDa: "",
    dataSegnalazioneA: "",
    classificazione:"",
}
const defaultFiltriAssistito = {
    cognome: "",
    nome: ""
}
const maxSelectHeight = 80;

export default class FiltriRichiesta extends Component {

    state = {
        filtriAssistito: _.cloneDeep(defaultFiltriAssistito),
        filtriRichiesta: _.cloneDeep(defaultFiltriRichiesta),
        mediciRichiedenti: "",
        showModale: false,
        disabledApplicaAnnullaFiltri: true,
        disabledApplicaFiltri: false,
        updatedLivelloPriorita: null,
        updatedStatoRichiesta: null
    };


    constructor(props) {
        super(props);
        this.handleAggiornaFiltriRichiesta = this.handleAggiornaFiltriRichiesta.bind(this);
        this.applicaFiltriRichiesta = this.applicaFiltriRichiesta.bind(this);
        this.annullaFiltriRichiesta = this.annullaFiltriRichiesta.bind(this);
        this.disableEnableData = this.disableEnableData.bind(this);
    }

    componentDidMount() {
        this.impostaMediciRichiedenti();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props?.filtri !== prevProps.filtri)
            this.setState({
                filtriAssistito: this.props.filtri.filtriAssistito,
                filtriRichiesta: this.props.filtri.filtriRichiesta
            })
        if ((prevState.filtriAssistito !== this.state.filtriAssistito || prevState.filtriRichiesta !== this.state.filtriRichiesta) && (Object.values(this.state.filtriAssistito).filter(el => el !== "").length === 0 || Object.values(this.state.filtriRichiesta).filter(el => el !== "").length === 0))
            this.setState({ disabledApplicaAnnullaFiltri: true })
        if ((prevState.filtriAssistito !== this.state.filtriAssistito || prevState.filtriRichiesta !== this.state.filtriRichiesta) && (Object.values(this.state.filtriAssistito).filter(el => el !== "").length > 0 || Object.values(this.state.filtriRichiesta).filter(el => el !== "").length > 0))
            this.setState({ disabledApplicaAnnullaFiltri: false })

        if ((prevState.filtriRichiesta?.statoValidazioneMultidimensionale?.label !== this.state.filtriRichiesta?.statoValidazioneMultidimensionale?.label) ||
            (prevState.filtriRichiesta?.statoValutazioneProfessionale?.label !== this.state.filtriRichiesta?.statoValutazioneProfessionale?.label) ||
            (prevState.filtriRichiesta?.statoRichiesta?.label !== this.state.filtriRichiesta?.statoRichiesta?.label))
            this.checkStatoRichiesta();

        if ((prevState.filtriRichiesta !== this.state.filtriRichiesta)) {
            this.checkDate();
        }

        // Appena viene aperta la modale dei filtri viene lanciata la funzione mapFiltri()
        if (prevState.showModale !== this.state.showModale && this.state.showModale)
            this.mapFiltri();
    }

    impostaMediciRichiedenti = () => {
        let mediciRichiedenti = _.cloneDeep(this.state.mediciRichiedenti);
        let params = {};
        params.filtro = FiltriUtils.getFiltriHomepage();
        RichiestaADIService.getMediciRichiedenti(params).subscribe((response) => {
            mediciRichiedenti = HomeUtils.estraiMediciRichiedenti(response);
            if (Array.isArray(mediciRichiedenti))
                mediciRichiedenti.unshift({ label: "-", value: null });
            this.setState({ mediciRichiedenti })
        })
    }

    handleAggiornaFiltriRichiesta(field, event) {
        let state = _.cloneDeep(this.state);
        let filtriAssistito = state.filtriAssistito;
        let filtriRichiesta = state.filtriRichiesta;

        if (field.currentTarget) {
            state[field.currentTarget.id] = field.currentTarget.value;
            filtriRichiesta[field.currentTarget.id] = field.currentTarget.value;
        } else if (field in filtriAssistito) {
            filtriAssistito[field] = event.target.value;
        } else {
            filtriRichiesta[field] = event.target.value;
        }

        state.disabledApplicaAnnullaFiltri = HomeUtils.disabledApplicaAnnullaFiltri(filtriAssistito, filtriRichiesta);
        this.setState(state);
    }

    //Conversione date da local a UTC
    applicaFiltriRichiesta() {
        let state = _.cloneDeep(this.state);
        let filtro = this.state.filtriRichiesta

        //Data richiesta
        if (state.filtriRichiesta.dataRichiestaDa) {
            filtro.dataRichiestaDa = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataRichiestaDa));
        }
        if (state.filtriRichiesta.dataRichiestaA) {
            filtro.dataRichiestaA = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataRichiestaA, true));
        }

        //Data valutazione professionale
        if (state.filtriRichiesta.dataValutazioneProfessionaleDa) {
            filtro.dataValutazioneProfessionaleDa = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataValutazioneProfessionaleDa));
        }
        if (state.filtriRichiesta.dataValutazioneProfessionaleA) {
            filtro.dataValutazioneProfessionaleA = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataValutazioneProfessionaleA, true));
        }

        //Data valutazione
        if (state.filtriRichiesta.dataValutazioneDa) {
            filtro.dataValutazioneDa = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataValutazioneDa));
        }
        if (state.filtriRichiesta.dataValutazioneA) {
            filtro.dataValutazioneA = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataValutazioneA, true));
        }

        //Data scadenza richiesta
        if (state.filtriRichiesta.dataScadenzaRichiestaDa) {
            filtro.dataScadenzaRichiestaDa = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataScadenzaRichiestaDa));
        }
        if (state.filtriRichiesta.dataScadenzaRichiestaA) {
            filtro.dataScadenzaRichiestaA = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataScadenzaRichiestaA, true));
        }

        //Data segnalazione
        if (state.filtriRichiesta.dataSegnalazioneDa) {
            filtro.dataSegnalazioneDa = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataSegnalazioneDa));
        }
        if (state.filtriRichiesta.dataSegnalazioneA) {
            filtro.dataSegnalazioneA = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataSegnalazioneA, true));
        }

        this.setState({ showModale: false, filtriRichiesta: filtro }, () => {
            this.props.impostaFiltri(this.state.filtriAssistito, this.state.filtriRichiesta, this.state.updatedLivelloPriorita, this.state.updatedStatoRichiesta);
        })

        this.chiudiModale();
    }

    annullaFiltriRichiesta() {
        this.setState({
            filtriAssistito: _.cloneDeep(defaultFiltriAssistito),
            filtriRichiesta: _.cloneDeep(defaultFiltriRichiesta), dataRichiestaDa: "",
            dataRichiestaA: "",
            dataValutazioneDa: "",
            dataValutazioneA: "",
            dataValutazioneProfessionaleDa: "",
            dataValutazioneProfessionaleA: "", disabledApplicaAnnullaFiltri: true
        });
    }

    chiudiModale() {
        this.setState({ showModale: false })
        $(".modal-backdrop").css("display", "none");
        $(".modal-open").removeClass("modal-open");
        $("body").attr("style", "padding-right: 0 !important");
    }

    mapFiltri = () => {
        let cloneFiltriRichiesta = _.cloneDeep(!Utils.isObjectEmpty(this.props.filtri.filtriRichiesta) ? this.props.filtri.filtriRichiesta : defaultFiltriRichiesta);
        let cloneFiltriAssistito = _.cloneDeep(!Utils.isObjectEmpty(this.props.filtri.filtriAssistito) ? this.props.filtri.filtriAssistito : defaultFiltriAssistito);

        for (var filtro in cloneFiltriRichiesta) {
            if (filtro.startsWith("data") && !Utils.isStringEmptyOrNullOrUndefined(cloneFiltriRichiesta[filtro])) {
                cloneFiltriRichiesta[filtro] = Utils.convertUtcToLocalDate(cloneFiltriRichiesta[filtro]).format("DD/MM/YYYY");
            }
        }

        this.setState({ filtriRichiesta: cloneFiltriRichiesta })
        this.setState({ filtriAssistito: cloneFiltriAssistito })
    }

    checkStatoRichiesta = () => {
        if (!Utils.isObjectEmpty(this.state.filtriRichiesta.statoRichiesta) && this.state.filtriRichiesta.statoRichiesta.value === "02") {
            let filtriRichiesta = _.cloneDeep(this.state.filtriRichiesta);

            filtriRichiesta.dataRichiestaDa = "";
            filtriRichiesta.dataRichiestaA = "";

            this.setState({ filtriRichiesta: filtriRichiesta })
            this.setState({ dataRichiestaDa: "", dataRichiestaA: "" })
        }

        if (!Utils.isObjectEmpty(this.state.filtriRichiesta.statoValidazioneMultidimensionale) && this.state.filtriRichiesta.statoValidazioneMultidimensionale.value === "02") {
            let filtriRichiesta = _.cloneDeep(this.state.filtriRichiesta);

            filtriRichiesta.dataValutazioneDa = "";
            filtriRichiesta.dataValutazioneA = "";

            this.setState({ filtriRichiesta: filtriRichiesta })
            this.setState({ dataValutazioneDa: "", dataValutazioneA: "" })
        }

        if (!Utils.isObjectEmpty(this.state.filtriRichiesta.statoValutazioneProfessionale) && this.state.filtriRichiesta.statoValutazioneProfessionale.value === "02") {
            let filtriRichiesta = _.cloneDeep(this.state.filtriRichiesta);

            filtriRichiesta.dataValutazioneProfessionaleDa = "";
            filtriRichiesta.dataValutazioneProfessionaleA = "";

            this.setState({ filtriRichiesta: filtriRichiesta })
            this.setState({ dataValutazioneProfessionaleDa: "", dataValutazioneProfessionaleA: "" })
        }
    }

    //Controlli sulle date
    checkDate = () => {
        if (this.isDataMaggioreDataCorrente("dataRichiestaDa") ||
            this.isDataMaggioreDataCorrente("dataRichiestaA") ||
            this.isDataMaggioreDataCorrente("dataValutazioneProfessionaleDa") ||
            this.isDataMaggioreDataCorrente("dataValutazioneProfessionaleA") ||
            this.isDataMaggioreDataCorrente("dataValutazioneDa") ||
            this.isDataMaggioreDataCorrente("dataValutazioneA") ||
            this.isDataDaMaggioreDataA("dataScadenzaRichiestaDa", "dataScadenzaRichiestaA") ||
            this.isDataDaMaggioreDataA("dataSegnalazioneDa", "dataSegnalazioneA") ||
            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataRichiestaA") ||
            this.isDataDaMaggioreDataA("dataValutazioneDa", "dataValutazioneA") ||
            this.isDataDaMaggioreDataA("dataValutazioneProfessionaleDa", "dataValutazioneProfessionaleA") ||
            this.isDataDaMaggioreDataA("dataValutazioneProfessionaleDa", "dataRichiestaA") ||
            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataValutazioneProfessionaleA") ||
            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataValutazioneA") ||
            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataValutazioneDa") ||
            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataValutazioneProfessionaleDa") ||
            this.isDataDaMaggioreDataA("dataSegnalazioneDa", "dataRichiestaDa")) {
            this.disabilitaApplicaFiltri();
        }
        else {
            this.abilitaApplicaFiltri();
        }
    }

    isDataMaggioreDataCorrente = (fieldData) => {
        let state = _.cloneDeep(this.state);

        if ((Utils.timeDifference(Date.now(), new Date(Utils.convertDDMMYYYYDateToIsoDate(state.filtriRichiesta[fieldData])), true) < 0 && !Utils.isStringEmptyOrNullOrUndefined(state.filtriRichiesta[fieldData]))) {
            return true;
        }
        else {
            return false;
        }
    }

    isDataDaMaggioreDataA = (fieldDataDa, fieldDataA) => {
        let state = _.cloneDeep(this.state);

        if ((!Utils.isStringEmptyOrNullOrUndefined(state.filtriRichiesta[fieldDataDa]) &&
            !Utils.isStringEmptyOrNullOrUndefined(state.filtriRichiesta[fieldDataA]) &&
            !Utils.checkDateIntervalMinore(state.filtriRichiesta[fieldDataDa], state.filtriRichiesta[fieldDataA], true))) {
            return true;
        }
        else {
            return false;
        }
    }

    //Gestione errori sulle date
    getDataError = (context) => {
        let erroreDataSuccessivaDataOdierna = "Non deve essere successiva alla data odierna";
        let erroreDataDaSuccessivaDataA = "Non deve essere successiva alla Data A";
        let erroreDataAPrecedenteDataDa = "Non deve essere precedente alla Data Da";
        let erroreDataPrecedenteAllaDataRichiestaDa = "Non deve essere precedente alla Data Richiesta Da";
        let erroreDataPrecedenteAllaDataSegnalazioneDa = "Non deve essere precedente alla Data Segnalazione Da";

        let error = {
            messaggio: "",
            valore: false
        };

        switch (context) {
            case "dataRichiestaDa":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA(context, "dataRichiestaA")) {
                    error.messaggio = erroreDataDaSuccessivaDataA;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataSegnalazioneDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataSegnalazioneDa;
                    error.valore = true;
                }
                // else if (this.isDataDaMaggioreDataA(context, "dataValutazioneProfessionaleDa") ||
                //     this.isDataDaMaggioreDataA(context, "dataValutazioneDa") ||
                //     this.isDataDaMaggioreDataA(context, "dataValutazioneA") ||
                //     this.isDataDaMaggioreDataA(context, "dataValutazioneProfessionaleA")) {
                //     error.valore = true;
                // }
                return error;
            case "dataRichiestaA":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                // else if (this.isDataDaMaggioreDataA("dataValutazioneProfessionaleDa", context) ||
                //     this.isDataDaMaggioreDataA("dataValutazioneDa", context) ||
                //     this.isDataDaMaggioreDataA("dataValutazioneProfessionaleA", context) ||
                //     this.isDataDaMaggioreDataA("dataValutazioneA", context)) {
                //     error.valore = true;
                // }
                return error;
            case "dataValutazioneProfessionaleDa":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA(context, "dataValutazioneProfessionaleA")) {
                    error.messaggio = erroreDataDaSuccessivaDataA;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataRichiestaDa;
                    error.valore = true;
                }
                return error;
            case "dataValutazioneProfessionaleA":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataValutazioneProfessionaleDa", context)) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataRichiestaDa;
                    error.valore = true;
                }
                return error;
            case "dataValutazioneDa":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA(context, "dataValutazioneA")) {
                    error.messaggio = erroreDataDaSuccessivaDataA;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataRichiestaDa;
                    error.valore = true;
                }
                return error;
            case "dataValutazioneA":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataValutazioneDa", context)) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataRichiestaDa;
                    error.valore = true;
                }
                return error;
            case "dataScadenzaRichiestaDa":
                if (this.isDataDaMaggioreDataA(context, "dataScadenzaRichiestaA")) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                return error;
            case "dataScadenzaRichiestaA":
                if (this.isDataDaMaggioreDataA("dataScadenzaRichiestaDa", context)) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                return error;
            case "dataSegnalazioneDa":
                if (this.isDataDaMaggioreDataA(context, "dataSegnalazioneA")) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                return error;
            case "dataSegnalazioneA":
                if (this.isDataDaMaggioreDataA("dataSegnalazioneDa", context)) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                return error;
        }
    }

    disableEnableData(dataDa, dataA, disable) {
        if (disable) {
            $(document).ready(function () {
                $("#" + dataDa).datepicker('disable');
                $("#" + dataA).datepicker('disable');
            })
            let newFiltro = this.state.filtriRichiesta
            newFiltro[dataDa] = ""
            newFiltro[dataA] = ""
            this.setState({ filtriRichiesta: newFiltro })
        } else {
            $(document).ready(function () {
                $("#" + dataDa).datepicker('enable');
                $("#" + dataA).datepicker('enable');
            })
        }

    }

    getNumeroFiltriApplicati = () => {
        let count = 0;

        for (var filtriAssistito in this.props.filtri.filtriAssistito) {
            if (!Utils.isStringEmptyOrNullOrUndefined(this.props.filtri.filtriAssistito[filtriAssistito])) count = count + 1;
        }

        for (var filtriRichiesta in this.props.filtri.filtriRichiesta) {
            if (!Utils.isStringEmptyOrNullOrUndefined(this.props.filtri.filtriRichiesta[filtriRichiesta])) count = count + 1;
        }

        return count;
    }

    disabilitaApplicaFiltri = () => {
        this.setState({ disabledApplicaFiltri: true })
    }

    abilitaApplicaFiltri = () => {
        this.setState({ disabledApplicaFiltri: false })
    }

    render() {
        const body = (
            <div className={"container"}>
                <div className="row">
                    <div className="col-lg-6">
                        <b>Priorità della richiesta</b>
                    </div>
                    <div className="col-lg-6">
                        <b>Scadenza</b>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-lg-6">
                        <div>
                            <p> Priorità </p>
                            <Select
                                id={"selectLivelloPriorita"}
                                className={"selectLivelloPriorita"}
                                classNamePrefix={"selectLivelloPriorita"}
                                components={DropdownIndicator}
                                options={OggettiUtili.livelloPrioritaSintesi}
                                value={this.state.filtriRichiesta.livelloPriorita}
                                onChange={(elem) => {
                                    let newFiltriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
                                    newFiltriRichiesta.livelloPriorita = elem.value == null ? "" : elem;
                                    this.setState({
                                        filtriRichiesta: newFiltriRichiesta,
                                        disabledApplicaAnnullaFiltri: false,
                                    })
                                }}
                                isSearchable={false}
                                styles={inputSelectStyles}
                                placeholder={"Seleziona il livello priorità"}
                                noOptionsMessage={() => "Errore: Nessun livello priorità disponibile"}
                                maxMenuHeight={maxSelectHeight}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <p> Data da </p>
                            <Calendar
                                id={"dataScadenzaRichiestaDa"}
                                value={this.state.filtriRichiesta.dataScadenzaRichiestaDa}
                                disabled={false}
                                field={"dataScadenzaRichiestaDa"}
                                onChange={this.handleAggiornaFiltriRichiesta}
                                errorMessage={this.getDataError("dataScadenzaRichiestaDa")?.messaggio}
                                error={this.getDataError("dataScadenzaRichiestaDa")?.valore}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <p> Data a </p>
                            <Calendar
                                id={"dataScadenzaRichiestaA"}
                                value={this.state.filtriRichiesta.dataScadenzaRichiestaA}
                                disabled={false}
                                field={"dataScadenzaRichiestaA"}
                                onChange={this.handleAggiornaFiltriRichiesta}
                                errorMessage={this.getDataError("dataScadenzaRichiestaA")?.messaggio}
                                error={this.getDataError("dataScadenzaRichiestaA")?.valore}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <b>Tipologia di ADI</b>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-lg-6">
                        <div>
                            <p> Tipologia </p>
                            <Select
                                id={"selectClassificazione"}
                                className={"selectClassificazione"}
                                classNamePrefix={"selectClassificazione"}
                                components={DropdownIndicator}
                                options={OggettiUtili.filtroClassificazione}
                                value={this.state.filtriRichiesta.classificazione}
                                onChange={(elem) => {
                                    let newFiltriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
                                    newFiltriRichiesta.classificazione = elem.value == null ? "" : elem;
                                    this.setState({
                                        filtriRichiesta: newFiltriRichiesta,
                                        disabledApplicaAnnullaFiltri: false,
                                    })
                                }}
                                isSearchable={false}
                                styles={inputSelectStyles}
                                placeholder={"Seleziona la Tipologia"}
                                noOptionsMessage={() => "Errore: Nessun livello priorità disponibile"}
                                maxMenuHeight={maxSelectHeight}
                            />
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-lg-8"}>
                        <b >Dati assistito</b>
                    </div>
                    <div className={"col-lg-4"}>
                        <b >Dati Formalizzazione</b>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-lg-4">
                        <div>
                            <p>&nbsp;</p>
                            <Input
                                parentClass={"mb-0"}
                                placeholder="Inserisci cognome utente"
                                field="cognome"
                                onChange={this.handleAggiornaFiltriRichiesta}
                                id="cognome"
                                value={this.state.filtriAssistito.cognome}
                                label="Cognome assistito"
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div>
                            <p>&nbsp;</p>
                            <Input
                                parentClass={"mb-0"}
                                placeholder="Inserisci nome utente"
                                field="nome"
                                onChange={this.handleAggiornaFiltriRichiesta}
                                id="nome"
                                value={this.state.filtriAssistito.nome}
                                label="Nome assistito"
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div>
                            <p>Stato formalizzazione</p>
                            <Select
                                id={"statoFormalizzazione"}
                                className={"statoFormalizzazione"}
                                classNamePrefix={"inputSelect"}
                                components={DropdownIndicator}
                                options={OggettiUtili.statiFormalizzazione}
                                value={this.state.filtriRichiesta.statoFormalizzazione}
                                onChange={(elem) => {
                                    let newFiltriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
                                    newFiltriRichiesta.statoFormalizzazione = elem.value == null ? "" : elem;
                                    this.setState({
                                        filtriRichiesta: newFiltriRichiesta,
                                        disabledApplicaAnnullaFiltri: false
                                    })
                                }}
                                isSearchable={false}
                                styles={inputSelectStyles}
                                placeholder={"Seleziona"}
                                noOptionsMessage={() => "Errore: Valutazione Multidimensionale non esistente"}
                                maxMenuHeight={maxSelectHeight}
                            />
                        </div>
                    </div>
                </div>
                <b >Dati Segnalazione</b>
                <div className="row mb-3">
                    {/* <div className="col-lg-6">
                        <div>
                            <p>Stato Segnalazione</p>
                            <Select
                                id={"selectStatoSegnalazione"}
                                className={"selectStatoSegnalazione"}
                                classNamePrefix={"selectStatoSegnalazione"}
                                components={DropdownIndicator}
                                options={OggettiUtili.statoRichiesta}
                                value={this.state.filtriRichiesta.statoSegnalazione}
                                onChange={(elem) => {
                                    let newFiltriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
                                    newFiltriRichiesta.statoSegnalazione = elem.value == null ? "" : elem;
                                    this.setState({
                                        filtriRichiesta: newFiltriRichiesta,
                                        disabledApplicaAnnullaFiltri: false
                                    })
                                }}
                                isSearchable={false}
                                styles={inputSelectStyles}
                                placeholder={"Seleziona"}
                                noOptionsMessage={() => "Errore: Nessun medico disponibile"}
                                maxMenuHeight={maxSelectHeight}
                            />
                        </div>
                    </div> */}

                    <div className="col-lg-6">
                        <div>
                            <p>Data Da</p>
                            <Calendar
                                id={"dataSegnalazioneDa"}
                                value={this.state.filtriRichiesta.dataSegnalazioneDa}
                                // disabled={this.state.filtriRichiesta.statoRichiesta ? this.state.filtriRichiesta.statoRichiesta.label === "Completata" : false}
                                field={"dataSegnalazioneDa"}
                                onChange={this.handleAggiornaFiltriRichiesta}
                                errorMessage={this.getDataError("dataSegnalazioneDa")?.messaggio}
                                error={this.getDataError("dataSegnalazioneDa")?.valore}
                            >
                            </Calendar>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div>
                            <p>Data A</p>
                            <Calendar
                                id={"dataSegnalazioneA"}
                                // disabled={this.state.filtriRichiesta.statoRichiesta ? this.state.filtriRichiesta.statoRichiesta.label === "Completata" : false}
                                value={this.state.filtriRichiesta.dataSegnalazioneA}
                                field={"dataSegnalazioneA"}
                                onChange={this.handleAggiornaFiltriRichiesta}
                                errorMessage={this.getDataError("dataSegnalazioneA")?.messaggio}
                                error={this.getDataError("dataSegnalazioneA")?.valore}
                            >
                            </Calendar>
                        </div>
                    </div>
                </div>
                <b >Dati di richiesta attivazione cure domiciliari</b>
                <div className="row mb-3">
                    <div className={AuthUtils.hasUtenteRuoloMedicoRichiedente() ? "col-lg-3" : "col-lg-6"}>
                        <div>
                            <p>Medico richiedente</p>
                            <Select
                                id={"mediciRichiedenti"}
                                className={"mediciRichiedenti"}
                                classNamePrefix={"mediciRichiedenti"}
                                components={DropdownIndicator}
                                options={this.state.mediciRichiedenti}
                                value={this.state.filtriRichiesta.codiceFiscale}
                                onChange={(elem) => {
                                    let newFiltriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
                                    newFiltriRichiesta.codiceFiscale = elem.value == null ? "" : elem;
                                    this.setState({
                                        filtriRichiesta: newFiltriRichiesta,
                                        disabledApplicaAnnullaFiltri: false
                                    })
                                }}
                                isSearchable={false}
                                styles={inputSelectStyles}
                                placeholder={"Seleziona"}
                                noOptionsMessage={() => "Errore: Nessun medico disponibile"}
                                maxMenuHeight={maxSelectHeight}
                            />
                        </div>
                    </div>
                    {AuthUtils.hasUtenteRuoloMedicoRichiedente() ?
                        <div className="col-lg-3">
                            <div>
                                <p>Stato Richiesta</p>
                                <Select
                                    id={"selectStatoRichiesta"}
                                    className={"selectStatoRichiesta"}
                                    classNamePrefix={"selectStatoRichiesta"}
                                    components={DropdownIndicator}
                                    options={OggettiUtili.statoRichiesta}
                                    value={this.state.filtriRichiesta.statoRichiesta}
                                    onChange={(elem) => {
                                        let newFiltriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
                                        newFiltriRichiesta.statoRichiesta = elem.value == null ? "" : elem;
                                        this.setState({
                                            filtriRichiesta: newFiltriRichiesta,
                                            disabledApplicaAnnullaFiltri: false,
                                            updatedStatoRichiesta: elem.value
                                        })
                                    }}
                                    isSearchable={false}
                                    styles={inputSelectStyles}
                                    placeholder={"Seleziona"}
                                    noOptionsMessage={() => "Errore: Nessun medico disponibile"}
                                    maxMenuHeight={maxSelectHeight}
                                />
                            </div>
                        </div> : null}

                    <div className="col-lg-3">
                        <div>
                            <p>Data Da</p>
                            <Calendar
                                id={"dataRichiestaDa"}
                                value={this.state.filtriRichiesta.dataRichiestaDa}
                                disabled={this.state.filtriRichiesta.statoRichiesta ? this.state.filtriRichiesta.statoRichiesta.label === "Completata" : false}
                                field={"dataRichiestaDa"}
                                onChange={this.handleAggiornaFiltriRichiesta}
                                errorMessage={this.getDataError("dataRichiestaDa")?.messaggio}
                                error={this.getDataError("dataRichiestaDa")?.valore}
                            >
                            </Calendar>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <p>Data A</p>
                            <Calendar
                                id={"dataRichiestaA"}
                                disabled={this.state.filtriRichiesta.statoRichiesta ? this.state.filtriRichiesta.statoRichiesta.label === "Completata" : false}
                                value={this.state.filtriRichiesta.dataRichiestaA}
                                field={"dataRichiestaA"}
                                onChange={this.handleAggiornaFiltriRichiesta}
                                errorMessage={this.getDataError("dataRichiestaA")?.messaggio}
                                error={this.getDataError("dataRichiestaA")?.valore}
                            >
                            </Calendar>
                        </div>
                    </div>
                </div>
                {AuthUtils.hasUtenteRuoloProfessionistaValutatore() || AuthUtils.hasUtenteRuoloCoordinatore() ?
                    <div>
                        <b >Dati valutazione professionale</b>
                        <div className="row mb-3">
                            <div className="col-lg-6">
                                <div>
                                    <p>Stato valutazione professionale</p>
                                    <Select
                                        id={"selectStatoValutazioneProfessionale"}
                                        className={"selectStatoValutazioneProfessionale"}
                                        classNamePrefix={"selectStatoValutazioneProfessionale"}
                                        components={DropdownIndicator}
                                        options={OggettiUtili.stato}
                                        value={this.state.filtriRichiesta.statoValutazioneProfessionale}
                                        onChange={(elem) => {
                                            let newFiltriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
                                            newFiltriRichiesta.statoValutazioneProfessionale = elem.value == null ? "" : elem;
                                            this.setState({
                                                filtriRichiesta: newFiltriRichiesta,
                                                disabledApplicaAnnullaFiltri: false
                                            })
                                        }}
                                        isSearchable={false}
                                        styles={inputSelectStyles}
                                        placeholder={"Seleziona"}
                                        noOptionsMessage={() => "Errore: Nessun stato valutazione professionale disponibile"}
                                        maxMenuHeight={maxSelectHeight}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div>
                                    <p>Data Da</p>
                                    <Calendar
                                        id={"dataValutazioneProfessionaleDa"}
                                        value={this.state.filtriRichiesta.dataValutazioneProfessionaleDa}
                                        field={"dataValutazioneProfessionaleDa"}
                                        onChange={this.handleAggiornaFiltriRichiesta}
                                        disabled={this.state.filtriRichiesta.statoValutazioneProfessionale ? (this.state.filtriRichiesta.statoValutazioneProfessionale.value === "01" || this.state.filtriRichiesta.statoValutazioneProfessionale.value === "03" || this.state.filtriRichiesta.statoValutazioneProfessionale.value === "04") : false}
                                        errorMessage={this.getDataError("dataValutazioneProfessionaleDa")?.messaggio}
                                        error={this.getDataError("dataValutazioneProfessionaleDa")?.valore}
                                    >
                                    </Calendar>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div>
                                    <p>Data A</p>
                                    <Calendar
                                        id={"dataValutazioneProfessionaleA"}
                                        value={this.state.filtriRichiesta.dataValutazioneProfessionaleA}
                                        disabled={this.state.filtriRichiesta.statoValutazioneProfessionale ? (this.state.filtriRichiesta.statoValutazioneProfessionale.value === "01" || this.state.filtriRichiesta.statoValutazioneProfessionale.value === "03" || this.state.filtriRichiesta.statoValutazioneProfessionale.value === "04") === "Completata" : false}
                                        field={"dataValutazioneProfessionaleA"}
                                        onChange={this.handleAggiornaFiltriRichiesta}
                                        errorMessage={this.getDataError("dataValutazioneProfessionaleA")?.messaggio}
                                        error={this.getDataError("dataValutazioneProfessionaleA")?.valore}
                                    >
                                    </Calendar>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }
                <b >Dati valutazione multidimensionale</b>
                <div className="row mb-3">
                    <div className="col-lg-6">
                        <div>
                            <p>Stato valutazione multidimensionale</p>

                            <Select
                                id={"selectValutazioneMultidimensionale"}
                                className={"statoValidazioneMultidimensionale"}
                                classNamePrefix={"inputSelect"}
                                components={DropdownIndicator}
                                options={OggettiUtili.stato}
                                value={this.state.filtriRichiesta.statoValidazioneMultidimensionale}
                                onChange={(elem) => {
                                    let newFiltriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
                                    newFiltriRichiesta.statoValidazioneMultidimensionale = elem.value == null ? "" : elem;
                                    this.setState({
                                        filtriRichiesta: newFiltriRichiesta,
                                        disabledApplicaAnnullaFiltri: false
                                    })
                                }}
                                isSearchable={false}
                                styles={inputSelectStyles}
                                placeholder={"Seleziona"}
                                noOptionsMessage={() => "Errore: Valutazione Multidimensionale non esistente"}
                                maxMenuHeight={maxSelectHeight}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <p>Data Da</p>
                            <Calendar
                                id={"dataValutazioneDa"}
                                value={this.state.filtriRichiesta.dataValutazioneDa}
                                field={"dataValutazioneDa"}
                                onChange={this.handleAggiornaFiltriRichiesta}
                                disabled={this.state.filtriRichiesta.statoValidazioneMultidimensionale ? (this.state.filtriRichiesta.statoValidazioneMultidimensionale.value === "01" || this.state.filtriRichiesta.statoValidazioneMultidimensionale.value === "03" || this.state.filtriRichiesta.statoValidazioneMultidimensionale.value === "04") : false}
                                errorMessage={this.getDataError("dataValutazioneDa")?.messaggio}
                                error={this.getDataError("dataValutazioneDa")?.valore}
                            >
                            </Calendar>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <p>Data A</p>
                            <Calendar
                                id={"dataValutazioneA"}
                                disabled={this.state.filtriRichiesta.statoValidazioneMultidimensionale ? (this.state.filtriRichiesta.statoValidazioneMultidimensionale.value === "01" || this.state.filtriRichiesta.statoValidazioneMultidimensionale.value === "03" || this.state.filtriRichiesta.statoValidazioneMultidimensionale.value === "04") : false}
                                value={this.state.filtriRichiesta.dataValutazioneA}
                                field={"dataValutazioneA"}
                                onChange={this.handleAggiornaFiltriRichiesta}
                                errorMessage={this.getDataError("dataValutazioneA")?.messaggio}
                                error={this.getDataError("dataValutazioneA")?.valore}
                            >
                            </Calendar>
                        </div>
                    </div>
                </div>
            </div>
        );

        const footer = (
            <>
                <div className={"col-3 text-left"}>
                    <Button
                        onClick={() => this.annullaFiltriRichiesta()}
                        disabled={this.state.disabledApplicaAnnullaFiltri}
                        id={"annullaFiltriBtn"}
                        text="Pulisci filtri"
                        className={"btn btn-adi btnGreenOutline"} />
                </div>
                <div className={"col-6 text-center"}>
                    <Button
                        onClick={() => this.applicaFiltriRichiesta()}
                        disabled={this.state.disabledApplicaAnnullaFiltri || this.state.disabledApplicaFiltri}
                        id={"applicaFiltriRichiesta"}
                        text="Applica filtri"
                        className={"btn btn-adi btnWhiteOutlineBorderGreen"} />
                </div>
                <div className={"col-3 text-right"}>
                    <Button
                        onClick={() => this.chiudiModale()}

                        id={"chiudiModale"}
                        text="Chiudi"
                        className={"btn btn-adi btnWhiteOutlineBorderGreen"} />
                </div>
                { }
            </>
        );

        const title = (
            <>
                <b id={"exampleModalLongTitle"}
                   className={"modal-title w-100"}
                   style={{color: "#176A65"}}>Cerca e filtra richieste</b>
            </>
        );

        return (
            <>
                {this.state.showModale && <ModalHelper id={"modaleFiltro"}
                    title={title}
                    titleClassName={"modal-header text-center pb-5"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered"}
                    contentClassName={("modal-content")}
                    body={body}
                    footer={footer}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={styles.bodyModal.concat(" modal-body")}
                />}

                <Button id={"btnCercaFiltraRichiesta"}
                        text={<>
                            <Icon.Search size={24} className={"mr-3"} style={{cursor: "pointer"}}/>
                            Filtra richieste
                        </>}
                        onClick={() => this.setState({showModale: true})}
                        className={["btn btn-adi btnGreenOutline mt-3 mb-2"].join(" ")}>
                </Button>
                {this.getNumeroFiltriApplicati() !== 0 &&
                    <span className={"px-2"}>
                        {this.getNumeroFiltriApplicati()} filtri applicati
                    </span>}
            </>
        );
    }
}

FiltriRichiesta.propTypes = propTypes;
