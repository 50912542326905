import React from "react";
import PropTypes from "prop-types";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {FieldsRow, RoleBasedSelect} from "../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";

export default function Ruolo(props) {
    return <AccordionSezioneRichiesta
        title={"Ruolo"}
        openAccordion={props.openAccordion}
        onClickAccordion={props.onClickAccordion}
        pageState={props.pageState}
        required={true}>
        <FieldsRow>
            <RoleBasedSelect
                fieldId={'RUOLO_SETT_ASSIST.RUOLO.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                id={"selectRuolo"}
                className={"selectRuolo"}
                options={OggettiUtili.ruoloSettingAssistenziale}
                value={props.datiMedico.ruolo}
                onChange={elem => props.onChangeDatiMedico("ruolo", elem)}
                fieldLabel={'Ruolo nel setting assistenziale'}
                field={"ruolo"}
                isSearchable={false}
                handleOnlyValue={true}
                fieldRequired={true}
                disableDoubleInput={true}
            />
        </FieldsRow>
    </AccordionSezioneRichiesta>;
}

Ruolo.propTypes = {
    pageState: PropTypes.string.isRequired,
    datiMedico: PropTypes.object.isRequired,
    editableFields: PropTypes.object.isRequired,
    onChangeDatiMedico: PropTypes.func.isRequired,
    openAccordion: PropTypes.bool.isRequired,
    onClickAccordion: PropTypes.func.isRequired
};
