import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import Box from "../../generalComponents/box/Box";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import {isArray} from "lodash";

const propTypes = {
    cuteEAnnessi: PropTypes.any,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber:  PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultSede = {
    id: null,
    sede: null,
    lesioni: []
}

const defaultLesione = {
    id: null,
    tipoLesione: null,
    lesioneDaPressione: null,
    cutePerilesionale: null,
    altezza: null,
    larghezza: null,
    profondita: null,
    caratteristicheMorfologiche: [],
    bordi: []
}

const defaultCaratteristicheMorfologiche = {
    id: null,
    caratteristicaMorfologica: null
}

const defaultBordi = {
    id: null,
    bordo: null
}

const defaultUstione = {
    id: null,
    grado: null,
    sede: null
}

const defaultDermatiti = {
    id: null,
    tipologia: null,
    sede: null,
    estensioni: null
}

const nomeAccordion = "CUTE_E_ANNESSI";

export default class CuteEAnnessi extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.cuteEAnnessi)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.cuteEAnnessi, this.props.cuteEAnnessi))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.cuteEAnnessi);
        this.setState({dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({dati}) => {
            if (index === null && subField === null) {
                dati[field] = elem;
            } else {
                dati[field][index][subField] = elem;
            }

            /* Alterazioni colorito */
            if (!dati.alterazioneColorito) {
                dati.alterazioneColoritoTipologia = null;
                dati.alterazioneColoritoCondizioneRilevata = null;
            }

            /* Alterazioni temperatura */
            if (!dati.alterazioneTemperatura) {
                dati.alterazioneTemperaturaAlterazione = null;
                dati.alterazioneTemperaturaTemperatura = null;
                dati.alterazioneTemperaturaNote = null;
                dati.alterazioneTemperaturaCondizioneRilevata = null;
            }
            if (dati.alterazioneTemperaturaAlterazione !== "03") {
                dati.alterazioneTemperaturaAlterazioneAltro = null;
            }

            /* Idratazione */
            if (dati.idratazione === null) {
                dati.idratazioneNote = null;
                dati.idratazioneCondizioneRilevata = null;
            }
            if (dati.idratazione !== "03") {
                dati.idratazioneNote = null;
            }

            /* Edemi */
            if (!dati.edemi) {
                dati.edemiSede = null;
                dati.edemiDescrizione = null;
                dati.edemiNote = null;
                dati.edemiCondizioneRilevata = null;
            }

            /* Cicatrici */
            if (!dati.cicatrici) {
                dati.cicatriciSede = null;
                dati.cicatriciNote = null;
                dati.cicatriciCondizioneRilevata = null;
            }

            /* Eritema */
            if (!dati.eritema) {
                dati.eritemaSede = null;
                dati.eritemaNote = null;
                dati.eritemaCondizioneRilevata = null;
            }

            /* Ecchimosi */
            if (!dati.ecchimosi) {
                dati.ecchimosiSede = null;
                dati.ecchimosiNote = null;
                dati.ecchimosiCondizioneRilevata = null;
            }

            /* Lesione */
            if (!dati.lesione) {
                dati.lesioneSedi = [];
                dati.lesioneCondizioneRilevata = null;
            }

            /* Ustioni */
            if (!dati.ustioni) {
                dati.listaUstioni = [];
                dati.ustioniCondizioneRilevata = null;
            }

            /* Dermatiti */
            if (!dati.dermatiti) {
                dati.listaDermatiti = [];
                dati.dermatitiPrurito = null;
                dati.dermatitiFrequenza = null;
                dati.dermatitiCondizioneRilevata = null;
            }
            if (!dati.dermatitiPrurito) {
                dati.dermatitiFrequenza = null;
            }

            /* Igiene */
            if (dati.igiene !== "02") {
                dati.igieneCondizioneRilevata = null;
            }

            return {dati};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        let dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("cuteEAnnessi", dati);
        let proposteAttEsugg = [];
        let campiObbligatoriRimanenti = 0;
        Object.entries(this.state.dati).forEach(([key, value]) => {
            if ((key.includes("PropostaAttivitaSuggerimenti") || key.includes("propostaAttivitaSuggerimenti")) && isArray(value)){
                value.forEach(element => {
                    proposteAttEsugg.push(element)
                });
            }
        });
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(proposteAttEsugg)
        this.props.onChangeRequiredFieldsNumber("sottosezioneCuteEAnnessi", campiObbligatoriRimanenti);

    }

    /* Lesione - sede */

    addNewSede = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultSede);
        type.id = uuid();
        dati.lesioneSedi.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeSede = (indexSede) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.lesioneSedi.splice(indexSede, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderSede = (dato, indexSede) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'M_SEDE_LESIO.CUTEEANNESSI.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_SEDE_LESIO.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci sede"}
                        field={"sede"}
                        onChange={(field, elem) => this.handleAggiornaForm("lesioneSedi", elem.target.value, indexSede, "sede")}
                        id={"inputLesioneSede" + indexSede}
                        value={dato.sede}
                        fieldLabel={"Sede"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                </FieldsRow>

                <SectionList
                    title={'Lesione'}
                    data={dato.lesioni}
                    renderSection={(e, i) => this.renderLesione(e, i, indexSede)}
                    addNewSectionCallback={() => this.addNewLesione(indexSede)}
                    removeSectionCallback={(index) => this.removeLesione(index, indexSede)}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    pageState={this.props.pageState}
                    keyFieldId={'M_TIPO_LESIO.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereKeyFieldId={'I_TIPO_LESIO.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    indentOffset={2}
                    field={'lesioni'}
                    parentJsonPath={"esameObiettivo.cuteEAnnessi.lesioneSedi[?(@.id == '" + dato?.id + "')].lesioni"}
                />
            </>
        );
    }

    /* Lesione - lesione */

    handleAggiornaLesione = (indexSede, indexLesione, field, elem) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.lesioneSedi[indexSede].lesioni[indexLesione][field] = elem;

        if (dati.lesioneSedi[indexSede].lesioni[indexLesione].tipoLesione !== "05")
            dati.lesioneSedi[indexSede].lesioni[indexLesione].lesioneDaPressione = null;

        this.setState({dati: dati}, () => this.validazioneDati());
    }

    addNewLesione = (indexSede) => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultLesione);
        type.id = uuid();
        dati.lesioneSedi[indexSede].lesioni.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeLesione = (indexLesione, indexSede) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.lesioneSedi[indexSede].lesioni.splice(indexLesione, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderLesione = (dato, indexLesione, indexSede) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_TIPO_LESIO.CUTEEANNESSI.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_TIPO_LESIO.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                        pageState={this.props.pageState}
                        id={"selectTipoLesione" + indexLesione}
                        options={OggettiUtili.tipoLesione}
                        value={dato.tipoLesione}
                        onChange={(elem) => this.handleAggiornaLesione(indexSede, indexLesione, "tipoLesione", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Tipo lesione non esistente"}
                        fieldLabel={"Tipo lesione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"tipoLesione"}
                    />
                    {dato.tipoLesione === "05" &&
                        <RoleBasedSelect
                            infermiereFieldId={'I_LES_DA_PRESS_STADIO.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            fieldId={'M_LES_DA_PRESS_STADIO.CUTEEANNESSI.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"selectLesioneDaPressione" + indexLesione}
                            options={OggettiUtili.stadioLesione}
                            value={dato.lesioneDaPressione}
                            onChange={(elem) => this.handleAggiornaLesione(indexSede, indexLesione, "lesioneDaPressione", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Lesione da pressione non esistente"}
                            fieldLabel={"Lesione da pressione - Stadio"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"lesioneDaPressione"}
                        />}
                    <RoleBasedSelect
                        fieldId={'M_CUTE_PERILES.CUTEEANNESSI.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_CUTE_PERILES.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                        pageState={this.props.pageState}
                        id={"selectLesioneCutePerilesionale" + indexLesione}
                        options={OggettiUtili.cutePerilesionale}
                        value={dato.cutePerilesionale}
                        onChange={(elem) => this.handleAggiornaLesione(indexSede, indexLesione, "cutePerilesionale", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Tipo lesione non esistente"}
                        fieldLabel={"Cute perilesionale"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"cutePerilesionale"}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'M_DIMENS_ALTEZZA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_DIMENS_ALTEZZA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci altezza"}
                        field={"altezza"}
                        onChange={(field, elem) => this.handleAggiornaLesione(indexSede, indexLesione, "altezza", elem.target.value)}
                        id={"inputLesioneAltezza" + indexLesione}
                        value={dato.altezza}
                        fieldLabel={"Altezza (cm)"}
                        type={"number"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                    <RoleBasedInput
                        fieldId={'M_DIMENS_LARGHEZZA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_DIMENS_LARGHEZZA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci larghezza"}
                        field={"profondita"}
                        onChange={(field, elem) => this.handleAggiornaLesione(indexSede, indexLesione, "larghezza", elem.target.value)}
                        id={"inputLesionelarghezza" + indexLesione}
                        value={dato.larghezza}
                        fieldLabel={"Larghezza (cm)"}
                        type={"number"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                    <RoleBasedInput
                        fieldId={'M_DIMENS_PROFONDITA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_DIMENS_PROFONDITA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci profondità"}
                        field={"profondita"}
                        onChange={(field, elem) => this.handleAggiornaLesione(indexSede, indexLesione, "profondita", elem.target.value)}
                        id={"inputLesioneProfondita" + indexLesione}
                        value={dato.profondita}
                        fieldLabel={"Profondità (cm)"}
                        type={"number"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                </FieldsRow>
                <SectionList
                    title={'Caratteristiche morfologiche'}
                    data={dato.caratteristicheMorfologiche}
                    renderSection={(e, i) => this.renderCaratteristicheMorfologiche(e, i, indexLesione, indexSede)}
                    addNewSectionCallback={() => this.addNewCaratteristicheMorfologiche(indexSede, indexLesione)}
                    removeSectionCallback={(i) => this.removeCaratteristicheMorfologiche(i, indexLesione, indexSede)}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    pageState={this.props.pageState}
                    keyFieldId={'M_CARAT_MORF.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereKeyFieldId={'I_CARAT_MORF.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    indentOffset={3}
                    field={'caratteristicheMorfologiche'}
                    parentJsonPath={"esameObiettivo.cuteEAnnessi.lesioneSedi.lesioni[?(@.id == '" + dato?.id + "')].caratteristicheMorfologiche"}
                />
                <SectionList
                    title={'Bordi'}
                    data={dato.bordi}
                    renderSection={(e, i) => this.renderBordi(e, i, indexLesione, indexSede)}
                    addNewSectionCallback={() => this.addNewBordi(indexSede, indexLesione)}
                    removeSectionCallback={(i) => this.removeBordi(i, indexLesione, indexSede)}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    pageState={this.props.pageState}
                    keyFieldId={'M_BORDI.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereKeyFieldId={'I_BORDI.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    indentOffset={3}
                    field={'bordi'}
                    parentJsonPath={"esameObiettivo.cuteEAnnessi.lesioneSedi.lesioni[?(@.id == '" + dato?.id + "')].bordi"}
                />
            </>
        );
    }

    /* Lesione - caratteristicheMorfologiche */

    handleAggiornaCaratteristicheMorfologiche = (indexSede, indexLesione, indexCaratteristicheMorfologiche, field, elem) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.lesioneSedi[indexSede].lesioni[indexLesione].caratteristicheMorfologiche[indexCaratteristicheMorfologiche][field] = elem;
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    addNewCaratteristicheMorfologiche = (indexSede, indexLesione) => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultCaratteristicheMorfologiche);
        type.id = uuid();
        dati.lesioneSedi[indexSede].lesioni[indexLesione].caratteristicheMorfologiche.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeCaratteristicheMorfologiche = (indexCaratteristicheMorfologiche, indexLesione, indexSede) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.lesioneSedi[indexSede].lesioni[indexLesione].caratteristicheMorfologiche.splice(indexCaratteristicheMorfologiche, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderCaratteristicheMorfologiche = (dato, indexCaratteristicheMorfologiche, indexLesione, indexSede) => {
        return <FieldsRow>
            <RoleBasedSelect
                fieldId={'M_CARAT_MORF.CUTEEANNESSI.ESAMEOBIETTIVO'}
                infermiereFieldId={'I_CARAT_MORF.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                pageState={this.props.pageState}
                id={"selectLesioneCaratteristiche" + indexCaratteristicheMorfologiche}
                options={OggettiUtili.caratteristicheMorfologiche}
                value={dato.caratteristicaMorfologica}
                onChange={(elem) => this.handleAggiornaCaratteristicheMorfologiche(indexSede, indexLesione, indexCaratteristicheMorfologiche, "caratteristicaMorfologica", elem)}
                isSearchable={false}
                noOptionsMessage={() => "Errore: Caratteristica morfologica non esistente"}
                fieldLabel={"Caratteristiche morfologiche"}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                disableDoubleInput={this.props.disableDoubleInput}
                hideUnsetValues={this.props.hideUnsetValues}
                handleOnlyValue={true}
                field={"caratteristicaMorfologica"}
            />
        </FieldsRow>;
    }

    /* Lesione - bordi */

    handleAggiornaBordi = (indexSede, indexLesione, indexBordi, field, elem) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.lesioneSedi[indexSede].lesioni[indexLesione].bordi[indexBordi][field] = elem;
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    addNewBordi = (indexSede, indexLesione) => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultBordi);
        type.id = uuid();
        dati.lesioneSedi[indexSede].lesioni[indexLesione].bordi.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeBordi = (indexBordi, indexLesione, indexSede) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.lesioneSedi[indexSede].lesioni[indexLesione].bordi.splice(indexBordi, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderBordi = (dato, indexBordi, indexLesione, indexSede) => {
        return <FieldsRow>
            <RoleBasedSelect
                fieldId={'M_BORDI.CUTEEANNESSI.ESAMEOBIETTIVO'}
                infermiereFieldId={'I_BORDI.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                pageState={this.props.pageState}
                id={"selectLesioneBordi" + indexBordi}
                options={OggettiUtili.bordi}
                value={dato.bordo}
                onChange={(elem) => this.handleAggiornaBordi(indexSede, indexLesione, indexBordi, "bordo", elem)}
                isSearchable={false}
                noOptionsMessage={() => "Errore: bordo non esistente"}
                fieldLabel={"Bordi"}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                disableDoubleInput={this.props.disableDoubleInput}
                hideUnsetValues={this.props.hideUnsetValues}
                handleOnlyValue={true}
                field={"bordo"}
            />
        </FieldsRow>;
    }

    /* Ustioni */

    addNewUstione = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultUstione);
        type.id = uuid();
        dati.listaUstioni.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeUstione = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.listaUstioni.splice(index, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderUstione = (ustione, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'M_GRADO_UST.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_GRADO_UST.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    pageState={this.props.pageState}
                    id={"selectUstioniGrado" + index}
                    options={OggettiUtili.ustioniGrado}
                    value={ustione.grado}
                    onChange={(elem) => this.handleAggiornaForm("listaUstioni", elem, index, "grado")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Ustioni grado non esistente"}
                    fieldLabel={"Grado"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    handleOnlyValue={true}
                    field={"grado"}
                />
                <RoleBasedInput
                    fieldId={'M_SEDE_UST.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_SEDE_UST.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci sede"}
                    field={"sede"}
                    onChange={(field, elem) => this.handleAggiornaForm("listaUstioni", elem.target.value, index, field)}
                    id={"inputUstioniSede" + index}
                    value={ustione.sede}
                    fieldLabel={"Sede"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
            </FieldsRow>
        );
    }

    /* Dermatiti */

    addNewDermatite = () => {
        let dati = _.cloneDeep(this.state.dati);
        let type = _.cloneDeep(defaultDermatiti);
        type.id = uuid();
        dati.listaDermatiti.push(type);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeDermatite = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.listaDermatiti.splice(index, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderDermatite = (dermatite, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'M_TIPO_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm("listaDermatiti", elem.target.value, index, field)}
                    value={dermatite.tipologia}
                    field={"tipologia"}
                    fieldLabel={"Tipologia"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
                <RoleBasedInput
                    fieldId={'M_SEDE_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_SEDE_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci sede"}
                    field={"sede"}
                    onChange={(field, elem) => this.handleAggiornaForm("listaDermatiti", elem.target.value, index, field)}
                    id={"inputDermatiteSede" + index}
                    value={dermatite.sede}
                    fieldLabel={"Sede"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
                <RoleBasedInput
                    fieldId={'M_EST_DIM_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_EST_DIM_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci estensioni o dimensioni"}
                    field={"estensioni"}
                    onChange={(field, elem) => this.handleAggiornaForm("listaDermatiti", elem.target.value, index, field)}
                    id={"inputDermatiteEstensioni" + index}
                    value={dermatite.estensioni}
                    fieldLabel={"Estensioni o dimensioni (cm)"}
                    type={"number"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
            </FieldsRow>
        );
    }

    handleDispositiviArrayChange = (array, field) => {
        let data = _.cloneDeep(this.state.dati);
        data[field] = array;
        this.setState({dati: data}, () => {
            this.validazioneDati();
            this.forceUpdate();
        });
    }

    renderBodySezione() {
        let dati = this.state.dati;
        return (
            <>
                { /* Alterazioni colorito */
                    <Box key={"boxAlterazioneColorito" + nomeAccordion} titleBox={"Colorito"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_PRES_ALT_COLOR.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_PRES_ALT_COLOR.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectAlterazioneColorito"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.alterazioneColorito}
                                onChange={(elem) => this.handleAggiornaForm("alterazioneColorito", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Alterazione colorito non esistente"}
                                fieldLabel={"Presenza di alterazione colorito"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"alterazioneColorito"}
                            />
                        </FieldsRow>

                        {dati.alterazioneColorito &&
                            <FieldsRow>
                                <RoleBasedSelect
                                    fieldId={'M_TIP_COLOR.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_TIP_COLOR.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    id={"selectAlterazioneColorito"}
                                    options={OggettiUtili.tipologiaColorito}
                                    value={dati.alterazioneColoritoTipologia}
                                    onChange={(elem) => this.handleAggiornaForm("alterazioneColoritoTipologia", elem)}
                                    isSearchable={false}
                                    noOptionsMessage={() => "Errore: Tipologia colorito non esistente"}
                                    fieldLabel={"Tipologia colorito"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    handleOnlyValue={true}
                                    field={"alterazioneColoritoTipologia"}
                                />
                            </FieldsRow>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI COLORITO'}
                            appendRolePrefix={true}
                            fieldsSuffix={'COLOR'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("alterazioneColoritoCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.alterazioneColoritoCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("alterazioneColoritoDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.alterazioneColoritoDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'alterazionecolorito'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("alterazioneColoritoPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.alterazioneColoritoPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.alterazioneColorito}
                        />
                    </Box>
                }

                { /* Alterazioni temperatura */
                    <Box key={"boxAlterazioneTemperatura" + nomeAccordion} titleBox={"Temperatura"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_PRES_ALT_TEMP.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_PRES_ALT_TEMP.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectAlterazioneTemperatura"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.alterazioneTemperatura}
                                onChange={(elem) => this.handleAggiornaForm("alterazioneTemperatura", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Alterazione temperatura non esistente"}
                                fieldLabel={"Presenza di alterazione temperatura"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"alterazioneTemperatura"}
                            />
                        </FieldsRow>

                        {dati.alterazioneTemperatura && <>
                            <FieldsRow>
                                <RoleBasedSelect
                                    fieldId={'M_ALT_TEMP.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_ALT_TEMP.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    id={"selectAlterazioneTemperaturaAlterazione"}
                                    options={OggettiUtili.alterazioneFebbre}
                                    value={dati.alterazioneTemperaturaAlterazione}
                                    onChange={(elem) => this.handleAggiornaForm("alterazioneTemperaturaAlterazione", elem)}
                                    isSearchable={false}
                                    noOptionsMessage={() => "Errore: Alterazione non esistente"}
                                    fieldLabel={"Alterazione"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    handleOnlyValue={true}
                                    field={"alterazioneTemperaturaAlterazione"}
                                />
                                <RoleBasedInput
                                    fieldId={'M_TEMP.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_TEMP.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci temperatura"}
                                    field={"alterazioneTemperaturaTemperatura"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                    id={"inputAlterazioneTemperaturaTemperatura"}
                                    value={dati.alterazioneTemperaturaTemperatura}
                                    fieldLabel={"Temperatura °C"}
                                    type={"number"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>

                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_NOTE_TEMP.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_NOTE_TEMP.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci note"}
                                    field={"alterazioneTemperaturaNote"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                    id={"inputAlterazioneTemperaturaNote"}
                                    value={dati.alterazioneTemperaturaNote}
                                    fieldLabel={"Note"}
                                    type={"textarea"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>
                        </>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI TEMPERATURA'}
                            appendRolePrefix={true}
                            fieldsSuffix={'TEMP'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("alterazioneTemperaturaCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.alterazioneTemperaturaCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("alterazioneTemperaturaDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.alterazioneTemperaturaDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'alterazioneTemperatura'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("alterazioneTemperaturaPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.alterazioneTemperaturaPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.alterazioneTemperatura}
                        />
                    </Box>
                }

                { /* Idratazione */
                    <Box key={"boxIdratazione" + nomeAccordion} titleBox={"Idratazione"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_IDRAT.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_IDRAT.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                pageState={this.props.pageState}
                                id={"selectIdratazione"}
                                options={OggettiUtili.idratazione}
                                value={dati.idratazione}
                                onChange={(elem) => this.handleAggiornaForm("idratazione", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Idratazione non esistente"}
                                fieldLabel={"Idratazione"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"idratazione"}
                            />
                            {dati.idratazione === "03" &&
                                <RoleBasedInput
                                    fieldId={'M_IDRAT.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_IDRAT.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci note"}
                                    field={"idratazioneNote"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                    id={"inputIdratazioneNote"}
                                    value={dati.idratazioneNote}
                                    fieldLabel={"Note"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />}
                        </FieldsRow>

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI IDRATAZIONE'}
                            appendRolePrefix={true}
                            fieldsSuffix={'IDRAT'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("idratazioneCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.idratazioneCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("idratazioneDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.idratazioneDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'idratazione'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("idratazionePropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.idratazionePropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.idratazione !== null}
                        />
                    </Box>
                }

                { /* Edemi */
                    <Box key={"boxEdemi" + nomeAccordion} titleBox={"Edemi"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_EDEMI.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_EDEMI.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectEdemi"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.edemi}
                                onChange={(elem) => this.handleAggiornaForm("edemi", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Edemi non esistente"}
                                fieldLabel={"Edemi"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"edemi"}
                            />
                        </FieldsRow>

                        {dati.edemi && <>
                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_SEDE_EDEMI.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_SEDE_EDEMI.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci sede"}
                                    field={"edemiSede"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                    id={"inputEdemiSede"}
                                    value={dati.edemiSede}
                                    fieldLabel={"Sede"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                                <RoleBasedInput
                                    fieldId={'M_DESCR_EDEMI.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_DESCR_EDEMI.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci descrizione"}
                                    field={"edemiDescrizione"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                    id={"inputEdemiDescrizione"}
                                    value={dati.edemiDescrizione}
                                    fieldLabel={"Descrizione"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>

                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_NOTE_EDEMI.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_NOTE_EDEMI.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci note"}
                                    field={"edemiNote"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                    id={"inputEdemiNote"}
                                    value={dati.edemiNote}
                                    fieldLabel={"Note"}
                                    type={"textarea"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>
                        </>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI EDEMI'}
                            appendRolePrefix={true}
                            fieldsSuffix={'EDEMI'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("edemiCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.edemiCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("edemiDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.edemiDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'edemi'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("edemiPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.edemiPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.edemi}
                        />
                    </Box>
                }

                { /* Cicatrici chirurgiche */
                    <Box key={"boxCicatrici" + nomeAccordion} titleBox={"Cicatrici chirurgiche"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_CIC_CHIR.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_CIC_CHIR.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectCicatrici"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.cicatrici}
                                onChange={(elem) => this.handleAggiornaForm("cicatrici", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Cicatrici chirurgiche non esistente"}
                                fieldLabel={"Cicatrici chirurgiche"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"cicatrici"}
                            />
                        </FieldsRow>

                        {dati.cicatrici && <>
                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_SEDE_CIC_CHIR.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_SEDE_CIC_CHIR.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci sede"}
                                    field={"cicatriciSede"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                    id={"inputCicatriciSede"}
                                    value={dati.cicatriciSede}
                                    fieldLabel={"Sede"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>

                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_NOTE_CIC_CHIR.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_NOTE_CIC_CHIR.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci note"}
                                    field={"cicatriciNote"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                    id={"inputEdemiNote"}
                                    value={dati.cicatriciNote}
                                    fieldLabel={"Note"}
                                    type={"textarea"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>
                        </>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI CICATRICI CHIRURGICHE'}
                            appendRolePrefix={true}
                            fieldsSuffix={'CIC_CHIR'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("cicatriciCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.cicatriciCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("cicatriciDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.cicatriciDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'cicatrici'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("cicatriciPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.cicatriciPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.cicatrici}
                        />
                    </Box>
                }

                { /* Eritema */
                    <Box key={"boxEritema" + nomeAccordion} titleBox={"Eritema/Eruzione"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_ERIT_ERUZ.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_ERIT_ERUZ.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectEritema"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.eritema}
                                onChange={(elem) => this.handleAggiornaForm("eritema", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Eritema chirurgiche non esistente"}
                                fieldLabel={"Eritema/Eruzione"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"eritema"}
                            />
                        </FieldsRow>

                        {dati.eritema && <>
                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_SEDE_ERIT_ERUZ.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_SEDE_ERIT_ERUZ.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci sede"}
                                    field={"eritemaSede"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                    id={"inputEritemaSede"}
                                    value={dati.eritemaSede}
                                    fieldLabel={"Sede"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>

                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_NOTE_ERIT_ERUZ.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_NOTE_ERIT_ERUZ.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci note"}
                                    field={"eritemaNote"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                    id={"inputEritemaNote"}
                                    value={dati.eritemaNote}
                                    fieldLabel={"Note"}
                                    type={"textarea"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>
                        </>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI ERITEMA/ERUZIONI'}
                            appendRolePrefix={true}
                            fieldsSuffix={'ERIT_ERUZ'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("eritemaCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.eritemaCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("eritemaDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.eritemaDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'eritema'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("eritemaPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.eritemaPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.eritema}
                        />
                    </Box>
                }

                { /* Ecchimosi */
                    <Box key={"boxEcchimosi" + nomeAccordion} titleBox={"Ecchimosi/Petecchie"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_ECCHIM.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_ECCHIM.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectEcchimosi"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.ecchimosi}
                                onChange={(elem) => this.handleAggiornaForm("ecchimosi", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Ecchimosi chirurgiche non esistente"}
                                fieldLabel={"Ecchimosi/Petecchie"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"ecchimosi"}
                            />
                        </FieldsRow>

                        {dati.ecchimosi && <>
                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_SEDE_ECCHIM.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_SEDE_ECCHIM.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci sede"}
                                    field={"ecchimosiSede"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                    id={"inputEcchimosiSede"}
                                    value={dati.ecchimosiSede}
                                    fieldLabel={"Sede"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>

                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_NOTE_ECCHIM.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_NOTE_ECCHIM.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci note"}
                                    field={"ecchimosiNote"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                    id={"inputEcchimosiNote"}
                                    value={dati.ecchimosiNote}
                                    fieldLabel={"Note"}
                                    type={"textarea"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>
                        </>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI ECCHIMOSI/PETECCHIE'}
                            appendRolePrefix={true}
                            fieldsSuffix={'ECCHIM'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("ecchimosiCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.ecchimosiCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("ecchimosiDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.ecchimosiDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'ecchimosi'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("ecchimosiPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.ecchimosiPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.ecchimosi}
                        />
                    </Box>
                }

                { /* Lesioni */
                    <Box key={"boxLesioni" + nomeAccordion} titleBox={"Lesioni"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_LESIO.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_LESIO.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectLesione"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.lesione}
                                onChange={(elem) => this.handleAggiornaForm("lesione", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Lesione non esistente"}
                                fieldLabel={"Lesioni"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"lesione"}
                            />
                        </FieldsRow>

                        {dati.lesione &&
                            <SectionList
                                title={'Sede'}
                                data={dati.lesioneSedi}
                                renderSection={this.renderSede}
                                addNewSectionCallback={this.addNewSede}
                                removeSectionCallback={this.removeSede}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                pageState={this.props.pageState}
                                keyFieldId={'M_SEDE_LESIO.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereKeyFieldId={'I_SEDE_LESIO.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                field={'lesioneSedi'}
                                parentJsonPath={"esameObiettivo.cuteEAnnessi.lesioniSedi"}
                            />}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI LESIONI'}
                            appendRolePrefix={true}
                            fieldsSuffix={'LESIO'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("lesioneCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.lesioneCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("lesioneDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.lesioneDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'lesione'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("lesionePropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.lesionePropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.lesione}
                        />
                    </Box>
                }

                { /* Ustioni */
                    <Box key={"boxUstioni" + nomeAccordion} titleBox={"Ustioni"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_UST.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_UST.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectUstioni"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.ustioni}
                                onChange={(elem) => this.handleAggiornaForm("ustioni", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Ustioni non esistente"}
                                fieldLabel={"Ustioni"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"ustioni"}
                            />
                        </FieldsRow>

                        {dati.ustioni &&
                            <SectionList
                                title={'Ustione'}
                                data={dati.listaUstioni}
                                renderSection={this.renderUstione}
                                addNewSectionCallback={this.addNewUstione}
                                removeSectionCallback={this.removeUstione}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                pageState={this.props.pageState}
                                keyFieldId={'M_GRADO_UST.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereKeyFieldId={'I_GRADO_UST.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                field={'listaUstioni'}
                                parentJsonPath={"esameObiettivo.cuteEAnnessi.listaUstioni"}
                            />}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI USTIONI'}
                            appendRolePrefix={true}
                            fieldsSuffix={'UST'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("ustioniCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.ustioniCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("ustioniDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.ustioniDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'ustioni'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("ustioniPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.ustioniPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.ustioni}
                        />
                    </Box>
                }

                { /* Dermatiti */
                    <Box key={"boxDermatiti" + nomeAccordion} titleBox={"Dermatiti/Micosi/Psoriasi/Parassitosi"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectDermatiti"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.dermatiti}
                                onChange={(elem) => this.handleAggiornaForm("dermatiti", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Dermatiti chirurgiche non esistente"}
                                fieldLabel={"Dermatiti/Micosi/Psoriasi/Parassitosi"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                field={"dermatiti"}
                                handleOnlyValue={true}
                            />
                        </FieldsRow>

                        {dati.dermatiti && <>
                            <SectionList
                                title={'Dermatite'}
                                data={dati.listaDermatiti}
                                renderSection={this.renderDermatite}
                                addNewSectionCallback={this.addNewDermatite}
                                removeSectionCallback={this.removeDermatite}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                pageState={this.props.pageState}
                                keyFieldId={'M_TIPO_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereKeyFieldId={'I_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                field={'listaDermatiti'}
                                parentJsonPath={"esameObiettivo.cuteEAnnessi.listaDermatiti"}
                            />

                            <FieldsRow>
                                <RoleBasedSelect
                                    fieldId={'M_PRUR_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_PRUR_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    id={"selectDermatitePrurito"}
                                    options={OggettiUtili.rispostaBreve}
                                    value={dati.dermatitiPrurito}
                                    onChange={(elem) => this.handleAggiornaForm("dermatitiPrurito", elem)}
                                    isSearchable={false}
                                    noOptionsMessage={() => "Errore: Prurito non esistente"}
                                    fieldLabel={"Prurito"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    handleOnlyValue={true}
                                    field={"dermatitiPrurito"}
                                />
                                {dati.dermatitiPrurito &&
                                    <RoleBasedSelect
                                        fieldId={'M_FREQ_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                        infermiereFieldId={'I_FREQ_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                        pageState={this.props.pageState}
                                        id={"selectDermatiteFrequenza"}
                                        options={OggettiUtili.dermatitiFrequenza}
                                        value={dati.dermatitiFrequenza}
                                        onChange={(elem) => this.handleAggiornaForm("dermatitiFrequenza", elem)}
                                        isSearchable={false}
                                        noOptionsMessage={() => "Errore: Frequenza non esistente"}
                                        fieldLabel={"Frequenza"}
                                        forceReadOnly={this.props.forceReadOnly}
                                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                        disableDoubleInput={this.props.disableDoubleInput}
                                        hideUnsetValues={this.props.hideUnsetValues}
                                        handleOnlyValue={true}
                                        field={"dermatitiFrequenza"}
                                    />}
                            </FieldsRow>
                        </>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI DERMATITI/MICOSI/PSORIASI/PARASSITOSI'}
                            appendRolePrefix={true}
                            fieldsSuffix={'MI_PS_PA'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("dermatitiCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.dermatitiCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("dermatitiDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.dermatitiDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'dermatiti'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("dermatitiPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.dermatitiPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.dermatiti}
                        />
                    </Box>
                }

                { /* Igiene */
                    <Box key={"boxIgiene" + nomeAccordion} titleBox={"Igiene"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_IGIENE.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_IGIENE.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectIgiene"}
                                options={OggettiUtili.igiene}
                                value={dati.igiene}
                                onChange={(elem) => this.handleAggiornaForm("igiene", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Igiene non esistente"}
                                fieldLabel={"Igiene"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"igiene"}
                            />
                        </FieldsRow>

                        <DispositiviPresenti
                            handleArrayChange={this.handleDispositiviArrayChange}
                            dispositiviPresenti={dati.listaDispositiviIgiene}
                            pageState={this.props.pageState}
                            fieldId={'M_DISP_IG.CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_DISP_IG.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            field={"listaDispositiviIgiene"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            parentJsonPath={"esameObiettivo.cuteEAnnessi.listaDispositiviIgiene"}
                        />

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI IGIENE'}
                            appendRolePrefix={true}
                            fieldsSuffix={'IG'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("igieneCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.igieneCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("igieneDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.igieneDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'igiene'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm("igienePropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.igienePropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.igiene === "02"}
                        />
                    </Box>}
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"cuteEAnnessi"}
                    title={"Cute"}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.cuteEAnnessi.lesioneSedi", "esameObiettivo.cuteEAnnessi.lesioneSedi.lesioni",
                            "esameObiettivo.cuteEAnnessi.lesioneSedi.lesioni.caratteristicheMorfologiche",
                            "esameObiettivo.cuteEAnnessi.lesioneSedi.lesioni.bordi",
                            "esameObiettivo.cuteEAnnessi.listaUstioni", "esameObiettivo.cuteEAnnessi.listaDermatiti",
                            "esameObiettivo.cuteEAnnessi.listaDispositiviIgiene",
                            "esameObiettivo.cuteEAnnessi.propostaAttivitaSuggerimenti"],
                        [defaultSede, defaultLesione, defaultCaratteristicheMorfologiche, defaultBordi,
                            defaultUstione, defaultDermatiti, defaultDispositiviPresenti,
                            defaultProposteAttivitaSuggerimenti]
                    )}
                    accordionReadOnly={this.props.accordionReadOnly}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}
CuteEAnnessi.propTypes = propTypes;

CuteEAnnessi.defaultProps = {
    showAccordion: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}
