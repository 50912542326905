import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {FieldsRow, RoleBasedSelect, SectionList} from "../../roleBasedComponents/RoleBasedComponents";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";

const propTypes = {
    fornitureAttive: PropTypes.any,
    onChangeDatiFornitureSanitarie: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

const defaultFornitura = {
    id: null,
    fornituraAttiva: null
}

export default class FornitureSanitarieAttive extends AccordionSecondLevel {

    state = {
        fornitureAttive: _.cloneDeep(this.props.fornitureAttive)
    }

    handleAggiornaForm = (field, elem, index) => {
        let fornitureAttive = _.cloneDeep(this.state.fornitureAttive);
        let fornitura = fornitureAttive[index];
        fornitura[field] = elem ?? null;

        fornitureAttive[index] = fornitura;

        this.setState({fornitureAttive}, () => this.props.onChangeDatiFornitureSanitarie("fornitureAttive", _.cloneDeep(this.state.fornitureAttive)));
    }

    addNewFornitura = () => {
        let fornituraAttiva = _.cloneDeep(defaultFornitura);
        fornituraAttiva.id = uuid();
        let fornitureAttive = [..._.cloneDeep(this.state.fornitureAttive), fornituraAttiva];
        this.setState({fornitureAttive}, () => this.props.onChangeDatiFornitureSanitarie("fornitureAttive", _.cloneDeep(this.state.fornitureAttive)));
    }

    removeFornitura = (index) => {
        let fornitureAttive = _.cloneDeep(this.state.fornitureAttive);
        fornitureAttive.splice(index, 1);

        this.setState({fornitureAttive}, () => this.props.onChangeDatiFornitureSanitarie("fornitureAttive", _.cloneDeep(this.state.fornitureAttive)));
    }


    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"fornitureAttive"}
                title={"Forniture sanitarie attive"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["informazioniDiBase.fornitureAttive"],
                    [defaultFornitura]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >
                <SectionList
                    title={'Fornitura sanitaria attiva'}
                    data={this.state.fornitureAttive}
                    renderSection={this.renderFornitura}
                    addNewSectionCallback={this.addNewFornitura}
                    removeSectionCallback={this.removeFornitura}
                    pageState={this.props.pageState}
                    keyFieldId={'FORNIT_ATTIVE.FORNITURESANITARIEATTIVE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={'fornitureAttive'}
                    addButtonVisibilityHandler={this.state.fornitureAttive === 0 || this.state.fornitureAttive.every(el => !Utils.isObjectNull(el?.fornituraAttiva))}
                    parentJsonPath={"informazioniDiBase.fornitureAttive"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </AccordionSezioneRichiesta>
        );
    }

    renderFornitura = (fornitura, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'FORNIT_ATTIVE.FORNITURESANITARIEATTIVE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    pageState={this.props.pageState}
                    id={"selectFornitureAttive " + index}
                    options={OggettiUtili.fornitureAttive}
                    value={fornitura.fornituraAttiva}
                    onChange={(elem) => this.handleAggiornaForm("fornituraAttiva", elem, index)}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Forniture attive non esistente"}
                    fieldRequired= {false}
                    handleOnlyValue={true}
                    field={"fornituraAttiva"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </FieldsRow>
        );
    }
}

FornitureSanitarieAttive.propTypes = propTypes;

