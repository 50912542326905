import Permissions from '../../utils/dataset/matrice/permissions.json';
import PermissionsDp from '../../utils/dataset/matrice/permissions_dp.json';
import React, {Fragment, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {globals, Icon, Input, SessioneUtente} from 'web-client-archetype';
import PropTypes from 'prop-types';
import Select from "react-select";
import Calendar from "../generalComponents/calendar/Calendar";
import Clock from "../generalComponents/clock/Clock";
import AsyncPaginate from "react-select-async-paginate";
import RadioButton from "../generalComponents/radiobutton/RadioButton"
import CheckBox from "../generalComponents/checkbox/CheckBox"
import {DropdownIndicator, inputSelectStyles} from "../common/select/SelectUtils";
import PopoverReact from "../popover/Popover";
import ReactDOMServer from 'react-dom/server';
import enumsUtente from '../../enum/enumsUtente.json'
import Autocomplete from "../generalComponents/autocomplete/Autocomplete";
import icd9cm from "../../utils/dataset/icd9cmData/icd9cm-diagnosi.json";
import icpc from "../../utils/dataset/icpcData/icpc-diagnosi.json";
import icd9ToIcpcMappings from "../../utils/dataset/icd9ToIcpcMappings.json";
import Utils from "../../utils/Utils";
import HistorySessionRequest from "../../utils/HistorySessionRequest";
import JsonPathMappings from "../../utils/dataset/matrice/parentfieldidmaps.json"
import SessionRequest from "../../utils/SessionRequest";
import _ from "lodash";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import * as BsIcon from "react-bootstrap-icons";
import styles from './RoleBasedComponents.module.css';
import {Col, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import farmaci from "../../utils/dataset/proposteAttivitaSuggerimentiData/farmaci.json";
import moment from "moment";
import AccordionHelper from "../../service/AccordionHelper";
import routePath from "../../utils/route/route-path.json";
import AuthUtils from "../../utils/AuthUtils";
import * as $ from "jquery";
import UserHelper from '../../service/userHelper';

const pageStateToRoleMappings = {
    'R': new Set([enumsUtente.ruoli.mmg, enumsUtente.ruoli.pediatra]),
    'V': new Set([enumsUtente.ruoli.mmg, enumsUtente.ruoli.pediatra, enumsUtente.ruoli.infermiere, enumsUtente.ruoli.specialista, enumsUtente.ruoli.fisiatra, enumsUtente.ruoli.assistenteSociale, enumsUtente.ruoli.logopedista, enumsUtente.ruoli.dietista, enumsUtente.ruoli.psicologo, enumsUtente.ruoli.medicoDiCurePalliative, enumsUtente.ruoli.direttore, enumsUtente.ruoli.mcp]),
    'F': new Set([enumsUtente.ruoli.direttore, enumsUtente.ruoli.mcp, enumsUtente.ruoli.operatore, enumsUtente.ruoli.fisiatra]),
    'DP-PDA': new Set([enumsUtente.ruoli.medicoOspedaliero]),
    'DP-PDV': new Set([enumsUtente.ruoli.mmg, enumsUtente.ruoli.medicoOspedaliero, enumsUtente.ruoli.pediatra, enumsUtente.ruoli.infermiere, enumsUtente.ruoli.specialista, enumsUtente.ruoli.fisiatra, enumsUtente.ruoli.assistenteSociale, enumsUtente.ruoli.logopedista, enumsUtente.ruoli.dietista, enumsUtente.ruoli.psicologo, enumsUtente.ruoli.medicoDiCurePalliative, enumsUtente.ruoli.direttore, enumsUtente.ruoli.mcp]),
    'DP-PDC': new Set([enumsUtente.ruoli.direttore, enumsUtente.ruoli.mcp]),
    'DP-V': new Set([enumsUtente.ruoli.mmg, enumsUtente.ruoli.pediatra, enumsUtente.ruoli.infermiere, enumsUtente.ruoli.specialista, enumsUtente.ruoli.fisiatra, enumsUtente.ruoli.assistenteSociale, enumsUtente.ruoli.logopedista, enumsUtente.ruoli.dietista, enumsUtente.ruoli.psicologo, enumsUtente.ruoli.medicoDiCurePalliative, enumsUtente.ruoli.direttore, enumsUtente.ruoli.mcp]),
    'DP-F': new Set([enumsUtente.ruoli.direttore, enumsUtente.ruoli.mcp, enumsUtente.ruoli.operatore]),
};

export const rolesProfessionista = new Set([enumsUtente.ruoli.logopedista, enumsUtente.ruoli.dietista, enumsUtente.ruoli.psicologo, enumsUtente.ruoli.medicoDiCurePalliative]);

const defaultVisualMode = 'R';
const noDataLabel = '';
const noHistoryDataLabel = '';

const selectNoValueLabel = '-';

const showDebugLabels = process.env.REACT_APP_FIELDID_DEBUG === 'true';
const showCorrectJsonPaths = showDebugLabels && false;

export const isFieldIdWriteable = (fieldId, pageState, forceUserRole) => computeVisualMode(fieldId, pageState, forceUserRole || findRoleFactory(pageState)) === 'RW';

export const isSomeFieldIdWriteable = (search, pageState, forceUserRole) => {
    if (pageState == null) return false;

    const source = pageState.startsWith('DP-') ? PermissionsDp : Permissions;
    return Object.keys(source).filter(fieldId => fieldId.includes(search)).some(fieldId => isFieldIdWriteable(fieldId, pageState, forceUserRole));
}

const findRoleFactory = pageState => {
    const admittedRoles = pageStateToRoleMappings[pageState] ?? new Set();
    const roles = SessioneUtente.getInstance().settings.map(setting => setting.ruolo);
    const role = roles.find(r => admittedRoles.has(r));
    if (!role) return null;

    return rolesProfessionista.has(role) ? enumsUtente.ruoli.professionista : role;
};

const computeVisualMode = (fieldId, pageState, role) => {
    if (pageState == null) return null;

    const source = pageState.startsWith('DP-') ? PermissionsDp : Permissions;
    const permissionObj = source[fieldId];
    if (!permissionObj) console.error(`cannot find fieldId ${fieldId} in json permission with role ${role}`);
    return !role || !permissionObj ? null : permissionObj[pageState]?.[role] ?? defaultVisualMode;
}

const RoleBasedField = props => {
    const [historyOpened, setHistoryOpened] = useState(false);
    const [showAddValueButton, setShowAddValueButton] = useState(true);
    const [showAlertError, setshowAlertError] = useState(null);
    const modalRef = useRef(null);
    const context = useContext(RoleBasedContext);

    const pageState = props.pageState ?? context.pageState;
    if (typeof pageState !== 'string' || !pageState?.trim()) {
        throw new Error(`valid prop pageState required on fieldId ${props.fieldId}`);
    }

    if (!props.targetComponent) throw new Error(`target component for fieldId ${props.fieldId} not defined`);

    const forceUserRole = context.forceUserRole || UserHelper.forceUserRole;
    const role = forceUserRole ?? findRoleFactory(pageState);
    const isActualUserInfermiere = role === enumsUtente.ruoli.infermiere;

    const fieldId = isActualUserInfermiere ? (props.infermiereFieldId ?? props.fieldId) : props.fieldId;
    if (typeof fieldId !== 'string' || !fieldId?.trim()) {
        throw new Error(`valid prop fieldId required (fieldId: ${props.fieldId}, infermiereFieldId: ${props.infermiereFieldId ?? '(n/a)'})`);
    }

    const visualMode = computeVisualMode(fieldId, pageState, role);
    const forceReadOnly = context.forceReadOnly || props.forceReadOnly || props.fieldInAccordionReadOnly;
    // non è in sola lettura e visualMode RW
    const showEditableInput = !forceReadOnly && visualMode === 'RW';
    // valore effettivo
    const computedValue = props.valueSupplier(props, props[props.valuePropAccessor]);

    useEffect(() => {
        if (showAddValueButton && computedValue != null) setShowAddValueButton(false);
    }, [showAddValueButton, computedValue]);

    const isFieldRequired = props.fieldRequired || props.showFieldIfRequiredAccordionPage || props.forceFieldRequiredVisual || props.forceVisibility;

    if ((context.showOnlyRequiredFields || props.canRemoveOptionalFields) && !window.location.href.includes(routePath.anagrafica_medico_richiedente)) {
        if (!isFieldRequired) return <div className={"empty"}/>;
        if (!showEditableInput && !props.forceFieldRequiredVisual && !props.forceVisibility && computedValue == null) return null;
    } else if (!showEditableInput && computedValue == null) {
        return <div className={"empty"}/>;
    }

    let historyEntries;
    let lastHistoryEntryForDoubleInput;
    let jsonPath;
    if ((!props.disableDoubleInput || props.hasHistory) && (Utils.isStateValutazione(props.pageState) || Utils.isStateValutazionePreDialogo(props.pageState))) {
        let parentJsonPath = parentFieldIdToJsonPath(props.parentJsonPath, props.fieldId);
        if (parentJsonPath && parentJsonPath.length > 0 && parentJsonPath.charAt(0) !== '.') parentJsonPath = '.' + parentJsonPath;

        if (props.preParentJsonPathSubObject) parentJsonPath += `.${props.preParentJsonPathSubObject}`;
        if (props.listId) props.listId.forEach(id => parentJsonPath += `.${id.name}[?(@.id == '${id.id}')]`);
        if (props.parentJsonPathSubObject) parentJsonPath += `.${props.parentJsonPathSubObject}`;

        if (parentJsonPath.toLowerCase().includes('propostaattivitasuggerimenti')) {
            const splitRegex = new RegExp(/\.(?![^(]*\))/g);
            const split = parentJsonPath.split(splitRegex);
            parentJsonPath = split.map((w, i) => {
                let word = _.cloneDeep(w);
                if (w.toLowerCase() === '.propostaattivitasuggerimenti') word = '';
                if (w.includes('currentProposteAttivitaSuggerimenti')) {
                    word = word.replace('currentProposteAttivitaSuggerimenti', split[i + 1]);
                    split[i + 1] = '';
                }
                return word;
            }).join('.').replace('..', '.');
        }

        jsonPath = `$${parentJsonPath}.${props.fieldJsonPath ?? props.field}`.replaceAll('.dati', '.').toLowerCase();

        if (props.hasDecodedValue && computedValue != null) {
            AccordionHelper.setMappaVariazionePathDecodedValue(jsonPath, String(props.labelSupplier(props, computedValue)));
        }

        historyEntries = HistorySessionRequest.getData()?.[jsonPath];
        if (historyEntries) {
            if (!parentJsonPath.match(/\[(.*?)\]+/g)) {
                const deletedData = HistorySessionRequest.getDataStartWithJsonPath(parentJsonPath, 'DELETE');
                const diffCfList = _.difference(_.uniq(deletedData.map(d => d.cf)), _.uniq(historyEntries.map(d => d.cf)));
                let historyClone = _.cloneDeep(historyEntries).map(e => ({...e, dateHour: e.date + ' ' + e.hour}));
                diffCfList.forEach(cf => {
                    const obj = deletedData.filter(d => d.cf === cf).find(d => d.field === 'id');
                    if (obj != null) {
                        historyClone.push({
                            ..._.cloneDeep(obj),
                            field: historyEntries[0].field,
                            value: _.isBoolean(historyEntries[0].value) ? true : historyEntries[0].value,
                            tipoOperazione: 'UPDATE',
                            jsonPath: historyEntries[0].jsonPath,
                            dateHour: obj.date + ' ' + obj.hour
                        });
                    }
                });
                historyEntries = _.orderBy(historyClone, 'dateHour', 'desc')
                    .map(e => mapDeletedDataAttributeInHistoryEntries(e, deletedData, props.fieldsOrderMap, false));

                const uniqByCfAndCfEsecuzioneOperazione = Utils.getUniqueItemsByProperties(
                    deletedData.map(e => ({cf: e.cf, cfEsecuzioneOperazione: e.cfEsecuzioneOperazione})),
                    ['cf', 'cfEsecuzioneOperazione']
                );
                const filterByCf = (cf) => uniqByCfAndCfEsecuzioneOperazione.filter(t => t.cf === cf);
                historyEntries.filter(e => filterByCf(e.cf) != null).forEach(e => {
                    const listaCfEsecuzioneOperazione = filterByCf(e.cf).map(v => v.cfEsecuzioneOperazione);
                    if (listaCfEsecuzioneOperazione.length > 0 && e.datiCancellati)
                        e["datiCancellati"] = "Ha cancellato: \n" + e.datiCancellati;
                });
            }

            const tester = isActualUserInfermiere
                ? historyEntry => historyEntry.role !== enumsUtente.ruoli.infermiere
                : historyEntry => historyEntry.role === enumsUtente.ruoli.infermiere;
            lastHistoryEntryForDoubleInput = historyEntries.find(tester);
        }
    }

    props = {
        ...props,
        showAlertError: noSuggestionMessage => setshowAlertError(noSuggestionMessage)
    };

    // input principale
    let input;
    if (showEditableInput) {
        if (showAddValueButton && lastHistoryEntryForDoubleInput && props.showAddValueAndTrashButton) {
            input = <button onClick={() => setShowAddValueButton(false)} className={`px-0 ${styles.addValueButton}`}>
                <BsIcon.PlusCircle size={24} color={"#176A65"} className={'mr-1'}/> Aggiungi valore
            </button>;
        } else {
            input = React.createElement(props.targetComponent, props.propsPreProcessor(props, computedValue), null);
            if (props.showValueTooltip) {
                input = <PopoverReact text={input} body={props.labelSupplier(props, computedValue)}
                                      textDisplayMode={'block'}
                                      openOnMouseOver={true} position={'bottom'} className={'popover-info'}/>
            }
            if (lastHistoryEntryForDoubleInput && !showAddValueButton && props.showAddValueAndTrashButton) {
                input = <Row className={'h-100'}>
                    <Col>{input}</Col>
                    <Col xs={'auto'} className={`my-auto ${styles.clickable}`}
                         onClick={() => {
                             props.setValue(props, null);
                             setShowAddValueButton(true);
                         }}>
                        <BsIcon.Trash3 color={'#176A65'} size={'1.7rem'}/>
                    </Col>
                </Row>;
            }
        }
    } else {
        const label = props.labelSupplier(props, computedValue);
        input = <span>{!label?.toString()?.trim() ? noDataLabel : label}</span>;
    }

    // post processing input
    input = props.componentPostProcessor(props, showEditableInput, input);

    // tasto per overlay
    if (props.hasHistory && historyEntries) {
        input = <Row className={'h-100'}>
            <Col>{input}</Col>
            <Col xs={'auto'} className={`my-auto ${styles.clickable}`} onClick={() => setHistoryOpened(true)}>
                <BsIcon.Clock color={'#176A65'} size={'1.7rem'}/>
            </Col>
        </Row>;
    }

    // doppio campo
    if (!props.disableDoubleInput && lastHistoryEntryForDoubleInput) {
        const historyLabel = (props.historyLabelSupplier ?? props.labelSupplier)(props, lastHistoryEntryForDoubleInput.value);

        const history = showEditableInput ? <Row className={'mx-0 mt-1'}>
            <Col xs={12} className={`pl-0 pr-1 ${styles.historyLabel}`}>
                {!historyLabel?.toString()?.trim() ? noHistoryDataLabel : historyLabel}
            </Col>
            <Col xs={12} className={`px-0 text-200 ${styles.historyLabelInfos}`}>
                {`${enumsUtente.ruoliFE[lastHistoryEntryForDoubleInput.role]}, ${lastHistoryEntryForDoubleInput.date}, ${lastHistoryEntryForDoubleInput.hour}`}
            </Col>
        </Row> : null;
        input = <FieldsRow containerClass={'align-items-end'} colNumber={1} spaceBetweenRows={false}>
            {input}
            {history}
        </FieldsRow>;
    }

    /* FOR DEBUGGING PURPOSE ONLY */
    if (showDebugLabels) {
        if (visualMode == null) {
            input = <FieldsRow containerClass={'align-items-end'} colNumber={1}>
                {input}
                <span className={`${styles.debug} ${styles.debugError}`}>
                    {`invalid props.fieldId: ${(!fieldId?.trim() ? '(empty string)' : fieldId)}`}!
                </span>
            </FieldsRow>;
        }

        if (jsonPath) {
            if (props.field == null) {
                input = <FieldsRow containerClass={'align-items-end'} colNumber={1}>
                    {input}
                    <span className={`${styles.debug} ${styles.debugError}`}>
                        {`invalid props.field: ${(!props.field?.trim() ? '(empty string)' : props.field)}`}!
                    </span>
                </FieldsRow>;
            }

            let deeping = jsonPath.replaceAll('@.id', '@:id').split('.');
            deeping.splice(0, 1);
            deeping = deeping.reduce(
                (req, split) => {
                    if (!req) return req;

                    let arrayId;
                    const idDeclIdx = split.indexOf('[?(@:id == \'');
                    if (idDeclIdx !== -1) {
                        arrayId = split.substring(idDeclIdx + 12, split.lastIndexOf('\''));
                        split = split.substring(0, idDeclIdx);
                    }
                    let result = req[Object.keys(req).find(k => k.toLowerCase() === split || k.toLowerCase() === 'dati' + split) ?? '$NOT_FOUND$'];
                    if (arrayId != null && (result instanceof Array)) result = result?.find(e => e.id === (typeof e.id === 'number' ? parseInt(arrayId, 10) : arrayId));
                    return result;
                },
                Object.values(SessionRequest.data)?.[0]
            );
            if (deeping === undefined) {
                input = <FieldsRow containerClass={'align-items-end'} colNumber={1}>
                    {input}
                    <span className={`${styles.debug} ${styles.debugError}`}>
                        {`cannot navigate to: ${(!jsonPath?.trim() ? '(empty string)' : jsonPath)}`}!
                    </span>
                </FieldsRow>;
            } else if (showCorrectJsonPaths) {
                input = <FieldsRow containerClass={'align-items-end'} colNumber={1}>
                    {input}
                    <span className={`${styles.debug} ${styles.debugInfo}`}>{jsonPath}</span>
                </FieldsRow>;
            }
        }

        if (showCorrectJsonPaths && props.listId != null && jsonPath) {
            const ids = [];
            props.listId.forEach((id, i) => {
                ids.push(`${id.name}: ${id.id}`);
                if (i < props.listId.length - 1) ids.push(<br/>);
            });

            input = <FieldsRow containerClass={'align-items-end'} colNumber={1}>
                {input}
                <span className={`${styles.debug} ${styles.debugOk}`}>{ids}</span>
            </FieldsRow>;
        }
    }

    if (historyOpened) {
        const dataForDialog = _.cloneDeep(historyEntries).map(e => ({
            ...e,
            originalValue: e.value,
            value: (props.historyLabelSupplier ?? props.labelSupplier)(props, e.value)
        }));

        input = <>
            <span ref={modalRef}>{input}</span>
            <HistorySidebar
                fieldLabel={props.overlayFieldLabelSupplier(props)}
                valueBodySupplier={props.overlayValueBodySupplier}
                data={dataForDialog}
                onModalClose={() => setHistoryOpened(false)}
                container={modalRef}
            />
        </>;
    }

    if (props.fieldRequired && showEditableInput && computedValue == null && !Array.isArray(input?.props?.children)) {
        input = <>
            {input}
            <span className={styles.inputMissingValue}>{showAlertError ?? "Campo obbligatorio"}</span>
        </>;
    }

    if (props.labelAlert) {
        input = <FieldsRow containerClass={'align-items-end'} colNumber={1} spaceBetweenRows={false}>
            {input}
            <span className={styles.inputLabelAlert}>{props.labelAlert}</span>
        </FieldsRow>;
    }

    if (props.fieldId && (props.fieldLabel || props.text)) {
        AccordionHelper.setMappaFieldLabelFieldId(props.fieldLabel || props.text, props.fieldId);
    }

    if (!props.fieldInAccordionReadOnly && isFieldRequired && jsonPath && !jsonPath?.includes('anagrafepaziente')
        && !jsonPath?.includes('anagrafesoggettorichiedente')) {

        let formattedJsonPath = _.cloneDeep(jsonPath).replaceAll(/\[.*\]/g, '');
        if (AuthUtils.hasUtenteRuoloInfermiere() && props.fieldId) {
            const fieldSelector = $(document.getElementById(props.fieldId))?.[0];
            let sottoaccordion = $($(fieldSelector)?.parents("div.collapse-body")?.[0])?.parents("div.collapse-div");
            let idAccordion = null;
            if (sottoaccordion?.length > 0) {
                for (const e of sottoaccordion) {
                    if (e?.id?.endsWith("Infermiere")) idAccordion = e.id;
                }
                if (idAccordion == null) {
                    sottoaccordion = document.querySelectorAll("[id='" + sottoaccordion[0].id + "']")?.[1];
                    const idSub = $(sottoaccordion)?.parents("div.collapse-div")?.[0]?.id;
                    if (idSub != null) idAccordion = idSub;
                }
            }
            if (idAccordion != null) {
                formattedJsonPath = formattedJsonPath.replaceAll(/\$\.\w*\./g, `$.${formattedJsonPath.split('.')[1]}_${idAccordion.replaceAll('datiRichiesta', '').toLowerCase()}.`);
            }
        }
        AccordionHelper.addToListaCampiObbligatori(formattedJsonPath);
    }

    return props.fieldLabel
        ? <FieldsGroup pageState={props.pageState}
                       fieldLabel={props.fieldLabel}
                       id={props.fieldId}
                       hint={props.hint}
                       fieldId={props.fieldId}
                       fieldRequired={props.fieldRequired && (showEditableInput || props.forceFieldRequiredVisual)}
                       fieldInAccordionReadOnly={props.fieldInAccordionReadOnly}>
            {input}
        </FieldsGroup>
        : <div id={props.fieldId}>{input}</div>;
}

RoleBasedField.propTypes = {
    fieldId: PropTypes.string.isRequired,
    infermiereFieldId: PropTypes.string,
    pageState: PropTypes.string,
    targetComponent: PropTypes.node,
    fieldLabel: PropTypes.string,
    fieldRequired: PropTypes.bool,
    forceFieldRequiredVisual: PropTypes.bool,
    labelContainerClass: PropTypes.string,
    inputContainerClass: PropTypes.string,
    rowClass: PropTypes.string,
    hasHistory: PropTypes.bool,
    parentJsonPath: PropTypes.string,
    preParentJsonPathSubObject: PropTypes.string,
    parentJsonPathSubObject: PropTypes.string,
    fieldJsonPath: PropTypes.string,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    valuePropAccessor: PropTypes.string,
    valueSupplier: PropTypes.func,
    labelSupplier: PropTypes.func,
    historyLabelSupplier: PropTypes.func,
    overlayFieldLabelSupplier: PropTypes.func,
    overlayValueBodySupplier: PropTypes.func,
    componentPostProcessor: PropTypes.func,
    propsPreProcessor: PropTypes.func,
    listId: PropTypes.arrayOf(PropTypes.object),
    setValue: PropTypes.func,
    showValueTooltip: PropTypes.bool,
    labelAlert: PropTypes.string,
    showAddValueAndTrashButton: PropTypes.bool,
    hasDecodedValue: PropTypes.bool,
    fieldsOrderMap: PropTypes.any,
    showFieldIfRequiredAccordionPage: PropTypes.bool,
    canRemoveOptionalFields: PropTypes.bool,
    forceVisibility: PropTypes.bool,
    fieldInAccordionReadOnly: PropTypes.bool
};

RoleBasedField.defaultProps = {
    fieldRequired: false,
    forceFieldRequiredVisual: false,
    hasHistory: true,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false,
    valueSupplier: (p, v) => v,
    valuePropAccessor: 'value',
    labelSupplier: (p, v) => v,
    componentPostProcessor: (p, isEditableInput, comp) => comp,
    propsPreProcessor: p => ({...p}),
    setValue: (p, v) => p.onChange(p.field, {target: {value: v}}),
    overlayFieldLabelSupplier: p => p.fieldLabel,
    overlayValueBodySupplier: (value, _originalValue, tipoOperazione) => showOverlayValue(value, tipoOperazione, true),
    showValueTooltip: false,
    showAddValueAndTrashButton: true,
    hasDecodedValue: false,
    fieldsOrderMap: {},
    showFieldIfRequiredAccordionPage: false,
    canRemoveOptionalFields: false,
    forceVisibility: false,
    fieldInAccordionReadOnly: false
}

////// SPECIFICI

export const RoleBasedInput = props => {
    const [isValidValue, setValidValue] = useState(true);
    const [value, setValue] = useState(props.value);

    useEffect(() => setValue(props.value), [props.value]);
    
    const isTypeNumber = props.type === 'number';
    if (isTypeNumber) {
        const valNum = parseInt(value, 10);
        if (props.min != null && valNum < props.min) setValue(props.min.toString());
        else if (props.max != null && valNum > props.max) setValue(props.max.toString());
    }

    const oldOnChange = props.onChange;
    const oldPostProcessor = props.componentPostProcessor;

    const props2 = {
        ...props,
        value,
        onChange: (f, e) => {
            const currVal = e.target.value;
            const processedValue = props.valueSupplier(props, currVal);
            const isValid = !processedValue || props.validator(processedValue)
            oldOnChange(f, isValid || props.notForzeNull ? e : {target: {value: null}});
            setValidValue(isValid);
            isValidValue ? document.getElementById('proseguiBtn').disabled = false : document.getElementById('proseguiBtn').disabled = true;
            setValue(currVal);
        },
        onKeyPress: isTypeNumber && (props.hideNumberArrows || !window.chrome) ? e => {
            const charCode = (typeof e.which === 'undefined') ? e.keyCode : e.which;
            const charStr = String.fromCharCode(charCode);
            if (!charStr.match(/^\d+$/)) e.preventDefault();
        } : undefined,
        parentClass: 'mb-0',
        targetComponent: Input,
        valueSupplier: (p, v) => {
            if (v && typeof v === 'string') v = v.trim();
            return v !== '' ? v : null;
        },
        componentPostProcessor: (p, isEditableInput, component) => {
            let input = oldPostProcessor ? oldPostProcessor(p, isEditableInput, component) : component;

            if (p.showAvailableCharsLabel && isEditableInput) {
                if (p.maxlength == null) throw new Error(`maxlength required if showAvailableCharsLabel = true on fieldId ${p.fieldId}`);
                const missingChars = p.maxlength - (p.value ?? '').length;

                input = <FieldsRow colNumber={1} spaceBetweenRows={false}>
                    {input}
                    <div className='row'>
                        {p.fieldRequired && !p.value && <div className='col align-self-start text-left'><span className={styles.inputMissingValue}>{"Campo obbligatorio"}</span></div>}
                        <div className={styles.availableCharsLabel.concat(" col align-self-end text-right")}>{missingChars.toString(10) + (missingChars === 1 ? ' carattere disponibile' : ' caratteri disponibili')}</div>
                    </div>
                </FieldsRow>;
            }

            if (!isValidValue) {
                input = <>
                    {input}
                    <span className={styles.inputInvalidText}>{props.invalidText}</span>
                </>;
            }

            return input;
        }
    };

    if (isTypeNumber) {
        if (props2.hideNumberArrows) delete props2.type;
    } else {
        delete props2.min;
        delete props2.max;
    }

    return RoleBasedField(props2);
}

RoleBasedInput.propTypes = {
    ...Input.propTypes,
    ...RoleBasedField.propTypes,
    showAvailableCharsLabel: PropTypes.bool,
    validator: PropTypes.func,
    invalidText: PropTypes.string,
    hideNumberArrows: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    notForzeNull: PropTypes.bool
};

RoleBasedInput.defaultProps = {
    ...Input.defaultProps,
    ...RoleBasedField.defaultProps,
    showAvailableCharsLabel: false,
    validator: () => true,
    invalidText: 'Campo non valido',
    hideNumberArrows: false,
    min: 0,
    notForzeNull: false
}

export const RoleBasedText = props => RoleBasedField({...props, targetComponent: Fragment, forceReadOnly: true});

RoleBasedText.propTypes = {...RoleBasedField.propTypes};

RoleBasedText.defaultProps = {...RoleBasedField.defaultProps};

export const RoleBasedPhoneInput = props => {
    const oldPostProcessor = props.componentPostProcessor;
    props = {
        ...props,
        validator: Utils.isValidTelefono,
        invalidText: 'Inserire un numero di telefono valido',
        type: 'number',
        hideNumberArrows: true,
        componentPostProcessor: (p, isEditableInput, component) => {
            let input = oldPostProcessor ? oldPostProcessor(p, isEditableInput, component) : component;
            if (props.phonePrefix == null) return input;

            if (isEditableInput) {
                return <Row className={'ml-0'}>
                    <Col xs={'auto'} className={`text-center ${styles.phonePrefix}`}>
                        <label>{props.phonePrefix}</label>
                    </Col>
                    <Col className={'pl-0'}>
                        {input}
                    </Col>
                </Row>
            } else {
                return <>
                    <span className={'mr-2'}>{props.phonePrefix}</span>
                    {input}
                </>;
            }
        }
    };

    return <RoleBasedInput {...props}/>;
}

RoleBasedPhoneInput.propTypes = {
    phonePrefix: PropTypes.string,
    ...Input.propTypes,
    ...RoleBasedField.propTypes
};

RoleBasedPhoneInput.defaultProps = {
    phonePrefix: '+ 39',
    ...Input.defaultProps,
    ...RoleBasedField.defaultProps
}

export const RoleBasedSelect = props => {
    let options = props.options;
    if (!props.fieldRequired && !options.some(elem => elem.value === null)) {
        options = [{value: null, label: selectNoValueLabel}, ...options];
    }

    let value = props.value;
    let onChange = props.onChange;
    if (props.handleOnlyValue) {
        value = props.options.find(elem => elem.value === value) ?? null;
        const oldOnChange = props.onChange;
        onChange = elem => oldOnChange(elem?.value);
    } else {
        value = value?.value != null ? value : null;
    }

    props = {
        ...props,
        options,
        value,
        onChange,
        placeholder: "Seleziona",
        styles: inputSelectStyles,
        components: DropdownIndicator,
        classNamePrefix: "inputSelect",
        parentClass: 'mb-0',
        targetComponent: Select,
        valueSupplier: (p, v) => v?.value,
        labelSupplier: (p, v) => {
            if (v == null) return null;
            return p.options?.find(o => o.value === v)?.label;
        },
        propsPreProcessor: p => {
            const newProps = {...p};
            delete newProps.field;
            return newProps;
        },
        setValue: (p, v) => p.onChange(v)
    };

    return RoleBasedField(props);
}

RoleBasedSelect.propTypes = {
    ...Select.propTypes,
    ...RoleBasedField.propTypes,
    handleOnlyValue: PropTypes.bool,
    hasDecodedValue: PropTypes.bool
};

RoleBasedSelect.defaultProps = {
    ...Select.defaultProps,
    ...RoleBasedField.defaultProps,
    handleOnlyValue: false,
    hasDecodedValue: true
}

export const RoleBasedRadio = props => {
    props = {
        ...props,
        targetComponent: RadioButton,
        valueSupplier: p => {
            const value = p.items.indexOf(p.items.find(item => item.value === p.value));
            return value === -1 ? null : value;
        },
        labelSupplier: (p, v) => v != null ? p.items[v]?.label : null,
        field: props.group,
        propsPreProcessor: (p, v) => {
            const newProps = {...p, defaultChoice: v};
            delete newProps.field;
            return newProps;
        },
        setValue: (p, v) => p.onChange(p.field, v),
        historyLabelSupplier: (p, v) => v != null ? p.items.find(item => item.value === v)?.label : null
    }

    return RoleBasedField(props);
}

RoleBasedRadio.propTypes = {
    ...RadioButton.propTypes,
    ...RoleBasedField.propTypes,
    hasDecodedValue: PropTypes.bool
};

RoleBasedRadio.defaultProps = {
    ...RadioButton.defaultProps,
    ...RoleBasedField.defaultProps,
    customStyles: {margin: '4px 0'},
    customItemStyles: {marginRight: '20px'},
    hasDecodedValue: true
}

export const RoleBasedAutocomplete = props => {
    let value = props.value;
    let onChange = props.onChange;
    if (props.handleOnlyValue) {
        value = props.suggestions.find(elem => elem[props.keyField] === value);
        if (value == null) {
            value = {};
            value[props.keyField] = null;
            value[props.descriptionField] = null;
        }
        const oldOnChange = props.onChange;
        onChange = oldOnChange
            ? (field, elem) => oldOnChange(field,
                props.fullElement
                    ? {target: {value: elem?.target?.value}}
                    : {target: {value: typeof elem?.target.value !== 'string' ? elem?.target.value?.[props.keyField] : null}})
            : oldOnChange;
    }

    props = {
        ...props,
        onChange,
        value,

        targetComponent: Autocomplete,
        valueSupplier: (p, v) => {
            const code = v?.[p.keyField]?.trim();
            return code != null && code !== '' ? (p.handleOnlyValue ? code : v) : null;
        },
        labelSupplier: (p, v) => {
            if (v == null) return null;
            const code = p.handleOnlyValue ? v : v[p.keyField];
            const valObj = (Array.isArray(p.suggestions) ? p.suggestions : Object.values(p.suggestions)).find(elem => elem[p.keyField] === code);
            return p.descriptionField ? valObj?.[p.descriptionField] : valObj;
        },
        componentPostProcessor: (p, isEditableInput, component) => <div className={'form-group mb-0'}>{component}</div>,
        historyLabelSupplier: (p, v) => {
            if (v == null) return null;
            const valObj = (Array.isArray(p.suggestions) ? p.suggestions : Object.values(p.suggestions)).find(elem => elem[p.keyField] === v);
            return p.descriptionField ? valObj?.[p.descriptionField] : valObj;
        },
    };

    return RoleBasedField(props);
}

RoleBasedAutocomplete.propTypes = {
    ...Autocomplete.propTypes,
    ...RoleBasedField.propTypes,
    handleOnlyValue: PropTypes.bool,
    hasDecodedValue: PropTypes.bool
};

RoleBasedAutocomplete.defaultProps = {
    ...Autocomplete.defaultProps,
    ...RoleBasedField.defaultProps,
    handleOnlyValue: false,
    hasDecodedValue: true
}

export const RoleBasedAsyncPaginate = props => {
    const suggestions = props.suggestions;
    const suggestionsFlatList = useMemo(() => suggestions?.flatMap(e => e?.options ?? e), [suggestions]);

    let inputValue;
    let code;
    if (props.handleOnlyValue) {
        code = props.value?.codice ?? props.value;
        if (code) {
            inputValue = {label: suggestionsFlatList?.find(e => e.value === code)?.label, value: code};
        } else {
            inputValue = null;
        }
    } else {
        inputValue = props.value;
        code = inputValue?.value;
    }

    const oldPostProcessor = props.componentPostProcessor;
    props = {
        ...props,
        styles: props?.styles ?? inputSelectStyles,
        value: inputValue,
        targetComponent: AsyncPaginate,
        valueSupplier: (p, v) => p.handleOnlyValue ? v : v?.value,
        labelSupplier: (p, v) => v?.label ?? null,
        componentPostProcessor: (p, isEditableInput, component) => {
            let input = oldPostProcessor ? oldPostProcessor(p, isEditableInput, component) : component;

            if (code && isEditableInput) {
                input = <Row>
                    <Col className={'pr-0'}>
                        {input}
                    </Col>
                    <Col xs={'auto'} className={'px-0'}>
                        <span onClick={() => props.onChange(props.field, null)} className={styles.clickable}>
                            <Icon id={'it-close'} className={['bg-white', 'icon', styles.iconColor]}
                                  style={{width: '44px', height: '44px'}}/>
                        </span>
                    </Col>
                </Row>
            }

            return input;
        },
        historyLabelSupplier: (p, v) => {
            return suggestionsFlatList?.find(e => e.value === v)?.label;
        }
    };

    return RoleBasedField(props);
}

RoleBasedAsyncPaginate.propTypes = {
    ...AsyncPaginate.propTypes,
    ...RoleBasedField.propTypes,
    handleOnlyValue: PropTypes.bool,
    hasDecodedValue: PropTypes.bool
};

RoleBasedAsyncPaginate.defaultProps = {
    ...AsyncPaginate.defaultProps,
    ...RoleBasedField.defaultProps,
    handleOnlyValue: false,
    hasDecodedValue: true
}

export const RoleBasedICD9Input = props => {
    const pageState = props.pageState;
    const context = useContext(RoleBasedContext);
    const forceUserRole = context.forceUserRole || UserHelper.forceUserRole;;
    const role = forceUserRole ?? findRoleFactory(pageState);

    if (role != null && role !== enumsUtente.ruoli.infermiere) {
        return <RoleBasedAsyncPaginate
            {...props}
            id={props.fieldId.toLowerCase()}
            loadOptions={(search, prevOptions) => asyncSelectLoadOptions(search, prevOptions, props?.suggestions ?? icd9List)}
            onChange={val => props.onChange(props.field, {target: {value: val.value}})}
            noOptionsMessage={() => 'Errore: ICD-9 non esistente'}
            placeholder={'ICD9-CM - Descrizione'}
            suggestions={props?.suggestions ?? icd9List}
            handleOnlyValue={true}
            styles={{
                ...inputSelectStyles,
                group: (provided) => {
                    return {
                        ...provided,
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }
            }}
        />;
    } else {
        return <RoleBasedInput
            {...props}
            onChange={props.onChange}
            value={props.value}
            placeholder={'Descrizione'}
            labelSupplier={(p, v) => v ? (icd9FlatList.find(e => e.value === v)?.label ?? v) : null}
            historyLabelSupplier={(p, v) => {
                if (!v) return null;
                return icd9FlatList.find(e => e.value === v)?.label ?? null;
            }}
        />;
    }
}

RoleBasedICD9Input.propTypes = {
    ...RoleBasedAsyncPaginate.propTypes
};

RoleBasedICD9Input.defaultProps = {
    ...RoleBasedAsyncPaginate.defaultProps
}

export const RoleBasedICPCInput = props => {
    const pageState = props.pageState;
    const context = useContext(RoleBasedContext);
    const forceUserRole = context.forceUserRole || UserHelper.forceUserRole;;
    const role = forceUserRole ?? findRoleFactory(pageState);

    let icpcSuggestions, icpcFlatSuggestions;
    const icpcFiltered = icd9ToIcpcMappings[props.icpcFilter?.substring(0, 3)];
    if (icpcFiltered) {
        icpcSuggestions = icpcFactory(icpc, icpcFiltered);
        icpcFlatSuggestions = icpcSuggestions?.flatMap(e => e.options);
    }

    if (!icpcFiltered || !icpcFlatSuggestions.length) {
        icpcSuggestions = icpcList;
        icpcFlatSuggestions = icpcFlatList;
    }

    if (role != null && role !== enumsUtente.ruoli.infermiere) {
        return <RoleBasedAsyncPaginate
            {...props}
            id={props.fieldId.toLowerCase()}
            loadOptions={(search, prevOptions) => asyncSelectLoadOptions(search, prevOptions, icpcSuggestions)}
            onChange={val => props.onChange(props.field, {target: {value: val.value}})}
            noOptionsMessage={() => 'Errore: ICPC non esistente'}
            placeholder={'ICPC-2e - Descrizione'}
            suggestions={icpcSuggestions}
            handleOnlyValue={true}
            cacheUniq={props.icpcFilter}
        />;
    } else {
        return <RoleBasedInput
            {...props}
            onChange={props.onChange}
            value={props.value}
            labelSupplier={(p, v) => v ? (icpcFlatSuggestions.find(e => e.value === v)?.label ?? v) : null}
            historyLabelSupplier={(p, v) => {
                if (!v) return null;
                return icpcFlatSuggestions.find(e => e.value === v)?.label ?? null;
            }}
        />;
    }
}

RoleBasedICPCInput.propTypes = {
    ...RoleBasedAsyncPaginate.propTypes,
    icpcFilter: PropTypes.string
};

RoleBasedICPCInput.defaultProps = {
    ...RoleBasedAsyncPaginate.defaultProps
}

export const RoleBasedAICInput = props => {
    const valueToMatch = props.classeEquivalenza;
    let suggestions = props?.suggestions ?? aicList;

    if (!Utils.isStringEmptyOrNullOrUndefined(valueToMatch)) {
        let filteredOptions = getFilteredOptionsList(farmaci, "codiceGruppoEquivalenza", valueToMatch, "codiceAIC", "descrizioneAIC");
        suggestions = suggestions.filter(s => filteredOptions.find(f => f.value === s.value));
    }

    suggestions = _.sortBy(suggestions, ['value']);

    return <RoleBasedAsyncPaginate
        {...props}
        id={props.fieldId.toLowerCase()}
        key={`asyncSelect${props.classeEquivalenza ?? props.value}`}
        cacheOptions
        loadOptions={(search, prevOptions) => asyncSelectLoadOptions(search, prevOptions, suggestions, false)}
        onChange={val => props.onChange(props.field, {target: {value: val.value}})}
        noOptionsMessage={() => 'Errore: Farmaco AIC non esistente'}
        placeholder={'Codice AIC'}
        suggestions={suggestions}
        handleOnlyValue={true}
    />;
}

RoleBasedAICInput.propTypes = {
    ...RoleBasedAsyncPaginate.propTypes,
    classeEquivalenza: PropTypes.string
};

RoleBasedAICInput.defaultProps = {
    ...RoleBasedAsyncPaginate.defaultProps,
    classeEquivalenza: null
}

export const RoleBasedFarmacoEquivalenteInput = props => {
    const valueToMatch = props.codiceAIC;
    let suggestions = farmaciEquivalentiList;

    if (!Utils.isStringEmptyOrNullOrUndefined(valueToMatch)) {
        suggestions = getFilteredOptionsList(farmaci, "codiceAIC", valueToMatch, "codiceGruppoEquivalenza", "descrizioneGruppoEquivalenza");
    }

    suggestions = _.uniqBy(_.sortBy(suggestions, ['value']), 'label');

    return <RoleBasedAsyncPaginate
        {...props}
        id={props.fieldId.toLowerCase()}
        key={`asyncSelect${props.codiceAIC ?? props.value}`}
        cacheOptions
        loadOptions={(search, prevOptions) => asyncSelectLoadOptions(search, prevOptions, suggestions, false)}
        onChange={val => props.onChange(props.field, {target: {value: val.value}})}
        noOptionsMessage={() => 'Errore: Farmaco equivalente non esistente'}
        placeholder={'Classe di equivalenza'}
        suggestions={suggestions}
        handleOnlyValue={true}
    />;
}

RoleBasedFarmacoEquivalenteInput.propTypes = {
    ...RoleBasedAsyncPaginate.propTypes,
    codiceAIC: PropTypes.string
};

RoleBasedFarmacoEquivalenteInput.defaultProps = {
    ...RoleBasedAsyncPaginate.defaultProps,
    codiceAIC: null
}

export const RoleBasedCalendar = props => {
    let value = props.value;
    let onChange = props.onChange;
    if (props.originalSyntax) {
        const m = moment(value, props.originalSyntax, true);
        if (m.isValid()) value = m.format('DD/MM/YYYY');

        onChange = elem => {
            let v = null;
            const m1 = moment(elem.target.value, 'DD/MM/YYYY', true);
            if (m1.isValid()) v = m1.format(props.originalSyntax);
            return props.onChange(({target: {value: v}}));
        };
    }

    return RoleBasedField({
        ...props,
        value,
        onChange,
        targetComponent: Calendar,
        valueSupplier: (p, v) => {
            if (v && typeof v === 'string') v = v.trim();
            return v !== '' ? v : null;
        }
    });
}

RoleBasedCalendar.propTypes = {
    originalSyntax: PropTypes.string,
    ...Calendar.propTypes,
    ...RoleBasedField.propTypes
};

RoleBasedCalendar.defaultProps = {
    ...Calendar.defaultProps,
    ...RoleBasedField.defaultProps
}

export const RoleBasedClock = props => RoleBasedField({
    ...props,
    targetComponent: Clock,
    hours: props.value?.split(props.separator)[0],
    minutes: props.value?.split(props.separator)[1]
});

RoleBasedClock.propTypes = {
    ...Clock.propTypes,
    ...RoleBasedField.propTypes,
    separator: PropTypes.string
};

RoleBasedClock.defaultProps = {
    ...Clock.defaultProps,
    ...RoleBasedField.defaultProps,
    separator: ':'
}

export const RoleBasedCheckbox = props => RoleBasedField({
    ...props,
    targetComponent: CheckBox,
    valuePropAccessor: 'checked',
    valueSupplier: (p, v) => v != null ? !!v : null,
    labelSupplier: (p, v) => <CheckBox text={p.text} checked={v} onChange={() => undefined} disabled={true}
                                       id={p.id} styleLabel={p.styleLabel} customStyles={p.customStyles}
                                       className={p.className} title={p.title}/>,
    historyLabelSupplier: (p, v) => <i>{v ? 'selezionato' : 'deselezionato'}</i>,
    setValue: (p, v) => p.onChange(p.field, {target: {checked: v}}),
    overlayFieldLabelSupplier: p => p.text
});

RoleBasedCheckbox.propTypes = {
    ...CheckBox.propTypes,
    ...RoleBasedField.propTypes
}

RoleBasedCheckbox.defaultProps = {
    ...CheckBox.defaultProps,
    ...RoleBasedField.defaultProps
}

////// FIELDS GRID SYSTEM

export const FieldsRow = props => {
    const context = useContext(RoleBasedContext);
    let childrenArray = React.Children.toArray(props.children);
    childrenArray = childrenArray.filter(child => {
        if(React.isValidElement(child)){
            let c = React.cloneElement(child, {
                forceReadOnly: child.props.forceReadOnly || context.forceReadOnly || child.props.fieldInAccordionReadOnly,
                hideUnsetValues: child.props.hideUnsetValues || context.hideUnsetValues,
                canRemoveOptionalFields: context.showOnlyRequiredFields
            });
            let mu = ReactDOMServer.renderToString(c);
            return !mu.includes("empty");
        }
    });
    if(childrenArray?.length === 0) return null;

    if (props.colNumber < -1 || props.colNumber > 4) throw new Error('column number out of range');
    const colLabel = props.colNumber === -1 ? 'auto' : props.colNumber === 0 ? null : (12 / props.colNumber);

    return (
        <Row className={`${props.containerClass} ${props.spaceBetweenRows ? 'py-1' : 'py-0'}`}>
            {childrenArray.map((child, i) => (
                <Col key={'.' + i} xs={colLabel} className={props.childClass ?? ''}>{child}</Col>
            ))}
        </Row>
    );
}

FieldsRow.propTypes = {
    children: PropTypes.node.isRequired,
    containerClass: PropTypes.string,
    childClass: PropTypes.string,
    spaceBetweenRows: PropTypes.bool,
    colNumber: PropTypes.number // -1 = auto, 0 = col
}

FieldsRow.defaultProps = {
    containerClass: 'align-items-top',
    spaceBetweenRows: true,
    colNumber: 4
}

export const FieldsGroup = props => {
    const context = useContext(RoleBasedContext);
    const canRemoveOptionalFields = context.showOnlyRequiredFields && !window.location.href.includes(routePath.anagrafica_medico_richiedente);

    if (props.hint && !props.fieldId) throw new Error('fieldId required when hint is specified');

    if (props.hide) return null;

    if (canRemoveOptionalFields) {
        if (!props.showFieldIfRequiredAccordionPage || !props.children) return null;

        if (_.isArray(props.children)) {
            let childrenArray = React.Children
                .toArray(props.children)
                .filter(React.isValidElement)
                .filter(child => isSectionListRequired(child));

            const isStateValutazione = Utils.isStateValutazione(props.pageState) || Utils.isStateValutazionePreDialogo(props.pageState);
            if (isStateValutazione && !props.fieldInAccordionReadOnly) {
                childrenArray = React.Children
                    .toArray(childrenArray)
                    .filter(React.isValidElement)
                    .filter(child => getWriteableFields(child, context.forceUserRole || UserHelper.forceUserRole, context.forceReadOnly, null));
            }
            if (!childrenArray?.length > 0) return null;
        }
    }

    const required = props.fieldRequired;
    const labelProps = {className: 'mr-1' + (required ? ' required' : '')};
    const label = typeof props.fieldLabel === 'string'
        ? <span {...labelProps}><strong>{props.fieldLabel}</strong></span>
        : props.fieldLabel;

    if (props.fieldId && props.fieldLabel) {
        AccordionHelper.setMappaFieldLabelFieldId(props.fieldLabel, props.fieldId);
    }

    return (
        <Row className={props.rowClass} style={props.rowStyle} id={props.fieldId}>
            <Col xs={12}>
                {label}
                {props.hint &&
                    <PopoverReact
                        text={<BsIcon.InfoCircle size={20} color={"#176A65"}/>}
                        body={props.hint}
                        openOnMouseOver={true}
                        position={'right'}
                        className={'popover-info'}
                    />}
            </Col>
            <Col xs={12} className={styles.fieldContainer}>
                {props.children}
            </Col>
        </Row>
    );
}

FieldsGroup.propTypes = {
    pageState: PropTypes.string,
    fieldLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    fieldRequired: PropTypes.bool,
    rowClass: PropTypes.string,
    children: PropTypes.node,
    inputHeight: PropTypes.bool,
    rowStyle: PropTypes.object,
    hint: PropTypes.string,
    fieldId: PropTypes.string,
    hide: PropTypes.bool,
    id: PropTypes.string,
    showFieldIfRequiredAccordionPage: PropTypes.bool,
    fieldInAccordionReadOnly: PropTypes.bool
}

FieldsGroup.defaultProps = {
    fieldRequired: false,
    rowStyle: {},
    hide: false,
    id: null,
    showFieldIfRequiredAccordionPage: true,
    fieldInAccordionReadOnly: false
}

export const AddSectionButton = props => (
    <FieldsRow colNumber={1}>
        <button className={'btn btn-link p-0 my-2 ml-2'} onClick={props.onClick} disabled={props.disabled}>
            <Icon nameIcon={props.icon} className={`${styles.iconColor} mr-2`}/>
            <b>{props.text}</b>
        </button>
    </FieldsRow>
);

AddSectionButton.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

AddSectionButton.defaultProps = {
    icon: 'plus-circle',
    disabled: false
}

export const SectionList = props => {
    if (props.data != null && !Array.isArray(props.data)) {
        throw new Error(`props.data must be an array (title '${props.title}', keyFieldId ${props.keyFieldId})`);
    }

    const [historyOpened, setHistoryOpened] = useState(false);
    const modalRef = useRef(null);

    const context = useContext(RoleBasedContext);
    const pageState = props.pageState ?? context.pageState;
    const title = props.title.trim();

    useEffect(() => {
        if (props.atLeastOne && (!props.data || props.data.length === 0)) {
            props.addNewSectionCallback();
        }
    });
    // prop forceReadOnly può essere booleano o array
    const forceReadOnly = props.forceReadOnly || context.forceReadOnly || props.fieldInAccordionReadOnly;

    let canWrite;
    let parentJsonPath;
    let actualValuesVisible = true;
    let fieldId;
    if (props.keyFieldId) {
        if (pageState == null) {
            throw new Error(`pageState required for SectionList with keyFieldId set (${props.keyFieldId})`);
        }

        const forceUserRole = context.forceUserRole || UserHelper.forceUserRole;
        const role = forceUserRole ?? findRoleFactory(pageState);
        const isInfermiere = role === enumsUtente.ruoli.infermiere;
        fieldId = !isInfermiere ? props.keyFieldId : (props.infermiereKeyFieldId ?? props.keyFieldId);

        parentJsonPath = parentFieldIdToJsonPath(props.parentJsonPath, fieldId);

        let visualMode = computeVisualMode(fieldId, pageState, role);
        if (visualMode !== 'RW') canWrite = false;

        // non è in sola lettura e visualMode RW
        const isArrayForceReadOnly = Array.isArray(forceReadOnly) ? forceReadOnly?.every(e => e) : !forceReadOnly;
        const showEditableInput = isArrayForceReadOnly && visualMode === 'RW';
        // valore effettivo
        let dataEmpty = props.data?.every(d => {
            let dc = _.cloneDeep(d);
            if(dc?.id) delete dc.id;
            return Utils.isNullish(dc);
        });
        const computedValue = props.data?.length && !dataEmpty ? props.data : null;

        // div vuoto
        if (!showEditableInput && computedValue == null) {
            actualValuesVisible = false;
        }
    }

    let historyEntries;
    if (parentJsonPath) {
        let deletedData = HistorySessionRequest.getDataStartWithJsonPath(parentJsonPath, 'DELETE');
        if (deletedData.length > 0) {
            historyEntries = _.uniqBy(deletedData.filter(d => d.field === 'id'), 'cf')
                .map(e => mapDeletedDataAttributeInHistoryEntries(e, deletedData, props.fieldsOrderMap, true));

            const listaCfEsecuzioneOperazione = _.uniq(deletedData.map(e => e.cfEsecuzioneOperazione));
            const previousInsertedValues = HistorySessionRequest.getDataStartWithJsonPath(parentJsonPath, 'UPDATE')
                .filter(e => listaCfEsecuzioneOperazione.includes(e.cf))
                .filter(e => deletedData.map(d => d.jsonPath).includes(e.jsonPath))
                .map(e => {
                    let clone = _.cloneDeep(e);
                    const found = deletedData.find(e2 => e2.jsonPath === e.jsonPath && e2.value === e.value);
                    if (found != null) clone = {...clone, decodedValue: found.decodedValue};
                    return clone;
                });
            const updatedValuesUniqByCf = _.uniqBy(previousInsertedValues.filter(d => d.field === 'id'), 'cf')
                .map(e => mapDeletedDataAttributeInHistoryEntries(e, previousInsertedValues, props.fieldsOrderMap, true));
            historyEntries.push(...updatedValuesUniqByCf);
        }
    }

    if (Array.isArray(forceReadOnly)) {
        if (canWrite == null) canWrite = forceReadOnly.map(e => !e);
    } else if (forceReadOnly) {
        const dati = props.data?.filter(data => data != null)?.map(data => {
            let obj = {};
            Object.keys(data)
                .filter(k => k !== 'id' && k !== 'codiceFiscale')
                .forEach(k => obj[k] = data[k]);
            return !Utils.isObjectEmpty(obj) && !Utils.isObjectNull(obj) ? obj : null;
        })?.filter(obj => obj != null) ?? [];
        if (!historyEntries?.length && !dati.length) return null;
        if (canWrite == null) canWrite = false;
    } else {
        if (canWrite == null) canWrite = true;
    }

    if (context.showOnlyRequiredFields) {
        const filteredData = props.data?.filter((e, i) => {
            let cloneData = _.cloneDeep(e);
            if(cloneData?.id) delete cloneData.id;

            let isElementWritable = (Array.isArray(canWrite) && (canWrite[i] ?? true)) || (!Array.isArray(canWrite) && canWrite);
            let childrenArray = React.Children
                .toArray(props.renderSection(cloneData, i, !isElementWritable))
                .filter(React.isValidElement)
                .filter(child => isSectionListRequired(child));

            const isStateValutazione = Utils.isStateValutazione(props.pageState) || Utils.isStateValutazionePreDialogo(props.pageState);
            if (isStateValutazione && props.fieldInAccordionReadOnly) {
                childrenArray = React.Children
                    .toArray(childrenArray)
                    .filter(React.isValidElement)
                    .filter(child => getWriteableFields(child, context.forceUserRole || UserHelper.forceUserRole, context.forceReadOnly, fieldId));
            }

            return !!childrenArray.length ?? props.required;
        });
        if (!props.required && !filteredData?.length && !historyEntries?.length) return null;
    } else if (!actualValuesVisible && !historyEntries?.length) {
        return null;
    }

    const canAdd = canWrite && props.addButtonVisibilityHandler;

    const subtitle = props.subtitle ?? title.charAt(0).toUpperCase() + title.substring(1);
    const addButtonLabel = (historyEntries || canAdd) && (props.addButtonLabel ?? `Aggiungi ${subtitle.charAt(0).toLowerCase() + subtitle.substring(1)}`);

    const canRemoveArray = canWrite && props.data?.map((e, i) => (!props.atLeastOne || (props.atLeastOne && props.data?.length > 1))
        && ((!Array.isArray(props.canRemoveData) && props.canRemoveData) || (Array.isArray(props.canRemoveData) && (props.canRemoveData[i] ?? true))));
    const subtitles = props.data?.map((e, i) => `${subtitle}${(i > 0 ? (' ' + (i + 1)) : '')}`);

    const marginLeft = (props.indentOffset * 18) + 'px';

    let addButtonLink = null;
    if (historyEntries) {
        // tasto per overlay
        addButtonLink = <FieldsRow colNumber={-1}>
            <Row className={'h-100'}>
                <Col><AddSectionButton text={addButtonLabel} onClick={props.addNewSectionCallback} disabled={!canAdd}/></Col>
                <Col xs={'auto'} className={`my-auto ${styles.clickable}`} onClick={() => setHistoryOpened(true)}>
                    <BsIcon.Clock color={'#176A65'} size={'1.7rem'}/>
                </Col>
            </Row>
        </FieldsRow>;
    } else if (canAdd) {
        addButtonLink = <AddSectionButton text={addButtonLabel} onClick={props.addNewSectionCallback}/>;
    }

    let input = <div style={{marginLeft}}>
        {Array.isArray(props.data) && props.data.map((e, i) => {
                let isElementWritable = (Array.isArray(canWrite) && (canWrite[i] ?? true)) || (!Array.isArray(canWrite) && canWrite);
                let renderedSection = props.renderSection(e, i, !isElementWritable);
                if (React.isValidElement(renderedSection)) {
                    renderedSection = React.Children.map(
                        renderedSection,
                        child => spreadListId(child, [...(props.listId ?? []), {id: e?.id, name: props.field}])
                    );
                }

                return <Fragment key={i}>
                    <FieldsRow colNumber={1}>
                        <Row className={'d-flex align-items-center pb-2 pt-2'}>
                            {isElementWritable &&
                                <Col xs={'auto'}>
                                    <button className='bg-transparent'
                                            style={{border: 'none', outline: 'none'}}
                                            onClick={() => props.removeSectionCallback(i)}
                                            disabled={!canRemoveArray[i]}>
                                        <BsIcon.Trash3 size={24} color={!canRemoveArray[i] ? "#717273" : "#176A65"}/>
                                    </button>
                                </Col>}
                            <Col>
                                <span className={'mb-0 f-weight-600'}>{subtitles[i]}</span>
                            </Col>
                        </Row>
                    </FieldsRow>
                    {renderedSection}
                </Fragment>
            }
        )}

        {addButtonLink}
    </div>;

    if (historyOpened) {
        const dataForDialog = _.cloneDeep(historyEntries).map(e => ({
            ...e,
            originalValue: e.value,
            value: e.value
        }));

        input = <>
            <span ref={modalRef}>{input}</span>
            <HistorySidebar
                fieldLabel={props.title}
                valueBodySupplier={(value, _originalValue, tipoOperazione) => showOverlayValue(value, tipoOperazione, false)}
                data={dataForDialog}
                onModalClose={() => setHistoryOpened(false)}
                container={modalRef}
            />
        </>;
    }

    return input;
}

SectionList.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    addButtonLabel: PropTypes.string,
    keyFieldId: PropTypes.string,
    infermiereKeyFieldId: PropTypes.string,
    pageState: PropTypes.string,
    field: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    renderSection: PropTypes.func.isRequired,
    addNewSectionCallback: PropTypes.func.isRequired,
    removeSectionCallback: PropTypes.func.isRequired,
    addButtonVisibilityHandler: PropTypes.bool,
    canRemoveData: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.bool)]),
    atLeastOne: PropTypes.bool,
    forceReadOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.bool)]),
    indentOffset: PropTypes.number,
    listId: PropTypes.arrayOf(PropTypes.object),
    parentJsonPath: PropTypes.string,
    fieldsOrderMap: PropTypes.any,
    required: PropTypes.bool,
    fieldInAccordionReadOnly: PropTypes.bool
}

SectionList.defaultProps = {
    addButtonVisibilityHandler: true,
    canRemoveData: true,
    atLeastOne: false,
    forceReadOnly: false,
    indentOffset: 1,
    fieldsOrderMap: {},
    required: false,
    fieldInAccordionReadOnly: false
}

////// HISTORY SIDEBAR

const HistorySidebar = props => {
    const [open, setOpen] = useState(true);

    return (
        <Modal cssModule={styles} isOpen={open} scrollable={true} onClosed={props.onModalClose}
               container={props.container}>
            <ModalHeader tag={"div"} cssModule={styles}>
                <Row>
                    <Col xs={12}>
                        <button type={'button'} className={'close'} onClick={() => setOpen(false)}>
                            <span className={'mr-2'}>Chiudi</span>
                            <BsIcon.ArrowRightCircle/>
                        </button>
                    </Col>
                </Row>
                <h1>Storico modifiche attivit&agrave;</h1>
                <h3>{props.fieldLabel}</h3>
                {props.data?.length > 2 &&
                    <div className={styles.fieldHeavilyEditedWarningText + ' mt-3'}>
                        <BsIcon.Flag size={'1rem'} className={styles.fieldHeavilyEditedWarningIcon}/> Attenzione: Questo
                        campo ha
                        subito più di 2 modifiche da almeno 2 professionisti diversi
                    </div>}
            </ModalHeader>

            <ModalBody cssModule={styles}>
                <ListGroup flush={true}>
                    {props.data.map((e, i) => (
                        <ListGroupItem key={i} className={`px-5 pb-3 ${styles.sidebarItem}`}>
                            <Row>
                                <Col xs={'auto'}>
                                    <Icon path={globals['app-application-logo']} classes={styles.avatarPiccolo}
                                          alt={globals['app-application-logo-alt']}/>
                                </Col>
                                <Col className={'ml-2'}>
                                    <Row className={'mb-2'}>
                                        <Col className={'font-weight-bold'}>
                                            {e.name + ' ' + e.surname}
                                        </Col>
                                        <Col xs={'auto'} className={`text-400 align-self-end ${styles.timestamp}`}>
                                            {e.date + ', ore ' + e.hour}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {props.valueBodySupplier(e.value, e.originalValue, e.tipoOperazione)}
                                        </Col>
                                    </Row>
                                    {e?.datiCancellati
                                        && <Row>
                                            <Col style={{whiteSpace: "pre-line"}}>
                                                {e.datiCancellati}
                                            </Col>
                                        </Row>}
                                </Col>
                            </Row>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </ModalBody>
        </Modal>
    );
}

HistorySidebar.propTypes = {
    fieldLabel: PropTypes.string.isRequired,
    valueBodySupplier: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    onModalClose: PropTypes.func.isRequired,
    container: PropTypes.elementType.isRequired
};

////// SUPPORT FUNCTIONS

const showOverlayValue = (value, tipoOperazione, showAlways) => {
    const label = tipoOperazione === 'DELETE' ? 'Ha cancellato il valore ' : 'Ha inserito il valore ';
    return <>
        {label}
        {(showAlways || (tipoOperazione !== 'DELETE' && value)) &&
            <span className={'font-weight-bold'}>{'"'}{value}{'"'}</span>}
    </>;
}

const spreadListId = (parent, id) => {
    const children = parent.props.children;
    if (!children) return React.cloneElement(parent, {listId: [...id]});

    return React.cloneElement(parent, {
        children: React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                if (child.props.children) return spreadListId(child, id);
                return React.cloneElement(child, {listId: [...id]});
            }
            return child;
        })
    });
}

export const spreadFieldsOrderMap = (parent, fieldsOrderMap) => {
    const children = parent.props.children;
    if (!children) return React.cloneElement(parent, {fieldsOrderMap: fieldsOrderMap});

    return React.cloneElement(parent, {
        children: React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                if (child.props.children) return spreadFieldsOrderMap(child, fieldsOrderMap);
                return React.cloneElement(child, {fieldsOrderMap: fieldsOrderMap});
            }
            return child;
        })
    });
}

const isSectionListRequired = (parent) => {
    const children = parent.props.children;
    if (!children) return false;

    return React.Children.toArray(children)
        .filter(React.isValidElement)
        .filter(child => {
            if (child.props.children) return isSectionListRequired(child);
            return !!child.props.required || !!child.props.fieldRequired
                || !!child.props.showFieldIfRequiredAccordionPage || !!child.props.forceFieldRequiredVisual
                || !!child.props.forceVisibility;
        })?.length > 0;
}

const getWriteableFields = (parent, forceUserRole = UserHelper.forceUserRole, readOnlyContext, fieldId) => {
    const children = parent.props.children;
    if (!children) return false;

    return React.Children.toArray(children)
        .filter(React.isValidElement)
        .filter(child => {
            if (child.props.children) return getWriteableFields(child, forceUserRole, readOnlyContext, fieldId);
            const forceReadOnly = child.props.forceReadOnly || readOnlyContext || child.props.fieldInAccordionReadOnly;
            const finalFieldId = fieldId ?? child.props.fieldId;
            return finalFieldId != null
                ? isFieldIdWriteable(finalFieldId, child.props.pageState, forceUserRole) && !forceReadOnly
                : !!child.props.forceFieldRequiredVisual || !!child.props.forceVisibility;
        })?.length > 0;
}

export const parentFieldIdToJsonPath = (parentJsonPath, fieldId) => {
    let result;

    if (parentJsonPath != null) {
        result = parentJsonPath;
    } else {
        const parentFieldId = fieldId.substring(fieldId.indexOf('.') + 1);
        result = JsonPathMappings[parentFieldId];

        if (result == null) {
            const split = parentFieldId.split('.');
            result = '';
            for (let i = split.length - 1; i >= 0; i--) {
                result += split[i];
                if (i > 0) result += '.';
            }
        }
    }

    return result;
}

const mapDeletedDataAttributeInHistoryEntries = (variazione, deletedDataList, fieldsOrderMap, isHistoryNearLink) => {
    const deletedValues = deletedDataList
        ?.filter(d => d.cfEsecuzioneOperazione === variazione.cf || d.cf === variazione.cf)
        ?.map(d => ({
            jsonPath: d.jsonPath.substring(0, d.jsonPath.lastIndexOf('.')),
            field: d.field,
            value: d.decodedValue ?? d.value
        })) ?? [];

    let variazioneClone = _.cloneDeep(variazione);

    if (deletedValues.length > 0) {
        let datiCancellati = deletedValues.filter(d => d.field !== "id").map((d, i) => {
            let value = d.value;
            if (isHistoryNearLink && i !== 0) value = '\n' + d.value;
            return value;
        });

        const mapKeys = Object.keys(fieldsOrderMap);
        if (mapKeys.length > 0) {
            const byJsonPath = _.groupBy(deletedValues, 'jsonPath');
            const byJsonPathKeys = Object.keys(byJsonPath);
            let ids = [];
            datiCancellati = [];

            byJsonPathKeys.forEach((k, i) => {
                if (k.match(/\[\d+\]/g)) {
                    ids.push(byJsonPath[k][0].value);
                    datiCancellati.push('\n');
                } else {
                    if (datiCancellati.length && ids.find(id => k.includes(id)) != null
                        && datiCancellati[datiCancellati.length - 1] !== '\n') {
                        datiCancellati.push('\n');
                    }

                    if (i !== 0 && datiCancellati[datiCancellati.length - 1] !== '\n') datiCancellati.push('\n');

                    const data = byJsonPath[k].filter(d => d.jsonPath === k).map(d => ({[d.field]: d.value}));
                    let formattedKey = k.replaceAll(/\[(.*?)\]+/g, '');

                    if (formattedKey.toLowerCase().includes("propostaattivita")) {
                        formattedKey = formattedKey.split('.')
                            .map(word => word.toLowerCase().includes("propostaattivita") ? 'propostaAttivitaSuggerimenti' : word)
                            .join('.');
                    }

                    const values = fieldsOrderMap?.[formattedKey]?.flatMap(field => _.filter(data, field).map(d => d[field])).filter(v => v != null) ?? [];
                    datiCancellati.push(...values);
                }
            });
        }

        if (isHistoryNearLink) variazioneClone["value"] = null;

        variazioneClone["datiCancellati"] = datiCancellati.join(" ")
            .split('\n')
            .map(v => v.trim())
            .filter(v => v !== "")
            .reduce((r, a, i) => r.concat(`${i !== 0 ? '\n' : ''}\u2022 ${a.replace('true', 'Sì').replace('false', 'No')}`), [])
            .join(" ")
            .split('\u2022')
            .filter((e, i, arr) => arr.indexOf(e, i + 1) === -1)
            .join('\u2022');
    }

    return variazioneClone;
}

const icd9Factory = () => {
    let list = [];

    // livello 1 profondità, contiene solo alberature
    Object.keys(icd9cm).sort().forEach(categoria => {
        let listaMotiviCategoria = [];
        let listaMotiviAnnidatiCategoria = [];

        // livello 2 profondità, contiene misto sia motivazioni semplici sia alberature
        Object.keys(icd9cm[categoria])
            .filter(e => !["codice", "descrizione"].some(e1 => e1 === e))
            .forEach(motivazione => {
                const motivazioniAnnidate = Object.keys(icd9cm[categoria][motivazione])
                    .filter(e => !["codice", "descrizione"].some(e1 => e1 === e));

                if (motivazioniAnnidate.length === 0) {
                    listaMotiviCategoria.push({
                        value: icd9cm[categoria][motivazione].codice,
                        label: icd9cm[categoria][motivazione].codice + " | " + icd9cm[categoria][motivazione].descrizione
                    });
                } else {
                    // livello 3 profondità, contiene solo motivazioni semplici
                    const options = motivazioniAnnidate.map(d => ({
                        value: icd9cm[categoria][motivazione][d].codice,
                        label: icd9cm[categoria][motivazione][d].codice +
                            " | " + icd9cm[categoria][motivazione][d].descrizione
                    }));
                    options.unshift({
                        value: icd9cm[categoria][motivazione].codice,
                        label: icd9cm[categoria][motivazione].codice + " | " + icd9cm[categoria].descrizione
                            + ": " + icd9cm[categoria][motivazione].descrizione
                    });
                    listaMotiviAnnidatiCategoria.push({
                        label: null,
                        options: options
                    });
                }
            });

        listaMotiviCategoria.unshift({
            value: icd9cm[categoria].codice,
            label: icd9cm[categoria].codice + " | " + icd9cm[categoria].descrizione
        });
        list.push({
            label: null,
            options: listaMotiviCategoria
        }, ...listaMotiviAnnidatiCategoria);
    });

    return list;
};

const getFilteredOptionsList = (listToFilter, fieldToMatch, valueToMatch, fieldValue, fieldLabel) => {
    return listToFilter
        .filter(f => f[fieldToMatch] === valueToMatch)
        .map(f => ({value: f[fieldValue], label: f[fieldValue] + ' - ' + f[fieldLabel]}));
};

const asyncSelectLoadOptions = (search, prevOptions, suggestions, isFieldOptionsPresent = true) => {
    let result;
    if (!search) {
        result = suggestions;
    } else {
        result = [];
        if (isFieldOptionsPresent) {
            suggestions.forEach(cat => {
                const opts = cat.options.filter(m => m.label.toLowerCase().includes(search.toLowerCase()));
                if (opts.length) result.push({
                    label: cat.label,
                    options: opts
                });
            });
        } else {
            const opts = suggestions.filter(s => s.label.toLowerCase().includes(search.toLowerCase()));
            if (opts.length) result.push(...opts);
        }
    }

    const resultNum = 10;
    return {
        options: result.slice(prevOptions.length, prevOptions.length + resultNum),
        hasMore: result.length > prevOptions.length + resultNum
    };
};

const icpcFactory = (icpcList, icpcFiltered = []) => {
    let list = [];

    //livello 1 di profondità
    Object.keys(icpcList).sort().forEach(categoria => {
        const listaMotiviCategoria = [];
        const listaMotiviAnnidatiCategoria = [];
        const isProcedure = categoria === "Procedure";

        //controllo se contiene sotto-oggetti
        const obj = icpcList[categoria];
        const motivazioniAnnidate = Object.keys(obj)

        if (isProcedure && icpcFiltered.length === 0) {
            motivazioniAnnidate.forEach(motivazione => {
                listaMotiviCategoria.push({
                    value: obj[motivazione],
                    label: obj[motivazione]
                })
            })
        }
        //livello 2 di profondità
        if (!isProcedure && motivazioniAnnidate.length > 0 && (icpcFiltered.length === 0 || icpcFiltered.some(i => i.charAt(0) === categoria))) {
            if (obj == null) return;

            motivazioniAnnidate.filter(e => !["codice", "descrizione"].some(e1 => e1 === e) && obj[e] != null)
                .forEach(e => {
                    //livello 3 di profondità
                    if (icpcFiltered.length === 0) {
                        listaMotiviAnnidatiCategoria.push({
                            label: obj.codice + ": " + obj.descrizione + " - " + e,
                            options: Object.keys(obj[e]).map(chiave => ({
                                value: chiave,
                                label: obj[e][chiave]
                            }))
                        });
                    } else {
                        Object.keys(obj[e]).forEach(key => {
                            if (icpcFiltered.includes(key)) {
                                listaMotiviAnnidatiCategoria.push({
                                    label: obj.codice + ": " + obj.descrizione + " - " + e,
                                    options: [{value: key, label: obj[e][key]}]
                                });
                            }
                        });
                    }
                });
        }
        if (icpcFiltered.length === 0 || icpcFiltered.some(i => i.charAt(0) === categoria)) {
            list.push({
                label: categoria,
                options: listaMotiviCategoria
            }, ...listaMotiviAnnidatiCategoria);
        }
    });
    return list;
};

export const icd9List = icd9Factory();
const icd9FlatList = icd9List.flatMap(e => e.options);

const icpcList = icpcFactory(icpc);
const icpcFlatList = icpcList.flatMap(e => e.options);

export const aicList = farmaci.map(f => ({value: f.codiceAIC, label: f.codiceAIC + ' - ' + f.descrizioneAIC}));

const farmaciEquivalentiList = farmaci.map(f => ({
    value: f.codiceGruppoEquivalenza,
    label: f.codiceGruppoEquivalenza + ' - ' + f.descrizioneGruppoEquivalenza
}));
