import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import enumQuestionario from "../../../enum/enumQuestionario"
import CompilaQuestionario from "../../compilaQuestionario/CompilaQuestionario";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import {isFieldIdWriteable} from "../../roleBasedComponents/RoleBasedComponents";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    datiAttivitaFisicaSedentarieta: PropTypes.any,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    richiesta: PropTypes.any,
    salvaBozza:  PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class AttivitaFisicaSedentaria extends AccordionSecondLevel {

    state = {
        datiAttivitaFisicaSedentarieta: _.cloneDeep(this.props.datiAttivitaFisicaSedentarieta)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiAttivitaFisicaSedentarieta, this.props.datiAttivitaFisicaSedentarieta)) {
            this.inizializzaForm();
        }
            
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiAttivitaFisicaSedentarieta}) => {
            datiAttivitaFisicaSedentarieta[field] = elem;
            return {datiAttivitaFisicaSedentarieta};
        }, () => this.validazioneDati());
    }

    inizializzaForm = () => {
        let datiAttivitaFisicaSedentarieta = _.cloneDeep(this.props.datiAttivitaFisicaSedentarieta);
        this.setState({datiAttivitaFisicaSedentarieta: datiAttivitaFisicaSedentarieta}, () => this.validazioneDati());
    }

    validazioneDati = () => {
        let datiAttivitaFisicaSedentarieta = _.cloneDeep(this.state.datiAttivitaFisicaSedentarieta);
        this.props.onChangeDatiAssistito("datiAttivitaFisicaSedentarieta", datiAttivitaFisicaSedentarieta);
        let campiObbligatori = 0;
        campiObbligatori += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiAttivitaFisicaSedentarieta?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneAttivitaFisicaSedentaria", campiObbligatori);
    }

    renderBody = () => {
        let datiAttivitaFisicaSedentarieta = this.state.datiAttivitaFisicaSedentarieta;
        const {richiesta, pageState, readOnly} = this.props;
        const isSurveyWriteable = isFieldIdWriteable("PUNT_QUEST_PASSI.ATTIVITAFISICASEDENTARIETA.DETERMINANTIDISALUTE", pageState, this.context.forceUserRole);
        const isSurveyDisabled = readOnly || this.context.forceReadOnly || !!this.props.accordionReadOnly || !isSurveyWriteable;
        const showSurvey = isSurveyWriteable && !isSurveyDisabled;

        return (
            <>
                {/* TODO CODE DA CANCELLARE */}
                {/* <Statistica
                    fieldId={"PUNT_QUEST_PASSI.ATTIVITAFISICASEDENTARIETA.DETERMINANTIDISALUTE"}
                    pageState={this.props.pageState}
                    nomeLink={"Compila questionario \"Valutazione delle abitudini all'attività fisica\""}
                    nomePunteggio={'Punteggio questionario PASSI - Valutazione delle abitudini all\'attività fisica'}
                    questionario={datiAttivitaFisicaSedentarieta.PASSI}
                    onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.PASSI, questionario)}
                    richiesta={this.props.richiesta}
                    pathRichiestaQuestionario={"determinantiDiSalute.datiAttivitaFisicaSedentarieta.PASSI"}
                    tipoQuestionario={enumQuestionario.tipoQuestionario.PASSI}
                    profileRequired={false}
                    salvaBozza={this.props.salvaBozza}
                    parentJsonPathSubObject={'passi'}
                /> */}

                {!this.context.showOnlyRequiredFields && showSurvey
                    ?
                    <CompilaQuestionario
                        fieldId={"PUNT_QUEST_PASSI.ATTIVITAFISICASEDENTARIETA.DETERMINANTIDISALUTE"}
                        richiesta={richiesta}
                        tipoQuestionario={enumQuestionario.tipoQuestionario.PASSI}
                        pageState={pageState}
                        pathRichiestaQuestionario={"determinantiDiSalute.datiAttivitaFisicaSedentarieta.PASSI"}
                        externalChange={(elem) => (this.handleAggiornaForm(enumQuestionario.tipoQuestionario.PASSI, elem.richiesta))}
                        hiddenCtaContainer={true}
                        hiddenScroll={true}
                        labelSezioneObbligatoria={false}
                        titoloRequired={false}
                        disabilitato={isSurveyDisabled}
                        salvaBozza={this.props.salvaBozza}
                        showOnlyRequiredFields={this.context.showOnlyRequiredFields}
                        forceFieldRequiredVisual={false}
                    />
                    : null}

                <ValutazioneSection
                    pageState={this.props.pageState}
                    ambito={'ATTIVITA FISICA SEDENTARIETA'}
                    parentFieldId={'ATTIVITAFISICASEDENTARIETA.DETERMINANTIDISALUTE'}
                    onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                    valueStatoDopoValutazione={datiAttivitaFisicaSedentarieta.statoDopoValutazione}
                    onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                    valueDataValutazione={datiAttivitaFisicaSedentarieta.dataValutazione}
                    onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                    valueDaConsiderare={datiAttivitaFisicaSedentarieta.daConsiderare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={datiAttivitaFisicaSedentarieta.propostaAttivitaSuggerimenti}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={datiAttivitaFisicaSedentarieta.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={datiAttivitaFisicaSedentarieta.autonomiaGestioneCaregiver}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiAttivitaFisicaSedentarieta"}
                title={"Attività fisica/Sedentarietà"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}
AttivitaFisicaSedentaria.contextType = RoleBasedContext;

AttivitaFisicaSedentaria.propTypes = propTypes;

