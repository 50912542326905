import React, {Component} from "react";
import Table from "../../generalComponents/table/Table";
import PropTypes from "prop-types";
import {Button} from "web-client-archetype";
import routepath from "../../../utils/route/route-path.json";
import {Container} from "reactstrap";


const propTypes = {
    rows: PropTypes.array,
    totalElements: PropTypes.number,
    mostraAltri: PropTypes.func
}

export default class ListaUtenti extends Component {

    cols = [
        {key: "cognome", name: "Cognome", sortDirection: "asc", ignoreCase: true},
        {key: "nome", name: "Nome", sortDirection: "none"},
        {key: "codiceFiscale", name: "Codice fiscale", sortDirection: "none"},
        {key: "mail", name: "E-mail", sortDirection: "none"},
        {key: "profili", name: "Profilo", sortDirection: "none", tipo: "array"},
        {key: "aulss", name: "AULSS", sortDirection: "none", tipo: "array"},
        {key: "distretto", name: "Distretto"},
        {key: "dettagli", tipo: "link", path: routepath.modifica_utente, label: "Dettagli"}
    ]

    render() {
        const showMostraAltriBtn = this.props.totalElements && this.props.totalElements > 10 && this.props.rows.length < this.props.totalElements;

        let containerClass = "pt-3 pl-5 pr-5";
        if (!showMostraAltriBtn) containerClass += " pb-2";

        return (
            <Container fluid={true} className={containerClass}>
                <div className={"row"}>
                    <div className={"col-12"}><h1>Risultati</h1></div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <Table cols={this.cols}
                               rows={this.props.rows.sort((a, b) => a.cognome.toLowerCase().localeCompare(b.cognome.toLowerCase()))}
                               emptyMsg={"La ricerca non ha prodotto risultati"}
                               totalElements={0}
                               bigTable={false}
                               nascondiPaginazione={true}
                               lengthEditable={false}
                               tableLenght={this.props.rows.length}
                               showScroll={false}
                               internalScroll={false}
                            //    tableHeightForInternalScroll={'40vh'}
                               
                               />
                    </div>
                </div>
                {showMostraAltriBtn
                    ? <div className={"row"}>
                        <div className="col-12 d-flex align-items-center flex-column bd-highlight">
                            <div className="p-1 bd-highlight">
                                <Button id={"mostraAltriBtn"}
                                        text={"Mostra Altri"}
                                        className="btn btn-adi btnGreenOutline"
                                        onClick={() => this.props.mostraAltri(true)}/>
                            </div>
                            <div className="p-1 bd-highlight">
                                <p>{this.props.rows.length} utenti di {this.props.totalElements}</p>
                            </div>
                        </div>
                    </div>
                    : null}
            </Container>
        );
    }
}

ListaUtenti.propTypes = propTypes;
