import React from 'react'
import './Icon.module.css'
import globals from 'web-client-archetype/src/config/appconfig.js'



class Icon extends React.Component {

    sprite = globals["app-icon-sprite"];

    render() {


        let classes = this.props.classes ? this.props.classes : "";
        let renderedIcon;

        if (this.props.id) {

            renderedIcon = (
                <svg className={"icon ".concat(classes)}
                     style={this.props.style}
                     onMouseOver={this.props.onMouseOver}
                     onMouseOut={this.props.onMouseOut}>
                    <use xlinkHref={`${this.sprite}#${this.props.id}`}/>
                </svg>
            )
        }

        if (this.props.path) {

            renderedIcon = (
                <img className={this.props.classes} src={require("../../../" + this.props.path)} alt={this.props.alt}/>)
        }

        return renderedIcon;

    }
}

export default Icon;
