import React, {Fragment} from "react";
import PropTypes from "prop-types";
import styles from "../Questionario.module.css";

/**
 * @function RenderTitoloSezione renderizza il titolo de ogni sezione
 * @param sezioneQuestionario contiene l'informazione della sezione renderizata es. id, titolo, domande, punteggi ect.
 * @param forceRequiredTitleStyle stabilisce se assegnare lo stile required (*) al titolo della sezione corrente del questionario
*/
function RenderTitoloSezione ({sezioneQuestionario, forceRequiredTitleStyle}) {
    return (
        <>
            {sezioneQuestionario?.titoloSezione ?
                (<Fragment>
                    <div className={"row"}>
                        <div className={"col"}>
                            <span className="f-weight-600">{sezioneQuestionario.titoloSezione} {forceRequiredTitleStyle &&
                                <span className={styles.requiredFields}>*</span>}</span>
                        </div>
                    </div>
                    {sezioneQuestionario.indicazione &&
                        (<div className={"row"}>
                            <div className={"col"}>
                                <span>{sezioneQuestionario.indicazione}</span>
                            </div>
                        </div>)}
                </Fragment>) : <Fragment />
            }
        </>
    )
}

RenderTitoloSezione.propTypes = {
    sezioneQuestionario: PropTypes.node,
    forceRequiredTitleStyle: PropTypes.bool
};

RenderTitoloSezione.defaultProps = {
    sezioneQuestionario: {},
    forceRequiredTitleStyle: false
};

export default RenderTitoloSezione;
