import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import PropTypes from "prop-types";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import React from "react";
import AderenzaAllaTerapia from "../../infoBase/aderenzaAllaTerapia/AderenzaAllaTerapia";
import * as _ from "lodash";
import AccordionHelper from "../../../service/AccordionHelper";
import Utils from "../../../utils/Utils";
import VersionedAccordion from "../../VersionedAccordion";

const propTypes = {
    datiGestioneDellaSalute: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiGestioneDellaSalute: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

export default class GestioneDellaSalute extends AccordionFirstLevel {

    state = {
        campiObbligatoriMancanti: {
            sottosezioneAderenzaAllaTerapia: 0
        },
        accordion: {
            openAccordionAderenzaAllaTerapia: true
        }
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        let campiObbligatoriMancanti = this.state.campiObbligatoriMancanti;
        campiObbligatoriMancanti[field] = value;
        this.setState({campiObbligatoriMancanti: campiObbligatoriMancanti}, () => {
            this.props.onChangeObbligatorieta("informazioniDiBase", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta.informazioniDiBase = this.props.datiInformazioniDiBase;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    renderBody = () => {
        return (
            <VersionedAccordion accordion={AderenzaAllaTerapia}
                datiAderenzaAllaTerapia={this.props.datiInformazioniDiBase.datiAderenzaAllaTerapia}
                onChangeDatiAderenzaAllaTerapia={this.props.onChangeDatiInformazioniDiBase}
                openAccordion={this.state.accordion.openAccordionAderenzaAllaTerapia}
                onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                onClickAccordion={this.onClickAccordion}
                field={"openAccordionAderenzaAllaTerapia"}
                pageState={this.props.pageState}
                onHide={() => this.hideOptionalAccordion('visualizzaAccordionAderenzaAllaTerapia')}
                isOptional={false}
                accordionPadre={"GESTIONEDELLASALUTE"}
                richiesta={this.props.richiesta}
                salvaBozza={this.props.salvaBozza}
                accordionReadOnly={this.props.accordionReadOnly}
            />);
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"gestioneDellaSaluteInfermiere"}
                title={"Gestione della salute"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBody()}</AccordionAvvioRichiesta>
        );
    }
}

GestioneDellaSalute.propTypes = propTypes;
