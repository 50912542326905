import React, {Component, Fragment} from "react";
import * as _ from 'lodash';
import {Icon, Input, SessioneUtente} from "web-client-archetype";
import {FieldsGroup, FieldsRow} from "../../roleBasedComponents/RoleBasedComponents";
import enumUtente from "../../../enum/enumsUtente"
import enumDelega from "../../../enum/enumDelega"
import classificazione from "../../../utils/dataset/richiestaData/classificazione"
import {professionistaDefault} from "../../statoValutazioneEComponentiDelTeam/StatoValutazioneEComponentiDelTeam";
import ApiAPMSService from "../../../service/ApiAPMSService";
import Select from "react-select";
import {DropdownIndicator, inputSelectStyles} from "../../common/select/SelectUtils";
import Calendar from "../../generalComponents/calendar/Calendar";
import Utils from "../../../utils/Utils";
import DelegaService from "../../../service/DelegaService";
import stiliAttivazioneFiguraProfessionale
    from "../../statoValutazioneEComponentiDelTeam/modaleAttivazioneFiguraProfessionale/AttivazioneFiguraProfessionale.module.css";
import PropTypes from "prop-types";
import aulssElenco from "../../../utils/dataset/aulssData/aulssElenco.json";
import aulssStrutture from "../../../utils/dataset/aulssData/strutture.json";
import AulssUtils from "../../../utils/AulssUtils";
import {
    DISCIPLINA_FISIATRA,
    disciplinaSelect,
    ruoliValutatoreSpecialistaSelect
} from "../../statoValutazioneEComponentiDelTeam/modaleAttivazioneFiguraProfessionale/AttivazioneFiguraProfessionale";
import * as moment from "moment";
import stiliAccordion from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta.module.css";
import * as BsIcon from "react-bootstrap-icons";
import {Container} from "reactstrap";
import ButtonsBoxStick from "../../buttonsBoxStick/buttonsBoxStick";

const propTypes = {
    name: PropTypes.string,
    paginaPrecedente: PropTypes.func,
    delega: PropTypes.object, // oggetto delega da modificare
    centrale: PropTypes.string, // passata se utente è amministratore in caso di modifica, consente di modificare solo l'email dell'ambito in input
    listaDeleghe: PropTypes.array // utilizzata per filtrare le liste delle select
}


const AZIONI_SELECT = [
    {
        value: [enumDelega.azioni.COMPOSIZIONE_DEL_TEAM_E_FORMALIZZAZIONE_DEL_SETTING_RICHIESTO.value],
        label: enumDelega.azioni.COMPOSIZIONE_DEL_TEAM_E_FORMALIZZAZIONE_DEL_SETTING_RICHIESTO.label
    },
    {
        value: [enumDelega.azioni.FORMALIZZAZIONE_DEL_SETTING_RICHIESTO_E_FIRMA.value],
        label: enumDelega.azioni.FORMALIZZAZIONE_DEL_SETTING_RICHIESTO_E_FIRMA.label
    },
    {
        value: [enumDelega.azioni.VALUTAZIONE_PROFESSIONALE.value],
        label: enumDelega.azioni.VALUTAZIONE_PROFESSIONALE.label
    },
];

const VALORE_TUTTI_LISTA = "TUTTI";
const VALORE_VUOTO_LISTA = "";
const SPAZIO_ICONA = <div className={"pr-3"} style={{width: "48px"}}/>;

const LISTA_TUTTI = [{
    value: VALORE_TUTTI_LISTA,
    label: "Tutti"
}];

const ATTIVA_DELEGA_PER_LIST = [
    {value: enumUtente.profiliUtente.centraleAdi, label: "Centrale ADI"},
    {value: enumUtente.ruoli.fisiatra, label: enumUtente.ruoliFE.P_MEDICO_SPECIALISTA_FISIATRA}
]

const PROFESSIONISTA_DELEGATO_DEFAULT = {
    profilo: null,
    ruolo: null,
    disciplina: null,
    codiceFiscale: null,
    nome: null,
    cognome: null,
    email: null
};

const INPUT_DISABILITATE_MODIFICA = {
    aulss: true,
    distretto: true,
    ambito: true,
    email: false,
    ruolo: true,
    disciplina: true,
    unitaOperativa: true,
    azioni: true,
    professionistaDelegato: true,
    dataInizio: false,
    dataFine: false
}

export const LISTA_TIPOLOGIE_ADI = [
    {value: classificazione.CON_INFERMIERE.value, label: "ADI con infermiere"},
    {value: classificazione.SENZA_INFERMIERE.value, label: "ADI senza infermiere"},
    {value: classificazione.PROGRAMMATA.value, label: "ADI programmata"},
    {value: classificazione.RIABILITATIVA.value, label: "ADI riabilitativa"},
]

const MESSAGGIO_SUCCESSO_CENSIMENTO_CENTRALE_ADI = "La centrale ADI è stata inserita correttamente. \n" +
    "E’ necessario configurare almeno un operatore di Centrale ADI affinché una richiesta sia gestita da una Centrale ADI";
const MESSAGGIO_SUCCESSO_INSERITA_DELEGA = "La delega è stata inserita correttamente";
const MESSAGGIO_SUCCESSO_INSERITA_NOMINA_AUTOMATICA = "Il professionista è stato nominato correttamente";
const MESSAGGIO_ERRORE_INSERIMENTO_NOMINA_AUTOMATICA = "Professionista nominato per la valutazione professionale già presente";
const MESSAGGIO_ERRORE_INSERIMENTO_DELEGA = "Delega già presente";

export default class InserisciDelega extends Component {

    state = {
        delega: null,
        tipologiaDaEliminare: null,
        listaProfessionisti: null,
        professionistiSelect: null,
        listaAulss: null,
        listaDistretti: [],
        listaAmbiti: null,
        listaUnitaOperativa: null,
        ruoliSpecialistaSelect: []
    };

    professionistiApms = [];


    DELEGA_DEFAULT = {
        id: null,
        ruolo: null,
        disciplina: null,
        aulss: null,
        distretto: null,
        listaCentrali: null,
        azioni: null,
        tipologie: null,
        professionistaDelegante: null,
        professionistaDelegato: this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI ? PROFESSIONISTA_DELEGATO_DEFAULT : null,
        dataInizio: null,
        dataFine: null,
        cancellata: this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI ? false : null,
    };

    CENTRALE_ADI_DEFAULT = {
        ambito: null,
        email: null,
        attivo: true
    }

    componentDidMount() {
        if(this.props.name == enumDelega.menu.CENSIMENTO_CENTRALE_ADI) {
            this.creaListePerAmministratoreReferente()
        }

        if(this.props.name == enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE){
            this.creaListePerAmministratoreReferente()
        }

        if(this.props.name === enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
            let listaUnitaOperativa = AulssUtils.getUnitaOperativeByIdAulss(SessioneUtente.getInstance().ulss);
            let ruoliSpecialistaSelect = ruoliValutatoreSpecialistaSelect;
            if(_.some(this.props.listaDeleghe, d => d.disciplina === DISCIPLINA_FISIATRA)){
                ruoliSpecialistaSelect = _.filter(ruoliValutatoreSpecialistaSelect, r => r.value !== enumUtente.ruoli.fisiatra);
            }
            this.setState({listaUnitaOperativa: listaUnitaOperativa, ruoliSpecialistaSelect: ruoliSpecialistaSelect})
        }

        if(this.props.delega){
           this.delegaPerModifica(_.cloneDeep(this.props.delega));
        } else {
            let delega = _.cloneDeep(this.DELEGA_DEFAULT);
            if(this.props.name == enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE){
                delega.aulss = SessioneUtente.getInstance().ulss;
            } else if(this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
                delega.azioni = [enumDelega.azioni.VALUTAZIONE_PROFESSIONALE.value];
            } else if(this.props.name == enumDelega.menu.CENSIMENTO_CENTRALE_ADI){
                delega.listaCentrali = [_.cloneDeep(this.CENTRALE_ADI_DEFAULT)];
                delega.ruolo = enumUtente.profiliUtente.centraleAdi;
            }

            this.setState({delega: delega})
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!_.isEqual(prevProps.delega, this.props.delega)){
            this.delegaPerModifica(_.cloneDeep(this.props.delega))
        }
    }

    creaListePerAmministratoreReferente = () => {
        let listaAulss = this.getListaSelect(aulssElenco);
        this.setState({listaAulss: listaAulss})
    }

    getDistretti = (aulss, ruolo = null) => {
        let distretti = AulssUtils.getDistrettiByAulssId(aulss);
        if (ruolo === enumUtente.profiliUtente.centraleAdi) {
            distretti = this.getDistrettiReferenteAulssConnesso(distretti);
            DelegaService.ricercaDeleghe(null, null, 0, 15, enumUtente.profiliUtente.centraleAdi).subscribe((pagina) => {
                let deleghe = pagina.lista;
                let distrettiCensiti = _.map(deleghe, d => d.distretto);
                distretti = _.filter(distretti, distretto => distrettiCensiti.includes(distretto.id));
                this.setState({listaDistretti: this.getListaSelect(distretti)})
            });
        } else if (ruolo === enumUtente.ruoli.fisiatra) {
            distretti = this.getDistrettiReferenteAulssConnesso(distretti);
            this.setState({listaDistretti: this.getListaSelect(distretti)});
        } else {
            this.setState({listaDistretti: this.getListaSelect(distretti)});
        }
    }

    getDistrettiReferenteAulssConnesso(distretti) {
        let settingsReferenteDistrettuale = _.find(SessioneUtente.getInstance().settings, s => s.profilo === enumUtente.profiliUtente.referenteAulss);
        // todo filtrare per lista distretti quando l'utente referente potrà avere 1-n distretti
        distretti = _.filter(distretti, distretto => settingsReferenteDistrettuale.distretto === distretto.id)
        return distretti;
    }

    getAmbiti = (aulss, distretto) => {
        let ambiti = AulssUtils.getAmbitiListByIdAulssAndIdDistretto(aulss, distretto);
        let listaCentrali = _.cloneDeep(this.props.listaDeleghe);
        let listaCentraliPerDistretto = _.filter(listaCentrali, el => el.distretto === this.state.delega?.distretto);
        let listaAmbitiPerDistretto = _.flatMap(listaCentraliPerDistretto, el => _.map(el.listaCentrali, c => c.ambito));
        this.setState({listaAmbiti: this.getListaSelect(ambiti, listaAmbitiPerDistretto)});
    }

    getListaSelect(lista, listaValoriDaScartare = []) {
        let listaSelect = [];
        _.forEach(lista, elemento => {
            if (!listaValoriDaScartare.includes(elemento.id)) {
                listaSelect.push({value: elemento.id, label: elemento.descrizione});
            }
        });
        return listaSelect;
    }

    delegaPerModifica = (delega) => {
        let listaProfessionisti = [];
        if (delega) {
            if(this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
                // set lista professionist per select
                this.professionistiApms = [];
                this.professionistiApms.push(delega.professionistaDelegato);
                this.setProfessionistaSelect(delega.professionistaDelegato, listaProfessionisti);
                delega.dataFine = !Utils.isStringEmptyOrNullOrUndefined(delega.dataFine) ? delega.dataFine : ""; // per rendere vuota la input calendar
            }

            // set liste per amministratori e referenti
            if (this.props.name == enumDelega.menu.CENSIMENTO_CENTRALE_ADI || this.props.name == enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE) {
                let ruolo = this.props.name == enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE ? delega.ruolo : null;
                this.getDistretti(delega.aulss, ruolo);
                if(this.props.name == enumDelega.menu.CENSIMENTO_CENTRALE_ADI){
                    this.getAmbiti(delega.aulss, delega.distretto);
                    // è possibile modificare il solo ambito selezionato
                    delega.listaCentrali = _.filter(delega.listaCentrali, centrale => centrale.ambito === this.props.centrale.ambito);
                }
            }
        }

        this.setState({
            delega: delega,
            listaProfessionisti: listaProfessionisti,
            professionistaSelect: listaProfessionisti
        })
    }

    setFormState(value, delega, field, listaProfessionisti, callBack) {
        if (Utils.isStringEmptyOrNullOrUndefined(value))
            value = null;

        delega[field] = value;

        this.setState({
            delega: delega,
            listaProfessionisti: listaProfessionisti
        }, callBack);
    }

    handleAggiornaFormRef = (field, value, index) => {
        let delega = _.cloneDeep(this.state.delega);
        let listaProfessionisti = _.cloneDeep(this.state.listaProfessionisti);

        switch (field) {
            case "ruolo": {
                if(value === enumUtente.profiliUtente.centraleAdi){
                    delega.azioni = [enumDelega.azioni.COMPOSIZIONE_DEL_TEAM_E_FORMALIZZAZIONE_DEL_SETTING_RICHIESTO.value]
                    delega.tipologie = [VALORE_VUOTO_LISTA]
                } else if(value === enumUtente.ruoli.fisiatra){
                    delega.azioni = [enumDelega.azioni.FORMALIZZAZIONE_DEL_SETTING_RICHIESTO_E_FIRMA.value]
                    delega.tipologie = [classificazione.RIABILITATIVA.value]
                }
                this.getDistretti(this.state.delega.aulss, value)
            }
                break;
            case "tipologie": {
                // passa il valore dell'intero array tipologie e non del solo elemento per aggiornare lo stato
                let tipologie = _.cloneDeep(delega.tipologie);
                tipologie[index] = value;
                value = tipologie;
            }
                break;
            default:
                break;
        }

        this.setFormState(value, delega, field, listaProfessionisti, () => {});
    }

    handleAggiornaFormAmm = (field, value, index, object, objectField) => {
        let delega = _.cloneDeep(this.state.delega);
        let listaProfessionisti = _.cloneDeep(this.state.listaProfessionisti);

        switch (field) {
            case "aulss": {
                this.getDistretti(value);
            }
                break;
            case "distretto": {
                this.getAmbiti(this.state.delega.aulss, value);
            }
                break;
            case "listaCentrali": {
                // passa il valore dell'intero array tipologie e non del solo elemento per aggiornare lo stato
                let listaCentrali = _.cloneDeep(delega.listaCentrali);
                object[objectField] = value;
                listaCentrali[index] = object;
                value = listaCentrali;
            }
                break;
            default:
                break;
        }

        this.setFormState(value, delega, field, listaProfessionisti, () => {});
    }

    handleAggiornaFormDirUO = (field, value) => {
        let delega = _.cloneDeep(this.state.delega);
        let listaProfessionisti = _.cloneDeep(this.state.listaProfessionisti);

        switch (field) {
            case "ruolo": {
                delega.unitaOperativa = null;
                delega.disciplina = null;

                if (value === enumUtente.ruoli.fisiatra && !_.some(this.props.listaDeleghe, d => d.disciplina === DISCIPLINA_FISIATRA)) {
                    delega.disciplina = DISCIPLINA_FISIATRA;
                }
                listaProfessionisti = [];


                delega.professionistaDelegato = PROFESSIONISTA_DELEGATO_DEFAULT
            }
                break;
            default:
                break;
        }

       let callBack = () => {
           let cercaSuRuolo = field === "unitaOperativa" && (this.state.delega?.ruolo === enumUtente.ruoli.psicologo || (this.state.delega?.ruolo === enumUtente.ruoli.fisiatra && this.state.delega?.disciplina));
           let cercaSuDisciplina = field === "disciplina";
           let cerca = cercaSuRuolo || cercaSuDisciplina;
            if (cerca) {
                this.cercaProfessionisti()
            }
        }

        this.setFormState(value, delega, field, listaProfessionisti, callBack);
    }

    cercaProfessionisti = () => {
        let delega = _.cloneDeep(this.state.delega);
        let utente = {
            aulss: SessioneUtente.getInstance().codiceStruttura,
            ruolo: delega.ruolo,
            disciplina: delega.disciplina,
            unitaOperativa: delega.unitaOperativa
            // distretto: getDistrettoSettings(SessioneUtente.getInstance().settings)
        };

        ApiAPMSService.letturaUtenti(utente).subscribe(res => {
            this.setListaProfessionisti(res.elementi, utente.ruolo, utente.disciplina, utente.distretto)
        });
    }

    setListaProfessionisti = (utenti, ruolo, disciplina, distretto) => {
        this.professionistiApms = [];
        let listaProfessionisti = [];
        // set null value tutti i professionisti
        if(!this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI) {
            this.aggiungiValoreTutti(listaProfessionisti);
        }
        let tutti = _.cloneDeep(PROFESSIONISTA_DELEGATO_DEFAULT);
        tutti.codiceFiscale = VALORE_TUTTI_LISTA;
        this.professionistiApms.push(tutti);
        let codiciGruppo = [enumUtente.profiliUtente.professionistaValutatore];
        _.forEach(utenti, utente => {
            let gruppo = _.find(utente?.gruppi, g => {
                let dirUoConnesso = g["codice_gruppo"] === enumUtente.profiliUtente.direttoreUnitaOperativa &&
                    utente?.codiceFiscale === SessioneUtente.getInstance().idUtente;
                return codiciGruppo.includes(g["codice_gruppo"]) || dirUoConnesso;
            });
            if(gruppo) {
                let attributo = _.find(gruppo?.attributi, a => a.codiceAttributo === "adi-lista-settings");
                let valoreAttributo = attributo?.valore?.slice(1, -1);
                // recupero il corretto elemento della adi lista settings
                let elements = valoreAttributo.split(",");
                let elementCodiceGruppo = _.find(elements, elem => {
                    let settings = elem?.split("@");
                    return [enumUtente.profiliUtente.professionistaValutatore, enumUtente.profiliUtente.direttoreUnitaOperativa].includes(settings[0]) &&
                        settings[1] === ruolo && (disciplina == null || settings[5] === disciplina) && (distretto == null || settings[2] === distretto );

                });
                let listaSettings = elementCodiceGruppo?.split("@");
                let profiloUtente = listaSettings?.[0];
                if (profiloUtente === enumUtente.profiliUtente.professionistaValutatore || utente?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                    let professionistaApms = _.cloneDeep(professionistaDefault);
                    professionistaApms.codiceFiscale = utente?.codiceFiscale;
                    professionistaApms.nome = utente?.nome;
                    professionistaApms.cognome = utente?.cognome;
                    professionistaApms.telefono = utente?.telefono;
                    professionistaApms.cellulare = utente?.cellulare;
                    professionistaApms.email = utente?.mail;
                    professionistaApms.profilo = profiloUtente;
                    professionistaApms.ruolo = listaSettings[1];
                    professionistaApms.comune = listaSettings[4];
                    professionistaApms.disciplina = listaSettings[5] || null;
                    this.professionistiApms.push(professionistaApms);
                    this.setProfessionistaSelect(utente, listaProfessionisti);
                }
            }
        });

        this.setState({listaProfessionisti: listaProfessionisti, professionistiSelect: listaProfessionisti})
    }

    setProfessionistaSelect(utente, listaProfessionisti) {
        // set label
        let label = utente?.nome + " " + utente?.cognome + " - " + utente?.codiceFiscale;
        let professionistaSelect = {value: utente?.codiceFiscale, label: label};
        listaProfessionisti.push(professionistaSelect);
    }

    aggiungiValoreTutti(listaProfessionisti) {
        listaProfessionisti.push({value: VALORE_TUTTI_LISTA, label: "Tutti i professionisti"});
    }

    bottoneDisabilitato = () => {
        let formValido;
        if(this.props.name == enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE){
            formValido = this.state.delega?.aulss && this.state.delega?.distretto &&
                (this.state.delega?.tipologie?.length > 0 && !_.some(this.state.delega?.tipologie, t => t === VALORE_VUOTO_LISTA)) && this.state.delega?.azioni?.length > 0;
        } else if(this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
            let now = moment().format("DD/MM/YYYY");
            let dataInizioBeforeNow = this.getData1AfterData2(now, this.state.delega?.dataInizio,
                "Data inizio è precedente alla data odierna");
            // data inizio deve essere maggiore di data odierna
            let isDataOdiernaBeforeEqualsDataInizio =  Utils.checkDateIntervalMinore(moment().format("DD/MM/YYYY"), this.state.delega?.dataInizio, true);
            // data fine deve essere maggiore a data inizio
            let isDataInizioBeforeEqualsDataFine = Utils.checkDateIntervalMinore(this.state.delega?.dataInizio, this.state.delega?.dataFine, true);
            let dateValide = ((this.state.delega?.dataInizio && !this.state.delega?.dataFine) ||
                (this.state.delega?.dataInizio && isDataInizioBeforeEqualsDataFine && isDataOdiernaBeforeEqualsDataInizio)) && !dataInizioBeforeNow;
            let consideraDisciplina = [enumUtente.ruoli.specialista, enumUtente.ruoli.fisiatra].includes(this.state.delega?.ruolo) ? this.state.delega.disciplina : true;
            formValido = this.state.delega?.ruolo && !_.isEmpty(this.state.delega?.azioni) && consideraDisciplina && this.state.delega.unitaOperativa
                && this.state.delega?.professionistaDelegato?.codiceFiscale && dateValide;
        } else if(this.props.name == enumDelega.menu.CENSIMENTO_CENTRALE_ADI){
            if(!this.props.centrale){
                formValido = this.state.delega?.aulss && this.state.delega?.distretto &&
                    (this.state.delega?.listaCentrali?.length > 0 &&
                        !_.some(this.state.delega?.listaCentrali,
                            c => _.isEqual(c.ambito, this.CENTRALE_ADI_DEFAULT.ambito) || _.isEqual(c.email, this.CENTRALE_ADI_DEFAULT.email)));
            } else {
                formValido = (this.props.centrale &&
                    !_.some(this.state.delega?.listaCentrali,
                        c => c.email ===  this.props.centrale.email));
            }
        }
        return !formValido;
    }

    confermaDelega = () => {
        let delega = _.cloneDeep(this.state.delega);
        if(!this.props.delega) {
            // inserimento
            this.chiamaInserimentoDelega(delega);
        } else {
            // modifica
            this.chiamaModificaDelega(delega);
        }
    }

    chiamaInserimentoDelega(delega) {
        let dto = this.mappaDelegaPerBE(delega);
        DelegaService.inserimentoDeleghe(dto).subscribe((res) => {
            if (!res.isError) {
                this.setState({
                    delega: null,
                    listaProfessionisti: null,
                    professionistiSelect: null
                }, () => {
                    let messaggioSuccesso;
                    if(this.props.name === enumDelega.menu.CENSIMENTO_CENTRALE_ADI){
                        messaggioSuccesso = MESSAGGIO_SUCCESSO_CENSIMENTO_CENTRALE_ADI;
                    } else if(this.props.name === enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE){
                        messaggioSuccesso = MESSAGGIO_SUCCESSO_INSERITA_DELEGA;
                    } else if(this.props.name === enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
                        messaggioSuccesso = MESSAGGIO_SUCCESSO_INSERITA_NOMINA_AUTOMATICA;
                    }

                    this.props.paginaPrecedente(messaggioSuccesso);
                })
            } else {
                if (res?.data && res?.data?.error && res?.status === 409) {
                    Utils.toasterFunction({
                        status: res?.status,
                        type: "critical",
                        text: this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI ?
                            MESSAGGIO_ERRORE_INSERIMENTO_NOMINA_AUTOMATICA :
                            MESSAGGIO_ERRORE_INSERIMENTO_DELEGA
                    })
                }
            }
        });
    }

    chiamaModificaDelega(delega) {
        let patchBody;
        if (this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI) {
            patchBody = {
                dataInizio: Utils.transformDateForBackend(delega.dataInizio, "YYYY-MM-DD"),
                dataFine: !Utils.isStringEmptyOrNullOrUndefined(delega.dataFine) ? Utils.transformDateForBackend(delega.dataFine, "YYYY-MM-DD") : null
            };
        } else if (this.props.name == enumDelega.menu.CENSIMENTO_CENTRALE_ADI) {
            let propDelega = _.cloneDeep(this.props.delega);
            let listaCentrali = _.map(propDelega.listaCentrali, centrale => {
                if(centrale.ambito === this.props.centrale.ambito){
                    // cerca centrale modificata e assegna a oggetto delega
                    centrale = _.find(this.state.delega.listaCentrali, c => c.ambito === this.props.centrale.ambito);
                }
                return centrale;
            })
            patchBody = {
                listaCentrali: listaCentrali
            };
        }
        DelegaService.modificaDelega(delega.id, patchBody).subscribe((res) => {
            if (!res.isError) {
                this.setState({
                    delega: null,
                    listaProfessionisti: null,
                    professionistiSelect: null
                }, () => {
                    if (!res.isError) {
                        
                        let messaggioSuccesso ="";

                        if(this.props.name === enumDelega.menu.CENSIMENTO_CENTRALE_ADI){
                            messaggioSuccesso = "La centrale ADI è stata modificata correttamente";
                        } else if(this.props.name === enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE){
                            messaggioSuccesso = "La delega è stata modificata correttamente";
                        } else if(this.props.name === enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
                            messaggioSuccesso = "La nomina è stata modificata correttamente";
                        }

                        this.props.paginaPrecedente(messaggioSuccesso);
                    }
                })
            }
        });
    }

    annulla = () => {
        this.professionistiApms = [];
        this.setState({delega: null, listaProfessionisti: null, professionistiSelect: null}, () => {
            this.props.paginaPrecedente(null);
        })
    }

    mappaDelegaPerBE(delega) {
        let delegaBE = _.cloneDeep(delega);
        if (this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI) {
            delegaBE.dataInizio = Utils.transformDateForBackend(delegaBE.dataInizio, "YYYY-MM-DD");
            delegaBE.dataFine = delegaBE.dataFine ? Utils.transformDateForBackend(delegaBE.dataFine, "YYYY-MM-DD") : null;
            if (this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI) {
                delegaBE.professionistaDelegato = delegaBE.professionistaDelegato?.codiceFiscale === VALORE_TUTTI_LISTA ? null : delegaBE.professionistaDelegato;
            }
        }
        return delegaBE;
    }

    getData1AfterData2 = (data1, data2, msg = null, minoreUguale = true) => {
        let data1BeforeEqualsData2 = Utils.checkDateIntervalMinore(data1, data2, minoreUguale);
        let isData1AfterData2 =  (!Utils.isStringEmptyOrNullOrUndefined(data1) &&
            !Utils.isStringEmptyOrNullOrUndefined(data2) &&
            !data1BeforeEqualsData2);
        let ret = null;
        if(isData1AfterData2)
            ret = msg;
        return ret;
    }

    isDisabilitato = (field, index) => {
        let isDisabilitato;
        let isDisabilitatoPerModifica = this.props.delega && INPUT_DISABILITATE_MODIFICA[field];
        switch (field) {
            case "aulss":
                isDisabilitato = this.props.name == enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE || (this.props.name == enumDelega.menu.CENSIMENTO_CENTRALE_ADI && index > 0) || isDisabilitatoPerModifica;
                break;
            case "distretto":
                isDisabilitato = !this.state.delega?.aulss || index > 0 || isDisabilitatoPerModifica;
                break;
            case "ambito":
                isDisabilitato = this.props.name == enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE || !this.state.delega?.distretto || isDisabilitatoPerModifica;
                break;
            case "email":
                isDisabilitato = !this.state.delega?.ambito || isDisabilitatoPerModifica;
                break;
            case "azioni":
                isDisabilitato = true;
                break;
                case "tipologie":
                isDisabilitato = this.state.delega?.ruolo === enumUtente.ruoli.fisiatra;
                break;
            default:
                isDisabilitato = isDisabilitatoPerModifica; // disabilitato modifica
                break;

        }
        return isDisabilitato;
    }

    renderDelegaPer = () => {
        return <div className={"row py-1"}>
            {SPAZIO_ICONA}
            {/*attiva delega per*/}
            <div className={"col"}>
                <FieldsGroup fieldLabel={"Attiva delega per"} fieldRequired={true}>
                    <Select
                        id={"selectAttivaDelegaPer"}
                        classNamePrefix={"inputSelect"}
                        components={DropdownIndicator}
                        styles={inputSelectStyles}
                        options={ATTIVA_DELEGA_PER_LIST}
                        value={_.find(ATTIVA_DELEGA_PER_LIST, delegaPer => delegaPer.value === this.state.delega?.ruolo) ?? null}
                        onChange={(elem) => this.handleAggiornaFormRef("ruolo", elem.value)}
                        isSearchable={false}
                        placeholder={"Seleziona a chi delegare"}
                        noOptionsMessage={() => "Errore: Ruoli da delegare non esistenti"}
                        isDisabled={this.isDisabilitato("ruolo")}
                    />
                </FieldsGroup>
            </div>
            <div className={"col"}>
                {/*azioni delegate*/}
                <FieldsGroup fieldLabel={"Azioni delegate"}>
                    <Select
                        id={"selectAzioniDelegate"}
                        classNamePrefix={"inputSelect"}
                        components={DropdownIndicator}
                        styles={inputSelectStyles}
                        options={AZIONI_SELECT}
                        value={_.find(AZIONI_SELECT, azione => _.isEqual(azione.value, this.state.delega?.azioni)) ?? null}
                        onChange={(elem) => this.handleAggiornaFormRef("azioni", elem.value)}
                        isSearchable={false}
                        placeholder={""}
                        noOptionsMessage={() => "Errore: Azioni non esistenti"}
                        isDisabled={this.isDisabilitato("azioni")}
                    />
                </FieldsGroup>
            </div>
            <div className={"col offset"}/>
            <div className={"col offset"}/>
        </div>
    }

    renderListaCentrali = () => {
        let listaCentrali = [];
        _.forEach(this.state.delega?.listaCentrali, (centrale, index) => {
            let centraleAssociata = _.find(this.props.listaDeleghe, d => d.aulss === this.state.delega.aulss &&
                d.distretto === this.state.delega.distretto && d.ruolo === this.state.delega.ruolo);
            let options = _.filter(this.state.listaAmbiti, ambito => {
                let centralePresenteInMonitoraggio = centraleAssociata ? !_.some(centraleAssociata.listaCentrali, c => c.ambito === ambito.value) : true;
                return !_.some(this.state.delega.listaCentrali, c => c.ambito === ambito.value) &&
                    centralePresenteInMonitoraggio;
            });
            let nascondi = this.state.delega.listaCentrali?.[index+1] != null ||  this.props.centrale; // this.state.delega.listaCentrali?.length === this.state.listaAmbiti?.length ||
            let disabilitato = _.isEqual(this.state.delega.listaCentrali[index].ambito, this.CENTRALE_ADI_DEFAULT.ambito) ||
                _.isEqual(this.state.delega.listaCentrali[index].email, this.CENTRALE_ADI_DEFAULT.email);
            let aggiungiFunc = () => {
                if(!disabilitato) {
                    let delega = _.cloneDeep(this.state.delega);
                    delega.listaCentrali.push(_.cloneDeep(this.CENTRALE_ADI_DEFAULT));
                    this.setState({delega: delega});
                }
            }
            let nascondiElimina = this.props.centrale;
            let disabilitatoElimina = this.state.delega.listaCentrali?.length === 1;
            let eliminaFunc = () => {
                let delega = _.cloneDeep(this.state.delega);
                delega.listaCentrali.splice(index, 1);
                this.setState({delega: delega});
            }
            const valueAmbito = _.find(this.state.listaAmbiti, ambito => ambito.value === centrale.ambito);
            let offset = <Fragment/>;
            let delegaJSX = <Fragment>
                <div className={"row py-1"}>
                    {/*{this.renderElimina(index, nascondiElimina, disabilitatoElimina, eliminaFunc)}*/}
                    <div className={"col"}>
                        {/*aulss*/}
                        <FieldsGroup fieldLabel={"Aulss"} fieldRequired={true}>
                            <Select
                                id={"selectAulssDelega"}
                                classNamePrefix={"inputSelect"}
                                components={DropdownIndicator}
                                styles={inputSelectStyles}
                                options={this.state.listaAulss}
                                value={_.find(this.state.listaAulss, aulss => aulss.value === this.state.delega?.aulss) ?? null}
                                onChange={(elem) => this.handleAggiornaFormAmm("aulss", elem.value, index)}
                                isSearchable={false}
                                placeholder={"Seleziona aulss"}
                                noOptionsMessage={() => "Errore: Aulss non esistente"}
                                isDisabled={this.isDisabilitato("aulss", index)}
                            />
                        </FieldsGroup>
                    </div>
                    <div className={"col"}>
                        {/*distretto*/}
                        <FieldsGroup fieldLabel={"Distretto"} fieldRequired={index === 0}>
                            <Select
                                id={"selectDistrettoDelega"}
                                classNamePrefix={"inputSelect"}
                                components={DropdownIndicator}
                                styles={inputSelectStyles}
                                options={this.state.listaDistretti}
                                value={_.find(this.state.listaDistretti, distretto => distretto.value === this.state.delega?.distretto) ?? "-"}
                                onChange={(elem) => this.handleAggiornaFormAmm("distretto", elem.value, index)}
                                isSearchable={false}
                                placeholder={"Seleziona distretto"}
                                noOptionsMessage={() => "Errore: Distretto non esistente"}
                                isDisabled={this.isDisabilitato("distretto", index)}
                            />
                        </FieldsGroup>
                    </div>
                    <div className={"col"}>
                        {/*ambito*/}
                        <FieldsGroup fieldLabel={"Ambito"}>
                            <Select
                                id={"selectAmbitoDelega"}
                                classNamePrefix={"inputSelect"}
                                components={DropdownIndicator}
                                styles={inputSelectStyles}
                                options={options}
                                value={valueAmbito ?? null}
                                onChange={(elem) => { this.handleAggiornaFormAmm("listaCentrali", elem.value, index, centrale, "ambito")
                                }}
                                isSearchable={false}
                                placeholder={"Seleziona ambito"}
                                noOptionsMessage={() => "Errore: Ambito non esistente"}
                                isDisabled={this.isDisabilitato("ambito")}
                            />
                        </FieldsGroup>
                    </div>
                    {this.props.name == enumDelega.menu.CENSIMENTO_CENTRALE_ADI && valueAmbito &&
                        <div className={"col"}>
                            <FieldsGroup fieldLabel={"Sede"}>
                                <Input
                                    value={aulssStrutture.find(a => a.id === this.state.delega?.aulss)?.distretti?.find(d => d.id === this.state.delega?.distretto)?.sedi?.find(s => s.ambiti.find(a => a.codice=== centrale.ambito))?.nome || ""}
                                    placeholder={""}
                                    field={"sede"}
                                    id={"inputSede"}
                                    type={"text"}
                                    parentClass={"mb-0"}
                                    disabled
                                />
                            </FieldsGroup>
                        </div>
                    }

                    <div className={"col"}>
                        {/*email*/}
                        <FieldsGroup fieldLabel={this.props.name == enumDelega.menu.CENSIMENTO_CENTRALE_ADI ? "E-mail di gruppo" : "Email"} fieldRequired={true}>
                            <Input
                                value={centrale.email ?? ""}
                                placeholder={"Inserisci email"}
                                field={"email"}
                                onChange={(field, elem) => this.handleAggiornaFormAmm("listaCentrali", elem.target.value, index, centrale, field)}
                                id={"inputEmail"}
                                type={"text"}
                                parentClass={"mb-0"}
                            />
                        </FieldsGroup>
                    </div>
                    {offset}
                    {this.renderElimina(index, nascondiElimina, disabilitatoElimina, eliminaFunc)}
                </div>
                {this.renderAggiungi(index, nascondi, disabilitato, aggiungiFunc, "Aggiungi centrale ADI")}
            </Fragment>

            listaCentrali.push(delegaJSX);
        })
        return listaCentrali;
    }

    renderListaDeleghe = () => {
        let listaDelegheJSX = [];
        _.forEach(this.state.delega?.tipologie, (tipologia, index) => {
            // delega presente in monitoraggio deleghe
            let delegaAssociata = _.find(this.props.listaDeleghe, d => d.aulss === this.state.delega.aulss &&
                d.distretto === this.state.delega.distretto && d.ruolo === this.state.delega.ruolo);
            let options = _.filter(LISTA_TIPOLOGIE_ADI, tipologiaADI => {
                let tipologiaPresenteInMonitoraggio = delegaAssociata ? !_.some(delegaAssociata.tipologie, t => t === tipologiaADI.value) : true;
                return !_.some(this.state.delega.tipologie, t => t === tipologiaADI.value) &&
                    tipologiaPresenteInMonitoraggio;
            });
            let ambito = <Fragment/>;
            let offset = <Fragment/>;
            let nascondi = this.state.delega.tipologie?.[index+1] != null || this.state.delega?.ruolo === enumUtente.ruoli.fisiatra; //this.state.delega.tipologie?.length === LISTA_TIPOLOGIE_ADI.length ||
            let disabilitato = this.state.delega.tipologie[index] === VALORE_VUOTO_LISTA;
            let aggiungiFunc = () => {
                if(!disabilitato) {
                    let delega = _.cloneDeep(this.state.delega);
                    delega.tipologie.push(VALORE_VUOTO_LISTA);
                    this.setState({delega: delega});
                }
            }

            let nascondiElimina = this.state.delega?.ruolo === enumUtente.ruoli.fisiatra;
            let disabilitatoElimina = this.state.delega.tipologie?.length === 1;
            let eliminaFunc = () => {
                let delega = _.cloneDeep(this.state.delega);
                delega.tipologie.splice(index, 1);
                this.setState({delega: delega});
            }
            if (this.state.delega.ruolo !== enumUtente.ruoli.fisiatra) {
                ambito = <div className={"col"}>
                    {/*ambito*/}
                    <FieldsGroup fieldLabel={"Ambito"}>
                        <Select
                            id={"selectAmbitoDelega"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            styles={inputSelectStyles}
                            options={LISTA_TUTTI}
                            value={_.find(LISTA_TUTTI, ambito => ambito.value === VALORE_TUTTI_LISTA) ?? null}
                            onChange={() => {
                            }}
                            isSearchable={false}
                            placeholder={"Seleziona ambito"}
                            noOptionsMessage={() => "Errore: Ambito non esistente"}
                            isDisabled={this.isDisabilitato("ambito")}
                        />
                    </FieldsGroup>
                </div>;
            } else {
                offset = <div className={"col offset"}/>
            }
            let delegaJSX = <Fragment>
                <div className={"row py-1"}>
                        {this.renderElimina(index, nascondiElimina, disabilitatoElimina, eliminaFunc)}
                    <div className={"col"}>
                        {/*aulss*/}
                        <FieldsGroup fieldLabel={"Aulss"} fieldRequired={true}>
                            <Select
                                id={"selectAulssDelega"}
                                classNamePrefix={"inputSelect"}
                                components={DropdownIndicator}
                                styles={inputSelectStyles}
                                options={this.state.listaAulss}
                                value={_.find(this.state.listaAulss, aulss => aulss.value === this.state.delega?.aulss) ?? null}
                                onChange={(elem) => this.handleAggiornaFormRef("aulss", elem.value)}
                                isSearchable={false}
                                placeholder={"Seleziona aulss"}
                                noOptionsMessage={() => "Errore: Aulss non esistente"}
                                isDisabled={this.isDisabilitato("aulss")}
                            />
                        </FieldsGroup>
                    </div>
                    <div className={"col"}>
                        {/*distretto*/}
                        <FieldsGroup fieldLabel={"Distretto"} fieldRequired={index === 0}>
                            <Select
                                id={"selectDistrettoDelega"}
                                classNamePrefix={"inputSelect"}
                                components={DropdownIndicator}
                                styles={inputSelectStyles}
                                options={this.state.listaDistretti}
                                value={_.find(this.state.listaDistretti, distretto => distretto.value === this.state.delega?.distretto) ?? null}
                                onChange={(elem) => this.handleAggiornaFormRef("distretto", elem.value)}
                                isSearchable={false}
                                placeholder={"Seleziona distretto"}
                                noOptionsMessage={() => "Non è stato censito nessun distretto. " +
                                    "Contattare l'amministratore per l'inserimento della Centrale ADI"}
                                isDisabled={this.isDisabilitato("distretto", index)}
                            />
                        </FieldsGroup>
                    </div>
                    {ambito}
                    <div className={"col"}>
                        {/*tipologia*/}
                        <FieldsGroup fieldLabel={"Tipologia ADI"} fieldRequired={true}>
                            <Select
                                id={"selectTipologiaADI"}
                                classNamePrefix={"inputSelect"}
                                components={DropdownIndicator}
                                styles={inputSelectStyles}
                                options={options}
                                value={_.find(LISTA_TIPOLOGIE_ADI, tipologiaADI => tipologiaADI.value === tipologia) ?? null}
                                onChange={(elem) => this.handleAggiornaFormRef("tipologie", elem.value, index)}
                                isSearchable={false}
                                placeholder={"Seleziona tipologia ADI"}
                                noOptionsMessage={() => "Nessuna tipologia di ADI delegabile. Tutte le tipologie sono state delegate"}
                                isDisabled={this.isDisabilitato("tipologie")}
                            />
                        </FieldsGroup>
                    </div>
                    {offset}
                </div>
                {this.renderAggiungi(index, nascondi, disabilitato, aggiungiFunc, "Aggiungi tipologia ADI")}
            </Fragment>

            listaDelegheJSX.push(delegaJSX);
        })
        return listaDelegheJSX;
    }

    renderElimina = (index, nascondi, disabilitato, eliminaFunc) => {
        let stile = !disabilitato ? stiliAttivazioneFiguraProfessionale.eliminaFiguraRichiesta : stiliAttivazioneFiguraProfessionale.eliminaFiguraRichiestaDisabilitato
        let eliminaJSX = SPAZIO_ICONA;
        if (!nascondi) {
            eliminaJSX = <Fragment>
                <div className={"pr-3 d-flex"}>
                    <div className={"row align-items-end"}>
                        <div className={"col"}>
                            <span
                                id={"elimina" + index}
                                className={stile}
                                onClick={() => eliminaFunc()}
                                style={{cursor: "pointer"}} >
                                <BsIcon.Trash3 size={15} className={stile}/>
                            </span>
                        </div>
                    </div>
                </div>
            </Fragment>
        }
        return eliminaJSX;
    }

    renderAggiungi = (index, nascondi, disabilitato, aggiungiFunc, text) => {
        let stile = !disabilitato ? stiliAttivazioneFiguraProfessionale.aggiungiFiguraRichiesta : stiliAttivazioneFiguraProfessionale.disabledAggiungiFiguraRichiesta;
        let aggiungiJSX = <Fragment/>;
        if(!nascondi){
            aggiungiJSX = <Fragment>
                <div className={"row justify-content-end py-1"}>
                    <div className={"col-2"}>
                <span
                    id={"aggiungi"}
                    className={stile}
                    onClick={() => aggiungiFunc()}
                    style={{cursor: "pointer"}}
                >
                    <p style={{fontWeight: 500}}><Icon id={'it-plus-circle'} className={["icon", stile]}/> {text} </p>
                        </span>
                    </div>
                </div>
            </Fragment>;
        }
        return aggiungiJSX;
    }

    renderFormAmministratore = () => {
        let titolo = <div className={"row"}>
            {/* {SPAZIO_ICONA} */}
            <div className={"col"}>
                <h1>Inserisci dati della centrale Adi</h1>
            </div>
        </div>;

        return <Fragment>
            {titolo}
            <div className={"container-fluid pb-5"}>
                {this.renderListaCentrali()}
            </div>
        </Fragment>
    }

    renderFormReferenteDistrettuale = () => {
        let titolo = <div className={"row"}>
            {/* {SPAZIO_ICONA} */}
            <div className={"col"}>
                <h1>Inserisci dati della delega</h1>
            </div>
        </div>;

        return <Fragment>
            {titolo}
            <div className={"container-fluid pb-5"}>
                {this.renderDelegaPer()}
                {this.renderListaDeleghe()}
            </div>
        </Fragment>
    }

    renderFormDirettoreUO = () => {
        let settingsDirUO = _.find(SessioneUtente.getInstance().settings, s => s.profilo === enumUtente.profiliUtente.direttoreUnitaOperativa);
        // todo aggiungere molteplicità unita operativa
        let optionsUO = _.filter(this.state.listaUnitaOperativa, uo => settingsDirUO ? uo.value === settingsDirUO.unitaOperativa : true);
        let titolo = <div className={"row"}>
            <div className={"col"}>
                <h6>Inserisci dati della nomina automatica</h6>
            </div>
        </div>;
        let listaDisciplinePresenti = _.map(this.props.listaDeleghe, d => d.disciplina);
        let optionsDisciplina = _.filter(disciplinaSelect, d => !listaDisciplinePresenti.includes(d.value));
        return <Fragment>
            {titolo}
            <div className={"container-fluid pb-5"}>
                <FieldsRow colNumber={4}>
                    {/*ruolo*/}
                    <FieldsGroup fieldLabel={"Attiva nomina per ruolo"} fieldRequired={true}>
                        <Select
                            id={"selectRuoloDelega"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            styles={inputSelectStyles}
                            options={this.state.ruoliSpecialistaSelect}
                            value={_.find(this.state.ruoliSpecialistaSelect, ruolo => ruolo.value === this.state.delega?.ruolo) ?? null}
                            onChange={(elem) => this.handleAggiornaFormDirUO("ruolo", elem.value)}
                            isSearchable={false}
                            placeholder={"Seleziona ruolo"}
                            noOptionsMessage={() => "Errore: Ruolo non esistente"}
                            isDisabled={this.isDisabilitato("ruolo")}
                        />
                    </FieldsGroup>
                    {/*azioni*/}
                    <FieldsGroup fieldLabel={"Azioni nomina"}>
                        <Select
                            id={"selectAzioni"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            styles={inputSelectStyles}
                            options={AZIONI_SELECT}
                            value={_.find(AZIONI_SELECT, azione => _.isEqual(azione.value, this.state.delega?.azioni)) ?? null}
                            onChange={(elem) => {
                            }}
                            isSearchable={false}
                            isDisabled={true}
                            placeholder={"Seleziona azione"}
                            noOptionsMessage={() => "Nessun risultato trovato"}
                        />
                    </FieldsGroup>
                </FieldsRow>
                <FieldsRow colNumber={4}>
                    {/*unita operativa*/}
                    <FieldsGroup fieldLabel={"Unità operativa"} fieldRequired={true}>
                        <Select
                            id={"selectUnitaOperativa"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            styles={inputSelectStyles}
                            options={optionsUO}
                            value={_.find(this.state.listaUnitaOperativa, uo => uo.value === this.state.delega?.unitaOperativa) ?? null}
                            onChange={(elem) => this.handleAggiornaFormDirUO("unitaOperativa", elem.value)}
                            isSearchable={false}
                            placeholder={"Seleziona unità operativa"}
                            noOptionsMessage={() => "Nessuna unità operativa associata"}
                            isDisabled={this.state.delega?.ruolo == null || this.isDisabilitato("unitaOperativa")}
                        />
                    </FieldsGroup>
                    {/*disciplina*/}
                    <FieldsGroup fieldLabel={"Disciplina"}
                                 fieldRequired={[enumUtente.ruoli.specialista, enumUtente.ruoli.fisiatra].includes(this.state.delega?.ruolo)}>
                        <Select
                            id={"selectDisciplinaSpecialisti"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            styles={inputSelectStyles}
                            options={optionsDisciplina}
                            value={_.find(disciplinaSelect, disciplina => disciplina.value === this.state.delega?.disciplina) ?? null}
                            onChange={(elem) => this.handleAggiornaFormDirUO("disciplina", elem.value)}
                            isSearchable={false}
                            placeholder={"Seleziona disciplina"}
                            noOptionsMessage={() => "Errore: Disciplina non esistente"}
                            isDisabled={[enumUtente.ruoli.psicologo, enumUtente.ruoli.fisiatra].includes(this.state.delega?.ruolo) ||
                            this.state.delega?.unitaOperativa == null || this.isDisabilitato("disciplina")}
                        />
                    </FieldsGroup>
                    {/*professionista nominato*/}
                    <FieldsGroup fieldLabel={"Professionista nominato"} fieldRequired={true}>
                        <Select
                            id={"selectProfessionistaNominato"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            styles={inputSelectStyles}
                            options={this.state.listaProfessionisti}
                            value={_.find(this.state.listaProfessionisti, professionista => professionista.value === this.state.delega?.professionistaDelegato?.codiceFiscale) ?? null}
                            onChange={(elem) => {
                                this.handleAggiornaFormDirUO("professionistaDelegato",_.find(this.professionistiApms, p => p.codiceFiscale === elem.value))
                            }}
                            isSearchable={false}
                            isDisabled={(([enumUtente.ruoli.fisiatra, enumUtente.ruoli.psicologo].includes(this.state.delega?.ruolo) && this.state.delega?.unitaOperativa == null) || ([enumUtente.ruoli.specialista].includes(this.state.delega?.ruolo) && this.state.delega?.disciplina == null))
                            || this.isDisabilitato("professionistaDelegato")}
                            placeholder={"Seleziona professionista nominato"}
                            noOptionsMessage={() => "Nessun risultato trovato"}
                        />
                    </FieldsGroup>
                </FieldsRow>
                {this.renderDate(4, this.handleAggiornaFormDirUO)}
            </div>
        </Fragment>
    }

    renderDate = (colNumber, handleAggiornaForm) => {
        // se la data inizio è maggiore di data fine allora restituisce null altrimenti un messaggio
        let dataInizioAfterDataFine = this.getData1AfterData2(this.state.delega?.dataInizio, this.state.delega?.dataFine,
            "Data fine è precedente alla data inizio");
        // se la data inizio è minore di data odierna allora restituisce null altrimenti un messaggio
        let now = moment().format("DD/MM/YYYY");
        let dataInizioBeforeNow = this.getData1AfterData2(now, this.state.delega?.dataInizio,
            "Data inizio è precedente alla data odierna");
        return <FieldsRow colNumber={colNumber}>
            {/*data inizio*/}
            <FieldsGroup fieldLabel={"Data inizio"} fieldRequired={true}>
                <Calendar
                    id={"dataInizio"}
                    value={this.state.delega?.dataInizio}
                    field={"dataInizio"}
                    onChange={(event) => handleAggiornaForm("dataInizio", event.currentTarget.value)}
                    errorMessage={dataInizioBeforeNow}
                    error={!Utils.isStringEmptyOrNullOrUndefined(dataInizioBeforeNow)}
                    disabled={this.state.delega?.professionistaDelegato?.codiceFiscale == null}
                />
            </FieldsGroup>
            {/*data fine*/}
            <FieldsGroup fieldLabel={"Data fine"} fieldRequired={false}>
                <Calendar
                    id={"dataFine"}
                    value={this.state.delega?.dataFine}
                    field={"dataFine"}
                    onChange={(event) => handleAggiornaForm("dataFine", event.currentTarget.value)}
                    errorMessage={dataInizioAfterDataFine}
                    error={!Utils.isStringEmptyOrNullOrUndefined(dataInizioAfterDataFine)}
                    disabled={this.state.delega?.professionistaDelegato?.codiceFiscale == null}
                />
            </FieldsGroup>
        </FieldsRow>;
    }

    renderButtons = () => {
        let textConferma;
        if (this.props.name === enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI) {
            textConferma = "Conferma nomina"
        } else if (this.props.name === enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE) {
            textConferma = "Conferma delega"
        } else if (this.props.name === enumDelega.menu.CENSIMENTO_CENTRALE_ADI) {
            textConferma = "Conferma centrale ADI"
        }

        return (
            <ButtonsBoxStick
                parentClass="d-flex justify-content-between"
                btnList={[
                    {
                        id: "indietro",
                        text: "Indietro",
                        className: "btnWhiteOutlineBorderGreen",
                        onclickFunction: () => {
                            this.annulla();
                        },
                        disabled: false,
                        isvisible: true,
                    },
                    {
                        id: "confermaDelega",
                        text: textConferma,
                        className: "btnGreenOutline",
                        onclickFunction: () => {
                            this.confermaDelega();
                        },
                        disabled: this.bottoneDisabilitato(),
                        isvisible: true,
                    }
                ]}
            />
        );
    }

    renderForm = () => {
        if(this.props.name == enumDelega.menu.CENSIMENTO_CENTRALE_ADI) {
            return this.renderFormAmministratore()
        } else if(this.props.name == enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE) {
            return this.renderFormReferenteDistrettuale()
        } else if(this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
            return this.renderFormDirettoreUO()
        } else {
            throw new Error("Non è possibile renderizzare il componente per il profilo loggato");
        }
    }

    render() {
        return (
            <Fragment>
                <Container fluid className={"px-5 pt-5 position-relative"}>
                    {/*riportati stili accordion per rispettare mockup*/}
                    <div className={stiliAccordion.sezioneAccordionBody.concat(" collapse-body mb-5")}>
                        {this.renderForm()}
                    </div>
                </Container>
                {this.renderButtons()}
            </Fragment>
        );
    }
}

InserisciDelega.propTypes = propTypes;


