import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import Utils, {isCalendarIconDisabled} from "../../../utils/Utils";
import Statistica from "../../statistica/Statistica";
import {
    FieldsRow,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import enumQuestionario from "../../../enum/enumQuestionario";
import moment from "moment";
import {isArray} from "lodash";
import {SessioneUtente} from "web-client-archetype";
import {
    defaultAltro,
    defaultFarmaco, defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    datiConsumoDiAlcoolForm: PropTypes.any,
    sessoPaziente: PropTypes.string,
    onChangeDatiConsumoAlcool: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    mascheraModificabilita: PropTypes.any,
    richiesta: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    salvaBozza: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class ConsumoDiAlcool extends AccordionSecondLevel {

    state = {
        datiConsumoAlcool: _.cloneDeep(this.props.datiConsumoDiAlcoolForm)
    }

    condRilevataForzeRead;

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiConsumoDiAlcoolForm, this.props.datiConsumoDiAlcoolForm))
            this.forceUpdate(() => this.inizializzaForm());
    }

    inizializzaForm = () => {
        let datiConsumoAlcool = _.cloneDeep(this.props.datiConsumoDiAlcoolForm);
        if (!Utils.isStringEmptyOrNullOrUndefined(datiConsumoAlcool.dataValutazioneAuditC))
            datiConsumoAlcool.dataValutazioneAuditC = Utils.transformTimeStampForFrontend(datiConsumoAlcool.dataValutazioneAuditC);
        else
            datiConsumoAlcool.dataValutazioneAuditC = "";
        if (!Utils.isStringEmptyOrNullOrUndefined(datiConsumoAlcool.dataValutazioneAudit))
            datiConsumoAlcool.dataValutazioneAudit = Utils.transformTimeStampForFrontend(datiConsumoAlcool.dataValutazioneAudit);
        else
            datiConsumoAlcool.dataValutazioneAudit = "";

        this.setState({datiConsumoAlcool: datiConsumoAlcool}, () => {
            isCalendarIconDisabled("idDataValutazioneTestAudit");
            this.countCampiObbligatoriRimanentiAndValida();
        });
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiConsumoAlcool}) => {
            if (field === "condizioneRilevata" || field === "consumoDiAlcolici") {
                datiConsumoAlcool[field] = elem;
                if (field === "consumoDiAlcolici" && this.isUsoDiAlcool())
                    this.resetDatiConsumoAlcool(datiConsumoAlcool, ["consumoDiAlcolici"]);
                else if (field === "condizioneRilevata" && elem === false)
                    this.resetDatiConsumoAlcool(datiConsumoAlcool, ["consumoDiAlcolici", "nBicchieriGiorno", "nBicchieriSettimana", "condizioneRilevata"]);
            } else {
                datiConsumoAlcool[field] = elem;
            }
            return {datiConsumoAlcool}
        }, () => {
            let datiConsumoAlcool = this.state.datiConsumoAlcool
            this.props.onChangeDatiConsumoAlcool("alcool", datiConsumoAlcool);
            this.countCampiObbligatoriRimanentiAndValida();
        });
    }

    countCampiObbligatoriRimanentiAndValida = () => {
        let datiConsumoAlcool = _.cloneDeep(this.state.datiConsumoAlcool);
        let campiObbligatoriRimanenti = 0;

        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiConsumoAlcool?.propostaAttivitaSuggerimenti)

        this.props.onChangeRequiredFieldsNumber("sottosezioneConsumoDiAlcool", campiObbligatoriRimanenti);
    }


    resetDatiConsumoAlcool = (datiConsumoAlcool, fieldsToSkip) => {
        Object.keys(datiConsumoAlcool).forEach(key => {
            if (!fieldsToSkip.includes(key))
                if (key === "dataValutazioneAuditC" || key === "dataValutazioneAudit")
                    datiConsumoAlcool[key] = "";
                else
                    datiConsumoAlcool[key] = null;
        });
    }

    isUsoDiAlcool = () => {
        return !Utils.isObjectNull(this.state.datiConsumoAlcool.consumoDiAlcolici)
            && this.state.datiConsumoAlcool.consumoDiAlcolici.value !== oggettiUtili.consumoDiAlcolici[0].value;
    }

    showTestAudit = () => {
        let questionarioAUDITC = this.state.datiConsumoAlcool?.AUDITC ?? null;
        return (!Utils.isObjectNull(questionarioAUDITC) && questionarioAUDITC.punteggio >= 5 && this.props.sessoPaziente === "M")
            || (!Utils.isObjectNull(questionarioAUDITC) && questionarioAUDITC.punteggio >= 4 && this.props.sessoPaziente === "F");
    }

    renderBodyConsumoDiAlcool = () => {
        if(this.state.datiConsumoAlcool.propostaAttivitaSuggerimenti && isArray(this.state.datiConsumoAlcool.propostaAttivitaSuggerimenti)) {
            this.condRilevataForzeRead = (this.context.forceReadOnly || this.props.forceReadOnly || !!(this.state.datiConsumoAlcool.propostaAttivitaSuggerimenti.filter(p => p?.codiceFiscale && p?.codiceFiscale != SessioneUtente.getInstance().idUtente || p?.propostaAttivitaSuggerimenti?.codiceFiscale && p?.propostaAttivitaSuggerimenti?.codiceFiscale != SessioneUtente.getInstance().idUtente)?.length))
        }
        return <>
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'CONS_ALCOL.ALCOOL.DETERMINANTIDISALUTE'}
                    pageState={this.props.pageState}
                    id={"selectConsumoDiAlcolici"}
                    className={"selectConsumoDiAlcolici"}
                    options={oggettiUtili.consumoDiAlcolici}
                    value={this.state.datiConsumoAlcool.consumoDiAlcolici}
                    onChange={(elem) => this.handleAggiornaForm("consumoDiAlcolici", elem)}
                    isSearchable={false}
                    fieldLabel={'Consumo di alcolici'}
                    field={"consumoDiAlcolici"}
                    handleOnlyValue={true}
                    forceReadOnly={this.props.forceReadOnly
                        || this.props.mascheraModificabilita?.determinantiDiSalute?.alcool?.consumoDiAlcolici}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                {this.state.datiConsumoAlcool.consumoDiAlcolici === "02" &&
                <RoleBasedInput
                    fieldId={'CONS_ALCOL_N_GIORNO.ALCOOL.DETERMINANTIDISALUTE'}
                    pageState={this.props.pageState}
                    value={this.state.datiConsumoAlcool.nBicchieriGiorno}
                    placeholder="Inserisci frequenza"
                    type={"number"}
                    min={1}
                    field={"nBicchieriGiorno"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    id={"inputAltraProposta"}
                    fieldLabel={"N° Bicchieri/giorno"}
                    forceReadOnly={this.props.forceReadOnly
                        || this.props.mascheraModificabilita?.determinantiDiSalute?.alcool?.nBicchieriGiorno}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                }
                {this.state.datiConsumoAlcool.consumoDiAlcolici === "03" &&
                <RoleBasedInput
                    fieldId={'CONS_ALCOL_N_SETTIMANA.ALCOOL.DETERMINANTIDISALUTE'}
                    pageState={this.props.pageState}
                    value={this.state.datiConsumoAlcool.nBicchieriSettimana}
                    placeholder="Inserisci frequenza"
                    type={"number"}
                    min={1}
                    field={"nBicchieriSettimana"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    id={"inputAltraProposta"}
                    fieldLabel={"N° Bicchieri/settimana"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                }
            </FieldsRow>

            {this.isUsoDiAlcool() &&
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'COND_RIL.ALCOOL.DETERMINANTIDISALUTE'}
                        pageState={this.props.pageState}
                        id={"selectIsCondizioneRilevataConsumoAlcool"}
                        options={oggettiUtili.rispostaCondizioneRilevata}
                        value={this.state.datiConsumoAlcool.condizioneRilevata}
                        onChange={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Condizione rilevata non esistente"}
                        fieldLabel={'La condizione rilevata'}
                        field={"condizioneRilevata"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly || this.condRilevataForzeRead}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>

                {this.state.datiConsumoAlcool.condizioneRilevata &&
                <>
                    <Statistica
                        fieldId={"PUNT_AUDIT_C.ALCOOL.DETERMINANTIDISALUTE"}
                        pageState={this.props.pageState}
                        nomeLink={"Compila Test AUDIT C"}
                        nomePunteggio={"Punteggio Alcohol Disorders Identification Test (AUDIT-C)"}
                        questionario={this.state.datiConsumoAlcool.AUDITC}
                        profileName={'Livello di rischio'}
                        profileFieldId={'LIV_RISCHIO_AUDITC.ALCOOL.DETERMINANTIDISALUTE'}
                        onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.AUDITC, questionario)}
                        richiesta={this.props.richiesta}
                        pathRichiestaQuestionario={"determinantiDiSalute.alcool.AUDITC"}
                        tipoQuestionario={enumQuestionario.tipoQuestionario.AUDITC}
                        profileRequired={false}
                        sessoPaziente={this.props.sessoPaziente}
                        salvaBozza={this.props.salvaBozza}
                        parentJsonPathSubObject={'auditc'}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <FieldsRow>
                        <RoleBasedCalendar
                            fieldId={"DATA_VAL_AUDITC.ALCOOL.DETERMINANTIDISALUTE"}
                            pageState={this.props.pageState}
                            id={"consumoAlcoolDataValutazioneTestAuditc"}
                            placeholder={"gg/mm/aaaa"}
                            onChange={(elem) => this.handleAggiornaForm("dataValutazioneAuditC", elem.target.value)}
                            value={this.state.datiConsumoAlcool.dataValutazioneAuditC}
                            error={moment(this.state.datiConsumoAlcool?.dataValutazioneAuditC, "DD/MM/YYYY") > new Date()}
                            errorMessage={"Data non valida"}
                            fieldLabel={'Data valutazione'}
                            field={"dataValutazioneAuditC"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </FieldsRow>

                    {this.showTestAudit() &&
                    <>
                        <Statistica
                            fieldId={"PUNT_AUDIT.ALCOOL.DETERMINANTIDISALUTE"}
                            pageState={this.props.pageState}
                            nomeLink={"Compila Test AUDIT"}
                            nomePunteggio={"Punteggio Alcohol Use Disorders Identification Test (AUDIT)"}
                            questionario={this.state.datiConsumoAlcool.AUDIT}
                            profileName={'Livello di rischio di sviluppare alcolismo'}
                            profileFieldId={'LIV_RISCHIO_AUDIT.ALCOOL.DETERMINANTIDISALUTE'}
                            onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.AUDIT, questionario)}
                            richiesta={this.props.richiesta}
                            pathRichiestaQuestionario={"determinantiDiSalute.alcool.AUDIT"}
                            tipoQuestionario={enumQuestionario.tipoQuestionario.AUDIT}
                            profileRequired={false}
                            salvaBozza={this.props.salvaBozza}
                            parentJsonPathSubObject={'audit'}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        <FieldsRow>
                            <RoleBasedCalendar
                                fieldId={"DATA_VAL_AUDIT.ALCOOL.DETERMINANTIDISALUTE"}
                                pageState={this.props.pageState}
                                id={"consumoAlcoolDataValutazioneTestAudit"}
                                placeholder={"gg/mm/aaaa"}
                                onChange={(elem) => this.handleAggiornaForm("dataValutazioneAudit", elem.target.value)}
                                value={this.state.datiConsumoAlcool.dataValutazioneAudit}
                                error={moment(this.state.datiConsumoAlcool?.dataValutazioneAudit, "DD/MM/YYYY") > new Date()}
                                errorMessage={"Data non valida"}
                                fieldLabel={'Data valutazione'}
                                disabled={Utils.isStringEmptyOrNullOrUndefined(this.state.datiConsumoAlcool?.AUDIT?.punteggio)}
                                field={"dataValutazioneAudit"}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                        </FieldsRow>
                    </>}

                    <ValutazioneSection
                        pageState={this.props.pageState}
                        ambito={"CONSUMO DI ALCOOL"}
                        parentFieldId={'ALCOOL.DETERMINANTIDISALUTE'}
                        showDataValutazione={false}
                        onChangeDataValutazione={() => undefined}
                        onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                        valueStatoDopoValutazione={this.state.datiConsumoAlcool.statoDopoValutazione}
                        onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                        valueDaConsiderare={this.state.datiConsumoAlcool.daConsiderare}
                        showSuggerimenti={true}
                        onChangeSuggerimenti={this.handleAggiornaForm}
                        valueSuggerimenti={this.state.datiConsumoAlcool.propostaAttivitaSuggerimenti}
                        showAutonomia={true}
                        onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                        valueAutonomiaPaziente={this.state.datiConsumoAlcool.autonomiaGestionePaziente}
                        onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                        valueAutonomiaCaregiver={this.state.datiConsumoAlcool.autonomiaGestioneCaregiver}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </>}
            </>}
        </>
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"alcool"}
                title={"Consumo di alcool"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["determinantiDiSalute.alcool.propostaAttivitaSuggerimenti"],
                    [defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodyConsumoDiAlcool()}</AccordionSezioneRichiesta>
        );
    }
}

ConsumoDiAlcool.propTypes = propTypes;
