import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import Utils from "../../../utils/Utils";
import {v4 as uuid} from "uuid";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import SessionRequest from "../../../utils/SessionRequest";
import {
    defaultAltro,
    defaultFarmaco, defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    datiSensorioUditoForm: PropTypes.any,
    onChangeDatiSensorioUditoForm: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    onHide: PropTypes.func,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}
const defaultAlterazione = {
    id: null,
    tipoAlterazione: null,
}

export default class SensorioUdito extends AccordionSecondLevel {

    state = {
        datiSensorioUditoForm: _.cloneDeep(this.props.datiSensorioUditoForm)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiSensorioUditoForm, this.props.datiSensorioUditoForm))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiSensorioUditoForm = _.cloneDeep(this.props.datiSensorioUditoForm);
        if (datiSensorioUditoForm.dispositiviPresenti === null) datiSensorioUditoForm.dispositiviPresenti = [];
        this.setState({datiSensorioUditoForm}, this.validazioneDati);
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({datiSensorioUditoForm}) => {
            if (index == null && subField == null) {
                datiSensorioUditoForm[field] = elem;
            } else {
                datiSensorioUditoForm[field][index][subField] = elem;
            }

            if (!this.showElements(datiSensorioUditoForm.esitoValutazioneUditoOrecchio)) {
                datiSensorioUditoForm.alterazione = [];
                datiSensorioUditoForm.statoDopoValutazione = null;
            }
            return {datiSensorioUditoForm};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        const datiSensorioUditoForm = _.cloneDeep(this.state.datiSensorioUditoForm);
        this.props.onChangeDatiSensorioUditoForm("datiSensorioUdito", datiSensorioUditoForm);
        let campiObbligatori = 0;
        campiObbligatori += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiSensorioUditoForm?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneSensorioUdito", campiObbligatori);
    }

    handleArrayDispositiviChange = (array, field) => {
        let datiSensorioUditoForm = _.cloneDeep(this.state.datiSensorioUditoForm);
        datiSensorioUditoForm[field] = array;
        this.setState({datiSensorioUditoForm}, () => {
            this.validazioneDati();
            this.forceUpdate();
        });
    }

    showElements(esitoValutazioneUditoOrecchio) {
        return esitoValutazioneUditoOrecchio && esitoValutazioneUditoOrecchio !== "01";
    }

    /* Alterazione */

    addNewAlterazione = () => {
        let datiSensorioUditoForm = _.cloneDeep(this.state.datiSensorioUditoForm);
        let type = _.cloneDeep(defaultAlterazione);
        type.id = uuid();
        if (datiSensorioUditoForm.alterazione == null) datiSensorioUditoForm.alterazione = [];
        datiSensorioUditoForm.alterazione.push(type);
        this.setState({datiSensorioUditoForm}, this.validazioneDati);
    }

    removeAlterazione = (index) => {
        let datiSensorioUditoForm = _.cloneDeep(this.state.datiSensorioUditoForm);
        datiSensorioUditoForm.alterazione.splice(index, 1);
        this.setState({datiSensorioUditoForm}, this.validazioneDati);
    }

    isAlterazioneValida = (alterazione) => {
        return !Utils.isObjectNull(alterazione) && !Utils.isStringEmptyOrNullOrUndefined(alterazione?.tipoAlterazione);
    }

    renderAlterazione = (alterazione, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'M_ALT.SENSORIOUDITO.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={'I_ALT.UDITO.SENSORIO'}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm("alterazione", elem.target.value, index, "tipoAlterazione")}
                    value={alterazione.tipoAlterazione}
                    placeholder={"Tipo di alterazione"}
                    disableDoubleInput={this.props.disableDoubleInput}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    fieldLabel={'Alterazione'}
                    field={'tipoAlterazione'}
                />
            </FieldsRow>
        )
    }

    renderBody = () => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={"M_ESITO_VAL_UD_OREC.SENSORIOUDITO.ANAMNESIFISIOLOGICA"}
                        infermiereFieldId={'I_ESITO_VAL_UD_OREC.UDITO.SENSORIO'}
                        pageState={this.props.pageState}
                        id={"esitoValutazioneUditoOrecchio"}
                        options={OggettiUtili.esitoValutazioneUditoOrecchio}
                        value={this.state.datiSensorioUditoForm.esitoValutazioneUditoOrecchio}
                        onChange={(elem) => this.handleAggiornaForm("esitoValutazioneUditoOrecchio", elem)}
                        isSearchable={false}
                        fieldLabel={'Esito valutazione udito - orecchio'}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"esitoValutazioneUditoOrecchio"}
                        handleOnlyValue={true}
                    />
                </FieldsRow>

                {this.showElements(this.state.datiSensorioUditoForm.esitoValutazioneUditoOrecchio) &&
                    <SectionList
                        title={'Alterazione'}
                        data={this.state.datiSensorioUditoForm.alterazione}
                        renderSection={this.renderAlterazione}
                        addNewSectionCallback={this.addNewAlterazione}
                        removeSectionCallback={this.removeAlterazione}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        pageState={this.props.pageState}
                        keyFieldId={'M_ALT.SENSORIOUDITO.ANAMNESIFISIOLOGICA'}
                        infermiereKeyFieldId={'I_ALT.UDITO.SENSORIO'}
                        addButtonVisibilityHandler={(this.state.datiSensorioUditoForm.alterazione?.length === 0 || this.state.datiSensorioUditoForm.alterazione?.every(this.isAlterazioneValida))}
                        field={'alterazione'}
                        parentJsonPath={"anamnesiFisiologica.datiSensorioUdito.alterazione"}
                    />}

                <DispositiviPresenti
                    dispositiviPresenti={this.state.datiSensorioUditoForm.dispositiviPresenti}
                    handleArrayChange={this.handleArrayDispositiviChange}
                    pageState={this.props.pageState}
                    fieldId={'M_DISP.SENSORIOUDITO.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={'I_DISP.UDITO.SENSORIO'}
                    field={"dispositiviPresenti"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    parentJsonPath={"anamnesiFisiologica.datiSensorioUdito.dispositiviPresenti"}
                />

                <ValutazioneSection
                    ambito={"SENSORIO - UDITO"}
                    pageState={this.props.pageState}
                    parentFieldId={'SENSORIOUDITO.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'UDITO.SENSORIO'}
                    appendRolePrefix={true}
                    valueStatoDopoValutazione={this.state.datiSensorioUditoForm.statoDopoValutazione}
                    onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                    valueDataValutazione={this.state.datiSensorioUditoForm.dataValutazione}
                    onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                    valueDaConsiderare={this.state.datiSensorioUditoForm.daConsiderare}
                    onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                    showSuggerimenti={true}
                    valueSuggerimenti={this.state.datiSensorioUditoForm.propostaAttivitaSuggerimenti}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={this.state.datiSensorioUditoForm.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={this.state.datiSensorioUditoForm.autonomiaGestioneCaregiver}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    openCondition={this.showElements(this.state.datiSensorioUditoForm.esitoValutazioneUditoOrecchio)}
                />
            </>
        );
    }

    onHide = () => {
        const datiSensorioUdito = _.cloneDeep(SessionRequest.getInitObject('anamnesiFisiologica.datiSensorioUdito'));
        this.setState({datiSensorioUditoForm: datiSensorioUdito},
            () => {
                this.props.onChangeDatiSensorioUditoForm("datiSensorioUdito", datiSensorioUdito);
                this.props.onHide();
            });
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"datiSensorioUdito"}
            title={"Sensorio - Udito"}
            required={false}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            onHide={this.onHide}
            isOptional={this.props.isOptional}
            fieldsOrderMap={Utils.generateMapPathsObjects(
                ["anamnesiFisiologica.datiSensorioUdito.alterazione", "anamnesiFisiologica.datiSensorioUdito.dispositiviPresenti", "anamnesiFisiologica.datiSensorioUdito.propostaAttivitaSuggerimenti"],
                [defaultAlterazione, defaultDispositiviPresenti, defaultProposteAttivitaSuggerimenti]
            )}
            accordionReadOnly={this.props.accordionReadOnly}
            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
        >{this.renderBody()}</AccordionSezioneRichiesta>
    }
}
SensorioUdito.propTypes = propTypes;

SensorioUdito.defaultProps = {
    isOptional: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}
