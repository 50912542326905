import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import Box from "../../generalComponents/box/Box";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    neurologicoEstatoDiVigilanza: PropTypes.any,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultAlterazione = {
    id: null,
    tipoAlterazione: null,
    altraAlterazione: null
}

const nomeAccordion = "NEUROLOGICO_STATO_DI_VIGILANZA";

export default class NeurologicoEstatoDiVigilanza extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.neurologicoEstatoDiVigilanza)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.neurologicoEstatoDiVigilanza, this.props.neurologicoEstatoDiVigilanza))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.neurologicoEstatoDiVigilanza);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({dati}) => {
            if (index === null && subField === null) {
                dati[field] = elem;
            } else {
                if ((field === "tipoDiAlterazioneStatoVigilanza" || field === "tipoDiAlterazionePupille")
                    && subField === "tipoAlterazione" && !_.isEqual(elem, dati[field][index][subField])) {
                    dati[field][index].altraAlterazione = null;
                }
                dati[field][index][subField] = elem;
            }

            /* StatoVigilanza */
            if (!dati.presenzaDiAlterazioniStatoVigilanza) {
                dati.tipoDiAlterazioneStatoVigilanza = [];
            }

            /* Pupille */
            if (!dati.presenzaDiAlterazioniPupille) {
                dati.tipoDiAlterazionePupille = [];
            }

            /* Cerebellari */
            if (dati.proveCerebellari !== "02") {
                dati.tipoDiAlterazioneCerebellari = [];
            }

            /* NerviCranici */
            if (!dati.presenzaDiAlterazioniNerviCranici) {
                dati.tipoDiAlterazioneNerviCranici = [];
            }

            /* testDiMingazziniI */
            if (dati.testDiMingazziniI !== "02" && dati.testDiMingazziniI !== "03") {
                dati.noteMingazziniI = null;
            }

            /* testDiMingazziniII */
            if (dati.testDiMingazziniII !== "02" && dati.testDiMingazziniII !== "03") {
                dati.noteMingazziniII = null;
            }

            /* DeficitSensoriali */
            if (!dati.deficitSensoriali) {
                dati.tipoDiAlterazioneSensoriali = [];
            }

            /* ROT */
            if (!dati.presenzaDiAlterazioniROT) {
                dati.tipoDiAlterazioneROT = [];
            }

            /* StazioneEretta */
            if (!dati.stazioneEretta) {
                dati.romberg = null;
            }

            /* Marcia */
            if (!dati.presenzaDiAlterazioniMarcia) {
                dati.tipoDiAlterazioneMarcia = [];
            }

            /* FunzioniSuperiori */
            if (!dati.presenzaDiAlterazioniFunzioniSuperiori) {
                dati.tipoDiAlterazioneFunzioniSuperiori = [];
                dati.noteFunzioniSuperiori = null;
            }

            /* CondizioneRilevata */
            if (!this.showCondizioneRilevata(dati)) {
                dati.condizioneRilevata = null;
            }
            return {dati};
        }, this.validazioneDati);

    }

    validazioneDati = () => {
        const dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("neurologicoEstatoDiVigilanza", dati);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneNaso", campiObbligatoriRimanenti);
    }

    showCondizioneRilevata(dati) {
        return dati.presenzaDiAlterazioniStatoVigilanza || dati.presenzaDiAlterazioniPupille ||
            dati.proveCerebellari === "02" || dati.presenzaDiAlterazioniNerviCranici ||
            dati.deficitSensoriali || dati.presenzaDiAlterazioniROT || dati.presenzaDiAlterazioniMarcia ||
            dati.presenzaDiAlterazioniFunzioniSuperiori;
    }

    /* Alterazione */

    addAlterazione = (fieldList) => {
        let dati = _.cloneDeep(this.state.dati);
        let alterazione = _.cloneDeep(defaultAlterazione);
        alterazione.id = uuid();
        dati[fieldList].push(alterazione);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    removeAlterazione = (index, fieldList) => {
        let dati = _.cloneDeep(this.state.dati);
        dati[fieldList].splice(index, 1);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    renderAlterazione = (alterazione, index, fieldList, fieldId) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={fieldId}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm(fieldList, elem.target.value, index, "tipoAlterazione")}
                    value={alterazione.tipoAlterazione}
                    placeholder={"Tipo di alterazione"}
                    disableDoubleInput={this.props.disableDoubleInput}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    fieldLabel={'Alterazione'}
                    field={'tipoAlterazione'}
                />
            </FieldsRow>
        )
    }

    renderAlterazioneSede = (alterazione, index, fieldList, alterazioneFieldId, sedeFieldId) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={alterazioneFieldId}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm(fieldList, elem.target.value, index, "tipoAlterazione")}
                    value={alterazione.tipoAlterazione}
                    placeholder={"Tipo di alterazione"}
                    disableDoubleInput={this.props.disableDoubleInput}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    fieldLabel={'Alterazione'}
                    field={'tipoAlterazione'}
                />
                <RoleBasedInput
                    fieldId={sedeFieldId}
                    pageState={this.props.pageState}
                    value={alterazione.sede}
                    placeholder={"Sede"}
                    onChange={(field, elem) => this.handleAggiornaForm(fieldList, elem.target.value, index, "sede")}
                    id={"basedInputSede" + index}
                    fieldLabel={"Sede"}
                    disableDoubleInput={this.props.disableDoubleInput}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    field={'sede'}
                />
            </FieldsRow>
        )
    }

    /* Alterazione StatoVigilanza */

    renderAlterazioneStatoVigilanza = (alterazione, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'TIPO_ALT_VIGIL.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    id={"inputTipoAlterazioneStatoVigilanza"}
                    className={"tipoAlterazione"}
                    options={OggettiUtili.tipoAlterazioneVigilanza}
                    value={alterazione.tipoAlterazione}
                    onChange={(elem) => this.handleAggiornaForm("tipoDiAlterazioneStatoVigilanza", elem, index, "tipoAlterazione")}
                    isSearchable={false}
                    fieldLabel={"Tipo di alterazione"}
                    handleOnlyValue={true}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    field={"tipoAlterazione"}
                />
                {alterazione.tipoAlterazione === "06" &&
                    <RoleBasedInput
                        /* TODO: Manca il campo fieldId corrispondente */
                        id={"inputAltraAlterazioneStatoVigilanza"}
                        fieldId={'TIPO_ALT_VIGIL.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        value={alterazione.altraAlterazione ?? ""}
                        placeholder={"Inserisci altra alterazione"}
                        field={"altraAlterazione"}
                        onChange={(field, elem) => this.handleAggiornaForm("tipoDiAlterazioneStatoVigilanza", elem.target.value, index, field)}
                        fieldLabel={'Altro'}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />}
            </FieldsRow>
        )
    }

    /* Alterazione Pupille */

    renderAlterazionePupille = (alterazione, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'TIPO_ALT_PUPILLE.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    id={"selectTipoDiAlterazionePupille" + index}
                    className={"tipoDiAlterazionePupille"}
                    options={OggettiUtili.tipoAlterazionePupille}
                    value={alterazione.tipoAlterazione}
                    onChange={(elem) => this.handleAggiornaForm("tipoDiAlterazionePupille", elem, index, "tipoAlterazione")}
                    isSearchable={false}
                    fieldLabel={"Tipo di alterazione"}
                    handleOnlyValue={true}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    field={"tipoAlterazione"}
                />
                {alterazione.tipoAlterazione === "03" &&
                    <RoleBasedInput
                        /* TODO: Manca il campo fieldId corrispondente */
                        id={"inputAltraAlterazionePupille"}
                        fieldId={'TIPO_ALT_PUPILLE.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        value={alterazione.altraAlterazione ?? ""}
                        placeholder={"Inserisci altra alterazione"}
                        field={"altraAlterazione"}
                        onChange={(field, elem) => this.handleAggiornaForm("tipoDiAlterazionePupille", elem.target.value, index, field)}
                        fieldLabel={'Altro'}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />}
                <RoleBasedSelect
                    fieldId={'SEDE_ALT_PUPILLE.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    id={"selectSede" + index}
                    options={OggettiUtili.sede}
                    value={alterazione.sede}
                    onChange={(elem) => this.handleAggiornaForm("tipoDiAlterazionePupille", elem, index, "sede")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Sede non esistente"}
                    fieldLabel={"Sede"}
                    handleOnlyValue={true}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    field={"sede"}
                />
            </FieldsRow>
        )
    }

    /* Dispositivi */

    handleDispositiviArrayChange = (array, field) => {
        let dati = _.cloneDeep(this.state.dati);
        dati[field] = array;
        this.setState({dati: dati}, () => {
            this.validazioneDati();
            this.forceUpdate();
        });
    }

    renderBodySezione = () => {
        const dati = this.state.dati;

        return (
            <>
                <Box key={"boxAlterazioniStatoVigilanza" + nomeAccordion} titleBox={"Stato di vigilanza"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'PRES_ALT_VIGIL.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"selectPresenzaDiAlterazioniStatoVigilanza"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.presenzaDiAlterazioniStatoVigilanza}
                            onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioniStatoVigilanza", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                            fieldLabel={"Presenza di alterazioni"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            field={"presenzaDiAlterazioniStatoVigilanza"}
                        />
                    </FieldsRow>

                    {dati.presenzaDiAlterazioniStatoVigilanza &&
                        <SectionList
                            title={'Alterazione stato di vigilanza'}
                            data={dati.tipoDiAlterazioneStatoVigilanza}
                            renderSection={this.renderAlterazioneStatoVigilanza}
                            addNewSectionCallback={() => this.addAlterazione("tipoDiAlterazioneStatoVigilanza")}
                            removeSectionCallback={(i) => this.removeAlterazione(i, "tipoDiAlterazioneStatoVigilanza")}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            pageState={this.props.pageState}
                            keyFieldId={'TIPO_ALT_VIGIL.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            field={'tipoDiAlterazioneStatoVigilanza'}
                            parentJsonPath={"esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneStatoVigilanza"}
                        />}
                </Box>

                <Box key={"boxAlterazioniAlterazioniPupille" + nomeAccordion} titleBox={"Pupille"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'PRES_ALT_PUPILLE.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"selectPresenzaDiAlterazioni"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.presenzaDiAlterazioniPupille}
                            onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioniPupille", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                            fieldLabel={"Presenza di alterazioni pupille"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            field={"presenzaDiAlterazioniPupille"}
                        />
                    </FieldsRow>

                    {dati.presenzaDiAlterazioniPupille &&
                        <SectionList
                            title={'Alterazione pupille'}
                            data={dati.tipoDiAlterazionePupille}
                            renderSection={this.renderAlterazionePupille}
                            addNewSectionCallback={() => this.addAlterazione("tipoDiAlterazionePupille")}
                            removeSectionCallback={(i) => this.removeAlterazione(i, "tipoDiAlterazionePupille")}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            pageState={this.props.pageState}
                            keyFieldId={'TIPO_ALT_VIGIL.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            field={'tipoDiAlterazionePupille'}
                            parentJsonPath={"esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazionePupille"}
                        />}

                    <DispositiviPresenti
                        handleArrayChange={this.handleDispositiviArrayChange}
                        dispositiviPresenti={dati.dispositiviPresentiPupille}
                        pageState={this.props.pageState}
                        field={"dispositiviPresentiPupille"}
                        fieldId={"DISP.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        parentJsonPath={"esameObiettivo.neurologicoEstatoDiVigilanza.dispositiviPresentiPupille"}
                    />
                </Box>

                <Box key={"boxProveCerebellari" + nomeAccordion} titleBox={"Prove cerebellari"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'PROVE_CEREBELL.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"proveCerebellari"}
                            className={"proveCerebellari"}
                            options={OggettiUtili.proveCerebellari}
                            value={dati.proveCerebellari}
                            onChange={(elem) => this.handleAggiornaForm("proveCerebellari", elem)}
                            isSearchable={false}
                            fieldLabel={"Prove cerebellari"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            field={"proveCerebellari"}
                        />
                    </FieldsRow>

                    {dati.proveCerebellari === "02" &&
                        <SectionList
                            title={'Alterazione cerebellare'}
                            data={dati.tipoDiAlterazioneCerebellari}
                            renderSection={(e, i) => this.renderAlterazione(e, i, "tipoDiAlterazioneCerebellari", "TIPO_ALT_PROVE_CEREB.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO")}
                            addNewSectionCallback={() => this.addAlterazione("tipoDiAlterazioneCerebellari")}
                            removeSectionCallback={(i) => this.removeAlterazione(i, "tipoDiAlterazioneCerebellari")}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            pageState={this.props.pageState}
                            keyFieldId={'TIPO_ALT_PROVE_CEREB.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            field={'tipoDiAlterazioneCerebellari'}
                            addButtonVisibilityHandler={dati.tipoDiAlterazioneCerebellari?.every(t => !!t.tipoAlterazione)}
                            parentJsonPath={"esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneCerebellari"}
                        />}
                </Box>

                <Box key={"boxNerviCranici" + nomeAccordion} titleBox={"Nervi cranici"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'PRES_ALT_NERVI_CRANICI.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"selectPresenzaDiAlterazioniNerviCranici"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.presenzaDiAlterazioniNerviCranici}
                            onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioniNerviCranici", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                            fieldLabel={"Presenza di alterazioni nervi cranici"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            field={"presenzaDiAlterazioniNerviCranici"}
                        />
                    </FieldsRow>

                    {dati.presenzaDiAlterazioniNerviCranici &&
                        <SectionList
                            title={'Alterazione nervi cranici'}
                            data={dati.tipoDiAlterazioneNerviCranici}
                            renderSection={(e, i) => this.renderAlterazioneSede(e, i, "tipoDiAlterazioneNerviCranici", "TIPO_ALT_NERVI_CRANICI.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO", "SEDE_ALT_NERVI_CRANICI.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO")}
                            addNewSectionCallback={() => this.addAlterazione("tipoDiAlterazioneNerviCranici")}
                            removeSectionCallback={(i) => this.removeAlterazione(i, "tipoDiAlterazioneNerviCranici")}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            pageState={this.props.pageState}
                            keyFieldId={'TIPO_ALT_NERVI_CRANICI.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            field={'tipoDiAlterazioneNerviCranici'}
                            addButtonVisibilityHandler={dati.tipoDiAlterazioneNerviCranici?.every(t => !!t.tipoAlterazione)}
                            parentJsonPath={"esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneNerviCranici"}
                        />}
                </Box>

                <Box key={"boxDeficitSensoriali" + nomeAccordion} titleBox={"Deficit Sensoriali"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'DEFICIT_SENS.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"selectDeficitSensoriali"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.deficitSensoriali}
                            onChange={(elem) => this.handleAggiornaForm("deficitSensoriali", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Deficit sensoriali non esistente"}
                            fieldLabel={"Deficit sensoriali"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            field={"deficitSensoriali"}
                        />
                    </FieldsRow>

                    {dati.deficitSensoriali &&
                        <SectionList
                            title={'Alterazione deficit sensoriale'}
                            data={dati.tipoDiAlterazioneSensoriali}
                            renderSection={(e, i) => this.renderAlterazioneSede(e, i, "tipoDiAlterazioneSensoriali", "TIPO_ALT_DEFICIT_SENS.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO", "SEDE_DEFICIT_SENS.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO")}
                            addNewSectionCallback={() => this.addAlterazione("tipoDiAlterazioneSensoriali")}
                            removeSectionCallback={(i) => this.removeAlterazione(i, "tipoDiAlterazioneSensoriali")}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            pageState={this.props.pageState}
                            keyFieldId={'TIPO_ALT_DEFICIT_SENS.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            field={'tipoDiAlterazioneSensoriali'}
                            addButtonVisibilityHandler={dati.tipoDiAlterazioneSensoriali?.every(t => !!t.tipoAlterazione)}
                            parentJsonPath={"esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneSensoriali"}
                        />}
                </Box>

                <Box key={"boxROT" + nomeAccordion} titleBox={"Riflessi Osteo-Tendinei (ROT)"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'PRES_ALT_ROT.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"selectPresenzaDiAlterazioneROT"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.presenzaDiAlterazioniROT}
                            onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioniROT", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Alterazione non esistente"}
                            fieldLabel={"Presenza di alterazioni Riflessi Osteo-Tendinei (ROT)"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            field={"presenzaDiAlterazioniROT"}
                        />
                    </FieldsRow>
                    {dati.presenzaDiAlterazioniROT &&
                        <SectionList
                            title={'Alterazione riflessi'}
                            data={dati.tipoDiAlterazioneROT}
                            renderSection={(e, i) => this.renderAlterazioneSede(e, i, "tipoDiAlterazioneROT", "TIPO_ALT_ROT.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO", "SEDE_ALT_ROT.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO")}
                            addNewSectionCallback={() => this.addAlterazione("tipoDiAlterazioneROT")}
                            removeSectionCallback={(i) => this.removeAlterazione(i, "tipoDiAlterazioneROT")}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            pageState={this.props.pageState}
                            keyFieldId={'TIPO_ALT_ROT.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            field={'tipoDiAlterazioneROT'}
                            addButtonVisibilityHandler={dati.tipoDiAlterazioneROT?.every(t => !!t.tipoAlterazione)}
                            parentJsonPath={"esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneROT"}
                        />}
                </Box>

                <Box key={"boxStazioneEretta" + nomeAccordion} titleBox={"Stazione eretta"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'STAZ_ERETT.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"selectStazioneEretta"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.stazioneEretta}
                            onChange={(elem) => this.handleAggiornaForm("stazioneEretta", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Stazione eretta non esistente"}
                            fieldLabel={"Stazione eretta"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            field={"stazioneEretta"}
                        />
                        {dati.stazioneEretta &&
                            <RoleBasedSelect
                                fieldId={'ROMBERG.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                                pageState={this.props.pageState}
                                id={"selectRomberg"}
                                options={OggettiUtili.segnoDiBlumberg}
                                value={dati.romberg}
                                onChange={(elem) => this.handleAggiornaForm("romberg", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Romberg non esistente"}
                                fieldLabel={"Romberg"}
                                handleOnlyValue={true}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                field={"romberg"}
                            />}
                    </FieldsRow>
                    <FieldsRow>
                        <RoleBasedInput
                            fieldId={'ROMBERG.NEUROLOGICOSTATODIVIGILANZA_NOTE.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            value={dati.noteRomberg}
                            placeholder="Note"
                            field={"noteRomberg"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"basedInputNoteRomberg"}
                            fieldLabel={"Note"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            parentJsonPath={'esameobiettivo.neurologicoestatodivigilanza'}
                        />
                    </FieldsRow>
                </Box>

                <Box key={"boxAlterazioniMarcia" + nomeAccordion} titleBox={"Marcia"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'PRES_ALT_MARCIA.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"selectPresenzaDiAlterazioniMarcia"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.presenzaDiAlterazioniMarcia}
                            onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioniMarcia", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                            fieldLabel={"Presenza di alterazioni nella marcia"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            field={"presenzaDiAlterazioniMarcia"}
                        />
                    </FieldsRow>
                    {dati.presenzaDiAlterazioniMarcia &&
                        <SectionList
                            title={'Alterazione marcia'}
                            data={dati.tipoDiAlterazioneMarcia}
                            renderSection={(e, i) => this.renderAlterazione(e, i, "tipoDiAlterazioneMarcia", "TIPO_ALT_MARCIA.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO")}
                            addNewSectionCallback={() => this.addAlterazione("tipoDiAlterazioneMarcia")}
                            removeSectionCallback={(i) => this.removeAlterazione(i, "tipoDiAlterazioneMarcia")}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            pageState={this.props.pageState}
                            keyFieldId={'TIPO_ALT_MARCIA.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            field={'tipoDiAlterazioneMarcia'}
                            addButtonVisibilityHandler={dati.tipoDiAlterazioneMarcia?.every(t => !!t.tipoAlterazione)}
                            parentJsonPath={"esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneMarcia"}
                        />}
                </Box>

                <Box key={"boxAlterazioniFunzioniSuperiori" + nomeAccordion} titleBox={"Funzioni superiori"}>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'PRES_ALT_FUNZ_SUP.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"selectPresenzaDiAlterazioniFunzioniSuperiori"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.presenzaDiAlterazioniFunzioniSuperiori}
                            onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioniFunzioniSuperiori", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                            fieldLabel={"Presenza di alterazioni funzioni superiori"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            field={"presenzaDiAlterazioniFunzioniSuperiori"}
                        />
                        {dati.presenzaDiAlterazioniFunzioniSuperiori &&
                            <RoleBasedInput
                                fieldId={'NOTE_ALT_FUNZ_SUP.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                                pageState={this.props.pageState}
                                value={dati.noteFunzioniSuperiori}
                                placeholder="Note"
                                field={"noteFunzioniSuperiori"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"basedInputNoteFunzioniSuperiori"}
                                fieldLabel={"Note"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />}
                    </FieldsRow>

                    {dati.presenzaDiAlterazioniFunzioniSuperiori &&
                        <SectionList
                            title={'Alterazione funzione superiore'}
                            data={dati.tipoDiAlterazioneFunzioniSuperiori}
                            renderSection={(e, i) => this.renderAlterazione(e, i, "tipoDiAlterazioneFunzioniSuperiori", "TIPO_ALT_FUNZ_SUP.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO")}
                            addNewSectionCallback={() => this.addAlterazione("tipoDiAlterazioneFunzioniSuperiori")}
                            removeSectionCallback={(i) => this.removeAlterazione(i, "tipoDiAlterazioneFunzioniSuperiori")}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            pageState={this.props.pageState}
                            keyFieldId={'TIPO_ALT_FUNZ_SUP.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            field={'tipoDiAlterazioneFunzioniSuperiori'}
                            addButtonVisibilityHandler={dati.tipoDiAlterazioneFunzioniSuperiori?.every(t => !!t.tipoAlterazione)}
                            parentJsonPath={"esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneFunzioniSuperiori"}
                       />}
                </Box>

                <Box key={"boxTonoMuscolare" + nomeAccordion}>
                    <FieldsRow>
                        <RoleBasedInput
                            fieldId={'TONOMUSC.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            value={dati.tonoMuscolare}
                            placeholder="Note"
                            field={"tonoMuscolare"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"basedInputTonoMuscolare"}
                            fieldLabel={"Tono muscolare"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                        />
                        <RoleBasedInput
                            fieldId={'TROFISMOMUSC.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            value={dati.trofismoMuscolare}
                            placeholder="Note"
                            field={"trofismoMuscolare"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"basedInputTrofismoMuscolare"}
                            fieldLabel={"Trofismo muscolare"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                        />
                    </FieldsRow>

                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'MINGAZZ1.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"selectTestDiMingazziniI"}
                            options={OggettiUtili.testMingazzini}
                            value={dati.testDiMingazziniI}
                            onChange={(elem) => this.handleAggiornaForm("testDiMingazziniI", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Test Di Mingazzini non esistente"}
                            fieldLabel={"Test di Mingazzini I"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            field={"testDiMingazziniI"}
                        />

                        {(dati.testDiMingazziniI === "02" || dati.testDiMingazziniI === "03") &&
                            <RoleBasedInput
                                fieldId={'NOTE_MINGAZZ1.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                                pageState={this.props.pageState}
                                value={dati.noteMingazziniI}
                                placeholder="Note"
                                field={"noteMingazziniI"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"basedInputNoteMingazziniI"}
                                fieldLabel={"Note"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />}
                    </FieldsRow>

                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'MINGAZZ2.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"selectTestDiMingazziniII"}
                            options={OggettiUtili.testMingazzini}
                            value={dati.testDiMingazziniII}
                            onChange={(elem) => this.handleAggiornaForm("testDiMingazziniII", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Test Di Mingazzini non esistente"}
                            fieldLabel={"Test di Mingazzini II"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            field={"testDiMingazziniII"}
                        />

                        {(dati.testDiMingazziniII === "02" || dati.testDiMingazziniII === "03") &&
                            <RoleBasedInput
                                fieldId={'NOTE_MINGAZZ2.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                                pageState={this.props.pageState}
                                value={dati.noteMingazziniII}
                                placeholder="Note"
                                field={"noteMingazziniII"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"basedInputNoteMingazziniII"}
                                fieldLabel={"Note"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                            />}
                    </FieldsRow>

                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'RIGOR.NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"selectRigor"}
                            options={OggettiUtili.rispostaBreve}
                            value={dati.rigor}
                            onChange={(elem) => this.handleAggiornaForm("rigor", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Rigor non esistente"}
                            fieldLabel={"Rigor"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            field={"rigor"}
                        />
                    </FieldsRow>

                    <CondizioneRilevataSection
                        ambito={'NEUROLOGICO STATO DI VIGILANZA'}
                        parentFieldId={'NEUROLOGICOSTATODIVIGILANZA.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                        valueCondizioneRilevata={dati.condizioneRilevata}
                        onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                        valueDaAttenzionare={dati.daAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        onChangeSuggerimenti={this.handleAggiornaForm}
                        valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                        showSuggerimenti={true}
                        openCondition={this.showCondizioneRilevata(dati)}
                    />
                </Box>
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"neurologicoEstatoDiVigilanza"}
                    title={"Neurologico e stato di vigilanza"}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneStatoVigilanza",
                            "esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazionePupille",
                            "esameObiettivo.neurologicoEstatoDiVigilanza.dispositiviPresentiPupille",
                            "esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneCerebellari",
                            "esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneNerviCranici",
                            "esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneSensoriali",
                            "esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneROT",
                            "esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneMarcia",
                            "esameObiettivo.neurologicoEstatoDiVigilanza.tipoDiAlterazioneFunzioniSuperiori",
                            "esameObiettivo.neurologicoEstatoDiVigilanza.propostaAttivitaSuggerimenti"],
                        [defaultAlterazione, defaultAlterazione, defaultDispositiviPresenti, defaultAlterazione,
                            defaultAlterazione, defaultAlterazione, defaultAlterazione, defaultAlterazione,
                            defaultAlterazione, defaultProposteAttivitaSuggerimenti]
                    )}
                    accordionReadOnly={this.props.accordionReadOnly}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}

NeurologicoEstatoDiVigilanza.propTypes = propTypes;

NeurologicoEstatoDiVigilanza.defaultProps = {
    showAccordion: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}
