import React from "react";
import {Icon} from "web-client-archetype";
import styles from "./Clock.module.css";
import PropTypes from "prop-types";

const espressione = /^[a-z]+$/i;

/**
 *  props da passare:
 *  - hours = ore nel formato "hh" oppure "h"
 *  - minutes = minuti nel formato "mm" oppure "m"
 *  - separator = carattere usato come separatore
 *  - onChange = funzione da eseguire all'onChange del clock
 *
 *  Opzionali
 *  - resetTime = per resettare l'orario
 *
 *  Esempio:
 *  <Clock hours={"02"} minutes={"55"} onChange={(hh, mm) => this.updateClock(hh, mm)}/>
 */
export default class Clock extends React.Component {

    state = {
        showDropTime: false,
        hourTime: this.props.hours,
        minuteTime: this.props.minutes,
        resetTime: this.props.resetTime
    };

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.resetTime !== prevProps.resetTime && this.props.resetTime) {
            this.setState({hourTime: null, minuteTime: null}, this.callOnChange);
        }
    }

    showDropTime = () => {
        this.setState({showDropTime: !this.state.showDropTime});
    };

    updateHour = (e) => {
        this.setState({hourTime: e.target.value}, this.callOnChange);
    };

    updateMinute = (e) => {
        this.setState({minuteTime: e.target.value}, this.callOnChange);
    };

    onBlurHour = (e) => {
        let hourTime = e.target.value;
        if (hourTime === "") {
            hourTime = null;
        } else if (espressione.test(hourTime)) {
            hourTime = "01";
        } else if (hourTime.length < 2) {
            hourTime = "0" + hourTime;
        }

        this.setState({hourTime}, this.callOnChange);
    }

    onBlurMinute = (e) => {
        let minuteTime = e.target.value;
        if (minuteTime === "") {
            minuteTime = null;
        } else if (espressione.test(minuteTime)) {
            minuteTime = "00";
        } else if (minuteTime.length < 2) {
            minuteTime = "0" + minuteTime;
        }

        this.setState({minuteTime}, this.callOnChange);
    }

    callOnChange = () => {
        let hh = this.state.hourTime !== '' ? this.state.hourTime : null;
        let mm = this.state.minuteTime !== '' ? this.state.minuteTime : null;
        // if (hh === null || mm === null) {
        //     hh = mm = null;
        // }
        // if(hh && !mm){
        //     mm = "00"
        // } else if (!hh && mm){
        //     hh = "00"
        // }
        console.log("CHANGE " + hh + ":" + mm);
        this.setState({minuteTime: mm,hourTime: hh })
        this.props.onChange(hh, mm);
    }

    onChangeField(e, min, max, final, callback) {
        e.target.value = e.target.value.substring(0, 2);
        if (e.target.value !== '') {
            const value = parseInt(e.target.value, 10);
            if (value > max) {
                e.target.value = min.toString();
            } else if (value < min) {
                e.target.value = max.toString();
            }
        }

        if (final) {
            if (e.target.value === '') {
                this.setState({hourTime: null, minuteTime: null}, this.callOnChange);
                return;
            } else if (e.target.value.length < 2) {
                e.target.value = "0" + e.target.value;
            }
        }

        callback(e);
    }

    render() {
        return (
            <>
                <div className={"row mx-0 ".concat(styles.generalLayout)} id={this.props.id}>
                    <div className={"col-4 ".concat(styles.timeLayout)}>
                        <input type="text" id="idHour1" name="hour1"
                               value={this.state.hourTime}
                               maxLength={2}
                               onChange={e => this.onChangeField(e, 0, 23, false, this.updateHour)}
                               min="-1" max="24"
                               className={styles.hoursLayout.concat(" text-center")}
                               onBlur={this.onBlurHour}
                               placeholder={"hh"}/>
                        <input type="text"
                               className={styles.separatorLayout.concat(" text-center")}
                               defaultValue={this.props.separator.substring(0, 1)} disabled
                               id={this.props.id ? this.props.id.concat("-spinnerLayout") : "spinnerLayout"}/>
                        <input type="text" id="idMinute1" name="minute1"
                               value={this.state.minuteTime}
                               maxLength={2}
                               onChange={e => this.onChangeField(e, 0, 59, false, this.updateMinute)}
                               min="-1" max="60"
                               className={styles.minuteLayout.concat(" text-center")}
                               onBlur={this.onBlurMinute}
                               placeholder={"mm"}/>
                    </div>
                    <div>
                        <div className={styles.iconTimeLayout.concat(" pr-1 my-1")}
                             onClick={this.showDropTime} id={"idBtnClock"}>
                            <Icon id="it-clock" className={["bg-white", "icon", "timepicker-icon"]}/>
                        </div>
                    </div>
                </div>
                {this.state.showDropTime &&
                <div className={styles.spinnersLayout}>
                    <input type="number" id="idHour" name="hour"
                           value={this.state.hourTime}
                           onChange={e => this.onChangeField(e, 0, 23, true, this.updateHour)}
                           maxLength={2}
                           onClick={this.updateHour} min="-1" max="24"
                           className={styles.hourSpinner.concat(" text-center")}
                           onBlur={this.onBlurHour}/>
                    <input type="number" id="idMinute" name="minute"
                           value={this.state.minuteTime}
                           onChange={e => this.onChangeField(e, 0, 59, true, this.updateMinute)}
                           maxLength={2}
                           onClick={this.updateMinute} min="-1" max="60"
                           className={styles.minuteSpinner.concat(" text-center")}
                           onBlur={this.onBlurMinute}/>
                </div>}
            </>
        )
    }

}

Clock.propTypes = {
    id: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    separator: PropTypes.string,
    resetTime: PropTypes.bool,
    onChange: PropTypes.func
}

Clock.defaultProps = {
    separator: ":",
    resetTime: false,
    onChange: () => undefined
}
