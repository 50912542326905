import React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {FieldsRow, RoleBasedInput} from "../../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../../utils/Utils";

const propTypes = {
    dati: PropTypes.any,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onChangeDatiBisogniAllaDimissione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class BisogniAllaDimissioneSub extends React.Component {
    state = {
        bisogniAllaDimissione: _.cloneDeep(this.props.dati)
    };

    componentDidMount() {
        this.props.onChangeRequiredFieldsNumber(this.countObbligatorieta())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati))
            this.setState({bisogniAllaDimissione: this.props.dati}, () => {
                this.props.onChangeRequiredFieldsNumber(this.countObbligatorieta());
            });
    }

    handleAggiornaForm = (field, elem) => {
        let bisogniAllaDimissione = _.cloneDeep(this.state.bisogniAllaDimissione)
        bisogniAllaDimissione[field] = elem
        this.setState({bisogniAllaDimissione: bisogniAllaDimissione}, () => {
            this.props.onChangeDatiBisogniAllaDimissione("bisogniAllaDimissione", this.state.bisogniAllaDimissione);
            this.props.onChangeRequiredFieldsNumber(this.countObbligatorieta());

        });
    }

    countObbligatorieta = () => {
        let dati = this.state.bisogniAllaDimissione
        let numeroCampiObbligatoriMancanti = 0
        if (!dati.note) numeroCampiObbligatoriMancanti++
        return numeroCampiObbligatoriMancanti
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"bisogniAllaDimissioneSub"}
                title={"Bisogni alla dimissione"}
                required={Utils.isStateRichiesta(this.props.pageState)}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'BISOGNI_PIC_TERR.BISOGNIALLADIMISSIONE.RICHIESTACAMBIOSETTING'}
                        pageState={this.props.pageState}
                        value={this.state.bisogniAllaDimissione.note}
                        placeholder="Inserisci note"
                        field={"note"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"note"}
                        fieldLabel={"Note"}
                        forceReadOnly={this.props.forceReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        fieldRequired={Utils.isDimissioneProtetta(this.props.pageState)}
                        parentJsonPath={"bisogniAllaDimissione"}
                    />
                </FieldsRow>
            </AccordionSezioneRichiesta>
        )
    }
}

BisogniAllaDimissioneSub.propTypes = propTypes;
