import React, {Fragment} from "react";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {v4 as uuid} from "uuid";
import {
    FieldsGroup,
    FieldsRow,
    RoleBasedAutocomplete,
    RoleBasedInput,
    RoleBasedSelect,
    RoleBasedText,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import Utils from "../../../utils/Utils";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti,
    defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    datiTrattamentiLesioniTrofiche: PropTypes.any,
    onChangeTrattamentiLesioniTrofiche: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeRequiredFieldsNumber:PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultSede = {
    id: null,
    sede: null,
    tipologiaMedicazioneApplicata: []
}

const defaultiTrattamentiUlteriori = {
    id: null,
    trattamentiUlteriori: null,
    frequenzaTrattamentiUlteriori: null,
    frequenzaTrattamentiUlterioriSettimana: null,
    frequenzaTrattamentiUlterioriMese: null,
}

const defaultTipologiaMedicazioneApplicata = {
    codice: null,
    id: null,
    frequenzaMedicazione: null,
    frequenzaMedicazioneSettimana: null,
    frequenzaMedicazioneMese: null
}

export default class TrattamentiLesioniTrofiche extends AccordionSecondLevel {
    state = {
        datiTrattamentiLesioniTrofiche: _.cloneDeep(this.props.datiTrattamentiLesioniTrofiche),
        presenzaTrattamentiLesioniTrofiche: false
    }

    static cndData = Utils.getListCndData();

    componentDidMount() {
        this.inizializzaForm()
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiTrattamentiLesioniTrofiche, this.props.datiTrattamentiLesioniTrofiche))
            this.forceUpdate(() => this.inizializzaForm());
    }

    inizializzaForm = () => {
        let datiTrattamentiLesioniTrofiche = _.cloneDeep(this.props.datiTrattamentiLesioniTrofiche);

        if (datiTrattamentiLesioniTrofiche.sede == null) datiTrattamentiLesioniTrofiche.sede = [];
        else datiTrattamentiLesioniTrofiche.sede.forEach(el => el.tipologiaMedicazioneApplicata = el.tipologiaMedicazioneApplicata ?? []);

        if (datiTrattamentiLesioniTrofiche.trattamentiUlteriori == null) datiTrattamentiLesioniTrofiche.trattamentiUlteriori = [];

        this.setState({datiTrattamentiLesioniTrofiche: datiTrattamentiLesioniTrofiche}, () => this.validazioneDati());
    }

    validazioneDati = () => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        this.props.onChangeTrattamentiLesioniTrofiche("datiTrattamentiLesioniTrofiche", dati);

        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneTrattamentiLesioniTrofiche", campiObbligatoriRimanenti);

    }


    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({datiTrattamentiLesioniTrofiche}) => {
            if (index === null && subField === null) {
                datiTrattamentiLesioniTrofiche[field] = elem;
            } else {
                datiTrattamentiLesioniTrofiche[field][index][subField] = elem;
            }

            if (field === "trattamentiLesioniTrofiche") {
                datiTrattamentiLesioniTrofiche[field] = elem;
                if (elem?.value !== "01") {
                    datiTrattamentiLesioniTrofiche.presenzaTrattamentiLesioniTrofiche = false;
                    datiTrattamentiLesioniTrofiche.sede = [];
                    datiTrattamentiLesioniTrofiche.pressioneSistolicaCaviglia = null;
                    datiTrattamentiLesioniTrofiche.pressioneSistolicaBraccioOmolaterale = null;
                    datiTrattamentiLesioniTrofiche.indiceDiWinsor = null;
                    datiTrattamentiLesioniTrofiche.interpretazioneIndiceDiWinsor = null;
                    datiTrattamentiLesioniTrofiche.tipologiaMedicazioneApplicata = []
                    datiTrattamentiLesioniTrofiche.frequenzaMedicazione = null;
                    datiTrattamentiLesioniTrofiche.trattamentiUlteriori = []
                    datiTrattamentiLesioniTrofiche.frequenzaTrattamentiUlteriori = null;
                    datiTrattamentiLesioniTrofiche.condizioneRilevata = null;
                }
            }

            if (datiTrattamentiLesioniTrofiche.sede?.length === 2 && field === "sede" && subField === "sede") {
                let sedeOpposta = null;
                datiTrattamentiLesioniTrofiche.sede[index].tipologiaMedicazioneApplicata = [];

                if (datiTrattamentiLesioniTrofiche.sede[index].sede === "01") {
                    sedeOpposta = "02";
                } else if (datiTrattamentiLesioniTrofiche.sede[index].sede === "02") {
                    sedeOpposta = "01";
                }
                datiTrattamentiLesioniTrofiche.sede[1 - index].sede = sedeOpposta;
                datiTrattamentiLesioniTrofiche.sede[1 - index].tipologiaMedicazioneApplicata = [];
            }

            datiTrattamentiLesioniTrofiche.indiceDiWinsor = this.getIndiceDiWinsor(datiTrattamentiLesioniTrofiche.pressioneSistolicaCaviglia, datiTrattamentiLesioniTrofiche.pressioneSistolicaBraccioOmolaterale);
            datiTrattamentiLesioniTrofiche.interpretazioneIndiceDiWinsor = this.getInterpretazioneIndiceDiWinsor(datiTrattamentiLesioniTrofiche.indiceDiWinsor);

            return {datiTrattamentiLesioniTrofiche};
        }, this.validazioneDati);
    }

    getIndiceDiWinsor(pressioneSistolicaCaviglia, pressioneSistolicaBraccioOmolaterale) {
        pressioneSistolicaCaviglia = parseInt(pressioneSistolicaCaviglia ?? '0', 10);
        pressioneSistolicaBraccioOmolaterale = parseInt(pressioneSistolicaBraccioOmolaterale ?? '0', 10);
        if (!pressioneSistolicaCaviglia || !pressioneSistolicaBraccioOmolaterale) return null;
        return (pressioneSistolicaCaviglia / pressioneSistolicaBraccioOmolaterale).toFixed(2);
    }

    getInterpretazioneIndiceDiWinsor(indiceDiWinsor) {
        switch (true) {
            case (!indiceDiWinsor):
                return null;

            case (indiceDiWinsor < 0.41):
                return "Vasculopatia periferica severa";

            case (indiceDiWinsor >= 0.41 && indiceDiWinsor <= 0.90):
                return "Vasculopatia periferica di entità lieve-moderata";

            case (indiceDiWinsor >= 0.91 && indiceDiWinsor <= 1.30):
                return "Valore normale";

            case (indiceDiWinsor >= 1.31):
                return "Arterie non comprimibili";
        }
    }

    getLabelAlert(indiceDiWinsor) {
        switch (true) {
            case (!indiceDiWinsor):
                return null;

            case (indiceDiWinsor < 0.5):
                return "Bendaggio controindicato!";

            case (indiceDiWinsor >= 0.5 && indiceDiWinsor < 0.8):
                return "Bendaggio sconsigliato!";

            case (indiceDiWinsor >= 0.8 && indiceDiWinsor < 0.9):
                return "Bendaggio elastico di sostegno (alta estensibilità)!";

            case (indiceDiWinsor >= 0.9):
                return "Bendaggio elastocompressivo consentito!";
        }
    }

    /* Sede */

    addNewSede = () => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        let type = _.cloneDeep(defaultSede)
        type.id = uuid();

        if (dati.sede.length === 1) {
            let newSede = null;
            if (dati.sede[0].sede === "01") {
                newSede = "02";
            } else if (dati.sede[0].sede === "02") {
                newSede = "01";
            }
            type.sede = newSede;
        }
        dati.sede.push(type);
        this.setState({datiTrattamentiLesioniTrofiche: dati}, () => this.validazioneDati());
    }

    removeSede = (index) => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        dati.sede.splice(index, 1);
        this.setState({datiTrattamentiLesioniTrofiche: dati}, () => this.validazioneDati());
    }

    renderSede = (dato, index) => {
        return (
            <Fragment>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'SEDE_LES_TROFICHE.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"selectSede" + index}
                        options={OggettiUtili.sede}
                        value={dato.sede}
                        onChange={(elem) => this.handleAggiornaForm("sede", elem, index, "sede")}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Sede non esistente"}
                        fieldLabel={"Sede"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"sede"}
                    />
                </FieldsRow>

                {<SectionList
                    title={'Tipologia medicazione applicata'}
                    keyFieldId={'TIPO_MED_APPLICATA.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    addNewSectionCallback={() => this.addNewMedicazione(index)}
                    data={dato.tipologiaMedicazioneApplicata}
                    removeSectionCallback={(i) => this.removeMedicazione(index, i)}
                    subtitle={'Tipologia medicazione applicata'}
                    renderSection={(el, i) => this.renderMedicazioneApplicata(el, i, index)}
                    infermiereKeyFieldId={this.props.infermiereFieldId}
                    pageState={this.props.pageState}
                    addButtonVisibilityHandler={!dato.tipologiaMedicazioneApplicata?.some(a => !a.codice) ?? true}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    indentOffset={this.props.indentOffset}
                    hideUnsetValues={this.props.hideUnsetValues}
                    field={'tipologiaMedicazioneApplicata'}
                    parentJsonPath={"informazioniDiBase.datiTrattamentiLesioniTrofiche.sede[?(@.id == '" + dato?.id + "')].tipologiaMedicazioneApplicata"}
                />}
            </Fragment>
        );
    }

    /* Medicazioni applicate CND*/

    handleArrayChange = (array, field, indexSede) => {
        let data = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        data.sede[indexSede][field] = array;

        this.setState({datiTrattamentiLesioniTrofiche: data}, this.validazioneDati);
    }

    addNewMedicazione = (index) => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        let defaultTipologia = _.cloneDeep(defaultTipologiaMedicazioneApplicata);
        defaultTipologia.id = uuid();
        dati.sede[index].tipologiaMedicazioneApplicata.push(defaultTipologia);
        this.handleArrayChange(dati.sede[index].tipologiaMedicazioneApplicata, "tipologiaMedicazioneApplicata", index);
    }

    removeMedicazione = (index, indexSede) => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        dati.sede[indexSede].tipologiaMedicazioneApplicata.splice(index, 1);
        this.handleArrayChange(dati.sede[indexSede].tipologiaMedicazioneApplicata, "tipologiaMedicazioneApplicata", indexSede);
    }

    update = (field, elem, i, indexSede) => {
        if (field === "codice" && !TrattamentiLesioniTrofiche.cndData.some(e => e.codice === elem)) elem = null;

        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        dati.sede[indexSede].tipologiaMedicazioneApplicata[i][field] = elem;

        if (dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazione === "01") {
            dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazioneSettimana = null;
            dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazioneMese = null;
        }

        if (dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazione == "02") {
            dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazioneMese = "";
        }

        if (dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazione == "03") {
            dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazioneSettimana = "";
        }
           

        this.handleArrayChange(dati.sede[indexSede].tipologiaMedicazioneApplicata, "tipologiaMedicazioneApplicata", indexSede);
    }

    renderMedicazioneApplicata = (el, i, indexSede) => {
        return <Fragment>
            <FieldsRow>
                <RoleBasedAutocomplete
                    fieldId={'TIPO_MED_APPLICATA.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    pageState={this.props.pageState}
                    id={'autocompleteMedicazione'}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={(field, elem) => this.update("codice", elem?.target?.value, i, indexSede)}
                    searchFromStart={false}
                    value={el.codice}
                    field={"codice"}
                    placeholder={'Inserisci medicazione'}
                    suggestions={TrattamentiLesioniTrofiche.cndData}
                    noSuggestions={'Errore: Medicazione non presente'}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    handleOnlyValue={true}
                    fieldLabel={'Tipologia Medicazione applicata'}
                />

                {!Utils.isObjectNull(el.codice) &&
                    <RoleBasedSelect
                        fieldId={'FREQ_MED_APPLICATA.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        options={OggettiUtili.frequenzaMedicazione}
                        value={el.frequenzaMedicazione}
                        onChange={(elem) => this.update("frequenzaMedicazione", elem, i, indexSede)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Frequenza medicazione non esistente"}
                        fieldLabel={'Frequenza medicazione'}
                        field={"frequenzaMedicazione"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />}

                {(el.frequenzaMedicazione === "02" || el.frequenzaMedicazione === "03") &&
                    <RoleBasedInput
                        fieldId={'FREQ_MED_APPLICATA_N_VOLTE.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        value={el.frequenzaMedicazione === "02" ? el.frequenzaMedicazioneSettimana : el.frequenzaMedicazioneMese}
                        placeholder={el.frequenzaMedicazione === "02" ? "Inserisci frequenza settimanale" : "Inserisci frequenza al mese"}
                        fieldLabel={el.frequenzaMedicazione === "02" ? 'Frequenza settimanale' : 'Frequenza al mese'}
                        field={el.frequenzaMedicazione === "02" ? "frequenzaMedicazioneSettimana" : "frequenzaMedicazioneMese"}
                        onChange={(field, elem) => this.update(field, elem?.target?.value, i, indexSede)}
                        id={"inputAltraProposta"}
                        type={"number"}
                        min={0}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />}
            </FieldsRow>
        </Fragment>
    }

    /* Trattamenti ulteriori */

    addNewTrattamento = () => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        let type = _.cloneDeep(defaultiTrattamentiUlteriori)
        type.id = uuid();
        dati.trattamentiUlteriori.push(type);
        this.setState({datiTrattamentiLesioniTrofiche: dati}, () => this.validazioneDati());
    }

    removeTrattamento = (index) => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        dati.trattamentiUlteriori.splice(index, 1);
        this.setState({datiTrattamentiLesioniTrofiche: dati}, () => this.validazioneDati());
    }

    renderTrattamentiUlteriori = (dato, index) => {
        return (
            <Fragment>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'TRATTAM_ULTERIORI.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        options={OggettiUtili.trattamentiUlteriori}
                        value={dato.trattamentiUlteriori}
                        onChange={(elem) => this.handleAggiornaForm("trattamentiUlteriori", elem, index, "trattamentiUlteriori")}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Trattamenti ulteriori non esistenti"}
                        fieldLabel={'Trattamenti ulteriori'}
                        handleOnlyValue={true}
                        field={"trattamentiUlteriori"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                
                {!Utils.isObjectNull(dato.trattamentiUlteriori) &&
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'FREQ_TRATTAM_ULTER.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                            pageState={this.props.pageState}
                            options={OggettiUtili.frequenzaTrattamentiUlteriori}
                            value={dato.frequenzaTrattamentiUlteriori}
                            field={"frequenzaTrattamentiUlteriori"}
                            onChange={(elem) => this.handleAggiornaForm("trattamentiUlteriori", elem, index, "frequenzaTrattamentiUlteriori")}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Frequenza trattamenti ulteriori non esistente"}
                            fieldLabel={'Frequenza trattamenti ulteriori'}
                            handleOnlyValue={true}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        <FieldsGroup showFieldIfRequiredAccordionPage={false}
                                     fieldInAccordionReadOnly={!!this.props.accordionReadOnly}>
                            {dato.frequenzaTrattamentiUlteriori === "01" &&
                            <RoleBasedInput
                                fieldId={'FREQ_TRATTAM_ULTER_N_SETTIMANA.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                value={dato.frequenzaTrattamentiUlterioriSettimana}
                                placeholder="Inserisci frequenza"
                                field={"frequenzaTrattamentiUlterioriSettimana"}
                                onChange={(field, elem) => this.handleAggiornaForm("trattamentiUlteriori", elem.target.value, index, "frequenzaTrattamentiUlterioriSettimana")}
                                id={"inputFrequenzaSettimanale" + index}
                                fieldLabel={'Inserisci frequenza'}
                                type={"number"}
                                min={0}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />}

                            {dato.frequenzaTrattamentiUlteriori === "02" &&
                            <RoleBasedInput
                                fieldId={'FREQ_TRATTAM_ULTER_N_MESE.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                value={this.state.datiTrattamentiLesioniTrofiche.frequenzaTrattamentiUlterioriMese}
                                placeholder="Inserisci frequenza"
                                field={"frequenzaTrattamentiUlterioriMese"}
                                onChange={(field, elem) => this.handleAggiornaForm("trattamentiUlteriori", elem.target.value, index, "frequenzaTrattamentiUlterioriMese")}
                                id={"inputFrequenzaMese" + index}
                                fieldLabel={'Inserisci frequenza'}
                                type={"number"}
                                min={0}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />}
                                    
                        </FieldsGroup>
                    </FieldsRow>}
            </Fragment>
        );
    }

    body = () => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'TRATTAM_LES_TROFICHE.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"trattamentiLesioniTrofiche"}
                        className={"trattamentiLesioniTrofiche"}
                        options={OggettiUtili.alterazioniLettura}
                        value={this.state.datiTrattamentiLesioniTrofiche.trattamentiLesioniTrofiche}
                        onChange={(elem) => this.handleAggiornaForm("trattamentiLesioniTrofiche", elem)}
                        isSearchable={false}
                        fieldLabel={"Trattamenti lesioni trofiche"}
                        field={"trattamentiLesioniTrofiche"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>

                {this.state.datiTrattamentiLesioniTrofiche?.trattamentiLesioniTrofiche === "01" &&
                    <>
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'PRESS_SIST_CAVIGL.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                value={this.state.datiTrattamentiLesioniTrofiche.pressioneSistolicaCaviglia}
                                placeholder="Inserisci valore"
                                field="pressioneSistolicaCaviglia"
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                type={"number"}
                                min={0}
                                fieldLabel={'Pressione sistolica caviglia'}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />

                            <RoleBasedInput
                                fieldId={'PRESS_SIST_BRACCIO_OMO.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                value={this.state.datiTrattamentiLesioniTrofiche.pressioneSistolicaBraccioOmolaterale}
                                placeholder="Inserisci valore"
                                field="pressioneSistolicaBraccioOmolaterale"
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                type={"number"}
                                min={0}
                                fieldLabel={'Pressione sistolica braccio omolaterale'}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                        </FieldsRow>

                        <FieldsRow>
                            <RoleBasedText
                                fieldId={'WINSOR_ABI.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                value={this.state.datiTrattamentiLesioniTrofiche.indiceDiWinsor ?? '-'}
                                placeholder={"Inserisci indice di Winsor"}
                                field={"indiceDiWinsor"}
                                id={"indiceDiWinsor"}
                                type={"number"}
                                fieldLabel={'Indice di Winsor (ABI)'}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />

                            <RoleBasedText
                                fieldId={'INTERPR_WINSOR_ABI.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                value={this.state.datiTrattamentiLesioniTrofiche.interpretazioneIndiceDiWinsor ?? '-'}
                                placeholder={"Interpretazione indice di Winsor (ABI)"}
                                field={"interpretazioneIndiceDiWinsor"}
                                fieldLabel={'Interpretazione indice di Winsor (ABI)'}
                                labelAlert={this.getLabelAlert(this.state.datiTrattamentiLesioniTrofiche.indiceDiWinsor)}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                        </FieldsRow>

                        <SectionList
                            title={'Sede'}
                            data={this.state.datiTrattamentiLesioniTrofiche.sede}
                            renderSection={this.renderSede}
                            addNewSectionCallback={() => this.addNewSede()}
                            removeSectionCallback={(index) => this.removeSede(index)}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            addButtonVisibilityHandler={this.state.datiTrattamentiLesioniTrofiche.sede?.length < 2}
                            pageState={this.props.pageState}
                            keyFieldId={'SEDE_LES_TROFICHE.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                            indentOffset={this.props.indentOffset}
                            field={'sede'}
                            parentJsonPath={"informazioniDiBase.datiTrattamentiLesioniTrofiche.sede"}
                        />

                        <SectionList
                            title={'Trattamenti ulteriori'}
                            data={this.state.datiTrattamentiLesioniTrofiche.trattamentiUlteriori}
                            renderSection={this.renderTrattamentiUlteriori}
                            addNewSectionCallback={() => this.addNewTrattamento()}
                            removeSectionCallback={(index) => this.removeTrattamento(index)}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            addButtonVisibilityHandler={this.state.datiTrattamentiLesioniTrofiche.trattamentiUlteriori?.length === 0 || this.state.datiTrattamentiLesioniTrofiche.trattamentiUlteriori?.every(el => !Utils.isObjectNull(el?.trattamentiUlteriori))}
                            pageState={this.props.pageState}
                            keyFieldId={'TRATTAM_ULTERIORI.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                            field={'trattamentiUlteriori'}
                            parentJsonPath={"informazioniDiBase.datiTrattamentiLesioniTrofiche.trattamentiUlteriori"}
                        />
                    </>}

                <CondizioneRilevataSection
                    ambito={"TRATTAMENTI LESIONI TROFICHE"}
                    pageState={this.props.pageState}
                    parentFieldId={'TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={this.state.datiTrattamentiLesioniTrofiche.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={this.state.datiTrattamentiLesioniTrofiche.daAttenzionare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={this.state.datiTrattamentiLesioniTrofiche.propostaAttivitaSuggerimenti}
                    openCondition={this.state.datiTrattamentiLesioniTrofiche?.trattamentiLesioniTrofiche === "01"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiTrattamentiLesioniTrofiche"}
                title={"Trattamenti lesioni trofiche"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["informazioniDiBase.datiTrattamentiLesioniTrofiche.sede",
                        "informazioniDiBase.datiTrattamentiLesioniTrofiche.sede.tipologiaMedicazioneApplicata",
                        "informazioniDiBase.datiTrattamentiLesioniTrofiche.trattamentiUlteriori",
                        "informazioniDiBase.datiTrattamentiLesioniTrofiche.propostaAttivitaSuggerimenti"],
                    [defaultSede, defaultTipologiaMedicazioneApplicata, defaultiTrattamentiUlteriori, defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.body()}</AccordionSezioneRichiesta>
        );
    }
}
TrattamentiLesioniTrofiche.propTypes = propTypes;
