import React from "react";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import * as _ from "lodash";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import PropTypes from "prop-types";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    naso: PropTypes.any,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultAlterazione = {
    id: null,
    tipologiaDiAlterazione: null,
    sede: null
};

export default class Naso extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.naso)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.naso, this.props.naso))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.naso);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index) => {
        this.setState(({dati}) => {
            if (field === "presenzaDiAlterazioni" && !elem) {
                dati[field] = elem;
                dati.alterazioni = [];
                dati.condizioneRilevata = null;
            } else if (field === "tipologiaDiAlterazione") {
                dati.alterazioni[index].tipologiaDiAlterazione = elem
            } else if (field === "sede") {
                dati.alterazioni[index].sede = elem
            } else {
                dati[field] = elem;
            }
            return {dati};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        const dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("naso", dati);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneDispositiviMediciPresenti", campiObbligatoriRimanenti);
    }

    addNewTipoAlterazione = () => {
        let dati = _.cloneDeep(this.state.dati);
        let alterazione = _.cloneDeep(defaultAlterazione);
        alterazione.id = uuid();
        dati.alterazioni.push(alterazione);
        this.setState({dati}, () => this.validazioneDati());
    }

    removeTipoAlterazione = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.alterazioni.splice(index, 1);
        this.setState({dati}, () => this.validazioneDati());
    }

    renderTipoAlterazione = (alterazione, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'TIPO_ALT.NASO.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    value={alterazione.tipologiaDiAlterazione}
                    placeholder={"Testo libero o ICD9-CM - Descrizione"}
                    onChange={(field, elem) => this.handleAggiornaForm("tipologiaDiAlterazione", elem.target.value, index)}
                    id={"basedInputTipoDiAlterazione" + index}
                    fieldLabel={"Tipo di alterazione"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    field={'tipologiaDiAlterazione'}
                />
                <RoleBasedInput
                    fieldId={"SEDE_ALT.NASO.ESAMEOBIETTIVO"}
                    pageState={this.props.pageState}
                    value={alterazione.sede}
                    placeholder="Inserisci sede"
                    field="sede"
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                    id="sede"
                    fieldLabel={'Sede/i'}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
            </FieldsRow>
        )
    }

    handleDispositiviArrayChange = (array, field) => {
        let dati = _.cloneDeep(this.state.dati);
        dati[field] = array;
        this.setState({dati}, () => {
            this.props.onChangeEsameObiettivo("naso", dati);
            this.forceUpdate();
        });
    }

    renderBodySezione = () => {
        const dati = this.state.dati;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PRES_ALT.NASO.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"selectPresenzaDiAlterazioni"}
                        options={OggettiUtili.rispostaBreve}
                        value={dati.presenzaDiAlterazioni}
                        onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioni", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Presenza di alterazioni non esistente"}
                        fieldLabel={"Presenza di alterazioni"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"presenzaDiAlterazioni"}
                    />
                </FieldsRow>

                {dati.presenzaDiAlterazioni &&
                    <SectionList
                        title={'Tipo di alterazione'}
                        data={dati.alterazioni}
                        renderSection={this.renderTipoAlterazione}
                        addNewSectionCallback={this.addNewTipoAlterazione}
                        removeSectionCallback={this.removeTipoAlterazione}
                        addButtonVisibilityHandler={dati.alterazioni?.every(a => !!a.tipologiaDiAlterazione)}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'TIPO_ALT.NASO.ESAMEOBIETTIVO'}
                        field={'alterazioni'}
                        parentJsonPath={"esameObiettivo.naso.alterazioni"}
                    />}

                <DispositiviPresenti
                    handleArrayChange={this.handleDispositiviArrayChange}
                    dispositiviPresenti={dati.dispositiviPresenti}
                    pageState={this.props.pageState}
                    fieldId={"DISPOSITIVI.NASO.ESAMEOBIETTIVO"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    parentJsonPath={"esameObiettivo.naso.dispositiviPresenti"}
                />

                <CondizioneRilevataSection
                    ambito={'NASO'}
                    parentFieldId={'NASO.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={dati.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={dati.daAttenzionare}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                    showSuggerimenti={true}
                    openCondition={dati.presenzaDiAlterazioni}
                />
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"naso"}
                    title={"Naso"}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.naso.alterazioni", "esameObiettivo.naso.dispositiviPresenti",
                            "esameObiettivo.naso.propostaAttivitaSuggerimenti"],
                        [defaultAlterazione, defaultDispositiviPresenti, defaultProposteAttivitaSuggerimenti]
                    )}
                    accordionReadOnly={this.props.accordionReadOnly}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}
Naso.propTypes = propTypes;

Naso.defaultProps = {
    showAccordion: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

