import React, { Component, Fragment } from "react";
import styles from "../Questionario.module.css";
import PropTypes from 'prop-types'

const propTypes = {
    labelDomanda: PropTypes.string,
    renderRisposte: PropTypes.func
}

const defaultProps = {
    labelDomanda: '',
    renderRisposte: null,
}

export default class DicotomicaOrizzontale extends Component {

    render() {
        const {labelDomanda, renderRisposte} = this.props;
        return (
            <Fragment>
                <div className={" row"}>
                    <div className={"col-10 m-auto"}>
                        {labelDomanda}
                    </div>
                    <div className={"col align-items-center justify-content-center"}>
                        {renderRisposte()}
                    </div>
                </div>
            </Fragment>
        )
    }
}

DicotomicaOrizzontale.propTypes = propTypes;
DicotomicaOrizzontale.defaultProps = defaultProps;

