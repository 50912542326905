import React, {Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedInput,
    RoleBasedSelect,
    RoleBasedText
} from "../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import Statistica from "../../statistica/Statistica";
import SessionRequest from "../../../utils/SessionRequest";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import enumQuestionario from "../../../enum/enumQuestionario";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import {
    defaultAltro,
    defaultFarmaco, defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import Utils from "../../../utils/Utils";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    statoNutrizionale: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    richiesta: PropTypes.any,
    onHide: PropTypes.func,
    isOptional: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export function calcoloBMI(peso, altezza) {
    peso = parseInt(peso ?? '0', 10);
    altezza = parseInt(altezza ?? '0', 10);
    if (!peso || !altezza) return null;
    return ((peso / altezza / altezza) * 10000).toFixed(2);
}

export function statoBMI(BMI) {
    switch (true) {
        case (!BMI):
            return null;

        case (BMI < 16):
            return "Grave magrezza";

        case (BMI >= 16 && BMI <= 18.49):
            return "Sottopeso";

        case (BMI >= 18.50 && BMI <= 24.99):
            return "Normopeso";

        case (BMI >= 25 && BMI <= 29.99):
            return "Sovrappeso";

        case (BMI >= 30):
            return "Obeso";
    }
}

export default class StatoNutrizionale extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.statoNutrizionale)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.statoNutrizionale, this.props.statoNutrizionale))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.statoNutrizionale);
        this.setState({dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({dati}) => {
            dati[field] = elem;

            if (!dati.variazionePesoSignificativo) {
                dati.caratteristicheVariazione = null;
                dati.variazioneKg = null;
                dati.tempoVariazione = null;
                dati.noteVariazionePeso = null;
                dati.condizioneRilevata = null;
            }

            if (!dati.condizioneRilevata) {
                dati.MNA = null;
                dati.statoDopoValutazione = null;
            }

            dati.BMI = calcoloBMI(dati.peso, dati.altezza);
            dati.statoBMI = statoBMI(dati.BMI);

            return {dati};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        const dati = _.cloneDeep(this.state.dati);
        this.props.onChangeDatiStatoNutrizionale("statoNutrizionale", dati);
        let campiObbligatori = 0;
        campiObbligatori += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneStatoNutrizionale", campiObbligatori);
    }

    onHide = () => {
        const dati = _.cloneDeep(SessionRequest.getInitObject('anamnesiFisiologica.statoNutrizionale'));
        this.setState({dati: dati},
            () => {
                this.props.onChangeDatiStatoNutrizionale("statoNutrizionale", dati)
                this.props.onHide();
            });
    }

    renderBody = () => {
        const dati = this.state.dati;

        return (
            <>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'M_ALTEZZA.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_ALTEZZA.STATONUTRIZIONALE.ASSORBIMENTO'}
                        pageState={this.props.pageState}
                        value={dati.altezza}
                        placeholder={"Inserisci altezza in cm"}
                        field={"altezza"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"basedInputAltezza"}
                        fieldLabel={"Altezza (cm)"}
                        type={"number"}
                        min={0}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedInput
                        fieldId={'M_PESO.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_PESO.STATONUTRIZIONALE.ASSORBIMENTO'}
                        pageState={this.props.pageState}
                        value={dati.peso}
                        placeholder={"Inserisci peso in Kg"}
                        field={"peso"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"peso"}
                        fieldLabel={"Peso (Kg)"}
                        type={"number"}
                        min={0}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <FieldsRow>
                    {dati.BMI ?
                    <RoleBasedText
                        fieldId={'M_BMI.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_BMI.STATONUTRIZIONALE.ASSORBIMENTO'}
                        pageState={this.props.pageState}
                        value={dati.BMI}
                        field={"BMI"}
                        fieldLabel={"BMI"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    : '' }
                    {dati.statoBMI ?
                    <RoleBasedText
                        fieldId={'M_STATOBMI.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_STATOBMI.STATONUTRIZIONALE.ASSORBIMENTO'}
                        pageState={this.props.pageState}
                        value={dati.statoBMI}
                        field={"statoBMI"}
                        fieldLabel={"Stato BMI"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    : ''}
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_VAR_PESO_SIGNIFICATIVE.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_VAR_PESO_SIGNIFICATIVE.STATONUTRIZIONALE.ASSORBIMENTO'}
                        pageState={this.props.pageState}
                        id={"selectVariazioniPesoSignificative"}
                        options={OggettiUtili.variazionePesoSignificativo}
                        value={dati.variazionePesoSignificativo}
                        onChange={(elem) => this.handleAggiornaForm("variazionePesoSignificativo", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Variazioni di Peso significative non esistente"}
                        handleOnlyValue={true}
                        fieldLabel={"Variazioni di Peso significative?"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"variazionePesoSignificativo"}
                    />
                </FieldsRow>

                {dati.variazionePesoSignificativo &&
                    <>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_CARAT_VAR.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA'}
                                infermiereFieldId={'I_CARAT_VAR.STATONUTRIZIONALE.ASSORBIMENTO'}
                                pageState={this.props.pageState}
                                id={"selectCaratteristicheVariazione"}
                                options={OggettiUtili.caratteristicheVariazioni}
                                value={dati.caratteristicheVariazione}
                                onChange={(elem) => this.handleAggiornaForm("caratteristicheVariazione", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: caratteristica variazione non presente"}
                                fieldLabel={"Caratteristiche variazione"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                                handleOnlyValue={true}
                                field={"caratteristicheVariazione"}
                            />
                            <RoleBasedInput
                                fieldId={'M_VAR_KG.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA'}
                                infermiereFieldId={'I_VAR_KG.STATONUTRIZIONALE.ASSORBIMENTO'}
                                pageState={this.props.pageState}
                                value={dati.variazioneKg}
                                placeholder="Inserisci peso in Kg"
                                field={"variazioneKg"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"variazioneKg"}
                                fieldLabel={"Variazione in Kg"}
                                type={"number"}
                                min={0}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                            <RoleBasedInput
                                fieldId={'M_TEMPO_VAR.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA'}
                                infermiereFieldId={'I_TEMPO_VAR.STATONUTRIZIONALE.ASSORBIMENTO'}
                                pageState={this.props.pageState}
                                value={dati.tempoVariazione}
                                placeholder="Inserisci il numero di giorni"
                                field={"tempoVariazione"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"tempoVariazione"}
                                fieldLabel={"Tempo variazione (gg)"}
                                type={"number"}
                                min={0}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                            <RoleBasedInput
                                fieldId={'M_NOTE.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA'}
                                infermiereFieldId={'I_NOTE.STATONUTRIZIONALE.ASSORBIMENTO'}
                                pageState={this.props.pageState}
                                value={dati.noteVariazionePeso}
                                placeholder="Inserisci note"
                                field={"noteVariazionePeso"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                                id={"noteVariazionePeso"}
                                fieldLabel={"Note"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                        </FieldsRow>

                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_COND_RIL.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA'}
                                infermiereFieldId={'I_COND_RIL.STATONUTRIZIONALE.ASSORBIMENTO'}
                                pageState={this.props.pageState}
                                id={"selectcondizioneRilevata"}
                                options={OggettiUtili.rispostaCondizioneRilevata}
                                value={dati.condizioneRilevata}
                                onChange={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: condizione non presente"}
                                fieldLabel={"La condizione rilevata"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                hideUnsetValues={this.props.hideUnsetValues}
                                disableDoubleInput={this.props.disableDoubleInput}
                                handleOnlyValue={true}
                                field={"condizioneRilevata"}
                            />
                        </FieldsRow>
                    </>}

                {dati.condizioneRilevata &&
                    <Fragment>
                        <Statistica
                            fieldId={'M_PUNT_MNA_GLOBALE.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_PUNT_MNA_GLOBALE.STATONUTRIZIONALE.ASSORBIMENTO'}
                            pageState={this.props.pageState}
                            nomeLink={"Compila questionario Test Stato nutrizionale – MNA (1-2 parte)"}
                            nomePunteggio={"Punteggio Test Stato nutrizionale - MNA"}
                            questionario={dati.MNA}
                            profileName={'Esito valutazione'}
                            profileFieldId={'M_ESITO_VAL_MNA_TOTALE.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA'}
                            profileInfermiereFieldId={'I_ESITO_VAL_MNA_TOTALE.STATONUTRIZIONALE.ASSORBIMENTO'}
                            onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.MNA, questionario)}
                            richiesta={this.props.richiesta}
                            pathRichiestaQuestionario={"anamnesiFisiologica.statoNutrizionale.MNA"}
                            tipoQuestionario={enumQuestionario.tipoQuestionario.MNA}
                            profileRequired={false}
                            salvaBozza={this.props.salvaBozza}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            parentJsonPathSubObject={'mna'}
                        />
                    </Fragment>}

                <ValutazioneSection
                    pageState={this.props.pageState}
                    ambito={"STATO NUTRIZIONALE"}
                    parentFieldId={'STATONUTRIZIONALE.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'STATONUTRIZIONALE.ASSORBIMENTO'}
                    appendRolePrefix={true}
                    showDataValutazione={true}
                    onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                    valueStatoDopoValutazione={dati.statoDopoValutazione}
                    onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                    valueDaConsiderare={dati.daConsiderare}
                    onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                    valueDataValutazione={dati.dataValutazione}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={dati.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={dati.autonomiaGestioneCaregiver}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    openCondition={dati.condizioneRilevata}
                />
            </>
        );
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"statoNutrizionale"}
            title={"Stato Nutrizionale"}
            required={false}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            onHide={this.onHide}
            isOptional={this.props.isOptional && isFieldIdWriteable('M_ALTEZZA.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA', this.props.pageState, this.context.forceUserRole)}
            fieldsOrderMap={Utils.generateMapPathsObjects(
                ["anamnesiFisiologica.statoNutrizionale.propostaAttivitaSuggerimenti"],
                [defaultProposteAttivitaSuggerimenti]
            )}
            accordionReadOnly={this.props.accordionReadOnly}
            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
    }
}
StatoNutrizionale.propTypes = propTypes;

StatoNutrizionale.defaultProps = {
    isOptional: true,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

StatoNutrizionale.contextType = RoleBasedContext;

