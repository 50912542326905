import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import {
    aicList,
    FieldsRow,
    RoleBasedAICInput,
    RoleBasedCalendar,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import enumRichiesta from "../../../enum/enumRichiesta.json";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import AuthUtils from "../../../utils/AuthUtils";

const propTypes = {
    pageState: PropTypes.string,
    datiAllergie: PropTypes.any,
    onChangeDatiAllergie: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    forceReadOnly: PropTypes.bool,
    mascheraModificabilita: PropTypes.any,
    field: PropTypes.any,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

const defaultAllergie = {
    id: null,
    codice: null,
    descrizioneAgente: null,
    tipologiaReazione: []
}
const defaultTipologiaReazione = {
    id: null,
    codice: null,
    dataRilevazione: null
}

export default class Allergie extends Component {

    state = {
        allergie: this.props.datiAllergie ? _.cloneDeep(this.props.datiAllergie) : []
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.datiAllergie, prevProps.datiAllergie)) {
           this.inizializzaForm()
        }
    }

    inizializzaForm = () => {
        let allergie = _.cloneDeep(this.props.datiAllergie);
        this.setState({allergie: allergie}, ()=>{ this.countCampiObbligatoriRimanentiAndValida() });
    }

    handleAggiornaForm = (field, elem, index) => {
        this.setState(({allergie}) => {
            if (field === "presenzaAllergie" && elem !== "03") {
                allergie.listaAllergie = []
                allergie.condizioneRilevata = null;
            }
            if (field === "tipologiaAllergie") {
                allergie.listaAllergie[index].codice = elem;
                allergie.listaAllergie[index].descrizioneAgente = null;
            } else if (field === "descrizioneAgente") {
                allergie.listaAllergie[index].descrizioneAgente = elem;
            } else {
                allergie[field] = elem;
            }
            return {allergie}
        }, () => {
            let allergieProps = _.cloneDeep(this.state.allergie)
            this.props.onChangeDatiAllergie("allergie", allergieProps)
            this.countCampiObbligatoriRimanentiAndValida();
        });
    }

       
    countCampiObbligatoriRimanentiAndValida = () => {
        let datiAllergie = _.cloneDeep(this.state.allergie);
        let campiObbligatoriRimanenti = 0;

        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiAllergie?.propostaAttivitaSuggerimenti)

        this.props.onChangeRequiredFieldsNumber("sottoSezioneAllergie", campiObbligatoriRimanenti);
    }

    

    handleAggiornaReazione = (elem, index, parentIndex) => {
        let allergie = _.cloneDeep(this.state.allergie);
        allergie.listaAllergie[parentIndex].tipologiaReazione = elem;
        this.setState({allergie}, () => this.props.onChangeDatiAllergie("allergie", this.state.allergie));
    }

    addNewTipologiaAllergie = () => {
        let allergie = _.cloneDeep(this.state.allergie);
        let type = _.cloneDeep(defaultAllergie)
        type.id = uuid();
        allergie.listaAllergie.push(type)
        this.setState({allergie: allergie}, () => {
            let allergieProps = _.cloneDeep(allergie)
            this.props.onChangeDatiAllergie("allergie", allergieProps)
        });
    }

    removeTipologiaAllergie = (index) => {
        let allergie = _.cloneDeep(this.state.allergie);
        allergie.listaAllergie.splice(index, 1);
        this.setState({allergie: allergie}, () => {
            let allergieProps = _.cloneDeep(allergie)
            this.props.onChangeDatiAllergie("allergie", allergieProps)
        })
    }

    renderTipologiaAllergie = (allergie, index) => {
        const optionsAIC = _.cloneDeep(aicList)
            .filter(f => !this.state.allergie.listaAllergie?.find(d => allergie.descrizioneAgente !== d.descrizioneAgente && d.descrizioneAgente === f.value));

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'TIPO_ALLERG.ALLERGIE.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"selectTipologiaAllergie"}
                        options={OggettiUtili.tipologiaAllergie}
                        value={allergie.codice}
                        onChange={(elem) => this.handleAggiornaForm("tipologiaAllergie", elem, index)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Tipologia di allergie non esistente"}
                        fieldLabel={"Tipologia di allergie"}
                        handleOnlyValue={true}
                        field={"codice"}
                        forceReadOnly={Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseRichiesta]?.allergie?.listaAllergie, "codice", allergie?.id)
                            || Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseValutazione]?.allergie?.listaAllergie, "codice", allergie?.id)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    {allergie.codice === "03" || allergie.codice === "08"
                        ? <RoleBasedAICInput
                            id={"codiceAICDescrizioneAgente"}
                            fieldId={'DESCR_AGENTE.ALLERGIE.INFORMAZIONIDIBASE'}
                            field={"descrizioneAgente"}
                            fieldLabel={"Descrizione agente"}
                            pageState={this.props.pageState}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            value={allergie.descrizioneAgente}
                            suggestions={optionsAIC}
                            forceReadOnly={Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseRichiesta]?.allergie?.listaAllergie, "descrizioneAgente", allergie?.id)
                                || Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseValutazione]?.allergie?.listaAllergie, "descrizioneAgente", allergie?.id)}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        : <RoleBasedInput
                            id={"descrizioneAgente"}
                            fieldId={'DESCR_AGENTE.ALLERGIE.INFORMAZIONIDIBASE'}
                            pageState={this.props.pageState}
                            value={allergie.descrizioneAgente}
                            placeholder={"Descrizione agente"}
                            field={"descrizioneAgente"}
                            fieldLabel={"Descrizione agente"}
                            forceReadOnly={Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseRichiesta]?.allergie?.listaAllergie, "descrizioneAgente", allergie?.id)
                                || Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseValutazione]?.allergie?.listaAllergie, "descrizioneAgente", allergie?.id)}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        />}
                </FieldsRow>
                <SectionList
                    title={'Tipologia di reazione'}
                    data={this.state.allergie.listaAllergie[index].tipologiaReazione}
                    renderSection={(reazione, indexReazione) => this.renderTipologiaReazione(reazione, indexReazione, index)}
                    addNewSectionCallback={() => this.addNewTipologiaReazione(index)}
                    removeSectionCallback={(indexReazione) => this.removeTipologiaReazione(indexReazione, index)}
                    addButtonVisibilityHandler={this.state.allergie.listaAllergie.length === 0 || this.state.allergie.listaAllergie[index].tipologiaReazione.every(this.isTipologiaReazioneValida)}
                    pageState={this.props.pageState}
                    keyFieldId={'TIPO_REAZ.ALLERGIE.INFORMAZIONIDIBASE'}
                    field={'tipologiaReazione'}
                    parentJsonPath={"informazioniBaseRicoveroAllergieAccesso.allergie.listaAllergie[?(@.id == '" + allergie?.id + "')].tipologiaReazione"}
                    forceReadOnly={Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseRichiesta]?.allergie?.listaAllergie, "tipologiaReazione", allergie?.id)
                        || Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseValutazione]?.allergie?.listaAllergie, "tipologiaReazione", allergie?.id)}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </>
        )
    }

    addNewTipologiaReazione = (parentIndex) => {
        let allergie = _.cloneDeep(this.state.allergie);
        let tipologiaReazione = _.cloneDeep(defaultTipologiaReazione);
        tipologiaReazione.id = uuid();
        allergie.listaAllergie[parentIndex].tipologiaReazione.push(tipologiaReazione);
        this.setState({allergie: allergie}, () => {
            let allergieProps = _.cloneDeep(allergie)
            this.props.onChangeDatiAllergie("allergie", allergieProps)
        });
    }

    removeTipologiaReazione = (index, parentIndex) => {
        let allergie = _.cloneDeep(this.state.allergie);
        allergie.listaAllergie[parentIndex].tipologiaReazione.splice(index, 1);
        this.setState({allergie: allergie}, () => {
                let allergieProps = _.cloneDeep(allergie)
                this.props.onChangeDatiAllergie("allergie", allergieProps)
            }
        )
    }

    renderTipologiaReazione = (reazioni, index, parentIndex) => {
        return <FieldsRow>
            <RoleBasedICD9Input
                fieldId={'TIPO_REAZ.ALLERGIE.INFORMAZIONIDIBASE'}
                pageState={this.props.pageState}
                onChange={(f, e) => this.handleAggiornaReazione(
                    _.cloneDeep(this.state.allergie)?.listaAllergie[parentIndex]?.tipologiaReazione?.map(function (item) {
                        if (reazioni.id === item.id)
                            return {...reazioni, codice: e.target.value}
                        return item
                    }), index, parentIndex)}
                value={reazioni.codice}
                field={"codice"}
                fieldLabel={"Tipo di reazione"}
                forceReadOnly={Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseRichiesta]?.allergie?.listaAllergie?.[parentIndex]?.tipologiaReazione , "codice", reazioni?.id)
                    || Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseValutazione]?.allergie?.listaAllergie?.[parentIndex]?.tipologiaReazione , "codice", reazioni?.id)}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            />

            <RoleBasedCalendar
                fieldId={'DATA_RILEV.ALLERGIE.INFORMAZIONIDIBASE'}
                pageState={this.props.pageState}
                id={"allergieDataRilevazione"}
                value={reazioni.dataRilevazione}
                onChange={(e) => e.target.value !== reazioni.dataRilevazione ? this.handleAggiornaReazione(
                    _.cloneDeep(this.state.allergie)?.listaAllergie[parentIndex]?.tipologiaReazione?.map(function (item) {
                        if (reazioni.id === item.id)
                            return {
                                ...reazioni,
                                dataRilevazione: e.target.value
                            }
                        return item
                    }), index, parentIndex) : null}
                errorMessage={"Data rilevazione non valida"}
                fieldLabel={"Data rilevazione"}
                field={"dataRilevazione"}
                forceReadOnly={Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseRichiesta]?.allergie?.listaAllergie?.[parentIndex]?.tipologiaReazione , "dataRilevazione", reazioni?.id)
                    || Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseValutazione]?.allergie?.listaAllergie?.[parentIndex]?.tipologiaReazione , "dataRilevazione", reazioni?.id)}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            />
        </FieldsRow>;
    }

    isTipologiaReazioneValida = (tipologiaReazione) => {
        return !Utils.isObjectNull(tipologiaReazione) && !!tipologiaReazione.codice;
    }

    isTipologiaAllergiaValida = (allergie) => {
        return !Utils.isObjectNull(allergie?.codice)
    }

    renderBody = () => {
        let allergie = _.cloneDeep(this.state.allergie);
        let prova = _.cloneDeep(this.props.disableDoubleInput);
        
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PRES_ALLERGIE.ALLERGIE.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"selectPresenzaAllergie"}
                        options={OggettiUtili.presenzaAllergie}
                        value={allergie.presenzaAllergie}
                        onChange={(elem) => this.handleAggiornaForm("presenzaAllergie", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Presenza allergie non esistente"}
                        fieldLabel={"Presenti allergie?"}
                        handleOnlyValue={true}
                        field={"presenzaAllergie"}
                        forceReadOnly={this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseRichiesta]?.allergie?.presenzaAllergie
                            || this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseValutazione]?.allergie?.presenzaAllergie}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
                {this.state.allergie.presenzaAllergie === "03" &&
                    <SectionList
                        title={'Tipologia di Allergia'}
                        data={this.state.allergie.listaAllergie}
                        renderSection={this.renderTipologiaAllergie}
                        addNewSectionCallback={this.addNewTipologiaAllergie}
                        removeSectionCallback={this.removeTipologiaAllergie}
                        addButtonVisibilityHandler={this.state.allergie.listaAllergie?.length === 0 || this.state.allergie.listaAllergie.every(this.isTipologiaAllergiaValida)}
                        pageState={this.props.pageState}
                        keyFieldId={'TIPO_ALLERG.ALLERGIE.INFORMAZIONIDIBASE'}
                        field={'listaAllergie'}
                        parentJsonPath={"informazioniBaseRicoveroAllergieAccesso.allergie.listaAllergie"}
                        forceReadOnly={this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseRichiesta]?.allergie?.listaAllergie
                            || this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseValutazione]?.allergie?.listaAllergie}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />}

                <CondizioneRilevataSection
                    ambito={'ALLERGIE'}
                    parentFieldId={'ALLERGIE.INFORMAZIONIDIBASE'}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={allergie.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    showSuggerimenti={true}
                    valueDaAttenzionare={allergie.daAttenzionare}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={allergie.propostaAttivitaSuggerimenti}
                    openCondition={this.state.allergie.presenzaAllergie === "03"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </>
        );
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"allergie"}
            title={"Allergie"}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            fieldsOrderMap={Utils.generateMapPathsObjects(
                ["informazioniBaseRicoveroAllergieAccesso.allergie.listaAllergie",
                    "informazioniBaseRicoveroAllergieAccesso.allergie.listaAllergie.tipologiaReazione",
                    "informazioniBaseRicoveroAllergieAccesso.allergie.propostaAttivitaSuggerimenti"],
                [defaultAllergie, defaultTipologiaReazione, defaultProposteAttivitaSuggerimenti]
            )}
            accordionReadOnly={this.props.accordionReadOnly}
        >{this.renderBody()}</AccordionSezioneRichiesta>
    }
}

Allergie.propTypes = propTypes;
