import React, {Fragment, useContext} from "react";
import PropTypes from "prop-types";
import {
  FieldsGroup,
  FieldsRow,
  RoleBasedInput,
  RoleBasedPhoneInput,
  RoleBasedSelect,
} from "../../roleBasedComponents/RoleBasedComponents";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import OggettiUtili from "../../../utils/dataset/OggettiUtili";
import {CONTROLLO_CAMPI_FORMALIZZAZIONE} from "../CompilaFormalizzazione";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";

function ResponsabileDelCasoNelSettingRichiesto({
  openAccordion,
  onClickAccordion,
  field,
  pageState,
  progettoAssistenziale,
  handleChangeProgettoAssistenziale,
}) {
  const context = useContext(RoleBasedContext);

  let ruoloResponsabile = (
    <FieldsGroup fieldId={"RUOLO_RESP.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
                 fieldLabel={"Ruolo Responsabile"}
                 fieldRequired={true}>
      <RoleBasedSelect
        fieldId={"RUOLO_RESP.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
        pageState={pageState}
        parentClass={"mb-0"}
        id={"RUOLO_RESP"}
        options={OggettiUtili.ruoloResponsabile}
        value={OggettiUtili.ruoloResponsabile.find(
            (option) => option.value === progettoAssistenziale?.ruoloDelResponsabile
          )}
        onChange={(elem) => {
          handleChangeProgettoAssistenziale(
            "ruoloDelResponsabile",
            elem.value
          );
        }}
        isSearchable={false}
        noOptionsMessage={() => "Errore: Non sono presenti opzioni"}
        forceReadOnly={false}
        hideUnsetValues={false}
        disableDoubleInput={false}
        fieldRequired={true}
      />
    </FieldsGroup>
  );

  let renderNomeCognome = () => {
    let nome = (
      <FieldsGroup
        fieldId={"NOME.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
        fieldLabel={"Nome"}
        fieldRequired={CONTROLLO_CAMPI_FORMALIZZAZIONE.nomeResponsabile.required}
        showFieldIfRequiredAccordionPage={CONTROLLO_CAMPI_FORMALIZZAZIONE.nomeResponsabile.required}
      >
        <RoleBasedInput
          fieldId={"NOME.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
          id="NOME"
          pageState={pageState}
          parentClass={"mb-0"}
          value={progettoAssistenziale?.nomeResponsabile}
          placeholder="Inserisci nome"
          field="nomeResponsabile"
          onChange={(field, elem) => {
            handleChangeProgettoAssistenziale(
              "nomeResponsabile",
              elem.target.value
            );
          }}
          forceReadOnly={false}
          hideUnsetValues={false}
          disableDoubleInput={false}
          fieldRequired={CONTROLLO_CAMPI_FORMALIZZAZIONE.nomeResponsabile.required}
        />
      </FieldsGroup>
    );
    let cognome = (
      <FieldsGroup
        fieldId={"COGNOME.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
        fieldLabel={"Cognome"}
        fieldRequired={CONTROLLO_CAMPI_FORMALIZZAZIONE.cognomeResponsabile.required}
        showFieldIfRequiredAccordionPage={CONTROLLO_CAMPI_FORMALIZZAZIONE.cognomeResponsabile.required}
      >
        <RoleBasedInput
          fieldId={"COGNOME.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
          id="COGNOME"
          pageState={pageState}
          parentClass={"mb-0"}
          value={progettoAssistenziale?.cognomeResponsabile}
          placeholder="Inserisci cognome"
          field="cognomeResponsabile"
          onChange={(field, elem) => {
            handleChangeProgettoAssistenziale(
              "cognomeResponsabile",
              elem.target.value
            );
          }}
          forceReadOnly={false}
          hideUnsetValues={false}
          disableDoubleInput={false}
          fieldRequired={CONTROLLO_CAMPI_FORMALIZZAZIONE.cognomeResponsabile.required}
        />
      </FieldsGroup>
    );
    return (
      <FieldsRow>
        {nome}
        {cognome}
      </FieldsRow>
    );
  };

  let renderTelefonoCellulare = () => {
    let obbligatorietaTelefono = false;
    let obbligatorietaCellulare = false;
    let valueTelefono = progettoAssistenziale?.telefonoResponsabile;
    let valueCellulare = progettoAssistenziale?.cellulareResponsabile;
    if ((valueTelefono && valueCellulare) || (!valueTelefono && !valueCellulare)) {
        obbligatorietaTelefono = true;
        obbligatorietaCellulare = true;
    } else if (!valueTelefono && valueCellulare) {
        obbligatorietaTelefono = false;
        obbligatorietaCellulare = true;
    } else if (valueTelefono && !valueCellulare) {
        obbligatorietaTelefono = true;
        obbligatorietaCellulare = false;
    }
    let telefono =
        <FieldsGroup
            fieldId={"TELEFONO.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
            fieldLabel={'Telefono'}
            fieldRequired={obbligatorietaTelefono}
            showFieldIfRequiredAccordionPage={obbligatorietaTelefono}>
            <RoleBasedInput
                fieldId={"TELEFONO.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
                id="TELEFONO"
                pageState={pageState}
                parentClass={"mb-0"}
                value={progettoAssistenziale?.telefonoResponsabile}
                placeholder="Inserisci telefono"
                field="telefonoResponsabile"
                onChange={(field, elem) => {
                    handleChangeProgettoAssistenziale("telefonoResponsabile", elem.target.value)
                }}
                forceReadOnly={false}
                hideUnsetValues={false}
                disableDoubleInput={false}
                fieldRequired={obbligatorietaTelefono}
                showFieldIfRequiredAccordionPage={!!valueTelefono || !!valueCellulare}
                disabled={false}
            />
        </FieldsGroup>;
    let cellulare =
        <FieldsGroup
            fieldId={"CELLULARE.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
            fieldLabel={'Cellulare'}
            fieldRequired={obbligatorietaCellulare}
            showFieldIfRequiredAccordionPage={obbligatorietaCellulare}>
            <RoleBasedPhoneInput
                fieldId={"CELLULARE.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
                id="CELLULARE"
                pageState={pageState}
                parentClass={"mb-0"}
                value={progettoAssistenziale?.cellulareResponsabile}
                placeholder="Inserisci cellulare"
                field="cellulareResponsabile"
                onChange={(field, elem) => {
                    handleChangeProgettoAssistenziale("cellulareResponsabile", elem.target.value)
                }}
                forceReadOnly={false}
                hideUnsetValues={false}
                disableDoubleInput={false}
                fieldRequired={obbligatorietaTelefono}
                showFieldIfRequiredAccordionPage={!!valueCellulare || !!valueTelefono}
                disabled={false}
            />
        </FieldsGroup>;
    return (<FieldsRow>
        {telefono}
        {cellulare}
    </FieldsRow>);
};

  let renderFaxEmail = () => {
    let fax = (
      <FieldsGroup
          fieldId={"FAX.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
          fieldLabel={"Fax"}
          fieldRequired={CONTROLLO_CAMPI_FORMALIZZAZIONE.faxResponsabile.required}
          showFieldIfRequiredAccordionPage={CONTROLLO_CAMPI_FORMALIZZAZIONE.faxResponsabile.required}
      >
        <RoleBasedInput
          fieldId={"FAX.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
          id="FAX"
          pageState={pageState}
          parentClass={"mb-0"}
          value={progettoAssistenziale?.faxResponsabile}
          placeholder="Inserisci fax"
          field="faxResponsabile"
          onChange={(field, elem) => {
            handleChangeProgettoAssistenziale(
              "faxResponsabile",
              elem.target.value
            );
          }}
          forceReadOnly={false}
          hideUnsetValues={false}
          disableDoubleInput={false}
          fieldRequired={CONTROLLO_CAMPI_FORMALIZZAZIONE.faxResponsabile.required}
        />
      </FieldsGroup>
    );
    let email = (
      <FieldsGroup
          fieldId={"EMAIL.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
          fieldLabel={"Email"}
          fieldRequired={CONTROLLO_CAMPI_FORMALIZZAZIONE.emailResponsabile.required}
          showFieldIfRequiredAccordionPage={CONTROLLO_CAMPI_FORMALIZZAZIONE.emailResponsabile.required}
      >
        <RoleBasedInput
          fieldId={"EMAIL.RESPONSABILECASOSETTINGRICHIESTO.PROGETTOASSISTENZIALERICHIESTO"}
          id="EMAIL"
          pageState={pageState}
          parentClass={"mb-0"}
          value={progettoAssistenziale?.emailResponsabile}
          placeholder="Inserisci e-mail"
          field="emailResponsabile"
          onChange={(field, elem) => {
            handleChangeProgettoAssistenziale(
              "emailResponsabile",
              elem.target.value
            );
          }}
          forceReadOnly={false}
          hideUnsetValues={false}
          disableDoubleInput={false}
          fieldRequired={CONTROLLO_CAMPI_FORMALIZZAZIONE.emailResponsabile.required}
        />
      </FieldsGroup>
    );
    return (
      <FieldsRow>
        {fax}
        {email}
      </FieldsRow>
    );
  };

  return (
    <Fragment>
      <AccordionSezioneRichiesta
        idAccordion={"responsabileCasoSettingRichiesto"}
        title={"Responsabile del caso nel setting richiesto"}
        required={true}
        openAccordion={openAccordion}
        onClickAccordion={onClickAccordion}
        field={field}
        pageState={pageState}
      >
        <FieldsRow>{ruoloResponsabile}</FieldsRow>
        {renderNomeCognome()}
        {renderTelefonoCellulare()}
        {renderFaxEmail()}
      </AccordionSezioneRichiesta>
    </Fragment>
  );
}

ResponsabileDelCasoNelSettingRichiesto.propTypes = {
  openAccordion: PropTypes.bool,
  onClickAccordion: PropTypes.func,
  field: PropTypes.string,
  pageState: PropTypes.string,
  progettoAssistenziale: PropTypes.node,
};

ResponsabileDelCasoNelSettingRichiesto.defaultProps = {
  openAccordion: false,
  onClickAccordion: null,
  field: "",
  pageState: "",
  progettoAssistenziale: {},
};

export default ResponsabileDelCasoNelSettingRichiesto;
