import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import LassativiMicroclismi, {defaultLassativiMicroclismi} from "./LassativiMicroclismi/LassativiMicroclismi";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import SessionRequest from "../../../utils/SessionRequest";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import {
    defaultAltro,
    defaultFarmaco, defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    dati: PropTypes.any,
    onChangeDatiAlvoEliminazioneFecale: PropTypes.func,
    pageState: PropTypes.string,
    isOptional: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultTipologia = {
    id: null,
    tipologia: null
}

export default class AlvoEliminazioneFecale extends AccordionSecondLevel {

    state = {
        alvoEliminazioneFecale: _.cloneDeep(this.props.dati)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        const alvoEliminazioneFecale = _.cloneDeep(this.props.dati);
        this.setState({alvoEliminazioneFecale}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index, subField) => {

        this.setState(({alvoEliminazioneFecale}) => {
            if (index != null) {
                alvoEliminazioneFecale[field][index][subField] = elem;
            } else {
                alvoEliminazioneFecale[field] = elem;
            }

            if (alvoEliminazioneFecale.isRegolare !== false) {
                alvoEliminazioneFecale.tipologiaAlterazione = [];
            }

            if (!alvoEliminazioneFecale.isTurbe) {
                alvoEliminazioneFecale.tipologiaTurbe = [];
            }

            if (!alvoEliminazioneFecale.isUsoLassativo) {
                alvoEliminazioneFecale.lassativo = [];
            }

            if (!alvoEliminazioneFecale.isUsoMicroclismi) {
                alvoEliminazioneFecale.microclisma = [];
            }

            if (!alvoEliminazioneFecale.isIncontinenza) {
                alvoEliminazioneFecale.tipologiaIncontinenza = null;
            }

            if (!this.showCondizioneRilevataSection(alvoEliminazioneFecale)) {
                alvoEliminazioneFecale.condizioneRilevata = null;
            }

            return {alvoEliminazioneFecale};
        }, this.validazioneDati);
    }

    handleArrayDispositiviChange = (array, field) => {
        let data = _.cloneDeep(this.state.alvoEliminazioneFecale);
        data[field] = array;
        this.setState({alvoEliminazioneFecale: data}, () => {
            this.props.onChangeDatiAlvoEliminazioneFecale("alvoEliminazioneFecale", data);
            this.forceUpdate();
        });
    }

    validazioneDati = () => {
        let alvoEliminazioneFecale = _.cloneDeep(this.state.alvoEliminazioneFecale);
        this.props.onChangeDatiAlvoEliminazioneFecale("alvoEliminazioneFecale", alvoEliminazioneFecale);

        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(alvoEliminazioneFecale?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneAlvoEliminazioneFecale", campiObbligatoriRimanenti);
    }

    addNewTipologiaTurbe = () => {
        let alvoEliminazioneFecale = _.cloneDeep(this.state.alvoEliminazioneFecale);
        let tipologia = _.cloneDeep(defaultTipologia);
        tipologia.id = uuid();
        if (!alvoEliminazioneFecale.tipologiaTurbe) alvoEliminazioneFecale.tipologiaTurbe = []
        alvoEliminazioneFecale.tipologiaTurbe.push(tipologia);
        this.setState({alvoEliminazioneFecale: alvoEliminazioneFecale}, () => this.validazioneDati());
    }

    removeTipologiaTurbe = (index) => {
        let alvoEliminazioneFecale = _.cloneDeep(this.state.alvoEliminazioneFecale);
        alvoEliminazioneFecale.tipologiaTurbe.splice(index, 1);
        this.setState({alvoEliminazioneFecale: alvoEliminazioneFecale}, () => this.validazioneDati());
    }

    addNewTipologiaForField = (field) => {
        let alvoEliminazioneFecale = _.cloneDeep(this.state.alvoEliminazioneFecale);
        if (!alvoEliminazioneFecale[field]) alvoEliminazioneFecale[field] = []
        alvoEliminazioneFecale[field].push({..._.cloneDeep(defaultTipologia), id: uuid()});
        this.setState({alvoEliminazioneFecale: alvoEliminazioneFecale}, () => this.validazioneDati());
    }

    removeTipoAlterazioneForField = (index, field) => {
        let alvoEliminazioneFecale = _.cloneDeep(this.state.alvoEliminazioneFecale);
        alvoEliminazioneFecale[field].splice(index, 1);
        this.setState({alvoEliminazioneFecale: alvoEliminazioneFecale}, () => this.validazioneDati());
    }

    renderTipoAlterazione = (typeAlt) => {
        const {tipologiaAlterazione} = this.state.alvoEliminazioneFecale;
        const filterTypeAlterazione = (item) => {
            return !(tipologiaAlterazione?.find(element => element.tipologia === item.value))
        }
        const options = OggettiUtili.tipologiaAlterazione.filter(filterTypeAlterazione);
        if (typeAlt?.tipologia) options.push(OggettiUtili.tipologiaAlterazione.find(e => e?.value === typeAlt?.tipologia));
        return <FieldsRow>
            <RoleBasedSelect
                fieldId={'M_TIPO_ALT.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                infermiereFieldId={'I_TIPO_ALT.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                pageState={this.props.pageState}
                id={"selectTipologiaAlterazione"}
                options={options}
                value={typeAlt?.tipologia}
                onChange={(elem) => this.handleAggiornaForm("tipologiaAlterazione", _.cloneDeep(this.state.alvoEliminazioneFecale).tipologiaAlterazione.map(function (typeAltItem) {
                    if (typeAlt.id === typeAltItem.id)
                        return {...typeAlt, tipologia: elem}
                    return typeAltItem
                }))}
                isSearchable={false}
                noOptionsMessage={() => "Errore: Tipologia alterazione non esistente"}
                fieldLabel={"Tipologia alterazione"}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                disableDoubleInput={this.props.disableDoubleInput}
                handleOnlyValue={true}
                field={"tipologia"}
            />
        </FieldsRow>;
    }

    renderTipologiaIncontinenza = (typeInc) => {
        const {tipologiaIncontinenza} = this.state.alvoEliminazioneFecale;
        const options = OggettiUtili.tipologiaIncontinenza.filter(item => !(tipologiaIncontinenza?.find(element => element.tipologia === item.value)));
        if (typeInc?.tipologia) options.push(OggettiUtili.tipologiaIncontinenza.find(e => e?.value === typeInc?.tipologia));
        return <FieldsRow>
            <RoleBasedSelect
                fieldId={'M_TIPO_INCONT.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                infermiereFieldId={'I_TIPO_INCONT.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                pageState={this.props.pageState}
                id={"selectTipologiaIncontinenza"}
                options={options}
                value={typeInc.tipologia}
                onChange={(elem) => this.handleAggiornaForm("tipologiaIncontinenza", _.cloneDeep(this.state.alvoEliminazioneFecale).tipologiaIncontinenza.map(function (typeIncItem) {
                    if (typeInc.id === typeIncItem.id)
                        return {...typeInc, tipologia: elem}
                    return typeIncItem
                }))}
                isSearchable={false}
                noOptionsMessage={() => "Errore: Tipologia Incontinenza non esistente"}
                fieldLabel={"Tipologia"}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                disableDoubleInput={this.props.disableDoubleInput}
                handleOnlyValue={true}
                field={"tipologia"}
            />
        </FieldsRow>;
    }

    renderTipologiaTurbe = (tipologiaTurbe, index) => {
        return <FieldsRow>
            <RoleBasedInput
                fieldId={'M_TIPO_TURBE.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                infermiereFieldId={'I_TIPO_TURBE.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                pageState={this.props.pageState}
                id={"tipologiaTurbe"}
                value={tipologiaTurbe.tipologia}
                placeholder={"Tipologia turbe"}
                field={"tipologia"}
                onChange={(field, elem) => this.handleAggiornaForm("tipologiaTurbe", elem.target.value, index, "tipologia")}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                disableDoubleInput={this.props.disableDoubleInput}
            />
        </FieldsRow>;
    }

    renderBody = () => {
        let alvoEliminazioneFecale = this.state.alvoEliminazioneFecale;
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_REGOLARE.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_REGOLARE.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                        pageState={this.props.pageState}
                        id={"selectRegolare"}
                        className={"selectRegolare"}
                        options={OggettiUtili.rispostaBreve}
                        value={alvoEliminazioneFecale.isRegolare}
                        onChange={(elem) => this.handleAggiornaForm("isRegolare", elem)}
                        isSearchable={false}
                        fieldLabel={"Regolare"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"isRegolare"}
                    />
                </FieldsRow>

                {alvoEliminazioneFecale.isRegolare === false &&
                    <SectionList
                        title={"Tipologia alterazione"}
                        data={alvoEliminazioneFecale?.tipologiaAlterazione || []}
                        renderSection={(e, i) => this.renderTipoAlterazione(e)}
                        addNewSectionCallback={() => this.addNewTipologiaForField("tipologiaAlterazione")}
                        removeSectionCallback={(i) => this.removeTipoAlterazioneForField(i, "tipologiaAlterazione")}
                        pageState={this.props.pageState}
                        keyFieldId={'M_TIPO_ALT.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                        infermiereKeyFieldId={'I_TIPO_ALT.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        indentOffset={1}
                        field={'tipologiaAlterazione'}
                        atLeastOne={true}
                        addButtonVisibilityHandler={alvoEliminazioneFecale?.tipologiaAlterazione?.length === 0
                            || alvoEliminazioneFecale?.tipologiaAlterazione?.every(el => !Utils.isObjectNull(el?.tipologia))}
                        parentJsonPath={"anamnesiFisiologica.alvoEliminazioneFecale.tipologiaAlterazione"}
                    />}

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_TURBE_COMP_ELIMINAZ.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_TURBE_COMP_ELIMINAZ.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                        pageState={this.props.pageState}
                        id={"modificheTurbe"}
                        className={"selectModificheTurbe"}
                        options={OggettiUtili.rispostaBreve}
                        value={alvoEliminazioneFecale.isTurbe}
                        onChange={(elem) => this.handleAggiornaForm("isTurbe", elem)}
                        isSearchable={false}
                        fieldLabel={"Turbe del comportamento legate all'eliminazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"isTurbe"}
                    />
                </FieldsRow>

                {alvoEliminazioneFecale.isTurbe &&
                    <SectionList
                        title={'Tipologia turbe'}
                        data={alvoEliminazioneFecale.tipologiaTurbe}
                        renderSection={this.renderTipologiaTurbe}
                        addNewSectionCallback={this.addNewTipologiaTurbe}
                        removeSectionCallback={this.removeTipologiaTurbe}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        hideUnsetValues={this.props.hideUnsetValues}
                        keyFieldId={'M_TIPO_TURBE.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                        infermiereKeyFieldId={'I_TIPO_TURBE.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                        field={'tipologiaTurbe'}
                        addButtonVisibilityHandler={alvoEliminazioneFecale?.tipologiaTurbe?.length === 0
                            || alvoEliminazioneFecale?.tipologiaTurbe?.every(el => !Utils.isObjectNull(el?.tipologia))
                        }
                        atLeastOne={true}
                        parentJsonPath={"anamnesiFisiologica.alvoEliminazioneFecale.tipologiaTurbe"}
                    />}

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_RITE_FECI.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_RITE_FECI.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                        pageState={this.props.pageState}
                        id={"modificheRitenzione"}
                        className={"selectModificheRitenzione"}
                        options={OggettiUtili.rispostaBreve}
                        value={alvoEliminazioneFecale.isRitenzioneFeci}
                        onChange={(elem) => this.handleAggiornaForm("isRitenzioneFeci", elem)}
                        isSearchable={false}
                        fieldLabel={"Ritenzione di feci"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"isRitenzioneFeci"}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_LASS_OS.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_LASS_OS.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                        pageState={this.props.pageState}
                        id={"usoLassativi"}
                        className={"selectUsoLassativi"}
                        options={OggettiUtili.rispostaBreve}
                        value={alvoEliminazioneFecale.isUsoLassativo}
                        onChange={(elem) => this.handleAggiornaForm("isUsoLassativo", elem)}
                        isSearchable={false}
                        fieldLabel={"Uso di lassativo per OS"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"isUsoLassativo"}
                    />
                </FieldsRow>

                {alvoEliminazioneFecale.isUsoLassativo &&
                    <LassativiMicroclismi
                        label={"lassativo"}
                        field={'lassativo'}
                        onChangeLassativiMicroclisma={this.handleAggiornaForm}
                        lassativiMicroclismi={alvoEliminazioneFecale.lassativo}
                        usoLassativi={alvoEliminazioneFecale.isUsoLassativo}
                        pageState={this.props.pageState}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        parentJsonPath={"anamnesiFisiologica.alvoEliminazioneFecale.lassativo"}
                    />}

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_USO_MICRO.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_USO_MICRO.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                        pageState={this.props.pageState}
                        id={"usoMicroclismi"}
                        className={"selectUsoMicroclismi"}
                        options={OggettiUtili.rispostaBreve}
                        value={alvoEliminazioneFecale.isUsoMicroclismi}
                        onChange={(elem) => this.handleAggiornaForm("isUsoMicroclismi", elem)}
                        isSearchable={false}
                        fieldLabel={"Uso di microclismi"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"isUsoMicroclismi"}
                    />
                </FieldsRow>

                {alvoEliminazioneFecale.isUsoMicroclismi &&
                    <LassativiMicroclismi
                        label={"microclisma"}
                        field={'microclisma'}
                        onChangeLassativiMicroclisma={this.handleAggiornaForm}
                        lassativiMicroclismi={alvoEliminazioneFecale.microclisma}
                        pageState={this.props.pageState}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        parentJsonPath={"anamnesiFisiologica.alvoEliminazioneFecale.microclisma"}
                    />}

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_INCONT.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_INCONT.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                        pageState={this.props.pageState}
                        id={"isIncontinenza"}
                        className={"isIncontinenza"}
                        options={OggettiUtili.rispostaBreve}
                        value={alvoEliminazioneFecale.isIncontinenza}
                        onChange={(elem) => this.handleAggiornaForm("isIncontinenza", elem)}
                        isSearchable={false}
                        fieldLabel={"Incontinenza"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"isIncontinenza"}
                    />

                </FieldsRow>

                {alvoEliminazioneFecale.isIncontinenza &&
                    <SectionList
                        title={"Tipologia"}
                        data={alvoEliminazioneFecale?.tipologiaIncontinenza || []}
                        renderSection={(e, i) => this.renderTipologiaIncontinenza(e)}
                        addNewSectionCallback={() => this.addNewTipologiaForField("tipologiaIncontinenza")}
                        removeSectionCallback={(i) => this.removeTipoAlterazioneForField(i, "tipologiaIncontinenza")}
                        pageState={this.props.pageState}
                        keyFieldId={'M_TIPO_INCONT.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                        infermiereKeyFieldId={'I_TIPO_INCONT.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        indentOffset={1}
                        field={'tipologiaIncontinenza'}
                        atLeastOne={true}
                        addButtonVisibilityHandler={alvoEliminazioneFecale?.tipologiaIncontinenza?.length === 0
                            || alvoEliminazioneFecale?.tipologiaIncontinenza?.every(el => !Utils.isObjectNull(el?.tipologia))}
                        parentJsonPath={"anamnesiFisiologica.alvoEliminazioneFecale.tipologiaIncontinenza"}
                    />
                }

                <DispositiviPresenti
                    handleArrayChange={this.handleArrayDispositiviChange}
                    dispositiviPresenti={alvoEliminazioneFecale.dispositiviPresenti}
                    pageState={this.props.pageState}
                    fieldId={"M_DISP.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA"}
                    infermiereFieldId={"I_DISP.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    parentJsonPath={"anamnesiFisiologica.alvoEliminazioneFecale.dispositiviPresenti"}
                />

                <CondizioneRilevataSection
                    ambito={'ALVO_ELIMINAZIONE_FECALE'}
                    parentFieldId={'ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                    appendRolePrefix={true}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={alvoEliminazioneFecale.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={alvoEliminazioneFecale.daAttenzionare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={alvoEliminazioneFecale.propostaAttivitaSuggerimenti}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={alvoEliminazioneFecale.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={alvoEliminazioneFecale.autonomiaGestioneCaregiver}
                    openCondition={this.showCondizioneRilevataSection(alvoEliminazioneFecale)}
                />

                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'M_DATA_VAL.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_DATA_VAL.ALVOELIMINAZIONEFECALE.FUNZIONEGASTROINTESTINALE'}
                        pageState={this.props.pageState}
                        id={"alvoEliminazioneFecaleDataValutazione"}
                        value={alvoEliminazioneFecale.dataValutazione}
                        onChange={(elem) => this.handleAggiornaForm("dataValutazione", elem.target.value)}
                        errorMessage={"Data valutazione non valida"}
                        fieldLabel={"Data Valutazione"}
                        field={"dataValutazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
            </>
        );
    }

    onHide = () => {
        const alvoEliminazioneFecale = _.cloneDeep(SessionRequest.getInitObject('anamnesiFisiologica.alvoEliminazioneFecale'));
        this.setState({alvoEliminazioneFecale: alvoEliminazioneFecale},
            () => {
                let alvoEliminazioneFecaleProps = _.cloneDeep(alvoEliminazioneFecale);
                this.props.onChangeDatiAlvoEliminazioneFecale("alvoEliminazioneFecale", alvoEliminazioneFecaleProps);
                this.props.onHide();
            });
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"alvoEliminazioneFecale"}
            title={"Alvo/Eliminazione fecale"}
            required={false}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            onHide={this.onHide}
            isOptional={this.props.isOptional && isFieldIdWriteable('M_TIPO_ALT.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA', this.props.pageState, this.context.forceUserRole)}
            fieldsOrderMap={Utils.generateMapPathsObjects(
                ["anamnesiFisiologica.alvoEliminazioneFecale.tipologiaAlterazione",
                    "anamnesiFisiologica.alvoEliminazioneFecale.tipologiaTurbe", "anamnesiFisiologica.alvoEliminazioneFecale.lassativo",
                    "anamnesiFisiologica.alvoEliminazioneFecale.microclisma", "anamnesiFisiologica.alvoEliminazioneFecale.tipologiaIncontinenza",
                    "anamnesiFisiologica.alvoEliminazioneFecale.dispositiviPresenti",
                    "anamnesiFisiologica.alvoEliminazioneFecale.propostaAttivitaSuggerimenti"],
                [defaultTipologia, defaultTipologia, defaultLassativiMicroclismi, defaultLassativiMicroclismi, defaultTipologia, defaultDispositiviPresenti,
                    defaultProposteAttivitaSuggerimenti]
            )}
            accordionReadOnly={this.props.accordionReadOnly}
            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
    }

    showCondizioneRilevataSection(alvoEliminazioneFecale) {
        return (alvoEliminazioneFecale.isRegolare === false || alvoEliminazioneFecale.isTurbe
            || alvoEliminazioneFecale.isIncontinenza || alvoEliminazioneFecale.isRitenzioneFeci
            || alvoEliminazioneFecale.isUsoLassativo || alvoEliminazioneFecale.isUsoMicroclismi
            || alvoEliminazioneFecale.isIncontinenza);
    }
}
AlvoEliminazioneFecale.propTypes = propTypes;

AlvoEliminazioneFecale.defaultProps = {
    isOptional: true,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

AlvoEliminazioneFecale.contextType = RoleBasedContext;
