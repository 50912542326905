import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {FieldsRow, RoleBasedInput, RoleBasedSelect} from "../../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../../utils/dataset/OggettiUtili.json";

const propTypes = {
    dati: PropTypes.object,
    onChange: PropTypes.func,
    pageState: PropTypes.string,
    fieldInAccordionReadOnly: PropTypes.bool
}

export default class TracheostomiaLaringectomia extends Component {

    state = {
        cannulaFenestrata: null,
        diametroCannula: null,
        cuffiaggio: null,
        pressioneCuffiaggio: null,
        presenzaControcanula: null,
        presenzaValvolaFonatoria: null,
        dispositiviPerSupportoRespiratorio: null,
        altroDispositivoPerSupportoRespiratorio: null,
        note: null,
        modelloPresidio: null
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati)) this.inizializzaForm();
    }

    inizializzaForm = () => {
        let state = _.cloneDeep(this.props.dati);
        if (state == null) state = _.cloneDeep(this.state);
        this.setState(state);
    }

    handleAggiornaForm = (field, elem) => {
        let state = _.cloneDeep(this.state);

        if (field === "cannulaFenestrata" && !_.isEqual(state.cannulaFenestrata, elem)) state.diametroCannula = null;

        if (field === "cuffiaggio" && !_.isEqual(state.cuffiaggio, elem)) state.pressioneCuffiaggio = null;

        if (field === "dispositiviPerSupportoRespiratorio" && !_.isEqual(state.dispositiviPerSupportoRespiratorio, "05"))
            state.altroDispositivoPerSupportoRespiratorio = null;

        state[field] = new RegExp(/^[-]\d+$/g).test(elem) ? "0" : elem;
        this.setState(state, () => this.props.onChange("tracheostomiaLaringectomia", this.state));
    }

    render() {
        return <>
            <FieldsRow colNumber={4}>
                <RoleBasedSelect
                    id={"selectCannulaFenestrata"}
                    fieldId={'CAN_FENESTR_TRACHEO.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"cannulaFenestrata"}
                    fieldLabel={"Cannula Fenestrata"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.rispostaBreve}
                    value={this.state.cannulaFenestrata}
                    onChange={(elem) => this.handleAggiornaForm("cannulaFenestrata", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                {this.state.cannulaFenestrata &&
                <RoleBasedInput
                    id={"inputDiametroCannula"}
                    fieldId={'DIM_CAN_TRACHEO.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Dimensioni cannula (diametro)"}
                    field={"diametroCannula"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci diametro cannula"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.diametroCannula}
                    type={"number"}
                    min={"0"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />}
                <RoleBasedSelect
                    id={"selectCuffiaggio"}
                    fieldId={'CUFF_TRACHEO.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"cuffiaggio"}
                    fieldLabel={"Cuffiaggio"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.rispostaBreve}
                    value={this.state.cuffiaggio}
                    onChange={(elem) => this.handleAggiornaForm("cuffiaggio", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                {this.state.cuffiaggio &&
                <RoleBasedInput
                    id={"inputPressioneCuffiaggio"}
                    fieldId={'PRESS_CUFF_TRACHEO.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Pressione cuffiaggio"}
                    field={"pressioneCuffiaggio"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci pressione cuffiaggio"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.pressioneCuffiaggio}
                    type={"number"}
                    min={"0"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />}
            </FieldsRow>
            <FieldsRow colNumber={4}>
                <RoleBasedSelect
                    id={"selectPresenzaControcanula"}
                    fieldId={'PRES_CONTROC_TRACHEO.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"presenzaControcanula"}
                    fieldLabel={"Presenza controcanula"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.rispostaBreve}
                    value={this.state.presenzaControcanula}
                    onChange={(elem) => this.handleAggiornaForm("presenzaControcanula", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectPresenzaValvolaFonatoria"}
                    fieldId={'PRES_VALV_FONAT_TRACHEO.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"presenzaValvolaFonatoria"}
                    fieldLabel={"Presenza valvola fonatoria"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.rispostaBreve}
                    value={this.state.presenzaValvolaFonatoria}
                    onChange={(elem) => this.handleAggiornaForm("presenzaValvolaFonatoria", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectDispositiviSupportoRespiratorio"}
                    fieldId={'UTIL_DISP_SUPP_RESP_TRACHEO.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"dispositiviPerSupportoRespiratorio"}
                    fieldLabel={"Utilizzo dispositivi per il supporto respiratorio"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.dispositiviPerSupportoRespiratorio}
                    value={this.state.dispositiviPerSupportoRespiratorio}
                    onChange={(elem) => this.handleAggiornaForm("dispositiviPerSupportoRespiratorio", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                {this.state.dispositiviPerSupportoRespiratorio === "05" &&
                <RoleBasedInput
                    id={"inputAltroDispositivoSupportoRespiratorio"}
                    fieldId={'UTIL_DISP_SUPP_RESP_TRACHEO_ALTRO.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Altro"}
                    field={"altroDispositivoPerSupportoRespiratorio"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci altro dispositivo per supporto respiratorio"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.altroDispositivoPerSupportoRespiratorio}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />}
            </FieldsRow>
            <FieldsRow>
                <RoleBasedInput
                    id={"inputNote"}
                    fieldId={'NOTE_TRACHEO.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Note"}
                    field={"note"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci note"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.note}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedInput
                    id={"inputModelloPresidio"}
                    fieldId={'MODEL_PRES_TRACHEO.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Modello presidio"}
                    field={"modelloPresidio"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci modello presidio"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.modelloPresidio}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
        </>;
    }
}

TracheostomiaLaringectomia.propTypes = propTypes;
