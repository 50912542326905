import React from 'react'
import styles from './ServizioNonDisponibile.module.css'

export default class ServizioNonDisponibile extends React.Component {

    getMessageFromCode = () => {

        const queryString = require('query-string');
        const parsed = queryString.parse(window.location.search);
        if (parsed.param && parsed.param === "0001") {
            return "Servizio di autenticazione non disponibile, riprovare più tardi"
        } else
            return ""
    }

    render() {
        return (
            <div className={styles.notAuthContainer.concat(" ml-5")}>
                <img style={{height: "200", width: "200"}}
                     src={require("../../img/servizioNonDisponibile/ServizioNonDisponibile.jpg")}
                     alt={"Servizio Non Disponibile"}/>
                <h1>{this.getMessageFromCode()}</h1>
            </div>
        );
    }
}
