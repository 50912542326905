import React from "react";
import OggettiUtili from "../../../../utils/dataset/OggettiUtili.json";
import * as _ from "lodash";
import PropTypes from "prop-types";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import { FieldsRow, RoleBasedSelect, } from "../../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../../utils/Utils";
import statiRichiesta from "../../../../utils/dataset/richiestaData/statiRichiesta.json";
import {isArray} from "lodash";

const propTypes = {
    dati: PropTypes.any,
    figureProfessionali: PropTypes.array,
    professionistiSupportoAValutazione: PropTypes.node,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onChangeLivelloPriorita: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    richiesta: PropTypes.any,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class LivelloPrioritaRichiestaSub extends React.Component {
    state = {
        livelloPriorita: _.cloneDeep(this.props.dati),
    };

    componentDidMount() {
        if (this.isPresentOnlyMMGorPLS(this.props.figureProfessionali)) {
            this.setLivelloPriorita(OggettiUtili.livelloPrioritaRichiesta[0].value);
        }
        this.props.onChangeRequiredFieldsNumber((Utils.isStateRichiesta(this.props.pageState) || Utils.isStateChiusuraPreDialogo(this.props.pageState)) && !this.state.livelloPriorita ? 1 : 0);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps, this.props)) {
            if (this.isPresentOnlyMMGorPLS(prevProps.figureProfessionali) && !this.isPresentOnlyMMGorPLS(this.props.figureProfessionali))
                this.setLivelloPriorita(null);
            else if (!this.isPresentOnlyMMGorPLS(prevProps.figureProfessionali) && this.isPresentOnlyMMGorPLS(this.props.figureProfessionali))
                this.setLivelloPriorita(OggettiUtili.livelloPrioritaRichiesta[0].value);
        }
    }

    handleAggiornaForm = (field, elem) => {
        this.setState({ livelloPriorita: elem }, () => {
            this.props.onChangeLivelloPriorita(field, elem);
            this.props.onChangeRequiredFieldsNumber(Utils.isStateRichiesta(this.props.pageState) && !this.state.livelloPriorita ? 1 : 0);
        });
    }

    isPresentOnlyMMGorPLS(figureProfessionali) {
        const figureRichieste = this.props.professionistiSupportoAValutazione && isArray(this.props.professionistiSupportoAValutazione) && this.props.professionistiSupportoAValutazione.length
            ? this.props.professionistiSupportoAValutazione[0].figureRichieste
            : [];

        const PresentOnlyToPAI = Array.isArray(figureProfessionali) && figureProfessionali.length === 1
            && (figureProfessionali[0].figuraProfessionale === OggettiUtili.figureProfessionaliPerPAI[0].value || figureProfessionali[0].figuraProfessionale === OggettiUtili.figureProfessionaliPerPAI[1].value);

        const mmgPresentOnlyToSupportoAValutazione = !figureRichieste.length || (Array.isArray(figureRichieste) && figureRichieste.length === 1
            && (figureRichieste[0].ruolo === OggettiUtili.figureProfessionaliPerValutazione[0].value || figureRichieste[0].ruolo === OggettiUtili.figureProfessionaliPerValutazione[1].value));

        return PresentOnlyToPAI && mmgPresentOnlyToSupportoAValutazione;

    }

    setLivelloPriorita(value) {
        this.handleAggiornaForm(this.checkChiusuraPreDialogo() ? "livelloPrioritaBozza" : "livelloPriorita", value);
        this.props.onChangeRequiredFieldsNumber((Utils.isStateRichiesta(this.props.pageState) || Utils.isStateChiusuraPreDialogo(this.props.pageState)) && !this.state.livelloPriorita ? 1 : 0);
    }

    checkAttivazionePreDialogo() {
        let richiesta = this.props.richiesta;
        return richiesta.stato === statiRichiesta.bozzaPreDialogo.value || richiesta.stato === statiRichiesta.inviataPreDialogo.value
            || richiesta.stato === statiRichiesta.inConferimentoPreDialogo.value || richiesta.stato === statiRichiesta.inValutazionePreDialogo.value
            || richiesta.stato === statiRichiesta.valutataPreDialogo.value
    }

    checkChiusuraPreDialogo() {
        let richiesta = this.props.richiesta;
        return richiesta.stato === statiRichiesta.confermataPreDialogo.value || richiesta.stato === statiRichiesta.inChiusuraPreDialogo.value
    }

    render() {
        const { livelloPriorita } = this.state
        let readOnly = this.isPresentOnlyMMGorPLS(this.props.figureProfessionali) && livelloPriorita != null;
        return (
            <AccordionSezioneRichiesta
                idAccordion={"prioritaRichiestaCureDomiciliari"}
                title={"Priorità della richiesta di cure domiciliari"}
                required={Utils.isStateRichiesta(this.props.pageState) || Utils.isStateChiusuraPreDialogo(this.props.pageState)}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={this.checkChiusuraPreDialogo() || !Utils.isDimissioneProtetta(this.props.pageState)
                            ? "LIVELLO_DI_PRIORITA_TERR.PRIORITADELLARICHIESTADICUREDOMICILIARI.RICHIESTACAMBIOSETTING"
                            : "LIVELLO_DI_PRIORITA_DIMPROT.PRIORITADELLARICHIESTADICUREDOMICILIARI.RICHIESTACAMBIOSETTING"}
                        pageState={this.props.pageState}
                        id={"selectLivelloPriorita"}
                        className={"selectLivelloPriorita"}
                        options={this.checkAttivazionePreDialogo() ? OggettiUtili.livelloPrioritaAttivazionePreDialogo : OggettiUtili.livelloPrioritaRichiesta}
                        value={livelloPriorita}
                        onChange={(element) => (this.handleAggiornaForm(this.checkChiusuraPreDialogo() ? "livelloPrioritaBozza" : "livelloPriorita", element))}
                        isSearchable={false}
                        fieldLabel={'Livello Priorità'}
                        field={this.checkChiusuraPreDialogo() ? "livelloPrioritaBozza" : "livelloPriorita"}
                        disableDoubleInput={true}
                        handleOnlyValue={true}
                        fieldRequired={!Utils.isDimissioneProtetta(this.props.pageState) || Utils.isStateChiusuraPreDialogo(this.props.pageState)}
                        forceReadOnly={readOnly}
                        forceFieldRequiredVisual={this.isPresentOnlyMMGorPLS(this.props.figureProfessionali)}
                    />
                </FieldsRow>
            </AccordionSezioneRichiesta>
        )
    }
}

LivelloPrioritaRichiestaSub.propTypes = propTypes;
