import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Autocomplete.module.css'
import $ from 'jquery'
import _ from 'lodash'
import {Util} from 'reactstrap'

const {mapToCssModules} = Util;

/*
* Spiegazione (parziale) delle props:
*
*   suggestions: array (di stringe o oggetti), gli elementi dell'autocomplete
*   value: (opzionale) l'elemento pre-selezionato
*   onChange: funzione con due parametri (field, elem); field è la props field passata in input; elem è l'elemento selezionato, contenuto dentro target.value
*
*   keyValue: (opzionale) da usare di array di oggetti; indica il campo degli oggetti da considerare chiave
*   descriptionValue: (opzionale) da usare di array di oggetti; indica il campo degli oggetti da considerare descrizione
*   onSelectCode: funzione con un parametro (elem); elem è il valore dell'attributo chiave dell'elemento selezionato (da usare in coppia con keyValue)
*   onSelectDescription: funzione con un parametro (elem); elem è il valore dell'attributo descrizione dell'elemento selezionato (da usare in coppia con descriptionValue)
*   onSelect: funzione con un parametro (elem); elem è il valore dell'elemento selezionato
*
*   infoText: stringa; il testo da mostrare sotto il campo autocomplete
*   noSuggestions: stringa; il testo da mostrare (al posto di infoText) in caso di autocomplete fallito
*   searchFromStart: boolean; specifica se effettuare la ricerca dall' inizio della stringa; di default è true; se false la ricerca viene effettuata all'interno della stringa

*   addCodiceToDescription: boolean; aggiunge il codice nella descrizione del singolo suggerimento
*
*   noOptionsMessageInDropdown: stringa; il testo da mostrare nella dropdown in caso di autocomplete fallito
*
*   forceUpdate: funzione; forza la renderizzazione dell'autocomplete e delle sue suggestions
*/

const propTypes = {
    type: PropTypes.string,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    value: PropTypes.any,
    id: PropTypes.string,
    infoText: PropTypes.string,
    onSelect: PropTypes.func,
    onSelectCode: PropTypes.func,
    onSelectDescription: PropTypes.func,
    customFilter: PropTypes.func,
    onValidate: PropTypes.func,
    innerRef: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
        PropTypes.string
    ]),
    className: PropTypes.any,
    suggestions: PropTypes.any.isRequired,
    styleErrorMessage: PropTypes.string,
    cssModule: PropTypes.object,
    errorMessage: PropTypes.string,
    showSuggestions: PropTypes.bool,
    descriptionField: PropTypes.string,
    suggestionField: PropTypes.string,
    noSuggestions: PropTypes.string,
    searchFromStart: PropTypes.bool,
    onChange: PropTypes.func,
    positionStartSearch: PropTypes.number,
    field: PropTypes.string,
    keyField: PropTypes.string,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    showAlertError: PropTypes.func,
    addCodiceToDescription: PropTypes.bool,
    noOptionsMessageInDropdown: PropTypes.string,
    forceUpdate: PropTypes.func
};

const defaultProps = {
    type: 'text',
    autoComplete: 'off',
    noSuggestions: 'Nessun suggerimento disponibile',
    activeSuggestion: 0,
    positionStartSearch: 1,
    searchFromStart: true,
    showAlertError: () => null,
    addCodiceToDescription: false
};

const keyCodes = {
    tab: 9,
    enter: 13,
    upArrow: 38,
    downArrow: 40
};

export default class Autocomplete extends Component {

    state = {
        isFocused: false,
        hidden: true,
        icon: true,
        className: this.props.className,
        // The active selection's index
        activeSuggestion: 0,
        // The suggestions that match the user's input
        filteredSuggestions: [],
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        value: this.props.value,
        hasChanged: false,
        searchString: (this.props.value?.[this.props.descriptionField]) ?? ''
    }

    ref = React.createRef();

    convertKeysToLowerCase = (obj) => {
        var output = {};
        for (let i in obj) {
            if (Object.prototype.toString.apply(obj[i]) === '[object Object]') {
                output[i.toLowerCase()] = this.convertKeysToLowerCase(obj[i])
            } else if (Object.prototype.toString.apply(obj[i]) === '[object Array]') {
                output[i.toLowerCase()] = [];
                output[i.toLowerCase()].push(this.convertKeysToLowerCase(obj[i][0]))
            } else {
                output[i.toLowerCase()] = obj[i]
            }
        }
        return output
    }

    parseSuggestion = (suggestions) => {
        if (typeof suggestions === 'string') {
            return JSON.parse(suggestions)
        } else {
            return this.convertKeysToLowerCase(suggestions)
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        // only update chart if the data has changed
        if (!_.isEqual(prevProps.value, this.props.value)) {
            this.setState({
                className: this.removeErrorClass(),
                errorMessage: '',
                styleErrorMessage: 'none',
                value: this.props.value,
                searchString: (this.props.value?.[this.props.descriptionField]) ?? ''
            }, () => {
                if (this.props.onValidate) {
                    let ret = this.props.onValidate();
                    if (ret) {
                        if (ret === true) {
                            this.setState({className: this.addErrorClass()})
                        } else if (typeof (ret) === 'string') {
                            this.setState({
                                className: this.addErrorClass(),
                                errorMessage: ret,
                                styleErrorMessage: 'inline'
                            })
                        }
                    }
                }

                //Il componente invia come valore all'onChange leggendo dalla filteredSuggestions; se si modifica props.value con delle logiche di precompilazione esterne, occorre aggiornare il valore inviato dall'onChange
                if (this.state.filteredSuggestions.length > 0 && this.state.filteredSuggestions[0].codice !== this.props.value?.codice && this.props.value?.codice) {
                    this.setState({filteredSuggestions: [this.props.value]})
                }
            });
        }
    }

    removeErrorClass = () => {
        let className = _.cloneDeep(this.state.className);
        if (typeof (className) === 'string') {
            className = styles.txtInput
        } else if (Array.isArray(className)) {
            if (className.regexIndexOf('errorBorder') !== -1) {
                className.splice(className.regexIndexOf('errorBorder'), 1)
            }
            if (className.regexIndexOf('txtInput') === -1) {
                className.push(styles.txtInput)
            }
        }
        return className
    }

    addErrorClass = () => {
        let className = _.cloneDeep(className);
        if (typeof (this.state.className) === 'string') {
            className = styles.errorBorder;
        } else if (Array.isArray(className)) {
            if (className.regexIndexOf('txtInput') !== -1) {
                className.splice(className.regexIndexOf('txtInput'), 1)
            }
            if (className.regexIndexOf('errorBorder') === -1) {
                className.push(styles.errorBorder);
            }
        }
        return className;
    }

    componentDidMount() {
        const clickFunc = e => {
            let id = this.props.id ? this.props.id + '_autocompleteSuggestions' : 'autocompleteSuggestions'
            let idInput = this.props.id ? this.props.id : 'inputSuggestions'
            if (!$(e.target).closest('#' + id).length && !$(e.target).closest('#' + idInput).length && this.ref.current != null) {
                this.setState({showSuggestions: false});
                if (this.state.value?.[this.props.keyField] == null) {
                    this.setState({searchString: ''});
                }
                this.handleOnSelect(this.state.value);
                $(document).off('click', clickFunc);
            }
        };

        $(this.ref.current).focus(() => $(document).click(clickFunc));
    }

    onBlur = () => {
        this.setState({showSuggestions: false});
        if (this.state.value?.[this.props.keyField] == null) {
            this.setState({searchString: ''});
        }
        this.handleOnSelect(this.state.value);
    }

    // Event fired when the input value is changed
    onChange = e => {
        const {suggestions} = this.props;
        const value = e.currentTarget.value;

        this.setState({searchString: value});

        if (value === '') {
            if (this.props.onChange) {
                this.props.onChange(this.props.field, {
                    target: {value: value}
                })
            }
        }

        if (value && value.toLowerCase().trim().length >= this.props.positionStartSearch) {
            let userInputParsed = value.toLowerCase().trim();
            // Filter our suggestions that don't contain the user's input

            let filteredSuggestions = [];
            if (suggestions) {
                let field = this.props.descriptionField;

                if (this.props.suggestionField) {
                    field = this.props.suggestionField;
                }

                let custFilterFun = this.props.customFilter;
                let searchFromStart = this.props.searchFromStart;
                filteredSuggestions = _.filter(suggestions, function (suggestion) {

                    let suggDescription = suggestion;
                    if (field) {
                        suggDescription = suggestion[field]
                    }

                    if (searchFromStart) {
                        if (custFilterFun) {
                            return suggDescription && suggDescription.toLowerCase().startsWith(userInputParsed) && custFilterFun(suggestion)
                        } else {
                            return suggDescription && suggDescription.toLowerCase().startsWith(userInputParsed)
                        }
                    } else {
                        if (custFilterFun) {
                            return suggDescription && suggDescription.toLowerCase().includes(userInputParsed) && custFilterFun(suggestion)
                        } else {
                            return suggDescription && suggDescription.toLowerCase().includes(userInputParsed)
                        }
                    }
                })
            }

            // Update the user input and filtered suggestions, reset the active
            // suggestion and make sure the suggestions are shown
            this.setState({
                hasChanged: true,
                filteredSuggestions,
                showSuggestions: true,
                value: null,
                activeSuggestion: 0
            })
        } else {
            this.setState({
                showSuggestions: false,
                value: null,
                activeSuggestion: 0
            })
        }

        if (this.props.noSuggestions && this.props.showAlertError) {
            const selectedValue = this.props.suggestions?.find(s => _.isEqual(s[this.props.descriptionField], value));
            const alertMessage = selectedValue == null && value?.length ? this.props.noSuggestions : null;
            this.props.showAlertError(alertMessage);
        }

        if (this.props.forceUpdate) this.props.forceUpdate();
    }

    handleOnSelect = (suggestion) => {
        this.setState({
            showSuggestions: false,
            value: suggestion,
            searchString: (suggestion?.[this.props.descriptionField]) ?? ''
        })

        if (this.props.onChange) {
            this.props.onChange(this.props.field, {
                target: {value: suggestion}
            })
        }

        if (this.props.onSelect) {
            this.props.onSelect(suggestion)
        }

        if (this.props.onSelectCode) {
            this.props.onSelectCode(suggestion[this.props.keyField])
        }

        if (this.props.onSelectDescription) {
            this.props.onSelectDescription(suggestion[this.props.descriptionField])
        }
    }

    // Event fired when the user presses a key down
    onKeyDown = e => {
        let {activeSuggestion, filteredSuggestions} = this.state

        // User pressed the enter key, update the input and close the
        // suggestions
        if (e.keyCode === keyCodes.enter || e.keyCode === keyCodes.tab) {
            if (activeSuggestion >= filteredSuggestions.length) {
                activeSuggestion = 0
            }
            if (this.state.hasChanged) {
                this.handleOnSelect(filteredSuggestions[activeSuggestion])
            }
        }
        // User pressed the up arrow, decrement the index
        else if (e.keyCode === keyCodes.upArrow) {
            if (activeSuggestion === 0) {
                return
            }

            //this.elementInViewPort(`suggestion${activeSuggestion}`)
            this.setState({activeSuggestion: activeSuggestion - 1})
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === keyCodes.downArrow) {
            if (activeSuggestion + 1 === filteredSuggestions.length) {
                return
            }

            //this.elementInViewPort(`suggestion${activeSuggestion}`)
            this.setState({activeSuggestion: activeSuggestion + 1})
        }
    }

    render() {
        const {
            cssModule,
            ...attributes
        } = this.props
        const {filteredSuggestions} = this.state

        if (this.state.className == null) {
            this.setState({className: ''});
        }

        let suggestionsListComponent

        let that = this
        if (this.state.showSuggestions) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <div style={{position: 'relative'}}>
                        <ul
                            id={this.props.id ? this.props.id + '_autocompleteSuggestions' : 'autocompleteSuggestions'}
                            className={styles.suggestions}
                            style={{
                                position: 'absolute',
                                zIndex: '1',
                                textAlign: 'left',
                                border: '1px solid #176A65',
                                backgroundColor: '#ffffff',
                                borderRadius: 10,
                            }}>
                            {filteredSuggestions.map((suggestion, index) => {
                                return (
                                    <li key={index}
                                        id={`suggestion${index}`}
                                        className={
                                            index === this.state.activeSuggestion
                                                ? styles.suggestionSelected
                                                : styles.suggestionActive
                                        }
                                        data-id={`${index}`}
                                        onClick={(e) => that.handleOnSelect(filteredSuggestions[e.currentTarget.dataset.id])}
                                        onBlur={this.onBlur}>
                                        {this.props.descriptionField
                                            ? this.props.addCodiceToDescription
                                                ? `${suggestion["codice"]} | ${suggestion[this.props.descriptionField]}`
                                                : suggestion[this.props.descriptionField]
                                            : suggestion}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                )
            } else if (this.props.noOptionsMessageInDropdown) {
                suggestionsListComponent = (
                    <div style={{position: 'relative'}}>
                        <ul
                            id={this.props.id ? this.props.id + '_autocompleteSuggestions' : 'autocompleteSuggestions'}
                            className={styles.emptySuggestions}
                            style={{
                                position: 'absolute',
                                zIndex: '1',
                                textAlign: 'left',
                                border: '1px solid #176A65',
                                backgroundColor: '#ffffff',
                                borderRadius: 10,
                            }}>
                            <li key={0} id={"emptySuggestion"} className={styles.noOptionsMessageInDropdown}>
                                {this.props.noOptionsMessageInDropdown}
                            </li>
                        </ul>
                    </div>
                );
            }
        }

        const classes = mapToCssModules(
            classNames(
                this.state.className,
                'autocomplete'
            ),
            cssModule
        )

        let styleRuntime = this.state.filteredSuggestions.length > 0 && this.state.showSuggestions
            ? '1px solid #176A65' : '1px solid #176A65'

        return (
            <>
                <input
                    ref={this.ref}
                    className={classes}
                    autoComplete={this.props.autoComplete}
                    id={this.props.id ? this.props.id : 'inputSuggestions'}
                    type="text"
                    style={{
                        border: !this.props.disabled ? '1px solid #176A65' : '1px solid #d6d6d6',
                        borderBottom: this.props.disabled ? '1px solid #d6d6d6' : styleRuntime,
                        fontSize: '16px',
                        backgroundColor: this.props.disabled ? '#e6e9f2' : '#ffffff',
                        borderRadius: '10px'
                    }}
                    onKeyDown={this.onKeyDown}
                    onChange={this.onChange}
                    placeholder={this.props.placeholder}
                    value={this.state.searchString}
                    onBlur={this.props.onBlur}
                />
                {!this.props.disabled && this.props.infoText && !this.state.showSuggestions && (
                    <span className={styles.mandatory}>
                        {this.props.infoText}
                    </span>
                )}
                {!attributes.disabled && suggestionsListComponent}
            </>
        );
    }
}

Autocomplete.propTypes = propTypes;
Autocomplete.defaultProps = defaultProps;

