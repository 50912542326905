import styles from "./PreviewNotifica.module.css";
import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import { Row, Jumbotron, Col } from "reactstrap";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import * as moment from "moment";
import enumsUtente from "../../../enum/enumsUtente.json";

const propTypes = {
    notifica: PropTypes.object,
    key: PropTypes.string,
    onClickNotifica: PropTypes.func,
    idNotificaSelezionata: PropTypes.string,
    dropdown: PropTypes.bool
}

export default class PreviewNotifica extends Component {

    getStylesPreviewModifica = (notifica) => {
        if (notifica.visualizzato === "SI") return styles.previewNotificaLetta;
        else return styles.previewNotificaNonLetta;
    }

    getStylesPreviewSelezionata = (idNotifica, idNotificaSelezionata) => {
        if (idNotifica === idNotificaSelezionata) {
            return styles.selectedPreview;
        }
    }

    getDataNotificaPreview = (dataNotifica) => {
        let dataUnaSettimanaFa = moment().subtract(7, 'days').startOf('day');

        let dataOdierna = Utils.transformDateAndTimeForFrontend(Date.now(), "DD/MM/YYYY");
        let dataCreazioneNotifica = Utils.transformDateAndTimeForFrontend(dataNotifica, "DD/MM/YYYY");

        let oraCreazioneNotifica = Utils.transformDateAndTimeForFrontend(dataNotifica, "HH:mm");

        if (dataOdierna === dataCreazioneNotifica) {
            return oraCreazioneNotifica;
        }
        else if (moment(dataNotifica).isAfter(dataUnaSettimanaFa)) {
            return Utils.getDayOfWeekIT(dataNotifica) + ", " + oraCreazioneNotifica;
        }
        else {
            return Utils.transformDateAndTimeForFrontend(dataNotifica, "DD/MM/YYYY, HH:mm");
        }
    }

    getDataPreviewDropdown = (dataNotifica) => {
        return Utils.transformDateAndTimeForFrontend(dataNotifica, "DD/MM/YYYY");
    }

    getOraPreviewDropdown = (dataNotifica) => {
        return "h " + Utils.transformDateAndTimeForFrontend(dataNotifica, "HH:mm");
    }

    getNomeAbbreviato = (nome) => {
        return nome?.charAt(0) + ".";
    }

    renderInfoMedicoRichiedente = (props) => {
        return (
            <Col xs={12} className={styles.ellipsisText} style={{color: "black", fontWeight: "normal"}}>
                Medico richiedente: {this.getNomeAbbreviato(props.notifica?.richiedente?.nome) + " " + props.notifica?.richiedente?.cognome} ({enumsUtente.ruoliFE[props.notifica?.richiedente?.ruolo]})
            </Col>
        )
    }

    renderNotifica = (props) => {
        return (
            <Fragment>
                <Row>
                    <Col xs={6} className={[styles.titoloPreviewNotificaCentro, styles.ellipsisText, 'text-nowrap'].join(" ")}>
                        {props.notifica.titoloNotifica}
                    </Col>
                    <Col xs={6}
                         className={[styles.ellipsisText, "text-right", styles.capitalizeLabel].join(" ")}
                         style={{color: "black", fontWeight: "normal"}}>
                        {this.getDataNotificaPreview(props.notifica.timestampCreazione)}
                    </Col>
                </Row>
                <Row>
                    {this.renderInfoMedicoRichiedente(props)}
                </Row>
            </Fragment>
        )
    }

    renderNotificaDropdown = (props) => {
        return (
            <Fragment>
                <Row>
                    <Col xs={6}
                         className={[styles.ellipsisText, "text-left", styles.capitalizeLabel].join(" ")}
                         style={{color: "black", fontWeight: "normal"}}>
                        {this.getDataPreviewDropdown(props.notifica.timestampCreazione)}
                    </Col>
                    <Col xs={6}
                         className={[styles.ellipsisText, "text-right"].join(" ")}
                         style={{color: "black", fontWeight: "normal"}}>
                        {this.getOraPreviewDropdown(props.notifica.timestampCreazione)}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={styles.titoloPreviewNotifica.concat(" ").concat(styles.ellipsisText)}>
                        {props.notifica.titoloNotifica}
                    </Col>
                </Row>
                <Row>
                    {this.renderInfoMedicoRichiedente(props)}
                </Row>
            </Fragment>
        )
    }

    render() {
        let props = _.cloneDeep(this.props);

        let stylesPreviewNotifica = this.getStylesPreviewModifica(props.notifica);
        let stylesPreviewSelezionata = this.getStylesPreviewSelezionata(props.notifica.id, props.idNotificaSelezionata);

        return (
            <Fragment>
                <Jumbotron className={[styles.previewNotifica, "pl-3 pr-3 pt-2 pb-2", stylesPreviewNotifica, stylesPreviewSelezionata].join(" ")}
                           key={this.props.key}
                           onClick={() => this.props.onClickNotifica(props.notifica.id)}>
                    {props.dropdown ? this.renderNotificaDropdown(props) : this.renderNotifica(props)}
                </Jumbotron>
            </Fragment>
        )
    }
}

PreviewNotifica.propTypes = propTypes;
