import React from "react";
import PropTypes from "prop-types";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {FieldsRow, RoleBasedInput} from "../../roleBasedComponents/RoleBasedComponents";

export default function DisponibilitaContatto(props) {
    return <AccordionSezioneRichiesta
        title={"Disponibilità di contatto"}
        openAccordion={props.openAccordion}
        onClickAccordion={props.onClickAccordion}
        pageState={props.pageState}>
        <FieldsRow>
            <RoleBasedInput
                fieldId={'DISP.DISPONIBILITADICONTATTO.ANAGRAFESOGGETTORICHIEDENTE'}
                pageState={props.pageState}
                id={"inputDisponibilita"}
                field={"disponibilita"}
                fieldLabel={"Disponibilità"}
                placeholder={"Disponibilità"}
                onChange={(field, elem) => props.onChangeDatiMedico(field, elem.target.value)}
                value={props.datiMedico.disponibilita}
                disableDoubleInput={true}
            />
        </FieldsRow>
    </AccordionSezioneRichiesta>;
}

DisponibilitaContatto.propTypes = {
    pageState: PropTypes.string.isRequired,
    datiMedico: PropTypes.object.isRequired,
    editableFields: PropTypes.object.isRequired,
    onChangeDatiMedico: PropTypes.func.isRequired,
    openAccordion: PropTypes.bool.isRequired,
    onClickAccordion: PropTypes.func.isRequired
};
