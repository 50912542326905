import Assorbimento from "../../../components/accordionInfermiere/assorbimento/Assorbimento";
import CapacitaCuraDiSe from "../../../components/accordionInfermiere/capacitaCuraDiSe/CapacitaCuraDiSe";
import ComunicazioneSensorio from "../../../components/accordionInfermiere/comunicazioneSensorio/ComunicazioneSensorio";
import CopingTolleranzaStressInfermiere
  from "../../../components/accordionInfermiere/copingTolleranzaStress/CopingTolleranzaStressInfermiere";
import Dieta from "../../../components/accordionInfermiere/dieta/Dieta";
import FunzioneGastrointestinale
  from "../../../components/accordionInfermiere/funzioneGastrointestinale/FunzioneGastrointestinale";
import FunzioneRespiratoria from "../../../components/accordionInfermiere/funzioneRespiratoria/FunzioneRespiratoria";
import ValutazioneSistemaRespiratorio
  from "../../../components/accordionInfermiere/funzioneRespiratoria/valutazioneSistemaRespiratorio/ValutazioneSistemaRespiratorio";
import FunzioneTegumentaria from "../../../components/accordionInfermiere/funzioneTegumentaria/FunzioneTegumentaria";
import ValutazioneDellaCute
  from "../../../components/accordionInfermiere/funzioneTegumentaria/valutazioneDellaCute/ValutazioneDellaCute";
import FunzioneUrinaria from "../../../components/accordionInfermiere/funzioneUrinaria/FunzioneUrinaria";
import GestioneDellaSalute from "../../../components/accordionInfermiere/gestioneDellaSalute/GestioneDellaSalute";
import IdratazioneInfermiere from "../../../components/accordionInfermiere/idratazione/IdratazioneInfermiere";
import Ingestione from "../../../components/accordionInfermiere/ingestione/Ingestione";
import SonnoRiposo from "../../../components/accordionInfermiere/SonnoERiposo/SonnoRiposo";
import AmministratoreDiSostegno
  from "../../../components/anagrafePaziente/amministratoreDiSostegno/AmministratoreDiSostegno";
import AnagrafePaziente from "../../../components/anagrafePaziente/AnagrafePaziente";
import DatiGenerali from "../../../components/anagrafePaziente/datiGenerali/DatiGenerali";
import Esenzioni from "../../../components/anagrafePaziente/esenzioni/Esenzioni";
import Fiduciario from "../../../components/anagrafePaziente/fiduciario/Fiduciario";
import InvaliditaCivile from "../../../components/anagrafePaziente/invaliditaCivile/InvaliditaCivile";
import Madre from "../../../components/anagrafePaziente/madre/Madre";
import MedicoDiBase from "../../../components/anagrafePaziente/medicoDiBase/MedicoDiBase";
import Padre from "../../../components/anagrafePaziente/padre/Padre";
import ResidenzaEDomicilio from "../../../components/anagrafePaziente/residenzaEDomicilio/ResidenzaEDomicilio";
import SettingProvenienza from "../../../components/anagrafePaziente/settingProvenienza/SettingProvenienza";
import Tutore from "../../../components/anagrafePaziente/tutore/Tutore";
import AnagrafeRichiedente from "../../../components/anagrafeRichiedente/AnagrafeRichiedente";
import ContattiRichiedente from "../../../components/anagrafeRichiedente/contattiRichiedente/ContattiRichiedente";
import DisponibilitaContatto from "../../../components/anagrafeRichiedente/disponibilitaContatto/DisponibilitaContatto";
import Ruolo from "../../../components/anagrafeRichiedente/ruolo/Ruolo";
import SedeLavorativa from "../../../components/anagrafeRichiedente/sedeLavorativa/SedeLavorativa";
import SoggettoRichiedenteCureDomiciliari
  from "../../../components/anagrafeRichiedente/soggettoRichiedenteCureDomiciliari/SoggettoRichiedenteCureDomiciliari";
import AnamnesiFamiliare from "../../../components/anamnesiFamiliare/AnamnesiFamiliare";
import PatologieFamiliari from "../../../components/anamnesiFamiliare/patologieFamiliari/PatologieFamiliari";
import AlvoEliminazioneFecale
  from "../../../components/anamnesiFisiologica/alvoEliminazioneFecale/AlvoEliminazioneFecale";
import AnamnesiFisiologica from "../../../components/anamnesiFisiologica/AnamnesiFisiologica";
import Appetito from "../../../components/anamnesiFisiologica/appetito/Appetito";
import AttivitaStrumentaliDellaVitaQuotidiana
  from "../../../components/anamnesiFisiologica/attivitaStrumentaliDellaVitaQuotidiana/AttivitaStrumentaliDellaVitaQuotidiana";
import Comunicazione from "../../../components/anamnesiFisiologica/comunicazione/Comunicazione";
import CopingTolleranzaStress
  from "../../../components/anamnesiFisiologica/copingTolleranzaStress/CopingTolleranzaStress";
import Diuresi from "../../../components/anamnesiFisiologica/diuresi/Diuresi";
import Donna from "../../../components/anamnesiFisiologica/donna/Donna";
import GradoDiMobilita from "../../../components/anamnesiFisiologica/gradoDiMobilita/GradoDiMobilita";
import Idratazione from "../../../components/anamnesiFisiologica/idratazione/Idratazione";
import InformazioniGenerali from "../../../components/anamnesiFisiologica/informazioniGenerali/InformazioniGenerali";
import NecessitaDiAssistenzaSanitaria
  from "../../../components/anamnesiFisiologica/necessitaDiAssistenzaSanitaria/NecessitaDiAssistenzaSanitaria";
import Respiro from "../../../components/anamnesiFisiologica/respiro/Respiro";
import RitmoSonnoVeglia from "../../../components/anamnesiFisiologica/ritmoSonnoEVeglia/RitmoSonnoVeglia";
import SensorioBaseValutazione
  from "../../../components/anamnesiFisiologica/sensorioBaseValutazione/SensorioBaseValutazione";
import SensorioDolore from "../../../components/anamnesiFisiologica/sensorioDolore/SensorioDolore";
import SensorioEquilibrio from "../../../components/anamnesiFisiologica/sensorioEquilibrio/SensorioEquilibrio";
import SensorioUdito from "../../../components/anamnesiFisiologica/sensorioUdito/SensorioUdito";
import SensorioVista from "../../../components/anamnesiFisiologica/sensorioVista/SensorioVista";
import StatoCognitivo from "../../../components/anamnesiFisiologica/statoCognitivo/StatoCognitivo";
import StatoComportamentale from "../../../components/anamnesiFisiologica/statoComportamentale/StatoComportamentale";
import StatoFunzionale from "../../../components/anamnesiFisiologica/statoFunzionale/StatoFunzionale";
import StatoNutrizionale from "../../../components/anamnesiFisiologica/statoNutrizionale/StatoNutrizionale";
import Uomo from "../../../components/anamnesiFisiologica/uomo/Uomo";
import AnamnesiPatologicaProssima from "../../../components/anamnesiPatologicaProssima/AnamnesiPatologicaProssima";
import AnamnesiPatologicaProssimaSottoAccordion
  from "../../../components/anamnesiPatologicaProssima/anamnesiPatologicaProssimaSottoAccordion/AnamnesiPatologicaProssimaSottoAccordion";
import AnamnesiPatologicaRemota from "../../../components/anamnesiPatologicaRemota/AnamnesiPatologicaRemota";
import Argomento from "../../../components/anamnesiPatologicaRemota/argomento/Argomento";
import BenessereAbitativo from "../../../components/benessereAbitativo/BenessereAbitativo";
import SituazioneAbitativa from "../../../components/benessereAbitativo/situazioneAbitativa/SituazioneAbitativa";
import CaratteristicheSettingRichiesto
  from "../../../components/compilaFormalizzazione/caratteristicheSettingRichiesto/CaratteristicheSettingRichiesto";
import ProfiloAutonomia
  from "../../../components/compilaFormalizzazione/caratteristicheSettingRichiesto/ProfiloAutonomia";
import ValutazioneInFormalizzazione
  from "../../../components/compilaFormalizzazione/caratteristicheSettingRichiesto/ValutazioneInFormalizzazione";
import EventoAssistenzialeCureDomiciliari
  from "../../../components/compilaFormalizzazione/progettoAssistenziale/EventoAssistenzialeCureDomiciliari";
import ProgettoAssistenziale
  from "../../../components/compilaFormalizzazione/progettoAssistenziale/ProgettoAssistenziale";
import ResponsabileDelCasoNelSettingRichiesto
  from "../../../components/compilaFormalizzazione/progettoAssistenziale/ResponsabileDelCasoNelSettingRichiesto";
import ValutazioniSettingRichiesto
  from "../../../components/compilaFormalizzazione/progettoAssistenziale/ValutazioniSettingRichiesto";
import BisogniPrestazioniAssistenziali
  from "../../../components/compilaProposte/bisogniPrestazioniAssistenziali/BisogniPrestazioniAssistenziali";
import BisogniPrestazioniAssistenzialiSottoAccordion
  from "../../../components/compilaProposte/bisogniPrestazioniAssistenziali/bisogniPrestazioniAssistenzialiSottoAccordion/BisogniPrestazioniAssistenzialiSottoAccordion";
import FigureProfessionaliPAI
  from "../../../components/compilaProposte/figureProfessionaliPresaInCarico/figureProfessionaliPAI/FigureProfessionaliPAI";
import FigureProfessionaliPresaInCarico
  from "../../../components/compilaProposte/figureProfessionaliPresaInCarico/FigureProfessionaliPresaInCarico";
import FigureProfessionaliSupportoAValutazione
  from "../../../components/compilaProposte/FigureProfessionaliSupportoAValutazione/FigureProfessionaliSupportoAValutazione";
import FigureProfessionaliSupportoAValutazioneSottoAccordion
  from "../../../components/compilaProposte/FigureProfessionaliSupportoAValutazione/figureProfessionaliSupportoAValutazioneSottoAccordion/FigureProfessionaliSupportoAValutazioneSottoAccordion";
import LivelloPrioritaRichiesta from "../../../components/compilaProposte/livelloPriorita/LivelloPrioritaRichiesta";
import LivelloPrioritaRichiestaSub
  from "../../../components/compilaProposte/livelloPriorita/livelloPrioritaRichiestaSub/LivelloPrioritaRichiestaSub";
import ObiettiviClinicoAssistenziali
  from "../../../components/compilaProposte/obiettiviClinicoAssistenziali/ObiettiviClinicoAssistenziali";
import ObiettiviClinicoAssistenzialiSottoAccordion
  from "../../../components/compilaProposte/obiettiviClinicoAssistenziali/obiettiviClinicoAssistenzialiSottoAccordion/ObiettiviClinicoAssistenzialiSottoAccordion";
import ProposteAttivitaSuggerimenti
  from "../../../components/compilaProposte/proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimenti";
import ProposteAttivitaSuggerimentiSottoAccordion
  from "../../../components/compilaProposte/proposteAttivitaSuggerimenti/proposteAttivitaSuggerimentiSottoAccordion/ProposteAttivitaSuggerimentiSottoAccordion";
import Alimentazione from "../../../components/determinantiDiSalute/alimentazione/Alimentazione";
import AttivitaFisicaSedentaria
  from "../../../components/determinantiDiSalute/attivitaFisicaSedentaria/AttivitaFisicaSedentaria";
import ConsumoDiAlcool from "../../../components/determinantiDiSalute/consumoDiAlcool/ConsumoDiAlcool";
import ConsumoDiTabacco from "../../../components/determinantiDiSalute/consumoDiTabacco/ConsumoDiTabacco";
import DeterminantiDiSalute from "../../../components/determinantiDiSalute/DeterminantiDiSalute";
import DrogheStupefacenti from "../../../components/determinantiDiSalute/drogheStupefacenti/DrogheStupefacenti";
import NoteERegoleOsservateDaCulturaEReligione
  from "../../../components/determinantiDiSalute/noteERegoleOsservateDaCulturaEReligione/NoteERegoleOsservateDaCulturaEReligione";
import Addome from "../../../components/esameObiettivo/addome/Addome";
import ApparatoVascolarePeriferico
  from "../../../components/esameObiettivo/apparatoVascolarePeriferico/ApparatoVascolarePeriferico";
import Capo from "../../../components/esameObiettivo/capo/Capo";
import Collo from "../../../components/esameObiettivo/collo/Collo";
import Cuore from "../../../components/esameObiettivo/cuore/Cuore";
import CuteEAnnessi from "../../../components/esameObiettivo/cuteEAnnessi/CuteEAnnessi";
import EsameObiettivo from "../../../components/esameObiettivo/EsameObiettivo";
import EsplorazioneRettale from "../../../components/esameObiettivo/esplorazioneRettale/EsplorazioneRettale";
import Mammelle from "../../../components/esameObiettivo/mammelle/Mammelle";
import Naso from "../../../components/esameObiettivo/naso/Naso";
import NeurologicoEstatoDiVigilanza
  from "../../../components/esameObiettivo/neurologicoEstatoDiVigilanza/NeurologicoEstatoDiVigilanza";
import Occhi from "../../../components/esameObiettivo/occhi/Occhi";
import Orecchie from "../../../components/esameObiettivo/orecchie/Orecchie";
import Orofaringe from "../../../components/esameObiettivo/orofaringe/Orofaringe";
import Osteoarticolare from "../../../components/esameObiettivo/osteoarticolare/Osteoarticolare";
import SistemaLinfatico from "../../../components/esameObiettivo/sistemaLinfatico/SistemaLinfatico";
import Torace from "../../../components/esameObiettivo/torace/Torace";
import Urogenitale from "../../../components/esameObiettivo/urogenitale/Urogenitale";
import UrogenitaleFemminileOstetrico
  from "../../../components/esameObiettivo/urogenitaleFemminileOstetrico/UrogenitaleFemminileOstetrico";
import AderenzaAllaTerapia from "../../../components/infoBase/aderenzaAllaTerapia/AderenzaAllaTerapia";
import AltriTrattamenti from "../../../components/infoBase/altriTrattamenti/AltriTrattamenti";
import DispositiviMediciPresenti
  from "../../../components/infoBase/dispositiviMediciPresenti/DispositiviMediciPresenti";
import FornitureSanitarieAttive from "../../../components/infoBase/fornitureSanitarieAttive/FornitureSanitarieAttive";
import InformazioniDiBase from "../../../components/infoBase/InformazioniDiBase";
import IntegratoriEFitoterapici from "../../../components/infoBase/integratoriEFitoterapici/IntegratoriEFitoterapici";
import TerapiaFarmacologicaInAtto
  from "../../../components/infoBase/terapiaFarmacologicaInAtto/TerapiaFarmacologicaInAtto";
import TrattamentiDietetici from "../../../components/infoBase/trattamentiDietetici/TrattamentiDietetici";
import TrattamentiLesioniTrofiche
  from "../../../components/infoBase/trattamentiLesioniTrofiche/TrattamentiLesioniTrofiche";
import InformazioniBaseFaseRichiesta
  from "../../../components/informazioniBaseFaseRichiesta/InformazioniBaseFaseRichiesta";
import Allergie from "../../../components/informazioniBaseFaseValutazione/allergie/Allergie";
import InformazioniBaseFaseValutazione
  from "../../../components/informazioniBaseFaseValutazione/InformazioniBaseFaseValutazione";
import InfoUltimoRicovero
  from "../../../components/informazioniBaseFaseValutazione/infoUltimoRicovero/InfoUltimoRicovero";
import UltimoAccessoPS from "../../../components/informazioniBaseFaseValutazione/ultimoAccessoPS/UltimoAccessoPS";
import PunteggioDiBrass from "../../../components/informazioniBaseFaseRichiesta/punteggioDiBrass/punteggioDiBrass";
import Parametri from "../../../components/parametriVitali/parametri/Parametri";
import ParametriVitali from "../../../components/parametriVitali/ParametriVitali";
import BenessereSociale from "../../../components/situazioneSociale/benessereSociale/BenessereSociale";
import ContributiEconomiciSostegno
  from "../../../components/situazioneSociale/contributiEconomiciSostegno/ContributiEconomiciSostegno";
import ParentiTenutiAgliAlimenti
  from "../../../components/situazioneSociale/parentiTenutiAgliAlimenti/ParentiTenutiAgliAlimenti";
import PersonaDiRiferimento from "../../../components/situazioneSociale/personaDiRiferimento/PersonaDiRiferimento";
import RelazioniFamiliari from "../../../components/situazioneSociale/relazioniFamiliari/RelazioniFamiliari";
import ServiziAllaPersonaAttiviOInCorsoDiAttivazione
  from "../../../components/situazioneSociale/serviziAllaPersonaAttiviOInCorsoDiAttivazione/ServiziAllaPersonaAttiviOInCorsoDiAttivazione";
import ServizioAssistenzaSociale
  from "../../../components/situazioneSociale/servizioAssistenzaSociale/ServizioAssistenzaSociale";
import SituazioneSociale from "../../../components/situazioneSociale/SituazioneSociale";
import SupportoSocialeAssistenteNonFamiliare
  from "../../../components/situazioneSociale/supportoSocialeAssistenteNonFamiliare/SupportoSocialeAssistenteNonFamiliare";
import ValutazioneReteSociale
  from "../../../components/situazioneSociale/valutazioneReteSociale/ValutazioneReteSociale";
import ValutazioneBisogniPalliativi
  from "../../../components/valutazioneBisogniPalliativi/ValutazioneBisogniPalliativi";
import ValutazioneBisogniPalliativiSub
  from "../../../components/valutazioneBisogniPalliativi/valutazioneBisogniPalliativiSub/ValutazioneBisogniPalliativiSub";
import ValutazioneProfessionista
  from "../../../components/compilaProposte/valutazioneProfessionista/ValutazioneProfessionista";
import ValutazioneProfessionistaSub
  from "../../../components/compilaProposte/valutazioneProfessionista/valutazioneProfessionistaSub/ValutazioneProfessionistaSub";
import DataDimissioneProgrammataDaRicovero
  from "../../../components/compilaProposte/dataDimissioneProgrammataDaRicovero/DataDimissioneProgrammataDaRicovero";
import DataDimissioneProgrammataDaRicoveroSub
  from "../../../components/compilaProposte/dataDimissioneProgrammataDaRicovero/dataDimissioneProgrammataDaRicoveroSub/DataDimissioneProgrammataDaRicoveroSub";
import DataDiPresaInCaricoTerritorialeDaDimissioneProtetta
  from "../../../components/compilaProposte/dataDiPresaInCaricoDaDimissioneProtetta/DataDiPresaInCaricoTerritorialeDaDimissioneProtetta";
import DataDiPresaInCaricoTerritorialeDaDimissioneProtettaSub
  from "../../../components/compilaProposte/dataDiPresaInCaricoDaDimissioneProtetta/dataDiPresaInCaricoTerritorialeDaDimissioneProtettaSub/DataDiPresaInCaricoTerritorialeDaDimissioneProtettaSub";
import BisogniAllaDimissione from "../../../components/compilaProposte/bisogniAllaDimissione/BisogniAllaDimissione";
import BisogniAllaDimissioneSub
  from "../../../components/compilaProposte/bisogniAllaDimissione/bisogniAllaDimissioneSub/BisogniAllaDimissioneSub";
import DatiRichiesta from "../../../components/datiRichiesta/DatiRichiesta";

export const accordions = [
{
  "accordion": DatiRichiesta,
  "subAccordions": []
},
{
  "accordion": AnagrafePaziente,
  "subAccordions": [
    DatiGenerali,
    MedicoDiBase,
    ResidenzaEDomicilio,
    SettingProvenienza,
    Esenzioni,
    InvaliditaCivile,
    Tutore,
    AmministratoreDiSostegno,
    Fiduciario,
    Padre,
    Madre
  ]
},
{
  "accordion": ValutazioneBisogniPalliativi,
  "subAccordions": [
    ValutazioneBisogniPalliativiSub
  ]
},
{
  "accordion": SituazioneSociale,
  "subAccordions": [
    RelazioniFamiliari,
    ParentiTenutiAgliAlimenti,
    PersonaDiRiferimento,
    BenessereSociale,
    ServizioAssistenzaSociale,
    ValutazioneReteSociale,
    SupportoSocialeAssistenteNonFamiliare,
    ContributiEconomiciSostegno,
    ServiziAllaPersonaAttiviOInCorsoDiAttivazione
  ]
},
{
  "accordion": BenessereAbitativo,
  "subAccordions": [
    SituazioneAbitativa
  ]
},
{
  "accordion": DeterminantiDiSalute,
  "subAccordions": [
    ConsumoDiTabacco,
    ConsumoDiAlcool,
    DrogheStupefacenti,
    Alimentazione
  ]
},
{
  "accordion": DeterminantiDiSalute,
  "subAccordions": [
    NoteERegoleOsservateDaCulturaEReligione,
    AderenzaAllaTerapia,
    IntegratoriEFitoterapici,
    AltriTrattamenti,
    FornitureSanitarieAttive,
    AttivitaFisicaSedentaria
  ]
},
{
  "accordion": AnamnesiFisiologica,
  "subAccordions": [
    StatoFunzionale,
    GradoDiMobilita,
    StatoCognitivo,
    StatoComportamentale,
    NecessitaDiAssistenzaSanitaria,
    AttivitaStrumentaliDellaVitaQuotidiana,
    SensorioUdito,
    SensorioVista,
    SensorioDolore,
    SensorioBaseValutazione,
    SensorioEquilibrio,
    Idratazione,
    Donna,
    InformazioniGenerali,
    StatoNutrizionale,
    Appetito,
    Respiro,
    AlvoEliminazioneFecale,
    Diuresi,
    RitmoSonnoVeglia,
    Comunicazione,
    CopingTolleranzaStress,
    AderenzaAllaTerapia,
    Uomo
  ]
},
{
  "accordion":InformazioniBaseFaseRichiesta,
  "subAccordions":[
    InfoUltimoRicovero,
    UltimoAccessoPS,
    Allergie,
    PunteggioDiBrass
  ]
},
{
  "accordion": InformazioniBaseFaseValutazione,
  "subAccordions": [
    InfoUltimoRicovero,
    Allergie,
    UltimoAccessoPS
  ]
},
{
  "accordion": InformazioniDiBase,
  "subAccordions": [
    DispositiviMediciPresenti,
    TerapiaFarmacologicaInAtto,
    TrattamentiDietetici,
    TrattamentiLesioniTrofiche,
    FornitureSanitarieAttive
  ]
},
{
  "accordion":   AnamnesiFamiliare,
  "subAccordions": [PatologieFamiliari]
},
{
  "accordion": AnamnesiPatologicaRemota,
  "subAccordions": [
    Argomento
  ]
},
{
  "accordion": AnamnesiPatologicaProssima,
  "subAccordions": [
    AnamnesiPatologicaProssimaSottoAccordion
  ]
},
{
  "accordion": EsameObiettivo,
  "subAccordions": [
    Capo,
    Occhi,
    Orofaringe,
    Naso,
    Orecchie,
    Collo,
    Torace,
    Mammelle,
    SistemaLinfatico,
    Cuore,
    ApparatoVascolarePeriferico,
    Addome,
    Urogenitale,
    UrogenitaleFemminileOstetrico,
    EsplorazioneRettale,
    Osteoarticolare,
    NeurologicoEstatoDiVigilanza,
    CuteEAnnessi
  ]
},
{
  "accordion": ParametriVitali,
  "subAccordions": [
    Parametri
  ]
},
{
  "accordion": LivelloPrioritaRichiesta,
  "subAccordions": [
    LivelloPrioritaRichiestaSub
  ]
},
{
  "accordion": BisogniPrestazioniAssistenziali,
  "subAccordions": [
    BisogniPrestazioniAssistenzialiSottoAccordion
  ]
},
{
  "accordion": ObiettiviClinicoAssistenziali,
  "subAccordions": [
    ObiettiviClinicoAssistenzialiSottoAccordion
  ]
},
  {
    "accordion": FigureProfessionaliPresaInCarico,
    "subAccordions": [
      FigureProfessionaliPAI
    ]
  },
  {
    "accordion": ValutazioneProfessionista,
    "subAccordions": [
      ValutazioneProfessionistaSub
    ]
  },
  {
    "accordion": ProposteAttivitaSuggerimenti,
    "subAccordions": [
      ProposteAttivitaSuggerimentiSottoAccordion
    ]
  },
  {
    "accordion": FigureProfessionaliSupportoAValutazione,
    "subAccordions": [
      FigureProfessionaliSupportoAValutazioneSottoAccordion
    ]
},
{
  "accordion": ProposteAttivitaSuggerimenti,
  "subAccordions": [
    ProposteAttivitaSuggerimentiSottoAccordion
  ]
},
{
  "accordion": FigureProfessionaliSupportoAValutazione,
  "subAccordions": [
    FigureProfessionaliSupportoAValutazioneSottoAccordion
  ]
},
  {
    "accordion": DataDimissioneProgrammataDaRicovero,
    "subAccordions": [
      DataDimissioneProgrammataDaRicoveroSub
    ]
  },
  {
    "accordion": DataDiPresaInCaricoTerritorialeDaDimissioneProtetta,
    "subAccordions": [
      DataDiPresaInCaricoTerritorialeDaDimissioneProtettaSub
    ]
  },
  {
    "accordion": BisogniAllaDimissione,
    "subAccordions": [
      BisogniAllaDimissioneSub
    ]
  },
{
  "accordion": CaratteristicheSettingRichiesto,
  "subAccordions": [
    ProfiloAutonomia,
    ValutazioneInFormalizzazione
  ]
},
{
  "accordion": ProgettoAssistenziale,
  "subAccordions": [
    ValutazioniSettingRichiesto,
    ResponsabileDelCasoNelSettingRichiesto,
    EventoAssistenzialeCureDomiciliari
  ]
},
{
  "accordion": AnagrafeRichiedente,
  "subAccordions": [
    SoggettoRichiedenteCureDomiciliari,
    Ruolo,
    SedeLavorativa,
    ContattiRichiedente,
    DisponibilitaContatto
  ]
},
{
  "accordion": GestioneDellaSalute,
  "subAccordions": [
    AderenzaAllaTerapia
  ]
},

{
  "accordion": Dieta,
  "subAccordions": [
    Alimentazione,
    TrattamentiDietetici
  ]
},
{
  "accordion": Ingestione,
  "subAccordions": [
    Orofaringe,
    Addome
  ]
},
{
  "accordion": IdratazioneInfermiere,
  "subAccordions": [
    Idratazione
  ]
},
{
  "accordion": Assorbimento,
  "subAccordions": [
    StatoNutrizionale
  ]
},
{
  "accordion": FunzioneGastrointestinale,
  "subAccordions": [
    Appetito,
    AlvoEliminazioneFecale,
    EsplorazioneRettale
  ]
},
{
  "accordion": FunzioneUrinaria,
  "subAccordions": [
    Urogenitale,
    Diuresi
  ]
},
{
  "accordion": FunzioneTegumentaria,
  "subAccordions": [
    ValutazioneDellaCute
  ]
},
{
  "accordion": FunzioneRespiratoria,
  "subAccordions": [
    ValutazioneSistemaRespiratorio
  ]
},
{
  "accordion": SonnoRiposo,
  "subAccordions": [
    RitmoSonnoVeglia
  ]
},
{
  "accordion": CapacitaCuraDiSe,
  "subAccordions": [
    StatoFunzionale,
    GradoDiMobilita,
    SensorioEquilibrio,
    AttivitaStrumentaliDellaVitaQuotidiana,
    StatoCognitivo,
    StatoComportamentale,
    NecessitaDiAssistenzaSanitaria
  ]
},
{
  "accordion": ComunicazioneSensorio,
  "subAccordions": [
    Comunicazione,
    SensorioUdito,
    SensorioVista,
    SensorioBaseValutazione,
    SensorioEquilibrio,
    SensorioDolore
  ]
},
{
  "accordion": CopingTolleranzaStressInfermiere,
  "subAccordions": [
    CopingTolleranzaStress
  ]
},
]
