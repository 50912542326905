import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import {
    FieldsRow,
    icd9List,
    RoleBasedCalendar,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
} from "../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import enumRichiesta from "../../../enum/enumRichiesta";

const propTypes = {
    pageState: PropTypes.string,
    datiUltimoAccessoPS: PropTypes.any,
    onChangeDatiUltimoAccessoPS: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    mascheraModificabilita:PropTypes.any,
    hideUnsetValues: PropTypes.bool,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

const defaultUltimoAccesso = {
    settingProvenienza: null,
    codiceIstitutoCuraDimissione: null,
    diagnosiDimissione: null,
    settingPresaInCarico: null
}
const defaultSelect = {codice: "", descrizione: ""}
export default class UltimoAccessoPS extends Component {

    state = {
        ultimoAccessoPS: this.props.datiUltimoAccessoPS ? _.cloneDeep(this.props.datiUltimoAccessoPS) : defaultUltimoAccesso,
        campiObbligatori: 0,
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiUltimoAccessoPS, this.props.datiUltimoAccessoPS))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let ultimoAccessoPS = _.cloneDeep(this.props.datiUltimoAccessoPS);
        ultimoAccessoPS.dataDimissione = Utils.transformTimeStampForFrontend(ultimoAccessoPS.dataDimissione)
        this.setState({ultimoAccessoPS: ultimoAccessoPS});
    }

    handleAggiornaForm = (field, elem) => {
        let ultimoAccessoPS = _.cloneDeep(this.state.ultimoAccessoPS);
        ultimoAccessoPS[field] = elem;
        this.setState({ultimoAccessoPS: ultimoAccessoPS}, () => {
            let ultimoAccessoProps = _.cloneDeep(ultimoAccessoPS)
            this.props.onChangeDatiUltimoAccessoPS("ultimoAccessoPS", ultimoAccessoProps)
        });
    }

    renderBody = () => {
        let ultimoAccesso = _.cloneDeep(this.state.ultimoAccessoPS);
        const icd9ListCustom = _.cloneDeep(icd9List);
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'SETTING_PROV.ULTIMOACCESSOPSNONESITATOINRICOVERO.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"selectSettingsProvenienza"}
                        options={OggettiUtili.settingProvenienza}
                        value={ultimoAccesso.settingProvenienza ? ultimoAccesso.settingProvenienza : defaultSelect}
                        onChange={(elem) => this.handleAggiornaForm("settingProvenienza", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Setting di provenienza non esistente"}
                        fieldLabel={"Setting di provenienza"}
                        handleOnlyValue={true}
                        field={"settingProvenienza"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldId={'COD_IST_CURA_DIMIS.ULTIMOACCESSOPSNONESITATOINRICOVERO.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"codiceIstitutoCuraDimissione"}
                        value={ultimoAccesso.codiceIstitutoCuraDimissione}
                        placeholder={"Codice di cura"}
                        field={"codiceIstitutoCuraDimissione"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        fieldLabel={"Codice istituto di cura di dimissione"}
                        forceReadOnly={this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseRichiesta]?.ultimoAccessoPS?.codiceIstitutoCuraDimissione
                            || this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseValutazione]?.ultimoAccessoPS?.codiceIstitutoCuraDimissione}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedICD9Input
                        fieldId={'DIAGN_DIMISS.ULTIMOACCESSOPSNONESITATOINRICOVERO.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"autocompleteDiagnosiDimissione"}
                        value={ultimoAccesso.diagnosiDimissione}
                        placeholder={"Diagnosi di dimissione"}
                        field={"diagnosiDimissione"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        fieldLabel={"Diagnosi di dimissione"}
                        suggestions={icd9ListCustom}
                        hideUnsetValues={this.props.hideUnsetValues}
                        forceReadOnly={this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseRichiesta]?.ultimoAccessoPS?.diagnosiDimissione
                            || this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseValutazione]?.ultimoAccessoPS?.diagnosiDimissione}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'DATA_DIMISS.ULTIMOACCESSOPSNONESITATOINRICOVERO.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"ultmoAccessoPSDataDimissione"}
                        value={ultimoAccesso.dataDimissione}
                        onChange={(elem) => this.handleAggiornaForm("dataDimissione", elem.target.value)}
                        errorMessage={"Data di dimissione non valida"}
                        fieldLabel={"Data di dimissione"}
                        field={"dataDimissione"}
                        forceReadOnly={this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseRichiesta]?.ultimoAccessoPS?.dataDimissione
                            || this.props.mascheraModificabilita?.[enumRichiesta.attributi.informazioniBaseFaseValutazione]?.ultimoAccessoPS?.dataDimissione}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedSelect
                        fieldId={'SETTING_PIC.ULTIMOACCESSOPSNONESITATOINRICOVERO.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"selectSettingsProvenienza"}
                        options={OggettiUtili.settingPresaInCarico}
                        value={ultimoAccesso.settingPresaInCarico ? ultimoAccesso.settingPresaInCarico : defaultSelect}
                        onChange={(elem) => this.handleAggiornaForm("settingPresaInCarico", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Setting di presa in carico non esistente"}
                        fieldLabel={"Setting di presa in carico"}
                        handleOnlyValue={true}
                        field={"settingPresaInCarico"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"ultimoAccessoPS"}
            title={"Ultimo accesso al PS negli ultimi due anni non esitato in ricovero"}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            accordionReadOnly={!!this.props.accordionReadOnly}
        >{this.renderBody()}</AccordionSezioneRichiesta>
    }
}

UltimoAccessoPS.propTypes = propTypes;
