import azioniRichiesta from "../../../utils/dataset/richiestaData/azioniRichiesta.json";
import statiRichiesta from "../../../utils/dataset/richiestaData/statiRichiesta.json";
import enumRichiesta from "../../../enum/enumRichiesta.json";
import enumsUtente from "../../../enum/enumsUtente.json";
import AuthUtils from "../../../utils/AuthUtils.js";
import Utils from "../../../utils/Utils.js";
import { SessioneUtente } from "web-client-archetype";
import HomeUtils, {getLinkProps, OGGETTO_SUBENTRA} from "../HomeUtils";
import * as moment from "moment";

const pazienteHaCureDomiciliariPrecedenti = "Il paziente in precedenza ha usufruito di cure domiciliari, seleziona per vedere il dettaglio.";

export default class RicercaAssistitiUtils {

    static mappingAssistito(richiesta, assistito, comuni, province, context) {
        let assistitoMapped = {};

        if (!Utils.isObjectEmpty(richiesta)) {
            let comune = comuni?.find(comune => comune.codice === assistito.comune)?.descrizione;
            comune = !!comune && comune.length > 0 ? comune : "";
            let provincia = province?.find(provincia => provincia.codice === assistito.provincia)?.descrizione
            provincia = !!provincia && provincia.length > 0 ? " (" + provincia + ")" : "";
            assistitoMapped = {
                ...assistito,
                dataNascita: Utils.transformTimeStampForFrontend(assistito.dataNascita),
                comune: comune + provincia,
                uuidRichiesta: richiesta?.id
            }
            let valutazione = HomeUtils.getUltimaValutazioneperUtenteLoggato(richiesta);
            let valutazionePreDialogo = richiesta.valutazioniPreDialogo?.filter(valutazione => SessioneUtente.getInstance().idUtente === valutazione.valutatore?.codiceFiscale)?.shift();
            assistitoMapped.primiLink = this.getAzioneRichiestaRicercaAssistiti(richiesta, context, valutazione, valutazionePreDialogo);
            if (AuthUtils.hasRuoloMedicoRichiedenteAndMedicoOspedaliero() || (AuthUtils.hasRuoloMedicoRichiedenteAndMedicoOspedaliero() && AuthUtils.hasRuoloValutatoreAndMedicoOspedaliero())) {
                assistitoMapped.secondiLink = this.getSecondiLink(richiesta, context, valutazione);
            }
            assistitoMapped.messaggioDaVisualizzare = HomeUtils.getMessaggioDaVisualizzare(richiesta, assistitoMapped.primiLink);
            assistitoMapped.linkStatoRichiesta = "";
            assistitoMapped.pazienteHaCureDomiciliariPrecedenti = this.getMsgSePazienteHaAvutoCureDomiciliariPrecedenti(richiesta, context);
            assistitoMapped.tipologia = richiesta.tipologia;
            assistitoMapped.showOnlyRequiredFields = true;
            assistitoMapped.showMultiCompilationPages = false;

            if (Utils.isStringEmptyOrNullOrUndefined(assistitoMapped.codiceFiscale)) {
                assistitoMapped.codiceFiscale = assistitoMapped.identificativoUtente;
            }

            if (Utils.isStringEmptyOrNullOrUndefined(assistitoMapped.mmg)) {
                assistitoMapped.mmg = richiesta?.richiedente?.cognome + " " + richiesta?.richiedente?.nome;
            }
        }
        return assistitoMapped;
    }

    static getSecondiLink(richiesta, context, valutazione) {
        let links = [];
        if (context === "avvioNuovaRichiesta") {
            switch (richiesta.stato) {
                case statiRichiesta.inviataPreDialogo.value:
                case statiRichiesta.inConferimentoPreDialogo.value:
                case statiRichiesta.inValutazionePreDialogo.value:
                case statiRichiesta.valutataPreDialogo.value:
                case statiRichiesta.confermataPreDialogo.value:
                case statiRichiesta.inChiusuraPreDialogo.value:
                case statiRichiesta.inFormalizzazione.value:
                    if (richiesta?.professionistaCreazione?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        links.push({
                            label: azioniRichiesta.modificaDataDimissione.label,
                            linkProps: getLinkProps(azioniRichiesta.modificaDataDimissione.label, richiesta)
                        });
                    }
                    return links;
                case statiRichiesta.inConferimento.value:
                case statiRichiesta.inValutazione.value:
                    if (Utils.isObjectEmpty(valutazione) || (!Utils.isObjectEmpty(valutazione) && (valutazione.stato === statiRichiesta.bozza.value || valutazione.stato === statiRichiesta.confermata.value))) {
                        links.push({
                            label: azioniRichiesta.avviaNuovoPreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.avviaNuovoPreDialogo.label, richiesta)
                        });
                    }
                    if (richiesta?.professionistaCreazione?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        links.push({
                            label: azioniRichiesta.modificaDataDimissione.label,
                            linkProps: getLinkProps(azioniRichiesta.modificaDataDimissione.label, richiesta)
                        });
                    }
                    return links;
                case statiRichiesta.inviata.value:
                case statiRichiesta.valutata.value:
                case statiRichiesta.confermata.value:
                    if (richiesta?.professionistaCreazione?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                        links.push({
                            label: azioniRichiesta.modificaDataDimissione.label,
                            linkProps: getLinkProps(azioniRichiesta.modificaDataDimissione.label, richiesta)
                        });
                        links.push({
                            label: azioniRichiesta.avviaNuovoPreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.avviaNuovoPreDialogo.label, richiesta)
                        })
                    }
                    return links;
            }
        }
        else if (context === "ricercaAssistiti") {
            let element = {secondiLink: []};
            HomeUtils.setAzioneRichiesta(richiesta, element, valutazione, null);
            return element.secondiLink
        }
    }

    static getAzioneRichiestaRicercaAssistiti(richiesta, context, valutazione) {
        const OGGETTO_VISUALIZZA_VALUTAZIONE = {
            label: azioniRichiesta.visualizzaValutazione.label,
            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
        };
        const OGGETTO_ACCEDI_VALUTAZIONE = {
            label: azioniRichiesta.accediValutazione.label,
            linkProps: getLinkProps(azioniRichiesta.accediValutazione.label, richiesta, true)
        };
        const OGGETTO_COMPLETA_VALUTAZIONE = {
            label: azioniRichiesta.completaValutazione.label,
            linkProps: getLinkProps(azioniRichiesta.completaValutazione.label, richiesta)
        };

        if (context === "avvioNuovaRichiesta") {
            if (AuthUtils.hasRuoloMedicoRichiedenteAndMedicoMedicinaGenerale() || AuthUtils.hasRuoloMedicoRichiedenteAndPediatraLiberaScelta()) {
                switch (richiesta.stato) {
                    case statiRichiesta.bozza.value:
                        if (richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            return [{
                                label: azioniRichiesta.completaRichiesta.label,
                                linkProps: getLinkProps(azioniRichiesta.completaRichiesta.label, richiesta)
                            }];
                        }
                        else {
                            return [{
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }];
                        }
                    case statiRichiesta.inviata.value:
                    case statiRichiesta.inConferimento.value:
                    case statiRichiesta.inValutazione.value:
                        if (richiesta.tipologia === enumRichiesta.tipologia.CLASSICA && richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            return [{
                                label: richiesta?.sostituzioneRichiedente ? azioniRichiesta.visualizzaValutazione.label : azioniRichiesta.accediValutazione.label,
                                linkProps: getLinkProps(richiesta?.sostituzioneRichiedente ? azioniRichiesta.visualizzaValutazione.label : azioniRichiesta.accediValutazione.label, richiesta, true)
                            }];
                        }
                        else if (richiesta.tipologia === enumRichiesta.tipologia.CURE_PALLIATIVE && richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            if (valutazione?.valutatore?.codiceFiscale === SessioneUtente.getInstance().idUtente && valutazione?.stato === statiRichiesta.bozza.value) {
                                return [
                                    OGGETTO_COMPLETA_VALUTAZIONE, OGGETTO_ACCEDI_VALUTAZIONE
                                ]
                            }
                            else if (valutazione?.valutatore?.codiceFiscale === SessioneUtente.getInstance().idUtente && valutazione?.stato === statiRichiesta.confermata.value) {
                                return [
                                    OGGETTO_VISUALIZZA_VALUTAZIONE
                                ]
                            }
                        }
                        else {
                            return [{
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }];
                        }
                        break;
                    case statiRichiesta.valutata.value:
                        if (richiesta.tipologia === enumRichiesta.tipologia.CLASSICA && richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            var label = richiesta?.sostituzioneRichiedente ? azioniRichiesta.visualizzaValutazione.label : azioniRichiesta.completaValutazione.label;
                            return [{
                                label: label,
                                linkProps: getLinkProps(label, richiesta, label.startsWith("Visualizza"))
                            }];
                        }
                        else if (richiesta.tipologia === enumRichiesta.tipologia.CURE_PALLIATIVE && richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            return [
                                {
                                    OGGETTO_VISUALIZZA_VALUTAZIONE
                                }
                            ]
                        }
                        else {
                            return [{
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }];
                        }
                    case statiRichiesta.confermata.value:
                    case statiRichiesta.inFormalizzazione.value:
                        return [{
                            label: azioniRichiesta.visualizzaValutazione.label,
                            linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                        }];
                    case statiRichiesta.accettata.value: {
                        if(richiesta?.timestampEsito !== null) {
                            if (richiesta?.formalizzazione?.dataFineProgettoAssistenziale == null)
                                return [{
                                    label: azioniRichiesta.apriCartellaUnica.label,
                                    linkProps: getLinkProps(azioniRichiesta.apriCartellaUnica.label, richiesta)
                                }];
                            const dataFineProgettoAssistenziale = moment(richiesta?.formalizzazione?.dataFineProgettoAssistenziale);
                            return dataFineProgettoAssistenziale.isSameOrAfter(moment()) ?
                                [{
                                    label: azioniRichiesta.apriCartellaUnica.label,
                                    linkProps: getLinkProps(azioniRichiesta.apriCartellaUnica.label, richiesta)
                                }] : [{
                                    label: azioniRichiesta.avviaNuovaRichiesta.label,
                                    linkProps: getLinkProps(azioniRichiesta.avviaNuovaRichiesta.label, richiesta)
                                }]
                        } else {
                            return [{
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }];
                        }
                    }
                    case statiRichiesta.rifiutata.value:
                    case statiRichiesta.annullata.value:
                        if (richiesta?.timestampEsito !== null) {
                            return [{
                                label: azioniRichiesta.avviaNuovaRichiesta.label,
                                linkProps: getLinkProps(azioniRichiesta.avviaNuovaRichiesta.label, richiesta)
                            }];
                        } else {
                            return [{
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }];
                        }
                }
            }
            else if (AuthUtils.hasRuoloMedicoRichiedenteAndMedicoOspedaliero() || (AuthUtils.hasRuoloMedicoRichiedenteAndMedicoOspedaliero() && AuthUtils.hasRuoloValutatoreAndMedicoOspedaliero())) {
                switch (richiesta.stato) {
                    case statiRichiesta.bozzaPreDialogo.value:
                        if (richiesta?.professionistaCreazione?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            return [{
                                label: azioniRichiesta.completaPreDialogo.label,
                                linkProps: getLinkProps(azioniRichiesta.completaPreDialogo.label, richiesta)
                            }];
                        }
                        else return [{
                            label: azioniRichiesta.avviaNuovoPreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.avviaNuovoPreDialogo.label, richiesta)
                        }];
                    case statiRichiesta.inviataPreDialogo.value:
                    case statiRichiesta.inConferimentoPreDialogo.value:
                    case statiRichiesta.inValutazionePreDialogo.value:
                        if (richiesta?.professionistaCreazione?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            return [{
                                label: azioniRichiesta.accediValutazionePreDialogo.label,
                                linkProps: getLinkProps(azioniRichiesta.accediValutazionePreDialogo.label, richiesta, true)
                            }];
                        }
                        else return [{
                            label: azioniRichiesta.avviaNuovoPreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.avviaNuovoPreDialogo.label, richiesta)
                        }];
                    case statiRichiesta.valutataPreDialogo.value:
                        if (richiesta?.professionistaCreazione?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            return [{
                                label: azioniRichiesta.completaValutazionePreDialogo.label,
                                linkProps: getLinkProps(azioniRichiesta.completaValutazionePreDialogo.label, richiesta)
                            }];
                        }
                        else return [{
                            label: azioniRichiesta.avviaNuovoPreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.avviaNuovoPreDialogo.label, richiesta)
                        }];
                    case statiRichiesta.confermataPreDialogo.value:
                    case statiRichiesta.inChiusuraPreDialogo.value:
                        if (richiesta?.professionistaCreazione?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            return [{
                                label: azioniRichiesta.visualizzaValutazionePreDialogo.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazionePreDialogo.label, richiesta, true)
                            }];
                        }
                        else return [{
                            label: azioniRichiesta.avviaNuovoPreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.avviaNuovoPreDialogo.label, richiesta)
                        }];
                    case statiRichiesta.bozza.value:
                        return [{
                            label: azioniRichiesta.avviaNuovoPreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.avviaNuovoPreDialogo.label, richiesta)
                        }];
                    case statiRichiesta.inviata.value:
                        if (richiesta?.professionistaCreazione?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            return [{
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }];
                        }
                        else {
                            return [{
                                label: azioniRichiesta.avviaNuovoPreDialogo.label,
                                linkProps: getLinkProps(azioniRichiesta.avviaNuovoPreDialogo.label, richiesta)
                            }];
                        }
                    case statiRichiesta.inConferimento.value:
                    case statiRichiesta.inValutazione.value:
                        if (!Utils.isObjectEmpty(valutazione)) {
                            if (valutazione.stato === statiRichiesta.bozza.value) {
                                return [{
                                    label: azioniRichiesta.completaValutazione.label,
                                    linkProps: getLinkProps(azioniRichiesta.completaValutazione.label, richiesta)
                                }];
                            }
                            else if (valutazione.stato === statiRichiesta.confermata.value) {
                                return [{
                                    label: azioniRichiesta.visualizzaValutazione.label,
                                    linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                                }];
                            }
                            else {
                                return [{
                                    label: azioniRichiesta.avviaNuovoPreDialogo.label,
                                    linkProps: getLinkProps(azioniRichiesta.avviaNuovoPreDialogo.label, richiesta)
                                }];
                            }
                        }
                        else {
                            return [{
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }];
                        }
                    case statiRichiesta.valutata.value:
                    case statiRichiesta.confermata.value:
                    case statiRichiesta.inFormalizzazione.value:
                        if (richiesta?.professionistaCreazione?.codiceFiscale === SessioneUtente.getInstance().idUtente) {
                            return [{
                                label: azioniRichiesta.visualizzaValutazione.label,
                                linkProps: getLinkProps(azioniRichiesta.visualizzaValutazione.label, richiesta, true)
                            }];
                        }
                        else {
                            return [{
                                label: azioniRichiesta.avviaNuovoPreDialogo.label,
                                linkProps: getLinkProps(azioniRichiesta.avviaNuovoPreDialogo.label, richiesta)
                            }];
                        }
                    case statiRichiesta.accettata.value: {
                        const dataFineProgettoAssistenziale = moment(richiesta?.formalizzazione?.dataFineProgettoAssistenziale);
                        return dataFineProgettoAssistenziale.isSameOrAfter(moment()) ?
                            [{
                                label: azioniRichiesta.apriCartellaUnica.label,
                                linkProps: getLinkProps(azioniRichiesta.apriCartellaUnica.label, richiesta)
                            }] :
                            [{
                                label: azioniRichiesta.avviaNuovoPreDialogo.label,
                                linkProps: getLinkProps(azioniRichiesta.avviaNuovoPreDialogo.label, richiesta)
                            }];
                    }
                    case statiRichiesta.rifiutata.value:
                    case statiRichiesta.annullata.value:
                        return [{
                            label: azioniRichiesta.avviaNuovoPreDialogo.label,
                            linkProps: getLinkProps(azioniRichiesta.avviaNuovoPreDialogo.label, richiesta)
                        }];
                }
            }
        }
        else if (context === "ricercaAssistiti") {
            if(richiesta.stato === statiRichiesta.accettata.value &&
                (valutazione.valutatore.codiceFiscale === SessioneUtente.getInstance().idUtente ||
                    (AuthUtils.hasRuoloMedicoRichiedenteAndMedicoMedicinaGenerale() || AuthUtils.hasRuoloMedicoRichiedenteAndPediatraLiberaScelta()))){
                return [{
                    label: azioniRichiesta.apriCartellaUnica.label,
                    linkProps: getLinkProps(azioniRichiesta.apriCartellaUnica.label, richiesta)
                }];
            } else {
                let element = {primiLink: []};
                HomeUtils.setAzioneRichiesta(richiesta, element, valutazione, null);
                return element.primiLink
            }
        }
    }

    static getMsgSePazienteHaAvutoCureDomiciliariPrecedenti(richiesta, context) {

        let dataOdierna = moment();
        let formalizzazione = richiesta?.formalizzazione;
        let dataFineProgettoAssistenziale = moment(formalizzazione?.dataFineProgettoAssistenziale, "DD/MM/YYYY");

        if (context === "avvioNuovaRichiesta") {
            if (dataFineProgettoAssistenziale.isBefore(dataOdierna)) {
                switch (richiesta.stato) {
                    case statiRichiesta.accettata.value:
                    case statiRichiesta.rifiutata.value:
                    case statiRichiesta.annullata.value:
                        return pazienteHaCureDomiciliariPrecedenti;
                    default:
                        return null;
                }
            }
            else return null
        }
        else if (context === "ricercaAssistiti") {
            if (dataFineProgettoAssistenziale.isBefore(dataOdierna)) {
                switch (richiesta.stato) {
                    case statiRichiesta.accettata.value:
                        return pazienteHaCureDomiciliariPrecedenti;
                    default:
                        return null;
                }
            }
            else return null;
        }
    }
}
