import React, {Component} from "react";
import {Card, CardBody, CardHeader, Collapse} from "reactstrap";
import styles from "./FiltroRapidoRichieste.module.css";
import {Icon} from "web-client-archetype";
import CheckBox from "../../generalComponents/checkbox/CheckBox";
import * as _ from "lodash";
import RichiestaADIService from "../../../service/RichiestaADIService";
import {map} from "rxjs/operators";
import {forkJoin} from "rxjs";
import {mostraSpinner, nascondiSpinner} from "../../../App";
import PropTypes from "prop-types";
import HomeUtils from "../HomeUtils";
import AuthUtils from "../../../utils/AuthUtils";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";

export default class FiltroRapidoRichieste extends Component {

    state = {
        collapse: true,
        filtriRichiesta: {
            priorita: null,
            classificazione: null,
            stato: null
        },
        countRichiesteFiltrate: {
            priorita01: null,
            priorita02: null,
            priorita03: null,
            adiProgrammata: null,
            adiConInfermiere: null,
            adiSenzaInfermiere: null,
            adiRiabilitativa: null,
            statoBozza: null,
            statoInoltrate: null
        }
    };

    componentDidMount() {
        HomeUtils.changeFiltriRapidiFromFiltriRichiesteSubject.subscribe(filtriList => {
            if (filtriList?.length > 0) {
                let countRichiesteFiltrateClone = _.cloneDeep(this.state.countRichiesteFiltrate);
                filtriList.forEach(e => {
                    const field = e.field === "priorita" ? `priorita${e.value}` : e.field;
                    countRichiesteFiltrateClone[field] = e.count;
                });
                this.setState({countRichiesteFiltrate: countRichiesteFiltrateClone});
            }
        });

        let countRichiesteFiltrate = _.cloneDeep(this.state.countRichiesteFiltrate);
        let obsList = [];

        mostraSpinner();

        obsList.push(this.calcolaElementiPerFiltro("livelloPriorita::01", countRichiesteFiltrate, "priorita01"));
        obsList.push(this.calcolaElementiPerFiltro("livelloPriorita::02", countRichiesteFiltrate, "priorita02"));
        obsList.push(this.calcolaElementiPerFiltro("livelloPriorita::03", countRichiesteFiltrate, "priorita03"));
        obsList.push(this.calcolaElementiPerFiltro("classificazione::PROGRAMMATA", countRichiesteFiltrate, "adiProgrammata"));
        obsList.push(this.calcolaElementiPerFiltro("classificazione::RIABILITATIVA", countRichiesteFiltrate, "adiRiabilitativa"));
        obsList.push(this.calcolaElementiPerFiltro("classificazione~~*CON_INFERMIERE*", countRichiesteFiltrate, "adiConInfermiere"));
        obsList.push(this.calcolaElementiPerFiltro("classificazione~~*SENZA_INFERMIERE*", countRichiesteFiltrate, "adiSenzaInfermiere"));

        if (AuthUtils.hasRuoloMedicoMedicinaGenerale()) {
            obsList.push(this.calcolaElementiPerFiltro("stato::BOZZA", countRichiesteFiltrate, "statoBozza"));
            obsList.push(this.calcolaElementiPerFiltro("timestampCompletamento!!null", countRichiesteFiltrate, "statoInoltrate"));
        }

        forkJoin(obsList).subscribe(() => this.setState({countRichiesteFiltrate}, nascondiSpinner));
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.filtriRichiesta, this.props.filtriRichiesta) && this.props.filtriRichiesta != null) {
            this.setState({filtriRichiesta: this.props.filtriRichiesta})
        }
    }

    calcolaElementiPerFiltro = (filtriRicerca, objectCount, fieldObjectCount) => {
        return RichiestaADIService.contaFiltriRapidi(0, filtriRicerca).pipe(map(response => {
            if (response) objectCount[fieldObjectCount] = response.data["elementiTotali"];
            return response;
        }));
    }

    renderRadioButtonsGroup = (groupTitle, items, field, spaceClass = null) => {
        const idRadioCheckboxBtn = "radioBtn" + field.charAt(0).toUpperCase() + field.slice(1);

        return <>
            {!!groupTitle && <div className={"row pl-1"}>
                <div className={"col-12"}>{groupTitle}</div>
            </div>}
            {items.map((item, index) => {
                const isItemChecked = item.value === this.state.filtriRichiesta[field];
                return <div key={index} className={['row', spaceClass ?? 'pb-3'].join(' ')}>
                    <div className={"col-9"}>
                        <CheckBox
                            id={idRadioCheckboxBtn + index}
                            field={"filtriRichiesta"}
                            onChange={(_f, value) => this.handleAggiornaFiltriRichieste(field, value, item)}
                            text={item.label}
                            checked={isItemChecked}
                            className={styles.customCheckboxLabel}
                            styleLabel={isItemChecked ? {textDecoration: "underline"} : {}}
                        />
                    </div>
                    <div className={"col-3 text-right align-self-center"}>
                        <span style={isItemChecked
                            ? {color: "black", fontWeight: "bold", fontSize: "12px"}
                            : {color: "rgb(25 25 26 / 80%)", fontSize: "12px"}}>{item.numeroElementi}</span>
                    </div>
                </div>
            })}
        </>;
    }

    handleAggiornaFiltriRichieste = (field, value, item) => {
        console.log(field);
        let filtriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
        const currentFilterValue = value?.target?.checked ? item.value : null;
        filtriRichiesta[field] = currentFilterValue;
        this.setState({filtriRichiesta}, () => this.applicaFiltriRichieste(currentFilterValue, item.objectField));
    }

    applicaFiltriRichieste = (currentFilterValue, objectField) => {
        if (currentFilterValue) {
            let countRichiesteFiltrate = _.cloneDeep(this.state.countRichiesteFiltrate);

            mostraSpinner();

            this.calcolaElementiPerFiltro(currentFilterValue, countRichiesteFiltrate, objectField)
                .subscribe(() => this.setState({countRichiesteFiltrate},
                    () => {
                        nascondiSpinner();
                        this.props.impostaFiltri(this.state.filtriRichiesta)
                    }));
        } else {
            this.props.impostaFiltri(this.state.filtriRichiesta);
        }
    }

    render() {
        const itemsPrioritaGroup = [
            {
                value: "livelloPriorita::03",
                label: "Richieste Urgenti (72 ore)",
                numeroElementi: this.state.countRichiesteFiltrate["priorita03"],
                objectField: "priorita03"
            },
            {
                value: "livelloPriorita::01",
                label: "Richieste Brevi (10 gg)",
                numeroElementi: this.state.countRichiesteFiltrate["priorita01"],
                objectField: "priorita01"
            },
            {
                value: "livelloPriorita::02",
                label: "Richieste Normali (30 gg)",
                numeroElementi: this.state.countRichiesteFiltrate["priorita02"],
                objectField: "priorita02"
            }
        ];

        const itemsClassificazioneGroup = [
            {
                value: "classificazione::PROGRAMMATA",
                label: "ADI programmata",
                numeroElementi: this.state.countRichiesteFiltrate["adiProgrammata"],
                objectField: "adiProgrammata"
            },
            {
                value: "classificazione~~*CON_INFERMIERE*",
                label: "ADI con infermiere",
                numeroElementi: this.state.countRichiesteFiltrate["adiConInfermiere"],
                objectField: "adiConInfermiere"
            },
            {
                value: "classificazione~~*SENZA_INFERMIERE*",
                label: "ADI senza infermiere",
                numeroElementi: this.state.countRichiesteFiltrate["adiSenzaInfermiere"],
                objectField: "adiSenzaInfermiere"
            },
            {
                value: "classificazione::RIABILITATIVA",
                label: "ADI riabilitativa",
                numeroElementi: this.state.countRichiesteFiltrate["adiRiabilitativa"],
                objectField: "adiRiabilitativa"
            }
        ];

        const itemsStatoRichiestaGroup = [
            {
                value: "stato::BOZZA",
                label: "Richieste in BOZZA",
                numeroElementi: this.state.countRichiesteFiltrate["statoBozza"],
                objectField: "statoBozza"
            },
            {
                value: "timestampCompletamento!!null",
                label: "Richieste Inoltrate",
                numeroElementi: this.state.countRichiesteFiltrate["statoInoltrate"],
                objectField: "statoInoltrate"
            }
        ];

        return <Card className={"bg-transparent"} key={0}>
            <CardHeader className={styles.headerTitle}
                        onClick={() => this.setState({collapse: !this.state.collapse})}
                        data-event={0}>
                <div className={"row"}>
                    <div className={"col-2 pb-2"}>
                        <Icon id={this.state.collapse ? "it-collapse" : "it-expand"}
                              className={["icon-md"]}
                              style={{fill: "black"}}/>
                    </div>
                    <div className={"col-10 align-self-center"}>
                        <span>Filtri rapidi</span>
                    </div>
                </div>
            </CardHeader>
            <Collapse isOpen={this.state.collapse}>
                <CardBody className={['pl-0', styles.radioCheckboxGroup].join(' ')}>
                    {this.renderRadioButtonsGroup("Priorità", itemsPrioritaGroup, "priorita")}
                    {this.renderRadioButtonsGroup("Tipologia", itemsClassificazioneGroup, "classificazione")}
                    {AuthUtils.hasRuoloMedicoMedicinaGenerale() ? this.renderRadioButtonsGroup("Stato richiesta", itemsStatoRichiestaGroup, "stato", "pt-3"): null }
                </CardBody>
            </Collapse>
        </Card>;
    }

}

FiltroRapidoRichieste.propTypes = {
    filtriRichiesta: PropTypes.object,
    impostaFiltri: PropTypes.func
}
