import React from "react";
import AccordionSecondLevel from "../../../accordionSezioneRichiesta/AccordionSecondLevel";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import oggettiUtili from "../../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../../roleBasedComponents/RoleBasedComponents";
import Utils, {closeModaleStyle} from "../../../../utils/Utils";
import disciplinaData from "../../../../utils/dataset/disciplinaData/disciplinaSpecialisti.json";
import {v4 as uuid} from "uuid";
import {Button, SessioneUtente} from "web-client-archetype";
import {RoleBasedContext} from "../../../../utils/RoleBasedContext";
import AuthUtils from "../../../../utils/AuthUtils";
import ModalHelper from "../../../modale/ModalHelper";
import routePath from "../../../../utils/route/route-path.json";
import enumQuestionario from "../../../../enum/enumQuestionario.json";
import {defaultQuestionario, setRichiestaQuestionario} from "../../../../utils/QuestionariUtils";
import {Redirect} from "react-router-dom";
import AccordionHelper from "../../../../service/AccordionHelper";

const propTypes = {
    dati: PropTypes.any,
    datiNecessitaAssistenzaSanitaria: PropTypes.object,
    salvaBozza: PropTypes.func,
    richiesta: PropTypes.func,
    onChangeDatiFigureProfessionaliSupportoAValutazione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    onChangeRequiredFieldsNumber: PropTypes.func,
    firstPageName: PropTypes.string,
    previousPageOnlyRequiredFields: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultProfessionistaSupportoAValutazione = {
    id: null,
    figureRichieste: [],
    note: null
}

const defaultFiguraRichiesta = {
    id: null,
    ruolo: null,
    disciplina: null,
    codiceFiscale: null
}

export default class FigureProfessionaliSupportoAValutazioneSottoAccordion extends AccordionSecondLevel {

    listaDisciplineSelect = disciplinaData.map(el => ({value: el.codice, label: el.descrizione}));

    constructor(props) {
        super(props);
        this.state = {
            dati: this.initAccordionObject(),
            showAlertSurveyToCompile: false,
            redirectToSurvey: false,
            richiestaForSurveyProp: null
        };
    }

    componentDidMount() {
        this.contaCampiObbligatoriMancanti();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati))
            this.setState({dati: this.initAccordionObject()}, this.contaCampiObbligatoriMancanti);
    }

    contaCampiObbligatoriMancanti = () => {
        let dati = _.cloneDeep(this.state.dati);

        let numCampi = 0;
        if (Utils.isStateRichiesta(this.props.pageState)) {
            (dati?.figureRichieste ?? defaultFiguraRichiesta).forEach(el => {
                // solo il campo disciplina è obbligatorio se è stato scelto il ruolo SPECIALISTA
                if (el.ruolo === "06" && !el.disciplina) numCampi += 1;
            });
        }

        this.props.onChangeRequiredFieldsNumber(numCampi);
    }

    initAccordionObject = () => {
        let figureProfessionaliSupportoAValutazione = _.cloneDeep(this.props.dati);
        if (figureProfessionaliSupportoAValutazione?.length > 0) {
            return {
                id: figureProfessionaliSupportoAValutazione[0].id,
                figureRichieste: Utils.getDistinctListByField(figureProfessionaliSupportoAValutazione.flatMap(p => p.figureRichieste ?? []), "ruolo"),
                note: figureProfessionaliSupportoAValutazione[0].note
            };
        }

        return {figureRichieste: [], note: null};
    }

    formatObjectForBackend = () => {
        const figureMapper = f => ({
            id: f.id,
            ruolo: f.ruolo,
            disciplina: f.disciplina,
            codiceFiscale: f.codiceFiscale
        });
        let figure = this.state.dati.figureRichieste.filter(el => el.ruolo);
        let list = [{
            ...defaultProfessionistaSupportoAValutazione,
            id: this.state.dati.id ?? uuid(),
            note: this.state.dati.note,
            figureRichieste: figure.map(figureMapper)
        }];

        this.props.onChangeDatiFigureProfessionaliSupportoAValutazione("professionistiSupportoAValutazione", list);
    }

    handleAggiornaForm = (field, elem, showAlertSurveyToCompile = false) => {
        let dati = _.cloneDeep(this.state.dati);
        dati[field] = elem;
        this.setState({dati, showAlertSurveyToCompile}, () => {
            this.contaCampiObbligatoriMancanti();
            this.formatObjectForBackend();
        });
    }

    renderFiguraProfessionale = (figuraProfessionale, indexFigura, readOnly) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'FIGURE_PROF_VALUTAZIONE.FIGUREPROFESSIONALIPERSUPPORTOALLAVALUTAZIONE.RICHIESTACAMBIOSETTING'}
                    pageState={this.props.pageState}
                    id={"selectFigureProfessionaliSupportoAValutazione"}
                    className={"selectFigureProfessionaliSupportoAValutazione"}
                    options={oggettiUtili.figureProfessionaliPerValutazione}
                    value={figuraProfessionale.ruolo}
                    onChange={(elem) => {
                        let listaFigureRichiestaClone = _.cloneDeep(this.state.dati.figureRichieste);
                        listaFigureRichiestaClone[indexFigura].ruolo = elem;
                        if (!_.isEqual(elem, this.state.dati.figureRichieste[indexFigura].ruolo))
                            listaFigureRichiestaClone[indexFigura].disciplina = null;

                        const isValidFiguraRichiesta = listaFigureRichiestaClone?.length > 1 && _.isEqual(elem, oggettiUtili.figureProfessionaliPerValutazione[0].value);
                        const showAlertSurveyToCompile = AuthUtils.hasRuoloMedicoMedicinaGenerale()
                            && !_.isEqual(elem, this.state.dati.figureRichieste[indexFigura].ruolo)
                            && !!elem && !_.isEqual(elem, oggettiUtili.figureProfessionaliPerValutazione[0].value)
                            && this.props.datiNecessitaAssistenzaSanitaria?.PSAN?.punteggiIntermedi?.find(p => p.id === 1)?.valore == null;
                        AccordionHelper.visibilityPsanSubject.next(showAlertSurveyToCompile || isValidFiguraRichiesta);
                        AccordionHelper.isPsanSurveyRequired = showAlertSurveyToCompile || isValidFiguraRichiesta;

                        this.handleAggiornaForm("figureRichieste", listaFigureRichiestaClone, showAlertSurveyToCompile);
                    }}
                    isSearchable={false}
                    fieldLabel={"Figure professionali per valutazione"}
                    handleOnlyValue={true}
                    field={"ruolo"}
                    forceReadOnly={readOnly}
                />
                {figuraProfessionale.ruolo === "06" &&
                <RoleBasedSelect
                    fieldId={'FIGURE_PROF_VALUTAZIONE.FIGUREPROFESSIONALIPERSUPPORTOALLAVALUTAZIONE.RICHIESTACAMBIOSETTING'}
                    pageState={this.props.pageState}
                    id={"selectDisciplinaFigureSupportoValutazione"}
                    className={"selectDisciplinaFigureSupportoValutazione"}
                    options={this.listaDisciplineSelect}
                    value={figuraProfessionale.disciplina}
                    onChange={(elem) => {
                        let listaFigureRichiestaClone = _.cloneDeep(this.state.dati.figureRichieste);
                        listaFigureRichiestaClone[indexFigura].disciplina = elem;
                        this.handleAggiornaForm("figureRichieste", listaFigureRichiestaClone);
                    }}
                    isSearchable={false}
                    fieldLabel={"Specificare"}
                    fieldRequired={true}
                    handleOnlyValue={true}
                    field={"disciplina"}
                    forceReadOnly={readOnly}
                />}
            </FieldsRow>
        );
    }

    addNewFiguraProfessionale = () => {
        let list = _.cloneDeep(this.state.dati.figureRichieste);
        list.push({
            ..._.cloneDeep(defaultFiguraRichiesta),
            id: uuid(),
            codiceFiscale: SessioneUtente.getInstance().idUtente
        });
        this.handleAggiornaForm("figureRichieste", list);
    }

    removeFiguraProfessionale = (indexFiguraProfessionale) => {
        let list = _.cloneDeep(this.state.dati.figureRichieste);
        list.splice(indexFiguraProfessionale, 1);

        const areValidFigureProfessionali = list?.length > 1
            || list?.find(fp => _.isEqual(fp.ruolo, oggettiUtili.figureProfessionaliPerValutazione[0].value)) == null;
        AccordionHelper.visibilityPsanSubject.next(areValidFigureProfessionali);
        AccordionHelper.isPsanSurveyRequired = areValidFigureProfessionali;

        this.handleAggiornaForm("figureRichieste", list);
    }

    renderAlertSurveyToCompile = () => {
        const footerModale = (
            <>
                <Button
                    onClick={() => {
                        return this.props.salvaBozza(false, true, this.props.richiesta).subscribe((data) => {
                            if (data) {
                                this.setState({
                                    redirectToSurvey: true,
                                    richiestaForSurveyProp: data
                                }, () => closeModaleStyle());
                            }
                        });
                    }}
                    id={"goToSurveyBtn"}
                    text="Vai al questionario"
                    className={'btn btn-adi btnOrangeOutline'}
                />
                <Button
                    onClick={() => this.setState({showAlertSurveyToCompile: false}, () => closeModaleStyle())}
                    id={"chiudiBtn"}
                    text="Chiudi"
                    className={'btn btn-adi btnWhiteOutlineBorderOrange'}
                />
            </>
        );
        
        return <ModalHelper
            id={'idModaleCompilaPSAN_VIP'}
            title={"Per le ADI non programmate è obbligatorio compilare e confermare il questionario PSAN (Punteggio VIP)."}
            titleClassName={"modal-header d-inline text-center backgroundOrange"}
            containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
            contentClassName={"modal-content"}
            body={"Si vuole procedere con la compilazione?"}
            footer={footerModale}
            footerClassName={"modal-footer justify-content-around"}
            bodyClassName={"modal-body d-flex align-items-center f-weight-600 mx-auto"}
        />;
    }

    redirectToSurveyCompilation = () => {
        let baseUrl = Utils.isStateRichiesta(this.props.pageState) ? routePath.inserimento_richiesta : routePath.valutazione_multidimensionale;
        let questionario = this.props.datiNecessitaDiAssistenzaSanitaria?.PSAN ?? _.cloneDeep(defaultQuestionario);
        let richiesta = _.cloneDeep(this.state.richiestaForSurveyProp);

        const pathRichiestaQuestionario = "anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria.PSAN";
        setRichiestaQuestionario(richiesta, questionario, pathRichiestaQuestionario);

        AccordionHelper.setIdSelectedAccordions("professionistiSupportoAValutazione|figureProfessionaliAssistenzialiSub");

        return <Redirect to={{
            pathname: baseUrl.concat(routePath.compila_questionario),
            richiesta: richiesta,
            tipoQuestionario: enumQuestionario.tipoQuestionario.PSAN,
            pageState: this.props.pageState,
            pathRichiestaQuestionario: pathRichiestaQuestionario,
            showOnlyRequiredFields: this.context.showOnlyRequiredFields,
            redirectPage: window.location.pathname,
            firstPageName: this.props.firstPageName,
            previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields
        }}/>;
    }

    renderBodySezione = () => {
        let dati = _.cloneDeep(this.state.dati);
        const readOnlyFigure = this.context.forceReadOnly || (dati?.figureRichieste.map(figura => Utils.isStateValutazione(this.props.pageState) && figura.codiceFiscale !== SessioneUtente.getInstance().idUtente));
        const isListaValida = (!dati.figureRichieste.length || dati.figureRichieste.every(f => {
            if (Utils.isStateRichiesta(this.props.pageState)) return f.ruolo && (f.ruolo !== "06" || (f.ruolo === "06" && f.disciplina))
            else return f.ruolo
        }));

        if (this.state.redirectToSurvey) return this.redirectToSurveyCompilation();

        return (
            <>
                {this.state.showAlertSurveyToCompile && this.renderAlertSurveyToCompile()}

                <SectionList
                    title={'Figura professionale di supporto alla valutazione'}
                    data={dati?.figureRichieste}
                    renderSection={this.renderFiguraProfessionale}
                    addNewSectionCallback={this.addNewFiguraProfessionale}
                    removeSectionCallback={this.removeFiguraProfessionale}
                    pageState={this.props.pageState}
                    keyFieldId={'FIGURE_PROF_VALUTAZIONE.FIGUREPROFESSIONALIPERSUPPORTOALLAVALUTAZIONE.RICHIESTACAMBIOSETTING'}
                    field={'figureRichieste'}
                    atLeastOne={true}
                    forceReadOnly={readOnlyFigure}
                    addButtonVisibilityHandler={!this.context.forceReadOnly && isListaValida && !AuthUtils.hasUtenteRuoloAssistenteSociale() && !AuthUtils.hasUtenteRuoloFisiatra()}
                />

                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'NOTE.FIGUREPROFESSIONALISUPPORTOVALUTAZIONE.RICHIESTACAMBIOSETTING'}
                        fieldLabel={'Note'}
                        pageState={this.props.pageState}
                        value={dati?.note}
                        placeholder={"Inserisci note"}
                        field={"note"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"note"}
                        type={"textarea"}
                        maxlength={"500"}
                        rows={"8"}
                        showAvailableCharsLabel={true}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"figureProfessionaliAssistenzialiSub"}
                title={"Figure professionali di supporto alla valutazione"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }
}

FigureProfessionaliSupportoAValutazioneSottoAccordion.propTypes = propTypes;
FigureProfessionaliSupportoAValutazioneSottoAccordion.contextType = RoleBasedContext;
