import auth from "../utils/AthnAuth";
import {catchError, map} from 'rxjs/operators'
import config from "../config/serviceConfig";
import {from} from "rxjs";
import Utils from "../utils/Utils";

export default class ToponomasticaService {

    static urlComuni = config['ms_toponomastica'].comuni;
    static urlProvince = config['ms_toponomastica'].province;
    static urlNazioni = config['ms_toponomastica'].nazioni;
    static urlNazioniIstat = config['ms_toponomastica'].nazioniIstat;
    static urlCap = config['ms_toponomastica'].cap;
    static urlSigleProvince = config['ms_toponomastica'].sigleProvince;

    static getComuni(lingua) {
        let instance = auth._httpClient;
        let obs = from(instance.get(ToponomasticaService.urlComuni.concat("/").concat(lingua)));

        return obs.pipe(
            map(response => {
                sessionStorage.setItem("comuni", JSON.stringify(response.data));
                return response;
            }), catchError(() => {
                console.log("Errore recupero comuni")
                return [];
            }));
    }

    static getProvince(lingua) {
        let instance = auth._httpClient;
        let obs = from(instance.get(ToponomasticaService.urlProvince.concat("/").concat(lingua)));

        return obs.pipe(map(response => {
                sessionStorage.setItem("province", JSON.stringify(response.data));
                return response;
            }
        ), catchError(() => {
            console.log("Errore recupero province")
            return [];
        }));
    }

    static getNazioni(lingua) {
        let instance = auth._httpClient;
        let obs = from(instance.get(ToponomasticaService.urlNazioni.concat("/").concat(lingua)));

        return obs.pipe(map(response => {
                sessionStorage.setItem("nazioni", JSON.stringify(response.data));
                return response;
            }), catchError(() => {
            console.log("Errore recupero nazioni")
            return [];
        }));
    }

    static getNazioniIstat(lingua) {
        let instance = auth._httpClient;
        let obs = from(instance.get(ToponomasticaService.urlNazioniIstat.concat("/").concat(lingua)));

        return obs.pipe(map(response => {
            sessionStorage.setItem("nazioniIstat", JSON.stringify(response.data));
            return response;
        }), catchError(() => {
            console.log("Errore recupero nazioni")
            return [];
        }));
    }

    static getCap(codiceProvincia) {
        let instance = auth._httpClient;
        let params = {};
        if (!Utils.isStringEmptyOrNullOrUndefined(codiceProvincia))
            params.codiceIstatProvincia = codiceProvincia;
        let obs = from(instance.get(ToponomasticaService.urlCap, {params: params}));

        return obs.pipe(map(response => {
            sessionStorage.setItem("cap", JSON.stringify(response.data));
            return response;
        }), catchError(() => {
            console.log("Errore recupero CAP")
            return [];
        }));
    }

    static getSigleProvince(lingua) {
        let instance = auth._httpClient;
        let obs = from(instance.get(ToponomasticaService.urlSigleProvince.concat("/").concat(lingua)));

        return obs.pipe(map(response => {
                sessionStorage.setItem("sigleProvince", JSON.stringify(response.data));
                return response;
            }
        ), catchError(() => {
            console.log("Errore recupero sigle province")
            return [];
        }));
    }
}
