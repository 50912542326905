import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button} from "web-client-archetype";
import {Link} from "react-router-dom";
import styles from "./buttonsBoxStick.module.css";

/**
 * @function ButtonsBoxStick renderizza il componente contenente i 3 bottoni Indietro Salva, Prosegui
 * @param btnList contiene l'elenco dei bottoni da visualizzare nella ButtonsBoxStick con la seguente struttura
 *   {aling, className, id, isvisible, onclickFunction, text}
 * @param parentClass
 */

function ButtonsBoxStick ({btnList, parentClass}) {

    /**
     * @function renderLink renderizza l'oggetto per creare il bottone Indietro
     * @param btn le proprieta del singolo bottone
     * @param colNumber numero delle colonne che conterrà la barra dei bottoni
     *
     */
    const renderLink = (btn, colNumber) => {
        const {id, text, className, onclickFunction, aling, disabled, to} = btn;
        return (<div className={aling ? `col-${colNumber} text-${aling}` : ""}>
            <Link to={to}>
                <Button
                    id={id}
                    text={text}
                    className={`btn btn-adi ${className}`}
                    onClick={() => onclickFunction && onclickFunction()}
                    disabled={disabled}
                />
            </Link>
        </div>)
    }

    /**
     * @function renderButton renderizza l'oggetto per creare il bottone (Salva, Salva In bozza, Prosegui)
     * @param btn le proprieta del singolo bottone
     * @param colNumber numero delle colonne che conterrà la barra dei bottoni
     *
     */

    const renderButton = (btn, colNumber) => {
        const {id, text, className, onclickFunction, aling, disabled} = btn;
        return (<div className={aling ? `col-${colNumber} text-${aling}` : ""}>
            <Button
                id={id}
                text={text}
                className={`btn btn-adi ${className}`}
                onClick={() => onclickFunction && onclickFunction()}
                disabled={disabled}
            />
        </div>)
    }

    /**
     * @function renderGroupButton renderizza l'oggetto contenente tutti i bottoni profilati per le varie utenze
     * @param gropBtnList l'elenco dei bottoni da inserire nella renderGroupButton
     * @param colNumber il numero di lolonne da visualizzare in base al numero di bottoni presenti nella renderGroupButton
     *
     */
    const renderGroupButton = (gropBtnList, colNumber) => {
        return (
            <div className={`col-${colNumber}`}>
                <div className="row">
                    {gropBtnList.map(btnChild => {
                        return (<div className={"mr-2"}>
                            {btnChild.isvisible ? btnChild?.isLink ? renderLink(btnChild) : renderButton(btnChild) : null}
                        </div>)
                    })}
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={styles.buttonsBoxStickContainer}>
                <div className={styles.stickBottom.concat(` bottoniLayout py-3 px-5 mx-auto align-items-center ${parentClass}`)}>
                    {btnList.map((btn) => {
                        const colNumber = String((12 / btnList?.filter(bt => bt.isvisible)?.length));
                        if (!btn?.isvisible) return null;
                        if (btn?.isLink) return renderLink(btn, colNumber)
                        if (btn?.gropBtnList) return renderGroupButton(btn.gropBtnList, colNumber)
                        return renderButton(btn, colNumber);
                    })}
                </div>
            </div>
        </>
    )
}

ButtonsBoxStick.propTypes = {
    btnList: PropTypes.node,
    parentClass: PropTypes.string
};

ButtonsBoxStick.defaultProps = {
    btnList: [],
    parentClass: ""
};

export default ButtonsBoxStick;
