
const globals = {
  //'app-nome-dominio': process.env.REACT_APP_APP_NOME_DOMINIO,
  //'app-url-backend': process.env.REACT_APP_APP_URL_BACKEND,
  'app-url-ms-anagrafica': process.env.REACT_APP_APP_URL_BACKEND_MSANAGRAFICALOCALE,
  'app-url-ms-toponomastica': process.env.REACT_APP_APP_URL_BACKEND_MSTOPONOMASTICA,
  'app-url-ms-utenza': process.env.REACT_APP_APP_URL_BACKEND_MSAPMS,
  'app-url-ms-utenza-v2': process.env.REACT_APP_APP_URL_BACKEND_MSAPMS_V2,
  'app-url-ms-richiestaadi': process.env.REACT_APP_APP_URL_BACKEND_MSRICHIESTAADI,
  'app-url-websocket': process.env.REACT_APP_APP_URL_WEBSOCKET,
  'app-url-ms-gestione-password': process.env.REACT_APP_APP_URL_BACKEND_MSGESTIONEPASSWORD,
  
  'versione-tipologia-classica': process.env.REACT_APP_VERSIONE_CLASSICA,
  'versione-tipologia-segnalazione': process.env.REACT_APP_VERSIONE_SEGNALAZIONE,
  'versione-tipologia-cure-palliative': process.env.REACT_APP_VERSIONE_SEGNALAZIONE,

  urlComuni: '/comuni',
  urlProvince: '/province',
  urlSiglaProvince: '/sigleProvince',
  urlNazioni: '/nazioni',
  cap: '/cap',
  appWidthBreakdown: 1220,
  'lingua-default': 'it-IT',
  zoneId: "Europe/Rome",
  timeoutToaster: 5000
}

export default globals
