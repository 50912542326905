import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  FieldsGroup,
  FieldsRow,
  RoleBasedCalendar,
  RoleBasedSelect,
} from "../../roleBasedComponents/RoleBasedComponents";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import OggettiUtili from "../../../utils/dataset/OggettiUtili";
import moment from "moment";

function ValutazioneInFormalizzazione({
  tipoValutazione,
  dataValutazione,
  handleChangeCaratteristicheSettingRichiesto,
  openAccordion,
  onClickAccordion,
  field,
  pageState,
  sezioneDatiRichiesta
}) {
  let valutazione = (
    <FieldsGroup fieldId={"TIPO_VALUTAZ.VALUTAZIONE.RICHIESTACAMBIOSETTING"} fieldLabel={"Tipo valutazione"} fieldRequired={true}>
      <RoleBasedSelect
        fieldId={"TIPO_VALUTAZ.VALUTAZIONE.RICHIESTACAMBIOSETTING"}
        pageState={pageState}
        parentClass={"mb-0"}
        id={"TIPO_VALUTAZ.VALUTAZIONE"}
        options={OggettiUtili.tipoValutazione}
        value={OggettiUtili.tipoValutazione.find(
          (option) => option.value === tipoValutazione
        )}
        onChange={(elem) => {
          handleChangeCaratteristicheSettingRichiesto(
            "tipoValutazione",
            elem.value
          );
        }}
        isSearchable={false}
        noOptionsMessage={() => "Errore: Non sono presenti opzioni"}
        forceReadOnly={false}
        hideUnsetValues={false}
        disableDoubleInput={false}
        fieldRequired={true}
      />
    </FieldsGroup>
  );
  let dataValutazioneComp = (
    <FieldsGroup fieldId={"DATA_VALUTAZ.VALUTAZIONE.RICHIESTACAMBIOSETTING"} fieldLabel={"Data valutazione"} fieldRequired={true}>
      <RoleBasedCalendar
        value={dataValutazione}
        key={"dataValutazione"}
        fieldId={"DATA_VALUTAZ.VALUTAZIONE.RICHIESTACAMBIOSETTING"}
        pageState={pageState}
        id={"caratteristicheSettingRichiestoDataValutazione"}
        placeholder={"gg/mm/aaaa"}
        errorMessage={"Data non valida"}
        onChange={(elem) =>
          handleChangeCaratteristicheSettingRichiesto(
            "dataValutazione",
            elem.target.value
          )
        }
        field={"dataValutazione"}
        fieldRequired={true}
        error={dataValutazione && !moment(dataValutazione, "DD/MM/YYYY").isValid()}
      />
    </FieldsGroup>
  );

  return (
    <Fragment>
      <AccordionSezioneRichiesta
        idAccordion={"valutazione"}
        title={"Valutazione"}
        required={true}
        openAccordion={openAccordion}
        onClickAccordion={onClickAccordion}
        field={field}
        pageState={pageState}
        sezioneDatiRichiesta={sezioneDatiRichiesta}
      >
        <FieldsRow>
          {valutazione}
          {dataValutazioneComp}
        </FieldsRow>
      </AccordionSezioneRichiesta>
    </Fragment>
  );
}

ValutazioneInFormalizzazione.propTypes = {
  tipoValutazione: PropTypes.string,
  dataValutazione: PropTypes.string,
  handleChangeCaratteristicheSettingRichiesto: PropTypes.func,
  openAccordion: PropTypes.bool,
  onClickAccordion: PropTypes.func,
  field: PropTypes.string,
  pageState: PropTypes.string,
  sezioneDatiRichiesta: PropTypes.bool
};

ValutazioneInFormalizzazione.defaultProps = {
  tipoValutazione: "",
  dataValutazione: "",
  handleChangeCaratteristicheSettingRichiesto: null,
  openAccordion: false,
  onClickAccordion: null,
  field: "",
  pageState: "",
  sezioneDatiRichiesta: false
};

export default ValutazioneInFormalizzazione;
