import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {FieldsRow, RoleBasedICD9Input, SectionList} from "../../../roleBasedComponents/RoleBasedComponents";
import {v4 as uuid} from "uuid";

const propTypes = {
    malattieEsantematiche: PropTypes.array,
    onChangeDatiInformazioniGenerali: PropTypes.func,
    index: PropTypes.func,
    pageState: PropTypes.string,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    fieldInAccordionReadOnly: PropTypes.bool
}

export const defaultMalattieEsantematiche = {
    id: null,
    malattiaEsantematica: null,
}

export default class MalattieEsantematiche extends AccordionSezioneRichiesta {

    state = {
        malattieEsantematiche: _.cloneDeep(this.props.malattieEsantematiche)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.malattieEsantematiche, this.props.malattieEsantematiche))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let listaMalattieEsantematiche = _.cloneDeep(this.props.malattieEsantematiche);
        this.setState({malattieEsantematiche: listaMalattieEsantematiche});
    }

    handleAggiornaForm = (value, index = null, subField = null) => {
        let malattieEsantematiche = _.cloneDeep(this.state.malattieEsantematiche);
        malattieEsantematiche[index][subField] = value;
        this.setState({malattieEsantematiche: malattieEsantematiche},
            () => this.props.onChangeDatiInformazioniGenerali("malattieEsantematiche", malattieEsantematiche))
    }

    addNewMalattiaEsantematica = () => {
        let malattieEsantematiche = this.state.malattieEsantematiche ? _.cloneDeep(this.state.malattieEsantematiche) : [];
        let malattiaEsantematica = _.cloneDeep(defaultMalattieEsantematiche);
        malattiaEsantematica.id = uuid();
        malattieEsantematiche.push(malattiaEsantematica);
        this.setState({malattieEsantematiche: malattieEsantematiche});
    }

    removeMalattiaEsantematica = (index) => {
        let malattieEsantematiche = _.cloneDeep(this.state.malattieEsantematiche);
        malattieEsantematiche.splice(index, 1);
        this.setState({malattieEsantematiche: malattieEsantematiche},
            () => this.props.onChangeDatiInformazioniGenerali("malattieEsantematiche", malattieEsantematiche))
    }

    renderNewMalattiaEsantematica = (malattiaEsantematica, index) => {
        return <FieldsRow>
            <RoleBasedICD9Input
                fieldId={"MAL_ESANTEM.INFORMAZIONIGENERALI.ANAMNESIFISIOLOGICA"}
                pageState={this.props.pageState}
                id={"autocompletemalattiaEsantematica" + index}
                onChange={(field, elem) => this.handleAggiornaForm(elem.target.value, index, field)}
                value={malattiaEsantematica.malattiaEsantematica}
                field={"malattiaEsantematica"}
                fieldLabel={"Malattia esantematica"}
                placeholder={"Inserisci malattia esantematica"}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                disableInfermiereInput={this.props.disableInfermiereInput}
                handleOnlyValue={true}
            />
        </FieldsRow>;
    }

    render() {
        let malattieEsantematiche = _.cloneDeep(this.state.malattieEsantematiche);
        return <SectionList
            addNewSectionCallback={this.addNewMalattiaEsantematica}
            removeSectionCallback={this.removeMalattiaEsantematica}
            renderSection={this.renderNewMalattiaEsantematica}
            data={malattieEsantematiche}
            title={'Malattia esantematica'}
            forceReadOnly={this.props.forceReadOnly}
            fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
            hideUnsetValues={this.props.hideUnsetValues}
            disableDoubleInput={this.props.disableDoubleInput}
            keyFieldId={'MAL_ESANTEM.INFORMAZIONIGENERALI.ANAMNESIFISIOLOGICA'}
            pageState={this.props.pageState}
            field={'malattieEsantematiche'}
            parentJsonPath={"anamnesiFisiologica.datiInformazioniGenerali.malattieEsantematiche"}
        />;
    }
}

MalattieEsantematiche.propTypes = propTypes;

MalattieEsantematiche.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    fieldInAccordionReadOnly: false
}
