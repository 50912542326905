import React from 'react';
import {globals, SessioneUtente} from 'web-client-archetype'
import stylesHeader from './Header.module.css';
import AuthUtils from "../../../utils/AuthUtils";
import WebSocketHelper from "../../../websocket/WebSocketHelper";
import enumsUtente from "../../../enum/enumsUtente.json";

import {withTheme} from 'styled-components'

const propTypes = {};

const imgAnonymusUser = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                             fill="#FFFFFF" class="bi bi-person" viewBox="1 1 13 13">
    <path
        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
</svg>`;

const imgAuthUser = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                         fill="#FFFFFF" class="bi bi-person" viewBox="1 1 13 13">
    <path
        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
    <path
        d="M0 2.5C0 1.11929 1.11929 0 2.5 0C3.88072 0 5 1.11929 5 2.5C5 3.88072 3.88072 5 2.5 5C1.11929 5 0 3.88072 0 2.5L0 2.5Z"
        transform="translate(8 1)" id="Ellipse" fill="#176A65"
        fill-rule="evenodd" stroke="none"/>
</svg>`;

class Header extends React.Component {

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        let userImgSelector = document.getElementById("userAuthImg");
        if (WebSocketHelper.isWebSocketConnected()) userImgSelector.innerHTML = imgAuthUser;
        else userImgSelector.innerHTML = imgAnonymusUser;
    }

    getRuoloUtenteLabel = (settings) => {
        let ruoli = [];
        settings.forEach(setting => {
            if (setting.profilo === enumsUtente.profiliUtente.admin) {
                ruoli.push(enumsUtente.profilo["ADI-AMM"]);
            } else {
                ruoli.push(AuthUtils.getRoleDescriptionFromCode(setting.ruolo));
            }
        });
        return Array.from(new Set(ruoli)).join(',');
    }

    renderRuoloUtenteLabel = () => {
        if (SessioneUtente.getInstance() && SessioneUtente.getInstance().settings?.length > 0)
            return (this.getRuoloUtenteLabel(SessioneUtente.getInstance().settings));
        else return "";
    }

    render() {
        return (
            <div className={stylesHeader.headerContainer.concat(" d-flex align-items-center")}>
                <div className={stylesHeader.styleHeader.concat("col-xl-3 col-lg-3 col-md-3")}>
                    <img className={stylesHeader.headerImg} alt="header" src={globals["app-header-image"]}/>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 text-center'><span
                    className={stylesHeader.labelNomeApplicazione}> {globals['app-application-name']} </span></div>

                <div className='col-xl-3 col-lg-3 col-md-3 col-auto text-right' style={{minWidth: "fit-content"}}>
                    <div className='row d-flex align-items-center'>
                        <div id="userAuthImg" className='col'/>
                        <div className={"col-auto text-left mt-1"}> 
                            <div style={{fontWeight: "700"}} className={stylesHeader.nomeUtente.concat(" text-capitalize d-inline-block text-truncate ")} >
                                {SessioneUtente.getInstance() ? SessioneUtente.getInstance().nomeUtente !== undefined && SessioneUtente.getInstance().cognomeUtente !== undefined
                                    ? SessioneUtente.getInstance().nomeUtente + ' ' + SessioneUtente.getInstance().cognomeUtente
                                    : 'Non presente' : 'Utente ospite'}
                            </div>
                            <div className={stylesHeader.profiliUtente.concat(" col-12 p-0 m-0 align-self-center ")}>
                                <span className="d-inline-block text-truncate" id={"profiliUtente"}>{this.renderRuoloUtenteLabel()}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Header.propTypes = propTypes;

export default withTheme(Header)
