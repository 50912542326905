import React, {Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {FieldsRow, isFieldIdWriteable, RoleBasedSelect} from "../../roleBasedComponents/RoleBasedComponents";
import SessionRequest from "../../../utils/SessionRequest";
import Statistica from "../../statistica/Statistica";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import enumQuestionario from "../../../enum/enumQuestionario"
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import Utils from "../../../utils/Utils";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    datiAderenzaAllaTerapia: PropTypes.any,
    onChangeDatiAderenzaAllaTerapia: PropTypes.func,
    isOptional: PropTypes.bool,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    onHide: PropTypes.func,
    accordionPadre: PropTypes.string.isRequired,
    richiesta: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultProps = {
    isOptional: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

export default class AderenzaAllaTerapia extends AccordionSecondLevel {

    state = {
        datiAderenzaAllaTerapia: _.cloneDeep(this.props.datiAderenzaAllaTerapia)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    inizializzaForm = () => {
        const datiAderenzaAllaTerapia = _.cloneDeep(this.state.datiAderenzaAllaTerapia);
        this.setState({datiAderenzaAllaTerapia}, () => this.validazioneDati());
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        const sessionData = Utils.isRuoloInfermiere() && this.props.accordionPadre === 'ANAMNESIFISIOLOGICA' ? this.props.datiAderenzaAllaTerapia : SessionRequest.getSessionObjectFor(this.props.richiesta)?.informazioniDiBase?.datiAderenzaAllaTerapia;
        if (_.isEqual(prevState.datiAderenzaAllaTerapia, this.state.datiAderenzaAllaTerapia)
            && !_.isEqual(this.state.datiAderenzaAllaTerapia, sessionData)) {
            this.setState({datiAderenzaAllaTerapia: _.cloneDeep(sessionData)});
        }
    }

    handleAggiornaForm = (field, elem) => {
        let datiAderenzaAllaTerapia = _.cloneDeep(this.state.datiAderenzaAllaTerapia);
        datiAderenzaAllaTerapia[field] = elem;

        switch (datiAderenzaAllaTerapia.testUsato) {
            case "01":
                datiAderenzaAllaTerapia.MMAS8 = null;
                break;

            case "02":
                datiAderenzaAllaTerapia.MMAS4 = null;
                break;

            default:
                datiAderenzaAllaTerapia.MMAS8 = null;
                datiAderenzaAllaTerapia.MMAS4 = null;
                break;
        }

        if (datiAderenzaAllaTerapia.statoDopoValutazione !== "02") {
            datiAderenzaAllaTerapia.dataValutazione = null;
            datiAderenzaAllaTerapia.daConsiderare = null;
            datiAderenzaAllaTerapia.propostaAttivitaSuggerimenti = null;
            datiAderenzaAllaTerapia.autonomiaGestionePaziente = null;
            datiAderenzaAllaTerapia.autonomiaGestioneCaregiver = null;
        }

        this.setState({datiAderenzaAllaTerapia}, this.validazioneDati);
    }

    validazioneDati = () => {
        const datiAderenzaAllaTerapia = _.cloneDeep(this.state.datiAderenzaAllaTerapia);
        this.props.onChangeDatiAderenzaAllaTerapia("datiAderenzaAllaTerapia", datiAderenzaAllaTerapia);
        let campiObbligatori = 0;
        campiObbligatori += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiAderenzaAllaTerapia?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneAderenzaAllaTerapia", campiObbligatori);
    }

    onHide = () => {
        const datiAderenzaAllaTerapia = _.cloneDeep(SessionRequest.getInitObject('anamnesiFisiologica.datiAderenzaAllaTerapia'));
        this.setState({datiAderenzaAllaTerapia: datiAderenzaAllaTerapia},
            () => {
                this.props.onChangeDatiAderenzaAllaTerapia("datiAderenzaAllaTerapia", datiAderenzaAllaTerapia);
                this.props.onHide();
            });
    }

    renderBody = () => {
        const datiAderenzaAllaTerapia = this.state.datiAderenzaAllaTerapia;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_TEST_US.ADERENZAALLATERAPIA.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_TEST_US.ADERENZAALLATERAPIA.GESTIONEDELLASALUTE'}
                        pageState={this.props.pageState}
                        id={"selectTestUsato" + this.props.accordionPadre}
                        options={OggettiUtili.testUsato}
                        value={datiAderenzaAllaTerapia.testUsato}
                        onChange={(elem) => this.handleAggiornaForm("testUsato", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Test usato non esistente"}
                        fieldLabel={"Test usato"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"testUsato"}
                        parentJsonPath={'informazionidibase.aderenzaallaterapia'}
                    />
                </FieldsRow>

                {datiAderenzaAllaTerapia.testUsato === "01" &&
                    <Fragment>
                        <Statistica
                            fieldId={'M_PUNTEGGIO.ADERENZAALLATERAPIA.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_PUNTEGGIO.ADERENZAALLATERAPIA.GESTIONEDELLASALUTE'}
                            pageState={this.props.pageState}
                            nomeLink={"Compila questionario 'Test Morisky Medication Adherence Scale Versione a 4 domande'"}
                            nomePunteggio={"Punteggio questionario"}
                            questionario={datiAderenzaAllaTerapia.MMAS4}
                            profileName={'Esito Morisky Medication Adherence Scale Versione a 4 domande'}
                            profileFieldId={'M_ESITO_MORISKY_4D.ADERENZAALLATERAPIA.ANAMNESIFISIOLOGICA'}
                            profileInfermiereFieldId={'I_ESITO_MORISKY_4D.ADERENZAALLATERAPIA.GESTIONEDELLASALUTE'}
                            onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.MMAS4, questionario)}
                            richiesta={this.props.richiesta}
                            pathRichiestaQuestionario={"informazioniDiBase.datiAderenzaAllaTerapia.MMAS4"}
                            tipoQuestionario={enumQuestionario.tipoQuestionario.MMAS4}
                            profileRequired={false}
                            salvaBozza={this.props.salvaBozza}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            scoreMandatoryForRole={null}
                            parentJsonPath={'informazionidibase.aderenzaallaterapia.mmas4'}
                        />
                    </Fragment>}

                {datiAderenzaAllaTerapia.testUsato === "02" &&
                    <Fragment>
                        <Statistica
                            fieldId={'M_PUNTEGGIO.ADERENZAALLATERAPIA.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_PUNTEGGIO.ADERENZAALLATERAPIA.GESTIONEDELLASALUTE'}
                            pageState={this.props.pageState}
                            nomeLink={"Compila questionario 'Test Morisky Medication Adherence Scale Versione a 8 domande'"}
                            nomePunteggio={"Punteggio questionario"}
                            questionario={datiAderenzaAllaTerapia.MMAS8}
                            profileName={'Esito Morisky Medication Adherence Scale Versione a 8 domande'}
                            profileFieldId={'M_ESITO_MORISKY_8D.ADERENZAALLATERAPIA.ANAMNESIFISIOLOGICA'}
                            profileInfermiereFieldId={'I_ESITO_MORISKY_8D.ADERENZAALLATERAPIA.GESTIONEDELLASALUTE'}
                            onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.MMAS8, questionario)}
                            richiesta={this.props.richiesta}
                            pathRichiestaQuestionario={"informazioniDiBase.datiAderenzaAllaTerapia.MMAS8"}
                            tipoQuestionario={enumQuestionario.tipoQuestionario.MMAS8}
                            profileRequired={false}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            salvaBozza={this.props.salvaBozza}
                            scoreMandatoryForRole={null}
                            parentJsonPath={'informazionidibase.aderenzaallaterapia.mmas8'}
                        />
                    </Fragment>}

                <ValutazioneSection
                    pageState={this.props.pageState}
                    id={"aderenzaAllaTerapia"}
                    ambito={'ADERENZAALLATERAPIA'}
                    parentFieldId={'ADERENZAALLATERAPIA.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'ADERENZAALLATERAPIA.GESTIONEDELLASALUTE'}
                    appendRolePrefix={true}
                    onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                    valueStatoDopoValutazione={datiAderenzaAllaTerapia.statoDopoValutazione}
                    onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                    valueDataValutazione={datiAderenzaAllaTerapia.dataValutazione}
                    onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                    valueDaConsiderare={datiAderenzaAllaTerapia.daConsiderare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={(field, elem) => this.handleAggiornaForm(field, elem)}
                    valueSuggerimenti={datiAderenzaAllaTerapia.propostaAttivitaSuggerimenti}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={datiAderenzaAllaTerapia.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={datiAderenzaAllaTerapia.autonomiaGestioneCaregiver}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    checkboxIdSuffix={this.props.accordionPadre}
                    parentJsonPath={'informazionidibase.aderenzaallaterapia'}
                />
            </>
        );
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"datiAderenzaAllaTerapia"}
            title={"Aderenza alla terapia"}
            required={false}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            onHide={this.onHide}
            isOptional={this.props.isOptional && isFieldIdWriteable('M_TEST_US.ADERENZAALLATERAPIA.ANAMNESIFISIOLOGICA', this.props.pageState, this.context.forceUserRole)}
            accordionReadOnly={this.props.accordionReadOnly}
            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
        >{this.renderBody()}</AccordionSezioneRichiesta>
    }
}

AderenzaAllaTerapia.propTypes = propTypes;
AderenzaAllaTerapia.defaultProps = defaultProps;
AderenzaAllaTerapia.contextType = RoleBasedContext;

