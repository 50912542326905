import React, {Component} from "react";
import PropTypes from 'prop-types';
import Table from "../../generalComponents/table/Table"
import {Button} from "web-client-archetype";
import RichiestaADIService from "../../../service/RichiestaADIService";
import _ from 'lodash';
import {mostraSpinner, nascondiSpinner} from "../../../App";
import Utils from "../../../utils/Utils";
import {Context} from "../../../utils/Context";
import FiltriUtils from "../../../utils/FiltriUtils";
import StoricoRichiesteUtils from "../StoricoRichiesteUtils";
import PopupConfermaCopiaRichiesta from "../popupConfermaCopiaRichiesta/PopupConfermaCopiaRichiesta";
import style from "./TabellaStoricoRichieste.module.css";
import FiltroStoricoRichieste from "../filtroStoricoRichieste/FiltroStoricoRichieste";
import {Col, Label, Row} from "reactstrap";
import HomeUtils from "../../home/HomeUtils";
import * as Icon from "react-bootstrap-icons";
import {Redirect} from "react-router-dom";
import routepath from "../../../utils/route/route-path";

const defaultSize = 15;
const defaltPage = 1;

const cols = [
    { key: "cognomeNome", name: "Assistito", sortDirection: "none" },
    { key: "dataRichiesta", name: "Data Richiesta", sortDirection: "none" },
    { key: "medicoRichiedente", name: "Medico Richiedente", sortDirection: "none" },
    { key: "esitoRichiesta", name: "Esito Richiesta", sortDirection: "none", tipo: "textWithStyle" },
    { key: "dataEsito", name: "Data Esito", sortDirection: "desc" },
    { key: "medicoFormalizzatore", name: "Formalizzata da", sortDirection: "none" },
    { key: "firmataDa", name: "Firmata da", sortDirection: "none" },
    { key: "cureDomiciliari", name: "Cure domiciliari", sortDirection: "none" },
    { key: "azioni", name: "Azioni", tipo: "dropdown" }
];

const defaultState = {
    rows: [],
    totalElements: 0,
    loading: false,
    size: defaultSize,
    page : defaltPage,
    sort: "",
    direction: "",
    richieste : [],
    countRichiesteDaFormalizzare: 0,
    cols: _.cloneDeep(cols),
    apriModaleCopiaRichiesta: false,
    uuidRichiesta: "",
    redirectToInserimentoRichiesta: false,
    newUuidRichiesta:null
};

const propTypes = {
    impostaFiltri: PropTypes.func,
    impostaRichieste: PropTypes.func,
    eliminaFiltro: PropTypes.func,
    filtri: PropTypes.object,
    apriAnnullamentoRichiesta: PropTypes.func,
    info: PropTypes.string
}

export default class TabellaStoricoRichieste extends Component {

    state = _.cloneDeep(defaultState);

    resetState = () => {
        let defState = _.cloneDeep(defaultState);
        this.setState(defState, () => this.ricercaRichieste(defaultSize,defaltPage));
    }

    componentDidMount() {
        this.ricercaRichieste(this.state.size, this.state.page, this.state.filtri, "dataEsito", "desc")
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filtri) !== JSON.stringify(this.props.filtri)) {
            this.setState({ size: defaultSize, totalElements: 0 }, () => {
                this.ricercaRichieste(this.state.size,this.state.page, this.props.filtri)
            });
        }
        if (this.context.clickHome) {
            this.resetState();
        }
    }

    openModal = (nomeModale, uuidRichiesta) => {
        if(nomeModale === 'popupConfermaCopiaRichiesta'){
            this.setState({apriModaleCopiaRichiesta : true, uuidRichiesta : uuidRichiesta});
        }
    }

    generatePdf = (pdfObject) => {
        // TODO verificare perchè questo servizio dà sempre 500
        RichiestaADIService.generaPdf(pdfObject.idRichiesta, pdfObject.step).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                Utils.toasterFunction({
                    status: response?.data?.codice || response?.status,
                    type: "confirm",
                    text: "Il fascicolo sanitario elettronico non è al momento disponibile. Si prega di riprovare più tardi"
                });
            } else {
                const linkSource = `data:${response.data.contentType};base64,${response.data.file}`;
                const downloadLink = document.createElement("a");
                const fileName = response.data.fileName;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        });
    }

    ricercaRichieste(size,page, filtri, sort, direction,caricamentoAltreRichieste) {
        mostraSpinner();
        let richiesta;
        //Gestione default filtri richiesta
        let params = {};
        FiltriUtils.setDefaultFiltriRichiesta("storicoRichieste", params);
        params.ordinamento = FiltriUtils.getOrdinamentoHomepage();
        if(direction !== this.state.direction || sort !== this.state.sort ||
            (Object.values(filtri?.filtriAssistito).some(value => value !== undefined && value !== null && value !== "") ||
                Object.values(filtri?.filtriRichiesta).some(value => value !== undefined && value !== null && value !== "")))
            page=1;

        RichiestaADIService.getRichiesteHome(size,page, filtri, sort, direction, params).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "critical",
                    text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                });
            } else {

                    if(caricamentoAltreRichieste)
                    {
                        richiesta =  _.cloneDeep(this.state?.richieste)
                        richiesta = richiesta.concat(response.data.elementi);

                    }
                    else {
                        richiesta =  _.cloneDeep(response.data.elementi)
                    }

                this.setState({
                    sort: sort,
                    direction: direction,
                    rows: StoricoRichiesteUtils.mapStoricoRichieste(richiesta, this.openModal, this.generatePdf),
                    loading: false,
                    countRichiesteDaFormalizzare: response.data.richiesteDaFormalizzare,
                    totalElements: response.data.elementiTotali,
                    size: size,
                    page:page,
                    richieste : _.cloneDeep(richiesta)
                }, () => this.props.impostaRichieste(this.state))
            }
            nascondiSpinner();
        })
    }

    mostraAltreRichieste = (sort, direction) => {
        let state = _.cloneDeep(this.state);
        this.ricercaRichieste(state.size, state.page, this.props.filtri, sort, direction)
    }

    getColsTable = () => {
        return this.state.cols;
    }

    renderMostraAltri = (page, filtri) => {
        if (this.state.rows.length < this.state.totalElements) {
            return (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"row text-center"}>
                            <div className={"col-12"}>
                                <Button
                                    id={"mostraAltri"}
                                    text="Mostra altri"
                                    onClick={() => this.ricercaRichieste(defaultSize, page + defaltPage, filtri, this.state.sort, this.state.direction, true)}
                                    className="btn btn-adi btnGreenOutline"
                                />
                            </div>
                        </div>
                        <div className={"row text-center"}>
                            <div className={"col-12"}>
                                <p>Hai visto {this.state.rows.length} di {this.state.totalElements} richieste</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else return null;
    }

    getFiltri = () => {
        let filtri = this.props.filtri;
        if (_.isEmpty(filtri.filtriAssistito) && _.isEmpty(filtri.filtriRichiesta)) {
            return null;
        } else return filtri;
    }

    mostraFiltriApplicati = () => {
        const areFiltriAssistitoValued = !!Object.values(this.props.filtri.filtriAssistito).filter(v => v && v !== "").length;
        const areFiltriRichiestaValued = !!Object.values(this.props.filtri.filtriRichiesta).filter(v => v && v !== "").length;
        return (areFiltriAssistitoValued || areFiltriRichiestaValued) &&
            <Row className={"mb-3"} style={{float: "right"}}>
                <Col xs={12}>
                    {Object.entries(this.props.filtri.filtriAssistito).map(el => (
                        <>{this.mostraFiltroApplicato(el[0], el[1], "filtriAssistito")}</>))}
                    {Object.entries(this.props.filtri.filtriRichiesta).map(el => (
                        <>{this.mostraFiltroApplicato(el[0], el[1], "filtriRichiesta")}</>
                    ))}
                </Col>
            </Row>
    }

    mostraFiltroApplicato(key, value, identificativoFiltri) {
        return (
            !Utils.isStringEmptyOrNullOrUndefined(value) &&
            <Label className={"border border-dark pt-1 pb-1 pr-2 pl-2 mr-2 rounded-pill"}>
                <Label tag={"strong"} className={"font-italic mb-0"}>
                    {HomeUtils.getEtichettaFiltro(key)}
                </Label>
                <Label className={"mb-0"}>
                    {HomeUtils.getValoreFiltro(value, key)}
                </Label>
                <Label onClick={() => this.props.eliminaFiltro(identificativoFiltri, key)} className={"mb-0 cursor-pointer"}>
                    <Icon.X color={"black"} size={20} style={{cursor: "pointer"}}/>
                </Label>
            </Label>
        )
    }

    render() {
        let state = _.cloneDeep(this.state);
        let filtri = this.getFiltri();


        if (state?.redirectToInserimentoRichiesta && state?.newUuidRichiesta)
            return (<Redirect to={{
                pathname: routepath.inserimento_richiesta.concat(routepath.compila_scheda_paziente),
                uuidRichiesta: this.state.newUuidRichiesta,
                // tipologia: currentRichiesta?.tipologia,
                // stato: currentRichiesta?.stato,
                showOnlyRequiredFields: true,
                pageState: "R",
            }}

        />)


        return (
            <>
                <div id={"containerStoricoRichiesteList"} className={style.container}>
                    <div className={"row pt-4"}>
                        <div className={"col-12"}>
                            <div className={"row"}>
                                <div className={"col-6"}>
                                    <b className={style.info}>{this.props.info}</b>
                                </div>
                                <div className={"col-6"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <FiltroStoricoRichieste impostaFiltri={this.props.impostaFiltri}
                                                                    filtri={this.props.filtri}/>
                                        </div>
                                    </div>
                                    {this.mostraFiltriApplicati()}
                                </div>
                            </div>
                            <hr className={style.separator}/>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <Table
                                        cols={this.getColsTable()}
                                        rows={this.state.rows}
                                        emptyMsg={"Non sono disponibili richieste che corrispondano ai filtri inseriti"}
                                        lengthEditable={false}
                                        totalElements={this.state.totalElements}
                                        bigTable={true}
                                        homePage={true}
                                        tableLenght={this.state.rows ? this.state.rows.length : 0}
                                        nascondiPaginazione={true}
                                        changePage={this.mostraAltreRichieste}
                                        classTable={"storico-richieste-table"}
                                        internalScroll={false}
                                        tableHeightForInternalScroll={`calc((100vh - ${document.getElementById("containerStoricoRichiesteList")?.offsetHeight ?? 0}px) + 0.5rem)`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderMostraAltri(state.page, filtri)}
                </div>
                {this.state.apriModaleCopiaRichiesta &&
                    <PopupConfermaCopiaRichiesta
                        uuidRichiesta={this.state.uuidRichiesta}
                        showPopup={this.state.apriModaleCopiaRichiesta}
                        chiudiPopupCopiaRichiesta={() => {
                            this.setState({apriModaleCopiaRichiesta: false})
                        }}
                        confermaCopiaRichiesta={(newUuidRichiesta) => {
                            this.setState({redirectToInserimentoRichiesta: true, newUuidRichiesta: newUuidRichiesta})
                        }}
                    />}
            </>
        );
    }
}

TabellaStoricoRichieste.contextType = Context;
TabellaStoricoRichieste.propTypes = propTypes;
