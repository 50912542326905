import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import StatoComportamentale from "./statoComportamentale/StatoComportamentale";
import NecessitaDiAssistenzaSanitaria from "./necessitaDiAssistenzaSanitaria/NecessitaDiAssistenzaSanitaria";
import Comunicazione from "./comunicazione/Comunicazione";
import SensorioUdito from "./sensorioUdito/SensorioUdito";
import AttivitaStrumentaliDellaVitaQuotidiana
    from "./attivitaStrumentaliDellaVitaQuotidiana/AttivitaStrumentaliDellaVitaQuotidiana";
import StatoCognitivo from "./statoCognitivo/StatoCognitivo";
import SensorioVista from "./sensorioVista/SensorioVista";
import SensorioDolore from "./sensorioDolore/SensorioDolore";
import Utils from "../../utils/Utils";
import StatoFunzionale from "./statoFunzionale/StatoFunzionale";
import GradoDiMobilita from "./gradoDiMobilita/GradoDiMobilita";
import AccordionHelper from "../../service/AccordionHelper";
import Idratazione from "./idratazione/Idratazione";
import InformazioniGenerali from "./informazioniGenerali/InformazioniGenerali";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import Donna from "./donna/Donna";
import Uomo from "./uomo/Uomo";
import Appetito from "./appetito/Appetito";
import AlvoEliminazioneFecale from "./alvoEliminazioneFecale/AlvoEliminazioneFecale";
import Respiro from "./respiro/Respiro";
import AderenzaAllaTerapia from "../infoBase/aderenzaAllaTerapia/AderenzaAllaTerapia";
import SensorioBaseValutazione from "./sensorioBaseValutazione/SensorioBaseValutazione";
import * as BsIcon from "react-bootstrap-icons";
import style from "./AnamnesiFisiologica.module.css";
import RitmoSonnoVeglia from "./ritmoSonnoEVeglia/RitmoSonnoVeglia";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import SensorioEquilibrio from "./sensorioEquilibrio/SensorioEquilibrio";
import Diuresi from "./diuresi/Diuresi";
import CopingTolleranzaStress from "./copingTolleranzaStress/CopingTolleranzaStress";
import StatoNutrizionale from "./statoNutrizionale/StatoNutrizionale";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import VersionedAccordion from "../VersionedAccordion";
import AuthUtils from "../../utils/AuthUtils";

const propTypes = {
    datiAnamnesiFisiologica: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiAnamnesiFisiologica: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    sessoPaziente: PropTypes.string,
    datiConsumoTabacco: PropTypes.any,
    onChangeObbligatorieta: PropTypes.func,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class AnamnesiFisiologica extends AccordionFirstLevel {

    state = {
        campiObbligatoriMancanti: {
            sottosezioneStatoFunzionale: 0,
            sottosezioneGradoDiMobilita: 0,
            sottosezioneStatoComportamentale: 0,
            sottosezioneRespiro: 0,
            sottosezioneAlvo: 0,
            sottosezioneAderenzaAllaTerapia: 0,
            sottosezioneSensorioOlfatto: 0,
            sottosezioneRitmoSonnoVeglia: 0,
            sottosezioneDiuresi: 0,
            sottosezioneAttivitaStrumentaliDellaVitaQuotidiana:0,
            sottosezioneComunicazione:0,
            sottosezioneSensorioBaseValutazioneOlfatto:0,
            sottosezioneSensorioBaseValutazioneGusto:0,
            sottosezioneSensorioBaseValutazioneTatto:0,
            sottosezioneSensorioDolore:0,
            sottosezioneSensorioEquilibrio:0,
            sottosezioneSensorioUdito:0,
            sottosezioneSensorioVista:0,
            sottosezioneStatoNutrizionale:0,
            sottosezioneIdratazione:0,
            sottosezioneCopingTolleranzaStress:0,
            sottosezioneAppetito:0,
            sottosezioneAlvoEliminazioneFecale:0
        },
        accordion: {
            openAccordionSituazioneFunzionale: true,
            openAccordionStatoComportamentale: true,
            openAccordionNecessitaDiAssistenzaSanitaria: true,
            openAccordionAttivitaStrumentaliDellaVitaQuotidiana: true,
            openAccordionStatoCognitivo: true,
            openAccordionGradoDiMobilita: true,
            openAccordionComunicazione: true,
            openAccordionSensorioUdito: true,
            openAccordionSensorioVista: true,
            openAccordionSensorioDolore: true,
            openAccordionSensorioOlfatto: true,
            openAccordionSensorioGusto: true,
            openAccordionSensorioTatto: true,
            openAccordionIdratazione: true,
            openAccordionInformazioniGenerali: true,
            openAccordionDonna: true,
            openAccordionUomo: true,
            openAccordionAppetito: true,
            openAccordionRespiro: true,
            openAccordionAlvoEliminazioneFecale: true,
            openAccordionAderenzaAllaTerapia: true,
            openAccordionRitmoSonnoVeglia: true,
            openAccordionEquilibrio: true,
            openAccordionDiuresi: true,
            openAccordionCoping: true,
            openAccordionStatoNutrizionale: true,
        },
        visualizzaAccordion: {
            visualizzaAccordionInformazioniGenerali: false,
            visualizzaAccordionStatoNutrizionale: false,
            visualizzaAccordionAppetito: false,
            visualizzaAccordionRespiro: false,
            visualizzaAccordionAlvoEliminazioneFecale: false,
            visualizzaAccordionDiuresi: false,
            visualizzaAccordionRitmoSonnoVeglia: false,
            visualizzaAccordionComunicazione: false,
            visualizzaAccordionCopingTolleranzaStress: false,
            visualizzaAccordionAderenzaAllaTerapia: false,
            visualizzaAccordionStatoFunzionale: false,
            visualizzaAccordionGradoDiMobilita: false,
            visualizzaAccordionStatoCognitivo: false,
            visualizzaAccordionStatoComportamentale: false,
            visualizzaAccordionNecessitaDiAssistenzaSanitaria: false,
            visualizzaAccordionAttivitaStrumentaliDellaVitaQuotidiana: false,
            visualizzaAccordionSensorioUdito: false,
            visualizzaAccordionSensorioVista: false,
            visualizzaAccordionSensorioDolore: false,
            visualizzaAccordionSensorioOlfatto: false,
            visualizzaAccordionSensorioGusto: false,
            visualizzaAccordionSensorioTatto: false,
            visualizzaAccordionSensorioEquilibrio: false,
            visualizzaAccordionIdratazione: false,
            visualizzaAccordionDonna: false,
            visualizzaAccordionUomo: false
        }
    }

    campiObbligatori = {...this.state.campiObbligatoriMancanti};

    componentDidMount() {
        this.inizializzaVisualizzazioneAccordion();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props, prevProps)) this.inizializzaVisualizzazioneAccordion();
    }

    inizializzaVisualizzazioneAccordion = () => {
        let infoAccordion = [
            {
                campo: "datiInformazioniGenerali",
                stato: "visualizzaAccordionInformazioniGenerali",
                fieldId: "NATO_DA_PARTO.INFORMAZIONIGENERALI.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "statoNutrizionale",
                stato: "visualizzaAccordionStatoNutrizionale",
                fieldId: "M_ALTEZZA.STATONUTRIZIONALE.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "appetito",
                stato: "visualizzaAccordionAppetito",
                fieldId: "M_MOD_APPET.APPETITO.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiRespiro",
                stato: "visualizzaAccordionRespiro",
                fieldId: "M_PRES_ALT.RESPIRO.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "alvoEliminazioneFecale",
                stato: "visualizzaAccordionAlvoEliminazioneFecale",
                fieldId: "M_TIPO_ALT.ALVOELIMINAZIONEFECALE.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiDiuresi",
                stato: "visualizzaAccordionDiuresi",
                fieldId: "M_TIPO_ALT.DIURESI.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiRitmoSonnoVeglia",
                stato: "visualizzaAccordionRitmoSonnoVeglia",
                fieldId: "M_TIPO_CODICE_AIC_FARMA.RITMOSONNOEVEGLIA.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiComunicazione",
                stato: "visualizzaAccordionComunicazione",
                fieldId: "M_TIPO_ALT_LETT.COMUNICAZIONE.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "copingTolleranzaStress",
                stato: "visualizzaAccordionCopingTolleranzaStress",
                fieldId: "M_PERC_DI_SE_ALT_AUTOPERC.COPINGTOLLERANZAALLOSTRESS.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiAderenzaAllaTerapia",
                stato: "visualizzaAccordionAderenzaAllaTerapia",
                fieldId: "M_TEST_US.ADERENZAALLATERAPIA.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "statoFunzionale",
                stato: "visualizzaAccordionStatoFunzionale",
                fieldId: "M_VADL.STATOFUNZIONALEPADL.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiGradoDiMobilita",
                stato: "visualizzaAccordionGradoDiMobilita",
                fieldId: "M_VMOB.GRADODIMOBILITA.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiStatoCognitivo",
                stato: "visualizzaAccordionStatoCognitivo",
                fieldId: "M_VCOG.SITUAZIONECOGNITIVA.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiStatoComportamentale",
                stato: "visualizzaAccordionStatoComportamentale",
                fieldId: "M_PROBLEMI_COMP_COMP.STATOCOMPORTAMENTALE.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiNecessitaDiAssistenzaSanitaria",
                stato: "visualizzaAccordionNecessitaDiAssistenzaSanitaria",
                fieldId: "M_PUNTEGGIO_VSAN.NECESSITADIASSISTENZASANITARIA.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiIADL",
                stato: "visualizzaAccordionAttivitaStrumentaliDellaVitaQuotidiana",
                fieldId: "M_PUNTEGGIOIADL_SCALADILAWTON.ATTIVITASTRUMENTALIDELLAVITAQUOTIDIANA.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiSensorioUdito",
                stato: "visualizzaAccordionSensorioUdito",
                fieldId: "M_ESITO_VAL_UD_OREC.SENSORIOUDITO.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiSensorioVista",
                stato: "visualizzaAccordionSensorioVista",
                fieldId: "M_ESITO_VAL_UD_OREC.SENSORIOUDITO.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiSensorioDolore",
                stato: "visualizzaAccordionSensorioDolore",
                fieldId: "M_MANT_SENS_TERMICA.SENSORIODOLORE.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "sensorioOlfatto",
                stato: "visualizzaAccordionSensorioOlfatto",
                fieldId: "M_ESITO_VAL_OLF.SENSORIOOLFATTO.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "sensorioGusto",
                stato: "visualizzaAccordionSensorioGusto",
                fieldId: "M_ESITO_VAL_GUSTO.SENSORIOGUSTO.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "sensorioTatto",
                stato: "visualizzaAccordionSensorioTatto",
                fieldId: "M_ESITO_VAL_TATTO.SENSORIOTATTO.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "sensorioEquilibrio",
                stato: "visualizzaAccordionSensorioEquilibrio",
                fieldId: "M_SCALE_REG_OSP.SENSORIOEQUILIBRIO.ANAMNESIFISIOLOGICA"
            },
            {
                campo: "datiIdratazione",
                stato: "visualizzaAccordionIdratazione",
                fieldId: "M_AB_ASS_LIQUID.IDRATAZIONE.ANAMNESIFISIOLOGICA"
            },
            {campo: "donna", stato: "visualizzaAccordionDonna", fieldId: "ULT_VIS_UROL.UOMO.ANAMNESIFISIOLOGICA"},
            {campo: "uomo", stato: "visualizzaAccordionUomo", fieldId: "MENARCA.DONNA.ANAMNESIFISIOLOGICA"},
        ];
        const data = {
            ..._.cloneDeep(this.props.datiAnamnesiFisiologica),
            datiAderenzaAllaTerapia: {
                ..._.cloneDeep(this.props.datiAderenzaAllaTerapia)
            }
        };
        let visualizzaAccordion = Utils.inizializzaVisualizzazioneAccordion(infoAccordion, this.state.visualizzaAccordion, data, this.context.forceReadOnly && !this.context.hideUnsetValues, this.context.forceUserRole, this.props.pageState);
        this.setState({visualizzaAccordion});
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        this.campiObbligatori[field] = value;
        this.setState({campiObbligatoriMancanti: _.cloneDeep(this.campiObbligatori)}, () => {
            this.props.onChangeObbligatorieta("anamnesiFisiologica", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    renderAggiungiAccordion = (label, visualizzaAccordion, idLink) => {
        let accordion = _.cloneDeep(this.state.visualizzaAccordion);

        const onClickAggiungi = () => {
            accordion[visualizzaAccordion] = true;
            this.setState({visualizzaAccordion: accordion})
        }

        return (
            <>
                <div id={idLink} className={"col-12 pt-2 pb-2 mx-3"}>
                    <span onClick={() => onClickAggiungi()} className={style.aggiungiAccordion}>
                        <BsIcon.PlusCircle size={24} color={"#176A65"}/> {label}
                    </span>
                </div>
            </>
        )
    }

    hideOptionalAccordion(accordion) {
        let data = _.cloneDeep(this.props.datiAnamnesiFisiologica);
        let accordions = _.cloneDeep(this.state.visualizzaAccordion);
        accordions[accordion] = false;

        this.setState({visualizzaAccordion: accordions, data: data});
    }

    renderBodyFase1 = () => {
        let visualizzaAccordion = _.cloneDeep(this.state.visualizzaAccordion);

        return (
            <>
                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionComunicazione && this.renderAggiungiAccordion("Aggiungi informazioni - Comunicazione", "visualizzaAccordionComunicazione", "datiComunicazione")}
                {visualizzaAccordion.visualizzaAccordionComunicazione &&
                    <VersionedAccordion
                        accordion={Comunicazione}
                        datiComunicazione={this.props.datiAnamnesiFisiologica.datiComunicazione}
                        onChangeDatiComunicazione={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionComunicazione}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionComunicazione"}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionComunicazione')}
                        richiesta={this.props.richiesta}
                        salvaBozza={this.props.salvaBozza}
                        isOptional={true}
                        pageState={this.props.pageState}
                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionSensorioUdito && this.renderAggiungiAccordion("Aggiungi informazioni - Sensorio udito", "visualizzaAccordionSensorioUdito", "datiSensorioUdito")}
                {visualizzaAccordion.visualizzaAccordionSensorioUdito &&
                    <VersionedAccordion
                        accordion={SensorioUdito}
                        datiSensorioUditoForm={this.props.datiAnamnesiFisiologica.datiSensorioUdito}
                        onChangeDatiSensorioUditoForm={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionSensorioUdito}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionSensorioUdito"}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionSensorioUdito')}
                        pageState={this.props.pageState}
                        salvaBozza={this.props.salvaBozza}
                        isOptional={true}
                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionSensorioVista && this.renderAggiungiAccordion("Aggiungi informazioni - Sensorio vista", "visualizzaAccordionSensorioVista", "datiSensorioVista")}
                {visualizzaAccordion.visualizzaAccordionSensorioVista &&
                    <VersionedAccordion
                        accordion={SensorioVista}
                        datiSensorioVista={this.props.datiAnamnesiFisiologica.datiSensorioVista}
                        onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionSensorioVista}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionSensorioVista"}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionSensorioVista')}
                        pageState={this.props.pageState}
                        richiesta={this.props.richiesta}
                        salvaBozza={this.props.salvaBozza}
                        isOptional={true}
                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionSensorioDolore && this.renderAggiungiAccordion("Aggiungi informazioni - Sensorio dolore", "visualizzaAccordionSensorioDolore", "datiSensorioDolore")}
                {visualizzaAccordion.visualizzaAccordionSensorioDolore &&
                    <VersionedAccordion
                        accordion={SensorioDolore}
                        datiSensorioDolore={this.props.datiAnamnesiFisiologica.datiSensorioDolore}
                        onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionSensorioDolore}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionSensorioDolore"}
                        pageState={this.props.pageState}
                        richiesta={this.props.richiesta}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionSensorioDolore')}
                        salvaBozza={this.props.salvaBozza}
                        isOptional={true}
                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                <div className="row" id={"idSottoaccordionQuestionariAnamnesiFisiologica"}>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={StatoFunzionale}
                            datiPADL={this.props.datiAnamnesiFisiologica.statoFunzionale}
                            onChangeDatiPADL={this.props.onChangeDatiAnamnesiFisiologica}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            openAccordion={this.state.accordion.openAccordionSituazioneFunzionale}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionSituazioneFunzionale"}
                            pageState={this.props.pageState}
                            richiesta={this.props.richiesta}
                            salvaBozza={this.props.salvaBozza}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={GradoDiMobilita}
                            datiGradoDiMobilitaForm={this.props.datiAnamnesiFisiologica.datiGradoDiMobilita}
                            onChangeDatiGradoDiMobilitaForm={this.props.onChangeDatiAnamnesiFisiologica}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            openAccordion={this.state.accordion.openAccordionGradoDiMobilita}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionGradoDiMobilita"}
                            pageState={this.props.pageState}
                            richiesta={this.props.richiesta}
                            salvaBozza={this.props.salvaBozza}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={StatoCognitivo}
                            datiStatoCognitivoForm={this.props.datiAnamnesiFisiologica.datiStatoCognitivo}
                            onChangeDatiStatoCognitivoForm={this.props.onChangeDatiAnamnesiFisiologica}
                            openAccordion={this.state.accordion.openAccordionStatoCognitivo}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionStatoCognitivo"}
                            pageState={this.props.pageState}
                            richiesta={this.props.richiesta}
                            salvaBozza={this.props.salvaBozza}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={StatoComportamentale}
                            datiStatoComportamentaleForm={this.props.datiAnamnesiFisiologica.datiStatoComportamentale}
                            onChangeDatiStatoComportamentaleForm={this.props.onChangeDatiAnamnesiFisiologica}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            openAccordion={this.state.accordion.openAccordionStatoComportamentale}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionStatoComportamentale"}
                            pageState={this.props.pageState}
                            salvaBozza={this.props.salvaBozza}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={NecessitaDiAssistenzaSanitaria}
                            datiNecessitaDiAssistenzaSanitariaForm={this.props.datiAnamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                            figurePai={this.props.richiesta?.figureProfessionaliPresaInCarico}
                            professionistiSupportoAValutazione={this.props.richiesta?.professionistiSupportoAValutazione}
                            onChangeDatiNecessitaDiAssistenzaSanitaria={this.props.onChangeDatiAnamnesiFisiologica}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            openAccordion={this.state.accordion.openAccordionNecessitaDiAssistenzaSanitaria}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionNecessitaDiAssistenzaSanitaria"}
                            pageState={this.props.pageState}
                            richiesta={this.props.richiesta}
                            salvaBozza={this.props.salvaBozza}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    </div>
                </div>

                <VersionedAccordion
                    accordion={AttivitaStrumentaliDellaVitaQuotidiana}
                    datiIADL={this.props.datiAnamnesiFisiologica.datiIADL}
                    onChangeDatiIADL={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionAttivitaStrumentaliDellaVitaQuotidiana}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionAttivitaStrumentaliDellaVitaQuotidiana"}
                    pageState={this.props.pageState}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />
            </>
        );
    }

    renderBodyFase2Medico = () => {
        let visualizzaAccordion = _.cloneDeep(this.state.visualizzaAccordion);
        const isFemale = this.props.sessoPaziente === '02';
        return (
            <>
                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionInformazioniGenerali && this.renderAggiungiAccordion("Aggiungi informazioni generali", "visualizzaAccordionInformazioniGenerali", "datiInformazioniGenerali")}
                {visualizzaAccordion.visualizzaAccordionInformazioniGenerali &&
                    <VersionedAccordion
                        accordion={InformazioniGenerali}
                        datiInformazioniGenerali={this.props.datiAnamnesiFisiologica.datiInformazioniGenerali}
                        onChangeDatiInformazioniGenerali={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionInformazioniGenerali}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionInformazioniGenerali"}
                        pageState={this.props.pageState}
                        richiesta={this.props.richiesta}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionInformazioniGenerali')}
                        hideUnsetValues={this.props.hideUnsetValues}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionStatoNutrizionale && this.renderAggiungiAccordion("Aggiungi informazioni - Stato nutrizionale", "visualizzaAccordionStatoNutrizionale", "statoNutrizionale")}
                {visualizzaAccordion.visualizzaAccordionStatoNutrizionale &&
                    <VersionedAccordion
                        accordion={StatoNutrizionale}
                        statoNutrizionale={this.props.datiAnamnesiFisiologica.statoNutrizionale}
                        onChangeDatiStatoNutrizionale={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionStatoNutrizionale}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionStatoNutrizionale"}
                        pageState={this.props.pageState}
                        richiesta={this.props.richiesta}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionStatoNutrizionale')}
                        salvaBozza={this.props.salvaBozza}
                        hideUnsetValues={this.props.hideUnsetValues}
                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionAppetito && this.renderAggiungiAccordion("Aggiungi informazioni - Appetito", "visualizzaAccordionAppetito", "appetito")}
                {visualizzaAccordion.visualizzaAccordionAppetito &&
                    <VersionedAccordion
                        accordion={Appetito}
                        dati={this.props.datiAnamnesiFisiologica.appetito}
                        onChangeDatiAppetito={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionAppetito}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionAppetito"}
                        pageState={this.props.pageState}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionAppetito')}
                        hideUnsetValues={this.props.hideUnsetValues}
                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionRespiro && this.renderAggiungiAccordion("Aggiungi informazioni - Respiro", "visualizzaAccordionRespiro", "datiRespiro")}
                {visualizzaAccordion.visualizzaAccordionRespiro &&
                    <VersionedAccordion
                        accordion={Respiro}
                        dati={this.props.datiAnamnesiFisiologica.datiRespiro}
                        onChangeDatiRespiro={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionRespiro}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionRespiro"}
                        pageState={this.props.pageState}
                        onChangeDatiFornitureSanitarie={this.props.onChangeDatiAnamnesiFisiologica}
                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                        richiesta={this.props.richiesta}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionRespiro')}
                        salvaBozza={this.props.salvaBozza}
                        hideUnsetValues={this.props.hideUnsetValues}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionAlvoEliminazioneFecale && this.renderAggiungiAccordion("Aggiungi informazioni - Alvo/Eliminazione fecale", "visualizzaAccordionAlvoEliminazioneFecale", "alvoEliminazioneFecale")}
                {visualizzaAccordion.visualizzaAccordionAlvoEliminazioneFecale &&
                    <VersionedAccordion
                        accordion={AlvoEliminazioneFecale}
                        dati={this.props.datiAnamnesiFisiologica.alvoEliminazioneFecale}
                        onChangeDatiAlvoEliminazioneFecale={this.props.onChangeDatiAnamnesiFisiologica}
                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                        openAccordion={this.state.accordion.openAccordionAlvoEliminazioneFecale}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionAlvoEliminazioneFecale"}
                        pageState={this.props.pageState}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionAlvoEliminazioneFecale')}
                        hideUnsetValues={this.props.hideUnsetValues}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionDiuresi && this.renderAggiungiAccordion("Aggiungi informazioni - Diuresi", "visualizzaAccordionDiuresi", "datiDiuresi")}
                {visualizzaAccordion.visualizzaAccordionDiuresi &&
                    <VersionedAccordion
                        accordion={Diuresi}
                        dati={this.props.datiAnamnesiFisiologica.datiDiuresi}
                        onChangeDatiDiuresi={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionDiuresi}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionDiuresi"}
                        pageState={this.props.pageState}
                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionDiuresi')}
                        hideUnsetValues={this.props.hideUnsetValues}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionRitmoSonnoVeglia && this.renderAggiungiAccordion("Aggiungi informazioni - Ritmo sonno e veglia", "visualizzaAccordionRitmoSonnoVeglia", "datiRitmoSonnoVeglia")}
                {visualizzaAccordion.visualizzaAccordionRitmoSonnoVeglia &&
                    <VersionedAccordion
                        accordion={RitmoSonnoVeglia}
                        ritmoSonnoVeglia={this.props.datiAnamnesiFisiologica.datiRitmoSonnoVeglia}
                        onChangeDatiRitmoSonnoVeglia={this.props.onChangeDatiAnamnesiFisiologica}
                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                        openAccordion={this.state.accordion.openAccordionRitmoSonnoVeglia}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionRitmoSonnoVeglia"}
                        pageState={this.props.pageState}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionRitmoSonnoVeglia')}
                        hideUnsetValues={this.props.hideUnsetValues}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionComunicazione && this.renderAggiungiAccordion("Aggiungi informazioni - Comunicazione", "visualizzaAccordionComunicazione", "datiComunicazione")}
                {visualizzaAccordion.visualizzaAccordionComunicazione &&
                    <VersionedAccordion
                        accordion={Comunicazione}
                        datiComunicazione={this.props.datiAnamnesiFisiologica.datiComunicazione}
                        onChangeDatiComunicazione={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionComunicazione}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionComunicazione"}
                        pageState={this.props.pageState}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionComunicazione')}
                        isOptional={true}
                        hideUnsetValues={this.props.hideUnsetValues}
                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionCopingTolleranzaStress && this.renderAggiungiAccordion("Aggiungi informazioni - Coping/Tolleranza allo stress", "visualizzaAccordionCopingTolleranzaStress", "copingTolleranzaStress")}
                {visualizzaAccordion.visualizzaAccordionCopingTolleranzaStress &&
                    <VersionedAccordion
                        accordion={CopingTolleranzaStress}
                        datiCopingTolleranzaStress={this.props.datiAnamnesiFisiologica.copingTolleranzaStress}
                        onChangedatiCopingTolleranzaStress={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionCoping}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionCoping"}
                        pageState={this.props.pageState}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionCopingTolleranzaStress')}
                        hideUnsetValues={this.props.hideUnsetValues}
                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                {!this.context.forceReadOnly && !this.context.showOnlyRequiredFields && !visualizzaAccordion.visualizzaAccordionAderenzaAllaTerapia && this.renderAggiungiAccordion("Aggiungi informazioni - Aderenza alla terapia", "visualizzaAccordionAderenzaAllaTerapia", "datiAderenzaAllaTerapia")}
                {visualizzaAccordion.visualizzaAccordionAderenzaAllaTerapia &&
                    <VersionedAccordion
                        accordion={AderenzaAllaTerapia}
                        datiAderenzaAllaTerapia={this.props.datiAderenzaAllaTerapia}
                        onChangeDatiAderenzaAllaTerapia={this.props.onChangeDatiAderenzaAllaTerapia}
                        openAccordion={this.state.accordion.openAccordionAderenzaAllaTerapia}
                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionAderenzaAllaTerapia"}
                        pageState={this.props.pageState}
                        onHide={() => this.hideOptionalAccordion('visualizzaAccordionAderenzaAllaTerapia')}
                        isOptional={true}
                        accordionPadre={"ANAMNESIFISIOLOGICA"}
                        richiesta={this.props.richiesta}
                        salvaBozza={this.props.salvaBozza}
                        hideUnsetValues={this.props.hideUnsetValues}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />}

                <div className="row" id={"idSottoaccordionQuestionariAnamnesiFisiologica"}>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={StatoFunzionale}
                            datiPADL={this.props.datiAnamnesiFisiologica.statoFunzionale}
                            onChangeDatiPADL={this.props.onChangeDatiAnamnesiFisiologica}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            openAccordion={this.state.accordion.openAccordionSituazioneFunzionale}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionSituazioneFunzionale"}
                            pageState={this.props.pageState}
                            richiesta={this.props.richiesta}
                            salvaBozza={this.props.salvaBozza}
                            hideUnsetValues={this.props.hideUnsetValues}
                            forceReadOnly={this.props.accordionReadOnly}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={GradoDiMobilita}
                            datiGradoDiMobilitaForm={this.props.datiAnamnesiFisiologica.datiGradoDiMobilita}
                            onChangeDatiGradoDiMobilitaForm={this.props.onChangeDatiAnamnesiFisiologica}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            openAccordion={this.state.accordion.openAccordionGradoDiMobilita}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionGradoDiMobilita"}
                            pageState={this.props.pageState}
                            richiesta={this.props.richiesta}
                            salvaBozza={this.props.salvaBozza}
                            hideUnsetValues={this.props.hideUnsetValues}
                            forceReadOnly={this.props.accordionReadOnly}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={StatoCognitivo}
                            datiStatoCognitivoForm={this.props.datiAnamnesiFisiologica.datiStatoCognitivo}
                            onChangeDatiStatoCognitivoForm={this.props.onChangeDatiAnamnesiFisiologica}
                            openAccordion={this.state.accordion.openAccordionStatoCognitivo}
                            onClickAccordion={this.onClickAccordion}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            field={"openAccordionStatoCognitivo"}
                            pageState={this.props.pageState}
                            richiesta={this.props.richiesta}
                            salvaBozza={this.props.salvaBozza}
                            hideUnsetValues={this.props.hideUnsetValues}
                            forceReadOnly={this.props.accordionReadOnly}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={StatoComportamentale}
                            datiStatoComportamentaleForm={this.props.datiAnamnesiFisiologica.datiStatoComportamentale}
                            onChangeDatiStatoComportamentaleForm={this.props.onChangeDatiAnamnesiFisiologica}
                            pageState={this.props.pageState}
                            openAccordion={this.state.accordion.openAccordionStatoComportamentale}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionStatoComportamentale"}
                            salvaBozza={this.props.salvaBozza}
                            hideUnsetValues={this.props.hideUnsetValues}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={NecessitaDiAssistenzaSanitaria}
                            datiNecessitaDiAssistenzaSanitariaForm={this.props.datiAnamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                            figurePai={this.props.richiesta?.figureProfessionaliPresaInCarico}
                            professionistiSupportoAValutazione={this.props.richiesta?.professionistiSupportoAValutazione}
                            onChangeDatiNecessitaDiAssistenzaSanitaria={this.props.onChangeDatiAnamnesiFisiologica}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            openAccordion={this.state.accordion.openAccordionNecessitaDiAssistenzaSanitaria}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionNecessitaDiAssistenzaSanitaria"}
                            pageState={this.props.pageState}
                            richiesta={this.props.richiesta}
                            salvaBozza={this.props.salvaBozza}
                            hideUnsetValues={this.props.hideUnsetValues}
                            forceReadOnly={this.props.accordionReadOnly}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    </div>
                </div>

                <VersionedAccordion
                    accordion={AttivitaStrumentaliDellaVitaQuotidiana}
                    datiIADL={this.props.datiAnamnesiFisiologica.datiIADL}
                    onChangeDatiIADL={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionAttivitaStrumentaliDellaVitaQuotidiana}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionAttivitaStrumentaliDellaVitaQuotidiana"}
                    pageState={this.props.pageState}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    forceReadOnly={this.props.accordionReadOnly}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioUdito}
                    datiSensorioUditoForm={this.props.datiAnamnesiFisiologica.datiSensorioUdito}
                    onChangeDatiSensorioUditoForm={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioUdito}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioUdito"}
                    pageState={this.props.pageState}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioVista}
                    datiSensorioVista={this.props.datiAnamnesiFisiologica.datiSensorioVista}
                    onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioVista}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioVista"}
                    pageState={this.props.pageState}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioDolore}
                    datiSensorioDolore={this.props.datiAnamnesiFisiologica.datiSensorioDolore}
                    onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioDolore}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioDolore"}
                    pageState={this.props.pageState}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioBaseValutazione}
                    sensorio={"Olfatto"}
                    datiSensorio={this.props.datiAnamnesiFisiologica.sensorioOlfatto}
                    onChangeDatiSensorio={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioOlfatto}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioOlfatto"}
                    pageState={this.props.pageState}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioBaseValutazione}
                    sensorio={"Gusto"}
                    datiSensorio={this.props.datiAnamnesiFisiologica.sensorioGusto}
                    onChangeDatiSensorio={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioGusto}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioGusto"}
                    pageState={this.props.pageState}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioBaseValutazione}
                    sensorio={"Tatto"}
                    datiSensorio={this.props.datiAnamnesiFisiologica.sensorioTatto}
                    onChangeDatiSensorio={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioTatto}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioTatto"}
                    pageState={this.props.pageState}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioEquilibrio}
                    datiSensorioEquilibrio={this.props.datiAnamnesiFisiologica.sensorioEquilibrio}
                    onChangeDatiSensorioEquilibrio={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionEquilibrio}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionEquilibrio"}
                    pageState={this.props.pageState}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    hideUnsetValues={this.props.hideUnsetValues}
                    fieldIdParent ={"anammnesiFisiologica"}  
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    forceReadOnly={this.props.accordionReadOnly}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={Idratazione}
                    datiIdratazione={this.props.datiAnamnesiFisiologica.datiIdratazione}
                    onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionIdratazione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionIdratazione"}
                    pageState={this.props.pageState}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    hideUnsetValues={this.props.hideUnsetValues}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>

                {isFemale ?
                    <VersionedAccordion
                        accordion={Donna}
                        donna={this.props.datiAnamnesiFisiologica.donna}
                        onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionDonna}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionDonna"}
                        pageState={this.props.pageState}
                        hideUnsetValues={this.props.hideUnsetValues}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/> :
                    <VersionedAccordion
                        accordion={Uomo}
                        uomo={this.props.datiAnamnesiFisiologica.uomo}
                        onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionUomo}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionUomo"}
                        pageState={this.props.pageState}
                        hideUnsetValues={this.props.hideUnsetValues}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>}
            </>
        );
    }

    renderBodyFase2Infermiere = () => {
        const isFemale = this.props.sessoPaziente === '02';
        return (
            <>
                <VersionedAccordion
                    accordion={InformazioniGenerali}
                    datiInformazioniGenerali={this.props.datiAnamnesiFisiologica.datiInformazioniGenerali}
                    onChangeDatiInformazioniGenerali={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionInformazioniGenerali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionInformazioniGenerali"}
                    pageState={this.props.pageState}
                    onHide={() => this.hideOptionalAccordion('visualizzaAccordionInformazioniGenerali')}
                    isOptional={false}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={StatoNutrizionale}
                    statoNutrizionale={this.props.datiAnamnesiFisiologica.statoNutrizionale}
                    onChangeDatiStatoNutrizionale={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionStatoNutrizionale}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionStatoNutrizionale"}
                    pageState={this.props.pageState}
                    richiesta={this.props.richiesta}
                    onHide={() => this.hideOptionalAccordion('visualizzaAccordionStatoNutrizionale')}
                    isOptional={false}
                    salvaBozza={this.props.salvaBozza}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={Appetito}
                    dati={this.props.datiAnamnesiFisiologica.appetito}
                    onChangeDatiAppetito={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionAppetito}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionAppetito"}
                    pageState={this.props.pageState}
                    onHide={() => this.hideOptionalAccordion('visualizzaAccordionAppetito')}
                    isOptional={false}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={Respiro}
                    dati={this.props.datiAnamnesiFisiologica.datiRespiro}
                    onChangeDatiRespiro={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionRespiro}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionRespiro"}
                    pageState={this.props.pageState}
                    onChangeDatiFornitureSanitarie={this.props.onChangeDatiAnamnesiFisiologica}
                    richiesta={this.props.richiesta}
                    onHide={() => this.hideOptionalAccordion('visualizzaAccordionRespiro')}
                    isOptional={false}
                    salvaBozza={this.props.salvaBozza}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={AlvoEliminazioneFecale}
                    dati={this.props.datiAnamnesiFisiologica.alvoEliminazioneFecale}
                    onChangeDatiAlvoEliminazioneFecale={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionAlvoEliminazioneFecale}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionAlvoEliminazioneFecale"}
                    pageState={this.props.pageState}
                    onHide={() => this.hideOptionalAccordion('visualizzaAccordionAlvoEliminazioneFecale')}
                    isOptional={false}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={Diuresi}
                    dati={this.props.datiAnamnesiFisiologica.datiDiuresi}
                    onChangeDatiDiuresi={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionDiuresi}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionDiuresi"}
                    pageState={this.props.pageState}
                    onHide={() => this.hideOptionalAccordion('visualizzaAccordionDiuresi')}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    isOptional={false}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={RitmoSonnoVeglia}
                    ritmoSonnoVeglia={this.props.datiAnamnesiFisiologica.datiRitmoSonnoVeglia}
                    onChangeDatiRitmoSonnoVeglia={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionRitmoSonnoVeglia}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionRitmoSonnoVeglia"}
                    pageState={this.props.pageState}
                    onHide={() => this.hideOptionalAccordion('visualizzaAccordionRitmoSonnoVeglia')}
                    isOptional={false}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    disableDoubleInput={this.props.disableDoubleInput}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={Comunicazione}
                    datiComunicazione={this.props.datiAnamnesiFisiologica.datiComunicazione}
                    onChangeDatiComunicazione={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionComunicazione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionComunicazione"}
                    pageState={this.props.pageState}
                    onHide={() => this.hideOptionalAccordion('visualizzaAccordionComunicazione')}
                    isOptional={false}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={CopingTolleranzaStress}
                    datiCopingTolleranzaStress={this.props.datiAnamnesiFisiologica.copingTolleranzaStress}
                    onChangedatiCopingTolleranzaStress={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionCoping}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionCoping"}
                    pageState={this.props.pageState}
                    onHide={() => this.hideOptionalAccordion('visualizzaAccordionCopingTolleranzaStress')}
                    isOptional={false}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={AderenzaAllaTerapia}
                    datiAderenzaAllaTerapia={this.props.datiAderenzaAllaTerapia}
                    onChangeDatiAderenzaAllaTerapia={this.props.onChangeDatiAderenzaAllaTerapia}
                    openAccordion={this.state.accordion.openAccordionAderenzaAllaTerapia}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionAderenzaAllaTerapia"}
                    pageState={this.props.pageState}
                    onHide={() => this.hideOptionalAccordion('visualizzaAccordionAderenzaAllaTerapia')}
                    isOptional={false}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    salvaBozza={this.props.salvaBozza}
                    richiesta={this.props.richiesta}
                    accordionPadre={"ANAMNESIFISIOLOGICA"}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <div className="row" id={"idSottoaccordionQuestionariAnamnesiFisiologica"}>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={StatoFunzionale}
                            datiPADL={this.props.datiAnamnesiFisiologica.statoFunzionale}
                            onChangeDatiPADL={this.props.onChangeDatiAnamnesiFisiologica}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            openAccordion={this.state.accordion.openAccordionSituazioneFunzionale}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionSituazioneFunzionale"}
                            pageState={this.props.pageState}
                            richiesta={this.props.richiesta}
                            salvaBozza={this.props.salvaBozza}
                            forceReadOnly={this.props.forceReadOnly || this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                        />
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={GradoDiMobilita}
                            datiGradoDiMobilitaForm={this.props.datiAnamnesiFisiologica.datiGradoDiMobilita}
                            onChangeDatiGradoDiMobilitaForm={this.props.onChangeDatiAnamnesiFisiologica}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            openAccordion={this.state.accordion.openAccordionGradoDiMobilita}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionGradoDiMobilita"}
                            pageState={this.props.pageState}
                            richiesta={this.props.richiesta}
                            salvaBozza={this.props.salvaBozza}
                            forceReadOnly={this.props.forceReadOnly || this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                        />
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={StatoCognitivo}
                            datiStatoCognitivoForm={this.props.datiAnamnesiFisiologica.datiStatoCognitivo}
                            onChangeDatiStatoCognitivoForm={this.props.onChangeDatiAnamnesiFisiologica}
                            openAccordion={this.state.accordion.openAccordionStatoCognitivo}
                            onClickAccordion={this.onClickAccordion}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            field={"openAccordionStatoCognitivo"}
                            pageState={this.props.pageState}
                            richiesta={this.props.richiesta}
                            salvaBozza={this.props.salvaBozza}
                            forceReadOnly={this.props.forceReadOnly || this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                        />
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={StatoComportamentale}
                            datiStatoComportamentaleForm={this.props.datiAnamnesiFisiologica.datiStatoComportamentale}
                            onChangeDatiStatoComportamentaleForm={this.props.onChangeDatiAnamnesiFisiologica}
                            pageState={this.props.pageState}
                            openAccordion={this.state.accordion.openAccordionStatoComportamentale}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionStatoComportamentale"}
                            forceReadOnly={this.props.forceReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            salvaBozza={this.props.salvaBozza}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                        />
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion
                            accordion={NecessitaDiAssistenzaSanitaria}
                            datiNecessitaDiAssistenzaSanitariaForm={this.props.datiAnamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                            figurePai={this.props.richiesta?.figureProfessionaliPresaInCarico}
                            professionistiSupportoAValutazione={this.props.richiesta?.professionistiSupportoAValutazione}
                            onChangeDatiNecessitaDiAssistenzaSanitaria={this.props.onChangeDatiAnamnesiFisiologica}
                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                            openAccordion={this.state.accordion.openAccordionNecessitaDiAssistenzaSanitaria}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionNecessitaDiAssistenzaSanitaria"}
                            pageState={this.props.pageState}
                            richiesta={this.props.richiesta}
                            salvaBozza={this.props.salvaBozza}
                            forceReadOnly={this.props.forceReadOnly || this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            accordionReadOnly={this.props.accordionReadOnly}
                            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                        />
                    </div>
                </div>

                <VersionedAccordion
                    accordion={AttivitaStrumentaliDellaVitaQuotidiana}
                    datiIADL={this.props.datiAnamnesiFisiologica.datiIADL}
                    onChangeDatiIADL={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionAttivitaStrumentaliDellaVitaQuotidiana}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionAttivitaStrumentaliDellaVitaQuotidiana"}
                    pageState={this.props.pageState}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    forceReadOnly={this.props.forceReadOnly || this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioUdito}
                    datiSensorioUditoForm={this.props.datiAnamnesiFisiologica.datiSensorioUdito}
                    onChangeDatiSensorioUditoForm={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioUdito}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioUdito"}
                    pageState={this.props.pageState}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioVista}
                    datiSensorioVista={this.props.datiAnamnesiFisiologica.datiSensorioVista}
                    onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioVista}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioVista"}
                    pageState={this.props.pageState}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioDolore}
                    datiSensorioDolore={this.props.datiAnamnesiFisiologica.datiSensorioDolore}
                    onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioDolore}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioDolore"}
                    pageState={this.props.pageState}
                    richiesta={this.props.richiesta}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput && !AuthUtils.hasUtenteRuoloFisiatra()}
                    salvaBozza={this.props.salvaBozza}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioBaseValutazione}
                    sensorio={"Olfatto"}
                    datiSensorio={this.props.datiAnamnesiFisiologica.sensorioOlfatto}
                    onChangeDatiSensorio={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioOlfatto}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioOlfatto"}
                    pageState={this.props.pageState}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioBaseValutazione}
                    sensorio={"Gusto"}
                    datiSensorio={this.props.datiAnamnesiFisiologica.sensorioGusto}
                    onChangeDatiSensorio={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioGusto}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioGusto"}
                    pageState={this.props.pageState}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioBaseValutazione}
                    sensorio={"Tatto"}
                    datiSensorio={this.props.datiAnamnesiFisiologica.sensorioTatto}
                    onChangeDatiSensorio={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioTatto}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioTatto"}
                    pageState={this.props.pageState}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={SensorioEquilibrio}
                    datiSensorioEquilibrio={this.props.datiAnamnesiFisiologica.sensorioEquilibrio}
                    onChangeDatiSensorioEquilibrio={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionEquilibrio}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionEquilibrio"}
                    pageState={this.props.pageState}
                    richiesta={this.props.richiesta}
                    forceReadOnly={this.props.forceReadOnly || this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    salvaBozza={this.props.salvaBozza}
                    fieldIdParent ={"anammnesiFisiologica"}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion
                    accordion={Idratazione}
                    datiIdratazione={this.props.datiAnamnesiFisiologica.datiIdratazione}
                    onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionIdratazione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionIdratazione"}
                    pageState={this.props.pageState}
                    forceReadOnly={this.props.forceReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    disableDoubleInput={this.props.disableDoubleInput}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                {isFemale ?
                    <VersionedAccordion
                        accordion={Donna}
                        donna={this.props.datiAnamnesiFisiologica.donna}
                        onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionDonna}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionDonna"}
                        pageState={this.props.pageState}
                        forceReadOnly={this.props.forceReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/> :
                    <VersionedAccordion
                        accordion={Uomo}
                        uomo={this.props.datiAnamnesiFisiologica.uomo}
                        onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                        openAccordion={this.state.accordion.openAccordionUomo}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionUomo"}
                        pageState={this.props.pageState}
                        forceReadOnly={this.props.forceReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        accordionReadOnly={this.props.accordionReadOnly}
                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                    />
                }
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"anamnesiFisiologica"}
                title={"Anamnesi Fisiologica"}
                subtitle={"(Stato nutrizionale, Idratazione, Respiro, Eliminazione, Sonno, Profilo dell’autonomia, Comunicazione, Sensorio)"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.getRenderFase()}</AccordionAvvioRichiesta>
        );
    }

    getRenderFase() {
        if (Utils.isStateRichiesta(this.props.pageState)) {
            return this.renderBodyFase1();
        }
        return Utils.isRuoloInfermiere() ? this.renderBodyFase2Infermiere() : this.renderBodyFase2Medico();
    }
}

AnamnesiFisiologica.contextType = RoleBasedContext;

AnamnesiFisiologica.propTypes = propTypes;

AnamnesiFisiologica.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}
