import React, {Fragment, useEffect} from "react";
import PropTypes from "prop-types";
import {Button} from "web-client-archetype";
import enumQuestionario from "../../../enum/enumQuestionario"
import ButtonHelper from "../../../service/ButtonHelper";
import {calcoloValutazione, getSezioneValutazione, punteggioTotaleArrotondato, setRichiestaQuestionario} from "../../../utils/QuestionariUtils";
import RichiestaADIService from "../../../service/RichiestaADIService";
import Utils from "../../../utils/Utils";
import {mostraSpinner, nascondiSpinner} from "../../../App";
import _ from "lodash";
import ButtonsBoxStick from "../../buttonsBoxStick/buttonsBoxStick";
import AccordionHelper from "../../../service/AccordionHelper";

/**
 * @function BottoniQuestionario renderizza i tre bottoni (Indietro, Salva in Bozza, Conferma Questionario) presenti sui questionari
 * @param indietro funzione che viene chiamata al click del button indietro.
 * @param richiesta contiene l'informazione della richiesta in compilazione (come arriva del backend)
 * @param richiestaAggiornata contiene l'informazione del questionario aggiornata con tutti i cambiamenti fatti
 * @param tipoQuestionario tipo di questionario che si compila, es. PSCO, PSAN, PASSI,. etc.
 * @param isQuestionarioCompleto booleano che ci indica se il questionario e stato compilato comppletamente
 * @param pageState stato della pagina attuale - R || V || F
 * @param pathRichiestaQuestionario indica in quale obggeto della richiesta ADI vengono salvate le informazione del questionario in compilazione
 * @param areRispostePresenti boolean che abilita/disabilati il bottone salva in bozza
 * @param sessoPaziente contiene il sesso del pasiente che compila l'attuale questionario - F || M
 * @param questionario contiene l'informazione del questionario in compilazione (pressa dal JSON)
 * @param punteggiIntermedi punteggi intermedi
 * @param canStorePunteggioAndValutazione boolean che stabilisce se gli attributi [punteggio] e [valutazione] possono essere memorizzati sul database nell'oggetto del relativo questionario
 * @param disabilitato boolean che disabilita tutto il questionario, per cio dobrebbe disabilitare anche il salva in bozza e il conferma questionario
*/
function BottoniQuestionario ({indietro, richiesta, richiestaAggiornata, tipoQuestionario, isQuestionarioCompleto, pageState, pathRichiestaQuestionario, areRispostePresenti, sessoPaziente, questionario, punteggiIntermedi, esegueSalvaBozza, resetSalvaBozza, canStorePunteggioAndValutazione, disabilitato, isMenuItemClicked}) {

    useEffect(() => {
        if(esegueSalvaBozza) {
            salvaBozza(richiestaAggiornata, enumQuestionario.statoQuestionario.bozza);
        }
    }, [esegueSalvaBozza]);


    /**
     * @function valorizzaPunteggiIntermedi valorizza il questionario con punteggi intermedi
     * @param punteggioSottoQuestionari punteggi
     * @param questionarioAggiornato contiene l'informazione del questionario aggiornata con tutti i cambiamenti fatti
     * 
    */
    const valorizzaPunteggiIntermedi = (punteggioSottoQuestionari, questionarioAggiornato) => {
        let punteggiIntermedi = null;
        if (punteggioSottoQuestionari != null) {
            punteggiIntermedi = [];
            _.forIn(punteggioSottoQuestionari[tipoQuestionario], (valore, idSezione) => {
                const numberIdSezione = Number(idSezione);
                const punteggio = questionario?.sezioni?.find(s => s.id === numberIdSezione)?.punteggi?.find(p => Object.prototype.hasOwnProperty.call(p, 'valori') && p?.valori?.length) ?? null;
                const valorePunteggio = punteggio?.valori?.find(v => {
                    if (v.max == null) return valore >= v.min;
                    return valore >= v.min && valore <= v.max;
                }) ?? null;
                let punteggioIntermedio = {
                    id: numberIdSezione,
                    linkId: punteggio?.linkId ?? null,
                    text: punteggio?.label || null,
                    tipoRisposta: punteggio?.tipoRisposta ?? null,
                    valore: isQuestionarioCompleto ? valore : null,
                    system: isQuestionarioCompleto ? (valorePunteggio?.system ?? null) : null,
                    code: isQuestionarioCompleto ? (valorePunteggio?.code ?? null) : null,
                    display: isQuestionarioCompleto ? (valorePunteggio?.display ?? null) : null
                };
                punteggiIntermedi.push(punteggioIntermedio);
            })
        }
        questionarioAggiornato[tipoQuestionario].punteggiIntermedi = punteggiIntermedi;
    }

    /**
     * @function getLinkIdPunteggi recupera tutti gli linkId che si trovano nel array punteggio position 0 di ogni sezione 
     * @return  array di oggeto con la sezioneId e il linkId trovato
    */
    const getLinkIdPunteggi = () => {
        return _.cloneDeep(questionario).sezioni.map(sezione => ({
            sezioneId: sezione.id,
            linkId: sezione.punteggi[0] && sezione.punteggi[0].linkId
        })).filter(s => s.linkId)
    }

    /**
     * @function salvaBozza funzione esseguita al click del bottone Salva bozza
     * @param questionarioAggiornato contiene l'informazione del questionario aggiornata con tutti i cambiamenti fatti
     * @param statoQuestionario stato attuale del questionario manuale|bozza|completo
     * 
    */
    const salvaBozza = (questionarioAggiornato, statoQuestionario) => {
        let richiestaBody = _.cloneDeep(richiesta);
        questionarioAggiornato[tipoQuestionario].stato = statoQuestionario;
        if (statoQuestionario !== enumQuestionario.statoQuestionario.bozza) {
            valorizzaPunteggiIntermedi(punteggiIntermedi, questionarioAggiornato);
            if (canStorePunteggioAndValutazione) {
                questionarioAggiornato[tipoQuestionario].punteggio = punteggioTotaleArrotondato(punteggiIntermedi, tipoQuestionario);
                questionarioAggiornato[tipoQuestionario].linkIdPunteggi = getLinkIdPunteggi();
                let sezioneValutazione = getSezioneValutazione(tipoQuestionario);
                questionarioAggiornato[tipoQuestionario].valutazione = calcoloValutazione(
                    tipoQuestionario, sezioneValutazione,
                    questionarioAggiornato[tipoQuestionario].punteggio, sessoPaziente
                );
            }
        } else {
            questionarioAggiornato[tipoQuestionario].punteggio = null;
            questionarioAggiornato[tipoQuestionario].punteggiIntermedi = null;
            questionarioAggiornato[tipoQuestionario].valutazione = null;
        }

        setRichiestaQuestionario(richiestaBody, questionarioAggiornato[tipoQuestionario], pathRichiestaQuestionario)
        let salva = Utils.isStateValutazione(pageState)
            ? RichiestaADIService.salvaVariazioniInBozza(richiestaBody.id, richiestaBody, ButtonHelper.clickSalvaButton)
            : RichiestaADIService.salvaBozza(richiestaBody.id, richiestaBody, ButtonHelper.clickSalvaButton);
        mostraSpinner();
        return salva.subscribe((response) => {
            if (response?.msg === "ERRORE") {
                if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                    })
                }
            }
            if (statoQuestionario === enumQuestionario.statoQuestionario.completo) {
                indietro();
            }
            if (isMenuItemClicked) {
                AccordionHelper.requiredAccordionFieldsSubject.next({
                    canRedirectToFullAccordionPage: true,
                    richiesta: null
                });
            }
            nascondiSpinner();
            if(esegueSalvaBozza) resetSalvaBozza() 
        });
    };

    /**
     * @function confermaQuestionario funzione esseguita al click del bottone conferma questionario
     * @param questionarioAggiornato contiene l'informazione del questionario aggiornata con tutti i cambiamenti fatti
     * 
    */
    const confermaQuestionario = (questionarioAggiornato) => {
        salvaBozza(questionarioAggiornato, enumQuestionario.statoQuestionario.completo);
    }

    return (
        <ButtonsBoxStick
            parentClass="d-flex justify-content-between"
            btnList={[
                {
                    id: "indietro",
                    text:"Indietro",
                    className:"btnWhiteOutlineBorderGreen",
                    onclickFunction:indietro,
                    isvisible: true,
                },
                {
                    id: "bozza",
                    text:"Salva bozza",
                    className:"btnWhiteOutlineBorderGreen",
                    onclickFunction: () => {
                        ButtonHelper.setSalvaButtonFlag(true);
                        salvaBozza(richiestaAggiornata, enumQuestionario.statoQuestionario.bozza);
                    },
                    disabled: disabilitato || !areRispostePresenti,
                    isvisible: true,
                },
                {
                    id: "conferma",
                    text:"Conferma questionario",
                    className:"btnGreenOutline",
                    onclickFunction:() => {
                        ButtonHelper.setSalvaButtonFlag(true);
                        confermaQuestionario(richiestaAggiornata)
                    },
                    disabled: disabilitato || !isQuestionarioCompleto,
                    isvisible: true,
                },
            ]}
        />
    )
}

BottoniQuestionario.propTypes = {
    indietro: PropTypes.func.isRequired,
    richiesta: PropTypes.node,
    richiestaAggiornata: PropTypes.node,
    tipoQuestionario: PropTypes.string.isRequired,
    isQuestionarioCompleto: PropTypes.bool,
    pageState: PropTypes.string,
    pathRichiestaQuestionario: PropTypes.string,
    areRispostePresenti: PropTypes.bool,
    sessoPaziente: PropTypes.string,
    disabilitato: PropTypes.bool,
    isMenuItemClicked: PropTypes.bool
};

BottoniQuestionario.defaultProps = {
    richiesta: {},
    richiestaAggiornata: {},
    isQuestionarioCompleto: false,
    pageState: "",
    pathRichiestaQuestionario: "",
    areRispostePresenti: false,
    sessoPaziente: "",
    disabilitato: false,
    isMenuItemClicked: false
};

export default BottoniQuestionario;
