import React, {Component} from "react";
import styles from "./Box.module.css";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";

const propTypes = {
    key: PropTypes.string,
    children: PropTypes.node.isRequired,
    hideBox: PropTypes.bool,
    titleBox: PropTypes.string,
    accordionPadre: PropTypes.bool
};

class Box extends Component {

    render() {
        return <div className={!this.props.hideBox && styles.boxAccordion + " box-accordion"} key={this.props.key} id={this.props.key}>
            <div className={"box-accordion-header"} style={{fontWeight: "bold"}}>
                {this.props.titleBox}
            </div>
            <div className={"box-accordion-body"}>
                {this.props.children}
            </div>
        </div>;
    }
}

Box.propTypes = propTypes;

Box.defaultProps = {
    accordionPadre: false
}

export default Box;
