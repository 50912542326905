import * as _ from "lodash";
import message from "./message/message-it";
import * as moment from "moment";
import {Observable} from "rxjs";
import globals from "../config/globalConfig";
import atob from "atob"
import * as FILESAVER from "file-saver";
import {SessioneUtente, toasterConfig, ToasterSingleton} from "web-client-archetype";
import icd9 from "./dataset/icd9cmData/icd9cm-flat.json";
import cndData from "./dataset/cndData/cnd.plain.json";
import OggettiUtili from "./dataset/OggettiUtili.json";
import 'moment/locale/it';
import $ from "jquery";
import routePath from "./route/route-path.json";
import pageTexts from "./message/pageTexts.json";
import errors from "./errors/errors.json";
import {isFieldIdWriteable} from "../components/roleBasedComponents/RoleBasedComponents";
import scadenzeAttivita from "./dataset/prioritaData/scadenze_attivita.json";
import enumRichiesta from "../enum/enumRichiesta.json";
import enumUtente from "../enum/enumsUtente.json";
import RichiestaADIService from "../service/RichiestaADIService";
import {map} from "rxjs/operators";
import enumPaginaCompila from "../enum/enumPaginaCompila.json";
import AuthUtils from "./AuthUtils";

// eslint-disable-next-line no-undef
const tzMoment = require("moment-timezone");

const toasterSingleton = ToasterSingleton.instance();

export default class Utils {

    static getBody = (response) => {
        return response.data
    };

    static toasterFunction(message) {
        let toaster = {
            type: message.type ? message.type : toasterConfig.types.confirm, //in caso di problemi nel recuperare il tipo di toaster, lo mostro come errore
            text: errors[message.text]?.message || message.text,
            timestamp: Date.now(),
            keepOpen: !!message.keepOpen,
            override: !!message.override,
            priority: message.priority ?? 1,
            key: message.key ? message.key : null
        }

        toasterSingleton.aggiuntaToaster(toaster);
    }

    static svuotaToaster(idToaster = null) {
        if (idToaster !== null) {
            toasterSingleton.onCloseToaster(idToaster, true);
        } else {
            toasterSingleton.pulisci();
        }
    }

    static isToasterPresente(idToaster) {
        return toasterSingleton.elencoToaster.some(toaster => toaster.key === idToaster);
    }

    static regexIndexOf(stringa, regex, startpos) {
        var indexOf = stringa.substring(startpos || 0).search(regex)
        return (indexOf >= 0) ? (indexOf + (startpos || 0)) : indexOf
    }

    static areToasterPresenti() {
        return toasterSingleton.elencoToaster.length > 0;
    }

    static transformTimeStampForFrontend(date, conversionFormatDate = "DD/MM/YYYY") {
        if (!Utils.isStringEmptyOrNullOrUndefined(date) && moment(date, conversionFormatDate).format(conversionFormatDate) !== date)
            return moment(date.split("T")[0]).format(conversionFormatDate);
        else if (!Utils.isStringEmptyOrNullOrUndefined(date) && moment(date, conversionFormatDate).format(conversionFormatDate) === date)
            return date;
        else
            return null;
    }

    static transformDateAndTimeForFrontend(date, conversionFormatDate = "DD/MM/YYYY") {
        if (!Utils.isStringEmptyOrNullOrUndefined(date) && moment(date, conversionFormatDate).format(conversionFormatDate) !== date)
            return moment.utc(date).local().format(conversionFormatDate);
        else if (!Utils.isStringEmptyOrNullOrUndefined(date) && moment(date, conversionFormatDate).format(conversionFormatDate) === date)
            return date;
        else
            return null;
    }

    static isDateInValid(string, delimiter = "/") {
        return !(moment(string, `DD${delimiter}MM${delimiter}YYYY`, true).isValid())
    }

    static convertUtcToLocalDate(utcDate) {
        return moment.utc(utcDate).local();
    }

    static convertLocalToUtcDate(localDate) {
        return moment.utc(localDate).format("YYYY-MM-DDTHH:00:00").concat("Z");
    }

    static getMomentLocalDate(date, endOfDay) {
        if (endOfDay) {
            return moment(date, "DD-MM-YYYY").endOf("day");
        }
        return moment(date, "DD-MM-YYYY");
    }

    static timeDifference(date1, date2, c) {
        let difference = date1 - date2;

        let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
        // difference -= daysDifference*1000*60*60*24

        let hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        difference -= hoursDifference * 1000 * 60 * 60

        // let minutesDifference = Math.floor(difference/1000/60);
        // difference -= minutesDifference*1000*60
        //
        // let secondsDifference = Math.floor(difference/1000);
        if (c)
            return daysDifference
        return hoursDifference
    }

    static checkDateIntervalMinore(dataUno, dataDue, minoreUguale) {
        let errore = false

        if (dataUno !== "" && dataDue !== "" &&
            dataUno != null && dataDue != null
        ) {
            let date1 = new Date(Utils.transformDateForBackend(dataUno))
            let date2 = new Date(Utils.transformDateForBackend(dataDue))

            if (minoreUguale) {
                if (date1.getTime() <= date2.getTime()) {
                    errore = true

                }
            } else {
                if (date1.getTime() < date2.getTime()) {
                    errore = true

                }
            }

        }
        return errore
    }

    static checkDateInterval(dataSegnalazioneA, dataSegnalazioneDa) {
        let errore = false
        if (dataSegnalazioneA !== "" && dataSegnalazioneDa !== "" &&
            dataSegnalazioneA != null && dataSegnalazioneDa != null) {
            let date1 = Utils.transformDateForBackend(new Date(dataSegnalazioneA))
            let date2 = Utils.transformDateForBackend(new Date(dataSegnalazioneDa))

            if (date1.getTime() <= date2.getTime()) {
                errore = true
            }
        }
        return errore
    }

    static isDateValid(string, delimiter = "/") {
        return (moment(string, `YYYY${delimiter}MM${delimiter}DD`, true).isValid())
    }

    static transformDateForBackend(date, format = "YYYY-MM-DDTHH:mm:ssZ") {
        if (moment(date, "DD/MM/YYYY", true).isValid() && date.replace)
            return moment(date, "DD/MM/YYYY").format(format);
        else return date;
    }

    static getDayOfWeekIT(date) {
        return moment(date).locale('it').format("dddd");
    }

    static isObjectEmpty(_object) {
        if (_object === undefined || _object === null || _object === "")
            return true;
        if (!_object) return false;
        return (
            Object.entries(_object).length === 0 && _object.constructor === Object
        );
    }


    /**
     * Ritorna una data nel formato Iso YYYY-MM-DDTHH:mm:ss+HH:mm
     *
     * @param date stringa nel formato DD/MM/YYYY
     * @param time (facoltativo) stringa nel formato HH:mm
     * @return stringa nel formato YYYY-MM-DDTHH:mm:ss+HH:mm
     */
    static convertDDMMYYYYDateToIsoDate = (date, time) => {
        let formattedDate;
        if (time)
            formattedDate = moment(date + " " + time, "DD/MM/YYYY HH:mm").format("YYYY-MM-DDTHH:mm:ss").concat("Z");
        else
            formattedDate = moment(date, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ss").concat("Z");
        return formattedDate;
    };

    /**
     * Ritorna una data nel formato ISO (YYYY-MM-DDTHH:mm:ss+HH:mm)
     * @returns stringa nel formato ISO (YYYY-MM-DDTHH:mm:ss+HH:mm)
     */
    static getNowInIsoDate = () => {
        return this.getDateInIsoDate();
    };

    /**
     * Ritorna una data nel formato ISO (YYYY-MM-DDTHH:mm:ss+HH:mm)
     * @param date stringa da convertire (data nel formato moment)
     * @returns stringa nel formato ISO (YYYY-MM-DDTHH:mm:ss+HH:mm)
     */
    static getDateInIsoDate = (date = moment()) => {
        let localtimezone = globals.zoneId;
        return moment.tz(date, localtimezone).format();
    };

    static getTimeFromTimestamp = (timestamp = moment(), format = "hh:mm") => {
        return moment(new Date(timestamp)).format(format);
    };

    //Controlla se date è una stringa in formato DD/MM/YYYY
    static isValidDateFormatDDMMYYYY = (date) => {
        let dataRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;

        //Esplicitare true-false NON è ridondante; Senza esplicitarli, quando la prima condizione va a buon fine, il metodo restituisce null o l'oggetto con i dati della regex
        return !!(!Utils.isStringEmptyOrNullOrUndefined(date) && date.match(dataRegex));
    };

    static getHourFormatHHmm = (ora, minuti) => {
        let oraRegex = /^([0-1][0-9]|2[0-3])$/;
        let minutiRegex = /^[0-5][0-9]$/;
        return !Utils.isStringEmptyOrNullOrUndefined(ora) && !Utils.isStringEmptyOrNullOrUndefined(minuti)
        && ora.match(oraRegex) && minuti.match(minutiRegex) ? ora + ":" + minuti : null;
    }

    static isValidHourFormatHHmm = (string) => {
        let orarioRegex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
        //Esplicitare true-false NON è ridondante; Senza esplicitarli, quando la prima condizione va a buon fine, il metodo restituisce null o l'oggetto con i dati della regex
        return !!(!Utils.isStringEmptyOrNullOrUndefined(string) && string.match(orarioRegex));
    }

    //Controlla se date è una stringa in formato YYYY-MM-DD
    static isValidDateFormatYYYYMMDD = (date) => {
        let dataRegex = /^(\d{4})(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$/;
        return !!(!Utils.isStringEmptyOrNullOrUndefined(date) && date.match(dataRegex));
    };

    static changeDateFormat = (v) => {
        let fromDate = new Date(v);
        return new Date(fromDate).toDateString("yyyy-MM-dd");
    };

    /**
     * @param date stringa in un qualsiasi formato
     * @param formatInputDate stringa nel formato di output voluto
     * @returns formatOutputDate che rappresenta la data nel formato voluto
     */
    static formatDate = (date, formatInputDate, formatOutputDate) => {
        return moment(date, formatInputDate).format(formatOutputDate);
    };

    static mapCodedValue(json) {
        json.forEach(element => {
            Object.keys(element).forEach(function (key) {
                    try {
                        var parsed = JSON.parse(element[key]);
                        if (_.isObject(parsed) && parsed.codedValue.length > 0) {
                            if (parsed.codedValue[0].tipo === "valore") {
                                element[key] = parsed.codedValue[0]["Msg"];
                            } else {
                                var parametersArray = [];
                                parsed.codedValue[0].parametri.forEach((parameter) => {
                                    if (parameter.tipo === "valore") {
                                        parametersArray.push(parameter.valore)
                                    } else {
                                        parametersArray.push(message[parameter.valore])
                                    }
                                });

                                let el = message[parsed.codedValue[0]["Msg"]];
                                parametersArray.forEach((value, index) => {
                                    el = el.replace("$" + (index + 1), value)
                                });
                                element[key] = el;
                            }
                        }
                    } catch {
                        console.log("no json")
                    }
                }
            );
        });
        return json;
    }

    static isStringEmptyOrNullOrUndefined = (string) => {
        return string === undefined || string === null || string === "";
    }

    static isValidBoolean = (param) => {
        return param === true || param === false;
    }

    /**
     * Controlla se una stringa contiene un numero float valido, composto da: una parte con numeri, obbligatoria; una parte con un punto e numeri, opzionale
     * @param string la stringa da controllare
     * @returns {boolean|boolean} true se la stringa rispetta il pattern; false altrimenti
     */
    static isValidFloat = (string) => {
        let floatRegex = /^[0-9]+(\.[0-9]+)?$/
        return !Utils.isStringEmptyOrNullOrUndefined(string) && !Utils.isObjectNull(String(string).match(floatRegex));
    }

    /**
     * Controlla se un oggetto è null o vuoto
     * @param obj l'oggetto da controllare
     * @returns
     *      - true se obj è:
     *          - null
     *          - undefined
     *          - oggetto vuoto {}
     *          - array vuoto []
     *          - stringa vuota ""
     *          - oggetto/array contenente solo valori null e/o valori undefined e/o stringhe vuote
     *          - un tipo di dato sul quale non è possibile eseguire un forEach (esempio: un numero, un boolean, una funzione, ...)
     *      - false altrimenti
     */
    static isObjectNull = (obj) => {
        let isObjNull;
        let bln = true;
        let objVal = _.filter(obj, (v, k) => {
            if (typeof obj[k] == "string" && obj[k] === "") {
                bln = false
            } else if (!obj[k]) {
                bln = false
            } else bln = !(Array.isArray(obj[k]) && obj[k].length === 0);

            return bln
        });

        isObjNull = objVal.length === 0;

        return isObjNull;
    };

    static getTarget = (e) => {
        if (e && e.target) {
            if (e.target.value != null) {
                return e.target.value;
            } else {
                return e.target.checked;
            }
        }
    };

    /**
     * Controlla se una stringa contiene un numero di telefono valido (da 1 a 15 caratteri numerici)
     * @param telefono la stringa da controllare
     * @returns {boolean|boolean} true se la stringa rispetta il pattern; false se la stringa non rispetta il pattern o è null o undefined
     */
    static isValidTelefono(telefono) {
        let regexPhoneNumber = /^[(+|[0-9]]*\d{0,14}$/;
        return !Utils.isStringEmptyOrNullOrUndefined(telefono) && !Utils.isObjectNull(telefono.match(regexPhoneNumber));
    }

    /**
     * Controlla se una stringa contiene un cap (5 caratteri numerici)
     * @param cap la stringa da controllare
     * @returns {boolean|boolean} true se la stringa rispetta il pattern; false se la stringa non rispetta il pattern o è null o undefined
     */
    static isValidCap(cap) {
        let regexCap = /^\d{5}$/
        return !Utils.isStringEmptyOrNullOrUndefined(cap) && !Utils.isObjectNull(cap.match(regexCap));
    }

    /**
     * Controlla se una stringa contiene un codice fiscale valido
     * @param cf la stringa da controllare
     * @returns {boolean} true se la stringa rispetta il pattern; false se la stringa non rispetta il pattern o è null o undefined
     */
    static isValidCodiceFiscale(cf) {
        var validi, i, s, set1, set2, setpari, setdisp;

        if (Utils.isStringEmptyOrNullOrUndefined(cf)) return false;

        cf = cf.toUpperCase();
        if (cf.length !== 16) return false;

        validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        for (i = 0; i < 16; i++) {
            if (validi.indexOf(cf.charAt(i)) === -1) return false;
        }
        set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
        setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
        s = 0;
        for (i = 1; i <= 13; i += 2)
            s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
        for (i = 0; i <= 14; i += 2)
            s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
        return s % 26 === cf.charCodeAt(15) - "A".charCodeAt(0);
    }

    static controllaPartitaIVA(pi) {
        let i;
        if (!/^\d{11}$/.test(pi)) return false;
        else {
            let s = 0;
            for (i = 0; i <= 9; i += 2) {
                s += pi.charCodeAt(i) - '0'.charCodeAt(0);
            }
            for (i = 1; i <= 9; i += 2) {
                let c = 2 * (pi.charCodeAt(i) - '0'.charCodeAt(0));
                if (c > 9) c = c - 9;
                s += c;
            }
            let controllo = (10 - s % 10) % 10;
            return (controllo === (pi.charCodeAt(10) - '0'.charCodeAt(0)));
        }
    }

    /**
     * Controlla se una stringa contiene un'email valida
     * @param email la stringa da controllare
     * @returns {boolean} true se la stringa rispetta il pattern; false se la stringa non rispetta il pattern o è null o undefined
     */
    static isValidEmail(email) {
        let res = true;
        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!email || !email.trim().length || email.replace(/<(.|\n)*?>/g, '').trim().length === 0 || !email.match(regex)) {
            res = false;
        }

        return res;
    }

     /**
     * Controlla se una stringa contiene un stringa alfanumerica valida
     * @param stringa la stringa da controllare
     * @returns {boolean} true se la stringa rispetta il pattern; false se la stringa non rispetta il pattern o è null o undefined
     */
      static isValidAlphanumeric(stringa) {
        let res = true;
        let regex = /^[a-z0-9]+$/;

        if (!stringa || !stringa.trim().length || !stringa.match(regex)) {
            res = false;
        }

        return res;
    }

    static isValidAlphanumericMaiuscola(stringa) {
        let res = true;
        let regex = /[A-Z]/;

        if (!stringa || !stringa.trim().length || !stringa.match(regex)) {
            res = false;
        }

        return res;
    }


    static isValidCaratteriSpeciali(stringa) {
        const regex = /[\\^&%$#@!~"'()_{}<,>.?*\-+=[\]:;\\\\/]/;
        let res = true;

        if (!stringa || !stringa.trim().length || !stringa.match(regex)) res = false;

        return res;
    }

    static isValidStringMinLength(string, length){
        if (Utils.isStringEmptyOrNullOrUndefined(string)
            || string.length < length) {
            return false;
        }
        return true;
    }

    static isPasswordValid(password) {
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"?*()_@#$%^&\-+={[}\]:;'~|<,>./\\])(?=\S+$).{9,}$/;
        let res = true;

        if (!password || !password.trim().length || !password.match(regex)) res = false;

        return res;
    }

    static arePasswordMatch(p1, p2) {
        let res = true;
        if (p1 !== p2) {
            res = false
        }
        return res;
    }

    //Converte da UTC (data con Z) a ISO (data con timezone)
    static convertUTCDateToISOFormat = (date) => {
        let tz = globals.zoneId
        let dataUTC = tzMoment.tz(date, tz)
        return dataUTC.format()
    };

    /**
     * Ordina un array per data
     * @param array l'array da ordinare
     * @param field il campo dell'array sul quale ordinare
     * @returns l'elemento più piccolo dell'array ordinato
     */
    static getLastObjectForDate = (array, field) => {
        let sortedArray = _.orderBy(array, field, 'desc')
        return sortedArray[0];
    }

    /**
     * apertura di un file in un nuovo tab
     * @param stream lo stream del file in base64
     */
    static aperturaFile = (stream) => {
        let pdfWindow = window.open("")
        pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            stream + "'></iframe>"
        )
    }

    /**
     * converte un file a base64
     * @param file il file da convertire a base64
     * @returns observable con risultato base64
     */
    static fileToBase64 = (file) => {
        return new Observable(subscriber => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                subscriber.next(e.target.result.split("base64,")[1])
                subscriber.complete()
            };
        });
    }

    static between(min, max) {
        return Math.floor(
            Math.random() * (max - min) + min
        )
    }


    static getRandomString() {
        const randomItems = new Uint32Array(28);
        window.crypto.getRandomValues(randomItems);
        const binaryStringItems = randomItems.map(dec => `0${dec.toString(16).substr(-2)}`)
        return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
    }

    static getListIcd9cmCodDescr() {
        return icd9.map(e => ({
            codice: e.codice.toString(),
            descrizione: e.codice + " - " + e.descrizione
        }));
    }

    static getListCndData() {
        return cndData.map(e => ({
            codice: e.codice,
            descrizione: e.codice + " - " + e.descrizione
        }));
    }

    static encryptStringWithSHA256 = async (str) => {
        const PROTOCOL = 'SHA-256'
        const textEncoder = new TextEncoder();
        const encodedData = textEncoder.encode(str);
        return window.crypto.subtle.digest(PROTOCOL, encodedData)
    }

    static hashToBase64url(arrayBuffer) {
        const items = new Uint8Array(arrayBuffer)
        const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '')
        const decodedHash = btoa(stringifiedArrayHash)
        return decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
    }


    static parseJwt(token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    /** permette di verificare l'uguaglianza di valore di un oggetto.*/
    isEquivalent = (a, b) => {
        // Create arrays of property names
        let aProps = Object.getOwnPropertyNames(a);
        let bProps = Object.getOwnPropertyNames(b);

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length !== bProps.length) {
            return false;
        }

        for (let propName of aProps) {
            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    }

    /** Salva file excel */
    static salvaComeFileExcel(buffer, fileName) {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = this.b64toBlob(buffer, EXCEL_TYPE);
        FILESAVER.saveAs(data, fileName + '_export_' + moment().format('DD-MM-YYYY HH:mm') + EXCEL_EXTENSION);
    }

    static b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }

    /** verifica se due array sono identici (lunghezza e valori)*/
    static arrayEquals = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => {
                if (typeof val === 'object' && typeof b[index] === 'object')
                    return _.isEqual(val, b[index]);
                else
                    return val === b[index];
            });
    }

    /**
     * Calcola l'età di una persona a partire dalla sua data di nascita
     * @param dataNascita data di nascita della persona da usare per il calcolo della sua età
     * @param formatoDataNascita
     * @returns eta età della persona
     */
    static calcolaEta = (dataNascita, formatoDataNascita) => {
        if (dataNascita !== null) {
            let oggi = new Date();
            let dataNascitaFormatted = new Date(moment(dataNascita, formatoDataNascita).format("YYYY/MM/DD"));
            let eta = oggi.getFullYear() - dataNascitaFormatted.getFullYear();
            let diff = oggi.getMonth() - dataNascitaFormatted.getMonth();
            if (diff < 0 || (diff === 0 && oggi.getDate() < dataNascitaFormatted.getDate()))
                eta--;
            return eta;
        } else
            return null;
    }


    static sumRequiredFieldsMissing = (campiObbligatori) => {
        let campiObbligatoriMancanti = _.cloneDeep(campiObbligatori);
        let sum = 0;
        Object.keys(campiObbligatoriMancanti).forEach(key => {
            sum = sum + campiObbligatoriMancanti[key];
        });
        return sum;
    }

    static isStateRichiesta(pageState) {
        return pageState === 'R' || pageState === 'DP-PDA';
    }

    static isStateValutazione(pageState) {
        return pageState === 'V' || pageState === 'DP-V';
    }

    static isStateFormalizzazione(pageState) {
        return pageState === 'F' || pageState === 'DP-F';
    }

    static isFasePreDialogo(tipologiaRichiesta, statoRichiesta) {
        return Utils.isSegnalazione(tipologiaRichiesta) && statoRichiesta.endsWith("_PRE_DIALOGO");
    }

    static isClassica(tipologiaRichiesta) {
        return enumRichiesta.tipologia.CLASSICA === tipologiaRichiesta;
    }

    static isSegnalazione(tipologiaRichiesta) {
        return enumRichiesta.tipologia.SEGNALAZIONE === tipologiaRichiesta;
    }

    static isDimissioneProtetta(pageState) {
        return pageState.startsWith('DP-');
    }

    static isStateRichiestaPreDialogo(pageState) {
        return pageState === 'DP-PDA';
    }

    static isStateValutazionePreDialogo(pageState) {
        return pageState === 'DP-PDV';
    }

    static isStateChiusuraPreDialogo(pageState) {
        return pageState === 'DP-PDC';
    }

    static getPageStateDimissioneProtetta(tipologiaRichiesta, statoRichiesta, pageName) {
        if (Utils.isSegnalazione(tipologiaRichiesta)) {
            const statiRichiestaDPNonPredialogo = [
                enumRichiesta.stato.IN_CONFERIMENTO,
                enumRichiesta.stato.IN_VALUTAZIONE,
                enumRichiesta.stato.VALUTATA,
                enumRichiesta.stato.CONFERMATA,
                enumRichiesta.stato.IN_FORMALIZZAZIONE
            ];
            if (statiRichiestaDPNonPredialogo.includes(statoRichiesta)) {
                switch (pageName) {
                    case enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE:
                    case enumPaginaCompila.VISUALIZZA_VALUTAZIONE_MULTIDIMENSIONALE:
                    case enumPaginaCompila.ACCEDI_VALUTAZIONE_MULTIDIMENSIONALE:
                    case enumPaginaCompila.VISUALIZZA_RIEPILOGO:
                    case enumPaginaCompila.VERIFICA_VALUTAZIONE:
                    case `valutazione-${enumPaginaCompila.STATO_VALUTAZIONE_COMPONENTI_TEAM}`:
                    case `valutazione-${enumPaginaCompila.COMPILA_PROPOSTE}`:
                        return "DP-V";
                    case enumPaginaCompila.COMPILA_FORMALIZZAZIONE:
                        return "DP-F";
                    default:
                        return null;
                }
            } else {
                switch (pageName) {
                    case enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE:
                    case enumPaginaCompila.COMPILA_PROPOSTE:
                        return "DP-PDA";
                    case enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE:
                    case enumPaginaCompila.VISUALIZZA_VALUTAZIONE_MULTIDIMENSIONALE:
                    case enumPaginaCompila.ACCEDI_VALUTAZIONE_MULTIDIMENSIONALE:
                    case enumPaginaCompila.VISUALIZZA_RIEPILOGO:
                    case enumPaginaCompila.VERIFICA_VALUTAZIONE:
                    case `valutazione-${enumPaginaCompila.STATO_VALUTAZIONE_COMPONENTI_TEAM}`:
                    case `valutazione-${enumPaginaCompila.COMPILA_PROPOSTE}`:
                        return "DP-PDV";
                    case enumPaginaCompila.COMPILA_CHIUDI_PREDIALOGO:
                        return "DP-PDC";
                    default:
                        return null;
                }
            }
        }

        return null;
    }

    static valueToObject(object, campiSelectelect) {
        campiSelectelect.forEach(campo => {
            for (const key in campo) {
                const value = campo[key];
                if (object?.hasOwnProperty(key) && !Utils.isStringEmptyOrNullOrUndefined(object[key]))
                    object[key] = OggettiUtili[value]?.find(item => item.value === object[key]);
            }
        });
        return object;
    }

    static objectToValue(object, campiSelectelect) {
        campiSelectelect.forEach(campo => {
            for (const key in campo) {
                if (object?.hasOwnProperty(key))
                    object[key] = !Utils.isObjectNull(object[key]) ? object[key].value : null
            }
        });
        return object
    }

    static isRuoloInfermiere() {
        return SessioneUtente.getInstance().settings.some(setting => setting.ruolo === 'P_INFERMIERE');
    }

    static isActiveRole(role) {
        return SessioneUtente.getInstance().settings.some(setting => setting.ruolo === role);
    }


    static inizializzaVisualizzazioneAccordion(infoAccordion, sottoAccordion, dati, forceTrue = false, forceUserRole, pageState = null ) {
        let accordion = _.cloneDeep(sottoAccordion);
        infoAccordion.forEach(elem => {
            if (pageState != null && !isFieldIdWriteable(elem?.fieldId, pageState,forceUserRole)) {
                accordion[elem.stato] = true;
                return;
            }
            const values = dati[elem.campo];
            for (const field in values) {
                if (forceTrue) {
                    accordion[elem.stato] = true;
                    break;
                }

                if (field === "checked") continue;

                if (Array.isArray(values[field])) {
                    if (values[field].length > 1) {
                        accordion[elem.stato] = true;
                        break;
                    }
                    if (values[field].length === 1) {
                        for (const [, value] of Object.entries(values[field][0])) {
                            if (!Utils.isStringEmptyOrNullOrUndefined(value)) {
                                accordion[elem.stato] = true;
                                break;
                            }
                        }
                        break;
                    }
                } else {
                    if (!Utils.isStringEmptyOrNullOrUndefined(values[field])) {
                        accordion[elem.stato] = true;
                        break;
                    }
                }
                accordion[elem.stato] = false;
            }
        });
        return accordion;
    }

    static validateContactAndShowErrorPlaceholder = (field, contact = "telefono") => {
        let isValido;
        let placeholder;

        switch (contact) {
            case "email":
                isValido = Utils.isValidEmail(field);
                placeholder = "Inserire un'email valida";
                break;
            default:
                isValido = Utils.isValidTelefono(field);
                placeholder = "Inserire un numero di " + contact + " valido";
                break;
        }

        if (!Utils.isStringEmptyOrNullOrUndefined(field) && !isValido) {
            return placeholder;
        } else {
            return null;
        }
    }

    static getDistinctListByField = (list, field) => {
        return list
            .filter((v, i, a) => a.findIndex(t => (_.isEqual(t[field], v[field]))) === i)
            ?.map(elem => {
                elem[field] = elem[field] ?? null;
                return elem;
            })
            .filter(el => !Utils.isObjectNull(el[field]));
    }

    static getTitoloRicercaAssistito = (location) => {
        switch (location) {
            case routePath.home:
                return pageTexts.home.header;
            case routePath.storico_richieste:
                return pageTexts.storicoRichieste.header;
            default:
                return null;
        }
    }

    // Verifica se due array o due oggetti contegono gli stessi valori
    static compareArraysOrObjects = (first, second) => {
        // Get the first type
        const type = Object.prototype.toString.call(first);

        // If the two objects are not the same type, return false
        if (type !== Object.prototype.toString.call(second)) return false;

        // If items are not an object or array, return false
        if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

        // Compare the length of the length of the two items
        const firstLen = type === '[object Array]' ? first.length : Object.keys(first).length;
        const secondLen = type === '[object Array]' ? second.length : Object.keys(second).length;
        if (firstLen !== secondLen) return false;

        // Compare two items
        const compare = (item1, item2) => {
            // Get the object type
            const itemType = Object.prototype.toString.call(item1);

            if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {  // If an object or array, compare recursively
                if (!Utils.compareArraysOrObjects(item1, item2)) return false;
            } else {  // Otherwise, do a simple comparison
                // If the two items are not the same type, return false
                if (itemType !== Object.prototype.toString.call(item2)) return false;

                // Else if it's a function, convert to a string and compare
                // Otherwise, just compare
                if (itemType === '[object Function]') {
                    if (item1.toString() !== item2.toString()) return false;
                } else {
                    if (item1 !== item2) return false;
                }
            }
        };

        // Compare properties
        if (type === '[object Array]') {
            for (let i = 0; i < firstLen; i++) {
                if (compare(first[i], second[i]) === false) return false;
            }
        } else {
            for (const key in first) {
                if (Object.prototype.hasOwnProperty.call(first, key)) {
                    if (compare(first[key], second[key]) === false) return false;
                }
            }
        }

        // If nothing failed, return true
        return true;
    }

    static addSpaceBetweenCamelCaseString = (camelCaseString) => {
        return camelCaseString
            // insert a space before all caps
            .replace(/([A-Z])/g, ' $1')
            // uppercase the first character
            .replace(/^./, (str) => str.toUpperCase());
    }

    static getAlertMessageInvalidPage = (accordionValidatorKeys, validitaPaginaObject) => {
        let alertMessage = {...message.alertValiditaPagina};
        let invalidAccordionsSubAccordions = new Map();
        accordionValidatorKeys
            .filter(k => !validitaPaginaObject[k] || Object.keys(validitaPaginaObject[k]).some(k2 => !validitaPaginaObject[k][k2]))
            .forEach(k => {
                const validator = _.cloneDeep(validitaPaginaObject[k]);
                let subAccordionValidatorKeys = Object.keys(validator).filter(k2 => !validator[k2]);
                if (subAccordionValidatorKeys.length) {
                    const regex = new RegExp(/^is(.*?)Valid[o|a]$/);
                    invalidAccordionsSubAccordions.set(Utils.addSpaceBetweenCamelCaseString(k), subAccordionValidatorKeys.map(k2 => Utils.addSpaceBetweenCamelCaseString(regex.exec(k2)[1])).join(', '));
                } else invalidAccordionsSubAccordions.set(Utils.addSpaceBetweenCamelCaseString(k), '');
            });

        alertMessage.text = alertMessage.text.replace("{n}", invalidAccordionsSubAccordions.size > 1 ? "Negli accordion " : "Nell'accordion ");
        let format = [];
        for (const [key, value] of invalidAccordionsSubAccordions.entries()) format.push(key + " (sottoaccordion " + value + ")");
        alertMessage.text = alertMessage.text.replace("{accordions} {subaccordions}", format.join(', '));

        return alertMessage;
    }

    static resetObject = (object) => {
        Object.values(object).forEach(v => {
            if (Array.isArray(v)) v = [];
            else for (const k in v) v[k] = Array.isArray(v[k]) ? [] : null;
        });
    }

    static getErrorDataDa = (dataDa, dataA) => {
        return dataDa && dataA
            ? moment(dataDa, "DD/MM/YYYY").isAfter(moment(dataA, "DD/MM/YYYY"))
            : false;
    }

    static getErrorDataA = (dataDa, dataA) => {
        if (!dataDa && dataA) {
            return moment(dataA, "DD/MM/YYYY") < moment().format("DD/MM/YYYY");
        } else if (dataDa && dataA) {
            return moment(dataA, "DD/MM/YYYY").isBefore(moment(dataDa, "DD/MM/YYYY"));
        }
        return false;
    }

    static generateMapPathsObjects = (paths, objects) => {
        if (paths == null || objects == null) return {};

        if (paths.length !== objects.length) return {};

        return paths.reduce((prev, curr, index) => {
            const key = `$.${curr}`;
            prev[key] = prev[key] || [];
            prev[key] = Object.keys(objects[index]);
            return prev;
        }, {});
    }

    static getUniqueItemsByProperties = (items, propNames) => {
        const isPropValuesEqual = (subject, target, propNameArray) => propNameArray.every(propName => subject[propName] === target[propName]);
        return items.filter((item, index, array) =>
            index === array.findIndex(foundItem => isPropValuesEqual(foundItem, item, Array.from(propNames)))
        );
    }

    /**
     * Prende il campo di una lista per id dell'elemento
     * @param lista
     * @param nomeCampo nome del campo da trovare
     * @param id identificativo del relativo elemento della lista
     * @returns campo del relativo elemento della lista
     */
    static getValoreCampoElementoLista = (lista, nomeCampo, id) => {
        let valoreCampo = null;
        if (lista == null) return null;
        if (lista instanceof Array) {
            let elementoTrovato = _.find(lista, elemento => elemento.id === id);
            if (elementoTrovato) {
                valoreCampo = elementoTrovato[nomeCampo];
            }
        } else {
            throw new Error("Il parametro lista non è un instanza di 'array'");
        }
        return valoreCampo;
    }

    static calcolaDataScadenza = (tipologiaRichiesta, fase, livelloPriorita, timestampDaModificare) => {
        const tipoPriorita = scadenzeAttivita
            .filter(s => _.isEqual(tipologiaRichiesta, s.tipologiaRichiesta))
            .filter(s => _.isEqual(fase, s.fase))
            .flatMap(s => s.tipoPriorita)
            .find(p => _.isEqual(livelloPriorita, p.codice));

        return tipoPriorita != null
            ? moment(timestampDaModificare).add(parseInt(tipoPriorita.tempisticheAttivita.oreValutazioneScaduta, 10), 'hours')
            : moment(timestampDaModificare);
    }

    static getDataDimissioneProgrammataDaRicovero = uuidRichiesta => {
        return RichiestaADIService.getRichiesteHome(null, null, {
            filtriRichiesta: {id: uuidRichiesta},
            filtriAssistito: {}
        }, null, null, null)
            .pipe(map(response => response?.data?.elementi?.[0]?.dataDimissioneProgrammataDaRicovero?.dataDimissioneProgrammataDaRicovero))
    }

    static isSegnalazioneConfermataPreDialogoOrInChiusuraPreDialogo = (richiesta) => {
        return richiesta?.tipologia === enumRichiesta.tipologia.SEGNALAZIONE
            && (richiesta?.stato === enumRichiesta.stato.CONFERMATA_PRE_DIALOGO || richiesta?.stato === enumRichiesta.stato.IN_CHIUSURA_PRE_DIALOGO);
    }

    static recuperoIdProfessionista = (richiesta) => {
        if (AuthUtils.hasUtenteRuoloMcp() && Utils.isSegnalazioneConfermataPreDialogoOrInChiusuraPreDialogo(richiesta)) {
            const formalizzatore = richiesta?.team?.find(o => o.profilo === enumUtente.profiliUtente.medicoFormalizzatore);
            return formalizzatore?.professionista?.idProfessionista;
        } else return richiesta?.richiedente?.idProfessionista
    }

    static containsBasicProps = (props) => {
        if (props?.uuidRichiesta && props?.tipologia && props?.stato && props?.pathname) return true;
        else return false;
    }


    static isNullish = (obj) => {
        if(!obj) return false;
        return Object.values(obj).every(value => {
            if (value == null) {
                return true;
            }
            return false;
        });
    }
}

export const isCalendarIconDisabled = (id) => {
    let idDataValutazioneTestAudit = document.getElementById(id);
    if (idDataValutazioneTestAudit?.disabled) {
        $("#" + id + "-group").find(".input-group").find("a").css("pointer-events", "none");
    } else {
        $("#" + id + "-group").find(".input-group").find("a").css("pointer-events", "all");
    }
}

export const setFasciaBottoniStyle = (id) => {
    let fasciaBottoni = $(id);
    window.onscroll = function (ev) {
        if (Math.round(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            if (fasciaBottoni)
                fasciaBottoni.css("border-bottom", "30px solid #176A65");
        } else {
            if (fasciaBottoni)
                fasciaBottoni.css("border-bottom", "");
        }
    };
}

export const closeModaleStyle = () => {
    $(".modal-backdrop").css("display", "none");
    $(".modal-open").removeClass("modal-open");
    $("body").attr("style", "padding-right: 0 !important");
}
