import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedCalendar,
    RoleBasedICD9Input,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti,
    defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import moment from "moment";

const propTypes = {
    pageState: PropTypes.string,
    datiPatologieFamiliari: PropTypes.any,
    onChangeDatiPatologieFamiliari: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    forceReadOnly: PropTypes.bool,
    mascheraModificabilita: PropTypes.any,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool
}

const defaultPatologia = {
    id: null,
    codice: null
}

export default class PatologieFamiliari extends Component {

    state = {
        datiPatologieFamiliari: _.cloneDeep(this.props.datiPatologieFamiliari)
    }

    componentDidMount() {
        this.inizializzaForm()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiPatologieFamiliari, this.props.datiPatologieFamiliari))
           this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiPatologieFamiliari = _.cloneDeep(this.props.datiPatologieFamiliari);
        this.setState({datiPatologieFamiliari: datiPatologieFamiliari});
    }

    handleAggiornaForm = (field, elem, topField, index) => {
        this.setState(({datiPatologieFamiliari}) => {
            if (index != null) {
                datiPatologieFamiliari[topField][index][field] = elem;
            } else {
                datiPatologieFamiliari[field] = elem;
            }

            if (field === 'isPatologieFamiliari' && !elem) {
                datiPatologieFamiliari.patologieFamiliari = []
                datiPatologieFamiliari.tipoRelazioneConAssistito = null;
                datiPatologieFamiliari.condizioneRilevata = null;
            }

            return {datiPatologieFamiliari};
        }, () => this.props.onChangeDatiPatologieFamiliari("patologieFamiliari", _.cloneDeep(this.state.datiPatologieFamiliari)));
    }

    addNewPatologia = () => {
        let patologie = _.cloneDeep(this.state.datiPatologieFamiliari);
        let patologia = _.cloneDeep(defaultPatologia);
        patologia.id = uuid();
        patologie.patologieFamiliari.push(patologia);
        this.setState({datiPatologieFamiliari: patologie}, () => {
            this.props.onChangeDatiPatologieFamiliari("patologieFamiliari", _.cloneDeep(this.state.datiPatologieFamiliari));
        });
    }

    removePatologia = (index) => {
        let patologie = _.cloneDeep(this.state.datiPatologieFamiliari);
        patologie.patologieFamiliari.splice(index, 1);
        this.setState({datiPatologieFamiliari: patologie}, () => {
                this.props.onChangeDatiPatologieFamiliari("patologieFamiliari", _.cloneDeep(this.state.datiPatologieFamiliari));
            }
        )
    }

    renderPatologia = (patologia, index) => {
        return <FieldsRow>
            <RoleBasedICD9Input
                id={"autocompletePatologieFamiliari"}
                fieldId={'PAT_FAM.PATOLOGIEFAMILIARI.ANAMNESIFAMILIARE'}
                pageState={this.props.pageState}
                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, 'patologieFamiliari', index)}
                placeholder={"Patologie familiari"}
                value={patologia.codice}
                field={"codice"}
                forceReadOnly={Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.anamnesiFamiliare?.patologieFamiliari?.patologieFamiliari, "codice", patologia?.id)}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            />
        </FieldsRow>;
    }

    renderBody = () => {
        const datiPatologieFamiliari = this.state.datiPatologieFamiliari;

        return <>
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'PRES_PAT_FAM.PATOLOGIEFAMILIARI.ANAMNESIFAMILIARE'}
                    pageState={this.props.pageState}
                    id={"selectPresenzaPatologieFamiliari"}
                    options={OggettiUtili.rispostaBreve}
                    value={datiPatologieFamiliari.isPatologieFamiliari}
                    onChange={(elem) => this.handleAggiornaForm("isPatologieFamiliari", elem)}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: presenza patologia non esistente"}
                    fieldLabel={"Sono presenti patologie familiari?"}
                    handleOnlyValue={true}
                    field={"isPatologieFamiliari"}
                    forceReadOnly={this.props.mascheraModificabilita?.anamnesiFamiliare?.patologieFamiliari?.isPatologieFamiliari}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </FieldsRow>
            {datiPatologieFamiliari.isPatologieFamiliari &&
                <>
                    <SectionList
                        title={'Patologie familiari'}
                        data={datiPatologieFamiliari.patologieFamiliari}
                        renderSection={this.renderPatologia}
                        addNewSectionCallback={this.addNewPatologia}
                        removeSectionCallback={this.removePatologia}
                        addButtonVisibilityHandler={datiPatologieFamiliari.patologieFamiliari.length === 0 || datiPatologieFamiliari.patologieFamiliari?.every(p => !!p.codice)}
                        forceReadOnly={this.props.mascheraModificabilita?.anamnesiFamiliare?.patologieFamiliari?.patologieFamiliari}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'PAT_FAM.PATOLOGIEFAMILIARI.ANAMNESIFAMILIARE'}
                        field={'patologieFamiliari'}
                        parentJsonPath={"datiAnamnesiFamiliare.patologieFamiliari.patologieFamiliari"}
                    />
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'TIPO_REL_ASSISTITO.PATOLOGIEFAMILIARI.ANAMNESIFAMILIARE'}
                            pageState={this.props.pageState}
                            id={"selectTipoRelazioneConAssistito"}
                            options={OggettiUtili.tipoRelazioneConAssistitoPatologieFamiliari}
                            value={datiPatologieFamiliari.tipoRelazioneConAssistito}
                            onChange={(elem) => this.handleAggiornaForm("tipoRelazioneConAssistito", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Tipo di relazione con assistito non esistente"}
                            fieldLabel={"Tipo di relazione con assistito"}
                            handleOnlyValue={true}
                            field={"tipoRelazioneConAssistito"}
                            forceReadOnly={this.props.mascheraModificabilita?.anamnesiFamiliare?.patologieFamiliari?.tipoRelazioneConAssistito}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </FieldsRow>
                </>}

            <FieldsRow>
                <RoleBasedCalendar
                    fieldId={"DATA_VAL.PATOLOGIEFAMILIARI.ANAMNESIFAMILIARE"}
                    pageState={this.props.pageState}
                    id={"patologieFamiliariDataValutazione"}
                    placeholder={"gg/mm/aaaa"}
                    onChange={(elem) => this.handleAggiornaForm("dataValutazione", elem.target.value)}
                    value={datiPatologieFamiliari.dataValutazione}
                    error={moment(datiPatologieFamiliari.dataValutazione, "DD/MM/YYYY") > new Date()}
                    errorMessage={"Data non valida"}
                    fieldLabel={'Data valutazione'}
                    field={"dataValutazione"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </FieldsRow>

            <CondizioneRilevataSection
                ambito={'ANAMNESIFAMILIARE'}
                parentFieldId={'PATOLOGIEFAMILIARI.ANAMNESIFAMILIARE'}
                pageState={this.props.pageState}
                onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                valueCondizioneRilevata={datiPatologieFamiliari.condizioneRilevata}
                onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                valueDaAttenzionare={datiPatologieFamiliari.daAttenzionare}
                showSuggerimenti={true}
                onChangeSuggerimenti={this.handleAggiornaForm}
                valueSuggerimenti={datiPatologieFamiliari.propostaAttivitaSuggerimenti}
                openCondition={this.state.datiPatologieFamiliari.isPatologieFamiliari}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            />
        </>;
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"patologieFamiliari"}
                title={"Patologie Familiari"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["datiAnamnesiFamiliare.patologieFamiliari.patologieFamiliari",
                    "datiAnamnesiFamiliare.patologieFamiliari.propostaAttivitaSuggerimenti"],
                    [defaultPatologia, defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}

PatologieFamiliari.propTypes = propTypes;
