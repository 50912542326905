import React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {
    FieldsRow,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedRadio
} from "../../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../../utils/Utils";
import oggettiUtili from "../../../../utils/dataset/OggettiUtili.json";
import {isFieldIdWriteable} from "../../../roleBasedComponents/RoleBasedComponents";

const propTypes = {
    dati: PropTypes.any,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onChangeDatiDataDiPresaInCaricoTerritorialeDaDimissioneProtetta: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class DataDiPresaInCaricoTerritorialeDaDimissioneProtettaSub extends React.Component {
    state = {
        dataDiPresaInCaricoTerritorialeDaDimissioneProtetta: _.cloneDeep(this.props.dati)
    };

    componentDidMount() {
        this.props.onChangeRequiredFieldsNumber(this.countObbligatorieta())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati))
            this.setState({dataDiPresaInCaricoTerritorialeDaDimissioneProtetta: this.props.dati}, () => {
                this.props.onChangeRequiredFieldsNumber(this.countObbligatorieta());
            });
    }

    handleAggiornaForm = (field, elem) => {
        let dataDiPresaInCaricoTerritorialeDaDimissioneProtetta = _.cloneDeep(this.state.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta)
        dataDiPresaInCaricoTerritorialeDaDimissioneProtetta[field] = elem
        this.setState({dataDiPresaInCaricoTerritorialeDaDimissioneProtetta: dataDiPresaInCaricoTerritorialeDaDimissioneProtetta}, () => {
            this.props.onChangeDatiDataDiPresaInCaricoTerritorialeDaDimissioneProtetta("dataDiPresaInCaricoTerritorialeDaDimissioneProtetta", this.state.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta);
            this.props.onChangeRequiredFieldsNumber(this.countObbligatorieta())
        });
    }

    verificaCampiObbligatori = () => {
        return (
            isFieldIdWriteable("DATA_PIC_TERR.DATADIPRESAINCARICOTERRITORIALEDADIMISSIONEPROTETTA.RICHIESTACAMBIOSETTING", this.props.pageState)
            && isFieldIdWriteable("INOLTRO_RIC_PROC_CD.DATADIPRESAINCARICOTERRITORIALEDADIMISSIONEPROTETTA.RICHIESTACAMBIOSETTING", this.props.pageState)
            && Utils.isDimissioneProtetta(this.props.pageState)
            && !Utils.isStateRichiestaPreDialogo(this.props.pageState)
            && !Utils.isStateValutazionePreDialogo(this.props.pageState))
        
    }

    countObbligatorieta = () => {
        let dati = this.state.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta
        let numeroCampiObbligatoriMancanti = 0;
        if(this.verificaCampiObbligatori()) {
            if (!dati.dataPresaInCaricoTerritoriale) numeroCampiObbligatoriMancanti++
            if (dati.isInoltroRichiestaCureomiciliari == null) numeroCampiObbligatoriMancanti++
        }

        return numeroCampiObbligatoriMancanti
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"dataPresaInCaricoTerritorialeDimissioneProtetta"}
                title={"Data di presa in carico territoriale da dimissione protetta"}
                required={Utils.isStateRichiesta(this.props.pageState)}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}>
                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'DATA_PIC_TERR.DATADIPRESAINCARICOTERRITORIALEDADIMISSIONEPROTETTA.RICHIESTACAMBIOSETTING'}
                        pageState={this.props.pageState}
                        id={"dataPresaInCaricoTerritoriale"}
                        value={this.state.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta.dataPresaInCaricoTerritoriale}
                        onChange={(elem) => this.handleAggiornaForm("dataPresaInCaricoTerritoriale", elem.target.value)}
                        errorMessage={"Data non valida"}
                        fieldLabel={"Data presa in carico territoriale"}
                        forceReadOnly={this.props.forceReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        fieldRequired={Utils.isDimissioneProtetta(this.props.pageState)
                            && !Utils.isStateRichiestaPreDialogo(this.props.pageState)
                            && !Utils.isStateValutazionePreDialogo(this.props.pageState)}
                        field={"dataPresaInCaricoTerritoriale"}
                        parentJsonPath={"dataDiPresaInCaricoTerritorialeDaDimissioneProtetta"}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedRadio id={"isInoltroRichiestaCureomiciliari"}
                                    fieldId={'INOLTRO_RIC_PROC_CD.DATADIPRESAINCARICOTERRITORIALEDADIMISSIONEPROTETTA.RICHIESTACAMBIOSETTING'}
                                    pageState={this.props.pageState}
                                    group={"isInoltroRichiestaCureomiciliari"}
                                    value={this.state.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta.isInoltroRichiestaCureomiciliari}
                                    items={oggettiUtili.rispostaBreve}
                                    field={"isInoltroRichiestaCureomiciliari"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem)}
                                    customStyles={{justifyContent: "flex-start"}}
                                    customItemStyles={{marginRight: "20px"}}
                                    fieldLabel={'Inoltro richiesta al processo di Cure Domiciliari?'}
                                    fieldRequired={Utils.isDimissioneProtetta(this.props.pageState)
                                        && !Utils.isStateRichiestaPreDialogo(this.props.pageState)
                                        && !Utils.isStateValutazionePreDialogo(this.props.pageState)}
                                    parentJsonPath={"dataDiPresaInCaricoTerritorialeDaDimissioneProtetta"}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'NOTE_PIC_TERR.DATADIPRESAINCARICOTERRITORIALEDADIMISSIONEPROTETTA.RICHIESTACAMBIOSETTING'}
                        pageState={this.props.pageState}
                        value={this.state.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta.note}
                        placeholder="Inserisci note"
                        field={"note"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"note"}
                        fieldLabel={"Note"}
                        forceReadOnly={this.props.forceReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        parentJsonPath={"dataDiPresaInCaricoTerritorialeDaDimissioneProtetta"}
                    />
                </FieldsRow>
            </AccordionSezioneRichiesta>
        )
    }
}

DataDiPresaInCaricoTerritorialeDaDimissioneProtettaSub.propTypes = propTypes;
