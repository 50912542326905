import React, {Component} from "react";
import PropTypes from "prop-types";
import routepath from "../../utils/route/route-path.json";
import enumPaginaCompila from "../../enum/enumPaginaCompila.json";
import {Redirect} from "react-router-dom";
import {Input} from "web-client-archetype";
import Utils from "../../utils/Utils";
import {validaCodiceFiscale} from "../anagrafePaziente/padre/Padre";
import * as _ from "lodash";
import {DropdownIndicator, inputSelectStyles} from "../common/select/SelectUtils";
import ruoli from "../../utils/ruoli.json";
import Select from "react-select";
import RichiestaADIService from "../../service/RichiestaADIService";
import Breadcrumb from '../breadcrumb/Breadcrumb';
import {getValutazionePrecedente} from "../home/HomeUtils";
import ButtonsBoxStick from "../buttonsBoxStick/buttonsBoxStick";

const propTypes = {
    richiesta: PropTypes.object,
    pageState: PropTypes.string,
    pageName: PropTypes.string,
    showOnlyRequiredFields: PropTypes.bool,
    previousPageOnlyRequiredFields: PropTypes.bool,
    showMultiCompilationPages: PropTypes.bool
}

export default class NuovoValutatore extends Component {

    state = {
        redirectToIndietro: false,
        valutazione: {
            id: null,
            valutatore: {
                idProfessionista: null,
                codiceFiscale: null,
                nome: null,
                cognome: null,
                email: null,
                ruolo: null
            },
            stato: null,
            timestampConferma: null,
            variazioni: [],
            valutazionePrecedente: null
        }
    }

    ruoliSelect = [];

    componentDidMount() {
        this.popolaListaRuoli();
    }

    popolaListaRuoli = () => {
        Object.keys(ruoli).forEach(key => {
            ruoli[key].forEach(ruolo => {
                switch (key) {
                    case "ruoliProfessionistaValutatore":
                        this.ruoliSelect.push({
                            value: ruolo.value,
                            label: "Professionista Valutatore - ".concat(ruolo.label)
                        });
                        break;
                    case "ruoloCoordinatore":
                        this.ruoliSelect.push({value: ruolo.value, label: "Coordinatore - ".concat(ruolo.label)});
                        break;
                    case "ruoloReferenteDirigenteAssistentiSociali":
                        this.ruoliSelect.push({
                            value: ruolo.value,
                            label: "Referente/Dirigente assistenti sociali - ".concat(ruolo.label)
                        });
                        break;
                    case "ruoliDirettoreDiUnitaOperativa":
                        this.ruoliSelect.push({
                            value: ruolo.value,
                            label: "Direttore di unità operativa - ".concat(ruolo.label)
                        });
                        break;
                    default:
                        this.ruoliSelect.push({value: ruolo.value, label: ruolo.label});
                }
            });
        });
        this.ruoliSelect.unshift({value: null, label: "Seleziona"})
    }

    creaValutatoreMock = () => {
        let idRichiesta = this.props.richiesta.id;
        let valutazione = _.cloneDeep(this.state.valutazione);
        RichiestaADIService.creaValutatoreMock(idRichiesta, valutazione).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: "Il valutatore con codice fiscale " + valutazione.valutatore.codiceFiscale + "è già esistente. Inserirne uno nuovo"
                    })
                }
            } else {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "successo",
                    text: "Valutatore salvato correttamente"
                })
            }
        });
    }

    handleAggiornaForm = (field, value) => {
        let valutazione = _.cloneDeep(this.state.valutazione);
        let valutatore = valutazione.valutatore;
        valutatore[field] = value;
        valutazione.valutatore = valutatore;

        this.setState({valutazione});
    }

    renderCampiInput = () => {
        let valutatore = _.cloneDeep(this.state.valutazione.valutatore);

        let codiceFiscaleJSX = (
            <Input
                parentClass={"mb-3"}
                placeholder={"Inserisci codice fiscale"}
                field={"codiceFiscale"}
                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                id={"inputCodiceFiscaleValutatore"}
                value={valutatore.codiceFiscale}
                maxlength={"16"}
                label={"Codice fiscale"}
                required
                onValidate={(value) => validaCodiceFiscale(value)}
                infoText="Campo obbligatorio"

            />
        );

        let nomeJSX = (
            <Input
                parentClass={"mb-0"}
                placeholder={"Inserisci nome"}
                field={"nome"}
                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                id={"inputNomeValutatore"}
                value={valutatore.nome}
                label="Nome"
            />
        );

        let cognomeJSX = (
            <Input
                parentClass={"mb-0"}
                placeholder={"Inserisci cognome"}
                field={"cognome"}
                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                id={"inputCognomeValutatore"}
                value={valutatore.cognome}
                label="Cognome"
            />
        );

        let emailJSX = (
            <Input
                parentClass={"mt-4"}
                placeholder={"Inserisci e-mail"}
                field={"email"}
                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                id={"inputEmailValutatore"}
                value={valutatore.email}
                label={"E-mail"}
                required
                onValidate={(value) => Utils.validateContactAndShowErrorPlaceholder(value, "email")}
                infoText="Campo obbligatorio"
            />
        );

        let currentRuolo = !Utils.isStringEmptyOrNullOrUndefined(valutatore.ruolo)
            ? this.ruoliSelect.find(ruolo => ruolo.value === valutatore.ruolo)
            : null;
        let ruoloJSX = (
            <Select
                id={"selectRuoloValutatore"}
                className={"ruolo"}
                classNamePrefix={"inputSelect"}
                components={DropdownIndicator}
                styles={inputSelectStyles}
                options={this.ruoliSelect}
                value={currentRuolo}
                onChange={(elem) => this.handleAggiornaForm("ruolo", elem.value)}
                isSearchable={false}
                noOptionsMessage={() => "Errore: Ruolo non esistente"}
                placeholder={"Seleziona"}
            />
        );

        return (
            <>
                <div className={"row mt-4"}>
                    {this.renderColumn(codiceFiscaleJSX, "", true)}
                    {this.renderColumn(nomeJSX,"", false)}
                    {this.renderColumn(cognomeJSX, "", false)}
                </div>
                <div className={"row"}>
                    {this.renderColumn(emailJSX, "", false)}
                    {this.renderColumn(ruoloJSX, "Ruolo", false)}
                </div>
            </>
        );
    }

    renderColumn = (elemJSX, title, isRequired) => {
        return (
            <div className={"col-4"}>
                {title ? (<div className={"row"}>
                    <div className={"col-12 pb-1"}>
                        <span className={isRequired ? "required" : ""}><strong>{title}</strong></span>
                    </div>
                </div>) :null}
                <div className={"row"}>
                    <div className={"col-12"}>
                        {elemJSX}
                    </div>
                </div>
            </div>
        );
    }

    renderBottoni = () => {
        const {codiceFiscale, email} = this.state?.valutazione?.valutatore || {};
        const isDisabledBtnConferma = (!codiceFiscale || !Utils.isValidCodiceFiscale(codiceFiscale) || (!email || !Utils.isValidEmail(email)))

        return (
            <ButtonsBoxStick
                parentClass={"d-flex justify-content-between"}
                btnList={[
                    {
                        id: "indietroBtn",
                        text: "Indietro",
                        className: "btnWhiteOutlineBorderGreen",
                        onclickFunction: () => this.setState({redirectToIndietro: true}),
                        isvisible: true

                    },
                    {
                        id: "btnAggiungi",
                        text: "Aggiungi",
                        className: "btnGreenOutline",
                        onclickFunction: () => this.creaValutatoreMock(),
                        disabled: isDisabledBtnConferma,
                        isvisible: true
                    }
                ]}
            />
        );
    }

    render() {
        if (this.state.redirectToIndietro) {
            let pageRedirect = this.props.pageName === enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE
                ? routepath.completa_valutazione_multidimensionale
                : this.props.pageName === enumPaginaCompila.COMPILA_PROPOSTE
                    ? routepath.compila_proposte
                    : routepath.visualizza_riepilogo;

            return (
                <Redirect to={{
                    pathname: routepath.valutazione_multidimensionale.concat(pageRedirect),
                    uuidRichiesta: this.props.richiesta.id,
                    pageState: this.props.pageState,
                    valutazionePrecedente: (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) ? getValutazionePrecedente(this.props.richiesta) : null,
                    showOnlyRequiredFields: this.props.showOnlyRequiredFields,
                    previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields,
                    showMultiCompilationPages: this.props.showMultiCompilationPages
                }}/>
            );
        }

        return (
            <>
                <Breadcrumb path={['Home', 'Dettaglio valutazione', 'Inserimento nuovo valutatore']}
                            richiesta={this.props.richiesta}/>
                <div className="mx-5 my-3">
                    <h1>Inserimento nuovo valutatore</h1>
                    {this.renderCampiInput()}
                </div>
                {this.renderBottoni()}
            </>
        )
    }

}

NuovoValutatore.propTypes = propTypes;
