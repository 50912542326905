import {catchError, map, finalize} from "rxjs/operators";
import {from, of} from "rxjs";
import config from "../config/serviceConfig";
import {nascondiSpinner} from "../App";
import auth from "../utils/AthnAuth";
import {SessioneUtente} from "web-client-archetype";

export default class AnagrafeService {

    static urlUuid = config['ms_anagrafica'].uuid;
    static urlAnagrafiche = config['ms_anagrafica'].anagrafiche;
    static urlRicercheAnagrafiche = config['ms_anagrafica'].ricerche;
    static urlRicerchePersone = config['ms_anagrafica'].ricerchePersone;
    static urlPersoneAssistiti = config['ms_anagrafica'].personeAssistiti;
    static urlPersone = config['ms_anagrafica'].persone;
    static urlPersonaById = config['ms_anagrafica'].personaById;
    static urlMmg = config['ms_anagrafica'].mmg;
    static urlRichiedente = config['ms_anagrafica'].richiedente;
    static urlRicercaAnagraficheMediciRichiedenti = config['ms_anagrafica'].ricercaAnagraficheMediciRichiedenti;

    static getAnagrafiche(queryParams) {
        let instance = auth._httpClient;
        let formaAssociativa = SessioneUtente.getInstance().formaAssociativa?.codice;
        queryParams.formaAssociativa = formaAssociativa;
        let obs = from(instance.get(this.urlAnagrafiche, {params: queryParams}));
        return obs.pipe(
            map(response => {
                return response.data;
            }),
            catchError((err) => {
                return of(err.response?.data);
            })
        );
    }

    static getUuid() {
        let instance = auth._httpClient;
        let obs = from(instance.get(this.urlUuid.replace("{id}",SessioneUtente.getInstance()?.idUtente)));
        return obs.pipe(
            map(response => {
                return response.data;
            }),
            catchError((err) => {
                return of(err.response?.data);
            })
        );
    }

    static getUltimeRicerche(queryParams) {
        let instance = auth._httpClient;

        let obs = from(instance.get(this.urlRicercheAnagrafiche, {params: queryParams}));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            nascondiSpinner();
            console.log("Errore recupero ultime ricerche anagrafiche")
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static getUltimeRicerchePersone(queryParams) {
        let instance = auth._httpClient;

        let obs = from(instance.get(this.urlRicerchePersone, {params: queryParams}));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            nascondiSpinner();
            console.log("Errore recupero ultime ricerche persone")
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static getPersone(queryParams) {
        let instance = auth._httpClient;

        let obs = from(instance.get(this.urlPersone, {params: queryParams}));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            nascondiSpinner();
            console.log("Errore recupero ultime ricerche")
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static getPersonaById(idPersona) {
        let instance = auth._httpClient;

        let obs = from(instance.get(this.urlPersonaById.replace("{id}", idPersona)));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            nascondiSpinner();
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static creaPersona(idPersona) {
        let instance = auth._httpClient;

        let obs = from(instance.put(this.urlPersoneAssistiti.replace("{id}", idPersona)));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static creaMmg(idPersona) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlMmg.replace("{id}", idPersona)));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            console.log("Errore creazione mmg")
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static creaRichiedente(idPersona, body = null) {
        let instance = auth._httpClient;
        let obs;
        if(body!=null)
         obs = from(instance.post(this.urlRichiedente.replace("{id}", idPersona), body));
        else
            obs = from(instance.post(this.urlRichiedente.replace("{id}", idPersona)));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            console.log("Errore creazione richiedente")
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static salvaRichiedente(idPersona, body = null) {
        let instance = auth._httpClient;
        let obs;
        if(body!=null)
         obs = from(instance.put(this.urlRichiedente.replace("{id}", idPersona), body));
        else
            obs = from(instance.put(this.urlRichiedente.replace("{id}", idPersona)));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            console.log("Errore salva richiedente")
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static insertPersona(personaBody) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlPersone, personaBody));
        return obs.pipe(
            map(response => response.data),
            catchError(err => {
                return of({status: err.response?.status, data: err.response?.data, msg: "ERRORE"});
            })
        );
    }

    static modificaPersona(idPersona, personaBody) {
        let instance = auth._httpClient;

        let obs = from(instance.put(this.urlPersone.concat("/").concat(idPersona), personaBody));
        return obs.pipe(
            map(response => response.data),
            catchError(err => {
                return of({status: err.response?.status, data: err.response?.data, msg: "ERRORE"});
            })
        );
    }

    static cancellaPersona(idPersona) {
        let instance = auth._httpClient;

        let obs = from(instance.delete(this.urlPersone.concat("/").concat(idPersona)));
        return obs.pipe(
            map(response => response.data),
            catchError(err => {
                return of({status: err.response?.status, data: err.response?.data, msg: "ERRORE"});
            })
        )
    }


    static ricercaAnagraficheMediciRichiedenti() {
        let instance = auth._httpClient;
        let samlToken = SessioneUtente.getInstance().samlToken;
        let params = {authrve1b: "IHE-SAML " + samlToken};

        let obs = from(instance.get(this.urlRicercaAnagraficheMediciRichiedenti, {params: params}));
        return obs.pipe(
            map(response => {
                return response.data;
            }),
            catchError((err) => {
                return of({error: err});
            })
        );
    }

}
