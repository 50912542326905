import React, {Fragment} from "react";
import PropTypes from "prop-types";
import AccordionSecondLevel from "../../../accordionSezioneRichiesta/AccordionSecondLevel";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import Utils from "../../../../utils/Utils";
import AuthUtils from "../../../../utils/AuthUtils";
import {FieldsGroup, FieldsRow, RoleBasedInput, RoleBasedText, SectionList} from "../../../roleBasedComponents/RoleBasedComponents";
import {v4 as uuid} from "uuid";
import ProposteAttivitaSuggerimentiSection, {
    defaultProposteAttivitaSuggerimenti
} from "../../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {SessioneUtente} from "web-client-archetype";
import {RoleBasedContext} from "../../../../utils/RoleBasedContext";
import {ProposteAttivitaSuggerimentiAssistenteSociale} from "./ProposteAttivitaSuggerimentiAssistenteSociale"
import enumRichiesta from "../../../../enum/enumRichiesta"
import {deletePropostaAttivitaEsuggGlobal, proposteGlobaleList, proposteGlobaleList$, setPropostaAttivitaEsuggGlobal} from "../../../../hook/useProposteAttivitaEsuggerimenti";
import {debounceTime} from "rxjs/operators";
import {mostraSpinner, nascondiSpinner} from "../../../../App";

const propTypes = {
    datiProposteAttivitaSuggerimenti: PropTypes.any,
    datiProposteAttivitaSuggerimentiNote: PropTypes.any,
    datiSituazioneSociale: PropTypes.any,
    datiDeterminantiDiSalute: PropTypes.any,
    datiAnamnesiFisiologica: PropTypes.any,
    datiInformazioniBase: PropTypes.any,
    datiInformazioniBaseFaseRichiesta: PropTypes.any,
    datiInformazioniBaseFaseValutazione: PropTypes.any,
    datiBenessereAbitativo: PropTypes.any,
    datiEsameObiettivo: PropTypes.any,
    datiAnamnesiPatologicaRemota: PropTypes.any,
    datiAnamnesiFamiliare: PropTypes.any,
    onChangeDatiProposteAttivitaSuggerimenti: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.any,
    field: PropTypes.any,
    datiproposteAttivitaSuggerimentiAssistenteSociale: PropTypes.node,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultProposta = {
    id: null,
    propostaAttivitaSuggerimenti: null,
    propostaAssistenteSociale: null,
    specificareSeAltro: null,
    note: null
};

const LABEL_ACCORDION_SITUAZIONE_SOCIALE = "SITUAZIONE SOCIALE";
const LABEL_ACCORDION_DETERMINANTI_DI_SALUTE = "DETERMINANTI DI SALUTE";
const LABEL_ACCORDION_ANAMNESI_FISIOLOGICA = "ANAMNESI FISIOLOGICA (PROFILO DELL'AUTONOMIA, COMUNICAZIONE, SENSORIO)";
const LABEL_ACCORDION_INFORMAZIONI_BASE = "INFORMAZIONI DI BASE: TRATTAMENTI IN ATTO E FORNITURE SANITARIE ATTIVE DEL PERIODO";
const LABEL_ACCORDION_INFORMAZIONI_BASE_FASE_RICHIESTA_OR_VALUTAZIONE = "INFORMAZIONI DI BASE";
const LABEL_ACCORDION_BENESSERE_ABITATIVO = "BENESSERE ABITATIVO";
const LABEL_ACCORDION_ESAME_OBIETTIVO = "ESAME OBIETTIVO";
const LABEL_ACCORDION_ANAMNESI_PATOLOGICA_REMOTA = "ANAMNESI PATOLOGICA REMOTA";
const LABEL_ACCORDION_ANAMNESI_FAMILIARE = "ANAMNESI FAMILIARE";

export default class ProposteAttivitaSuggerimentiSottoAccordion extends AccordionSecondLevel {
    state = {
        currentProposteAttivitaSuggerimenti: _.cloneDeep(this.props.datiProposteAttivitaSuggerimenti),
        proposteAttivitaSuggerimentiNote: _.cloneDeep(this.props.datiProposteAttivitaSuggerimentiNote)
    }

    constructor(props) {
        super(props);
        // let listaProposteAttivitaSuggerimentiFromOtherAccordion = this.recuperoProposteAttivitaSuggerimentiAltriAccordion();
        // this.state = {
        //     currentProposteAttivitaSuggerimenti: this.inizializzaForm(listaProposteAttivitaSuggerimentiFromOtherAccordion),
        //     proposteAttivitaSuggerimentiNote: _.cloneDeep(this.props.datiProposteAttivitaSuggerimentiNote)
        // };



    }

    componentWillUnmount () {proposteGlobaleList.next([])}

    async componentDidMount() {

        mostraSpinner();
        setPropostaAttivitaEsuggGlobal(this.recuperoProposteAttivitaSuggerimentiAltriAccordion().map(pro => pro.propostaAttivitaSuggerimenti))
        await proposteGlobaleList$
            .pipe(debounceTime(200))
            .subscribe(globalProposte => {
                const oltreAccordion = this.recuperoProposteAttivitaSuggerimentiAltriAccordion().filter(proposte => {
                    if (this.props.datiProposteAttivitaSuggerimenti.find(a => a.propostaAttivitaSuggerimenti.id === proposte.propostaAttivitaSuggerimenti.id))
                        return null
                    else return proposte
                });

                const proposte = _.uniqBy([
                    ...this.props.datiProposteAttivitaSuggerimenti.filter(a => a.firstPageAccordionPath), 
                    ...oltreAccordion, 
                    ...this.props.datiProposteAttivitaSuggerimenti.filter(b => !b.firstPageAccordionPath)], 'id').map(pr => {
                    const globalPro = globalProposte.find(a => a.id === pr.propostaAttivitaSuggerimenti.id)

                    if (globalPro)
                        return {
                            ...pr,
                            propostaAttivitaSuggerimenti: {
                                ...pr.propostaAttivitaSuggerimenti,
                                ambito: globalPro.ambito,
                                altro: globalPro.altro,
                                cvp: globalPro.cvp,
                                farmaci: globalPro.farmaci,
                                nomenclatore: globalPro.nomenclatore
                            }
                        }
                    else if (!pr.firstPageAccordionPath) return pr
                    else return null
                }).filter(m => m)

                
             

                this.setState({currentProposteAttivitaSuggerimenti: [
                    ...proposte.filter(a => a.propostaAttivitaSuggerimenti.codiceFiscale !== SessioneUtente.getInstance().idUtente),
                    ...proposte.filter(b => b.propostaAttivitaSuggerimenti.codiceFiscale === SessioneUtente.getInstance().idUtente)
                ]},
                    () => {
                        this.validaSezione();
                        this.formatObjectForBackend();
                         nascondiSpinner();
                    });
            })
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        // Se prevProps.datiProposteAttivitaSuggerimenti sono diverse da this.props.datiProposteAttivitaSuggerimenti
        // allora aggiorno il form con le props correnti
        // (che contengono sia le proposte degli accordion di pagina 1 che le proposte dell'accordion di pagina 2 nel formato corretto)
        // in modo da evitare di richiamare nuovamente il metodo che formatta le proposte provenienti dagli accordion di pagina 1
        // if (!_.isEqual(prevProps.datiProposteAttivitaSuggerimenti, this.props.datiProposteAttivitaSuggerimenti)) {
        //     this.setState({currentProposteAttivitaSuggerimenti: this.inizializzaForm(this.props.datiProposteAttivitaSuggerimenti)},
        //         () => {
        //             this.validaSezione();
        //             this.formatObjectForBackend();
        //         });
        // }

        if (!_.isEqual(prevProps.datiproposteAttivitaSuggerimentiAssistenteSociale, this.props.datiproposteAttivitaSuggerimentiAssistenteSociale)) { this.validaSezione() }

    }

    recuperoProposteAttivitaSuggerimentiAltriAccordion = () => {
        // recupero la lista delle proposteAttivitaSuggerimenti selezionati nei precedenti accordion della pagina "Compila (1 di 3)"
        let proposteFromOtherAccordionList = [];
        const isKeyIncluded = (object) => JSON.stringify(object).toLowerCase().includes("propostaattivitasuggerimenti");

        const sottoaccordionConPossibiliProposteInAnamnesiFamiliare = Object.keys(this.props.datiAnamnesiFamiliare ?? []).filter(key => isKeyIncluded(this.props.datiAnamnesiFamiliare[key]));
        proposteFromOtherAccordionList.push(...this.extractProposteFromOtherAccordion(_.cloneDeep(this.props.datiAnamnesiFamiliare), sottoaccordionConPossibiliProposteInAnamnesiFamiliare, LABEL_ACCORDION_ANAMNESI_FAMILIARE, "datiAnamnesiFamiliare"));

        const sottoaccordionConPossibiliProposteInSituazioneSociale = Object.keys(this.props.datiSituazioneSociale).filter(key => isKeyIncluded(this.props.datiSituazioneSociale[key]));
        proposteFromOtherAccordionList.push(...this.extractProposteFromOtherAccordion(_.cloneDeep(this.props.datiSituazioneSociale), sottoaccordionConPossibiliProposteInSituazioneSociale, LABEL_ACCORDION_SITUAZIONE_SOCIALE, "situazioneSociale"));

        const sottoaccordionConPossibiliProposteInDeterminantiSalute = Object.keys(this.props.datiDeterminantiDiSalute).filter(key => isKeyIncluded(this.props.datiDeterminantiDiSalute[key]));
        proposteFromOtherAccordionList.push(...this.extractProposteFromOtherAccordion(_.cloneDeep(this.props.datiDeterminantiDiSalute), sottoaccordionConPossibiliProposteInDeterminantiSalute, LABEL_ACCORDION_DETERMINANTI_DI_SALUTE, "determinantiDiSalute"));

        const sottoaccordionConPossibiliProposteInAnamnesiFisiologica = Object.keys(this.props.datiAnamnesiFisiologica).filter(key => isKeyIncluded(this.props.datiAnamnesiFisiologica[key]));
        proposteFromOtherAccordionList.push(...this.extractProposteFromOtherAccordion(_.cloneDeep(this.props.datiAnamnesiFisiologica), sottoaccordionConPossibiliProposteInAnamnesiFisiologica, LABEL_ACCORDION_ANAMNESI_FISIOLOGICA, "anamnesiFisiologica"));

        const sottoaccordionConPossibiliProposteInInformazioniBase = Object.keys(this.props.datiInformazioniBase).filter(key => isKeyIncluded(this.props.datiInformazioniBase[key]));
        proposteFromOtherAccordionList.push(...this.extractProposteFromOtherAccordion(_.cloneDeep(this.props.datiInformazioniBase), sottoaccordionConPossibiliProposteInInformazioniBase, LABEL_ACCORDION_INFORMAZIONI_BASE, "informazioniDiBase"));

        const sottoaccordionConPossibiliProposteInAnamnesiPatologicaRemota = Object.keys(this.props.datiAnamnesiPatologicaRemota).filter(key => isKeyIncluded(this.props.datiAnamnesiPatologicaRemota[key]));
        proposteFromOtherAccordionList.push(...this.extractProposteFromOtherAccordion(_.cloneDeep(this.props.datiAnamnesiPatologicaRemota), sottoaccordionConPossibiliProposteInAnamnesiPatologicaRemota, LABEL_ACCORDION_ANAMNESI_PATOLOGICA_REMOTA, "anamnesiPatologicaRemota"));

        if (Utils.isStateRichiesta(this.props.pageState)) {
            const sottoaccordionConPossibiliProposteInInformazioniBaseFaseRichiesta = Object.keys(this.props.datiInformazioniBaseFaseRichiesta).filter(key => isKeyIncluded(this.props.datiInformazioniBaseFaseRichiesta[key]));
            proposteFromOtherAccordionList.push(...this.extractProposteFromOtherAccordion(_.cloneDeep(this.props.datiInformazioniBaseFaseRichiesta), sottoaccordionConPossibiliProposteInInformazioniBaseFaseRichiesta, LABEL_ACCORDION_INFORMAZIONI_BASE_FASE_RICHIESTA_OR_VALUTAZIONE, enumRichiesta.attributi.informazioniBaseFaseRichiesta));
        } else if (Utils.isStateValutazione(this.props.pageState)) {
            const sottoaccordionConPossibiliProposteInInformazioniBaseFaseValutazione = Object.keys(this.props.datiInformazioniBaseFaseValutazione).filter(key => isKeyIncluded(this.props.datiInformazioniBaseFaseValutazione[key]));
            proposteFromOtherAccordionList.push(...this.extractProposteFromOtherAccordion(_.cloneDeep(this.props.datiInformazioniBaseFaseValutazione), sottoaccordionConPossibiliProposteInInformazioniBaseFaseValutazione, LABEL_ACCORDION_INFORMAZIONI_BASE_FASE_RICHIESTA_OR_VALUTAZIONE, enumRichiesta.attributi.informazioniBaseFaseValutazione));

            const sottoaccordionConPossibiliProposteInBenessereAbitativo = Object.keys(this.props.datiBenessereAbitativo).filter(key => isKeyIncluded(this.props.datiBenessereAbitativo[key]));
            proposteFromOtherAccordionList.push(...this.extractProposteFromOtherAccordion(_.cloneDeep(this.props.datiBenessereAbitativo), sottoaccordionConPossibiliProposteInBenessereAbitativo, LABEL_ACCORDION_BENESSERE_ABITATIVO, "benessereAbitativo"));

            const sottoaccordionConPossibiliProposteInEsameObiettivo = Object.keys(this.props.datiEsameObiettivo).filter(key => isKeyIncluded(this.props.datiEsameObiettivo[key]));
            proposteFromOtherAccordionList.push(...this.extractProposteFromOtherAccordion(_.cloneDeep(this.props.datiEsameObiettivo), sottoaccordionConPossibiliProposteInEsameObiettivo, LABEL_ACCORDION_ESAME_OBIETTIVO, "esameObiettivo"));
        }

        return proposteFromOtherAccordionList;
    }

    extractProposteFromOtherAccordion = (accordion, fieldList, basePathAmbito, parentPath) => {
        let cloneObject = _.cloneDeep(accordion);
        cloneObject = _.pick(cloneObject, fieldList);

        let proposteAttivitaSuggerimenti = [];
        for (const field of Object.keys(cloneObject)) {
            let subObject = cloneObject[field];

            if (parentPath === "anamnesiPatologicaRemota") subObject = subObject["lista"];

            if (Array.isArray(subObject)) {
                subObject.forEach((item, i) => {
                    proposteAttivitaSuggerimenti.push(...this.getProposteOfOtherAccordion(item, i, parentPath, field, basePathAmbito));
                });
            } else {
                proposteAttivitaSuggerimenti.push(...this.getProposteOfOtherAccordion(subObject, null, parentPath, field, basePathAmbito));
            }
        }

        return proposteAttivitaSuggerimenti;
    }

    getProposteOfOtherAccordion = (object, index, parentPath, field, basePathAmbito) => {
        let listProposteOtherAccordion = [];
        let fieldPropostaAttivitaSuggerimenti = "propostaAttivitaSuggerimenti";

        Object.keys(object)
            .filter(key => key.toLowerCase().includes(fieldPropostaAttivitaSuggerimenti.toLowerCase()))
            .forEach(key => {
                let keyDaConsiderare = Object.keys(object).find(k => {
                    k = k.toLowerCase();
                    const daConsiderareCorrespondingToProposte = key.toLowerCase().replace(fieldPropostaAttivitaSuggerimenti.toLowerCase(), "");
                    const defaultCondition = k.includes("daconsiderare");
                    if (!Utils.isStringEmptyOrNullOrUndefined(daConsiderareCorrespondingToProposte)) return k.includes(daConsiderareCorrespondingToProposte) && defaultCondition;
                    else return defaultCondition;
                });
                let daConsiderare = keyDaConsiderare != null ? object[keyDaConsiderare] : null;

                let keyDaAttenzionare = Object.keys(object).find(k => {
                    k = k.toLowerCase();
                    const daAttenzionareCorrespondingToProposte = key.toLowerCase().replace(fieldPropostaAttivitaSuggerimenti.toLowerCase(), "");
                    const defaultCondition = k.includes("daattenzionare");
                    if (!Utils.isStringEmptyOrNullOrUndefined(daAttenzionareCorrespondingToProposte)) return k.includes(daAttenzionareCorrespondingToProposte) && defaultCondition;
                    else return defaultCondition;
                });
                let daAttenzionare = keyDaAttenzionare != null ? object[keyDaAttenzionare] : null;

                let propostaAttivitaSuggerimenti = object[key];
                
                if (!Utils.isObjectNull(propostaAttivitaSuggerimenti)) {
                    if(Array.isArray(propostaAttivitaSuggerimenti)){
                        for (let indexProposta = 0; indexProposta < propostaAttivitaSuggerimenti.length; indexProposta++) {
                            let proposta = propostaAttivitaSuggerimenti[indexProposta] ? this.getPropostaCloned(propostaAttivitaSuggerimenti[indexProposta], daConsiderare, daAttenzionare, index, parentPath, field, basePathAmbito, key, indexProposta) : null;
                            if(proposta){
                                listProposteOtherAccordion.push(proposta);
                            }                            
                        }                        
                    } else {
                        let proposta = this.getPropostaCloned(propostaAttivitaSuggerimenti, daConsiderare, daAttenzionare, index, parentPath, field, basePathAmbito, key, null);
                        if(proposta){
                            listProposteOtherAccordion.push(proposta);
                        }
                    }
                }
            });

        return listProposteOtherAccordion;
    }

    getPropostaCloned = (propostaAttivitaSuggerimenti, daConsiderare, daAttenzionare, index, parentPath, field, basePathAmbito, key, indexProposta) => {
        let nomenclatore = propostaAttivitaSuggerimenti.nomenclatore;
        let cvp = propostaAttivitaSuggerimenti.cvp;
        let farmaci = propostaAttivitaSuggerimenti.farmaci;
        let altro = propostaAttivitaSuggerimenti.altro;
        let ambitoCustom = propostaAttivitaSuggerimenti?.ambito?.includes(basePathAmbito + ' - ')
            ? propostaAttivitaSuggerimenti.ambito
            : basePathAmbito + ' - ' + propostaAttivitaSuggerimenti.ambito;
        const propostaObject = {
            ...defaultProposteAttivitaSuggerimenti,
            id: propostaAttivitaSuggerimenti.id,
            propostaEffettuataDa: propostaAttivitaSuggerimenti.propostaEffettuataDa,
            codiceFiscale: propostaAttivitaSuggerimenti.codiceFiscale,
            ambito: propostaAttivitaSuggerimenti.ambito
        };
        if (nomenclatore?.checked) propostaObject.nomenclatore = nomenclatore
        if (cvp?.checked) propostaObject.cvp = cvp
        if (farmaci?.checked) propostaObject.farmaci = farmaci
        if (altro?.checked) propostaObject.altro = altro

        if (propostaObject.nomenclatore?.valori?.length || propostaObject.cvp?.valori?.length
            || propostaObject.farmaci?.valori?.length || propostaObject.altro?.valore) {
            // per le proposte provenienti dalla pagina 1 viene generato sempre un nuovo uuid
            // perchè non vengono salvate nell'oggetto di questo accordion ma vengono sempre lette dalla pagina 1
            return {
                id: uuid(),
                daConsiderare: daConsiderare,
                daAttenzionare: daAttenzionare,
                ambitoCustom: ambitoCustom,
                propostaAttivitaSuggerimenti: propostaObject,
                firstPageAccordionPath: `${parentPath}.${field}${parentPath === "anamnesiPatologicaRemota" ? '.lista' : ''}${(index != null ? '[' + index + '].' : '.')}${key}`
            };
        }
    }

    inizializzaForm = (datiProposteAttivitaSuggerimenti) => {
        let proposteAttivitaSuggerimentiFromOtherAccordion = _.cloneDeep(datiProposteAttivitaSuggerimenti);
        let currentProposteAttivitaSuggerimenti = _.cloneDeep(this.props.datiProposteAttivitaSuggerimenti ?? []);

        if (proposteAttivitaSuggerimentiFromOtherAccordion.length > 0) {
            currentProposteAttivitaSuggerimenti.unshift(...proposteAttivitaSuggerimentiFromOtherAccordion);
            currentProposteAttivitaSuggerimenti = _.uniqBy(currentProposteAttivitaSuggerimenti, 'id');
        }

        return currentProposteAttivitaSuggerimenti;
    }

    formatObjectForBackend = (pathOfPropostaRemoved = null) => {
        let proposteAttivitaSuggerimenti = _.cloneDeep(this.state.currentProposteAttivitaSuggerimenti);
        this.props.onChangeDatiProposteAttivitaSuggerimenti(
            "proposteAttivitaSuggerimenti|pathOfPropostaRemoved",
            [proposteAttivitaSuggerimenti, pathOfPropostaRemoved]
        );
    }

    contaNumeroCampiObbligatoriDaValorizzare = () => {
        let proposteAttivitaSuggerimenti = _.cloneDeep(this.state.currentProposteAttivitaSuggerimenti);
        let numeroCampiObbligatoriDaValorizzare = 0;

        if (!Utils.isStateRichiesta(this.props.pageState) && proposteAttivitaSuggerimenti.length === 0)
            return numeroCampiObbligatoriDaValorizzare;

        if (proposteAttivitaSuggerimenti != null && proposteAttivitaSuggerimenti.length > 0) {
            numeroCampiObbligatoriDaValorizzare = proposteAttivitaSuggerimenti.filter(el => {
                let propostaAttivita = el.propostaAttivitaSuggerimenti;
                let esito;
                if (!Utils.isObjectNull(propostaAttivita)) {
                    delete propostaAttivita.required;
                    delete propostaAttivita.propostaEffettuataDa;
                    delete propostaAttivita.codiceFiscale;
                    delete propostaAttivita.ambito;
                    const isInvalidList = (list) => !list.length
                        || list.some(e => {
                            delete e.id;
                            return Object.prototype.hasOwnProperty.call(e, "valore") ? Utils.isStringEmptyOrNullOrUndefined(e.valore) : Utils.isObjectNull(e);
                        });
                    esito = Object.values(propostaAttivita).every(v => !v.checked
                        || ((Object.prototype.hasOwnProperty.call(v, "valori") ? isInvalidList(v.valori) : true) && (Object.prototype.hasOwnProperty.call(v, "valore") ? Utils.isStringEmptyOrNullOrUndefined(v.valore) : true)));
                } else {
                    esito = true;
                }
                if (AuthUtils.hasUtenteRuoloAssistenteSociale())
                    esito = Utils.isStringEmptyOrNullOrUndefined(el.propostaAssistenteSociale);

                return esito;
            }).length;
        }

        return numeroCampiObbligatoriDaValorizzare;
    }

    validaSezione = () => {
        let numeroCampiObbligatoriMancanti = 0;
        let proposteAttivitaSuggerimentiAssSociale = _.cloneDeep(this.props.datiproposteAttivitaSuggerimentiAssistenteSociale);
        const isMMGValutatore = Utils.isStateValutazione(this.props.pageState) && this.props.richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente;

        if ((Utils.isStateRichiesta(this.props.pageState)) || (isMMGValutatore)) numeroCampiObbligatoriMancanti = this.contaNumeroCampiObbligatoriDaValorizzare();
        if (Utils.isStateValutazione(this.props.pageState) && AuthUtils.hasUtenteRuoloAssistenteSociale() && !this.context.forceReadOnly && !proposteAttivitaSuggerimentiAssSociale.propostaAssistenteSociale) 
            numeroCampiObbligatoriMancanti++

        this.props.onChangeRequiredFieldsNumber(numeroCampiObbligatoriMancanti);
    }

    handleAggiornaForm = (field, elem, index) => {
        let currentProposteAttivitaSuggerimenti = _.cloneDeep(this.state.currentProposteAttivitaSuggerimenti);
        let proposta = currentProposteAttivitaSuggerimenti[index];

        proposta[field] = elem;
        currentProposteAttivitaSuggerimenti[index] = proposta;

        this.setState({currentProposteAttivitaSuggerimenti}, () => {
            this.validaSezione();
            this.formatObjectForBackend(proposta?.firstPageAccordionPath ?? null);
        })
    }

    handleAggiornaFormNote = (field, elem) => {
        let proposteAttivitaSuggerimentiNote = _.cloneDeep(this.state.proposteAttivitaSuggerimentiNote);
        proposteAttivitaSuggerimentiNote[field] = elem;
        this.setState({proposteAttivitaSuggerimentiNote},
            () => this.props.onChangeDatiProposteAttivitaSuggerimenti("proposteAttivitaSuggerimentiNote", this.state.proposteAttivitaSuggerimentiNote));
    }

    renderPropostaAttivitaSuggerimento = (proposta, index, readOnly) => {
        if (Object.prototype.hasOwnProperty.call(proposta, "firstPageAccordionPath")) {
            return this.renderPropostaAltroAccordion(proposta, index, readOnly);
        } else {
            return this.renderNuovaProposta(proposta, index, readOnly);
        }
    }

    renderPropostaAltroAccordion = (proposta, index, readOnly) => {
        let titoloCampoPropostaAttivitaSuggerimenti;
        if (proposta.daConsiderare === "01")
            titoloCampoPropostaAttivitaSuggerimenti = "Proposta di attività da considerare per PAI";
        else if (proposta.daConsiderare === "02")
            titoloCampoPropostaAttivitaSuggerimenti = "Proposta di attività da considerare per monitoraggio";
        else if (proposta.daAttenzionare === "01")
            titoloCampoPropostaAttivitaSuggerimenti = "Proposta di attività da attenzionare per PAI";
        else if (proposta.daAttenzionare === "02")
            titoloCampoPropostaAttivitaSuggerimenti = "Proposta di attività da attenzionare per monitoraggio";
        else titoloCampoPropostaAttivitaSuggerimenti = "Proposte di attività e suggerimenti";

        return (
            <Fragment key={index}>

                <RoleBasedText
                    pageState={this.props.pageState}
                    value={proposta.propostaAttivitaSuggerimenti.propostaEffettuataDa}
                    field={"propostaEffettuataDa"}
                    id={"propostaEffettuataDa"}
                    fieldLabel={'Proposta effettuata da'}
                    showFieldIfRequiredAccordionPage={true}
                    fieldId={"X_PROP_ATT_SUGG.PROPOSTEATTIVITAESUGGERIMENTI.EFFETTUATADA"}
                />
                <RoleBasedText
                    pageState={this.props.pageState}
                    value={proposta.ambitoCustom || proposta.propostaAttivitaSuggerimenti.ambito}
                    field={"ambito"}
                    id={"ambito"}
                    fieldLabel={'Ambito'}
                    showFieldIfRequiredAccordionPage={true}
                    fieldId={"X_PROP_ATT_SUGG.PROPOSTEATTIVITAESUGGERIMENTI.AMBITO"}
                />

                <FieldsRow colNumber={1}>
                    <ProposteAttivitaSuggerimentiSection
                        title={titoloCampoPropostaAttivitaSuggerimenti}
                        required={true}
                        id={"proposteAttivitaSuggerimenti"}
                        ambito={''}
                        fieldsPrefix={"X"}
                        parentFieldId={"PROPOSTEATTIVITAESUGGERIMENTI.RICHIESTACAMBIOSETTING"}
                        checkboxIdSuffix={'_' + index}
                        value={proposta.propostaAttivitaSuggerimenti}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem, index)}
                        pageState={this.props.pageState}
                        forceReadOnly={readOnly}/>
                </FieldsRow>
            </Fragment>
        );
    }

    renderNuovaProposta = (proposta, index, readOnly) => {
        return <Fragment key={index}>
            <RoleBasedText
                pageState={this.props.pageState}
                value={proposta?.propostaAttivitaSuggerimenti?.propostaEffettuataDa || proposta?.propostaEffettuataDa}
                field={"propostaEffettuataDa"}
                id={"propostaEffettuataDa"}
                fieldLabel={'Proposta effettuata da'}
                showFieldIfRequiredAccordionPage={true}
                fieldId={"X_PROP_ATT_SUGG.PROPOSTEATTIVITAESUGGERIMENTI.EFFETTUATADA"}
            />
            <FieldsRow colNumber={1}>
                <ProposteAttivitaSuggerimentiSection
                    required={true}
                    id={"proposteAttivitaSuggerimenti"}
                    ambito={''}
                    fieldsPrefix={"X"}
                    fieldId={'X_PROP_ATT_SUGG.PROPOSTEATTIVITAESUGGERIMENTI.RICHIESTACAMBIOSETTING'}
                    parentFieldId={'PROPOSTEATTIVITAESUGGERIMENTI.RICHIESTACAMBIOSETTING'}
                    checkboxIdSuffix={'_' + index}
                    value={proposta.propostaAttivitaSuggerimenti || proposta}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem, index)}
                    pageState={this.props.pageState}
                    forceReadOnly={readOnly}/>
            </FieldsRow>
        </Fragment>
    }

    addNewPropostaAttivitaSuggerimento = () => {
        let currentProposteAttivitaSuggerimenti = _.cloneDeep(this.state.currentProposteAttivitaSuggerimenti);
        currentProposteAttivitaSuggerimenti.push({
            ..._.cloneDeep(defaultProposteAttivitaSuggerimenti),
            id: uuid(),
            propostaEffettuataDa: AuthUtils.getInfoUtente(),
            codiceFiscale: SessioneUtente.getInstance().idUtente
        });

        this.setState({currentProposteAttivitaSuggerimenti}, () => this.validaSezione());
    }

    removePropostaAttivitaSuggerimento = (index) => {
        let currentProposteAttivitaSuggerimenti = _.cloneDeep(this.state.currentProposteAttivitaSuggerimenti);
        const pathOfPropostaRemoved = currentProposteAttivitaSuggerimenti[index]?.firstPageAccordionPath ?? null;
        deletePropostaAttivitaEsuggGlobal(currentProposteAttivitaSuggerimenti[index]?.propostaAttivitaSuggerimenti)
        currentProposteAttivitaSuggerimenti.splice(index, 1);

        this.setState({currentProposteAttivitaSuggerimenti}, () => {
            this.validaSezione();
            this.formatObjectForBackend(pathOfPropostaRemoved);
        });
    }

    renderBodySezione = () => {
        let currentProposteAttivitaSuggerimenti = _.cloneDeep(this.state.currentProposteAttivitaSuggerimenti);
        const proposteIsReadOnly = this.context.forceReadOnly || currentProposteAttivitaSuggerimenti.map(p => Utils.isStateValutazione(this.props.pageState) && p.propostaAttivitaSuggerimenti?.codiceFiscale !== SessioneUtente.getInstance().idUtente);
        const {datiproposteAttivitaSuggerimentiAssistenteSociale, pageState, onChangeDatiProposteAttivitaSuggerimenti, richiesta} = this.props; 
        const isMMGValutatore = Utils.isStateValutazione(this.props.pageState) && richiesta?.richiedente?.codiceFiscale === SessioneUtente.getInstance().idUtente;
        return (
            <>
                <SectionList
                    title={"Proposta di attività"}
                    data={currentProposteAttivitaSuggerimenti}
                    renderSection={this.renderPropostaAttivitaSuggerimento}
                    addNewSectionCallback={this.addNewPropostaAttivitaSuggerimento}
                    removeSectionCallback={this.removePropostaAttivitaSuggerimento}
                    forceReadOnly={proposteIsReadOnly}
                    addButtonVisibilityHandler={!this.context.forceReadOnly && this.contaNumeroCampiObbligatoriDaValorizzare() === 0 && !AuthUtils.hasUtenteRuoloAssistenteSociale()}
                    addButtonLabel={"Aggiungi proposte di attività e suggerimenti"}
                    pageState={this.props.pageState}
                    field={'currentProposteAttivitaSuggerimenti'}
                    required
                    atLeastOne={((Utils.isStateRichiesta(this.props.pageState)) || (isMMGValutatore))
                        && (proposteIsReadOnly == null || !proposteIsReadOnly.length) && !currentProposteAttivitaSuggerimenti.length}
                />
                <ProposteAttivitaSuggerimentiAssistenteSociale
                    datiproposteAttivitaSuggerimentiAssistenteSociale={datiproposteAttivitaSuggerimentiAssistenteSociale}
                    onChangeProposteAttivitaSuggerimentiAssistenteSociale={onChangeDatiProposteAttivitaSuggerimenti}
                    validaSezione={() => this.validaSezione()}
                    readOnly={proposteIsReadOnly}
                    pageState={pageState}
                />
                <FieldsRow colNumber={2}>
                    <RoleBasedInput
                        fieldId={'X_NOTE.PROPOSTEATTIVITAESUGGERIMENTI.RICHIESTACAMBIOSETTING'}
                        pageState={this.props.pageState}
                        value={this.state.proposteAttivitaSuggerimentiNote?.note}
                        placeholder={"Inserisci note"}
                        field={"note"}
                        onChange={(field, elem) => this.handleAggiornaFormNote(field, elem.target.value)}
                        id={"note"}
                        type={"textarea"}
                        maxlength={"400"}
                        rows={"8"}
                        fieldLabel={'Note'}
                        showAvailableCharsLabel={true}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"proposteAttivitaSuggerimentiSub"}
                title={"Proposte di attività e suggerimenti"}
                required={true}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["proposteAttivitaSuggerimenti"],
                    [defaultProposteAttivitaSuggerimenti]
                )}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }
}

ProposteAttivitaSuggerimentiSottoAccordion.propTypes = propTypes;
ProposteAttivitaSuggerimentiSottoAccordion.contextType = RoleBasedContext
