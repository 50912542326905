import React, { Component, Fragment } from "react";
import Table from "../../generalComponents/table/Table";
import {Icon, SessioneUtente} from "web-client-archetype";
import * as _ from 'lodash';
import PropTypes from "prop-types";
import enumsUtente from "../../../enum/enumsUtente.json";
import azioniRichiesta from "../../../utils/dataset/richiestaData/azioniRichiesta.json";
import Utils from "../../../utils/Utils";
import enumRichiesta from "../../../enum/enumRichiesta";
import enumStep from "../../../enum/enumStep";
import { Link } from "react-router-dom";
import { FieldsRow } from "../../roleBasedComponents/RoleBasedComponents";
import PopupConfermaSostituizioneMedicoRichiedente from "../popupConfermaSostituizioneMedicoRichiedente/PopupConfermaSostituizioneMedicoRichiedente";
import RichiestaADIService from "../../../service/RichiestaADIService";
import styles from "./TabellaStatoValutazione.module.css";
import PopoverReact from "../../popover/Popover";
import * as BsIcon from "react-bootstrap-icons";


const propTypes = {
    richiesta: PropTypes.object,
    updateRichiesta: PropTypes.func
}

export const statoRichiestaValitoPerSostituzioneMedicoR =
    [
        enumRichiesta.stato.INVIATA,
        enumRichiesta.stato.IN_CONFERIMENTO,
        enumRichiesta.stato.IN_VALUTAZIONE,
        enumRichiesta.stato.VALUTATA
    ];

const RICHIESTA_CLASSICA_ROWS = [
    {
        faseProcessoAttivazione: { label: "1. Richiesta di attivazione" },
        professionista: [],
        statoAvanzamento: [],
        dataCompletamento: [],
        statoRichiesta: [],
        key: "richiestaDiAttivazione"
    },
    {
        faseProcessoAttivazione: { label: "2. Valutazione multidimensionale" },
        professionista: [],
        statoAvanzamento: [],
        dataCompletamento: [],
        statoRichiesta: [],
        key: "valutazioneMultidimensionale"
    },
    {
        faseProcessoAttivazione: { label: "3. Formalizzazione" },
        professionista: [],
        statoAvanzamento: [],
        dataCompletamento: [],
        statoRichiesta: [],
        key: "formalizzazione"
    }
];
const RICHIESTA_SEGNALAZIONE_ROWS = [
    {
        faseProcessoAttivazione: { label: "1. Attivazione pre-dialogo" },
        professionista: [],
        statoAvanzamento: [],
        dataCompletamento: [],
        statoRichiesta: [],
        key: "attivazionePreDialogo"
    },
    {
        faseProcessoAttivazione: { label: "2. Valutazione professionista territoriale" },
        professionista: [],
        statoAvanzamento: [],
        dataCompletamento: [],
        statoRichiesta: [],
        key: "valutazioneProfessionistaTerritoriale"
    },
    {
        faseProcessoAttivazione: { label: "3. Richiesta di attivazione" },
        professionista: [],
        statoAvanzamento: [],
        dataCompletamento: [],
        statoRichiesta: [],
        key: "richiestaDiAttivazione"
    },
    {
        faseProcessoAttivazione: { label: "4. Valutazione multidimensionale" },
        professionista: [],
        statoAvanzamento: [],
        dataCompletamento: [],
        statoRichiesta: [],
        key: "valutazioneMultidimensionale"
    },
    {
        faseProcessoAttivazione: { label: "5. Formalizzazione" },
        professionista: [],
        statoAvanzamento: [],
        dataCompletamento: [],
        statoRichiesta: [],
        key: "formalizzazione"
    }
];

export default class TabellaStatoValutazione extends Component {

    state = {
        totalElements: 0,
        loading: false,
        size: 10,
        sort: "",
        direction: "",
        cols: [
            { key: "faseProcessoAttivazione", name: "Fase del processo di attivazione", sortDirection: null, tipo: "labelConLink" },
            { key: "professionista", name: "Professionista", tipo: "array", sortDirection: null },
            { key: "statoAvanzamento", name: "Stato avanzamento", tipo: "arrayConIcona", sortDirection: null },
            { key: "dataCompletamento", name: "Data completamento", tipo: "array", sortDirection: null },
            { key: "statoRichiesta", name: null, tipo: "array", sortDirection: null }
        ],
        rows: [],
        popupConfermaSostituizioneMedicoRichiedente: false,
    };

    componentDidMount() {
        this.getRows();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.richiesta, prevProps.richiesta)) {
            this.getRows();
        }
    }

    isProfessionistaEqualsToLoggedUser = (codiceFiscaleValutatore) => {
        return SessioneUtente.getInstance() && codiceFiscaleValutatore === SessioneUtente.getInstance().idUtente;
    }

    /**
     * fa vedere la popup de conferma per la sostituzione del medico richiedente da parte del Medico formalizzatore
    */
    openPopupConfermaSostituizioneMedicoRichiedente = () => this.setState({ popupConfermaSostituizioneMedicoRichiedente: true });

    /**
     * vengono fatti dei controlli per fare vedere oppure no il link Sostituzione Medico Richiedente
     * sostituzioneRichiedente deve essere false;
     * stato della reichiesta deve essere INVIATA||IN_CONFERIMENTO||IN_VALUTAZIONE||VALUTATA; 
     * l'utente loggato deve essere medicoFormalizzatore della richiesta
     * @returns true / false
    */
    showLnkSostituzioneMedicoRichiedente = () => {
        const { sostituzioneRichiedente, stato, team } = this.props.richiesta ? this.props.richiesta : {};
        const verificaUtenteAdiForIsLogged = team?.find(el => el.profilo === enumsUtente.profiliUtente.centraleAdi || el.profilo === enumsUtente.profiliUtente.medicoFormalizzatore)?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente;
        console.log("codiceFiscale---->", team?.find(el => el.profilo === enumsUtente.profiliUtente.medicoFormalizzatore)?.professionista?.codiceFiscale);
        console.log("sostituzioneRichiedente---->", sostituzioneRichiedente);
        console.log("verificaUtenteAdiForIsLogged---->", verificaUtenteAdiForIsLogged);
        return !!(!sostituzioneRichiedente && statoRichiestaValitoPerSostituzioneMedicoR.includes(stato) && verificaUtenteAdiForIsLogged);
    }

    /**
     * viene rilavorata la lista delle valutazioni:
     * aggiunto il medico richiedente nella lista delle valutazioni anche quando non e ancora confermata. 
     * aggiunto il MCP nella lista delle valutazioni anche quando non e ancora confermata. 
     * @returns lista delle valutazioni aggiornata
    */
    getValutazioni = (valutazioni) => {
        const { sostituzioneRichiedente, richiedente, team } = this.props.richiesta ? this.props.richiesta : {};
        const buildValutazione = _.cloneDeep(valutazioni);
        const medicoFormalizzatore = _.find(team, t => t.profilo === enumsUtente.profiliUtente.centraleAdi || t.profilo === enumsUtente.profiliUtente.medicoFormalizzatore)?.professionista;
        if(!sostituzioneRichiedente) {
            const condPerVisualizzareMedicoRichiedente = buildValutazione && !(buildValutazione.find(el => el.valutatore?.codiceFiscale === richiedente?.codiceFiscale));
            if(condPerVisualizzareMedicoRichiedente) buildValutazione.push( {stato:"", valutatore: richiedente} )
        }else {
            const condPerVisualizzareMCP = buildValutazione && !(buildValutazione.find(el => el.valutatore?.codiceFiscale === medicoFormalizzatore?.codiceFiscale));
            if(condPerVisualizzareMCP) buildValutazione.push( {stato:"", valutatore: medicoFormalizzatore} )
        }
               
        return buildValutazione || [];
    }

    getRows = () => {
        let righe = _.cloneDeep(this.state.rows);

        const medicoFormalizzatore = _.find(this.props.richiesta?.team, t => t.profilo === enumsUtente.profiliUtente.centraleAdi || t.profilo === enumsUtente.profiliUtente.medicoFormalizzatore)?.professionista;

        // inizializza righe
        if (Utils.isClassica(this.props.richiesta?.tipologia)) {
            righe = RICHIESTA_CLASSICA_ROWS;
            righe = righe.map(riga => {
                this.modificaRigaPerRichiestaClassica(riga, medicoFormalizzatore);
                return riga;
            })
        } else {
            righe = RICHIESTA_SEGNALAZIONE_ROWS;
            righe = righe.map(riga => {
                this.modificaRigaPerRichiestaSegnalazione(riga, medicoFormalizzatore);
                return riga;
            })
        }
        this.setState({ rows: righe });
    }

    modificaRigaPerRichiestaClassica(riga, medicoFormalizzatore) {
        switch (riga.key) {
            case "richiestaDiAttivazione": {
                riga.professionista = this.getProfessionistiRichiestaAttivazione(this.props.richiesta?.richiedente);
                riga.statoAvanzamento = [{sName: "Richiesta inoltrata al distretto", cssClass: "stickerGreen"}];
                riga.dataCompletamento = [Utils.transformTimeStampForFrontend(this.props.richiesta?.timestampCompletamento) != null ? Utils.transformTimeStampForFrontend(this.props.richiesta?.timestampCompletamento) : "-"];
                riga.statoRichiesta = [this.getLinkStatoRichiesta(azioniRichiesta.visualizzaRichiestaAttivazione.label, this.props.richiesta?.richiedente?.codiceFiscale, enumStep.cda2.STEP_CDA2_INOLTRO)];
            }
                break;
            case "valutazioneMultidimensionale":
                riga.faseProcessoAttivazione = {
                    label: "2. Valutazione multidimensionale",
                    link: this.showLnkSostituzioneMedicoRichiedente() ? {
                        label: "Sostituisci Medico richiedente",
                        onClick: () => this.openPopupConfermaSostituizioneMedicoRichiedente
                    } : null
                }
                riga.professionista = this.getProfessionistiValutazioneMultidimensionale(this.getValutazioni(this.props.richiesta?.valutazioni));
                riga.statoAvanzamento = this.getStatoAvanzamento(this.getValutazioni(this.props.richiesta?.valutazioni), this.props.richiesta?.richiedente, medicoFormalizzatore);
                riga.dataCompletamento = this.getDataCompletamento(this.getValutazioni(this.props.richiesta?.valutazioni));
                riga.statoRichiesta = this.getLinkLabel(this.getValutazioni(this.props.richiesta?.valutazioni), this.props.richiesta?.richiedente, medicoFormalizzatore);
                break;
            case "formalizzazione": {
                let teamProfessionista = this.getTeamProfessionistaFormalizzatore();
                riga.professionista = this.getProfessionistiFormalizzazione(teamProfessionista, this.props.richiesta?.valutazioni);
                riga.statoAvanzamento = [{sName: "Formalizzazione", cssClass: "stickerGreenOp4"}];
                riga.dataCompletamento = ["-"];
                riga.statoRichiesta = ["In attesa di formalizzazione"];
            }
                break;
            default:
                riga.professionista = [];
                riga.statoAvanzamento = [];
                riga.dataCompletamento = [];
                riga.statoRichiesta = "-";
                break;
        }
    }

    getTeamProfessionistaFormalizzatore() {
        let teamProfessionista = _.find(this.props.richiesta?.team, t => {
            let formalizzatoreMCP = t.profilo === enumsUtente.profiliUtente.medicoFormalizzatore && t?.professionista?.codiceFiscale != null;
            let formalizzatoreCentraleADI = t.profilo === enumsUtente.profiliUtente.centraleAdi && this.props.richiesta?.delega?.some(d => d?.profilo === enumsUtente.profiliUtente.centraleAdi && d.valore);
            return formalizzatoreMCP || formalizzatoreCentraleADI;
        });
        if (this.props.richiesta?.delega?.some(d => d?.ruolo === enumsUtente.ruoli.fisiatra && d.valore)) {
            if (this.props.richiesta?.formalizzazione?.codiceFiscaleFormalizzatore != null) {
                teamProfessionista = {
                    professionista: {
                        nome: this.props.richiesta?.formalizzazione?.nomeFormalizzatore,
                        cognome: this.props.richiesta?.formalizzazione?.cognomeFormalizzatore
                    }
                }
            } else {
                let valutatoriFisiatri = _.filter(this.props.richiesta.valutazioni, v => v?.valutatore?.ruolo === enumsUtente.ruoli.fisiatra)?.map(v => v.valutatore);
                if (valutatoriFisiatri?.length === 1) {
                    teamProfessionista = {professionista: valutatoriFisiatri[0]}
                } else if (valutatoriFisiatri?.length > 1) {
                    teamProfessionista = {professionista: {nome: "Fisiatri valutatori"}}
                }
            }
        }
        return teamProfessionista;
    }

    modificaRigaPerRichiestaSegnalazione(riga, medicoFormalizzatore) {
        let isFasePreDialogo = Utils.isFasePreDialogo(this.props.richiesta?.tipologia, this.props.richiesta?.stato);
        let teamFormalizzatore = _.find(this.props.richiesta?.team, t => t.profilo === enumsUtente.profiliUtente.medicoFormalizzatore);
        // todo verificare azioni predialogo
        switch (riga.key) {
            case "attivazionePreDialogo": {
                riga.professionista = this.getProfessionistiRichiestaAttivazione(this.props.richiesta?.professionistaCreazione);
                riga.statoAvanzamento = [{sName: "Richiesta inoltrata al territorio", cssClass: "stickerGreen"}];
                riga.dataCompletamento = [Utils.transformTimeStampForFrontend(this.props.richiesta?.timestampCompletamentoPreDialogo) != null ? Utils.transformTimeStampForFrontend(this.props.richiesta?.timestampCompletamentoPreDialogo) : "-"];
                // todo step per download pdf cda2 attivazione predialogo
                riga.statoRichiesta = [this.getLinkStatoRichiesta(azioniRichiesta?.visualizzaValutazionePreDialogo?.label, this.props.richiesta?.richiedente?.codiceFiscale, enumStep.cda2.STEP_CDA2_INOLTRO)];
            }
                break;
            case "valutazioneProfessionistaTerritoriale": {
                let valutazioniDaConsiderare = this.props.richiesta?.valutazioniPreDialogo?.length ? this.props.richiesta?.valutazioniPreDialogo : this.props.richiesta?.valutazioni;
                riga.professionista = this.getProfessionistiValutazioneMultidimensionale(this.getValutazioni(valutazioniDaConsiderare));
                riga.statoAvanzamento = this.getStatoAvanzamento(this.getValutazioni(valutazioniDaConsiderare), this.props.richiesta?.richiedente, medicoFormalizzatore, enumRichiesta.tipologia.SEGNALAZIONE);
                riga.dataCompletamento = this.getDataCompletamento(this.getValutazioni(valutazioniDaConsiderare));
                // todo step per download pdf cda2 valutazione predialogo
                riga.statoRichiesta = this.getLinkLabel(this.getValutazioni(valutazioniDaConsiderare), this.props.richiesta?.richiedente, medicoFormalizzatore);
            }
                break;
            case "richiestaDiAttivazione": {
                let attivatore = isFasePreDialogo ?
                    teamFormalizzatore?.professionista :
                    this.props.richiesta?.richiedente;
                riga.professionista = this.getProfessionistiRichiestaAttivazione(attivatore);
                riga.statoAvanzamento = [{sName: "Richiesta inoltrata al distretto", cssClass: isFasePreDialogo ? "stickerGreenOp4" : "stickerGreen"}];
                riga.dataCompletamento = [Utils.transformTimeStampForFrontend(this.props.richiesta?.timestampChiusuraPreDialogo) != null ? Utils.transformTimeStampForFrontend(this.props.richiesta?.timestampChiusuraPreDialogo) : "-"];
                riga.statoRichiesta = [this.getLinkStatoRichiesta(azioniRichiesta.visualizzaRichiestaAttivazione.label, this.props.richiesta?.richiedente?.codiceFiscale, enumStep.cda2.STEP_CDA2_INOLTRO)];
            }
                break;
            case "valutazioneMultidimensionale":
                riga.faseProcessoAttivazione = {
                    label: "2. Valutazione multidimensionale",
                    link: this.showLnkSostituzioneMedicoRichiedente() ? {
                        label: "Sostituisci Medico richiedente",
                        onClick: () => this.openPopupConfermaSostituizioneMedicoRichiedente
                    } : null
                }
                riga.professionista = this.getProfessionistiValutazioneMultidimensionale(this.getValutazioni(this.props.richiesta?.valutazioni));
                riga.statoAvanzamento = this.getStatoAvanzamento(this.getValutazioni(this.props.richiesta?.valutazioni), this.props.richiesta?.richiedente, medicoFormalizzatore);
                riga.dataCompletamento = this.getDataCompletamento(this.getValutazioni(this.props.richiesta?.valutazioni));
                riga.statoRichiesta = this.getLinkLabel(this.getValutazioni(this.props.richiesta?.valutazioni), this.props.richiesta?.richiedente, medicoFormalizzatore);
                break;
            case "formalizzazione":
                riga.professionista = !isFasePreDialogo ? this.getProfessionistiFormalizzazione(teamFormalizzatore, this.props.richiesta?.valutazioni) : ["-"];
                riga.statoAvanzamento = [{sName: "Formalizzazione", cssClass: "stickerGreenOp4"}];
                riga.dataCompletamento = ["-"];
                riga.statoRichiesta = ["In attesa di formalizzazione"];
                break;
            default:
                riga.professionista = [];
                riga.statoAvanzamento = [];
                riga.dataCompletamento = [];
                riga.statoRichiesta = "-";
                break;
        }
    }

    getProfessionistiRichiestaAttivazione = (professionista, listaProfessionisti = []) => {
        this.pushProfessionista(professionista, listaProfessionisti);
        return listaProfessionisti;
    }

    pushProfessionista(professionista, listaProfessionisti, storicoProfessionisti = []) {
        let professionistiSostituitiJSX = storicoProfessionisti?.length ? <Fragment>
            <PopoverReact
                body={_.map(storicoProfessionisti, p => {
                    let jsx = <div className={"row"}>
                        <div className={"col text-capitalize"}>
                            {_.join(["Professionista: " + p.professionista.nome, p.professionista.cognome, "| Data sostituzione: " + Utils.transformDateAndTimeForFrontend(p.timestampSostituzione)], " ")}
                        </div>
                    </div>
                    return jsx;
                })}
                text={<Fragment>
                    <Icon id="it-exchange-circle" className={["icon-sm", "left"]}/>
                </Fragment>}
                openOnMouseOver
                position={'right'}
                className={'popover-info'}
                arrowContainerClassName={styles.popoverStoricoProfessionista}
                arrowStyle={{}}
                id={`popoverProfessionistaSostituitiA` +  professionista?.codiceFiscale}
            />
        </Fragment> : <Fragment/>;

        if (!Utils.isObjectNull(professionista)) {
            let professionistaJSX;
            if (this.isProfessionistaEqualsToLoggedUser(professionista.codiceFiscale)) {
                professionistaJSX = <Fragment>
                    <div className="text-capitalize">{professionista.nome} {professionista.cognome} (Io) {professionistiSostituitiJSX}</div>
                </Fragment>;
            } else {
                professionistaJSX = <Fragment>
                    <div className="text-capitalize">{professionista.nome} {professionista.cognome} {professionistiSostituitiJSX}</div>
                </Fragment>;
            }
            listaProfessionisti.push(professionistaJSX);
        } else {
            listaProfessionisti.push("-");
        }
    }

    getProfessionistiValutazioneMultidimensionale = (valutazioni) => {
        let listaProfessionisti = [];
        _.forEach(valutazioni, (valutazione) => {
            this.pushProfessionista(valutazione.valutatore, listaProfessionisti, valutazione?.storicoProfessionisti)
        });
        return listaProfessionisti;
    }

    getProfessionistiFormalizzazione = (teamMcp, valutazioni, listaProfessionisti = []) => {
        if (teamMcp != null) {
            this.pushProfessionista(teamMcp?.professionista, listaProfessionisti, teamMcp?.storicoProfessionisti)
        }
        return listaProfessionisti;
    }


    getStatoAvanzamento = (valutazioni, richiedente, medicoFormalizzatore, tipologia = enumRichiesta.tipologia.CLASSICA) => {
        let statoDaVisualizzare = [];
        let stringTipologia = tipologia === enumRichiesta.tipologia.CLASSICA ? "multidimensionale" : "territoriale";

        _.forEach(valutazioni, (valutazione) => {
            let statoAvanzamento;
            let valutatore = valutazione.valutatore;
            if (valutatore.codiceFiscale === richiedente.codiceFiscale) {
                // richiedente
                if (valutazione.stato === enumRichiesta.stato.CONFERMATA) {
                    statoAvanzamento = { sName: "Valutazione "+ stringTipologia+" confermata", cssClass: "stickerGreen" };
                } else {
                    statoAvanzamento = { sName: "Valutazione "+ stringTipologia+" confermata", cssClass: "stickerGreenOp4" };
                }
            } else if (valutatore.codiceFiscale === medicoFormalizzatore?.codiceFiscale) {
                // mcp || centrale - adi
                if (valutazione.stato === enumRichiesta.stato.CONFERMATA) {
                    statoAvanzamento = { sName: "Valutazione "+ stringTipologia+" confermata", cssClass: "stickerGreen" };
                } else {
                    statoAvanzamento = { sName: "Valutazione "+ stringTipologia+" confermata", cssClass: "stickerGreenOp4" };
                } 
            } else {
                // valutatore
                if (valutazione.stato === enumRichiesta.stato.CONFERMATA) {
                    statoAvanzamento = { sName: "Valutazione professionale confermata", cssClass: "stickerGreen" };
                } else {
                    statoAvanzamento = { sName: "Valutazione professionale confermata", cssClass: "stickerGreenOp4" };
                }
            }
            statoDaVisualizzare.push(statoAvanzamento);
        });

        return statoDaVisualizzare;
    }

    getDataCompletamento = (valutazioni) => {
        let dataDaVisualizzare = [];
        _.forEach(valutazioni, (valutazione) => {
            let dataCompletamento;
            if (valutazione.timestampConferma) {
                dataCompletamento = Utils.transformTimeStampForFrontend(valutazione.timestampConferma);
            } else {
                dataCompletamento = "-";
            }
            dataDaVisualizzare.push(dataCompletamento);
        });
        return dataDaVisualizzare;
    }

    getLinkLabel = (valutazioni, richiedente, medicoFormalizzatore, tipologia = enumRichiesta.tipologia.CLASSICA) => {

        let links = [];
        _.forEach(valutazioni, (valutazione) => {
            let link;
            let valutatore = valutazione.valutatore;
            if (valutatore.codiceFiscale === richiedente.codiceFiscale) {
                // richiedente
                if (valutazione.stato === enumRichiesta.stato.CONFERMATA) {
                    link = this.getLinkStatoRichiesta(azioniRichiesta.visualizzaValutazioneMultidimensionale.label, valutatore.codiceFiscale, enumStep.cda2.STEP_CDA2_VALUTAZIONE_MEDICO_RICHIEDENTE);
                } else {
                    link = this.getLinkStatoRichiesta(azioniRichiesta.inAttesaDiValutazioneMultidimensionale.label, valutatore.codiceFiscale, enumStep.cda2.STEP_CDA2_VALUTAZIONE_MEDICO_RICHIEDENTE );
                }

            } else if (valutatore.codiceFiscale === medicoFormalizzatore?.codiceFiscale) {
                // mcp || centrale adi
                if (valutazione.stato === enumRichiesta.stato.CONFERMATA) {
                    link = this.getLinkStatoRichiesta(azioniRichiesta.visualizzaValutazioneMultidimensionale.label, valutatore.codiceFiscale,  enumStep.cda2.STEP_CDA2_VALUTAZIONE_MEDICO_RICHIEDENTE );
                } else {
                    link = this.getLinkStatoRichiesta(azioniRichiesta.inAttesaDiValutazioneMultidimensionale.label, valutatore.codiceFiscale,  enumStep.cda2.STEP_CDA2_VALUTAZIONE_MEDICO_RICHIEDENTE );
                } 
            } else {
                if (valutazione.stato === enumRichiesta.stato.CONFERMATA) {
                    link = this.getLinkStatoRichiesta(azioniRichiesta.visualizzaValutazioneProfessionale.label, valutatore.codiceFiscale, enumStep.cda2.STEP_CDA2_INVIO_VALUTAZIONE );
                } else {
                    link = this.getLinkStatoRichiesta(azioniRichiesta.inAttesaDiValutazioneProfessionale.label, valutatore.codiceFiscale,  enumStep.cda2.STEP_CDA2_INVIO_VALUTAZIONE );
                }
            }
            links.push(link);
        });

        return links;
    }

    // TODO verificare le routepath corrette
    getLinkStatoRichiesta(statoRichiesta, codiceFiscaleAutore, step) {
        let pdfPresente;
        switch (statoRichiesta) {
            case azioniRichiesta.visualizzaRichiestaAttivazione.label:
            case azioniRichiesta.visualizzaValutazioneProfessionale.label:
            case azioniRichiesta.visualizzaValutazioneMultidimensionale.label:
                pdfPresente = true;
                break;
            default:
                pdfPresente = false;
                break;
        }

        let linkRiapriValutazione = null;
        // todo fase 2 link riapri valutazione
        let condizioneRiapriValutazione = false; //AuthUtils.hasUtenteRuoloMedicoRichiedente() && [azioniRichiesta.visualizzaValutazioneProfessionale.label, azioniRichiesta.visualizzaValutazioneMultidimensionale.label].includes(statoRichiesta);
        if (condizioneRiapriValutazione) {
            linkRiapriValutazione = <Fragment>
                | <Link to={{ pathname: "", uuidRichiesta: this.props.richiesta?.id }} className={styles.link}>Riapri valutazione</Link>
            </Fragment>;
        }

        if (pdfPresente) {
            return <FieldsRow colNumber={-1} spaceBetweenRows={false}>
                <a className={styles.link} onClick={() => this.downloadPdf(codiceFiscaleAutore, step)}>
                    {statoRichiesta}
                </a>
                <BsIcon.EyeFill color={"#176A65"} width={15} height={15}/>
                {linkRiapriValutazione}
            </FieldsRow>;
        } else {
            return statoRichiesta;
        }
    }

    downloadPdf = (codiceFiscaleAutore, step) => {
        let uuidRichiesta = this.props.richiesta.id;
        RichiestaADIService.generaPdf(uuidRichiesta, step, codiceFiscaleAutore).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "confirm",
                    text: "Il fascicolo sanitario elettronico non è al momento disponibile. Si prega di riprovare più tardi"
                });
            } else {
                const linkSource = `data:${response.data.contentType};base64,${response.data.file}`;
                const downloadLink = document.createElement("a");
                const fileName = response.data.fileName;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        });

    }

    render() {
        return (
            <Fragment>
                <div className={"mb-2"}>
                    <span className={styles.title.concat(" pl-4")}>
                        Monitoraggio stato avanzamento richiesta di attivazione di cure domiciliari
                    </span>
                    <div className={styles.tableContainer.concat(" m-4")}>
                        <Table
                            cols={this.state.cols}
                            rows={this.state.rows}
                            emptyMsg={"Nessun valutatore recuperato"}
                            totalElements={0}
                            bigTable={false}
                            nascondiPaginazione={true}
                            lengthEditable={false}
                            tableLenght={this.state.rows?.length}
                            classNameRow={"row d-flex align-item-start ml-auto"}
                            alignColsToStart={true}
                            showScroll={false}
                            classTable={"stato-valutazione-table"}
                        />
                    </div>
                </div>
                {this.state.popupConfermaSostituizioneMedicoRichiedente &&
                    <PopupConfermaSostituizioneMedicoRichiedente
                        uuidRichiesta={this.props.richiesta?.id}
                        showPopup={this.state.popupConfermaSostituizioneMedicoRichiedente}
                        chiudiPopupSostituzioneMedicoichiedente={() => this.setState({ popupConfermaSostituizioneMedicoRichiedente: false })}
                        updateRichiesta = {() => this.props.updateRichiesta()}
                    />}
            </Fragment>
        );
    }
}

TabellaStatoValutazione.propTypes = propTypes;


