import React, {Component} from "react";
import PropTypes from "prop-types";
import AccordionHelper from "../../service/AccordionHelper";
import * as _ from "lodash";
import {mostraSpinner, nascondiSpinner} from "../../App";
import {forkJoin, of} from "rxjs";
import Utils from "../../utils/Utils";
import enumsUtente from "../../enum/enumsUtente.json";
import WebSocketHelper from "../../websocket/WebSocketHelper";
import HistorySessionRequest from "../../utils/HistorySessionRequest";
import {getCountMandatoryFieldsMissing} from "../anagrafeRichiedente/AnagrafeRichiedente";
import RichiestaADIService from "../../service/RichiestaADIService";
import {flatMap, map} from "rxjs/operators";
import AnagrafeService from "../../service/AnagrafeService";
import {defaultAnagrafePaziente} from "../../utils/SessionRequest";
import enumContatto from "../../enum/enumContatto.json";
import enumIndirizzo from "../../enum/enumIndirizzo.json";
import {Redirect} from "react-router-dom";
import {SessioneUtente} from "web-client-archetype";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import routepath from "../../utils/route/route-path.json";
import {isSomeFieldIdWriteable} from "../roleBasedComponents/RoleBasedComponents";
import {Context} from "../../utils/Context";
import SchedaPazienteChiudiPreDialogo from "./SchedaPazienteChiudiPreDialogo";
import ProposteChiudiPreDialogo from "./ProposteChiudiPreDialogo";
import RiepilogoChiudiPreDialogo from "./RiepilogoChiudiPreDialogo";
import Trackbar from "../trackbar/Trackbar";
import * as $ from "jquery";
import {showMessageAulssDistrettoDifferences} from "../inserimentoRichiesta/InserimentoRichiesta";

const propTypes = {
    uuidRichiesta: PropTypes.string,
    pageState: PropTypes.string,
    pageName: PropTypes.string,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    richiesta: PropTypes.object,
    richiestaMedico: PropTypes.object,
    persone: PropTypes.object,
    firstPageName: PropTypes.string,
    pageNumber: PropTypes.number,
    forcedModalitaRiepilogo: PropTypes.bool,
    pathname: PropTypes.string
}

export default class CompilaChiudiPredialogo extends Component {

    state = {
        richiesta: null,
        infoRichiesta: null,
        richiestaMedico: null,
        persone: null,
        error: false,
        gotoAnagraficaRichiedente: false,
        listaCodiciFiscaliUtentiOnline: []
    };

    defaultFormalizzazione = {
        nomeFormalizzatore: null,
        cognomeFormalizzatore: null,
        codiceFiscaleFormalizzatore: null,
        codiceProfilo: null,
        tipoValutazione: null,
        dataValutazione: null,
        progettoAssistenzialePrincipale: null,
        progettoAssistenzialeSecondario: null,
        ruoloDelResponsabile: null,
        nomeResponsabile: null,
        cognomeResponsabile: null,
        telefonoResponsabile: null,
        cellulareResponsabile: null,
        faxResponsabile: null,
        emailResponsabile: null,
        dataInizioProgettoAssistenziale: null,
        dataVerificaProgrammata: null,
        esitoValutazione: null,
        motivazione: null
    };

    componentDidMount() {
        const callback = () => {
            if (AccordionHelper.idSelectedAccordions != null) {
                const ref = $(document.getElementById(AccordionHelper.idSelectedAccordions));
                ref.find('> div.collapse-header > button').trigger('click');
            }
        };
        this.montaComponente(this.props.uuidRichiesta, callback);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.richiesta, this.props.richiesta)) this.montaComponente(this.props.uuidRichiesta);
    }

    montaComponente = (uuidRichiesta, callback = null) => {
        mostraSpinner();

        let requests = [this.recuperaRichiesta(uuidRichiesta, callback), of(null), of(null)];

        if (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) {
            if (!this.props.readOnly) requests[1] = this.recuperaStoricoListaCampi(uuidRichiesta, null);
            if (SessioneUtente.getInstance().settings.map(setting => setting.ruolo).includes(enumsUtente.ruoli.infermiere)) {
                requests[2] = this.recuperaRichiestaMediciConfermati(uuidRichiesta);
            }
        }

        forkJoin(requests).subscribe((data) => {
            if (!data?.[0]) {
                this.setState({error: true}, nascondiSpinner);
            } else {
                WebSocketHelper.onMessageListener();
                WebSocketHelper.setIdRichiesta(this.state.richiesta?.id ?? null);
                WebSocketHelper.utenteAutenticatoSubject.subscribe((message) => {
                    console.log("Lista Utenti Loggati Chiusura Predialogo", WebSocketHelper.utentiLoggati);
                    this.setState({listaCodiciFiscaliUtentiOnline: _.cloneDeep(WebSocketHelper.utentiLoggati)});
                });
                WebSocketHelper.utenteDisconnessoSubject.subscribe((flag) => {
                    if (flag) this.setState({listaCodiciFiscaliUtentiOnline: WebSocketHelper.utentiLoggati});
                });
                if (data[1]) HistorySessionRequest.setData(data[1], data[0]?.[0]);
                if (data[2]) this.setState({richiestaMedico: data[2]});

                this.countMandatoryFieldsMissing(this.state.richiesta);
                AccordionHelper.resetMappaVariazionePathDecodedValue();
                AccordionHelper.setLastSurveyCompiled(null);

                nascondiSpinner();
            }
        });
    }

    countMandatoryFieldsMissing = (richiesta) => {
        if (Utils.isSegnalazioneConfermataPreDialogoOrInChiusuraPreDialogo(richiesta)) {
            const richiedente = this.state.persone?.[Utils.recuperoIdProfessionista(richiesta)];
            let missingFieldsCount = 0;
            if (richiedente?.identificativoUtente === SessioneUtente.getInstance().idUtente) {
                missingFieldsCount = getCountMandatoryFieldsMissing(richiedente, this.props.pageState);
            }
            this.setState({gotoAnagraficaRichiedente: missingFieldsCount > 0});
        }
    }

    recuperaRichiesta(uuidRichiesta, callback) {
        return RichiestaADIService.letturaRichiesta(uuidRichiesta)
            .pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        if (response?.status === 404) {
                            this.setState({error: true}, () => {
                                nascondiSpinner();
                                AccordionHelper.setLastSurveyCompiled(null);
                            });
                        } else {
                            Utils.toasterFunction({
                                status: response?.data?.codice ? response?.data?.codice : response?.status,
                                type: "critical",
                                text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                            });
                        }
                    }
                    return null;
                } else {
                    return [response.data, this.estraiUUIDPersone(response.data)];
                }
            }))
            .pipe(flatMap((dataArray) => {
                if (!dataArray?.[0]) return of(null);

                let richiesta = dataArray[0];
                let listaUUIDPersone = dataArray[1];
                let queryParams = {};
                queryParams.vista = "totale";
                queryParams.filtro = "id_persona~~" + listaUUIDPersone.join("|");
                return AnagrafeService.getPersone(queryParams).pipe(map((data) => {
                    if (data) {
                        let map = _.keyBy(data, "id");
                        if (!richiesta.hasOwnProperty("anagrafePaziente"))
                            richiesta.anagrafePaziente = {};
                        let anagrafePaziente = _.cloneDeep(richiesta.anagrafePaziente);

                        Utils.resetObject(defaultAnagrafePaziente);

                        let assistito = map[richiesta.idAssistito];
                        if (!Utils.isObjectNull(assistito)) {
                            anagrafePaziente.datiGenerali = defaultAnagrafePaziente.datiGenerali;
                            anagrafePaziente.datiGenerali.identificativoEventoAssistenzialeDiCD = richiesta.id;
                            anagrafePaziente.datiGenerali.ilPazienteEInformatoDellaDomanda = richiesta.anagrafePaziente?.datiGenerali?.ilPazienteEInformatoDellaDomanda !== undefined
                                ? richiesta.anagrafePaziente?.datiGenerali?.ilPazienteEInformatoDellaDomanda : null;
                            Object.keys(assistito).forEach(key => {
                                if (key === "lingue") {
                                    this.getLingua(anagrafePaziente.datiGenerali, assistito[key]);
                                } else if (key === "contatti" && assistito[key].length > 0) {
                                    this.setContatto(assistito[key], enumContatto.contatto.telefono, anagrafePaziente.datiGenerali);
                                    this.setContatto(assistito[key], enumContatto.contatto.cellulare, anagrafePaziente.datiGenerali);
                                    this.setContatto(assistito[key], enumContatto.contatto.email, anagrafePaziente.datiGenerali);
                                } else if (key in defaultAnagrafePaziente.datiGenerali)
                                    anagrafePaziente.datiGenerali[key] = assistito[key];
                            });

                            anagrafePaziente.residenza = defaultAnagrafePaziente.residenza;
                            anagrafePaziente.domicilio = defaultAnagrafePaziente.domicilio;
                            anagrafePaziente.domicilioDiCura = defaultAnagrafePaziente.domicilioDiCura;
                            if (assistito.indirizzi.length > 0) {
                                let residenza = this.getRecapito("residenza", assistito.indirizzi, enumIndirizzo.indirizzo.residenza);
                                if (residenza !== null) {
                                    anagrafePaziente.residenza = {
                                        ...residenza,
                                        distretto: assistito.distrettoResidenza ?? residenza.distretto
                                    };
                                }

                                let domicilio = this.getRecapito("domicilio", assistito.indirizzi, enumIndirizzo.indirizzo.domicilio);
                                if (domicilio !== null)
                                    anagrafePaziente.domicilio = domicilio;

                                let domicilioDiCura = this.getRecapito("domicilioDiCura", assistito.indirizzi, enumIndirizzo.indirizzo.altro);
                                if (domicilioDiCura !== null)
                                    anagrafePaziente.domicilioDiCura = domicilioDiCura;
                            }
                            anagrafePaziente.residenza.residenzaDiversaDaDomicilio = richiesta.anagrafePaziente?.residenza?.residenzaDiversaDaDomicilio ?? null;
                            anagrafePaziente.domicilio.domicilioDiversoDaDomicilioCure = richiesta.anagrafePaziente?.domicilio?.domicilioDiversoDaDomicilioCure ?? null;

                            anagrafePaziente.esenzioni = defaultAnagrafePaziente.esenzioni;
                            if (assistito.esenzioni?.length > 0) {
                                anagrafePaziente.esenzioni = [];
                                assistito.esenzioni.forEach(esenzione => {
                                    Object.keys(esenzione).forEach(key => {
                                        if (!(key in esenzione))
                                            delete esenzione[key];
                                    });
                                    anagrafePaziente.esenzioni.push(esenzione);
                                });
                            }
                            let mascheraModificabilitaAssistito = _.cloneDeep(assistito.mascheraModificabilita);
                            if (!Utils.isObjectNull(mascheraModificabilitaAssistito)) {
                                mascheraModificabilitaAssistito.indirizzi = mascheraModificabilitaAssistito.indirizzi.map(i => {
                                    if (i.idElemento === anagrafePaziente.residenza?.idElemento
                                        && !Utils.isStringEmptyOrNullOrUndefined(assistito.distrettoResidenza)) {
                                        i.distretto = mascheraModificabilitaAssistito.distrettoResidenza;
                                    }
                                    return i;
                                });
                            }
                            anagrafePaziente.datiGenerali.mascheraModificabilita = mascheraModificabilitaAssistito;
                        }

                        anagrafePaziente.mmgPlsDelPaziente = defaultAnagrafePaziente.mmgPlsDelPaziente;
                        let mmgPlsPaziente = map[richiesta.anagrafePaziente.mmgPlsDelPaziente?.id];
                        if (!Utils.isObjectNull(mmgPlsPaziente)) {
                            Object.keys(mmgPlsPaziente).forEach(key => {
                                if (key === "contatti" && mmgPlsPaziente[key].length > 0) {
                                    this.setContatto(mmgPlsPaziente[key], enumContatto.contatto.telefono, anagrafePaziente.mmgPlsDelPaziente);
                                    this.setContatto(mmgPlsPaziente[key], enumContatto.contatto.cellulare, anagrafePaziente.mmgPlsDelPaziente);
                                    this.setContatto(mmgPlsPaziente[key], enumContatto.contatto.email, anagrafePaziente.mmgPlsDelPaziente);
                                } else if (key in defaultAnagrafePaziente.mmgPlsDelPaziente)
                                    anagrafePaziente.mmgPlsDelPaziente[key] = mmgPlsPaziente[key];
                            });
                            anagrafePaziente.mmgPlsDelPaziente.mascheraModificabilita = mmgPlsPaziente.mascheraModificabilita;
                            if (!Utils.isObjectNull(richiesta?.mmg)) richiesta.mmg.id = mmgPlsPaziente.id;
                        }

                        anagrafePaziente.padre = defaultAnagrafePaziente.padre;
                        let padre = map[richiesta.anagrafePaziente.padre?.id];
                        if (!Utils.isObjectNull(padre)) {
                            anagrafePaziente.padre.isPadreIndicatoComeRiferimentoPerPaziente = richiesta.anagrafePaziente.padre?.isPadreIndicatoComeRiferimentoPerPaziente;
                            Object.keys(padre).forEach(key => {
                                if (key === "indirizzi" && padre[key].length > 0) {
                                    this.setIndirizzo(padre[key], anagrafePaziente.padre);
                                } else if (key === "contatti" && padre[key].length > 0) {
                                    this.setContatto(padre[key], enumContatto.contatto.telefono, anagrafePaziente.padre);
                                    this.setContatto(padre[key], enumContatto.contatto.cellulare, anagrafePaziente.padre);
                                    this.setContatto(padre[key], enumContatto.contatto.email, anagrafePaziente.padre);
                                } else if (key === "lingue")
                                    this.getLingua(anagrafePaziente.padre, padre[key]);
                                else if (key in defaultAnagrafePaziente.padre)
                                    anagrafePaziente.padre[key] = padre[key];
                            });
                        }

                        anagrafePaziente.madre = defaultAnagrafePaziente.madre;
                        let madre = map[richiesta.anagrafePaziente.madre?.id];
                        if (!Utils.isObjectNull(madre)) {
                            anagrafePaziente.madre.isMadreIndicataComeRiferimentoPerPaziente = richiesta.anagrafePaziente.madre?.isMadreIndicataComeRiferimentoPerPaziente;
                            Object.keys(madre).forEach(key => {
                                if (key === "indirizzi" && madre[key].length > 0) {
                                    this.setIndirizzo(madre[key], anagrafePaziente.madre);
                                } else if (key === "contatti" && madre[key].length > 0) {
                                    this.setContatto(madre[key], enumContatto.contatto.telefono, anagrafePaziente.madre);
                                    this.setContatto(madre[key], enumContatto.contatto.cellulare, anagrafePaziente.madre);
                                    this.setContatto(madre[key], enumContatto.contatto.email, anagrafePaziente.madre);
                                } else if (key === "lingue")
                                    this.getLingua(anagrafePaziente.madre, madre[key]);
                                else if (key in defaultAnagrafePaziente.madre)
                                    anagrafePaziente.madre[key] = madre[key];
                            });
                        }

                        anagrafePaziente.tutore = defaultAnagrafePaziente.tutore;
                        if (richiesta.anagrafePaziente?.tutore) {
                            anagrafePaziente.tutore = [];
                            richiesta.anagrafePaziente.tutore.forEach(tutore => {
                                let tutoreRecuperato = map[tutore.id];
                                if (!Utils.isObjectNull(tutoreRecuperato)) {
                                    let obj = {};
                                    Object.keys(tutoreRecuperato).forEach(key => {
                                        if (key === "indirizzi" && tutoreRecuperato[key].length > 0) {
                                            this.setIndirizzo(tutoreRecuperato[key], obj);
                                        } else if (key === "contatti" && tutoreRecuperato[key].length > 0) {
                                            this.setContatto(tutoreRecuperato[key], enumContatto.contatto.telefono, obj);
                                            this.setContatto(tutoreRecuperato[key], enumContatto.contatto.cellulare, obj);
                                            this.setContatto(tutoreRecuperato[key], enumContatto.contatto.email, obj);
                                        } else if (key === "lingue")
                                            this.getLingua(obj, tutoreRecuperato[key]);
                                        else if (key === "id" || key === "nome" || key === "cognome" || key === "identificativoUtente")
                                            obj[key] = tutoreRecuperato[key];
                                    });
                                    anagrafePaziente.tutore.push(obj);
                                }
                            });
                        }

                        anagrafePaziente.amministratoreDiSostegno = defaultAnagrafePaziente.amministratoreDiSostegno;
                        if (richiesta.anagrafePaziente?.amministratoreDiSostegno) {
                            anagrafePaziente.amministratoreDiSostegno = [];
                            richiesta.anagrafePaziente.amministratoreDiSostegno.forEach(amministratoreDiSostegno => {
                                let amministratoreDiSostegnoRichiesta = richiesta.anagrafePaziente.amministratoreDiSostegno.find(el => el.id === amministratoreDiSostegno.id);
                                let amministratoreDiSostegnoRecuperato = map[amministratoreDiSostegno.id];
                                if (!Utils.isObjectNull(amministratoreDiSostegnoRecuperato)) {
                                    let obj = {};
                                    obj.conDelegaSanitaria = amministratoreDiSostegnoRichiesta?.conDelegaSanitaria;
                                    obj.numeroDecretoDiNomina = amministratoreDiSostegnoRichiesta?.numeroDecretoDiNomina;
                                    obj.annoDecretoDiNomina = amministratoreDiSostegnoRichiesta?.annoDecretoDiNomina;
                                    Object.keys(amministratoreDiSostegnoRecuperato).forEach(key => {
                                        if (key === "indirizzi" && amministratoreDiSostegnoRecuperato[key].length > 0) {
                                            this.setIndirizzo(amministratoreDiSostegnoRecuperato[key], obj);
                                        } else if (key === "contatti" && amministratoreDiSostegnoRecuperato[key].length > 0) {
                                            this.setContatto(amministratoreDiSostegnoRecuperato[key], enumContatto.contatto.telefono, obj);
                                            this.setContatto(amministratoreDiSostegnoRecuperato[key], enumContatto.contatto.cellulare, obj);
                                            this.setContatto(amministratoreDiSostegnoRecuperato[key], enumContatto.contatto.email, obj);
                                        } else if (key === "lingue")
                                            this.getLingua(obj, amministratoreDiSostegnoRecuperato[key]);
                                        else if (key === "id" || key === "nome" || key === "cognome" || key === "identificativoUtente")
                                            obj[key] = amministratoreDiSostegnoRecuperato[key];
                                    });
                                    anagrafePaziente.amministratoreDiSostegno.push(obj);
                                }
                            });
                        }

                        anagrafePaziente.fiduciario = defaultAnagrafePaziente.fiduciario;
                        let fiduciarioRecuperato = map[richiesta.anagrafePaziente.fiduciario?.id];
                        if (!Utils.isObjectNull(fiduciarioRecuperato)) {
                            Object.keys(fiduciarioRecuperato).forEach(key => {
                                if (key === "indirizzi" && fiduciarioRecuperato[key].length > 0) {
                                    this.setIndirizzo(fiduciarioRecuperato[key], anagrafePaziente.fiduciario);
                                } else if (key === "contatti" && fiduciarioRecuperato[key].length > 0) {
                                    this.setContatto(fiduciarioRecuperato[key], enumContatto.contatto.telefono, anagrafePaziente.fiduciario);
                                    this.setContatto(fiduciarioRecuperato[key], enumContatto.contatto.cellulare, anagrafePaziente.fiduciario);
                                    this.setContatto(fiduciarioRecuperato[key], enumContatto.contatto.email, anagrafePaziente.fiduciario);
                                } else if (key === "lingue")
                                    this.getLingua(anagrafePaziente.fiduciario, fiduciarioRecuperato[key]);
                                else if (key in defaultAnagrafePaziente.fiduciario)
                                    anagrafePaziente.fiduciario[key] = fiduciarioRecuperato[key];
                            });
                        }

                        richiesta.anagrafePaziente = anagrafePaziente;

                        let listaUtentiCfOnline = _.cloneDeep(WebSocketHelper.utentiLoggati) ?? [];
                        if (WebSocketHelper.isWebSocketConnected()) {
                            WebSocketHelper.utentiLoggati.push(SessioneUtente.getInstance().idUtente);
                            WebSocketHelper.utentiLoggati = Array.from(new Set(WebSocketHelper.utentiLoggati));
                            listaUtentiCfOnline = WebSocketHelper.utentiLoggati;
                        }

                        richiesta = {
                            ..._.cloneDeep(richiesta),
                            formalizzazione: richiesta?.formalizzazione ?? this.defaultFormalizzazione
                        };

                        if (RichiestaADIService.richiestaRicercata == null)
                            RichiestaADIService.richiestaRicercata = _.cloneDeep(richiesta);

                        this.setState({
                            richiesta: richiesta,
                            persone: map,
                            listaCodiciFiscaliUtentiOnline: listaUtentiCfOnline
                        }, () => {
                            if (callback) callback();
                        });
                    }
                    dataArray.push(data);
                    return dataArray;
                }));
            }));
    }

    recuperaStoricoListaCampi = (uuidRichiesta, nomiCampi) => {
        return RichiestaADIService.storicoListaCampi(uuidRichiesta, nomiCampi)
            .pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        if (response?.status === 404) {
                            //this.setState({error: true}, () => nascondiSpinner());
                        } else {
                            Utils.toasterFunction({
                                status: response?.data?.codice ? response?.data?.codice : response?.status,
                                type: "critical",
                                text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                            });
                        }
                    }
                    return null;
                } else {
                    return response.data;
                }
            }));
    }

    recuperaRichiestaMediciConfermati = (uuidRichiesta) => {
        return RichiestaADIService.letturaRichiesta(uuidRichiesta, true)
            .pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        if (response?.status === 404) {
                            this.setState({error: true}, () => {
                                nascondiSpinner();
                                AccordionHelper.setLastSurveyCompiled(null);
                            });
                        } else {
                            Utils.toasterFunction({
                                status: response?.data?.codice ? response?.data?.codice : response?.status,
                                type: "critical",
                                text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                            });
                        }
                    }
                    return null;
                } else {
                    return response.data;
                }
            }));
    }

    estraiUUIDPersone = (richiesta) => {
        let listUUIDPersone = [];

        this.addToUUIDPersoneList(richiesta.idAssistito, listUUIDPersone);
        this.addToUUIDPersoneList(richiesta.richiedente.idProfessionista, listUUIDPersone);
        this.addToUUIDPersoneList(Utils.recuperoIdProfessionista(richiesta), listUUIDPersone);

        if (richiesta.hasOwnProperty("anagrafePaziente")) {
            let anagrafePaziente = richiesta.anagrafePaziente;
            let uuidMmgPlsPaziente = anagrafePaziente.hasOwnProperty("mmgPlsDelPaziente") ? anagrafePaziente.mmgPlsDelPaziente.id : null;
            this.addToUUIDPersoneList(uuidMmgPlsPaziente, listUUIDPersone);
            let uuidPadre = anagrafePaziente.hasOwnProperty("padre") ? anagrafePaziente.padre.id : null;
            this.addToUUIDPersoneList(uuidPadre, listUUIDPersone);
            let uuidMadre = anagrafePaziente.hasOwnProperty("madre") ? anagrafePaziente.madre.id : null;
            this.addToUUIDPersoneList(uuidMadre, listUUIDPersone);
            if (anagrafePaziente.hasOwnProperty("tutore"))
                anagrafePaziente.tutore.forEach(el => this.addToUUIDPersoneList(el.id, listUUIDPersone));
            if (anagrafePaziente.hasOwnProperty("amministratoreDiSostegno"))
                anagrafePaziente.amministratoreDiSostegno.forEach(el => this.addToUUIDPersoneList(el.id, listUUIDPersone));
            let uuidFiduciario = anagrafePaziente.hasOwnProperty("fiduciario") ? anagrafePaziente.fiduciario.id : null;
            this.addToUUIDPersoneList(uuidFiduciario, listUUIDPersone);
        }

        return Array.from(new Set(listUUIDPersone));
    }

    addToUUIDPersoneList = (uuid, list) => {
        if (uuid)
            list.push(uuid);
    }

    getLingua = (object, lingue) => {
        object.idLingue = [];
        object.madrelingua = null;
        object.livello = null;
        object.descrizione = [];
        if (lingue.length > 0) {
            let isMadreLingua = !!lingue.find(lingua => lingua.madrelingua);
            if (isMadreLingua) {
                object.idLingue = [lingue[0].idElemento];
                object.madrelingua = isMadreLingua;
            } else {
                object.idLingue = lingue.map(lingua => lingua.idElemento);
                object.madrelingua = isMadreLingua;
                object.livello = lingue.find(lingua => !lingua.madrelingua)?.livello ?? null;
                object.descrizione = lingue.map(lingua => lingua.descrizione);
            }
        }
    }

    setIndirizzo = (indirizzi, object) => {
        let residenza = this.getRecapito("residenza", indirizzi, enumIndirizzo.indirizzo.residenza);
        if (residenza !== null && !Utils.isObjectNull(residenza)) {
            object.indirizzo = residenza.indirizzo;
            object.numero = residenza.numero;
            object.idElementoIndirizzo = residenza.idElemento;
        } else {
            let domicilio = this.getRecapito("domicilio", indirizzi, enumIndirizzo.indirizzo.domicilio);
            if (domicilio !== null && !Utils.isObjectNull(domicilio)) {
                object.indirizzo = domicilio.indirizzo;
                object.numero = domicilio.numero;
                object.idElementoIndirizzo = domicilio.idElemento;
            } else {
                let domicilioDiCura = this.getRecapito("domicilioDiCura", indirizzi, enumIndirizzo.indirizzo.altro);
                if (domicilioDiCura !== null && !Utils.isObjectNull(domicilioDiCura)) {
                    object.indirizzo = domicilioDiCura.indirizzo;
                    object.numero = domicilioDiCura.numero;
                    object.idElementoIndirizzo = domicilioDiCura.idElemento;
                } else {
                    object.indirizzo = null;
                    object.numero = null;
                    object.idElementoIndirizzo = null;
                }
            }
        }
    }

    getRecapito = (field, indirizzi, tipoIndirizzo) => {
        let listaIndirizzi = indirizzi.filter(indirizzo => indirizzo.tipo === tipoIndirizzo);
        let object = undefined;

        if (listaIndirizzi.length > 0)
            object = listaIndirizzi[listaIndirizzi.length - 1];

        if (object !== undefined || !Utils.isObjectNull(object)) {
            Object.keys(object).forEach(key => {
                if (!(key in defaultAnagrafePaziente[field]))
                    delete object[key];
            });
            return object;
        } else
            return null;
    }

    setContatto = (contatti, tipoContatto, object) => {
        let listaContatti = contatti.filter(el => el.tipo === tipoContatto);
        let contatto;

        if (listaContatti.length > 0)
            contatto = listaContatti[listaContatti.length - 1];

        switch (tipoContatto) {
            case enumContatto.contatto.telefono:
                object.telefono = contatto?.valore ?? null;
                object.idElementoTelefono = contatto?.idElemento ?? null;
                break;
            case enumContatto.contatto.cellulare:
                object.cellulare = contatto?.valore ?? null;
                object.idElementoCellulare = contatto?.idElemento ?? null;
                break;
            case enumContatto.contatto.email:
                object.email = contatto?.valore ?? null;
                object.idElementoEmail = contatto?.idElemento ?? null;
                break;
            default:
                break;
        }
    }

    render() {
        if (this.state.gotoAnagraficaRichiedente) {
            return <Redirect to={{
                pathname: this.props.pathname.concat(routepath.anagrafica_medico_richiedente),
                idPersona: Utils.recuperoIdProfessionista(this.state.richiesta),
                uuidRichiesta: this.props.uuidRichiesta,
                pageState: this.props.pageState,
                pageName: this.props.pageName,
                readOnly: !isSomeFieldIdWriteable('ANAGRAFESOGGETTORICHIEDENTE', this.props.pageState) || !!this.props.readOnly,
                hideUnsetValues: this.props.hideUnsetValues,
                firstPageName: this.props.firstPageName,
                insRichiestaPathname: this.props.pathname,
                richiesta: this.state.richiesta,
                pageNumber: this.props.pageNumber
            }}/>
        }

        if (this.state.error) {
            return (
                <Redirect to={{
                    pathname: routepath.not_found,
                    state: {title: "Errore", motivation: "La richiesta non è stata trovata"}
                }}/>
            );
        }

        if (!this.state.richiesta) return null;

        let page;

        // Props della componente Trackbar
        let props = {
            pageState: this.props.pageState,
            richiesta: this.state.richiesta,
            showOptionalFieldsCount: false,
            listaCodiciFiscaliUtentiOnline: this.state.listaCodiciFiscaliUtentiOnline
        }

        switch (this.props.pageNumber) {
            case 1:
                page = <SchedaPazienteChiudiPreDialogo richiesta={this.state.richiesta}
                                                       richiestaMedico={this.state.richiestaMedico ?? this.props.richiestaMedico}
                                                       persone={this.state.persone}
                                                       pageState={this.props.pageState}
                                                       pageName={this.props.pageName}
                                                       readOnly={this.props.readOnly}
                                                       hideUnsetValues={false}
                                                       firstPageName={this.props.pageName}
                                                       pageNumber={this.props.pageNumber}
                                                       listaCodiciFiscaliUtentiOnline={this.state.listaCodiciFiscaliUtentiOnline}
                />;
                break;

            case 2:
                page = <ProposteChiudiPreDialogo richiesta={this.state.richiesta}
                                                 richiestaMedico={this.state.richiestaMedico ?? this.props.richiestaMedico}
                                                 persone={this.state.persone}
                                                 pageState={this.props.pageState}
                                                 pageName={this.props.pageName}
                                                 readOnly={this.props.readOnly}
                                                 hideUnsetValues={false}
                                                 firstPageName={this.props.firstPageName}
                                                 pageNumber={this.props.pageNumber}
                                                 listaCodiciFiscaliUtentiOnline={this.state.listaCodiciFiscaliUtentiOnline}
                />;
                break;

            case 3:
                props = {...props, showAllMenuItems: true};

                page = <RiepilogoChiudiPreDialogo richiesta={this.state.richiesta}
                                                  richiestaMedico={this.state.richiestaMedico ?? this.props.richiestaMedico}
                                                  pageState={this.props.pageState}
                                                  forcedModalitaRiepilogo={this.props.forcedModalitaRiepilogo}
                                                  readOnly={this.props.readOnly}
                                                  hideUnsetValues={true}
                                                  pageName={this.props.pageName}
                                                  firstPageName={this.props.firstPageName}
                                                  persone={this.state.persone}
                                                  pageNumber={this.props.pageNumber}
                                                  listaCodiciFiscaliUtentiOnline={this.state.listaCodiciFiscaliUtentiOnline}
                />;
                break;
        }

        return <>
            <Breadcrumb path={['Home', "Dettaglio chiusura pre-dialogo"]}
                        richiesta={this.state.richiesta}
                        infoRichiesta={this.state.infoRichiesta}/>
            {showMessageAulssDistrettoDifferences(this.state.richiesta, this.state.persone)}
            <Trackbar {...props}/>
            {page}
        </>;
    }
}

CompilaChiudiPredialogo.propTypes = propTypes;
CompilaChiudiPredialogo.defaultProps = {
    pageNumber: 1
}
ProposteChiudiPreDialogo.contextType = Context;
