import React, {Fragment, useContext} from "react";
import PropTypes from "prop-types";
import RadioButton from "../../../generalComponents/radiobutton/RadioButton";
import styles from "../Questionario.module.css";

/**
 * @function RenderPunteggio renderizza i punteggi di ogni sezione se c'e la
 * @param sezioneQuestionario contiene l'informazione della sezione renderizata es. id, titolo, domande, punteggi ect.
 * @param isQuestionarioCompleto booleano che ci indica se il questionario e stato compilato comppletamente
 * @param punteggio contiene il punteggio attuale della sezione in compilazione 
 * @param valutazione indica la valutazione del questionario in funzione alle domande risposte
 * @param sezione contiene l'informazione della sezione renderizata es. id, titolo, domande, punteggi ect.
*/
function RenderPunteggio ({sezioneQuestionario, isQuestionarioCompleto, areCampiObbligatoriCompilati, punteggio, valutazione, sezione}) {

    const {punteggi} = sezioneQuestionario;
    const indicazionePunteggio = punteggi[0]?.indicazione;
    let labelPunteggio = punteggi[0]?.label;
    let labelSecondoPunteggio = punteggi?.[1]?.label;
    let infoPunteggioQuestionario;

    if ((isQuestionarioCompleto != null && isQuestionarioCompleto) || (sezione?.domande?.length > 0 && areCampiObbligatoriCompilati)) {
        const {valori} = punteggi[1] ?? {};
        infoPunteggioQuestionario = valori?.find(valore => valore.label === valutazione)?.description ?? null
        labelPunteggio = labelPunteggio?.replace("*", punteggio != null ? punteggio : "");

        if (punteggi[1]?.valori) {
            if (valutazione) {
                if (labelSecondoPunteggio) {
                    labelSecondoPunteggio += " | " + valutazione;
                } else {
                    labelPunteggio += " | " + valutazione;
                }
            }
        }
    } else {
        labelPunteggio = labelPunteggio?.replace("*", "");
    }

    return (
        <Fragment>
            <div className={"row mt-2"}>
                <div className={"col"}>
                    <span className="f-weight-600">{labelPunteggio}</span>
                    {labelSecondoPunteggio ? <span className="f-weight-600">{labelSecondoPunteggio}</span> : <Fragment />}
                    {infoPunteggioQuestionario ? (<div className={styles.descriptionPunteggio}>{infoPunteggioQuestionario}</div>) : <Fragment />}
                </div>
            </div>
            {indicazionePunteggio && (<div className={"row"} style={{fontSize: '14px'}}>
                <div className={"col"}>
                    {indicazionePunteggio}
                </div>
            </div>)}
        </Fragment>
    )
}

RenderPunteggio.propTypes = {
    sezioneQuestionario: PropTypes.node,
    isQuestionarioCompleto: PropTypes.bool,
    areCampiObbligatoriCompilati: PropTypes.bool,
    punteggio: PropTypes.number,
    valutazione: PropTypes.node,
    sezione: PropTypes.node,
};

RenderPunteggio.defaultProps = {
    sezioneQuestionario: {},
    isQuestionarioCompleto: false,
    areCampiObbligatoriCompilati: false,
    punteggio: null,
    valutazione: {},
    sezione: {},
};

export default RenderPunteggio;
