import React, { Component } from "react";
import { Button } from "web-client-archetype";
import PropTypes from "prop-types";
import ModalHelper from "../modale/ModalHelper";
import $ from "jquery"

const propTypes = {
    id: PropTypes.string,
    title: PropTypes.any,
    body: PropTypes.any,
    footer: PropTypes.any,
    redirectTo: PropTypes.any,
    handleOnClickModale: PropTypes.any,
    btnText: PropTypes.string,
    renderFooter: PropTypes.bool
}

export default class ModalAnagraficaRichiedente extends Component {

    componentDidMount() {
        // this.blurBackground();
        this.customRightClick(false);
    }

    blurBackground() {
        $('#bg-custom').addClass('bg-blur');
    }

    componentWillUnmount() {
        $('#bg-custom').removeClass('bg-blur');
        this.customRightClick(true);
    }

    customRightClick(value) {
        window.oncontextmenu = function () {
            return value;
        };

        document.addEventListener("keydown", function (event) {
            var key = event.key || event.keyCode;

            if (key == 123) {
                return value;
            } else if ((event.ctrlKey && event.shiftKey && key == 73) || (event.ctrlKey && event.shiftKey && key == 74)) {
                return value;
            }
        }, false);
    }

    renderModale = () => {
        const footer = (
            <>
                <Button
                    id={"btnFooter"}
                    text={this.props.btnText}
                    onClick={this.props.handleOnClickModale}
                    dataDismiss={"modal"}
                    className={"btn btn-adi btnGreenOutline"}
                />
            </>
        );

        const getFooter = () => {
            return footer;
        }

        return (
            <>
                <ModalHelper
                    id={"modalAnagraficaRichiedente"}
                    title={this.props.title}
                    titleClassName={"d-inline text-center backgroundGreen"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={"modal-content"}
                    body={this.props.body}
                    footer={this.props.renderFooter ? getFooter() : <></>}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={"modal-body d-flex align-items-center align-self-center"}
                />
            </>
        );
    }

    render() {
        return (
            <>
                {this.renderModale()}
            </>
        )
    }

}

ModalAnagraficaRichiedente.propTypes = propTypes;
