import globals from "../config/globalConfig";
import {w3cwebsocket as W3CWebSocket} from "websocket";
import {BehaviorSubject} from "rxjs";
import enumTipoMessaggioWebSocket from "../enum/enumTipoMessaggioWebSocket.json";
import {SessioneUtente} from "web-client-archetype";
import * as _ from "lodash"

export default class WebSocketHelper {

    static client;
    static wsTicket = null;
    static message = null;
    static utentiOnline = {};
    static utentiLoggati = [];
    static utenteOnlineSubject = new BehaviorSubject();
    static utenteOfflineSubject = new BehaviorSubject();
    static utenteAutenticatoSubject = new BehaviorSubject();
    static utenteDisconnessoSubject = new BehaviorSubject();
    static nuovaNotificaSubject = new BehaviorSubject();
    static idRichiesta = null;
    static valutazioneConfermataEvent = null;

    static setWsTicket = (wsTicket) => {
        this.wsTicket = wsTicket;
    }

    static setMessage = (message) => {
        this.message = message;
    }

    static setIdRichiesta = (idRichiesta) => {
        this.idRichiesta = idRichiesta;
    }

    static setOnlineUsers = (message) => {
        if (!this.utentiOnline[message.idRichiesta])
            this.utentiOnline[message.idRichiesta] = [message.codiceFiscale];
        else {
            var array = this.utentiOnline[message.idRichiesta]
            if (!_.includes(this.utentiOnline, message.codiceFiscale))
                array.push(message.codiceFiscale);
            array = _.uniq(array);
            this.utentiOnline[message.idRichiesta] = array;
        }
    }

    static setOfflineUsers = (message) => {
        if (this.utentiOnline[message.idRichiesta]) {
            var array = this.utentiOnline[message.idRichiesta]
            var newArray = _.uniq(array.filter((v) => v !== message.codiceFiscale));
            this.utentiOnline[message.idRichiesta] = newArray;
        }
    }

    static connect = () => {
        this.client = new W3CWebSocket(globals['app-url-websocket'] + "?WsTicket=" + this.wsTicket);
        this.client.onopen = () => {
            console.log('WebSocket Client Connected');
            if (this.client.readyState === this.client.OPEN) {
                setTimeout(() => {
                    this.sendMessage(enumTipoMessaggioWebSocket.ONCONNECT, SessioneUtente.getInstance().idUtente, null)
                }, 2000);
            }
        }

        this.onMessageListener();
    }

    static disconnect = (url) => {
        if (WebSocketHelper.isWebSocketConnected()) {
            // rimuovo l'utente autenticato dalla lista degli utenti online
            // if (WebSocketHelper.idRichiesta)
            //     this.utentiOnline[WebSocketHelper.idRichiesta] = this.utentiOnline[WebSocketHelper.idRichiesta].filter(cf => cf !== SessioneUtente.getInstance().idUtente);
            this.utentiLoggati = this.utentiLoggati.filter(cf => cf !== SessioneUtente.getInstance().idUtente);

            WebSocketHelper.sendDisconnect(SessioneUtente.getInstance().idUtente, WebSocketHelper.idRichiesta);

            // La chiusura del browser o del tab del browser non triggera il metodo onclose della WebSocket
            // In questi casi per disconnettere la WebSocket bisogna invocare il metodo close() manualmente
            this.client.close();

            // Dopo la chiamata al metodo close viene triggerato l'evento onclose della WebSocket
            this.client.onclose = () => {
                console.log('WebSocket Client Disconnected');
                window.location.href = url;
            }
        } else
            window.location.href = url;
    }

    static setLoggedUser = (valoreMessaggio) => {
        var array = this.utentiLoggati;
        let listaCf = valoreMessaggio.split(';');
        array.push(...listaCf);
        array = _.uniq(array);
        this.utentiLoggati = array;
    }

    static setOfflineUser = (valoreMessaggio) => {
        if (this.utentiLoggati.length > 0) {
            var array = this.utentiLoggati;
            let listaCf = valoreMessaggio.split(';');
            var newArray = _.uniq(array.filter((v) => !listaCf.includes(v)));
            this.utentiLoggati = newArray;
        }
    }

    static onMessageListener = () => {
        this.client.onmessage = (message) => {
            console.log("Messaggio");
            console.log(message.data);
            try {
                let data = JSON.parse(message.data);
                switch (data.tipoMessaggio) {
                    case enumTipoMessaggioWebSocket.ONLINE:
                        this.setOnlineUsers(data.valoreMessaggio);
                        this.utenteOnlineSubject.next(data.valoreMessaggio);
                        break;
                    case enumTipoMessaggioWebSocket.OFFLINE:
                        this.setOfflineUsers(data.valoreMessaggio);
                        this.utenteOfflineSubject.next(true);
                        break;
                    case enumTipoMessaggioWebSocket.CONFERMAVALUTAZIONE:
                        this.valutazioneConfermataEvent = data.valoreMessaggio;
                        break;
                    case enumTipoMessaggioWebSocket.NOTIFICHEDALEGGERE:
                        this.nuovaNotificaSubject.next(data.valoreMessaggio);
                        break;
                    case enumTipoMessaggioWebSocket.ONCONNECT:
                        this.setLoggedUser(data.valoreMessaggio);
                        this.utenteAutenticatoSubject.next(data.valoreMessaggio);
                        break;
                    case enumTipoMessaggioWebSocket.ONDISCONNECT:
                        this.setOfflineUser(data.valoreMessaggio);
                        this.utenteDisconnessoSubject.next(true);
                        break;
                    default:
                        break;
                }
                console.log(this.utentiLoggati);
            } catch (e) {
                if (message.data !== "OK") console.log(e);
            }
        }
    }

    static isWebSocketConnected = () => {
        return this.client !== undefined && this.client !== null && this.client.readyState === this.client.OPEN;
    }

    static sendMessage = (tipoMessaggio, codiceFiscale, idRichiesta) => {
        var body = {};
        body["tipoMessaggio"] = tipoMessaggio;
        if (tipoMessaggio === enumTipoMessaggioWebSocket.OFFLINE) {
            body["valoreMessaggio"] = {codiceFiscale: codiceFiscale, idRichiesta: idRichiesta};
        } else if (tipoMessaggio === enumTipoMessaggioWebSocket.ONCONNECT || tipoMessaggio === enumTipoMessaggioWebSocket.ONDISCONNECT) {
            body["valoreMessaggio"] = {codiceFiscale: codiceFiscale};
        }

        this.client.send(JSON.stringify(body));
    }

    static sendDisconnect(codiceFiscale, idRichiesta, onDisconnect = true) {
        if (this.isWebSocketConnected()) {
            this.sendMessage(enumTipoMessaggioWebSocket.OFFLINE, codiceFiscale, idRichiesta);
            if (onDisconnect) this.sendMessage(enumTipoMessaggioWebSocket.ONDISCONNECT, codiceFiscale, null);
        }
    }
}
