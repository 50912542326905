import Utils from "../../utils/Utils";


export default class CentroNotificheUtils {

    static getFiltriNotifiche(filtroCercaNelleNotifiche, mostraNotificheNonLette, ordinaNotifichePerDataCrescente, sizeNotifiche, pageNotifiche) {
        let params = {}

        if (!Utils.isStringEmptyOrNullOrUndefined(filtroCercaNelleNotifiche)) {
            let filtro = "testoNotifica~~*" + filtroCercaNelleNotifiche + "*";
            CentroNotificheUtils.addParamsFiltroNotifica(params, filtro)
        }

        if (!Utils.isStringEmptyOrNullOrUndefined(mostraNotificheNonLette) && mostraNotificheNonLette) {
            let filtro = "visualizzato::NO";
            CentroNotificheUtils.addParamsFiltroNotifica(params, filtro)
        }

        if (!Utils.isStringEmptyOrNullOrUndefined(ordinaNotifichePerDataCrescente) && ordinaNotifichePerDataCrescente) {
            params.ordinamento = "timestampCreazione:true";
        }

        if (!Utils.isStringEmptyOrNullOrUndefined(sizeNotifiche)) {
            params.numeroElementi = sizeNotifiche;
        }

        if (!Utils.isStringEmptyOrNullOrUndefined(sizeNotifiche)) {
            params.numeroPagina = pageNotifiche;
        }

        return params;
    }

    static addParamsFiltroNotifica(params, filtro) {
        if (params.filtroNotifica) {
            params.filtroNotifica += "," + filtro;
        } else {
            params.filtroNotifica = filtro;
        }
    }

}