import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import * as _ from "lodash";
import { Col, Jumbotron, Row } from "reactstrap";
import Utils from "../../../utils/Utils";
import { Link } from "react-router-dom";
import { Button, SessioneUtente } from "web-client-archetype";
import HomeUtils from "../../home/HomeUtils";
import styles from "../CentroNotifiche.module.css";

const propTypes = {
    notifica: PropTypes.object,
    campoDaEvidenziare: PropTypes.string,
    richiesta: PropTypes.object,
    additionalPadding: PropTypes.string
}

export default class Notifica extends Component {

    getHighlightedText(text, highlight) {
        const parts = text.split(new RegExp("(?<!<[^>]*)" + `(${highlight})`, 'gi'));

        let testoNotifica = "";

        parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? "<span style='background-color:#ffd862;'>" + part + "</span>" : part).map(el => testoNotifica = testoNotifica.concat(el));

        return testoNotifica;
    }

    getValutazioneRichiesta = (richiesta) => {
        let valutazioneRichiesta = {};

        if (richiesta.valutazioni) {
            richiesta.valutazioni.forEach(valutazione => {
                if (SessioneUtente.getInstance().idUtente === valutazione?.valutatore?.codiceFiscale) {
                    valutazioneRichiesta = valutazione;
                }
            });
        }

        return valutazioneRichiesta;
    }

    decodificaTesto = (testoNotifica) => {
        let testo = testoNotifica;
        testo = _.replace(testo, new RegExp("\\{(.*?)\\}", "g"), "");
        return decodeURIComponent(testo);
    }

    renderPulsanteAzioneRichiesta = () => {
        let element = {};
        let valutazione = this.getValutazioneRichiesta(this.props.richiesta);

        HomeUtils.setAzioneRichiesta(this.props.richiesta, element, valutazione);

        return (
            <>
                {element.primiLink?.map((link, i) => (
                    <Link
                        key={link?.label + i}
                        to={{
                            ...link?.linkProps,
                            uuidRichiesta: this.props.notifica.idRichiesta
                        }}>
                        {link?.label &&
                            <Button
                                id={"btnRichiestaNotifica"}
                                text={link?.label}
                                style={{ outline: "none" }}
                                className={"btn btn-adi btnGreenOutline m-3"}
                            />}
                    </Link>
                ))}
            </>
        )
    }

    render() {
        let props = _.cloneDeep(this.props);
        let testoNotifica = !Utils.isStringEmptyOrNullOrUndefined(props.campoDaEvidenziare) ? this.getHighlightedText(props.notifica.testoNotifica, props.campoDaEvidenziare) : props.notifica.testoNotifica;

        return (
            <Fragment>
                <Jumbotron fluid className={"pl-4 pr-4 h-100 d-flex flex-column " + this.props.additionalPadding}>
                    <Row>
                        <Col xs={12}>
                            <span className="f-weight-600">
                                <section dangerouslySetInnerHTML={{ __html: this.decodificaTesto(props.notifica.titoloNotifica) }} />
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <section className={styles.sectionScroll}
                                style={{ height: `calc(100vh - ${document.getElementById("containerNotificheList").offsetHeight}px - 2rem)` }}
                                dangerouslySetInnerHTML={{ __html: this.decodificaTesto(testoNotifica) }} />
                        </Col>
                    </Row>
                    <Row className={"mt-auto"}>
                        <Col xs={12} className={"text-center"}>
                            {!Utils.isStringEmptyOrNullOrUndefined(props.richiesta) && this.renderPulsanteAzioneRichiesta()}
                        </Col>
                    </Row>
                </Jumbotron>
            </Fragment>
        )
    }
}

Notifica.propTypes = propTypes;
