import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {
    FieldsRow,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../../utils/dataset/OggettiUtili.json";
import {v4 as uuid} from "uuid";
import Utils from "../../../../utils/Utils";
import {isSectionReadOnly} from "../DispositiviMediciPresenti";

const propTypes = {
    dati: PropTypes.object,
    onChange: PropTypes.func,
    pageState: PropTypes.string,
    idDispositivo: PropTypes.string,
    fieldInAccordionReadOnly: PropTypes.bool
}

export const defaultSublistElementStomie = {
    id: null,
    codice: null
}

export default class StomieAddominaliGastriche extends Component {

    state = {
        tipo: null,
        descrizioneStomia: null,
        sedeTopografia: null,
        dimensioniSonda: null,
        sondaABottone: null
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati)) this.inizializzaForm();
    }

    inizializzaForm = () => {
        let state = _.cloneDeep(this.props.dati);
        if (state == null) state = _.cloneDeep(this.state);
        this.setState(state);
    }

    handleAggiornaForm = (field, elem) => {
        let state = _.cloneDeep(this.state);
        if (field === "irrigazioni" && elem !== "02") {
            state["quantitaLiquidi"] = null;
            state["tipologia"] = null;
        }
        state[field] = new RegExp(/^[-]\d+$/g).test(elem) ? "0" : elem;
        this.setState(state, () => this.props.onChange("stomieAddominaliGastriche", this.state));
    }

    addNewElementToList = (field) => {
        let dati = _.cloneDeep(this.state);
        if (!dati[field]) dati[field] = [];
        dati[field].push({..._.cloneDeep(defaultSublistElementStomie), id: uuid()});
        this.handleAggiornaForm(field, dati[field]);
    }

    removeElementToList = (sublistIndex, field) => {
        let dati = _.cloneDeep(this.state);
        dati[field].splice(sublistIndex, 1);
        this.handleAggiornaForm(field, dati[field]);
    }

    renderSedeTopografia = (element, index) => {
        const {sedeTopografia} = this.state;
        const filterSedeTopografia = (item) => {
            return !(sedeTopografia?.find(e => e.codice === item.value))
        }
        const options = OggettiUtili.sedeTopogStomia.filter(filterSedeTopografia);
        if (element?.codice) options.push(OggettiUtili.sedeTopogStomia.find(e => e?.value === element?.codice));
        return <Fragment key={index}>
            <FieldsRow>
                <RoleBasedSelect
                    id={"selectSedeTopografia"}
                    fieldId={'SEDE_STOMIA_ADD_GASTR.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"sedeTopografia"}
                    fieldLabel={"Sede/i e Topografia"}
                    pageState={this.props.pageState}
                    options={options}
                    value={element.codice}
                    onChange={(elem) => this.handleAggiornaForm(
                        "sedeTopografia",
                        _.cloneDeep(this.state)?.sedeTopografia?.map(function (typePro) {
                            if (element.id === typePro.id)
                                return {...element, codice: elem}
                            return typePro
                        }))}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
        </Fragment>;
    }

    render() {
        return <>
            <FieldsRow>
                <RoleBasedSelect
                    id={"selectTipo"}
                    fieldId={'TIPO_STOMIA_ADD_GASTR.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"tipo"}
                    fieldLabel={"Tipo"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.tipoStomiaAddGastr}
                    value={this.state.tipo}
                    onChange={(elem) => this.handleAggiornaForm("tipo", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedInput
                    fieldId={'TIPO_STOMIA_ADD_GASTR.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    pageState={this.props.pageState}
                    placeholder={"Descrizione stomia"}
                    field={"descrizioneStomia"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    id={"descrizioneStomia"}
                    value={this.state.descrizioneStomia}
                    fieldLabel={"Descrizione stomia"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
            <SectionList
                addNewSectionCallback={() => this.addNewElementToList("sedeTopografia")}
                removeSectionCallback={(i) => this.removeElementToList(i, "sedeTopografia")}
                data={this.state.sedeTopografia || []}
                title={'Sede/i e Topografia'}
                renderSection={(e, i) => {
                    return this.renderSedeTopografia(e, i);
                }}
                pageState={this.props.pageState}
                field={"sedeTopografia"}
                addButtonVisibilityHandler={this.state.sedeTopografia?.length === 0
                || this.state.sedeTopografia?.every(el => !Utils.isObjectNull(el?.codice))}
                indentOffset={0}
                fieldInAccordionReadOnly={isSectionReadOnly(this.props.pageState) || this.props.fieldInAccordionReadOnly}
                parentJsonPath={"informazioniDiBase.dispositiviMediciPresenti[?(@.id == '"+ this.props.idDispositivo +"')].stomieAddominaliGastriche"}
            />
            <FieldsRow>
                <RoleBasedInput
                    id={"inputDimensioniSonda"}
                    fieldId={'DIM_SONDA_STOMIA_ADD_GASTR.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Dimensioni sonda (diametro)"}
                    field={"dimensioniSonda"}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci dimensioni sonda (diametro)"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.dimensioniSonda}
                    type={"number"}
                    min={"0"}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                <RoleBasedSelect
                    id={"selectSondaABottone"}
                    fieldId={'SONDA_BOTT_STOMIA_ADD_GASTR.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"sondaABottone"}
                    fieldLabel={"Sonda a bottone"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.rispostaBreve}
                    value={this.state.sondaABottone}
                    onChange={(elem) => this.handleAggiornaForm("sondaABottone", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
        </>;
    }
}

StomieAddominaliGastriche.propTypes = propTypes;
