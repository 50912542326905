import Utils from "./Utils";
import aulssData from "./dataset/aulssData/aulssMappingSedi.json";
import aulssComuni from "./dataset/aulssData/aulssMappingComuni.json";
import aulssElenco from "./dataset/aulssData/aulssElenco.json";
import aulssUO from "./dataset/aulssData/aulssMappingUnitaOperative.json";

export default class AulssUtils {

    static aulssData;


    ////// METODO INIT
    static inizializzaDatiAulss = () => {
        if (Utils.isObjectNull(AulssUtils.aulssData)) {
            sessionStorage.setItem("aulss", JSON.stringify(aulssData));
            AulssUtils.aulssData = JSON.parse(sessionStorage.getItem("aulss"));
        }
    };

    ////// METODI RECUPERO OGGETTI

    /**
     * Ritorna la lista di oggetti aulss
     *
     * @return array di oggetti aulss
     */
    static getListaAulss() {
        AulssUtils.inizializzaDatiAulss();
        return AulssUtils.aulssData;
    }

    /**
     * Ritorna l'oggetto aulss corrispondente al codiceAulss
     *
     * @param codiceAulss stringa del codice dell'aulss
     * @return oggetto aulss
     */
    static getAulssByCodiceAulss(codiceAulss) {
        return AulssUtils.getListaAulss().find(a => a.id === codiceAulss);
    }

    /**
     * Ritorna l' array di distretti a partire dall' id di una aulss
     *
     * @param idAulss stringa dell' id dell'aulss
     * @return array di distretti
     */
    static getDistrettiByAulssId(idAulss) {
        return AulssUtils.getAulssByCodiceAulss(idAulss)?.distretti ?? [];
    }

    /**
     * Ritorna l'oggetto distretto a partire dall' id dell'aulss e l'id del distretto
     *
     * @param idAulss stringa dell' id dell'aulss
     * @param idDistretto stringa dell' id del distretto
     * @return oggetto distretto
     */
    static getDistrettoByIdAulssAndIdDistretto(idAulss, idDistretto) {
        return AulssUtils.getAulssByCodiceAulss(idAulss)?.distretti?.find(d => d.id === idDistretto) ?? null;
    }

    /**
     * Ritorna l'oggetto sede a partire dall' id dell'aulss, l'id del distretto e l'id della sede
     *
     * @param idAulss stringa dell' id dell'aulss
     * @param idDistretto stringa dell' id del distretto
     * @param idSede stringa dell' id della sede
     * @return oggetto sede
     */
    static getSedeByIdAulssAndIdDistrettoAndIdSede(idAulss, idDistretto, idSede) {
        return AulssUtils.getAulssByCodiceAulss(idAulss)?.distretti?.find(d => d.id === idDistretto)?.sedi?.find(s => s.id === idSede) ?? null;
    }

    /**
     * Ritorna l' array delle sedi a partire dall' id di un distretto
     *
     * @param idAulss stringa dell' id dell'aulss
     * @param idDistretto stringa dell' id del distretto
     * @return array di sedi
     */
    static getSediByAulssIdAndDistrettoId(idAulss, idDistretto) {
        return AulssUtils.getAulssByCodiceAulss(idAulss)?.distretti?.find(d => d.id === idDistretto)?.sedi ?? [];
    }

    /**
     * Ritorna l' array delle aulss a partire dal codice del comune
     *
     * @param codiceComune codice del comune selezionato
     * @return array di AULSS
     */
    static getListaAulssByComune(codiceComune) {
        let aulssElencoByComune = [];
        let idAulssList = aulssComuni.filter(ac => ac.id === codiceComune).map(ac => ac.ulss) ?? [];
        Array.from(new Set(idAulssList)).forEach(idAulss => {
            const aulss = aulssElenco.find(a => a.id === idAulss) ?? null;
            if (aulss) aulssElencoByComune.push(aulss);
        });

        return aulssElencoByComune;
    }

    /**
     * Ritorna l' array dei comuni a partire dall' id di una aulss
     *
     * @param idAulss stringa dell' id dell'aulss
     * @return array di comuni
     */
    static getComuniByAulssId(idAulss) {
        return aulssComuni.filter(c => c.ulss === idAulss)?.map(c => c.id) ?? [];
    }

    /**
     * Ritorna l' oggetto comune a partire dall' id aulss e l'id comune
     *
     * @param idAulss stringa dell' id dell'aulss
     * @param idComune stringa dell' id del comune
     * @return oggetto comune
     */
    static getComuneByIdAulssAndIdComune(idAulss, idComune) {
        const isComunePresent = aulssComuni.some(c => c.ulss === idAulss && c.id === idComune);

        if (isComunePresent) {
            const comuni = JSON.parse(sessionStorage.getItem("comuni"));
            return comuni.find(c => c.codice === idComune) ?? null;
        }

        return null;
    }

    /**
     * Ritorna l'oggetto ambito a partire dall' id dell'aulss, l'id del distretto
     * e l'id dell'ambito presente nella lista distretti
     *
     * @param idAulss stringa dell' id dell'aulss
     * @param idDistretto stringa dell' id del distretto
     * @param idAmbito stringa dell' id dell'ambito
     * @return oggetto ambito
     */
    static getAmbitoByIdAulssAndIdDistrettoAndIdAmbito(idAulss, idDistretto, idAmbito) {
        return AulssUtils.getAulssByCodiceAulss(idAulss)?.distretti?.find(d => d.id === idDistretto)?.ambiti?.find(a => a.id === idAmbito) ?? null;
    }

    /**
     * Ritorna l' array degli ambiti a partire dall' id dell'aulss e l'id del distretto
     *
     * @param idAulss stringa dell' id dell'aulss
     * @param idDistretto stringa dell' id del distretto
     * @return array di ambiti
     */
    static getAmbitiListByIdAulssAndIdDistretto(idAulss, idDistretto) {
        return AulssUtils.getAulssByCodiceAulss(idAulss)?.distretti?.find(d => d.id === idDistretto)?.ambiti ?? [];
    }

    /**
     * Ritorna l'array delle unità operative a partire dall'id dell'aulss
     *
     * @param idAulss stringa dell'id dell'aulss
     * @return array di unità operative
     */
    static getUnitaOperativeByIdAulss(idAulss) {
        return aulssUO?.[idAulss] ?? [];
    }

    // METODI TEST

    /**
     * Ritorna un booleano che indica se gli id dei ditretti sono duplicati
     *
     * @return boolean
     * true: se ci sono id duplicati
     * false: se non ci sono id duplicati
     */
    static checkIdDistrettiDuplicati() {
        let idDistretti = AulssUtils.getListaAulss()?.map(a => a.distretti)?.map(d => d.id) ?? [];
        return (new Set(idDistretti)).size !== idDistretti.length;
    }

    /**
     * Ritorna un booleano che indica se gli id delle sedi sono duplicati
     *
     * @return boolean
     * true: se ci sono id duplicati
     * false: se non ci sono id duplicati
     */
    static checkIdSediDuplicate() {
        let idSedi = []
        AulssUtils.getListaAulss()
            .map(a => a.distretti)
            .forEach(d => idSedi.push(...(d?.sedi?.map(s => s.id) ?? [])));
        return (new Set(idSedi)).size !== idSedi.length;
    }
}
