import React, { Component } from "react";
import TabellaRichieste from "./tabellaRichieste/TabellaRichieste";
import { Button, Icon, SessioneUtente } from "web-client-archetype";
import FiltriRichiesta from "./filtriRichiesta/FiltriRichiesta";
import _ from "lodash";
import Utils, { closeModaleStyle } from "../../utils/Utils";
import AuthUtils from "../../utils/AuthUtils";
import styleHome from "./Home.module.css";
import { Context } from "../../utils/Context";
import azioniRichiesta from "../../utils/dataset/richiestaData/azioniRichiesta.json";
import AnnullamentoRichiesta from "./annullamentoRichiesta/AnnullamentoRichiesta";
import HomeUtils from "./HomeUtils";
import pageTexts from "../../utils/message/pageTexts.json";
import { withTheme } from 'styled-components'
import ModificaDataDimissioneProgrammata from "./modificaDataDimissioneProgrammata/ModificaDataDimissioneProgrammata";
import AnagrafeService from "../../service/AnagrafeService";
import SubentroRichiesta from "./subentroRichiesta/SubentroRichiesta";
import { Link, Redirect } from "react-router-dom";
import { mostraSpinner, nascondiSpinner } from "../../App";
import RichiestaADIService from "../../service/RichiestaADIService";
import ModalHelper from "../modale/ModalHelper";
import FiltroRapidoRichieste from "./filtroRapidoRichieste/FiltroRapidoRichieste";
import OggettiUtili from "../../utils/dataset/OggettiUtili.json";
import routepath from "../../utils/route/route-path.json";
import * as BsIcon from "react-bootstrap-icons";

const defaultFiltriRichiesta = {
    codiceFiscale: "",
    dataRichiestaDa: "",
    dataRichiestaA: "",
    dataValutazioneDa: "",
    dataValutazioneA: "",
    dataValutazioneProfessionaleDa: "",
    dataValutazioneProfessionaleA: "",
    statoValidazioneMultidimensionale: "",
    statoValutazioneProfessionale: "",
    statoRichiesta: "",
    statoFormalizzazione: "",
    livelloPriorita: "",
    dataScadenzaRichiestaDa: "",
    dataScadenzaRichiestaA: "",
    classificazione: "",
}
const defaultFiltriAssistito = {
    cognome: "",
    nome: ""
}


class Home extends Component {
    state = {
        richieste: {},
        filtri: {
            filtriAssistito: _.cloneDeep(defaultFiltriAssistito),
            filtriRichiesta: _.cloneDeep(defaultFiltriRichiesta)
        },
        avviaNuovaRichiesta: false,
        avviaNuovoPreDialogo: false,
        annullaRichiesta: false,
        apriModificaDataDimissione: false,
        apriSubentroRichiesta: {valore: false, tipo: null},
        uuidRichiestaDaAnnullare: null,
        uuidRichiestaModificaDataDimissione: null,
        uuidRichiestaDaSubentrare: null,
        dataDimissioneProgrammataDaRicovero: null,
        contestoAnnullamentoRichiesta: null,
        apriModalePresaInCaricoRichiesta: false,
        messaggioModaleDaVisualizzare: null,
        linkPropsRedirect: null,
        uuidRichiestaSostituzioneRichiedente: null,
        filtriRicercaRapidi: null,
        updatedLivelloPriorita: null,
        updatedStatoRichiesta: null,
        redirectStoricoRichiesta: false
    };

    constructor(props) {
        super(props);
        this.impostaRichieste = this.impostaRichieste.bind(this)
        this.impostaFiltri = this.impostaFiltri.bind(this)
        this.impostaAvviaNuovaRichiesta = this.impostaAvviaNuovaRichiesta.bind(this)
        this.impostaAvviaNuovoPreDialogo = this.impostaAvviaNuovoPreDialogo.bind(this)
    }

    componentDidMount() {
        // se il samlToken è null attende che sia pronto e chiama ricerca anagrafiche medici richiedenti,
        // altrimenti se è presente e la forma associativa non è ancora presente riprova la chiamata
        let instance = SessioneUtente.getInstance();
        if (instance.samlToken == null) {
            if (instance?.readySubject != null) {
                instance.readySubject.subscribe(isReady => {
                    if (isReady) {
                        this.chiamaRicercaAnagraficheMediciRichiedenti();
                    }
                });
            }
        } else {
            this.chiamaRicercaAnagraficheMediciRichiedenti();
        }

    }

    chiamaRicercaAnagraficheMediciRichiedenti() {
        if (SessioneUtente.getInstance()?.formaAssociativa == null && AuthUtils.hasUtenteRuoloMedicoRichiedente()) {
            AnagrafeService.ricercaAnagraficheMediciRichiedenti().subscribe((res) => {
                // eslint-disable-next-line no-prototype-builtins
                if (!res.hasOwnProperty("error")) {
                    SessioneUtente.getInstance().formaAssociativa = res.formaAssociativa;
                }
            });
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.context.clickHome) {
            if (this.state.avviaNuovaRichiesta) {
                this.impostaAvviaNuovaRichiesta(false);
            }
            else if (this.state.avviaNuovoPreDialogo) {
                this.impostaAvviaNuovoPreDialogo(false);
            }
            this.impostaFiltri({}, {});
            this.context.setClickHome(false);
        }
    }

    impostaRichieste(richieste) {
        this.setState({ richieste: richieste })
    }

    impostaAvviaNuovaRichiesta(stato) {
        this.setState({ avviaNuovaRichiesta: stato })
    }

    impostaAvviaNuovoPreDialogo(stato) {
        this.setState({ avviaNuovoPreDialogo: stato })
    }

    impostaFiltri(filtriAssistito, filtriRichiesta, updatedLivelloPriorita, updatedStatoRichiesta) {
        let filtri = _.cloneDeep(this.state.filtri);
        let filtriRicercaRapidi = _.cloneDeep(this.state.filtriRicercaRapidi);

        filtri.filtriAssistito = filtriAssistito;
        filtri.filtriRichiesta = filtriRichiesta;

        const statoDaCompletare = OggettiUtili.statoRichiesta.find(e => e.value === "01");
        const isStatoRichiestaBozzaSelected = _.isEqual(filtriRichiesta.statoRichiesta, statoDaCompletare);
        const stato = OggettiUtili.statoRichiesta.find(e => e?.value === filtriRichiesta?.statoRichiesta?.value);

        filtriRicercaRapidi = {
            priorita: !Utils.isObjectNull(filtriRichiesta.livelloPriorita)
                ? `livelloPriorita::${filtriRichiesta.livelloPriorita.value}`
                : this.state.filtriRicercaRapidi?.priorita || null,

            classificazione: !Utils.isObjectNull(filtriRichiesta.classificazione)
                ? filtriRichiesta.classificazione.value
                : this.state.filtriRicercaRapidi?.classificazione || null,

            stato: filtriRichiesta.statoRichiesta ? stato.value === "01" ? "stato::BOZZA" : "timestampCompletamento!!null" : null
        };

        if (isStatoRichiestaBozzaSelected) filtri.filtriRichiesta.statoRichiesta = statoDaCompletare;

        this.setState({filtri, filtriRicercaRapidi, updatedLivelloPriorita, updatedStatoRichiesta});
    }

    eliminaFiltro(identificativoFiltri, filtro) {
        let filtri = _.cloneDeep(this.state.filtri);
        let filtriRicercaRapidi = _.cloneDeep(this.state.filtriRicercaRapidi);

        filtri[identificativoFiltri][filtro] = "";
        if (identificativoFiltri === "filtriRichiesta") {
            if (filtro === "livelloPriorita" && !!filtriRicercaRapidi?.priorita) filtriRicercaRapidi.priorita = null;
            if (filtro === "statoRichiesta" && !!filtriRicercaRapidi?.stato) filtriRicercaRapidi.stato = null;
            if (filtro === "classificazione" && !!filtriRicercaRapidi?.classificazione) filtriRicercaRapidi.classificazione = null;
        }

        this.setState({filtri, filtriRicercaRapidi});
    }

    mostraRichiesteTotali = () => {
        if((Object.keys(this.state.filtri.filtriRichiesta).some(key => !Utils.isStringEmptyOrNullOrUndefined(this.state.filtri.filtriRichiesta[key])) ||
        Object.keys(this.state.filtri.filtriAssistito).some(key => !Utils.isStringEmptyOrNullOrUndefined(this.state.filtri.filtriAssistito[key])))) {
            return pageTexts.home.headerDescriptionConFiltri.replace("{x}", this.state.richieste.totalElements)
        }

        return pageTexts.home.headerDescriptionSenzaFiltri.replace("{x}", this.state.richieste.totalElements)
    }   

    mostraFiltriApplicati = () => {
        const areFiltriAssistitoValued = !!Object.values(this.state.filtri.filtriAssistito).filter(v => v && v !== "").length;
        const areFiltriRichiestaValued = !!Object.values(this.state.filtri.filtriRichiesta).filter(v => v && v !== "").length;
        return (areFiltriAssistitoValued || areFiltriRichiestaValued) && <div className={"row"}>
            <div className={"col-12 pt-4"}>
                {Object.entries(this.state.filtri.filtriAssistito).map(el => (
                    <>{this.mostraFiltroApplicato(el[0], el[1], "filtriAssistito")}</>))}
                {Object.entries(this.state.filtri.filtriRichiesta).map(el => (
                    <>{this.mostraFiltroApplicato(el[0], el[1], "filtriRichiesta")}</>
                ))}
            </div>
        </div>
    }

    mostraFiltroApplicato(key, value, identificativoFiltri) {
        return (
            value && value !== '' &&
            <label className={"border border-dark pt-1 pb-1 pr-2 pl-2 mr-2 rounded-pill"}>
                <>
                    <span><i><strong>{HomeUtils.getEtichettaFiltro(key)}</strong></i>{HomeUtils.getValoreFiltro(value, key)}</span>
                    <span onClick={() => this.eliminaFiltro(identificativoFiltri, key)} style={{ cursor: "pointer" }}>
                        <Icon id={"it-close"} />
                    </span>
                </>
            </label>
        )
    }

    visualizzaBenvenuto = () => (
        <div className={"row"}>
            <div className={"col-lg-9 pt-4 pl-xl-4 pl-lg-5 pl-md-0 position-relative"}>
                <strong  className={styleHome.title}>{pageTexts.home.header}</strong>
            </div>
        </div>
    )

    apriAnnullamentoRichiesta = (uuidRichiestaDaAnnullare, contesto = null) => {
        this.setState({ uuidRichiestaDaAnnullare: uuidRichiestaDaAnnullare, apriAnnullamentoRichiesta: true, contestoAnnullamentoRichiesta: contesto });
    }

    apriSubentroRichiesta = (uuidRichiesta, label) => {
        this.setState({ uuidRichiestaDaSubentrare: uuidRichiesta, apriSubentroRichiesta: {valore:true, tipo:label} });
    }

    apriModificaDataDimissione = (uuidRichiestaModificaDataDimissione, dataDimissioneProgrammataDaRicovero) => {
        this.setState({ uuidRichiestaModificaDataDimissione: uuidRichiestaModificaDataDimissione, dataDimissioneProgrammataDaRicovero: dataDimissioneProgrammataDaRicovero, apriModificaDataDimissione: true });
    }

    chiudiAnnullamentoRichiesta = () => {
        this.setState({ apriAnnullamentoRichiesta: false });
    }

    chiudiSubentroRichiesta = () => {
        this.setState({ apriSubentroRichiesta: {valore: false, tipo: null} });
    }

    chiudiModificaDataDimissione = () => {
        this.setState({ apriModificaDataDimissione: false });
    }

    apriModalePresaInCaricoRichiesta = (messaggioModaleDaVisualizzare, linkPropsRedirect, uuidRichiestaSostituzioneRichiedente) => {
        this.setState({ apriModalePresaInCaricoRichiesta: true, messaggioModaleDaVisualizzare: messaggioModaleDaVisualizzare, linkPropsRedirect: linkPropsRedirect, uuidRichiestaSostituzioneRichiedente: uuidRichiestaSostituzioneRichiedente });
    }

    chiudiModalePresaInCaricoRichiesta = () => {
        closeModaleStyle();
        this.setState({ apriModalePresaInCaricoRichiesta: false });
    }

    sostituzioneRichiedente = () => {
        mostraSpinner();
        RichiestaADIService.sostituzioneMedicoRichiedenti(this.state.uuidRichiestaSostituzioneRichiedente).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                this.chiudiModalePresaInCaricoRichiesta();
                if (response?.msgCode && response.msgCode.startsWith("WARNING_WD")) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "informational",
                        text: response?.msgCode
                    });
                    nascondiSpinner();
                } else if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                    });
                    nascondiSpinner();
                    return;
                }
            }

            nascondiSpinner();
            this.chiudiModalePresaInCaricoRichiesta();
        });
    }

    getPulsanteAvvia = () => {
        if (AuthUtils.hasRuoloMedicoMedicinaGenerale()) {
            return <div className={"row"}>
                <div className="col-lg-12 text-right">
                    <Button
                        id={"avviaNuovaRichiesta"}
                        text={<>
                            <BsIcon.PlusLg size={24} className={"mr-3"} style={{cursor: "pointer"}}/>
                            Crea Richiesta
                        </>}
                        onClick={() => this.impostaAvviaNuovaRichiesta(true)}
                        style={{outline: "none"}}
                        className={"btn btn-adi btnGreenOutline"}
                    />
                </div>
            </div>
        } else if (AuthUtils.hasRuoloMedicoRichiedenteAndMedicoOspedaliero()) {
            return <div className={"row"}>
                <div className="col-lg-12 text-right">
                    <Button
                        id={"avviaNuovoPreDialogo"}
                        text={<>
                            <BsIcon.PlusLg size={24} className={"mr-3"} style={{cursor: "pointer"}}/>
                            {azioniRichiesta.avviaNuovoPreDialogo.label}
                        </>}
                        onClick={() => this.impostaAvviaNuovoPreDialogo(true)}
                        style={{outline: "none"}}
                        className={"btn btn-adi btnGreenOutline"}
                    />
                </div>
            </div>
        }
    }

    impostaFiltriRapidi = (filtriRicercaRapidi) => {
        const livelloPriorita = filtriRicercaRapidi.priorita?.substring(filtriRicercaRapidi.priorita.lastIndexOf(':') + 1) ?? null;

        let filtri = _.cloneDeep(this.state.filtri);
        
        filtri.filtriRichiesta.livelloPriorita = OggettiUtili.livelloPrioritaSintesi.find(lp => lp.value === livelloPriorita) ?? "";
        filtri.filtriRichiesta.classificazione = OggettiUtili.filtroClassificazione.find(cl => cl.value === filtriRicercaRapidi?.classificazione) ?? "";
        filtri.filtriRichiesta.statoRichiesta = filtriRicercaRapidi?.stato ? filtriRicercaRapidi?.stato === "stato::BOZZA" ? OggettiUtili.statoRichiesta.find(e => e.value === "01") : OggettiUtili.statoRichiesta.find(e => e.value === "02") : "";

        this.setState({filtriRicercaRapidi, filtri});
    }

    renderContenutoIniziale = () => {
        return <div className={styleHome.homeContainer.concat(" row m-auto")}>
            <div className={styleHome.filtriRapidi.concat(" col-2 pl-xl-2 pl-lg-3 pl-md-0")}>
                <div className={"pt-4 position-relative"}>
                    <div className={"row"}><div className={"col-12"}/></div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <FiltriRichiesta
                                impostaFiltri={this.impostaFiltri}
                                filtri={this.state.filtri}
                            />
                        </div>
                    </div>
                    {this.mostraFiltriApplicati()}
                </div>
                <div className={"row"}>
                    <FiltroRapidoRichieste
                        filtriRichiesta={this.state.filtriRicercaRapidi}
                        impostaFiltri={(filtriRicercaRapidi) => this.impostaFiltriRapidi(filtriRicercaRapidi)}/>
                </div>
            </div>
            <div className={"col-10"}>
                {this.visualizzaBenvenuto()}
                {this.getPulsanteAvvia()}
                <TabellaRichieste
                    impostaRichieste={this.impostaRichieste}
                    filtri={this.state.filtri}
                    apriAnnullamentoRichiesta={this.apriAnnullamentoRichiesta}
                    apriModificaDataDimissione={this.apriModificaDataDimissione}
                    apriSubentroRichiesta={this.apriSubentroRichiesta}
                    mostraSubentroRichiesta={this.state.apriSubentroRichiesta}
                    apriModalePresaInCaricoRichiesta={this.apriModalePresaInCaricoRichiesta}
                    mostraModalePresaInCaricoRichiesta={this.state.apriModalePresaInCaricoRichiesta}
                    filtriRicercaRapidi={this.state.filtriRicercaRapidi}
                    updatedLivelloPriorita={this.state.updatedLivelloPriorita}
                    updatedStatoRichiesta={this.state.updatedStatoRichiesta}
                    info={this.mostraRichiesteTotali()}
                />
            </div>
        </div>;
    }


    renderContenutoClickAvvia = () => {
        return <>
            {(this.state.avviaNuovaRichiesta || this.state.avviaNuovoPreDialogo) &&
                <Redirect to={{
                    pathname: routepath.ricercaPaziente,
                    impostaAvviaNuovaRichiesta:this.impostaAvviaNuovaRichiesta,
                    impostaAvviaNuovoPreDialogo:this.impostaAvviaNuovoPreDialogo,
                    statoAvviaNuovaRichiesta:this.state.avviaNuovaRichiesta,
                    statoAvviaNuovoPreDialogo:this.state.avviaNuovoPreDialogo,
                    apriAnnullamentoRichiesta:this.apriAnnullamentoRichiesta,
                    apriSubentroRichiesta:this.apriSubentroRichiesta,
                }} />}

            {/* TODO CODE DA ELIMINARE */}
            {/* <div className={styleHome.backgroundMode}>
                <TabellaRichieste
                    impostaRichieste={this.impostaRichieste}
                    filtri={this.state.filtri}
                    apriAnnullamentoRichiesta={this.apriAnnullamentoRichiesta}
                    apriModificaDataDimissione={this.apriModificaDataDimissione}
                    apriSubentroRichiesta={this.apriSubentroRichiesta}
                    mostraSubentroRichiesta={this.state.apriSubentroRichiesta}
                    apriModalePresaInCaricoRichiesta={this.apriModalePresaInCaricoRichiesta}
                    mostraModalePresaInCaricoRichiesta={this.state.apriModalePresaInCaricoRichiesta}
                    info={this.mostraRichiesteTotali()}
                />
            </div> */}
        </>;
    }

    render() {
        let footerModalePresaInCarico = (<>
            <Button
                id={"prendiInCaricoRichiesta"}
                className={"btn btn-adi btnGreenOutline"}
                onClick={() => this.sostituzioneRichiedente()}
                text={"Prendi in carico"}>
            </Button>
            <Link to={this.state.linkPropsRedirect}>
                <Button
                    id={"visualizzaRichiesta"}
                    className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                    onClick={() => { this.chiudiModalePresaInCaricoRichiesta() }}
                    text={"Visualizza"}
                />
            </Link>
        </>)

        if(this.state.redirectStoricoRichiesta){
            return (<Redirect to={{pathname: routepath.storico_richieste}}/>)
        }

        return (
            <>
                {!this.state.avviaNuovaRichiesta && !this.state.avviaNuovoPreDialogo && this.renderContenutoIniziale()}
                {(this.state.avviaNuovaRichiesta || this.state.avviaNuovoPreDialogo) && this.renderContenutoClickAvvia()}
                {this.state.apriAnnullamentoRichiesta &&
                    <AnnullamentoRichiesta chiudiAnnullamentoRichiesta={this.chiudiAnnullamentoRichiesta}
                                           uuidRichiesta={this.state.uuidRichiestaDaAnnullare}
                                           redirectStoricoRichiesta={() =>{this.setState({redirectStoricoRichiesta:true})}}
                                           contestoAnnullamentoRichiesta={this.state.contestoAnnullamentoRichiesta}/>}

                {this.state.apriModificaDataDimissione &&
                    <ModificaDataDimissioneProgrammata dataIniziale={this.state.dataDimissioneProgrammataDaRicovero}
                                                       chiudiModificaDataDimissione={this.chiudiModificaDataDimissione}
                                                       uuidRichiesta={this.state.uuidRichiestaModificaDataDimissione}/>}

                {this.state.apriSubentroRichiesta?.valore &&
                    <SubentroRichiesta chiudiSubentroRichiesta={this.chiudiSubentroRichiesta}
                                       uuidRichiesta={this.state.uuidRichiestaDaSubentrare}
                                       tipo={this.state.apriSubentroRichiesta.tipo}/>}

                {this.state.apriModalePresaInCaricoRichiesta &&
                    <ModalHelper
                        id={"modalePresaInCaricoRichiesta"}
                        title={"Prendi in carico richiesta"}
                        titleClassName={"modal-header d-inline text-center backgroundGreen"}
                        body={(<span className={"text-center"}>{this.state.messaggioModaleDaVisualizzare}</span>)}
                        bodyClassName={"modal-body d-flex align-items-center align-self-center"}
                        containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                        contentClassName={"modal-content"}
                        footerClassName={"modal-footer justify-content-around"}
                        footer={footerModalePresaInCarico}
                    />}
            </>
        );
    }
}

Home.contextType = Context;

export default withTheme(Home)
