import React from "react";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {isArray} from "lodash";
import Utils from "../../../utils/Utils";
import {FieldsRow, RoleBasedCalendar, RoleBasedSelect} from "../../roleBasedComponents/RoleBasedComponents";
import enumQuestionario from "../../../enum/enumQuestionario.json";
import Statistica from "../../statistica/Statistica";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import enumsUtente from "../../../enum/enumsUtente.json";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg, ProposteAttivitaSuggerimentiList} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import {SessioneUtente} from "web-client-archetype";
import AuthUtils from "../../../utils/AuthUtils";

const propTypes = {
    datiValutazioneReteSociale: PropTypes.any,
    onChangeDatiAssistito: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onValidaSezione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    pageState: PropTypes.any,
    field: PropTypes.any,
    richiesta: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

export default class ValutazioneReteSociale extends AccordionSecondLevel {
    state = {
        datiValutazioneReteSociale: _.cloneDeep(this.props.datiValutazioneReteSociale)
    }

    condRilevataForzeRead;

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiValutazioneReteSociale, this.props.datiValutazioneReteSociale))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiValutazioneReteSociale = _.cloneDeep(this.props.datiValutazioneReteSociale);
        datiValutazioneReteSociale.dataValutazione = Utils.transformTimeStampForFrontend(datiValutazioneReteSociale.dataValutazione);

        this.setState({datiValutazioneReteSociale: datiValutazioneReteSociale}, () => {
            this.countCampiObbligatoriRimanentiAndValida();
        });
    }

    countCampiObbligatoriRimanentiAndValida = () => {
        let datiValutazioneReteSociale = _.cloneDeep(this.state.datiValutazioneReteSociale);
        let campiObbligatoriRimanenti = 0;

        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiValutazioneReteSociale?.propostaAttivitaSuggerimenti);

        if (Utils.isStateRichiesta(this.props.pageState) && Utils.isStringEmptyOrNullOrUndefined(datiValutazioneReteSociale?.PSOC?.valutazione)) {
            campiObbligatoriRimanenti++;
        }

        if (AuthUtils.isCurrentUserEvaluator(enumsUtente.ruoli.mmg, this.props.richiesta, this.context.forceUserRole, this.props.pageState) && Utils.isStringEmptyOrNullOrUndefined(datiValutazioneReteSociale?.PSOC?.punteggio)) {
            campiObbligatoriRimanenti++;
        }

        this.props.onChangeRequiredFieldsNumber("sottosezioneValutazioneReteSociale", campiObbligatoriRimanenti);
        this.props.onValidaSezione("isValutazioneReteSocialeValido", campiObbligatoriRimanenti === 0);
    }

    onChangePunteggio = (tipoPunteggio, punteggio, tipoLivello) => {
        let datiValutazioneReteSociale = _.cloneDeep(this.state.datiValutazioneReteSociale)
        datiValutazioneReteSociale[tipoPunteggio] = punteggio
        this.setState({datiValutazioneReteSociale: datiValutazioneReteSociale}, () =>
            this.handleAggiornaForm(tipoPunteggio, punteggio, tipoLivello));
    }

    handleAggiornaForm = (field, elem) => {
        let datiValutazioneReteSociale = _.cloneDeep(this.state.datiValutazioneReteSociale);

        datiValutazioneReteSociale[field] = elem ?? null;

        if(field == "statoDopoValutazione" && elem !== "02") {
            datiValutazioneReteSociale["propostaAttivitaSuggerimenti"] = [];
            datiValutazioneReteSociale.daConsiderare = null
        }
      
        this.setState({datiValutazioneReteSociale: datiValutazioneReteSociale}, () => {
            let datiValutazioneReteSociale = _.cloneDeep(this.state.datiValutazioneReteSociale);
            this.props.onChangeDatiAssistito("datiValutazioneReteSociale", datiValutazioneReteSociale);
            this.countCampiObbligatoriRimanentiAndValida();
        });
    }

    renderBodySezione = () => {
        let datiValutazioneReteSociale = this.state.datiValutazioneReteSociale;

        if (datiValutazioneReteSociale.propostaAttivitaSuggerimenti && isArray(datiValutazioneReteSociale.propostaAttivitaSuggerimenti) || this.props?.datiproposteAttivitaSuggerimentiAssistenteSociale?.propostaAssistenteSociale) {
            this.condRilevataForzeRead = (this.context.forceReadOnly || this.props.forceReadOnly || !!(datiValutazioneReteSociale?.propostaAttivitaSuggerimenti?.filter(p => (p?.codiceFiscale && p?.codiceFiscale != SessioneUtente.getInstance().idUtente || p?.propostaAttivitaSuggerimenti?.codiceFiscale && p?.propostaAttivitaSuggerimenti?.codiceFiscale != SessioneUtente.getInstance().idUtente))?.length))
        }
        const isMmgCurrentEvaluator = AuthUtils.isCurrentUserEvaluator(enumsUtente.ruoli.mmg, this.props.richiesta, this.context.forceUserRole, this.props.pageState);

        return (
            <>  
                <Statistica
                    fieldId={'VAL_RETE_SOC_VSOC.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    nomeLink={"Compila questionario \"Valutazione della rete sociale PSOC\""}
                    nomePunteggio={"Punteggio Valutazione della rete sociale - VSOC"}
                    questionario={datiValutazioneReteSociale.PSOC}
                    profileName={"Profilo Socio-assistenziale - Punteggio PSOC"}
                    profileFieldId={'PROF_SOC_ASS_PSOC.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                    profileRequired={Utils.isStateRichiesta(this.props.pageState) ? true : false}
                    onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.PSOC, questionario)}
                    richiesta={this.props.richiesta}
                    pathRichiestaQuestionario={"situazioneSociale.datiValutazioneReteSociale.PSOC"}
                    tipoQuestionario={enumQuestionario.tipoQuestionario.PSOC}
                    salvaBozza={this.props.salvaBozza}
                    scoreMandatoryForRole={enumsUtente.ruoli.mmg}
                    forceReadOnly={this.props.forceReadOnly}
                    parentJsonPath={'situazionesociale.valutazioneretesociale.psoc'}
                    compilaQuestionarioObbligatorio={isMmgCurrentEvaluator ? true : false}
                    obbligatorieta={{
                        surveyType: enumQuestionario.tipoQuestionario.PSOC,
                        forceRequiredCompilation: isMmgCurrentEvaluator && Utils.isStringEmptyOrNullOrUndefined(this.state.datiValutazioneReteSociale?.PSOC?.punteggio)
                    }}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />

                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'DATA_VAL_PSOC.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        id={"valutazioneReteSocialeDataValutazione"}
                        onChange={elem => this.handleAggiornaForm("dataValutazione", elem.target.value)}
                        value={datiValutazioneReteSociale.dataValutazione}
                        errorMessage={"Data valutazione non valida"}
                        fieldLabel={"Data Valutazione"}
                        field={"dataValutazione"}
                        parentJsonPath={"situazionesociale.valutazioneretesociale"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedSelect
                        fieldId={"STATO_DOPO_VAL_PSOC.SUPPORTOSOCIALE.SITUAZIONESOCIALE"}
                        pageState={this.props.pageState}
                        id={"statoDopoValutazione"}
                        options={oggettiUtili.statoDopoValutazione}
                        value={datiValutazioneReteSociale.statoDopoValutazione}
                        onChange={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                        isSearchable={false}
                        fieldLabel={'Stato dopo valutazione'}
                        noOptionsMessage={() => "Errore: stato dopo valutazione non esistente"}
                        field={"statoDopoValutazione"}
                        parentJsonPath={"situazionesociale.valutazioneretesociale"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly || this.condRilevataForzeRead}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    {datiValutazioneReteSociale?.statoDopoValutazione === "02" &&
                        <RoleBasedSelect
                        fieldId={'DACONSID_PSOC.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        options={oggettiUtili.daConsiderareAiFiniAssistenziali}
                        value={datiValutazioneReteSociale.daConsiderare}
                        onChange={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                        isSearchable={false}
                        fieldLabel={'Da considerare'}
                        field={"daConsiderare"}
                        parentJsonPath={"situazionesociale.valutazioneretesociale"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />}
                </FieldsRow>

                {datiValutazioneReteSociale?.statoDopoValutazione === '02' &&
                    <ProposteAttivitaSuggerimentiList
                        suggerimenti={datiValutazioneReteSociale.propostaAttivitaSuggerimenti && !isArray(datiValutazioneReteSociale.propostaAttivitaSuggerimenti) ? [datiValutazioneReteSociale.propostaAttivitaSuggerimenti] : datiValutazioneReteSociale.propostaAttivitaSuggerimenti || []}
                        pageState={this.props.pageState}
                        ambito={"SUPPORTO SOCIALE: VALUTAZIONE RETE SOCIALE"}
                        parentFieldId={"SUPPORTOSOCIALE.SITUAZIONESOCIALE"}
                        infParentFieldId={"SUPPORTOSOCIALE.SITUAZIONESOCIALE"}
                        onChange={(name, value) => {
                            this.handleAggiornaForm(name, value)
                        }}
                        forceReadOnly={this.context.forceReadOnly || this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        id={"ValutazioneReteSociale"}
                        parentJsonPath={"situazionesociale.valutazioneretesociale"}
                        fieldsSuffix={'PSOC'}
                        keyFieldId={"PROP_ATT_SUGG_PSOC.SUPPORTOSOCIALE.SITUAZIONESOCIALE"}
                    />
                }
                
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiValutazioneReteSociale"}
                title={"Supporto sociale: Valutazione rete sociale"}
                required={true}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }

}

ValutazioneReteSociale.contextType = RoleBasedContext;
ValutazioneReteSociale.propTypes = propTypes;
