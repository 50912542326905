import React, {Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import OggettiUtili from "../../../../utils/dataset/OggettiUtili.json";

import {v4 as uuid} from "uuid";
import {
    FieldsGroup,
    FieldsRow,
    RoleBasedAutocomplete,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    RoleBasedText,
    SectionList
} from "../../../roleBasedComponents/RoleBasedComponents";
import Box from "../../../generalComponents/box/Box";
import CondizioneRilevataSection from "../../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import AccordionSecondLevel from "../../../accordionSezioneRichiesta/AccordionSecondLevel";
import Utils from "../../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti,
    defaultValore
} from "../../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import {isArray} from "lodash";

const propTypes = {
    cuteEAnnessi: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultSede = {
    id: null,
    sede: null,
    lesioni: []
}

const defaultLesione = {
    id: null,
    tipoLesione: null,
    lesioneDaPressione: null,
    cutePerilesionale: null,
    altezza: null,
    larghezza: null,
    profondita: null,
    caratteristicheMorfologiche: [],
    bordi: []
}

const defaultCaratteristicheMorfologiche = {
    id: null,
    caratteristicaMorfologica: null
}

const defaultBordi = {
    id: null,
    bordo: null
}

const defaultUstione = {
    id: null,
    grado: null,
    sede: null
}

const defaultDermatiti = {
    id: null,
    tipologia: null,
    sede: null,
    estensioni: null
}

const defaultSedeTrattamentiLesioniTrofiche = {
    id: null,
    sede: null,
    tipologiaMedicazioneApplicata: []
}

const defaultiTrattamentiUlteriori = {
    id: null,
    trattamentiUlteriori: null,
    frequenzaTrattamentiUlteriori: null,
    frequenzaTrattamentiUlterioriSettimana: null,
    frequenzaTrattamentiUlterioriMese: null,
}

const defaultTipologiaMedicazioneApplicata = {
    codice: null,
    id: null,
    frequenzaMedicazione: null,
    frequenzaMedicazioneSettimana: null,
    frequenzaMedicazioneMese: null
}

const nomeAccordion = "VALUTAZIONE_DELLA_CUTE";

export default class ValutazioneDellaCute extends AccordionSecondLevel {
    state = {
        datiCuteEAnnessi: _.cloneDeep(this.props.cuteEAnnessi),
        datiTrattamentiLesioniTrofiche: _.cloneDeep(this.props.datiTrattamentiLesioniTrofiche)
    }

    static cndData = Utils.getListCndData();

    componentDidMount() {
        this.inizializzaFormCuteEAnnessi();
        this.inizializzaFormTrattamentiLesioniTrofiche();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.cuteEAnnessi, this.props.cuteEAnnessi)) {
            this.inizializzaFormCuteEAnnessi();
        }

        if (!_.isEqual(prevProps.datiTrattamentiLesioniTrofiche, this.props.datiTrattamentiLesioniTrofiche)) {
            this.inizializzaFormTrattamentiLesioniTrofiche();
        }
            
    }

    /* Trattamenti Lesioni Trofiche */
    inizializzaFormTrattamentiLesioniTrofiche = () => {
        let datiTrattamentiLesioniTrofiche = _.cloneDeep(this.props.datiTrattamentiLesioniTrofiche);

        if (datiTrattamentiLesioniTrofiche.sede == null) datiTrattamentiLesioniTrofiche.sede = [];
        else datiTrattamentiLesioniTrofiche.sede.forEach(el => el.tipologiaMedicazioneApplicata = el.tipologiaMedicazioneApplicata ?? []);

        if (datiTrattamentiLesioniTrofiche.trattamentiUlteriori == null) datiTrattamentiLesioniTrofiche.trattamentiUlteriori = [];

        this.setState({datiTrattamentiLesioniTrofiche: datiTrattamentiLesioniTrofiche}, () => this.validazioneDatiTrattamentiLesioniTrofiche());
    }

    handleAggiornaFormTrattamentiLesioniTrofiche = (field, elem, index = null, subField = null) => {
        let datiTrattamentiLesioniTrofiche = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);

        if (index === null && subField === null) {
            datiTrattamentiLesioniTrofiche[field] = elem;
        } else {
            datiTrattamentiLesioniTrofiche[field][index][subField] = elem;
        }

        if (field === "trattamentiLesioniTrofiche") {
            datiTrattamentiLesioniTrofiche[field] = elem;
            if (elem?.value !== "01") {
                datiTrattamentiLesioniTrofiche.presenzaTrattamentiLesioniTrofiche = false;
                datiTrattamentiLesioniTrofiche.sede = [];
                datiTrattamentiLesioniTrofiche.pressioneSistolicaCaviglia = null;
                datiTrattamentiLesioniTrofiche.pressioneSistolicaBraccioOmolaterale = null;
                datiTrattamentiLesioniTrofiche.indiceDiWinsor = null;
                datiTrattamentiLesioniTrofiche.interpretazioneIndiceDiWinsor = null;
                datiTrattamentiLesioniTrofiche.tipologiaMedicazioneApplicata = []
                datiTrattamentiLesioniTrofiche.frequenzaMedicazione = null;
                datiTrattamentiLesioniTrofiche.trattamentiUlteriori = []
                datiTrattamentiLesioniTrofiche.frequenzaTrattamentiUlteriori = null;
                datiTrattamentiLesioniTrofiche.condizioneRilevata = null;
            }
        }

        if (datiTrattamentiLesioniTrofiche.sede?.length === 2 && field === "sede" && subField === "sede") {
            let sedeOpposta = null;
            datiTrattamentiLesioniTrofiche.sede[index].tipologiaMedicazioneApplicata = [];

            if (datiTrattamentiLesioniTrofiche.sede[index].sede === "01") {
                sedeOpposta = "02";
            } else if (datiTrattamentiLesioniTrofiche.sede[index].sede === "02") {
                sedeOpposta = "01";
            }
            datiTrattamentiLesioniTrofiche.sede[1 - index].sede = sedeOpposta;
            datiTrattamentiLesioniTrofiche.sede[1 - index].tipologiaMedicazioneApplicata = [];
        }

        datiTrattamentiLesioniTrofiche.indiceDiWinsor = this.getIndiceDiWinsor(datiTrattamentiLesioniTrofiche.pressioneSistolicaCaviglia, datiTrattamentiLesioniTrofiche.pressioneSistolicaBraccioOmolaterale);
        datiTrattamentiLesioniTrofiche.interpretazioneIndiceDiWinsor = this.getInterpretazioneIndiceDiWinsor(datiTrattamentiLesioniTrofiche.indiceDiWinsor);

        this.setState({datiTrattamentiLesioniTrofiche: datiTrattamentiLesioniTrofiche}, () => this.validazioneDatiTrattamentiLesioniTrofiche());
    }

    validazioneDatiTrattamentiLesioniTrofiche = () => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        this.props.onChangeTrattamentiLesioniTrofiche("datiTrattamentiLesioniTrofiche", dati);
        this.countCampiObbligatoriRimanentiAndValida();
        
    }

    getIndiceDiWinsor(pressioneSistolicaCaviglia, pressioneSistolicaBraccioOmolaterale) {
        pressioneSistolicaCaviglia = parseInt(pressioneSistolicaCaviglia ?? '0', 10);
        pressioneSistolicaBraccioOmolaterale = parseInt(pressioneSistolicaBraccioOmolaterale ?? '0', 10);
        if (!pressioneSistolicaCaviglia || !pressioneSistolicaBraccioOmolaterale) return null;
        return (pressioneSistolicaCaviglia / pressioneSistolicaBraccioOmolaterale).toFixed(2);
    }

    getInterpretazioneIndiceDiWinsor(indiceDiWinsor) {
        switch (true) {
            case (!indiceDiWinsor):
                return null;

            case (indiceDiWinsor < 0.41):
                return "Vasculopatia periferica severa";

            case (indiceDiWinsor >= 0.41 && indiceDiWinsor <= 0.90):
                return "Vasculopatia periferica di entità lieve-moderata";

            case (indiceDiWinsor >= 0.91 && indiceDiWinsor <= 1.30):
                return "Valore normale";

            case (indiceDiWinsor >= 1.31):
                return "Arterie non comprimibili";
        }
    }

    getLabelAlert(indiceDiWinsor) {
        switch (true) {
            case (!indiceDiWinsor):
                return null;

            case (indiceDiWinsor < 0.5):
                return "Bendaggio controindicato!";

            case (indiceDiWinsor >= 0.5 && indiceDiWinsor < 0.8):
                return "Bendaggio sconsigliato!";

            case (indiceDiWinsor >= 0.8 && indiceDiWinsor < 0.9):
                return "Bendaggio elastico di sostegno (alta estensibilità)!";

            case (indiceDiWinsor >= 0.9):
                return "Bendaggio elastocompressivo consentito!";
        }
    }

    /* SedeTrattamentiLesioniTrofiche */

    addNewSedeTrattamentiLesioniTrofiche = () => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        let type = _.cloneDeep(defaultSedeTrattamentiLesioniTrofiche)
        type.id = uuid();

        if (dati.sede.length === 1) {
            let newSede = null;
            if (dati.sede[0].sede === "01") {
                newSede = "02";
            } else if (dati.sede[0].sede === "02") {
                newSede = "01";
            }
            type.sede = newSede;
        }
        dati.sede.push(type);
        this.setState({datiTrattamentiLesioniTrofiche: dati}, () => this.validazioneDatiTrattamentiLesioniTrofiche());
    }

    removeSedeTrattamentiLesioniTrofiche = (index) => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        dati.sede.splice(index, 1);
        this.setState({datiTrattamentiLesioniTrofiche: dati}, () => this.validazioneDatiTrattamentiLesioniTrofiche());
    }

    renderSedeTrattamentiLesioniTrofiche = (dato, index) => {
        return (
            <Fragment>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'SEDE_LES_TROFICHE.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"selectSede" + index}
                        options={OggettiUtili.sede}
                        value={dato.sede}
                        onChange={(elem) => this.handleAggiornaFormTrattamentiLesioniTrofiche("sede", elem, index, "sede")}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Sede non esistente"}
                        fieldLabel={"Sede"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"sede"}
                    />
                </FieldsRow>

                {<SectionList
                    title={'Tipologia medicazione applicata'}
                    keyFieldId={'TIPO_MED_APPLICATA.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    addNewSectionCallback={() => this.addNewMedicazione(index)}
                    data={dato.tipologiaMedicazioneApplicata}
                    removeSectionCallback={(i) => this.removeMedicazione(i, index)}
                    subtitle={'Tipologia medicazione applicata'}
                    renderSection={(el, i) => this.renderMedicazioneApplicata(el, i, index)}
                    infermiereKeyFieldId={this.props.infermiereFieldId}
                    pageState={this.props.pageState}
                    addButtonVisibilityHandler={!dato.tipologiaMedicazioneApplicata?.some(a => !a.codice) ?? true}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    indentOffset={this.props.indentOffset}
                    hideUnsetValues={this.props.hideUnsetValues}
                    field={'tipologiaMedicazioneApplicata'}
                    parentJsonPath={"informazioniDiBase.datiTrattamentiLesioniTrofiche.sede[?(@.id == '" + dato?.id + "')].tipologiaMedicazioneApplicata"}
                />}
            </Fragment>
        );
    }

    /* Medicazioni applicate CND*/

    handleArrayChange = (array, field, indexSede) => {
        let data = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        data.sede[indexSede][field] = array;

        this.setState({datiTrattamentiLesioniTrofiche: data}, this.validazioneDatiTrattamentiLesioniTrofiche);
    }

    addNewMedicazione = (index) => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        let defaultTipologia = _.cloneDeep(defaultTipologiaMedicazioneApplicata);
        defaultTipologia.id = uuid();
        dati.sede[index].tipologiaMedicazioneApplicata.push(defaultTipologia);
        this.handleArrayChange(dati.sede[index].tipologiaMedicazioneApplicata, "tipologiaMedicazioneApplicata", index);
    }

    removeMedicazione = (index, indexSede) => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        dati.sede[indexSede].tipologiaMedicazioneApplicata.splice(index, 1);
        this.handleArrayChange(dati.sede[indexSede].tipologiaMedicazioneApplicata, "tipologiaMedicazioneApplicata", indexSede);
    }

    update = (field, elem, i, indexSede) => {
        if (field === "codice" && !ValutazioneDellaCute.cndData.some(e => e.codice === elem)) elem = null;

        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        dati.sede[indexSede].tipologiaMedicazioneApplicata[i][field] = elem;

        if (dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazione === "01") {
            dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazioneSettimana = null;
            dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazioneMese = null;
        }

        if (dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazione == "02") {
            dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazioneMese = "";
        }

        if (dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazione == "03") {
            dati.sede[indexSede].tipologiaMedicazioneApplicata[i].frequenzaMedicazioneSettimana = "";
        }
           
        this.handleArrayChange(dati.sede[indexSede].tipologiaMedicazioneApplicata, "tipologiaMedicazioneApplicata", indexSede);
    }

    renderMedicazioneApplicata = (el, i, indexSede) => {
        return <Fragment>
            <FieldsRow>
                <RoleBasedAutocomplete
                    fieldId={'TIPO_MED_APPLICATA.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    pageState={this.props.pageState}
                    id={'autocompleteMedicazione'}
                    keyField={"codice"}
                    descriptionField={"descrizione"}
                    onChange={(field, elem) => this.update("codice", elem?.target?.value, i, indexSede)}
                    searchFromStart={false}
                    value={el.codice}
                    field={"codice"}
                    placeholder={'Inserisci medicazione'}
                    suggestions={ValutazioneDellaCute.cndData}
                    noSuggestions={'Errore: Medicazione non presente'}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    handleOnlyValue={true}
                    fieldLabel={'Tipologia Medicazione applicata'}
                />

                {!Utils.isObjectNull(el.codice) &&
                    <RoleBasedSelect
                        fieldId={'FREQ_MED_APPLICATA.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        options={OggettiUtili.frequenzaMedicazione}
                        value={el.frequenzaMedicazione}
                        onChange={(elem) => this.update("frequenzaMedicazione", elem, i, indexSede)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Frequenza medicazione non esistente"}
                        fieldLabel={'Frequenza medicazione'}
                        field={"frequenzaMedicazione"}
                        handleOnlyValue={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />}

                {(el.frequenzaMedicazione === "02" || el.frequenzaMedicazione === "03") &&
                    <RoleBasedInput
                        fieldId={'FREQ_MED_APPLICATA_N_VOLTE.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        value={el.frequenzaMedicazione === "02" ? el.frequenzaMedicazioneSettimana : el.frequenzaMedicazioneMese}
                        placeholder={el.frequenzaMedicazione === "02" ? "Inserisci frequenza settimanale" : "Inserisci frequenza al mese"}
                        fieldLabel={el.frequenzaMedicazione === "02" ? 'Frequenza settimanale' : 'Frequenza al mese'}
                        field={el.frequenzaMedicazione === "02" ? "frequenzaMedicazioneSettimana" : "frequenzaMedicazioneMese"}
                        onChange={(field, elem) => this.update(field, elem?.target?.value, i, indexSede)}
                        id={"inputAltraProposta"}
                        type={"number"}
                        min={0}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />}
            </FieldsRow>
        </Fragment>
    }

    /* Trattamenti ulteriori */

    addNewTrattamento = () => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        let type = _.cloneDeep(defaultiTrattamentiUlteriori)
        type.id = uuid();
        dati.trattamentiUlteriori.push(type);
        this.setState({datiTrattamentiLesioniTrofiche: dati}, () => this.validazioneDatiTrattamentiLesioniTrofiche());
    }

    removeTrattamento = (index) => {
        let dati = _.cloneDeep(this.state.datiTrattamentiLesioniTrofiche);
        dati.trattamentiUlteriori.splice(index, 1);
        this.setState({datiTrattamentiLesioniTrofiche: dati}, () => this.validazioneDatiTrattamentiLesioniTrofiche());
    }

    renderTrattamentiUlteriori = (dato, index) => {
        return (
            <Fragment>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'TRATTAM_ULTERIORI.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        options={OggettiUtili.trattamentiUlteriori}
                        value={dato.trattamentiUlteriori}
                        onChange={(elem) => this.handleAggiornaFormTrattamentiLesioniTrofiche("trattamentiUlteriori", elem, index, "trattamentiUlteriori")}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Trattamenti ulteriori non esistenti"}
                        fieldLabel={'Trattamenti ulteriori'}
                        handleOnlyValue={true}
                        field={"trattamentiUlteriori"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>

                {!Utils.isObjectNull(dato.trattamentiUlteriori) &&
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'FREQ_TRATTAM_ULTER.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                            pageState={this.props.pageState}
                            options={OggettiUtili.frequenzaTrattamentiUlteriori}
                            value={dato.frequenzaTrattamentiUlteriori}
                            field={"frequenzaTrattamentiUlteriori"}
                            onChange={(elem) => this.handleAggiornaFormTrattamentiLesioniTrofiche("trattamentiUlteriori", elem, index, "frequenzaTrattamentiUlteriori")}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Frequenza trattamenti ulteriori non esistente"}
                            fieldLabel={'Frequenza trattamenti ulteriori'}
                            handleOnlyValue={true}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        <FieldsGroup showFieldIfRequiredAccordionPage={false}
                                     fieldInAccordionReadOnly={!!this.props.accordionReadOnly}>
                            {dato.frequenzaTrattamentiUlteriori === "01" &&
                                <RoleBasedInput
                                    fieldId={'FREQ_TRATTAM_ULTER_N_SETTIMANA.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                    pageState={this.props.pageState}
                                    value={dato.frequenzaTrattamentiUlterioriSettimana}
                                    placeholder="Inserisci frequenza"
                                    field={"frequenzaTrattamentiUlterioriSettimana"}
                                    onChange={(field, elem) => this.handleAggiornaFormTrattamentiLesioniTrofiche("trattamentiUlteriori", elem.target.value, index, "frequenzaTrattamentiUlterioriSettimana")}
                                    id={"inputFrequenzaSettimanale" + index}
                                    fieldLabel={'Inserisci frequenza'}
                                    type={"number"}
                                    min={0}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                />}

                            {dato.frequenzaTrattamentiUlteriori === "02" &&
                                <RoleBasedInput
                                    fieldId={'FREQ_TRATTAM_ULTER_N_MESE.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                    pageState={this.props.pageState}
                                    value={this.state.datiTrattamentiLesioniTrofiche.frequenzaTrattamentiUlterioriMese}
                                    placeholder="Inserisci frequenza"
                                    field={"frequenzaTrattamentiUlterioriMese"}
                                    onChange={(field, elem) => this.handleAggiornaFormTrattamentiLesioniTrofiche("trattamentiUlteriori", elem.target.value, index, "frequenzaTrattamentiUlterioriMese")}
                                    id={"inputFrequenzaMese" + index}
                                    fieldLabel={'Inserisci frequenza'}
                                    type={"number"}
                                    min={0}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                />}

                        </FieldsGroup>
                    </FieldsRow>}
            </Fragment>
        );
    }

    /* Cute e Annessi */

    inizializzaFormCuteEAnnessi = () => {
        let datiCuteEAnnessi = _.cloneDeep(this.props.cuteEAnnessi);
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    handleAggiornaFormCuteEAnnessi = (field, elem, index = null, subField = null) => {
        this.setState(({datiCuteEAnnessi}) => {
            if (index === null && subField === null) {
                datiCuteEAnnessi[field] = elem;
            } else {
                datiCuteEAnnessi[field][index][subField] = elem;
            }

            /* Alterazioni colorito */
            if (!datiCuteEAnnessi.alterazioneColorito) {
                datiCuteEAnnessi.alterazioneColoritoTipologia = null;
                datiCuteEAnnessi.alterazioneColoritoCondizioneRilevata = null;
            }

            /* Alterazioni temperatura */
            if (!datiCuteEAnnessi.alterazioneTemperatura) {
                datiCuteEAnnessi.alterazioneTemperaturaAlterazione = null;
                datiCuteEAnnessi.alterazioneTemperaturaTemperatura = null;
                datiCuteEAnnessi.alterazioneTemperaturaNote = null;
                datiCuteEAnnessi.alterazioneTemperaturaCondizioneRilevata = null;
            }
            if (datiCuteEAnnessi.alterazioneTemperaturaAlterazione !== "03") {
                datiCuteEAnnessi.alterazioneTemperaturaAlterazioneAltro = null;
            }

            /* Idratazione */
            if (datiCuteEAnnessi.idratazione === null) {
                datiCuteEAnnessi.idratazioneNote = null;
                datiCuteEAnnessi.idratazioneCondizioneRilevata = null;
            }
            if (datiCuteEAnnessi.idratazione !== "03") {
                datiCuteEAnnessi.idratazioneNote = null;
            }

            /* Edemi */
            if (!datiCuteEAnnessi.edemi) {
                datiCuteEAnnessi.edemiSede = null;
                datiCuteEAnnessi.edemiDescrizione = null;
                datiCuteEAnnessi.edemiNote = null;
                datiCuteEAnnessi.edemiCondizioneRilevata = null;
            }

            /* Cicatrici */
            if (!datiCuteEAnnessi.cicatrici) {
                datiCuteEAnnessi.cicatriciSede = null;
                datiCuteEAnnessi.cicatriciNote = null;
                datiCuteEAnnessi.cicatriciCondizioneRilevata = null;
            }

            /* Eritema */
            if (!datiCuteEAnnessi.eritema) {
                datiCuteEAnnessi.eritemaSede = null;
                datiCuteEAnnessi.eritemaNote = null;
                datiCuteEAnnessi.eritemaCondizioneRilevata = null;
            }

            /* Ecchimosi */
            if (!datiCuteEAnnessi.ecchimosi) {
                datiCuteEAnnessi.ecchimosiSede = null;
                datiCuteEAnnessi.ecchimosiNote = null;
                datiCuteEAnnessi.ecchimosiCondizioneRilevata = null;
            }

            /* Lesione */
            if (!datiCuteEAnnessi.lesione) {
                datiCuteEAnnessi.lesioneSedi = [];
                datiCuteEAnnessi.lesioneCondizioneRilevata = null;
            }

            /* Ustioni */
            if (!datiCuteEAnnessi.ustioni) {
                datiCuteEAnnessi.listaUstioni = [];
                datiCuteEAnnessi.ustioniCondizioneRilevata = null;
            }

            /* Dermatiti */
            if (!datiCuteEAnnessi.dermatiti) {
                datiCuteEAnnessi.listaDermatiti = [];
                datiCuteEAnnessi.dermatitiPrurito = null;
                datiCuteEAnnessi.dermatitiFrequenza = null;
                datiCuteEAnnessi.dermatitiCondizioneRilevata = null;
            }
            if (!datiCuteEAnnessi.dermatitiPrurito) {
                datiCuteEAnnessi.dermatitiFrequenza = null;
            }

            /* Igiene */
            if (datiCuteEAnnessi.igiene !== "02") {
                datiCuteEAnnessi.igieneCondizioneRilevata = null;
            }

            return {datiCuteEAnnessi};
        }, this.validazioneDatiCuteEAnnessi);
    }

    countCampiObbligatoriRimanentiAndValida = () => {
        let proposteAttEsugg = [];
        let campiObbligatoriRimanenti = 0;
        Object.entries({..._.cloneDeep(this.state.datiCuteEAnnessi), ..._.cloneDeep(this.state.datiTrattamentiLesioniTrofiche)}).forEach(([key, value]) => {
            if ((key.includes("PropostaAttivitaSuggerimenti") || key.includes("propostaAttivitaSuggerimenti")) && isArray(value)){
                value.forEach(element => {
                    proposteAttEsugg.push(element)
                });
            }
        });
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(proposteAttEsugg)
        this.props.onChangeRequiredFieldsNumber("sottosezioneValutazioneDellaCute", campiObbligatoriRimanenti);
    }


    validazioneDatiCuteEAnnessi = () => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        this.props.onChangeEsameObiettivo("cuteEAnnessi", datiCuteEAnnessi);
        this.countCampiObbligatoriRimanentiAndValida();
    }

    /* Lesione - sede */

    addNewSede = () => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        let type = _.cloneDeep(defaultSede);
        type.id = uuid();
        datiCuteEAnnessi.lesioneSedi.push(type);
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    removeSede = (indexSede) => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        datiCuteEAnnessi.lesioneSedi.splice(indexSede, 1);
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    renderSede = (dato, indexSede) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'M_SEDE_LESIO.CUTEEANNESSI.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_SEDE_LESIO.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci sede"}
                        field={"sede"}
                        onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi("lesioneSedi", elem.target.value, indexSede, "sede")}
                        id={"inputLesioneSede" + indexSede}
                        value={dato.sede}
                        fieldLabel={"Sede"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                </FieldsRow>

                <SectionList
                    title={'Lesione'}
                    data={dato.lesioni}
                    renderSection={(e, i) => this.renderLesione(e, i, indexSede, dato?.id)}
                    addNewSectionCallback={() => this.addNewLesione(indexSede)}
                    removeSectionCallback={(index) => this.removeLesione(index, indexSede)}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    pageState={this.props.pageState}
                    keyFieldId={'M_TIPO_LESIO.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereKeyFieldId={'I_TIPO_LESIO.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    indentOffset={2}
                    field={'lesioni'}
                    parentJsonPath={"esameObiettivo.cuteEAnnessi.lesioneSedi[?(@.id == '" + dato?.id + "')].lesioni"}
                />
            </>
        );
    }

    /* Lesione - lesione */

    handleAggiornaLesione = (indexSede, indexLesione, field, elem) => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        datiCuteEAnnessi.lesioneSedi[indexSede].lesioni[indexLesione][field] = elem;

        if (datiCuteEAnnessi.lesioneSedi[indexSede].lesioni[indexLesione].tipoLesione !== "05")
            datiCuteEAnnessi.lesioneSedi[indexSede].lesioni[indexLesione].lesioneDaPressione = null;

        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    addNewLesione = (indexSede) => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        let type = _.cloneDeep(defaultLesione);
        type.id = uuid();
        datiCuteEAnnessi.lesioneSedi[indexSede].lesioni.push(type);
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    removeLesione = (indexLesione, indexSede) => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        datiCuteEAnnessi.lesioneSedi[indexSede].lesioni.splice(indexLesione, 1);
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    renderLesione = (dato, indexLesione, indexSede, idSedeItem) => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_TIPO_LESIO.CUTEEANNESSI.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_TIPO_LESIO.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                        pageState={this.props.pageState}
                        id={"selectTipoLesione" + indexLesione}
                        options={OggettiUtili.tipoLesione}
                        value={dato.tipoLesione}
                        onChange={(elem) => this.handleAggiornaLesione(indexSede, indexLesione, "tipoLesione", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Tipo lesione non esistente"}
                        fieldLabel={"Tipo lesione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"tipoLesione"}
                    />
                    {dato.tipoLesione === "05" &&
                        <RoleBasedSelect
                            infermiereFieldId={'I_LES_DA_PRESS_STADIO.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            fieldId={'M_LES_DA_PRESS_STADIO.CUTEEANNESSI.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            id={"selectLesioneDaPressione" + indexLesione}
                            options={OggettiUtili.stadioLesione}
                            value={dato.lesioneDaPressione}
                            onChange={(elem) => this.handleAggiornaLesione(indexSede, indexLesione, "lesioneDaPressione", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Lesione da pressione non esistente"}
                            fieldLabel={"Lesione da pressione - Stadio"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            handleOnlyValue={true}
                            field={"lesioneDaPressione"}
                        />}
                    <RoleBasedSelect
                        fieldId={'M_CUTE_PERILES.CUTEEANNESSI.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_CUTE_PERILES.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                        pageState={this.props.pageState}
                        id={"selectLesioneCutePerilesionale" + indexLesione}
                        options={OggettiUtili.cutePerilesionale}
                        value={dato.cutePerilesionale}
                        onChange={(elem) => this.handleAggiornaLesione(indexSede, indexLesione, "cutePerilesionale", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Tipo lesione non esistente"}
                        fieldLabel={"Cute perilesionale"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"cutePerilesionale"}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'M_DIMENS_ALTEZZA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_DIMENS_ALTEZZA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci altezza"}
                        field={"altezza"}
                        onChange={(field, elem) => this.handleAggiornaLesione(indexSede, indexLesione, "altezza", elem.target.value)}
                        id={"inputLesioneAltezza" + indexLesione}
                        value={dato.altezza}
                        fieldLabel={"Altezza (cm)"}
                        type={"number"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                    <RoleBasedInput
                        fieldId={'M_DIMENS_LARGHEZZA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_DIMENS_LARGHEZZA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci larghezza"}
                        field={"profondita"}
                        onChange={(field, elem) => this.handleAggiornaLesione(indexSede, indexLesione, "larghezza", elem.target.value)}
                        id={"inputLesionelarghezza" + indexLesione}
                        value={dato.larghezza}
                        fieldLabel={"Larghezza (cm)"}
                        type={"number"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                    <RoleBasedInput
                        fieldId={'M_DIMENS_PROFONDITA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                        infermiereFieldId={'I_DIMENS_PROFONDITA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci profondità"}
                        field={"profondita"}
                        onChange={(field, elem) => this.handleAggiornaLesione(indexSede, indexLesione, "profondita", elem.target.value)}
                        id={"inputLesioneProfondita" + indexLesione}
                        value={dato.profondita}
                        fieldLabel={"Profondità (cm)"}
                        type={"number"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                    />
                </FieldsRow>
                <SectionList
                    title={'Caratteristiche morfologiche'}
                    data={dato.caratteristicheMorfologiche}
                    renderSection={(e, i) => this.renderCaratteristicheMorfologiche(e, i, indexLesione, indexSede)}
                    addNewSectionCallback={() => this.addNewCaratteristicheMorfologiche(indexSede, indexLesione)}
                    removeSectionCallback={(i) => this.removeCaratteristicheMorfologiche(i, indexLesione, indexSede)}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    pageState={this.props.pageState}
                    keyFieldId={'M_CARAT_MORF.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereKeyFieldId={'I_CARAT_MORF.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    indentOffset={3}
                    field={'caratteristicheMorfologiche'}
                    parentJsonPath={"esameObiettivo.cuteEAnnessi.lesioneSedi[?(@.id == '" + idSedeItem + "')].lesioni[?(@.id == '" + dato?.id + "')].caratteristicheMorfologiche"}
                />
                <SectionList
                    title={'Bordi'}
                    data={dato.bordi}
                    renderSection={(e, i) => this.renderBordi(e, i, indexLesione, indexSede)}
                    addNewSectionCallback={() => this.addNewBordi(indexSede, indexLesione)}
                    removeSectionCallback={(i) => this.removeBordi(i, indexLesione, indexSede)}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    pageState={this.props.pageState}
                    keyFieldId={'M_BORDI.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereKeyFieldId={'I_BORDI.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    indentOffset={3}
                    field={'bordi'}
                    parentJsonPath={"esameObiettivo.cuteEAnnessi.lesioneSedi[?(@.id == '" + idSedeItem + "')].lesioni[?(@.id == '" + dato?.id + "')].bordi"}
                />
            </>
        );
    }

    /* Lesione - caratteristicheMorfologiche */

    handleAggiornaCaratteristicheMorfologiche = (indexSede, indexLesione, indexCaratteristicheMorfologiche, field, elem) => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        datiCuteEAnnessi.lesioneSedi[indexSede].lesioni[indexLesione].caratteristicheMorfologiche[indexCaratteristicheMorfologiche][field] = elem;
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    addNewCaratteristicheMorfologiche = (indexSede, indexLesione) => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        let type = _.cloneDeep(defaultCaratteristicheMorfologiche);
        type.id = uuid();
        datiCuteEAnnessi.lesioneSedi[indexSede].lesioni[indexLesione].caratteristicheMorfologiche.push(type);
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    removeCaratteristicheMorfologiche = (indexCaratteristicheMorfologiche, indexLesione, indexSede) => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        datiCuteEAnnessi.lesioneSedi[indexSede].lesioni[indexLesione].caratteristicheMorfologiche.splice(indexCaratteristicheMorfologiche, 1);
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    renderCaratteristicheMorfologiche = (dato, indexCaratteristicheMorfologiche, indexLesione, indexSede) => {
        return <FieldsRow>
            <RoleBasedSelect
                fieldId={'M_CARAT_MORF.CUTEEANNESSI.ESAMEOBIETTIVO'}
                infermiereFieldId={'I_CARAT_MORF.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                pageState={this.props.pageState}
                id={"selectLesioneCaratteristiche" + indexCaratteristicheMorfologiche}
                options={OggettiUtili.caratteristicheMorfologiche}
                value={dato.caratteristicaMorfologica}
                onChange={(elem) => this.handleAggiornaCaratteristicheMorfologiche(indexSede, indexLesione, indexCaratteristicheMorfologiche, "caratteristicaMorfologica", elem)}
                isSearchable={false}
                noOptionsMessage={() => "Errore: Caratteristica morfologica non esistente"}
                fieldLabel={"Caratteristiche morfologiche"}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                disableDoubleInput={this.props.disableDoubleInput}
                hideUnsetValues={this.props.hideUnsetValues}
                handleOnlyValue={true}
                field={"caratteristicaMorfologica"}
            />
        </FieldsRow>;
    }

    /* Lesione - bordi */

    handleAggiornaBordi = (indexSede, indexLesione, indexBordi, field, elem) => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        datiCuteEAnnessi.lesioneSedi[indexSede].lesioni[indexLesione].bordi[indexBordi][field] = elem;
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    addNewBordi = (indexSede, indexLesione) => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        let type = _.cloneDeep(defaultBordi);
        type.id = uuid();
        datiCuteEAnnessi.lesioneSedi[indexSede].lesioni[indexLesione].bordi.push(type);
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    removeBordi = (indexBordi, indexLesione, indexSede) => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        datiCuteEAnnessi.lesioneSedi[indexSede].lesioni[indexLesione].bordi.splice(indexBordi, 1);
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    renderBordi = (dato, indexBordi, indexLesione, indexSede) => {
        return <FieldsRow>
            <RoleBasedSelect
                fieldId={'M_BORDI.CUTEEANNESSI.ESAMEOBIETTIVO'}
                infermiereFieldId={'I_BORDI.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                pageState={this.props.pageState}
                id={"selectLesioneBordi" + indexBordi}
                options={OggettiUtili.bordi}
                value={dato.bordo}
                onChange={(elem) => this.handleAggiornaBordi(indexSede, indexLesione, indexBordi, "bordo", elem)}
                isSearchable={false}
                noOptionsMessage={() => "Errore: bordo non esistente"}
                fieldLabel={"Bordi"}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                disableDoubleInput={this.props.disableDoubleInput}
                hideUnsetValues={this.props.hideUnsetValues}
                handleOnlyValue={true}
                field={"bordo"}
            />
        </FieldsRow>;
    }

    /* Ustioni */

    addNewUstione = () => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        let type = _.cloneDeep(defaultUstione);
        type.id = uuid();
        datiCuteEAnnessi.listaUstioni.push(type);
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    removeUstione = (index) => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        datiCuteEAnnessi.listaUstioni.splice(index, 1);
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    renderUstione = (ustione, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'M_GRADO_UST.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_GRADO_UST.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    pageState={this.props.pageState}
                    id={"selectUstioniGrado" + index}
                    options={OggettiUtili.ustioniGrado}
                    value={ustione.grado}
                    onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("listaUstioni", elem, index, "grado")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Ustioni grado non esistente"}
                    fieldLabel={"Grado"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    handleOnlyValue={true}
                    field={"grado"}
                />
                <RoleBasedInput
                    fieldId={'M_SEDE_UST.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_SEDE_UST.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci sede"}
                    field={"sede"}
                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi("listaUstioni", elem.target.value, index, field)}
                    id={"inputUstioniSede" + index}
                    value={ustione.sede}
                    fieldLabel={"Sede"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
            </FieldsRow>
        );
    }

    /* Dermatiti */

    addNewDermatite = () => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        let type = _.cloneDeep(defaultDermatiti);
        type.id = uuid();
        datiCuteEAnnessi.listaDermatiti.push(type);
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    removeDermatite = (index) => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        datiCuteEAnnessi.listaDermatiti.splice(index, 1);
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => this.validazioneDatiCuteEAnnessi());
    }

    renderDermatite = (dermatite, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'M_TIPO_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi("listaDermatiti", elem.target.value, index, field)}
                    value={dermatite.tipologia}
                    field={"tipologia"}
                    fieldLabel={"Tipologia"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
                <RoleBasedInput
                    fieldId={'M_SEDE_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_SEDE_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci sede"}
                    field={"sede"}
                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi("listaDermatiti", elem.target.value, index, field)}
                    id={"inputDermatiteSede" + index}
                    value={dermatite.sede}
                    fieldLabel={"Sede"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
                <RoleBasedInput
                    fieldId={'M_EST_DIM_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                    infermiereFieldId={'I_EST_DIM_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                    pageState={this.props.pageState}
                    placeholder={"Inserisci estensioni o dimensioni"}
                    field={"estensioni"}
                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi("listaDermatiti", elem.target.value, index, field)}
                    id={"inputDermatiteEstensioni" + index}
                    value={dermatite.estensioni}
                    fieldLabel={"Estensioni o dimensioni (cm)"}
                    type={"number"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
            </FieldsRow>
        );
    }

    handleDispositiviArrayChange = (array, field) => {
        let datiCuteEAnnessi = _.cloneDeep(this.state.datiCuteEAnnessi);
        datiCuteEAnnessi[field] = array;
        this.setState({datiCuteEAnnessi: datiCuteEAnnessi}, () => {
            this.validazioneDatiCuteEAnnessi();
            this.forceUpdate();
        });
    }

    renderBodySezione() {
        let dati = this.state.datiCuteEAnnessi;
        return (
            <>
                { /* Alterazioni colorito */
                    <Box key={"boxAlterazioneColorito" + nomeAccordion} titleBox={"Colorito"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_PRES_ALT_COLOR.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_PRES_ALT_COLOR.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectAlterazioneColorito"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.alterazioneColorito}
                                onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("alterazioneColorito", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Alterazione colorito non esistente"}
                                fieldLabel={"Presenza di alterazione colorito"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"alterazioneColorito"}
                            />
                        </FieldsRow>

                        {dati.alterazioneColorito &&
                            <FieldsRow>
                                <RoleBasedSelect
                                    fieldId={'M_TIP_COLOR.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_TIP_COLOR.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    id={"selectAlterazioneColorito"}
                                    options={OggettiUtili.tipologiaColorito}
                                    value={dati.alterazioneColoritoTipologia}
                                    onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("alterazioneColoritoTipologia", elem)}
                                    isSearchable={false}
                                    noOptionsMessage={() => "Errore: Tipologia colorito non esistente"}
                                    fieldLabel={"Tipologia colorito"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    handleOnlyValue={true}
                                    field={"alterazioneColoritoTipologia"}
                                />
                            </FieldsRow>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI COLORITO'}
                            appendRolePrefix={true}
                            fieldsSuffix={'COLOR'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormCuteEAnnessi("alterazioneColoritoCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.alterazioneColoritoCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormCuteEAnnessi("alterazioneColoritoDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.alterazioneColoritoDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'alterazionecolorito'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormCuteEAnnessi("alterazioneColoritoPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.alterazioneColoritoPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.alterazioneColorito}
                        />
                    </Box>
                }

                { /* Alterazioni temperatura */
                    <Box key={"boxAlterazioneTemperatura" + nomeAccordion} titleBox={"Temperatura"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_PRES_ALT_TEMP.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_PRES_ALT_TEMP.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectAlterazioneTemperatura"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.alterazioneTemperatura}
                                onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("alterazioneTemperatura", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Alterazione temperatura non esistente"}
                                fieldLabel={"Presenza di alterazione temperatura"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"alterazioneTemperatura"}
                            />
                        </FieldsRow>

                        {dati.alterazioneTemperatura && <>
                            <FieldsRow>
                                <RoleBasedSelect
                                    fieldId={'M_ALT_TEMP.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_ALT_TEMP.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    id={"selectAlterazioneTemperaturaAlterazione"}
                                    options={OggettiUtili.alterazioneFebbre}
                                    value={dati.alterazioneTemperaturaAlterazione}
                                    onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("alterazioneTemperaturaAlterazione", elem)}
                                    isSearchable={false}
                                    noOptionsMessage={() => "Errore: Alterazione non esistente"}
                                    fieldLabel={"Alterazione"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    handleOnlyValue={true}
                                    field={"alterazioneTemperaturaAlterazione"}
                                />
                                <RoleBasedInput
                                    fieldId={'M_TEMP.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_TEMP.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci temperatura"}
                                    field={"alterazioneTemperaturaTemperatura"}
                                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi(field, elem.target.value)}
                                    id={"inputAlterazioneTemperaturaTemperatura"}
                                    value={dati.alterazioneTemperaturaTemperatura}
                                    fieldLabel={"Temperatura °C"}
                                    type={"number"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>

                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_NOTE_TEMP.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_NOTE_TEMP.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci note"}
                                    field={"alterazioneTemperaturaNote"}
                                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi(field, elem.target.value)}
                                    id={"inputAlterazioneTemperaturaNote"}
                                    value={dati.alterazioneTemperaturaNote}
                                    fieldLabel={"Note"}
                                    type={"textarea"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>
                        </>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI TEMPERATURA'}
                            appendRolePrefix={true}
                            fieldsSuffix={'TEMP'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormCuteEAnnessi("alterazioneTemperaturaCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.alterazioneTemperaturaCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormCuteEAnnessi("alterazioneTemperaturaDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.alterazioneTemperaturaDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'alterazioneTemperatura'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormCuteEAnnessi("alterazioneTemperaturaPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.alterazioneTemperaturaPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.alterazioneTemperatura}
                        />
                    </Box>
                }

                { /* Idratazione */
                    <Box key={"boxIdratazione" + nomeAccordion} titleBox={"Idratazione"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_IDRAT.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_IDRAT.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                pageState={this.props.pageState}
                                id={"selectIdratazione"}
                                options={OggettiUtili.idratazione}
                                value={dati.idratazione}
                                onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("idratazione", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Idratazione non esistente"}
                                fieldLabel={"Idratazione"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"idratazione"}
                            />
                            {dati.idratazione === "03" &&
                                <RoleBasedInput
                                    fieldId={'M_IDRAT.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_IDRAT.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci note"}
                                    field={"idratazioneNote"}
                                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi(field, elem.target.value)}
                                    id={"inputIdratazioneNote"}
                                    value={dati.idratazioneNote}
                                    fieldLabel={"Note"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />}
                        </FieldsRow>

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI IDRATAZIONE'}
                            appendRolePrefix={true}
                            fieldsSuffix={'IDRAT'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormCuteEAnnessi("idratazioneCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.idratazioneCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormCuteEAnnessi("idratazioneDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.idratazioneDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'idratazione'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormCuteEAnnessi("idratazionePropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.idratazionePropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.idratazione !== null}
                        />
                    </Box>
                }

                { /* Edemi */
                    <Box key={"boxEdemi" + nomeAccordion} titleBox={"Edemi"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_EDEMI.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_EDEMI.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectEdemi"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.edemi}
                                onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("edemi", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Edemi non esistente"}
                                fieldLabel={"Edemi"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"edemi"}
                            />
                        </FieldsRow>

                        {dati.edemi && <>
                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_SEDE_EDEMI.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_SEDE_EDEMI.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci sede"}
                                    field={"edemiSede"}
                                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi(field, elem.target.value)}
                                    id={"inputEdemiSede"}
                                    value={dati.edemiSede}
                                    fieldLabel={"Sede"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                                <RoleBasedInput
                                    fieldId={'M_DESCR_EDEMI.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_DESCR_EDEMI.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci descrizione"}
                                    field={"edemiDescrizione"}
                                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi(field, elem.target.value)}
                                    id={"inputEdemiDescrizione"}
                                    value={dati.edemiDescrizione}
                                    fieldLabel={"Descrizione"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>

                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_NOTE_EDEMI.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_NOTE_EDEMI.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci note"}
                                    field={"edemiNote"}
                                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi(field, elem.target.value)}
                                    id={"inputEdemiNote"}
                                    value={dati.edemiNote}
                                    fieldLabel={"Note"}
                                    type={"textarea"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>
                        </>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI EDEMI'}
                            appendRolePrefix={true}
                            fieldsSuffix={'EDEMI'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormCuteEAnnessi("edemiCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.edemiCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormCuteEAnnessi("edemiDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.edemiDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'edemi'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormCuteEAnnessi("edemiPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.edemiPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.edemi}
                        />
                    </Box>
                }

                { /* Cicatrici chirurgiche */
                    <Box key={"boxCicatrici" + nomeAccordion} titleBox={"Cicatrici chirurgiche"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_CIC_CHIR.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_CIC_CHIR.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectCicatrici"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.cicatrici}
                                onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("cicatrici", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Cicatrici chirurgiche non esistente"}
                                fieldLabel={"Cicatrici chirurgiche"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"cicatrici"}
                            />
                        </FieldsRow>

                        {dati.cicatrici && <>
                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_SEDE_CIC_CHIR.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_SEDE_CIC_CHIR.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci sede"}
                                    field={"cicatriciSede"}
                                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi(field, elem.target.value)}
                                    id={"inputCicatriciSede"}
                                    value={dati.cicatriciSede}
                                    fieldLabel={"Sede"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>

                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_NOTE_CIC_CHIR.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_NOTE_CIC_CHIR.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci note"}
                                    field={"cicatriciNote"}
                                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi(field, elem.target.value)}
                                    id={"inputEdemiNote"}
                                    value={dati.cicatriciNote}
                                    fieldLabel={"Note"}
                                    type={"textarea"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>
                        </>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI CICATRICI CHIRURGICHE'}
                            appendRolePrefix={true}
                            fieldsSuffix={'CIC_CHIR'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormCuteEAnnessi("cicatriciCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.cicatriciCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormCuteEAnnessi("cicatriciDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.cicatriciDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'cicatrici'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormCuteEAnnessi("cicatriciPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.cicatriciPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.cicatrici}
                        />
                    </Box>
                }

                { /* Eritema */
                    <Box key={"boxEritema" + nomeAccordion} titleBox={"Eritema/Eruzione"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_ERIT_ERUZ.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_ERIT_ERUZ.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectEritema"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.eritema}
                                onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("eritema", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Eritema chirurgiche non esistente"}
                                fieldLabel={"Eritema/Eruzione"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"eritema"}
                            />
                        </FieldsRow>

                        {dati.eritema && <>
                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_SEDE_ERIT_ERUZ.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_SEDE_ERIT_ERUZ.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci sede"}
                                    field={"eritemaSede"}
                                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi(field, elem.target.value)}
                                    id={"inputEritemaSede"}
                                    value={dati.eritemaSede}
                                    fieldLabel={"Sede"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>

                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_NOTE_ERIT_ERUZ.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_NOTE_ERIT_ERUZ.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci note"}
                                    field={"eritemaNote"}
                                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi(field, elem.target.value)}
                                    id={"inputEritemaNote"}
                                    value={dati.eritemaNote}
                                    fieldLabel={"Note"}
                                    type={"textarea"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>
                        </>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI ERITEMA/ERUZIONI'}
                            appendRolePrefix={true}
                            fieldsSuffix={'ERIT_ERUZ'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormCuteEAnnessi("eritemaCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.eritemaCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormCuteEAnnessi("eritemaDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.eritemaDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'eritema'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormCuteEAnnessi("eritemaPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.eritemaPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.eritema}
                        />
                    </Box>
                }

                { /* Ecchimosi */
                    <Box key={"boxEcchimosi" + nomeAccordion} titleBox={"Ecchimosi/Petecchie"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_ECCHIM.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_ECCHIM.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectEcchimosi"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.ecchimosi}
                                onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("ecchimosi", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Ecchimosi chirurgiche non esistente"}
                                fieldLabel={"Ecchimosi/Petecchie"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"ecchimosi"}
                            />
                        </FieldsRow>

                        {dati.ecchimosi && <>
                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_SEDE_ECCHIM.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_SEDE_ECCHIM.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci sede"}
                                    field={"ecchimosiSede"}
                                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi(field, elem.target.value)}
                                    id={"inputEcchimosiSede"}
                                    value={dati.ecchimosiSede}
                                    fieldLabel={"Sede"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>

                            <FieldsRow>
                                <RoleBasedInput
                                    fieldId={'M_NOTE_ECCHIM.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_NOTE_ECCHIM.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    placeholder={"Inserisci note"}
                                    field={"ecchimosiNote"}
                                    onChange={(field, elem) => this.handleAggiornaFormCuteEAnnessi(field, elem.target.value)}
                                    id={"inputEcchimosiNote"}
                                    value={dati.ecchimosiNote}
                                    fieldLabel={"Note"}
                                    type={"textarea"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                />
                            </FieldsRow>
                        </>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI ECCHIMOSI/PETECCHIE'}
                            appendRolePrefix={true}
                            fieldsSuffix={'ECCHIM'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormCuteEAnnessi("ecchimosiCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.ecchimosiCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormCuteEAnnessi("ecchimosiDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.ecchimosiDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'ecchimosi'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormCuteEAnnessi("ecchimosiPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.ecchimosiPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.ecchimosi}
                        />
                    </Box>
                }

                { /* Lesioni */
                    <Box key={"boxLesioni" + nomeAccordion} titleBox={"Lesioni"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_LESIO.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_LESIO.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectLesione"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.lesione}
                                onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("lesione", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Lesione non esistente"}
                                fieldLabel={"Lesioni"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"lesione"}
                            />
                        </FieldsRow>

                        {dati.lesione &&
                            <SectionList
                                title={'Sede'}
                                data={dati.lesioneSedi}
                                renderSection={this.renderSede}
                                addNewSectionCallback={this.addNewSede}
                                removeSectionCallback={this.removeSede}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                pageState={this.props.pageState}
                                keyFieldId={'M_SEDE_LESIO.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereKeyFieldId={'I_SEDE_LESIO.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                field={'lesioneSedi'}
                                parentJsonPath={"esameObiettivo.cuteEAnnessi.lesioneSedi"}
                            />}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI LESIONI'}
                            appendRolePrefix={true}
                            fieldsSuffix={'LESIO'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormCuteEAnnessi("lesioneCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.lesioneCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormCuteEAnnessi("lesioneDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.lesioneDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'lesione'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormCuteEAnnessi("lesionePropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.lesionePropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.lesione}
                        />
                    </Box>
                }

                { /* Trattamenti Lesioni Trofiche */
                    <Box key={"boxTrattamentiLesioniTrofiche" + nomeAccordion}
                         titleBox={"Trattamenti lesioni trofiche"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'TRATTAM_LES_TROFICHE.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                id={"trattamentiLesioniTrofiche"}
                                className={"trattamentiLesioniTrofiche"}
                                options={OggettiUtili.alterazioniLettura}
                                value={this.state.datiTrattamentiLesioniTrofiche.trattamentiLesioniTrofiche}
                                onChange={(elem) => this.handleAggiornaFormTrattamentiLesioniTrofiche("trattamentiLesioniTrofiche", elem)}
                                isSearchable={false}
                                fieldLabel={"Trattamenti lesioni trofiche"}
                                field={"trattamentiLesioniTrofiche"}
                                handleOnlyValue={true}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                        </FieldsRow>

                        {this.state.datiTrattamentiLesioniTrofiche?.trattamentiLesioniTrofiche === "01" &&
                            <>
                                <FieldsRow>
                                    <RoleBasedInput
                                        fieldId={'PRESS_SIST_CAVIGL.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                        pageState={this.props.pageState}
                                        value={this.state.datiTrattamentiLesioniTrofiche.pressioneSistolicaCaviglia}
                                        placeholder="Inserisci valore"
                                        field="pressioneSistolicaCaviglia"
                                        onChange={(field, elem) => this.handleAggiornaFormTrattamentiLesioniTrofiche(field, elem.target.value)}
                                        type={"number"}
                                        min={0}
                                        fieldLabel={'Pressione sistolica caviglia'}
                                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    />

                                    <RoleBasedInput
                                        fieldId={'PRESS_SIST_BRACCIO_OMO.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                        pageState={this.props.pageState}
                                        value={this.state.datiTrattamentiLesioniTrofiche.pressioneSistolicaBraccioOmolaterale}
                                        placeholder="Inserisci valore"
                                        field="pressioneSistolicaBraccioOmolaterale"
                                        onChange={(field, elem) => this.handleAggiornaFormTrattamentiLesioniTrofiche(field, elem.target.value)}
                                        type={"number"}
                                        min={0}
                                        fieldLabel={'Pressione sistolica braccio omolaterale'}
                                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    />
                                </FieldsRow>

                                <FieldsRow>
                                    <RoleBasedText
                                        fieldId={'WINSOR_ABI.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                        pageState={this.props.pageState}
                                        value={this.state.datiTrattamentiLesioniTrofiche.indiceDiWinsor ?? '-'}
                                        placeholder={"Inserisci indice di Winsor"}
                                        field={"indiceDiWinsor"}
                                        id={"indiceDiWinsor"}
                                        type={"number"}
                                        fieldLabel={'Indice di Winsor (ABI)'}
                                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    />

                                    <RoleBasedText
                                        fieldId={'INTERPR_WINSOR_ABI.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                        pageState={this.props.pageState}
                                        value={this.state.datiTrattamentiLesioniTrofiche.interpretazioneIndiceDiWinsor ?? '-'}
                                        placeholder={"Interpretazione indice di Winsor (ABI)"}
                                        field={"interpretazioneIndiceDiWinsor"}
                                        fieldLabel={'Interpretazione indice di Winsor (ABI)'}
                                        labelAlert={this.getLabelAlert(this.state.datiTrattamentiLesioniTrofiche.indiceDiWinsor)}
                                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    />
                                </FieldsRow>

                                <SectionList
                                    title={'Sede'}
                                    data={this.state.datiTrattamentiLesioniTrofiche.sede}
                                    renderSection={this.renderSedeTrattamentiLesioniTrofiche}
                                    addNewSectionCallback={() => this.addNewSedeTrattamentiLesioniTrofiche()}
                                    removeSectionCallback={(index) => this.removeSedeTrattamentiLesioniTrofiche(index)}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    addButtonVisibilityHandler={this.state.datiTrattamentiLesioniTrofiche.sede?.length < 2}
                                    pageState={this.props.pageState}
                                    keyFieldId={'SEDE_LES_TROFICHE.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                    indentOffset={this.props.indentOffset}
                                    field={'sede'}
                                    parentJsonPath={"informazioniDiBase.datiTrattamentiLesioniTrofiche.sede"}
                                />

                                <SectionList
                                    title={'Trattamenti ulteriori'}
                                    data={this.state.datiTrattamentiLesioniTrofiche.trattamentiUlteriori}
                                    renderSection={this.renderTrattamentiUlteriori}
                                    addNewSectionCallback={() => this.addNewTrattamento()}
                                    removeSectionCallback={(index) => this.removeTrattamento(index)}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    addButtonVisibilityHandler={this.state.datiTrattamentiLesioniTrofiche.trattamentiUlteriori?.length === 0 || this.state.datiTrattamentiLesioniTrofiche.trattamentiUlteriori?.every(el => !Utils.isObjectNull(el?.trattamentiUlteriori))}
                                    pageState={this.props.pageState}
                                    keyFieldId={'TRATTAM_ULTERIORI.TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                    field={'trattamentiUlteriori'}
                                    parentJsonPath={"informazioniDiBase.datiTrattamentiLesioniTrofiche.trattamentiUlteriori"}
                                />

                                <CondizioneRilevataSection
                                    ambito={"TRATTAMENTI LESIONI TROFICHE"}
                                    pageState={this.props.pageState}
                                    parentFieldId={'TRATTAMENTILESIONITROFICHE.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormTrattamentiLesioniTrofiche("condizioneRilevata", elem)}
                                    valueCondizioneRilevata={this.state.datiTrattamentiLesioniTrofiche.condizioneRilevata}
                                    onChangeDaAttenzionare={(elem) => this.handleAggiornaFormTrattamentiLesioniTrofiche("daAttenzionare", elem)}
                                    valueDaAttenzionare={this.state.datiTrattamentiLesioniTrofiche.daAttenzionare}
                                    showSuggerimenti={true}
                                    onChangeSuggerimenti={this.handleAggiornaFormTrattamentiLesioniTrofiche}
                                    valueSuggerimenti={this.state.datiTrattamentiLesioniTrofiche.propostaAttivitaSuggerimenti}
                                    showAutonomia={true}
                                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaFormTrattamentiLesioniTrofiche("autonomiaGestionePaziente", elem)}
                                    valueAutonomiaPaziente={this.state.datiTrattamentiLesioniTrofiche.autonomiaGestionePaziente}
                                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaFormTrattamentiLesioniTrofiche("autonomiaGestioneCaregiver", elem)}
                                    valueAutonomiaCaregiver={this.state.datiTrattamentiLesioniTrofiche.autonomiaGestioneCaregiver}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                />
                            </>}
                    </Box>
                }

                { /* Ustioni */
                    <Box key={"boxUstioni" + nomeAccordion} titleBox={"Ustioni"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_UST.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_UST.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectUstioni"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.ustioni}
                                onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("ustioni", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Ustioni non esistente"}
                                fieldLabel={"Ustioni"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"ustioni"}
                            />
                        </FieldsRow>

                        {dati.ustioni &&
                            <SectionList
                                title={'Ustione'}
                                data={dati.listaUstioni}
                                renderSection={this.renderUstione}
                                addNewSectionCallback={this.addNewUstione}
                                removeSectionCallback={this.removeUstione}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                pageState={this.props.pageState}
                                keyFieldId={'M_GRADO_UST.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereKeyFieldId={'I_GRADO_UST.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                field={'listaUstioni'}
                                parentJsonPath={"esameObiettivo.cuteEAnnessi.listaUstioni"}
                            />}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI USTIONI'}
                            appendRolePrefix={true}
                            fieldsSuffix={'UST'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormCuteEAnnessi("ustioniCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.ustioniCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormCuteEAnnessi("ustioniDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.ustioniDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'ustioni'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormCuteEAnnessi("ustioniPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.ustioniPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.ustioni}
                        />
                    </Box>
                }

                { /* Dermatiti */
                    <Box key={"boxDermatiti" + nomeAccordion} titleBox={"Dermatiti/Micosi/Psoriasi/Parassitosi"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectDermatiti"}
                                options={OggettiUtili.rispostaBreve}
                                value={dati.dermatiti}
                                onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("dermatiti", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Dermatiti chirurgiche non esistente"}
                                fieldLabel={"Dermatiti/Micosi/Psoriasi/Parassitosi"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                field={"dermatiti"}
                                handleOnlyValue={true}
                            />
                        </FieldsRow>

                        {dati.dermatiti && <>
                            <SectionList
                                title={'Dermatite'}
                                data={dati.listaDermatiti}
                                renderSection={this.renderDermatite}
                                addNewSectionCallback={this.addNewDermatite}
                                removeSectionCallback={this.removeDermatite}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                pageState={this.props.pageState}
                                keyFieldId={'M_TIPO_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereKeyFieldId={'I_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                field={'listaDermatiti'}
                                parentJsonPath={"esameObiettivo.cuteEAnnessi.listaDermatiti"}
                            />

                            <FieldsRow>
                                <RoleBasedSelect
                                    fieldId={'M_PRUR_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                    infermiereFieldId={'I_PRUR_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                    pageState={this.props.pageState}
                                    id={"selectDermatitePrurito"}
                                    options={OggettiUtili.rispostaBreve}
                                    value={dati.dermatitiPrurito}
                                    onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("dermatitiPrurito", elem)}
                                    isSearchable={false}
                                    noOptionsMessage={() => "Errore: Prurito non esistente"}
                                    fieldLabel={"Prurito"}
                                    forceReadOnly={this.props.forceReadOnly}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                    hideUnsetValues={this.props.hideUnsetValues}
                                    handleOnlyValue={true}
                                    field={"dermatitiPrurito"}
                                />
                                {dati.dermatitiPrurito &&
                                    <RoleBasedSelect
                                        fieldId={'M_FREQ_DER_MI_PS_PA.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                        infermiereFieldId={'I_FREQ_DER_MI_PS_PA.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                        pageState={this.props.pageState}
                                        id={"selectDermatiteFrequenza"}
                                        options={OggettiUtili.dermatitiFrequenza}
                                        value={dati.dermatitiFrequenza}
                                        onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("dermatitiFrequenza", elem)}
                                        isSearchable={false}
                                        noOptionsMessage={() => "Errore: Frequenza non esistente"}
                                        fieldLabel={"Frequenza"}
                                        forceReadOnly={this.props.forceReadOnly}
                                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                        disableDoubleInput={this.props.disableDoubleInput}
                                        hideUnsetValues={this.props.hideUnsetValues}
                                        handleOnlyValue={true}
                                        field={"dermatitiFrequenza"}
                                    />}
                            </FieldsRow>
                        </>}

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI DERMATITI/MICOSI/PSORIASI/PARASSITOSI'}
                            appendRolePrefix={true}
                            fieldsSuffix={'MI_PS_PA'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormCuteEAnnessi("dermatitiCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.dermatitiCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormCuteEAnnessi("dermatitiDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.dermatitiDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'dermatiti'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormCuteEAnnessi("dermatitiPropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.dermatitiPropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.dermatiti}
                        />
                    </Box>
                }

                { /* Igiene */
                    <Box key={"boxIgiene" + nomeAccordion} titleBox={"Igiene"}>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'M_IGIENE.CUTEEANNESSI.ESAMEOBIETTIVO'}
                                infermiereFieldId={'I_IGIENE.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                                pageState={this.props.pageState}
                                id={"selectIgiene"}
                                options={OggettiUtili.igiene}
                                value={dati.igiene}
                                onChange={(elem) => this.handleAggiornaFormCuteEAnnessi("igiene", elem)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Igiene non esistente"}
                                fieldLabel={"Igiene"}
                                forceReadOnly={this.props.forceReadOnly}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                                hideUnsetValues={this.props.hideUnsetValues}
                                handleOnlyValue={true}
                                field={"igiene"}
                            />
                        </FieldsRow>

                        <DispositiviPresenti
                            handleArrayChange={this.handleDispositiviArrayChange}
                            dispositiviPresenti={dati.listaDispositiviIgiene}
                            pageState={this.props.pageState}
                            fieldId={'M_DISP_IG.CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infermiereFieldId={'I_DISP_IG.VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            field={"listaDispositiviIgiene"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            parentJsonPath={"esameObiettivo.cuteEAnnessi.listaDispositiviIgiene"}
                        />

                        <CondizioneRilevataSection
                            ambito={'CUTE E ANNESSI IGIENE'}
                            appendRolePrefix={true}
                            fieldsSuffix={'IG'}
                            parentFieldId={'CUTEEANNESSI.ESAMEOBIETTIVO'}
                            infParentFieldId={'VALUTAZIONEDELLACUTE.FUNZIONETEGUMENTARIA'}
                            pageState={this.props.pageState}
                            onChangeCondizioneRilevata={(elem) => this.handleAggiornaFormCuteEAnnessi("igieneCondizioneRilevata", elem)}
                            valueCondizioneRilevata={dati.igieneCondizioneRilevata}
                            onChangeDaAttenzionare={(elem) => this.handleAggiornaFormCuteEAnnessi("igieneDaAttenzionare", elem)}
                            valueDaAttenzionare={dati.igieneDaAttenzionare}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                            fieldJsonPathPrefix={'igiene'}
                            onChangeSuggerimenti={(field, elem) => this.handleAggiornaFormCuteEAnnessi("igienePropostaAttivitaSuggerimenti", elem)}
                            valueSuggerimenti={dati.igienePropostaAttivitaSuggerimenti}
                            showSuggerimenti={true}
                            openCondition={dati.igiene === "02"}
                        />
                    </Box>}
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"valutazioneCute"}
                title={"Valutazione della cute"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["esameObiettivo.cuteEAnnessi.lesioneSedi", "esameObiettivo.cuteEAnnessi.lesioneSedi.lesioni",
                        "esameObiettivo.cuteEAnnessi.lesioneSedi.lesioni.caratteristicheMorfologiche",
                        "esameObiettivo.cuteEAnnessi.lesioneSedi.lesioni.bordi", "esameObiettivo.cuteEAnnessi.listaUstioni",
                        "esameObiettivo.cuteEAnnessi.listaDermatiti", "esameObiettivo.cuteEAnnessi.listaDispositiviIgiene",
                        "esameObiettivo.cuteEAnnessi.propostaAttivitaSuggerimenti",
                        "informazioniDiBase.datiTrattamentiLesioniTrofiche.sede",
                        "informazioniDiBase.datiTrattamentiLesioniTrofiche.sede.tipologiaMedicazioneApplicata",
                        "informazioniDiBase.datiTrattamentiLesioniTrofiche.trattamentiUlteriori",
                        "informazioniDiBase.datiTrattamentiLesioniTrofiche.propostaAttivitaSuggerimenti"],
                    [defaultSede, defaultLesione, defaultCaratteristicheMorfologiche, defaultBordi,
                        defaultUstione, defaultDermatiti, defaultDispositiviPresenti,
                        defaultProposteAttivitaSuggerimenti, defaultSedeTrattamentiLesioniTrofiche,
                        defaultTipologiaMedicazioneApplicata, defaultiTrattamentiUlteriori,
                        defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }
}
ValutazioneDellaCute.propTypes = propTypes;

ValutazioneDellaCute.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}
