import React, {Component} from "react";
import PropTypes from 'prop-types';
import Select from "react-select";
import {DropdownIndicator, inputSelectStyles} from "../../common/select/SelectUtils";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import * as $ from "jquery";
import {Button, Input} from "web-client-archetype";
import styles from "./FiltroStoricoRichieste.module.css";
import ModalHelper from "../../modale/ModalHelper";
import Calendar from "../../generalComponents/calendar/Calendar";
import Utils from "../../../utils/Utils";
import * as _ from "lodash";
import RichiestaADIService from "../../../service/RichiestaADIService";
import AuthUtils from "../../../utils/AuthUtils";
import FiltriUtils from "../../../utils/FiltriUtils";
import {Col, Container, span, Row} from "reactstrap";
import HomeUtils from "../../home/HomeUtils";
import * as Icon from "react-bootstrap-icons";

const propTypes = {
    onChange: PropTypes.func,
    filtri: PropTypes.object,
    impostaFiltri: PropTypes.func
};

const defaultFiltriRichiesta = {
    codiceFiscale: "",
    codiceFiscaleFormalizzatore: "",
    codiceFiscaleFirmatario:"",
    dataRichiestaDa: "",
    dataRichiestaA: "",
    dataValutazioneDa: "",
    dataValutazioneA: "",
    dataValutazioneProfessionaleDa: "",
    dataValutazioneProfessionaleA: "",
    dataFormalizzazioneDa: "",
    dataFormalizzazioneA: "",
    dataInizioCureDomiciliariDa: "",
    dataInizioCureDomiciliariA: "",
    dataFineCureDomiciliariDa: "",
    dataFineCureDomiciliariA: "",
    dataAnnullamentoRichiestaDa: "",
    dataAnnullamentoRichiestaA: "",
    statoValidazioneMultidimensionale: "",
    statoValutazioneProfessionale: "",
    statoRichiesta: "",
    statoCureDomiciliari: "",
    statoSegnalazione: "",
    dataSegnalazioneDa: "",
    dataSegnalazioneA: ""
}
const defaultFiltriAssistito = {
    cognome: "",
    nome: ""
}
const maxSelectHeight = 80;

export default class FiltroStoricoRichieste extends Component {

    state = {
        filtriAssistito: _.cloneDeep(defaultFiltriAssistito),
        filtriRichiesta: _.cloneDeep(defaultFiltriRichiesta),
        mediciRichiedenti: "",
        mediciFormalizzatori: "",
        mediciFirmatari:"",
        showModale: false,
        disabledApplicaAnnullaFiltri: true,
        disabledApplicaFiltri: false,
        dataRichiestaDa: "",
        dataRichiestaA: "",
        dataValutazioneDa: "",
        dataValutazioneA: "",
        dataValutazioneProfessionaleDa: "",
        dataValutazioneProfessionaleA: "",
    };


    constructor(props) {
        super(props);
        this.handleAggiornaFiltriRichiesta = this.handleAggiornaFiltriRichiesta.bind(this);
        this.applicaFiltriRichiesta = this.applicaFiltriRichiesta.bind(this);
        this.pulisciFiltriStoricoRichieste = this.pulisciFiltriStoricoRichieste.bind(this);
        this.disableEnableData = this.disableEnableData.bind(this);
    }

    componentDidMount() {
        this.impostaMediciRichiedenti();
        this.impostaMediciFormalizzatori();
        this.impostaMediciFirmatari();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.filtriAssistito !== this.state.filtriAssistito || prevState.filtriRichiesta !== this.state.filtriRichiesta) && (Object.values(this.state.filtriAssistito).filter(el => el !== "").length === 0 || Object.values(this.state.filtriRichiesta).filter(el => el !== "").length === 0))
            this.setState({ disabledApplicaAnnullaFiltri: true })
        if ((prevState.filtriAssistito !== this.state.filtriAssistito || prevState.filtriRichiesta !== this.state.filtriRichiesta) && (Object.values(this.state.filtriAssistito).filter(el => el !== "").length > 0 || Object.values(this.state.filtriRichiesta).filter(el => el !== "").length > 0))
            this.setState({ disabledApplicaAnnullaFiltri: false })

        // Ogni volta che cambiano i filtriRichiesta vengono fatti i controlli sulle date
        if ((prevState.filtriRichiesta !== this.state.filtriRichiesta)) {
            this.checkDate();
        }

        // Appena viene aperta la modale dei filtri viene lanciata la funzione mapFiltri
        if (prevState.showModale !== this.state.showModale && this.state.showModale && !Utils.isObjectEmpty(this.props.filtri.filtriRichiesta) && !Utils.isObjectEmpty(this.props.filtri.filtriAssistito))
            this.mapFiltri();
    }

    isDataAPrecedenteDataDa = (dataA, dataDa) => {
        if (dataA && dataDa && Utils.checkDateInterval(Utils.convertDDMMYYYYDateToIsoDate(dataA, "23:59"), Utils.convertDDMMYYYYDateToIsoDate(dataDa))) {
            return true;
        }
    }

    impostaMediciRichiedenti = () => {
        let mediciRichiedenti = _.cloneDeep(this.state.mediciRichiedenti);
        let params = {};
        params.filtro = FiltriUtils.getFiltriStoricoRichieste();
        RichiestaADIService.getMediciRichiedenti(params).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "critical",
                    text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                });
            } else {
                mediciRichiedenti = HomeUtils.estraiMediciRichiedenti(response);
                this.setState({ mediciRichiedenti })
            }
        })
    }

    impostaMediciFormalizzatori = () => {
        let mediciFormalizzatori = _.cloneDeep(this.state.mediciFormalizzatori);
        let params = {};
        params.filtro = FiltriUtils.getFiltriStoricoRichieste();
        RichiestaADIService.getMediciFormalizzatori(params).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "critical",
                    text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                });
            } else {
                mediciFormalizzatori = HomeUtils.estraiMediciRichiedenti(response);
                this.setState({ mediciFormalizzatori })
            }
        })
    }

    impostaMediciFirmatari = () => {
        let mediciFirmatari = _.cloneDeep(this.state.mediciFirmatari);
        let params = {};
        params.filtro = FiltriUtils.getFiltriStoricoRichieste();
        RichiestaADIService.getMediciFirmatari(params).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "critical",
                    text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                });
            } else {
                mediciFirmatari = HomeUtils.estraiMediciRichiedenti(response);
                this.setState({ mediciFirmatari })
            }
        })
    }

    handleAggiornaFiltriRichiesta(field, event) {
        let state = _.cloneDeep(this.state);
        let filtriAssistito = state.filtriAssistito;
        let filtriRichiesta = state.filtriRichiesta;

        if (field.currentTarget) {
            state[field.currentTarget.id] = field.currentTarget.value;
            filtriRichiesta[field.currentTarget.id] = field.currentTarget.value;
        }
        else if (field in filtriAssistito) {
            filtriAssistito[field] = event.target.value;
        }
        else {
            filtriRichiesta[field] = event.target.value;
        }

        state.disabledApplicaAnnullaFiltri = HomeUtils.disabledApplicaAnnullaFiltri(filtriAssistito, filtriRichiesta);
        this.setState(state);
    }

    applicaFiltriRichiesta() {
        let state = _.cloneDeep(this.state);
        let filtro = this.state.filtriRichiesta

        //Data richiesta
        if (state.filtriRichiesta.dataRichiestaDa) {
            filtro.dataRichiestaDa = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataRichiestaDa));
        }
        if (state.filtriRichiesta.dataRichiestaA) {
            filtro.dataRichiestaA = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataRichiestaA, true));
        }

        //Data valutazione professionale
        if (state.filtriRichiesta.dataValutazioneProfessionaleDa) {
            filtro.dataValutazioneProfessionaleDa = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataValutazioneProfessionaleDa));
        }
        if (state.filtriRichiesta.dataValutazioneProfessionaleA) {
            filtro.dataValutazioneProfessionaleA = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataValutazioneProfessionaleA, true));
        }

        //Data valutazione
        if (state.filtriRichiesta.dataValutazioneDa) {
            filtro.dataValutazioneDa = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataValutazioneDa));
        }
        if (state.filtriRichiesta.dataValutazioneA) {
            filtro.dataValutazioneA = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataValutazioneA, true));
        }

        //Data formalizzazione
        if (state.filtriRichiesta.dataFormalizzazioneDa) {
            filtro.dataFormalizzazioneDa = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataFormalizzazioneDa));
        }
        if (state.filtriRichiesta.dataFormalizzazioneA) {
            filtro.dataFormalizzazioneA = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataFormalizzazioneA, true));
        }

        //Data inizio cure domiciliari
        if (state.filtriRichiesta.dataInizioCureDomiciliariDa) {
            filtro.dataInizioCureDomiciliariDa = Utils.getMomentLocalDate(state.filtriRichiesta.dataInizioCureDomiciliariDa).format("DD/MM/YYYY");
        }
        if (state.filtriRichiesta.dataInizioCureDomiciliariA) {
            filtro.dataInizioCureDomiciliariA = Utils.getMomentLocalDate(state.filtriRichiesta.dataInizioCureDomiciliariA).format("DD/MM/YYYY");
        }

        //Data fine cure domiciliari
        if (state.filtriRichiesta.dataFineCureDomiciliariDa) {
            filtro.dataFineCureDomiciliariDa = Utils.getMomentLocalDate(state.filtriRichiesta.dataFineCureDomiciliariDa).format("DD/MM/YYYY");
        }
        if (state.filtriRichiesta.dataFineCureDomiciliariA) {
            filtro.dataFineCureDomiciliariA = Utils.getMomentLocalDate(state.filtriRichiesta.dataFineCureDomiciliariA).format("DD/MM/YYYY");
        }

        //Data annullamento richiesta
        if (state.filtriRichiesta.dataAnnullamentoRichiestaDa) {
            filtro.dataAnnullamentoRichiestaDa = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataAnnullamentoRichiestaDa));
        }
        if (state.filtriRichiesta.dataAnnullamentoRichiestaA) {
            filtro.dataAnnullamentoRichiestaA = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataAnnullamentoRichiestaA, true));
        }

        //Data segnalazione
        if (state.filtriRichiesta.dataSegnalazioneDa) {
            filtro.dataSegnalazioneDa = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataSegnalazioneDa));
        }
        if (state.filtriRichiesta.dataSegnalazioneA) {
            filtro.dataSegnalazioneA = Utils.convertLocalToUtcDate(Utils.getMomentLocalDate(state.filtriRichiesta.dataSegnalazioneA, true));
        }

        this.setState({ showModale: false, filtriRichiesta: filtro }, () => {
            this.props.impostaFiltri(this.state.filtriAssistito, this.state.filtriRichiesta)
        })

        this.chiudiModale();
    }

    pulisciFiltriStoricoRichieste() {
        this.setState({
            filtriAssistito: _.cloneDeep(defaultFiltriAssistito),
            filtriRichiesta: _.cloneDeep(defaultFiltriRichiesta), dataRichiestaDa: "",
            dataRichiestaA: "",
            dataValutazioneDa: "",
            dataValutazioneA: "",
            dataValutazioneProfessionaleDa: "",
            dataValutazioneProfessionaleA: "", disabledApplicaAnnullaFiltri: true
        });
    }

    chiudiModale() {
        this.setState({ showModale: false })
        $(".modal-backdrop").css("display", "none");
        $(".modal-open").removeClass("modal-open");
        $("body").attr("style", "padding-right: 0 !important");
    }

    mapFiltri = () => {
        let cloneFiltriRichiesta = _.cloneDeep(!Utils.isObjectEmpty(this.props.filtri.filtriRichiesta) ? this.props.filtri.filtriRichiesta : defaultFiltriRichiesta);
        let cloneFiltriAssistito = _.cloneDeep(!Utils.isObjectEmpty(this.props.filtri.filtriAssistito) ? this.props.filtri.filtriAssistito : defaultFiltriAssistito);

        for (var filtro in cloneFiltriRichiesta) {
            if (filtro.startsWith("data") && !Utils.isStringEmptyOrNullOrUndefined(cloneFiltriRichiesta[filtro]) && !filtro.includes("CureDomiciliari")) {
                cloneFiltriRichiesta[filtro] = Utils.convertUtcToLocalDate(cloneFiltriRichiesta[filtro]).format("DD/MM/YYYY");
            }
        }

        this.setState({ filtriRichiesta: cloneFiltriRichiesta })
        this.setState({ filtriAssistito: cloneFiltriAssistito })
    }

    checkStatoRichiesta = () => {
        let filtriRichiesta = _.cloneDeep(this.state.filtriRichiesta);

        if (!Utils.isObjectEmpty(filtriRichiesta.statoRichiesta) && filtriRichiesta.statoRichiesta.value === "02") {
            let filtriRichiesta = _.cloneDeep(filtriRichiesta);

            filtriRichiesta.dataRichiestaDa = "";
            filtriRichiesta.dataRichiestaA = "";

            this.setState({ filtriRichiesta: filtriRichiesta })
            this.setState({ dataRichiestaDa: "", dataRichiestaA: "" })
        }

        if (!Utils.isObjectEmpty(filtriRichiesta.statoValidazioneMultidimensionale) && filtriRichiesta.statoValidazioneMultidimensionale.value === "02") {
            let filtriRichiesta = _.cloneDeep(filtriRichiesta);

            filtriRichiesta.dataValutazioneDa = "";
            filtriRichiesta.dataValutazioneA = "";

            this.setState({ filtriRichiesta: filtriRichiesta })
            this.setState({ dataValutazioneDa: "", dataValutazioneA: "" })
        }

        if (!Utils.isObjectEmpty(filtriRichiesta.statoValutazioneProfessionale) && filtriRichiesta.statoValutazioneProfessionale.value === "02") {
            let filtriRichiesta = _.cloneDeep(filtriRichiesta);

            filtriRichiesta.dataValutazioneProfessionaleDa = "";
            filtriRichiesta.dataValutazioneProfessionaleA = "";

            this.setState({ filtriRichiesta: filtriRichiesta })
            this.setState({ dataValutazioneProfessionaleDa: "", dataValutazioneProfessionaleA: "" })
        }
    }

    //Controlli sulle date
    checkDate = () => {
        if (
            this.isDataMaggioreDataCorrente("dataRichiestaDa") ||
            this.isDataMaggioreDataCorrente("dataRichiestaA") ||
            this.isDataMaggioreDataCorrente("dataValutazioneProfessionaleDa") ||
            this.isDataMaggioreDataCorrente("dataValutazioneProfessionaleA") ||
            this.isDataMaggioreDataCorrente("dataValutazioneDa") ||
            this.isDataMaggioreDataCorrente("dataValutazioneA") ||
            this.isDataMaggioreDataCorrente("dataFormalizzazioneDa") ||
            this.isDataMaggioreDataCorrente("dataFormalizzazioneA") ||
            this.isDataMaggioreDataCorrente("dataAnnullamentoRichiestaDa") ||
            this.isDataMaggioreDataCorrente("dataAnnullamentoRichiestaA") ||

            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataRichiestaA") ||
            this.isDataDaMaggioreDataA("dataValutazioneDa", "dataValutazioneA") ||
            this.isDataDaMaggioreDataA("dataValutazioneProfessionaleDa", "dataValutazioneProfessionaleA") ||
            this.isDataDaMaggioreDataA("dataFormalizzazioneDa", "dataFormalizzazioneA") ||
            this.isDataDaMaggioreDataA("dataInizioCureDomiciliariDa", "dataInizioCureDomiciliariA") ||
            this.isDataDaMaggioreDataA("dataFineCureDomiciliariDa", "dataFineCureDomiciliariA") ||
            this.isDataDaMaggioreDataA("dataAnnullamentoRichiestaDa", "dataAnnullamentoRichiestaA") ||
            this.isDataDaMaggioreDataA("dataSegnalazioneDa", "dataSegnalazioneA") ||

            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataValutazioneProfessionaleA") ||
            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataValutazioneA") ||
            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataValutazioneDa") ||
            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataValutazioneProfessionaleDa") ||
            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataFormalizzazioneA") ||
            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataFormalizzazioneDa") ||
            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataAnnullamentoRichiestaA") ||
            this.isDataDaMaggioreDataA("dataRichiestaDa", "dataAnnullamentoRichiestaDa") ||

            this.isDataDaMaggioreDataA("dataInizioCureDomiciliariDa", "dataFineCureDomiciliariDa") ||
            this.isDataDaMaggioreDataA("dataFormalizzazioneDa", "dataInizioCureDomiciliariDa", false) ||
            this.isDataDaMaggioreDataA("dataSegnalazioneDa", "dataRichiestaDa")
        ) {
            this.disabilitaApplicaFiltri();
        }
        else {
            this.abilitaApplicaFiltri();
        }
    }

    isDataMaggioreDataCorrente = (fieldData) => {
        let state = _.cloneDeep(this.state);

        if ((Utils.timeDifference(Date.now(), new Date(Utils.convertDDMMYYYYDateToIsoDate(state.filtriRichiesta[fieldData])), true) < 0 && !Utils.isStringEmptyOrNullOrUndefined(state.filtriRichiesta[fieldData]))) {
            return true;
        }
        else {
            return false;
        }
    }

    isDataDaMaggioreDataA = (fieldDataDa, fieldDataA, minoreUguale = true) => {
        let state = _.cloneDeep(this.state);

        if ((!Utils.isStringEmptyOrNullOrUndefined(state.filtriRichiesta[fieldDataDa]) &&
            !Utils.isStringEmptyOrNullOrUndefined(state.filtriRichiesta[fieldDataA]) &&
            !Utils.checkDateIntervalMinore(state.filtriRichiesta[fieldDataDa], state.filtriRichiesta[fieldDataA], minoreUguale))) {
            return true;
        }
        else {
            return false;
        }
    }


    //Gestione errori sulle date
    getDataError = (context) => {
        let erroreDataSuccessivaDataOdierna = "Non deve essere successiva alla data odierna";
        let erroreDataDaSuccessivaDataA = "Non deve essere successiva alla Data A";
        let erroreDataAPrecedenteDataDa = "Non deve essere precedente alla Data Da";
        let erroreDataPrecedenteAllaDataRichiestaDa = "Non deve essere precedente alla Data Richiesta Da";
        let erroreDataFineCureDomiciliariPrecedenteDataInizioCureDomiciliari = "Non deve essere precedente alla data inizio cure domiciliari da";
        let erroreDataCureDomiciliariPrecedenteUgualeDataFormalizzazione = "Non deve essere precedente o uguale alla data formalizzazione da";
        let erroreDataPrecedenteAllaDataSegnalazioneDa = "Non deve essere precedente alla Data Segnalazione Da";

        let error = {
            messaggio: "",
            valore: false
        };

        switch (context) {
            case "dataRichiestaDa":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA(context, "dataRichiestaA")) {
                    error.messaggio = erroreDataDaSuccessivaDataA;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataSegnalazioneDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataSegnalazioneDa;
                    error.valore = true;
                }
                // else if (this.isDataDaMaggioreDataA(context, "dataValutazioneProfessionaleDa") ||
                //     this.isDataDaMaggioreDataA(context, "dataValutazioneDa") ||
                //     this.isDataDaMaggioreDataA(context, "dataValutazioneA") ||
                //     this.isDataDaMaggioreDataA(context, "dataValutazioneProfessionaleA")) {
                //     error.valore = true;
                // }
                return error;
            case "dataRichiestaA":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                // else if (this.isDataDaMaggioreDataA("dataValutazioneProfessionaleDa", context) ||
                //     this.isDataDaMaggioreDataA("dataValutazioneDa", context) ||
                //     this.isDataDaMaggioreDataA("dataValutazioneProfessionaleA", context) ||
                //     this.isDataDaMaggioreDataA("dataValutazioneA", context)) {
                //     error.valore = true;
                // }
                return error;
            case "dataValutazioneProfessionaleDa":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA(context, "dataValutazioneProfessionaleA")) {
                    error.messaggio = erroreDataDaSuccessivaDataA;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataRichiestaDa;
                    error.valore = true;
                }
                return error;
            case "dataValutazioneProfessionaleA":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataValutazioneProfessionaleDa", context)) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataRichiestaDa;
                    error.valore = true;
                }
                return error;
            case "dataValutazioneDa":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA(context, "dataValutazioneA")) {
                    error.messaggio = erroreDataDaSuccessivaDataA;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataRichiestaDa;
                    error.valore = true;
                }
                return error;
            case "dataValutazioneA":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataValutazioneDa", context)) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataRichiestaDa;
                    error.valore = true;
                }
                return error;
            case "dataFormalizzazioneDa":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA(context, "dataFormalizzazioneA")) {
                    error.messaggio = erroreDataDaSuccessivaDataA;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataRichiestaDa;
                    error.valore = true;
                }
                return error;
            case "dataFormalizzazioneA":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataFormalizzazioneDa", context)) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataRichiestaDa;
                    error.valore = true;
                }
                return error;
            case "dataInizioCureDomiciliariDa":
                if (this.isDataDaMaggioreDataA(context, "dataInizioCureDomiciliariA")) {
                    error.messaggio = erroreDataDaSuccessivaDataA;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataFormalizzazioneDa", context, false)) {
                    error.messaggio = erroreDataCureDomiciliariPrecedenteUgualeDataFormalizzazione;
                    error.valore = true;
                }
                return error;
            case "dataInizioCureDomiciliariA":
                if (this.isDataDaMaggioreDataA("dataInizioCureDomiciliariDa", context)) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                return error;
            case "dataFineCureDomiciliariDa":
                if (this.isDataDaMaggioreDataA(context, "dataFineCureDomiciliariA")) {
                    error.messaggio = erroreDataDaSuccessivaDataA;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataInizioCureDomiciliariDa", context)) {
                    error.messaggio = erroreDataFineCureDomiciliariPrecedenteDataInizioCureDomiciliari;
                    error.valore = true;
                }
                return error;
            case "dataFineCureDomiciliariA":
                if (this.isDataDaMaggioreDataA("dataFineCureDomiciliariDa", context)) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                return error;
            case "dataAnnullamentoRichiestaDa":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA(context, "dataAnnullamentoRichiestaA")) {
                    error.messaggio = erroreDataDaSuccessivaDataA;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataRichiestaDa;
                    error.valore = true;
                }
                return error;
            case "dataAnnullamentoRichiestaA":
                if (this.isDataMaggioreDataCorrente(context)) {
                    error.messaggio = erroreDataSuccessivaDataOdierna;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataAnnullamentoRichiestaDa", context)) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                else if (this.isDataDaMaggioreDataA("dataRichiestaDa", context)) {
                    error.messaggio = erroreDataPrecedenteAllaDataRichiestaDa;
                    error.valore = true;
                }
                return error;
            case "dataSegnalazioneDa":
                if (this.isDataDaMaggioreDataA(context, "dataSegnalazioneA")) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                return error;
            case "dataSegnalazioneA":
                if (this.isDataDaMaggioreDataA("dataSegnalazioneDa", context)) {
                    error.messaggio = erroreDataAPrecedenteDataDa;
                    error.valore = true;
                }
                return error;
            default:
                return error;
        }
    }

    disableEnableData(dataDa, dataA, disable) {
        if (disable) {
            $(document).ready(function () {
                $("#" + dataDa).datepicker('disable');
                $("#" + dataA).datepicker('disable');
            })
            let newFiltro = this.state.filtriRichiesta
            newFiltro[dataDa] = ""
            newFiltro[dataA] = ""
            this.setState({ filtriRichiesta: newFiltro })
        } else {
            $(document).ready(function () {
                $("#" + dataDa).datepicker('enable');
                $("#" + dataA).datepicker('enable');
            })
        }

    }

    getNumeroFiltriApplicati = () => {
        let count = 0;

        for (var filtriAssistito in this.props.filtri.filtriAssistito) {
            if (!Utils.isStringEmptyOrNullOrUndefined(this.props.filtri.filtriAssistito[filtriAssistito])) count = count + 1;
        }

        for (var filtriRichiesta in this.props.filtri.filtriRichiesta) {
            if (!Utils.isStringEmptyOrNullOrUndefined(this.props.filtri.filtriRichiesta[filtriRichiesta])) count = count + 1;
        }

        let textFiltriApplicati = count > 1 ? "filtri applicati" : "filtro applicato";

        return count > 0 && <span className={"ml-3"}>{count} {textFiltriApplicati}</span>;
    }

    disabilitaApplicaFiltri = () => {
        this.setState({ disabledApplicaFiltri: true })
    }

    abilitaApplicaFiltri = () => {
        this.setState({ disabledApplicaFiltri: false })
    }

    render() {
        let filtriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
        let filtriAssistito = _.cloneDeep(this.state.filtriAssistito);
        const body = (
            <Container className="pr-3">
                <Row>
                    <Col xs={12}> 
                        <span  className="f-weight-700">Dati assistito</span>
                    </Col>
                </Row>
                <Row >
                    <Col xs={6}>
                        <span>&nbsp;</span>
                        <Input
                            parentClass={"mb-0"}
                            placeholder="Inserisci cognome utente"
                            field="cognome"
                            onChange={this.handleAggiornaFiltriRichiesta}
                            id="cognome"
                            label="Cognome assistito"
                            value={filtriAssistito.cognome}
                        />
                    </Col>
                    <Col xs={6}>
                        <span>&nbsp;</span>
                        <Input
                            parentClass={"mb-0"}
                            placeholder="Inserisci nome utente"
                            field="nome"
                            onChange={this.handleAggiornaFiltriRichiesta}
                            id="nome"
                            value={filtriAssistito.nome}
                            label="Nome assistito"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} className="mt-2">
                        <span  className="f-weight-700">Dati Segnalazione</span>
                    </Col>
                </Row>
                <Row >
                    {/* <Col xs={6}>
                        <span className="f-weight-500 m-0 ">Stato segnalazione</span>
                        <Select
                            id={"statoSegnalazione"}
                            className={"statoSegnalazione"}
                            classNamePrefix={"statoSegnalazione"}
                            components={DropdownIndicator}
                            options={OggettiUtili.statoRichiesta}
                            value={filtriRichiesta.statoSegnalazione}
                            onChange={(elem) => {
                                let newFiltriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
                                newFiltriRichiesta.statoSegnalazione = elem
                                this.setState({
                                    filtriRichiesta: newFiltriRichiesta,
                                    disabledApplicaAnnullaFiltri: false
                                })
                            }}
                            isSearchable={false}
                            styles={inputSelectStyles}
                            placeholder={"Seleziona"}
                            noOptionsMessage={() => "Errore: Nessuno stato disponibile"}
                            maxMenuHeight={maxSelectHeight}
                        />
                    </Col> */}
                    <Col xs={6}>
                        <span className="f-weight-500 m-0 ">Data Da</span>
                        <Calendar
                            id={"dataSegnalazioneDa"}
                            value={filtriRichiesta.dataSegnalazioneDa}
                            // disabled={filtriRichiesta.statoRichiesta ? filtriRichiesta.statoRichiesta.label === "Completata" : false}
                            field={"dataSegnalazioneDa"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            errorMessage={this.getDataError("dataSegnalazioneDa").messaggio}
                            error={this.getDataError("dataSegnalazioneDa").valore}
                        />
                    </Col>
                    <Col>
                        <span className="f-weight-500 m-0 ">Data A</span>
                        <Calendar
                            id={"dataSegnalazioneA"}
                            // disabled={filtriRichiesta.statoRichiesta ? filtriRichiesta.statoRichiesta.label === "Completata" : false}
                            value={filtriRichiesta.dataSegnalazioneA}
                            field={"dataSegnalazioneA"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            errorMessage={this.getDataError("dataSegnalazioneA").messaggio}
                            error={this.getDataError("dataSegnalazioneA").valore}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} className="mt-2">
                        <span  className="f-weight-700">Dati richiesta di attivazione di cure domiciliari</span>
                    </Col>
                </Row>
                <Row >
                    <Col xs={6}>
                        <span className="f-weight-500 m-0 ">Medico richiedente</span>
                        <Select
                            id={"mediciRichiedenti"}
                            className={"mediciRichiedenti"}
                            classNamePrefix={"mediciRichiedenti"}
                            components={DropdownIndicator}
                            options={this.state.mediciRichiedenti}
                            value={filtriRichiesta.codiceFiscale}
                            onChange={(elem) => {
                                let newFiltriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
                                newFiltriRichiesta.codiceFiscale = elem
                                this.setState({
                                    filtriRichiesta: newFiltriRichiesta,
                                    disabledApplicaAnnullaFiltri: false
                                })
                            }}
                            isSearchable={false}
                            styles={inputSelectStyles}
                            placeholder={"Seleziona"}
                            noOptionsMessage={() => "Errore: Nessun medico disponibile"}
                            maxMenuHeight={maxSelectHeight}
                        />
                    </Col>
                    <Col xs={3}>
                        <span className="f-weight-500 m-0 ">Data Da</span>
                        <Calendar
                            id={"dataRichiestaDa"}
                            value={filtriRichiesta.dataRichiestaDa}
                            disabled={filtriRichiesta.statoRichiesta ? filtriRichiesta.statoRichiesta.label === "Completata" : false}
                            field={"dataRichiestaDa"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            errorMessage={this.getDataError("dataRichiestaDa").messaggio}
                            error={this.getDataError("dataRichiestaDa").valore}
                        />
                    </Col>
                    <Col>
                        <span className="f-weight-500 m-0 ">Data A</span>
                        <Calendar
                            id={"dataRichiestaA"}
                            disabled={filtriRichiesta.statoRichiesta ? filtriRichiesta.statoRichiesta.label === "Completata" : false}
                            value={filtriRichiesta.dataRichiestaA}
                            field={"dataRichiestaA"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            errorMessage={this.getDataError("dataRichiestaA").messaggio}
                            error={this.getDataError("dataRichiestaA").valore}
                        />
                    </Col>
                </Row>
                {(AuthUtils.hasUtenteRuoloProfessionistaValutatore() || AuthUtils.hasUtenteRuoloCoordinatore()) &&
                    <>
                        <Row>
                            <Col xs={12} className="mt-2">
                                <span  className="f-weight-700">Dati valutazione professionale</span>
                            </Col>
                        </Row>
                        <Row >
                            <Col xs={6}>
                                <span className="f-weight-500 m-0 ">Data Da</span>
                                <Calendar
                                    id={"dataValutazioneProfessionaleDa"}
                                    value={filtriRichiesta.dataValutazioneProfessionaleDa}
                                    field={"dataValutazioneProfessionaleDa"}
                                    onChange={this.handleAggiornaFiltriRichiesta}
                                    disabled={filtriRichiesta.statoValutazioneProfessionale ? (filtriRichiesta.statoValutazioneProfessionale.value === "01" || filtriRichiesta.statoValutazioneProfessionale.value === "03" || filtriRichiesta.statoValutazioneProfessionale.value === "04") : false}
                                    errorMessage={this.getDataError("dataValutazioneProfessionaleDa").messaggio}
                                    error={this.getDataError("dataValutazioneProfessionaleDa").valore}
                                />
                            </Col>
                            <Col xs={6}>
                                <span className="f-weight-500 m-0 ">Data A</span>
                                <Calendar
                                    id={"dataValutazioneProfessionaleA"}
                                    value={filtriRichiesta.dataValutazioneProfessionaleA}
                                    disabled={filtriRichiesta.statoValutazioneProfessionale ? (filtriRichiesta.statoValutazioneProfessionale.value === "01" || filtriRichiesta.statoValutazioneProfessionale.value === "03" || filtriRichiesta.statoValutazioneProfessionale.value === "04") : false}
                                    field={"dataValutazioneProfessionaleA"}
                                    onChange={this.handleAggiornaFiltriRichiesta}
                                    errorMessage={this.getDataError("dataValutazioneProfessionaleA").messaggio}
                                    error={this.getDataError("dataValutazioneProfessionaleA").valore}
                                />
                            </Col>
                        </Row>
                    </>}
                <Row>
                    <Col xs={12} className="mt-2">
                        <span  className="f-weight-700">Dati valutazioni multidimensionale</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <span className={"f-weight-600"}>Data valutazione multidimensionale</span>
                    </Col>
                </Row>
                <Row >
                    <Col xs={6}>
                        <span className="f-weight-500 m-0 ">Data Da</span>
                        <Calendar
                            id={"dataValutazioneDa"}
                            value={filtriRichiesta.dataValutazioneDa}
                            field={"dataValutazioneDa"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            disabled={filtriRichiesta.statoValidazioneMultidimensionale ? (filtriRichiesta.statoValidazioneMultidimensionale.value === "01" || filtriRichiesta.statoValidazioneMultidimensionale.value === "03" || filtriRichiesta.statoValidazioneMultidimensionale.value === "04") : false}
                            errorMessage={this.getDataError("dataValutazioneDa").messaggio}
                            error={this.getDataError("dataValutazioneDa").valore}
                        />
                    </Col>
                    <Col xs={6}>
                        <span className="f-weight-500 m-0 ">Data A</span>
                        <Calendar
                            id={"dataValutazioneA"}
                            disabled={filtriRichiesta.statoValidazioneMultidimensionale ? (filtriRichiesta.statoValidazioneMultidimensionale.value === "01" || filtriRichiesta.statoValidazioneMultidimensionale.value === "03" || filtriRichiesta.statoValidazioneMultidimensionale.value === "04") : false}
                            value={filtriRichiesta.dataValutazioneA}
                            field={"dataValutazioneA"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            errorMessage={this.getDataError("dataValutazioneA").messaggio}
                            error={this.getDataError("dataValutazioneA").valore}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mt-2">
                        <span  className="f-weight-700">Dati formalizzazione</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <span className={"f-weight-600"}>Medico formalizzatore</span>
                    </Col>
                    <Col xs={6}>
                        <span className={"f-weight-600"}>Data formalizzazione</span>
                    </Col>
                </Row>

                <Row >
                    <Col xs={3}>
                        <span className="f-weight-500 m-0 ">Medico formalizzatore</span>
                        <Select
                            id={"mediciFormalizzatori"}
                            className={"mediciFormalizzatori"}
                            classNamePrefix={"mediciFormalizzatori"}
                            components={DropdownIndicator}
                            options={this.state.mediciFormalizzatori}
                            value={filtriRichiesta.codiceFiscaleFormalizzatore}
                            onChange={(elem) => {
                                let newFiltriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
                                newFiltriRichiesta.codiceFiscaleFormalizzatore = elem
                                this.setState({
                                    filtriRichiesta: newFiltriRichiesta,
                                    disabledApplicaAnnullaFiltri: false
                                })
                            }}
                            isSearchable={false}
                            styles={inputSelectStyles}
                            placeholder={"Seleziona"}
                            noOptionsMessage={() => "Errore: Nessun formalizzatore disponibile"}
                            maxMenuHeight={maxSelectHeight}
                        />
                    </Col>
                    <Col xs={3}>
                        <span className="f-weight-500 m-0 ">Medico Firmatario</span>
                        <Select
                            id={"mediciFirmatario"}
                            className={"mediciFirmatario"}
                            classNamePrefix={"mediciFirmatario"}
                            components={DropdownIndicator}
                            options={this.state.mediciFirmatari}
                            value={filtriRichiesta.codiceFiscaleFirmatario}
                            onChange={(elem) => {
                                let newFiltriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
                                newFiltriRichiesta.codiceFiscaleFirmatario = elem
                                this.setState({
                                    filtriRichiesta: newFiltriRichiesta,
                                    disabledApplicaAnnullaFiltri: false
                                })
                            }}
                            isSearchable={false}
                            styles={inputSelectStyles}
                            placeholder={"Seleziona"}
                            noOptionsMessage={() => "Errore: Nessun Firmatario disponibile"}
                            maxMenuHeight={maxSelectHeight}
                        />
                    </Col>
                    <Col xs={3}>
                        <span className="f-weight-500 m-0 ">Data Da</span>
                        <Calendar
                            id={"dataFormalizzazioneDa"}
                            value={filtriRichiesta.dataFormalizzazioneDa}
                            field={"dataFormalizzazioneDa"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            errorMessage={this.getDataError("dataFormalizzazioneDa").messaggio}
                            error={this.getDataError("dataFormalizzazioneDa").valore}
                        />
                    </Col>
                    <Col xs={3}>
                        <span className="f-weight-500 m-0 ">Data A</span>
                        <Calendar
                            id={"dataFormalizzazioneA"}
                            value={filtriRichiesta.dataFormalizzazioneA}
                            field={"dataFormalizzazioneA"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            errorMessage={this.getDataError("dataFormalizzazioneA").messaggio}
                            error={this.getDataError("dataFormalizzazioneA").valore}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} className="mt-2">
                        <span  className="f-weight-700">Dati cure domiciliari</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <span className={"f-weight-600"}>Stato cure domiciliari</span>
                    </Col>
                    <Col xs={4}>
                        <span className={"f-weight-600"}>Data inizio cure domiciliari</span>
                    </Col>
                    <Col xs={4}>
                        <span className={"f-weight-600"}>Data conclusione cure domiciliari</span>
                    </Col>
                </Row>
                <Row >
                    <Col xs={4}>
                        <span className="f-weight-500 m-0 ">Stato cure domiciliari</span>
                        <Select
                            id={"statoCureDomiciliari"}
                            className={"statoCureDomiciliari"}
                            classNamePrefix={"statoCureDomiciliari"}
                            components={DropdownIndicator}
                            options={OggettiUtili.statiCureDomiciliari}
                            value={filtriRichiesta.statoCureDomiciliari}
                            onChange={(elem) => {
                                let newFiltriRichiesta = _.cloneDeep(this.state.filtriRichiesta);
                                newFiltriRichiesta.statoCureDomiciliari = elem
                                this.setState({
                                    filtriRichiesta: newFiltriRichiesta,
                                    disabledApplicaAnnullaFiltri: false
                                })
                            }}
                            isSearchable={false}
                            styles={inputSelectStyles}
                            placeholder={"Seleziona"}
                            noOptionsMessage={() => "Errore: Nessuno stato disponibile"}
                            maxMenuHeight={maxSelectHeight}
                        />
                    </Col>
                    <Col xs={2}>
                        <span className="f-weight-500 m-0">Data Da</span>
                        <Calendar
                            id={"dataInizioCureDomiciliariDa"}
                            value={filtriRichiesta.dataInizioCureDomiciliariDa}
                            field={"dataInizioCureDomiciliariDa"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            errorMessage={this.getDataError("dataInizioCureDomiciliariDa").messaggio}
                            error={this.getDataError("dataInizioCureDomiciliariDa").valore}
                        />
                    </Col>
                    <Col xs={2}>
                        <span className="f-weight-500 m-0">Data A</span>
                        <Calendar
                            id={"dataInizioCureDomiciliariA"}
                            value={filtriRichiesta.dataInizioCureDomiciliariA}
                            field={"dataInizioCureDomiciliariA"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            errorMessage={this.getDataError("dataInizioCureDomiciliariA").messaggio}
                            error={this.getDataError("dataInizioCureDomiciliariA").valore}
                        />
                    </Col>
                    <Col xs={2}>
                        <span className="f-weight-500 m-0">Data Da</span>
                        <Calendar
                            id={"dataFineCureDomiciliariDa"}
                            value={filtriRichiesta.dataFineCureDomiciliariDa}
                            field={"dataFineCureDomiciliariDa"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            errorMessage={this.getDataError("dataFineCureDomiciliariDa").messaggio}
                            error={this.getDataError("dataFineCureDomiciliariDa").valore}
                        />
                    </Col>
                    <Col xs={2}>
                        <span className="f-weight-500 m-0">Data A</span>
                        <Calendar
                            id={"dataFineCureDomiciliariA"}
                            value={filtriRichiesta.dataFineCureDomiciliariA}
                            field={"dataFineCureDomiciliariA"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            errorMessage={this.getDataError("dataFineCureDomiciliariA").messaggio}
                            error={this.getDataError("dataFineCureDomiciliariA").valore}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <span className="f-weight-700">Dati annullamento richiesta</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <span className="f-weight-500 m-0">Data Da</span>
                        <Calendar
                            id={"dataAnnullamentoRichiestaDa"}
                            value={filtriRichiesta.dataAnnullamentoRichiestaDa}
                            field={"dataAnnullamentoRichiestaDa"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            errorMessage={this.getDataError("dataAnnullamentoRichiestaDa").messaggio}
                            error={this.getDataError("dataAnnullamentoRichiestaDa").valore}
                        />
                    </Col>
                    <Col xs={6}>
                        <span className="f-weight-500 m-0">Data A</span>
                        <Calendar
                            id={"dataAnnullamentoRichiestaA"}
                            value={filtriRichiesta.dataAnnullamentoRichiestaA}
                            field={"dataAnnullamentoRichiestaA"}
                            onChange={this.handleAggiornaFiltriRichiesta}
                            errorMessage={this.getDataError("dataAnnullamentoRichiestaA").messaggio}
                            error={this.getDataError("dataAnnullamentoRichiestaA").valore}
                        />
                    </Col>
                </Row>
            </Container>
        );
        const footer = (
            <>
                <Col xs={3} className={"text-left"}>
                    <Button
                        onClick={() => this.pulisciFiltriStoricoRichieste()}
                        disabled={this.state.disabledApplicaAnnullaFiltri}
                        id={"annullaFiltriBtn"}
                        text="Pulisci filtri"
                        className={"btn btn-adi btnGreenOutline"}
                    />
                </Col>
                <Col xs={6} className={"text-center"}>
                    <Button
                        onClick={() => this.applicaFiltriRichiesta()}
                        disabled={this.state.disabledApplicaAnnullaFiltri || this.state.disabledApplicaFiltri}
                        id={"applicaFiltriRichiesta"}
                        text="Applica filtri"
                        className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                    />
                </Col>
                <Col xs={3} className={"text-right"}>
                    <Button
                        onClick={() => this.chiudiModale()}
                        id={"chiudiModale"}
                        text="Chiudi"
                        className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                    />
                </Col>
            </>
        );
        const title = (
            <>
                <b tag={"span"} className={"modal-title w-100"}  style={{color: "#176A65"}}>Cerca e filtra richieste</b>
            </>
        )
        return (
            <>
                {this.state.showModale &&
                    <ModalHelper id={"modaleFiltro"}
                        title={title}
                        titleClassName={"modal-header text-center"}
                        containerClassName={"modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered"}
                        contentClassName={("modal-content")}
                        body={body}
                        footer={footer}
                        footerClassName={"modal-footer justify-content-around"}
                        bodyClassName={styles.bodyModal.concat(" modal-body")}
                    />}
                <div style={{float: "right", marginTop: "-13px"}}>
                    <Button id={"btnStoricoCercaFiltriRichiesta"}
                            text={<>
                                <Icon.Search size={24} className={"mr-3"} style={{cursor: "pointer"}}/>
                                Filtra richieste
                            </>}
                            onClick={() => this.setState({showModale: true})}
                            className={["btn btn-adi btnGreenOutline mt-2 mb-4", styles.cercaFiltraRichieste].join(" ")}
                    />
                    {this.getNumeroFiltriApplicati()}
                </div>
            </>
        );
    }
}

FiltroStoricoRichieste.propTypes = propTypes;
