import AuthUtils from "./AuthUtils";
import Utils from "./Utils";
import * as moment from "moment";
import { SessioneUtente } from "web-client-archetype";
import enumsUtente from "../enum/enumsUtente.json";

export default class FiltriUtils {

    static setFiltriStatoSelect(stato) {
        let queryParams = "";

        switch (stato) {
            case "01": //Da completare
                queryParams = "timestampCompletamento::null";
                break;
            case "02": //Completata
                queryParams = "timestampCompletamento!!null";
                break;
            default:
                break;
        }

        return queryParams;
    }

    static getFiltroStatoCureDomiciliari(stato) {

        let dataOdierna = moment().format("DD/MM/YYYY");

        switch (stato) {
            case "01":
                return "formalizzazione.dataInizioProgettoAssistenziale:<" + dataOdierna + ",formalizzazione.dataFineProgettoAssistenziale>>" + dataOdierna;
            case "02":
                return "formalizzazione.dataInizioProgettoAssistenziale>>" + dataOdierna;
            case "03":
                return "formalizzazione.dataInizioProgettoAssistenziale<<" + dataOdierna + ",formalizzazione.dataFineProgettoAssistenziale:<" + dataOdierna;
            case "04":
                return "formalizzazione.dataInizioProgettoAssistenziale::null";
            default:
                return "";
        }
    }

    static setFiltriDate(dataDa, dataA, tipoTimestamp) {
        let queryParams = dataDa ? tipoTimestamp + ":>" + dataDa : "";

        if (dataA) {
            let filtro = tipoTimestamp + ":<" + dataA;
            queryParams += queryParams ? "," + filtro : filtro;
        }

        return queryParams;
    }

    static setFiltriAssistito(nome, cognome, id, params) {
        if (nome) {
            params.filtriAssistito = "nome~~" + nome;
        }

        if (cognome) {
            let filtro = "cognome~~" + cognome;
            this.addParamsFiltriAssistito(params, filtro);
        }

        if (id) {
            let filtro;
            if (Array.isArray(id)) {
                filtro = "id_persona~~" + id.join("|");
            }
            else {
                filtro = "id_persona~~" + id;
            }
            this.addParamsFiltriAssistito(params, filtro);
        }
    }

    static addParamsFiltriAssistito(params, filtro) {
        if (params.filtriAssistito) {
            params.filtriAssistito += "," + filtro;
        } else {
            params.filtriAssistito = filtro;
        }
    }

    static addParamsFiltriRichiesta(params, filtro) {
        if (params.filtriRichiesta) {
            params.filtriRichiesta += "," + filtro;
        } else {
            params.filtriRichiesta = filtro;
        }
    }

    static addParams(params, field, filtro) {

        params[field] = filtro;
    }

    static getFiltriHomepage() {

        return "timestampEsito::null";
    }

    static getFiltriRicercaAssistito() {

            return "stato!!RIFIUTATA,stato!!ANNULLATA";
    }

    static getFiltriStoricoRichieste() {
        return "timestampEsito!!null";
    }

    static getOrdinamentoHomepage(sort, direction) {
        if (!Utils.isStringEmptyOrNullOrUndefined(sort) && !Utils.isStringEmptyOrNullOrUndefined(direction)) {
            let fieldOrdinamento;
            switch (sort) {
                case "cognomeNome": {
                    fieldOrdinamento = "assistito.cognome";
                    break
                }
                case "dataSegnalazione": {
                    fieldOrdinamento = "timestampConfermaPreDialogo";
                    break
                }
                case "dataRichiesta": {
                    fieldOrdinamento = "timestampCompletamento";
                    break
                }
                case "medicoRichiedente": {
                    fieldOrdinamento = "richiedente.cognome";
                    break;
                }
                case "valutazioneMultid": {
                    fieldOrdinamento = "timestampConferma";
                    break;
                }
                case "formalizzazione": {
                    fieldOrdinamento = "timestampFormalizzazione";
                    break;
                }
                case "valutazioneProfessionale": {
                    let sessioneUtente = SessioneUtente.getInstance();
                    fieldOrdinamento = "valutazioni[valutatore.codiceFiscale::" + sessioneUtente.idUtente + "].timestampConferma"
                    break;
                }
                case "esitoRichiesta": {
                    fieldOrdinamento = "stato";
                    break;
                }
                case "dataEsito": {
                    fieldOrdinamento = "timestampEsito";
                    break;
                }
                case "timestampCreazione": {
                    fieldOrdinamento = "timestampCreazione";
                    break;
                }
                case "medicoFormalizzatore": {
                    fieldOrdinamento = "professionistaEsito.cognome";
                    break;
                }
                case "cureDomiciliari": {
                    fieldOrdinamento = "formalizzazione.dataInizioProgettoAssistenziale";
                    break;
                }
                case "livelloPriorita": {
                    fieldOrdinamento = "livelloPriorita";
                    break;
                }
                case "inCaricoA": {
                    if (AuthUtils.hasUtenteRuoloFormalizzatore() && AuthUtils.hasUtenteRuoloMcp()) {
                        fieldOrdinamento = "team[profilo::" + enumsUtente.profiliUtente.medicoFormalizzatore + "].professionista.cognome"
                    }
                    else if (AuthUtils.hasUtenteProfiloCoordinatore()) {
                        fieldOrdinamento = "team[profilo::" + enumsUtente.profiliUtente.coordinatore + "].professionista.cognome"
                    }
                    break;
                }
                default: {
                    fieldOrdinamento = "";
                    break;
                }
            }
            let ordinamento;
            if (direction === "asc") {
                ordinamento = fieldOrdinamento.concat(":true")
            } else if (direction === "desc") {
                ordinamento = fieldOrdinamento.concat(":false")
            }

            return ordinamento;
        }
        else {
            return "timestampCompletamento:false,livelloPriorita:false,timestampConferma:false,assistito.cognome:true";
        }
    }

    static setDefaultFiltriRichiesta(context, params) {
        let treMesiFa = Utils.convertLocalToUtcDate(moment().subtract(3, 'months'));
        switch (context) {
            case "ricercaAssistiti":
                this.addParamsFiltriRichiesta(params, this.getFiltriRicercaAssistito());
                break;
            case "homepage":
                this.addParamsFiltriRichiesta(params, this.getFiltriHomepage());
                break;
            case "storicoRichieste":
                this.addParamsFiltriRichiesta(params, "timestampEsito!!null");
                this.addParamsFiltriRichiesta(params, "timestampEsito:>" + treMesiFa);
                break;
            default:
                break;
        }
    }

    static setFiltriRichiesta(filtriRichiesta, params) {

        //classificazione
        if (filtriRichiesta?.classificazione) {
            FiltriUtils.addParamsFiltriRichiesta(params, filtriRichiesta?.classificazione?.value);
        }

        //Livello priorità richiesta
        if (filtriRichiesta?.livelloPriorita) {
            FiltriUtils.addParamsFiltriRichiesta(params, "livelloPriorita::" + filtriRichiesta.livelloPriorita?.value);
        }

        //Data scadenza richiesta da
        if (filtriRichiesta?.dataScadenzaRichiestaDa) {
            FiltriUtils.addParams(params, "scadenzaRichiestaDa", filtriRichiesta.dataScadenzaRichiestaDa);
        }

        //Data scadenza richiesta a
        if (filtriRichiesta?.dataScadenzaRichiestaA) {
            FiltriUtils.addParams(params, "scadenzaRichiestaA", filtriRichiesta.dataScadenzaRichiestaA);
        }

        //Id richiesta
        if (filtriRichiesta?.id) {
            let filtro = "id::" + filtriRichiesta.id;
            FiltriUtils.addParamsFiltriRichiesta(params, filtro);
        }

        //Codice fiscale richiedente
        if (filtriRichiesta?.codiceFiscale) {
            let filtro = "richiedente.codiceFiscale::" + filtriRichiesta.codiceFiscale.value;
            FiltriUtils.addParamsFiltriRichiesta(params, filtro);
        }

        //Codice fiscale formalizzatore
        if (filtriRichiesta?.codiceFiscaleFormalizzatore) {
            let filtro = "professionistaEsito.codiceFiscale::" + filtriRichiesta?.codiceFiscaleFormalizzatore?.value;
            FiltriUtils.addParamsFiltriRichiesta(params, filtro);
        }

        //Codice fiscale firmatario
        if (filtriRichiesta?.codiceFiscaleFirmatario) {
            let filtro = "firma.professionista.codiceFiscale::" + filtriRichiesta?.codiceFiscaleFirmatario?.value;
            FiltriUtils.addParamsFiltriRichiesta(params, filtro);
        }
        

        //Stato richiesta
        if (filtriRichiesta?.statoRichiesta) {
            let filtro = FiltriUtils.setFiltriStatoSelect(filtriRichiesta.statoRichiesta.value);
            FiltriUtils.addParamsFiltriRichiesta(params, filtro);
        }

        //Date completamento richiesta
        let filtroDateCompletamento = FiltriUtils.setFiltriDate(filtriRichiesta?.dataRichiestaDa,
            filtriRichiesta?.dataRichiestaA, "timestampCompletamento");
        if (filtroDateCompletamento) {
            FiltriUtils.addParamsFiltriRichiesta(params, filtroDateCompletamento);
        }

        //Stato valutazione professionale
        if (filtriRichiesta?.statoValutazioneProfessionale) {
            if (filtriRichiesta.statoValutazioneProfessionale?.value === "02") {
                FiltriUtils.addParamsFiltriRichiesta(params, "valutazioni..timestampConferma!!null");
                FiltriUtils.addParamsFiltriRichiesta(params, "valutazioni..valutatore.codiceFiscale::" + SessioneUtente.getInstance().idUtente);
            }
            else {
                FiltriUtils.addParams(params, "statoValutazioneProfessionale", filtriRichiesta?.statoValutazioneProfessionale?.fieldStato);
            }
        }

        //Date valutazione professionale
        let filtroDateValutazioneProfessionale = FiltriUtils.setFiltriDate(filtriRichiesta?.dataValutazioneProfessionaleDa,
            filtriRichiesta?.dataValutazioneProfessionaleA, "valutazioni..timestampConferma");
        if (filtroDateValutazioneProfessionale) {
            let filtroCfValutatore = "valutazioni..valutatore.codiceFiscale::" + SessioneUtente.getInstance().idUtente;
            FiltriUtils.addParamsFiltriRichiesta(params, filtroDateValutazioneProfessionale);
            FiltriUtils.addParamsFiltriRichiesta(params, filtroCfValutatore);
        }

        //Stato valutazione multidimensionale
        if (filtriRichiesta?.statoValidazioneMultidimensionale) {
            FiltriUtils.addParams(params, "statoValutazioneMultidimensionale", filtriRichiesta?.statoValidazioneMultidimensionale?.fieldStato);
        }

        //Data valutazione multidimensionale da
        if (filtriRichiesta?.dataValutazioneDa) {
            FiltriUtils.addParams(params, "valutazioneMultidimensionaleDa", filtriRichiesta.dataValutazioneDa);
        }

        //Data valutazione multidimensionale a
        if (filtriRichiesta?.dataValutazioneA) {
            FiltriUtils.addParams(params, "valutazioneMultidimensionaleA", filtriRichiesta.dataValutazioneA);
        }

        //Date formalizzazione
        let filtroDateFormalizzazione = FiltriUtils.setFiltriDate(filtriRichiesta?.dataFormalizzazioneDa, filtriRichiesta?.dataFormalizzazioneA, "timestampFormalizzazione");
        if (filtroDateFormalizzazione) {
            FiltriUtils.addParamsFiltriRichiesta(params, filtroDateFormalizzazione);
        }


        //Stato formalizzazione
        if (filtriRichiesta?.statoFormalizzazione) {
            if (filtriRichiesta.statoFormalizzazione?.value === "02") {
                FiltriUtils.addParamsFiltriRichiesta(params, "timestampFormalizzazione!!null");
            }
            else {
                FiltriUtils.addParams(params, "statoFormalizzazione", filtriRichiesta?.statoFormalizzazione?.fieldStato);
            }
        }

        //Date inizio cure domiciliari
        let filtroDateInizioCureDomiciliari = FiltriUtils.setFiltriDate(filtriRichiesta?.dataInizioCureDomiciliariDa, filtriRichiesta?.dataInizioCureDomiciliariA, "formalizzazione.dataInizioProgettoAssistenziale");
        if (filtroDateInizioCureDomiciliari) {
            FiltriUtils.addParamsFiltriRichiesta(params, filtroDateInizioCureDomiciliari);
        }

        //Date fine cure domiciliari
        let filtroDateFineCureDomiciliari = FiltriUtils.setFiltriDate(filtriRichiesta?.dataFineCureDomiciliariDa, filtriRichiesta?.dataFineCureDomiciliariA, "formalizzazione.dataFineProgettoAssistenziale");
        if (filtroDateFineCureDomiciliari) {
            FiltriUtils.addParamsFiltriRichiesta(params, filtroDateFineCureDomiciliari);
        }

        //Stato cure domiciliari
        if (filtriRichiesta?.statoCureDomiciliari) {
            let filtro = FiltriUtils.getFiltroStatoCureDomiciliari(filtriRichiesta?.statoCureDomiciliari.value);
            FiltriUtils.addParamsFiltriRichiesta(params, filtro);
        }

        //Date annullamento richiesta
        let filtroDateAnnullamentoRichiesta = FiltriUtils.setFiltriDate(filtriRichiesta?.dataAnnullamentoRichiestaDa, filtriRichiesta?.dataAnnullamentoRichiestaA, "timestampAnnullamento");
        if (filtroDateAnnullamentoRichiesta) {
            FiltriUtils.addParamsFiltriRichiesta(params, filtroDateAnnullamentoRichiesta);
        }

        //Date segnalazione
        let filtroDateSegnalazione = FiltriUtils.setFiltriDate(filtriRichiesta?.dataSegnalazioneDa, filtriRichiesta?.dataSegnalazioneA, "timestampCompletamentoPreDialogo");
        if (filtroDateSegnalazione) {
            FiltriUtils.addParamsFiltriRichiesta(params, filtroDateSegnalazione);
        }

        //Stato segnalazione
        if (filtriRichiesta?.statoSegnalazione) {
            if (filtriRichiesta.statoSegnalazione?.value === "01") {
                FiltriUtils.addParamsFiltriRichiesta(params, "timestampCompletamentoPreDialogo::null");
            }
            else if (filtriRichiesta.statoSegnalazione?.value === "02") {
                FiltriUtils.addParamsFiltriRichiesta(params, "timestampCompletamentoPreDialogo!!null");
            }
        }
    }
}
