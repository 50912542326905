import React, {Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedCalendar,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti"
import SessionRequest from "../../../utils/SessionRequest";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti,
    defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    dati: PropTypes.any,
    onChangeDatiDiuresi: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    pageState: PropTypes.string,
    isOptional: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultTipologiaAlterazione = {
    id: null,
    tipologiaAlterazione: null,
    durataAlterazioneDataDa: null,
    durataAlterazioneDataA: null
}

const defaultTipologiaTurbe = {
    id: null,
    tipologia: null
};

const defaultTipologiaLimitazioni = {
    id: null,
    tipologia: null,
    testoLibero: null
};

export default class Diuresi extends AccordionSecondLevel {

    state = {
        diuresi: _.cloneDeep(this.props.dati),
        campiObbligatori: 0
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati))
            this.forceUpdate(() => this.inizializzaForm());
    }

    inizializzaForm = () => {
        const diuresi = _.cloneDeep(this.props.dati);
        this.setState({diuresi}, () => this.validaSezione());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({diuresi}) => {
            diuresi[field] = elem;

            /* Regolare */
            if (diuresi.isRegolare == null) {
                diuresi.tipologieAlterazione = [];
                diuresi.numeroMinzioni = null;
            } else if (diuresi.isRegolare) {
                diuresi.tipologieAlterazione = [];
            } else if (diuresi.isRegolare === false) {
                diuresi.numeroMinzioni = null;
            }

            /* Incontinenza urinaria */
            if (!diuresi.isIncontinenzaUrinaria) {
                diuresi.tipologiaIncontinenza = null;
            }

            if (diuresi.tipologiaIncontinenza == null) {
                diuresi.tipologiaIncontinenzaAbituale = null;
                diuresi.periodoIncontinenzaDa = null;
                diuresi.periodoIncontinenzaA = null;
                diuresi.tipologiaIncontinenzaOccasionale = null;
            } else if (diuresi.tipologiaIncontinenza === "01") {
                diuresi.tipologiaIncontinenzaAbituale = null;
                diuresi.periodoIncontinenzaDa = null;
                diuresi.periodoIncontinenzaA = null;
            } else if (diuresi.tipologiaIncontinenza === "02") {
                diuresi.tipologiaIncontinenzaOccasionale = null;
            }

            /* Turbe */
            if (!diuresi.isTurbe) {
                diuresi.tipologieTurbe = [];
            }

            /* Limitazioni */
            if (!diuresi.isLimitazioni) {
                diuresi.tipologieLimitazioni = [];
            }

            if (!this.showCondizioneRilevata(diuresi)) {
                diuresi.condizioneRilevata = null;
            }

            return {diuresi};
        }, this.validaSezione);
    }

    validaSezione() {
        const diuresi = _.cloneDeep(this.state.diuresi);
        this.props.onChangeDatiDiuresi("datiDiuresi", diuresi);
        this.props.onChangeRequiredFieldsNumber("sottosezioneDiuresi", this.numeroCampiObbligatori());
    }

    numeroCampiObbligatori = () => {
        let numeroCampiObbligatori = 0;

        numeroCampiObbligatori += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(this.state.diuresi?.propostaAttivitaSuggerimenti);

        if (Utils.getErrorDataDa(this.state.diuresi.periodoIncontinenzaDa, this.state.diuresi.periodoIncontinenzaA)) numeroCampiObbligatori++;
        if (Utils.getErrorDataA(this.state.diuresi.periodoIncontinenzaDa, this.state.diuresi.periodoIncontinenzaA)) numeroCampiObbligatori++;

        Array.isArray(this.state.diuresi?.tipologieAlterazione) && this.state.diuresi.tipologieAlterazione.forEach(t => {
            if (Utils.getErrorDataDa(t.durataAlterazioneDataDa, t.durataAlterazioneDataA)) numeroCampiObbligatori++;
            if (Utils.getErrorDataA(t.durataAlterazioneDataDa, t.durataAlterazioneDataA)) numeroCampiObbligatori++;
        });

        return numeroCampiObbligatori;
    }

    //Gestione liste
    handleAggiornaListaForm = (field, index, subField, value) => {
        let diuresi = _.cloneDeep(this.state.diuresi);

        if (Utils.isStringEmptyOrNullOrUndefined(subField)) {
            diuresi[field][index] = value;
        } else {
            diuresi[field][index][subField] = value;
        }

        if (diuresi[field][index]?.tipologia !== "03" && diuresi[field][index]?.testoLibero) {
            diuresi[field][index].testoLibero = null;
        }

        this.setState({diuresi}, () => this.validaSezione());
    }

    aggiungiElementoAllaLista = (nomeCampoLista, elementoDefault) => {
        let diuresi = _.cloneDeep(this.state.diuresi);
        let elemento = _.cloneDeep(elementoDefault);
        elemento.id = uuid();
        if (!Array.isArray(diuresi[nomeCampoLista])) diuresi[nomeCampoLista] = [];
        diuresi[nomeCampoLista].push(elemento);

        this.setState({diuresi}, () => this.validaSezione());
    }

    eliminaElementoDallaLista = (nomeCampoLista, indiceElemento) => {
        let diuresi = _.cloneDeep(this.state.diuresi);
        diuresi[nomeCampoLista].splice(indiceElemento, 1);
        this.setState({diuresi}, () => this.validaSezione());
    }

    checkValiditaLista = (nomeCampoLista, campoDaValidare) => {
        return this.state.diuresi[nomeCampoLista]?.every(e => !!e[campoDaValidare]);
    }

    handleDispositiviArrayChange = (array, field) => {
        let data = _.cloneDeep(this.state.diuresi);
        data[field] = array;
        this.setState({diuresi: data}, () => {
            this.props.onChangeDatiDiuresi("datiDiuresi", data);
            this.forceUpdate();
        });
    }

    onHide = () => {
        const diuresi = _.cloneDeep(SessionRequest.getInitObject('anamnesiFisiologica.datiDiuresi'));
        this.setState({diuresi},
            () => {
                this.props.onChangeDatiDiuresi("datiDiuresi", diuresi)
                this.props.onHide();
            });
    }

    renderTipologiaAlterazione = (el, i) => {
        const errorDurataAlterazioneDataDa = Utils.getErrorDataDa(el.durataAlterazioneDataDa, el.durataAlterazioneDataA);
        const errorDurataAlterazioneDataA = Utils.getErrorDataA(el.durataAlterazioneDataDa, el.durataAlterazioneDataA);
        return <Fragment key={"TIPOLOGIA_ALTERAZIONE_" + i}>
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'M_TIPO_ALT.DIURESI.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={'I_TIPO_ALT.DIURESI.FUNZIONEURINARIA'}
                    pageState={this.props.pageState}
                    id={"tipologiaAlerazione" + i}
                    options={OggettiUtili.tipologiaAlterazioneDiuresi}
                    value={el.tipologiaAlterazione}
                    onChange={(elem) => this.handleAggiornaListaForm("tipologieAlterazione", i, "tipologiaAlterazione", elem)}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Tipologia diuresi non esistente"}
                    fieldLabel={'Tipologia di alterazione'}
                    handleOnlyValue={true}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    field={"tipologiaAlterazione"}
                />
                <RoleBasedCalendar
                    fieldId={"M_DUR_ALT.DIURESI.ANAMNESIFISIOLOGICA"}
                    infermiereFieldId={'I_DUR_ALT.DIURESI.FUNZIONEURINARIA'}
                    pageState={this.props.pageState}
                    id={"diuresiDurataAlterazioneDataDa" + i} F
                    value={el.durataAlterazioneDataDa}
                    field={"durataAlterazioneDataDa"}
                    onChange={(elem) => this.handleAggiornaListaForm("tipologieAlterazione", i, "durataAlterazioneDataDa", elem.target.value)}
                    errorMessage={"Data non valida"}
                    fieldLabel={'Durata alterazione da'}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    error={errorDurataAlterazioneDataDa}
                    fieldRequired={errorDurataAlterazioneDataDa}
                />
                <RoleBasedCalendar
                    fieldId={"M_DUR_ALT.DIURESI.ANAMNESIFISIOLOGICA"}
                    infermiereFieldId={'I_DUR_ALT.DIURESI.FUNZIONEURINARIA'}
                    pageState={this.props.pageState}
                    id={"diuresiDurataAlterazioneDataA" + i}
                    value={el.durataAlterazioneDataA}
                    field={"durataAlterazioneDataA"}
                    onChange={(elem) => this.handleAggiornaListaForm("tipologieAlterazione", i, "durataAlterazioneDataA", elem.target.value)}
                    errorMessage={"Data non valida"}
                    fieldLabel={'Durata alterazione a'}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    error={errorDurataAlterazioneDataA}
                    fieldRequired={errorDurataAlterazioneDataA}
                />
            </FieldsRow>
        </Fragment>;
    }

    renderTipologiaTurbe = (el, i) => {
        return <FieldsRow key={"TIPOLOGIA_TURBE_" + i}>
            <RoleBasedICD9Input
                fieldId={"M_TIPO_TURBE.DIURESI.ANAMNESIFISIOLOGICA"}
                infermiereFieldId={'I_TIPO_TURBE.DIURESI.FUNZIONEURINARIA'}
                pageState={this.props.pageState}
                onChange={(field, elem) => this.handleAggiornaListaForm("tipologieTurbe", i, "tipologia", elem.target.value)}
                fieldLabel={'Tipologia turbe'}
                value={el.tipologia}
                field={'tipologia'}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                disableDoubleInput={this.props.disableDoubleInput}
            />
        </FieldsRow>;
    }

    renderTipologiaLimitazioni = (el, i) => {
        return <FieldsRow>
            <RoleBasedSelect
                fieldId={'M_TIPO_USCITE.DIURESI.ANAMNESIFISIOLOGICA'}
                infermiereFieldId={'I_TIPO_USCITE.DIURESI.FUNZIONEURINARIA'}
                pageState={this.props.pageState}
                id={"tipologiaLimitazioni" + i}
                options={OggettiUtili.tipologieLimitazioni}
                value={el.tipologia}
                onChange={(elem) => this.handleAggiornaListaForm("tipologieLimitazioni", i, "tipologia", elem)}
                isSearchable={false}
                placeholder={"Seleziona tipologia"}
                noOptionsMessage={() => "Errore: Tipologia limitazioni non esistente"}
                fieldLabel={'Tipologia limitazioni'}
                handleOnlyValue={true}
                forceReadOnly={this.props.forceReadOnly}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                disableDoubleInput={this.props.disableDoubleInput}
                field={"tipologia"}
            />
            {el.tipologia === "03" &&
                <RoleBasedInput
                    fieldId={'M_TIPO_USCITE_ALTRO.DIURESI.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={'I_TIPO_USCITE_ALTRO.DIURESI.FUNZIONEURINARIA'}
                    pageState={this.props.pageState}
                    id={"testoLibero"}
                    value={el.testoLibero}
                    placeholder={"Specificare tipologia"}
                    field={"testoLibero"}
                    onChange={(field, elem) => this.handleAggiornaListaForm("tipologieLimitazioni", i, "testoLibero", elem.target.value)}
                    fieldLabel={"Specificare"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                />}
        </FieldsRow>;
    }

    showCondizioneRilevata(diuresi) {
        return (diuresi.isRegolare === false || (diuresi.isIncontinenzaUrinaria || diuresi.isTurbe || diuresi.isLimitazioni));
    }

    renderDiuresi = () => {
        let diuresi = this.state.diuresi;
        const errorPeriodoIncontinenzaDa = Utils.getErrorDataDa(diuresi.periodoIncontinenzaDa, diuresi.periodoIncontinenzaA);
        const errorPeriodoIncontinenzaA = Utils.getErrorDataA(diuresi.periodoIncontinenzaDa, diuresi.periodoIncontinenzaA);

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_REG.DIURESI.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_REG.DIURESI.FUNZIONEURINARIA'}
                        pageState={this.props.pageState}
                        id={"isRegolare"}
                        className={"isRegolare"}
                        options={OggettiUtili.isRegolareDiuresi}
                        value={diuresi.isRegolare}
                        onChange={(elem) => this.handleAggiornaForm("isRegolare", elem)}
                        isSearchable={false}
                        fieldLabel={"Regolare"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"isRegolare"}
                    />
                    {diuresi.isRegolare &&
                        <RoleBasedInput
                            fieldId={'M_REG.DIURESI.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_REG.DIURESI.FUNZIONEURINARIA'}
                            pageState={this.props.pageState}
                            id={"numeroMinzioni"}
                            value={diuresi.numeroMinzioni}
                            placeholder={"Inserisci N° minzioni/die"}
                            field={"numeroMinzioni"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            type={"number"}
                            min={0}
                            fieldLabel={"N° minzioni/die"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />}
                </FieldsRow>

                {diuresi.isRegolare === false &&
                    <SectionList
                        title={'Alterazione'}
                        data={diuresi.tipologieAlterazione}
                        addNewSectionCallback={() => this.aggiungiElementoAllaLista("tipologieAlterazione", defaultTipologiaAlterazione)}
                        removeSectionCallback={(i) => this.eliminaElementoDallaLista("tipologieAlterazione", i)}
                        renderSection={this.renderTipologiaAlterazione}
                        atLeastOne={true}
                        addButtonVisibilityHandler={this.checkValiditaLista("tipologieAlterazione", 'tipologiaAlterazione')}
                        pageState={this.props.pageState}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        keyFieldId={'M_TIPO_ALT.DIURESI.ANAMNESIFISIOLOGICA'}
                        infermiereKeyFieldId={'I_TIPO_ALT.DIURESI.FUNZIONEURINARIA'}
                        field={'tipologieAlterazione'}
                        parentJsonPath={"anamnesiFisiologica.datiDiuresi.tipologieAlterazione"}
                    />}

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_INCONT_URIN.DIURESI.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_INCONT_URIN.DIURESI.FUNZIONEURINARIA'}
                        pageState={this.props.pageState}
                        id={"isIncontinenzaUrinaria"}
                        className={"isIncontinenzaUrinaria"}
                        options={OggettiUtili.rispostaBreve}
                        value={diuresi.isIncontinenzaUrinaria}
                        onChange={(elem) => this.handleAggiornaForm("isIncontinenzaUrinaria", elem)}
                        isSearchable={false}
                        fieldLabel={"Incontinenza urinaria"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"isIncontinenzaUrinaria"}
                    />
                    {diuresi.isIncontinenzaUrinaria &&
                        <RoleBasedSelect
                            fieldId={'M_TIPO_INCONT.DIURESI.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_TIPO_INCONT.DIURESI.FUNZIONEURINARIA'}
                            pageState={this.props.pageState}
                            id={"tipologiaIncontinenza"}
                            options={OggettiUtili.tipologiaIncontinenzaUrinaria}
                            value={diuresi.tipologiaIncontinenza}
                            onChange={(elem) => this.handleAggiornaForm("tipologiaIncontinenza", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Tipologia incontinenza non esistente"}
                            fieldLabel={"Tipologia"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={"tipologiaIncontinenza"}
                        />}
                </FieldsRow>

                {diuresi.tipologiaIncontinenza === "02" ?
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_TIPO_AB.DIURESI.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_TIPO_AB.DIURESI.FUNZIONEURINARIA'}
                            pageState={this.props.pageState}
                            id={"tipologiaIncontinenzaAbituale"}
                            options={OggettiUtili.tipologiaIncontinenzaUrinariaAbituale}
                            value={diuresi.tipologiaIncontinenzaAbituale}
                            onChange={(elem) => this.handleAggiornaForm("tipologiaIncontinenzaAbituale", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Tipologia incontinenza non esistente"}
                            fieldLabel={"Tipologia abituale"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={"tipologiaIncontinenzaAbituale"}
                        />
                        <RoleBasedCalendar
                            fieldId={"M_PER.DIURESI.ANAMNESIFISIOLOGICA"}
                            infermiereFieldId={'I_PER.DIURESI.FUNZIONEURINARIA'}
                            pageState={this.props.pageState}
                            id={"periodoIncontinenzaDa"}
                            value={diuresi.periodoIncontinenzaDa}
                            field={"periodoIncontinenzaDa"}
                            onChange={(elem) => this.handleAggiornaForm("periodoIncontinenzaDa", elem?.target?.value)}
                            errorMessage={"Data non valida"}
                            fieldLabel={"Periodo incontinenza da"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            error={errorPeriodoIncontinenzaDa}
                            fieldRequired={errorPeriodoIncontinenzaDa}
                        />
                        <RoleBasedCalendar
                            fieldId={"M_PER.DIURESI.ANAMNESIFISIOLOGICA"}
                            infermiereFieldId={'I_PER.DIURESI.FUNZIONEURINARIA'}
                            pageState={this.props.pageState}
                            id={"periodoIncontinenzaA"}
                            value={diuresi.periodoIncontinenzaA}
                            field={"periodoIncontinenzaA"}
                            onChange={(elem) => this.handleAggiornaForm("periodoIncontinenzaA", elem?.target?.value)}
                            errorMessage={"Data non valida"}
                            fieldLabel={"Periodo incontinenza a"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            error={errorPeriodoIncontinenzaA}
                            fieldRequired={errorPeriodoIncontinenzaA}
                        />
                    </FieldsRow>
                    : diuresi.tipologiaIncontinenza === "01" &&
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'M_TIPO_OCCAS.DIURESI.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_TIPO_OCCAS.DIURESI.FUNZIONEURINARIA'}
                            pageState={this.props.pageState}
                            id={"tipologiaIncontinenzaOccasionale"}
                            options={OggettiUtili.tipologiaIncontinenzaUrinariaOccasionale}
                            value={diuresi.tipologiaIncontinenzaOccasionale}
                            onChange={(elem) => this.handleAggiornaForm("tipologiaIncontinenzaOccasionale", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Tipologia incontinenza non esistente"}
                            fieldLabel={"Tipologia occasionale"}
                            handleOnlyValue={true}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={"tipologiaIncontinenzaOccasionale"}
                        />
                    </FieldsRow>}

                <DispositiviPresenti
                    handleArrayChange={this.handleDispositiviArrayChange}
                    dispositiviPresenti={diuresi.dispositiviPresenti}
                    pageState={this.props.pageState}
                    fieldId={"M_DISP.DIURESI.ANAMNESIFISIOLOGICA"}
                    infermiereFieldId={'I_DISP.DIURESI.FUNZIONEURINARIA'}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    parentJsonPath={"anamnesiFisiologica.datiDiuresi.dispositiviPresenti"}
                />

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_TURBE_COMP_ELIMINAZ.DIURESI.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_TURBE_COMP_ELIMINAZ.DIURESI.FUNZIONEURINARIA'}
                        pageState={this.props.pageState}
                        id={"isTurbe"}
                        options={OggettiUtili.rispostaBreve}
                        value={diuresi.isTurbe}
                        onChange={(elem) => this.handleAggiornaForm("isTurbe", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Turbe del comportamento legate all'eliminazione non esistente"}
                        fieldLabel={"Turbe del comportamento legate all'eliminazione"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"isTurbe"}
                    />
                </FieldsRow>

                {diuresi.isTurbe &&
                    <SectionList
                        title={'Tipologie turbe'}
                        data={diuresi.tipologieTurbe}
                        addNewSectionCallback={() => this.aggiungiElementoAllaLista("tipologieTurbe", defaultTipologiaTurbe)}
                        removeSectionCallback={(i) => this.eliminaElementoDallaLista("tipologieTurbe", i)}
                        renderSection={this.renderTipologiaTurbe}
                        atLeastOne={true}
                        addButtonVisibilityHandler={this.checkValiditaLista("tipologieTurbe", "tipologia")}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'M_TIPO_TURBE.DIURESI.ANAMNESIFISIOLOGICA'}
                        infermiereKeyFieldId={'I_TIPO_TURBE.DIURESI.FUNZIONEURINARIA'}
                        field={'tipologieTurbe'}
                        parentJsonPath={"anamnesiFisiologica.datiDiuresi.tipologieTurbe"}    
                    />}

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_COND_LIMITAZ.DIURESI.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_COND_LIMITAZ.DIURESI.FUNZIONEURINARIA'}
                        pageState={this.props.pageState}
                        id={"isLimitazioni"}
                        options={OggettiUtili.rispostaBreve}
                        value={diuresi.isLimitazioni}
                        onChange={(elem) => this.handleAggiornaForm("isLimitazioni", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Condizionamenti nella vita quotidiana - Limitazioni non esistente"}
                        fieldLabel={"Condizionamenti nella vita quotidiana - Limitazioni"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"isLimitazioni"}
                    />
                </FieldsRow>

                {diuresi.isLimitazioni &&
                    <SectionList
                        title={'Tipologie limitazioni'}
                        data={diuresi.tipologieLimitazioni}
                        addNewSectionCallback={() => this.aggiungiElementoAllaLista("tipologieLimitazioni", defaultTipologiaLimitazioni)}
                        removeSectionCallback={(i) => this.eliminaElementoDallaLista("tipologieLimitazioni", i)}
                        renderSection={this.renderTipologiaLimitazioni}
                        atLeastOne={true}
                        addButtonVisibilityHandler={this.checkValiditaLista("tipologieLimitazioni", 'tipologia')}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        pageState={this.props.pageState}
                        keyFieldId={'M_TIPO_USCITE.DIURESI.ANAMNESIFISIOLOGICA'}
                        infermiereKeyFieldId={'I_TIPO_USCITE.DIURESI.FUNZIONEURINARIA'}
                        field={'tipologieLimitazioni'}
                        parentJsonPath={"anamnesiFisiologica.datiDiuresi.tipologieLimitazioni"}
                    />}

                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'M_DATA_VAL.DIURESI.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_DATA_VAL.DIURESI.FUNZIONEURINARIA'}
                        pageState={this.props.pageState}
                        id={"diuresiDataValutazione"}
                        value={diuresi.dataValutazione}
                        onChange={(elem) => this.handleAggiornaForm("dataValutazione", elem.target.value)}
                        errorMessage={"Data valutazione non valida"}
                        fieldLabel={"Data Valutazione"}
                        field={"dataValutazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <CondizioneRilevataSection
                    ambito={'DIURESI'}
                    parentFieldId={'DIURESI.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'DIURESI.FUNZIONEURINARIA'}
                    appendRolePrefix={true}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={diuresi.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={diuresi.daAttenzionare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={diuresi.propostaAttivitaSuggerimenti}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={diuresi.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={diuresi.autonomiaGestioneCaregiver}
                    openCondition={this.showCondizioneRilevata(diuresi)}
                />
            </>
        );
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"datiDiuresi"}
            title={"Diuresi"}
            required={this.numeroCampiObbligatori() > 0}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            onHide={this.onHide}
            fieldsOrderMap={Utils.generateMapPathsObjects(
                ["anamnesiFisiologica.datiDiuresi.tipologieAlterazione",
                    "anamnesiFisiologica.datiDiuresi.dispositiviPresenti",
                    "anamnesiFisiologica.datiDiuresi.tipologieTurbe",
                    "anamnesiFisiologica.datiDiuresi.tipologieLimitazioni",
                    "anamnesiFisiologica.datiDiuresi.propostaAttivitaSuggerimenti"],
                [defaultTipologiaAlterazione, defaultDispositiviPresenti, defaultTipologiaTurbe,
                    defaultTipologiaLimitazioni, defaultProposteAttivitaSuggerimenti]
            )}
            isOptional={this.props.isOptional && isFieldIdWriteable('M_TIPO_ALT.DIURESI.ANAMNESIFISIOLOGICA', this.props.pageState, this.context.forceUserRole)}
            accordionReadOnly={this.props.accordionReadOnly}
            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
        >{this.renderDiuresi()}</AccordionSezioneRichiesta>
    }
}
Diuresi.propTypes = propTypes;

Diuresi.defaultProps = {
    isOptional: true,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

Diuresi.contextType = RoleBasedContext;

