import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedCalendar,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import SessionRequest from "../../../utils/SessionRequest";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco, defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    dati: PropTypes.any,
    onChangeDatiAppetito: PropTypes.func,
    pageState: PropTypes.string,
    isOptional: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class Appetito extends AccordionSecondLevel {
    state = {
        appetito: _.cloneDeep(this.props.dati)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        const appetito = _.cloneDeep(this.props.dati);
        this.setState({appetito}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({appetito}) => {
            appetito[field] = elem;

            if (!appetito.isModificheAppetito)
                appetito.tipologiaModifica = null;

            if (!this.showCondizioneRilevata(appetito))
                appetito.condizioneRilevata = null;

            return {appetito};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        const appetito = _.cloneDeep(this.state.appetito);
        this.props.onChangeDatiAppetito("appetito", appetito);

        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(appetito?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneAppetito", campiObbligatoriRimanenti);
    }

    onHide = () => {
        const appetito = _.cloneDeep(SessionRequest.getInitObject('anamnesiFisiologica.appetito'));
        this.setState({appetito},
            () => {
                this.props.onChangeDatiAppetito("appetito", appetito)
                this.props.onHide();
            });
    }

    showCondizioneRilevata(appetito) {
        return appetito.isModificheAppetito || appetito.isNausea || appetito.isVomito;
    }

    renderAppetito = () => {
        const appetito = this.state.appetito;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_MOD_APPET.APPETITO.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_MOD_APPET.APPETITO.FUNZIONEGASTROINTESTINALE'}
                        pageState={this.props.pageState}
                        id={"selectModificheAppetito"}
                        className={"selectModificheAppetito"}
                        options={OggettiUtili.rispostaBreve}
                        value={appetito.isModificheAppetito}
                        onChange={(elem) => this.handleAggiornaForm("isModificheAppetito", elem)}
                        isSearchable={false}
                        fieldLabel={"Modifiche all'appetito?"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"isModificheAppetito"}
                    />
                    {appetito.isModificheAppetito &&
                        <RoleBasedSelect
                            fieldId={'M_TIPO_MOD.APPETITO.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_TIPO_MOD.APPETITO.FUNZIONEGASTROINTESTINALE'}
                            pageState={this.props.pageState}
                            id={"selectTipologiaModifica"}
                            options={OggettiUtili.tipologiaModifica}
                            value={appetito.tipologiaModifica}
                            onChange={(elem) => this.handleAggiornaForm("tipologiaModifica", elem)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Tipologia appetito non esistente"}
                            fieldLabel={"Tipologia modifica"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                            field={"tipologiaModifica"}
                        />}
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_NAUSEA.APPETITO.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_NAUSEA.APPETITO.FUNZIONEGASTROINTESTINALE'}
                        pageState={this.props.pageState}
                        id={"selectNausea"}
                        className={"selectNausea"}
                        options={OggettiUtili.rispostaBreve}
                        value={appetito.isNausea}
                        onChange={(elem) => this.handleAggiornaForm("isNausea", elem)}
                        isSearchable={false}
                        fieldLabel={"Nausea"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"isNausea"}
                    />
                    <RoleBasedSelect
                        fieldId={'M_VOMITO.APPETITO.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_VOMITO.APPETITO.FUNZIONEGASTROINTESTINALE'}
                        pageState={this.props.pageState}
                        id={"selectVomito"}
                        className={"selectVomito"}
                        options={OggettiUtili.rispostaBreve}
                        value={appetito.isVomito}
                        onChange={(elem) => this.handleAggiornaForm("isVomito", elem)}
                        isSearchable={false}
                        fieldLabel={"Vomito"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"isVomito"}
                    />
                </FieldsRow>
                <CondizioneRilevataSection
                    ambito={'APPETITO'}
                    parentFieldId={'APPETITO.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'APPETITO.FUNZIONEGASTROINTESTINALE'}
                    appendRolePrefix={true}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={appetito.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={appetito.daAttenzionare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={appetito.propostaAttivitaSuggerimenti}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={appetito.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={appetito.autonomiaGestioneCaregiver}
                    openCondition={this.showCondizioneRilevata(appetito)}
                />
                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'M_DATA_VAL.APPETITO.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_DATA_VAL.APPETITO.FUNZIONEGASTROINTESTINALE'}
                        pageState={this.props.pageState}
                        id={"appetitoDataValutazione"}
                        value={appetito.dataValutazione}
                        onChange={(elem) => this.handleAggiornaForm("dataValutazione", elem.target.value)}
                        errorMessage={"Data valutazione non valida"}
                        fieldLabel={"Data Valutazione"}
                        field={"dataValutazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"appetito"}
            title={"Appetito"}
            required={false}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            onHide={this.onHide}
            isOptional={this.props.isOptional && isFieldIdWriteable('M_MOD_APPET.APPETITO.ANAMNESIFISIOLOGICA', this.props.pageState, this.context.forceUserRole)}
            fieldsOrderMap={Utils.generateMapPathsObjects(
                ["anamnesiFisiologica.appetito.propostaAttivitaSuggerimenti"],
                [defaultProposteAttivitaSuggerimenti]
            )}
            accordionReadOnly={this.props.accordionReadOnly}
            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
        >{this.renderAppetito()}</AccordionSezioneRichiesta>
    }
}

Appetito.propTypes = propTypes;

Appetito.defaultProps = {
    isOptional: true,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

Appetito.contextType = RoleBasedContext;

