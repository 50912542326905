import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {
    FieldsRow,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../../utils/dataset/OggettiUtili.json";
import {v4 as uuid} from "uuid";
import Utils from "../../../../utils/Utils";
import {isSectionReadOnly} from "../DispositiviMediciPresenti";

const propTypes = {
    dati: PropTypes.object,
    onChange: PropTypes.func,
    pageState: PropTypes.string,
    idDispositivo: PropTypes.string,
    fieldInAccordionReadOnly: PropTypes.bool
}

export const defaultSublistElementProtesi = {
    id: null,
    codice: null
}

export default class ProtesiOdontoiatriche extends Component {

    state = {
        tipologiaProtesiDentaria: null,
        problemiCorrelatiAllaProtesiDentaria: null,
        quali: null
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati)) this.inizializzaForm();
    }

    inizializzaForm = () => {
        let state = _.cloneDeep(this.props.dati);
        if (state == null) state = _.cloneDeep(this.state);
        this.setState(state);
    }

    handleAggiornaForm = (field, elem) => {
        let state = _.cloneDeep(this.state);

        if (field === "problemiCorrelatiAllaProtesiDentaria" && !elem) state["quali"] = null;
        state[field] = new RegExp(/^[-]\d+$/g).test(elem) ? "0" : elem;
        this.setState(state, () => this.props.onChange("protesiOdontoiatriche", this.state));
    }

    addNewElementToList = (field) => {
        let dati = _.cloneDeep(this.state);
        if (!dati[field]) dati[field] = [];
        dati[field].push({..._.cloneDeep(defaultSublistElementProtesi), id: uuid()});
        this.handleAggiornaForm(field, dati[field]);
    }

    removeElementToList = (sublistIndex, field) => {
        let dati = _.cloneDeep(this.state);
        dati[field].splice(sublistIndex, 1);
        this.handleAggiornaForm(field, dati[field]);
    }

    renderTipologiaProtesiDentaria = (element, index) => {
        const {tipologiaProtesiDentaria} = this.state;
        const filterTipologiaProtesiDentaria = (item) => {
            return !(tipologiaProtesiDentaria?.find(e => e.codice === item.value))
        }
        const options = OggettiUtili.tipologiaProtesiDentaria.filter(filterTipologiaProtesiDentaria);
        if (element?.codice) options.push(OggettiUtili.tipologiaProtesiDentaria.find(e => e?.value === element?.codice));
        return <Fragment key={index}>
            <FieldsRow>
                <RoleBasedSelect
                    id={"selectTipologiaProtesiDentaria"}
                    fieldId={'TIPO_PROT_DENT.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"tipologiaProtesiDentaria"}
                    fieldLabel={"Tipologia di protesi dentaria"}
                    pageState={this.props.pageState}
                    options={options}
                    value={element.codice}
                    onChange={(elem) => this.handleAggiornaForm(
                        "tipologiaProtesiDentaria",
                        _.cloneDeep(this.state)?.tipologiaProtesiDentaria?.map(function (typePro) {
                            if (element.id === typePro.id)
                                return {...element, codice: elem}
                            return typePro
                        }))}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
            </FieldsRow>
        </Fragment>;
    }

    render() {
        return <>
            <SectionList
                addNewSectionCallback={() => this.addNewElementToList("tipologiaProtesiDentaria")}
                removeSectionCallback={(i) => this.removeElementToList(i, "tipologiaProtesiDentaria")}
                data={this.state.tipologiaProtesiDentaria || []}
                title={'Tipologia di protesi dentaria'}
                renderSection={(e, i) => {
                    return this.renderTipologiaProtesiDentaria(e, i);
                }}
                pageState={this.props.pageState}
                field={"tipologiaProtesiDentaria"}
                addButtonVisibilityHandler={this.state.tipologiaProtesiDentaria?.length === 0
                || this.state.tipologiaProtesiDentaria?.every(el => !Utils.isObjectNull(el?.codice))}
                indentOffset={0}
                forceReadOnly={isSectionReadOnly(this.props.pageState)}
                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                parentJsonPath={"informazioniDiBase.dispositiviMediciPresenti[?(@.id == '"+ this.props.idDispositivo +"')].protesiOdontoiatriche"}
            />
            <FieldsRow>
                <RoleBasedSelect
                    id={"selectProblemiCorrelatiAllaProtesiDentaria"}
                    fieldId={'PROBL_CORR_PROT_DENT.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"problemiCorrelatiAllaProtesiDentaria"}
                    fieldLabel={"Problemi correlati alla protesi dentaria"}
                    pageState={this.props.pageState}
                    options={OggettiUtili.rispostaBreve}
                    value={this.state.problemiCorrelatiAllaProtesiDentaria}
                    onChange={(elem) => this.handleAggiornaForm("problemiCorrelatiAllaProtesiDentaria", elem)}
                    isSearchable={false}
                    handleOnlyValue={true}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />
                {this.state.problemiCorrelatiAllaProtesiDentaria &&
                <RoleBasedInput
                    id={"inputQuali"}
                    fieldId={'QUALI_PROBL.DISPOSITIVIMEDICIPRESENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    fieldLabel={"Indicare quali"}
                    field={"quali"}
                    pageState={this.props.pageState}
                    placeholder={"Indicare quali"}
                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                    value={this.state.quali}
                    fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                />}
            </FieldsRow>
        </>;
    }
}

ProtesiOdontoiatriche.propTypes = propTypes;
