import React, {Component} from "react";
import $ from 'jquery'
import styles from "./Calendar.module.css";
import Formatter from "../../../utils/Formatter";
import Utils from "../../../utils/Utils";
import PropTypes from "prop-types";

window.$ = $;

const imgCalendar = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="24" height="24" fill="#176A65" style="margin-top: 8px;margin-right: 10px">
    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"></path>
    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"></path>
</svg>`;

export default class Calendar extends Component {

    state = {
        value: this.props.value,
    }

    prepareCalendar() {
        $(document).ready(function () {
            $(".visualizzazione .elemento-visualizzazione .it-date-datepicker").datepicker('disable');
            $(".it-date-datepicker").datepicker({
                inputFormat: ["dd/MM/yyyy"],
                outputFormat: "dd/MM/yyyy",
            });
        });
    }

    componentDidMount() {
        var {fixedHeight, id} = this.props;
        $(document).ready(function () {
            for (let calendarIcon of $("a.datepicker-button.input-group-addon.btn.default")) {
                if (calendarIcon) calendarIcon.innerHTML = imgCalendar;
            }

            for (let datePicker of $("div.datepicker-calendar")) {
                if (datePicker) $(datePicker).css({'z-index': '2'});
            }

            if (fixedHeight) $("#datepicker-calendar-" + id).css({"height": fixedHeight});
        });

        this.prepareCalendar();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.value !== this.props.value) {
            this.setState({value: this.props.value})
        }

        this.prepareCalendar();
    }

    updateCaret(event) {
        const caret = event.target.selectionStart
        let element = event.target
        let updateCaret = event.target.value.length
        if (this.props.value && this.props.value.length - event.target.value.length !== 1) {
            event.target.value =
                Formatter.formatDate(event.target.value, "dd/MM/yyyy")
        }
        updateCaret = event.target.value.length - updateCaret
        window.requestAnimationFrame(() => {
            element.selectionStart = caret + updateCaret
            element.selectionEnd = caret + updateCaret
        })
    }


    render() {
        let cssInput = this.props.error ? styles.inputDataError : this.props.disabled ? [styles.inputDataDisabled, "elemento-visualizzazione"].join(" ") : [styles.inputData, "elemento-visualizzazione"].join(" ")
        return (
            <div className={"it-datepicker-wrapper"} style={{width: this.props.size, backgroundColor: "#ffffff"}}>
                <div id="date"
                     className={[cssInput].join(" ").concat(" form-group ").concat(!Utils.isObjectEmpty(this.props.classes) && this.props.classes.includes("auditField") ? " auditField" : "")}
                     height="45px">
                    {this.props.label ? <label className={styles.labelTxtInput} htmlFor={this.props.id ? this.props.id : "date1"}>{this.props.label}{this.props.required ? <span className={styles.required}></span> : null}</label>: null}
                    <input onChange={(event) => {
                        this.updateCaret(event);
                        this.props.newCalendar ? this.props.onChange(this.props.id, event) : this.props.onChange(event)
                    }}
                           autoComplete={"off"}
                           onBlur={(event) => {
                               this.props.newCalendar ? this.props.onChange(this.props.id, event) :
                                   this.props.onChange(event)
                           }}
                           onFocusCapture={(event) => {
                               this.props.newCalendar ? this.props.onChange(this.props.id, event) :
                                   this.props.onChange(event)
                           }}
                           className={["form-control", "it-date-datepicker", "fontSize16", this.props.classes, this.props.required ? styles.placeholder : ""].join(" ")}
                           id={this.props.id ? this.props.id : "date1"}
                           type="text"
                           style={{height: "40px"}}
                           placeholder={this.props.placeholder ? this.props.placeholder : this.props.required ? "gg/mm/aaaa*" : "gg/mm/aaaa"}
                           value={this.state.value}
                           disabled={this.props.disabled}
                    />
                </div>
                <label htmlFor={this.props.id ? this.props.id : "date1"} className={"d-none"}/>
                {(this.props.smallNote || this.props.error) &&
                <div style={{
                    marginTop: this.props.ricerca ? "-0.5rem" : "0rem",
                    marginBottom: this.props.ricerca ? "0.6rem" : "0rem",
                    background: "white",
                    zIndex: 1
                }}>
                    {this.props.smallNote &&
                    <span style={{paddingLeft: "20px", marginRight: "10px", color: "#999", fontSize: "12px"}}>
                        {this.props.smallNote}
                    </span>}

                    {this.props.error &&
                    <span className={styles.errorMessage}>{this.props.errorMessage}</span>}
                </div>}
            </div>
        )
    }
}

Calendar.propTypes = {
    value: PropTypes.string,
    error: PropTypes.bool,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    newCalendar: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string,
    classes: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    ricerca: PropTypes.bool,
    smallNote: PropTypes.string,
    errorMessage: PropTypes.string,
    label: PropTypes.string,
    fixedHeight: PropTypes.string
}
