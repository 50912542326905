import Utils from "../utils/Utils";
import * as _ from "lodash";
import * as $ from "jquery";
import {BehaviorSubject} from "rxjs";

export default class AccordionHelper {
    // variabili utilizzate per tenere traccia degli ultimi accordion/subaccordion aperti dall'utente
    static lastOpenedAccordion = null;
    static lastOpenedSubAccordion = null;

    /* variabile, di tipo String, utilizzata per tenere traccia dell'ultimo questionario compilato dall'utente
     * in modo da posizionarci sul link una volta ritornati dalla pagina di compilazione del questionario.
     */
    static lastSurveyCompiled = null;

    /* variabile, di tipo String, utilizzata per memorizzare gli id degli accordion e sottoaccordion in cui
     * atterrare dopo essere ritornati dalla pagina di compilazione dei questionari. La stringa è composta
     * da tanti id quante sono le voci selezionate, ognuno separato da pipe (|):
     * idAccordionPrimoLivello|idAccordionSecondoLivello|...|idAccordionN-esimoLivello.
     */
    static idAccordionsAfterSurveyPage = null;

    // mappa composta da jsonPath e valore decodificato dei vari campi per cui viene salvato sul db un codice
    static mappaVariazionePathDecodedValue = {};

    // variabili utilizzate per gestire le logiche della barra di ricerca
    static previousSearchedField = null;
    static mappaFieldLabelFieldId = {};

    /* Subject che consente di inviare una risposta (boolean) nel caso in cui il Prosegui
     * è abilitato o meno; viene utilizzato per abilitare o disabilitare le voci del menu relative agli
     * accordion/sottoaccordion presenti nelle pagine successive alla pagina 1
     */
    static abilitazioneProseguiSubject = new BehaviorSubject();

    // variabile utilizzata per memorizzare l'id dell'accordion di primo livello al click del link "Inserisci informazioni aggiuntive"
    static idAccordionToAddInformation = null;
    static cloneIdAccordionToAddInformation = null;

    /* variabile, di tipo String, utilizzata per memorizzare gli id degli accordion selezionati al click di ogni voce
     * e sottovoce del menu. La stringa è composta da tanti id quante sono le voci selezionate, ognuno separato
     * da pipe (|): idAccordionPrimoLivello|idAccordionSecondoLivello|...|idAccordionN-esimoLivello.
     */
    static idSelectedAccordions = null;

    // variabile utilizzata per memorizzare l'ultimo url della pagina in cui ci si trovava nel momento in cui è stata selezionata una voce del menu
    static lastPageUrlBeforeMenuItemClick = null;

    /* Lista dei campi obbligatori presenti nei vari accordion valorizzata in fase di VALUTAZIONE, nel momento
     * in cui viene effettuata una variazione, con i jsonPath dei campi obbligatori per l'utente autenticato.
     */
    static listaCampiObbligatori = [];

    /* Mappa valorizzata dalla response del servizio contaCampiOpzionali: per ogni accordion e sottoaccordion è
     * indicato il numero di campi opzionali calcolato dalle variazioni di tutti i valutatori precedenti al valutatore
     * attualmente autenticato
     */
    static mappaCountOptionalAccordionFields = {};

    /* Subject che consente di effettuare un update del menu impostando il numero di campi opzionali nel caso
     * in cui il servizio abbia qualche rallentamento
     */
    static updateCountOptionalFieldsSubject = new BehaviorSubject();

    // Subject che effettua un emit del titolo della pagina degli accordion in cui ci si trova e lo lega alle voci della breadcrumb in posizione i-esima
    static accordionPageSubject = new BehaviorSubject();

    // Subject che effettua un emit di un booleano per determinare se mostrare o meno l'icona della lente di ingrandimento nella navbar, per la barra di ricerca dei campi degli accordion
    static showSearchIconSubject = new BehaviorSubject();

    // Oggetto contenente le props del questionario selezionato passate durante la redirect alla pagina 'Compila Questionario'
    static propsRichiestaQuestionario = null;

    static visibilityPsanSubject = new BehaviorSubject();
    static isPsanSurveyRequired = null;

    // Subject che emitta un object, dopo aver salvato i campi obbligatori, per potersi redirigere alla pagina con tutti i campi
    static requiredAccordionFieldsSubject = new BehaviorSubject();

    ////// METODI SETTER DELLE VARIABILI SOPRA ISTANZIATE

    static setLastOpenedAccordion = (lastOpenedAccordion) => {
        AccordionHelper.lastOpenedAccordion = lastOpenedAccordion;
    }

    static setLastOpenedSubAccordion = (lastOpenedSubAccordion) => {
        AccordionHelper.lastOpenedSubAccordion = lastOpenedSubAccordion;
    }

    static setLastSurveyCompiled = (lastSurveyCompiled) => {
        AccordionHelper.lastSurveyCompiled = lastSurveyCompiled;
    }

    static setIdAccordionsAfterSurveyPage = (idAccordionsAfterSurveyPage) => {
        AccordionHelper.idAccordionsAfterSurveyPage = idAccordionsAfterSurveyPage;
    }

    static setMappaVariazionePathDecodedValue = (jsonPath, decodedValue) => {
        AccordionHelper.mappaVariazionePathDecodedValue[jsonPath] = decodedValue;
    }

    static setPreviousSearchedField = (previousSearchedField) => {
        AccordionHelper.previousSearchedField = previousSearchedField;
    }

    static setMappaFieldLabelFieldId = (label, fieldId) => {
        if (document.getElementById(fieldId) != null) {
            if (AccordionHelper.mappaFieldLabelFieldId[label] == null) AccordionHelper.mappaFieldLabelFieldId[label] = [];
            if (!AccordionHelper.mappaFieldLabelFieldId[label].includes(fieldId)) AccordionHelper.mappaFieldLabelFieldId[label].push(fieldId);
        }
    }

    static setIdAccordionToAddInformation = (idAccordionToAddInformation) => {
        AccordionHelper.idAccordionToAddInformation = idAccordionToAddInformation;
        if (idAccordionToAddInformation) AccordionHelper.setIdSelectedAccordions(null);
    }

    static setCloneIdAccordionToAddInformation = (idAccordionToAddInformation) => {
        AccordionHelper.cloneIdAccordionToAddInformation = idAccordionToAddInformation;
    }

    static setIdSelectedAccordions = (idSelectedAccordions) => {
        AccordionHelper.idSelectedAccordions = idSelectedAccordions;
    }

    static setLastPageUrlBeforeMenuItemClick = (lastPageUrlBeforeMenuItemClick) => {
        AccordionHelper.lastPageUrlBeforeMenuItemClick = lastPageUrlBeforeMenuItemClick;
    }

    static addToListaCampiObbligatori = (jsonPath) => {
        AccordionHelper.listaCampiObbligatori.push(jsonPath);
        AccordionHelper.listaCampiObbligatori = Array.from(new Set(AccordionHelper.listaCampiObbligatori));
    }

    static setMappaCountOptionalAccordionFields = (mappa, updateMenu = false) => {
        AccordionHelper.mappaCountOptionalAccordionFields = mappa;
        if (updateMenu) AccordionHelper.updateCountOptionalFieldsSubject.next(mappa);
    }

    static setPropsRichiestaQuestionario = (propsRichiestaQuestionario) => {
        AccordionHelper.propsRichiestaQuestionario = propsRichiestaQuestionario;
    }


    ////// METODI DI UTILITY PER ACCORDION

    static resetMappaVariazionePathDecodedValue = () => {
        AccordionHelper.mappaVariazionePathDecodedValue = {};
    }

    static initLastOpenedAccordionInAccordionObject = (accordionObject, lastOpenedAccordion) => {
        let accordion = _.cloneDeep(accordionObject);

        if (!Utils.isStringEmptyOrNullOrUndefined(lastOpenedAccordion)
            && Object.keys(accordion).some(key => key === lastOpenedAccordion)) {
            accordion[lastOpenedAccordion] = true;
        }

        return accordion;
    }

    static getSubAccordionFieldsList = () => {
        const labels = Object.keys(AccordionHelper.mappaFieldLabelFieldId);
        const duplicatedLabels = labels.filter(fieldLabel => AccordionHelper.mappaFieldLabelFieldId[fieldLabel].length > 1);

        const getSubAccordionNameFromFieldId = (fieldId) => {
            const subAccordion = $($($(document.getElementById(fieldId))[0]).parents("div.collapse-div").children().get(0));
            return subAccordion.find("div.row > div.col > button > div.row > div.mr-auto.col-auto > label").text();
        };

        const getDuplicatedLabel = (fieldLabel, fieldId) => `${fieldLabel} - ${getSubAccordionNameFromFieldId(fieldId)}`;

        return labels
            .flatMap(fieldLabel => AccordionHelper.mappaFieldLabelFieldId[fieldLabel]
                .map(fieldId => {
                    if (document.getElementById(fieldId) == null) return null;
                    return duplicatedLabels.includes(fieldLabel)
                        ? {value: fieldId, label: getDuplicatedLabel(fieldLabel, fieldId)}
                        : {value: fieldId, label: fieldLabel};
                })
                .filter(item => item != null));
    }

    static onScrollIntoAccordion = (idsSelected, isRiepilogo = false, timeOut = null) => {
        (idsSelected ?? '').split("|")
            .filter(id => !!id)
            .forEach(id => {
                const elem = document.getElementById(id);
                const canOpenAccordion = $(elem)?.find("> div.collapse.show")?.length === 0;

                if (canOpenAccordion && !isRiepilogo) {
                    let selector = $(elem).find("> div.collapse-header > button") ?? $(elem).find("> div.collapse-header > div.row > div.col > button");
                    selector.trigger('click');
                }

                if (elem != null) {
                    const scroll = () => {
                        const elementPosition = elem.getBoundingClientRect().top;
                        const offsetPosition = elementPosition + window.scrollY - 90;
                        window.scrollTo({top: offsetPosition, behavior: "smooth"});
                    }
                    if (timeOut) setTimeout(scroll, timeOut);
                    else scroll();
                } 

               
                }
            );
        AccordionHelper.idAccordionsAfterSurveyPage = null;
    }


    static worningMessage = () => {
            Utils.toasterFunction({
                type: "confirm",
                text: "La sezione richiesta non è presente"
            })
    }

    static processStrutturaDatiObbligatori = () => {
        const regex = new RegExp(/\.(\w*)_(\w*)\./g);
        const customAccordionJsonPath = AccordionHelper.listaCampiObbligatori.filter(s => s.match(regex));
        const strutturaDatiObbligatori = AccordionHelper.listaCampiObbligatori.map(s => {
            const customJsonPath = customAccordionJsonPath.find(jp => s.replaceAll(regex, ".$1.") === jp.replaceAll(regex, ".$1.")) ?? null;
            return customJsonPath ?? s;
        }).filter((value, index, self) => self.indexOf(value) === index);
        AccordionHelper.listaCampiObbligatori = strutturaDatiObbligatori;

        return strutturaDatiObbligatori;
    }
}
