import React from "react";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionHelper from "../../../service/AccordionHelper";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import LivelloPrioritaRichiestaSub from "./livelloPrioritaRichiestaSub/LivelloPrioritaRichiestaSub";
import Utils from "../../../utils/Utils";
import VersionedAccordion from "../../VersionedAccordion";

const propTypes = {
    dati: PropTypes.any,
    figureProfessionali: PropTypes.bool,
    professionistiSupportoAValutazione: PropTypes.node,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeLivelloPriorita: PropTypes.func,
    pageState: PropTypes.string,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultProps = {
    salvaBozza: () => undefined,
    onClickAccordion: () => undefined,
    onChangeLivelloPriorita: () => undefined,
    onChangeObbligatorieta: () => undefined
}

export default class LivelloPrioritaRichiesta extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: 0,
        accordion: {
            openSubAccordionLivelloPriorita: true
        }
    };

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;
        if (accordion[field]) {
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeRequiredFieldsNumber = (value) => {
        this.setState({campiObbligatoriMancanti: value}, () => {
            this.props.onChangeObbligatorieta("livelloPriorita", this.state.campiObbligatoriMancanti);
        });
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={Utils.isStateChiusuraPreDialogo(this.props.pageState) ? "livelloPrioritaBozza" : "livelloPriorita"}
                title={"Priorità della richiesta di cure domiciliari"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                numberOfRequiredFieldsMissing={Utils.isStateRichiesta(this.props.pageState) || Utils.isStateChiusuraPreDialogo(this.props.pageState) ? this.state.campiObbligatoriMancanti : 0}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}>
                <VersionedAccordion accordion={LivelloPrioritaRichiestaSub}
                                    dati={this.props.dati}
                                    richiesta={this.props.richiesta}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    figureProfessionali={this.props.figureProfessionali}
                                    professionistiSupportoAValutazione={this.props.professionistiSupportoAValutazione}
                                    onChangeLivelloPriorita={this.props.onChangeLivelloPriorita}
                                    openAccordion={this.state.accordion.openSubAccordionLivelloPriorita}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openSubAccordionLivelloPriorita"}
                                    fieldRequired={!Utils.isDimissioneProtetta(this.props.pageState) || Utils.isStateChiusuraPreDialogo(this.props.pageState)}
                                    pageState={this.props.pageState}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
            </AccordionAvvioRichiesta>
        );
    }
}

LivelloPrioritaRichiesta.propTypes = propTypes;
LivelloPrioritaRichiesta.defaultProps = defaultProps;
