import React, {Fragment} from "react";
import {Route} from "react-router-dom";
import Home from "../../components/home/Home";
import Faq from "../../components/faq/Faq";
import {BehaviorSubject} from "rxjs";
import Error from "../../components/error/Error";
import ServizioNonDisponibile from "../../layouts/servizioNonDisponibile/ServizioNonDisponibile";
import NotAuthorized from "../../layouts/notAuthorized/NotAuthorized";
import routepath from "./route-path";
import AuthUtils from "../AuthUtils";
import PannelloConfigurazioneGestioneUtenteADI
    from "../../components/pannelloConfigurazioneGestioneUtenteAdi/PannelloConfigurazioneGestioneUtenteADI";
import PannelloConfigurazioneRicercaUtenteADI
    from "../../components/pannelloConfigurazioneRicercaUtenteAdi/PannelloConfigurazioneRicercaUtenteADI";
import InserimentoRichiesta from "../../components/inserimentoRichiesta/InserimentoRichiesta";
import PaginaDiCortesia from "../../components/paginaDiCortesia/PaginaDiCortesia";
import enumPaginaCompila from "../../enum/enumPaginaCompila.json";
import StatoValutazioneEComponentiDelTeam
    from "../../components/statoValutazioneEComponentiDelTeam/StatoValutazioneEComponentiDelTeam";
import CompilaFormalizzazione from "../../components/compilaFormalizzazione/CompilaFormalizzazione";
import NuovoValutatore from "../../components/nuovoValutatore/NuovoValutatore";
import CentroNotifiche from "../../components/notifiche/CentroNotifiche";
import StoricoRichieste from "../../components/storicoRichieste/StoricoRichieste";
import PropTypes from 'prop-types';
import VisualizzaDettagliRichiestaStorico
    from "../../components/storicoRichieste/visualizzaDettagliStoricoRichiesta/VisualizzaDettagliRichiestaStorico";
import AnagrafeRichiedente from "../../components/anagrafeRichiedente/AnagrafeRichiedente";
import VersionedAccordion from "../../components/VersionedAccordion";
import {CreaPassword,ModificaPassword,RecuperaPassword}  from "../../components/home/gestionePassword/index";
import Utils from "../Utils";
import CompilaChiudiPredialogo from "../../components/compilaChiudiPredialogo/CompilaChiudiPredialogo";
import GestioneDeleghe from "../../components/deleghe/GestioneDeleghe";
import FirmaFormalizzazioni from "../../components/firma/FirmaFormalizzazioni";
import SubentroRichiesteMultiple from "../../components/subentroRichiesteMultiple/SubentroRichiesteMultiple";
import RicercaAssistiti from "../../components/home/ricercaAssistiti/RicercaAssistiti";
import AccordionHelper from "../../service/AccordionHelper";
import ScalaEQuestionari from "../../components/storicoRichieste/scalaEquestionari/ScalaEQuestionari";

const propTypes = {
    className: PropTypes.string,
    changeUrl: PropTypes.func
}

export default class RouteComponent extends React.Component {

    subject = new BehaviorSubject();

    state = {};

    componentDidMount() {
        this.subject.next(true);
        this.subject.complete();
    }

    errorRender = (props) => {
        return (<Error sourceUrl={props.location.state ? props.location.state.sourceUrl : null}/>)
    };


    render() {
        let overlay = <Fragment/>;

        if (window.location.search) {
            overlay = <Fragment/>
        }



        return (
            <div className={this.props.className}>
                {overlay}

                <Route path={routepath.home} render={() => {
                    if (AuthUtils.isUtenteAbilitato()) {
                        this.props.changeUrl();
                        return <Home/>
                    } else {
                        this.props.changeUrl();
                        return <NotAuthorized/>
                    }
                }}/>

                <Route path={routepath.servizio_non_disponibile} render={() => {
                    this.props.changeUrl();
                    return <ServizioNonDisponibile/>
                }}/>

                <Route exact path={routepath.not_authorized} render={() => {
                    this.props.changeUrl();
                    return <NotAuthorized/>
                }}/>

                <Route exact path={routepath.error} render={(props) => {
                    this.errorRender(props)
                }}/>

                <Route key={"addNewUser"} exact path={routepath.inserimento_utente}
                       render={() => {
                           if (AuthUtils.hasUtenteRuoloDiCategoriaAmministrativa() || AuthUtils.hasUtenteRuoloReferenteAulss()) {
                               this.props.changeUrl();
                               return <PannelloConfigurazioneGestioneUtenteADI/>
                           } else {
                               this.props.changeUrl();
                               return <Home/>
                           }
                       }}/>

                <Route key={"editUser"} exact path={routepath.modifica_utente + "/:codiceFiscale"}
                       render={(props) => {
                           if (AuthUtils.hasUtenteRuoloDiCategoriaAmministrativa() || AuthUtils.hasUtenteRuoloReferenteAulss()) {
                               this.props.changeUrl();
                               return <PannelloConfigurazioneGestioneUtenteADI
                                   codiceFiscaleUtenteProfilato={props.match.params.codiceFiscale}/>
                           } else {
                               this.props.changeUrl();
                               return <Home/>
                           }
                       }}/>

                <Route exact path={routepath.pannello_configurazione_ricerca} render={() => {
                    if (AuthUtils.hasUtenteRuoloDiCategoriaAmministrativa() || AuthUtils.hasUtenteRuoloReferenteAulss()) {
                        this.props.changeUrl();
                        return <PannelloConfigurazioneRicercaUtenteADI/>
                    } else {
                        this.props.changeUrl();
                        return <Home/>
                    }
                }}/>

                <Route exact path={routepath.inserimento_richiesta.concat(routepath.compila_scheda_paziente)}
                       render={(props) => {
                           this.props.changeUrl();
                           let newProps = Utils.containsBasicProps(props.location.state) ? props.location.state : props.location;
                           AccordionHelper.showSearchIconSubject.next(!newProps?.showOnlyRequiredFields);
                           return <InserimentoRichiesta uuidRichiesta={newProps.uuidRichiesta}
                                                        pageState={Utils.getPageStateDimissioneProtetta(newProps.tipologia, newProps.stato, enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE) ?? "R"}
                                                        pageName={enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE}
                                                        pathname={newProps.pathname}
                                                        richiesta={newProps.richiesta}
                                                        richiestaMedico={newProps.richiestaMedico}
                                                        persone={newProps?.persone ?? null}
                                                        key={newProps.key}
                                                        showOnlyRequiredFields={!!newProps?.showOnlyRequiredFields}
                                                        previousPageOnlyRequiredFields={!!newProps?.previousPageOnlyRequiredFields}
                                                        scrollIntoAccordionAfterRedirect={!!newProps?.scrollIntoAccordionAfterRedirect}
                                                        showMultiCompilationPages={!!newProps?.showMultiCompilationPages}/>
                       }}/>

                <Route exact
                       path={routepath.valutazione_multidimensionale.concat(routepath.completa_valutazione_multidimensionale)}
                       render={(props) => {
                           this.props.changeUrl();
                           let newProps = Utils.containsBasicProps(props.location.state) ? props.location.state : props.location;
                           AccordionHelper.showSearchIconSubject.next(!newProps?.showOnlyRequiredFields);
                           return <InserimentoRichiesta uuidRichiesta={newProps.uuidRichiesta}
                                                        pageState={Utils.getPageStateDimissioneProtetta(newProps.tipologia, newProps.stato, enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE) ?? "V"}
                                                        pageName={enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE}
                                                        readOnly={false}
                                                        hideUnsetValues={false}
                                                        richiesta={newProps.richiesta}
                                                        richiestaMedico={newProps.richiestaMedico}
                                                        persone={newProps?.persone ?? null}
                                                        pathname={newProps.pathname}
                                                        key={newProps.key}
                                                        valutazionePrecedente={newProps?.valutazionePrecedente}
                                                        showOnlyRequiredFields={!!newProps?.showOnlyRequiredFields}
                                                        previousPageOnlyRequiredFields={!!newProps?.previousPageOnlyRequiredFields}
                                                        scrollIntoAccordionAfterRedirect={!!newProps?.scrollIntoAccordionAfterRedirect}
                                                        showMultiCompilationPages={!!newProps?.showMultiCompilationPages}/>
                       }}/>

                <Route exact
                       path={routepath.valutazione_multidimensionale.concat(routepath.visualizza_valutazione_multidimensionale)}
                       render={(props) => {
                           this.props.changeUrl();
                           let newProps = Utils.containsBasicProps(props.location.state) ? props.location.state : props.location;
                           AccordionHelper.showSearchIconSubject.next(!newProps?.showOnlyRequiredFields);
                           return <InserimentoRichiesta uuidRichiesta={newProps.uuidRichiesta}
                                                        pageState={Utils.getPageStateDimissioneProtetta(newProps.tipologia, newProps.stato, enumPaginaCompila.VISUALIZZA_VALUTAZIONE_MULTIDIMENSIONALE) ?? "V"}
                                                        pageName={enumPaginaCompila.VISUALIZZA_VALUTAZIONE_MULTIDIMENSIONALE}
                                                        readOnly={true}
                                                        hideUnsetValues={false}
                                                        pathname={newProps.pathname}
                                                        key={newProps.key}
                                                        valutazionePrecedente={newProps?.valutazionePrecedente}
                                                        showOnlyRequiredFields={!!newProps?.showOnlyRequiredFields}
                                                        previousPageOnlyRequiredFields={!!newProps?.previousPageOnlyRequiredFields}
                                                        lastPageNumber={newProps?.lastPageNumber}
                                                        scrollIntoAccordionAfterRedirect={!!newProps?.scrollIntoAccordionAfterRedirect}
                                                        showMultiCompilationPages={!!newProps?.showMultiCompilationPages}/>
                       }}/>

                <Route exact
                       path={routepath.valutazione_multidimensionale.concat(routepath.accedi_valutazione_multidimensionale)}
                       render={(props) => {
                           this.props.changeUrl();
                           let newProps = Utils.containsBasicProps(props.location.state) ? props.location.state : props.location;
                           AccordionHelper.showSearchIconSubject.next(!newProps?.showOnlyRequiredFields);
                           return <InserimentoRichiesta uuidRichiesta={newProps.uuidRichiesta}
                                                        pageState={Utils.getPageStateDimissioneProtetta(newProps.tipologia, newProps.stato, enumPaginaCompila.ACCEDI_VALUTAZIONE_MULTIDIMENSIONALE) ?? "V"}
                                                        pageName={enumPaginaCompila.ACCEDI_VALUTAZIONE_MULTIDIMENSIONALE}
                                                        readOnly={true}
                                                        hideUnsetValues={false}
                                                        pathname={newProps.pathname}
                                                        key={newProps.key}
                                                        valutazionePrecedente={newProps?.valutazionePrecedente}
                                                        showOnlyRequiredFields={!!newProps?.showOnlyRequiredFields}
                                                        previousPageOnlyRequiredFields={!!newProps?.previousPageOnlyRequiredFields}
                                                        lastPageNumber={newProps?.lastPageNumber}
                                                        scrollIntoAccordionAfterRedirect={!!newProps?.scrollIntoAccordionAfterRedirect}
                                                        showMultiCompilationPages={!!newProps?.showMultiCompilationPages}/>
                       }}/>

                <Route exact
                       path={[routepath.inserimento_richiesta.concat(routepath.compila_proposte), routepath.valutazione_multidimensionale.concat(routepath.compila_proposte)]}
                       render={(props) => {
                           this.props.changeUrl();
                           AccordionHelper.showSearchIconSubject.next(!props.location?.showOnlyRequiredFields);
                           return <InserimentoRichiesta uuidRichiesta={props.location.uuidRichiesta}
                                                        pageState={props.location.pageState}
                                                        pageName={enumPaginaCompila.COMPILA_PROPOSTE}
                                                        readOnly={props.location.readOnly}
                                                        hideUnsetValues={props.location.hideUnsetValues}
                                                        firstPageName={props.location.firstPageName}
                                                        richiesta={props.location.richiesta}
                                                        richiestaMedico={props.location.richiestaMedico}
                                                        persone={props.location?.persone ?? null}
                                                        pathname={props.location.pathname}
                                                        key={props.location.key}
                                                        valutazionePrecedente={props.location?.valutazionePrecedente ?? null}
                                                        showOnlyRequiredFields={!!props.location?.showOnlyRequiredFields}
                                                        previousPageOnlyRequiredFields={!!props.location?.previousPageOnlyRequiredFields}
                                                        scrollIntoAccordionAfterRedirect={!!props.location?.scrollIntoAccordionAfterRedirect}
                                                        showMultiCompilationPages={true}/>
                       }}/>

                <Route exact
                       path={[routepath.inserimento_richiesta.concat(routepath.visualizza_riepilogo), routepath.valutazione_multidimensionale.concat(routepath.visualizza_riepilogo)]}
                       render={(props) => {
                           this.props.changeUrl();
                           AccordionHelper.showSearchIconSubject.next(!props.location?.showOnlyRequiredFields);
                           return <InserimentoRichiesta uuidRichiesta={props.location.uuidRichiesta}
                                                        pageState={props.location.pageState}
                                                        pageName={enumPaginaCompila.VISUALIZZA_RIEPILOGO}
                                                        richiesta={props.location?.richiesta}
                                                        richiestaMedico={props.location.richiestaMedico}
                                                        forcedModalitaRiepilogo={props.location.forcedModalitaRiepilogo}
                                                        readOnly={props.location.readOnly}
                                                        hideUnsetValues={props.location.hideUnsetValues}
                                                        firstPageName={props.location.firstPageName}
                                                        pathname={props.location.pathname}
                                                        key={props.location.key}
                                                        persone={props.location.persone ?? null}
                                                        valutazionePrecedente={props.location?.valutazionePrecedente ?? null}
                                                        showOnlyRequiredFields={!!props.location?.showOnlyRequiredFields}
                                                        previousPageOnlyRequiredFields={!!props.location?.previousPageOnlyRequiredFields}
                                                        scrollIntoAccordionAfterRedirect={!!props.location?.scrollIntoAccordionAfterRedirect}
                                                        showMultiCompilationPages={!!props.location?.showMultiCompilationPages}/>
                       }}/>

                <Route exact
                       path={[routepath.inserimento_richiesta.concat(routepath.compila_questionario), routepath.valutazione_multidimensionale.concat(routepath.compila_questionario)]}
                       render={(props) => {
                           this.props.changeUrl();
                           return <InserimentoRichiesta
                               uuidRichiesta={props.location.richiesta.id}
                               pageName={enumPaginaCompila.COMPILA_QUESTIONARIO}
                               richiesta={props.location.richiesta}
                               tipoQuestionario={props.location.tipoQuestionario}
                               pageState={props.location.pageState}
                               pathRichiestaQuestionario={props.location.pathRichiestaQuestionario}
                               sessoPaziente={props.location.sessoPaziente}
                               labelSezioneObbligatoria={!!props.location?.labelSezioneObbligatoria}
                               showOnlyRequiredFields={!!props.location?.showOnlyRequiredFields}
                               infoRichiesta={props.location?.infoRichiesta}
                               redirectPage={props.location?.redirectPage}
                               firstPageName={props.location?.firstPageName}
                               previousPageOnlyRequiredFields={props.location?.previousPageOnlyRequiredFields}
                               obbligatorieta={props.location?.obbligatorieta}
                               disabilitato={props.location?.disabilitato}
                               tornaIndietro={props.location?.tornaIndietro}
                               showMultiCompilationPages={!!props.location?.showMultiCompilationPages}
                           />
                       }}/>

                <Route exact
                       path={[
                           routepath.valutazione_multidimensionale + routepath.completa_valutazione_multidimensionale + routepath.stato_valutazione_componenti_team,
                           routepath.valutazione_multidimensionale + routepath.compila_proposte + routepath.stato_valutazione_componenti_team,
                           routepath.valutazione_multidimensionale + routepath.visualizza_riepilogo + routepath.stato_valutazione_componenti_team,
                           routepath.valutazione_multidimensionale + routepath.visualizza_valutazione_multidimensionale + routepath.stato_valutazione_componenti_team,
                           routepath.valutazione_multidimensionale + routepath.accedi_valutazione_multidimensionale + routepath.stato_valutazione_componenti_team,
                           routepath.compila_formalizzazione + routepath.stato_valutazione_componenti_team,
                           routepath.compila_chiusura_predialogo + routepath.compila_scheda_paziente + routepath.stato_valutazione_componenti_team,
                           routepath.compila_chiusura_predialogo + routepath.compila_proposte + routepath.stato_valutazione_componenti_team,
                           routepath.compila_chiusura_predialogo + routepath.visualizza_riepilogo + routepath.stato_valutazione_componenti_team,
                           routepath.verifica_valutazione + routepath.stato_valutazione_componenti_team
                       ]}
                       render={(props) => {
                           this.props.changeUrl();
                           const {
                                rediretDaHome, 
                                tipologia, 
                                stato, 
                                uuidRichiesta, 
                                pathname, 
                                key,
                                persone, 
                                showOnlyRequiredFields, 
                                previousPageOnlyRequiredFields, 
                                scrollIntoAccordionAfterRedirect,
                                showMultiCompilationPages
                           } = props.location;

                           if (rediretDaHome) {
                               return <InserimentoRichiesta 
                                   uuidRichiesta={uuidRichiesta}
                                   pageState={Utils.getPageStateDimissioneProtetta(tipologia, stato, enumPaginaCompila.VISUALIZZA_RIEPILOGO) ?? "V"}
                                   pageName={enumPaginaCompila.STATO_VALUTAZIONE_COMPONENTI_TEAM}
                                   pathname={pathname}
                                   key={key}
                                   persone={persone ?? null}
                                   showOnlyRequiredFields={!!showOnlyRequiredFields}
                                   previousPageOnlyRequiredFields={!!previousPageOnlyRequiredFields}
                                   scrollIntoAccordionAfterRedirect={!!scrollIntoAccordionAfterRedirect}
                                   showMultiCompilationPages={!!showMultiCompilationPages}/>
                           }

                           return <StatoValutazioneEComponentiDelTeam
                               key={props.location?.key}
                               pathname={props.location.pathname}
                               richiesta={props.location.richiesta}
                               pageState={props.location.pageState}
                               showAllMenuItems={!!props.location.showAllMenuItems}
                               readOnly={!!props.location.readOnly}
                               hideUnsetValues={!!props.location.hideUnsetValues}
                               richiestaMedico={props.location.richiestaMedico}
                               persone={props.location.persone}
                               showOnlyRequiredFields={!!props.location?.showOnlyRequiredFields}
                               containsOnlyRequiredFields={!!props.location.containsOnlyRequiredFields}
                               previousPageOnlyRequiredFields={!!props.location?.previousPageOnlyRequiredFields}
                               showOptionalFieldsCount={!!props.location.showOptionalFieldsCount}
                               showMultiCompilationPages={!!props.location?.showMultiCompilationPages}
                           />
                       }}/>

                <Route exact
                       path={routepath.valutazione_multidimensionale.concat(routepath.inserimento_nuovo_valutatore)}
                       render={(props) => {
                           this.props.changeUrl();
                           return <NuovoValutatore
                               richiesta={props.location.richiesta}
                               pageState={props.location.pageState}
                               pageName={props.location.pageName}
                               showOnlyRequiredFields={!!props.location?.showOnlyRequiredFields}
                               previousPageOnlyRequiredFields={!!props.location?.previousPageOnlyRequiredFields}
                               showMultiCompilationPages={!!props.location?.showMultiCompilationPages}
                           />
                       }}/>

                <Route exact
                       path={[
                           routepath.anagrafica_medico_richiedente,
                           routepath.inserimento_richiesta + routepath.compila_scheda_paziente + routepath.anagrafica_medico_richiedente,
                           routepath.inserimento_richiesta + routepath.compila_proposte + routepath.anagrafica_medico_richiedente,
                           routepath.inserimento_richiesta + routepath.visualizza_riepilogo + routepath.anagrafica_medico_richiedente,
                           routepath.valutazione_multidimensionale + routepath.completa_valutazione_multidimensionale + routepath.anagrafica_medico_richiedente,
                           routepath.valutazione_multidimensionale + routepath.compila_proposte + routepath.anagrafica_medico_richiedente,
                           routepath.valutazione_multidimensionale + routepath.visualizza_riepilogo + routepath.anagrafica_medico_richiedente,
                           routepath.valutazione_multidimensionale + routepath.visualizza_valutazione_multidimensionale + routepath.anagrafica_medico_richiedente,
                           routepath.valutazione_multidimensionale + routepath.accedi_valutazione_multidimensionale + routepath.anagrafica_medico_richiedente,
                           routepath.valutazione_multidimensionale + routepath.completa_valutazione_multidimensionale + routepath.stato_valutazione_componenti_team + routepath.anagrafica_medico_richiedente,
                           routepath.valutazione_multidimensionale + routepath.compila_proposte + routepath.stato_valutazione_componenti_team + routepath.anagrafica_medico_richiedente,
                           routepath.valutazione_multidimensionale + routepath.visualizza_riepilogo + routepath.stato_valutazione_componenti_team + routepath.anagrafica_medico_richiedente,
                           routepath.valutazione_multidimensionale + routepath.visualizza_valutazione_multidimensionale + routepath.stato_valutazione_componenti_team + routepath.anagrafica_medico_richiedente,
                           routepath.valutazione_multidimensionale + routepath.accedi_valutazione_multidimensionale + routepath.stato_valutazione_componenti_team + routepath.anagrafica_medico_richiedente,
                           routepath.compila_formalizzazione + routepath.anagrafica_medico_richiedente,
                           routepath.compila_formalizzazione + routepath.stato_valutazione_componenti_team + routepath.anagrafica_medico_richiedente,
                           routepath.compila_chiusura_predialogo + routepath.compila_scheda_paziente + routepath.anagrafica_medico_richiedente,
                           routepath.compila_chiusura_predialogo + routepath.compila_proposte + routepath.anagrafica_medico_richiedente,
                           routepath.compila_chiusura_predialogo + routepath.visualizza_riepilogo + routepath.anagrafica_medico_richiedente,
                           routepath.compila_chiusura_predialogo + routepath.compila_scheda_paziente + routepath.stato_valutazione_componenti_team + routepath.anagrafica_medico_richiedente,
                           routepath.compila_chiusura_predialogo + routepath.compila_proposte + routepath.stato_valutazione_componenti_team + routepath.anagrafica_medico_richiedente,
                           routepath.compila_chiusura_predialogo + routepath.visualizza_riepilogo + routepath.stato_valutazione_componenti_team + routepath.anagrafica_medico_richiedente,
                           routepath.verifica_valutazione + routepath.anagrafica_medico_richiedente,
                           routepath.verifica_valutazione + routepath.stato_valutazione_componenti_team + routepath.anagrafica_medico_richiedente
                       ]}
                       render={(props) => {
                           this.props.changeUrl();
                           AccordionHelper.showSearchIconSubject.next(true);
                           return <VersionedAccordion accordion={AnagrafeRichiedente}
                                                      key={props.location?.key}
                                                      uuidRichiesta={props.location.uuidRichiesta}
                                                      pageState={props.location.pageState}
                                                      pageName={props.location.pageName}
                                                      readOnly={props.location.readOnly}
                                                      hideUnsetValues={props.location.hideUnsetValues}
                                                      firstPageName={props.location.firstPageName}
                                                      idPersona={props.location.idPersona}
                                                      insRichiestaPathname={props.location.insRichiestaPathname}
                                                      richiesta={props.location.richiesta}
                                                      pageNumber={props.location?.pageNumber ?? null}
                                                      showOnlyRequiredFields={!!props.location?.showOnlyRequiredFields}
                                                      previousPageOnlyRequiredFields={!!props.location?.previousPageOnlyRequiredFields}
                                                      profileAction={!!props.location?.profileAction}
                                                      showMultiCompilationPages={!!props.location?.showMultiCompilationPages}/>
                       }}/>

                <Route exact path={routepath.centro_notifiche} render={() => {
                    this.props.changeUrl();
                    return <CentroNotifiche/>
                }}/>

                <Route exact path={routepath.storico_richieste} render={() => {
                    this.props.changeUrl();
                    return <StoricoRichieste/>
                }}/>

                <Route exact path={routepath.faq} render={() => {
                    this.props.changeUrl();
                    return <Faq/>
                }}/>

                <Route exact path={routepath.ricercaPaziente} render={(props) => {
                    this.props.changeUrl();
                    return <RicercaAssistiti 
                                clickSearchButton={!props?.location?.statoAvviaNuovoPreDialogo && !props?.location?.statoAvviaNuovaRichiesta}
                                impostaAvviaNuovaRichiesta={props?.location?.impostaAvviaNuovaRichiesta || (() => null)}
                                statoAvviaNuovaRichiesta={props?.location?.statoAvviaNuovaRichiesta || false}
                                impostaAvviaNuovoPreDialogo={props?.location?.impostaAvviaNuovoPreDialogo || (() => null)}
                                statoAvviaNuovoPreDialogo={props?.location?.statoAvviaNuovoPreDialogo || false}
                                apriAnnullamentoRichiesta={props?.location?.apriAnnullamentoRichiesta || (() => null)}
                                apriSubentroRichiesta={props?.location?.apriSubentroRichiesta || false} 
                            />
                }} />

                <Route exact
                       path={routepath.storico_richieste.concat(routepath.visualizza_dettagli_richiesta_storico)}
                       render={(props) => {
                           this.props.changeUrl();
                           return <VisualizzaDettagliRichiestaStorico uuidRichiesta={props.location.uuidRichiesta}/>
                       }}/>

                <Route exact
                    path={routepath.storico_richieste.concat(routepath.scalaEquestionario)}
                    render={(props) => {
                        this.props.changeUrl();
                        return <ScalaEQuestionari uuidRichiesta={props.location.uuidRichiesta} />
                    }} />


                <Route exact path={routepath.verifica_valutazione}
                       render={(props) => {
                           this.props.changeUrl();
                           let newProps = Utils.containsBasicProps(props.location.state) ? props.location.state : props.location;
                           AccordionHelper.showSearchIconSubject.next(!newProps?.showOnlyRequiredFields);
                           return <InserimentoRichiesta uuidRichiesta={newProps.uuidRichiesta}
                                                        pageState={Utils.getPageStateDimissioneProtetta(newProps.tipologia, newProps.stato, enumPaginaCompila.VISUALIZZA_RIEPILOGO) ?? "V"}
                                                        pageName={enumPaginaCompila.VISUALIZZA_RIEPILOGO}
                                                        pathname={newProps.pathname}
                                                        key={newProps.key}
                                                        persone={newProps.persone ?? null}
                                                        showOnlyRequiredFields={!!newProps?.showOnlyRequiredFields}
                                                        previousPageOnlyRequiredFields={!!newProps?.previousPageOnlyRequiredFields}
                                                        scrollIntoAccordionAfterRedirect={!!newProps?.scrollIntoAccordionAfterRedirect}
                                                        showMultiCompilationPages={!!newProps?.showMultiCompilationPages}/>
                       }}/>

                <Route exact path={routepath.compila_formalizzazione}
                       render={(props) => {
                           this.props.changeUrl();
                           AccordionHelper.showSearchIconSubject.next(true);
                           let newProps = Utils.containsBasicProps(props.location.state) ? props.location.state : props.location;
                           return <CompilaFormalizzazione
                               uuidRichiesta={newProps.uuidRichiesta}
                               pageState={Utils.getPageStateDimissioneProtetta(newProps.tipologia, newProps.stato, enumPaginaCompila.COMPILA_FORMALIZZAZIONE) ?? "F"}
                               pageName={enumPaginaCompila.COMPILA_FORMALIZZAZIONE}
                               pathname={newProps.pathname}/>
                       }}/>

                <Route exact
                       path={[
                           routepath.compila_chiusura_predialogo + routepath.compila_scheda_paziente,
                           routepath.compila_chiusura_predialogo + routepath.compila_proposte,
                           routepath.compila_chiusura_predialogo + routepath.visualizza_riepilogo
                       ]}
                       render={(props) => {
                           this.props.changeUrl();
                           let newProps = Utils.containsBasicProps(props.location.state) ? props.location.state : props.location;
                           AccordionHelper.showSearchIconSubject.next(true);
                           return <CompilaChiudiPredialogo
                               uuidRichiesta={newProps.uuidRichiesta}
                               pageState={Utils.getPageStateDimissioneProtetta(newProps.tipologia, newProps.stato, enumPaginaCompila.COMPILA_CHIUDI_PREDIALOGO) ?? "F"}
                               pageName={newProps?.pageName ?? enumPaginaCompila.COMPILA_CHIUDI_PREDIALOGO}
                               readOnly={newProps.readOnly}
                               hideUnsetValues={newProps.hideUnsetValues}
                               richiesta={newProps.richiesta}
                               richiestaMedico={newProps.richiestaMedico}
                               persone={newProps.persone}
                               firstPageName={newProps.firstPageName}
                               pageNumber={newProps.pageNumber}
                               forcedModalitaRiepilogo={newProps.forcedModalitaRiepilogo}
                               pathname={newProps.pathname}/>
                       }}/>

                <Route exact
                       path={routepath.deleghe}
                       render={(props) => {
                           this.props.changeUrl();
                           return <GestioneDeleghe name={props.location.name} profileAction={!!props.location?.profileAction}/>
                       }}/>

                <Route exact
                       path={routepath.censimentoCentraleADI}
                       render={(props) => {
                           this.props.changeUrl();
                           return <GestioneDeleghe name={props.location.name} profileAction={!!props.location?.profileAction}/>
                       }}/>

                <Route exact
                       path={routepath.nominaAutomaticaProfessionisti}
                       render={(props) => {
                           this.props.changeUrl();
                           return <GestioneDeleghe name={props.location.name} profileAction={!!props.location?.profileAction}/>
                       }}/>

                <Route exact path={routepath.not_found} render={(props) => {
                    return <PaginaDiCortesia title={props.location.state.title}
                                             motivation={props.location.state.motivation}/>
                }}/>

                <Route exact
                       path={routepath.firme}
                       render={(props) => {
                           this.props.changeUrl();
                           return <FirmaFormalizzazioni profileAction={!!props.location?.profileAction}/>
                       }}/>

                <Route exact
                       path={routepath.subentroRichiesteMultiple}
                       render={(props) => {
                           this.props.changeUrl();
                           return <SubentroRichiesteMultiple profileAction={!!props.location?.profileAction}
                                                             name={props.location?.name}/>
                       }}/>

                <Route exact
                       path={routepath.presaInCaricoRichiesteMultiple}
                       render={(props) => {
                           this.props.changeUrl();
                           return <SubentroRichiesteMultiple profileAction={!!props.location?.profileAction}
                                                             name={props.location?.name}/>
                       }}/>

                <Route exact path={routepath.crea_password} render={() => {
                    this.props.changeUrl();
                    return <CreaPassword/>
                }}/>

                <Route exact path={routepath.cambia_password} render={() => {
                    this.props.changeUrl();
                    return <ModificaPassword />
                }} />

                <Route exact
                       path={[
                           routepath.recupera_password,
                           routepath.ripristino_password_scaduta
                       ]}
                       render={(props) => {
                           this.props.changeUrl();
                           return <RecuperaPassword pathname={props.location.pathname}/>
                       }}/>

            </div>
        );
    }
}

RouteComponent.propTypes = propTypes;
