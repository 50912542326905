import React from "react";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import PropTypes from "prop-types";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import * as _ from "lodash";
import {FieldsRow, RoleBasedInput, RoleBasedSelect, SectionList} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";
import {v4 as uuid} from "uuid";

const propTypes = {
    datiContributiEconomici: PropTypes.any,
    onChangeDatiAssistito: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

const defaultTipologia = {
    id: null,
    codice: null,
    altraTipologia: null
}


export default class ContributiEconomiciSostegno extends AccordionSecondLevel {
    state = {
        datiContributiEconomici: _.cloneDeep(this.props.datiContributiEconomici)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiContributiEconomici, this.props.datiContributiEconomici))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiContributiEconomici = _.cloneDeep(this.props.datiContributiEconomici);
        if (datiContributiEconomici.tipologia === null) {
            datiContributiEconomici.tipologia = [];
        }
        this.setState({datiContributiEconomici});
    }

    formatObjectForBackend = () => {
        let datiContributiEconomici = _.cloneDeep(this.state.datiContributiEconomici);
        if (datiContributiEconomici.tipologia.length > 0) {
            datiContributiEconomici.tipologia = Utils.getDistinctListByField(datiContributiEconomici.tipologia, "codice");
        }
        this.props.onChangeDatiAssistito("datiContributiEconomici", datiContributiEconomici);
    }

    handleAggiornaForm = (field, elem, index) => {
        let datiContributiEconomici = _.cloneDeep(this.state.datiContributiEconomici);

        if (field === "contributiEconomici" && !_.isEqual(elem, this.state.datiContributiEconomici.contributiEconomici)) {
            datiContributiEconomici.contributiEconomici = elem;
            datiContributiEconomici.tipologia = [];
        } else if (field !== "contributiEconomici") {
            datiContributiEconomici.tipologia[index][field] = elem;
            if (datiContributiEconomici.tipologia[index]?.codice !== "07")
                datiContributiEconomici.tipologia[index].altraTipologia = null;
        }

        this.setState({datiContributiEconomici}, () => this.formatObjectForBackend());
    }

    addNewTipologia = () => {
        let datiContributiEconomici = _.cloneDeep(this.state.datiContributiEconomici);
        let defaultTipologiaClone = _.cloneDeep(defaultTipologia);
        defaultTipologiaClone.id = uuid();
        datiContributiEconomici.tipologia.push(defaultTipologiaClone);
        this.setState({datiContributiEconomici}, () => this.formatObjectForBackend());
    }

    removeTipologia = (index) => {
        let datiContributiEconomici = _.cloneDeep(this.state.datiContributiEconomici);
        datiContributiEconomici.tipologia.splice(index, 1);
        this.setState({datiContributiEconomici}, () => this.formatObjectForBackend());
    }

    renderTipologia = (tipologia, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'TIPO_CONTRIBUTI.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                    pageState={this.props.pageState}
                    id={"selectTipologia"}
                    options={OggettiUtili.tipologiaContributi}
                    value={tipologia.codice}
                    onChange={(elem) => this.handleAggiornaForm("codice", elem, index)}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Tipologia non esistente"}
                    fieldLabel={'Tipologia'}
                    handleOnlyValue={true}
                    field={"codice"}
                    parentJsonPath={"situazioneSociale.datiContributiEconomici"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
                {tipologia.codice === "07" &&
                    <RoleBasedInput
                        fieldId={'TIPO_CONTRIBUTI.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        fieldLabel={"Specificare"}
                        value={tipologia.altraTipologia}
                        placeholder="Inserisci altra tipologia"
                        field="altraTipologia"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        id={"altraTipologia"}
                        parentJsonPath={"situazioneSociale.datiContributiEconomici"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />}
            </FieldsRow>
        );
    }

    renderBodySezione = () => {
        let datiContributiEconomici = this.state.datiContributiEconomici;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PRES_CONTR_ECO_SOST.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        id={"selectContributiEconomici"}
                        className={"selectContributiEconomici"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiContributiEconomici.contributiEconomici}
                        onChange={(elem) => this.handleAggiornaForm("contributiEconomici", elem, -1)}
                        isSearchable={false}
                        fieldLabel={'Presenti contributi economici di sostegno'}
                        handleOnlyValue={true}
                        field={"contributiEconomici"}
                        parentJsonPath={"situazioneSociale.datiContributiEconomici"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
                {datiContributiEconomici.contributiEconomici &&
                    <SectionList
                        title={'Tipologia'}
                        data={this.state.datiContributiEconomici.tipologia}
                        renderSection={this.renderTipologia}
                        addNewSectionCallback={this.addNewTipologia}
                        removeSectionCallback={this.removeTipologia}
                        pageState={this.props.pageState}
                        keyFieldId={'TIPO_CONTRIBUTI.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                        indentOffset={0}
                        field={'tipologia'}
                        addButtonVisibilityHandler={this.state.datiContributiEconomici.tipologia.every(el => !!el.codice)}
                        parentJsonPath={"situazioneSociale.datiContributiEconomici.tipologia"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />}
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiContributiEconomici"}
                title={"Supporto sociale: Contributi economici di sostegno"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["situazioneSociale.datiContributiEconomici.tipologia"],
                    [defaultTipologia]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }
}

ContributiEconomiciSostegno.propTypes = propTypes;
