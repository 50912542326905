import React, {Component} from "react";
import styles from "./RadioButton.module.css";
import PropTypes from "prop-types";
import _ from "lodash";

/*
Props da inserire:

    Obbligatorie:
        items: Array di oggetti (uno per radiobutton), contenenti i seguenti attributi
          - value: Any (obbligatorio), il valore del radiobutton
          - label: String (obbligatorio), la label del radiobutton
          - disabled: Boolean (opzionale), specifica se il radiobutton è selezionabile (se non specificato, di default è selezionabile)
        onChange: una funzione che riceve in input due parametri:
            - il nome dei radiobutton (la props group passata al componente)
            - il valore del radiobutton selezionato

     Opzionali:
        defaultChoice: Numero, posizione nell'array del radiobutton da impostare come predefinito
        id: Stringa, per l'id dei radiobutton (composto da id input + posizione array); default: "radio" + posizione array
        group: name dei radiobutton; default: "radio"
        position: allineamento per visualizzazione (valori possibili: "horizontal", "vertical"; default: "horizontal")
        customStyles: proprietà per applicare lo stile al gruppo dei radio button
        customItemStyles: proprietà per applicare lo stile al singolo radio button
        customItemClassName: proprietà per applicare una classe css al singolo radio button
        customFormCheckClassName: proprietà per applicare una classe css al div del contenuto del singolo radiobutton
        customRadioButtonContainerRow: proprietà per applicare una classe css alla riga che contiene i radiobutton
        colorFont: il font dei radiobutton
        showLabel: se false non viene visualizzata la label del radio, di default è true
        disabled: Boolean, possibilità di cliccare i radiobutton (default: false)
        customAdditionLabelStyle: Object, stile custom per una label aggiuntiva accanto al radio+label
*/

const propTypes = {
    items: PropTypes.array,
    id: PropTypes.string,
    group: PropTypes.string,
    onChange: PropTypes.func,
    defaultChoice: PropTypes.number,
    position: PropTypes.string,
    colorFont: PropTypes.string,
    disabled: PropTypes.bool,
    showLabel: PropTypes.bool,
    customStyles: PropTypes.object,
    customItemStyles: PropTypes.object,
    customItemClassName: PropTypes.object,
    customFormCheckClassName: PropTypes.object,
    customRadioButtonContainerRow: PropTypes.object,
    customAdditionLabelStyle: PropTypes.object
};

const defaultProps = {
    items: [],
    colorFont: "#0073E6",
    defaultChoice: null,
    position: "horizontal",
    disabled: false,
    showLabel: true,
    customFormCheckClassName: "form-check my-0",
    customRadioButtonContainerRow: styles.radioButtonContainerRow,
    customAdditionLabelStyle: {}
}

class RadioButton extends Component {

    state = {
        items: this.props.items,
        selectedOption: null,
        sceltaDefault: false
    };

    componentDidMount() {
        if (this.props.defaultChoice !== null) {
            this.setState({selectedOption: this.props.defaultChoice, sceltaDefault: true})
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.defaultChoice != null && prevProps.defaultChoice !== this.props.defaultChoice) { // lasciare esplicito il confronto con != null, o nascono problemi se defaultChoice == 0
            this.setState({selectedOption: this.props.defaultChoice, sceltaDefault: true})
        }

        if (this.props.items != null && prevProps.items != null && (!(_.isEqualWith(this.props.items, prevProps.items, this.customizer)))) {
            this.setState({items: this.props.items})
        }
    }

    customizer = (objValue, othValue) => {
        if (_.isEqual(objValue, othValue)) {
            return true;
        }
    }

    handleOnChange = (e) => {
        let currentOptionIndex = Number.parseInt(e.currentTarget.value)
        this.setState({selectedOption: currentOptionIndex, sceltaDefault: false})

        if (this.props.onChange) {
            this.props.onChange(this.props.group, this.state.items[currentOptionIndex].value)
        }
    }

    render() {
        const itemClass = this.props.position === "vertical" ? 'col-12' : 'col-auto';
        const items = [];
        for (const [index, item] of this.state.items.entries()) {
            let isChecked = (index === this.state.selectedOption);
            let buttonId = (this.props.id != null) ? (this.props.id + index) : ("radio" + index);
            let buttonName = (this.props.group != null) ? (this.props.group) : ("radio");

            let disableAllItems = this.props.disabled;

            // L'attributo disabled negli item è opzionale: se non è presente, imposto default a false; se è presente, leggo il valore specificato nell'item
            let disableThisItem = (item.disabled === undefined) ? false : item.disabled;

            items.push(
                <div key={index} className={itemClass + (this.props.customItemClassName ? ' ' + this.props.customItemClassName : '')} style={this.props.customItemStyles}>
                    <div key={index} className={this.props.customFormCheckClassName}>
                        <input
                            onClick={this.handleOnChange}
                            name={buttonName}
                            disabled={disableAllItems || disableThisItem}
                            type="radio"
                            id={buttonId}
                            checked={isChecked}
                            value={index}
                        />
                        <label
                            id={"labelRadioButton" + buttonName + index}
                            className={"labelHeader my-0 ".concat(styles.fontRadioButton)}
                            style={{color: this.props.colorFont}}
                            htmlFor={buttonId}>
                            {this.props.showLabel ? item.label : ""}
                        </label>
                        {item?.optionalFieldLabel != null && item?.optionalFieldLabel !== "" &&
                            <span style={this.props.customAdditionLabelStyle}>{item?.optionalFieldLabel}</span>}
                    </div>
                </div>
            );
        }

        return (
            <div className={'row'} style={this.props.customStyles}>
                {items}
            </div>
        );
    }
}

RadioButton.propTypes = propTypes
RadioButton.defaultProps = defaultProps

export default RadioButton;
