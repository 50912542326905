import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {FieldsRow, RoleBasedInput} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    dati: PropTypes.any,
    onChangeEsameObiettivo: PropTypes.func,
    pageState: PropTypes.any,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

export default class UrogenitaleFemminileOstetrico extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.dati)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.dati);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({dati}) => {
            dati[field] = elem;
            if (Utils.isStringEmptyOrNullOrUndefined(dati.note)) {
                dati.condizioneRilevata = null;
            }
            return {dati};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        const dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("urogenitaleFemminileOstetrico", dati);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneUrogenitaleFemminileOstetrico", campiObbligatoriRimanenti);
    }

    renderBodySezione() {
        const dati = this.state.dati;
        return (
            <>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'NOTE.UROGEITALEFEMMINILEOSTETRICO.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci note"}
                        field={"note"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"note"}
                        value={dati.note}
                        fieldLabel={"Note"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}

                    />
                </FieldsRow>
                <CondizioneRilevataSection
                    ambito={'UROGENITALE FEMMINILE OSTETRICO'}
                    parentFieldId={'UROGEITALEFEMMINILEOSTETRICO.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={dati.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={dati.daAttenzionare}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                    showSuggerimenti={true}
                    openCondition={!Utils.isStringEmptyOrNullOrUndefined(dati.note)}
                />
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (<AccordionSezioneRichiesta
                idAccordion={"urogenitaleFemminileOstetrico"}
                title={"Urogenitale femminile ostetrico" + (Utils.isStringEmptyOrNullOrUndefined(this.props.sessoPaziente) ? " !!!" : "")}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.urogenitaleFemminileOstetrico.propostaAttivitaSuggerimenti"],
                        [defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>);
        } else {
            return this.renderBodySezione();
        }
    }
}
UrogenitaleFemminileOstetrico.propTypes = propTypes;

UrogenitaleFemminileOstetrico.defaultProps = {
    showAccordion: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

