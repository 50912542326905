import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import AuthUtils from "../../../utils/AuthUtils";
import styles from "./SchedaProfessionista.module.css";
import {Button, Icon, NavbarDropdown, SessioneUtente} from "web-client-archetype";
import {
    isRuoloMedicoSpecialista,
    RUOLI_SELEZIONABILI_DA_COORDINATORE,
    RUOLI_SELEZIONABILI_DA_DIRETTORE_UO,
    RUOLI_SELEZIONABILI_DA_REF_DIR_ASS_SOCIALI,
    RUOLI_SELEZIONABILI_DA_MCP, isRuoloMedicoSpecialistaFisiatra,
    RUOLI_SELEZIONABILI_DA_CENTRALE_ADI
} from "../modaleAttivazioneFiguraProfessionale/AttivazioneFiguraProfessionale";
import * as _ from "lodash";
import disciplinaData from "../../../utils/dataset/disciplinaData/disciplinaSpecialisti";
import ModalHelper from "../../modale/ModalHelper";
import RichiestaADIService from "../../../service/RichiestaADIService";
import enumsUtente from "../../../enum/enumsUtente.json";
import PopoverReact from "../../popover/Popover";
import enumUtente from "../../../enum/enumsUtente";
import enumRichiesta from "../../../enum/enumRichiesta";
import * as BsIcon from "react-bootstrap-icons";
import Utils from "../../../utils/Utils";

const propTypes = {
    uuidRichiesta: PropTypes.string,
    teams: PropTypes.array,
    valutazioni: PropTypes.array,
    valutatore: PropTypes.object,
    attivaFiguraProfessionale: PropTypes.bool,
    onClickAggiungiSpecialista: PropTypes.func,
    utentiOnline: PropTypes.arrayOf(PropTypes.string),
    attesaConferma: PropTypes.bool,
    professionistaEliminato: PropTypes.func,
    label: PropTypes.string,
    deleghe: PropTypes.array
}

const defaultProps = {
    valutatore: null,
    attivaFiguraProfessionale: false,
    onClickAggiungiSpecialista: () => {
    },
    attesaConferma: false,
    label: null
}

export const LISTA_PROFESSIONISTI_INTERMEDI = [enumUtente.profiliUtente.direttoreUnitaOperativa,
    enumUtente.profiliUtente.referenteDirigenteAssistentiSociali,
    enumUtente.profiliUtente.coordinatore];

export const getDescrizioneDisciplina = (disciplinaCodice) => {
    let descizioneDisciplina = _.find(disciplinaData,
        disciplina => disciplina.codice === disciplinaCodice)?.descrizione ?? "";
    return descizioneDisciplina;
}

export default class SchedaProfessionista extends Component {

    state = {
        showEliminaProfessionista: false
    }

    isValutatoreEqualsToLoggedUser = (codiceFiscaleValutatore) => {
        return SessioneUtente.getInstance() && codiceFiscaleValutatore === SessioneUtente.getInstance().idUtente;
    }

    componentDidMount() {
    }

    confermaEliminaProfessionista = () => {
        this.setState({showEliminaProfessionista: false}, () => {
            RichiestaADIService.eliminaProfessionista(this.props.uuidRichiesta, this.props.valutatore).subscribe(res => {
                if(res.msg !== "ERRORE"){
                    this.props.professionistaEliminato()
                }
            });
        });
    }

    renderRuoloSpecialista = (valutatore) => {
        if (this.props.attivaFiguraProfessionale && this.props.valutatore == null) {
            return <div className={"row"}>
                <div className={"col-11 font-weight-bold py-2"}>
                    <span>Professionista</span>
                </div>
            </div>;
        }

        let opzioni = this.showMenu() ? (<Fragment>
            <div className={"col-1 dropdown"}>
                <div className={"row dropdown-toggle"}
                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{cursor: "pointer"}}>
                    <div className={"col"}>
                        <div className={styles.dot.concat(" mb-1")}/>
                        <div className={styles.dot.concat(" mb-1")}/>
                        <div className={styles.dot}/>
                    </div>
                </div>
                {this.renderCollaborationToolsMenu()}
            </div>
        </Fragment>) : null;
        let descizioneDisciplina = getDescrizioneDisciplina(valutatore?.disciplina);
        let professionistaIntermedio = [enumUtente.profiliUtente.coordinatore, enumUtente.profiliUtente.referenteDirigenteAssistentiSociali, enumUtente.profiliUtente.direttoreUnitaOperativa]
            .includes(valutatore.profilo);
        let professione;
        if(!valutatore.isValutatore){
            // mostrare ruolo medico ospedaliero
            if(valutatore.ruolo === enumUtente.ruoli.medicoOspedaliero){
                professione = AuthUtils.getRoleDescriptionFromCode(valutatore.ruolo);
            } else {
                professione = AuthUtils.getProfileDescriptionFromCode(valutatore.profilo);
            }
        } else {
            professione = AuthUtils.getRoleDescriptionFromCode(valutatore.ruolo);
        }
        let specialista = <span>
            {professione}
            {this.isValutatoreEqualsToLoggedUser(valutatore.codiceFiscale) ? " (Io)" : ""}
            {isRuoloMedicoSpecialista(valutatore.ruolo) || isRuoloMedicoSpecialistaFisiatra(valutatore.ruolo) ? " " + descizioneDisciplina : ""}
         </span>;

        return (<div className={"row"}>
                <div className={"col-11 font-weight-bold py-2"}>
                    {specialista}
                </div>
                {(!this.isValutatoreEqualsToLoggedUser(valutatore.codiceFiscale) && !this.props.attesaConferma) || (professionistaIntermedio) ? opzioni :
                    <Fragment/>}
            </div>
        );
    }

    renderBody = (valutatore) => {
        //let listaCodiciFiscaliUtentiOnline = this.props.utentiOnline ?? [];
        let labelScheda = <Fragment/>;
        if(this.props.label){
            labelScheda = <div className={"row bg-white rounded mt-4"}>
                <span className={"p-2 text-black"}>
                    {this.props.label}
                </span>
            </div>
        }

        if (this.props.attivaFiguraProfessionale) {
            return <div className={"row py-3"} onClick={() => this.onClickAggiungiSpecialista()}>
                <div className={"col-12"} style={{textAlign: "-webkit-center"}}>
                    <div className={styles.roundedIcon.concat(" rounded-circle")} style={{cursor: "pointer"}}>
                        <Icon id={"it-plus"} className={["icon-md","icon-white","mt-2"]}/>
                        <div className={styles.labelAttivaProfessionista}>Attiva</div>
                    </div>
                </div>
                {labelScheda}
            </div>
        }

        

        if (this.props.attesaConferma) {
            return <div className={"row py-3"}>
                <div className={"col-12 font-weight-bold text-200"} style={{textAlign: "-webkit-center"}}>
                    In fase di attivazione
                </div>
                {labelScheda}
            </div>
        }

        return (<div className={"pt-1"}>
                <p className="m-0 text-capitalize"> {valutatore.nome} {valutatore.cognome}</p>
                <p className="text-lowercase">{valutatore.email}</p>
                {this.getLabelAttivatoDa(valutatore)}
                {this.getLabelAutoAssegnazione(valutatore)}
                {labelScheda}

            </div>
        );
    }

    getLabelSostituzione(valutatore, storicoProfessionisti) {
        let SOSTITUZIONE_STRING = "Attivato per sostituzione dal professionista {nome} {cognome} in data {dataSostituzione}";
        let labelSostituito = <Fragment/>;
        if(valutatore.professionistaAttivatore) {
            let dataSostituzione = _.head(_.orderBy(storicoProfessionisti, ['timestampSostituzione'], ['desc']));
            let dataSostituzioneFormattata = Utils.transformDateAndTimeForFrontend(dataSostituzione);
            labelSostituito = <p>{SOSTITUZIONE_STRING
                .replace("{nome}", valutatore.professionistaAttivatore?.nome)
                .replace("{cognome}", valutatore.professionistaAttivatore?.cognome)
                .replace("{dataSostituzione}", dataSostituzioneFormattata)}</p>
        }
        return labelSostituito;
    }

    getStoricoProfessionisti(valutatore) {
        let storicoProfessionisti;
        if (valutatore.profilo === enumsUtente.profiliUtente.professionistaValutatore) {
            storicoProfessionisti = _.find(this.props.valutazioni, v => v.valutatore?.codiceFiscale === valutatore.codiceFiscale)?.storicoProfessionisti ?? null;
        } else {
            storicoProfessionisti = _.find(this.props.teams, t => t.professionista?.codiceFiscale === valutatore.codiceFiscale)?.storicoProfessionisti ?? null;
        }
        return storicoProfessionisti;
    }

    getLabelAttivatoDa(valutatore) {
        let attivatoDa = <Fragment/>;
        let storicoProfessionisti = this.getStoricoProfessionisti(valutatore);
        if(storicoProfessionisti?.length){
            attivatoDa = this.getLabelSostituzione(valutatore, storicoProfessionisti);
        } else {
            if (valutatore?.professionistaAttivatore) {
                attivatoDa =
                    <p>Attivato da {valutatore.professionistaAttivatore?.nome + " " + valutatore.professionistaAttivatore?.cognome}</p>
            }
        }
        return attivatoDa;
    }

    getLabelAutoAssegnazione(valutatore) {
        let labelAutoassegnazione = <Fragment/>;
        let dirUO = _.find(this.props.teams, t=> t.profilo === enumsUtente.profiliUtente.direttoreUnitaOperativa)?.professionista?.codiceFiscale ?? null;
        let refDirAssSoc = _.find(this.props.teams, t=> t.profilo === enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali)?.professionista?.codiceFiscale ?? null;
        let presenteInValutazione = _.some(this.props.valutazioni, v => v.valutatore?.codiceFiscale === valutatore.codiceFiscale);
        if ([dirUO, refDirAssSoc].includes(valutatore.codiceFiscale) && presenteInValutazione) {
            labelAutoassegnazione =
                <p>Il professionista si è auto-assegnato la valutazione</p>
        }
        return labelAutoassegnazione;
    }

    onClickAggiungiSpecialista() {
        this.props.onClickAggiungiSpecialista(true, this.props.attivaFiguraProfessionale ? this.props.valutatore : null);
    }

    renderEliminaProfessionista = (stylesLi) => {
        let {iconaJSX, isProfessionistaValido, isUtenteAbilitato, labelDisabilitato} = this.preparaAzioneProfessionista();
        let isTimestampConfermaPresente = _.find(this.props.teams,
            team => team.profilo === enumsUtente.profiliUtente.centraleAdi || team.profilo === enumsUtente.profiliUtente.medicoFormalizzatore)?.timestampConferma != null;
        // se non è stato confermato, controllo il professionista che si dovrebbe eliminare
        if(!isTimestampConfermaPresente) {
            let professionistaIntermedio = [enumsUtente.profiliUtente.direttoreUnitaOperativa,
                enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali].includes(this.props.valutatore?.profilo);
            if (professionistaIntermedio && !this.props.valutatore?.isValutatore && (AuthUtils.hasUtenteRuoloMcp() || AuthUtils.hasUtenteCentraleAdi())) {
                // se è un professionista intermedio (DIR_UNITA_OPERATIVA o REF_ASS)
                let teamProfessionistaIntermedio = _.find(this.props.teams, team => team.profilo === this.props.valutatore?.profilo && team?.professionista?.codiceFiscale === this.props.valutatore.codiceFiscale);
                if(teamProfessionistaIntermedio) {
                    let figureRichieste = teamProfessionistaIntermedio?.figureRichieste;
                    // controllo che se ci sono figure richieste, nessuna di esse sia stata aggiunta
                    // altrimenti se la lista non è presente controllo il timestampConferma che non sia presente
                    if (figureRichieste != null && figureRichieste?.length) {
                        let isAggiuntoPresente = _.some(figureRichieste, figura => figura.aggiunto);
                        if (isAggiuntoPresente) {
                            labelDisabilitato = "Sono stati aggiunti dei valutatori da parte del professionista che si sta tentando di eliminare"
                        }
                    } else {
                        let teamProfessionistaIntermedioConfermato = teamProfessionistaIntermedio?.timestampConferma != null;
                        if (teamProfessionistaIntermedioConfermato) {
                            labelDisabilitato = "Il team è stato confermato dal professionista che si sta tentando di eliminare"
                        }
                    }
                }
            } else {
                // se è un valutatore controllo che non abbia già fatto la sua valutazione
                let valutazione = this.getValutazioneProfessionista();
                if (valutazione) {
                    // risulta valutata solo se non è un professionista intermedio ed ha una valutazione precedente,
                    // o se è un professionista intermedio lo stato della valutazione è in bozza
                    let isValutata = (valutazione.valutazionePrecedente != null && !LISTA_PROFESSIONISTI_INTERMEDI.includes(valutazione.valutatore?.profilo)) ||
                        valutazione.stato === enumRichiesta.stato.CONFERMATA;
                    if(isValutata){
                        labelDisabilitato = "La richiesta è stata valutata dal professionista che si sta tentando di eliminare"
                    }
                }
            }
        } else {
            labelDisabilitato = AuthUtils.hasUtenteCentraleAdi() 
            ? "Centrale Adi ha confermato il team, non è possibile eliminare il professionista"
            : "Il medico di cure primarie ha confermato il team, non è possibile eliminare il professionista"
        }

        if (isProfessionistaValido && isUtenteAbilitato) {
            let stileElimina = labelDisabilitato == null ? styles.eliminaProfessionista : styles.eliminaProfessionistaDisabilitato;
            iconaJSX = <li className={stylesLi}>
                <PopoverReact
                    body={labelDisabilitato}
                    text={<Fragment>
                            <span className={stileElimina} onClick={() => {
                                this.setState({showEliminaProfessionista: true})
                            }}>
                                <BsIcon.Trash3 size={24} className={["pr-1", stileElimina].join(" ")}/>Elimina
                            </span>
                    </Fragment>}
                    openOnMouseOver
                    disabled={labelDisabilitato == null}
                    position={'right'}
                    className={'popover-info '.concat(styles.popoverElimina)}
                    arrowStyle={{}}
                    id={`popoverElimina` + this.props.valutatore.codiceFiscale}
                />
            </li>
        } else {return null}


        return iconaJSX;
    }


    renderSubentroProfessionista = (stylesLi) => {
        let {iconaJSX, isProfessionistaValido, isUtenteAbilitato, labelDisabilitato} = this.preparaAzioneProfessionista();

        let professionistaIntermedio = [enumsUtente.profiliUtente.direttoreUnitaOperativa,
            enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali].includes(this.props.valutatore?.profilo);

        // se non è stato confermato, controllo il professionista che si dovrebbe sostituire
        if (AuthUtils.hasUtenteRuoloMcp() || AuthUtils.hasUtenteCentraleAdi()) {
            let isTimestampConfermaPresente = _.find(this.props.teams,
                team =>team.profilo === enumsUtente.profiliUtente.centraleAdi || team.profilo === enumsUtente.profiliUtente.medicoFormalizzatore)?.timestampConferma != null;
            if (isTimestampConfermaPresente) {
                labelDisabilitato = AuthUtils.hasUtenteCentraleAdi() 
                    ? "Centrale Adi ha confermato il team, non è possibile sostituire il professionista" 
                    : "Il medico di cure primarie ha confermato il team, non è possibile sostituire il professionista"
            }
        } else {
            if (this.props.valutatore?.isValutatore ||
                [enumsUtente.profiliUtente.direttoreUnitaOperativa, enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali,
                    enumsUtente.profiliUtente.coordinatore].includes(this.props.valutatore.profilo)) {
                // se è un valutatore controllo che non abbia già fatto la sua valutazione
                let valutazione = this.getValutazioneProfessionista();
                if (valutazione) {
                    // disabilitato se il professionista ha già confermato la valutazione
                    let isValutata = valutazione.stato === enumRichiesta.stato.CONFERMATA;
                    if (isValutata) {
                        labelDisabilitato = "La richiesta è stata valutata dal professionista che si sta tentando di sostituire"
                    }
                }
            }
        }

        if (isProfessionistaValido && isUtenteAbilitato) {
            let stileSubentro =  labelDisabilitato != null && styles.eliminaProfessionistaDisabilitato;
            let iconPrimary = labelDisabilitato == null && "icon-green";
            iconaJSX = <li className={stylesLi}>
                <PopoverReact
                    body={labelDisabilitato}
                    text={<Fragment>
                            <span className={stileSubentro} onClick={() => {
                                this.props.onClickAggiungiSpecialista(true, this.props.valutatore)
                            }}>
                            <Icon id="it-exchange-circle"
                                  className={["icon-sm", "left", iconPrimary, stileSubentro]}/> Sostituisci
                             </span>
                    </Fragment>}
                    openOnMouseOver
                    disabled={labelDisabilitato == null}
                    position={'right'}
                    className={'popover-info '.concat(styles.popoverElimina)}
                    arrowStyle={{}}
                    id={`popoverSubentro` + this.props.valutatore.codiceFiscale}
                />
            </li>
        } else {return null}


        return iconaJSX;
    }

    hasUtenteDelega = (richiesta) => {
        return richiesta?.delega?.some(d => d.valore && d.profilo===enumsUtente.profiliUtente.centraleAdi)
    }

    preparaAzioneProfessionista() {
        let iconaJSX = <Fragment/>;
        let isProfessionistaValido = this.props.valutatore?.codiceFiscale != null &&
            this.props.valutatore?.profilo != null &&
            (this.props.valutatore?.profilo !== enumsUtente.profiliUtente.professionistaValutatore || this.props.valutatore?.ruolo != null) &&
            ![enumsUtente.profiliUtente.medicoRichiedente, enumsUtente.profiliUtente.medicoFormalizzatore].includes(this.props.valutatore?.profilo);
        // controllo il team del formalizzatore se è stato confermato

        let isUtenteAbilitato = this.getIsUtenteAbilitato();
        let labelDisabilitato = null;
        return {iconaJSX, isProfessionistaValido, isUtenteAbilitato, labelDisabilitato};
    }

    getValutazioneProfessionista() {
        let valutazione = _.find(this.props.valutazioni, valutazione => {
            return valutazione.valutatore?.codiceFiscale === this.props.valutatore.codiceFiscale &&
                valutazione.valutatore?.ruolo === this.props.valutatore.ruolo
        })
        return valutazione;
    }

    getIsUtenteAbilitato() {
        let isUtenteAbilitato;
        let settingsUtente = _.find(SessioneUtente.getInstance().settings,
            setting => LISTA_PROFESSIONISTI_INTERMEDI.includes(setting.profilo)
                || (enumUtente.profiliUtente.medicoFormalizzatore === setting.profilo && enumUtente.ruoli.mcp === setting.ruolo)
                || (enumUtente.profiliUtente.centraleAdi === setting.profilo)
        );
        let profiloUtente = settingsUtente?.profilo;
        switch (profiloUtente) {
            case enumsUtente.profiliUtente.medicoFormalizzatore : {
                isUtenteAbilitato = RUOLI_SELEZIONABILI_DA_MCP.includes(this.props.valutatore?.ruolo) ||
                    ([enumsUtente.profiliUtente.direttoreUnitaOperativa, enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali].includes(this.props.valutatore?.profilo) && !this.props.valutatore.isValutatore)
            }
                break;
            case enumsUtente.profiliUtente.centraleAdi: {
                if(this.props.deleghe?.some(d => d.valore && d.profilo===enumsUtente.profiliUtente.centraleAdi)) {
                    isUtenteAbilitato = RUOLI_SELEZIONABILI_DA_CENTRALE_ADI.concat(RUOLI_SELEZIONABILI_DA_MCP).includes(this.props.valutatore?.ruolo) ||
                        ([enumsUtente.profiliUtente.direttoreUnitaOperativa, enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali].includes(this.props.valutatore?.profilo) && !this.props.valutatore.isValutatore)
                }
                else
                {
                    isUtenteAbilitato = RUOLI_SELEZIONABILI_DA_CENTRALE_ADI.includes(this.props.valutatore?.ruolo) ||
                        ([enumsUtente.profiliUtente.direttoreUnitaOperativa, enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali].includes(this.props.valutatore?.profilo) && !this.props.valutatore.isValutatore)
                }
            }
                break;
            case enumsUtente.profiliUtente.coordinatore : {
                isUtenteAbilitato = RUOLI_SELEZIONABILI_DA_COORDINATORE.includes(this.props.valutatore?.ruolo) || (this.props.valutatore?.isValutatore && this.props.valutatore?.profilo === enumUtente.profiliUtente.coordinatore)
            }
                break;
            case enumsUtente.profiliUtente.direttoreUnitaOperativa : {
                let teamProfessionistaLoggato = _.find(this.props.teams, team => team?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente) ?? null;
                if (teamProfessionistaLoggato) {
                    let valutatorePresenteNelTeam = _.some(teamProfessionistaLoggato.figureRichieste, figuraRichiesta => figuraRichiesta?.codiceFiscaleAggiunti?.length && figuraRichiesta?.codiceFiscaleAggiunti?.includes(this.props.valutatore.codiceFiscale));
                    isUtenteAbilitato = RUOLI_SELEZIONABILI_DA_DIRETTORE_UO.includes(this.props.valutatore?.ruolo) && valutatorePresenteNelTeam;
                }
            }
                break;
            case enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali : {
                isUtenteAbilitato = RUOLI_SELEZIONABILI_DA_REF_DIR_ASS_SOCIALI.includes(this.props.valutatore?.ruolo) || (this.props.valutatore?.isValutatore && this.props.valutatore?.profilo === enumUtente.profiliUtente.referenteDirigenteAssistentiSociali)
            }
                break;
            default: {
                isUtenteAbilitato = false
            }
                break;
        }
        return isUtenteAbilitato;
    }

    renderSchedaFiguraProfessionale = () => {
        let listaCodiciFiscaliUtentiOnline = this.props.utentiOnline ?? [];
        return (
            <div className={"col-sm-6 col-md-5 col-lg-4 col-xl-2 mb-4 mr-4"}>
                <div className={styles.boxScheda.concat(" p-3 ")}>
                    {this.renderIconaUtente()}
                    {this.renderRuoloSpecialista(this.props.valutatore)}
                    {this.renderBody(this.props.valutatore)}
                    {listaCodiciFiscaliUtentiOnline.length > 0 && listaCodiciFiscaliUtentiOnline.includes(this.props.valutatore.codiceFiscale)
                        ? <div className={styles.containerStatoUtente}>
                            <div className={styles.onlineDot}>Online</div>
                        </div>
                        : null}
                    
                </div>
            </div>
        );
    }

    renderIconaUtente = () => {
        return <div className={"row"}>
            <div className={"col-12"} style={{textAlign: "-webkit-center"}}>
                <div>
                    {this.props.attivaFiguraProfessionale
                        ? <img alt={"Attiva professionista"}
                               src={require("../../../img/attiva_professionista.png")}
                               width={45} height={45}/>
                        : <img alt={"Professionista attivato"}
                               src={require("../../../img/professionista_attivato.png")}
                               width={45} height={45}/>}
                </div>
            </div>
        </div>
    }

    // Gli strumenti di collaboration per il momento sono mockati perchè saranno previsti in una seconda release
    renderCollaborationToolsMenu = () => {
        let stylesLi = "dropdown-item ".concat([styles.schedaSpanLi, styles.schedaDropdownItem].join(" "));
        let collaborationTools = [
            {
                element: this.renderSubentroProfessionista(stylesLi)
            },
            {
                element: this.renderEliminaProfessionista(stylesLi)
            }
        ];
        return (<NavbarDropdown elements={collaborationTools}/>);
    }

    showMenu = () => {
        let menuJSX = [this.renderSubentroProfessionista(""), this.renderEliminaProfessionista("")];
        return menuJSX.filter(menu => menu).length;
    }

    renderModaleEliminaProfessionista = () => {
        const body = (
            <Fragment>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"offset-1 col-10 font-weight-100 text-center py-2"}>
                            <span>
                                <div> Stai per procedere con l&apos;eliminazione di un professionista </div>
                                <strong> Vuoi confermare l&apos;operazione? </strong>
                            </span>
                        </div>
                    </div>
                </div>
            </Fragment>
        )

        const footer = (
            <Fragment>
                <Button
                    id={"confermaBtn"}
                    text={"Conferma"}
                    className={"btn btn-adi btnOrangeOutline"}
                    dataDismiss={"modal"}
                    onClick={() => {
                        this.confermaEliminaProfessionista()
                    }}/>
                <Button
                    onClick={() => {
                        this.setState({showEliminaProfessionista: false});
                    }}
                    id={"annullaBtn"}
                    dataDismiss={"modal"}
                    text={"Annulla"}
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                />
            </Fragment>
        );

        return (
            <Fragment>
                <ModalHelper
                    id={"modaleConferma"}
                    title={"Attenzione!"}
                    body={body}
                    footer={footer}
                    titleClassName={"modal-header d-inline text-center backgroundOrange"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={" modal-content"}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={"modal-body align-items-center d-flex text-center"}
                />
            </Fragment>
        );
    }

    render() {
        return (
            <Fragment>
                {this.state.showEliminaProfessionista && this.renderModaleEliminaProfessionista()}
                {this.renderSchedaFiguraProfessionale()}
            </Fragment>
        );
    }

}

SchedaProfessionista.propTypes = propTypes;
SchedaProfessionista.defaultProps = defaultProps;
