import React from "react";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionHelper from "../../service/AccordionHelper";
import PatologieFamiliari from "./patologieFamiliari/PatologieFamiliari";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import VersionedAccordion from "../VersionedAccordion";
import enumRichiesta from "../../enum/enumRichiesta.json"

const propTypes = {
    datiAnamnesiFamiliare: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    handleChangeAnamnesiFamiliare: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

export default class AnamnesiFamiliare extends AccordionFirstLevel {
    state = {
        anamnesiFamiliareForm: _.cloneDeep(this.props.datiAnamnesiFamiliare),
        accordion: {
            openAccordionAnamnesiFamiliare: true
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta.anamnesiFamiliare = this.props.datiAnamnesiFamiliare;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    renderBodySezione = () => {
        return (
            <VersionedAccordion accordion={PatologieFamiliari}
                datiPatologieFamiliari={this.props.datiAnamnesiFamiliare.patologieFamiliari}
                onChangeDatiPatologieFamiliari={this.props.onChangeDatiAnamnesiFamiliare}
                openAccordion={this.state.accordion.openAccordionAnamnesiFamiliare}
                onClickAccordion={this.onClickAccordion}
                field={"openAccordionAnamnesiFamiliare"}
                mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}/>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"datiAnamnesiFamiliare"}
                title={"Anamnesi Familiare"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBodySezione()}</AccordionAvvioRichiesta>
        );
    }
}

AnamnesiFamiliare.propTypes = propTypes;
