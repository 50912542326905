import React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import Utils from "../../../utils/Utils";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {FieldsRow, RoleBasedInput, RoleBasedPhoneInput} from "../../roleBasedComponents/RoleBasedComponents";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";

const propTypes = {
    datiA0: PropTypes.any,
    datiMedicoForm: PropTypes.any,
    onChangeDatiAssistito: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onValidaSezione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class MedicoDiBase extends AccordionSecondLevel {
    state = {
        datiMedico: _.cloneDeep(this.props.datiMedicoForm)
    }

    componentDidMount() {
        this.countCampiObbligatoriRimanentiAndValida();
    }

    countCampiObbligatoriRimanentiAndValida = () => {
        let datiMedico = _.cloneDeep(this.state.datiMedico);
        let isCodiceFiscaleValido;
        let isTelefonoValido;
        let isCellulareValido;
        let isEmailValida = Utils.isStringEmptyOrNullOrUndefined(datiMedico.email) || Utils.isValidEmail(datiMedico.email);
        let campiObbligatoriRimanenti = 0;

        let defaultCodiceFiscaleValidationCondition = Utils.isValidCodiceFiscale(datiMedico.identificativoUtente);
        let defaultTelefonoValidationCondition = Utils.isValidTelefono(datiMedico.telefono);
        let defaultCellulareValidationCondition = Utils.isValidTelefono(datiMedico.cellulare);

        if (Utils.isStateRichiesta(this.props.pageState)) {
            // controllo obbigatorietà identificativo utente
            campiObbligatoriRimanenti += !defaultCodiceFiscaleValidationCondition ? 1 : 0;
            isCodiceFiscaleValido = defaultCodiceFiscaleValidationCondition;

            // Almeno uno dei due contatti tra telefono o cellulare devono essere valorizzati
            // Nel caso in cui entrambi sono null li conto come campi obbligatori ancora da valorizzare
            if (Utils.isStringEmptyOrNullOrUndefined(datiMedico.telefono) && Utils.isStringEmptyOrNullOrUndefined(datiMedico.cellulare)) {
                campiObbligatoriRimanenti += 2;
                isTelefonoValido = false;
                isCellulareValido = false;
            } else {
                isTelefonoValido = Utils.isStringEmptyOrNullOrUndefined(datiMedico.telefono) || defaultTelefonoValidationCondition;
                isCellulareValido = Utils.isStringEmptyOrNullOrUndefined(datiMedico.cellulare) || defaultCellulareValidationCondition;
            }

            // controllo obbligatorietà nome
            if (Utils.isStringEmptyOrNullOrUndefined(datiMedico.nome)) campiObbligatoriRimanenti++;

            // controllo obbligatorietà cognome
            if (Utils.isStringEmptyOrNullOrUndefined(datiMedico.cognome)) campiObbligatoriRimanenti++;
        } else {
            // controllo obbigatorietà identificativo utente
            isCodiceFiscaleValido = Utils.isStringEmptyOrNullOrUndefined(datiMedico.identificativoUtente) || defaultCodiceFiscaleValidationCondition;

            // controllo obbligatorietà telefono, cellulare
            isTelefonoValido = Utils.isStringEmptyOrNullOrUndefined(datiMedico.telefono) || defaultTelefonoValidationCondition;
            isCellulareValido = Utils.isStringEmptyOrNullOrUndefined(datiMedico.cellulare) || defaultCellulareValidationCondition;
        }

        this.props.onChangeRequiredFieldsNumber("sottoSezioneMedico", campiObbligatoriRimanenti);

        let isSezioneValida = isCodiceFiscaleValido && isTelefonoValido && isCellulareValido && isEmailValida && campiObbligatoriRimanenti === 0;
        this.props.onValidaSezione("isMedicoDiBaseValido", isSezioneValida);
    }

    handleAggiornaForm = (field, elem) => {
        let datiMedico = _.cloneDeep(this.state.datiMedico);
        datiMedico[field] = elem ? elem : null;

        this.setState({datiMedico: datiMedico}, () => {
            this.props.onChangeDatiAssistito("mmgPlsDelPaziente", datiMedico);
            this.countCampiObbligatoriRimanentiAndValida();
        });
    }

    renderCampiInputTesto = () => {
        const isContactRequired = Utils.isStringEmptyOrNullOrUndefined(this.state.datiMedico.telefono)
            && Utils.isStringEmptyOrNullOrUndefined(this.state.datiMedico.cellulare);
        const forceContactVisibility = !Utils.isStringEmptyOrNullOrUndefined(this.state.datiMedico.telefono)
            || !Utils.isStringEmptyOrNullOrUndefined(this.state.datiMedico.cellulare);

        return (
            <>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'COGN_MMG_PLS.MMGPLSDELPAZIENTE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        forceReadOnly={!this.props.datiA0?.mascheraModificabilita?.cognome && !Utils.isStringEmptyOrNullOrUndefined(this.props.datiA0.cognome)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        placeholder="Inserisci cognome utente"
                        field="cognome"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="filterInputCognome"
                        value={this.state.datiMedico.cognome}
                        fieldLabel={"Cognome"}
                        fieldRequired={true}
                        disableDoubleInput={true}
                    />
                    <RoleBasedInput
                        fieldId={'NOME_MMG_PLS.MMGPLSDELPAZIENTE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        forceReadOnly={!this.props.datiA0?.mascheraModificabilita?.nome && !Utils.isStringEmptyOrNullOrUndefined(this.props.datiA0.nome)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        placeholder="Inserisci nome utente"
                        field="nome"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="filterInputNome"
                        value={this.state.datiMedico.nome}
                        fieldLabel={"Nome"}
                        fieldRequired={true}
                        disableDoubleInput={true}
                    />
                    <RoleBasedInput
                        fieldLabel={"Codice fiscale"}
                        fieldId={'COD_FISC_MMG_PLS.MMGPLSDELPAZIENTE.ANAGRAFEPAZIENTE'}
                        fieldRequired={true}
                        pageState={this.props.pageState}
                        forceReadOnly={!this.props.datiA0?.mascheraModificabilita?.identificativoUtente && !Utils.isStringEmptyOrNullOrUndefined(this.props.datiA0.identificativoUtente)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        placeholder="Inserisci codice fiscale"
                        field="identificativoUtente"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="filterInputCodiceFiscale"
                        value={this.state.datiMedico.identificativoUtente}
                        validator={Utils.isValidCodiceFiscale}
                        invalidText={"Inserire un codice fiscale valido"}
                        disableDoubleInput={true}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedPhoneInput
                        fieldId={'TEL_AMB_MMG_PLS.MMGPLSDELPAZIENTE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        forceReadOnly={this.props.datiA0?.mascheraModificabilita?.contatti?.length > 0 && !this.props.datiA0?.mascheraModificabilita?.contatti[0].valore && !Utils.isStringEmptyOrNullOrUndefined(this.props.datiA0.telefono)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        placeholder="Inserisci numero di telefono ambulatorio"
                        field="telefono"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="filterInputTelefonoAmbulatorio"
                        value={this.state.datiMedico.telefono}
                        fieldLabel={"Telefono ambulatorio"}
                        fieldRequired={isContactRequired}
                        showFieldIfRequiredAccordionPage={forceContactVisibility}
                        disabled={false}
                        phonePrefix={null}
                        disableDoubleInput={true}
                    />
                    <RoleBasedPhoneInput
                        fieldLabel={'Cellulare'}
                        fieldId={"CELL_MMG_PLS.MMGPLSDELPAZIENTE.ANAGRAFEPAZIENTE"}
                        fieldRequired={isContactRequired}
                        showFieldIfRequiredAccordionPage={forceContactVisibility}
                        disabled={false}
                        pageState={this.props.pageState}
                        value={this.state.datiMedico.cellulare}
                        placeholder={"Inserisci numero cellulare"}
                        field={"cellulare"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"filterInputCellulare"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldLabel={"E-mail"}
                        fieldId={'EMAIL_MMG_PLS.MMGPLSDELPAZIENTE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci e-mail"
                        field="email"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="filterInputEmail"
                        value={this.state.datiMedico.email}
                        validator={Utils.isValidEmail}
                        invalidText={"Inserire un'email valida"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"mmgPlsDelPaziente"}
                title={"Medico di base (MMG/PLS)"}
                required={true}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderCampiInputTesto()}</AccordionSezioneRichiesta>
        );
    }
}

MedicoDiBase.propTypes = propTypes;
MedicoDiBase.contextType = RoleBasedContext;
