import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {FieldsRow, parentFieldIdToJsonPath, RoleBasedSelect} from "../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../utils/dataset/OggettiUtili.json";
import {ProposteAttivitaSuggerimentiList} from "./ProposteAttivitaSuggerimentiList";
import {isArray} from "lodash";
import {SessioneUtente} from "web-client-archetype";

const propTypes = {
    pageState: PropTypes.string.isRequired,
    ambito: PropTypes.string.isRequired,
    fieldId: PropTypes.string,
    parentFieldId: PropTypes.string.isRequired,
    infParentFieldId: PropTypes.string,
    fieldsPrefix: PropTypes.string,
    fieldsSuffix: PropTypes.string,
    appendRolePrefix: PropTypes.bool,

    onChangeCondizioneRilevata: PropTypes.func.isRequired,
    valueCondizioneRilevata: PropTypes.string,
    onChangeDaAttenzionare: PropTypes.func.isRequired,
    valueDaAttenzionare: PropTypes.string,

    showSuggerimenti: PropTypes.bool,
    onChangeSuggerimenti: PropTypes.func,
    valueSuggerimenti: PropTypes.object,

    showAutonomia: PropTypes.bool,
    onChangeAutonomiaPaziente: PropTypes.func,
    valueAutonomiaPaziente: PropTypes.string,
    onChangeAutonomiaCaregiver: PropTypes.func,
    valueAutonomiaCaregiver: PropTypes.string,

    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    fieldInAccordionReadOnly: PropTypes.bool,

    requiredCondizioneRilevata: PropTypes.bool,
    requiredDaAttenzionare: PropTypes.bool,

    checkboxIdSuffix: PropTypes.string,

    fieldJsonPathPrefix: PropTypes.string,
    fieldJsonPathSuffix: PropTypes.string,
    parentJsonPath: PropTypes.string,
    listId: PropTypes.arrayOf(PropTypes.object),

    openCondition: PropTypes.bool,
    disabilitaCondizioneRilevata: PropTypes.bool
}

export default class CondizioneRilevataSection extends React.Component {

    state = {
        condizioneRilevata: this.props.valueCondizioneRilevata,
        daAttenzionare: this.props.valueDaAttenzionare,
        autonomiaGestionePaziente: this.props.valueAutonomiaPaziente,
        autonomiaGestioneCaregiver: this.props.valueAutonomiaCaregiver,
        suggerimenti: this.props.valueSuggerimenti && !isArray(this.props.valueSuggerimenti) ? [this.props.valueSuggerimenti] : this.props.valueSuggerimenti,
        
    }
    
    condRilevataForzeRead;

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps, this.props)) {
            if (prevProps.valueCondizioneRilevata !== this.props.valueCondizioneRilevata) {
                this.setState({condizioneRilevata: this.props.valueCondizioneRilevata}, () => this.validateData());
            }

            if (prevProps.valueDaAttenzionare !== this.props.valueDaAttenzionare) {
                this.setState({daAttenzionare: this.props.valueDaAttenzionare});
            }

            if (prevProps.valueAutonomiaPaziente !== this.props.valueAutonomiaPaziente) {
                this.setState({autonomiaGestionePaziente: this.props.valueAutonomiaPaziente});
            }

            if (prevProps.valueAutonomiaCaregiver !== this.props.valueAutonomiaCaregiver) {
                this.setState({autonomiaGestioneCaregiver: this.props.valueAutonomiaCaregiver});
            }

            if (prevProps.valueSuggerimenti !== this.props.valueSuggerimenti) {
                this.setState({suggerimenti:  this.props.valueSuggerimenti && !isArray(this.props.valueSuggerimenti) ? [this.props.valueSuggerimenti] : this.props.valueSuggerimenti});
            }
        }
    }

    validateData() {
        if (this.state.condizioneRilevata !== "02") {
            this.setState({daAttenzionare: null}, () => this.props.onChangeDaAttenzionare(null));
            this.setState({autonomiaGestionePaziente: null}, () => {
                if (this.props.onChangeAutonomiaPaziente) this.props.onChangeAutonomiaPaziente(null);
            });
            this.setState({autonomiaGestioneCaregiver: null}, () => {
                if (this.props.onChangeAutonomiaCaregiver) this.props.onChangeAutonomiaCaregiver(null);
            });
            this.setState({suggerimenti: []}, () => {
                if (this.props.onChangeSuggerimenti)
                    this.props.onChangeSuggerimenti("propostaAttivitaSuggerimenti", []);
            });
        }
    }

    onChange = (effectCallback) => {
        effectCallback();
        this.validateData();
    }

    render() {
        if (!this.props.openCondition) return null;
        const mRolePrefix = this.props.appendRolePrefix ? 'M_' : '';
        const iRolePrefix = this.props.appendRolePrefix ? 'I_' : null;
        const fieldsPrefix = this.props.fieldsPrefix ? this.props.fieldsPrefix + '_' : '';
        const fieldsSuffix = this.props.fieldsSuffix ? '_' + this.props.fieldsSuffix : '';
        const infParentFieldId = this.props.infParentFieldId ?? this.props.parentFieldId;

        if(this.state.suggerimenti && isArray(this.state.suggerimenti)) {
            this.condRilevataForzeRead = (this.context.forceReadOnly || this.props.forceReadOnly || !!(this.state.suggerimenti.filter(p => p?.codiceFiscale && p?.codiceFiscale != SessioneUtente.getInstance().idUtente || p?.propostaAttivitaSuggerimenti?.codiceFiscale && p?.propostaAttivitaSuggerimenti?.codiceFiscale != SessioneUtente.getInstance().idUtente)?.length))
        }
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={mRolePrefix + fieldsPrefix + 'COND_RIL' + fieldsSuffix + '.' + this.props.parentFieldId}
                        infermiereFieldId={iRolePrefix ? (iRolePrefix + fieldsPrefix + 'COND_RIL' + fieldsSuffix + '.' + infParentFieldId) : null}
                        pageState={this.props.pageState}
                        id={"selectCondizioneRilevata"}
                        options={OggettiUtili.condizioneRilevata}
                        value={this.state.condizioneRilevata}
                        onChange={(elem) => {
                            this.setState({condizioneRilevata: elem, suggerimenti: elem !== "02" ?? []}, () => this.onChange(() => this.props.onChangeCondizioneRilevata(elem)));
                        }}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Condizione rilevata non esistente"}
                        fieldLabel={'La condizione rilevata'}
                        fieldRequired={this.props.requiredCondizioneRilevata}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"condizioneRilevata"}
                        fieldJsonPath={this.getFieldJsonPath("CondizioneRilevata")}
                        listId={this.props.listId}
                        forceReadOnly={this.props.forceReadOnly || this.condRilevataForzeRead}
                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                        isDisabled={this.props.disabilitaCondizioneRilevata}
                    />

                    {this.state.condizioneRilevata === "02" &&
                    <RoleBasedSelect
                        fieldId={mRolePrefix + fieldsPrefix + 'DA_ATTENZ' + fieldsSuffix + '.' + this.props.parentFieldId}
                        infermiereFieldId={iRolePrefix ? (iRolePrefix + fieldsPrefix + 'DA_ATTENZ' + fieldsSuffix + '.' + infParentFieldId) : null}
                        pageState={this.props.pageState}
                        id={"selectDaAttenzionare"}
                        options={OggettiUtili.daConsiderareAiFiniAssistenziali}
                        value={this.state.daAttenzionare}
                        onChange={(elem) => {
                            this.setState({daAttenzionare: elem}, () => this.onChange(() => this.props.onChangeDaAttenzionare(elem)));
                        }}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Da Attenzionare non esistente"}
                        fieldLabel={'Da attenzionare'}
                        fieldRequired={this.props.requiredDaAttenzionare}
                        forceReadOnly={this.props.forceReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"daAttenzionare"}
                        fieldJsonPath={this.getFieldJsonPath("DaAttenzionare")}
                        listId={this.props.listId}
                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                    />}
                </FieldsRow>

                {this.props.showSuggerimenti && this.state.condizioneRilevata === "02" &&
                <div>
                    <ProposteAttivitaSuggerimentiList 
                        suggerimenti={this.state.suggerimenti || []}  
                        pageState={this.props.pageState} 
                        ambito={this.props.ambito}
                        fieldId={fieldsPrefix + 'PROP_ATT_SUGG' + fieldsSuffix + '.' + this.props.parentFieldId}
                        onChange={(name, value) => {
                            this.setState({suggerimenti: value}, () => this.onChange(() => this.props.onChangeSuggerimenti(name, value)));
                        }}
                        parentFieldId={this.props.parentFieldId}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        infParentFieldId={infParentFieldId}
                        appendRolePrefix={this.props.appendRolePrefix}
                        fieldsPrefix={this.props.fieldsPrefix}
                        fieldsSuffix={this.props.fieldsSuffix}
                        checkboxIdSuffix={this.props.checkboxIdSuffix}
                        fieldJsonPath={this.getFieldJsonPath("propostaAttivitaSuggerimenti")}
                        listId={this.props.listId}
                    />
                </div>}

                {this.props.showAutonomia && this.state.condizioneRilevata === "02" &&
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'AUT_GEST_PAZ' + fieldsSuffix + '.' + infParentFieldId}
                        pageState={this.props.pageState}
                        id={"selectAutonomiaGestionePaziente"}
                        options={OggettiUtili.autonomiaGestione}
                        value={this.state.autonomiaGestionePaziente}
                        onChange={(elem) => {
                            this.setState({autonomiaGestionePaziente: elem}, () => this.onChange(() => this.props.onChangeAutonomiaPaziente(elem)));
                        }}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Autonomia gestione paziente non esistente"}
                        fieldLabel={'Autonomia di gestione del paziente'}
                        forceReadOnly={this.props.forceReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={this.getFieldJsonPath("autonomiaGestionePaziente")}
                        parentJsonPath={parentFieldIdToJsonPath(this.props.parentJsonPath, 'AUT_GEST_PAZ' + fieldsSuffix + '.' + this.props.parentFieldId)}
                        listId={this.props.listId}
                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                    />

                    <RoleBasedSelect
                        fieldId={'AUT_GEST_CAREG' + fieldsSuffix + '.' + infParentFieldId}
                        pageState={this.props.pageState}
                        id={"selectAutonomiaGestioneCaregiver"}
                        options={OggettiUtili.autonomiaGestione}
                        value={this.state.autonomiaGestioneCaregiver}
                        onChange={(elem) => {
                            this.setState({autonomiaGestioneCaregiver: elem}, () => this.onChange(() => this.props.onChangeAutonomiaCaregiver(elem)));
                        }}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Autonomia gestione caregiver non esistente"}
                        fieldLabel={'Autonomia di gestione del caregiver'}
                        forceReadOnly={this.props.forceReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={this.getFieldJsonPath("autonomiaGestioneCaregiver")}
                        parentJsonPath={parentFieldIdToJsonPath(this.props.parentJsonPath, 'AUT_GEST_CAREG' + fieldsSuffix + '.' + this.props.parentFieldId)}
                        listId={this.props.listId}
                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                    />
                </FieldsRow>}
            </>
        );
    }

    getFieldJsonPath(selectName) {
        if (!this.props.fieldJsonPathPrefix && !this.props.fieldJsonPathSuffix) return selectName;
        selectName = this.props.fieldJsonPathPrefix ? `${this.props.fieldJsonPathPrefix}${selectName}` : `${selectName}`;
        selectName = this.props.fieldJsonPathSuffix ? `${selectName}${this.props.fieldJsonPathSuffix}` : `${selectName}`;
        return selectName;
    }
}

CondizioneRilevataSection.propTypes = propTypes;

CondizioneRilevataSection.defaultProps = {
    appendRolePrefix: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false,
    fieldInAccordionReadOnly: false,
    requiredCondizioneRilevata: false,
    requiredDaAttenzionare: false,
    openCondition: true,
    disabilitaCondizioneRilevata:false
}
