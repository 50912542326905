import React from "react";
import PropTypes from "prop-types";
import styles from "./TabellaRichieste.module.css";
import Utils from "../../../utils/Utils";
import * as _ from "lodash";

function InfoTable({info, className}) {
    let pageInfo = _.cloneDeep(info);
    let requestsNumber = null;

    if (!Utils.isStringEmptyOrNullOrUndefined(pageInfo)) {
        const possibleNumber = info?.substring(0, 2);
        if (possibleNumber.match(/\d+/g)) {
            requestsNumber = pageInfo.substring(0, possibleNumber.length).trim();
            pageInfo = pageInfo.substring(requestsNumber.length);
        }
    }

    const text = `${pageInfo.trim()} ${requestsNumber != null ? '(' + requestsNumber + ')' : ''}`.trim();
    return <div className={"row"}>
        <div className={["col-12", className].join(" ")}>
            <b className={styles.info}>{text}</b>
            <hr className={styles.separator}/>
        </div>
    </div>;
}

InfoTable.propTypes = {
    info: PropTypes.string,
    className: PropTypes.string
};

InfoTable.defaultProps = {
    info: "",
    className: "pt-4 position-relative"
};

export default InfoTable;
