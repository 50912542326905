import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "web-client-archetype";
import ModalHelper from "../../modale/ModalHelper";
import * as $ from "jquery";
import RichiestaADIService from "../../../service/RichiestaADIService";
import { Link } from "react-router-dom";
import routePath from "../../../utils/route/route-path.json";
import _ from "lodash";
import Utils from "../../../utils/Utils";
import { mostraSpinner, nascondiSpinner } from "../../../App";
import errors from "../../../utils/errors/errors.json";

const propTypes = {
    uuidRichiesta: PropTypes.string,
    chiudiPopupInoltraRichiesta: PropTypes.func,
    showPopup: PropTypes.bool
}

const bodyModaleText = {
    top: "Stai per procedere con l'invio della richiesta al Distretto. \n",
    down: "Vuoi confermare l'operazione?"
};
const bodyModaleOperazioneEseguitaText = "La richiesta è stata correttamente inoltrata al Distretto.";

const titleModale = "Attenzione!";
const titleModaleOperazioneEseguita = "Operazione completata";

const idModale = "modaleInoltraRichiesta";
const idModaleOperazioneEseguita = "modaleInoltraRichiestaOperazioneEseguita";

export default class PopupConfermaInoltraRichiesta extends Component {
    state = {
        showModaleOperazioneEseguita: false,
        pdfBtnDisabled: false
    }

    closeModaleStyle = () => {
        $(".modal-backdrop").css("display", "none");
        $(".modal-open").removeClass("modal-open");
        $("body").attr("style", "padding-right: 0 !important");
    }

    annullaInoltraRichiesta = () => {
        this.closeModaleStyle();
        this.props.chiudiPopupInoltraRichiesta();
    }

    showOperazioneEseguita = () => {
        this.setState({ showModaleOperazioneEseguita: true });
    }

    downloadPdf = () => {
        let uuidRichiesta = this.props.uuidRichiesta;
        RichiestaADIService.generaPdf(uuidRichiesta, "STEP_CDA2_INOLTRO").subscribe((response) => {   
            if (response?.msg === "ERRORE") {       
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "confirm",
                    text: "Il fascicolo sanitario elettronico non è al momento disponibile. Si prega di riprovare più tardi"
                });         
            } else {
                const linkSource = `data:${response.data.contentType};base64,${response.data.file}`;
                const downloadLink = document.createElement("a");
                const fileName = response.data.fileName;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();                        
            }
        });
    }

    confermaInoltraRichiesta = () => {
        let uuidRichiesta = this.props.uuidRichiesta;
        mostraSpinner();
        RichiestaADIService.inoltraRichiesta(uuidRichiesta).subscribe((response) => {
            if (response?.msg === "ERRORE") {                
                if(response?.msgCode && response.msgCode.startsWith("WARNING_WD")){
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "confirm",
                        text: errors[response?.msgCode]?.message || response?.msgCode
                    });
                     //l'operazione è stata comunque eseguita, quindi proseguo come per un ok
                } else if(response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_NON_INVIATO")) {
                    //non deve più farlo ma bisogna comunque gestire il caso
                    //this.setState({ pdfBtnDisabled: true });                    
                } else if(response?.msgCode && response.msgCode.startsWith("WARNING_CDA2_IN_ERRORE")) {
                    this.annullaInoltraRichiesta();
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: "Errore nella creazione del CDA2"
                    });                    
                    nascondiSpinner();
                    return;
                } else if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    this.annullaInoltraRichiesta();
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                    });
                    nascondiSpinner();
                    return;
                }                
            }            
            nascondiSpinner();
            this.showOperazioneEseguita();            
        });
    }

    tornaHome = () => {
        this.annullaInoltraRichiesta();
        this.setState({ showModaleOperazioneEseguita: false })
    }

    renderModale = () => {
        let state = _.cloneDeep(this.state);

        const bodyModale = (
            <div>
                {bodyModaleText.top}
                <strong>{bodyModaleText.down}</strong>
            </div>
        );

        const bodyModaleOperazioneEseguita = (
            <div>{bodyModaleOperazioneEseguitaText}</div>
        );

        const getBody = () => (
            <>{state.showModaleOperazioneEseguita ? bodyModaleOperazioneEseguita : bodyModale}</>
        );

        const footerModale = (
            <>
                <Button
                    onClick={() => this.confermaInoltraRichiesta()}
                    id={"confermaBtn"}
                    text="Conferma"
                    className={"btn btn-adi btnOrangeOutline"}
                />
                <Button
                    onClick={() => this.annullaInoltraRichiesta()}
                    id={"annullaBtn"}
                    text="Annulla"
                    className={"btn btn-adi btnWhiteOutlineBorderOrange"}
                    disabled={false}
                />
            </>
        );

        const footerModaleOperazioneEseguita = (
            <>
                <Button
                    onClick={() => this.downloadPdf()}
                    id={"visualizzaPdfBtn"}
                    text="Visualizza Pdf"
                    className={"btn btn-adi btnGreenOutline"}
                    disabled={state.pdfBtnDisabled} 
                />
                <Link to={{ pathname: routePath.home }}>
                    <Button
                        onClick={() => this.tornaHome()}
                        id={"tornaHomeBtn"}
                        text="Torna a Home"
                        className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                        disabled={false}
                    />
                </Link>
            </>
        );

        const getFooter = () => (
            <> {state.showModaleOperazioneEseguita ? footerModaleOperazioneEseguita : footerModale} </>
        )

        function getTitle() {
            if (state.showModaleOperazioneEseguita) return titleModaleOperazioneEseguita;
            else return titleModale;
        }

        function getId() {
            if (state.showModaleOperazioneEseguita) return idModaleOperazioneEseguita;
            else return idModale;
        }

        function getBackgroundStyle() {
            if (state.showModaleOperazioneEseguita) return "backgroundGreen";
            else return "backgroundOrange";
        }

        return (
            <>
                <ModalHelper
                    id={getId()}
                    title={getTitle()}
                    titleClassName={("modal-header d-inline text-center ").concat(getBackgroundStyle())}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={"modal-content"}
                    body={getBody()}
                    footer={getFooter()}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={"modal-body d-flex align-items-center justify-content-around f-weight-600 text-center"}
                />
            </>
        );
    }

    render() {
        return (
            <>
                {this.renderModale()}
            </>
        )
    }

}

PopupConfermaInoltraRichiesta.propTypes = propTypes;
