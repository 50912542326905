import React, {Component, Fragment} from "react";
import {Button, SessioneUtente} from "web-client-archetype";
import AnagrafePaziente, {isPazienteMinoreDi18Anni} from "../anagrafePaziente/AnagrafePaziente";
import PropTypes from "prop-types";
import RichiestaADIService from "../../service/RichiestaADIService";
import Utils, {closeModaleStyle} from "../../utils/Utils";
import * as _ from "lodash";
import SituazioneSociale from "../situazioneSociale/SituazioneSociale";
import DeterminantiDiSalute from "../determinantiDiSalute/DeterminantiDiSalute";
import AnamnesiFisiologica from "../anamnesiFisiologica/AnamnesiFisiologica";
import InformazioniDiBase from "../infoBase/InformazioniDiBase";
import AnagrafeService from "../../service/AnagrafeService";
import {map} from "rxjs/operators";
import {forkJoin, of} from "rxjs";
import AnamnesiPatologicaProssima from "../anamnesiPatologicaProssima/AnamnesiPatologicaProssima";
import AnamnesiPatologicaRemota from "../anamnesiPatologicaRemota/AnamnesiPatologicaRemota";
import AccordionHelper from "../../service/AccordionHelper";
import AnamnesiFamiliare from "../anamnesiFamiliare/AnamnesiFamiliare";
import enumIndirizzo from "../../enum/enumIndirizzo.json";
import enumContatto from "../../enum/enumContatto.json";
import BenessereAbitativo from "../benessereAbitativo/BenessereAbitativo";
import SessionRequest, {defaultAnagrafePaziente} from "../../utils/SessionRequest";
import $ from "jquery";
import routepath from "../../utils/route/route-path.json";
import {Redirect} from "react-router-dom";
import enumPaginaCompila from "../../enum/enumPaginaCompila.json";
import InformazioniBaseFaseValutazione from "../informazioniBaseFaseValutazione/InformazioniBaseFaseValutazione";
import InformazioniBaseFaseRichiesta from "../informazioniBaseFaseRichiesta/InformazioniBaseFaseRichiesta";
import EsameObiettivo from "../esameObiettivo/EsameObiettivo";
import GestioneDellaSalute from "../accordionInfermiere/gestioneDellaSalute/GestioneDellaSalute";
import CapacitaCuraDiSe from "../accordionInfermiere/capacitaCuraDiSe/CapacitaCuraDiSe";
import Dieta from "../accordionInfermiere/dieta/Dieta";
import Ingestione from "../accordionInfermiere/ingestione/Ingestione";
import ComunicazioneSensorio from "../accordionInfermiere/comunicazioneSensorio/ComunicazioneSensorio";
import FunzioneUrinaria from "../accordionInfermiere/funzioneUrinaria/FunzioneUrinaria";
import IdratazioneInfermiere from "../accordionInfermiere/idratazione/IdratazioneInfermiere";
import Assorbimento from "../accordionInfermiere/assorbimento/Assorbimento";
import FunzioneTegumentaria from "../accordionInfermiere/funzioneTegumentaria/FunzioneTegumentaria";
import FunzioneRespiratoria from "../accordionInfermiere/funzioneRespiratoria/FunzioneRespiratoria";
import SonnoRiposo from "../accordionInfermiere/SonnoERiposo/SonnoRiposo";
import FunzioneGastrointestinale from "../accordionInfermiere/funzioneGastrointestinale/FunzioneGastrointestinale";
import CopingTolleranzaStressInfermiere
    from "../accordionInfermiere/copingTolleranzaStress/CopingTolleranzaStressInfermiere";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import {mostraSpinner, nascondiSpinner} from "../../App";
import ButtonHelper from "../../service/ButtonHelper";
import {v4 as uuid} from "uuid";
import enumUtente from "../../enum/enumsUtente.json";
import InserimentoRichiesta from "../inserimentoRichiesta/InserimentoRichiesta";
import {getCountMandatoryFieldsMissing} from "../anagrafeRichiedente/AnagrafeRichiedente";
import ValutazioneBisogniPalliativi from "../valutazioneBisogniPalliativi/ValutazioneBisogniPalliativi"
import VersionedAccordion from "../VersionedAccordion";
import {
    isFieldIdWriteable,
    isSomeFieldIdWriteable,
    rolesProfessionista
} from "../roleBasedComponents/RoleBasedComponents";
import ScrollTop from "../scrollTop/ScrollTop";
import enumRichiesta from "../../enum/enumRichiesta";
import {getValutazionePrecedente} from "../home/HomeUtils";
import DatiRichiesta from "../datiRichiesta/DatiRichiesta";
import ButtonsBoxStick from "../buttonsBoxStick/buttonsBoxStick";
import AuthUtils from "../../utils/AuthUtils";
import UserHelper from "../../service/userHelper";
import accordionsCompilaRichiesta from "../../utils/dataset/accordionData/accordionSubaccordionCompilaRichiesta.json";
import ValutazioneProfessionista from "../compilaProposte/valutazioneProfessionista/ValutazioneProfessionista";
import BisogniPrestazioniAssistenziali
    from "../compilaProposte/bisogniPrestazioniAssistenziali/BisogniPrestazioniAssistenziali";
import FigureProfessionaliPresaInCarico
    from "../compilaProposte/figureProfessionaliPresaInCarico/FigureProfessionaliPresaInCarico";
import LivelloPrioritaRichiesta from "../compilaProposte/livelloPriorita/LivelloPrioritaRichiesta";
import ProposteAttivitaSuggerimenti from "../compilaProposte/proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimenti";
import statiRichiesta from "../../utils/dataset/richiestaData/statiRichiesta.json";
import DataDimissioneProgrammataDaRicovero
    from "../compilaProposte/dataDimissioneProgrammataDaRicovero/DataDimissioneProgrammataDaRicovero";
import DataDiPresaInCaricoTerritorialeDaDimissioneProtetta
    from "../compilaProposte/dataDiPresaInCaricoDaDimissioneProtetta/DataDiPresaInCaricoTerritorialeDaDimissioneProtetta";
import BisogniAllaDimissione from "../compilaProposte/bisogniAllaDimissione/BisogniAllaDimissione";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import ValutazioneInFormalizzazione
    from "../compilaFormalizzazione/caratteristicheSettingRichiesto/ValutazioneInFormalizzazione";
import WebSocketHelper from "../../websocket/WebSocketHelper";
import styles from "./CompilaRichiestaCampiObbligatori.module.css";
import ModalHelper from "../modale/ModalHelper";
import {
    isPsanSectionVipRequired
} from "../anamnesiFisiologica/necessitaDiAssistenzaSanitaria/NecessitaDiAssistenzaSanitaria";

const propTypes = {
    richiesta: PropTypes.object,
    richiestaMedico: PropTypes.object,
    persone: PropTypes.object,
    pageState: PropTypes.string,
    pageName: PropTypes.string,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    openAccordion: PropTypes.bool,
    hideDefaultHeader: PropTypes.bool,
    listaCodiciFiscaliUtentiOnline: PropTypes.array
}

export const buildFullRichiesta = (richiesta, self, pageName, buildAnagrafe = true) => {
    let datiAnagrafePaziente = _.cloneDeep(self.accordionData.anagrafePaziente);

    if (buildAnagrafe) buildAnagrafePaziente(datiAnagrafePaziente, richiesta);
    else richiesta.anagrafePaziente = datiAnagrafePaziente;

    richiesta.situazioneSociale = self.accordionData.situazioneSociale;
    richiesta.determinantiDiSalute = self.accordionData.determinantiDiSalute;
    richiesta.anamnesiFisiologica = self.accordionData.anamnesiFisiologica;
    richiesta[enumRichiesta.attributi.informazioniBaseFaseValutazione] = self.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione];
    richiesta[enumRichiesta.attributi.informazioniBaseFaseRichiesta] = self.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta];
    richiesta.informazioniDiBase = self.accordionData.informazioniDiBase;
    richiesta.parametriVitali = self.accordionData.parametriVitali;
    richiesta.valutazioneBisogniPalliativi = self.accordionData.valutazioneBisogniPalliativi;

    let anamnesiPatologicaProssima = _.cloneDeep(self.accordionData.anamnesiPatologicaProssima);
    anamnesiPatologicaProssima.patologieAttive = anamnesiPatologicaProssima.patologieAttive.filter(patologia => {
        let e = _.cloneDeep(patologia);
        delete e.id;
        return !Utils.isObjectNull(e);
    });
    richiesta.anamnesiPatologicaProssima = anamnesiPatologicaProssima;

    richiesta.anamnesiPatologicaRemota = self.accordionData.anamnesiPatologicaRemota;
    richiesta.anamnesiFamiliare = self.accordionData.datiAnamnesiFamiliare;
    richiesta.benessereAbitativo = self.accordionData.benessereAbitativo;
    richiesta.esameObiettivo = self.accordionData.esameObiettivo;

    let bisogniPrestazioniAssistenziali = _.cloneDeep(self.accordionData.bisogniPrestazioniAssistenziali);
    bisogniPrestazioniAssistenziali = bisogniPrestazioniAssistenziali.filter(el => !Utils.isStringEmptyOrNullOrUndefined(el));
    richiesta.bisogniPrestazioniAssistenziali = bisogniPrestazioniAssistenziali;

    richiesta.obiettiviClinicoAssistenziali = self.accordionData.obiettiviClinicoAssistenziali;

    let figureProfessionaliPresaInCarico = _.cloneDeep(self.accordionData.figureProfessionaliPresaInCarico);
    figureProfessionaliPresaInCarico = figureProfessionaliPresaInCarico?.filter(el => {
        let e = _.cloneDeep(el);
        delete e.id;
        return !Utils.isObjectNull(e);
    }) ?? [];
    richiesta.figureProfessionaliPresaInCarico = figureProfessionaliPresaInCarico;

    if (richiesta?.tipologia === enumRichiesta.tipologia.CLASSICA && !Utils.isObjectNull(figureProfessionaliPresaInCarico)) {
        const idTeamAdiFor = richiesta?.team?.find(el => el.profilo === enumUtente.profiliUtente.medicoFormalizzatore)?.id ?? uuid();
        const idTeamAdiCoord = richiesta?.team?.find(el => el.profilo === enumUtente.profiliUtente.coordinatore)?.id ?? uuid();
        richiesta.team = buildListaTeamProfessionista(figureProfessionaliPresaInCarico, idTeamAdiFor, idTeamAdiCoord);
    }

    let proposteAttivitaSuggerimenti = _.cloneDeep(self.accordionData.proposteAttivitaSuggerimenti).filter(p => !Object.prototype.hasOwnProperty.call(p, "firstPageAccordionPath"));
    proposteAttivitaSuggerimenti = proposteAttivitaSuggerimenti.filter(el => !Utils.isObjectNull(el));
    richiesta.proposteAttivitaSuggerimenti = proposteAttivitaSuggerimenti;

    let professionistiSupportoAValutazione = _.cloneDeep(self.accordionData.professionistiSupportoAValutazione);
    professionistiSupportoAValutazione = professionistiSupportoAValutazione?.filter(p => p.figureRichieste.length > 0 || p.note)?.map(p => {
        p.figureRichieste = _.uniqBy(p.figureRichieste, 'ruolo');
        return p;
    }) ?? [];
    richiesta.professionistiSupportoAValutazione = professionistiSupportoAValutazione;
    richiesta.proposteAttivitaSuggerimentiAssistenteSociale = self.accordionData.proposteAttivitaSuggerimentiAssistenteSociale;
    richiesta.dataDimissioneProgrammataDaRicovero = self.accordionData.dataDimissioneProgrammataDaRicovero;
    richiesta.bisogniAllaDimissione = self.accordionData.bisogniAllaDimissione;
    richiesta.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta = self.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta;
    richiesta.livelloPriorita = self.accordionData.livelloPriorita;
    richiesta.livelloPrioritaBozza = self.accordionData.livelloPrioritaBozza;
    richiesta.proposteAttivitaSuggerimentiNote = self.accordionData.proposteAttivitaSuggerimentiNote;

    richiesta.valutazioneProfessionista = _.cloneDeep(self.accordionData.valutazioneProfessionista);
}

const buildAnagrafePaziente = (datiAnagrafePaziente, richiesta) => {
    richiesta.anagrafePaziente = {};

    richiesta.anagrafePaziente.datiGenerali = {
        identificativoEventoAssistenzialeDiCD: datiAnagrafePaziente.datiGenerali.identificativoEventoAssistenzialeDiCD,
        ilPazienteEInformatoDellaDomanda: datiAnagrafePaziente?.datiGenerali?.ilPazienteEInformatoDellaDomanda ?? null
    };

    richiesta.anagrafePaziente.residenza = {residenzaDiversaDaDomicilio: datiAnagrafePaziente.residenza.residenzaDiversaDaDomicilio};
    richiesta.anagrafePaziente.domicilio = {domicilioDiversoDaDomicilioCure: datiAnagrafePaziente.domicilio.domicilioDiversoDaDomicilioCure};

    if (datiAnagrafePaziente.mmgPlsDelPaziente?.id) {
        richiesta.anagrafePaziente.mmgPlsDelPaziente = {id: datiAnagrafePaziente.mmgPlsDelPaziente.id};
        if (!Utils.isObjectNull(richiesta?.mmg)) {
            richiesta.mmg.codiceFiscale = datiAnagrafePaziente.mmgPlsDelPaziente.identificativoUtente;
            richiesta.mmg.nome = datiAnagrafePaziente.mmgPlsDelPaziente.nome;
            richiesta.mmg.cognome = datiAnagrafePaziente.mmgPlsDelPaziente.cognome;
            richiesta.mmg.email = datiAnagrafePaziente.mmgPlsDelPaziente.email;
            richiesta.mmg.idProfessionista = datiAnagrafePaziente.mmgPlsDelPaziente.id;
        }
    }

    if (!Utils.isObjectNull(datiAnagrafePaziente.settingDiProvenienza))
        richiesta.anagrafePaziente.settingDiProvenienza = datiAnagrafePaziente.settingDiProvenienza;

    if (datiAnagrafePaziente.padre?.id) {
        richiesta.anagrafePaziente.padre = {
            id: datiAnagrafePaziente.padre.id,
            isPadreIndicatoComeRiferimentoPerPaziente: datiAnagrafePaziente.padre?.isPadreIndicatoComeRiferimentoPerPaziente
        };
    }

    if (datiAnagrafePaziente.madre?.id) {
        richiesta.anagrafePaziente.madre = {
            id: datiAnagrafePaziente.madre.id,
            isMadreIndicataComeRiferimentoPerPaziente: datiAnagrafePaziente.madre?.isMadreIndicataComeRiferimentoPerPaziente
        };
    }

    if (datiAnagrafePaziente.tutore.length > 0) {
        richiesta.anagrafePaziente.tutore = [];
        datiAnagrafePaziente.tutore.forEach(tutore => {
            if (tutore?.id) {
                richiesta.anagrafePaziente.tutore.push({
                    id: tutore.id
                });
            }
        });
    }

    if (datiAnagrafePaziente.amministratoreDiSostegno.length > 0) {
        richiesta.anagrafePaziente.amministratoreDiSostegno = [];
        datiAnagrafePaziente.amministratoreDiSostegno.forEach(amministratoreDiSostegno => {
            if (amministratoreDiSostegno?.id) {
                richiesta.anagrafePaziente.amministratoreDiSostegno.push({
                    id: amministratoreDiSostegno.id,
                    conDelegaSanitaria: amministratoreDiSostegno.conDelegaSanitaria,
                    numeroDecretoDiNomina: amministratoreDiSostegno.numeroDecretoDiNomina,
                    annoDecretoDiNomina: amministratoreDiSostegno.annoDecretoDiNomina
                });
            }
        });
    }

    if (datiAnagrafePaziente.fiduciario?.id) {
        richiesta.anagrafePaziente.fiduciario = {id: datiAnagrafePaziente.fiduciario.id};
    }
}

const buildListaTeamProfessionista = (figureProfessionali, idTeamAdiFor, idTeamAdiCoord) => {
    const defaultTeamProfessionista = {
        id: null,
        profilo: null,
        figureRichieste: [],
        // professionistaConferma: null,
        timestampConferma: null,
        timestampRiapertura: null,
        note: null,
        fase: 'R'
    };
    const figureMapper = p => ({
        id: p.id,
        ruolo: p.figuraProfessionale,
        disciplina: p.disciplina,
        numeroProfessionisti: 1,
        codiceFiscale: p.codiceFiscale
    });
    const CODICE_INFERMIERE = "03";
    const CODICE_INFERMIERE_CURE_PALLIATIVE = "04";
    let list;

    let figure = figureProfessionali.filter(el => el.figuraProfessionale);
    // const professionisti = figure.filter(el => el.figuraProfessionale !== CODICE_INFERMIERE && el.figuraProfessionale !== CODICE_INFERMIERE_CURE_PALLIATIVE);
    list = [{
        ...defaultTeamProfessionista,
        id: idTeamAdiFor,
        profilo: enumUtente.profiliUtente.medicoFormalizzatore,
        note: null,
        figureRichieste: [] //professionisti.map(figureMapper)
    }];

    const infermieri = figure.filter(el => el.figuraProfessionale === CODICE_INFERMIERE || el.figuraProfessionale === CODICE_INFERMIERE_CURE_PALLIATIVE);
    if (infermieri.length > 0) {
        list.push({
            ...defaultTeamProfessionista,
            id: idTeamAdiCoord,
            profilo: enumUtente.profiliUtente.coordinatore,
            note: null,
            figureRichieste: [] //infermieri.map(figureMapper)
        });
    }

    return list;
}

export const disableButton = (idButton, flag) => {
    if (flag) {
        $(idButton).prop("disabled", true).attr("style", "background-color: #BDBDBD; border: 2px solid #BDBDBD; color: #333333 !important");
    } else {
        $(idButton).prop("disabled", false).removeAttr("style");
    }
}

export default class CompilaRichiestaCampiObbligatori extends Component {

    state = {
        redirectToVisualizzaRiepilogo: false,
        redirectToAnagrafeRichiedente: false,
        salvaBozzaSubscription: null,
        accordion: {
            openAccordionDatiRichiesta: true,
            openAccordionAnagrafePaziente: true,
            openAccordionSituazioneSociale: true,
            openAccordionBenessereAbitativo: true,
            openAccordionDeterminantiSalute: true,
            openAccordionDieta: true,
            openAccordionAnamnesiFisiologica: true,
            openAccordionGestioneDellaSalute: true,
            openAccordionSonnoRiposo: true,
            openAccordionCapacitaCuraDiSe: true,
            openAccordionIdratazioneInfermiere: true,
            openAccordionAssorbimento: true,
            openAccordionComunicazioneSensorio: true,
            openAccordionInformazioniBaseFaseValutazione: true,
            openAccordionInformazioniBaseFaseRichiesta: true,
            openAccordionInfoBase: true,
            openAccordionAnamnesiPatologicaRemota: true,
            openAccordionAnamnesiPatologicaProssima: true,
            openAccordionAnamnesiFamiliare: true,
            openAccordionEsameObiettivo: true,
            openAccordionIngestione: true,
            openAccordionFunzioneUrinaria: true,
            openAccordionFunzioneTegumentaria: true,
            openAccordionFunzioneRespiratoria: true,
            openAccordionFunzioneGastrointestinale: true,
            openAccordionCopingTolleranzaStressInfermiere: true,
            openAccordionParametriVitali: true,
            openAccordionValutazioneBisogniPalliativi: true,
            openAccordionBisogniPrestazioniAssistenziali: true,
            openAccordionFigureProfessionaliPresaInCarico: true,
            openAccordionValutazioneProfessionista: true,
            openAccordionProposteAttivitaSuggerimenti: true,
            openAccordionFigureProfessionaliSupportoAValutazione: true,
            openAccordionLivelloPriorita: true,
            openAccordionDataDimissioneProgrammataDaRicovero: true,
            openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta: true,
            openAccordionBisogniAllaDimissione: true,
            openAccordionValutazione: true
        },
        apriModaleAlert: false,
        idAccordionFirstLevel: null,
        redirectToAccordionPage: false
    }

    accordionData;
    datiA0;
    team;

    // oggetto utilizzato per controllare se abilitare o disabilitare il pulsante Prosegui
    obbligatorieta = {
        anagrafePaziente: null,
        situazioneSociale: null,
        anamnesiFisiologica: null,
        informazioniDiBase: null,
        informazioniBaseFaseRichiesta: null,
        informazioniBaseFaseValutazione: null,
        anamnesiPatologicaProssima: null,
        funzioneUrinaria: null,
        determinantiDiSalute: null,
        comunicazioneSensorio: null,
        assorbimento: null,
        benessereAbitativo: null,
        anamnesiPatologicaRemota: null,
        esameObiettivo: null,
        funzioneGastrointestinale: null,
        dieta: null,
        sonnoRiposo: null,
        funzioneRespiratoria: null,
        ingestione: null,
        funzioneTegumentaria: null,
        bisogniPrestazioniAssistenziali: null,
        figureProfessionaliPresaInCarico: null,
        proposteAttivitaSuggerimenti: null,
        figureProfessionaliSupportoAllaValutazione: null,
        dataDiPresaInCaricoTerritorialeDaDimissioneProtetta: null,
        livelloPriorita: null,
        dataDimissioneProgrammataDaRicovero: null,
        bisogniAllaDimissione: null
    };

    // oggetto utilizzato per controllare che al click del bottone Prosegui si può accedere alla pagina successiva
    validitaPagina = {
        anagrafePaziente: false,
        situazioneSociale: false
    };

    constructor(props) {
        super(props);

        this.accordionData = SessionRequest.getSessionObjectFor(props.richiesta);

        if (Utils.isStateRichiestaPreDialogo(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) {
            delete this.obbligatorieta.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta;
        }

        if (!Utils.isDimissioneProtetta(this.props.pageState)) {
            delete this.obbligatorieta.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta;
            delete this.obbligatorieta.dataDimissioneProgrammataDaRicovero;
            delete this.obbligatorieta.bisogniAllaDimissione;
        }

        this.updateFields();

        this.team = this.props.richiesta?.team ?? [];

        this.datiA0 = _.cloneDeep({
            ...this.accordionData.anagrafePaziente,
            aulssAssistenza: this.props.richiesta.aulss,
            distrettoAssistenza: this.props.richiesta.distretto,
            provinciaAssistenza: this.props.richiesta.provincia
        });
    }

    componentDidMount() {
        this.countMandatoryFieldsMissing();

        this.setState({
            salvaBozzaSubscription: InserimentoRichiesta.salvaBozzaSubject.subscribe((isMenuItemClicked) => {
                this.effettuaSalvataggio(false, false, false, null, !!isMenuItemClicked);
            })
        }, () => {
            InserimentoRichiesta.infoRichiestaSubject.next({
                idRichiesta: this.props.richiesta.id,
                anagrafePaziente: this.accordionData?.anagrafePaziente ?? null,
                situazioneSociale: this.accordionData?.situazioneSociale ?? null,
                richiedenteOrMcp: this.props.persone?.[Utils.recuperoIdProfessionista(this.props.richiesta)]
            });
        });

        if (AccordionHelper.idAccordionToAddInformation != null && AccordionHelper.lastSurveyCompiled == null) {
            if (AccordionHelper.idAccordionToAddInformation.startsWith("datiRichiesta")) {
                const mainAccordion = $("div#datiRichiestaAccordion");
                if (mainAccordion.find("> div.collapse.show").length === 0)
                    mainAccordion.find("> div.collapse-header > button").trigger("click");
            }
            setTimeout(() => {
                    AccordionHelper.onScrollIntoAccordion(AccordionHelper.idAccordionToAddInformation);
                    AccordionHelper.setIdAccordionToAddInformation(null);
                    AccordionHelper.setCloneIdAccordionToAddInformation(null);
                },
                1000
            );

            return;
        }

        if (AccordionHelper.idSelectedAccordions != null) {
            const selectedIds = this.getFormattedAccordionIds(AccordionHelper.idSelectedAccordions);
            $(document).ready(function () {
                AccordionHelper.onScrollIntoAccordion(selectedIds);
                AccordionHelper.setIdSelectedAccordions(null);
                AccordionHelper.setLastPageUrlBeforeMenuItemClick(null);
            });
            return;
        }

        if (this.props.openAccordion) {
            let accordion = _.cloneDeep(this.state.accordion);
            for (const singoloAccordion in accordion) {
                accordion[singoloAccordion] = true;
            }
            this.setState({accordion: accordion});
        } else {
            this.setState({accordion: AccordionHelper.initLastOpenedAccordionInAccordionObject(this.state.accordion, AccordionHelper.lastOpenedAccordion, this.props.openAccordion)});
        }
    }

    getFormattedAccordionIds = (idsSelected) => {
        let ids = (idsSelected ?? []).split('|');
        let canAddPrefixSezioneRichiesta = true;
        ids = ids.map((e, i) => {
            const isEvaluation = Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState);
            canAddPrefixSezioneRichiesta = ids[0] !== "valutazioneProfessionista" && !ids[0].endsWith("Infermiere");
            const isSezioneRichiesta = canAddPrefixSezioneRichiesta && Object.keys(accordionsCompilaRichiesta).includes(ids[0]);
            if (isEvaluation && isSezioneRichiesta) return `${i === 0 ? 'datiRichiesta' : 'datiRichiestaSub'}${e}`;
            return e;
        }).join('|');
        return `${canAddPrefixSezioneRichiesta ? 'datiRichiestaAccordion|' : ''}${ids}`;
    }

    countMandatoryFieldsMissing = () => {
        if (this.props.pageName === enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE) {
            const richiedente = this.props.persone?.[Utils.recuperoIdProfessionista(this.props.richiesta)];
            let missingFieldsCount = 0;
            if (richiedente?.identificativoUtente === SessioneUtente.getInstance().idUtente) {
                missingFieldsCount = getCountMandatoryFieldsMissing(richiedente, this.props.pageState);
            }
            this.setState({redirectToAnagrafeRichiedente: missingFieldsCount > 0});
        }
    }

    componentWillUnmount() {
        if (this.state.salvaBozzaSubscription) this.state.salvaBozzaSubscription.unsubscribe();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if( AuthUtils.hasUtenteRuoloInfermiere() && (!this.props.richiesta || !this.props.richiestaMedico)) return;
        if( !AuthUtils.hasUtenteRuoloInfermiere() && !this.props.richiesta) return;
        let $this = this;
        $(document).ready(function () { 

            if ($('div.box-accordion-body').is("html *")) {
                $('div.box-accordion-body > div:empty').remove();
                $('div.box-accordion-header:empty').remove();
                $('div.box-accordion-body:empty').closest('div.box-accordion').remove();
                $('div.box-accordion-body:empty').remove();
            }
    
            if ($('div.align-self-center:empty').is("html *") === null) {
                $('div.align-self-center:empty').remove();
            }
    
            if ($('div.align-self-start:empty').is("html *") === null) {
                $('div.align-self-start:empty').remove();
            }
    
            if ($('div.align-items-top').is("html *") && !AuthUtils.hasUtenteRuoloInfermiere()) {
                $('div.align-items-top > div.col-3:empty').remove();
                $('div.align-items-top > div.col-6:empty').remove();
                $('div.align-items-top > div.col-12:empty').remove();
            }
    
            if ($('div.align-items-center').is("html *")) {
                $('div.align-items-center > div.col-3:empty').remove();
    
                let alignItemsTopCol12Empty = $('div.align-items-top > div.col-12:empty');
                if (alignItemsTopCol12Empty.is("html *")) alignItemsTopCol12Empty.remove();
    
                let alignItemsTopEmpty = $('div.align-items-top:empty');
                if (alignItemsTopEmpty.is("html *")) alignItemsTopEmpty.remove();
    
                $('div.align-items-center:empty').remove();
            }
    
            if ($('div#statisticaContainer').is("html *")) {
                const arrayEmpty = $("div#statisticaContainer > div.col-6 > div.empty");
                arrayEmpty.each(function () {
                    $(this).parent().remove()
                });
                $("div#statisticaContainer > div.col-6:empty").remove();
                $("div#statisticaContainer > div.col-12:empty").remove();
                $("div#statisticaContainer:empty").remove();
                $("div.align-items-top > div.mb-3:empty").remove();
                $("div.align-items-top:empty").remove();
            }
    

            $this.removeEmptySubAccordionsWithSurvey("idSottoaccordionQuestionariAnamnesiFisiologica");
            $this.removeEmptySubAccordionsWithSurvey("idSottoaccordionQuestionariCapacitaCuraDiSe");
            
    
            const isPsanVipRequired = isPsanSectionVipRequired($this.props.richiesta.figureProfessionaliPresaInCarico,
                $this.props.richiesta.professionistiSupportoAValutazione);
            if (isPsanVipRequired) {
                let subAccordionWithSurvey = $('div.col-12.col-xl-6 > div#datiNecessitaDiAssistenzaSanitaria');
                if (subAccordionWithSurvey.is("html *")) {
                    let parent = subAccordionWithSurvey.parent()?.[0];
                    if (parent != null) $(parent).show();
                }
            }
    
            if ($('div.collapse-body').is("html *")) {
                $('div.collapse-body > div:empty').closest('div.collapse-div').remove();
                $('div.collapse-body > div.row > div.col:empty').closest('div.collapse-div').remove();
            }
    
            let accordionsSubAccordions = $('div.collapse-body:empty');
            if (accordionsSubAccordions.is("html *")) {
                // Duplicato perchè prima vengono eliminati i sottoAccordion vuoti e poi i relativi Accordion vuoti
                accordionsSubAccordions.closest('div.collapse-div').remove();
                accordionsSubAccordions.closest('div.collapse-div').remove();
            }

        })

        if (!_.isEqual(prevProps.richiesta, this.props.richiesta)) this.updateFields();
    }

    removeEmptySubAccordionsWithSurvey = (idSelector) => {
        const selectorAll = "[id^='" + idSelector + "']";
        let subAccordionsWithSurvey = $(selectorAll + " > div.col-12.col-xl-6 > ");

        if (subAccordionsWithSurvey.is("html *")) {
            for (const element of subAccordionsWithSurvey) {
                if ($(element).is("html *") && $(element)?.children()?.length <= 1) {
                    let parent = $(element).parent()?.[0];
                    if (parent != null) $(parent).hide();
                }
            }
        }
    }

    updateFields() {
        let richiesta = this.props.richiesta;

        if (richiesta) {
            this.accordionData.anagrafePaziente = this.initObjectsForm("anagrafePaziente", richiesta, defaultAnagrafePaziente);
            this.accordionData.situazioneSociale = this.initObjectsForm("situazioneSociale", richiesta, this.accordionData.situazioneSociale);
            this.accordionData.determinantiDiSalute = this.initObjectsForm("determinantiDiSalute", richiesta, this.accordionData.determinantiDiSalute);
            this.accordionData.anamnesiFisiologica = this.initObjectsForm("anamnesiFisiologica", richiesta, this.accordionData.anamnesiFisiologica);
            this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione] = this.initObjectsForm(enumRichiesta.attributi.informazioniBaseFaseValutazione, richiesta, this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]);
            this.accordionData.informazioniDiBase = this.initObjectsForm("informazioniDiBase", richiesta, this.accordionData.informazioniDiBase);
            this.accordionData.datiAnamnesiFamiliare = this.initObjectsForm("anamnesiFamiliare", richiesta, this.accordionData.datiAnamnesiFamiliare);
            this.accordionData.anamnesiPatologicaProssima = this.initObjectsForm("anamnesiPatologicaProssima", richiesta, this.accordionData.anamnesiPatologicaProssima);
            this.accordionData.anamnesiPatologicaRemota = this.initObjectsForm("anamnesiPatologicaRemota", richiesta, this.accordionData.anamnesiPatologicaRemota);
            this.accordionData.benessereAbitativo = this.initObjectsForm("benessereAbitativo", richiesta, this.accordionData.benessereAbitativo);
            this.accordionData.esameObiettivo = this.initObjectsForm("esameObiettivo", richiesta, this.accordionData.esameObiettivo);
            this.accordionData.parametriVitali = this.initObjectsForm("parametriVitali", richiesta, this.accordionData.parametriVitali);
            this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta] = this.initObjectsForm(enumRichiesta.attributi.informazioniBaseFaseRichiesta, richiesta, this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]);
            this.accordionData.valutazioneBisogniPalliativi = this.initObjectsForm("valutazioneBisogniPalliativi", richiesta, this.accordionData.valutazioneBisogniPalliativi);
            this.accordionData.bisogniPrestazioniAssistenziali = this.initObjectsForm("bisogniPrestazioniAssistenziali", richiesta, this.accordionData.bisogniPrestazioniAssistenziali) ?? [];
            this.accordionData.obiettiviClinicoAssistenziali = this.initObjectsForm("obiettiviClinicoAssistenziali", richiesta, this.accordionData.obiettiviClinicoAssistenziali);
            this.accordionData.figureProfessionaliPresaInCarico = this.initObjectsForm("figureProfessionaliPresaInCarico", richiesta, this.accordionData.figureProfessionaliPresaInCarico) ?? [];
            this.accordionData.valutazioneProfessionista = this.initObjectsForm("valutazioneProfessionista", richiesta, this.accordionData.valutazioneProfessionista);
            this.accordionData.proposteAttivitaSuggerimenti = this.initObjectsForm("proposteAttivitaSuggerimenti", richiesta, this.accordionData.proposteAttivitaSuggerimenti) ?? [];
            this.accordionData.professionistiSupportoAValutazione = this.initObjectsForm("professionistiSupportoAValutazione", richiesta, this.accordionData.professionistiSupportoAValutazione) ?? [];
            this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale = this.initObjectsForm("proposteAttivitaSuggerimentiAssistenteSociale", richiesta, this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale);
            this.accordionData.proposteAttivitaSuggerimentiNote = this.initObjectsForm("proposteAttivitaSuggerimentiNote", richiesta, this.accordionData.proposteAttivitaSuggerimentiNote);
            this.accordionData.dataDimissioneProgrammataDaRicovero = this.initObjectsForm("dataDimissioneProgrammataDaRicovero", richiesta, this.accordionData.dataDimissioneProgrammataDaRicovero);
            this.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta = this.initObjectsForm("dataDiPresaInCaricoTerritorialeDaDimissioneProtetta", richiesta, this.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta);
            this.accordionData.bisogniAllaDimissione = this.initObjectsForm("bisogniAllaDimissione", richiesta, this.accordionData.bisogniAllaDimissione);
            this.accordionData.livelloPriorita = richiesta?.livelloPriorita ?? null;
            this.accordionData.livelloPrioritaBozza = richiesta?.livelloPrioritaBozza ?? null;
        }
    }

    initObjectsForm = (property, richiesta, defaultObject) => {
        let output;

        if (Object.prototype.hasOwnProperty.call(richiesta, property)) {
            if (Array.isArray(richiesta[property])) {
                output = [];
                output.push(...richiesta[property]);
            } else if (Object.prototype.toString.call(defaultObject) === '[object Object]') {
                output = _.cloneDeep(defaultObject);
                Object.keys(defaultObject).forEach(key => {
                    if (richiesta[property] != null && key in richiesta[property]) {
                        output[key] = richiesta[property][key];
                    } else {
                        output[key] = defaultObject[key];
                    }
                });
            } else output = defaultObject;
        } else output = defaultObject;

        return output;
    }

    handleChangeDatiAnagrafePaziente = (field, value) => {
        let fieldSplit = field.split(",");
        if (fieldSplit.length === 1) {
            this.accordionData.anagrafePaziente[field] = value;
        } else if (fieldSplit.length > 1) {
            fieldSplit.forEach(fieldName => {
                this.accordionData.anagrafePaziente[fieldName] = value[fieldName];
            });
        }
    }

    handleChangeDatiSituazioneSocialeForm = (field, value) => {
        this.accordionData.situazioneSociale[field] = value;
    }

    handleChangeDeterminantiDiSaluteForm = (field, value) => {
        this.accordionData.determinantiDiSalute[field] = value;
    }

    handleChangeAnamnesiFisiologicaForm = (field, value) => {
        this.accordionData.anamnesiFisiologica[field] = value;
    }

    handleChangeInformazioniBaseFaseValutazioneForm = (field, value) => {
        this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione][field] = value;
    }

    handleChangeInformazioniBaseFaseRichiestaForm = (field, value) => {
        this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta][field] = value;
    }

    handleChangeInformazioniDiBaseForm = (field, value) => {
        this.accordionData.informazioniDiBase[field] = value;
    }

    handleChangeAnamnesiPatologicaRemota = (value) => {
        this.accordionData.anamnesiPatologicaRemota = value;
    }

    handleChangeBenessereAbitativo = (field, value) => {
        this.accordionData.benessereAbitativo[field] = value;
    }

    handleChangeAnamnesiPatologicaProssima = (value) => {
        this.accordionData.anamnesiPatologicaProssima = value;
    }

    handleChangeAnamnesiFamiliare = (field, value) => {
        this.accordionData.datiAnamnesiFamiliare[field] = value;
    }

    handleChangeEsameObiettivo = (field, value) => {
        this.accordionData.esameObiettivo[field] = value;
    }

    handleChangeValutazioneBisogniPalliativi = (field, value) => {
        this.accordionData.valutazioneBisogniPalliativi[field] = value;
    }

    handleChangeDatiAccordionPagina2 = (field, value) => {
        const split = field.split('|');
        if (split.length === 2) {
            this.accordionData[split[0]] = value[0];
            if (split[1] && split[1].startsWith('path')) {
                const propostaOtherAccordionModified = this.accordionData.proposteAttivitaSuggerimenti
                    .filter(e => e.firstPageAccordionPath === value[1])?.map(p => p?.propostaAttivitaSuggerimenti) ?? [];
                _.set(this.accordionData, value[1], propostaOtherAccordionModified);
            }
        } else {
            this.accordionData[field] = value;
        }
        this.forceUpdate();
    }


    handleChangeObbligatorieta = (field, value) => {
        this.obbligatorieta[field] = value;

        let obbligatorietaClone = _.cloneDeep(this.obbligatorieta);
        if (Utils.isStateRichiesta(this.props.pageState)) {
            Object.keys(obbligatorietaClone?.informazioniDiBase ?? {}).forEach(key => {
                if (key !== "sottosezioneTerapiaFarmacologicaInAtto") delete obbligatorietaClone.informazioniDiBase[key];
            });
        }
        let isButtonProseguiEnabled = obbligatorietaClone && Object.keys(obbligatorietaClone).every(key => !obbligatorietaClone[key]);
        AccordionHelper.abilitazioneProseguiSubject.next(true);
        disableButton("#proseguiBtn", !isButtonProseguiEnabled);
    }

    handleValiditaPagina = (field, value) => {
        this.validitaPagina[field] = value;
    }

    salvaBozza = (richiestaBody, canAddInformazioniAggiuntive = false) => {
        return RichiestaADIService.salvaBozza(richiestaBody.id, richiestaBody, ButtonHelper.clickSalvaButton, canAddInformazioniAggiuntive)
            .pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice || response?.status,
                            type: "critical",
                            text: response?.data?.errore || response?.data?.message
                        })
                    }
                }
                this.team = richiestaBody?.team ?? [];

                return richiestaBody;
            }));
    }

    salvaValutazioni = (richiestaBody, isProcediBtn = false, canAddInformazioniAggiuntive = false) => {
        if (isProcediBtn) mostraSpinner();
        return RichiestaADIService.salvaVariazioniInBozza(richiestaBody.id, richiestaBody, ButtonHelper.clickSalvaButton, canAddInformazioniAggiuntive)
            .pipe(map((response) => {
                if (isProcediBtn) nascondiSpinner();
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                }
                this.team = richiestaBody?.team ?? [];

                return richiestaBody;
            }));
    }

    creaPersona = (personaBody) => {
        if (!Utils.isObjectNull(personaBody)) {
            return AnagrafeService.insertPersona(personaBody).pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                    return of(null)
                } else {
                    return response;
                }
            }));
        } else
            return of(null);
    }

    modificaPersona = (idPersona, personaBody) => {
        if (!Utils.isStringEmptyOrNullOrUndefined(idPersona) && !Utils.isObjectNull(personaBody)) {
            return AnagrafeService.modificaPersona(idPersona, personaBody).pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                    return of(null)
                } else {
                    return response;
                }
            }));
        } else
            return of(null);
    }

    cancellaPersona = (uuidPersona) => {
        if (!Utils.isStringEmptyOrNullOrUndefined(uuidPersona)) {
            AnagrafeService.cancellaPersona(uuidPersona).pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                    return of(null)
                } else {
                    return response;
                }
            })).subscribe((response) => {
                if (response) this.prepareToSalvaBozza();
            });
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            this.effettuaSalvataggio();
            AccordionHelper.setLastOpenedAccordion(field);
            this.setState({accordion});
        }
    }

    addInformazioniAggiuntive = (idAccordionFirstLevel) => {
        AccordionHelper.setIdAccordionToAddInformation(idAccordionFirstLevel);
        AccordionHelper.setCloneIdAccordionToAddInformation(idAccordionFirstLevel);

        if (!this.props.readOnly) {
            mostraSpinner();
            this.effettuaSalvataggio(false, false, true, idAccordionFirstLevel);
        } else {
            this.setState({redirectToAccordionPage: true, idAccordionFirstLevel: idAccordionFirstLevel});
        }
    }

    effettuaSalvataggio = (isProcediBtn = false, isSurveyLinkClicked = false,
                           canAddInformazioniAggiuntive = false, idAccordionFirstLevel = null,
                           isMenuItemClicked = false) => {
        if (this.props.readOnly) {
            if (isProcediBtn) this.setState({redirectToVisualizzaRiepilogo: true});
            return;
        }

        if (isMenuItemClicked) mostraSpinner();

        let anagrafePaziente = _.cloneDeep(this.accordionData.anagrafePaziente);
        let obsList = [];

        const lastOpenedAccordion = AccordionHelper.lastOpenedAccordion;
        const isAnagrafeLastOpenedAccordion = !Utils.isStringEmptyOrNullOrUndefined(lastOpenedAccordion) && lastOpenedAccordion === "openAccordionAnagrafePaziente";
        const areAllAccordionsOpened = Object.keys(this.state.accordion).filter(k => k !== "openAccordionDatiRichiesta").every(k => !!this.state.accordion[k]);
        if (areAllAccordionsOpened || isAnagrafeLastOpenedAccordion) {
            let assistito = this.convertObjectToPersonaDto(this.props.richiesta.idAssistito, anagrafePaziente.datiGenerali);
            assistito.indirizzi = [];
            this.setRecapito(assistito.indirizzi, anagrafePaziente.residenza, enumIndirizzo.indirizzo.residenza);
            this.setRecapito(assistito.indirizzi, anagrafePaziente.domicilio, enumIndirizzo.indirizzo.domicilio);
            this.setRecapito(assistito.indirizzi, anagrafePaziente.domicilioDiCura, enumIndirizzo.indirizzo.altro);
            assistito.contatti = [];
            this.setContatto(assistito.contatti, anagrafePaziente.datiGenerali, enumContatto.contatto.telefono, "telefono");
            this.setContatto(assistito.contatti, anagrafePaziente.datiGenerali, enumContatto.contatto.cellulare, "cellulare");
            this.setContatto(assistito.contatti, anagrafePaziente.datiGenerali, enumContatto.contatto.email, "email");
            assistito.esenzioni = anagrafePaziente.esenzioni?.filter(esenzione => Object.keys(esenzione).some(k => k !== "idElemento" && !Utils.isObjectNull(esenzione[k])));
            let mascheraModificabilitaAssistito = anagrafePaziente.datiGenerali?.mascheraModificabilita;
            assistito.mascheraModificabilita = mascheraModificabilitaAssistito !== undefined ? mascheraModificabilitaAssistito : null;
            obsList.push(this.modificaPersona(assistito.id, assistito).pipe(map(paziente => paziente)));

            if (Object.prototype.hasOwnProperty.call(anagrafePaziente,"mmgPlsDelPaziente")
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.mmgPlsDelPaziente.cognome)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.mmgPlsDelPaziente.nome)) {
                let mmgPlsDelPaziente = this.convertObjectToPersonaDto(anagrafePaziente.mmgPlsDelPaziente.id, anagrafePaziente.mmgPlsDelPaziente);
                mmgPlsDelPaziente.contatti = [];
                this.setContatto(mmgPlsDelPaziente.contatti, anagrafePaziente.mmgPlsDelPaziente, enumContatto.contatto.telefono, "telefono");
                this.setContatto(mmgPlsDelPaziente.contatti, anagrafePaziente.mmgPlsDelPaziente, enumContatto.contatto.cellulare, "cellulare");
                this.setContatto(mmgPlsDelPaziente.contatti, anagrafePaziente.mmgPlsDelPaziente, enumContatto.contatto.email, "email");
                if (mmgPlsDelPaziente?.id) {
                    obsList.push(this.modificaPersona(mmgPlsDelPaziente.id, mmgPlsDelPaziente).pipe(map(mmgPlsPaziente => mmgPlsPaziente)));
                } else {
                    const observableMmgPlsPaziente = this.creaPersona(mmgPlsDelPaziente).pipe(map((response) => {
                        if (response)
                            anagrafePaziente.mmgPlsDelPaziente.id = response.id;
                        return response;
                    }));
                    obsList.push(observableMmgPlsPaziente);
                }
            }

            if (Object.prototype.hasOwnProperty.call(anagrafePaziente,"padre")
                && isPazienteMinoreDi18Anni(anagrafePaziente.datiGenerali.dataNascita)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.padre.cognome)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.padre.nome)) {

                let padre = this.convertObjectToPersonaDto(anagrafePaziente.padre.id, anagrafePaziente.padre);
                padre.indirizzi = [];
                if (!Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.padre.indirizzo))
                    this.setRecapito(padre.indirizzi, anagrafePaziente.padre, enumIndirizzo.indirizzo.domicilio);
                padre.contatti = [];
                this.setContatto(padre.contatti, anagrafePaziente.padre, enumContatto.contatto.telefono, "telefono");
                this.setContatto(padre.contatti, anagrafePaziente.padre, enumContatto.contatto.email, "email");
                if (padre?.id) {
                    obsList.push(this.modificaPersona(padre.id, padre).pipe(map(padre => padre)));
                } else {
                    const observablePadre = this.creaPersona(padre).pipe(map((response) => {
                        if (response)
                            anagrafePaziente.padre.id = response.id;
                        return response;
                    }));
                    obsList.push(observablePadre);
                }
            }

            if (Object.prototype.hasOwnProperty.call(anagrafePaziente,"madre")
                && isPazienteMinoreDi18Anni(anagrafePaziente.datiGenerali.dataNascita)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.madre.cognome)
                && !Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.madre.nome)) {

                let madre = this.convertObjectToPersonaDto(anagrafePaziente.madre.id, anagrafePaziente.madre);
                madre.indirizzi = [];
                if (!Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.madre.indirizzo))
                    this.setRecapito(madre.indirizzi, anagrafePaziente.madre, enumIndirizzo.indirizzo.domicilio);
                madre.contatti = [];
                this.setContatto(madre.contatti, anagrafePaziente.madre, enumContatto.contatto.telefono, "telefono");
                this.setContatto(madre.contatti, anagrafePaziente.madre, enumContatto.contatto.email, "email");
                if (madre?.id) {
                    obsList.push(this.modificaPersona(madre.id, madre).pipe(map(madre => madre)));
                } else {
                    const observableMadre = this.creaPersona(madre).pipe(map((response) => {
                        if (response)
                            anagrafePaziente.madre.id = response.id;
                        return response;
                    }));
                    obsList.push(observableMadre);
                }
            }

            if (Object.prototype.hasOwnProperty.call(anagrafePaziente,"tutore")) {
                anagrafePaziente.tutore.forEach(tutore => {
                    if (!Utils.isStringEmptyOrNullOrUndefined(tutore.cognome)
                        && !Utils.isStringEmptyOrNullOrUndefined(tutore.nome)) {

                        let indexTutore = anagrafePaziente.tutore.indexOf(tutore);
                        let personaTutore = this.convertObjectToPersonaDto(tutore.id, tutore);
                        personaTutore.indirizzi = [];
                        if (!Utils.isStringEmptyOrNullOrUndefined(tutore.indirizzo))
                            this.setRecapito(personaTutore.indirizzi, tutore, enumIndirizzo.indirizzo.domicilio);
                        personaTutore.contatti = [];
                        this.setContatto(personaTutore.contatti, tutore, enumContatto.contatto.telefono, "telefono");
                        this.setContatto(personaTutore.contatti, tutore, enumContatto.contatto.email, "email");
                        if (personaTutore?.id) {
                            obsList.push(this.modificaPersona(personaTutore.id, personaTutore).pipe(map(tutore => tutore)));
                        } else {
                            const observableTutori = this.creaPersona(personaTutore).pipe(map((response) => {
                                if (response)
                                    anagrafePaziente.tutore[indexTutore].id = response.id;
                                return response;
                            }));
                            obsList.push(observableTutori);
                        }
                    }
                });
            }

            if (Object.prototype.hasOwnProperty.call(anagrafePaziente,"amministratoreDiSostegno")) {
                anagrafePaziente.amministratoreDiSostegno.forEach(amministratoreDiSostegno => {
                    if (!Utils.isStringEmptyOrNullOrUndefined(amministratoreDiSostegno.cognome)
                        && !Utils.isStringEmptyOrNullOrUndefined(amministratoreDiSostegno.nome)) {

                        let indexAmministratoreDiSostegno = anagrafePaziente.amministratoreDiSostegno.indexOf(amministratoreDiSostegno);
                        let personaAmministratoreDiSostegno = this.convertObjectToPersonaDto(amministratoreDiSostegno.id, amministratoreDiSostegno);
                        personaAmministratoreDiSostegno.indirizzi = [];
                        if (!Utils.isStringEmptyOrNullOrUndefined(amministratoreDiSostegno.indirizzo))
                            this.setRecapito(personaAmministratoreDiSostegno.indirizzi, amministratoreDiSostegno, enumIndirizzo.indirizzo.domicilio);
                        personaAmministratoreDiSostegno.contatti = [];
                        this.setContatto(personaAmministratoreDiSostegno.contatti, amministratoreDiSostegno, enumContatto.contatto.telefono, "telefono");
                        this.setContatto(personaAmministratoreDiSostegno.contatti, amministratoreDiSostegno, enumContatto.contatto.email, "email");
                        if (personaAmministratoreDiSostegno?.id) {
                            const observableAmministratoriDiSostegno = this.modificaPersona(personaAmministratoreDiSostegno.id, personaAmministratoreDiSostegno)
                                .pipe(map(amministratoreDiSostegno => amministratoreDiSostegno));
                            obsList.push(observableAmministratoriDiSostegno);
                        } else {
                            const observableAmministratoriDiSostegno = this.creaPersona(personaAmministratoreDiSostegno).pipe(map((response) => {
                                if (response)
                                    anagrafePaziente.amministratoreDiSostegno[indexAmministratoreDiSostegno].id = response.id;
                                return response;
                            }));
                            obsList.push(observableAmministratoriDiSostegno);
                        }
                    }
                });
            }

            if (Object.prototype.hasOwnProperty.call(anagrafePaziente,"fiduciario")) {
                // Eliminato controllo sulla presenza di cognome & nome - sul db è stato tolto il vincolo del not null su entrambe
                let fiduciario = this.convertObjectToPersonaDto(anagrafePaziente.fiduciario.id, anagrafePaziente.fiduciario);
                fiduciario.indirizzi = [];
                if (!Utils.isStringEmptyOrNullOrUndefined(anagrafePaziente.fiduciario.indirizzo))
                    this.setRecapito(fiduciario.indirizzi, anagrafePaziente.fiduciario, enumIndirizzo.indirizzo.domicilio);
                fiduciario.contatti = [];
                this.setContatto(fiduciario.contatti, anagrafePaziente.fiduciario, enumContatto.contatto.telefono, "telefono");
                this.setContatto(fiduciario.contatti, anagrafePaziente.fiduciario, enumContatto.contatto.email, "email");
                if (fiduciario?.id) {
                    obsList.push(this.modificaPersona(fiduciario.id, fiduciario).pipe(map(fiduciario => fiduciario)));
                } else {
                    const observableFiduciario = this.creaPersona(fiduciario).pipe(map((response) => {
                        if (response)
                            anagrafePaziente.fiduciario.id = response.id;
                        return response;
                    }));
                    obsList.push(observableFiduciario);
                }
            }

            forkJoin(obsList)
                .subscribe((response) => {
                    this.accordionData.anagrafePaziente = anagrafePaziente;
                    response.forEach(r => {
                        if (r != null) this.props.persone[r.id] = r;
                    });
                    if (!isSurveyLinkClicked) {
                        this.prepareToSalvaBozza(isProcediBtn, false, _.cloneDeep(this.props.richiesta),
                            canAddInformazioniAggiuntive, idAccordionFirstLevel, isMenuItemClicked);
                    }
                });
        } else {
            if (!isSurveyLinkClicked) {
                this.prepareToSalvaBozza(isProcediBtn, false, _.cloneDeep(this.props.richiesta),
                    canAddInformazioniAggiuntive, idAccordionFirstLevel, isMenuItemClicked);
            }
        }
    }

    convertObjectToPersonaDto = (idPersona, object) => {
        let persona = {
            ..._.cloneDeep(this.props.persone?.[idPersona]),
            nome: object.nome,
            cognome: object.cognome,
            identificativoUtente: object.identificativoUtente,
            tipoIdentificativoUtente: object.tipoIdentificativoUtente,
            dataNascita: object.dataNascita ? object.dataNascita : null,
            sesso: object.sesso ? object.sesso : null,
            comune: object.comune ? object.comune : null,
            provincia: object.provincia ? object.provincia : null,
            nazione: object.nazione ? object.nazione : null,
            cittadinanza: object.cittadinanza ? object.cittadinanza : null,
            tesseraSanitaria: object.tesseraSanitaria ? object.tesseraSanitaria : null,
            titoloStudio: object.titoloStudio ? object.titoloStudio : null,
            codiceRegionale: object.codiceRegionale ? object.codiceRegionale : null,
            esenzioni: []
        };
        persona.lingue = [];
        this.setLingue(persona.lingue, object);

        return persona;
    }

    setLingue = (lingue, object) => {
        if (Object.prototype.hasOwnProperty.call(object,"madrelingua")
            && Object.prototype.hasOwnProperty.call(object,"livello")
            && Object.prototype.hasOwnProperty.call(object,"descrizione")
            && object.madrelingua !== null) {
            let lingua = {
                idElemento: object?.idLingue?.[0] ?? null,
                descrizione: null,
                madrelingua: object.madrelingua,
                livello: object.livello
            };

            if (object.madrelingua || !Utils.isStringEmptyOrNullOrUndefined(object.livello) || object.descrizione.length > 0) {
                if (object.descrizione.length > 0 && object.descrizione.some(d => d !== null)) {
                    let descrizioniDistinct = Array.from(new Set(object.descrizione)).filter(el => el !== null);
                    descrizioniDistinct.forEach((linguaParlata, index) => {
                        lingue.push({
                            ..._.cloneDeep(lingua),
                            idElemento: object?.idLingue?.[index] ?? null,
                            descrizione: linguaParlata
                        });
                    });
                } else lingue.push(_.cloneDeep(lingua));
            } else lingue.push(_.cloneDeep(lingua));
        }
    }

    setRecapito = (indirizzi, recapito, tipoRecapito) => {
        if (!Utils.isObjectNull(recapito)) {
            let indirizzo = {};
            indirizzo.tipo = tipoRecapito;
            indirizzo.indirizzo = recapito?.indirizzo ?? null;
            indirizzo.numero = recapito?.numero ?? null;
            indirizzo.cap = recapito?.cap ?? null;
            indirizzo.comune = recapito?.comune ?? null;
            indirizzo.provincia = recapito?.provincia ?? null;
            indirizzo.nazione = recapito?.nazione ?? null;
            indirizzo.aulss = recapito?.aulss ?? null;
            indirizzo.distretto = recapito?.distretto ?? null;
            indirizzo.idElemento = Object.prototype.hasOwnProperty.call(recapito,"idElementoIndirizzo")
                ? recapito.idElementoIndirizzo
                : recapito.idElemento
                    ? recapito.idElemento
                    : null;
            indirizzi.push(indirizzo);
        }
    }

    setContatto = (contatti, object, tipoContatto, field) => {
        if (!Utils.isStringEmptyOrNullOrUndefined(object[field])) {
            contatti.push({
                tipo: tipoContatto,
                valore: object[field],
                idElemento: object["idElemento".concat(field.charAt(0).toUpperCase()).concat(field.slice(1))]
            });
        }
    }

    prepareToSalvaBozza = (isProcediBtn = false,
                           returnObservable = false,
                           richiesta = _.cloneDeep(this.props.richiesta),
                           canAddInformazioniAggiuntive = false,
                           idAccordionFirstLevel = null,
                           isMenuItemClicked = false) => {
        if (this.props.readOnly) return;

        let richiestaClone = {
            ..._.cloneDeep(richiesta),
            team: this.team,
        };
        buildFullRichiesta(richiestaClone, this, this.props.pageName);
        let salva = Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)
            ? this.salvaValutazioni(richiestaClone, isProcediBtn, canAddInformazioniAggiuntive)
            : this.salvaBozza(richiestaClone, canAddInformazioniAggiuntive);

        if (isProcediBtn) {
            salva.subscribe(() => this.setState({redirectToVisualizzaRiepilogo: true}));
        } else if (returnObservable) {
            this.effettuaSalvataggio(false, true);
            return salva;
        } else salva.subscribe(() => {
            if (canAddInformazioniAggiuntive) {
                this.setState({
                    redirectToAccordionPage: true,
                    idAccordionFirstLevel: idAccordionFirstLevel
                }, nascondiSpinner);
            } else if (isMenuItemClicked) {
                let request = _.cloneDeep(richiestaClone);
                buildFullRichiesta(request, this, this.props.pageName, false);
                AccordionHelper.requiredAccordionFieldsSubject.next({
                    canRedirectToFullAccordionPage: true,
                    richiesta: request,
                    richiestaMedico: this.props.richiestaMedico,
                    persone: this.props.persone
                });
                nascondiSpinner();
            }
        });
    }

    renderForm() {
        switch (this.props.pageState) {
            case 'R':
            case 'DP-PDA':
                return this.renderAccordionAvvioRichiesta();
            case 'V':
                if (SessioneUtente.getInstance().settings.some(setting => setting.ruolo === 'P_INFERMIERE')) {
                    return this.renderAccordionValutazioneInfermiere();
                } else {
                    return this.renderAccordionValutazioneMedico();
                }
            case 'F':
            case 'DP-PDC':
            case 'DP-F':
                return this.renderAccordionFormalizzazione();
            case 'DP-PDV':
            case 'DP-V':
                return this.renderAccordionValutazioneMedico();
            default:
                return null;
        }
    }

    renderButtons() {
        return (
            <ButtonsBoxStick
                parentClass={"row"}
                btnList={[
                    {
                        id: "salvaBozzaBtn",
                        text: "Salva bozza",
                        className: "btn btn-adi btnWhiteOutlineBorderGreen",
                        onclickFunction: () => {
                            ButtonHelper.setSalvaButtonFlag(true);
                            this.effettuaSalvataggio();
                            InserimentoRichiesta.infoRichiestaSubject.next({
                                idRichiesta: this.props.richiesta.id,
                                anagrafePaziente: this.accordionData?.anagrafePaziente ?? null,
                                situazioneSociale: this.accordionData?.situazioneSociale ?? null,
                                richiedenteOrMcp: this.props.persone?.[Utils.recuperoIdProfessionista(this.props.richiesta)]
                            });
                        },
                        aling: "right",
                        isvisible: !this.props.readOnly
                    },
                    {
                        id: "proseguiBtn",
                        text: "Prosegui",
                        className: "btn btn-adi btnGreenOutline",
                        onclickFunction: () => {
                            let accordionValidatorKeys = _.cloneDeep(Object.keys(this.validitaPagina));
                            const numberOfValidAccordions = accordionValidatorKeys.filter(k => {
                                const validator = this.validitaPagina[k];
                                let validation;
                                let subAccordionValidatorKeys = Object.keys(validator);
                                if (subAccordionValidatorKeys.length) {
                                    validation = subAccordionValidatorKeys
                                        .filter(k => k !== "isTutoreValido" && k !== "isAmministratoreDiSostegnoValido")
                                        .every(k2 => validator[k2] === true);
                                }
                                else validation = validator === true;
                                return validation;
                            }).length;

                            if (numberOfValidAccordions === accordionValidatorKeys.length) {
                                if (WebSocketHelper.valutazioneConfermataEvent !== null) this.apriModaleAlert();
                                else this.effettuaSalvataggio(true);
                            } else {
                                Utils.toasterFunction(Utils.getAlertMessageInvalidPage(accordionValidatorKeys, this.validitaPagina));
                            }
                        },
                        aling: "right",
                        isvisible: true,
                    }
                ]}
            />
        )
    }

    renderAccordionAvvioRichiesta = () => {
        return (
            <>
                {this.state.apriModaleAlert && this.renderModaleAlert()}

                <VersionedAccordion accordion={AnagrafePaziente}
                                    datiAssistitoA0={this.datiA0}
                                    datiAnagrafePaziente={this.accordionData.anagrafePaziente}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnagrafePaziente={this.handleChangeDatiAnagrafePaziente}
                                    openAccordion={this.state.accordion.openAccordionAnagrafePaziente}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnagrafePaziente"}
                                    pageState={this.props.pageState}
                                    cancellaPersona={this.cancellaPersona}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    onValidaPagina={this.handleValiditaPagina}
                                    readOnly={this.props.readOnly}
                                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                />

                <VersionedAccordion accordion={ValutazioneBisogniPalliativi}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    openAccordion={this.state.accordion.openAccordionValutazioneBisogniPalliativi}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionValutazioneBisogniPalliativi"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    onChangeValutazioneBisogniPalliativi={this.handleChangeValutazioneBisogniPalliativi}
                                    onValidaPagina={this.handleValiditaPagina}
                                    readOnly={this.props.readOnly}
                                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                />

                <VersionedAccordion accordion={SituazioneSociale}
                                    datiAnagrafePaziente={this.accordionData.anagrafePaziente}
                                    datiSituazioneSociale={this.accordionData.situazioneSociale}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiSituazioneSociale={this.handleChangeDatiSituazioneSocialeForm}
                                    openAccordion={this.state.accordion.openAccordionSituazioneSociale}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionSituazioneSociale"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    onValidaPagina={this.handleValiditaPagina}
                                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                />
{/* 
                <VersionedAccordion accordion={DeterminantiDiSalute}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDeterminatiDiSalute={this.handleChangeDeterminantiDiSaluteForm}
                                    openAccordion={this.state.accordion.openAccordionDeterminantiSalute}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionDeterminantiSalute"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                /> */}

                <VersionedAccordion accordion={AnamnesiFisiologica}
                                    datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiFisiologica}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiFisiologica"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    datiConsumoTabacco={this.accordionData.determinantiDiSalute?.datiConsumoTabacco}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                />

                <VersionedAccordion accordion={InformazioniBaseFaseRichiesta}
                                    datiInformazioniBaseFaseRichiesta={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiInformazioniBaseFaseRichiesta={this.handleChangeInformazioniBaseFaseRichiestaForm}
                                    openAccordion={this.state.accordion.openAccordionInformazioniBaseFaseRichiesta}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionInformazioniBaseFaseRichiesta"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                />

                <VersionedAccordion accordion={InformazioniDiBase}
                                    datiInformazioniDiBase={this.accordionData.informazioniDiBase}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiInformazioniDiBase={this.handleChangeInformazioniDiBaseForm}
                                    openAccordion={this.state.accordion.openAccordionInfoBase}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionInfoBase"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                />

                <VersionedAccordion accordion={AnamnesiPatologicaRemota}
                                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnamnesiPatologicaRemota={this.handleChangeAnamnesiPatologicaRemota}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaRemota}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiPatologicaRemota"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                />

                <VersionedAccordion accordion={AnamnesiPatologicaProssima}
                                    datiAnamnesiPatologicaProssima={this.accordionData.anamnesiPatologicaProssima}
                                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnamnesiPatologicaProssima={this.handleChangeAnamnesiPatologicaProssima}
                                    openAccordion={this.state.accordion.openAccordionAnamnesiPatologicaProssima}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAnamnesiPatologicaProssima"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                />

                {Utils.isDimissioneProtetta(this.props.pageState)
                    ? this.renderFormPaginaProposteFaseDimissioneProtetta()
                    : this.renderFormPaginaProposteFaseClassica()}
            </>
        );
    }

    renderAccordionValutazioneMedico() {
        let showValutazioneProfessionista = false;
        if (this.props.richiesta?.valutazioni) {
            this.props.richiesta.valutazioni.forEach(valutazione => {
                if (rolesProfessionista.has(valutazione.valutatore?.ruolo))
                    showValutazioneProfessionista = true
            });
        }

        return (
            <>
                {this.state.apriModaleAlert && this.renderModaleAlert()}

                <VersionedAccordion accordion={DatiRichiesta}
                                    datiAssistitoA0={this.datiA0}
                                    accordionData={this.accordionData}
                                    richiesta={this.props.richiesta}
                                    openAccordion={this.state.accordion.openAccordionDatiRichiesta}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionDatiRichiesta"}
                                    pageState={this.props.pageState}
                                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                                    forceReadOnly={false}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiAnagrafePaziente={this.handleChangeDatiAnagrafePaziente}
                                    cancellaPersona={this.cancellaPersona}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    onValidaPagina={this.handleValiditaPagina}
                                    onChangeDatiSituazioneSociale={this.handleChangeDatiSituazioneSocialeForm}
                                    onChangeDeterminatiDiSalute={this.handleChangeDeterminantiDiSaluteForm}
                                    onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                    onChangeDatiAderenzaAllaTerapia={this.handleChangeInformazioniDiBaseForm}
                                    onChangeDatiInformazioniDiBase={this.handleChangeInformazioniDiBaseForm}
                                    onChangeDatiAnamnesiPatologicaRemota={this.handleChangeAnamnesiPatologicaRemota}
                                    onChangeDatiAnamnesiPatologicaProssima={this.handleChangeAnamnesiPatologicaProssima}
                                    onChangeBisogniPrestazioniAssistenziali={this.handleChangeDatiAccordionPagina2}
                                    onChangeDatiFigureProfessionaliPresaInCarico={this.handleChangeDatiAccordionPagina2}
                                    onChangeLivelloPriorita={this.handleChangeDatiAccordionPagina2}
                                    onChangeDatiProposteAttivitaSuggerimenti={this.handleChangeDatiAccordionPagina2}
                                    onChangeDatiFigureProfessionaliSupportoAValutazione={this.handleChangeDatiAccordionPagina2}
                                    onChangeDatiDataDimissioneProgrammataDaRicovero={this.handleChangeDatiAccordionPagina2}
                                    onChangeDatiDataDiPresaInCaricoTerritorialeDaDimissioneProtetta={this.handleChangeDatiAccordionPagina2}
                                    onChangeDatiBisogniAllaDimissione={this.handleChangeDatiAccordionPagina2}
                />

                <VersionedAccordion accordion={BenessereAbitativo}
                                    benessereAbitativo={this.accordionData.benessereAbitativo}
                                    onChangeBenessereAbitativo={this.handleChangeBenessereAbitativo}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    openAccordion={this.state.accordion.openAccordionBenessereAbitativo}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionBenessereAbitativo"}
                                    pageState={this.props.pageState}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                                    disableDoubleInput={AuthUtils.hasUtenteRuoloFisiatra()}
                />

                <VersionedAccordion accordion={InformazioniBaseFaseValutazione}
                                    datiInformazioniBaseFaseValutazione={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeDatiInformazioniBaseFaseValutazione={this.handleChangeInformazioniBaseFaseValutazioneForm}
                                    openAccordion={this.state.accordion.openAccordionInformazioniBaseFaseValutazione}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionInformazioniBaseFaseValutazione"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                />

                <VersionedAccordion accordion={EsameObiettivo}
                                    esameObiettivo={this.accordionData.esameObiettivo}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.prepareToSalvaBozza}
                                    onChangeEsameObiettivo={this.handleChangeEsameObiettivo}
                                    openAccordion={this.state.accordion.openAccordionEsameObiettivo}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionEsameObiettivo"}
                                    pageState={this.props.pageState}
                                    sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                    showAccordionSezioneRichiesta={!isFieldIdWriteable('M_PRES_ALT_COLOR.CUTEEANNESSI.ESAMEOBIETTIVO', this.props.pageState, this.context.forceUserRole)}
                                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                />

                {!Utils.isDimissioneProtetta(this.props.pageState) && showValutazioneProfessionista
                    && <VersionedAccordion
                        accordion={ValutazioneProfessionista}
                        dati={this.accordionData.valutazioneProfessionista}
                        richiesta={this.props.richiesta}
                        salvaBozza={this.prepareToSalvaBozza}
                        onChangeDatiValutazioneProfessionista={this.handleChangeDatiAccordionPagina2}
                        openAccordion={this.state.accordion.openAccordionValutazioneProfessionista}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionValutazioneProfessionista"}
                        pageState={this.props.pageState}
                        onChangeObbligatorieta={this.handleChangeObbligatorieta}
                        addInformazioniAggiuntive={this.addInformazioniAggiuntive}/>}
            </>
        );
    }

    renderAccordionValutazioneInfermiere() {
        let showValutazioneProfessionista = false;
        if (this.props.richiesta?.valutazioni) {
            this.props.richiesta.valutazioni.forEach(valutazione => {
                if (rolesProfessionista.has(valutazione.valutatore?.ruolo))
                    showValutazioneProfessionista = true
            });
        }

        return <>
            {this.state.apriModaleAlert && this.renderModaleAlert()}

            <VersionedAccordion accordion={DatiRichiesta}
                                datiAssistitoA0={this.datiA0}
                                accordionData={this.accordionData}
                                richiesta={this.props.richiesta}
                                richiestaMedico={this.props.richiestaMedico}
                                openAccordion={this.state.accordion.openAccordionDatiRichiesta}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionDatiRichiesta"}
                                pageState={this.props.pageState}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                                forceReadOnly={false}
                                hideUnsetValues={this.props.hideUnsetValues}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnagrafePaziente={this.handleChangeDatiAnagrafePaziente}
                                cancellaPersona={this.cancellaPersona}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                onValidaPagina={this.handleValiditaPagina}
                                onChangeDatiInformazioniDiBase={this.handleChangeInformazioniDiBaseForm}
                                onChangeDeterminatiDiSalute={this.handleChangeDeterminantiDiSaluteForm}
                                onChangeDatiSituazioneSociale={this.handleChangeDatiSituazioneSocialeForm}
                                onChangeBisogniPrestazioniAssistenziali={this.handleChangeDatiAccordionPagina2}
                                onChangeDatiFigureProfessionaliPresaInCarico={this.handleChangeDatiAccordionPagina2}
                                onChangeLivelloPriorita={this.handleChangeDatiAccordionPagina2}
                                onChangeDatiProposteAttivitaSuggerimenti={this.handleChangeDatiAccordionPagina2}
                                onChangeDatiFigureProfessionaliSupportoAValutazione={this.handleChangeDatiAccordionPagina2}
                                onChangeDatiDataDimissioneProgrammataDaRicovero={this.handleChangeDatiAccordionPagina2}
                                onChangeDatiDataDiPresaInCaricoTerritorialeDaDimissioneProtetta={this.handleChangeDatiAccordionPagina2}
                                onChangeDatiBisogniAllaDimissione={this.handleChangeDatiAccordionPagina2}
            />

            <VersionedAccordion accordion={InformazioniBaseFaseValutazione}
                                accordionSubtitle={""}
                                datiInformazioniBaseFaseValutazione={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiInformazioniBaseFaseValutazione={this.handleChangeInformazioniBaseFaseValutazioneForm}
                                openAccordion={this.state.accordion.openAccordionInformazioniBaseFaseValutazione}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionInformazioniBaseFaseValutazione"}
                                pageState={this.props.pageState}
                                sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={AnamnesiFamiliare}
                                datiAnamnesiFamiliare={this.accordionData.datiAnamnesiFamiliare}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFamiliare={this.handleChangeAnamnesiFamiliare}
                                openAccordion={this.state.accordion.openAccordionAnamnesiFamiliare}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionAnamnesiFamiliare"}
                                pageState={this.props.pageState}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={GestioneDellaSalute}
                                datiInformazioniDiBase={this.accordionData.informazioniDiBase}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiInformazioniDiBase={this.handleChangeInformazioniDiBaseForm}
                                openAccordion={this.state.accordion.openAccordionGestioneDellaSalute}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionGestioneDellaSalute"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={Dieta}
                                datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                                onChangeDeterminatiDiSalute={this.handleChangeDeterminantiDiSaluteForm}
                                datiInformazioniDiBase={this.accordionData.informazioniDiBase}
                                onChangeDatiInformazioniDiBase={this.handleChangeInformazioniDiBaseForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                openAccordion={this.state.accordion.openAccordionDieta}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionDieta"}
                                pageState={this.props.pageState}
                                hideUnsetValues={this.props.hideUnsetValues}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={Ingestione}
                                esameObiettivo={this.accordionData.esameObiettivo}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeEsameObiettivo={this.handleChangeEsameObiettivo}
                                openAccordion={this.state.accordion.openAccordionIngestione}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionIngestione"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={IdratazioneInfermiere}
                                datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                datiIdratazione={this.accordionData.anamnesiFisiologica?.statoFunzionale}
                                onChangeDatiIdratazione={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionIdratazioneInfermiere}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionIdratazioneInfermiere"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={Assorbimento}
                                datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                datiAssorbimento={this.accordionData.anamnesiFisiologica?.statoFunzionale}
                                onChangeDatiAssorbimento={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionAssorbimento}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionAssorbimento"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={FunzioneGastrointestinale}
                                datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                datiEsameObiettivo={this.accordionData.esameObiettivo}
                                onChangeEsameObiettivo={this.handleChangeEsameObiettivo}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                openAccordion={this.state.accordion.openAccordionFunzioneGastrointestinale}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionFunzioneGastrointestinale"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={FunzioneUrinaria}
                                esameObiettivo={this.accordionData.esameObiettivo}
                                onChangeEsameObiettivo={this.handleChangeEsameObiettivo}
                                datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                openAccordion={this.state.accordion.openAccordionFunzioneUrinaria}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionFunzioneUrinaria"}
                                pageState={this.props.pageState}
                                sessoPaziente={this.accordionData.anagrafePaziente?.datiGenerali?.sesso}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={FunzioneTegumentaria}
                                esameObiettivo={this.accordionData.esameObiettivo}
                                datiInformazioniDiBase={this.accordionData.informazioniDiBase}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeEsameObiettivo={this.handleChangeEsameObiettivo}
                                onChangeDatiInformazioniDiBase={this.handleChangeInformazioniDiBaseForm}
                                openAccordion={this.state.accordion.openAccordionFunzioneTegumentaria}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionFunzioneTegumentaria"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={FunzioneRespiratoria}
                                esameObiettivo={this.accordionData.esameObiettivo}
                                anamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeEsameObiettivo={this.handleChangeEsameObiettivo}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionFunzioneRespiratoria}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionFunzioneRespiratoria"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={SonnoRiposo}
                                datiSonnoRiposo={this.accordionData.anamnesiFisiologica?.datiRitmoSonnoVeglia}
                                onChangeDatiSonnoRiposo={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionSonnoRiposo}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionSonnoRiposo"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={CapacitaCuraDiSe}
                                datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                datiCapacitaCuraDiSe={this.accordionData.anamnesiFisiologica?.statoFunzionale}
                                onChangeDatiCapacitaCuraDiSe={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionCapacitaCuraDiSe}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionCapacitaCuraDiSe"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={ComunicazioneSensorio}
                                datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                                datiComunicazioneSensorio={this.accordionData.anamnesiFisiologica?.statoFunzionale}
                                onChangeComunicazioneSensorio={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionComunicazioneSensorio}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionComunicazioneSensorio"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={CopingTolleranzaStressInfermiere}
                                datiCopingTolleranzaStress={this.accordionData.anamnesiFisiologica?.copingTolleranzaStress}
                                onChangeCopingTolleranzaStress={this.handleChangeAnamnesiFisiologicaForm}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                onChangeDatiAnamnesiFisiologica={this.handleChangeAnamnesiFisiologicaForm}
                                openAccordion={this.state.accordion.openAccordionCopingTolleranzaStressInfermiere}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionCopingTolleranzaStressInfermiere"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
            />

            <VersionedAccordion accordion={BenessereAbitativo}
                                benessereAbitativo={this.accordionData.benessereAbitativo}
                                onChangeBenessereAbitativo={this.handleChangeBenessereAbitativo}
                                richiesta={this.props.richiesta}
                                salvaBozza={this.prepareToSalvaBozza}
                                openAccordion={this.state.accordion.openAccordionBenessereAbitativo}
                                onClickAccordion={this.onClickAccordion}
                                field={"openAccordionBenessereAbitativo"}
                                pageState={this.props.pageState}
                                onChangeObbligatorieta={this.handleChangeObbligatorieta}
                                addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                                disableDoubleInput={true}
            />
            {!Utils.isDimissioneProtetta(this.props.pageState) && showValutazioneProfessionista
                && <VersionedAccordion
                    accordion={ValutazioneProfessionista}
                    dati={this.accordionData.valutazioneProfessionista}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiValutazioneProfessionista={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionValutazioneProfessionista}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionValutazioneProfessionista"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}/>}
        </>;
    }

    renderAccordionFormalizzazione() {
        return null;
    }

    renderFormPaginaProposteFaseClassica = () => {
        return (
            <>
                <VersionedAccordion
                    accordion={BisogniPrestazioniAssistenziali}
                    dati={this.accordionData.bisogniPrestazioniAssistenziali ?? []}
                    onChangeBisogniPrestazioniAssistenziali={this.handleChangeDatiAccordionPagina2}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    openAccordion={this.state.accordion.openAccordionBisogniPrestazioniAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniPrestazioniAssistenziali"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliPresaInCarico}
                    dati={this.accordionData.figureProfessionaliPresaInCarico}
                    datiNecessitaAssistenzaSanitaria={this.accordionData.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiFigureProfessionaliPresaInCarico={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliPresaInCarico}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliPresaInCarico"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                    firstPageName={this.props.pageName}
                    previousPageOnlyRequiredFields={true}/>

                <VersionedAccordion
                    accordion={LivelloPrioritaRichiesta}
                    professionistiSupportoAValutazione={this.accordionData.professionistiSupportoAValutazione}
                    dati={this.accordionData.livelloPriorita}
                    figureProfessionali={this.accordionData.figureProfessionaliPresaInCarico}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    openAccordion={this.state.accordion.openAccordionLivelloPriorita}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionLivelloPriorita"}
                    onChangeLivelloPriorita={this.handleChangeDatiAccordionPagina2}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                />

                <VersionedAccordion
                    accordion={ProposteAttivitaSuggerimenti}
                    datiProposteAttivitaSuggerimenti={this.accordionData.proposteAttivitaSuggerimenti}
                    datiproposteAttivitaSuggerimentiAssistenteSociale={this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale}
                    datiProposteAttivitaSuggerimentiNote={this.accordionData.proposteAttivitaSuggerimentiNote}
                    datiSituazioneSociale={this.accordionData.situazioneSociale}
                    datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                    datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                    datiInformazioniBase={this.accordionData.informazioniDiBase}
                    datiInformazioniBaseFaseRichiesta={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]}
                    datiInformazioniBaseFaseValutazione={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                    datiBenessereAbitativo={this.accordionData.benessereAbitativo}
                    datiEsameObiettivo={this.accordionData.esameObiettivo}
                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                    datiAnamnesiFamiliare={this.accordionData.datiAnamnesiFamiliare}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiProposteAttivitaSuggerimenti={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionProposteAttivitaSuggerimenti}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionProposteAttivitaSuggerimenti"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}/>
            </>
        );
    }

    renderFormPaginaProposteFaseDimissioneProtetta = () => {
        return (
            <>
                <VersionedAccordion
                    accordion={LivelloPrioritaRichiesta}
                    professionistiSupportoAValutazione={this.accordionData.professionistiSupportoAValutazione}
                    dati={this.props.richiesta.stato === statiRichiesta.confermataPreDialogo.value || this.props.richiesta.stato === statiRichiesta.inChiusuraPreDialogo.value
                        ? this.accordionData.livelloPrioritaBozza
                        : this.accordionData.livelloPriorita}
                    figureProfessionali={this.accordionData.figureProfessionaliPresaInCarico}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    openAccordion={this.state.accordion.openAccordionLivelloPriorita}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionLivelloPriorita"}
                    onChangeLivelloPriorita={this.handleChangeDatiAccordionPagina2}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                />

                <VersionedAccordion
                    accordion={DataDimissioneProgrammataDaRicovero}
                    dati={this.accordionData.dataDimissioneProgrammataDaRicovero}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiDataDimissioneProgrammataDaRicovero={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionDataDimissioneProgrammataDaRicovero}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionDataDimissioneProgrammataDaRicovero"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}/>

                {!Utils.isStateRichiestaPreDialogo(this.props.pageState)
                    && !Utils.isStateValutazionePreDialogo(this.props.pageState) &&
                    <VersionedAccordion
                        accordion={DataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                        dati={this.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                        richiesta={this.props.richiesta}
                        salvaBozza={this.prepareToSalvaBozza}
                        onChangeDatiDataDiPresaInCaricoTerritorialeDaDimissioneProtetta={this.handleChangeDatiAccordionPagina2}
                        openAccordion={this.state.accordion.openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta"}
                        pageState={this.props.pageState}
                        onChangeObbligatorieta={this.handleChangeObbligatorieta}
                        addInformazioniAggiuntive={this.addInformazioniAggiuntive}/>}

                <VersionedAccordion
                    accordion={BisogniPrestazioniAssistenziali}
                    dati={this.accordionData.bisogniPrestazioniAssistenziali ?? []}
                    onChangeBisogniPrestazioniAssistenziali={this.handleChangeDatiAccordionPagina2}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    openAccordion={this.state.accordion.openAccordionBisogniPrestazioniAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniPrestazioniAssistenziali"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}/>

                <VersionedAccordion
                    accordion={BisogniAllaDimissione}
                    dati={this.accordionData.bisogniAllaDimissione}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiBisogniAllaDimissione={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionBisogniAllaDimissione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniAllaDimissione"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliPresaInCarico}
                    dati={this.accordionData.figureProfessionaliPresaInCarico}
                    datiNecessitaAssistenzaSanitaria={this.accordionData.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiFigureProfessionaliPresaInCarico={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliPresaInCarico}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliPresaInCarico"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}
                    firstPageName={this.props.pageName}
                    previousPageOnlyRequiredFields={true}/>

                <VersionedAccordion
                    accordion={ProposteAttivitaSuggerimenti}
                    datiProposteAttivitaSuggerimenti={this.accordionData.proposteAttivitaSuggerimenti}
                    datiproposteAttivitaSuggerimentiAssistenteSociale={this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale}
                    datiProposteAttivitaSuggerimentiNote={this.accordionData.proposteAttivitaSuggerimentiNote}
                    datiSituazioneSociale={this.accordionData.situazioneSociale}
                    datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                    datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                    datiInformazioniBase={this.accordionData.informazioniDiBase}
                    datiInformazioniBaseFaseRichiesta={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]}
                    datiInformazioniBaseFaseValutazione={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                    datiBenessereAbitativo={this.accordionData.benessereAbitativo}
                    datiEsameObiettivo={this.accordionData.esameObiettivo}
                    datiAnamnesiFamiliare={this.accordionData.datiAnamnesiFamiliare}
                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiProposteAttivitaSuggerimenti={this.handleChangeDatiAccordionPagina2}
                    openAccordion={this.state.accordion.openAccordionProposteAttivitaSuggerimenti}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionProposteAttivitaSuggerimenti"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    addInformazioniAggiuntive={this.addInformazioniAggiuntive}/>

                {!Utils.isStateRichiestaPreDialogo(this.props.pageState)
                    && !Utils.isStateValutazionePreDialogo(this.props.pageState)
                    && <AccordionAvvioRichiesta
                        idAccordion={"valutazioneChiusuraPreDialogo"}
                        title={"Valutazione"}
                        openAccordion={this.state.accordion.openAccordionValutazione}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionValutazione"}
                        pageState={this.props.pageState}
                        addInformazioniAggiuntive={this.addInformazioniAggiuntive}>
                        <VersionedAccordion
                            accordion={ValutazioneInFormalizzazione}
                            tipoValutazione={this.props.richiesta?.formalizzazione?.tipoValutazione}
                            dataValutazione={!Utils.isStringEmptyOrNullOrUndefined(this.props.richiesta?.formalizzazione?.dataValutazione)
                                ? this.props.richiesta.formalizzazione.dataValutazione.match(/\d{4}-\d{2}-\d{2}/g)
                                    ? Utils.formatDate(this.props.richiesta?.formalizzazione.dataValutazione, "YYYY-MM-DD", "DD/MM/YYYY")
                                    : this.props.richiesta?.formalizzazione.dataValutazione
                                : ''}
                            openAccordion={this.state.accordion.openAccordionValutazione}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionValutazione"}
                            pageState={this.props.pageState}/>
                    </AccordionAvvioRichiesta>}
            </>
        );
    }

    // Modale di alert al click del button "Prosegui"
    apriModaleAlert = () => {
        let showModale = WebSocketHelper.valutazioneConfermataEvent.idRichiesta === this.props.richiesta.id;
        this.setState({apriModaleAlert: showModale});
    }

    chiudiModaleAlert = () => {
        this.setState({apriModaleAlert: false});
    }

    renderRowsSezioni = (listaSezioniModificate) => {
        let righeJSX = [];
        let arraySezioniModificate = listaSezioniModificate != null
            ? Array.from(listaSezioniModificate.split(";"))
            : ["nessuna sezione modificata"];

        let rigaJSX = <Fragment>
            {arraySezioniModificate?.map((nomeSezione, index) => {
                return (<tr key={index} className={index % 2 === 0 ? styles.rowTableGray : styles.rowTableWhite}>
                    <td>
                        <div className={'row ml-1 mb-1 mt-1 text-justify'}>
                            <div className={'col- span mb-1 mt-1 '}>
                                {nomeSezione}
                            </div>
                        </div>
                    </td>
                </tr>);
            })}

        </Fragment>
        righeJSX.push(rigaJSX);

        return (<Fragment>{righeJSX}</Fragment>);
    }

    renderModaleAlert = () => {
        let nominativoUtente = WebSocketHelper.valutazioneConfermataEvent?.nominativoUtente
        let isInfermiere = Utils.isRuoloInfermiere();

        const body = (
            <div className={"container vertical-scrollable"}>
                <div className={"row"}>
                    <div className={"offset-1 col-10 font-weight-100 text-center py-2"}>
                         <span>
                             <p>
                                {isInfermiere === "P_INFERMIERE" ? "L'infermiere" : "Il medico"} {nominativoUtente} ha confermato la propria valutazione professionale, indicando nella sua valutazione dati diversi da quelli da te inseriti.
                             </p>
                             <p>
                                 {"Puoi visualizzare il dato inserito, nello \"Storico modifiche\" di ciascun campo selezionando la relativa icona. Le sezioni modificate sono le seguenti:"}
                             </p>
                         </span>
                    </div>
                </div>
                <div className={"table-responsive px-2 mt-4 mb-4"}>
                    <table className={"table table-striped table-sm mx-auto w-50"}>
                        <th className="col-span p-2 pr-md-3 bg-secondary text-white"> Sezioni modificate</th>
                        <tbody>
                        {this.renderRowsSezioni(WebSocketHelper.valutazioneConfermataEvent?.listaSezioniModificate)}
                        </tbody>
                    </table>
                </div>
            </div>
        );

        const footer = (
            <>
                <div className={"mx-auto w-auto"}>
                    <Button
                        id={"chiudiBtn"}
                        text={"Ho preso atto"}
                        className={"btn btn-adi btnGreenOutline"}
                        onClick={() => {
                            this.setState({apriModaleAlert: false}, () => this.effettuaSalvataggio(true));
                            closeModaleStyle();
                            //Si rimanda a caso d’uso UC_04_15_0L: Conferma valutazione: Professionista diverso da MMG
                        }}/>
                </div>
            </>
        );

        const title = (
            <>
                <h6 className="modal-title w-100">Attenzione!</h6>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={() => this.chiudiModaleAlert()}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </>
        )

        return (
            <>
                <ModalHelper
                    id={"modaleAlert"}
                    title={title}
                    titleClassName={"modal-header text-center"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered"}
                    contentClassName={("modal-content px-3 py-1 text-center text-danger")}
                    body={body}
                    footer={footer}
                    footerClassName={"modal-footer justify-content-around"}
                />
            </>
        );
    }

    render() {
        if (!this.props.hideDefaultHeader) {
            let headerLabel;
            if (this.props.pageState === 'R') headerLabel = "Compila Scheda paziente";
            else if (Utils.isStateRichiestaPreDialogo(this.props.pageState)) headerLabel = "Attiva Pre-Dialogo";
            else if (Utils.isStateValutazione(this.props.pageState)) headerLabel = "Completa valutazione multidimensionale";
            else headerLabel = "Completa Pre-Dialogo";
            AccordionHelper.accordionPageSubject.next({position: 2, title: headerLabel});
        }

        if (this.state.redirectToAccordionPage) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            buildFullRichiesta(richiesta, this, this.props.pageName, false);
            return (
                <Redirect to={{
                    key: uuid(),
                    pathname: window.location.pathname,
                    uuidRichiesta: this.props.richiesta.id,
                    pageState: this.props.pageState,
                    readOnly: this.props.readOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    firstPageName: this.props.pageName,
                    richiesta: richiesta,
                    richiestaMedico: this.props.richiestaMedico,
                    persone: this.props.persone,
                    tipologia: richiesta?.tipologia,
                    stato: richiesta?.stato,
                    valutazionePrecedente: (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) ? getValutazionePrecedente(richiesta) : null,
                    showOnlyRequiredFields: false,
                    previousPageOnlyRequiredFields: true,
                    lastPageNumber: window.location.href.includes(routepath.accedi_valutazione_multidimensionale) || window.location.href.includes(routepath.visualizza_valutazione_multidimensionale) ? 1 : null
                }}/>
            );
        }

        if (this.state.redirectToVisualizzaRiepilogo) {
            const basePath = Utils.isStateRichiesta(this.props.pageState) ? routepath.inserimento_richiesta : routepath.valutazione_multidimensionale;
            let richiesta = _.cloneDeep(this.props.richiesta);
            buildFullRichiesta(richiesta, this, this.props.pageName, false);
            richiesta = {
                ...richiesta,
                team: richiesta?.team?.map((t, index) => {
                    return {...t, professionista: this.props.richiesta?.team?.[index]?.professionista}
                })
            }
            return (
                <Redirect to={{
                    pathname: basePath.concat(routepath.visualizza_riepilogo),
                    uuidRichiesta: this.props.richiesta.id,
                    richiesta: richiesta,
                    richiestaMedico: this.props.richiestaMedico,
                    pageState: this.props.pageState,
                    readOnly: this.props.readOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    firstPageName: this.props.pageName,
                    tipologia: this.props.richiesta?.tipologia,
                    stato: this.props.richiesta?.stato,
                    valutazionePrecedente: (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) ? getValutazionePrecedente(this.props.richiesta) : null,
                    showOnlyRequiredFields: true,
                    previousPageOnlyRequiredFields: true
                }}/>
            );
        }

        if (this.state.redirectToAnagrafeRichiedente) {
            return (
                <Redirect to={{
                    pathname: routepath.inserimento_richiesta.concat(routepath.compila_scheda_paziente).concat(routepath.anagrafica_medico_richiedente),
                    idPersona: Utils.recuperoIdProfessionista(this.props.richiesta),
                    uuidRichiesta: this.props.richiesta.id,
                    pageState: this.props.pageState,
                    pageName: this.props.pageName,
                    readOnly: !isSomeFieldIdWriteable('ANAGRAFESOGGETTORICHIEDENTE', this.props.pageState, forceUserRole) || !!this.props.readOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    insRichiestaPathname: routepath.inserimento_richiesta.concat(routepath.compila_scheda_paziente),
                    richiesta: this.props.richiesta,
                    showOnlyRequiredFields: true,
                    previousPageOnlyRequiredFields: true
                }}/>
            );
        }

        const isStateValutazione = Utils.isStateValutazione(this.props.pageState);
        const forceUserRole = isStateValutazione && this.props.richiesta.sostituzioneRichiedente
        && (AuthUtils.hasUtenteCentraleAdi() || AuthUtils.hasUtenteRuoloMcp()) ? enumUtente.ruoli.mmg : null;

        UserHelper.setForceUserRole(forceUserRole);

        const {accordion} = this.state;
        return (
            <>
                {((!this.props.openAccordion && !Object.values(accordion).includes(true)) || (this.props.openAccordion)) &&
                    <ScrollTop scrollInit={true} conAnimazione={false}/>}
                <div id={"compilaRichiestaId"}>
                    <RoleBasedContext.Provider
                        value={{
                            forceReadOnly: this.props.readOnly,
                            hideUnsetValues: this.props.hideUnsetValues,
                            scrollToHead: this.props.openAccordion,
                            forceUserRole: forceUserRole,
                            requestVersion: this.props.richiesta?.versione,
                            showOnlyRequiredFields: true
                        }}>
                        <div className="mx-5">
                            {this.renderForm()}
                        </div>
                    </RoleBasedContext.Provider>
                </div>
                {!this.props.readOnly && this.renderButtons()}
            </>
        );
    }
}

CompilaRichiestaCampiObbligatori.propTypes = propTypes;

CompilaRichiestaCampiObbligatori.defaultProps = {
    readOnly: false,
    hideUnsetValues: false,
    openAccordion: false,
    hideDefaultHeader: false
}
