import React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import Utils from "../../../utils/Utils";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import ToponomasticaUtils from "../../../utils/ToponomasticaUtils";
import {
    FieldsRow,
    RoleBasedAutocomplete,
    RoleBasedInput,
    RoleBasedPhoneInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import {v4 as uuid} from "uuid";
import {isMadrelingua} from "../../anagrafePaziente/datiGenerali/DatiGenerali";

const propTypes = {
    assistentiNonFamiliariForm: PropTypes.any,
    onChangeAssistenteNonFamiliare: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onValidaSezione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

const defaultAssistenteNonFamiliare = {
    id: null,
    supportoSociale: null,
    cognome: null,
    nome: null,
    indirizzo: null,
    numero: null,
    comune: "",
    provincia: "",
    telefono: null,
    cellulare: null,
    email: null,
    isItalianoMadrelinguaAssistenteFamiliare: null,
    livello: null,
    descrizione: [],
    tipoRelazioneConAssistito: null,
    tipoOccupazione: [],
    numeroGiorniSettimanaAttuale: null,
    numeroGiorniSettimanaFuturo: null
}

const defaultDescrizione = {
    id: null,
    codice: null
}

export const defaultTipoOccupazione = {
    id: null,
    codice: null
}

const parentJsonpath = 'situazioneSociale';

export default class SupportoSocialeAssistenteNonFamiliare extends AccordionSecondLevel {
    state = {
        assistentiNonFamiliari: _.cloneDeep(this.props.assistentiNonFamiliariForm)
    }

    campiSelect = [
        {isItalianoMadrelinguaAssistenteFamiliare: "rispostaBreve"},
        {supportoSociale: "assistenteNonFamiliare"},
        {livello: "linguaItalianaParlataCompresa"},
        {tipoRelazioneConAssistito: "tipoRelazioneConAssistito"},
        {numeroGiorniSettimanaAttuale: "giorniSettimanaAttuale"},
        {numeroGiorniSettimanaFuturo: "giorniSettimanaFutura"}
    ];

    listaComuni = [];
    listaProvince = [];

    componentDidMount() {
        this.listaComuni = ToponomasticaUtils.getComuniValidi(ToponomasticaUtils.getComuni());
        this.listaProvince = ToponomasticaUtils.getProvinceValide(ToponomasticaUtils.getProvince());
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.assistentiNonFamiliariForm, this.props.assistentiNonFamiliariForm)) {
            this.inizializzaForm();
        }
    }

    inizializzaForm = () => {
        let assistentiNonFamiliari = _.cloneDeep(this.props.assistentiNonFamiliariForm);

        if (assistentiNonFamiliari.length > 0) {
            assistentiNonFamiliari = assistentiNonFamiliari.map(assistente => {
                let assistenteMap = assistente;
                this.inizializzaDatiComuniProvince(assistenteMap);
                if (assistente.descrizione == null) {
                    assistenteMap.descrizione = [];
                } else {
                    assistenteMap.descrizione = assistente.descrizione.map(linguaParlata => {
                        linguaParlata.codice = oggettiUtili.elencoLingue.find(el => el.value === (linguaParlata.codice?.value || linguaParlata.codice));
                        return linguaParlata;
                    });
                }
                if (assistente.tipoOccupazione == null)
                    assistenteMap.tipoOccupazione = [];

                Utils.valueToObject(assistenteMap, this.campiSelect);
                return assistenteMap;
            });
        } else {
            assistentiNonFamiliari.push(defaultAssistenteNonFamiliare);
        }

        this.setState({assistentiNonFamiliari: assistentiNonFamiliari}, () => {
            this.controllaCampiObbligatoriMancantiAndValida();
        });
    }

    inizializzaDatiComuniProvince = (assistenteNonFamiliare) => {
        let comune = this.listaComuni.find(c => c.codice === assistenteNonFamiliare.comune);
        assistenteNonFamiliare.comune = comune ? this.getOggettoComune(comune) : comune;

        if (assistenteNonFamiliare.provincia) {
            let provincia = this.listaProvince.find(p => p.codice === assistenteNonFamiliare.comune?.codiceProvincia);
            assistenteNonFamiliare.provincia = provincia ? this.getOggettoProvincia(provincia) : provincia;
        }
    }

    getOggettoComune = (comune) => {
        return {
            codice: comune.codice,
            codiceProvincia: comune.codiceProvincia,
            descrizione: comune.descrizione
        };
    }

    getOggettoProvincia = (provincia) => {
        return {
            codice: provincia.codice,
            codiceRegione: provincia.codiceRegione,
            descrizione: provincia.descrizione
        };
    }

    getDistinctList = (list) => {
        return list
            .filter((v, i, a) => a.findIndex(t => (_.isEqual(t.codice, v.codice))) === i)
            ?.map(elem => {
                elem.codice = (!Utils.isObjectNull(elem.codice) && Object.prototype.hasOwnProperty.call(elem.codice,"value")) ? elem.codice.value : elem.codice;
                return elem;
            })
            .filter(el => !Utils.isObjectNull(el.codice));
    }

    formatObjectForBackend = () => {
        let assistentiNonFamiliari = _.cloneDeep(this.state.assistentiNonFamiliari);
        // formatto l'oggetto per inviarlo come parametro di input al metodo salvaBozza nel componente padre "CompilaRichiesta"
        assistentiNonFamiliari = assistentiNonFamiliari.map(assistente => {
            let assistenteMap = assistente;
            if (assistente.descrizione.length > 0) {
                assistenteMap.descrizione = this.getDistinctList(assistente.descrizione);
            }
            if (assistente.tipoOccupazione.length > 0) {
                assistenteMap.tipoOccupazione = this.getDistinctList(assistente.tipoOccupazione);
            }
            Utils.objectToValue(assistenteMap, this.campiSelect);
            this.getCodice(assistente);
            return _.mapValues(assistenteMap, el => el === null || el === undefined || el === "" ? null : el);
        });
        this.props.onChangeAssistenteNonFamiliare("assistenteNonFamiliare", assistentiNonFamiliari);
    }

    getCodice = (assistente) => {
        assistente.comune = !Utils.isObjectNull(assistente.comune) ? assistente.comune.codice : "";
        assistente.provincia = !Utils.isObjectNull(assistente.provincia) ? assistente.provincia.codice : "";
    }

    controllaCampiObbligatoriMancantiAndValida = () => {
        let assistentiNonFamiliari = _.cloneDeep(this.state.assistentiNonFamiliari);
        let numeroCampiObbligatoriMancanti = 0;
        let isSezioneValida = false;

        // controllo la validità dei vari campi di ogni elemento della lista assistentiNonFamiliari e se ci sono campi obbligatori non valorizzati li conto
        if (assistentiNonFamiliari.length > 0) {
            numeroCampiObbligatoriMancanti = assistentiNonFamiliari.filter(assistente => Utils.isObjectNull(assistente.supportoSociale)).length;
            assistentiNonFamiliari.forEach(assistente => {
                let isTelefonoValido = Utils.isStringEmptyOrNullOrUndefined(assistente.telefono) || Utils.isValidTelefono(assistente.telefono);
                let isEmailValida = Utils.isStringEmptyOrNullOrUndefined(assistente.email) || Utils.isValidEmail(assistente.email);
                isSezioneValida = numeroCampiObbligatoriMancanti === 0 && isTelefonoValido && isEmailValida;
            });
        } else {
            isSezioneValida = true;
        }

        this.props.onChangeRequiredFieldsNumber("sottoSezioneAssistenteNonFamiliare", numeroCampiObbligatoriMancanti);
        this.props.onValidaSezione("isAssistenteNonFamiliareValido", isSezioneValida);
    }

    handleAggiornaForm = (field, elem, index) => {
        let assistentiNonFamiliari = _.cloneDeep(this.state.assistentiNonFamiliari);
        let assistenteNonFamiliare = (field === "supportoSociale" && elem?.value === "07") ? _.cloneDeep(defaultAssistenteNonFamiliare) : assistentiNonFamiliari[index];

        if (field === "comune" || field === "provincia") {
            let isOpzioneAutocompleteValida = !Utils.isObjectNull(elem.target.value) && !Utils.isObjectNull(elem.target.value["codice"]);
            if (isOpzioneAutocompleteValida) {
                elem = elem.target.value;
                if (field === "comune") {
                    let provincia = this.listaProvince.find(p => p.codice === elem.codice.substring(0, 3));
                    assistenteNonFamiliare.provincia = this.getOggettoProvincia(provincia);
                }
            } else {
                elem = null;
            }
        } else if (field === "isItalianoMadrelinguaAssistenteFamiliare" && !_.isEqual(elem, this.state.assistentiNonFamiliari[index][field])) {
            assistenteNonFamiliare.livello = null;
            assistenteNonFamiliare.descrizione = [];
        } else if (field === "supportoSociale") {
            assistenteNonFamiliare = {
                ..._.cloneDeep(defaultAssistenteNonFamiliare),
                id: assistenteNonFamiliare.id
            };
        }

        if (assistenteNonFamiliare.id === null) assistenteNonFamiliare.id = uuid();

        assistenteNonFamiliare[field] = elem;
        assistentiNonFamiliari[index] = assistenteNonFamiliare;
        this.setState({assistentiNonFamiliari: assistentiNonFamiliari}, () => {
            this.formatObjectForBackend();
            this.controllaCampiObbligatoriMancantiAndValida();
        });
    }

    addNewAssistenteNonFamiliare = () => {
        let assistentiNonFamiliari = _.cloneDeep(this.state.assistentiNonFamiliari);
        let assistenteNonFamiliare = _.cloneDeep(defaultAssistenteNonFamiliare);
        assistenteNonFamiliare.id = uuid();
        assistentiNonFamiliari.push(assistenteNonFamiliare);
        this.setState({assistentiNonFamiliari: assistentiNonFamiliari}, () => {
            this.formatObjectForBackend();
            this.controllaCampiObbligatoriMancantiAndValida();
        });
    }

    removeAssistenteNonFamiliare = (index) => {
        let assistentiNonFamiliari = _.cloneDeep(this.state.assistentiNonFamiliari);
        assistentiNonFamiliari.splice(index, 1);
        this.setState({assistentiNonFamiliari: assistentiNonFamiliari}, () => {
            this.formatObjectForBackend();
            this.controllaCampiObbligatoriMancantiAndValida();
        });
    }

    renderLinguaParlata = (linguaParlata, indexListaLinguaParlata, indexListaAssistenteNonFamiliare) => {
        return (
            <RoleBasedSelect
                fieldId={'LING_PARL_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                pageState={this.props.pageState}
                parentClass={"mb-0"}
                id={"selectLinguaParlata"}
                className={"selectLinguaParlata"}
                options={oggettiUtili.elencoLingue}
                value={linguaParlata.codice}
                onChange={(elem) => this.handleAggiornaLinguaParlata(elem, indexListaLinguaParlata, indexListaAssistenteNonFamiliare)}
                isSearchable={false}
                fieldLabel={"Lingua parlata"}
                field={"codice"}
                parentJsonPath={parentJsonpath}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                disableDoubleInput={this.props.disableDoubleInput}
            />
        );
    }

    handleAggiornaLinguaParlata = (elem, indexListaLinguaParlata, indexListaAssistenteNonFamiliare) => {
        let assistentiNonFamiliari = _.cloneDeep(this.state.assistentiNonFamiliari);
        let currentAssistenteNonFamiliare = assistentiNonFamiliari[indexListaAssistenteNonFamiliare];
        currentAssistenteNonFamiliare.descrizione[indexListaLinguaParlata].codice = elem;
        assistentiNonFamiliari[indexListaAssistenteNonFamiliare] = currentAssistenteNonFamiliare;

        this.setState({assistentiNonFamiliari}, () => this.formatObjectForBackend());
    }

    addNewLinguaParlata = (indexCurrentAssistenteNonFamiliare) => {
        let assistentiNonFamiliari = _.cloneDeep(this.state.assistentiNonFamiliari);
        let currentAssistenteNonFamiliare = assistentiNonFamiliari[indexCurrentAssistenteNonFamiliare];
        let defaultDescrizioneClone = _.cloneDeep(defaultDescrizione);
        defaultDescrizioneClone.id = uuid();
        currentAssistenteNonFamiliare.descrizione.push(defaultDescrizioneClone);
        assistentiNonFamiliari[indexCurrentAssistenteNonFamiliare] = currentAssistenteNonFamiliare;

        this.setState({assistentiNonFamiliari}, () => this.formatObjectForBackend());
    }

    removeLinguaParlata = (indexListaLinguaParlata, indexCurrentAssistenteNonFamiliare) => {
        let assistentiNonFamiliari = _.cloneDeep(this.state.assistentiNonFamiliari);
        let currentAssistenteNonFamiliare = assistentiNonFamiliari[indexCurrentAssistenteNonFamiliare];
        currentAssistenteNonFamiliare.descrizione.splice(indexListaLinguaParlata, 1);
        assistentiNonFamiliari[indexCurrentAssistenteNonFamiliare] = currentAssistenteNonFamiliare;

        this.setState({assistentiNonFamiliari}, () => this.formatObjectForBackend());
    }

    renderTipoOccupazione = (tipoOccupazione, indexListaOccupazione, indexListaAssistentiNonFamiliari) => {
        return <RoleBasedSelect
            fieldId={'OCCUP_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
            pageState={this.props.pageState}
            id={"selectTipoOccupazione"}
            className={"selectTipoOccupazione"}
            options={oggettiUtili.occupazioneAssistenteNonFamiliare}
            value={tipoOccupazione.codice}
            onChange={(elem) => this.handleAggiornaTipoOccupazione(elem, indexListaOccupazione, indexListaAssistentiNonFamiliari)}
            isSearchable={false}
            fieldLabel={'Si occupa di'}
            handleOnlyValue={true}
            field={"codice"}
            parentJsonPath={parentJsonpath}
            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            disableDoubleInput={this.props.disableDoubleInput}
        />;
    }

    handleAggiornaTipoOccupazione = (elem, indexListaTipoOccupazione, indexListaAssistentiNonFamiliari) => {
        let assistentiNonFamiliari = _.cloneDeep(this.state.assistentiNonFamiliari);
        let currentAssistenteNonFamiliare = assistentiNonFamiliari[indexListaAssistentiNonFamiliari];
        currentAssistenteNonFamiliare.tipoOccupazione[indexListaTipoOccupazione].codice = elem;
        assistentiNonFamiliari[indexListaAssistentiNonFamiliari] = currentAssistenteNonFamiliare;

        this.setState({assistentiNonFamiliari}, () => this.formatObjectForBackend());
    }

    addNewTipoOccupazione = (indexCurrentAssistenteNonFamiliare) => {
        let assistentiNonFamiliari = _.cloneDeep(this.state.assistentiNonFamiliari);
        let currentAssistenteNonFamiliare = assistentiNonFamiliari[indexCurrentAssistenteNonFamiliare];
        let defaultTipoOccupazioneClone = _.cloneDeep(defaultTipoOccupazione);
        defaultTipoOccupazioneClone.id = uuid();
        currentAssistenteNonFamiliare.tipoOccupazione.push(defaultTipoOccupazioneClone);
        assistentiNonFamiliari[indexCurrentAssistenteNonFamiliare] = currentAssistenteNonFamiliare;

        this.setState({assistentiNonFamiliari}, () => this.formatObjectForBackend());
    }

    removeTipoOccupazione = (indexListaTipoOccupazione, indexCurrentAssistenteNonFamiliare) => {
        let assistentiNonFamiliari = _.cloneDeep(this.state.assistentiNonFamiliari);
        let currentAssistenteNonFamiliare = assistentiNonFamiliari[indexCurrentAssistenteNonFamiliare];
        currentAssistenteNonFamiliare.tipoOccupazione.splice(indexListaTipoOccupazione, 1);
        assistentiNonFamiliari[indexCurrentAssistenteNonFamiliare] = currentAssistenteNonFamiliare;

        this.setState({assistentiNonFamiliari}, () => this.formatObjectForBackend());
    }

    renderNewAssistenteNonFamiliare = (assistenteNonFamiliare, index) => {
        let comuniSuggestions = !Utils.isObjectNull(assistenteNonFamiliare.provincia?.codice)
            ? this.listaComuni.filter(comune => comune.codiceProvincia === assistenteNonFamiliare.provincia.codice)
            : this.listaComuni;
        comuniSuggestions = comuniSuggestions.map(comune => this.getOggettoComune(comune));

        let provinceSuggestions = !Utils.isObjectNull(assistenteNonFamiliare.comune?.codice)
            ? this.listaProvince.filter(provincia => provincia.codice === assistenteNonFamiliare.comune.codice.substring(0, 3))
            : this.listaProvince;
        provinceSuggestions = provinceSuggestions.map(provincia => this.getOggettoProvincia(provincia));

        return (
            <div key={index}>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        id={"selectTipoSupportoSociale"}
                        className={"selectTipoSupportoSociale"}
                        options={oggettiUtili.assistenteNonFamiliare}
                        value={assistenteNonFamiliare.supportoSociale}
                        onChange={(elem) => this.handleAggiornaForm("supportoSociale", elem, index)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Tipo supporto sociale non esistente"}
                        fieldRequired={true}
                        fieldLabel={'Assistente non familiare'}
                        field={"supportoSociale"}
                        parentJsonPath={parentJsonpath}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
                {!Utils.isObjectNull(assistenteNonFamiliare.supportoSociale) && assistenteNonFamiliare.supportoSociale.value !== "07" &&
                    <>
                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'COGN_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                placeholder="Inserisci cognome"
                                field="cognome"
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                                id="inputCognomeAssistenteNonFamiliare"
                                value={assistenteNonFamiliare.cognome}
                                fieldLabel={'Cognome'}
                                parentJsonPath={parentJsonpath}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />

                            <RoleBasedInput
                                fieldId={'NOME_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                placeholder="Inserisci nome"
                                field="nome"
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                                id="inputNomeAssistenteNonFamiliare"
                                fieldLabel={'Nome'}
                                value={assistenteNonFamiliare.nome}
                                parentJsonPath={parentJsonpath}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                        </FieldsRow>

                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'INDIR_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                placeholder="Inserisci indirizzo"
                                field="indirizzo"
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                                id="inputIndirizzoAssistenteNonFamiliare"
                                fieldLabel={'Indirizzo'}
                                value={assistenteNonFamiliare.indirizzo}
                                parentJsonPath={parentJsonpath}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />

                            <RoleBasedInput
                                fieldId={"NUMEROCIVICO_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE"}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci numero civico"}
                                field={"numero"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                                id={"inputNumeroCivico"}
                                value={assistenteNonFamiliare.numero}
                                fieldLabel={'N°'}
                                parentJsonPath={parentJsonpath}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />

                            <RoleBasedAutocomplete
                                fieldId={'COM_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                id={"autocompleteComuneAssistenteNonFamiliare"}
                                keyField={"codice"}
                                descriptionField={"descrizione"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem, index)}
                                value={assistenteNonFamiliare.comune ?? ""}
                                field={"comune"}
                                placeholder={"Inserisci comune"}
                                suggestions={comuniSuggestions}
                                noSuggestions={"Errore: Comune non esistente"}
                                fieldLabel={'Comune'}
                                parentJsonPath={parentJsonpath}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />

                            <RoleBasedAutocomplete
                                fieldId={'PROV_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                id={"autocompleteProvinciaAssistenteNonFamiliare"}
                                keyField={"codice"}
                                descriptionField={"descrizione"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem, index)}
                                value={assistenteNonFamiliare.provincia ?? ""}
                                field={"provincia"}
                                placeholder={"Inserisci provincia"}
                                fieldLabel={'Provincia'}
                                suggestions={provinceSuggestions}
                                noSuggestions={"Errore: Provincia non relativa al comune inserito"}
                                parentJsonPath={parentJsonpath}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                        </FieldsRow>

                        <FieldsRow>
                            <RoleBasedPhoneInput
                                fieldId={'TEL_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci numero di telefono"}
                                field={"telefono"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                                id={"inputNumeroTelefonoAssistenteNonFamiliare"}
                                value={assistenteNonFamiliare.telefono ? assistenteNonFamiliare.telefono : ""}
                                phonePrefix={null}
                                fieldLabel={"Telefono"}
                                parentJsonPath={parentJsonpath}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />

                            <RoleBasedPhoneInput
                                fieldId={'CELL_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci numero di cellulare"}
                                field={"cellulare"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                                id={"inputCellulareAssistenteNonFamiliare"}
                                value={assistenteNonFamiliare.cellulare ? assistenteNonFamiliare.cellulare : ""}
                                fieldLabel={'Cellulare'}
                                hideUnsetValues={Utils.isStringEmptyOrNullOrUndefined(assistenteNonFamiliare.cellulare)}
                                parentJsonPath={parentJsonpath}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />

                            <RoleBasedInput
                                fieldLabel={"E-mail"}
                                fieldId={'EMAIL_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                placeholder={"Inserisci e-mail"}
                                field={"email"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                                id={"inputEmailAssistenteNonFamiliare"}
                                value={assistenteNonFamiliare.email ? assistenteNonFamiliare.email : ""}
                                validator={Utils.isValidEmail}
                                invalidText={"Inserire un'email valida"}
                                parentJsonPath={parentJsonpath}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                            <RoleBasedSelect
                                fieldId={'ITA_MADREL_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                id={"selectMadrelingua"}
                                className={"selectMadrelingua"}
                                options={oggettiUtili.rispostaBreve}
                                value={assistenteNonFamiliare.isItalianoMadrelinguaAssistenteFamiliare}
                                onChange={(elem) => this.handleAggiornaForm("isItalianoMadrelinguaAssistenteFamiliare", elem, index)}
                                isSearchable={false}
                                fieldLabel={'Italiano madrelingua?'}
                                field={"isItalianoMadrelinguaAssistenteFamiliare"}
                                parentJsonPath={parentJsonpath}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                        </FieldsRow>

                        {!isMadrelingua(assistenteNonFamiliare.isItalianoMadrelinguaAssistenteFamiliare) &&
                            <FieldsRow containerClass={"align-self-center"}
                                       childClass={"align-self-start"}>
                                <RoleBasedSelect
                                    fieldId={'LING_ITA_PARL_COMPR_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                    pageState={this.props.pageState}
                                    id={"selectLinguaItalianaParlataCompresa"}
                                    className={"selectLinguaItalianaParlataCompresa"}
                                    options={oggettiUtili.linguaItalianaParlataCompresa}
                                    value={assistenteNonFamiliare.livello}
                                    onChange={(elem) => this.handleAggiornaForm("livello", elem, index)}
                                    isSearchable={false}
                                    fieldLabel={'Lingua italiana parlata e compresa'}
                                    field={"livello"}
                                    parentJsonPath={parentJsonpath}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                />
                                <SectionList
                                    title={'Lingua parlata'}
                                    data={assistenteNonFamiliare.descrizione}
                                    renderSection={(e, i) => this.renderLinguaParlata(e, i, index)}
                                    addNewSectionCallback={() => this.addNewLinguaParlata(index)}
                                    removeSectionCallback={(i) => this.removeLinguaParlata(i, index)}
                                    addButtonVisibilityHandler={!isMadrelingua(assistenteNonFamiliare.isItalianoMadrelinguaAssistenteFamiliare)
                                        && (assistenteNonFamiliare.descrizione?.length === 0 || assistenteNonFamiliare.descrizione?.every(el => !Utils.isObjectNull(el?.codice)))}
                                    pageState={this.props.pageState}
                                    keyFieldId={'LING_PARL_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                    indentOffset={0}
                                    field={'descrizione'}
                                    parentJsonPath={parentJsonpath + ".assistenteNonFamiliare[?(@.id == '" + assistenteNonFamiliare?.id + "')].descrizione"}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    disableDoubleInput={this.props.disableDoubleInput}
                                />
                            </FieldsRow>}

                        <FieldsRow containerClass={"align-self-center"}
                                   childClass={"align-self-start"}>
                            <RoleBasedSelect
                                fieldId={'TIPO_REL_ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                id={"selectTipoRelazioneConAssistito"}
                                className={"selectTipoRelazioneConAssistito"}
                                options={oggettiUtili.tipoRelazioneConAssistito}
                                value={assistenteNonFamiliare.tipoRelazioneConAssistito}
                                onChange={(elem) => this.handleAggiornaForm("tipoRelazioneConAssistito", elem, index)}
                                isSearchable={false}
                                fieldLabel={'Tipo di relazione con assistito'}
                                field={"tipoRelazioneConAssistito"}
                                parentJsonPath={parentJsonpath}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                            <SectionList
                                title={'Si occupa di'}
                                data={assistenteNonFamiliare.tipoOccupazione}
                                renderSection={(e, i) => this.renderTipoOccupazione(e, i, index)}
                                addNewSectionCallback={() => this.addNewTipoOccupazione(index)}
                                removeSectionCallback={(i) => this.removeTipoOccupazione(i, index)}
                                pageState={this.props.pageState}
                                keyFieldId={'TIPO_CONTRIBUTI.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                indentOffset={0}
                                field={'tipoOccupazione'}
                                addButtonVisibilityHandler={assistenteNonFamiliare.tipoOccupazione?.length === 0
                                    || assistenteNonFamiliare.tipoOccupazione?.every(el => !Utils.isObjectNull(el?.codice))}
                                parentJsonPath={parentJsonpath + ".assistenteNonFamiliare[?(@.id == '" + assistenteNonFamiliare?.id + "')].tipoOccupazione"}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                        </FieldsRow>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'N_GG_SETT_ATT.ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                id={"selectGiorniSettimanaAttuale"}
                                className={"selectGiorniSettimanaAttuale"}
                                options={oggettiUtili.giorniSettimanaAttuale}
                                value={assistenteNonFamiliare.numeroGiorniSettimanaAttuale}
                                onChange={(elem) => this.handleAggiornaForm("numeroGiorniSettimanaAttuale", elem, index)}
                                isSearchable={false}
                                fieldLabel={'N°gg/settimana attuale'}
                                field={"numeroGiorniSettimanaAttuale"}
                                parentJsonPath={parentJsonpath}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                            <RoleBasedSelect
                                fieldId={'N_GG_SETT_FUT.ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
                                pageState={this.props.pageState}
                                id={"selectGiorniSettimanaFutura"}
                                className={"selectGiorniSettimanaFutura"}
                                options={oggettiUtili.giorniSettimanaFutura}
                                value={assistenteNonFamiliare.numeroGiorniSettimanaFuturo}
                                onChange={(elem) => this.handleAggiornaForm("numeroGiorniSettimanaFuturo", elem, index)}
                                isSearchable={false}
                                fieldLabel={'N°gg/settimana futuro'}
                                field={"numeroGiorniSettimanaFuturo"}
                                parentJsonPath={parentJsonpath}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                disableDoubleInput={this.props.disableDoubleInput}
                            />
                        </FieldsRow>
                    </>}
            </div>
        );
    }

    isLinkAggiungiEnabled = (list) => {
        return list.every(el => !Utils.isObjectNull(el.supportoSociale));
    }

    renderBodyAssistenteNonFamiliare = () => {
        let assistenteNonFamiliare = _.cloneDeep(this.state.assistentiNonFamiliari);

        return <SectionList
            addNewSectionCallback={this.addNewAssistenteNonFamiliare}
            removeSectionCallback={this.removeAssistenteNonFamiliare}
            renderSection={this.renderNewAssistenteNonFamiliare}
            data={assistenteNonFamiliare}
            title={'Assistente non familiare'}
            addButtonVisibilityHandler={this.isLinkAggiungiEnabled(assistenteNonFamiliare)}
            field={'assistenteNonFamiliare'}
            keyFieldId={'ASS_NON_FAM.SUPPORTOSOCIALE.SITUAZIONESOCIALE'}
            pageState={this.props.pageState}
            parentJsonPath={parentJsonpath + ".assistenteNonFamiliare"}
            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            disableDoubleInput={this.props.disableDoubleInput}
        />;
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"assistenteNonFamiliare"}
                title={"Supporto sociale: Assistente non familiare"}
                required={true}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    [parentJsonpath + ".assistenteNonFamiliare", parentJsonpath + ".assistenteNonFamiliare.descrizione", parentJsonpath + ".assistenteNonFamiliare.tipoOccupazione"],
                    [defaultAssistenteNonFamiliare, defaultDescrizione, defaultTipoOccupazione]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodyAssistenteNonFamiliare()}</AccordionSezioneRichiesta>
        );
    }
}

SupportoSocialeAssistenteNonFamiliare.propTypes = propTypes;
