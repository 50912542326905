import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {FieldsRow, RoleBasedRadio} from "../../roleBasedComponents/RoleBasedComponents";

const propTypes = {
    datiInvaliditaCivileForm: PropTypes.any,
    datiEsenzioniForm: PropTypes.any,
    onChangeDatiAssistito: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class InvaliditaCivile extends AccordionSecondLevel {

    state = {
        datiInvaliditaCivileForm: _.cloneDeep(this.props.datiInvaliditaCivileForm)
    }

    renderRadioButtonChoice = () => {
        return <>
            <FieldsRow>
                <RoleBasedRadio id={"radioButtonPresenza"}
                                fieldId={'INVAL_CIV.INVALIDITACIVILE.ANAGRAFEPAZIENTE'}
                                pageState={this.props.pageState}
                                group={"presenzaInvaliditaRiconosciuta"}
                                items={oggettiUtili.rispostaBreve}
                                onChange={(field, value) => {
                                    this.setState({
                                        datiInvaliditaCivileForm: {
                                            ...this.state.datiInvaliditaCivileForm,
                                            [field]: value
                                        }
                                    }, () => this.props.onChangeDatiAssistito("invalidita", this.state.datiInvaliditaCivileForm));
                                }}
                                customStyles={{justifyContent: "flex-start"}}
                                customItemStyles={{marginRight: "20px"}}
                                fieldLabel={'Presenza Invalidità riconosciuta'}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </FieldsRow>

            {(this.state.datiInvaliditaCivileForm.presenzaInvaliditaRiconosciuta || this.props.datiEsenzioniForm.map(e => e.tipologiaEsenzione).includes("per invalidità")) &&
            <FieldsRow>
                <RoleBasedRadio id={"radioButtonDomanda"}
                                fieldId={'PRES_DOM.INVALIDITACIVILE.ANAGRAFEPAZIENTE'}
                                pageState={this.props.pageState}
                                group={"presentataDomanda"}
                                items={oggettiUtili.rispostaBreve}
                                onChange={(field, value) => {
                                    this.setState({
                                        datiInvaliditaCivileForm: {
                                            ...this.state.datiInvaliditaCivileForm,
                                            [field]: value
                                        }
                                    }, () => this.props.onChangeDatiAssistito("invalidita", this.state.datiInvaliditaCivileForm));
                                }}
                                customStyles={{justifyContent: "flex-start"}}
                                customItemStyles={{marginRight: "20px"}}
                                disabled={!this.state.datiInvaliditaCivileForm.presenzaInvaliditaRiconosciuta}
                                fieldLabel={'Presentata domanda'}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </FieldsRow>}
        </>;
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"invalidita"}
                title={"Invalidità civile"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderRadioButtonChoice()}</AccordionSezioneRichiesta>
        );
    }
}

InvaliditaCivile.propTypes = propTypes;

