import {SessioneUtente} from "web-client-archetype";
import enumsUtente from "../enum/enumsUtente.json";
import Utils from "../utils/Utils";
import ruoli from "../utils/ruoli.json";

export default class AuthUtils {
    static hasUtenteRuoloAmministratoreSistema = () => {
        return SessioneUtente.getInstance()?.ruoliUtente?.includes(enumsUtente.tipoUtente.admin);
    }

    static hasUtenteRuoloOperatorePalliativo = () => {
        return SessioneUtente.getInstance()?.settings?.some(setting => enumsUtente.ruoli.operatorePalliativo === setting.ruolo);
    }
    static hasUtenteRuoloOperatore = () => {
        return SessioneUtente.getInstance()?.settings?.some(setting => enumsUtente.ruoli.operatore === setting.ruolo);
    }

    static hasUtenteRuoloReferenteAulss = () => {
        return SessioneUtente.getInstance()?.settings?.some(setting => enumsUtente.profiliUtente.referenteAulss === setting.profilo);
    }

    static hasUtenteCentraleAdi = () => {
        return SessioneUtente.getInstance()?.settings?.some(setting => enumsUtente.profiliUtente.centraleAdi === setting.profilo);
    }

    static hasUtenteOnlyRuoloReferenteAulss = () => {
        let ruoloUtenteAutenticato = SessioneUtente.getInstance()?.ruoliUtente;
        return !Utils.isStringEmptyOrNullOrUndefined(ruoloUtenteAutenticato)
            && ruoloUtenteAutenticato.split(",").length === 1
            && ruoloUtenteAutenticato === enumsUtente.tipoUtente.referenteAulss;
    }

    static hasUtenteRuoloDiCategoriaAmministrativa = () => {
        return AuthUtils.hasUtenteRuoloAmministratoreSistema() || AuthUtils.hasUtenteOnlyRuoloReferenteAulss();
    }

    static hasUtenteRuoloMedicoRichiedente = () => {
        return SessioneUtente.getInstance()?.settings?.some(setting => enumsUtente.profiliUtente.medicoRichiedente === setting.profilo);
    }

    static hasUtenteRuoloFormalizzatore = () => {
        return SessioneUtente.getInstance()?.settings?.some(setting => enumsUtente.profiliUtente.medicoFormalizzatore === setting.profilo);
    }

    static hasUtenteRuoloProfessionistaValutatore = () => {
        return SessioneUtente.getInstance()?.settings?.some(setting => enumsUtente.profiliUtente.professionistaValutatore === setting.profilo);
    }

    static hasUtenteRuoloSpecialistaValutatore = () => {
        return SessioneUtente.getInstance()?.settings?.some(setting => enumsUtente.profiliUtente.specialista === setting.profilo);
    }

    static hasUtenteRuoloCoordinatore = () => {
        return SessioneUtente.getInstance()?.settings?.some(setting => enumsUtente.profiliUtente.coordinatore === setting.profilo);
    }

    static hasUtenteRuoloDirettoreDiDistretto = () => {
        return SessioneUtente.getInstance().settings?.some(setting => setting.ruolo === enumsUtente.ruoli.direttore);
    }

    static hasUtenteRuoloMcp = () => {
        return SessioneUtente.getInstance().settings?.some(setting => setting.ruolo === enumsUtente.ruoli.mcp);
    }
    static hasUtenteRuoloMpa = () => {
        return SessioneUtente.getInstance().settings?.some(setting => setting.ruolo === enumsUtente.ruoli.mpa);
    }

    static hasUtenteRuoloAssistenteSociale = () => {
        return SessioneUtente.getInstance().settings?.some(setting => setting.ruolo === enumsUtente.ruoli.assistenteSociale);
    }

    static getInfoUtente = () => {
        if (SessioneUtente.getInstance()) {
            let nomeUtente = !Utils.isStringEmptyOrNullOrUndefined(SessioneUtente.getInstance().nomeUtente) ? SessioneUtente.getInstance().nomeUtente : '';
            let cognomeUtente = !Utils.isStringEmptyOrNullOrUndefined(SessioneUtente.getInstance().cognomeUtente) ? SessioneUtente.getInstance().cognomeUtente : '';
            let ruoloUtente = SessioneUtente.getInstance().ruoliUtente?.length > 0
                ? SessioneUtente.getInstance().ruoliUtente.split(",").map(ruolo => enumsUtente.profilo[ruolo]).join(', ')
                : '';
            return [nomeUtente, cognomeUtente, (ruoloUtente ? '(' + ruoloUtente + ')' : '')].join(' ');
        } else {
            return '';
        }
    }

    static getRoleDescriptionFromCode = (ruoloUtente) => {
        let descriptionRole;
        for (const field of Object.keys(ruoli)) {
            descriptionRole = ruoli[field].find(el => el.value === ruoloUtente)?.label;
            if (descriptionRole) break;
        }

        return descriptionRole ?? "";
    }

    static getProfileDescriptionFromCode = (profilo) => {
        return enumsUtente.profilo[profilo] ?? "";
    }

    static hasUtenteProfiloCoordinatore = () => {
        return SessioneUtente.getInstance()?.settings?.some(setting => enumsUtente.profiliUtente.coordinatore === setting.profilo);
    }

    static hasUtenteProfiloValutatore = () => {
        return SessioneUtente.getInstance()?.settings?.some(setting => enumsUtente.profiliUtente.professionistaValutatore === setting.profilo);
    }

    static hasUtenteProfiloDirettoreUO = () => {
        return SessioneUtente.getInstance().settings?.some(setting => enumsUtente.profiliUtente.direttoreUnitaOperativa === setting.profilo);
    }
    static hasUtenteCOT = () => {
        return SessioneUtente.getInstance().settings?.some(setting => enumsUtente.profiliUtente.cot === setting.profilo);
    }

    static hasUtenteProfiloReferenteAssistentiSociali = () => {
        return SessioneUtente.getInstance().settings?.some(setting => enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali === setting.profilo);
    }

    static hasRuoloMedicoMedicinaGenerale = () => {
        return SessioneUtente.getInstance().settings?.some(setting => setting.ruolo === enumsUtente.ruoli.mmg);
    }

    static hasRuoloMedicoOspedaliero = () => {
        return SessioneUtente.getInstance().settings?.some(setting => setting.ruolo === enumsUtente.ruoli.medicoOspedaliero);
    }

    static hasRuoloMedicoRichiedenteAndMedicoOspedaliero = () => {
        return SessioneUtente.getInstance().settings?.some(setting => setting.profilo === enumsUtente.profiliUtente.medicoRichiedente && setting.ruolo === enumsUtente.ruoli.medicoOspedaliero);
    }

    static hasRuoloMedicoRichiedenteAndMedicoMedicinaGenerale = () => {
        return SessioneUtente.getInstance().settings?.some(setting => setting.profilo === enumsUtente.profiliUtente.medicoRichiedente && setting.ruolo === enumsUtente.ruoli.mmg);
    }

    static hasRuoloMedicoRichiedenteAndPediatraLiberaScelta = () => {
        return SessioneUtente.getInstance().settings?.some(setting => setting.profilo === enumsUtente.profiliUtente.medicoRichiedente && setting.ruolo === enumsUtente.ruoli.pediatra);
    }

    static hasRuoloValutatoreAndMedicoOspedaliero = () => {
        return SessioneUtente.getInstance().settings?.some(setting => setting.profilo === enumsUtente.profiliUtente.professionistaValutatore && setting.ruolo === enumsUtente.ruoli.medicoOspedaliero);
    }

    static isUtenteAbilitato = () => {
        return !Utils.isStringEmptyOrNullOrUndefined(SessioneUtente.getInstance().settings);
    }

    static hasUtenteMcpFormalizzatoreRichiesta = (team) => !!( this.hasUtenteRuoloMcp() && team?.find(el => el?.profilo === enumsUtente.profiliUtente.medicoFormalizzatore)?.professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente);

    static hasUtenteRuoloInfermiere = () => {
        return SessioneUtente.getInstance().settings?.some(setting => setting.ruolo === enumsUtente.ruoli.infermiere);
    }

    static hasUtenteRuoloFisiatra = () => {
        return SessioneUtente.getInstance().settings?.some(setting => setting.ruolo === enumsUtente.ruoli.fisiatra);
    }

    static hasUtenteMcpFormalizzatore = () => {
        return AuthUtils.hasUtenteRuoloMcp() && AuthUtils.hasUtenteRuoloFormalizzatore();
    }

    static hasUtenteValutatoreAssistenteSociale = () => {
        return AuthUtils.hasUtenteRuoloAssistenteSociale();
    }

    static hasUtenteValutatoreFisiatra = () => {
        return AuthUtils.hasUtenteRuoloFisiatra();
    }

    static hasUtenteValutatoreInfermiere = () => {
        return AuthUtils.hasUtenteRuoloInfermiere() && AuthUtils.hasUtenteRuoloInfermiere();
    }

    static hasUtenteCoordinatoreInfermiere = () => {
        return AuthUtils.hasUtenteProfiloCoordinatore() && AuthUtils.hasUtenteRuoloInfermiere();
    }

    static hasUtenteAssistenteSocialeReferenteAssSoc = () => {
        return AuthUtils.hasUtenteProfiloReferenteAssistentiSociali() && AuthUtils.hasUtenteRuoloAssistenteSociale();
    }


    static isCurrentUserEvaluator = (role, richiesta, contextRole, pageState) => {
        //const richiesta = this.props.richiesta;

        const cfUtenteAutenticato = SessioneUtente.getInstance().idUtente;
        let isCurrentEvaluator = false;
        switch (role) {
            case enumsUtente.ruoli.mmg:
                isCurrentEvaluator = (AuthUtils.hasRuoloMedicoMedicinaGenerale() && richiesta?.valutazioni?.some(v => v.valutatore?.codiceFiscale === cfUtenteAutenticato)) ||
                    (contextRole && AuthUtils.hasUtenteMcpFormalizzatore)
                break;
            case enumsUtente.ruoli.infermiere:
                isCurrentEvaluator = AuthUtils.hasUtenteValutatoreInfermiere() && richiesta?.valutazioni?.some(v => v.valutatore?.codiceFiscale === cfUtenteAutenticato);
                break;
            case enumsUtente.ruoli.fisiatra:
                isCurrentEvaluator = AuthUtils.hasUtenteValutatoreFisiatra() && richiesta?.valutazioni?.some(v => v.valutatore?.codiceFiscale === cfUtenteAutenticato);
                break;
            case enumsUtente.ruoli.assistenteSociale:
                isCurrentEvaluator = AuthUtils.hasUtenteValutatoreAssistenteSociale() && richiesta?.valutazioni?.some(v => v.valutatore?.codiceFiscale === cfUtenteAutenticato);
                break;
            case enumsUtente.ruoli.professionista:
                isCurrentEvaluator = AuthUtils.hasUtenteRuoloProfessionistaValutatore() && richiesta?.valutazioni?.some(v => v.valutatore?.codiceFiscale === cfUtenteAutenticato);
                break;
            case enumsUtente.ruoli.specialista:
                isCurrentEvaluator = AuthUtils.hasUtenteRuoloSpecialistaValutatore() && richiesta?.valutazioni?.some(v => v.valutatore?.codiceFiscale === cfUtenteAutenticato);
                break;
            default:
                break;
        }

        return Utils.isStateValutazione(pageState) && isCurrentEvaluator;
    }
    professionista
}
