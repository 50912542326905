import React from "react";
import styles from "./Table.module.css";
import Icon from "../icon/Icon";
import CheckBox from "../checkbox/CheckBox";
import {Link} from "react-router-dom";
import RadioButton from "../radiobutton/RadioButton";
import Modale from "../modale/Modale"
import Button from "../button/Button";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json"
import Utils from "../../../utils/Utils"
import routepath from "../../../utils/route/route-path.json";
import azioniRichiesta from "../../../utils/dataset/richiestaData/azioniRichiesta.json"
import AuthUtils from "../../../utils/AuthUtils";
import PropTypes from "prop-types";
import Dropdown from "../dropdown/Dropdown";
import PopupAvvisoCategoriaAur from "../../home/ricercaAssistiti/popupAvvisoCategoriaAur/PopupAvvisoCategoriaAur";
import _ from "lodash";
import * as BsIcon from "react-bootstrap-icons";

export const exclamationCircle = <BsIcon.ExclamationCircleFill width={15} height={15}/>;

export const checkCircle =  <BsIcon.CheckCircleFill width={15} height={15}/>;

const propTypes = {
    page: PropTypes.number,
    tableLenght: PropTypes.number,
    totalElements: PropTypes.number,
    rows: PropTypes.array,
    totalPages: PropTypes.number,
    cols: PropTypes.array,
    homepage: PropTypes.bool,
    bigTable: PropTypes.bool,
    lengthEditable: PropTypes.bool,
    alignColsToStart: PropTypes.bool,
    changePage: PropTypes.func,
    title: PropTypes.string,
    ricercaAssistito: PropTypes.bool,
    information: PropTypes.array,
    showScroll: PropTypes.bool,
    internalScroll: PropTypes.bool,
    tableHeightForInternalScroll: PropTypes.string,
    classTable: PropTypes.string,
    keyColsAlignStart: PropTypes.array,
}

class Table extends React.Component {
    state = {
        page: this.props.page ? this.props.page : 0,
        tableLenght: this.props.tableLenght,
        totalElements: this.props.totalElements
            ? this.props.totalElements
            : this.props.rows.length,
        totalPages: this.props.totalPages,
        rows: this.props.rows,
        initialRows: this.props.rows,
        cols: this.props.cols,
        homepage: this.props.homepage,
        bigTable: this.props.bigTable,
        lengthEditable: this.props.lengthEditable,
        selected: null,
        selezioneFromRadioButton: null,
        editedSize: 10,
        modaleAvvisoCategoriaAur: {
            apriModaleAvvisoCategoriaAur: false,
            clickAvviaNuovaRichiesta: null,
            link: null,
            text: ""
        }
    };

    constructor(props) {
        super(props);
        this.renderCell = this.renderCell.bind(this);
        this.handleChangess = this.handleChangess.bind(this);
        this.handleRadioClick = this.handleRadioClick.bind(this);
        this.handleButton = this.handleButton.bind(this);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.rows !== this.props.rows) {
            this.setState({ rows: this.props.rows });
        }
        if (prevProps.tableLenght !== this.props.tableLenght) {
            this.setState({ tableLenght: this.props.tableLenght });
        }
        if (prevProps.cols !== this.props.cols) {
            this.setState({ cols: this.props.cols });
        }
    }

    legend = (
        <div className={styles.legendContainer.concat(" fit-width text")}>
            <div
                className={styles.stickerGreen.concat(" d-inlineblock mr-2 ml-3")}
            />
            {" "}
            Ok
            <div
                className={styles.stickerYellow.concat(" d-inline-block mr-2 ml-3")}
            />{" "}
            In attesa
            <div
                className={styles.stickerRed.concat(" d-inline-block mr-2 ml-3")}
            />{" "}
            Revocato
            <div
                className={styles.stickerGrey.concat(" d-inline-block mr-2 ml-3")}
            />{" "}
            Sospeso
        </div>
    );

    render() {
        if (this.props.page + 1 && this.props.page !== this.state.page) {
            this.setState({ page: this.props.page });
        }

        const isScrollableTable = !this.props.ricercaAssistito && this.props.showScroll;

        return (
            <>
                {this.state.modaleAvvisoCategoriaAur.apriModaleAvvisoCategoriaAur ?
                    <PopupAvvisoCategoriaAur
                        link={this.state.modaleAvvisoCategoriaAur.link}
                        text={this.state.modaleAvvisoCategoriaAur.text}
                        clickAvviaNuovaRichiesta={this.state.modaleAvvisoCategoriaAur.clickAvviaNuovaRichiesta}
                        showPopup={this.state.modaleAvvisoCategoriaAur.apriModaleAvvisoCategoriaAur}
                        chiudiPopupAvvisoCategoriaAur={this.getChiudiPopupAvvisoCategoriaAur()}
                    /> :
                    <>
                        <div className={[styles.azDiv, isScrollableTable && styles.scrollCols.concat(" customScrollbarStyle")].join(" ").trim()}
                             style={isScrollableTable && this.props.internalScroll ? {
                                 overflowY: "auto",
                                 display: "block",
                                 height: this.props.tableHeightForInternalScroll
                             } : {}}>
                            {this.createPageLengthSelect()}
                            {this.props.homepage
                                ? <div>
                                    {this.createTable()}
                                    {this.legend}
                                </div>
                                : this.createTable()}
                        </div>
                        <>
                            {this.createPagination()}
                        </>
                        {this.props.information ?
                            <div className={styles.informationLegend}>{this.informationTable()}</div> : null}
                    </>}

            </>
        );
    }

    getChiudiPopupAvvisoCategoriaAur() {
        return () => {
            let modale = _.cloneDeep(this.state.modaleAvvisoCategoriaAur);
            modale.link = null;
            modale.text = "";
            modale.clickAvviaNuovaRichiesta = null;
            modale.apriModaleAvvisoCategoriaAur = false;
            this.setState({ modaleAvvisoCategoriaAur: modale });
        };
    }

    informationTable() {
        let boxPallino = []

        for (let i = 0; i < this.props.information.length; i++) {
            boxPallino.push(
                <td className={styles.azTableCell.concat(" noselect")}>
                    <div className={"row"} style={{ marginLeft: "1rem" }}>
                        {(this.props.information[i] === "02" || this.props.information[i] === "05" || this.props.information[i] === "06") ?
                            <h3>-</h3> :
                            <div
                                className={styles[OggettiUtili.informazioniTabella[this.props.information[i]].cssClass]} />}

                        <div style={{
                            fontSize: "14px",
                            marginLeft: "10px",
                            marginTop: (this.props.information[i] === "02" || this.props.information[i] === "05" || this.props.information[i] === "06") ? "11px" : ""
                        }}>
                            {OggettiUtili.informazioniTabella[this.props.information[i]].Descrizione}
                        </div>
                    </div>
                </td>
            );
        }

        return boxPallino

    }

    createPageLengthSelect() {
        return (
            <>
                {this.state.lengthEditable ? (
                    <div className="dataTables_length bs-select">
                        <span style={{ fontSize: "16px" }}>
                            <label>Mostra&nbsp;</label>
                            <select name="test_length"
                                defaultValue={this.props.tableLenght ? this.props.tableLenght : "10"}
                                onChange={this.changePagination}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>{" "}
                            risultati
                        </span>
                    </div>
                ) : null}
            </>
        );
    }

    changePagination = event => {

        if (this.props.changePagination) {
            this.props.changePagination(
                this.getActualSort() ? this.getActualSort().col : null,
                this.getActualSort() ? this.getActualSort().direction : null,
                // this.props.page,
                0,
                event.target.value
            )

            let newSize = event.target.value
            this.setState({ editedSize: newSize, page: this.props.page })

        } else {
            let newSize = event.target.value;
            //if the selected number of el is > of current els, fix table size to num of els to avoid flickering
            this.state.rows.length > newSize
                ? this.setState({ tableLenght: newSize, page: 0 })
                : this.setState({ tableLenght: this.state.rows.length, page: 0 });
        }

    };

    createTable() {
        let style = { minHeight: "100px", width: "99%" };
        return (
            <table
                className={this.props.classTable.concat(" table table-striped")}
                style={style}
                id={"az-table"}
            >
                <thead style={{fontSize: "14px"}}>
                <tr>
                    {this.props.empty
                        ? this.createHeadersEmpty()
                        : this.props.homepage
                            ? this.createHeadersButton()
                            : this.createHeaders()}
                </tr>
                </thead>
                <tbody>
                {this.props.empty || (this.state.rows && this.state.rows.length === 0) ? (
                    <td colSpan={this.props.reportStatistici ? this.props.cols.length : this.state.cols.length}
                        style={{textAlign: "center"}}>{this.props.emptyMsg}</td>
                ) : (
                    this.createRows()
                )}
                </tbody>
            </table>
        );
    }

    createHeaders() {
        //var cols = this.state.cols ? this.state.cols : this.props.cols

        const getClassNameTh = () => {
            if (this.props.ricercaAssistito) return styles.azTableHeaderCellMinWidthRicercaAssistito.concat(" noselect");
            else if (this.props.homePage) return null;
            else return styles.azTableHeaderCellMinWidth.concat(" noselect");
        }

        let cols = this.state.cols
        let classNameTh = getClassNameTh();
        let keyColsAlignStart = this.props.keyColsAlignStart ? this.props.keyColsAlignStart : ["cognomeNome", "iconaOspedale", "dataSegnalazione", "dataRichiesta", "medicoRichiedente", "idRichiesta", "esitoRichiesta", "dataEsito", "medicoFormalizzatore", "cureDomiciliari", "cognome", "nome", "dataNascita", "comune", "codiceFiscale", "mmg", "livelloPriorita", "inCaricoA"];

        return cols.map((el, i) => {
            if (!el["sortDirection"]) {
                return (
                    <th key={i} className={classNameTh}>
                        <div className={"row"}>
                            {typeof el.name === 'string' ?
                                <div className={keyColsAlignStart.includes(el.key) || this.props.alignColsToStart
                                    ? "col-12 d-flex align-items-start justify-content-start pl-2"
                                    : "col-12 d-flex align-items-center justify-content-center"}
                                    dangerouslySetInnerHTML={{__html: el.name}} ></div> :
                                    <div className={keyColsAlignStart.includes(el.key) || this.props.alignColsToStart
                                        ? "col-12 d-flex align-items-start justify-content-start pl-2"
                                        : "col-12 d-flex align-items-center justify-content-center"}>{el.name}</div> 
                            }
                        </div>
                    </th>
                );
            } else {
                return (this.props.homePage ?
                    <th key={i} onClick={() => this.setRows(el, i)}>
                        <div
                            className={keyColsAlignStart.includes(el.key) ? "align-items-start justify-content-start" : "align-items-center justify-content-center"}
                            style={{ display: "flex", margin: "auto" }}>

                            <span dangerouslySetInnerHTML={{__html: el.name}}/>

                            <span>
                                {el["sortDirection"] === "none" && (
                                    <Icon classes={"w3-small"} path={"img/icons/sort_both.png"} />
                                )}
                                {el["sortDirection"] === "asc" && (
                                    <Icon id={"it-arrow-up-triangle"} classes="mt-1" />)}
                                {el["sortDirection"] === "desc" && (
                                    <Icon id={"it-arrow-down-triangle"} classes="mb-1" />
                                )}
                            </span>
                        </div>
                    </th>
                    :
                    <th
                        className={classNameTh}
                        onClick={() => this.setRows(el, i)}
                        key={i}
                    >
                        <div className={"align-items-center justify-content-center"}>
                            {el.name}
                            <span>
                                {el["sortDirection"] === "none" && (
                                    <Icon classes={"w3-small"} path={"img/icons/sort_both.png"} />
                                )}
                                {el["sortDirection"] === "asc" && (
                                    <Icon id={"it-arrow-up-triangle"} classes="mt-1" />)}
                                {el["sortDirection"] === "desc" && (
                                    <Icon id={"it-arrow-down-triangle"} classes="mb-1" />
                                )}
                            </span>
                        </div>
                    </th>
                );
            }
        });
    }

    createHeadersEmpty() {
        //var cols = this.state.cols ? this.state.cols : this.props.cols
        let cols = this.state.cols;

        return cols.map((el, i) => {
            return (
                <th className={styles.azTableHeaderCell.concat(" noselect")}
                    key={i}>
                    <span>
                        {this.props.el.name}
                        {el["sortDirection"] === "NONE" && (
                            <Icon path={"img/icons/sort_both.png"} classes={"ml-2"} />
                        )}
                    </span>
                </th>
            );
        });
    }

    createHeadersButton() {

        let cols = this.state.cols ? this.state.cols : this.props.cols;
        let classNameTh = this.props.ricercaAssistito ? styles.azTableHeaderCellMinWidthRicercaAssistito.concat(" noselect") : styles.azTableHeaderCellMinWidth.concat(" noselect");
        return cols.map((el, i) => {
            if (!el["sortDirection"]) {
                return (
                    <th
                        key={i}
                        className={classNameTh}
                        style={{ cursor: "default", minWidth: "125px" }}
                    >
                        {el.name}{" "}
                    </th>
                );
            } else {
                return (
                    <th
                        key={i}
                        style={{ minWidth: "125px" }}
                        className={styles.azTableHeaderCell.concat(" noselect")}
                        onClick={() => this.setRows(el, i)}
                    >
                        <span>
                            {el.name}
                            {el["sortDirection"] === "none" && (
                                <Icon path={"img/icons/sort_both.png"} classes={"ml-2"} />
                            )}
                            {el["sortDirection"] === "asc" && (
                                <Icon id={"it-arrow-up-triangle"} classes="mt-1" />
                            )}
                            {el["sortDirection"] === "desc" && (
                                <Icon id={"it-arrow-down-triangle"} classes="mb-1" />
                            )}
                        </span>
                    </th>
                );
            }
        });
    }

    setRows(sortColumn, i) {
        //Se ho la paginazione backend, richiedo la pagina attuale con i parametri di ordinamento aggiornati
        if (this.props.changePage) { //ordinamento con richiesta della nuova pagina al backend
            /* eslint-disable-next-line */
            let updateRows = this.getSortDirection(sortColumn, i)
            console.log("[DEBUG] Changing page to page: " + this.state.page);
            this.props.changePage(
                this.getActualSort().col,
                this.getActualSort().direction,
                this.state.page,
                this.state.tableLenght
            )
        } else { //ordinamento frontend

            let updateRows = this.sortRows(
                this.state.initialRows,
                sortColumn.key,
                this.getSortDirection(sortColumn, i),
                (sortColumn?.ignoreCase ?? false)
            )
            this.setState({ rows: updateRows });
        }
    }

    sortRows = (initialRows, sortColumn, sortDirection, ignoreCase) => {

        const comparer = (a, b) => {
            let fieldA = ignoreCase ? a[sortColumn].toLowerCase() : a[sortColumn];
            let fieldB = ignoreCase ? b[sortColumn].toLowerCase() : b[sortColumn];
            if (sortDirection === "asc") {
                return fieldA > fieldB ? 1 : -1;
            } else if (sortDirection === "desc") {
                return fieldA < fieldB ? 1 : -1;
            }
        };

        return sortDirection === "none"
            ? initialRows
            : [...this.state.rows].sort(comparer);
    };

    getSortDirection(col, i) {
        let newDirection;
        let actualDirection = col["sortDirection"];
        let newCols
        if (this.props.reportStatistici)
            newCols = this.props.cols
        else
            newCols = this.state.cols

        //Reset all sortings
        newCols.forEach(el => {
            if (el["sortDirection"]) {
                el["sortDirection"] = "none";
            }
        });

        //Set new sorting

        //NONE direction NOT present
        if (actualDirection === "none" || actualDirection === "desc") {
            newDirection = "asc";
            newCols[i]["sortDirection"] = newDirection;
        } else {
            newDirection = "desc";
            newCols[i]["sortDirection"] = newDirection;
        }

        this.setState({ cols: newCols });

        return newDirection
    }

    createRows() {
        let renderCell = this.renderCell;
        let rows = [];

        if (!this.props.totalPages) {
            rows =
                this.state.rows &&
                this.state.rows.slice(
                    this.state.page * this.state.tableLenght,
                    (this.state.page + 1) * this.state.tableLenght
                );
        } else {
            rows = this.state.rows;
        }

        let cols = this.state.cols

        return rows.map((row, i) => (
            <tr rowId={i} key={i} className={this.props.ricercaAssistito && styles.bgColorWhite}>
                {cols.map(col => renderCell(row, col, i))}
            </tr>
        ));
    }

    handleRadioClick(value) {

        if (this.props.onButtonSelect) {
            this.props.onButtonSelect(this.state.rows[value.currentTarget.id])
        }

        if (this.props.onRadioButtonSelect)
            this.props.onRadioButtonSelect(this.state.rows[value])


        this.setState({
            selezioneFromRadioButton: this.state.rows[value]
        })

    }

    handleConfermaModale(rowIndex) {
        if (this.props.onModalConferma) {
            this.props.onModalConferma(this.state.rows[rowIndex]);
        }
    }

    handleButton(index) {
        if (this.props.onButtonSelect) {
            this.props.onButtonSelect(this.state.rows[index.currentTarget.id])
        }
    }

    handleChangess(row, value, isRadio, fieldName) {
        let updatedRows = this.state.rows;
        let keyField = "checked";
        if (fieldName)
            keyField = fieldName

        if (isRadio) {
            for (let i in updatedRows) {
                if (i !== row)
                    updatedRows[i][keyField] = false
            }
            this.setState({
                selected: value === true ? this.state.rows[row] : null,
                rows: updatedRows
            })
        }

        const newRows = _.cloneDeep(this.state.rows).map(r => {
            if (r.id === row.id) return {...r, checkBox:{...r.checkBox, checkValue: value}}
            else return r
        })

        this.setState({
            rows: newRows
        })

        if(row?.checkBox?.hangleChange) {
            row.checkBox.hangleChange({...row, checkBox:{...row.checkBox, checkValue: value}}, value)
        }

        if (this.props.onSelectCheckbox)
            this.props.onSelectCheckbox(this.state.rows[row], value, keyField)

    }

    renderCell(row, el, index) {
        let cell;
        let realRowIndex;

        const getIcon = (label, disable = false) => {
            if (label === azioniRichiesta.visualizzaValutazione.label ||
                label === azioniRichiesta.apriCartellaUnica.label ||
                label === azioniRichiesta.visualizzaRichiestaAttivazione.label ||
                label === azioniRichiesta.visualizzaValutazioneProfessionale.label ||
                label === azioniRichiesta.visualizzaValutazioneMultidimensionale.label ||
                label === azioniRichiesta.visualizzaValutazionePreDialogo.label ||
                label === azioniRichiesta.visualizzaPreDialogoPerPresaInCarico.label) {
                return <BsIcon.EyeFill color={disable ? "#717273" : "#176A65"} size={15}/>
            } else if (label === azioniRichiesta.completaRichiesta.label ||
                label === azioniRichiesta.completaValutazione.label ||
                label === azioniRichiesta.completaFormalizzazione.label ||
                label === azioniRichiesta.completaValutazionePreDialogo.label ||
                label === azioniRichiesta.modificaDataDimissione.label ||
                label === azioniRichiesta.completaPreDialogo.label) {
                return <BsIcon.PenFill color={disable ? "#717273" : "#176A65"} size={15}/>
            } else if (label === azioniRichiesta.accediValutazione.label ||
                label === azioniRichiesta.avviaFormalizzazione.label ||
                label === azioniRichiesta.avviaNuovaRichiesta.label ||
                label === azioniRichiesta.accediValutazionePreDialogo.label ||
                label === azioniRichiesta.avviaNuovoPreDialogo.label ||
                label === azioniRichiesta.attivaProfessionista.label) {
                return <BsIcon.ArrowUpRightSquareFill color={disable ? "#717273" : "#176A65"} size={15}/>
            }
        }

        const getLinkProps = () => {
            let nomeParametro = el["nomeParametro"];
            let path = row.linkStatoRichiesta;
            let param = el.nomeParametro ? row[el.nomeParametro] : row[el.key];
            let customState = el["state"] ? el["state"] : null;
            let label = el.label ? el.label : row[el.key];
            let valutazionePrecedente = row.valutazionePrecedente;

            if (label !== azioniRichiesta.annullaRichiesta.label && label !== azioniRichiesta.annullaPreDialogo.label && label !== azioniRichiesta.richiediAnnullamentoRichiesta.label && label !== azioniRichiesta.modificaDataDimissione.label && label !== azioniRichiesta.avviaNuovoPreDialogo.label) {
                return (
                    {
                        pathname: el["path"] === routepath.modifica_utente ? el["path"].concat("/" + row.codiceFiscale) : el["path"] ? el.path : path,
                        [nomeParametro]: param,
                        uuidRichiesta: row.uuidRichiesta,
                        state: customState,
                        tipologia: row.tipologia,
                        stato: row.stato,
                        valutazionePrecedente: valutazionePrecedente,
                        showOnlyRequiredFields: row.showOnlyRequiredFields
                    }
                )
            }
        }

        const getOnClick = (label) => {
            switch (label) {
                case azioniRichiesta.avviaNuovoPreDialogo.label:
                    return () => this.props.onClickAvviaNuovoPreDialogo(row);
                case azioniRichiesta.avviaNuovaRichiesta.label:
                    return () => this.props.onClickAvviaNuovaRichiesta(row);
                case azioniRichiesta.subentra.label:
                    return () => this.props.apriSubentroRichiesta(row.uuidRichiesta, azioniRichiesta.subentra.label);
                    case azioniRichiesta.prendiInCarico.label:
                    return () => this.props.apriSubentroRichiesta(row.uuidRichiesta, azioniRichiesta.prendiInCarico.label);
                case azioniRichiesta.annullaRichiesta.label:
                    return () => this.props.apriAnnullamentoRichiesta(row.uuidRichiesta);
                case azioniRichiesta.annullaPreDialogo.label:
                    return () => this.props.apriAnnullamentoRichiesta(row.uuidRichiesta, azioniRichiesta.annullaPreDialogo.label);
                case azioniRichiesta.richiediAnnullamentoRichiesta.label:
                    return () => this.props.apriAnnullamentoRichiesta(row.uuidRichiesta, azioniRichiesta.richiediAnnullamentoRichiesta.label);
                case azioniRichiesta.modificaDataDimissione.label:
                    return () => this.props.apriModificaDataDimissione(row.uuidRichiesta, row.dataDimissioneProgrammataDaRicovero);
                case azioniRichiesta.visualizzaValutazione.label:
                    return () => this.props.apriModalePresaInCaricoRichiesta(row.messaggioDaVisualizzare, row?.primiLink[0]?.linkProps, row.uuidRichiesta)
                default:
                    return () => null;
            }
        }

        const renderLinkPrecedentiCurePaziente = () => {
            if (el.key === "cognome" && !Utils.isStringEmptyOrNullOrUndefined(row.pazienteHaCureDomiciliariPrecedenti)) {
                return <div className={styles.ellipsisLabel}><span
                    className={"pl-1"}><Link>{row.pazienteHaCureDomiciliariPrecedenti}</Link></span></div>
            }
        }

        const renderCustomLink = (link) => (<div className={styles.linkToTd} onClick={link?.onClick()}>{link?.label}</div>)
        if (this.props.changePage) {
            realRowIndex = index
        } else {
            realRowIndex = (this.state.page * this.state.tableLenght) + index;
        }
        if (el["tipo"] === "link") {
            let links = row[el.key] ? row[el.key] : [];
            let label = el.label ? el.label : null;

            let div;

            if (el.notShown && row[el.notShown.field] === el.notShown.conditionValue) {
                div = null;
            } else {
                if (row["linkStatoRichiesta"] === null) {
                    div = <div className={"row"}>
                        <div className={styles.ellipsisLabel.concat(" col-10")}>
                            <span style={{textDecoration: "underline", color: "#717273", fontSize: "12px"}}>{label}</span>
                        </div>
                        <div className={"col-2 d-flex justify-content-end align-self-center"}>
                            {getIcon(label, true)}
                        </div>
                    </div>;
                } else {
                    if (row["flag"]) {
                        div = <div className={"row"}>
                            <div className={styles.ellipsisLabel.concat(" col-10")}>
                                <Link>
                                    <span onClick={this.setModal(row, getLinkProps, getOnClick, label)}
                                        style={{cursor: "pointer", fontSize: "12px"}}>{label}</span>
                                </Link>
                            </div>
                            <div className={"col-2 d-flex justify-content-end align-self-center"}>
                                {getIcon(label)}
                            </div>
                        </div>;
                    } else {
                        if (!Utils.isStringEmptyOrNullOrUndefined(label)) {
                            div = <div className={"row"} onClick={getOnClick(label)}>
                                <div className={styles.ellipsisLabel.concat(" col-10")}>
                                    <Link to={getLinkProps()}>
                                        <span style={{cursor: "pointer", fontSize: "12px"}}>{label}</span>
                                    </Link>
                                </div>
                                <div className={"col-2 d-flex justify-content-end align-self-center"}>
                                    {getIcon(label)}
                                </div>
                            </div>;
                        }
                        else {
                            div = links?.map(link => {
                                const icon = getIcon(link?.label);
                                return <>
                                    <div className={["row m-0", icon ? "pr-1" : ""].join(" ").trim()}
                                         onClick={getOnClick(link?.label)}>
                                        <div className={[styles.ellipsisLabel, icon ? "col-9" : "col-12", el?.className].join(" ")}>
                                            <Link to={row.messaggioDaVisualizzare ? {} : link?.linkProps}>
                                                <span title={link?.label} style={{cursor: "pointer", fontSize: "12px"}}>{link?.label}
                                                    </span>
                                            </Link>
                                        </div>
                                        {icon}
                                    </div>
                                </>
                            })
                        }
                    }
                }
            }

            cell = (
                <td className={styles.azTableCell.concat(" noselect")}>
                    {div}
                </td>
            );
        } else if (el["tipo"] === "icon") {
            cell = (
                <td className={styles.azTableCell.concat(" noselect")}>
                    <div className={"text-center"}>
                        {row[el.key]}
                    </div>
                </td>
            )
        } else if (el["tipo"] === "dropdown") {
            cell = (
                <td className={styles.azTableCellDropdown.concat(" noselect")}>
                    <div className={"row justify-content-center"}>
                        <Dropdown
                            renderDropdownToggle={row[el.key].renderDropdownToggle}
                            renderDropdownMenu={row[el.key].renderDropdownMenu}
                            idDropdown={row[el.key].idDropdown}
                            idDropdownToggle={row[el.key].idDropdownToggle}
                            tagDropdownToggle={row[el.key].tagDropdownToggle}
                            direction={row[el.key].direction}
                            classNameDropdown={row[el.key].classNameDropdown}
                            classNameDropdownMenu={row[el.key].classNameDropdownMenu}
                            classNameDropdownToggle={row[el.key].classNameDropdownToggle}
                        />
                    </div>
                </td>
            )
        } else if (el["tipo"] === "pallino") {
            let sticker;
            let stickerFill;
            let stato = row[el.key]; //codice dello stato del pallino da mostrare
            let oggetto = el["oggetto"];
            if (el["home"]) {
                sticker = stato?.cssClass
            } else {

                sticker = oggetto ? (stato ? (oggetto[stato] ? (oggetto[stato].cssClass ? oggetto[stato].cssClass : null) : null) : null) : null;
            }

            


            stickerFill = sticker.includes("stickerGreen") ? checkCircle : (sticker.includes("sticker") ? exclamationCircle : null);
            let classNameStickerFill = sticker.includes("stickerGreen") ? "stickerFillVerificato" : "stickerFillWarning";

            let pallino = this.creaIconaPallino(row, el, sticker, stickerFill, classNameStickerFill);

            cell = (
                (el.notShown && row[el.notShown.field] === el.notShown.conditionValue) ?
                    <td className={styles.azTableCell.concat(" noselect")} style={{ textAlign: "center" }}>
                        <div className={"row"}>
                            <div className={"col-10 d-flex align-items-center justify-content-center"}>
                                <h3>-</h3>
                            </div>
                        </div>
                    </td> :
                    <td className={styles.azTableCell.concat(" noselect")}>
                        {(oggetto && stato && oggetto[stato] && oggetto[stato].sNome) || stato.sNome ?
                            <div className={"row"}>
                                <div className={"col-auto d-flex align-items-center justify-content-center"}>
                                    {stato.sNome ? stato.sNome : oggetto[stato].sNome}
                                </div>
                                {pallino}
                            </div> :
                            <div className={"row justify-content-center"}>
                                {pallino}
                            </div>}
                    </td>
            );

        } else if (el["tipo"] === "checkbox") {
            let disabled = false;
            if (this.props.disableCheckbox) {
                disabled = this.props.disableCheckbox(this.state.rows[realRowIndex], el["key"])
            }
            cell = (
                <td>
                    <CheckBox id={"checkbox_" + realRowIndex + el["key"]}
                        index={realRowIndex}
                        isRadio={el["isRadio"]} //prop aggiuntiva che indica se il comportamento è quello di un radiobutton
                        deselectStyle={el["deselect"]}  //prop aggiuntiva che determina stile e comportamento della checkbox: quando la deseleziono, la riga corrispondente viene eliminata
                        parentClass={styles.azTableCellCheckbox}
                        checked={this.state.rows[realRowIndex][el["key"]] || this.state.rows[realRowIndex]["checkBox"]["checkValue"]}
                        row={this.state.rows[realRowIndex]}
                        fieldName={el["key"]}
                        disabled={disabled ||  this.state.rows[realRowIndex]["checkBox"]["disabled"]}
                        classContainer={el["classContainer"]}
                        onChange={(field, value) => this.handleChangess(this.state.rows[realRowIndex], value?.target?.checked)}></CheckBox>
                </td>
            );
        } else if (el["tipo"] === "modale") {
            cell = (
                <td>
                    <Modale
                        id={"modale" + realRowIndex}
                        typeModal="modalConsenso"
                        modalText={el["modalContent"]["text"]}
                        text={el["modalContent"]["buttonText"] ? el["modalContent"]["buttonText"] : null}
                        icon={el["modalContent"]["buttonIcon"] ? el["modalContent"]["buttonIcon"] : null}
                        classes={el["modalContent"]["buttonClass"] ? el["modalContent"]["buttonClass"] : ""}
                        title={"Elimina Segnalazione"}
                        textButtonOk={el["modalContent"]["conferma"]}
                        textButtonKo={el["modalContent"]["annulla"]}
                        confirmFun={(e) => {
                            this.handleConfermaModale(realRowIndex)
                        }} />
                </td>

            );
        } else if (el["tipo"] === "dizionario") {
            let codice;
            if (el.key === "ulssAppartenenza" && row[el.key] === null) {
                codice = row.ulssResidenza;
            } else {
                codice = row[el.key]
            }
            let oggetto = el["oggetto"]
            let campo = el["campo"]
            cell = (
                <td className={styles.azTableCell.concat(" noselect")}>
                    <span>{(oggetto ? (codice ? (
                        oggetto[codice] ?
                            ((campo) ?
                                oggetto[codice][campo] : oggetto[codice]) : codice) : "") : "")}</span>
                </td>)
        } else if (el["tipo"] === "radiobutton") {
            cell = (
                <td>

                    <RadioButton id={"tableRadioButton" + realRowIndex}
                        name={"tableRadioButtons"}
                        onClick={this.handleRadioClick}
                        value={index}
                        checked={
                            this.state.selezioneFromRadioButton
                                ? this.state.rows[realRowIndex][el["identifierName"]] === this.state.selezioneFromRadioButton[el["identifierName"]]
                                : !!this.state.rows[realRowIndex][el["identifierName"]]}
                    />

                </td>
            );
        } else if (el["tipo"] === "linkButton") {
            let label = el.label ? el.label : row[el.key]

            cell = (
                <td>
                    <Button
                        id={index}
                        classes="btn btn btn-adi btnGreenOutline "
                        onClick={this.handleButton}
                        style={{
                            outline: "none !important",
                            border: "none",
                            background: "none",
                            fontSize: "16px",
                            color: "#176A65",
                            textDecoration: "underline"
                        }}
                        text={this.props.t("Tables.reportStatistici.visualizzaGrafico")}
                    >
                        <span style={{ marginLeft: "5px", cursor: "pointer" }}>{label}</span>
                    </Button>

                </td>
            );
        } else if (el["tipo"] === "data") {
            cell = (

                <td className={styles.azTableCellTwo.concat(" noselect")} style={{ textAlign: "center" }}>
                    <span>{Utils.transformTimeStampForFrontend(row[el.key])}</span>
                </td>
            );
        } else if (el["tipo"] === "textWithStyle") {
            cell = (
                <td className={styles.azTableCell.concat(" noselect")}>
                    <span className={[_.isEqual(row[el.key]?.text, "-") ? "px-2 mx-5" : "pl-1"].join(" ").trim()} style={row[el.key]?.style}>{row[el.key]?.text}</span>
                </td>
            );
        } else if (this.state.bigTable === true) {
            cell = (
                <td className={styles.azTableCell.concat(" noselect")}>
                    <span className={[_.isEqual(row[el.key], "-") ? "px-2 mx-5" : "pl-1"].join(" ").trim()}>{row[el.key]}</span>
                    {renderLinkPrecedentiCurePaziente()}
                </td>
            );
        } else if (el["tipo"] === "array" || el["tipo"] === "arrayConIcona") {
            let stickerFill = checkCircle;

            cell = (
                <td className={styles.azTableCellTwo.concat(" noselect")} style={{ textAlign: "center" }}>
                    {row[el.key].map(elem => {
                        const text = elem?.sName ?? elem;
                        return (
                            <div key={elem}
                                className={this.props.classNameRow ? this.props.classNameRow : "row d-flex justify-content-md-center"}>
                                <span className={_.isEqual(text, "-") ? "px-2 mx-5" : ""}>
                                    {text}
                                </span>
                                {el["tipo"] === "arrayConIcona" ? this.creaIconaPallino(row, el, elem?.cssClass, stickerFill, (elem?.cssClass === "stickerGreen" ? "stickerFillVerificato" : "stickerFillWarning")) : null}
                            </div>)
                    })}
                </td>
            );

        } else if (el["tipo"] === "labelConLink") {
            cell = (
                <td className={styles.azTableCell.concat(" noselect")}>
                    <div>{row[el.key]?.label}</div>
                    {row[el.key].link && renderCustomLink(row[el.key]?.link)}
                </td>
            );
        }
        else {
            cell = (
                <td className={styles.azTableCellTwo.concat(" noselect")} style={{ textAlign: "center" }}>
                    <span>{row[el.key]}</span>
                </td>
            );
        }

        return cell;

    }

    setModal(row, getLinkProps, getOnClick, label) {
        return () => {
            let modale = _.cloneDeep(this.state.modaleAvvisoCategoriaAur);
            modale.text = row["modalText"];
            modale.link = getLinkProps();
            modale.clickAvviaNuovaRichiesta = getOnClick(label);
            modale.apriModaleAvvisoCategoriaAur = true;
            this.setState({ modaleAvvisoCategoriaAur: modale });
        };
    }

    creaIconaPallino(row, el, sticker, stickerFill, classNameStickerFill) {
        return (el.notShown && row[el.notShown.field] === el.notShown.conditionValue) ? null : sticker ?
            <div className={"col-auto d-flex align-items-center"}>
                {sticker.includes("stickerTrattino")
                    ? <span className={"font-weight-bold text-center"}>-</span>
                    : sticker.includes("stickerGreen") || sticker.includes("stickerRed") || sticker.includes("stickerYellow") || sticker.includes("stickerGreenOp4")
                        ? <div className={styles[sticker]}>
                            <span className={styles[classNameStickerFill]}>{stickerFill}</span>
                        </div>
                        : null}
            </div>
            : null;
    }

    createPagination() {

        let totalPages
        if (this.props.pagination) {
            if (this.props.empty)
                return null
            else {
                if (this.props.totalPages) {
                    totalPages = this.props.totalPages
                } else {
                    totalPages = this.state.rows.length / this.state.tableLenght
                }
            }
        } else {
            return null
        }
        let pageNumbers = []
        totalPages = Math.ceil(totalPages)
        let lastPage = Math.floor(totalPages - 1)
        totalPages && pageNumbers.push(
            <li key={0} className="page-item">
                {this.state.page === 0 && <a className="page-link" aria-current="page">{this.state.page + 1}</a>}
                {this.state.page > 0 && this.state.page !== lastPage &&
                    <a className="page-link" onClick={() => this.changePage(this.state.page - 1)}>{this.state.page}</a>}
                {totalPages === 2 && this.state.page === lastPage &&
                    <a className="page-link" onClick={() => this.changePage(this.state.page - 1)}>{this.state.page}</a>}
                {totalPages !== 2 && this.state.page === lastPage && this.state.page !== 0 &&
                    <a className="page-link"
                        onClick={() => this.changePage(this.state.page - 2)}>{this.state.page - 1}</a>}
            </li>
        )

        totalPages > 1 && pageNumbers.push(
            <li key={1} className="page-item">
                {this.state.page === 0 &&
                    <a className="page-link"
                        onClick={() => this.changePage(this.state.page + 1)}>{this.state.page + 2}</a>}
                {this.state.page > 0 && this.state.page !== lastPage &&
                    <a className="page-link" aria-current="page">{this.state.page + 1}</a>}
                {totalPages === 2 && this.state.page === lastPage &&
                    <a className="page-link" aria-current="page">{this.state.page + 1}</a>}
                {totalPages !== 2 && this.state.page === lastPage &&
                    <a className="page-link" onClick={() => this.changePage(this.state.page - 1)}>{this.state.page}</a>}
            </li>
        )

        totalPages > 2 && pageNumbers.push(
            <li key={2} className="page-item">
                {this.state.page === 0 &&
                    <a className="page-link"
                        onClick={() => this.changePage(this.state.page + 2)}>{this.state.page + 3}</a>}
                {this.state.page > 0 && this.state.page !== lastPage &&
                    <a className="page-link"
                        onClick={() => this.changePage(this.state.page + 1)}>{this.state.page + 2}</a>}
                {this.state.page === lastPage && <a className="page-link" aria-current="page">{this.state.page + 1}</a>}
            </li>
        )

        let toRender

        this.props.nascondiPaginazione ? toRender = null :
            toRender = <div className={styles.paginationContainer.concat(" fit-width")}>
                <ul className="pagination mt-2">
                    <label style={{ "marginTop": "12px", "marginRight": "15px" }}>
                        {this.state.editedSize !== 10 ? this.state.editedSize > this.props.totalElements ? this.props.totalElements :
                            ((this.state.page + 1) * this.state.editedSize) > this.props.totalElements ?
                                this.props.totalElements : ((this.state.page + 1) * this.state.editedSize) :
                            ((this.state.page + 1) * this.state.tableLenght > this.props.totalElements
                                ? this.props.totalElements
                                : (this.state.page + 1) * this.state.tableLenght)
                        }
                        {" "}
                        di{" "}
                        {this.props.totalElements
                            ? this.props.totalElements
                            : this.state.rows.length}
                    </label>
                    <li
                        className="page-item"
                        onClick={() => this.state.page === 0 ? null : this.changePage(0)}
                    >
                        <a style={{ cursor: this.state.page === 0 ? "auto" : "pointer" }} className="page-link"
                            tabIndex="-1" aria-hidden="true">
                            <span style={{ fontFamily: "Arial", opacity: this.state.page === 0 ? "0.5" : "1" }}>
                                Prima
                            </span>
                        </a>
                    </li>
                    <li
                        className="page-item"
                        onClick={() => this.state.page === 0 ? null : this.changePage(this.state.page - 1)}
                    >
                        <a style={{ cursor: this.state.page === 0 ? "auto" : "pointer" }} className="page-link"
                            tabIndex="-1" aria-hidden="true">
                            <span className="sr-only" style={{ fontFamily: "Arial" }}>
                                Pagina precedente
                            </span>
                            <Icon style={{ opacity: this.state.page === 0 ? "0.5" : "1" }} id={"it-chevron-left"} />
                        </a>
                    </li>
                    {pageNumbers}
                    <li
                        className="page-item"
                        onClick={() => this.state.page === lastPage ? null : this.changePage(this.state.page + 1)}
                    >
                        <a style={{ cursor: this.state.page === lastPage ? "auto" : "pointer" }} className="page-link">
                            <span className="sr-only">Pagina successiva</span>
                            <Icon style={{ opacity: this.state.page === lastPage ? "0.5" : "1" }}
                                id={"it-chevron-right"} />
                        </a>
                    </li>
                    <li
                        className="page-item"
                        onClick={() => this.state.page === lastPage ? null : this.changePage(lastPage)}
                    >

                        <a style={{ cursor: this.state.page === lastPage ? "auto" : "pointer" }} className="page-link"
                            tabIndex="-1" aria-hidden="true">
                            <span style={{ fontFamily: "Arial", opacity: this.state.page === lastPage ? "0.5" : "1" }}>
                                Ultima
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        return toRender;
    }

    getActualSort() {
        let actualSorted
        if (this.props.reportStatistici) {
            this.props.cols.forEach(col => {
                if (col.sortDirection && col.sortDirection !== "none") {
                    actualSorted = { col: col.key, direction: col.sortDirection };
                }
            });
        } else {
            this.state.cols.forEach(col => {
                if (col.sortDirection && col.sortDirection !== "none") {
                    actualSorted = { col: col.key, direction: col.sortDirection };
                }
            });
        }


        return actualSorted;
    }

    changePage(newPage) {

        if (this.props.totalPages) {
            if (newPage >= 0 && newPage < this.props.totalPages) {
                if (this.props.changePage) {
                    console.log("[DEBUG] Changing page to page: " + newPage);
                    this.props.changePage(
                        this.getActualSort() ? this.getActualSort().col : null,
                        this.getActualSort() ? this.getActualSort().direction : null,
                        newPage,
                        this.state.tableLenght
                    )
                    return false;
                } else {

                    this.setState({ page: newPage });
                }
            } else console.log("[DEBUG] Invalid page: " + newPage);
        } else {
            if (
                newPage >= 0 &&
                newPage < this.state.rows.length / this.state.tableLenght
            ) {
                this.setState({ page: newPage });
            } else console.log("[DEBUG] Invalid page: " + newPage);
        }
    }
}

Table.propTypes = propTypes;
Table.defaultProps = {
    rows: [],
    initialRows: [],
    tableLenght: 10,
    cols: [],
    homepage: false,
    empty: false,
    lengthEditable: false,
    pagination: true,
    bigTable: false,
    emptyMsg: "Non sono presenti istanze da completare",
    alignColsToStart: false,
    showScroll: true,
    internalScroll: false,
    tableHeightForInternalScroll: "50vh",
    classTable: ""
};

export default Table;
