import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    cuore: PropTypes.any,
    onChangeEsameObiettivo: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.string,
    pageState: PropTypes.string,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultTipoAlterazione = {
    id: null,
    alterazione: null
};

const defaultPausaLibera = {
    id: null,
    pausa: null,
    sede: null
}

export default class Cuore extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.cuore)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.cuore, this.props.cuore))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.cuore);
        this.setState({dati}, () => this.validazioneDati(dati));
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({dati}) => {
            if (index === null && subField === null) {
                dati[field] = elem;
            } else {
                dati[field][index][subField] = elem;
            }

            if (!dati.presenzaDiAlterazioni) {
                dati.tipoDiAlterazione = [];
            }

            if (!this.showNote(dati)) {
                dati.noteToniCardiaci = null;
            }

            if (dati.pauseLibere) {
                dati.listaPauseLibere = [];
                dati.notePauseLibere = null;
            }

            if (!this.showCondizioneRilevata(dati)) {
                dati.condizioneRilevata = null;
            }

            return {dati};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        const dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("cuore", dati);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneCuore", campiObbligatoriRimanenti);
    }

    showCondizioneRilevata(dati) {
        return (dati.presenzaDiAlterazioni || (dati.ritmoCardiaco !== null && dati.ritmoCardiaco !== "01") || (dati.toniCardiaci !== null && dati.toniCardiaci !== "01") || dati.pauseLibere === false);
    }

    showNote(dati) {
        return (dati.presenzaDiAlterazioni || (dati.ritmoCardiaco !== null && dati.ritmoCardiaco !== "01") || (dati.toniCardiaci !== null && dati.toniCardiaci !== "01"));
    }

    handleDispositiviArrayChange = (array, field) => {
        let dati = _.cloneDeep(this.state.dati);
        dati[field] = array;
        this.setState({dati}, () => {
            this.validazioneDati();
            this.forceUpdate();
        });
    }

    /* Alterazione */

    renderTipoAlterazione = (alterazione, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'TIPO_ALT_CUORE.CUORE.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm("tipoDiAlterazione", elem.target.value, index, "alterazione")}
                    value={alterazione.alterazione}
                    field={"alterazione"}
                    placeholder={"Tipo di alterazione"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                />
            </FieldsRow>
        )
    }

    addNewTipoAlterazione = () => {
        let dati = _.cloneDeep(this.state.dati);
        let tipoAlterazione = _.cloneDeep(defaultTipoAlterazione);
        tipoAlterazione.id = uuid();
        dati.tipoDiAlterazione.push(tipoAlterazione);
        this.setState({dati}, () => this.validazioneDati());
    }

    removeTipoAlterazione = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.tipoDiAlterazione.splice(index, 1);
        this.setState({dati}, () => this.validazioneDati());
    }

    /* Pause libere */

    renderPausaLibera = (pausaLibera, index) => {
        return (
            <FieldsRow>
                <RoleBasedSelect
                    fieldId={'SPECIFIC_PAUSE_LIBERE.CUORE.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    id={"selectMotivoAssenzaPauseLibere"}
                    options={oggettiUtili.motivoAssenzaPauseLibere}
                    value={pausaLibera.pausa}
                    onChange={(elem) => this.handleAggiornaForm("listaPauseLibere", elem, index, "pausa")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Motivi assenza Pause libere non esistenti"}
                    fieldLabel={"Specificare"}
                    handleOnlyValue={true}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    field={"pausa"}
                />
                <RoleBasedSelect
                    fieldId={'SEDE_PAUSE_LIBERE.CUORE.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    id={"selectSedePauseLibere"}
                    options={oggettiUtili.sediPauseLibereCuore}
                    value={pausaLibera.sede}
                    onChange={(elem) => this.handleAggiornaForm("listaPauseLibere", elem, index, "sede")}
                    isSearchable={false}
                    noOptionsMessage={() => "Errore: Sede Pause libere non esistente"}
                    fieldLabel={"Sede"}
                    handleOnlyValue={true}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    field={"sede"}
                />
            </FieldsRow>
        );
    }

    addNewPausaLibera = () => {
        let dati = _.cloneDeep(this.state.dati);
        let pausaLibera = _.cloneDeep(defaultPausaLibera);
        pausaLibera.id = uuid();
        dati.listaPauseLibere.push(pausaLibera);
        this.setState({dati}, () => this.validazioneDati());
    }

    removePausaLibera = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.listaPauseLibere.splice(index, 1);
        this.setState({dati}, () => this.validazioneDati());
    }

    renderBodySezione() {
        const dati = this.state.dati;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PRES_ALT_CUORE.CUORE.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"selectPresenzaDiAlterazioni"}
                        options={oggettiUtili.rispostaBreve}
                        value={dati.presenzaDiAlterazioni}
                        onChange={(elem) => this.handleAggiornaForm("presenzaDiAlterazioni", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Esito alterazioni non esistente"}
                        fieldLabel={"Presenza di alterazioni"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        field={"presenzaDiAlterazioni"}
                    />
                </FieldsRow>

                {dati.presenzaDiAlterazioni &&
                    <SectionList
                        title={'Tipo di alterazione'}
                        data={dati.tipoDiAlterazione}
                        renderSection={this.renderTipoAlterazione}
                        addNewSectionCallback={this.addNewTipoAlterazione}
                        removeSectionCallback={this.removeTipoAlterazione}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        pageState={this.props.pageState}
                        keyFieldId={'TIPO_ALT_CUORE.CUORE.ESAMEOBIETTIVO'}
                        field={'tipoDiAlterazione'}
                        addButtonVisibilityHandler={dati.tipoDiAlterazione?.every(t => !!t.alterazione)}
                        parentJsonPath={"esameObiettivo.cuore.tipoDiAlterazione"}
                    />}

                <DispositiviPresenti
                    handleArrayChange={this.handleDispositiviArrayChange}
                    dispositiviPresenti={dati.dispositiviPresenti}
                    pageState={this.props.pageState}
                    fieldId={"DISPOSITIVI.CUORE.ESAMEOBIETTIVO"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    parentJsonPath={"esameObiettivo.cuore.dispositiviPresenti"}
                />

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'RITMO_ATTIV_CARD.CUORE.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"selectRitmoCardiaco"}
                        options={oggettiUtili.ritmoCardiacoAttivitaCardiaca}
                        value={dati.ritmoCardiaco}
                        onChange={(elem) => this.handleAggiornaForm("ritmoCardiaco", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Ritmo cardiaco/Attività cardiaca non rilevato/a"}
                        fieldLabel={"Ritmo cardiaco/Attività cardiaca"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        field={"ritmoCardiaco"}
                    />

                    <RoleBasedSelect
                        fieldId={'TONI_CARD_CUORE.CUORE.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"selectToniCardiaci"}
                        options={oggettiUtili.toniCardiaci}
                        value={dati.toniCardiaci}
                        onChange={(elem) => this.handleAggiornaForm("toniCardiaci", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Tono cardiaco non rilevato"}
                        fieldLabel={"Toni cardiaci"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        field={"toniCardiaci"}
                    />

                    {this.showNote(dati) &&
                        <RoleBasedInput
                            fieldId={'NOTE_TONI_CARD.CUORE.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            value={dati.noteToniCardiaci ?? ""}
                            placeholder={"Note"}
                            field={"noteToniCardiaci"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"inputNoteToniCardiaci"}
                            fieldLabel={"Note"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                        />}
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PAUSE_LIBERE.CUORE.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"selectPauseLibere"}
                        options={oggettiUtili.rispostaBrevePauseLibere}
                        value={dati.pauseLibere}
                        onChange={(elem) => this.handleAggiornaForm("pauseLibere", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Pause libere non presenti"}
                        fieldLabel={"Pause libere"}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        field={"pauseLibere"}
                    />
                    {dati.pauseLibere === false &&
                        <RoleBasedInput
                            fieldId={'NOTE_PAUSE_LIBERE.CUORE.ESAMEOBIETTIVO'}
                            pageState={this.props.pageState}
                            value={dati.notePauseLibere ?? ""}
                            placeholder={"Note"}
                            field={"notePauseLibere"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id={"inputNotePauseLibere"}
                            fieldLabel={"Note"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            disableDoubleInput={this.props.disableDoubleInput}
                            hideUnsetValues={this.props.hideUnsetValues}
                        />}
                </FieldsRow>

                {dati.pauseLibere === false &&
                    <SectionList
                        title={'Pausa libera'}
                        data={dati.listaPauseLibere}
                        renderSection={this.renderPausaLibera}
                        addNewSectionCallback={this.addNewPausaLibera}
                        removeSectionCallback={this.removePausaLibera}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        pageState={this.props.pageState}
                        keyFieldId={'SPECIFIC_PAUSE_LIBERE.CUORE.ESAMEOBIETTIVO'}
                        field={'listaPauseLibere'}
                        addButtonVisibilityHandler={dati.listaPauseLibere?.every(p => p.pausa)}
                        parentJsonPath={"esameObiettivo.cuore.listaPauseLibere"}
                    />}

                <CondizioneRilevataSection
                    ambito={'CUORE'}
                    parentFieldId={'CUORE.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={dati.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={dati.daAttenzionare}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                    showSuggerimenti={true}
                    openCondition={this.showCondizioneRilevata(dati)}
                />
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (
                <AccordionSezioneRichiesta
                    idAccordion={"cuore"}
                    title={"Cuore"}
                    required={false}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    pageState={this.props.pageState}
                    fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.cuore.tipoDiAlterazione", "esameObiettivo.cuore.dispositiviPresenti",
                            "esameObiettivo.cuore.listaPauseLibere", "esameObiettivo.cuore.propostaAttivitaSuggerimenti",
                            "esameObiettivo.cuore.propostaAttivitaSuggerimenti.nomenclatore"],
                        [defaultTipoAlterazione, defaultDispositiviPresenti, defaultPausaLibera, defaultProposteAttivitaSuggerimenti]
                    )}
                    accordionReadOnly={this.props.accordionReadOnly}
                >{this.renderBodySezione()}</AccordionSezioneRichiesta>
            );
        } else {
            return this.renderBodySezione();
        }
    }
}

Cuore.propTypes = propTypes;

Cuore.defaultProps = {
    showAccordion: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}
