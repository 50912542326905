import serviceConfig from "../config/serviceConfig";
import {catchError, finalize, map} from "rxjs/operators";
import {from, of} from "rxjs";
import auth from "../utils/AthnAuth";
import * as _ from "lodash";

export default class GestionePasswordService {
    static urlRichieste = serviceConfig['ms_gestione_password'];



    static creaPassword(request) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.creaPassword, request));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({status: err.response?.status, data: err.response?.data, msg: "ERRORE"});
        }));
    }

    static modificaPassword(request) {
        let instance = auth._httpClient;

        let obs = from(instance.patch(this.urlRichieste.modificaPassword, request));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({status: err.response?.status, data: err.response?.data, msg: "ERRORE"});
        }));
    }

    static recuperaPassword(request) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.recuperaPassword, request));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({status: err.response?.status, data: err.response?.data, msg: "ERRORE"});
        }));
    }

    static verificaToken(request) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.verificaToken, request));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({status: err.response?.status, data: err.response?.data, msg: "ERRORE"});
        }));
    }

    static resetPassword(request) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.resetPassword, request));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({status: err.response?.status, data: err.response?.data, msg: "ERRORE"});
        }));
    }

    static checkPassword(request) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.checkPassword, request));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({status: err.response?.status, data: err.response?.data, msg: "ERRORE"});
        }));
    }

    static verificaEsistenzaPassword() {
        let instance = auth._httpClient;

        let obs = from(instance.get(this.urlRichieste.verificaEsistenzaPassword));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({status: err.response?.status, data: err.response?.data, msg: "ERRORE"});
        }));
    }

    static invioMailCreazionePassword(){
        let instance = auth._httpClient;

        let obs = from(instance.get(this.urlRichieste.invioEmailCreazionePassword));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({status: err.response?.status, data: err.response?.data, msg: "ERRORE"});
        }));
    }

}
