import React, {Component} from "react";
import PropTypes from "prop-types";
import AccordionHelper from "../../service/AccordionHelper";
import * as _ from "lodash";
import Utils from "../../utils/Utils";
import styles from "./Breadcrumb.module.css";
import AnagrafeService from "../../service/AnagrafeService";
import {mostraSpinner, nascondiSpinner} from "../../App";

export default class Breadcrumb extends Component {

    state = {
        finalPath: _.cloneDeep(this.props.path),
        infoAssistito : ""
    }


    componentDidMount() {
        AccordionHelper.accordionPageSubject.subscribe(pathToAdd => {
            if (!this.props.profileAction && pathToAdd != null && this.props.path.length <= pathToAdd?.position) {
                let finalPath = _.cloneDeep(this.state.finalPath);
                finalPath[pathToAdd.position] = pathToAdd.title;
                this.setState({finalPath});
            }
        });
        this.recuperaInformazioneDelAssistito()
    }

    recuperaInformazioneDelAssistito = () => {
        if (this.props.richiesta?.idAssistito) {
            AnagrafeService.getPersonaById(this.props.richiesta.idAssistito).subscribe(res => {
                this.setState({
                    infoAssistito: res
                });
            })
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.path, this.props.path)) this.setState({finalPath: this.props.path});
        if (!_.isEqual(prevProps.richiesta?.idAssistito, this.props.richiesta?.idAssistito)) {
            this.recuperaInformazioneDelAssistito()
        }
    }

    render() {
        if (!this.state.finalPath || this.state.finalPath.length === 0) throw new Error('Breadcrumb path is empty');

        const active = <li className={"breadcrumb-item active font-weight-bold"} style={{color: "#176A65"}} aria-current="page">
            {this.state.finalPath[this.state.finalPath.length - 1]}
        </li>;

        const parents = [];
        for (let i = 0; i < this.state.finalPath.length - 1; i++) {
            let className = "breadcrumb-item";
            if (i === 0) className += " font-weight-bold";
            parents.push(<li key={`breadcrub-item-${i}`} className={className}>
                {this.state.finalPath[i]}<span className="separator">{this.props.separator}</span>
            </li>);
        }

        const {infoAssistito} = this.state;

        return <div id={"breadcrumbContainer"} className={["row", "w-100", "m-auto", styles.stickyBar].join(" ")}>
            <div className={"col-8"}>
                <nav className={"breadcrumb-container"} aria-label={"breadcrumb"}>
                    <ol className={"breadcrumb px-1 ml-4 mb-0"}>
                        {parents}
                        {active}
                    </ol>
                </nav>
            </div>
            {(infoAssistito) &&
                <div id={"pazienteBreadcrumbLabel"} className={"col-4 align-self-center d-inline text-right pr-4"}>
                    <span className={"font-weight-bold"}>Paziente: </span>
                    <span>{infoAssistito?.nome} {infoAssistito?.cognome}; nato il {Utils.transformTimeStampForFrontend(infoAssistito?.dataNascita)}</span>
                </div>}
        </div>;
    }
}

Breadcrumb.propTypes = {
    path: PropTypes.arrayOf(PropTypes.string).isRequired,
    separator: PropTypes.string,
    richiesta: PropTypes.object,
    profileAction: PropTypes.bool
}

Breadcrumb.defaultProps = {
    separator: '>'
}
