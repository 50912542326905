import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import * as _ from "lodash";
import TabellaDeleghe from "./tabellaDeleghe/TabellaDeleghe";
import {setFasciaBottoniStyle} from "../../utils/Utils";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import {Button} from "web-client-archetype";
import InserisciDelega from "./inserisciDelega/InserisciDelega";
import ModalHelper from "../modale/ModalHelper";
import DelegaService from "../../service/DelegaService";
import enumDelega from "../../enum/enumDelega"

const propTypes = {
    name: PropTypes.string,
    profileAction: PropTypes.bool
}

const defaultSize = 10;
const defaultPage = 1;

export default class GestioneDeleghe extends Component {

    state = {
        listaDeleghe: null, // deleghe recuperate
        paginaInserimentoDelega: false, // cambia pagina
        messaggioSuccesso: null, // messaggio di successo al termine di un'operazione di scrittura
        delegaModifica: null // delega da modificare
    }

    componentDidMount() {
        setFasciaBottoniStyle("#fasciaBottoni");
        this.recuperaDeleghe()
    }

    componentWillUnmount() {
        $("#fasciaBottoni").css("border-bottom", "");
    }

    recuperaDeleghe = (page = defaultPage, size = defaultSize) => {
        let data = null; let azioni = null;
        if(this.props.name == enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE){
            azioni = [enumDelega.azioni.FORMALIZZAZIONE_DEL_SETTING_RICHIESTO_E_FIRMA.value,
                enumDelega.azioni.COMPOSIZIONE_DEL_TEAM_E_FORMALIZZAZIONE_DEL_SETTING_RICHIESTO.value];
        } else if(this.props.name == enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
            azioni = [enumDelega.azioni.VALUTAZIONE_PROFESSIONALE.value];
        }
        DelegaService.ricercaDeleghe(data, azioni, page, size).subscribe((pagina) => {
            let deleghe;
            if(page !== defaultPage){
               deleghe = _.cloneDeep(this.state.listaDeleghe).concat(...pagina.lista);
            } else {
                deleghe = pagina.lista;
            }
            this.setState({listaDeleghe: deleghe});
        });
    };

    renderModaleSuccesso() {
        let body = <><span className={"text-center f-weight-600"}>{this.state.messaggioSuccesso}</span></>;
        let footer = <>
            <div className={"col-12 text-center"}>
                <Button
                    id={"chiudiBtn"}
                    text={"Chiudi"}
                    className={"btn btn-adi btnGreenOutline"}
                    dataDismiss={"modal"}
                    onClick={() => {
                        this.setState({messaggioSuccesso: null});
                    }}
                />
            </div>
        </>;
        return <ModalHelper id={"modaleSuccesso"}
                            title={"Operazione completata"}
                            body={body}
                            footer={footer}
                            titleClassName={"modal-header d-inline text-center backgroundGreen"}
                            containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                            contentClassName={"modal-content"}
                            footerClassName={"modal-footer justify-content-around"}
                            bodyClassName={"modal-body row align-items-center justify-content-around"}
        />;
    }

    render() {
        let pagina, pathBreadcrumb;
        let home = 'Home';
        if(!this.state.paginaInserimentoDelega){
            pagina = this.renderPaginaMonitoraggioDeleghe();
            if(this.props.name === enumDelega.menu.CENSIMENTO_CENTRALE_ADI){
                pathBreadcrumb = [home,'Censimento Centrali ADI'];
            } else if(this.props.name === enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE){
                pathBreadcrumb = [home,'Gestione deleghe'];
            } else if(this.props.name === enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
                pathBreadcrumb = [home,'Nomina automatica professionisti'];
            }
        } else {
            pagina = this.renderPaginaInserimentoDelega();
            if(this.props.name === enumDelega.menu.CENSIMENTO_CENTRALE_ADI){
                pathBreadcrumb = [home, 'Censimento Centrali ADI', 'Inserisci dati della centrale ADI'];
            } else if(this.props.name === enumDelega.menu.DELEGHE_REFERENTE_DISTRETTUALE){
                pathBreadcrumb = [home, 'Gestione deleghe', 'Inserisci dati della delega'];
            } else if(this.props.name === enumDelega.menu.NOMINA_AUTOMATICA_PROFESSIONISTI){
                pathBreadcrumb = [home, 'Nomina automatica professionisti', 'Inserisci dati della nomina automatica'];
            }
        }

        return (
            <Fragment>
                {this.state.messaggioSuccesso ? this.renderModaleSuccesso() : <Fragment/>}
                <Breadcrumb path={pathBreadcrumb} profileAction={this.props.profileAction}/>
                    {pagina}
            </Fragment>
        )
    }

    renderPaginaMonitoraggioDeleghe = () => {
        return <Fragment>
            <TabellaDeleghe
                name={this.props.name}
                listaDeleghe={this.state.listaDeleghe}
                ricaricaTabella={(load) => this.setState({
                    messaggioSuccesso: load.messaggioSuccesso
                }, () => this.recuperaDeleghe(load.mostraAltri ? defaultPage + 1 : defaultPage, defaultSize))}
                cambiaPagina={(value, delega, centrale) => {this.setState({paginaInserimentoDelega: value, delegaModifica: {delega, centrale}})}}
            />
        </Fragment>

    }

    renderPaginaInserimentoDelega = () => {
        return <Fragment>
            <InserisciDelega
                name={this.props.name}
                paginaPrecedente={(msg) => this.setState({
                    messaggioSuccesso: msg,
                    paginaInserimentoDelega: false,
                    delegaModifica: null
                }, () => {
                    if (msg) this.recuperaDeleghe()
                })}
                delega={this.state.delegaModifica.delega}
                centrale={this.state.delegaModifica.centrale}
                listaDeleghe={this.state.listaDeleghe}
            />
        </Fragment>
    }
}

GestioneDeleghe.propTypes = propTypes;
