import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {FieldsRow, RoleBasedSelect} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";

const propTypes = {
    datiBenessereSociale: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    mascheraModificabilita: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

export default class BenessereSociale extends AccordionSecondLevel {
    state = {
        datiBenessereSociale: _.cloneDeep(this.props.datiBenessereSociale),
        campiSelectelect: [
            {occupazioneBenessereSociale: "occupazioneBenessereSociale"},
            {disponibilitaEconomicaMensileAssistito: "disponibilitaEconomicaMensileAssistito"}
        ]
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiBenessereSociale = _.cloneDeep(this.state.datiBenessereSociale);
        datiBenessereSociale = Utils.valueToObject(datiBenessereSociale, this.state.campiSelectelect);
        this.setState({datiBenessereSociale: datiBenessereSociale}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        let datiBenessereSociale = _.cloneDeep(this.state.datiBenessereSociale);
        datiBenessereSociale[field] = elem;
        this.setState({datiBenessereSociale: datiBenessereSociale}, () => this.validazioneDati());
    }

    validazioneDati = () => {
        let datiBenessereSociale = _.cloneDeep(this.state.datiBenessereSociale);
        datiBenessereSociale = Utils.objectToValue(datiBenessereSociale, this.state.campiSelectelect);
        datiBenessereSociale = _.mapValues(datiBenessereSociale, el => el === null || el === undefined || el === "" ? null : el);
        this.props.onChangeDatiAssistito("datiBenessereSociale", datiBenessereSociale);
    }

    renderBody = () => {
        let datiBenessereSociale = this.state.datiBenessereSociale;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'OCCUP.BENESSERESOCIALE.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        id={"selectOccupazioneBenessereSociale"}
                        options={OggettiUtili.occupazioneBenessereSociale}
                        value={datiBenessereSociale.occupazioneBenessereSociale}
                        onChange={(elem) => this.handleAggiornaForm("occupazioneBenessereSociale", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Occupazione benessere sociale non esistente"}
                        field={"occupazioneBenessereSociale"}
                        fieldLabel={"Occupazione"}
                        forceReadOnly={this.props.forceReadOnly
                            || this.props.mascheraModificabilita?.situazioneSociale?.datiBenessereSociale?.occupazioneBenessereSociale}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'DISP_ECO_MENS_ASSIST.BENESSERESOCIALE.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        id={"selectDisponibilitaEconomicaMensileAssistito"}
                        options={OggettiUtili.disponibilitaEconomicaMensileAssistito}
                        value={datiBenessereSociale.disponibilitaEconomicaMensileAssistito}
                        onChange={(elem) => this.handleAggiornaForm("disponibilitaEconomicaMensileAssistito", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Disponibilità economica mensile assistito non esistente"}
                        field={"disponibilitaEconomicaMensileAssistito"}
                        fieldLabel={"Disponibilità economica mensile dell'assistito"}
                        hint={"(reddito totale dell'anziano inclusa l'eventuale indennità di accompagnamento se percepita o anche solo riconosciuta)"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiBenessereSociale"}
                title={"Benessere sociale"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}

BenessereSociale.propTypes = propTypes;

