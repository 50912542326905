import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  FieldsGroup,
  FieldsRow,
  RoleBasedSelect,
} from "../../roleBasedComponents/RoleBasedComponents";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import OggettiUtili from "../../../utils/dataset/OggettiUtili";

function ValutazioniSettingRichiesto({
  openAccordion,
  onClickAccordion,
  field,
  pageState,
  progettoAssistenziale,
  handleChangeProgettoAssistenziale,
}) {
  let progettoAssistenzialeRichiestoPrincipale = (
    <FieldsGroup
      fieldId={"PROG_ASS_RICHIES_PRINC.VALUTAZIONECAMBIOSETTING.PROGETTOASSISTENZIALERICHIESTO"}
      fieldLabel={"Progetto assistenziale richiesto principale"}
      fieldRequired={true}
    >
      <RoleBasedSelect
        fieldId={"PROG_ASS_RICHIES_PRINC.VALUTAZIONECAMBIOSETTING.PROGETTOASSISTENZIALERICHIESTO"}
        pageState={pageState}
        parentClass={"mb-0"}
        id={"PROG_ASS_RICHIES_PRINC"}
        options={OggettiUtili.progettoAssistenzialeRichiesta}
        value={OggettiUtili.progettoAssistenzialeRichiesta.find(
          (option) =>
            option.value ===
            progettoAssistenziale?.progettoAssistenzialePrincipale
        )}
        onChange={(elem) => {
          handleChangeProgettoAssistenziale(
            "progettoAssistenzialePrincipale",
            elem.value
          );
        }}
        isSearchable={false}
        noOptionsMessage={() => "Errore: Non sono presenti opzioni"}
        forceReadOnly={false}
        hideUnsetValues={false}
        disableDoubleInput={false}
        fieldRequired={true}
      />
    </FieldsGroup>
  );

  let progettoAssistenzialeRichiestoSecondario = (
    <FieldsGroup fieldId={"PROG_ASS_RICHIES_SECOND.VALUTAZIONECAMBIOSETTING.PROGETTOASSISTENZIALERICHIESTO"}
                 fieldLabel={"Progetto assistenziale richiesto secondario"}
                 showFieldIfRequiredAccordionPage={false}>
      <RoleBasedSelect
        fieldId={"PROG_ASS_RICHIES_SECOND.VALUTAZIONECAMBIOSETTING.PROGETTOASSISTENZIALERICHIESTO"}
        pageState={pageState}
        parentClass={"mb-0"}
        id={"PROG_ASS_RICHIES_SECOND"}
        options={OggettiUtili.progettoAssistenzialeRichiesta}
        value={OggettiUtili.progettoAssistenzialeRichiesta.find(
          (option) =>
            option.value ===
            progettoAssistenziale?.progettoAssistenzialeSecondario
        )}
        onChange={(elem) => {
          handleChangeProgettoAssistenziale(
            "progettoAssistenzialeSecondario",
            elem.value
          );
        }}
        isSearchable={false}
        noOptionsMessage={() => "Errore: Non sono presenti opzioni"}
        forceReadOnly={false}
        hideUnsetValues={false}
        disableDoubleInput={false}
      />
    </FieldsGroup>
  );
  return (
    <Fragment>
      <AccordionSezioneRichiesta
        idAccordion={"valutazioniSettingRichiesto"}
        title={"Valutazioni setting richiesto"}
        required={true}
        openAccordion={openAccordion}
        onClickAccordion={onClickAccordion}
        field={field}
        pageState={pageState}
      >
        <FieldsRow>
          {progettoAssistenzialeRichiestoPrincipale}
          {progettoAssistenzialeRichiestoSecondario}
        </FieldsRow>
      </AccordionSezioneRichiesta>
    </Fragment>
  );
}

ValutazioniSettingRichiesto.propTypes = {
  openAccordion: PropTypes.bool,
  onClickAccordion: PropTypes.func,
  field: PropTypes.string,
  pageState: PropTypes.string,
  progettoAssistenziale: PropTypes.node,
};

ValutazioniSettingRichiesto.defaultProps = {
  openAccordion: false,
  onClickAccordion: null,
  field: "",
  pageState: "",
  progettoAssistenziale: {},
};

export default ValutazioniSettingRichiesto;
