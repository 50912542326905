import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import {FieldsRow, icd9List,
    RoleBasedCalendar, 
    RoleBasedICD9Input,
    RoleBasedInput,} from "../../roleBasedComponents/RoleBasedComponents";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import moment from "moment";

const propTypes = {
    pageState: PropTypes.string,
    datiInfoUltimoRicovero: PropTypes.any,
    onChangeDatiInfoUltimoRicovero: PropTypes.any,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    hideUnsetValues: PropTypes.bool,
    mascheraModificabilita:PropTypes.any,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool
}

export default class InfoUltimoRicovero extends Component {

    state = {
        ultimoRicovero: _.cloneDeep(this.props.datiInfoUltimoRicovero),
        campiObbligatori: 0,
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiInfoUltimoRicovero, this.props.datiInfoUltimoRicovero))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let ultimoRicovero = _.cloneDeep(this.props.datiInfoUltimoRicovero);
        ultimoRicovero.dataRicovero = Utils.transformTimeStampForFrontend(ultimoRicovero.dataRicovero)
        ultimoRicovero.dataDimissione = Utils.transformTimeStampForFrontend(ultimoRicovero.dataDimissione)
        this.setState({ultimoRicovero: ultimoRicovero}, () => this.props.onChangeRequiredFieldsNumber("informazioniSuUltimoRicovero", this.numeroCampiObbligatori()));
    }

    getErrorDataDimissione(ultimoRicovero) {
        if (!ultimoRicovero.dataRicovero && ultimoRicovero.dataDimissione) {
            return moment(ultimoRicovero.dataDimissione, "DD/MM/YYYY") < moment().format("DD/MM/YYYY");
        } else if (ultimoRicovero.dataRicovero && ultimoRicovero.dataDimissione) {
            return moment(ultimoRicovero.dataDimissione, "DD/MM/YYYY").isBefore(moment(ultimoRicovero.dataRicovero, "DD/MM/YYYY"));
        }
        return false;
    }

    getErrorDataUltimoRicovero(ultimoRicovero) {
        return ultimoRicovero.dataRicovero && ultimoRicovero.dataDimissione
            ? moment(ultimoRicovero.dataRicovero, "DD/MM/YYYY").isAfter(moment(ultimoRicovero.dataDimissione, "DD/MM/YYYY"))
            : false;
    }

    numeroCampiObbligatori = () => {
        let campiObbligatori = 0;
        if (this.getErrorDataDimissione(this.state.ultimoRicovero)) campiObbligatori++;
        if (this.getErrorDataUltimoRicovero(this.state.ultimoRicovero)) campiObbligatori++;
        return campiObbligatori;
    }

    handleAggiornaForm = (field, elem) => {
        let ultimoRicovero = _.cloneDeep(this.state.ultimoRicovero);
        if (field === "isRicovero" && !elem) {
            ultimoRicovero.codiceIstitutoCura = null;
            ultimoRicovero.unitaOperativaDimissione = null;
            ultimoRicovero.diagnosiDimissione = null;
            ultimoRicovero.dataRicovero = null;
            ultimoRicovero.dataDimissione = null;
            ultimoRicovero.provenienzaPaziente = null;
            ultimoRicovero.modalitaDimissione = null;
        }
        ultimoRicovero[field] = elem;
        this.setState({ultimoRicovero: ultimoRicovero}, () => {
            let ultimoRicoveroProps = _.cloneDeep(ultimoRicovero);
            this.props.onChangeDatiInfoUltimoRicovero("infoUltimoRicovero", ultimoRicoveroProps);
            this.props.onChangeRequiredFieldsNumber("informazioniSuUltimoRicovero", this.numeroCampiObbligatori());
        });
    }

    renderBody = () => {
        const ultimoRicovero = _.cloneDeep(this.state.ultimoRicovero);
        const errorDataUltimoRicovero = this.getErrorDataUltimoRicovero(ultimoRicovero);
        const errorDataDimissione = this.getErrorDataDimissione(ultimoRicovero);
        const icd9ListCustom = _.cloneDeep(icd9List);
        return (
            <>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'COD_IST_CURA_DIMIS.INFOULTIMORICOVERO.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"codiceIstitutoCura"}
                        value={ultimoRicovero.codiceIstitutoCura}
                        placeholder={"Codice istituto di cura di dimissione (stabilimento)"}
                        field={"codiceIstitutoCura"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        fieldLabel={"Codice istituto di cura di dimissione (stabilimento)"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'UN_OP_DIM.INFOULTIMORICOVERO.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"unitaOperativaDimissione"}
                        value={ultimoRicovero.unitaOperativaDimissione}
                        placeholder={"Unità Operativa di dimissione"}
                        field={"unitaOperativaDimissione"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        fieldLabel={"Unità Operativa di dimissione"}
                        forceReadOnly={this.props.mascheraModificabilita?.informazioniBaseFaseRichiesta?.infoUltimoRicovero?.unitaOperativaDimissione
                            || this.props.mascheraModificabilita?.informazioniBaseFaseValutazione?.infoUltimoRicovero?.unitaOperativaDimissione}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedICD9Input
                        fieldId={'DIAGN_DIMISS.INFOULTIMORICOVERO.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"autocompleteDiagnosiDimissione"}
                        value={ultimoRicovero.diagnosiDimissione}
                        placeholder={"Diagnosi di dimissione"}
                        field={"diagnosiDimissione"}
                        suggestions={icd9ListCustom}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        hideUnsetValues={this.props.hideUnsetValues}
                        fieldLabel={"Diagnosi di dimissione"}
                        forceReadOnly={this.props.mascheraModificabilita?.informazioniBaseFaseRichiesta?.infoUltimoRicovero?.diagnosiDimissione
                            || this.props.mascheraModificabilita?.informazioniBaseFaseValutazione?.infoUltimoRicovero?.diagnosiDimissione}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedCalendar
                        fieldId={'DATA_RICOV.INFOULTIMORICOVERO.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"infoUltimoRicoveroDataRicovero"}
                        value={ultimoRicovero.dataRicovero}
                        onChange={(elem) => this.handleAggiornaForm("dataRicovero", elem.target.value)}
                        errorMessage={"Data di ricovero non valida"}
                        fieldLabel={"Data di ricovero"}
                        field={"dataRicovero"}
                        error={errorDataUltimoRicovero}
                        fieldRequired={errorDataUltimoRicovero}
                        forceReadOnly={this.props.mascheraModificabilita?.informazioniBaseFaseRichiesta?.infoUltimoRicovero?.dataRicovero
                            || this.props.mascheraModificabilita?.informazioniBaseFaseValutazione?.infoUltimoRicovero?.dataRicovero}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}

                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'DATA_DIM.INFOULTIMORICOVERO.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"infoUltimoRicoveroDataDimissione"}
                        value={ultimoRicovero.dataDimissione}
                        onChange={(elem) => this.handleAggiornaForm("dataDimissione", elem.target.value)}
                        errorMessage={"Data di dimissione non valida"}
                        fieldLabel={"Data di dimissione"}
                        field={"dataDimissione"}
                        error={errorDataDimissione}
                        fieldRequired={errorDataDimissione}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldId={'PROVENIENZA_PAZ.INFOULTIMORICOVERO.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"provenienzaPaziente"}
                        value={ultimoRicovero.provenienzaPaziente}
                        placeholder={"Provenienza del paziente"}
                        field={"provenienzaPaziente"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        fieldLabel={"Provenienza del paziente"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldId={'MOD_DIMISS.INFOULTIMORICOVERO.INFORMAZIONIDIBASE'}
                        pageState={this.props.pageState}
                        id={"modalitaDimissione"}
                        value={ultimoRicovero.modalitaDimissione}
                        placeholder={"Modalità di dimissione"}
                        field={"modalitaDimissione"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        fieldLabel={"Modalità di dimissione"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"infoUltimoRicovero"}
            title={"Informazioni su ultimo ricovero negli ultimi due anni"}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            required={this.numeroCampiObbligatori() > 0}
            accordionReadOnly={this.props.accordionReadOnly}
        >{this.renderBody()}</AccordionSezioneRichiesta>
    }
}

InfoUltimoRicovero.propTypes = propTypes;
