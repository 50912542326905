import React, { Component, Fragment } from "react";
import { Button, Icon, Input, SessioneUtente } from "web-client-archetype";
import styleRicerca from "./RicercaAssistiti.module.css";
import Calendar from "../../generalComponents/calendar/Calendar";
import Utils, { closeModaleStyle } from "../../../utils/Utils";
import Table from "../../generalComponents/table/Table"
import * as _ from "lodash";
import RichiastaADIService from "../../../service/RichiestaADIService";
import RichiestaADIService from "../../../service/RichiestaADIService";
import { mostraSpinner, nascondiSpinner } from "../../../App";
import AnagrafeService from "../../../service/AnagrafeService";
import routepath from "../../../utils/route/route-path.json";
import { Redirect } from "react-router";
import azioniRichiesta from "../../../utils/dataset/richiestaData/azioniRichiesta.json";
import statiRichiesta from "../../../utils/dataset/richiestaData/statiRichiesta.json";
import enumRichiesta from "../../../enum/enumRichiesta.json";
import RicercaAssistitiUtils from "./RicercaAssistitiUtils";
import FiltriUtils from "../../../utils/FiltriUtils";
import enumsUtente from "../../../enum/enumsUtente.json";
import { Context } from "../../../utils/Context";
import PropTypes from "prop-types";
import { Link, withRouter } from 'react-router-dom';
import categorieAur from "../../../utils/dataset/categorieAurData/categorieAur.json";
import AuthUtils from "../../../utils/AuthUtils";
import SubentroRichiesta from "../subentroRichiesta/SubentroRichiesta";
import ModalHelper from "../../modale/ModalHelper";
import * as BsIcon from "react-bootstrap-icons";

const propTypes = {
    statoAvviaNuovaRichiesta: PropTypes.bool,
    impostaAvviaNuovaRichiesta: PropTypes.func,
    impostaAvviaNuovoPreDialogo: PropTypes.func,
    apriAnnullamentoRichiesta: PropTypes.func,
    apriSubentroRichiesta: PropTypes.func,
    statoAvviaNuovoPreDialogo: PropTypes.bool,
    location: PropTypes.object,
    clickSearchButton: PropTypes.bool,
}

const colonne = [
    { key: "cognome", name: "Cognome " },
    { key: "nome", name: "Nome" },
    { key: "dataNascita", name: "Data di nascita" },
    { key: "comune", name: "Comune di nascita" },
    { key: "codiceFiscale", name: "Codice fiscale" },
    { key: "mmg", name: "MMG/PLS" },
    { key: "primiLink", name: "", tipo: "link", path: "", nomeParametro: "", className: "text-right"}
];

const getColonneMedicoOspedaliero = () => {
    let colonneMedicoOspedaliero = _.cloneDeep(colonne);
    colonneMedicoOspedaliero.push({ key: "secondiLink", name: "", tipo: "link", path: "", nomeParametro: "" })
    return colonneMedicoOspedaliero;
}

const msgErrore = "ERRORE";

class ricercaAssistiti extends Component {
    state = {
        cognome: "",
        nome: "",
        dataNascita: "",
        codiceFiscale: "",
        codiceFiscaleObbligatorio: true,
        cognomeNomeDataObbligatori: true,
        pulsanteCercaAbilitato: false,
        colonneRisultati: _.cloneDeep(colonne),
        colonneRisultatiMedicoOspedaliero: getColonneMedicoOspedaliero(),
        ricercaAssistiti: false,
        ultimoAssistitoCaricato: false,
        risultatiCaricati: false,
        anagrafeAssistiti: [],
        listaAssistiti: [],
        numeroUltimeRicercheAnagrafiche: 5,
        numeroUltimeRicerchePersone: 5,
        ultimeRicerche: [],
        ultimeRicerchePersone: [],
        ultimeRicercheAnagraficheCaricate: false,
        ultimeRicerchePersoneCaricate: false,
        anagrafeAssistitiCaricata: false,
        nuovaRichiesta: {},
        redirectInserimentoRichiesta: false,
        redirectToHome: false,
        apriSubentroRichiesta: false,
        uuidRichiestaDaSubentrare: null,
        apriConfermaAvviaNuovaRichiesta: false,
        assistito: null,
        apriModalePresaInCaricoRichiesta: false,
        messaggioModaleDaVisualizzare: null,
        linkPropsRedirect: null,
        uuidRichiestaSostituzioneRichiedente: null
    }

    listaComuni = JSON.parse(sessionStorage.getItem("comuni"));
    listaProvince = JSON.parse(sessionStorage.getItem("province"));

    constructor(props) {
        super(props);
        this.handleAggiornaFiltriAssistito = this.handleAggiornaFiltriAssistito.bind(this);
    }

    handleAggiornaFiltriAssistito(field, event) {
        let state = _.cloneDeep(this.state);

        if (field === "dataNascita") {
            state[field] = event;
        } else if (field === "codiceFiscale") {
            if (event.target.value?.length <= 16)
                state[field] = event.target.value;
        } else state[field] = event.target.value;

        this.setState(state);
    }

    resetAnagrafeAssistiti() {
        this.setState({ anagrafeAssistiti: [] });
    }

    resetListaAssistiti() {
        this.setState({ listaAssistiti: [] });
    }

    setRisultatiCaricati = (bool) => {
        this.setState({ risultatiCaricati: bool })
    }

    setAnagrafeAssistitiCaricata(bool) {
        this.setState({ anagrafeAssistitiCaricata: bool })
    }

    setricercaAssistiti(bool) {
        this.setState({ ricercaAssistiti: bool })
    }

    setUltimeRicercheAnagraficheCaricate = (bool) => {
        this.setState({ ultimeRicercheAnagraficheCaricate: bool })
    }

    setUltimeRicerchePersoneCaricate = (bool) => {
        this.setState({ ultimeRicerchePersoneCaricate: bool })
    }

    aggiungiAssistitoAllaLista = (assistito, assistiti, indiceAssistito) => {
        let listaAssistiti = _.cloneDeep(this.state.listaAssistiti);
        listaAssistiti.push(assistito)
        this.setState({ listaAssistiti: listaAssistiti }, () => {
            if (assistiti?.length - 1 === indiceAssistito) {
                this.setRisultatiCaricati(true);
                nascondiSpinner();
            }
        });
    }

    aggiungiAssistitoAlleUltimeRicerche = (assistito, assistiti, indiceAssistito) => {
        let ultimeRicerche = _.cloneDeep(this.state.ultimeRicerche);
        ultimeRicerche.push(assistito);

        if (ultimeRicerche.length === assistiti.length) {
            ultimeRicerche = this.ordinaUltimeRicerche(ultimeRicerche, assistiti)
        }

        this.setState({ ultimeRicerche: ultimeRicerche }, () => {
            if (assistiti?.length - 1 === indiceAssistito) {
                this.setUltimeRicercheAnagraficheCaricate(true);
                nascondiSpinner();
            }
        });
    }

    ordinaUltimeRicerche(ultimeRicerche, assistiti) {

        const assistitiIds = assistiti.map((el) => el.id);

        ultimeRicerche.sort(function (a, b) {
            let A = a["id"];
            let B = b["id"];

            if (assistitiIds.indexOf(A) > assistitiIds.indexOf(B)) {
                return 1;
            } else {
                return -1;
            }

        });

        return ultimeRicerche;
    }

    aggiungiAssistitoAlleUltimeRicerchePersone = (assistito) => {
        let ultimeRicerchePersone = _.cloneDeep(this.state.ultimeRicerchePersone);
        ultimeRicerchePersone.push(assistito);
        this.setState({ ultimeRicerchePersone: ultimeRicerchePersone });
    }

    ricercaAssistiti(assistiti, aggiungiAssistitoAllaLista, setRisultatiCaricati) {
        if ((this.props.statoAvviaNuovaRichiesta || this.props.statoAvviaNuovoPreDialogo) && assistiti?.length > 0) {
            assistiti.forEach((assistito, i) => {
                if (!assistito.anagraficaLocale) {
                    let comune = this.listaComuni?.find(comune => comune.codice === assistito.comune)?.descrizione;
                    comune = comune && comune.length > 0 ? comune : "";
                    let provincia = this.listaProvince.find(provincia => provincia.codice === assistito.provincia)?.descrizione
                    provincia = provincia && provincia.length > 0 ? " (" + provincia + ")" : "";
                    let assistitoMapping = {
                        ...assistito,
                        dataNascita: Utils.transformTimeStampForFrontend(assistito.dataNascita),
                        comune: comune + provincia,
                        primiLink: [{ label: this.props.statoAvviaNuovoPreDialogo ? azioniRichiesta.avviaNuovoPreDialogo.label : azioniRichiesta.avviaNuovaRichiesta.label, linkProps: {} }]
                    }
                    this.verificaCategoriaAur(assistitoMapping);
                    aggiungiAssistitoAllaLista(assistitoMapping, assistiti, i)
                } else {
                    let assistitoMapping;
                    RichiastaADIService.getRichiesteHome(null, null, {
                        filtriAssistito: { id_persona: assistito.id },
                        ordinamentoFormatoTimestamp: "DATETIME_HH_MM_SS",
                        profilazione: AuthUtils.hasRuoloMedicoRichiedenteAndMedicoOspedaliero() ? false : true
                    }, "timestampCreazione", "desc").subscribe((response) => {
                        if (response.msg === "ERRORE") {
                            Utils.toasterFunction({
                                status: response?.data?.codice ? response?.data?.codice : response?.status,
                                type: "critical",
                                text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                            });
                        } else {
                            assistitoMapping = RicercaAssistitiUtils.mappingAssistito(response?.data?.elementi[0], assistito, this.listaComuni, this.listaProvince, "avvioNuovaRichiesta");
                            this.verificaCategoriaAur(assistitoMapping);
                            if (response?.data?.elementi?.length > 0 && !Utils.isObjectEmpty(assistitoMapping)) {
                                aggiungiAssistitoAllaLista(assistitoMapping, assistiti, i);
                            } else {
                                let assistitoMapped = {
                                    ...assistito,
                                    dataNascita: Utils.transformTimeStampForFrontend(assistito.dataNascita),
                                    comune: this.listaComuni ? this.listaComuni?.find(comune => comune.codice === assistito.comune)?.descrizione + " (" + this.listaProvince.find(provincia => provincia.codice === assistito.provincia)?.descrizione + ")" : "-",
                                    primiLink: [{ label: this.props.statoAvviaNuovoPreDialogo ? azioniRichiesta.avviaNuovoPreDialogo.label : azioniRichiesta.avviaNuovaRichiesta.label, linkProps: {} }]
                                }
                                aggiungiAssistitoAllaLista(assistitoMapped, assistiti, i);
                            }
                        }
                    })
                }
            })
        } else if (assistiti?.length > 0) {

            let listaPersoneId = assistiti.map(assistito => assistito.id);

            //Gestione default filtri richiesta
            let params = {};
            FiltriUtils.setDefaultFiltriRichiesta("ricercaAssistiti", params);

            RichiastaADIService.getRichiesteHome(null, null, { filtriAssistito: { id_persona: listaPersoneId } }, null, null, params).subscribe((response) => {
                if (response.msg === "ERRORE") {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                    });
                } else {
                    assistiti.forEach((el) => {
                        if (response?.data?.elementi?.find(richiesta => richiesta?.assistito?.identificativoUtente === el.identificativoUtente)) {
                            aggiungiAssistitoAllaLista(RicercaAssistitiUtils.mappingAssistito(response?.data?.elementi?.find(richiesta => richiesta?.assistito?.identificativoUtente === el.identificativoUtente), el, this.listaComuni, this.listaProvince, "ricercaAssistiti"));
                        }
                    })
                    setRisultatiCaricati(true);
                    nascondiSpinner();
                }
            })
        } else setRisultatiCaricati(true);
    }

    verificaCategoriaAur(assistitoMapped) {
        if (assistitoMapped?.primiLink?.length > 0) {
            if (assistitoMapped?.primiLink[0].label === azioniRichiesta.avviaNuovaRichiesta.label) {
                const categoriaAur = categorieAur.find(c => c?.categoria === assistitoMapped?.categoria);
                if (!Utils.isObjectEmpty(categoriaAur)) {
                    switch (categoriaAur?.cure) {
                        case "SI":
                            //Non fare nulla
                            break;

                        case "NO":
                            //Disabilitare il pulsante;
                            assistitoMapped.primiLink[0].linkProps = null;
                            break;

                        case "-":
                            //Non mostrare il pulsante
                            assistitoMapped.primiLink[0].linkProps = null;
                            assistitoMapped.primiLink[0].label = null;
                            break;

                        case "FLAG":
                            //Creazione modale;
                            assistitoMapped.flag = true;
                            assistitoMapped.modalText = categoriaAur?.note;
                            break;
                    }
                }
            }
        }
    }


    ricercaAnagrafiche() {
        mostraSpinner();

        const gestioneResponse = (response) => {
            this.setricercaAssistiti(false);

            this.visualizzaMessaggioErroreGenericoAnagrafica(response);

            this.visualizzaMessaggioAnagraficaZero(response);

            // continua flusso
            if (response?.list?.length || response?.length) {
                this.setState({ anagrafeAssistiti: response.list });
                this.setAnagrafeAssistitiCaricata(true);
                this.ricercaAssistiti(response?.list?.length ? response.list : response, this.aggiungiAssistitoAllaLista, this.setRisultatiCaricati);
            } else {
                this.setRisultatiCaricati(true)
                nascondiSpinner()
            }
        }

        if (this.props.statoAvviaNuovaRichiesta || this.props.statoAvviaNuovoPreDialogo) {

            let queryParams = {};

            if (!Utils.isStringEmptyOrNullOrUndefined(this.state.codiceFiscale))
                queryParams.codiceFiscale = this.state.codiceFiscale;
            if (!Utils.isStringEmptyOrNullOrUndefined(this.state.cognome) && !Utils.isStringEmptyOrNullOrUndefined(this.state.nome)) {
                queryParams.cognome = this.state.cognome;
                queryParams.nome = this.state.nome;
            }
            if (!Utils.isStringEmptyOrNullOrUndefined(this.state.dataNascita))
                queryParams.dataNascita = Utils.transformDateForBackend(this.state.dataNascita, "YYYY-MM-DD");

            AnagrafeService.getAnagrafiche(queryParams).subscribe((response) => {
                gestioneResponse(response);
            })

        } else  {

            let queryParams = {
                vista: "totale",
                filtro: {},
                storicizza: true
            }

            if (!Utils.isStringEmptyOrNullOrUndefined(this.state.codiceFiscale))
                queryParams.filtro = "identificativo_utente~~" + this.state.codiceFiscale;
            if (!Utils.isStringEmptyOrNullOrUndefined(this.state.cognome))
                queryParams.filtro = "cognome~~" + this.state.cognome;
            if (!Utils.isStringEmptyOrNullOrUndefined(this.state.nome))
                queryParams.filtro = queryParams.filtro.concat(",nome~~" + this.state.nome);
            if (!Utils.isStringEmptyOrNullOrUndefined(this.state.dataNascita))
                queryParams.filtro = queryParams.filtro.concat(",data_nascita::" + Utils.transformDateForBackend(this.state.dataNascita, "YYYY-MM-DD"));

            AnagrafeService.getPersone(queryParams).subscribe((response) => {
                gestioneResponse(response);
            })

        }

    }

    visualizzaMessaggioErroreGenericoAnagrafica(response) {
        // eslint-disable-next-line no-prototype-builtins
        if (response?.hasOwnProperty("error")) {
            Utils.toasterFunction({
                status: response?.codice ? response?.codice : response?.status,
                type: "critical",
                text: response?.message
            })
            nascondiSpinner()
        }
    }

    visualizzaMessaggioAnagraficaZero(response) {
        const estraiIssues = (type, severity) => {
            let messages = _.filter(response.issues, issue => issue.severity === severity).map(issue => issue.message);
            if (messages?.length) {
                let messagesJSX = _.map(messages, msg => {
                    return <Fragment>
                        {msg}
                        <br />
                    </Fragment>
                });
                Utils.toasterFunction({
                    type: type,
                    text: messagesJSX
                })
                // tolgo spinner se c'è un errore perchè termina il flusso
                if (severity === "error") {
                    nascondiSpinner()
                }
            }
        }

        if (response?.issues?.length) {
            estraiIssues("confirm", "warning");
            estraiIssues("critical", "error");
            this.setAnagrafeAssistitiCaricata(true);
        }
    }

    recuperaCronologiaRicercaAnagrafiche() {
        mostraSpinner();
        let queryParams = { numeroRicercheAnagrafiche: this.state.numeroUltimeRicercheAnagrafiche };

        AnagrafeService.getUltimeRicerche(queryParams).subscribe((response) => {
            this.ricercaAssistiti(response, this.aggiungiAssistitoAlleUltimeRicerche, this.setUltimeRicercheAnagraficheCaricate);
        })
    }

    recuperaCronologiaRicercaPersone() {
        mostraSpinner();
        let queryParams = { numeroRicerchePersone: this.state.numeroUltimeRicerchePersone };

        AnagrafeService.getUltimeRicerchePersone(queryParams).subscribe((response) => {
            this.ricercaAssistiti(response, this.aggiungiAssistitoAlleUltimeRicerchePersone, this.setUltimeRicerchePersoneCaricate)
        })
    }

    getRuolo(profilo) {
        let ruolo = null;
        SessioneUtente.getInstance().settings.forEach(setting => {
            if (setting.profilo === profilo)
                ruolo = setting.ruolo;
        });
        return ruolo;
    }

    avviaNuovoPreDialogo = (assistito) => {
        mostraSpinner();
        let msgErrore = "ERRORE";

        let creaPreDialogo = () => {
            AnagrafeService.creaPersona(assistito.id).subscribe((persona) => {
                if (persona?.msg === msgErrore) {
                    this.apriToasterErrore(persona);
                    nascondiSpinner();
                }
                else {
                    AnagrafeService.creaMmg(assistito.id).subscribe((mmg) => {
                        if (mmg?.msg === msgErrore) {
                            this.apriToasterErrore(mmg);
                            nascondiSpinner();
                        }
                        else {
                            let sessioneUtente = SessioneUtente.getInstance();
                            let body = {
                                nome: sessioneUtente.nomeUtente,
                                cognome: sessioneUtente.cognomeUtente,
                                identificativoUtente: sessioneUtente.idUtente,
                                tipoIdentificativoUtente: "CF",
                                aulss: sessioneUtente.ulss,
                                distretto: sessioneUtente.settings.find(el => el.ruolo === enumsUtente.ruoli.medicoOspedaliero)?.distretto
                            }
                            AnagrafeService.salvaRichiedente(assistito.id, body).subscribe((richiedente) => {
                                if (richiedente?.msg === msgErrore) {
                                    this.apriToasterErrore(richiedente);
                                    nascondiSpinner();
                                }
                                else {
                                    let richiesta = {
                                        idAssistito: persona.id,
                                        mmg: {
                                            nome: mmg.nome,
                                            cognome: mmg.cognome,
                                            codiceFiscale: mmg.identificativoUtente,
                                            aulss: mmg.aulss,
                                            idProfessionista: mmg.id,
                                            codiceRegionale: mmg.codiceRegionale
                                        },
                                        richiedente: {
                                            nome: richiedente.nome,
                                            cognome: richiedente.cognome,
                                            codiceFiscale: richiedente.identificativoUtente,
                                            profilo: enumsUtente.profiliUtente.medicoRichiedente,
                                            ruolo: enumsUtente.ruoli.medicoOspedaliero,
                                            aulss: richiedente.aulss,
                                            idProfessionista: richiedente.id
                                        },
                                        anagrafePaziente: {
                                            settingDiProvenienza: {
                                                tipologiaSetting: null,
                                                domicilio: null,
                                                cureDomiciliariAttive: null,
                                                strutturaIntermedia: null,
                                                ospedale: null,
                                                altroSetting: null
                                            },
                                            padre: {
                                                id: null
                                            },
                                            madre: {
                                                id: null
                                            },
                                            tutore: [],
                                            amministratoreDiSostegno: [],
                                            fiduciario: {
                                                id: null
                                            },
                                            datiGenerali: {
                                                identificativoEventoAssistenzialeDiCD: null,
                                                ilPazienteEInformatoDellaDomanda: null
                                            },
                                            mmgPlsDelPaziente: {
                                                id: mmg.id
                                            }
                                        },
                                        aulss: mmg.aulss,
                                        distretto: mmg.distretto,
                                        ambito: mmg.ambito,
                                        provincia: persona.provinciaAssistenza,
                                        tipologia: enumRichiesta.tipologia.SEGNALAZIONE,
                                        valutazioni: []
                                    }
                                    RichiestaADIService.creaRichiesta(richiesta).subscribe((res) => {
                                        if (res?.msg === msgErrore) {
                                            this.apriToasterErrore(res);
                                            nascondiSpinner();
                                            return;
                                        }
                                        this.setState({ nuovaRichiesta: res });
                                        this.setState({ redirectInserimentoRichiesta: true });
                                        nascondiSpinner();
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }

        if (assistito.uuidRichiesta) {
            RichiestaADIService.letturaRichiesta(assistito.uuidRichiesta).subscribe((ric) => {
                if (ric?.msg === msgErrore) {
                    this.apriToasterErrore(ric);
                    nascondiSpinner();
                    return;
                }
                else {
                    if (!Utils.isObjectEmpty(ric.data) && ric.data.stato !== statiRichiesta.annullata.value && ric.data.stato !== statiRichiesta.rifiutata.value) {
                        this.props.apriAnnullamentoRichiesta(assistito.uuidRichiesta, azioniRichiesta.avviaNuovoPreDialogo.label);
                        nascondiSpinner();
                    }
                    else {
                        creaPreDialogo();
                    }
                }
            })
        }
        else {
            creaPreDialogo();
        }


    }

    avviaNuovaRichiesta = (assistito) => {
        mostraSpinner();
        let richiesta = {}


        if (assistito.mmgCodiceFiscale === SessioneUtente.getInstance().idUtente) {
            if (!Utils.isObjectEmpty(assistito)) {
                AnagrafeService.creaPersona(assistito.id).subscribe((persona) => {
                    if (persona?.msg === msgErrore) {
                        this.apriToasterErrore(persona);
                        nascondiSpinner();
                    } else {
                        AnagrafeService.creaMmg(assistito.id).subscribe((mmg) => {
                            if (mmg?.msg === msgErrore) {
                                this.apriToasterErrore(mmg);
                                nascondiSpinner();
                            } else {
                                AnagrafeService.salvaRichiedente(assistito.id).subscribe((richiedente) => {
                                    if (richiedente?.msg === msgErrore) {
                                        this.apriToasterErrore(richiedente);
                                        nascondiSpinner();
                                    } else {
                                        richiesta = {
                                            idAssistito: persona.id,
                                            mmg: {
                                                nome: mmg.nome,
                                                cognome: mmg.cognome,
                                                codiceFiscale: mmg.identificativoUtente,
                                                aulss: mmg.aulss,
                                                idProfessionista: mmg.id,
                                                codiceRegionale: mmg.codiceRegionale
                                            },
                                            richiedente: {
                                                nome: richiedente.nome,
                                                cognome: richiedente.cognome,
                                                codiceFiscale: richiedente.identificativoUtente,
                                                profilo: enumsUtente.profiliUtente.medicoRichiedente,
                                                ruolo: this.getRuolo(enumsUtente.profiliUtente.medicoRichiedente),
                                                aulss: richiedente.aulss,
                                                idProfessionista: richiedente.id
                                            },
                                            anagrafePaziente: {
                                                settingDiProvenienza: {
                                                    tipologiaSetting: null,
                                                    domicilio: null,
                                                    cureDomiciliariAttive: null,
                                                    strutturaIntermedia: null,
                                                    ospedale: null,
                                                    altroSetting: null
                                                },
                                                padre: {
                                                    id: null
                                                },
                                                madre: {
                                                    id: null
                                                },
                                                tutore: [],
                                                amministratoreDiSostegno: [],
                                                fiduciario: {
                                                    id: null
                                                },
                                                datiGenerali: {
                                                    identificativoEventoAssistenzialeDiCD: null,
                                                    ilPazienteEInformatoDellaDomanda: null
                                                },
                                                mmgPlsDelPaziente: {
                                                    id: mmg.id
                                                }
                                            },
                                            aulss: mmg.aulss,
                                            distretto: mmg.distretto,
                                            ambito: mmg.ambito,
                                            tipologia: enumRichiesta.tipologia.CLASSICA,
                                            valutazioni: []
                                        }
                                        RichiestaADIService.creaRichiesta(richiesta).subscribe((res) => {
                                            if (res?.msg === msgErrore) {
                                                this.apriToasterErrore(res);
                                                nascondiSpinner();
                                                return;
                                            }
                                            this.setState({ nuovaRichiesta: res });
                                            this.setState({ redirectInserimentoRichiesta: true });
                                            nascondiSpinner();
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
        }
        else {
            nascondiSpinner();
            this.apriConfermaAvviaNuovaRichiesta(assistito);
        }
    }

    apriConfermaAvviaNuovaRichiesta = (assistito) => {
        this.setState({ apriConfermaAvviaNuovaRichiesta: true, assistito: assistito });
    }

    chiudiConfermaAvviaNuovaRichiesta = () => {
        closeModaleStyle();
        this.setState({ apriConfermaAvviaNuovaRichiesta: false });
    }

    apriModalePresaInCaricoRichiesta = (messaggioModaleDaVisualizzare, linkPropsRedirect, uuidRichiestaSostituzioneRichiedente) => {
        this.setState({ apriModalePresaInCaricoRichiesta: true, messaggioModaleDaVisualizzare: messaggioModaleDaVisualizzare, linkPropsRedirect: linkPropsRedirect, uuidRichiestaSostituzioneRichiedente: uuidRichiestaSostituzioneRichiedente });
    }

    chiudiModalePresaInCaricoRichiesta = () => {
        closeModaleStyle();
        this.setState({ apriModalePresaInCaricoRichiesta: false });
    }

    confermaAvvioNuovaRichiesta = () => {
        mostraSpinner();

        let assistito = _.cloneDeep(this.state.assistito);

        AnagrafeService.creaPersona(assistito.id).subscribe((persona) => {
            if (persona?.msg === msgErrore) {
                this.apriToasterErrore(persona);
                this.chiudiConfermaAvviaNuovaRichiesta();
                nascondiSpinner();
            }
            else {
                AnagrafeService.creaMmg(assistito.id).subscribe((mmg) => {
                    if (mmg?.msg === msgErrore) {
                        this.apriToasterErrore(mmg);
                        this.chiudiConfermaAvviaNuovaRichiesta();
                        nascondiSpinner();
                    }
                    else {
                        let sessioneUtente = SessioneUtente.getInstance();
                        let body = {
                            nome: sessioneUtente.nomeUtente,
                            cognome: sessioneUtente.cognomeUtente,
                            identificativoUtente: sessioneUtente.idUtente,
                            tipoIdentificativoUtente: "CF",
                            aulss: sessioneUtente.ulss,
                            distretto: sessioneUtente.settings.find(el => el.ruolo === enumsUtente.ruoli.mmg || el.ruolo === enumsUtente.ruoli.pediatra)?.distretto
                        }
                        AnagrafeService.salvaRichiedente(assistito.id, body).subscribe((richiedente) => {
                            if (richiedente?.msg === msgErrore) {
                                this.apriToasterErrore(richiedente);
                                this.chiudiConfermaAvviaNuovaRichiesta();
                                nascondiSpinner();
                            }
                            else {
                                let richiesta = {
                                    idAssistito: persona.id,
                                    mmg: {
                                        nome: mmg.nome,
                                        cognome: mmg.cognome,
                                        codiceFiscale: mmg.identificativoUtente,
                                        aulss: mmg.aulss,
                                        idProfessionista: mmg.id,
                                        codiceRegionale: mmg.codiceRegionale
                                    },
                                    richiedente: {
                                        nome: richiedente.nome,
                                        cognome: richiedente.cognome,
                                        codiceFiscale: richiedente.identificativoUtente,
                                        profilo: enumsUtente.profiliUtente.medicoRichiedente,
                                        ruolo: enumsUtente.ruoli.medicoOspedaliero,
                                        aulss: richiedente.aulss,
                                        idProfessionista: richiedente.id
                                    },
                                    anagrafePaziente: {
                                        settingDiProvenienza: {
                                            tipologiaSetting: null,
                                            domicilio: null,
                                            cureDomiciliariAttive: null,
                                            strutturaIntermedia: null,
                                            ospedale: null,
                                            altroSetting: null
                                        },
                                        padre: {
                                            id: null
                                        },
                                        madre: {
                                            id: null
                                        },
                                        tutore: [],
                                        amministratoreDiSostegno: [],
                                        fiduciario: {
                                            id: null
                                        },
                                        datiGenerali: {
                                            identificativoEventoAssistenzialeDiCD: null,
                                            ilPazienteEInformatoDellaDomanda: null
                                        },
                                        mmgPlsDelPaziente: {
                                            id: mmg.id
                                        }
                                    },
                                    aulss: mmg.aulss,
                                    distretto: mmg.distretto,
                                    ambito: mmg.ambito,
                                    provincia: persona.provinciaAssistenza,
                                    tipologia: enumRichiesta.tipologia.CLASSICA,
                                    valutazioni: []
                                }
                                RichiestaADIService.creaRichiesta(richiesta).subscribe((res) => {
                                    if (res?.msg === msgErrore) {
                                        this.apriToasterErrore(res);
                                        this.chiudiConfermaAvviaNuovaRichiesta();
                                        nascondiSpinner();
                                        return;
                                    }
                                    this.setState({ nuovaRichiesta: res });
                                    this.setState({ redirectInserimentoRichiesta: true });
                                    this.chiudiConfermaAvviaNuovaRichiesta();
                                    nascondiSpinner();
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    checkUltimoElementoArray(array, elemento) {
        return array?.indexOf(elemento) === array?.indexOf(array[array?.length - 1]);
    }

    apriToasterErrore = (response) => {
        Utils.toasterFunction({
            status: response?.data?.codice ? response?.data?.codice : response?.status,
            type: "critical",
            text: response?.data?.errore || response?.data?.message
        })
    }

    componentDidMount() {
        if (this.props.statoAvviaNuovaRichiesta || this.props.statoAvviaNuovoPreDialogo) this.recuperaCronologiaRicercaAnagrafiche();
        if (this.props.clickSearchButton) this.recuperaCronologiaRicercaPersone();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.cognome !== this.state.cognome && !Utils.isStringEmptyOrNullOrUndefined(this.state.cognome)) ||
            (prevState.nome !== this.state.nome && !Utils.isStringEmptyOrNullOrUndefined(this.state.nome)) ||
            (prevState.dataNascita !== this.state.dataNascita && !Utils.isStringEmptyOrNullOrUndefined(this.state.dataNascita)))
            this.setState({ codiceFiscaleObbligatorio: false })


        if (prevState.codiceFiscale !== this.state.codiceFiscale && !Utils.isStringEmptyOrNullOrUndefined(this.state.codiceFiscale))
            this.setState({ cognomeNomeDataObbligatori: false })


        if ((prevState.codiceFiscaleObbligatorio !== this.state.codiceFiscaleObbligatorio || prevState.cognomeNomeDataObbligatori !== this.state.cognomeNomeDataObbligatori) &&
            this.state.codiceFiscaleObbligatorio && this.state.cognomeNomeDataObbligatori)
            this.setState({ pulsanteCercaAbilitato: false })

        if ((prevState.codiceFiscaleObbligatorio !== this.state.codiceFiscaleObbligatorio
            || prevState.cognomeNomeDataObbligatori !== this.state.cognomeNomeDataObbligatori
            || prevState.codiceFiscale !== this.state.codiceFiscale
            || prevState.dataNascita !== this.state.dataNascita
            || prevState.nome !== this.state.nome
            || prevState.cognome !== this.state.cognome) &&
            !(this.state.codiceFiscaleObbligatorio && this.state.cognomeNomeDataObbligatori)) {
            if (this.state.codiceFiscaleObbligatorio && Utils.isValidCodiceFiscale(this.state.codiceFiscale)) this.setState({ pulsanteCercaAbilitato: true })

            if (this.state.codiceFiscaleObbligatorio && !Utils.isValidCodiceFiscale(this.state.codiceFiscale)) this.setState({ pulsanteCercaAbilitato: false })

            if (this.state.cognomeNomeDataObbligatori && this.props.clickSearchButton && !Utils.isStringEmptyOrNullOrUndefined(this.state.cognome)) this.setState({ pulsanteCercaAbilitato: true })
            if (this.state.cognomeNomeDataObbligatori && this.props.clickSearchButton && Utils.isStringEmptyOrNullOrUndefined(this.state.cognome)) this.setState({ pulsanteCercaAbilitato: false })

            if (this.state.cognomeNomeDataObbligatori && !this.props.clickSearchButton && !Utils.isStringEmptyOrNullOrUndefined(this.state.nome) && !Utils.isStringEmptyOrNullOrUndefined(this.state.cognome)) this.setState({ pulsanteCercaAbilitato: true })
            if (this.state.cognomeNomeDataObbligatori && !this.props.clickSearchButton && (Utils.isStringEmptyOrNullOrUndefined(this.state.nome) || Utils.isStringEmptyOrNullOrUndefined(this.state.cognome))) this.setState({ pulsanteCercaAbilitato: false })
        }

        //Gestione ricerca assistiti
        if (prevState.ricercaAssistiti !== this.state.ricercaAssistiti && this.state.ricercaAssistiti) {
            this.resetAnagrafeAssistiti();
            this.resetListaAssistiti();
            this.setRisultatiCaricati(false);
            this.setAnagrafeAssistitiCaricata(false);
            this.setUltimeRicercheAnagraficheCaricate(false);
            this.setUltimeRicerchePersoneCaricate(false);
            this.ricercaAnagrafiche();
        }
        if (prevState.listaAssistiti !== this.state.listaAssistiti && this.state.anagrafeAssistitiCaricata && this.state.listaAssistiti?.length === this.state.anagrafeAssistiti?.length && this.state.ricercaAssistiti && (this.props.statoAvviaNuovaRichiesta || this.props.statoAvviaNuovoPreDialogo)) {
            this.setRisultatiCaricati(true);
            nascondiSpinner();
        }
        if (prevState.anagrafeAssistitiCaricata !== this.state.anagrafeAssistitiCaricata && this.state.anagrafeAssistitiCaricata && this.state.anagrafeAssistiti?.length === 0) {
            nascondiSpinner();
        }
        if ((prevState.cognome !== this.state.cognome || prevState.nome !== this.state.nome || prevState.dataNascita !== this.state.dataNascita || prevState.codiceFiscale !== this.state.codiceFiscale) && this.state.ricercaAssistiti) {
            this.resetAnagrafeAssistiti();
            this.setricercaAssistiti(false);
        }
        if (prevState.anagrafeAssistitiCaricata !== this.state.anagrafeAssistitiCaricata && this.state.anagrafeAssistitiCaricata && this.state.anagrafeAssistiti?.length === 0) {
            this.setRisultatiCaricati(true);
        }

        //Gestione spinner
        if (prevState.ultimeRicerchePersoneCaricate !== this.state.ultimeRicerchePersoneCaricate && this.state.ultimeRicerchePersoneCaricate) {
            nascondiSpinner();
        }
        if (prevState.ultimeRicercheAnagraficheCaricate !== this.state.ultimeRicercheAnagraficheCaricate && this.state.ultimeRicercheAnagraficheCaricate) {
            nascondiSpinner();
        }

        //Gestione ricarica risultati
        if ((prevState.apriSubentroRichiesta && !this.state.apriSubentroRichiesta) ||
            (prevState.apriModalePresaInCaricoRichiesta && !this.state.apriModalePresaInCaricoRichiesta)) {
            this.ricaricaRisultati();
        }
    }

    getOnFocusCognomeNomeDataObbligatori() {
        this.setState({ cognomeNomeDataObbligatori: true, codiceFiscaleObbligatorio: false, codiceFiscale: "" });
    }

    getOnFocusCodiceFiscaleObbligatorio() {
        this.setState({
            codiceFiscaleObbligatorio: true,
            cognomeNomeDataObbligatori: false,
            nome: "",
            cognome: "",
            dataNascita: ""
        });
    }

    cercaAssistito() {
        this.resetAnagrafeAssistiti();
        this.resetListaAssistiti();
        this.setRisultatiCaricati(false);
        this.setAnagrafeAssistitiCaricata(false);
        this.setUltimeRicercheAnagraficheCaricate(false);
        this.setUltimeRicerchePersoneCaricate(false);
        this.setricercaAssistiti(true);
    }

    getColonneTabella() {
        if (AuthUtils.hasRuoloMedicoRichiedenteAndMedicoOspedaliero()) {
            return this.state.colonneRisultatiMedicoOspedaliero;
        }
        else {
            return this.state.colonneRisultati;
        }
    }

    getTitolo() {
        if (this.props.statoAvviaNuovaRichiesta) {
            return "Avvia nuova richiesta";
        }
        else if (this.props.statoAvviaNuovoPreDialogo) {
            return "Avvia nuovo pre-dialogo";
        }
        else {
            return "Ricerca assistito";
        }
    }

    apriSubentroRichiesta = (uuidRichiesta) => {
        this.setState({ uuidRichiestaDaSubentrare: uuidRichiesta, apriSubentroRichiesta: true });
    }

    chiudiSubentroRichiesta = () => {
        this.setState({ apriSubentroRichiesta: false });
    }

    ricaricaRisultati = () => {
        if (this.state.listaAssistiti?.length > 0) {
            this.setState({ listaAssistiti: [], ultimeRicerchePersoneCaricate: false, ultimeRicercheAnagraficheCaricate: false }, () => this.ricercaAnagrafiche());
        }
        else {
            if (this.props.statoAvviaNuovaRichiesta || this.props.statoAvviaNuovoPreDialogo) {
                this.setState({ ultimeRicerche: [] }, () => this.recuperaCronologiaRicercaAnagrafiche());
            }
            else {
                this.setState({ ultimeRicerchePersone: [] }, () => this.recuperaCronologiaRicercaPersone());
            }
        }
    }

    sostituzioneRichiedente = () => {
        mostraSpinner();
        RichiestaADIService.sostituzioneMedicoRichiedenti(this.state.uuidRichiestaSostituzioneRichiedente).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                this.chiudiModalePresaInCaricoRichiesta();
                if (response?.msgCode && response.msgCode.startsWith("WARNING_WD")) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "informational",
                        text: response?.msgCode
                    });
                    nascondiSpinner();
                } else if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                    });
                    nascondiSpinner();
                    return;
                }
            }

            nascondiSpinner();
            this.chiudiModalePresaInCaricoRichiesta();
        });
    }

    chiudiModaleAvviaNuovaRichiestaRicercaAssistito = () => {
        if (this.props.statoAvviaNuovoPreDialogo) {
            this.props.impostaAvviaNuovoPreDialogo(false)
        }
        this.props.impostaAvviaNuovaRichiesta(false)
    }

    render() {
        let labelAnnullaRichiesta = this.props.clickSearchButton ? "Chiudi" : "Annulla";

        let footerModalePresaInCarico = (
            <>
                <Button
                    id={"prendiInCaricoRichiesta"}
                    className={"btn btn-adi btnGreenOutline"}
                    onClick={() => this.sostituzioneRichiedente()}
                    text={"Prendi in carico"}>
                </Button>
                <Link to={this.state.linkPropsRedirect}>
                    <Button
                        id={"visualizzaRichiesta"}
                        className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                        onClick={() => { this.chiudiModalePresaInCaricoRichiesta() }}
                        text={"Visualizza"}
                    />
                </Link>
            </>
        )

        if(this.state.redirectToHome) return  <Redirect to={{pathname: routepath.home}}/>

        return (
           
            this.state.redirectInserimentoRichiesta ? <Redirect to={{
                pathname: routepath.inserimento_richiesta.concat(routepath.compila_scheda_paziente),
                uuidRichiesta: this.state.nuovaRichiesta?.id,
                tipologia: this.state.nuovaRichiesta?.tipologia,
                stato: this.state.nuovaRichiesta?.stato,
                showOnlyRequiredFields: true,
                showMultiCompilationPages: false
            }} /> :
                <>
                    <div className={styleRicerca.foregroundMode.concat(" position-relative px-5 pt-5")}>
                        <div>
                            <div className="row">
                                <div className="col-lg-9">
                                    <h1>{Utils.getTitoloRicercaAssistito(this.props.location.pathname)}</h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <Button
                                        id={"annullaRichiesta"}
                                        text={<span> <BsIcon.ArrowUpShort width={24} height={24} />{labelAnnullaRichiesta}</span>}
                                        // className={styleRicerca.btnAnnullaRicercaAssistito.concat(" float-right")}
                                        className={"btn btn-adi btnGreenOutline float-right"}
                                        style={{ outline: "none" }}
                                        onClick={() =>  this.setState({ redirectToHome: true })}
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-9 mb-3">
                                    <h1>{this.getTitolo()}</h1>
                                </div>
{/*                                <div className="col-lg-3">
                                    <span className={styleRicerca.required.concat(" float-right")}><strong>Campo obbligatorio</strong></span>
                                </div>*/}
                            </div>
                            <div className="row text-align-center">
                                <div className="col" onFocus={() => this.getOnFocusCognomeNomeDataObbligatori()}>
                                    <Input
                                        placeholder="Inserisci cognome assistito"
                                        field="cognome"
                                        onChange={this.handleAggiornaFiltriAssistito}
                                        id="cognome"
                                        value={this.state.cognome}
                                        label="Cognome"
                                        required={this.state.cognomeNomeDataObbligatori}
                                        infoText="Campo obbligatorio"
                                    />
                                </div>
                                <div className="col" onFocus={() => this.getOnFocusCognomeNomeDataObbligatori()}>
                                    <Input
                                        placeholder="Inserisci nome assistito"
                                        field="nome"
                                        onChange={this.handleAggiornaFiltriAssistito}
                                        id="nome"
                                        value={this.state.nome}
                                        label="Nome"
                                        required={this.state.cognomeNomeDataObbligatori && !this.props.clickSearchButton && styleRicerca.required}
                                    />
                                </div>
                                <div className="col" onFocus={() => this.getOnFocusCognomeNomeDataObbligatori()}>
                                    <Calendar
                                        id={"dataNascita"}
                                        value={this.state.dataNascita}
                                        field={"dataNascita"}
                                        onChange={(event) => this.handleAggiornaFiltriAssistito("dataNascita", event.currentTarget.value)}
                                        errorMessage={"Data non valida"}
                                        error={false}
                                        label={"Data di nascita"}
                                    >
                                    </Calendar>
                                </div>
                                <div className="col-auto pt-2">
                                    <span><i>Oppure</i></span>
                                </div>
                                <div className="col" onFocus={() => this.getOnFocusCodiceFiscaleObbligatorio()}>
                                    <Input
                                        placeholder="Inserisci codice fiscale assistito"
                                        field="codiceFiscale"
                                        onChange={this.handleAggiornaFiltriAssistito}
                                        id="codiceFiscale"
                                        value={this.state.codiceFiscale}
                                        label="Codice fiscale"
                                        required={this.state.codiceFiscaleObbligatorio}
                                        infoText="Campo obbligatorio"
                                    />
                                </div>
                                <div className="col-auto">
                                    <Button
                                        id={"cercaRichiesta"}
                                        text="Cerca"
                                        className={"btn btn-adi btnGreenOutline float-right"}
                                        style={{ outline: "none" }}
                                        disabled={!this.state.pulsanteCercaAbilitato}
                                        onClick={() => this.cercaAssistito()}
                                    />
                                </div>
                            </div>
                        </div>
                        {this.state.ultimeRicercheAnagraficheCaricate && (this.props.statoAvviaNuovaRichiesta || this.props.statoAvviaNuovoPreDialogo) &&
                            <div className="pb-3">
                                <hr className="mt-0"></hr>
                                <span className={styleRicerca.labelRisultati}>Ultime {this.state.numeroUltimeRicercheAnagrafiche} ricerche</span>
                                <Table
                                    cols={this.getColonneTabella()}
                                    rows={this.state.ultimeRicerche}
                                    emptyMsg={"La ricerca effettuata non ha prodotto risultati."}
                                    lengthEditable={false}
                                    totalElements={this.state.ultimeRicerche?.length}
                                    bigTable={true}
                                    ricercaAssistito
                                    tableLenght={this.state.ultimeRicerche?.length}
                                    onClickAvviaNuovaRichiesta={this.avviaNuovaRichiesta}
                                    onClickAvviaNuovoPreDialogo={this.avviaNuovoPreDialogo}
                                    apriSubentroRichiesta={this.apriSubentroRichiesta}
                                    apriModalePresaInCaricoRichiesta={this.apriModalePresaInCaricoRichiesta}
                                    nascondiPaginazione={true}
                                    homePage
                                />
                            </div>}
                        {this.state.ultimeRicerchePersoneCaricate && this.props.clickSearchButton &&
                            <div className="pb-3">
                                <hr></hr>
                                <span className={styleRicerca.labelRisultati}>Ultime {this.state.numeroUltimeRicerchePersone} ricerche</span>
                                <Table
                                    cols={this.getColonneTabella()}
                                    rows={this.state.ultimeRicerchePersone}
                                    emptyMsg={"La ricerca effettuata non ha prodotto risultati."}
                                    lengthEditable={false}
                                    totalElements={this.state.ultimeRicerchePersone?.length}
                                    bigTable={true}
                                    ricercaAssistito
                                    tableLenght={this.state.ultimeRicerchePersone?.length}
                                    onClickAvviaNuovaRichiesta={this.avviaNuovaRichiesta}
                                    onClickAvviaNuovoPreDialogo={this.avviaNuovoPreDialogo}
                                    apriSubentroRichiesta={this.apriSubentroRichiesta}
                                    apriModalePresaInCaricoRichiesta={this.apriModalePresaInCaricoRichiesta}
                                    nascondiPaginazione={true}
                                    homePage
                                />
                            </div>}
                        {this.state.risultatiCaricati &&
                            <div className="pb-3">
                                <hr></hr>
                                <span className={styleRicerca.labelRisultati}>Risultati</span>
                                <Table
                                    cols={this.getColonneTabella()}
                                    rows={this.state.listaAssistiti}
                                    emptyMsg={this.props.statoAvviaNuovaRichiesta || this.props.statoAvviaNuovoPreDialogo || !AuthUtils.hasUtenteRuoloMedicoRichiedente() ? "La ricerca effettuata non ha prodotto risultati." : "La ricerca non ha prodotti risultati. Selezionare \"Avvia nuova richiesta\" per avviare una nuova richiesta."}
                                    lengthEditable={false}
                                    totalElements={this.state.listaAssistiti?.length}
                                    bigTable={true}
                                    ricercaAssistito
                                    tableLenght={this.state.listaAssistiti?.length}
                                    nascondiPaginazione={true}
                                    onClickAvviaNuovaRichiesta={this.avviaNuovaRichiesta}
                                    onClickAvviaNuovoPreDialogo={this.avviaNuovoPreDialogo}
                                    apriSubentroRichiesta={this.apriSubentroRichiesta}
                                    apriModalePresaInCaricoRichiesta={this.apriModalePresaInCaricoRichiesta}
                                    homePage
                                />
                            </div>}

                        {this.state.apriSubentroRichiesta && <SubentroRichiesta chiudiSubentroRichiesta={this.chiudiSubentroRichiesta} uuidRichiesta={this.state.uuidRichiestaDaSubentrare} />}

                        {this.state.apriConfermaAvviaNuovaRichiesta &&
                            <ModalHelper
                                id={"confermaAvviaNuovaRichiesta"}
                                title={"Avvio nuova richiesta"}
                                titleClassName={" modal-header d-inline text-center backgroundGreen"}
                                body={"Il paziente non è un tuo assistito, vuoi avviare comunque la richiesta?"}
                                bodyClassName={"modal-body d-flex align-items-center align-self-center f-weight-600 mx-auto text-center"}
                                containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                                contentClassName={"modal-content"}
                                confirmBtnFunction={this.confermaAvvioNuovaRichiesta}
                                cancelBtnFunction={this.chiudiConfermaAvviaNuovaRichiesta}
                                footerClassName={"modal-footer justify-content-around"}
                                confirmBtnClassName={"btn btn-adi btnGreenOutline"}
                                cancelBtnClassName={"btn btn-adi btnWhiteOutlineBorderGreen"}
                                confirmBtnText={"Conferma"}
                                cancelBtnText={"Chiudi"}
                            />}

                        {this.state.apriModalePresaInCaricoRichiesta &&
                            <ModalHelper
                                id={"modalePresaInCaricoRichiesta"}
                                title={"Prendi in carico richiesta"}
                                titleClassName={"modal-header d-inline text-center backgroundGreen"}
                                body={this.state.messaggioModaleDaVisualizzare}
                                bodyClassName={"modal-body d-flex align-items-center align-self-center f-weight-600 mx-auto text-center"}
                                containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                                contentClassName={"modal-content"}
                                footerClassName={"modal-footer justify-content-around"}
                                footer={footerModalePresaInCarico}
                            />}
                    </div>
                </>
        )
    }
}

ricercaAssistiti.contextType = Context;
ricercaAssistiti.propTypes = propTypes;

export default withRouter(ricercaAssistiti);
