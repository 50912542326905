import React, {Component} from "react";
import RichiestaADIService from "../../service/RichiestaADIService";
import {mostraSpinner, nascondiSpinner} from "../../App";
import Utils from "../../utils/Utils";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import routepath from "../../utils/route/route-path.json";
import {concatAll, flatMap, map, mergeMap} from "rxjs/operators";
import AnagrafeService from "../../service/AnagrafeService";
import * as _ from "lodash";
import {asyncScheduler, BehaviorSubject, of, scheduled, Subject} from "rxjs";
import enumIndirizzo from "../../enum/enumIndirizzo.json";
import enumContatto from "../../enum/enumContatto.json";
import {defaultAnagrafePaziente} from "../../utils/SessionRequest";
import enumPaginaCompila from "../../enum/enumPaginaCompila.json";
import VisualizzaRiepilogo, {getModalitaRiepilogo} from "../visualizzaRiepilogo/VisualizzaRiepilogo";
import {SessioneUtente} from "web-client-archetype";
import WebSocketHelper from "../../websocket/WebSocketHelper";
import HistorySessionRequest from "../../utils/HistorySessionRequest";
import enumsUtente from '../../enum/enumsUtente.json'
import Breadcrumb from '../breadcrumb/Breadcrumb';
import AccordionHelper from "../../service/AccordionHelper";
import {isSomeFieldIdWriteable} from "../roleBasedComponents/RoleBasedComponents";
import {getValutazionePrecedente} from "../home/HomeUtils";
import CompilaRichiestaCampiObbligatori from "../compilaRichiestaCampiObbligatori/CompilaRichiestaCampiObbligatori";
import Trackbar from "../trackbar/Trackbar";
import enumRiepilogo from "../../enum/enumRiepilogo.json";
import CompilaQuestionario from "../compilaQuestionario/CompilaQuestionario";
import {getQuestionario} from "../../utils/QuestionariUtils";
import enumRichiesta from "../../enum/enumRichiesta.json";
import StatoValutazioneEComponentiDelTeam from "../statoValutazioneEComponentiDelTeam/StatoValutazioneEComponentiDelTeam";
import ContainerPaginaCompila from "../containerPaginaCompila/ContainerPaginaCompila";
import * as $ from "jquery";

const propTypes = {
    uuidRichiesta: PropTypes.any,
    pageState: PropTypes.string,
    pageName: PropTypes.string,
    forcedModalitaRiepilogo: PropTypes.string,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    firstPageName: PropTypes.string,
    richiesta: PropTypes.object,
    richiestaMedico: PropTypes.object,
    persone: PropTypes.object,
    pathname: PropTypes.string,
    valutazionePrecedente: PropTypes.string,
    showOnlyRequiredFields: PropTypes.bool,
    previousPageOnlyRequiredFields: PropTypes.bool,
    lastPageNumber: PropTypes.number,
    tipoQuestionario: PropTypes.string,
    pathRichiestaQuestionario: PropTypes.string,
    sessoPaziente: PropTypes.any,
    labelSezioneObbligatoria: PropTypes.bool,
    infoRichiesta: PropTypes.object,
    redirectPage: PropTypes.string,
    obbligatorieta: PropTypes.object,
    scrollIntoAccordionAfterRedirect: PropTypes.bool,
    disabilitato: PropTypes.bool,
    tornaIndietro: PropTypes.string,
    showMultiCompilationPages: PropTypes.bool
}

export const showMessageAulssDistrettoDifferences = (richiesta, persone) => {
    let isConditionValid = false;

    if (richiesta != null) {
        const aulssAssistito = persone?.[richiesta.idAssistito]?.aulssResidenza ?? richiesta.aulss;
        const distrettoAssistito = persone?.[richiesta.idAssistito]?.distrettoResidenza ?? richiesta.distretto;
        isConditionValid = _.isEqual(richiesta.stato, enumRichiesta.stato.INVIATA)
            && (!_.isEqual(aulssAssistito, richiesta.aulss) || !_.isEqual(distrettoAssistito, richiesta.distretto));
    }

    return isConditionValid && <div className={"row font-italic justify-content-center m-0"}
                                    style={{fontSize: "18px", color: "#D83D3D"}}>
        La richiesta verr&agrave; presa in carico all&apos;esterno dell&apos;applicativo, poich&egrave; il Distretto
        di residenza &egrave; diverso dal Distretto di assistenza
    </div>
}

export default class InserimentoRichiesta extends Component {

    static salvaBozzaSubject = new Subject();
    static infoRichiestaSubject = new BehaviorSubject();
    static redirectToAnagrafeRichiedenteSubject = new Subject();

    state = {
        richiesta: null,
        infoRichiesta: null,
        richiestaMedico: null,
        persone: null,
        error: false,
        redirectToAggiungiValutatore: false,
        gotoAnagraficaRichiedente: false,
        listaCodiciFiscaliUtentiOnline: []
    }

    componentDidMount() {
        this.montaComponente(this.props.uuidRichiesta);
        InserimentoRichiesta.redirectToAnagrafeRichiedenteSubject.subscribe(() => this.gotoAnagraficaRichiedente());
    }

    montaComponente = (uuidRichiesta) => {
        let self = this;
        $(document).ready(function () {
            if (self.props.scrollIntoAccordionAfterRedirect) {
                if (self.props.pageName === enumPaginaCompila.VISUALIZZA_RIEPILOGO ||
                    self.props.pageName === enumPaginaCompila.VERIFICA_VALUTAZIONE ||
                    self.props.pageName === enumPaginaCompila.VISUALIZZA_VALUTAZIONE_MULTIDIMENSIONALE ||
                    self.props.pageName === enumPaginaCompila.ACCEDI_VALUTAZIONE_MULTIDIMENSIONALE ||
                    self.props.pageName === enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE) {
                    !self.controlloSubAccordionValido() ? AccordionHelper.worningMessage() : AccordionHelper.onScrollIntoAccordion(AccordionHelper.idSelectedAccordions);
                } else {
                    AccordionHelper.onScrollIntoAccordion(AccordionHelper.idSelectedAccordions, false);
                }
            }
        });

        if (this.props.richiesta != null) {
            const richiesta = this.props.persone != null ? this.mapRichiestaAssistito(this.props.richiesta, _.cloneDeep(this.props.persone)) : this.props.richiesta;
            this.setState({richiesta: richiesta, persone: this.props.persone});
            return;
        }

        mostraSpinner();
        // se il pageState = "V" (fase valutazione) e readOnly = false (inserimento/modifica valutazione) e valutazionePrecedente == NULL
        // il sistema prova a recuperare le informazioni dal fascicolo sanitario elettronico (solo per il primo accesso del valutatore)
        if (this.isRichiestaDaAggiornareFser()) {
            RichiestaADIService.aggiornaRichiestaDaFser(uuidRichiesta).subscribe(() => {
                this.chiamaRecuperaRichiesta(uuidRichiesta);
            })
        } else {
            this.chiamaRecuperaRichiesta(uuidRichiesta);
        }
    }

    controlloSubAccordionValido() {
        const elemSelMenu = this.props.richiesta?.[AccordionHelper.idSelectedAccordions];
        for (var k in elemSelMenu) {
            let elemSubMenu = elemSelMenu[k]
            for (var s in elemSubMenu) {
                if (elemSubMenu[s] !== null) {
                    return true;
                }else{
                    return false;
                }
            }
        }
    }
    

    isRichiestaDaAggiornareFser() {
        const isFaseValutazione = Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState);
        const valutazionePrecedente = (this.props.richiesta != null &&
            getValutazionePrecedente(_.cloneDeep(this.props.richiesta))) || this.props.valutazionePrecedente;
        return isFaseValutazione && !this.props.readOnly && valutazionePrecedente == null;
    }

    chiamaRecuperaRichiesta(uuidRichiesta) {
        this.recuperaRichiesta(uuidRichiesta)
            .pipe(mergeMap(data => {
                if (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) {
                    let obs = [];

                    if (data[0].stato !== 'BOZZA' && data[0].stato !== 'INVIATA') {
                        obs = this.recuperaStoricoListaCampi(uuidRichiesta, null)
                            .pipe(map(hdata => {
                                HistorySessionRequest.setData(hdata, data[0]);
                                return hdata;
                            }));
                        obs = scheduled([
                            obs,
                            this.contaCampiOpzionali(uuidRichiesta).pipe(map(mappaCountOptionalAccordionFields => {
                                AccordionHelper.setMappaCountOptionalAccordionFields(mappaCountOptionalAccordionFields, true);
                                return mappaCountOptionalAccordionFields;
                            }))
                        ], asyncScheduler).pipe(concatAll());
                    }

                    if (SessioneUtente.getInstance().settings.map(setting => setting.ruolo).includes(enumsUtente.ruoli.infermiere)) {
                        obs = scheduled([
                            obs,
                            this.recuperaRichiestaMediciConfermati(uuidRichiesta)
                                .pipe(map(richiestaMedico => {
                                    this.setState({richiestaMedico});
                                    return richiestaMedico;
                                }))
                        ], asyncScheduler).pipe(concatAll());
                    }
                    return obs;
                }
                return of(data);
            }))
            .subscribe({
                next: data => data,
                complete: () => {
                    WebSocketHelper.onMessageListener();
                    WebSocketHelper.setIdRichiesta(this.state.richiesta?.id ?? null);
                    WebSocketHelper.utenteAutenticatoSubject.subscribe(() => {
                        console.log("Lista Utenti Loggati Ins. Richiesta", WebSocketHelper.utentiLoggati);
                        this.setState({listaCodiciFiscaliUtentiOnline: _.cloneDeep(WebSocketHelper.utentiLoggati)});
                    });
                    WebSocketHelper.utenteDisconnessoSubject.subscribe(flag => {
                        if (flag) this.setState({listaCodiciFiscaliUtentiOnline: WebSocketHelper.utentiLoggati});
                    });
                    InserimentoRichiesta.infoRichiestaSubject.subscribe((infoRichiesta) => {
                        if (infoRichiesta != null && this.props.uuidRichiesta === infoRichiesta.idRichiesta)
                            this.setState({infoRichiesta});
                    });

                    nascondiSpinner();

                    AccordionHelper.setLastSurveyCompiled(null);
                    AccordionHelper.resetMappaVariazionePathDecodedValue();
                },
                error: err => {
                    console.error(err);
                    nascondiSpinner();
                    AccordionHelper.setLastSurveyCompiled(null);
                }
            });
    }

    recuperaRichiesta(uuidRichiesta) {
        return RichiestaADIService.letturaRichiesta(uuidRichiesta)
            .pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        if (response?.status === 404) {
                            this.setState({error: true}, () => {
                                nascondiSpinner();
                                AccordionHelper.setLastSurveyCompiled(null);
                            });
                        } else {
                            Utils.toasterFunction({
                                status: response?.data?.codice ? response?.data?.codice : response?.status,
                                type: "critical",
                                text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                            });
                        }
                    }
                    return null;
                } else {
                    if (RichiestaADIService.richiestaRicercata == null
                        || window.location.href.includes(routepath.completa_valutazione_multidimensionale)) {
                        RichiestaADIService.richiestaRicercata = _.cloneDeep(response.data);
                    }
                    return [response.data, this.estraiUUIDPersone(response.data)];
                }
            }))
            .pipe(flatMap((dataArray) => {
                if (!dataArray?.[0]) return of(null);

                let richiesta = dataArray[0];
                let listaUUIDPersone = dataArray[1];
                let queryParams = {};
                queryParams.vista = "totale";
                queryParams.filtro = "id_persona~~" + listaUUIDPersone.join("|");
                return AnagrafeService.getPersone(queryParams).pipe(map((data) => {
                    if (data) {
                        let map = _.keyBy(data, "id");
                        let richiestaAggiornata = this.mapRichiestaAssistito(richiesta, _.cloneDeep(map));
                        let listaUtentiCfOnline = _.cloneDeep(WebSocketHelper.utentiLoggati) ?? [];
                        if (WebSocketHelper.isWebSocketConnected()) {
                            WebSocketHelper.utentiLoggati.push(SessioneUtente.getInstance().idUtente);
                            WebSocketHelper.utentiLoggati = Array.from(new Set(WebSocketHelper.utentiLoggati));
                            listaUtentiCfOnline = WebSocketHelper.utentiLoggati;
                        }

                        this.setState({
                            richiesta: richiestaAggiornata,
                            persone: map,
                            listaCodiciFiscaliUtentiOnline: listaUtentiCfOnline
                        });
                    }
                    dataArray.push(data);
                    return dataArray;
                }));
            }));
    }

    mapRichiestaAssistito = (richiesta, mappaPersone) => {
        if (!richiesta.hasOwnProperty("anagrafePaziente"))
            richiesta.anagrafePaziente = {};
        let anagrafePaziente = _.cloneDeep(richiesta.anagrafePaziente);

        Utils.resetObject(defaultAnagrafePaziente);

        let assistito = mappaPersone[richiesta.idAssistito];
        if (!Utils.isObjectNull(assistito)) {
            anagrafePaziente.datiGenerali = defaultAnagrafePaziente.datiGenerali;
            anagrafePaziente.datiGenerali.identificativoEventoAssistenzialeDiCD = richiesta.id;
            anagrafePaziente.datiGenerali.ilPazienteEInformatoDellaDomanda = richiesta.anagrafePaziente?.datiGenerali?.ilPazienteEInformatoDellaDomanda !== undefined
                ? richiesta.anagrafePaziente?.datiGenerali?.ilPazienteEInformatoDellaDomanda : null;
            Object.keys(assistito).forEach(key => {
                if (key === "lingue") {
                    this.getLingua(anagrafePaziente.datiGenerali, assistito[key]);
                } else if (key === "contatti" && assistito[key].length > 0) {
                    this.setContatto(assistito[key], enumContatto.contatto.telefono, anagrafePaziente.datiGenerali);
                    this.setContatto(assistito[key], enumContatto.contatto.cellulare, anagrafePaziente.datiGenerali);
                    this.setContatto(assistito[key], enumContatto.contatto.email, anagrafePaziente.datiGenerali);
                } else if (key in defaultAnagrafePaziente.datiGenerali)
                    anagrafePaziente.datiGenerali[key] = assistito[key];
            });

            anagrafePaziente.residenza = defaultAnagrafePaziente.residenza;
            anagrafePaziente.domicilio = defaultAnagrafePaziente.domicilio;
            anagrafePaziente.domicilioDiCura = defaultAnagrafePaziente.domicilioDiCura;
            if (assistito.indirizzi.length > 0) {
                let residenza = this.getRecapito("residenza", assistito.indirizzi, enumIndirizzo.indirizzo.residenza);
                if (residenza !== null) {
                    anagrafePaziente.residenza = {
                        ...residenza,
                        distretto: assistito.distrettoResidenza ?? residenza.distretto
                    };
                }

                let domicilio = this.getRecapito("domicilio", assistito.indirizzi, enumIndirizzo.indirizzo.domicilio);
                if (domicilio !== null)
                    anagrafePaziente.domicilio = domicilio;

                let domicilioDiCura = this.getRecapito("domicilioDiCura", assistito.indirizzi, enumIndirizzo.indirizzo.altro);
                if (domicilioDiCura !== null)
                    anagrafePaziente.domicilioDiCura = domicilioDiCura;
            }
            anagrafePaziente.residenza.residenzaDiversaDaDomicilio = richiesta.anagrafePaziente?.residenza?.residenzaDiversaDaDomicilio ?? null;
            anagrafePaziente.domicilio.domicilioDiversoDaDomicilioCure = richiesta.anagrafePaziente?.domicilio?.domicilioDiversoDaDomicilioCure ?? null;

            anagrafePaziente.esenzioni = defaultAnagrafePaziente.esenzioni;
            if (assistito.esenzioni?.length > 0) {
                anagrafePaziente.esenzioni = [];
                assistito.esenzioni.forEach(esenzione => {
                    Object.keys(esenzione).forEach(key => {
                        if (!(key in esenzione))
                            delete esenzione[key];
                    });
                    anagrafePaziente.esenzioni.push(esenzione);
                });
            }
            let mascheraModificabilitaAssistito = _.cloneDeep(assistito.mascheraModificabilita);
            if (!Utils.isObjectNull(mascheraModificabilitaAssistito)) {
                mascheraModificabilitaAssistito.indirizzi = mascheraModificabilitaAssistito.indirizzi.map(i => {
                    if (i.idElemento === anagrafePaziente.residenza?.idElemento
                        && !Utils.isStringEmptyOrNullOrUndefined(assistito.distrettoResidenza)) {
                        i.distretto = mascheraModificabilitaAssistito.distrettoResidenza;
                    }
                    return i;
                });
            }
            anagrafePaziente.datiGenerali.mascheraModificabilita = mascheraModificabilitaAssistito;
        }

        anagrafePaziente.mmgPlsDelPaziente = defaultAnagrafePaziente.mmgPlsDelPaziente;
        let mmgPlsPaziente = mappaPersone[richiesta.anagrafePaziente.mmgPlsDelPaziente?.id];
        if (!Utils.isObjectNull(mmgPlsPaziente)) {
            Object.keys(mmgPlsPaziente).forEach(key => {
                if (key === "contatti" && mmgPlsPaziente[key].length > 0) {
                    this.setContatto(mmgPlsPaziente[key], enumContatto.contatto.telefono, anagrafePaziente.mmgPlsDelPaziente);
                    this.setContatto(mmgPlsPaziente[key], enumContatto.contatto.cellulare, anagrafePaziente.mmgPlsDelPaziente);
                    this.setContatto(mmgPlsPaziente[key], enumContatto.contatto.email, anagrafePaziente.mmgPlsDelPaziente);
                } else if (key in defaultAnagrafePaziente.mmgPlsDelPaziente)
                    anagrafePaziente.mmgPlsDelPaziente[key] = mmgPlsPaziente[key];
            });
            anagrafePaziente.mmgPlsDelPaziente.mascheraModificabilita = mmgPlsPaziente.mascheraModificabilita;
            if (!Utils.isObjectNull(richiesta?.mmg)) richiesta.mmg.id = mmgPlsPaziente.id;
        }

        anagrafePaziente.padre = defaultAnagrafePaziente.padre;
        let padre = mappaPersone[richiesta.anagrafePaziente.padre?.id];
        if (!Utils.isObjectNull(padre)) {
            anagrafePaziente.padre.isPadreIndicatoComeRiferimentoPerPaziente = richiesta.anagrafePaziente.padre?.isPadreIndicatoComeRiferimentoPerPaziente;
            Object.keys(padre).forEach(key => {
                if (key === "indirizzi" && padre[key].length > 0) {
                    this.setIndirizzo(padre[key], anagrafePaziente.padre);
                } else if (key === "contatti" && padre[key].length > 0) {
                    this.setContatto(padre[key], enumContatto.contatto.telefono, anagrafePaziente.padre);
                    this.setContatto(padre[key], enumContatto.contatto.cellulare, anagrafePaziente.padre);
                    this.setContatto(padre[key], enumContatto.contatto.email, anagrafePaziente.padre);
                } else if (key === "lingue")
                    this.getLingua(anagrafePaziente.padre, padre[key]);
                else if (key in defaultAnagrafePaziente.padre)
                    anagrafePaziente.padre[key] = padre[key];
            });
        }

        anagrafePaziente.madre = defaultAnagrafePaziente.madre;
        let madre = mappaPersone[richiesta.anagrafePaziente.madre?.id];
        if (!Utils.isObjectNull(madre)) {
            anagrafePaziente.madre.isMadreIndicataComeRiferimentoPerPaziente = richiesta.anagrafePaziente.madre?.isMadreIndicataComeRiferimentoPerPaziente;
            Object.keys(madre).forEach(key => {
                if (key === "indirizzi" && madre[key].length > 0) {
                    this.setIndirizzo(madre[key], anagrafePaziente.madre);
                } else if (key === "contatti" && madre[key].length > 0) {
                    this.setContatto(madre[key], enumContatto.contatto.telefono, anagrafePaziente.madre);
                    this.setContatto(madre[key], enumContatto.contatto.cellulare, anagrafePaziente.madre);
                    this.setContatto(madre[key], enumContatto.contatto.email, anagrafePaziente.madre);
                } else if (key === "lingue")
                    this.getLingua(anagrafePaziente.madre, madre[key]);
                else if (key in defaultAnagrafePaziente.madre)
                    anagrafePaziente.madre[key] = madre[key];
            });
        }

        anagrafePaziente.tutore = defaultAnagrafePaziente.tutore;
        if (richiesta.anagrafePaziente?.tutore) {
            anagrafePaziente.tutore = [];
            richiesta.anagrafePaziente.tutore.forEach(tutore => {
                let tutoreRecuperato = mappaPersone[tutore.id];
                if (!Utils.isObjectNull(tutoreRecuperato)) {
                    let obj = {};
                    Object.keys(tutoreRecuperato).forEach(key => {
                        if (key === "indirizzi" && tutoreRecuperato[key].length > 0) {
                            this.setIndirizzo(tutoreRecuperato[key], obj);
                        } else if (key === "contatti" && tutoreRecuperato[key].length > 0) {
                            this.setContatto(tutoreRecuperato[key], enumContatto.contatto.telefono, obj);
                            this.setContatto(tutoreRecuperato[key], enumContatto.contatto.cellulare, obj);
                            this.setContatto(tutoreRecuperato[key], enumContatto.contatto.email, obj);
                        } else if (key === "lingue")
                            this.getLingua(obj, tutoreRecuperato[key]);
                        else if (key === "id" || key === "nome" || key === "cognome" || key === "identificativoUtente")
                            obj[key] = tutoreRecuperato[key];
                    });
                    anagrafePaziente.tutore.push(obj);
                }
            });
        }

        anagrafePaziente.amministratoreDiSostegno = defaultAnagrafePaziente.amministratoreDiSostegno;
        if (richiesta.anagrafePaziente?.amministratoreDiSostegno) {
            anagrafePaziente.amministratoreDiSostegno = [];
            richiesta.anagrafePaziente.amministratoreDiSostegno.forEach(amministratoreDiSostegno => {
                let amministratoreDiSostegnoRichiesta = richiesta.anagrafePaziente.amministratoreDiSostegno.find(el => el.id === amministratoreDiSostegno.id);
                let amministratoreDiSostegnoRecuperato = mappaPersone[amministratoreDiSostegno.id];
                if (!Utils.isObjectNull(amministratoreDiSostegnoRecuperato)) {
                    let obj = {};
                    obj.conDelegaSanitaria = amministratoreDiSostegnoRichiesta?.conDelegaSanitaria;
                    obj.numeroDecretoDiNomina = amministratoreDiSostegnoRichiesta?.numeroDecretoDiNomina;
                    obj.annoDecretoDiNomina = amministratoreDiSostegnoRichiesta?.annoDecretoDiNomina;
                    Object.keys(amministratoreDiSostegnoRecuperato).forEach(key => {
                        if (key === "indirizzi" && amministratoreDiSostegnoRecuperato[key].length > 0) {
                            this.setIndirizzo(amministratoreDiSostegnoRecuperato[key], obj);
                        } else if (key === "contatti" && amministratoreDiSostegnoRecuperato[key].length > 0) {
                            this.setContatto(amministratoreDiSostegnoRecuperato[key], enumContatto.contatto.telefono, obj);
                            this.setContatto(amministratoreDiSostegnoRecuperato[key], enumContatto.contatto.cellulare, obj);
                            this.setContatto(amministratoreDiSostegnoRecuperato[key], enumContatto.contatto.email, obj);
                        } else if (key === "lingue")
                            this.getLingua(obj, amministratoreDiSostegnoRecuperato[key]);
                        else if (key === "id" || key === "nome" || key === "cognome" || key === "identificativoUtente")
                            obj[key] = amministratoreDiSostegnoRecuperato[key];
                    });
                    anagrafePaziente.amministratoreDiSostegno.push(obj);
                }
            });
        }

        anagrafePaziente.fiduciario = defaultAnagrafePaziente.fiduciario;
        let fiduciarioRecuperato = mappaPersone[richiesta.anagrafePaziente.fiduciario?.id];
        if (!Utils.isObjectNull(fiduciarioRecuperato)) {
            Object.keys(fiduciarioRecuperato).forEach(key => {
                if (key === "indirizzi" && fiduciarioRecuperato[key].length > 0) {
                    this.setIndirizzo(fiduciarioRecuperato[key], anagrafePaziente.fiduciario);
                } else if (key === "contatti" && fiduciarioRecuperato[key].length > 0) {
                    this.setContatto(fiduciarioRecuperato[key], enumContatto.contatto.telefono, anagrafePaziente.fiduciario);
                    this.setContatto(fiduciarioRecuperato[key], enumContatto.contatto.cellulare, anagrafePaziente.fiduciario);
                    this.setContatto(fiduciarioRecuperato[key], enumContatto.contatto.email, anagrafePaziente.fiduciario);
                } else if (key === "lingue")
                    this.getLingua(anagrafePaziente.fiduciario, fiduciarioRecuperato[key]);
                else if (key in defaultAnagrafePaziente.fiduciario)
                    anagrafePaziente.fiduciario[key] = fiduciarioRecuperato[key];
            });
        }
        richiesta.anagrafePaziente = anagrafePaziente;

        return richiesta;
    }

    recuperaStoricoListaCampi = (uuidRichiesta, nomiCampi) => {
        return RichiestaADIService.storicoListaCampi(uuidRichiesta, nomiCampi)
            .pipe(map(response => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error) && response?.status !== 404) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        });
                    }
                    return null;
                }

                return response.data;
            }));
    }

    contaCampiOpzionali = (uuidRichiesta) => {
        return RichiestaADIService.contaCampiOpzionali(uuidRichiesta).pipe(map((response) => {
            if (response?.msg === "ERRORE") {
                if (response?.data && (response?.data?.errore || response?.data?.error) && response?.status !== 404) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ?? response?.status,
                        type: "critical",
                        text: response?.data?.errore ?? response?.data?.message
                    });
                }
                return null;
            }

            return response.data;
        }));
    }

    recuperaRichiestaMediciConfermati = (uuidRichiesta) => {
        return RichiestaADIService.letturaRichiesta(uuidRichiesta, true)
            .pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        if (response?.status === 404) {
                            this.setState({error: true}, () => {
                                nascondiSpinner();
                                AccordionHelper.setLastSurveyCompiled(null);
                            });
                        } else {
                            Utils.toasterFunction({
                                status: response?.data?.codice ? response?.data?.codice : response?.status,
                                type: "critical",
                                text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                            });
                        }
                    }
                    return null;
                } else {
                    return response.data;
                }
            }));
    }

    estraiUUIDPersone = (richiesta) => {
        let listUUIDPersone = [];

        if (richiesta.idAssistito) listUUIDPersone.push(richiesta.idAssistito);
        if (richiesta.richiedente) listUUIDPersone.push(richiesta.richiedente.idProfessionista);

        const idProfessionistaMcp = Utils.recuperoIdProfessionista(richiesta);
        if (idProfessionistaMcp != null) listUUIDPersone.push(idProfessionistaMcp);

        if (richiesta.hasOwnProperty("anagrafePaziente")) {
            let anagrafePaziente = richiesta.anagrafePaziente;
            let uuidMmgPlsPaziente = anagrafePaziente.hasOwnProperty("mmgPlsDelPaziente") ? anagrafePaziente.mmgPlsDelPaziente.id : null;
            if (uuidMmgPlsPaziente) listUUIDPersone.push(uuidMmgPlsPaziente);

            let uuidPadre = anagrafePaziente.hasOwnProperty("padre") ? anagrafePaziente.padre.id : null;
            if (uuidPadre) listUUIDPersone.push(uuidPadre);

            let uuidMadre = anagrafePaziente.hasOwnProperty("madre") ? anagrafePaziente.madre.id : null;
            if (uuidMadre) listUUIDPersone.push(uuidMadre);

            if (anagrafePaziente.hasOwnProperty("tutore"))
                anagrafePaziente.tutore.filter(el => el.id).forEach(el => listUUIDPersone.push(el.id));

            if (anagrafePaziente.hasOwnProperty("amministratoreDiSostegno"))
                anagrafePaziente.amministratoreDiSostegno.filter(el => el.id).forEach(el => listUUIDPersone.push(el.id));

            let uuidFiduciario = anagrafePaziente.hasOwnProperty("fiduciario") ? anagrafePaziente.fiduciario.id : null;
            if (uuidFiduciario) listUUIDPersone.push(uuidFiduciario);
        }

        return Array.from(new Set(listUUIDPersone));
    }

    getLingua = (object, lingue) => {
        object.idLingue = [];
        object.madrelingua = null;
        object.livello = null;
        object.descrizione = [];
        if (lingue.length > 0) {
            let isMadreLingua = !!lingue.find(lingua => lingua.madrelingua);
            if (isMadreLingua) {
                object.idLingue = [lingue[0].idElemento];
                object.madrelingua = isMadreLingua;
            } else {
                object.idLingue = lingue.map(lingua => lingua.idElemento);
                object.madrelingua = isMadreLingua;
                object.livello = lingue.find(lingua => !lingua.madrelingua)?.livello ?? null;
                object.descrizione = lingue.map(lingua => lingua.descrizione);
            }
        }
    }

    setIndirizzo = (indirizzi, object) => {
        let residenza = this.getRecapito("residenza", indirizzi, enumIndirizzo.indirizzo.residenza);
        if (residenza !== null && !Utils.isObjectNull(residenza)) {
            object.indirizzo = residenza.indirizzo;
            object.numero = residenza.numero;
            object.idElementoIndirizzo = residenza.idElemento;
        } else {
            let domicilio = this.getRecapito("domicilio", indirizzi, enumIndirizzo.indirizzo.domicilio);
            if (domicilio !== null && !Utils.isObjectNull(domicilio)) {
                object.indirizzo = domicilio.indirizzo;
                object.numero = domicilio.numero;
                object.idElementoIndirizzo = domicilio.idElemento;
            } else {
                let domicilioDiCura = this.getRecapito("domicilioDiCura", indirizzi, enumIndirizzo.indirizzo.altro);
                if (domicilioDiCura !== null && !Utils.isObjectNull(domicilioDiCura)) {
                    object.indirizzo = domicilioDiCura.indirizzo;
                    object.numero = domicilioDiCura.numero;
                    object.idElementoIndirizzo = domicilioDiCura.idElemento;
                } else {
                    object.indirizzo = null;
                    object.numero = null;
                    object.idElementoIndirizzo = null;
                }
            }
        }
    }

    getRecapito = (field, indirizzi, tipoIndirizzo) => {
        let listaIndirizzi = indirizzi.filter(indirizzo => indirizzo.tipo === tipoIndirizzo);
        let object = undefined;

        if (listaIndirizzi.length > 0)
            object = listaIndirizzi[listaIndirizzi.length - 1];

        if (object !== undefined || !Utils.isObjectNull(object)) {
            Object.keys(object).forEach(key => {
                if (!(key in defaultAnagrafePaziente[field]))
                    delete object[key];
            });
            return object;
        } else
            return null;
    }

    setContatto = (contatti, tipoContatto, object) => {
        let listaContatti = contatti.filter(el => el.tipo === tipoContatto);
        let contatto;

        if (listaContatti.length > 0)
            contatto = listaContatti[listaContatti.length - 1];

        switch (tipoContatto) {
            case enumContatto.contatto.telefono:
                object.telefono = contatto?.valore ?? null;
                object.idElementoTelefono = contatto?.idElemento ?? null;
                break;
            case enumContatto.contatto.cellulare:
                object.cellulare = contatto?.valore ?? null;
                object.idElementoCellulare = contatto?.idElemento ?? null;
                break;
            case enumContatto.contatto.email:
                object.email = contatto?.valore ?? null;
                object.idElementoEmail = contatto?.idElemento ?? null;
                break;
            default:
                break;
        }
    }

    redirectToPaginaDiCortesia = () => {
        return (
            <Redirect to={{
                pathname: routepath.not_found,
                state: {title: "Errore", motivation: "La richiesta non è stata trovata"}
            }}/>
        );
    }

    gotoAnagraficaRichiedente = () => {
        InserimentoRichiesta.salvaBozzaSubject.next();
        this.setState({gotoAnagraficaRichiedente: true});
    }

    render() {
        if (this.state.redirectToAggiungiValutatore) {
            return <Redirect to={{
                pathname: routepath.valutazione_multidimensionale.concat(routepath.inserimento_nuovo_valutatore),
                richiesta: this.state.richiesta,
                pageState: this.props.pageState,
                pageName: this.props.pageName,
                showOnlyRequiredFields: this.props.showOnlyRequiredFields,
                previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields,
                showMultiCompilationPages: this.props.showMultiCompilationPages
            }}/>;
        }

        if (this.state.gotoAnagraficaRichiedente) {
            return <Redirect to={{
                pathname: this.props.pathname.concat(routepath.anagrafica_medico_richiedente),
                idPersona: Utils.recuperoIdProfessionista(this.state.richiesta),
                uuidRichiesta: this.props.uuidRichiesta,
                pageState: this.props.pageState,
                pageName: this.props.pageName,
                readOnly: !isSomeFieldIdWriteable('ANAGRAFESOGGETTORICHIEDENTE', this.props.pageState) || !!this.props.readOnly,
                hideUnsetValues: this.props.hideUnsetValues,
                firstPageName: this.props.firstPageName,
                insRichiestaPathname: this.props.pathname,
                richiesta: this.state.richiesta,
                showOnlyRequiredFields: this.props.showOnlyRequiredFields,
                previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields,
                showMultiCompilationPages: this.props.showMultiCompilationPages
            }}/>
        }

        if (this.state.error) {
            return this.redirectToPaginaDiCortesia();
        }

        if (!this.state.richiesta) return null;

        let page;

        // Props della componente Trackbar
        let props = {
            pageState: this.props.pageState,
            richiesta: this.state.richiesta,
            readOnly: this.props.readOnly,
            hideUnsetValues: this.props.hideUnsetValues,
            richiestaMedico: this.state.richiestaMedico ?? this.props.richiestaMedico,
            persone: this.state.persone,
            listaCodiciFiscaliUtentiOnline: this.state.listaCodiciFiscaliUtentiOnline,
            showMultiCompilationPages: this.props.showMultiCompilationPages
        };

        let additionalBreadcrumbPath = null;

        switch (this.props.pageName) {
            case enumPaginaCompila.COMPILA_SCHEDA_PAZIENTE:
            case enumPaginaCompila.COMPLETA_VALUTAZIONE_MULTIDIMENSIONALE:
                if (this.props.showOnlyRequiredFields) {
                    props = {...props, containsOnlyRequiredFields: true};
                } else {
                    props = {...props, previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields};
                }

                page = this.props.showOnlyRequiredFields
                    ? <CompilaRichiestaCampiObbligatori richiesta={this.state.richiesta}
                                                        richiestaMedico={this.state.richiestaMedico ?? this.props.richiestaMedico}
                                                        persone={this.state.persone}
                                                        pageState={this.props.pageState}
                                                        pageName={this.props.pageName}
                                                        readOnly={this.props.readOnly}
                                                        hideUnsetValues={false}
                                                        firstPageName={this.props.pageName}
                                                        listaCodiciFiscaliUtentiOnline={this.state.listaCodiciFiscaliUtentiOnline}
                                                        showMultiCompilationPages={this.props.showMultiCompilationPages}/>
                    : <ContainerPaginaCompila richiesta={this.state.richiesta}
                                              richiestaMedico={this.state.richiestaMedico ?? this.props.richiestaMedico}
                                              persone={this.state.persone}
                                              pageState={this.props.pageState}
                                              pageName={this.props.pageName}
                                              readOnly={this.props.readOnly}
                                              hideUnsetValues={false}
                                              listaCodiciFiscaliUtentiOnline={this.state.listaCodiciFiscaliUtentiOnline}/>;
                break;

            case enumPaginaCompila.ACCEDI_VALUTAZIONE_MULTIDIMENSIONALE:
            case enumPaginaCompila.VISUALIZZA_VALUTAZIONE_MULTIDIMENSIONALE:
            case enumPaginaCompila.VISUALIZZA_RIEPILOGO:
                var modalitaRiepilogo2 = getModalitaRiepilogo(this.props.forcedModalitaRiepilogo, this.state.richiesta);
                var isTabRichiestaActive2, isTabValutazioneActive2;
                if (modalitaRiepilogo2 === enumRiepilogo.modalitaRiepilogo.FASE_RICHIESTA_MEDICO_RICHIEDENTE
                    || this.props.forcedModalitaRiepilogo === enumRiepilogo.modalitaRiepilogo.FASE_RICHIESTA_STATO_TEAM_VALUTAZIONE) {
                    isTabRichiestaActive2 = true;
                    isTabValutazioneActive2 = false;
                } else {
                    isTabRichiestaActive2 = false;
                    isTabValutazioneActive2 = true;
                }

                props = {
                    ...props,
                    activeTabConditions: [isTabRichiestaActive2, isTabValutazioneActive2, false],
                    showAllMenuItems: true,
                    containsOnlyRequiredFields: this.props.showOnlyRequiredFields,
                    previousPageOnlyRequiredFields: this.props.showOnlyRequiredFields || this.props.previousPageOnlyRequiredFields
                };

                page = <VisualizzaRiepilogo richiesta={this.state.richiesta}
                                            richiestaMedico={this.state.richiestaMedico ?? this.props.richiestaMedico}
                                            pageState={this.props.pageState}
                                            forcedModalitaRiepilogo={this.props.forcedModalitaRiepilogo}
                                            readOnly={this.props.readOnly}
                                            hideUnsetValues={true}
                                            firstPageName={this.props.firstPageName}
                                            persone={this.state.persone}
                                            showOnlyRequiredFields={this.props.showOnlyRequiredFields}
                                            previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields}
                                            lastPageNumber={this.props.lastPageNumber}
                                            listaCodiciFiscaliUtentiOnline={this.state.listaCodiciFiscaliUtentiOnline}
                                            showMultiCompilationPages={this.props.showMultiCompilationPages}/>;
                break;

            case enumPaginaCompila.COMPILA_QUESTIONARIO:
                var previousPropsRichiestaQuestionario = _.cloneDeep(AccordionHelper.propsRichiestaQuestionario);
                var tipoQuestionario = this.props.tipoQuestionario ?? previousPropsRichiestaQuestionario?.tipoQuestionario;
                var questionario = getQuestionario(tipoQuestionario);
                additionalBreadcrumbPath = `Compilazione Questionario ${questionario?.sottotitolo || questionario?.titolo}`;

                AccordionHelper.setPropsRichiestaQuestionario({
                    tipoQuestionario: tipoQuestionario,
                    pathRichiestaQuestionario: this.props.pathRichiestaQuestionario ?? previousPropsRichiestaQuestionario?.pathRichiestaQuestionario,
                    sessoPaziente: this.props.sessoPaziente ?? previousPropsRichiestaQuestionario?.sessoPaziente,
                    labelSezioneObbligatoria: this.props.labelSezioneObbligatoria ?? previousPropsRichiestaQuestionario?.labelSezioneObbligatoria,
                    showOnlyRequiredFields: this.props.showOnlyRequiredFields ?? previousPropsRichiestaQuestionario?.showOnlyRequiredFields,
                    infoRichiesta: this.props.infoRichiesta ?? previousPropsRichiestaQuestionario?.infoRichiesta,
                    redirectPage: this.props.redirectPage ?? previousPropsRichiestaQuestionario?.redirectPage,
                    firstPageName: this.props.firstPageName ?? previousPropsRichiestaQuestionario?.firstPageName,
                    previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields || previousPropsRichiestaQuestionario?.previousPageOnlyRequiredFields,
                    obbligatorieta: this.props.obbligatorieta ?? previousPropsRichiestaQuestionario?.obbligatorieta,
                    disabilitato: this.props.disabilitato ?? previousPropsRichiestaQuestionario?.disabilitato,
                    tornaIndietro: this.props.tornaIndietro ?? previousPropsRichiestaQuestionario?.tornaIndietro,
                    showMultiCompilationPages: this.props.showMultiCompilationPages
                });

                page = <CompilaQuestionario richiesta={this.props.richiesta}
                                            tipoQuestionario={this.props.tipoQuestionario ?? previousPropsRichiestaQuestionario?.tipoQuestionario}
                                            pageState={this.props.pageState}
                                            pathRichiestaQuestionario={this.props.pathRichiestaQuestionario ?? previousPropsRichiestaQuestionario?.pathRichiestaQuestionario}
                                            sessoPaziente={this.props.sessoPaziente ?? previousPropsRichiestaQuestionario?.sessoPaziente}
                                            labelSezioneObbligatoria={this.props.labelSezioneObbligatoria ?? previousPropsRichiestaQuestionario?.labelSezioneObbligatoria}
                                            showOnlyRequiredFields={this.props.showOnlyRequiredFields ?? previousPropsRichiestaQuestionario?.showOnlyRequiredFields}
                                            infoRichiesta={this.props.infoRichiesta ?? previousPropsRichiestaQuestionario?.infoRichiesta}
                                            redirectPage={this.props.redirectPage ?? previousPropsRichiestaQuestionario?.redirectPage}
                                            firstPageName={this.props.firstPageName ?? previousPropsRichiestaQuestionario?.firstPageName}
                                            previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields || previousPropsRichiestaQuestionario?.previousPageOnlyRequiredFields}
                                            obbligatorieta={this.props.obbligatorieta ?? previousPropsRichiestaQuestionario?.obbligatorieta}
                                            disabilitato={this.props.disabilitato ?? previousPropsRichiestaQuestionario?.disabilitato}
                                            tornaIndietro={this.props.tornaIndietro ?? previousPropsRichiestaQuestionario?.tornaIndietro}
                                            showMultiCompilationPages={this.props.showMultiCompilationPages ?? previousPropsRichiestaQuestionario?.showMultiCompilationPages}
                />
                break;

            case enumPaginaCompila.STATO_VALUTAZIONE_COMPONENTI_TEAM:
                page = <StatoValutazioneEComponentiDelTeam
                    key={this.props?.key}
                    pathname={this.props.pathname}
                    richiesta={this.state.richiesta}
                    richiestaMedico={this.state.richiestaMedico ?? this.props.richiestaMedico}
                    pageState={this.props.pageState}
                    persone={this.state.persone}
                    showAllMenuItems={true}
                    containsOnlyRequiredFields={true}
                    showOptionalFieldsCount={true}
                    readOnly={!!this.props.readOnly}
                    hideUnsetValues={!!this.props.hideUnsetValues}
                    showOnlyRequiredFields={!!this.props?.showOnlyRequiredFields}
                    previousPageOnlyRequiredFields={!!this.props?.previousPageOnlyRequiredFields}
                    showMultiCompilationPages={!!this.props.showMultiCompilationPages}
                />
                break;
        }

        let breadcrumbPath = ['Home', Utils.isStateRichiesta(this.props.pageState) ? "Dettaglio richiesta" : "Dettaglio valutazione"];
        if (additionalBreadcrumbPath) breadcrumbPath = [...breadcrumbPath, additionalBreadcrumbPath];

        const showBreadcrumb = this.props.pageName !== enumPaginaCompila.STATO_VALUTAZIONE_COMPONENTI_TEAM;

        return <>
            {showBreadcrumb && <Breadcrumb
                path={breadcrumbPath}
                richiesta={this.state.richiesta}
                infoRichiesta={this.state.infoRichiesta || this.props.infoRichiesta} />}
            {showMessageAulssDistrettoDifferences(this.state.richiesta, this.state.persone)}
            {showBreadcrumb && <Trackbar {...props}/>}
            {page}
        </>;
    }
}

InserimentoRichiesta.propTypes = propTypes;
