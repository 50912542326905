import React, {Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {
    aicList,
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedAICInput,
    RoleBasedCalendar,
    RoleBasedClock,
    RoleBasedFarmacoEquivalenteInput,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import Utils from "../../../utils/Utils";
import SessionRequest from "../../../utils/SessionRequest";
import {v4 as uuid} from "uuid";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import {Label} from "reactstrap";
import moment from "moment";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";

const propTypes = {
    datiTerapiaFarmacologicaInAtto: PropTypes.any,
    onChangeTerapiaFarmacologicaInAtto: PropTypes.func,
    openAccordion: PropTypes.any,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    mascheraModificabilita: PropTypes.any,
    pageState: PropTypes.string,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultFarmaco = {
    id: null,
    codiceAIC: null,
    classeDiEquivalenza: null,
    viaDiSomministrazione: null,
    dosaggio: null,
    unitaDosaggio:null,
    modalitaDiInfusione: null,
    tipoSoluzione: null,
    altroTipoSoluzione: null,
    volumeDiDiluizione: null,
    altroVolumeDiDiluizione: null,
    orariPosologia: null,
    intervalloTempoPeriodicoPosologia: null,
    intervalloTempoPeriodicoDaPosologia: null,
    intervalloTempoPeriodicoAPosologia: null,
    intervalloTempoRelativoEventiVita: null,
    terapiaCondizionata: null,
    doseMassima: null,
    unitaDoseMassima:null,
    intervalloDiTempoDoseMassima:null,
    noteDoseMassima:null,
    ultimaDoseData: null,
    ultimaDoseOra: null
}

export default class TerapiaFarmacologicaInAtto extends AccordionSecondLevel {

    state = {
        datiTerapiaFarmacologicaInAtto: _.cloneDeep(this.props.datiTerapiaFarmacologicaInAtto)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiTerapiaFarmacologicaInAtto, this.props.datiTerapiaFarmacologicaInAtto))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiTerapiaFarmacologicaInAtto = _.cloneDeep(this.props.datiTerapiaFarmacologicaInAtto);
        this.setState({datiTerapiaFarmacologicaInAtto}, () => this.contaCampiObbligatoriMancanti());
    }

    updateClock(field, index, hh, mm) {
        if (hh && mm) {
            this.handleAggiornaForm(field, (hh + ':' + mm), index);
        } else if(!hh && mm) {
            hh = "00"
            this.handleAggiornaForm(field, (hh + ':' + mm), index);
        } else if(hh && !mm){
            mm = "00"
            this.handleAggiornaForm(field, (hh + ':' + mm), index);
        }
        else {
            this.handleAggiornaForm(field, null, index);
        }
    }

    addNewFarmaco = () => {
        let datiTerapiaFarmacologicaInAtto = _.cloneDeep(this.state.datiTerapiaFarmacologicaInAtto);
        let farmaco = _.cloneDeep(defaultFarmaco);
        farmaco.id = uuid();
        datiTerapiaFarmacologicaInAtto.farmaci.push(farmaco);
        this.setState({datiTerapiaFarmacologicaInAtto});
    }

    removeFarmaco = (index) => {
        let datiTerapiaFarmacologicaInAtto = _.cloneDeep(this.state.datiTerapiaFarmacologicaInAtto);
        datiTerapiaFarmacologicaInAtto.farmaci.splice(index, 1);
        this.setState({datiTerapiaFarmacologicaInAtto}, () => {
            this.formatObjectForBackend();
            this.contaCampiObbligatoriMancanti();
        });
    }

    onHide = () => {
        const datiTerapiaFarmacologicaInAtto = _.cloneDeep(SessionRequest.getInitObject('anamnesiFisiologica.terapiaFarmacologicaInAtto'));
        this.setState({datiTerapiaFarmacologicaInAtto}, () => {
            let objectClone = _.cloneDeep(this.state.datiTerapiaFarmacologicaInAtto);
            this.props.onChangeTerapiaFarmacologicaInAtto("terapiaFarmacologicaInAtto", objectClone)
            this.contaCampiObbligatoriMancanti();
            this.props.onHide();
        });
    }

    handleChangeDispositivi = (array, field) => {
        let datiTerapiaFarmacologicaInAtto = _.cloneDeep(this.state.datiTerapiaFarmacologicaInAtto);
        datiTerapiaFarmacologicaInAtto[field] = array;
        this.setState({datiTerapiaFarmacologicaInAtto}, () => {
            this.formatObjectForBackend();
            this.contaCampiObbligatoriMancanti();
            this.forceUpdate();
        });
    }

    handleAggiornaForm = (field, elem, indexCurrentFarmaco) => {
        let datiTerapiaFarmacologicaInAtto = _.cloneDeep(this.state.datiTerapiaFarmacologicaInAtto);
        if (indexCurrentFarmaco === -1) {
            datiTerapiaFarmacologicaInAtto[field] = elem;
            if (field === "presenzaTerapiaFarmacologica" && elem !== "01") datiTerapiaFarmacologicaInAtto.farmaci = [];
        } else {
            let currentFarmaco = datiTerapiaFarmacologicaInAtto.farmaci[indexCurrentFarmaco];
            if (field === "modalitaDiInfusione" && !_.isEqual(elem, currentFarmaco[field])) {
                currentFarmaco[field] = elem;
                currentFarmaco.tipoSoluzione = null;
                currentFarmaco.altroTipoSoluzione = null;
                currentFarmaco.volumeDiDiluizione = null;
                currentFarmaco.altroVolumeDiDiluizione = null;
            } else if (field === "tipoSoluzione" && !_.isEqual(elem, currentFarmaco[field])) {
                currentFarmaco[field] = elem;
                currentFarmaco.altroTipoSoluzione = null;
            } else if (field === "volumeDiDiluizione" && !_.isEqual(elem, currentFarmaco[field])) {
                currentFarmaco[field] = elem;
                currentFarmaco.altroVolumeDiDiluizione = null;
            } else {
                currentFarmaco[field] = elem;
            }

            if (currentFarmaco.codiceAIC == null && currentFarmaco.classeDiEquivalenza == null) {
                currentFarmaco = {..._.cloneDeep(defaultFarmaco), id: currentFarmaco.id};
            }

            datiTerapiaFarmacologicaInAtto.farmaci[indexCurrentFarmaco] = currentFarmaco;
        }


        this.setState({datiTerapiaFarmacologicaInAtto}, () => {
            this.formatObjectForBackend();
            this.contaCampiObbligatoriMancanti();
        });
    }

    formatObjectForBackend = () => {
        let datiTerapiaFarmacologicaInAtto = _.cloneDeep(this.state.datiTerapiaFarmacologicaInAtto);
        this.props.onChangeTerapiaFarmacologicaInAtto("terapiaFarmacologicaInAtto", datiTerapiaFarmacologicaInAtto);
    }

    contaCampiObbligatoriMancanti = () => {
        let farmaci = _.cloneDeep(this.state.datiTerapiaFarmacologicaInAtto.farmaci);
        this.props.onChangeRequiredFieldsNumber("sottosezioneTerapiaFarmacologicaInAtto", this.getNumeroCampiObbligatoriMancanti(farmaci));
    }

    checkPermissionToWriteFields = () => {
        const orarioWritable = isFieldIdWriteable('POSOLOGIA_ORARI.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE', this.props.pageState, this.context.forceUserRole);
        const intervalloTempoPeriodicoWritable = isFieldIdWriteable('POSOLOGIA_INT_TEMPO_PERIOD.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE', this.props.pageState, this.context.forceUserRole);
        const intervalloTempoPeriodicoDaWritable = isFieldIdWriteable('POSOLOGIA_INT_TEMPO_PERIOD_DA.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE', this.props.pageState, this.context.forceUserRole);
        const intervalloTempoPeriodicoAWritable = isFieldIdWriteable('POSOLOGIA_INT_TEMPO_PERIOD_A.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE', this.props.pageState, this.context.forceUserRole);
        const intervalloTempoRelativoEventiVitaWritable = isFieldIdWriteable('POSOLOGIA_INT_TEMPO_REL_EVENTI_VITA.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE', this.props.pageState, this.context.forceUserRole);

        return orarioWritable && intervalloTempoPeriodicoWritable && intervalloTempoPeriodicoDaWritable
            && intervalloTempoPeriodicoAWritable && intervalloTempoRelativoEventiVitaWritable
    }

    getSintesiListaFarmaci = (farmaci) => {
        return farmaci
            .map(f => ({
                orariPosologia: f.orariPosologia,
                intervalloTempoPeriodicoPosologia: f.intervalloTempoPeriodicoPosologia,
                intervalloTempoPeriodicoDaPosologia: f.intervalloTempoPeriodicoDaPosologia,
                intervalloTempoPeriodicoAPosologia: f.intervalloTempoPeriodicoAPosologia,
                intervalloTempoRelativoEventiVita: f.intervalloTempoRelativoEventiVita,
            }));
    }

    getNumeroCampiObbligatoriMancanti = (farmaci) => {
        let numRequiredFieldsNotValued = 0;

        if (this.checkPermissionToWriteFields()) {
            this.getSintesiListaFarmaci(farmaci)
                .forEach(f => {
                    if (Object.keys(f).every(key => Utils.isStringEmptyOrNullOrUndefined(f[key]))) numRequiredFieldsNotValued += 5;
                    else {
                        if ((!Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoDaPosologia) && Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoAPosologia))
                            || (!Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoAPosologia) && Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoDaPosologia))) {
                            numRequiredFieldsNotValued++;
                        } else {
                            let isInvalidDate;
                            if (!f.intervalloTempoPeriodicoDaPosologia && f.intervalloTempoPeriodicoAPosologia) {
                                isInvalidDate = moment(f.intervalloTempoPeriodicoAPosologia, "DD/MM/YYYY") < moment().format("DD/MM/YYYY");
                            } else if (f.intervalloTempoPeriodicoDaPosologia && f.intervalloTempoPeriodicoAPosologia) {
                                isInvalidDate = moment(f.intervalloTempoPeriodicoAPosologia, "DD/MM/YYYY").isBefore(moment(f.intervalloTempoPeriodicoDaPosologia, "DD/MM/YYYY"));
                            } else isInvalidDate = false;
                            if (isInvalidDate) numRequiredFieldsNotValued++;
                        }
                    }
                });
        }

        return numRequiredFieldsNotValued;
    }

    getRequiredField = (field, index) => {
        if (this.checkPermissionToWriteFields()) {
            let esiti = [];
            this.getSintesiListaFarmaci(_.cloneDeep([this.state.datiTerapiaFarmacologicaInAtto.farmaci[index]] || []))
                .forEach(f => {
                    let esito = Object.keys(f).every(key => Utils.isStringEmptyOrNullOrUndefined(f[key]));
                    if (!esito) {
                        const isIntervalloDaAndNotIntervalloA = (field === "intervalloTempoPeriodicoDaPosologia" || field === "intervalloTempoPeriodicoAPosologia")
                            && !Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoDaPosologia)
                            && Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoAPosologia);
                        const isIntervalloAAndNotIntervalloDa = (field === "intervalloTempoPeriodicoAPosologia" || field === "intervalloTempoPeriodicoDaPosologia")
                            && !Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoAPosologia)
                            && Utils.isStringEmptyOrNullOrUndefined(f.intervalloTempoPeriodicoDaPosologia);
                        esito = isIntervalloDaAndNotIntervalloA || isIntervalloAAndNotIntervalloDa;
                    }
                    esiti.push(esito);
                });
            return !esiti.length || esiti.every(e => e);
        } else return false;
    }

    renderFarmaco = (object, index) => {
        const optionsAIC = _.cloneDeep(aicList)
            .filter(f => !this.state.datiTerapiaFarmacologicaInAtto.farmaci?.find(d => object.codiceAIC !== d.codiceAIC && d.codiceAIC === f.value));

        return (
            <Fragment key={index}>
                <FieldsRow>
                    <RoleBasedAICInput
                        id={"codiceAIC"}
                        fieldId={'FARMACO_CODICE_AIC.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        field={"codiceAIC"}
                        fieldLabel={'Codice AIC'}
                        pageState={this.props.pageState}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        value={object.codiceAIC}
                        suggestions={optionsAIC}
                        classeEquivalenza={object.classeDiEquivalenza}
                        forceReadOnly={Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.informazioniDiBase?.terapiaFarmacologicaInAtto?.farmaci, "codiceAIC", object?.id)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedFarmacoEquivalenteInput
                        id={"classeDiEquivalenza"}
                        fieldId={'FARMACO_CLASSE_EQUIVALENZA.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        field={"classeDiEquivalenza"}
                        fieldLabel={'Classe di equivalenza'}
                        pageState={this.props.pageState}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        value={object.classeDiEquivalenza}
                        codiceAIC={object.codiceAIC}
                        forceReadOnly={Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.informazioniDiBase?.terapiaFarmacologicaInAtto?.farmaci, "classeDiEquivalenza", object?.id)}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>

                {(!Utils.isStringEmptyOrNullOrUndefined(object.classeDiEquivalenza) || !Utils.isStringEmptyOrNullOrUndefined(object.codiceAIC)) &&
                    <>
                        <FieldsRow>
                            <RoleBasedSelect
                                fieldId={'VIA_SOMMIN.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                options={OggettiUtili.viaDiSomministrazione}
                                value={object.viaDiSomministrazione}
                                onChange={(elem) => this.handleAggiornaForm("viaDiSomministrazione", elem, index)}
                                isSearchable={false}
                                fieldLabel={'Via di somministrazione'}
                                noOptionsMessage={() => "Errore: Via di somministrazione non esistente"}
                                handleOnlyValue={true}
                                field={"viaDiSomministrazione"}
                                forceReadOnly={Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.informazioniDiBase?.terapiaFarmacologicaInAtto?.farmaci, "viaDiSomministrazione", object?.id)}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                            <RoleBasedInput
                                fieldId={'DOSAGGIO.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                value={object.dosaggio}
                                placeholder={"Inserisci dosaggio"}
                                field={"dosaggio"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                                type={"number"}
                                min={0}
                                fieldLabel={'Dosaggio'}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                            <RoleBasedSelect
                                fieldId={'DOSAGGIO_MISURA.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                id={"selectUnitaDiMisura"}
                                options={OggettiUtili.unitaDiMisura}
                                value={object.unitaDosaggio}
                                onChange={(elem) => this.handleAggiornaForm("unitaDosaggio", elem, index)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Unita Dosaggio non esistente"}
                                field={"unitaDosaggio"}
                                fieldLabel={"Unità misura del dosaggio"}
                                handleOnlyValue={true}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                            <RoleBasedSelect
                                fieldId={'MOD_INFUSIONE.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                options={OggettiUtili.modalitaDiInfusione}
                                value={object.modalitaDiInfusione}
                                onChange={(elem) => this.handleAggiornaForm("modalitaDiInfusione", elem, index)}
                                isSearchable={false}
                                fieldLabel={'Modalità di infusione'}
                                noOptionsMessage={() => "Errore: Modalita di infusione non esistente"}
                                handleOnlyValue={true}
                                field={"modalitaDiInfusione"}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                        </FieldsRow>

                        {object.modalitaDiInfusione === "02" &&
                            <>
                                <FieldsRow colNumber={4}>
                                    <RoleBasedSelect
                                        fieldId={'TIPO_SOLUZ.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                        pageState={this.props.pageState}
                                        options={OggettiUtili.tipoSoluzione}
                                        value={object.tipoSoluzione}
                                        onChange={(elem) => this.handleAggiornaForm("tipoSoluzione", elem, index)}
                                        isSearchable={false}
                                        noOptionsMessage={() => "Errore: Tipo soluzione non esistente"}
                                        fieldLabel={'Tipo di soluzione'}
                                        handleOnlyValue={true}
                                        field={"tipoSoluzione"}
                                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    />
                                    {object.tipoSoluzione === "09" &&
                                        <RoleBasedInput
                                            /* TODO: Manca il campo fieldId corrispondente */
                                            fieldId={'TIPO_SOLUZ.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                            pageState={this.props.pageState}
                                            value={object.altroTipoSoluzione}
                                            placeholder={"Inserisci altro tipo di soluzione"}
                                            field={"altroTipoSoluzione"}
                                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                                            fieldLabel={'Altro tipo di soluzione'}
                                            id={"altroTipoSoluzioneTerapiaFarmacologicaInAtto"}
                                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                        />}
                                    <RoleBasedSelect
                                        fieldId={'VOL_DILUIZ.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                        pageState={this.props.pageState}
                                        options={OggettiUtili.volumeDiDiluizione}
                                        value={object.volumeDiDiluizione}
                                        onChange={(elem) => this.handleAggiornaForm("volumeDiDiluizione", elem, index)}
                                        isSearchable={false}
                                        noOptionsMessage={() => "Errore: Volume di diluizione non esistente"}
                                        fieldLabel={'Volume di diluizione'}
                                        handleOnlyValue={true}
                                        field={"volumeDiDiluizione"}
                                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                    />
                                    {object.volumeDiDiluizione === "05" &&
                                        <RoleBasedInput
                                            fieldId={'VOL_DILUIZ_ML.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                            pageState={this.props.pageState}
                                            value={object.altroVolumeDiDiluizione}
                                            placeholder={"Inserisci altro volume di diluizione"}
                                            field={"altroVolumeDiDiluizione"}
                                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                                            fieldLabel={'Altro volume di diluizione (mL)'}
                                            id={"altroVolumeDiDiluizioneTerapiaFarmacologicaInAtto"}
                                            type={"number"}
                                            min={0}
                                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                        />}
                                </FieldsRow>
                            </>}

                        <>
                            <FieldsRow spaceBetweenRows={false} containerClass={"pt-3"}>
                                <Label className="font-weight-bold m-0">Posologia</Label>
                            </FieldsRow>

                            <FieldsRow>
                                <RoleBasedClock
                                    fieldId={'POSOLOGIA_ORARI.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                    pageState={this.props.pageState}
                                    id={"inputOrariPosologia"}
                                    fieldLabel={'Orari'}
                                    value={object.orariPosologia}
                                    onChange={(hh, mm) => this.updateClock("orariPosologia", index, hh, mm)}
                                    field={'orariPosologia'}
                                    fieldRequired={this.getRequiredField("orariPosologia", index)}
                                    forceReadOnly={Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.informazioniDiBase?.terapiaFarmacologicaInAtto?.farmaci, "orariPosologia", object?.id)}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                />
                            </FieldsRow>

                            <FieldsRow colNumber={-1}>
                                <div className="mb-2">
                                    <strong>Intervallo di tempo periodico: Ogni </strong>
                                </div>
                                <RoleBasedInput
                                    fieldId={'POSOLOGIA_INT_TEMPO_PERIOD.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                    pageState={this.props.pageState}
                                    value={object.intervalloTempoPeriodicoPosologia}
                                    field={"intervalloTempoPeriodicoPosologia"}
                                    onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                                    type={"number"}
                                    min={0}
                                    placeholder="Inserisci ore"
                                    fieldRequired={this.getRequiredField("intervalloTempoPeriodicoPosologia", index)}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                />
                                <div
                                    className={"mb-2 ".concat(this.getRequiredField("intervalloTempoPeriodicoPosologia", index) && "required")}>
                                    <strong> ore</strong>
                                </div>
                            </FieldsRow>

                            <FieldsRow colNumber={-1}>
                                <div
                                    className={"mb-2 ".concat(this.getRequiredField("intervalloTempoPeriodicoDaPosologia", index) && "required")}>
                                    <strong>Intervallo di tempo periodico: Da </strong>
                                </div>
                                <RoleBasedCalendar
                                    fieldId={'POSOLOGIA_INT_TEMPO_PERIOD_DA.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                    pageState={this.props.pageState}
                                    id={"terapiaFarmacologicaInAttoIntervalloTempoPeriodicoDaPosologia" + index}
                                    value={object.intervalloTempoPeriodicoDaPosologia}
                                    onChange={(elem) => this.handleAggiornaForm("intervalloTempoPeriodicoDaPosologia", elem.target.value, index)}
                                    error={
                                        object.intervalloTempoPeriodicoDaPosologia && object.intervalloTempoPeriodicoAPosologia
                                            ? moment(object.intervalloTempoPeriodicoDaPosologia, "DD/MM/YYYY").isAfter(moment(object.intervalloTempoPeriodicoAPosologia, "DD/MM/YYYY"))
                                            : false
                                    }
                                    errorMessage={"Data non valida"}
                                    field={'intervalloTempoPeriodicoDaPosologia'}
                                    fieldRequired={this.getRequiredField("intervalloTempoPeriodicoDaPosologia", index)}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                />
                                <div
                                    className={"mb-2 ".concat(this.getRequiredField("intervalloTempoPeriodicoAPosologia", index) && "required")}>
                                    <strong> a </strong>
                                </div>
                                <RoleBasedCalendar
                                    fieldId={'POSOLOGIA_INT_TEMPO_PERIOD_A.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                    pageState={this.props.pageState}
                                    id={"terapiaFarmacologicaInAttoIntervalloTempoPeriodicoAPosologia" + index}
                                    value={object.intervalloTempoPeriodicoAPosologia}
                                    onChange={(elem) => this.handleAggiornaForm("intervalloTempoPeriodicoAPosologia", elem.target.value, index)}
                                    error={
                                        !object.intervalloTempoPeriodicoDaPosologia && object.intervalloTempoPeriodicoAPosologia
                                            ? moment(object.intervalloTempoPeriodicoAPosologia, "DD/MM/YYYY") < moment().format("DD/MM/YYYY")
                                            : object.intervalloTempoPeriodicoDaPosologia && object.intervalloTempoPeriodicoAPosologia
                                                ? moment(object.intervalloTempoPeriodicoAPosologia, "DD/MM/YYYY").isBefore(moment(object.intervalloTempoPeriodicoDaPosologia, "DD/MM/YYYY"))
                                                : false
                                    }
                                    errorMessage={"Data non valida"}
                                    field={'intervalloTempoPeriodicoAPosologia'}
                                    fieldRequired={this.getRequiredField("intervalloTempoPeriodicoAPosologia", index)}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                />
                            </FieldsRow>

                            <FieldsRow>
                                <RoleBasedSelect
                                    fieldId={'POSOLOGIA_INT_TEMPO_REL_EVENTI_VITA.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                    pageState={this.props.pageState}
                                    options={OggettiUtili.intervalliTempoRelativiEventiVita}
                                    value={object.intervalloTempoRelativoEventiVita}
                                    onChange={(elem) => this.handleAggiornaForm("intervalloTempoRelativoEventiVita", elem, index)}
                                    isSearchable={false}
                                    fieldLabel={'Intervallo tempo relativo ad eventi della vita'}
                                    noOptionsMessage={() => "Errore: Intervallo non esistente"}
                                    handleOnlyValue={true}
                                    field={"intervalloTempoRelativoEventiVita"}
                                    id={"intervalloTempoRelativoEventiVita"}
                                    fieldRequired={this.getRequiredField("intervalloTempoRelativoEventiVita", index)}
                                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                />
                            </FieldsRow>
                        </>

                        <FieldsRow>
                            <RoleBasedInput
                                fieldId={'DOSE_MAX.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                value={object.doseMassima}
                                placeholder={"Inserisci dosaggio"}
                                field={"doseMassima"}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                                fieldLabel={'Dose massima'}
                                forceReadOnly={Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.informazioniDiBase?.terapiaFarmacologicaInAtto?.farmaci, "doseMassima", object?.id)}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                                type={"number"}
                                min={0}
                            />
                            <RoleBasedSelect
                                fieldId={'DOSE_MAX_MISURA.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                id={"selectUnitaDiMisuraDoseMassima"}
                                options={OggettiUtili.unitaDiMisura}
                                value={object.unitaDoseMassima}
                                onChange={(elem) => this.handleAggiornaForm("unitaDoseMassima", elem, index)}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Unità di misura non esistente"}
                                field={"unitaDoseMassima"}
                                fieldLabel={"Unità di misura"}
                                handleOnlyValue={true}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                            <RoleBasedSelect
                            /* TODO: Manca il campo fieldId corrispondente */
                                fieldId={'DOSE_MAX.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                id={"selectIntervalloDitempoDoseMassima"}
                                options={OggettiUtili.ripetizioneNelTempo}
                                value={object.intervalloDiTempoDoseMassima}
                                onChange={(elem) => this.handleAggiornaForm("intervalloDiTempoDoseMassima", elem, index)}
                                isSearchable={false}
                                field={"intervalloDiTempoDoseMassima"}
                                fieldLabel={"Intervallo di tempo"}
                                handleOnlyValue={true}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                            <RoleBasedInput
                                fieldId={'DOSE_MAX_TESTO_LIBERO.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                value={object.noteDoseMassima}
                                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                                placeholder={"Note dose massima"}
                                field={"noteDoseMassima"}                                
                                fieldLabel={'Note dose massima'}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                        </FieldsRow>
                        <FieldsRow>
                            <RoleBasedCalendar
                                fieldId={'ULTIMA_DOSE_DATA.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                id={"terapiaFarmacologicaInAttoUltimaDoseData"}
                                value={object.ultimaDoseData}
                                onChange={(elem) => this.handleAggiornaForm("ultimaDoseData", elem.target.value, index)}
                                errorMessage={"Data non valida"}
                                fieldLabel={'Data ultima dose'}
                                field={'ultimaDoseData'}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                            <RoleBasedClock
                                fieldId={'ULTIMA_DOSE_ORA.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                                pageState={this.props.pageState}
                                id={"ultimaDoseOra"}
                                fieldLabel={'Orario ultima dose'}
                                value={object.ultimaDoseOra}
                                onChange={(hh, mm) => this.updateClock("ultimaDoseOra", index, hh, mm)}
                                field={'ultimaDoseOra'}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />
                        </FieldsRow>
                    </>}
            </Fragment>
        );
    }

    renderSezioneBody = () => {
        let terapiaFarmacologicaInAtto = _.cloneDeep(this.state.datiTerapiaFarmacologicaInAtto);

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        id={"selectPresenzaTerapiaFarmacologica"}
                        fieldId={"NESSUNA_TERAPIA.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE"}
                        fieldLabel={'É presente una terapia farmacologica ?'}
                        field={"presenzaTerapiaFarmacologica"}
                        pageState={this.props.pageState}
                        className={"selectPresenzaTerapiaFarmacologica"}
                        options={OggettiUtili.presenzaTerapiaFarmacologica}
                        value={terapiaFarmacologicaInAtto.presenzaTerapiaFarmacologica}
                        onChange={(elem) => this.handleAggiornaForm("presenzaTerapiaFarmacologica", elem, -1)}
                        isSearchable={false}
                        disableDoubleInput={true}
                        handleOnlyValue={true}
                        forceReadOnly={this.props.mascheraModificabilita?.informazioniDiBase?.terapiaFarmacologicaInAtto?.presenzaTerapiaFarmacologica}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                {terapiaFarmacologicaInAtto.presenzaTerapiaFarmacologica === "01" ?
                <SectionList
                    addNewSectionCallback={this.addNewFarmaco}
                    data={terapiaFarmacologicaInAtto.farmaci}
                    removeSectionCallback={this.removeFarmaco}
                    title={'Farmaco'}
                    renderSection={this.renderFarmaco}
                    pageState={this.props.pageState}
                    keyFieldId={'FARMACO_CODICE_AIC.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    indentOffset={0}
                    addButtonVisibilityHandler={terapiaFarmacologicaInAtto.presenzaTerapiaFarmacologica === "01"
                        && (terapiaFarmacologicaInAtto.farmaci?.length === 0 || terapiaFarmacologicaInAtto.farmaci?.every(el => !!el.codiceAIC || !!el.classeDiEquivalenza))
                        &&  this.getNumeroCampiObbligatoriMancanti(_.cloneDeep(this.state.datiTerapiaFarmacologicaInAtto.farmaci)) == 0
                    }
                    field={'farmaci'}
                    parentJsonPath={"informazioniDiBase.terapiaFarmacologicaInAtto.farmaci"}
                    forceReadOnly={this.props.mascheraModificabilita?.informazioniDiBase?.terapiaFarmacologicaInAtto?.farmaci}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
                : <Fragment/>}
                <DispositiviPresenti
                    handleArrayChange={this.handleChangeDispositivi}
                    dispositiviPresenti={terapiaFarmacologicaInAtto.dispositivi}
                    pageState={this.props.pageState}
                    fieldId={'DISPOSITIVI.TERAPIAFARMACOLOGICAINATTO.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                    field={"dispositivi"}
                    handleOnlyValue={true}
                    addButtonVisibilityHandler={!Utils.isObjectNull(terapiaFarmacologicaInAtto.dispositivi)}
                    indentOffset={0}
                    parentJsonPath={"informazioniDiBase.terapiaFarmacologicaInAtto.dispositivi"}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
               />
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"terapiaFarmacologicaInAtto"}
                title={"Terapia farmacologica in atto"}
                required={this.getNumeroCampiObbligatoriMancanti(_.cloneDeep(this.state.datiTerapiaFarmacologicaInAtto.farmaci)) > 0}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                onHide={this.onHide}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["informazioniDiBase.terapiaFarmacologicaInAtto.farmaci", "informazioniDiBase.terapiaFarmacologicaInAtto.dispositivi"],
                    [defaultFarmaco, defaultDispositiviPresenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >
                {this.renderSezioneBody()}
            </AccordionSezioneRichiesta>
        );
    }
}
TerapiaFarmacologicaInAtto.propTypes = propTypes;
TerapiaFarmacologicaInAtto.contextType = RoleBasedContext;
