import React from "react";
import styles from './Modale.module.css';
import modalConfig from "../../../config/modalConfig";
import Icon from "../icon/Icon";




export default class Modale extends React.Component {

    state = {
        cssModal: [],
        title: this.props.title ? this.props.title : "Titolo di default",
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.title !== this.props.title) {
            this.setState({title: this.props.title})
        }

        if (this.props.modalText !== this.state.modalMessage) {
            this.setState({modalMessage: this.props.modalText})
        }
    }

    componentDidMount() {
        let cssModal = [];

        switch (this.props.typeModal) {
            case "modalConsenso": {
                cssModal = modalConfig.modalCssConsenso;
                break;
            }
            case "modalOscura": {
                cssModal = modalConfig.modalCssOscura;
                break;
            }

            case "modalViola": {
                cssModal = modalConfig.modalCssViola;
                break;
            }

            default: break;

        }
        this.setState({typeModal: this.props.typeModal, cssModal: cssModal, modalMessage: this.props.modalText})
    }

    getButton() {
        let element;
        if (this.props.modalShape === "arrow") {
            element =
                <a href="/ricerca-fascicolo" className={styles.ricercaLink} data-toggle="modal"
                   data-target={'#'.concat(this.props.id)}>
                    <Icon style={{fill: "#176A65"}} id={"it-arrow-left"} classes={"icon-sm"}/><span
                    className="fontSize14"><span id={this.props.textId}>{this.state.title}</span> </span>
                </a>
        } else {
            element =
                <button type="button" onClick={this.props.onClick} name={this.props.name}
                        id={this.props.id ? "modalButton_" + this.props.id : "modalButton"}
                        className={this.props.classes} style={this.props.style} disabled={this.props.disabled}
                        data-toggle="modal" data-target={'#'.concat(this.props.id)}>
                    {
                        this.props.text ? <span aria-hidden="true">{this.props.text}</span> : ""
                    }
                    {
                        this.props.icon ? <Icon
                            classes={"icon-white"}
                            id={this.props.icon}
                        ></Icon> : ""
                    }

                </button>

            // <button type="button" class="close" aria-label="Close" onClick={this.props.onClick}>
            // <span aria-hidden="true">&times;</span>
            // </button>

        }
        return element;
    }

    handleConfrimFun(event) {
        if (this.props.progressBar) {
            this.props.onClickCustom(event.target.value)
        } else {
            this.props.confirmFun()
        }
    }

    render() {

        return (
            <div style={{textAlign: "center"}} className={this.props.containerClass}>

                <div style={{display: this.props.soloModale ? "none" : ""}}>{this.getButton()}</div>

                <div className="modal fade noScroll" tabIndex="-1" role="dialog" id={this.props.id}>
                    <div className="modal-dialog modal-dialog-centered " role="document">
                        <div
                            className={("modal-content").concat(" " + styles[this.state.cssModal[0]]).concat(" " + styles.modalContentCustom)}>
                            <div className={styles[this.state.cssModal[5]]}>
                                <h5 className={styles.h5custom}>{this.state.title}</h5>
                            </div>
                            <div className={styles.modalContainerCustom}>
                                <div className={"container".concat(" " + styles[this.state.cssModal[4]])}>
                                    <div className="row">
                                        <div className="col-3">
                                        </div>
                                        <div className="col-6">
                                            <div className={styles[this.state.cssModal[1]]}></div>
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={("modal-body").concat(" " + styles[this.state.cssModal[6]]).concat(" " + styles.modalBodyCustom)}>
                                <p dangerouslySetInnerHTML={{__html: this.state.modalMessage}}/>
                            </div>
                            <div className={("modal-footer").concat(" " + styles[this.state.cssModal[7]])}>
                                <div className={[styles.buttons].join(" ").concat(" btn-group")} role="group"
                                     aria-label="Basic example">
                                    <button
                                        id={"buttonOK"}
                                        className={[this.state.cssModal[2], styles.buttonOk].join(" ").concat(" btn")}
                                        data-dismiss="modal"
                                        value={this.props.value}
                                        type="button"
                                        onClick={(e) => this.handleConfrimFun(e)}
                                    >
                                        {this.props.textButtonOk}
                                    </button>
                                    <button
                                        id={"buttonKO"}
                                        onClick={this.props.onClickKo ? this.props.onClickKo : ""}
                                        className={[styles.buttonAnnulla, this.state.cssModal[3]].join(" ").concat(" btn")}
                                        data-dismiss="modal"
                                        type="button"
                                        style={{display: this.props.buttonMail ? "none" : ""}}>{this.props.textButtonKo}
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        );
    }
}
Modale.defaultProps = {
    modalText: "Messaggio di Prova",
    confirmFun: function () {

    },
    textButtonOk: "Conferma",
    textButtonKo: "Annulla",
    modalShape: "button",


}
