import React, {Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import Utils from "../../../utils/Utils";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {
    FieldsRow,
    RoleBasedInput,
    RoleBasedPhoneInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";

const propTypes = {
    amministratoriDiSostegnoForm: PropTypes.any,
    datiTutoriForm: PropTypes.any,
    onChangeDatiAmministratoreDiSostegno: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onValidaSezione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    deleteAmministratoreDiSostegno: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultAmministratoreDiSostegno = {
    id: null,
    conDelegaSanitaria: null,
    numeroDecretoDiNomina: null,
    annoDecretoDiNomina: null,
    cognome: "",
    nome: "",
    identificativoUtente: "",
    indirizzo: "",
    numero: "",
    telefono: "",
    email: "",
    madrelingua: null,
    livello: null,
    descrizione: []
}

export default class AmministratoreDiSostegno extends AccordionSecondLevel {

    state = {
        amministratoriDiSostegno: _.cloneDeep(this.props.amministratoriDiSostegnoForm)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.amministratoriDiSostegnoForm, this.props.amministratoriDiSostegnoForm))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let listaAmministratoriDiSostegno = _.cloneDeep(this.props.amministratoriDiSostegnoForm);
        this.setState({amministratoriDiSostegno: listaAmministratoriDiSostegno}, () => {
            this.props.onChangeRequiredFieldsNumber("sottoSezioneAmministratoreDiSostegno", this.countInvalidItems(this.state.amministratoriDiSostegno));
            this.validaSezione(this.state.amministratoriDiSostegno);
        });
    }

    countInvalidItems = (listaAmministratoriDiSostegno) => {
        if (listaAmministratoriDiSostegno != null && listaAmministratoriDiSostegno?.length > 0) {
            return listaAmministratoriDiSostegno.map(as => {
                const annoDecretoDiNomina = document.getElementById("inputAnnoDecretoDiNomina")?.value ?? null;
                const isAnnoDecretoDiNominaValido = !Utils.isStringEmptyOrNullOrUndefined(annoDecretoDiNomina) ? annoDecretoDiNomina.match(/^(19|20)\d{2}$/) : true;
                const isCognomeValido = !Utils.isStringEmptyOrNullOrUndefined(as.cognome);
                const isNomeValido = !Utils.isStringEmptyOrNullOrUndefined(as.nome);
                const isCodiceFiscaleValido = Utils.isStringEmptyOrNullOrUndefined(as.identificativoUtente) || Utils.isValidCodiceFiscale(as.identificativoUtente);
                const isEmailValida = Utils.isStringEmptyOrNullOrUndefined(as.email) || Utils.isValidEmail(as.email);
                const isTelefonoValido = Utils.isStringEmptyOrNullOrUndefined(as.telefono) || Utils.isValidTelefono(as.telefono);
                let count = 0;

                if (as.conDelegaSanitaria) {
                    if (!isAnnoDecretoDiNominaValido) count++;
                    if (!isCognomeValido) count++;
                    if (!isNomeValido) count++;
                    if (!isCodiceFiscaleValido) count++;
                    if (!isEmailValida) count++;
                    if (!isTelefonoValido) count++;
                }

                return count;
            }).reduce((a, b) => a + b, 0);
        }

        return 0;
    }

    handleAggiornaForm = (field, elem, index) => {
        let amministratoriDiSostegno = _.cloneDeep(this.state.amministratoriDiSostegno);
        let amministratore = amministratoriDiSostegno[index];
        amministratore[field] = elem;

        if (field === "madrelingua" && !_.isEqual(elem, this.state.amministratoriDiSostegno[index][field])) {
            amministratore.livello = null;
            amministratore.descrizione = [];
        } else if (field === "conDelegaSanitaria" && (elem === null || elem === false)) {
            Object.keys(amministratore).forEach(key => {
                if (key !== "conDelegaSanitaria")
                    amministratore[key] = !Utils.isObjectNull(amministratore[key]) ? "" : amministratore[key];
            });
        }
        amministratoriDiSostegno[index] = amministratore;

        this.setState({amministratoriDiSostegno: amministratoriDiSostegno}, () => {
            this.controllaCampi();
        });
    }

    controllaCampi = (idAmministratoreDiSostegnoDaEliminare = null) => {
        let amministratoriDiSostegno = _.cloneDeep(this.state.amministratoriDiSostegno);
        this.props.onChangeDatiAmministratoreDiSostegno("amministratoreDiSostegno", amministratoriDiSostegno);

        // controllo la validità dei vari campi di ogni elemento della lista amministratoriDiSostegno
        this.validaSezione(amministratoriDiSostegno, idAmministratoreDiSostegnoDaEliminare);
    }

    validaSezione = (amministratoriDiSostegno, idAmministratoreDiSostegnoDaEliminare = null) => {
        const counterInvalidItems = this.countInvalidItems(amministratoriDiSostegno);
        this.props.deleteAmministratoreDiSostegno(idAmministratoreDiSostegnoDaEliminare);
        this.props.onChangeRequiredFieldsNumber("sottoSezioneAmministratoreDiSostegno", counterInvalidItems);
        this.props.onValidaSezione("isAmministratoreDiSostegnoValido", counterInvalidItems === 0);
    }

    addNewAmministratoreDiSostegno = () => {
        let amministratoriDiSostegno = _.cloneDeep(this.state.amministratoriDiSostegno);
        let amministratore = _.cloneDeep(defaultAmministratoreDiSostegno);
        amministratoriDiSostegno.push(amministratore);
        this.setState({amministratoriDiSostegno: amministratoriDiSostegno}, () => {
            this.controllaCampi();
        });
    }

    removeAmministratoreDiSostegno = (index) => {
        let amministratoriDiSostegno = _.cloneDeep(this.state.amministratoriDiSostegno);
        let idAmministratoreDiSostegnoDaEliminare = amministratoriDiSostegno[index].id;
        amministratoriDiSostegno.splice(index, 1);
        this.setState({amministratoriDiSostegno: amministratoriDiSostegno}, () => {
            this.controllaCampi(idAmministratoreDiSostegnoDaEliminare);
        });
    }

    renderLinguaParlata = (linguaParlata, indexListaLinguaParlata, indexListaAmministratori) => {
        return (
            <RoleBasedSelect
                fieldId={'LING_PARL.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                pageState={this.props.pageState}
                parentClass={"mb-0"}
                id={"selectLinguaParlata"}
                className={"selectLinguaParlata"}
                options={OggettiUtili.elencoLingue}
                value={linguaParlata}
                onChange={(elem) => this.handleAggiornaLinguaParlata(elem, indexListaLinguaParlata, indexListaAmministratori)}
                field={indexListaLinguaParlata}
                isSearchable={false}
                fieldLabel={"Lingua parlata"}
                handleOnlyValue={true}
                disableDoubleInput={true}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            />
        );
    }

    handleAggiornaLinguaParlata = (elem, indexListaLinguaParlata, indexListaAmministratori) => {
        let listaAmministratoriSostegno = _.cloneDeep(this.state.amministratoriDiSostegno);
        let currentAmministratore = listaAmministratoriSostegno[indexListaAmministratori];
        currentAmministratore.descrizione[indexListaLinguaParlata] = elem;
        listaAmministratoriSostegno[indexListaAmministratori] = currentAmministratore;

        this.setState({amministratoriDiSostegno: listaAmministratoriSostegno}, () => this.controllaCampi());
    }

    addNewLinguaParlata = (indexCurrentAmministratore) => {
        let listaAmministratoriSostegno = _.cloneDeep(this.state.amministratoriDiSostegno);
        let currentAmministratore = listaAmministratoriSostegno[indexCurrentAmministratore];
        currentAmministratore.descrizione.push(null);
        listaAmministratoriSostegno[indexCurrentAmministratore] = currentAmministratore;

        this.setState({amministratoriDiSostegno: listaAmministratoriSostegno}, () => this.controllaCampi());
    }

    removeLinguaParlata = (indexListaLinguaParlata, indexCurrentAmministratore) => {
        let listaAmministratoriSostegno = _.cloneDeep(this.state.amministratoriDiSostegno);
        let currentAmministratore = listaAmministratoriSostegno[indexCurrentAmministratore];
        currentAmministratore.descrizione.splice(indexListaLinguaParlata, 1);
        listaAmministratoriSostegno[indexCurrentAmministratore] = currentAmministratore;

        this.setState({amministratoriDiSostegno: listaAmministratoriSostegno}, () => this.controllaCampi());
    }

    campiObbligatoriValorizzati = (amministratore) => {
        return (!Utils.isStringEmptyOrNullOrUndefined(amministratore.nome) && !Utils.isStringEmptyOrNullOrUndefined(amministratore.cognome));
    }

    renderAmministratoreDiSostegno = (amministratore, index) => {
        return (
            <Fragment key={index}>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'CON_DEL_SAN.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        id={"selectDelegaSanitariaAmministratoreDiSostegno"}
                        className={"selectDelegaSanitariaAmministratoreDiSostegno"}
                        options={OggettiUtili.rispostaBreve}
                        value={amministratore.conDelegaSanitaria}
                        onChange={(elem) => this.handleAggiornaForm("conDelegaSanitaria", elem, index)}
                        isSearchable={false}
                        fieldLabel={"Con delega sanitaria?"}
                        handleOnlyValue={true}
                        field={"conDelegaSanitaria"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                {amministratore.conDelegaSanitaria &&
                <>
                    <FieldsRow>
                        <RoleBasedInput
                            fieldId={'NUM_DECR_NOM.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            placeholder={"Inserisci numero decreto di nomina"}
                            field={"numeroDecretoDiNomina"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            id={"inputNumeroDecretoDiNomina"}
                            value={amministratore.numeroDecretoDiNomina}
                            type={"number"}
                            min={0}
                            fieldLabel={"Numero decreto di nomina"}
                            disableDoubleInput={true}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        <RoleBasedInput
                            fieldId={'ANNO_DECR_NOM.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            placeholder={"Inserisci anno decreto di nomina"}
                            field={"annoDecretoDiNomina"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            id={"inputAnnoDecretoDiNomina"}
                            value={amministratore.annoDecretoDiNomina}
                            minLength={"4"}
                            maxLength={"4"}
                            invalidText={'Data non valida'}
                            validator={val => !val || val.match(/^(19|20)\d{2}$/)}
                            fieldLabel={"Anno decreto di nomina"}
                            disableDoubleInput={true}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        <RoleBasedInput
                            fieldId={'COGN.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            placeholder="Inserisci cognome"
                            field="cognome"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            id="inputCognomeAmministratoreDiSostegno"
                            value={amministratore.cognome}
                            fieldLabel={"Cognome"}
                            fieldRequired={true}
                            disableDoubleInput={true}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        <RoleBasedInput
                            fieldId={'NOME.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            placeholder="Inserisci nome Amministratore di sostegno"
                            field="nome"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            id="inputNomeAmministratoreDiSostegno"
                            value={amministratore.nome}
                            fieldRequired={true}
                            fieldLabel={"Nome"}
                            disableDoubleInput={true}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </FieldsRow>

                    <FieldsRow>
                        <RoleBasedInput
                            fieldId={'COD_FISC.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            placeholder="Inserisci codice fiscale"
                            field="identificativoUtente"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            id="inputCodiceFiscaleAmministratoreDiSostegno"
                            value={amministratore.identificativoUtente}
                            maxlength={"16"}
                            fieldLabel={"Codice fiscale"}
                            validator={Utils.isValidCodiceFiscale}
                            invalidText={"Inserire un codice fiscale valido"}
                            disableDoubleInput={true}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        <RoleBasedInput
                            fieldId={'INDIR.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            placeholder="Inserisci indirizzo"
                            field="indirizzo"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            id="inputIndirizzoAmministratoreDiSostegno"
                            value={amministratore.indirizzo}
                            fieldLabel={"Indirizzo"}
                            disableDoubleInput={true}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        <RoleBasedInput
                            fieldId={"NUMEROCIVICO.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE"}
                            pageState={this.props.pageState}
                            placeholder={"Inserisci numero civico"}
                            field={"numero"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            id={"inputNumeroCivico"}
                            value={amministratore.numero}
                            fieldLabel={'N°'}
                            disableDoubleInput={true}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </FieldsRow>

                    <FieldsRow>
                        <RoleBasedPhoneInput
                            fieldId={'TEL.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            placeholder={"Inserisci numero di telefono"}
                            field={"telefono"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            id={"inputNumeroTelefonoAmministratoreDiSostegno"}
                            value={amministratore.telefono}
                            fieldLabel={"Telefono"}
                            phonePrefix={null}
                            disableDoubleInput={true}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />

                        <RoleBasedInput
                            fieldId={'EMAIL.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                            fieldLabel={"E-mail"}
                            pageState={this.props.pageState}
                            placeholder={"Inserisci e-mail"}
                            field={"email"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            id={"inputEmailAmministratoreDiSostegno"}
                            value={amministratore.email}
                            validator={Utils.isValidEmail}
                            invalidText={"Inserire un'email valida"}
                            disableDoubleInput={true}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        <RoleBasedSelect
                            fieldId={'ITA_MADREL.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            id={"selectMadrelingua"}
                            className={"selectMadrelingua"}
                            options={OggettiUtili.rispostaBreve}
                            value={amministratore.madrelingua}
                            onChange={(elem) => this.handleAggiornaForm("madrelingua", elem, index)}
                            isSearchable={false}
                            fieldLabel={"Italiano madrelingua?"}
                            handleOnlyValue={true}
                            field={"madrelingua"}
                            disableDoubleInput={true}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </FieldsRow>
                    {amministratore.madrelingua === false &&
                    <FieldsRow containerClass={"align-self-center"}
                               childClass={"align-self-start"}>
                        <RoleBasedSelect
                            fieldId={'LING_ITA_PARL_COMPR.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                            pageState={this.props.pageState}
                            id={"selectLinguaItalianaParlataCompresa"}
                            className={"selectLinguaItalianaParlataCompresa"}
                            options={OggettiUtili.linguaItalianaParlataCompresa}
                            value={amministratore.livello}
                            onChange={(elem) => this.handleAggiornaForm("livello", elem, index)}
                            isSearchable={false}
                            fieldLabel={"Lingua italiana parlata e compresa"}
                            handleOnlyValue={true}
                            field={"livello"}
                            disableDoubleInput={true}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        <SectionList
                            title={'Lingua parlata'}
                            data={amministratore.descrizione}
                            renderSection={(e, i) => this.renderLinguaParlata(e, i, index)}
                            addNewSectionCallback={() => this.addNewLinguaParlata(index)}
                            removeSectionCallback={(i) => this.removeLinguaParlata(i, index)}
                            addButtonVisibilityHandler={amministratore.madrelingua === false
                            && (amministratore.descrizione?.length === 0 || amministratore.descrizione?.every(el => !Utils.isObjectNull(el)))}
                            pageState={this.props.pageState}
                            keyFieldId={'LING_PARL.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                            indentOffset={0}
                            field={'descrizione'}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </FieldsRow>}
                </>}
            </Fragment>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"amministratoreDiSostegno"}
                title={"Amministratore di sostegno"}
                required={this.countInvalidItems(this.state.amministratoriDiSostegno) > 0}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                disabled={this.props.datiTutoriForm?.length > 0}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}>
                <SectionList
                    addNewSectionCallback={this.addNewAmministratoreDiSostegno}
                    addButtonVisibilityHandler={!this.props.datiTutoriForm?.length && this.state.amministratoriDiSostegno?.every(amministratore => this.campiObbligatoriValorizzati(amministratore))}
                    data={_.cloneDeep(this.state.amministratoriDiSostegno)}
                    removeSectionCallback={this.removeAmministratoreDiSostegno}
                    title={'Amministratore di sostegno'}
                    renderSection={this.renderAmministratoreDiSostegno}
                    pageState={this.props.pageState}
                    keyFieldId={'CON_DEL_SAN.AMMINISTRATOREDISOSTEGNO.ANAGRAFEPAZIENTE'}
                    field={'amministratoreDiSostegno'}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </AccordionSezioneRichiesta>
        );
    }
}

AmministratoreDiSostegno.propTypes = propTypes
