import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import Statistica from "../../statistica/Statistica";
import Utils from "../../../utils/Utils";
import ValutazioneSection from "../../proposteAttivitaSuggerimenti/ValutazioneSection";
import enumQuestionario from "../../../enum/enumQuestionario.json";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import enumsUtente from "../../../enum/enumsUtente.json";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import {SessioneUtente} from "web-client-archetype";
import AuthUtils from "../../../utils/AuthUtils";

const propTypes = {
    datiGradoDiMobilitaForm: PropTypes.any,
    openAccordion: PropTypes.any,
    onChangeDatiGradoDiMobilitaForm: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onClickAccordion: PropTypes.func,
    pageState: PropTypes.any,
    field: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class GradoDiMobilita extends AccordionSecondLevel {

    state = {
        datiGradoDiMobilitaForm: _.cloneDeep(this.props.datiGradoDiMobilitaForm)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiGradoDiMobilitaForm, this.props.datiGradoDiMobilitaForm))
            this.forceUpdate(() => this.inizializzaForm());
    }

    inizializzaForm = () => {
        let datiGradoDiMobilitaForm = _.cloneDeep(this.props.datiGradoDiMobilitaForm);
        this.setState({datiGradoDiMobilitaForm: datiGradoDiMobilitaForm}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiGradoDiMobilitaForm}) => {
            datiGradoDiMobilitaForm[field] = elem;
            return {datiGradoDiMobilitaForm};
        }, () => this.validazioneDati());
    }

    validazioneDati = () => {
        let datiGradoDiMobilita = _.cloneDeep(this.state.datiGradoDiMobilitaForm);
        this.props.onChangeDatiGradoDiMobilitaForm("datiGradoDiMobilita", datiGradoDiMobilita);
        this.props.onChangeRequiredFieldsNumber("sottosezioneGradoDiMobilita", this.numeroCampiObbligatori());
    }

    renderBody = () => {
        const isMmgCurrentEvaluator = AuthUtils.isCurrentUserEvaluator(enumsUtente.ruoli.mmg, this.props.richiesta, this.context.forceUserRole, this.props.pageState);

        return (
            <>
                <Statistica
                    fieldId={'M_VMOB.GRADODIMOBILITA.ANAMNESIFISIOLOGICA'}
                    infermiereFieldId={'I_VMOB.GRADODIMOBILITA.CAPACITAECURADISE'}
                    pageState={this.props.pageState}
                    nomeLink={"Compila questionario \"Grado di mobilità – PMOB\""}
                    nomePunteggio={"Punteggio indice di Barthel (VMOB)"}
                    questionario={this.state.datiGradoDiMobilitaForm.PMOB}
                    profileName={'Profilo mobilità – Punteggio PMOB'}
                    profileFieldId={'M_PROF_FUNZ_PMOB.GRADODIMOBILITA.ANAMNESIFISIOLOGICA'}
                    onChange={(questionario) => this.handleAggiornaForm(enumQuestionario.tipoQuestionario.PMOB, questionario)}
                    profileInfermiereFieldId={'I_PROF_FUNZ_PMOB.GRADODIMOBILITA.CAPACITAECURADISE'}
                    richiesta={this.props.richiesta}
                    pathRichiestaQuestionario={"anamnesiFisiologica.datiGradoDiMobilita.PMOB"}
                    tipoQuestionario={enumQuestionario.tipoQuestionario.PMOB}
                    salvaBozza={this.props.salvaBozza}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    parentJsonPathSubObject={'pmob'}
                    scoreMandatoryForRole={enumsUtente.ruoli.mmg}
                    compilaQuestionarioObbligatorio={isMmgCurrentEvaluator ? true : false}
                    obbligatorieta={{
                        surveyType: enumQuestionario.tipoQuestionario.PMOB,
                        forceRequiredCompilation: isMmgCurrentEvaluator && Utils.isStringEmptyOrNullOrUndefined(this.state.datiGradoDiMobilitaForm?.PMOB?.punteggio)
                    }}
                />

                <ValutazioneSection
                    pageState={this.props.pageState}
                    ambito={'GRADO DI MOBILITÀ (PMOB)'}
                    parentFieldId={'GRADODIMOBILITA.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'GRADODIMOBILITA.CAPACITAECURADISE'}
                    appendRolePrefix={true}
                    onChangeStatoDopoValutazione={(elem) => this.handleAggiornaForm("statoDopoValutazione", elem)}
                    valueStatoDopoValutazione={this.state.datiGradoDiMobilitaForm.statoDopoValutazione}
                    onChangeDataValutazione={(elem) => this.handleAggiornaForm("dataValutazione", elem)}
                    valueDataValutazione={this.state.datiGradoDiMobilitaForm.dataValutazione}
                    requiredDataValutazione={true}
                    onChangeDaConsiderare={(elem) => this.handleAggiornaForm("daConsiderare", elem)}
                    valueDaConsiderare={this.state.datiGradoDiMobilitaForm.daConsiderare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={this.state.datiGradoDiMobilitaForm.propostaAttivitaSuggerimenti}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={this.state.datiGradoDiMobilitaForm.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={this.state.datiGradoDiMobilitaForm.autonomiaGestioneCaregiver}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                />
            </>
        );
    }


    numeroCampiObbligatori = () => {
        if (this.props.forceReadOnly || this.context.forceReadOnly) return 0;

        let campiObbligatori = 0;

        campiObbligatori += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(this.state.datiGradoDiMobilitaForm?.propostaAttivitaSuggerimenti);

        if (Utils.isStateRichiesta(this.props.pageState) && Utils.isStringEmptyOrNullOrUndefined(this.state.datiGradoDiMobilitaForm?.PMOB?.valutazione)) {
            campiObbligatori++;
        }

        if (AuthUtils.isCurrentUserEvaluator(enumsUtente.ruoli.mmg, this.props.richiesta, this.context.forceUserRole, this.props.pageState) && Utils.isStringEmptyOrNullOrUndefined(this.state.datiGradoDiMobilitaForm?.PMOB?.punteggio)) {
            campiObbligatori++;
        }

        if (this.state.datiGradoDiMobilitaForm.statoDopoValutazione === "02" && Utils.isStringEmptyOrNullOrUndefined(this.state.datiGradoDiMobilitaForm.dataValutazione)) {
            campiObbligatori++;
        }

        return campiObbligatori;
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiGradoDiMobilita"}
                title={"Grado Di Mobilità (PMOB)"}
                required={this.numeroCampiObbligatori() > 0}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                modeAccordion={false}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}
GradoDiMobilita.propTypes = propTypes;

GradoDiMobilita.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

GradoDiMobilita.contextType = RoleBasedContext;
