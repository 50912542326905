import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Breadcrumb from "../../breadcrumb/Breadcrumb";
import RichiestaADIService from "../../../service/RichiestaADIService";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import * as BsIcon from "react-bootstrap-icons";
import Utils from "../../../utils/Utils";
import routepath from "../../../utils/route/route-path"
import {Redirect} from "react-router-dom";
import SvamaQuestionari from "./SvamaQuestionari";
import AltriQuestionari from "./AltriQuestionari";
import ButtonsBoxStick from "../../buttonsBoxStick/buttonsBoxStick";
import {getRichiestaQuestionario} from "../../../utils/QuestionariUtils";


function ScalaEQuestionari ({uuidRichiesta}) {

    const [questionario, setQuestionario] = useState();
    const [richiesta, setRichiesta] = useState({});
    const [openQuestionario, setOpenQuestionario] = useState({
        "openAccordionQuestionariSvama": true,
        "openAccordionAltriQuestionari": true
    });

    useEffect(() => {
        if (uuidRichiesta) {recuperaRichiesta()}
    }, [uuidRichiesta]);

    const onClickAccordion = (field, value) => {}

    const recuperaRichiesta = () => {
        RichiestaADIService.letturaRichiesta(uuidRichiesta, false)
            .subscribe(res => {setRichiesta(res?.data)})
    }

    const sottoTitolo = (
        <div className="mb-2 d-flex align-items-center">
            <div className="mr-2"><BsIcon.People width={25} height={25} /></div>
            <strong>Valutazione multidimensionale (Team)</strong>
        </div>
    );

    const dataCompletamento = (<div className="mb-2">Data di compilazione: {Utils.convertUtcToLocalDate(richiesta?.timestampCreazione).format("DD/MM/YYYY")}</div>)

    const redirectToCompilaQuestionarioF = () => {
        let baseUrl = routepath.valutazione_multidimensionale;
        return <Redirect to={{
            pathname: baseUrl.concat(routepath.compila_questionario),
            richiesta: richiesta,
            tipoQuestionario: questionario.tipoQuestionario,
            pageState: "V",
            pathRichiestaQuestionario: questionario.pathRichiestaQuestionario,
            disabilitato: true,
            tornaIndietro: routepath.storico_richieste.concat(routepath.scalaEquestionario)
        }} />;
    }

    const verificaQuestionarioCompilado = (questionario) => {
        return !!(getRichiestaQuestionario(richiesta, questionario.pathRichiestaQuestionario)[questionario.tipoQuestionario]?.sezioni.find(a => a.domande.length));
     }

     
    if (questionario) return redirectToCompilaQuestionarioF();
    return (
        <>
            <Breadcrumb path={['Home', 'Storico richieste', 'Visualizza Scale e Questionari']} profileAction={true} richiesta={richiesta} />
            <div className="px-5 pt-3">
                <AccordionAvvioRichiesta
                    idAccordion={"scalaQuestionariAccordion"}
                    title={"Scala e Questionari (In questa sezione è possibile consultare le scale e i questionari compilati in valutazione)"}
                    openAccordion={openQuestionario}
                    onClickAccordion={onClickAccordion}
                    field={"scalaQuestionariAccordion"}>

                    <SvamaQuestionari
                        openQuestionario={openQuestionario}
                        onClickAccordion={onClickAccordion}
                        sottoTitolo={sottoTitolo}
                        dataCompletamento={dataCompletamento}
                        setQuestionario={(questionario) => setQuestionario(questionario)}
                        verificaQuestionarioCompilado={(questionario) => verificaQuestionarioCompilado(questionario)}
                    />

                    <AltriQuestionari
                        openQuestionario={openQuestionario}
                        onClickAccordion={onClickAccordion}
                        sottoTitolo={sottoTitolo}
                        dataCompletamento={dataCompletamento}
                        setQuestionario={(questionario) => setQuestionario(questionario)}
                        verificaQuestionarioCompilado={(questionario) => verificaQuestionarioCompilado(questionario)}
                    />

                </AccordionAvvioRichiesta>
                <ButtonsBoxStick
                    parentClass="row"
                    btnList={[
                        {
                            id: "indietroBtn",
                            text: "Indietro",
                            className: "btnGreenOutline",
                            isLink: true,
                            to: routepath.storico_richieste,
                            isvisible: true,
                            aling: "start"
                        },
                    ]}
                />
            </div>
        </>

    )
}

ScalaEQuestionari.propTypes = {
    richiesta: PropTypes.string,
};

ScalaEQuestionari.defaultProps = {
    richiesta: {},
};

export default ScalaEQuestionari;
