import React, {useState, useEffect, Fragment, useContext} from "react";
import PropTypes from "prop-types";
import {SectionList, FieldsGroup, RoleBasedText} from "../roleBasedComponents/RoleBasedComponents";
import _, {isArray} from "lodash";
import ProposteAttivitaSuggerimentiSection, {defaultProposteAttivitaSuggerimenti} from "./ProposteAttivitaSuggerimentiSection";
import AuthUtils from "../../utils/AuthUtils";
import {SessioneUtente} from "web-client-archetype";
import {v4 as uuid} from "uuid";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import Utils from "../../utils/Utils";
import {useProposteAttivitaEsuggerimenti} from "../../hook/useProposteAttivitaEsuggerimenti";

export const contaNumeroCampiObbligatoriInPropostaAttivitaEsugg = (currentProposteAttivitaSuggerimenti) => {
    let proposteAttivitaSuggerimenti = _.cloneDeep(currentProposteAttivitaSuggerimenti);
    proposteAttivitaSuggerimenti = isArray(currentProposteAttivitaSuggerimenti) ? currentProposteAttivitaSuggerimenti.filter(a => a) : [];
    let numeroCampiObbligatoriDaValorizzare = 0;

    if (!proposteAttivitaSuggerimenti?.length) return numeroCampiObbligatoriDaValorizzare;
    
    const checkedVuouto = proposteAttivitaSuggerimenti
                            .filter(e => (e.cvp.checked || e.farmaci.checked || e.nomenclatore.checked) && 
                                         (!e.altro.checked) && 
                                         (!e.cvp.valori.length && !e.farmaci.valori.length && !e.nomenclatore.valori.length))?.length
                                
                                
            
    const notChecked = proposteAttivitaSuggerimenti.filter(e => !e.altro.checked && !e.cvp.checked && !e.farmaci.checked && !e.nomenclatore.checked)?.length
    const altroList  = proposteAttivitaSuggerimenti.map(e => e.altro)?.filter(a => a.checked && !a.valore)?.length 
    const cvpList =  proposteAttivitaSuggerimenti.map(e => e.cvp)?.filter(a => a.checked &&  (a.valori.length && a.valori.find(g => !g.valore)))?.length
    const nomenclatoreList =  proposteAttivitaSuggerimenti.map(e => e.nomenclatore)?.filter(a => a.checked && (a.valori.length && a.valori.find(g => !g.valore)))?.length
    const farmaciList = proposteAttivitaSuggerimenti.map(e => e.farmaci).filter(a => a.checked &&  ((a.valori.length && a.valori.find(g => !g.codiceAIC && !g.classeDiEquivalenza))))?.length;
    

    return (Number(altroList ?? 0) + Number(cvpList ?? 0) + Number(farmaciList ? farmaciList * 2 : 0) + Number(nomenclatoreList ?? 0) + Number(notChecked ?? 0) + Number(checkedVuouto ?? 0));
}

export function ProposteAttivitaSuggerimentiList ({suggerimenti,
    infParentFieldId,
    appendRolePrefix,
    fieldsPrefix,
    fieldsSuffix,
    checkboxIdSuffix,
    fieldJsonPath,
    listId,
    pageState,
    ambito,
    fieldId,
    parentFieldId,
    forceReadOnly,
    fieldInAccordionReadOnly,
    hideUnsetValues,
    disableDoubleInput,
    onChange,
    parentJsonPath,
    id,
    unicheParentId    
}) {

    const [currentSuggerimenti, setCurrentSuggerimento] = useState();
    const context = useContext(RoleBasedContext);

    const {
        addPropostaAttivitaEsuggGlobal,
        deletePropostaAttivitaEsuggGlobal,
        editPropostaAttivitaEsuggGlobal,
        setPropostaAttivitaEsuggGlobal
     } = useProposteAttivitaEsuggerimenti();

    useEffect(() => {
        const condizioneForSet = (suggerimenti && suggerimenti !== currentSuggerimenti);
        if (condizioneForSet) {
            setCurrentSuggerimento(suggerimenti.filter(a => a) || []);
            setPropostaAttivitaEsuggGlobal(suggerimenti.filter(a => a) || [])
        }
        
        
    }, [suggerimenti]);


    const renderPropostaAttivitaSuggerimento = (proposta, index, readOnly) => {     
        const {altro, cvp, farmaci, nomenclatore} = proposta;
        const notChecked = !(altro?.checked) && (!(cvp?.checked) || !(cvp?.valori.length)) && (!(farmaci?.checked) || !(farmaci?.valori.length)) && (!(nomenclatore?.checked) || !(nomenclatore?.valori.length))
        return <Fragment key={index}>
            <RoleBasedText
                pageState={pageState}
                value={proposta?.propostaAttivitaSuggerimenti?.propostaEffettuataDa || proposta?.propostaEffettuataDa}
                field={"propostaEffettuataDa"}
                id={"propostaEffettuataDa"}
                fieldLabel={'Proposta effettuata da'}
                showFieldIfRequiredAccordionPage={true}
                fieldId={"X_PROP_ATT_SUGG.PROPOSTEATTIVITAESUGGERIMENTI.EFFETTUATADA"}
            />
            <ProposteAttivitaSuggerimentiSection
                id={id}
                pageState={pageState}
                ambito={ambito}
                fieldId={fieldId}
                value={currentSuggerimenti[index]}
                onChange={(field, elem) => handleChange(field, elem, index)}
                parentFieldId={parentFieldId}
                forceReadOnly={forceReadOnly || readOnly}
                fieldInAccordionReadOnly={fieldInAccordionReadOnly}
                hideUnsetValues={hideUnsetValues}
                disableDoubleInput={disableDoubleInput}
                infParentFieldId={infParentFieldId}
                appendRolePrefix={appendRolePrefix}
                fieldsPrefix={fieldsPrefix}
                fieldsSuffix={fieldsSuffix}
                checkboxIdSuffix={checkboxIdSuffix}
                fieldJsonPath={fieldJsonPath}
                listId={listId}
                unicheParentId={unicheParentId ? unicheParentId + proposta?.id : proposta?.id} 
                showLabelCampoObbligatorio={notChecked}
                parentJsonPath={parentJsonPath}/>
        </Fragment>
    }

    const handleChange = (field, elem, index) => {
        let currentProposteAttivitaSuggerimenti = _.cloneDeep(currentSuggerimenti);
        currentProposteAttivitaSuggerimenti[index] = elem;
        onChange(field, currentProposteAttivitaSuggerimenti);
        editPropostaAttivitaEsuggGlobal(currentProposteAttivitaSuggerimenti[index])
    }

    const addNewPropostaAttivitaSuggerimento = () => {
        let currentProposteAttivitaSuggerimenti = _.cloneDeep(currentSuggerimenti);
        if(!currentProposteAttivitaSuggerimenti){
            currentProposteAttivitaSuggerimenti = [];
        }
        const newProposta = {
            ..._.cloneDeep(defaultProposteAttivitaSuggerimenti),
            id: uuid(),
            propostaEffettuataDa: AuthUtils.getInfoUtente(),
            codiceFiscale: SessioneUtente.getInstance().idUtente
        }

        currentProposteAttivitaSuggerimenti.push(newProposta);
        setCurrentSuggerimento(currentProposteAttivitaSuggerimenti);
        onChange("propostaAttivitaSuggerimenti", currentProposteAttivitaSuggerimenti);
        addPropostaAttivitaEsuggGlobal(newProposta)
    }

    const removePropostaAttivitaSuggerimento = (index) => {
        let currentProposteAttivitaSuggerimenti = _.cloneDeep(currentSuggerimenti);
        deletePropostaAttivitaEsuggGlobal(currentProposteAttivitaSuggerimenti[index]);
        currentProposteAttivitaSuggerimenti.splice(index, 1);
        setCurrentSuggerimento(currentProposteAttivitaSuggerimenti);
        onChange("propostaAttivitaSuggerimenti", currentProposteAttivitaSuggerimenti);
    }


    const proposteIsReadOnly = context.forceReadOnly || forceReadOnly ||
    (isArray(currentSuggerimenti) && currentSuggerimenti?.map(p => Utils.isStateValutazione(pageState)
     && ((p?.codiceFiscale && p?.codiceFiscale !== SessioneUtente.getInstance().idUtente) || (p?.propostaAttivitaSuggerimenti?.codiceFiscale && p?.propostaAttivitaSuggerimenti?.codiceFiscale !== SessioneUtente.getInstance().idUtente) || (p?.proposta?.codiceFiscale && p?.proposta?.codiceFiscale !== SessioneUtente.getInstance().idUtente))));
     
    const getFieldId = (isInfermiere = null) => {
        const mRolePrefix = appendRolePrefix ? 'M_' : '';
        const iRolePrefix = appendRolePrefix ? 'I_' : null;
        const fieldsPrefixVar = fieldsPrefix ? fieldsPrefix + '_' : '';
        const fieldsSuffixVar = fieldsSuffix ? '_' + fieldsSuffix : '';
        const fieldId = mRolePrefix + fieldsPrefixVar + 'PROP_ATT_SUGG' + fieldsSuffixVar + '.' + parentFieldId;
        const infermiereFieldId = iRolePrefix ? (iRolePrefix + fieldsPrefixVar + 'PROP_ATT_SUGG' + fieldsSuffixVar + '.' + infParentFieldId) : null;
        if(isInfermiere) return infermiereFieldId
        return fieldId
    }

    if(context.showOnlyRequiredFields) return null;

    return (
        <SectionList
            title={"Proposta di attività"}
            data={currentSuggerimenti}
            renderSection={renderPropostaAttivitaSuggerimento}
            addNewSectionCallback={addNewPropostaAttivitaSuggerimento}
            removeSectionCallback={removePropostaAttivitaSuggerimento}
            forceReadOnly={proposteIsReadOnly}
            fieldInAccordionReadOnly={fieldInAccordionReadOnly}
            pageState={pageState}
            field={'currentProposteAttivitaSuggerimenti'}
            addButtonVisibilityHandler={!(context.forceReadOnly || forceReadOnly) && contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(currentSuggerimenti) === 0}
            addButtonLabel={"Aggiungi proposte di attività e suggerimenti"}
            keyFieldId={getFieldId()}
            infermiereKeyFieldId={getFieldId("infermiere")}
            parentJsonPath={parentJsonPath}
        />
    );
}

ProposteAttivitaSuggerimentiList.propTypes = {
    suggerimenti: PropTypes.array,
    pageState: PropTypes.string,
    infParentFieldId: PropTypes.string,
    appendRolePrefix: PropTypes.bool,
    fieldsPrefix: PropTypes.string,
    fieldsSuffix: PropTypes.string,
    checkboxIdSuffix: PropTypes.string,
    fieldJsonPath: PropTypes.string,
    listId: PropTypes.arrayOf(PropTypes.object),
    ambito: PropTypes.string.isRequired,
    fieldId: PropTypes.string,
    parentFieldId: PropTypes.string.isRequired,
    forceReadOnly: PropTypes.bool,
    fieldInAccordionReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    parentJsonPath: PropTypes.string,
    id:PropTypes.string,
};

ProposteAttivitaSuggerimentiList.defaultProps = {
    suggerimenti: [],
    pageState: "",
    readOnly: false,
    fieldInAccordionReadOnly: false
};


