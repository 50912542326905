import React, {Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {
    FieldsGroup,
    FieldsRow,
    RoleBasedInput,
    RoleBasedPhoneInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import {isMadrelingua} from "../datiGenerali/DatiGenerali";

const propTypes = {
    tutoriForm: PropTypes.array,
    datiAmministratoreDiSostegno: PropTypes.any,
    onChangeDatiTutore: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onValidaSezione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    deleteTutore: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultTutore = {
    id: null,
    cognome: "",
    nome: "",
    identificativoUtente: "",
    indirizzo: "",
    numero: "",
    telefono: "",
    email: "",
    madrelingua: null,
    livello: null,
    descrizione: []
}

export default class Tutore extends AccordionSecondLevel {

    state = {
        tutori: _.cloneDeep(this.props.tutoriForm)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.tutoriForm, this.props.tutoriForm))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let listaTutore = _.cloneDeep(this.props.tutoriForm);
        if (!Utils.isObjectNull(listaTutore) && listaTutore.length > 0) {
            listaTutore = listaTutore.map(tutore => {
                let tutoreMap = tutore;
                tutoreMap.madrelingua = OggettiUtili.rispostaBreve.find(el => el.value === tutore.madrelingua) ?? null;
                tutoreMap.livello = OggettiUtili.linguaItalianaParlataCompresa.find(el => el.value === tutore.livello) ?? null;
                if (tutore.descrizione.length > 0) {
                    tutoreMap.descrizione = tutore.descrizione.map(el => OggettiUtili.elencoLingue.find(el2 => el2.value === el) ?? null);
                }
                return tutoreMap;
            });
        }
        this.setState({tutori: listaTutore}, () => this.contaCampiObbligatoriDaValorizzareAndValida(this.state.tutori));
    }

    handleAggiornaForm = (field, elem, index) => {
        let tutori = _.cloneDeep(this.state.tutori);
        let tutore = tutori[index];
        tutore[field] = elem;

        if (field === "madrelingua" && !_.isEqual(elem, this.state.tutori[index][field])) {
            tutore.livello = null;
            tutore.descrizione = [];
        }
        tutori[index] = tutore;

        this.setState({tutori: tutori}, () => this.controllaCampi());
    }

    controllaCampi = (idTutoreDaEliminare = null) => {
        let tutori = _.cloneDeep(this.state.tutori);

        // formatto l'oggetto per inviarlo come parametro di input al metodo salvaBozza nel componente padre "CompilaRichiesta"
        tutori = tutori.map(tutore => {
            let tutoreMap = tutore;
            tutoreMap.madrelingua = tutore.madrelingua?.value;
            tutoreMap.livello = tutore.livello?.value;
            if (tutore.descrizione.length > 0) {
                tutoreMap.descrizione = tutore.descrizione.map(el => el?.value ?? null);
            }
            return _.mapValues(tutoreMap, el => el === null || el === undefined || el === "" ? null : el);
        });
        this.props.onChangeDatiTutore("tutore", tutori);

        // controllo la validità dei vari campi di ogni elemento della lista tutori e nel caso in cui ci siano campi obbligatori non valorizzati li conto
        this.contaCampiObbligatoriDaValorizzareAndValida(tutori, idTutoreDaEliminare);
    }

    contaCampiObbligatoriDaValorizzareAndValida = (tutori, idTutoreDaEliminare = null) => {
        let isSezioneValida;
        let numeroCampiObbligatoriDaValorizzare = 0;

        if (tutori.length > 0) {
            tutori.forEach(tutore => {
                if (!tutore.nome || !tutore.cognome) {
                    if (!tutore.nome && !tutore.cognome) numeroCampiObbligatoriDaValorizzare = (numeroCampiObbligatoriDaValorizzare + 2) 
                    else numeroCampiObbligatoriDaValorizzare++;
                    isSezioneValida = false;
                } else {
                    let isCodiceFiscaleValido = ((!Utils.isStringEmptyOrNullOrUndefined(tutore.identificativoUtente) && Utils.isValidCodiceFiscale(tutore.identificativoUtente))
                        || Utils.isStringEmptyOrNullOrUndefined(tutore.identificativoUtente));
                    let isTelefonoValido = ((!Utils.isStringEmptyOrNullOrUndefined(tutore.telefono) && Utils.isValidTelefono(tutore.telefono))
                        || Utils.isStringEmptyOrNullOrUndefined(tutore.telefono));
                    let isEmailValida = ((!Utils.isStringEmptyOrNullOrUndefined(tutore.email) && Utils.isValidEmail(tutore.email))
                        || Utils.isStringEmptyOrNullOrUndefined(tutore.email));
                    isSezioneValida = isCodiceFiscaleValido && isTelefonoValido && isEmailValida;
                }
            });
        } else {
            isSezioneValida = true;
        }

        this.props.deleteTutore(idTutoreDaEliminare);
        this.props.onChangeRequiredFieldsNumber("sottoSezioneTutore", numeroCampiObbligatoriDaValorizzare);
        this.props.onValidaSezione("isTutoreValido", isSezioneValida);
    }

    addNewTutore = () => {
        let tutori = _.cloneDeep(this.state.tutori);
        let tutore = _.cloneDeep(defaultTutore);
        tutori.push(tutore);
        this.setState({tutori: tutori}, () => this.controllaCampi());
    }

    removeTutore = (index) => {
        let tutori = _.cloneDeep(this.state.tutori);
        let idTutoreDaEliminare = tutori[index].id;
        tutori.splice(index, 1);
        this.setState({tutori: tutori}, () => this.controllaCampi(idTutoreDaEliminare));
    }

    renderLinguaParlata = (linguaParlata, indexListaLinguaParlata, indexListaTutori) => {
        return (
            <RoleBasedSelect
                fieldId={'LING_PARL.TUTORE.ANAGRAFEPAZIENTE'}
                pageState={this.props.pageState}
                parentClass={"mb-0"}
                id={"selectLinguaParlata"}
                className={"selectLinguaParlata"}
                options={OggettiUtili.elencoLingue}
                value={linguaParlata}
                onChange={(elem) => this.handleAggiornaLinguaParlata(elem, indexListaLinguaParlata, indexListaTutori)}
                isSearchable={false}
                fieldLabel={"Lingua parlata"}
                field={"linguaParlata"}
                disableDoubleInput={true}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            />
        );
    }

    handleAggiornaLinguaParlata = (elem, indexListaLinguaParlata, indexListaTutori) => {
        let listaTutori = _.cloneDeep(this.state.tutori);
        let currentTutore = listaTutori[indexListaTutori];
        currentTutore.descrizione[indexListaLinguaParlata] = elem;
        listaTutori[indexListaTutori] = currentTutore;

        this.setState({tutori: listaTutori}, () => this.controllaCampi());
    }

    addNewLinguaParlata = (indexCurrentTutore) => {
        let listaTutori = _.cloneDeep(this.state.tutori);
        let currentTutore = listaTutori[indexCurrentTutore];
        currentTutore.descrizione.push(null);
        listaTutori[indexCurrentTutore] = currentTutore;

        this.setState({tutori: listaTutori}, () => this.controllaCampi());
    }

    removeLinguaParlata = (indexListaLinguaParlata, indexCurrentTutore) => {
        let listaTutori = _.cloneDeep(this.state.tutori);
        let currentTutore = listaTutori[indexCurrentTutore];
        currentTutore.descrizione.splice(indexListaLinguaParlata, 1);
        listaTutori[indexCurrentTutore] = currentTutore;

        this.setState({tutori: listaTutori}, () => this.controllaCampi());
    }

    renderNewTutore = (tutore, index) => {
        return (
            <Fragment key={index}>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={"COGN_TUT.TUTORE.ANAGRAFEPAZIENTE"}
                        pageState={this.props.pageState}
                        placeholder="Inserisci cognome Tutore"
                        field="cognome"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        id="inputCognomeTutore"
                        value={tutore.cognome}
                        fieldLabel={"Cognome Tutore"}
                        fieldRequired={true}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldId={'NOME_TUT.TUTORE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci nome Tutore"
                        field="nome"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        id="inputNomeTutore"
                        value={tutore.nome}
                        fieldLabel={"Nome Tutore"}
                        fieldRequired={true}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <FieldsGroup fieldLabel={"Codice fiscale Tutore"}
                                 showFieldIfRequiredAccordionPage={false}
                                 fieldInAccordionReadOnly={!!this.props.accordionReadOnly}>
                        <RoleBasedInput
                            fieldId={"COD_FISC_TUT.TUTORE.ANAGRAFEPAZIENTE"}
                            pageState={this.props.pageState}
                            placeholder="Inserisci codice fiscale Tutore"
                            field="identificativoUtente"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                            id="inputCodiceFiscaleTutore"
                            value={tutore.identificativoUtente}
                            maxlength={"16"}
                            disableDoubleInput={true}
                            validator={Utils.isValidCodiceFiscale}
                            invalidText={"Inserire un codice fiscale valido"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </FieldsGroup>
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={"INDIR_TUT.TUTORE.ANAGRAFEPAZIENTE"}
                        pageState={this.props.pageState}
                        placeholder="Inserisci indirizzo Tutore"
                        field="indirizzo"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        id="inputIndirizzoTutore"
                        value={tutore.indirizzo}
                        fieldLabel={"Indirizzo"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldId={"NUMEROCIVICO_TUT.TUTORE.ANAGRAFEPAZIENTE"}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci numero civico"}
                        field={"numero"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        id={"inputNumeroCivico"}
                        value={tutore.numero}
                        fieldLabel={'N°'}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedPhoneInput
                        fieldLabel={"Telefono Tutore"}
                        fieldId={'TEL_TUT.TUTORE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci numero di telefono Tutore"}
                        field={"telefono"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        id={"inputNumeroTelefonoTutore"}
                        value={tutore.telefono}
                        phonePrefix={null}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldLabel={"E-mail Tutore"}
                        fieldId={'EMAIL_TUT.TUTORE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci e-mail Tutore"}
                        field={"email"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                        id={"inputEmailTutore"}
                        value={tutore.email}
                        validator={Utils.isValidEmail}
                        invalidText={"Inserire un'email valida"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'ITA_MADREL.TUTORE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        id={"selectMadrelingua"}
                        className={"selectMadrelingua"}
                        options={OggettiUtili.rispostaBreve}
                        value={tutore.madrelingua}
                        onChange={(elem) => this.handleAggiornaForm("madrelingua", elem, index)}
                        isSearchable={false}
                        fieldLabel={"Italiano madrelingua?"}
                        field={"madrelingua"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                <FieldsRow containerClass={"align-self-center"}
                           childClass={"align-self-start"}>
                    {!isMadrelingua(tutore.madrelingua) &&
                    <RoleBasedSelect
                        fieldId={'LING_ITA_PARL_COMPR.TUTORE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        id={"selectLinguaItalianaParlataCompresa"}
                        className={"selectLinguaItalianaParlataCompresa"}
                        options={OggettiUtili.linguaItalianaParlataCompresa}
                        value={tutore.livello}
                        onChange={(elem) => this.handleAggiornaForm("livello", elem, index)}
                        isSearchable={false}
                        fieldLabel={"Lingua italiana parlata e compresa"}
                        field={"livello"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />}
                </FieldsRow>
                <FieldsRow>
                    {!isMadrelingua(tutore.madrelingua) &&
                        <SectionList
                            title={'Lingua parlata'}
                            data={tutore.descrizione}
                            renderSection={(e, i) => this.renderLinguaParlata(e, i, index)}
                            addNewSectionCallback={() => this.addNewLinguaParlata(index)}
                            removeSectionCallback={(i) => this.removeLinguaParlata(i, index)}
                            addButtonVisibilityHandler={!isMadrelingua(tutore.madrelingua)
                                && (tutore.descrizione?.length === 0 || tutore.descrizione?.every(el => !Utils.isObjectNull(el)))}
                            pageState={this.props.pageState}
                            keyFieldId={'LING_PARL.TUTORE.ANAGRAFEPAZIENTE'}
                            indentOffset={0}
                            field={'descrizione'}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />}
                </FieldsRow>
                
            </Fragment>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"tutore"}
                title={"Tutore"}
                required={this.state.tutori.some(tutore => !tutore.cognome || !tutore.nome)}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                disabled={this.props.datiAmministratoreDiSostegno?.length > 0}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}>
                <SectionList
                    title={'Tutore'}
                    data={_.cloneDeep(this.state.tutori)}
                    addNewSectionCallback={this.addNewTutore}
                    removeSectionCallback={this.removeTutore}
                    renderSection={this.renderNewTutore}
                    addButtonVisibilityHandler={!this.props.datiAmministratoreDiSostegno?.length && !(this.state.tutori.find(tutore => !tutore.nome || !tutore.cognome ))}
                    pageState={this.props.pageState}
                    keyFieldId={'NOME_TUT.TUTORE.ANAGRAFEPAZIENTE'}
                    field={'tutori'}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </AccordionSezioneRichiesta>
        );
    }
}

Tutore.propTypes = propTypes;
