import React from "react";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import PropTypes from "prop-types";
import Parametri from "./parametri/Parametri";
import * as _ from "lodash";
import AccordionHelper from "../../service/AccordionHelper";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import VersionedAccordion from "../VersionedAccordion";
import enumRichiesta from "../../enum/enumRichiesta.json"


const propTypes = {
    datiParametriVitali: PropTypes.any,
    onChangeDatiParametriVitali: PropTypes.func,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    punteggioTestEsasr: PropTypes.any,
    addInformazioniAggiuntive: PropTypes.func,
    disableDoubleInput: PropTypes.bool
}

const defaultProps = {
    salvaBozza: () => { },
    onClickAccordion: () => { }
}

export default class ParametriVitali extends AccordionFirstLevel {
    state = {
        accordion: {
            openAccordionParametriVitali: true
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta.parametri = this.props.datiParametriVitali;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({ accordion: accordion });
        }
    }

    hiddenAccordion = (dati) => {
        let isEmpty = null;
        if (dati.pageState === 'DP-PDV' || dati.pageState === 'DP-V') {
            isEmpty = Object.values(dati.datiParametriVitali).every(x => (x === null || x === ''));
        } else {
            isEmpty = true;
        }
        return isEmpty
    }

    renderBodyParametriVitali = () => {
        return (
            <>
                {this.hiddenAccordion(this.props) &&
                    <VersionedAccordion accordion={Parametri}
                        datiParametriVitali={this.props.datiParametriVitali.datiParametri}
                        onChangeDatiParametriVitali={this.props.onChangeDatiParametriVitali}
                        openAccordion={this.state.accordion.openAccordionParametriVitali}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionParametriVitali"}
                        mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                        showAccordion={true}
                        pageState={this.props.pageState}
                        punteggioTestEsasr={this.props.punteggioTestEsasr}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />}
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"parametriVitali"}
                title={"Parametri vitali"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
            >{this.renderBodyParametriVitali()}</AccordionAvvioRichiesta>
        );
    }
}

ParametriVitali.propTypes = propTypes;
ParametriVitali.defaultProps = defaultProps;
