import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import oggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {calcolaNumeroCampiObbligatoriDaValorizzareAndValida} from "../padre/Padre";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {
    FieldsRow,
    RoleBasedInput,
    RoleBasedPhoneInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import {isMadrelingua} from "../datiGenerali/DatiGenerali";

const propTypes = {
    datiMadreForm: PropTypes.any,
    onChangeDatiMadre: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onValidaSezione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    forceReadOnly: PropTypes.bool,
    mascheraModificabilita: PropTypes.any,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class Madre extends AccordionSecondLevel {
    state = {
        datiMadre: _.cloneDeep(this.props.datiMadreForm)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiMadreForm, this.props.datiMadreForm))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiMadre = _.cloneDeep(this.props.datiMadreForm);
        datiMadre.isMadreIndicataComeRiferimentoPerPaziente = oggettiUtili.rispostaBreve.find(el => el.value === datiMadre.isMadreIndicataComeRiferimentoPerPaziente);
        datiMadre.madrelingua = oggettiUtili.rispostaBreve.find(el => el.value === datiMadre.madrelingua);
        datiMadre.livello = oggettiUtili.linguaItalianaParlataCompresa.find(el => el.value === datiMadre.livello);
        if (datiMadre.descrizione.length > 0) {
            datiMadre.descrizione = datiMadre.descrizione.map(el => oggettiUtili.elencoLingue.find(el2 => el2.value === el));
        }
        this.setState({datiMadre: datiMadre}, () => {
            calcolaNumeroCampiObbligatoriDaValorizzareAndValida(this.props, this.state.datiMadre, "id",
                "isMadreIndicataComeRiferimentoPerPaziente",
                "sottoSezioneMadre", "isMadreValido", this.props.pageState);
        });
    }

    handleAggiornaForm = (field, elem) => {
        let datiMadre = _.cloneDeep(this.state.datiMadre);
        datiMadre[field] = elem;

        if (field === "isMadreIndicataComeRiferimentoPerPaziente" && !_.isEqual(elem, this.state.datiMadre[field])) {
            datiMadre.indirizzo = "";
            datiMadre.numero = "";
            datiMadre.telefono = "";
            datiMadre.email = "";
        }
        if (field === "madrelingua" && !_.isEqual(elem, this.state.datiMadre[field])) {
            datiMadre.livello = null;
            datiMadre.descrizione = [];
        }

        this.setState({datiMadre: datiMadre}, () => {
            this.controllaCampi();
        });
    }

    controllaCampi = () => {
        let datiMadre = _.cloneDeep(this.state.datiMadre);

        // formatto l'oggetto per inviarlo come parametro di input al metodo salvaBozza nel componente padre "CompilaRichiesta"
        datiMadre.isMadreIndicataComeRiferimentoPerPaziente = datiMadre.isMadreIndicataComeRiferimentoPerPaziente?.value;
        datiMadre.madrelingua = datiMadre.madrelingua?.value;
        datiMadre.livello = this.state.datiMadre.livello?.value;
        if (datiMadre.descrizione.length > 0) {
            datiMadre.descrizione = datiMadre.descrizione.map(el => el?.value ?? null);
        }
        this.props.onChangeDatiMadre("madre", _.mapValues(datiMadre, v => Utils.isStringEmptyOrNullOrUndefined(v) ? null : v));

        // controllo la validità dei vari campi dell'oggetto datiMadre e nel caso in cui ci siano campi obbligatori non valorizzati li conto
        calcolaNumeroCampiObbligatoriDaValorizzareAndValida(this.props, datiMadre, "id",
            "isMadreIndicataComeRiferimentoPerPaziente",
            "sottoSezioneMadre", "isMadreValido", this.props.pageState);
    }

    renderLinguaParlata = (linguaParlata, index) => {
        return (
            <RoleBasedSelect
                fieldId={'LING_PARL.MADRE.ANAGRAFEPAZIENTE'}
                pageState={this.props.pageState}
                parentClass={"mb-0"}
                id={"selectLinguaParlata"}
                className={"selectLinguaParlata"}
                options={oggettiUtili.elencoLingue}
                value={linguaParlata}
                onChange={(elem) => {
                    let datiMadre = _.cloneDeep(this.state.datiMadre);
                    datiMadre.descrizione[index] = elem;
                    this.setState({datiMadre}, () => this.controllaCampi());
                }}
                isSearchable={false}
                fieldLabel={"Lingua parlata"}
                field={"descrizione"}
                disableDoubleInput={true}
                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
            />
        );
    }

    addNewLinguaParlata = () => {
        let datiMadre = _.cloneDeep(this.state.datiMadre);
        datiMadre.descrizione.push(null);
        this.setState({datiMadre}, () => this.controllaCampi())
    }

    removeLinguaParlata = (index) => {
        let datiMadre = _.cloneDeep(this.state.datiMadre);
        datiMadre.descrizione.splice(index, 1);
        this.setState({datiMadre}, () => this.controllaCampi());
    }

    renderInput = () => {
        return (
            <>
                <FieldsRow>
                <RoleBasedInput
                        fieldId={'COGN_MADRE.MADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci cognome Madre"
                        field="cognome"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="inputCognomeMadre"
                        value={this.state.datiMadre.cognome}
                        fieldLabel={"Cognome Madre"}
                        fieldRequired={true}
                        forceReadOnly={this.props.forceReadOnly || this.props.mascheraModificabilita?.anagrafePaziente?.madre?.cognome}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={true}
                    />
                    <RoleBasedInput
                        fieldId={'NOME_MADRE.MADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        field="nome"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="inputNomeMadre"
                        value={this.state.datiMadre.nome}
                        placeholder={"Inserisci nome madre"}
                        fieldLabel={"Nome Madre"}
                        fieldRequired={true}
                        forceReadOnly={this.props.forceReadOnly || this.props.mascheraModificabilita?.anagrafePaziente?.madre?.nome}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={true}
                    />
                    <RoleBasedInput
                        fieldLabel={"Codice fiscale Madre"}
                        fieldId={'COD_FISC_MADRE.MADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder="Inserisci codice fiscale Madre"
                        field="identificativoUtente"
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id="inputCodiceFiscaleMadre"
                        value={this.state.datiMadre.identificativoUtente}
                        maxlength={"16"}
                        validator={Utils.isValidCodiceFiscale}
                        invalidText={"Inserire un codice fiscale valido"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PERS_IND_RIF_PAZ.MADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        id={"selectPersonaRiferimento"}
                        className={"selectPersonaRiferimento"}
                        options={oggettiUtili.rispostaBreve}
                        value={this.state.datiMadre.isMadreIndicataComeRiferimentoPerPaziente}
                        onChange={(elem) => this.handleAggiornaForm("isMadreIndicataComeRiferimentoPerPaziente", elem)}
                        isSearchable={false}
                        fieldLabel={"Persona indicata come riferimento per il paziente?"}
                        fieldRequired={true}
                        field={"isMadreIndicataComeRiferimentoPerPaziente"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldId={'INDIR_MADRE.MADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci indirizzo Madre"}
                        field={"indirizzo"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"inputIndirizzoMadre"}
                        value={this.state.datiMadre.indirizzo}
                        disabled={this.state.datiMadre.isMadreIndicataComeRiferimentoPerPaziente?.value === false}
                        fieldLabel={"Indirizzo Madre"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldId={"NUMEROCIVICO_MADRE.MADRE.ANAGRAFEPAZIENTE"}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci numero civico"}
                        field={"numero"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"inputNumeroCivico"}
                        value={this.state.datiMadre.numero}
                        disabled={this.state.datiMadre.isMadreIndicataComeRiferimentoPerPaziente?.value === false}
                        fieldLabel={'N°'}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                <FieldsRow>
                    <RoleBasedPhoneInput
                        fieldLabel={"Telefono Madre"}
                        fieldId={'TEL_MADRE.MADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci numero di telefono"}
                        field={"telefono"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"inputNumeroTelefonoMadre"}
                        value={this.state.datiMadre.telefono}
                        disabled={this.state.datiMadre.isMadreIndicataComeRiferimentoPerPaziente?.value === false}
                        fieldRequired={this.state.datiMadre.isMadreIndicataComeRiferimentoPerPaziente?.value === true && Utils.isStateRichiesta(this.props.pageState)}
                        phonePrefix={null}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedInput
                        fieldLabel={"E-mail Madre"}
                        fieldId={'EMAIL_MADRE.MADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci e-mail Madre"}
                        field={"email"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"inputEmailMadre"}
                        value={this.state.datiMadre.email}
                        disabled={this.state.datiMadre.isMadreIndicataComeRiferimentoPerPaziente?.value === false}
                        validator={Utils.isValidEmail}
                        invalidText={"Inserire un'email valida"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <RoleBasedSelect
                        fieldId={'ITA_MADREL.MADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        id={"selectMadrelingua"}
                        className={"selectMadrelingua"}
                        options={oggettiUtili.rispostaBreve}
                        value={this.state.datiMadre.madrelingua}
                        onChange={(elem) => this.handleAggiornaForm("madrelingua", elem)}
                        isSearchable={false}
                        fieldLabel={"Italiano madrelingua?"}
                        field={"madrelingua"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>
                {!isMadrelingua(this.state.datiMadre.madrelingua) &&
                <FieldsRow containerClass={"align-self-center"}
                           childClass={"align-self-start"}>
                    <RoleBasedSelect
                        fieldId={'LING_ITA_PARL_COMPR.MADRE.ANAGRAFEPAZIENTE'}
                        pageState={this.props.pageState}
                        id={"selectLinguaItalianaParlataCompresa"}
                        className={"selectLinguaItalianaParlataCompresa"}
                        options={oggettiUtili.linguaItalianaParlataCompresa}
                        value={this.state.datiMadre.livello}
                        onChange={(elem) => this.handleAggiornaForm("livello", elem)}
                        isSearchable={false}
                        fieldLabel={"Lingua italiana parlata e compresa"}
                        field={"livello"}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                    <SectionList
                        title={'Lingua parlata'}
                        data={this.state.datiMadre.descrizione}
                        renderSection={this.renderLinguaParlata}
                        addNewSectionCallback={this.addNewLinguaParlata}
                        removeSectionCallback={this.removeLinguaParlata}
                        addButtonVisibilityHandler={!isMadrelingua(this.state.datiMadre.madrelingua)
                        && (this.state.datiMadre.descrizione?.length === 0 || this.state.datiMadre.descrizione?.every(el => !Utils.isObjectNull(el)))}
                        pageState={this.props.pageState}
                        keyFieldId={'LING_PARL.MADRE.ANAGRAFEPAZIENTE'}
                        indentOffset={0}
                        field={'descrizione'}
                        disableDoubleInput={true}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>}
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"madre"}
                title={"Madre"}
                required={true}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderInput()}</AccordionSezioneRichiesta>
        );
    }
}

Madre.propTypes = propTypes;
