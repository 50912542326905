import React from "react";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import AccordionHelper from "../../../service/AccordionHelper";
import PropTypes from "prop-types";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import * as _ from "lodash";
import ObiettiviClinicoAssistenzialiSottoAccordion
    from "./obiettiviClinicoAssistenzialiSottoAccordion/ObiettiviClinicoAssistenzialiSottoAccordion";
import VersionedAccordion from "../../VersionedAccordion";

const propTypes = {
    dati: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiObiettiviClinicoAssistenziali: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    addInformazioniAggiuntive: PropTypes.func,
    disableDoubleInput: PropTypes.bool
}

export default class ObiettiviClinicoAssistenziali extends AccordionFirstLevel {
    state = {
        accordion: {
            openSubAccordionObiettiviClinicoAssistenziali: true
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        let richiesta = _.cloneDeep(this.props.richiesta);
        richiesta.obiettiviClinicoAssistenziali = this.props.dati;
        this.props.salvaBozza(false, false, richiesta);
        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            })
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    renderBody = () => {
        return (
            <>
                <VersionedAccordion accordion={ObiettiviClinicoAssistenzialiSottoAccordion}
                    dati={this.props.dati}
                    onChangeDatiObiettiviClinicoAssistenziali={this.props.onChangeDatiObiettiviClinicoAssistenziali}
                    openAccordion={this.state.accordion.openSubAccordionObiettiviClinicoAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openSubAccordionObiettiviClinicoAssistenziali"}
                    pageState={this.props.pageState}
                    disableDoubleInput={this.props.disableDoubleInput}/>
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"obiettiviClinicoAssistenziali"}
                title={"Obiettivi clinico-assistenziali"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
            >{this.renderBody()}</AccordionAvvioRichiesta>
        );
    }
}

ObiettiviClinicoAssistenziali.propTypes = propTypes;
