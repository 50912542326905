import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {FieldsRow, RoleBasedInput, RoleBasedSelect} from "../../../roleBasedComponents/RoleBasedComponents";
import AuthUtils from "../../../../utils/AuthUtils";
import proposte from "../../../../utils/dataset/proposteAttivitaSuggerimentiData/proposteAttivitaSuggerimenti.json";
import Utils from "../../../../utils/Utils";

export function ProposteAttivitaSuggerimentiAssistenteSociale ({datiproposteAttivitaSuggerimentiAssistenteSociale, pageState, readOnly, validaSezione, onChangeProposteAttivitaSuggerimentiAssistenteSociale}) {

    const [dati, setDati] = useState();

    const handleAggiornaForm = (filedId, elem) => {
        const objetToserver = {
            ...dati,
            [filedId]: elem
        }
        setDati(objetToserver);
        onChangeProposteAttivitaSuggerimentiAssistenteSociale("proposteAttivitaSuggerimentiAssistenteSociale", objetToserver);
        if(validaSezione) validaSezione() 
    }

    useEffect(() => {
        const condizioneForSet = (!Utils.isObjectNull(datiproposteAttivitaSuggerimentiAssistenteSociale) && datiproposteAttivitaSuggerimentiAssistenteSociale?.propostaAssistenteSociale !== dati?.propostaAssistenteSociale);
        if (condizioneForSet) setDati(datiproposteAttivitaSuggerimentiAssistenteSociale);
    }, [datiproposteAttivitaSuggerimentiAssistenteSociale]);

    return (
        <FieldsRow>
            <RoleBasedSelect
                fieldId={'X_PROP_ATT_SUGG.PROPOSTEATTIVITAESUGGERIMENTI.RICHIESTACAMBIOSETTING.ASS_SOC'}
                pageState={pageState}
                id={"selectProposteAttivitaSuggerimenti"}
                value={dati?.propostaAssistenteSociale}
                field={"propostaAssistenteSociale"}
                options={proposte}
                onChange={(elem) => handleAggiornaForm("propostaAssistenteSociale", elem)}
                isSearchable={false}
                noOptionsMessage={() => "Errore: Proposta attività e suggerimenti non esistente"}
                fieldRequired={(Utils.isStateValutazione(pageState) && AuthUtils.hasUtenteRuoloAssistenteSociale())}
                fieldLabel={'Proposte di attività e suggerimenti - Assistente sociale'}
                handleOnlyValue={true}
                forceReadOnly={AuthUtils.hasUtenteRuoloAssistenteSociale() ? false : readOnly}
                showFieldIfRequiredAccordionPage={!!dati?.propostaAssistenteSociale}
            />

            {dati?.propostaAssistenteSociale === "05" &&
                <RoleBasedInput
                    fieldId={'SPEC_SE_ALTRO.PROPOSTEATTIVITAESUGGERIMENTI.RICHIESTACAMBIOSETTING'}
                    pageState={pageState}
                    id={"inputSpecificareSeAltro"}
                    value={dati?.specificareSeAltro ?? ""}
                    placeholder={"Specificare altra proposta di attività e suggerimenti"}
                    field={"specificareSeAltro"}
                    onChange={(field, elem) => handleAggiornaForm(field, elem.target.value)}
                    fieldLabel={'Specificare se altro'}
                    forceReadOnly={AuthUtils.hasUtenteRuoloAssistenteSociale() ? false : readOnly}
                    showFieldIfRequiredAccordionPage={!!dati?.specificareSeAltro}
                />}
        </FieldsRow>
    );
}

ProposteAttivitaSuggerimentiAssistenteSociale.propTypes = {
    datiproposteAttivitaSuggerimentiAssistenteSociale: PropTypes.node,
    pageState: PropTypes.string,
    readOnly: PropTypes.bool,
    validaSezione: PropTypes.func,
    onChangeProposteAttivitaSuggerimentiAssistenteSociale: PropTypes.func
};

ProposteAttivitaSuggerimentiAssistenteSociale.defaultProps = {
    datiproposteAttivitaSuggerimentiAssistenteSociale: {},
    pageState: "",
    readOnly: false,
};


