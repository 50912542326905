import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import * as BsIcon from "react-bootstrap-icons";
import Dropdown from "../../../generalComponents/dropdown/Dropdown";
import stylesNavbarComponent from "../NavBar.module.css";
import {DropdownItem} from 'reactstrap';
import {Link, useLocation} from "react-router-dom";
import routepath from "../../../../utils/route/route-path";
import PreviewNotifica from '../../../../components/notifiche/previewNotifica/PreviewNotifica';

/**
 * @function MenuNotification renderizza la barra di notifiche (Notifiche, FAQ, Azioni Profilo, LogOut)
 * @param notifiche oggetto contenente le notifiche del componente da visualizzare sull'Icona Notifiche
 */

function MenuNotification ({notifiche}) {

    const location = useLocation();
    const [linkActive, setLinkActive] = useState(false);

    useEffect(() => {
        if (location.pathname) {
            setLinkActive(location.pathname == routepath.centro_notifiche)
        }
    }, [location.pathname]);

    let headerDropdown = notifiche?.numeroNotificheNonLette + (notifiche?.numeroNotificheNonLette !== 1 ? " Notifiche non lette" : " Notifica non letta");
    let numeroNotificheNonLette = notifiche?.numeroNotificheNonLette > 99 ? "99+" : notifiche?.numeroNotificheNonLette;

    /**
     * @function renderDropdownToggle visualizza il numero di Notifiche non lette su l'Icona Megafono
     */
    const renderDropdownToggle = () => {
        return (
            <>
                <span className={[linkActive ? stylesNavbarComponent.activePage : "", stylesNavbarComponent.stylePagineLi, stylesNavbarComponent.liPagina, stylesNavbarComponent.labelCustom].join(' ')} style={{ paddingTop: "10px", paddingRight: "33px", paddingBottom: "10px"}} >
                    <BsIcon.Megaphone width={30} height={30} />
                    {notifiche?.numeroNotificheNonLette && notifiche?.numeroNotificheNonLette > 0 ? <span className={"nonLette ml-1"}>{numeroNotificheNonLette}</span> : null}
                </span>
            </>
        )
    }

    /**
     * @function renderDropdownMenu apre un menu con tutte le modifiche non lette sotto l'Icona Megafono
     */
    const renderDropdownMenu = () => {
        return (
            <>
                <DropdownItem header className={"text-center"} style={{color: "black", fontWeight: "800", fontSize: "14px"}}>{headerDropdown}</DropdownItem>
                <DropdownItem divider className={"dropdownMenuDivider"} />
                {notifiche?.notificaDto?.elementi?.map(el => (
                    <DropdownItem key={el.id}>
                        <Link
                            to={{pathname: routepath.centro_notifiche, idNotifica: el.id}}
                            className="text-decoration-none linkNotifica">
                            <PreviewNotifica notifica={el} key={el.id} onClickNotifica={() => null} dropdown />
                        </Link>
                    </DropdownItem>
                ))}
                <Link to={routepath.centro_notifiche} className={"apriCentroNotifiche"}>
                    <DropdownItem className={"text-center apriCentroNotifiche"}>
                        Apri il centro notifiche
                    </DropdownItem>
                </Link>
            </>
        )
    }

    return (
        <>
            <div id={"dropdownNotifiche"} >
                <Dropdown
                    idDropdown={"idDropdownNotifiche"}
                    idDropdownToggle={"dropdownToggleNotifiche"}
                    idDropdownMenu={"idDropdownMenuNotifiche"}
                    tagDropdownToggle={"span"}
                    renderDropdownToggle={renderDropdownToggle}
                    renderDropdownMenu={renderDropdownMenu}
                    direction={"down"}
                    classNameDropdownMenu={"dropdown-notifiche"}
                />
            </div>
        </>
    )
}

MenuNotification.propTypes = {
    notifiche: PropTypes.node
};

MenuNotification.defaultProps = {
    notifiche: {}
};

export default MenuNotification;
