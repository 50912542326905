import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {
    FieldsRow,
    RoleBasedICD9Input,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import DispositiviPresenti, {
    defaultDispositiviPresenti
} from "../../accordionComponents/dispositiviPresenti/DispositiviPresenti";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {v4 as uuid} from "uuid";
import Utils from "../../../utils/Utils";
import {
    defaultAltro,
    defaultFarmaco,
    defaultObject,
    defaultProposteAttivitaSuggerimenti, defaultValore
} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiSection";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    dati: PropTypes.any,
    onChangeEsameObiettivo: PropTypes.func,
    pageState: PropTypes.any,
    showAccordion: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber:  PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

const defaultAlterazione = {
    id: null,
    tipoAlterazione: null,
    sede: null
}

export default class Osteoarticolare extends AccordionSecondLevel {
    state = {
        dati: _.cloneDeep(this.props.dati)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati))
            this.inizializzaForm();
    }


    inizializzaForm = () => {
        const dati = _.cloneDeep(this.props.dati);
        this.setState({dati: dati}, () => this.validazioneDati());
    }

    handleAggiornaForm = (field, elem, index = null, subField = null) => {
        this.setState(({dati}) => {
            if (index === null && subField === null) {
                dati[field] = elem;
            } else {
                dati[field][index][subField] = elem;
            }

            if (!dati.presenzaAlterazione) {
                dati.alterazioni = [];
                dati.condizioneRilevata = null;
            }

            return {dati};
        }, this.validazioneDati);
    }

    validazioneDati = () => {
        let dati = _.cloneDeep(this.state.dati);
        this.props.onChangeEsameObiettivo("osteoarticolare", dati);
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(dati?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneOsteoarticolare", campiObbligatoriRimanenti);
    }

    handleDispositiviArrayChange = (array, field) => {
        let data = _.cloneDeep(this.state.dati);
        data[field] = array;
        this.setState({dati: data}, () => {
            this.props.onChangeEsameObiettivo("osteoarticolare", data);
            this.forceUpdate();
        });
    }

    /* Alterazione */

    addAlterazione = () => {
        let dati = _.cloneDeep(this.state.dati);
        let alterazione = _.cloneDeep(defaultAlterazione);
        alterazione.id = uuid();
        dati.alterazioni.push(alterazione);
        this.setState({dati}, () => this.validazioneDati());
    }

    removeAlterazione = (index) => {
        let dati = _.cloneDeep(this.state.dati);
        dati.alterazioni.splice(index, 1);
        this.setState({dati}, () => this.validazioneDati());
    }

    renderAlterazione = (alterazione, index) => {
        return (
            <FieldsRow>
                <RoleBasedICD9Input
                    fieldId={'TIPO_ALT.OSTEOARTICOLARE.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    onChange={(field, elem) => this.handleAggiornaForm("alterazioni", elem.target.value, index, "tipoAlterazione")}
                    value={alterazione.tipoAlterazione}
                    placeholder={"Tipo di alterazione"}
                    disableDoubleInput={this.props.disableDoubleInput}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    fieldLabel={'Alterazione'}
                    field={'tipoAlterazione'}
                />
                <RoleBasedInput
                    fieldId={'SEDE_ALT.OSTEOARTICOLARE.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    value={alterazione.sede}
                    placeholder={"Sede"}
                    onChange={(field, elem) => this.handleAggiornaForm("alterazioni", elem.target.value, index, "sede")}
                    id={"basedInputSede" + index}
                    fieldLabel={"Sede"}
                    disableDoubleInput={this.props.disableDoubleInput}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    field={'sede'}
                />
            </FieldsRow>
        )
    }

    renderBodySezione() {
        const dati = this.state.dati;
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'PRES_ALT.OSTEOARTICOLARE.ESAMEOBIETTIVO'}
                        pageState={this.props.pageState}
                        id={"presenzaAlterazione"}
                        className={"presenzaAlterazione"}
                        options={OggettiUtili.rispostaBreve}
                        value={dati.presenzaAlterazione}
                        onChange={(elem) => this.handleAggiornaForm("presenzaAlterazione", elem)}
                        isSearchable={false}
                        fieldLabel={"Presenza di alterazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                        hideUnsetValues={this.props.hideUnsetValues}
                        handleOnlyValue={true}
                        field={"presenzaAlterazione"}
                    />
                </FieldsRow>

                {dati.presenzaAlterazione &&
                <SectionList
                    title={'Alterazione'}
                    data={dati.alterazioni}
                    renderSection={this.renderAlterazione}
                    addNewSectionCallback={this.addAlterazione}
                    removeSectionCallback={this.removeAlterazione}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    pageState={this.props.pageState}
                    keyFieldId={'TIPO_ALT.OSTEOARTICOLARE.ESAMEOBIETTIVO'}
                    field={'alterazioni'}
                    parentJsonPath={"esameObiettivo.osteoarticolare.alterazioni"}
                    addButtonVisibilityHandler={dati.alterazioni?.every(a => !!a.tipoAlterazione)}
                />}

                <DispositiviPresenti
                    handleArrayChange={this.handleDispositiviArrayChange}
                    dispositiviPresenti={dati.dispositiviPresenti}
                    pageState={this.props.pageState}
                    fieldId={"DISP.OSTEOARTICOLARE.ESAMEOBIETTIVO"}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    parentJsonPath={"esameObiettivo.osteoarticolare.dispositiviPresenti"}
                />

                <CondizioneRilevataSection
                    ambito={'OSTEOARTICOLARE'}
                    parentFieldId={'OSTEOARTICOLARE.ESAMEOBIETTIVO'}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={dati.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={dati.daAttenzionare}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    disableDoubleInput={this.props.disableDoubleInput}
                    hideUnsetValues={this.props.hideUnsetValues}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={dati.propostaAttivitaSuggerimenti}
                    showSuggerimenti={true}
                    openCondition={dati.presenzaAlterazione}
                />
            </>
        );
    }

    render() {
        if (this.props.showAccordion) {
            return (<AccordionSezioneRichiesta
                idAccordion={"osteoarticolare"}
                title={"Osteoarticolare"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                        ["esameObiettivo.osteoarticolare.alterazioni",
                            "esameObiettivo.osteoarticolare.dispositiviPresenti",
                            "esameObiettivo.osteoarticolare.propostaAttivitaSuggerimenti"],
                        [defaultAlterazione, defaultDispositiviPresenti, defaultProposteAttivitaSuggerimenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>);
        } else {
            return this.renderBodySezione();
        }
    }
}
Osteoarticolare.propTypes = propTypes;

Osteoarticolare.defaultProps = {
    showAccordion: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

