import React, { Component } from "react";
import _ from "lodash";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import styles from './Faq.module.css';
import PropTypes from "prop-types";

const propTypes = {
    openAccordion: PropTypes.bool,
    onClickAccordion: PropTypes.func,
    field: PropTypes.string,
    idAccordion:PropTypes.string,
    title:PropTypes.string,
    content:PropTypes.string,
    subContent:PropTypes.string,
    img:PropTypes.string,
    alt:PropTypes.string
}

const defaultProps = {
    openAccordion: true,
    onClickAccordion: null,
    field: "",
    idAccordion:"",
    title:"",
    content:"",
    subContent:"",
    img:"",
    alt:""
}

class Question extends Component {
    render() {
        const { openAccordion, onClickAccordion, field, idAccordion, title, content, subContent, img, alt } = this.props;
        return (
            <AccordionAvvioRichiesta
                idAccordion={idAccordion}
                title={title}
                openAccordion={openAccordion}
                onClickAccordion={onClickAccordion}
                field={field}>
                <label className={styles.contentFaq.concat(" mx-4")} dangerouslySetInnerHTML={{ __html:content}}></label>
                {img &&(<div className="mb-3 mx-4"><img src={img} alt={alt}/></div>)}
                {subContent && ( <label className={styles.contentFaq.concat(" mx-4")} dangerouslySetInnerHTML={{ __html:subContent}}></label>)}
            </AccordionAvvioRichiesta>
        )
    }
}


Question.propTypes = propTypes;
Question.defaultProps = defaultProps;


export default Question;