import React, {Component} from "react";
import PropTypes from "prop-types";
import ModalHelper from "../../modale/ModalHelper";
import Utils, {closeModaleStyle} from "../../../utils/Utils";
import enumRichiesta from "../../../enum/enumRichiesta.json";
import AuthUtils from "../../../utils/AuthUtils";
import moment from "moment";
import {Button, Icon} from "web-client-archetype";
import * as _ from "lodash";
import styles from "./PopupAutoAssegnazioneValutazione.module.css";
import RichiestaADIService from "../../../service/RichiestaADIService";
import {Redirect} from "react-router-dom";
import routePath from "../../../utils/route/route-path.json";

const propTypes = {
    uuidRichiesta: PropTypes.string,
    livelloPriorita: PropTypes.string,
    timestampCompletamentoPreDialogo: PropTypes.string,
    statoRichiesta: PropTypes.string,
    valutazioni: PropTypes.arrayOf(PropTypes.object),
    mmg: PropTypes.object,
    chiudiPopupAutoAssegnazioneValutazione: PropTypes.func,
    timestampCompletamento: PropTypes.string
}

export default class PopupAutoAssegnazioneValutazione extends Component {

    state = {
        isValutazioneAutoAssegnata: false
    }

    componentWillUnmount() {
        closeModaleStyle();
    }

    rifiutaAutoAssegnazioneValutazione = () => {
        closeModaleStyle();
        this.props.chiudiPopupAutoAssegnazioneValutazione();
    }

    autoAssegnazioneValutazione = () => {
        RichiestaADIService.autoAssegnazioneValutazione(this.props.uuidRichiesta).subscribe((response) => {
            if (response?.msg === "ERRORE") {
                if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ?? response?.status,
                        type: "critical",
                        text: response?.data?.errore ?? response?.data?.message
                    });
                }
            } else this.setState({isValutazioneAutoAssegnata: true});
        });
    }

    renderModale = () => {
        const dataScadenzaPredialogo = Utils.calcolaDataScadenza(enumRichiesta.tipologia.SEGNALAZIONE, enumRichiesta.fase.PREDIALOGO, this.props.livelloPriorita, this.props.timestampCompletamentoPreDialogo);
        const dataScadenzaClassica = Utils.calcolaDataScadenza(enumRichiesta.tipologia.SEGNALAZIONE, enumRichiesta.fase.CLASSICA, this.props.livelloPriorita, this.props.timestampCompletamento);
        const timestampCorrente = moment(moment().format("YYYY-MM-DDTHH:mm:ss"));
        let title = <span onClick={() => this.rifiutaAutoAssegnazioneValutazione()}>
                    <Icon id={'it-close'}
                          className={['bg-white', 'icon', 'icon-green']}
                          style={{width: '44px', height: '44px', cursor: 'pointer'}}/>
                </span>
        let body;
        let footer;

        if (AuthUtils.hasRuoloMedicoMedicinaGenerale()) {
            if (dataScadenzaPredialogo.isAfter(timestampCorrente) || !_.isEqual(this.props.statoRichiesta, enumRichiesta.stato.VALUTATA_PRE_DIALOGO)) {
                body = <div className={"row text-center mb-3"}>
                    <div className={"col-12"}>Vuoi contribuire a valutare la richiesta?</div>
                </div>
                footer = <>
                    <Button
                        id={"rifiutoAutoAssegnazioneValutazioneBtn"}
                        text={"No"}
                        className={[styles.modalBtnStyle, 'btn btn-adi btnWhiteOutlineBorderGreen'].join(' ')}
                        onClick={() => this.rifiutaAutoAssegnazioneValutazione()}
                    />
                    <Button
                        id={"autoAssegnazioneValutazioneBtn"}
                        text={"Si"}
                        className={[styles.modalBtnStyle, 'btn btn-adi btnGreenOutline'].join(' ')}
                        onClick={() => this.autoAssegnazioneValutazione()}
                    />
                </>
            } else {
                body = <div className={"row text-center mb-3"}>
                    <div className={"col-12"}>Non è più possibile contribuire alla valutazione</div>
                </div>
                footer = <></>;
            }

            
        } else if ((AuthUtils.hasRuoloMedicoOspedaliero() && !dataScadenzaPredialogo.isAfter(timestampCorrente)) || (AuthUtils.hasUtenteRuoloMcp && !dataScadenzaClassica.isAfter(timestampCorrente))) {
            const valutazioniStatoBozza = this.props.valutazioni.filter(vp => _.isEqual(vp.stato, enumRichiesta.stato.BOZZA));
            if (valutazioniStatoBozza.length === 1 && _.isEqual(valutazioniStatoBozza?.[0]?.valutatore?.codiceFiscale, this.props.mmg.codiceFiscale)) {
                body = <div className={"row text-center mb-3"}>
                    <div className={"col-12"}>Il Medico di Medicina Generale sta compilando la propria valutazione. Vuoi
                        annullare la sua valutazione ed iniziare a valutare la richiesta?
                    </div>
                </div>
                footer = <>
                    <Button
                        id={"rifiutoAutoAssegnazioneValutazioneBtn"}
                        text={"No"}
                        className={[styles.modalBtnStyle, 'btn btn-adi btnWhiteOutlineBorderGreen'].join(' ')}
                        onClick={() => this.rifiutaAutoAssegnazioneValutazione()}
                    />
                    <Button
                        id={"autoAssegnazioneValutazioneBtn"}
                        text={"Si"}
                        className={[styles.modalBtnStyle, 'btn btn-adi btnGreenOutline'].join(' ')}
                        onClick={() => this.autoAssegnazioneValutazione()}
                    />
                </>
            } else {
                title = null;
                body = null;
                footer = <></>;
            }
        } else {
            title = null;
            body = null;
            footer = <></>;
        }

        return body != null
            ? <>
                <ModalHelper
                    id={"modaleAutoAssegnazioneValutazione"}
                    title={title}
                    titleClassName={['font-weight-bold', 'modal-header d-flex ml-auto'].join(' ')}
                    containerClassName={'modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered'}
                    contentClassName={'modal-content'}
                    body={body}
                    footer={footer}
                    footerStyle={{justifyContent: 'space-between'}}
                    footerClassName={'modal-footer'}
                    bodyClassName={'modal-body d-inline ml-xl-5 ml-lg-5 pl-xl-2 pl-lg-2'}
                />
            </>
            : <></>
    }

    render() {
        if (this.state.isValutazioneAutoAssegnata) {
            return <Redirect to={{
                pathname: routePath.valutazione_multidimensionale + routePath.completa_valutazione_multidimensionale,
                uuidRichiesta: this.props.uuidRichiesta
            }}/>;
        }

        return <>{this.renderModale()}</>;
    }
}

PopupAutoAssegnazioneValutazione.propTypes = propTypes;
