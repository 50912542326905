import React, {Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import Utils from "../../../utils/Utils";
import {
    FieldsGroup,
    FieldsRow,
    RoleBasedCalendar,
    RoleBasedClock,
    RoleBasedInput,
    RoleBasedSelect,
    SectionList
} from "../../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import {v4 as uuid} from "uuid";

const propTypes = {
    datiAltriTrattamenti: PropTypes.any,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultAltriTrattamenti = {
    id: null,
    trattamento: null,
    frequenzaTrattamento: null,
    frequenzaAltriTrattamentiSettimana: null,
    frequenzaAltriTrattamentiMese: null,
    ultimaApplicazioneData: null,
    ultimaApplicazioneOra: null
}

export default class AltriTrattamenti extends AccordionSecondLevel {

    state = {
        datiAltriTrattamenti: _.cloneDeep(this.props.datiAltriTrattamenti),
        showAggiungiDato: true
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    validazioneDati = () => {
        let datiAltriTrattamenti = _.cloneDeep(this.state.datiAltriTrattamenti);
        let isSezioneValida = true;

        this.props.onChangeDatiFornitureSanitarie("datiAltriTrattamenti", datiAltriTrattamenti);

        datiAltriTrattamenti.forEach(dato => {
            if (dato.trattamento === null)
                isSezioneValida = false;
        });

        this.setState({showAggiungiDato: isSezioneValida});
    }

    inizializzaForm = () => {
        let datiAltriTrattamenti = _.cloneDeep(this.state.datiAltriTrattamenti);

        this.setState({datiAltriTrattamenti: datiAltriTrattamenti}, () => {
            this.validazioneDati();
            this.props.onChangeRequiredFieldsNumber("sottosezioneAltriTrattamenti", this.numeroCampiObbligatori());
        });
    }

    numeroCampiObbligatori = () => {
        /*
        let datiAltriTrattamenti = _.cloneDeep(this.state.datiAltriTrattamenti);
        let trattamentoDiversoDaNessunTrattamento = ["frequenzaTrattamento", "ultimaApplicazioneData", "ultimaApplicazioneOra"];
        let numeroCampiObbligatori = 0;

        if (datiAltriTrattamenti.length > 0) {
            datiAltriTrattamenti.map(dato => {
                dato = Utils.objectToValue(dato, this.state.campiSelectelect);
                if (dato?.trattamento && dato?.trattamento !== "01") {
                    trattamentoDiversoDaNessunTrattamento.forEach(field => {
                        if (Utils.isStringEmptyOrNullOrUndefined(dato[field]))
                            numeroCampiObbligatori++;
                    });
                }
                return dato;
            });
        }
        return numeroCampiObbligatori;
         */
        return 0;
    }

    handleAggiornaForm = (field, elem, index) => {
        let dati = _.cloneDeep(this.state.datiAltriTrattamenti);
        let dato = dati[index];

        if (field === "trattamento" && elem === "01") {
            dato.frequenzaTrattamento = null;
            dato.ultimaApplicazioneData = null;
            dato.ultimaApplicazioneOra = null;
            dato.frequenzaAltriTrattamentiMese = null;
            dato.frequenzaAltriTrattamentiSettimana = null;
        } else if (field === "frequenzaAltriTrattamentiMese") {
            dato.frequenzaAltriTrattamentiSettimana = null;
        } else if (field === "frequenzaAltriTrattamentiSettimana") {
            dato.frequenzaAltriTrattamentiMese = null;
        }

        dato[field] = elem;
        dati[index] = dato;
        this.setState({datiAltriTrattamenti: dati}, () => {
            this.validazioneDati();
            this.props.onChangeRequiredFieldsNumber("sottosezioneAltriTrattamenti", this.numeroCampiObbligatori());
        });
    }

    updateClock(field, index, hh, mm) {
        if (hh && mm) {
            this.handleAggiornaForm(field, (hh + ':' + mm), index);
        } else if(!hh && mm) {
            hh = "00"
            this.handleAggiornaForm(field, (hh + ':' + mm), index);
        } else if(hh && !mm){
            mm = "00"
            this.handleAggiornaForm(field, (hh + ':' + mm), index);
        }
        else {
            this.handleAggiornaForm(field, null, index);
        }
    }

    addNewAltriTrattamenti = () => {
        let dati = _.cloneDeep(this.state.datiAltriTrattamenti);
        let dato = _.cloneDeep(defaultAltriTrattamenti);
        dato.id = uuid();
        dati.push(dato);
        this.setState({datiAltriTrattamenti: dati}, () => {
            this.validazioneDati();
            this.props.onChangeRequiredFieldsNumber("sottosezioneAltriTrattamenti", this.numeroCampiObbligatori());
        });
    }

    removeAltriTrattamenti = (index) => {
        let dati = _.cloneDeep(this.state.datiAltriTrattamenti);
        dati.splice(index, 1);
        this.setState({datiAltriTrattamenti: dati}, () => {
            if (this.state.datiAltriTrattamenti.length === 0) {
                this.setState({areDatiAltriTrattamenti: false}, () => {
                    this.validazioneDati();
                    this.props.onChangeRequiredFieldsNumber("sottosezioneAltriTrattamenti", this.numeroCampiObbligatori());
                });
            } else {
                this.validazioneDati();
                this.props.onChangeRequiredFieldsNumber("sottosezioneAltriTrattamenti", this.numeroCampiObbligatori());
            }
        });
    }

    renderNewAltriTrattamenti = (dato, index) => {
        return (
            <Fragment key={index}>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'TRATTAMENTO.ALTRITRATTAMENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                        pageState={this.props.pageState}
                        id={"selectTrattamento"}
                        options={OggettiUtili.altriTrattamenti}
                        value={dato.trattamento}
                        onChange={(elem) => this.handleAggiornaForm("trattamento", elem, index)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Principio attivo non esistente"}
                        fieldLabel={'Trattamento'}
                        handleOnlyValue={true}
                        field={"trattamento"}
                        parentJsonPath={"informazionidibase"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    />
                </FieldsRow>

                {dato?.trattamento && dato?.trattamento !== "01" &&
                <>
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'FREQ_TRATTAM.ALTRITRATTAMENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                            pageState={this.props.pageState}
                            options={OggettiUtili.frequenzaTrattamentiUlteriori}
                            value={dato.frequenzaTrattamento}
                            field={"frequenzaTrattamento"}
                            onChange={(elem) => this.handleAggiornaForm("frequenzaTrattamento", elem, index)}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Frequenza altri trattamenti non esistente"}
                            fieldLabel={'Frequenza trattamento'}
                            handleOnlyValue={true}
                            parentJsonPath={"informazionidibase"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        <FieldsGroup showFieldIfRequiredAccordionPage={false}
                                     fieldInAccordionReadOnly={!!this.props.accordionReadOnly}>
                            {dato.frequenzaTrattamento === "01" &&
                            <RoleBasedInput
                                fieldId={'FREQ_TRATTAM.ALTRITRATTAMENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'} //TODO: inserire field id corretto
                                pageState={this.props.pageState}
                                value={dato.frequenzaAltriTrattamentiSettimana}
                                placeholder="Inserisci frequenza"
                                field={"frequenzaAltriTrattamentiSettimana"}
                                onChange={(field, elem) => this.handleAggiornaForm("frequenzaAltriTrattamentiSettimana", elem.target.value, index)}
                                id={"inputFrequenzaSettimanale" + index}
                                fieldLabel={'Inserisci frequenza'}
                                parentJsonPath={"informazionidibase"}
                                type={"number"}
                                min={0}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />}

                            {dato.frequenzaTrattamento === "02" &&
                            <RoleBasedInput
                                fieldId={'FREQ_TRATTAM.ALTRITRATTAMENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'} //TODO: inserire field id corretto
                                pageState={this.props.pageState}
                                value={dato.frequenzaAltriTrattamentiMese}
                                placeholder="Inserisci frequenza"
                                field={"frequenzaAltriTrattamentiMese"}
                                onChange={(field, elem) => this.handleAggiornaForm("frequenzaAltriTrattamentiMese", elem.target.value, index)}
                                id={"frequenzaAltriTrattamentiMese" + index}
                                fieldLabel={'Inserisci frequenza'}
                                parentJsonPath={"informazionidibase"}
                                type={"number"}
                                min={0}
                                fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            />}

                        </FieldsGroup>
                    </FieldsRow>

                    <FieldsRow>
                        <RoleBasedCalendar
                            fieldId={'ULTIMA_APPLIC_DATA.ALTRITRATTAMENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                            pageState={this.props.pageState}
                            id={"altriTrattamentiUltimaApplicazioneData"}
                            value={dato.ultimaApplicazioneData}
                            field="ultimaApplicazioneData"
                            onChange={(elem) => this.handleAggiornaForm("ultimaApplicazioneData", elem.target.value, index)}
                            errorMessage={"Data non valida"}
                            fieldLabel={'Ultima applicazione'}
                            parentJsonPath={"informazionidibase"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                        <RoleBasedClock
                            fieldId={'ULTIMA_APPLIC_ORA.ALTRITRATTAMENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                            pageState={this.props.pageState}
                            id={"ultimaApplicazioneOra"}
                            value={dato.ultimaApplicazioneOra}
                            onChange={(hh, mm) => this.updateClock("ultimaApplicazioneOra", index, hh, mm)}
                            field={'ultimaApplicazioneOra'}
                            parentJsonPath={"informazionidibase"}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        />
                    </FieldsRow>
                </>}
            </Fragment>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiAltriTrattamenti"}
                title={"Altri trattamenti"}
                required={this.numeroCampiObbligatori() > 0}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                fieldsOrderMap={Utils.generateMapPathsObjects(
                    ["informazioniDiBase.datiAltriTrattamenti"],
                    [defaultAltriTrattamenti]
                )}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >
                <SectionList addNewSectionCallback={this.addNewAltriTrattamenti}
                             data={_.cloneDeep(this.state.datiAltriTrattamenti)}
                             removeSectionCallback={this.removeAltriTrattamenti}
                             title={'Altri trattamenti'}
                             renderSection={this.renderNewAltriTrattamenti}
                             addButtonVisibilityHandler={this.state.showAggiungiDato}
                             pageState={this.props.pageState}
                             keyFieldId={'TRATTAMENTO.ALTRITRATTAMENTI.INFORMAZIONIDIBASETRATTAMENTIINATTOEFORNITURESANITARIEATTIVE'}
                             field={"datiAltriTrattamenti"}
                             parentJsonPath={"informazioniDiBase.datiAltriTrattamenti"}
                             fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                />
            </AccordionSezioneRichiesta>
        );
    }
}

AltriTrattamenti.propTypes = propTypes;
