import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Utils from "../../../../utils/Utils";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {
    aicList,
    FieldsRow,
    RoleBasedAICInput,
    RoleBasedCalendar,
    SectionList
} from "../../../roleBasedComponents/RoleBasedComponents";
import {v4 as uuid} from "uuid";

const propTypes = {
    vaccinazioniEffettuate: PropTypes.array,
    onChangeDatiInformazioniGenerali: PropTypes.func,
    index: PropTypes.func,
    pageState: PropTypes.string,
    forceReadOnly: PropTypes.bool,
    mascheraModificabilita: PropTypes.any,
    hideUnsetValues: PropTypes.bool,
    fieldInAccordionReadOnly: PropTypes.bool
}

export const defaultVaccinazioniEffettuate = {
    id: null,
    nomeVaccino: null,
    data: null
}

export default class VaccinazioniEffettuate extends AccordionSezioneRichiesta {

    state = {
        vaccinazioniEffettuate: this.props.vaccinazioniEffettuate ? _.cloneDeep(this.props.vaccinazioniEffettuate) : []
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.vaccinazioniEffettuate, this.props.vaccinazioniEffettuate))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let listaVaccinazioniEffettuate = _.cloneDeep(this.props.vaccinazioniEffettuate);
        if (!Utils.isObjectNull(listaVaccinazioniEffettuate) && listaVaccinazioniEffettuate.length > 0) {
            listaVaccinazioniEffettuate = listaVaccinazioniEffettuate.map(vaccinazione => {
                let vaccinazioneMap = vaccinazione;
                if (!Utils.isObjectNull(vaccinazione))
                    vaccinazioneMap.data = Utils.transformTimeStampForFrontend(vaccinazione.data)
                return vaccinazioneMap;
            });
        }
        this.setState({vaccinazioniEffettuate: listaVaccinazioniEffettuate});
    }

    handleAggiornaForm = (field, elem, index) => {
        let vaccinazioniEffettuate = _.cloneDeep(this.state.vaccinazioniEffettuate);
        if (elem.target) {
            vaccinazioniEffettuate[index][field] = elem.target.value;
        } else {
            vaccinazioniEffettuate[index][field] = elem;
        }
        this.setState({vaccinazioniEffettuate: vaccinazioniEffettuate}, () => {
            if (this.isRigaVaccinoValida(vaccinazioniEffettuate[index])) {
                let vaccinazioniEffettuateProps = _.cloneDeep(vaccinazioniEffettuate);
                this.props.onChangeDatiInformazioniGenerali("vaccinazioniEffettuate", vaccinazioniEffettuateProps)
            }
        });
    }
    isRigaVaccinoValida = (vaccino) => {
        return (!Utils.isStringEmptyOrNullOrUndefined(vaccino.nomeVaccino) && !Utils.isStringEmptyOrNullOrUndefined(vaccino.data)
            && !Utils.isDateInValid(vaccino.data));
    }

    addNewVaccinazioneEffettuata = () => {
        let vaccinazioniEffettuate = this.state.vaccinazioniEffettuate ? _.cloneDeep(this.state.vaccinazioniEffettuate) : [];
        let vaccinazioneEffettuata = _.cloneDeep(defaultVaccinazioniEffettuate);
        vaccinazioneEffettuata.id = uuid();
        vaccinazioniEffettuate.push(vaccinazioneEffettuata);
        this.setState({vaccinazioniEffettuate: vaccinazioniEffettuate}, () => {
            this.props.onChangeDatiInformazioniGenerali("vaccinazioniEffettuate", vaccinazioniEffettuate)
        });
    }

    removeVaccinazioneEffettuata = (index) => {
        let vaccinazioniEffettuate = _.cloneDeep(this.state.vaccinazioniEffettuate);
        vaccinazioniEffettuate.splice(index, 1);
        this.setState({vaccinazioniEffettuate: vaccinazioniEffettuate}, () => {
                this.props.onChangeDatiInformazioniGenerali("vaccinazioniEffettuate", vaccinazioniEffettuate)
            }
        )
    }

    renderNewVaccinazioneEffettuata = (vaccinazioneEffettuata, index) => {
        const optionsAIC = _.cloneDeep(aicList)
            .filter(f => !this.state.vaccinazioniEffettuate.find(d => vaccinazioneEffettuata.nomeVaccino !== d.nomeVaccino && d.nomeVaccino === f.value));

        return <FieldsRow>
            <RoleBasedAICInput
                id="vaccinazioneEffettuata"
                fieldId={'VACC_EFFET.INFORMAZIONIGENERALI.ANAMNESIFISIOLOGICA'}
                field="nomeVaccino"
                fieldLabel={"Vaccinazione effettuata"}
                pageState={this.props.pageState}
                onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value, index)}
                value={vaccinazioneEffettuata.nomeVaccino}
                suggestions={optionsAIC}
                forceReadOnly={this.props.forceReadOnly || 
                    Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.anamnesiFisiologica?.datiInformazioniGenerali?.vaccinazioniEffettuate, "nomeVaccino", vaccinazioneEffettuata?.id)}
                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                disableDoubleInput={this.props.disableDoubleInput}
            />
            <RoleBasedCalendar
                fieldId={'DATA_ESEC_VACC.INFORMAZIONIGENERALI.ANAMNESIFISIOLOGICA'}
                pageState={this.props.pageState}
                id={"vaccinazioniEffettuateData" + index}
                value={vaccinazioneEffettuata.data}
                field={"data"}
                onChange={(event) => this.handleAggiornaForm("data", event, index)}
                errorMessage={"Data non valida"}
                error={(Utils.timeDifference(Date.now(), new Date(Utils.convertDDMMYYYYDateToIsoDate(vaccinazioneEffettuata.data)), true) < 0)}
                fieldLabel={"Data esecuzione vaccino"}
                forceReadOnly={this.props.forceReadOnly || 
                    Utils.getValoreCampoElementoLista(this.props.mascheraModificabilita?.anamnesiFisiologica?.datiInformazioniGenerali?.vaccinazioniEffettuate, "data", vaccinazioneEffettuata?.id)}
                fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                hideUnsetValues={this.props.hideUnsetValues}
                disableDoubleInput={this.props.disableDoubleInput}
            />
        </FieldsRow>;
    }

    render() {
        const vaccinazioniEffettuate = _.cloneDeep(this.state.vaccinazioniEffettuate);
        return <SectionList
            addNewSectionCallback={this.addNewVaccinazioneEffettuata}
            removeSectionCallback={this.removeVaccinazioneEffettuata}
            renderSection={this.renderNewVaccinazioneEffettuata}
            data={vaccinazioniEffettuate}
            title={'Vaccinazione effettuata'}
            forceReadOnly={this.props.forceReadOnly || this.props.mascheraModificabilita?.anamnesiFisiologica?.datiInformazioniGenerali?.vaccinazioniEffettuate}
            fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
            hideUnsetValues={this.props.hideUnsetValues}
            disableDoubleInput={this.props.disableDoubleInput}
            keyFieldId={'VACC_EFFET.INFORMAZIONIGENERALI.ANAMNESIFISIOLOGICA'}
            pageState={this.props.pageState}
            field={'vaccinazioniEffettuate'}
            parentJsonPath={"anamnesiFisiologica.datiInformazioniGenerali.vaccinazioniEffettuate"}
        />;
    }
}

VaccinazioniEffettuate.propTypes = propTypes;

VaccinazioniEffettuate.defaultProps = {
    forceReadOnly: false,
    hideUnsetValues: false,
    fieldInAccordionReadOnly: false
}
