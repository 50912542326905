import React, {Fragment} from "react";
import AccordionHelper from "../../../service/AccordionHelper";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import OggettiUtili from "../../../utils/dataset/OggettiUtili";
import {
    FieldsGroup,
    FieldsRow,
    RoleBasedCalendar,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import profiliData from "../../../utils/dataset/profiliData/profili.json";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {
    calcolaCampiObbligatoriRimanenti,
    CONTROLLO_CAMPI_FORMALIZZAZIONE
} from "../CompilaFormalizzazione";
import VersionedAccordion from "../../VersionedAccordion";
import ProfiloAutonomia from "./ProfiloAutonomia";
import ValutazioneInFormalizzazione from "./ValutazioneInFormalizzazione";

const propTypes = {
    richiesta: PropTypes.any,
    caratteristicheSettingRichiesto: PropTypes.any,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeFormalizzazione: PropTypes.func,
    pageState: PropTypes.string
}

const defaultProps = {};

export default class CaratteristicheSettingRichiesto extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: 0,
        accordion: {
            openSubAccordionProfiloAutonomia: false,
            openSubAccordionValutazione: false
        },
        caratteristicheSettingRichiesto: null
    }

    componentDidMount() {
        this.setCaratteristicheSettingRichiesto(_.cloneDeep(this.props.caratteristicheSettingRichiesto));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.caratteristicheSettingRichiesto, this.props.caratteristicheSettingRichiesto)) {
            this.setCaratteristicheSettingRichiesto(_.cloneDeep(this.props.caratteristicheSettingRichiesto));
        }
    }

    modificaCodiceProfilo(caratteristicheSettingRichiesto, richiesta) {
        if (richiesta && richiesta.anamnesiFisiologica) {
            let punteggiRichiesta = {
                punteggioPMOB: richiesta.anamnesiFisiologica.datiGradoDiMobilita?.PMOB?.punteggio,
                punteggioPADL: richiesta.anamnesiFisiologica.statoFunzionale?.PADL?.punteggio,
                punteggioPCOG: richiesta.anamnesiFisiologica.datiStatoCognitivo?.PCOG?.punteggio,
                punteggioPSAN: richiesta.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria?.PSAN?.punteggio
            };

            let profiloJSX = this.recuperaProfilo(punteggiRichiesta);
            if (caratteristicheSettingRichiesto) {
                caratteristicheSettingRichiesto.codiceProfilo = profiloJSX;
            }
        }
    }

    setCaratteristicheSettingRichiesto(caratteristicheSettingRichiesto) {
        let numeroCampiObbligatoriRimanenti = calcolaCampiObbligatoriRimanenti(CONTROLLO_CAMPI_FORMALIZZAZIONE, caratteristicheSettingRichiesto, "caratteristicheSettingRichiesto");
        this.modificaCodiceProfilo(caratteristicheSettingRichiesto, _.cloneDeep(this.props.richiesta));
        this.setState({
            caratteristicheSettingRichiesto: caratteristicheSettingRichiesto,
            campiObbligatoriMancanti: numeroCampiObbligatoriRimanenti
        }, () => {
            this.props.onChangeFormalizzazione(caratteristicheSettingRichiesto, "caratteristicheSettingRichiesto", numeroCampiObbligatoriRimanenti);
        })
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            });
            AccordionHelper.setLastOpenedAccordion(field);
            this.setState({accordion});
        }
    }

    handleChangeCaratteristicheSettingRichiesto = (field, value) => {
        let caratteristicheSettingRichiesto = _.cloneDeep(this.state.caratteristicheSettingRichiesto);
        if (caratteristicheSettingRichiesto) {
            caratteristicheSettingRichiesto[field] = value;
            let numeroCampiObbligatoriRimanenti = calcolaCampiObbligatoriRimanenti(CONTROLLO_CAMPI_FORMALIZZAZIONE, caratteristicheSettingRichiesto, "caratteristicheSettingRichiesto");
            this.setState({
                caratteristicheSettingRichiesto: caratteristicheSettingRichiesto,
                campiObbligatoriMancanti: numeroCampiObbligatoriRimanenti
            }, () => {
                this.props.onChangeFormalizzazione(caratteristicheSettingRichiesto, "caratteristicheSettingRichiesto", numeroCampiObbligatoriRimanenti);
            })
        }
    };

    recuperaProfilo = (punteggiRichiesta) => {
        let profili = profiliData.profili;
        let profiloTrovato = _.find(profili, profilo => {
            let punteggioPCOG = profilo.punteggioPCOG;
            let punteggioPMOB = profilo.punteggioPMOB;
            let punteggioPSAN = profilo.punteggioPSAN;
            let punteggioPADL = profilo.punteggioPADL;

            let condizionePunteggioPCOG = (punteggioPCOG.min == null || (punteggioPCOG.min != null && punteggiRichiesta.punteggioPCOG >= punteggioPCOG.min)) &&
                (punteggioPCOG.max == null || (punteggioPCOG.max != null && punteggiRichiesta.punteggioPCOG <= punteggioPCOG.max));

            let condizionePunteggioPSAN = (punteggioPSAN.min == null || (punteggioPSAN.min != null && punteggiRichiesta.punteggioPSAN >= punteggioPSAN.min)) &&
                (punteggioPSAN.max == null || (punteggioPSAN.max != null && punteggiRichiesta.punteggioPSAN <= punteggioPSAN.max));

            let condizionePunteggioPMOB = (punteggioPMOB.min == null || (punteggioPMOB.min != null && punteggiRichiesta.punteggioPMOB >= punteggioPMOB.min)) &&
                (punteggioPMOB.max == null || (punteggioPMOB.max != null && punteggiRichiesta.punteggioPMOB <= punteggioPMOB.max));

            let condizionePunteggioPADL = (punteggioPADL.min == null || (punteggioPADL.min != null && punteggiRichiesta.punteggioPADL >= punteggioPADL.min)) &&
                (punteggioPADL.max == null || (punteggioPADL.max != null && punteggiRichiesta.punteggioPADL <= punteggioPADL.max));

            return condizionePunteggioPCOG && condizionePunteggioPSAN && condizionePunteggioPMOB && condizionePunteggioPADL;
        });
        let profiloDaVisualizzare = "";
        if(profiloTrovato){
            profiloDaVisualizzare = profiloTrovato?.numero + " - " + profiloTrovato?.definizione;
        }


        return profiloDaVisualizzare;
    }


    
    renderProfiloAutonomia = () => (
        <VersionedAccordion accordion={ProfiloAutonomia}
            codiceProfilo={this.state.caratteristicheSettingRichiesto?.codiceProfilo}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
        />
    )

    renderValutazione = () => (
        <VersionedAccordion accordion={ValutazioneInFormalizzazione}
            tipoValutazione= {this.state.caratteristicheSettingRichiesto?.tipoValutazione}
            dataValutazione= {this.state.caratteristicheSettingRichiesto?.dataValutazione}
            handleChangeCaratteristicheSettingRichiesto={this.handleChangeCaratteristicheSettingRichiesto}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
        />
    )

   
    render() {
        return (
            <Fragment>
                <AccordionAvvioRichiesta
                    idAccordion={"caratteristicheSettingRichiesto"}
                    title={"Caratteristiche del Setting richiesto"}
                    openAccordion={this.props.openAccordion}
                    onClickAccordion={this.props.onClickAccordion}
                    field={this.props.field}
                    numberOfRequiredFieldsMissing={this.state.campiObbligatoriMancanti}
                >  {this.renderProfiloAutonomia()}
                    {this.renderValutazione()}
                </AccordionAvvioRichiesta>
            </Fragment>
        );
    }
}

CaratteristicheSettingRichiesto.propTypes = propTypes;
CaratteristicheSettingRichiesto.defaultProps = defaultProps;
