import React from "react";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Argomento from "./argomento/Argomento";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import {argomenti} from "./DatiPerArgomento";
import VersionedAccordion from "../VersionedAccordion";
import {isFieldIdWriteable} from "../roleBasedComponents/RoleBasedComponents";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import enumRichiesta from "../../enum/enumRichiesta.json"
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";
import {isArray} from "lodash";

const propTypes = {
    datiAnamnesiPatologicaRemota: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiAnamnesiPatologicaRemota: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    showAccordionSezioneRichiesta: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}
export default class AnamnesiPatologicaRemota extends AccordionFirstLevel {

    state = {
        datiAnamnesiPatologicaRemota: _.cloneDeep(this.props.datiAnamnesiPatologicaRemota),
        sottoAccordion: {},
        campiObbligatoriMancanti:0
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiAnamnesiPatologicaRemota, this.props.datiAnamnesiPatologicaRemota))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        const datiAnamnesiPatologicaRemota = _.cloneDeep(this.props.datiAnamnesiPatologicaRemota);
        this.setState({datiAnamnesiPatologicaRemota}, () => {
           this.countCampiObbligatoriRimanentiAndValida();
        })
    }

    countCampiObbligatoriRimanentiAndValida = () => {
        let datiAnamnesiPatologicaRemota = _.cloneDeep(this.state.datiAnamnesiPatologicaRemota);
        const getProposte = Object.values(datiAnamnesiPatologicaRemota)
                                .filter(e => e.lista && e.lista.find(a => a.propostaAttivitaSuggerimenti?.length))
                                .map(o => o.lista)
        let currentProposte = [];
        getProposte.forEach(element => {
            isArray(element) && element.forEach(e =>{
                isArray(e.propostaAttivitaSuggerimenti) && e.propostaAttivitaSuggerimenti.forEach(o =>{
                    currentProposte.push(o)
                })
            })
        });  
        let campiObbligatoriMancanti = 0;  
        campiObbligatoriMancanti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(currentProposte)
        this.setState({campiObbligatoriMancanti}, () => {
            this.props.onChangeObbligatorieta("anamnesiPatologicaRemota", campiObbligatoriMancanti);
         })
        
    }

    onChangeAnamnesiPatologicaRemota = (field, dati) => {
        let datiAnamnesiPatologicaRemota = _.cloneDeep(this.state.datiAnamnesiPatologicaRemota);
        datiAnamnesiPatologicaRemota[field] = dati;
        this.setState({datiAnamnesiPatologicaRemota: datiAnamnesiPatologicaRemota}, () => {
            this.props.onChangeDatiAnamnesiPatologicaRemota(this.state.datiAnamnesiPatologicaRemota)
            this.countCampiObbligatoriRimanentiAndValida();
        })
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.sottoAccordion);
        accordion[field] = value;
        if (accordion[field]) {
            this.setState({sottoAccordion: accordion});
        }
    }

    renderCheckBoxAnamnesiPatologicaRemota() {
        let sottoArgomenti = _.cloneDeep(argomenti);

        return <>
            {Object.keys(sottoArgomenti).map(argomento => (
                <VersionedAccordion
                    accordion={Argomento}
                    key={sottoArgomenti[argomento].field}
                    dati={this.state.datiAnamnesiPatologicaRemota[sottoArgomenti[argomento].field]}
                    argomento={sottoArgomenti[argomento]}
                    onChangeDati={this.onChangeAnamnesiPatologicaRemota}
                    pageState={this.props.pageState}
                    openAccordion={true}
                    onClickAccordion={this.onClickAccordion}
                    showAccordionSezioneRichiesta={this.props.showAccordionSezioneRichiesta || !isFieldIdWriteable(sottoArgomenti[argomento].fieldsId['diagnosiPatologiaPregressa'], this.props.pageState, this.context.forceUserRole)}
                    forceReadOnly={this.props.forceReadOnly}
                    mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                    hideUnsetValues={false}
                    disableDoubleInput={true}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />
            ))}
        </>;
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"anamnesiPatologicaRemota"}
                title={"Anamnesi patologica remota"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                numberOfRequiredFieldsMissing={this.state.campiObbligatoriMancanti}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderCheckBoxAnamnesiPatologicaRemota()}</AccordionAvvioRichiesta>
        );
    }
}

AnamnesiPatologicaRemota.propTypes = propTypes;

AnamnesiPatologicaRemota.defaultProps = {
    showAccordionSezioneRichiesta: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

AnamnesiPatologicaRemota.contextType = RoleBasedContext;

