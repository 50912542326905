import {components} from 'react-select';
import React from 'react';
import {Icon} from 'web-client-archetype';

export const DropdownIndicator = {DropdownIndicator: (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <Icon id={'it-arrow-down-triangle'} className={["icon-green", "pb-1"]}/>
            </components.DropdownIndicator>
        )
    );
}}

export const DropdownIndicatorHidden ={DropdownIndicator: () => null};

export const inputSelectStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            height: 45,
            borderRadius: 10,
            borderWidth: 1,
            borderColor: state.isDisabled ? '#d6d6d6' : '#176A65',
            backgroundColor: state.isDisabled? '#E5E5E5' : '#ffffff',
            // ':hover': {borderColor: '#0e3f3b'},
            ':hover': {borderColor: '#176A65'},
            boxShadow: 'none'
        }
    },
    indicatorSeparator: () => {}, //Rimuove la barra di separazione accanto all'icona dropdown
    valueContainer: (provided) => {
        return {
            ...provided,
            height: 40
        }
    },
    input: (provided) => {
        return {
            ...provided,
            position: 'absolute',
            color: '#176A65'
        }
    },
    placeholder: (provided) => {
        return {
            ...provided,
            fontFamily: 'Arial',
            fontSize: 14,
            color: '#333333',
            opacity: 0.9
        }
    },
    singleValue: (provided) => {
        return {
            ...provided,
            fontFamily: 'Arial',
            fontSize: 14,
            color: '#176A65'
        }
    },
    option: (provided, state) => {
        return {
            ...provided,
            fontFamily: 'Arial',
            fontSize: 14,
            color: state.isDisabled ? '#333333': '#176A65',
            backgroundColor: state.isDisabled ? '#e6e9f2' : '#ffffff',
            ':active': {
                backgroundColor: '#ffffff',
                textDecoration: 'underline'
            },
            ':hover': {
                backgroundColor: state.isDisabled ? '#e6e9f2' : '#ffffff',
                textDecoration: 'underline'
            },
            textDecoration: state.isFocused ? 'underline' : 'none'
        }
    },
    menu: (provided) => {
        return {
            ...provided,
            borderRadius: 10,
            boxShadow: 'none',
            marginTop: 0,
            borderLeft: '1px solid #176A65',
            borderRight: '1px solid #176A65',
            borderBottom: '1px solid #176A65',
            zIndex: 9999
        }
    },
    dropdownIndicator: (provided) => {
        return {
            ...provided,
            padding: 0
        }
    }
};

export const paginationSelectStyles = {
    width: 55,
    height: 24
};

export const paginationOptions = () => {
    return (
        <>
            <option value={5}>5</option>
            <option value={10} selected>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
        </>
    );
}
