import React from "react";
import {Button, Modal} from "web-client-archetype";
import styles from "./ModalHelper.module.css";
import PropTypes from 'prop-types'

const propTypes = {
    id: PropTypes.string,
    title: PropTypes.any,
    body: PropTypes.any,
    footer: PropTypes.any,
    confirmBtnFunction: PropTypes.func,
    confirmBtnId: PropTypes.string,
    confirmBtnText: PropTypes.string,
    confirmBtnClassName: PropTypes.string,
    cancelBtnFunction: PropTypes.func,
    cancelBtnId: PropTypes.string,
    cancelBtnText: PropTypes.string,
    cancelBtnClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    footerClassName: PropTypes.string,
    footerStyle: PropTypes.object,
    dataDismissOnConfirm: PropTypes.bool,
    internalBackdrop: PropTypes.bool, // Indica se mostrare una backdrop interna al div della modale (in aggiunta a quella di default presente all'esterno del div principale di App.js); Da usare in casi di z-index innestati
    closePopupFunction: PropTypes.func
}

const defaultProps = {
    id: "Modal",
    title: "",
    body: "Vuoi procedere?",
    confirmBtnId: "btnConferma",
    confirmBtnText: "Si, procedi",
    confirmBtnClassName: "btn btn-adi btnGreenOutline mr-2",
    cancelBtnId: "btnAnnulla",
    cancelBtnText: "Annulla",
    cancelBtnClassName: "btn btn-adi btnWhiteOutlineBorderGreen",
    containerClassName: ("modal-dialog modal-dialog-centered ").concat(styles.containerModale),
    contentClassName: ("modal-content ").concat(styles.transform),
    titleClassName: ("modal-header backgroundGreen"),
    bodyClassName: ("modal-body ").concat(styles.modalBodyCustom),
    footerClassName: ("modal-footer justify-content-around"),
    footerStyle: {},
    dataDismissOnConfirm: true,
    internalBackdrop: false,
    closePopupFunction: null
}

class ModalHelper extends React.Component {
    render() {
        return (
            <>
                {this.props.internalBackdrop && <div className={styles.backdrop}/>}
                <Modal id={this.props.id}
                       hideBackdrop={this.props.internalBackdrop}
                       modalComponent={
                           <div className={"modal-helper modal fade noScroll ".concat(styles.clickDisabled)} tabIndex="-1"
                                role="dialog" id={this.props.id} data-keyboard="false">
                               <div className={this.props.containerClassName}>
                                   <div className={this.props.contentClassName}>
                                       <div className={"modal-header ".concat(this.props.titleClassName)}>
                                           <span className="modal-title w-100">{this.props.title}</span>
                                           {this.props.closePopupFunction ? <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                               onClick={() => this.props.closePopupFunction()}>
                                               <span aria-hidden="true">&times;</span>
                                           </button> : null}
                                        </div>

                                       <div className={this.props.bodyClassName}>
                                           {this.props.body}
                                       </div>

                                       <div className={this.props.footerClassName} style={this.props.footerStyle}>
                                           {this.renderFooter()}
                                       </div>
                                   </div>
                               </div>
                           </div>
                       }
                       showOnLoad={true}>
                </Modal>
            </>
        )
    }

    renderFooter = () => {
        let footerButtons = (
            <>
                    <Button
                        id={this.props.confirmBtnId}
                        className={this.props.confirmBtnClassName}
                        dataDismiss={this.props.dataDismissOnConfirm ? "modal" : null}
                        onClick={this.props.confirmBtnFunction}
                        text={this.props.confirmBtnText}>
                    </Button>
                    <Button
                        id={this.props.cancelBtnId}
                        className={this.props.cancelBtnClassName}
                        dataDismiss={"modal"}
                        onClick={this.props.cancelBtnFunction}
                        text={this.props.cancelBtnText}
                    />
            </>
        )

        return (this.props.footer) ? this.props.footer : footerButtons;
    }
}
ModalHelper.propTypes = propTypes
ModalHelper.defaultProps =defaultProps

export default ModalHelper
