import serviceConfig from "../config/serviceConfig";
import {catchError, finalize, map} from "rxjs/operators";
import {from, of} from "rxjs";
import auth from "../utils/AthnAuth";
import {mostraSpinner, nascondiSpinner} from "../App";
import * as _ from "lodash";


export default class RichiestaADIService {
    static urlRichieste = serviceConfig['ms_richiesta'];

    static ricercaDeleghe(data = null, azioni = null, page = null, size = null, ruolo = null) {
        mostraSpinner()
        let instance = auth._httpClient;

        let params = {};
            params.data = data;
        if (azioni)
            params.azioni = _.join(azioni, ",");
            params.ruolo = ruolo;
            params.page = page;
            params.size = size;

        let obs = from(instance.get(this.urlRichieste.ricercaDeleghe, {params: params}));
        return obs.pipe(map(response => {
                return response.data;
            }), catchError((err) => {
                return of({status: err.response?.status, data: err.response?.data, isError: true});
            }),
            finalize(() => {
                nascondiSpinner();
            }));
    }

    static inserimentoDeleghe(body) {
        mostraSpinner();
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.inserimentoDelega, body));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({status: err.response?.status, data: err.response?.data, isError: true});
            }),
            finalize(() => {
                nascondiSpinner();
            })
        );
    }

    static eliminaDelega(id, tipologia) {
        mostraSpinner();
        let instance = auth._httpClient;
        let obs = from(instance.delete(this.urlRichieste.eliminaDelega
            .replace("{id}", id)
            .replace("{tipologia}", tipologia)));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({status: err.response?.status, data: err.response?.data, isError: true});
            }),
            finalize(() => {
                nascondiSpinner();
            })
        );
    }

    static modificaDelega(id, body) {
        mostraSpinner();
        let instance = auth._httpClient;

        let obs = from(instance.patch(this.urlRichieste.modificaDelega.replace("{id}", id), body));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({status: err.response?.status, data: err.response?.data, isError: true});
            }),
            finalize(() => {
                nascondiSpinner();
            })
        );
    }
}
