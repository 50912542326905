const authConfig = {
    //ATTENZIONE!!! NON COMMENTARE NULLA DI QUESTO FILE, MODIFICARE IL FILE DI .ENV SPECIFICO E RIESEGUIRE LA BUILD
    //I FILE SONO NELLA ROOT DEL PROGETTO, SI POSSONO CREARE DEI PROBLEMI NEI DEPLOY DIMENTICANDO UN COMMENTO !!!

    "utente-reale": (process.env.REACT_APP_UTENTE_REALE === "true"),
    "attivazione-auth": (process.env.REACT_APP_ATTIVAZIONE_AUTH === "true"),
    "test-refresh-saml-token": (process.env.REACT_APP_TEST_REFRESH_SAML_TOKEN === "true"),
    "url-redirect-auth": process.env.REACT_APP_APP_URL_ATHN,
    "url-request-authToken" : process.env.REACT_APP_REQUEST_AUTH_TOKENS,
    "url-request-samlToken" : process.env.REACT_APP_REQUEST_SAML_TOKENS,
    "url-request-refreshToken" : process.env.REACT_APP_REQUEST_REFRESH_TOKENS,
    "url-logout": process.env.REACT_APP_APP_URL_LOGOUT,
    "url-redirect-home": process.env.REACT_APP_APP_URL_HOME,
    "SessionTimeOut": 30, //minutes,
    "SessionExpirationTime": 10 //hours
}

export default authConfig
