import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {
    FieldsRow,
    parentFieldIdToJsonPath,
    RoleBasedCalendar,
    RoleBasedSelect
} from "../roleBasedComponents/RoleBasedComponents";
import OggettiUtili from "../../utils/dataset/OggettiUtili.json";
import Utils from "../../utils/Utils";
import {isArray} from "lodash";
import {ProposteAttivitaSuggerimentiList} from "./ProposteAttivitaSuggerimentiList";
import {SessioneUtente} from "web-client-archetype";

const propTypes = {
    pageState: PropTypes.string.isRequired,
    ambito: PropTypes.string.isRequired,
    fieldId: PropTypes.string,
    parentFieldId: PropTypes.string.isRequired,
    infParentFieldId: PropTypes.string,
    fieldsPrefix: PropTypes.string,
    fieldsSuffix: PropTypes.string,
    appendRolePrefix: PropTypes.bool,

    showDataValutazione: PropTypes.bool,
    requiredDataValutazione: PropTypes.bool,

    onChangeStatoDopoValutazione: PropTypes.func.isRequired,
    valueStatoDopoValutazione: PropTypes.any,
    onChangeDataValutazione: PropTypes.func,
    valueDataValutazione: PropTypes.string,
    onChangeDaConsiderare: PropTypes.func.isRequired,
    valueDaConsiderare: PropTypes.string,

    showSuggerimenti: PropTypes.bool,
    valueSuggerimenti: PropTypes.object,
    onChangeSuggerimenti: PropTypes.func,

    showAutonomia: PropTypes.bool,
    onChangeAutonomiaPaziente: PropTypes.func,
    valueAutonomiaPaziente: PropTypes.string,
    onChangeAutonomiaCaregiver: PropTypes.func,
    valueAutonomiaCaregiver: PropTypes.string,

    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    fieldInAccordionReadOnly: PropTypes.bool,

    checkboxIdSuffix: PropTypes.string,

    parentJsonPath: PropTypes.string,
    openCondition: PropTypes.bool,
    unicheParentId: PropTypes.string
}

const defaults = {
    appendRolePrefix: false,
    showDataValutazione: true,
    requiredDataValutazione: false,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false,
    fieldInAccordionReadOnly: false,
    openCondition: true
}

export default class ValutazioneSection extends React.Component {

    state = {
        statoDopoValutazione: this.props.valueStatoDopoValutazione,
        dataValutazione: this.props.valueDataValutazione,
        daConsiderare: this.props.valueDaConsiderare,
        autonomiaGestionePaziente: this.props.valueAutonomiaPaziente,
        autonomiaGestioneCaregiver: this.props.valueAutonomiaCaregiver,
        suggerimenti: this.props.valueSuggerimenti && !isArray(this.props.valueSuggerimenti) ? [this.props.valueSuggerimenti] : this.props.valueSuggerimenti,
    }

    condRilevataForzeRead;

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.valueStatoDopoValutazione !== this.props.valueStatoDopoValutazione) {
            this.setState({statoDopoValutazione: this.props.valueStatoDopoValutazione}, () => this.validateData());
        }

        if (prevProps.valueDataValutazione !== this.props.valueDataValutazione) {
            this.setState({dataValutazione: this.props.valueDataValutazione});
        }

        if (prevProps.valueDaConsiderare !== this.props.valueDaConsiderare) {
            this.setState({daConsiderare: this.props.valueDaConsiderare});
        }

        if (prevProps.valueAutonomiaPaziente !== this.props.valueAutonomiaPaziente) {
            this.setState({autonomiaGestionePaziente: this.props.valueAutonomiaPaziente});
        }

        if (prevProps.valueAutonomiaCaregiver !== this.props.valueAutonomiaCaregiver) {
            this.setState({autonomiaGestioneCaregiver: this.props.valueAutonomiaCaregiver});
        }

        if (prevProps.valueSuggerimenti !== this.props.valueSuggerimenti) {
            this.setState({suggerimenti: this.props.valueSuggerimenti && !isArray(this.props.valueSuggerimenti) ? [this.props.valueSuggerimenti] : this.props.valueSuggerimenti});
        }
    }

    onChange = (effectCallback) => {
        effectCallback();
        this.validateData();
    }

    validateData() {
        if (this.state.statoDopoValutazione !== "02") {
            this.setState({dataValutazione: null}, () => this.props.onChangeDataValutazione(null));
            this.setState({daConsiderare: null}, () => this.props.onChangeDaConsiderare(null));
            this.setState({autonomiaGestionePaziente: null}, () => {
                if (this.props.onChangeAutonomiaPaziente) this.props.onChangeAutonomiaPaziente(null);
            });
            this.setState({autonomiaGestioneCaregiver: null}, () => {
                if (this.props.onChangeAutonomiaCaregiver) this.props.onChangeAutonomiaCaregiver(null);
            });
            this.setState({suggerimenti: []},
                () => this.props.onChangeSuggerimenti("propostaAttivitaSuggerimenti", []));
        }
    }

    render() {
        if (!this.props.openCondition) return null;
        const mRolePrefix = this.props.appendRolePrefix ? 'M_' : '';
        const iRolePrefix = this.props.appendRolePrefix ? 'I_' : null;
        const fieldsPrefix = this.props.fieldsPrefix ? this.props.fieldsPrefix + '_' : '';
        const fieldsSuffix = this.props.fieldsSuffix ? '_' + this.props.fieldsSuffix : '';
        const infParentFieldId = this.props.infParentFieldId ?? this.props.parentFieldId;
        
        if(this.state.suggerimenti && isArray(this.state.suggerimenti)) {
            this.condRilevataForzeRead = (this.context.forceReadOnly || this.props.forceReadOnly || !!(this.state.suggerimenti.filter(p => p?.codiceFiscale && p?.codiceFiscale != SessioneUtente.getInstance().idUtente || p?.propostaAttivitaSuggerimenti?.codiceFiscale && p?.propostaAttivitaSuggerimenti?.codiceFiscale != SessioneUtente.getInstance().idUtente)?.length));
        }

        const daConsiderareJSX = <RoleBasedSelect
            key={fieldsPrefix + "DA_CONSID" + fieldsSuffix + '.' + this.props.parentFieldId}
            fieldId={mRolePrefix + fieldsPrefix + (this.props.fieldsSuffix === "PSOC" ? 'DACONSID' : 'DA_CONSID') + fieldsSuffix + '.' + this.props.parentFieldId}
            infermiereFieldId={iRolePrefix ? (iRolePrefix + fieldsPrefix + 'DA_CONSID' + fieldsSuffix + '.' + infParentFieldId) : null}
            pageState={this.props.pageState}
            id={"selectDaConsiderare"}
            options={OggettiUtili.daConsiderareAiFiniAssistenziali}
            value={this.state.daConsiderare}
            onChange={(elem) => {
                this.setState({daConsiderare: elem}, () => this.onChange(() => this.props.onChangeDaConsiderare(elem)));
            }}
            isSearchable={false}
            noOptionsMessage={() => "Errore: Da considerare non esistente"}
            fieldLabel={'Da considerare'}
            forceReadOnly={this.props.forceReadOnly}
            hideUnsetValues={this.props.hideUnsetValues}
            disableDoubleInput={this.props.disableDoubleInput}
            handleOnlyValue={true}
            field={"daConsiderare"}
            parentJsonPath={this.props.parentJsonPath}
            fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
        />;

        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={mRolePrefix + fieldsPrefix + 'STATO_DOPO_VAL' + fieldsSuffix + '.' + this.props.parentFieldId}
                        infermiereFieldId={iRolePrefix ? (iRolePrefix + fieldsPrefix + 'STATO_DOPO_VAL' + fieldsSuffix + '.' + infParentFieldId) : null}
                        pageState={this.props.pageState}
                        id={"selectStatoDopoValutazione"}
                        className={"selectStatoDopoValutazione"}
                        options={OggettiUtili.statoDopoValutazione}
                        value={this.state.statoDopoValutazione}
                        onChange={(elem) => {
                            this.setState({statoDopoValutazione: elem}, () => this.onChange(() => this.props.onChangeStatoDopoValutazione(elem)));
                        }}
                        isSearchable={false}
                        fieldLabel={"Stato dopo valutazione"}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        handleOnlyValue={true}
                        field={"statoDopoValutazione"}
                        parentJsonPath={this.props.parentJsonPath}
                        forceReadOnly={this.props.forceReadOnly || this.condRilevataForzeRead}
                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                    />

                    {this.state.statoDopoValutazione === "02" && this.props.showDataValutazione && [
                        <RoleBasedCalendar
                            key={fieldsPrefix + 'DATA_VAL' + fieldsSuffix + '.' + this.props.parentFieldId}
                            fieldId={mRolePrefix + fieldsPrefix + 'DATA_VAL' + fieldsSuffix + '.' + this.props.parentFieldId}
                            infermiereFieldId={iRolePrefix ? (iRolePrefix + fieldsPrefix + 'DATA_VAL' + fieldsSuffix + '.' + infParentFieldId) : null}
                            pageState={this.props.pageState}
                            id={"valutazioneSectionDataValutazione" + this.props.parentFieldId?.replace('.', '')}
                            placeholder={"gg/mm/aaaa"}
                            onChange={(elem) => {
                                this.setState({dataValutazione: elem.target?.value}, () => this.onChange(() => this.props.onChangeDataValutazione(this.state.dataValutazione)));
                            }}
                            error={(Utils.timeDifference(Date.now(), new Date(Utils.convertDDMMYYYYDateToIsoDate(this.state.dataValutazione)), true) < 0)}
                            value={this.state.dataValutazione}
                            errorMessage={"Data non valida"}
                            fieldLabel={"Data valutazione"}
                            forceReadOnly={this.props.forceReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            field={"dataValutazione"}
                            fieldRequired={this.props.requiredDataValutazione}
                            parentJsonPath={this.props.parentJsonPath}
                            fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                        />,
                        daConsiderareJSX
                    ]}
                    {this.state.statoDopoValutazione === "02" && !this.props.showDataValutazione && daConsiderareJSX}
                </FieldsRow>

                {this.props.showSuggerimenti && this.state.statoDopoValutazione === '02' &&
                    <ProposteAttivitaSuggerimentiList
                        suggerimenti={this.state.suggerimenti || []}  
                        pageState={this.props.pageState} 
                        ambito={this.props.ambito}
                        fieldId={fieldsPrefix + 'PROP_ATT_SUGG' + fieldsSuffix + '.' + this.props.parentFieldId}
                        onChange={(name, value) => {
                            this.setState({suggerimenti: value}, () => this.onChange(() => this.props.onChangeSuggerimenti(name, value)));
                        }}
                        parentFieldId={this.props.parentFieldId}
                        forceReadOnly={this.context.forceReadOnly || this.props.forceReadOnly}
                        fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        infParentFieldId={infParentFieldId}
                        appendRolePrefix={this.props.appendRolePrefix}
                        fieldsPrefix={this.props.fieldsPrefix}
                        fieldsSuffix={this.props.fieldsSuffix}
                        checkboxIdSuffix={this.props.checkboxIdSuffix}
                        parentJsonPath={this.props.parentJsonPath}
                        unicheParentId={this.props.unicheParentId}
                    />
                }

                {this.props.showAutonomia && this.state.statoDopoValutazione === "02" &&
                    <FieldsRow>
                        <RoleBasedSelect
                            fieldId={'AUT_GEST_PAZ' + fieldsSuffix + '.' + infParentFieldId}
                            pageState={this.props.pageState}
                            id={"selectAutonomiaGestionePaziente"}
                            options={OggettiUtili.autonomiaGestione}
                            value={this.state.autonomiaGestionePaziente}
                            onChange={(elem) => {
                                this.setState({autonomiaGestionePaziente: elem}, () => this.onChange(() => this.props.onChangeAutonomiaPaziente(elem)));
                            }}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Autonomia gestione paziente non esistente"}
                            fieldLabel={'Autonomia di gestione del paziente'}
                            forceReadOnly={this.props.forceReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                            field={"autonomiaGestionePaziente"}
                            parentJsonPath={parentFieldIdToJsonPath(this.props.parentJsonPath, 'AUT_GEST_PAZ' + fieldsSuffix + '.' + this.props.parentFieldId)}
                            fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                        />

                        <RoleBasedSelect
                            fieldId={'AUT_GEST_CAREG' + fieldsSuffix + '.' + infParentFieldId}
                            pageState={this.props.pageState}
                            id={"selectAutonomiaGestioneCaregiver"}
                            options={OggettiUtili.autonomiaGestione}
                            value={this.state.autonomiaGestioneCaregiver}
                            onChange={(elem) => {
                                this.setState({autonomiaGestioneCaregiver: elem}, () => this.onChange(() => this.props.onChangeAutonomiaCaregiver(elem)));
                            }}
                            isSearchable={false}
                            noOptionsMessage={() => "Errore: Autonomia gestione caregiver non esistente"}
                            fieldLabel={'Autonomia di gestione del caregiver'}
                            forceReadOnly={this.props.forceReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                            handleOnlyValue={true}
                            field={"autonomiaGestioneCaregiver"}
                            parentJsonPath={parentFieldIdToJsonPath(this.props.parentJsonPath, 'AUT_GEST_CAREG' + fieldsSuffix + '.' + this.props.parentFieldId)}
                            fieldInAccordionReadOnly={this.props.fieldInAccordionReadOnly}
                        />
                    </FieldsRow>}
            </>
        );
    }
}

ValutazioneSection.propTypes = propTypes;
ValutazioneSection.defaultProps = defaults;
