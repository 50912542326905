import React from "react";
import PropTypes from "prop-types";
import AccordionSecondLevel from "../../../accordionSezioneRichiesta/AccordionSecondLevel";
import * as _ from "lodash";
import AccordionSezioneRichiesta from "../../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {FieldsRow, RoleBasedInput} from "../../../roleBasedComponents/RoleBasedComponents";

const propTypes = {
    dati: PropTypes.any,
    onChangeDatiObiettiviClinicoAssistenziali: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    disableDoubleInput: PropTypes.bool
}

export default class ObiettiviClinicoAssistenzialiSottoAccordion extends AccordionSecondLevel {
    state = {
        obiettiviClinicoAssistenziali: _.cloneDeep(this.props.dati)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.dati, this.props.dati))
            this.setState({obiettiviClinicoAssistenziali: this.props.dati});
    }

    renderBody = () => {
        return (
            <>
                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'X_OBIETT.OBIETTIVICLINICORIABILITATIVOASSISTENZIALI.RICHIESTACAMBIOSETTING'}
                        pageState={this.props.pageState}
                        placeholder={"Inserisci obiettivo"}
                        field={"obiettivi"}
                        onChange={(field, elem) => {
                            this.setState({obiettiviClinicoAssistenziali: {...this.state.obiettiviClinicoAssistenziali, [field]: elem.target.value}}, () => {
                                this.props.onChangeDatiObiettiviClinicoAssistenziali("obiettiviClinicoAssistenziali", this.state.obiettiviClinicoAssistenziali);
                            });
                        }}
                        id={"inputObiettivoClinicoAssistenziale"}
                        value={this.state.obiettiviClinicoAssistenziali.obiettivi}
                        fieldLabel={"Obiettivi"}
                        parentJsonPath={"obiettiviClinicoAssistenziali"}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"obiettivi"}
                title={"Obiettivi clinico-assistenziali"}
                required={false}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
            >{this.renderBody()}</AccordionSezioneRichiesta>
        );
    }
}

ObiettiviClinicoAssistenzialiSottoAccordion.propTypes = propTypes;
