import React, {Component} from "react";
import {Button, Icon, Input, SessioneUtente} from "web-client-archetype";
import Utils, { closeModaleStyle } from "../../../utils/Utils";
import * as _ from "lodash";
import {Context} from "../../../utils/Context";
import PropTypes from "prop-types";
import styleModalDate from "./ModificaDataDimissioneProgrammata.module.css";
import { mostraSpinner, nascondiSpinner } from "../../../App";
import RichiestaADIService from "../../../service/RichiestaADIService";
import ModalHelper from "../../modale/ModalHelper";
import Calendar from "../../generalComponents/calendar/Calendar";

const propTypes = {
    location: PropTypes.object,
    dataIniziale: PropTypes.any,
    chiudiModificaDataDimissione: PropTypes.func,
    uuidRichiesta: PropTypes.any
}

const ORA_MEZZOGIORNO = 12;
const FORMAT_DATE_FE = "YYYY-MM-DD";
const FORMAT_DATE_BE = "DD/MM/YYYY";
const DAYS_IF_BEFORE_12 = 3;
const DAYS_IF_AFTER_12 = 4;

class ModificaDataDimissioneProgrammata extends Component {


    state = {
        dataModificata: Utils.formatDate(this.props.dataIniziale, FORMAT_DATE_BE, FORMAT_DATE_FE),
        pulsanteConfermaAbilitato: false,
        mostraModaleOperazioneEseguita: false
    }

    constructor(props) {
        super(props);
        this.handleAggiornaState = this.handleAggiornaState.bind(this);
    }

    handleAggiornaState(field, event) {
        let state = _.cloneDeep(this.state);
        if ('' === event || Utils.isValidDateFormatYYYYMMDD(event)) {
            state[field] = event;
        }
        let diff = this.props.dataIniziale !== Utils.formatDate(this.state.dataModificata, FORMAT_DATE_FE, FORMAT_DATE_BE);
        if(diff){
            if(Utils.isValidDateFormatYYYYMMDD(this.state.dataModificata)){
                if(this.controlloDataDimissione(this.state.dataModificata, Utils.formatDate(this.props.dataIniziale, FORMAT_DATE_BE, FORMAT_DATE_FE))) {
                    state["pulsanteConfermaAbilitato"] = true;
                } else {
                    state["pulsanteConfermaAbilitato"] = false;
                }
            } else {
                state["pulsanteConfermaAbilitato"] = false;
            }
        } else {
            state["pulsanteConfermaAbilitato"] = false;
        }
        this.setState(state);
    }

    validateDataModificata(value){
        if(value && !Utils.isValidDateFormatYYYYMMDD(value)){
            return "*data non valida";
        }

        return null;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {                
    }

    chiudiModificaDataDimissione = () => {
        closeModaleStyle();
        this.setState({ mostraModaleOperazioneEseguita: false });
        this.props.chiudiModificaDataDimissione();
    }

    controlloGiorniDifferenza(days, number){
      if(days >= number){
          return true;
      }
      return false;
    }

    controlloDataDimissione(date, prevDate){        
        var oggiAMezzanotte = new Date();
        oggiAMezzanotte.setHours(0,0,0,0);
        const giorniDifferenza = Math.floor((new Date(date) - oggiAMezzanotte) / (1000*60*60*24));        
        if((new Date()).getHours < ORA_MEZZOGIORNO){
            if(this.controlloGiorniDifferenza(giorniDifferenza, DAYS_IF_BEFORE_12)){
                return true;
            }
            return false;
        } else {
            if(this.controlloGiorniDifferenza(giorniDifferenza, DAYS_IF_AFTER_12)){
                return true;
            }
            return false;
        }
    }

    modificaDataDimissione() {
        mostraSpinner();

        RichiestaADIService.modificaDataDimissioneProgrammata(this.props.uuidRichiesta, Utils.formatDate(this.state.dataModificata, FORMAT_DATE_FE, FORMAT_DATE_BE)).subscribe((response) => {
            if (response?.msg === "ERRORE") {                
                Utils.toasterFunction({
                    status: response?.data?.codice ? response?.data?.codice : response?.status,
                    type: "critical",
                    text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                });
                this.chiudiModificaDataDimissione();
                nascondiSpinner();
                return;                                                
            }            
            this.setState({                
                mostraModaleOperazioneEseguita: true
            });            
        });
    }

    renderModale = () => {
        let state = _.cloneDeep(this.state);

        const titleModaleModificaDataDimissioneProgrammataMedico = "Modifica la data di dimissione programmata del Medico";        
        const titleModaleOperazioneEseguita = "Operazione completata";
        
        const bodyModaleOperazioneEseguitaText = "Data di dimissione programmata correttamente modificata.";
        
        const footer = (
            <>
                <Button
                    id={"conferma"}
                    text="Conferma"
                    className={"btn btn-adi btnGreenOutline"}
                    disabled={!this.state.pulsanteConfermaAbilitato}
                    onClick={() => this.modificaDataDimissione()}
                />
                <Button
                    id={"annulla"}
                    text="Annulla"
                    className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                    onClick={() => this.chiudiModificaDataDimissione()}
                />
            </>
        );
        
        const footerModaleOperazioneEseguita = (
            <>                
                <Context.Consumer>
                    {({ clickHome, setClickHome }) => (
                        <Button
                            onClick={() => {
                                this.chiudiModificaDataDimissione();
                                setClickHome(true);
                            }}
                            id={"chiudiModificaDimissioneProgrammataMedico"}
                            text="Chiudi"
                            className={"btn btn-adi btnWhiteOutlineBorderGreen"}
                        />
                    )}
                </Context.Consumer>
            </>
        );
        
        const bodyModale = (
            <>
                <div className="m-3">
                    <Calendar
                        id={"dataModificata"}
                        value={this.state.dataModificata}
                        field={"dataModificata"}
                        onChange={(event) => this.handleAggiornaState("dataModificata", event.currentTarget.value)}
                        errorMessage={"Data non valida"}
                        error={!this.state.dataModificata ? false : true}
                        label={"Modifica Data"}
                        required
                    ></Calendar>
                </div>
            </>
        );
        
        const getFooter = () => {
            if (state.mostraModaleOperazioneEseguita) {
                return (footerModaleOperazioneEseguita);
            }
            else {
                return (footer);
            }
        }
        
        const getTitle = () => {
            if (state.mostraModaleOperazioneEseguita) return titleModaleOperazioneEseguita;            
            else return (
                <>
                    <span>{titleModaleModificaDataDimissioneProgrammataMedico}</span>
                </>
            );
        }
        
        const getBody = () => {
            if (state.mostraModaleOperazioneEseguita) {
                return (bodyModaleOperazioneEseguitaText);
            }            
            else {
                return (bodyModale);
            }
        };
        
        return (
            <>
                <ModalHelper
                    id={"modaleModificaDimissioneProgrammataMedico"}
                    title={getTitle()}
                    titleClassName={"modal-header d-inline text-center backgroundGreen"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal modal-dialog-centered"}
                    contentClassName={" modal-content"}
                    body={getBody()}
                    footer={getFooter()}
                    footerClassName={"modal-footer justify-content-around"}
                    bodyClassName={("modal-body").concat(state.mostraModaleOperazioneEseguita ? " d-flex align-items-center f-weight-600 mx-auto": "")}
                    closePopupFunction={state.mostraModaleOperazioneEseguita ? null : () => this.chiudiModificaDataDimissione()}
                    //internalBackdrop={true}
                />
            </>)
    }

    render() {
        return (
            <>
                {this.renderModale()}
            </>
        )
    }
    
}



ModificaDataDimissioneProgrammata.contextType = Context;
ModificaDataDimissioneProgrammata.propTypes = propTypes;

export default ModificaDataDimissioneProgrammata;