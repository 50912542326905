import React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import Utils from "../../../utils/Utils";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import CompilaQuestionario from "../../compilaQuestionario/CompilaQuestionario";
import enumQuestionario from "../../../enum/enumQuestionario"
import {RoleBasedContext} from "../../../utils/RoleBasedContext";

const propTypes = {
    richiesta: PropTypes.any,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onChangeValutazioneBisogniPalliativi: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    readOnly: PropTypes.bool,
    salvaBozza:PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class ValutazioneBisogniPalliativiSub extends React.Component {

    componentDidMount() {
        this.props.onChangeRequiredFieldsNumber(Utils.isStateRichiesta(this.props.pageState) ? 1 : 0);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.richiesta, this.props.richiesta)) {
            // this.externaQuestionario = _.cloneDeep(questionariData[enumQuestionario.tipoQuestionario.NECPAL]
            this.props.onChangeRequiredFieldsNumber(Utils.isStateRichiesta(this.props.pageState) ? 1 : 0);
        }
    }

    handleAggiornaForm = (field, elem) => {
        this.props.onChangeValutazioneBisogniPalliativi(field, elem.richiesta);
        this.props.onChangeRequiredFieldsNumber(Utils.isStateRichiesta(this.props.pageState) ? elem?.numeroCampiMancanti : 0);
    }

    render() {
        const { openAccordion, onClickAccordion, field, pageState, richiesta } = this.props
        return (
            <AccordionSezioneRichiesta
                idAccordion={"NECPAL"}
                title={"Valutazione bisogni palliativi"}
                required={Utils.isStateRichiesta(pageState)}
                openAccordion={openAccordion}
                onClickAccordion={onClickAccordion}
                field={field}
                pageState={pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}>
                <CompilaQuestionario
                    richiesta={richiesta}
                    tipoQuestionario={enumQuestionario.tipoQuestionario.NECPAL}
                    pageState={pageState}
                    pathRichiestaQuestionario={"valutazioneBisogniPalliativi.NECPAL"}
                    externalChange={(element) => (this.handleAggiornaForm(enumQuestionario.tipoQuestionario.NECPAL, element))}
                    hiddenCtaContainer={true}
                    hiddenScroll={true}
                    labelSezioneObbligatoria={false}
                    titoloRequired={true}
                    disabilitato={Utils.isStateValutazione(this.props.pageState) || this.props.readOnly || this.context.forceReadOnly || !!this.props.accordionReadOnly}
                    salvaBozza = {this.props.salvaBozza}
                    showOnlyRequiredFields={this.context.showOnlyRequiredFields}
                />
            </AccordionSezioneRichiesta>
        )
    }
}

ValutazioneBisogniPalliativiSub.propTypes = propTypes;
ValutazioneBisogniPalliativiSub.contextType = RoleBasedContext;
