import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  FieldsGroup,
  FieldsRow,
  RoleBasedCalendar,
} from "../../roleBasedComponents/RoleBasedComponents";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {CONTROLLO_CAMPI_FORMALIZZAZIONE} from "../CompilaFormalizzazione";

import moment from "moment";

function EventoAssistenzialeCureDomiciliari({
  openAccordion,
  onClickAccordion,
  field,
  pageState,
  progettoAssistenziale,
  handleChangeProgettoAssistenziale,
}) {
  let dataInizio = (
    <FieldsGroup
        fieldId={"DATA_INIZ.DATADIINIZIOEVENTOASSISTENZCD.PROGETTOASSISTENZIALERICHIESTO"}
        fieldLabel={"Data inizio"}
        fieldRequired={CONTROLLO_CAMPI_FORMALIZZAZIONE.dataInizioProgettoAssistenziale.required}
        showFieldIfRequiredAccordionPage={CONTROLLO_CAMPI_FORMALIZZAZIONE.dataInizioProgettoAssistenziale.required}
    >
      <RoleBasedCalendar
        fieldId={"DATA_INIZ.DATADIINIZIOEVENTOASSISTENZCD.PROGETTOASSISTENZIALERICHIESTO"}
        pageState={pageState}
        id={"progettoAssistenzialeDATA_INIZ"}
        value={
          progettoAssistenziale?.dataInizioProgettoAssistenziale
        }
        onChange={(elem) =>
          handleChangeProgettoAssistenziale(
            "dataInizioProgettoAssistenziale",
            elem.target.value
          )
        }
        errorMessage={"Data non valida"}
        forceReadOnly={false}
        hideUnsetValues={false}
        disableDoubleInput={false}
        field={"dataInizioProgettoAssistenziale"}
        fieldRequired={CONTROLLO_CAMPI_FORMALIZZAZIONE.dataInizioProgettoAssistenziale.required}
        error={progettoAssistenziale?.dataInizioProgettoAssistenziale && !moment(progettoAssistenziale?.dataInizioProgettoAssistenziale, "DD/MM/YYYY").isValid()}
        fixedHeight={"350px"}
      />
    </FieldsGroup>
  );

  let dataVerifica = (
    <FieldsGroup
        fieldId={"DATA_VERIFICA.DATAVERIFICAPROGRAMMATA.PROGETTOASSISTENZIALERICHIESTO"}
        fieldLabel={"Data verifica"}
        fieldRequired={CONTROLLO_CAMPI_FORMALIZZAZIONE.dataVerificaProgrammata.required}
        showFieldIfRequiredAccordionPage={false}
    >
      <RoleBasedCalendar
        fieldId={"DATA_VERIFICA.DATAVERIFICAPROGRAMMATA.PROGETTOASSISTENZIALERICHIESTO"}
        pageState={pageState}
        id={"progettoAssistenzialeDATA_VERIFICA"}
        value={progettoAssistenziale?.dataVerificaProgrammata}
        onChange={(elem) =>
          handleChangeProgettoAssistenziale(
            "dataVerificaProgrammata",
            elem.target.value
          )
        }
        errorMessage={"La data verifica deve essere successiva alla data inizio"}
        forceReadOnly={false}
        hideUnsetValues={false}
        disableDoubleInput={false}
        minDate={
          progettoAssistenziale?.dataInizioProgettoAssistenziale
        }
        field={"dataVerificaProgrammata"}
        error={ progettoAssistenziale?.dataInizioProgettoAssistenziale && progettoAssistenziale?.dataVerificaProgrammata && moment(progettoAssistenziale?.dataInizioProgettoAssistenziale, "DD/MM/YYYY").isSameOrAfter(moment(progettoAssistenziale?.dataVerificaProgrammata, "DD/MM/YYYY"))}
        fixedHeight={"350px"}
      />
    </FieldsGroup>
  );

  return (
    <Fragment>
      <AccordionSezioneRichiesta
        idAccordion={"eventoAssistenzialeCureDomiciliari"}
        title={"Evento assistenziale di cure domiciliari"}
        required={true}
        openAccordion={openAccordion}
        onClickAccordion={onClickAccordion}
        field={field}
        pageState={pageState}
      >
        <FieldsRow>
          {dataInizio}
          {dataVerifica}
        </FieldsRow>
      </AccordionSezioneRichiesta>
    </Fragment>
  );
}

EventoAssistenzialeCureDomiciliari.propTypes = {
  openAccordion: PropTypes.bool,
  onClickAccordion: PropTypes.func,
  field: PropTypes.string,
  pageState: PropTypes.string,
  progettoAssistenziale: PropTypes.node,
};

EventoAssistenzialeCureDomiciliari.defaultProps = {
  openAccordion: false,
  onClickAccordion: null,
  field: "",
  pageState: "",
  progettoAssistenziale: {},
};

export default EventoAssistenzialeCureDomiciliari;
