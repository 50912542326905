import questionariData from "./dataset/questionariData/questionari";
import enumQuestionario from "../enum/enumQuestionario"
import _ from "lodash";

export const defaultQuestionario = {
    sezioni: [],
    punteggio: null,
    valutazione: null,
    stato: enumQuestionario.statoQuestionario.manuale
};


export const calcoloValutazione = (tipoQuestionario, sezioneQuestionario, punteggio, sessoPaziente) => {
    if(sezioneQuestionario?.punteggi?.length > 1 && punteggio != null){
        if(tipoQuestionario === enumQuestionario.tipoQuestionario.AUDITC){
            punteggio = impostaPunteggioFittizzioPerQuestionarioAUDITC(punteggio, sessoPaziente);
        }
        let itemDescrizionePunteggio = sezioneQuestionario.punteggi[1].valori
            .find(rangePunteggio => {
                return (rangePunteggio.min == null || (rangePunteggio.min != null && punteggio >= rangePunteggio.min)) &&
                    (rangePunteggio.max == null || (rangePunteggio.max != null && punteggio <= rangePunteggio.max))
            });
        return itemDescrizionePunteggio ? itemDescrizionePunteggio.label : null;
    } else {
        return null;
    }
};

export const impostaPunteggioFittizzioPerQuestionarioAUDITC = (punteggio, sessoPaziente) => {
    // imposta punteggio fittizio
    const SESSO_M = "M";
    const SESSO_F = "F";
    if (punteggio < 5 && sessoPaziente === SESSO_M) {
        punteggio = 1;
    } else if (punteggio >= 5 && sessoPaziente === SESSO_M) {
        punteggio = 2;
    } else if (punteggio < 4 && sessoPaziente === SESSO_F) {
        punteggio = 3;
    } else if (punteggio >= 4 && sessoPaziente === SESSO_F) {
        punteggio = 4;
    }
    return punteggio;
}

export const punteggiIntermedi = {};


export const getQuestionario = (jsonPath) => {
    return questionariData[jsonPath] || null;
}

export const getSezioneQuestionario = (tipoQuestionario, idSezione) => {
    return getQuestionario(tipoQuestionario)?.sezioni.find(sezione => sezione.id === idSezione) || null;
}

export const getSezioneValutazione = (tipoQuestionario) => {
    let questionario = getQuestionario(tipoQuestionario);
    return questionario.sezioni.reduce(function (prev, curr) {
        return prev.id > curr.id ? prev : curr;
    })
}

export const areCampiObbligatoriSezioneCompilati = (sezioneRichiesta, datiSezione) => {
    if (sezioneRichiesta) {
        let isAlmenoUnaDomandaObbligatoriaNonCompilata = datiSezione.domande.some(datiDomanda => {
            if (datiDomanda.obbligatorio) {
                let indiceDomandaRichiesta = sezioneRichiesta.domande.findIndex(infoDomanda => infoDomanda.id === datiDomanda.id);
                let isDomandaRichiestaPresente = indiceDomandaRichiesta > -1;
                return !isDomandaRichiestaPresente;
            } else {
                return false;
            }
        });
        return !isAlmenoUnaDomandaObbligatoriaNonCompilata;
    }
}

export const areAllSectionValorised = (sezioni, tipoQuestionario) => {
    if (sezioni == null) return false;

    return sezioni.every(sezione => {
        const currentDefaultSection = getSezioneQuestionario(tipoQuestionario, sezione.id);
        const lengthRequiredQuestions = currentDefaultSection.domande.filter(d => !!d.obbligatorio).length
        const sezioneById = sezioni.find(s => s.id === currentDefaultSection.id);
        return sezioneById.domande.length >= (lengthRequiredQuestions || 1);
    });
}

export const getRichiestaQuestionario = (richiesta, path) => {
    let i;
    path = path.split('.');
    for (i = 0; i < path.length - 1; i++) {
        if (richiesta?.[path[i]]) {
            richiesta = richiesta[path[i]];
        }
    }
    return richiesta
}

export const setRichiestaQuestionario = (richiesta, questionario, path) => {
    let i;
    path = path.split('.');
    for (i = 0; i < path.length - 1; i++) {
        if (richiesta && richiesta[path[i]]) {
            richiesta = richiesta[path[i]];
        } else {
            if (richiesta) {
                richiesta[path[i]] = {};
                richiesta = richiesta[path[i]];
            } else {
                richiesta = {};
                richiesta[path[i]] = {};
                richiesta = richiesta[path[i]];
            }
        }
    }
    if (richiesta && richiesta[path[i]]) {
        richiesta[path[i]] = questionario;
    } else {
        richiesta[path[i]] = {};
        richiesta[path[i]] = questionario;
    }

}


export const calcoloPunteggioTotaleQuestionario = (punteggioSottoQuestionari, tipoQuestionario) => {
    let punteggioTotale = punteggioSottoQuestionari[tipoQuestionario] ? 0 : null;
    _.forIn(punteggioSottoQuestionari[tipoQuestionario], (value, key) => {
        // non calcola il punteggio di scala braden e exton smith per psan
        if (tipoQuestionario === enumQuestionario.tipoQuestionario.PSAN && ["3","4"].includes(key)) return;
        punteggioTotale += value ?? 0;
    });
    return punteggioTotale;
}


export const punteggioTotaleArrotondato = (punteggioSottoQuestionari, tipoQuestionario) => {
    // calcolo punteggio totale questionario
    let punteggioTotale = calcoloPunteggioTotaleQuestionario(punteggioSottoQuestionari, tipoQuestionario);

    if (punteggioTotale != null) {
        let decimaliPunteggio = punteggioTotale - Math.floor(punteggioTotale);
        if (decimaliPunteggio !== 0.5) punteggioTotale = Math.round(punteggioTotale);
    }

    return punteggioTotale;
}
