import React from "react";
import DatiGenerali from "./datiGenerali/DatiGenerali";
import MedicoDiBase from "./medicoDiBase/MedicoDiBase";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import SettingProvenienza from "./settingProvenienza/SettingProvenienza";
import Esenzioni from "./esenzioni/Esenzioni";
import PropTypes from "prop-types";
import Padre from "./padre/Padre";
import Madre from "./madre/Madre";
import Tutore from "./tutore/Tutore";
import * as _ from "lodash";
import AmministratoreDiSostegno from "./amministratoreDiSostegno/AmministratoreDiSostegno";
import Fiduciario from "./fiduciario/Fiduciario";
import ResidenzaEDomicilio from "./residenzaEDomicilio/ResidenzaEDomicilio";
import Utils from "../../utils/Utils";
import AccordionHelper from "../../service/AccordionHelper";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import InvaliditaCivile from "./invaliditaCivile/InvaliditaCivile";
import VersionedAccordion from "../VersionedAccordion";
import enumRichiesta from "../../enum/enumRichiesta.json";

const propTypes = {
    datiAssistitoA0: PropTypes.any,
    datiAnagrafePaziente: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiAnagrafePaziente: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    cancellaPersona: PropTypes.func,
    onChangeObbligatorieta: PropTypes.func,
    onValidaPagina: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultProps = {
    salvaBozza: () => {
    },
    onChangeDatiAnagrafePaziente: () => {
    },
    onClickAccordion: () => {
    },
    cancellaPersona: () => {
    },
    onChangeObbligatorieta: () => {
    },
    onValidaPagina: () => {
    }
}

export const isPazienteMinoreDi18Anni = (dataNascita) => {
    let eta = Utils.calcolaEta(dataNascita, "DD/MM/YYYY");
    return eta != null && eta < 18;
}

export default class AnagrafePaziente extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: {
            sottoSezioneDatiGenerali: 0,
            sottoSezioneMedico: 0,
            sottoSezioneContattiResidenzaDomicilio: 0,
            sottoSezionePadre: 0,
            sottoSezioneMadre: 0,
            sottoSezioneTutore: 0,
            sottoSezioneAmministratoreDiSostegno: 0
        },

        validitaSezione: {
            isDatiGeneraliValido: false,
            isMedicoDiBaseValido: false,
            isResidenzaDomicilioValida: false,
            isPadreValido: !isPazienteMinoreDi18Anni(this.props.datiAnagrafePaziente.datiGenerali.dataNascita) || false,
            isMadreValido: !isPazienteMinoreDi18Anni(this.props.datiAnagrafePaziente.datiGenerali.dataNascita) || false,
            isTutoreValido: false,
            isAmministratoreDiSostegnoValido: false,
            isFiduciarioValido: false
        },

        accordion: {
            openAccordionDatiGenerali: true,
            openAccordionMedico: true,
            openAccordionResidenza: true,
            openAccordionSettingProvenienza: true,
            openAccordionEsenzioni: true,
            openAccordionInvaliditaCivile: true,
            openAccordionPadre: true,
            openAccordionMadre: true,
            openAccordionTutore: true,
            openAccordionAmministratoreSostegno: true,
            openAccordionFiduciario: true
        },
        tutore: null,
        amministratoreDiSostegno: null
    };


    componentDidMount() {
        this.setState({tutore: this.props.datiAnagrafePaziente.tutore,
            amministratoreDiSostegno: this.props.datiAnagrafePaziente.amministratoreDiSostegno})
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeDatiResidenzaDomicilio = (datiResidenza, datiDomicilio, datiDomicilioDiCura, datiAulssAssistenza) => {
        let datiAssistito = _.cloneDeep(this.props.datiAnagrafePaziente);
        datiAssistito.residenza = _.mapValues(datiResidenza, v => !Utils.isStringEmptyOrNullOrUndefined(v) ? v : null);
        datiAssistito.domicilio = _.mapValues(datiDomicilio, v => !Utils.isStringEmptyOrNullOrUndefined(v) ? v : null);
        datiAssistito.domicilioDiCura = _.mapValues(datiDomicilioDiCura, v => !Utils.isStringEmptyOrNullOrUndefined(v) ? v : null);
        this.props.richiesta.aulss = datiAulssAssistenza.aulss ?? null;
        this.props.richiesta.distretto = datiAulssAssistenza.distretto ?? null;
        this.props.richiesta.provincia = datiAulssAssistenza.provincia ?? null;
        this.props.onChangeDatiAnagrafePaziente("residenza,domicilio,domicilioDiCura", datiAssistito);
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        let campiObbligatoriMancanti = this.state.campiObbligatoriMancanti;
        campiObbligatoriMancanti[field] = value;
        this.setState({campiObbligatoriMancanti: campiObbligatoriMancanti}, () => {
            this.props.onChangeObbligatorieta("anagrafePaziente", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    validaSezione = (field, flag) => {
        let validitaSezione = this.state.validitaSezione;
        validitaSezione[field] = flag;

        this.setState({validitaSezione}, () => {
            this.props.onValidaPagina("anagrafePaziente", this.state.validitaSezione);
        });
    }

    contrlloHiddenAccordionAmm = () => {
        return !this.state.tutore?.length
    }
    contrlloHiddenAccordionTutore = () => {
       return !this.state.amministratoreDiSostegno?.length
    }

    renderBodyAnagrafePaziente = () => {
        return (
            <>
                <VersionedAccordion accordion={DatiGenerali} 
                                    datiGeneraliForm={this.props.datiAnagrafePaziente.datiGenerali}
                                    datiA0={this.props.datiAssistitoA0.datiGenerali}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    onValidaSezione={this.validaSezione}
                                    onChangeDatiAssistito={this.props.onChangeDatiAnagrafePaziente}
                                    openAccordion={this.state.accordion.openAccordionDatiGenerali}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionDatiGenerali"}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>

                <VersionedAccordion accordion={MedicoDiBase} 
                                    datiA0={this.props.datiAssistitoA0.mmgPlsDelPaziente}
                                    datiMedicoForm={this.props.datiAnagrafePaziente.mmgPlsDelPaziente}
                                    onChangeDatiAssistito={this.props.onChangeDatiAnagrafePaziente}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    onValidaSezione={this.validaSezione}
                                    openAccordion={this.state.accordion.openAccordionMedico}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionMedico"}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>

                <VersionedAccordion accordion={ResidenzaEDomicilio}
                                    datiResidenzaForm={this.props.datiAnagrafePaziente.residenza}
                                    datiDomicilioForm={this.props.datiAnagrafePaziente.domicilio}
                                    datiDomicilioDiCuraForm={this.props.datiAnagrafePaziente.domicilioDiCura}
                                    aulssAssistenza={this.props.datiAssistitoA0.aulssAssistenza}
                                    distrettoAssistenza={this.props.datiAssistitoA0.distrettoAssistenza}
                                    provinciaAssistenza={this.props.datiAssistitoA0.provinciaAssistenza}
                                    datiGeneraliA0={this.props.datiAssistitoA0.datiGenerali}
                                    residenzaA0={this.props.datiAssistitoA0.residenza}
                                    domicilioA0={this.props.datiAssistitoA0.domicilio}
                                    domicilioDiCuraA0={this.props.datiAssistitoA0.domicilioDiCura}
                                    onChangeDatiResidenzaDomicilio={this.handleChangeDatiResidenzaDomicilio}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    onValidaSezione={this.validaSezione}
                                    openAccordion={this.state.accordion.openAccordionResidenza}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionResidenza"}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>

                <VersionedAccordion accordion={SettingProvenienza}
                                    datiSettingProvenienza={this.props.datiAnagrafePaziente.settingDiProvenienza}
                                    onChangeDatiAssistito={this.props.onChangeDatiAnagrafePaziente}
                                    openAccordion={this.state.accordion.openAccordionSettingProvenienza}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionSettingProvenienza"}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>

                <VersionedAccordion accordion={Esenzioni}
                                    mascheraModificabilita={this.props.datiAssistitoA0.datiGenerali?.mascheraModificabilita}
                                    esenzioniA0={this.props.datiAssistitoA0.esenzioni}
                                    datiEsenzioniForm={this.props.datiAnagrafePaziente.esenzioni}
                                    onChangeDatiAssistito={this.props.onChangeDatiAnagrafePaziente}
                                    openAccordion={this.state.accordion.openAccordionEsenzioni}
                                    mascheraModificabilitaRichiesta={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionEsenzioni"}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>


                {Utils.isStateValutazione(this.props.pageState) &&
                    <VersionedAccordion accordion={InvaliditaCivile}
                                        datiInvaliditaCivileForm={this.props.datiAnagrafePaziente.invalidita}
                                        datiEsenzioniForm={this.props.datiAnagrafePaziente.esenzioni}
                                        onChangeDatiAssistito={this.props.onChangeDatiAnagrafePaziente}
                                        openAccordion={this.state.accordion.openAccordionInvaliditaCivile}
                                        onClickAccordion={this.onClickAccordion}
                                        field={"openAccordionInvaliditaCivile"}
                                        pageState={this.props.pageState}
                                        accordionReadOnly={this.props.accordionReadOnly}
                                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>}

                {isPazienteMinoreDi18Anni(this.props.datiAnagrafePaziente.datiGenerali.dataNascita)
                    ? <VersionedAccordion accordion={Padre} datiPadreForm={this.props.datiAnagrafePaziente.padre}
                                          onChangeDatiPadre={this.props.onChangeDatiAnagrafePaziente}
                                          onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                          onValidaSezione={this.validaSezione}
                                          openAccordion={this.state.accordion.openAccordionPadre}
                                          onClickAccordion={this.onClickAccordion}
                                          field={"openAccordionPadre"}
                                          mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                                          pageState={this.props.pageState}
                                          accordionReadOnly={this.props.accordionReadOnly}
                                          sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    : null}

                {isPazienteMinoreDi18Anni(this.props.datiAnagrafePaziente.datiGenerali.dataNascita)
                    ? <VersionedAccordion accordion={Madre} datiMadreForm={this.props.datiAnagrafePaziente.madre}
                                          onChangeDatiMadre={this.props.onChangeDatiAnagrafePaziente}
                                          onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                          onValidaSezione={this.validaSezione}
                                          openAccordion={this.state.accordion.openAccordionMadre}
                                          onClickAccordion={this.onClickAccordion}
                                          field={"openAccordionMadre"}
                                          mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                                          pageState={this.props.pageState}
                                          accordionReadOnly={this.props.accordionReadOnly}
                                          sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    : null}

                {this.contrlloHiddenAccordionTutore(this.state || this.props.datiAnagrafePaziente) ?
                    < VersionedAccordion accordion={Tutore} tutoriForm={this.props.datiAnagrafePaziente.tutore}
                                         datiAmministratoreDiSostegno={this.state.amministratoreDiSostegno || this.props.datiAnagrafePaziente.amministratoreDiSostegno}
                                         onChangeDatiTutore={(field, value) => {
                                             this.setState({tutore: value});
                                             this.props.onChangeDatiAnagrafePaziente(field, value)
                                         }}
                                         onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                         onValidaSezione={this.validaSezione}
                                         openAccordion={this.state.accordion.openAccordionTutore}
                                         onClickAccordion={this.onClickAccordion}
                                         field={"openAccordionTutore"}
                                         pageState={this.props.pageState}
                                         deleteTutore={this.props.cancellaPersona}
                                         accordionReadOnly={this.props.accordionReadOnly}
                                         sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    : null}
                {this.contrlloHiddenAccordionAmm(this.state || this.props.datiAnagrafePaziente) ?
                    <VersionedAccordion accordion={AmministratoreDiSostegno}
                                        amministratoriDiSostegnoForm={this.props.datiAnagrafePaziente.amministratoreDiSostegno}
                                        datiTutoriForm={this.state.tutore || this.props.datiAnagrafePaziente.tutore}
                                        onChangeDatiAmministratoreDiSostegno={(field, value) => {
                                            this.setState({amministratoreDiSostegno: value});
                                            this.props.onChangeDatiAnagrafePaziente(field, value)
                                        }}
                                        onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                        onValidaSezione={this.validaSezione}
                                        openAccordion={this.state.accordion.openAccordionAmministratoreSostegno}
                                        onClickAccordion={this.onClickAccordion}
                                        field={"openAccordionAmministratoreSostegno"}
                                        pageState={this.props.pageState}
                                        deleteAmministratoreDiSostegno={this.props.cancellaPersona}
                                        accordionReadOnly={this.props.accordionReadOnly}
                                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
                    : null}
                <VersionedAccordion accordion={Fiduciario} fiduciarioForm={this.props.datiAnagrafePaziente.fiduciario}
                                    onChangeDatiFiduciari={this.props.onChangeDatiAnagrafePaziente}
                                    onValidaSezione={this.validaSezione}
                                    openAccordion={this.state.accordion.openAccordionFiduciario}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionFiduciario"}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>
            </>
        );
    }


    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"anagrafePaziente"}
                title={"Anagrafe paziente"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}>
                {this.renderBodyAnagrafePaziente()}
            </AccordionAvvioRichiesta>
        );
    }
}

AnagrafePaziente.propTypes = propTypes;
AnagrafePaziente.defaultProps = defaultProps;
