import React, { Component, Fragment } from "react";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import PropTypes from "prop-types";
import Table from "../../generalComponents/table/Table";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import enumFirma from "../../../enum/enumFirma.json";
import styles from "./TabellaRichiesteDaFirmare.module.css";

const propTypes = {
    sintesiRichieste: PropTypes.object,
    aggiornaRichiesteDaFirmare: PropTypes.func,
    aggiornaRighe: PropTypes.func
}

export default class TabellaRichiesteDaFirmare extends Component {

    COLS_RICHIESTE_DA_FIRMARE = [
        {key: "nomeCognomeAssistito", name: "Assistito", sortDirection: null},
        {key: "codiceFiscaleAssistito", name: "Codice Fiscale", sortDirection: null},
        {key: "dataRichiesta", name: "Data richiesta", sortDirection: null},
        {key: "priorita", name: "Priorità", sortDirection: null},
        {key: "medicoRichiedente", name: "Medico richiedente", sortDirection: null},
        {key: "dataFormalizzazione", name: "Data Formalizzazione", sortDirection: null},
        {key: "formalizzazioneCompilataDa", name: "Formalizzata da", sortDirection: null},
        {key: "esitoFormalizzazione", name: "Esito", sortDirection: null},
        {key: "firma", name: "Firma", sortDirection: null},
        {key: "selezionePerFirma", name: "", sortDirection: null, tipo: "checkbox", classContainer: "text-center pt-2"},
    ];
    
    keyColsAlignStart = this.COLS_RICHIESTE_DA_FIRMARE.filter(a => a.key && a.key !="selezionePerFirma").map(b => b.key)
    

    state = {
        richiesta: null,
        cols: this.COLS_RICHIESTE_DA_FIRMARE,
        rows: [],
        livelloPriorita: null,
        richiesteSelezionate: []
    }

    componentDidMount() {
        this.inizializzaTabella()
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.sintesiRichieste, this.props.sintesiRichieste)) {
            this.inizializzaTabella();
        }
    }

    inizializzaTabella() {
        if (this.props.sintesiRichieste) {
            this.creaRigheTabellaFirme(this.props.sintesiRichieste?.elementi)
        }
    }

    selezionaTuttePerFirma  = () => {
        const { richiesteSelezionate, cols, rows} = this.state;
        let rowsState = _.cloneDeep(rows);
        let colsState = _.cloneDeep(cols);
        let selezionaTuttoIsCheck = rowsState?.length === richiesteSelezionate?.length;

        this.setState({ 
            richiesteSelezionate: !selezionaTuttoIsCheck ? rowsState.map(row => row.id) : [],
            rows: rowsState.map(row => {
                return {...row, checkBox:{...row.checkBox, checkValue: !selezionaTuttoIsCheck}}
            }),
            cols: colsState.map(a => {
                let title = selezionaTuttoIsCheck ? "Seleziona tutte" : "Deseleziona tutte"
                if (a.key == "selezionePerFirma") return {...a, name: (<span onClick={() => this.selezionaTuttePerFirma()} className={styles.linkSelezionaTutte}>{title}</span>)}
                else return a;
            })
        }, () => { this.props.aggiornaRichiesteDaFirmare(this.state.richiesteSelezionate); });
       
    }

    aggiornaCheck = (row, checked) => {
        let richiesteSelezionate = _.cloneDeep(this.state.richiesteSelezionate);
        const { cols, rows} = this.state;
        let rowsState = _.cloneDeep(rows);
        let colsState = _.cloneDeep(cols);
 
        if (checked) {
            richiesteSelezionate.push(row.id);
        } else {
            _.pull(richiesteSelezionate, row.id);
        }

        let selezionaTuttoIsCheck = rowsState?.length === richiesteSelezionate?.length;
        this.setState({ 
            richiesteSelezionate: richiesteSelezionate,
            rows: rowsState.map(r => {
                if(r.id === row.id) return row
                else return r
            }),
            cols: colsState.map(a => {
                let title = !selezionaTuttoIsCheck ? "Seleziona tutte" : "Deseleziona tutte"
                if (a.key == "selezionePerFirma") return {...a, name: (<span onClick={() => this.selezionaTuttePerFirma()} className={styles.linkSelezionaTutte}>{title}</span>)}
                else return a;
            })
        }, () => { this.props.aggiornaRichiesteDaFirmare(richiesteSelezionate); });
    }

    creaRigheTabellaFirme = (listaRichieste) => {
        let righe = [];
        const { richiesteSelezionate, cols, rows} = this.state;
        let colsState = _.cloneDeep(cols);
        if (this.state.rows.length && this.state.rows.length === listaRichieste?.length) righe = this.state.rows;
        
        _.forEach(listaRichieste, richiesta => {
            if(!rows.find(row => row.id === richiesta.id)) {
                let riga = {};
                let priorita = _.find(OggettiUtili.livelloPrioritaRichiesta, (elem) => { return elem.value === richiesta?.livelloPriorita });
                let statoFirma = enumFirma.label[richiesta?.firma?.stato];
                riga.id = richiesta?.id;
                riga.nomeCognomeAssistito = _.join([richiesta?.assistito?.nome, richiesta?.assistito?.cognome], " ");
                riga.codiceFiscaleAssistito = richiesta?.assistito?.identificativoUtente;
                riga.dataRichiesta = Utils.transformDateAndTimeForFrontend(richiesta?.timestampCreazione);
                riga.priorita = priorita?.label;
                riga.medicoRichiedente = _.join([richiesta?.richiedente.nome, richiesta?.richiedente.cognome], " ");
                riga.dataValutazioneMultidimensionale = Utils.transformDateAndTimeForFrontend(richiesta?.timestampValutazione);
                riga.dataFormalizzazione = Utils.transformDateAndTimeForFrontend(richiesta?.timestampFormalizzazione);
                riga.formalizzazioneCompilataDa = _.join([richiesta?.formalizzazione?.nomeFormalizzatore, richiesta?.formalizzazione?.cognomeFormalizzatore], " ");
                riga.esitoFormalizzazione = richiesta?.formalizzazione?.esitoValutazione;
                riga.firma = statoFirma;
                riga.checkBox = {hangleChange: (row, value) => this.aggiornaCheck(row, value), checkValue: false,  disabled:richiesta?.firma?.stato == enumFirma.values.IN_ELABORAZIONE}
                righe.push(riga);
            }
        })

        let selezionaTuttoIsCheck = righe?.length === richiesteSelezionate?.length;

        this.setState({
            rows: righe,
            cols: colsState.map(a => {
                let title = !selezionaTuttoIsCheck ? "Seleziona tutte" : "Deseleziona tutte"
                if (a.key == "selezionePerFirma" && righe.length) return {...a, name: (<span onClick={() => this.selezionaTuttePerFirma()} className={styles.linkSelezionaTutte}>{title}</span>)}
                else return a;
            })
        });
        this.props.aggiornaRighe(righe);
    }

    render() {
        return (
            <Fragment>
                <div className={"mb-5"}>
                    <div>
                        <Table
                            cols={this.state.cols}
                            rows={this.state.rows}
                            emptyMsg={"Non sono disponibili richieste da firmare"}
                            lengthEditable={false}
                            totalElements={this.state.totalElements}
                            bigTable={true}
                            homePage={false}
                            tableLenght={this.state.rows?.length}
                            nascondiPaginazione={true}
                            changePage={this.props.changePage}
                            internalScroll={false}
                            //tableHeightForInternalScroll={'46vh'}
                            keyColsAlignStart={this.keyColsAlignStart}
                            classTable={"firme-table"}
                        />
                    </div>
                </div>
            </Fragment>
        )
    }

}

TabellaRichiesteDaFirmare.propTypes = propTypes;
