import React, {useState} from "react";
import PropTypes from "prop-types";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import enumQuestionario from "../../../enum/enumQuestionario"
import styles from "./ScalaEQuestionari.module.css";

const altriQuestionari = [
    {
        tipoQuestionario: enumQuestionario.tipoQuestionario.NECPAL,
        label: "NECPAL CCOMS-ICO",
        pathRichiestaQuestionario: "valutazioneBisogniPalliativi.NECPAL"
    },
    {
        tipoQuestionario: enumQuestionario.tipoQuestionario.MMAS4,
        label: "Morisky Medication Adherence Scale",
        pathRichiestaQuestionario: "informazioniDiBase.datiAderenzaAllaTerapia.MMAS4"
    }
]

function AltriQuestionari ({openQuestionario, onClickAccordion, sottoTitolo, dataCompletamento, setQuestionario, verificaQuestionarioCompilado}) {

    const questionTotalCompiled = altriQuestionari.filter(a => verificaQuestionarioCompilado(a)).length;

    return (
        <>
            <AccordionSezioneRichiesta
                idAccordion={"altriQuestionai"}
                title={"Altri questionari"}
                required={false}
                openAccordion={openQuestionario}
                onClickAccordion={onClickAccordion}
                field={"openAccordionQuestionariSvama"}
            >
                {sottoTitolo}
                {questionTotalCompiled && dataCompletamento}
                {questionTotalCompiled ? altriQuestionari.map(questionario => {
                    if (verificaQuestionarioCompilado(questionario))
                        return <div onClick={() => setQuestionario(questionario)} className={styles.linkQuestionario.concat(" mb-3")}>{questionario.label}</div>
                    else return;
                }):<div>Non ci sono questionari compilati</div>}
            </AccordionSezioneRichiesta>
        </>

    )
}

AltriQuestionari.propTypes = {
    openQuestionario: PropTypes.bool,
    onClickAccordion: PropTypes.func,
    sottoTitolo: PropTypes.node,
    dataCompletamento: PropTypes.node,
    setQuestionario: PropTypes.func,
    verificaQuestionarioCompilado: PropTypes.func 
};

AltriQuestionari.defaultProps = {};

export default AltriQuestionari;
