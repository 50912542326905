import React, {Component, Fragment} from "react";
import {Button} from "web-client-archetype";
import PropTypes from "prop-types";
import RichiestaADIService from "../../service/RichiestaADIService";
import Utils, {closeModaleStyle} from "../../utils/Utils";
import * as _ from "lodash";
import AccordionHelper from "../../service/AccordionHelper";
import SessionRequest from "../../utils/SessionRequest";
import {buildRichiesta, disableButton} from "../compilaRichiesta/CompilaRichiesta";
import BisogniPrestazioniAssistenziali from "./bisogniPrestazioniAssistenziali/BisogniPrestazioniAssistenziali";
import ObiettiviClinicoAssistenziali from "./obiettiviClinicoAssistenziali/ObiettiviClinicoAssistenziali";
import FigureProfessionaliPresaInCarico from "./figureProfessionaliPresaInCarico/FigureProfessionaliPresaInCarico";
import ProposteAttivitaSuggerimenti from "./proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimenti";
import FigureProfessionaliSupportoAValutazione
    from "./FigureProfessionaliSupportoAValutazione/FigureProfessionaliSupportoAValutazione";
import routepath from "../../utils/route/route-path.json";
import {Redirect} from "react-router-dom";
import {map} from "rxjs/operators";
import * as $ from "jquery";
import ModalHelper from "../modale/ModalHelper";
import styles from "./CompilaProposte.module.css";
import WebSocketHelper from "../../websocket/WebSocketHelper";
import {RoleBasedContext} from "../../utils/RoleBasedContext";
import ButtonHelper from "../../service/ButtonHelper";
import LivelloPrioritaRichiesta from "./livelloPriorita/LivelloPrioritaRichiesta";
import InserimentoRichiesta from "../inserimentoRichiesta/InserimentoRichiesta";
import enumUtente from "../../enum/enumsUtente.json";
import VersionedAccordion from "../VersionedAccordion";
import ScrollTop from "../scrollTop/ScrollTop";
import enumRichiesta from "../../enum/enumRichiesta";
import ValutazioneProfessionista from "./valutazioneProfessionista/ValutazioneProfessionista";
import {rolesProfessionista} from "../roleBasedComponents/RoleBasedComponents";
import DataDimissioneProgrammataDaRicovero
    from "./dataDimissioneProgrammataDaRicovero/DataDimissioneProgrammataDaRicovero";
import DataDiPresaInCaricoTerritorialeDaDimissioneProtetta
    from "./dataDiPresaInCaricoDaDimissioneProtetta/DataDiPresaInCaricoTerritorialeDaDimissioneProtetta";
import statiRichiesta from "../../utils/dataset/richiestaData/statiRichiesta.json";
import BisogniAllaDimissione from "./bisogniAllaDimissione/BisogniAllaDimissione";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import ValutazioneInFormalizzazione
    from "../compilaFormalizzazione/caratteristicheSettingRichiesto/ValutazioneInFormalizzazione";
import {getValutazionePrecedente} from "../home/HomeUtils";
import AuthUtils from "../../utils/AuthUtils";
import {v4 as uuid} from "uuid";
import ButtonsBoxStick from "../buttonsBoxStick/buttonsBoxStick";
import UserHelper from "../../service/userHelper";

const propTypes = {
    richiesta: PropTypes.any,
    richiestaMedico: PropTypes.any,
    persone: PropTypes.object,
    pageState: PropTypes.string,
    pageName: PropTypes.string,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    firstPageName: PropTypes.string,
    openAccordion: PropTypes.bool,
    showOnlyRequiredFields: PropTypes.bool,
    previousPageOnlyRequiredFields: PropTypes.bool,
    hideDefaultHeader: PropTypes.bool,
    listaCodiciFiscaliUtentiOnline: PropTypes.array
}

export default class CompilaProposte extends Component {
    state = {
        redirectToIndietro: false,
        redirectToVisualizzaRiepilogo: false,
        salvaBozzaSubscription: null,
        accordion: {
            openAccordionBisogniPrestazioniAssistenziali: false,
            openAccordionObiettiviClinicoAssistenziali: false,
            openAccordionFigureProfessionaliPresaInCarico: false,
            openAccordionValutazioneProfessionista: false,
            openAccordionProposteAttivitaSuggerimenti: false,
            openAccordionFigureProfessionaliSupportoAValutazione: false,
            openAccordionLivelloPriorita: false,
            openAccordionDataDimissioneProgrammataDaRicovero: false,
            openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta: false,
            openAccordionBisogniAllaDimissione: false,
            openAccordionValutazione: false
        },
        apriModaleAlert: false
    }

    accordionData;
    accordionCompilaProposte;
    team;

    // oggetto utilizzato per controllare se abilitare o disabilitare il pulsante Prosegui
    obbligatorieta = {
        bisogniPrestazioniAssistenziali: null,
        figureProfessionaliPresaInCarico: null,
        proposteAttivitaSuggerimenti: null,
        figureProfessionaliSupportoAllaValutazione: null,
        dataDiPresaInCaricoTerritorialeDaDimissioneProtetta: null,
        livelloPriorita: null,
        dataDimissioneProgrammataDaRicovero: null,
        bisogniAllaDimissione: null
    };

    constructor(props) {
        super(props);
        this.accordionData = SessionRequest.getSessionObjectFor(props.richiesta);

        if (Utils.isStateRichiestaPreDialogo(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) {
            delete this.obbligatorieta.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta;
        }

        if (!Utils.isDimissioneProtetta(this.props.pageState)) {
            delete this.obbligatorieta.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta;
            delete this.obbligatorieta.dataDimissioneProgrammataDaRicovero;
            delete this.obbligatorieta.bisogniAllaDimissione;
        }
        
        this.updateFields();
        this.team = this.props.richiesta?.team ?? [];
    }

    componentDidMount() {
        this.setState({
            salvaBozzaSubscription: InserimentoRichiesta.salvaBozzaSubject.subscribe(() => this.prepareToSalvaBozza(this.props.richiesta))
        }, () => {
            InserimentoRichiesta.infoRichiestaSubject.next({
                idRichiesta: this.props.richiesta.id,
                anagrafePaziente: this.accordionData?.anagrafePaziente ?? null,
                situazioneSociale: this.accordionData?.situazioneSociale ?? null,
                richiedenteOrMcp: this.props.persone?.[Utils.recuperoIdProfessionista(this.props.richiesta)]
            });
        });

        const idAccordionToAddInformation = AccordionHelper.idAccordionToAddInformation;
        const idSelectedAccordions = AccordionHelper.idSelectedAccordions;
        const idAccordionsAfterSurveyPage = AccordionHelper.idAccordionsAfterSurveyPage;

        if (this.props.openAccordion) {
            let accordion = _.cloneDeep(this.state.accordion);
            for (const singoloAccordion in accordion) {
                accordion[singoloAccordion] = true;
            }
            this.setState({accordion: accordion});
        } else if (idAccordionToAddInformation != null && idSelectedAccordions == null && idAccordionsAfterSurveyPage == null) {
            const ref = $(document.getElementById(idAccordionToAddInformation));
            ref.find('> div.collapse-header > button').trigger('click');
            AccordionHelper.setCloneIdAccordionToAddInformation(null);
        } else if (idSelectedAccordions != null) {
            this.setState({accordion: AccordionHelper.initLastOpenedAccordionInAccordionObject(this.state.accordion, AccordionHelper.lastOpenedAccordion)}, () => WebSocketHelper.onMessageListener());
        } else if (idAccordionsAfterSurveyPage != null) {
            AccordionHelper.onScrollIntoAccordion(idAccordionsAfterSurveyPage, false, 1000);
        }
    }

    componentWillUnmount() {
        if (this.state.salvaBozzaSubscription) this.state.salvaBozzaSubscription.unsubscribe();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.richiesta, this.props.richiesta)) {
            this.updateFields();
        }


        $('div.box-accordion-body:empty').closest('div.box-accordion').remove();

        //Duplicato perchè prima vengono eliminati i sottoAccordion vuoti e poi i relativi Accordion vuoti
        $('div.collapse-body:empty').closest('div.collapse-div').remove();
        $('div.collapse-body:empty').closest('div.collapse-div').remove();


        $('div.align-items-top > div.col-3:empty').remove();
        $('div.align-items-top:empty').remove();
        $('div.collapse-body > div:empty').closest('div.collapse-div').remove();
        $('div.collapse-body > div.row > div.col:empty').closest('div.collapse-div').remove();
    }

    updateFields() {
        let richiesta = this.props.richiesta;

        if (richiesta) {
            this.accordionData.bisogniPrestazioniAssistenziali = this.initObjectsForm("bisogniPrestazioniAssistenziali", richiesta, this.accordionData.bisogniPrestazioniAssistenziali) ?? [];
            this.accordionData.obiettiviClinicoAssistenziali = this.initObjectsForm("obiettiviClinicoAssistenziali", richiesta, this.accordionData.obiettiviClinicoAssistenziali);
            this.accordionData.figureProfessionaliPresaInCarico = this.initObjectsForm("figureProfessionaliPresaInCarico", richiesta, this.accordionData.figureProfessionaliPresaInCarico) ?? [];
            this.accordionData.valutazioneProfessionista = this.initObjectsForm("valutazioneProfessionista", richiesta, this.accordionData.valutazioneProfessionista);
            this.accordionData.proposteAttivitaSuggerimenti = this.initObjectsForm("proposteAttivitaSuggerimenti", richiesta, this.accordionData.proposteAttivitaSuggerimenti) ?? [];
            this.accordionData.professionistiSupportoAValutazione = this.initObjectsForm("professionistiSupportoAValutazione", richiesta, this.accordionData.professionistiSupportoAValutazione) ?? [];
            this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale = this.initObjectsForm("proposteAttivitaSuggerimentiAssistenteSociale", richiesta, this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale);
            this.initObjectsForm(this.accordionData.proposteAttivitaSuggerimentiNote, "proposteAttivitaSuggerimentiNote", richiesta, this.accordionData.proposteAttivitaSuggerimentiNote);
            this.accordionData.dataDimissioneProgrammataDaRicovero = this.initObjectsForm("dataDimissioneProgrammataDaRicovero", richiesta, this.accordionData.dataDimissioneProgrammataDaRicovero);
            this.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta = this.initObjectsForm("dataDiPresaInCaricoTerritorialeDaDimissioneProtetta", richiesta, this.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta);
            this.accordionData.bisogniAllaDimissione = this.initObjectsForm("bisogniAllaDimissione", richiesta, this.accordionData.bisogniAllaDimissione);
            this.accordionData.anamnesiFisiologica = this.initObjectsForm("anamnesiFisiologica", richiesta, this.accordionData.anamnesiFisiologica);
        }

        this.accordionCompilaProposte = {
            bisogniPrestazioniAssistenziali: _.cloneDeep(this.accordionData.bisogniPrestazioniAssistenziali),
            obiettiviClinicoAssistenziali: _.cloneDeep(this.accordionData.obiettiviClinicoAssistenziali),
            figureProfessionaliPresaInCarico: _.cloneDeep(this.accordionData.figureProfessionaliPresaInCarico),
            valutazioneProfessionista: _.cloneDeep(this.accordionData.valutazioneProfessionista),
            proposteAttivitaSuggerimenti: _.cloneDeep(this.accordionData.proposteAttivitaSuggerimenti),
            professionistiSupportoAValutazione: _.cloneDeep(this.accordionData.professionistiSupportoAValutazione),
            livelloPriorita: _.cloneDeep(richiesta.livelloPriorita),
            livelloPrioritaBozza: _.cloneDeep(richiesta.livelloPrioritaBozza),
            proposteAttivitaSuggerimentiAssistenteSociale: _.cloneDeep(this.accordionData.proposteAttivitaSuggerimentiAssistenteSociale),
            proposteAttivitaSuggerimentiNote: _.cloneDeep(this.accordionData.proposteAttivitaSuggerimentiNote),
            dataDimissioneProgrammataDaRicovero: _.cloneDeep(this.accordionData.dataDimissioneProgrammataDaRicovero),
            dataDiPresaInCaricoTerritorialeDaDimissioneProtetta: _.cloneDeep(this.accordionData.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta),
            bisogniAllaDimissione: _.cloneDeep(this.accordionData.bisogniAllaDimissione)
        }
    }

    initObjectsForm = (property, richiesta, defaultObject) => {
        let output;

        if (Object.prototype.hasOwnProperty.call(richiesta, property)) {
            if (Array.isArray(richiesta[property])) {
                output = [];
                output.push(...richiesta[property]);
            } else if (Object.prototype.toString.call(defaultObject) === '[object Object]') {
                output = _.cloneDeep(defaultObject);
                Object.keys(defaultObject).forEach(key => {
                    if (richiesta[property] != null && key in richiesta[property]) {
                        output[key] = richiesta[property][key];
                    } else {
                        output[key] = defaultObject[key];
                    }
                });
            } else output = defaultObject;
        } else output = defaultObject;

        return output;
    }

    salvaBozza = (richiestaBody) => {
        return RichiestaADIService.salvaBozza(richiestaBody.id, richiestaBody, ButtonHelper.clickSalvaButton)
            .pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                }
                this.team = richiestaBody?.team ?? [];

                return richiestaBody;
            }));
    }

    salvaValutazioni = (richiestaBody) => {
        return RichiestaADIService.salvaVariazioniInBozza(richiestaBody.id, richiestaBody, ButtonHelper.clickSalvaButton)
            .pipe(map((response) => {
                if (response?.msg === "ERRORE") {
                    if (response?.data && (response?.data?.errore || response?.data?.error)) {
                        Utils.toasterFunction({
                            status: response?.data?.codice ? response?.data?.codice : response?.status,
                            type: "critical",
                            text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                        })
                    }
                }
                this.team = richiestaBody?.team ?? [];

                return richiestaBody;
            }));
    }


    prepareToSalvaBozza = (isProcediBtn = false, returnObservable = false, richiesta) => {
        if (this.props.readOnly) {
            if (isProcediBtn) {
                this.setState({redirectToVisualizzaRiepilogo: true});
            }
            return;
        }

        this.accordionData = Object.assign(this.accordionData, this.accordionCompilaProposte);
        let richiestaClone = {
            ..._.cloneDeep(richiesta),
            team: this.team,
        };
        buildRichiesta(richiestaClone, this, this.props.pageName);
        let salva = Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)
            ? this.salvaValutazioni(richiestaClone)
            : this.salvaBozza(richiestaClone);

        if (isProcediBtn) salva.subscribe(() => this.setState({redirectToVisualizzaRiepilogo: true}));
        else if (returnObservable) return salva;
        else salva.subscribe();
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (!this.props.readOnly) this.prepareToSalvaBozza(this.props.richiesta);
        if (accordion[field]) {
            Object.keys(accordion).forEach(elem => {
                if (elem !== field) {
                    accordion[elem] = false;
                }
            });
            AccordionHelper.setLastOpenedAccordion(field);
            this.setState({accordion});
        }
    }

    handleChangeDati = (field, value) => {
        const split = field.split('|');
        if (split.length === 2) {
            this.accordionCompilaProposte[split[0]] = value[0];
            if (split[1] && split[1].startsWith('path')) {
                const propostaOtherAccordionModified = this.accordionCompilaProposte.proposteAttivitaSuggerimenti
                    .filter(e => e.firstPageAccordionPath === value[1])?.map(p => p?.propostaAttivitaSuggerimenti) ?? [];
                _.set(this.accordionData, value[1], propostaOtherAccordionModified);
            }
        } else {
            this.accordionCompilaProposte[field] = value;
        }
        this.forceUpdate();
    }

    handleChangeObbligatorieta = (field, value) => {
        this.obbligatorieta[field] = value;
        let isButtonProseguiEnabled = this.obbligatorieta != null
            && Object.keys(this.obbligatorieta).every(key => this.obbligatorieta[key] !== null && this.obbligatorieta[key] === 0);
        disableButton("#proseguiBtn", !isButtonProseguiEnabled);
    }

    renderButtons() {
        return (
            <ButtonsBoxStick
                parentClass="d-flex justify-content-between"
                btnList={[
                    {
                        id: "indietroBtn",
                        text: "Indietro",
                        className: "btnWhiteOutlineBorderGreen",
                        onclickFunction: () => this.setState({redirectToIndietro: true}),
                        isvisible: true
                    },
                    {
                        id: "salvaBozzaBtn",
                        text: "Salva bozza",
                        className: "btnWhiteOutlineBorderGreen",
                        isvisible: !this.props.readOnly,
                        onclickFunction:() => {
                            ButtonHelper.setSalvaButtonFlag(true);
                            this.prepareToSalvaBozza(this.props.richiesta);
                        }
                    },
                    {
                        id: "proseguiBtn",
                        text: "Prosegui",
                        className: "btnGreenOutline",
                        onclickFunction: () => {
                            if (WebSocketHelper.valutazioneConfermataEvent !== null)
                                this.apriModaleAlert();
                            else
                                this.onClickProsegui();
                        },
                        isvisible: true
                    }
                ]}
            />
        );
    }

    onClickProsegui() {
        this.prepareToSalvaBozza(this.props.richiesta, true);
    }

    renderFormClassica = () => {
        let showValutazioneProfessionista = false;
        if (this.props.richiesta?.valutazioni) {
            this.props.richiesta.valutazioni.forEach(valutazione => {
                if (rolesProfessionista.has(valutazione.valutatore?.ruolo))
                    showValutazioneProfessionista = true
            })
        }

        return (
            <>
                {this.state.apriModaleAlert && this.renderModaleAlert()}

                {showValutazioneProfessionista &&
                    <VersionedAccordion
                        accordion={ValutazioneProfessionista}
                        dati={this.accordionCompilaProposte.valutazioneProfessionista}
                        richiesta={this.props.richiesta}
                        salvaBozza={this.prepareToSalvaBozza}
                        onChangeDatiValutazioneProfessionista={this.handleChangeDati}
                        openAccordion={this.state.accordion.openAccordionValutazioneProfessionista}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionValutazioneProfessionista"}
                        pageState={this.props.pageState}
                        onChangeObbligatorieta={this.handleChangeObbligatorieta}/>}

                <VersionedAccordion
                    accordion={BisogniPrestazioniAssistenziali}
                    dati={this.accordionCompilaProposte.bisogniPrestazioniAssistenziali ?? []}
                    onChangeBisogniPrestazioniAssistenziali={this.handleChangeDati}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    openAccordion={this.state.accordion.openAccordionBisogniPrestazioniAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniPrestazioniAssistenziali"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={ObiettiviClinicoAssistenziali}
                    dati={this.accordionCompilaProposte.obiettiviClinicoAssistenziali}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiObiettiviClinicoAssistenziali={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionObiettiviClinicoAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionObiettiviClinicoAssistenziali"}
                    pageState={this.props.pageState}
                    disableDoubleInput={Utils.isRuoloInfermiere()}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliPresaInCarico}
                    dati={this.accordionCompilaProposte.figureProfessionaliPresaInCarico}
                    datiNecessitaAssistenzaSanitaria={this.accordionData.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiFigureProfessionaliPresaInCarico={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliPresaInCarico}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliPresaInCarico"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    firstPageName={this.props.firstPageName}
                    previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields}/>

                <VersionedAccordion
                    accordion={LivelloPrioritaRichiesta}
                    professionistiSupportoAValutazione={this.accordionCompilaProposte.professionistiSupportoAValutazione}
                    dati={this.accordionCompilaProposte.livelloPriorita}
                    figureProfessionali={this.accordionCompilaProposte.figureProfessionaliPresaInCarico}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    openAccordion={this.state.accordion.openAccordionLivelloPriorita}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionLivelloPriorita"}
                    onChangeLivelloPriorita={this.handleChangeDati}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion
                    accordion={ProposteAttivitaSuggerimenti}
                    datiProposteAttivitaSuggerimenti={this.accordionCompilaProposte.proposteAttivitaSuggerimenti}
                    datiproposteAttivitaSuggerimentiAssistenteSociale={this.accordionCompilaProposte.proposteAttivitaSuggerimentiAssistenteSociale}
                    datiProposteAttivitaSuggerimentiNote={this.accordionCompilaProposte.proposteAttivitaSuggerimentiNote}
                    datiSituazioneSociale={this.accordionData.situazioneSociale}
                    datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                    datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                    datiInformazioniBase={this.accordionData.informazioniDiBase}
                    datiInformazioniBaseFaseRichiesta={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]}
                    datiInformazioniBaseFaseValutazione={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                    datiBenessereAbitativo={this.accordionData.benessereAbitativo}
                    datiEsameObiettivo={this.accordionData.esameObiettivo}
                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiProposteAttivitaSuggerimenti={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionProposteAttivitaSuggerimenti}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionProposteAttivitaSuggerimenti"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliSupportoAValutazione}
                    dati={this.accordionCompilaProposte.professionistiSupportoAValutazione}
                    datiNecessitaAssistenzaSanitaria={this.accordionData.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiFigureProfessionaliSupportoAValutazione={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliSupportoAValutazione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliSupportoAValutazione"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    firstPageName={this.props.firstPageName}
                    previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields}/>
            </>
        );
    }

    renderFormDimissioneProtetta = () => {
        return (
            <>
                {this.state.apriModaleAlert && this.renderModaleAlert()}

                <VersionedAccordion
                    accordion={LivelloPrioritaRichiesta}
                    professionistiSupportoAValutazione={this.accordionCompilaProposte.professionistiSupportoAValutazione}
                    dati={this.props.richiesta.stato === statiRichiesta.confermataPreDialogo.value || this.props.richiesta.stato === statiRichiesta.inChiusuraPreDialogo.value
                        ? this.accordionCompilaProposte.livelloPrioritaBozza
                        : this.accordionCompilaProposte.livelloPriorita}
                    figureProfessionali={this.accordionCompilaProposte.figureProfessionaliPresaInCarico}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    openAccordion={this.state.accordion.openAccordionLivelloPriorita}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionLivelloPriorita"}
                    onChangeLivelloPriorita={this.handleChangeDati}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                />

                <VersionedAccordion
                    accordion={DataDimissioneProgrammataDaRicovero}
                    dati={this.accordionCompilaProposte.dataDimissioneProgrammataDaRicovero}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiDataDimissioneProgrammataDaRicovero={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionDataDimissioneProgrammataDaRicovero}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionDataDimissioneProgrammataDaRicovero"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                {!Utils.isStateRichiestaPreDialogo(this.props.pageState)
                    && !Utils.isStateValutazionePreDialogo(this.props.pageState) &&
                    <VersionedAccordion
                        accordion={DataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                        dati={this.accordionCompilaProposte.dataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                        richiesta={this.props.richiesta}
                        salvaBozza={this.prepareToSalvaBozza}
                        onChangeDatiDataDiPresaInCaricoTerritorialeDaDimissioneProtetta={this.handleChangeDati}
                        openAccordion={this.state.accordion.openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionDataDiPresaInCaricoTerritorialeDaDimissioneProtetta"}
                        pageState={this.props.pageState}
                        onChangeObbligatorieta={this.handleChangeObbligatorieta}/>}

                <VersionedAccordion
                    accordion={BisogniPrestazioniAssistenziali}
                    dati={this.accordionCompilaProposte.bisogniPrestazioniAssistenziali ?? []}
                    onChangeBisogniPrestazioniAssistenziali={this.handleChangeDati}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    openAccordion={this.state.accordion.openAccordionBisogniPrestazioniAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniPrestazioniAssistenziali"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={BisogniAllaDimissione}
                    dati={this.accordionCompilaProposte.bisogniAllaDimissione}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiBisogniAllaDimissione={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionBisogniAllaDimissione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionBisogniAllaDimissione"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={ObiettiviClinicoAssistenziali}
                    dati={this.accordionCompilaProposte.obiettiviClinicoAssistenziali}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiObiettiviClinicoAssistenziali={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionObiettiviClinicoAssistenziali}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionObiettiviClinicoAssistenziali"}
                    pageState={this.props.pageState}
                    disableDoubleInput={Utils.isRuoloInfermiere()}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliPresaInCarico}
                    dati={this.accordionCompilaProposte.figureProfessionaliPresaInCarico}
                    datiNecessitaAssistenzaSanitaria={this.accordionData.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiFigureProfessionaliPresaInCarico={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliPresaInCarico}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliPresaInCarico"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    firstPageName={this.props.firstPageName}
                    previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields}/>

                <VersionedAccordion
                    accordion={ProposteAttivitaSuggerimenti}
                    datiProposteAttivitaSuggerimenti={this.accordionCompilaProposte.proposteAttivitaSuggerimenti}
                    datiproposteAttivitaSuggerimentiAssistenteSociale={this.accordionCompilaProposte.proposteAttivitaSuggerimentiAssistenteSociale}
                    datiProposteAttivitaSuggerimentiNote={this.accordionCompilaProposte.proposteAttivitaSuggerimentiNote}
                    datiSituazioneSociale={this.accordionData.situazioneSociale}
                    datiDeterminantiDiSalute={this.accordionData.determinantiDiSalute}
                    datiAnamnesiFisiologica={this.accordionData.anamnesiFisiologica}
                    datiInformazioniBase={this.accordionData.informazioniDiBase}
                    datiInformazioniBaseFaseRichiesta={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseRichiesta]}
                    datiInformazioniBaseFaseValutazione={this.accordionData[enumRichiesta.attributi.informazioniBaseFaseValutazione]}
                    datiBenessereAbitativo={this.accordionData.benessereAbitativo}
                    datiEsameObiettivo={this.accordionData.esameObiettivo}
                    datiAnamnesiPatologicaRemota={this.accordionData.anamnesiPatologicaRemota}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiProposteAttivitaSuggerimenti={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionProposteAttivitaSuggerimenti}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionProposteAttivitaSuggerimenti"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}/>

                <VersionedAccordion
                    accordion={FigureProfessionaliSupportoAValutazione}
                    dati={this.accordionCompilaProposte.professionistiSupportoAValutazione}
                    datiNecessitaAssistenzaSanitaria={this.accordionData.anamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.prepareToSalvaBozza}
                    onChangeDatiFigureProfessionaliSupportoAValutazione={this.handleChangeDati}
                    openAccordion={this.state.accordion.openAccordionFigureProfessionaliSupportoAValutazione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionFigureProfessionaliSupportoAValutazione"}
                    pageState={this.props.pageState}
                    onChangeObbligatorieta={this.handleChangeObbligatorieta}
                    firstPageName={this.props.firstPageName}
                    previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields}/>

                {!Utils.isStateRichiestaPreDialogo(this.props.pageState)
                    && !Utils.isStateValutazionePreDialogo(this.props.pageState)
                    && <AccordionAvvioRichiesta
                        idAccordion={"valutazioneChiusuraPreDialogo"}
                        title={"Valutazione"}
                        openAccordion={this.state.accordion.openAccordionValutazione}
                        onClickAccordion={this.onClickAccordion}
                        field={"openAccordionValutazione"}
                        pageState={this.props.pageState}>
                        <VersionedAccordion
                            accordion={ValutazioneInFormalizzazione}
                            tipoValutazione={this.props.richiesta?.formalizzazione?.tipoValutazione}
                            dataValutazione={!Utils.isStringEmptyOrNullOrUndefined(this.props.richiesta?.formalizzazione?.dataValutazione)
                                ? this.props.richiesta.formalizzazione.dataValutazione.match(/\d{4}-\d{2}-\d{2}/g)
                                    ? Utils.formatDate(this.props.richiesta?.formalizzazione.dataValutazione, "YYYY-MM-DD", "DD/MM/YYYY")
                                    : this.props.richiesta?.formalizzazione.dataValutazione
                                : ''}
                            openAccordion={this.state.accordion.openAccordionValutazione}
                            onClickAccordion={this.onClickAccordion}
                            field={"openAccordionValutazione"}
                            pageState={this.props.pageState}/>
                    </AccordionAvvioRichiesta>}
            </>
        );
    }

    // Modale di alert al click del button "Prosegui"
    apriModaleAlert = () => {
        let showModale = WebSocketHelper.valutazioneConfermataEvent.idRichiesta === this.props.richiesta.id;
        this.setState({apriModaleAlert: showModale});
    }

    chiudiModaleAlert = () => {
        this.setState({apriModaleAlert: false, redirectToIndietro: false});
    }

    renderRowsSezioni = (listaSezioniModificate) => {
        let righeJSX = [];
        let arraySezioniModificate = listaSezioniModificate != null
            ? Array.from(listaSezioniModificate.split(";"))
            : ["nessuna sezione modificata"];

        let rigaJSX = <Fragment>
            {arraySezioniModificate?.map((nomeSezione, index) => {
                return (<tr key={index} className={index % 2 === 0 ? styles.rowTableGray : styles.rowTableWhite}>
                    <td>
                        <div className={'row ml-1 mb-1 mt-1 text-justify'}>
                            <div className={'col- span mb-1 mt-1 '}>
                                {nomeSezione}
                            </div>
                        </div>
                    </td>
                </tr>);
            })}

        </Fragment>
        righeJSX.push(rigaJSX);

        return (<Fragment>{righeJSX}</Fragment>);
    }

    renderModaleAlert = () => {
        let nominativoUtente = WebSocketHelper.valutazioneConfermataEvent?.nominativoUtente
        let isInfermiere = Utils.isRuoloInfermiere();

        const body = (
            <div className={"container vertical-scrollable"}>
                <div className={"row"}>
                    <div className={"offset-1 col-10 font-weight-100 text-center py-2"}>
                         <span>
                             <p>
                                {isInfermiere === "P_INFERMIERE" ? "L'infermiere" : "Il medico"} {nominativoUtente} ha confermato la propria valutazione professionale, indicando nella sua valutazione dati diversi da quelli da te inseriti.
                             </p>
                             <p>
                                 {"Puoi visualizzare il dato inserito, nello \"Storico modifiche\" di ciascun campo selezionando la relativa icona. Le sezioni modificate sono le seguenti:"}
                             </p>
                         </span>
                    </div>
                </div>
                <div className={"table-responsive px-2 mt-4 mb-4"}>
                    <table className={"table table-striped table-sm mx-auto w-50"}>
                        <th className="col-span p-2 pr-md-3 bg-secondary text-white"> Sezioni modificate</th>
                        <tbody>
                        {this.renderRowsSezioni(WebSocketHelper.valutazioneConfermataEvent?.listaSezioniModificate)}
                        </tbody>
                    </table>
                </div>
            </div>
        );

        const footer = (
            <>
                <div className={"mx-auto w-auto"}>
                    <Button
                        id={"chiudiBtn"}
                        text={"Ho preso atto"}
                        className={"btn btn-adi btnGreenOutline"}
                        onClick={() => {
                            this.setState({apriModaleAlert: false}, () => this.onClickProsegui());
                            closeModaleStyle();
                            //Si rimanda a caso d’uso UC_04_15_0L: Conferma valutazione: Professionista diverso da MMG
                        }}/>
                </div>
            </>
        );

        const title = (
            <>
                <h6 className="modal-title w-100">Attenzione!</h6>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={() => this.chiudiModaleAlert()}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </>
        )

        return (
            <>
                <ModalHelper
                    id={"modaleAlert"}
                    title={title}
                    titleClassName={"modal-header text-center"}
                    containerClassName={"modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered"}
                    contentClassName={("modal-content px-3 py-1 text-center text-danger")}
                    body={body}
                    footer={footer}
                    footerClassName={"modal-footer justify-content-around"}
                />
            </>
        );
    }

    render() {
        if (!this.props.hideDefaultHeader) {
            let headerLabel;
            if (Utils.isStateRichiestaPreDialogo(this.props.pageState)) {
                headerLabel = "Attiva Pre-Dialogo: Proposte (2 di 3)";
            } else if (Utils.isStateValutazionePreDialogo(this.props.pageState)) {
                headerLabel = "Completa Pre-Dialogo: Proposte (2 di 3)";
            } else headerLabel = "Compila Proposte (2 di 3)";
            AccordionHelper.accordionPageSubject.next({position: 2, title: headerLabel});
        }

        let basePath = Utils.isStateRichiesta(this.props.pageState) ? routepath.inserimento_richiesta : routepath.valutazione_multidimensionale;

        if (this.state.redirectToIndietro) {
            const showOnlyRequiredFields = this.props.showOnlyRequiredFields || this.props.previousPageOnlyRequiredFields;
            let richiesta = _.cloneDeep(this.props.richiesta);
            buildRichiesta(richiesta, this, this.props.pageName, false);
            return (
                <Redirect to={{
                    key: uuid(),
                    pathname: showOnlyRequiredFields && AccordionHelper.idSelectedAccordions == null
                        ? basePath.concat(routepath.compila_proposte)
                        : showOnlyRequiredFields && AccordionHelper.idSelectedAccordions != null
                            ? AccordionHelper.lastPageUrlBeforeMenuItemClick
                            : this.props.firstPageName,
                    uuidRichiesta: this.props.richiesta.id,
                    pageState: this.props.pageState,
                    readOnly: this.props.readOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    firstPageName: this.props.firstPageName,
                    richiesta: richiesta,
                    richiestaMedico: this.props.richiestaMedico,
                    persone: this.props.persone,
                    tipologia: richiesta?.tipologia,
                    stato: richiesta?.stato,
                    valutazionePrecedente: (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) ? getValutazionePrecedente(richiesta) : null,
                    showOnlyRequiredFields: showOnlyRequiredFields,
                    previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields
                }}/>
            );
        } else if (this.state.redirectToVisualizzaRiepilogo) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            buildRichiesta(richiesta, this, this.props.pageName, false);
            richiesta = {
                ...richiesta,
                team: richiesta?.team?.map((t, index) => {
                    return {...t, professionista: this.props.richiesta?.team[index]?.professionista}
                })
            }
            return (
                <Redirect to={{
                    pathname: basePath.concat(routepath.visualizza_riepilogo),
                    uuidRichiesta: this.props.richiesta.id,
                    richiesta: richiesta,
                    richiestaMedico: this.props.richiestaMedico,
                    pageState: this.props.pageState,
                    readOnly: this.props.readOnly,
                    hideUnsetValues: this.props.hideUnsetValues,
                    firstPageName: this.props.firstPageName,
                    tipologia: this.props.richiesta?.tipologia,
                    stato: this.props.richiesta?.stato,
                    valutazionePrecedente: (Utils.isStateValutazione(this.props.pageState) || Utils.isStateValutazionePreDialogo(this.props.pageState)) ? getValutazionePrecedente(this.props.richiesta) : null,
                    showOnlyRequiredFields: this.props.showOnlyRequiredFields,
                    previousPageOnlyRequiredFields: this.props.previousPageOnlyRequiredFields
                }}/>
            );
        }

        const isStateValutazione = Utils.isStateValutazione(this.props.pageState);
        const forceUserRole = isStateValutazione && this.props.richiesta.sostituzioneRichiedente
        && (AuthUtils.hasUtenteCentraleAdi() || AuthUtils.hasUtenteRuoloMcp()) ? enumUtente.ruoli.mmg : null;

        UserHelper.setForceUserRole(forceUserRole);

        const {accordion} = this.state;
        return (
            <>
                {((!this.props.openAccordion && !Object.values(accordion).includes(true)) || (this.props.openAccordion)) &&
                <ScrollTop scrollInit={true} conAnimazione={false}/>}
                <div id={"compilaProposteId"}>
                    <RoleBasedContext.Provider
                        value={{
                            forceReadOnly: this.props.readOnly,
                            hideUnsetValues: this.props.hideUnsetValues,
                            scrollToHead: this.props.openAccordion,
                            forceUserRole: forceUserRole,
                            requestVersion: this.props.richiesta?.versione,
                            showOnlyRequiredFields: false
                        }}>
                        {Utils.isDimissioneProtetta(this.props.pageState) ? <div className="mx-5">{this.renderFormDimissioneProtetta()}</div> : <div className="mx-5">{this.renderFormClassica()}</div>}
                    </RoleBasedContext.Provider>
                </div>
                {!this.props.readOnly && this.renderButtons()}
            </>
        );
    }
}

CompilaProposte.propTypes = propTypes;

CompilaProposte.defaultProps = {
    openAccordion: false,
    hideDefaultHeader: false
}
