import React from "react";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import PropTypes from "prop-types";
import RelazioniFamiliari from "./relazioniFamiliari/RelazioniFamiliari";
import * as _ from "lodash";
import AccordionHelper from "../../service/AccordionHelper";
import ServizioAssistenzaSociale from "./servizioAssistenzaSociale/ServizioAssistenzaSociale";
import ParentiTenutiAgliAlimenti from "./parentiTenutiAgliAlimenti/ParentiTenutiAgliAlimenti";
import SupportoSocialeAssistenteNonFamiliare
    from "./supportoSocialeAssistenteNonFamiliare/SupportoSocialeAssistenteNonFamiliare"
import ValutazioneReteSociale from "./valutazioneReteSociale/ValutazioneReteSociale";
import ContributiEconomiciSostegno from "./contributiEconomiciSostegno/ContributiEconomiciSostegno";
import PersonaDiRiferimento from "./personaDiRiferimento/PersonaDiRiferimento";
import Utils from "../../utils/Utils";
import BenessereSociale from "./benessereSociale/BenessereSociale";
import ServiziAllaPersonaAttiviOInCorsoDiAttivazione
    from "./serviziAllaPersonaAttiviOInCorsoDiAttivazione/ServiziAllaPersonaAttiviOInCorsoDiAttivazione";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import VersionedAccordion from "../VersionedAccordion";
import enumRichiesta from "../../enum/enumRichiesta.json"


const propTypes = {
    datiAnagrafePaziente: PropTypes.any,
    datiSituazioneSociale: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiSituazioneSociale: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta: PropTypes.func,
    onValidaPagina: PropTypes.func,
    datiproposteAttivitaSuggerimentiAssistenteSociale: PropTypes.node,
    changeDatiProposteAttivitaSuggerimentiAssistenteSociale:PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

const defaultProps = {
    salvaBozza: () => undefined,
    onChangeDatiSituazioneSociale: () => undefined,
    onClickAccordion: () => undefined,
    onChangeObbligatorieta: () => undefined,
    onValidaPagina: () => undefined
}

export default class SituazioneSociale extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: {
            sottosezioneRelazioniFamiliari: 0,
            sottoSezionePersonaDiRiferimento: 0,
            sottosezioneValutazioneReteSociale: 0,
            sottoSezioneAssistenteNonFamiliare: 0,
            sottoSezioneServiziAllaPersonaAttiviOInCorsoDiAttivazione: 0
        },
        validitaSezione: {
            isRelazioniFamiliariValido: false,
            isParenteTenutoAgliAlimentiValido: false,
            isPersonaDiRiferimentoValida: false,
            isValutazioneReteSocialeValido: false,
            isAssistenteNonFamiliareValido: false
        },
        accordion: {
            openAccordionRelazioniFamiliari: true,
            openAccordionParentiTenutiAgliAlimenti: true,
            openAccordionPersonaDiRiferimento: true,
            openAccordionBenessereSociale: true,
            openAccordionServizioAssistenzaSociale: true,
            openAccordionValutazioneReteSociale: true,
            openAccordionSupportoSocialeAssistenteNonFamiliare: true,
            openAccordionContributiEconomiciSostegno: true,
            openAccordionServiziAllaPersonaAttiviOInCorsoDiAttivazione: true
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta.situazioneSociale = this.props.datiSituazioneSociale;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        let campiObbligatoriMancanti = this.state.campiObbligatoriMancanti;
        campiObbligatoriMancanti[field] = value;
        this.setState({campiObbligatoriMancanti: campiObbligatoriMancanti}, () => {
            this.props.onChangeObbligatorieta("situazioneSociale", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    validaSezione = (field, flag) => {
        let validitaSezione = this.state.validitaSezione;
        validitaSezione[field] = flag;
        this.setState({validitaSezione}, () => {
            this.props.onValidaPagina("situazioneSociale", this.state.validitaSezione);
        });
    }


    renderBodySituazioneSociale = () => {
        return (
            <>
                <VersionedAccordion accordion={RelazioniFamiliari}
                                    datiRelazioniFamiliari={this.props.datiSituazioneSociale.datiRelazioniFamiliari}
                                    onChangeDatiAssistito={this.props.onChangeDatiSituazioneSociale}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    onValidaSezione={this.validaSezione}
                                    openAccordion={this.state.accordion.openAccordionRelazioniFamiliari}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionRelazioniFamiliari"}
                                    mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                                    disableDoubleInput={this.props.disableDoubleInput}/>

                <VersionedAccordion accordion={ParentiTenutiAgliAlimenti}
                                    datiParentiForm={this.props.datiSituazioneSociale.parentiTenutiAgliAlimenti}
                                    onChangeParentiTenutiAgliAlimenti={this.props.onChangeDatiSituazioneSociale}
                                    onValidaSezione={this.validaSezione}
                                    openAccordion={this.state.accordion.openAccordionParentiTenutiAgliAlimenti}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionParentiTenutiAgliAlimenti"}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                                    disableDoubleInput={this.props.disableDoubleInput}/>

                <VersionedAccordion accordion={PersonaDiRiferimento}
                                    personeDiRiferimentoForm={this.props.datiSituazioneSociale.personaDiRiferimento}
                                    datiPadreForm={this.props.datiAnagrafePaziente.padre}
                                    datiMadreForm={this.props.datiAnagrafePaziente.madre}
                                    onChangePersoneDiRiferimento={this.props.onChangeDatiSituazioneSociale}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    onValidaSezione={this.validaSezione}
                                    openAccordion={this.state.accordion.openAccordionPersonaDiRiferimento}
                                    onClickAccordion={this.onClickAccordion}
                                    mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                                    field={"openAccordionPersonaDiRiferimento"}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                                    disableDoubleInput={this.props.disableDoubleInput}/>

                
                    <VersionedAccordion accordion={BenessereSociale}
                                        datiBenessereSociale={this.props.datiSituazioneSociale.datiBenessereSociale}
                                        onChangeDatiAssistito={this.props.onChangeDatiSituazioneSociale}
                                        openAccordion={this.state.accordion.openAccordionBenessereSociale}
                                        onClickAccordion={this.onClickAccordion}
                                        mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                                        field={"openAccordionBenessereSociale"}
                                        pageState={this.props.pageState}
                                        accordionReadOnly={this.props.accordionReadOnly}
                                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                                        disableDoubleInput={this.props.disableDoubleInput}/>

                <VersionedAccordion accordion={ServizioAssistenzaSociale}
                                    datiAssistenzaSociale={this.props.datiSituazioneSociale.datiAssistenzaSociale}
                                    onChangeDatiAssistito={this.props.onChangeDatiSituazioneSociale}
                                    openAccordion={this.state.accordion.openAccordionServizioAssistenzaSociale}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionServizioAssistenzaSociale"}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                                    disableDoubleInput={this.props.disableDoubleInput}/>

                <VersionedAccordion accordion={ValutazioneReteSociale}
                                    datiValutazioneReteSociale={this.props.datiSituazioneSociale.datiValutazioneReteSociale}
                                    onChangeDatiAssistito={this.props.onChangeDatiSituazioneSociale}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    onValidaSezione={this.validaSezione}
                                    openAccordion={this.state.accordion.openAccordionValutazioneReteSociale}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionValutazioneReteSociale"}
                                    pageState={this.props.pageState}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.props.salvaBozza}
                                    datiproposteAttivitaSuggerimentiAssistenteSociale={this.props.datiproposteAttivitaSuggerimentiAssistenteSociale}
                                    changeDatiProposteAttivitaSuggerimentiAssistenteSociale={this.props.changeDatiProposteAttivitaSuggerimentiAssistenteSociale}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                                    disableDoubleInput={this.props.disableDoubleInput}
                />

                <VersionedAccordion accordion={SupportoSocialeAssistenteNonFamiliare}
                                    assistentiNonFamiliariForm={this.props.datiSituazioneSociale.assistenteNonFamiliare}
                                    onChangeAssistenteNonFamiliare={this.props.onChangeDatiSituazioneSociale}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    onValidaSezione={this.validaSezione}
                                    openAccordion={this.state.accordion.openAccordionSupportoSocialeAssistenteNonFamiliare}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionSupportoSocialeAssistenteNonFamiliare"}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                                    disableDoubleInput={this.props.disableDoubleInput}/>

                <VersionedAccordion accordion={ContributiEconomiciSostegno}
                                    datiContributiEconomici={this.props.datiSituazioneSociale.datiContributiEconomici}
                                    onChangeDatiAssistito={this.props.onChangeDatiSituazioneSociale}
                                    openAccordion={this.state.accordion.openAccordionContributiEconomiciSostegno}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionContributiEconomiciSostegno"}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                                    disableDoubleInput={this.props.disableDoubleInput}/>

                {Utils.isStateValutazione(this.props.pageState) &&
                    <VersionedAccordion accordion={ServiziAllaPersonaAttiviOInCorsoDiAttivazione}
                                        datiServiziAllaPersonaAttiviOInCorsoDiAttivazione={this.props.datiSituazioneSociale.datiServiziAllaPersonaAttiviOInCorsoDiAttivazione}
                                        onChangeDatiAssistito={this.props.onChangeDatiSituazioneSociale}
                                        openAccordion={this.state.accordion.openAccordionServiziAllaPersonaAttiviOInCorsoDiAttivazione}
                                        onClickAccordion={this.onClickAccordion}
                                        field={"openAccordionServiziAllaPersonaAttiviOInCorsoDiAttivazione"}
                                        pageState={this.props.pageState}
                                        accordionReadOnly={this.props.accordionReadOnly}
                                        sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                                        disableDoubleInput={this.props.disableDoubleInput}/>}

            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"situazioneSociale"}
                title={"Situazione sociale"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySituazioneSociale()}</AccordionAvvioRichiesta>
        );
    }
}

SituazioneSociale.propTypes = propTypes;
SituazioneSociale.defaultProps = defaultProps;
