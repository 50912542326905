import React from "react";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import PropTypes from "prop-types";
import * as _ from "lodash";
import ConsumoDiTabacco from "./consumoDiTabacco/ConsumoDiTabacco";
import DrogheStupefacenti from "./drogheStupefacenti/DrogheStupefacenti";
import Alimentazione from "./alimentazione/Alimentazione";
import ConsumoDiAlcool from "./consumoDiAlcool/ConsumoDiAlcool";
import oggettiUtili from "../../utils/dataset/OggettiUtili.json";
import Utils from "../../utils/Utils";
import AttivitaFisicaSedentaria from "./attivitaFisicaSedentaria/AttivitaFisicaSedentaria";
import AccordionHelper from "../../service/AccordionHelper";
import NoteERegoleOsservateDaCulturaEReligione
    from "./noteERegoleOsservateDaCulturaEReligione/NoteERegoleOsservateDaCulturaEReligione";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import VersionedAccordion from "../VersionedAccordion";
import enumRichiesta from "../../enum/enumRichiesta.json"

const propTypes = {
    sessoPaziente: PropTypes.string,
    datiDeterminantiDiSalute: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDeterminatiDiSalute: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    accordionTitle: PropTypes.string,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class DeterminantiDiSalute extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: {
            sottosezioneAttivitaFisicaSedentarieta: 0,
            sottosezioneNoteERegoleOsservateDaCulturaEReligione: 0,
            sottosezioneConsumoDiTabacco:0,
            sottosezioneConsumoDiAlcool:0,
            sottosezioneAttivitaFisicaSedentaria:0,
            sottosezioneDrogheStupefacenti:0,
            sottosezioneAlimentazione:0,
        },
        accordion: {
            openAccordionConsumoDiTabacco: true,
            openAccordionDroghe: true,
            openAccordionAlimentazione: true,
            openAccordionAlcool: true,
            openAccordionAttivitaFisicaSedentarieta: true,
            openAccordionNoteERegoleOsservateDaCulturaEReligione: true
        }
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            let richiesta = _.cloneDeep(this.props.richiesta);
            richiesta.determinantiDiSalute = this.props.datiDeterminantiDiSalute;
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        let campiObbligatoriMancanti = this.state.campiObbligatoriMancanti;
        campiObbligatoriMancanti[field] = value;

        this.setState({campiObbligatoriMancanti: campiObbligatoriMancanti}, () => {
            this.props.onChangeObbligatorieta("determinantiDiSalute", Utils.sumRequiredFieldsMissing(campiObbligatoriMancanti));
        });
    }

    getSessoPaziente = () => {
        let sessoValue = !Utils.isStringEmptyOrNullOrUndefined(this.props.sessoPaziente) ? this.props.sessoPaziente : null
        return sessoValue ? oggettiUtili.sesso.find(el => el.value === sessoValue)?.label : null;
    }

    renderBodySezione = () => {
        return (
            <>
                <VersionedAccordion accordion={ConsumoDiTabacco}
                                    datiConsumoTabaccoForm={this.props.datiDeterminantiDiSalute.datiConsumoTabacco}
                                    onChangeDatiConsumoTabacco={this.props.onChangeDeterminatiDiSalute}
                                    openAccordion={this.state.accordion.openAccordionConsumoDiTabacco}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionConsumoDiTabacco"}
                                    pageState={this.props.pageState}
                                    richiesta={this.props.richiesta}
                                    mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                                    salvaBozza={this.props.salvaBozza}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion accordion={ConsumoDiAlcool}
                                    datiConsumoDiAlcoolForm={this.props.datiDeterminantiDiSalute.alcool}
                                    sessoPaziente={this.getSessoPaziente()}
                                    onChangeDatiConsumoAlcool={this.props.onChangeDeterminatiDiSalute}
                                    openAccordion={this.state.accordion.openAccordionAlcool}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAlcool"}
                                    pageState={this.props.pageState}
                                    richiesta={this.props.richiesta}
                                    mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                                    salvaBozza={this.props.salvaBozza}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />

                <VersionedAccordion accordion={DrogheStupefacenti}
                                    datiDroghe={this.props.datiDeterminantiDiSalute.datiDroghe}
                                    onChangeDatiAssistito={this.props.onChangeDeterminatiDiSalute}
                                    openAccordion={this.state.accordion.openAccordionDroghe}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionDroghe"}
                                    mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>

                <VersionedAccordion accordion={Alimentazione}
                                    datiAlimentazione={this.props.datiDeterminantiDiSalute.datiAlimentazione}
                                    onChangeDatiAssistito={this.props.onChangeDeterminatiDiSalute}
                                    openAccordion={this.state.accordion.openAccordionAlimentazione}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAlimentazione"}
                                    mascheraModificabilita={this.props.richiesta?.[enumRichiesta.attributi.maschera]}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>

                <VersionedAccordion accordion={AttivitaFisicaSedentaria}
                                    datiAttivitaFisicaSedentarieta={this.props.datiDeterminantiDiSalute.datiAttivitaFisicaSedentarieta}
                                    onChangeDatiAssistito={this.props.onChangeDeterminatiDiSalute}
                                    openAccordion={this.state.accordion.openAccordionAttivitaFisicaSedentarieta}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionAttivitaFisicaSedentarieta"}
                                    pageState={this.props.pageState}
                                    richiesta={this.props.richiesta}
                                    salvaBozza={this.props.salvaBozza}
                                    readOnly={this.props.accordionReadOnly}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>

                <VersionedAccordion accordion={NoteERegoleOsservateDaCulturaEReligione}
                                    datiNoteERegoleOsservateDaCulturaEReligione={this.props.datiDeterminantiDiSalute.datiNoteERegoleOsservateDaCulturaEReligione}
                                    onChangeDatiAssistito={this.props.onChangeDeterminatiDiSalute}
                                    openAccordion={this.state.accordion.openAccordionNoteERegoleOsservateDaCulturaEReligione}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openAccordionNoteERegoleOsservateDaCulturaEReligione"}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    pageState={this.props.pageState}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}/>

            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"determinantiDiSalute"}
                title={Utils.isRuoloInfermiere() ? 'Consapevolezza della salute' :  this.props.accordionTitle}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                field={this.props.field}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySezione()}</AccordionAvvioRichiesta>
        );
    }
}

DeterminantiDiSalute.propTypes = propTypes;
DeterminantiDiSalute.defaultProps = {
        accordionTitle: "Determinanti di salute"
}
