import serviceConfig from "../config/serviceConfig";
import { catchError, map } from "rxjs/operators";
import { from, of } from "rxjs";
import auth from "../utils/AthnAuth";

export default class RichiestaADIService {
    static urlNotifiche = serviceConfig['ms_richiesta'].notifiche;
    static urlNotifica = serviceConfig['ms_richiesta'].getNotifica;
    static urlDeleteNotifiche = serviceConfig['ms_richiesta'].deleteNotifiche;

    static getNotifiche(params) {
        let instance = auth._httpClient;

        let obs = from(instance.get(this.urlNotifiche, { params: params }));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static getNotifica(idNotifica) {
        let instance = auth._httpClient;

        let obs = from(instance.patch(this.urlNotifica.replace("{id}", idNotifica)));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static eliminaNotifiche(body) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlDeleteNotifiche, body));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

}
