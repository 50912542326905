import React from 'react'

export const VERSION_TO_NEW_REQUEST = 0;

export const Context = React.createContext({
    clickHome: false,
    setClickHome: () => { },


    numeroNotificheNonLette: 0,
    setNumeroNotificheNonLette: () => { },
    diminuisciNumeroNotificheNonLette: () => { }
});
