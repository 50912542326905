import React from "react";
import AccordionAvvioRichiesta from "../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionHelper from "../../service/AccordionHelper";
import AccordionFirstLevel from "../accordionAvvioRichiesta/AccordionFirstLevel";
import ValutazioneBisogniPalliativiSub from "./valutazioneBisogniPalliativiSub/ValutazioneBisogniPalliativiSub";
import Utils from "../../utils/Utils";
import VersionedAccordion from "../VersionedAccordion";


const propTypes = {
    dati: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeValutazioneBisogniPalliativi: PropTypes.func,
    pageState: PropTypes.string,
    onChangeObbligatorieta: PropTypes.func,
    readOnly:PropTypes.bool,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

const defaultProps = {
    salvaBozza: () => {
    },
    onClickAccordion: () => {
    },
    onChangeValutazioneBisogniPalliativi: () => {
    },
    onChangeObbligatorieta: () => {
    }
}

export default class ValutazioneBisogniPalliativi extends AccordionFirstLevel {
    state = {
        campiObbligatoriMancanti: 0,
        accordion: {
            openSubAccordionValutazioneBisogniPalliativi: true
        }
    };

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;
        if (accordion[field]) {
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({ accordion: accordion });
        }
    }

    handleChangeRequiredFieldsNumber = (value) => {
        this.setState({ campiObbligatoriMancanti: value }, () => {
            this.props.onChangeObbligatorieta("valutazioneBisogniPalliativi", this.state.campiObbligatoriMancanti);
        });
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"valutazioneBisogniPalliativi"}
                title={"Valutazione bisogni palliativi"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                numberOfRequiredFieldsMissing={Utils.isStateRichiesta(this.props.pageState) ? this.state.campiObbligatoriMancanti : 0}
                pageState={this.props.pageState}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}>
                <VersionedAccordion accordion={ValutazioneBisogniPalliativiSub}
                                    richiesta={this.props.richiesta}
                                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                    onChangeValutazioneBisogniPalliativi={this.props.onChangeValutazioneBisogniPalliativi}
                                    openAccordion={this.state.accordion.openSubAccordionValutazioneBisogniPalliativi}
                                    onClickAccordion={this.onClickAccordion}
                                    field={"openSubAccordionValutazioneBisogniPalliativi"}
                                    fieldRequired={true}
                                    pageState={this.props.pageState}
                                    readOnly={this.props.readOnly}
                                    salvaBozza={this.props.salvaBozza}
                                    accordionReadOnly={this.props.accordionReadOnly}
                                    sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
                />
            </AccordionAvvioRichiesta>
        );
    }
}

ValutazioneBisogniPalliativi.propTypes = propTypes;
ValutazioneBisogniPalliativi.defaultProps = defaultProps;
