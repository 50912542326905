import React, {Component} from "react";
import PropTypes from "prop-types";
import Utils from "../../utils/Utils";
import styles from "./Trackbar.module.css";
import MenuAccordion from "../menuAccordion/MenuAccordion";
import * as $ from "jquery";

export default class Trackbar extends Component {

    state = {
        activeTabConditions: [],
        tabLabels: []
    }

    componentDidMount() {
        this.montaComponente();
    }

    montaComponente = () => {
        this.setState({
            activeTabConditions: this.props.activeTabConditions ?? this.getDefaultActiveTabConditions(this.props.pageState),
            tabLabels: this.props.tabLabels ?? this.getDefaultTabLabels(this.props.pageState, this.props.richiesta)
        });
    }

    getDefaultActiveTabConditions = (pageState) => {
        return [
            Utils.isStateRichiesta(pageState),
            Utils.isStateValutazione(pageState) || Utils.isStateValutazionePreDialogo(pageState),
            Utils.isStateFormalizzazione(pageState) || Utils.isStateChiusuraPreDialogo(pageState)
        ];
    }

    getDefaultTabLabels = (pageState, richiesta) => {
        let labels = ['1. RICHIESTA', '2. VALUTAZIONE MULTIDIMENSIONALE', '3. FORMALIZZAZIONE'];
        if (Utils.isDimissioneProtetta(pageState)) {
            labels[0] = `${Utils.isFasePreDialogo(richiesta?.tipologia, richiesta?.stato) ? '1. ATTIVAZIONE PRE-DIALOGO' : '3. CHIUSURA PRE-DIALOGO'}`;
            labels[1] = `${Utils.isFasePreDialogo(richiesta?.tipologia, richiesta?.stato) ? '2. VALUTAZIONE PRE-DIALOGO' : labels[1].replace('2', '4')}`;
            labels[2] = `${Utils.isFasePreDialogo(richiesta?.tipologia, richiesta?.stato) ? '3. CHIUSURA PRE-DIALOGO' : labels[2].replace('3', '5')}`;
        }
        return labels;
    }

    renderProfessionistiOnline = () => {
        let valutatori = this.props.richiesta.valutazioni
            .filter(valutazione => this.props.listaCodiciFiscaliUtentiOnline.includes(valutazione.valutatore.codiceFiscale))
            .flatMap(valutazione => valutazione.valutatore);

        // aggiungo MMG
        if (this.props.listaCodiciFiscaliUtentiOnline.includes(this.props.richiesta?.mmg?.codiceFiscale)) {
            valutatori.push(this.props.richiesta?.mmg);
        }

        if (Object.prototype.hasOwnProperty.call(this.props.richiesta, "team")) {
            const professionisti = this.props.richiesta.team
                .filter(t => t?.professionista != null)
                .filter(t => this.props.listaCodiciFiscaliUtentiOnline.includes(t?.professionista?.codiceFiscale))
                .map(t => t?.professionista);
            valutatori.push(...professionisti);
        }
        valutatori = Utils.getDistinctListByField(valutatori, "codiceFiscale");

        const ref = document.getElementById("pazienteBreadcrumbLabel");
        const paddingRight = $(ref).css('padding-right')?.replace('px', '') ?? '0';
        const paddingLeft = $(ref).css('padding-left')?.replace('px', '') ?? '0';
        let customPaddingRight = '0px';
        if (window.innerWidth > 1024 && (paddingLeft !== '0' || paddingRight !== '0')) {
            customPaddingRight = String((Number(paddingLeft) * Number(paddingRight)) - 14) + 'px';
        }

        return (
            valutatori.length > 0
                ? <div className={"col-4"}>
                    <div className={"row float-right"} style={{paddingRight: customPaddingRight}}>
                        <div className={"col-auto align-self-center"}><strong>Professionisti online ora</strong></div>
                        {valutatori.map((valutatore, index) => {
                            let inizialiNome = !Utils.isStringEmptyOrNullOrUndefined(valutatore.nome) ? valutatore.nome.match(/\b(\w)/g).join('').toUpperCase() : "";
                            let inizialiCognome = !Utils.isStringEmptyOrNullOrUndefined(valutatore.cognome) ? valutatore.cognome.match(/\b(\w)/g).join('').toUpperCase() : "";
                            return <div key={index} className={"col-auto my-1"}>
                                <div className={styles.circle.concat(" rounded-circle")}>
                                    {inizialiNome}{inizialiCognome}
                                </div>
                            </div>;
                        })}
                    </div>
                </div>
                : null
        );
    }

    render() {
        const isPageStateValutazioneOrFormalizzazione = Utils.isStateValutazione(this.props.pageState)
            || Utils.isStateValutazionePreDialogo(this.props.pageState)
            || Utils.isStateFormalizzazione(this.props.pageState)
            || Utils.isStateChiusuraPreDialogo(this.props.pageState);
        const showProfessionistiOnline = isPageStateValutazioneOrFormalizzazione && this.props.richiesta && !!this.props.listaCodiciFiscaliUtentiOnline?.length;

        let activePhase = this.state.tabLabels[this.state.activeTabConditions.findIndex(e => !!e)];
        if (activePhase != null) activePhase = activePhase.replaceAll(/\d\.\s+/g, "");

        return <div id={"trackbar"} className={['row', styles.stickyBar, 'm-0', 'mb-4'].join(' ')}>
            <div className={"col-3 p-0"}>
                <MenuAccordion richiesta={this.props.richiesta}
                               pageState={this.props.pageState}
                               showAllMenuItems={!!this.props.showAllMenuItems}
                               readOnly={this.props.readOnly}
                               hideUnsetValues={this.props.hideUnsetValues}
                               richiestaMedico={this.props.richiestaMedico}
                               persone={this.props.persone}
                               containsOnlyRequiredFields={!!this.props.containsOnlyRequiredFields}
                               previousPageOnlyRequiredFields={this.props.previousPageOnlyRequiredFields}
                               showOptionalFieldsCount={this.props.showOptionalFieldsCount}
                               showMultiCompilationPages={this.props.showMultiCompilationPages}
                />
            </div>
            <div className={"col-9 align-self-center"}>
                <div className={"row"}>
                    <div className={"col-8 text-center"}>
                        <span id={"idActiveTab"}
                              style={{fontSize: "x-large", fontWeight: "bold", color: "#176A65"}}>{activePhase}</span>
                    </div>
                    {showProfessionistiOnline && this.renderProfessionistiOnline()}
                </div>
            </div>
        </div>;
    }
}

Trackbar.propTypes = {
    pageState: PropTypes.string,
    activeTabConditions: PropTypes.arrayOf(PropTypes.bool),
    tabLabels: PropTypes.arrayOf(PropTypes.string),
    richiesta: PropTypes.object,
    showAllMenuItems: PropTypes.bool,
    readOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    richiestaMedico: PropTypes.object,
    persone: PropTypes.object,
    containsOnlyRequiredFields: PropTypes.bool,
    previousPageOnlyRequiredFields: PropTypes.any,
    showOptionalFieldsCount: PropTypes.bool,
    listaCodiciFiscaliUtentiOnline: PropTypes.array,
    showMultiCompilationPages: PropTypes.bool
}

Trackbar.defaultProps = {
    previousPageOnlyRequiredFields: null,
    showOptionalFieldsCount: true,
    showMultiCompilationPages: true
}
