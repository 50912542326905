import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import PropTypes from "prop-types";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import React from "react";
import SessionRequest from "../../../utils/SessionRequest";
import * as _ from "lodash";
import AccordionHelper from "../../../service/AccordionHelper";
import Utils from "../../../utils/Utils";
import StatoFunzionale from "../../anamnesiFisiologica/statoFunzionale/StatoFunzionale";
import GradoDiMobilita from "../../anamnesiFisiologica/gradoDiMobilita/GradoDiMobilita";
import SensorioEquilibrio from "../../anamnesiFisiologica/sensorioEquilibrio/SensorioEquilibrio";
import AttivitaStrumentaliDellaVitaQuotidiana
    from "../../anamnesiFisiologica/attivitaStrumentaliDellaVitaQuotidiana/AttivitaStrumentaliDellaVitaQuotidiana";
import StatoCognitivo from "../../anamnesiFisiologica/statoCognitivo/StatoCognitivo";
import StatoComportamentale from "../../anamnesiFisiologica/statoComportamentale/StatoComportamentale";
import NecessitaDiAssistenzaSanitaria
    from "../../anamnesiFisiologica/necessitaDiAssistenzaSanitaria/NecessitaDiAssistenzaSanitaria";
import VersionedAccordion from "../../VersionedAccordion";

const propTypes = {
    datiCapacitaCuraDiSe: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiCapacitaCuraDiSe: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    datiAnamnesiFisiologica: PropTypes.node
}

export default class CapacitaCuraDiSe extends AccordionFirstLevel {

    state = {
        campiObbligatoriMancanti: {
            sottosezioneStatoFunzionale: 0,
            sottosezioneGradoDiMobilita: 0,
            sottosezioneStatoComportamentale: 0,
            sottosezioneComunicazione:0,
            sottosezioneSensorioEquilibrio:0
        },
        accordion: {
            openAccordionSituazioneFunzionale: true,
            openAccordionGradoDiMobilita: true,
            openAccordionEquilibrio: true,
            openAccordionAttivitaStrumentaliDellaVitaQuotidiana: true,
            openAccordionStatoCognitivo: true,
            openAccordionStatoComportamentale: true,
            openAccordionNecessitaDiAssistenzaSanitaria: true,
        }
    }

    constructor(props) {
        super(props);

        const sessionObj = SessionRequest.getSessionObjectFor(props.richiesta);
        this.state.data = _.cloneDeep(sessionObj.anamnesiFisiologica);
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    onChange = (field, value) => {
        const data = _.cloneDeep(this.state.data);
        data[field] = value;
        this.setState(data);
    }

    hideOptionalAccordion(accordion) {
        let accordions = _.cloneDeep(this.state.visualizzaAccordion);
        accordions[accordion] = false;
        this.setState({visualizzaAccordion: accordions});
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        let campiObbligatoriMancanti = this.state.campiObbligatoriMancanti;
        campiObbligatoriMancanti[field] = value;
        this.setState({campiObbligatoriMancanti: campiObbligatoriMancanti}, () => {
            this.props.onChangeObbligatorieta("capacitaCuraDiSe", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    renderBody = () => {
        return (
            <>
                <div className="row" id={"idSottoaccordionQuestionariCapacitaCuraDiSe"}>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion accordion={StatoFunzionale} datiPADL={this.props.datiAnamnesiFisiologica.statoFunzionale}
                                            onChangeDatiPADL={this.props.onChangeDatiAnamnesiFisiologica}
                                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                            openAccordion={this.state.accordion.openAccordionSituazioneFunzionale}
                                            onClickAccordion={this.onClickAccordion}
                                            field={"openAccordionSituazioneFunzionale"}
                                            pageState={this.props.pageState}
                                            richiesta={this.props.richiesta}
                                            salvaBozza={this.props.salvaBozza}
                                            forceReadOnly={this.props.accordionReadOnly}
                                            accordionReadOnly={this.props.accordionReadOnly}/>
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion accordion={GradoDiMobilita}
                                            datiGradoDiMobilitaForm={ this.props.datiAnamnesiFisiologica.datiGradoDiMobilita}
                                            onChangeDatiGradoDiMobilitaForm={this.props.onChangeDatiAnamnesiFisiologica}
                                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                            openAccordion={this.state.accordion.openAccordionGradoDiMobilita}
                                            onClickAccordion={this.onClickAccordion}
                                            field={"openAccordionGradoDiMobilita"}
                                            pageState={this.props.pageState}
                                            richiesta={this.props.richiesta}
                                            salvaBozza={this.props.salvaBozza}
                                            forceReadOnly={this.props.accordionReadOnly}
                                            accordionReadOnly={this.props.accordionReadOnly}/>
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion accordion={StatoCognitivo}
                                            datiStatoCognitivoForm={ this.props.datiAnamnesiFisiologica.datiStatoCognitivo}
                                            onChangeDatiStatoCognitivoForm={this.props.onChangeDatiAnamnesiFisiologica}
                                            openAccordion={this.state.accordion.openAccordionStatoCognitivo}
                                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                            onClickAccordion={this.onClickAccordion}
                                            field={"openAccordionStatoCognitivo"}
                                            pageState={this.props.pageState}
                                            richiesta={this.props.richiesta}
                                            salvaBozza={this.props.salvaBozza}
                                            forceReadOnly={this.props.accordionReadOnly}
                                            accordionReadOnly={this.props.accordionReadOnly}/>
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion accordion={StatoComportamentale}
                                            datiStatoComportamentaleForm={ this.props.datiAnamnesiFisiologica.datiStatoComportamentale}
                                            onChangeDatiStatoComportamentaleForm={this.props.onChangeDatiAnamnesiFisiologica}
                                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                            openAccordion={this.state.accordion.openAccordionStatoComportamentale}
                                            onClickAccordion={this.onClickAccordion}
                                            field={"openAccordionStatoComportamentale"}
                                            pageState={this.props.pageState}
                                            salvaBozza={this.props.salvaBozza}
                                            accordionReadOnly={this.props.accordionReadOnly}/>
                    </div>
                    <div className="col-12 col-xl-6">
                        <VersionedAccordion accordion={NecessitaDiAssistenzaSanitaria}
                                            datiNecessitaDiAssistenzaSanitariaForm={ this.props.datiAnamnesiFisiologica.datiNecessitaDiAssistenzaSanitaria}
                                            figurePai={this.props.richiesta.figureProfessionaliPresaInCarico}
                                            professionistiSupportoAValutazione={this.props.richiesta.professionistiSupportoAValutazione}
                                            onChangeDatiNecessitaDiAssistenzaSanitaria={this.props.onChangeDatiAnamnesiFisiologica}
                                            onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                                            openAccordion={this.state.accordion.openAccordionNecessitaDiAssistenzaSanitaria}
                                            onClickAccordion={this.onClickAccordion}
                                            field={"openAccordionNecessitaDiAssistenzaSanitaria"}
                                            pageState={this.props.pageState}
                                            richiesta={this.props.richiesta}
                                            salvaBozza={this.props.salvaBozza}
                                            forceReadOnly={this.props.accordionReadOnly}
                                            accordionReadOnly={this.props.accordionReadOnly}/>
                    </div>
                </div>
             

                <VersionedAccordion accordion={SensorioEquilibrio}
                    datiSensorioEquilibrio={ this.props.datiAnamnesiFisiologica.sensorioEquilibrio}
                    onChangeDatiSensorioEquilibrio={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionEquilibrio}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionEquilibrio"}
                    pageState={this.props.pageState}
                    richiesta={this.props.richiesta}
                    accordionTitle={"Rischio di cadute"}
                    salvaBozza={this.props.salvaBozza}
                    fieldIdParent ={"rischioDiCadute"}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    forceReadOnly={this.props.accordionReadOnly}
                    accordionReadOnly={this.props.accordionReadOnly}
                />
                <VersionedAccordion accordion={AttivitaStrumentaliDellaVitaQuotidiana} datiIADL={ this.props.datiAnamnesiFisiologica.datiIADL}
                    onChangeDatiIADL={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionAttivitaStrumentaliDellaVitaQuotidiana}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionAttivitaStrumentaliDellaVitaQuotidiana"}
                    pageState={this.props.pageState}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    forceReadOnly={this.props.accordionReadOnly}
                    accordionReadOnly={this.props.accordionReadOnly}
                />
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"capacitaCuraDiSeInfermiere"}
                title={"Capacità e cura di sé"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBody()}</AccordionAvvioRichiesta>
        );
    }
}

CapacitaCuraDiSe.propTypes = propTypes;
