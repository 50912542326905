import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import PropTypes from "prop-types";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import React from "react";
import SessionRequest from "../../../utils/SessionRequest";
import * as _ from "lodash";
import AccordionHelper from "../../../service/AccordionHelper";
import SensorioEquilibrio from "../../anamnesiFisiologica/sensorioEquilibrio/SensorioEquilibrio";
import Comunicazione from "../../anamnesiFisiologica/comunicazione/Comunicazione";
import SensorioUdito from "../../anamnesiFisiologica/sensorioUdito/SensorioUdito";
import SensorioVista from "../../anamnesiFisiologica/sensorioVista/SensorioVista";
import SensorioBaseValutazione from "../../anamnesiFisiologica/sensorioBaseValutazione/SensorioBaseValutazione";
import SensorioDolore from "../../anamnesiFisiologica/sensorioDolore/SensorioDolore";
import VersionedAccordion from "../../VersionedAccordion";
import Utils from "../../../utils/Utils";

const propTypes = {
    datiComunicazioneSensorio: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeComunicazioneSensorio: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

export default class ComunicazioneSensorio extends AccordionFirstLevel {

    state = {
        campiObbligatoriMancanti: {
            sottosezioneComunicazione:0,
            sottosezioneSensorioBaseValutazioneOlfatto:0,
            sottosezioneSensorioBaseValutazioneGusto:0,
            sottosezioneSensorioBaseValutazioneTatto:0,
            sottosezioneSensorioDolore:0,
            sottosezioneSensorioEquilibrio:0,
            sottosezioneSensorioUdito:0,
            sottosezioneSensorioVista:0
        },
        accordion: {
            openAccordionComunicazione: true,
            openAccordionSensorioUdito: true,
            openAccordionSensorioVista: true,
            openAccordionSensorioOlfatto: true,
            openAccordionSensorioGusto: true,
            openAccordionSensorioTatto: true,
            openAccordionEquilibrio: true,
            openAccordionSensorioDolore: true
        }
    }

    campiObbligatori = {...this.state.campiObbligatoriMancanti};
    
    constructor(props) {
        super(props);

        const sessionObj = SessionRequest.getSessionObjectFor(props.richiesta);
        this.state.data = _.cloneDeep(sessionObj.anamnesiFisiologica);
    }


    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    onChange = (field, value) => {
        const data = _.cloneDeep(this.state.data);
        data[field] = value;
        this.setState(data);
    }

    hideOptionalAccordion(accordion) {
        let accordions = _.cloneDeep(this.state.visualizzaAccordion);
        accordions[accordion] = false;
        this.setState({visualizzaAccordion: accordions});
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        this.campiObbligatori[field] = value;
        this.setState({campiObbligatoriMancanti: _.cloneDeep(this.campiObbligatori)}, () => {
            this.props.onChangeObbligatorieta("comunicazioneSensorio", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    renderBody = () => {
        return (
            <>
                <VersionedAccordion accordion={Comunicazione}
                    datiComunicazione={this.props.datiAnamnesiFisiologica.datiComunicazione}
                    onChangeDatiComunicazione={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionComunicazione}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionComunicazione"}
                    pageState={this.props.pageState}
                    onHide={() => this.hideOptionalAccordion('visualizzaAccordionComunicazione')}
                    isOptional={false}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                />

                <VersionedAccordion accordion={SensorioUdito}
                    datiSensorioUditoForm={this.props.datiAnamnesiFisiologica.datiSensorioUdito}
                    onChangeDatiSensorioUditoForm={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioUdito}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioUdito"}
                    pageState={this.props.pageState}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                />

                <VersionedAccordion accordion={SensorioVista}
                    datiSensorioVista={this.props.datiAnamnesiFisiologica.datiSensorioVista}
                    onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioVista}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioVista"}
                    pageState={this.props.pageState}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                />

                <VersionedAccordion accordion={SensorioBaseValutazione}
                    sensorio={"Olfatto"}
                    datiSensorio={this.props.datiAnamnesiFisiologica.sensorioOlfatto}
                    onChangeDatiSensorio={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioOlfatto}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioOlfatto"}
                    pageState={this.props.pageState}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                />

                <VersionedAccordion accordion={SensorioBaseValutazione}
                    sensorio={"Gusto"}
                    datiSensorio={this.props.datiAnamnesiFisiologica.sensorioGusto}
                    onChangeDatiSensorio={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioGusto}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioGusto"}
                    pageState={this.props.pageState}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                />

                <VersionedAccordion accordion={SensorioBaseValutazione}
                    sensorio={"Tatto"}
                    datiSensorio={this.props.datiAnamnesiFisiologica.sensorioTatto}
                    onChangeDatiSensorio={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioTatto}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioTatto"}
                    pageState={this.props.pageState}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                />

                <VersionedAccordion accordion={SensorioEquilibrio}
                    datiSensorioEquilibrio={this.props.datiAnamnesiFisiologica.sensorioEquilibrio}
                    onChangeDatiSensorioEquilibrio={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionEquilibrio}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionEquilibrio"}
                    pageState={this.props.pageState}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    fieldIdParent ={"comunicazioneEsensorio"}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    forceReadOnly={this.props.accordionReadOnly}
                    accordionReadOnly={this.props.accordionReadOnly}
                />

                <VersionedAccordion accordion={SensorioDolore}
                    datiSensorioDolore={this.props.datiAnamnesiFisiologica.datiSensorioDolore}
                    onChangeDatiAssistito={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionSensorioDolore}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionSensorioDolore"}
                    pageState={this.props.pageState}
                    richiesta={this.props.richiesta}
                    salvaBozza={this.props.salvaBozza}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                />
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"comunicazioneSensorioInfermiere"}
                title={"Comunicazione e sensorio"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBody()}</AccordionAvvioRichiesta>
        );
    }
}

ComunicazioneSensorio.propTypes = propTypes;
