import React from "react";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Utils from "../../../utils/Utils";
import {FieldsRow, RoleBasedInput, RoleBasedSelect} from "../../roleBasedComponents/RoleBasedComponents";

const propTypes = {
    datiRelazioniFamiliari: PropTypes.any,
    onChangeDatiAssistito: PropTypes.func,
    onChangeRequiredFieldsNumber: PropTypes.func,
    onValidaSezione: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.any,
    mascheraModificabilita: PropTypes.any,
    forceReadOnly: PropTypes.bool,
    field: PropTypes.any,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool,
    disableDoubleInput: PropTypes.bool
}

const requiredFields = ["statoCivile", "numeroComponentiNucleoFamiliare"]

export default class RelazioniFamiliari extends AccordionSecondLevel {

    state = {
        datiRelazioniFamiliari: _.cloneDeep(this.props.datiRelazioniFamiliari)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiRelazioniFamiliari, this.props.datiRelazioniFamiliari))
            this.inizializzaForm();
    }

    inizializzaForm = () => {
        let datiRelazioniFamiliari = _.cloneDeep(this.props.datiRelazioniFamiliari);
        this.setState({datiRelazioniFamiliari}, () => {
            this.countCampiObbligatoriRimanentiAndValida();
        });
    }

    countCampiObbligatoriRimanentiAndValida = () => {
        let datiRelazioniFamiliari = this.state.datiRelazioniFamiliari;
        let campiObbligatoriRimanenti = 0;

        requiredFields.forEach(field => {
            if (Utils.isStringEmptyOrNullOrUndefined(datiRelazioniFamiliari[field])) {
                campiObbligatoriRimanenti += 1;
            }
        });

        this.props.onChangeRequiredFieldsNumber("sottosezioneRelazioniFamiliari", campiObbligatoriRimanenti);
        this.props.onValidaSezione("isRelazioniFamiliariValido", campiObbligatoriRimanenti === 0);
    }

    handleAggiornaForm = (field, elem) => {
        let datiRelazioniFamiliari = _.cloneDeep(this.state.datiRelazioniFamiliari);

        if (field !== "figli") {
            datiRelazioniFamiliari[field] = elem != null && elem !== "" ? elem : null;
        } else {
            datiRelazioniFamiliari[field] = elem;
        }

        this.setState({datiRelazioniFamiliari}, () => {
            let datiRelazioniFamiliariClone = _.cloneDeep(this.state.datiRelazioniFamiliari);
            this.props.onChangeDatiAssistito("datiRelazioniFamiliari", datiRelazioniFamiliariClone);
            this.countCampiObbligatoriRimanentiAndValida();
        });
    }

    renderBodySezione = () => {
        let datiRelazioniFamiliari = this.state.datiRelazioniFamiliari;
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'STATO_CIV.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        id={"selectStatoCivile"}
                        options={OggettiUtili.statoCivile}
                        value={datiRelazioniFamiliari.statoCivile}
                        onChange={(elem) => this.handleAggiornaForm("statoCivile", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Stato civile non esistente"}
                        fieldLabel={'Stato civile'}
                        fieldRequired={true}
                        handleOnlyValue={true}
                        field={"statoCivile"}
                        forceReadOnly={this.props.forceReadOnly
                            || this.props.mascheraModificabilita?.situazioneSociale?.datiRelazioniFamiliari?.statoCivile}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedInput
                        fieldId={'NUM_COMP_NUCL_FAM.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        value={datiRelazioniFamiliari.numeroComponentiNucleoFamiliare}
                        placeholder="Es. 3"
                        field="numeroComponentiNucleoFamiliare"
                        onChange={(field, elem) => {
                            elem.target.value = elem.target.value.replace(/\D*/g,'');
                            this.handleAggiornaForm(field, elem.target.value);
                        }}
                        id="numeroComponentiNucleoFamiliare"
                        fieldLabel={"N. componenti nucleo familiare"}
                        hint={"(escluso l’assistito e l’eventuale assistente non familiare convivente)"}
                        fieldRequired={true}
                        type={"number"}
                        onKeyDown={(event) => {
                            if (event.key === '.') event.preventDefault();
                        }}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                    <RoleBasedSelect
                        fieldId={'FIGLI.RELAZIONIFAMILIARI.SITUAZIONESOCIALE'}
                        pageState={this.props.pageState}
                        id={"selectFigli"}
                        className={"selectFigli"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiRelazioniFamiliari.figli}
                        onChange={(elem) => this.handleAggiornaForm("figli", elem)}
                        isSearchable={false}
                        fieldLabel={'Figli'}
                        handleOnlyValue={true}
                        field={"figli"}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return (
            <AccordionSezioneRichiesta
                idAccordion={"datiRelazioniFamiliari"}
                title={"Relazioni familiari"}
                required={true}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                accordionReadOnly={this.props.accordionReadOnly}
                sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
            >{this.renderBodySezione()}</AccordionSezioneRichiesta>
        );
    }
}

RelazioniFamiliari.propTypes = propTypes;
