import React from "react";
import Utils from "../../utils/Utils";
import HomeUtils from "../home/HomeUtils";
import statiRichiesta from "../../utils/dataset/richiestaData/statiRichiesta.json";
import * as Icon from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import {DropdownItem} from "reactstrap";
import AuthUtils from "../../utils/AuthUtils";
import {SessioneUtente} from "web-client-archetype";
import styles from "./StoricoRichieste.module.css";
import * as moment from "moment";
import routepath from "../../utils/route/route-path.json";
import enumRichiesta from "../../enum/enumRichiesta.json";
import enumFirma from "../../enum/enumFirma.json";

import {RUOLI_SELEZIONABILI_DA_CENTRALE_ADI, RUOLI_SELEZIONABILI_DA_COORDINATORE, RUOLI_SELEZIONABILI_DA_DIRETTORE_UO, RUOLI_SELEZIONABILI_DA_MCP, RUOLI_SELEZIONABILI_DA_REF_DIR_ASS_SOCIALI} from "../statoValutazioneEComponentiDelTeam/modaleAttivazioneFiguraProfessionale/AttivazioneFiguraProfessionale";
import enumsUtente from "../../enum/enumsUtente";
import ruoliSelect from "../../utils/ruoli.json";
export default class StoricoRichiesteUtils {

    static mapStoricoRichieste(response, openModal, generatePdf) {
        let res = [];

        const renderDropdownToggle = () => {
            return (
                <Icon.ListUl color={"#176A65"} size={24} />
            )
        }

        response.forEach(function (richiesta) {

            let element = {}

            let listaAzioni = StoricoRichiesteUtils.getListaAzioni(richiesta);

            const renderDropdownMenu = () => {
                return (
                    <>
                        {listaAzioni.map(el => (
                            <>        
                                 {el?.modalToOpen && 
                                    <Link onClick={() => openModal(el?.modalToOpen, el.to.uuidRichiesta)} className={styles.apriAzione} key={el.id}>
                                        <DropdownItem className={styles.apriAzione.concat(" text-center")}>
                                            {el.label}
                                        </DropdownItem>
                                    </Link> }  
                                 {!el?.modalToOpen &&                     
                                    <Link to={el?.pdfObject ? '#' : el.to}
                                          onClick={() => el?.pdfObject ? generatePdf(el?.pdfObject) : null}
                                          className={styles.apriAzione}
                                          key={el.id}>
                                        <DropdownItem className={styles.apriAzione.concat(" text-center")}>
                                            {el.label}
                                        </DropdownItem>
                                    </Link>}
                            </>
                        ))}

                    </>
                )
            }

            element.cognomeNome = richiesta.assistito?.cognome + " " + richiesta.assistito?.nome;
            element.dataRichiesta = richiesta.timestampCompletamento ? Utils.transformTimeStampForFrontend(richiesta.timestampCompletamento) : "-";
            element.medicoRichiedente = HomeUtils.getMedicoRichiedente(richiesta.richiedente);
            element.esitoRichiesta = StoricoRichiesteUtils.getEsitoRichiesta(richiesta.stato);
            element.dataEsito = StoricoRichiesteUtils.getDataEsito(richiesta);
            element.medicoFormalizzatore = StoricoRichiesteUtils.getMedicoFormalizzatore(richiesta);

            if (!!element.medicoFormalizzatore && element.medicoFormalizzatore !== "-") {
                element.firmataDa = StoricoRichiesteUtils.getInfoFirma(richiesta);
            } else element.firmataDa = "-";

            element.cureDomiciliari = StoricoRichiesteUtils.getCureDomiciliari(richiesta);
            element.azioni = {
                renderDropdownToggle: renderDropdownToggle,
                renderDropdownMenu: renderDropdownMenu,
                idDropdown: "dropdownAzioni" + richiesta.id,
                idDropdownToggle: "dropdownToggleAzioni" + richiesta.id,
                tagDropdownToggle: "button",
                classNameDropdown: styles.dropdownAzioni,
                classNameDropdownMenu: styles.dropdownMenu,
                classNameDropdownToggle: styles.dropdownToggle,
                direction: "right"
            }

            res.push(element)
        })

        if (res.length === 0) {
            return response
        }
        return res
    }

    static getDataEsito(richiesta) {
        switch (richiesta.stato) {
            case statiRichiesta.accettata.value:
            case statiRichiesta.rifiutata.value:
                return Utils.transformTimeStampForFrontend(richiesta.timestampEsito);
            default:
                return "-";
        }
    }

    static getMedicoFormalizzatore(richiesta) {
        switch (richiesta.stato) {
            case statiRichiesta.accettata.value:
            case statiRichiesta.rifiutata.value:
                var professionista = richiesta?.professionistaEsito;
                var nominativo = professionista?.codiceFiscale === SessioneUtente.getInstance().idUtente ? "Io" : professionista?.nome + " " + professionista?.cognome;
                return !Utils.isStringEmptyOrNullOrUndefined(nominativo)
                    ? nominativo + " (" + AuthUtils.getRoleDescriptionFromCode(professionista.ruolo) + ")"
                    : "-";
            default:
                return "-";
        }
    }

    static getEsitoRichiesta(statoRichiesta) {
        let esitoRichiesta = {};

        Object.keys(statiRichiesta).forEach((key) => {
            if (statiRichiesta[key].value === statoRichiesta) {
                esitoRichiesta.text = statiRichiesta[key].label;
                esitoRichiesta.style = {};
                if (statoRichiesta === statiRichiesta.annullata.value) {
                    esitoRichiesta.text = "Annullata da mmg";
                    esitoRichiesta.style = {color: "#D83D3D"};
                }
            }
        });

        return esitoRichiesta;
    }

    static getListaAzioni(richiesta) {
        const visualizzaRichiesta = {
            label: "Visualizza pdf Richiesta",
            pdfObject: {idRichiesta: richiesta.id, step: "STEP_CDA2_INOLTRO"},
            id: "visualizzaRichiesta"
        };

        const visualizzaScaleQuestionari = {
            label: "Visualizza Scale e Questionari",
            to: {
                pathname: window.location.pathname.concat(routepath.scalaEquestionario),
                uuidRichiesta: richiesta.id,
                richiesta: richiesta
            },
            id: "visualizzaScaleQuestionari"
        };

        const visualizzaMaggioriDettagli = {
            label: "Visualizza maggiori dettagli",
            to: {
                pathname: window.location.pathname.concat(routepath.visualizza_dettagli_richiesta_storico),
                uuidRichiesta: richiesta.id
            },
            id: "visualizzaMaggioriDettagli"
        };

        const apriCartella = {
            label: "Apri cartella",
            to: {pathname: "", uuidRichiesta: richiesta.id},
            id: "apriCartella"
        }

        let listaAzioni = [];

        const {delega, valutazioni} = richiesta;
        const valore = delega?.find(delega => delega?.profilo === enumsUtente.profiliUtente.centraleAdi)?.valore;

        const ruoliProfiloIntermedio =  AuthUtils.hasUtenteRuoloMcp() ? RUOLI_SELEZIONABILI_DA_MCP :
                                        AuthUtils.hasUtenteRuoloCoordinatore() ? RUOLI_SELEZIONABILI_DA_COORDINATORE :
                                        AuthUtils.hasUtenteProfiloDirettoreUO() ? RUOLI_SELEZIONABILI_DA_DIRETTORE_UO :
                                        AuthUtils.hasUtenteProfiloReferenteAssistentiSociali() ? RUOLI_SELEZIONABILI_DA_REF_DIR_ASS_SOCIALI :
                                        AuthUtils.hasUtenteCentraleAdi() && !valore ? RUOLI_SELEZIONABILI_DA_CENTRALE_ADI :
                                        AuthUtils.hasUtenteCentraleAdi() && valore ? RUOLI_SELEZIONABILI_DA_CENTRALE_ADI.concat(RUOLI_SELEZIONABILI_DA_MCP) : [];
        

        if (AuthUtils.hasUtenteRuoloProfessionistaValutatore() ||
            AuthUtils.hasUtenteRuoloCoordinatore() ||
            AuthUtils.hasUtenteProfiloDirettoreUO() ||
            AuthUtils.hasUtenteProfiloReferenteAssistentiSociali()) {
            let valutazione;

            richiesta.valutazioni.forEach(val => {
                if (SessioneUtente.getInstance().idUtente === val.valutatore.codiceFiscale) {
                    valutazione = val;
                }
            });

            if (valutazione?.stato === statiRichiesta.confermata.value) {
                listaAzioni.push({
                    label: "Visualizza pdf Valutazione professionale (mia)",
                    pdfObject: {idRichiesta: richiesta.id, step: AuthUtils.hasUtenteRuoloMedicoRichiedente() || AuthUtils.hasUtenteRuoloFormalizzatore() ? "STEP_CDA2_VALUTAZIONE_MEDICO_RICHIEDENTE" : "STEP_CDA2_INVIO_VALUTAZIONE"},
                    id: "visualizzaValutazioneProfessionaleMia"
                })
            }
        }

        if (AuthUtils.hasUtenteRuoloMcp() ||
            AuthUtils.hasUtenteRuoloCoordinatore() ||
            AuthUtils.hasUtenteProfiloDirettoreUO() ||
            AuthUtils.hasUtenteProfiloReferenteAssistentiSociali() ||
            AuthUtils.hasUtenteCentraleAdi()) {

            const valutazioneProfesionista = valutazioni?.filter(val => ruoliProfiloIntermedio.includes(val?.valutatore?.ruolo)) || [];

            valutazioneProfesionista.forEach(val => {
                const ruoloLabel = ruoliSelect?.ruoliProfessionistaValutatore?.find(ruolo => ruolo?.value === val?.valutatore?.ruolo)?.label;
                if (val?.stato === statiRichiesta.confermata.value) {
                    listaAzioni.push({
                        label: `Visualizza pdf Valutazione professionale di ${val?.valutatore?.nome} ${val?.valutatore?.cognome} (${ruoloLabel})`,
                        pdfObject: {idRichiesta: richiesta.id, step: AuthUtils.hasUtenteRuoloMedicoRichiedente() || AuthUtils.hasUtenteRuoloFormalizzatore() ? "STEP_CDA2_VALUTAZIONE_MEDICO_RICHIEDENTE" : "STEP_CDA2_INVIO_VALUTAZIONE"},
                        id: "visualizzaValutazioneProfessionaleMia"
                    })
                }
            });
        }


        if (!Utils.isStringEmptyOrNullOrUndefined(richiesta.timestampConferma)) {
            listaAzioni.push({
                label: "Visualizza pdf Valutazione multidimensionale",
                pdfObject: {idRichiesta: richiesta.id, step: AuthUtils.hasUtenteRuoloMedicoRichiedente() || AuthUtils.hasUtenteRuoloFormalizzatore() ? "STEP_CDA2_VALUTAZIONE_MEDICO_RICHIEDENTE" : "STEP_CDA2_INVIO_VALUTAZIONE"},
                id: "visualizzaValutazioneMultidimensionale"
            })
        }

        if (!Utils.isStringEmptyOrNullOrUndefined(richiesta.timestampFormalizzazione)) {
            listaAzioni.push({
                label: "Visualizza pdf Esito formalizzazione",
                pdfObject: {idRichiesta: richiesta.id, step: "STEP_CDA2_CONFERMA_SETTING"},
                id: "visualizzaEsitoFormalizzazione"
            })
        }

        if (AuthUtils.hasUtenteRuoloMedicoRichiedente()
            && richiesta.richiedente.codiceFiscale === SessioneUtente.getInstance().idUtente) {
            listaAzioni.push({
                label: "Copia richiesta",
                to: {pathname: "", uuidRichiesta: richiesta.id},
                id: "copiaRichiesta",
                modalToOpen: "popupConfermaCopiaRichiesta"
            });
        }

        listaAzioni = [
            visualizzaRichiesta,
            ...listaAzioni,
            visualizzaScaleQuestionari,
            visualizzaMaggioriDettagli,
            apriCartella
        ];

        return listaAzioni;
    }

    static getInfoFirma(richiesta) {
        if (enumFirma.label?.[richiesta?.firma?.stato] === enumFirma.label.FIRMATA) {
            const professionista = richiesta.firma.professionista;
            const nominativo = professionista.codiceFiscale === SessioneUtente.getInstance().idUtente ? "Io" : professionista.nome + " " + professionista.cognome;
            return nominativo + " (" + AuthUtils.getRoleDescriptionFromCode(professionista.ruolo) + ")";
        } else {
            return "In attesa di firma";
        }
    }

    static getCureDomiciliari(richiesta) {
        const formalizzazione = richiesta?.formalizzazione;

        if (formalizzazione && richiesta.stato === enumRichiesta.stato.ACCETTATA) {
            const dataInizioProgettoAssistenziale = moment(formalizzazione?.dataInizioProgettoAssistenziale);
            const dataFineProgettoAssistenziale = moment(formalizzazione?.dataFineProgettoAssistenziale);
            const dataOdierna = moment();

            if (dataInizioProgettoAssistenziale.isSameOrBefore(dataOdierna) && dataFineProgettoAssistenziale.isSameOrBefore(dataOdierna)) {
                return "Concluse";
            } else if (dataInizioProgettoAssistenziale.isSameOrBefore(dataOdierna)) {
                return "Attive";
            } else if (dataInizioProgettoAssistenziale.isAfter(dataOdierna)) {
                return "Non ancora attive";
            } else {
                return "-";
            }
        } else {
            return "-";
        }
    }

}
