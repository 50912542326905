import AccordionFirstLevel from "../../accordionAvvioRichiesta/AccordionFirstLevel";
import PropTypes from "prop-types";
import AccordionAvvioRichiesta from "../../accordionAvvioRichiesta/AccordionAvvioRichiesta";
import React from "react";
import SessionRequest from "../../../utils/SessionRequest";
import * as _ from "lodash";
import AccordionHelper from "../../../service/AccordionHelper";
import Utils from "../../../utils/Utils";
import StatoNutrizionale from "../../anamnesiFisiologica/statoNutrizionale/StatoNutrizionale";
import VersionedAccordion from "../../VersionedAccordion";


const propTypes = {
    datiAssorbimento: PropTypes.any,
    richiesta: PropTypes.any,
    salvaBozza: PropTypes.func,
    onChangeDatiAssorbimento: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    onChangeObbligatorieta: PropTypes.func,
    addInformazioniAggiuntive: PropTypes.func,
    accordionReadOnly: PropTypes.bool
}

export default class Assorbimento extends AccordionFirstLevel {

    state = {
        campiObbligatoriMancanti: {
            sottosezioneStatoNutrizionale:0
        },
        accordion: {
            openAccordionStatoNutrizionale: true
        }
    }

    constructor(props) {
        super(props);

        const sessionObj = SessionRequest.getSessionObjectFor(props.richiesta);
        this.state.data = _.cloneDeep(sessionObj.anamnesiFisiologica);
    }

    onClickAccordion = (field, value) => {
        let accordion = _.cloneDeep(this.state.accordion);
        accordion[field] = value;

        if (accordion[field]) {
            this.props.salvaBozza();
            AccordionHelper.setLastOpenedSubAccordion(field);
            this.setState({accordion: accordion});
        }
    }

    onChange = (field, value) => {
        const data = _.cloneDeep(this.state.data);
        data[field] = value;
        this.setState(data);
    }

    hideOptionalAccordion(accordion) {
        let accordions = _.cloneDeep(this.state.visualizzaAccordion);
        accordions[accordion] = false;
        this.setState({visualizzaAccordion: accordions});
    }

    handleChangeRequiredFieldsNumber = (field, value) => {
        let campiObbligatoriMancanti = this.state.campiObbligatoriMancanti;
        campiObbligatoriMancanti[field] = value;
        this.setState({campiObbligatoriMancanti: campiObbligatoriMancanti}, () => {
            this.props.onChangeObbligatorieta("assorbimento", Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti));
        });
    }

    renderBody = () => {
        return (
            <>
                <VersionedAccordion accordion={StatoNutrizionale}
                    statoNutrizionale={this.props.datiAnamnesiFisiologica.statoNutrizionale}
                    onChangeDatiStatoNutrizionale={this.props.onChangeDatiAnamnesiFisiologica}
                    openAccordion={this.state.accordion.openAccordionStatoNutrizionale}
                    onClickAccordion={this.onClickAccordion}
                    field={"openAccordionStatoNutrizionale"}
                    pageState={this.props.pageState}
                    richiesta={this.props.richiesta}
                    onHide={() => this.hideOptionalAccordion('visualizzaAccordionStatoNutrizionale')}
                    salvaBozza={this.props.salvaBozza}
                    isOptional={false}
                    onChangeRequiredFieldsNumber={this.handleChangeRequiredFieldsNumber}
                    accordionReadOnly={this.props.accordionReadOnly}
                />
            </>
        );
    }

    render() {
        return (
            <AccordionAvvioRichiesta
                idAccordion={"assorbimentoInfermiere"}
                title={"Assorbimento"}
                openAccordion={this.props.openAccordion}
                onClickAccordion={this.props.onClickAccordion}
                field={this.props.field}
                pageState={this.props.pageState}
                numberOfRequiredFieldsMissing={Utils.sumRequiredFieldsMissing(this.state.campiObbligatoriMancanti)}
                addInformazioniAggiuntive={this.props.addInformazioniAggiuntive}
                accordionReadOnly={this.props.accordionReadOnly}
            >{this.renderBody()}</AccordionAvvioRichiesta>
        );
    }
}

Assorbimento.propTypes = propTypes;
