import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import OggettiUtili from "../../../utils/dataset/OggettiUtili.json";
import AccordionSecondLevel from "../../accordionSezioneRichiesta/AccordionSecondLevel";
import {
    FieldsRow,
    isFieldIdWriteable,
    RoleBasedCalendar,
    RoleBasedInput,
    RoleBasedSelect
} from "../../roleBasedComponents/RoleBasedComponents";
import SessionRequest from "../../../utils/SessionRequest";
import CondizioneRilevataSection from "../../proposteAttivitaSuggerimenti/CondizioneRilevataSection";
import AccordionSezioneRichiesta from "../../accordionSezioneRichiesta/AccordionSezioneRichiesta";
import {RoleBasedContext} from "../../../utils/RoleBasedContext";
import {contaNumeroCampiObbligatoriInPropostaAttivitaEsugg} from "../../proposteAttivitaSuggerimenti/ProposteAttivitaSuggerimentiList";

const propTypes = {
    datiCopingTolleranzaStress: PropTypes.any,
    onChangedatiCopingTolleranzaStress: PropTypes.func,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.func,
    field: PropTypes.any,
    pageState: PropTypes.string,
    isOptional: PropTypes.bool,
    forceReadOnly: PropTypes.bool,
    hideUnsetValues: PropTypes.bool,
    disableDoubleInput: PropTypes.bool,
    onChangeRequiredFieldsNumber: PropTypes.func,
    accordionReadOnly: PropTypes.bool,
    sezioneDatiRichiesta: PropTypes.bool
}

export default class CopingTolleranzaStress extends AccordionSecondLevel {
    state = {
        datiCopingTolleranzaStress: _.cloneDeep(this.props.datiCopingTolleranzaStress)
    }

    componentDidMount() {
        this.inizializzaForm();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.datiCopingTolleranzaStress, this.props.datiCopingTolleranzaStress))
            this.forceUpdate(() => this.inizializzaForm());
    }

    inizializzaForm = () => {
        const datiCopingTolleranzaStress = _.cloneDeep(this.props.datiCopingTolleranzaStress)
        this.setState({datiCopingTolleranzaStress}, () => this.validazioneDati());
    }

    validazioneDati = () => {
        const datiCopingTolleranzaStress = _.cloneDeep(this.state.datiCopingTolleranzaStress)
        this.props.onChangedatiCopingTolleranzaStress("copingTolleranzaStress", datiCopingTolleranzaStress);
        
        let campiObbligatoriRimanenti = 0;
        campiObbligatoriRimanenti += contaNumeroCampiObbligatoriInPropostaAttivitaEsugg(datiCopingTolleranzaStress?.propostaAttivitaSuggerimenti)
        this.props.onChangeRequiredFieldsNumber("sottosezioneCopingTolleranzaStress", campiObbligatoriRimanenti);

    }

    handleAggiornaForm = (field, elem) => {
        this.setState(({datiCopingTolleranzaStress}) => {
            datiCopingTolleranzaStress[field] = elem;

            if (!datiCopingTolleranzaStress.isPreoccupazione) {
                datiCopingTolleranzaStress.descrizionePreoccupazione = null;
            }

            if (!datiCopingTolleranzaStress.isRispostePostTraumatiche) {
                datiCopingTolleranzaStress.descrizioneRispostePostTraumatice = null;
            }

            return {datiCopingTolleranzaStress};
        }, this.validazioneDati);

    }

    onHide = () => {
        const datiCopingTolleranzaStress = _.cloneDeep(SessionRequest.getInitObject('anamnesiFisiologica.copingTolleranzaStress'));
        this.setState({datiCopingTolleranzaStress: datiCopingTolleranzaStress},
            () => {
                this.props.onChangedatiCopingTolleranzaStress("copingTolleranzaStress", datiCopingTolleranzaStress)
                this.props.onHide();
            });
    }

    renderBody = () => {
        let datiCopingTolleranzaStress = this.state.datiCopingTolleranzaStress;
        return (
            <>
                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_PERC_DI_SE_ALT_AUTOPERC.COPINGTOLLERANZAALLOSTRESS.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_PERC_DI_SE_ALT_AUTOPERC.VALUTAZIONECOPINGTOLLERANZAALLOSTRESS.COPINGTOLLERANZAALLOSTRESS'}
                        pageState={this.props.pageState}
                        id={"selectPercezione"}
                        options={OggettiUtili.percezione}
                        value={datiCopingTolleranzaStress.percezione}
                        onChange={(elem) => this.handleAggiornaForm("percezione", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: percezione non esistente"}
                        fieldLabel={"Percezione di sè - Alterazioni inerenti l'autopercezione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"percezione"}
                        handleOnlyValue={true}
                    />
                    <RoleBasedSelect
                        fieldId={'M_RISP_COPING.COPINGTOLLERANZAALLOSTRESS.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_RISP_COPING.VALUTAZIONECOPINGTOLLERANZAALLOSTRESS.COPINGTOLLERANZAALLOSTRESS'}
                        pageState={this.props.pageState}
                        id={"selectRisposteCoping"}
                        options={OggettiUtili.percezione}
                        value={datiCopingTolleranzaStress.risposteCoping}
                        onChange={(elem) => this.handleAggiornaForm("risposteCoping", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: risposte di coping non esistente"}
                        fieldLabel={"Risposte di Coping - Alterazioni inerenti le strategie di coping"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"risposteCoping"}
                        handleOnlyValue={true}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_PREOC_SITUAZ_MALAT.COPINGTOLLERANZAALLOSTRESS.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_PREOC_SITUAZ_MALAT.VALUTAZIONECOPINGTOLLERANZAALLOSTRESS.COPINGTOLLERANZAALLOSTRESS'}
                        pageState={this.props.pageState}
                        id={"radioButtonPreoccupazioni"}
                        classNamePrefix={"inputSelectPreoccupazioni"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiCopingTolleranzaStress.isPreoccupazione}
                        onChange={(elem) => this.handleAggiornaForm("isPreoccupazione", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Preoccupazioni non esistente"}
                        fieldLabel={"Preoccupazioni sulla situazione o sulla malattia"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"isPreoccupazione"}
                        handleOnlyValue={true}
                    />
                    {this.state.datiCopingTolleranzaStress.isPreoccupazione &&
                        <RoleBasedInput
                            fieldId={'M_DESCR_PREOC.COPINGTOLLERANZAALLOSTRESS.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_DESCR_PREOC.VALUTAZIONECOPINGTOLLERANZAALLOSTRESS.COPINGTOLLERANZAALLOSTRESS'}
                            pageState={this.props.pageState}
                            value={datiCopingTolleranzaStress.descrizionePreoccupazione}
                            placeholder="Inserisci descrizione delle preoccupazioni"
                            field="descrizionePreoccupazione"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id="descrizionePreoccupazione"
                            fieldLabel={"Descrizione delle preoccupazioni"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />}
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_ANSIA.COPINGTOLLERANZAALLOSTRESS.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_ANSIA.VALUTAZIONECOPINGTOLLERANZAALLOSTRESS.COPINGTOLLERANZAALLOSTRESS'}
                        pageState={this.props.pageState}
                        id={"selectPunteggioAnsia"}
                        options={OggettiUtili.punteggioAnsia}
                        value={datiCopingTolleranzaStress.punteggioAnsia}
                        onChange={(elem) => this.handleAggiornaForm("punteggioAnsia", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Punteggio ansia non esistente"}
                        fieldLabel={"Ansia (Punteggio Test ESASr)"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"punteggioAnsia"}
                        handleOnlyValue={true}
                    />
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedSelect
                        fieldId={'M_RISP_POST_TRAUM_PERD_CAMB.COPINGTOLLERANZAALLOSTRESS.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_RISP_POST_TRAUM_PERD_CAMB.VALUTAZIONECOPINGTOLLERANZAALLOSTRESS.COPINGTOLLERANZAALLOSTRESS'}
                        pageState={this.props.pageState}
                        id={"SelectRispostePostTraumatiche"}
                        classNamePrefix={"inputSelectRispostePostTraumatiche"}
                        options={OggettiUtili.rispostaBreve}
                        value={datiCopingTolleranzaStress.isRispostePostTraumatiche}
                        onChange={(elem) => this.handleAggiornaForm("isRispostePostTraumatiche", elem)}
                        isSearchable={false}
                        noOptionsMessage={() => "Errore: Risposte Post Traumatiche non esistente"}
                        fieldLabel={"Risposte post-traumatiche - Perdite / cambiamenti importanti nell'ultimo anno"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                        field={"isRispostePostTraumatiche"}
                        handleOnlyValue={true}
                    />
                    {this.state.datiCopingTolleranzaStress.isRispostePostTraumatiche &&
                        <RoleBasedInput
                            fieldId={'M_DESCR_RISP_POST_TRAUM.COPINGTOLLERANZAALLOSTRESS.ANAMNESIFISIOLOGICA'}
                            infermiereFieldId={'I_DESCR_RISP_POST_TRAUM.VALUTAZIONECOPINGTOLLERANZAALLOSTRESS.COPINGTOLLERANZAALLOSTRESS'}
                            pageState={this.props.pageState}
                            value={datiCopingTolleranzaStress.descrizioneRispostePostTraumatice}
                            placeholder="Inserisci descrizione"
                            field="descrizioneRispostePostTraumatice"
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                            id="descrizioneRispostePostTraumatice"
                            fieldLabel={"Descrizione"}
                            forceReadOnly={this.props.forceReadOnly}
                            fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                            hideUnsetValues={this.props.hideUnsetValues}
                            disableDoubleInput={this.props.disableDoubleInput}
                        />}
                </FieldsRow>

                <FieldsRow>
                    <RoleBasedInput
                        fieldId={'M_VIS_FUTURO.COPINGTOLLERANZAALLOSTRESS.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_VIS_FUTURO.VALUTAZIONECOPINGTOLLERANZAALLOSTRESS.COPINGTOLLERANZAALLOSTRESS'}
                        pageState={this.props.pageState}
                        type={"number"}
                        min={0}
                        max={10}
                        value={datiCopingTolleranzaStress.visioneFuturo}
                        placeholder={"Da 1 (pessimistica) a 10 (molto ottimistica)"}
                        field={"visioneFuturo"}
                        onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.value)}
                        id={"visioneFuturo"}
                        fieldLabel={"Visione del futuro"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>

                <CondizioneRilevataSection
                    ambito={'COPINGTOLLERANZAALLOSTRESS'}
                    parentFieldId={'COPINGTOLLERANZAALLOSTRESS.ANAMNESIFISIOLOGICA'}
                    infParentFieldId={'VALUTAZIONECOPINGTOLLERANZAALLOSTRESS.COPINGTOLLERANZAALLOSTRESS'}
                    appendRolePrefix={true}
                    pageState={this.props.pageState}
                    onChangeCondizioneRilevata={(elem) => this.handleAggiornaForm("condizioneRilevata", elem)}
                    valueCondizioneRilevata={datiCopingTolleranzaStress.condizioneRilevata}
                    onChangeDaAttenzionare={(elem) => this.handleAggiornaForm("daAttenzionare", elem)}
                    valueDaAttenzionare={datiCopingTolleranzaStress.daAttenzionare}
                    showSuggerimenti={true}
                    onChangeSuggerimenti={this.handleAggiornaForm}
                    valueSuggerimenti={datiCopingTolleranzaStress.propostaAttivitaSuggerimenti}
                    forceReadOnly={this.props.forceReadOnly}
                    fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                    hideUnsetValues={this.props.hideUnsetValues}
                    disableDoubleInput={this.props.disableDoubleInput}
                    showAutonomia={true}
                    onChangeAutonomiaPaziente={(elem) => this.handleAggiornaForm("autonomiaGestionePaziente", elem)}
                    valueAutonomiaPaziente={datiCopingTolleranzaStress.autonomiaGestionePaziente}
                    onChangeAutonomiaCaregiver={(elem) => this.handleAggiornaForm("autonomiaGestioneCaregiver", elem)}
                    valueAutonomiaCaregiver={datiCopingTolleranzaStress.autonomiaGestioneCaregiver}
                />

                <FieldsRow>
                    <RoleBasedCalendar
                        fieldId={'M_DATA_VAL.COPINGTOLLERANZAALLOSTRESS.ANAMNESIFISIOLOGICA'}
                        infermiereFieldId={'I_DATA_VAL.VALUTAZIONECOPINGTOLLERANZAALLOSTRESS.COPINGTOLLERANZAALLOSTRESS'}
                        pageState={this.props.pageState}
                        id={"copingTolleranzaStressDataValutazione"}
                        value={datiCopingTolleranzaStress.dataValutazione}
                        onChange={(elem) => this.handleAggiornaForm("dataValutazione", elem.target.value)}
                        errorMessage={"Data valutazione non valida"}
                        fieldLabel={"Data Valutazione"}
                        field={"dataValutazione"}
                        forceReadOnly={this.props.forceReadOnly}
                        fieldInAccordionReadOnly={!!this.props.accordionReadOnly}
                        hideUnsetValues={this.props.hideUnsetValues}
                        disableDoubleInput={this.props.disableDoubleInput}
                    />
                </FieldsRow>
            </>
        );
    }

    render() {
        return <AccordionSezioneRichiesta
            idAccordion={"copingTolleranzaStress"}
            title={"Coping/Tolleranza allo stress"}
            required={false}
            openAccordion={this.props.openAccordion}
            onClickAccordion={this.props.onClickAccordion}
            field={this.props.field}
            pageState={this.props.pageState}
            onHide={this.onHide}
            isOptional={this.props.isOptional && isFieldIdWriteable('M_PERC_DI_SE_ALT_AUTOPERC.COPINGTOLLERANZAALLOSTRESS.ANAMNESIFISIOLOGICA', this.props.pageState, this.context.forceUserRole)}
            accordionReadOnly={this.props.accordionReadOnly}
            sezioneDatiRichiesta={this.props.sezioneDatiRichiesta}
        >{this.renderBody()}</AccordionSezioneRichiesta>
    }
}
CopingTolleranzaStress.propTypes = propTypes;

CopingTolleranzaStress.defaultProps = {
    isOptional: true,
    forceReadOnly: false,
    hideUnsetValues: false,
    disableDoubleInput: false
}

CopingTolleranzaStress.contextType = RoleBasedContext;
