import serviceConfig from "../config/serviceConfig";
import FiltriUtils from "../utils/FiltriUtils";
import { catchError, finalize, map } from "rxjs/operators";
import { from, of } from "rxjs";
import auth from "../utils/AthnAuth";
import { mostraSpinner, nascondiSpinner } from "../App";
import Utils from "../utils/Utils";
import ButtonHelper from "./ButtonHelper";
import { SessioneUtente } from "web-client-archetype";
import * as _ from "lodash";
import AccordionHelper from "./AccordionHelper";
import AuthUtils from "../utils/AuthUtils";
import { isSomeFieldIdWriteable } from "../components/roleBasedComponents/RoleBasedComponents";
import SessionRequest from "../utils/SessionRequest";
import { v4 as uuid } from "uuid";
import AnagrafeService from "./AnagrafeService";
import enumRichiesta from "../enum/enumRichiesta.json";
import config from "../config/globalConfig"


export default class RichiestaADIService {
    static urlRichieste = serviceConfig['ms_richiesta'];

    static richiestaRicercata = null;

    static versioneTipologiaClassica = config['versione-tipologia-classica'];
    static versioneTipologiaSegnalazione = config['versione-tipologia-segnalazione'];
    static versioneTipologiaCurePalliative = config['versione-tipologia-cure-palliative'];

    static salvaVariazioniPreviousObject = null;
    static salvaVariazioniCurrentObject = null;
    static salvaVariazioniErrorCode = null;

    static salvaFormalizzazionePreviousObject = null;
    static salvaFormalizzazioneCurrentObject = null;
    static salvaFormalizzazioneErrorCode = null;

    static chiudiPreDialogoPreviousObject = null;
    static chiudiPreDialogoCurrentObject = null;
    static chiudiPreDialogoErrorCode = null;

    static getRichiesteHome(size, page, filtri, sort, direction, defaultParams, filtriRicercaRapidi = null) {
        let instance = auth._httpClient;

        const areFiltriRicercaRapidiValued = !Utils.isObjectEmpty(filtriRicercaRapidi) && !Utils.isObjectNull(filtriRicercaRapidi);

        let params = defaultParams && !areFiltriRicercaRapidiValued ? defaultParams : {};
        params.vista = "sintesi";
        params.numeroPagina = page;
        params.numeroElementi = size;

        if (filtri && (filtri.filtriAssistito || filtri.filtriRichiesta) &&
            (Object.values(filtri.filtriAssistito).some(value => value !== undefined && value !== null && value !== "") ||
                Object.values(filtri.filtriRichiesta).some(value => value !== undefined && value !== null && value !== ""))) {

            FiltriUtils.setFiltriAssistito(filtri.filtriAssistito.nome, filtri.filtriAssistito.cognome, filtri.filtriAssistito.id_persona, params);

            FiltriUtils.setFiltriRichiesta(filtri.filtriRichiesta, params);

            if (filtri.ordinamentoFormatoTimestamp) {

                FiltriUtils.addParams(params, "ordinamentoFormatoTimestamp", filtri.ordinamentoFormatoTimestamp);
            }

            if (filtri.profilazione !== undefined) {

                FiltriUtils.addParams(params, "profilazione", filtri.profilazione);
            }
        }

        if (!Utils.isStringEmptyOrNullOrUndefined(sort) && !Utils.isStringEmptyOrNullOrUndefined(direction)) {
            params.ordinamento = FiltriUtils.getOrdinamentoHomepage(sort, direction);
        }

        if (areFiltriRicercaRapidiValued) {
            const additionalFilters = Object.keys(filtriRicercaRapidi)
                .filter(k => k !== "priorita" && !!filtriRicercaRapidi[k])
                .map(k => filtriRicercaRapidi[k])
                .join(',');
            if (additionalFilters) {
                params.filtriRichiesta = params.filtriRichiesta ? (params.filtriRichiesta + "," + additionalFilters) : additionalFilters;
            }
            if (Object.prototype.hasOwnProperty.call(params, "ordinamento")) delete params.ordinamento;
            if (!params.filtriRichiesta.includes('timestampEsito')) params.filtriRichiesta += ',timestampEsito::null';
        }

        let obs = from(instance.get(this.urlRichieste.home, { params: params }));
        return obs.pipe(map(response => {
            if (!!response?.data && !!response?.data?.elementi) {
                SessionRequest.setIdRichiedente();
            }
            return response;
        }), catchError((err) => {
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static creaRichiesta(richiesta) {

        switch (richiesta.tipologia) {
            case enumRichiesta.tipologia.CLASSICA:
                richiesta.versione = this.versioneTipologiaClassica;
                break;
            case enumRichiesta.tipologia.SEGNALAZIONE:
                richiesta.versione = this.versioneTipologiaSegnalazione;
                break;
            case enumRichiesta.tipologia.PALLIATIVA:
                richiesta.versione = this.versioneTipologiaCurePalliative;
                break;
        }

        let instance = auth._httpClient;
        // aggiungo saml token
        richiesta.samlToken = SessioneUtente.getInstance().samlToken;
        let obs = from(instance.post(this.urlRichieste.home, richiesta));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static aggiornaRichiestaDaFser(id) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.aggiornaRichiestaDaFser.replace("{id}", id), { samlToken: SessioneUtente.getInstance().samlToken }));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static getMediciRichiedenti(params) {
        let instance = auth._httpClient;
        let obs = from(instance.get(this.urlRichieste.medici, { params: params ? params : null }));
        return obs.pipe(map(response => {
            return response;
        }), catchError((err) => {
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static getMediciFormalizzatori(params) {
        let instance = auth._httpClient;
        let obs = from(instance.get(this.urlRichieste.formalizzatori, { params: params ? params : null }));
        return obs.pipe(map(response => {
            return response;
        }), catchError((err) => {
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }


    static getMediciFirmatari(params) {
        let instance = auth._httpClient;
        let obs = from(instance.get(this.urlRichieste.firmatari, { params: params ? params : null }));
        return obs.pipe(map(response => {
            return response;
        }), catchError((err) => {
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static letturaRichiesta(id, escludiVariazioniInfermiere = false) {
        let instance = auth._httpClient;
        const config = escludiVariazioniInfermiere ? { params: { escludiVariazioniInfermiere } } : {};

        let obs = from(instance.get(this.urlRichieste.getRichiesta.replace("{id}", id), config));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            }));
    }

    static annullaRichiesta(id, body, params) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.annullaRichiesta.replace("{id}", id), { samlToken: SessioneUtente.getInstance().samlToken, motivoAnnullamento: body }, { params: params }));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.data?.errors?.[0]?.message });
            }));
    }

    static presaInCarico(id) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.presaInCarico.replace("{id}", id), { samlToken: SessioneUtente.getInstance().samlToken }));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.data?.errors?.[0]?.message });
            }));
    }

    static salvaBozza(id, body, isButtonSalvaBozzaClicked, canAddInformazioniAggiuntive = false) {
        return isButtonSalvaBozzaClicked
            ? this.salvaBozzaSync(id, body, canAddInformazioniAggiuntive)
            : this.salvaBozzaAsync(id, body);
    }

    static salvaBozzaSync(id, body, canAddInformazioniAggiuntive) {
        let instance = auth._httpClient;

        if (!canAddInformazioniAggiuntive) mostraSpinner();

        let obs = from(instance.post(this.urlRichieste.salvaBozza.replace("{id}", id), body));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            }),
            finalize(() => {
                ButtonHelper.setSalvaButtonFlag(false);
                if (!canAddInformazioniAggiuntive) nascondiSpinner();
            })
        );
    }

    static salvaBozzaAsync(id, body) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.salvaBozzaAsync.replace("{id}", id), body));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            })
        );
    }

    static salvaVariazioniInBozza(id, richiestaAggiornata, isButtonSalvaBozzaClicked, canAddInformazioniAggiuntive = false) {
        const regexTime = /timestamp(\w*|\W*)":"(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})[Z]*/g;
        const body = {
            richiestaLeft: JSON.stringify(RichiestaADIService.richiestaRicercata).replace(regexTime, g1 => g1.endsWith('Z') ? g1 : g1 + 'Z'),
            richiestaRight: JSON.stringify(richiestaAggiornata).replace(regexTime, g1 => g1.endsWith('Z') ? g1 : g1 + 'Z'),
            mappaDecodedValue: AccordionHelper.mappaVariazionePathDecodedValue,
            campiObbligatori: AccordionHelper.processStrutturaDatiObbligatori()
        };
        return isButtonSalvaBozzaClicked
            ? this.salvaVariazioniInBozzaSync(id, body, richiestaAggiornata, canAddInformazioniAggiuntive)
            : this.salvaVariazioniInBozzaAsync(id, body, richiestaAggiornata);
    }

    static salvaVariazioniInBozzaSync(id, body, richiestaAggiornata, canAddInformazioniAggiuntive) {
        let instance = auth._httpClient;

        if (!canAddInformazioniAggiuntive) mostraSpinner();

        let obs = from(instance.post(this.urlRichieste.salvaVariazioniInBozza.replace("{id}", id), body));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                RichiestaADIService.salvaVariazioniErrorCode = err.code;
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            }),
            finalize(() => {
                ButtonHelper.setSalvaButtonFlag(false);
                if (RichiestaADIService.salvaVariazioniErrorCode == null) {
                    RichiestaADIService.richiestaRicercata = _.cloneDeep(richiestaAggiornata);
                }
                RichiestaADIService.salvaVariazioniErrorCode = null;
                if (!canAddInformazioniAggiuntive) nascondiSpinner();
            })
        );
    }

    static salvaVariazioniInBozzaAsync(id, body, richiestaAggiornata) {
        let instance = auth._httpClient;

        let obs = of(null);
        RichiestaADIService.salvaVariazioniCurrentObject = {
            richiestaAggiornata: richiestaAggiornata,
            body: body
        }

        const currentBody = _.cloneDeep(RichiestaADIService.salvaVariazioniCurrentObject);
        if (currentBody != null
            && !_.isEqual(RichiestaADIService.salvaVariazioniPreviousObject?.richiestaAggiornata, currentBody?.richiestaAggiornata)
            && RichiestaADIService.salvaVariazioniErrorCode == null) {
            RichiestaADIService.salvaVariazioniPreviousObject = {
                richiestaAggiornata: currentBody.richiestaAggiornata,
                body: currentBody.body
            }
            obs = from(instance.post(this.urlRichieste.salvaVariazioniInBozzaAsync.replace("{id}", id), currentBody.body))
                .pipe(
                    map(response => response),
                    catchError(err => {
                        RichiestaADIService.salvaVariazioniErrorCode = err.code;
                        return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
                    }),
                    finalize(() => {
                        if (RichiestaADIService.salvaVariazioniErrorCode == null) {
                            RichiestaADIService.richiestaRicercata = _.cloneDeep(currentBody.richiestaAggiornata);
                        } else {
                            RichiestaADIService.salvaVariazioniPreviousObject = null;
                        }
                        RichiestaADIService.salvaVariazioniErrorCode = null;
                    })
                );
        }

        return obs;
    }

    static storicoListaCampi(id, nomiCampi) {
        let instance = auth._httpClient;
        const config = { headers: { 'Content-Type': 'text/plain' } };

        let obs = from(instance.post(this.urlRichieste.storicoListaCampi.replace("{id}", id), JSON.stringify(nomiCampi), config));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            }));
    }

    static inoltraRichiesta(id) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.inoltraRichiesta.replace("{id}", id), { samlToken: SessioneUtente.getInstance().samlToken }));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.data?.errors?.[0]?.message });
            }));
    }

    static copiaRichiesta(id) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.copiaRichiesta.replace("{id}", id)));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.data?.errors?.[0]?.message });
            }));
    }

    static creaValutatoreMock(id, body) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.creaValutatore.replace("{id}", id), body));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            }));
    }

    static salvaFormalizzazione(id, formalizzazioneAggiornata, isButtonSalvaFormalizzazioneClicked) {
        return isButtonSalvaFormalizzazioneClicked
            ? this.salvaFormalizzazioneSync(id, formalizzazioneAggiornata)
            : this.salvaFormalizzazioneAsync(id, formalizzazioneAggiornata);
    }

    static salvaFormalizzazioneSync(id, formalizzazioneAggiornata) {
        mostraSpinner();

        let instance = auth._httpClient;

        formalizzazioneAggiornata.samlToken = SessioneUtente.getInstance().samlToken;
        let obs = from(instance.post(this.urlRichieste.salvaFormalizzazione.replace("{id}", id), formalizzazioneAggiornata));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                RichiestaADIService.salvaFormalizzazioneErrorCode = err.code;
                return of({
                    status: err.response?.status,
                    data: err.response?.data,
                    msg: "ERRORE",
                    msgCode: err.response?.data?.errors?.[0]?.message
                });
            }),
            finalize(() => {
                if (RichiestaADIService.salvaFormalizzazioneErrorCode == null
                    && !Utils.isObjectNull(RichiestaADIService.richiestaRicercata?.formalizzazione)) {

                    let cloneFormalizzazione = _.cloneDeep(formalizzazioneAggiornata);
                    delete cloneFormalizzazione.samlToken;
                    RichiestaADIService.richiestaRicercata.formalizzazione = cloneFormalizzazione;
                }
                RichiestaADIService.salvaFormalizzazioneErrorCode = null;
                nascondiSpinner();
            })
        );
    }

    static salvaFormalizzazioneAsync(id, formalizzazioneAggiornata) {
        let instance = auth._httpClient;
        let obs = of(null);

        RichiestaADIService.salvaFormalizzazioneCurrentObject = formalizzazioneAggiornata;

        const currentBody = _.cloneDeep(RichiestaADIService.salvaFormalizzazioneCurrentObject);
        if (currentBody != null && !_.isEqual(RichiestaADIService.salvaFormalizzazionePreviousObject, currentBody)
            && RichiestaADIService.salvaFormalizzazioneErrorCode == null) {

            RichiestaADIService.salvaFormalizzazionePreviousObject = _.cloneDeep(currentBody);

            currentBody.samlToken = SessioneUtente.getInstance().samlToken;
            obs = from(instance.post(this.urlRichieste.salvaFormalizzazioneAsync.replace("{id}", id), currentBody))
                .pipe(
                    map(response => response),
                    catchError(err => {
                        RichiestaADIService.salvaFormalizzazioneErrorCode = err.code;
                        return of({
                            status: err.response?.status,
                            data: err.response?.data,
                            msg: "ERRORE",
                            msgCode: err.response?.data?.errors?.[0]?.message
                        });
                    }),
                    finalize(() => {
                        if (RichiestaADIService.salvaFormalizzazioneErrorCode == null
                            && !Utils.isObjectNull(RichiestaADIService.richiestaRicercata?.formalizzazione)) {

                            let cloneFormalizzazione = _.cloneDeep(currentBody);
                            delete cloneFormalizzazione.samlToken;
                            RichiestaADIService.richiestaRicercata.formalizzazione = cloneFormalizzazione;
                        } else {
                            RichiestaADIService.salvaFormalizzazionePreviousObject = null;
                        }
                        RichiestaADIService.salvaFormalizzazioneErrorCode = null;
                    })
                );
        }

        return obs;
    }

    static chiudiPreDialogo(id, chiusuraPreDialogoAggiornata, isButtonChiudiPreDialogoClicked) {
        return isButtonChiudiPreDialogoClicked
            ? this.chiudiPreDialogoSync(id, chiusuraPreDialogoAggiornata)
            : this.chiudiPreDialogoAsync(id, chiusuraPreDialogoAggiornata);
    }

    static chiudiPreDialogoSync(id, chiusuraPreDialogoAggiornata) {
        mostraSpinner();

        let instance = auth._httpClient;

        chiusuraPreDialogoAggiornata.samlToken = SessioneUtente.getInstance().samlToken;
        let obs = from(instance.post(this.urlRichieste.chiudiPreDialogo.replace("{id}", id), chiusuraPreDialogoAggiornata));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                RichiestaADIService.chiudiPreDialogoErrorCode = err.code;
                return of({
                    status: err.response?.status,
                    data: err.response?.data,
                    msg: "ERRORE",
                    msgCode: err.response?.data?.errors?.[0]?.message
                });
            }),
            finalize(() => {
                ButtonHelper.setSalvaButtonFlag(false);
                if (RichiestaADIService.chiudiPreDialogoErrorCode == null
                    && !Utils.isObjectNull(RichiestaADIService.richiestaRicercata)) {

                    let cloneChiusuraPreDialogo = _.cloneDeep(chiusuraPreDialogoAggiornata);
                    delete cloneChiusuraPreDialogo.samlToken;
                    RichiestaADIService.richiestaRicercata = JSON.parse(cloneChiusuraPreDialogo.richiestaRight);
                }
                RichiestaADIService.chiudiPreDialogoErrorCode = null;
                nascondiSpinner();
            })
        );
    }

    static chiudiPreDialogoAsync(id, chiusuraPreDialogoAggiornata) {
        let instance = auth._httpClient;
        let obs = of(null);

        RichiestaADIService.chiudiPreDialogoCurrentObject = chiusuraPreDialogoAggiornata;

        const currentBody = _.cloneDeep(RichiestaADIService.chiudiPreDialogoCurrentObject);
        if (currentBody != null && !_.isEqual(RichiestaADIService.chiudiPreDialogoPreviousObject, currentBody)
            && RichiestaADIService.chiudiPreDialogoErrorCode == null) {

            RichiestaADIService.chiudiPreDialogoPreviousObject = _.cloneDeep(currentBody);

            currentBody.samlToken = SessioneUtente.getInstance().samlToken;
            obs = from(instance.post(this.urlRichieste.chiudiPreDialogoAsync.replace("{id}", id), currentBody))
                .pipe(
                    map(response => response),
                    catchError(err => {
                        RichiestaADIService.chiudiPreDialogoErrorCode = err.code;
                        return of({
                            status: err.response?.status,
                            data: err.response?.data,
                            msg: "ERRORE",
                            msgCode: err.response?.data?.errors?.[0]?.message
                        });
                    }),
                    finalize(() => {
                        if (RichiestaADIService.chiudiPreDialogoErrorCode == null
                            && !Utils.isObjectNull(RichiestaADIService.richiestaRicercata)) {

                            let cloneChiusuraPreDialogo = _.cloneDeep(currentBody);
                            delete cloneChiusuraPreDialogo.samlToken;
                            RichiestaADIService.richiestaRicercata = JSON.parse(cloneChiusuraPreDialogo.richiestaRight);
                        } else {
                            RichiestaADIService.chiudiPreDialogoPreviousObject = null;
                        }
                        RichiestaADIService.chiudiPreDialogoErrorCode = null;
                    })
                );
        }

        return obs;
    }

    static confermaValutazione(idRichiesta) {
        let instance = auth._httpClient;

        let obs = from(instance.patch(this.urlRichieste.confermaValutazione.replace("{id}", idRichiesta), { samlToken: SessioneUtente.getInstance().samlToken }));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.data?.errors?.[0]?.message });
        }));
    }

    static aggiungiProfessionista(id, body) {
        mostraSpinner();
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.aggiungiProfessionista.replace("{id}", id), body));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            }),
            finalize(() => {
                nascondiSpinner();
            })
        );
    }

    static confermaTeam(id) {
        mostraSpinner();
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.confermaTeam.replace("{id}", id)));
        return obs.pipe(
            map(response => response),
            finalize(() => {
                nascondiSpinner();
            })
        );
    }

    static visualizzaDettaglioRichiestaStorico(id) {
        let instance = auth._httpClient;

        let obs = from(instance.get(this.urlRichieste.visualizzaDettaglioRichiestaStorico.replace("{id}", id)));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            }));
    }

    static eliminaProfessionista(id, body) {
        mostraSpinner();
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.eliminaProfessionista.replace("{id}", id), body));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            }),
            finalize(() => {
                nascondiSpinner();
            })
        );
    }

    static riapriTeamValutatori(idRichiesta) {
        mostraSpinner();
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.riapriTeamValutatori.replace("{id}", idRichiesta)));
        return obs.pipe(
            map(response => response),
            finalize(() => {
                nascondiSpinner();
            })
        );
    }

    static sostituzioneMedicoRichiedenti(idRichiesta) {
        let instance = auth._httpClient;

        let obs = from(instance.patch(this.urlRichieste.sostituzioneMedicoRichiedenti.replace("{id}", idRichiesta), { samlToken: SessioneUtente.getInstance().samlToken }));
        return obs.pipe(map(response => {
            return response.data;
        }), catchError((err) => {
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.errors[0]?.message });
        }));
    }

    static riapriValutazione(id, body) {
        mostraSpinner();
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.riapriValutazione.replace("{id}", id), body));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            }),
            finalize(() => nascondiSpinner())
        );
    }

    static generaPdf(id, step, codiceFiscaleAutore = null) {
        mostraSpinner();
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.generaPdf.replace("{id}", id).replace("{step}", step), { codiceFiscaleAutore: codiceFiscaleAutore, samlToken: SessioneUtente.getInstance().samlToken }));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.data?.errors?.[0]?.message });
            }),
            finalize(() => {
                nascondiSpinner();
            }));
    }

    static autoAssegnazioneValutazione(id) {
        mostraSpinner();
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.autoAssegnazioneValutazione.replace("{id}", id)));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            }),
            finalize(() => nascondiSpinner())
        );
    }

    static subentraProfessionista(id, codiceFiscale, body) {
        mostraSpinner();
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.subentraProfessionista.replace("{id}", id).replace("{codiceFiscale}", codiceFiscale), body));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            }),
            finalize(() => {
                nascondiSpinner();
            })
        );
    }

    static modificaDataDimissioneProgrammata(id, dataDimissione) {
        let instance = auth._httpClient;

        let obs = from(instance.post(this.urlRichieste.modificaDataDimissioneProgrammata.replace("{id}", id), { dataDimissioneProgrammataDaRicovero: dataDimissione }));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.data?.errors?.[0]?.message });
            }));
    }

    static aggiornaDatiRichiedente(id, richiedente, pageState, forceUserRole) {
        mostraSpinner();

        let obs = of(null);

        if (Utils.isDimissioneProtetta(pageState) && AuthUtils.hasRuoloMedicoOspedaliero() && isSomeFieldIdWriteable('ANAGRAFESOGGETTORICHIEDENTE', pageState, forceUserRole)) {
            let instance = auth._httpClient;

            const body = {
                ospedale: richiedente.indirizzi?.find(e => e.tipo === 'SEDE_LAVORATIVA')?.ospedale,
                unitaOperativa: richiedente.indirizzi?.find(e => e.tipo === 'SEDE_LAVORATIVA')?.unitaOperativa
            }
            obs = from(instance.post(this.urlRichieste.aggiornaDatiRichiedente.replace("{id}", id), body));
            return obs.pipe(
                map(response => response),
                catchError(err => {
                    return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
                })
            );
        }

        return obs;
    }

    static recuperaRichiesteDaFirmare(limit) {
        mostraSpinner();

        let instance = auth._httpClient;
        let params = { limitRecord: limit }
        let obs = from(instance.get(this.urlRichieste.recuperaRichiesteDaFirmare, { params: params }));

        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.data?.errors?.[0]?.message });
            }), finalize(() => { nascondiSpinner() }));
    }

    static firmaFormalizzazioni(listaRichieste) {
        mostraSpinner();

        let instance = auth._httpClient;
        let obs = from(instance.post(this.urlRichieste.firmaFormalizzazioni, { samlToken: SessioneUtente.getInstance().samlToken, listaId: listaRichieste }));

        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.data?.errors?.[0]?.message });
            }), finalize(() => { nascondiSpinner() }));
    }

    static richiestaAggiuntaInfermiere(id) {
        mostraSpinner();

        let instance = auth._httpClient;
        let obs = from(instance.post(this.urlRichieste.richiestaAggiuntaInfermiere.replace("{id}", id)));

        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.data?.errors?.[0]?.message });
            }), finalize(() => { nascondiSpinner() }));
    }

    static contaCampiOpzionali(id) {
        let instance = auth._httpClient;

        let obs = from(instance.get(this.urlRichieste.contaCampiOpzionali.replace("{id}", id)));
        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
            }));
    }

    static contaFiltriRapidi(numeroElementi, filtriRichiesta) {
        let instance = auth._httpClient;
        let params = {
            numeroPagina: 1,
            vista: "sintesi"
        };

        if (numeroElementi != null) params = { ...params, numeroElementi };

        if (typeof filtriRichiesta === 'string') {
            params = { ...params, filtriRichiesta };
        } else if (typeof filtriRichiesta === 'object' && !Utils.isObjectEmpty(filtriRichiesta)) {
            params = {
                ...params,
                filtriRichiesta: Object.values(filtriRichiesta).join(',')
            }
        }
        params.filtriRichiesta = params.filtriRichiesta + ',timestampEsito::null';

        let obs = from(instance.get(this.urlRichieste.home, { params: params }));
        return obs.pipe(map(response => {
            return response;
        }), catchError((err) => {
            return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE" });
        }));
    }

    static subentroPresaInCarico(limit, codiceFiscaleSubentrante) {
        mostraSpinner();

        let instance = auth._httpClient;
        let params = { limitRecord: limit, codiceFiscaleSubentrante: codiceFiscaleSubentrante }
        let obs = from(instance.get(this.urlRichieste.subentroPresaInCarico, { params: params }));

        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.data?.errors?.[0]?.message });
            }), finalize(() => { nascondiSpinner() }));
    }

    static presaInCaricoMultipla(listaRichieste) {
        mostraSpinner();

        let instance = auth._httpClient;
        let obs = from(instance.post(this.urlRichieste.presaInCaricoMultipla, { samlToken: SessioneUtente.getInstance().samlToken, listaId: listaRichieste }));

        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.data?.errors?.[0]?.message });
            }), finalize(() => { nascondiSpinner() }));
    }

    static delegaFisiatra(id, delega) {
        mostraSpinner();

        let instance = auth._httpClient;
        let obs = from(instance.post(this.urlRichieste.delegaFisiatra.replace("{id}", id).replace("{delega}", delega)));

        return obs.pipe(
            map(response => response),
            catchError(err => {
                return of({ status: err.response?.status, data: err.response?.data, msg: "ERRORE", msgCode: err.response?.data?.errors?.[0]?.message });
            }), finalize(() => { nascondiSpinner() }));
    }

}
