import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    title: PropTypes.any,
    body: PropTypes.any,
    required: PropTypes.any,
    openAccordion: PropTypes.any,
    onClickAccordion: PropTypes.any,
    field: PropTypes.any,
    disabled: PropTypes.bool,
    children: PropTypes.node
}

const defaultProps = {
    disabled: false
}

export default class AccordionSecondLevel extends React.Component {
}

AccordionSecondLevel.propTypes = propTypes;
AccordionSecondLevel.defaultProps = defaultProps;
