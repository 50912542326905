import React, {Component, Fragment} from "react";
import * as _ from "lodash";
import Utils from "../../utils/Utils";
import {CheckBox, Input, SessioneUtente} from "web-client-archetype";
import AuthUtils from "../../utils/AuthUtils";
import styles from "./PannelloConfigurazioneGestioneUtenteADI.module.css";
import Accordion from "../accordion/Accordion";
import {DropdownIndicator, inputSelectStyles} from "../common/select/SelectUtils";
import Select from "react-select";
import enumsUtente from "../../enum/enumsUtente.json";
import ruoliSelect from "../../utils/ruoli.json";
import aulssElenco from "../../utils/dataset/aulssData/aulssElenco.json";
import ToponomasticaUtils from "../../utils/ToponomasticaUtils";
import PopoverReact from "../popover/Popover";
import AulssUtils from "../../utils/AulssUtils";
import messaggi from "../../utils/message/message-it.json";
import ApiAPMSService from "../../service/ApiAPMSService";
import {Redirect} from "react-router-dom";
import routepath from "../../utils/route/route-path.json";
import PropTypes from 'prop-types';
import {mostraSpinner, nascondiSpinner} from "../../App";
import ModalHelper from "../modale/ModalHelper";
import {map} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import disciplinaData from "../../utils/dataset/disciplinaData/disciplinaSpecialisti.json";
import enumAttributo from "../../enum/enumAttributo.json";
import GestionePasswordADIService from "../../service/GestionePasswordADIService";
import * as BsIcon from "react-bootstrap-icons";
import ButtonsBoxStick from "../buttonsBoxStick/buttonsBoxStick";
import DelegaService from "../../service/DelegaService";
import enumUtente from "../../enum/enumsUtente";

const propTypes = {
    codiceFiscaleUtenteProfilato: PropTypes.string
}

const defaultUtenteapiapms = {
    cognome: "",
    nome: "",
    codiceFiscale: "",
    mail: ""
};

const FISIATRA_DISCIPLINE = "FISIOCHINESITERAPIA";

const ruoliAdiValConPossibileUnitaOperativa = [
    ruoliSelect.ruoliProfessionistaValutatore[1],
    ruoliSelect.ruoliProfessionistaValutatore[2],
    ruoliSelect.ruoliProfessionistaValutatore[6],
    ruoliSelect.ruoliProfessionistaValutatore[7]
];

const MESSAGGIO_INFORMATIVO_CENTRALE_ADI = "E' possibile configurare Operatori di Centrale ADI per le sole Centrale ADI già configurate.";

const renderBottoneElimina = (listLength, currentElementIndex, codiceFiscaleUtenteProfilato,
                              removeAccordionItemRow) => {
    return (
        <span
            id={"btnRimuoviRigaItemAccordionBody"}
            className={"py-1 align-self-center"}
            style={{cursor: "pointer"}}
            onClick={() => {
                if (codiceFiscaleUtenteProfilato && listLength === 1)
                    Utils.toasterFunction(messaggi.eliminazioneUltimoSettingUtenteModificato);
                removeAccordionItemRow(currentElementIndex);
            }}>
            <BsIcon.Trash3 color={"#176A65"} size={28}/>
        </span>
    )
}

export default class PannelloConfigurazioneGestioneUtenteADI extends Component {
    state = {
        fullAulssSettingList: [],
        utente: null,
        utenteapiapms: _.cloneDeep(defaultUtenteapiapms),

        amministratore: false,
        referenteAulss: false,
        medicoRichiedente: false,
        medicoFormalizzatore: false,
        professionistaValutatore: false,
        coordinatore: false,
        referenteDirigenteAssistentiSociali: false,
        direttoreUnitaOperativa: false,
        centraleAdi: false,

        listaGruppiPerRigheAccordion: [],
        oldListaGruppi: null,

        redirectToRicerca: false,
        apriModaleEliminazioneUtente: false,

        centraliAdiCensite: null
    }

    listaAulssSelect = aulssElenco.map(el => ({value: el.id, label: el.descrizione}));

    // variabili utilizzate per memorizzare i ruoli assegnati in precedenza per i vari profili
    oldRuoloMedicoRichiedente = null;
    oldRuoloMedicoFormalizzatore = null;
    oldRuoloProfessionistaValutatore = null;
    oldRuoloCoordinatore = null;
    oldRuoloReferenteDirigenteAssistentiSociali = null;
    oldRuoloDirettoreUnitaOperativa = null;
    oldRuoloCentraleAdi = null;

    // variabili utilizzate per memorizzare i nuovi ruoli per i vari profili
    currentRuoloReferenteAulss = null;
    currentRuoloMedicoRichiedente = null;
    currentRuoloMedicoFormalizzatore = null;
    currentRuoloProfessionistaValutatore = null;
    currentRuoloCoordinatore = null;
    currentRuoloReferenteDirigenteAssistentiSociali = null;
    currentRuoloDirettoreUnitaOperativa = null;
    currentRuoloCentraleAdi = null;

    // variabili utilizzate per memorizzare le discipline assegnate in precedenza per i vari profili
    oldDisciplinaProfessionistaValutatore = null;
    oldDisciplinaDirettoreUnitaOperativa = null;

    // variabili utilizzate per memorizzare le nuove discipline per i vari profili
    currentDisciplinaProfessionistaValutatore = null;
    currentDisciplinaDirettoreUnitaOperativa = null;

    listaGruppi = [];

    disciplinaSelect = disciplinaData.map(el => ({value: el.codice, label: el.descrizione}));

    componentDidMount() {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        this.inizializzaForm();
    }

    isInsertMode = () => {
        return window.location.pathname === routepath.inserimento_utente && Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato);
    }

    inizializzaForm = () => {
        if (this.props.codiceFiscaleUtenteProfilato) this.recuperaUtente(true).subscribe();
        else this.setState({utenteapiapms: _.cloneDeep(defaultUtenteapiapms)});
    }

    recuperaUtente = (flagSetCurrentListaRighe) => {
        mostraSpinner();

        return ApiAPMSService.getUtenti({size: 0}, 0).pipe(map(response => {
            const codiceFiscale = this.props?.codiceFiscaleUtenteProfilato ?? this.state.utenteapiapms.codiceFiscale;
            const elementiByCodiceFiscale = response?.elementi?.filter(e => e.codiceFiscale === codiceFiscale) ?? [];
            if (elementiByCodiceFiscale.length > 0) {
                let utenteAdi = elementiByCodiceFiscale;
                let utente;
                let utenteapiapms;
                const lastEmail = utenteAdi[utenteAdi.length - 1].mail;
                if (!this.isInsertMode()) {
                    utente = utenteAdi;
                    utenteapiapms = {};
                    utenteapiapms.cognome = utenteAdi[0].cognome;
                    utenteapiapms.nome = utenteAdi[0].nome;
                    utenteapiapms.codiceFiscale = utenteAdi[0].codiceFiscale;
                    utenteapiapms.mail = lastEmail;
                } else {
                    utente = null;
                    utenteapiapms = this.state.utenteapiapms;
                }
                this.listaGruppi = !flagSetCurrentListaRighe ? _.cloneDeep(this.listaGruppi) : [];
                this.setState({
                    utente: utente,
                    utenteapiapms: utenteapiapms,
                    oldEmail: lastEmail,
                    fullAulssSettingList: response.elementi
                }, () => this.formattaListaGruppiPerRigheAccordion(utenteAdi, flagSetCurrentListaRighe));
            } else {
                this.setState({
                    oldListaGruppi: [],
                    fullAulssSettingList: response.elementi
                }, () => nascondiSpinner());
            }
            return of(this.state.oldListaGruppi);
        }));
    }

    formattaListaGruppiPerRigheAccordion = (elementi, flagSetCurrentListaRighe) => {
        let listaRigheAccordions = [];
        let ruoloReferenteAulss;
        let ruoloMedicoRichiedente;
        let ruoloMedicoFormalizzatore;
        let ruoloProfessionistaValutatore;
        let ruoloCoordinatore;
        let ruoloReferenteDirigenteAssistentiSociali;
        let ruoloDirettoreUnitaOperativa;
        let ruoloCentraleAdi;

        let disciplinaProfessionistaValutatore;
        let disciplinaDirettoreUnitaOperativa;

        // creo una mappa chiave (aulss) - valore (lista settings) a partire dai dati recuperati dal db
        let mappaProfiloListaSettings = this.creaMappaProfiloListaSettings(elementi);

        // utilizzando mappaProfiloListaSettings creo l'oggetto utilizzato per il render del form
        Object.keys(mappaProfiloListaSettings).forEach(key => {
            const aulss = aulssElenco.find(a => a.id === key);
            mappaProfiloListaSettings[key].forEach(setting => {
                let distretto = null;
                let sede = null;
                let comune = null;
                let ambito = null;
                let splitSetting = setting.split("@");

                let riga = {};
                riga.codicegruppo = splitSetting[0];

                switch (splitSetting[0]) {
                    case enumsUtente.profiliUtente.referenteAulss:
                        ruoloReferenteAulss = ruoliSelect.ruoloReferenteAulss[0];
                        break;
                    case enumsUtente.profiliUtente.medicoRichiedente:
                        ruoloMedicoRichiedente = ruoliSelect.ruoliMedicoRichiedente.find(ruolo => ruolo.value === splitSetting[1]);
                        break;
                    case enumsUtente.profiliUtente.medicoFormalizzatore:
                        ruoloMedicoFormalizzatore = ruoliSelect.ruoliMedicoFormalizzatore.find(ruolo => ruolo.value === splitSetting[1]);
                        break;
                    case enumsUtente.profiliUtente.professionistaValutatore:
                        ruoloProfessionistaValutatore = ruoliSelect.ruoliProfessionistaValutatore.find(ruolo => ruolo.value === splitSetting[1]);
                        disciplinaProfessionistaValutatore = this.disciplinaSelect.find(disciplina => disciplina.value === splitSetting[5]) ?? null;
                        break;
                    case enumsUtente.profiliUtente.coordinatore:
                        ruoloCoordinatore = ruoliSelect.ruoloCoordinatore[0];
                        break;
                    case enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali:
                        ruoloReferenteDirigenteAssistentiSociali = ruoliSelect.ruoloReferenteDirigenteAssistentiSociali[0];
                        break;
                    case enumsUtente.profiliUtente.direttoreUnitaOperativa:
                        ruoloDirettoreUnitaOperativa = ruoliSelect.ruoliDirettoreDiUnitaOperativa.find(ruolo => ruolo.value === splitSetting[1]);
                        disciplinaDirettoreUnitaOperativa = this.disciplinaSelect.find(disciplina => disciplina.value === splitSetting[5]) ?? null;
                        break;
                    case enumsUtente.profiliUtente.centraleAdi:
                        ruoloCentraleAdi = ruoliSelect.ruoloCentraleAdi.find(ruolo => ruolo.value === splitSetting[1]);
                        break;
                    default:
                        break;
                }

                riga.aulss = {value: aulss?.id, label: aulss?.descrizione};
                if (!Utils.isStringEmptyOrNullOrUndefined(splitSetting[2])) {
                    let distrettoObject = AulssUtils.getDistrettoByIdAulssAndIdDistretto(aulss.id, splitSetting[2]);
                    if (distrettoObject != null) distretto = {
                        value: distrettoObject.id,
                        label: distrettoObject.descrizione
                    };
                    if (!Utils.isStringEmptyOrNullOrUndefined(splitSetting[3])) {
                        let sedeObject = AulssUtils.getSedeByIdAulssAndIdDistrettoAndIdSede(aulss.id, distretto?.value, splitSetting[3].substring(5));
                        if (sedeObject != null) sede = {value: sedeObject.id, label: sedeObject.nome};
                    }
                }
                if (!Utils.isStringEmptyOrNullOrUndefined(splitSetting[4])) {
                    let comuneObject = AulssUtils.getComuneByIdAulssAndIdComune(aulss.id, splitSetting[4]);
                    comune = {value: comuneObject.codice, label: comuneObject.descrizione};
                }

                if (!Utils.isStringEmptyOrNullOrUndefined(splitSetting[7])) {
                    let ambitoObject = AulssUtils.getAmbitoByIdAulssAndIdDistrettoAndIdAmbito(aulss.id, distretto?.value, splitSetting[7]);
                    ambito = {value: ambitoObject.id, label: ambitoObject.descrizione};
                }
                riga.distretto = distretto;
                riga.sede = sede;
                riga.comune = comune;
                riga.ambito = ambito;
                riga.unitaOperativa = !Utils.isStringEmptyOrNullOrUndefined(splitSetting[6]) ? {
                    value: splitSetting[6],
                    label: splitSetting[6]
                } : null;
                riga.isDisabled = true;

                listaRigheAccordions.push(riga);
            });
        });

        let oldListaGruppi = listaRigheAccordions;

        let strutture = Array.of(SessioneUtente.getInstance().codiceStruttura);
        if (AuthUtils.hasUtenteRuoloReferenteAulss()) {
            const codiceDistrettoReferenteAulss = SessioneUtente.getInstance().settings.map(s => s.distretto).filter(d => !!d)[0];
            listaRigheAccordions = listaRigheAccordions
                .filter(riga => !riga.aulss?.value || riga.aulss?.value === strutture.reduce((f, s) => `${f},${s}`))
                .filter(riga => !riga.distretto?.value || riga.distretto?.value === codiceDistrettoReferenteAulss);
        }

        this.oldRuoloMedicoRichiedente = ruoloMedicoRichiedente;
        this.oldRuoloMedicoFormalizzatore = ruoloMedicoFormalizzatore;
        this.oldRuoloProfessionistaValutatore = ruoloProfessionistaValutatore;
        this.oldRuoloCoordinatore = ruoloCoordinatore;
        this.oldRuoloReferenteDirigenteAssistentiSociali = ruoloReferenteDirigenteAssistentiSociali;
        this.oldRuoloDirettoreUnitaOperativa = ruoloDirettoreUnitaOperativa;
        this.oldRuoloCentraleAdi = ruoloCentraleAdi;

        this.oldDisciplinaProfessionistaValutatore = disciplinaProfessionistaValutatore;
        this.oldDisciplinaDirettoreUnitaOperativa = disciplinaDirettoreUnitaOperativa;

        if (!flagSetCurrentListaRighe || Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato)) {
            this.setState({oldListaGruppi: oldListaGruppi}, () => nascondiSpinner());
        } else {
            let isAmministratore = listaRigheAccordions.some(riga => riga.codicegruppo === enumsUtente.profiliUtente.admin);
            let isReferenteAulss = listaRigheAccordions.some(riga => riga.codicegruppo === enumsUtente.profiliUtente.referenteAulss);
            let isMedicoRichiedente = listaRigheAccordions.some(riga => riga.codicegruppo === enumsUtente.profiliUtente.medicoRichiedente);
            let isMedicoFormalizzatore = listaRigheAccordions.some(riga => riga.codicegruppo === enumsUtente.profiliUtente.medicoFormalizzatore);
            let isProfessionistaValutatore = listaRigheAccordions.some(riga => riga.codicegruppo === enumsUtente.profiliUtente.professionistaValutatore);
            let isCoordinatore = listaRigheAccordions.some(riga => riga.codicegruppo === enumsUtente.profiliUtente.coordinatore);
            let isReferenteDirigenteAssistentiSociali = listaRigheAccordions.some(riga => riga.codicegruppo === enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali);
            let isDirettoreUnitaOperativa = listaRigheAccordions.some(riga => riga.codicegruppo === enumsUtente.profiliUtente.direttoreUnitaOperativa);
            let isCentraleAdi = listaRigheAccordions.some(riga => riga.codicegruppo === enumsUtente.profiliUtente.centraleAdi);

            this.currentRuoloReferenteAulss = ruoloReferenteAulss;
            this.currentRuoloMedicoRichiedente = ruoloMedicoRichiedente;
            this.currentRuoloMedicoFormalizzatore = ruoloMedicoFormalizzatore;
            this.currentRuoloProfessionistaValutatore = ruoloProfessionistaValutatore;
            this.currentRuoloCoordinatore = ruoloCoordinatore;
            this.currentRuoloReferenteDirigenteAssistentiSociali = ruoloReferenteDirigenteAssistentiSociali;
            this.currentRuoloDirettoreUnitaOperativa = ruoloDirettoreUnitaOperativa;
            this.currentRuoloCentraleAdi = ruoloCentraleAdi;

            this.currentDisciplinaProfessionistaValutatore = disciplinaProfessionistaValutatore;
            this.currentDisciplinaDirettoreUnitaOperativa = disciplinaDirettoreUnitaOperativa;

            this.setState({
                listaGruppiPerRigheAccordion: listaRigheAccordions,
                oldListaGruppi: oldListaGruppi,
                amministratore: isAmministratore,
                referenteAulss: isReferenteAulss,
                medicoRichiedente: isMedicoRichiedente,
                medicoFormalizzatore: isMedicoFormalizzatore,
                professionistaValutatore: isProfessionistaValutatore,
                coordinatore: isCoordinatore,
                referenteDirigenteAssistentiSociali: isReferenteDirigenteAssistentiSociali,
                direttoreUnitaOperativa: isDirettoreUnitaOperativa,
                centraleAdi: isCentraleAdi
            }, () => nascondiSpinner());
        }
    }

    creaMappaProfiloListaSettings = (data) => {
        let mappaProfiloListaSettings = {};
        let dataGroupedByStruttura = _.groupBy(data, "struttura");
        Object.keys(dataGroupedByStruttura).forEach(key => {
            let settings = [];
            dataGroupedByStruttura[key].forEach(e => {
                e.gruppi.forEach(gruppo => {
                    const flatSettings = gruppo.attributi.flatMap(attributo => attributo.valore.slice(1, -1).split(","));
                    if (flatSettings !== null && flatSettings.length > 0) settings.push(...flatSettings);
                });
            });
            if (settings.length > 0) mappaProfiloListaSettings[key] = Array.from(new Set(settings));
        });

        return mappaProfiloListaSettings;
    }

    handleAggiornaForm = (field, elem) => {
        let currentUtente = {...this.state.utenteapiapms};
        let listaGruppiRighePerAccordion = _.cloneDeep(this.state.listaGruppiPerRigheAccordion);
        if (typeof elem === "boolean") {
            if(field === "centraleAdi" && elem){
                DelegaService.ricercaDeleghe(null, null, 0, 15, enumUtente.profiliUtente.centraleAdi).subscribe((pagina) => {
                    let centraliAdiCensite = pagina.lista;
                    this.setState({centraliAdiCensite: centraliAdiCensite})
                });
            }
            if (!elem)
                listaGruppiRighePerAccordion = listaGruppiRighePerAccordion.filter(gruppo => gruppo.codicegruppo !== enumsUtente.profiliUtente[field]);
            this.setState({listaGruppiPerRigheAccordion: listaGruppiRighePerAccordion, [field]: elem}, () => {
                let areAllCheckboxDeselected = !this.state.amministratore && !this.state.referenteAulss
                    && !this.state.medicoRichiedente && !this.state.medicoFormalizzatore
                    && !this.state.professionistaValutatore && !this.state.coordinatore
                    && !this.state.referenteDirigenteAssistentiSociali
                    && !this.state.direttoreUnitaOperativa && !this.state.centraleAdi;

                if (this.props.codiceFiscaleUtenteProfilato && areAllCheckboxDeselected) {
                    Utils.toasterFunction(messaggi.deselezioneTutteCheckboxProfili);
                }
            });
        } else {
            currentUtente[field] = elem;
            this.setState({utenteapiapms: currentUtente, listaGruppiPerRigheAccordion: listaGruppiRighePerAccordion});
        }
    }

    onChangeListaRigheAccordion = (field, elem, profiloUtenteSelezionato, indexRiga = -1, isCurrentElementDifferentFromSelectedOption = false) => {
        let righeAccordionProfiloSelezionato = !Utils.isObjectNull(this.state.listaGruppiPerRigheAccordion) ? _.cloneDeep(this.state.listaGruppiPerRigheAccordion) : [];
        let profilazioneUtente = righeAccordionProfiloSelezionato.filter(gruppo => gruppo.codicegruppo === profiloUtenteSelezionato);

        if (profilazioneUtente !== null && profilazioneUtente.length > 0) {
            if (field === enumAttributo.ruolo.value) {
                if (profilazioneUtente.length === 0 || isCurrentElementDifferentFromSelectedOption)
                    righeAccordionProfiloSelezionato = righeAccordionProfiloSelezionato.filter(gruppo => gruppo.codicegruppo !== profiloUtenteSelezionato);
            } else {
                if (field === enumAttributo.aulss.value && isCurrentElementDifferentFromSelectedOption) {
                    righeAccordionProfiloSelezionato[indexRiga].distretto = null;
                    righeAccordionProfiloSelezionato[indexRiga].sede = null;
                    righeAccordionProfiloSelezionato[indexRiga].comune = null;
                    righeAccordionProfiloSelezionato[indexRiga].ambito = null;
                    righeAccordionProfiloSelezionato[indexRiga].unitaOperativa = null;
                } else if (field === enumAttributo.distretto.value && isCurrentElementDifferentFromSelectedOption) {
                    righeAccordionProfiloSelezionato[indexRiga].sede = null;
                    righeAccordionProfiloSelezionato[indexRiga].ambito = null;
                }

                if (indexRiga !== -1) righeAccordionProfiloSelezionato[indexRiga][field] = elem;
            }
        } else {
            if (indexRiga !== -1) righeAccordionProfiloSelezionato[indexRiga][field] = elem;
        }

        this.setState({listaGruppiPerRigheAccordion: righeAccordionProfiloSelezionato}, () => this.creaArrayListaGruppi());
    }


    validaCodiceFiscale = () => {
        if (!Utils.isStringEmptyOrNullOrUndefined(this.state.utenteapiapms.codiceFiscale)
            && !Utils.isValidCodiceFiscale(this.state.utenteapiapms.codiceFiscale)) {
            return "Inserire un codice fiscale valido";
        } else {
            return null;
        }
    }

    validaEmail = () => {
        if (!Utils.isStringEmptyOrNullOrUndefined(this.state.utenteapiapms.mail)
            && !Utils.isValidEmail(this.state.utenteapiapms.mail)) {
            return "Inserire un'email valida";
        } else {
            return null;
        }
    }

    renderCampoInputTesto = (title, field, value, placeholder, isDisabled, maxlength) => {
        return (
            <div className={"col-md-3 bg-white"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <Input
                            parentClass={"mb-3"}
                            placeholder={placeholder}
                            field={field}
                            onChange={(f, e) => this.handleAggiornaForm(f, e.target.value)}
                            id={"filterInput" + field.charAt(0).toUpperCase() + field.slice(1)}
                            value={value}
                            disabled={isDisabled}
                            maxlength={maxlength}
                            required
                            label={title}
                            infoText="Campo obbligatorio"
                            onValidate={field === "codiceFiscale" ? () => this.validaCodiceFiscale() : field === "mail" ? () => this.validaEmail() : null}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderTitoloProfilo = () => {
        let indicazionePerProfilo = AuthUtils.hasUtenteRuoloAmministratoreSistema()
            ? "      (Attenzione: si ricorda che la selezione dell'opzione \"Amministratore\" è mutuamente esclusiva con le altre voci)"
            : null;

        return (
            <div className={"row py-2"}>
                <div className={"col-12"}>
                    <span><span className={"required f-weight-600"}>Profilo</span>{indicazionePerProfilo}</span>
                </div>
            </div>
        );
    }

    renderCheckboxProfilo = () => {
        let isCheckboxAmministratoreDisabled = this.state.referenteAulss
            || this.state.medicoRichiedente || this.state.medicoFormalizzatore
            || this.state.professionistaValutatore || this.state.coordinatore
            || this.state.referenteDirigenteAssistentiSociali
            || this.state.direttoreUnitaOperativa || this.state.centraleAdi;

        let areOtherCheckboxDisabled = this.state.amministratore;

        return (
            <>
                <div className={AuthUtils.hasUtenteRuoloAmministratoreSistema() ? "row pb-3" : "d-none"}>
                    <div className={"col-12"}>
                        <CheckBox
                            id={"amministratore"}
                            field={'amministratore'}
                            styleLabel={{fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"}}
                            text={"Amministratore"}
                            onChange={(field, elem) => this.handleAggiornaForm(field, elem.target.checked)}
                            checked={this.state.amministratore}
                            disabled={isCheckboxAmministratoreDisabled}
                        />
                    </div>
                </div>
                <div className={AuthUtils.hasUtenteRuoloAmministratoreSistema() ? "row pb-3" : "d-none"}>
                    <div className={"col-12"}>
                        <Accordion
                            id={"referenteAulss"}
                            fieldCheckbox={'referenteAulss'}
                            labelHeaderStyle={{fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"}}
                            headerText={"Referente Distrettuale"}
                            idHeader={"collapseReferenteAulss"}
                            onChangeCheckbox={(field, elem) => {
                                this.currentRuoloReferenteAulss = null;
                                this.handleAggiornaForm(field, elem);
                            }}
                            ariaExpanded={this.state.referenteAulss}
                            body={this.renderBodyAccordion(enumsUtente.profiliUtente.referenteAulss)}
                            elementsNearHeaderLabel={[this.renderSelectRuolo(enumsUtente.profiliUtente.referenteAulss)]}
                            disableCheckbox={areOtherCheckboxDisabled}/>
                    </div>
                </div>
                <div className={"row pb-3"}>
                    <div className={"col-12"}>
                        <Accordion
                            id={"medicoRichiedente"}
                            fieldCheckbox={'medicoRichiedente'}
                            labelHeaderStyle={{fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"}}
                            headerText={"Medico richiedente"}
                            idHeader={"collapseMedicoRichiedente"}
                            onChangeCheckbox={(field, elem) => {
                                this.currentRuoloMedicoRichiedente = null;
                                this.handleAggiornaForm(field, elem);
                            }}
                            ariaExpanded={this.state.medicoRichiedente}
                            body={this.renderBodyAccordion(enumsUtente.profiliUtente.medicoRichiedente)}
                            elementsNearHeaderLabel={[this.renderSelectRuolo(enumsUtente.profiliUtente.medicoRichiedente)]}
                            disableCheckbox={areOtherCheckboxDisabled}/>
                    </div>
                </div>
                <div className={"row pb-3"}>
                    <div className={"col-12"}>
                        <Accordion
                            id={"medicoFormalizzatore"}
                            fieldCheckbox={'medicoFormalizzatore'}
                            labelHeaderStyle={{fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"}}
                            headerText={"Medico formalizzatore"}
                            idHeader={"collapseMedicoFormalizzatore"}
                            onChangeCheckbox={(field, elem) => {
                                this.currentRuoloMedicoFormalizzatore = null;
                                this.handleAggiornaForm(field, elem);
                            }}
                            ariaExpanded={this.state.medicoFormalizzatore}
                            body={this.renderBodyAccordion(enumsUtente.profiliUtente.medicoFormalizzatore)}
                            elementsNearHeaderLabel={[this.renderSelectRuolo(enumsUtente.profiliUtente.medicoFormalizzatore)]}
                            disableCheckbox={areOtherCheckboxDisabled}/>
                    </div>
                </div>
                <div className={"row pb-3"}>
                    <div className={"col-12"}>
                        <Accordion
                            id={"professionistaValutatore"}
                            fieldCheckbox={'professionistaValutatore'}
                            labelHeaderStyle={{fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"}}
                            headerText={"Professionista valutatore"}
                            idHeader={"collapseProfessionistaValutatore"}
                            onChangeCheckbox={(field, elem) => {
                                this.currentRuoloProfessionistaValutatore = null;
                                this.currentDisciplinaProfessionistaValutatore = null;
                                this.handleAggiornaForm(field, elem);
                            }}
                            ariaExpanded={this.state.professionistaValutatore}
                            body={this.renderBodyAccordion(enumsUtente.profiliUtente.professionistaValutatore)}
                            elementsNearHeaderLabel={[
                                this.renderSelectRuolo(enumsUtente.profiliUtente.professionistaValutatore),
                                this.renderSelectDisciplina(enumsUtente.profiliUtente.professionistaValutatore, this.currentRuoloProfessionistaValutatore)
                            ]}
                            disableCheckbox={areOtherCheckboxDisabled}/>
                    </div>
                </div>
                <div className={"row pb-3"}>
                    <div className={"col-12"}>
                        <Accordion
                            id={"coordinatore"}
                            fieldCheckbox={'coordinatore'}
                            labelHeaderStyle={{fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"}}
                            headerText={"Coordinatore"}
                            idHeader={"collapseCoordinatore"}
                            onChangeCheckbox={(field, elem) => {
                                this.currentRuoloCoordinatore = null;
                                this.handleAggiornaForm(field, elem);
                            }}
                            ariaExpanded={this.state.coordinatore}
                            body={this.renderBodyAccordion(enumsUtente.profiliUtente.coordinatore)}
                            elementsNearHeaderLabel={[this.renderSelectRuolo(enumsUtente.profiliUtente.coordinatore)]}
                            disableCheckbox={areOtherCheckboxDisabled}/>
                    </div>
                </div>
                <div className={"row pb-3"}>
                    <div className={"col-12"}>
                        <Accordion
                            id={"referenteDirigenteAssistentiSociali"}
                            fieldCheckbox={'referenteDirigenteAssistentiSociali'}
                            labelHeaderStyle={{fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"}}
                            headerText={"Referente/Dirigente assistenti sociali"}
                            idHeader={"collapseReferenteDirigenteAssistentiSociali"}
                            onChangeCheckbox={(field, elem) => {
                                this.currentRuoloReferenteDirigenteAssistentiSociali = null;
                                this.handleAggiornaForm(field, elem);
                            }}
                            ariaExpanded={this.state.referenteDirigenteAssistentiSociali}
                            body={this.renderBodyAccordion(enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali)}
                            elementsNearHeaderLabel={[this.renderSelectRuolo(enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali)]}
                            disableCheckbox={areOtherCheckboxDisabled}/>
                    </div>
                </div>
                <div className={"row pb-3"}>
                    <div className={"col-12"}>
                        <Accordion
                            id={"direttoreUnitaOperativa"}
                            fieldCheckbox={'direttoreUnitaOperativa'}
                            labelHeaderStyle={{fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"}}
                            headerText={"Direttore di Unità Operativa"}
                            idHeader={"collapseDirettoreUnitaOperativa"}
                            onChangeCheckbox={(field, elem) => {
                                this.currentRuoloDirettoreUnitaOperativa = null;
                                this.currentDisciplinaDirettoreUnitaOperativa = null;
                                this.handleAggiornaForm(field, elem);
                            }}
                            ariaExpanded={this.state.direttoreUnitaOperativa}
                            body={this.renderBodyAccordion(enumsUtente.profiliUtente.direttoreUnitaOperativa)}
                            elementsNearHeaderLabel={[
                                this.renderSelectRuolo(enumsUtente.profiliUtente.direttoreUnitaOperativa),
                                this.renderSelectDisciplina(enumsUtente.profiliUtente.direttoreUnitaOperativa, this.currentRuoloDirettoreUnitaOperativa)
                            ]}
                            disableCheckbox={areOtherCheckboxDisabled}/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <Accordion
                            id={"centraleAdi"}
                            fieldCheckbox={'centraleAdi'}
                            labelHeaderStyle={{fontFamily: "Arial", fontSize: "14px", whiteSpace: "nowrap"}}
                            headerText={"Centrale ADI"}
                            idHeader={"collapseCentraleAdi"}
                            onChangeCheckbox={(field, elem) => {
                                this.currentRuoloCentraleAdi = null;
                                this.handleAggiornaForm(field, elem);
                            }}
                            ariaExpanded={this.state.centraleAdi}
                            body={this.renderBodyAccordion(enumsUtente.profiliUtente.centraleAdi)}
                            elementsNearHeaderLabel={[this.renderSelectRuolo(enumsUtente.profiliUtente.centraleAdi)]}
                            disableCheckbox={areOtherCheckboxDisabled}/>
                    </div>
                </div>
            </>
        );
    }

    getRuoloObject = (profiloSelezionato, oldObjectFlag = false) => {
        switch (profiloSelezionato) {
            case enumsUtente.profiliUtente.referenteAulss:
                return oldObjectFlag ? {value: "P_REFERENTE_AULSS"} : this.currentRuoloReferenteAulss;
            case enumsUtente.profiliUtente.medicoRichiedente:
                return oldObjectFlag ? this.oldRuoloMedicoRichiedente : this.currentRuoloMedicoRichiedente;
            case enumsUtente.profiliUtente.medicoFormalizzatore:
                return oldObjectFlag ? this.oldRuoloMedicoFormalizzatore : this.currentRuoloMedicoFormalizzatore;
            case enumsUtente.profiliUtente.professionistaValutatore:
                return oldObjectFlag ? this.oldRuoloProfessionistaValutatore : this.currentRuoloProfessionistaValutatore;
            case enumsUtente.profiliUtente.coordinatore:
                return oldObjectFlag ? this.oldRuoloCoordinatore : this.currentRuoloCoordinatore;
            case enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali:
                return oldObjectFlag ? this.oldRuoloReferenteDirigenteAssistentiSociali : this.currentRuoloReferenteDirigenteAssistentiSociali;
            case enumsUtente.profiliUtente.direttoreUnitaOperativa:
                return oldObjectFlag ? this.oldRuoloDirettoreUnitaOperativa : this.currentRuoloDirettoreUnitaOperativa;
            case enumsUtente.profiliUtente.centraleAdi:
                return oldObjectFlag ? this.oldRuoloCentraleAdi : this.currentRuoloCentraleAdi;
            default:
                break;
        }
    }

    getDisciplinaObject = (profiloSelezionato, oldObjectFlag = false) => {
        switch (profiloSelezionato) {
            case enumsUtente.profiliUtente.professionistaValutatore:
                return oldObjectFlag ? this.oldDisciplinaProfessionistaValutatore : this.currentDisciplinaProfessionistaValutatore;
            case enumsUtente.profiliUtente.direttoreUnitaOperativa:
                return oldObjectFlag ? this.oldDisciplinaDirettoreUnitaOperativa : this.currentDisciplinaDirettoreUnitaOperativa;
            default:
                break;
        }
    }

    renderSelectRuolo = (profiloSelezionato) => {
        let ruoli = [];
        let nomeCampoStato;
        let currentRuolo;

        switch (profiloSelezionato) {
            case enumsUtente.profiliUtente.referenteAulss:
                ruoli = ruoliSelect.ruoloReferenteAulss;
                nomeCampoStato = "currentRuoloReferenteAulss";
                break;
            case enumsUtente.profiliUtente.medicoRichiedente:
                ruoli = ruoliSelect.ruoliMedicoRichiedente;
                nomeCampoStato = "currentRuoloMedicoRichiedente";
                break;
            case enumsUtente.profiliUtente.medicoFormalizzatore:
                ruoli = ruoliSelect.ruoliMedicoFormalizzatore;
                nomeCampoStato = "currentRuoloMedicoFormalizzatore";
                break;
            case enumsUtente.profiliUtente.professionistaValutatore:
                ruoli = ruoliSelect.ruoliProfessionistaValutatore;
                nomeCampoStato = "currentRuoloProfessionistaValutatore";
                break;
            case enumsUtente.profiliUtente.coordinatore:
                ruoli = ruoliSelect.ruoloCoordinatore;
                nomeCampoStato = "currentRuoloCoordinatore";
                break;
            case enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali:
                ruoli = ruoliSelect.ruoloReferenteDirigenteAssistentiSociali;
                nomeCampoStato = "currentRuoloReferenteDirigenteAssistentiSociali";
                break;
            case enumsUtente.profiliUtente.direttoreUnitaOperativa:
                ruoli = ruoliSelect.ruoliDirettoreDiUnitaOperativa;
                nomeCampoStato = "currentRuoloDirettoreUnitaOperativa";
                break;
            case enumsUtente.profiliUtente.centraleAdi:
                ruoli = ruoliSelect.ruoloCentraleAdi;
                nomeCampoStato = "currentRuoloCentraleAdi";
                break;
            default:
                break;
        }

        currentRuolo = this.getRuoloObject(profiloSelezionato);
        let isProfiloCoordinatoreSelezionato = _.isEqual(profiloSelezionato, enumsUtente.profiliUtente.coordinatore) && this.state.coordinatore;
        let isProfiloReferenteDirigenteAssistentiSociali = _.isEqual(profiloSelezionato, enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali)
            && this.state.referenteDirigenteAssistentiSociali;
        let isProfiloCentraleAdiSelezionato = _.isEqual(profiloSelezionato, enumsUtente.profiliUtente.centraleAdi) && this.state.centraleAdi;
        if (isProfiloCoordinatoreSelezionato && Utils.isObjectNull(currentRuolo)) {
            this.currentRuoloCoordinatore = ruoliSelect.ruoloCoordinatore[0];
            this.onChangeListaRigheAccordion(enumAttributo.ruolo.value, ruoliSelect.ruoloCoordinatore[0], profiloSelezionato);
        } else if (isProfiloReferenteDirigenteAssistentiSociali && Utils.isObjectNull(currentRuolo)) {
            this.currentRuoloReferenteDirigenteAssistentiSociali = ruoliSelect.ruoloReferenteDirigenteAssistentiSociali[0];
            this.onChangeListaRigheAccordion(enumAttributo.ruolo.value, ruoliSelect.ruoloReferenteDirigenteAssistentiSociali[0], profiloSelezionato);
        } else if (isProfiloCentraleAdiSelezionato && Utils.isObjectNull(currentRuolo)) {
            this.currentRuoloCentraleAdi = ruoliSelect.ruoloCentraleAdi[0];
            this.onChangeListaRigheAccordion(enumAttributo.ruolo.value, ruoliSelect.ruoloCentraleAdi[0], profiloSelezionato);
        }

        return (
            <div className={"col-3"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <span className={"required"}>Ruolo</span>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <Select
                            id={"selectRuolo"}
                            className={"ruoli"}
                            classNamePrefix={"inputSelect"}
                            components={DropdownIndicator}
                            styles={inputSelectStyles}
                            options={ruoli}
                            value={currentRuolo}
                            onChange={(elem) => {
                                this[nomeCampoStato] = elem;
                                const isCurrentRuoloDifferentFromSelectedOption = !_.isEqual(elem, currentRuolo);
                                if (isCurrentRuoloDifferentFromSelectedOption) {
                                    this[nomeCampoStato.replace("Ruolo", "UnitaOperativa")] = null;
                                    this[nomeCampoStato.replace("Ruolo", "Disciplina")] = null;
                                }
                                this.onChangeListaRigheAccordion(enumAttributo.ruolo.value, elem, profiloSelezionato, -1, isCurrentRuoloDifferentFromSelectedOption);
                            }}
                            isSearchable={false}
                            placeholder={"Seleziona"}
                            noOptionsMessage={() => "Errore: Ruolo non esistente"}
                            isDisabled={isProfiloCoordinatoreSelezionato || isProfiloReferenteDirigenteAssistentiSociali || isProfiloCentraleAdiSelezionato}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderSelectDisciplina = (profiloSelezionato, currentRuolo) => {
        let isRuoloValued = !Utils.isObjectNull(currentRuolo);
        let showDisciplina;
        let nomeCampoStato;
        let currentDisciplina;
        let disciplinaJSX;

        if (isRuoloValued) {
            if (_.isEqual(profiloSelezionato, enumsUtente.profiliUtente.professionistaValutatore)) {
                showDisciplina = _.isEqual(currentRuolo, ruoliSelect.ruoliProfessionistaValutatore[1])
                    || _.isEqual(currentRuolo, ruoliSelect.ruoliProfessionistaValutatore[2]);
            } else if (_.isEqual(profiloSelezionato, enumsUtente.profiliUtente.direttoreUnitaOperativa)) {
                showDisciplina = _.isEqual(currentRuolo, ruoliSelect.ruoliDirettoreDiUnitaOperativa[0])
                    || _.isEqual(currentRuolo, ruoliSelect.ruoliDirettoreDiUnitaOperativa[1]);
            } else {
                showDisciplina = false;
            }
        } else {
            showDisciplina = false;
        }

        if (showDisciplina) {
            let isAdiValFisiatraRoleSelected = false;
            let isAdiDirUOFisiatraRoleSelected = false;
            switch (profiloSelezionato) {
                case enumsUtente.profiliUtente.professionistaValutatore:
                    nomeCampoStato = "currentDisciplinaProfessionistaValutatore";
                    isAdiValFisiatraRoleSelected = _.isEqual(currentRuolo, ruoliSelect.ruoliProfessionistaValutatore[2]);
                    currentDisciplina = this.getDisciplinaObject(profiloSelezionato);
                    if (isAdiValFisiatraRoleSelected && Utils.isObjectNull(currentDisciplina)) {
                        this.currentDisciplinaProfessionistaValutatore = this.disciplinaSelect.find(e => e.label === FISIATRA_DISCIPLINE);
                        this.onChangeListaRigheAccordion(enumAttributo.disciplina.value, this.currentDisciplinaProfessionistaValutatore, profiloSelezionato);
                    }
                    break;
                case enumsUtente.profiliUtente.direttoreUnitaOperativa:
                    nomeCampoStato = "currentDisciplinaDirettoreUnitaOperativa";
                    isAdiDirUOFisiatraRoleSelected = _.isEqual(currentRuolo, ruoliSelect.ruoliDirettoreDiUnitaOperativa[1]);
                    currentDisciplina = this.getDisciplinaObject(profiloSelezionato);
                    if (isAdiDirUOFisiatraRoleSelected && Utils.isObjectNull(currentDisciplina)) {
                        this.currentDisciplinaDirettoreUnitaOperativa = this.disciplinaSelect.find(e => e.label === FISIATRA_DISCIPLINE);
                        this.onChangeListaRigheAccordion(enumAttributo.disciplina.value, this.currentDisciplinaDirettoreUnitaOperativa, profiloSelezionato);
                    }
                    break;
                default:
                    break;
            }
            disciplinaJSX = (
                <div className={"col-3"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <span className={"required"}>Disciplina</span>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Select
                                id={"selectDisciplina"}
                                className={enumAttributo.disciplina.value}
                                classNamePrefix={"inputSelect"}
                                components={DropdownIndicator}
                                styles={inputSelectStyles}
                                options={this.disciplinaSelect}
                                value={currentDisciplina}
                                onChange={(elem) => {
                                    this[nomeCampoStato] = elem;
                                    this.onChangeListaRigheAccordion(enumAttributo.disciplina.value, elem, profiloSelezionato);
                                }}
                                isSearchable={false}
                                noOptionsMessage={() => "Errore: Disciplina non esistente"}
                                placeholder={"Seleziona"}
                                isDisabled={isAdiValFisiatraRoleSelected || isAdiDirUOFisiatraRoleSelected}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            disciplinaJSX = null;
        }

        return (disciplinaJSX);
    }

    renderBodyAccordion = (profiloSelezionato) => {
        let currentRuolo = this.getRuoloObject(profiloSelezionato);
        let itemsInAccordionRowsJSX = [];

        let profilazioneUtente = this.state.listaGruppiPerRigheAccordion.filter(gruppo => gruppo.codicegruppo === profiloSelezionato);
        if (profilazioneUtente !== null && profilazioneUtente.length > 0 && !Utils.isStringEmptyOrNullOrUndefined(currentRuolo?.label)) {
            profilazioneUtente.forEach((attributo, indexItem) => {
                let key = Object.keys(attributo).find(k => attributo[k] === attributo[indexItem]);
                if (key !== "codicegruppo") {
                    const aulssPrimaRiga = profilazioneUtente[0].aulss;
                    const distrettoPrimaRiga = profilazioneUtente[0]?.distretto ?? null;
                    const unitaOperativaPrimaRiga = profilazioneUtente[0]?.unitaOperativa ?? null;
                    const itemAccordionRowJSX = this.renderInputRigaAccordion(
                        attributo, profiloSelezionato, currentRuolo.label,
                        indexItem, profilazioneUtente.length, aulssPrimaRiga,
                        distrettoPrimaRiga, unitaOperativaPrimaRiga
                    );
                    itemsInAccordionRowsJSX.push(itemAccordionRowJSX);
                }
            });
        }

        let addButton = (
            <>
                <span
                    id={"btnAggiungiRigaItemAccordionBody"}
                    style={{cursor: "pointer"}}
                    onClick={() => this.addAccordionItemRow(profiloSelezionato)}>
                    <BsIcon.PlusCircle color={"#176A65"} size={28}/>
                </span>
                <div onClick={() => this.addAccordionItemRow(profiloSelezionato)} className={styles.aggiungiSetting.concat(" col-11 pl-3 py-1")}>
                    {"Aggiungi setting"}
                    <span className={profilazioneUtente.length === 0 ? styles.requiredText.concat(" pl-3") : "d-none"}>
                        {"(Inserire almeno un setting)"}
                    </span>
                </div>
            </>
        );

        let bottoneAggiungiSetting;
        let isBottoneVisualizzatoUnaSolaVolta = profiloSelezionato === enumsUtente.profiliUtente.referenteAulss
            || profiloSelezionato === enumsUtente.profiliUtente.medicoRichiedente
            || (profiloSelezionato === enumsUtente.profiliUtente.medicoFormalizzatore && currentRuolo === ruoliSelect.ruoliMedicoFormalizzatore[0]);
        if (isBottoneVisualizzatoUnaSolaVolta) {
            if (profilazioneUtente.length === 0) bottoneAggiungiSetting = (<>{addButton}</>);
            else bottoneAggiungiSetting = (<div className={"col-12"}/>);
        } else {
            if (!this.isUltimaRigaInseritaInvalida(profiloSelezionato, currentRuolo, false)) {
                bottoneAggiungiSetting = (<>{addButton}</>);
            } else {
                bottoneAggiungiSetting = (
                    <PopoverReact
                        body={"Aggiungi setting"}
                        text={
                            <span
                                id={"btnAggiungiRigaItemAccordionBody"}
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    if (!this.isUltimaRigaInseritaInvalida(profiloSelezionato, currentRuolo, false)) {
                                        this.addAccordionItemRow(profiloSelezionato);
                                    }
                                }}>
                                <BsIcon.PlusCircle color={"#176A65"} size={28} className={styles.btnDisabled}/>
                                <span
                                    className={profilazioneUtente.length === 1 && this.isUltimaRigaInseritaInvalida(profiloSelezionato, currentRuolo, false) ? styles.requiredText.concat(" pl-3") : "d-none"}>
                                    {"(Inserire almeno un setting)"}
                                </span>
                            </span>
                        }
                        openOnMouseOver
                        position={"right"}
                        className={"popover-info"}
                        id={"idPopoverAggiungiSettingProfilazione" + profiloSelezionato}
                        textDisplayMode={"block"}
                    />
                );
            }
        }

        let messaggioJSX;
        if(profiloSelezionato === enumsUtente.profiliUtente.centraleAdi){
            messaggioJSX =
                <div className={"row pt-5"}>
                    <div className={"col ".concat(styles.requiredText)}>
                        {MESSAGGIO_INFORMATIVO_CENTRALE_ADI}
                    </div>
                </div>
        }

        return (!Utils.isStringEmptyOrNullOrUndefined(currentRuolo) &&
            <>
                {messaggioJSX}
                <div className={"row py-5 text-left"}>{bottoneAggiungiSetting}</div>
                {itemsInAccordionRowsJSX}
            </>);
    }

    addAccordionItemRow = (profiloSelezionato) => {
        let currentListaGruppi = _.cloneDeep(this.state.listaGruppiPerRigheAccordion);
        let aulss = null;
        let distretto = null;
        if (AuthUtils.hasUtenteRuoloReferenteAulss()) {
            aulss = AulssUtils.getAulssByCodiceAulss(SessioneUtente.getInstance().codiceStruttura);
            aulss = {value: aulss.id, label: "Azienda ".concat(aulss.nome).concat(" ").concat(aulss.descrizione)};

            const codiceDistrettoReferenteAulss = SessioneUtente.getInstance().settings.map(s => s.distretto).filter(d => !!d)[0];
            distretto = AulssUtils.getDistrettoByIdAulssAndIdDistretto(aulss.value, codiceDistrettoReferenteAulss);
            distretto = {value: distretto.id, label: distretto.descrizione};
        }

        currentListaGruppi.push({
            codicegruppo: profiloSelezionato,
            aulss: aulss,
            distretto: distretto,
            comune: null,
            sede: null,
            ambito: null,
            unitaOperativa: null
        });

        this.setState({listaGruppiPerRigheAccordion: currentListaGruppi});
    }

    removeAccordionItemRow = (indexAttributo) => {
        let currentListaGruppi = _.cloneDeep(this.state.listaGruppiPerRigheAccordion);
        currentListaGruppi.splice(indexAttributo, 1);

        this.setState({listaGruppiPerRigheAccordion: currentListaGruppi}, () => this.creaArrayListaGruppi());
    }

    renderInputRigaAccordion = (attributo, profiloSelezionato, currentRuolo,
                                indexRow, listLength, aulssPrimaRiga,
                                distrettoPrimaRiga, unitaOperativaPrimaRiga) => {
        const currentElementIndex = this.state.listaGruppiPerRigheAccordion.indexOf(attributo);
        const showAulssOrDistretto = indexRow === 0;
        const showOnlyOneField = listLength > 1 && showAulssOrDistretto;
        const isRigaDisabledPerModifica = Object.prototype.hasOwnProperty.call(attributo, "isDisabled") && attributo.isDisabled;

        switch (profiloSelezionato) {
            case enumsUtente.profiliUtente.referenteAulss:
                return (
                    <>
                        <div className={"row"}>
                            <div className={"col-3 ml-4 pl-3"}>
                                <span className={"required"}>AULSS</span>
                            </div>
                            <div className={"offset-0 col-3 pl-3"}>
                                <span className={"required"}>Distretto</span>
                            </div>
                        </div>
                        <div className={"row text-left"}>
                            {renderBottoneElimina(listLength, currentElementIndex, this.props.codiceFiscaleUtenteProfilato, this.removeAccordionItemRow)}
                            {this.renderAulssSelect(attributo.aulss, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica)}
                            {this.renderDistrettoSelect(attributo.distretto, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, attributo.aulss?.value)}
                        </div>
                    </>
                );
            case enumsUtente.profiliUtente.medicoRichiedente:
                var showOnlyAulss = _.isEqual(currentRuolo, ruoliSelect.ruoliMedicoRichiedente[2].label);
                return (
                    <>
                        <div className={"row"}>
                            <div className={"col-3 ml-4 pl-3"}>
                                <span className={"required"}>AULSS</span>
                            </div>
                            {!showOnlyAulss && <div className={"offset-0 col-3 pl-3"}>
                                <span className={"required"}>Distretto</span>
                            </div>}
                        </div>
                        <div className={"row text-left"}>
                            {renderBottoneElimina(listLength, currentElementIndex, this.props.codiceFiscaleUtenteProfilato, this.removeAccordionItemRow)}
                            {this.renderAulssSelect(attributo.aulss, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica)}
                            {!showOnlyAulss && this.renderDistrettoSelect(attributo.distretto, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, attributo.aulss?.value)}
                        </div>
                    </>
                );
            case enumsUtente.profiliUtente.medicoFormalizzatore:
                var aulss = !showAulssOrDistretto ? aulssPrimaRiga.value : attributo.aulss?.value;
                return (
                    <>
                        <div className={indexRow === 0 ? "row" : "d-none"}>
                            <div className={"col-3 ml-4 pl-3"}>
                                <span className={"required"}>AULSS</span>
                            </div>
                            <div className={"offset-0 col-3 pl-3"}>
                                <span className={"required"}>Distretto</span>
                            </div>
                            {_.isEqual(this.currentRuoloMedicoFormalizzatore, ruoliSelect.ruoliMedicoFormalizzatore[1])
                                ? <div className={"offset-0 col-3 pl-3"}>
                                    <span className={"required"}>Ambito</span>
                                </div>
                                : null}
                        </div>
                        <div className={"row text-left"}>
                            {renderBottoneElimina(listLength, currentElementIndex, this.props.codiceFiscaleUtenteProfilato, this.removeAccordionItemRow)}
                            {!showAulssOrDistretto
                                ? <div className={"col-3 pb-sm-2"}/>
                                : this.renderAulssSelect(attributo.aulss, profiloSelezionato, currentElementIndex, showOnlyOneField || isRigaDisabledPerModifica)}
                            {this.renderDistrettoSelect(attributo.distretto, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, aulss)}
                            {_.isEqual(this.currentRuoloMedicoFormalizzatore, ruoliSelect.ruoliMedicoFormalizzatore[1])
                                ? this.renderAmbitoSelect(attributo.ambito, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, aulss, attributo?.distretto?.value)
                                : null}
                        </div>
                    </>
                );
            case enumsUtente.profiliUtente.professionistaValutatore:
                switch (currentRuolo) {
                    case ruoliSelect.ruoliProfessionistaValutatore[0].label:
                        var aulss2 = !showAulssOrDistretto ? aulssPrimaRiga.value : attributo.aulss?.value;
                        return (
                            <>
                                <div className={indexRow === 0 ? "row" : "d-none"}>
                                    <div className={"col-3 ml-4 pl-3"}>
                                        <span className={"required"}>AULSS</span>
                                    </div>
                                    <div className={"offset-0 col-3 pl-3"}>
                                        <span className={"required"}>Distretto</span>
                                    </div>
                                    <div className={attributo.distretto ? "offset-0 col-3 pl-3" : "d-none"}>
                                        <span className={"required"}>Sede</span>
                                    </div>
                                </div>
                                <div className={"row text-left"}>
                                    {renderBottoneElimina(listLength, currentElementIndex, this.props.codiceFiscaleUtenteProfilato, this.removeAccordionItemRow)}
                                    {!showAulssOrDistretto
                                        ? <div className={"col-3 pb-sm-2"}/>
                                        : this.renderAulssSelect(attributo.aulss, profiloSelezionato, currentElementIndex, showOnlyOneField || isRigaDisabledPerModifica)}
                                    {this.renderDistrettoSelect(attributo.distretto, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, aulss2)}
                                    {!attributo.distretto
                                        ? <Fragment/>
                                        : this.renderSedeSelect(attributo.sede, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, aulss2, attributo.distretto?.value)}
                                </div>
                            </>
                        );
                    case ruoliSelect.ruoliProfessionistaValutatore[1].label:
                    case ruoliSelect.ruoliProfessionistaValutatore[2].label:
                    case ruoliSelect.ruoliProfessionistaValutatore[4].label:
                    case ruoliSelect.ruoliProfessionistaValutatore[5].label:
                    case ruoliSelect.ruoliProfessionistaValutatore[6].label:
                    case ruoliSelect.ruoliProfessionistaValutatore[7].label:
                        var aulss3 = !showAulssOrDistretto ? aulssPrimaRiga.value : attributo.aulss?.value;
                        return (
                            <>
                                <div className={indexRow === 0 ? "row" : "d-none"}>
                                    <div className={"col-3 ml-4 pl-3"}>
                                        <span className={"required"}>AULSS</span>
                                    </div>
                                    {ruoliAdiValConPossibileUnitaOperativa.includes(this.currentRuoloProfessionistaValutatore)
                                        && <div className={"offset-0 col-3 pl-3"}>
                                            <span className={"required"}>Unità Operativa</span>
                                        </div>}
                                    {!ruoliAdiValConPossibileUnitaOperativa.includes(this.currentRuoloProfessionistaValutatore)
                                        && <div className={"offset-0 col-3 pl-3"}>
                                            <span className={"required"}>Distretto</span>
                                        </div>}
                                </div>
                                <div className={"row text-left"}>
                                    {renderBottoneElimina(listLength, currentElementIndex, this.props.codiceFiscaleUtenteProfilato, this.removeAccordionItemRow)}
                                    {!showAulssOrDistretto
                                        ? <div className={"col-3 pb-sm-2"}/>
                                        : this.renderAulssSelect(attributo.aulss, profiloSelezionato, currentElementIndex, showOnlyOneField || isRigaDisabledPerModifica)}
                                    {ruoliAdiValConPossibileUnitaOperativa.includes(this.currentRuoloProfessionistaValutatore)
                                        && this.renderUnitaOperativaSelect(attributo.unitaOperativa, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, aulss3)}
                                    {!ruoliAdiValConPossibileUnitaOperativa.includes(this.currentRuoloProfessionistaValutatore)
                                        && this.renderDistrettoSelect(attributo.distretto, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, aulss3)}
                                </div>
                            </>
                        );
                    case ruoliSelect.ruoliProfessionistaValutatore[3].label:
                        return (
                            <>
                                <div className={indexRow === 0 ? "row" : "d-none"}>
                                    <div className={"col-3 ml-4 pl-3"}>
                                        <span className={"required"}>AULSS</span>
                                    </div>
                                    <div className={"offset-0 col-3 pl-3"}>
                                        <span className={"required"}>Distretto</span>
                                    </div>
                                    <div className={"offset-0 col-3 pl-3"}>
                                        <span className={"required"}>Comune</span>
                                    </div>
                                </div>
                                <div className={"row text-left"}>
                                    {renderBottoneElimina(listLength, currentElementIndex, this.props.codiceFiscaleUtenteProfilato, this.removeAccordionItemRow)}
                                    {this.renderAulssSelect(attributo.aulss, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica)}
                                    {this.renderDistrettoSelect(attributo.distretto, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, attributo.aulss?.value)}
                                    {this.renderComuneSelect(attributo.comune, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, attributo.aulss?.value)}
                                </div>
                            </>
                        );
                    default:
                        break;
                }
                break;
            case enumsUtente.profiliUtente.coordinatore:
                var aulss4 = !showAulssOrDistretto ? aulssPrimaRiga.value : attributo.aulss?.value;
                var distretto = !showAulssOrDistretto ? distrettoPrimaRiga?.value : attributo.distretto?.value;
                return (
                    <>
                        <div className={indexRow === 0 ? "row" : "d-none"}>
                            <div className={"col-3 ml-4 pl-3"}>
                                <span className={"required"}>AULSS</span>
                            </div>
                            <div className={"offset-0 col-3 pl-3"}>
                                <span className={"required"}>Distretto</span>
                            </div>
                            <div className={attributo.distretto ? "offset-0 col-3 pl-3" : "d-none"}>
                                <span className={"required"}>Sede</span>
                            </div>
                        </div>
                        <div className={"row text-left"}>
                            {renderBottoneElimina(listLength, currentElementIndex, this.props.codiceFiscaleUtenteProfilato, this.removeAccordionItemRow)}
                            {!showAulssOrDistretto
                                ? <div className={"col-3 pb-sm-2"}/>
                                : this.renderAulssSelect(attributo.aulss, profiloSelezionato, currentElementIndex, showOnlyOneField || isRigaDisabledPerModifica)}
                            {!showAulssOrDistretto
                                ? <div className={"offset-0 col-3 pb-sm-2"}/>
                                : this.renderDistrettoSelect(attributo.distretto, profiloSelezionato, currentElementIndex, showOnlyOneField || isRigaDisabledPerModifica, aulss4)}
                            {(distretto === undefined || distretto === null)
                                ? <Fragment/>
                                : this.renderSedeSelect(attributo.sede, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, aulss4, distretto)}
                        </div>
                    </>
                );
            case enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali:
                return (
                    <>
                        <div className={indexRow === 0 ? "row" : "d-none"}>
                            <div className={"col-3 ml-4 pl-3"}>
                                <span className={"required"}>AULSS</span>
                            </div>
                            <div className={"offset-0 col-3 pl-3"}>
                                <span className={"required"}>Distretto</span>
                            </div>
                            <div className={"offset-0 col-3 pl-3"}>
                                <span className={"required"}>Comune</span>
                            </div>
                        </div>
                        <div className={"row text-left"}>
                            {renderBottoneElimina(listLength, currentElementIndex, this.props.codiceFiscaleUtenteProfilato, this.removeAccordionItemRow)}
                            {this.renderAulssSelect(attributo.aulss, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica)}
                            {this.renderDistrettoSelect(attributo.distretto, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, attributo.aulss?.value)}
                            {this.renderComuneSelect(attributo.comune, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, attributo.aulss?.value)}
                        </div>
                    </>
                );
            case enumsUtente.profiliUtente.direttoreUnitaOperativa:
                var aulss5 = !showAulssOrDistretto ? aulssPrimaRiga.value : attributo.aulss?.value;
                var unitaOperativa = indexRow > 0 ? unitaOperativaPrimaRiga : attributo.unitaOperativa;
                return (
                    <>
                        <div className={indexRow === 0 ? "row" : "d-none"}>
                            <div className={"col-3 ml-4 pl-3"}>
                                <span className={"required"}>AULSS</span>
                            </div>
                            <div className={"offset-0 col-3 pl-3"}>
                                <span className={"required"}>Unità Operativa</span>
                            </div>
                            <div className={"offset-0 col-3 pl-3"}>
                                <span className={"required"}>Distretto</span>
                            </div>
                        </div>
                        <div className={"row text-left"}>
                            {renderBottoneElimina(listLength, currentElementIndex, this.props.codiceFiscaleUtenteProfilato, this.removeAccordionItemRow)}
                            {!showAulssOrDistretto
                                ? <div className={"col-3 pb-sm-2"}/>
                                : this.renderAulssSelect(attributo.aulss, profiloSelezionato, currentElementIndex, showOnlyOneField || isRigaDisabledPerModifica)}
                            {this.renderUnitaOperativaSelect(unitaOperativa, profiloSelezionato, currentElementIndex, (listLength > 1 && indexRow > 0) || isRigaDisabledPerModifica, aulss5)}
                            {this.renderDistrettoSelect(attributo.distretto, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, aulss5)}
                        </div>
                    </>
                );
            case enumsUtente.profiliUtente.centraleAdi:
                var aulss6 = !showAulssOrDistretto ? aulssPrimaRiga.value : attributo.aulss?.value;
                return (
                    <>
                        <div className={indexRow === 0 ? "row" : "d-none"}>
                            <div className={"col-2 ml-4 pl-3"}>
                                <span className={"required"}>AULSS</span>
                            </div>
                            <div className={"offset-0 col-2 pl-3"}>
                                <span className={"required"}>Distretto</span>
                            </div>
                            <div className={attributo.distretto ? "offset-0 col-2 pl-3" : "d-none"}>
                                <span className={"required"}>Sede</span>
                            </div>
                            <div className={"offset-0 col-2 pl-3"}>
                                <span className={"required"}>Ambito</span>
                            </div>
                        </div>
                        <div className={"row text-left"}>
                            {renderBottoneElimina(listLength, currentElementIndex, this.props.codiceFiscaleUtenteProfilato, this.removeAccordionItemRow)}
                            {!showAulssOrDistretto
                                ? <div className={"col-2 pb-sm-2"}/>
                                : this.renderAulssSelect(attributo.aulss, profiloSelezionato, currentElementIndex, showOnlyOneField || isRigaDisabledPerModifica, 'col-2')}
                            {this.renderDistrettoSelect(attributo.distretto, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, aulss6, 'col-2')}
                            {attributo.distretto?.value != null
                                ? this.renderSedeSelect(attributo.sede, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, aulss6, attributo?.distretto?.value, 'col-2')
                                : <Fragment/>}
                            {this.renderAmbitoSelect(attributo.ambito, profiloSelezionato, currentElementIndex, isRigaDisabledPerModifica, aulss6, attributo?.distretto?.value, 'col-2')}
                        </div>
                    </>
                );
            default:
                break;
        }
    }

    renderAulssSelect = (attributo, profiloSelezionato, index, isSelectDisabled, colClass = null) => {
        let options = _.cloneDeep(this.listaAulssSelect);
        if (profiloSelezionato === enumsUtente.profiliUtente.centraleAdi && this.state.centraliAdiCensite) {
            let aulssCentraleAdiCensite = _.map(this.state.centraliAdiCensite, centraleAdi => centraleAdi.aulss);
            options = _.filter(options, aulss => aulssCentraleAdiCensite?.includes(aulss.value));
        }
        const aulssSelect = <Select
            id={"selectAulss"}
            className={enumAttributo.aulss.value}
            classNamePrefix={"inputSelect"}
            components={DropdownIndicator}
            styles={inputSelectStyles}
            options={options}
            value={attributo}
            onChange={(elem) => {
                this.onChangeListaRigheAccordion(enumAttributo.aulss.value, elem, profiloSelezionato, index, !_.isEqual(elem, attributo));
            }}
            isSearchable={false}
            noOptionsMessage={() => "Errore: AULSS non esistente"}
            isDisabled={AuthUtils.hasUtenteRuoloReferenteAulss() || isSelectDisabled}
            placeholder={"Seleziona"}
        />;
        return (
            <div className={`${colClass ?? 'col-3'} pb-sm-2`}>
                {!Utils.isObjectNull(attributo)
                    ? <PopoverReact
                        body={attributo.label}
                        text={aulssSelect}
                        openOnMouseOver
                        position={"top"}
                        className={"popover-info"}
                        arrowStyle={{display: "none"}}
                        id={"popoverInserimentoModificaUtenteProfilato" + profiloSelezionato + index}
                        textDisplayMode={"block"}
                    />
                    : aulssSelect
                }
            </div>
        );
    }

    renderDistrettoSelect = (attributo, profiloSelezionato, index, isSelectDisabled, idAulss, colClass = null) => {
        let distretti = AulssUtils.getDistrettiByAulssId(idAulss);
        distretti = distretti.map(el => ({value: el.id, label: el.descrizione}));
        if (profiloSelezionato === enumsUtente.profiliUtente.centraleAdi && this.state.centraliAdiCensite) {
            let centraliAdiAulss = _.filter(this.state.centraliAdiCensite, centraleAdi => centraleAdi.aulss === idAulss);
            let distrettiCentraleAdiCensite = _.map(centraliAdiAulss, centraleAdi => centraleAdi.distretto);
            distretti = _.filter(distretti, distretto => distrettiCentraleAdiCensite?.includes(distretto.value));
        }
        return (
            <div className={`${colClass ?? 'col-3'} pb-sm-2`}>
                <Select
                    id={"selectDistretto"}
                    className={"distretti"}
                    classNamePrefix={"inputSelect"}
                    components={DropdownIndicator}
                    styles={inputSelectStyles}
                    options={distretti}
                    value={attributo}
                    onChange={(elem) => {
                        this.onChangeListaRigheAccordion(enumAttributo.distretto.value, elem, profiloSelezionato, index, !_.isEqual(elem, attributo));
                    }}
                    isSearchable={false}
                    noOptionsMessage={() => distretti.length
                        ? "Errore: Distretto non esistente"
                        : "Inserire un AULSS prima di selezionare un distretto"}
                    isDisabled={AuthUtils.hasUtenteRuoloReferenteAulss() || isSelectDisabled}
                    placeholder={"Seleziona"}
                />
            </div>
        );
    }

    renderUnitaOperativaSelect = (attributo, profiloSelezionato, index, isSelectDisabled, idAulss) => {
        const unitaOperativeByAulss = AulssUtils.getUnitaOperativeByIdAulss(idAulss);
        const unitaOperativaSelect = <Select
            id={"selectUnitaOperativa"}
            className={"unitaOperativeByAulss"}
            classNamePrefix={"inputSelect"}
            components={DropdownIndicator}
            styles={inputSelectStyles}
            options={unitaOperativeByAulss}
            value={attributo}
            onChange={(elem) => {
                this.onChangeListaRigheAccordion(enumAttributo.unitaOperativa.value, elem, profiloSelezionato, index, !_.isEqual(elem, attributo));
            }}
            isSearchable={false}
            noOptionsMessage={() => unitaOperativeByAulss.length
                ? "Errore: Unità operativa non esistente"
                : "Inserire un AULSS prima di selezionare un'unità operativa"}
            isDisabled={isSelectDisabled}
            placeholder={"Seleziona"}
        />;

        return (
            <div className={"col-3 pb-sm-2"}>
                {!Utils.isObjectNull(attributo)
                    ? <PopoverReact
                        body={attributo.label}
                        text={unitaOperativaSelect}
                        openOnMouseOver
                        position={"top"}
                        className={"popover-info"}
                        arrowStyle={{display: "none"}}
                        id={"popoverInserimentoModificaUtenteProfilato" + profiloSelezionato + index}
                        textDisplayMode={"block"}
                    />
                    : unitaOperativaSelect
                }
            </div>
        );
    }

    renderAmbitoSelect = (attributo, profiloSelezionato, index, isSelectDisabled, idAulss, idDistretto, colClass = null) => {
        let ambiti = AulssUtils.getAmbitiListByIdAulssAndIdDistretto(idAulss, idDistretto);
        ambiti = ambiti.map(el => ({value: el.id, label: el.descrizione}));

        if (profiloSelezionato === enumsUtente.profiliUtente.centraleAdi && this.state.centraliAdiCensite) {
            let centraliAdiAulss = _.filter(this.state.centraliAdiCensite, centraleAdi => centraleAdi.aulss === idAulss && centraleAdi.distretto === idDistretto);
            let ambitiCentraleAdiCensite = _.flatMap(centraliAdiAulss, centraleAdi => _.map(centraleAdi.listaCentrali, c => c.ambito));
            ambiti = _.filter(ambiti, ambito => ambitiCentraleAdiCensite?.includes(ambito.value));
        }

        return (
            <div className={`${colClass ?? 'col-3'} pb-sm-2`}>
                <Select
                    id={"selectAmbito"}
                    className={"ambiti"}
                    classNamePrefix={"inputSelect"}
                    components={DropdownIndicator}
                    styles={inputSelectStyles}
                    options={ambiti}
                    value={attributo}
                    onChange={(elem) => {
                        this.onChangeListaRigheAccordion(enumAttributo.ambito.value, elem, profiloSelezionato, index, !_.isEqual(elem, attributo));
                    }}
                    isSearchable={false}
                    noOptionsMessage={() => {
                        if (ambiti.length > 0) {
                            return "Errore: Ambito non esistente"
                        } else {
                            return "Inserire un AULSS e Distretto prima di selezionare un ambito";
                        }
                    }}
                    isDisabled={isSelectDisabled}
                    placeholder={"Seleziona"}
                />
            </div>
        );
    }

    renderComuneSelect = (attributo, profiloSelezionato, index, isSelectDisabled, idAulss) => {
        let comuniAulss = AulssUtils.getComuniByAulssId(idAulss);
        let listaComuniSelect = [];
        let listaComuniTemp = ToponomasticaUtils.getComuniValidi(JSON.parse(sessionStorage.getItem("comuni")))?.filter(comune => comuniAulss.some(comuneAulss => comuneAulss === comune.codice)) ?? [];
        listaComuniTemp.forEach(comune => listaComuniSelect.push({value: comune.codice, label: comune.descrizione}));

        return (
            <div className={"col-3 pb-sm-2"}>
                <Select
                    id={"selectComuni"}
                    className={"comuni"}
                    classNamePrefix={"inputSelect"}
                    components={DropdownIndicator}
                    styles={inputSelectStyles}
                    options={listaComuniSelect}
                    value={attributo}
                    onChange={(elem) => {
                        this.onChangeListaRigheAccordion(enumAttributo.comune.value, elem, profiloSelezionato, index);
                    }}
                    isSearchable={false}
                    noOptionsMessage={() => listaComuniSelect.length
                        ? "Errore: Comune non esistente"
                        : "Inserire un AULSS prima di selezionare un comune"}
                    isDisabled={isSelectDisabled}
                    placeholder={"Seleziona"}
                />
            </div>
        );
    }

    renderSedeSelect = (attributo, profiloSelezionato, index, isSelectDisabled, idAulss, idDistretto, colClass = null) => {
        let sedi = AulssUtils.getSediByAulssIdAndDistrettoId(idAulss, idDistretto);
        sedi = sedi.map(el => {
            return {value: el.id, label: el.nome};
        });

        return (
            <div className={`${colClass ?? 'col-3'} pb-sm-2`}>
                <Select
                    id={"selectSedi"}
                    className={"sedi"}
                    classNamePrefix={"inputSelect"}
                    components={DropdownIndicator}
                    styles={inputSelectStyles}
                    options={sedi}
                    value={attributo}
                    onChange={(elem) => {
                        this.onChangeListaRigheAccordion(enumAttributo.sede.value, elem, profiloSelezionato, index);
                    }}
                    isSearchable={false}
                    noOptionsMessage={() => sedi.length
                        ? "Errore: Sede non esistente"
                        : "Inserire un distretto prima di selezionare una sede"}
                    isDisabled={isSelectDisabled}
                    placeholder={"Seleziona"}
                />
            </div>
        );
    }

    isUltimaRigaInseritaInvalida = (profiloSelezionato, currentRuolo, isFlagBottoneCrea) => {
        let copiaRigheAccordion = _.cloneDeep(this.state.listaGruppiPerRigheAccordion);
        let righeByProfiloSelezionato = copiaRigheAccordion.filter(riga => riga.codicegruppo === profiloSelezionato);
        if (righeByProfiloSelezionato.length > 0) {
            let ultimaRigaInserita = isFlagBottoneCrea ? righeByProfiloSelezionato[0] : righeByProfiloSelezionato[righeByProfiloSelezionato.length - 1];
            switch (profiloSelezionato) {
                case enumsUtente.profiliUtente.medicoRichiedente:
                    return _.isEqual(currentRuolo, ruoliSelect.ruoliMedicoRichiedente[2])
                        ? ultimaRigaInserita.aulss === null
                        : ultimaRigaInserita.aulss === null || ultimaRigaInserita.distretto === null;
                case enumsUtente.profiliUtente.medicoFormalizzatore:
                    if (_.isEqual(currentRuolo, ruoliSelect.ruoliMedicoFormalizzatore[1])) {
                        return righeByProfiloSelezionato.length === 0
                            ? (ultimaRigaInserita.aulss === null || ultimaRigaInserita.distretto === null || ultimaRigaInserita.ambito === null)
                            : (ultimaRigaInserita.distretto === null || ultimaRigaInserita.ambito === null);
                    } else {
                        return ultimaRigaInserita.aulss === null || ultimaRigaInserita.distretto === null;
                    }
                case enumsUtente.profiliUtente.professionistaValutatore:
                    switch (currentRuolo) {
                        case ruoliSelect.ruoliProfessionistaValutatore[0]:
                            return righeByProfiloSelezionato.length === 0
                                ? (ultimaRigaInserita.aulss === null || ultimaRigaInserita.distretto === null || ultimaRigaInserita.sede === null)
                                : (ultimaRigaInserita.distretto === null || ultimaRigaInserita.sede === null);
                        case ruoliSelect.ruoliProfessionistaValutatore[1]:
                        case ruoliSelect.ruoliProfessionistaValutatore[2]:
                        case ruoliSelect.ruoliProfessionistaValutatore[4]:
                        case ruoliSelect.ruoliProfessionistaValutatore[5]:
                        case ruoliSelect.ruoliProfessionistaValutatore[6]:
                        case ruoliSelect.ruoliProfessionistaValutatore[7]:
                            var isUltimaRigaInseritaInvalida;
                            if (ruoliAdiValConPossibileUnitaOperativa.includes(currentRuolo)) {
                                isUltimaRigaInseritaInvalida = !righeByProfiloSelezionato.length
                                    ? (ultimaRigaInserita.aulss === null || ultimaRigaInserita.unitaOperativa === null)
                                    : ultimaRigaInserita.unitaOperativa === null;
                            } else {
                                isUltimaRigaInseritaInvalida = !righeByProfiloSelezionato.length
                                    ? (ultimaRigaInserita.aulss === null || ultimaRigaInserita.distretto === null)
                                    : ultimaRigaInserita.distretto === null;
                            }
                            return isUltimaRigaInseritaInvalida;
                        case ruoliSelect.ruoliProfessionistaValutatore[3]:
                            return ultimaRigaInserita.aulss === null || ultimaRigaInserita.distretto === null || ultimaRigaInserita.comune === null;
                        default:
                            return ultimaRigaInserita.aulss === null || ultimaRigaInserita.distretto === null;
                    }
                case enumsUtente.profiliUtente.coordinatore:
                    return righeByProfiloSelezionato.length === 0
                        ? (ultimaRigaInserita.aulss === null || ultimaRigaInserita.distretto === null || ultimaRigaInserita.sede === null)
                        : (ultimaRigaInserita.sede === null);
                case enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali:
                    return ultimaRigaInserita.aulss === null || ultimaRigaInserita.distretto === null || ultimaRigaInserita.comune === null;
                case enumsUtente.profiliUtente.direttoreUnitaOperativa:
                    return righeByProfiloSelezionato.length === 0
                        ? (ultimaRigaInserita.aulss === null || ultimaRigaInserita.distretto === null || ultimaRigaInserita.unitaOperativa === null)
                        : ultimaRigaInserita.distretto === null;
                case enumsUtente.profiliUtente.centraleAdi:
                    return righeByProfiloSelezionato.length === 0
                        ? (ultimaRigaInserita.aulss === null || ultimaRigaInserita.distretto === null || ultimaRigaInserita.sede === null || ultimaRigaInserita.ambito === null)
                        : (ultimaRigaInserita.distretto === null || ultimaRigaInserita.sede === null || ultimaRigaInserita.ambito === null);
                default:
                    return ultimaRigaInserita.aulss === null || ultimaRigaInserita.distretto === null;
            }
        } else {
            return false;
        }
    }

    isPulsanteAnnullaDisabled = (utenteapiapms) => {
        let isDisabled = Object.keys(utenteapiapms).every(key => Utils.isStringEmptyOrNullOrUndefined(utenteapiapms[key]));

        return isDisabled && !this.state.amministratore && !this.state.referenteAulss
            && !this.state.medicoRichiedente && !this.state.medicoFormalizzatore
            && !this.state.professionistaValutatore && !this.state.coordinatore
            && !this.state.referenteDirigenteAssistentiSociali && !this.state.direttoreUnitaOperativa
            && !this.state.centraleAdi && this.state.listaGruppiPerRigheAccordion.length === 0;
    }

    isPulsanteCreaUtenteDisabled = (utenteapiapms) => {
        let cognomeInvalido = Utils.isStringEmptyOrNullOrUndefined(utenteapiapms.cognome);
        let nomeInvalido = Utils.isStringEmptyOrNullOrUndefined(utenteapiapms.nome);
        let codiceFiscaleInValido = Utils.isStringEmptyOrNullOrUndefined(utenteapiapms.codiceFiscale) || !Utils.isValidCodiceFiscale(utenteapiapms.codiceFiscale);
        let emailInValida = Utils.isStringEmptyOrNullOrUndefined(utenteapiapms.mail) || !Utils.isValidEmail(utenteapiapms.mail);

        let areTuttiProfiliFalse = !this.state.amministratore && !this.state.referenteAulss
            && !this.state.medicoRichiedente && !this.state.medicoFormalizzatore
            && !this.state.professionistaValutatore && !this.state.coordinatore
            && !this.state.referenteDirigenteAssistentiSociali && !this.state.direttoreUnitaOperativa
            && !this.state.centraleAdi;
        let isProfiloAmministratoreInvalido;
        let isProfiloReferenteAulssInvalido;
        let isProfiloMedicoRichiedenteInvalido;
        let isProfiloMedicoFormalizzatoreInvalido;
        let isProfiloProfessionistaValutatoreInvalido;
        let isProfiloCoordinatoreInvalido;
        let isProfiloReferenteDirigenteAssistentiSocialiInvalido;
        let isProfiloDirettoreUnitaOperativaInvalido;
        let isProfiloCentraleAdiInvalido;

        // controlli Amministratore
        isProfiloAmministratoreInvalido = this.state.amministratore
            && (this.state.referenteAulss || this.state.medicoRichiedente
                || this.state.medicoFormalizzatore || this.state.professionistaValutatore
                || this.state.coordinatore || this.state.referenteDirigenteAssistentiSociali
                || this.state.direttoreUnitaOperativa || this.state.centraleAdi);

        // controlli Referente Aulss
        let ruoloReferenteAulss = this.currentRuoloReferenteAulss;
        let righeReferenteAulss = this.state.listaGruppiPerRigheAccordion.filter(riga => riga.codicegruppo === enumsUtente.profiliUtente.referenteAulss);
        isProfiloReferenteAulssInvalido = this.state.referenteAulss
            && (ruoloReferenteAulss === null || righeReferenteAulss.length === 0 || this.isUltimaRigaInseritaInvalida(enumsUtente.profiliUtente.referenteAulss, ruoloReferenteAulss, true));

        // controlli Medico Richiedente
        let ruoloMedicoRichiedente = this.currentRuoloMedicoRichiedente;
        let righeMedicoRichiedente = this.state.listaGruppiPerRigheAccordion.filter(riga => riga.codicegruppo === enumsUtente.profiliUtente.medicoRichiedente);
        isProfiloMedicoRichiedenteInvalido = this.state.medicoRichiedente
            && (ruoloMedicoRichiedente === null || righeMedicoRichiedente.length === 0 || this.isUltimaRigaInseritaInvalida(enumsUtente.profiliUtente.medicoRichiedente, ruoloMedicoRichiedente, true));

        // controlli Medico Formalizzatore
        let ruoloMedicoFormalizzatore = this.currentRuoloMedicoFormalizzatore;
        let righeMedicoFormalizzatore = this.state.listaGruppiPerRigheAccordion.filter(riga => riga.codicegruppo === enumsUtente.profiliUtente.medicoFormalizzatore);
        isProfiloMedicoFormalizzatoreInvalido = this.state.medicoFormalizzatore
            && (ruoloMedicoFormalizzatore === null || righeMedicoFormalizzatore.length === 0
                || this.isUltimaRigaInseritaInvalida(enumsUtente.profiliUtente.medicoFormalizzatore, ruoloMedicoFormalizzatore, true));

        // controlli Professionista Valutatore
        let ruoloProfessionistaValutatore = this.currentRuoloProfessionistaValutatore;
        let isDisciplinaProfessionistaValutatoreValid = true;
        if (!Utils.isObjectNull(ruoloProfessionistaValutatore)
            && (_.isEqual(ruoloProfessionistaValutatore, ruoliSelect.ruoliProfessionistaValutatore[1])
                || _.isEqual(ruoloProfessionistaValutatore, ruoliSelect.ruoliProfessionistaValutatore[2]))) {
            isDisciplinaProfessionistaValutatoreValid = !Utils.isObjectNull(this.currentDisciplinaProfessionistaValutatore);
        }
        let righeProfessionistaValutatore = this.state.listaGruppiPerRigheAccordion.filter(riga => riga.codicegruppo === enumsUtente.profiliUtente.professionistaValutatore);
        isProfiloProfessionistaValutatoreInvalido = this.state.professionistaValutatore
            && (ruoloProfessionistaValutatore === null
                || !isDisciplinaProfessionistaValutatoreValid || righeProfessionistaValutatore.length === 0
                || this.isUltimaRigaInseritaInvalida(enumsUtente.profiliUtente.professionistaValutatore, ruoloProfessionistaValutatore, true));

        // controlli Coordinatore
        let ruoloCoordinatore = this.currentRuoloCoordinatore;
        let righeCoordinatore = this.state.listaGruppiPerRigheAccordion.filter(riga => riga.codicegruppo === enumsUtente.profiliUtente.coordinatore);
        isProfiloCoordinatoreInvalido = this.state.coordinatore
            && (ruoloCoordinatore === null || righeCoordinatore.length === 0
                || this.isUltimaRigaInseritaInvalida(enumsUtente.profiliUtente.coordinatore, ruoloCoordinatore, true));

        // controlli Referente/Dirigente Assistenti Sociali
        let ruoloRefDirAssistentiSociali = this.currentRuoloReferenteDirigenteAssistentiSociali;
        let righeRefDirAssistentiSociali = this.state.listaGruppiPerRigheAccordion.filter(riga => riga.codicegruppo === enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali);
        isProfiloReferenteDirigenteAssistentiSocialiInvalido = this.state.referenteDirigenteAssistentiSociali
            && (ruoloRefDirAssistentiSociali === null || righeRefDirAssistentiSociali.length === 0
                || this.isUltimaRigaInseritaInvalida(enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali, ruoloRefDirAssistentiSociali, true));

        // controlli Direttore Di Unità Operativa
        let ruoloDirettoreUnitaOperativa = this.currentRuoloDirettoreUnitaOperativa;
        let isDisciplinaDirettoreUnitaOperativaValid = true;
        if (!Utils.isObjectNull(ruoloDirettoreUnitaOperativa)
            && (_.isEqual(ruoloDirettoreUnitaOperativa, ruoliSelect.ruoliDirettoreDiUnitaOperativa[0])
                || _.isEqual(ruoloDirettoreUnitaOperativa, ruoliSelect.ruoliDirettoreDiUnitaOperativa[1]))) {
            isDisciplinaDirettoreUnitaOperativaValid = !Utils.isObjectNull(this.currentDisciplinaDirettoreUnitaOperativa);
        }
        let righeDirettoreUnitaOperativa = this.state.listaGruppiPerRigheAccordion.filter(riga => riga.codicegruppo === enumsUtente.profiliUtente.direttoreUnitaOperativa);
        isProfiloDirettoreUnitaOperativaInvalido = this.state.direttoreUnitaOperativa
            && (ruoloDirettoreUnitaOperativa === null
                || !isDisciplinaDirettoreUnitaOperativaValid || righeDirettoreUnitaOperativa.length === 0
                || this.isUltimaRigaInseritaInvalida(enumsUtente.profiliUtente.direttoreUnitaOperativa, ruoloDirettoreUnitaOperativa, true));

        // Controlli Centrale ADI
        let ruoloCentraleAdi = this.currentRuoloCentraleAdi;
        let righeCentraleAdi = this.state.listaGruppiPerRigheAccordion.filter(riga => riga.codicegruppo === enumsUtente.profiliUtente.centraleAdi);
        isProfiloCentraleAdiInvalido = this.state.centraleAdi
            && (ruoloCentraleAdi === null || righeCentraleAdi.length === 0 || this.isUltimaRigaInseritaInvalida(enumsUtente.profiliUtente.centraleAdi, ruoloCentraleAdi, true));

        return cognomeInvalido || nomeInvalido || codiceFiscaleInValido || emailInValida || areTuttiProfiliFalse
            || isProfiloAmministratoreInvalido || isProfiloReferenteAulssInvalido || isProfiloMedicoRichiedenteInvalido
            || isProfiloMedicoFormalizzatoreInvalido || isProfiloProfessionistaValutatoreInvalido
            || isProfiloCoordinatoreInvalido || isProfiloReferenteDirigenteAssistentiSocialiInvalido
            || isProfiloDirettoreUnitaOperativaInvalido || isProfiloCentraleAdiInvalido;
    }

    annullaModifiche = () => {
        this.currentRuoloReferenteAulss = null;
        this.currentRuoloMedicoRichiedente = null;
        this.currentRuoloMedicoFormalizzatore = null;
        this.currentRuoloProfessionistaValutatore = null;
        this.currentRuoloCoordinatore = null;
        this.currentRuoloReferenteDirigenteAssistentiSociali = null;
        this.currentRuoloDirettoreUnitaOperativa = null;
        this.currentRuoloCentraleAdi = null;

        this.currentDisciplinaProfessionistaValutatore = null;
        this.currentDisciplinaDirettoreUnitaOperativa = null;

        this.setState({
            utenteapiapms: _.cloneDeep(defaultUtenteapiapms),
            listaGruppiPerRigheAccordion: [],
            oldListaGruppi: null,
            amministratore: false,
            referenteAulss: false,
            medicoRichiedente: false,
            medicoFormalizzatore: false,
            professionistaValutatore: false,
            coordinatore: false,
            referenteDirigenteAssistentiSociali: false,
            direttoreUnitaOperativa: false,
            centraleAdi: false
        });
    }

    formattaMessaggio = (messaggio, isUtenteAlreadyPresentOnDb, valori = []) => {
        let formatMessage = {...messaggio};
        let replacedValues = [];

        if (this.isInsertMode() && isUtenteAlreadyPresentOnDb) replacedValues.push("l'utente è già presente nel sistema; ")
        replacedValues.push(...valori);
        replacedValues.forEach((e, index) => formatMessage.text = formatMessage.text.replace('{' + index + '}', e));
        formatMessage.text = formatMessage.text.replace(/{\d+}/g, '');

        return formatMessage;
    }

    validaListaGruppi = () => {
        let listaGruppiRighePerAccordion = _.cloneDeep(this.state.listaGruppiPerRigheAccordion);
        const isUtentePresenteSuDb = this.state.oldListaGruppi !== null && this.state.oldListaGruppi.length > 0;

        if (Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato) && isUtentePresenteSuDb
            && this.state.oldListaGruppi.find(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.admin)
            && !this.state.amministratore) {
            Utils.toasterFunction(messaggi.alertInserimentoUtenteConNuovoProfiloMaProfiloPrecedenteAmministratore);
            return false;
        } else {
            let listaGruppiCompletaTemp = _.cloneDeep(this.state.oldListaGruppi);
            let listaGruppiRigheTemp = _.cloneDeep(listaGruppiRighePerAccordion);
            listaGruppiCompletaTemp = listaGruppiCompletaTemp.map(gruppo => {
                delete gruppo.isDisabled;
                if (gruppo.codicegruppo !== enumsUtente.profiliUtente.referenteAulss) gruppo.aulss = "";
                return gruppo;
            });
            listaGruppiRigheTemp = listaGruppiRigheTemp.map(riga => {
                if (riga.codicegruppo !== enumsUtente.profiliUtente.referenteAulss) riga.aulss = "";
                if (Object.prototype.hasOwnProperty.call(riga, "isDisabled")) delete riga.isDisabled;
                return riga;
            });

            if (!Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato) && listaGruppiRigheTemp.length > 0) {
                let listaRigheInComune = listaGruppiRigheTemp.filter(riga => listaGruppiCompletaTemp.find(gruppo => _.isEqual(gruppo, riga)));
                if (listaRigheInComune.length === 0 || listaRigheInComune.length < listaGruppiRigheTemp.length)
                    listaGruppiRigheTemp = listaGruppiRigheTemp.filter(riga => !listaGruppiCompletaTemp.find(gruppo => _.isEqual(gruppo, riga)));
            }

            if (this.areSameInfoStoredInDb(isUtentePresenteSuDb, listaGruppiCompletaTemp, listaGruppiRigheTemp)) {
                Utils.toasterFunction(messaggi.alertInserimentoModificaUtenteConStesseInformazioni);
                return false;
            } else if (listaGruppiRighePerAccordion.length > 0) {
                let listaGruppiCompletaClone = _.cloneDeep(this.state.oldListaGruppi);
                let listaGruppiRigheClone = _.cloneDeep(listaGruppiRighePerAccordion);
                let righeProfiloMedicoRichiedenteInvalide = 0;
                let righeProfiloMedicoFormalizzatoreInvalide = 0;
                let righeProfiloProfessionistaValutatoreInvalide = 0;
                let righeProfiloCoordinatoreInvalide = 0;
                let righeProfiloRefDirAssistentiSocialiInvalide = 0;
                let righeProfiloDirettoreUnitaOperativeInvalide = 0;
                let righeProfiloCentraleAdiInvalide = 0;

                if (Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato)) {
                    Array.prototype.push.apply(listaGruppiRigheClone, listaGruppiCompletaClone);
                    const profilesWithOnePossibleSetting = ["ADI-REF", "ADI-RIC", "ADI-FOR"];
                    let listaTemp = listaGruppiRigheClone.filter(riga => !profilesWithOnePossibleSetting.includes(riga.codicegruppo));
                    listaGruppiRigheClone
                        .filter(riga => profilesWithOnePossibleSetting.includes(riga.codicegruppo))
                        .forEach(riga => {
                            if (!listaTemp.some(e => e.codicegruppo === riga.codicegruppo)) listaTemp.push(riga);
                        });
                    listaGruppiRigheClone = listaTemp;
                }
                this.creaArrayListaGruppi(listaGruppiRigheClone);

                const distinctSelectedProfiles = _.uniq(listaGruppiRighePerAccordion.map(g => g.codicegruppo).filter(p => p !== enumsUtente.profiliUtente.referenteAulss));
                if (!this.areProfiliCompatibili(listaGruppiRigheClone, isUtentePresenteSuDb, distinctSelectedProfiles[distinctSelectedProfiles.length - 1])) return false;

                let righeProfiloReferenteAulss = listaGruppiRigheClone.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.referenteAulss);
                let aulssReferenteAulss = [];
                let distrettiReferenteAulss = [];
                if (righeProfiloReferenteAulss.length > 0) {
                    aulssReferenteAulss = this.getAulssByProfilo(righeProfiloReferenteAulss);
                    distrettiReferenteAulss = this.getDistrettiByProfilo(righeProfiloReferenteAulss);
                }

                let righeProfiloMedicoRichiedente = listaGruppiRigheClone.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.medicoRichiedente);
                let aulssMedicoRichiedente = [];
                let distrettiMedicoRichiedente = [];
                if (righeProfiloMedicoRichiedente.length > 0) {
                    if (Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato)
                        && isUtentePresenteSuDb && this.isProfileWithDifferentPreviousRole(enumsUtente.profiliUtente.medicoRichiedente)) {
                        Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoPiuRuoliPerStessoProfilo, false, [enumsUtente.profilo[enumsUtente.profiliUtente.medicoRichiedente]]));
                        return false;
                    } else {
                        const isRuoloMedicoOspedaliero = _.isEqual(this.currentRuoloMedicoRichiedente, ruoliSelect.ruoliMedicoRichiedente[2]);
                        righeProfiloMedicoRichiedenteInvalide = !Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato)
                            ? righeProfiloMedicoRichiedente.filter(riga => isRuoloMedicoOspedaliero ? riga.aulss === null : riga.aulss === null || riga.distretto === null).length
                            : this.checkAulssDifferencesInSameProfilo(righeProfiloMedicoRichiedente);
                        if (righeProfiloMedicoRichiedenteInvalide > 0 || righeProfiloMedicoRichiedenteInvalide.length > 0) {
                            Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoPiuProfiliPerAulssDistrettiDiversi, false));
                            return false;
                        }
                        aulssMedicoRichiedente = this.getAulssByProfilo(righeProfiloMedicoRichiedente);
                        distrettiMedicoRichiedente = this.getDistrettiByProfilo(righeProfiloMedicoRichiedente);
                    }
                }

                let righeProfiloMedicoFormalizzatore = listaGruppiRigheClone.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.medicoFormalizzatore);
                let aulssMedicoFormalizzatore = [];
                let distrettiMedicoFormalizzatore = [];
                if (righeProfiloMedicoFormalizzatore.length > 0) {
                    if (this.isInsertMode() && isUtentePresenteSuDb
                        && this.isProfileWithDifferentPreviousRole(enumsUtente.profiliUtente.medicoFormalizzatore)) {
                        Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoPiuRuoliPerStessoProfilo, false, [enumsUtente.profilo[enumsUtente.profiliUtente.medicoFormalizzatore]]));
                        return false;
                    } else if ((this.isInsertMode() || !_.isEqual(this.getRuoloObject(enumsUtente.profiliUtente.medicoFormalizzatore), this.oldRuoloMedicoFormalizzatore))
                        && this.isThereSomeUtenzaWithSameProfileAndRole(
                            enumsUtente.profiliUtente.medicoFormalizzatore,
                            this.currentRuoloMedicoFormalizzatore,
                            enumsUtente.profiliUtente.medicoFormalizzatore,
                            enumsUtente.ruoli.direttore,
                            righeProfiloMedicoFormalizzatore.map(e => e.aulss?.value),
                            righeProfiloMedicoFormalizzatore.map(e => e.distretto?.value), 2
                        )
                    ) {
                        const settingDuplicato = ["l'AULSS " + righeProfiloMedicoFormalizzatore[0].aulss.label, "e Distretto " + righeProfiloMedicoFormalizzatore[0].distretto.label].join(' ');
                        Utils.toasterFunction(this.formattaMessaggio(
                            messaggi.alertInserimentoPiuUtentiPerSetting,
                            false,
                            [enumsUtente.ruoliFE.P_DIRETTORE_DISTRETTO, settingDuplicato]
                        ));
                        return false;
                    } else {
                        if (this.currentRuoloMedicoFormalizzatore === ruoliSelect.ruoliMedicoFormalizzatore[1]) {
                            if (this.arePresentDuplicatedRows(righeProfiloMedicoFormalizzatore, [enumAttributo.distretto.value, enumAttributo.ambito.value])) {
                                Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoCampoDuplicato, false, [_.capitalize(enumAttributo.distretto.value)]));
                                return false;
                            } else if (this.isUltimaRigaInseritaInvalida(enumsUtente.profiliUtente.medicoFormalizzatore, this.currentRuoloMedicoFormalizzatore, false)) {
                                righeProfiloMedicoFormalizzatoreInvalide++;
                            }
                            aulssMedicoFormalizzatore = this.getAulssByProfilo(righeProfiloMedicoFormalizzatore);
                            distrettiMedicoFormalizzatore = this.getDistrettiByProfilo(righeProfiloMedicoFormalizzatore);
                        } else {
                            righeProfiloMedicoFormalizzatoreInvalide = !Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato)
                                ? righeProfiloMedicoFormalizzatore.filter(riga => riga.aulss === null || riga.distretto === null).length
                                : this.checkAulssDifferencesInSameProfilo(righeProfiloMedicoFormalizzatore);
                            if (righeProfiloMedicoFormalizzatoreInvalide > 0 || righeProfiloMedicoFormalizzatoreInvalide.length > 0) {
                                Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoPiuProfiliPerAulssDistrettiDiversi, false));
                                return false;
                            }
                            aulssMedicoFormalizzatore = this.getAulssByProfilo(righeProfiloMedicoFormalizzatore);
                            distrettiMedicoFormalizzatore = this.getDistrettiByProfilo(righeProfiloMedicoFormalizzatore);
                        }
                    }
                }

                let righeProfiloProfessionistaValutatore = listaGruppiRigheClone.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.professionistaValutatore);
                let aulssProfessionistaValutatore = [];
                let distrettiProfessionistaValutatore = [];
                if (righeProfiloProfessionistaValutatore.length > 0) {
                    if (Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato)
                        && isUtentePresenteSuDb && this.isProfileWithDifferentPreviousRole(enumsUtente.profiliUtente.professionistaValutatore)) {
                        Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoPiuRuoliPerStessoProfilo, false, [enumsUtente.profilo[enumsUtente.profiliUtente.professionistaValutatore]]));
                        return false;
                    } else {
                        aulssProfessionistaValutatore = this.getAulssByProfilo(righeProfiloProfessionistaValutatore);
                        distrettiProfessionistaValutatore = this.getDistrettiByProfilo(righeProfiloProfessionistaValutatore);
                        let currentRuolo = this.getRuoloObject(enumsUtente.profiliUtente.professionistaValutatore, Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato) && !Utils.isObjectNull(this.oldRuoloProfessionistaValutatore));
                        for (const riga of righeProfiloProfessionistaValutatore) {
                            switch (currentRuolo) {
                                case ruoliSelect.ruoliProfessionistaValutatore[0]:
                                    if (this.arePresentDuplicatedRows(righeProfiloProfessionistaValutatore, [enumAttributo.sede.value])) {
                                        Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoCampoDuplicato, false, [_.capitalize(enumAttributo.sede.value)]));
                                        righeProfiloProfessionistaValutatoreInvalide = 1;
                                    } else {
                                        if ((righeProfiloProfessionistaValutatore.length === 0
                                                && (riga.aulss === null || riga.distretto === null || riga.sede === null))
                                            || (riga.distretto === null || riga.sede === null)) {
                                            righeProfiloProfessionistaValutatoreInvalide = 1;
                                        }
                                    }
                                    break;
                                case ruoliSelect.ruoliProfessionistaValutatore[1]:
                                case ruoliSelect.ruoliProfessionistaValutatore[2]:
                                case ruoliSelect.ruoliProfessionistaValutatore[4]:
                                case ruoliSelect.ruoliProfessionistaValutatore[5]:
                                case ruoliSelect.ruoliProfessionistaValutatore[6]:
                                case ruoliSelect.ruoliProfessionistaValutatore[7]:
                                    var isRuoloConUO = ruoliAdiValConPossibileUnitaOperativa.includes(currentRuolo);
                                    var fieldsToMatch = isRuoloConUO ? [enumAttributo.unitaOperativa.value] : [enumAttributo.distretto.value];
                                    if (this.arePresentDuplicatedRows(righeProfiloProfessionistaValutatore, fieldsToMatch)) {
                                        Utils.toasterFunction(this.formattaMessaggio(
                                            messaggi.alertInserimentoCampoDuplicato,
                                            false,
                                            [isRuoloConUO ? 'Unità Operativa' : _.capitalize(enumAttributo.distretto.value)])
                                        );
                                        righeProfiloProfessionistaValutatoreInvalide = 1;
                                    } else {
                                        var secondFieldToMatch = isRuoloConUO ? riga.unitaOperativa : riga.distretto;
                                        if ((righeProfiloProfessionistaValutatore.length === 0
                                                && (riga.aulss === null || secondFieldToMatch === null))
                                            || (secondFieldToMatch === null)) {
                                            righeProfiloProfessionistaValutatoreInvalide = 1;
                                        }
                                    }
                                    break;
                                case ruoliSelect.ruoliProfessionistaValutatore[3]:
                                    if (this.arePresentDuplicatedRows(righeProfiloProfessionistaValutatore, [enumAttributo.distretto.value, enumAttributo.comune.value])) {
                                        Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoCampoDuplicato, false, [_.capitalize(enumAttributo.comune.value)]));
                                        righeProfiloProfessionistaValutatoreInvalide = 1;
                                    } else {
                                        if (riga.aulss === null || riga.distretto === null || riga.comune === null) {
                                            righeProfiloProfessionistaValutatoreInvalide = 1;
                                        }
                                    }
                                    break;
                                default:
                                    break;
                            }

                            if (righeProfiloProfessionistaValutatoreInvalide > 0) break;
                        }

                        if (Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato)
                            && righeProfiloProfessionistaValutatoreInvalide === 0
                            && currentRuolo !== ruoliSelect.ruoliProfessionistaValutatore[3]
                            && this.checkAulssDifferencesInSameProfilo(righeProfiloProfessionistaValutatore)) {
                            Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoPiuProfiliPerAulssDistrettiDiversi, false));
                            return false;
                        }

                        if (!Utils.isObjectNull(currentRuolo) && currentRuolo === ruoliSelect.ruoliProfessionistaValutatore[3] && this.state.referenteAulss) {
                            let isAtLeastOneAulssAndDistrettoPresent = righeProfiloReferenteAulss
                                .find(riga => righeProfiloProfessionistaValutatore
                                    .some(el => el.aulss?.label === riga.aulss?.label && el.aulss?.value === riga.aulss?.value && el.distretto?.label === riga.distretto?.label && el.distretto?.value === riga.distretto?.value));
                            if (Utils.isObjectNull(isAtLeastOneAulssAndDistrettoPresent)) {
                                const msg = _.cloneDeep(messaggi.alertCoerenzaAulssDistrettiProfiliXReferenteAulss);
                                msg.text = msg.text.replace("{0}", "Professionista valutatore");
                                Utils.toasterFunction(msg);
                                return false;
                            }
                        }
                    }
                }

                let righeProfiloCoordinatore = listaGruppiRigheClone.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.coordinatore);
                let aulssCoordinatore = [];
                let distrettiCoordinatore = [];
                if (righeProfiloCoordinatore.length > 0) {
                    if (this.arePresentDuplicatedRows(righeProfiloCoordinatore, [enumAttributo.sede.value])) {
                        Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoCampoDuplicato, false, [_.capitalize(enumAttributo.sede.value)]));
                        return false;
                    } else {
                        if (righeProfiloCoordinatore.length > 0 && righeProfiloCoordinatore.some(riga => riga.sede === null)) {
                            righeProfiloCoordinatoreInvalide = 1;
                        }
                    }
                    aulssCoordinatore = this.getAulssByProfilo(righeProfiloCoordinatore);
                    distrettiCoordinatore = this.getDistrettiByProfilo(righeProfiloCoordinatore);
                }

                let righeProfiloRefDirAssistentiSociali = listaGruppiRigheClone.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali);
                let aulssRefDirAssistentiSociali = [];
                let distrettiRefDireAssistentiSociali = [];
                if (righeProfiloRefDirAssistentiSociali.length > 0) {
                    if (this.arePresentDuplicatedRows(righeProfiloRefDirAssistentiSociali, [enumAttributo.distretto.value, enumAttributo.comune.value])) {
                        Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoCampoDuplicato, false, [_.capitalize(enumAttributo.comune.value)]));
                        return false;
                    } else {
                        if (righeProfiloRefDirAssistentiSociali.length > 0
                            && righeProfiloRefDirAssistentiSociali.some(riga => riga.aulss === null || riga.distretto === null || riga.comune === null)) {
                            righeProfiloRefDirAssistentiSocialiInvalide = 1;
                        }
                    }
                    aulssRefDirAssistentiSociali = this.getAulssByProfilo(righeProfiloRefDirAssistentiSociali);
                    distrettiRefDireAssistentiSociali = this.getDistrettiByProfilo(righeProfiloRefDirAssistentiSociali);
                }

                let righeProfiloDirettoreUnitaOperativa = listaGruppiRigheClone.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.direttoreUnitaOperativa);
                let aulssDirettoreUnitaOperativa = [];
                let distrettiDirettoreUnitaOperativa = [];
                if (righeProfiloDirettoreUnitaOperativa.length > 0) {
                    if (Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato)
                        && isUtentePresenteSuDb && this.isProfileWithDifferentPreviousRole(enumsUtente.profiliUtente.direttoreUnitaOperativa)) {
                        Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoPiuRuoliPerStessoProfilo, false, [enumsUtente.profilo[enumsUtente.profiliUtente.direttoreUnitaOperativa]]));
                        return false;
                    } else if (this.isInsertMode()
                        && this.isUniqueProfileForSameUtenteProfilato(
                            enumsUtente.profiliUtente.direttoreUnitaOperativa,
                            righeProfiloDirettoreUnitaOperativa.map(e => e.aulss?.value),
                            righeProfiloDirettoreUnitaOperativa.map(e => e.unitaOperativa?.value))
                    ) {
                        const settingDuplicato = ["l'AULSS " + righeProfiloDirettoreUnitaOperativa[0].aulss.label,
                            "e Unità Operativa " + righeProfiloDirettoreUnitaOperativa[0].unitaOperativa.label].join(' ');
                        Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoPiuUtentiPerSetting, false,
                            [`${enumsUtente.profilo["ADI-DIR-UO"]} - ${enumsUtente.ruoliFE[this.currentRuoloDirettoreUnitaOperativa.value]}`, settingDuplicato]));
                        return false;
                    } else {
                        if (this.arePresentDuplicatedRows(righeProfiloDirettoreUnitaOperativa, [enumAttributo.distretto.value])) {
                            Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoCampoDuplicato, false, [_.capitalize(enumAttributo.distretto.value)]));
                            return false;
                        } else {
                            if (righeProfiloDirettoreUnitaOperativa.length >= 1
                                && righeProfiloDirettoreUnitaOperativa.some(riga => riga.distretto === null)) {
                                righeProfiloDirettoreUnitaOperativeInvalide = 1;
                            }
                        }
                        aulssDirettoreUnitaOperativa = this.getAulssByProfilo(righeProfiloDirettoreUnitaOperativa);
                        distrettiDirettoreUnitaOperativa = this.getDistrettiByProfilo(righeProfiloDirettoreUnitaOperativa);
                    }
                }

                let righeProfiloCentraleAdi = listaGruppiRigheClone.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.centraleAdi);
                let aulssCentraleAdi = [];
                let distrettiCentraleAdi = [];
                if (righeProfiloCentraleAdi.length > 0) {
                    const distrettoField = enumAttributo.distretto.value;
                    const sedeField = enumAttributo.sede.value;
                    const ambitoField = enumAttributo.ambito.value;

                    if (righeProfiloCentraleAdi.some(el => el[distrettoField] == null || el[sedeField] == null || el[ambitoField] == null)) {
                        righeProfiloCentraleAdiInvalide = 1;
                    } else {
                        let uniqueObjectsList = [];
                        const isDuplicatedRow = righeProfiloCentraleAdi.some(e => {
                            let item = _.cloneDeep(e);
                            if (Object.prototype.hasOwnProperty.call(item, "aulss")) delete item.aulss;
                            if (Object.prototype.hasOwnProperty.call(item, "isDisabled")) delete item.isDisabled;
                            const index = uniqueObjectsList.findIndex(x => _.isEqual(x, item));
                            if (index <= -1) {
                                uniqueObjectsList.push(item);
                                return false;
                            } else {
                                return true;
                            }
                        });
                        if (isDuplicatedRow) {
                            const listaDistretti = righeProfiloCentraleAdi.map(el => el[distrettoField]?.value).filter(e => !!e);
                            const listaSedi = righeProfiloCentraleAdi.map(el => el[sedeField]?.value).filter(e => !!e);
                            const listaAmbiti = righeProfiloCentraleAdi.map(el => el[ambitoField]?.value).filter(e => !!e);
                            const isListaDistrettiDuplicata = listaDistretti.some((item, idx) => listaDistretti.indexOf(item) !== idx);
                            const isListaSediDuplicata = listaSedi.some((item, idx) => listaSedi.indexOf(item) !== idx);
                            const isListaAmbitiDuplicata = listaAmbiti.some((item, idx) => listaAmbiti.indexOf(item) !== idx);

                            if (isListaDistrettiDuplicata) {
                                Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoCampoDuplicato, false, [_.capitalize(enumAttributo.distretto.value)]));
                                return false;
                            } else if (isListaSediDuplicata) {
                                Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoCampoDuplicato, false, [_.capitalize(enumAttributo.sede.value)]));
                                return false;
                            } else if (isListaAmbitiDuplicata) {
                                Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoCampoDuplicato, false, [_.capitalize(enumAttributo.ambito.value)]));
                                return false;
                            }
                        }
                    }
                    aulssCentraleAdi = this.getAulssByProfilo(righeProfiloCentraleAdi);
                    distrettiCentraleAdi = this.getDistrettiByProfilo(righeProfiloCentraleAdi);
                }

                if (!this.areProfiliConAulssAndDistrettiUguali(isUtentePresenteSuDb, aulssReferenteAulss, distrettiReferenteAulss,
                    aulssMedicoRichiedente, distrettiMedicoRichiedente, aulssMedicoFormalizzatore, distrettiMedicoFormalizzatore,
                    aulssProfessionistaValutatore, distrettiProfessionistaValutatore, aulssCoordinatore, distrettiCoordinatore,
                    aulssRefDirAssistentiSociali, distrettiRefDireAssistentiSociali,
                    aulssDirettoreUnitaOperativa, distrettiDirettoreUnitaOperativa, aulssCentraleAdi, distrettiCentraleAdi)) {
                    Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoPiuProfiliPerAulssDistrettiDiversi, false));
                    return false;
                } else {
                    return (!this.state.medicoRichiedente || righeProfiloMedicoRichiedenteInvalide === 0)
                        && (!this.state.medicoFormalizzatore || righeProfiloMedicoFormalizzatoreInvalide === 0)
                        && (!this.state.professionistaValutatore || righeProfiloProfessionistaValutatoreInvalide === 0)
                        && (!this.state.coordinatore || righeProfiloCoordinatoreInvalide === 0)
                        && (!this.state.referenteDirigenteAssistentiSociali || righeProfiloRefDirAssistentiSocialiInvalide === 0)
                        && (!this.state.direttoreUnitaOperativa || righeProfiloDirettoreUnitaOperativeInvalide === 0)
                        && (!this.state.centraleAdi || righeProfiloCentraleAdiInvalide === 0);
                }
            } else if (Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato)
                && isUtentePresenteSuDb && !AuthUtils.hasUtenteRuoloReferenteAulss()) {
                let oldListaGruppi = _.cloneDeep(this.state.oldListaGruppi);
                let profiliIncompatibili = oldListaGruppi.map(gruppo => enumsUtente.profilo[gruppo.codicegruppo]).join(',');
                Utils.toasterFunction(this.formattaMessaggio(messaggi.alertInserimentoPiuRuoliPerStessoProfilo, false, Array.from(new Set(profiliIncompatibili.split(',')))));
                return false;
            } else {
                return true;
            }
        }
    }

    areSameInfoStoredInDb = (isUtentePresenteSuDb, listaGruppiCompletaTemp, listaGruppiRigheTemp) => {
        const isEmailEqualsToPreviousInput = (this.state.utente
                && ((Array.isArray(this.state.utente) && this.state.utente.some(u => u?.mail === this.state.utenteapiapms.mail))
                    || this.state.utente?.mail === this.state.utenteapiapms.mail))
            || this.state?.oldEmail === this.state.utenteapiapms.mail;

        const wereAlreadyAdministrator = (listaGruppiRigheTemp.length === 0 && listaGruppiCompletaTemp.length === 1
            && listaGruppiCompletaTemp.find(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.admin));

        let old = _.cloneDeep(listaGruppiCompletaTemp);
        let current = _.cloneDeep(listaGruppiRigheTemp);
        if (this.isInsertMode() && old.length !== current.length) {
            const currentGruppi = listaGruppiRigheTemp.map(g => g.codicegruppo);
            old = old.filter(g => currentGruppi.includes(g.codicegruppo)) ?? [];
            current = current.filter(g => currentGruppi.includes(g.codicegruppo)) ?? [];
        }

        const getProfiloByCodiceGruppo = (codiceGruppo) => Object.keys(enumsUtente.profiliUtente).find(key => enumsUtente.profiliUtente[key] === codiceGruppo);
        const getListOfDifferentRoles = (gruppo) => current.find(gruppo2 => _.isEqual(gruppo, gruppo2)
            && this.isProfileWithDifferentPreviousRole(enumsUtente.profiliUtente[getProfiloByCodiceGruppo(gruppo2.codicegruppo)], true));
        const isEqualList = (old.every(gruppo => Utils.isObjectNull(getListOfDifferentRoles(gruppo))) && Utils.compareArraysOrObjects(old, current));

        return isUtentePresenteSuDb && isEmailEqualsToPreviousInput && (wereAlreadyAdministrator || isEqualList);
    }

    areProfiliCompatibili = (listaGruppiRighe, isUtentePresenteSuDb, ultimoProfiloSelezionato) => {
        // lista di tutti i profili per cui l'utente è profilato (contiene sia i profili precedentemente salvati che quelli appena inseriti nel form)
        let profiliSelezionati = listaGruppiRighe.map(gruppo => gruppo.codicegruppo).filter((value, index, self) => self.indexOf(value) === index);

        // Se ho selezionato un solo profilo oppure ne ho selezionati due tra cui il referente aulss allora ritorno true
        if (profiliSelezionati.length === 1
            || (profiliSelezionati.length === 2 && profiliSelezionati.includes(enumsUtente.profiliUtente.referenteAulss))) {
            return true;
        } else {  // caso in cui ho selezionato più di due profili
            const isMedicoRichiedente = profiliSelezionati.includes(enumsUtente.profiliUtente.medicoRichiedente);
            const isReferenteDirigenteAssistentiSociali = profiliSelezionati.includes(enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali);
            const isReferenteAulss = profiliSelezionati.includes(enumsUtente.profiliUtente.referenteAulss);
            const isDirettoreUnitaOperativa = profiliSelezionati.includes(enumsUtente.profiliUtente.direttoreUnitaOperativa);
            const isProfessionistaValutatore = profiliSelezionati.includes(enumsUtente.profiliUtente.professionistaValutatore);

            const currentRuoloAdiRic = this.getRuoloObject(
                enumsUtente.profiliUtente.medicoRichiedente,
                Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato) && !Utils.isObjectNull(this.oldRuoloMedicoRichiedente));

            const currentRuoloAdiVal = this.getRuoloObject(
                enumsUtente.profiliUtente.professionistaValutatore,
                Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato) && !Utils.isObjectNull(this.oldRuoloProfessionistaValutatore));

            const currentRuoloAdiDirUO = this.getRuoloObject(
                enumsUtente.profiliUtente.direttoreUnitaOperativa,
                Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato) && !Utils.isObjectNull(this.oldRuoloDirettoreUnitaOperativa));

            const isMedicoOspedaliero = isMedicoRichiedente && _.isEqual(currentRuoloAdiRic, ruoliSelect.ruoliMedicoRichiedente[2]);

            if (this.state.professionistaValutatore || this.state.direttoreUnitaOperativa) {
                const adiValWithUOSettings = listaGruppiRighe.filter(r => r.codicegruppo === enumsUtente.profiliUtente.professionistaValutatore);
                const adiDirUOSettings = listaGruppiRighe.filter(r => r.codicegruppo === enumsUtente.profiliUtente.direttoreUnitaOperativa);
                if (adiValWithUOSettings.length && adiDirUOSettings.length) {
                    const disciplinaAdiVal = this.getDisciplinaObject(
                        enumsUtente.profiliUtente.professionistaValutatore,
                        Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato) && !Utils.isObjectNull(this.oldDisciplinaProfessionistaValutatore)
                    );
                    const disciplinaAdiDirUO = this.getDisciplinaObject(
                        enumsUtente.profiliUtente.direttoreUnitaOperativa,
                        Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato) && !Utils.isObjectNull(this.oldDisciplinaDirettoreUnitaOperativa)
                    );
                    const isRuoloEquals = _.isEqual(currentRuoloAdiVal, currentRuoloAdiDirUO);
                    const isDisciplinaEquals = _.isEqual(disciplinaAdiVal, disciplinaAdiDirUO);
                    const isAulssEquals = _.isEqual(adiValWithUOSettings[0].aulss, adiDirUOSettings[0].aulss);
                    const isUODifferent = adiValWithUOSettings.map(e => e.unitaOperativa?.value).filter(e => e != null).every(uo => !_.isEqual(uo, adiDirUOSettings[0].unitaOperativa.value));
                    if (ruoliAdiValConPossibileUnitaOperativa.includes(currentRuoloAdiVal) && isRuoloEquals
                        && isDisciplinaEquals && isAulssEquals && isUODifferent) {
                        if (profiliSelezionati.length === 2) return true;
                    } else {
                        Utils.toasterFunction(this.formattaMessaggio(
                            messaggi.alertCompatibilitaProfiloAdiDirUOAdiVal,
                            false,
                            [this.isInsertMode() && isUtentePresenteSuDb ? 'l\'utente è già presente nel sistema;' : ''])
                        );
                        return false;
                    }
                }
            }

            if (isMedicoOspedaliero) {
                const validRoles = [enumsUtente.ruoli.specialista, enumsUtente.ruoli.fisiatra];
                const isValidProfile = (isActiveProfile, currentRole) => !isActiveProfile || validRoles.includes(currentRole);
                let profiliValidi = [
                    enumsUtente.profiliUtente.referenteAulss,
                    enumsUtente.profiliUtente.medicoRichiedente,
                    enumsUtente.profiliUtente.professionistaValutatore,
                    enumsUtente.profiliUtente.direttoreUnitaOperativa
                ];

                if (profiliSelezionati.length > 4
                    || !profiliSelezionati.every(p => profiliValidi.includes(p))
                    || !isValidProfile(isProfessionistaValutatore, currentRuoloAdiVal?.value)
                    || !isValidProfile(isDirettoreUnitaOperativa, currentRuoloAdiDirUO?.value)) {
                    profiliValidi = [
                        "\"" + enumsUtente.profilo["ADI-REF"] + "\"",
                        "\"" + enumsUtente.profilo["ADI-VAL"] + " (ruoli " + enumsUtente.ruoliFE.P_MEDICO_SPECIALISTA + ", " + enumsUtente.ruoliFE.P_FISIATRA + ")\"",
                        "\"" + enumsUtente.profilo["ADI-DIR-UO"] + " (ruoli " + enumsUtente.ruoliFE.P_MEDICO_SPECIALISTA + ", " + enumsUtente.ruoliFE.P_FISIATRA + ")\""
                    ].join(', ');
                    Utils.toasterFunction(this.formattaMessaggio(
                        messaggi.alertCompatibilitaRuolo,
                        false,
                        [this.isInsertMode() && isUtentePresenteSuDb ? 'l\'utente è già presente nel sistema;' : '', enumsUtente.ruoliFE.P_MEDICO_OSPEDALIERO, profiliValidi])
                    );
                    return false;
                }
                return true;
            }

            if (this.state.referenteAulss && isReferenteAulss
                && this.state.referenteDirigenteAssistentiSociali && isReferenteDirigenteAssistentiSociali) {
                let righeReferenteAulss = listaGruppiRighe.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.referenteAulss);
                let righeReferenteDirigenteAssistentiSociali = listaGruppiRighe.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali);
                let isAtLeastOneAulssAndDistrettoPresent = righeReferenteAulss
                    .find(riga => righeReferenteDirigenteAssistentiSociali
                        .some(el => el.aulss?.label === riga.aulss?.label && el.aulss?.value === riga.aulss?.value && el.distretto?.label === riga.distretto?.label && el.distretto?.value === riga.distretto?.value));
                if (Utils.isObjectNull(isAtLeastOneAulssAndDistrettoPresent)) {
                    const msg = messaggi.alertCoerenzaAulssDistrettiProfiliXReferenteAulss;
                    const invalidProfiles = profiliSelezionati
                        .filter(p => p !== enumsUtente.profiliUtente.referenteAulss)
                        .map(p => enumsUtente.profilo[p])
                        .join(', ');
                    msg.text = msg.text.replace("{0}", invalidProfiles);
                    Utils.toasterFunction(msg);
                    return false;
                }
            }

            const profiloIncompatibile = enumsUtente.profilo[ultimoProfiloSelezionato];
            const profiliIgnoreProfiloIncompatibile = profiliSelezionati
                .filter(p => p !== enumsUtente.profiliUtente.referenteAulss)
                .filter(p => p !== ultimoProfiloSelezionato)
                .map(p => "\"" + enumsUtente.profilo[p] + "\"")
                .join(', ');
            Utils.toasterFunction(this.formattaMessaggio(
                messaggi.alertInserimentoProfiliRuoliIncompatibili,
                false,
                [this.isInsertMode() && isUtentePresenteSuDb ? 'l\'utente è già presente nel sistema;' : '', profiloIncompatibile, profiliIgnoreProfiloIncompatibile]
            ));

            return false;
        }
    }

    isThereSomeUtenzaWithSameProfileAndRole = (selectedProfile, selectedRole, profileToCompare,
                                               roleToCompare, list1, list2, positionSetting) => {
        let fullAulssSettingListClone = _.cloneDeep(this.state.fullAulssSettingList);

        if (!fullAulssSettingListClone.length
            || !_.isEqual(selectedProfile, profileToCompare)
            || !_.isEqual(selectedRole?.value, roleToCompare))
            return false;

        let mappaAulssSettings = this.creaMappaProfiloListaSettings(fullAulssSettingListClone.filter(e => list1.includes(e.struttura)));
        return Object.keys(mappaAulssSettings).filter(key => {
            return mappaAulssSettings[key].filter(e => {
                let split = e.split('@');
                return split[0] === profileToCompare && split[1] === roleToCompare
                    && list1.includes(key) && list2.includes(split[positionSetting]);
            }).length > 0;
        }).length > 0;
    }

    isUniqueProfileForSameUtenteProfilato = (selectedProfile, list1, list2) => {
        let settingByAulss = _.cloneDeep(this.state.fullAulssSettingList).filter(e => list1.includes(e.struttura));
        return settingByAulss?.filter(s => s.codiceFiscale !== this.state.utenteapiapms.codiceFiscale)
            ?.flatMap(s => s.gruppi)
            ?.filter(g => g.codice_gruppo === selectedProfile)
            ?.flatMap(g => g.attributi)
            ?.flatMap(a => a.valore.slice(1, -1).split(","))
            ?.some(v => v.includes(selectedProfile) && list2.some(e => v.includes(e))) ?? false;
    }

    checkAulssDifferencesInSameProfilo = (righeByProfilo) => {
        let righe = _.cloneDeep(righeByProfilo);
        let strutture = righe.map(riga => riga.aulss?.value).filter(aulss => !Utils.isStringEmptyOrNullOrUndefined(aulss));
        strutture = Array.from(new Set(strutture));
        return strutture.some(aulss => aulss !== strutture[0]) ? 1 : 0;
    }

    isProfileWithDifferentPreviousRole = (profilo, fullValidation = false) => {
        let currentRuolo = this.getRuoloObject(profilo);
        let currentDisciplina = this.getDisciplinaObject(profilo);
        switch (profilo) {
            case enumsUtente.profiliUtente.medicoRichiedente:
                return this.checkFieldValue(currentRuolo, this.oldRuoloMedicoRichiedente);
            case enumsUtente.profiliUtente.medicoFormalizzatore:
                return this.checkFieldValue(currentRuolo, this.oldRuoloMedicoFormalizzatore);
            case enumsUtente.profiliUtente.professionistaValutatore:
                var isValidAdiValRole = this.checkFieldValue(currentRuolo, this.oldRuoloProfessionistaValutatore);
                return !fullValidation ? isValidAdiValRole : isValidAdiValRole || this.checkFieldValue(currentDisciplina, this.oldDisciplinaProfessionistaValutatore);
            case enumsUtente.profiliUtente.coordinatore:
                return this.checkFieldValue(currentRuolo, this.oldRuoloCoordinatore);
            case enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali:
                return this.checkFieldValue(currentRuolo, this.oldRuoloReferenteDirigenteAssistentiSociali);
            case enumsUtente.profiliUtente.direttoreUnitaOperativa:
                var isValidAdiDirUORole = this.checkFieldValue(currentRuolo, this.oldRuoloDirettoreUnitaOperativa);
                return !fullValidation ? isValidAdiDirUORole : isValidAdiDirUORole || this.checkFieldValue(currentDisciplina, this.oldDisciplinaDirettoreUnitaOperativa);
            case enumsUtente.profiliUtente.centraleAdi:
                return this.checkFieldValue(currentRuolo, this.oldRuoloCentraleAdi);
            default:
                break;
        }
    }

    checkFieldValue = (currentValue, oldValue) => {
        return !Utils.isObjectNull(currentValue) && !Utils.isObjectNull(oldValue) && !_.isEqual(currentValue, oldValue);
    }

    arePresentDuplicatedRows = (list, fields) => {
        const getInvalidValues = (el) => Object.keys(el).filter(key => fields.includes(key) && (el[key] === undefined || el[key] === null));

        if (list.some(el => getInvalidValues(el).length > 0)) return false;

        let values = [];
        list.forEach(el => {
            let object = _.pick(el, fields);
            values.push(Object.values(object).map(e => e.label).join('|'));
        });
        return values.some((item, idx) => values.indexOf(item) !== idx);
    }

    areElementsArrayInCommon = (array1, array2) => {
        return Utils.arrayEquals(array1, array2)
            || !array1.length && (!this.isInsertMode() || _.uniq(array2).length === 1)
            || Utils.compareArraysOrObjects(array1, array2);
    }

    areProfiliConAulssAndDistrettiUguali = (isUtentePresenteSuDb, aulssReferenteAulss, distrettiReferenteAulss,
                                            aulssMedicoRichiedente, distrettiMedicoRichiedente,
                                            aulssMedicoFormalizzatore, distrettiMedicoFormalizzatore,
                                            aulssProfessionistaValutatore, distrettiProfessionistaValutatore,
                                            aulssCoordinatore, distrettiCoordinatore,
                                            aulssRefDirAssistentiSociali, distrettiRefDireAssistentiSociali,
                                            aulssDirettoreUnitaOperativa, distrettiDirettoreUnitaOperativa,
                                            aulssCentraleAdi, distrettiCentraleAdi) => {
        let aulssValidator = {};
        let distrettiValidator = {};

        if (this.state.medicoRichiedente || (aulssReferenteAulss.length > 0 && aulssMedicoRichiedente.length > 0)) {
            if (_.isEqual(this.currentRuoloMedicoRichiedente ?? this.oldRuoloMedicoRichiedente, ruoliSelect.ruoliMedicoRichiedente[2]) && aulssMedicoRichiedente.length > 0) {
                const validate = (list1, list2) => list2.length ? !_.difference(list1, list2).length : true;
                aulssValidator.medicoOspedaliero = validate(aulssMedicoRichiedente, aulssReferenteAulss)
                    && validate(aulssMedicoRichiedente, aulssProfessionistaValutatore)
                    && validate(aulssMedicoRichiedente, aulssDirettoreUnitaOperativa);
                distrettiValidator.medicoOspedaliero = validate(distrettiMedicoRichiedente, distrettiReferenteAulss)
                    && validate(distrettiMedicoRichiedente, distrettiProfessionistaValutatore)
                    && validate(distrettiMedicoRichiedente, distrettiDirettoreUnitaOperativa);
            } else {
                aulssValidator.referenteAulssAndMedicoRichiedente = this.areElementsArrayInCommon(aulssReferenteAulss, aulssMedicoRichiedente);
                distrettiValidator.referenteAulssAndMedicoRichiedente = this.areElementsArrayInCommon(distrettiReferenteAulss, distrettiMedicoRichiedente);
            }
        }

        if (this.state.medicoFormalizzatore || (aulssReferenteAulss.length > 0 && aulssMedicoFormalizzatore.length > 0)) {
            aulssValidator.referenteAulssAndMedicoFormalizzatore = this.areElementsArrayInCommon(aulssReferenteAulss, aulssMedicoFormalizzatore);
            distrettiValidator.referenteAulssAndMedicoFormalizzatore = this.areElementsArrayInCommon(distrettiReferenteAulss, distrettiMedicoFormalizzatore);
        }

        const ruoloProfessionistaValutatore = this.currentRuoloProfessionistaValutatore ?? this.oldRuoloProfessionistaValutatore;
        if (!_.isEqual(ruoloProfessionistaValutatore, ruoliSelect.ruoliProfessionistaValutatore[3])) {
            if (this.state.professionistaValutatore || (aulssReferenteAulss.length > 0 && aulssProfessionistaValutatore.length > 0)) {
                aulssValidator.referenteAulssAndProfessionistaValutatore = this.areElementsArrayInCommon(aulssReferenteAulss, aulssProfessionistaValutatore);
                aulssValidator.medicoRichiedenteAndProfessionistaValutatore = this.areElementsArrayInCommon(aulssMedicoRichiedente, aulssProfessionistaValutatore);

                if (!ruoliAdiValConPossibileUnitaOperativa.includes(ruoloProfessionistaValutatore)) {
                    distrettiValidator.referenteAulssAndProfessionistaValutatore = this.areElementsArrayInCommon(distrettiReferenteAulss, distrettiProfessionistaValutatore);
                    distrettiValidator.medicoRichiedenteAndProfessionistaValutatore = this.areElementsArrayInCommon(distrettiMedicoRichiedente, distrettiProfessionistaValutatore);
                }
            }
        }

        if (this.state.coordinatore || (aulssReferenteAulss.length > 0 && aulssCoordinatore.length > 0)) {
            aulssValidator.referenteAulssAndCoordinatore = this.areElementsArrayInCommon(aulssReferenteAulss, aulssCoordinatore);
            distrettiValidator.referenteAulssAndCoordinatore = this.areElementsArrayInCommon(distrettiReferenteAulss, distrettiCoordinatore);
        }
        if (this.state.referenteDirigenteAssistentiSociali || (aulssReferenteAulss.length > 0 && aulssRefDirAssistentiSociali.length > 0)) {
            if (aulssRefDirAssistentiSociali.length < 2) {
                aulssValidator.referenteDirigenteAssistentiSociali = this.areElementsArrayInCommon(aulssReferenteAulss, aulssRefDirAssistentiSociali);
            }
            distrettiValidator.referenteDirigenteAssistentiSociali = this.areElementsArrayInCommon(distrettiReferenteAulss, distrettiRefDireAssistentiSociali);
        }
        if (this.state.direttoreUnitaOperativa || (aulssReferenteAulss.length > 0 && aulssDirettoreUnitaOperativa.length > 0)) {
            aulssValidator.referenteAulssAndDirettoreUnitaOperativa = this.areElementsArrayInCommon(aulssReferenteAulss, aulssDirettoreUnitaOperativa);
            distrettiValidator.referenteAulssAndDirettoreUnitaOperativa = this.areElementsArrayInCommon(distrettiReferenteAulss, distrettiDirettoreUnitaOperativa);
        }

        if (this.state.centraleAdi || (aulssReferenteAulss.length > 0 && aulssCentraleAdi.length > 0)) {
            aulssValidator.centraleAdi = this.areElementsArrayInCommon(aulssReferenteAulss, aulssCentraleAdi);
            distrettiValidator.centraleAdi = distrettiReferenteAulss.length === 0 || _.uniq(distrettiCentraleAdi).some(d => distrettiReferenteAulss.includes(d));
        }

        if (isUtentePresenteSuDb && this.isInsertMode() && this.state.oldListaGruppi.length === 1
            && this.state.oldListaGruppi[0].codicegruppo === enumsUtente.profiliUtente.referenteAulss) {
            const oldAulssAdiRef = this.state.oldListaGruppi
                .map(g => g.aulss?.value)
                .filter(a => !Utils.isStringEmptyOrNullOrUndefined(a))
                .filter((value, index, self) => self.indexOf(value) === index);

            const oldDistrettoAdiRef = this.state.oldListaGruppi
                .map(g => g.distretto?.value)
                .filter(a => !Utils.isStringEmptyOrNullOrUndefined(a))
                .filter((value, index, self) => self.indexOf(value) === index);

            aulssValidator.referenteAulss = _.isEqual(aulssReferenteAulss, oldAulssAdiRef);
            distrettiValidator.referenteAulss = _.isEqual(distrettiReferenteAulss, oldDistrettoAdiRef);
        }

        return Object.keys(aulssValidator).every(key => aulssValidator[key])
            && Object.keys(distrettiValidator).every(key => distrettiValidator[key]);
    }

    getAulssByProfilo = (righeAccordion) => {
        return Array.from(new Set(righeAccordion.map(riga => riga.aulss?.value).filter(aulss => !Utils.isStringEmptyOrNullOrUndefined(aulss))));
    }

    getDistrettiByProfilo = (righeAccordion) => {
        return Array.from(new Set(righeAccordion.map(riga => riga.distretto?.value).filter(distretto => !Utils.isStringEmptyOrNullOrUndefined(distretto))));
    }

    creaArrayListaGruppi = (list = []) => {
        let listaGruppi = [];
        let listaGruppiRighePerAccordion = list.length > 0 ? _.cloneDeep(list) : _.cloneDeep(this.state.listaGruppiPerRigheAccordion);
        if (listaGruppiRighePerAccordion.length > 0) {
            let righeProfiloReferenteAulss = listaGruppiRighePerAccordion.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.referenteAulss);
            if (righeProfiloReferenteAulss.length > 0) {
                righeProfiloReferenteAulss = righeProfiloReferenteAulss.map(riga => {
                    let gruppo = {};
                    gruppo.codicegruppo = riga.codicegruppo;
                    let listaAttributi = [];
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.ruolo.value, Utils.isObjectNull(this.currentRuoloReferenteAulss) ? enumsUtente.profiliUtente.referenteAulss : this.currentRuoloReferenteAulss?.label));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.aulss.value, riga.aulss?.value));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.distretto.value, riga.distretto?.value));
                    gruppo.listaAttributi = listaAttributi;

                    return gruppo;
                });
                listaGruppi = listaGruppi.concat(righeProfiloReferenteAulss);
            }

            let righeProfiloMedicoRichiedente = listaGruppiRighePerAccordion.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.medicoRichiedente);
            if (righeProfiloMedicoRichiedente.length > 0) {
                righeProfiloMedicoRichiedente = righeProfiloMedicoRichiedente.map(riga => {
                    let gruppo = {};
                    gruppo.codicegruppo = riga.codicegruppo;
                    let listaAttributi = [];
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.ruolo.value, Utils.isObjectNull(this.currentRuoloMedicoRichiedente) ? this.oldRuoloMedicoRichiedente.label : this.currentRuoloMedicoRichiedente?.label));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.aulss.value, riga.aulss?.value));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.distretto.value, riga.distretto?.value));
                    gruppo.listaAttributi = listaAttributi;

                    return gruppo;
                });
                listaGruppi = listaGruppi.concat(righeProfiloMedicoRichiedente);
            }

            let righeProfiloMedicoFormalizzatore = listaGruppiRighePerAccordion.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.medicoFormalizzatore);
            if (righeProfiloMedicoFormalizzatore.length > 0) {
                righeProfiloMedicoFormalizzatore = righeProfiloMedicoFormalizzatore.map(riga => {
                    let gruppo = {};
                    gruppo.codicegruppo = riga.codicegruppo;
                    let listaAttributi = [];
                    let ruoloMedicoFormalizzatore = Utils.isObjectNull(this.currentRuoloMedicoFormalizzatore)
                        ? this.oldRuoloMedicoFormalizzatore
                        : this.currentRuoloMedicoFormalizzatore;

                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.ruolo.value, ruoloMedicoFormalizzatore?.label));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.aulss.value, riga?.aulss?.value ?? righeProfiloMedicoFormalizzatore[0]?.aulss?.value));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.distretto.value, riga.distretto?.value));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.ambito.value, riga.ambito?.value));

                    gruppo.listaAttributi = listaAttributi;

                    return gruppo;
                });
                listaGruppi = listaGruppi.concat(righeProfiloMedicoFormalizzatore);
            }

            let righeProfiloProfessionistaValutatore = listaGruppiRighePerAccordion.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.professionistaValutatore);
            if (righeProfiloProfessionistaValutatore.length > 0) {
                righeProfiloProfessionistaValutatore = righeProfiloProfessionistaValutatore.map(riga => {
                    let gruppo = {};
                    gruppo.codicegruppo = riga.codicegruppo;
                    const ruoloProfessionistaValutatore = Utils.isObjectNull(this.currentRuoloProfessionistaValutatore)
                        ? this.oldRuoloProfessionistaValutatore : this.currentRuoloProfessionistaValutatore;
                    let listaAttributi = [];
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.ruolo.value, ruoloProfessionistaValutatore?.label));

                    switch (ruoloProfessionistaValutatore) {
                        case ruoliSelect.ruoliProfessionistaValutatore[0]:
                            listaAttributi.push(this.creaOggettoAttributo(enumAttributo.aulss.value, riga?.aulss?.value ?? righeProfiloProfessionistaValutatore[0]?.aulss?.value));
                            listaAttributi.push(this.creaOggettoAttributo(enumAttributo.distretto.value, riga.distretto?.value));
                            listaAttributi.push(this.creaOggettoAttributo(enumAttributo.sede.value, riga.sede?.value));
                            gruppo.listaAttributi = listaAttributi;
                            break;
                        case ruoliSelect.ruoliProfessionistaValutatore[1]:
                        case ruoliSelect.ruoliProfessionistaValutatore[2]:
                        case ruoliSelect.ruoliProfessionistaValutatore[4]:
                        case ruoliSelect.ruoliProfessionistaValutatore[5]:
                        case ruoliSelect.ruoliProfessionistaValutatore[6]:
                        case ruoliSelect.ruoliProfessionistaValutatore[7]:
                            listaAttributi.push(this.creaOggettoAttributo(enumAttributo.aulss.value, riga?.aulss?.value ?? righeProfiloProfessionistaValutatore[0]?.aulss?.value));
                            if (!ruoliAdiValConPossibileUnitaOperativa.includes(ruoloProfessionistaValutatore)) {
                                listaAttributi.push(this.creaOggettoAttributo(enumAttributo.distretto.value, riga.distretto?.value));
                            }
                            var disciplina = this.getDisciplinaObject(enumsUtente.profiliUtente.professionistaValutatore, Utils.isObjectNull(this.currentDisciplinaProfessionistaValutatore))?.value ?? null;
                            listaAttributi.push(this.creaOggettoAttributo(enumAttributo.disciplina.value, disciplina));
                            if (ruoliAdiValConPossibileUnitaOperativa.includes(ruoloProfessionistaValutatore)) {
                                listaAttributi.push(this.creaOggettoAttributo(enumAttributo.unitaOperativa.value, riga.unitaOperativa?.value));
                            }
                            gruppo.listaAttributi = listaAttributi;
                            break;
                        case ruoliSelect.ruoliProfessionistaValutatore[3]:
                            listaAttributi.push(this.creaOggettoAttributo(enumAttributo.aulss.value, riga.aulss?.value));
                            listaAttributi.push(this.creaOggettoAttributo(enumAttributo.distretto.value, riga.distretto?.value));
                            listaAttributi.push(this.creaOggettoAttributo(enumAttributo.comune.value, riga.comune?.value));
                            gruppo.listaAttributi = listaAttributi;
                            break;
                        default:
                            break;
                    }

                    return gruppo;
                });
                listaGruppi = listaGruppi.concat(righeProfiloProfessionistaValutatore);
            }

            let righeProfiloCoordinatore = listaGruppiRighePerAccordion.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.coordinatore);
            if (righeProfiloCoordinatore.length > 0) {
                righeProfiloCoordinatore = righeProfiloCoordinatore.map(riga => {
                    let gruppo = {};
                    gruppo.codicegruppo = riga.codicegruppo;
                    let listaAttributi = [];
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.ruolo.value, Utils.isObjectNull(this.currentRuoloCoordinatore) ? this.oldRuoloCoordinatore.label : this.currentRuoloCoordinatore?.label));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.aulss.value, riga?.aulss?.value ?? righeProfiloCoordinatore[0]?.aulss?.value));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.distretto.value, riga?.distretto?.value ?? righeProfiloCoordinatore[0]?.distretto?.value));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.sede.value, riga.sede?.value));

                    gruppo.listaAttributi = listaAttributi;

                    return gruppo;
                });
                listaGruppi = listaGruppi.concat(righeProfiloCoordinatore);
            }

            let righeProfiloRefDirAssistentiSociali = listaGruppiRighePerAccordion.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali);
            if (righeProfiloRefDirAssistentiSociali.length > 0) {
                righeProfiloRefDirAssistentiSociali = righeProfiloRefDirAssistentiSociali.map(riga => {
                    let gruppo = {};
                    gruppo.codicegruppo = riga.codicegruppo;
                    let listaAttributi = [];
                    let currentRuolo = Utils.isObjectNull(this.currentRuoloReferenteDirigenteAssistentiSociali)
                        ? this.oldRuoloReferenteDirigenteAssistentiSociali
                        : this.currentRuoloReferenteDirigenteAssistentiSociali;
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.ruolo.value, currentRuolo?.label));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.aulss.value, riga.aulss?.value));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.distretto.value, riga.distretto?.value));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.comune.value, riga.comune?.value));

                    gruppo.listaAttributi = listaAttributi;

                    return gruppo;
                });
                listaGruppi = listaGruppi.concat(righeProfiloRefDirAssistentiSociali);
            }

            let righeProfiloDirettoreUnitaOperativa = listaGruppiRighePerAccordion.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.direttoreUnitaOperativa);
            if (righeProfiloDirettoreUnitaOperativa.length > 0) {
                righeProfiloDirettoreUnitaOperativa = righeProfiloDirettoreUnitaOperativa.map(riga => {
                    let gruppo = {};
                    gruppo.codicegruppo = riga.codicegruppo;
                    let listaAttributi = [];
                    let ruoloDirettoreUnitaOperativa = Utils.isObjectNull(this.currentRuoloDirettoreUnitaOperativa)
                        ? this.oldRuoloDirettoreUnitaOperativa
                        : this.currentRuoloDirettoreUnitaOperativa;

                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.ruolo.value, ruoloDirettoreUnitaOperativa?.label));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.aulss.value, riga?.aulss?.value ?? righeProfiloDirettoreUnitaOperativa[0]?.aulss?.value));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.distretto.value, riga.distretto?.value));
                    if (_.isEqual(ruoloDirettoreUnitaOperativa, ruoliSelect.ruoliDirettoreDiUnitaOperativa[0])
                        || _.isEqual(ruoloDirettoreUnitaOperativa, ruoliSelect.ruoliDirettoreDiUnitaOperativa[1])) {
                        const disciplina = this.getDisciplinaObject(enumsUtente.profiliUtente.direttoreUnitaOperativa, Utils.isObjectNull(this.currentDisciplinaDirettoreUnitaOperativa))?.value ?? null;
                        listaAttributi.push(this.creaOggettoAttributo(enumAttributo.disciplina.value, disciplina));
                    }
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.unitaOperativa.value, riga.unitaOperativa?.value ?? righeProfiloDirettoreUnitaOperativa[0]?.unitaOperativa?.value));

                    gruppo.listaAttributi = listaAttributi;

                    return gruppo;
                });
                listaGruppi = listaGruppi.concat(righeProfiloDirettoreUnitaOperativa);
            }

            let righeProfiloCentraleAdi = listaGruppiRighePerAccordion.filter(gruppo => gruppo.codicegruppo === enumsUtente.profiliUtente.centraleAdi);
            if (righeProfiloCentraleAdi.length > 0) {
                righeProfiloCentraleAdi = righeProfiloCentraleAdi.map(riga => {
                    let gruppo = {};
                    gruppo.codicegruppo = riga.codicegruppo;
                    let listaAttributi = [];
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.ruolo.value, Utils.isObjectNull(this.currentRuoloCentraleAdi) ? enumsUtente.profiliUtente.centraleAdi : this.currentRuoloCentraleAdi?.label));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.aulss.value, riga?.aulss?.value ?? righeProfiloCentraleAdi[0]?.aulss?.value));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.distretto.value, riga.distretto?.value));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.sede.value, riga.sede?.value));
                    listaAttributi.push(this.creaOggettoAttributo(enumAttributo.ambito.value, riga.ambito?.value));
                    gruppo.listaAttributi = listaAttributi;

                    return gruppo;
                });
                listaGruppi = listaGruppi.concat(righeProfiloCentraleAdi);
            }
        }
        this.listaGruppi = listaGruppi;
    }

    creaOggettoAttributo = (nomeAttributo, valore) => {
        let attributo = {};
        attributo.codiceAttributo = this.getCodiceAttributo(nomeAttributo);
        attributo.valore = valore;
        return attributo;
    }

    getCodiceAttributo = (nomeAttributo) => {
        switch (nomeAttributo) {
            case enumAttributo.ruolo.value:
                return enumAttributo.ruolo.label;
            case enumAttributo.aulss.value:
                return enumAttributo.aulss.label;
            case enumAttributo.distretto.value:
                return enumAttributo.distretto.label;
            case enumAttributo.comune.value:
                return enumAttributo.comune.label;
            case enumAttributo.sede.value:
                return enumAttributo.sede.label;
            case enumAttributo.disciplina.value:
                return enumAttributo.disciplina.label;
            case enumAttributo.unitaOperativa.value:
                return enumAttributo.unitaOperativa.label;
            case enumAttributo.ambito.value:
                return enumAttributo.ambito.label;
            default:
                return null;
        }
    }

    creaBodyForBackend = () => {
        let utenteapiapms = _.cloneDeep(this.state.utenteapiapms);
        let righe = _.cloneDeep(this.state.listaGruppiPerRigheAccordion);
        let listaGruppi = _.cloneDeep(this.listaGruppi);
        let listaGruppiCompleta = _.cloneDeep(this.state.oldListaGruppi)?.filter(g => g.codicegruppo !== enumsUtente.profiliUtente.referenteAulss);

        if (Utils.isStringEmptyOrNullOrUndefined(this.props.codiceFiscaleUtenteProfilato) || AuthUtils.hasUtenteRuoloReferenteAulss()) {
            Array.prototype.push.apply(righe, listaGruppiCompleta);
        }

        let listaStruttura = righe.map(riga => riga.aulss?.value).filter(aulss => !Utils.isStringEmptyOrNullOrUndefined(aulss));
        listaStruttura = Array.from(new Set(listaStruttura));

        if (this.state.amministratore && listaStruttura.length === 0)
            listaStruttura.push("050511");

        let listaUtentiAdi = [];
        listaStruttura.forEach(struttura => {
            let listaGruppiByStruttura = listaGruppi
                .filter(gruppo => gruppo.listaAttributi
                    .filter(attributo => attributo.codiceAttributo === enumAttributo.aulss.label && attributo.valore === struttura));
            let utente = {};
            utente.codiceFiscale = utenteapiapms.codiceFiscale.toUpperCase();
            utente.nome = utenteapiapms.nome.trim();
            utente.cognome = utenteapiapms.cognome.trim();
            utente.mail = utenteapiapms.mail;
            utente.utentelocale = false;

            utente.listaGruppi = [];
            if (this.state.amministratore) {
                let gruppo = {};
                gruppo.codicegruppo = enumsUtente.profiliUtente.admin;
                gruppo.listaAttributi = [
                    {
                        codiceAttributo: "adi-lista-settings",
                        valore: "[" + enumsUtente.profiliUtente.admin + "@@@@@@@]"
                    }
                ];
                utente.listaGruppi.push(gruppo);
            } else {
                this.creaListaGruppiPerProfilo(listaGruppiByStruttura, enumsUtente.profiliUtente.referenteAulss, struttura, utente);
                this.creaListaGruppiPerProfilo(listaGruppiByStruttura, enumsUtente.profiliUtente.medicoRichiedente, struttura, utente);
                this.creaListaGruppiPerProfilo(listaGruppiByStruttura, enumsUtente.profiliUtente.medicoFormalizzatore, struttura, utente);
                this.creaListaGruppiPerProfilo(listaGruppiByStruttura, enumsUtente.profiliUtente.professionistaValutatore, struttura, utente);
                this.creaListaGruppiPerProfilo(listaGruppiByStruttura, enumsUtente.profiliUtente.coordinatore, struttura, utente);
                this.creaListaGruppiPerProfilo(listaGruppiByStruttura, enumsUtente.profiliUtente.referenteDirigenteAssistentiSociali, struttura, utente);
                this.creaListaGruppiPerProfilo(listaGruppiByStruttura, enumsUtente.profiliUtente.direttoreUnitaOperativa, struttura, utente);
                this.creaListaGruppiPerProfilo(listaGruppiByStruttura, enumsUtente.profiliUtente.centraleAdi, struttura, utente);
                this.formattaListaSettings(utente);
            }

            utente.applicazione = "ADI";
            utente.struttura = struttura;
            listaUtentiAdi.push(utente);
        });

        return listaUtentiAdi;
    }

    creaListaGruppiPerProfilo = (listaGruppiByStruttura, profiloSelezionato, struttura, utente) => {
        let gruppoByProfilo = listaGruppiByStruttura
            .filter(gruppo => gruppo.codicegruppo === profiloSelezionato
                && gruppo.listaAttributi
                    .filter(attributo => attributo.codiceAttributo === enumAttributo.aulss.label && attributo.valore === struttura).length > 0);
        if (gruppoByProfilo.length > 0) {
            let gruppo = {};
            gruppo.codicegruppo = profiloSelezionato;
            gruppo.listaAttributi = [];
            let listaSettings = "[";
            gruppoByProfilo.forEach(g => {
                let currentRuolo = this.getRuoloObject(profiloSelezionato);
                let ruolo = Utils.isObjectNull(currentRuolo) ? this.getRuoloObject(profiloSelezionato, true)?.value : currentRuolo?.value;
                let disciplina = g.listaAttributi.find(a => a.codiceAttributo === enumAttributo.disciplina.label);
                let unitaOperativa = g.listaAttributi.find(a => a.codiceAttributo === enumAttributo.unitaOperativa.label);
                let distretto = g.listaAttributi.find(a => a.codiceAttributo === enumAttributo.distretto.label);
                let sede = g.listaAttributi.find(a => a.codiceAttributo === enumAttributo.sede.label);
                let comune = g.listaAttributi.find(a => a.codiceAttributo === enumAttributo.comune.label);
                let ambito = g.listaAttributi.find(a => a.codiceAttributo === enumAttributo.ambito.label);
                sede = !Utils.isObjectNull(sede) ? distretto?.valore?.concat(sede.valore) : '';

                listaSettings = listaSettings + profiloSelezionato + "@" + ruolo + "@" + (distretto?.valore ?? '')
                    + "@" + (sede ?? '') + "@" + (comune?.valore ?? '') + "@" + (disciplina?.valore ?? '')
                    + "@" + (unitaOperativa?.valore ?? '') + "@" + (ambito?.valore ?? '') + ",";
            });
            listaSettings = listaSettings.slice(0, -1) + "]";
            gruppo.listaAttributi.push({
                codiceAttributo: "adi-lista-settings",
                valore: listaSettings
            })
            utente.listaGruppi.push(gruppo);
        }
    }

    formattaListaSettings = (utente) => {
        let listaGruppi = _.cloneDeep(utente.listaGruppi);
        if (listaGruppi != null && listaGruppi.length > 0) {
            let listaSettings = "[";
            listaGruppi.forEach(gruppo => {
                let listaAttributi = gruppo.listaAttributi;
                listaSettings += listaAttributi.map(attributo => attributo.valore.replace("[", "").replace("]", ""));
                listaSettings += ","
            });
            listaSettings = listaSettings.slice(0, -1) + "]";
            utente.listaGruppi = utente.listaGruppi.map(gruppo => {
                gruppo.listaAttributi = gruppo.listaAttributi.map(attributo => {
                    attributo.valore = listaSettings;
                    return attributo;
                });
                return gruppo;
            });
        }
    }

    salvaUtente = () => {
        let listaUtenti = this.creaBodyForBackend();
        const isUtentePresenteSuDb = this.state.oldListaGruppi !== null && this.state.oldListaGruppi.length > 0;
        if (this.state.utente === null && !isUtentePresenteSuDb) {  // fase di creazione di un nuovo utente
            this.inserisciUtente(listaUtenti, messaggi.inserimentoNuovoUtente);
        } else {  // fase di modifica
            this.eliminaUtente(true, messaggi.modificaUtente, listaUtenti);
        }
    }

    inserisciUtente = (listaUtenti, messaggio) => {
        mostraSpinner();
        ApiAPMSService.inserisciListaUtenti(listaUtenti).subscribe(response => {
            if (response?.msg === "ERRORE") {
                if (response?.data && (response?.data?.errore || response?.data?.error)) {
                    Utils.toasterFunction({
                        status: response?.data?.codice ? response?.data?.codice : response?.status,
                        type: "critical",
                        text: response?.data?.errore ? response?.data?.errore : response?.data?.message
                    })
                }
                nascondiSpinner();
            } else {
                this.setState({redirectToRicerca: true}, () => Utils.toasterFunction(messaggio));
            }
        });

    }

    eliminaUtente = (isModifica, messaggio, listaUtenti = []) => {
        let utente = {};
        utente.codiceFiscale = this.state.utenteapiapms.codiceFiscale;
        utente.applicazione = "ADI";

        let utenteAdi = this.state.utente !== null ? _.cloneDeep(this.state.utente) : _.cloneDeep(this.state.oldListaGruppi);
        let listaStruttura = utenteAdi.map(u => u?.struttura ?? u?.aulss?.value)?.filter((value, index, self) => self.indexOf(value) === index);

        mostraSpinner();
        let observableList = [];
        listaStruttura.forEach(struttura => {
            utente.struttura = struttura;
            let observable = ApiAPMSService.eliminaUtente(utente).pipe(map(response => response));
            observableList.push(observable);
        });
        forkJoin(observableList).subscribe(responses => {
            let errore = responses.find(r => r?.msg === "ERRORE") ?? null;
            if (errore) {
                if (errore?.data && (errore?.data?.errore || errore?.data?.error)) {
                    Utils.toasterFunction({
                        status: errore?.data?.codice ?? errore?.status,
                        type: "critical",
                        text: errore?.data?.errore ?? errore?.data?.message
                    });
                }
                nascondiSpinner();
            } else {
                if (isModifica) {
                    nascondiSpinner();
                    this.inserisciUtente(listaUtenti, messaggio);
                } else {
                    this.setState({redirectToRicerca: true}, () => Utils.toasterFunction(messaggio));
                }
            }
        });
    }

    renderBottoni = () => {
        let btnList = [
            {
                gropBtnList: [
                    {
                        id: "indietroBtn",
                        text: this.props.codiceFiscaleUtenteProfilato ? "Indietro" : "Chiudi",
                        className: "btnWhiteOutlineBorderGreen",
                        isLink: true,
                        to: routepath.pannello_configurazione_ricerca,
                        isvisible: true
                    },
                    {
                        id: "annullaFiltriBtn",
                        text: "Pulisci campi",
                        className: "btnWhiteOutlineBorderGreen",
                        isvisible: !this.props.codiceFiscaleUtenteProfilato,
                        onclickFunction: () => this.annullaModifiche(),
                        disabled: this.isPulsanteAnnullaDisabled(this.state.utenteapiapms),
                    },
                ],
                isvisible: true
            },
            {
                id: "eliminaUtenteBtn",
                text: "Elimina utente",
                className: "btnRedOutline",
                dataDismiss: "modal",
                isvisible: this.props.codiceFiscaleUtenteProfilato,
                onclickFunction: () => this.apriModaleEliminazioneUtente()
            },
            {

                id: "creaProfiloBtn",
                text: "Salva",
                className: "btnGreenOutline",
                onclickFunction: () => {
                    if (this.isInsertMode()) {
                        this.recuperaUtente(false).subscribe(() => {
                            let isListaGruppiValida = this.validaListaGruppi();
                            if (isListaGruppiValida) this.salvaUtente();
                        });
                    } else {
                        let isListaGruppiValida = this.validaListaGruppi();
                        if (isListaGruppiValida) this.salvaUtente();
                    }
                    GestionePasswordADIService.invioMailCreazionePassword();
                },
                disabled: this.isPulsanteCreaUtenteDisabled(this.state.utenteapiapms),
                aling: "right",
                isvisible: true
            }
        ]

        return (

            <ButtonsBoxStick
                parentClass={this.props.codiceFiscaleUtenteProfilato ? "d-flex justify-content-between" : "row"}
                btnList={btnList}
            />
        );
    }

    renderModaleEliminazioneUtente = () => {
        return <ModalHelper
            id={"confermaEliminazione"}
            title={"Eliminazione in corso"}
            titleClassName={"modal-header d-inline text-center backgroundGreen"}
            containerClassName={("modal-dialog modal-dialog-scrollable modal modal-dialog-centered")}
            contentClassName={"modal-content"}
            body={(<span className={"text-center"}>Attenzione: stai per procedere con l’eliminazione dell’utente. Vuoi confermare?</span>)}
            footerClassName={"modal-footer justify-content-around"}
            bodyClassName={"modal-body d-flex align-items-center mx-auto f-weight-600"}
            confirmBtnFunction={this.confermaEliminazioneUtente}
            cancelBtnFunction={this.chiudiModaleEliminazioneUtente}
            confirmBtnText={"Conferma"}
        />
    }
    apriModaleEliminazioneUtente = () => {
        this.setState({apriModaleEliminazioneUtente: true});
    }

    chiudiModaleEliminazioneUtente = () => {
        this.setState({apriModaleEliminazioneUtente: false});
    }

    confermaEliminazioneUtente = () => {
        this.eliminaUtente(false, messaggi.eliminazioneUtente);
        this.chiudiModaleEliminazioneUtente();
    }

    renderTitolo = () => {
        if (this.props.codiceFiscaleUtenteProfilato) {
            return (
                <div className={"row m-0"}>
                    <h1 className={"pb-2"}>Dati utente</h1>
                </div>
            );
        } else {
            return (
                <>
                    <div className={"row m-0"}>
                        <h1 className={"pb-2"}>Gestione della profilazione degli utenti</h1>
                    </div>
                    <div className={"row m-0"}>
                        <span>Si richiede la compilazione dei campi sottostanti per procedere con la creazione di un nuovo profilo utente:</span>
                    </div>
                </>
            );
        }
    }

    render() {
        if (this.state.redirectToRicerca) {
            nascondiSpinner();
            return <Redirect to={routepath.pannello_configurazione_ricerca}/>
        }

        return (
            <div id={"pannelloConfigurazioneGestioneUtenteBody"} className={"container-fluid"}>
                {this.state.apriModaleEliminazioneUtente && this.renderModaleEliminazioneUtente()}
                <div className={["p-4", "mr-3", styles.container, "customScrollbarStyle"].join(" ")}>
                    {this.renderTitolo()}
                    <div className={["row", styles.infoSticky, "mt-4"].join(" ")}>
                        {this.renderCampoInputTesto("Cognome", "cognome", this.state.utenteapiapms.cognome, "Inserisci cognome utente", this.props.codiceFiscaleUtenteProfilato, "")}
                        {this.renderCampoInputTesto("Nome", "nome", this.state.utenteapiapms.nome, "Inserisci nome utente", this.props.codiceFiscaleUtenteProfilato, "")}
                        {this.renderCampoInputTesto("Codice fiscale", "codiceFiscale", this.state.utenteapiapms.codiceFiscale, "Inserisci codice fiscale utente", this.props.codiceFiscaleUtenteProfilato, "16")}
                        {this.renderCampoInputTesto("E-mail", "mail", this.state.utenteapiapms.mail, "Inserisci e-mail utente", false, "")}
                    </div>
                    {this.renderTitoloProfilo()}
                    {this.renderCheckboxProfilo()}
                </div>
                {this.renderBottoni()}
            </div>
        );
    }
}

PannelloConfigurazioneGestioneUtenteADI.propTypes = propTypes;
